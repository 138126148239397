import React, { useState, useEffect, useRef } from 'react';
// import ReactDOM from 'react-dom';
import { useOutsideClickRef } from 'rooks';
import {debounce} from 'lodash';
import {Icon} from 'antd';
import './styles.scss';
import classNames from 'classnames';
import {Portal} from './index';

interface SeachProps{
    onBlur: Function;
    onSelect: Function;
    elem: React.RefObject<HTMLInputElement>;
    data: any;
}

const Search = ({ ...props}: any) => {
    const ref = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState('');
    const [isError, setError] = useState(false);
    const [data, setData] = useState<any>([]);
    const [showDetails, setShowDetails] = useState(false);

    useEffect(() => {
        setData(props.data);
        setError(false);
    }, [props.data]);

    useEffect(() => {
        setValue(props.selectedValue);
    }, [props.selectedValue]);

    return (
        <>
            <div className={classNames('ui mini input', {
                error: isError
            })}>
                <input 
                    ref={ref}
                    type="text" 
                    value={value}
                    placeholder={props.placeholder}
                    onChange={(e)=>{
                        props.onSearch && props.onSearch(e.target.value);
                        setValue(e.target.value);
                        setShowDetails(true);
                    }}
                    onClick={() => {
                        props.getData();
                        setShowDetails(!showDetails);
                    }}
                />
            </div>
            {
                showDetails?
                    (
                        <Portal>
                            <SearchCategoryUI 
                                elem={ref}
                                data={data}
                                //hideShow={}
                                onBlur={()=>{
                                    setShowDetails(false);
                                }}
                                onSelect={(value: string)=>{
                                    setShowDetails(false);
                                    props.onSelect(value);
                                }}
                            />
                        </Portal>
                    ) : null
            }
        </>
    );
};

export const SearchCategoryUI = (props: SeachProps) => {
    const [data, setData] = useState([] as any);
    const [position, setPosition] = useState({
        X: 0,
        Y: 0
    });
    
    const [ref] = useOutsideClickRef(()=>{
        props.onBlur();
    });

    const updatePosition = ()=>{
        const rect: DOMRect = props.elem.current?.getBoundingClientRect() || {} as DOMRect;
        setPosition({
            X: rect.left,
            Y: rect.top + rect.height
        });
    };

    useEffect(()=>{
        const fn = debounce(()=>{
            updatePosition();
        }, 20);
        document.getElementById('querybuilder_holder')?.addEventListener('scroll',fn );
        return ()=>{
            document.getElementById('querybuilder_holder')?.removeEventListener('scroll', fn);
        };
    }, []);

    useEffect(()=>{
        updatePosition();
        setData(props.data);
    }, [props]);

    return (
        <div 
            ref={ref}
            className="deepiq-search"
            style={{
                left: position.X,
                top: position.Y
            }}
        >
            {
                (!Array.isArray(data) || data.length===0)? null : 
                    <div style={{
                        maxHeight: 300,
                        overflow: 'auto',
                        width: '100%'
                    }}>
                        {
                            data?.map((elem: any, index: number) => {
                                return (
                                    <div key={elem.title + 'index1' + index} className="category">
                                        <div className="name">{elem.name}</div>
                                        <div className="results">
                                            {
                                                elem?.results.map((result: any, index1: number)=>{
                                                    return (
                                                        <div key={result.name + 'index1' + index1} onClick={()=>{
                                                            props.onSelect(result);
                                                        }} className="result">
                                                            {
                                                                result.isSelected? <Icon type="check" className="result-icon" /> : null
                                                            }
                                                            <div className="content">{result.title}</div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
            }
        </div>
    );
};


export default Search;
