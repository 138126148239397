import { useSelector } from 'react-redux';
import { RootState } from '../store/types';
import { CanvasReducerState, WorkflowCanvasTabInfo, WorkflowAnalyticsTabInfo } from '../store/canvas';

export function useGetActiveTabInfo(page: 'workflowEditor'): WorkflowCanvasTabInfo | undefined;
export function useGetActiveTabInfo(page: 'analytics'): WorkflowAnalyticsTabInfo | undefined;
export function useGetActiveTabInfo(page: keyof CanvasReducerState): WorkflowCanvasTabInfo | WorkflowAnalyticsTabInfo | undefined{
    const { activeTab, openTabs } = useSelector((store: RootState) => store.CanvasReducer[page]);

    return openTabs.get(activeTab.id)?.info;
};