import { newComponentHandler } from "@api/new-custom-component";
import { errorAlert } from "@components/toastify/notify-toast";
import { ExecutionEnvModes } from "@constants/enums";
import { AppThunk } from "@store/types";
import {
	setVariableComponentInfo,
	setCustomComponentsList,
	setComponentTreeData,
	setComponentFnNamesInfo,
} from "@store/workflow";
import { ComponentTreeView, ComponentTreeViewItemInfo } from "./types";

function processRITreeData(data: any) {
	let treeData = JSON.parse(data.jsondata);
	let variableComponent: ComponentTreeViewItemInfo | null = null;

	const labelFnNames: Record<string, string> = {};
	treeData.forEach((parentInfo: { nodes: any[] }) => {
		parentInfo.nodes.some((child: any) => {
			if (!child.is_component) {
				// For components that are in the second level like Data Transformation, Structured Analytics
				child.nodes.forEach((grandChild: any) => {
					labelFnNames[grandChild.details.fnName] = grandChild.label;
				});
			} else {
				// For components that are in the first level like Streaming Analytics, Geospatial Analytics
				labelFnNames[child.details.fnName] = child.label;
			}
		});
	});

	const utilsIndex = treeData.findIndex(
		(parent: { label: string }) => parent.label === "Utils"
	);
	if (utilsIndex !== -1) {
		variableComponent = treeData[utilsIndex].nodes.find(
			(node: any) => node.key === "generate_variable"
		);
		treeData = treeData
			.slice(0, utilsIndex)
			.concat(treeData.slice(utilsIndex + 1));
	}

	return { treeData, labelFnNames, variableComponent };
}

const setFormDataOfCustomComponent = (element: any, index: number) => {
	const formDatainputports: any = element.formData.inputPorts;
	const argLsit: any = element.formData.argList;
	const argFormData: any = [];
	try {
		argLsit.forEach((element: any, index: number) => {
			if (element.typeOf === "text") {
				const arg: Record<string, any> = {
					key: element.title,
					type: "input",
					defaultValue: element.defaultValue,
					templateOptions: {
						arg_index: formDatainputports + 2 + index,
						label: element.title,
						qtip: element.tooltip,
						required: element.required,
						variable_type: element.variable_type,
					},
				};
				if (element.useCaptureSchema) {
					arg.useCaptureSchema = {
						multipleSelect: true,
					};
				}
				argFormData.push(arg);
			} else if (element.typeOf === "dropdown") {
				const options = element.defaultValue;
				let optionsArray: any;
				const optionList: any = [];
				if (options?.includes(",")) {
					optionsArray = options.split(",");
					for (let i = 0; i < optionsArray.length; i++) {
						const optionValue = {
							label: optionsArray[i],
							value: optionsArray[i],
						};
						optionList.push(optionValue);
					}
					const arg = {
						key: element.title,
						type: "select",
						defaultValue: optionList[0].value,
						templateOptions: {
							arg_index: formDatainputports + 2 + index,
							label: element.title,
							qtip: element.tooltip,
							variable_type: element.variable_type,
							options: optionList,
						},
					};
					argFormData.push(arg);
				}
			}
		});
	} catch (e) {
        // eslint-disable-next-line no-console
		console.log(e, "Skipped ", element);
	}

	const details: any = {
		componentInfo: [
			{
				key: "description",
				defaultValue: element.formData.componentDesc,
			},
		],
		key: element.formData.key || element.formData.functionName,
		qtip: element.formData.componentDesc,
		fnName: element.formData.functionName || `tedt${index}`,
		fnType: element.formData.functionType,
		fn_id: element.formData.functionName || `tedt${index}`,
		description: element.formData.componentDesc,
		portManagement: {
			inputPorts: {
				defaultValue: element.formData.inputPorts,
			},
			outputPorts: {
				defaultValue: element.formData.outputPorts,
			},
		},
		formData: argFormData || [],
	};
	return details;
};

function processCustomComponentData(
	responseData: any,
	activeExecutionEnv: ExecutionEnvModes
) {
	const data = responseData.data;
	if (Array.isArray(data)) {
		const customComponentDataArray = data.map(
			(element: any, index: any) => {
				const customComponentPayload = {
					customComponentPayload: element.payload,
				};
				let details_RI_tree: any;
				if (element.formData) {
					details_RI_tree = setFormDataOfCustomComponent(
						element,
						index
					);
				}
				const componentObject = {
					id: element.id,
					name: element.name || `${index}`,
					fn_id: element.name || `${index}`,
					payload: element.payload,
					label: element.name,
					key: element.name || `test${index}`,
					is_component: true,
					description: element.formData.componentDesc,
					isCustomComponent: true,
					type: activeExecutionEnv,
					details: { ...details_RI_tree, ...customComponentPayload },
					formDetails: element.formData || [],
				};

				return componentObject;
			}
		);
		const customComponentWrapperObject = {
			id: "Custom_Component",
			label: "User Custom Component",
			key: "Custom_Component",
			is_component: false,
			nodes: customComponentDataArray,
		};
		return customComponentWrapperObject;
	}
}

function removeRITreeData(data: any, arr: any, filters: any, type: any) {
	if (!arr) {
		arr = [];
	}
	if (Array.isArray(data)) {
		data.forEach((_data: any) => {
			data = { ..._data };
			let isAdd = false;
			let isRemove = false;
			if (type.includes(data.type)) {
				isAdd = true;
			}
			filters?.keys?.forEach((key: string) => {
				// eslint-disable-next-line no-prototype-builtins
				if (data.details?.hasOwnProperty(key)) {
					if (
						filters.filters[key].values.includes(data.details[key])
					) {
						if (filters.filters[key].type === "add") {
							isAdd = true;
						} else {
							isRemove = true;
						}
					}
				}
			});
			if ((isRemove || !isAdd) && data.is_component) {
				return;
			}

			if (data.nodes) {
				data.nodes = removeRITreeData(data.nodes, [], filters, type);
				if (data.nodes.length) {
					arr.push(data);
				}
			} else {
				arr.push(data);
			}
		});
	}
	return arr;
}

const updateTreeData = (
	treeData: any,
	isAdmin: boolean,
	activeExecutionEnv: ExecutionEnvModes
) => {
	const removeObj: any = {};
	if (!isAdmin) {
		removeObj.users = {
			values: ["admin"],
			type: "remove",
		};
	}
	const keys = Object.keys(removeObj);
	treeData = removeRITreeData(treeData, [], { keys, filters: removeObj }, [
		activeExecutionEnv,
	]);
	return treeData;
};

export const getComponentTreeData = (): AppThunk => (
	dispatch,
	getState
): Promise<ComponentTreeView> => {
	dispatch(setComponentTreeData({ loading: true, data: [] }));
	const {
		CommonReducer: { activeExecutionEnv },
		AccountReducer,
	} = getState();

	let _treeData: ComponentTreeView = [];

	return new Promise((resolve, reject) => {
		Promise.all([
			newComponentHandler.getRITree(),
			newComponentHandler.getComponentList(activeExecutionEnv),
		])
			.then((result: any) => {
				const ri_tree = result[0].data;
				const customComponent_data = result[1].data;
				const {
					treeData,
					labelFnNames,
					variableComponent,
				} = processRITreeData(ri_tree);

				dispatch(setVariableComponentInfo(variableComponent));
				dispatch(setComponentFnNamesInfo(labelFnNames));

				if (customComponent_data.data.length !== 0) {
					dispatch(
						setCustomComponentsList(customComponent_data.data)
					);
					treeData.push(
						processCustomComponentData(
							customComponent_data,
							activeExecutionEnv
						)
					);
				}
				_treeData = updateTreeData(
					treeData,
					AccountReducer.activeUserInfo.isAdmin,
					activeExecutionEnv
				);

				resolve(_treeData);
			})
			.catch((e) => {
                // eslint-disable-next-line no-console
				console.log(e);
				errorAlert("Cannot load Components");
				reject();
				//handle error case
			})
			.finally(() => {
				dispatch(
					setComponentTreeData({ loading: false, data: _treeData })
				);
			});
	});
};
