import ReactModal from 'react-modal';
import React, { CSSProperties } from 'react';
import './styles.scss';
import classNames from 'classnames';
import { useDataExplorerContext } from '@pages/data-explorer-new/useDataExplorerContext';

export interface ModalProps {
    isOpen: boolean;
    toggleClose: () => void;
    className?: string;
    title: React.ReactNode;
    subtitle?: React.ReactNode | string;
    showCloseMark?: boolean;
    image?: string | React.ReactNode;
    shouldCloseOnOverlayClick?: boolean;
}


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)'
    }
};

ReactModal.setAppElement('#root');

export const Modal: React.FC<ModalProps> = ({ 
    isOpen, 
    toggleClose, 
    className, 
    children, 
    title, 
    subtitle, 
    showCloseMark = false,
    shouldCloseOnOverlayClick = true, 
    image 
}) =>{
    const { theme } = useDataExplorerContext();

    const bodyStyle: CSSProperties | undefined = theme ? {
        background: theme === "dark" ? 'rgba(0, 0, 0, 0.24)' : '#F5F5F5',
    } : undefined;

    const textStyle: CSSProperties | undefined = theme ? {
        color: theme === "dark" ? '#fff' : '#000'
    } : undefined;

    return(
    <ReactModal
        isOpen={isOpen}
        onRequestClose={toggleClose}
        style={customStyles}
        closeTimeoutMS={200}
        className={className}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
        <div className="modal__header" style={bodyStyle}>
            {image && 
                typeof image === 'string' 
                    ? <img className= {classNames({'show_modal_img': image})}  src= {image} alt=""/> 
                    : image
            }
            <div>
                <span className="modal__title" role="modal-title" style={textStyle}> 
                    {title}
                </span>
                {!!(subtitle) && <span className="modal__subtitle" style={textStyle}>{subtitle}</span>}
                {(showCloseMark || !shouldCloseOnOverlayClick) && 
                    <img 
                        className="icon__hover close__icon__modal" 
                        src="/icons/close_tab.svg" 
                        alt="close-modal" 
                        onClick={toggleClose} 
                    />
                }
            </div>
        </div>
        <div className="modal__body" style={bodyStyle}>
            {children}
        </div>
    </ReactModal>
)};