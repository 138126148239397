import React, { memo } from 'react';
import { Modal } from '../../../components/modals';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/types';
import { toggleModal } from '../../../store/workflow';

interface Props {
    isEmptyWorkflow:boolean;
    workflowName: string; 
    onSubmit: () => void;
}

export const WrapperComponent: React.FC<Props> = ({ workflowName, onSubmit, isEmptyWorkflow }) => {
    const dispatch = useDispatch();
    const showModal = useSelector((store: RootState) => store.WorkflowReducer.showModal.loseUnsavedChanges);

    const toggleClose = () => dispatch(toggleModal('loseUnsavedChanges', false));

    return(
        <Modal
            isOpen={showModal}
            toggleClose={toggleClose}
            title={'Close Workflow'}
            className="deleteWorkflowModal"
        >
            <p className="deleteWorkflowModal__areYouSureMsg">Are you sure you want to lose the unsaved changes of {workflowName}?</p>
            {!isEmptyWorkflow&&<p className="deleteWorkflowModal__warningMsg">Warning: You can&apos;t undo this action!</p>}
            {isEmptyWorkflow&&<p className="deleteWorkflowModal__warningMsg">Warning: You can&apos;t apply this action on empty workflow!</p>}
            <div className="modalBtns__box">
                <button 
                    className="btn-md btn-yellow"
                    type="submit"
                    onClick={onSubmit}
                    disabled={isEmptyWorkflow}
                >
                    Yes
                </button>
                <button 
                    className="btn-md btn-cancel"
                    type="button"
                    onClick={toggleClose}
                >
                    Cancel
                </button>
            </div>
        </Modal>
    );
}; 

export const LoseUnsavedChangesModal = memo(WrapperComponent);