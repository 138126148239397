import React, { useMemo } from 'react';
import Form, { InputField } from '../../../../components/form';
import * as yup from 'yup';
import { RadioField } from '../../../../components/form/radio-field';
import { _selectoptionType } from '../../../../components/form/select-field';
import styles from '../../styles.module.scss';
import { convertEnumsToOptions } from '../../../../utils';
import { ToggleFieldFormik } from '../../../../components/form/toggle-field-formik';
import { UserRoles } from '../../../../constants/enums';

const userFormSchema = yup.object({
    firstName: yup.string().required('This is a required field'),
    lastName: yup.string().required('This is a required field'),
    email: yup.string().email('Not a valid email').required('This is a required field'),
    role: yup.string(),
    userStatus: yup.boolean()
});

export type UserFormValues = yup.InferType<typeof userFormSchema>

export type UserFormProps = {
    type: 'update' | 'readOnly';
    initialValues: any;
    submitButtons?: React.ReactNode;
    onSubmit?: (arg0: UserFormValues) => any;
    hideUserStatus?: boolean;
}


const userRoleOptions: _selectoptionType[] = convertEnumsToOptions(UserRoles);

export const UserForm: React.FC<UserFormProps> = ({ onSubmit, type, initialValues, submitButtons, hideUserStatus = false }) => {

    const handleFormSubmit = (values: UserFormValues) => {
        onSubmit && onSubmit(values);
    };

    const initialValuesForForm: UserFormValues = useMemo(() => {
        if(initialValues)
            return { ...initialValues, userStatus: initialValues.enabled };
        return { firstName: '',  lastName: '', email: '',userStatus: true, role: UserRoles['User'] };
    }, [initialValues, type]);


    const readOnly = type === 'readOnly';

    return(
        <Form
            initialValues={initialValuesForForm}
            validationSchema={userFormSchema}
            onSubmit={handleFormSubmit}
            enableReinitialize
        >
            <div
                className={styles['userInfoForm']}
            >
                <InputField 
                    name="firstName"
                    label="First Name"
                    readOnly={readOnly}
                    required={!readOnly}
                />
                <InputField 
                    name="lastName"
                    label="Last Name"
                    readOnly={readOnly}
                    required={!readOnly}
                />
                <InputField 
                    name="email"
                    label="Email Address"
                    readOnly
                />
                <RadioField
                    label="Role"
                    name="role" 
                    options={userRoleOptions}
                    readOnly={readOnly}
                    inline
                />
                {!hideUserStatus &&
                    <div>
                        <label className={styles['userStatusLabel']}>
                            User Status
                        </label>
                        <ToggleFieldFormik name="userStatus" label="Active" unSelectedLabel="Inactive" readOnly={readOnly}/>
                    </div>
                }
            </div>
            <br />
            {submitButtons && submitButtons}
        </Form>
    );
};