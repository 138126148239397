import { Progress, Row, Col } from 'antd';
import React from 'react';
import { Modal } from '../../../components/modals';

interface ViewRequestDataModalProps {
  isOpen: boolean;
  data: any[];
  onClose: () => void;
  title: string;
}

export const ViewProgressRequestDataModal: React.FC<ViewRequestDataModalProps> = ({ isOpen, data, onClose, title }) => {

  return (
    <Modal
      isOpen={isOpen}
      toggleClose={onClose}
      title={title}
      subtitle={""}
      className="progressModal__containerLarge"
      showCloseMark
    >
        <div style={{maxHeight: 430, overflowY: 'auto'}}>
     {data?.map((item, index) => (
        <div key={index} style={{ marginBottom: '25px', marginRight: '17px' }}>
          <Row align="middle">
            <Col span={24}>
              <span style={{ color: 'white', display: 'inline-block' }}>
                • {item.filename}
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Progress 
              percent={item.progress}
              format={percent => <span style={{ color: 'white' }}>{`${percent}%`}</span>}
               />
            </Col>
          </Row>
        </div>
      ))}
      </div>
    </Modal>
  );
};