import range from 'lodash/range';
import React from 'react';
import './styles.scss';

export const MainLoadingSkeleton = () => (
    <section
        className="mainLoadingSkeleton"    
    >
        <div className="mainLoadingSkeleton__LHS">
            <div className="mainTitle" />
            <div
                className="treeviewTitle__box"
            >
                <div className="treeviewTitle" />
                <div className="treeviewTitle" />
            </div>
            <div className="search__box" />
            {range(1,6).map(r => (
                <div 
                    key={r}
                    className={`treeviewItems ${r === 1 ? 'firstItem': ''}`} 
                />
            ))}
        </div>
        <div className="mainLoadingSkeleton__canvas">
            <div
                className="canvasHeader"
            >
                <div
                    className="tabSkeleton"
                >
                    <div className="innerTabSkeleton"/>
                </div>
            </div>
            <div className="floatingToolsSkeleton" />
            <div className="zoomActionToolsSkeleton">
                <div />
                <div />
            </div>
            <div className="consoleSkeleton">
                <div className="consoleTitle" />
                <div className="consoleOptions" />
            </div>
        </div>
        <div className="mainLoadingSkeleton__RHS">
            <div className="userIconSkeleton" />
            <div className="propsIconSkeleton" />
        </div>

    </section>   
);