import React, { useEffect, useState } from 'react';
import { ToggleField } from './toggle-field';
import { useField } from 'formik';

interface Props  {
    name: string;
    label: string;
    className?: string;
    unSelectedLabel?: string;
    readOnly?: boolean;
}


export const ToggleFieldFormik: React.FC<Props> = ({ name, label, className, unSelectedLabel, readOnly = false }) => {
    const [,fieldMeta ,{ setValue }] = useField(name);
    const [active, setActive] = useState(false);

    useEffect(() => {
        // updates formik state
        setValue(active);
    }, [active]);

    useEffect(() => {
        // to set initial value
        setActive(!!(fieldMeta.initialValue));
    }, [fieldMeta.initialValue]);

    const handleOnClick = () => {
        setActive(active => !active);
    };

    return(
        <ToggleField 
            active={active}
            onClick={handleOnClick}
            label={label}
            className={className}
            unSelectedLabel={unSelectedLabel}
            disabled={readOnly}
        />
        
    );
};