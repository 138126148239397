import React, { useState } from 'react';
import { Modal } from '../../../components/modals';
import { SelectedUserInfo } from '../types';
import { UserForm, UserFormValues } from './../user_management/UserInfoSection/UserForm';
import cs from 'classnames';
import styles from '../styles.module.scss';
import { getUserStatusStyle } from '../utils';
import {
    CreateUserData,
    UserHandler,
    UserInfo,
    UserRole
} from '../../../api/user-handler';
import { UserRoles } from '../../../constants/enums';
import { errorHandlerFor400 } from '../../../api/services/errorhandler';
import { InPageSpinner } from '../../../components/spinners/in-page-spinner';
import { successAlert } from '../../../components/toastify/notify-toast';

type EditUserModalProps = {
    showModal: boolean;
    toggleClose: () => void;
    selectedUserInfo: SelectedUserInfo;
    rolesList: UserRole[];
    onUpdateUserSuccess: (arg0: UserInfo) => void;
};

const filterAndAddRoleToExistingRoles = (
    roleToBeAdded: UserRoles,
    roleToBeRemoved: UserRoles,
    userRoles: UserRole[],
    rolesListRef: UserRole[]
) => {
    let hasRoleToBeAdded = false;
    userRoles = userRoles.filter(role => {
        if (role.name === roleToBeAdded) {
            hasRoleToBeAdded = true;
        }
        return role.name !== roleToBeRemoved;
    });
    // const hasRoleToBeAdded = userRoles.find(role => role.name === roleToBeAdded);
    if (!hasRoleToBeAdded) {
        const roleToBeAddedInfo = rolesListRef.find(
            role => role.name === roleToBeAdded
        );
        if (roleToBeAddedInfo) {
            userRoles.push(roleToBeAddedInfo);
        }
    }
    return userRoles;
};

const EditUserModal: React.FC<EditUserModalProps> = ({
    showModal,
    toggleClose,
    selectedUserInfo,
    rolesList,
    onUpdateUserSuccess
}) => {
    const [showLoader, toggleLoader] = useState(false);

    const handleSubmit = (values: UserFormValues) => {
        if (selectedUserInfo) {
            toggleLoader(true);
            let userRoles = selectedUserInfo?.roles;
            if (values.role === UserRoles['Admin']) {
                userRoles = filterAndAddRoleToExistingRoles(
                    UserRoles['Admin'],
                    UserRoles['User'],
                    userRoles,
                    rolesList
                );
            } else if (values.role === UserRoles['User']) {
                userRoles = filterAndAddRoleToExistingRoles(
                    UserRoles['User'],
                    UserRoles['Admin'],
                    userRoles,
                    rolesList
                );
            }

            const data: CreateUserData = {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                enabled: !!values.userStatus,
                roles: userRoles,
                groups: selectedUserInfo.groups
            };
            UserHandler.UpdateUser(
                selectedUserInfo.id,
                data,
                res => {
                    toggleLoader(false);
                    successAlert('User updated successfully');
                    onUpdateUserSuccess(res);
                },
                res => {
                    toggleLoader(false);
                    errorHandlerFor400(res);
                }
            );
        }
    };

    return (
        <Modal
            isOpen={showModal}
            toggleClose={toggleClose}
            title={'Edit User Settings'}
            className={cs(
                'editConnection_container',
                styles['addEditUserModal']
            )}
            subtitle={
                <>
                    {selectedUserInfo?.fullName}
                    <div
                        className={cs(
                            getUserStatusStyle(!!selectedUserInfo?.enabled),
                            styles['']
                        )}
                    >
                        {selectedUserInfo?.status}
                    </div>
                </>
            }
        >
            <div className={styles['addEditUserModal__innerContainer']}>
                <UserForm
                    type={showLoader ? 'readOnly': 'update'}
                    initialValues={selectedUserInfo}
                    submitButtons={
                        <div className={styles['addEditUserModal__actionBtns']}>
                            <button
                                className="btn btn-lg btn-yellow"
                                type="submit"
                                disabled={showLoader}
                            >
                                {showLoader ?
                                    <InPageSpinner size="XSmall" color="black" />
                                    :
                                    'Save'
                                }
                                
                            </button>
                            <button
                                className="btn btn-lg"
                                type="button"
                                onClick={toggleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    }
                    onSubmit={handleSubmit}
                />
            </div>
        </Modal>
    );
};

export { EditUserModal };
