import _ from 'lodash';
import { AnalyticsFlowReducerState, ActionTypes } from './types';

const initialState: AnalyticsFlowReducerState = {
    jobs: {
        totalCount: 0, 
        data: [], 
        jobFrequency: [],
        hourlyBarchart: [], 
        dailyBarchart: [],
        weeklyBarchart: [],
        monthlyBarchart: [],
        totalBarchart: [],
        is_loaded: false
    }
};


export const AnalyticsFlowReducer = (state = initialState, action: ActionTypes): AnalyticsFlowReducerState => {
    const new_state = _.cloneDeep(state);

    switch(action.type){
        case 'EXECUTION_ANALYTICS_FLOW_ALL':
            _.setWith(new_state, ['jobs'], action.payload);
            return new_state;
        default:
            return state;
    }
};