import React from 'react';

interface Props {
    title: React.ReactNode; 
    subtitle: React.ReactNode;
}


export const ErrorContent: React.FC<Props> = ({ title, subtitle }) => {
    // use className title for title and subtitle for subtitle
    return(
        <section className="errorPage">
            <div className="errorContentBox">
                <svg
                    width={461}
                    height={320}
                    viewBox="0 0 461 320"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0)">
                        <path
                            d="M87.451 68.6965C101.991 68.6965 113.778 56.9096 113.778 42.3697C113.778 27.8299 101.991 16.043 87.451 16.043C72.9112 16.043 61.1243 27.8299 61.1243 42.3697C61.1243 56.9096 72.9112 68.6965 87.451 68.6965Z"
                            fill="#FF6584"
                        />
                        <path
                            d="M215.541 141.145C215.541 203.264 178.612 224.954 133.057 224.954C87.5032 224.954 50.5742 203.264 50.5742 141.145C50.5742 79.0257 133.057 0 133.057 0C133.057 0 215.541 79.0257 215.541 141.145Z"
                            fill="#F2F2F2"
                        />
                        <path
                            d="M130.052 215.452L130.897 163.464L166.053 99.1466L131.03 155.308L131.409 131.932L155.639 85.3995L131.51 125.746L132.193 83.703L158.139 46.6572L132.3 77.0917L132.727 0L130.045 102.056L130.266 97.8454L103.887 57.468L129.843 105.927L127.385 152.882L127.312 151.636L96.9009 109.145L127.219 156.039L126.912 161.911L126.857 161.999L126.882 162.481L120.646 281.609H128.978L129.978 220.077L160.221 173.298L130.052 215.452Z"
                            fill="#3F3D56"
                        />
                        <path
                            d="M460.962 166.587C460.962 217.435 430.734 235.189 393.446 235.189C356.157 235.189 325.929 217.435 325.929 166.587C325.929 115.74 393.446 51.0532 393.446 51.0532C393.446 51.0532 460.962 115.739 460.962 166.587Z"
                            fill="#F2F2F2"
                        />
                        <path
                            d="M390.986 227.411L391.677 184.856L420.454 132.21L391.786 178.18L392.097 159.046L411.93 120.957L392.179 153.982L392.738 119.568L413.976 89.2443L392.826 114.157L393.175 51.0532L390.98 134.59L391.161 131.145L369.568 98.0934L390.814 137.76L388.802 176.195L388.742 175.174L363.85 140.393L388.667 178.778L388.415 183.585L388.37 183.657L388.391 184.052L383.286 281.564H390.106L390.924 231.197L415.681 192.906L390.986 227.411Z"
                            fill="#3F3D56"
                        />
                        <path
                            d="M228.135 291.451C354.13 291.451 456.269 286.288 456.269 279.919C456.269 273.55 354.13 268.387 228.135 268.387C102.139 268.387 0 273.55 0 279.919C0 286.288 102.139 291.451 228.135 291.451Z"
                            fill="#3F3D56"
                        />
                        <path
                            d="M367.112 300.947C388.692 300.947 406.186 300.063 406.186 298.972C406.186 297.881 388.692 296.997 367.112 296.997C345.532 296.997 328.038 297.881 328.038 298.972C328.038 300.063 345.532 300.947 367.112 300.947Z"
                            fill="#3F3D56"
                        />
                        <path
                            d="M225.719 320C247.299 320 264.793 319.116 264.793 318.025C264.793 316.934 247.299 316.05 225.719 316.05C204.139 316.05 186.645 316.934 186.645 318.025C186.645 319.116 204.139 320 225.719 320Z"
                            fill="#3F3D56"
                        />
                        <path
                            d="M118.448 306.562C167.75 306.562 207.718 304.542 207.718 302.05C207.718 299.557 167.75 297.537 118.448 297.537C69.1451 297.537 29.1775 299.557 29.1775 302.05C29.1775 304.542 69.1451 306.562 118.448 306.562Z"
                            fill="#3F3D56"
                        />
                        <path
                            d="M39.9361 265.435C57.8837 265.435 72.4331 250.886 72.4331 232.938C72.4331 214.99 57.8837 200.441 39.9361 200.441C21.9884 200.441 7.43896 214.99 7.43896 232.938C7.43896 250.886 21.9884 265.435 39.9361 265.435Z"
                            fill="#2F2E41"
                        />
                        <path
                            d="M34.6995 258.382L24.8276 258.266L24.6192 275.953L34.4911 276.069L34.6995 258.382Z"
                            fill="#2F2E41"
                        />
                        <path
                            d="M54.4431 258.615L44.5713 258.499L44.3629 276.186L54.2347 276.302L54.4431 258.615Z"
                            fill="#2F2E41"
                        />
                        <path
                            d="M41.07 276.353C41.0901 274.649 37.4232 273.224 32.8798 273.171C28.3364 273.117 24.637 274.455 24.6169 276.159C24.5968 277.862 28.2637 279.287 32.8071 279.341C37.3505 279.394 41.0499 278.056 41.07 276.353Z"
                            fill="#2F2E41"
                        />
                        <path
                            d="M60.8185 276.174C60.8386 274.47 57.1717 273.046 52.6283 272.992C48.0849 272.938 44.3855 274.276 44.3655 275.98C44.3454 277.684 48.0122 279.108 52.5556 279.162C57.099 279.215 60.7985 277.878 60.8185 276.174Z"
                            fill="#2F2E41"
                        />
                        <path
                            d="M40.8556 235.828C46.9896 235.828 51.9622 230.855 51.9622 224.721C51.9622 218.587 46.9896 213.615 40.8556 213.615C34.7216 213.615 29.749 218.587 29.749 224.721C29.749 230.855 34.7216 235.828 40.8556 235.828Z"
                            fill="white"
                        />
                        <path
                            d="M40.8555 228.423C42.9002 228.423 44.5577 226.766 44.5577 224.721C44.5577 222.677 42.9002 221.019 40.8555 221.019C38.8108 221.019 37.1533 222.677 37.1533 224.721C37.1533 226.766 38.8108 228.423 40.8555 228.423Z"
                            fill="#3F3D56"
                        />
                        <path
                            d="M8.77091 202.348C6.28556 190.566 14.8127 178.791 27.8169 176.048C40.821 173.305 53.3777 180.632 55.8631 192.413C58.3485 204.195 49.7017 208.427 36.6975 211.171C23.6934 213.914 11.2563 214.129 8.77091 202.348Z"
                            fill="#6C63FF"
                        />
                        <path
                            d="M89.5077 251.068C89.5077 273.723 76.0399 281.634 59.4252 281.634C59.0403 281.634 58.656 281.63 58.2724 281.621C57.5026 281.604 56.7401 281.568 55.9868 281.517C40.9923 280.455 29.3428 272.138 29.3428 251.068C29.3428 229.262 57.2075 201.748 59.3006 199.711L59.3042 199.708C59.3849 199.629 59.4252 199.59 59.4252 199.59C59.4252 199.59 89.5077 228.412 89.5077 251.068Z"
                            fill="#6C63FF"
                        />
                        <path
                            d="M58.3293 278.168L69.3318 262.794L58.3018 279.856L58.2725 281.621C57.5027 281.604 56.7402 281.568 55.9869 281.517L57.1728 258.85L57.1636 258.674L57.1838 258.641L57.2956 256.5L46.238 239.396L57.3304 254.894L57.3561 255.349L58.2523 238.223L48.7857 220.549L58.3678 235.217L59.3007 199.711L59.3044 199.59V199.708L59.1486 227.706L68.5731 216.606L59.1101 230.118L58.8608 245.452L67.6603 230.736L58.8242 247.708L58.6849 256.232L71.4598 235.75L58.6372 259.207L58.3293 278.168Z"
                            fill="#3F3D56"
                        />
                        <path
                            d="M293.085 265.084C311.032 265.084 325.582 250.534 325.582 232.586C325.582 214.639 311.032 200.089 293.085 200.089C275.137 200.089 260.588 214.639 260.588 232.586C260.588 250.534 275.137 265.084 293.085 265.084Z"
                            fill="#2F2E41"
                        />
                        <path
                            d="M295.858 258.416L286.422 261.316L291.618 278.224L301.055 275.324L295.858 258.416Z"
                            fill="#2F2E41"
                        />
                        <path
                            d="M314.732 252.617L305.295 255.518L310.491 272.426L319.928 269.525L314.732 252.617Z"
                            fill="#2F2E41"
                        />
                        <path
                            d="M300.448 278.952C304.792 277.618 307.907 275.215 307.406 273.587C306.906 271.958 302.979 271.72 298.636 273.054C294.293 274.389 291.177 276.792 291.678 278.42C292.179 280.049 296.105 280.287 300.448 278.952Z"
                            fill="#2F2E41"
                        />
                        <path
                            d="M319.202 272.759C323.545 271.424 326.66 269.021 326.159 267.393C325.659 265.764 321.732 265.526 317.389 266.86C313.046 268.195 309.931 270.598 310.431 272.226C310.932 273.855 314.858 274.093 319.202 272.759Z"
                            fill="#2F2E41"
                        />
                        <path
                            d="M291.454 235.588C297.588 235.588 302.561 230.615 302.561 224.481C302.561 218.347 297.588 213.375 291.454 213.375C285.32 213.375 280.348 218.347 280.348 224.481C280.348 230.615 285.32 235.588 291.454 235.588Z"
                            fill="white"
                        />
                        <path
                            d="M291.454 228.183C293.499 228.183 295.157 226.526 295.157 224.481C295.157 222.436 293.499 220.779 291.454 220.779C289.41 220.779 287.752 222.436 287.752 224.481C287.752 226.526 289.41 228.183 291.454 228.183Z"
                            fill="#3F3D56"
                        />
                        <path
                            d="M254.074 212.959C248.114 202.497 252.643 188.683 264.191 182.104C275.739 175.525 289.932 178.673 295.893 189.135C301.853 199.597 294.909 206.265 283.361 212.844C271.814 219.423 260.035 223.422 254.074 212.959Z"
                            fill="#6C63FF"
                        />
                        <path
                            d="M235.128 246.919C235.128 267.716 222.765 274.977 207.513 274.977C207.16 274.977 206.807 274.973 206.455 274.965C205.748 274.95 205.048 274.917 204.357 274.87C190.593 273.895 179.899 266.26 179.899 246.919C179.899 226.902 205.477 201.645 207.399 199.776L207.402 199.773C207.476 199.7 207.513 199.665 207.513 199.665C207.513 199.665 235.128 226.122 235.128 246.919Z"
                            fill="#6C63FF"
                        />
                        <path
                            d="M206.507 271.796L216.607 257.683L206.482 273.345L206.455 274.965C205.748 274.95 205.048 274.917 204.357 274.87L205.446 254.063L205.437 253.901L205.456 253.871L205.558 251.906L195.408 236.205L205.59 250.432L205.614 250.849L206.437 235.128L197.747 218.904L206.543 232.369L207.399 199.776L207.402 199.665V199.773L207.259 225.474L215.91 215.285L207.224 227.688L206.995 241.764L215.073 228.255L206.961 243.835L206.834 251.66L218.56 232.858L206.79 254.391L206.507 271.796Z"
                            fill="#3F3D56"
                        />
                        <path
                            d="M343.726 255.592C343.726 278.248 330.258 286.159 313.643 286.159C313.258 286.159 312.874 286.154 312.49 286.146C311.72 286.129 310.958 286.093 310.205 286.041C295.21 284.98 283.561 276.663 283.561 255.592C283.561 233.787 311.425 206.272 313.518 204.236L313.522 204.233C313.603 204.154 313.643 204.115 313.643 204.115C313.643 204.115 343.726 232.937 343.726 255.592Z"
                            fill="#6C63FF"
                        />
                        <path
                            d="M312.547 282.693L323.55 267.319L312.52 284.381L312.49 286.146C311.72 286.129 310.958 286.093 310.205 286.041L311.391 263.375L311.381 263.199L311.402 263.166L311.513 261.025L300.456 243.921L311.548 259.419L311.574 259.874L312.47 242.748L303.004 225.074L312.586 239.742L313.518 204.236L313.522 204.115V204.233L313.366 232.231L322.791 221.131L313.328 234.643L313.079 249.977L321.878 235.261L313.042 252.233L312.903 260.757L325.678 240.275L312.855 263.732L312.547 282.693Z"
                            fill="#3F3D56"
                        />
                        <path
                            d="M295.149 252.04C296.356 249.495 290.762 244.314 282.654 240.468C274.545 236.623 266.994 235.569 265.787 238.114C264.579 240.66 270.174 245.841 278.282 249.686C286.39 253.532 293.941 254.586 295.149 252.04Z"
                            fill="#2F2E41"
                        />
                        <path
                            d="M45.0342 249.066C46.0823 246.451 40.1795 241.625 31.8498 238.286C23.5202 234.947 15.918 234.361 14.8699 236.976C13.8218 239.591 19.7247 244.417 28.0543 247.756C36.384 251.094 43.9861 251.681 45.0342 249.066Z"
                            fill="#2F2E41"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="460.962" height={320} fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                {title}
                {subtitle}
            </div>
        </section>
    );
};