import React, { useEffect } from 'react';
import './styles.scss';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { WorkflowTreeview } from '../workflow-page/treeview-LHS/workflows';
import { DetailedWorkflowInfo, WorkflowHandler } from '../../api/workflow-handler';
import { useDebounce } from 'rooks';
import {  switchToExistingCanvasTab, openANewWorkflowAnalyticsTab } from '../../store/canvas';
import { Canvas } from './Canvas';
import { onWorkflowClick } from '../../store/analytics';
import { RetrieveWorkflowsPayload } from '../../store/workflow';
import { SwitchExecutionEnv } from '../../components/execution-env';
import { ShowWhenTrue } from '../../helpers';
import { showExecutionEnvToggle } from '../../constants/settings';
import { ExecutionEnvModes } from '../../constants/enums';
import { useAppSelector } from '../../store/hooks';
import { updateExecutionEnv } from '../../store/common';
import { AnalyticsProvider } from './useAnalyticsContext';
import { WorkflowConfig } from '@services/WorkflowConfig';

const WorkflowAnalytics: React.FC = () => {
    const isWorkflowTreeviewMinimized = useAppSelector((store) => store.AnalyticsReducer.isWorkflowTreeviewMinimized);
    const { activeTab, openTabs } = useAppSelector((store) => store.CanvasReducer.analytics);
    const activeExecutionEnv = useAppSelector(store => store.CommonReducer.activeExecutionEnv)
    const dispatch = useDispatch();


    const handleGetWorkflowSuccess = (pageNo: number, workflow: DetailedWorkflowInfo) => {
        const { details, projectName, id, version, createdBy, isStarred, notes, readOnly, executable, hasVariables, componentCounter, zeppelinNotebookId, position, directoryId, env, zepplinData, created, updated, config } = workflow.data;
        let workflowConfig: WorkflowConfig = [];

        try {
            workflowConfig = JSON.parse(config)
        } catch{
            /* eslint-disable */
        }

        dispatch(openANewWorkflowAnalyticsTab({ name: projectName, details, id, version, saved: true, createdBy, isStarred, notes, componentIdsOfPlots: [], showPlotInProgressSpinner: false, 
            graphsInfo: { layout: [], refs: {}, data: {},  fullScreenInfo: null}, dfList: [], previewStatementId: null, activeComponentIdForAnalytics: null, sessionId: null, isStreaming: false, 
            workflowPositionInList: position, isReadOnly: readOnly, isExecutable: executable, hasVariables, componentCounter, zeppelinNotebookId: zeppelinNotebookId || null, directoryId, env, zepplinData, isPreviewSessionCreationInProgress: false, sessionState: null, created, updated, config: workflowConfig }));
    };

    function getWorkflow(this: any, id: number, page: number) {
        WorkflowHandler.GetWorkflowInfo(id, handleGetWorkflowSuccess.bind(this, page));
    }


    const handleClickWorkflow = useDebounce((workflow: RetrieveWorkflowsPayload['results'][0]) => {
        dispatch(onWorkflowClick());
        if(activeTab.id !== workflow.id) {
            const isWorkflowAlreadyOpen = openTabs.get(workflow.id);
            if(isWorkflowAlreadyOpen) {
                dispatch(switchToExistingCanvasTab('analytics', workflow.id));
            }
            else {
                getWorkflow(workflow.id, workflow.page);
            }
        }
    }, 250);

    useEffect(() => {
        // Streaming and Pipelines are not allowed on visualizations page
        if(activeExecutionEnv === ExecutionEnvModes.Streaming || activeExecutionEnv === ExecutionEnvModes.Pipelines) {
            dispatch(updateExecutionEnv(ExecutionEnvModes.Spark));
        }
    }, [activeExecutionEnv])

   

    return(
        <div className="workflowAnalytics__container">
            <div className={classNames('treeview__container', {'treeview--minimized': isWorkflowTreeviewMinimized, showExecutionEnvToggle})}>
                <p className="LHS__title">Visualizations</p>
                <p className="LHS__subtitle">Select a workflow to get started</p>
                <ShowWhenTrue show={showExecutionEnvToggle}>
                    <SwitchExecutionEnv 
                        page="analytics"
                        disabledTypes={[ExecutionEnvModes.Pipelines, ExecutionEnvModes.Streaming]}
                    />
                </ShowWhenTrue>
                <div className="WorkflowLHS__selection__box">
                    <div className="WorkflowLHS__selectionOptions__outer">
                        <ul className='WorkflowLHS__selectionOptions'>
                            <li
                                className="switchable__tab switchable__tab--active"
                            >
                                WORKFLOWS
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="treeview__master">
                    <div className='treeview__inner'>
                        <WorkflowTreeview 
                            hideWorkflowTreeview={false}
                            handleClickWorkflow={handleClickWorkflow}
                            activeTabId={activeTab.id}
                            openTabs={openTabs}
                            readOnlyMode
                        />
                    </div>
                </div>
            </div>
            <AnalyticsProvider>
                <Canvas />
            </AnalyticsProvider>
        </div>

    );
};

export default WorkflowAnalytics;