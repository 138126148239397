import { BaseFieldType } from "@components/formcreators/types";
import { RepeatV3ValueType } from ".";

const splitDataFieldValue = (value: any, delimiter: string) => {
	if (typeof value === "string" && typeof delimiter === "string") {
		return value.split(delimiter);
	}
	return [];
};

const getRepeatFieldV3Key = (
	actualFieldKey: string,
	repeatFieldKey: string,
	index: number
) => `${actualFieldKey}.repeatData[${index}].${repeatFieldKey}`;

const getRepeatV3FieldsPayload = (field: BaseFieldType) => {
	const payload: {
		key: string;
		value: string;
		arg_index: number;
		variable_type: "string";
		useKey: boolean;
	}[] = [];
	const fieldValue = field.value as RepeatV3ValueType;
	const outputFormat = field.repeat_options?.outputFormat;
	if (outputFormat && fieldValue?.repeatData) {
		Object.entries(outputFormat).forEach(([key, outputFormatInfo]) => {
			// retrieve key in every iteration
			const valueInArr =
				fieldValue.repeatData.map((data) => data[key]) || [];
			// join the all values using the delimiter
			const value = valueInArr.join(outputFormatInfo.delimiter);
			if (outputFormatInfo.arg_index) {
				payload.push({
					key,
					value,
					arg_index: outputFormatInfo.arg_index,
					variable_type: "string",
					useKey: !!outputFormatInfo.useKey,
				});
			}
		});
	}
	return payload;
};

export { splitDataFieldValue, getRepeatFieldV3Key, getRepeatV3FieldsPayload };
