import { isEmpty, omit, range, reverse } from 'lodash';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'rooks';
import { Menu, Dropdown as DropdownAntd , Icon, Button, Modal } from "antd";
import { DetailedWorkflowInfo, DownloadDagWorkflowResponse, OpenDatabricksNotebookPayload, WorkflowHandler } from '../../../../api/workflow-handler';
import Form, { SelectField } from '../../../../components/form';
import { Dropdown } from '../../../../components/form/dropdown';
import { _selectoptionType } from '../../../../components/form/select-field';
import { errorAlert, infoAlert } from '../../../../components/toastify/notify-toast';
import { TooltipTop } from '../../../../components/tooltips';
import { WorkflowCanvas } from '../../../../components/workflow-canvas';
import { Env } from '../../../../constants/settings';
import { ShowWhenTrue } from '../../../../helpers';
import { openANewWorkflowEditorTabUsingWorkflowInfo, WorkflowCanvasTabInfo } from '../../../../store/canvas';
import { cloneWorkflow, retrieveWorkflows, runOrPreviewWorkflowOnDatabricks, setActiveSelectionType, setModalInfoTypeInWorkflowsPage, setSingleItemInfoForModals, showProperties, switchCodeEditorOfActiveTab, toggleModal, toggleWorkflowCanvasSpinner } from '../../../../store/workflow';
import { saveTextToFile } from '../../../../utils';
import { SixDotsIcon } from '../../assets/icons';
import { RunPreviewWorkflowModalProps } from '../../modals/run-preview-workflow';
import { checkIfAllLinksAreConnected, getDagWorkflowInfo, getWorkflowInfo } from '../../utils';
import moment from 'moment';
import { RootState } from '../../../../store/types';
import Draggable from 'react-draggable';
import { ExecutionEnvModes } from '../../../../constants/enums';
import { AddVariable, Preview, RunBtn, Save, SaveBtn, ScheduleIcon } from '../../../../assets/icons';
import { ClusterState } from '../../../../api/databricks-handler';
import { useCallback } from 'react';
import { uuid  } from 'uuidv4';
import { RestartSVGIcon } from '@pages/cluster_redisign/icons';
import workflowActionHandler from '@services/WorkflowActionHandler';
import '../code-editor.scss';
interface Props {
    handleSaveWorkflow: (saveAsVersion?: boolean) => void;
    activeWorkflowInfo: WorkflowCanvasTabInfo | undefined;
    setActionType: React.Dispatch<React.SetStateAction<RunPreviewWorkflowModalProps['actionType']>>;
    handleShowVarPlaceHolder: () => void;
    getRecentJobs: () => void;
}

const disabledMessage = 'You don\'t have permission to perform this action';
const { SubMenu } = Menu;

export const CanvasFloatingToolbar: React.FC<Props> = ({
    handleSaveWorkflow,
    activeWorkflowInfo,
    setActionType,
    handleShowVarPlaceHolder, 
}) => {
    const dispatch = useDispatch();
    const { activeExecutionEnv } = useSelector((store: RootState) => store.CommonReducer);
    const { activeTab, openTabs } = useSelector((store: RootState) => store.CanvasReducer.workflowEditor);
    const { clusters, defaultClusterInfo } = useSelector((store: RootState) => store.ClusterReducer);
    const selectedDirectory = useSelector((store: RootState) => store.WorkflowReducer.workflowTabSelection.selectedDirectory);
    const workSpaceData = useSelector((store: RootState) => store.ClusterReducer.workspaceList);
    const enabledWorkspace = workSpaceData?.filter((item)=>item.isEnabled)?.[0];

    const handleCloneSuccess = () => {
        dispatch(retrieveWorkflows({ directory_id: selectedDirectory?.id }));
    };


    const cloneActiveWorkflowFromToolbar = () => {
        if (activeWorkflowInfo) {
            infoAlert('Cloning ' + activeWorkflowInfo.name);
            const workflowConfig = JSON.stringify(activeWorkflowInfo.config || []);
            const serializedActiveWorkflowData = WorkflowCanvas.serializeModel({ env: activeExecutionEnv, config: workflowConfig, workflowDependenciesRef: {}, workflowsInfo: {}, workflowName: activeWorkflowInfo.name });
            dispatch(cloneWorkflow({id: activeWorkflowInfo.id, name: activeWorkflowInfo.name, config: workflowConfig },serializedActiveWorkflowData, handleCloneSuccess));
        }
    };


    const handleDownloadWorkflowSuccess = (response: any) => {
        const timeStamp = moment(new Date()).format('YYYY_MM_DD_HH_mm_ss_SSS');
        activeWorkflowInfo && saveTextToFile(`${activeWorkflowInfo.name}_${timeStamp}.py`, response.script);
    };


    const onDagDownload = (response: DownloadDagWorkflowResponse) => {
        Object.entries(response).forEach(([fileName, fileContext]) => {
            saveTextToFile(`${fileName}.py`, fileContext)
        })
    }


    const downloadWorkflow = () => {
        infoAlert('Downloading ' + activeWorkflowInfo?.name);
        let workflowData:any = getWorkflowInfo('workflowEditor', dispatch);
        workflowData = {...workflowData,workspaceType:enabledWorkspace?.workspaceType};
        if(workflowData) {
            if (activeExecutionEnv !== ExecutionEnvModes.Pipelines) {
                WorkflowHandler.DownloadWorkflow(activeExecutionEnv, workflowData, handleDownloadWorkflowSuccess);
            } else {
                const data = getDagWorkflowInfo(dispatch, false);
                if(data) {
                    data.nodes = workflowData.nodes;
                    data.links = workflowData.links;
                    data.workflow_config = workflowData.workflow_config;
                    WorkflowHandler.DownloadDagWorkflow(data, onDagDownload);
                }
            }
        }
    };


    const setActiveWorkflowInfoForModals = () => {
        dispatch(setActiveSelectionType('single'));
        const selectedWorkflowInfo = omit(activeWorkflowInfo, 'details');
        dispatch(setSingleItemInfoForModals(selectedWorkflowInfo));
    };

    const runOrPreviewWorkflow = (type: RunPreviewWorkflowModalProps['actionType'], clusterId: string | null) => {     
        const allPropertiesValidArr  = Object.values(WorkflowCanvas.getAllNodes()).filter((item)=>item.getOptions().isPropertiesValid === true);
        const isAllPropertiesValid = allPropertiesValidArr.length !== Object.values(WorkflowCanvas.getAllNodes()).length;
        const isRouteOptimisationComponent = openTabs.get(activeTab.id)?.info?.details?.toString()?.includes("Route Optimization");

        if(isAllPropertiesValid && !isRouteOptimisationComponent){    
            Modal.confirm({
                title: "Validation failed",
                content: "Do you want to proceed ?",
                onOk:()=>{
                    if(isEmpty(WorkflowCanvas.getAllNodes())) {
                        errorAlert('Please add components to start executing the workflow');
                    }else if (checkIfAllLinksAreConnected()) {
                        setActiveWorkflowInfoForModals();
                        setActionType(type);
                        if(activeExecutionEnv === ExecutionEnvModes.Streaming && type === 'preview') {
                            dispatch(toggleModal('streamingPreviewWorkflow', true))
                        }
                        else if(Env.databricks) {
                            if(!clusterId) {
                                errorAlert('Please set a default cluster');      
                            } else {
                                dispatch(runOrPreviewWorkflowOnDatabricks('workflowEditor' ,type as any, {  activeTabId: activeTab.id, clusterId }));
                                const activeTabInfo = openTabs.get(activeTab.id)?.info;
                                if(handleSaveWorkflow && !activeTabInfo?.saved) handleSaveWorkflow();
                            }
                        } else {
                            dispatch(toggleModal('runWorkflow', true));
                        }
                    }
                },
                okText: 'Yes',
                cancelText: 'Cancel',
                className:'confirmModal'
              });
            return;
        }else{
            if(isEmpty(WorkflowCanvas.getAllNodes())) {
                errorAlert('Please add components to start executing the workflow');
            }else if (checkIfAllLinksAreConnected()) {
                setActiveWorkflowInfoForModals();
                setActionType(type);
                if(activeExecutionEnv === ExecutionEnvModes.Streaming && type === 'preview') {
                    dispatch(toggleModal('streamingPreviewWorkflow', true))
                }
                else if(Env.databricks) {
                    if(!clusterId) {
                        errorAlert('Please set a default cluster');      
                    } else {
                        dispatch(runOrPreviewWorkflowOnDatabricks('workflowEditor' ,type as any, {  activeTabId: activeTab.id, clusterId }));
                        const activeTabInfo = openTabs.get(activeTab.id)?.info;
                        if(handleSaveWorkflow && !activeTabInfo?.saved) handleSaveWorkflow();
                    }
                } else {
                    dispatch(toggleModal('runWorkflow', true));
                }
            }
        }
    };

    const saveWorkflowBtnClick = useDebounce(() => {
        handleSaveWorkflow();
    }, 300);

    const handleRetrieveWorkflowUsingVersionSuccess = (workflow: DetailedWorkflowInfo['data']) => {
        if (activeWorkflowInfo) {
            workflow.projectName = activeWorkflowInfo.name;
            workflow.version = activeWorkflowInfo.version;
            dispatch(openANewWorkflowEditorTabUsingWorkflowInfo(workflow));
            dispatch(toggleWorkflowCanvasSpinner(false));
        }
    };


    const retrieveWorkflowUsingVersion = (option: _selectoptionType) => {
        if (activeWorkflowInfo) {
            dispatch(toggleWorkflowCanvasSpinner(true));
            WorkflowHandler.GetWorkflowInfoUsingVersion(activeWorkflowInfo.id, option.value as number, handleRetrieveWorkflowUsingVersionSuccess);
        }
    };

    const deleteWorkflow = () => {
        setActiveWorkflowInfoForModals();
        dispatch(toggleModal('deleteWorkflow', true));
        dispatch(setModalInfoTypeInWorkflowsPage('workflow'));
    };

    const openSagemaker = () => {
        const workflowData = getWorkflowInfo('workflowEditor', dispatch);
        if (activeWorkflowInfo && workflowData) {
            WorkflowHandler.SageMaker(activeWorkflowInfo.name, workflowData, (data: any) => {
                window.open(data.data);
            });
        }
    };
    const openNotebook = () => {
        const workflowData = getWorkflowInfo('workflowEditor', dispatch);
        if (activeWorkflowInfo && workflowData) {
            const data: OpenDatabricksNotebookPayload = {
                name: activeWorkflowInfo.name,
                sparkVersion: '2.x',
                env: activeExecutionEnv,
                payload: workflowData,
                type: 'run',
                workflowId: activeWorkflowInfo.id
            };
            WorkflowHandler.OpenDatabricksNotebook(data, (data: any) => {
                window.open(data.data);
            });
        }
    };

    const [isCurrentWorkflowReadOnly, isCurrentWorkflowNotExecutable] = useMemo(() => {
        if(activeWorkflowInfo) {
            return [activeWorkflowInfo.isReadOnly, !activeWorkflowInfo.isExecutable];
        }
        return [false, false];
    }, [activeWorkflowInfo]);

    const versionsInfo = useMemo(() => {
        return reverse(range(1, (activeWorkflowInfo?.version || 0) + 1).map(verNum => ({ 'label': `Ver ${verNum}`, value: verNum, key:uuid() })));
    },[activeWorkflowInfo?.id, activeWorkflowInfo?.version]);

    const isDagMode = activeExecutionEnv === ExecutionEnvModes['Pipelines'];

    const showScheduleDagOptions = () => {
        WorkflowCanvas.clearSelection();
        dispatch(showProperties());
    };

    const openCodeEditor = (clusterId: string, clusterName: string)=>{
        const activeTabInfo = openTabs.get(activeTab.id)?.info;
        if(handleSaveWorkflow && !activeTabInfo?.saved) handleSaveWorkflow();
        dispatch(switchCodeEditorOfActiveTab(clusterId, clusterName));
    };

    const clusterOptions = useCallback((type: 'run' | 'preview' | 'codeEditor') => {
        const options: any = {};
        const action = (clusterId: string, clusterName: string) => 
            type === 'codeEditor' ? openCodeEditor(clusterId, clusterName) :
                    runOrPreviewWorkflow(type, clusterId);
        options['CHOOSE A CLUSTER'] = {action: () => {return;}, disabled: true};
        if(defaultClusterInfo && type !== 'codeEditor' && defaultClusterInfo.state === ClusterState['RUNNING']) {
            options[`${defaultClusterInfo.clusterName} (Default)`] = { action: () => action(defaultClusterInfo.clusterId, defaultClusterInfo.clusterName) }; 
        }

        clusters.forEach(cluster => {
            if(!cluster.defaultCluster) {
                if(type === 'codeEditor' && cluster.npipWorkSpace === false && cluster.state === ClusterState['RUNNING']) {
                    options[cluster.clusterName] = { action: () => action(cluster.clusterId, cluster.clusterName) }; 
                }else if(type !== 'codeEditor'  &&((cluster.npipWorkSpace && cluster.workspaceType === 'databricks')||(!cluster.npipWorkSpace && cluster.workspaceType === 'cloudera')) && (cluster.state === ClusterState['RUNNING'] || cluster.state === ClusterState['RESIZING'])) {
                    options[cluster.clusterName] = { action: () => action(cluster.clusterId, cluster.clusterName) }; 
                }
            }
        });
        return options;
    // activeTab, openTabs are used in runOrPreviewWorkflow function
    }, [clusters, defaultClusterInfo, activeTab, openTabs]);

    const isZeppelinDisabledInStreamingExp = activeExecutionEnv === ExecutionEnvModes.Streaming && !Env.enableZeppelinInStreamingExp

    const refreshAllComponents = useCallback(() => {
        infoAlert("Refreshing components")
        dispatch(workflowActionHandler({ 
            type: "REFRESH_COMPONENTS_IN_ACTIVE_WORKFLOW"
        }))
    }, [activeTab])
      
    const clusterObj = clusterOptions('codeEditor');
    const clusterOptionsKeys = Object.keys(clusterObj);
    const noClustersAvailableForZeppelin = clusterOptionsKeys.length === 1

    const editorMenu = (
        <Menu className="editor-dropdown-menu">
            {Env.enableDatabricksNoteBook && !isDagMode &&
                <Menu.Item  key="notebook"  onClick={openNotebook} disabled={isCurrentWorkflowReadOnly}>
                    <TooltipTop title={isCurrentWorkflowNotExecutable ? disabledMessage: null}>
                        <div className='menu_item'>Open In Notebook</div>
                    </TooltipTop>
                </Menu.Item>
            }
            {/* {Env.zeppelin && !isDagMode &&
                <SubMenu 
                    disabled={isCurrentWorkflowReadOnly || isZeppelinDisabledInStreamingExp || noClustersAvailableForZeppelin} 
                    key="zeppelin" 
                    title={<TooltipTop title={isZeppelinDisabledInStreamingExp ? "Currently not supported for Streaming Experience": noClustersAvailableForZeppelin ? "No Clusters Available": null}>
                        <div className='menu_item zeppelin-sub-menu'>
                            Zeppelin <LeftArrowIcon />
                        </div>
                    </TooltipTop>
                    }
                    popupClassName="zeppelin-sub-menu-popup"
                    popupOffset={[-4, -18]}
                >
                    {clusterOptionsKeys.map((item,i)=> 
                        <Menu.Item className={i>0?'menu_item':'placeholder_menu_item'} key={item} disabled={clusterObj[item].disabled} onClick={clusterObj[item].action}>{item}</Menu.Item>
                    )}
                </SubMenu>
            } */}
        </Menu>
    );

    return (
        <Draggable
            axis="both"
            handle=".floating-toolbar-handle"
            bounds=".workflowCanvas__outer"
            defaultPosition={{x: 0, y: 0}}
            grid={[25, 25]}
            scale={1}
        >
            <div className="floatingToolbar__container" style={{
                width: Env.zeppelin && !isDagMode ? '614px': isDagMode ? '440px': '500px' 
            }} >

                <span className=" floating-toolbar-handle sixdots__span" ><SixDotsIcon /></span>
                <Form
                    initialValues={{ version: activeWorkflowInfo ?.version || 1 }}
                    enableReinitialize
                    onSubmit={() => { return; }}
                >
                    <SelectField
                        name="version"
                        options={versionsInfo}
                        onOptionClick={retrieveWorkflowUsingVersion}
                    />

                </Form>
                <div className="vertical__separator" />

                {/* <TooltipTop
                    title={'Clone current workflow state'}
                >
                    <button
                        onClick={cloneActiveWorkflowFromToolbar}
                        id='CloneCanvas_btn'
                    >
                        <img
                            src="/icons/workflow/duplicate.svg"
                            className="icon__hover"
                            alt=""
                        />
                    </button>
                </TooltipTop> */}
                {/* <TooltipTop
                    title={isCurrentWorkflowReadOnly ? disabledMessage: 'Delete'}
                >
                    <button
                        onClick={deleteWorkflow}
                        aria-label="tool-bar-delete-workflow"
                        id={'DeleteCanvas_btn'}
                        disabled={isCurrentWorkflowReadOnly}
                    >
                        <img
                            src="/icons/workflow/delete.svg"
                            className="icon__hover"
                            alt=""

                        />
                    </button>
                </TooltipTop> */}
                {/* <div className="vertical__separator" /> */}
                <TooltipTop title={isCurrentWorkflowNotExecutable ? disabledMessage:"Refresh all the components"}>
                    <button
                        onClick={refreshAllComponents}
                        disabled={isCurrentWorkflowNotExecutable}
                        id="refreshComponents_btn"
                    >
                        <RestartSVGIcon />
                    </button>
                </TooltipTop>
                <TooltipTop
                    title={isCurrentWorkflowNotExecutable ? disabledMessage: 'Download'}
                >
                    <button
                        onClick={downloadWorkflow}
                        id={'DownloadCanvas_btn'}
                        disabled={isCurrentWorkflowNotExecutable}           
                    >
                        <div className='centerh'><Save /></div>
                    </button>
                </TooltipTop>
                {/* {
                    Env.zeppelin && !isDagMode ? (
                        
                        Env.databricks ?
                            <Dropdown
                                id='workflow_code_editor_dropdown'
                                title={
                                    <TooltipTop
                                        title={isZeppelinDisabledInStreamingExp ? "Currently not supported for Streaming Experience": 'Switch to code editor'}
                                    >
                                        <img
                                            src="/icons/workflow/code-slash.svg"
                                            className="icon__hover"
                                            alt=""
                                            style={{
                                                height: 20,
                                                opacity: isZeppelinDisabledInStreamingExp ? 0.56: 1
                                            }}
                                        />
                                    </TooltipTop>
                                }
                                position="right"
                                dropdownOptions={clusterOptions('codeEditor')}
                                className="runWorkflow__options"
                                disabled={isCurrentWorkflowReadOnly || isZeppelinDisabledInStreamingExp}
                            />
                            :
                            <TooltipTop
                                title={'Switch to code editor'}
                            >
                                <img
                                    src="/icons/workflow/code-slash.svg"
                                    className="icon__hover"
                                    alt=""
                                    style={{
                                        height: 20
                                    }}
                                />
                            </TooltipTop>
                    ):null
                } */}
                <ShowWhenTrue show={!isDagMode}>
                    {/* Show configuration options modal when user clicks preview in streaming mode */}
                    {Env.databricks && activeExecutionEnv !== ExecutionEnvModes.Streaming ? 
                        <Dropdown
                            id='workflow_preview_dropdown'
                            title={
                                <TooltipTop
                                    title={isCurrentWorkflowNotExecutable ? disabledMessage: 'Preview'}
                                >
                                    <div className='centerh'><Preview/></div>
                                </TooltipTop>
                            }
                            position="right"
                            dropdownOptions={clusterOptions('preview')}
                            className="runWorkflow__options"
                            disabled={isCurrentWorkflowReadOnly}
                        />
                        :
                        <TooltipTop
                            title={isCurrentWorkflowNotExecutable ? disabledMessage: 'Preview'}
                        >
                            <button
                                id={'CanvasPreview_btn'}
                                onClick={() => runOrPreviewWorkflow('preview', null)}
                                disabled={isCurrentWorkflowNotExecutable}
                                aria-label="tool-bar-preview-workflow"
                            >
                                <div className='centerh'><Preview/></div>
                            </button>
                        </TooltipTop>   

                    }
                </ShowWhenTrue>
                {/* <TooltipTop
                    title={isCurrentWorkflowNotExecutable ? disabledMessage: 'Schedule'}
                > */}
                {!!isDagMode &&
                        <TooltipTop
                            title={isCurrentWorkflowNotExecutable ? disabledMessage: 'Schedule'}
                        >
                            <button
                                className="btn-sm btn-yellow dag__scheduleBtn"
                                onClick={showScheduleDagOptions}
                                aria-label="tool-bar-run-workflow"
                                disabled={isCurrentWorkflowNotExecutable}
                            >
                                <ScheduleIcon />
                                    Schedule
                            </button>
                        </TooltipTop>
                //         :
                //         <button
                //             id='ScheduleCanvas_btn'
                //             onClick={() => {
                //                 setActiveWorkflowInfoForModals();
                //                 dispatch(toggleModal('scheduleWorkflow', true));
                //             }}
                //             disabled={isCurrentWorkflowNotExecutable}
                //         >
                //             <img
                //                 src="/icons/workflow/schedule.svg"
                //                 className="icon__hover"
                //                 alt=""
                //             />
                //         </button>
                }
                {/* </TooltipTop> */}
                <ShowWhenTrue show={!isDagMode}>
                    <TooltipTop
                        title={isCurrentWorkflowReadOnly ? disabledMessage: 'Add Variable'}
                    >
                        <button
                            id='addVariable_btn'
                            onClick={handleShowVarPlaceHolder}
                            disabled={isCurrentWorkflowReadOnly}
                        >
                            <div  className='centerh'><AddVariable/></div>
                        </button>
                    </TooltipTop>
                    <ShowWhenTrue show={Env.sagemaker}>
                        <TooltipTop
                            title={isCurrentWorkflowNotExecutable ? disabledMessage: 'Open in Sagemaker'}
                        >
                            <button
                                id={'sagemakerbtn'}
                                onClick={() => {
                                    openSagemaker();
                                }}
                                disabled={isCurrentWorkflowNotExecutable}
                            >
                                <img
                                    src="/icons/aws/sagemaker.svg"
                                    className="icon__hover"
                                    alt=""
                                />
                            </button>
                        </TooltipTop>
                    </ShowWhenTrue>
                </ShowWhenTrue>
                <ShowWhenTrue show={(Env.enableDatabricksNoteBook && !isDagMode)||(Env.zeppelin && !isDagMode)}>
                    <div className="vertical__separator" />
                    <DropdownAntd overlay={editorMenu} placement="bottomRight" trigger={["hover"]}>
                        <Button className='btn-editor'>
                            Editor
                        </Button>
                    </DropdownAntd>
                </ShowWhenTrue>
                {/* <ShowWhenTrue show={Env.enableDatabricksNoteBook && !isDagMode}>
                    <button
                        disabled={isCurrentWorkflowReadOnly}
                        type="button"
                        onClick={openNotebook}
                    >
                        <TooltipTop
                            title={isCurrentWorkflowNotExecutable ? disabledMessage: 'Open Notebook'}
                        >
                            <img
                                src="/icons/Component/dataframes.svg"
                                className="icon__hover"
                                alt=""
                                width="20px"
                                height="20px"
                            />
                        </TooltipTop>
                    </button>
                </ShowWhenTrue> */}
                <ShowWhenTrue show={!isDagMode}>
                    <div className="btn-save__main btn-run-toolbar">
                
                        <TooltipTop
                            title={isCurrentWorkflowNotExecutable ? disabledMessage: ''}
                        >
                            <button
                                className="btn-sm btn-yellow btn-save centerh"
                                onClick={() => {
                                    runOrPreviewWorkflow('run', Env.databricks ? defaultClusterInfo?.clusterId || null: null);
                                }}
                                aria-label="tool-bar-run-workflow"
                                disabled={isCurrentWorkflowNotExecutable}
                            >
                                <RunBtn/>
                                Run
                            </button>
                        </TooltipTop>
                        <TooltipTop
                            title={isCurrentWorkflowReadOnly ? disabledMessage: ''}
                        >
                            <Dropdown
                                id='workflow_run_dropdown'
                                title={
                                    <span className="btn-yellow btn__dropdown__triangle">
                                        &#x25BE;
                                    </span>
                                }
                                position="right"
                                dropdownOptions={clusterOptions('run')}
                                className="runWorkflow__options"
                                disabled={isCurrentWorkflowReadOnly}
                            />
                        </TooltipTop>
                    </div>
                </ShowWhenTrue>
                <div className="btn-save__main">
                    <TooltipTop
                        title={isCurrentWorkflowReadOnly ? disabledMessage: ''}
                    >
                        <button
                            onClick={saveWorkflowBtnClick}
                            className="btn-sm btn-yellow btn-save centerh"
                            aria-label="tool-bar-save-workflow"
                            disabled={isCurrentWorkflowReadOnly}
                        >
                            <SaveBtn/>
                                Save
                        </button>
                    </TooltipTop>
                    <TooltipTop
                        title={isCurrentWorkflowReadOnly ? disabledMessage: ''}
                    >
                        <Dropdown
                            id="workflow_save_dropdown"
                            title={
                                <span className="btn-yellow btn__dropdown__triangle ">
                            &#x25BE;
                                </span>
                            }
                            position="right"
                            dropdownOptions={{
                                Save: { action: handleSaveWorkflow },
                                'Save as version': { action: () => handleSaveWorkflow(true) }
                            }}
                            disabled={isCurrentWorkflowReadOnly}
                        />
                    </TooltipTop>
                </div>
            </div>
        </Draggable>
    );
};