import React from 'react'
import styles from './../styles.module.scss';
import { SelectField } from '@components/form';
import { Formik } from 'formik';
import { _selectoptionType } from '@components/form/NewSelectField';
import { KEY_VALUE } from '../types';

export type SelectList = {
    title: string,
    value: string,
    options: _selectoptionType[]
    icon?: React.ReactElement,
    info?: string
}

export type SelectGroupProps = {
    selectList: SelectList[],
    onClick: (group: string, selectedOptions?: Record<string, any>) => void,
    multiple_select: boolean,
    initialValues: KEY_VALUE,
    isSingleSelect?: boolean,
    theme?: string
}

const SelectGroup: React.FC<SelectGroupProps> = ({
    selectList,
    onClick,
    multiple_select = true,
    initialValues,
    isSingleSelect,
    theme
}) => {
    return (
        <div className={isSingleSelect ? styles['single-select'] : styles['select']} >
            {
                selectList.map((select, index) => {
                    return (
                        <Formik
                            key={"form_" + index}
                            initialValues={initialValues}
                            onSubmit={() => {
                                //
                            }}
                        >
                            {(_formikprops: any) => {
                                return (
                                    <form style={{ width: '100%' }}>
                                        <SelectField
                                            className='mb-0'
                                            label={select.title}
                                            name={select.value}
                                            multiple_select={
                                                multiple_select
                                            }
                                            infoText={select?.info}
                                            options={select.options}
                                            onOptionClick={(option: _selectoptionType, selectedOptions?: Record<string, any>, selected?: boolean) => {
                                                isSingleSelect ? onClick(select.value, option) : onClick(select.value, selectedOptions)
                                            }}
                                            onSelectAll={() => {
                                                const all: KEY_VALUE = {}
                                                select.options.forEach((option) => {
                                                    all[option.value] = option.value
                                                })
                                                onClick(select.value, all)
                                            }}
                                            selectAll={true}
                                            theme={theme}
                                        />
                                    </form>
                                );
                            }}
                        </Formik>
                    )
                })
            }
        </div>
    )
}

export default SelectGroup