/* eslint @typescript-eslint/no-empty-function: ["off"] */
import HttpInvoker, { responseCallback, errorHandlerCallback } from './httpinvoker';
import { errorHandlerFor400 } from './errorhandler';
import { _Axios } from './httpsettings';

class HttpClientClass {
    _pendingRequests: Record<string, any>

    constructor() {
        this._pendingRequests = {};
    }

    AbortPendingRequests(key: string) {
        if (this._pendingRequests[key]) {
            this._pendingRequests[key]._callback = function () { };
            this._pendingRequests[key].cancel();
            this._pendingRequests[key] = null;
        }
    }

    GetPromise(url: string, cors = false) {
        return HttpInvoker._get(url, cors);
    }

    PostPromise(url: string, data: Record<string | number, any> | FormData, cors = false) {
        return HttpInvoker._post(url, data, cors);
    }

    PutPromise(url: string, data: Record<string | number, any>, cors = false) {
        return HttpInvoker._put(url, data, cors);
    }

    PatchPromise(url: string, data: Record<string | number, any>, cors = false) {
        return HttpInvoker._patch(url, data, cors);
    }

    DeletePromise(url: string, cors = false) {
        return HttpInvoker._delete(url, cors);
    }

    Get(actionName: string, url: string, callback: responseCallback, errorHandlerCallBack: errorHandlerCallback = errorHandlerFor400, cors = false) {
        const key = actionName;
        this.AbortPendingRequests(key);
        this._pendingRequests[key] = HttpInvoker.Get(url, callback, errorHandlerCallBack, cors);
    }

    GetBlob(url: string) {
        return _Axios().get(url, {responseType: 'blob'});
    }

    Post(actionName: string, url: string, data: Record<string | number, any> | FormData, callback: responseCallback, errorHandlerCallBack: errorHandlerCallback = errorHandlerFor400, cors = false) {
        const key = actionName;
        this.AbortPendingRequests(key);
        this._pendingRequests[key] = HttpInvoker.Post(url, data, callback, errorHandlerCallBack, cors);
    }

    PostFile(url: string, formData: FormData) {
        return _Axios().post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    Put(actionName: string, url: string, data: Record<string | number, any>, callback: responseCallback, errorHandlerCallBack: errorHandlerCallback = errorHandlerFor400, cors = false) {
        const key = actionName;
        this.AbortPendingRequests(key);
        this._pendingRequests[key] = HttpInvoker.Put(url, data, callback, errorHandlerCallBack, cors);
    }

    Patch(actionName: string, url: string, data: Record<string | number, any>, callback: responseCallback, errorHandlerCallBack: errorHandlerCallback = errorHandlerFor400, cors = false) {
        const key = actionName;
        this.AbortPendingRequests(key);
        this._pendingRequests[key] = HttpInvoker.Patch(url, data, callback, errorHandlerCallBack, cors);
    }

    Delete(actionName: string, url: string, callback: responseCallback, errorHandlerCallBack: errorHandlerCallback = errorHandlerFor400, cors = false) {
        const key = actionName;
        this.AbortPendingRequests(key);
        this._pendingRequests[key] = HttpInvoker.Delete(url, callback, errorHandlerCallBack, cors);
    }
}

const HttpClient = new HttpClientClass();

export { HttpClient };