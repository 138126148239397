/* eslint-disable @typescript-eslint/no-use-before-define */
import React, {
	useMemo,
	useEffect,
	useState,
	useRef,
	useCallback,
} from "react";
import { CanvasTabsComponent } from "../../workflow-page/canvas/canvas-tabs";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/types";
import {
	BasicTabInfo,
	switchToExistingCanvasTab,
	updateAWorkflowAnalyticsTabInfo,
	WorkflowAnalyticsTabInfo,
	updateOpenTabsOfACanvas,
	OpenTabsType,
	closeWorkflowEditorAnalyticsTab,
	getTabType,
} from "../../../store/canvas";
import isEmpty from "lodash/isEmpty";
import { CanvasWidget } from "@projectstorm/react-canvas-core";
import {
	WorkflowCanvas,
	SerializedData,
} from "../../../components/workflow-canvas";
import GridLayout, { Layout } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
//import 'react-resizable/css/styles.css';
import { WorkflowCanvasMagnificationTools } from "../../workflow-page/canvas/workflow-canvas-renderer/workflow-canvas-magnification-tools";
import classNames from "classnames";
import { DsNodeModel } from "../../../components/workflow-canvas/node/ds_nodemodel";
import {
	useDebounce,
	useWillUnmount,
	useDidUpdate,
	useOnWindowResize,
} from "rooks";
import {
	setComponentIdForAnalytics,
	handleAddOrRemoveComponentIdOfActivePlots,
	toggleAnalyticsModal,
} from "../../../store/analytics";
import {
	errorAlert,
	infoAlert,
} from "../../../components/toastify/notify-toast";
import { InPageSpinner } from "../../../components/spinners/in-page-spinner";
import { ShowWhenTrue } from "../../../helpers";
import {
	PlotSelectionModal,
	PlotSelectionFormType,
	MultiBandColTypes,
	MultiBandColumnsInfo,
} from "../modals/plot-selection";
import { ErrorLogsModal } from "../modals/error-logs";
import { isEqual, omit, cloneDeep, set, has } from "lodash";
import { UserDetails } from "../../../components/header-sidebar/user-details";
import ReactEcharts from "echarts-for-react";
import { useGetActiveTabInfo } from "../../../utils";
import {
	GoToFullScreenIcon,
	RestoreIcon,
	MinimiseIcon,
	GoBackArrow,
} from "../assets/icons";
import { GraphCard } from "./graph-card";
import { RunPreviewWorkflowModal } from "../../workflow-page/modals/run-preview-workflow";
import {
	toggleModal,
	setActiveTabPreviewClusterId,
} from "../../../store/workflow";
import { filterTabsFuncForWorkflowEditorVisualizations } from "../../workflow-page/utils";
import "leaflet/dist/leaflet.css";
import { ColorPickerModal } from "../modals/color-picker";
import {
	ColorModes,
	ColorRamps,
	RasterPlotFailureMessages,
} from "../enums";
import {
	RasterConfig,
	RasterPlotPayload,
	SubmitPlotInfo,
} from "../../../api/analytics-handler";
import { uuid } from "uuidv4";
import SelectClusterModal from "../modals/SelectCluster";
import usePlotApi, {
	SubmitPlotDataRef,
} from "../modals/plot-selection/usePlotApi";
import { useAnalyticsContext } from "../useAnalyticsContext";

export type PlotData = {
	plotType:
		| "bar"
		| "area"
		| "scatter"
		| "map"
		| "line"
		| "geospatial"
		| "log"
		| "heatmap"
		| "correlation"
		| "boxplot"
		| "raster";
	plotData: Record<string, any[]>;
	x: string;
	y: string[];
	geometries?: string[];
	boundingBox?: {
		maxx: number;
		maxy: number;
		minx: number;
		miny: number;
		centroidx: number;
		centroidy: number;
	};
	latitude: null | string;
	longitude: null | string;
	tiffFilePath: null | Record<string, string>;
	raster_info?: RasterPlotPayload;
};

export type GraphState = "SHOW_GRAPH" | "LOADING" | "ERROR";
export type PlotSelectionFormInfo = PlotSelectionFormType & {
	xAxis: string;
	yAxis: string[];
	groupByColumns: string[];
	graphIdentifier: string | null;
	multiBandCols: Record<MultiBandColTypes, string[]>;
	rasterInfo?: RasterPlotPayload;
};

export type RasterColorConfigInfo = {
	startColor: string;
	endColor: string;
	colorRamp: ColorRamps;
	invertColors: boolean;
	noOfClasses: number;
	colorMode: ColorModes;
	alpha: number;
	hidden: boolean;
};

export interface GraphInfo {
	plotData: PlotData | null;
	componentId: string;
	state: GraphState;
	errorMessage: RasterPlotFailureMessages | null;
	plotStatementId: string;
	plotSelectionFormInfo: PlotSelectionFormInfo;
	tiffColorPlotInfo: TiffColorPlotInfo;
}

export type TiffColorPlotInfo = Record<string, RasterColorConfigInfo>;

export type FullScreenInfo = {
	graphIdentifier: string;
	show: boolean;
	layout: Layout[];
	scrollTop: number;
	resizeWorkflowCanvas: boolean;
};

export type AllGraphsInfo = {
	layout: GridLayout.Layout[];
	refs: Record<string, Record<string, any>>;
	data: Record<string, GraphInfo>;
	fullScreenInfo: FullScreenInfo | null;
};

export type InitialDataForTiffColorSelection = {
	rasterConfigId: string;
	graphIdentifier: string;
	tiffColorPlotInfo: GraphInfo["tiffColorPlotInfo"];
} | null;

export type UserColumnSelectionRef = {
	multiBandCols: MultiBandColumnsInfo;
} & Record<"keys" | "values" | "seriesGroupings", string[]>;

const WORKFLOW_CANVAS = "WORKFLOW_CANVAS";

const gridConfig = {
	NUMBER_OF_COLUMNS: 9,
	MARGIN_X: 20,
	MARGIN_Y: 20,
	ROW_HEIGHT: 30,
	OFFSET_FOR_FULLSCREEN: 40,
};

// Infinity puts it at the bottom
const getBaseGraphLayout = (key: string, numberOfItems: number): Layout => ({
	i: key,
	x: (numberOfItems * 3) % gridConfig.NUMBER_OF_COLUMNS,
	y: Infinity,
	w: 3,
	h: 8,
	minH: 8,
	minW: 3,
});
const initialGridLayoutInfo: Layout[] = [
	{
		i: WORKFLOW_CANVAS,
		x: 0,
		y: 0,
		w: gridConfig.NUMBER_OF_COLUMNS,
		h: 9.5,
		static: true,
		minH: 0,
		minW: 0,
	},
];
const initialFullScreenInfo = {
	graphIdentifier: "",
	show: false,
	layout: [],
	scrollTop: 0,
	resizeWorkflowCanvas: false,
};

export const Canvas: React.FC = () => {
	const { openTabs, activeTab } = useSelector(
		(store: RootState) => store.CanvasReducer.analytics
	);
	const [gridLayoutInfo, setGridLayoutInfo] = useState<Layout[]>(
		initialGridLayoutInfo
	);
	const [graphsData, setGraphsData] = useState<Record<string, GraphInfo>>({});
	// const [actualLayoutInfo, setActualLayoutInfo] = useState<Layout[] | null>()
	const [fullScreenInfo, setFullScreenInfo] = useState<FullScreenInfo>(
		initialFullScreenInfo
	);

	const dispatch = useDispatch();
	const {
		isWorkflowTreeviewMinimized,
		runAnalyticsOnActiveComponent,
		workflowClicked,
	} = useSelector((store: RootState) => store.AnalyticsReducer);
	// initialDataForPlotSelection is set to null for when clicked on components in the canvas, set to forminfo when clicked on graph cards
	const activeTabInfo = useGetActiveTabInfo(
		"analytics"
	) as WorkflowAnalyticsTabInfo;

	const graphsLayoutRef = useRef<Layout[]>(gridLayoutInfo);
	const echartsRefOfAllPlots = useRef<
		Record<string, React.RefObject<ReactEcharts>>
	>({});
	const graphsDataRef = useRef(graphsData);
	const activeTabInfoRef = useRef(activeTabInfo);
	const canvasOuterRef = useRef<HTMLDivElement>(null);
	const fullScreenInfoRef = useRef<FullScreenInfo>(fullScreenInfo);
	// const sessionCreatorRef = useRef<AnalyticsSession>();
	const activeExecutionEnv = useSelector(
		(store: RootState) => store.CommonReducer.activeExecutionEnv
	);
	const [activeGraphIdentifer, setActiveGraphIdentifer] = useState<
		string | null
	>(null);
	const columnSelectionRef = useRef<UserColumnSelectionRef>({
		keys: [],
		values: [],
		seriesGroupings: [],
		multiBandCols: {
			red: [],
			green: [],
			blue: [],
		},
	});

	const componentIdForAnalytics =
		activeTabInfo?.activeComponentIdForAnalytics || null;
	const [
		initialDataForTiffColorSelection,
		setInitialDataForTiffColorSelection,
	] = useState<InitialDataForTiffColorSelection>(null);
	useEffect(() => {
		graphsDataRef.current = graphsData;
	}, [graphsData]);

	useEffect(() => {
		activeTabInfoRef.current = activeTabInfo;
	}, [activeTabInfo]);

	useEffect(() => {
		fullScreenInfoRef.current = fullScreenInfo;
	}, [fullScreenInfo]);

	// const openTabsList: BasicTabInfo[] = useMemo(() => {
	//     const __openTabsInArray = Array.from(openTabs.values());
	//     return __openTabsInArray.map(tab => ({ id: tab.info.id, name: tab.info.name, saved: tab.info.saved }));
	// }, [openTabs]);

	const handlePlusIconClick = () => {
		return;
	};

	const handleUpdateTabInfo = (__info: Partial<WorkflowAnalyticsTabInfo>) => {
		dispatch(
			updateAWorkflowAnalyticsTabInfo({
				type: getTabType("analytics", activeTabInfoRef.current.env),
				info: { ...activeTabInfoRef.current, ...__info },
			})
		);
	};

	const saveCurrentState = () => {
		const activeTabInfo = activeTabInfoRef.current;
		if (activeTabInfo) {
			// DO NOT STORE FULL ECHARTS OPTION, STORING LEGEND INFO, DATAZOOM INFO IS ENOUGH
			const _refs: Record<string, any> = {};
			Object.entries(echartsRefOfAllPlots.current).forEach(
				([_graphKey, _ref]) =>
					(_refs[
						_graphKey
					] = (_ref.current as any)?.getEchartsInstance().getOption())
			);
			const fullScreenInfo = fullScreenInfoRef.current.show
				? fullScreenInfoRef.current
				: null;
			const allGraphsData: AllGraphsInfo = {
				layout: graphsLayoutRef.current,
				refs: _refs,
				data: graphsDataRef.current,
				fullScreenInfo,
			};
			handleUpdateTabInfo({ graphsInfo: allGraphsData });
		}
	};

	useEffect(() => {
		if (activeTabInfoRef.current?.graphsInfo.data !== graphsData)
			saveCurrentState();
	}, [graphsData]);

	const handleSetGridLayout = (gridLayoutInfo: Layout[]) => {
		setGridLayoutInfo(gridLayoutInfo);
		graphsLayoutRef.current = gridLayoutInfo;
	}

	const addAGraph = (graphIdentifier: string, graphInfo: GraphInfo) => {
		const newLayoutInfo = [
			...graphsLayoutRef.current,
			getBaseGraphLayout(
				graphIdentifier,
				graphsLayoutRef.current.length - 1
			),
		];
		handleSetGridLayout(newLayoutInfo)

		setGraphsData((graphsData) => ({
			...graphsData,
			[graphIdentifier]: graphInfo,
		}));
	};

	const updateAGraph = (
		graphIdentifier: string,
		graphInfo: Partial<GraphInfo>
	) => {
		setGraphsData((graphsData) => {
			const __updatedGraphInfo = {
				...graphsData[graphIdentifier],
				...graphInfo,
			};
			return { ...graphsData, [graphIdentifier]: __updatedGraphInfo };
		});
	};

	const removeGraphInfoFromGraphData = (graphIdentifier: string) => {
		const graphsData = graphsDataRef.current;
		const componentIdOfGraphToBeRemoved =
			graphsData[graphIdentifier].componentId;
		const updatedGraphData: typeof graphsData = omit(
			graphsData,
			graphIdentifier
		);
		setGraphsData(updatedGraphData);

		// Loooooong name, I know :(
		const doesOtherGraphsHaveTheSameComponent = Object.values(
			updatedGraphData
		).find(
			(_graphData) =>
				_graphData.componentId === componentIdOfGraphToBeRemoved
		);
		if (!doesOtherGraphsHaveTheSameComponent) {
			dispatch(
				handleAddOrRemoveComponentIdOfActivePlots(
					"REMOVE",
					componentIdOfGraphToBeRemoved
				)
			);
		}
	};

	const removeGraph = (graphIdentifier: string) => {
		if (fullScreenInfo.show) {
			setFullScreenInfo(initialFullScreenInfo);
			// Graphs state is saved before switching to full screen
			loadInitialGraphsState(activeTabInfoRef.current.graphsInfo.refs);
		}
		const updatedGraphsLayoutInfo = graphsLayoutRef.current.filter(
			(_gridLayout) => _gridLayout.i !== graphIdentifier
		);
		handleSetGridLayout(updatedGraphsLayoutInfo)
		echartsRefOfAllPlots.current = omit(
			echartsRefOfAllPlots.current,
			graphIdentifier
		);
		removeGraphInfoFromGraphData(graphIdentifier);
	};

	const resizeGridItem = (
		gridItemIdentifier: string,
		resizeWorkflowCanvas = false
	) => {
		// gridItemIdentifier - key of graphs or _WORKFLOW(key of mini workflow canvas)
		// let h = Math.round((height + margin[1]) / (rowHeight + margin[1])); from react-grid-layout
		const heightOfCanvas = canvasOuterRef.current?.clientHeight;
		if (heightOfCanvas && !fullScreenInfo.show) {
			// Switch to full screen
			// save current state is needed to save the current state of graphs
			saveCurrentState();
			const h =
				(heightOfCanvas +
					gridConfig.MARGIN_Y -
					gridConfig.OFFSET_FOR_FULLSCREEN) /
				(gridConfig.ROW_HEIGHT + gridConfig.MARGIN_Y);
			const graphLayout = cloneDeep(graphsLayoutRef.current)
				.filter((layout) => layout.i === gridItemIdentifier)
				.map((layout) => {
					layout.h = h;
					layout.w = gridConfig.NUMBER_OF_COLUMNS;
					return layout;
				});

			setTimeout(() => {
				// Scroll to top
				if (canvasOuterRef.current) {
					canvasOuterRef.current.scrollTo({ top: 0 });
				}
			}, 1);
			// Maximise canvas if canvas is minimized
			resizeWorkflowCanvas &&
				isWorkflowCanvasMinimized &&
				handleMinimizeClick();
			setFullScreenInfo({
				show: true,
				graphIdentifier: gridItemIdentifier,
				layout: graphLayout,
				scrollTop: canvasOuterRef.current?.scrollTop || 0,
				resizeWorkflowCanvas,
			});
		} else {
			setTimeout(() => {
				// Scrolls to the graph's original position
				if (canvasOuterRef.current) {
					canvasOuterRef.current.scrollTo({
						top: fullScreenInfo.scrollTop,
					});
				}
				zoomNodesToFit();
			}, 500);
			// WHEN A GRAPH IS MINIMIZED FROM FULL SCREEN, graph card's minimizing to original size first
			// but stale full screen graph state is shown for couple of seconds
			// To fix this, it's state is set to loading on minimizing
			// and set to show_graph in loadInitialGraphsState function
			// if there's no plotData, don't set the state to loading again
			if (
				!!graphsData[gridItemIdentifier] &&
				graphsData[gridItemIdentifier].plotData &&
				graphsData[gridItemIdentifier].plotData?.plotType !== "raster"
			) {
				// This is only needed for echart graphs
				setGraphsData((graphsData) => {
					const __graphData = cloneDeep(graphsData);
					if (__graphData[gridItemIdentifier]) {
						__graphData[gridItemIdentifier].state = "LOADING";
					}
					return __graphData;
				});
				loadInitialGraphsState(
					activeTabInfoRef.current.graphsInfo.refs,
					gridItemIdentifier
				);
			}
			// Switch to normal mode
			// as latest state is always stored in graphsLayoutRef.current
			setGridLayoutInfo(graphsLayoutRef.current);
			setFullScreenInfo(initialFullScreenInfo);
		}
	};

	const resizeWorkflowCanvas = () => {
		resizeGridItem(WORKFLOW_CANVAS, true);
	};

	const handleSwitchTab = (tabInfo: BasicTabInfo) => {
		if (tabInfo.id !== activeTab.id) {
			saveCurrentState();
			dispatch(switchToExistingCanvasTab("analytics", tabInfo.id));
		}
	};

	// useDidMount(() => {
	//     dispatch(openANewWorkflowAnalyticsTab(testTabData.info as any));
	// });

	useDidUpdate(() => {
		// This gets triggerred when user clicks workflows from the LHS workflow list
		saveCurrentState();
	}, [workflowClicked]);

	useWillUnmount(() => {
		// currentSessionInfoRef.current.workflowSessionId && AnalyticsHandler.KillSession(currentSessionInfoRef.current.workflowSessionId);
		// dispatch(setComponentIdForAnalytics(''));
		saveCurrentState();
	});

	const handleCloseTab = (tabInfo: BasicTabInfo) => {
		dispatch(closeWorkflowEditorAnalyticsTab("analytics", tabInfo.id));
	};

	const workflowCanvasInfo: Layout & { index: number } = useMemo(() => {
		const index = gridLayoutInfo.findIndex(
			(item) => item.i === WORKFLOW_CANVAS
		);
		if (index !== -1) {
			const info: Layout & { index: number } = {
				...gridLayoutInfo[index],
				index,
			};
			return info;
		}
		return {
			i: WORKFLOW_CANVAS,
			x: 0,
			y: 0,
			w: 3,
			h: 10,
			static: true,
			index: 0,
		};
	}, [gridLayoutInfo]);

	const updateHeightOfWorkflowCanvas = (h: number): Layout[] => {
		const index = workflowCanvasInfo.index;
		const updatedInfo = { ...workflowCanvasInfo, h, x: 0, y: 0 };
		const gridLayoutInfoCopy = [...gridLayoutInfo];
		gridLayoutInfoCopy[index] = updatedInfo;
		return gridLayoutInfoCopy;
	};

	const handleMinimizeClick = () => {
		if (workflowCanvasInfo.h !== 1.5) {
			handleSetGridLayout(updateHeightOfWorkflowCanvas(1.5));
		} else handleSetGridLayout(updateHeightOfWorkflowCanvas(10));
		zoomNodesToFit();
	};

	const {
		sendAnalyticsReducerCmd,
		rasterSelectionInfo,
	} = useAnalyticsContext();

	const showPlotSelectionModal = (
		componentId: string,
		isNewPlot: boolean
	) => {
		handleUpdateTabInfo({ activeComponentIdForAnalytics: componentId });
		dispatch(toggleAnalyticsModal("plotSelection", true));
		if (isNewPlot) {
			sendAnalyticsReducerCmd({
				type: "SET_PLOT_SELECTION_STATE_OF_NEW_PLOT",
				payload: {
					componentId,
					activeTabInfo: activeTabInfoRef.current,
				},
			});
		}
	};

	const hidePlotSelectionModal = useCallback(() => {
		dispatch(toggleAnalyticsModal("plotSelection", false));
		// toggleSessionCreationLoadingState(false);
	}, []);

	const handleSetRasterSelectionInfo = (
		graphIdentifier: string,
		rasterConfig: RasterConfig
	) => {
		sendAnalyticsReducerCmd({
			type: "SET_RASTER_SELECTION_INFO",
			payload: {
				graphCardInfo: graphsData[graphIdentifier],
				rasterConfig,
			},
		});
	};

	const handleAddNewRaster = (graphIdentifier: string) => {
		sendAnalyticsReducerCmd({
			type: "SET_RASTER_SELECTION_INFO",
			payload: {
				graphCardInfo: graphsData[graphIdentifier],
				rasterConfig: "new",
			},
		});
	};

	const showPlotSelectionModalFromGraphCard = (
		plotSelectionFormInfo: PlotSelectionFormInfo,
		rasterConfig: RasterConfig | null | "new"
	) => {
		if (plotSelectionFormInfo.graphIdentifier) {
			setActiveGraphIdentifer(plotSelectionFormInfo.graphIdentifier);
			sendAnalyticsReducerCmd({
				type: "SET_PLOT_SELECTION_STATE_FROM_GRAPH_CARD",
				payload: {
					plotSelectionFormInfo,
					rasterConfig,
					graphCardInfo:
						graphsData[plotSelectionFormInfo.graphIdentifier],
					activeTabInfo,
				},
			});
			showPlotSelectionModal(
				plotSelectionFormInfo.connectedComponentInfo.component_id,
				false
			);
		}
	};

	const showTiffColorPickerFromGraphCard = (
		graphIdentifier: string,
		rasterConfigId: string,
		tiffColorPlotInfo: GraphInfo["tiffColorPlotInfo"]
	) => {
		setInitialDataForTiffColorSelection({
			tiffColorPlotInfo,
			graphIdentifier,
			rasterConfigId,
		});
		dispatch(toggleAnalyticsModal("tiffColorPicker", true));
	};

	const runAnalyticsOnTheSelectedNode = (nodeId: string) => {
		// This is called when node is clicked on the canvas
		if (activeTabInfoRef.current.showPlotInProgressSpinner) {
			infoAlert("Cannot run analytics on two components at a time.");
		} else if (
			!isEmpty(activeTabInfoRef.current.dfList) &&
			activeTabInfoRef.current.previewStatementId
		) {
			// This means workflow has already ran once - dflist and previewstatementid aren't empty
			showPlotSelectionModal(nodeId, true);
			// setInitialDataForPlotSelection(null);
			setActiveGraphIdentifer(null);
			toggleSessionCreationLoadingState(false);
		} else {
			// this means initial run - data has to be prepared
			dispatch(setComponentIdForAnalytics(nodeId));
			// setInitialDataForPlotSelection(null);
			setActiveGraphIdentifer(null);
		}
	};

	const lockAllNodes = (node: DsNodeModel) => {
		node.setLocked(true);
		node.registerListener({
			selectionChanged: function(event: any) {
				if (event.isSelected) {
					if (!isEmpty(node.getOutPorts())) {
						runAnalyticsOnTheSelectedNode(node.getID());
						// addDummyGraph();
					}
					node.setSelected(false);
				}
			},
		});
	};

	const zoomNodesToFit = () => {
		setTimeout(() => {
			const nodesList = Object.values(WorkflowCanvas.getAllNodes());
			if (nodesList.length > 1) {
				WorkflowCanvas.engine.zoomToFitNodes({ margin: 30 });
			} else {
				// zoom to fit nodes for less than 1 occupy the entire screen
				WorkflowCanvas.engine.zoomToFit();
			}
		}, 200);
	};

	const loadWorkflowOntoCanvas = (details: SerializedData) => {
		WorkflowCanvas.clearSelection();
		WorkflowCanvas.resetWorkflow();
		if (!isEmpty(details)) {
			WorkflowCanvas.deSerializeModel(details as SerializedData);

			const nodesList = Object.values(WorkflowCanvas.getAllNodes());
			// Lock all nodes
			nodesList.map((node: any) => lockAllNodes(node as DsNodeModel));
			zoomNodesToFit();
		}
	};

	const loadInitialGraphsState = (
		refs: AllGraphsInfo["refs"],
		resizeGraphIdentifer = ""
	) => {
		if (!isEmpty(refs)) {
			if (resizeGraphIdentifer) {
				setGraphsData((graphsData) => {
					const __graphData = cloneDeep(graphsData);
					__graphData[resizeGraphIdentifer].state = "SHOW_GRAPH";
					return __graphData;
				});
			}

			setTimeout(() => {
				Object.entries(refs).forEach(([_graphIdentifier, _option]) => {
					if (!isEmpty(_option)) {
						// (echartsRefOfAllPlots.current[_graphIdentifier].current as any)?.getEchartsInstance().setOption(_option, true);
						const graphRef = (echartsRefOfAllPlots.current[
							_graphIdentifier
						]?.current as any)?.getEchartsInstance();
						if (graphRef) {
							// resizeGraphs && graphRef.resize()
							// datazoom is for retaining the zoom state
							_option.dataZoom.forEach(
								(_datazoomOption: any, index: number) => {
									graphRef.dispatchAction({
										type: "dataZoom",
										dataZoomIndex: index,
										start: _datazoomOption.start,
										end: _datazoomOption.end,
										startValue: _datazoomOption.startValue,
										endValue: _datazoomOption.endValue,
									});
								}
							);
							// legendunselect is for unselecting the legends
							_option.legend.forEach((_legendOption: any) => {
								// _legendOption.selected = {optionName: boolean}
								if (!isEmpty(_legendOption.selected)) {
									Object.keys(_legendOption.selected).forEach(
										(_optionName) => {
											if (
												!_legendOption.selected[
													_optionName
												]
											) {
												graphRef.dispatchAction({
													type: "legendUnSelect",
													name: _optionName,
												});
											}
										}
									);
								}
							});
						}
					}
				});
			}, 1);
		} else {
			echartsRefOfAllPlots.current = {};
		}
	};

	const loadInitialGraphs = (graphsInfo: AllGraphsInfo) => {
		const { layout, data, refs, fullScreenInfo } = graphsInfo;

		setFullScreenInfo(
			fullScreenInfo?.show ? fullScreenInfo : initialFullScreenInfo
		);
		if (!isEmpty(layout)) {
			handleSetGridLayout(layout)
		} else if (gridLayoutInfo !== initialGridLayoutInfo) {
			handleSetGridLayout(initialGridLayoutInfo)
		}

		setGraphsData(data);
		loadInitialGraphsState(refs);
	};

	const prepareWorkflowCanvasAndGraphs = (id: number | string) => {
		const workflowInfo = openTabs.get(id);
		if (workflowInfo) {
			const { details, graphsInfo } = workflowInfo.info;
			loadWorkflowOntoCanvas(details as SerializedData);
			loadInitialGraphs(graphsInfo);
		}
	};

	// const addDummyGraph = () => {
	//     addAGraph(uuid(), { plotData: testMapPlotWithCentroid, componentId: '', state: 'SHOW_GRAPH',  plotStatementId: '' } as any);
	//     // addAGraph(uuid(), { plotData: testGeospatialPlot, componentId: '', state: 'SHOW_GRAPH',  plotStatementId: '' } as any);
	//     return;
	// };

	useEffect(() => {
		if (activeTab.id !== 0) {
			prepareWorkflowCanvasAndGraphs(activeTab.id);
		}
	}, [activeTab]);

	const [gridWidth, setGridWidth] = useState<number>();

	// const gridWidth = useMemo(() => {
	//     if (isWorkflowTreeviewMinimized) return window.innerWidth - 80;
	//     return window.innerWidth - 235 - 72;
	// }, [isWorkflowTreeviewMinimized, window.innerWidth]);

	const handleSetGridWidth = () => {
		let gridWidth;
		if (isWorkflowTreeviewMinimized) gridWidth = window.innerWidth - 80;
		else gridWidth = window.innerWidth - 235 - 72;
		setGridWidth(gridWidth);
	};

	useEffect(() => {
		handleSetGridWidth();
	}, [isWorkflowTreeviewMinimized]);

	const setGridWidthOnWindowResize = useDebounce(() => {
		handleSetGridWidth();
	}, 300);

	useOnWindowResize(setGridWidthOnWindowResize);

	const isWorkflowCanvasMinimized = useMemo(
		() => workflowCanvasInfo.h === 1.5,
		[workflowCanvasInfo]
	);

	const handleSessionCreateSuccess = (
		sessionId: string,
		isStreaming = false
	) => {
		// isStreaming info is added here when there's no old session info
		// for old sessions, isStreaming is added in runAnalyticsOnActiveWorkflow in src\store\analytics\actions.ts
		dispatch(
			updateAWorkflowAnalyticsTabInfo({
				type: getTabType("analytics", activeTabInfoRef.current.env),
				info: { ...activeTabInfoRef.current, sessionId, isStreaming },
			})
		);
		toggleSessionCreationLoadingState(true);
		dispatch(toggleModal("runWorkflow", false));
	};

	useEffect(() => {
		if (runAnalyticsOnActiveComponent) {
			toggleSessionCreationLoadingState(true);
			startNewAnalyticsSession();
		}
	}, [runAnalyticsOnActiveComponent]);

	const onClusterSelection = (clusterId: string) => {
		toggleSessionCreationLoadingState(true);
		dispatch(toggleAnalyticsModal("clusterSelection", false));
		dispatch(
			setActiveTabPreviewClusterId(
				activeTabInfoRef.current.id,
				clusterId,
				"analytics"
			)
		);
		createSessionAndPrepareData(clusterId);
	};

	const handleSaveLayoutOnChange = useDebounce(
		(layout: Layout[]) => {
			if (
				!isEqual(layout, graphsLayoutRef.current) &&
				!fullScreenInfoRef.current.show
			) {
				graphsLayoutRef.current = cloneDeep(layout);
			}
		},
		[800]
	);

	const handleUpdateOpenTabs = (__updatedOpenTabs: OpenTabsType) => {
		dispatch(updateOpenTabsOfACanvas("workflowEditor", __updatedOpenTabs));
	};

	const handleSetGraphRef = (
		graphIdentifier: string,
		graphRef: React.RefObject<ReactEcharts>
	) => {
		echartsRefOfAllPlots.current = {
			...echartsRefOfAllPlots.current,
			[graphIdentifier]: graphRef,
		};
	};

	const graphLayoutInfoForRender: Layout[] = useMemo(
		() =>
			// hide graphlayout when resizing workflow canvas
			fullScreenInfo.show
				? fullScreenInfo.resizeWorkflowCanvas
					? []
					: fullScreenInfo.layout
				: gridLayoutInfo.filter(
						(layoutInfo) => layoutInfo.i !== WORKFLOW_CANVAS
				  ),
		[gridLayoutInfo, fullScreenInfo]
	);

	const isWorkflowCanvasInFullScreen =
		fullScreenInfo.show && fullScreenInfo.resizeWorkflowCanvas;
	const hideWorkflowCanvas =
		fullScreenInfo.show && !fullScreenInfo.resizeWorkflowCanvas;

	const handleErrorResponse = (message: string) => {
		errorAlert(message);
	};

	const onSubmitPlotSuccessCb = (
		formValues: PlotSelectionFormType,
		plotInfo: SubmitPlotInfo,
		plotStatementId: string
	) => {
		if (componentIdForAnalytics) {
			toggleSessionCreationLoadingState(true)
			dispatch(
				handleAddOrRemoveComponentIdOfActivePlots(
					"ADD",
					componentIdForAnalytics
				)
			);
			hidePlotSelectionModal();
			const {
				keys,
				values,
				seriesGroupings,
				multiBandCols,
			} = columnSelectionRef.current;
			const rasterConfigInfo = plotInfo.raster_info;
			const graphIdentifier = uuid();
			const graphInfo: GraphInfo = {
				plotData: null,
				componentId: componentIdForAnalytics,
				state: "LOADING",
				plotStatementId: plotStatementId,
				plotSelectionFormInfo: {
					...formValues,
					xAxis: keys[0],
					yAxis: values,
					groupByColumns: seriesGroupings,
					graphIdentifier,
					multiBandCols,
					rasterInfo: rasterConfigInfo,
				},
				errorMessage: null,
				tiffColorPlotInfo: rasterSelectionInfo?.tiffColorPlotInfo || {},
			};

			if (
				formValues.plot_type === "raster" &&
				rasterConfigInfo?.raster_id &&
				!has(graphInfo.tiffColorPlotInfo, rasterConfigInfo.raster_id)
			) {
				const initialRasterConfig: RasterColorConfigInfo = {
					colorRamp: ColorRamps.Grey,
					startColor: "white",
					endColor: "black",
					invertColors: false,
					colorMode: ColorModes.Continous,
					noOfClasses: 5,
					alpha: 1,
					hidden: false,
				};

				set(
					graphInfo.tiffColorPlotInfo,
					rasterConfigInfo.raster_id,
					initialRasterConfig
				);
			}
			if (activeGraphIdentifer) {
				// initialDataForPlotSelection means the modal is opened from the graph card
				graphInfo.plotSelectionFormInfo.graphIdentifier = activeGraphIdentifer;
				updateAGraph(activeGraphIdentifer, graphInfo);
			} else {
				setActiveGraphIdentifer(graphIdentifier);
				addAGraph(graphIdentifier, graphInfo);
			}
		}
	};

	const handleSubmitPlot = (submitPlotData: SubmitPlotDataRef) => {
		columnSelectionRef.current = submitPlotData.columnSelectionInfo;
		submitPlot(submitPlotData);
	};

	const onPlotDataFailureCb = (message: string) => {
		toggleSessionCreationLoadingState(false)
		errorAlert(message);
		if (activeGraphIdentifer)
			updateAGraph(activeGraphIdentifer, { state: "ERROR" });
	};

	const onPlotDataSuccessCb = (plotData: PlotData) => {
		toggleSessionCreationLoadingState(false)
		if (activeGraphIdentifer) {
			const graphInfo: Partial<GraphInfo> = { plotData };
			// spinner states are handled in raster plot directly
			if (plotData.plotType !== "raster") {
				graphInfo.state = "SHOW_GRAPH";
			}
			updateAGraph(activeGraphIdentifer, graphInfo);
		}
	};

	const handleSetInitialDataForPlotForm = (
		submitPlotDataRef: SubmitPlotDataRef
	) => {
		sendAnalyticsReducerCmd({
			type: "REOPEN_PLOT_SELECTION_IF_CLUSTER_SELECTION_FAILS",
			payload: submitPlotDataRef,
		});
	};


	const handleClusterSelectionCancel = () => {
		// Cluster selection modal is shown only in 3 scenarios
		// 1. User opens the workflow in visualizations page and clicks on a component - new session will be created on selecting cluster
		//    Cancelling the workflow closes
		// 2. User already visualized the workflow and has some plots, both cluster and session are not available -
		// 	  Clicking on a component or trying to replot an existing plot will show the cluster - If user closes the modal,
		//    plot modal is shown and if user selects the cluster, session is created and plot is submitted automatically.
		// 3. User previews the workflow in workflow page, clicks visualizations on a component but the session and cluster are not available
		//    Brings up the cluster modal automatically after session check is done. Closing the cluster modal
		// 	  and clicking the component should show the cluster modal again.
		//    To show the cluster modal again, preview information and dfList should be removed

		if (
			isEmpty(graphsDataRef.current) &&
			!!activeTabInfoRef.current.sessionId &&
			!!activeTabInfoRef.current.sessionState &&
			activeTabInfoRef.current.sessionState.type !== "SESSION_ACTIVE"
		) {
			// this is 3rd scenario - preview info is cleared
			handleUpdateTabInfo({
				sessionId: null,
				previewStatementId: null,
				dfList: [],
				sessionState: null,
				componentIdsOfPlots: [],
				activeClusterIdForPreview: undefined
			})
		}


		if (activeTabInfoRef.current.sessionId)
			onClusterSelectionCancel();
	};

	// move submit plot, onPlotSuccess functions to inside the graph card\
	// so that each graph card has its own state and multiple graphs
	// can be plotted at once

	const {
		submitPlot,
		showPlotSelectionSpinner,
		startNewAnalyticsSession,
		createSessionAndPrepareData,
		toggleSessionCreationLoadingState,
		dataPrepState,
		onClusterSelectionCancel,
		onPlotSelectionModalCancel,
		togglePlotSelectionSpinner,
	} = usePlotApi({
		onSubmitPlotSuccessCb,
		onSubmitPlotFailureCb: handleErrorResponse,
		onPlotDataSuccessCb,
		onPlotDataFailureCb,
		handleSetInitialDataForPlotForm,
		showPlotSelectionModal,
		hidePlotSelectionModal,
	});

	return (
		<div className="workflow__master__container workflow__analytics__tabs">
			<CanvasTabsComponent
				handlePlusIconClick={handlePlusIconClick}
				openTabs={openTabs}
				activeTab={activeTab.id}
				switchTab={handleSwitchTab}
				closeTab={handleCloseTab}
				updateOpenTabs={handleUpdateOpenTabs}
				filterTabsFunc={filterTabsFuncForWorkflowEditorVisualizations(
					"analytics",
					activeExecutionEnv
				)}
				showSavedStatus={false}
				hidePlusIcon
			>
				<UserDetails />
			</CanvasTabsComponent>
			<div className="canvas__master canvas__analytics">
				{!activeTab.id ? (
					<span className={classNames("openWorkflowMsg")}>
						Open a workflow to get started
					</span>
				) : (
					<div
						className={classNames("canvasGrid__outer", {
							fullScreenMode: fullScreenInfo.show,
						})}
						ref={canvasOuterRef}
					>
						<GridLayout
							className="layout"
							cols={gridConfig.NUMBER_OF_COLUMNS}
							rowHeight={gridConfig.ROW_HEIGHT}
							layout={
								fullScreenInfo.show
									? fullScreenInfo.layout
									: gridLayoutInfo
							}
							width={gridWidth}
							margin={[gridConfig.MARGIN_X, gridConfig.MARGIN_Y]}
							draggableHandle=".react-grid-dragHandle"
							onLayoutChange={handleSaveLayoutOnChange}
							isResizable={!fullScreenInfo.show}
							// onResize={handleResize}
						>
							<div
								className={classNames(
									"workflowCanvas__outer",
									{ minimized: isWorkflowCanvasMinimized },
									{ hide: hideWorkflowCanvas }
								)}
								key={WORKFLOW_CANVAS}
								// This is needed --- Miscalculates height in full screen mode -- DONOTREMOVE
								data-grid={
									hideWorkflowCanvas
										? {
												x: 0,
												y: 12,
												w: 0,
												h: 0,
												minW: 0,
												minH: 0,
												maxW: 0,
												maxH: 0,
										  }
										: {}
								}
							>
								<div className="workflowCanvas__header">
									{isWorkflowCanvasInFullScreen && (
										<button
											className="btn-sm btn-grey btn-goBackWithArrow"
											onClick={resizeWorkflowCanvas}
										>
											<GoBackArrow />
											Back
										</button>
									)}

									<span className="workflowName">
										CANVAS: {activeTab.name}
									</span>
									<div className="separator" />
									<button
										onClick={resizeWorkflowCanvas}
										className="graphCardIcon"
									>
										{isWorkflowCanvasInFullScreen ? (
											<RestoreIcon />
										) : (
											<GoToFullScreenIcon />
										)}
									</button>
									<button
										onClick={handleMinimizeClick}
										className={classNames("btn-minimise", {
											reverse: isWorkflowCanvasMinimized,
											disabled: isWorkflowCanvasInFullScreen
										})}
										disabled={isWorkflowCanvasInFullScreen}
									>
										<MinimiseIcon />
									</button>
								</div>
								<div className="canvas">
									<CanvasWidget
										engine={WorkflowCanvas.engine}
									/>
									<ShowWhenTrue show={dataPrepState}>
										<div className="spinnerDiv">
											<InPageSpinner />
										</div>
									</ShowWhenTrue>
								</div>
								<WorkflowCanvasMagnificationTools />

								<div className="selectAComponentMsg">
									Select a component to visualize
									{/* {dataPrepState && (
										<span className="fl-r inProgressMsg">
											&nbsp;
											{plotDataProgress * 100 + "%"} -
											Workflow preview in progress...
										</span>
									)} */}
								</div>
							</div>

							{graphLayoutInfoForRender.map((_gridInfo) => {
								return (
									<div
										key={_gridInfo.i}
										className="grid-graph"
										data-graphid={_gridInfo.i}
									>
										<GraphCard
											graphIdentifier={_gridInfo.i}
											removeGraph={removeGraph}
											setGraphRef={handleSetGraphRef}
											showPlotSelectionModalFromGraphCard={
												showPlotSelectionModalFromGraphCard
											}
											showTiffColorPickerFromGraphCard={
												showTiffColorPickerFromGraphCard
											}
											resizeGraph={resizeGridItem}
											isFullScreenActive={
												fullScreenInfo.graphIdentifier ===
												_gridInfo.i
											}
											updateGraph={updateAGraph}
											plotInProgress={dataPrepState}
											{...graphsData[_gridInfo.i]}
										/>
									</div>
								);
							})}
						</GridLayout>
					</div>
				)}
			</div>
			<RunPreviewWorkflowModal
				actionType="visualize"
				handleSessionCreateSuccess={handleSessionCreateSuccess}
			/>
			<PlotSelectionModal
				handleAddGraph={addAGraph}
				handleUpdateGraph={updateAGraph}
				setRasterSelectionInfo={handleSetRasterSelectionInfo}
				handleAddNewRaster={handleAddNewRaster}
				submitPlot={handleSubmitPlot}
				showPlotSelectionSpinner={showPlotSelectionSpinner}
				togglePlotSelectionSpinner={togglePlotSelectionSpinner}
				onCancel={onPlotSelectionModalCancel}
				isDataExplorer={false}
			/>
			<ErrorLogsModal />
			<ColorPickerModal
				initialDataForTiffColorSelection={
					initialDataForTiffColorSelection
				}
				handleUpdateGraph={updateAGraph}
			/>
			<SelectClusterModal
				onClusterSubmit={onClusterSelection}
				onCancel={handleClusterSelectionCancel}
			/>
		</div>
	);
};
