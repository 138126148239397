import { RasterConfig } from "@api/analytics-handler";
import { WorkflowAnalyticsTabInfo } from "@store/canvas";
import { isEmpty, omit } from "lodash";
import React, { createContext, useContext, useMemo, useReducer } from "react";
import { uuid } from "uuidv4";
import { GraphInfo, PlotData, PlotSelectionFormInfo } from "./Canvas";
import { BandType, graphTypes, projectionCodeOptions } from "./enums";
import {
	ColumnErrors,
	ConnectedComponents,
	MultiBandColumnsInfo,
	NormalColTypes,
	PlotSelectionFormType,
	RasterSelectionInfo,
	SourceColumns,
	TypesOfColumns,
} from "./modals/plot-selection";
import { SubmitPlotDataRef } from "./modals/plot-selection/usePlotApi";
import { getConnectedComponentsListOfComponent } from "./utils";

type AnalyticsReducerState = {
	keys: string[];
	values: string[];
	seriesGroupings: string[];
	multiBandCols: MultiBandColumnsInfo;
	columnErrors: ColumnErrors;
	initialDataForPlotSelection: PlotSelectionFormInfo | null;
	initialValuesForPlotSelectionForm: PlotSelectionFormType;
	connectedComponents: ConnectedComponents[];
	rasterSelectionInfo: RasterSelectionInfo | null;
};

type AnalyticsContextType = AnalyticsReducerState & {
	sendAnalyticsReducerCmd: (arg0: AnalyticsReducerAction) => void;
};

export const getInitialValuesForPlotSelectionForm = (): PlotSelectionFormType => ({
	_id: Math.random(),
	plot_type: graphTypes[0].value,
	aggregate_type: "",
	connectedComponentInfo: {
		component_id: "",
		target_component_id: "",
		output: "",
	},
	additional_params: {},
	bandType: BandType.Single,
	projection_code: projectionCodeOptions[0].value,
	custom_projection_code: '',
	resolution:'',
	mode: "default",
    min_lat: "",
    max_lat: "",
    min_lon: "",
    max_lon: "",
});

const analyticsReducerInitialState: AnalyticsReducerState = {
	keys: [],
	values: [],
	seriesGroupings: [],
	multiBandCols: {
		red: [],
		green: [],
		blue: [],
	},
	columnErrors: {},
	initialDataForPlotSelection: null,
	initialValuesForPlotSelectionForm: getInitialValuesForPlotSelectionForm(),
	connectedComponents: [],
	rasterSelectionInfo: null,
};

type ColumnAddAction = {
	type: "COLUMN_ADD";
	payload: {
		columnName: string;
		target: TypesOfColumns;
		source: SourceColumns;
		plotType: PlotData["plotType"];
	};
};

type ColumnRemoveAction = {
	type: "COLUMN_REMOVE";
	payload: {
		type: TypesOfColumns;
		columnName: string;
	};
};

export type SetPlotStateFromGraphCardPayload = {
	plotSelectionFormInfo: PlotSelectionFormInfo;
	rasterConfig: RasterConfig | null | "new";
	graphCardInfo: GraphInfo;
	activeTabInfo?: WorkflowAnalyticsTabInfo;
};

type SetPlotStateFromGraphCardAction = {
	type: "SET_PLOT_SELECTION_STATE_FROM_GRAPH_CARD";
	payload: SetPlotStateFromGraphCardPayload;
};

type SetRasterSelectionInfoAction = {
	type: "SET_RASTER_SELECTION_INFO";
	payload: Pick<
		SetPlotStateFromGraphCardPayload,
		"graphCardInfo" | "rasterConfig"
	>;
};

type SetPlotStateOfNewPlot = {
	type: "SET_PLOT_SELECTION_STATE_OF_NEW_PLOT";
	payload: { componentId: string; activeTabInfo: WorkflowAnalyticsTabInfo };
};

type SetPlotStateOfNewPlotDataExplorer = {
	type: "SET_PLOT_SELECTION_STATE_OF_NEW_PLOT_DATAEXPLORER",
	payload: any;
}

type ReopenPlotSelectionIfClusterSelectioFailsAction = {
	type: "REOPEN_PLOT_SELECTION_IF_CLUSTER_SELECTION_FAILS";
	payload: SubmitPlotDataRef;
};

type AnalyticsReducerAction =
	| ColumnAddAction
	| ColumnRemoveAction
	| SetPlotStateOfNewPlot
	| SetPlotStateOfNewPlotDataExplorer
	| SetPlotStateFromGraphCardAction
	| SetRasterSelectionInfoAction
	| ReopenPlotSelectionIfClusterSelectioFailsAction
	| { type: "RESET_COLUMNS"; payload?: undefined }
	| { type: "UPDATE_STATE"; payload: Partial<AnalyticsReducerState> }
	| { type: "COLUMN_CLEAR_ALL"; payload: NormalColTypes }
	| { type: "RESET_PLOT_SELECTION_STATE" };

const getEmptyColumnsState = (): Partial<AnalyticsReducerState> => ({
	keys: [],
	values: [],
	seriesGroupings: [],
	multiBandCols: {
		...analyticsReducerInitialState.multiBandCols,
	},
	columnErrors: {},
});

const parseRasterSelectionInfoAction = ({
	rasterConfig,
	graphCardInfo,
}: SetRasterSelectionInfoAction["payload"]) => {
	let rasterSelectionInfo: AnalyticsReducerState["rasterSelectionInfo"] = null;
	if (typeof rasterConfig === "string" && rasterConfig === "new") {
		const id = uuid();
		rasterSelectionInfo = {
			type: "new",
			config: null,
			statementId: graphCardInfo?.plotStatementId,
			tiffColorPlotInfo: graphCardInfo?.tiffColorPlotInfo,
			newRasterConfigInfo: {
				id,
			},
			activeTabId: id,
		};
	} else if (rasterConfig?.id) {
		rasterSelectionInfo = {
			type: "edit",
			config: rasterConfig,
			statementId: graphCardInfo?.plotStatementId,
			tiffColorPlotInfo: graphCardInfo?.tiffColorPlotInfo,
			newRasterConfigInfo: null,
			activeTabId: rasterConfig.id,
		};
	}
	return rasterSelectionInfo;
};

const convertRasterSelectionInfoToInitialValues = (
	rasterSelectionInfo: RasterSelectionInfo
): {
	updatedState: Partial<AnalyticsReducerState>;
	bandType: BandType;
} => {
	let bandType = BandType.Single;
	let updatedState: Partial<AnalyticsReducerState> = {};
	if (rasterSelectionInfo.type === "edit" && rasterSelectionInfo.config) {
		const selectedRasterConfig = rasterSelectionInfo.config;
		updatedState.keys = [selectedRasterConfig.latitude];
		updatedState.values = [selectedRasterConfig.longitude];

		if (!selectedRasterConfig.singleband) {
			// multi band raster
			const multiBandCols = selectedRasterConfig.y;
			updatedState.multiBandCols = {
				red: [multiBandCols[0]],
				green: [multiBandCols[1]],
				blue: [multiBandCols[2]],
			};
			bandType = BandType.Multi;
		} else {
			// single band raster
			updatedState.seriesGroupings = selectedRasterConfig.y;
		}
	} else {
		updatedState = getEmptyColumnsState();
	}
	return {
		updatedState,
		bandType,
	};
};

const analyticsReducer = (
	state: AnalyticsReducerState,
	action: AnalyticsReducerAction
): AnalyticsReducerState => {
	const {
		keys,
		values,
		seriesGroupings,
		multiBandCols,
		columnErrors,
	} = state;
	switch (action.type) {
		case "UPDATE_STATE":
			return { ...state, ...action.payload };
		case "COLUMN_ADD": {
			const { columnName, target, source, plotType } = action.payload;
			const isCurrentGraphTypeMap =
				plotType === "map" || plotType === "geospatial";
			const isCurrentGraphTypeBox = plotType === "boxplot";
			const isCurrentGraphTypeRaster = plotType === "raster";
			const updatedState: Partial<AnalyticsReducerState> = {};

			switch (target) {
				case "keys": {
					if (isCurrentGraphTypeBox) {
						if (!keys.includes(columnName))
							updatedState.keys = [...keys, columnName];
					} else {
						updatedState.keys = [columnName];
					}
					break;
				}
				case "values": {
					if (isCurrentGraphTypeMap || isCurrentGraphTypeRaster)
						updatedState.values = [columnName];
					else if (!values.includes(columnName))
						updatedState.values = [...values, columnName];
					break;
				}
				case "seriesGroupings": {
					if (isCurrentGraphTypeRaster || plotType === "geospatial")
						updatedState.seriesGroupings = [columnName];
					else if (!seriesGroupings.includes(columnName)) {
						updatedState.seriesGroupings = [
							...seriesGroupings,
							columnName,
						];
					}

					break;
				}
				case "blue":
				case "green":
				case "red":
					updatedState.multiBandCols = {
						...multiBandCols,
						[target]: [columnName],
					};
					break;
			}

			// set Error of target column to false
			updatedState.columnErrors = {
				...columnErrors,
				[target]: false,
			};
			return { ...state, ...updatedState };
		}
		case "COLUMN_REMOVE": {
			const { columnName, type } = action.payload;
			switch (type) {
				case "keys":
				case "values":
				case "seriesGroupings": {
					const _updatedList = state[type].filter(
						(col) => col !== columnName
					);
					return { ...state, [type]: _updatedList };
				}
				case "blue":
				case "green":
				case "red":
					return {
						...state,
						multiBandCols: { ...multiBandCols, [type]: [] },
					};
				default:
					return state;
			}
		}
		case "COLUMN_CLEAR_ALL":
			return { ...state, [action.payload]: [] };
		case "RESET_COLUMNS":
			return {
				...state,
				...getEmptyColumnsState(),
			};
		case "SET_PLOT_SELECTION_STATE_FROM_GRAPH_CARD": {
			const {
				plotSelectionFormInfo,
				rasterConfig,
				graphCardInfo,
				activeTabInfo,
			} = action.payload;

			const connectedComponents = getConnectedComponentsListOfComponent(
				plotSelectionFormInfo.connectedComponentInfo.component_id,
				activeTabInfo
			);

			let updatedState: Partial<AnalyticsReducerState> = {
				initialDataForPlotSelection: plotSelectionFormInfo,
				...getEmptyColumnsState(),
				rasterSelectionInfo: null,
				connectedComponents,
			};

			let bandType = BandType.Single;
			const rasterSelectionInfo: AnalyticsReducerState["rasterSelectionInfo"] = parseRasterSelectionInfoAction(
				{ rasterConfig, graphCardInfo }
			);

			updatedState.rasterSelectionInfo = rasterSelectionInfo;
			const __initialData = omit(
				plotSelectionFormInfo,
				"xAxis",
				"yAxis",
				"groupByColumns",
				"graphIdentifier",
				"bandType"
			);

			// Existing raster can only be edited when this action is called
			if (plotSelectionFormInfo.plot_type === "raster") {
				// rasterSelectionInfo?.type === "edit" is checked inside to make sure columns are not added 
				// if raster type is new
				if (
					rasterSelectionInfo?.type === "edit") {
					const rasterState = convertRasterSelectionInfoToInitialValues(
						rasterSelectionInfo
					);

					updatedState = {
						...updatedState,
						...rasterState.updatedState,
					};

					bandType = rasterState.bandType;
				}
			} else {
				if (plotSelectionFormInfo.xAxis)
					updatedState.keys = [plotSelectionFormInfo.xAxis];
				if (plotSelectionFormInfo.yAxis)
					updatedState.values = plotSelectionFormInfo.yAxis;
				if (plotSelectionFormInfo.groupByColumns)
					updatedState.seriesGroupings =
						plotSelectionFormInfo.groupByColumns;
			}

			updatedState.initialValuesForPlotSelectionForm = {
				...__initialData,
				bandType,
			};

			return { ...state, ...updatedState };
		}
		case "SET_RASTER_SELECTION_INFO": {
			const rasterSelectionInfo = parseRasterSelectionInfoAction(
				action.payload
			);

			if (rasterSelectionInfo) {
				const updatedState: Partial<AnalyticsReducerState> = {};

				const rasterState = convertRasterSelectionInfoToInitialValues(
					rasterSelectionInfo
				);

				updatedState.rasterSelectionInfo = rasterSelectionInfo;

				return {
					...state,
					rasterSelectionInfo,
					...rasterState.updatedState,
					initialValuesForPlotSelectionForm: {
						...state.initialValuesForPlotSelectionForm,
						bandType: rasterState.bandType,
						// _id: Math.random() is added for formik to force initialize the initialValues
						_id: Math.random(),
					},
					// reset column errors
					columnErrors: {},
				};
			}
			return state;
		}
		case "RESET_PLOT_SELECTION_STATE":
			return {
				...state,
				...getEmptyColumnsState(),
				initialDataForPlotSelection: null,
				initialValuesForPlotSelectionForm: getInitialValuesForPlotSelectionForm(),
				connectedComponents: [],
			};
		case "SET_PLOT_SELECTION_STATE_OF_NEW_PLOT": {
			const { componentId, activeTabInfo } = action.payload;
			const connectedComponents = getConnectedComponentsListOfComponent(
				componentId,
				activeTabInfo
			);

			let connectedComponentInfo = getInitialValuesForPlotSelectionForm()
				.connectedComponentInfo;

			if (!isEmpty(connectedComponents)) {
				connectedComponentInfo = connectedComponents[0].value;
			}

			return {
				...state,
				...getEmptyColumnsState(),
				initialDataForPlotSelection: null,
				initialValuesForPlotSelectionForm: {
					...getInitialValuesForPlotSelectionForm(),
					connectedComponentInfo,
				},
				connectedComponents,
			};
		}
		case "SET_PLOT_SELECTION_STATE_OF_NEW_PLOT_DATAEXPLORER": {
			return {
				...state,
				connectedComponents: [
					{
						"label": "Output1",
						"value": {
							"output": "read_rdbms_0_0",
							"component_id": "10e1557f-1baa-435d-96fe-4d704457a0ea",
							"target_component_id": "eeb14611-f563-46af-ad92-ba52569aab9c",
						}
					}
				],
				initialValuesForPlotSelectionForm: {
					"_id": 0.42364824980991855,
					"plot_type": "scatter",
					"aggregate_type": "",
					"connectedComponentInfo": {
						"output": "read_rdbms_0_0",
						"component_id": "10e1557f-1baa-435d-96fe-4d704457a0ea",
						"target_component_id": "eeb14611-f563-46af-ad92-ba52569aab9c",
					},
					"additional_params": {},
					"bandType": BandType.Single,
					"projection_code": "None",
					"custom_projection_code": "",
					"resolution":"",
					"mode": "default",
                    "min_lat": "",
                    "max_lat": "",
                    "min_lon": "",
                    "max_lon": "",
				}
			}
		}
		case "REOPEN_PLOT_SELECTION_IF_CLUSTER_SELECTION_FAILS": {
			const { formValues, columnSelectionInfo } = action.payload;

			return {
				...state,
				...columnSelectionInfo,
				initialValuesForPlotSelectionForm: formValues,
			};
		}
		default:
			return state;
	}
};

const AnalyticsContext = createContext<AnalyticsContextType>(
	{} as AnalyticsContextType
);

const AnalyticsProvider: React.FC<{}> = ({ children }) => {
	const [state, dispatch] = useReducer(
		analyticsReducer,
		analyticsReducerInitialState
	);

	const _analyticsContext: AnalyticsContextType = useMemo(() => {
		return {
			...state,
			sendAnalyticsReducerCmd: dispatch,
		};
	}, [state, dispatch]);

	return (
		<AnalyticsContext.Provider value={_analyticsContext}>
			{children}
		</AnalyticsContext.Provider>
	);
};

const useAnalyticsContext = () => {
	return useContext(AnalyticsContext);
};

export { AnalyticsProvider, useAnalyticsContext };
// export default analyticsReducer;
