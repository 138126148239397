import React from 'react';
import { Button, Checkbox } from 'antd';
import indicator from './classes/indicator';

const Buttons = props => {
    //   <Button.Group basic size="small">

    const deleteTabIndex = () => {
        [
            ...document.querySelectorAll('[data-react-beautiful-dnd-drag-handle]'),
        ].map(elem => elem.removeAttribute('tabindex'));
    };

    const { query, rowIndex, updateQueryState, type, joinColumnIndex } = props;

    const handleAddJoinRowClick = () => {
        query.from.handleAddJoinRowClick(rowIndex);

        deleteTabIndex();
        updateQueryState();
        setTimeout(()=>{
            indicator.fromIndicator();
        }, 100 );
    };

    const handleRemoveJoinRowClick = () => {
        query.from.handleRemoveJoinRowClick(rowIndex);

        updateQueryState();
        setTimeout(()=>{
            indicator.fromIndicator();
        }, 100 );
    };

    const handleAddJoinConditionClick = () => {
        query.from.handleAddJoinConditionClick(rowIndex, joinColumnIndex);

        deleteTabIndex();
        updateQueryState();
        setTimeout(()=>{
            indicator.fromIndicator();
        }, 100 );
    };

    const handleRemoveJoinConditionClick = () => {
        query.from.handleRemoveJoinConditionClick(rowIndex, joinColumnIndex);

        updateQueryState();
        setTimeout(()=>{
            indicator.fromIndicator();
        }, 100 );
    };

    const handleAddSelectRowClick = () => {
        query.select.handleAddClick(rowIndex);

        deleteTabIndex();
        updateQueryState();
    };

    const handleAddGroupByRowClick = () => {
        query.groupby.handleAddClick(rowIndex);

        deleteTabIndex();
        updateQueryState();
    };

    const handleRemoveSelectRowClick = () => {
        query.select.handleRemoveClick(rowIndex);

        updateQueryState();
        setTimeout(()=>{
            indicator.selectIndicator();
        }, 100 );
    };

    const handleRemoveGroupByRowClick = () => {
        query.groupby.handleRemoveClick(rowIndex);

        updateQueryState();

        setTimeout(()=>{
            indicator.groupbyIndicator();
        }, 100 );
    };

    const handleAddWhereRowClick = () => {
        query.where.handleAddClick(rowIndex);

        deleteTabIndex();
        updateQueryState();
    };

    const handleRemoveWhereRowClick = () => {
        query.where.handleRemoveClick(rowIndex);

        updateQueryState();
        setTimeout(()=>{
            indicator.whereIndicator();
        }, 100 );
    };

    let addFunc = null;
    let removeFunc = null;
    let len = null;
    if (type === 'fromJoinRow') {
        addFunc = handleAddJoinRowClick;
        removeFunc = handleRemoveJoinRowClick;
        len = query.from.fromJoinRows.length;
    } else if (type === 'joinCondition') {
        addFunc = handleAddJoinConditionClick;
        removeFunc = handleRemoveJoinConditionClick;
        len = query.from.fromJoinRows[rowIndex].joinColumns.length;
    } else if (type === 'selectRow') {
        addFunc = handleAddSelectRowClick;
        removeFunc = handleRemoveSelectRowClick;
        len = query.select.selectRows.length;
    } else if (type === 'whereRow') {
        addFunc = handleAddWhereRowClick;
        removeFunc = handleRemoveWhereRowClick;
        len = query.where.whereRows.length;
    } else if (type === 'groupbyRow') {
        addFunc = handleAddGroupByRowClick;
        removeFunc = handleRemoveGroupByRowClick;
        len = query.groupby.groupbyRows.length;
    }

    return (
        <div style={{ verticalAlign: 'top' }}>
            { (type === 'joinCondition' && !query.geoSpatial.isGeoSpatialEnabled) ? (<Button
                onClick={addFunc}
                className="addButtonIcon"
                id="custom-btn"
                shape="circle"
                size="small"
                style={{
                    marginRight: '5px'
                }}
                icon="plus"
            />) : null }
            {(len > 1 && !query.geoSpatial.isGeoSpatialEnabled)? (
                <Button
                    onClick={removeFunc}
                    id="custom-btn"
                    className="removeButtonIcon"
                    shape="circle"
                    style={{
                        marginRight: '5px'
                    }}
                    size="small"
                    icon="close"
                />
            ) : null}
        </div>
    );
};

export default Buttons;
