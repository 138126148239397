import { _selectoptionType } from '../../components/form/select-field';

export const TOGGLE_DOC_SPINNER = 'TOGGLE_DOC_SPINNER';
export const TOGGLE_SHOW_HELP = 'TOGGLE_SHOW_HELP';
export const SET_DOCS_LIST = 'SET_DOCS_LIST';
export const SET_ACTIVE_HELP_DOC = 'SET_ACTIVE_HELP_DOC';
export const SET_DEMO_WORKFLOWS_HELP_DOC_LIST = 'SET_DEMO_WORKFLOWS_HELP_DOC_LIST';
export const TOGGLE_HELP_BULB_ANIMATION = 'SHOW_HELP_BULB_ANIMATION';
export const SET_DEMO_WORKFLOWS_ACTIVE_TAB = 'SHOW_HELP_BULB_ANIMATION';

interface HelpActions {
    type: typeof TOGGLE_DOC_SPINNER | typeof SET_DOCS_LIST 
        | typeof SET_ACTIVE_HELP_DOC  | typeof TOGGLE_SHOW_HELP 
        | typeof SET_DEMO_WORKFLOWS_HELP_DOC_LIST
        | typeof TOGGLE_HELP_BULB_ANIMATION;
    payload: any;
}

export type HelpActionTypes = HelpActions;

export interface HelpReducerState {
    docsList: string[];
    activeHelpDoc: null | { title: string; data: string; subHeadingSlug?: string };
    showDocSpinner: boolean;
    toggleHelpTab: boolean;
    helpDemoWorkflowsInfo: {headings: _selectoptionType[]; data: string};
    showHelpBulbAnimation: boolean;
} 