import React from "react";
import { Slider as AntDSlider } from 'antd';
import { SliderProps as AntDSliderProps } from "antd/lib/slider";
import clsx from "classnames";


type SliderProps = AntDSliderProps & {
    label ?: string;
    min: number;
    max: number;
    step?: number;
}

const Slider: React.FC<SliderProps> = ({ label, min, max, step, className, ...props }) => {
    return(
    <div>
        <label 
            className="inputfield__label"
        >
            <span>{label}</span>
        </label>
        <div className={clsx("slider__container", className)}>
            <span>{min}</span>
            <AntDSlider 
                className={"custom-slider"}
                min={min}
                max={max}
                step={step}
                {...props}
            />
            <span>{max}</span>
        </div>
    </div>)
}

export { Slider };