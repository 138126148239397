import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { WorkflowReducer } from './workflow';
import { AccountReducer } from './account';
import { AnalyticsFlowReducer } from './analytic-flow';
import { AzkabanReducer } from './azkaban-data';
import { CommonReducer } from './common';
import { BreadCrumbReducer } from './breadcrumb';
import { AdminReducer } from './admin';
import { CronExpressionReducer } from './cron';
import { NewDataSourceReducer } from './datasources';
import { CanvasReducer } from './canvas';
import { AnalyticsReducer } from './analytics';
import { TagBrowserReducer } from './tag-browser';
import { UserManagementReducer } from './admin';
import { HelpReducer } from './help';
import {  ClusterReducer } from './cluster';
import LogRocket from 'logrocket';
import { Env } from '../constants/settings';
import { middlewareToSaveActiveWorkflowInfo } from './middleware';
import { CoachMarkReducer } from './coachMark';

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const rootReducer = combineReducers({
    WorkflowReducer,
    AccountReducer,
    AnalyticsFlowReducer,
    AzkabanReducer,
    CommonReducer,
    BreadCrumbReducer,
    AdminReducer,
    CronExpressionReducer,
    NewDataSourceReducer,
    CanvasReducer,
    AnalyticsReducer,
    TagBrowserReducer,
    UserManagementReducer,
    HelpReducer,
    ClusterReducer,
    CoachMarkReducer,
});


export const store = Env.enableLogRocket
    ? createStore(
        rootReducer,
        undefined,
        composeEnhancers(applyMiddleware(thunk, LogRocket.reduxMiddleware(), middlewareToSaveActiveWorkflowInfo))
    )
    : createStore(
        rootReducer,
        undefined,
        composeEnhancers(applyMiddleware(thunk, middlewareToSaveActiveWorkflowInfo))
    );
