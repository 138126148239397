import React from 'react'
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts-latest/core';
import {
    CanvasRenderer,
    SVGRenderer,
} from 'echarts-latest/renderers';
import { TooltipComponent, LegendComponent } from "echarts-latest/components";
import {
    GraphChart
} from 'echarts-latest/charts';
import styles from './../styles.module.scss';

export type ChartsProps = {
    graphData: any,
    handleEdgeData: (e: any, i: number) => any;
    unionOrIntersect?: string;
    showLegends?: boolean;
    Key: string;
};

const GraphCharts: React.FC<ChartsProps> = ({
    graphData,
    handleEdgeData,
    unionOrIntersect,
    showLegends,
    Key
}) => {
    echarts.use(
        [SVGRenderer, CanvasRenderer, TooltipComponent, LegendComponent, GraphChart,]
    );
    return <div key={Key} className={styles["queryGraphContainer"]}><ReactEChartsCore
        echarts={echarts}
        style={{
            width: "300vw",
            height: '80vh',
            padding: 10,
        }}
        notMerge={true}
        lazyUpdate={false}
        onEvents={
            {
                'click': handleEdgeData
            }
        }
        option={
            {
                color: [
                    "#808080",
                    "#ea7ccc",
                    "#ff8a45",
                    "#05c091",
                    "#fddd60",
                ],
                backgroundColor: "#100C2A",
                tooltip: {
                    show: true,
                    trigger: 'item',
                    formatter: function (params: any) {
                        return params.dataType === "edge" ? (params.data?.value || params.data?.join) : params.data.name;
                    },
                },
                animationDuration: 1500,
                animationEasingUpdate: 'quinticInOut',
                textStyle: {
                    fontSize: 16
                },
                legend: showLegends ? [
                    {
                        top: 10,
                        left: "right",
                        orient: 'vertical',
                        textStyle: {
                            color: "#fff",

                        },
                        selectedMode: false,
                        data: graphData?.categories.map(function (a: any) {
                            return a.name;
                        })
                    }
                ] : [],
                series: [
                    {
                        name: 'Data Explorer Charts',
                        type: 'graph',
                        layout: 'none',
                        data: graphData?.nodes,
                        links: graphData?.links,
                        categories: graphData?.categories,
                        lineStyle: {
                            color: 'source',
                            curveness: 0.3
                        },
                        roam: true,
                        scaleLimit: {
                            min: 0.4,
                            max: 2
                        },
                        labelLayout: {
                            hideOverlap: true
                        },
                        draggable: true,
                        label: {
                            show: true,
                            position: 'top',
                            formatter: '{b}',
                        },
                    }
                ]
            }
        }
    />
        {unionOrIntersect && <div>
            {unionOrIntersect}
            <div className={styles["divider"]} />
        </div>}


    </div>
}

export default React.memo(GraphCharts);