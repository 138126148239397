import { FormikHelpers, useFormikContext } from 'formik';
import { cloneDeep, get, has, isArray, isEmpty, omit, set, trim, union } from 'lodash';
import { _selectoptionType } from '../../form/select-field';
import { ModeOptions, PipelineCategoryOptions, ValidateModelOptions, OperationOptions, ScalerTypes, ExtraOperationOptions, StandardHyperParams, ClassificationTypes, RegressionTypes, ClusteringTypes, GeoSpatialParams, ReduceDimensionsParams, EvalMetricClassOptions, EvalMetricRegOptions, MinMaxHyperParams, OutputFormatOptions, getTrainModelKey, StopWordsRemoverParams, CountVectorizerParams, IDFVectorizerParams, NGramParams, BinarizerParams, HashingTransformerParams, RegexTokenizerParams, StemmerParams, ModelLibraryOptions, PREPROCESSING, PythonModelLibraryOptions, SparkModelLibraryOptions, MlPipelineStageKeys, TensorflowExtraModelParams, inputTypeFieldName, InputTypeOptions, SplitForTestTypes, SkLearnPreProcessingOptions, getDefaultCheckboxOptions, HyperParamsInfo, TuneTypes, TensorflowLayers} from './enums';
import * as yup from 'yup';
import { FieldSchemaCreator } from '../schema-creator';
import { MlPipelineStageInfo, MlPipelineStages, ModelParamStageInfo, ModelParamStages } from './build-pipeline-modal/build-pipeline-RHS';
import { PythonModelsInfo, PythonSingleModelParamInfo, PythonSingleModelTypeInfo, PythonSingleModelTypeInfoOptions, SklearnModelsInfo, TensorflowModelsInfo } from './build-pipeline-modal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/types';
import { ExecutionEnvModes } from '../../../constants/enums';
import { useMemo } from 'react';
import { uuid } from 'uuidv4';
import { getHyperParamFieldKey } from './build-pipeline-modal/hyper-params-table';
import { PythonModelHyperParamsResponse, PythonPipelineCategoryResponse, PythonTensorflowModelParamsResponse } from '@api/mlflow-handler';

export const getStageKey = (id: string) => `stage_${id}`;
export const getKeyInPipelineStage = (key: string, id: string) => `${getStageKey(id)}.${key}`;
export const getValueInPipelineStage = (values: object, key: string, id: string) => get(values, getKeyInPipelineStage(key, id));

export type CurrentModelLibrarySelection = { 
    activePipelineCategory?: PipelineCategoryOptions; 
    modelTypeSelection?: Record<Partial<PipelineCategoryOptions>, string>; 
    preprocessorSelection?: Record<SkLearnPreProcessingOptions, string>;
    pythonModuleInfo: {
        [stageId: string]: Record<Partial<PipelineCategoryOptions | SkLearnPreProcessingOptions>, string>;
    };
}

    
/** STORE MODEL_TYPE AND PIPELINE CATEGORY W.R.T MODEL LIBRARY 
 * 
 * Initially, pipeline_category: Classification and model_type: Random Forest Classification is shown
 * 
 * User selects model_type: Decision Tree Classifcation and makes some changes
 * 
 * User switches to pipeline_category: Regression -> model_type is changed to Random Forest Regression
 * 
 * User switches back to Classification, Decision Tree Classification has to be shown 
 * 
 * if user switches to sklearn, classification and Adaboostclassifier is shown
 * 
 * When user switches to Spark, Decision Tree has to be shown
 * 
 * To store this info w.r.t each model library, this is used
 * 
 * @returns spark__current_selection 
 * 
 * Value - { activePipelineCategory: Regression, modelTypeSelection: {Regression: Random Forest Regressor, Classification: Linear SVM...} }
 * 
 * On switching pipeline category, this value is checked
 */
export const getModelLibrarySelectionKey = (type: ModelLibraryOptions): string =>
    type + '__current_selection'; 

export const getOldFormatPythonModuleKey = (type: ModelLibraryOptions, pipelineCategory: PipelineCategoryOptions) => 
    getModelLibrarySelectionKey(type) + '.pythonModuleInfo.' +pipelineCategory; 

export const getCurrentPythonModuleKey = (type: ModelLibraryOptions, stageId: string, pipelineCategory: PipelineCategoryOptions | SkLearnPreProcessingOptions) => 
    getModelLibrarySelectionKey(type) + '.pythonModuleInfo.' + stageId + '.' +pipelineCategory; 

export const getCurrentModelTypeSelection = (modelLibrarySelection: any, pipelineCategory: PipelineCategoryOptions) => 
    get(modelLibrarySelection, 'modelTypeSelection.'+pipelineCategory);


export const isSklearnPreprocessor = (preprocessor: any) => (Object.keys(SkLearnPreProcessingOptions).indexOf(preprocessor) !== -1);

type PreprocessorSelection = {
    preprocessorOperation: SkLearnPreProcessingOptions; 
    preprocessorType: string;
    stageId: string;
    pythonModuleInfo: string;
}

type ModelTypeSelection = {
    pipelineCategory: PipelineCategoryOptions;
    modelType: any;
    stageId: string;
    pythonModuleInfo: string;
}

type PipelineSelection = {
    pipelineCategory: PipelineCategoryOptions;
}


type CommonSelectionProps = {
    values: any; 
    setFieldValue: any; 
    modelLibrary: ModelLibraryOptions; 
}

type handleSetModelLibrarySelection = CommonSelectionProps & Partial<PreprocessorSelection> & Partial<ModelTypeSelection>

export function handleSetModelLibrarySelection(key: 'pipelineCategory', params: CommonSelectionProps & PipelineSelection): void;
export function handleSetModelLibrarySelection(key: 'modelType', params: CommonSelectionProps & ModelTypeSelection): void;
export function handleSetModelLibrarySelection(key: 'preprocessorType', params: CommonSelectionProps & PreprocessorSelection): void;
export function handleSetModelLibrarySelection(key: 'pipelineCategory' | 'modelType' | 'preprocessorType', { values, setFieldValue, modelLibrary, pipelineCategory, preprocessorType, preprocessorOperation, modelType, pythonModuleInfo, stageId }: handleSetModelLibrarySelection): void{
    const modelLibrarySelectionKey = getModelLibrarySelectionKey(modelLibrary);
    const modelLibrarySelectionValue: CurrentModelLibrarySelection = cloneDeep(get(values, modelLibrarySelectionKey));
    if(modelLibrarySelectionValue) {
        if(key === 'modelType') {
            set(modelLibrarySelectionValue, 'modelTypeSelection.' + pipelineCategory, modelType);
            set(modelLibrarySelectionValue, 'pythonModuleInfo.' + stageId + '.' + pipelineCategory, pythonModuleInfo);
            
        } else if(key === 'pipelineCategory') {
            modelLibrarySelectionValue['activePipelineCategory'] = pipelineCategory;
        } else {
            set(modelLibrarySelectionValue, 'preprocessorSelection.' + preprocessorOperation, preprocessorType);
            // console.log(preprocessorOperation, 'pythonModuleInfo.' + stageId + '.' + preprocessorOperation)
            set(modelLibrarySelectionValue, 'pythonModuleInfo.' + stageId + '.' + preprocessorOperation, pythonModuleInfo);
            
        }
        setFieldValue(modelLibrarySelectionKey, modelLibrarySelectionValue);
    }
}

export const getTensorFlowInitialModelType = (models: _selectoptionType[]) => 
    models.find(opt => opt.label === 'Dense');

type getPipelineStageInitialValuesProps = { 
    stageId: string;
    operation: OperationOptions | ExtraOperationOptions | SkLearnPreProcessingOptions;
    optionsForModelType: _selectoptionType[];
    pipelineCategory: PipelineCategoryOptions; 
    modelLibrary: ModelLibraryOptions; 
    currentModelLibrarySelection:  CurrentModelLibrarySelection;
    stageInfo: MlPipelineStageInfo;
    sklearnPreprocessorOptions: _selectoptionType[];
}


export const getPipelineStageInitialValues = ({ stageId, stageInfo, operation, optionsForModelType, pipelineCategory, modelLibrary, currentModelLibrarySelection, sklearnPreprocessorOptions }: getPipelineStageInitialValuesProps) => {
    //  "stage_228592f6-8cbc-4e22-a588-71e044e59a37.reduce_dimensions_hyperparams.k.value"
    // To access hyperparam value - "stage_{stage_id}.{hyperParamsKey}.{hyperParamName}.value"
    const _getKey = (name: string) => getKeyInPipelineStage(name, stageId);
    // const _getKey = (name: string) => name


    /**
     * This is used to set value directly in stageInfo 
     */
    const getHyperParamValueKey = (hyperParamsKey: string, hyperParamName: string) => {
        return `${hyperParamsKey}.${hyperParamName}.value`;
    };

    /**
     * This is used to set value in initial values which needs stage id as well 
     */
    const getHyperParamValueKeyWithStageInfo = (hyperParamsKey: string, hyperParamName: string) => {
        return getKeyInPipelineStage(`${hyperParamsKey}.${hyperParamName}.value` ,stageId);
    };

    /**
     * VALUES RELATED TO THE STAGE ARE DIRECTLY UPDATED IN THE CURRENT STAGE VALUES
     * This is done as current stage is losing its state and initialized again when there's an operation change
     * But VALUES OF DROPDOWNS ARE SET USING INITIAL VALUES 
     */
    const setStageInfo = (key: string, value: any) => {
        set(stageInfo, key, value);
    };
    

    const __initialValues: Record<string, any> = {};
    const __initialValuesOfDropdowns: Record<string, any> = {};
    setStageInfo('operation', operation);
    switch(operation){
        case OperationOptions.Scale:


            set(__initialValuesOfDropdowns, _getKey('scalerType'), ScalerTypes.Standard);
            set(__initialValuesOfDropdowns, _getKey(MinMaxHyperParams.key+'.selectedHyperParams'), { [MinMaxHyperParams.info[0].name]: false, [MinMaxHyperParams.info[1].name]: false });
            
            setStageInfo(getHyperParamValueKey(StandardHyperParams.key, StandardHyperParams.info[0].name), 'false');
            setStageInfo(getHyperParamValueKey(StandardHyperParams.key, StandardHyperParams.info[1].name), 'false');
            break;
        case OperationOptions['Reduce Dimensions']:
            set(__initialValuesOfDropdowns, _getKey(ReduceDimensionsParams.key+'.selectedHyperParams'), { [ReduceDimensionsParams.info[0].name]: true });
            setStageInfo(getHyperParamValueKey(ReduceDimensionsParams.key, ReduceDimensionsParams.info[0].name), 5);
            break;
        case ExtraOperationOptions['Feature Vectorizers']:
            set(__initialValuesOfDropdowns, _getKey(BinarizerParams.key+'.selectedHyperParams'), { [BinarizerParams.info[0].name]: true });
            setStageInfo(getHyperParamValueKey(BinarizerParams.key, BinarizerParams.info[0].name), 0.5);

            set(__initialValuesOfDropdowns, _getKey(CountVectorizerParams.key+'.selectedHyperParams'), { [CountVectorizerParams.info[1].name]: true });
            setStageInfo(getHyperParamValueKey(CountVectorizerParams.key, CountVectorizerParams.info[1].name), 2);
            
            set(__initialValuesOfDropdowns, _getKey(HashingTransformerParams.key+'.selectedHyperParams'), { [HashingTransformerParams.info[0].name]: true });
            setStageInfo(getHyperParamValueKey(HashingTransformerParams.key, HashingTransformerParams.info[0].name), 32);
            setStageInfo(getHyperParamValueKey(HashingTransformerParams.key, HashingTransformerParams.info[1].name), 'false');
            
            set(__initialValuesOfDropdowns, _getKey(IDFVectorizerParams.key+'.selectedHyperParams'), { [IDFVectorizerParams.info[0].name]: true });
            setStageInfo(getHyperParamValueKey(IDFVectorizerParams.key, IDFVectorizerParams.info[0].name), 2);
            
            break;
        case ExtraOperationOptions['Text Processors']:
            set(__initialValuesOfDropdowns, _getKey(NGramParams.key+'.selectedHyperParams'), { [NGramParams.info[0].name]: true });
            setStageInfo(getHyperParamValueKey(NGramParams.key, NGramParams.info[0].name), 2);

            set(__initialValuesOfDropdowns, _getKey(RegexTokenizerParams.key+'.selectedHyperParams'), { [RegexTokenizerParams.info[0].name]: true, [RegexTokenizerParams.info[1].name]: true, [RegexTokenizerParams.info[2].name]: true, [RegexTokenizerParams.info[3].name]: true });
            setStageInfo(getHyperParamValueKey(RegexTokenizerParams.key, RegexTokenizerParams.info[0].name), 1);
            setStageInfo(getHyperParamValueKey(RegexTokenizerParams.key, RegexTokenizerParams.info[1].name), 'true');
            setStageInfo(getHyperParamValueKey(RegexTokenizerParams.key, RegexTokenizerParams.info[2].name), '\\s+\'');
            setStageInfo(getHyperParamValueKey(RegexTokenizerParams.key, RegexTokenizerParams.info[3].name), 'true');    

            set(__initialValuesOfDropdowns, _getKey(StemmerParams.key+'.selectedHyperParams'), { [StemmerParams.info[0].name]: true, [StemmerParams.info[1].name]: true } );
            set(__initialValuesOfDropdowns, getHyperParamValueKeyWithStageInfo(StemmerParams.key, StemmerParams.info[0].name), 'English' );
            set(__initialValuesOfDropdowns, getHyperParamValueKeyWithStageInfo(StemmerParams.key, StemmerParams.info[1].name), 'Snowball' );
          
            set(__initialValuesOfDropdowns, _getKey(StopWordsRemoverParams.key+'.selectedHyperParams'), { [StopWordsRemoverParams.info[1].name]: true } );
            setStageInfo(getHyperParamValueKey(StopWordsRemoverParams.key, StopWordsRemoverParams.info[1].name), 'false' );
            break;
        case OperationOptions['Train Model']: {
            let modelType; 
            let validate_eval_metric;
            let pythonModuleInfo;
            // const selectedModelLibrary = values.model_library
            if(modelLibrary === SparkModelLibraryOptions['Spark']) {
                switch(pipelineCategory){
                    case PipelineCategoryOptions.Classification:
                        modelType = ClassificationTypes['Random Forest Classification'];
                        validate_eval_metric = EvalMetricClassOptions['Accuracy'];
                        break;
                    case PipelineCategoryOptions.Regression:
                        modelType = RegressionTypes['Random Forest Regression'];
                        validate_eval_metric = EvalMetricRegOptions['RMSE'];
                        break;
                    case PipelineCategoryOptions.Clustering:
                        modelType = ClusteringTypes['K-Means'];
                }
            } else if(!isEmpty(optionsForModelType)) {
                let initialOption = optionsForModelType[0];
                if(modelLibrary === PythonModelLibraryOptions['Tensorflow']) {
                    const denseOption = getTensorFlowInitialModelType(optionsForModelType);
                    if(denseOption) initialOption = denseOption;
                }
                modelType = initialOption.value;
                pythonModuleInfo = initialOption.pythonModule;
            }
            set(__initialValuesOfDropdowns, _getKey('model_type'), modelType);

            const modelTypeSelection = currentModelLibrarySelection?.modelTypeSelection ? currentModelLibrarySelection.modelTypeSelection: { } as Record<PipelineCategoryOptions, string>;
            
            const currentPythonModuleInfo = currentModelLibrarySelection?.pythonModuleInfo || {};
            set(currentPythonModuleInfo, stageId + '.' + pipelineCategory, pythonModuleInfo);
            // TO SET CURRENT MODEL LIBRARY SELECTION
            // refer handleSetModelLibrarySelection
            const __currentModelLibrarySelection: CurrentModelLibrarySelection = {
                modelTypeSelection: { ...modelTypeSelection, [pipelineCategory]: modelType },
                activePipelineCategory: pipelineCategory,
                pythonModuleInfo: currentPythonModuleInfo 
            }; 
            set(__initialValues ,getModelLibrarySelectionKey(modelLibrary), __currentModelLibrarySelection);


            if(validate_eval_metric) {
                set(__initialValuesOfDropdowns, 'validate_eval_metric', validate_eval_metric);
            }
            break;
        } default:
            if(isSklearnPreprocessor(operation)) {
                if(!isEmpty(sklearnPreprocessorOptions)) {
                    const initialOption = sklearnPreprocessorOptions[0];
                    const sklearnPreprocessor = initialOption.value;
                    const pythonModuleInfo = initialOption.pythonModule;

                    set(__initialValuesOfDropdowns, _getKey('sklearnPreprocessor'), sklearnPreprocessor);

                    const preprocessorSelection = currentModelLibrarySelection?.preprocessorSelection || { } as Record<SkLearnPreProcessingOptions, string>;
                
                    const currentPythonModuleInfo = currentModelLibrarySelection?.pythonModuleInfo || {};
                    set(currentPythonModuleInfo, stageId + '.' + operation, pythonModuleInfo);
                    // TO SET CURRENT MODEL LIBRARY SELECTION
                    // refer handleSetModelLibrarySelection
                    const __currentModelLibrarySelection: CurrentModelLibrarySelection = {
                        preprocessorSelection: { ...preprocessorSelection, [operation]: sklearnPreprocessor },
                        pythonModuleInfo: currentPythonModuleInfo 
                    }; 
                    

                    set(__initialValues ,getModelLibrarySelectionKey(modelLibrary), __currentModelLibrarySelection);

                }
            }
    }



    return { __initialValues, __initialValuesOfDropdowns, stageInfo };
};

type SetInitialValuesForStageProps = {
    stageId: string; 
    operationType: OperationOptions;
    currentInitialValues: Record<string, any>; 
    currentValues: Record<string, any>; 
    setFieldValue: FormikHelpers<any>['setFieldValue'];
    optionsForModelType: _selectoptionType[];
    sklearnPreprocessorOptions?: _selectoptionType[];
    pipelineCategory?: PipelineCategoryOptions;
    modelLibrary?: ModelLibraryOptions;
}

export const setInitialValuesForStage = ({
    stageId, 
    operationType, 
    currentInitialValues,
    currentValues, 
    setFieldValue,
    optionsForModelType,
    pipelineCategory,
    modelLibrary,
    sklearnPreprocessorOptions = []
}: SetInitialValuesForStageProps) => {
    const _modelLibrary = modelLibrary ? modelLibrary: currentValues.model_library;
    const { __initialValues, __initialValuesOfDropdowns, stageInfo } = getPipelineStageInitialValues(
        { 
            stageId, 
            operation: operationType, 
            optionsForModelType, 
            pipelineCategory: pipelineCategory ? pipelineCategory: currentValues.pipeline_category,
            modelLibrary: _modelLibrary,
            currentModelLibrarySelection: get(currentValues, getModelLibrarySelectionKey(_modelLibrary)),
            stageInfo: cloneDeep(get(currentValues, 'stage_' + stageId) || {}),
            sklearnPreprocessorOptions
        } 
    );

    // VALUES RELATED TO STAGE ARE DIRECTLY UPDATED 
    setFieldValue('stage_' + stageId, stageInfo);

    Object.keys(__initialValues).forEach(key => {
        setFieldValue(key,  __initialValues[key]);
    });


    // FOR SELECT FIELDS, VALUE IS SET ON THE BASIS OF INITIAL VALUE
    Object.keys(__initialValuesOfDropdowns).forEach(key => {
        set(currentInitialValues, key, __initialValuesOfDropdowns[key]);
    });

    
};


/**
    * For single selection type
    * 
    "losses__909eb66b-35b4-471d-bf01-81c16ec6e621": {
        "type": "SparseCategoricalCrossentropy",
       "python_module": "tensorflow.keras.losses",
       "params": {
           SparseCategoricalCrossentropy: {
                "from_logits": true       
           }
       }
    },
    *
    * For Multiple selection type
    *
    "metrics__6137c60a-41ba-4eb9-afae-bd83e5fb545": {
        "type": "Accuracy",
        "python_module": "tensorflow.keras.metrics",
        "params": {
            "Accuracy": {
                "num_thresholds": 200,
                "curve": "ROC"
            }
        }
    },
    "metrics__9f8dad1f-5593-4e5a-b1bf-1e93631a04d2":{
        "type": "AUC",
        "python_module": "tensorflow.keras.metrics",
        "params": {}
    }
    *
*/
// export function getTensorflowModelParamsKey(keyType: 'params', type: TensorflowExtraModelParams, model: string): string; 
// export function getTensorflowModelParamsKey(keyType: 'type' | 'pythonModule', type: TensorflowExtraModelParams, selection: 'single'): string; 
// export function getTensorflowModelParamsKey(keyType: 'type' | 'pythonModule', type: TensorflowExtraModelParams, selection: 'multiple', index: number): string; 
export function getTensorflowModelParamsKey(stageId: string, type: TensorflowExtraModelParams, keyType: 'type' | 'python_module'): string;
export function getTensorflowModelParamsKey(stageId: string, type: TensorflowExtraModelParams, keyType: 'params', modelType: string): string;
export function getTensorflowModelParamsKey(stageId: string, type: TensorflowExtraModelParams, keyType: 'type' | 'params' | 'python_module', modelType?: string) {
    let _key = type + '__' + stageId + '.' + keyType;
    if(keyType === 'params') {
        _key += '.' + modelType;
    }
    return _key;
    // switch(keyType) {
    //     case 'params':
    //         return type + '__hyperparams__' + selectionOrModel;
    //     case 'type':
    //         if(selectionOrModel === 'multiple')
    //             return type + `[${index}].type`;
    //         return type + '.type';
    //     case 'pythonModule':
    //         if(selectionOrModel === 'multiple')
    //             return type + `[${index}].python_module`;
    //         return type + '.python_module';
    // }
}


export const showValidateModelFieldsFun = (values: any) => values.model_library !== PythonModelLibraryOptions['Tensorflow'] ?
    (values.pipeline_category === PipelineCategoryOptions.Classification 
    || values.pipeline_category === PipelineCategoryOptions.Regression)
    :
    values[inputTypeFieldName] !== InputTypeOptions['Parquet folder'];

export const showValidateModelInnerFieldsFun = (values: any) => !!(values.validate_model) && !(values.model_library === PythonModelLibraryOptions['Tensorflow'] && values[inputTypeFieldName] !== InputTypeOptions['Default (dataframes)']);
export const showSplitForTestTypesFun = (values: any) => values.model_library === PythonModelLibraryOptions['Tensorflow'] && values[inputTypeFieldName] !== InputTypeOptions['Default (dataframes)'];


const __mlPipelineFunctions = { 
    [ModeOptions['Fit Data']]: 'pipeline.fit',
    [ModeOptions['Fit and Transform Data']]: 'pipeline.fit_and_transform',
    [ModeOptions['Transform Data']]: 'pipeline.transform',
    [ModeOptions['Upload Model']]: 'pipeline.upload',
};


export const getMlPipelineFunction = (mode: keyof typeof __mlPipelineFunctions) => (__mlPipelineFunctions[mode]);

export const getPresetParams = (values: any) => {
    if(isEmpty(values)) {
        return {};
    }
    if(values.mode === ModeOptions['Upload Model']) {
        return {
            file_location: values.file_location,
            connection_type: values.connection_type,
            connection_name: values.connection_name,
            uname: values.uname,
            passwd: values.passwd,
            token_type: values.token_type,
            fs_type: values.file_type,
            pipeline_name: values.pipeline_name,
            model_library: values.model_library,
            exp_name: values.exp_name,
            experiment: values.experiment_info,
        }
    }
    let preset_params: any = {
        user_name: values.extraData.username,
        workflow_name: values.extraData.workflowName,
        mode: values.mode,
        experiment: values.experiment_info,
        features: values.features ? values.features.split(',').map((t: string) => trim(t)) : [],
        pipeline_category: values.build_model === 'true' ?  
            values.pipeline_category === PipelineCategoryOptions['Layers'] ?
                'keras_sequential': values.pipeline_category
            : 
            PREPROCESSING,
        model_lib: values.model_library,
    };
    if(values.model_library === PythonModelLibraryOptions['Tensorflow']) {
        preset_params = {
            ...preset_params,
            encode_categ_features: false,
            num_epochs: parseInt(values.num_epochs),
            label: values.label_col,
            [inputTypeFieldName]: values[inputTypeFieldName],
            batch_size: parseInt(values.batch_size),
            train_steps_per_epoch: null,
            validation_steps_per_epoch: null
        };
        if(values.train_steps_per_epoch !== '') {
            preset_params.train_steps_per_epoch = parseInt(values.train_steps_per_epoch); 
        }
        if(values.validation_steps_per_epoch !== '') {
            preset_params.validation_steps_per_epoch = parseInt(values.validation_steps_per_epoch); 
        }
        if(values.seed !== '') {
            preset_params.seed = values.seed;
        }
        if(values[inputTypeFieldName] !== InputTypeOptions['Default (dataframes)']) {
            preset_params = omit(preset_params, 'features', 'label');
            preset_params.file_path = values.file_path;
            preset_params.label_mode = values.label_mode;
            if(values[inputTypeFieldName] === InputTypeOptions['Image folder']) {
                preset_params.color_mode = values.color_mode;
            }      

            if(values[inputTypeFieldName] === InputTypeOptions['Numpy array folder']) {
                preset_params = {
                    ...preset_params,
                    use_generator: values.use_generator === 'true',
                    balance_classes: values.balance_classes === 'true',
                    balance_mode: values.balance_mode,
                };

                if(preset_params.use_generator) {
                    preset_params.balance_batches = values.balance_batches === 'true';
                    preset_params.sample_from_datasets = false;
                } else {
                    preset_params.balance_batches = false;
                    preset_params.sample_from_datasets = values.sample_from_datasets === 'true';
                }
            }

            if(values[inputTypeFieldName] === InputTypeOptions['Parquet folder']) {
                preset_params = {
                    ...preset_params,
                    balance_batches: values.balance_batches === 'true',
                    image_col: values.image_column,
                    label_col: values.label_column,
                    image_size: values.image_size,
                    dataset_type: values.dataset_type,
                    auto_resize: values.auto_resize === 'true',
                    features: values.features,
                    train_data_filters: values.train_data_filters
                };

                if(values.mode === ModeOptions['Fit Data']) {
                    preset_params.prediction_col = values.prediction_col;
                }

                if(values.mode !== ModeOptions['Fit Data']) {
                    preset_params.required_columns = values.required_columns
                }

                if(values.data !== ModeOptions['Transform Data']) {
                    preset_params.validation_data_filters = values.validation_data_filters;
                }
            }
        } 
    }
    else {
        preset_params = { 
            ...preset_params,
            encode_categ_features: values.encode_categ_features === 'true',
            encode_categ_target: values.encode_categ_target === 'true',
            show_intermediate_values: values.show_intermediate_values === 'true'
        };
    } 
    if(values.mode ===  ModeOptions['Fit and Transform Data']) {
        if(values.build_model !== 'true' || values.show_intermediate_values === 'true') {
            preset_params.output_format = values.output_format;
        }
        if(values.build_model === 'true') {
            preset_params.prediction_col = values.prediction_col;
        } else if(
            values.build_model !== 'true' &&
            values.output_format === OutputFormatOptions['Vectorized']
        ) {
            preset_params.output_col = values.output_col;
        }
    }

    if(values.split_for_test) {
        if(showSplitForTestTypesFun(values) && values.split_for_test_type === SplitForTestTypes['Split By Pattern']) {
            preset_params.validation_files_pattern = values.validation_files_pattern;
            preset_params.train_files_pattern = values.train_files_pattern;
        } else {
            preset_params.train_test_split = {
                train_ratio: parseFloat(values.split_for_test_train)
            };
        }
    }

    if(showValidateModelFieldsFun(values)) {
        preset_params.validate_model = !!values.validate_model;
        if(showValidateModelInnerFieldsFun(values)) {
            preset_params.train_eval_split = {
                type: values.validate_model_type,
                eval_metric: values.validate_eval_metric
            };
            if(values.validate_model_type === ValidateModelOptions['Cross-Validation'] ) {
                preset_params.train_eval_split.num_folds = parseInt(values.validate_num_folds);
            } else {
                preset_params.train_eval_split.train_ratio = parseFloat(values.split_for_test_train);
            }
        }
    }
    return preset_params;
};

export const splitAndConvertToBoolean = (p: string) => {
    if(typeof(p) === 'string')
        return (p || '').split(',').map(s => s === 'true');
    return p;
};

export const splitAndConvertToFloat = (p: string) => {
    if(typeof(p) === 'string')
        return (p || '').split(',').map(s => parseFloat(s));
    return p;
};

export const splitString = (p: string) => {
    if(typeof(p) === 'string')
        return (p || '').split(',');
    return p;
};


const getPreprocessingTrainStages = (preprocessingStages: MlPipelineStageInfo[], trainModelStages: MlPipelineStageInfo[]) => {
    if(preprocessingStages.length !== 0 && preprocessingStages[0].touched === false) {
        // Only train model is selected
        return trainModelStages;
    } else {
        return union(preprocessingStages.filter(st => st.operation !== null), trainModelStages);
    }
};

const getSelectedHyperParams = (value: any) => (
    value?.selectedHyperParams ? 
        Object.keys(omit(value, 'selectedHyperParams'))
            .filter((hyperParamKey) => value.selectedHyperParams[hyperParamKey]) 
        : 
        []
);

export const getIsTuneEnabledInfo = (values: any) => 
    (values.model_library === SparkModelLibraryOptions['Spark'] ?
        values.pipeline_category !== PipelineCategoryOptions['Clustering']
        :
        values.model_library === PythonModelLibraryOptions['Sklearn'])
    && !!(values.validate_model);


export const getPipelineParams = (values: any) => {
    if(isEmpty(values)) {
        return {};
    }

    const isTuneEnabled = getIsTuneEnabledInfo(values);

    const parseHyperParams = (getValue: (key: string) => any, hyperParamCategorykey: string, stageInfo: MlPipelineStageInfo) => {
        const hyperParamsList = getSelectedHyperParams(getValue(hyperParamCategorykey));
        hyperParamsList.forEach((hyperParam) => {
            // REFER TO BUILD-PIPELINE TO UNDERSTAND KEYS
            const hyperParamInfo = getValue(hyperParamCategorykey+'.' + hyperParam); 
            if(hyperParamInfo.value !== undefined && hyperParamInfo.value !== '') {
                let __value = hyperParamInfo.value;
                if(isTuneEnabled && hyperParamInfo.tune) {
                    if(hyperParamInfo._isFloat) {
                        __value = splitAndConvertToFloat(hyperParamInfo.value);
                    } else if(hyperParamInfo._isBoolean) {
                        // Since boolean options are stored in the list in the form of string
                        __value = splitAndConvertToBoolean(hyperParamInfo.value);
                    } else {
                        __value = splitString(hyperParamInfo.value);
                    }
                    set(stageInfo, 'tune_params.'+hyperParam, __value);
                } else {
                    if(hyperParamInfo._isFloat) {
                        __value = parseFloat(hyperParamInfo.value);
                    } else if(hyperParamInfo._isBoolean) {
                        __value = hyperParamInfo.value === 'true';
                    }
                    set(stageInfo, 'params.'+hyperParam, __value);
                }
            }
        });
    };

    let stagesInfo: MlPipelineStageInfo[] = [];
    const actualStagesInfo: MlPipelineStages = values.stagesInfo;
    if(values.build_model !== 'true') {
        const stageKey = values.model_library === PythonModelLibraryOptions['Sklearn'] ? MlPipelineStageKeys.PREPROCESSING_Sklearn: MlPipelineStageKeys.PREPROCESSING_Spark;
        // Preprocessing stages can only be set in spark/sklearn
        stagesInfo = actualStagesInfo[stageKey].filter(st => st.operation !== null);
    } else if(values.model_library === PythonModelLibraryOptions['Tensorflow']) {
        // NO PREPROCESSING STAGES FOR TENSORFLOW
        // build_model is always true in tensorflow mode
        const activeStage = MlPipelineStageKeys[PythonModelLibraryOptions['Tensorflow']];
        stagesInfo = actualStagesInfo[activeStage];
    } 
    else {
        // // Both preprocessing and spark stages
        if(values.model_library === PythonModelLibraryOptions['Sklearn']) {
            stagesInfo = getPreprocessingTrainStages(actualStagesInfo.PREPROCESSING_sklearn, actualStagesInfo.sklearn);
        } else {
            stagesInfo = getPreprocessingTrainStages(actualStagesInfo.PREPROCESSING_spark, actualStagesInfo.Spark);

        }       
    }


    const pipeline_params: Record<string, any> = {
        name: values.pipeline_name,
        stages: stagesInfo.map(stage => {
            let stageInfo: any = {};

            const getValue = (key: string) => (
                getValueInPipelineStage(values, key, stage.id)
            );

                

            switch(stage.operation) {
                case OperationOptions.Scale: {
                    const scalerType = getValue('scalerType');
                    stageInfo = {
                        name: OperationOptions.Scale,
                        type: scalerType
                    };
                    switch(scalerType) {
                        case ScalerTypes.Standard:
                            parseHyperParams(getValue, StandardHyperParams.key, stageInfo);
                            break;
                        case ScalerTypes['Min Max']:
                            parseHyperParams(getValue, MinMaxHyperParams.key, stageInfo);
                            break;
                    }
                    break;
                } case OperationOptions['Reduce Dimensions']: {
                    stageInfo = {
                        name: OperationOptions['Reduce Dimensions'],
                        type: 'PCA'
                    };
                    parseHyperParams(getValue, ReduceDimensionsParams.key, stageInfo);
                    break;
                } 
                case ExtraOperationOptions['Structured Learning']: {
                    stageInfo = {
                        name: ExtraOperationOptions['Structured Learning'],
                        type: 'GeoFeaturesSynthesizer',
                        params: {
                            lonCol: getValue('lonCol'),
                            latCol: getValue('latCol'),
                        }
                    };
                    parseHyperParams(getValue, GeoSpatialParams.key, stageInfo);
                    break;
                } case ExtraOperationOptions['Feature Vectorizers']: {
                    const featureVectorizersType = getValue(ExtraOperationOptions['Feature Vectorizers']);
                    stageInfo = {
                        name: ExtraOperationOptions['Feature Vectorizers'],
                        type: featureVectorizersType
                    };
                    // featureVectorizersType and [FeatureVectorizersType].key are same
                    parseHyperParams(getValue, featureVectorizersType, stageInfo);
                    
                    break;
                } case ExtraOperationOptions['Text Processors']: {
                    const textProcessorsType = getValue(ExtraOperationOptions['Text Processors']);
                    
                    stageInfo = {
                        name: ExtraOperationOptions['Text Processors'],
                        type: textProcessorsType
                    };
                    parseHyperParams(getValue, textProcessorsType, stageInfo);
                    break;
                }case OperationOptions['Train Model']: {
                    const model_type = getValue('model_type'); 
                    if(values.model_library === PythonModelLibraryOptions['Tensorflow']) {
                        stageInfo = {
                            name: 'layers',
                            type: model_type,
                            params: {},
                            python_module: get(values, getCurrentPythonModuleKey(PythonModelLibraryOptions['Tensorflow'], stage.id, values.pipeline_category))
                        };
                        if(!stageInfo.python_module) {
                            stageInfo.python_module = get(values, getOldFormatPythonModuleKey(PythonModelLibraryOptions['Tensorflow'], values.pipeline_category));
                        }

                        if(model_type === TensorflowLayers['Pretrained Model']) {
                            stageInfo.params = {
                                pipeline_name: getValue('pipeline_name'),
                                pipeline_version: getValue('pipeline_version'),
                                layer: getValue('pipeline_layer'),
                                trainable: getValue('trainable') === 'true'
                            }
                        }

                    } else {
                        stageInfo = {
                            name: OperationOptions['Train Model'],
                            params: {}
                        };
                        
                        if(values.pipeline_category !== PipelineCategoryOptions['Clustering']) {
                            stageInfo.params['labelCol'] = getValue('label_col');
                        }
                        
                        if(values.model_library === SparkModelLibraryOptions['Spark']) {
                            stageInfo.type = model_type;
                            
                        } else {
                            stageInfo.type = {
                                class: model_type,
                                module: get(values, getCurrentPythonModuleKey(PythonModelLibraryOptions['Sklearn'], stage.id, values.pipeline_category))
                            };
                            if(!stageInfo.module) {
                                stageInfo.python_module = get(values, getOldFormatPythonModuleKey(PythonModelLibraryOptions['Sklearn'], values.pipeline_category));
                            }
                        }
                        if(getValue('model_type') === ClusteringTypes['LDA']) {
                            stageInfo.params['topicDistributionCol'] = getValue('topicDistributionCol');
                        }
                    }

                    // key is same for all train model hyper params
                    parseHyperParams(
                        getValue, 
                        getTrainModelKey(values.model_library, getValue('model_type')), 
                        stageInfo
                    );
                    
                    
                    break;
                } default: 
                    if(isSklearnPreprocessor(stage.operation) && stage.operation) {
                        const preprocessorType = getValue('sklearnPreprocessor'); 
                        stageInfo = {
                            name: stage.operation,
                            type: {
                                class: preprocessorType,
                                module: get(values, getCurrentPythonModuleKey(PythonModelLibraryOptions['Sklearn'], stage.id, stage.operation)),
                            },
                            features: getValue('features')
                        };
                        // key is same for all train sklearn preprocessing hyper params
                        parseHyperParams(
                            getValue, 
                            getTrainModelKey(values.model_library, preprocessorType), 
                            stageInfo
                        );
                    }

            }
            return stageInfo;
        })
    };
    if(values.model_library === PythonModelLibraryOptions['Tensorflow'] && values.mode !== ModeOptions['Upload Model']) {
        const model_params: Record<string, any> = {};
        // pipeline_params.model_params = 
        const getModelParamsFromStageInfo = (stageInfo: ModelParamStageInfo) => {
            const modelTypeKey = getTensorflowModelParamsKey(stageInfo.id, stageInfo.type, 'type');
            const pythonModuleKey = getTensorflowModelParamsKey(stageInfo.id, stageInfo.type, 'python_module');
            const selectedModelType = get(values, modelTypeKey);
            
            const hyperParamsKey = getTensorflowModelParamsKey(stageInfo.id, stageInfo.type, 'params', selectedModelType);
            
            const getValue = (key: string) => (
                get(values, key)
            );

            const params: any = {};
            parseHyperParams(
                getValue,
                hyperParamsKey,
                params);
            return ({
                type: selectedModelType,
                python_module: get(values, pythonModuleKey),
                params: params.params || {}
            });
            
        };
        (values.modelStagesInfo as ModelParamStageInfo[]).forEach((stage) => {
            if(!stage.isMultiOptimizerStage) {
                const modelParamPayload = getModelParamsFromStageInfo(stage);
                const modelTypeKey = getTensorflowModelParamsKey(stage.id, TensorflowExtraModelParams.optimizers, 'type');

                if(stage.selectionType === 'multiple') {
                    if(isArray(model_params[stage.type])) {
                        model_params[stage.type].push(modelParamPayload);
                    } else {
                        model_params[stage.type] = [modelParamPayload];
                    }
                } else if(stage.type === TensorflowExtraModelParams.optimizers && 
                        get(values, modelTypeKey) === 'MultiOptimizer'
                    ) {
                        // In MultiOptimizer, optimiser stages are added as sub stages
                        model_params[stage.type] = (values.modelStagesInfo as ModelParamStageInfo[])
                            .filter(stage => stage.isMultiOptimizerStage)
                            .map(stage => ({
                                ...getModelParamsFromStageInfo(stage),
                                layer: getValueInPipelineStage(values, 'model_type', stage.pipelineStageInfoForMultiOptimizer?.id as string)
                                })
                            );
                } 
                else {
                    model_params[stage.type] = modelParamPayload;               
                 }
            }
        });
            
        pipeline_params.model_params = model_params;
    }

    return pipeline_params;
};

export const getMlTransformData = (values: any) => {
    const data: any = {
        pipeline_version: values.pipeline_version,
        pipeline_category: values.categoryTypeForTransform,
        mode: values.mode
    };
    if(get(values, 'categoryTypeForTransform') === PREPROCESSING && values.model_library === SparkModelLibraryOptions['Spark']){
        data.output_format = values.output_format;

        if(values.output_format === OutputFormatOptions['Vectorized']) {
            data.output_col = values.output_col;
        }
    } else {
        data.prediction_col = values.transform_prediction_col;
    }
    if(values.model_library !== SparkModelLibraryOptions['Spark']) {
        data[inputTypeFieldName] = values[inputTypeFieldName];
        if(values[inputTypeFieldName] !== InputTypeOptions['Default (dataframes)']) {
            data.file_path = values.file_path;
            data.train_files_pattern = values.train_files_pattern;
        }    
    }
    if(values.input_type === 'parquet_folder') {
        data.dataset_type = values.dataset_type;
        data.image_col = values.image_column;
        data.image_size = values.image_size;
        data.auto_resize = values.auto_resize === 'true';
        data.features = values.features;
        data.label_col = values.label_column;
        data.label_mode = values.label_mode;
        data.prediction_col = values.prediction_col;
        data.train_data_filters = values.train_data_filters;
        data.required_columns = values.required_columns;
    }

    return ({
        pipeline_name: values.pipeline_name, 
        preset_params: data
    });
};

export const getPythonModelOptions = (pythonModelsInfo: PythonModelsInfo, modelLibrary: PythonModelLibraryOptions, pipelineCategory: PipelineCategoryOptions): _selectoptionType[] => 
    get(pythonModelsInfo, modelLibrary+ '.' + pipelineCategory + '.options')  || [];

type RangeType = (number | undefined)[];

export const rangeValidator = (schema: yup.StringSchema, [ start, stop ]: RangeType, type: 'stop' | 'step') => (value: any) => {
    return schema
        .validate(value)
        .then((value) => {
            let message = undefined;
            // here value is stop
            if(type === 'stop'&& start && ((value as unknown as number) < start)) {
                // console.log(stop)
                message = 'Cannot be less than start value';
                // else if(stop > start) message = 'Cannot be greater than start value'
            } else if(type === 'step' && stop && ((value as unknown as number) >= stop)) {
                message = 'Cannot be greater than stop value';
            }
            if(message) {
                throw { message };
            }
            return message;
        })
        .catch((error) => error.message);
};

export const getNumberOfDigitsAfterDecimal = (number: string | number) => {
    const stringifiedNumber = number.toString().split('.');
    if(stringifiedNumber.length > 1) {
        return stringifiedNumber[1].length;
    }
    return 0; 
};


export const fieldSchema = (variable_type: 'number' | 'string' = 'number') => FieldSchemaCreator({ required: true, variable_type }) as yup.StringSchema;
export const numberFieldSchema = fieldSchema('number');
export const stringFieldSchema = fieldSchema('string');

export const useGetStagesInfo = (stagesInfo: MlPipelineStages): {
    preprocessingStages: MlPipelineStageInfo[];
    modelLibraryStages: MlPipelineStageInfo[];
} => {
    const activeExecutionEnv = useSelector((store: RootState) => store.CommonReducer.activeExecutionEnv);
    const { values } = useFormikContext<any>();

    const {
        preprocessingStages,
        modelLibraryStages   
    } = useMemo(() => {
        let preprocessingStages: MlPipelineStageInfo[] = [];
        switch(values.model_library) {
            case SparkModelLibraryOptions['Spark']:
                preprocessingStages = stagesInfo['PREPROCESSING_spark'];
                break;
            case PythonModelLibraryOptions['Sklearn']:
                preprocessingStages = stagesInfo['PREPROCESSING_sklearn'];
        }
        return({
            preprocessingStages,
            modelLibraryStages: stagesInfo[values.model_library as MlPipelineStageKeys] || []
        });
    }, [stagesInfo, values.model_library, activeExecutionEnv]);
    

    return ({ preprocessingStages, modelLibraryStages });
}; 

export const getMlPipelineStagesInitialVal = (): MlPipelineStages => ({
    // touched: true because we show these PREPROCESSING_Spark by default in spark Mode
    // and Tensorflow by default in python Mode
    [MlPipelineStageKeys['PREPROCESSING_Spark']]: [
        { id: uuid(), operation: null, touched: false }
    ],
    [MlPipelineStageKeys['Spark']]: [
        { id: uuid(), operation: OperationOptions['Train Model'], touched: false}
    ],
    [MlPipelineStageKeys['PREPROCESSING_Sklearn']]: [
        { id: uuid(), operation: null, touched: false }
    ],
    [MlPipelineStageKeys['sklearn']]: [
        { id: uuid(), operation: OperationOptions['Train Model'], touched: false}
    ],
    [MlPipelineStageKeys['tensorflow']]: [
        { id: uuid(), operation: OperationOptions['Train Model'], touched: false}
    ]
});


export const getDefaultValuesForTensorflowExtraParams = (initialModelStagesInfoValue: ModelParamStages | undefined, tensorflowExtraParams: PythonModelsInfo['tensorflowExtraParams'] | TensorflowExtraModelParams) => {
    // this to set default values for tensor flow extra params
    // For example, single-select-field optimizers: Adadelta
    // multiple-select-field Metrics: Adadelta, Accuracy
    const __initialVal: Record<string, any> = {};
    const modelParamStagesInfo: ModelParamStages = [];

    if(!initialModelStagesInfoValue) {
        const handleSetInitialValue = (key: string, value: any) => {
            __initialVal[key] = value;
        };


        const handleSetDefaultValueOfStage = (currentModelParam: TensorflowExtraModelParams, defaultValue: PythonSingleModelTypeInfoOptions, selectionType: PythonSingleModelParamInfo['selectionType']) => {
            const stageId = uuid();
            const modelParamTypeKey = getTensorflowModelParamsKey(stageId, currentModelParam, 'type');
            const modelParamPythonModuleKey = getTensorflowModelParamsKey(stageId, currentModelParam, 'python_module');
            // __initialVal[modelParamTypeKey] = defaultValue.value;
            // __initialVal[modelParamPythonModuleKey] = defaultValue.pythonModule;
            
            handleSetInitialValue(modelParamTypeKey, defaultValue.value);
            handleSetInitialValue(modelParamPythonModuleKey, defaultValue.pythonModule);
            modelParamStagesInfo.push({ id: stageId, type: currentModelParam, selectionType });
        };


        Object.entries(tensorflowExtraParams).forEach(([modelParam, modelParamInfo]) => {
            if(modelParamInfo.selectionType === 'multiple') {
                const defaultOptions = modelParamInfo.defaultValue as PythonSingleModelTypeInfoOptions[]; 
                if(isArray(defaultOptions)) {
                    defaultOptions.forEach((defaultOpt) => {
                        if(defaultOpt.label !== '') {
                            handleSetDefaultValueOfStage(modelParam as TensorflowExtraModelParams, defaultOpt, 'multiple');
                        }
                    });
                }
            } else {
                const defaultValue = modelParamInfo.defaultValue as PythonSingleModelTypeInfoOptions; 
                if(defaultValue.label !== '') {
                    handleSetDefaultValueOfStage(modelParam as TensorflowExtraModelParams, defaultValue, 'single');
                }
            }
        });

        // if(!isEmpty(modelParamStagesInfo)) {
        //     setModelParamStagesInfo(__modelParamStagesInfo);
        // }
        handleSetInitialValue('modelStagesInfo', modelParamStagesInfo);
    } 
    return { tensorflowExtraParamInitialValues: __initialVal, modelParamStagesInfo };
};

/*
"stage_9e421f2f-6908-4cce-a0c9-446a7b957857": {
        "operation": "train_model",
        "model_type": "AdaBoostClassifier",
        "train_model_hyperparams__AdaBoostClassifier__sklearn": {
          "algorithm": {
            "tune": false,
            "_isFloat": false,
            "_isBoolean": false
          },
          "base_estimator": {
            "tune": false,
            "_isFloat": false,
            "_isBoolean": false
          },
          "learning_rate": {
            "tune": false,
            "_isFloat": true,
            "_isBoolean": false
          },
          "n_estimators": {
            "tune": false,
            "_isFloat": true,
            "_isBoolean": false
          },
          "random_state": {
            "tune": false,
            "_isFloat": true,
            "_isBoolean": false
          },
          "_info": [
            {hyperParamLabel: \"algorithm\", hyperParamType: \"Lis…},
            {hyperParamLabel: \"base_estimator\", hyperParamType:…},
            {hyperParamLabel: \"learning_rate\", hyperParamType: …},
            {hyperParamLabel: \"n_estimators\", hyperParamType: \"…},
            {hyperParamLabel: \"random_state\", hyperParamType: \"…}
          ],
          "selectedHyperParams": {}
        }
      },
      "sklearn__current_selection": {
        "activePipelineCategory": "classification",
        "modelTypeSelection": {classification: "AdaBoostClassifier"},
        "pythonModuleInfoForSklearn": "sklearn.emsemble"
      }
*/



export const getDefaultValuesForSklearnParams = (initialValues: Record<string, any>, stageId: string | undefined, params: SklearnModelsInfo) => {
    const __initialVal: Record<string, any> = {};
    const set = (key: string, value: any) => {
        // keys are stored in string instead of the actual path
        // for example, 
        // {
        //      stage_4044aae7-acf8-4726-9733-5301ca3985c9.train_model_hyperparams__AdaBoostClassifier__sklearn.selected_params: {}  
        // } instead of 
        //  {
        //      stage_4044aae7-acf8-4726-9733-5301ca3985c9: {
        //          train_model_hyperparams__AdaBoostClassifier__sklearn: { selected_params: {} }
        //      }
        // }
        // this is done in setInitialValues in src\components\formcreators\ml-pipeline\build-pipeline-modal\index.tsx
        __initialVal[key] = value;
    };
    if(!!stageId && !isEmpty(params.classification.options)) {
        // selectedModel = AdaBoostClassifier
        // trainModelkey = train_model_hyperparams__AdaBoostClassifier__sklearn
        // stageKey = stage_4044aae7-acf8-4726-9733-5301ca3985c9.train_model_hyperparams__AdaBoostClassifier__sklearn
        const selectedModel = params.classification.options[0].value;
        const trainModelkey = getTrainModelKey(PythonModelLibraryOptions['Sklearn'], selectedModel);
        const stageKey = getKeyInPipelineStage(trainModelkey, stageId);
        if(!has(initialValues, stageKey)) {
            const selectedModelParams = params.classification.params[selectedModel].info;
            // Refer to handleSetHyperParamValuesOnMount in src\components\formcreators\ml-pipeline\build-pipeline-modal\hyper-params-table.tsx
            selectedModelParams.forEach(hyperParam => {
                const hyperParamKey = getHyperParamFieldKey(stageKey, hyperParam.name);
                // console.log(name, hyperParam.name)
                set(hyperParamKey + '.tune', hyperParam.tune);
                set(hyperParamKey + '._isFloat', hyperParam.variableType === 'float');
                set(hyperParamKey + '._isBoolean', hyperParam.variableType === 'boolean');
                // set(additionalValues, hyperParamKey + '.type', hyperParam.hyperParamType);
            });
            set(stageKey + '._info', selectedModelParams);
            set(stageKey + '.selectedHyperParams', {});

            if(params.classification.options[0].pythonModule) {
                const libraryCurrentSelectionValue: CurrentModelLibrarySelection =  {
                    activePipelineCategory: PipelineCategoryOptions['Classification'],
                    // @ts-ignore
                    pythonModuleInfo: {
                        [stageId]: {
                            [PipelineCategoryOptions['Classification']]: params.classification.options[0].pythonModule
                        }
                    },
                    // @ts-ignore
                    modelTypeSelection: { [PipelineCategoryOptions['Classification']]: selectedModel}
                };
                set(getModelLibrarySelectionKey(PythonModelLibraryOptions['Sklearn']), libraryCurrentSelectionValue);
            }
            set('pipeline_category', PipelineCategoryOptions['Classification']);
            set(getKeyInPipelineStage('operation', stageId), OperationOptions['Train Model']);
            set(getKeyInPipelineStage('model_type', stageId), 'AdaBoostClassifier');
        }

        
    }
    return __initialVal;

};

export const getSklearnPreprocessingOptions = (pythonModelsInfo: PythonModelsInfo, operation: SkLearnPreProcessingOptions, ) => {
    const PreprocessingData = pythonModelsInfo['PREPROCESSING_sklearn'];
    return PreprocessingData[operation]?.options || [];
};

const getDefaultValueOfModelParams = (pythonModelTypes: PythonModelHyperParamsResponse, options: _selectoptionType[]) => {
    let defaultValue: PythonSingleModelParamInfo['defaultValue'] = { label: '', value: ''};
    if(pythonModelTypes._config) {
        const defaultParamInResponse = get(pythonModelTypes, '_config.default_param');
        if(typeof(defaultParamInResponse) === 'string') {
            const __defaultOption = options.find(opt => opt.value === defaultParamInResponse);
            if(__defaultOption) defaultValue = __defaultOption.value;  
        } else if((isArray(defaultParamInResponse) && defaultParamInResponse.length > 0)) {
            const __defaultValue: _selectoptionType[] = [];
            const __multipleDefaultOptionsInResponse: Record<string, true> = {};
            defaultParamInResponse.forEach(val => {
                __multipleDefaultOptionsInResponse[val] = true;
            });
            options.forEach(opt => {
                if(__multipleDefaultOptionsInResponse[opt.value]) {
                    __defaultValue.push(opt);
                }
            });
            if(isEmpty(__defaultValue)) defaultValue = __defaultValue;
        }
        const selectionType = pythonModelTypes._config.selection;
        if(!isEmpty(options)){
            if(selectionType === 'multiple' && !isArray(defaultValue) && !defaultValue.label) {
                defaultValue = [options[0]];
            }
            else if(selectionType === 'single' && !(defaultValue as _selectoptionType).label) {
                defaultValue = options[0];
            }
        }
    }
        
    return defaultValue;
};


export function parseResponseOfPythonModel(res: PythonPipelineCategoryResponse, modelLibrary: PythonModelLibraryOptions.Sklearn): PythonModelsInfo['sklearn'];
export function parseResponseOfPythonModel(res: PythonPipelineCategoryResponse, modelLibrary: PythonModelLibraryOptions.Tensorflow): PythonModelsInfo['tensorflow'];
export function parseResponseOfPythonModel(res: PythonTensorflowModelParamsResponse, modelLibrary: PythonModelLibraryOptions.Tensorflow, checkForDefaultValue: boolean): PythonModelsInfo['tensorflowExtraParams'];
export function parseResponseOfPythonModel(res: PythonPipelineCategoryResponse | PythonTensorflowModelParamsResponse, modelLibrary: PythonModelLibraryOptions, checkForDefaultValue?: boolean) {
    const pythonModelTypesInfo: any = {};
    Object.entries(res).forEach(([pipelineCategoryType, pythonModelTypes]) => {
        let options: PythonSingleModelTypeInfoOptions[] = [];
        let params: PythonSingleModelTypeInfo['params'] = {};
        let extraConfigParams: any = { };

        const paramTypeRef: Record<string, HyperParamsInfo['info'][0]['variableType']> = { 'str': 'string', 'dict': 'string', 'bool': 'boolean', 'int': 'float', 'float': 'float', 'array': 'string' }; 

        Object.entries(omit(pythonModelTypes, '_config')).forEach(([pythonModelTypeName, pythonModelTypeInfo]) => {
            options.push({ label: pythonModelTypeName, value: pythonModelTypeName, pythonModule: pythonModelTypeInfo.python_module });
            params[pythonModelTypeName] = {
                key: getTrainModelKey(modelLibrary, pythonModelTypeName),
                showTypeColumn: false,
                info: Object.entries(pythonModelTypeInfo.params).map(([pythonModelHyperParamName, pythonModelHyperParamInfo]) => {
                    const variableType = pythonModelHyperParamInfo.type ?
                        paramTypeRef[pythonModelHyperParamInfo.type as keyof typeof paramTypeRef]  || 'float':
                        'string';
                    
                    return {
                        hyperParamLabel: pythonModelHyperParamName,
                        tune: false,
                        hyperParamType: variableType === 'boolean' ? TuneTypes.Checkbox: TuneTypes.List,
                        hyperParamValue: '',
                        name: pythonModelHyperParamName,
                        variableType,
                        qtip: pythonModelHyperParamInfo.desc,
                        options: variableType === 'boolean' ? getDefaultCheckboxOptions():[]
                    };
                })
            };
        });
        if(checkForDefaultValue) {
            extraConfigParams.defaultValue = getDefaultValueOfModelParams(pythonModelTypes, options);
            if(pythonModelTypes._config?.selection) {
                extraConfigParams.selectionType = pythonModelTypes._config.selection;
                extraConfigParams.label = pythonModelTypes._config.label;
            }
        }
        pythonModelTypesInfo[pipelineCategoryType] = {
            options,
            params,
            ...extraConfigParams
        };
        options = [];
        params = {};
        extraConfigParams = {};
    });
    return pythonModelTypesInfo;
}

export const addAdditionalLayersToTensorflow = (tensorflowModels : TensorflowModelsInfo) => {
    tensorflowModels.layers.options.push({
        label: "Pretrained Model",
        value: TensorflowLayers['Pretrained Model']
    })

    tensorflowModels.layers.params[TensorflowLayers['Pretrained Model']] = {
        key: getTrainModelKey(PythonModelLibraryOptions.Tensorflow ,TensorflowLayers['Pretrained Model']),
        showTypeColumn: false,
        info: []
    }
}