import { SelectField } from "@components/form";
import { _selectoptionType } from "@components/form/select-field";
import React, { useRef } from "react";
import { useMlPipelineContext } from "./context";

type Props = {
    getKey ?: (key: string) => string;
    onPipelineVersionClick ?: (option: _selectoptionType) => void; 
}

const StoredPipelineFields: React.FC<Props> = ({ onPipelineVersionClick, getKey = (key) => key }) => {
    const { modelsList, versionsList, onModelListSelection } = useMlPipelineContext();
    const pipelineVersionFieldRef = useRef<SelectField>(null);

    return(
        <>
            <SelectField 
                name={getKey("pipeline_name")}
                options={modelsList}
                label="Pipeline Name"
                onOptionClick={onModelListSelection.bind(null, true, pipelineVersionFieldRef)}
            />
            <SelectField 
                name={getKey("pipeline_version")}
                options={versionsList}
                label="Pipeline Version"
                ref={pipelineVersionFieldRef}
                onOptionClick={onPipelineVersionClick}  
            />
        </>
    )
}

export default StoredPipelineFields;