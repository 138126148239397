import { useFormikContext } from 'formik';
import { cloneDeep, concat, has, isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { CreateTrainModelStageParams, PythonModelsInfo, PythonSingleModelParamInfo } from '.';
import { _selectoptionType } from '../../../form/select-field';
import { OperationOptions, ExtraOperationOptions, operationOptionsForDropdown, DataCategoryOptions, extraOperationOptionsForDropdown, SparkModelLibraryOptions, PipelineCategoryOptions, MlPipelineStageKeys, PythonModelLibraryOptions, TensorflowExtraModelParams, SkLearnPreProcessingOperationDropdown, SkLearnPreProcessingOptions } from '../enums';
import { getStageKey, useGetStagesInfo } from '../utils';
import { PipelineStage, PipelineStageProps } from './pipeline-stage';
import { Collapse } from 'antd';
import { UpChevronIcon } from '../../icons';
import classNames from 'classnames';
import { TensorflowModelParams } from './tensorflow-model-params';
import { useMlPipelineContext } from '../context';
import { getUniqueId } from '@utils/common';


const { Panel } = Collapse;

export type MlPipelineStageInfo = { 
    id: string; 
    operation: OperationOptions | ExtraOperationOptions | SkLearnPreProcessingOptions | null;
    touched: boolean; 
};


export type MlPipelineStages = Record<MlPipelineStageKeys, MlPipelineStageInfo[]>

export type ModelParamStageInfo = {
    id: string;
    type: TensorflowExtraModelParams;
    selectionType: PythonSingleModelParamInfo['selectionType'];
    isMultiOptimizerStage ?: boolean;
    pipelineStageInfoForMultiOptimizer ?: MlPipelineStageInfo;
} 

export type ModelParamStages = ModelParamStageInfo[];

export type StageOperationInfo = Record<string, {
    currentOpt: MlPipelineStageInfo['operation'] | null;
    options: _selectoptionType[];
}>

export type BuildPipelineRHSProps = {
    handleStagesInfo: [MlPipelineStages, React.Dispatch<React.SetStateAction<MlPipelineStages>>];
    handleModelParamStagesInfo: [ModelParamStages, React.Dispatch<React.SetStateAction<ModelParamStages>>];
    // captureSchemaOptions: BuildPipelineModalProps['captureSchemaOptions'];
    pythonModelsInfo: PythonModelsInfo;
    resetModelTypeFieldInfo: PipelineStageProps['resetModelTypeFieldInfo'];
    resetOperationFieldInfo: PipelineStageProps['resetOperationFieldInfo'];
    handleCreateTrainModelStage: (arg0: CreateTrainModelStageParams) => any;
}


export const BuildPipelineRHS: React.FC<BuildPipelineRHSProps> = ({ handleStagesInfo, handleModelParamStagesInfo, pythonModelsInfo, resetModelTypeFieldInfo, resetOperationFieldInfo, handleCreateTrainModelStage }) => {
    const { values, setFieldValue } = useFormikContext<any>();
    const [stagesInfo, setStagesInfo] = useMemo(() => handleStagesInfo, [handleStagesInfo]);
    const { preprocessingStages, modelLibraryStages } = useGetStagesInfo(stagesInfo);
    const [activeKeyForPipeline, setActiveKeyForPipeline] = useState('2');
    const { captureSchemaOptions } = useMlPipelineContext();

    const operationsInfo: StageOperationInfo = useMemo(() => {
        // For preprocessing stages only
        // This is to add DataCategoryOptions["Geospatial Data"] & DataCategoryOptions['Text Data']
        // to operation types dropdown when data category geospatial data / text data is selected
        const stageOperationInfo: StageOperationInfo = {};

        preprocessingStages.forEach((stage, index) => {
            let opts = cloneDeep(operationOptionsForDropdown);

            if(index === 0 && values.data_category === DataCategoryOptions['Geospatial Data']) {
                // ADD DataCategoryOptions["Geospatial Data"] only for the first step when data_category is geospatial data
                opts.unshift(extraOperationOptionsForDropdown[0]);
            } else if(values.data_category === DataCategoryOptions['Text Data']) {
                // ADD DataCategoryOptions["Text Data"] only for the first step when data_category is text data
                opts = opts.concat(extraOperationOptionsForDropdown.slice(1,));
            } else if (MlPipelineStageKeys['sklearn'] === values.model_library ) {
                opts = SkLearnPreProcessingOperationDropdown;
            }
            stageOperationInfo[stage.id] = { options: opts, currentOpt: stage.operation || null};
        });
        modelLibraryStages.forEach((stage) => {
            stageOperationInfo[stage.id] = { options: [], currentOpt: stage.operation};
        });
        return stageOperationInfo;

    }, [preprocessingStages, modelLibraryStages, values.data_category]);

    const handleCreateNewStage = (selectedStage: number, key: MlPipelineStageKeys) => {
        if(values.pipeline_category === PipelineCategoryOptions['Layers']) {
            handleCreateTrainModelStage({
                createNewStage: true,
                pipelineCategory: PipelineCategoryOptions['Layers'], 
                newStagePosition :selectedStage, 
                // Since multiple Train stages are created only in tensorflow
                mlPipelineStageKey: MlPipelineStageKeys['tensorflow']
            });
        } else {
            const __stagesInfo = cloneDeep(stagesInfo);
            const selectedStagesInfo = __stagesInfo[key]; 
            if((key === MlPipelineStageKeys['PREPROCESSING_Spark'] || key === MlPipelineStageKeys['PREPROCESSING_Sklearn']) && preprocessingStages.length === 1 && preprocessingStages[0].touched === false) {
                // Initially, on opening the component for the first time in spark mode,  there's only one preprocessing stage
                // if operation is not set and build model is checked, this preprocessing stage is replaced with train model stage.
                // This is filtered by using the boolean param - touched
                // On clicking add new stage in only one train model stage, this is set to true instead of creating a new stage
                selectedStagesInfo[0].touched = true;
            } else {
                // add preprocessing stages
                selectedStagesInfo.splice(selectedStage, 0, { id: getUniqueId(), operation: null, touched: true });
            }
            setStagesInfo(__stagesInfo);
        }
    };

    const handleDeleteStage = (id: string, key: MlPipelineStageKeys) => {
        const __stagesInfo = cloneDeep(stagesInfo);
        const selectedStageInfo = __stagesInfo[key]; 
        __stagesInfo[key] = selectedStageInfo.filter(stage => stage.id !== id);
        setFieldValue(getStageKey(id), null);
        setStagesInfo(__stagesInfo);
        if(key === MlPipelineStageKeys.tensorflow) {
            removeMultiOptimizerStageOnPipelineStageDeletion(id)
        }
    };

    const handleSetOperationType = (id: string, operation: OperationOptions, key: MlPipelineStageKeys) => {
        // getKeyInPipelineStage() 
        const __stagesInfo = cloneDeep(stagesInfo);
        const selectedStageInfo = __stagesInfo[key]; 
        __stagesInfo[key] = selectedStageInfo.map(stage => {
            if(stage.id === id) {
                stage.operation = operation;
                stage.touched = true;
            }
            return stage;
        });        
        setStagesInfo(__stagesInfo);
    };

    const stagesToBeShown: MlPipelineStageInfo[] = useMemo(() => {
        // console.log(values.model_library,PythonModelLibraryOptions['Tensorflow'])
        if(values.build_model !== 'true') {
            return preprocessingStages;
        } else if(values.model_library !== PythonModelLibraryOptions['Tensorflow'] ) {
            // if there's only one preprocessing stage with operation as null - replace the stage with modelLibrary stage
            if(preprocessingStages.length === 1 && preprocessingStages[0].touched === false)
                return modelLibraryStages;
            return [...preprocessingStages, ...modelLibraryStages];
        } else { 
            // ONLY SHOW Train Model in Python models - Tensorflow
            return modelLibraryStages;
        }
    }, [ values.build_model, values.model_library, values.data_category, preprocessingStages, modelLibraryStages]);


    const tensorflowMode = values.model_library === PythonModelLibraryOptions['Tensorflow'];

    useEffect(() => {
        if(tensorflowMode) {
            setActiveKeyForPipeline('1');
        } else {
            setActiveKeyForPipeline('2');
        }
    }, [tensorflowMode]);

    const onMultiOptimizerSelection = (selected: boolean) => {
        const [modelParamStagesInfo, setModelParamStagesInfo] = handleModelParamStagesInfo;
        if(selected) {
            const tensorflowStages: ModelParamStageInfo[] = (values.stagesInfo.tensorflow as MlPipelineStages['tensorflow']).map(stage => ({
                id: getUniqueId(),
                type: TensorflowExtraModelParams['optimizers'],
                isMultiOptimizerStage: true,
                selectionType: 'single',
                pipelineStageInfoForMultiOptimizer: stage
            }));
            const optimizerStageIndex = modelParamStagesInfo.findIndex(stage => stage.type === TensorflowExtraModelParams['optimizers'])
            if(optimizerStageIndex !== -1) {
                const modelParamStagesInfoCopy = concat(
                    modelParamStagesInfo.slice(0, optimizerStageIndex + 1),
                    tensorflowStages,
                    modelParamStagesInfo.slice(optimizerStageIndex + 1),
                )
                setModelParamStagesInfo(modelParamStagesInfoCopy)
            }
        } else {
            // remove multioptimizer stages
            setModelParamStagesInfo(modelParamStagesInfo => modelParamStagesInfo.filter(stage => !has(stage, 'isMultiOptimizerStage') ))
        }
    }

    const removeMultiOptimizerStageOnPipelineStageDeletion = (pipelineStageId: string) => {
        const [, setModelParamStagesInfo] = handleModelParamStagesInfo;
        setModelParamStagesInfo(modelParamStagesInfo => modelParamStagesInfo.filter(stage => stage.pipelineStageInfoForMultiOptimizer ? stage.pipelineStageInfoForMultiOptimizer?.id !== pipelineStageId: true));
    }

    return(
        <Collapse
            bordered={false}
            expandIcon={({ isActive }) => 
                <div className={classNames('collapseIcon', {'rotate': isActive})}>
                    <UpChevronIcon />
                </div>}
            className={classNames('configurePipeline__RHS__collapse', { tensorflowMode })}
            activeKey={activeKeyForPipeline}
            onChange={(k) => setActiveKeyForPipeline(k as string)}
            accordion
        >
            {/* <div
                className="configurePipeline__RHS__innerBox"
            > */}
            <Panel 
                key="2"
                // header="Configure pipeline"
                header={
                    values.pipeline_name ?
                        <span className="pipelineName">Configure Pipeline - {values.pipeline_name}</span> 
                        :
                        <span className="noPipelineName__msg">Configure Pipeline</span>
                }
                className="configurePipeline__RHS__innerBox"
                disabled={!tensorflowMode}
            >
                {/* <div className="pipelineName__box">
                    {values.pipeline_name ?
                        <span className="pipelineName">{values.pipeline_name}</span> 
                        :
                        <span className="noPipelineName__msg">Pipeline Name</span>
                    }
                </div> */}
                <div
                    className={classNames('pipelineStages__outer', { tensorflowMode })}
                >
                    {isEmpty(stagesToBeShown) ?
                        <div
                            className="noCategory__msg"
                        >
                            To build your pipeline, select a pipeline category.
                        </div>
                        :
                        stagesToBeShown.map((stage, index) => (
                            <PipelineStage 
                                key={stage.id}
                                step={index + 1}
                                stageId={stage.id}
                                handleCreateNewStage={handleCreateNewStage}
                                handleDeleteStage={handleDeleteStage}
                                handleSetOperationType={handleSetOperationType}
                                operationsInfo={operationsInfo}
                                noOfStages={stagesToBeShown.length}
                                captureSchemaOptions={captureSchemaOptions}
                                pythonModelsInfo={pythonModelsInfo}
                                resetModelTypeFieldInfo={resetModelTypeFieldInfo}
                                resetOperationFieldInfo={resetOperationFieldInfo}
                            />
                        ))
                    }
                </div>
            </Panel>
            <Panel 
                key="1"
                header="Model Parameters"
                className={classNames(
                    'configurePipeline__RHS__innerBox tensorflowParams__box',
                    { hide: !tensorflowMode}
                )}
            >
                <div
                    className="pipelineStages__outer tensorflowMode"
                >
                    <TensorflowModelParams
                        extraModelParamsInfo={pythonModelsInfo['tensorflowExtraParams']}
                        handleModelParamStagesInfo={handleModelParamStagesInfo}
                        onMultiOptimizerSelection={onMultiOptimizerSelection}
                    />
                </div>
            </Panel>
        </Collapse>
    );
};