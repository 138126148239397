import React, { useState } from 'react';
import { Modal } from '../../../components/modals';
import { capitalize } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/types';
import { toggleTagBrowserModal } from '../../../store/tag-browser';
import Form, { InputField } from '../../../components/form';
import { object, string, InferType } from 'yup';
import { TagsHandler, ClientSiteInfo } from '../../../api/tags-handler';
import { successAlert, errorAlert } from '../../../components/toastify/notify-toast';
import { InPageSpinner } from '../../../components/spinners/in-page-spinner';
import classNames from 'classnames';

export interface NewEditPlantSiteProps {
    actionType: 'new' | 'edit';
    refreshClientList: (arg0: ClientSiteInfo) => any;
}

const addPlantSiteSchema = object().shape({
    siteName: string()
        .required('Plant site name is a required field.'),
    siteDescription: string()
        .required('Description is a required field.'),
    username: string()
        .required('username is a required field.'),
    uri: string()
        .required('URL is a required field.'),
    password: string()
}); 

type addPlantSiteSchema = InferType<typeof addPlantSiteSchema>



export const NewEditPlantSite: React.FC<NewEditPlantSiteProps> = ({ actionType, refreshClientList }) => {
    const { editPlantSite: showModal } = useSelector((store: RootState) => store.TagBrowserReducer.showModal);
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState<addPlantSiteSchema>({ siteName: '', siteDescription: '', username: '', uri: '', password: ''}); /* eslint-disable-line */
    const [showSpinner, toggleShowSpinner] = useState(false);

    const toggleClose = () => {
        dispatch(toggleTagBrowserModal('editPlantSite', false));
    };

    const handleCreateClientSiteSuccess = (response: ClientSiteInfo) => {
        successAlert('Successfully created ' + response.siteName);
        refreshClientList(response);
        toggleShowSpinner(false);
        toggleClose();
    };

    const handleCreateError = (response: any) => {
        errorAlert(response.data);
        toggleShowSpinner(false);
    };

    const handleFormSubmit = (values: addPlantSiteSchema) => {
        toggleShowSpinner(true);
        TagsHandler.CreateClientSite(values, handleCreateClientSiteSuccess, handleCreateError);
    };

    return(
        <Modal
            isOpen={showModal}
            title={capitalize(actionType) + ' Plant Site'}
            toggleClose={toggleClose}
            className="newEditPlantSite__modal"
        >
            
            <Form
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
                validationSchema={addPlantSiteSchema}
                className="newEditPlantSite__form"
            >
                {showSpinner && <InPageSpinner /> }
                <div className={classNames({'hide': showSpinner})}>
                    
                    <InputField 
                        label="Name"
                        name="siteName"
                        placeholder="Enter Plant name"
                        required
                    />
                    <InputField 
                        label="Description"
                        name="siteDescription"
                        placeholder="Enter description"
                        required
                    />
                    <InputField 
                        label="URL"
                        name="uri"
                        placeholder="Enter url"
                        required
                    />
                    <InputField 
                        label="Username"
                        name="username"
                        placeholder="Enter username"
                        required
                    />
                    <InputField 
                        label="Password"
                        name="password"
                        placeholder="Enter password"
                        type="password"
                    />
                </div>
                <div className="modalBtns__box">
                    <button 
                        className="btn-md btn-yellow"
                        type="submit"
                        disabled={showSpinner}
                    >
                        Create
                    </button>
                    <button 
                        className="btn-md btn-cancel"
                        type="button"
                        onClick={toggleClose}
                    >
                        Cancel
                    </button>
                </div>
            </Form>
        </Modal>
    );
};