import { WorkflowConfig } from '@services/WorkflowConfig';
import { cloneDeep, get, has, isEmpty, omit, set } from 'lodash';
import { DsNodeModel } from '../../workflow-canvas/node/ds_nodemodel';
import {
    ComponentFieldsInfo,
    ConnectedComponentFieldInfo,
    ConnectedComponentFields,
    VariableComponentMappingInfo,
    WorkflowConfigMappingInfo,
    WorkflowConfigMappingInFormikContext
} from './reducer';

export const getVariablePlaceholder = (varName: string) => '{{' + varName + '}}';
export const getWorkflowConfigItemPlaceholder = (varName: string) => '<<' + varName + '>>';

export const getVariableUniqueId = (fieldLabel: string, fieldKey: string) => fieldLabel + '______' + fieldKey;

export const getVariableKeyFromUniqueId = (id: string) => id.split("______")?.[1] || ''

export const getComponentFieldsInfoUsingPropertiesContainerRef = (
    propertiesFieldsRef: HTMLDivElement
) => {
    // const _fieldValuesRef: Record<string, string[]> = {};

    const componentFields: ComponentFieldsInfo[] = []
    // fields with data-fieldlabelforvariable will and data-fieldkey be shown as options for variable
    Array.from(
        propertiesFieldsRef.querySelectorAll("[data-fieldlabelforvariable][data-fieldkey]")
    ).forEach(e => {
        const label = e.getAttribute('data-fieldlabelforvariable');
        const fieldKey = e.getAttribute('data-fieldkey');
        if(label && fieldKey) {
            const fieldId = getVariableUniqueId(label, fieldKey);
            componentFields.push({
                label,
                id: fieldId,
                fieldKey,
                hidden: e.className.includes('hide__formfield'),
                type: (e.getAttribute('data-fieldtype') || 'input') as ComponentFieldsInfo['type']
            })
        }

        // const _fieldInfo = { fieldKey, fieldId }
        // const _fieldInfo = fieldId;
        // if (has(_fieldValuesRef, fieldKey)) {
        //     _fieldValuesRef[fieldKey].push(_fieldInfo);
        // } else {
        //     _fieldValuesRef[fieldKey] = [_fieldInfo];
        // }

        
    });
    // // console.log(_fieldValuesRef)
    // componentFields.forEach(field => {
    //     if (_fieldValuesRef[field.fieldKey].length > 1) {
    //         const otherFieldInfo = _fieldValuesRef[field.fieldKey].filter(
    //             otherFieldId => otherFieldId !== field.id
    //         )?.[0];
    //         if (otherFieldInfo) {
    //             field.hasSameFieldKeyWithDifferentProps = {
    //                 otherFieldId: otherFieldInfo
    //             };
    //         }
    //     }
    // });

    return componentFields;
};

const getComponentFieldsMapping = (
    selectedFields: string,
    fieldsList: ComponentFieldsInfo[]
) => {
    // fieldsList = [
    // { label: "Table name", value: "Table name_table_name", fieldKey: "table_name", hidden: false },
    // { label: "Cols", value: "Cols_cols", fieldKey: "cols", hidden: false }
    // { label: "Database", value: "Table name_table_name", fieldKey: "database", hidden: true },
    // ]
    // variableValue = "table_name,cols"
    const connectedComponentFields: VariableComponentMappingInfo['connectedComponentFields'] = {};
    fieldsList.forEach(field => {
        // field.value is the key name
        // set(connectedComponentFields, field.value, { selected: selectedFields .includes(field.value), hidden: field.hidden, label: field.label });
        connectedComponentFields[field.id] = {
            ...field,
            selected: selectedFields.includes(field.id)
        };
    });
    return connectedComponentFields;
};

export const getVarMappingInfo = (
    activeNodeInfo: DsNodeModel | undefined,
    variableMappingInFormikContext: any,
    componentFieldsArr: ComponentFieldsInfo[]
) => {
    let varComponentsInfo: VariableComponentMappingInfo[] = [];
    if (activeNodeInfo) {
        varComponentsInfo = activeNodeInfo
            .getConnectedVariables()
            .map(({ node: variableNode, link: variableLink }) => {
                // actual-variable-key = extraData.workflow_var_mapping.var_1
                // varName: var_1
                // selectedFieldsOfVariable = fetchSize,cols,...

                const varName = variableNode.getOptions().title;
                const selectedFieldsOfVariable = get(
                    variableMappingInFormikContext,
                    varName
                );
                const connectedComponentFields = getComponentFieldsMapping(
                    selectedFieldsOfVariable || '',
                    componentFieldsArr
                );

                return {
                    name: varName,
                    variableComponentId: variableNode.getID(),
                    connectedComponentFields,
                    linkId: variableLink.getID(),
                    isEditorType: false
                };
            });
    }
    return varComponentsInfo;
};

export const getComponentFieldStatus = (
    currentFieldValue: string | undefined,
    itemPlaceholder: string,
    fieldInfo: ConnectedComponentFieldInfo
) => {
    const currentFieldValueIncludesItem =
        typeof currentFieldValue === 'string' &&
        currentFieldValue.includes(itemPlaceholder);
    let updatedFieldValue = currentFieldValue ?? '';
    let _triggerDropdownValidation = false;
    if (fieldInfo.selected) {
        if (
            !fieldInfo.hidden &&
            !currentFieldValueIncludesItem 
        ) {
            if(fieldInfo.type === 'select') {
                // if field type is select - replace field's value with itemPlaceholder
                updatedFieldValue = itemPlaceholder;
            } else if(typeof updatedFieldValue === 'string') {
                updatedFieldValue += itemPlaceholder;
            }
        } else if (fieldInfo.hidden && currentFieldValueIncludesItem) {
            // if field is hidden and selected, remove the variable placeholder
            // variableName = "var1"
            // variableValue = "fetchSize,database"
            // _activeFieldsInfo = [{label: "FetchSize", value: "fetchSize", hidden: false}, {label: "Database", value: "database", hidden: true}]
            // fetchSize = {{var1}}
            // database = {{var1}}, cols
            // here database is not active field,
            // variable placeholder has to be removed from database value -- database = ",cols"
            // database also has to be removed variableValue, variableValue = "fetchSize"

            // this removes the placeholder from field value

            // Variable dropdown needs to be updated as well
            updatedFieldValue = updatedFieldValue.replace(itemPlaceholder, '');
            _triggerDropdownValidation = true;
        }
    } else if (!fieldInfo.selected && currentFieldValueIncludesItem) {
        updatedFieldValue = updatedFieldValue.replace(itemPlaceholder, '');
    }
    return {
        _value: updatedFieldValue,
        isFieldSelected: typeof(updatedFieldValue) === 'string' && updatedFieldValue.includes(itemPlaceholder),
        _triggerDropdownValidation
    };
};


export const removeVariableInfoFromComponent = (activeNodeInfo: DsNodeModel) => {
    if(has(activeNodeInfo.extras.extraData, 'workflow_var_mapping')) {
        const listOfSelectedFields = get(activeNodeInfo.extras.extraData.workflow_var_mapping, 'all_fields_list')
        let formData = cloneDeep(activeNodeInfo.extras.formData);
        // { 
        //      var_1: "Table/Query______tablequery,Database______p_db",
        //      var_2: "Database______p_db"
        // }

        const variablesMapping = omit(activeNodeInfo.extras.extraData.workflow_var_mapping, 'all_fields_list')

        if(!isEmpty(listOfSelectedFields)) {
            Object.entries(variablesMapping).forEach(([variableName, selectedFields]) => {
                // loop through  all the variables and replace field values
                const varPlaceholder = getVariablePlaceholder(variableName);

                formData = formData.map(field => {
                    const fieldUniqueId = getVariableUniqueId(field.templateOptions.label, field.key)
                    if(selectedFields.includes(fieldUniqueId)){
                        field.value = field.value.replace(varPlaceholder, '')
                        if(field.type === 'select' || field.type === 'select-multiple' || field.type === 'ng-select') {
                            // automatically set default value as value for select fields
                            field.value = field.defaultValue;
                        }
                    }
                    return field
                })
            })
            activeNodeInfo.extras.formData = formData;
            // remove variable related inforatiom from mapping and fields
            set(activeNodeInfo.extras.extraData, 'workflow_var_mapping', { all_fields_list: [] })
        }
        
        
    }
}


export const getWorkflowConfigMappingInfo = (
    workflowConfig: WorkflowConfig,
    workflowConfigMappingInFormikContext: WorkflowConfigMappingInFormikContext,
    componentFieldsArr: ComponentFieldsInfo[]
): WorkflowConfigMappingInfo[] => {
    const workflowConfigMapping: WorkflowConfigMappingInfo[] = [];
    if(workflowConfig)
        workflowConfigMappingInFormikContext?.selectedConfigKeys?.split(",").forEach(configKey => {
            const workflowConfigItem = workflowConfig.find(config => config.key === configKey);
            if(workflowConfigItem) {
                const selectedFields = get(workflowConfigMappingInFormikContext.keyFieldMapping, configKey);
                
                const workflowConfigMappingItem: WorkflowConfigMappingInfo = {
                    ...workflowConfigItem,
                    connectedComponentFields: getComponentFieldsMapping(
                        selectedFields || '',
                        componentFieldsArr
                    )
                } 

                workflowConfigMapping.push(workflowConfigMappingItem)
            }
        })

    return workflowConfigMapping;
}

/**
 * connectedComponentFields are sorted on the basis of visibility
 * Hidden fields are first evaluated before visible fields
 * This is done as same field name can be shared by multiple fields
 * And latest visible field's selection will be considered
 */
export const updateConnectedComponentFieldValues = (connectedComponentFields: ConnectedComponentFields, itemPlaceholder: string, updatedValues: Record<string, any>, values: Record<string, any>, listOfSelectedFields: string[]) => {
    let isItemSelected = false;
    let triggerDropdownValidation = false;
    Object.values(connectedComponentFields)
        // @ts-ignore
        .sort((fieldA, fieldB) => fieldB.hidden - fieldA.hidden)
        .forEach((fieldInfo) => {
            const fieldKey = fieldInfo.fieldKey;
            // Single field can be selected in two variables
            // fieldValue is updated in first variable iteration
            // Second variable iteration has to use the fieldValue updated in the previous iteration
            // Variables = var1,var2
            // Initially fieldValue = ""
            // After first iteration fieldValue becomes {{var1}}
            // In Second iteration, if fieldValue is retrieved from values obj, it would be "" and the final value would be {{var2}}
            // Hence __updatedValues is checked first -> fieldValue = {{var1}} -> {{var1}}{{var2}}

            const fieldValue =
                get(updatedValues, fieldKey) ?? get(values, fieldKey);
            const {
                isFieldSelected,
                _value,
                _triggerDropdownValidation,
            } = getComponentFieldStatus(
                fieldValue,
                itemPlaceholder,
                fieldInfo
            );

            isItemSelected = isItemSelected || isFieldSelected;

            triggerDropdownValidation =
                _triggerDropdownValidation || triggerDropdownValidation;

            set(updatedValues, fieldKey, _value);

            if (isFieldSelected) {
                listOfSelectedFields.push(fieldKey);
            }
        });
    return {
        isItemSelected,
        triggerDropdownValidation
    }
}


export const getWorkflowConfigItemDropdownKey = (key: string) => "extraData.workflow_config_mapping.keyFieldMapping." + key