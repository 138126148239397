import { getFieldValue } from "@components/formcreators/schema-creator";
import { BaseFieldType } from "@components/formcreators/types";
import { WorkflowCanvas } from "@components/workflow-canvas";
import { DsNodeModel } from "@components/workflow-canvas/node/ds_nodemodel";
import { has } from "lodash";

const getUniqueValue = (
	currentValues: string[],
	key: string,
	currentCounter = 0
): string => {
	const updatedCounter = currentCounter + 1;
	const value = key + "__" + updatedCounter;
	if (currentValues.includes(value)) {
		return getUniqueValue(currentValues, key, updatedCounter);
	}
	return value;
};

const getRefValuesForUniqueValueField = (
	fieldKey: string,
	workflowCanvas: typeof WorkflowCanvas,
	considerSelectedNode = false
) => {
	const refValuesForUniqueValueField: (string | number)[] = [];
	Object.values(workflowCanvas.getAllNodes()).forEach((node) => {
		// Selected node is usually the active component
		if (considerSelectedNode ? !node.isSelected() : true) {
			node.extras.formData.some((field) => {
				if (field.key === fieldKey && field.type === "unique-value") {
					const fieldValue = getFieldValue(field);
					if (
						fieldValue !== "" &&
						fieldValue !== null &&
						fieldValue !== undefined
					) {
						refValuesForUniqueValueField.push(
							fieldValue as string | number
						);
					}
					return true;
				}
			});
		}
	});
	return refValuesForUniqueValueField;
};

const setDefaultValueForUniqueValueField = (
	currentComponent: DsNodeModel,
	fieldData: BaseFieldType,
	workflowCanvas: typeof WorkflowCanvas
) => {
	const refValuesForUniqueValueField = getRefValuesForUniqueValueField(
		fieldData.key,
		workflowCanvas
	);

	if (!has(fieldData, "value") && currentComponent) {
		// ExternalTaskSensorOperator__1
		// this sets defaultValue for the field if it is not set
		fieldData.defaultValue = getUniqueValue(
			refValuesForUniqueValueField.map((t) => t.toString()),
			currentComponent.extras.key
		);
	}
};

export {
	getUniqueValue,
	getRefValuesForUniqueValueField,
	setDefaultValueForUniqueValueField,
};
