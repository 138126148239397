import styled from "styled-components";

export const FileContextMenu = styled.div`
	.react-contextmenu {
		background-color: #2a2c42;
		border-right: 1px solid #6d707e;
		border-left: 1px solid #6d707e;
		min-width: 120px;
		text-align: right;

    &.hidden {
      display: none;
    }
	}

	.react-contextmenu-item {
		padding: 4px 10px;
		color: white;
    font-size: 12px;

		&:hover {
			background-color: #666a8d;
			color: #ff7761;
		}

		&:first-child {
			border-top: 1px solid #6d707e;
		}

		&:last-child {
			border-bottom: 1px solid #6d707e;
		}
	}
`;
