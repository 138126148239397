import { FieldCreatorProps, FieldsCreator } from "@components/formcreators/field-creator";
import { initialValuesCreator } from "@components/formcreators/schema-creator";
import { BaseFieldType } from "@components/formcreators/types";
import { TooltipTop } from "@components/tooltips";
import classNames from "classnames";
import { useField, useFormikContext } from "formik";
import { cloneDeep, get, range, replace } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDebounce, useFreshRef } from "rooks5";
import { splitDataFieldValue, getRepeatFieldV3Key } from "./utils";


export type RepeatV3ValueType = {
	count: number;
	repeatData: Record<string, any>[];
};

const RepeatV3: React.FC<FieldCreatorProps> = ({ fieldData }) => {
	const { values } = useFormikContext<any>();
	const [{ value }, , { setValue }] = useField<RepeatV3ValueType>(
		fieldData.key
	);
	const count = value?.count || 0;

	const fieldInitialValues = useMemo(
		() =>
			fieldData.fields
				? initialValuesCreator({ formData: fieldData.fields, extraData: values.extraData })
				: null,
		[fieldData.fields, values.extraData]
	);

	const onPlusMinusClick = useCallback(
		(type: "increase" | "decrease") => {
			if (fieldData.fields)
				if (type === "increase") {
					setValue({
						count: value.count + 1,
						repeatData: [
							...value.repeatData,
							cloneDeep(fieldInitialValues),
						],
					});
				} else {
					setValue({
						count: value.count - 1,
						repeatData: [...value.repeatData].slice(
							0,
							value.repeatData.length - 1
						),
					});
				}
		},
		[
			value,
			setValue,
			fieldData.fields,
			values.extraData,
			fieldInitialValues,
		]
	);

	const dataFieldKey = fieldData.repeat_options?.data_field || "";
	const showPlusMinusButtons = !dataFieldKey;

	const updateCount = useDebounce((newCount: number) => {
		if (value.count > newCount) {
			setValue({
				count: newCount,
				repeatData: [...value.repeatData].slice(0, newCount),
			});
		} else if (newCount > value.count) {
			const diff = newCount - value.count;
			setValue({
				count: newCount,
				repeatData: [
					...value.repeatData,
					...range(0, diff).map(() => cloneDeep(fieldInitialValues)),
				],
			});
		}
	}, 500);

	const handleUpdateCount = useFreshRef(updateCount);

	const dataFieldValue = get(values, dataFieldKey);

	useEffect(() => {
		// if dataFieldKey exists, show the fields grouping on the basis of dataFieldKey value
		if (dataFieldKey) {
			const delimiter = fieldData.repeat_options?.data_field_delimiter;
			if (delimiter && dataFieldValue) {
				const newCount = splitDataFieldValue(dataFieldValue, delimiter)
					.length;
				handleUpdateCount.current(newCount);
			}
		}
	}, [dataFieldValue]);

	// const handleSetFieldsToShow = useDebounce((currentCount: number) => {
	const fieldsToShow: BaseFieldType[][] = useMemo(() => {
		const _fieldsToShow: BaseFieldType[][] = [];
		const dataFieldValueInArr = dataFieldValue ? splitDataFieldValue(dataFieldValue, fieldData.repeat_options?.data_field_delimiter || ''): ''

		range(0, count).forEach((rep, index) => {
			const _t: BaseFieldType[] = [];
			fieldData.fields?.forEach((field) => {
				const _field = cloneDeep(field);
				_field.key =  getRepeatFieldV3Key(fieldData.key, _field.key, rep);
				if (field.hideExpression) {
					_field.hideExpression = replace(
						field.hideExpression,
						/\[index\]/g,
						`[${index}]`
					);
				}
				let _suffixForVariableLabel = ` (${index})`
				let _prefixForVariableLabel = ''
				if(dataFieldValueInArr) {
					_prefixForVariableLabel = `${dataFieldValueInArr[index]} - `
					_suffixForVariableLabel = ''
				}
				_field.labelForVariable = `${_prefixForVariableLabel}${field.templateOptions.label}${_suffixForVariableLabel}`
				_t.push(_field);
			});
			_fieldsToShow.push(_t);
		});
		return _fieldsToShow;
	}, [count, fieldData, dataFieldValue]);

	const getRepeatLabel = useCallback(
		(index) => {
			const dataFieldDelimiter =
				fieldData.repeat_options?.data_field_delimiter;
			if (dataFieldKey && dataFieldDelimiter) {
				const dataFieldValue = get(values, dataFieldKey);
				return (
					splitDataFieldValue(dataFieldValue, dataFieldDelimiter)?.[
						index
					] || ""
				);
			}
			return "";
		},
		[values, fieldData.repeat_options]
	);

	const showAddButton = fieldData.repeat_options?.showAddButtonOnly;

	const removeIdx = useCallback(
		(index: number) => {
			setValue({
				count: value.count - 1,
				repeatData: value.repeatData.filter((t, idx) => idx !== index),
			});
		},
		[value]
	);

	return (
		<div className={classNames("repeatV3", { showAddButton })}>
			{showPlusMinusButtons && !showAddButton && (
				<div className="portTicker__box">
					<span className="portType">
						{fieldData.repeatOptions?.label || "Number of Fields"}
					</span>
					<div className="portTicker__Btnbox">
						<button
							onClick={onPlusMinusClick.bind(null, "decrease")}
							disabled={count === 1}
							type="button"
							className="decrement__btn"
						>
							<span>&#8722;</span>
						</button>
						<span className={classNames("countText")}>{count}</span>
						<button
							onClick={onPlusMinusClick.bind(null, "increase")}
							type="button"
							className="increment__btn"
						>
							<span>&#43;</span>
						</button>
					</div>
				</div>
			)}
			{showAddButton && (
				<TooltipTop
					overlay={
						fieldData.repeat_options?.addButtonQtip || "Add Row"
					}
				>
					<button
						onClick={onPlusMinusClick.bind(null, "increase")}
						type="button"
						className="add-button"
					>
						<img src="/icons/add_tab.svg" />
					</button>
				</TooltipTop>
			)}

			{fieldsToShow.map((fieldDataGroup, index) => {
				const label = getRepeatLabel(index);
				return (
					<div key={index} className="repeatV3__fieldsGroup">
						{!!label && (
							<label className="fieldsGroup__name">{label}</label>
						)}
						{showAddButton && (
							<TooltipTop overlay="Remove Row">
								<button
									onClick={removeIdx.bind(null, index)}
									type="button"
									className="remove-button"
								>
									<img src="/icons/clear_selection.svg" />
								</button>
							</TooltipTop>
						)}
						<FieldsCreator formData={fieldDataGroup} />
					</div>
				);
			})}
		</div>
	);
};

export default RepeatV3;
