import React, { createContext, useContext, useEffect, useMemo, useReducer } from "react";
import cloneDeep from 'lodash/cloneDeep'
import { AI_QUERY, AI_RESPONSE, GenAIContextType, GenAIReducerAction, GenAIReducerState, HISTORY, MESSAGE_TYPE, NEXT_INPUT_OPTIONS, NEXT_INPUT_TYPE } from "./types";
import { DataSourceHandler } from "@api/data-source-handler";
import isEmpty from "lodash/isEmpty";
import { useDataExplorerContext } from "../useDataExplorerContext";

const genAIReducerInitialState: GenAIReducerState = {
	aiResponseList: [],
	inputDisabled: false,
	loading: false,
	logLevel: ['DEBUG', 'INFO'],
	sessionId: '',
	_sessionId: '',
	sqlQuery: '',
	isNextActionEnabled: false,
	nextAction: {
		next_input_type: 'MULTI_SELECT',
		next_input_options: {
			choices: [],
			defaults: [],
		},
		query_uuid: '',
		isFeedbackSubmitted: false
	},
	error: {
		state: false,
		message: ''
	},
	history: undefined,
	newData: -1,
	resultMode: 'sql',
	injection: {
		persist: false,
		value: ''
	},
	isCygnet: false
};

const genAIReducer = (
	state: GenAIReducerState,
	action: GenAIReducerAction
): GenAIReducerState => {
	switch (action.type) {
		case "APPEND_CONTENT":
			return {
				...state,
				...{
					aiResponseList: [...state.aiResponseList, {
						content: action.payload.content,
						message_type: action.payload.message_type,
						timeStamp: new Date(),
						isFeedbackSubmitted: false,
						query_uuid: action.payload.query_uuid,
						noFeedback: action.payload.noFeedback
					}]
				}
			}
		case "SQL_QUERY":
			return {
				...state,
				sqlQuery: action.payload.sql
			}
		case 'NEXT_ACTION':
			return {
				...state,
				...{
					showNextAction: true,
					nextAction: {
						next_input_options: action.payload.next_input_options,
						next_input_type: action.payload.next_input_type,
						message: action.payload.message,
						query_uuid: action.payload.query_uuid,
						isFeedbackSubmitted: false
					}
				}
			}
		case "SHOW_NEXT_ACTION":
			return {
				...state,
				...{ isNextActionEnabled: action.payload.showNextAction }
			}
		case "LOG_LEVEL":
			return {
				...state,
				...{ logLevel: action.payload.log_level }
			}
		case "LOADING":
			return {
				...state,
				...{
					loading: action.payload.loading
				}
			}
		case "USER_INPUT":
			return {
				...state,
				...{ inputDisabled: action.payload.inputDisabled }
			}
		case "SESSION":
			return {
				...state,
				...{ sessionId: action.payload.sessionId }
			}
		case "SESSION_ID":
			return {
				...state,
				...{ _sessionId: Date.now() + '' }
			}
		case "UPDATE_CONTENT":
			return {
				...state,
				aiResponseList: [...action.payload.data]
			}
		case "ERROR_STATE":
			return {
				...state,
				error: {
					state: action.payload.state,
					message: action.payload.message
				}
			}
		case "SET_FEEDBACK": {
			const aiResponseList = state.aiResponseList;
			const nextAction = state.nextAction;
			if (action.payload.itemNumber !== -1) {
				const item = aiResponseList[action.payload.itemNumber]
				item.isFeedbackSubmitted = true;
			} else {
				nextAction.isFeedbackSubmitted = true;
			}

			return {
				...state, ...{
					aiResponseList,
					nextAction
				}
			}
		}
		case "RESET": {
			const temp = cloneDeep(genAIReducerInitialState)
			temp.aiResponseList.push({
				content: 'Hi, I am your Generative AI - Data Exploration Assistant, here to help you get answers from your knowledge graph.',
				message_type: 'ai',
				timeStamp: new Date(),
				isFeedbackSubmitted: false,
				noFeedback: true,
				query_uuid: '0'
			})
			return {
				...temp,
				isCygnet: state.isCygnet,
				resultMode: state.resultMode,
				trigger: state.trigger,
				setHistory: state.setHistory,
				sessionId: sessionStorage.getItem("sessionId") || '',
				_sessionId: ''
			}
		}

		case "SET_HISTORY":
			return {
				...state,
				history: action.payload.history
			}
		case "SET_CALLBACK":
			return {
				...state,
				...{
					trigger: action.payload.trigger,
					setHistory: action.payload.setHistory,
				}
			}
		case "GET_NEW_DATA":
			return {
				...state,
				...{
					newData: Date.now()
				}
			}
		case "SET_RESULT_MODE":
			return {
				...state,
				...{
					resultMode: action.payload.result
				}
			}
		case "SET_INJECTION_VALUE":
			return {
				...state,
				...{
					injection: {
						...state.injection,
						value: action.payload.value
					}
				}
			}
		case "SET_INJECTION_PERSIST":
			return {
				...state,
				...{
					injection: {
						...state.injection,
						persist: action.payload.persist
					}
				}
			}
		case "SET_CYGNET_VALUE":
			return {
				...state,
				...{
					isCygnet: action.payload.value
				}
			}
		default:
			return state;
	}
};

const GenAIContext = createContext<GenAIContextType>(
	{} as GenAIContextType
);

export const GenAIProvider: React.FC<{
	children: React.ReactNode | React.ReactNodeArray
}> = ({ children }) => {
	const [state, dispatch] = useReducer(genAIReducer, cloneDeep(genAIReducerInitialState));
	const { database } = useDataExplorerContext();

	useEffect(() => {
		dispatch({
			type: 'RESET'
		});

	}, [database])

	const setError = (value: boolean, message?: string) => {
		dispatch({
			type: 'ERROR_STATE',
			payload: {
				state: value,
				message: message || ""
			}
		})
	}

	const setLoading = (value: boolean) => {
		dispatch({
			type: 'LOADING',
			payload: {
				loading: value
			}
		})
	}

	const setSession = (value: string) => {
		dispatch({
			type: 'SESSION',
			payload: {
				sessionId: value
			}
		})
	}

	const set_Session = () => {
		dispatch({
			type: 'SESSION_ID',
		})
	}

	const setSqlQuery = (value: string) => {
		dispatch({
			type: 'SQL_QUERY',
			payload: {
				sql: value
			}
		})
	}

	const setNextAction = (next_input_type: NEXT_INPUT_TYPE, next_input_options: NEXT_INPUT_OPTIONS, query_uuid: string, message?: string) => {
		dispatch({
			type: 'NEXT_ACTION',
			payload: {
				next_input_options,
				next_input_type,
				message,
				query_uuid
			}
		})
	}

	const showNextAction = (value: boolean) => {
		dispatch({
			type: 'SHOW_NEXT_ACTION',
			payload: {
				showNextAction: value
			}
		})
	}

	const addContent = (content: string | string[], message_type: MESSAGE_TYPE, query_uuid: string, noFeedback?: boolean) => {
		dispatch({
			type: 'APPEND_CONTENT',
			payload: {
				content,
				message_type,
				query_uuid,
				noFeedback
			}
		})
	}

	const disableInput = (value: boolean) => {
		dispatch({
			type: 'USER_INPUT',
			payload: {
				inputDisabled: value
			}
		})
	}

	const setHistoryState = (history: HISTORY) => {
		state.setHistory && state.setHistory(history)
		dispatch({
			type: 'SET_HISTORY',
			payload: {
				history
			}
		})
	}

	const getNewData = () => {
		dispatch({
			type: 'GET_NEW_DATA',
		})
	}

	const updateContent = (index: number, value: string | string[]) => {
		let index1 = index;
		if (index === -1) {
			index1 = state.aiResponseList.length - 1
		}
		const data = [...state.aiResponseList]
		const content = data[index1].content
		if (Array.isArray(value)) {
			data[index1].content = content + " - [" + value.join(',') + "]"
		} else {
			data[index1].content = content + " \n " + value
		}
		dispatch({
			type: 'UPDATE_CONTENT',
			payload: {
				data
			}
		})
	};

	const setCallback = (
		trigger: (sql: string) => void,
		setHistory: (history: HISTORY) => void,
	) => {
		dispatch({
			type: 'SET_CALLBACK',
			payload: {
				trigger,
				setHistory
			}
		})
	}

	const setResultMode = (
		result: string
	) => {
		dispatch({
			type: 'SET_RESULT_MODE',
			payload: {
				result
			}
		})
	}

	const setInjectionPersist = (persist: boolean) => {
		dispatch({
			type: 'SET_INJECTION_PERSIST',
			payload: {
				persist
			}
		})
	}

	const setInjectionValue = (value: string) => {
		dispatch({
			type: 'SET_INJECTION_VALUE',
			payload: {
				value
			}
		})
	}


	const setCygnetValue = (value: boolean) => {
		dispatch({
			type: 'SET_CYGNET_VALUE',
			payload: {
				value
			}
		})
	}

	const setFeedBack = (itemNumber: number) => {
		dispatch({
			type: 'SET_FEEDBACK',
			payload: {
				itemNumber
			}
		})
	}

	const runQuery = async (content: string, next_action?: string, choices?: any, human_message?: any, query_uuid?: string, username?: string) => {
		setLoading(true);
		setError(false);
		try {
			const aiContent: AI_QUERY = {
				human_message: human_message || {
					content: content.trim()
				},
				// chat_config: {
				// 	return_data: false
				// }
			}

			if (state.injection.value) {
				aiContent.human_message.user_prompt_injection = state.injection.value
			}
			if (!state.injection.persist) {
				setInjectionValue('');
			}
			if (!human_message) {
				if (next_action) {
					aiContent.human_message.next_action = next_action
				} else if (!isEmpty(choices)) {
					if (choices?.length === 1 && typeof (choices[0]) === "object") {
						aiContent.human_message.choices = choices,
							aiContent.human_message = { ...aiContent.human_message, return_query_errors: true }
					} else {
						aiContent.human_message.choices = choices
					}
				} else {
					addContent(content, 'human', query_uuid || '', true)
				}
			}

			showNextAction(false)
			const resp = state.isCygnet ? await DataSourceHandler.CygnetSQLChat(state.sessionId, aiContent) : await DataSourceHandler.DeepSQLChat(database.id, state.sessionId, aiContent, username || "")
			const data: AI_RESPONSE = resp.data
			setSqlQuery(data.history.scratchpad.sql)
			if (resp.data.history) {
				setHistoryState(resp.data.history)
			}

			//On first time we get the session id
			if (isEmpty(state.sessionId)) {
				setSession(data.session_id)
				sessionStorage.setItem("sessionId", data.session_id)
				set_Session()
			} else {
				set_Session()
			}

			// if sql is generated run the query in the data explorer
			if (data.ai_message.current_action === "GEN_SQL") {
				if (data.ai_message.output?.is_sql_valid && state.resultMode === 'spark') {
					setSqlQuery(data.ai_message.output.output_value as any)
					state.trigger && state.trigger(data.ai_message.output.output_value as any)
				} else {
					if (data.ai_message.return_data) {
						getNewData()
					}
				}
			}
			switch (data.ai_message.next_input_type) {
				case "BUTTONS":
					disableInput(true);
					setNextAction(
						data.ai_message.next_input_type,
						data.ai_message.next_input_options,
						data.ai_message.query_uuid,
						data.ai_message.content
					)
					showNextAction(true)
					break;

				case "MULTI_SELECT":
					disableInput(true);
					setNextAction(
						data.ai_message.next_input_type,
						data.ai_message.next_input_options,
						data.ai_message.query_uuid,
						data.ai_message.content
					)
					showNextAction(true)
					break;
				case "MULTI_SINGLE_SELECT":
					disableInput(true);
					setNextAction(
						data.ai_message.next_input_type,
						data.ai_message.next_input_options,
						data.ai_message.query_uuid,
						data.ai_message.content
					)
					showNextAction(true)
					break;
				case "TEXT":
					addContent(
						data.ai_message.content,
						data.ai_message.message_type,
						data.ai_message.query_uuid
					)
					disableInput(false)
					break;
				default:
					disableInput(false)
			}
			setLoading(false);
			if (data.ai_message.return_data) {
				getNewData()
			}
		} catch (e: any) {
			disableInput(false)
			setLoading(false);
			setError(true, e.message || "Something went wrong, please try after sometime");
		}
	}

	const _analyticsContext: GenAIContextType = useMemo(() => {
		return {
			...state,
			sendGenAIReducerCmd: dispatch,
			setLoading,
			setSession,
			setSqlQuery,
			setNextAction,
			showNextAction,
			addContent,
			runQuery,
			disableInput,
			updateContent,
			setError,
			setCallback,
			setResultMode,
			setInjectionPersist,
			setInjectionValue,
			setCygnetValue,
			setFeedBack
		};
	}, [state, dispatch]);

	return (
		<GenAIContext.Provider value={_analyticsContext}>
			{children}
		</GenAIContext.Provider>
	);
};

export const useGenAIContext = () => {
	return useContext(GenAIContext);
};

