import React, { useState, useRef, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WorkflowHandler } from '../../../api/workflow-handler';
import { SerializedData, WorkflowCanvas } from '../../../components/workflow-canvas';
import { Env, isDevelopment } from '../../../constants/settings';
import { updateAWorkflowEditorTabInfo } from '../../../store/canvas/action';
import { RootState } from '../../../store/types';
import { saveActiveWorkflow, saveWorkflow } from '../../../store/workflow/actions';
import { addRunTillHereIdIfExists, convertWorkflowDataForExecution } from '../utils';
import { cloneDeep, isEmpty } from 'lodash';
import { getActiveWorkflowType } from '../../../utils';
import { successAlert } from '../../../components/toastify/notify-toast';
import { extractContent, getNewOrder } from './codeEditorHelper';
import { Graph } from './types';
import { DiagramModel, DiagramModelGenerics } from '@projectstorm/react-diagrams';
import { convertSerializedWorkflowDataToModel } from '../../../components/workflow-canvas/utils';
import './code-editor.scss';
import { ExecutionEnvModes } from '../../../constants/enums';

export const CodeEditor: React.FC<{}> = () => {
    const notebook = '';
    const dispatch = useDispatch();
    const [workflowId, setWorkFlowID] = useState(-1);
    const { activeTab, openTabs } = useSelector((store: RootState) => store.CanvasReducer.workflowEditor);
    const { isPropertiesMinimized, isComponentTreeviewMinimized } = useSelector((store: RootState) => store.WorkflowReducer);
    const { activeExecutionEnv } = useSelector((store: RootState) => store.CommonReducer);
    const { activeUserInfo } = useSelector((store: RootState) => store.AccountReducer);
    const openedTabs = useRef<any>({});
    const openTabsRef = useRef(openTabs);
    const activeTabRef = useRef(activeTab);
    const activeExecutionEnvRef = useRef(activeExecutionEnv);
    const workSpaceData = useSelector((store: RootState) => store.ClusterReducer.workspaceList);
    const enabledWorkspace = workSpaceData?.filter((item)=>item.isEnabled)?.[0];

    const deletePreviousIframes = (id: string) => {
        const dom = document.getElementById('iframe_' + id);
        if (dom) {
            document.getElementById('iframe_holder')?.removeChild(dom);
        }
    };

    const deletePreviousAllIframes = () => {
        const dom = document.getElementById('iframe_holder');
        dom && (dom.innerHTML = '');
    };

    const setIframeClassNames = () => {
        // if(isPropertiesMinimized){
        //     ref.current?.classList.add('iframeMinized');
        // } else {
        //     ref.current?.classList.remove('iframeMinized');
        // }
        // if(isComponentTreeviewMinimized) {
        //     ref.current?.classList.add('iframeMinized_lhs');
        // } else {
        //     ref.current?.classList.remove('iframeMinized_lhs');
        // }
    };

    const createIframe = (id: string, notebookId: string) => {
        const doc = document.createElement('iframe');
        doc.src = `${isDevelopment ? 'https://dev.deepiqlab.com' : ''}/lib/zeppelin/index.html#/notebook/${notebookId}?workflowId=${id}&env=${activeExecutionEnvRef.current}`;
        if (Env.isZepplinInTestMode) {
            doc.src = `http://localhost:9001/index.html#/notebook/${notebookId}?workflowId=${id}&env=${activeExecutionEnvRef.current}`;
        }
        doc.classList.add('codeEditor');
        if (isPropertiesMinimized) {
            doc.classList.add('iframeMinized');
        }
        if (isComponentTreeviewMinimized) {
            doc.classList.add('iframeMinized_lhs');
        }
        doc.classList.add('z100');
        document.getElementById('iframe_holder')?.appendChild(doc);
        doc.id = 'iframe_' + id;
    };

    useEffect(() => {
        setIframeClassNames();
    }, [isPropertiesMinimized, isComponentTreeviewMinimized]);


    useEffect(() => {
        deletePreviousAllIframes();
    }, [notebook]);

    useEffect(() => {
        openTabsRef.current = openTabs;
    }, [openTabs]);

    useEffect(() => {
        activeTabRef.current = activeTab;
    }, [activeTab]);

    useEffect(() => {
        activeExecutionEnvRef.current = activeExecutionEnv;
    }, [activeExecutionEnv]);
    

    useEffect(() => {
        if (workflowId === -1) {
            setWorkFlowID(activeTabRef.current.id as number);
        } else {
            if (openedTabs.current[activeTabRef.current.id]) {
                const obj = openTabsRef.current.get(activeTabRef.current.id);
                if (obj && !openedTabs.current[activeTabRef.current.id].isOpenedOnce && obj.info.showCodeEditor) {
                    createIframe(obj.info.id + '', obj.info.zeppelinNotebookId as string);
                    openedTabs.current[activeTabRef.current.id].isOpenedOnce = true;
                } else if (obj && openedTabs.current[activeTabRef.current.id].isOpenedOnce && obj.info.showCodeEditor && !openedTabs.current[activeTabRef.current.id].isCodeDownload) {
                    downloadWorkflow(obj.info.id + '', activeExecutionEnvRef.current);
                }
            }
        }

    }, [activeTab]);

    const displayActiveIframe = () => {
        document.querySelectorAll('#iframe_holder iframe').forEach((dom) => {
            dom.classList.remove('z1000');
        });
        document.getElementById('iframe_' + activeTabRef.current.id)?.classList.add('z1000')
    };

    useEffect(displayActiveIframe, [activeTab]);

    useEffect(() => {
        const tem: any = {};
        openTabsRef.current.forEach((_obj) => {
            tem[_obj.info.id] = true;
            if (!openedTabs.current[_obj.info.id] && !isEmpty(_obj.info.zeppelinNotebookId) && _obj.info.showCodeEditor) {
                if (activeTabRef.current.id === _obj.info.id) {
                    openedTabs.current[_obj.info.id] = {
                        isOpenedOnce: true,
                        isIframeReady: false,
                        isCodeDownload: false
                    };
                    createIframe(_obj.info.id + '', _obj.info.zeppelinNotebookId as string);
                } else {
                    openedTabs.current[_obj.info.id] = {
                        isOpenedOnce: false,
                        isIframeReady: false,
                        isCodeDownload: false
                    };
                }

            } else {
                if (openedTabs.current[_obj.info.id] && !_obj.info.showCodeEditor) {
                    deletePreviousIframes(_obj.info.id + '');
                    delete openedTabs.current[_obj.info.id];
                }
            }
        });

        Object.keys(openedTabs.current).forEach((_key) => {
            if (!tem[_key]) {
                deletePreviousIframes(_key);
                delete openedTabs.current[_key];
            }
        });

        displayActiveIframe();
    }, [openTabs]);

    const getWorkflowInfo = (_CanvasModel: DiagramModel<DiagramModelGenerics>) => {
        const activeTabInfo = openTabsRef.current.get(activeTabRef.current.id);
        const workflowData = convertWorkflowDataForExecution(_CanvasModel, true, activeExecutionEnvRef.current, { workflowName: activeTab.name, userName: activeUserInfo.name }, activeTabInfo?.info.config);
        workflowData.nodes = dispatch(addRunTillHereIdIfExists(workflowData.nodes)) as any;
        return workflowData;
    };

    const getParaHeader = () => {
        const activeTabInfo = openTabsRef.current.get(activeTabRef.current.id);
        return Env.databricks ? activeTabInfo?.info.activeClusterInfoForZeppelin ? `%jupyter(kernel=${activeTabInfo.info.activeClusterInfoForZeppelin.name})` : '%jupyter' : Env.zeppelin_paragraph_header;
    };

    const downloadWorkflow = (_workflowId: string, env: ExecutionEnvModes) => {
        const workflowInfo = openTabsRef.current.get(Number(_workflowId))?.info;
        if (!workflowInfo) return;

        let _CanvasModel: DiagramModel<DiagramModelGenerics>;
        if (Number(_workflowId) === activeTabRef.current.id) {
            _CanvasModel = WorkflowCanvas.model
        } else {
            _CanvasModel = convertSerializedWorkflowDataToModel(workflowInfo.details as SerializedData)
        }
        
        setTimeout(() => {
            let workflowData:any = getWorkflowInfo(_CanvasModel);
            workflowData = {...workflowData,workspaceType:enabledWorkspace?.workspaceType};
            const version = 'v1';
            if (openedTabs.current[Number(_workflowId)]) {
                openedTabs.current[Number(_workflowId)].isCodeDownload = true;
            }
            if (workflowData) {
                WorkflowHandler.DownloadWorkflow(env, workflowData, (response) => {
                    const dataEmptyArray: Graph = {
                        graph: {
                            noBinding: []
                        },
                        workbook_to_zepplin: {

                        },
                        position: [],
                        timestamp: Date.now(),
                        version: version
                    };
                    let isEmpty = false;

                    const out = extractContent(response.script, getParaHeader());
                    let zepplinData = workflowInfo.zepplinData as any;

                    if (zepplinData) {
                        if (typeof zepplinData === 'string') {
                            zepplinData = JSON.parse(zepplinData);
                        }
                        //if it is empty array
                        if (Array.isArray(zepplinData) || zepplinData.version !== version) {
                            zepplinData = dataEmptyArray;
                            isEmpty = true;
                        }
                    } else {
                        zepplinData = dataEmptyArray;
                        isEmpty = true;
                    }
                    const temp = getNewOrder(out.arr, zepplinData, out.dataArray);
                    (document.getElementById("iframe_" + _workflowId) as HTMLIFrameElement)?.contentWindow?.postMessage({
                        type: 'deepiq_zeppelin',
                        paragraph_str: JSON.stringify(out.arr),
                        zeppelinData: JSON.stringify(temp.zepplinData),
                        addComponent: JSON.stringify(temp.addComponent),
                        removeComponent: JSON.stringify(temp.removeComponent),
                        header: getParaHeader() + '\n',
                        token: localStorage.getItem('token'),
                        isFirst: isEmpty,
                        workflowId: _workflowId
                    }, '*');
                });
            }
        }, 100)
    };

    useEffect(() => {
        const obj = (e: any) => {
            switch (e.data.key) {
                case 'zeppelin-init':
                    if (openedTabs.current[Number(e.data.workflowId)]) {
                        openedTabs.current[Number(e.data.workflowId)].isIframeReady = true;
                    }
                    return downloadWorkflow(e.data.workflowId, e.data.env);
                    // if(activeTab.id === Number(e.data.workflowId)) {
                    //     return downloadWorkflow(e.data.workflowId);
                    // };
                    break;
                case 'zeppelin-update': {
                    if (!e.data.workflowId) {
                        return;
                    }
                    const val: any = JSON.parse(e.data.value);
                    if (Number(e.data.workflowId) === activeTabRef.current.id) {
                        if (Array.isArray(val) && val.length) {
                            val.forEach((obj: any) => {
                                const o = (WorkflowCanvas.model as any).activeNodeLayer.models[obj.c_id.replace('custom_', '')];
                                if (o) {
                                    obj.para = (obj.para || '').replace(`${getParaHeader()}\n`, '')
                                        .replace('#DEEPIQ COMPONENT - DON\'T DELETE THIS LINE', '').trim();
                                    o.extras.customComponentPayload = obj.para;
                                }
                            });
                        }
                        const activeWorkflow = cloneDeep(openTabsRef.current.get(activeTabRef.current.id));
                        if (activeWorkflow && activeWorkflow.info.showCodeEditor) {
                            !e.data.onlySave && (activeWorkflow.info.showCodeEditor = false);
                            activeWorkflow.info.zepplinData = e.data.zeppelinData;
                            dispatch(updateAWorkflowEditorTabInfo(activeWorkflow));
                        }

                        setTimeout(() => {
                            const handleSaveWorkflowSuccess = () => {
                                !e.data.onlySave && successAlert('Successfully saved ' + getActiveWorkflowType(activeExecutionEnvRef.current));
                            };
                            dispatch(saveActiveWorkflow(handleSaveWorkflowSuccess, false, 'workflowEditor'));
                        }, 100);

                        return;
                    } else {
                        const activeWorkflow = cloneDeep(openTabsRef.current.get(Number(e.data.workflowId)));
                        if (activeWorkflow) {
                            const details = JSON.parse(activeWorkflow.info.details as string);
                            const diagramNodes = details.layers.find((layer: any) => layer.type === 'diagram-nodes')

                            if (Array.isArray(val) && val.length) {
                                val.forEach((obj: any) => {
                                    const o = diagramNodes.models[obj.c_id.replace('custom_', '')]
                                    if (o) {
                                        obj.para = (obj.para || '').replace(`${getParaHeader()}\n`, '')
                                            .replace('#DEEPIQ COMPONENT - DON\'T DELETE THIS LINE', '').trim();
                                        o.extras.customComponentPayload = obj.para;
                                    }
                                });
                            }
                            activeWorkflow.info.details = JSON.stringify(details);

                            if (activeWorkflow && activeWorkflow.info.showCodeEditor) {
                                !e.data.onlySave && (activeWorkflow.info.showCodeEditor = false);
                                activeWorkflow.info.zepplinData = e.data.zeppelinData;
                                dispatch(updateAWorkflowEditorTabInfo(activeWorkflow));
                            }

                            setTimeout(() => {
                                const handleSaveWorkflowSuccess = () => {
                                    !e.data.onlySave && successAlert('Successfully saved ' + getActiveWorkflowType(activeExecutionEnvRef.current));
                                };
                                dispatch(saveWorkflow(handleSaveWorkflowSuccess, 'workflowEditor', Number(e.data.workflowId)));
                            }, 100);
                        }
                    }
                }
            }
        };

        window.addEventListener('message', obj);
        return () => {
            window.removeEventListener('message', obj);
        };
    }, []);

    return (
        <div id="iframe_holder"></div>
    );
};

export default memo(CodeEditor);