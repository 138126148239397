import { Modal } from '@components/modals'
import { KeycloakService } from '@services/keycloak';
import { useAppSelector } from '@store/hooks'
import React from 'react'
import { useIntervalWhen } from 'rooks5';
import { useSelector } from 'react-redux';
import { RootState } from '@store/types';
import { LimitReachedIcon } from './icons';
import styles from './styles.module.scss';


const ActiveSessionsModal = () => {
    const activeSessions = useAppSelector(store => store.AccountReducer.activeSessionsInfo)
    const { activeUserInfo: { username: loggedUserName } } = useSelector((store: RootState) => store.AccountReducer);
    const lastLoggedUserName = activeSessions?.activeUsersList[activeSessions?.activeUsersList?.length-1]?.username;
    const isLastLoggedUserIsYou = lastLoggedUserName === loggedUserName;
    const showModal = (activeSessions !== null && !activeSessions?.isLoginAllowed) && isLastLoggedUserIsYou ;
    const [timer, setTimer] = React.useState(7);
    
    useIntervalWhen(() => {
        setTimer(timer - 1);
    }, 1000, showModal && timer > 0)

    if(timer < 2) {
        KeycloakService.logoutWithoutClearingLocalStorage()
    }


    return(
        <Modal
            isOpen={showModal}
            title="Limit Reached"
            subtitle={`You are licensed to use this software on ${activeSessions?.allowedConcurrentUsers} accounts`}
            shouldCloseOnOverlayClick={false}
            toggleClose={() => {return;}}
            image={<LimitReachedIcon />}
            className={styles.modal}
            showCloseMark={false}
        >
            <div className={styles.activeUsersBox}>
                <p>Users</p>
                <ul className={styles.activeUsersList}>
                    {(activeSessions?.activeUsersList)?.map((user) => (
                        <li key={user.id}>
                            {user.username}
                        </li>
                    ))}
                </ul>
            </div>
            <p className={styles.willBeLoggedOutMessage}>To continue, please sign out of at least one more account and try again.<br /> 
            You&apos;ll be logged out in {timer} second{timer > 1 ? 's': ''}.</p>
        </Modal>
    )

}

export default ActiveSessionsModal;