import { RadioField } from "@components/form/radio-field";
import { errorAlert } from "@components/toastify/notify-toast";
import { ShowWhenTrue } from "@helpers/showwhentrue";
import WorkflowConfigTab from "@pages/workflow-page/RHS-Tabs/WorkflowConfigTab";
import {
	getWorkflowConfigSelectionKey,
	GlobalPipelineConfigMap,
	validateWorkflowConfig,
	WorkflowConfigSelectionType,
} from "@services/WorkflowConfig";
import {
	WorkflowConfig,
	WorkflowConfigSelectionInFormikContext,
} from "@services/WorkflowConfig";
import { convertEnumsToOptions } from "@utils/common";
import { useField, useFormikContext } from "formik";
import { isArray, isEmpty } from "lodash";
import React, {
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from "react";
import WorkflowNArgsModal from "./workflowNArgsModal";

export type WorkflowConfigSelectionProps = {
	stringifiedConfig: string;
	fieldKey: string;
	globalPipelineConfig: GlobalPipelineConfigMap | undefined;
};

const workflowConfigSelectionTypeOptions = convertEnumsToOptions(
	WorkflowConfigSelectionType
);

const getConfigItemPositionMapping = (config: WorkflowConfig) =>
	config.reduce((positioning, configItem, index) => {
		positioning[configItem.key] = index;
		return positioning;
	}, {} as Record<string, number>);

export type WorkflowConfigSelectionRef = {
	onWorkflowChange: (config: string) => void;
};

// ref is used instead of moving the useField state up because workflowField
// shouldnt be concerned with workflowConfigSelection fields
const WorkflowConfigSelection = React.forwardRef<
	WorkflowConfigSelectionRef,
	WorkflowConfigSelectionProps
>(({ stringifiedConfig, fieldKey, globalPipelineConfig }, ref) => {
	const { setFieldValue } = useFormikContext<any>();

	const [
		oldWorkflowConfigSelectionKey,
		newWorkflowConfigSelectionKey,
	] = useMemo(
		() =>
			// extraData.workflow.workflowConfigSelection, extraData.workflow.__workflowConfigSelection
			[
				`extraData.${getWorkflowConfigSelectionKey("old", fieldKey)}`,
				`extraData.${getWorkflowConfigSelectionKey("new", fieldKey)}`,
			],
		[]
	);
	// old format
	const [, { initialValue }] = useField<WorkflowConfig>(
		oldWorkflowConfigSelectionKey
	);
	// new format
	const [
		,
		{
			initialValue: initialWorkflowConfigSelectionInfo,
			value: workflowConfigSelectionInfo,
		},
		{ setValue: setNewFormatValue },
	] = useField<WorkflowConfigSelectionInFormikContext>(
		newWorkflowConfigSelectionKey
	);

	const isMountedRef = useRef(false);
	const [showWorfklowNArgsModal, toggleNArgsModal] = useState(false);
	const [workflowConfig, setWorkflowConfig] = useState<
		WorkflowConfig<"component">
	>([]);
	const [validations, setValidations] = useState({
		areKeysAddedorDeleted: false,
		areValuesUpdated: false,
		isGlobalPipelineConfigUpdated: false,
	});

	useImperativeHandle(
		ref,
		() => ({
			onWorkflowChange: (config) => {
				if (config) {
					try {
						const _config = JSON.parse(config) as WorkflowConfig;
						// to convert number datatype
						const { workflowConfig } = validateWorkflowConfig(
							_config,
							_config,
							getConfigItemPositionMapping(_config),
							globalPipelineConfig
						);
						setWorkflowConfig(workflowConfig);
						onWorkflowNArgsUpdate([workflowConfig], []);
					} catch (e) {
						/* eslint-disable no-console */
						console.log(e);
						errorAlert("Error loading workflow config");
					}
				} else {
					setWorkflowConfig([]);
					onWorkflowNArgsUpdate([], []);
				}
				setValidations({
					areKeysAddedorDeleted: false,
					areValuesUpdated: false,
					isGlobalPipelineConfigUpdated: false,
				});
			},
		}),
		[globalPipelineConfig]
	);

	useEffect(() => {
		// This is only valid when user has already selected workflow config
		// if keys in stored config (initialValue) and latest config of workflow (user can change the config inside the workflow) dont match
		// Add new keys of the latest config in stored config and remove deleted keys of latest config
		if (stringifiedConfig) {
			try {
				const latestWorkflowConfig = JSON.parse(
					stringifiedConfig
				) as WorkflowConfig;
				const initialWorkflowConfig =
					initialWorkflowConfigSelectionInfo?.config || initialValue;

				const configItemPositionMapping = getConfigItemPositionMapping(
					latestWorkflowConfig
				);

				if (
					!isMountedRef.current &&
					!!initialWorkflowConfig &&
					!isEmpty(initialWorkflowConfig) &&
					isArray(latestWorkflowConfig)
				) {
					const {
						workflowConfig,
						areKeysAddedorDeleted,
						areValuesUpdated,
						isGlobalPipelineConfigUpdated,
					} = validateWorkflowConfig(
						initialWorkflowConfig,
						latestWorkflowConfig,
						configItemPositionMapping,
						globalPipelineConfig
					);


					if (initialWorkflowConfigSelectionInfo?.nArgsConfig) {
						// update config in nArgs if config is updated
						const updatedNArgs = initialWorkflowConfigSelectionInfo.nArgsConfig.map(
							(nArgConfig) =>
								validateWorkflowConfig(
									nArgConfig,
									latestWorkflowConfig,
									configItemPositionMapping,
									globalPipelineConfig
								).workflowConfig
						);
						onWorkflowNArgsUpdate(
							updatedNArgs,
							initialWorkflowConfigSelectionInfo.comments
						);
					}

					setWorkflowConfig(workflowConfig);
					setValidations({
						areKeysAddedorDeleted,
						areValuesUpdated,
						isGlobalPipelineConfigUpdated,
					});
				}

				isMountedRef.current = true;
			} catch (e) {
				/* eslint-disable no-console */
				console.log(e);
				errorAlert("Error loading workflow config");
			}
		}
	}, [
		stringifiedConfig,
		initialValue,
		initialWorkflowConfigSelectionInfo,
		globalPipelineConfig,
	]);

	useEffect(() => {
		// set new format
		if (!workflowConfigSelectionInfo?.type && !isEmpty(workflowConfig)) {
			const nArgsConfig = [workflowConfig];
			const _value: WorkflowConfigSelectionInFormikContext = {
				config: workflowConfig,
				type: WorkflowConfigSelectionType.Default,
				comments: [],
				nArgsConfig,
			};
			setNewFormatValue(_value);
		}
	}, [workflowConfigSelectionInfo, workflowConfig]);

	const onWorkflowConfigUpdate = useCallback(
		(config: WorkflowConfig) => {
			setWorkflowConfig(config as WorkflowConfig<"component">);
		},
		[setWorkflowConfig]
	);

	useEffect(() => {
		if (isMountedRef.current) {
			// setValue(workflowConfig);
			setFieldValue(
				`${newWorkflowConfigSelectionKey}.config`,
				workflowConfig
			);
		}
	}, [workflowConfig]);

	const onWorkflowNArgsUpdate = (
		nArgsConfig: WorkflowConfig<"component">[],
		comments: string[]
	) => {
		setFieldValue(
			`${newWorkflowConfigSelectionKey}.nArgsConfig`,
			nArgsConfig
		);
		setFieldValue(`${newWorkflowConfigSelectionKey}.comments`, comments);
	};

	return (
		<div className="workflowConfigList__insideComponent">
			{!isEmpty(workflowConfig) && (
				<>
					<WorkflowNArgsModal
						isOpen={showWorfklowNArgsModal}
						toggleClose={() => toggleNArgsModal(false)}
						config={workflowConfig}
						nArgsConfig={workflowConfigSelectionInfo?.nArgsConfig}
						comments={workflowConfigSelectionInfo?.comments}
						onWorkflowNArgsUpdate={onWorkflowNArgsUpdate}
						fieldKey={fieldKey}
						globalPipelineConfig={globalPipelineConfig}
					/>
					<RadioField
						name={`${newWorkflowConfigSelectionKey}.type`}
						options={workflowConfigSelectionTypeOptions}
						label="Configurations"
						className="workflowConfig__configurationType"
					/>
					<ShowWhenTrue show={validations.areKeysAddedorDeleted || validations.isGlobalPipelineConfigUpdated}>
						<div className="validationMessages">
							{validations.areKeysAddedorDeleted && (
								<p>
									The list of keys are auto-refreshed to fetch
									the latest updates made in the workflow.
								</p>
							)}
							{validations.isGlobalPipelineConfigUpdated && (
								<p>
									The list of keys are auto-refreshed to fetch
									the latest updates made in the global arguments.
								</p>
							)}
							{/* {(validations.areValuesUpdated) && (
								<>
									<p>
										There are changes made to the values. Please refresh to fetch the latest updates.
									</p>
									<button className="btn btn-sm btn-grey" type="button">
										Refresh Values
									</button>
								</>
							)} */}
						</div>
					</ShowWhenTrue>
				</>
			)}
			{!isEmpty(workflowConfig) &&
			workflowConfigSelectionInfo?.type ===
				WorkflowConfigSelectionType["Multiple Arguments"] ? (
				<button
					className="btn btn-yellow"
					type="button"
					onClick={() => toggleNArgsModal(true)}
					id="edit-nArgs-Config"
				>
					Edit Multiple Arguments configuration
				</button>
			) : (
				<WorkflowConfigTab
					config={workflowConfig}
					onWorkflowConfigUpdate={onWorkflowConfigUpdate}
					type="CONFIGURATION_INSIDE_COMPONENT"
					globalPipelineConfig={globalPipelineConfig}
				/>
			)}
		</div>
	);
});

WorkflowConfigSelection.displayName = "WorkflowConfigSelection";

export default WorkflowConfigSelection;
