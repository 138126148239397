import React from 'react';
import { Env, isDevelopment } from '../../constants/settings';
import { useSelector } from 'react-redux';
import { RootState } from '@store/types';

export const MlLoop: React.FC = () => {
    const workSpaceData = useSelector((store: RootState) => store.ClusterReducer.workspaceList);
    const enabledWorkspace = workSpaceData.filter((item)=>item.isEnabled)[0];
    const mlflowUrl = `${enabledWorkspace?.workspaceUrl}ml/experiments`;

    return (
        Env.disableMlflow ?
            <div className="disableMlFlow__box" >
                <h2>Model Management is not available.Contact DeepIQ for more information.<br />Email: support@deepiq.com </h2>
            </div> : (
                <iframe 
                    style={{
                        width: 'calc(100% - 72px)',
                        height: 'calc(100vh - 54px)',
                        marginLeft: '72px',
                        marginTop: '64px',
                        border: 'none',
                        backgroundColor: 'rgb(4, 6, 20)'
                    }}
                    className='iframe'
                    // src={isDevelopment? 'http://localhost:3001' : '/lib/mlflow/index.html'}
                    src={isDevelopment? 'http://localhost:3001' : mlflowUrl}
                    >
                </iframe> )
    );
};