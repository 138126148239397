import { Flex } from "@components/ui/Flex";
import { Text } from "@components/ui/Text";
import React from "react";
import { Icons } from "./Icons";
import { Search } from "./Search";
import { FileExplorerProps, Folder } from "./types";
import { Breadcrumb } from "./ui/Breadcrumb";
import { Button } from "./ui/Button";
import { DownloadIcon } from "@pages/data-browser/icons";
import { DeleteSVGIcon } from "@pages/cluster_redisign/icons";

type Props = FileExplorerProps & {
	search?: string;
	setSearch: (text: string) => void;
	searchResultCount: number;
};

export const ActionBar = ({
	folderChain,
	search,
	searchResultCount,
	actionBarOptions,
	setFolderChain,
	setSearch,
	onRefresh,
	onUpload,
	onDownload,
	onDelete,
	view,
}: Props) => {
	const updateFolderChain = (folder: Folder) => {
		const index = folderChain.indexOf(folder);
		if (index === folderChain.length) {
			return;
		}
		setFolderChain(folderChain.slice(0, index + 1));
	};

	return (
		<Flex alignItems="center">
			<Flex flex={1} alignItems="center">
				{actionBarOptions?.hideBreadcrumb || (
					<Text
						color="white"
						fontSize={view === "Table" ? "16px" : "12px"}
						fontWeight="bold"
					>
						{folderChain.map((folder: any) => (
							<Breadcrumb
								key={folder.id}
								onClick={() => updateFolderChain(folder)}
							>
								{folder.name}
							</Breadcrumb>
						))}
					</Text>
				)}
				{search && (
					<Flex>
						<Text fontSize="16px" color="white">
							Search Results:&nbsp;
						</Text>
						<Text fontSize="16px" color="#9495A0">
							{searchResultCount} results found
						</Text>
					</Flex>
				)}
			</Flex>
			<Flex gap="16px">
				{actionBarOptions?.hideDownload|| (
					<Button onClick={onDownload} id="file_download">
						<Flex gap="4px" alignItems="center">
							<DownloadIcon />
							Download
						</Flex>
					</Button>
				)}
				{actionBarOptions?.hideDelete || (
					<Button onClick={onDelete} id="file_delete">
						<Flex gap="4px" alignItems="center">
							<DeleteSVGIcon />
							Delete
						</Flex>
					</Button>
				)}
				{actionBarOptions?.hideUpload || (
					<Button onClick={onUpload} id="file_upload">
						<Flex gap="4px" alignItems="center">
							<Icons.upload />
							Upload
						</Flex>
					</Button>
				)}
				{actionBarOptions?.hideRefresh || (
					<Button primary onClick={onRefresh}>
						<Flex gap="4px" alignItems="center">
							<Icons.refresh />
							Refresh
						</Flex>
					</Button>
				)}
				{actionBarOptions?.hideSearch || (
					<Search  search={search || ''} onSearch={setSearch} />
				)}
			</Flex>
		</Flex>
	);
};
