import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Button } from "antd";
import { isEmpty } from "lodash";
import classNames from "classnames";
import { Modal } from "@components/modals";
import { PlotForm } from "../ui/plotForm";
import PlotLayout from "../ui/plotLayout";
import { useVisualization } from "../hook/useVisualization";
import "../styles.scss";
import styles from '../../styles.module.scss';
import { EmptyPlot } from "@pages/data-explorer-new/icons";
import { ShowWhenTrue } from "@helpers/showwhentrue";
import { useDataExplorerContext } from "@pages/data-explorer-new/useDataExplorerContext";

export const DataExplorerVisualization: React.FC<{}> = () => {
	const {
		showVisualizationPopup: showVisualizationPopup,
		plotData,
		queryOutput,
		openPlotPopUp,
		handleSettings,
		handleClose,
		columnsList,
		handlePlotSubmit,
		selectedPlotItem,
		initialValuesPlotForm,
		handleDeletePlot,
		handleOnDragEnd,
	} = useVisualization();

	const { theme } = useDataExplorerContext();
	
	return (
		<>
			<div id="right-container" className={styles['right_container']} style={{background: theme === "dark" ? '#030613' : '#fff'}}>
				<ShowWhenTrue show={isEmpty(plotData)}>
					<div className={"plot-empty-view"}>
						<div className="plot-empty-chart">
							<EmptyPlot />
						</div>

						<div className={classNames("text-center", "m-1", "text-configure")}>
							Configure a plot to view custom visualizations
						</div>
						<div
							className={classNames("align-middle", {
								["bottom"]: !isEmpty(plotData),
							})}
						>
							<Button
								disabled={isEmpty(queryOutput)}
								className={classNames("button", "m-1")}
								onClick={openPlotPopUp}
							>
								Configure Plot
							</Button>
						</div>
					</div>
				</ShowWhenTrue>
				<div>
					<ShowWhenTrue show={!isEmpty(plotData)}>
						<div
							className={classNames("align-middle", {
								["bottom"]: !isEmpty(plotData),
							})}
						>
							<Button
								disabled={isEmpty(queryOutput)}
								className={classNames("button", "m-1")}
								onClick={openPlotPopUp}
							>
								Configure Plot
							</Button>
						</div>
						<DragDropContext onDragEnd={handleOnDragEnd}>
							<Droppable droppableId="plot-layout">
								{(provided) => (
									<div
										{...provided.droppableProps}
										ref={provided.innerRef}
									>
										<PlotLayout
											plotData={plotData}
											onSettingsClick={handleSettings}
											onDeleteClick={handleDeletePlot}
										/>
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					</ShowWhenTrue>
				</div>
			</div>

			<Modal
				isOpen={showVisualizationPopup}
				toggleClose={handleClose}
				title="Customize Plot To View"
				subtitle={""}
				className="plotSelectionModal__container"
				shouldCloseOnOverlayClick={false}
				showCloseMark
			>
				<PlotForm
					columnsList={columnsList}
					toggleClose={handleClose}
					handlePlot={handlePlotSubmit}
					selectedPlotItem={selectedPlotItem}
					initialValuesPlotForm={initialValuesPlotForm}
				/>
			</Modal>
		</>
	);
};
