import React from 'react';
import DataExplorerLayout from './components/layout';
import { DataExplorerProvider } from './useDataExplorerContext';
import { GenAIProvider } from './gen-ai/useGenAIContext';

const DataExplorerNew: React.FC = () => {
    return (
        <DataExplorerProvider>
            <GenAIProvider>
                <DataExplorerLayout />
            </GenAIProvider>
        </DataExplorerProvider>
    );
};

export default DataExplorerNew;