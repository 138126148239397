import { Modal } from '../../../components/modals';
import { object, string, InferType } from 'yup';
import React, { useState, useMemo } from 'react';
import Form from '../../../components/form';
import { WorkflowHandler, ShareWorkflowPostData, DetailedWorkflowInfo } from '../../../api/workflow-handler';
import { getDependentWorkflowDetailsAndPayload, importWorkflow, toggleModal } from '../../../store/workflow';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/types';
import { TagFieldOption, TagFieldWithSuggestions } from '../../../components/form/tag-field-suggestions';
import { isEmpty } from 'lodash';
import { errorAlert, successAlert } from '../../../components/toastify/notify-toast';
import { InPageSpinner } from '../../../components/spinners/in-page-spinner';
import { getActiveWorkflowType } from '../../../utils';
import { ExecutionEnvModes } from '../../../constants/enums';
import { AxiosResponse } from 'axios';
import { WorkflowSerializedObjData } from '@components/workflow-canvas';

const NewWorkflowSchema = object().shape({
    emails: string()
        .required('This is a required field')
});

type NewWorkflowSchema = InferType<typeof NewWorkflowSchema>

export interface ShareWorkFlowProps {
    onlyShare? : boolean;
}



export const ShareWorkflowModal: React.FC<ShareWorkFlowProps> =  ({onlyShare=false}) => {
    const showModal = useSelector((store: RootState) => store.WorkflowReducer.showModal.shareWorkflow);
    const dispatch = useDispatch();
    const { singleItemInfoForModals, workflowsSelectedState, selectionType } = useSelector((store: RootState) => store.WorkflowReducer);
    const usersList = useSelector((store: RootState) => store.AccountReducer.allUsersList);
    const activeExecutionEnv = useSelector((store: RootState) => store.CommonReducer.activeExecutionEnv);
    const [showSpinner, toggleSpinner] = useState(false);
  
    function toggleClose() {
        dispatch(toggleModal('shareWorkflow', false));
    }

    const usersListForTagField: TagFieldOption[] = useMemo(() => {
        return usersList.map(user => ({ label: `${user.firstName} ${user.lastName} (${user.email})`, value: user.email, textForTag: `${user.firstName} ${user.lastName}`, }));
    }, [usersList]);

    

    const { subtitle: modalSubtitle, selectedWorkflowIds } = useMemo(() => {
        const selectedWorkflowIds: number[] = [];
        const selectedWorkflowNames: string[] = [];
        if(selectionType === 'single') {
            selectedWorkflowNames.push(singleItemInfoForModals.name);
            selectedWorkflowIds.push(singleItemInfoForModals.id);
        } else {    
            Object.entries(workflowsSelectedState).forEach(([id, workflow]) => {
                if (workflow.status) {
                    selectedWorkflowIds.push(parseInt(id));
                    selectedWorkflowNames.push(workflow.name);
                }
            });
        }
        return {subtitle: selectedWorkflowNames.join(', '), selectedWorkflowIds };
        
    }, [workflowsSelectedState, selectionType, singleItemInfoForModals]);

    const handleShareSuccess = (userIds: string[]) => {
        userIds.forEach(userId => {
            successAlert(`A copy of ${modalSubtitle} shared successfully with ${userId}`);
        });
        toggleClose();
        toggleSpinner(false);
    };

    const handleShareFailure = () => {
        toggleSpinner(false);
        errorAlert('Error sharing the ' + getActiveWorkflowType(activeExecutionEnv) +'s');
    };

    const handleSubmit = (values: { emails: TagFieldOption[] }) => {
        if(onlyShare){
                if(!showSpinner && showModal) {
                    toggleSpinner(true);                   
                            const userIds = values.emails.map(email => email.value);
        
                                const data: ShareWorkflowPostData = {
                                    userIds,
                                    workflowIds: selectedWorkflowIds
                                };
                                WorkflowHandler.ShareWorkflow(data, 
                                    (response: any) => {
                                        if (response.success) {
                                            handleShareSuccess(userIds);
                                        } else {
                                            handleShareFailure();
                                        }
                                    }, 
                                    (error: any) => {
                                        console.error('Error sharing workflow:', error);
                                        handleShareFailure();
                                    }
                                );
                }
        }else{
        if(!showSpinner && showModal) {
            toggleSpinner(true);
            const workflowNamesRef: Record<number, string> = {};
            Promise
                .allSettled(selectedWorkflowIds.map(workflowId => getDependentWorkflowDetailsAndPayload(workflowId)))
                .then((response: PromiseSettledResult<WorkflowSerializedObjData>[]) => {
                    const { workflowsWithDependencies, workflowsIdsWithoutDependencies } = response.reduce((acc, prom, idx) => {
                        if(prom.status === 'fulfilled') {
                            const { workflowMetaData } = prom.value;
                            const workflowId = selectedWorkflowIds[idx];
                            workflowNamesRef[workflowId] = workflowMetaData.workflowName
                            if(isEmpty(workflowMetaData.workflowDependenciesRef)) {
                                acc.workflowsIdsWithoutDependencies.push(workflowId)
                            } else {
                                acc.workflowsWithDependencies[workflowId] = prom.value;
                            }
                        }
                        return acc
                    }, { workflowsWithDependencies: {}, workflowsIdsWithoutDependencies: [] } as { workflowsWithDependencies: Record<number, WorkflowSerializedObjData>, workflowsIdsWithoutDependencies: number[] })

                    const userIds = values.emails.map(email => email.value);

                    let sharedWorkflowsWithoutDependencies = isEmpty(workflowsIdsWithoutDependencies);
                    let sharedWorkflowsWithDependencies = isEmpty(workflowsWithDependencies);
                    const failedWorkflowsList: string[] = []

                    if(!isEmpty(workflowsIdsWithoutDependencies)) {
                        // Workflows without dependencies can be shared directly 
                        const data: ShareWorkflowPostData = {
                            userIds,
                            workflowIds: workflowsIdsWithoutDependencies
                        };
                        WorkflowHandler.ShareWorkflow(data, ()  => {
                            sharedWorkflowsWithoutDependencies = true;
                        }, () => {
                            workflowsIdsWithoutDependencies.forEach(workflowId => {
                                failedWorkflowsList.push(workflowNamesRef[workflowId])
                            })
                        });
                    }

                    if(!isEmpty(workflowsWithDependencies)) {
                        const importWorkflowPromisesArray: Promise<AxiosResponse<DetailedWorkflowInfo>>[] = [];
                        for(const workflowId in workflowsWithDependencies) {
                            const workflowInfo = workflowsWithDependencies[parseInt(workflowId)];
                            userIds.forEach(userId => {
                                importWorkflowPromisesArray.push(
                                    dispatch(importWorkflow({ serializedWorkflowInfo: workflowInfo as unknown as string, workflowMetaData: workflowInfo.workflowMetaData, userId })) as any   
                                )  
                            })
                        }

                        Promise
                            .allSettled(importWorkflowPromisesArray)
                            .then(settledPromises => {
                                settledPromises.forEach((prom) => {
                                    if(prom.status === 'rejected') {
                                        const workflowName = prom.reason.message.split(":::")?.[1];
                                        workflowName && failedWorkflowsList.push(workflowName)
                                    }
                                })
                                sharedWorkflowsWithDependencies = true;
                            })
                    }

                    const intervalId = setInterval(() => {
                        if(sharedWorkflowsWithoutDependencies && sharedWorkflowsWithDependencies) {
                            if(isEmpty(failedWorkflowsList)) {
                                handleShareSuccess(userIds)
                            } else {
                                handleShareFailure()
                            }
                            clearInterval(intervalId)
                        }
                    }, 500)
                })
        }
    }
    };

    return(
        <Modal
            isOpen={showModal}
            toggleClose={toggleClose}
            className="profileModal__container shareWorkflowModal__container"
            title="Share a Copy"
            subtitle={modalSubtitle}
            showCloseMark
        >
            <Form
                validationSchema={NewWorkflowSchema}
                initialValues={{emails: []}}
                onSubmit={handleSubmit}
            >
                {({ _formikprops: { setFieldValue, values } }) => (
                    <>
                        <TagFieldWithSuggestions 
                            options={usersListForTagField} 
                            label="Enter Email address of recipients" 
                            handleSelectOptions={(selectedUsers) => {
                                setFieldValue('emails', selectedUsers);
                            }} 
                            autoFocus
                        />
                        {activeExecutionEnv === ExecutionEnvModes.Pipelines && 
                            <span className="workflow__dagMsg">Please ensure that the workflows configured in this DAG have been shared</span>
                        }
                        <div className="modalBtns__box">
                            <button 
                                className="btn-md btn-yellow"
                                id="rename_btn_yellow"
                                type="submit"
                                disabled={isEmpty(values.emails)}
                            >
                                {showSpinner ? 
                                    <InPageSpinner size="XSmall" color="black" />
                                    :
                                    <span>Share</span>
                                }
                            </button>
                            <button 
                                className="btn-md btn-cancel"
                                type="button"
                                id="rename_btn_cancel"
                                onClick={toggleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </>
                )}
                
                
                
            </Form>

        </Modal>
    );
};
