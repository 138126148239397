import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "../../../components/modals";
import "react-color-palette/lib/css/styles.css";
import Form, { InputField, SelectField } from "../../../components/form";
import { colorModeOptions, ColorModes, colorRampOptions, ColorRamps } from "../enums";
import { _selectoptionType } from "../../../components/form/select-field";
import chroma from "chroma-js";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/types";
import { toggleAnalyticsModal } from "../../../store/analytics";
import {
	GraphInfo,
	InitialDataForTiffColorSelection,
	RasterColorConfigInfo,
} from "../Canvas";
import { CheckboxField } from "../../../components/form/checkbox-field";
import { RadioField } from "../../../components/form/radio-field";
import * as yup from "yup";
import { Slider } from "../../../components/form/Slider";
import styles from "../styles.module.scss";
import { greyRampColors } from "@services/geo-tiff/utils";

type Props = {
	initialDataForTiffColorSelection: InitialDataForTiffColorSelection;
	handleUpdateGraph: (
		graphIdentifier: string,
		arg1: Partial<GraphInfo>
	) => any
};

type ColorPreviewProps = {
	title: string;
	color: string;
	onClick: () => void;
};

const colorPickerSchema = yup.object().shape({
	colorRamp: yup.string(),
	invertColors: yup.string(),
	colorMode: yup.string(),
	noOfClasses: yup
		.number()
		.integer("Should be a positive number greater than 0")
		.positive("Should be greater than 0"),
});

type ColorPickerSchema = yup.InferType<typeof colorPickerSchema>;

const ColorPreview: React.FC<ColorPreviewProps> = ({
	title,
	color,
	onClick,
}) => {
	return (
		<div className="colorPreview__box">
			<span>{title}</span>
			<div
				style={{ background: color }}
				onClick={onClick}
				className="colorPreview"
			/>
		</div>
	);
};

const invertColorsOptions = [{ label: "Invert Colors", value: "true" }];

export const ColorPickerModal: React.FC<Props> = ({
	initialDataForTiffColorSelection,
	handleUpdateGraph,
}) => {
	const showColorPickerModal = useSelector(
		(store: RootState) => store.AnalyticsReducer.showModal.tiffColorPicker
	);
	const dispatch = useDispatch();

	const [colorOne, setColorOne] = useState("");
	const [colorTwo, setColorTwo] = useState("");
	const [alpha, setAlpha] = useState(100)

	useEffect(() => {
		if (initialDataForTiffColorSelection) {
			const rasterConfigColorInfo = initialDataForTiffColorSelection.tiffColorPlotInfo[initialDataForTiffColorSelection.rasterConfigId]
			setColorOne(rasterConfigColorInfo.startColor);
			setColorTwo(rasterConfigColorInfo.endColor);
			// alpha is set between 0 to 1
			setAlpha(rasterConfigColorInfo.alpha * 100)
		}
	}, [initialDataForTiffColorSelection]);

	const [colorForColorPicker, setColorForColorPicker] = useState("");

	const handeShowColorPicker = (type: "colorOne" | "colorTwo") => {
		if (type === "colorOne") {
			setColorForColorPicker(colorOne);
		} else {
			setColorForColorPicker(colorTwo);
		}
	};

	// const [color, setColor] = useColor('hex', colorForColorPicker);

	const handleSelectColorRamp = (option: _selectoptionType) => {
        if(option.value !== ColorRamps.Grey) {
            const selectedScale = chroma.scale(option.value);
            setColorOne(selectedScale(0).hex());
            setColorTwo(selectedScale(1).hex());
        } else {
            setColorOne(greyRampColors[0])
            setColorTwo(greyRampColors[1])
        }
		
	};

	const handleClose = () => {
		dispatch(toggleAnalyticsModal("tiffColorPicker", false));
	};

	const handleSubmitColorPicker = (values: ColorPickerSchema) => {
		if (initialDataForTiffColorSelection?.graphIdentifier) {
			const currentRasterConfigColorInfo = initialDataForTiffColorSelection.tiffColorPlotInfo[initialDataForTiffColorSelection.rasterConfigId]
			
			const rasterColorConfigInfo: RasterColorConfigInfo = {
				colorRamp: values.colorRamp as ColorRamps,
				startColor: colorOne,
				endColor: colorTwo,
				invertColors: values.invertColors === "true",
				noOfClasses: values.noOfClasses,
				colorMode: values.colorMode as ColorModes,
				alpha: alpha/100,
				hidden: currentRasterConfigColorInfo.hidden
			};

			const tiffColorPlotInfo = {
				...initialDataForTiffColorSelection.tiffColorPlotInfo,
				[initialDataForTiffColorSelection.rasterConfigId]: rasterColorConfigInfo
			}

			handleUpdateGraph(
				initialDataForTiffColorSelection.graphIdentifier,
				{ tiffColorPlotInfo }
			);
			handleClose();
		}
	};

	const colorRampInitialValue: ColorPickerSchema = useMemo(() => {
		const rasterConfigColorInfo = initialDataForTiffColorSelection?.tiffColorPlotInfo[initialDataForTiffColorSelection.rasterConfigId]

		return {
			colorRamp: rasterConfigColorInfo?.colorRamp || ColorRamps.Grey,
			invertColors: rasterConfigColorInfo?.invertColors
				? invertColorsOptions[0].value
				: "",
            colorMode: rasterConfigColorInfo?.colorMode || ColorModes.Continous,
            noOfClasses: rasterConfigColorInfo?.noOfClasses || 5
		};
	}, [initialDataForTiffColorSelection]);

	return (
		<Modal
			isOpen={showColorPickerModal}
			toggleClose={handleClose}
			title="Configure TIFF"
			className="plotSelectionModal__container colorPickerModal__container"
		>
			<div>
				<Form
					onSubmit={handleSubmitColorPicker}
					initialValues={colorRampInitialValue}
					validationSchema={colorPickerSchema}
					enableReinitialize
				>
					{({ _formikprops: { values } }) => {
						const colorsInverted = values.invertColors === "true";
						return (
							<>
								<SelectField
									name="colorRamp"
									label="Color Ramp"
									options={colorRampOptions}
									onOptionClick={handleSelectColorRamp}
								/>
								<CheckboxField
									name="invertColors"
									options={invertColorsOptions}
								/>
								<div className="split__fields--halfwide colorModeSelection">
									<RadioField
										name="colorMode"
										label="Color Mode"
										options={colorModeOptions}
									/>
									{values.colorMode ===
										ColorModes["Equal Interval"] && (
										<InputField
											name="noOfClasses"
											label="Classes"
											type="number"
										/>
									)}
								</div>
								<Slider label="Transparency" min={0} max={100} value={alpha} onChange={setAlpha as any} className={styles["alphaSlider"]} />
								<div className="colorPreview__outer">
									<ColorPreview
										title="Color 1"
										color={
											colorsInverted ? colorTwo : colorOne
										}
										onClick={() =>
											handeShowColorPicker("colorOne")
										}
									/>
									<ColorPreview
										title="Color 2"
										color={
											colorsInverted ? colorOne : colorTwo
										}
										onClick={() =>
											handeShowColorPicker("colorTwo")
										}
									/>
								</div>
								<div className="btns__box">
									<button
										className="btn-md btn-cancel"
										type="button"
										onClick={handleClose}
									>
										Cancel
									</button>
									<button
										id="btn_color_picker_submit"
										className="btn-md btn-yellow"
										type="submit"
									>
										Submit
									</button>
								</div>
							</>
						);
					}}
				</Form>

				{/* <ColorPicker width={456} height={228} color={color} onChange={setColor} dark /> */}
			</div>
		</Modal>
	);
};
