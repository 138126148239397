import React, { useState, useEffect, useMemo, forwardRef, useImperativeHandle } from 'react';
import { Chevron } from './chevron';
import './styles.scss';
import classNames from 'classnames';
import { toInteger, range } from 'lodash';

interface PaginateProps {
    startPage: number;
    totalCount: number;
    itemsPerPage: number;
    handlePageChange: (arg0: number) => any;
    showArrowsForPageNav?: boolean;
    children?: React.ReactNode;
}

export interface PaginateRef{
    changePage: (arg0: number) => void;
    currentPage: number;
    resetPagesToStartPage: () => void;
    // this doesnt trigger the handlePageChange callback
    setCurrentPage: (arg0: number) => void;
}

const Paginate: React.ForwardRefRenderFunction<PaginateRef, PaginateProps> = ({ totalCount, itemsPerPage, handlePageChange, startPage, showArrowsForPageNav = false, children  }, ref) => {
    const [currentPage, setCurrentPage] = useState(toInteger(startPage));
    const isPrevPagePossible = currentPage !== 1;
    const isNextPagePossible = currentPage * itemsPerPage < totalCount;


    function changePage(pageNumber: number) {
        handlePageChange(pageNumber);
        setCurrentPage(pageNumber);
    }

    const resetPagesToStartPage = () => {
        setCurrentPage(toInteger(startPage));
        changePage(startPage);
    };

    
    useEffect(() => {
        resetPagesToStartPage();
    }, [totalCount, itemsPerPage ]);

    useImperativeHandle(ref, () => ({
        changePage, 
        currentPage, 
        resetPagesToStartPage, 
        setCurrentPage: (pageNumber: number) => setCurrentPage(pageNumber)
    }));

    function goToPreviousPage() {
        if(isPrevPagePossible) changePage(currentPage - 1);
    }

    function goToNextPage() {
        if(isNextPagePossible) changePage(currentPage + 1);
    }

    const totalNumberOfPages = useMemo(() => Math.ceil(totalCount/itemsPerPage), [totalCount, itemsPerPage]);

    const renderNumbersButtons = (numberList: number[]) => (
        numberList.map(_num => (
            <button
                key={_num+'i'}
                onClick={() => changePage(_num)}
                className={classNames('btn-sm btn-pageNum', {'btn-grey-transparent': currentPage !== _num }, {'btn-grey': currentPage === _num })}
            >
                {_num}
            </button>
        ))
    );

    const pageNumbersAsButtons = useMemo(() => {
        let firstSetOfNumbers: number[] = [];
        // const secondSetOfNumbers: number[] = [];
        let start = currentPage - 1;
        let stop = currentPage + 1;
        if(totalNumberOfPages === 1){
            start = 1;
            stop = 0;
        } else if(currentPage === totalNumberOfPages) {
            stop = currentPage;
        } else {
            if(currentPage === 1) {
                start = 1;
                if(totalNumberOfPages > 2) stop = 3;
                else if (totalNumberOfPages === 1) stop = 2;
            }
            if(stop >= totalNumberOfPages && currentPage >= 4) {
                start = totalNumberOfPages - 2;
                stop = totalNumberOfPages;
            }
            
        }

        // +1 is to include stop number
        firstSetOfNumbers = range(start, stop+1);
        // secondSetOfNumbers= [totalNumberOfPages];

        return(
            <div className="pageNumbers">
                {renderNumbersButtons(firstSetOfNumbers)}                
                {/* <button
                    onClick={() => changePage(0)}
                    className="btn-grey-transparent btn-pageNum btn-sm"
                >
                    ..
                </button>                 */}
                {/* {renderNumbersButtons(secondSetOfNumbers)}                 */}
            </div>
        );
    }, [totalNumberOfPages, startPage, currentPage]);

    const upperLimit = isNextPagePossible ? currentPage*itemsPerPage: totalCount; 
    return(
        <div className={classNames('pagination__main', {'showArrows': showArrowsForPageNav}, {'showButtonsForPageNav': !showArrowsForPageNav})}>
            <span className="countText">
                {!showArrowsForPageNav && 'Showing '}
                {totalCount !== 0  ? (itemsPerPage*(currentPage-1))+1 : 0} to {upperLimit} of {totalCount}
            </span>
            <div>
                {showArrowsForPageNav ? 
                    <>
                        <button 
                            onClick={goToPreviousPage} 
                            id='PrevBtnPaginationWorkflow'
                            className={classNames('reverse__chevron', {'disable__hover': !isPrevPagePossible})}
                        >
                            <Chevron active={isPrevPagePossible} /> 
                        </button>
                        <button 
                            id='NextBtnPaginationWorkflow'
                            onClick={goToNextPage} className={classNames({'disable__hover': !isNextPagePossible})}>
                            <Chevron active={isNextPagePossible} /> 
                        </button>
                    </>
                    :
                    <div className="pageNumbersBox">
                        <button
                            className="btn-grey btn-sm"
                            onClick={goToPreviousPage} 
                            disabled={!isPrevPagePossible}
                        >
                        Previous
                        </button>
                        {!!totalCount &&  pageNumbersAsButtons}
                        <button
                            className="btn-grey btn-sm"
                            onClick={goToNextPage}
                            disabled={!isNextPagePossible}
                        >
                        Next
                        </button>
                    </div>
                }
                {children}
            </div>
        </div>
    );
};

export default forwardRef(Paginate);