import React, { useState, ChangeEvent } from 'react';
import { Modal, Input, Button, Select } from 'antd';
import classNames from 'classnames';
import { dir } from 'console';
import { errorAlert } from '@components/toastify/notify-toast';

const { Option } = Select;

interface NewGitWorkflowProps {
    visible: boolean;
    dirName: string;
    dirId: number;
    onCreate: (directoryName: string, dirId: number) => void;
    onCancel: (directoryName: string, dirId: number) => void;
}



const NewGitWorkflow: React.FC<NewGitWorkflowProps> = ({ visible, dirName, dirId, onCreate, onCancel }) => {
    const [directoryName, setDirectoryName] = useState<string>('');

    const isValidWorkflowName = (name: string): boolean => {
        return name.trim().length > 0 && !/\s/.test(name);
    };

    const handleCreate = () => {
        if (isValidWorkflowName(directoryName)) {
            onCreate(directoryName.trim(), dirId); 
            setDirectoryName('');
        } else {
            errorAlert("Invalid workflow name");
            
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setDirectoryName(e.target.value);
    };

    const handleCancel =()=>{
        onCancel(directoryName, dirId)
    }

    const showErrorMessage = !isValidWorkflowName(directoryName) && directoryName.trim().length > 0;

    return (
        <Modal
            title="Create New Workflow"
            visible={visible}
            onOk={handleCreate}
            onCancel={handleCancel}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button className="btn-md btn-yellow" key="create" type="primary" onClick={handleCreate} disabled={!isValidWorkflowName(directoryName)}>
                    Create
                </Button>
            ]}
        >
            <Input
                placeholder="Worflow Name"
                value={directoryName}
                onChange={handleChange}
                style={{borderStyle:'none'}}
            />
            {showErrorMessage  && (<span style={{color:'red', fontSize:'11px'}}>Workflow name should not contain spaces</span>)}
            <Select value={dirName} className={classNames('disabled-select')} disabled style={{ width: '100%', marginTop: '15px', backgroundColor:'grey' }}>
                <Option value={dirName}>{dirName}</Option>
            </Select>
        </Modal>
    );
};

export default NewGitWorkflow;
