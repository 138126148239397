/* eslint-disable prefer-const */
import React, { useEffect, useRef, useState } from "react";
import styles from "../styles.module.scss";
import RowRender from "../components/rowRender";
import { RobotIcon } from "../components/Icons";
import SelectGroup, { SelectList } from "../components/selectGroup";
import { useGenAIContext } from "../useGenAIContext";
import { KEY_VALUE, NEXT_INPUT_OPTIONS } from "../types";
import { _selectoptionType } from "@components/form/select-field";
import classNames from "classnames";
import { uniq } from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "@store/types";

const MultiSelect: React.FC<{
	options: NEXT_INPUT_OPTIONS;
	message?: string;
	showFeedback: any,
	query_uuid: string,
	isFeedbackSubmitted: boolean,
	isSingleSelect?: boolean
}> = ({ options, message, showFeedback, query_uuid, isFeedbackSubmitted,isSingleSelect }) => {
	const [selectList, setSelectList] = useState<SelectList[]>([]);
	const [updatedSelectedOptions, setSelectedOptions] = useState<any>({});
	const [initialValues, setInitialValues] = useState<any>({});
	const { runQuery, addContent } = useGenAIContext();
	const update = useRef<any>({});
	const update1 = useRef<any>({});
    const { activeUserInfo: { username } } = useSelector((store: RootState) => store.AccountReducer);

	useEffect(() => {
		update.current = null;
		update1.current = null;
		if(isSingleSelect){
			const optionsChoicesList = Object.entries(options?.choices).map(([key, value]) => ({
				title: key,
				value: key,
				info: value?.description,
				options: value["top retrived values"].map((val:string)=>{return{label:val,value:val}})
			  }));
			  setSelectList(optionsChoicesList);
		}else{
			if (typeof options.choices[0] === "string") {
				const option: _selectoptionType[] = (options.choices as string[]).map(
					(option) => {
						return {
							label: option,
							value: option,
						};
					}
				);
				setSelectList([
					{
						title: "options",
						value: "options1",
						options: option,
					},
				]);
				const temp = uniq(options.defaults as string[]);
				setInitialValues({
					options1: temp.join(","),
				});
				update.current = temp.join(",");
			} else {
				const optionList: _selectoptionType[] = (options.choices as KEY_VALUE[]).map(
					(choice, index: number) => {
						const key = Object.keys(choice)[0];
						const value = choice[key];
						return {
							value: index + "",
							label: `${key}-${value[2]} (${value[0]}.${value[1]})`,
						};
					}
				);
				setSelectList([
					{
						title: "options",
						value: "options1",
						options: optionList,
					},
				]);
	
				const defaults = (options.defaults as KEY_VALUE[]).map((choice) => {
					const key = Object.keys(choice)[0] as string;
					const value = choice[key];
					const label = `${key}-${value[2]} (${value[0]}.${value[1]})`;
					return (
						optionList.findIndex((option) => option.label === label) +
						""
					);
				});
				setInitialValues({
					options1: defaults.join(","),
				});
				update.current = defaults.join(",");
				update1.current = optionList;
			}
		}
	}, []);

	const onUpdate = () => {
		if(isSingleSelect){
			addContent(JSON.stringify(updatedSelectedOptions), "human", query_uuid, true);
			runQuery('', undefined,[updatedSelectedOptions],"","",username);
		}else{
			addContent(message as string, "ai", query_uuid);
			if (update1.current) {
				const obj: any = {
					choices: [],
					content: "",
				};
				const val =
					typeof update.current === "string"
						? update.current.split(",")
						: Object.keys(update.current).map((k) => update.current[k]);
				const val1: string[] = [];
				val.forEach((i) => {
					if(update1.current[Number(i)]) {
						obj.choices.push(options.choices[Number(i)]);
						val1.push(update1.current[Number(i)].label);
					}
				});
				addContent(val1, "human", query_uuid, true);
				runQuery("", undefined, val1, obj);
				return;
			}
			const val =
				typeof update.current === "string"
					? update.current.split(",")
					: Object.keys(update.current);
			addContent(val, "human", query_uuid, true);
			runQuery("", undefined, val);
		}
	};

	return (
		<>
			<RowRender
				text={message || "Please select your the list"}
				icon={<RobotIcon />}
				direction="ai"
				date={new Date()}
				isFeedbackSubmitted={isFeedbackSubmitted}
				noFeedback={false}
				showFeedback={showFeedback}
				query_uuid={query_uuid}
			/>
			<SelectGroup
				multiple_select={isSingleSelect?false: true}
				selectList={selectList}
				initialValues={initialValues}
				isSingleSelect={isSingleSelect}
				onClick={(
					group: string,
					selectedOptions?: Record<string, any>
				) => {
					if(isSingleSelect){
						setSelectedOptions((choice: any) => { return {...choice, [group] : selectedOptions?.label}})
					}else{
						update.current = selectedOptions;
					}
				}}
			/>
			<div>
				<button
					className={classNames(
						"btn-grey btn-sm",
						styles["btn"],
						styles["btn_update"]
					)}
					onClick={() => onUpdate()}
				>
					Update
				</button>
			</div>
		</>
	);
};

export default MultiSelect;
