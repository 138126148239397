/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { Component } from 'react';
import ConsoleSegment from './ConsoleSegment';
import QueryBuilderUI from './QueryBuilder';
import Db from './classes/db';
import Query from './classes/query';
import { Row, Col, Switch, Radio } from 'antd';
import _ from 'lodash';
import indicator from './classes/indicator';

class QueryBuilder extends Component {
    constructor(props) {
        super(props);
        const db = Db.build(this.props.data || {});
        const query = Query.build(db);
        this.state = {
            db,
            query,
            isGeoSpatial: false,
            geoSpatial: {
                column1: '',
                table1: '',
                column2: '',
                table2: '',
                select: [],
                fn: ''
            }
        };
    }

    updateQueryState = () => {
        this.setState(prevState => ({ query: { ...prevState.query } }));
        this.props.setSQLQuery(this.state.query.toSql());
    };

    render() {
        return (
            <>
                <div style={{
                    marginBottom: 10,
                    marginTop: 10,
                    position: 'absolute',
                    top:  10,
                    right: 0,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    transition: '150ms all',
                    transitionDelay: '250ms all'
                }}>
                    <div style={{
                        marginLeft: 5
                    }}>
                        <span>Auto Alias: </span>
                        <Switch 
                            defaultChecked={false}
                            onChange={(checked)=>{
                                this.state.query.select.setAutoAlias(checked);
                                this.updateQueryState();
                            }}
                        />
                    </div>
                    <div style={{
                        borderLeft: '1px solid #6d707e',
                        marginLeft: 5
                    }}></div>
                    <div style={{
                        marginLeft: 5
                    }}>
                        <span>Use Geospatial Index: </span>
                        <Switch 
                            onChange={(checked)=>{
                                this.state.query.geoSpatial.setGeoSpatial(checked);
                                this.updateQueryState();
                                setTimeout(()=>{
                                    indicator.fromIndicator();
                                });
                            }}
                        />
                    </div>
                </div>
                <Row className="queryBuilder">
                    <Col span={14}>
                        {
                            <QueryBuilderUI
                                db={this.state.db}
                                query={this.state.query}
                                updateQueryState={this.updateQueryState}
                            />
                        }
                    </Col>
                    <Col span={10}>   
                        <ConsoleSegment
                            querySQL={this.state.query.toSql()}
                        />
                    </Col>
                </Row>
            </>
            
        );
    }
}

export default QueryBuilder;
