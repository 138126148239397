import React from "react";
import Form, { SelectField } from "../form";
import Spinner from "@components/spinners/spinner";

type NextPrevPaginationProps = {
	handleItems: (newPageSize: number) => void;
	pageNoList: number[],
	onPreviousPage: ()=> void,
	onNextPage: ()=> void,
	isPrevDisabled: boolean,
	pageItemCount: number,
	isNextDisabled: boolean,
	isLoading: boolean,
	reachedLast: boolean,
	pageNo: number
};

export const NextPrevPagination: React.FC<NextPrevPaginationProps> = ({
	handleItems,
	pageNoList = [20, 25, 30],
    onPreviousPage,
	onNextPage,
	isPrevDisabled,
	pageItemCount,
	isNextDisabled,
	isLoading,
	reachedLast,
	pageNo
}) => {
	const options = pageNoList.map(_num => ({ label: _num.toString(), value: _num }));
	
	return (
		<div className="flex justify-between">
			<div className="flex justify-center align-center">
				<div style={{marginRight:'0.5rem', marginTop: '-2rem', fontSize: '1rem'}}>Page size: </div>
				<div className="">
					<Form
						initialValues={{ pageItemCount }}
						onSubmit={() => {
							return;
						}}
					>
						<SelectField
							name="pageItemCount"
							className="numberOfItemsDropdown"
							label=""
							options={options}
							onOptionClick={(option) => {
								handleItems(option.value)
							}}
						/>
					</Form>
				</div>
			</div>
			<div className="pageNumbersBox">
				{
					isLoading ? ( 
						<div className="centerh" style={{height: '2rem', marginRight: '2rem'}}>
							<Spinner />
						</div>
					) : null 
				}
				{
					reachedLast? (
						<div className="centerh" style={{height: '2rem', marginRight: '2rem'}}>
							Reached Last Page
						</div>
					) : null
				}
				<button
					className="btn-grey btn-sm"
					onClick={onPreviousPage} 
					disabled={isLoading || isPrevDisabled}
				>
				Previous
				</button>
				<span className="pageNumbers" style={{
					padding: '5px 2px',
    				marginRight: '8px'
				}}>{pageNo}</span>
				<button
					className="btn-grey btn-sm"
					onClick={onNextPage}
					disabled={isLoading || isNextDisabled}
				>
				Next
				</button>
			</div>
		</div>
	);
};
