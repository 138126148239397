import React from 'react'
import classNames from 'classnames';
import styles from './../styles.module.scss';

export type ButtonList = {
    title: string,
    value: string,
    icon?: React.ReactElement
}

export type ButtonGroupProps = {
    buttonList: ButtonList[],
    onClick: (buttonValue: string) => void
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({
    buttonList,
    onClick
}) => {
    return (
        <div className={styles['button']} >
            {
                buttonList.map((button, index) => {
                    return (
                        <button className={classNames("btn-grey btn-sm", styles['btn'])} key={button.value + index} onClick={() => onClick(button.value)}  >
                            {button.icon}
                            {button.title}
                        </button>
                    )
                })
            }
        </div>
    )
}

export default ButtonGroup