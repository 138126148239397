import React, {useEffect, useRef, useState} from 'react';
import ReactEcharts from "echarts-for-react";
import { Layout } from "react-grid-layout";
import { FullScreenInfo, GraphInfo, GraphState, InitialDataForTiffColorSelection, PlotData, PlotSelectionFormInfo, RasterColorConfigInfo } from '@pages/workflow-analytics-page/Canvas';
import { GraphCard } from '@pages/workflow-analytics-page/Canvas/graph-card';
import { RasterConfig } from '@api/analytics-handler';
import '../style.scss'
import { useAnalyticsContext } from '@pages/workflow-analytics-page/useAnalyticsContext';
import { toggleAnalyticsModal } from '@store/analytics';
import { useDispatch } from 'react-redux';
import { useDataExplorerContext } from '../useDataExplorerContext';
import { ErrorLogsModal } from '@pages/workflow-analytics-page/modals/error-logs';
import { ColorPickerModal } from '@pages/workflow-analytics-page/modals/color-picker';
import { cloneDeep, has, omit, set } from 'lodash';
import { ColorRamps, ColorModes } from '@pages/workflow-analytics-page/enums';
import { PlotSelectionFormType } from '@pages/workflow-analytics-page/modals/plot-selection';

const initialGridLayoutInfo: Layout[] = [
	
];

const initialFullScreenInfo = {
	graphIdentifier: "",
	show: false,
	layout: [],
	scrollTop: 0,
	resizeWorkflowCanvas: false,
};

const PlotLayout: React.FC<{
    childFunc: any,
    setEmpty: any,
    showPlotConfigPopup: any
}> = ({
	childFunc,
    setEmpty
}) => {
    const [gridLayoutInfo, setGridLayoutInfo] = useState<Layout[]>(
        initialGridLayoutInfo
    );
    const [fullScreenInfo, setFullScreenInfo] = useState<FullScreenInfo>(
		initialFullScreenInfo
	);
    const [initialDataForTiffColorSelection, setInitialDataForTiffColorSelection] 
        = useState<InitialDataForTiffColorSelection>(null);
    const [graphsData, setGraphsData] = useState<Record<string, GraphInfo>>({});
	const graphsLayoutRef = useRef<Layout[]>(gridLayoutInfo);
    const echartsRefOfAllPlots = useRef<Record<string, React.RefObject<ReactEcharts>>>({});
	const dispatch = useDispatch();
    const selectedPlot = useRef<string>("");
    const waitLoading = useRef(false);
    (window as any).graphsData = graphsData;
    const {
        sendAnalyticsReducerCmd
	} = useAnalyticsContext();
    const {
        sendDataExplorerReducerCmd,
    } = useDataExplorerContext();

    useEffect(() => {
        childFunc.current = {
            addDummyGraph: addDummyGraph.bind(this),
            updateGraph: updateGraph.bind(this),
            updateGraph1: updateGraph1.bind(this),
            deleteRaster: deleteRaster.bind(this),
            updateGraphLoading: updateGraphLoading.bind(this),
            waitGraphLoading: waitGraphLoading.bind(this)
        }
    }, [graphsData, gridLayoutInfo]);
    
    const handleSetGridLayout = (gridLayoutInfo: Layout[]) => {
		setGridLayoutInfo(gridLayoutInfo);
		graphsLayoutRef.current = gridLayoutInfo;
	};

    const waitGraphLoading = (iden: string, loading: boolean) => {
        waitLoading.current = loading;
    }

    const removeGraph = (pId: string) => {
		const updatedGraphsLayoutInfo = graphsLayoutRef.current.filter(
			(_gridLayout) => _gridLayout.i !== pId
		);
        setGraphsData((graphsData1) => {
            delete graphsData1[pId];
			return { ...graphsData1 };
		});
		handleSetGridLayout(updatedGraphsLayoutInfo)
	};

    // Infinity puts it at the bottom
    const getBaseGraphLayout = (key: string, numberOfItems: number): Layout => ({
        i: key,
        x: 10,
        y: Infinity,
        w: 3,
        h: 10,
        minH: 8,
        minW: 3,
    });

    const deleteRaster = (graphId: string, rasterConfigId: string) => {
        const __plotData = graphsData[graphId]?.plotData;
        const __plotSelectionFormInfo = cloneDeep(graphsData[graphId]?.plotSelectionFormInfo);

        if (__plotData?.raster_info?.raster_configs.length === 2) {
             __plotData.raster_info.raster_configs = __plotData?.raster_info?.raster_configs.filter(
                (config) => config.id !== rasterConfigId
            );
            __plotData.tiffFilePath = omit(
                __plotData.tiffFilePath,
                rasterConfigId
            );
            __plotSelectionFormInfo.rasterInfo = cloneDeep(__plotData.raster_info)
            updateGraph1(graphId, {
                ...graphsData[graphId],
                tiffColorPlotInfo: omit(graphsData[graphId].tiffColorPlotInfo, rasterConfigId),
                plotData: __plotData,
                plotSelectionFormInfo: __plotSelectionFormInfo
            });
        }
    }

    const addAGraph = (graphIdentifier: string, graphInfo: GraphInfo) => {
		const newLayoutInfo = [
			...graphsLayoutRef.current,
			getBaseGraphLayout(
				graphIdentifier,
				graphsLayoutRef.current.length - 1
			),
		];
		handleSetGridLayout(newLayoutInfo)

		setGraphsData((graphsData) => ({
			...graphsData,
			[graphIdentifier]: graphInfo,
		}));
	};

    const addDummyGraph = (
        graphIdentifier: string,
        graphInfo: GraphInfo
    ) => {
        addAGraph(graphIdentifier, graphInfo);
        sendDataExplorerReducerCmd({
            type: 'SELECTED_PLOT',
            payload: {
                graphIdentifier: ''
            }
        });
	};

    const updateGraphLoading = (graphIdentifier: string) => {
        setGraphsData((graphsData) => {
            const graphInfo =  graphsData[graphIdentifier];
            graphInfo.state = 'LOADING';
			return { ...graphsData, [graphIdentifier]: cloneDeep(graphInfo) };
		});
    };

    const updateGraph1 = (
        graphIdentifier: string, 
        graphInfo: GraphInfo
    ) => {
        setGraphsData((graphsData) => {
			const __updatedGraphInfo = {
				...graphsData[graphIdentifier],
				...graphInfo,
			};
			return { ...graphsData, [graphIdentifier]: __updatedGraphInfo };
		});
        dispatch(toggleAnalyticsModal("plotSelection", false));
        sendDataExplorerReducerCmd({
            type: 'SELECTED_PLOT',
            payload: {
                graphIdentifier: ''
            }
        });
    };

    const updateGraph = (plotData: PlotData, plotSelectionFormInfo: PlotSelectionFormInfo, jobID: string, pID: string, formValues: PlotSelectionFormType, state: GraphState = "SHOW_GRAPH" ) => {
        const graphInfo: GraphInfo = {
            plotData: plotData,
            componentId: "10e1557f-1baa-435d-96fe-4d704457a0ea",
            state,
            errorMessage: null,
            plotStatementId: jobID,
            plotSelectionFormInfo: plotSelectionFormInfo,
            tiffColorPlotInfo: {},
        };

        setGraphsData((graphsData1) => {
            graphInfo.tiffColorPlotInfo = graphsData1[pID].tiffColorPlotInfo;
            if (
                (formValues?.plot_type === "raster" || plotSelectionFormInfo.plot_type === "raster") &&
                plotSelectionFormInfo.rasterInfo?.raster_id &&
                !has(graphInfo.tiffColorPlotInfo, plotSelectionFormInfo.rasterInfo.raster_id)
              ) {
                const initialRasterConfig: RasterColorConfigInfo = {
                  colorRamp: ColorRamps.Grey,
                  startColor: "white",
                  endColor: "black",
                  invertColors: false,
                  colorMode: ColorModes.Continous,
                  noOfClasses: 5,
                  alpha: 1,
                  hidden: false,
                };
        
                set(
                  graphInfo.tiffColorPlotInfo,
                  plotSelectionFormInfo.rasterInfo.raster_id,
                  initialRasterConfig
                );
              }
              
			const __updatedGraphInfo = {
				...graphsData1[pID],
				...graphInfo,
                state
			};
			return { ...graphsData1, [pID]: __updatedGraphInfo };
		});
        dispatch(toggleAnalyticsModal("plotSelection", false));
        sendDataExplorerReducerCmd({
            type: 'SELECTED_PLOT',
            payload: {
                graphIdentifier: ''
            }
        });
	};

    const handleSetGraphRef = (
		graphIdentifier: string,
		graphRef: React.RefObject<ReactEcharts>
	) => {
		echartsRefOfAllPlots.current = {
			...echartsRefOfAllPlots.current,
			[graphIdentifier]: graphRef,
		};
	};

    const showPlotSelectionModalFromGraphCard = (
		plotSelectionFormInfo: PlotSelectionFormInfo,
		rasterConfig: RasterConfig | null | "new",
        graphIdentifier?: string
	) => {
        selectedPlot.current = graphIdentifier as string;
        sendDataExplorerReducerCmd({
            type: 'SELECTED_PLOT',
            payload: {
                graphIdentifier: graphIdentifier as string
            }
        })
        sendAnalyticsReducerCmd({
            type: "SET_PLOT_SELECTION_STATE_FROM_GRAPH_CARD",
            payload: {
                plotSelectionFormInfo,
                rasterConfig,
                graphCardInfo: graphsData[graphIdentifier as string],
            },
        });
        dispatch(toggleAnalyticsModal("plotSelection", true))
	};

    const showTiffColorPickerFromGraphCard = (
		graphIdentifier: string,
		rasterConfigId: string,
		tiffColorPlotInfo: GraphInfo["tiffColorPlotInfo"]
	) => {
		setInitialDataForTiffColorSelection({
			tiffColorPlotInfo,
			graphIdentifier,
			rasterConfigId,
		});
		dispatch(toggleAnalyticsModal("tiffColorPicker", true));
	};

    useEffect(() => {
        setEmpty(gridLayoutInfo.length === 0)
    }, [gridLayoutInfo])

    const updateAGraph = (
		graphIdentifier: string,
		graphInfo: Partial<GraphInfo>
	) => {
		setGraphsData((graphsData) => {
            if(waitLoading.current) {
                graphInfo.state = "LOADING"
            }
			const __updatedGraphInfo: any = {
				...graphsData[graphIdentifier],
				...graphInfo,
			};
            
			return { ...graphsData, [graphIdentifier]: __updatedGraphInfo };
		});
	};

    return (
        <div className='dataexplorer__container'>
            <div className='react-grid-layout layout ' style={{height: '80vh'}}>
                {gridLayoutInfo.map((_gridInfo) => {
                    return (
                        <div
                            key={_gridInfo.i}
                            className="grid-graph"
                            id={`pid_${_gridInfo.i}`}
                            style={{
                                width: '28rem',
                                height: '20rem'
                            }}
                        >
                            <GraphCard
                                isDataExplorer={true}
                                graphIdentifier={_gridInfo.i}
                                removeGraph={removeGraph}
                                setGraphRef={handleSetGraphRef}
                                showPlotSelectionModalFromGraphCard={showPlotSelectionModalFromGraphCard}
                                showTiffColorPickerFromGraphCard={
                                    showTiffColorPickerFromGraphCard
                                }
                                resizeGraph={(gridItemIdentifier: string, fullscreen?: boolean ) => {
                                    if(fullscreen) {
                                        fullScreenInfo.graphIdentifier = gridItemIdentifier;
                                    } else {
                                        fullScreenInfo.graphIdentifier = "";
                                    }
                                    setFullScreenInfo({...fullScreenInfo});
                                }}
                                isFullScreenActive={
                                    fullScreenInfo.graphIdentifier ===
												_gridInfo.i
                                }
                                updateGraph={updateAGraph}
                                plotInProgress={false}
                                {...graphsData[_gridInfo.i]}
                            />
                        </div>
                    );
                })}
            </div>
            <ErrorLogsModal />
			<ColorPickerModal
				initialDataForTiffColorSelection={
					initialDataForTiffColorSelection
				}
				handleUpdateGraph={updateAGraph}
			/>
        </div>
        
    );
};

export default PlotLayout;