import React, { useState, useEffect, useCallback, useRef } from "react";
import MonacoEditor from "react-monaco-editor";
import * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';
import 'monaco-editor/esm/vs/basic-languages/monaco.contribution';
import 'monaco-editor/esm/vs/editor/edcore.main';
import 'monaco-editor/esm/vs/language/typescript/monaco.contribution';
import 'monaco-editor/esm/vs/editor/standalone/browser/standaloneServices';
import 'monaco-editor/esm/vs/language/json/monaco.contribution';
import { Space } from "antd-latest";
import { useSelector } from "react-redux";
import styles from "../styles.module.scss";
import { useGenAIContext } from "../useGenAIContext";
import { infoAlert } from "@components/toastify/notify-toast";
import { useDataExplorerContext } from "@pages/data-explorer-new/useDataExplorerContext";
import { RootState } from "@store/types";
import { Send } from "@pages/data-explorer-new/icons";
import classNames from "classnames";
import { isEmpty } from "lodash";

const InputContainer = () => {
	const [queryData, setQueryData] = useState("");
	const { runQuery, inputDisabled, loading } = useGenAIContext();
	const { cluster, database, theme } = useDataExplorerContext();
	const {
		activeUserInfo: { username },
	} = useSelector((store: RootState) => store.AccountReducer);
    const ref = useRef<MonacoEditor>(null)
	const trigger = useCallback(async () => {
		setQueryData("");
		if (inputDisabled || loading) return;
		if (queryData.trim().length === 0)
			return infoAlert("Please enter a valid text");
		await runQuery(queryData, "", [], "", "", username);
	}, [inputDisabled, loading, queryData, database?.id]);

	useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                trigger();
            }
        };

        const editorElement = ref.current?.editor?.getDomNode();
        editorElement?.addEventListener('keydown', handleKeyPress);

        return () => {
            editorElement?.removeEventListener('keydown', handleKeyPress);
        };
    }, [trigger]);


	useEffect(() => {
		if (inputDisabled) {
			setQueryData("");
		}
	}, [inputDisabled]);

	  useEffect(() => {
        const placeholder = document.getElementById('placeholderm') as HTMLDivElement

        // we need the parent of the editor
        const parent = document.getElementById('chat_middle_container') as HTMLDivElement //placeholder.parentElement as any
        const parent1 = placeholder.parentElement as any

        const fn = () => {
            ref.current?.editor?.layout({ width: 0, height: 0 });
            window.requestAnimationFrame(() => {
                // get the parent dimensions and re-layout the editor
                const rect = parent.getBoundingClientRect()
                const rect1 = parent1.getBoundingClientRect()
                ref.current?.editor?.layout({ width: rect.width - 56, height: rect1.height })
            })
        }
        window.addEventListener("resize", fn);
        const temp = setInterval(fn, 1000)

        return () => {
            clearInterval(temp)
            window.removeEventListener("resize", fn)
        }
    }, [])

	  useEffect(() => {
        const temp = ref.current?.editor?.addAction({
            id: "",
            label: "",
            keybindings: [monacoEditor.KeyCode.Enter],
            precondition: '!suggestWidgetVisible && !renameInputVisible && !inSnippetMode && !quickFixWidgetVisible',
            run: function (editor) {
                if(inputDisabled || loading) return;
                if(editor.getValue().trim().length === 0) 
                    return infoAlert("Please enter a valid text");
                runQuery(editor.getValue())
                setQueryData('')
            },
        })
        return ()=>{
            temp?.dispose();
        }
    }, [loading, inputDisabled, cluster ]);

	const isDisabled = inputDisabled || loading || isEmpty(queryData);
	return (
		<div id="placeholderm1" className={styles["input_holder"]}>
			<div
				id="placeholderm"
				style={{
					flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
				}}
				className={`${styles["editorContainer"]} ${styles["editorPadding"]}`}
			>
				<Space.Compact style={{ width: "100%", border: '0px solid #3E4161' }}>
				<MonacoEditor
                    ref={ref}
                    // height='65px'
                    width='100%'
                    theme={theme=='dark'?'vs-dark':'vs'}
                    value={queryData}
                    language='deepiq'
                    onChange={(value) => {
                        setQueryData(value)
                    }}
                    options={{
                        selectOnLineNumbers: true,
                        'formatOnPaste': true,
                        'accessibilitySupport': 'auto',
                        'autoIndent': 'full',
                        'highlightActiveIndentGuide': true,
                        'quickSuggestions': true,
                        readOnly: inputDisabled || loading,
                        formatOnType: true,
                        minimap: {
                            enabled: false,
                        },
                        fontSize: 15,
                        letterSpacing: 0.25,
                        lineNumbersMinChars: 0,
                        lineDecorationsWidth: 0,
                        lineHeight: 20,
                        scrollbar:{
                            horizontal: 'hidden',
                            vertical: 'hidden',
                            // avoid can not scroll page when hover monaco
                            alwaysConsumeMouseWheel: false,
                        },
                        glyphMargin: false,
						suggest: {
							maxVisibleSuggestions: 10, 
						  },
                        lineNumbers: 'off',
                        folding: false,
                        fontWeight: 'normal',
                        wordWrap: 'off',
                        overviewRulerLanes: 0,
                        overviewRulerBorder: false,
                        hideCursorInOverviewRuler: true,
                        scrollBeyondLastColumn: 0,
                        // disable `Find`
                        find: {
                            addExtraSpaceOnTop: false,
                            autoFindInSelection: 'never',
                            seedSearchStringFromSelection: false,
                        },
                        // avoid links underline
                        links: false,
                        // avoid highlight hover word
                        occurrencesHighlight: false,
                        cursorStyle: 'line-thin',
                        // hide current row highlight grey border
                        // see: https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.ieditoroptions.html#renderlinehighlight
                        renderLineHighlight: 'none',
                        contextmenu: false,
                        // default selection is rounded
                        roundedSelection: false,
                        hover: {
                            // unit: ms
                            // default: 300
                            delay: 100,
                        },
                        acceptSuggestionOnEnter: 'on',
                        // auto adjust width and height to parent
                        // see: https://github.com/Microsoft/monaco-editor/issues/543#issuecomment-321767059
                        automaticLayout: false,
                        // if monaco is inside a table, hover tips or completion may casue table body scroll
                        fixedOverflowWidgets: true,
                    }}
                    // editorDidMount={onEditorDidMount}
                />
					<button onClick={trigger}  disabled={isDisabled} className={classNames(!isDisabled ? styles["inputBtn"] : styles["inputBtnDisabled"])}>
						<Send />
					</button>
				</Space.Compact>
			</div>
		</div>
	);
};

export default InputContainer;
