import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ShowWhenTrue } from '../../helpers';
import { omit } from 'lodash';
import './styles.scss';
import classNames from 'classnames';
import { uuid } from 'uuidv4';
import { useOutsideClick } from 'rooks5';

export interface ColumnSelectorProps {
    columnsList: { label: string; disabled?: boolean }[];
    title?: string;
    selectAllColumnsInitially?: boolean;
}

type _ReturnType = [Record<number, true>, JSX.Element, React.Dispatch<React.SetStateAction<Record<number, true>>>]

export const ColumnSelectorIcon: React.FC<{ id?: string }> = ({ id=uuid() }) => (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none">
        <rect width={20} height={20} rx={2} fill="#D8D8D8" />
        <mask
            id={id}
            maskUnits="userSpaceOnUse"
            x={0}
            y={0}
            width={20}
            height={20}
        >
            <rect width={20} height={20} rx={2} fill="#fff" />
        </mask>
        <g mask={`url(#${id})`}>
            <path fill="#D4D6E0" d="M0 0H20V20H0z" />
        </g>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.334 4.334a1 1 0 011-1h.5a1 1 0 011 1v11.333a1 1 0 01-1 1h-.5a1 1 0 01-1-1V4.334zm5.417 0a1 1 0 011-1h.5a1 1 0 011 1v11.333a1 1 0 01-1 1h-.5a1 1 0 01-1-1V4.334zm6.416-1a1 1 0 00-1 1v11.333a1 1 0 001 1h.5a1 1 0 001-1V4.334a1 1 0 00-1-1h-.5z"
            fill="#fff"
        />
        <mask
        // id="CSIT-2"
            id={`${id}-2`}
            maskUnits="userSpaceOnUse"
            x={3}
            y={3}
            width={14}
            height={14}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.334 4.334a1 1 0 011-1h.5a1 1 0 011 1v11.333a1 1 0 01-1 1h-.5a1 1 0 01-1-1V4.334zm5.417 0a1 1 0 011-1h.5a1 1 0 011 1v11.333a1 1 0 01-1 1h-.5a1 1 0 01-1-1V4.334zm6.416-1a1 1 0 00-1 1v11.333a1 1 0 001 1h.5a1 1 0 001-1V4.334a1 1 0 00-1-1h-.5z"
                fill="#fff"
            />
        </mask>
        <g mask={`url(#${id}-2)`}>
            <path fill="#2A2C42" d="M0 0H20V20H0z" />
        </g>
    </svg>
);

export const useColumnsSelector = ({ columnsList, title='Select columns to display', selectAllColumnsInitially = false }: ColumnSelectorProps): _ReturnType => {
    const [showColumnsList, toggleShowColumnsList] = useState(false);
    const [selectedColumnsIndices, setSelectedColumnsIndices] = useState<_ReturnType[0]>({});
    const outerDivRef = useRef<HTMLDivElement>(null);

    useOutsideClick(outerDivRef, () => showColumnsList && toggleShowColumnsList(false), showColumnsList);


    useEffect(() => {
        if(selectAllColumnsInitially) {
            const __selectedColumnsIndices: typeof selectedColumnsIndices = {};
            columnsList.forEach((val ,index) => {
                __selectedColumnsIndices[index] = true;
            });
            setSelectedColumnsIndices(__selectedColumnsIndices);
        }   
    }, [columnsList]);

    const handleColumnClick = (index: number) => {
        let __selectedColumnsIndices = {...selectedColumnsIndices};
        if(__selectedColumnsIndices[index]) __selectedColumnsIndices = omit(__selectedColumnsIndices, index);
        else __selectedColumnsIndices[index] = true;
        setSelectedColumnsIndices(__selectedColumnsIndices);
    };


    const renderContent = useMemo(() => {
        return(
            <div 
                ref={outerDivRef}
                className="columnsSelector__box"    
            >
                <button
                    onClick={() => toggleShowColumnsList(state => !state)}
                    className="columnsSelector__icon"
                >
                    <ColumnSelectorIcon />
                </button>
                <ShowWhenTrue show={showColumnsList}>
                    <div
                        className="columnsList__box"
                    >
                        <div className="columnSelector__title">
                            {title}
                        </div>
                        <div className="innerColumnsList__box">
                            {columnsList.map((column, index) => {
                                const onClick = () => !column.disabled && handleColumnClick(index);
                                return (
                                    <button
                                        key={column.label+index}
                                        className={classNames('columnName__box', {'columnName__disabled' : column.disabled})}
                                        // onClick={() => !column.disabled && handleColumnClick(index)}
                                    >
                                        <label
                                            className="checkbox__container"
                                            // onClick={(e) => e.stopPropagation()}
                                        >
                                            <input type="checkbox" checked={!!(selectedColumnsIndices[index])} onChange={onClick} value="" />
                                            <span className="checkmark" />
                                        </label>
                                        <span
                                            onClick={onClick}
                                        >
                                            {column.label}
                                        </span>
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                </ShowWhenTrue>
            </div>
        );
    }, [showColumnsList, columnsList, selectedColumnsIndices]);


    return [selectedColumnsIndices ,renderContent, setSelectedColumnsIndices];
};