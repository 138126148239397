import { FileBrowser } from 'chonky';
import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Modal } from '../modals';
import { FileHandler } from '../../api/file-handler';
import { FileData } from 'chonky/lib/typedef';
import 'chonky/style/main.css';
import { RootState } from '../../store/types';
import { useSelector } from 'react-redux';
import { useDebounce } from 'rooks';
import { uuid } from 'uuidv4';


interface FileBrowserProps {
    initialPath?: string;
    show: boolean;
    toggleClose: () => any;
    onFileSelection: (arg0: string) => any;
}


export const DbfsFileBrowserComponent: React.FC<FileBrowserProps> = ({ show, toggleClose, onFileSelection}) => {
    const userInfo = useSelector((store: RootState) => store.AccountReducer.activeUserInfo);
    const [selectedFileInfo, setSelectedFileInfo] = useState({ path: '', name: '' });
    const [folderChain,setFolderChain] =  useState([{ id: userInfo.username, name: userInfo.username, isDir: true }]);
    const [_initialPath,setIntialPath] = useState(`${userInfo.username}`) ;
    const fileBrowserRef = useRef<FileBrowser>(null);
    const [fileList, setFileList] = useState([]);
    const handleFileListResponse = (params: any) => {
        const FileArray: any = [];
        const fileListResponse = params.files;
        if (fileListResponse.length > 0) {
            fileListResponse.forEach((file: any) => {
                const fileInfo: FileData = { id: file.id, name: file.name, isDir: file.is_dir, size: (file.is_dir != true ? file.file_size :  undefined), parentId: file.parentId };
                FileArray.push(fileInfo);
            });
            setFileList(FileArray);
        }
    };

    const handleErrorResponse = () => {
        // errorAlert('Noting to show,Please Upload files');
        setFileList([]);
    };
    useEffect(() => {
        FileHandler.getDbfsFiles(_initialPath, handleFileListResponse, handleErrorResponse);
    }, [_initialPath]);
    const folderTraverseHandler = useDebounce((e: any) => {
        if(e.target.className === 'chonky-folder-chain-entry-name') {
            setFileList([]);
            setIntialPath(_initialPath.split(e.target.innerHTML)[0]+e.target.innerHTML);
            const removeIndex = folderChain.findIndex(x => x.name === e.target.innerHTML);
            const _folderChain = folderChain.slice(0,removeIndex + 1);
            setFolderChain(_folderChain);
        }
    }, [300]);
    document.addEventListener('click',folderTraverseHandler);
    const handleSelectFile = (file: FileData) => {
        if (!file.isDir) {
            let currentFileLocation = '';
            folderChain.forEach(folder => {
                currentFileLocation += '/' + folder.name;
            });
            currentFileLocation += `/${file.name}`;
            setSelectedFileInfo({ path: `/users${currentFileLocation}`, name: file.name });
        }else {
            setIntialPath(`${_initialPath}/${file.name}`);
            // console.log(`${_initialPath}/${file.name}`);
            const _folderchain = folderChain;
            _folderchain.push({ id: uuid(), name: file.name, isDir: true });
            setFileList([]);
            // const files: any = FileHandler.getDbfsFiles(initialPath, handleFileListResponse);
        }
    };

    const handleSelectCurrentFile = () => {
        onFileSelection(selectedFileInfo.path);
    };

    const fileBrowserComponent = useMemo(() => {
        return (
            <FileBrowser
                ref={fileBrowserRef}
                files={fileList}
                folderChain={folderChain}
                onFileSingleClick={handleSelectFile}
                fillParentContainer
            />
        );
    }, [fileBrowserRef, fileList, folderChain]);

    return (
        <Modal
            title=""
            toggleClose={toggleClose}
            className="fileBrowserModal"
            isOpen={show}
        >
            {fileBrowserComponent}
            <div className="fileSelectionOptions">
                <button
                    className="btn-md"
                    onClick={toggleClose}
                >
                    Cancel
                </button>
                <button
                    className="btn-md btn-yellow"
                    onClick={handleSelectCurrentFile}
                    disabled={!selectedFileInfo.name}
                >
                    Select
                </button>
            </div>
        </Modal>
    );
};