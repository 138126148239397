import { JobInfo, JobsHandler, JobStates } from "@api/job-handler";
import { ExecutionEnvModes } from "@constants/enums";
import { Env } from "@constants/settings";
import { getDurationFromSeconds } from "@utils/common";
import classNames from "classnames";
import { capitalize } from "lodash";
import moment from "moment";
import { useState, useMemo } from "react";
import { useTimeout } from "rooks";
import { JobOpenWorkflowIcon, JobCloseWorkflowIcon, ClockIcon, HourGlassIcon, JobPreviewIcon, JobRunIcon } from "../assets/icons";
import React from "react";

const jobTypeImages = { run: JobRunIcon, preview: JobPreviewIcon };

export interface JobItemProps {
    toggleJobStatusPanel: React.Dispatch<React.SetStateAction<boolean>>;
    job: JobInfo;
    checkIfActiveComponentFormIsSaved: () => boolean;
    checkIfActiveComponentNotesIsSaved: () => boolean;
    showJobLog: (job: JobInfo) => any;
    refreshJobTimeInfo: boolean;
}


const jobStates: Record<JobStates | 'dead', string> = {
    success: 'Success',
    killed: 'Terminated',
    failed: 'Failed',
    dead: 'Failed',
    running: 'In Progress',
    starting: 'In Progress',
    waiting: 'In Progress',
    error: 'Failed'
};




const JobItem: React.FC<JobItemProps> = ({
    job,
    toggleJobStatusPanel,
    checkIfActiveComponentFormIsSaved,
    checkIfActiveComponentNotesIsSaved,
    showJobLog,
    refreshJobTimeInfo
}) => {
    const databricksRunPageUrl = job.databricksRunPageUrl;
    const [showTerminateMessage, toggleTerminateMessage] = useState(false);
    const [showJobTermination, toggleJobTermination] = useState(false);

    const handleJobTerminationFailure = () => {
        toggleJobTermination(false);
    };

    const handleJobTerminationSuccess = () => {
        toggleTerminateMessage(false);
        toggleJobTermination(true);
    };

    const terminateJob = (jobInfo: JobInfo) => {
        if (jobInfo.type === 'run')
            JobsHandler.TerminateAJob(
                jobInfo.id,
                handleJobTerminationSuccess,
                handleJobTerminationFailure
            );
        else
            jobInfo.id &&
                JobsHandler.TerminateAPreviewJob(
                    jobInfo.sessionId,
                    jobInfo.id,
                    handleJobTerminationSuccess,
                    handleJobTerminationFailure
                );
    };

    // optimise this
    const { start } = useTimeout(() => toggleJobStatusPanel(true), 0.01);

    const JobStatusIcon =
        jobTypeImages[(job.type || 'run').toLowerCase() as 'run' | 'preview'];
    const isJobRunning =
        job.state === 'running' ||
        job.state === 'starting' ||
        job.state === 'waiting';

    const [timeAgo, minsDiff] = useMemo(() => {
        const startTimeObj = moment(job.startTime);
        const endTimeObj = moment(job.endTime);
        const diffInSeconds = endTimeObj.diff(startTimeObj, 'seconds');

        return [
            moment(job.createdDate).fromNow(),
            getDurationFromSeconds(diffInSeconds)
        ];
    }, [job.startTime, job.endTime, refreshJobTimeInfo]);

    return (
        <li
            className={classNames(
                { terminateMode: showTerminateMessage },
                'jobItemBox'
            )}
        >
            {showTerminateMessage ? (
                <>
                    <span className="terminateMsg">
                        Are you sure you want to terminate this job?
                    </span>
                    <div className="terminateButtons">
                        <button
                            type="button"
                            onClick={() => {
                                // start();
                                terminateJob(job);
                            }}
                        >
                            Yes
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                // start();
                                toggleTerminateMessage(false);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <JobStatusIcon />
                    <div className="jobStatusInfo">
                        <div className="jobStatusItem__firstLine">
                            <div className="jobName">
                                {capitalize(job.type)}:&nbsp;
                                <span>{job.name}</span>
                            </div>
                            {!isJobRunning && (
                                <span
                                    className={`jobState jobState--${job.state}`}
                                >
                                    {jobStates[job.state]}
                                </span>
                            )}
                        </div>
                        <div
                            className={classNames('jobStatus', {
                                showDot: !isJobRunning,
                                streamingRunningJob:
                                    job.env === ExecutionEnvModes.Streaming &&
                                    isJobRunning
                            })}
                        >
                            {isJobRunning ? (
                                showJobTermination ? (
                                    'Terminating...'
                                ) : (
                                    <div className="jobItem__inProgress__state">
                                        {/* <span className="ds__node__preview__status"> */}
                                        <div
                                            className={classNames(
                                                'ds__node__preview__status status--running active'
                                            )}
                                        >
                                            <span className="progress__box">
                                                <span className="progress" />
                                            </span>
                                        </div>
                                        {/* </span> */}
                                        {job.env !==
                                            ExecutionEnvModes.Pipelines && (
                                            <button
                                                className="btn-viewLog"
                                                type="button"
                                                onClick={() => {
                                                    if (
                                                        checkIfActiveComponentFormIsSaved() &&
                                                        checkIfActiveComponentNotesIsSaved()
                                                    ) {
                                                        toggleJobStatusPanel(
                                                            false
                                                        );
                                                        showJobLog(job);
                                                    }
                                                }}
                                            >
                                                <JobOpenWorkflowIcon />
                                            </button>
                                        )}
                                        {Env.killRunJobs ? (
                                            <button
                                                className="btn-jobTerminate"
                                                type="button"
                                                onClick={() => {
                                                    start();
                                                    toggleTerminateMessage(
                                                        true
                                                    );
                                                }}
                                            >
                                                <JobCloseWorkflowIcon />
                                            </button>
                                        ) : null}
                                        In Progress
                                    </div>
                                )
                            ) : (
                                <div className="jobStatusItem__secondLine">
                                    <div className="jobStatusItem__timeDurationInfo">
                                        <ClockIcon />{' '}
                                        <span className="">{timeAgo}</span>
                                        <HourGlassIcon />
                                        <span>{minsDiff}</span>
                                    </div>
                                    {databricksRunPageUrl && <button
                                        className="viewLogText"
                                        onClick={() => {
                                            window.open(databricksRunPageUrl);
                                        }}
                                    >
                                        View in Databricks
                                    </button>}
                                    <button
                                        className="viewLogText"
                                        onClick={() => {
                                            if (
                                                checkIfActiveComponentFormIsSaved() &&
                                                checkIfActiveComponentNotesIsSaved()
                                            ) {
                                                toggleJobStatusPanel(false);
                                                showJobLog(job);
                                            }
                                        }}
                                    >
                                        View Log
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </li>
    );
};

export default JobItem;