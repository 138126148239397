import { AppThunk, AppDispatch } from '../types';
import { 
    AzkabanDataHandler,
    ExecutionAnalyticsFlowAllResponse
} from '../../api/azkaban-data-handler';
import { 
    AZKABAN_EXECUTION_ANALYTICS_FLOW_ALL, AzkabanExecutionAnalyticsFlowAllPayload
} from './types';

//AzkabanDataHandler Actions

const handleExecutionAnalyticsFlowAllSuccess = (dispatch: AppDispatch, response: ExecutionAnalyticsFlowAllResponse) => {
    const payload: AzkabanExecutionAnalyticsFlowAllPayload = { 
        totalCount: response.data.length, 
        data: response.data, 
        is_loaded: true
    };
    dispatch({ type: AZKABAN_EXECUTION_ANALYTICS_FLOW_ALL, payload: payload });
};

export function execution_analytic_flow_all(this: any, id: number, time: number): AppThunk {
    return function(this: any, dispatch) {
        const payload: AzkabanExecutionAnalyticsFlowAllPayload = { 
            totalCount: 0, 
            data: [], 
            is_loaded: false
        };
        dispatch({ type: AZKABAN_EXECUTION_ANALYTICS_FLOW_ALL, payload: payload });
        AzkabanDataHandler.ExecutionAnalyticsFlowAll(id, time, handleExecutionAnalyticsFlowAllSuccess.bind(this, dispatch as unknown as AppDispatch));
    };
}