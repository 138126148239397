import { Modal } from '@components/modals';
import React, { useEffect, useState } from 'react';

interface ConfirmModalProps {
    title: string;
    showModal: boolean;
    toggleClose: () => void;
    onConfirm: () => void
}

export const DeleteNifiEdge: React.FC<ConfirmModalProps> = ({ 
    showModal, 
    toggleClose,
    title,
    onConfirm
}) => {
    return (
        <Modal
            isOpen={showModal}
            toggleClose={toggleClose}
            title={title}
            className="deleteWorkflowModal"
        >
            <div className="modalBtns__box">
                <button
                    id={'Workfflow_deletemodal_btn'}
                    className="btn-md btn-yellow"
                    onClick={onConfirm}
                    type="submit"
                >
                    Yes
                </button>
                <button
                    id={'Workfflow_deletemodal_cancelbtn'}
                    className="btn-md btn-cancel"
                    type="button"
                    onClick={toggleClose}
                >
                    Cancel
                </button>
            </div>
        </Modal>
    );
};