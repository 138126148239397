import { DataArray, Graph, KeyValueAny } from './types';


const cleanCID = (_guid: string[] ) => {
    return _guid[1].replace('\')', '');
};

const insert = (arr: any[], index: number, newItem: any[]) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    ...newItem,
    // part of the array after the specified index
    ...arr.slice(index)
];

const getNewOrder = (dataArray: DataArray[], zepplinData: Graph, zepplinDataNew: Graph) => {
    const removeComponent: string[] = [];
    const addComponent: string[] = [];
    const cIdList: KeyValueAny = {};
    let position = 0;
    let noBinding: string[] = [];

    //zepplinDataNew.graph['noBinding'] =  zepplinData.graph['noBinding'];

    zepplinDataNew.workbook_to_zepplin = zepplinData.workbook_to_zepplin;
    
    // Add Cells in Zeppelin
    dataArray.forEach((_data) => {
        cIdList[_data.c_id] = true;

        if(!zepplinData.graph[_data.c_id]) {
            addComponent.push(_data.c_id);
            zepplinDataNew.graph[_data.c_id] = [];
            position++;
        } else {
            position++;
            zepplinDataNew.position = insert(zepplinDataNew.position, position, zepplinData.graph[_data.c_id]);
            position = position + zepplinData.graph[_data.c_id].length;
            zepplinDataNew.graph[_data.c_id] =  zepplinData.graph[_data.c_id];
        }
    });

    // Remove Cells in Zeppelin
    Object.keys(zepplinData.graph).forEach((key) => {
        if(!cIdList[key] && key !== 'noBinding') {
            const obj = zepplinData.graph[key];
            zepplinDataNew.position = zepplinDataNew.position.concat(obj);
            noBinding = noBinding.concat(obj);
            const temp = zepplinDataNew.workbook_to_zepplin[key];
            if(temp) {
                removeComponent.push(temp);
            } else {
                removeComponent.push(key);
            }
            delete zepplinDataNew.workbook_to_zepplin[key];
        }
    });
    
    zepplinDataNew.graph.noBinding = noBinding;
    zepplinDataNew.graph.noBinding = zepplinDataNew.graph.noBinding.concat(zepplinData.graph.noBinding);
    zepplinDataNew.position = zepplinDataNew.position.concat(zepplinData.graph['noBinding']);

    return {
        removeComponent,
        addComponent,
        zepplinData: zepplinDataNew
    };
};

const extractContent = (script: string, paraHeader: string) => {
    const importRegex = /^((.*)_([0-9]+)=|#--custom)/gmi;
    const customStartRegex = /^(#--custom start)/gmi;
    const guid = /\[([^\]]+)\]/i;
    const customEndRegex = /^(#--custom end)/gmi;
    const end =/^(spark.stop)/gmi;
    const outregex = /^(.*)=/gmi;
    const paramsDictRegex = /^(params_dict=)/gmi;
                
    const headerText = `${paraHeader}\n#DEEPIQ COMPONENT - DON'T DELETE THIS LINE\n\n`;
    const strArr = script.split('\n');
    const arr: DataArray[] = [];
    let isImportDone = false;
    let startCustom = false;
    let isEnd = false;
    let result: any = [headerText];
    let c_id = '';
    let position = 0;
    const dataArray: Graph = {
        graph: {
            noBinding: []
        },
        workbook_to_zepplin: {
            
        },
        position: [],
        timestamp: Date.now(),
        version: 'v1'
    };

    dataArray.position.push('import');
                
    strArr.forEach((str) => {
        if(isEnd) return;
        if(end.exec(str)){
            isEnd=true;
            return;
        }
        const _guid = str.split('component_id=\'');
        if(!isImportDone){
            str = str.trim();
            const out = importRegex.exec(str.trim());
            if(out === null) {
                result.push(str);
            } else {
                arr.push({
                    edit: false,
                    data: result.join('\n'),
                    c_id: 'import',
                    position: position++
                });
                result = [headerText];
                dataArray.graph['import'] = [];
                isImportDone = true;
            }
        }

        if(isImportDone){
            if(startCustom){
                if(str.trim().match(customEndRegex)){
                    arr.push({
                        edit: true,
                        data: result.join('\n'),
                        c_id: 'custom_' + c_id,
                        isCustom: true,
                        position: position++
                    });
                    result = [headerText];
                    startCustom=false;
                } else {
                    result.push(str);
                }
                return;
            }

            if(str.trim().match(paramsDictRegex)) {
                arr[0].data = arr[0].data +  str.trim() + '\n';
                return;
            }

            if(str.trim().match(customStartRegex)) {
                startCustom=true;
                const obj = guid.exec(str);
                c_id = obj ? obj[1] : '';

                const _id = 'custom_' + c_id;
                dataArray.position.push(_id);
                dataArray.graph[_id] = [];
                        
                return;
            }
            if(outregex.exec(str.trim())){
                const _g =  cleanCID(_guid);
                arr.push({
                    edit: false,
                    data: headerText + str,
                    c_id: _g,
                    position: position++
                });

                dataArray.position.push(_g);
                dataArray.graph[_g] = [];
            }
        }
    });

    return {
        arr,
        dataArray
    };
};

export {
    extractContent,
    getNewOrder
};