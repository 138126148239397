import { SubmitStatementData, SessionInfo, StatementInfo, ColumnsListResponse, SubmitPlotInfoData,
    PlotResponse, CreateSessionData, SessionStates, } from './types';
import { HttpClient } from '../services';
import { API, Env } from '../../constants/settings';
import { ApiPromise} from '../data-source-handler';
import { errorHandlerCallback } from '../services/httpinvoker';

class AnalyticsHandlerClass {
    url: string;
    constructor() {
        this.url = Env.databricks ? API.databricksUrl: API.url;
    }

    StartSession(data: CreateSessionData, callback: (arg0: SessionInfo) => any, errorHandlerCallBack: any) {
        HttpClient.Post('Start a analytics session', `${this.url}/sessions/create`, data, callback, errorHandlerCallBack);
    }

    StartSessionPromise(data: CreateSessionData): ApiPromise<SessionInfo> {
        return HttpClient.PostPromise(`${this.url}/sessions/create`, data);
    }

    StartSessionWithoutParams(): ApiPromise<SessionInfo> {
        return HttpClient.GetPromise(`${API.url}/sessions/create`);
    }

    SubmitStatement(data: SubmitStatementData, callback: (arg0: StatementInfo) => any, errorHandlerCallBack: any) {
        HttpClient.Post('Submit statement for analytics', `${this.url}/sessions/submit-statement`, data, callback, errorHandlerCallBack);
    }

    SubmitStatementPromise(data: SubmitStatementData): ApiPromise<StatementInfo> {
        return HttpClient.PostPromise(`${this.url}/sessions/submit-statement`, data);
    }

    // GetStatementInfo(workflowSessionId: string, statementId: string, callback: (arg0: StatementInfo) => any) {
    GetStatementInfo(workflowSessionId: string, statementId: string): ApiPromise<StatementInfo> {
        // HttpClient.Get('Get statement info', `${API.url}/sessions/${workflowSessionId}/statements/${statementId}`, callback);
        return HttpClient.GetPromise(`${this.url}/sessions/${workflowSessionId}/statements/${statementId}`);
    }

    GetComponentColumnsList(workflowSessionId: any, componentId: string, targetComponentId: string, callback: (arg0: ColumnsListResponse) => any, errorCb: errorHandlerCallback) {
        HttpClient.Get('Get statement info', `${this.url}/batches/schema_capture/dataframe/${workflowSessionId}/${componentId}/${targetComponentId}`, callback, errorCb);
    }

    SubmitPlot(data: SubmitPlotInfoData, callback: (arg0: StatementInfo) => any, errorHandlerCallBack: errorHandlerCallback) {
        HttpClient.Post('Submit statement for analytics', `${this.url}/sessions/submit-plot`, data, callback, errorHandlerCallBack);
    }

    SubmitPlotPromise(data: SubmitPlotInfoData) {
        return HttpClient.PostPromise(`${this.url}/sessions/submit-plot`, data);
    }

    GetPlotData(workflowSessionId: string, statementId: string, callback: (arg0: PlotResponse) => any, errorHandlerCallBack: (arg0: any) => any) {
        HttpClient.Get('Get plot info', `${this.url}/sessions/plot-data/${workflowSessionId}/${statementId}`, callback, errorHandlerCallBack);
    }

    KillSession(workflowSessionId: string) {
        HttpClient.Delete('kill a session', `${this.url}/sessions/${workflowSessionId}`, () => { return; });
    }

    GetSessionInfo(workflowSessionId: string): ApiPromise<SessionInfo> {
        // HttpClient.Get('get session status', `${API.url}/sessions/${workflowSessionId}/state`, callback);
        return HttpClient.GetPromise(`${this.url}/sessions/${workflowSessionId}`);
    }

    GetSessionStatus(workflowSessionId: string): ApiPromise<SessionStates> {
        // HttpClient.Get('get session status', `${API.url}/sessions/${workflowSessionId}/state`, callback);
        return HttpClient.GetPromise(`${this.url}/sessions/${workflowSessionId}/state`);
    }

    GetErrorLogs(workflowSessionId: string, statementId: string, callback: (arg0: any) => any, errorHandlerCallBack: (arg0: any) => any) {
        HttpClient.Get('get Error logs ', `${this.url}/sessions/${workflowSessionId}/statements/${statementId}/log`, callback, errorHandlerCallBack);
    }

    GetPlotDataStatus(workflowSessionId: string, statementId: string, callback: (arg0: any) => any, errorHandlerCallBack: (arg0: any) => any) {
        HttpClient.Get('get Error logs ', `${this.url}/sessions/${workflowSessionId}/statements/${statementId}/progress`, callback, errorHandlerCallBack);
    }

    GetPlotDataStatusPromise(workflowSessionId: string, statementId: string) {
        return HttpClient.GetPromise(`${this.url}/sessions/${workflowSessionId}/statements/${statementId}/progress`);
    }

    GetLocalMap(callback: (arg0: any) => any) {
        HttpClient.Get('get Error logs ', 'http://127.0.0.1:5000/bokeh', callback);
    }
}

const AnalyticsHandler = new AnalyticsHandlerClass();

export { AnalyticsHandler };