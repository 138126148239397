import React, { useState, useRef, useEffect, useMemo, useLayoutEffect } from 'react';
import {
    JobStatusIcon,
} from '../assets/icons';
import { useDidUpdate, useInterval } from 'rooks';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/types';
import { JobInfo } from '../../../api/job-handler';
import moment from 'moment-mini';
import {
    openJobLogsOfWorkflow,
    toggleConsoleHeightToShowLogs,
    updatePreviewStatusOfWorkflowTab
} from '@store/workflow/actions';
import { errorAlert } from '@components/toastify/notify-toast';
import { isEmpty, omit } from 'lodash';
import {
    WorkflowCanvasTab,
    WorkflowCanvasTabInfo,
    getTabType,
    updateAWorkflowEditorTabInfo
} from '../../../store/canvas';
import { TooltipBottom } from '../../../components/tooltips';
import { ExecutionEnvModes } from '../../../constants/enums';
import cx from 'classnames';
import { useOutsideClick } from 'rooks5';
import JobItemList from './JobItemList';

moment.locale('en', {
    relativeTime: {
        past: '%s ago',
        s: '0m',
        ss: '0m',
        m: '1m',
        mm: '%dm',
        h: '1h',
        hh: '%dh',
        d: '1d',
        dd: '%dd',
        M: '1mo',
        MM: '%dmo',
        y: '1y',
        yy: '%dy'
    }
});


interface JobStatusPanelProps {
    jobsList: JobInfo[];
    getRecentJobs: () => void;
}

export const JobStatusPanel: React.FC<JobStatusPanelProps> = ({
    jobsList,
    getRecentJobs
}) => {
    const [showJobStatusPanel, toggleJobStatusPanel] = useState(false);
    const jobStatusPanelRef = useRef<HTMLDivElement>(null);
    const jobsListRef = useRef(jobsList);
    const dispatch = useDispatch();
    const { activeTab, openTabs } = useSelector(
        (store: RootState) => store.CanvasReducer.workflowEditor
    );

    const [sessionIdForPreview, setSessionIdForPreview] = useState('');
    const sessionIdRef = useRef(sessionIdForPreview);
    const [refreshJobTimeInfo, toggleRefreshJobTimeInfo] = useState(false);

    useLayoutEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;
        if(showJobStatusPanel) {
            toggleRefreshJobTimeInfo(t => !t);
            intervalId = setInterval(() => {
                // refresh time every one minute if panel is open
                toggleRefreshJobTimeInfo(t => !t);

            }, 60*1000)
        } else if(intervalId) {
            clearInterval(intervalId)
            intervalId = null
        }

        return () => {
            if(intervalId) {
                clearInterval(intervalId)
                intervalId = null
            }
        }
    }, [showJobStatusPanel])
    

    useEffect(() => {
        sessionIdRef.current = sessionIdForPreview;
    }, [sessionIdForPreview]);

    useEffect(() => {
        jobsListRef.current = jobsList;
    }, [jobsList]);

    useOutsideClick(jobStatusPanelRef, () => {
        toggleJobStatusPanel(false);
    }, showJobStatusPanel);

    const {
        completedJobsList,
        runningJobsList,
        previewJobsList
    } = useMemo(() => {
        const previewJobsList: JobInfo[] = [];
        const runningJobsList: JobInfo[] = [];
        const completedJobsList: JobInfo[] = [];
        // const activeJobId = activeTabInfo?.consoleInfo?.jobInfo?.id;
        jobsList.forEach(job => {
            if (
                job.state === 'running' ||
                job.state === 'starting' ||
                job.state === 'waiting'
            ) {
                runningJobsList.push(job);
                // updates the logs of job that's active
                if (job.type === 'preview') {
                    previewJobsList.push(job);
                    // check if active workflow id is same as job's workflow id
                    if (job.workflowId === activeTab.id) {
                        setSessionIdForPreview(job.sessionId);
                    }
                }
            } else completedJobsList.push(job);
            // if (activeJobId === job.id && activeTab.id === job.workflowId)
            //     dispatch(openJobLogsOfWorkflow(job.type, { jobInfo: job }));
        });
        return {
            completedJobsList,
            runningJobsList,
            previewJobsList
        };
    }, [jobsList, activeTab]);

    const [startRecentJobsPolling, stopRecentJobsPolling] = useInterval(
        getRecentJobs,
        5000
    );

    function saveRunningConsoleLogs(runningJobsList: JobInfo[]) {
        runningJobsList.forEach((jobInfo: JobInfo) => {
            // Logs are only available for streaming workflows during execution and run
            // This function is also getting triggered on preview which removes previewStatusInfo from activeTabInfo
            if(jobInfo.env === ExecutionEnvModes.Streaming && jobInfo.type === 'run') {
                const workflowInfo = openTabs.get(jobInfo.workflowId);
                if (workflowInfo) {
                    const consoleInfo: WorkflowCanvasTabInfo['consoleInfo'] = {
                        height: workflowInfo.info.consoleInfo?.height || 0,
                        logs: jobInfo.log || [],
                        type: 'run',
                        jobInfo
                    };

                    const updatedWorkflowInfo: WorkflowCanvasTab = {
                        type: getTabType('workflowEditor', workflowInfo.info.env),
                        info: {
                            ...omit(workflowInfo.info, 'previewStatusInfo'),
                            consoleInfo
                        }
                    };
                    dispatch(updateAWorkflowEditorTabInfo(updatedWorkflowInfo));
                }
            }
        });
    }

    useDidUpdate(() => {
        if (!isEmpty(runningJobsList)) {
            saveRunningConsoleLogs(runningJobsList);
            startRecentJobsPolling();
        } else {
            stopRecentJobsPolling();
        }
    }, [runningJobsList]);

    const handleUpdatePreviewStatusOfWorkflowTab = (
        sessionId: string,
        tabId: number
    ) => {
        dispatch(
            updatePreviewStatusOfWorkflowTab(
                sessionId,
                tabId,      
                jobsListRef.current
            )
        );
    };

    const getWorkflowPreviewStatus = () => {
        !!sessionIdRef.current &&
            handleUpdatePreviewStatusOfWorkflowTab(
                sessionIdRef.current,
                activeTab.id as number
            );
    };

    const [
        startPreviewStatusPolling,
        stopPreviewStatusPolling,
        previewStatusIntervalId
    ] = useInterval(getWorkflowPreviewStatus, 3000);

    const updateWorkflowPreviewStatusOfCompletedJobs = () => {
        // if workflow A has active preview running, user switches to another worklow B, and A's preview is completed
        // since the job's status is completed, it's not added to the preview jobs list - so no call preview status call
        // This function updates the preview state of such workflows
        const openTabsArray = Array.from(openTabs.values());
        openTabsArray.forEach(_tab => {
            if (_tab.info.previewStatusInfo?.isRunning) {
                const { id } = _tab.info;
                const { workflowSessionId } = _tab.info.previewStatusInfo;
                // id is the worklow id / tab id
                const previewJobInfo = previewJobsList.some(
                    _job => _job.workflowId === id
                );

                if (!previewJobInfo && workflowSessionId)
                    handleUpdatePreviewStatusOfWorkflowTab(
                        workflowSessionId,
                        id
                    );
            }
        });
    };

    useEffect(() => {
        updateWorkflowPreviewStatusOfCompletedJobs();
        if (!isEmpty(previewJobsList)) {
            // To start and stop polling when user switches to workflow with active preview
            const doesActiveTabHavePreview = previewJobsList.some(
                _job => _job.workflowId === activeTab.id
            );
            if (doesActiveTabHavePreview) {
                // as polling starts after 3 seconds
                getWorkflowPreviewStatus();
                startPreviewStatusPolling();
            } else if (previewStatusIntervalId) stopPreviewStatusPolling();
        }
        // allow one more preview status call after preview job has completed
        else if (previewStatusIntervalId)
            setTimeout(() => {
                stopPreviewStatusPolling();
            }, 4500);
    }, [previewJobsList, activeTab]);

    

    const checkForWorkflowActiveRunOrPreview = (jobInfo: JobInfo) => {
        const selectedWorkflowInfo = openTabs.get(jobInfo.workflowId);
        let hasActiveRunOrPreview = false;
        if (selectedWorkflowInfo) {
            // Checks if there's an active run - active run is possible only if the selected tab is active
            // if(activeJobInfo.state === 'running' && activeTab.id === activeJobInfo.workflowId && showJobLogsInWorkflowPage){
            //     errorAlert('Cannot open log as selected workflow has active run');
            //     hasActiveRunOrPreview = true;
            // }
            // // }
            // // Check for active preview
            if (selectedWorkflowInfo.info.previewStatusInfo?.isRunning) {
                errorAlert(
                    'Cannot open log as selected workflow has active preview'
                );
                hasActiveRunOrPreview = true;
            }
        }
        return hasActiveRunOrPreview;
    };

    const showJobLog = (jobInfo: JobInfo) => {
        if (!checkForWorkflowActiveRunOrPreview(jobInfo)) {
            dispatch(openJobLogsOfWorkflow(jobInfo.type, { jobInfo }));
            dispatch(toggleConsoleHeightToShowLogs());
            if (jobInfo.type === 'preview') {
                setTimeout(
                    () =>
                        handleUpdatePreviewStatusOfWorkflowTab(
                            jobInfo.sessionId,
                            jobInfo.workflowId
                        ),
                    1200
                );
            }
        }

        // if(!checkForWorkflowActiveRunOrPreview(jobInfo)){
        //     dispatch(setActiveJobInWorkflowPage(jobInfo));
        //     dispatch(showLogsInWorkflowPage());
        //     dispatch(toggleConsoleHeightIfRequired());

        // }
    };

    const runningJobsListLength = runningJobsList.length;

    const hidePendingJobs = !runningJobsListLength;

    return (
        <div className="jobStatusIndicator" ref={jobStatusPanelRef}>
            <TooltipBottom title="Job Status">
                <button
                    id={'WK_JobStatusIcon'}
                    onClick={() => toggleJobStatusPanel(!showJobStatusPanel)}
                >
                    <JobStatusIcon />
                </button>

                {runningJobsListLength > 0 && (
                    <div className="runningJobsNumber">
                        {runningJobsListLength}
                    </div>
                )}
            </TooltipBottom>
            <div
                className={cx('jobStatusPanel', {
                    show: showJobStatusPanel
                })}
            >
                {!hidePendingJobs && (
                    <span className="jobStatusTitle">PENDING JOBS</span>
                )}
                <ul className="jobStatusList">
                    <JobItemList 
                        jobs={runningJobsList}
                        toggleJobStatusPanel={toggleJobStatusPanel}
                        showJobLog={showJobLog}
                        refreshJobTimeInfo={refreshJobTimeInfo}
                    />
                    {!isEmpty(completedJobsList) && (
                        <>
                            <span
                                className={cx(
                                    'jobStatusTitle jobStatusTitleCompleted',
                                    { hideBorderTop: hidePendingJobs }
                                )}
                            >
                                COMPLETED JOBS
                            </span>
                            <JobItemList 
                                jobs={completedJobsList}
                                toggleJobStatusPanel={toggleJobStatusPanel}
                                showJobLog={showJobLog}
                                refreshJobTimeInfo={refreshJobTimeInfo}
                            />
                        </>
                    )}
                </ul>
            </div>
        </div>
    );
};
