import DsNodeComponent from './node_component';
import { DsNodeModel } from './ds_nodemodel';
import React from 'react';
import { AbstractReactFactory, GenerateWidgetEvent } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { DS } from '../enums';
import DsNodeVariable from './node_variable';

export class DsNodeFactory extends AbstractReactFactory<DsNodeModel, DiagramEngine> {
    constructor() {
        super(DS.name);
    }

    generateReactWidget(event: GenerateWidgetEvent<DsNodeModel>): JSX.Element {
        // event.model.
        return event.model.getOptions().nodeType === 'variable' ? 
            <DsNodeVariable 
                engine={this.engine} 
                size={50} 
                node={event.model} 
            />
            :
            <DsNodeComponent 
                engine={this.engine} 
                size={50} 
                node={event.model} 
            />;
    }

    
    generateModel() {
        return new DsNodeModel();
    }
}