/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { Segment } from 'semantic-ui-react';
import Highlight from 'react-highlight.js';

// eslint-disable-next-line react/display-name
const ConsoleSegment = props => {
    // eslint-disable-next-line complexity
    return (
        <div style={{ textAlign: 'center' }}>
            <Segment
                style={{ width: '100%', margin: '0 auto', overflow: 'auto', height: '490px', borderRadius: '4px' ,backgroundColor: '#14182A', paddingBottom: 0 }}
                inverted
                size="small"
                textAlign="left"
            >
                <Highlight language={'sql'}>
                    {props.querySQL}
                </Highlight>
            </Segment>
        </div>
    );
};

export default ConsoleSegment;
