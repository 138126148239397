import { errorAlert } from "@components/toastify/notify-toast";
import { cloneDeep } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { FileRejection } from "react-dropzone";
import { uuid } from "uuidv4";
import {
	FileAborter,
	UploadedFileInfo,
	UploadedFilesMetaData,
	useFileUploaderType,
} from "./types";

const formatFileSize = (bytes: number) => {
	if (bytes == 0) return "0 Bytes";
	const k = 1000;
	const sizes = ["B", "Kb", "Mb", "Gb"];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

/**
 * useFileUploaderController takes onFileUpload and onDropRejected
 * onFileUpload should be used for uploading files
 * Returns onFileUploadFailure, setUploadProgress and other helpers
 * Do not forget to bind setUploadProgress to track the upload progress
 * onFileUploadFailure to check if file upload failed in the file upload api
 */
const useFileUploaderController: useFileUploaderType = ({
	onFileUpload,
	onDropRejected,
}) => {
	const [filesMetaData, setFilesMetaData] = useState<UploadedFilesMetaData>(
		{}
	);
	const [filesList, setFilesList] = useState<UploadedFileInfo[]>([]);
	const [uploadInProgress, setUploadInProgress] = useState(false);
	const [fileAborter, setFileAborter] = useState<FileAborter>({});

	const onFileUploadFailure = useCallback((fileId: string) => {
		setFilesMetaData((fileMeta) => {
			const _fileMeta = cloneDeep(fileMeta);
			if (_fileMeta[fileId]) _fileMeta[fileId].status = "FAILED";
			return _fileMeta;
		});
	}, []);

	const setUploadProgress = useCallback(
		(fileId: string, progressEvent: ProgressEvent) => {
			const percentCompleted = Math.round(
				(progressEvent.loaded * 100) / progressEvent.total
			);
			setFilesMetaData((fileMeta) => {
				const _fileMeta = cloneDeep(fileMeta);
				if (_fileMeta[fileId])
					_fileMeta[fileId].uploadedPer = percentCompleted;
				if (percentCompleted === 100) {
					_fileMeta[fileId].status = "SUCCESS";
				}
				return _fileMeta;
			});
		},
		[]
	);

	const _onDropRejected = (fileRejections: FileRejection[]) => {
		fileRejections.forEach((f) => {
			// errorAlert('File format not supported, Please import .'+ values.libraryTypeForUpload);
			errorAlert(f.file.name + " - " + f.errors[0].message);
		});
		onDropRejected?.(fileRejections);
	};

	const onDropAccepted = (uploadedFiles: File[]) => {
		const uploadedFilesMetaData: UploadedFilesMetaData = {};

		const __files: UploadedFileInfo[] = uploadedFiles.map((file) => {
			const fileId = uuid();
			const timeObj = moment();

			uploadedFilesMetaData[fileId] = {
				uploadedAt:
					timeObj.format("D MMM, YYYY") +
					" at " +
					timeObj.format("HH:mm"),
				status: "IN_PROGRESS",
				uploadedPer: 0,
				type: file.type,
				size: formatFileSize(file.size),
			};

			return { id: fileId, file };
		});

		onFileUpload(__files);
		setFilesMetaData((fileMeta) => ({
			...fileMeta,
			...uploadedFilesMetaData,
		}));
		setFilesList([...filesList, ...__files]);
	};

	const validateFileDrop = (file: File) => {
		if (/\s/.test(file.name)) {
			return { message: "Cannot have spaces", code: "no-spaces" };
		}
		return null;
	};

	const clearFilesList = () => {
		setFilesList([]);
		setFilesMetaData({});
	};

	useEffect(() => {
		filesList.map((file) => {
			if (filesMetaData[file.id].status === "IN_PROGRESS") {
				setUploadInProgress(true);
				return;
			}
			setUploadInProgress(false);
		});
	}, [filesMetaData, filesList]);

	const markFileAsDeleted = useCallback((fileId: string) => {
		const _filesMetaData = cloneDeep(filesMetaData);
		setFilesMetaData({
			..._filesMetaData,
			[fileId]: { ..._filesMetaData[fileId], status: "DELETED" },
		});
	}, [filesMetaData]);

	const abortUpload = () => {
		for(const key in fileAborter) {
			fileAborter[key]();
		}
	}

	const addFileAborter = useCallback((aborterData: FileAborter) => {
		setFileAborter((_fileAborter) => ({
			..._fileAborter,
			...aborterData,
		}));
	}, []);

	return {
		filesMetaData,
		filesList,
		clearFilesList,
		onDropAccepted,
		onDropRejected: _onDropRejected,
		validateFileDrop,
		onFileUploadFailure,
		setUploadProgress,
		markFileAsDeleted,
		uploadInProgress,
		addFileAborter,
		abortUpload,
	};
};

export default useFileUploaderController;
