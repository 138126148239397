export const theme = {
  sizes: {
    sideBarWidth: '72px',
    headerHeight: '64px',
  },
  colors: {
    secondary400: '#2A2C42',
    secondary500: '#1E2038',
    white: '#FFFFFF',
  },
};