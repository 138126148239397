import { SelectField, _selectoptionType } from "@components/form/select-field";
import React, { useContext } from "react";
import { SchemaCaptureProps } from "../field-creator";

type MlPipelineContextType = {
    modelsList: _selectoptionType[];
    versionsList: _selectoptionType[];
    onModelListSelection: (retrieveCategoryInfo: boolean, pipelineVersionFieldRef: React.RefObject<SelectField | null>, model: _selectoptionType) => void;
    captureSchemaOptions: SchemaCaptureProps['captureSchemaOptions'];
}


export const MlPipelineContext = React.createContext<MlPipelineContextType>({
    modelsList: [],
    versionsList: [],
    onModelListSelection: () => {return;},
    captureSchemaOptions: [{}, (() => {return;})]
});


export const useMlPipelineContext = () => {
    const context = useContext(MlPipelineContext);
    return context
}

