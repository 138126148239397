import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import ReactDOM from 'react-dom';


const dropdownRoot = document.getElementById('_dropdowns');

interface PortalProps {
    parentRef?: React.RefObject<HTMLElement | null>;
    childRef?: React.RefObject<HTMLElement | null>;
    showChild?: boolean;
    setParentWidthForChild?: boolean;
    className?: string;
    rightAlign?: boolean;
    children?: React.ReactNode;
    offset?: {top?: number; left?: number };
}

export type Portal = {
    reCalculatePosition: () => any; 
}


export const Portal = forwardRef<Portal, PortalProps>(({ children, className = '', showChild = false, setParentWidthForChild = false, rightAlign = false, parentRef, childRef, offset = {}  }, ref) => {
    const portalRef = useRef<Element>(document.createElement('div'));
    // const resizeObserverRef = useRef<any>(null);

    const reCalculatePosition = () => {
        if(parentRef?.current && childRef?.current) {
            const parentBoundingDim = parentRef.current.getBoundingClientRect();
            let top = parentBoundingDim.top + parentBoundingDim.height;
            if (offset.top) {
                top += offset.top;
            }
            childRef.current.style.top = top + 'px';
            if(rightAlign) {
                const childBoundingDim = childRef.current.getBoundingClientRect();
                childRef.current.style.left = `${(parentBoundingDim.right - childBoundingDim.width)}px`;
            } else {
                let left =  parentBoundingDim.left;
                if (offset.left) {
                    left += offset.left;
                }
                childRef.current.style.left = left + 'px';
            }
            if(setParentWidthForChild) {
                childRef.current.style.width = parentBoundingDim.width + 'px';
            }
        }
    };

    

    useEffect(() => {
        reCalculatePosition();
    }, [showChild]);

    useImperativeHandle(ref, () => ({
        reCalculatePosition
    }));

    useEffect(() => {
        dropdownRoot?.appendChild(portalRef.current);
        // const handleResizeObserver = new IntersectionObserver((entries) => {
        //     if(!isEmpty(entries)) {
        //         console.log(entries)
        //         reCalculatePosition();
        //         // optionsListRef.current.style.top = `${entries[0].contentRect.height + 6}px`;
        //     }
        // }, {
        //     root: null,
        //     threshold: 0.0001
        // });
        // resizeObserverRef.current = handleResizeObserver;
        // parentRef?.current && handleResizeObserver.observe(parentRef.current);

        return () => {
            dropdownRoot?.removeChild(portalRef.current);
        };
    }, []);

    useEffect(() => {
        portalRef.current.className = className;
    }, [className]);

    return ReactDOM.createPortal(children, portalRef.current);
});

Portal.displayName = 'CustomPortal';