import React, { useEffect, useMemo, useRef } from 'react';
import Form from '../form';
import { BaseFieldType } from './types';
import { initialValuesCreator } from './schema-creator';
import { FieldsCreator } from './field-creator';
import { useFormikContext } from 'formik';
import {
    cloneDeep,
    isEmpty,
    isEqual,
    merge,
    omit,
    reverse
} from 'lodash';
import './styles.scss';
import { useDebounce, useDidMount } from 'rooks';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/types';
import { WorkflowCanvas } from '../workflow-canvas';
import {
    callSuccessCallbackFunc,
    closeActiveForm,
    setActiveComponentPropsState,
    toggleModal
} from '../../store/workflow';
import RouteOptimisation  from '@components/RouteOptimisation';
import { LoseUnsavedFormChangesModal } from '../../pages/workflow-page/modals/lose-unsaved-form-changes';
import { DsPortModel } from '../workflow-canvas/port/ds_portmodel';
import { ShowWhenTrue } from '../../helpers';
import { setComponentPropertiesState } from '../../pages/workflow-page/utils';
import classNames from 'classnames';
import WorkflowConfigVariablePortFields from './WorkflowConfigVariablePortFields';
import { WorkflowCanvasTabInfo } from '@store/canvas';
import RefreshComponentInProperties from './RefreshComponent';
import { ComponentDescription } from 'types/component';

interface FormCreatorProps {
    formData: BaseFieldType[];
    componentInfo: ComponentDescription;
    onSubmit: (values: any, successCb ?: Function) => void;
    extraData: any;
    uniqueId: string;
    isReadOnly?: boolean;
    activeTabInfo: WorkflowCanvasTabInfo;
    isRouteOptimisationComponent?:boolean;
 }

interface FormControllerProps {
    handleFormSubmit: (arg0: Record<any, any>, successCb ?: Function) => any;
    isReadOnly: boolean;
}

const FormController: React.FC<FormControllerProps> = ({
    handleFormSubmit,
    isReadOnly
}) => {
    const {
        errors,
        setFieldValue,
        initialValues,
        values,
        setValues,
        setFieldTouched
    } = useFormikContext<Record<any, any>>();
    const activeComponentInfo = useSelector(
        (store: RootState) => store.WorkflowReducer.activeComponentInfo
    );
    const dispatch = useDispatch();
    const activeComponentPropsInfo = useSelector(
        (store: RootState) =>
            store.WorkflowReducer.activeComponentPropertiesInfo
    );
    const showBuildPipelineModal = useSelector(
        (store: RootState) =>
            store.WorkflowReducer.showModal.mlBuildPipelineModal
    );


    useDidMount(() => {
        // this is for triggering the errors if exists
        setTimeout(() => setFieldValue('toTriggerError', '1'), 1);
        // setTimeout(() => setMounted(true), 500);
    });

    const calculateSavedState = useDebounce(
        (initialValues: Record<any, any>, values: Record<any, any>) => {
            const currentStatus = isEqual(
                initialValues,
                omit(values, 'toTriggerError')
            );
            // console.log(initialValues, values);
            // console.log(difference(Object.values(initialValues), Object.values(omit(values, 'toTriggerError'))));
            // console.log(difference(Object.values(omit(values, 'toTriggerError')), Object.values(initialValues) ));
            // if(!currentStatus) {
            //     console.log(JSON.stringify(Object.entries(initialValues), null, 2))
            //     console.log(JSON.stringify(Object.entries(values), null, 2))
            // }
            if (activeComponentPropsInfo.isSaved !== currentStatus)
                dispatch(
                    setActiveComponentPropsState({ isSaved: currentStatus })
                );
        },
        300
    );

    useEffect(() => {
        calculateSavedState(initialValues, values);
    }, [initialValues, values]);

    useEffect(() => {
        if (activeComponentInfo) {
            const activeNode = WorkflowCanvas.getNode(activeComponentInfo.id);
            if (activeNode) {
                const activeNodeOutPortsLength = activeNode.getOutPorts()
                    .length;
                const numberOfPortsRequired = parseInt(
                    values['num_op_ports'],
                    10
                );
                if (activeNodeOutPortsLength < numberOfPortsRequired) {
                    // this adds ports
                    let i = activeNodeOutPortsLength;
                    for (i; i < numberOfPortsRequired; i++) {
                        activeNode.addOutPort(i);
                    }
                } else if (activeNodeOutPortsLength > numberOfPortsRequired) {
                    // this removes ports
                    const numberOfPortsToBeRemoved =
                        activeNodeOutPortsLength - numberOfPortsRequired;
                    // ports added after are removed
                    reverse(activeNode.getOutPorts()).map(
                        (port: DsPortModel, index) => {
                            if (index + 1 <= numberOfPortsToBeRemoved)
                                activeNode.removePort(port);
                        }
                    );
                }
                WorkflowCanvas.repaintCanvas();
            }
        }
    }, [values['num_op_ports']]);

    const activeComponentNodeInfo = useMemo(() => {
        if (activeComponentInfo) {
            const activeNode = WorkflowCanvas.getNode(activeComponentInfo.id);
            if (activeNode) return activeNode;
        }

        return null;
    }, [activeComponentInfo]);

    useEffect(() => {
        const activeNodeOptions = activeComponentNodeInfo?.getOptions();
        // Show the errors of fields on mount if the component is already saved once
        // ignore if its ml pipeline
        if(activeNodeOptions && activeNodeOptions.actualTitle !== "ML Pipeline" && activeNodeOptions.isPropertiesSavedAtleastOnce && !isEmpty(errors)) {
            Object.keys(errors).forEach((key) => {
                setFieldTouched(key, true)
            })
        }
    }, [errors, activeComponentNodeInfo])

    const validateComponentProperties = useDebounce(() => {
        if (activeComponentNodeInfo) {
            setComponentPropertiesState(
                activeComponentNodeInfo,
                errors,
                WorkflowCanvas
            );
            // const isPropsValid = activeComponentNodeInfo.getOptions().isPropertiesValid;
            // if(isEmpty(errors)){
            //     if(!isPropsValid){
            //         activeComponentNodeInfo.setIsPropertiesValid(true);
            //         WorkflowCanvas.repaintCanvas();
            //     }
            // } else if(isPropsValid) {
            //     activeComponentNodeInfo.setIsPropertiesValid(false);
            //     WorkflowCanvas.repaintCanvas();
            // }
        }
    }, 1000);

    useEffect(() => {
        validateComponentProperties();
    }, [errors, activeComponentNodeInfo, activeComponentPropsInfo]);

    const hideLoseUnSavedChangesModal = () => {
        dispatch(toggleModal('loseUnsavedFormChanges', false));
    };

    const onCancel = () => {
        hideLoseUnSavedChangesModal();
    };

    const hideModalAndProps = () => {
        dispatch(setActiveComponentPropsState({ isSaved: true }));
        activeComponentNodeInfo?.setSelected(false);
        hideLoseUnSavedChangesModal();
        // does the actual action its supposed to do
        // Opening a new workflow when one of the existing workflow's components for is not saved
        // setTimeout is to execute onDiscardChanges and onSaveCurrentAndContinue
        setTimeout(() => dispatch(callSuccessCallbackFunc()), 500);
    };

    const onDiscardChanges = () => {
        setValues(initialValues);
        validateComponentProperties();
        hideModalAndProps();
    };

    const onSaveCurrentAndContinue = () => {
        handleFormSubmit(values, hideModalAndProps);
    };

    const onSaveClick = () => {
        handleFormSubmit(values);
    }

    return (
        <>
            <div
                className={classNames('properties__btn__box', {
                    hide: showBuildPipelineModal
                })}
            >
                <button
                    className="btn-yellow btn-sm"
                    type="submit"
                    onClick={onSaveClick}
                    role="submit"
                    aria-label="save-component-form"
                    disabled={isReadOnly}
                >
                    Save
                </button>
                <button
                    className="btn-grey btn-sm"
                    type="button"
                    onClick={() => dispatch(closeActiveForm())}
                    disabled={activeComponentPropsInfo.isSaved || isReadOnly}
                >
                    Discard
                </button>
                <ShowWhenTrue show={!isReadOnly}>
                    {activeComponentPropsInfo.isSaved ? (
                        <span className="changes__msg">All changes saved</span>
                    ) : (
                        <span className="changes__msg changes__msg--unsaved">
                            Changes not saved
                        </span>
                    )}
                </ShowWhenTrue>
            </div>
            <LoseUnsavedFormChangesModal
                onCancel={onCancel}
                onDiscardChanges={onDiscardChanges}
                onSaveAndContinue={onSaveCurrentAndContinue}
            />
        </>
    );
};


export const FormCreator: React.FC<FormCreatorProps> = ({
    formData,
    onSubmit,
    componentInfo,
    extraData,
    uniqueId,
    activeTabInfo,
    isReadOnly = false,
    isRouteOptimisationComponent,
}) => {
    const additionalInitialData = useSelector((store: RootState) => {
        return store.WorkflowReducer.additionalValuesForForm;
    });

    const activeExecutionEnv = useSelector(
        (store: RootState) => store.CommonReducer.activeExecutionEnv
    );

    const initialValues = useMemo(
        () =>
            merge(
                cloneDeep(additionalInitialData),
                initialValuesCreator({ formData, extraData, activeExecutionEnv })
            ),
        [formData, additionalInitialData]
    );

    const fieldsRef = useRef<HTMLDivElement>(null);

    const getComponentDescription = useMemo(() => {
        if (componentInfo) {
            const descriptionField = componentInfo.find(
                component => component.key === 'description'
            );
            return (
                <div className="componentDescription">
                    <label className="inputfield__label">Description</label>
                    <span>
                        {descriptionField ? descriptionField.defaultValue : ''}
                    </span>
                </div>
            );
        }
    }, [componentInfo]);

    const handleFormSubmit = (values: any, successCb ?: Function) => {
        onSubmit(values, successCb);
    };

    if(isRouteOptimisationComponent){
        return(
            <div className="properties__fields__container"  ref={fieldsRef}>
                <RouteOptimisation getComponentDescription={getComponentDescription} formData={formData||[]} handleRouteFormSubmit={handleFormSubmit}/>
            </div>
        )
    }
    return (
        formData && (
            <Form
                initialValues={initialValues}
                // ONSUBMIT IS TRIGGED ON BUTTON CLICK TO SUBMIT THE FORM EVEN WHEN THERE ARE ERRORS
                // AS FORMIK DOESNT ALLOW FORM TO BE SUBMITTED
                onSubmit={(val) => handleFormSubmit(val)}
                validateOnMount
                enableReinitialize
            >
                <div className="properties__fields__container"  ref={fieldsRef}>
                    <div className='componentMetaData'>
                        {getComponentDescription}
                        <RefreshComponentInProperties />
                    </div>
                    <WorkflowConfigVariablePortFields
                        propertiesFieldsRef={fieldsRef}
                        isReadOnly={isReadOnly}
                        activeTabInfo={activeTabInfo}
                    />
                    <FieldsCreator formData={formData} uniqueKey={uniqueId} />        
                </div>
                <FormController
                    handleFormSubmit={handleFormSubmit}
                    isReadOnly={isReadOnly}
                />
            </Form>
        )
    );
};
