import React from 'react'
import styles from './styles.module.scss';
import GenAIContainer from './GenAIContainer';
import InputContainer from './container/InputContainer';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

TimeAgo.addLocale(en)

const Layout = () => {
  return (
    <>
      <div id="gen_ai" className={styles['gen_ai']}>
          <GenAIContainer />
          <InputContainer />
      </div>
    </>
  )
}

export default Layout