
export const Tabs = {
    COMPONENTS: 'COMPONENTS',
    WORKFLOWS: 'WORKFLOWS'
};

export const MasterTabs = {
    COMPONENTS: 'COMPONENTS',
    DATASOURCES: 'DATASOURCES'
};

export const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
];

export const WEEKLY_DAY = {
    1: 'Sunday',
    2: 'Monday',
    3: 'Tuesday',
    4: 'Wednesday',
    5: 'Thursday',
    6: 'Friday',
    7: 'Saturday',
    
};

export enum ExecutionEnvModes{
    Spark = 'spark',
    Python = 'python',
    Streaming = 'streaming',
    Pipelines = 'dag',
}


export enum ExecutionEnvModesLabels{
    spark = 'Spark',
    python = 'Python',
    streaming = 'Streaming',
    dag = 'Pipelines',
}

export enum JobsExecutionModes {
    databricks = 'databricks',
    livy = 'livy'
}

export enum StorageRef {
    ActiveZeppelinId = 'ActiveZeppelinId'
}

export const uuidRegex = /[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/;

export enum UserRoles {
    'User' = 'USER',
    'Admin' = 'ADMIN'
}

export const noSpecialCharactersRegex = /^[A-Za-z0-9_\- ]+$/;
export const noEmptySpaceRegex = /^[a-zA-Z0-9À-ÿ '-]*[a-zA-Z0-9À-ÿ'-][a-zA-Z0-9À-ÿ '-]*/;
export const noBlankSpaceInputFieldRegex =/^\S*$/;
