/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Tooltip} from 'antd';
import HandGrab from './handGrab';

import { Draggable } from 'react-beautiful-dnd';
import Buttons from './buttons';

import JoinPopup from './joinPopup';
import TableSearchBar from './TableSearchBar';
import JoinSearchBarSource from './JoinSearchBarSource';
import JoinSearchBar from './JoinSearchBar';

const portal = document.createElement('div');
document.body.appendChild(portal);

const PortalDraggableItem = props => {
    const {
        provided,
        snapshot,
        rowIndex,
        db,
        query,
        row,
        updateQueryState,
    } = props;
    const result = (
        <div ref={provided.innerRef} {...provided.draggableProps}>
            <div className="drag"  
                style={{
                    backgroundColor: 'rgba(102, 106, 141, 0.72)', 
                    borderRadius: '2px',
                    margin: '5px 0',
                    padding: '5px'
                }}>
                <div
                    style={{
                        position: 'relative',
                        width: '100%'
                    }}
                >
                    <div style={{ display: 'inline-block' }}>
                        <div className="query_position_indicator"></div>
                        <div
                            {...props.provided.dragHandleProps}
                            style={{ 
                                display: 'inline-block',
                                position: 'absolute',
                                top: '50%',
                                left: '5px',
                                transform: 'translate(-50%, -50%)'
                            }}
                        >
                            <HandGrab />
                        </div>
                        <div>
                            <div
                                style={{
                                    verticalAlign: 'top',
                                    display: 'inline-block',
                                    paddingLeft: '15px'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'inline-block',
                                        position: 'absolute',
                                        right: 0,
                                        top: 5
                                    }}
                                >
                                    <Buttons
                                        type="fromJoinRow"
                                        updateQueryState={updateQueryState}
                                        rowIndex={rowIndex}
                                        query={query}
                                    />
                                </div>

                                <div
                                    style={{
                                        display: 'inline-block',
                                    }}
                                >
                                    <TableSearchBar
                                        style={{ position: 'relative' }}
                                        rowIndex={rowIndex}
                                        resultTables={db.getTableNames}
                                        table={row.tableMetadata.name}
                                        tableText={row.tableText}
                                        tableTextText={row.tableTextText}
                                        tableTextInitial={row.tableTextInitial}
                                        tableTextError={row.tableTextError}
                                        updateQueryState={updateQueryState}
                                        query={query}
                                    />
                                </div>
                            </div>
                            {rowIndex > 0 ? (
                                <div
                                    style={{
                                        display: 'inline-block',
                                        width: '110px',
                                        marginLeft: '10px',
                                        marginRight: '10px',
                                    }}
                                >
                                    <JoinPopup
                                        updateQueryState={updateQueryState}
                                        rowIndex={rowIndex}
                                        query={query}
                                    />
                                </div>
                            ) : null}

                            <div
                                style={{
                                    display: 'none',
                                    verticalAlign: 'top',
                                    marginTop: '7px',
                                    marginLeft: '4px',
                                    width: '40px',
                                    //display: 'inline-block',
                                }}
                            >
                                {/* {`AS ${row.tableAlias}`} */}
                            </div>
                            {rowIndex > 0 ? (
                                <div
                                    style={{
                                        verticalAlign: 'top',
                                        display: 'inline-block',
                                        marginTop: '7px',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                        color: 'white'
                                    }}
                                >
                                    <span>Condition</span>
                                    <Tooltip placement="top" title="Condition: A JOIN clause is used to combine rows from two or more dataframe(s), based on related column(s) between them ">
                                        <img src="/icons/info-fields.png" width="16" height="16" style={{marginLeft: '3px'}} className="info__icon" alt="" />
                                    </Tooltip>
                                </div>
                            ) : null}
                            {rowIndex > 0 ? (
                                <div
                                    style={{
                                        verticalAlign: 'top',
                                        display: 'block',
                                        marginLeft: '9px'
                                    }}
                                >
                                    <div style={{ verticalAlign: 'top' }}>
                                        {row.joinColumns.map((col, colIndex) => (
                                            <div
                                                style={{
                                                    verticalAlign: 'top',
                                                    margin: '5px 0'
                                                }}
                                                key={`jc-${rowIndex}-${colIndex}`}
                                            >
                                                {colIndex > 0 ? (
                                                    <div style={{ display: 'inline-block', color: 'white' }}>&nbsp;AND</div>
                                                ) : <div style={{ display: 'inline-block', color: 'white', width: colIndex > 0 ? '32px' : 0 }}></div>}
                                                <div
                                                    style={{
                                                        display: 'inline-block',
                                                        verticalAlign: 'top',
                                                        marginLeft: '5px',
                                                        marginRight: '0px',
                                                    }}
                                                >
                                                    <JoinSearchBarSource
                                                        rowIndex={rowIndex}
                                                        joinColumnIndex={colIndex}
                                                        table={row.tableMetadata}
                                                        tableAlias={row.tableAlias}
                                                        columnText={col.rowTableJoinColumn.name}
                                                        error={col.rowTableJoinColumn.error}
                                                        initial={col.rowTableJoinColumn.initial}
                                                        text={col.rowTableJoinColumn.text}
                                                        query={query}
                                                        updateQueryState={updateQueryState}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'inline-block',
                                                        verticalAlign: 'top',
                                                        margin: '5px',
                                                        color: 'white'
                                                    }}
                                                >
                                            =
                                                </div>
                                                <div style={{ display: 'inline-block' }}>
                                                    <JoinSearchBar
                                                        rowIndex={rowIndex}
                                                        joinColumnIndex={colIndex}
                                                        text={col.previousTableJoinColumn.text}
                                                        previousTablesJoinColumns={
                                                            row.previousTablesJoinColumns
                                                        }
                                                        previousTableJoinColumn={
                                                            col.previousTableJoinColumn.name
                                                        }
                                                        error={col.previousTableJoinColumn.error}
                                                        initial={col.previousTableJoinColumn.initial}
                                                        query={query}
                                                        updateQueryState={updateQueryState}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'inline-block',
                                                        verticalAlign: 'top',
                                                        margin: '5px',
                                                        marginTop: '3px',
                                                    }}
                                                >
                                                    <Buttons
                                                        type="joinCondition"
                                                        updateQueryState={updateQueryState}
                                                        rowIndex={rowIndex}
                                                        query={query}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    if (snapshot.isDragging) {
        return ReactDOM.createPortal(result, portal);
    }
    return result;
};

class FromJoinRow extends Component {
    render() {
        const { rowIndex, query, updateQueryState, db, row } = this.props;

        return (
            <Draggable
                key={`itemF-${rowIndex}`}
                draggableId={`itemF-${rowIndex}`}
                index={rowIndex}
            >
                {(provided, snapshot) => {
                    return (
                        <div>
                            <PortalDraggableItem
                                provided={provided}
                                snapshot={snapshot}
                                query={query}
                                updateQueryState={updateQueryState}
                                row={row}
                                rowIndex={rowIndex}
                                db={db}
                            />
                            {provided.placeholder}
                        </div>
                    );
                }}
            </Draggable>
        );
    }
}

export default FromJoinRow;
