import { useEffect, useState, useRef, useMemo } from 'react';
import React from 'react';
import { UserHandler, UserInfo, UserRole } from '../../../api/user-handler';
import cs from 'classnames';
import styles from './../styles.module.scss';
import { UsersTable } from './UsersTable';
import UserInfoSection from './UserInfoSection';
import { AdminUserInfo, ConcurrentUser, SelectedUserInfo } from './../types';
import { UserRoles } from '../../../constants/enums';
import { EditUserModal } from './../modals/edit-user';
import { errorHandlerFor400 } from '../../../api/services/errorhandler';
import LeftNav from '../left-nav';
import { UserDetails } from '@components/header-sidebar/user-details';
import { UserConcurrentTable } from './UserConcurrentTable';

const AdminPage: React.FC = () => {
    const [usersList, setUsersList] = useState<AdminUserInfo[]>([]);
    const [usersConcurrentList, setUsersConcurrentList] = useState<ConcurrentUser[]>([]);
    const [showConcurrentUser, setConcurrentUser] = useState(false);
    const [selectedUserInfo, setSelectedUserInfo] = useState<SelectedUserInfo>(
        null
    );
    const [searchText, setSearchText] = useState('');
    const [showUserMgmtModal, toggleUserMgmtModal] = useState(false);
    const [rolesList, setRolesList] = useState<UserRole[]>([]);
    const [showUserSectionLoader, toggleUserSectionLoader] = useState(false);

    const closeUserMgmtModal = () => {
        toggleUserMgmtModal(false);
    };

    const handleShowUserMgmtModal = () => {
        toggleUserMgmtModal(true);
    };

    const onUserInfoResponse = (res: UserInfo) => {
        const _selectedUserInfo: NonNullable<SelectedUserInfo> = {
            ...res,
            fullName: res.firstName + ' ' + (res.lastName ?? ''),
            status: res.enabled ? 'Active' : 'In-active',
            role: res.roles.find(role => role.name === UserRoles['Admin'])
                ? UserRoles['Admin']
                : UserRoles['User']
        };
        setSelectedUserInfo(_selectedUserInfo);
        toggleUserSectionLoader(false);
    };

    const onUserSelection = (user: AdminUserInfo) => {
        toggleUserSectionLoader(true);
        setSelectedUserInfo({
            ...user,
            fullName: user.firstName + ' ' + (user.lastName ?? ''),
            role: UserRoles['User'],
            roles: [],
            groups: [],
            access: {}
        });
        UserHandler.GetUserInfo(user.id, onUserInfoResponse, res => {
            errorHandlerFor400(res);
            toggleUserSectionLoader(false);
        });
    };

    const setAllUsers = () => {
        UserHandler.GetAllUsers(res => {
            const usersList: AdminUserInfo[] = res.map(user => ({
                ...user,
                status: user.enabled ? 'Active' : 'In-active'
            }));
            !!usersList.length && !selectedUserInfo && onUserSelection(usersList[0]);
            setUsersList(usersList);
        });
    };

    const getAllRoles = () => {
        UserHandler.GetAllRoles(res => {
            setRolesList(res);
        });
    };

    useEffect(() => {
        setAllUsers();
        getAllRoles();
    }, []);

    useEffect(() => {
        if(showConcurrentUser) {
            UserHandler.GetActiveSessions((data: any) => {
                setUsersConcurrentList(data.activeUsersList);
            })
        }
    }, [showConcurrentUser]);

    const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const usersListToShow = useMemo(() => {
        const _searchText = searchText.toLowerCase();
        return usersList.filter(
            user =>
            user.email.includes(_searchText) ||
            (user.firstName ?? '').toLowerCase().includes(_searchText) ||
            (user.lastName ?? '').toLowerCase().includes(_searchText)
        );
    }, [usersList, searchText]);

    const usersConcListToShow = useMemo(() => {
        const _searchText = searchText.toLowerCase();
        return usersConcurrentList.filter(
            user =>
                user.username.includes(_searchText)
        );
    }, [usersConcurrentList, searchText]);

    const onUpdateUserSuccess = (res: UserInfo) => {
        closeUserMgmtModal();
        // Update User info in RHS User Section and the users table
        onUserInfoResponse(res);
        setAllUsers();
    };

    const onRefresh = () => {
        UserHandler.GetActiveSessions((data: any) => {
            setUsersConcurrentList(data.activeUsersList);
        })
	};
    return (
        <section className={cs(styles['admin__container'])}>
            <LeftNav selected_id='user' />
            <div className={styles['content_holder']}>
                <header>
                    <h1>
                        <img src="/icons/user-manage.svg" alt="" />
                        User Management
                    </h1>
                </header>
                <br/>
                <div className={styles['pageTableHeader']}>
                    <div
                        className={cs(
                            'search__input__container',
                            styles['search__input__container']
                        )}
                    >
                        <input
                            value={searchText}
                            onChange={handleSearchTextChange}
                            className="search_input"
                            placeholder="Search Users"
                        />

                        {!!searchText && (
                            <button
                                onClick={() => {
                                    setSearchText('');
                                }}
                            >
                                <img
                                    src="/icons/treeview/search-cross.svg"
                                    alt=""
                                    className="search_reset"
                                />
                            </button>
                        )}
                    </div>
                    <div className={cs(styles['inline'], styles['ml-half'], styles['mt-half'])}>
                        <label className="checkbox__container checkbox__gold ml-1">
                            <input 
                                type="checkbox" 
                                value="" 
                                onChange={(e) => {
                                    setConcurrentUser(e.target.checked)
                                }}
                            />
                            <span className="checkmark" style={{
                                fontSize: '1rem',
                            }}></span>
                        </label>
                        <span  className={styles['ml-half']}>Show Concurrent Users</span>
                    </div>
                    {showConcurrentUser && <div className={cs(styles['ml-half'], styles['mt-half'])}>
                        <button onClick={onRefresh}><img src="/icons/data-browser/refresh.svg" /></button>
                    </div>}
                </div>
                {
                    showConcurrentUser ? (
                        <div className={styles['mainContainer']}>
                            <UserConcurrentTable 
                                usersList={usersConcListToShow}
                            />
                        </div>
                    ) : (
                        <div className={styles['mainContainer']}>
                            <UsersTable
                                usersList={usersListToShow}
                                onUserSelection={onUserSelection}
                                selectedUserInfo={selectedUserInfo}
                            />
                            <UserInfoSection
                                selectedUserInfo={selectedUserInfo}
                                showUserMgmtModal={handleShowUserMgmtModal}
                                showLoader={showUserSectionLoader}
                            />
                        </div>
                    )
                }
                
                <EditUserModal
                    onUpdateUserSuccess={onUpdateUserSuccess}
                    showModal={showUserMgmtModal}
                    toggleClose={closeUserMgmtModal}
                    selectedUserInfo={selectedUserInfo}
                    rolesList={rolesList}
                />
            </div>
            <div
                className={cs("properties__container", {
                    "properties__container--minimized": true,
                })}
            >
                <UserDetails hideNameOnMinimize={true} />
            </div>
        </section>
    );
};

export default AdminPage;
