/*eslint-disable no-console */

import { RootState } from '../types';
import { deserializeReduxState, serializeReduxState, PersistedReduxState } from '.';

export const loadReduxStateFromLS = (): PersistedReduxState | undefined => {
    try {
        const serializedState = localStorage.getItem('state');
        if(!!serializedState && !!localStorage.getItem('token')) {
            return deserializeReduxState(serializedState);
        }
        return undefined;
    } catch (err) {
        console.log(err);
        return undefined;
    }
};
  
export const saveReduxStateInLS = (state: RootState) => {
    try {
        if (localStorage.getItem('token') !== null) {
            const serializedState = serializeReduxState(state);
            localStorage.setItem('state', serializedState);
        }
    } catch (err) {
        console.log(err);
        // Ignore write errors.
    }
};