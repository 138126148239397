import { LoginPostData, LoginResponse, SignupPostData, UpdatePasswordData, AllUsersInfo, ForgotPasswordData, ValidateTokenResponse, ResetPasswordData, ValidateUsernameData, ValidateUsernameResponse, UpdateProfile, UserInfo, CreateUserData, CrateGitRepoResponseTypes, GitRepoTypes, ActiveSessionsInfo } from './types';
import { HttpClient } from '../services';
import { API, Env } from '../../constants/settings';
import { errorHandlerCallback } from '../services/httpinvoker';/* eslint-disable-line */
import { ApiPromise, ApiResponse } from '../data-source-handler';

class UserHandlerClass {
    Login(data: LoginPostData, callback: (arg0: LoginResponse) => void, errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Post('Login', API.url +  '/auth/signin', data, callback, errorHandlerCallback);
    }

    // GetConcurrentUser(cb: ApiResponse<any>) {
    //     HttpClient.Get('Get concurrent users', API.userManagementUrl + '/client/datastudio-public/users/active-sessions', cb);
    // }
    
    Signup(data: SignupPostData, callback: (arg0: any) => void) {
        HttpClient.Post('Sign up', API.url + '/auth/signup', data, callback);
    }

    ValidateUsername(data: ValidateUsernameData, callback: ApiResponse<ValidateUsernameResponse>) {
        HttpClient.Post('Sign up', API.url + '/auth/usernames', data, callback);
    }

    // GetUserInfo(callback: ApiResponse<UserInfo>, errorCallback: (arg0: AxiosResponse) => void){
    //     HttpClient.Get('Get user info', API.url + '/user/me', callback, errorCallback);
    // }

    UpdatePassword(data: UpdatePasswordData, callback: (arg0: any) => void, errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Post('Update Password', API.url +  '/user/updatePassword', data, callback, errorHandlerCallback);
    }

    // UpdateProfile(data:UpdatePasswordData);
    UpdateProfile(data: UpdateProfile, callback: (arg0: any) => void, errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Post('Update Profile', API.url +  '/user/updateProfile', data, callback, errorHandlerCallback);
    }

    GetAllUsers(callback: (arg0: AllUsersInfo) => any){
        HttpClient.Get('Get all users', API.userManagementUrl + '/users', callback);
    }

    GetUserInfo(userId: string, callback: ApiResponse<UserInfo>, errorHandlerCallback: ApiResponse<any>){
        HttpClient.Get('Get all users', API.userManagementUrl + '/users/' + userId, callback, errorHandlerCallback);
    }

    GetAllRoles(callback: ApiResponse<any>) {
        HttpClient.Get('Get all roles', API.userManagementUrl + '/roles', callback);
    }

    ForgotPassword(data: ForgotPasswordData, callback: any, errorHandlerCallback: errorHandlerCallback) {
        HttpClient.Post('Send forgot password email', API.url + '/auth/forgot-password', data, callback, errorHandlerCallback);
    }

    ValidateToken(token: string): ApiPromise<ValidateTokenResponse>{
        return HttpClient.PostPromise(API.url + '/auth/validate-token', { token });
    }

    ResetPassword(data: ResetPasswordData, callback: any, errorHandlerCallback: errorHandlerCallback) {
        HttpClient.Post('Reset password', API.url + '/auth/reset-password', data, callback, errorHandlerCallback);
    }

    InitialCall(cb: ApiResponse<any>) {
        // to create default directory in the backend
        HttpClient.Get('Initial Call', API.url + '/v2/load-data', cb, cb);
    }

    CreateUser(data: CreateUserData, cb: ApiResponse<any>, errorCb: errorHandlerCallback) {
        HttpClient.Post('Create user', API.userManagementUrl + '/users', data, cb, errorCb);
    }

    UpdateUser(userId: string, data: CreateUserData, cb: ApiResponse<UserInfo>, errorCb: errorHandlerCallback) {
        HttpClient.Put('Create user', API.userManagementUrl + '/users/' + userId, data, cb, errorCb);
    }   

    GetGitRepoDetails(callback: ApiResponse<any>) {
        HttpClient.Get('Get git repo details', API.gitUrl + '/api/repo/', callback);
    }

    GetPlatformList(callback: ApiResponse<any>) {
        HttpClient.Get('Get git repo details', API.gitUrl + '/api/repo/platforms', callback);
    }

    DeleteGitRepoDetails(userId: number, callback: ApiResponse<CrateGitRepoResponseTypes>) {
        HttpClient.Delete('Delete git repo details', API.gitUrl + `/api/repo/${userId}`, callback);
    }

    CreateGitRepo(data: GitRepoTypes, cb: ApiResponse<CrateGitRepoResponseTypes>, errorCb: errorHandlerCallback) {
        HttpClient.Put('Create git integration',API.gitUrl + '/api/repo/',data, cb, errorCb);
    }

    GetActiveSessions(callback: ApiResponse<ActiveSessionsInfo>) {
        HttpClient.Get('Get active sessions', `${API.userManagementUrl}/client/${Env.keycloakClientId}/users/active-sessions`, callback);
    }
}

const UserHandler = new UserHandlerClass();

export { UserHandler };