import { AnalyticsProvider } from '@pages/workflow-analytics-page/useAnalyticsContext';
import React from 'react';
import DataExplorerLayout from './components/layout';
import { DataExplorerProvider } from './useDataExplorerContext';
import { GenAIProvider } from './gen-ai/useGenAIContext';

const DataExplorer: React.FC = () => {
    return (
        <DataExplorerProvider>
            <AnalyticsProvider>
                <GenAIProvider>
                    <DataExplorerLayout />
                </GenAIProvider>
            </AnalyticsProvider>
        </DataExplorerProvider>
    );
};

export default DataExplorer;