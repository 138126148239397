import {
	ConnectedComponentFieldInfo,
	WorkflowConfigMappingInFormikContext,
} from "@components/formcreators/WorkflowConfigVariablePortFields/reducer";
import {
	getComponentFieldStatus,
	getVariableUniqueId,
	getWorkflowConfigItemPlaceholder,
} from "@components/formcreators/WorkflowConfigVariablePortFields/utils";
import { errorAlert, infoAlert } from "@components/toastify/notify-toast";
import { WorkflowCanvas } from "@components/workflow-canvas";
import { DsNodeExtras } from "@components/workflow-canvas/node/ds_nodemodel";
import { getComponentTreeData } from "@services/RiTreeParser";
import refreshComponentInfo from "@services/RiTreeParser/refreshComponentUtil";
import { ComponentTreeView } from "@services/RiTreeParser/types";
import { WorkflowConfigItemType } from "@services/WorkflowConfig";
import { WorkflowConfigItem } from "@services/WorkflowConfig";
import { updateAWorkflowEditorTabInfo } from "@store/canvas";
import { AppThunk } from "@store/types";
import {
	ActiveComponentInfo,
	setActiveComponentInfo,
	setCurrentWorkflowStateToUnsaved,
} from "@store/workflow";
import { getUniqueId } from "@utils/common";
import { get, has, isEmpty, cloneDeep, set } from "lodash";
import {
	WorkflowActionHandlerTypes,
	WorkflowConfigItemToUpdate,
} from "./types";
import {
	getNodeWorkflowConfigItems,
	getWorkflowConfigNumberDataType,
} from "./utils";

const setNewComponentInfoAsActiveComponent = (
	activeComponentInfo: ActiveComponentInfo,
	componentExtras: DsNodeExtras
): AppThunk => (dispatch) => {
	dispatch(
		setActiveComponentInfo({
			...cloneDeep(activeComponentInfo),
			formDetails: componentExtras.formData,
			portManagementDetails: componentExtras.portManagement,
			componentInfo: componentExtras.componentInfo,
		})
	);
};

const workflowActionHandler = ({
	type,
	payload,
	successCb,
	failureCb,
}: WorkflowActionHandlerTypes): AppThunk => (dispatch, getState) => {
	const {
		CanvasReducer: {
			workflowEditor: {
				activeTab: workflowEditorActiveTab,
				openTabs: workflowEditorOpenTabs,
			},
		},
		WorkflowReducer: { activeComponentInfo },
	} = getState();

	const activeWorkflowEditorTabInfo = workflowEditorOpenTabs.get(
		workflowEditorActiveTab.id
	);

	switch (type) {
		case "ADD_IDS_TO_WORKFLOW_CONFIG_ITEMS_OF_ACTIVE_TAB": {
			if (activeWorkflowEditorTabInfo) {
				const updatedWorkflowConfig: WorkflowConfigItem[] = activeWorkflowEditorTabInfo?.info.config.map(
					(workflowConfigItem) => {
						return {
							...workflowConfigItem,
							id: getUniqueId(),
						};
					}
				);
				dispatch(
					updateAWorkflowEditorTabInfo({
						...activeWorkflowEditorTabInfo,
						info: {
							...activeWorkflowEditorTabInfo.info,
							config: updatedWorkflowConfig,
						},
					})
				);
			}

			break;
		}
		case "UPDATE_WORKFLOW_CONFIG_MAP_IN_COMPONENTS": {
			const updatedConfigMap = payload.reduce(
				// had to add ts-ignore to fix any error
				// @ts-ignore
				(mapping, workflowConfig) => {
					mapping[workflowConfig.id] = workflowConfig;
					return mapping;
				},
				{} as Record<string, WorkflowConfigItem>
			);

			// Either one workflow item can be edited at once
			// Or
			// Multiple workflow items can be deleted

			let updatedWorkflowConfigItem: WorkflowConfigItemToUpdate | null = null;
			const deletedWorkflowConfigItems: WorkflowConfigItem[] = [];

			activeWorkflowEditorTabInfo?.info.config?.some((workflowConfig) => {
				if (has(updatedConfigMap, workflowConfig.id)) {
					// current config key exists in the updated config and key is updated in the updated Config
					if (
						updatedConfigMap[workflowConfig.id].key !==
						workflowConfig.key
					) {
						updatedWorkflowConfigItem = {
							...workflowConfig,
							updatedKey: updatedConfigMap[workflowConfig.id].key,
						};
						return true;
					}
				} else {
					// if current config key doesn't exist in the updated config
					deletedWorkflowConfigItems.push(workflowConfig);
				}
				return false;
			});

			if (
				!isEmpty(deletedWorkflowConfigItems) ||
				updatedWorkflowConfigItem
			) {
				Object.values(WorkflowCanvas.getAllNodes()).forEach((node) => {
					const workflowConfigData = cloneDeep(
						get(node.extras.extraData, "workflow_config_mapping")
					) as WorkflowConfigMappingInFormikContext | undefined;

					// check if user has selected workflow config items in the component
					if (workflowConfigData?.selectedConfigKeys) {
						const formData = cloneDeep(node.extras.formData);
						const workflowConfigItemsInCurrentNode = getNodeWorkflowConfigItems(
							updatedWorkflowConfigItem
								? [updatedWorkflowConfigItem]
								: deletedWorkflowConfigItems,
							workflowConfigData
						);

						const isConfigItemUpdate = !!updatedWorkflowConfigItem;

						workflowConfigItemsInCurrentNode.forEach(
							(configItem) => {
								formData.forEach((field) => {
									const fieldId = getVariableUniqueId(
										field.templateOptions.label,
										field.key
									);
									// if current field is selected in current config item
									if (
										configItem.selectedFieldIdMapping[
											fieldId
										]
									) {
										const fieldInfo: ConnectedComponentFieldInfo = {
											label: field.templateOptions.label,
											id: fieldId,
											hidden: false,
											fieldKey: field.key,
											type:
												field.type === "select"
													? "select"
													: "input",
											selected: false,
										};

										// remove the existing config item placeholder
										field.value = getComponentFieldStatus(
											field.value,
											getWorkflowConfigItemPlaceholder(
												configItem.key
											),
											fieldInfo
										)._value;

										if (
											isConfigItemUpdate &&
											configItem.updatedKey
										) {
											fieldInfo.selected = true;
											// add the updated key placeholder
											// if configItem has updated key -> it means item is being updated
											field.value = getComponentFieldStatus(
												field.value,
												getWorkflowConfigItemPlaceholder(
													configItem.updatedKey
												),
												fieldInfo
											)._value;
										}
									}
								});
								if (
									isConfigItemUpdate &&
									configItem.updatedKey
								) {
									// Replace old key's keyFieldMapping with new key
									const oldKeyFieldMapping = get(
										workflowConfigData.keyFieldMapping,
										configItem.key
									);
									set(
										workflowConfigData.keyFieldMapping,
										configItem.updatedKey,
										oldKeyFieldMapping
									);
									// delete old key KeyFieldMapping
									delete workflowConfigData[
										"keyFieldMapping"
									][configItem.key];

									// replace old key with new key
									set(
										workflowConfigData,
										"selectedConfigKeys",
										workflowConfigData.selectedConfigKeys.replace(
											configItem.key,
											configItem.updatedKey
										)
									);
								} else {
									// remove the configItemKey from keyFieldMapping
									delete workflowConfigData[
										"keyFieldMapping"
									][configItem.key];
									// Remove the configItemKey from selectedConfigKeys list
									// selectedConfigKeys - "key1,key2,key3"
									set(
										workflowConfigData,
										"selectedConfigKeys",
										get(
											workflowConfigData,
											"selectedConfigKeys"
										)
											.split(",")
											.filter(
												(configKey) =>
													configKey !== configItem.key
											)
											.join(",")
									);
								}
							}
						);

						node.extras.formData = formData;
						set(
							node.extras.extraData,
							"workflow_config_mapping",
							workflowConfigData
						);
					}
				});
			}
			break;
		}
		case "CONVERT_NUMBER_TYPE_TO_INT_OR_DECIMAL": {
			if (activeWorkflowEditorTabInfo) {
				const updatedWorkflowConfig: WorkflowConfigItem[] = activeWorkflowEditorTabInfo?.info.config.map(
					(workflowConfigItem) => {
						if (
							workflowConfigItem.type ===
							WorkflowConfigItemType.Number
						) {
							return {
								...workflowConfigItem,
								type: getWorkflowConfigNumberDataType(
									workflowConfigItem.value as any
								),
							};
						}
						return workflowConfigItem;
					}
				);
				dispatch(
					updateAWorkflowEditorTabInfo({
						...activeWorkflowEditorTabInfo,
						info: {
							...activeWorkflowEditorTabInfo.info,
							config: updatedWorkflowConfig,
						},
					})
				);
			}

			break;
		}
		case "REFRESH_COMPONENT":
			{
				if (activeWorkflowEditorTabInfo) {
					const component = WorkflowCanvas.getNode(
						payload.componentId
					);
					if (component) {
						((dispatch(
							getComponentTreeData()
						) as unknown) as Promise<ComponentTreeView>)
							.then((updatedTreeData) => {
								const isComponentUpdated = (dispatch(
									refreshComponentInfo(
										component,
										updatedTreeData
									)
								) as unknown) as boolean;
								if (isComponentUpdated && activeComponentInfo) {
									dispatch(
										setNewComponentInfoAsActiveComponent(
											activeComponentInfo,
											component.extras
										)
									);
									dispatch(
										setCurrentWorkflowStateToUnsaved()
									);
									infoAlert("Refreshed successfully");
								} else {
									infoAlert("No changes found");
								}
								successCb?.();
							})
							.catch(() => {
								failureCb?.();
								errorAlert("Couldn't refresh the component");
							});
					}
				}
			}
			break;
		case "REFRESH_COMPONENTS_IN_ACTIVE_WORKFLOW": {
			if (activeWorkflowEditorTabInfo) {
				((dispatch(getComponentTreeData()) as unknown) as Promise<
					ComponentTreeView
				>)
					.then((updatedTreeData) => {
						let isAnyComponentUpdated = false;
						Object.values(WorkflowCanvas.getAllNodes()).forEach(
							(component) => {
								const isCurrentComponentUpdated = (dispatch(
									refreshComponentInfo(
										component,
										updatedTreeData
									)
								) as unknown) as boolean;

								isAnyComponentUpdated =
									isCurrentComponentUpdated ||
									isAnyComponentUpdated;

								if (
									activeComponentInfo?.id ===
									component.getID()
								) {
									dispatch(
										setNewComponentInfoAsActiveComponent(
											activeComponentInfo,
											component.extras
										)
									);
								}
							}
						);
						if (isAnyComponentUpdated) {
							infoAlert("Refreshed successfully");
							dispatch(
								setCurrentWorkflowStateToUnsaved()
							);
						} else {
							infoAlert("No changes found");
						}
					})
					.catch(() => {
						errorAlert(
							"Couldn't refresh components in the workflow"
						);
					});
			}
			break;
		}
	}
};

export default workflowActionHandler;
export * from "./types";
