/* eslint-disable no-mixed-spaces-and-tabs */

import React from "react";
import { DsLinkModel } from "./ds_linkmodel";
import { ComponentStatus } from "../../../store/workflow";
import { store as _store } from "../../../store";
import classNames from "classnames";
import { PREVIEW_SUCCESS } from "../../../api/workflow-handler";
import { WorkflowCanvas } from "..";
import { debounce, get, isEmpty } from "lodash";
import { ShowWhenTrue } from "../../../helpers";
import { ExecutionEnvModes } from "../../../constants/enums";
import { WorkflowCanvasTabInfo } from "../../../store/canvas";
import { DsNodeModel } from "../node/ds_nodemodel";
import { Popover } from "antd";

export class DsLinkSegment extends React.Component<
	{ model: DsLinkModel; path: string; selected: boolean },
	{
		previewComponentStatus: ComponentStatus;
		targetNode: string;
		sourceNode: string;
		selectedUsingClick: boolean;
		showContextMenu: boolean;
		isStreamingEnv: boolean;
		isStreamingPreview: boolean;
		isPreviewDisabled: boolean;
	}
> {
	path?: SVGPathElement | null;
	handle: any;
	unsubscribe: any;
	contextMenuRef: React.RefObject<SVGForeignObjectElement>;
	activeTabPreviewStatusRef: WorkflowCanvasTabInfo["previewStatusInfo"];

	constructor(
		props: Readonly<{ model: DsLinkModel; path: string; selected: boolean }>
	) {
		super(props);
		this.state = {
			previewComponentStatus: {},
			targetNode: "",
			sourceNode: "",
			selectedUsingClick: false,
			showContextMenu: false,
			isStreamingEnv:
				_store.getState().CommonReducer.activeExecutionEnv ===
				ExecutionEnvModes.Streaming,
			isStreamingPreview: false,
			isPreviewDisabled: false,
		};
		this.contextMenuRef = React.createRef<SVGForeignObjectElement>();
		this.activeTabPreviewStatusRef = undefined;
	}

	getWorkflowReducerState = () => {
		const {
			openTabs,
			activeTab,
		} = _store.getState().CanvasReducer.workflowEditor;
		const activeTabInfo = openTabs.get(activeTab.id)?.info;
		const activeTabPreviewStatus = activeTabInfo?.previewStatusInfo;
		const previewComponentStatus = activeTabPreviewStatus?.components;
		if (activeTabPreviewStatus !== this.activeTabPreviewStatusRef) {
			if (previewComponentStatus !== this.state.previewComponentStatus) {
				if (previewComponentStatus) {
					const _state: any = { previewComponentStatus };
					const sourceNodeId = this.getSourceNodeId();
					const targetNodeId = this.getTargetNodeId();
					if (sourceNodeId) _state.sourceNode = sourceNodeId;
					if (targetNodeId) _state.targetNode = targetNodeId;
					this.setState({ ..._state });
				} else if (!isEmpty(this.state.previewComponentStatus)) {
					// resets preview component state
					this.setState({
						previewComponentStatus: {},
						sourceNode: "",
						targetNode: "",
					});
				}
			}
			if (
				activeTabInfo?.env === ExecutionEnvModes.Streaming &&
				this.state.isStreamingPreview !==
					activeTabPreviewStatus?.isRunning
			) {
				{
					this.setState({
						isStreamingPreview: !!activeTabPreviewStatus?.isRunning,
					});
				}
			}
			this.setPreviewAllowedStatus();
		} else if(!activeTabPreviewStatus && !isEmpty(this.state.previewComponentStatus)) {
			this.setState({
				previewComponentStatus: {},
				sourceNode: "",
				targetNode: "",
			});
		}
	};

	debouncedPreviewSetter = debounce(() => {
		const sourceNode = this.props.model
			?.getSourcePort()
			?.getParent() as DsNodeModel;
		const targetNode = this.props.model
			?.getTargetPort()
			?.getParent() as DsNodeModel;
		if (
			get(sourceNode, "extras.previewAllowed") === false ||
			get(targetNode, "extras.previewAllowed") === false
		) {
			this.setState({ isPreviewDisabled: true });
		}
	}, 500);

	setPreviewAllowedStatus() {
		this.debouncedPreviewSetter();
	}

	componentDidMount() {
		this.unsubscribe = _store.subscribe(() =>
			this.getWorkflowReducerState()
		);
		this.setPreviewAllowedStatus();
	}

	// componentDidUpdate(prevProps: { selected: any }) {
	//     if (prevProps.selected !== this.props.selected) {
	//         // this.props.model?.getSourcePort() &&
	//         // 	this.props.model.getSourcePort().setSelected(this.props.selected);
	//         // this.props.model?.getTargetPort() &&
	//         // 	this.props.model.getTargetPort().setSelected(this.props.selected);
	//         // this.props.model.getSourcePort().setSelected(true);
	//         // this.props.model.getSourcePort().setSelected(true);
	//     }
	// }

	getSourceNodeId = (): string =>
		this.props.model
			.getSourcePort()
			?.getParent()
			.getID();

	getTargetNodeId = (): string =>
		this.props.model
			.getTargetPort()
			?.getParent()
			.getID();

	removeMouseClickListener() {
		window.removeEventListener(
			"mousedown",
			this.detectMouseClickWhenOpen.bind(this, this.contextMenuRef)
		);
	}

	handleDeleteLink = () => {
		this.removeMouseClickListener();
		this.props.model.remove();
		WorkflowCanvas.repaintCanvas();
	};

	detectMouseClickWhenOpen(
		nodeRef: React.RefObject<SVGForeignObjectElement>,
		e: MouseEvent
	) {
		if (!nodeRef.current?.contains(e.target as any)) {
			this.setState({ showContextMenu: false });
			this.removeMouseClickListener();
		}
	}

	showContextMenu = (e: React.MouseEvent<SVGGElement, MouseEvent>) => {
		// e.preventDefault();
		// this.contextMenuRef.current?.showContextMenu(e)
		e.preventDefault();
		e.stopPropagation();
		const points = WorkflowCanvas.engine.getRelativeMousePoint(e);
		if (this.contextMenuRef.current) {
			this.contextMenuRef.current.setAttributeNS(
				null,
				"x",
				points.x + "px"
			);
			this.contextMenuRef.current.setAttributeNS(
				null,
				"y",
				points.y + "px"
			);
			window.addEventListener(
				"mousedown",
				this.detectMouseClickWhenOpen.bind(this, this.contextMenuRef)
			);
			this.setState({ showContextMenu: true });
		}
	};

	componentWillUnmount() {
		if (this.props.model) {
			this.props.model?.getSourcePort() &&
				this.props.model.getSourcePort().setSelected(false);
			this.props.model?.getTargetPort() &&
				this.props.model.getTargetPort().setSelected(false);
		}
		this.unsubscribe();
		WorkflowCanvas.repaintCanvas();
		this.removeMouseClickListener();
	}

	render() {
		const {
			sourceNode,
			targetNode,
			previewComponentStatus,
			// selectedUsingClick,
			showContextMenu,
			isPreviewDisabled,
			isStreamingPreview,
			isStreamingEnv,
		} = this.state;
		const isSourceNodeSuccess =
			!!sourceNode &&
			previewComponentStatus[sourceNode]?.status === PREVIEW_SUCCESS;
		const isTargetNodeSuccess =
			!!targetNode &&
			previewComponentStatus[targetNode]?.status === PREVIEW_SUCCESS;

		const _propsIfTargetPortIsNotInPort = this.props.model.isTargetPortInPort()
			? {}
			: {
					keyPoints: "1;0",
					keyTimes: "0;1",
					calcMode: "linear",
			  };

		const showStreamingAnimation = isStreamingPreview && !isPreviewDisabled;
    /////////////// Port Display in Pop Up /////////////////
	const sourcePort = this.props?.model?.getSourcePort();
    const targetPort = this.props?.model?.getTargetPort();

    const sourceParentOptions = sourcePort?.getParent()?.getOptions() as { title?: string };
    const targetParentOptions = targetPort?.getParent()?.getOptions() as { title?: string };

    const sourceTitle = sourceParentOptions?.title;
    const sourceName = sourcePort?.getOptions()?.name;
	const newSourceName = sourceName && sourceName.startsWith("OUT-")
  ? `OUT-${parseInt(sourceName.split("-")[1], 10) + 1}`
  : sourceName;
    const targetTitle = targetParentOptions?.title;
    const targetName = targetPort?.getOptions()?.name;
	const newTargetName = targetName && targetName.startsWith("IN-")
  ? `IN-${parseInt(targetName.split("-")[1], 10) + 1}`
  : targetName;

    const content = (
		<div>
		  <p style={{ fontSize: '10px', fontWeight: 'bolder', display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
			{sourceTitle}: <strong>{newSourceName}</strong>
		  </p>
		  <p style={{ fontSize: '10px', fontWeight: 'bolder', display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
			{targetTitle}: <strong>{newTargetName}</strong>
		  </p>
		</div>
	  );
	  
	///////////////////////////////////////////////////////////

		return (
			<g
				className={classNames("componentLink__outer", {
					unConnectedLink:
						!this.props.model.getTargetPort() &&
						!this.props.selected,
				})}
				onContextMenu={this.showContextMenu}
			>
				<path
					className={classNames("componentLink", {
						success: isSourceNodeSuccess || isTargetNodeSuccess,
						selected: this.props.selected,
						dottedLink:
							this.props.model.getOptions().color === "dotted",
						streamingLink: isStreamingEnv,
						steamingPreview: showStreamingAnimation,
						previewDisabled:
							isPreviewDisabled && !isEmpty(previewComponentStatus),
							// show disabled state until the preview state is reset
					})}
					fill="none"
					strokeWidth={isStreamingEnv ? "5" : "1.5"}
					d={this.props.path}
				/>
				<Popover content={content} title="" trigger="click">
				<path
					className={classNames("componentLink__dummy", {
						selected: this.props.selected,
					})}
					fill="none"
					ref={(ref) => {
						if (this.path) {
							this.path = ref;
						}
					}}
					onClick={() => {
						this.props.model.setSelected(true);
						this.props.model.getSourcePort().setSelected(true);
						// this.props.model.getSourcePort().setSelected(true);
					}}
					strokeWidth={15}
					d={this.props.path}
				/>
				</Popover>
				<foreignObject
					x="20"
					y="20"
					width="80"
					height="28"
					ref={this.contextMenuRef}
					className={classNames(
						"contextMenuBox",
						"canvasContextMenu",
						{ show: showContextMenu }
					)}
				>
					{/* <ContextMenuComponent
                        ref={this.contextMenuRef}
                        className="canvasContextMenu"
                        // useGrandParentElement
                    > */}
					<button onClick={this.handleDeleteLink}>Delete Link</button>
					{/* </ContextMenuComponent> */}
				</foreignObject>
				<ShowWhenTrue show={showStreamingAnimation}>
					<defs>
						<filter
							id="circleShadow"
							x="0"
							y="0"
							width="200%"
							height="200%"
						>
							<feDropShadow
								dx="-1"
								dy="0"
								stdDeviation="0.5"
								floodColor="rgb(255,255,255"
								floodOpacity="1"
							/>
						</filter>
					</defs>
					<circle r={2.5} color="white">
						<animateMotion
							id="op"
							dur="2s"
							repeatCount="indefinite"
							path={this.props.path}
							{..._propsIfTargetPortIsNotInPort}
						/>
					</circle>
					<circle r={2.5} color="white">
						<animateMotion
							dur="2s"
							repeatCount="indefinite"
							attributeName="path"
							path={this.props.path}
							begin="0.67s"
							{..._propsIfTargetPortIsNotInPort}
						/>
					</circle>
					<circle r={2.5} color="white">
						<animateMotion
							dur="2s"
							repeatCount="indefinite"
							attributeName="path"
							path={this.props.path}
							begin="1.33s"
							{..._propsIfTargetPortIsNotInPort}
						/>
					</circle>
				</ShowWhenTrue>
			</g>
		);
	}
}
