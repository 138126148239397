import React from "react";
import { useTimeAgo } from "rooks";
import styles from "./styles.module.scss";
export const SavedAgo = ({ time }: { time: Date }) => {
	const refreshTimeAgo = useTimeAgo(time);
	return (
		<span className={styles.timeAgo}>
			Saved {refreshTimeAgo}
		</span>
	);
};