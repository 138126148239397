import React, { useState, ChangeEvent } from 'react';
import './styles.scss';
import {  Table, Column } from 'react-virtualized';
import Paginate from '../../components/paginate';
import {  useDebounce } from 'rooks';
import { TagsHandler, ClientSiteInfo, ClientSiteTagsInfo, BasePaginationSortSearchQuery, ClientSiteTagsResponse, SyncSetInfo, CreateSyncSetInfo } from '../../api/tags-handler';
// import { TooltipTop } from '../../components/tooltips';
import { SitesTreeview } from './treeviews/sites-treeview';
// import { ShowWhenTrue } from '../../helpers';
import Form, { SelectField } from '../../components/form';
import { has } from 'lodash';
import { _selectoptionType } from '../../components/form/select-field';
import { addNewSyncSetInfo } from '../../store/tag-browser';
import { useDispatch } from 'react-redux';
import { successAlert } from '../../components/toastify/notify-toast';
import classNames from 'classnames';

export type retrieveSiteTagsQuery = { id: number; searchText?: string;  isSyncSet?: boolean } & BasePaginationSortSearchQuery

const options = [20, 25, 30].map(_num => ({ label: _num.toString(), value: _num }));

export const TagSynchronizer: React.FC = () => {
    const [clientTagsInfo, setClientTagsInfo] = useState<{ results: ClientSiteTagsInfo[]; totalCount: number }>({ results: [], totalCount: 0 });
    const [selectedSiteInfo, setSelectedSiteInfo] = useState<ClientSiteInfo>({ 
        createdAt: new Date(), 
        updatedAt: new Date(),
        id: 0,
        siteName: '',
        siteDescription: '',
        uri: '',
        username: '' ,
        siteServers: []
    });
    const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState(25);
    const [searchString, setSearchString] = useState('');
    const [syncSetCreationMode, toggleSyncSetCreationMode] = useState(false);
    const [selectedTagIds, handleAddSelectedTagIds] = useState<Record<number, boolean>>({});
    const [syncSetInfo, setSyncSetInfo] = useState<Partial<SyncSetInfo>>({ clientId: 0, name: '',  tagIds: [], serverId: 0 });
    const [showSyncSetTags, toggleShowSyncSetTags] = useState(false);
    const dispatch = useDispatch();

    const handleTagsResponse = (response: ClientSiteTagsResponse) => {
        setClientTagsInfo({ results: response.content, totalCount: response.totalElements });
    };

    const retrieveTags = ({ id, pageNo = 1, size = numberOfItemsPerPage, searchText = searchString, isSyncSet = showSyncSetTags }: retrieveSiteTagsQuery ) => {
        if(isSyncSet) {
            TagsHandler.GetTagsOfSyncSet(id, { pageNo, size, searchText }, handleTagsResponse);
        } else {
            showSyncSetTags && toggleShowSyncSetTags(false);
            TagsHandler.GetServerTags(id, { pageNo, size, searchText }, handleTagsResponse);
        }
            
    };

    const getId = () => {
        return showSyncSetTags && syncSetInfo.syncId ? syncSetInfo.syncId: (syncSetInfo.serverId || 0);
    };


    const handlePageChange = (pageNo: number) => {
        const id = getId();
        retrieveTags({ pageNo, id });
    };

    const handlePageOptionClick = (option: _selectoptionType) => {
        setNumberOfItemsPerPage(option.value);
        const id = getId();
        retrieveTags({ size: option.value, id });
    };

    const _retrieveTags = useDebounce((searchText: string) =>  {
        const id = getId();
        retrieveTags({ searchText, id });
    }, 400);

    const handleSearchTags = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchString(e.target.value);
        _retrieveTags(e.target.value);
    };

    const handleToggleTagSelection = (id: number) => {
        syncSetCreationMode && handleAddSelectedTagIds({ ...selectedTagIds, [id]: !(selectedTagIds[id]) });
    };

    const handleShowSyncSetCreationMode = (siteInfo: ClientSiteInfo, syncSetInfo: CreateSyncSetInfo) => {
        showSyncSetTags && toggleShowSyncSetTags(false);
        toggleSyncSetCreationMode(true);
        setSelectedSiteInfo(siteInfo);
        retrieveTags({ id: syncSetInfo.serverId, isSyncSet: false });
        setSyncSetInfo(syncSetInfo);
    };
    
    const resetSyncSetState = () => {
        toggleSyncSetCreationMode(false);
        handleAddSelectedTagIds({});
        setClientTagsInfo({ results: [], totalCount: 0});
        // setTimeout(() =>{
        setSyncSetInfo({ clientId: 0, name: '' });
        setSelectedSiteInfo({
            createdAt: new Date(), 
            updatedAt: new Date(),
            id: 0,
            siteName: '',
            siteDescription: '',
            uri: '',
            username: '' ,
            siteServers: []
        });
        // }, 500);
    };

    const handleCreateSyncSetSuccess = () => {
        syncSetInfo.clientId && dispatch(addNewSyncSetInfo({ id: syncSetInfo.clientId, key: 'sites'+syncSetInfo.clientId } ));
        syncSetInfo.name && successAlert(syncSetInfo.name + ' created successfully');
        resetSyncSetState();
    };

    const handleCancelSyncSetCreation = () => {
        resetSyncSetState();
    };

    const handleSubmitSyncSetCreation = () => {
        let selectedTagsList: any[] = Object.keys(selectedTagIds).filter(_tagId => selectedTagIds[parseInt(_tagId)]);
        selectedTagsList = selectedTagsList.map(id => parseInt(id));
        if(syncSetInfo.name && syncSetInfo.clientId && syncSetInfo.serverId) {
            const data: CreateSyncSetInfo = { name: syncSetInfo.name, tagIds: selectedTagsList, clientId: syncSetInfo.clientId, 
                serverId:  syncSetInfo.serverId };
            TagsHandler.CreateSyncSet(data, handleCreateSyncSetSuccess);
        }
    };

    const handleRetrieveSyncSetTags = (syncSetInfo: Partial<SyncSetInfo>) => {
        setSyncSetInfo(syncSetInfo);
        syncSetInfo.syncId && retrieveTags({ id: syncSetInfo.syncId, isSyncSet: true });
        toggleShowSyncSetTags(true);
    };

    return(
        <section className="tagSyncBrowser__container">
            <div className="tagSyncBrowser__LHS">
                <p className="LHS__title">PI Tag Synchronizer</p>
                <SitesTreeview 
                    selectedSiteName={selectedSiteInfo.siteName}
                    selectedSyncSetName={syncSetInfo.name || ''}
                    handleShowSyncSetCreationMode={handleShowSyncSetCreationMode}
                    handleRetrieveSyncSetTags={handleRetrieveSyncSetTags}
                />
                
            </div>
            <div className="tagSyncBrowser__RHS ">
                {syncSetCreationMode && 
                    <div className="syncSetCreation__options">
                        <h2 style={{ color: 'white' }}>Select the tags to be added in {syncSetInfo.name}</h2>
                        <div className="btns__box">
                            <button
                                className="btn-sm"
                                onClick={handleCancelSyncSetCreation} 
                            >
                                Cancel
                            </button>
                            <button
                                className="btn-yellow btn-sm"
                                onClick={handleSubmitSyncSetCreation}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                }
                {syncSetInfo.name || selectedSiteInfo.siteName ? 
                    <>  
                        <div className="dataBrowser__RHS__header">
                            <p className={classNames('dataBrowser__table__title', {'syncset__title': showSyncSetTags})}>
                                {!!(syncSetInfo.name) && showSyncSetTags ?
                                    `${syncSetInfo.name}`
                                    :
                                    `${selectedSiteInfo.siteName} - ${selectedSiteInfo.siteDescription}`
                                }
                            </p>
                            <input
                                onChange={handleSearchTags}
                                className="search_input"
                                placeholder="Try 'Tag1'"
                                value={searchString}
                            />
                        </div>
                        <Table
                            width={showSyncSetTags ? 650: 700}
                            height={window.innerHeight - 180}
                            headerHeight={32}
                            rowHeight={30}
                            rowCount={clientTagsInfo.results.length}
                            rowGetter={({index}) => clientTagsInfo.results[index]}
                            className="dataBrowser__table"
                            headerClassName="dataBrowser__table__header"
                            rowClassName="dataBrowser__table__row"
                            gridClassName="dataBrowser__table__grid"
                        >
                            <Column label="ID" dataKey="tagId" width={100} />
                            <Column width={150} label="Tag Name" dataKey="tagName" />
                            <Column width={400} label="Description" dataKey="tagDescription" />
                            {!showSyncSetTags && 
                                <Column 
                                    width={50} 
                                    label="Sync" 
                                    dataKey="sync"
                                    className="checkBoxTableField"
                                    cellRenderer={(props) => {
                                        const tagId = props.rowData.tagId;
                                        return(
                                            <label className="checkbox__container" >
                                                <input type="checkbox" id={`workflow_inputchecbox_${has(selectedTagIds, tagId) ? selectedTagIds[tagId] : !!(props.cellData)}` }  checked={has(selectedTagIds, tagId) ? selectedTagIds[tagId] : !!(props.cellData)} onChange={() => {return;}} value="" />
                                                <span className="checkmark" id= {`workflow_inputchecmark_${has(selectedTagIds, tagId) ? selectedTagIds[tagId] : !!(props.cellData)}` }  
                                                    onClick={() => handleToggleTagSelection(tagId)} />
                                            </label> 
                                        );
                                    }}
                                />
                            }
                        </Table>
                        <div className="paginationContainer">
                            <Paginate 
                                startPage={1}
                                totalCount={clientTagsInfo.totalCount}
                                itemsPerPage={numberOfItemsPerPage}
                                handlePageChange={handlePageChange}
                            >
                                <Form
                                    initialValues={{ numberOfItemsPerPage }}
                                    onSubmit={() => {return;}}
                                >
                                    <SelectField 
                                        name="numberOfItemsPerPage"
                                        className="numberOfItemsDropdown"
                                        label="Per Page"
                                        options={options}
                                        onOptionClick={handlePageOptionClick}
                                    />

                                </Form>
                            </Paginate>
                        </div>
                    </>
                    :
                    <p className="noTagsMsg">No tags to show</p>
                }
                
                {/* <Paginate 
                    startPage={1}
                    totalCount={40}
                    itemsPerPage={10}
                    handlePageChange={() => {}}
                /> */}
            </div>
        </section>
    );
};