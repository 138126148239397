// import ReactEcharts from 'echarts-for-react';
import 'echarts/lib/component/legend';
import { PlotData } from '.';
import { isArray, isEmpty, round } from 'lodash';

// const tooltipOps = {
//     trigger: 'axis',
//     axisPointer: {
//         type: 'shadow',
//         label: {
//             backgroundColor: '#6a7985',
//         },
//     },
// };
type CoordinatesForGeoSpatialPlot = [number, number]

const gridOps = {
    // left: 'auto'
    right: '3%',
    bottom: 70,
    containLabel: true,
    show: false
};


const toolboxOps = {
    show: true,
    backgroundColor: '#13182a',
    orient: 'horizontal',
    itemSize: 20,
    itemGap: 10,
    padding: 10,
    showTitle: false,
    feature: {
        dataZoom: {
            show: true,
            title: {
                zoom: 'Zoom In',
                back: 'Zoom Out',
            },
            icon: {
                zoom: 'image:///icons/graphicons/zoomin.png',
                back: 'image:///icons/graphicons/zoomout.png',
            },
        },
        restore: {
            show: true,
            title: 'Reset',
            icon: 'image:///icons/graphicons/restore.png',
        },
        saveAsImage: {
            show: true,
            title: 'Download',
            name: 'Deepiq_chart',
            backgroundColor: '#2a2c42',
            icon: 'image:///icons/graphicons/download.png',
            iconStyle: {
                borderColor: '#fff',
                color: '#fff',
                borderWidth: 1,
                borderType: 'solid',
                textBackgroundColor: '#fff',
            },
        },
    },
    tooltip: {
        show: true,
        formatter: function (param: any) {
            return '<div>' + param.title + '</div>';
        },
        backgroundColor: '#222',
        textStyle: {
            fontSize: 12,
        },
        extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);', // user-defined CSS styles
    },
    z: 3,
    left: 'auto',
    right: '2.8%'
};


const dataZoomOps = [
    {
        type: 'inside',
        start: 0,
        end: 100,
    },
    {
        start: 0,
        end: 10,
        borderColor: 'transparent',
        dataBackground: {
            areaStyle: {
                color: '#00C896',
            },
        },
        backgroundColor: '#2a2c42',
        handleSize: '100%',
        handleStyle: {
            color: '#fcad01',
        },
    },
    {
        type: 'inside',
        orient: 'vertical',
        start: 0,
        end: 100,
    },
    {
        orient: 'vertical',
        start: 0,
        end: 100,
        borderColor: 'transparent',
        dataBackground: {
            areaStyle: {
                color: '#00C896',
            },
        },
        backgroundColor: '#2a2c42',
        handleSize: '100%',
        handleStyle: {
            color: '#fcad01',
        },
        left: 10
    },
];

const tooltipOps = (xAxislabel: string, type: 'cross' | 'shadow' | 'line' = 'line') => ({
    trigger: 'axis',
    axisPointer: {
        type,
        label: {
            backgroundColor: '#6a7985',
        },
    },
    formatter: function(params: any) {
        let tooltip = `${xAxislabel}: ${params[0].axisValueLabel}<br /> `;

        params.forEach(({ marker, seriesName, value }: any) => {
            value = value || [0, 0];
            tooltip += `${marker} ${seriesName}: ${value}<br />`;
        });
        return tooltip;
    }
});


const titleOps = {
    text: '',
};

const _xAxisConfig = {
    nameLocation: 'middle',
    nameGap: 40,
    nameTextStyle: {
        fontSize: 15,
        color: '#fff',
        fontFamily: 'DM Sans',
    },
    axisLine: {
        lineStyle: {
            color: '#E0E7FF',
        },
    },
    splitLine: {
        show: true,
        lineStyle: {
            color: ['#8097B1'],
            type: 'dashed',
            opacity: 0.2,
        },
    },
    axisTick: {
        show: false,
    },
    axisLabel: {
        color: '#D8D8D8',
        fontFamily: 'DM Sans',
        fontSize: 11,
        margin: 10,
        lineHeight: 16,
        // formatter: function (value: string) {
        //     // this is to add a space after comma
        //     return value.split(",").join(", ")
        // }
    },
};

const _yAxisConfig = {
    ..._xAxisConfig,
    // nameGap: 'auto',
    nameTextStyle: {
        fontSize: 15,
        color: '#fff',
        fontFamily: 'DM Sans',
    },
    splitLine: {
        show: true,
        lineStyle: {
            color: ['#8097B1'],
            type: 'dashed',
            opacity: 0.4,
        },
    },
};

const renderPolygon = (params: any, api: any, coords: any) => {
    const points = coords.map((coord: any) => {
        return api.coord(coord)
    });

    // const color = api.visual('color');

    const eChartsPolygon = {
        type: 'polygon',
        shape: {
            points,
        },
        style: api.style({
            fill: 'red',
            stroke: 'red',
        }),
        z: 3,
    };
    return eChartsPolygon;
};

const mapConfig = {
    backgroundColor: '#2a2c42',
    title: {
        text: '',
        subtext: '',
        sublink: '',
        left: 'center',
        textStyle: {
            color: '#fff',
        },
    },

    toolbox: {
        show: true,
        backgroundColor: '#13182a',
        orient: 'horizontal',
        itemSize: 20,
        itemGap: 10,
        padding: 10,
        showTitle: false,
        feature: {
            dataZoom: {
                show: false,
                title: {
                    zoom: 'Zoom In',
                    back: 'Zoom Out',
                },
                icon: {
                    zoom: 'image:///icons/graphicons/zoomin.png',
                    back: 'image:///icons/graphicons/zoomout.png',
                },
            },
            restore: {
                show: true,
                title: 'Reset',
                icon: 'image:///icons/graphicons/restore.png',
            },
            saveAsImage: {
                show: true,
                title: 'Download',
                name: 'Deepiq_chart',
                backgroundColor: '#2a2c42',
                icon: 'image:///icons/graphicons/download.png',
                iconStyle: {
                    borderColor: '#fff',
                    color: '#fff',
                    borderWidth: 1,
                    borderType: 'solid',
                    textBackgroundColor: '#fff',
                },
            },
        },
        tooltip: {
            show: true,
            formatter: function (param: any) {
                return '<div>' + param.title + '</div>';
            },
            backgroundColor: '#222',
            textStyle: {
                fontSize: 12,
            },
            extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);', // user-defined CSS styles
        },
        z: 3,
        right: 37,
    },
    legend: {
        orient: 'vertical',
        top: 'bottom',
        left: 'right',
        textStyle: {
            color: '#fff',
        },
    },
    renderer: 'svg',
    aspectScale: 0.75,
    boundingCoords: [
        // [lng, lat] of left-top corner
        [-180, 90],
        // [lng, lat] of right-bottom corner
        [180, -90],
    ],
    scaleLimit: {
        min: 1,
        max: 200,
    },
    geo: {
        map: 'world',
        roam: true,
        zoom: 1.25,
        label: {
            emphasis: {
                show: false,
            },
        },
        itemStyle: {
            normal: {
                areaColor: '#323c48',
                borderColor: '#111',
            },
            emphasis: {
                areaColor: '#2a333d',
            },
        },
        silent: true,
        // center: [-99.7578, 37.4536]
    },
};


const calculateMapZoom = (boundingBox: NonNullable<PlotData['boundingBox']>) => {
    // Actual BoundingCoords = [
    //      [-180, 90],
    //      [180, -90]
    // ] 
    // zoom scale for these coordinates is 1
    // Diagonal length = 402.49223594996215

    // boundingCoords = [
    //     [-69.527099609375,-22.940200805664062],
    //     [-68.66840362548828, -24.503700256347656],
    // ];
    // for these coordinates, zoom scale is 60
    // Diagonal length = 1.783785111786473 

    // for Diagonal length = 402.49223594996215: scale = 1
    // for Diagonal length = 1.783785111786473: scale = 60

    // actualLength - diagonal length of actual box
    const actualLength = Math.sqrt(180*180 + 360*360); // 402.49223594996215

    // newLength - diagonal length of coordinates boundingbox
    const newCoordsLength = Math.sqrt(
        (Math.pow((boundingBox.minx) - (boundingBox.maxx), 2))
            +
        (Math.pow((boundingBox.miny) - (boundingBox.maxy), 2))
    );
    if(Math.abs(actualLength - newCoordsLength) < 5) {
        return 1;
    } else {
        // created using the equation of line passing through two points - (x1=402.49223594996215, y1=1), (x2=1.783785111786473, y2=60)
        return -0.14723922062683645*newCoordsLength+60.2626431296252;
    }
    // console.log(actualLength, newCoordsLength, boundingBox)
};

const generateMapDataUsingLatLong = (plotData: PlotData, yAxislabel: string[]) => {
    const latitudeLabel = plotData.latitude;
    const longitudeLabel = plotData.longitude;
    let latitudeData: number[] = [];
    let longitudeData: number[] = [];
    let valueData: number[] = [];
    let isAdditionalDataProvided = false;

    let minValue = 0;
    let maxValue = 0;
    const coordinatesData: any = [];

    if (latitudeLabel && longitudeLabel) {
        latitudeData = plotData.plotData[latitudeLabel];
        longitudeData = plotData.plotData[longitudeLabel];
    }

    if (!isEmpty(yAxislabel)) {
        isAdditionalDataProvided = true;
        // means additional data is given in Y Axis box
        valueData = plotData.plotData[yAxislabel[0]];
        minValue = valueData[0];
        maxValue = valueData[0];
    }

    // const xAxisData = plotData.plotData[xAxislabel];
    // const yAxisData: any = plotData.plotData[yAxislabel[0]];

    latitudeData.forEach((lat: any, index: number) => {
        // const lat = element.toString();
        const lon = longitudeData[index];
        if (isAdditionalDataProvided) {
            if (minValue < valueData[index]) {
                minValue = valueData[index];
            }
            if (maxValue > valueData[index]) {
                maxValue = valueData[index];
            }
        }

        const coOrbj = {
            name: lon + ':' + lat,
            value: [lon, lat, isAdditionalDataProvided ? valueData[index] : ''],
            itemStyle: {
                color: '#52cfba',
            },
        };
        coordinatesData.push(coOrbj);
    });

    const __mapConfig = { ...mapConfig };
    if (isAdditionalDataProvided) {
        // @ts-ignore
        __mapConfig.visualMap = {
            type: 'piecewise',
            min: maxValue,
            max: minValue,
            splitNumber: 5,
            color: ['#d94e5d', '#eac736', '#50a3ba'],
            textStyle: {
                color: '#fff',
                fontSize: 14,
            },
            text: [yAxislabel[0]],
            showLabel: true,
            // realtime: false,
            // calculable: true,
            show: true
        };
    }
    // @ts-ignore
    __mapConfig.tooltip = {
        trigger: 'item',
        formatter: function (params: any) {
            return (
                `Coordinates: ${params.value[0]}, ${params.value[1]} ${isAdditionalDataProvided ? `<br/> Value : ${params.value[2]}` : ''}`
            );
        }
    };

    return [coordinatesData, __mapConfig];
};

export function getGraphConfig(
    plotData: PlotData,
    // echartsRef: React.RefObject<ReactEcharts>
) {
    const index = 0;
    const xAxislabel: any = plotData.x;
    const yAxislabel: any = plotData.y;

    if (plotData.plotData && plotData.plotType === 'bar') {
        const scattercolor = ['#2f97fb', '#f1527f', '#4658d4', '#52cfba'];
        const xAxisData = plotData.plotData[xAxislabel];
        const yAxisData: any = [];
        plotData.y.forEach((element: any, index: number) => {
            const seriesData = {
                data: plotData.plotData[element],
                type: 'bar',
                showBackground: false,
                backgroundStyle: {
                    color: 'rgba(220, 220, 220, 0.8)',
                },
                itemStyle: {
                    color: scattercolor[index],
                },
                axisLabel: {
                    show: true,
                    formatter: xAxislabel,
                    color: 'white',
                },
                name: element
            };
            yAxisData.push(seriesData);
        });
        return {
            title: {
                text: '',
            },
            color: scattercolor,
            tooltip: tooltipOps(xAxislabel, 'shadow'),
            legend: {
                data: yAxislabel,
                backgroundColor: '#13182a',
                // itemSize: 20,
                // itemGap: 10,
                // padding: 10,
                // right: 40,
                textStyle: {
                    color: '#fff',
                },
                left: '5%'
            },
            grid: gridOps,
            toolbox: toolboxOps,
            dataZoom: dataZoomOps,
            xAxis: {
                type: 'category',
                name: xAxislabel,
                data: xAxisData,
                ..._xAxisConfig,
            },
            yAxis: {
                type: 'value',
                // name: yAxislabel,
                data: yAxisData,
                ..._yAxisConfig,
            },
            series: yAxisData,
        };
    } else if (plotData.plotData && plotData.plotType === 'area') {
        const scattercolor = ['#f1527f', '#52cfba', '#2f97fb', '#f5775c'];
        const xAxisData = plotData.plotData[xAxislabel];
        const yAxisData: any = [];
        plotData.y.forEach((element: any, index: number) => {
            const seriesData = {
                name: element,
                data: plotData.plotData[element],
                type: 'line',
                coordinateSystem: 'cartesian2d',
                areaStyle: {},
                stack: '',
                itemStyle: {
                    color: scattercolor[index],
                },
                axisLabel: {
                    color: 'white',
                },
            };
            yAxisData.push(seriesData);
        });
        return {
            title: {
                text: '',
            },
            tooltip: tooltipOps(xAxislabel, 'cross'),
            legend: {
                data: yAxislabel,
                backgroundColor: '#13182a',
                itemSize: 20,
                itemGap: 10,
                padding: 10,
                // backgroundColor: 'rgba(30, 33, 56, 0.34)',
                left: '5%',
                textStyle: {
                    color: '#fff',
                },
            },
            toolbox: toolboxOps,
            dataZoom: dataZoomOps,
            grid: gridOps,
            geo: {},
            xAxis: [
                {
                    type: 'category',
                    name: xAxislabel,
                    data: xAxisData,
                    ..._xAxisConfig,
                },
            ],
            yAxis: [
                {
                    type: 'value',
                    // name: yAxislabel,
                    data: yAxisData,
                    ..._yAxisConfig,
                },
            ],
            series: yAxisData,
        };
    } else if (plotData.plotData && plotData.plotType === 'scatter') {
        const scattercolor = [
            '#4658d4',
            '#52cfba',
            '#f1527f',
            '#2f97fb',
            '#f5775c',
        ];
        const xAxisData = plotData.plotData[xAxislabel];
        const yAxisData: any = [];
        plotData.y.forEach((element: any, index: number) => {
            const seriesData = {
                name: element,
                data: plotData.plotData[element],
                type: plotData.plotType,
                coordinateSystem: 'cartesian2d',
                itemStyle: {
                    color: scattercolor[index],
                    shadowBlur: 0,
                    shadowColor: 'transperent',
                    shadowOffsetY: 0,
                },
                axisLabel: {
                    color: 'white',
                },
            };
            yAxisData.push(seriesData);
        });

        return {
            responsive: true,
            title: titleOps,
            tooltip: tooltipOps(xAxislabel, 'shadow'),
            legend: {
                data: yAxislabel,
                backgroundColor: '#13182a',
                padding: 10,
                itemSize: 20,
                itemGap: 10,
                // backgroundColor: 'rgba(30, 33, 56, 0.34)',
                left: '5%',
                textStyle: {
                    color: '#fff',
                },
            },
            grid: gridOps,
            toolbox: toolboxOps,
            dataZoom: dataZoomOps,
            geo: {},
            xAxis: {
                type: 'category',
                name: xAxislabel,
                data: xAxisData,
                ..._xAxisConfig,
            },
            yAxis: [
                {
                    type: 'value',
                    // name: yAxislabel,
                    data: xAxisData,
                    ..._yAxisConfig,
                },
            ],
            series: yAxisData,
        };
    } else if (plotData.plotData && plotData.plotType === 'map') {
        const [coordinatesData, __mapConfig] = generateMapDataUsingLatLong(plotData, yAxislabel);
        if (plotData.boundingBox ?.centroidx) {
            // @ts-ignore
            __mapConfig.geo.center = [plotData.boundingBox.centroidx, plotData.boundingBox.centroidy];
            __mapConfig.geo.zoom = calculateMapZoom(plotData.boundingBox);
            // __mapConfig.geo.boundingCoords = [
            //     // [lng, lat] of left-top corner
            //     [plotData.boundingBox.minx, plotData.boundingBox.maxy],
            //     // [lng, lat] of right-bottom corner
            //     [plotData.boundingBox.maxx, plotData.boundingBox.miny],
            // ];
        }

        return {
            ...__mapConfig,
            series: [
                {
                    type: 'scatter',
                    coordinateSystem: 'geo',
                    data: coordinatesData,
                    symbolSize: 6,
                    activeOpacity: 1,
                    itemStyle: {
                        color: '#52cfba',
                    },
                    emphasis: {
                        label: {
                            show: false,
                        },
                    },
                },
            ],
        };
    } else if (plotData.plotData && plotData.plotType === 'geospatial') {
        const series: Record<string, any>[] = [];
        const points: any[] = [];
        const lines: {"coords": [number, number][] }[] = [];
        // xAxislabel, yAxislabel
        if (plotData.geometries && !isEmpty(plotData.geometries)) {
            // plotData.geometries = BasinsData.features
            plotData.geometries.forEach(columnName => {
                let columnData = plotData.plotData[columnName] as string[];
                columnData = Array.from(new Set(columnData));
                if (!isEmpty(columnData)) {
                    columnData.forEach(row => {
                        const rowData = JSON.parse(row);
                        if (rowData.type === 'Polygon' || rowData.type === 'MultiPolygon') {
                            if(isArray(rowData.coordinates)) {
                                rowData.coordinates.forEach((coordinates: CoordinatesForGeoSpatialPlot[] | [CoordinatesForGeoSpatialPlot[]]) => {
                                    series.push(
                                        {
                                            type: 'custom',
                                            coordinateSystem: 'geo',
                                            renderItem: (a: any, b: any) => renderPolygon(a, b, rowData.type === 'MultiPolygon'? coordinates[0]:  coordinates),
                                            itemStyle: {
                                                color: 'green',
                                                opacity: 0.3,
                                            },
                                            animation: false,
                                            silent: true,
                                            data: [0],
                                            z: 10
                                        }
                                    );
                                })
                            }
                        } 
                        else if (rowData.type === 'Point') {
                            const [long, lat] = rowData.coordinates;
                            points.push({
                                name: lat + ':' + long,
                                value: [long, lat, 0],
                                itemStyle: {
                                    color: '#f1527f',
                                }
                            });
                        } else if (rowData.type === 'LineString') {
                            lines.push({
                                coords: rowData.coordinates
                            })
                        }
                    });
                }
            });
        }
        if (!isEmpty(points)) {
            series.push(
                {
                    type: 'scatter',
                    coordinateSystem: 'geo',
                    data: points,
                    symbolSize: 6,
                    activeOpacity: 1,
                    itemStyle: {
                        color: '#52cfba',
                    },
                    emphasis: {
                        label: {
                            show: false,
                        },
                    },
                    zLevel: 1
                },
            );
        }
        if (!isEmpty(lines)) {
            series.push({
                type: 'lines',
                coordinateSystem: 'geo',
                data: lines,
                polyline: true,
                lineStyle: {
                    color: 'red',
                    width: 1.5
                },
                progressiveThreshold: 500,
                progressive: 200
            })
        }

        let __mapConfig = { ...mapConfig };
        if (plotData.latitude && plotData.latitude) {
            const [coordinatesData, __toolTipInfoMap] = generateMapDataUsingLatLong(plotData, yAxislabel);

            series.push(
                {
                    type: 'scatter',
                    coordinateSystem: 'geo',
                    data: coordinatesData,
                    symbolSize: 6,
                    activeOpacity: 1,
                    itemStyle: {
                        color: '#52cfba',
                    },
                    emphasis: {
                        label: {
                            show: false,
                        },
                    },
                    z: 15,
                });
            __mapConfig = { ...mapConfig, ...__toolTipInfoMap };
        }

        if (plotData.boundingBox ?.centroidx) {
            // @ts-ignore
            __mapConfig.center = [plotData.boundingBox.centroidx, plotData.boundingBox.centroidy];
        }

        return {
            ...__mapConfig,
            series,
            scaleLimit: {
                min: 0.1,
                max: 100,
            }
        };
    } else if (plotData.plotData && plotData.plotType === 'line') {
        const scattercolor = ['#f1527f', '#52cfba', '#2f97fb', '#f5775c'];
        const xAxisData = plotData.plotData[xAxislabel];
        const yAxisData: any = [];
        plotData.y.forEach((element: any, index: number) => {
            const seriesData = {
                name: element,
                data: plotData.plotData[element],
                type: 'line',
                lineStyle: { color: scattercolor[index] },
                stack: element,
                axisLabel: {
                    color: 'white',
                },
            };
            yAxisData.push(seriesData);
        });
        return {
            title: {
                text: '',
            },
            tooltip: tooltipOps(xAxislabel, 'shadow'),
            legend: {
                data: yAxislabel,
                backgroundColor: '#13182a',
                itemSize: 20,
                itemGap: 10,
                padding: 10,
                // backgroundColor: 'rgba(30, 33, 56, 0.34)',
                // right: 40,
                textStyle: {
                    color: '#fff',
                },
                left: '5%'
            },
            grid: gridOps,
            toolbox: toolboxOps,
            dataZoom: dataZoomOps,
            xAxis: {
                type: 'category',
                name: xAxislabel,
                data: xAxisData,
                ..._xAxisConfig,
            },
            yAxis: {
                type: 'value',
                ..._yAxisConfig,
            },
            series: yAxisData,
        };
    } else if (plotData.plotData && plotData.plotType === 'log') {
        const scattercolor = ['#f1527f', '#52cfba', '#2f97fb', '#f5775c'];
        const xAxisData = plotData.plotData[xAxislabel];
        const yAxisData: any = [];
        plotData.y.forEach((element: any, index: number) => {
            const seriesData = {
                name: element,
                data: plotData.plotData[element],
                type: 'line',
                coordinateSystem: 'cartesian2d',
                itemStyle: { color: '#2a2c42' },
                lineStyle: { color: scattercolor[index] },
                stack: element,
                axisLabel: {
                    color: 'white',
                },
            };
            yAxisData.push(seriesData);
        });
        return {
            title: {
                text: '',
            },
            tooltip: tooltipOps(xAxislabel, 'cross'),
            legend: {
                data: yAxislabel,
                backgroundColor: '#13182a',
                itemSize: 20,
                itemGap: 10,
                padding: 10,
                // backgroundColor: 'rgba(30, 33, 56, 0.34)',
                right: 40,
                textStyle: {
                    color: '#fff',
                },
            },
            grid: gridOps,
            toolbox: toolboxOps,
            dataZoom: dataZoomOps,
            xAxis: {
                type: 'log',
                name: xAxislabel,
                data: xAxisData,
                ..._xAxisConfig,
            },
            yAxis: {
                type: 'log',
                ..._yAxisConfig,
            },
            series: yAxisData,
        };
    } 
    else if (plotData.plotData && (plotData.plotType === 'heatmap')) {
        const scattercolor = ['#f1527f', '#52cfba', '#2f97fb', '#f5775c'];
        // const xAxisData = plotData.plotData[xAxislabel];

        // function convert(num){
        //     return Math.round( num * 100 + Number.EPSILON ) / 100
        // }

        let seriesData: any = [];

    

        let columnNames: string[] = [];
        let visualMapMin = -1;
        let visualMapMax = 1;
       
        // if(xAxislabel === null) {
            // means correlation heatmap
            // plotData.plotData = [[x, y, value]]
            
            // @ts-ignore
            seriesData = plotData.plotData;
            columnNames = yAxislabel;
            if(plotData.boundingBox?.minx !== undefined) {
                visualMapMin = plotData.boundingBox.minx;
            }
            if(plotData.boundingBox?.maxx !== undefined) {
                visualMapMax = plotData.boundingBox.maxx;
            }
        // }
        


        // Object.entries(plotData.plotData).forEach(([columnName, columnValues], rowIndex) => {
        //     columnNames.push(columnName);
        //     columnValues.forEach((data, columnIndex) => {
        //         seriesData.push([rowIndex, columnIndex, 
        //             Math.round(data * 100 + Number.EPSILON) / 100 ]);
        //     });
        // });

        // @ts-ignore
        // const columnNames = Object.keys(plotData.plotData[0])


        // plotData.y.forEach((element: any, index: number) => {
        const seriesInfo = {
            // name: element,
            data: seriesData,
            type: 'heatmap',
            itemStyle: { color: '#2a2c42' },
            lineStyle: { color: scattercolor[index] },
            axisLabel: {
                color: 'white',
            },
            label: {
                show: true,
                formatter: function(t: any) {
                    return round(t.value[2], 2);
                }
            }
        };
            // yAxisData.push(seriesData);
        // });
        return {
            tooltip: {
                axisPointer: {
                    label: {
                        backgroundColor: '#6a7985',
                    },
                },
                formatter: function(params: any) {
                    const tooltip = `x: ${columnNames[params.data[0]]}<br />
                    y: ${columnNames[params.data[1]]}<br />
                    value: ${params.data[2]}`;
            
                    // params.forEach(({ marker, seriesName, value }: any) => {
                    //     value = value || [0, 0];
                    //     tooltip += `${marker} ${seriesName}: ${value}<br />`;
                    // });
                    return tooltip;
                }
            },
            
            grid: {
                ...gridOps,
                left: 'auto',
            },
            toolbox: toolboxOps,
            // dataZoom: dataZoomOps,
            xAxis: {
                type: 'category',
                data: [xAxislabel],
                ..._xAxisConfig,
                axisLine: {
                    show: false
                },
                axisLabel: {
                    ..._xAxisConfig.axisLabel,
                    rotate: 90,
                    interval: 0,
                    formatter: function(l: string) {
                        return l.slice(0, 35);
                    }
                }
            },
            yAxis: {
                type: 'category',
                data: yAxislabel,
                ..._yAxisConfig,
                axisLine: {
                    show: false
                },
                axisLabel: {
                    ..._yAxisConfig.axisLabel,
                    interval: 0
                }
            },
            series: [seriesInfo],
            visualMap: {
                min: visualMapMin,
                max: visualMapMax,
                calculable: true,
                orient: 'horizontal',
                left: 'center',
                textStyle: _xAxisConfig.nameTextStyle,
                show: true,
                // bottom: '15%'
                color: ['#2b4b6d', '#286283', '#3292BD', '#44AFCD', '#94dde9'],
                formatter: function(t: number) {
                    return round(t, 4);
                }
            },
        };
    } else if (plotData.plotData && plotData.plotType === 'boxplot') {
        // plotData = [lower, Q1, median, Q3, Upper][]
        const colors = ['#F7517F','#16CEB9'];
        const series =  
            [{
                name: 'boxplot',
                type: 'boxplot',
                // @ts-ignore
                data: Array.isArray(plotData?.plotData) ? plotData.plotData.map((d,i) => ({
                    value: d,
                    itemStyle: {
                        color: 'transparent',
                        borderColor: colors[i%2]
                    }
                })) : [],
                tooltip: {
                    formatter: function (param: any) {
                        return [
                            'upper: ' + param.value[5],
                            'Q3: ' + param.value[4],
                            'median: ' + param.value[3],
                            'Q1: ' + param.value[2],
                            'lower: ' + param.value[1]
                        ].join('<br/>');
                    }
                },
                
            }
            ];

        return {
            xAxis: {
                type: 'category',
                data: yAxislabel,
                ..._xAxisConfig,
            },
            yAxis: {
                type: 'value',
                ..._yAxisConfig,
            },
            series,
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: gridOps,
            toolbox: toolboxOps,
        };
    }



}
