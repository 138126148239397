import React, { useEffect, useState } from "react";
import { TableCellRenderer } from "react-virtualized";
import { DataSourceHandler } from "../../api/data-source-handler";
import { ActiveSortColumn, SetActiveSortColumn, Table } from "../../components/table";
import styles from "./styles.module.scss";
import clsx from "classnames";
import { convertEnumsToOptions, saveTextToFile } from "../../utils";
import { InPageSpinner } from "../../components/spinners/in-page-spinner";
import { useInterval } from "rooks";
import { errorAlert, successAlert } from "../../components/toastify/notify-toast";
import { SelectedPropertyForRequestLogModal } from "./modals/request-logs";
import isEmpty from "lodash/isEmpty";
import { TooltipTop } from "@components/tooltips";
import { errorHandlerFor400 } from "@api/services/errorhandler";

export enum PiRequestStates {
	"New" = "NEW",
	"Submitted" = "SUBMITTED",
	"Queued" = "QUEUED",
	"Running" = "RUNNING",
	"Retrying" = "RETRYING",
	"Failed" = "FAILED",
	"Stopping" = "STOPPING",
	"Stopped" = "STOPPED",
	"Success" = "SUCCESS",
}

export const piRequestStateOptions = convertEnumsToOptions(PiRequestStates);

export type PiRequestInfo = {
	created: string;
	updated: string;
	userName: string;
	id: number;
	json: string;
	requestId: string;
	state: PiRequestStates;
	data: string[] | null;
	warning: string[] | null;
	error: string[] | null;
	requestName: string | null;
	sinkName: string;
	fileInfo: string[] | null;
};

type PiManagedRequestsTableProps = {
	data: PiRequestInfo[];
	refreshActivePrRecords: () => void;
	viewRequest: (requestInfo: PiRequestInfo) => void;
	viewRequestLog: (requestInfo: PiRequestInfo, type: SelectedPropertyForRequestLogModal) => void;
	activeSortColumn: ActiveSortColumn;
	setActiveSortColumn: SetActiveSortColumn;
	loading: boolean;
	viewData: (data: string[]) => void;
	viewLogs:(data: PiRequestInfo) => void;
	viewProgress:(data: PiRequestInfo) => void;
	connectionType: string;
};

type NextStateButtonProps = {
	requestInfo: PiRequestInfo;
	submitRequest: (arg0: PiRequestInfo) => void;
	stopRequest: (arg0: PiRequestInfo) => void;
	deleteRequest: (arg0: PiRequestInfo) => void;
	isLoading: boolean;
	connectionType: string;
};

const NextStateButton: React.FC<NextStateButtonProps> = ({
	requestInfo,
	submitRequest,
	stopRequest,
	deleteRequest,
	isLoading,
	connectionType
}) => {
	switch (requestInfo.state) {
		case PiRequestStates.New:
		case PiRequestStates.Failed:
		case PiRequestStates.Stopped:
		case PiRequestStates.Success:
			return (
				<>	
					{/* {requestInfo.state === PiRequestStates.New &&
					<button
						onClick={() => deleteRequest(requestInfo)}
						className="btn-sm btn-red"
					>
						Delete
					</button>
					} */}
					<button
						onClick={() => submitRequest(requestInfo)}
						className={clsx("btn-sm btn-yellow", styles.piTableBtn)}
						disabled={isLoading || requestInfo.state === PiRequestStates.Success}
					>
						<img src="/icons/data-browser/submit.svg" alt="" /> 
						Submit
					</button>
				</>
			);
		case PiRequestStates.Submitted:
		case PiRequestStates.Queued:
		case PiRequestStates.Running:
		case PiRequestStates.Stopping:
		case PiRequestStates.Retrying:
			return (
				<div>
					{connectionType !=='Edge_Managed_Oracle' && (
						<button
							onClick={() => stopRequest(requestInfo)}
							className={clsx("btn-sm btn-red", styles.piTableBtn )}
							disabled={requestInfo.state === PiRequestStates.Stopping}
						>
							{requestInfo.state === PiRequestStates.Stopping ? (
								<InPageSpinner size="XSmall" color="black" />
							) : (
								<>
									<img src="/icons/data-browser/stop.svg" alt="" />
									<span>Stop</span>
								</>
							)}
						</button>
					)}
				</div>
			);			
		default:
			return null;
	}
};

const PiManagedRequestsTable: React.FC<PiManagedRequestsTableProps> = ({
	data,
	refreshActivePrRecords,
	viewRequest,
	viewRequestLog,
	activeSortColumn,
	setActiveSortColumn,
	loading,
	viewData,
	viewLogs,
	viewProgress,
	connectionType
}) => {
	const { start, stop } = useInterval(refreshActivePrRecords, 7500);
	const [deleting, setDeleting] = useState<number[]>([]);
	const [cloning, setCloning] = useState<number[]>([]);
	const [isLoading,setIsLoading] = useState(false);

	useEffect(() => {
		start();

		return () => {
			stop();
		};
	}, []);

	const onRequestActionSuccess = () => {
		refreshActivePrRecords();
		setIsLoading(false)
	};

	const submitRequest = (requestInfo: PiRequestInfo) => {
		setIsLoading(true)
		DataSourceHandler.SubmitPiRequest(
			requestInfo.id,
			onRequestActionSuccess,
			(err:any)=>{
				setIsLoading(false);
				errorHandlerFor400(err);
			}
		);
	};

	const downloadRequest = (requestInfo: PiRequestInfo) => {
		DataSourceHandler.DownloadPiRequestJson(
			requestInfo.id,
			(res: Object) => {
				saveTextToFile(
					`request_${requestInfo.id}_${Date.now()}.json`,
					JSON.stringify(res)
				);
			}
		);
	};

	const cloneRequest = (requestInfo: PiRequestInfo) => {
		if(cloning.includes(requestInfo.id)) return;
		setCloning(state => [...state, requestInfo.id]);
		DataSourceHandler.ClonePiRequest(requestInfo.id, () => {
			setCloning(state => state.filter(id => id !== requestInfo.id));
			successAlert("Cloned Successfully.");
			onRequestActionSuccess();
		});
	};

	const stopRequest = (requestInfo: PiRequestInfo) => {
		DataSourceHandler.StopPiRequest(requestInfo.id, onRequestActionSuccess);
	};

	const deleteRequest = (requestInfo: PiRequestInfo) => {
		if(deleting.includes(requestInfo.id)) return;
		setDeleting(state => [...state, requestInfo.id]);
		DataSourceHandler.DeletePiRequest(requestInfo.id, () => {
			setDeleting(state => state.filter(id => id !== requestInfo.id));
			successAlert("Deleted Successfully.")
			onRequestActionSuccess()
		});
	};

	const renderActionButtons: TableCellRenderer = ({ rowData }) => {
		return (
			<div className={styles["piRequest__buttonsContainer"]}>
				{/* styles["download__btn"] */}
				<NextStateButton
					requestInfo={rowData}
					submitRequest={submitRequest}
					stopRequest={stopRequest}
					deleteRequest={deleteRequest}
					isLoading={isLoading}
					connectionType={connectionType}
				/>
				<div className="piRequest_actionBtnIcons">
					{
						!isEmpty(rowData.warning) &&
						<TooltipTop
							title="View Warning"
						>
							<button
								onClick={() => viewRequestLog(rowData, 'warning')}
							>
								<img src="/icons/data-browser/warning.svg" alt="" />
							</button>
						</TooltipTop>
					}
					{
						!isEmpty(rowData.error) &&
						<TooltipTop
							title="View Error"
						>
							<button
								onClick={() => viewRequestLog(rowData, 'error')}
							>
								<img src="/icons/data-browser/error.svg" alt="" />
							</button>
						</TooltipTop>
					}
					<div className="piRequest_actionBtnIcons show-on-hover">
						<TooltipTop
							title="View Request"
						>
							<button
								onClick={() => viewRequest(rowData)}
							>
								<img src="/icons/data-browser/view.svg" alt="" />
							</button>
						</TooltipTop>
						<TooltipTop
							title="Clone Request"
						>
							<button
								disabled={cloning.includes(rowData.id)}
								onClick={() => cloneRequest(rowData)}
							>
								<img src="/icons/data-browser/copy.svg" alt="" />
							</button>
						</TooltipTop>
						{ rowData.state === PiRequestStates.New &&
							<TooltipTop
								title="Delete Request"
							>
								<button
									disabled={deleting.includes(rowData.id)}
									onClick={() => deleteRequest(rowData)}
								>
									<img src="/icons/data-browser/delete.svg" alt="" />
								</button>
							</TooltipTop>
						}
						{rowData.data && rowData.data.length > 0 &&
							<TooltipTop
								title="View Data"
							>
								<button
									onClick={() => viewData(rowData.data)}
								>
									<img src="/icons/data-browser/view.svg" alt="" />
								</button>
							</TooltipTop>
						}
							{connectionType !=='Edge_Managed_Oracle' && (<TooltipTop
								title="View Logs"
							>
								<button
									onClick={() => viewLogs(rowData)}
								>
									<img src="/icons/data-browser/view.svg" alt="" />
								</button>
							</TooltipTop>)}

							{connectionType =='Edge_Managed_FTP' && (<TooltipTop
								title="View Progress"
							>
								<button
									onClick={() => viewProgress(rowData)}
								>
									<img src="/icons/data-browser/view.svg" alt="" />
								</button>
							</TooltipTop>)}
					</div>
				</div>
				{/* <button className={clsx("btn btn-sm btn-grey-transparent", styles["download__btn"])} onClick={() => downloadRequest(rowData)} ><DownloadIcon /> JSON</button> */}
			</div>
		);
	};

	const renderState: TableCellRenderer = ({ rowData }) => (
		<div
			className={clsx(styles.requestState, styles[rowData.state],{
			})}
		>
			<span>{rowData.state}</span>
		</div>
	);

	return (
		<div className={styles.piRequestTableOuter}>
			<Table.AutoSizer>
				{({ width, height }) => (
					<Table
						height={height}
						rowCount={data.length}
						width={width}
						rowGetter={({ index }) => {
							return data[index];
						}}
						rowHeight={42}
						headerHeight={42}
						activeSortColumn={activeSortColumn}
						setActiveSortColumn={setActiveSortColumn}
						showLoader={loading}
					>
						<Table.Column
							dataKey="requestId"
							width={80}
							label="ID"
						/>
						<Table.Column
							dataKey="requestName"
							width={width * 0.2}
							label="Request Name"
						/>
						<Table.Column
							dataKey="sinkName"
							width={width * 0.2}
							label="Sink Name"
						/>
						<Table.Column
							dataKey="created"
							width={width * 0.15}
							label="Request Created"
						/>
						<Table.Column
							dataKey="userName"
							width={width * 0.1}
							label="User"
						/>
						<Table.Column
							dataKey="updated"
							width={width * 0.15}
							label="State Updated"
						/>
						<Table.Column
							dataKey="state"
							width={160}
							label="State"
							cellRenderer={renderState}
						/>
						<Table.Column
							width={340}
							dataKey="_actions"
							label="Actions"
							cellRenderer={renderActionButtons}
						/>
					</Table>
				)}
			</Table.AutoSizer>
		</div>

	);
};

export { PiManagedRequestsTable };
