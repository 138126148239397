import { useFormikContext } from "formik";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { uuid } from "uuidv4";
import { DetailedWorkflowInfo, WorkflowHandler } from "@api/workflow-handler";
import { _selectoptionType } from "../../form/select-field";
import { BaseFieldType } from "../types";
import { SchemaCaptureProps, strictFunc } from "../field-creator";
import { workflowRunData } from "../../../pages/workflow-page";
import ComponentFieldSelection, {
	ComponentFieldSelectionType,
} from "./componentFieldSelection";
import WorkflowConfigSelection, {
	WorkflowConfigSelectionRef,
} from "./workflowConfigSelection";
import classNames from "classnames";
import { useAppSelector } from "@store/hooks";
import { BaseSelectField } from "../fieldComponents/BaseSelectField";
import { getUniqueKeyForWorkflowConfigItem, WorkflowConfigItem } from "@services/WorkflowConfig";
import { ExecutionEnvModes } from "@constants/enums";
import { isArray } from "lodash";

type WorkflowFieldProps = {
	fieldData: BaseFieldType;
	captureSchemaOptions: SchemaCaptureProps["captureSchemaOptions"];
};

export type WorkflowFieldSelectionObj = {
	componentPropertiesArr: ComponentFieldSelectionType[];
	// Stores the values of all components as
	// { [componentId]: { [fieldKey]: fieldValue }}
	componentPropertiesValuesRef: Record<string, Record<string, any>>;
	workflowInfo: {
		payload: workflowRunData;
		id: number;
		name: string;
	};
};

const WorkflowField: React.FC<WorkflowFieldProps> = ({
	fieldData,
	captureSchemaOptions,
}) => {
	const [workflowsList, setWorkflowsList] = useState<_selectoptionType[]>([]);
	const { setFieldValue, values } = useFormikContext<any>();
	const [
		workflowInfo,
		setWorkflowInfo,
	] = useState<DetailedWorkflowInfo | null>(null);
	const activeComponentName = useAppSelector(
		(store) => store.WorkflowReducer.activeComponentInfo?.name
	);
	const globalPipelineConfig = useAppSelector((store) => {
		const { activeTab, openTabs } = store.CanvasReducer.workflowEditor;
		const activeTabInfo = openTabs.get(activeTab.id)?.info;
		if (
			activeTabInfo?.env === ExecutionEnvModes["Pipelines"] &&
			isArray(activeTabInfo.config)
		) {
			return activeTabInfo.config.reduce((acc, configItem) => {
				acc[getUniqueKeyForWorkflowConfigItem(configItem)] = configItem;
				return acc;
			}, {} as Record<string, WorkflowConfigItem>);
		}
	});
	const workflowConfigSelectionRef = useRef<WorkflowConfigSelectionRef>(null);

	useEffect(() => {
		if (activeComponentName) {
			const _name = activeComponentName.toLowerCase();
			if (_name === "databricks operator") {
				// automatically set showWorkflowConfig to true in dag components - will be removed later
				fieldData.templateOptions.showWorkflowConfig = true;
			}
		}
	}, [fieldData, activeComponentName]);

	const getWorkflowInfo = (
		workflowId: number,
		setWorkflowConfigInfo = false
	) => {
		WorkflowHandler.GetWorkflowInfo(workflowId, (workflowInfo) => {
			setWorkflowInfo(workflowInfo);
			if (setWorkflowConfigInfo) {
				workflowConfigSelectionRef.current?.onWorkflowChange(
					workflowInfo.data.config
				);
			}
		});
	};

	const setTaskFieldValue = (option: _selectoptionType) => {
		getWorkflowInfo(option.value, true);
		// task_id is set directly using unique-id field
		// this can be removed after all components use unique-id field
		setFieldValue(
			"task_id",
			workflowsList.find((w) => w.value === option.value)?.label +
				"_" +
				uuid()
		);
	};

	const retrieveWorkflows = () => {
		WorkflowHandler.RetrieveSparkPythonWorkflows((res) => {
			const options: _selectoptionType[] = res.data.map((workflow) => ({
				label: workflow.projectName,
				value: workflow.id,
			}));
			setWorkflowsList(options);

			// Set Selected Workflow info on mount
			if (
				(fieldData.templateOptions?.showPropertySelection ||
					fieldData.templateOptions.showWorkflowConfig) &&
				values[fieldData.key]
			) {
				getWorkflowInfo(values[fieldData.key]);
			}
		});
	};

	useEffect(() => {
		retrieveWorkflows();
	}, []);

	const isHidden = strictFunc(values, fieldData?.hideExpression  || '');


	const showWorkflowConfig =
		fieldData.templateOptions.showWorkflowConfig &&
		workflowInfo?.data

	return (
		<div className={classNames("workflowField", { showWorkflowConfig })}>
			<BaseSelectField
				fieldData={fieldData}
				options={workflowsList}
				onOptionClick={setTaskFieldValue}
				className="workflowSelection__dropdown"
			/>
			{fieldData.templateOptions?.showPropertySelection && (
				<ComponentFieldSelection
					workflowInfo={workflowInfo}
					captureSchemaOptions={captureSchemaOptions}
					fieldKey={
						fieldData.templateOptions.showPropertySelection.key
					}
				/>
			)}
			{showWorkflowConfig && !isHidden && (
					<WorkflowConfigSelection
						stringifiedConfig={workflowInfo.data.config || ""}
						fieldKey={fieldData.key}
						ref={workflowConfigSelectionRef}
						globalPipelineConfig={globalPipelineConfig}
					/>
			)}
		</div>
	);
};

export default WorkflowField;
