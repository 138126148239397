// import {
//     DataTransformationapis, DataTransformationAws, DataTransformationAzure, DataTransformationGoogleCloud,
//     DataTransformationsap, DataTransformationfile_format_conversion, DataTransformationfiledataframes,
//     DataTransformationfileMatch, DataTransformationTimeseries_DB, DataTransformationOther, DataTransformationCheckpointing, DataTransformationRelationalSources,
//     DataTransformationPartition, DataTransformationcdc, DataTransformationTransform, DataTransformationNoSQL, DataTransformationHadoop, DataTransformationdatabricks,
//     DataTransformationFiles, StructuredAnalyticsImputation, StructuredAnalyticsdimensionality_reduction, StructuredAnalyticscustom_model,
//     StructuredAnalyticsStatistics, StructuredAnalyticsCorrelation, StructuredAnalyticsClustering, StructuredAnalyticsRegression,
//     StructuredAnalyticsOptimizers, TimeSeriesAnalyticsPatternSearch, TimeSeriesAnalyticsOultierDetection,
//     TimeSeriesAnalyticsSmoothData1, TimeSeriesAnalyticsForecast, UnstructuredAnalyticsTextAnalytics,
//     StreamingAnalytics, Custom_Component, StructuredAnalyticsClassification
// } from './assets/icons';

import {
    ReadRDS, WriteRDS, ReadDynamoDB, WriteDynamoDB, ReadRedshift, WriteRedshift,
    ReadSQLDataWarehouse, WriteSQLDataWarehouse, ReadSQLDatabase, WriteSQLDatabase,
    ReadMySQL, WriteMySQL, ReadPostgreSQL, WritePostgreSQL, ReadAzureBlob, WriteAzureBlob,
    ReadCloudSQL, WriteCloudSQL, ReadBigQuery, WriteBigQuery, ReadBigTable, WriteBigTable,
    ReadCSV, ReadLAS, WriteCSV, ReadXML, WriteXML, ReadJSON, WriteJSON, ReadHDFS, WriteHDFS,
    ReadDocument, ReadGeoJSON, ReadESRIJSON, WriteGeoJSON, ReadShapeFile, WriteShapeFile,
    ReadTIFF, ReadDirectory, WriteTIFF, ReadMongoDB, WriteMongoDB, ReadHive, WriteHive,
    ReadHBase, WriteHBase, ReadKudu, WriteKudu, ReadMapR, WriteMapR, Join, Filter, RenameMultipleColumns,
    Select, RandomSplit, CumulativeSum, Rollup, Sort, RemoveDuplicates, Replace, DropColumns, FormatTimeStamp,
    ChangeTimeZone, ChangeDataType, ReplaceSpecialCharacters, GenerateID, ComputeStatistics, Concat, TreatNull,
    EncodeDecode, Groupby, Hash, SetOperation, Substring, ScrubColumnNames, Explode, Pivot, ColumnAdd, ColumnSubtract,
    ColumnMultiply, ColumnDivide, SQLQuery, Union, CollectColumns, RemoveArrayValue, ReplaceArrayValue, AggregateArray,
    WindowGroup, WindowUngroup, Bin, Downsample, CreateandDeleteCDC, CreateUpdateandDeleteCDC, TimeFilterCDC,
    Audit, MasterAudit, Master, Coalesce, Repartition, Merge, ReadRDBMS, WriteRDBMS,
    ReadSAPHana, ReadSnowflake, WriteSnowflake, ReadSAPECC, Persist, TempTable, Show,
    CustomComponent, DataQuality, NestedLoop, FastDTW, PatternMatching, BulkIngest, Loop,
    GEProficy, ClearSCADA, OSIPI, iFix, AspenTechIP21, CygNet, WITSML, NumberMatch,
    FuzzyDateMatch, GeospatialMatch, FuzzyStringMatch, FromPandas, ToPandas,
    FromGlueDynamicFrame, ToGlueDynamicFrame, ReadREST, GDBtoGeoJSON, ReadBAPI,
    ReadDeltaLake, WriteDeltaLake, LinearRegression, RandomForestRegression,
    DecisionTreeRegression, GradientBoostedTreeRegression, GeneralizedLinearRegression,
    LogisticRegression, LinearSVM, RandomForestClassification, DecisionTreeClassification,
    GradientBoostedTreeClassification, NaiveBayes, PearsonCorrelation, SpearmanCorrelation,
    GaussianMixture, KMeans, BisectingKMeans, Median, Mean, StandardDeviation, Variance,
    Percentile, RollingMean, RollingStandardDeviation, RollingMedian, RollingMax, RollingMin,
    RollingKurt, RollingSkew, RollingWindowImpute, CustomPredict, PCA, IMPL,
    SimpleExponentialForecast, HoltForecast, HoltWintersForecast, ARIMAForecast, MADOutliers,
    STDOutliers, SimpleExponentialSmoothing, HoltSmoothing, HoltWintersSmoothing,
    SequenceMatching, LanguageDetection, Stemming, StopWords, Tokenize, ReadKafka, WriteKafka, ReadAurora, WriteAurora,
    ReadAthena, WriteAthena, GeneticProgramming, ReadAzureEventHubs, WriteAzureEventHubs, ReadParquet, WriteParquet, ShowStream, Deduplicate
} from './assets/icons';
// import { uuid } from 'uuidv4';
// import { Env } from '../../constants/settings';
import { WorflowImportType, WorkflowSearchQuery } from '../../api/workflow-handler';
import { _selectoptionType } from '../../components/form/select-field';
import { WorkflowConfig, WorkflowConfigItemType } from '@services/WorkflowConfig';
import { getUniqueId } from '@utils/common';

export enum CanvasTabs {
    COMPONENTS = 'COMPONENTS',
    WORKFLOWS = 'WORKFLOWS',
    PROFILES = 'PROFILES',
    // CLUSTERS = 'CLUSTERS'
}

export enum DagCanvasTabs {
    OPERATORS = 'COMPONENTS',
    PIPELINES = 'WORKFLOWS'
}

export const canvasTabsList =  Object.entries(CanvasTabs).map(([tabName, tabValue]) => ({ label: tabName, value: tabValue})) as _selectoptionType[];
export const dagCanvasTabsList = Object.entries(DagCanvasTabs).map(([tabName, tabValue]) => ({ label: tabName, value: tabValue})) as _selectoptionType[];


export const TABS_HEIGHT = 115;

export const scheduleTabs = {
    scheduleOptions: { title: 'Schedule', img: '/icons/workflow/schedule.svg' },
    runOptions: { title: 'Run Options', img: '/icons/workflow/run.svg' }
};

export type ProfileModalTypes = 'NEW' | 'EDIT';

export const ProfileModalTypes: Record<string, ProfileModalTypes> = {
    new: 'NEW',
    edit: 'EDIT'
};

// also add resizing event listener
export const MIN_HEIGHT_FOR_CANVAS = 200;
export const MAX_HEIGHT_FOR_CANVAS = window.innerHeight - TABS_HEIGHT;



export const iconList = {
    'Read RDS': ReadRDS,
    'Write RDS': WriteRDS,
    'Read DynamoDB': ReadDynamoDB,
    'Write DynamoDB': WriteDynamoDB,
    'Read Redshift': ReadRedshift,
    'Write Redshift': WriteRedshift,
    'Read Aurora': ReadAurora,
    'Write Aurora': WriteAurora,
    'Read Athena': ReadAthena,
    'Write Athena': WriteAthena,
    'Read SQL Data Warehouse': ReadSQLDataWarehouse,
    'Write SQL Data Warehouse': WriteSQLDataWarehouse,
    'Read SQL Database': ReadSQLDatabase,
    'Write SQL Database': WriteSQLDatabase,
    'Read MySQL': ReadMySQL,
    'Write MySQL': WriteMySQL,
    'Read PostgreSQL': ReadPostgreSQL,
    'Write PostgreSQL': WritePostgreSQL,
    'Read Azure Blob': ReadAzureBlob,
    'Write Azure Blob': WriteAzureBlob,
    'Read Cloud SQL': ReadCloudSQL,
    'Write Cloud SQL': WriteCloudSQL,
    'Read BigQuery': ReadBigQuery,
    'Write BigQuery': WriteBigQuery,
    'Read BigTable': ReadBigTable,
    'Write BigTable': WriteBigTable,
    'Read CSV': ReadCSV,
    'Read LAS': ReadLAS,
    'Write CSV': WriteCSV,
    'Read XML': ReadXML,
    'Write XML': WriteXML,
    'Read JSON': ReadJSON,
    'Write JSON': WriteJSON,
    'Read HDFS': ReadHDFS,
    'Write HDFS': WriteHDFS,
    'Read Document': ReadDocument,
    'Read GeoJSON': ReadGeoJSON,
    'Read ESRI JSON': ReadESRIJSON,
    'Write GeoJSON': WriteGeoJSON,
    'Read Shape File': ReadShapeFile,
    'Write Shape File': WriteShapeFile,
    'Read TIFF': ReadTIFF,
    'Read Directory': ReadDirectory,
    'Write TIFF': WriteTIFF,
    'Read MongoDB': ReadMongoDB,
    'Write MongoDB': WriteMongoDB,
    'Read Hive': ReadHive,
    'Write Hive': WriteHive,
    'Read HBase': ReadHBase,
    'Write HBase': WriteHBase,
    'Read Kudu': ReadKudu,
    'Write Kudu': WriteKudu,
    'Read MapR': ReadMapR,
    'Write MapR': WriteMapR,
    'Join': Join,
    'Filter': Filter,
    'Rename Multiple Columns': RenameMultipleColumns,
    'Select': Select,
    'Random Split': RandomSplit,
    'Cumulative Sum': CumulativeSum,
    'Rollup': Rollup,
    'Sort': Sort,
    'Remove Duplicates': RemoveDuplicates,
    'Replace': Replace,
    'Drop Columns': DropColumns,
    'Format Time Stamp': FormatTimeStamp,
    'Change Time Zone': ChangeTimeZone,
    'Change Data Type': ChangeDataType,
    'Replace Special Characters': ReplaceSpecialCharacters,
    'Generate ID': GenerateID,
    'Compute Statistics': ComputeStatistics,
    'Concat': Concat,
    'Treat Null': TreatNull,
    'Encode/Decode': EncodeDecode,
    'Groupby': Groupby,
    'Group By': Groupby,
    'Hash': Hash,
    'Set Operation': SetOperation,
    'Substring': Substring,
    'Scrub Column Names': ScrubColumnNames,
    'Explode': Explode,
    'Pivot': Pivot,
    'Column Add': ColumnAdd,
    'Column Subtract': ColumnSubtract,
    'Column Multiply': ColumnMultiply,
    'Column Divide': ColumnDivide,
    'SQL Query': SQLQuery,
    'Union': Union,
    'Collect Columns': CollectColumns,
    'Remove Array Value': RemoveArrayValue,
    'Replace Array Value': ReplaceArrayValue,
    'Aggregate Array': AggregateArray,
    'Window Group': WindowGroup,
    'Window Ungroup': WindowUngroup,
    'Bin': Bin,
    'Downsample': Downsample,
    'Create and Delete - CDC': CreateandDeleteCDC,
    'Create, Update and Delete - CDC': CreateUpdateandDeleteCDC,
    'Time Filter - CDC': TimeFilterCDC,
    'Audit': Audit,
    'Master Audit': MasterAudit,
    'Master': Master,
    'Coalesce': Coalesce,
    'Repartition': Repartition,
    'Merge': Merge,
    'Read RDBMS': ReadRDBMS,
    'Write RDBMS': WriteRDBMS,
    'Read SAP Hana': ReadSAPHana,
    'Read Snowflake': ReadSnowflake,
    'Write Snowflake': WriteSnowflake,
    'Read SAP ECC': ReadSAPECC,
    'Persist': Persist,
    'Temp Table': TempTable,
    'Show': Show,
    'Custom Component': CustomComponent,
    'Data Quality': DataQuality,
    'Nested Loop': NestedLoop,
    'Fast DTW': FastDTW,
    'Pattern Matching': PatternMatching,
    'Bulk Ingest': BulkIngest,
    'Loop': Loop,
    'GE Proficy': GEProficy,
    'ClearSCADA': ClearSCADA,
    'OSI PI': OSIPI,
    'iFix': iFix,
    'AspenTech IP21': AspenTechIP21,
    'CygNet': CygNet,
    'WITSML': WITSML,
    'VTScada': iFix,
    'Honeywell PHD': iFix,
    'Number Match': NumberMatch,
    'Fuzzy Date Match': FuzzyDateMatch,
    'Geospatial Match': GeospatialMatch,
    'Fuzzy String Match': FuzzyStringMatch,
    'From Pandas': FromPandas,
    'To Pandas': ToPandas,
    'From Glue DynamicFrame': FromGlueDynamicFrame,
    'To Glue DynamicFrame': ToGlueDynamicFrame,
    'Read REST': ReadREST,
    'GDB to GeoJSON': GDBtoGeoJSON,
    'Read BAPI': ReadBAPI,
    'Read Delta Lake': ReadDeltaLake,
    'Write Delta Lake': WriteDeltaLake,
    'Linear Regression': LinearRegression,
    'Random Forest Regression': RandomForestRegression,
    'Decision Tree Regression': DecisionTreeRegression,
    'Gradient Boosted Tree Regression': GradientBoostedTreeRegression,
    'Generalized Linear Regression': GeneralizedLinearRegression,
    'Logistic Regression': LogisticRegression,
    'Linear SVM': LinearSVM,
    'Random Forest Classification': RandomForestClassification,
    'Decision Tree Classification': DecisionTreeClassification,
    'Gradient Boosted Tree Classification': GradientBoostedTreeClassification,
    'Naive Bayes': NaiveBayes,
    'Pearson Correlation': PearsonCorrelation,
    'Spearman Correlation': SpearmanCorrelation,
    'Gaussian Mixture': GaussianMixture,
    'K-Means': KMeans,
    'Bisecting K-Means': BisectingKMeans,
    'Median': Median,
    'Mean': Mean,
    'Standard Deviation': StandardDeviation,
    'Variance': Variance,
    'Percentile': Percentile,
    'Rolling Mean': RollingMean,
    'Rolling Standard Deviation': RollingStandardDeviation,
    'Rolling Median': RollingMedian,
    'Rolling Max': RollingMax,
    'Rolling Min': RollingMin,
    'Rolling Kurt': RollingKurt,
    'Rolling Skew': RollingSkew,
    'Rolling Window Impute': RollingWindowImpute,
    'Custom Predict': CustomPredict,
    'PCA': PCA,
    'IMPL': IMPL,
    'Simple Exponential Forecast': SimpleExponentialForecast,
    'Holt Forecast': HoltForecast,
    'Holt-Winters Forecast': HoltWintersForecast,
    'ARIMA Forecast': ARIMAForecast,
    'MAD Outliers': MADOutliers,
    'STD Outliers': STDOutliers,
    'Simple Exponential Smoothing': SimpleExponentialSmoothing,
    'Holt Smoothing': HoltSmoothing,
    'Holt-Winters Smoothing': HoltWintersSmoothing,
    'Sequence Matching': SequenceMatching,
    'Language Detection': LanguageDetection,
    'Stemming': Stemming,
    'Stop Words': StopWords,
    'Tokenize': Tokenize,
    'Read Kafka': ReadKafka,
    'Read Timestream': ReadKafka,
    'Write Kafka': WriteKafka,
    'CustomComponent': CustomComponent,
    'Genetic Programming': GeneticProgramming,
    'Read Azure Event Hub': ReadAthena,
    'Write Azure Event Hub': WriteAthena,
    'Write Stream': Median,
    "Read Azure Event Hubs": ReadAzureEventHubs,
    "Write Azure Event Hubs": WriteAzureEventHubs,
    'Read Parquet': ReadParquet,
    'Write Parquet': WriteParquet,
    'Show Stream': ShowStream, 
    "Deduplicate": Deduplicate,
};


export const customIconList = {
    'Custom_Component': CustomComponent
};

export const OFFSET_VARIABLE_PLACEHOLDER = {
    x: 18,
    y: 18
};

export function getInitialCustombuilderValues() {
    // const argList = [{
    //     id: uuid(),
    //     ['title']: ' ',
    //     ['name']: '',
    //     ['typeOf']: '',
    //     ['defaultValue']: '',
    //     ['tooltip']: '',
    //     ['required']: false
    // }];

    return {
        argList: [],
        inputPorts: 1,
        outputPorts: 1,
        noOfArgs: 1,
        componentDesc: '',
        functionType: 'pyspark',
        functionName: '',
        functionString: '   #Type code here\r\n    pass',
        extraFn: '',
        importCode: '',
        outputList: ['   return output1'],
        inputList: ['df1'],
        name: ''
    };
}

export enum InnerWorkflowTabs {
    WORKFLOWS = 'Workflows',
    STARRED = 'Starred',
    SHARED = 'Remote'
}

export const sortByOptions: Record<string, WorkflowSearchQuery['sort']> = {
    'Date Created': 'created',
    'Last Modified': 'updated'
};

export const importOptions: Record<string, WorflowImportType> = {
    'Import workflow': 'workflow',
    'Import Directory': 'directory'
};


export const sampleConfigForDownload: WorkflowConfig = [
    {
        id: getUniqueId(),
        type: WorkflowConfigItemType.String,
        key: 'ping',
        value: 'pong'
    },
    {
        id: getUniqueId(),
        type: WorkflowConfigItemType.Integer,
        key: 'james_bond',
        value: 7
    },
    {
        id: getUniqueId(),
        type: WorkflowConfigItemType.Decimal,
        key: 'pi',
        value: 3.414
    },
    {
        id: getUniqueId(),
        type: WorkflowConfigItemType.Boolean,
        key: 'valid',
        value: true
    },
]