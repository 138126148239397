import React from 'react';
import './styles.scss';
import classNames from 'classnames';




export const InPageSpinner: React.FC<{ size?: 'XSmall' | 'Small' | 'Large'; hide?: boolean; color?: 'black' | 'white'; className?: string }> = React.memo(({ size = 'Large', hide = false, color = 'white', className = '' }) => (
    <div 
        className={classNames(
            'inPageSpinner', 
            {'inPageSpinner__large': size === 'Large', 
                'inPageSpinner__small': size === 'Small',
                'inPageSpinner__XSmall': size === 'XSmall',
                'inPageSpinner__black': color === 'black',
                'hide': hide,
                [className]: className   
            }

        )}
    >
        <div className="lds-ring">
            <div></div><div></div><div></div><div></div>
        </div>
    </div>
));

InPageSpinner.displayName = 'InPageSpinner';