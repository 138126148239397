import Form, { InputField, NewSelectField, RadioField } from "@components/form";
import { booleanOptions } from "@components/formcreators/ml-pipeline/enums";
import React, { useMemo } from "react";
import styles from "./styles.module.scss";
import * as yup from "yup";
import { convertEnumsToOptions } from "@utils/common";
import { has, isBoolean, isInteger, isString } from "lodash";
import {
	CommonKeysInfoForGlobalArguments,
	WorkflowConfig,
	WorkflowConfigItem,
} from "@services/WorkflowConfig";
import { WorkflowConfigItemType } from "@services/WorkflowConfig";
import { _selectoptionType } from "@components/form/select-field";
import { FormikHelpers } from "formik";

const typeOptions = convertEnumsToOptions(WorkflowConfigItemType).slice(0, 4);

export type WorkflowConfigFormValues = WorkflowConfigItem;

type WorkflowConfigFormProps = {
	onSubmit: (arg0: WorkflowConfigItem) => void;
	initialValues: WorkflowConfigFormValues;
	children:
		| ((arg0: { values: any; isValid: boolean }) => JSX.Element)
		| JSX.Element;
	config: WorkflowConfig;
	allowValueEditOnly?: boolean;
	commonKeysInfoForGlobalArguments?: CommonKeysInfoForGlobalArguments;
};

const BLACKLISTED_KEYS = {
	int: true,
	decimal: true,
	float: true,
	string: true,
	str: true,
	long: true,
	double: true,
	bool: true,
	boolean: true,
	read: true,
	write: true,
	transform: true,
};

export const getWorkflowConfigSchema = (existingKeyValidationFunc ?: (key: string) => boolean) => {	
	return yup.object().shape({
		key: yup
			.string()
			.test({
				test: function(value) {
					const getError = (message: string) =>
						new yup.ValidationError(message, value, this.path);
					if (value) {
						if (/\s/.test(value)) {
							return getError(`Cannot have spaces`);
						} else if (/^\d/.test(value)) {
							return getError(`Cannot start with a number`);
						} else if (!/^[a-zA-Z0-9_]*$/.test(value)) {
							return getError(
								`Has to be alphanumeric, only _ are allowed`
							);
						} else if (existingKeyValidationFunc?.(value) || false) {
							return getError(
								`${value} already exists, please use a different key`
							);
						} else if (has(BLACKLISTED_KEYS, value.toLowerCase())) {
							return getError(
								`Please use a different key, ${value} is not allowed`
							);
						}
					}
					return true;
				},
			})
			.required("This is a required field"),
		value: yup
			.mixed()
			.test({
				test: function(value) {
					const valueType = this.parent
						.type as WorkflowConfigItemType;
					const getError = (errorMessage = `Value must be a ${valueType}`) =>
						new yup.ValidationError(
							errorMessage,
							value,
							this.path
						);
					switch (valueType) {
						case WorkflowConfigItemType.String:
							if (!isString(value)) return getError();
							return true;
						case WorkflowConfigItemType.Integer:
						case WorkflowConfigItemType.Decimal: {
							if (isBoolean(value) || isNaN(+value))
								return getError();
							else if (
								valueType === WorkflowConfigItemType.Integer &&
								!isInteger(+value)
							) {
								return getError();
							} else if((/\s/g).test(value)) {
								return getError('Value cannot have spaces');
							}
							return true;
						}
						case WorkflowConfigItemType.Boolean:
							if (!isBoolean(value)) return getError();
							return true;
					}
					return true;
				},
			})
			.required("This is a required field"),
	});
};

const WorkflowConfigForm: React.FC<WorkflowConfigFormProps> = ({
	children,
	onSubmit,
	initialValues,
	config,
	allowValueEditOnly = false,
	commonKeysInfoForGlobalArguments,
}) => {
	const globalArgsMode = !!commonKeysInfoForGlobalArguments;

	const schema = useMemo(() => {
		let existingKeyValidationFunc;

		// In global args mode, two keys with same name of different type can exist
		if (!globalArgsMode) {
			const existingKeyMap = config.reduce((_map, configItem) => {
				_map[configItem.key] = true;
				return _map;
			}, {} as Record<string, true>);

			existingKeyValidationFunc = (key: string) =>
				has(existingKeyMap, key);
		}

		return getWorkflowConfigSchema(existingKeyValidationFunc);
	}, [config, globalArgsMode]);

	const onArgumentSelection = (
		setFieldValue: FormikHelpers<any>["setFieldValue"],
		option: _selectoptionType
	) => {
		// set option of the type dropdown
		const selectedTypeOption = typeOptions.find(
			(typeOption) => typeOption.value === option.configItem.type
		);
		// shouldValidate is to false to fix required field issue of key dropdown
		selectedTypeOption &&
			setFieldValue("type", selectedTypeOption.value, false);
	};

	return (
		<Form
			initialValues={initialValues}
			onSubmit={onSubmit}
			enableReinitialize
			className={styles["workflowConfig__form"]}
			validationSchema={schema}
			validateOnMount
		>
			{({
				_formikprops: {
					values,
					isValid,
					setFieldTouched,
					setFieldValue,
					validateForm,
				},
			}) => (
				<>
					{globalArgsMode ? (
						<>
							<NewSelectField
								label="Key"
								name="key"
								options={commonKeysInfoForGlobalArguments}
								onOptionClick={onArgumentSelection.bind(
									null,
									setFieldValue
								)}
							/>
						</>
					) : (
						<InputField
							label="Key"
							name="key"
							readOnly={allowValueEditOnly}
						/>
					)}
					<NewSelectField
						label="Type"
						options={typeOptions}
						onOptionClick={(option) => {
							if (
								option.value === WorkflowConfigItemType.Boolean
							) {
								// Sets default value of value to be true
								setFieldValue("value", true);
								setTimeout(() => {
									// this is to set isValid to False
									validateForm();
								}, 0);
							} else if (isBoolean(values.value)) {
								setFieldValue("value", "");
								setFieldTouched("value", false);
								setTimeout(() => {
									// this is to set isValid to False
									validateForm();
								}, 0);
							}
						}}
						name="type"
						disabled={
							allowValueEditOnly ||
							!!commonKeysInfoForGlobalArguments?.length
						}
					/>
					{values.type === WorkflowConfigItemType.Boolean ? (
						<RadioField
							label="Value"
							name="value"
							options={booleanOptions}
						/>
					) : (
						<InputField label="Value" name="value" />
					)}
					{typeof children === "function"
						? children({ values, isValid })
						: children}
				</>
			)}
		</Form>
	);
};

export default WorkflowConfigForm;
