/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect, useRef } from 'react';
import {  useField, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ShowWhenTrue } from '../../helpers';
import classNames from 'classnames';
import MonacoEditor from 'react-monaco-editor';
import { Modal, Tooltip, Tabs } from 'antd';
import { RootState } from '../../store/types';
import QueryBuilder from '../../components/builder/sql/index';
import { CaptureSchemaResponse } from '../../api/workflow-handler';
import { InPageSpinner } from '../../components/spinners/in-page-spinner';
import { has, omit } from 'lodash';
import * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';
import { format } from 'sql-formatter';
import {Env} from '../../constants/settings';
import { useSchemaCapture } from '../../utils/schema-capture-hook';
import { errorAlert, infoAlert } from '../toastify/notify-toast';
import { handleShowCaptureSchemaLogs } from '../../store/workflow';
import { useGetActiveTabInfo } from '../../utils';
import { ClusterModal } from './../../pages/cluster_redisign/ClusterModal';
import { ClusterDetail, ClusterState, NewDatabricksHandlerClass } from '@api/databricks-handler';
import AIQueryBuilder from '@components/builder/ai_sql';
import TextToSQL from './TextToSQL';

const { TabPane } = Tabs;

export interface EditorProps {
    name: string;
    label?: string;
    required?: boolean;
    className?: string;
    // inline?: boolean;
    readOnly?: boolean;
    value?: any;
    validate?: any;
    editor_type?: string;
    'aria-labelledby'?: string;
    advance_sql?: boolean;
    editorHeight?: string;
    usecase?: string;
}

export const editorOptions: monacoEditor.editor.IEditorConstructionOptions = {
    extraEditorClassName: 'codeEditor',
    'selectOnLineNumbers': true,
    'contextmenu': false,
    'formatOnPaste': true,
    'accessibilitySupport': 'auto',
    'autoIndent': 'full',
    'folding': true,
    'highlightActiveIndentGuide': true,
    'quickSuggestions': true,
    'formatOnType': true,
    'minimap': {
        enabled: false,
    },
    fontSize: 12,
    letterSpacing: 0.25,
    lineNumbersMinChars: 3,
    lineDecorationsWidth: 0,
    lineHeight: 21,
    scrollbar:{
        verticalScrollbarSize: 0,
        horizontalScrollbarSize: 10,
        verticalSliderSize: 4,
        horizontalSliderSize: 4,
    },
    glyphMargin: false,
    automaticLayout: true
};


export const EditorField: React.FC<EditorProps> = ({ 
    label, 
    required, 
    readOnly, 
    children, 
    className = '', 
    editor_type = 'normal', 
    // inline = false, 
    advance_sql,
    editorHeight='200px',
    usecase,
    ...props 
}): JSX.Element => {
    const dispatch = useDispatch();
    const currentUser = useSelector((store: RootState) => store.AccountReducer.activeUserInfo);
    const activeComponentId = useSelector((store: RootState) => store.WorkflowReducer.activeComponentInfo?.id);
    const {  setFieldError, setErrors, errors, setFieldValue, values } = useFormikContext<any>();
    const [ queryData, setQueryData ] = useState<any>(null);
    const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
    const [showClusterModal, setShowClusterModel] = useState(false);
    const [loadingSchemaCapture, setLoadingSchemaCapture] = useState(false);
    const [failedSchemaCapture, setFailedSchemaCapture] = useState(false);
    const [, {value: actualValue }, { setValue: setActualValue }] = useField(props.name);
    const [changedValue, setChangedValue] = useState('');
    const { activeExecutionEnv } = useSelector((store: RootState) => store.CommonReducer);
    const { activeUserInfo } = useSelector((store: RootState) => store.AccountReducer);
    const activeTabInfo = useGetActiveTabInfo('workflowEditor');
    const defaultClusterId = useSelector(
        (store: RootState) => store.ClusterReducer.defaultClusterInfo?.clusterId
    );
    const [currentClusterDetail, setClusterDetail ] = useState<(ClusterDetail & {state?: ClusterState })|null>(null);

    const workSpaceData = useSelector((store: RootState) => store.ClusterReducer.workspaceList);
    const enabledWorkspace = workSpaceData.filter((item)=>item.isEnabled)[0];
    const [textToSqlView, SetTextToSqlView] = useState(false);
    const [editorWidth, setEditorWidth] = useState('100%');
    const te =  useRef<any>();
    const mref =  useRef<MonacoEditor>(null)

    useEffect(() => {
        if(props.validate){
            props
                .validate(actualValue)
                .then((errorMessage?: string) =>{
                    if(errorMessage) setFieldError(props.name, errorMessage);
                    else {
                        if(has(errors, props.name)){
                            const __errors = omit(errors, props.name);
                            setErrors(__errors);
                        }
                    }
                }); 
        }
        if(usecase === 'cluster') {
            try {
                setClusterDetail(typeof actualValue === 'string' ? JSON.parse(actualValue) : actualValue);
            } catch(e) {
                setClusterDetail(null);
            }
        }
        // setFieldValue(props.name, actualValue);
    }, [actualValue]);

    const provideHover = (model: monacoEditor.editor.ITextModel, position: monacoEditor.Position, token: monacoEditor.CancellationToken) => {
        const temp = (te.current || (window as any).options_schema) 
        
        if(!temp.keyvalue1) return;
        const word = model.getWordAtPosition(position)?.word || '';

        if(!temp.keyvalue1[word]) 
            return null;

        return {
            contents: [
            {
                value: temp.keyvalue1[word]
            }]
        };
    }

    useEffect(() => {
        const temp = monacoEditor.languages.registerHoverProvider('sql', {
            provideHover: provideHover,               
		});
        return ()=>{
            temp.dispose();
            (window as any).options_schema = null
            // Hack to render monoco editor to display the ui, when show dynmaically
            window.dispatchEvent(new Event('resize'));
        };
    }, [monacoEditor]);


    useEffect(() => {
        mref.current?.editor?.layout()
    },[usecase])

    useEffect(() => {
        mref.current?.editor?.layout()
    }, [mref.current, values])

    const formatCode = (style = false)=>{
        if(style) {
            setChangedValue(format(changedValue));
        } else {
            setActualValue(format(actualValue));
        }
        infoAlert('Format Complete');
    };

    function handleEditorDidMount(editor: monacoEditor.editor.IStandaloneCodeEditor, monaco: typeof monacoEditor) {
        const color = '#14182a';
        monaco.editor.defineTheme('ds', {
            base: 'vs-dark',
            inherit: true,
            rules: [],
            colors: {
                'editor.foreground': color,
                'editor.background': color,
                'editor.lineHighlightBackground': color
            }
        });
        monaco.editor.setTheme('ds');
        if(readOnly) {
            editor.updateOptions({ readOnly: true });
            monaco.editor.setTheme('ds-readOnly');
        } else {
            // editor.onDidPaste((e)=>{
            //     //console.log(e);
            //     setTimeout(()=>{
            //         formatCode();
            //     }, 100);
            // });
        }
    }
    
    function onChange(newValue: any) {
        setChangedValue(newValue);
    }

    function showModal() {
        const newWidth = Math.max(90, window.innerWidth * 0.95);
        setEditorWidth(`${newWidth}px`);
        setChangedValue(actualValue);
        setIsVisibleModal(true);
    }

    function handleCancel() {
        setIsVisibleModal(false);
    }

    function handleOk(){
        setIsVisibleModal(false);
        setActualValue(changedValue);
    }

    const handleSchemaCaptureSuccess = (response: CaptureSchemaResponse[]) => {
        const __optionsForSchemaCapture: any = {tables: [], openaisql: ''};
        let index = 0;
        const text:any[] = [];
        const keyvalue: any = {};
        const suggestion: any = [];
        const keyvalue1: any = {};

        response.map(nodeDataframeInfo => {
            const temp1 = [];
            const temp: any = {
                name: connectedNodesInfo[nodeDataframeInfo.componentId],
                //id: nodeDataframeInfo.componentId,
                id: `input${index}`, 
                fields: []
            };
            keyvalue[connectedNodesInfo[nodeDataframeInfo.componentId]] = `input${index}`;
            keyvalue1[`input${index}`] = connectedNodesInfo[nodeDataframeInfo.componentId]
            temp1.push("# ");
            // temp1.push(connectedNodesInfo[nodeDataframeInfo.componentId]);
            temp1.push(`input${index}`);
            temp1.push("(");
            if(nodeDataframeInfo.dataframes) {
                const r: string[] = [];
                temp.fields  = nodeDataframeInfo.dataframes.map(option => {
                    suggestion.push({
                        colName: option.name,
                        tableName: connectedNodesInfo[nodeDataframeInfo.componentId],
                        aTableName: `input${index}`
                    })
                    r.push(option.name + " " + option.dtypes);
                    return ({ name: option.name, type: 'text', fkTargetTables:[], 'constraint':null, nullable:false})
                });
                temp1.push(r.join(','));
            }
            temp1.push(")");
            text.push(temp1.join(''));
            index++;
            __optionsForSchemaCapture.tables.push(temp);
        });  
        
        __optionsForSchemaCapture.openaisql = text.join('\n');
        __optionsForSchemaCapture.suggestion = suggestion;
        __optionsForSchemaCapture.tablekeyvalue = keyvalue;
        __optionsForSchemaCapture.keyvalue1 = keyvalue1;

        // eslint-disable-next-line no-console
        console.log(__optionsForSchemaCapture)

        setLoadingSchemaCapture(false);
        setFailedSchemaCapture(false);
        setQueryData(__optionsForSchemaCapture);
        te.current = __optionsForSchemaCapture;
        (window as any).options_schema = __optionsForSchemaCapture;
    };

    const toggleSchemaCaptureInProgress = (obj: any) => {
        setLoadingSchemaCapture(obj);
    };

    const handleSchemaCaptureFailure = () => {
        setFailedSchemaCapture(true);
    };

    const { 
        initiateSchemaCapture, 
        connectedNodesInfo,
        sessionId
    } = useSchemaCapture({
        componentId: activeComponentId || '', 
        handleSchemaCaptureSuccess, 
        toggleSchemaCaptureInProgress, 
        handleSchemaCaptureFailure,
        activeExecutionEnv,
        workflowUserInfo: { userName: activeUserInfo.name, workflowName: activeTabInfo?.name || '' },
        clusterId: defaultClusterId,
        workflowConfig: activeTabInfo?.config
    });

    const _initiateSchemaCapture = () => {
        const result = initiateSchemaCapture();
        if(result){
            setLoadingSchemaCapture(true);
            setFailedSchemaCapture(false);
        }
    };

    function callback(key: string) {
        if(key === 'queryBuilder' || key === 'aqueryBuilder'){
            if(loadingSchemaCapture) return;
            if(Env.test_sqlbuilder){
                handleSchemaCaptureSuccess([] as any);
            } else {
                if(!queryData){
                    setLoadingSchemaCapture(true);
                    initiateSchemaCapture();
                }
            }
        }
    }

    const onEditorValueChange = (value: string) => {
        setActualValue(value);
    };

    const formatButton = (style = false) => {
        return (
            <span 
                className="maximize-img" 
                style={{
                    marginTop: style? -26 : 0
                }}
                onClick={()=>{
                    formatCode(style);
                }}>
                <Tooltip
                    key={'max-editor1'}
                    title={'Format'}
                    placement="bottom"
                    overlayClassName="tab__toolTip"
                >
                    <span style={{
                        color: '#6d6f7a',
                        fontSize: 15
                    }}>
                        {'{;}'}
                    </span>
                </Tooltip>
            </span>
        );
    };

    const handleViewLogs = () => {
        dispatch(handleShowCaptureSchemaLogs(sessionId, () => {
            setIsVisibleModal(false);
        }));
    };

    const clusterDetailsUpdate = (data: any, clusterMode: any) => {
        NewDatabricksHandlerClass.ClusterDetails(data, (_data) => {
            const extraData = values.extraData || {};
            extraData.clusterDetails = JSON.stringify(_data)
            extraData.clusterModeName = clusterMode && clusterMode.filter((r: any) => r.value === data.clusterModeId)[0].label
            setFieldValue('extraData' as never, extraData);
            setIsVisibleModal(false);
            setClusterDetail(data);
            setActualValue(JSON.stringify(data, null, 2));
            setShowClusterModel(false);
        }, (e)=>{
            errorAlert("Failed to get the details, please retry...");
        });
    };

    return (
        <div 
            className={classNames('inputfield__container', { [className]: !!(className) })}
            data-fieldlabelforvariable={label}
            data-fieldkey={props.name}
        >
            <div className="radiofield__inner__container sql_editor">
                <ShowWhenTrue show={!!(label) || usecase === 'cluster'}>
                    <label className='inputfield__label m-r-30' style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        {
                            usecase === 'cluster'? <span> &nbsp;</span> : (
                                <span className={required ? 'red-star' : ''}>
                                    {label}
                                </span>
                            )
                        }
                        { editor_type === 'sql' && textToSqlView !== true ? (
                            <div
                                className={classNames({
                                    hide: readOnly
                                })}
                            >
                                <span className="maximize-img" id="editor-max" onClick={showModal}>
                                    <Tooltip
                                        key={'max-editor'}
                                        title={'Maximize'}
                                        placement="bottom"
                                        overlayClassName="tab__toolTip"
                                    >
                                        <img width="20px" src="/icons/maximize-modal.png" alt="" />
                                    </Tooltip>
                                </span>
                                {formatButton()}
                            </div>
                        ) : ''}
                        {
                            usecase === 'cluster' ? (
                                <button 
                                    className='btn-yellow btn-sm'
                                    type='button'
                                    onClick={()=>{
                                        setShowClusterModel(true);
                                    }}>
                                        {
                                            currentClusterDetail?  'Edit Cluster Details' : 'Create Cluster Details'
                                        }
                                </button>
                            ) : null
                        }
                    </label>
                </ShowWhenTrue>
                { editor_type === 'sql' ?  (
                    <div>
                        <Modal
                            title={label}
                            visible={isVisibleModal}
                            width={'80vw'}
                            onOk={handleOk}
                            okText="Apply"
                            onCancel={handleCancel}
                            bodyStyle={{height: '550px', overflow: 'auto', paddingBottom: '5px'}}
                            okButtonProps={{ 
                                style: {
                                    backgroundColor: '#fcad01',
                                    boxShadow: '0 1px 4px 0 rgba(252, 173, 1, 0.72)',
                                    color: '#262231'
                                }
                            }}
                            cancelButtonProps={{ 
                                type: 'link',
                                style: {
                                    color: 'white'
                                }
                            }}

                            className="editorTab"
                        >
                            <Tabs 
                                tabBarGutter={0}
                                tabBarStyle={{
                                    padding: '0px'    
                                }}
                                defaultActiveKey="editor" 
                                size={'large'} 
                                onChange={callback}
                                className={'tabclass'}
                            >
                                <TabPane className={'Editor'} tab="Editor" key="editor">
                                    {formatButton(true)}
                                    <MonacoEditor
                                        height="490px"
                                        width={editorWidth}
                                        language="sql"
                                        theme={'vs-dark'}
                                        value={changedValue}
                                        options={editorOptions}
                                        onChange={onChange}
                                        editorDidMount={handleEditorDidMount}
                                    />
                                </TabPane>
                                {/* <TabPane className={'queryBuilder'} tab={<div id='aqueryBuilder'>AI SQL Builder </div>} key="aqueryBuilder">
                                    <div id={'aqueryBuilder'}>
                                        {
                                            failedSchemaCapture ? (
                                                <div style={{height: '490px', flexDirection: 'column'}} className="align-middle" >
                                                    <div>
                                                        Failed to fetch schema. <a onClick={()=>{_initiateSchemaCapture();}}> Retry</a>
                                                    </div>
                                                    <div>
                                                        <button className="btn-viewLog" onClick={handleViewLogs} type="button">
                                                            View log
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                        {
                                            loadingSchemaCapture ? (<div 
                                                className="align-middle"
                                                style={{height: '490px'}}>
                                                <InPageSpinner />
                                                <span style={{color:'white'}}>Fetching schema, please wait...</span>
                                            </div>) : null
                                        }
                                        {queryData && !failedSchemaCapture && !loadingSchemaCapture? (
                                            <AIQueryBuilder
                                                dataQuery={queryData}
                                                setSQLQuery={onChange}
                                            />
                                            ) : null
                                        }
                                    </div>
                                </TabPane> */}

                                {/* <TabPane className={'queryBuilder'} tab={<div id='queryBuilder'>Interactive Query Builder</div>} key="queryBuilder">
                                    <div style={{overflow: 'auto'}} id={'queryBuilder'}>
                                        {
                                            failedSchemaCapture ? (
                                                <div style={{height: '490px', flexDirection: 'column'}} className="align-middle" >
                                                    <div>
                                                        Failed to fetch schema. <a onClick={()=>{_initiateSchemaCapture();}}> Retry</a>
                                                    </div>
                                                    <div>
                                                        <button className="btn-viewLog" onClick={handleViewLogs} type="button">
                                                            View log
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                        {
                                            loadingSchemaCapture ? (<div 
                                                className="align-middle"
                                                style={{height: '490px'}}>
                                                <InPageSpinner />
                                                <span style={{color:'white'}}>Fetching schema, please wait...</span>
                                            </div>) : null
                                        }
                                        {queryData && !failedSchemaCapture && !loadingSchemaCapture? 
                                            (<QueryBuilder
                                                data={queryData}
                                                setSQLQuery={onChange}
                                            />) : null
                                        }
                                    </div>
                                </TabPane> */}
                               
                            </Tabs>
                        </Modal>
                    </div>
                ) : ''}
                {
                    usecase === 'cluster' ? ( 
                        currentClusterDetail ? (
                            <div style={{
                                marginTop: 10
                            }}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                Cluster Name &nbsp; 
                                            </td>
                                            <td> = </td>
                                            <td>
                                                &nbsp; {currentClusterDetail.clusterName}
                                            </td>
                                        </tr>
                                        {
                                            values.extraData?.clusterModeName ? (
                                                <tr>
                                                    <td>
                                                        Cluster Mode &nbsp; 
                                                    </td>
                                                    <td> = </td>
                                                    <td>
                                                        &nbsp; {values.extraData.clusterModeName }
                                                    </td>
                                                </tr>
                                            ) : null
                                        }
                                        {
                                            currentClusterDetail.clusterModeId !== '1' ? (
                                                <>
                                                    <tr>
                                                        <td>
                                                            Driver Type &nbsp; 
                                                        </td>
                                                        <td> = </td>
                                                        <td>
                                                            &nbsp; {currentClusterDetail.driverNodeTypeId}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Spark Version &nbsp; 
                                                        </td>
                                                        <td> = </td>
                                                        <td>
                                                            &nbsp; {currentClusterDetail.sparkRunTimeVersion}
                                                        </td>
                                                    </tr>
                                                </>
                                            ) : null
                                        }
                                    </tbody>
                                </table>
                            </div>
                        ) : <div style={{
                            textAlign: 'center',
                            margin: '1.5rem 0'
                        }}>No job cluster information provided</div> 
                    ) : (
                        <div className="editor_holder" style={{
                            minWidth: 220,
                            maxWidth: 350,
                            minHeight: 210
                        }}>
                            { Env.textToSQLProperty ? (
                                <TextToSQL 
                                    onEditorValueChange={(value => {
                                        onEditorValueChange(format(value))
                                    })}
                                    loadingSchemaCapture={loadingSchemaCapture}
                                    initiateSchemaCapture={initiateSchemaCapture}
                                    onToggle={(value) => SetTextToSqlView(value)}
                                    failedSchemaCapture={failedSchemaCapture}
                                    handleViewLogs={handleViewLogs}
                                    // sqlSchemaText={te.current?.openaisql}
                                    // queryListData={te.current?.suggestion}
                                    // queryData1={queryData}
                                >
                                    <span className="maximize-img" id="editor-max" onClick={showModal}>
                                        <Tooltip
                                            key={'max-editor'}
                                            title={'Maximize'}
                                            placement="bottom"
                                            overlayClassName="tab__toolTip"
                                        >
                                            <img width="20px" src="/icons/maximize-modal.png" alt="" />
                                        </Tooltip>
                                    </span>
                                    {formatButton()}
                                </TextToSQL>
                            ) : null}
                            <div style={{
                                minWidth: 220,
                                maxWidth: 350,
                                minHeight: 210,
                                width: 220,
                                height: 220
                            }}>
                                <MonacoEditor
                                    height="200px"
                                    width='350px'
                                    ref={mref}
                                    theme='vs-dark'
                                    language={editor_type}
                                    value={actualValue}
                                    options={editorOptions}
                                    onChange={onEditorValueChange}
                                    editorDidMount={handleEditorDidMount}
                                />
                            </div>
                        </div>
                    )
                }
            </div>
            {children}
            {
                usecase === 'cluster' ? (
                    <ClusterModal 
                        key='cluster-modal'
                        showModal={showClusterModal} 
                        toggleModal={()=>{
                            setShowClusterModel(false);
                        }}
                        {...(currentClusterDetail && {currentClusterDetail:currentClusterDetail})}
                        currentUser={currentUser}
                        isPipeline={true}
                        callback={clusterDetailsUpdate}
                        enabledWorkspace={enabledWorkspace}
                    /> 
                ) : null
            }
        </div>
    );
};