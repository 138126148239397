import { API } from '../../constants/settings';
import { HttpClient } from '@api/services';

class UserService {
    GetAdminInitStatus(key: any, callback: (arg0: any) => void) {
        HttpClient.Get('Get InitState', `${API.url}/v1/user-data/${key}`, callback);
    }
    SetStateForCounchMark(data: any, callback: (arg0: any) => void) {
        HttpClient.Post('Get InitState', `${API.url}/v1/user-data`, data, callback);
    }
    GetWorkflowForDataExplorer(key: string) {
        return HttpClient.GetPromise( `${API.url}/v1/user-data/${key}`)
    }
}


const InitHandler = new UserService(); 

export { InitHandler };