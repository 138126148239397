// import { TreeNodeInArray } from 'react-simple-tree-menu';
// import { _selectoptionType } from '../../components/form/select-field';
import { editor } from 'monaco-editor';

export const dataBrowserTabs = {
    connections: 'CONNECTIONS',
    catalogs: 'CATALOGS',
    datalakes: 'DATA LAKES'
};

// export const dataTabsList = Object.values(dataBrowserTabs);
export const dataTabsList = ['CONNECTIONS'];

export const dataBrowserInsideTabs = {
    table: 'Table',
    query: 'Query'
};

export const dataInsideTabsList = Object.values(dataBrowserInsideTabs);

export const queryBrowserTabs = {
    queryHistory: 'Query History',
    starredQueries: 'Starred Queries',
    logs: 'Logs'
};

export const queryBrowserTabsList = Object.values(queryBrowserTabs);

export const sqlEditorOptions: editor.IEditorConstructionOptions = {
    extraEditorClassName: 'sqlEditor',
    'selectOnLineNumbers': true,
    'contextmenu': false,
    'formatOnPaste': true,
    'accessibilitySupport': 'auto',
    'autoIndent': 'full',
    'folding': true,
    'highlightActiveIndentGuide': true,
    'quickSuggestions': true,
    'formatOnType': true,
    'minimap': {
        enabled: false,
    },
    fontSize: 12,
    letterSpacing: 0.25,
    lineNumbersMinChars: 3,
    lineDecorationsWidth: 0,
    lineHeight: 21,
    scrollbar:{
        verticalScrollbarSize: 0,
        horizontalScrollbarSize: 10,
        verticalSliderSize: 4,
        horizontalSliderSize: 4,
    },
    glyphMargin: false
};


export enum SourceTypes {
    "OSIPI" = "OSIPI",
    "OPCUA" = "OPCUA",
    "OPCHDA" = "OPCHDA",
    "AspenIP21" = "AspenIP21"
}

export enum ConnectionTypes {
    "NiFi Managed" = "Edge_Managed",
    'WITSML' = 'WITSML',
    "Snowflake" = 'Snowflake',
    "NiFi Managed FTP" = "Edge_Managed_FTP",
}

export const sourceTypeNameMapping: Record<string, string> = {
    "OPCUA": "OPC UA",
    "OSIPI": "OSI PI",
    "OPCHDA": "OPC HDA",
    AspenIP21: "Aspen IP.21"
}
