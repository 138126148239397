import React, {
    useState,
    useEffect
} from 'react';
import { useFormikContext } from 'formik';
import { replace, orderBy, uniqBy } from 'lodash';
import { FieldsCreator } from '../field-creator';
import { _selectoptionType } from '../../form/select-field';
import { SelectField as FormikSelectField } from '../../form';

const LoopRender: React.FC<{ _items: any; fieldData: any }> = ({ _items, fieldData }) => {
    const {  values } = useFormikContext<any>();
    const compoenentId = replace(_items.id, /-/g, '_');
    let options = _items ?.extras ?.formData ?.map((_o: any) => {
        return {
            value: _o.key,
            label: _o.templateOptions.label
        };
    }).filter((o: any)=>o.label !== '') || [];

    options = uniqBy(options, (e: any) => {
        return e.label;
    });

    options = orderBy(options, ['label']);

    const [items, setItems] = useState([] as any);
    useEffect(() => {
        const _items: any = [];
        const data = values[compoenentId] ?.split(',');
        data ?.forEach((element: any) => {
            const label = options.find((res: any) => res.value === element);
            if(label) {
                _items.push({
                    id: element,
                    label: label.label
                });
            }
        });
        setItems(_items);
    }, [_items]);

    function getComponent(item: any, ) {
        const _item: any = {
            key: `${compoenentId}__${item.id}`,
            type: 'input',
            readOnly: fieldData.readOnly,
            useCaptureSchema: {
                multipleSelect: true
            },
            templateOptions: {
                variable_type: 'string',
                required: true,
                label: item.label
            }
        };
        return (
            <FieldsCreator key={_item.key} formData={[_item]} />
        );
    }

    return (
        <>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '5px 36px 0px 16px'
            }}>
                <label className="inputfield__label">{_items.title}</label>
                {
                    fieldData.readOnly ? null : (
                        <FormikSelectField
                            label={
                                <img src="/icons/add_tab.svg" style={{
                                    width: 25,
                                    transform: 'scale(0.55)'
                                }} ></img>
                            }
                            className="no-padding no-border loop-dropdown"
                            name={compoenentId}
                            onOptionClick={(option: _selectoptionType) => {
                                const itemIndex = items.findIndex((item: any) => (item.id === option.value));
                                if (itemIndex === -1) {
                                    items.push({
                                        id: option.value,
                                        label: option.label
                                    });
                                } else {
                                    items.splice(itemIndex, 1);
                                }
                                setItems(items);
                            }}
                            options={options}
                            multiple_select
                            hideInputField
                        />
                    )
                }
            </div>
            <div>
                {items?.map((_it: any) => {
                    return (
                        getComponent(_it)
                    );
                })}
                {
                    (!items || items ?.length === 0 ) ? <div className="inputfield__label text-center p-5">No Components Added</div> : ''
                }
            </div>
        </>
    );
};

export default LoopRender;