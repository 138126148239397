import React from 'react'
import GenLayout from './layout'
import { GenAIProvider } from './useGenAIContext'

const GenAI:React.FC<{}> = () => {
    return (
      <GenLayout />
    )
  }
  
  export default GenAI