import { useFormikContext } from "formik";
import { cloneDeep, get, isEmpty, isEqual, merge, set } from "lodash";
import React, { useRef, useMemo, useEffect, useReducer } from "react";
import { useDidUpdate } from "rooks";
import { ShowWhenTrue } from "../../../helpers";
import { useAppSelector } from "../../../store/hooks";
import { ActiveComponentInfo } from "../../../store/workflow";
import { SelectField } from "../../form";
import { SelectFieldProps, _selectoptionType } from "../../form/select-field";
import { WorkflowCanvas } from "../../workflow-canvas";
import { DsNodeModel } from "../../workflow-canvas/node/ds_nodemodel";
import {
	ComponentFieldsInfo,
	VariableComponentMappingInfo,
	variableFormStateReducer,
	variableFormStateReducerInitialState,
	WorkflowConfigMappingInfo,
	WorkflowConfigMappingInFormikContext,
} from "./reducer";
import {
	getVariablePlaceholder,
	getWorkflowConfigItemDropdownKey,
	getWorkflowConfigItemPlaceholder,
	updateConnectedComponentFieldValues,
} from "./utils";
import { SelectFieldUsingPortal } from "@components/form/select-field-portal";
import { SearchIcon } from "@pages/workflow-page/assets/common";
import { Collapse } from "antd";
import { WorkflowCanvasTabInfo } from "@store/canvas";
import { useDebounce } from "rooks5";
import { WorkflowConfig } from "@services/WorkflowConfig";
import { ExecutionEnvModes } from "@constants/enums";

let _prevActiveComponentInfo: ActiveComponentInfo | null = null;

const setLinkState = (linkId: string, isVariableSelected: boolean) => {
	const linkInfo = WorkflowCanvas.getLink(linkId);
	if (linkInfo) {
		if (isVariableSelected) {
			linkInfo.setColor("normal");
		} else {
			linkInfo.setColor("dotted");
		}
	}
};

const WorkflowConfigVariablePortFields: React.FC<{
	propertiesFieldsRef: React.RefObject<HTMLDivElement>;
	isReadOnly: boolean;
	activeTabInfo: WorkflowCanvasTabInfo;
}> = ({ propertiesFieldsRef, isReadOnly, activeTabInfo }) => {
	const activeComponentInfo = useAppSelector(
		(store) => store.WorkflowReducer.activeComponentInfo
	);

	const { values, setValues } = useFormikContext<any>();

	const [
		{ componentFieldsArr, variablesMappingInfo, workflowConfigMappingInfo },
		dispatch,
	] = useReducer(
		variableFormStateReducer,
		variableFormStateReducerInitialState
	);

	// string is the varName
	const componentFieldsRef = useRef<Record<string, SelectField | null>>({});

	const activeNodeInfo: DsNodeModel | undefined = useMemo(() => {
		if (activeComponentInfo) {
			return WorkflowCanvas.getNode(activeComponentInfo.id);
		}
	}, [activeComponentInfo]);

	const parseComponentProperties = useDebounce(
		(
			currentFormFieldsInfo: ComponentFieldsInfo[],
			activeComponentInfo: ActiveComponentInfo | null,
			workflowConfig: WorkflowConfig,
			variableMappingInFormikContext: any,
			workflowConfigMappingInFormikContext: WorkflowConfigMappingInFormikContext
		) => {
			if (
				propertiesFieldsRef.current &&
				activeNodeInfo &&
				activeComponentInfo
			) {
				dispatch({
					type: "SET_COMPONENT_FIELDS_ARR",
					payload: {
						propertiesFieldsContainerRef:
							propertiesFieldsRef.current,
						currentFormFieldsInfo,
						activeNodeInfo,
						variableMappingInFormikContext,
						workflowConfigMappingInFormikContext,
						workflowConfig,
						isSourcePortAffected:
							_prevActiveComponentInfo?.isVariableSourcePortConnected !==
							activeComponentInfo.isVariableSourcePortConnected,
					},
				});
				_prevActiveComponentInfo = activeComponentInfo;
			}
		},
		500
	);

	useEffect(() => {
		parseComponentProperties(
			componentFieldsArr,
			activeComponentInfo,
			activeTabInfo.config,
			get(values, "extraData.workflow_var_mapping") || {},
			get(values, "extraData.workflow_config_mapping") || {}
		);
	}, [values, activeComponentInfo, activeTabInfo.config]);

	const updateComponentFieldValuesInFormikContext = (
		variablesMappingInfo: VariableComponentMappingInfo[],
		workflowConfigMappingInfo: WorkflowConfigMappingInfo[]
	) => {
		let listOfSelectedFields: string[] = [];
		const __updatedValues: Record<string, any> = cloneDeep(values);
		// update variables state
		variablesMappingInfo.forEach((varMap) => {
			const varPlaceholder = getVariablePlaceholder(varMap.name);
			const {
				isItemSelected,
				triggerDropdownValidation,
			} = updateConnectedComponentFieldValues(
				varMap.connectedComponentFields,
				varPlaceholder,
				__updatedValues,
				values,
				listOfSelectedFields
			);

			if (triggerDropdownValidation) {
				componentFieldsRef.current?.[varMap.name]?.revalidateOptions();
			}
			setLinkState(varMap.linkId, isItemSelected);
		});

		set(
			__updatedValues,
			"extraData.workflow_var_mapping.all_fields_list",
			Array.from(new Set(listOfSelectedFields))
		);

		listOfSelectedFields = [];
		// update workflow config state
		workflowConfigMappingInfo.forEach((workflowConfigItem) => {
			const workflowConfigItemPlaceholder = getWorkflowConfigItemPlaceholder(
				workflowConfigItem.key
			);
			updateConnectedComponentFieldValues(
				workflowConfigItem.connectedComponentFields,
				workflowConfigItemPlaceholder,
				__updatedValues,
				values,
				listOfSelectedFields
			);

			// if (triggerDropdownValidation) {
			// 	componentFieldsRef.current?.[varMap.name]?.revalidateOptions();
			// }
		});

		set(
			__updatedValues,
			"extraData.workflow_config_mapping.selectedFieldKeys",
			Array.from(new Set(listOfSelectedFields))
		);

		//  Instead of updating fields separately, entire values obj is updated at once
		if (!isEqual(__updatedValues, values)) {
			setValues(__updatedValues);
		}
	};

	// useDidUpdate instead of useEffect to make sure updateComponentFieldValuesInFormikContext is skipped during mounting
	useDidUpdate(() => {
		updateComponentFieldValuesInFormikContext(
			variablesMappingInfo,
			workflowConfigMappingInfo
		);
	}, [variablesMappingInfo, workflowConfigMappingInfo]);

	function handleSelectVariable(
		selectionType: "workflowConfig" | "variable",
		mapping: VariableComponentMappingInfo | WorkflowConfigMappingInfo,
		option: _selectoptionType,
		selectedOptions?: Record<string, any>,
		isFieldSelected?: boolean
	) {
		if (selectedOptions && isFieldSelected !== undefined) {
			dispatch({
				type:
					"UPDATE_COMPONENT_FIELD_SELECTION_OF_VARIABLE_OR_WORKFLOW_CONFIG_ITEM",
				payload: {
					label:
						selectionType === "variable"
							? (mapping as VariableComponentMappingInfo).name
							: (mapping as WorkflowConfigMappingInfo).key,
					selectionType,
					componentFieldId:
						mapping.connectedComponentFields[option.value].id,
					isFieldSelected,
				},
			});
		}
	}

	const activeOptions = useMemo(() => {
		return componentFieldsArr
			.filter((field) => !field.hidden)
			.map((field) => ({ ...field, value: field.id }));
	}, [componentFieldsArr]);

	const workflowConfigItems = useMemo(() => {
		return (
			activeTabInfo.config?.map((configItem) => ({
				configItem,
				label: configItem.key,
				value: configItem.key,
			})) || []
		);
	}, [activeTabInfo.config]);

	const onWorkflowConfigItemSelect = (
		...args: Parameters<NonNullable<SelectFieldProps["onOptionClick"]>>
	) => {
		if (args[2] === false) {
			const workflowConfigItemKey = args[0].value;
			// Clear selected fields of the unselected workflow config item
			dispatch({
				type: "REMOVE_FIELD_SELECTION_OF_WORKFLOW_CONFIG_ITEM",
				payload: workflowConfigItemKey,
			});
		}
	};

	return (
		<>
			<ShowWhenTrue
				show={
					!isEmpty(variablesMappingInfo) &&
					!!activeNodeInfo?.hasVariableInputPort
				}
			>
				<div className="variableMapping__box">
					<span>Variable link</span>
					{variablesMappingInfo.map((varComp, index) => (
						<SelectField
							key={varComp.name + index}
							name={
								"extraData.workflow_var_mapping." + varComp.name
							}
							label={varComp.name + " - Variable input mapping"}
							options={activeOptions}
							onOptionClick={(...args) =>
								handleSelectVariable(
									"variable",
									varComp,
									...args
								)
							}
							ref={(ref) =>
								(componentFieldsRef.current[varComp.name] = ref)
							}
							disabled={isReadOnly}
							multiple_select
						/>
					))}
				</div>
			</ShowWhenTrue>
			{/* Pipelines uses workflow config in a different way */}
			{!isEmpty(workflowConfigItems) && activeTabInfo.env !== ExecutionEnvModes.Pipelines && (
				<Collapse
					defaultActiveKey={["1"]}
					className="workflowConfig__container"
					expandIconPosition="right"
				>
					<Collapse.Panel
						header={
							<div className="workflowConfig__header">
								Run Configurations
								<SearchIcon />
							</div>
						}
						key="1"
					>
						<SelectFieldUsingPortal
							options={workflowConfigItems}
							name="extraData.workflow_config_mapping.selectedConfigKeys"
							placeholder="Add Configurations"
							className="workflowConfig__mappingDropdown"
							onOptionClick={onWorkflowConfigItemSelect}
							multiple_select
						/>
						{workflowConfigMappingInfo.map((workflowConfigItem) => {
							return (
								<SelectFieldUsingPortal
									key={workflowConfigItem.key}
									options={activeOptions}
									name={getWorkflowConfigItemDropdownKey(
										workflowConfigItem.key
									)}
									label={workflowConfigItem.key}
									disabled={isReadOnly}
									placeholder="Select Fields"
									onOptionClick={(...args) =>
										handleSelectVariable(
											"workflowConfig",
											workflowConfigItem,
											...args
										)
									}
									multiple_select
								/>
							);
						})}
					</Collapse.Panel>
				</Collapse>
			)}
		</>
	);
};

export default WorkflowConfigVariablePortFields;
