import React from 'react';

export const CaretDown = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M18 10.4271L16.59 9L12 13.6356L7.41 9L6 10.4271L12 16.5L18 10.4271Z" fill="#C7C7CD"/>
    </svg>
);

export const ArrowRight = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 8H13.5" stroke="#0F121F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 3.5L13.5 8L9 12.5" stroke="#0F121F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)