import React, { useState, useMemo, useEffect } from 'react';
import { Modal } from '../../../components/modals';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/types';
import Form, { SelectField, InputField } from '../../../components/form';
import { schemaTypes, formSchemaForNfs, formSchemaForNoSqlRelational, formPIServerSchema, formSchemaWitsml, formOpcuaSchema, formSchemaAPI, azureBlobSchema } from './old-form-schema';
import { ShowWhenTrue } from '../../../helpers';
import { useFormikContext } from 'formik';
import { _selectoptionType } from '../../../components/form/select-field';
import { DataSourceHandler, DatabaseTypeInfo } from '../../../api/data-source-handler';
import isEmpty from 'lodash/isEmpty';
import { InPageSpinner } from '../../../components/spinners/in-page-spinner';
import classNames from 'classnames';
import { AxiosResponse } from 'axios';
import { errorAlert,successAlert } from '../../../components/toastify/notify-toast';
import { toggleDataBrowserModal } from '../../../store/datasources';
import { useDidUpdate } from 'rooks';
import { DynamicKeyValueFieldGenerator } from '../../../components/form/dynamic-field-generator';
// import moment from 'moment-timezone';
import { FieldSchemaCreator, FieldSchemaValidator } from '../../../components/formcreators/schema-creator';
import { convertEnumsToOptions } from '../../../utils';
import { ToggleField } from '../../../components/form/toggle-field';
import { startCase } from 'lodash';
import { Env } from '../../../constants/settings';
import { ScheduleFields, ScheduleTiming, ScheduleTypes } from '../../workflow-page/modals/schedule-workflow';

interface DatabaseTypeListResponse {
	[type: string]: DatabaseTypeInfo[];
}


const requestTypes = [
    { label: 'POST', value: 'POST' },
    { label: 'GET', value: 'GET' },
    { label: 'PUT', value: 'PUT' },
    { label: 'DELETE', value: 'DELETE' }
];

export enum AuthTypes {
    None = 'None',
    'Basic Auth' = 'basicAuth' 
}

export enum ConnectionScheduleTypes {
    'minutes' = 'minutes',
    'hourly' = 'hourly',
    'daily' = 'daily',
    'weekly' = 'weekly',
    'monthly' = 'monthly'
}

export enum AzureAccessType {
    'Access Key' = 'access_key',
    'SAS Token' = 'sas_token'
}

const azureAccessTypeOptions = convertEnumsToOptions(AzureAccessType);


const scheduleTypesToConnectionScheduleTypes: Record<ScheduleTypes, ConnectionScheduleTypes> =  {
    'Minute': ConnectionScheduleTypes.minutes,
    'Hour': ConnectionScheduleTypes.hourly,
    'Day': ConnectionScheduleTypes.daily,
    'Week': ConnectionScheduleTypes.weekly,
    'Month': ConnectionScheduleTypes.monthly
};

const connectionScheduleTypesToScheduleTypes: Record<ConnectionScheduleTypes, ScheduleTypes> =  {
    [ConnectionScheduleTypes.minutes]: ScheduleTypes.Minute,
    [ConnectionScheduleTypes.hourly]: ScheduleTypes.Hour,
    [ConnectionScheduleTypes.daily]: ScheduleTypes.Day,
    [ConnectionScheduleTypes.weekly]: ScheduleTypes.Week,
    [ConnectionScheduleTypes.monthly]: ScheduleTypes.Month
};




export const authTypeOptions = convertEnumsToOptions(AuthTypes);

const initialValues: schemaTypes = {
    name: '', host: '', port: '', username: '', password: '', connectionType: 'API', sudoName: '', databaseType: '',
    url: '', databaseName: '', driver: '', connectionStringOption: '', oracleServiceName: '' ,
    scheduleType: ScheduleTypes.Hour, weekOfTheMonth: 1, 
    days: 1, hrs: 1, mins: 0, months: 1, weekDays: '0', 
    headers: {}, queryparam: {}, serverUrl: '', requestType: requestTypes[1].value,
    authType: AuthTypes['Basic Auth'], formData: {}, scheduleAdvancedMode: false, responseTokenKey: '', 
    accessTypeName: AzureAccessType['Access Key'], accountName: '', accountKeyOrSasToken:'',
    scheduleTimingInfo: ScheduleTiming.Recurring,
    cronExpression: "0 * * * *", // Runs every 0th minute of the 1st hour - default value on the basis of days hours mins fields
};


interface NewConnectionFormProps {
    connectionTypes: _selectoptionType[];
    handleConnectionTypeSelection: (arg0: _selectoptionType) => any;
    isConnectionTypeNfs: boolean;
    isPIServer: boolean;
    isWitsml: boolean;
    isOpcua: boolean;
    isAPI: boolean;
    isAzureBlob: boolean;
    currentDatabaseOptions: _selectoptionType[];
}


const NewConnectionForm: React.FC<NewConnectionFormProps> = ({ connectionTypes, handleConnectionTypeSelection, isConnectionTypeNfs, isPIServer, isWitsml, isOpcua, isAPI, isAzureBlob, currentDatabaseOptions }) => {
    const { values, setFieldValue } = useFormikContext<schemaTypes>();

    useEffect(() => {
        const selectedDatabaseType = currentDatabaseOptions.find(_option => _option.value === values.databaseType) as _selectoptionType & DatabaseTypeInfo;
        
        if (selectedDatabaseType ?.url) {
            let _adjustedUrl = selectedDatabaseType.url;
            if (values.host) _adjustedUrl = _adjustedUrl.replace('<hostname>', values.host);
            if (values.port) _adjustedUrl = _adjustedUrl.replace('<port>', values.port.toString());
            if (values.databaseName) _adjustedUrl = _adjustedUrl.replace('<database_name>', values.databaseName);
            if (values.databaseType === 'Oracle' && values.oracleServiceName) _adjustedUrl = _adjustedUrl.replace('<oracle_service_name>', values.oracleServiceName);
            if (values.connectionStringOption) _adjustedUrl += '?' + values.connectionStringOption;
            setFieldValue('url', _adjustedUrl);
            setFieldValue('driver', selectedDatabaseType.driver);
        }
    }, [values.host, values.port, values.databaseName, values.databaseType, values.connectionStringOption, values.oracleServiceName]);

    // const handleFileUpload = (event: React.FormEvent<HTMLInputElement>) => {
    //     const a = event;
    // };

    // const formatTime = (time: string): string => {
    //     return moment(time).format('LLL');
    // };

    const handleAccessTypeClick = () => {
        // reset accountKeyOrSasToken on option change
        setFieldValue('accountKeyOrSasToken', '');
    };

    return (
        <>
            <SelectField
                name="connectionType"
                label="Data Connection Type"
                className="connectionType"
                options={connectionTypes}
                onOptionClick={handleConnectionTypeSelection}
            />
            <div className="horizontalSeparator" />
            <div
                className="innerConnectionForm"
            >
                <InputField
                    label="Name your connection"
                    name="name"
                    autoComplete="off"
                    required
                />
                <ShowWhenTrue show={!(isConnectionTypeNfs || isPIServer || isWitsml || isAPI || isAzureBlob)}>
                    <div className={classNames({ '_form--halfwide': !isOpcua })}>
                        <SelectField
                            label={isOpcua ? 'Source Type' : 'Database Type'}
                            name="databaseType"
                            options={currentDatabaseOptions}
                        />
                        <ShowWhenTrue show={!isOpcua}>
                            {values.databaseType === 'Oracle' ?
                                <InputField
                                    label="Service name"
                                    name="oracleServiceName"
                                    autoComplete="off"
                                    validate={FieldSchemaValidator(FieldSchemaCreator({ variable_type: 'string', required: true }) as any)}
                                    required
                                />
                                :
                                <InputField
                                    label="Database name"
                                    name="databaseName"
                                    autoComplete="off"
                                    required
                                />
                            }
                        </ShowWhenTrue>
                    </div>
                    <ShowWhenTrue show={values.databaseType === 'Oracle'}>
                        <InputField
                            label="Database name"
                            name="databaseName"
                            autoComplete="off"
                        />
                    </ShowWhenTrue>
                </ShowWhenTrue>
                <ShowWhenTrue show={!isWitsml && !isOpcua && !isAPI && !isAzureBlob}>
                    <div className="_form--halfwide">
                        <InputField
                            label="Host"
                            name="host"
                            placeholder="Enter Host"
                            required
                        />
                        <InputField
                            label="Port"
                            name="port"
                            type="number"
                            placeholder="Enter port"
                            required
                        />
                    </div>
                </ShowWhenTrue>
                <ShowWhenTrue show={isWitsml || isOpcua}>
                    <InputField
                        label="Url"
                        name="url"
                        placeholder="Enter Url"
                        required
                    />
                </ShowWhenTrue>
                <ShowWhenTrue show={isAPI}>
                    <InputField
                        label="Server URL"
                        name="serverUrl"
                        placeholder="Enter Host Address"
                    />
                    <InputField
                        label="Token URL"
                        name="url"
                        placeholder="Enter Url"
                        required
                    />

                    <div className="_form--halfwide">
                        <SelectField
                            name="requestType"
                            label="Request Type"
                            className="connectionType"
                            options={requestTypes}
                        // onOptionClick={handleConnectionTypeSelection}
                        />
                        <SelectField
                            name="authType"
                            label="Authentication Type"
                            className="connectionType"
                            options={authTypeOptions}
                        />
                    </div>
                </ShowWhenTrue>
                <ShowWhenTrue show={isAzureBlob}>
                    <SelectField
                        name="accessTypeName"
                        label="Type of Access"
                        className="connectionType"
                        options={azureAccessTypeOptions}
                        onOptionClick={handleAccessTypeClick}
                        required
                    />
                    <div className="_form--halfwide">
                        <InputField
                            label='Storage Account'
                            name="accountName"
                            placeholder='Enter storage account name'
                            required
                        />
                        <InputField
                            label={values.accessTypeName === AzureAccessType['Access Key'] ? 'Access Key': 'SAS Token'}
                            name="accountKeyOrSasToken"
                            placeholder={values.accessTypeName === AzureAccessType['Access Key'] ? 'Enter access key': 'Enter SAS token'}
                            required
                            type="password"
                        />
                    </div>
                </ShowWhenTrue>

                <ShowWhenTrue show={!isOpcua &&  !isAzureBlob && (isAPI ? values.authType === AuthTypes['Basic Auth']: true)}>
                    <div className={classNames({'credentials__box': isAPI})}>
                        {isAPI && 
                            <label 
                                className="inputfield__label credentials__label"
                            >
                                Credentials
                            </label>
                        }
                        <div className="_form--halfwide">
                            <>
                                <InputField
                                    label='Username'
                                    name="username"
                                    placeholder='Enter username'
                                    required
                                />
                                <InputField
                                    label='Password'
                                    name="password"
                                    placeholder='Enter password'
                                    type="password"
                                />
                            </>
                        </div>
                    </div>
                </ShowWhenTrue>
                <ShowWhenTrue show={!isPIServer && !isWitsml && !isOpcua && !isAzureBlob}>
                    <ShowWhenTrue show={!isAPI}>
                        <InputField
                            label="Url"
                            name="url"
                            required
                        />
                    </ShowWhenTrue>
                    <InputField
                        label="Additional Parameters"
                        name="connectionStringOption"
                    />
                </ShowWhenTrue>
                <ShowWhenTrue show={isAPI}>
                    {/* <InputField
                        label="Token"
                        name="token"
                        placeholder="Enter Token"
                    /> */}
                    {/* <div className="_form--halfwide">
                        <SelectField
                            name="contentType"
                            label="Content Type"
                            className="connectionType"
                            options={contentTypes}
                        />
                        <SelectField
                            name="authType"
                            label="Authentication Type"
                            className="connectionType"
                            options={authTypeOptions}
                        />
                    </div> */}
                    <InputField
                        label="Token attribute"
                        name="responseTokenKey"
                        placeholder="Key to access the token"
                        infoText="This should match the access token attribute in api response"
                        required
                    />
                    <ToggleField
                        label="Schedule Options"
                        active={values.scheduleAdvancedMode}
                        onClick={() => setFieldValue('scheduleAdvancedMode', !values.scheduleAdvancedMode)}
                    />
                    <ShowWhenTrue show={values.scheduleAdvancedMode}>
                        <ScheduleFields
                            showAllMinutes  
                            showScheduleFormAtAllTimes
                            disableMultiWeekDaySelection
                            hideScheduleType
                        />
                    </ShowWhenTrue>
                    <DynamicKeyValueFieldGenerator
                        keyToSaveKeyValueInfo="formData"
                        addButtonText="+ Form Data"
                    />
                    <DynamicKeyValueFieldGenerator
                        keyToSaveKeyValueInfo="headers"
                        addButtonText="+ Headers"
                    />
                    <DynamicKeyValueFieldGenerator
                        keyToSaveKeyValueInfo="queryparam"
                        addButtonText="+ Query Parameters"
                    />
                    
                </ShowWhenTrue>
            </div>
            {/* <ShowWhenTrue show={isOpcua}>
                <div className="uploadFileBox">
                    <label className="inputField__label" htmlFor="fileUpload">Upload certificate</label>
                    <input 
                        type="file"
                        id="fileUpload"
                        accept="image/png, image/jpeg" 
                        onChange={handleFileUpload}
                    />
                </div>
            </ShowWhenTrue> */}
        </>
    );
};

export interface NewEditConnectionProps {
    onCreateConnSuccess: () => any;
    actionType: 'NEW' | 'EDIT';
    connectionDataForEdit: any;
}

export const OldNewEditConnectionModal: React.FC<NewEditConnectionProps> = ({ onCreateConnSuccess, connectionDataForEdit, actionType }) => {
    const { newEditConnection: showModal } = useSelector((store: RootState) => store.NewDataSourceReducer.showModal);
    const [connectionTypes, setConnectionTypes] = useState<_selectoptionType[]>([]);
    const [connectionTypesInfo, setConnectionTypesInfo] = useState<DatabaseTypeListResponse>({});
    const [currentDatabaseOptions, setDatabaseOptions] = useState<_selectoptionType[]>([]);
    const [additionalInitialValues, setAdditionalInitialvalues] = useState<Record<any, any>>({});
    const [isConnectionTypeNfs, setIsConnectionTypeNfs] = useState(false);
    const [isPIServer, setIsPIServer] = useState(false);
    const [isWitsml, setIsWitsml] = useState(false);
    const [isAPI, setIsAPI] = useState(false);
    const [isOpcua, setIsOpcua] = useState(false);
    const [isAzureBlob, setIsAzureBlob] = useState(false);
    const [showSpinner, toggleSpinner] = useState(false);
    const __initialValues = useMemo(() => ({ ...initialValues, ...additionalInitialValues }), [initialValues, additionalInitialValues]);
    // const [___initialValues, set___initialValues] = useState<Record<any,any>>(__initialValues);
    const dispatch = useDispatch();

    const handleSetOptionsForConnectionsAndDatabases = (response: DatabaseTypeListResponse) => {
        if (!isEmpty(response)) {
            let _connectionTypes: any = [];
            if(Env.dataSourceList === 'ALL'){
                _connectionTypes = Object.keys(response).map(_val => ({ label: startCase(_val), value: _val }));
            } else {
                const obj: any = {};
                Env.dataSourceList.split(',').forEach((key: string | number) => {
                    obj[key] = response[key];
                });
                _connectionTypes = Object.keys(obj).map(_val => ({ label: startCase(_val), value: _val }));
            }
            setConnectionTypes(_connectionTypes);
            setConnectionTypesInfo(response);

            if (actionType === 'NEW') {
                const firstConnectionType = _connectionTypes[0].label;
                const _databaseOptions: _selectoptionType[] = response[firstConnectionType].map(_opt => ({ label: _opt.databaseType, value: _opt.databaseType, ..._opt }));
                setDatabaseOptions(_databaseOptions);
                const firstDatabaseType = !isEmpty(_databaseOptions) ? _databaseOptions[0].value : '';
                setAdditionalInitialvalues({ connectionType: firstConnectionType, databaseType: firstDatabaseType });
            } else {
                if (connectionDataForEdit.connectionType && connectionDataForEdit.databaseType) {
                    const _databaseOptions: _selectoptionType[] = response[connectionDataForEdit.connectionType].map(_opt => ({ label: _opt.databaseType, value: _opt.databaseType, ..._opt }));
                    setDatabaseOptions(_databaseOptions);
                }
            }
        }
    };

    useEffect(() => {
        if (showModal) DataSourceHandler.GetDatabaseTypeList(handleSetOptionsForConnectionsAndDatabases as any);
        else {
            setAdditionalInitialvalues({});
            setIsWitsml(false);
            setIsPIServer(false);
            setIsOpcua(false);
            setIsAzureBlob(false);
        }
    }, [showModal]);

    useDidUpdate(() => {
        if (actionType === 'EDIT' && showModal) {
            setAdditionalInitialvalues(connectionDataForEdit);
            if(connectionDataForEdit.scheduleType) {
                connectionDataForEdit.scheduleType = connectionScheduleTypesToScheduleTypes[connectionDataForEdit.scheduleType as ConnectionScheduleTypes];
                connectionDataForEdit.days = connectionDataForEdit.day;
                if(connectionDataForEdit.scheduleType === ScheduleTypes.Week && connectionDataForEdit.day) {
                    // scheduleFields days start from 0, backend days start from 1
                    // hence 1 is added while generating the payload
                    connectionDataForEdit.weekDays = (connectionDataForEdit.day - 1).toString();

                }
            }
            switch (connectionDataForEdit.connectionType) {
                case 'WITSML':
                    setIsWitsml(true);
                    break;
                case 'PI':
                    setIsPIServer(true);
                    break;
                case 'OPCUA':
                    setIsOpcua(true);
                    break;
                case 'API':
                    setIsAPI(true);
                    break;
                case 'AzureBlobStorage':
                    setIsAzureBlob(true);
                    break;
                default:
                    setIsWitsml(false);
                    setIsPIServer(false);
                    setIsOpcua(false);
                    setIsAzureBlob(false);
            }
        }
    }, [connectionDataForEdit, showModal]);

    const toggleClose = () => {
        dispatch(toggleDataBrowserModal('newEditConnection', false));
    };

    const handleNewConnectionSuccess = () => {
        if(actionType === 'NEW') successAlert('New Connection Created');
        if(actionType === 'EDIT') successAlert('Connection Updated');
        onCreateConnSuccess();
        toggleSpinner(false);
        toggleClose();
    };

    const handleNewConnectionFailure = (response: AxiosResponse) => {
        errorAlert(response.data.message ? response.data.message: response.data);
        toggleSpinner(false);
    };

    const handleConnectionInfoSubmit = (values: schemaTypes) => {
        let payload: Partial<schemaTypes> = {
            'name': values.name,
            'connectionType': values.connectionType,
            'databaseType': values.databaseType,
            driver: values.driver,
            connectionStringOption: values.connectionStringOption
        };
        toggleSpinner(true);
        let scheduleObj: any = {};

        if(values.connectionType === 'AzureBlobStorage') {
            payload = {
                ...payload,
                accessTypeName: values.accessTypeName,
                accountName: values.accountName,
                accountKeyOrSasToken: values.accountKeyOrSasToken
            };
        } else {
            payload = { 
                ...payload,
                'url': values.url,
                'password': values.password,
                'username': values.username,
                'headers': values.headers,
                // 'token':values.token,
                'queryparam':values.queryparam,
                'formData':values.formData,
                'requestType':values.requestType,
                'serverUrl': values.serverUrl,
                'scheduleAdvancedMode' :values.scheduleAdvancedMode,
                'authType': values.authType,
                responseTokenKey: values.responseTokenKey,
                host: values.host,
                port: values.port,
                databaseName: values.databaseName
            };
        }
        if (values.connectionType === 'WITSML') {
            values.databaseType = 'WITSML';
        }
        // if(!!values.scheduleType && scheduleTypesToConnectionScheduleTypes[values.scheduleType as ScheduleTypes]) {
        //     payloadReq.scheduleType = scheduleTypesToConnectionScheduleTypes[values.scheduleType as ScheduleTypes];
        // } else {
        //     payloadReq.scheduleType = scheduleTypesToConnectionScheduleTypes.Hour;
        // }
        if (values.connectionType === 'API') {
            values.databaseType = 'API';

            if(values.authType !== AuthTypes['Basic Auth']) {
                // @ts-ignore
                payload.username = null;
                // @ts-ignore
                payload.password = null;
            }

            if(values.scheduleAdvancedMode) {
                scheduleObj.scheduleType = scheduleTypesToConnectionScheduleTypes[values.scheduleType as ScheduleTypes] || scheduleTypesToConnectionScheduleTypes.Hour;
                scheduleObj.mins = values.mins;
                scheduleObj.cronExpression = values.cronExpression;
                
                if(values.scheduleType === ScheduleTypes.Hour) {
                    scheduleObj.hrs = values.hrs; 
                } else if(values.scheduleType === ScheduleTypes.Day) {
                    scheduleObj = {
                        ...scheduleObj,
                        'hrs':values.hrs,
                        'day': values.days
                    };
                } else if (values.scheduleType === ScheduleTypes.Week) {
                    scheduleObj = {
                        ...scheduleObj,
                        'hrs':values.hrs,
                        // weekDays in the from start from 0 - Sunday : 0
                        // backend's sunday starts from 1 
                        'day': parseInt(values.weekDays as string) + 1
                    };
                } else if (values.scheduleType === ScheduleTypes.Month) {
                    scheduleObj = {
                        ...scheduleObj,
                        'hrs':values.hrs,
                        'day': values.days,
                        'weekOfTheMonth': 1
                    };
                }
            }            

            payload = {...payload,...scheduleObj};
        }
        if (actionType === 'NEW') {
            DataSourceHandler.CreateConnection(payload as any, handleNewConnectionSuccess, handleNewConnectionFailure);
        } else {
            DataSourceHandler.UpdateConnection(connectionDataForEdit.id, payload as any, handleNewConnectionSuccess, handleNewConnectionFailure);
        }
    };

    const validationSchema = useMemo(() => {
        if (isConnectionTypeNfs)
            return formSchemaForNfs;
        if (isPIServer) {
            return formPIServerSchema;
        }
        if (isWitsml) {
            return formSchemaWitsml;
        }
        if (isOpcua) {
            return formOpcuaSchema;
        }
        if (isAPI) {
            return formSchemaAPI;
        }
        if(isAzureBlob) {
            return azureBlobSchema;
        }
        return formSchemaForNoSqlRelational;
    }, [isConnectionTypeNfs, isPIServer, isWitsml, isOpcua, isAPI, isAzureBlob]);

    const handleConnectionTypeSelection = (option: _selectoptionType) => {
        if (!isEmpty(connectionTypesInfo[option.value])) {
            const _databaseOptions: _selectoptionType[] = connectionTypesInfo[option.value].map(_val => ({ label: _val.databaseType, value: _val.databaseType, ..._val }));
            setDatabaseOptions(_databaseOptions);
            setIsPIServer(option.value === 'PI');
            setIsWitsml(option.value === 'WITSML');
            setIsOpcua(option.value === 'OPCUA');
            setIsAPI(option.value === 'API');
            setIsAzureBlob(option.value === 'AzureBlobStorage');
            if (!isEmpty(_databaseOptions)) {
                // automatically select first option on database change
                setAdditionalInitialvalues({ ...additionalInitialValues, connectionType: option.value, databaseType: _databaseOptions[0].value });
            }
            isConnectionTypeNfs && setIsConnectionTypeNfs(false);
        } else if (option.value === 'NFS') {
            setIsConnectionTypeNfs(true);
            setIsPIServer(false);
            setIsWitsml(false);
            setIsOpcua(false);
            setIsAPI(false);
        } else if(option.value === 'Azure Blob Storage') {
            setIsAzureBlob(true);
            setIsPIServer(false);
            setIsWitsml(false);
            setIsOpcua(false);
            setIsAPI(false);

        }
    };




    return (
        <Modal
            title={actionType === 'NEW' ? 'Create New Connection' : 'Edit Connection'}
            isOpen={showModal}
            toggleClose={toggleClose}
            className={classNames('newConnection__modal newConnection__modal__old', { 'inEditMode': actionType === 'EDIT' })}
            image={actionType === 'EDIT' ? '/icons/link.svg' : ''}
            subtitle={actionType === 'EDIT' ? connectionDataForEdit.name : ''}
            shouldCloseOnOverlayClick={false}
        >
            <Form
                initialValues={__initialValues}
                validationSchema={validationSchema}
                onSubmit={handleConnectionInfoSubmit}
                className={classNames({ 'showSpinner': showSpinner }, { 'nfsType': isConnectionTypeNfs })}
                enableReinitialize
            >
                <ShowWhenTrue show={showSpinner}>
                    <InPageSpinner />
                </ShowWhenTrue>
                <div className={classNames('newConnection__form' , { 'hide': showSpinner })}>
                    <NewConnectionForm
                        connectionTypes={connectionTypes}
                        currentDatabaseOptions={currentDatabaseOptions}
                        isConnectionTypeNfs={isConnectionTypeNfs}
                        isPIServer={isPIServer}
                        isWitsml={isWitsml}
                        isAPI={isAPI}
                        isOpcua={isOpcua}
                        isAzureBlob={isAzureBlob}
                        handleConnectionTypeSelection={handleConnectionTypeSelection}
                    />
                </div>
                <div className="modalBtns__box">
                    <button
                        className="btn-md btn-yellow"
                        type="submit"
                        id={'btnConnectionSubmit'}
                        disabled={showSpinner}
                    >
                        {actionType === 'EDIT' ? 'Update' : 'Create'}
                    </button>
                    <button
                        className="btn-md btn-cancel"
                        type="button"
                        onClick={toggleClose}
                    >
                        Cancel
                    </button>
                </div>
            </Form>
        </Modal>
    );
};