import { Flex } from "@components/ui/Flex";
import styled from "styled-components";
import { Icons } from "./Icons";
import React, { useState } from "react";

const SearchInput = styled.input`
	background-color: transparent;
	border: none;
	color: white;
	&:focus {
		outline: none;
	}
`;

type Props = {
	onSearch: (search: string) => void;
	search: string
};

export const Search = ({ onSearch, search }: Props) => {
	const [focus, setFocus] = useState(false);

	return (
		<Flex
			gap="4px"
			alignItems="center"
			bg="#14182A"
			borderRadius="2px"
			px="8px"
			py="4px"
			border={`1px solid ${focus ? "#FCAD01" : "#14182A"}`}
			className="search_file"
		>
			<Icons.search />
			<SearchInput
				id="search_input"
				value={search}
				onChange={(e) => onSearch(e.target.value)}
				placeholder="Search"
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)}
			/>
		</Flex>
	);
};
