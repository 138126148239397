import { Resizable } from "re-resizable";
import React, { useEffect, useState } from "react";
import { useWindowSize } from "rooks5";
import { Icon } from "../Icon";
import { HelpTab } from "./HelpTab";
import styles from "./styles.module.scss";

type Props = {
	open: boolean;
	tabIndex: number;
	onMinimize?: () => void;
	onMaximize?: () => void;
};

export const BottomPanel = ({
	tabIndex,
	open,
	onMinimize,
	onMaximize,
}: Props) => {
	const [height, setHeight] = useState(36);
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const { innerHeight: windowHeight } = useWindowSize();

	useEffect(() => {
		if (tabIndex !== selectedTabIndex) {
			setSelectedTabIndex(tabIndex);
		}
	}, [tabIndex]);

	useEffect(() => {
		if (open) {
			setHeight((windowHeight as number) * 0.6);
		} else {
			setHeight(36);
		}
	}, [open]);

	return (
		<div className={styles.bottomPanelWrapper}>
			<Resizable
				minHeight="36px"
				maxHeight="60vh"
				enable={{ top: true }}
				size={{ height: height, width: "100%" }}
				onResizeStop={(event, direction, ref, delta) => {
					setHeight((h) => h + delta.height);
				}}
			>
				<div>
					<div className={styles.bottomPanelHeader}>
						<ul className={styles.headerTabs}>
							<li
								onClick={() => setSelectedTabIndex(0)}
								className={
									selectedTabIndex === 0
										? styles.selected
										: ""
								}
							>
								Help
							</li>
						</ul>

						<div className={styles.headerActions}>
							{/* <button>
								<Icon name="popout" />
							</button> */}
							<button
								onClick={() => {
									onMinimize?.();
									setHeight(36);
								}}
								disabled={height === 36}
							>
								<Icon name="minimize" />
							</button>
							<button
								disabled={
									height === (windowHeight as number) * 0.6
								}
								onClick={() => {
									onMaximize?.();
									setHeight((windowHeight as number) * 0.6);
								}}
							>
								<Icon name="maximize" />
							</button>
						</div>
					</div>
					<div className={styles.tabWrapper}>
						{selectedTabIndex === 0 && <HelpTab />}
					</div>
				</div>
			</Resizable>
		</div>
	);
};
