import React from 'react';
import { Action, ActionEvent, InputType } from '@projectstorm/react-canvas-core';
import { DsNodeModel } from '../node/ds_nodemodel';
import { forEach } from 'lodash';
import { store } from '../../../store';
import { deleteCanvasEntities, setActiveComponentPropsState } from '../../../store/workflow';
import {isMac} from '../../../helpers/utils';

/**
 * Deletes all selected items, but asks for confirmation first
 */
export class CustomDeleteItemsAction extends Action {
    constructor() {
        super({
            type: InputType.KEY_DOWN,
            // @ts-ignore
            fire: (event: ActionEvent<React.KeyboardEvent>) => {
                const { key } = event.event;

                if(key === 'Delete' || (key === 'Backspace' && isMac())) {
                // 46 - Delete
                    const arrayList: any = Array.from(document.getElementsByClassName('properties__fields__container')).find(t => {
                        return  Array.from (t.getElementsByTagName('input')).find(t => t === document.activeElement) || Array.from (t.getElementsByTagName('textarea')).find(t => t === document.activeElement);
                    });
                    if(arrayList || (isMac() && (document.activeElement?.tagName === 'INPUT' || document.activeElement?.tagName === 'TEXTAREA'))) {
                        return;
                    }
                    const selectedEntities = this.engine.getModel().getSelectedEntities();
                    let hasNodes = false;
                    let isLocked = false;
                    selectedEntities.forEach(model => {
                        if(model instanceof DsNodeModel) {
                            hasNodes = true;
                            if(model.isLocked()) {
                                isLocked = true;
                            }
                        }
                    });

                    const removeItemsFn = () => {
                        forEach(selectedEntities, (model) => {
                        // only delete items which are not locked
                            model.remove();
                            this.engine.repaintCanvas();
                        });
                        store.dispatch(setActiveComponentPropsState({ isSaved: true }) as any);
                    };

                    if(!isLocked)
                        if(hasNodes) {
                        // @ts-ignore
                            store.dispatch(deleteCanvasEntities(selectedEntities, removeItemsFn));
                        } else {
                            removeItemsFn();
                        }
                }
            }
        });
    }
}
