import React, { memo } from 'react';
import { Modal } from '../../../components/modals';
import { RootState } from '../../../store/types';
import { useSelector } from 'react-redux';

interface Props {
    onSaveAndContinue: () => any; 
    onDiscardChanges: () => any; 
    onCancel: () => any; 
    title?: string;
    description?: string;
}

export const LoseUnsavedFormChangesComponent: React.FC<Props> = ({ onSaveAndContinue, onDiscardChanges, onCancel, title, description }) => {
    return(
        <>
            <p className="deleteWorkflowModal__areYouSureMsg">{title ?? 'Are you sure you want to discard the unsaved changes applied on this component?'}</p>
            <p className="deleteWorkflowModal__warningMsg">{description ?? 'Warning: You must save or discard changes before any further action'}</p>
            
            <div className="modalBtns__box">
                <button
                    className="btn-md"
                    onClick={onDiscardChanges}
                >
                    Yes, Discard
                </button>
                <button 
                    className="btn-md btn-yellow"
                    type="submit"
                    onClick={onSaveAndContinue}
                >
                    Save & Continue
                </button>
                <button 
                    className="btn-md btn-grey"
                    type="button"
                    onClick={onCancel}
                >
                    Cancel
                </button>
            </div>

        </>
    );
};   


export const WrapperComponent: React.FC<Props> = ({ onSaveAndContinue, onDiscardChanges, onCancel }) => {
    const showModal = useSelector((store: RootState) => store.WorkflowReducer.showModal.loseUnsavedFormChanges);
    return(
        <Modal
            isOpen={showModal}
            toggleClose={onCancel}
            title=""
            className="deleteWorkflowModal loseUnsavedFormChangesModal"
            showCloseMark
        >
            <LoseUnsavedFormChangesComponent 
                onCancel={onCancel}
                onSaveAndContinue={onSaveAndContinue}
                onDiscardChanges={onDiscardChanges}
            />
        </Modal>
    );
}; 

export const LoseUnsavedFormChangesModal = memo(WrapperComponent);