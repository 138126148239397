import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import React, {
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import * as htmlToImage from 'html-to-image';
import { useDidUpdate } from "rooks";
import {
	PlotData,
	GraphInfo,
	PlotSelectionFormInfo,
	RasterColorConfigInfo,
} from ".";
import RasterPlotService from "../../../services/geo-tiff/RasterPlotService";
import RasterService from "../../../services/geo-tiff/RasterService";
import {
	getMultiRasterColorValuesRange,
	getRasterColorValuesRange,
} from "../utils";
import styles from "../styles.module.scss";
import {
	DeleteFilledIcon,
	EyeIcon,
	EyeSlashIcon,
	PaintBrushIcon,
	RasterSettingsIcon,
} from "../assets/icons";
import { UpChevronIcon } from "../../../components/formcreators/icons";
import clsx from "classnames";
import { Collapse as AntdCollapse } from "antd";
import { TooltipTop } from "../../../components/tooltips";
import { set } from "lodash";
import { RasterPlotPayload } from "@api/analytics-handler";
import { RasterPlotFailureMessages } from "../enums";
import { FaDownload } from "react-icons/fa";
import FileSaver from "file-saver";

export type RasterPlotProps = {
	rasterPlotsInfo: RasterPlotPayload | null;
	tiffFilePath: PlotData["tiffFilePath"] | null;
	isFullScreenActive: boolean;
	isSpinnerActive: boolean;
	tiffColorPlotInfo: GraphInfo["tiffColorPlotInfo"];
	handleToggleSpinner: (action: "show" | "hide") => void;
	handleShowRasterError: (message: RasterPlotFailureMessages) => void;
	graphId: string;
	plotSelectionFormInfo: PlotSelectionFormInfo;
	showPlotSelectionModal: (rasterConfigId: string | null) => void;
	showColorPicker: (rasterConfigId: string) => void;
	deleteRaster: (rasterConfigId: string) => void;
	handleToggleVisibility: (rasterConfigId: string) => void;
	isErrorState: boolean;
	switchToFullScreen: () => void;
	isDataExplorer?: boolean
};

export type ColorValueRange = {
	label: string;
	color: string;
};

type RasterConfigStats = {
	mins: number[];
	maxs: number[];
	ranges: number[];
};

type RasterConfigInfoToDisplay = {
	id: string;
	colorValueRange: ColorValueRange[];
	title: string;
	alpha: number;
	singleband: boolean;
};

export const RasterPlot: React.FC<RasterPlotProps> = memo(
	({
		rasterPlotsInfo,
		tiffFilePath,
		isFullScreenActive,
		tiffColorPlotInfo,
		handleToggleSpinner,
		isSpinnerActive,
		graphId,
		plotSelectionFormInfo,
		showColorPicker,
		showPlotSelectionModal,
		deleteRaster,
		handleToggleVisibility,
		handleShowRasterError,
		isErrorState,
		switchToFullScreen,
		isDataExplorer
	}) => {
		const mapId = useMemo(() => "map_" + graphId, [graphId]);

		const rasterPlotRef = useRef(new RasterPlotService());
		const [rasterConfigStats, setRasterConfigStats] = useState<
			Record<string, RasterConfigStats>
		>({});

		const useLeafletMap = useMemo(
			() => plotSelectionFormInfo.projection_code !== "None",
			[plotSelectionFormInfo.projection_code]
		);

		const isErrorStateRef = useRef(isErrorState);
		const [currPlotUrl,setCurrPlotUrl] = useState("");

		useEffect(() => {
			return ()=>{
				rasterPlotRef.current.unSubscribe()
			}
		}, [])

		useEffect(() => {
			isErrorStateRef.current = isErrorState;
		}, [isErrorState]);

		const [activeLegendKeys, setActiveLegendKeys] = useState<string[]>([]);
		const activeLegendKeysRef = useRef<Record<string, true>>({});

		useEffect(() => {
			const rasterConfigs = rasterPlotsInfo?.raster_configs;
			if (rasterConfigs) {
				setActiveLegendKeys((currentActiveKeys) => {
					const updatedActiveKeys = [...currentActiveKeys];
					rasterConfigs.forEach((rasterConfig) => {
						if (!activeLegendKeysRef.current[rasterConfig.id]) {
							// only add the keys initially on mount
							updatedActiveKeys.push(rasterConfig.id);
							activeLegendKeysRef.current[rasterConfig.id] = true;
						}
					});
					return updatedActiveKeys;
				});
			}
		}, [rasterPlotsInfo?.raster_configs]);

		const handleSetRasterPlot = useCallback(() => {
			handleToggleSpinner("show");
			setTimeout(() => {
				rasterPlotRef.current.initialize({ mapId });
			}, 500);
			
		}, []);

		const handleSetRasterConfigStats = useCallback(
			(configId: string, stats: RasterConfigStats) => {
				setRasterConfigStats((rasterStats) => ({
					...rasterStats,
					[configId]: stats,
				}));
			},
			[]
		);

		useEffect(() => {
			if (useLeafletMap) {
				handleSetRasterPlot();
			}
		}, [useLeafletMap]);

		const loadRasterWithoutMap = useCallback(
			(
				rasterConfigId: string,
				rasterPlotUrl: string,
				rasterColorConfig: RasterColorConfigInfo,
				singleband: boolean,
				isLastMapPlot: boolean
			) => {
				RasterService.loadRasterFromUrl(
					rasterPlotUrl,
					rasterConfigId,
					rasterColorConfig,
					singleband
				)
					.then(({ mins, maxs, ranges, plotUrl }) => {
						setCurrPlotUrl(plotUrl);
						handleSetRasterConfigStats(rasterConfigId, {
							mins,
							maxs,
							ranges,
						});
						setTimeout(() => {
							if (isLastMapPlot && !isErrorStateRef.current) {
								// Even if one of the rasters fail, show only the error message
								handleToggleSpinner("hide");
							}
						}, 100);
					})
					.catch((e) => {
						handleShowRasterError(e);
					});
			},
			[]
		);

		const loadRasterWithMap = useCallback(
			(
				rasterConfigId: string,
				rasterPlotUrl: string,
				rasterColorConfig: RasterColorConfigInfo,
				isLastMapPlot: boolean
			) => {
				RasterService.createRaster(rasterPlotUrl, rasterColorConfig)
					.then((res) => {
						const { mins, maxs, ranges } = res.georasters[0];
						rasterPlotRef.current.addRaster(
							rasterConfigId,
							rasterPlotUrl,
							res
						);
						handleSetRasterConfigStats(rasterConfigId, {
							mins,
							maxs,
							ranges,
						});
						setTimeout(() => {
							if (isLastMapPlot && !isErrorStateRef.current) {
								// Even if one of the rasters fail, show only the error message
								handleToggleSpinner("hide");
							}
						}, 100);
					})
					.catch((e) => {
						handleShowRasterError(e);
					});
			},
			[]
		);

		useEffect(() => {
			if (rasterPlotsInfo) {
				handleToggleSpinner("show");
				rasterPlotsInfo.raster_configs.forEach((rasterConfig, idx) => {
					const rasterConfigId = rasterConfig.id;
					if (tiffFilePath) {
						const rasterPlotUrl = tiffFilePath[rasterConfigId];
						const rasterColorConfig = tiffColorPlotInfo[rasterConfig.id] || {
							"colorRamp": "Grey",
							"startColor": "white",
							"endColor": "black",
							"invertColors": false,
							"colorMode": "Continous",
							"noOfClasses": 5,
							"alpha": 1,
							"hidden": false
						};
						if (rasterColorConfig) {
							const actualRasterColorConfig: RasterColorConfigInfo = {
								...rasterColorConfig,
								alpha: rasterColorConfig.hidden
									? 0
									: rasterColorConfig.alpha,
							};
							const isLastMapPlot = rasterPlotsInfo.raster_configs.length === idx + 1;
							setTimeout(() => {
								if (useLeafletMap) {
									loadRasterWithMap(
										rasterConfigId,
										rasterPlotUrl,
										actualRasterColorConfig,
										isLastMapPlot
									);
								} else {
									loadRasterWithoutMap(
										rasterConfigId,
										rasterPlotUrl,
										actualRasterColorConfig,
										rasterConfig.singleband,
										isLastMapPlot
									);
								}
							}, 500);
						}
					}
				});
			}
		}, [tiffColorPlotInfo, useLeafletMap, rasterPlotsInfo?.raster_configs]);

		useDidUpdate(() => {
			if (useLeafletMap) {
				rasterPlotRef.current.refresh();
			}
		}, [isFullScreenActive, useLeafletMap]);

		const { rasterConfigInfoToDisplay, hiddenRasterIds } = useMemo(() => {
			const rasterConfigInfoToDisplay: RasterConfigInfoToDisplay[] = [];
			const hiddenRasterIds: Record<string, true> = {};
			let multiBandRasterCounter = 1;
			if (
				tiffColorPlotInfo &&
				rasterPlotsInfo &&
				!isEmpty(rasterConfigStats)
			) {
				rasterPlotsInfo.raster_configs.forEach((rasterConfig) => {
					let alpha = 1;
					const rasterConfigId = rasterConfig.id;
					const rasterStats = rasterConfigStats[rasterConfigId];

					let colorValueRange: ColorValueRange[] = [];
					const rasterColorConfigInfo =
						tiffColorPlotInfo[rasterConfigId];
					if (rasterStats?.mins && rasterColorConfigInfo) {
						if (rasterConfig.singleband) {
							colorValueRange = getRasterColorValuesRange(
								{
									minValue: rasterStats.mins[0],
									maxValue: rasterStats.maxs[0],
									valueRange: rasterStats.ranges[0],
								},
								rasterColorConfigInfo
							);
						} else {
							colorValueRange = getMultiRasterColorValuesRange(
								rasterConfig
							);
						}

						alpha = rasterColorConfigInfo.alpha;

						if (rasterColorConfigInfo.hidden) {
							set(hiddenRasterIds, rasterConfigId, true);
						}
					}

					rasterConfigInfoToDisplay.push({
						title: rasterConfig.singleband
							? rasterConfig.y[0]
							: `Multi Band Raster (${multiBandRasterCounter})`,
						colorValueRange,
						id: rasterConfigId,
						alpha,
						singleband: rasterConfig.singleband,
					});
					if (!rasterConfig.singleband) {
						multiBandRasterCounter += 1;
					}
				});
			}
			return { rasterConfigInfoToDisplay, hiddenRasterIds };
		}, [rasterPlotsInfo, tiffColorPlotInfo, rasterConfigStats]);

		const addRaster = () => {
			showPlotSelectionModal(null);
		};

		const handleDeleteRaster = (rasterConfigId: string) => {
			if (useLeafletMap && rasterConfigInfoToDisplay.length > 1) {
				rasterPlotRef.current.removeRaster(rasterConfigId);
			}
			deleteRaster(rasterConfigId);
		};

		const isPlotMinimized = !isFullScreenActive;
		const domMapEl:any = useRef(null);

		const downloadRasterPlot = async () => {
			if( plotSelectionFormInfo.projection_code  === "None"){
				FileSaver.saveAs(currPlotUrl, 'Deepiq_chart.png');
			}else{
				htmlToImage.toPng(domMapEl.current)
				.then(function (dataUrl) {
					FileSaver.saveAs(dataUrl, 'Deepiq_chart.png');
				})
			}
		}

		return (
			<div
				className={classNames("map_outer", {
					hideVisibility: isSpinnerActive,
					tiffWithoutMap: !useLeafletMap,
					hide: isErrorState,
				})}
			>
				{useLeafletMap ? (
					<div id={mapId} ref={domMapEl} className="raster-leaflet-map" />
				) : (
					<>
						{/* Multiple canvas are added for tiff without projection */}
						{rasterPlotsInfo?.raster_configs.map((rasterConfig) => {
							const rasterColorConfig =
								tiffColorPlotInfo[rasterConfig.id] || {};
							return (
								<canvas
									key={rasterConfig.id}
									id={rasterConfig.id}
									width={500}
									height={500}
									className={styles["raster__canvas"]}
									style={{
										opacity: hiddenRasterIds[
											rasterConfig.id
										]
											? 0
											: rasterColorConfig.alpha,
									}}
								/>
							);
						})}
					</>
				)}

				<ul
					className={clsx(styles["rasterPlot__valuesOverlay"], {
						[styles["minimized"]]: isPlotMinimized,
					})}
				>
					<div className={styles["rasterPlot__headingBox"]}>
						<h4 className={styles["rasterPlot__heading"]}>
							Raster Configuration
						</h4>
						{rasterConfigInfoToDisplay.length === 1 && (
							<button
								className="btn-sm btn-grey"
								id="add-raster"
								onClick={addRaster}
							>
								<img
									src="/icons/workflow/add.svg"
									alt="add-raster"
									width={16}
									height={16}
								/>
								Add Raster
							</button>
						)}
						&nbsp;&nbsp;
                            <button 
                            	onClick={downloadRasterPlot}
                            >
                                 <FaDownload/>
                            </button>
					</div>
					{isPlotMinimized ? (
						<div
							onClick={()=>{
								!isDataExplorer && switchToFullScreen();
							}}
						>
							{rasterConfigInfoToDisplay.map((rasterConfig) => {
								const isRasterHidden =
									hiddenRasterIds[rasterConfig.id];
								return (
									<div
										className={classNames(
											styles["rasterConfig__item"],
											{
												[styles[
													"rasterConfig__item__multiBand"
												]]: !rasterConfig.singleband,
											}
										)}
										key={rasterConfig.id}
									>
										<div
											className={
												styles[
													"rasterConfig__item__LHS"
												]
											}
										>
											<button
												onClick={() => {
													handleToggleVisibility(
														rasterConfig.id
													);
												}}
											>
												{isRasterHidden ? (
													<EyeSlashIcon />
												) : (
													<EyeIcon />
												)}
											</button>
											<TooltipTop
												overlay={rasterConfig.title}
											>
												<h4>{rasterConfig.title}</h4>
											</TooltipTop>
										</div>
									</div>
								);
							})}
						</div>
					) : (
						<AntdCollapse
							className={styles["rasterPlot__valuesBox"]}
							activeKey={activeLegendKeys}
							onChange={setActiveLegendKeys as any}
						>
							{rasterConfigInfoToDisplay.map((rasterConfig) => {
								const isRasterHidden =
									hiddenRasterIds[rasterConfig.id];

								return (
									<AntdCollapse.Panel
										key={rasterConfig.id}
										header={
											<div
												className={classNames(
													styles[
														"rasterConfig__item"
													],
													{
														[styles[
															"rasterConfig__item__multiBand"
														]]: !rasterConfig.singleband,
													}
												)}
											>
												<div
													className={
														styles[
															"rasterConfig__item__LHS"
														]
													}
												>
													<button
														onClick={(e) => {
															e.stopPropagation();
															handleToggleVisibility(
																rasterConfig.id
															);
														}}
													>
														{isRasterHidden ? (
															<EyeSlashIcon />
														) : (
															<EyeIcon />
														)}
													</button>
													<button
														className={clsx(
															styles[
																"chevronIcon"
															],
															styles[
																"chevronIcon__active"
															]
														)}
													>
														<UpChevronIcon />
													</button>
													<TooltipTop
														overlay={
															rasterConfig.title
														}
													>
														<h4>
															{rasterConfig.title}
														</h4>
													</TooltipTop>
												</div>
												<div
													className={
														styles[
															"rasterConfig__actionBtns"
														]
													}
												>
													<button
														onClick={(e) => {
															e.stopPropagation();
															showPlotSelectionModal(
																rasterConfig.id
															);
														}}
													>
														<RasterSettingsIcon />
													</button>
													{rasterConfig.singleband && (
														<button
															onClick={(e) => {
																e.stopPropagation();
																showColorPicker(
																	rasterConfig.id
																);
															}}
														>
															<PaintBrushIcon />
														</button>
													)}
													<button
														onClick={(e) => {
															e.stopPropagation();
															handleDeleteRaster(
																rasterConfig.id
															);
														}}
													>
														<DeleteFilledIcon />
													</button>
												</div>
											</div>
										}
										showArrow={false}
									>
										<div
											className={
												styles[
													"rasterConfig__collapseContent"
												]
											}
										>
											{rasterConfig.colorValueRange.map(
												(value, idx) => (
													<li
														key={value.label + '_' + idx}
														className={
															styles[
																"valueColorRange"
															]
														}
													>
														<span
															style={{
																backgroundColor:
																	value.color,
															}}
															className={
																styles[
																	"colorBox"
																]
															}
														/>
														<span>
															{value.label}
														</span>
													</li>
												)
											)}
										</div>
									</AntdCollapse.Panel>
								);
							})}
						</AntdCollapse>
					)}
				</ul>
			</div>
		);
	}
);

RasterPlot.displayName = "RasterPlot";
