import styled from "styled-components";
import {
	border,
	BorderProps,
	color,
	ColorProps,
	flexbox,
	FlexboxProps,
	layout,
	LayoutProps,
	space,
	SpaceProps,
	position,
	PositionProps
} from "styled-system";

interface Props
	extends BorderProps,
		LayoutProps,
		FlexboxProps,
		ColorProps,
		PositionProps,
		SpaceProps {
	children?: React.ReactNode;
	gap?: string;
}

export const Flex = styled.div<Props>`
	display: flex;
	${(props) => (props.gap ? `gap: ${props.gap};` : "")};
	${flexbox};
	${space};
	${color};
	${layout};
	${border};
	${position};
`;
