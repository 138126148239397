import { useFormikContext } from 'formik';
import { cloneDeep, isEmpty, set, get } from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';
import { useDidUpdate } from 'rooks';
import {
    BuildPipelineModalProps,
    PythonModelsInfo,
    PythonSingleModelTypeInfo,
} from '.';
import { ShowWhenTrue } from '../../../../helpers';
import { SelectField, InputField, CheckboxField } from '../../../form';
import { _selectoptionType } from '../../../form/select-field';
import { NewExperimentAddIcon } from '../../icons';
import { BaseFieldType } from '../../types';
import {
    ModelLibraryOptions,
    PipelineCategoryOptions,
    classificationTypesForDropdown,
    regressionTypesForDropdown,
    clusteringTypesForDropdown,
    ReduceDimensionsParams,
    GeoSpatialParams,
    MinMaxHyperParams,
    StandardHyperParams,
    BinarizerParams,
    CountVectorizerParams,
    FeatureHasherParams,
    HashingTransformerParams,
    IDFVectorizerParams,
    NGramParams,
    TokenizerParams,
    RegexTokenizerParams,
    StemmerParams,
    StopWordsRemoverParams,
    getTrainModelKey,
    mlModelsHyperParams,
    getTrainHyperParams,
    scalerTypesOptionsForDropdown,
    ScalerTypes,
    ExtraOperationOptions,
    FeatureVectorizersTypes,
    ClusteringTypes,
    OperationOptions,
    featureVectorizerTypesOptionsForDropdown,
    textProcessorTypesOptionsForDropdown,
    SparkModelLibraryOptions,
    PythonModelLibraryOptions,
    HyperParamsInfo,
    MlPipelineStageKeys,
    SkLearnPreProcessingOptions,
    TensorflowLayers,
    trainableOptions,
} from '../enums';
import {
    getKeyInPipelineStage,
    getValueInPipelineStage,
    setInitialValuesForStage,
    handleSetModelLibrarySelection,
    getSklearnPreprocessingOptions,
} from '../utils';
import { StageOperationInfo } from './build-pipeline-RHS';
import { HyperParamsTable } from './hyper-params-table';
import { InputField as CaptureSchemaField } from '../../field-creator';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/types';
import { ExecutionEnvModes } from '../../../../constants/enums';
import { ThreeDotsIcon } from '../../../../pages/workflow-page/assets/icons';
import { Dropdown, Menu } from 'antd';
import StoredPipelineFields from '../StoredPipelineFields';
import classNames from 'classnames';
// import { CopyIcon } from '../../../../assets/icons';

const getCaptureSchemaFieldData = (
    key: string,
    label: string,
    infoText = '',
    required = false
): BaseFieldType => ({
    key,
    type: 'input',
    defaultValue: '',
    useCaptureSchema: {
        singleSelect: true,
        limitType: 'struct',
        showType: false,
    },
    templateOptions: {
        variable_type: 'string',
        options: [],
        qtip: infoText,
        label,
        required,
    },
});




export interface PipelineStageProps {
  step: number;
  handleCreateNewStage: (position: number, key: MlPipelineStageKeys) => void;
  handleDeleteStage: (arg0: string, key: MlPipelineStageKeys) => void;
  handleSetOperationType: (
    id: string,
    type: OperationOptions,
    key: MlPipelineStageKeys
  ) => void;
  stageId: string;
  operationsInfo: StageOperationInfo;
  noOfStages: number;
  captureSchemaOptions: BuildPipelineModalProps['captureSchemaOptions'];
  pythonModelsInfo: PythonModelsInfo;
  resetModelTypeFieldInfo: { label: string; value: string } | null;
  resetOperationFieldInfo: string | null;
}

export const PipelineStage: React.FC<PipelineStageProps> = ({
    step,
    stageId,
    handleCreateNewStage,
    handleDeleteStage,
    handleSetOperationType,
    operationsInfo,
    noOfStages,
    captureSchemaOptions,
    pythonModelsInfo,
    resetModelTypeFieldInfo,
    resetOperationFieldInfo,
}) => {
    const { values, initialValues, setFieldValue } = useFormikContext<any>();
    const modelTypeFieldRef = useRef<SelectField>(null);
    const operationFieldRef = useRef<SelectField>(null);
    const activeExecutionEnv = useSelector(
        (store: RootState) => store.CommonReducer.activeExecutionEnv
    );
  
    const getKey = (key: string) => getKeyInPipelineStage(key, stageId);

    const getValue = (key: string) => {
        return getValueInPipelineStage(values, key, stageId);
    };

    const optionsForModelType = useMemo(() => {
        const selectedModelLibrary = values.model_library as ModelLibraryOptions;
        if (selectedModelLibrary === SparkModelLibraryOptions['Spark']) {
            switch (values.pipeline_category) {
                case PipelineCategoryOptions.Classification:
                    return classificationTypesForDropdown;
                case PipelineCategoryOptions.Regression:
                    return regressionTypesForDropdown;
                case PipelineCategoryOptions.Clustering:
                    return clusteringTypesForDropdown;
                default:
                    return [];
            }
        } else {
            const selectedModelLibraryInfo = get(
                pythonModelsInfo,
                selectedModelLibrary + '.' + values.pipeline_category
            );
            return selectedModelLibraryInfo?.options || [];
        }
    }, [values.pipeline_category, values.model_library, pythonModelsInfo]);




    const sklearnPreprocessorOptions = useMemo(() => {
        return getSklearnPreprocessingOptions(pythonModelsInfo, getValue('operation'));
    }, [getValue('operation'), pythonModelsInfo]);

    const handleClickOperation = (option: _selectoptionType) => {
        if (operationsInfo[stageId].currentOpt !== option.value) {
            // Preprocessing is enabled only for spark stages
            handleSetOperationType(
                stageId,
                option.value,
                activeExecutionEnv === ExecutionEnvModes.Python ? MlPipelineStageKeys['PREPROCESSING_Sklearn']: MlPipelineStageKeys['PREPROCESSING_Spark']
            );
            setInitialValuesForStage({
                stageId,
                operationType: option.value,
                currentValues: values,
                currentInitialValues: initialValues,
                setFieldValue,
                optionsForModelType,
                sklearnPreprocessorOptions: getSklearnPreprocessingOptions(pythonModelsInfo, option.value)
            });
        }
    };

    useDidUpdate(() => {
        if (resetModelTypeFieldInfo) {
      modelTypeFieldRef.current?.setOption(resetModelTypeFieldInfo);
        }
    }, [resetModelTypeFieldInfo]);

    useDidUpdate(() => {
        if (resetOperationFieldInfo === stageId) {
      operationFieldRef.current?.resetSelectedOption();
        }
    }, [resetOperationFieldInfo]);

    const hyperParams = useMemo(() => {
    // HYPERPARAMS CONSTANTS SHOULD BE IMMUTABLE
        return {
            ReduceDimensionsParams: cloneDeep(ReduceDimensionsParams),
            GeoSpatialParams: cloneDeep(GeoSpatialParams),
            MinMaxHyperParams: cloneDeep(MinMaxHyperParams),
            StandardHyperParams: cloneDeep(StandardHyperParams),
            [BinarizerParams.key]: cloneDeep(BinarizerParams),
            [CountVectorizerParams.key]: cloneDeep(CountVectorizerParams),
            [FeatureHasherParams.key]: cloneDeep(FeatureHasherParams),
            [HashingTransformerParams.key]: cloneDeep(HashingTransformerParams),
            [IDFVectorizerParams.key]: cloneDeep(IDFVectorizerParams),
            [NGramParams.key]: cloneDeep(NGramParams),
            [TokenizerParams.key]: cloneDeep(TokenizerParams),
            [RegexTokenizerParams.key]: cloneDeep(RegexTokenizerParams),
            [StemmerParams.key]: cloneDeep(StemmerParams),
            [StopWordsRemoverParams.key]: cloneDeep(StopWordsRemoverParams),
        };
    }, []);

    useEffect(() => {
        const modelType = getValue('model_type');
        if (modelType) {
            const modelTypeKey = getTrainModelKey(values.model_library, modelType);
            const selectedHyperParamsKey = `${modelTypeKey}.selectedHyperParams`;
            if (getValue(selectedHyperParamsKey) === undefined) {
                let _initialSelection: Record<string, boolean> = {};
                if (values.model_library === SparkModelLibraryOptions.Spark) {
                    const mlModelInfo =
            mlModelsHyperParams[modelType as keyof typeof mlModelsHyperParams];
                    if (mlModelInfo?.initialSelection) {
                        // SWITCHING TO NEW MODEL TYPE FOR THE FIRST TIME
                        _initialSelection = mlModelInfo.initialSelection;
                        // set(initialValues, getKey(selectedHyperParamsKey), mlModelInfo.initialSelection);
                    }
                    if (!isEmpty(mlModelInfo?.defaultValues)) {
                        Object.keys(mlModelInfo.defaultValues).forEach((paramKey) => {
                            set(
                                initialValues,
                                getKey(`${modelTypeKey}.${paramKey}.value`),
                                get(mlModelInfo.defaultValues, paramKey)
                            );
                            setFieldValue(
                                getKey(`${modelTypeKey}.${paramKey}.value`),
                                get(mlModelInfo.defaultValues, paramKey)
                            );
                        });
                    }
                }
                //  else {
                // SELECT ALL HYPER PARAMS FOR INITIAL SELECTION IN SKLEARN
                // sklearnModelsInfo[values.pipeline_category].params[modelType].info.forEach(opt => {
                //     _initialSelection[opt.name] = true;
                // });
                // }

                set(initialValues, getKey(selectedHyperParamsKey), _initialSelection);
            } else {
                // SINCE SELECTED HYPERPARAMS ARE SET ON THE BASIS OF INITIAL VALUES
                // TO RETAIN THE OLD STATE, INITIAL VALUES ARE SET
                set(initialValues, getKey(modelTypeKey), getValue(modelTypeKey));
            }
        }
    }, [getValue('model_type'), values.model_library]);

    const replaceVariableKeyInHideExpression = (
        modelTrainhyperParamKey: string,
        expr: string
    ): string => {
        let _expr = expr;
        const variablesToBeReplacedList = _expr.match(/\{(.*?)\}/g) as string[];
        if (!isEmpty(variablesToBeReplacedList)) {
            variablesToBeReplacedList.forEach((v) => {
                // v = {family},
                // actualVariableName = family,
                // varValueKey = "stage_b989466d-1824-475c-8275-2dc6b2c9059b".train_model_hyperparams__GeneralizedLinearRegression.family.value
                const actualVariableName = v.slice(1, -1);
                const varValueKey =
          'values[\'stage_' +
          stageId +
          '\'].' +
          modelTrainhyperParamKey +
          '.' +
          actualVariableName +
          '.value';
                _expr = _expr.replace(v, varValueKey);
            });
        }
        return _expr;
    };

    const trainHyperParams: HyperParamsInfo = useMemo(() => {
        const modelType = getValue('model_type');
        const selectedModelLibrary = values.model_library as ModelLibraryOptions;

        if (modelType) {
            if (values.model_library === SparkModelLibraryOptions['Spark']) {
                const modelTrainhyperParams = cloneDeep(getTrainHyperParams(modelType));

                modelTrainhyperParams.info = modelTrainhyperParams.info.map(
                    (hyperParam) => {
                        if (hyperParam.hideExpression) {
                            hyperParam.hideExpression = replaceVariableKeyInHideExpression(
                                modelTrainhyperParams.key,
                                hyperParam.hideExpression
                            );
                        }
                        return hyperParam;
                    }
                );
                return modelTrainhyperParams;
            } else if (values.pipeline_category) {
                const selectedModelInfo = get(
                    pythonModelsInfo,
                    selectedModelLibrary + '.' + values.pipeline_category
                ) as PythonSingleModelTypeInfo | undefined;
                if (selectedModelInfo?.params?.[modelType]) {
                    return selectedModelInfo.params[modelType];
                }
            }
        }
        return { key: '', info: [], showTypeColumn: false };
    }, [
        getValue('model_type'),
        values.model_library,
        pythonModelsInfo,
        values.pipeline_category,
    ]);



    const renderScaleForm = () => {
        return (
            <>
                <SelectField
                    name={getKey('scalerType')}
                    options={scalerTypesOptionsForDropdown}
                    label="Scaler Type"
                    className="operation_subType_field"
                />
                <ShowWhenTrue show={getValue('scalerType') === ScalerTypes.Standard}>
                    <HyperParamsTable
                        hyperParams={hyperParams.StandardHyperParams.info}
                        name={getKey(hyperParams.StandardHyperParams.key)}
                        showTypeColumn={hyperParams.StandardHyperParams.showTypeColumn}
                    />
                </ShowWhenTrue>
                <ShowWhenTrue show={getValue('scalerType') === ScalerTypes['Min Max']}>
                    <HyperParamsTable
                        hyperParams={hyperParams.MinMaxHyperParams.info}
                        name={getKey(hyperParams.MinMaxHyperParams.key)}
                        showTypeColumn={hyperParams.MinMaxHyperParams.showTypeColumn}
                    />
                </ShowWhenTrue>
            </>
        );
    };

    const renderCaptureSchemaField = (fieldData: BaseFieldType) => (
        <div className="labelCol__field">
            <CaptureSchemaField
                fieldData={fieldData}
                captureSchemaOptions={captureSchemaOptions}
            />
        </div>
    );



    const { labelColFieldData, lonColFieldData, latColFieldData,featuresFieldData } = useMemo(
        () => ({
            labelColFieldData: getCaptureSchemaFieldData(
                getKey('label_col'),
                'Label Column',
                'The column that the model is learning to predict',
                true
            ),
            lonColFieldData: getCaptureSchemaFieldData(
                getKey('lonCol'),
                'Longitude Column',
                'Name of the Longitude column: not needed if input data has a column of type Geometry or ST_Geometry'
            ),
            latColFieldData: getCaptureSchemaFieldData(
                getKey('latCol'),
                'Latitude Column',
                'Name of the latitude column: not needed if input data has a column of type Geometry or ST_Geometry'
            ),
            featuresFieldData: getCaptureSchemaFieldData(
                getKey('features'),
                'Features',
                'Comma separated string of features to be used for fitting the model. Click "populate schema" to import schema from input data and select features from drop-down. You can add the column names manually as well'
            )
        }),
        [stageId]
    );

    const preprocessingParams = useMemo(()=>{
        const defaultValueList =  {key:'',info:[],showTypeColumn:false};
        if(getValue('operation')&& getValue('sklearnPreprocessor')) {
            const PreprocessingData = pythonModelsInfo['PREPROCESSING_sklearn'];
            const _hyperParams = PreprocessingData[getValue('operation')]?.params || [];
            const hyperTableParams = _hyperParams[getValue('sklearnPreprocessor')] || defaultValueList;
            return hyperTableParams;
        }else {
            return defaultValueList;
        }
    },[ getValue('operation'),getValue('sklearnPreprocessor'),pythonModelsInfo]);

    const handleSelectSklearnPreprocessor = (option: _selectoptionType) => {
        handleSetModelLibrarySelection('preprocessorType', {
            values,
            setFieldValue,
            modelLibrary: PythonModelLibraryOptions['Sklearn'],
            preprocessorOperation: getValue('operation'),
            preprocessorType: option.value,
            pythonModuleInfo: option.pythonModule,
            stageId
        });
    };


    const renderSkLearnPreprocessingForm = ( operationName: string ) =>{
        
        return (
            <>
                <SelectField
                    name={getKey('sklearnPreprocessor')}
                    options={sklearnPreprocessorOptions}
                    label="Preprocessor Type"
                    className="operation_subType_field"
                    onOptionClick={handleSelectSklearnPreprocessor}
                />   
                <ShowWhenTrue show={!isEmpty(getValue('sklearnPreprocessor'))}>
                    {renderCaptureSchemaField(featuresFieldData)}
                </ShowWhenTrue>
                <ShowWhenTrue show={!isEmpty(preprocessingParams.info)}>
                    <HyperParamsTable
                        hyperParams={preprocessingParams.info}
                        name={getKey(preprocessingParams.key)}
                        showTypeColumn={
                            preprocessingParams.showTypeColumn ||
                  values.model_library ===
                    PythonModelLibraryOptions['Sklearn']
                    
                        }
                        hideTune={
                            values.model_library !==
                PythonModelLibraryOptions['Sklearn']
                        }
                                
                    />

                </ShowWhenTrue>

           
            </>
        );
    };
    const renderGeospatialDataFields = () => (
        <div className="apply_window_fields">
            {/* <InputField 
                name={getKey('lonCol')}
                label="Longitude Column"
            />
            <InputField 
                name={getKey('latCol')}
                label="Latitude Column"
            /> */}
            {renderCaptureSchemaField(lonColFieldData)}
            {renderCaptureSchemaField(latColFieldData)}
        </div>
    );

    const renderFeatureVectorizerTextProcessorForm = (
        label: string,
        type: ExtraOperationOptions,
        dropdownOptions: _selectoptionType[]
    ) => {
    // type = 'Feature Hasher
    // selectedVectorizerOrTextProcessor = CountVectorizer
    // selectedHyperParams = hyperParams of CountVectorizer
        const selectedVectorizerOrTextProcessor = getValue(
            type
        ) as FeatureVectorizersTypes;
        const selectedHyperParams = hyperParams[selectedVectorizerOrTextProcessor];

        return (
            <>
                <SelectField
                    name={getKey(type)}
                    options={dropdownOptions}
                    label={label}
                    className="operation_subType_field"
                />
                {!isEmpty(selectedHyperParams?.info) ? (
                    <HyperParamsTable
                        hyperParams={selectedHyperParams.info}
                        name={getKey(selectedHyperParams.key)}
                        showTypeColumn={selectedHyperParams.showTypeColumn}
                    />
                ) : null}
            </>
        );
    };

    const handleModelTypeClick = (option: _selectoptionType) => {
    // handleSetModelLibrarySelection(values, setFieldValue, values.model_library, 'modelType', option.value)
        handleSetModelLibrarySelection('modelType', {
            values,
            setFieldValue,
            modelLibrary: values.model_library,
            pipelineCategory: values.pipeline_category,
            modelType: option.value,
            // Refer to useDidMount in index.tsx of this folder
            pythonModuleInfo: option.pythonModule,
            stageId,
        });
    // handleSetModeltypeInfo({
    //     values, setFieldValue, modelLibrary: values.model_library, initialValues, modelType: option.value, trainStageId: stageId
    // })
    };

    const renderTrainForm = () => (
        <>
            <SelectField
                name={getKey('model_type')}
                options={optionsForModelType}
                label={
                    values.model_library === PythonModelLibraryOptions['Tensorflow']
                        ? 'Layer'
                        : 'Model Type'
                }
                className="operation_subType_field modelType_field"
                ref={modelTypeFieldRef}
                onOptionClick={handleModelTypeClick}
            />
            <ShowWhenTrue
                show={
                    values.pipeline_category === PipelineCategoryOptions.Classification ||
          values.pipeline_category === PipelineCategoryOptions.Regression ||
          values.pipeline_category === PipelineCategoryOptions['Semi-Supervised']
                }
            >
                {renderCaptureSchemaField(labelColFieldData)}
            </ShowWhenTrue>
            <ShowWhenTrue show={getValue('model_type') === ClusteringTypes['LDA']}>
                <InputField
                    name={getKey('topicDistributionCol')}
                    label="Topic Distribution Column"
                    className="no-margin"
                />
            </ShowWhenTrue>
        </>
    );

    const isOperationTrainModel =
    getValue('operation') === OperationOptions['Train Model'];

    const getCreateDeleteStageKey = (modelLibrary: ModelLibraryOptions) => {
    // Adding/Deleting stages is allowed -
    // 1. Preprocessing stages in Spark/Sklearn
    // 2. Train Model Stages in Tensorflow
        return {
            [SparkModelLibraryOptions['Spark']]:
        MlPipelineStageKeys['PREPROCESSING_Spark'],
            [PythonModelLibraryOptions['Sklearn']]:
        MlPipelineStageKeys['PREPROCESSING_Sklearn'],
            [PythonModelLibraryOptions['Tensorflow']]:
        MlPipelineStageKeys['tensorflow'],
        }[modelLibrary];
    };

    const createNewStage = (position: number) => {
        const key = getCreateDeleteStageKey(values.model_library);
        handleCreateNewStage(position, key);
    };

    const deleteStage = (id: string) => {
        const key = getCreateDeleteStageKey(values.model_library);
        handleDeleteStage(id, key);
    };

    const enableDeleteOption =
    (step === 1 ? noOfStages > 1 : true) &&
    (values.model_library !== PythonModelLibraryOptions['Tensorflow']
        ? !isOperationTrainModel
        : true);

    return (
        <div className="pipelineStage__box">
            {step === 1 && (
                <>
                    <div className="pipelineStage__upperBorder" />
                    <button
                        className="btn-grey-transparent btn_new_stage"
                        onClick={() => createNewStage(0)}
                        id="btn_new_stage_1"
                        disabled={
                            getValue('operation') ===
              ExtraOperationOptions['Structured Learning']
                        }
                    >
                        <NewExperimentAddIcon /> <span>New Stage</span>
                    </button>
                </>
            )}
            <div className="pipelineStage__innerBox">
                <div className="pipelineStage__name">
          STAGE {step}
                    <Dropdown
                        overlay={
                            <Menu className="stageContextMenu">
                                {/* <Menu.Item>
                                <CopyIcon />
                                Duplicate
                            </Menu.Item>
                            <Menu.Item>
                                <CopyIcon />
                                <img src="/icons/copy.svg" alt="" />
                                Reset Stage
                            </Menu.Item> */}
                                <Menu.Item
                                    onClick={() => deleteStage(stageId)}
                                    disabled={!enableDeleteOption}
                                    id="btn_delete_stage"
                                >
                                    {/* <CopyIcon /> */}
                  Delete Stage
                                </Menu.Item>
                            </Menu>
                        }
                        trigger={['click']}
                        openClassName="active"
                        placement="bottomRight"
                    >
                        <div className="stageContextMenuTrigger">
                            <ThreeDotsIcon />
                        </div>
                    </Dropdown>
                    {/* <ShowWhenTrue show={(step === 1 ? noOfStages > 1 : true) && (values.model_library !== PythonModelLibraryOptions['Tensorflow'] ? !isOperationTrainModel:true)}>
                        <button
                            onClick={() => deleteStage(stageId)}
                            id="btn_delete_stage"
                        >
                            <img src="/icons/delete-filled.svg" alt="delete-stage"/>
                        </button>
                    </ShowWhenTrue> */}
                </div>
                <div className="pipelineStage__form">
                    {isOperationTrainModel ? (
                        <InputField
                            name={getKey('operation')}
                            label="Operation"
                            value="Train Model"
                            readOnly
                        />
                    ) : (
                        <SelectField
                            name={getKey('operation')}
                            options={operationsInfo[stageId].options}
                            onOptionClick={handleClickOperation}
                            placeholder="<Select One>"
                            label="Operation"
                            ref={operationFieldRef}
                        />
                    )}

                    <ShowWhenTrue show={getValue('operation') === SkLearnPreProcessingOptions.Scaler || getValue('operation') === SkLearnPreProcessingOptions.Binarizer || getValue('operation') === SkLearnPreProcessingOptions.Transformer || getValue('operation') === SkLearnPreProcessingOptions.Encoder || getValue('operation') === SkLearnPreProcessingOptions['Reduce Dimensions']  }>
  
                        {renderSkLearnPreprocessingForm(getValue('operation'))}
                    </ShowWhenTrue>

                    <ShowWhenTrue show={getValue('operation') === OperationOptions.Scale}>
                        {renderScaleForm()}
                    </ShowWhenTrue>
                    <ShowWhenTrue
                        show={
                            getValue('operation') === OperationOptions['Reduce Dimensions']
                        }
                    >
                        {/* {renderReduceDimemsionsForm()} */}
                        <HyperParamsTable
                            hyperParams={hyperParams.ReduceDimensionsParams.info}
                            name={getKey(hyperParams.ReduceDimensionsParams.key)}
                            showTypeColumn={hyperParams.ReduceDimensionsParams.showTypeColumn}
                        />
                    </ShowWhenTrue>
                    {/* <ShowWhenTrue show={getValue('operation') === ExtraOperationOptions['Stop Words Remover']}>
                        <HyperParamsTable 
                            hyperParams={hyperParams.StopWordsRemoverParams.info}
                            name={getKey(hyperParams.StopWordsRemoverParams.key)}
                            showTypeColumn={hyperParams.StopWordsRemoverParams.showTypeColumn}
                        />
                    </ShowWhenTrue> */}
                    <ShowWhenTrue
                        show={
                            getValue('operation') ===
              ExtraOperationOptions['Feature Vectorizers']
                        }
                    >
                        {renderFeatureVectorizerTextProcessorForm(
                            'Feature Vectorizers',
                            ExtraOperationOptions['Feature Vectorizers'],
                            featureVectorizerTypesOptionsForDropdown
                        )}
                    </ShowWhenTrue>
                    <ShowWhenTrue
                        show={
                            getValue('operation') === ExtraOperationOptions['Text Processors']
                        }
                    >
                        {renderFeatureVectorizerTextProcessorForm(
                            'Text Processors',
                            ExtraOperationOptions['Text Processors'],
                            textProcessorTypesOptionsForDropdown
                        )}
                    </ShowWhenTrue>
                    <ShowWhenTrue
                        show={getValue('operation') === OperationOptions['Train Model']}
                    >
                        {renderTrainForm()}
                        <ShowWhenTrue show={!isEmpty(trainHyperParams.info)}>
                            <HyperParamsTable
                                hyperParams={trainHyperParams.info}
                                name={getKey(trainHyperParams.key)}
                                showTypeColumn={
                                    trainHyperParams.showTypeColumn ||
                  values.model_library !==
                    PythonModelLibraryOptions['Tensorflow']
                                }
                                hideTune={
                                    values.model_library ===
                  PythonModelLibraryOptions['Tensorflow']
                                }
                            />
                        </ShowWhenTrue>
                    </ShowWhenTrue>
                    <ShowWhenTrue
                        show={
                            getValue('operation') ===
              ExtraOperationOptions['Structured Learning']
                        }
                    >
                        {renderGeospatialDataFields()}
                        <HyperParamsTable
                            hyperParams={hyperParams.GeoSpatialParams.info}
                            name={getKey(hyperParams.GeoSpatialParams.key)}
                            showTypeColumn={hyperParams.GeoSpatialParams.showTypeColumn}
                        />
                    </ShowWhenTrue>
                    <ShowWhenTrue show={getValue('model_type') === TensorflowLayers['Pretrained Model']}>
                        <div className="stored_pipeline_fields">
                            <StoredPipelineFields getKey={getKey} />
                            <InputField name={getKey("pipeline_layer")} label="Layer" />
                        </div>
                        <CheckboxField 
                                name={getKey("trainable")}
                                options={trainableOptions}
                                color="gold"
                            />
                    </ShowWhenTrue>
                </div>
            </div>
            <button
                className="btn-grey-transparent btn_new_stage"
                id="btn_new_stage"
                onClick={() => createNewStage(step)}
                // In Tensorflow, multiple train model stages can be added
                disabled={
                    operationsInfo[stageId].currentOpt === OperationOptions['Train Model']
                }
            >
                <NewExperimentAddIcon /> <span>New Stage</span>
            </button>
        </div>
    );
};
