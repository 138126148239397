import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { ShowWhenTrue } from '../../helpers';
import classNames from 'classnames';
import { isEmpty, omit, cloneDeep, has } from 'lodash';
import { TooltipTop } from '../tooltips';

type option = { label: string; value: string | number | boolean; infoText?: string }

export type CheckboxSelectionType = 'checked' | 'unchecked'
export interface RadioProps {
    name: string;
    label?: string;
    required?: boolean;
    className?: string;
    inline?: boolean;
    options: option[];
    readOnly?: boolean;
    infoText?: string;
    color?: 'normal' | 'gold';
    disabled?: boolean;
    onOptionClick?: (type: CheckboxSelectionType, selectedOptions: Record<string, any>) => any;
}

// for CheckboxField, VALUES MUST BE STRINGS SINCE THEY'RE JOINED 

export const CheckboxField: React.FC<RadioProps> = ({ name, label, required, options, readOnly, children, className='', infoText = '', inline=false, color='normal', disabled=false, onOptionClick }): JSX.Element => {
    const [, { initialValue }, { setValue }] = useField(name);
    const [selectedOptions, setSelectedOptions] = useState<Record<string, option['value']>>({});

    useEffect(() => {
        const __value = Object.values(selectedOptions).join(',');
        setValue(__value);
    }, [selectedOptions]);

    // useEffect(() => {
    //     if(disabled) {
    //         setSelectedOptions({});
    //     }
    // }, [disabled]);

    const handleSelectOption = (option: option) => {
        if(!disabled) {
            let updatedSelectedOptions = cloneDeep(selectedOptions);
            let type: CheckboxSelectionType = 'checked';
            if(has(selectedOptions, option.label)) {
                updatedSelectedOptions = omit(updatedSelectedOptions, option.label);
                type = 'unchecked';
            } else {
                updatedSelectedOptions[option.label] = option.value;
            }
            onOptionClick && onOptionClick(type, updatedSelectedOptions);
            setSelectedOptions(updatedSelectedOptions);
        }
    };
    
    
    useEffect(() => {
        if(initialValue && !isEmpty(options)) {
            const __initialSelectedValues: any[] = initialValue.split(',');
            const __selectedOptions: typeof selectedOptions = {};
            __initialSelectedValues.forEach(_value => {
                const _selectedInitialOption = options.find(option => option.value === _value);
                if(_selectedInitialOption) __selectedOptions[_selectedInitialOption.label] = _value;
            });
            if(!isEmpty(__selectedOptions)) setSelectedOptions(__selectedOptions);
        }
    }, [initialValue, options]);


    const renderToolTip = (toolTipText?: string) => (
        <ShowWhenTrue show={!!toolTipText}>
            <TooltipTop placement="topRight" overlay={toolTipText}>
                <img src="/icons/info-fields.png" width="16" height="16" className="info__icon" alt="information-icon" />
            </TooltipTop>
        </ShowWhenTrue>
    );


   
    return(
        <div 
            className={classNames(
                'radiofield__container checkboxfield__container', 
                {'checkboxfield__disabled': disabled},
                {[className]: !!(className)}
            )}
        >
            <div className={classNames('radiofield__inner__container', {'readonly-radio': !!(readOnly || disabled)}, {'inline': inline})}>
                <ShowWhenTrue show={!!(label)}>
                    <label className={classNames('inputfield__label m-r-30')}>
                        <span className={required ? 'red-star': ''}>{label}</span>
                        {renderToolTip(infoText)}
                        {/* <ShowWhenTrue show={!!infoText}>
                            <Tooltip placement="topRight" overlay={infoText}>
                                <img src="/icons/info-fields.png" width="16" height="16" className="info__icon" alt="information-icon" />
                            </Tooltip>
                        </ShowWhenTrue> */}
                    </label>
                </ShowWhenTrue>

                <ul className="optionsList">
                    {(options || []).map((option: option, index: number) => {
                        const checked = has(selectedOptions, option.label);
                        return(
                            <li
                                key={option.value.toString()+index}
                                // onClick={() => handleSelectOption(option)} 
                            >
                                <label 
                                    className={classNames('checkbox__container', 
                                        {'checkbox__active': checked},
                                        {'checkbox__gold': color === 'gold'}
                                    )}
                                >
                                    <input type="checkbox"  checked={checked} onChange={() => {return;}} value="" />
                                    <span className="checkmark"  onClick={() => handleSelectOption(option)}  />
                                </label> 
                                <span className="checkbox__label">{option.label}</span>
                                {renderToolTip(option.infoText)}
                            </li>
                        );
                    })}
                </ul>
            </div>
            {children}

        </div>
    );
    
};