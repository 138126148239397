import { object, string, number, InferType, boolean } from 'yup';
import { AuthTypes } from './new-edit-connection';


export const baseFormSchema = object().shape({
    name: string()
        .required('Name is a required field'),
    host: string()
        .required('Host is a required field'),
    port: string()
        .required('Port is a required field'),
    username: string()
        .required('Username is a required field'),
    password: string(),
    connectionType: string(),
    url: string()
        .required('Url is a required field'),
    databaseName: string(),
    driver: string(),
    connectionStringOption: string(),
});


export const formSchemaForNoSqlRelational = baseFormSchema.concat(object().shape({
    databaseType: string().required("This is a required field"),
    oracleServiceName: string(),
}));


export const formSchemaForNfs = formSchemaForNoSqlRelational.concat(object().shape({
    sudoName: string()
        .required('Sudo name is a required field')
}));

export const formSchemaWitsml = object().shape({
    name: string()
        .required('Name is a required field'),
    username: string()
        .required('Username is a required field'),
    password: string(),
    url: string()
        .required('Url is a required field'),
    headers: object(),
    queryparams: object(),
});

export const formSchemaAPI = object().shape({
    name: string()
        .required('Name is a required field'),
    username: string()
        // .required('Username is a required field')
        .test('check-for-username', 'Username is a required field', function(this, value) {
            if(this.parent.authType === AuthTypes['Basic Auth']) {
                // if authType is basic auth = username and password are shown
                return value && value.length > 0;
            } return true;
        }).nullable(),
    password: string().nullable(),
    serverUrl: string(),
    // token: string(),
    url: string()
        .required('Token Url is a required field'),
    requestType: string()
        .required('Request Type is required field'),
    scheduleType: string(),
    weekOfTheMonth: number().nullable(),
    days: number().nullable(),
    hrs: number().nullable(),
    mins: number().nullable(),
    months: number().nullable(),
    weekDays: string().nullable(),
    headers: object(),
    queryparam: object(),
    formData: object(),
    authType: string(),
    scheduleAdvancedMode: boolean(),
    responseTokenKey: string()
        .required('Token Attribute is a required field'),
    scheduleTimingInfo: string(),
    cronExpression: string()

});


export const formPIServerSchema = object().shape({
    name: string()
        .required('Name is a required field'),
    host: string()
        .required('Host is a required field'),
    port: string()
        .required('Port is a required field'),
    username: string()
        .required('Username is a required field'),
    password: string()
});

export const formOpcuaSchema = object().shape({
    name: string()
        .required('Name is a required field'),
    connectionType: string(),
    url: string()
        .required('Url is a required field'),
    databaseType: string().required("This is a required field")
});

export const azureBlobSchema = object().shape({
    name: string()
        .required('Name is a required field'),
    accessTypeName: string(),
    accountName: string()
        .required('Account Name is a required field'),
    accountKeyOrSasToken: string()
        .required('This is a required field'),
});


export type schemaTypes = InferType<typeof formSchemaForNoSqlRelational> & InferType<typeof formSchemaForNfs> & InferType<typeof formSchemaAPI> & InferType<typeof azureBlobSchema>