import { InPageSpinner } from "@components/spinners/in-page-spinner";
import { infoAlert } from "@components/toastify/notify-toast";
import { TooltipTop } from "@components/tooltips";
import { WorkflowCanvas } from "@components/workflow-canvas";
import { DsNodeExtras } from "@components/workflow-canvas/node/ds_nodemodel";
import { RestartSVGIcon } from "@pages/cluster_redisign/icons";
import { getComponentInfoFromRiTree } from "@services/RiTreeParser/refreshComponentUtil";
import validateComponentPropertiesForRefresh from "@services/RiTreeParser/validateComponentPropertiesForRefresh";
import workflowActionHandler from "@services/WorkflowActionHandler";
import { RootState } from "@store/types";
import classNames from "classnames";
import { isEmpty, omit } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const RefreshComponentInProperties = () => {
	const isFormSaved = useSelector(
		(store: RootState) =>
			store.WorkflowReducer.activeComponentPropertiesInfo.isSaved
	);

	const activeComponentInfo = useSelector(
		(store: RootState) => store.WorkflowReducer.activeComponentInfo
	);

	const componentTreeData = useSelector(
		(store: RootState) => store.WorkflowReducer.componentTreeData.data
	);

	const [refreshInProgress, setRefreshInProgress] = useState(false);

	const dispatch = useDispatch();

	const showRefreshIcon = useMemo(() => {
		if (activeComponentInfo && !isEmpty(componentTreeData)) {
			const activeComponentModel = WorkflowCanvas.getNode(
				activeComponentInfo.id
			);
			if (activeComponentModel) {
				if (activeComponentModel.getOptions().subtitle === "DAG") {
					// for older dag components, subtitle is transformed to uppercase
					activeComponentModel.getOptions().subtitle = "dag";
				}
				const componentInfoFromRiTree = getComponentInfoFromRiTree(
					componentTreeData,
					activeComponentModel
				);
				if (
					componentInfoFromRiTree &&
					(activeComponentModel.getOptions().hash !==
						componentInfoFromRiTree.hash) ||
						!!activeComponentModel.getOptions().extras?.customComponentPayload
				) {
					const currentComponentRiTreeInfo = omit(
						activeComponentModel.extras as DsNodeExtras,
						"extraData"
					);
					return validateComponentPropertiesForRefresh(
						currentComponentRiTreeInfo,
						componentInfoFromRiTree?.details
					);
				}
			}
		}
		return false;
	}, [activeComponentInfo, componentTreeData]);

	const hideRefreshProgress = () => setRefreshInProgress(false);

	const refreshComponent = useCallback(() => {
		if (activeComponentInfo) {
			setRefreshInProgress(true);
			infoAlert("Refreshing " + activeComponentInfo.name);
			dispatch(
				workflowActionHandler({
					type: "REFRESH_COMPONENT",
					payload: { componentId: activeComponentInfo.id },
					successCb: hideRefreshProgress,
					failureCb: hideRefreshProgress,
				})
			);
		}
	}, [activeComponentInfo]);

	return showRefreshIcon || refreshInProgress ? (
		<div className="refreshComponentProperties__message">
			<p className="messageContent">
				There are changes made to the component. Please refresh to fetch
				the latest updates.
			</p>
			<TooltipTop
				overlay={
					!isFormSaved ? "Please save the properties to refresh" : ""
				}
			>
				<button
					className={classNames(
						"btn btn-sm btn-grey btn-refresh-component",
						{
							"btn-grey-disabled":
								!isFormSaved || refreshInProgress,
						}
					)}
					type="button"
					id="refresh-component"
					onClick={refreshComponent}
					disabled={!isFormSaved || refreshInProgress}
				>
					{refreshInProgress ? (
						<>
							<InPageSpinner size="XSmall" color="black" />
							Refresh in progress
						</>
					) : (
						<>
							<RestartSVGIcon />
							Refresh Component
						</>
					)}
				</button>
			</TooltipTop>
		</div>
	) : null;
};

export default RefreshComponentInProperties;
