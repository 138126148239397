import {
	WorkflowConfig,
	WorkflowConfigItem,
	WorkflowConfigItemType,
} from "@services/WorkflowConfig";
import { getUniqueId } from "@utils/common";
import {
	get,
	has,
	isEmpty,
	isNil,
	isPlainObject,
	isString,
	omit,
} from "lodash";

function readFile(file: File, cb: (content: string | undefined) => void) {
	const reader = new FileReader();
	reader.addEventListener("load", (event) => {
		cb(event?.target?.result as string | undefined);
	});

	reader.readAsText(file);
}

const getConfigItemValues = (obj: Record<string, any>) => ({
	key: get(obj, "key"),
	value: get(obj, "value"),
	type: get(obj, "type"),
});

const acceptedConfigItemTypes = Object.values(WorkflowConfigItemType);

const getAndValidateConfigData = (parsedConfig: WorkflowConfig) => {
	const keyMap: Record<string, true> = {};
	const duplicateKeys: string[] = [];
	const data: WorkflowConfigItem[] = [];
	let failedRows = 0;

	for (let i = 0; i < parsedConfig.length; i++) {
		const parsedConfigItem = parsedConfig[i];
		if (isPlainObject(parsedConfigItem)) {
			const { key, value, type } = getConfigItemValues(parsedConfigItem);
			if (
				isNil(key) ||
				isNil(value) ||
				isNil(type) ||
				!isString(key) ||
				!isString(type)
			) {
				failedRows++;
				break;
			}

			if (!acceptedConfigItemTypes.includes(type.trim() as any)) {
				failedRows++;
				break;
			}

			const configItem: WorkflowConfigItem = {
				key: key.trim(),
				value,
				type: type.trim() as WorkflowConfigItemType,
				id: getUniqueId(),
			};

			if (configItem.type !== WorkflowConfigItemType.String) {
				// .trim() is not added on line 42 as spaces are supported in string type
				if (typeof value === "string") {
					configItem.value = value.trim();
				}
				switch (configItem.type) {
					case WorkflowConfigItemType.Boolean:
						configItem.value = value;
						break;
					case WorkflowConfigItemType.Decimal:
						configItem.value = parseFloat(value);
						break;
					case WorkflowConfigItemType.Integer:
						configItem.value = parseInt(value);
						break;
				}
			}

			if (has(keyMap, configItem.key)) {
				duplicateKeys.push(configItem.key);
			} else {
				keyMap[configItem.key] = true;
			}

			data.push(configItem);
		} else {
			failedRows++;
		}
	}
	return {
		duplicateKeys: isEmpty(duplicateKeys)
			? null
			: new Array(new Set(duplicateKeys)).join(", "),
		data,
		failedRows,
	};
};

const parseConfigJson = (
	file: File,
	successCb: (config: WorkflowConfig) => void,
	failureCb: (message?: string) => void
) => {
	readFile(file, (content) => {
		try {
			if (content) {
				const results = JSON.parse(content);
				if (!isEmpty(results) && Array.isArray(results)) {
					if (results) {
						const _config = getAndValidateConfigData(results);
						if (_config.duplicateKeys) {
							failureCb(
								`Found multiple rows with same key - ${_config.duplicateKeys}. Please upload a valid configuration`
							);
						} else {
							if (_config.failedRows) {
								failureCb(
									`Failed to import ${
										_config.failedRows
									} row${_config.failedRows > 1 ? "s" : ""}`
								);
							}
							if (!isEmpty(_config.data)) {
								successCb(_config.data);
							}
						}
					} else {
						failureCb("No config items found");
					}
				} else {
					throw new Error("Invalid JSON");
				}
			} else {
				failureCb("File is empty");
			}
		} catch {
			failureCb("Invalid file format. Failed to parse the file.");
		}
	});
};

const generateConfigJson = (config: WorkflowConfig) =>
	JSON.stringify(config.map((configItem) => omit(configItem, "id")));

export { parseConfigJson, generateConfigJson };
