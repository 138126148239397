import React, { useEffect, useState } from 'react';
import MonacoEditor from "react-monaco-editor";
import * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';
import { HISTORY } from '../gen-ai/types';
import { Switch, Tabs } from 'antd';
import { DataSourceHandler } from '@api/data-source-handler';
import { InputField } from '@components/form';
import { Formik } from 'formik';
import Button from '@components/buttons';
import { useGenAIContext } from '../gen-ai/useGenAIContext';
import { successAlert } from '@components/toastify/notify-toast';

const { TabPane } = Tabs;

const options: any = {
    extraEditorClassName: 'codeEditor',
    selectOnLineNumbers: true,
    'contextmenu': false,
    'formatOnPaste': true,
    'accessibilitySupport': 'auto',
    'autoIndent': 'full',
    'highlightActiveIndentGuide': true,
    'quickSuggestions': true,
    'readOnly':true,
    'fontSize':'14px',
    'textAlign':'right',
    renderLineHighlight:'none',
    formatOnType: true,
    minimap: {
        enabled: false,
    },
    letterSpacing: 0.25,
    lineNumbersMinChars: 0,
    lineDecorationsWidth: 0,
    scrollbar:{
        verticalScrollbarSize: 0,
        horizontalScrollbarSize: 0,
        verticalSliderSize: 0,
        horizontalSliderSize: 0,
        useShadows: false,
		verticalHasArrows: true,
		horizontalHasArrows: true,
		vertical: 'hidden',
		horizontal: 'hidden',
		arrowSize: 30 
    },
    glyphMargin: false,
    automaticLayout: true,
    lineNumbers: 'off',
    folding: true,
};

const ShowScratchPad: React.FC<{history?: HISTORY}> = ({history}) => {
    const [logData, setLogData] = useState("");
    const [guid, setGUID] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [iChecked, setIChecked] = useState(false);
    const [iValue, setIValue] = useState("");
    const { setInjectionPersist, setInjectionValue, injection } =  useGenAIContext();

    const getLogs = async (_guid?: string) => {
        setLoading(true)
        const log = await DataSourceHandler.GetLogs(_guid || guid);
        setLogData(log.data)
        setLoading(false)
    }

    useEffect(() => {
        const uuid = history?.scratchpad.query_uuid || ""
        setGUID(uuid)
        setIValue(injection.value)
        setIChecked(injection.persist)
    }, [history?.scratchpad_base, injection.value, injection.persist])

    const onTabChange = (key: string) => {
        if(key === "log") {
            getLogs(guid);
        }
    }

    const saveInjection = () => {
        setInjectionValue(iValue);
        setInjectionPersist(iChecked);
        successAlert("Successfully updated")
    }

    return (
        <div style={{width: 600}}>
            <Formik
                key={"form_" }
                enableReinitialize={true}
                initialValues={{
                    guid: guid,
                    promptInjection: injection.value
                }}
                onSubmit={()=>{
                    //
                }}
            >
                {({
                    values
                }) => {
                    return(
                        <form style={{width: '100%'}}>
                            <Tabs className="innerWorkflowsTabs" onChange={onTabChange} >
                                <TabPane tab={"Scratch Pad"} key={"ScratchPad"} >
                                    <MonacoEditor
                                        height={500}
                                        width='100%'
                                        language='json'
                                        theme='vs-dark'
                                        value={JSON.stringify(history?.scratchpad || {}, null, 2)}
                                        options={options}
                                    />
                                </TabPane>
                                <TabPane tab={"Logs"} key={"log"} >
                                    <>
                                        <div style={{display: 'flex', alignItems: 'end', marginBottom: '1rem'}}>
                                            <InputField
                                                name="guid"
                                                label='GUID'
                                                autoComplete="off"
                                                autoFocus
                                            />
                                            <Button title='Get Logs' onClick={()=>getLogs(values.guid)}></Button>
                                        </div>
                                        {
                                            isLoading? <div style={{ width: '100%', height: '250px' }}> loading ... </div> : (
                                                <MonacoEditor
                                                    height={500}
                                                    width='100%'
                                                    language='json'
                                                    theme='vs-dark'
                                                    value={JSON.stringify(logData || {}, null, 2)}
                                                    options={options}
                                                />
                                            )
                                        }
                                    </>
                                </TabPane>
                                <TabPane tab={"Prompt Injection"} key={"injection"} >
                                    <div>
                                        <div>
                                            <span>Use for all api calls: </span>
                                            <Switch 
                                                checked={iChecked}
                                                onChange={(checked)=>{
                                                    setIChecked(checked);
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <InputField
                                                name="promptInjection"
                                                label={`Prompt Injection`}
                                                autoComplete="off"
                                                autoFocus
                                                onChange={(e: any)=>{
                                                    setIValue(e)
                                                }}
                                            />
                                        </div>
                                        <div style={{marginTop: '1rem', float: 'right'}}>
                                            <Button title='Save' onClick={()=>saveInjection()}></Button>
                                        </div>
                                    </div>
                                </TabPane>
                            </Tabs>
                        </form>
                )}}
            </Formik>
        </div>
    )
};

export default ShowScratchPad;