import React, { useState, ChangeEvent } from 'react';
import { Modal, Input, Button, Select } from 'antd';
import classNames from 'classnames';
import { errorAlert } from '@components/toastify/notify-toast';

const { Option } = Select;

interface NewDirectoryModalProps {
    visible: boolean;
    repoName: string;
    type: string;
    repoId: number;
    onCreate: (directoryName: string, repoId: number) => void;
    onCancel: () => void;
}



const NewDirectoryModal: React.FC<NewDirectoryModalProps> = ({ visible, type,  repoName, repoId, onCreate, onCancel }) => {
    const [directoryName, setDirectoryName] = useState<string>('');

    const isValidDirectoryName = (name: string): boolean => {
        return name.trim().length > 0;
    };

    const handleCreate = () => {
        if (isValidDirectoryName(directoryName)) {
            onCreate(directoryName.trim(), repoId); 
            setDirectoryName('');
        } else {
            errorAlert("Invalid directory name")
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setDirectoryName(e.target.value);
    };

    return (
        <Modal
            title={type=='Rename'?"Rename Directory":"Create New Directory"}
            visible={visible}
            onOk={handleCreate}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button className="btn-md btn-yellow" key="create" type="primary" onClick={handleCreate} disabled={!isValidDirectoryName(directoryName)}>
                    {type=='Rename'?'Confirm':'Create'}
                </Button>
                
            ]}
        >
            <Input
                placeholder="Directory Name"
                value={directoryName}
                onChange={handleChange}
                style={{borderStyle:'none'}}
            />
            <Select value={repoName} className={classNames('disabled-select')} disabled style={{ width: '100%', marginTop: '15px', backgroundColor:'grey' }}>
                <Option value={repoName}>{repoName}</Option>
            </Select>
        </Modal>
    );
};

export default NewDirectoryModal;
