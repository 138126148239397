import { useOutsideClick } from 'rooks5';
import classNames from 'classnames';
import { isEmpty, union } from 'lodash';
import React, { useMemo } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PipelineIcon, PythonIcon, SparkIcon, StreamingIcon, SwitchIcon } from '../../assets/icons';
import { ExecutionEnvModes, ExecutionEnvModesLabels } from '../../constants/enums';
import { Env } from '../../constants/settings';
import { PageTypes } from '../../store/canvas';
import { updateExecutionEnv } from '../../store/common';
import { RootState } from '../../store/types';
import './styles.scss';

interface SwitchExecutionEnv {
    page: PageTypes;
    disabledTypes?: ExecutionEnvModes[];
}

const _Icons = {
    [ExecutionEnvModes.Spark]: SparkIcon,
    [ExecutionEnvModes.Python]: PythonIcon,
    [ExecutionEnvModes.Pipelines]: PipelineIcon,
    [ExecutionEnvModes.Streaming]: StreamingIcon
};

export const SwitchExecutionEnv: React.FC<SwitchExecutionEnv> = ({ disabledTypes = [] }) => {
    const dispatch = useDispatch();
    const activeExecutionEnv = useSelector((store: RootState) => store.CommonReducer.activeExecutionEnv);
    const [showExecutionOptions, toggleShowExecutionOptions] = useState(false);
    const executionOptionsOuterRef = useRef<HTMLDivElement>(null);

    const handleSetExecutionEnv = (env: ExecutionEnvModes) => {
        dispatch(updateExecutionEnv(env));
    };


    useOutsideClick(executionOptionsOuterRef, () => toggleShowExecutionOptions(false), showExecutionOptions);

    const executionEnvOptions = useMemo(() => {
        const executionEnvOptions = Object.entries(ExecutionEnvModes);
        let options: typeof executionEnvOptions = []
        if(Env.showStreamingComponents){
            options = executionEnvOptions
        } else {
            options = union(executionEnvOptions.slice(0, 2), [executionEnvOptions[3]])
        }
        if(!isEmpty(disabledTypes)) {
            const disabledTypesRef: Record<string, true> = disabledTypes.reduce((ref, key) => {
                ref[key] = true;
                return ref
            }, {} as Record<string, true>)
            options  = options.filter(([label, val]) => !disabledTypesRef[val])
        }
        return options
    }, [disabledTypes]);


    const ActiveExecutionIcon = _Icons[activeExecutionEnv] ? _Icons[activeExecutionEnv]: null;
    return(
        <div
            className="executionEnv__outerBox"
            ref={executionOptionsOuterRef}
        >
            <div 
                className="executionEnv__optionItem activeExecutionEnv__optionItem"
                onClick={() => toggleShowExecutionOptions(true)}    
            >
                <div className="activeExecutionEnv__optionItem__inner">
                    {ActiveExecutionIcon && 
                            <ActiveExecutionIcon />
                    }
                    {ExecutionEnvModesLabels[activeExecutionEnv]}
                </div>
                {/* <div className="switchIcon"> */}
                <SwitchIcon />
                {/* </div> */}
            </div>
            {showExecutionOptions && 

                <div className="executionEnv__options">
                    <div>
                        {executionEnvOptions.map(([title, value]) => {
                            const Icon = _Icons[value] ? _Icons[value]: null;
                            
                            return(
                                <div
                                    key={value}
                                    className={classNames('executionEnv__optionItem' , {'activeExecutionEnv': value === activeExecutionEnv})}
                                    onClick={() => {
                                        toggleShowExecutionOptions(false);
                                        value !== activeExecutionEnv && handleSetExecutionEnv(value);   
                                    }}
                                >
                                    {Icon &&
                                    <Icon />}
                                    {title}
                                </div>
                            );})}
                    </div>
                    <div className="switchIcon">
                        <SwitchIcon />
                    </div>
                </div>
            }
            {/* <Formik
                initialValues={{ env: activeExecutionEnv }}
                onSubmit={() => {return;}}
            >
                <SelectField 
                    options={optionsToShow}
                    onOptionClick={(option) => {
                        option.value !== activeExecutionEnv && handleSetExecutionEnv(option.value);
                    }}
                    name="env"
                    className="executionEnv__dropdown"
                />
            </Formik> */}
        </div>
    );
};