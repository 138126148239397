/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { Component, useState } from 'react';
import ReactDOM from 'react-dom';
import {
    Input
} from 'semantic-ui-react';
import { Select } from 'antd';
import HandGrab from './handGrab';
import { Draggable } from 'react-beautiful-dnd';
import Buttons from './buttons';
import WhereColumnSearchBar from './WhereColumnSearchBar';
import WhereColumnSearchBarDetails from './WhereColumnSearchBarDetails';
import OperatorSearchBar from './OperatorSearchBar';
import { Tooltip } from 'antd';
import aggretorOptions from './aggregatorOptions';

const { Option } = Select;
const portal = document.createElement('div');
document.body.appendChild(portal);

const PortalDraggableItem = props => {
    const { rowIndex, query, updateQueryState } = props;
    const handleFilterChange = value => {
        query.where.handleFilterChange(value, rowIndex);
        updateQueryState();
    };
    const [fnName, setFnName] = useState('');
    const result = (
        <div ref={props.provided.innerRef} {...props.provided.draggableProps}>
            <div className="drag" style={{
                backgroundColor: 'rgba(102, 106, 141, 0.72)', 
                borderRadius: '2px',
                margin: '5px 0',
                padding: '5px'
            }}>
                <div style={{ width: '100%', position: 'relative' }}>
                    <div className="query_position_indicator"></div>
                    <div
                        {...props.provided.dragHandleProps}
                        style={{ 
                            display: 'inline-block',
                            position: 'absolute',
                            top: '50%',
                            transform: 'translate(-50%, -50%)',
                            left: '5px'
                        }}
                    >
                        <HandGrab />
                    </div>
                    <div style={{
                        marginLeft: '15px'
                    }}>
                        <div style={{ display: 'inline-block', position: 'absolute', top:5, right: 0 }}>
                            <Buttons
                                type="whereRow"
                                updateQueryState={props.updateQueryState}
                                rowIndex={props.rowIndex}
                                query={props.query}
                            />
                        </div>
                        <div style={{ 
                            display: 'inline-block',
                            marginRight: '5px',
                            marginBottom: '5px'
                        }}>
                            <Select 
                                style={{
                                    display: 'inline-block',
                                    width: 120
                                }}
                                placeholder="Where function"
                                dropdownClassName="editorTab"
                                onChange={(value) => {
                                    setFnName(value);
                                    if(value){
                                        const obj = aggretorOptions.where.filter((option)=>{
                                            return option.value === value;
                                        });
                                        props.query.where.modifyFunction(
                                            props.rowIndex,
                                            `${value}`, 
                                            obj[0].suffix
                                        );
                                    } else {
                                        props.query.where.modifyFunction(
                                            props.rowIndex,
                                            `${value}`, 
                                            ''
                                        );  
                                    }
                                    props.updateQueryState();
                                }} 
                                allowClear> 
                                {aggretorOptions.where.map(option => (
                                    <Option id={option.text} key={option.key} value={option.value} >{option.text}</Option>
                                ))}
                            </Select>
                        </div>
                        { fnName ? (
                            <>
                                <div style={{ 
                                    display: 'inline-block',
                                    marginRight: '5px',
                                    marginBottom: '5px'
                                }}>
                                    <WhereColumnSearchBarDetails
                                        type="select"
                                        rowIndex={props.rowIndex}
                                        updateQueryState={props.updateQueryState}
                                        query={props.query}
                                        value={props.row.name}
                                        text={props.row.text}
                                        error={props.row.error}
                                        initial={props.row.initial}
                                    />
                                </div>
                                <br/>
                            </>
                        ) : (
                            <div style={{ 
                                display: 'inline-block',
                                marginRight: '5px'
                            }}>
                                <WhereColumnSearchBar
                                    type="where"
                                    rowIndex={props.rowIndex}
                                    updateQueryState={props.updateQueryState}
                                    query={props.query}
                                    value={props.row.name}
                                    text={props.row.columnText}
                                    error={props.row.columnError}
                                    initial={props.row.initial}
                                />
                            </div>
                        ) }

                        <div
                            style={{
                                display: 'inline-block',
                                marginRight: '5px',
                                marginBottom: '5px'
                            }}
                        >
                            <OperatorSearchBar
                                rowIndex={props.rowIndex}
                                updateQueryState={props.updateQueryState}
                                query={props.query}
                                operatorText={props.row.operatorText}
                                text={props.row.operatorTextText}
                                error={props.row.operatorError}
                            />
                        </div>
                        <div
                            style={{
                                display: 'inline-block',
                                marginRight: '5px',
                                marginBottom: '5px'
                            }}
                        >
                            <Tooltip
                                title={props.query.where.whereRows[props.rowIndex].filterText}
                            >
                                <Input
                                    size="mini"
                                    type="text"
                                    placeholder={`Filter ${props.rowIndex + 1}`}
                                    onChange={e => handleFilterChange(e.target.value)}
                                    value={props.query.where.whereRows[props.rowIndex].filter}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    if (props.snapshot.isDragging) {
        return ReactDOM.createPortal(result, portal);
    }
    return result;
};

class WhereRow extends Component {
  componentDidMount = () => {
      [
          ...document.querySelectorAll('[data-react-beautiful-dnd-drag-handle]'),
      ].map(elem => elem.removeAttribute('tabindex'));
  };

  render() {
      const { rowIndex, query, updateQueryState, row } = this.props;
      return (
          <Draggable
              key={`itemS-${rowIndex}`}
              draggableId={`itemS-${rowIndex}`}
              index={rowIndex}
          >
              {(provided, snapshot) => {
                  return (
                      <div>
                          <PortalDraggableItem
                              rowIndex={rowIndex}
                              query={query}
                              updateQueryState={updateQueryState}
                              row={row}
                              provided={provided}
                              snapshot={snapshot}
                          />
                          {provided.placeholder}
                      </div>
                  );
              }}
          </Draggable>
      );
  }
}

export default WhereRow;
