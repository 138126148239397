import React, { useCallback, useMemo } from "react";
import { useState } from "react";
import styles from "./styles.module.scss";
import { Checkbox } from "@components/form/elements";
import classNames from "classnames";
import omit from "lodash/omit";
import isEmpty from "lodash/isEmpty";
import { PencilEditIcon } from "../assets/common";
import WorkflowConfigForm, {
	WorkflowConfigFormValues,
} from "./WorkflowConfigForm";
import { EditWorkflowConfigModal } from "../modals/edit-workflow-config";
import { isBoolean } from "lodash";
import { getUniqueId, saveTextToFile } from "@utils/common";
import { useDidUpdate } from "rooks5";
import {
	CommonKeysInfoForGlobalArguments,
	getGlobalValueInfoForConfigItem,
	GlobalPipelineConfigMap,
	WorkflowConfig,
	WorkflowConfigInComponentItemInfo,
	WorkflowConfigItem,
	WorkflowConfigTabType,
} from "@services/WorkflowConfig";
import { WorkflowConfigItemType } from "@services/WorkflowConfig";
import { GlobeIcon } from "@assets/icons";
import { TooltipTop } from "@components/tooltips";
import WorkflowConfigImport from "./WorkflowConfigImport";
import { generateConfigJson } from "./WorkflowConfigImport/ConfigParser";
import { sampleConfigForDownload } from "../enums";

const getFormInitialValues = (
	globalArgsMode = false
): WorkflowConfigFormValues => ({
	// to force initial value reset
	id: getUniqueId(),
	key: "",
	type: globalArgsMode ? "" : (WorkflowConfigItemType.String as any),
	value: "",
});

type WorkflowConfigTabProps = {
	config: WorkflowConfig<"component"> | WorkflowConfig<"workflow">;
	onWorkflowConfigUpdate: (config: WorkflowConfigTabProps["config"]) => void;
	resetParamsProp?: any;
	type: WorkflowConfigTabType;
	commonKeysInfoForGlobalArguments?: CommonKeysInfoForGlobalArguments;
	globalPipelineConfig?: GlobalPipelineConfigMap;
	showImportCsv?: boolean;
	workflowName?: string;
};

const WorkflowConfigTab: React.FC<WorkflowConfigTabProps> = ({
	config,
	onWorkflowConfigUpdate,
	resetParamsProp,
	type,
	commonKeysInfoForGlobalArguments,
	globalPipelineConfig,
	showImportCsv,
	workflowName,
}) => {
	const isGlobalArgsMode = !!commonKeysInfoForGlobalArguments?.length;
	const insideComponentProps = type === "CONFIGURATION_INSIDE_COMPONENT";
	const [initialValues, setInitialValues] = useState(
		getFormInitialValues(isGlobalArgsMode)
	);
	const [selectedConfigItems, setSelectedConfigItems] = useState<
		Record<string, boolean>
	>({});
	const [showEditWorkflowModal, toggleEditWorkflowModal] = useState(false);
	const [workflowItemToBeEdited, setWorkflowItemToBeEdited] = useState<
		WorkflowConfigFormValues
	>(getFormInitialValues());

	const onSubmit = (values: WorkflowConfigItem) => {
		const updatedConfig = [...config];
		updatedConfig.push(values);
		onWorkflowConfigUpdate(updatedConfig);
		setInitialValues(getFormInitialValues(isGlobalArgsMode));
	};

	const onCheckboxSelection = useCallback((id: string) => {
		setSelectedConfigItems((state) => ({ ...state, [id]: !state[id] }));
	}, []);

	const noOfSelectedConfigItems = useMemo(
		() => Object.values(selectedConfigItems).filter(Boolean).length,
		[selectedConfigItems]
	);

	const onDeleteConfigItems = () => {
		const updatedConfig = config.filter(
			(configItem) => !selectedConfigItems[configItem.id]
		);
		onWorkflowConfigUpdate(updatedConfig);
		setSelectedConfigItems({});
	};

	const onConfigKeyEdit = (configItem: WorkflowConfigItem) => {
		setWorkflowItemToBeEdited({
			...getFormInitialValues(),
			...configItem,
		});
		toggleEditWorkflowModal(true);
	};

	const configForEditWorkflowModal: WorkflowConfig = useMemo(() => {
		if (workflowItemToBeEdited.key) {
			// Exclude this configItem key from existingMap as editing the same item shows duplicate key error
			return config.filter(
				(__configItem) =>
					__configItem.key !== workflowItemToBeEdited.key
			);
		}
		return config;
	}, [workflowItemToBeEdited, config]);

	const closeEditWorkflowModal = useCallback(() => {
		toggleEditWorkflowModal(false);
	}, []);

	const handleEditWorkflowSubmit = (values: WorkflowConfigItem) => {
		const updatedConfig = [...config];
		const editedItemIndex = updatedConfig.findIndex(
			(config) => config.key === workflowItemToBeEdited.key
		);
		updatedConfig[editedItemIndex] = omit(values, "_id");
		onWorkflowConfigUpdate(updatedConfig);
		closeEditWorkflowModal();
	};

	useDidUpdate(() => {
		// Reset form on every resetParamsProp update
		setInitialValues(getFormInitialValues());
	}, [resetParamsProp]);

	// check if atleast one config item is part of global pipeline config
	const showGlobalIcon = useMemo(
		() =>
			(config as WorkflowConfigInComponentItemInfo[]).some(
				(configItem) =>
					getGlobalValueInfoForConfigItem(
						globalPipelineConfig || {},
						configItem as WorkflowConfigInComponentItemInfo
					).hasGlobalValue
			),
		[globalPipelineConfig, config]
	);

	const onGlobalIconClick = (configKey: string) => {
		const updatedConfig = (config as WorkflowConfigInComponentItemInfo[]).map(
			(configItem) => {
				if (configKey === configItem.key) {
					return {
						...configItem,
						usesGlobalValue: !configItem.usesGlobalValue,
					};
				}
				return configItem;
			}
		);
		onWorkflowConfigUpdate(updatedConfig);
	};

	const onDownloadConfig = useCallback(() => {
		const configToBeDownloaded = isEmpty(config) ? sampleConfigForDownload : config;
		const stringifedConfig = generateConfigJson(configToBeDownloaded);
		saveTextToFile(`${workflowName}_config.json`, stringifedConfig);
	}, [config, workflowName]);

	return (
		<div className={styles["workflowConfig__container"]}>
			{!insideComponentProps && (
				<WorkflowConfigForm
					initialValues={initialValues}
					onSubmit={onSubmit}
					config={config}
					commonKeysInfoForGlobalArguments={
						commonKeysInfoForGlobalArguments
					}
				>
					{({ isValid }) => (
						<button
							className="btn btn-yellow btn-md btn-block"
							type="submit"
							disabled={!isValid}
						>
							{isGlobalArgsMode ? "Apply" : "Add Pair"}
						</button>
					)}
				</WorkflowConfigForm>
			)}
			<div
				className={classNames(
					styles["workflowConfig__list__container"],
					{
						[styles[
							"workflowConfigList__insideComponent"
						]]: insideComponentProps,
					}
				)}
			>
				{!insideComponentProps && (
					<div className={styles["workflowConfig__list_header"]}>
						<p className={styles["workflowConfig__list__title"]}>
							{isGlobalArgsMode ? (
								"Global Arguments"
							) : (
								<>
									Key/Value Pairs
									{showImportCsv && (
										<TooltipTop
											placement="topRight"
											overlay="To start importing workflow arguments, click on the download icon for a sample template. This template provides a list of data types supported by workflow arguments"
										>
											<img
												src="/icons/info-fields.png"
												width="16"
												height="16"
												className="info__icon"
												alt="information-icon"
											/>
										</TooltipTop>
									)}
								</>
							)}
						</p>
						<div className={styles.workflowConfig__actionBtns}>
							{noOfSelectedConfigItems ? (
								<button
									className={classNames(
										"btn btn-md btn-grey-transparent",
										styles.deleteConfigItemsButton
									)}
									type="button"
									onClick={onDeleteConfigItems}
								>
									<img
										src="/icons/workflow/delete.svg"
										alt=""
									/>
									Delete Selected ({noOfSelectedConfigItems})
								</button>
							) : (
								showImportCsv && (
									<WorkflowConfigImport
										config={config}
										onWorkflowConfigUpdate={
											onWorkflowConfigUpdate
										}
									/>
								)
							)}
							{showImportCsv && (
								<button
									className={classNames(
										"btn btn-md btn-grey-transparent",
										styles.downloadConfigItemsButton
									)}
									type="button"
									onClick={onDownloadConfig}
								>
									<img
										src="/icons/workflow/download_code.svg"
										alt="download-config"
									/>
								</button>
							)}
						</div>
					</div>
				)}
				<div className={styles["workflowConfig__tableWrapper"]}>
					<table
						className={classNames(styles["workflowConfig__table"], {
							[styles[
								"workflowConfig__table__globalPipeline"
							]]: showGlobalIcon,
						})}
					>
						<thead>
							<tr>
								{!insideComponentProps && (
									<th className={styles.checkboxColumn} />
								)}
								<th className={styles.keyColumn}>Key</th>
								<th className={styles.typeColumn}>Type</th>
								<th className={styles.valueColumn}>Value</th>
								{showGlobalIcon && (
									<th className={styles.checkboxColumn} />
								)}
								<th className={styles.checkboxColumn} />
							</tr>
						</thead>
						<tbody>
							{config.map((configItem) => {
								const {
									globalValue,
									hasGlobalValue,
									isGloballySelected,
								} = getGlobalValueInfoForConfigItem(
									globalPipelineConfig || {},
									configItem as WorkflowConfigInComponentItemInfo
								);

								const valueToShow = isGloballySelected
									? globalValue
									: configItem.value;

								return (
									<tr key={configItem.id}>
										{!insideComponentProps && (
											<td
												className={
													styles.checkboxColumn
												}
											>
												<Checkbox
													checked={
														selectedConfigItems[
															configItem.id
														]
													}
													onClick={() =>
														onCheckboxSelection(
															configItem.id
														)
													}
													color="gold"
												/>
											</td>
										)}
										<td className={styles.keyColumn}>
											<div className={styles.tableText}>
												{configItem.key}
											</div>
										</td>
										<td className={styles.typeColumn}>
											{configItem.type}
										</td>
										<td className={styles.valueColumn}>
											<div className={styles.tableText}>
												{isBoolean(valueToShow)
													? JSON.stringify(
															valueToShow
													  )
													: valueToShow}
											</div>
										</td>
										{showGlobalIcon && (
											<td className={styles.globalColumn}>
												<TooltipTop
													title={
														isGloballySelected
															? "Click to assign local value"
															: "Click to assign global value"
													}
												>
													<button
														onClick={onGlobalIconClick.bind(
															null,
															configItem.key
														)}
														type="button"
														className={
															styles.globalIconBtn
														}
													>
														{hasGlobalValue && (
															<GlobeIcon
																active={
																	isGloballySelected
																}
															/>
														)}
													</button>
												</TooltipTop>
											</td>
										)}
										<td className={styles.editColumn}>
											<button
												onClick={() =>
													onConfigKeyEdit(configItem)
												}
												disabled={isGloballySelected}
												type="button"
											>
												<PencilEditIcon />
											</button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				{isEmpty(config) && (
					<span className={styles.emptyKVMessage}>
						Nothing to see here
					</span>
				)}
				<EditWorkflowConfigModal
					initialValues={workflowItemToBeEdited}
					showModal={showEditWorkflowModal}
					closeModal={closeEditWorkflowModal}
					onSubmit={handleEditWorkflowSubmit}
					config={configForEditWorkflowModal}
					type={type}
				/>
			</div>
		</div>
	);
};

export default WorkflowConfigTab;
