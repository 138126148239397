import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import ReactEcharts from 'echarts-for-react'; 
import { ShowWhenTrue } from '../../../helpers';
import { Dropdown, DropdownOptions } from '../../form/dropdown';
import { analyticsDimensions } from './node_component';


type graphTypes = 'bar' | 'line' | 'pie' | 'scatter';

export interface ComponentAnalyticsProps {
    graphData?: {
        data: Record<string, number | string | boolean | null>[];
        schema: Record<'fields', {type: string; name: string}[]>;
    }; 
    analyticsDimensions: analyticsDimensions;
    show: boolean;
    className?: string;
}

export const AnalyticsGraphs: React.FC<ComponentAnalyticsProps> = ({ graphData, analyticsDimensions, show }) => {
    const [xAxisField, setXAxisField] = useState('');
    const [yAxisField, setYAxisField] = useState('');
    const [graphType, setGraphType] = useState<graphTypes>('bar');
    const [availableColumns, setAvailableColumns] = useState<DropdownOptions>({});
    const [dataForChart, setDataForChart] = useState<Record<string, any>>({
        legend: {},
        tooltip: {},
        dataset: { source: [] },
        xAxis: {type: 'category', name: xAxisField, nameLocation: 'middle', nameGap: 30, nameTextStyle: { color: '#B0BAC9', fontWeight: 500 } },
        yAxis: {name: yAxisField, nameLocation: 'middle', nameGap: 35, nameTextStyle: { color: '#B0BAC9', fontWeight: 500 } },
        series: [
            {type: graphType,
                encode: {
                    x: xAxisField,
                    y: yAxisField
                }
            }
        ],
        textStyle: {
            color: '#B0BAC9'
        }
    });


    useEffect(() => {
        // console.log(graphData);  
        graphData && setDataForChart({ 
            ...dataForChart, 
            dataset: { source: graphData.data, itemStyle: {
                color: '#fff'
            } },
            xAxis: { type: 'category', name: xAxisField, nameLocation: 'middle', nameGap: 30, offset: 3,nameTextStyle: { color: '#B0BAC9', fontWeight: 500 }, 
                axisLine: {
                    onZero: true,
                    show: true,
                    lineStyle: {
                        type: 'solid',
                        color: '#fff'
                    }
                },
            }, 
            yAxis: { name: yAxisField, nameLocation: 'middle', nameGap: 35, nameTextStyle: { color: '#B0BAC9', fontWeight: 500 },
                axisLine: {
                    // onZero: true,
                    show: true,
                    lineStyle: {
                        type: 'solid',
                        color: '#fff'
                    }
                },
            },
            toolbox: {
                show: true,
                orient: 'horizontal' ,
                itemSize: 15 ,
                itemGap: 10,
                feature: {
                    dataZoom: {
                        show: true, 
                        title: {
                            zoom: 'Zoom In',
                            back: 'Zoom Out'
                        }
                    },
                    restore: {
                        title: 'Reset'
                    },
                    saveAsImage: {
                        title: 'Download'
                    }
                },
                z: 3,
                right: 10
            },
            dataZoom: [
                {
                    type: 'inside',
                    start: 0,
                    end: 100
                }, {
                    start: 0,
                    end: 10,
                    borderColor: 'transparent',
                    dataBackground: {
                        areaStyle:{
                            color: '#00C896'
                        }
                    },
                    backgroundColor: '#2a2c42',
                    handleSize: '100%',
                    handleStyle: {
                        color: '#fcad01',
                    }
                },
                {
                    type: 'inside',
                    orient: 'vertical',
                    start: 0,
                    end: 100
                }, {
                    orient: 'vertical',
                    start: 0,
                    end: 10,
                    borderColor: 'transparent',
                    dataBackground: {
                        areaStyle:{
                            color: '#00C896'
                        }
                    },
                    backgroundColor: '#2a2c42',
                    handleSize: '100%',
                    handleStyle: {
                        color: '#fcad01',
                    }
                }
            ],
            series: [
                {type: graphType,
                    encode: {
                        x: xAxisField,
                        y: yAxisField
                    },
                    roundCap: true,
                    itemStyle: {
                        color: '#00C896'
                    },
                    barWidth: '7px'
                }
            ]
        });
    }, [xAxisField, yAxisField, graphType, graphData]);

    useEffect(() => {
        const allColumnsData: DropdownOptions = {}; 
        if(graphData && graphData.schema) {
            graphData.schema.fields.map(fieldInfo => {
                allColumnsData[fieldInfo.name] = { disabled: fieldInfo.name === xAxisField || fieldInfo.name === yAxisField };
            });
            setAvailableColumns(allColumnsData);
        }
       
    }, [xAxisField, yAxisField, graphData]);


    return(
        <div className={classNames('ds__node__graph__container', {'hide': !show})}>
            <div className="ds__node__fields__box"> 
                <Dropdown
                    title="Graph types"
                    dropdownOptions={{
                        'Bar graph': {action: () => setGraphType('bar')},
                        'Line graph': {action: () => setGraphType('line')},
                        'Pie chart': {action: () => setGraphType('pie')},
                        'Scatter graph': {action: () => setGraphType('scatter')},
                    }}
                    dynamicTitle
                />
                <Dropdown
                    title="X-Axis"
                    dropdownOptions={availableColumns}
                    onClickItem={setXAxisField}
                    dynamicTitle
                />
                <Dropdown
                    title="Y-Axis"
                    dropdownOptions={availableColumns}
                    onClickItem={setYAxisField}
                    dynamicTitle
                />
                
            </div>
            <ShowWhenTrue show={!!(xAxisField) && !!(yAxisField)}>
                <ReactEcharts
                    style={analyticsDimensions}
                    option={dataForChart}
                />

            </ShowWhenTrue>
        </div>
    );
};


