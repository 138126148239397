import omit from "lodash/omit";
import React, { useEffect, useMemo, useState } from "react";
import { DataSourceHandler } from "../../../api/data-source-handler";
import Form, { InputField, NewSelectField } from "../../../components/form";
import { Modal } from "../../../components/modals";
import CustomMonacoEditor from "../../../components/monacoEditor";
import { errorAlert } from "../../../components/toastify/notify-toast";
import { toggleDataBrowserModal } from "../../../store/datasources";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { PiRequestInfo } from "../pi-managed-requests-table";
import { TableMetaData } from "../treeviews/connections-treeview";
import { SelectField, _selectoptionType } from '../../../components/form/select-field';
import * as yup from "yup";
import styles from "../styles.module.scss";

export type NewEditPiRequestModalTypes = "new" | "edit" | "view";

type Props = {
	activeRecordsMetadata: TableMetaData;
	refreshActivePrRecords: () => void;
	type: NewEditPiRequestModalTypes;
	selectedPiRequestInfo: PiRequestInfo | null;
};

const _modalTitles: Record<NewEditPiRequestModalTypes, string> = {
	new: "Create",
	view: "View",
	edit: "Edit",
};

const formSchema = yup.object().shape({
	requestName: yup
		.string()
		.required("This is a required field")
		.matches(/^[A-Za-z0-9_-]+$/, "Special Characters are not allowed except _ and -"),
	sinkName: yup
		.string()
		.required("This is a required field")
});

type FormSchemaType = yup.InferType<typeof formSchema>;

const NewEditPiRequestModal: React.FC<Props> = ({
	activeRecordsMetadata,
	refreshActivePrRecords,
	selectedPiRequestInfo,
	type
}) => {
	const showModal = useAppSelector(
		(store) => store.NewDataSourceReducer.showModal.newPiRequest
	);
	const editorControllerProps = CustomMonacoEditor.useCustomMonacoEditorController();
	const dispatch = useAppDispatch();
	const [sinkOptions, setSinkOptions] = useState<{label:string,value:string,type:string}[]>([]);
	
	useEffect(() => {
		if (showModal) {
			if (selectedPiRequestInfo) {
				try {
					let requestJson = JSON.parse(selectedPiRequestInfo.json);
					requestJson = omit(requestJson, "request_id");
					editorControllerProps.setEditorValue(
						JSON.stringify(requestJson, null, 4)
					);
				} catch {
					errorAlert("Error parsing the Request body");
				}
			} else {
				editorControllerProps.setEditorValue("");
			}
		}
	}, [showModal, type, selectedPiRequestInfo]);

	const toggleClose = () => {
		editorControllerProps.clearEditor();
		dispatch(toggleDataBrowserModal("newPiRequest", false));
	};

	const onRequestSuccess = () => {
		refreshActivePrRecords();
		toggleClose();
	};

	const handleOnSubmit = (values: FormSchemaType) => {
		try {
			const sinkKind = sinkOptions.filter((item)=>item.label === values.sinkName)[0].type;
			// added to check syntax validation
			const parsedJson = JSON.parse(editorControllerProps.editorValue);
			if (type === "edit" && selectedPiRequestInfo) {
				parsedJson.request_id = selectedPiRequestInfo.requestId;
				DataSourceHandler.UpdatePiRequestJson(
					selectedPiRequestInfo.requestId,
					values.requestName,
					JSON.stringify(parsedJson),
					values.sinkName,
					sinkKind,
					onRequestSuccess
				);
			} else if(activeRecordsMetadata.managedNodeInfo?.nodeName){
				DataSourceHandler.CreatePiNodeRequest(
					activeRecordsMetadata.managedNodeInfo.nodeName,
					values.requestName,
					editorControllerProps.editorValue,
					values.sinkName,
					sinkKind,
					onRequestSuccess,
				);
			}
		} catch (e) {
			errorAlert("Invalid JSON");
		}
	};

	const [initialValues, setIntitialValues] = useState({ requestName: "",sinkName:'' });

	useEffect(() => {
		if(showModal && activeRecordsMetadata.managedNodeInfo?.nodeName) {
			if (type === "new") { 
				// sending state as empty returns all records 
				DataSourceHandler.GetRequestsOfPiNode(activeRecordsMetadata.managedNodeInfo.nodeName, { page: 1, size: 5, filter: "", sort: "updated,desc", state: [] }, (res) => {
					// sinkName should be  in response -- will add it once done from backend
					setIntitialValues({ requestName:  "request_" + res.totalItems,sinkName:'' })
				})
			} else if (type === "edit" && selectedPiRequestInfo?.requestName  && selectedPiRequestInfo?.sinkName) {
				// sinkName should be  in response -- will add it once done from backend
				setIntitialValues({ requestName:  selectedPiRequestInfo?.requestName,sinkName:selectedPiRequestInfo?.sinkName })
			}
		}

	}, [type, showModal, activeRecordsMetadata.managedNodeInfo]);

	useEffect(() => {
        DataSourceHandler.GetSinkNodes((res) => {
            setSinkOptions(res.map((_res: any) => {
                return {
                    label: _res.sinkName,
                    value: _res.sinkName,
					type:  _res.sinkKind
                }
            }))
        });
    },  []);

	return (
		<Modal
			isOpen={showModal}
			toggleClose={toggleClose}
			title={`${_modalTitles[type]} Request`}
			subtitle={`${
				type !== "new"
					? `${selectedPiRequestInfo?.requestName ??
							selectedPiRequestInfo?.id} - `
					: ""
			}${activeRecordsMetadata.connectionName}`}
			className="queryBuilderModal__container newPiRequestModal__container"
		>
			<Form
				initialValues={initialValues}
				onSubmit={handleOnSubmit}
				validationSchema={formSchema}
				enableReinitialize
			>
				{type !== "view" && (
					<>
						<InputField
							name="requestName"
							label="Request Name"
							className={styles["requestName__field"]}
						/>
						 <NewSelectField
							name="sinkName"
							label="Choose Sink"
							options={sinkOptions}
							required={true}
							className={styles["requestName__field"]}
						/>
					</>
				)}

				<CustomMonacoEditor
					{...editorControllerProps}
					editorHeight={250}
					editorWidth="100%"
					language="json"
					readOnly={type === "view"}
					toolbarOptions={
						type === "view" ? ["Copy"] : ["Copy", "Clear"]
					}
					label="Request Body"
					required
				/>
				<div className="actionButtons__box">
					{type === "edit" || type === "new" ? (
						<>
							<button
								className="btn-md c-w"
								onClick={toggleClose}
								type="button"
							>
								Cancel
							</button>
							<button className="btn-md btn-yellow" type="submit">
								{type === "edit" ? "Update" : "Create"}
							</button>
						</>
					) : (
						<button
							className="btn-md btn-grey"
							onClick={toggleClose}
							type="button"
						>
							Close
						</button>
					)}
				</div>
			</Form>
		</Modal>
	);
};

export { NewEditPiRequestModal };
