import { DefaultLinkModel, PortModel, PortModelAlignment, PortModelGenerics } from '@projectstorm/react-diagrams';
import { DS } from '../enums';
import { DsNodeModel } from '../node/ds_nodemodel';


export class DsLinkModel extends DefaultLinkModel {
    constructor(color = 'normal') {
        super({
            type: DS.name,
            width: 3,
            color
        });
    }

    
    
    getConnectedNode(currentNodeId: string): DsNodeModel | undefined {
        // returns the node of the link which is on the other side of the link
        const sourcePortNode = this.sourcePort?.getParent() as DsNodeModel;
        const targetPortNode = this.targetPort?.getParent() as DsNodeModel | undefined;
        if(targetPortNode && (targetPortNode.getID() !== currentNodeId)) return targetPortNode;
        return sourcePortNode;
    }


    setTargetPort(port: PortModel<PortModelGenerics> | null): void {
        super.setTargetPort(port);
        port?.getNode().fireEvent({ targetPort: port, sourcePort: this.getSourcePort(), link: this }, 'linkConnected');
    }

    isTargetPortInPort() {
        return this.targetPort?.getOptions().alignment === PortModelAlignment.LEFT
    }
}