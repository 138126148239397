import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { errorAlert } from '../toastify/notify-toast';

interface Props {
    show: boolean;
    value: string;
    onChange: (val: string) => void;
    saveChanges: () => void;
    discardChanges: () => void;
    showError?: boolean;
    errorMessage?: string;
}

export const InlineInputField: React.FC<Props> = ({ show, saveChanges, discardChanges, value, onChange, showError = false, errorMessage = '' }) => {
    const inputContainerRef = useRef<HTMLDivElement>(null);
    const inputFieldRef = useRef<HTMLInputElement>(null);

    const isValueEmpty = value === '';
    
    const handleSaveChanges = () => {
        if(!isValueEmpty)
            if(showError) {
                errorAlert(errorMessage || 'Name exists');
            } else {
                saveChanges();
            }
    };


    const handleClickOutsideRenameField = (event: MouseEvent) => {
        if(inputContainerRef.current && !inputContainerRef.current.contains(event.target as any)) {
            handleSaveChanges();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideRenameField);
        inputFieldRef.current?.select();
        inputFieldRef.current?.focus();
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideRenameField);
        };
    }, [show]);

    

    


    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.keyCode === 27) {
            discardChanges();
        } else if(e.keyCode === 13) {
            handleSaveChanges();
        }
    };


    return(
        <div
            className={'inputfield__container inlineInputField'}
            ref={inputContainerRef}
        >
            <input 
                className={classNames('inputfield', {'inputfield--error': showError || isValueEmpty })}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onKeyDown={onKeyDown}
                ref={inputFieldRef}
                autoFocus
            />
        </div>
    );
};