import React from 'react';
import { FieldCreatorProps, strictFunc } from '../field-creator';
import { useField, useFormikContext } from 'formik';
import { ShowWhenTrue } from '../../../helpers';
import { Tooltip } from 'antd';
import { capitalize, get, isEmpty, omit } from 'lodash';
import { TooltipTop } from '../../tooltips';
import { InputField } from '../../form';
import { BaseFieldType } from '../types';
import { uuid } from 'uuidv4';

const getKeyValueFieldInfo = (
    fieldData: BaseFieldType,
    rowId: string,
    number: number,
    type: 'key' | 'value'
) => ({
    name: `${fieldData.key}.data.${rowId}.${type}`,
    variableLabel: `${fieldData.templateOptions.label}: ${capitalize(
        type
    )}${number}`
});

// {
// 	"rows": ["37613fc3-1948-4af8-a7ee-6beb1f55ac9f", "ce850c63-8f62-41e1-ad8f-28231a8fd383"],
// 	"data": {
// 		"37613fc3-1948-4af8-a7ee-6beb1f55ac9f": {
// 			"key": "key1",
// 			"value": "value1"
// 		},
// 		"ce850c63-8f62-41e1-ad8f-28231a8fd383": {
// 			"key": "key2",
// 			"value": "value2"
// 		}
// 	}
// }

export type KeyPairTableValueType = {
    rows: string[];
    data: Record<string, { key: string; value: string }>;
};

const KeyPairTable: React.FC<FieldCreatorProps> = ({ fieldData }) => {
    const { values, setFieldValue } = useFormikContext<any>();
    const label = fieldData.templateOptions.label;

    const [, { value: rows }, { setValue }] = useField(fieldData.key + '.rows');

    const addRow = () => {
        const __value = rows ? [...rows, uuid()] : [uuid()];
        setValue(__value);
    };

    const deleteRow = (rowIdToBeDeleted: string) => {
        const _updatedRows = rows.filter(
            (rowId: string) => rowId !== rowIdToBeDeleted
        );
        setValue(_updatedRows);
        let rowData = { ...get(values, `${fieldData.key}.data`) };
        rowData = omit(rowData, rowIdToBeDeleted);
        setFieldValue(`${fieldData.key}.data`, rowData);
    };

    const isHidden = strictFunc(values, fieldData?.hideExpression  || '');

    return (
        <ShowWhenTrue show={!isHidden}>
            <div className="tableHolder">
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <div className="align-middle">
                        <label className="inputfield__label">{label}</label>
                        <ShowWhenTrue show={!!fieldData.templateOptions.qtip}>
                            <TooltipTop
                                placement="topRight"
                                overlay={fieldData.templateOptions.qtip}
                            >
                                <img
                                    src="/icons/info-fields.png"
                                    width="16"
                                    height="16"
                                    className="info__icon"
                                    alt="information-icon"
                                />
                            </TooltipTop>
                        </ShowWhenTrue>
                    </div>
                    <div className="align-middle">
                        <TooltipTop overlay="Add Row">
                            <img
                                src="/icons/add_tab.svg"
                                style={{
                                    width: 25,
                                    transform: 'scale(0.55)'
                                }}
                                onClick={addRow}
                            ></img>
                        </TooltipTop>
                    </div>
                </div>
                <table
                    style={{
                        width: '100%',
                        marginTop: 10
                    }}
                >
                    <tbody>
                        {!isEmpty(rows) && (
                            <>
                                <tr className="keyValue__placeholder">
                                    <td>Key</td>
                                    <td>&nbsp;&nbsp;&nbsp;</td>
                                    <td>Value</td>
                                </tr>
                                {rows.map((rowId: string, index: number) => {
                                    const keyField = getKeyValueFieldInfo(
                                        fieldData,
                                        rowId,
                                        index + 1,
                                        'key'
                                    );
                                    const valueField = getKeyValueFieldInfo(
                                        fieldData,
                                        rowId,
                                        index + 1,
                                        'value'
                                    );
                                    // IN VARIABLE DROPDOWN, KEY IS SHOWN AS LABEL, VALUE KEY IS SPECIFIED FOR IT TO UPDATE VARIABLE IN THE VALUE
                                    return (
                                        <tr key={rowId}>
                                            <td>
                                                <div
                                                    data-fieldlabelforvariable={keyField.variableLabel}
                                                    data-fieldkey={keyField.name}
                                                >
                                                    <InputField
                                                        name={keyField.name}
                                                    />
                                                </div>
                                            </td>
                                            <td> = </td>
                                            <td>
                                                <div
                                                    data-fieldlabelforvariable={valueField.variableLabel}
                                                    data-fieldkey={valueField.name}
                                                >
                                                    <InputField
                                                        name={valueField.name}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <Tooltip
                                                    placement="topLeft"
                                                    overlay="Remove row"
                                                >
                                                    <img
                                                        onClick={() => {
                                                            deleteRow(rowId);
                                                        }}
                                                        src="/icons/close_tab.svg"
                                                        alt=""
                                                    />
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </>
                        )}
                    </tbody>
                </table>
            </div>
        </ShowWhenTrue>
    );
};

export default KeyPairTable;
