import React, { useEffect, useState, useRef} from 'react';
import { ToggleField } from './toggle-field';
import { useToggle } from 'rooks';
import MonacoEditor from "react-monaco-editor";
import * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';
import 'monaco-editor/esm/vs/basic-languages/monaco.contribution';
import 'monaco-editor/esm/vs/editor/edcore.main';
import 'monaco-editor/esm/vs/language/typescript/monaco.contribution';
import 'monaco-editor/esm/vs/editor/standalone/browser/standaloneServices';
import 'monaco-editor/esm/vs/language/json/monaco.contribution';
import { DataSourceHandler } from '@api/data-source-handler';
import classNames from 'classnames';
import { AiFillSetting } from 'react-icons/ai';
import { InPageSpinner } from '../../components/spinners/in-page-spinner';
import OpenAISettings from '@pages/data-explorer/components/openaiSettings';
import { Modal } from "@components/modals";
import { errorAlert } from '@components/toastify/notify-toast';
import _ from 'lodash';

const TextToSQL: React.FC<{
    onEditorValueChange: (value: string) => void,
    loadingSchemaCapture: boolean,
    initiateSchemaCapture: () => void,
    onToggle: (value: boolean) => void,
    failedSchemaCapture: boolean,
    handleViewLogs: (value: boolean) => void
}> = ({
    onEditorValueChange,
    loadingSchemaCapture,
    initiateSchemaCapture,
    onToggle,
    children,
    failedSchemaCapture,
    handleViewLogs,
}): JSX.Element => {
    const [value, toggle] = useToggle();
    const [queryData, setQueryData] = useState("");
    const [loadingQuery, ShowLoadingQuery] = useState(false);
    const [showOpenAISettings,setOpenAISettingsView] = useState(false);
    const openAiSettings = useRef<any>({
        temperature: 0,
        max_tokens: 750,
        top_p: 0.5,
        frequency_penalty: 0.55,
        presence_penalty: 0.49,
        best_of: 5
    });

    useEffect(() => {
        onToggle(value);
        // eslint-disable-next-line no-console
        if(loadingSchemaCapture || !value || !_.isEmpty((window as any).options_schema?.openaisql)) return;
        initiateSchemaCapture();
    }, [value]);

    const ProvideCompletetionItem = (model: monacoEditor.editor.ITextModel, position: monacoEditor.Position) => {
        const wordAtPosition = model.getWordUntilPosition(position);

        const suggestions = ((window as any).options_schema?.suggestion).filter((da: any) => {
            return da.colName.toLowerCase().includes(wordAtPosition?.word.toLowerCase() || '') //reg.test(da.colName)
        }).map((da: any) => {
            return {
                label: da.colName,
                insertText: da.colName,
                kind: monacoEditor.languages.CompletionItemKind.Operator,
                detail: da.tableName
            }
        });
        return {
            suggestions
        }
    }

    useEffect(() => {
        monacoEditor.languages.register({id: 'deepiq'})
        const temp = monacoEditor.languages.registerCompletionItemProvider('deepiq', {
            triggerCharacters: [''],
            // @ts-ignore
            provideCompletionItems: ProvideCompletetionItem,
        });
        return () => {
            temp.dispose();
        }
    }, []);

    const onGenerate = async () => {
        try{
            ShowLoadingQuery(true);
            const temp = [
                "### Postgres SQL tables, with their properties:",
                "#",
                (window as any).options_schema?.openaisql,
                "### " + queryData,
                "SELECT "
            ]
            const resp = await DataSourceHandler.OpenAI(temp.join("\n"), openAiSettings.current)
            ShowLoadingQuery(false);
            onEditorValueChange(resp.data.sql)
        } catch (e) {
            ShowLoadingQuery(false);
            errorAlert("Something went wrong");
        }
    };

    const onLogView = () => {
        handleViewLogs(true);
    }

    return (
        <div className='mb-1'>
            <div className='flex justify-between'>
                <ToggleField
                    label="Text to SQL"
                    active={value}
                    disabled={loadingSchemaCapture}
                    onClick={(_value) => {
                        toggle();
                    }}
                />
            </div>
            {
                value === true? (
                    <>
                        {
                            (loadingSchemaCapture ? (
                                <div className='center h-auto mt-1'>
                                    <InPageSpinner size='Small' />
                                    <span>Please wait, while we get the schema.</span>
                                </div>
                            ) : null)
                        }
                        {
                            (failedSchemaCapture? <>
                            <button className="btn-viewLog" onClick={onLogView} type="button">
                                View log
                            </button></> : null)
                        }
                        {
                            !(loadingSchemaCapture || failedSchemaCapture) &&  (
                                <div className='sqltext clear'>
                                    <div className='flex justify-between align-center'>
                                        <div className='flex justify-between align-center w-100'>
                                            <label>Enter text</label>
                                            <button className={classNames("iconButton", "ml-1")} onClick={()=>{
                                                setOpenAISettingsView(true);
                                            }}>
                                                <AiFillSetting />
                                            </button>
                                        </div>
                                    </div>
                                    <MonacoEditor
                                        height='150px'
                                        width='100%'
                                        theme='vs-dark'
                                        value={queryData}
                                        language='deepiq'
                                        onChange={(value) => {
                                            //setSQLData(value);
                                            setQueryData(value)
                                        }}
                                        options={{
                                            selectOnLineNumbers: true,
                                            'contextmenu': false,
                                            'formatOnPaste': true,
                                            'accessibilitySupport': 'auto',
                                            'autoIndent': 'full',
                                            'highlightActiveIndentGuide': true,
                                            'quickSuggestions': true,
                                            formatOnType: true,
                                            minimap: {
                                                enabled: false,
                                            },
                                            fontSize: 14,
                                            letterSpacing: 0.25,
                                            lineNumbersMinChars: 0,
                                            lineDecorationsWidth: 0,
                                            lineHeight: 21,
                                            scrollbar:{
                                                verticalScrollbarSize: 0,
                                                horizontalScrollbarSize: 10,
                                                verticalSliderSize: 4,
                                                horizontalSliderSize: 4,
                                            },
                                            glyphMargin: false,
                                            automaticLayout: true,
                                            lineNumbers: 'off',
                                            folding: false,
                                        }}
                                    />
                                    <div className='flex justify-between align-center  mt-1'>
                                        {
                                            loadingQuery ? (
                                                <div className='center h-auto mt-1'>
                                                    <InPageSpinner size='Small' />
                                                    <span>Please wait.</span>
                                                </div>
                                            ) : (
                                                <button className='btn-sm btn-yellow-transparent float-right' onClick={onGenerate} >Generate Query</button>
                                            )
                                        }

                                        <div>
                                        {children}
                                        </div>
                                    </div>
                                    <div className='clear'></div>
                                </div>
                            )
                        }
                    </>
                ) :  null
            }
            <Modal
                isOpen={showOpenAISettings}
                toggleClose={()=>{setOpenAISettingsView(false)}}
                title="Settings"
                subtitle=""
                className={
                   ""
                }
                showCloseMark
            >
                <OpenAISettings 
                    value={openAiSettings.current}
                    visible={showOpenAISettings}
                    onSubmit={(val: any) => {
                        openAiSettings.current = val;
                        setOpenAISettingsView(false);
                    }}
                    />
            </Modal> 
        </div>
    )
};


export default TextToSQL;