/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import HandGrab from './handGrab';

import { Draggable } from 'react-beautiful-dnd';
import Buttons from './buttons';

import SelectColumnSearchBar from './SelectColumnSearchBar';
import Aggregator from './aggregator';
import { Checkbox, Tooltip } from 'antd';

const portal = document.createElement('div');
document.body.appendChild(portal);

const PortalDraggableItem = props => {
    const result = (
        <div 
            ref={props.provided.innerRef} {...props.provided.draggableProps}>
            <div className="drag" 
                style={{
                    backgroundColor: 'rgba(102, 106, 141, 0.72)', 
                    borderRadius: '2px',
                    margin: '5px 0',
                    padding: '5px'
                }}>
                <div style={{ position: 'relative', width: '100%' }}>
                    <div className="query_position_indicator"></div>
                    <div
                        {...props.provided.dragHandleProps}
                        style={{  
                            display: 'inline-block',
                            position: 'absolute',
                            top: '50%',
                            transform: 'translate(-50%, -50%)',
                            left: '5px'
                        }}
                    >
                        <HandGrab />
                    </div>
                    <div style={{
                        marginLeft: '15px'
                    }}>
                        <div style={{ 
                            display: 'inline-block', 
                            position: 'absolute', 
                            right: 0, 
                            top: 5, 
                            marginRight: '5px'
                        }}>
                            <Buttons
                                type="selectRow"
                                updateQueryState={props.updateQueryState}
                                rowIndex={props.rowIndex}
                                query={props.query}
                            />
                        </div>
                        <div style={{ 
                            display: 'inline-block', 
                            marginRight: '5px', 
                            marginBottom:'5px' 
                        }}>
                            <Aggregator
                                rowIndex={props.rowIndex}
                                updateQueryState={props.updateQueryState}
                                query={props.query}
                            />
                        </div>
                        <div style={{ 
                            marginRight: '5px', 
                            marginBottom:'5px', 
                            display: 'inline-block' 
                        }}>
                            <SelectColumnSearchBar
                                type="select"
                                rowIndex={props.rowIndex}
                                updateQueryState={props.updateQueryState}
                                query={props.query}
                                value={props.row.name}
                                text={props.row.text}
                                error={props.row.error}
                                initial={props.row.initial}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    if (props.snapshot.isDragging) {
        return ReactDOM.createPortal(result, portal);
    }
    return result;
};

class SelectRow extends Component {
  componentDidMount = () => {
      [
          ...document.querySelectorAll('[data-react-beautiful-dnd-drag-handle]'),
      ].map(elem => elem.removeAttribute('tabindex'));
  };

  render() {
      const { rowIndex, query, updateQueryState, row } = this.props;
      return (
          <Draggable
              key={`itemS-${rowIndex}`}
              draggableId={`itemS-${rowIndex}`}
              index={rowIndex}
          >
              {(provided, snapshot) => {
                  return (
                      <div>
                          <PortalDraggableItem
                              snapshot={snapshot}
                              provided={provided}
                              row={row}
                              query={query}
                              updateQueryState={updateQueryState}
                              rowIndex={rowIndex}
                          />
                          {provided.placeholder}
                      </div>
                  );
              }}
          </Draggable>
      );
  }
}

export default SelectRow;
