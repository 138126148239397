import React from 'react';
import styles from '../../styles.module.scss';
import cs from 'classnames';
import { UserForm } from './UserForm';
import { SelectedUserInfo } from '../../types';
import { getUserStatusStyle } from '../../utils';
import { InPageSpinner } from '../../../../components/spinners/in-page-spinner';
import { ShowWhenTrue } from '../../../../helpers';

type UserInfoProps = {
    selectedUserInfo: SelectedUserInfo;
    showUserMgmtModal: () => void;
    showLoader: boolean;
};

const UserInfoSection: React.FC<UserInfoProps> = ({ selectedUserInfo, showUserMgmtModal, showLoader }) => {
    return (
        <div className={styles['userInfoPane']}>
            <div>
                <div className={styles['userInfoPaneHeader']}>
                    <p>User Details</p>
                    <div>
                        {selectedUserInfo ? (
                            <>
                                <span>{selectedUserInfo.fullName}</span>
                                <span
                                    className={cs(
                                        getUserStatusStyle(
                                            selectedUserInfo.enabled
                                        ),
                                        'fl-r'
                                    )}
                                >
                                    {selectedUserInfo.status}
                                </span>
                            </>
                        ) : (
                            <span>Select a user to view options</span>
                        )}
                    </div>
                </div>
                <div
                    className={cs(styles['userInfoFormContainer'])}
                >
                    {showLoader ?
                        <InPageSpinner className={styles['userInfoFormContainerSpinner']}/>
                        :
                        selectedUserInfo ? (
                            <UserForm
                                type="readOnly"
                                initialValues={selectedUserInfo}
                                hideUserStatus
                            />
                        ) : (
                            <span>Select a user</span>
                        )}
                </div>
            </div>

            <ShowWhenTrue show={!showLoader && !!selectedUserInfo}>
                <div className={styles['userInfoFormActionBtnsContainer']}>
                    <button
                        className={cs('btn btn-lg btn-grey', styles['edit-user'])}
                        id="#edit-user"
                        onClick={showUserMgmtModal}
                    >
                    Edit
                    </button>
                    {/* <button
                    className={cs('btn btn-lg btn-red', styles['delete-user'])}
                    id="#delete-user"
                >
                    <img src="/icons/delete.svg" alt="Delete user" />
                </button> */}
                </div>
            </ShowWhenTrue>
        </div>
    );
};

export default UserInfoSection;
