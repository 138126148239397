import classNames from "classnames";
import { get, has, set } from "lodash";
import React, {
	useRef,
	useMemo,
	useState,
	useCallback,
	useEffect,
	ReactNode,
} from "react";
import {
	Column as RVColumn,
	Table as RVTable,
	TableProps,
	TableCellProps,
	TableHeaderProps,
	ColumnProps as RVColumnProps,
	TableRowProps as RVTableRowProps,
	AutoSizer,
	defaultTableRowRenderer,
} from "react-virtualized";
import {
	SortDirectionType,
	TableHeaderRowProps,
	TableRowRenderer,
} from "react-virtualized/dist/es/Table";
import { AtoZSortingIcon } from "../../pages/data-browser/icons";
import { InPageSpinner } from "../spinners/in-page-spinner";
import styles from "./styles.module.scss";
import Draggable from "react-draggable";
import { TooltipTop } from "@components/tooltips";

type _ColumnProps = RVColumnProps & { allowResize?: boolean };
type _TableProps = TableProps & {
	activeSortColumn?: ActiveSortColumn;
	setActiveSortColumn?: SetActiveSortColumn;
	showLoader?: boolean;
	disableHoverState ?: boolean;
	containerClassName ?: string;
};

export type ActiveSortColumn = { columnKey: string; type: SortDirectionType };
export type SetActiveSortColumn = (arg0: ActiveSortColumn) => void;

type _Table = ((arg0: _TableProps) => JSX.Element) & {
	Column: React.ComponentClass<_ColumnProps>;
	AutoSizer: typeof AutoSizer;
	defaultTableRowRenderer: TableRowRenderer;
};

export type TableRowProps = RVTableRowProps;

type HoverGridIndex = { row: number; column: number };
type RenderGridOnHover = (rowIndex: number, columnIndex: number) => void;

const cellRenderer = ({
	columnIndex,
	hoverGridIndex,
	cellData,
	className = "",
	isLastColumn
}: TableCellProps & {
	hoverGridIndex: HoverGridIndex;
	className?: string;
	isFirstColumn ?: boolean;
	isLastColumn ?: boolean
}) => {
	const hoveredColumnIndex = hoverGridIndex.column;

	return (
		<div
			className={classNames(
				styles["tableCell"],
				// {'tableCell_even': rowIndex % 2 === 0,
				// 'tableCell--hover': hoveredRowIndex === rowIndex,
				// 'tableCell--hover--firstCell': hoveredRowIndex === rowIndex && columnIndex === 0,
				// 'tableCell--hover--lastCell': hoveredRowIndex === rowIndex && columnIndex + 1 === rowData.length,
				{
					"tableCell--headerHover":
						hoveredColumnIndex === columnIndex,
					// "tableCell--headerHover--firstCell":
					// 	hoveredColumnIndex === columnIndex && rowIndex === 0,
					"tableCell__firstColumn": columnIndex === 0, 
					"tableCell__lastColumn": isLastColumn, 
					[className]: !!className,
				}
			)}
		>
			{typeof(cellData) === 'string' ? 
				<TooltipTop overlay={cellData} mouseEnterDelay={0.25}>
					{cellData}
				</TooltipTop>
				:
				cellData
			}
		</div>
	);
};

const _rowRenderer: TableRowRenderer = ({ style, ...props }) => {
	// width is reduced by 10px to show the border on row hover
	return defaultTableRowRenderer({ ...props, style: { ...style, width: style.width - 10 } })
}


type HeaderRendererProps = TableHeaderProps & {
	columnIndex: number;
	renderGridOnHover: RenderGridOnHover;
	inColumnHover: boolean;
	className?: string;
	isActiveSortColumn: boolean;
	setActiveSortColumn?: _TableProps["setActiveSortColumn"];
	allowColumnResize?: boolean;
	handleResizeColumn: (dataKey: string, deltaX: number) => void;
	isFirstColumn ?: boolean;
	isLastColumn ?: boolean
};

const headerRenderer = ({
	sortDirection,
	columnIndex,
	label,
	renderGridOnHover,
	inColumnHover,
	className = "",
	dataKey,
	isActiveSortColumn,
	setActiveSortColumn,
	allowColumnResize,
	handleResizeColumn,
	isFirstColumn,
	isLastColumn
}: HeaderRendererProps) => (
	<div
		onMouseOver={() => renderGridOnHover(0, columnIndex)}
		className={classNames(styles["tableHeaderCellText"], {
			columnHover: inColumnHover,
			[styles["tableHeaderCellText__firstColumn"]]: isFirstColumn,
			[styles["tableHeaderCellText__lastColumn"]]: isLastColumn,
			[className]: className,
		})}
		onMouseLeave={() => renderGridOnHover(0, -1)}
	>
		<div
			onClick={() => {
				if (setActiveSortColumn && dataKey) {
					if (isActiveSortColumn) {
						setActiveSortColumn({
							columnKey: dataKey,
							type: sortDirection === "ASC" ? "DESC" : "ASC",
						});
					} else {
						setActiveSortColumn({
							columnKey: dataKey,
							type: "DESC",
						});
					}
				}
			}}
			className={styles["tableHeaderInnerCell"]}
		>
			<span data-headerkey={dataKey} className="tableHeaderText">
				{label}
			</span>
			{isActiveSortColumn &&
				(sortDirection === "ASC" ? (
					<AtoZSortingIcon />
				) : (
					<AtoZSortingIcon className={styles["reverseSortingIcon"]} />
				))}
		</div>
		{allowColumnResize && 
			<Draggable
				axis="x"
				defaultClassName="DragHandle"
				defaultClassNameDragging="DragHandleActive"
				onDrag={(event, { deltaX }) => {
					handleResizeColumn(dataKey, deltaX);
					event.stopPropagation();
					event.preventDefault();
				}}
				// adding y:0 is making the draggable handle move
				// @ts-ignore
				position={{ x: 0 }}
			>
				<span className={styles["DragHandleIcon"]}>⋮</span>
			</Draggable>
		}
	</div>
);

const _headerRowRenderer = ({
	className,
	columns,
	style,
}: TableHeaderRowProps) => {
	return (
		<div
			className={`${className} ${styles["tableHeaderRow"]}`}
			role="row"
			style={style}
		>
			{columns}
		</div>
	);
};

const ROW_HEIGHT = 42;
const Table: _Table = ({
	children,
	rowHeight = ROW_HEIGHT,
	headerHeight = ROW_HEIGHT,
	rowRenderer = _rowRenderer,
	headerRowRenderer = _headerRowRenderer,
	activeSortColumn,
	setActiveSortColumn,
	showLoader,
	disableHoverState,
	containerClassName,
	...props
}: _TableProps) => {
	const rvTableRef = useRef<RVTable>(null);
	const [hoverGridIndex, setHoverGridIndex] = useState<HoverGridIndex>({
		row: -1,
		column: -1,
	});
	const [columnsWidth, setColumnsWidth] = useState<
		Record<string, { widthPer: number; minWidthPer: number | null }>
	>({});
	const isColumnsWidthCalculated = useRef<boolean>(false);

	const renderGridOnHover = useCallback(
		(rowIndex: number, columnIndex: number) => {
			if(!disableHoverState) {
				if (rowIndex !== 0) {
					// change the row color on cell hover
					setHoverGridIndex({ row: rowIndex, column: -1 });
				} else {
					// change the column color on header hover
					setHoverGridIndex({ row: -1, column: columnIndex });
				}
				rvTableRef.current?.forceUpdateGrid();
			}
		},
		[disableHoverState]
	);

	useEffect(() => {
		if (!isColumnsWidthCalculated.current && props.width) {
			// hasZeroWidthColumn - on mount - sometimes column have 0 width
			// Hence, children are added as prop and isColumnsWidthCalculated is tracked
			const tableHeaderCellsWidth = Array.from(
				document.getElementsByClassName("tableHeaderText")
			).reduce((cellWidthInfo, element) => {
				const headerKey = element.getAttribute("data-headerkey");
				if (headerKey) {
					set(cellWidthInfo, headerKey, element.clientWidth);
				}

				return cellWidthInfo;
			}, {} as Record<string, number>);


			let hasZeroWidthColumn = false;
			const columnWidthRef = React.Children.toArray(children).reduce(
				(columnWidthRef, column) => {
					// columnWidthRef[column.]
					// @ts-ignore
					if (column?.props) {
						// @ts-ignore
						const columnProps = (column as ReactNode) // @ts-ignore
							.props as RVColumnProps;

						const headerTextWidth = get(
							tableHeaderCellsWidth,
							columnProps.dataKey
						);
						// min width of 5%'s table width is set as default
						const minWidth = columnProps.minWidth
							? columnProps.minWidth
							: headerTextWidth
							? headerTextWidth + 35 + 50
							: null;

						// @ts-ignore
						set(
							// @ts-ignore
							columnWidthRef,
							columnProps.dataKey,
							// width percentage is set
							{
								widthPer: columnProps.width / props.width,
								minWidthPer: minWidth
									? minWidth / props.width
									: null,
							}
						);
						if (columnProps.width === 0) {
							hasZeroWidthColumn = true;
						}
					}

					return columnWidthRef;
				},
				{} as typeof columnsWidth
			) as typeof columnsWidth;

			setColumnsWidth(columnWidthRef);
			isColumnsWidthCalculated.current = !hasZeroWidthColumn;
		}
	}, [children, props.width]);

	const handleResizeColumn: HeaderRendererProps["handleResizeColumn"] = useCallback((
		dataKey,
		deltaX
	) => {
		setColumnsWidth((columnsWidth) => {
			// const prevWidths = columnsWidth;
			const percentDelta = deltaX / props.width;

			const columnsToBeIgnored: Record<string, true> = {};

			let noOfColumns = Object.keys(columnsWidth).length - 1;
			let terminateResize = false;

			// ignore columns that reached their minimum width
			// Object.keys(columnsWidth).length - 1 to ignore the column that's being resized

			noOfColumns = Object.entries(columnsWidth).reduce(
				(totalNoOfCols, [columnKey, columnInfo]) => {
					if (
						columnInfo.minWidthPer &&
						columnInfo.widthPer <= columnInfo.minWidthPer
					) {
						// if user tries to reduce width of a column that's already at minimum
						if (dataKey === columnKey && deltaX < 0) {
							terminateResize = true;
						}
						columnsToBeIgnored[columnKey] = true;
						return totalNoOfCols - 1;
					}
					return totalNoOfCols;
				},
				noOfColumns
			);
			
			if (terminateResize) {
				return columnsWidth;
			}

			// Resizing column's width is added by equally reducing the width of other columns
			// until columns reach their minimum width
			const newColumnsWidth = Object.entries(columnsWidth).reduce(
				(newColumnsWidth, [columnKey, columnInfo]) => {
					let addPercentDelta = false;
					let addPercentDeltaPerNoOfColumns = false;
					if(deltaX >= 0) {
						// Width of the active column is increased
						if (columnKey === dataKey) {
							addPercentDelta = true
							
						} else if(!columnsToBeIgnored[columnKey]){
							// Width of the other columns is decreased
							addPercentDeltaPerNoOfColumns = true
						}
					} else {
						// Width of the active column is decreased
						// and not included in columnsToBeIgnored
						if (columnKey === dataKey && !columnsToBeIgnored[columnKey]) {
							addPercentDelta = true
						} else if(columnKey !== dataKey){
							// Width of the other columns is increased
							addPercentDeltaPerNoOfColumns = true
						}
					}

					if(addPercentDelta) {
						columnInfo.widthPer =
								columnInfo.widthPer + percentDelta;
					} else if(addPercentDeltaPerNoOfColumns) {
						columnInfo.widthPer =
								columnInfo.widthPer -
								percentDelta / noOfColumns;
					}

					set(newColumnsWidth, columnKey, columnInfo);

					return newColumnsWidth;
				},
				{} as typeof columnsWidth
			);

			return newColumnsWidth;
		});
	}, [props.width]);

	const tableElements = useMemo(() => {
		const elements = React.Children.toArray(children)
			// @ts-ignore
			.map((element: React.ReactElement<_ColumnProps>, columnIndex, arr) => {
				const {
					className,
					cellRenderer: _cellRenderer,
					headerClassName,
					allowResize
				} = element.props;

				const isLastColumn = arr.length - 1 === columnIndex
				const isFirstColumn = columnIndex === 0

				return React.cloneElement(element, {
					className: `${styles["tableColumn"]}`,
					cellRenderer: (cellRendererProps: TableCellProps) =>
						cellRenderer({
							...cellRendererProps,
							hoverGridIndex,
							cellData: _cellRenderer
								? _cellRenderer(cellRendererProps)
								: cellRendererProps.cellData,
							className,
							isFirstColumn,
							isLastColumn
						}),
					headerRenderer: (cellRendererProps: any) =>
						headerRenderer({
							...cellRendererProps,
							columnIndex,
							renderGridOnHover,
							inColumnHover:
								hoverGridIndex.column === columnIndex,
							className: classNames(headerClassName, { [styles.disableHoverState]: disableHoverState}),
							sortDirection: activeSortColumn?.type,
							isActiveSortColumn:
								activeSortColumn?.columnKey ===
								cellRendererProps.dataKey,
							setActiveSortColumn,
							handleResizeColumn,
							// disable resize of last column
							allowColumnResize: allowResize && arr.length - 1!== columnIndex,
							isFirstColumn,
							isLastColumn
						}),
					width: allowResize && has(columnsWidth, element.props.dataKey)
						? columnsWidth[element.props.dataKey].widthPer *
						  props.width
						: element.props.width,
				});
			});
		return elements;
	}, [
		children,
		hoverGridIndex.column,
		activeSortColumn,
		setActiveSortColumn,
		columnsWidth,
	]);

	

	// const onHeaderClick = ({
	//     columnData,
	//     dataKey
	// }: HeaderMouseEventHandlerParams) => {};
	return (
		<div
			className={classNames(styles["tableContainer"], containerClassName)}
			onMouseLeave={() => renderGridOnHover(-1, -1)}
		>
			{showLoader && <InPageSpinner className={styles["tableSpinner"]} />}
			<RVTable
				headerClassName={styles["tableHeaderCell"]}
				rowClassName={({index}) => classNames(styles["tableRow"], {[styles.alternative]: index % 2 === 0})} // Using nth-child(even/odd) is causing UI glitches while scrolling
				gridClassName={styles["tableGrid"]}
				headerRowRenderer={headerRowRenderer}
				rowRenderer={rowRenderer}
				// onHeaderClick={onHeaderClick}
				// height={tableHeight}
				// rowHeight={cellMeasurerCache.current.rowHeight}
				// rowCount={hyperParamsToBeShown.length}
				// rowGetter={({ index}) => hyperParamsToBeShown[index]}
				{...props}
				rowHeight={rowHeight}
				headerHeight={headerHeight}
				// autoHeight
				ref={rvTableRef}
			>
				{tableElements}
			</RVTable>
		</div>
	);
};

// @ts-ignore
Table.Column = RVColumn;
Table.AutoSizer = AutoSizer;
Table.defaultTableRowRenderer = _rowRenderer;

export { Table };
