import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import { SavedAgo } from "./SavedAgo";
import Styles from "./styles.module.scss";

type NoteEditorProps = {
	note: string;
	onDiscard?: () => void;
	onSave?: (note: string) => void;
	isVisible: boolean;
	placeholder?: string;
	onChange?: (note: string) => void;
	actionEnabled?: boolean;
	hideActionButtons?: boolean;
	lastSaved?: Date | null;
	onFocus?: () => void;
	onBlur?: () => void;
	lastSavedPosition?: "BottomLeft" | "TopRight";
};

export const NoteEditor = ({
	note,
	onDiscard,
	onSave,
	isVisible,
	placeholder,
	onChange,
	actionEnabled = true,
	hideActionButtons,
	lastSaved,
	onFocus,
	onBlur,
	lastSavedPosition = "BottomLeft",
}: NoteEditorProps) => {
	const ref = useRef<HTMLTextAreaElement>(null);

	useEffect(() => {
		if (isVisible && ref.current) {
			// Move cursor to the end of the text for better editing experience.
			ref.current?.setSelectionRange(note?.length, note?.length);
		}
	}, [isVisible]);

	return (
		<div className={Styles.noteEditorWrapper}>
			{lastSavedPosition === "TopRight" && (
				<div className={classNames(Styles.lastSaved, Styles.topRight)}>
					{lastSaved && <SavedAgo time={lastSaved} />}
				</div>
			)}
			<textarea
				ref={ref}
				autoFocus
				value={note}
				className={Styles.textArea}
				onFocus={onFocus}
				onBlur={onBlur}
				onChange={(e) => {
					if (note === e.target.value) return;
					onChange?.(e.target.value);
				}}
				name="note"
				placeholder={placeholder ?? "Add here..."}
			/>
			{lastSavedPosition === "BottomLeft" && (
				<div className={Styles.lastSaved}>
					{lastSaved && <SavedAgo time={lastSaved} />}
				</div>
			)}
			{hideActionButtons || (
				<div className={Styles.noteEditorActions}>
					<button
						disabled={!actionEnabled}
						className={"btn-sm " + Styles.cancelBtn}
						onClick={() => {
							onDiscard?.();
						}}
					>
						Discard Changes
					</button>
					<button
						disabled={!actionEnabled}
						className="btn-sm btn-yellow"
						onClick={() => {
							onSave?.(note);
						}}
					>
						Save
					</button>
				</div>
			)}
		</div>
	);
};
