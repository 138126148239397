import { PortModel, PortModelAlignment, PortModelOptions, PortModelGenerics } from '@projectstorm/react-diagrams';
import { DeserializeEvent } from '@projectstorm/react-canvas-core';
import { PortTypes, DS } from '../enums';
import { DsLinkModel } from '../link/ds_linkmodel';
import isEmpty from 'lodash/isEmpty';
import { map } from 'lodash';
import { DsNodeModel } from '../node/ds_nodemodel';
import { infoAlert } from '../../toastify/notify-toast';

export interface DsPortModelOptions extends PortModelOptions {
	isVariablePort: boolean;
}

export interface DsPortModelGenerics extends PortModelGenerics {
	OPTIONS: DsPortModelOptions;
}


export class DsPortModel extends PortModel<DsPortModelGenerics> {
    constructor(isVariablePort: boolean, portType?: PortTypes, portNumber?: number){
        let alignment;
        if(portType === PortTypes.IN){
            alignment = PortModelAlignment.LEFT;
        } else {
            alignment = PortModelAlignment.RIGHT;
        }
        super({
            type: DS.name,
            name: portType+'-'+portNumber,
            isVariablePort,
            alignment
        });
    }

    isPortConnected(): boolean {
        return !isEmpty(this.getLinks());
    }

    isVariablePortAndConnected(): boolean{
        return !!this.options.isVariablePort && this.isPortConnected();   
    }


    canLinkToPort(port: DsPortModel): boolean{
        if(port instanceof DsPortModel) {    
            const isSourceComponentVariableType = (this.getParent() as DsNodeModel).getOptions().nodeType === 'variable';
            const isDestinationComponentVariableType = (port.getParent() as DsNodeModel).getOptions().nodeType === 'variable';
            const isInputOutputLink = this.getOptions().alignment !== port.getOptions().alignment; 
            if(this.getParent().getID() !== port.getParent().getID()) {
                if(isSourceComponentVariableType || isDestinationComponentVariableType) {
                    // INPUT TO VARIABLE COMPONENT CAN COME FROM ANY TYPE OF PORT: VARIABLE INPUT <---> ANY COMPONENT OUTPUT
                    // OUT LINK FROM VARIABLE COMPONENT CAN ONLY BE LINKED TO VARIABLE PORT: VARIABLE OUTPUT <---> COMPONENT INPUT(ONLY VARIABLE PORT)
                    if(isSourceComponentVariableType) {
                        if(this.getOptions().alignment === PortModelAlignment.RIGHT) {
                            // VARIABLE OUT TO COMPONENT IN (CHECK FOR VARIABLE PORT)
                            if(port.getOptions().isVariablePort) {
                                return isInputOutputLink;
                            } else {
                                infoAlert('Can only be connected to Variable Port');
                            }
                        } else if(this.getLinksInArray().length === 1) {
                            // CURRENT LINK IS COUNTED AS 1
                            // DO NOT ALLOW INPUT PORTS TO HAVE MULTIPLE LINKS
                            // VARIABLE IN TO COMPONENT OUT
                            return isInputOutputLink;
                        }
                    }

                    else {
                        // isDestinationComponentVariableType
                        if(this.getOptions().alignment === PortModelAlignment.LEFT) {
                            // COMPONENT IN TO VARIABLE OUT (CHECK FOR VARIABLE PORT)
                            if(this.getOptions().isVariablePort) {
                                return isInputOutputLink;
                            } else {
                                infoAlert('Can only be connected to Variable Port');
                            }
                        } else  if(!port.isPortConnected()){
                            // DO NOT ALLOW INPUT PORTS TO HAVE MULTIPLE LINKS
                            // COMPONENT OUT TO VARIABLE IN 
                            return isInputOutputLink;
                        }
                    }
                }
                else if(this.getOptions().isVariablePort || port.getOptions().isVariablePort) {
                    infoAlert('Only links from Variable output can be connected');
                    // SOURCE / DESTINATION IS A NORMAL COMPONENT - ONLY LINKS FROM VARIABLE OUTPUT CAN BE CONNECTED to A VARIABLE PORT
                    return false;
                } else if (!port.isPortConnected()) {
                    // NEW LINKS CANNOT BE LINKED TO ALREADY CONNECTED PORTS 
                    if (this.getLinksInArray().length > 1 && port.getOptions().alignment === PortModelAlignment.RIGHT) {
                        // CONNECTED INPUT PORT TO OUTPUT PORT 
                        // DO NOT ALLOW INPUT PORTS TO HAVE MULTIPLE LINKS
                        return false;
                    } return isInputOutputLink;
                }
                
                return false;
            } 
        }
        return false;
    }

    createLinkModel(): DsLinkModel {
        const color = this.getOptions().isVariablePort ? 'dotted': 'normal'; 
        return new DsLinkModel(color);
    }

    getLinksInArray() {
        return Object.values(this.getLinks()) as DsLinkModel[];
    }

    deserialize(event: DeserializeEvent<this>) {
        super.deserialize(event);
        this.reportedPosition = false;
        this.options.name = event.data.name;
        this.options.alignment = event.data.alignment;
        this.options.isVariablePort = event.data.isVariablePort;
    }

    serialize() {
        return {
            ...super.serialize(),
            name: this.options.name,
            alignment: this.options.alignment || PortModelAlignment.LEFT,
            isVariablePort: this.options.isVariablePort,
            parentNode: this.parent.getID(),
            links: map(this.links, link => {
                return link.getID();
            })
        };
    }
}


// export class DsVariablePortModel extends DsPortModel {
    
//     createLinkModel(): DsLinkModel {
//         return new DsLinkModel('dotted');
//     }
// }