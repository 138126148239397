import React, { memo } from 'react';
import { ErrorContent } from './error-page-content';
import { RootState } from '../../store/types';
import { useSelector } from 'react-redux';


export const NetworkConnectionError: React.FC = memo(() => {
    const { showNetworkErrorPage } = useSelector((store: RootState) => store.CommonReducer);

    return(
        showNetworkErrorPage  ?
            <ErrorContent 
                title={
                    <p className="title">
                        Whoops. Cannot connect to the Network.
                    </p>
                }
                subtitle={
                    <p className="subtitle">
                        Please check your connectivity.
                    </p>
                }
            />
            :
            null
    );
});

NetworkConnectionError.displayName = 'Network Connection Error Page';
