import React, { useEffect, useState } from "react";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts-latest/core";
import { CanvasRenderer } from "echarts-latest/renderers";
import { UniversalTransition } from "echarts-latest/features";
import {
	TitleComponent,
	ToolboxComponent,
	TooltipComponent,
	GridComponent,
	LegendComponent,
	DataZoomComponent,
	VisualMapComponent,
} from "echarts-latest/components";
import {
	BarChart,
	BoxplotChart,
	HeatmapChart,
	LineChart,
	ScatterChart,
} from "echarts-latest/charts";
import classNames from "classnames";
import { InPageSpinner } from "@components/spinners/in-page-spinner";
import { ShowWhenTrue } from "@helpers/showwhentrue";
import { Plot, PlotConfig } from "@api/visualization-handler";
import { getGraphConfig } from "../utils/config";
import { isEmpty } from "lodash";

export type ChartsProps = {
	plotData: Plot;
};

const Plot: React.FC<ChartsProps> = ({ plotData }) => {
	const [options, setOptions] = useState<PlotConfig>({} as PlotConfig);
	const [isError, setIsError] = useState(false);
	useEffect(() => {
		let _plotType = plotData.config.plot_type;
		let _plotData = plotData.config.plotData;
		setIsError(isEmpty(_plotData));
		let y = plotData.config.y;
		if (plotData.config.plot_type === "boxplot") {
			_plotData = [plotData.config.plotData?.[plotData?.config?.x || ""]];
			y = [plotData.config.x || ""];
		}
		if (plotData.config.plot_type === "correlation") {
			_plotType = "heatmap";
		}
		const plotConfigData = {
			...plotData?.config,
			plot_type: _plotType,
			y: y,
			plotData: _plotData,
		};
		const plotConfig: any = getGraphConfig(plotConfigData);
		setOptions(plotConfig);
	}, [plotData]);

	echarts.use([
		TitleComponent,
		ToolboxComponent,
		TooltipComponent,
		GridComponent,
		LegendComponent,
		DataZoomComponent,
		LineChart,
		CanvasRenderer,
		UniversalTransition,
		ScatterChart,
		BarChart,
		VisualMapComponent,
		HeatmapChart,
		BoxplotChart,
	]);
	return (
		<>
			<ShowWhenTrue show={isEmpty(options)}>
				<div
					className={classNames("spinnerDiv", {
						center: true,
					})}
				>
					<InPageSpinner />
				</div>
			</ShowWhenTrue>
			<ShowWhenTrue show={isError}>
				<div className="graph_error_wrapper">
					<div className="graph_error">
						<div>
							<img
								src="/icons/Bitmap.png"
								style={{ marginLeft: 15 }}
							/>
						</div>

						<span>Oops, An error occured.</span>
					</div>
				</div>
			</ShowWhenTrue>
			<ShowWhenTrue show={!isEmpty(options) && !isError}>
				<ReactEChartsCore
					echarts={echarts}
					option={options}
					notMerge
					style={{
						height: "100%",
						width: "100%",
					}}
					opts={{}}
				/>
			</ShowWhenTrue>
		</>
	);
};

export default React.memo(Plot);
