import React from "react";

type Props = {
	name: keyof typeof icons;
};

const icons = {
	maximize: () => (
		<svg
			width={24}
			height={25}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				opacity={0.2}
				d="M12 21.5a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
				fill="#C7C7CD"
			/>
			<path
				d="M12 21.5a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
				stroke="#C7C7CD"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="m9 16.25 3-3 3 3M9 11l3-3 3 3"
				stroke="#C7C7CD"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	minimize: () => (
		<svg
			width={24}
			height={25}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g>
				<path
					opacity={0.2}
					d="M12 21.5a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
					fill="#C7C7CD"
				/>
				<path
					d="M12 21.5a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
					stroke="#C7C7CD"
					strokeWidth={2}
					strokeMiterlimit={10}
				/>
				<path
					d="M8.25 12.5h7.5"
					stroke="#C7C7CD"
					strokeWidth={2}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	),
	popout: () => (
		<svg
			width={24}
			height={25}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g
				stroke="#C7C7CD"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			>
				<path d="M20.25 9.875V4.25h-5.625M13.5 11l6.75-6.75M17.25 14v6a.75.75 0 0 1-.75.75h-12a.75.75 0 0 1-.75-.75V8a.75.75 0 0 1 .75-.75h6" />
			</g>
		</svg>
	),
	help: () => (
		<svg
			width={16}
			height={16}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.04 14.667H6.93a.52.52 0 0 1-.495-.361l-.26-.807a.52.52 0 1 1 .99-.32l.145.446h1.35l.145-.446a.52.52 0 1 1 .991.32l-.26.807a.52.52 0 0 1-.496.36Zm.563-2.701c.035-.025.871-.627 1.712-1.631 1.135-1.358 1.736-2.814 1.736-4.21 0-1.345-.533-2.574-1.5-3.46-.928-.851-2.18-1.323-3.528-1.332h-.048c-1.35.009-2.601.481-3.529 1.332-.967.886-1.5 2.115-1.5 3.46 0 1.395.596 2.85 1.722 4.208a10.188 10.188 0 0 0 1.698 1.631.521.521 0 0 0 .304.098H9.3a.52.52 0 0 0 .303-.096ZM3.988 6.125c0-2.134 1.723-3.744 4.01-3.75 2.288.006 4.01 1.616 4.01 3.75 0 2.467-2.311 4.444-2.883 4.896H6.847c-.57-.455-2.859-2.43-2.859-4.896Z"
				fill="#fff"
			/>
			<path
				d="M9.585 5.603c-.074-.056-.753-.546-1.638-.546-.86 0-1.565.49-1.64.546a.52.52 0 0 0 .622.836c.003-.002.205-.147.497-.247v2.772a.52.52 0 1 0 1.041 0V6.192c.293.1.494.245.497.247a.519.519 0 0 0 .729-.107.52.52 0 0 0-.108-.73Z"
				fill="#fff"
			/>
		</svg>
	),
};

export const Icon = ({ name }: Props) => {
	const IconComponent = icons[name];
	return <IconComponent />;
};
