import logrocket from 'logrocket';

export const isDevelopment = process.env.NODE_ENV === 'development';

let env = (window as any)._env_;

if(isDevelopment) {
    env = {};
}

let licenseId = window.location.hostname?.split(".")[0];
licenseId = licenseId ==="localhost"?"dev":licenseId;

const replaceGateWayUrlPart=(url:string, replacement:string)=>{
    const pattern = /(?<=gateway\.)[^.]+(?=\.)/g;
    return url?.replace(pattern, replacement);
}

const replaceAirFlowUrlPart=(url:string, replacement:string)=>{
    const pattern = /(?<=airflow\.)[^.]+(?=\.)/g;
    return url?.replace(pattern, replacement);
}

const airflowUrl  = replaceAirFlowUrlPart(env.REACT_APP_AIRFLOW_URL, licenseId);
const platformUrl = replaceGateWayUrlPart(env.REACT_APP_PLATFORM_URL, licenseId);
const _deepSQLURL = replaceGateWayUrlPart(env.REACT_DEEP_SQL_URL, licenseId);
const _deepSQLURL1= replaceGateWayUrlPart(env.REACT_DEEP_SQL_URL1, licenseId);
const zeppelinUrl = replaceGateWayUrlPart(env.REACT_APP_ZEPPELIN_URL, licenseId);

export const Env = {
    isDevelopment,
    showPropertyJSONUpload: env.REACT_APP_ENABLE_DEV_JSON_PROPERTY === 'true' || isDevelopment,
    deepiq_client: env.REACT_APP_DEEPIQ_CLIENT || 'deepiq',
    test_sqlbuilder: env.TEST_SQLBUILDER || false,
    disableLocalMode: env.REACT_APP_DISABLE_LOCAL_MODE === 'true' || false,
    disableClientMode: env.REACT_APP_DISABLE_CLIENT_MODE === 'true' || false,
    disableClusterMode:  env.REACT_APP_DISABLE_CLUSTER_MODE === 'true' || false,
    sagemaker: env.REACT_APP_SAGEMAKER === 'true' || false,
    databricks: env.REACT_APP_DATABRICKS === 'true' || isDevelopment,
    localFileBrowser: env.REACT_APP_LOCALFILEBROWSER === 'true' || false,
    userNameForDemoWorkflows: env.REACT_APP_USER_NAME_DEMO_WORKFLOWS_HELP || false,
    zeppelin_paragraph_header: env.REACT_APP_ZEPPELIN_PARAGRAPH_HEADER || '%jupyter(kernel=ds_dev_cluster)',
    mlflow: env.REACT_APP_ENABLE_MLFLOW === 'true' || isDevelopment,
    zeppelin: env.REACT_APP_ENABLE_ZEPPELIN === 'true' || isDevelopment,
    azureDeltaLakes: env.REACT_APP_AZURE_DELTALAKES === 'true' || isDevelopment,
    //Hide all profile related information
    profiles: env.REACT_APP_PROFILES === 'true',
    //Hides cancel button in job panel and 'Kill Scheduled Jobs' job page
    killRunJobs: env.REACT_APP_KILL_RUN_JOBS === 'true' || isDevelopment,
    // Upload location for dbfs libraries
    uploadLibraryPath: env.REACT_APP_UPLOAD_LIBRARY_PATH || 'datastudio/setupfiles',
    rootFolderPathForFileBrowser: env.REACT_APP_ROOT_FOLDER_PATH_FOR_FILE_BROWSER || 'deepiq/filesystem',
    sparkDeployMode: env.REACT_APP_SPARK_DEPLOY_MODE_IP || false,
    // s !isDevelopment && env.REACT_APP_ENABLE_LOGROCKET === 'true',
    enableLogRocket: false, 
    logrocketId: env.REACT_APP_LOGROCKET_ID ? env.REACT_APP_LOGROCKET_ID : 'hdkpwm/datastudio-20',
    // Supported list of datasource: OPCUA,Aurora,WITSML,Azure_Blob_Storage,SAP,RDBMS,PI,API,NOSQL
    dataSourceList: env.REACT_APP_DATA_SOURCE_LIST || 'ALL',
    disableMlflow: env.REACT_APP_DISABLE_MLFLOW === 'true' || isDevelopment,
    showExecutionEnvToggle: env.REACT_APP_ENABLE_PYTHON_EXPERIENCE === 'true' || isDevelopment,
    gatewayURL: platformUrl,
    helpImagesUrl: env.REACT_APP_HELP_IMAGES_URL,
    zeppelinUrl: zeppelinUrl || 'http://localhost:9005',
    azureBlobFileSystemPrefix: env.REACT_APP_AZURE_BLOB_FILESYSTEM_PREFIX || 'wasbs://devdatastudio@devdeepiqlab.blob.core.windows.net/FileSystem',
    mlPipelineAzureBlobFileSystemPrefix: env.AZURE_BLOB_FILESYSTEM_ML_PIPELINE_PREFIX || "/dbfs/mnt/blobfilebrowser/FileSystem",
    airFlowURL: airflowUrl || 'https://airflow.dev.deepiqlab.com',
    disableAirFlow: env.REACT_APP_DISABLE_AIRFLOW === 'true'|| false,
    keycloakRealm: env.IS_MULTITENANT==='false'? 'datastudio' : licenseId || 'datastudio',
    keycloakUrl: env.KEYCLOAK_URL || 'https://deepiqauth.dev.azuredeepiq.com/auth/',
    keycloakClientId: env.KEYCLOAK_CLIENT_ID || 'datastudio-public',
    showStreamingComponents: env.SHOW_STREAMING_COMPONENTS === 'true' || isDevelopment,
    isZepplinInTestMode:  env.REACT_ZEPPLIN_TEST_MODE === 'true' || false,
    showPiRequestsTable: env.REACT_SHOW_PI_REQUESTS_TABLE === 'true' || isDevelopment,
    enableZeppelinInStreamingExp: env.ENABLE_ZEPPELIN_STREAMING_EXP === 'true',
    enableContainerizedDeployment: env.ENABLE_CONTAINERIZED_DEPLOYMENT === 'true' || isDevelopment,
    disablePetastorm: env.DISABLE_PETASTORM === 'true',
    useOldDatabrowserConnections: env.USE_OLD_DATABROWSER_CONNECTIONS === 'true',
    s3Enable: env.REACT_APP_S3_ENABLED === 'true' || false,
    enableDatabricksNoteBook: env.ENABLE_DATABRICKS_NOTEBOOK === 'true',
    textToSQLURL: env.REACT_TEXT_SQL_URL || 'https://deepsqlstd.deepiqlab.com',
    enableDataStudioWalkthrough: env.ENABLE_DATASTUDIO_WALKTHROUGH === 'true',
    deepSQLURL: _deepSQLURL || 'https://deepsql.dev.azuredeepiq.com',
    deepSQLURL1: _deepSQLURL1|| 'https://deepsql.dev.azuredeepiq.com',
    enableDataExplorer:env.ENABLE_DATA_EXPLORER === 'true',
    disableGitIntegration: env.REACT_APP_DISABLE_GIT_INTEGRATION === 'true' || false,
    disableMlPipelineComponent: env.REACT_APP_DISABLE_ML_PIPELINE_COMPONENT === 'true' || false,
    disableRasterPlot: env.REACT_APP_DISABLE_RASTER_PLOT === 'true'|| false,
    disableTriggerDagRunOperator: env.REACT_APP_DISABLE_TRIGGER_DAG_RUN_OPERATOR === 'true' || false,
    disableExternalTaskSensorOperator: env.REACT_APP_DISABLE_EXTERNAL_TASK_SENSOR_OPERATOR === 'true' || false,
    enableStorageOption:env.ENABLE_STORAGE_OPTION === 'true' || false,
    showDropDownDEEPAI: env.REACT_APP_SHOW_DEEP_AI || 'ALL',
    showDropDownDEEPAISubtType: env.REACT_APP_SHOW_DEEP_AI_SUB_TYPE || false,
    dataExplorer: env.REACT_APP_DATA_EXPLORER || "dmadan86@gmail.com|12886|570",
    enableDataExplorerSqlQueryView: env.ENABLE_DATA_EXPLORER_SQL_QUERY_VIEW === 'true' || false,
    enableDataExplorerMoreInfo: env.ENABLE_DATA_EXPLORER_MORE_INFO === 'true' || false,
    enableDataExplorerExplain: env.ENABLE_DATA_EXPLORER_EXPLAIN === 'true' || false,
    enableDataExplorerDownloadCSV: env.ENABLE_DATA_EXPLORER_DOWNLOAD_CSV === 'true' || isDevelopment,
    streamlitChatURL: env.REACT_CHAT_STREAMLIT_URL || 'https://deepsqlchat.dev.deepiqlab.com',
    enableScratchPad: env.REACT_ENABLE_SCRATCHPAD === 'true' || isDevelopment,
    cygnetDemoURL: env.REACT_CYGNET_URL || 'https://deepsqldeploy5.dev.deepiqlab.com',
    showTableRelation: env.REACT_TABLE_RELATION === 'true' || isDevelopment,
    textToSQLProperty: env.REACT_TEXT_2_SQL_PROPERTY === 'true' || isDevelopment,
    enableScheduleSettings: env.REACT_ENABLE_SCHEDULE_SETTINGS_CANVAS === 'true' || isDevelopment
};

export const gatewayUrl = isDevelopment ? 'https://gateway.dev.azuredeepiq.com': Env.gatewayURL;
export const zepplinUrl = Env.zeppelinUrl;
export const tagsUrl = isDevelopment ? 'http://10.0.9.145:8089/api' : '/api1';
export const witsmlUrl = isDevelopment ? 'http://10.0.9.145:4547' : '/api2';
export const socketUrl = isDevelopment ? 'ws://10.0.9.145:4550/websocket/websocket' : 'wss://ds-dev.deepiq.com/websocket/websocket';
export const fileBrowserUrl = isDevelopment ? 'http://10.0.9.145:4888': '/api3';
export const mlFlowUrl = isDevelopment ? 'http://10.0.9.145:4553': '/api4';
export const dbfsUrl = isDevelopment? 'http://104.42.151.138:7704': '/api5';
export const azureUrl = isDevelopment? 'https://devgateway.deepiqlab.com': '/api6';

export const databrowserUrl = '/api7';
export const API = {
    url: gatewayUrl + '/platform/api',
    databricksUrl: gatewayUrl + '/databricks/api',
    userManagementUrl: gatewayUrl + '/usermanagement/api',
    mlFlowUrl: gatewayUrl + '/databricks/api',
    airflowUrl: gatewayUrl + '/airflow/api/v1',
    socketUrl,
    tagsUrl,
    witsmlUrl,
    fileBrowserUrl: fileBrowserUrl + '/api',
    databricksFilerBrowser: dbfsUrl + '/api',
    azureUrl: azureUrl + '/api',
    azureblobUrl: gatewayUrl + '/filebrowser/api',
    s3Url: gatewayUrl + '/filebrowser/api',
    databrowserUrl: gatewayUrl + '/databrowser/api',
    zepplinUrl,
    gitUrl: gatewayUrl + "/git",
    deepSqlUrl: Env.deepSQLURL
};

export const helpImagesUrl = isDevelopment ? 'https://dshelpfiles.blob.core.windows.net/helpfiles/Images': Env.helpImagesUrl;
export const showExecutionEnvToggle = Env.showExecutionEnvToggle;

// '{"rahul": ["test_workflow","test_UI","test_wf_demo_ESP"]}'
// '[{"UserName":"Preet","workflowName":"timeseries_window_group","componentName":"Read Csv"},{"UserName":"Preet","workflowName":"timeseries_window_group","componentName":"Read Csv"}]'
export const clientMode = {
    deepiq: env.REACT_APP_SPARK_SUBMIT || 'spark2-submit --master',
    maverick:
    'sudo PYSPARK_DRIVER_PYTHON=/opt/conda/bin/python PYSPARK_PYTHON=/opt/conda/bin/python spark2-submit --master',
};

if (Env.enableLogRocket) {
    logrocket.init(Env.logrocketId);
}
