import React, { useEffect, useState} from 'react';
import { ShowWhenTrue } from '../../../helpers';
import { AUTOSCALE, schemaTypes, SPOT_FALL_BACK_TO_OD, SPOT_INSTANCES, TERMINATE, accessNodeText, usersListText} from './form-schema';
import classNames from 'classnames';
import { _selectoptionType, SelectField } from '../../../components/form/select-field';
import { Row, Col, Tabs } from 'antd';
import { InputField } from '../../../components/form';
import { CheckboxField } from '../../../components/form/checkbox-field';
import { DynamicKeyValueFieldGeneratorCluster } from '../../../components/form/dynamic-field-generator-cluster';
import { useFormikContext } from 'formik';
import './styles.scss';
import { RadioField } from '../../../components/form/radio-field';
import { ClusterDetail, ClusterState } from '../../../api/databricks-handler';
import { Env } from '@constants/settings';
import { Slider } from '@components/form/Slider';
import { useSelector } from 'react-redux';
import { RootState } from '@store/types';

const { TabPane } = Tabs;

const enableAutoScaleOptions = [{ label: 'Enable AutoScaling', value: AUTOSCALE }];
const terminateAfterminOptions = [{ label: 'Terminate After', value: TERMINATE }];
const spotInstanceOptions = [{ label: 'Spot Instances', value: SPOT_INSTANCES }];
const spotFallbackToOD = [{ label: 'Spot fall back to On-demand', value: SPOT_FALL_BACK_TO_OD }];
const spotFallbackToOD_ = [{ label: 'Spot fall back to On-demand ', value: '' }];
const dockerOptions = [{ label: 'Use Containerized Deployment', value: 'true' }];

interface ClusterFormProps {
    nodeTypeList: _selectoptionType[];
    clusterMode:  _selectoptionType[];
    dataBricksVersionList:  _selectoptionType[];
    currentClusterDetail?: ClusterDetail & {state?: ClusterState };
    isPipeline?: boolean; 
    firstOnDemand?:number;
    setFirstOnDemand?:any;
    dataSecurityMode: _selectoptionType[];
    singleUserName : _selectoptionType[];
    onAccessModeChange : (mode: string) => void;
}

export const ClusterForm: React.FC<ClusterFormProps> = ({
    nodeTypeList,
    clusterMode,
    dataBricksVersionList,
    currentClusterDetail,
    isPipeline = false,
    firstOnDemand=1,
    setFirstOnDemand,
    dataSecurityMode,
    singleUserName,
    onAccessModeChange
}) => {
    const sparkConfigPlaceholder='Enter your spark configuration options here. Provide only one key-value pair per line.\nExample:\nspark.speculation true\nspark.kyro.registrator my.package.MyRegistrator';
    const { values } = useFormikContext<schemaTypes>();
    const { workspaceList} = useSelector((store: RootState) => store.ClusterReducer);

    const enabledWorkspace = workspaceList.filter((item)=>item.isEnabled)?.[0];
    const isAwsCloudProviderType = enabledWorkspace?.cloudProvider === 'Aws';

    const getInitScriptsField = () => <DynamicKeyValueFieldGeneratorCluster
        keyToSaveKeyValueInfo="initScripts"
        addButtonText="Init Scripts"
    />;

    const [filteredData, setFilteredData] = useState<_selectoptionType[]>([]);
    const [selectedAccessMode, setSelectedAccessMode] = useState(values.dataSecurityMode);
    const [user, setUser]= useState(singleUserName)
    useEffect(() => {
        setFilteredData(dataBricksVersionList);
    }, []);

    let max = 1;
    if(values.enableAutoScaling === AUTOSCALE){
        max =  (values.maxWorkers||0)+1;
    }
    if(!values.enableAutoScaling){
        max = (values.noOfWorkers||0)+1;
    }

    let selectedALphaText = firstOnDemand + ' ';
    if(firstOnDemand === 0){
        selectedALphaText += 'All Spot'
    }if(firstOnDemand === max){
        selectedALphaText += 'All On-demand'
    }if(firstOnDemand >0 && firstOnDemand<max){
        selectedALphaText += `On-demand first, followed by ${max-firstOnDemand} Spot`
    }

    const handleAccessModeChange = (option:_selectoptionType) => {
        setSelectedAccessMode(option.value);
        onAccessModeChange(option.value)
        setUser(singleUserName)
    };

    return (
        <>
            <Row className="addedit-cluster-inner">
                <Col span={9}>
                    <div className="form-left-content">
                        <div className="details">
                            <input type="hidden" name="model_type" />
                            <InputField
                                label="Cluster Name"
                                name="name"
                                className=""
                                placeholder="Enter Cluster Name"
                                type="text"
                                autoComplete="off"
                                required
                            />
                            {/* {
                                !isPipeline? (
                                    <RadioField
                                        name="npipWorkSpace"
                                        label="Public"
                                        readOnly={currentClusterDetail !== undefined && !isPipeline}
                                        options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
                                        inline
                                    />
                                ) : null
                            } */}
                             <SelectField
                                infoText={accessNodeText}
                                name="dataSecurityMode"
                                label="Access Mode"
                                options={dataSecurityMode}
                                onOptionClick={handleAccessModeChange}
                                required
                            >
                            </SelectField>

                            {selectedAccessMode=="SINGLE_USER" ? 
                            <SelectField
                                infoText={usersListText}
                                name="singleUserName"
                                label="Single User Access"
                                options={user}
                                disabled
                            >
                            </SelectField>:<></>}
                           
                            <SelectField
                                name="clusterModeId"
                                label="Cluster Mode"
                                options={clusterMode}
                                disabled={currentClusterDetail !== undefined && !isPipeline}
                                required
                            >
                            </SelectField>
                            {/* <CheckboxField 
                                name="showPhotons"
                                label=""
                                options={
                                    [ {
                                        label: 'Show only Photon',
                                        value:'photon' }
                                    ]
                                }
                                onOptionClick={(obj)=>{
                                    if(obj !== 'checked')
                                        setFilteredData(dataBricksVersionList);
                                    else {
                                        setFilteredData(dataBricksVersionList.filter((obj)=>{
                                            return obj.label.toLowerCase().includes('photon');
                                        }));
                                    } 
                                }}
                                color="gold"
                                className=""
                            /> */}
                            <SelectField
                                name="sparkRunTimeVersion"
                                label="Databricks runtime version"
                                options={filteredData}
                                required
                            >
                            </SelectField>
                           {
                                Env.enableContainerizedDeployment && enabledWorkspace?.useDockerImage ? ( 
                                    // <CheckboxField 
                                    //     name="showDocker"
                                    //     label=""
                                    //     options={dockerOptions}
                                    // /> 
                                    <SelectField
                                        name="dockerContainer"
                                        label="Select Container to be deployed"
                                        options={[{ label: 'Latest', value: 'latest' }]}
                                        required
                                    ></SelectField>
                                ) : null
                           }
                            {/*<label className="inputfield__label autopilotOptions__label">Autopilot Options</label>*/}
                            { values.clusterModeId !== '1' ? (
                                <CheckboxField
                                    name="enableAutoScaling"
                                    options={enableAutoScaleOptions}
                                    className="autoScaling__checkbox"
                                /> ) : null }
                                {
                                     !isPipeline? (
                                        <div className="terminate-group">
                                            <CheckboxField
                                                name="terminate"
                                                className="terminate-checkbox"
                                                options={terminateAfterminOptions}
                                                inline
                                            />
                                            <InputField
                                                label="minutes of inactivity"
                                                name="minutesOfInactivity"
                                                className="terminate-inputfield"
                                                placeholder=""
                                                type="number"
                                                disabled={!values.terminate}
                                                autoComplete="off"
                                            />
                                        </div>
                                     ) : null
                                }
                        </div>

                        <SelectField
                            name="workerType"
                            label="Worker Type"
                            className="ClusterName"
                            options={nodeTypeList}
                            required
                        >
                        </SelectField>

                        { values.clusterModeId !== '1' ? (
                            <>
                               
                                <div className="workers-input-group">
                                    <ShowWhenTrue show={values.enableAutoScaling === AUTOSCALE}>
                                        <InputField
                                            label="Min Workers"
                                            name="minWorkers"
                                            className="min-workers"
                                            placeholder=""
                                            type="number"
                                            autoComplete="off"
                                        />
                                        <InputField
                                            label="Max Workers"
                                            name="maxWorkers"
                                            className="max-workers"
                                            placeholder=""
                                            type="number"
                                            autoComplete="off"
                                            required
                                        />
                                    </ShowWhenTrue>
                                    <ShowWhenTrue show={!values.enableAutoScaling}>
                                        <InputField
                                            label="No Of Workers"
                                            name="noOfWorkers"
                                            type="number"
                                            autoComplete="off"
                                            required
                                        />
                                    </ShowWhenTrue>

                                </div>
                                {!isAwsCloudProviderType &&<CheckboxField
                                    name="spotInstances"
                                    options={spotInstanceOptions}
                                    className="checkboxfield__spotinstance"
                                    inline
                                />}
                                <SelectField
                                    name="driverType"
                                    label="Driver Type"
                                    className="ClusterName"
                                    initial_value={values.workerType}
                                    options={nodeTypeList}
                                    required
                                >
                                </SelectField>
                           
                            </>)
                            : null }

                        
                    </div>
                </Col>
                <Col span={15}>
                    <div className="form-right-content">
                        <label className={classNames('advancedOptions__label', {enabled: true})}>Advanced Options</label>
                        {isAwsCloudProviderType &&<>
                            <div  className="inputfield__label">On-demand/spot composition </div>
                            <Slider label={selectedALphaText} min={0} max={max} value={firstOnDemand} onChange={setFirstOnDemand} className={"alphaSlider"} />
                            <div className={"alphaSlider"}>
                                <div  className="inputfield__label">1 Driver</div>
                                <div  className="inputfield__label">{values.enableAutoScaling === AUTOSCALE ? `${values.minWorkers}-${values.maxWorkers}`:values.noOfWorkers} Workers</div>
                            </div>
                            {firstOnDemand !== max?<CheckboxField
                                name="spotFallbackToOD"
                                options={spotFallbackToOD}
                                className={"checkboxfield__spotinstance"}
                                inline
                            />:<CheckboxField
                                name="spotFallbackToOD_"
                                options={spotFallbackToOD_}
                                className={"checkboxfield__spotinstance"}
                                disabled={true}
                                inline
                            />}
                        </>}
                        <Tabs defaultActiveKey="1" className="advancedOptions__tabs" >
                            <TabPane tab="Spark" key="1">
                                <InputField
                                    label="Spark Config"
                                    name="sparkConfig"
                                    className="spark-config-field"
                                    placeholder={sparkConfigPlaceholder}
                                    type="textarea"
                                    autoComplete="off"
                                />
                                <InputField
                                    label="Environment Variables"
                                    name="sparkEnvVars"
                                    className="spark-config-field"
                                    type="textarea"
                                    autoComplete="off"
                                />
                                {/* DO NOT DELETE - THIS IS TO INITIALIZE the value of initScriptsField  */}
                                <div className="hide">
                                    {getInitScriptsField()}
                                </div>
                            </TabPane>
                            <TabPane tab="Init Scripts" key="2">
                                {getInitScriptsField()}
                            </TabPane>
                        </Tabs>
                    </div>
                </Col>
            </Row>
        </>
    );
};
