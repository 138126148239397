import { ExecutionAnalyticsFlow } from '../../api/azkaban-data-handler';

export const AZKABAN_EXECUTION_ANALYTICS_FLOW_ALL = 'AZKABAN_EXECUTION_ANALYTICS_FLOW_ALL';

export type AzkabanExecutionAnalyticsFlowAllPayload = { 
    totalCount: number; 
    data: ExecutionAnalyticsFlow[]; 
    is_loaded?: boolean;
};

export type ActionTypes = {
    type: typeof AZKABAN_EXECUTION_ANALYTICS_FLOW_ALL;
    payload: AzkabanExecutionAnalyticsFlowAllPayload;
}

export type AzkabanReducerState = {
    execution_analytics_flow: AzkabanExecutionAnalyticsFlowAllPayload;
}