import * as React from 'react';
import { ListenerHandle } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import { DsPortModel } from './ds_portmodel';
import { DsNodeModel } from '../node/ds_nodemodel';

export interface PortProps {
    port: DsPortModel;
    engine: DiagramEngine;
    className?: any;
    style?: React.CSSProperties | undefined;
}

export class PortWidget extends React.Component<PortProps> {
    ref: React.RefObject<HTMLDivElement>;
    engineListenerHandle: ListenerHandle | undefined;

    constructor(props: PortProps) {
        super(props);
        this.ref = React.createRef();
    }

    report() {
        // @ts-ignore
        this.props.port?.updateCoords(this.props.engine?.getPortCoords(this.props.port, this.ref.current));
    }

    componentWillUnmount(): void {
        this.engineListenerHandle && this.engineListenerHandle.deregister();
    }

    componentDidUpdate(prevProps: Readonly<PortProps>): void {
        if ((!this.props.port?.reportedPosition) || (prevProps.style !== this.props.style)) {
            this.report();
        }
    }

    componentDidMount(): void {
        this.engineListenerHandle = this.props.engine.registerListener({
            canvasReady: () => {
                this.report();
            }
        });
        if (this.props.engine.getCanvas()) {
            this.report();
        }
    }

    // getExtraProps() {
    // 	if (Toolkit.TESTING) {
    // 		const links = _.keys(this.props.port.getNode().getPort(this.props.port.getName()).links).join(',');
    // 		return {
    // 			'data-links': links
    // 		};
    // 	}
    // 	return {};
    // }

    render() {
        return (
            <div
                style={this.props.style}
                ref={this.ref}
                className={`port ${this.props.className || ''}`}
                data-name={this.props.port?.getName()}
                data-nodeid={this.props.port?.getNode()?.getID()}
                role="node-port"
                data-test-id={(this.props.port?.getNode() as DsNodeModel)?.getOptions().title}
                // {...this.getExtraProps()}
            >
                {this.props.children}
            </div>
        );
    }
}