import { Modal } from "@components/modals";
import { WorkflowConfigItem, WorkflowConfig, WorkflowConfigTabType } from "@services/WorkflowConfig";
import React from "react";
import WorkflowConfigForm, { WorkflowConfigFormValues } from "../RHS-Tabs/WorkflowConfigForm";

type EditWorkflowConfigModalProps = {
    showModal: boolean; 
    closeModal: () => void;
    initialValues: WorkflowConfigFormValues;
    onSubmit: (values: WorkflowConfigItem) => void;
    config: WorkflowConfig;
    type: WorkflowConfigTabType;
}


const EditWorkflowConfigModal: React.FC<EditWorkflowConfigModalProps> = ({  config, showModal, closeModal, initialValues, onSubmit, type }) => {
    
    return(
        <Modal
            isOpen={showModal}
            toggleClose={closeModal}
            title="Edit Configuration"
            className="editWorkflowConfig__modal"
        >
            <WorkflowConfigForm 
                initialValues={initialValues} 
                onSubmit={onSubmit}
                config={config}
                allowValueEditOnly={type !== 'CONFIGURATION'}
            >
                {({ isValid }) => (
                    <div className="modalBtns__box">
                        <button
                            className="btn-md btn-yellow"
                            type="submit"
                            disabled={!isValid}
                        >
                            Save
                        </button>
                        <button
                            className="btn-md btn-cancel"
                            type="button"
                            onClick={closeModal}
                        >
                            Cancel
                        </button>
                    </div>
                )}
            </WorkflowConfigForm>
        </Modal>
    )
}

export { EditWorkflowConfigModal };