import React from 'react';

interface Props{
    show: boolean;
}

const ShowWhenTrue: React.FC<Props> = ({show, children}) => {
    return (
        <React.Fragment>
            {show &&
                children
            }
        </React.Fragment>
    );
};

export { ShowWhenTrue };