import React, { useMemo } from 'react';
import { Modal } from '../../../components/modals';
import { RootState } from '../../../store/types';
import { useSelector, useDispatch } from 'react-redux';
import { capitalize } from 'lodash';
import { NewEditPlantSiteProps } from './new-edit-plant-site';
import { toggleTagBrowserModal } from '../../../store/tag-browser';
import { object, string, InferType, number } from 'yup';
import Form, { InputField, SelectField } from '../../../components/form';
import { serverInfo } from '../../../api/tags-handler';
import { _selectoptionType } from '../../../components/form/select-field';

const addSyncSetSchema = object().shape({
    name: string()
        .required('Name is a required field.'),
    serverId: number()
        .required('Server is a required field.'),
}); 

export type addSyncSetSchema = InferType<typeof addSyncSetSchema>


interface Props {
    actionType: NewEditPlantSiteProps['actionType'];
    onCreateSuccess: (arg0: addSyncSetSchema) => any;
    serversList: serverInfo[];
}

export const NewSyncSetModal: React.FC<Props> = ({ actionType, onCreateSuccess, serversList }) => {
    const { editSyncSets: showModal } = useSelector((store: RootState) => store.TagBrowserReducer.showModal);
    const dispatch = useDispatch();

    const toggleClose = () => {
        dispatch(toggleTagBrowserModal('editSyncSets', false));
    };

    const handleFormSubmit = (e: addSyncSetSchema) => {
        toggleClose();
        onCreateSuccess(e);
    };

    const serversOptions: _selectoptionType[] = useMemo(() => {
        return serversList.map(_server => ({ label: _server.serverName, value: _server.serverId }));
    }, [serversList]);

    return(
        <Modal
            isOpen={showModal}
            title={capitalize(actionType) + ' Sync Set'}
            toggleClose={toggleClose}
            className="newEditPlantSite__modal"
        >
            <Form
                initialValues={{ name: ''}}
                onSubmit={handleFormSubmit}
                validationSchema={addSyncSetSchema}
            >
                <InputField 
                    label="Name"
                    name="name"
                    placeholder="Enter Sync Set name"
                    required
                />
                <SelectField 
                    label="Server"
                    name="serverId"
                    options={serversOptions}
                    autoSelectFirstOption
                    required
                />
                <div className="modalBtns__box">
                    <button 
                        className="btn-md btn-yellow"
                        type="submit"
                    >
                        Create
                    </button>
                    <button 
                        className="btn-md btn-cancel"
                        type="button"
                        onClick={toggleClose}
                    >
                        Cancel
                    </button>
                </div>
            </Form>
            
        </Modal>
    );
};