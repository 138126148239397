import { useState, useEffect } from 'react';

export const useGetNumberOfItemsPerPage = () => {

    const getNumberOfItemsPerPage = () => {
        if(window.innerHeight < 760) return 8;
        else if(window.innerHeight < 845) return 10;
        else if (window.innerHeight < 960) return 12;
        else return 15;
    }; 

    const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState(getNumberOfItemsPerPage);


    const handleSetNumberOfItemsPerPage = () => {
        setNumberOfItemsPerPage(getNumberOfItemsPerPage());
    };
  
    useEffect(() => {
        window.addEventListener('resize', handleSetNumberOfItemsPerPage);
        return () =>  window.removeEventListener('resize', handleSetNumberOfItemsPerPage);
    }, [numberOfItemsPerPage]);

    return numberOfItemsPerPage;
};