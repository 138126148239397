import React from 'react';
import { iconList } from '../../pages/workflow-page/enums';
import './styles.scss';
import { CustomComponent } from '../../pages/workflow-page/assets/icons';
import { TooltipRight } from '../tooltips';
export interface Item {
    hasNodes: boolean;
    isOpen: boolean;
    level: number;
    key: string;
    [x: string]: any;
}

export interface TreeMenuItem extends Item {
    active?: boolean;
    onClick: (event: React.MouseEvent<HTMLLIElement>) => void;
    toggleNode?: () => void;
    toggleNodeCb?: () => void;
}

const openedIcon = <img draggable={false} className="arrow  arrow--active" src="/icons/treeview/Right.svg" alt="" />;
const closedIcon = <img draggable={false} className="arrow" src="/icons/treeview/Right.svg" alt="" />;


const ToggleIcon = ({
    on,
    openedIcon,
    closedIcon,
}: {
    on: boolean;
    openedIcon: React.ReactNode;
    closedIcon: React.ReactNode;
}) => (
    <div role="img" aria-label="Toggle" className="rstm-toggle-icon-symbol">
        {on ? openedIcon : closedIcon}
    </div>
);

const DEFAULT_PADDING = 10;
const ICON_SIZE = 8;
const LEVEL_SPACE = 20;

const rolesInfo: any = {0: 'Parent-Component-Classifer', 1: 'Child-Component', 2: 'Grand-Child-Component', 3: 'Great-Grand-Child-Component'};

export const ItemComponent: React.FunctionComponent<TreeMenuItem> = ({
    hasNodes = false,
    isOpen = false,
    level = 0,
    onClick,
    toggleNode,
    toggleNodeCb,
    active,
    focused,
    label = 'unknown',
    style = {},
    onContextMenu,
    is_component,
    details
}) => {
    // @ts-ignore
    const Icon = iconList[label as string] || CustomComponent;

    const handleToggleNode = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if(hasNodes && toggleNode) {
            toggleNode();
            toggleNodeCb && toggleNodeCb();
        }
        e.stopPropagation();

    };
    const componentDescription = is_component ? details?.componentInfo?.[0].defaultValue: '';
    return (
        <TooltipRight title={componentDescription} modifiedOverlayClassName='content-through-tooltip' align={{ offset: [-30, 0]}} >
            <li
                className={`rstm-tree-item ${active ? 'rstm-tree-item--active' : ''} ${focused ? 'rstm-tree-item--focused' : ''}`}
                style={{
                    paddingLeft:  ICON_SIZE
                    + level * LEVEL_SPACE,
                    paddingRight: 25,
                    ...style,
                }}
                role={rolesInfo[level] || ''}
                aria-label={label}
                aria-pressed={active}
                onClick={onClick}
                onContextMenu={onContextMenu}
                id={`usercomponent_${label}`}
            >
                {hasNodes && (
                    <div
                        className="rstm-toggle-icon"
                        onClick={handleToggleNode}
                    >
                        <ToggleIcon on={isOpen} openedIcon={openedIcon} closedIcon={closedIcon} />
                    </div>
                )}
                {is_component && Icon && <span className={'compoennt_icon_cls'}>  <Icon /></span>}
                {/* {parent === 'Custom_Component' && <span className={'compoennt_icon_cls'}><CustomComponent /></span>} */}
                <span
                    onClick={handleToggleNode}
                    role='component'
                    className="tree-item-title"
                >
                    {label}
                </span>
            </li>
        </TooltipRight>
    );
};
