/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types */
import _ from 'lodash';
import React, {Component} from 'react';
import { Tooltip } from 'antd';
import Search from '../../../components/search/search2';

export default class SelectAndWhereColumnSearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            result: [],
            fullResults: [],
        };
    }

    componentDidMount() {
        this.setState({
            fullResults: this.getData()
        });
    }


    modifyColumn = (alias, tableName, value, id) => {
        if (this.props.type === 'select') {
            this.props.query.select.modifySelectColumn(
                this.props.rowIndex,
                alias,
                tableName,
                value,
                id
            );
        } else if (this.props.type === 'where') {
            this.props.query.where.modifyWhereColumn(
                this.props.rowIndex,
                alias,
                tableName,
                value,
                id
            );
        } else if(this.props.type === 'groupby'){
            this.props.query.groupby.modifyGroupByColumn(
                this.props.rowIndex,
                alias,
                tableName,
                value,
                id
            );
        }
        this.props.updateQueryState();
    };

    getData = () => {
        let fullResults = {};

        if (this.props.type === 'select' || this.props.type === 'groupby') {
            fullResults.All = {
                name: 'all',
                alias: null,
                results: [{ tableName: 'all', alias: null, title: '*' }],
            };
        }

        fullResults = {
            ...fullResults,
            ...this.props.query.fullResults.results.reduce((resultDrop, result) => {
                // eslint-disable-next-line no-param-reassign

                resultDrop[
                    result.tableMetadata.name //+ ' (' + result.tableAlias + ')'
                ] = {
                    name: result.tableMetadata.name, // + ' (' + result.tableAlias + ')',
                    tableAlias: result.tableAlias,
                    _id: result.tableMetadata.id,
                    results: result.tableMetadata.fields
                        ? result.tableMetadata.fields.map(column => ({
                            alias: result.tableAlias,
                            tablename: result.tableMetadata.name,
                            title: column.name,
                            _id: result.tableMetadata.id
                        }))
                        : [],
                };
                return resultDrop;
            }, {}),
        };

        if (this.props.type === 'select' || this.props.type === 'groupby') {
            Object.keys(fullResults).forEach(key => {
                if (key !== 'All') {
                    fullResults[key].results.unshift({
                        tableName: fullResults[key].name,
                        alias: fullResults[key].tableAlias,
                        title: '*',
                        _id: fullResults[key]._id
                    });
                }
            });
        }

        return fullResults;
    }

    transform = (_data = null) => {
        const data =[];
        const obj =  _data || this.getData();
        Object.keys(obj).forEach((name)=>{
            const results = obj[name].results;
            data.push({
                name,
                results
            });
        });
        this.setState(
            {
                result:data
            }
        );
    };

    handleSearchChange = (value) => {
        this.modifyColumn(null, null, value, '');
        setTimeout(() => {
            const re = new RegExp(
                _.escapeRegExp(this.props.value.split('.')[1] || this.props.value.split('.')[0] || this.props.value),'i'
            );

            const isMatch = result => {
                if (this.props.value) {
                    return (
                        re.test(result.title) || re.test(result.tablename) || re.test(result.tableAlias) || re.test(result._id)
                    );
                } else {
                    return true;
                }
            };

            const filteredResults = _.reduce(
                this.getData(),
                (resultDrop, data, name) => {
                    const results = _.filter(data.results, isMatch);
                    if (results.length) resultDrop[name] = { name, results }; // eslint-disable-line no-param-reassign

                    return resultDrop;
                },
                {}
            );
            this.setState({
                result: (this.transform(filteredResults))
            });
        }, 100);
    };

    render(){
        return (
            <Tooltip
                title={this.props.text}
                mouseLeaveDelay={0}
            >
                <Search
                    placeholder={`${this.props.type.toUpperCase()} column ${this.props.rowIndex + 1}`}
                    data={this.state.result} 
                    selectedValue={this.props.value}
                    getData={()=>{
                        this.transform();
                    }}
                    onSearch={(key)=>{
                        this.handleSearchChange(key);
                    }}
                    onSelect={(value)=>{
                        this.modifyColumn(value.alias, value.tablename, value.title, value._id);
                    }}
                />
            </Tooltip>
        );
    }
}
