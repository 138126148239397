import { ApiResponse } from "@api/data-source-handler";
import { File } from "@components/FileExplorer/types";
import { Canceler } from "axios";
import { Azure } from "./Azure";

export type StorageType = "Azure";

export type GetFile = (
	path: string,
	successCallback: (files: File[]) => void,
	errorCallback?: (err:any) => void
) => void;

export type DeleteFile = (
	filePath: string,
	successCallback: () => void,
	errorCallback?: () => void
) => void;

export type DownloadFile = (filePath: string, fileName: string) => void;

export type UploadFile = (
	path: string,
	requestData: any,
	successCallback: () => void,
	errorCallback: () => void,
	uploadProgress: ApiResponse<ProgressEvent>
) => Canceler;

export type FileStorageController = {
	getFiles: GetFile;
	deleteFile: DeleteFile;
	downloadFile: DownloadFile;
	uploadFile: UploadFile;
	prefix: string;
};

export const fileStorage: Record<StorageType, FileStorageController> = {
	Azure,
};
