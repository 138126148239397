import React from 'react';
import './styles.scss';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classNames from 'classnames';
import { isString } from 'lodash';


interface  NotifyProps {
    type: string;
    message: string;
}

const Success = ({notifyObj}: {notifyObj: NotifyProps}) => {
    return (<div>
        <div className='icon-wrapper'>
            <div className="notify-icon"> <img src={`/icons/notification/${notifyObj.type}.svg`} alt="" /></div>
            <div className="notify-text">
                {notifyObj.message}
            </div>
        </div>
    </div>);
};

function notify( parameter: NotifyProps) {
    if (isString(parameter.message) &&  parameter.message.length > 0) {
        toast(<Success notifyObj = {parameter}  />, {
            progressClassName: 'progress_toasty',
            className: classNames( 'toasty_common', `${parameter.type}_toasty`),
            position: toast.POSITION.BOTTOM_LEFT, pauseOnHover: true
        });
    }
}


export function successAlert(message: string) {
    notify({type:'success',message:message});
}

export function errorAlert(message: string) {
    notify({type:'error',message:message});
}

export function infoAlert(message: string) {
    notify({type:'info',message:message});
}

export function warnAlert(message: string) {
    notify({type:'warn',message:message});
}


