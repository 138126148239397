import { AxiosResponse } from 'axios';

export type ApiResponse<T extends any> = Promise<AxiosResponse<T>>

export type ClusterScheduleInfo = {
    
    'scheduleType': string;
    'startTime': {
        'cronExpression': string;
        'weekOfTheMonth'?: number;
        'day'?: number;
        'hrs'?: number;
        'mins': number;
    };
    'endTime': ClusterScheduleInfo['startTime'];
}

export type BriefClusterInfo = {
    'clusterId': string;
    'clusterName': 'databricks_connect';
}

export type ScheduleClusterApiData = BriefClusterInfo & ClusterScheduleInfo;

export type ClusterLibraryInfo = {
    'name': string;
    'type': string;
    'status': 'PENDING' | 'SKIPPED' | 'INSTALLED' | 'INSTALLING' | 'RESOLVING' | 'FAILED' | 'UNINSTALL_ON_RESTART';
    'source': string | null;
}


export type InstallClusterInfo = {
    'cluster_id': string;
    'libraries': {
        'cran'?: {
            'package': string;
            'repo'?: string;
        };
        'egg'?: string;
        'jar'?: string;
        'maven'?: {
            'coordinates': string;
            'exclusions'?: [
                string
            ];
            'repo'?: string;
        };
        'pypi'?: {
            'package': string;
            'repo'?: string;
        };
        'whl'?: string;
    }[];
}

export enum ClusterState {
    PENDING = 'PENDING',
    TERMINATED = 'TERMINATED',
    TERMINATING = 'TERMINATING',
    RESIZING = 'RESIZING',
    RESTARTING = 'RESTARTING',
    RUNNING = 'RUNNING'
}

export interface Cluster {
    clusterId: string;
    clusterName: string;
    databricksClusterId: string;
    state: ClusterState;
    nodeTypeId: string;
    driverNodeTypeId: string;
    createdBy: string;
    createdDate: string;
    defaultCluster: boolean;
    sparkRunTimeVersion: string;
    npipWorkSpace: boolean;
    stateMessage?: string; // will not be available when cluster is in RUNNING state 
    workspaceType: string;
    workspaceId: string;
    command?: string; // for cloudera profile cluster
}

export interface ClusterDetail {
    clusterId?: string; // Only available when a cluster exists. For new cluster there is no clusterId
    clusterName: string;
    nodeTypeId: string;
    driverNodeTypeId: string;
    autoterminationMinutes: number;
    isSpotInstances: boolean;
    numWorkers: number;
    sparkEnvVars:  Record<string, string>;
    sparkConf:  Record<string, string>;
    autoscale: {
        min_workers: number;
        max_workers: number;
    } | null;
    initScripts?: Record<string, {destination: string}>[];
    npipWorkSpace: boolean;
    clusterModeId: string;
    dataSecurityMode: string;
    singleUserName: string;
    sparkRunTimeVersion: string;
    modelType: string;
    useDockerImage: boolean;
    dockerContainer: string;
    awsAttributes: {
        first_on_demand: number,
        availability: string,
    }|null
}

export interface NodeType {
    label: string;
    value: string;
}

export interface ClusterModeType {
    name: string;
    id: number;
}

export interface AccessNodeType {
    label: string;
    value: string;
    disabled: boolean;
}

export interface UsersType {
    label: string;
    value: string;
}

export interface DataBricksVersionListType {
    name: string;
    key: string;
}