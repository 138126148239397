import React, {  useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './../styles.module.scss';
import MonacoEditor from "react-monaco-editor";
import * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';
import 'monaco-editor/esm/vs/basic-languages/monaco.contribution';
import 'monaco-editor/esm/vs/editor/edcore.main';
import 'monaco-editor/esm/vs/language/typescript/monaco.contribution';
import 'monaco-editor/esm/vs/editor/standalone/browser/standaloneServices';
import 'monaco-editor/esm/vs/language/json/monaco.contribution';

import { _selectoptionType } from '@components/form/select-field';
import { Env } from '@constants/settings';
import { Modal } from "@components/modals";
import { isEmpty, sortBy } from 'lodash';
import { TableData } from '../types';
import { useDataExplorerContext } from '../useDataExplorerContext';
import ShowHistory from './showHistory';
import GenAI from '../gen-ai';
import { InPageSpinner } from '@components/spinners/in-page-spinner';
import ShowScratchPad from './showScratchPad';
import { HISTORY } from '../gen-ai/types';
import { useGenAIContext } from '../gen-ai/useGenAIContext';
import { DataSourceHandler } from '@api/data-source-handler';
import { errorAlert, infoAlert, successAlert } from '@components/toastify/notify-toast';
import { encryptAndExport } from '@pages/workflow-page/utils';


//TODO: to move any to proper type
const QueryContainer:React.FC<{
    ruleEngine: string,
    sqlDataError: string,
    tableSchemaData: TableData[],
    queryData: any,
    setQueryData: any,
    sqlError: any,
    sqlData: any,
    setSQLData: any,
    loadingQuery: boolean
    sqlWarning: string
    onDataGenerate: (sql:string, cluster: string) => void,
    onCancelJob: any
}> = ({ruleEngine, sqlDataError, tableSchemaData, queryData, setQueryData, sqlError, sqlData, setSQLData, loadingQuery, sqlWarning, onDataGenerate, onCancelJob}) => {
    const [isShowHistory,setIsShowHistory] = useState(false);
    const [isQueryShow, setQueryExpand] = useState(false);
    const [isSQLShow, setSQLExpand] = useState(false);
    const [isIframeLoading, setIframeLoading] = useState(true);
    const {database, cluster } = useDataExplorerContext();
    const [isShowScratchPad, setIsShowScratchPad] = useState(false);
    const [history1, setHistory] = useState<HISTORY>();
    const { history, sessionId } = useGenAIContext();
    const { setCallback } = useGenAIContext()

    const tableSchData = useRef<{
        colName: string,
        tableName: string
    }[]>([])

    const onQueryReset = () => {
        setQueryData("");
    };

    const triggerSQL = (sql: string) => {
        setQueryData(sql);
        onCancelJob();
        setTimeout(() => {
            onDataGenerate(sql, cluster);
        }, 200)
    }

    useEffect(() => {
        const temp: {
            colName: string,
            tableName: string
        }[] = [];
        tableSchemaData?.forEach((tTable) => {
            return tTable.columnsMetaData.forEach((_col) => {
                temp.push({
                    colName: _col.name,
                    tableName: tTable.tableName
                })
            })
        })

        tableSchData.current = sortBy(temp, ['colName']);
    }, [tableSchemaData]);

    useEffect(() => {
        if(ruleEngine !== "GENAI") {
            setIframeLoading(false);
        }
    }, [ruleEngine])

    useEffect(() => {
        setCallback( triggerSQL, setHistory)
        setIframeLoading(true);
        const fn = (e: any) => {
            if(e.data.indexOf && e.data.indexOf('loaded:') !== -1) {
                setIframeLoading(false);
            }
        }
        window.addEventListener('message', fn);
        return () => {
            window.removeEventListener('message', fn);
        }
    }, []);

    useEffect(() => {
        setCallback( triggerSQL, setHistory);
    }, [database]);

    useEffect(() => {
        const fn1 = (e: any) => {
            if(e.data.indexOf && e.data.indexOf('sqlQuery:') !== -1) {
                const sql = e.data.split('sqlQuery:')[1]
                if(sql === "undefined" || sql === undefined) return;
                setSQLData(sql);
                onDataGenerate(sql, cluster);
            }
        }
        window.addEventListener('message', fn1);
        return () => {
            window.removeEventListener('message', fn1);
        }
    }, [cluster]);

    useEffect(() => {
        monacoEditor.languages.register({id: 'deepiq'})
        const temp = monacoEditor.languages.registerCompletionItemProvider('deepiq', {
            triggerCharacters: [''],
            // @ts-ignore
            provideCompletionItems: function(model, position) {
                const wordAtPosition = model.getWordUntilPosition(position);
                const suggestions = tableSchData.current.filter((da) => {
                    return da.colName.toLowerCase().includes(wordAtPosition?.word.toLowerCase() || '') //reg.test(da.colName)
                }).map((da) => {
                    return {
                        label: da.colName,
                        insertText: da.colName,
                        kind: monacoEditor.languages.CompletionItemKind.Operator,
                        detail: da.tableName
                    }
                })
                return {
                    suggestions
                }
            }
        });
        return () => {
            temp.dispose()
        }
    }, [])

    const onScratchPad = () => {
        setIsShowScratchPad(true);
    }

    const onDownload = async () => {
        try{
            if(isEmpty(sessionId)) {
                return infoAlert("No session present to download the logs");
            }
            const data = await DataSourceHandler.DownloadLogs(sessionId);
            encryptAndExport(JSON.stringify(data), 'ds-logs', '', 'dslog')
            successAlert("Logs downloaded successfully")
        } catch(e){
            errorAlert("Failed to download the log");
        }
    }

    return (
        <div id="chat_middle_container">
            <div className={classNames(styles['panel'], styles['m-1'])}>
                <div className={styles["space-between"]}>
                    <div className={styles["page_title"]}>What would you like to know?</div>
                </div>
                <div>
                    <div className={styles["panel-container"]} style={ruleEngine === "GENAI" ? {height: 460} : ruleEngine === "DEMO"?  {minHeight: 200} : {minHeight: 350}}>
                        <div>
                            <div className={styles["space-between"]}>
                                <div className={styles["title"]}></div>
                                <div className='flex'>
                                    {
                                        ruleEngine === "GENAI" && Env.enableScratchPad ? (
                                            <div>
                                                {/* <button className={classNames(styles["u-button"], styles["mb-half"], styles["ml-1"])} onClick={onScratchPad}>
                                                    Scratchpad
                                                </button> */}
                                            </div>
                                        ): null
                                    }

                                    {
                                        ruleEngine === "GENAI" ? (
                                            <div>
                                                <button className={classNames(styles["u-button"], styles["mb-half"], styles["ml-1"])} onClick={onDownload}>
                                                    Logs
                                                </button>
                                            </div>
                                        ): null
                                    }
                                </div>

                                {
                                    ruleEngine !== 'GENAI'? (
                                        <div>
                                            <button className={classNames(styles["u-button"], styles["mb-half"])} onClick={onQueryReset}>
                                                Reset
                                            </button>
                                            <button className={classNames(styles["u-button"], styles["mb-half"], styles["ml-1"])} onClick={()=>{
                                                setQueryExpand(!isQueryShow);
                                            }}>
                                                <img width="20px" src="/icons/maximize-modal.png" alt="" />
                                            </button>
                                        </div>
                                    ): null
                                }
                            </div>
                            <div className={classNames(styles["mb-half"])} style={{position: 'relative'}}>
                                {
                                    ruleEngine === "GENAI" ?  <GenAI /> : null
                                }
                                {
                                    ruleEngine === "DeepQuery6" ? (
                                        <div >
                                            <iframe 
                                                style={{
                                                    width: '100%',
                                                    height: isIframeLoading ? '0' : '40rem',
                                                    border:0,
                                                    outline: 'none',
                                                    opacity: isIframeLoading ? 0 : 1,
                                                    display: isIframeLoading? 'absolute': '',
                                                    zIndex: 100
                                                }} 
                                                className='iframe'
                                                id="iframe"
                                                src={ 
                                                    Env.streamlitChatURL + '?embedded=true&db_id=' + database.id
                                                }
                                            ></iframe>
                                            {
                                                isIframeLoading? (
                                                    <div className={classNames("align-middle")} style={{marginTop: '10rem'}}>
                                                        <InPageSpinner />
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                    ) : null
                                }
                                {
                                    ruleEngine === "DEMO" ? (
                                        <MonacoEditor
                                            height='150px'
                                            width='100%'
                                            theme='vs-dark'
                                            value={queryData}
                                            language='deepiq'
                                            onChange={(value) => {
                                                setQueryData(value)
                                            }}
                                            options={{
                                                selectOnLineNumbers: true,
                                                'contextmenu': false,
                                                'formatOnPaste': true,
                                                'accessibilitySupport': 'auto',
                                                'autoIndent': 'full',
                                                'highlightActiveIndentGuide': true,
                                                'quickSuggestions': true,
                                                formatOnType: true,
                                                minimap: {
                                                    enabled: false,
                                                },
                                                fontSize: 14,
                                                letterSpacing: 0.25,
                                                lineNumbersMinChars: 0,
                                                lineDecorationsWidth: 0,
                                                lineHeight: 21,
                                                scrollbar:{
                                                    verticalScrollbarSize: 0,
                                                    horizontalScrollbarSize: 10,
                                                    verticalSliderSize: 4,
                                                    horizontalSliderSize: 4,
                                                },
                                                glyphMargin: false,
                                                automaticLayout: true,
                                                lineNumbers: 'off',
                                                folding: false,
                                            }}
                                        />
                                    ) : null
                                }
                                <div className={classNames(styles['right'], styles['mt-1'])} >
                                    <div>
                                    {
                                        ruleEngine !== "GENAI" && sqlError  && !loadingQuery && <div className={styles['error-text']}>{sqlError}</div>
                                    }
                                    {
                                        !Env.enableDataExplorerSqlQueryView ? sqlDataError && ruleEngine !== "GENAI" &&  <div className={styles['error-text']}>{sqlDataError}</div>:""
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {Env.enableDataExplorerSqlQueryView && ruleEngine !== "GENAI" && <div className={classNames(styles['panel'], styles['m-1'])}>
                <div className={styles["space-between"]}>
                    <div className={styles["page_title"]}></div>
                </div>
                <div>
                    <div>
                        <div className={styles["space-between"]}>
                            <div className={styles["title"]}>Your generated SQL Query / Editor</div>
                            <div>
                                <button 
                                    className={classNames(styles["u-button"], styles["mb-half"])}
                                    onClick={()=>navigator.clipboard.writeText(sqlData)}
                                >
                                    Copy
                                </button>
                                <button className={classNames(styles["u-button"], styles["mb-half"], styles["ml-1"])} onClick={()=>{
                                    setSQLExpand(!isSQLShow);
                                }}>
                                    <img width="20px" src="/icons/maximize-modal.png" alt="" />
                                </button>
                            </div>
                        </div>
                        <div className=''>
                            <MonacoEditor
                                height='150px'
                                width='100%'
                                theme='vs-dark'
                                value={sqlData}
                                language='sql'
                                onChange={(value) => {
                                    setSQLData(value);
                                }}
                                options={{
                                    selectOnLineNumbers: true,
                                    'contextmenu': false,
                                    'formatOnPaste': true,
                                    'accessibilitySupport': 'auto',
                                    'autoIndent': 'full',
                                    'highlightActiveIndentGuide': true,
                                    'quickSuggestions': true,
                                    formatOnType: true,
                                    minimap: {
                                        enabled: false,
                                    },
                                    fontSize: 14,
                                    letterSpacing: 0.25,
                                    lineNumbersMinChars: 0,
                                    lineDecorationsWidth: 0,
                                    lineHeight: 21,
                                    scrollbar:{
                                        verticalScrollbarSize: 0,
                                        horizontalScrollbarSize: 10,
                                        verticalSliderSize: 4,
                                        horizontalSliderSize: 4,
                                    },
                                    glyphMargin: false,
                                    automaticLayout: true,
                                    lineNumbers: 'off',
                                    folding: false,
                                }}
                            />
                            <div className={classNames(styles['right'], styles['mt-1'])}>
                                <span></span>
                                {
                                    sqlDataError && <span className={styles['error-text']}>{sqlDataError}</span>
                                }
                                {
                                    sqlWarning && <div className={styles['warning-text']}>Warning: {sqlWarning}</div>
                                }
                            </div>
                            <div className={styles['btn-margin']}></div>
                        </div>
                    </div>
                </div>
            </div>}

            <Modal
                isOpen={isShowHistory}
                toggleClose={()=>{setIsShowHistory(false)}}
                title="Show History"
                subtitle=""
                className={
                    styles['moreInfoModal']
                }
                showCloseMark
            >
                <ShowHistory/>
            </Modal> 
            <Modal
                isOpen={isShowScratchPad}
                toggleClose={()=>{setIsShowScratchPad(false)}}
                title="Show ScratchPad"
                subtitle=""
                className={
                    styles['moreInfoModal']
                }
                showCloseMark
            >
                <ShowScratchPad history={history} />
            </Modal> 
            <Modal
                isOpen={isSQLShow}
                toggleClose={()=>{setSQLExpand(false)}}
                title="Your generated SQL Query / Editor"
                subtitle=""
                className={
                    styles['moreInfoModal']
                }
                showCloseMark
            >
                <div style={{height: '50vh', width: '60vh' }}>
                    <MonacoEditor
                        height='100%'
                        width='100%'
                        theme='vs-dark'
                        value={sqlData}
                        language='sql'
                        onChange={(value) => {
                            setSQLData(value);
                        }}
                        options={{
                            selectOnLineNumbers: true,
                            'contextmenu': false,
                            'formatOnPaste': true,
                            'accessibilitySupport': 'auto',
                            'autoIndent': 'full',
                            'highlightActiveIndentGuide': true,
                            'quickSuggestions': true,
                            formatOnType: true,
                            minimap: {
                                enabled: false,
                            },
                            fontSize: 14,
                            letterSpacing: 0.25,
                            lineNumbersMinChars: 0,
                            lineDecorationsWidth: 0,
                            lineHeight: 21,
                            scrollbar:{
                                verticalScrollbarSize: 0,
                                horizontalScrollbarSize: 10,
                                verticalSliderSize: 4,
                                horizontalSliderSize: 4,
                            },
                            glyphMargin: false,
                            automaticLayout: true,
                            lineNumbers: 'off',
                            folding: false,
                        }}
                    />
                </div>
            </Modal>  
            <Modal
                isOpen={isQueryShow}
                toggleClose={()=>{setQueryExpand(false)}}
                title="What would like to know?"
                subtitle=""
                className={
                    styles['moreInfoModal']
                }
                showCloseMark
            >
                <div style={{height: '50vh', width: '60vh' }}>
                    <MonacoEditor
                        height='100%'
                        width='100%'
                        theme='vs-dark'
                        value={queryData}
                        language='deepiq'
                        onChange={(value) => {
                            //setSQLData(value);
                            setQueryData(value)
                        }}
                        options={{
                            selectOnLineNumbers: true,
                            'contextmenu': false,
                            'formatOnPaste': true,
                            'accessibilitySupport': 'auto',
                            'autoIndent': 'full',
                            'highlightActiveIndentGuide': true,
                            'quickSuggestions': true,
                            formatOnType: true,
                            minimap: {
                                enabled: false,
                            },
                            fontSize: 14,
                            letterSpacing: 0.25,
                            lineNumbersMinChars: 0,
                            lineDecorationsWidth: 0,
                            lineHeight: 21,
                            scrollbar:{
                                verticalScrollbarSize: 0,
                                horizontalScrollbarSize: 10,
                                verticalSliderSize: 4,
                                horizontalSliderSize: 4,
                            },
                            glyphMargin: false,
                            automaticLayout: true,
                            lineNumbers: 'on',
                            folding: false,
                        }}
                    />
                </div>
            </Modal> 
        </div>
    )
};


export default QueryContainer;