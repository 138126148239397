import React from "react";
import { ShowWhenTrue } from "../../helpers";
import Form, { SelectField } from "../form";
import Paginate, { PaginateRef } from "../paginate";

type TablePaginationProps = {
	numberOfItemsPerPage?: number;
	totalNoOfRecords: number;
	showNumberOfItemsPerPageDropdown: boolean;
	handlePageChange: (arg0: number) => void;
	handleItemsPerPageOptionClick?: (newPageSize: number) => void;
    paginateRef?: React.RefObject<PaginateRef>
	pageNoList?: number[]
};

export const TablePagination: React.FC<TablePaginationProps> = ({
	numberOfItemsPerPage = 10,
	totalNoOfRecords,
	showNumberOfItemsPerPageDropdown = false,
	handlePageChange,
	handleItemsPerPageOptionClick,
    paginateRef,
	pageNoList = [20, 25, 30]
}) => {
	const options = pageNoList.map(_num => ({ label: _num.toString(), value: _num }));
	
	return (
		<div className="paginationContainer">
			<Paginate
				startPage={1}
				totalCount={totalNoOfRecords}
				itemsPerPage={numberOfItemsPerPage}
				handlePageChange={handlePageChange}
                ref={paginateRef}
			>
				<ShowWhenTrue show={showNumberOfItemsPerPageDropdown}>
					<Form
						initialValues={{ numberOfItemsPerPage }}
						onSubmit={() => {
							return;
						}}
					>
						<SelectField
							name="numberOfItemsPerPage"
							className="numberOfItemsDropdown"
							label="Per Page"
							options={options}
							onOptionClick={(option) =>
								handleItemsPerPageOptionClick &&
								handleItemsPerPageOptionClick(option.value)
							}
						/>
					</Form>
				</ShowWhenTrue>
			</Paginate>
		</div>
	);
};
