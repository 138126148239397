import { AppThunk } from '../types';
import { TOGGLE_WORKFLOWS_IN_ANALYTICS,  SET_PLOTDATA_PROGRESS , ModalTypes, TOGGLE_MODAL, SetPlotDataProgress, 
    RunAnalyticsOnActiveComponent, RUN_ANALYTICS_ON_ACTIVE_COMPONENT, SET_SESSION_INFO_FOR_ERROR_MODAL, SessionInfoForErrorModal, WORKFLOW_CLICKED, ClusterSelectionInfoInPlot, CLUSTER_SELECTION_INFO, } from './types';
import { openANewWorkflowAnalyticsTab, updateAWorkflowAnalyticsTabInfo, WorkflowAnalyticsTabInfo } from '../canvas';
import { errorAlert } from '../../components/toastify/notify-toast';
import { omit, isEqual } from 'lodash';


export const toggleWorkflowsInAnalytics = (action?: boolean): AppThunk => (dispatch, getState) => {
    const currentToggleState = getState().AnalyticsReducer.isWorkflowTreeviewMinimized;
    let payload = !currentToggleState;
    if(action !== undefined) payload = action;
    dispatch({ type: TOGGLE_WORKFLOWS_IN_ANALYTICS, payload });
};

const runAnalyticsOnActiveComponent = (): RunAnalyticsOnActiveComponent => ({ type: RUN_ANALYTICS_ON_ACTIVE_COMPONENT });

export const toggleRunAnalyics = (): AppThunk => (dispatch) => {
    dispatch(runAnalyticsOnActiveComponent());
    // reset the state to false 
    // Analytics is executed on active component id when runAnalyticsOnActiveComponent is set to true
    // This is automatically set to false after 5 seconds
    setTimeout(() => dispatch(runAnalyticsOnActiveComponent()), 5000);
};


export const runAnalyticsOnActiveWorkflow = (componentId: string): AppThunk => (dispatch, getState) => {
    // when visualizations is clicked on a component in preview mode in workflow page
    const { activeTab, openTabs } = getState().CanvasReducer.workflowEditor;
    const activeWorkflowInfo = openTabs.get(activeTab.id);
    if(activeWorkflowInfo && activeWorkflowInfo.info.previewStatusInfo) {

        let _workflowInfo: WorkflowAnalyticsTabInfo = { 
            ...activeWorkflowInfo.info, 
            activeComponentIdForAnalytics: componentId,
            sessionId: activeWorkflowInfo.info.previewStatusInfo.workflowSessionId,
            previewStatementId: activeWorkflowInfo.info.previewStatusInfo.statementId,
            isStreaming: activeWorkflowInfo.info.previewStatusInfo.isStreaming,
            dfList: [],
            componentIdsOfPlots: [componentId],
            showPlotInProgressSpinner: false,
            graphsInfo: { layout: [], refs: {}, data: {}, fullScreenInfo: null},
            activeClusterIdForPreview: undefined,
            sessionState: null
        };

        if(activeWorkflowInfo.info.activeClusterIdForPreview) {
            _workflowInfo.activeClusterIdForPreview = activeWorkflowInfo.info.activeClusterIdForPreview;
        }

        _workflowInfo = omit(_workflowInfo, 'previewStatusInfo', 'activeComponentInfo');

        dispatch(openANewWorkflowAnalyticsTab(_workflowInfo));
        dispatch(toggleRunAnalyics());
    } else {
        errorAlert('Something went wrong opening current workflow');
    }
}; 


export const setComponentIdForAnalytics = (componentId: string): AppThunk => (dispatch, getState) => {
    const { activeTab, openTabs } = getState().CanvasReducer.analytics;
    const activeTabInfo = openTabs.get(activeTab.id);
    if(activeTabInfo) {
        activeTabInfo.info = { ...activeTabInfo.info, activeComponentIdForAnalytics: componentId };
        dispatch(updateAWorkflowAnalyticsTabInfo(activeTabInfo));
        dispatch(toggleRunAnalyics());
    }
}; 

export const setPlotDataProgress = (plotDataProgress: number): SetPlotDataProgress  =>
    ({type: SET_PLOTDATA_PROGRESS , payload: plotDataProgress});


export const toggleAnalyticsModal = (modalType: ModalTypes, action?: boolean): AppThunk => (dispatch, getState) => {
    if(action !== undefined) {
        dispatch({ type: TOGGLE_MODAL, payload: { [modalType]: action }});
    } else {
        const currentState = getState().AnalyticsReducer.showModal[modalType];
        dispatch({ type: TOGGLE_MODAL, payload: { [modalType]: !currentState }});
    }
}; 

export const handleAddOrRemoveComponentIdOfActivePlots = (action: 'ADD' | 'REMOVE', componentId: string): AppThunk => (dispatch, getState) => {
    const { activeTab, openTabs } = getState().CanvasReducer.analytics;
    const activeTabInfo = openTabs.get(activeTab.id);
    if(activeTabInfo) {
        let selectedComponentIds = [...activeTabInfo.info.componentIdsOfPlots];
        const isIdAdded = selectedComponentIds.find(id => id === componentId);
        if(action === 'ADD' && !isIdAdded) {
            selectedComponentIds.push(componentId);
        } else if(action === 'REMOVE') {
            selectedComponentIds = selectedComponentIds.filter(_id => _id !== componentId);
        }
        if(!isEqual(selectedComponentIds, activeTabInfo.info.componentIdsOfPlots)){
            activeTabInfo.info = { ...activeTabInfo.info, componentIdsOfPlots: selectedComponentIds };
            dispatch(updateAWorkflowAnalyticsTabInfo(activeTabInfo));
        }
    }
};

export const togglePlotInProgressSpinner = (action: boolean): AppThunk => (dispatch, getState) => {
    const { activeTab, openTabs } = getState().CanvasReducer.analytics;
    const activeTabInfo = openTabs.get(activeTab.id);
    if(activeTabInfo) {
        activeTabInfo.info.showPlotInProgressSpinner = action;
        dispatch(updateAWorkflowAnalyticsTabInfo(activeTabInfo));
    }
};

export const setPlotStatmentInfoForErrorModal = (payload: string | null): SessionInfoForErrorModal => ({ type: SET_SESSION_INFO_FOR_ERROR_MODAL, payload });
export const onWorkflowClick = () => ({ type: WORKFLOW_CLICKED });

export const setClusterInfoInPlot = ({ type, error = '' }: ClusterSelectionInfoInPlot) => {
    return { type: CLUSTER_SELECTION_INFO, payload: { type, error}}
}