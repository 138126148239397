import { Env } from '@constants/settings';
import { capitalize, cloneDeep, lowerCase, union } from 'lodash';
import { convertEnumsToOptions, getBooleanCheckboxOptions } from '../../../utils';
import { _selectoptionType } from '../../form/select-field';
import { BaseFieldType } from '../types';


export enum ModeOptions {
    'Fit Data' = 'fit', 
    'Fit and Transform Data' = 'fit_and_transform', 
    'Transform Data' ='transform',
    'Upload Model' = 'upload_model'
}


export enum WindowOptions {
    'Time' = 'Time' , 
    'Depth' = 'Depth', 
    'GeoSpatial' = 'GeoSpatial'
}

export enum DataCategoryOptions {
    'Geospatial Data' = 'Geospatial Data',
    'Text Data' = 'Text Data',
    'Other' = 'Other'

}

export const PREPROCESSING = 'preprocessing';

export enum PipelineCategoryOptions {
    // Preprocessing = 'preprocessing', 
    Classification ='classification', 
    Regression = 'regression', 
    Clustering = 'clustering',
    "Semi-Supervised"  ='semi-supervised',
    Layers = 'layers'
}



export enum SparkModelLibraryOptions {
    Spark = 'Spark', 
}

export enum PythonModelLibraryOptions {
    Sklearn = 'sklearn',
    Tensorflow = 'tensorflow'
}

export type ModelLibraryOptions = SparkModelLibraryOptions | PythonModelLibraryOptions

export enum ValidateModelOptions {
    Holdout = 'holdout', 
    'Cross-Validation' = 'cross_validation'
}
export enum PreprocessingKeys {
    PREPROCESSING_Spark = SparkModelLibraryOptions.Spark
}

export enum MlPipelineStageKeys  {
    // make sure these keys are consistent with ModelLibraryOptions
    Spark = 'Spark',
    sklearn = 'sklearn',
    tensorflow = 'tensorflow',
    PREPROCESSING_Spark = 'PREPROCESSING_spark',
    PREPROCESSING_Sklearn = 'PREPROCESSING_sklearn'
}

export enum TensorflowExtraModelParams {
    losses = 'losses',
    optimizers = 'optimizers',
    metrics = 'metrics'
}

export enum EvalMetricRegOptions {
    RMSE = 'rmse',
    MSE = 'mse',
    MAE = 'mae',
    'R2' = 'R2'
}

export enum EvalMetricClassOptions {
    Accuracy = 'accuracy',
    Precision = 'precision',
    Recall = 'recall',
    'F1 Measure' = 'f1Measure'
}

const evalMetricSklearnClassOptions = [
    'accuracy',
    'balanced_accuracy',
    'top_k_accuracy',
    'average_precision',
    'neg_brier_score',
    'f1',
    'f1_micro',
    'f1_macro',
    'f1_weighted',
    'f1_samples',
    'neg_log_loss',
    'roc_auc',
    'roc_auc_ovr',
    'roc_auc_ovo',
    'roc_auc_ovr_weighted',
    'roc_auc_ovo_weighted'
];

const evalMetricSklearnClusteringOptions = [
    'adjusted_mutual_info_score',
    'adjusted_rand_score',
    'completeness_score',
    'fowlkes_mallows_score',
    'homogeneity_score',
    'mutual_info_score',
    'normalized_mutual_info_score',
    'rand_score',
    'v_measure_score'
];

const evalMetricSklearnRegOptions = [
    'explained_variance',
    'max_error',    
    'neg_mean_absolute_error',    
    'neg_mean_squared_error',    
    'neg_root_mean_squared_error',    
    'neg_mean_squared_log_error',    
    'neg_median_absolute_error',    
    'r2',    
    'neg_mean_poisson_deviance',    
    'neg_mean_gamma_deviance',    
    'neg_mean_absolute_percentage_error'
];

export enum OutputFormatOptions {
    Exploded = 'exploded',
    Vectorized = 'vectorized'
}

export enum InputTypeOptions {
    'Default (dataframes)' = 'dataframe',
    'Image folder' = 'image_directory',
    'Text folder' = 'text_directory',
    'Numpy array folder' = 'numpy_directory',
    'Parquet folder' = 'parquet_folder'
}

export enum LabelModeOptions {
    'Int' = 'int',
    'Categorical' = 'categorical',
    'Binary' = 'binary',
    'None' = 'None',
}

export enum ColorModeOptions {
    'grayscale' = 'grayscale',
    'rgb' = 'rgb',
    'rgba' = 'rgba',
}

export enum SplitForTestTypes {
    'Split By Fraction' = 'split_by_fraction',
    'Split By Pattern' = 'split_by_pattern'
}

export enum BalanceModes {
    'Undersample' = 'undersample',
    'Oversample' = 'oversample'
}

export enum DataSet {
    'Images' = 'images',
    'Table' = 'table'
}

export enum LabelModel {
    'Integer' = 'int', 
    'Binary' = "binary", 
    'Categorical' = 'categorical'
}

export enum AutoResize {
    'True' = 'true',
    'False' = 'false'
}

export enum TensorflowLayers {
    "Pretrained Model" = "pretrained_model"
}


export const inputTypeFieldName = 'input_type';

export const modeOptionsForDropdown = convertEnumsToOptions(ModeOptions);
export const windowOptionsForDropdown = convertEnumsToOptions(WindowOptions);
export const dataCategoryOptionsForDropdown = convertEnumsToOptions(DataCategoryOptions);
export const pipelineCategoryOptionsForDropdown = convertEnumsToOptions(PipelineCategoryOptions);
export const modelLibraryOptionsForDropdown = union(convertEnumsToOptions(SparkModelLibraryOptions), convertEnumsToOptions(PythonModelLibraryOptions));
export const validateModelOptionsForDropdown = convertEnumsToOptions(ValidateModelOptions);
export const evalMetricRegOptionsForDropdown = convertEnumsToOptions(EvalMetricRegOptions);
export const evalMetricClassOptionsForDropdown = convertEnumsToOptions(EvalMetricClassOptions);
export const outputFormatOptionsForDropdown = convertEnumsToOptions(OutputFormatOptions);
// hide parqeut if petastorm is disabled
export const inputTypeOptionsForDropdown = convertEnumsToOptions(InputTypeOptions).slice(0, Env.disablePetastorm ? -1:undefined);
export const splitForTestTypesOptions = convertEnumsToOptions(SplitForTestTypes);
export const labelModeOptionsForDropdown = convertEnumsToOptions(LabelModeOptions);
export const colorModeOptionsForDropdown = convertEnumsToOptions(ColorModeOptions);
export const balanceModeOptions = convertEnumsToOptions(BalanceModes);
export const dataSetOptions = convertEnumsToOptions(DataSet);
export const labelModelOptions = convertEnumsToOptions(LabelModel);
export const autoResizeOptions = convertEnumsToOptions(AutoResize);

const convertStringToDropdownOptions = (options: string[]) => options.map(opt => ({
    label: capitalize(lowerCase(opt)), value: opt
}));

export const evalMetricSklearnClassOptionsForDropdown = convertStringToDropdownOptions(evalMetricSklearnClassOptions);
export const evalMetricSklearnClusteringOptionsForDropdown = convertStringToDropdownOptions(evalMetricSklearnClusteringOptions);
export const evalMetricSklearnRegOptionsForDropdown = convertStringToDropdownOptions(evalMetricSklearnRegOptions);

export const booleanOptions = [{label: 'True', value: true }, { label: 'False', value: false }];
export const splitForTestOptions = getBooleanCheckboxOptions('Split For Test', 'Optionally split input data into training and testing data.');
export const useGeneratorOptions = getBooleanCheckboxOptions('Use Generator', 'Indicates whether custom generator is requred or not.');
export const balanceBatchesOptions = getBooleanCheckboxOptions('Balance Batches', 'Indicates if batches have to be balanced or not. All the classes will have have equal number of samples in each batch if this option is enabled.');
export const balanceClassesOptions = getBooleanCheckboxOptions('Balance Classes', 'Samples will be fed for training randomly, some batches might have more instances of a class but the average number of samples per class will be the same');
export const sampleFromDatasetsOptions = getBooleanCheckboxOptions('Sample From Datasets', 'Only used if use generator is False. Uses alternative method to create datasets by sampling from datasets of individual classes');
export const encodeCategoricalOptions = getBooleanCheckboxOptions('Encode Categorical Features', 'Check this box to transform categorical data to numerical values');
export const buildModelOptions = getBooleanCheckboxOptions('Build Model', 'You can add model on top of your preprocessing steps when using Spark as model library. Preprocessing steps are currently unavailable when using Sklearn as model library.');
export const encodeCategoricalTargetOptions = getBooleanCheckboxOptions('Encode Categorical Label', 'Check this box to transform categorical labels to numerical values');
export const splitForWindowOptions = getBooleanCheckboxOptions('Validate Model', 'Select to enable hypertuning parameters');
export const showIntermediateValuesOptions = getBooleanCheckboxOptions('Show Intermediate Outputs', 'Mentions whether to include the outputs from intermediate stages while returning the output DataFrame. If unchecked, then the DataFrame would only include the final output column');
export const balanceBatchOptions = getBooleanCheckboxOptions('Balance Batches');
export const trainableOptions = getBooleanCheckboxOptions('Trainable')

export const featuresFieldData: BaseFieldType = {
    'key': 'features',
    'type': 'input',
    'defaultValue': '',
    'useCaptureSchema': {
        'multipleSelect': true,
        limitType: 'struct',
        showType: false
    },
    'templateOptions': {
        'variable_type': 'string',
        'label': 'Features',
        'placeholder': 'col1, col2',
        'qtip': 'Comma separated string of features to be used for fitting the model. Click "populate schema" to import schema from input data and select features from drop-down. You can add the column names manually as well',
        'options': [],
        required: true
    },
};

export const labelColFieldData: BaseFieldType = {
    'key': 'label_col',
    'type': 'input',
    'defaultValue': '',
    'useCaptureSchema': {
        'multipleSelect': false,
        limitType: 'struct',
        showType: true
    },
    'templateOptions': {
        'variable_type': 'string',
        'label': 'Label Column',
        'options': [],
        qtip: 'The column that the model is learning to predict',
        required: true
    }
};

export enum OperationOptions {
    'Scale' = 'scale',
    'Reduce Dimensions' = 'reduce_dimensions',
    'Train Model' = 'train_model'
}

export enum SkLearnPreProcessingOptions {
  'Scaler' = 'Scaler',
  'Binarizer' =  'Binarizer',
  'Transformer' =   'Transformer',
  'Encoder' = 'Encoder',
  'Reduce Dimensions' = 'Reduce Dimensions'
 }

export enum ExtraOperationOptions {
    'Structured Learning' = 'synthesize_features',
    'Feature Vectorizers' = 'FeatureVectorizers',
    'Text Processors' = 'Text Processors',
}

export enum ScalerTypes {
    'Standard' = 'StandardScaler',
    'Min Max'= 'MinMaxScaler',
    'Max Absolute' = 'MaxAbsScaler'
}

export enum FeatureVectorizersTypes {
    'Binarizer' = 'Binarizer',
    'Count Vectorizer' = 'CountVectorizer',
    'Feature Hasher' = 'FeatureHasher',
    'Hashing Transformer' = 'HashingTF',
    'IDF Vectorizer' = 'IDF',
}

export enum TextProcessorTypes {
    'N-Gram' = 'NGram',
    'Tokenizer' = 'Tokenizer',
    'RegexTokenizer' = 'RegexTokenizer',
    'Stemmer' = 'Stemmer',
    'Stop Words Remover' = 'StopWordsRemover',
}

// TRAIN MODEL IS NOT SHOWN IN THE DROPDOWN, IT IS AUTOMATICALLY SELECTED
export const operationOptionsForDropdown = convertEnumsToOptions(OperationOptions).slice(0, 2);
export const extraOperationOptionsForDropdown = convertEnumsToOptions(ExtraOperationOptions);
export const scalerTypesOptionsForDropdown = convertEnumsToOptions(ScalerTypes);
export const featureVectorizerTypesOptionsForDropdown = convertEnumsToOptions(FeatureVectorizersTypes);
export const textProcessorTypesOptionsForDropdown = convertEnumsToOptions(TextProcessorTypes);
export const SkLearnPreProcessingOperationDropdown = convertEnumsToOptions(SkLearnPreProcessingOptions);

export enum ClassificationTypes  {
    'Decision Tree Classification' = 'DecisionTreeClassifier',
    'Gradient Boosted Tree Classification' = 'GBTClassifier',
    'Linear SVM' = 'LinearSVC',
    'Logistic Regression' = 'LogisticRegression', 
    'Naive Bayes' = 'NaiveBayes',
    'Neural Net' = 'MultilayerPerceptronClassifier',
    'Random Forest Classification' = 'RandomForestClassifier'
}

export enum ClusteringTypes {
    'Bisecting K-Means' = 'BisectingKMeans',
    'Gaussian Mixture' = 'GaussianMixture',
    'K-Means' = 'KMeans',
    'LDA' = 'LDA'
}

export enum RegressionTypes {
    'Decision Tree Regression' = 'DecisionTreeRegressor',
    'Generalized Linear Regression' = 'GeneralizedLinearRegression',
    'Gradient Boosted Tree Regression' = 'GBTRegressor',
    'Linear Regression' = 'LinearRegression',
    'Random Forest Regression' = 'RandomForestRegressor'
}

export const classificationTypesForDropdown = convertEnumsToOptions(ClassificationTypes);
export const clusteringTypesForDropdown = convertEnumsToOptions(ClusteringTypes);
export const regressionTypesForDropdown = convertEnumsToOptions(RegressionTypes);


export enum TuneFieldOptions {
    List = 'List',
    Range = 'Range'
}

export const tuneFieldOptionsForDropdown = convertEnumsToOptions(TuneFieldOptions);



export enum TuneTypes {
    Range = 'Range',
    List = 'List',
    Dropdown = 'Dropdown',
    Checkbox = 'Checkbox'
} 


export type sklearnscalerOptions = {
    label: string;
    value: string;
    pythonModule: string;
}

export type HyperParamsInfo = {
    key: string;
    info: {
        hyperParamLabel: string;
        tune: boolean;
        hyperParamType: TuneTypes;
        hyperParamValue: string;
        name: string;
        options?: {
            label: string;
            value: string | boolean;
        }[];
        variableType: 'float' | 'boolean' | 'string';
        disabled?: boolean;
        qtip: string;
        hideExpression?: string;
        // for generalized linear expression
        paramBasedOptions?: {
            paramName: string;
            options: Record<string, { label: string; value: string | boolean }[]>;
        };
    }[];
    showTypeColumn: boolean;
    // for sklearn;
    python_module?: string;
}

export const generateRangeHyperParam = (hyperParamLabel: string, hyperParamVariablename: string, qtip = '', disabled = false, hideExpression: string | undefined = undefined): HyperParamsInfo['info'][0] => 
    ({ hyperParamLabel, tune: false, hyperParamType: TuneTypes.List, hyperParamValue: '', name: hyperParamVariablename, variableType: 'float', qtip, disabled, hideExpression });

export default generateRangeHyperParam;

export const getDefaultCheckboxOptions = () => cloneDeep([{'label': 'True', 'value': 'true'}, {'label': 'False', 'value': 'false'}]);
// export const generateDefaultCheckboxHyperParam = (hyperParamLabel: string, hyperParamVariablename: string,  qtip = '', disabled = false, hideExpression: string | undefined = undefined): HyperParamsInfo['info'][0] => 
export const generateDefaultCheckboxHyperParam = (hyperParamLabel: string, hyperParamVariablename: string,  qtip = '', disabled = false): HyperParamsInfo['info'][0] => 
    ({ hyperParamLabel, tune: false, hyperParamType: TuneTypes.Checkbox, hyperParamValue: '', name: hyperParamVariablename, 
        options: getDefaultCheckboxOptions(), variableType: 'boolean', qtip, disabled });

export const generateDropdownHyperParam = (hyperParamLabel: string, hyperParamVariablename: string,  qtip = '', options: _selectoptionType[], disabled = false, hideExpression: string | undefined = undefined): HyperParamsInfo['info'][0] => 
    ({ hyperParamLabel, tune: false, hyperParamType: TuneTypes.Dropdown, hyperParamValue: '', name: hyperParamVariablename, 
        options, variableType: 'string', qtip, disabled, hideExpression });
    
export const generateInputHyperParam = (hyperParamLabel: string, hyperParamVariablename: string,  qtip = '',  disabled = false, hideExpression: string | undefined = undefined): HyperParamsInfo['info'][0] => 
    ({ hyperParamLabel, tune: false, hyperParamType: TuneTypes.List, hyperParamValue: '', name: hyperParamVariablename, 
        variableType: 'string', qtip, disabled, hideExpression });
// hyperParamLabel: sklearnHyperParamName,
//                         tune: false,
//                         hyperParamType: TuneTypes.List,
//                         hyperParamValue: '',
//                         name: sklearnHyperParamName,
//                         variableType: sklearnHyperParamInfo.desc === 'str' ? 'string': 'float',
//                         qtip: sklearnHyperParamInfo.desc

export const StandardHyperParams: HyperParamsInfo = {
    key: 'scale_std_hyperparams',
    info: [
        generateDefaultCheckboxHyperParam('With Mean', 'withMean', 'Center data with mean'),
        generateDefaultCheckboxHyperParam('With Standard', 'withStd', 'Scale to unit standard deviation')
    ],
    showTypeColumn: false
};

export const MinMaxHyperParams: HyperParamsInfo = {
    key: 'scale_minMax_hyperparams',
    info: [
        generateRangeHyperParam('Upper Bound', 'min', 'Upper bound of the output feature range'),
        generateRangeHyperParam('Lower Bound', 'max', 'Lower bound of the output feature range'),
    ],
    showTypeColumn: true
};


export const ReduceDimensionsParams: HyperParamsInfo = {
    key: 'reduce_dimensions_hyperparams',
    info: [
        generateRangeHyperParam('No. of Principal Components', 'k', '',true)
    ],
    showTypeColumn: true
};

export const GeoSpatialParams: HyperParamsInfo = {
    key: 'train_geo_spatial', 
    info:[
        generateRangeHyperParam('Neighborhood Size', 'k', '',false)
    ],
    showTypeColumn: true
};

export const StopWordsRemoverParams: HyperParamsInfo = {
    key: 'StopWordsRemover',
    info: [
        generateInputHyperParam('Stop Words', 'stopWords', 'List of words to be removed from the text'),
        generateDefaultCheckboxHyperParam('Case Sensitive', 'caseSensitive'),
    ],
    showTypeColumn: false
};

export const CountVectorizerParams: HyperParamsInfo = {
    key: FeatureVectorizersTypes['Count Vectorizer'],
    info: [
        generateRangeHyperParam('Vocabulary Size', 'vocabSize', 'Optional - size of vocabulary to use'),
        generateRangeHyperParam('Minimum Document Size', 'minDF', 'Minimum number of documents a term must appear in, to be used'),
        
    ],
    showTypeColumn: false
};

export const IDFVectorizerParams: HyperParamsInfo = {
    key: FeatureVectorizersTypes['IDF Vectorizer'],
    info: [
        generateRangeHyperParam('Minimum Document Size', 'minDocFreq', 'Minimum number of documents a term must appear in, to be used')
    ],
    showTypeColumn: true
};

export const HashingTransformerParams: HyperParamsInfo = {
    key: FeatureVectorizersTypes['Hashing Transformer'],
    info: [
        generateRangeHyperParam('Number of Features', 'numFeatures', 'Number of Features'),
        generateDefaultCheckboxHyperParam('Force to Binary', 'binary', 'Set all non-zero counts to 1'),
    ],
    showTypeColumn: true
};

export const BinarizerParams: HyperParamsInfo = {
    key: FeatureVectorizersTypes['Binarizer'],
    info: [
        generateRangeHyperParam('Threshold', 'threshold', 'Values above the threshold are converted to 1 and the rest as 0'),
    ],
    showTypeColumn: true
};

export const FeatureHasherParams: HyperParamsInfo = {
    key: FeatureVectorizersTypes['Feature Hasher'],
    info: [],
    showTypeColumn: true
};

export const NGramParams: HyperParamsInfo = {
    key: TextProcessorTypes['N-Gram'],
    info: [
        generateRangeHyperParam('n', 'n', 'Number of words in n-gram'),
    ],
    showTypeColumn: true
};

export const TokenizerParams: HyperParamsInfo = {
    key: TextProcessorTypes['Tokenizer'],
    info: [],
    showTypeColumn: true
};

export const RegexTokenizerParams: HyperParamsInfo = {
    key: TextProcessorTypes['RegexTokenizer'],
    info: [
        generateRangeHyperParam('Min Token Length', 'minTokenLength', 'Minimum token length'),  
        generateDefaultCheckboxHyperParam('Split Text', 'gaps', 'Split the text or repeatedly run the regex'),
        generateInputHyperParam('Pattern', 'pattern', 'Regular Expression Pattern to match for each token (Java style regex)'),
        generateDefaultCheckboxHyperParam('To Lower Case', 'toLowerCase', 'Convert to lower case'),
    ],
    showTypeColumn: true
};

export const StemmerParams: HyperParamsInfo = {
    key: TextProcessorTypes['Stemmer'],
    info: [
        generateDropdownHyperParam(
            'Language', 
            'language', 
            'Language of Text', 
            [{
                label: 'English',
                value: 'English'
            }]    
        ),
        generateDropdownHyperParam(
            'Stemmer Class', 
            'stemmer_class', 
            'Type of stemmer to use', 
            [
                {
                    label: 'Snowball',
                    value: 'Snowball'
                },
                {
                    label: 'Portal',
                    value: 'Portal'
                }
            ]    
        ),
    ],
    showTypeColumn: true
};


type MlModelsHyperParams = {
    params: HyperParamsInfo['info']; 
    initialSelection: Record<string, boolean>;
    defaultValues: Record<string, any>;
}

const linearSvmHyperParams: MlModelsHyperParams = {
    params:[
        generateRangeHyperParam('Convergence Tolerance', 'tol', 'Param for the convergence tolerance for iterative algorithms (>= 0)'),
        generateDefaultCheckboxHyperParam('Fit Intercept', 'fitIntercept', 'Param for whether to fit an intercept term'),
        generateRangeHyperParam('Max Iterations', 'maxIter', 'Param for maximum number of iterations (>= 0)'),
        generateRangeHyperParam('Regularization Param', 'regParam', 'Param for regularization parameter (>= 0)'),
        generateRangeHyperParam('Threshold', 'threshold', 'Param for threshold in binary classification prediction'),
    ],
    initialSelection: {
        'tol': false,
        'fitIntercept': false,
        'maxIter': true,
        'regParam': true,
        'threshold': false
    },
    defaultValues: {
        'maxIter': 100,
        // number 0.0 is not working as default value
        'regParam': '0.0',
    }
};

const logisticRegressionHyperParams: MlModelsHyperParams = {
    params: union(
        [
            linearSvmHyperParams.params[0],
            generateRangeHyperParam('Elastic Net Parameter', 'elasticNetParam', 'Param for the ElasticNet mixing parameter, 0 for L2 penalty and 1 for L1 penalty, in range [0, 1]')
        ],
        linearSvmHyperParams.params.slice(1)
    ),
    initialSelection: {
        ...linearSvmHyperParams.initialSelection,
        'elasticNetParam': true
    },
    defaultValues: {
        ...linearSvmHyperParams.defaultValues,
        'elasticNetParam': '0.0'
    }
};

const naiveBayesHyperParams: MlModelsHyperParams = { params: [], initialSelection: {}, defaultValues: {}};

const decisionTreeClassiferParams: MlModelsHyperParams = {
    params: [
        generateDropdownHyperParam(
            'Impurity', 
            'impurity', 
            'Criterion used for information gain calculation.',
            [{'label': 'Gini', 'value': 'gini'}, {'label': 'Entropy', 'value': 'entropy'}],
        ),
        generateRangeHyperParam('Max Bins', 'maxBins', 'Maximum number of bins used for discretizing continuous features and for choosing how to split on features at each node'),
        generateRangeHyperParam('Max Depth', 'maxDepth', 'Maximum depth of the tree (> 0)'),
        generateRangeHyperParam('Min Info Gain', 'minInfoGain', 'Minimum information gain for a split to be considered at a tree node'),
        generateRangeHyperParam('Min Instances per Node', 'minInstancesPerNode', 'Minimum number of instances each child must have after split'),
        generateRangeHyperParam('Min Weight Fraction per Node', 'minWeightFractionPerNode', 'Minimum fraction of the weighted sample count that each child must have after split')
    ],
    initialSelection: {
        impurity: true,
        maxBins: true,
        'maxDepth': true,
    },
    defaultValues: {
        'impurity': 'gini',
        'maxBins': 32,
        'maxDepth': 5,
        dataset_type: 'images'
    }
};

const gradientBoostingTreesHyperParams: MlModelsHyperParams = {
    params: union(
        [
            generateDropdownHyperParam(
                'Feature Subset Strategy', 
                'featureSubsetStrategy', 
                'The number of features to consider for splits at each tree node',
                [{'label': 'Auto', 'value': 'auto'}, {'label': 'All', 'value': 'all'}, {'label': 'One-Third', 'value': 'onethird'}, {'label': 'Square-Root', 'value': 'sqrt'}, {'label': 'Log2', 'value': 'log2'}],
            ),
            decisionTreeClassiferParams.params[1],
            decisionTreeClassiferParams.params[2],
            linearSvmHyperParams.params[2],
        ],
        decisionTreeClassiferParams.params.slice(3,),
    )
    ,
    initialSelection: {
        ...decisionTreeClassiferParams.initialSelection,
        'maxIter': true,
    },
    defaultValues: {
        ...decisionTreeClassiferParams.defaultValues,
        'maxIter': 20,
        'featureSubsetStrategy': 'auto'
    }
}; 

const neuralNetHyperParams: MlModelsHyperParams = {
    params: [
        linearSvmHyperParams.params[2],
        generateRangeHyperParam(
            'Random Seed',
            'seed',
            'Random seed'
        ),
        generateRangeHyperParam(
            'Layers',
            'layers',
            `'Sizes of layers from input layer to output layer 
            E.g., Array(780, 100, 10) means 780 inputs, 
            one hidden layer with 100 neurons and output layer of 10 neurons.')`
        ),
        generateRangeHyperParam(
            'Block Size',
            'blockSize',
            'Batch size'
        ),
        generateRangeHyperParam(
            'Step Size',
            'stepSize',
            'Step size to be used for each iteration for optimization'
        ),
        linearSvmHyperParams.params[0]
    ],
    initialSelection: {
        'maxIter': true,
        'blockSize': true,
        'stepSize': true,
        'tol': true,
        'layers': true
    },
    defaultValues: {
        'maxIter': 100,
        'blockSize': 128,
        'stepSize': 0.01,
        'seed': 1,
        'tol': 0.01
    }
}; 

const randomForestClassifierHyperParams: MlModelsHyperParams = {
    params:
        union(
            [
                gradientBoostingTreesHyperParams.params[0]
            ],
            decisionTreeClassiferParams.params,
            [
                generateRangeHyperParam('Number of Trees', 'numTrees', 'Number of trees to train (at least 1)', true),
                generateRangeHyperParam('Sub Sampling Rate', 'subsamplingRate', 'Fraction of the training data used for learning each decision tree, in range (0, 1]'),
            ]
        ),
    initialSelection: {
        ...decisionTreeClassiferParams.initialSelection,
        'featureSubsetStrategy': false,
        'numTrees': true,
        'subsamplingRate': false,
    },
    defaultValues: {
        ...decisionTreeClassiferParams.defaultValues,
        'numTrees': 20,
        'featureSubsetStrategy': 'auto'
    }
};

const linearRegressionHyperParams: MlModelsHyperParams = {
    params: [
        linearSvmHyperParams.params[0],
        generateRangeHyperParam('Elastic Net Parameter', 'elasticNetParam', 'Param for the ElasticNet mixing parameter, 0 for L2 penalty and 1 for L1 penalty, in range [0, 1]'),
        linearSvmHyperParams.params[1],
        generateDropdownHyperParam(
            'Loss', 
            'loss', 
            'The loss function to be optimized', 
            [{label: 'Huber', value: 'huber'}, {label: 'Squared Error', value: 'squaredError'}]
        ),
        linearSvmHyperParams.params[2],
        linearSvmHyperParams.params[3],
        generateDropdownHyperParam(
            'Solver', 
            'solver', 
            'The solver algorithm for optimization', 
            [{label: 'Auto', value: 'auto'}, {label: 'L-BFGS', value: 'l-bfgs'}]
        )
    ],
    initialSelection: {
        ...linearSvmHyperParams.initialSelection,
        'elasticNetParam': true,
        'loss': true
    },
    defaultValues: {
        ...linearSvmHyperParams.defaultValues,
        'elasticNetParam': '0.0',
        loss: 'squaredError',
        solver: 'auto'
    }
};

const familyDropdownOptions = [
    {label: 'Gaussian', value: 'gaussian'}, 
    {label: 'Binomial', value: 'binomial'}, 
    {label: 'Poisson', value: 'poisson'}, 
    {label: 'Gamma', value: 'gamma'}, 
    {label: 'Tweedie', value: 'tweedie'}
];

const linkDropdownOptions = {
    [familyDropdownOptions[0].value]: [{label: 'Identity', value: 'identity'}, {label: 'Log', value: 'log'}, {label: 'Inverse', value: 'inverse'}],
    [familyDropdownOptions[1].value]: [{label: 'Logit', value: 'logit'}, {label: 'Probit', value: 'probit'}, {label: 'Cloglog', value: 'cloglog'}],
    [familyDropdownOptions[2].value]: [{label: 'Log', value: 'log'}, {label: 'Identity', value: 'identity'}, {label: 'Square Root', value: 'sqrt'}],
    [familyDropdownOptions[3].value]: [{label: 'Inverse', value: 'inverse'}, {label: 'Identity', value: 'identity'}, {label: 'Log', value: 'log'}],
};

const genlinearRegressionHyperParams: MlModelsHyperParams = {
    params: union(
        [
            linearRegressionHyperParams.params[0],
            generateDropdownHyperParam(
                'Family', 
                'family', 
                'Param for the name of family which is a description of the error distribution to be used in the model.', 
                familyDropdownOptions
            ),
            linearRegressionHyperParams.params[2],
            {
                hyperParamLabel: 'Link',
                name: 'link',
                hyperParamType: TuneTypes.Dropdown,
                hyperParamValue: '',
                variableType: 'string',
                disabled: true,
                qtip: 'Param for the name of link function which provides the relationship between the linear predictor and the mean of the distribution function.',
                hideExpression: '{family} === "tweedie"',
                tune: false,
                paramBasedOptions: {
                    paramName: 'family',
                    options: linkDropdownOptions
                }
            },
            generateRangeHyperParam('Link Power', 'linkPower', 'Param for the index in the power link function. (>= 0)', false, '{family} !== "tweedie"'),
            generateRangeHyperParam('Variance Power', 'variancePower', 'Param for the power in the variance function of the Tweedie distribution which provides the relationship between the variance and mean of the distribution. Only applicable to the Tweedie family. Supported values: 0 and [1, Inf). Note that variance power 0, 1, or 2 corresponds to the Gaussian, Poisson or Gamma family, respectively')
        ],
        linearRegressionHyperParams.params.slice(4,)
    ),
    initialSelection:{
        ...linearRegressionHyperParams.initialSelection,
        family: true,
        link: true,
        variancePower: true
    },
    defaultValues: {
        ...linearRegressionHyperParams.defaultValues,
        family :'gaussian',
        link: 'identity'
    }
};

const decisionTreeRegressionHyperParams: MlModelsHyperParams = {
    params: decisionTreeClassiferParams.params.slice(1),
    initialSelection: decisionTreeClassiferParams.initialSelection,
    defaultValues: decisionTreeClassiferParams.defaultValues,
};

const gradBoostingTreesRegressorHyperParams: MlModelsHyperParams = {
    params: union(
        gradientBoostingTreesHyperParams.params,
        [
            generateRangeHyperParam('Sub Sampling Rate', 'subsamplingRate', 'Fraction of the training data used for learning each decision tree, in range (0, 1]'),
        ]
    ),
    initialSelection: gradientBoostingTreesHyperParams.initialSelection,
    defaultValues: gradientBoostingTreesHyperParams.defaultValues,
};

const randomForestRegressorHyperParams: MlModelsHyperParams = {
    params: union(
        gradBoostingTreesRegressorHyperParams.params.slice(0, 3),
        gradBoostingTreesRegressorHyperParams.params.slice(4, 7),
        [
            generateRangeHyperParam('Number of Trees', 'numTrees', 'Number of trees to train (at least 1)', true),
            gradBoostingTreesRegressorHyperParams.params[7],
        ]
    ),
    initialSelection: {
        ...gradientBoostingTreesHyperParams.initialSelection,
        'numTrees': true,
    },
    defaultValues: {
        ...gradientBoostingTreesHyperParams.defaultValues,
        'numTrees': 20,
    }
};

const kMeansHyperParams: MlModelsHyperParams = {
    params: [
        linearSvmHyperParams.params[0],
        generateDropdownHyperParam(
            'Distance Measure', 
            'distanceMeasure', 
            'Param for The distance measure', 
            [{label: 'Euclidean', value: 'euclidean'}, {label: 'Cosine', value: 'cosine'}]
        ),
        linearSvmHyperParams.params[2],
        generateRangeHyperParam('Number of Clusters', 'k', 'The number of clusters to create (k)', true),
    ],
    initialSelection:{
        distanceMeasure: true,
        k: true,
        maxIter: true
    },
    defaultValues: {
        distanceMeasure: 'euclidean',
        k: 2,
        maxIter: 20
    }
};

const bisectingKMeansHyperParams: MlModelsHyperParams = {
    params: kMeansHyperParams.params.slice(1,),
    initialSelection: kMeansHyperParams.initialSelection,
    defaultValues: kMeansHyperParams.defaultValues
};

const gaussianMixtureHyperParams: MlModelsHyperParams = {
    params: [
        linearSvmHyperParams.params[0],
        linearSvmHyperParams.params[2],
        generateRangeHyperParam('Number of Gaussians', 'k', 'Number of independent Gaussians in the mixture model', true)
    ],
    initialSelection: {
        k: true,
        maxIter: true
    },
    defaultValues: {
        k: 2,
        maxIter: 20
    }
};

const ldaHyperParams: MlModelsHyperParams = {
    params: [
        linearSvmHyperParams.params[2],
        generateRangeHyperParam('Number of Topics', 'k', 'Number of topics'),
        generateRangeHyperParam('Random Seed', 'seed', 'Seed for random number generator'),
        generateDropdownHyperParam(
            'Optimizer', 
            'optimizer', 
            'Optimizer algorithm to use', 
            [{label: 'em', value: 'em'}, {label: 'online', value: 'online'}]
        ),
        generateDefaultCheckboxHyperParam('Optimize Doc Concentration', 'optimizeDocConcentration', 'Boolean to indicate that model should optimizer for document concentration'),

    ],
    initialSelection: {
        k: true,
    },
    defaultValues: {
        k: 10,
        seed: 1,
        optimizer: 'online',
        optimizeDocConcentration:'true',
    }
};

export const mlModelsHyperParams = {
    [ClassificationTypes['Linear SVM']]: linearSvmHyperParams,
    [ClassificationTypes['Logistic Regression']]: logisticRegressionHyperParams,
    [ClassificationTypes['Naive Bayes']]: naiveBayesHyperParams,
    [ClassificationTypes['Decision Tree Classification']]: decisionTreeClassiferParams,
    [ClassificationTypes['Gradient Boosted Tree Classification']]: gradientBoostingTreesHyperParams,
    [ClassificationTypes['Neural Net']]: neuralNetHyperParams,
    [ClassificationTypes['Random Forest Classification']]: randomForestClassifierHyperParams,
    [RegressionTypes['Linear Regression']]: linearRegressionHyperParams,
    [RegressionTypes['Generalized Linear Regression']]: genlinearRegressionHyperParams,
    [RegressionTypes['Decision Tree Regression']]: decisionTreeRegressionHyperParams,
    [RegressionTypes['Gradient Boosted Tree Regression']]: gradBoostingTreesRegressorHyperParams,
    [RegressionTypes['Random Forest Regression']]: randomForestRegressorHyperParams,
    [ClusteringTypes['K-Means']]: kMeansHyperParams,
    [ClusteringTypes['Bisecting K-Means']]: bisectingKMeansHyperParams,
    [ClusteringTypes['Gaussian Mixture']]: gaussianMixtureHyperParams,
    [ClusteringTypes['LDA']]: ldaHyperParams,
}; 

// export const getMlPipelineHypery


export const getTrainModelKey = (type: ModelLibraryOptions, modelName: string) => 
    type === SparkModelLibraryOptions['Spark'] ?
        'train_model_hyperparams__' + modelName
        :
        'train_model_hyperparams__' + modelName + '__' + type;




export const getTrainHyperParams = (model: string): HyperParamsInfo => ({
    key: getTrainModelKey(SparkModelLibraryOptions['Spark'], model),
    info: mlModelsHyperParams[model as keyof typeof mlModelsHyperParams]?.params || [],
    showTypeColumn: true
});


export const filterFormData = [{
    label: '==',
    value: '=='
}, {
    label: '>',
    value: '>'
}, {
    label: '<',
    value: '<'
}];

