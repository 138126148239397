import { Modal } from "@components/modals";
import { RootState } from "@store/types";
import { Tooltip } from "antd";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoseUnsavedFormChangesComponent } from "../modals/lose-unsaved-form-changes";
import { NoteEditor } from "./NoteEditor";
import Styles from "./styles.module.scss";
import {
	closeActiveComponentNotes,
	saveCurrentWorkflowTabState,
	setCurrentWorkflowStateToUnsaved,
	toggleModal,
} from "@store/workflow";
import { setActiveComponentPropsState } from "@store/workflow";
import { WorkflowCanvas } from "@components/workflow-canvas";
import { DsNodeModel } from "@components/workflow-canvas/node/ds_nodemodel";
import { useGetActiveTabInfo } from "@utils/get-active-tab-info";
import { WorkflowCanvasTabInfo } from "@store/canvas";
import { iconList } from "../enums";
import { CustomComponent } from '../assets/icons';

export const ComponentNote = () => {
	const dispatch = useDispatch();
	const { activeComponentInfo } = useSelector(
		(store: RootState) => store.WorkflowReducer
	);
	const activeTabInfo = useGetActiveTabInfo("workflowEditor") as
		| WorkflowCanvasTabInfo
		| undefined;

	const [isEditing, setIsEditing] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [actionEnabled, setActionEnabled] = useState(false);

	const [notes, setNotes] = useState("");
	const [ModalIcon, setModalIcon] = useState<(() => JSX.Element) | null>(
		null
	);
	const [modalTitle, setModalTitle] = useState("");

	const activeNode = useMemo(() => {
		const node = activeComponentInfo
			? (WorkflowCanvas.getNode(activeComponentInfo.id) as DsNodeModel)
			: null;
		if (node) {
			setNotes(node.getOptions().notes);
			const Icon =
				// @ts-ignore
				iconList[
					node.getOptions().actualTitle ||
						(node.getOptions().title as string)
				];
			setModalIcon(Icon || CustomComponent);
			setModalTitle(node.getOptions().title);
		}
		return node;
	}, [activeComponentInfo, activeTabInfo]);

	const onSaveNote = () => {
		activeNode?.setNotes(notes ?? "");
		dispatch(setCurrentWorkflowStateToUnsaved());
		dispatch(saveCurrentWorkflowTabState());
		stopEditing();
	};

	const onNoteChange = (note: string) => {
		setNotes(note);
		actionEnabled ||
			dispatch(
				setActiveComponentPropsState({ isNotesSaved: false }) as any
			);
		setActionEnabled(true);
	};

	const showUnsavedChangesModal = useSelector(
		(store: RootState) =>
			store.WorkflowReducer.showModal.loseUnsavedComponentNotesChanges
	);

	const startEditing = () => setIsEditing(true);
	const stopEditing = () => {
		setIsModalOpen(false);
		dispatch(setActiveComponentPropsState({ isNotesSaved: true }));
		setIsEditing(false);
		setActionEnabled(false);
		closeUnsavedChangesModal();
	};

	const closeUnsavedChangesModal = () => {
		dispatch(toggleModal("loseUnsavedComponentNotesChanges", false));
	};

	const onDiscardAndContinue = () => {
		setIsModalOpen(false);
		dispatch(setActiveComponentPropsState({ isNotesSaved: true }) as any);
		closeUnsavedChangesModal();
		stopEditing();
		activeNode?.setSelected(false);
	};

	const onSaveAndContinue = () => {
		onSaveNote();
		activeNode?.setSelected(false);
	};

	const onDiscard = () => {
		dispatch(closeActiveComponentNotes());
	};

	return (
		<div className="properties__fields__container">
			{/* 'properties__fields__container' class is required to prevent component delete on delete button click(MacOS) 
				- refer: src/components/workflow-canvas/custom-actions/delete-action.ts */}
			<div className={Styles.container}>
				<div className={Styles.header}>
					<label className={Styles.title}>Component Note</label>
					<div className={Styles.actions}>
						{isEditing || (
							<button className={Styles.action}>
								<Tooltip
									key={"notes-edit"}
									title={"Edit Note"}
									placement="bottom"
									overlayClassName="tab__toolTip"
								>
									<img
										onClick={startEditing}
										width="20px"
										src="/icons/workflow/notes/edit-notes.svg"
										alt="Edit Notes Button"
									/>
								</Tooltip>
							</button>
						)}
						<button
							className={Styles.action}
							onClick={() => setIsModalOpen(true)}
						>
							<Tooltip
								key={"popout-edit"}
								title={"Expand Editor"}
								placement="bottom"
								overlayClassName="tab__toolTip"
							>
								<img
									width="20px"
									src="/icons/workflow/notes/popout.svg"
									alt="Note Modal Button"
								/>
							</Tooltip>
						</button>
					</div>
				</div>
				<div className={Styles.content}>
					{isEditing ? (
						<NoteEditor
							note={notes}
							onSave={onSaveNote}
							onChange={onNoteChange}
							onDiscard={onDiscard}
							actionEnabled={actionEnabled}
							isVisible={isEditing}
						/>
					) : (
						<p className={Styles.noteText} onClick={startEditing}>
							{notes || (
								<span>
									<img
										onClick={startEditing}
										width="16px"
										src="/icons/workflow/notes/edit-notes.svg"
										alt="Edit Notes Button"
									/>{" "}
									Click to add...
								</span>
							)}
						</p>
					)}
				</div>
			</div>

			<Modal
				title={<span className={Styles.modalTitle}>{modalTitle}</span>}
				image={
					ModalIcon ? ModalIcon : "/icons/notification/workflow.svg"
				}
				subtitle={<span className={Styles.modalSubtitle}>NOTES</span>}
				className={Styles.modal}
				isOpen={isModalOpen}
				toggleClose={() => setIsModalOpen(false)}
				showCloseMark
			>
				<div className="properties__fields__container">
					<NoteEditor
						onChange={onNoteChange}
						actionEnabled={actionEnabled}
						note={notes}
						onDiscard={onDiscard}
						onSave={onSaveNote}
						isVisible={isModalOpen}
					/>
				</div>
			</Modal>

			<Modal
				isOpen={showUnsavedChangesModal}
				toggleClose={closeUnsavedChangesModal}
				title=""
				className="deleteWorkflowModal loseUnsavedFormChangesModal"
				showCloseMark
			>
				<LoseUnsavedFormChangesComponent
					onCancel={closeUnsavedChangesModal}
					onSaveAndContinue={onSaveAndContinue}
					onDiscardChanges={onDiscardAndContinue}
					title="Are you sure you want to discard the unsaved component note?"
				/>
			</Modal>
		</div>
	);
};
