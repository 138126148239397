export const GET_CLUSTERS = 'GET_CLUSTERS';
export const UPDATE_CLUSTER_STATUS = 'UPDATE_CLUSTER_STATUS';
export const UPDATE_CLUSTER_DEFAULT = 'SET_CLUSTER_AS_DEFAULT';
export const CREATED_BY_ME = 'CREATED_BY_ME';
export const DELETE_CLUSTER = 'DELETE_CLUSTER';
export const ADD_CLUSTER = 'ADD_CLUSTER';
export const GET_NODE_TYPE_LIST = 'GET_NODE_TYPE_LIST';
export const GET_CLUSTER_MODE = 'GET_CLUSTER_MODE';
export const GET_ACCESS_NODE = 'GET_ACCESS_NODE';
export const GET_USERS_LIST = 'GET_USERS_LIST';
export const GET_DATABRICKS_VERSION_LIST = 'GET_DATABRICKS_VERSION_LIST';
export const SET_WORKSPACE_LIST  = 'SET_WORKSPACE_LIST';
export const SET_ROUTE_OPTIMIZATION_LIST = 'SET_ROUTE_OPTIMIZATION_LIST';
