import { HelpActionTypes, HelpReducerState, SET_ACTIVE_HELP_DOC, SET_DEMO_WORKFLOWS_HELP_DOC_LIST, SET_DOCS_LIST, TOGGLE_HELP_BULB_ANIMATION, TOGGLE_DOC_SPINNER, TOGGLE_SHOW_HELP } from './types';


const initialState: HelpReducerState = {
    docsList: [],
    activeHelpDoc: null,
    showDocSpinner: false,
    toggleHelpTab: false,
    helpDemoWorkflowsInfo: { headings: [], data: '' },
    showHelpBulbAnimation: false
};

const _refVarNames: Record< string, keyof HelpReducerState> = {
    [SET_DOCS_LIST]: 'docsList', 
    [SET_ACTIVE_HELP_DOC]: 'activeHelpDoc',
    [TOGGLE_DOC_SPINNER]: 'showDocSpinner',
    [TOGGLE_SHOW_HELP]: 'toggleHelpTab',
    [SET_DEMO_WORKFLOWS_HELP_DOC_LIST]: 'helpDemoWorkflowsInfo',
    [TOGGLE_HELP_BULB_ANIMATION]: 'showHelpBulbAnimation'
};


export const HelpReducer = (state = initialState, action: HelpActionTypes): HelpReducerState => {
    const actionVarName = _refVarNames[action.type];
    switch(action.type){
        case TOGGLE_SHOW_HELP:
            return { ...state, [actionVarName]: !state[actionVarName] };
        case TOGGLE_DOC_SPINNER:
        case SET_DOCS_LIST:
        case SET_ACTIVE_HELP_DOC:
        case SET_DEMO_WORKFLOWS_HELP_DOC_LIST:
        case TOGGLE_HELP_BULB_ANIMATION:
            return { ...state, [_refVarNames[action.type]]: action.payload };
        default:
            return state;
    }
};
