import styled from "styled-components";

export const Breadcrumb = styled.button`
	font-weight: bold;
	transition: 0.3s;
	border-radius: 4px;
	padding: 4px;
	margin-right: 16px;

	&:hover {
		background-color: #666a8d;
	}

	&::after {
		position: absolute;
		content: ">";
		padding-left: 8px;
	}

	&:last-child {
		::after {
			content: "";
		}
	}
`;