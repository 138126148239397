import { StorageConnectionData } from '@pages/file-browser/types';
import { ConnectionInfo } from '../../api/data-source-handler';
import { ResetReducerState } from '../types';

export const TOGGLE_DATASOURCES_MODAL = 'TOGGLE_DATASOURCES_MODAL';
export const SET_ACTION_TYPE = 'SET_ACTION_TYPE';
export const TOGGLE_COLUMN_MANAGEMENT = 'TOGGLE_COLUMN_MANAGEMENT';
export const TOGGLE_CONNECTIONS_TREEVIEW = 'TOGGLE_CONNECTIONS_TREEVIEW';
export const TOGGLE_ADMINISTRATOR_TREEVIEW = 'TOGGLE_ADMINISTRATOR_TREEVIEW';
export const SET_ALL_CONNECTIONS_INFO = 'SET_ALL_CONNECTIONS_INFO';
export const SET_FILE_BROWSER_CONNECTION = 'SET_FILE_BROWSER_CONNECTION';

export type ToggleDataBrowserModalType = {
    type: typeof TOGGLE_DATASOURCES_MODAL;
    payload: Record<keyof NewDataSourceReducerState['showModal'], boolean>;
}

export type ToggleColumnManagementType = {
    type: typeof TOGGLE_COLUMN_MANAGEMENT;
    payload: boolean;
}

export type NoPayloadType<T extends string> = { type: T }

export type SetAllConnectionsInfo = {
    type: typeof SET_ALL_CONNECTIONS_INFO;
    payload: ConnectionInfo[];
}

export type SetFileBrowserConnections = {
    type: typeof SET_FILE_BROWSER_CONNECTION;
    payload: StorageConnectionData[];
}

export type setActionsType = {
    type: typeof SET_ACTION_TYPE;
    payload: string;
}

export type ActionTypes = 
    | ToggleDataBrowserModalType 
    | ToggleColumnManagementType
    | NoPayloadType<typeof TOGGLE_CONNECTIONS_TREEVIEW>
    | SetAllConnectionsInfo
    | NoPayloadType<typeof TOGGLE_ADMINISTRATOR_TREEVIEW>
    | ResetReducerState
    | SetFileBrowserConnections
    | setActionsType


export type NewDataSourceReducerState = {   
    showModal: { 
        newEditConnection: boolean;
        deleteConnection: boolean;
        queryEditorBuilder: boolean;
        newPiRequest: boolean;
        requestLogs: boolean;
    };
    isColumnManagementMinimized: boolean;
    showConnectionsTreeview: boolean;
    showAdministratorTreeView: boolean;
    allConnectionsInfo: ConnectionInfo[];
    fileBrowserConnectionList:StorageConnectionData[];
    actionType: string
}