import React from 'react';
import { Table, TableRowProps, TableStyles } from '../../../components/table';
import cs from 'classnames';
import styles from './../styles.module.scss';
import { AdminUserInfo, SelectedUserInfo } from '../types';
import { getUserStatusStyle } from '../utils';
import classNames from 'classnames';

export type UsersTableProps = {
    // tableWidth: number;
    usersList: AdminUserInfo[];
    onUserSelection: (arg0: AdminUserInfo) => any;
    selectedUserInfo: SelectedUserInfo;
};

export const UsersTable: React.FC<UsersTableProps> = ({ usersList, selectedUserInfo, onUserSelection }) => {

    const handleRenderRow = ({ className, ...props }: TableRowProps) => {
        return Table.defaultTableRowRenderer({
            className: classNames(className, { [styles['selectedTableRowState']]: selectedUserInfo?.id === props.rowData.id }),
            ...props
        });
    };


    return (
        <div className={styles['usersTable']}>
            <Table.AutoSizer>
                {({ height, width }) => (
                    <Table
                        height={height}
                        rowHeight={42}
                        rowCount={usersList.length}
                        width={width}
                        headerHeight={42}
                        rowGetter={({ index }) => usersList[index]}
                        onRowClick={({ rowData }) => onUserSelection(rowData)}
                        rowRenderer={handleRenderRow}
                    >
                        <Table.Column
                            width={width * 0.25}
                            dataKey="firstName"
                            label="First Name"
                        />
                        <Table.Column
                            width={width * 0.25}
                            dataKey="lastName"
                            label="Last Name"
                        />
                        <Table.Column
                            width={width * 0.3}
                            dataKey="email"
                            label="Email"
                        />
                        <Table.Column
                            width={width * 0.2}
                            dataKey="status"
                            label="Status"
                            cellRenderer={({ cellData, rowData }) => {
                                return (
                                    <span
                                        className={getUserStatusStyle(rowData.enabled)}
                                    >
                                        {cellData}
                                    </span>
                                );
                            }}
                        />
                        {/* <Table.Column width={95} dataKey="" label="Actions" /> */}
                    </Table>
                )}
            </Table.AutoSizer>
        </div>
    );
};
