import { BaseFieldType } from "@components/formcreators/types";
import { isEqual, omit } from "lodash";
import { MultiplePortDetails, RiTreeComponentDetails } from "types/component";

type FieldMapping = Record<string, BaseFieldType & { position: number }>;

export const getFieldMappingFromFormFields = (formFields: BaseFieldType[]) =>
	formFields.reduce((acc, field, position) => {
		if (!["num_ip_ports", "num_op_ports"].includes(field.key)) {
			acc[field.key] = {
				...omit(field, ["value", "isFieldHidden"]),
				position,
			};
		}
		return acc;
	}, {} as FieldMapping);

export const validateFormFields = (
	currentComponentFields: BaseFieldType[],
	newComponentFields: BaseFieldType[]
) => {
	const currentComponentFieldsMapping: Record<
		string,
		BaseFieldType
	> = getFieldMappingFromFormFields(currentComponentFields);
	const newComponentFieldsMapping: Record<
		string,
		BaseFieldType
	> = getFieldMappingFromFormFields(newComponentFields);

	// deep compares the keys and values of every field in the formFields
	return !isEqual(currentComponentFieldsMapping, newComponentFieldsMapping);
};

export const validatePortManagement = (
	currentComponentPorts: MultiplePortDetails,
	newComponentPorts: MultiplePortDetails
) => {
	if (
		!isEqual(currentComponentPorts?.fields, newComponentPorts?.fields)
	) {
		return validateFormFields(
			currentComponentPorts?.fields || [],
			newComponentPorts?.fields || []
		);
	}
	return !isEqual(currentComponentPorts, newComponentPorts);
};

const validateComponentPropertiesForRefresh = (
	currentComponent: RiTreeComponentDetails,
	newComponent: RiTreeComponentDetails
) => {
	try {
		if (
			currentComponent.componentInfo[0]?.defaultValue !==
				newComponent.componentInfo[0]?.defaultValue ||
			currentComponent?.fnName !== newComponent?.fnName ||
			currentComponent?.append !== newComponent?.append ||
			currentComponent?.input_df_to_key !==
				newComponent?.input_df_to_key ||
			currentComponent?.previewAllowed !== newComponent?.previewAllowed ||
			currentComponent?.users !== newComponent?.users ||
			currentComponent?.customComponentPayload !== newComponent?.customComponentPayload
		) {
			// description/function name/append/input_df_to_key/preview allowed/users is updated/customComponentPayload
			return true;
		} else if (
			!isEqual(
				currentComponent.portManagement,
				newComponent.portManagement
			)
		) {
			// To check if inputPorts or outputPorts are updated
			return (
				validatePortManagement(
					currentComponent.portManagement.inputPorts,
					newComponent.portManagement.inputPorts
				) ||
				validatePortManagement(
					currentComponent.portManagement.outputPorts,
					newComponent.portManagement.outputPorts
				)
			);
		} else {
			return validateFormFields(
				currentComponent.formData,
				newComponent.formData
			);
		}
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error(
			"Error in validating component properties for refresh",
			e
		);
	}

	return false;
};

export default validateComponentPropertiesForRefresh;
