import React, { useState, useEffect, useMemo, useRef, useLayoutEffect } from 'react';
import classNames from 'classnames';
import { InPageSpinner } from '../../../components/spinners/in-page-spinner';
import { SavedWorkflowInfo, DetailedWorkflowInfo, WorkflowHandler, DirectoryInfo, WorkflowSearchQuery, CreateWorkflow, RepositoriesList } from '../../../api/workflow-handler';
import { ShowWhenTrue } from '../../../helpers';
import Paginate, { PaginateRef } from '../../../components/paginate';
import { capitalize, cloneDeep, omit } from 'lodash';
import { FavoriteIcon, GoBackTiltedArrow, RightChevron, SortByIcon, WorkflowProjectIcon } from '../assets/icons';
import { useDebounce, usePrevious } from 'rooks';
import { useDispatch, useSelector } from 'react-redux';
import {
    retrieveWorkflows, setActiveSelectionType, toggleModal, changeWorkflowsSelection, setSingleItemInfoForModals,
    ModalTypes, setWorkflowsListInStore, RetrieveWorkflowsPayload, setModalInfoTypeInWorkflowsPage, getWorkflowDirectories,
    setWorkflowTabSelection, cloneWorkflow, WorkflowsSelectionStatus, updateDirectoryIdOfOpenWorkflows, getDependentWorkflowDetailsAndPayload,
    saveActiveWorkflow,
    handleOpenWorkflowUsingWorkflowId,
    setCurrentWorkflowStateToUnsaved
} from '../../../store/workflow';
import { hideSpinner, showSpinner, SpinnerTypes } from '../../../store/common';
import { RootState } from '../../../store/types';
// import { _selectoptionType } from '../../../components/form/select-field';
import { InlineInputField } from '../../../components/form';
import { successAlert, infoAlert, errorAlert } from '../../../components/toastify/notify-toast';
import { encryptAndExport } from '../utils';
import { getActiveWorkflowType, useGetNumberOfItemsPerPage } from '../../../utils/';
import isEmpty from 'lodash/isEmpty';
import { TooltipTop } from '../../../components/tooltips';
import { NewWorkflowModal } from '../modals/new-workflow';
import { DeleteWorkflowModal } from '../modals/delete-profile-workflow-canvas-items';
import { RenameWorkflowModal } from '../modals/rename-workflow';
import { openANewWorkflowEditorTab, openANewWorkflowEditorTabUsingWorkflowInfo, OpenTabsType, WorkflowCanvasTabInfo } from '../../../store/canvas';
import { Dropdown, Tabs, Tooltip } from 'antd';
import { InnerWorkflowTabs, sortByOptions } from '../enums';
import { Menu, Dropdown as AntDropdown } from 'antd';
import { ContextMenuComponent } from '../../../components/context-menu';
import { ShareWorkflowModal } from '../modals/share-workflow';
import { setUserDirectories } from '../../../store/account';
import { ExecutionEnvModes } from '../../../constants/enums';
import { WorkflowSerializedObjData } from '@components/workflow-canvas';
import { Env } from '@constants/settings';
import { GoGitMerge } from 'react-icons/go';
import { MdArrowForwardIos, MdOutlineOpenInNew } from 'react-icons/md';
import { BsPlusLg } from 'react-icons/bs';
import NewDirectoryModal from '../modals/new-directory';
import NewGitWorkflow from '../modals/new-git-workflow';
import ErrorHandlingModal from '../modals/error-handleGit';
import { RiGitRepositoryLine } from 'react-icons/ri';
import { Text } from '@components/ui/Text';
const { TabPane } = Tabs;
const { SubMenu } = Menu;

export type selectedWorkflowInfo = { id: number; name: string }
interface Props {
    hideWorkflowTreeview: boolean;
    readOnlyMode?: boolean;
    handleClickWorkflow: (arg0: RetrieveWorkflowsPayload['results'][0]) => any;
    activeTabId: string | number;
    openTabs: OpenTabsType;
}

type NewDirectoryInfo = {
    editable: boolean; directoryInfo: DirectoryInfo | null; renameValue: string; showError: boolean;
}

export const WorkflowTreeview: React.FC<Props> = ({ hideWorkflowTreeview, handleClickWorkflow, activeTabId, openTabs, readOnlyMode = false }) => {
    const dispatch = useDispatch();
    const { activeUserInfo: { id: userId } } = useSelector((store: RootState) => store.AccountReducer);
    const [workflowSearchString, setWorkflowSearchString] = useState('');
    // const [workflowSelectedUserID, setWorkflowSelectedUserID] = useState<string>(userId);
    const { inPageSpinner: showWorkflowSpinner, initialPageLoadSpinner: showInitialPageLoadSpinner } = useSelector((store: RootState) => store.CommonReducer);
    const {
        workflows, selectionType, workflowsSelectedState,
        workflowTabSelection:
        { selectedDirectory, activeInnerTab, starredPageNum, workflowsPageNum, activeSortState, sharredPageNum }
    } = useSelector((store: RootState) => store.WorkflowReducer);
    const showDeleteModal = useSelector((store: RootState) => store.WorkflowReducer.showModal.deleteWorkflow);
    const showRenameModal = useSelector((store: RootState) => store.WorkflowReducer.showModal.renameWorkflow);
    const { activeExecutionEnv } = useSelector((store: RootState) => store.CommonReducer);
    const directories = useSelector((store: RootState) => store.AccountReducer.userDirectories[store.CommonReducer.activeExecutionEnv].directories);
    const numberOfWorkflowsPerPage = useGetNumberOfItemsPerPage();
    const paginateRef = useRef<PaginateRef>(null);
    const [activeHoverWorkflowId, setActiveHoverWorkflowId] = useState<number | null>(null);
    const [newDirectoryInfo, setNewDirectoryInfo] = useState<NewDirectoryInfo>
        ({ editable: false, directoryInfo: null, renameValue: '', showError: false });

    const [repositories, setRepositories] = useState<RepositoriesList[]>([]);
    const [hoveredGitRepoId, setHoveredGitRepoId] = useState<number | null>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRepo, setSelectedRepo] = useState<{ name: string, id: number } | null>(null);
    const [gitactiveInnerTab, setGitActiveInnerTab] = useState('1');
    const [isGitDirModalVisible, setIsGitDirModalVisible] = useState(false);
    const [selectedGitDirectory, setSelectedGitDirectory] = useState<{ id: number; name: string, userId?: string } | null>(null);
    const [gitWorkflows, setGitWorkflows] = useState<any[]>([]);
    const [activeRepoId, setActiveRepoId] = useState<number | null>(null);
    const [loadingDirectories, setLoadingDirectories] = useState(false);
    const [isRepositoriesLoading, setisRepositoriesLoading] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorOption, setErrorOption] = useState('');
    const [triggerElement, setTriggerElement] = useState(null);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState<{ left: number; top: number } | null>(null);
    const [typeGitDirectory, setTypeGitDirectory] = useState('');
    const [activeWorkflowId, setActiveWorkflowId] = useState<number | null>(null);

     

    const workflowSearchStringRef = useRef(workflowSearchString)

    useLayoutEffect(() => {
        workflowSearchStringRef.current = workflowSearchString
    }, [workflowSearchString])

     
    let currentActivePageNumberForWorkflows:any;

    if (activeInnerTab === InnerWorkflowTabs.STARRED) {
        currentActivePageNumberForWorkflows = starredPageNum;
    } else if (activeInnerTab === InnerWorkflowTabs.SHARED) {
        currentActivePageNumberForWorkflows = sharredPageNum;
    } else {
        currentActivePageNumberForWorkflows = workflowsPageNum;
    }

    const showDirectories = !selectedDirectory && workflowSearchString === '';

    const handleGetWorkflowDirectories = ({ sort = activeSortState.directories }) => {
        dispatch(getWorkflowDirectories(sort));
    };

    const retrieveUserWorkflows = ({ 
        page = currentActivePageNumberForWorkflows, 
        size = numberOfWorkflowsPerPage, 
        workflowSearch = workflowSearchString, 
        isStarred = false, 
        notes = '',
        directory_id = selectedDirectory?.id,
        sort = activeSortState.workflows
    }: Partial<WorkflowSearchQuery>) => {
        dispatch(showSpinner(SpinnerTypes.inPageSpinner));
        // if(activeInnerTab === InnerWorkflowTabs.STARRED) {
        //     isStarred = true;
        //     directory_id = undefined;
        // }
        if (workflowSearch) {
            directory_id = undefined;
        }

        dispatch(retrieveWorkflows({ page, size, workflowSearch, isStarred, notes, directory_id, sort }));
    }

    // const gitSync = () => {
    //     setGitLoadingState(true);
    //     WorkflowHandler.GitSync(activeExecutionEnv, ()=>{
    //         successAlert('Successfully synced with GIT');
    //         setGitLoadingState(false);
    //     }, (e: any) => {
    //         setGitLoadingState(false);
    //         infoAlert(e.data);
    //     });
    // };

    // useEffect(() => {
    //     if (!hideWorkflowTreeview && numberOfWorkflowsPerPage) retrieveUserWorkflows({});
    // }, [numberOfWorkflowsPerPage, hideWorkflowTreeview, userId]);

    const prevActiveExecutionEnv = usePrevious(activeExecutionEnv);

    useEffect(() => {
        if (!hideWorkflowTreeview) {
            if (prevActiveExecutionEnv !== activeExecutionEnv) {
                setWorkflowSearchString('');
            }
            if (showDirectories || (prevActiveExecutionEnv !== activeExecutionEnv)) {
                handleGetWorkflowDirectories({});
            }
            else if (!showInitialPageLoadSpinner) {
                // this is done to make sure directories are retrieved after load-data call is made
                retrieveUserWorkflows({});
            }
        }
        // if(workflowSelectedUserID === '') {
        //     setWorkflowSelectedUserID(userId);
        // }

    }, [hideWorkflowTreeview, activeExecutionEnv, userId, showInitialPageLoadSpinner]);

    // useEffect(() => {
    //     setWorkflowSelectedUserID(userId);
    //     if(userId) {
    //         UserHandler.GetAllUsers(handleUsersResponse);
    //     }
    // }, [userId]);


    const changeWorkflowsPageOnActiveTabChange = (pageNo: number) => {
        if (paginateRef.current?.currentPage !== pageNo) {
            return true;
        } return false;
    };

    // const changeUserOnActiveTabChange = (createdBy: string) => {
    //     if(createdBy !== workflowSelectedUserID) {
    //         setWorkflowSelectedUserID(createdBy);
    //         return true;
    //     } return false;
    // };

    const handleUpdateSelectedDirectoryUsingId = (directories: DirectoryInfo[], directoryId: number) => {
        const newSelectedDirectory = directories.find(pr => pr.id === directoryId);
        newSelectedDirectory && dispatch(setWorkflowTabSelection('selectedDirectory', newSelectedDirectory));
    };

    const changeSelectedDirectoryOnActiveTabChange = (directoryId: number) => {
        if (directoryId !== selectedDirectory?.id) {
            handleUpdateSelectedDirectoryUsingId(directories, directoryId);
            return true;
        } return false;
    };

    // const checkIfCurrentWorkflowsBelongToActiveWorkflowUser = (workflowUserId: number) => 
    //     !isEmpty(workflows.results) ? workflows.results[0].createdBy === workflowUserId: true;


    const checkParamsOnActiveTabChange = (activeTabId: string | number, numberOfWorkflowsPerPage: number) => {
        const activeTab = openTabs.get(activeTabId);
        if (activeTab?.info) {
            const { workflowPositionInList, createdBy, directoryId } = activeTab.info;
            // const userChangeNeeded = changeUserOnActiveTabChange(createdBy);
            const workflowPageNo = Math.ceil(workflowPositionInList / numberOfWorkflowsPerPage);
            const pageChangeNeeded = changeWorkflowsPageOnActiveTabChange(workflowPageNo);
            const isDirectoryChangeNeeded = changeSelectedDirectoryOnActiveTabChange(directoryId);
            if ((pageChangeNeeded || isDirectoryChangeNeeded) && (numberOfWorkflowsPerPage !== 0)) {

                // setTimeout(() => retrieveUserWorkflows({ page: pageNo, user_id: createdBy }), 1);
                retrieveUserWorkflows({ page: workflowPageNo, user_id: createdBy, directory_id: activeTab.info.directoryId });
                if (pageChangeNeeded) {
                    // changePage is not used as selectedUserID is not updated when retrieveworkflows call is made
                    paginateRef.current?.setCurrentPage(workflowPageNo);
                }
            }
        }

    };

    useEffect(() => {
        // do not refresh workflow list when there's a search string
        if (!workflowSearchStringRef.current) {
            checkParamsOnActiveTabChange(activeTabId, numberOfWorkflowsPerPage);
        }
    }, [activeTabId, numberOfWorkflowsPerPage]);

    function addIdsToSelectedWorkflowState() {
        const _selectedState: WorkflowsSelectionStatus = {};
        workflows.results.forEach(workflow => _selectedState[workflow.id] = { status: false, name: workflow.projectName });
        dispatch(changeWorkflowsSelection(_selectedState));
    }

    const retrieveWorkflowsOnActionSuccess = () => {
        // TO RETAIN THE SAME PAGE
        // WHEN A WORKFLOW IS DELETED FROM A PAGE OTHER THAN 1, 
        // SINCE WORKFLOW COUNT CHANGES, PAGE NUMBERS ARE RESET TO STARTPAGE
        // USING THE ACTUAL PAGE NUMBER IN WORKFLOW INFO, PAGENUMBER IS SET AGAIN
        const page = paginateRef.current?.currentPage;
        if (page) {
            retrieveUserWorkflows({ page });
            const intervalId = setInterval(() => {
                if (paginateRef.current?.currentPage !== page) {
                    paginateRef.current?.setCurrentPage(page);
                    clearInterval(intervalId);
                }
            }, 500);
        }

    };


    useEffect(() => {
        addIdsToSelectedWorkflowState();
    }, [workflows]);


    const searchWorkflows = useDebounce((searchString: string) => {
        retrieveUserWorkflows({ workflowSearch: searchString, page: 1 });
    }, 500);

    const handleWorkflowSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchString = e.target.value;
        setWorkflowSearchString(searchString);
        searchWorkflows(searchString);
    };

    const handleSingleWorkflowSelection = (workflow: SavedWorkflowInfo) => {
        dispatch(setActiveSelectionType('single'));
        dispatch(setSingleItemInfoForModals({ id: workflow.id, name: workflow.projectName, version: workflow.version }));
    };

    const handleMultipleWorkflowSelection = (id: number) => {
        dispatch(setActiveSelectionType('multiple'));
        dispatch(changeWorkflowsSelection({ ...workflowsSelectedState, [id]: { ...workflowsSelectedState[id], status: !workflowsSelectedState[id].status } }));
    };

    const handlePageChange = (targetPage: number) => {
        if(targetPage !== paginateRef.current?.currentPage) {
            retrieveUserWorkflows({ page: targetPage });
        }
    };

    function handleToggleModal(name: ModalTypes, action: boolean) {
        dispatch(toggleModal(name, action));
    }

    const handleCloneWorkflowSuccess = (createdBy: string) => {
        if (createdBy === userId) {
            // RETRIEVE WORKFLOWS IF USER CLONES THEIR WORKFLOWS
            retrieveUserWorkflows({ page: paginateRef.current?.currentPage });
        }
    };

    const handleGetWorkflowInfoSuccess = (response: DetailedWorkflowInfo) => {
        dispatch(
            cloneWorkflow(
                { id: response.data.id, name: response.data.projectName, config: response.data.config },
                response.data.details,
                handleCloneWorkflowSuccess.bind(null, response.data.createdBy)
            ));
    };

    const cloneWorkflowClick = (workflowName: string, workflowId: number) => {
        workflowName && infoAlert('Cloning ' + workflowName);
        WorkflowHandler.GetWorkflowInfo(workflowId, handleGetWorkflowInfoSuccess);
    };



    const handleWorkflowInnerTabChange = (tab: string) => {
        if (tab !== activeInnerTab) {
            dispatch(setWorkflowTabSelection('activeInnerTab', tab as InnerWorkflowTabs));
            if (tab === InnerWorkflowTabs.STARRED)
                retrieveUserWorkflows({ isStarred: true, directory_id: undefined });
            else {
                if (selectedDirectory)
                    retrieveUserWorkflows({ directory_id: selectedDirectory?.id });
                else
                    handleGetWorkflowDirectories({});
            }
        }

    };


    // const changeSelectedUserId = (option: _selectoptionType) => {
    //     setWorkflowSelectedUserID(option.value);
    //     setWorkflowSearchString('');
    //     if(activeInnerTab === InnerWorkflowTabs.WORKFLOWS)
    //         dispatch(setWorkflowTabSelection('selectedDirectory', null));
    //     else
    //         retrieveUserWorkflows({ page: 1, user_id: option.value, workflowSearch: '', isStarred: true });

    //     // handleGetWorkflowDirectories({ userId: option.value });
    // };

    const showNewWorkflowModal = () => {
        dispatch(toggleModal('newWorkflow', true));
    };

    const getMultipleSelectedWorkflows = () => {
        const selectedWorkflowIds: number[] = [];
        Object.entries(workflowsSelectedState).forEach(([id, workflow]) => {
            if (workflow.status) {
                selectedWorkflowIds.push(parseInt(id));
            }
        });
        return selectedWorkflowIds;
    };

    const copyMultipleWorkflows = () => {
        const selectedWorkflowIds = getMultipleSelectedWorkflows();
        selectedWorkflowIds.forEach(_id => {
            const selectedWorkflowInfo = workflows.results.find(__workflow => __workflow.id === _id);
            selectedWorkflowInfo && cloneWorkflowClick(selectedWorkflowInfo.projectName, _id);
        });
    };

    const starWorkflows = (workflowIds: number[]) => {
        const selectedWorkflowIds: Record<number, boolean> = {};
        workflowIds.forEach(__id => selectedWorkflowIds[__id] = true);
        WorkflowHandler.StarWorkflows(workflowIds)
            .then(() => {
                // retrieveUserWorkflows({});
                const __workflowsList = { ...workflows };
                __workflowsList.results = __workflowsList.results.map(_workflow => {
                    if (selectedWorkflowIds[_workflow.id]) _workflow.isStarred = !_workflow.isStarred;
                    return _workflow;
                });
                dispatch(setWorkflowsListInStore(__workflowsList));
            });
    };

    const exportWorkflow = async (workflow: SavedWorkflowInfo) => {
        infoAlert('Exporting ' + workflow.projectName);
        try {
            const workflowInfo = await getDependentWorkflowDetailsAndPayload(workflow.id) as WorkflowSerializedObjData;
            // console.log("🚀 ~ file: workflows.tsx:372 ~ handleGetWorkflowInfoSuccessForExport ~ workflowInfo", workflowInfo)
            const serializedWorkflowInfo = JSON.stringify(workflowInfo);
            encryptAndExport(serializedWorkflowInfo, workflowInfo.workflowMetaData.workflowName, 'Workflow')
                .then(() => {
                    if (activeExecutionEnv === ExecutionEnvModes.Pipelines) {
                        infoAlert('Please ensure that the workflows configured in this DAG are accessible when sharing');
                    }
                });
        } catch {
            errorAlert('Error exporting the workflow');
        }
    };

    const exportMultipleProfiles = () => {
        const workflowIdsList = getMultipleSelectedWorkflows();
        workflowIdsList.forEach(_id => {
            const selectedWorkflowInfo = workflows.results.find(__workflow => __workflow.id === _id);
            selectedWorkflowInfo && exportWorkflow(selectedWorkflowInfo);
        });
    };

    const handleOpenSelectedWorkflows = () => {
        const workflowIdsList = getMultipleSelectedWorkflows();
        workflowIdsList.forEach(_id => {
            const selectedWorkflowInfo = workflows.results.find(__workflow => __workflow.id === _id);
            selectedWorkflowInfo && handleClickWorkflow(selectedWorkflowInfo);
        });
    };

    const handleSetSelectedDirectory = (directoryInfo: DirectoryInfo | null) => {
        dispatch(setWorkflowTabSelection('selectedDirectory', directoryInfo));
        if (!directoryInfo) {
            // when selected project is null
            setTimeout(() => dispatch(setWorkflowTabSelection('workflowsPageNum', 1)), 100);
        }
        if (directoryInfo)
            retrieveUserWorkflows({ directory_id: directoryInfo.id });
    };

    const handleMoveWorkflowsToDirectory = (directoryId: number, directoryName: string, workflowIds: number[]) => {
        // dispatch(showSpinner(SpinnerTypes.inPageSpinner));
        WorkflowHandler.MoveWorkflowsToDirectory(directoryId, workflowIds, () => {
            retrieveUserWorkflows({});
            dispatch(updateDirectoryIdOfOpenWorkflows(directoryId, workflowIds));
            successAlert('Added to ' + directoryName + ' successfully');
        });
    };

    const handleSetNewDirectoryMode = (workflowIds: number[]) => {
        dispatch(showSpinner(SpinnerTypes.inPageSpinner));
        handleSetSelectedDirectory(null);
        setWorkflowSearchString('');
        WorkflowHandler.CreateDirectory(workflowIds, activeExecutionEnv, (r) => {
            const newDirectoryInfo = { ...omit(r.data, 'status'), userId };
            setNewDirectoryInfo({ editable: true, directoryInfo: newDirectoryInfo, renameValue: newDirectoryInfo.name, showError: false });
            dispatch(setUserDirectories(activeExecutionEnv, [newDirectoryInfo, ...directories]));
            dispatch(hideSpinner(SpinnerTypes.inPageSpinner));
            dispatch(updateDirectoryIdOfOpenWorkflows(r.data.id, workflowIds));
        });

    };

    const renderDirectoriesDropdown = (workflowIds: number[], workflowDirectoryId: number | undefined, disabled: boolean) => {
        return (
            <SubMenu
                title={<div className="dropdownSubMenu__title"><span>Move to Directory</span> <span>&#10148;</span></div>}
                disabled={disabled}
                popupOffset={[-3, 0]}
            >
                <Menu.Item
                    onClick={() => handleSetNewDirectoryMode(workflowIds)}
                >
                    New Directory
                </Menu.Item>
                <SubMenu
                    title={<div className="dropdownSubMenu__title"><span>Existing Directory</span> <span>&#10148;</span></div>}
                    disabled={disabled}
                    popupOffset={[-3, 0]}
                    className=""
                    style={{ width: 140 }}
                >
                    {directories.map(dir => (
                        <Menu.Item
                            style={{ paddingRight: 12 }}
                            key={dir.id}
                            disabled={workflowDirectoryId === dir.id}
                            onClick={() => handleMoveWorkflowsToDirectory(dir.id, dir.name, workflowIds)}
                        >
                            {dir.name}
                        </Menu.Item>
                    ))}
                </SubMenu>
            </SubMenu>
        );
    };

    const activeWorkflowType = getActiveWorkflowType(activeExecutionEnv);
    const heading=activeWorkflowType == 'local'?'Workflow':activeWorkflowType;

    const workflowsOptions = useMemo(() => {
        // const multipleSelectionType = selectionType === 'multiple';
        // const selectedWorkflowIdStatus = Object.values(workflowsSelectedState);
        // const isFullySelected = multipleSelectionType && _.isEqual(selectedWorkflowIdStatus.filter(status => status === true), selectedWorkflowIdStatus);
        // const isNotSelected = multipleSelectionType && _.isEqual(selectedWorkflowIdStatus.filter(status => status === false), selectedWorkflowIdStatus);
        // const isPartiallySelected = multipleSelectionType && !isFullySelected && !isNotSelected;

        const isSelected = selectionType === 'multiple' && Object.values(workflowsSelectedState).filter(workflowInfo => !!workflowInfo.status).length > 0;
        // isReadOnly means user doesn't have permission to edit/delete workflows
        const isReadOnly = !isEmpty(workflows.results) ? workflows.results[0].readOnly : false;
        const __activeSortState = showDirectories ? activeSortState.directories : activeSortState.workflows;
        return (
            <div className={classNames('saved__workflows__options', { selectedState: isSelected })}>
                <ShowWhenTrue show={!isSelected}>
                    <div className="workflows__options__toggleAllContainer">
                        <AntDropdown
                            overlay={<Menu
                                className="sortByWorkflows__dropdown"
                            >
                                {Object.entries(sortByOptions).map(([title, sortValue]) => (
                                    <Menu.Item
                                        key={title}
                                        onClick={() => {

                                            if (sortValue && sortValue !== __activeSortState) {
                                                // dispatch(setWorkflowTabSelection('activeInnerTab', tab as InnerWorkflowTabs));
                                                // if(activeInnerTab === InnerWorkflowTabs)
                                                const updatedActiveSortState = cloneDeep(activeSortState);
                                                if (showDirectories) {
                                                    updatedActiveSortState.directories = sortValue;
                                                    handleGetWorkflowDirectories({ sort: sortValue });
                                                    // retrieveUserWorkflows({ page: 1, sort: sortValue });
                                                } else {
                                                    updatedActiveSortState.workflows = sortValue;
                                                    retrieveUserWorkflows({ page: 1, sort: sortValue });
                                                }
                                                dispatch(setWorkflowTabSelection('activeSortState', updatedActiveSortState));
                                            }
                                        }}
                                        className={classNames({ 'activeMenuItem': __activeSortState === sortValue })}
                                    >
                                        {title}
                                    </Menu.Item>
                                ))}
                            </Menu>}
                            trigger={['click']}
                            openClassName="showHoverState"
                            align={{
                                offset: [3, 1]
                            }}
                        >
                            <button
                                className="btn-sm"
                                id="btn_sortByWorkflows"
                            >
                                <SortByIcon />
                                Sort
                            </button>
                        </AntDropdown>
                    </div>
                </ShowWhenTrue>

                <ShowWhenTrue show={!readOnlyMode}>
                    {(isSelected) ?
                        <div className="common__workflow__icons">
                            <TooltipTop
                                title="Clone selected"
                            >
                                <button
                                    onClick={copyMultipleWorkflows}
                                >
                                    <img src="/icons/copy.svg" className="icon__square__hover" alt="" />
                                </button>
                            </TooltipTop>
                            <ShowWhenTrue show={!isReadOnly}>
                                <TooltipTop
                                    title="Export selected"
                                >
                                    <button
                                        onClick={exportMultipleProfiles}
                                    >
                                        <img src="/icons/export.svg" className="icon__square__hover export__icon" alt="" />
                                    </button>
                                </TooltipTop>
                                <TooltipTop
                                    title="Delete selected"
                                >
                                    <button
                                        onClick={() => {
                                            dispatch(setActiveSelectionType('multiple'));
                                            handleToggleModal('deleteWorkflow', true);
                                            dispatch(setModalInfoTypeInWorkflowsPage('workflow'));
                                        }}
                                    >
                                        <img id="worklfowlist_delete" src="/icons/delete.svg" className="icon__square__hover" alt="" />
                                    </button>
                                </TooltipTop>
                            </ShowWhenTrue>
                            <AntDropdown
                                overlay={<Menu
                                    className="workflowItem__contextMenuOptions"
                                >
                                    <Menu.Item
                                        onClick={handleOpenSelectedWorkflows}
                                    >
                                        Open All
                                    </Menu.Item>
                                    <Menu.Item
                                        onClick={() => {
                                            starWorkflows(getMultipleSelectedWorkflows());
                                        }}
                                        disabled={isReadOnly}
                                    >
                                        Add Star
                                    </Menu.Item>
                                    {renderDirectoriesDropdown(getMultipleSelectedWorkflows(), undefined, isReadOnly)}
                                    <Menu.Item
                                        onClick={() => {
                                            dispatch(setActiveSelectionType('multiple'));
                                            handleToggleModal('shareWorkflow', true);
                                            dispatch(setModalInfoTypeInWorkflowsPage('workflow'));
                                        }}
                                    >
                                        Share a copy
                                    </Menu.Item>
                                </Menu>}
                                trigger={['click']}
                                openClassName="showHoverState"
                            >
                                <div
                                    className="dropdown__threedots"
                                >
                                    <img src="/icons/more.svg" alt="" />
                                </div>
                            </AntDropdown>
                        </div>
                        :
                        <>
                            <div className="component_action_wrapper">
                                {/* <TooltipTop title={'Sync with git'}>
                                    <button
                                        className="btn__new__workflow  btn__import"
                                        id="btn_import_workflow"
                                        onClick={function (this: any) {
                                            if(Env.disableGitIntegration){
                                                infoAlert("Sync with git is not available with this subscription!")
                                            }else{
                                                gitSync();
                                            }
                                        }}
                                    >
                                        {
                                            isGitLoading ? 
                                                <InPageSpinner size='XSmall' className='h-24' />  :  (
                                                    <div style={{
                                                        display: 'inline-block',
                                                        border: '1px solid grey', 
                                                        width: 22, 
                                                        height: 22, 
                                                    }}>
                                                        <GoGitMerge />
                                                    </div>

                                            )
                                        }
                                    </button>
                                </TooltipTop> */}
                                <TooltipTop title={'Import directory/' + heading}>
                                    <button
                                        className="btn__new__workflow  btn__import"
                                        id="btn_import_workflow"
                                        onClick={function (this: any) {
                                            dispatch(toggleModal('componentImport', true, retrieveUserWorkflows.bind(this, {})));
                                        }}
                                    >
                                        <div style={{
                                            display: 'inline-block',
                                            border: '1px solid grey',
                                            width: 22,
                                            height: 22,
                                        }}>
                                            <MdOutlineOpenInNew />
                                        </div>
                                    </button>
                                </TooltipTop>
                                <TooltipTop title={'Create a ' + heading}>
                                    <button
                                        id="btn_new_workflow"
                                        className="btn-sm btn-yellow-transparent btn__new__workflow btn__new__action"
                                        onClick={showNewWorkflowModal}
                                        aria-label="New Workflow"
                                        style={{display:'flex'}}
                                    >
                                        <div style={{marginTop:'2px'}}>
                                        <BsPlusLg />
                                        </div>
                                        <span>New</span>
                                    </button>
                                </TooltipTop>

                            </div>
                        </>
                    }
                </ShowWhenTrue>
            </div>);
    }, [workflowsSelectedState, activeSortState, showDirectories, activeWorkflowType]);


    useEffect(()=>{
        if(InnerWorkflowTabs.SHARED==='Remote'){
            setGitActiveInnerTab('1')
        }
    },[InnerWorkflowTabs.SHARED])

    const handleBackButtonClick = () => {
        setSelectedRepo(null);
        setGitActiveInnerTab('1');
        setActiveWorkflowId(null)
        setDropdownVisible(false)
    };


    const handleCreateDirectory = (name: string, id: number) => {
        setHoveredGitRepoId(null);
        if(typeGitDirectory=='Rename'){
        WorkflowHandler.RenameProject(id, name, () => {
            return;
        });
    }else if(typeGitDirectory == 'Create'){
        const data={
            "name": name, 
            "env": activeExecutionEnv, 
            "repoId": id 
        }
        WorkflowHandler.CreateGitDirectory(data, (response) => {
            const repo = { name: name, id: id };
            listDirectories(repo);
            if (response.success) {
                successAlert('Directory created successfully!');
            } else {
                errorAlert('Failed to create directory.');
            }
        }, (error)=>{
            console.error(error)
            errorAlert('Failed to create directory.');
        });
    }
        setIsModalVisible(false);
    };

    const handleCreateGitWorflow = (name: string, id: number) => {
        const data: CreateWorkflow = { 
            projectName: name, 
            payload: '', 
            jobtype: 'DT', 
            status: true, 
            details: '', 
            env: activeExecutionEnv,
            config: '[]',
            directoryId: id
        };

        WorkflowHandler.CreateWorkflow(data, (res)=>{
            if (res.success) {
                successAlert("Workflow created successfully")
                const { id, projectName, version, readOnly, executable, zeppelinNotebookId, directoryId, env, created, updated } = res.data;
                 const newWorkflowDetails: WorkflowCanvasTabInfo = { id, name: projectName, details: '', version, saved: false, isStarred: false, notes: '', createdBy: userId, isReadOnly: readOnly, isExecutable: executable, hasVariables: false, activeComponentInfo: null, componentCounter: 0, zeppelinNotebookId, showCodeEditor: false, showHelpBulbAnimation: false, helpDocRef: null, workflowPositionInList: 1, directoryId, env, scheduleInfoForDag: null, activeClusterInfoForZeppelin: null, zepplinData: [], isPreviewSessionCreationInProgress: false, created, updated, config: [] };
                 dispatch(openANewWorkflowEditorTab(newWorkflowDetails));
                setIsGitDirModalVisible(false);
                if(gitactiveInnerTab=='2' && selectedGitDirectory){
                    goToWorkflows({id:selectedGitDirectory.id, name: selectedGitDirectory.name})
                }
            }else {
                errorAlert("Failed to create workflow.")
            }
        });
        setIsModalVisible(false);
    };

    const handleCancelGitWorkflow=(name: string, id: number)=>{
        WorkflowHandler.RetrieveWorkflows({ page:1, size:8, user_id: userId, workflowSearch:'', isStarred:false, notes:'', directory_id:id, sort:'updated', env: activeExecutionEnv, repo:'remote' })
                .then(response => {
                    setGitWorkflows(response?.data?.data)
                });
                setIsGitDirModalVisible(false);
                setGitActiveInnerTab('2'); 
    }

    const openGitDirModal = (directory: { id: number; name: string }) => {
        setSelectedGitDirectory(directory);
        setIsGitDirModalVisible(true);
    };


    const handleNewDirectory = (repo: any) => {
        setTypeGitDirectory('Create')
        setSelectedRepo(repo);
        setIsModalVisible(true);
        setHoveredGitRepoId(null);
    };

    const goToWorkflows=(dir: any)=>{
        if(dir){
         setSelectedGitDirectory({id: dir.id, name: dir.name})
        setGitActiveInnerTab('2')
        WorkflowHandler.RetrieveWorkflows({ page:1, size:8, user_id: userId, workflowSearch:'', isStarred:false, notes:'', directory_id:dir.id, sort:'updated', env: activeExecutionEnv, repo:'remote' })
                .then(response => {
                    setGitWorkflows(response?.data?.data)
            });
        }
    }

    const handleSaveWorkflowSuccess = (saveAsVersion: boolean) => {
        successAlert('Successfully saved ' + getActiveWorkflowType(activeExecutionEnv));
    };

    const handleSaveWorkflow =  (saveAsVersion = false) => {
        dispatch(saveActiveWorkflow(handleSaveWorkflowSuccess.bind(null, saveAsVersion), saveAsVersion, 'workflowEditor'));
    };


    const handlePushPull = (repo: any, choice:string) => {
        if(openTabs.size ==0){
        const data={
            "repoId": repo.id,
            "env": activeExecutionEnv,
            "command": choice
        }
        WorkflowHandler.PushDirectory(data, (response) => {
            setHoveredGitRepoId(null)
            successAlert(response)
            setActiveRepoId(null)
            if(choice=='clone'){
                handleGetRepositories();
            }
        }, (error)=>{
            console.error(error)
            setSelectedRepo(repo)
            errorAlert(error.data)
            if(error.data=='You have merge conflicts'){
                setShowErrorModal(true);
            }
        });
    }else {
        errorAlert("Please close all the workflows before Pull or Push.")
    }
    };

    const handleErrorSubmit = () => {
        const data={
            "repoId": selectedRepo?.id, 
            "env": activeExecutionEnv, 
            "mergeStratergy": errorOption 
        }
        WorkflowHandler.HandleConflict(data, (response) => {
            if (response) {
                successAlert(response);
            } else {
                errorAlert(response);
            }
        }, (error)=>{
            console.error(error)
            errorAlert(error?.data);
        });
        setShowErrorModal(false);
      };

    const listDirectories = (repo: { name: string, id: number }) => {
        setLoadingDirectories(true);
        setActiveRepoId(prevActiveRepoId => (prevActiveRepoId === repo.id ? null : repo.id));
        
        const env = activeExecutionEnv;
        const sort = 'updated';
    
        WorkflowHandler.GetListDirectories(userId, { env, sort, repoId: repo.id }, (res: any) => {
                setRepositories(prevRepos => {
                    const updatedRepos = prevRepos.map(r => {
                        if (r.id === repo.id) {
                            return { ...r, directories: res.data };
                        }
                        return r;
                    });
                    return updatedRepos;
                });
            setSelectedRepo(repo);
            setLoadingDirectories(false);
        }, (error)=>{
            console.error(error)
            errorAlert("Failed to fetch directories.");
        });
    };

    const handleGetWorkflowSuccess = (index: number, workflow: DetailedWorkflowInfo) => {
        dispatch(openANewWorkflowEditorTabUsingWorkflowInfo(workflow.data));
    };

    function handleOpenWorkflow(this: any, id: number, index: number) {
        WorkflowHandler.GetWorkflowInfo(id, handleGetWorkflowSuccess.bind(this, index));
    }

    const gitWorkflowOptions= useMemo(()=>(
        <div className="component_action_wrapper" style={{marginTop:'10px'}}>
                                <TooltipTop title={'Import directory/' + heading}>
                                    <button
                                        className="btn__new__workflow  btn__import"
                                        id="btn_import_workflow"
                                        onClick={function (this: any) {
                                            dispatch(toggleModal('componentImport', true, retrieveUserWorkflows.bind(this, {})));
                                        }}
                                    >
                                        <div style={{
                                            display: 'inline-block',
                                            border: '1px solid grey',
                                            width: 22,
                                            height: 22,
                                        }}>
                                            <MdOutlineOpenInNew />
                                        </div>
                                    </button>
                                </TooltipTop>
                            </div>
    ),[])

    const renderedGitWorkflows = useMemo(() => (
        <div className="gitDiv" style={{ marginLeft: '20px' }}>
            {gitWorkflows.length > 0 ? (
                gitWorkflows.map((gitWorkflow, index) => (
                    <li className={classNames('workflow__item')} key={gitWorkflow.id} onClick={() => handleOpenWorkflow(gitWorkflow.id, index)} style={{ justifyContent: 'space-between' }}>
                        <TooltipTop title={gitWorkflow.projectName} mouseEnterDelay={0.5}>
                        <span className="workflow__title">
                            {gitWorkflow.projectName}
                        </span>
                        </TooltipTop>
                        {/* <span className="workflow__dropdownTrigger"
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => { e.stopPropagation(); setActiveWorkflowId(gitWorkflow.id); }}
                        >
                             <div
                                    id={`Workflow_dropDownDots_${gitWorkflow.projectName}`}
                                    className="dropdown__threedots"
                                >
                                    <img id={`Workflow_dropDownDots_Img${gitWorkflow.projectName}`} src="/icons/more.svg" alt="" />
                                </div>
                            {activeWorkflowId === gitWorkflow.id && (
                                <AntDropdown
                                    overlay={
                                        <Menu className="workflowItem__contextMenuOptions">
                                            <Menu.Item
                                        onClick={() => {
                                            handleSingleWorkflowSelection(gitWorkflow);
                                            handleToggleModal('renameWorkflow', true);
                                            setActiveWorkflowId(null);
                                                setTimeout(() => {
                                                    setActiveWorkflowId(null);
                                                    if (!showRenameModal && selectedGitDirectory) {
                                                        goToWorkflows(selectedGitDirectory);
                                                    }
                                                }, 0);
                                        }}
                                    >
                                        Rename
                                    </Menu.Item>
                                        </Menu>
                                    }
                                    visible={activeWorkflowId === gitWorkflow.id}
                                >
                                    <span style={{ width: 0, height: 0 }} />
                                </AntDropdown>
                            )}
                        </span> */}
                    </li>
                ))
            ) : (
                <div className='center h-auto ml-1'>
                    <Text fontSize={12}>No Workflows available</Text>
                </div>
            )}
        </div>
    ), [gitWorkflows, activeWorkflowId, showDeleteModal,showRenameModal,selectedGitDirectory]);

    const handleContextMenu = (e: any, dir: any) => {
        e.preventDefault();
        setTriggerElement(e.currentTarget);
        const userIdString = userId || '';
        const directory: DirectoryInfo = {
            ...dir,
            userId: userIdString
          };
          const { clientX, clientY } = e;
        setSelectedGitDirectory(directory);
        setDropdownPosition({ left: clientX, top: clientY });
        setDropdownVisible(true);
      };
    
      const handleMenuClick = (e: any) => {
        setDropdownVisible(true);
      };

      useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (hoveredGitRepoId !== null) {
                // Check if the click is outside the active dropdown
                if (!(event.target as HTMLElement).closest('.workflowItem__contextMenuOptions')) {
                    setHoveredGitRepoId(null);
                }
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [hoveredGitRepoId]);

    const dropdownStyles = {
        position: 'absolute',
        zIndex: 1000,
        transition: 'opacity 0.2s ease',
    };

      const menu = (
        <Menu onClick={()=>handleMenuClick} className="directory__contextMenuOptionss" style={{ position:'fixed', left: `${dropdownPosition?.left}px`, top: `${dropdownPosition?.top}px`, width:'115px'}}>
          <Menu.Item onClick={() => {
          if (selectedGitDirectory) {
            openGitDirModal(selectedGitDirectory);
            setDropdownVisible(false);
          }
        }}
        >
            Create Workflow
          </Menu.Item>
          <Menu.Item
            onClick={() => {
                    setIsModalVisible(true);
                    setTypeGitDirectory('Rename')
                    setDropdownVisible(false);
                    setActiveRepoId(null)
            }}
          >
            Rename
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              handleToggleModal('deleteWorkflow', true);
              dispatch(setSingleItemInfoForModals(selectedGitDirectory?selectedGitDirectory:{name:'', id:0}));
              dispatch(setModalInfoTypeInWorkflowsPage('directory'));
              setDropdownVisible(false);
              setActiveRepoId(null)
            }}
          >
            Delete
          </Menu.Item>
              <Menu.Item
                  onClick={() => {
                    if(selectedGitDirectory)
                      WorkflowHandler.DownloadDirectory({
                          directoryId: selectedGitDirectory.id,
                          env: activeExecutionEnv
                      }).then((response) => {
                          const url = window.URL.createObjectURL(new Blob([response.data]));
                          const link = document.createElement('a');
                          link.href = url;
                          link.setAttribute('download', selectedGitDirectory.name + '-' + Date.now() + '.zip'); //or any other extension
                          document.body.appendChild(link);
                          link.click();
                      })
                      setDropdownVisible(false);
                  }}
              >
                  Download
              </Menu.Item>
        </Menu>
      );

    const renderedGitRepos = useMemo(() => (
        <div>
        {repositories.length > 0 ? (
        repositories.map(repo => (
            <div
                className={classNames('gitflow__item')}
                style={{ position: 'relative', display:'block'}}
                key={repo.id}
            >
                <div style={{ display: 'flex', alignItems: 'center', position: 'relative'}}>
                        <RiGitRepositoryLine color='#9495A0'/>
                        <span className="gitflow__title"  key={repo.id} onClick={() => listDirectories(repo)}>
                            {repo.name}
                            <span className="workflow__dropdownTrigger" 
                     style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                cursor: 'pointer',
                            }}
                            onClick={(e) => {e.stopPropagation(); setHoveredGitRepoId(repo.id);}}
                            >
                            <div
                                id={`Workflow_dropDownDots_${repo.id}`}
                                className="dropdown__threedots"
                            >
                                <img id={`Workflow_dropDownDots_Img${repo.id}`} src="/icons/more.svg" alt="" />
                            </div>
                         {hoveredGitRepoId === repo.id && (
                     <Dropdown
                     overlay={
                         <Menu className="workflowItem__contextMenuOptions">
                             <Menu.Item onClick={() => {handleNewDirectory(repo);setHoveredGitRepoId(null);}}>
                                 Create Directory
                             </Menu.Item>
                             <Menu.Item
                                 onClick={() => {
                                     handlePushPull(repo, "pull");
                                     setHoveredGitRepoId(null);
                                 }}
                             >
                                 Pull
                             </Menu.Item>
                             <Menu.Item
                                 onClick={() => {
                                     handlePushPull(repo, "push");
                                     setHoveredGitRepoId(null);
                                 }}
                             >
                                 Push
                             </Menu.Item>
                             <Menu.Item
                                 onClick={() => {
                                     handlePushPull(repo, "clone");
                                     setHoveredGitRepoId(null);
                                 }}
                             >
                                 Clone
                             </Menu.Item>
                         </Menu>
                     }
                     visible={hoveredGitRepoId === repo.id}
                     onVisibleChange={() => setHoveredGitRepoId(null)}
                 >
                   <span style={{ width: 0, height: 0 }} />
                 </Dropdown>
                )}
                     </span>
                        </span>
                    </div>
                {activeRepoId == repo.id && (
                    <div className="dropdown-content">
                        <div className="gitScroll">
                        {loadingDirectories ? (
                               <div className='center h-auto'>
                               <InPageSpinner size='Small' />
                               <Text fontSize={12}>Fetching Directories.</Text>
                           </div>
                            ) : 
                            repo.isCloned ? (
                                repo?.directories?.length > 0 ? (
                                <ul className='gitDirectories__container'>
                                    {repo.directories.map((dir: any) => (
                                        <li className="gitDirectories__item" 
                                        key={dir.id}
                                        onClick={()=>goToWorkflows(dir)}
                                        onContextMenu={(e) => handleContextMenu(e, dir)}
                                        >
                                            <TooltipTop title={dir.name} mouseEnterDelay={0.5}>
                                                <span className="gitdirectoryName">
                                                    <WorkflowProjectIcon />
                                                    <span className="directoryNameText">{dir.name}</span>
                                                </span>
                                            </TooltipTop>
                                        </li>
                                    ))}      
                                    {(dropdownVisible) && (
                                            <div className='custom-dropdown' style={{
                                                ...dropdownStyles,
                                                left: dropdownPosition?.left,
                                                top: dropdownPosition?.top,
                                                opacity: dropdownVisible ? 1 : 0,
                                            }as React.CSSProperties}>
                                            <Dropdown
                                                overlay={menu}
                                                trigger={['contextMenu']}
                                                visible={dropdownVisible}
                                                onVisibleChange={(visible) => setDropdownVisible(visible)}
                                            >
                                                <div style={{ width: 0, height: 0 }} />
                                            </Dropdown>
                                            </div>
                                        )}             
                                </ul>
                            ) : (
                                <div className='center h-auto ml-1'>
                                   <Text fontSize={12}>No directories available</Text>
                                </div>
                            )
                        ):(
                                <div className='center h-auto ml-1'>
                                   <Text fontSize={12}>Clone the repo to see directories</Text>
                                </div>
                        )
                    }
                        </div>
                    </div>
                )}
            </div>
        ))
    ) : (
        <div className='center h-auto'>
          <Text fontSize={14}>No repositories available</Text>
        </div>
      )
      }
        </div>
    ), [repositories, hoveredGitRepoId, loadingDirectories, dropdownVisible, activeRepoId]);


    const handleGetRepositories=()=>{
        WorkflowHandler.GetRepositories((res) => {
            const updatedRepos = res.map((repo: any) => ({
                ...repo,
                directories: directories[repo.id] || [],  
            }));
            setRepositories(updatedRepos);
            setisRepositoriesLoading(false);
        },(error)=>{
            setisRepositoriesLoading(false);
            console.error(error);
            errorAlert('Failed to fetch repositories.');
        });
    }

    useEffect(()=>{
        setActiveRepoId(null) 
    if(!Env.disableGitIntegration){
        handleGetRepositories();
    }
        
    },[activeExecutionEnv])
    

    useEffect(() => {
        if (activeInnerTab === 'Remote' && !Env.disableGitIntegration) {
            setisRepositoriesLoading(true);
            handleGetRepositories();
            setActiveRepoId(null)
        }
    }, [activeInnerTab]);

    const handleSetActiveHoverWorkflowId = (workflowId: number | null = null) => {
        setActiveHoverWorkflowId(workflowId);
    };

    const handleClickWorkflowItemInList = (workflow: RetrieveWorkflowsPayload['results'][0]) => {
        if (workflowSearchString !== '') {
            // dispatch(showSpinner(SpinnerTypes.inPageSpinner));
            // setWorkflowSearchString('');
        }
        handleClickWorkflow(workflow);
    };


    const workflowsList = () => (
        workflows.results.map((workflow, index) => {
            const __selected = workflowsSelectedState[workflow.id]?.status;
            const isStarred = workflow.isStarred;
            const showWorkflowHoverState = activeHoverWorkflowId === workflow.id;
            return (
                <TooltipTop
                    key={`${index}-${workflow.id}`}
                    title={workflow.projectName}
                    mouseEnterDelay={0.5}
                // id= {`${index}-${workflow.id}`}
                >
                    <div
                        className={classNames('workflow__item',
                            { 'workflow__item--selected': __selected },
                            { 'workflow__item--starred': isStarred },
                            { 'workflow__disableHoverState': __selected || readOnlyMode },
                            { 'workflow__item--active': activeTabId === workflow.id },
                            { 'workflow___noPermissions': workflow.readOnly },
                            { 'workflow___hoverState': showWorkflowHoverState },
                        )}
                        role="workflows-list"
                        id={`workflow_${workflow.projectName}`}
                    >
                        {workflow.isLoading ?
                            <InPageSpinner />
                            :
                            <label className="checkbox__container" id={`Workflow_checkbox_lable${workflow.projectName}`} >
                                <input id={`Workflow_checkbox_${workflow.projectName}`} type="checkbox" checked={__selected} onChange={() => { return; }} value="" />
                                <span id={`Workflow_checkmark_${workflow.projectName}`} className="checkmark" onClick={() => handleMultipleWorkflowSelection(workflow.id)} />
                            </label>
                        }

                        <button
                            className="favoriteIcon__btn"
                            id={`favIcon_Btn_workflow${workflow.projectName}`}
                            onClick={() => starWorkflows([workflow.id])}
                        >
                            <FavoriteIcon active={isStarred} />
                        </button>
                        <span
                            className="workflow__title"
                            onClick={() => handleClickWorkflowItemInList(workflow)}
                            role="workflow-title"
                            aria-label={workflow.projectName}
                            id={`workflow_label_${workflow.projectName.replace(/[^a-zA-Z ]/g, '')}`}
                        >
                            {workflow.projectName}
                        </span>
                        <div className="workflow__icons common__workflow__icons"
                            id={`workflow_actionBtn_${workflow.projectName}`}
                        >
                            {/* <TooltipTop
                                title={'Copy ' + capitalize(activeWorkflowType)}
                            >
                                <button
                                    id={`workflow_CloneBtn_${workflow.projectName}`}
                                    onClick={() => cloneWorkflowClick(workflow.projectName, workflow.id)}
                                >
                                    <img id={`workflow_CloneBtn_img_${workflow.projectName}`} src="/icons/copy.svg" className="icon__square__hover" alt="" />
                                </button>
                            </TooltipTop> */}
                            {/* <button 
                                onClick={() => exportWorkflow(workflow)}
                            >
                                <img src="/icons/export.svg" className="icon__square__hover export__icon" alt="" />
                            </button> */}
                            {/* <ShowWhenTrue
                                show={!workflow.readOnly}
                            >
                                <TooltipTop
                                    title={'Delete ' + capitalize(activeWorkflowType)}
                                >
                                    <button
                                        id={`workflow_DeleteBtn_${workflow.projectName}`}
                                        onClick={() => {
                                            handleSingleWorkflowSelection(workflow);
                                            handleToggleModal('deleteWorkflow', true);
                                            dispatch(setModalInfoTypeInWorkflowsPage('workflow'));
                                        }}
                                    >
                                        <img id={`workflow_DeleteBtn_${workflow.projectName}`} src="/icons/delete.svg" className="icon__square__hover" alt="" />
                                    </button>
                                </TooltipTop>
                            </ShowWhenTrue> */}
                            <AntDropdown
                                overlay={<Menu
                                    className="workflowItem__contextMenuOptions"
                                    onClick={() =>
                                        handleSetActiveHoverWorkflowId()
                                    }
                                >
                                    <Menu.Item
                                        onClick={() => {
                                            handleClickWorkflowItemInList(workflow);
                                        }}
                                    >
                                        Open
                                    </Menu.Item>
                                    <Menu.Item
                                        onClick={() => {
                                            handleSingleWorkflowSelection(workflow);
                                            handleToggleModal('renameWorkflow', true);
                                        }}
                                        disabled={workflow.readOnly}
                                    >
                                        Rename
                                    </Menu.Item>
                                    {renderDirectoriesDropdown([workflow.id], workflow.directoryId, workflow.readOnly)}
                                    <Menu.Item
                                        onClick={() => {
                                            handleSingleWorkflowSelection(workflow);
                                            handleToggleModal('shareWorkflow', true);
                                        }}
                                        disabled={workflow.readOnly}
                                    >
                                        Share a copy
                                    </Menu.Item>
                                    <Menu.Item
                                        onClick={() => {
                                            exportWorkflow(workflow);
                                        }}
                                        disabled={workflow.readOnly}
                                    >
                                        Export
                                    </Menu.Item>
                                    <Menu.Item
                                        onClick={() => cloneWorkflowClick(workflow.projectName, workflow.id)}
                                    >
                                        Copy workflow
                                    </Menu.Item>
                                    <Menu.Item
                                        onClick={() => {
                                            handleSingleWorkflowSelection(workflow);
                                            handleToggleModal('deleteWorkflow', true);
                                            dispatch(setModalInfoTypeInWorkflowsPage('workflow'));
                                        }}
                                        disabled={workflow.readOnly}
                                       
                                    >
                                        Delete workflow
                                    </Menu.Item>
                                </Menu>}
                                trigger={['click']}
                                onVisibleChange={(visible) => {
                                    if (visible) setActiveHoverWorkflowId(workflow.id);
                                    else setActiveHoverWorkflowId(null);
                                }}
                                openClassName="showHoverState"
                            >
                                <div
                                    id={`Workflow_dropDownDots_${workflow.projectName}`}
                                    className="dropdown__threedots"
                                >
                                    <img id={`Workflow_dropDownDots_Img${workflow.projectName}`} src="/icons/more.svg" alt="" />
                                </div>
                            </AntDropdown>
                            {/* <Dropdown
                                title={
                                    <div id={`Workflow_dropDownDots_${workflow.projectName}`} className="dropdown__threedots">
                                        <img id={`Workflow_dropDownDots_Img${workflow.projectName}`} src="/icons/more.svg" alt="" />
                                    </div>
                                }
                                dropdownOptions={{
                                    'Open': { action: () => handleClickWorkflow(workflow) },
                                    'Rename': {
                                        action: () => {
                                            handleSingleWorkflowSelection(workflow);
                                            handleToggleModal('renameWorkflow', true);
                                        },
                                        disabled: workflow.readOnly
                                    },
                                    'Export': {
                                        action: () => exportWorkflow(workflow),
                                        disabled: workflow.readOnly
                                    }
                                }}
                                position="left"
                            /> */}
                        </div>
                    </div>
                </TooltipTop>
            );
        })
    );



    const directoriesHeader = () => (
        <div
            className="goBackDirectories__box"
        >
            {workflowSearchString ?
                <button
                // onClick={() => handleSetSelectedProject(null)}
                >
                    <GoBackTiltedArrow active />
                    Search Results
                </button>
                :
                <>
                    <button
                        disabled={!selectedDirectory}
                        onClick={() => handleSetSelectedDirectory(null)}
                    >
                        <GoBackTiltedArrow active={!!selectedDirectory} />
                        Directories
                    </button>
                    <div
                        className="directory__breadcrumb"
                    >
                        {selectedDirectory?.name &&
                            <>
                                <RightChevron />
                                <span>
                                    <TooltipTop
                                        title={selectedDirectory.name}
                                    >
                                        {selectedDirectory.name}
                                    </TooltipTop>
                                </span>
                            </>
                        }
                    </div>
                </>
            }
        </div>
    );

    const handleProjectRenameValueChange = (renameValue: string) => {
        setNewDirectoryInfo(p => ({ ...p, renameValue, showError: !!directories.find(p => p.name === renameValue) }));
    };

    const resetNewProjectInfo = () => {
        // timeout is added as clicking on directory in editable mode is triggering onClick event of directory div
        setTimeout(() => setNewDirectoryInfo({ editable: false, directoryInfo: null, renameValue: '', showError: false }), 200);
    };

    const handleSaveProjectRenameValue = () => {
        if (newDirectoryInfo.directoryInfo) {
            const updatedDirectories = cloneDeep(directories).map(proj => {
                if (proj.id === newDirectoryInfo.directoryInfo?.id) {
                    proj.name = newDirectoryInfo.renameValue;
                }
                return proj;
            });
            dispatch(setUserDirectories(activeExecutionEnv, updatedDirectories));
            resetNewProjectInfo();
            WorkflowHandler.RenameProject(newDirectoryInfo.directoryInfo.id, newDirectoryInfo.renameValue, () => {
                return;
            });
        }
    };

    const directoryContextMenuRef = useRef<ContextMenuComponent>(null);

    const directoriesList = () => {
        return (
            showWorkflowSpinner ?
                <InPageSpinner />
                :

                <div
                    className="directoriesList"
                >
                    {directories.map(dir => {
                        const directoryRenameMode = newDirectoryInfo.editable && newDirectoryInfo.directoryInfo?.id === dir.id;
                        return (
                            <AntDropdown
                                overlay={
                                    directoryRenameMode ?
                                        <Menu
                                            className="directory__contextMenuOptions"
                                        >
                                            <Menu.Item
                                                onClick={resetNewProjectInfo}
                                            >
                                                Cancel
                                            </Menu.Item>
                                            <Menu.Item
                                                onClick={handleSaveProjectRenameValue}
                                            >
                                                Save
                                            </Menu.Item>
                                        </Menu>
                                        :
                                        <Menu
                                            className="directory__contextMenuOptions"
                                        >
                                            <Menu.Item
                                                onClick={() => {
                                                    handleSetSelectedDirectory(dir);
                                                }}
                                            >
                                                Open
                                            </Menu.Item>
                                            <Menu.Item
                                                onClick={() => {
                                                    setNewDirectoryInfo({ editable: true, directoryInfo: dir, renameValue: dir.name, showError: false });
                                                }}
                                                disabled={dir.readOnly}
                                            >
                                                Rename
                                            </Menu.Item>
                                            <Menu.Item
                                                onClick={() => {
                                                    handleToggleModal('deleteWorkflow', true);
                                                    dispatch(setSingleItemInfoForModals(dir));
                                                    dispatch(setModalInfoTypeInWorkflowsPage('directory'));
                                                }}
                                                disabled={dir.readOnly}
                                            >
                                                Delete
                                            </Menu.Item>
                                            <Menu.Item
                                                onClick={() => {
                                                    WorkflowHandler.DownloadDirectory({
                                                        directoryId: dir.id,
                                                        env: activeExecutionEnv
                                                    }).then((response) => {
                                                        const url = window.URL.createObjectURL(new Blob([response.data]));
                                                        const link = document.createElement('a');
                                                        link.href = url;
                                                        link.setAttribute('download', dir.name + '-' + Date.now() + '.zip'); //or any other extension
                                                        document.body.appendChild(link);
                                                        link.click();
                                                    })
                                                }}
                                            >
                                                Download
                                            </Menu.Item>
                                        </Menu>
                                }
                                trigger={['contextMenu']}
                                openClassName="showHoverState"
                                key={dir.id + '_proj'}
                            >
                                <div
                                    className="directory__item"
                                    onClick={() => !(directoryRenameMode) && handleSetSelectedDirectory(dir)}
                                    onContextMenu={(e) => directoryContextMenuRef.current?.showContextMenu(e)}
                                >
                                    <WorkflowProjectIcon />
                                    {directoryRenameMode ?
                                        <InlineInputField
                                            show
                                            value={newDirectoryInfo.renameValue}
                                            onChange={handleProjectRenameValueChange}
                                            saveChanges={handleSaveProjectRenameValue}
                                            discardChanges={resetNewProjectInfo}
                                            showError={newDirectoryInfo.showError}
                                        />
                                        :
                                        dir.name
                                    }
                                </div>
                            </AntDropdown>
                        );
                    })}
                </div>

        );
    };

    const workflowView = () => (
        <>
            {showWorkflowSpinner ?
                (<InPageSpinner />)
                :
                <div className="saved__workflows__innerContainer">
                    {/* {workflowsOptions} */}
                    {isEmpty(workflows.results) ?
                        <div
                            className="noWorkflows__msg"
                        >
                            No {heading}s Available.
                        </div>
                        :
                        workflowsList()
                    }
                </div>
            }
            {workflows.results.length === 0 ? '' : (
                <div className={classNames('pagination__container', { 'align__end': !!(workflowSearchString) })}>
                    <ShowWhenTrue show={!!(workflowSearchString)}>
                        <span className="pagination__searchResultsNumber">
                            {workflows.totalCount} results for <br />
                            &#39;{workflowSearchString}&#39;
                        </span>
                    </ShowWhenTrue>
                    <Paginate
                        startPage={currentActivePageNumberForWorkflows}
                        totalCount={workflows.totalCount}
                        itemsPerPage={numberOfWorkflowsPerPage}
                        handlePageChange={handlePageChange}
                        ref={paginateRef}
                        showArrowsForPageNav
                    />
                </div>)
            }
        </>
    );




    return (
        <>
            <div className={classNames('saved__workflows__container', { 'hide__treeview': hideWorkflowTreeview })}>
                {/* <div className="userFiltering__box">
                    <span>Show workflows for</span>
                    <Form
                        initialValues={{ selectedUserId: workflowSelectedUserID }}
                        onSubmit={() => { return; }}
                        enableReinitialize
                    >
                        <SelectField
                            name="selectedUserId"
                            options={allUsersList}
                            onOptionClick={changeSelectedUserId}
                            className="usersList__dropdown"
                            // setWidthManually

                        />                    
                    </Form>

                </div> */}

                <div className="search__input__container">
                    <input
                        value={workflowSearchString}
                        onChange={handleWorkflowSearch}
                        className="search_input"
                        placeholder={`Try "${capitalize(heading)}"`}
                        id='SearchWorklow'
                    />

                    {!!(workflowSearchString) &&
                        <img
                            src="/icons/treeview/search-cross.svg"
                            alt=""
                            className="search_reset"
                            onClick={() => { setWorkflowSearchString(''); searchWorkflows(''); }}
                        />
                    }
                </div>
                <div>
                    <Tabs activeKey={activeInnerTab} className="innerWorkflowsTabss" onChange={handleWorkflowInnerTabChange}>
                        <TabPane tab={capitalize(getActiveWorkflowType(activeExecutionEnv))} key={InnerWorkflowTabs.WORKFLOWS}>
                            {workflowsOptions}
                            {directoriesHeader()}
                            <Tabs activeKey={showDirectories ? '1a' : '2b'} className="directoryWorkflow__nav">
                                <TabPane key="1a" tab="a">
                                    {directoriesList()}
                                </TabPane>
                                <TabPane key="2b" tab="b">
                                    {workflowView()}
                                </TabPane>
                            </Tabs>
                        </TabPane>
                        <TabPane tab={InnerWorkflowTabs.SHARED} key={InnerWorkflowTabs.SHARED} className='innerGitTabs'>
                            <Tabs activeKey={gitactiveInnerTab} onChange={setGitActiveInnerTab}>
                                <TabPane key="1">
                                {/* {gitWorkflowOptions} */}
                                    {gitactiveInnerTab === "1" && (
                                        <>
                                        <div style={{display:'flex', padding:'4px'}}>
                                            <button
                                                disabled={true}
                                                style={{ marginLeft: '5px', marginTop: '4px' }}
                                            >
                                                <GoBackTiltedArrow active={false} />
                                            </button>
                                            <span className='GitInnerTab'>Repositories</span>
                                            </div>
                                            {isRepositoriesLoading ?<InPageSpinner />:renderedGitRepos}
                                            {selectedRepo && (
                                                <NewDirectoryModal
                                                    visible={isModalVisible}
                                                    repoName={selectedRepo.name}
                                                    repoId={selectedRepo.id}
                                                    type={typeGitDirectory}
                                                    onCreate={handleCreateDirectory}
                                                    onCancel={() => setIsModalVisible(false)}
                                                />
                                            )}
                                            {(selectedGitDirectory && isGitDirModalVisible) && (
                                                <NewGitWorkflow
                                                visible={isGitDirModalVisible}
                                                dirName={selectedGitDirectory.name}
                                                dirId={selectedGitDirectory.id}
                                                onCreate={handleCreateGitWorflow}
                                                onCancel={handleCancelGitWorkflow}
                                                />
                                            )}
                                            <ErrorHandlingModal
                                                visible={showErrorModal}
                                                onSubmit={handleErrorSubmit}
                                                onCancel={() => setShowErrorModal(false)}
                                                onOptionChange={(value: string) => setErrorOption(value)}
                                            />
                                        </>
                                    )}
                                </TabPane>
                                <TabPane key="2">
                                    {gitactiveInnerTab=='2' && (
                                        <>
                                <div style={{display:'flex'}}>
                                               <button
                                                    disabled={false}
                                                    style={{ marginLeft: '5px', marginTop: '4px' }}
                                                    onClick={handleBackButtonClick}
                                                >
                                                    <GoBackTiltedArrow active={!!selectedRepo} />
                                                </button>
                                                <span className='GitInnerTab'>Git Workflows</span>
                                                <TooltipTop title={'Create a ' + heading}>
                                    <button
                                        id="btn_new_workflow"
                                        className="btn-sm btn-yellow-transparent btn__new__workflow btn__new__action"
                                        onClick={()=>setIsGitDirModalVisible(true)}
                                        aria-label="New Workflow"
                                        style={{display:'flex',marginBottom:'5px', marginTop:'5px',marginLeft:'auto'}}
                                    >
                                        <div style={{marginTop:'2px'}}>
                                        <BsPlusLg />
                                        </div>
                                        <span>New</span>
                                    </button>
                                </TooltipTop>
                                                </div>
                                                {renderedGitWorkflows}
                                                {(selectedGitDirectory) && (
                                                <NewGitWorkflow
                                                visible={isGitDirModalVisible}
                                                dirName={selectedGitDirectory.name}
                                                dirId={selectedGitDirectory.id}
                                                onCreate={handleCreateGitWorflow}
                                                onCancel={handleCancelGitWorkflow}
                                                />
                                            )}
                                            </>
                                            )}
                                </TabPane>
                            </Tabs>
                        </TabPane>

                        <TabPane tab={InnerWorkflowTabs.STARRED} key={InnerWorkflowTabs.STARRED}>
                            {workflowsOptions}
                            {workflowView()}
                        </TabPane>
                    </Tabs>
                        </div>
            </div>
            <NewWorkflowModal
                retrieveWorkflowsOnSuccess={retrieveUserWorkflows}
            />
            <DeleteWorkflowModal
                retrieveWorkflowsOnSuccess={retrieveWorkflowsOnActionSuccess}
            />
            <RenameWorkflowModal
                retrieveWorkflowsOnSuccess={retrieveUserWorkflows}
            />
            <ShareWorkflowModal />
        </>
    );
}; 


