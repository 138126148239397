
export const worldJson = {
    'type': 'FeatureCollection',
    'features': [
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            74.54140625000002,
                            37.02216796875
                        ],
                        [
                            74.00185546875002,
                            36.823095703125
                        ],
                        [
                            73.769140625,
                            36.888476562499996
                        ],
                        [
                            72.62285156250002,
                            36.82958984375
                        ],
                        [
                            71.62050781250002,
                            36.436474609375
                        ],
                        [
                            71.18505859375,
                            36.04208984375
                        ],
                        [
                            71.42753906250002,
                            35.833740234375
                        ],
                        [
                            71.62050781250002,
                            35.183007812499994
                        ],
                        [
                            70.96562500000002,
                            34.53037109375
                        ],
                        [
                            71.05156250000002,
                            34.049707031249994
                        ],
                        [
                            69.86806640625002,
                            33.89765625
                        ],
                        [
                            70.2841796875,
                            33.369042968749994
                        ],
                        [
                            69.92011718750001,
                            33.1125
                        ],
                        [
                            69.5015625,
                            33.020068359374996
                        ],
                        [
                            69.24140625000001,
                            32.433544921875
                        ],
                        [
                            69.279296875,
                            31.936816406249996
                        ],
                        [
                            68.86894531250002,
                            31.634228515624997
                        ],
                        [
                            68.21396484375,
                            31.807373046875
                        ],
                        [
                            67.57822265625,
                            31.506494140624994
                        ],
                        [
                            67.28730468750001,
                            31.217822265624996
                        ],
                        [
                            66.92431640625,
                            31.305615234374997
                        ],
                        [
                            66.39716796875001,
                            30.91220703125
                        ],
                        [
                            66.17705078125002,
                            29.835595703124994
                        ],
                        [
                            65.09550781250002,
                            29.559472656249994
                        ],
                        [
                            64.52109375,
                            29.564501953124996
                        ],
                        [
                            64.09873046875,
                            29.391943359375
                        ],
                        [
                            63.56757812500001,
                            29.497998046874997
                        ],
                        [
                            62.4765625,
                            29.408349609374994
                        ],
                        [
                            62.37343750000002,
                            29.425390625
                        ],
                        [
                            61.22441406250002,
                            29.749414062499994
                        ],
                        [
                            60.843359375000006,
                            29.858691406249996
                        ],
                        [
                            61.104101562500006,
                            30.12841796875
                        ],
                        [
                            61.55947265625002,
                            30.599365234375
                        ],
                        [
                            61.7841796875,
                            30.831933593749994
                        ],
                        [
                            61.81083984375002,
                            30.913281249999997
                        ],
                        [
                            61.81425781250002,
                            31.072558593749996
                        ],
                        [
                            61.75507812500001,
                            31.285302734374994
                        ],
                        [
                            61.66015625,
                            31.382421875
                        ],
                        [
                            61.34648437500002,
                            31.421630859375
                        ],
                        [
                            61.11074218750002,
                            31.451123046874997
                        ],
                        [
                            60.854101562500006,
                            31.483251953125
                        ],
                        [
                            60.82929687500001,
                            32.249414062499994
                        ],
                        [
                            60.71044921875,
                            32.599999999999994
                        ],
                        [
                            60.57656250000002,
                            32.994873046875
                        ],
                        [
                            60.560546875,
                            33.137841796874994
                        ],
                        [
                            60.9169921875,
                            33.505224609375
                        ],
                        [
                            60.485937500000006,
                            33.7119140625
                        ],
                        [
                            60.48574218750002,
                            34.094775390624996
                        ],
                        [
                            60.72626953125001,
                            34.51826171875
                        ],
                        [
                            60.802343750000006,
                            34.554638671875
                        ],
                        [
                            60.91474609375001,
                            34.633984375
                        ],
                        [
                            60.951171875,
                            34.653857421874996
                        ],
                        [
                            61.080078125,
                            34.855615234374994
                        ],
                        [
                            61.18925781250002,
                            35.31201171875
                        ],
                        [
                            61.3447265625,
                            35.6294921875
                        ],
                        [
                            61.54277343750002,
                            35.457861328125
                        ],
                        [
                            62.61054687500001,
                            35.233154296875
                        ],
                        [
                            62.688085937500006,
                            35.255322265625
                        ],
                        [
                            62.72265625,
                            35.271337890625
                        ],
                        [
                            62.858007812500006,
                            35.349658203124996
                        ],
                        [
                            62.98027343750002,
                            35.4091796875
                        ],
                        [
                            63.056640625,
                            35.44580078125
                        ],
                        [
                            63.08417968750001,
                            35.56806640625
                        ],
                        [
                            63.16972656250002,
                            35.678124999999994
                        ],
                        [
                            63.15078125000002,
                            35.728271484375
                        ],
                        [
                            63.129980468750006,
                            35.766748046874994
                        ],
                        [
                            63.10859375000001,
                            35.818701171875
                        ],
                        [
                            63.129980468750006,
                            35.84619140625
                        ],
                        [
                            63.17890625000001,
                            35.858447265624996
                        ],
                        [
                            63.30166015625002,
                            35.8583984375
                        ],
                        [
                            63.86250000000001,
                            36.012353515624994
                        ],
                        [
                            64.18437500000002,
                            36.14892578125
                        ],
                        [
                            64.51103515625002,
                            36.340673828125
                        ],
                        [
                            64.6025390625,
                            36.554541015625
                        ],
                        [
                            64.75312500000001,
                            36.964794921875
                        ],
                        [
                            64.78242187500001,
                            37.05927734375
                        ],
                        [
                            64.81630859375002,
                            37.132080078125
                        ],
                        [
                            64.95156250000002,
                            37.1935546875
                        ],
                        [
                            65.08964843750002,
                            37.237939453124994
                        ],
                        [
                            65.30361328125002,
                            37.24677734375
                        ],
                        [
                            65.55498046875002,
                            37.251171875
                        ],
                        [
                            65.76503906250002,
                            37.569140624999996
                        ],
                        [
                            66.1083984375,
                            37.41474609375
                        ],
                        [
                            66.35029296875001,
                            37.3681640625
                        ],
                        [
                            66.47187500000001,
                            37.3447265625
                        ],
                        [
                            66.52226562500002,
                            37.348486328125
                        ],
                        [
                            66.827734375,
                            37.3712890625
                        ],
                        [
                            67.06884765625,
                            37.334814453125
                        ],
                        [
                            67.19550781250001,
                            37.235205078125
                        ],
                        [
                            67.31972656250002,
                            37.2095703125
                        ],
                        [
                            67.44169921875002,
                            37.2580078125
                        ],
                        [
                            67.51728515625001,
                            37.266650390624996
                        ],
                        [
                            67.546484375,
                            37.235644531249996
                        ],
                        [
                            67.607421875,
                            37.222509765625
                        ],
                        [
                            67.70000000000002,
                            37.22724609375
                        ],
                        [
                            67.7529296875,
                            37.1998046875
                        ],
                        [
                            67.75898437500001,
                            37.172216796875
                        ],
                        [
                            67.76601562500002,
                            37.14013671875
                        ],
                        [
                            67.83447265625,
                            37.064208984375
                        ],
                        [
                            67.9580078125,
                            36.972021484375
                        ],
                        [
                            68.06777343750002,
                            36.9498046875
                        ],
                        [
                            68.21210937500001,
                            37.021533203124996
                        ],
                        [
                            68.38691406250001,
                            37.137499999999996
                        ],
                        [
                            68.66914062500001,
                            37.2583984375
                        ],
                        [
                            68.96044921875,
                            37.325048828125
                        ],
                        [
                            69.18017578125,
                            37.15830078125
                        ],
                        [
                            69.26484375000001,
                            37.1083984375
                        ],
                        [
                            69.30390625000001,
                            37.116943359375
                        ],
                        [
                            69.35380859375002,
                            37.150048828124994
                        ],
                        [
                            69.41445312500002,
                            37.207763671875
                        ],
                        [
                            69.4296875,
                            37.290869140625
                        ],
                        [
                            69.39921875000002,
                            37.399316406249994
                        ],
                        [
                            69.42011718750001,
                            37.486718749999994
                        ],
                        [
                            69.49208984375002,
                            37.553076171875
                        ],
                        [
                            69.62578125000002,
                            37.594042968749996
                        ],
                        [
                            69.8208984375,
                            37.6095703125
                        ],
                        [
                            69.9849609375,
                            37.566162109375
                        ],
                        [
                            70.25146484375,
                            37.66416015625
                        ],
                        [
                            70.41777343750002,
                            38.075439453125
                        ],
                        [
                            70.87890625,
                            38.456396484375
                        ],
                        [
                            71.255859375,
                            38.306982421875
                        ],
                        [
                            71.33271484375001,
                            38.170263671875
                        ],
                        [
                            71.2828125,
                            38.00791015625
                        ],
                        [
                            71.27851562500001,
                            37.918408203125
                        ],
                        [
                            71.319921875,
                            37.90185546875
                        ],
                        [
                            71.3896484375,
                            37.906298828124996
                        ],
                        [
                            71.48779296875,
                            37.931884765625
                        ],
                        [
                            71.55195312500001,
                            37.933154296874996
                        ],
                        [
                            71.58222656250001,
                            37.910107421875
                        ],
                        [
                            71.58037109375002,
                            37.8642578125
                        ],
                        [
                            71.50507812500001,
                            37.602929687499994
                        ],
                        [
                            71.43291015625002,
                            37.1275390625
                        ],
                        [
                            71.530859375,
                            36.845117187499994
                        ],
                        [
                            71.665625,
                            36.696923828124994
                        ],
                        [
                            72.65742187500001,
                            37.029052734375
                        ],
                        [
                            72.8955078125,
                            37.267529296875
                        ],
                        [
                            73.21113281250001,
                            37.408496093749996
                        ],
                        [
                            73.38291015625,
                            37.462255859375
                        ],
                        [
                            73.48134765625002,
                            37.4716796875
                        ],
                        [
                            73.60468750000001,
                            37.446044921875
                        ],
                        [
                            73.63261718750002,
                            37.43720703125
                        ],
                        [
                            73.65712890625002,
                            37.430468749999996
                        ],
                        [
                            73.72060546875002,
                            37.418749999999996
                        ],
                        [
                            73.71728515625,
                            37.329443359375
                        ],
                        [
                            73.749609375,
                            37.231787109375
                        ],
                        [
                            74.16708984375,
                            37.329443359375
                        ],
                        [
                            74.20351562500002,
                            37.372460937499994
                        ],
                        [
                            74.25966796875002,
                            37.415429687499994
                        ],
                        [
                            74.65937500000001,
                            37.394482421875
                        ],
                        [
                            74.37216796875,
                            37.15771484375
                        ],
                        [
                            74.37617187500001,
                            37.137353515624994
                        ],
                        [
                            74.49794921875002,
                            37.0572265625
                        ],
                        [
                            74.52646484375,
                            37.030664062499994
                        ],
                        [
                            74.54140625000002,
                            37.02216796875
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Afghanistan',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                17.57958984375,
                                -8.099023437500009
                            ],
                            [
                                17.643359375000017,
                                -8.090722656250009
                            ],
                            [
                                18.00878906250003,
                                -8.107617187499983
                            ],
                            [
                                18.56269531250001,
                                -7.9359375000000085
                            ],
                            [
                                18.89833984375008,
                                -7.998144531249977
                            ],
                            [
                                18.944433593750063,
                                -8.001464843750028
                            ],
                            [
                                19.142675781250034,
                                -8.001464843750028
                            ],
                            [
                                19.34082031249997,
                                -7.966601562500031
                            ],
                            [
                                19.369921875000045,
                                -7.706542968749986
                            ],
                            [
                                19.371679687500063,
                                -7.655078124999989
                            ],
                            [
                                19.527636718750017,
                                -7.144433593749952
                            ],
                            [
                                19.87519531250004,
                                -6.986328124999986
                            ],
                            [
                                19.99746093750008,
                                -6.976464843750023
                            ],
                            [
                                20.190039062500063,
                                -6.9462890625
                            ],
                            [
                                20.482226562500074,
                                -6.915820312500017
                            ],
                            [
                                20.59003906250001,
                                -6.919921874999957
                            ],
                            [
                                20.598730468750006,
                                -6.935156249999949
                            ],
                            [
                                20.536914062500045,
                                -7.1217773437499545
                            ],
                            [
                                20.558398437500045,
                                -7.244433593749989
                            ],
                            [
                                20.60781250000008,
                                -7.277734375000023
                            ],
                            [
                                20.910937500000017,
                                -7.281445312499983
                            ],
                            [
                                21.190332031250023,
                                -7.284960937499989
                            ],
                            [
                                21.751074218750034,
                                -7.305468749999989
                            ],
                            [
                                21.905371093750034,
                                -8.693359374999943
                            ],
                            [
                                21.813183593750068,
                                -9.46875
                            ],
                            [
                                22.19775390625,
                                -10.040624999999977
                            ],
                            [
                                22.274511718750006,
                                -10.259082031249974
                            ],
                            [
                                22.30244140625001,
                                -10.39667968750004
                            ],
                            [
                                22.203515625000023,
                                -10.829492187500009
                            ],
                            [
                                22.226171875000006,
                                -11.121972656250009
                            ],
                            [
                                23.156738281250057,
                                -11.074804687499991
                            ],
                            [
                                23.400195312500017,
                                -10.976464843750023
                            ],
                            [
                                23.55996093750005,
                                -10.97861328125002
                            ],
                            [
                                23.696386718750034,
                                -11.007617187499974
                            ],
                            [
                                23.83388671875008,
                                -11.013671874999972
                            ],
                            [
                                23.96650390625001,
                                -10.871777343750011
                            ],
                            [
                                24.046679687500074,
                                -11.405371093750006
                            ],
                            [
                                23.991308593750006,
                                -12.422167968750031
                            ],
                            [
                                23.90937500000001,
                                -12.636132812500009
                            ],
                            [
                                23.843164062500023,
                                -13.0009765625
                            ],
                            [
                                22.209570312500006,
                                -13.0009765625
                            ],
                            [
                                21.97890625000008,
                                -13.0009765625
                            ],
                            [
                                21.979101562500034,
                                -13.477734374999969
                            ],
                            [
                                21.979101562500034,
                                -13.798730468749994
                            ],
                            [
                                21.979296875000074,
                                -14.11962890625
                            ],
                            [
                                21.979394531249994,
                                -14.440527343750006
                            ],
                            [
                                21.97949218750003,
                                -14.761425781250011
                            ],
                            [
                                21.979589843750034,
                                -15.082324218750017
                            ],
                            [
                                21.979687500000068,
                                -15.403222656250023
                            ],
                            [
                                21.97978515624999,
                                -15.724121093750028
                            ],
                            [
                                21.97978515624999,
                                -15.955566406250014
                            ],
                            [
                                22.15068359374999,
                                -16.597167968749986
                            ],
                            [
                                22.193945312500006,
                                -16.628124999999983
                            ],
                            [
                                22.305078125000023,
                                -16.689550781249977
                            ],
                            [
                                22.955859375000074,
                                -17.28574218750002
                            ],
                            [
                                23.181640625000057,
                                -17.474414062500003
                            ],
                            [
                                23.380664062500017,
                                -17.64062500000003
                            ],
                            [
                                23.06826171875005,
                                -17.698828124999977
                            ],
                            [
                                22.624023437500057,
                                -17.78164062499998
                            ],
                            [
                                22.32421875,
                                -17.83749999999999
                            ],
                            [
                                21.96083984375005,
                                -17.90517578125001
                            ],
                            [
                                21.41689453125008,
                                -18.00068359375001
                            ],
                            [
                                20.625097656250063,
                                -17.99667968749999
                            ],
                            [
                                20.507617187500017,
                                -17.95253906249998
                            ],
                            [
                                20.392968750000023,
                                -17.887402343750026
                            ],
                            [
                                19.076464843750045,
                                -17.81767578125003
                            ],
                            [
                                18.825976562500074,
                                -17.766308593750054
                            ],
                            [
                                18.718066406250045,
                                -17.70322265624999
                            ],
                            [
                                18.39638671875005,
                                -17.3994140625
                            ],
                            [
                                17.83535156250005,
                                -17.392773437499983
                            ],
                            [
                                17.296289062500023,
                                -17.391992187499994
                            ],
                            [
                                16.913671875000034,
                                -17.39140624999996
                            ],
                            [
                                16.531054687500074,
                                -17.390820312500026
                            ],
                            [
                                16.14843750000003,
                                -17.39023437499999
                            ],
                            [
                                15.765820312500068,
                                -17.389648437499957
                            ],
                            [
                                13.93798828125,
                                -17.388769531249963
                            ],
                            [
                                13.403710937500051,
                                -17.00781249999997
                            ],
                            [
                                13.101171875000063,
                                -16.967675781249966
                            ],
                            [
                                12.548144531250017,
                                -17.212695312499974
                            ],
                            [
                                12.35927734375008,
                                -17.205859375000003
                            ],
                            [
                                12.318457031250006,
                                -17.21337890625003
                            ],
                            [
                                12.213378906250028,
                                -17.209960937500043
                            ],
                            [
                                12.013964843750074,
                                -17.168554687500034
                            ],
                            [
                                11.902539062500011,
                                -17.226562499999957
                            ],
                            [
                                11.743066406250023,
                                -17.24921875000004
                            ],
                            [
                                11.780078125000017,
                                -16.87128906249997
                            ],
                            [
                                11.818945312500034,
                                -16.704101562500014
                            ],
                            [
                                11.750878906250023,
                                -15.831933593749966
                            ],
                            [
                                11.967871093750006,
                                -15.63398437500004
                            ],
                            [
                                12.280468750000011,
                                -14.637499999999989
                            ],
                            [
                                12.55048828125004,
                                -13.437792968750003
                            ],
                            [
                                12.983203124999989,
                                -12.775683593750017
                            ],
                            [
                                13.4169921875,
                                -12.52041015624998
                            ],
                            [
                                13.785351562499983,
                                -11.81279296874996
                            ],
                            [
                                13.847460937500045,
                                -11.054394531249997
                            ],
                            [
                                13.20937500000008,
                                -9.703222656249977
                            ],
                            [
                                12.99853515625,
                                -9.048046874999997
                            ],
                            [
                                13.358984375000006,
                                -8.687207031250026
                            ],
                            [
                                13.378515625000063,
                                -8.369726562500006
                            ],
                            [
                                12.862304687500057,
                                -7.231835937499994
                            ],
                            [
                                12.82343750000004,
                                -6.9547851562499545
                            ],
                            [
                                12.521289062500045,
                                -6.590332031249957
                            ],
                            [
                                12.302539062500074,
                                -6.092578124999989
                            ],
                            [
                                13.346484375000017,
                                -5.863378906250006
                            ],
                            [
                                13.978515625,
                                -5.857226562500003
                            ],
                            [
                                14.398632812500068,
                                -5.89267578125002
                            ],
                            [
                                15.726953125000051,
                                -5.86386718750002
                            ],
                            [
                                16.060156250000063,
                                -5.864941406249969
                            ],
                            [
                                16.315234375000074,
                                -5.865625000000023
                            ],
                            [
                                16.431445312500045,
                                -5.90019531249996
                            ],
                            [
                                16.53710937499997,
                                -5.9658203125
                            ],
                            [
                                16.63955078125008,
                                -6.114550781250017
                            ],
                            [
                                16.697265625,
                                -6.164257812500026
                            ],
                            [
                                16.71777343750003,
                                -6.241406250000011
                            ],
                            [
                                16.70937500000008,
                                -6.471679687499943
                            ],
                            [
                                16.74296874999999,
                                -6.618457031250003
                            ],
                            [
                                16.813085937500063,
                                -6.772558593749963
                            ],
                            [
                                16.919433593750057,
                                -6.93398437499998
                            ],
                            [
                                16.98476562500005,
                                -7.257421874999977
                            ],
                            [
                                17.57958984375,
                                -8.099023437500009
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                12.255273437500023,
                                -5.746484374999994
                            ],
                            [
                                12.018359375000074,
                                -5.004296874999966
                            ],
                            [
                                12.384570312500074,
                                -4.619140625000014
                            ],
                            [
                                12.829687499999977,
                                -4.73662109374996
                            ],
                            [
                                12.573535156250017,
                                -4.996582031249986
                            ],
                            [
                                12.502734375000074,
                                -5.036914062500031
                            ],
                            [
                                12.451464843750017,
                                -5.071484374999969
                            ],
                            [
                                12.453222656250034,
                                -5.09062499999996
                            ],
                            [
                                12.52236328125008,
                                -5.148925781250028
                            ],
                            [
                                12.518945312499994,
                                -5.424609374999974
                            ],
                            [
                                12.503710937500017,
                                -5.695800781249972
                            ],
                            [
                                12.255273437500023,
                                -5.746484374999994
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Angola',
                'childNum': 2
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            20.06396484375,
                            42.547265624999994
                        ],
                        [
                            20.485449218750006,
                            42.223388671875
                        ],
                        [
                            20.566210937500017,
                            41.873681640624994
                        ],
                        [
                            20.48896484375001,
                            41.272607421874994
                        ],
                        [
                            20.964257812500023,
                            40.849902343749996
                        ],
                        [
                            20.95576171875001,
                            40.775292968749994
                        ],
                        [
                            20.881640625000017,
                            40.467919921874994
                        ],
                        [
                            20.4560546875,
                            40.065576171874994
                        ],
                        [
                            20.382421875000006,
                            39.802636718749994
                        ],
                        [
                            20.001269531250017,
                            39.709423828125
                        ],
                        [
                            19.851855468750017,
                            40.0435546875
                        ],
                        [
                            19.39814453125001,
                            40.28486328125
                        ],
                        [
                            19.44062500000001,
                            41.424755859375
                        ],
                        [
                            19.342382812500006,
                            41.869091796875
                        ],
                        [
                            19.280664062500023,
                            42.17255859375
                        ],
                        [
                            19.65449218750001,
                            42.628564453124994
                        ],
                        [
                            20.06396484375,
                            42.547265624999994
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Albania',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            56.29785156250003,
                            25.650683593750045
                        ],
                        [
                            56.38798828125002,
                            24.97919921875004
                        ],
                        [
                            56.06386718750005,
                            24.73876953125
                        ],
                        [
                            55.795703125000074,
                            24.868115234374955
                        ],
                        [
                            55.76083984375006,
                            24.24267578125
                        ],
                        [
                            55.1999023437501,
                            23.034765625000034
                        ],
                        [
                            55.185839843750074,
                            22.7041015625
                        ],
                        [
                            55.104296875000074,
                            22.621484375000023
                        ],
                        [
                            52.55507812500005,
                            22.932812499999955
                        ],
                        [
                            51.592578125000074,
                            24.07885742187503
                        ],
                        [
                            51.56835937500003,
                            24.286181640625074
                        ],
                        [
                            51.906054687500074,
                            23.98535156249997
                        ],
                        [
                            52.64824218750002,
                            24.154638671875006
                        ],
                        [
                            53.89335937500002,
                            24.077050781249994
                        ],
                        [
                            54.39707031250006,
                            24.278173828125034
                        ],
                        [
                            54.74677734375004,
                            24.810449218750023
                        ],
                        [
                            55.09814453124997,
                            25.041601562500034
                        ],
                        [
                            55.522851562499994,
                            25.498144531249977
                        ],
                        [
                            55.94121093750002,
                            25.793994140625017
                        ],
                        [
                            56.080468749999994,
                            26.06264648437505
                        ],
                        [
                            56.29785156250003,
                            25.650683593750045
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'United Arab Emirates',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                -68.65322265624994,
                                -54.85361328124999
                            ],
                            [
                                -68.62993164062499,
                                -52.65263671875004
                            ],
                            [
                                -68.27822265625002,
                                -52.98398437500004
                            ],
                            [
                                -68.00849609374995,
                                -53.5640625
                            ],
                            [
                                -67.29423828125002,
                                -54.049804687500014
                            ],
                            [
                                -65.34599609374993,
                                -54.87792968749997
                            ],
                            [
                                -66.5111328125,
                                -55.032128906249945
                            ],
                            [
                                -66.93046874999999,
                                -54.92490234375004
                            ],
                            [
                                -68.65322265624994,
                                -54.85361328124999
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -61.084716796875,
                                -23.65644531250001
                            ],
                            [
                                -60.83984375000003,
                                -23.85810546874997
                            ],
                            [
                                -59.89248046874994,
                                -24.093554687499974
                            ],
                            [
                                -59.60859375000001,
                                -24.26679687500001
                            ],
                            [
                                -59.372949218749966,
                                -24.45390625000003
                            ],
                            [
                                -58.72402343750002,
                                -24.786621093749957
                            ],
                            [
                                -58.519628906250034,
                                -24.84287109375005
                            ],
                            [
                                -58.422802734374926,
                                -24.894140625000006
                            ],
                            [
                                -58.365380859374966,
                                -24.95927734374996
                            ],
                            [
                                -57.82167968749994,
                                -25.136425781249983
                            ],
                            [
                                -57.58715820312494,
                                -25.405078125000003
                            ],
                            [
                                -57.943115234375,
                                -26.05292968750001
                            ],
                            [
                                -58.19130859375002,
                                -26.629980468749977
                            ],
                            [
                                -58.31767578125002,
                                -26.79589843750003
                            ],
                            [
                                -58.33466796875001,
                                -26.824902343749983
                            ],
                            [
                                -58.32255859374993,
                                -26.857617187499983
                            ],
                            [
                                -58.64174804687494,
                                -27.196093750000017
                            ],
                            [
                                -58.60483398437498,
                                -27.314355468750037
                            ],
                            [
                                -58.16826171874993,
                                -27.27343749999997
                            ],
                            [
                                -57.11181640625,
                                -27.47011718749998
                            ],
                            [
                                -56.437158203124966,
                                -27.553808593749977
                            ],
                            [
                                -56.16406250000003,
                                -27.321484374999983
                            ],
                            [
                                -55.789990234374926,
                                -27.416406249999966
                            ],
                            [
                                -55.426660156249994,
                                -27.00927734374997
                            ],
                            [
                                -54.934472656249994,
                                -26.70253906250001
                            ],
                            [
                                -54.677734375,
                                -26.308789062499997
                            ],
                            [
                                -54.631933593750006,
                                -26.005761718749994
                            ],
                            [
                                -54.615869140624994,
                                -25.576074218750023
                            ],
                            [
                                -54.15458984374999,
                                -25.523046874999963
                            ],
                            [
                                -53.89116210937499,
                                -25.66884765625001
                            ],
                            [
                                -53.67128906249994,
                                -26.22509765625
                            ],
                            [
                                -53.83818359375002,
                                -27.121093750000014
                            ],
                            [
                                -54.32700195312495,
                                -27.423535156249997
                            ],
                            [
                                -54.82910156250003,
                                -27.55058593750003
                            ],
                            [
                                -55.10151367187501,
                                -27.866796874999963
                            ],
                            [
                                -55.72548828125002,
                                -28.20410156250003
                            ],
                            [
                                -56.938623046874994,
                                -29.594824218750034
                            ],
                            [
                                -57.60888671875003,
                                -30.187792968750045
                            ],
                            [
                                -57.87250976562501,
                                -30.591015625000026
                            ],
                            [
                                -57.81059570312499,
                                -30.85859375000001
                            ],
                            [
                                -58.18901367187499,
                                -31.924218750000037
                            ],
                            [
                                -58.12304687499997,
                                -32.32187499999996
                            ],
                            [
                                -58.201171875,
                                -32.471679687500014
                            ],
                            [
                                -58.219970703125,
                                -32.563964843749986
                            ],
                            [
                                -58.17099609374998,
                                -32.95927734374996
                            ],
                            [
                                -58.424462890624994,
                                -33.11152343749998
                            ],
                            [
                                -58.54721679687498,
                                -33.66347656249998
                            ],
                            [
                                -58.28334960937494,
                                -34.68349609375005
                            ],
                            [
                                -57.303662109374926,
                                -35.188476562499986
                            ],
                            [
                                -57.15888671875001,
                                -35.505957031250006
                            ],
                            [
                                -57.35390624999994,
                                -35.72031249999998
                            ],
                            [
                                -57.26499023437495,
                                -36.14414062499999
                            ],
                            [
                                -56.698095703125006,
                                -36.42646484375004
                            ],
                            [
                                -56.67202148437494,
                                -36.85126953124998
                            ],
                            [
                                -57.39575195312494,
                                -37.74462890625
                            ],
                            [
                                -57.546972656250034,
                                -38.085644531250026
                            ],
                            [
                                -58.17919921874994,
                                -38.435839843750045
                            ],
                            [
                                -59.82832031250001,
                                -38.83818359375003
                            ],
                            [
                                -61.112207031249994,
                                -38.99296875000003
                            ],
                            [
                                -62.06689453125,
                                -38.91914062500001
                            ],
                            [
                                -62.33808593749998,
                                -39.1505859375
                            ],
                            [
                                -62.053662109374955,
                                -39.373828125
                            ],
                            [
                                -62.28691406249996,
                                -39.89531250000002
                            ],
                            [
                                -62.39501953124997,
                                -40.89082031249997
                            ],
                            [
                                -62.95903320312493,
                                -41.10966796875006
                            ],
                            [
                                -63.77299804687493,
                                -41.150000000000006
                            ],
                            [
                                -64.85297851562495,
                                -40.81376953124999
                            ],
                            [
                                -65.13339843749998,
                                -40.88066406250003
                            ],
                            [
                                -65.05908203125003,
                                -41.96992187499998
                            ],
                            [
                                -64.42041015625003,
                                -42.43378906249998
                            ],
                            [
                                -63.59589843750001,
                                -42.40654296875003
                            ],
                            [
                                -63.69248046874998,
                                -42.80527343749999
                            ],
                            [
                                -64.13066406249996,
                                -42.86142578124998
                            ],
                            [
                                -64.65048828125,
                                -42.53144531249998
                            ],
                            [
                                -65.02690429687496,
                                -42.75888671874996
                            ],
                            [
                                -64.43222656250003,
                                -43.059179687500034
                            ],
                            [
                                -64.98554687499995,
                                -43.29355468749999
                            ],
                            [
                                -65.28359375000002,
                                -43.62998046874996
                            ],
                            [
                                -65.36127929687495,
                                -44.47734375000002
                            ],
                            [
                                -65.64760742187502,
                                -44.661425781250045
                            ],
                            [
                                -65.63876953125,
                                -45.0078125
                            ],
                            [
                                -66.19013671874995,
                                -44.96474609375002
                            ],
                            [
                                -66.94140625,
                                -45.25732421875003
                            ],
                            [
                                -67.556640625,
                                -45.97011718750002
                            ],
                            [
                                -67.56337890624997,
                                -46.34541015625001
                            ],
                            [
                                -66.77685546874994,
                                -47.005859375
                            ],
                            [
                                -65.99853515625,
                                -47.09374999999997
                            ],
                            [
                                -65.73808593749999,
                                -47.34492187499998
                            ],
                            [
                                -65.81005859374997,
                                -47.941113281250026
                            ],
                            [
                                -67.03310546875002,
                                -48.627734375000024
                            ],
                            [
                                -67.68486328125002,
                                -49.2466796875
                            ],
                            [
                                -67.78349609374996,
                                -49.85888671875002
                            ],
                            [
                                -68.939453125,
                                -50.382324218749986
                            ],
                            [
                                -69.23515625000002,
                                -50.95058593750003
                            ],
                            [
                                -69.03251953124993,
                                -51.63623046875
                            ],
                            [
                                -68.443359375,
                                -52.35664062500004
                            ],
                            [
                                -69.96025390624993,
                                -52.00820312500002
                            ],
                            [
                                -71.91865234374995,
                                -51.98955078125004
                            ],
                            [
                                -72.40766601562501,
                                -51.54082031250002
                            ],
                            [
                                -72.27631835937498,
                                -50.910253906249984
                            ],
                            [
                                -72.50981445312496,
                                -50.607519531250034
                            ],
                            [
                                -73.15292968749998,
                                -50.73828125000003
                            ],
                            [
                                -73.50126953124996,
                                -50.125292968750024
                            ],
                            [
                                -73.57626953124998,
                                -49.582910156250016
                            ],
                            [
                                -72.61440429687494,
                                -48.79287109375
                            ],
                            [
                                -72.35473632812497,
                                -48.36582031250005
                            ],
                            [
                                -72.517919921875,
                                -47.87636718749998
                            ],
                            [
                                -72.34594726562497,
                                -47.49267578124997
                            ],
                            [
                                -71.90498046875001,
                                -47.201660156250014
                            ],
                            [
                                -71.94023437499999,
                                -46.831250000000054
                            ],
                            [
                                -71.69521484375,
                                -46.57841796875006
                            ],
                            [
                                -71.87568359374998,
                                -46.16054687500001
                            ],
                            [
                                -71.77265624999995,
                                -45.724414062499974
                            ],
                            [
                                -71.35375976562497,
                                -45.23046874999997
                            ],
                            [
                                -71.5962890625,
                                -44.97919921875004
                            ],
                            [
                                -71.26113281250002,
                                -44.763085937499966
                            ],
                            [
                                -71.21259765624998,
                                -44.44121093750003
                            ],
                            [
                                -71.82001953124993,
                                -44.38310546875
                            ],
                            [
                                -71.68007812500002,
                                -43.92958984374998
                            ],
                            [
                                -71.90498046875001,
                                -43.44013671875001
                            ],
                            [
                                -71.781494140625,
                                -43.16679687500002
                            ],
                            [
                                -72.14643554687498,
                                -42.990039062499974
                            ],
                            [
                                -72.12460937499998,
                                -42.298339843750014
                            ],
                            [
                                -71.76093749999998,
                                -42.101464843749966
                            ],
                            [
                                -71.91127929687497,
                                -41.650390624999986
                            ],
                            [
                                -71.93212890624994,
                                -40.69169921874999
                            ],
                            [
                                -71.69531250000003,
                                -40.33525390625003
                            ],
                            [
                                -71.71992187499995,
                                -39.63525390624997
                            ],
                            [
                                -71.53945312499997,
                                -39.60244140624995
                            ],
                            [
                                -71.42558593749996,
                                -38.98564453125006
                            ],
                            [
                                -70.95161132812493,
                                -38.73847656249997
                            ],
                            [
                                -71.028173828125,
                                -38.041210937500026
                            ],
                            [
                                -71.16757812499998,
                                -37.76230468749996
                            ],
                            [
                                -71.05551757812498,
                                -36.52373046874996
                            ],
                            [
                                -70.40478515625,
                                -36.06171874999998
                            ],
                            [
                                -70.47041015624995,
                                -35.32617187499997
                            ],
                            [
                                -70.28994140624997,
                                -34.732812499999966
                            ],
                            [
                                -69.85244140625,
                                -34.224316406250026
                            ],
                            [
                                -69.79775390624997,
                                -33.398632812500026
                            ],
                            [
                                -70.01982421874999,
                                -33.27148437499997
                            ],
                            [
                                -70.16962890624995,
                                -32.471679687500014
                            ],
                            [
                                -70.585205078125,
                                -31.569433593749963
                            ],
                            [
                                -70.51958007812493,
                                -31.1484375
                            ],
                            [
                                -70.16142578124999,
                                -30.44023437499996
                            ],
                            [
                                -69.95634765624996,
                                -30.35820312500003
                            ],
                            [
                                -69.92763671874997,
                                -29.76914062500002
                            ],
                            [
                                -70.02680664062501,
                                -29.324023437500017
                            ],
                            [
                                -69.82788085937497,
                                -29.10322265624997
                            ],
                            [
                                -69.65693359374995,
                                -28.413574218749986
                            ],
                            [
                                -69.17441406249998,
                                -27.924707031250037
                            ],
                            [
                                -68.84633789062494,
                                -27.153710937499994
                            ],
                            [
                                -68.34599609374996,
                                -27.02792968750005
                            ],
                            [
                                -68.58115234375,
                                -26.518359374999974
                            ],
                            [
                                -68.41450195312498,
                                -26.153710937500023
                            ],
                            [
                                -68.60029296874998,
                                -25.48564453124999
                            ],
                            [
                                -68.38422851562495,
                                -25.091894531249977
                            ],
                            [
                                -68.56201171875,
                                -24.83769531249996
                            ],
                            [
                                -68.25029296875002,
                                -24.391992187500023
                            ],
                            [
                                -67.35620117187503,
                                -24.033789062499963
                            ],
                            [
                                -67.00878906249994,
                                -23.00136718750005
                            ],
                            [
                                -67.19487304687493,
                                -22.821679687500037
                            ],
                            [
                                -66.99111328125,
                                -22.509863281250006
                            ],
                            [
                                -66.71171874999999,
                                -22.216308593749986
                            ],
                            [
                                -66.36518554687501,
                                -22.113769531249957
                            ],
                            [
                                -66.32246093750001,
                                -22.053125000000037
                            ],
                            [
                                -66.28212890624997,
                                -21.94746093750001
                            ],
                            [
                                -66.24760742187496,
                                -21.83046874999998
                            ],
                            [
                                -66.22016601562495,
                                -21.802539062499974
                            ],
                            [
                                -66.174658203125,
                                -21.805664062499986
                            ],
                            [
                                -66.09858398437495,
                                -21.83505859375002
                            ],
                            [
                                -66.05859375,
                                -21.87949218750002
                            ],
                            [
                                -65.86015624999999,
                                -22.019726562499983
                            ],
                            [
                                -65.77104492187493,
                                -22.099609375000014
                            ],
                            [
                                -65.68618164062497,
                                -22.11025390625005
                            ],
                            [
                                -65.05781249999995,
                                -22.102734375000026
                            ],
                            [
                                -64.60551757812499,
                                -22.228808593750045
                            ],
                            [
                                -64.52363281250001,
                                -22.37158203125
                            ],
                            [
                                -64.47773437499998,
                                -22.485351562499986
                            ],
                            [
                                -64.44550781249998,
                                -22.585351562500023
                            ],
                            [
                                -64.37397460937498,
                                -22.761035156250017
                            ],
                            [
                                -64.32529296875,
                                -22.82763671875
                            ],
                            [
                                -64.30791015624993,
                                -22.795312499999994
                            ],
                            [
                                -64.26640625000002,
                                -22.60332031249996
                            ],
                            [
                                -63.97612304687502,
                                -22.072558593750003
                            ],
                            [
                                -63.92167968749993,
                                -22.028613281250017
                            ],
                            [
                                -63.81865234374999,
                                -22.00546875000002
                            ],
                            [
                                -62.843359375000006,
                                -21.997265625000026
                            ],
                            [
                                -62.62597656250003,
                                -22.29042968749998
                            ],
                            [
                                -62.54155273437496,
                                -22.349609374999957
                            ],
                            [
                                -62.37250976562498,
                                -22.439160156249997
                            ],
                            [
                                -61.798535156249955,
                                -23.182031249999994
                            ],
                            [
                                -61.084716796875,
                                -23.65644531250001
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Argentina',
                'childNum': 2
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            46.490624999999994,
                            38.90668945312498
                        ],
                        [
                            46.1144531250001,
                            38.877783203125034
                        ],
                        [
                            45.76630859375004,
                            39.37846679687499
                        ],
                        [
                            45.03164062500005,
                            39.76513671874997
                        ],
                        [
                            44.76826171875004,
                            39.70351562500005
                        ],
                        [
                            44.28925781250004,
                            40.040380859375006
                        ],
                        [
                            43.79169921875004,
                            40.07026367187498
                        ],
                        [
                            43.56933593750003,
                            40.48237304687498
                        ],
                        [
                            43.72265624999997,
                            40.71953124999999
                        ],
                        [
                            43.43945312500003,
                            41.10712890625001
                        ],
                        [
                            44.077246093750006,
                            41.182519531249994
                        ],
                        [
                            44.227343750000074,
                            41.213330078124955
                        ],
                        [
                            44.473046874999994,
                            41.191015625000034
                        ],
                        [
                            44.56484375000005,
                            41.20820312499998
                        ],
                        [
                            44.841406250000006,
                            41.21137695312501
                        ],
                        [
                            44.84853515625005,
                            41.22016601562501
                        ],
                        [
                            44.810937499999994,
                            41.248583984375045
                        ],
                        [
                            44.81132812500002,
                            41.259375000000006
                        ],
                        [
                            44.97587890625002,
                            41.277490234374966
                        ],
                        [
                            45.001367187499994,
                            41.29096679687498
                        ],
                        [
                            45.58750000000006,
                            40.846923828125
                        ],
                        [
                            45.37617187500004,
                            40.63808593750002
                        ],
                        [
                            45.96464843750002,
                            40.233789062499966
                        ],
                        [
                            45.789648437500006,
                            39.88110351562497
                        ],
                        [
                            46.5500000000001,
                            39.20141601562497
                        ],
                        [
                            46.490624999999994,
                            38.90668945312498
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Armenia',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            69.18486328125002,
                            -49.10957031250004
                        ],
                        [
                            69.915625,
                            -49.348535156249966
                        ],
                        [
                            69.612890625,
                            -49.65097656250003
                        ],
                        [
                            68.87207031249997,
                            -49.44433593750003
                        ],
                        [
                            69.18486328125002,
                            -49.10957031250004
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'France',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                145.04296875000003,
                                -40.78671874999999
                            ],
                            [
                                145.28300781250002,
                                -40.76992187500002
                            ],
                            [
                                146.31748046875006,
                                -41.16347656250001
                            ],
                            [
                                146.98984375000006,
                                -40.99238281249997
                            ],
                            [
                                147.45478515625004,
                                -41.00166015624998
                            ],
                            [
                                147.96875000000003,
                                -40.779589843750045
                            ],
                            [
                                148.29287109375,
                                -40.947070312499974
                            ],
                            [
                                148.30146484375004,
                                -42.03994140624995
                            ],
                            [
                                147.92441406250006,
                                -42.5724609375
                            ],
                            [
                                147.980859375,
                                -43.157031249999974
                            ],
                            [
                                147.40800781250002,
                                -42.89384765625
                            ],
                            [
                                146.87392578125,
                                -43.61250000000004
                            ],
                            [
                                146.04316406250004,
                                -43.547167968749974
                            ],
                            [
                                145.26816406250006,
                                -42.54433593749995
                            ],
                            [
                                145.23818359375,
                                -42.01962890624999
                            ],
                            [
                                144.77792968750012,
                                -41.41884765624998
                            ],
                            [
                                144.64609375000006,
                                -40.98085937500001
                            ],
                            [
                                145.04296875000003,
                                -40.78671874999999
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                137.59648437500007,
                                -35.73867187499998
                            ],
                            [
                                137.44843750000004,
                                -36.07480468749999
                            ],
                            [
                                136.7550781250001,
                                -36.03310546875002
                            ],
                            [
                                136.63867187499997,
                                -35.74882812500002
                            ],
                            [
                                137.33408203125006,
                                -35.59248046875004
                            ],
                            [
                                137.59648437500007,
                                -35.73867187499998
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                136.71464843750002,
                                -13.803906249999983
                            ],
                            [
                                136.89433593750002,
                                -14.293066406249977
                            ],
                            [
                                136.36328125000003,
                                -14.228906249999966
                            ],
                            [
                                136.42470703125,
                                -13.864843749999963
                            ],
                            [
                                136.71464843750002,
                                -13.803906249999983
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                130.6188476562501,
                                -11.376074218749991
                            ],
                            [
                                131.26826171875004,
                                -11.18984374999998
                            ],
                            [
                                131.53857421874997,
                                -11.436914062500037
                            ],
                            [
                                130.95097656250007,
                                -11.926464843750026
                            ],
                            [
                                130.644921875,
                                -11.742382812500011
                            ],
                            [
                                130.6188476562501,
                                -11.376074218749991
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                143.17890625000004,
                                -11.954492187499966
                            ],
                            [
                                143.11025390625,
                                -12.303515625000017
                            ],
                            [
                                143.40156250000004,
                                -12.639941406249989
                            ],
                            [
                                143.5866210937501,
                                -13.443652343750031
                            ],
                            [
                                143.54843750000012,
                                -13.74101562499996
                            ],
                            [
                                143.75634765625003,
                                -14.348828124999969
                            ],
                            [
                                143.96181640625005,
                                -14.462890625000028
                            ],
                            [
                                144.473046875,
                                -14.231835937500023
                            ],
                            [
                                144.64804687500006,
                                -14.492480468750017
                            ],
                            [
                                145.28769531250006,
                                -14.943164062499989
                            ],
                            [
                                145.27158203125006,
                                -15.476660156249963
                            ],
                            [
                                145.45800781250003,
                                -16.05644531249996
                            ],
                            [
                                145.42607421875002,
                                -16.406152343749966
                            ],
                            [
                                145.90195312500006,
                                -17.070214843749994
                            ],
                            [
                                146.12587890625005,
                                -17.63525390625
                            ],
                            [
                                146.03222656249997,
                                -18.272851562500037
                            ],
                            [
                                146.3332031250001,
                                -18.55371093749997
                            ],
                            [
                                146.48115234375004,
                                -19.07871093749999
                            ],
                            [
                                147.13876953125006,
                                -19.39316406250002
                            ],
                            [
                                147.41855468750012,
                                -19.37812499999997
                            ],
                            [
                                147.91562500000006,
                                -19.86923828125002
                            ],
                            [
                                148.75937500000006,
                                -20.28955078125003
                            ],
                            [
                                148.68369140625012,
                                -20.58017578124999
                            ],
                            [
                                149.20488281250007,
                                -21.125097656249977
                            ],
                            [
                                149.70390625000002,
                                -22.440527343750006
                            ],
                            [
                                150.07617187500003,
                                -22.16445312499998
                            ],
                            [
                                150.54130859375002,
                                -22.55908203125
                            ],
                            [
                                150.76386718750004,
                                -22.576171875000014
                            ],
                            [
                                150.84316406250005,
                                -23.4580078125
                            ],
                            [
                                151.50078125000002,
                                -24.01240234374997
                            ],
                            [
                                151.902734375,
                                -24.200976562500017
                            ],
                            [
                                152.12988281250003,
                                -24.59755859374998
                            ],
                            [
                                152.45634765625007,
                                -24.802441406249983
                            ],
                            [
                                152.91347656250005,
                                -25.432128906250014
                            ],
                            [
                                153.16494140625,
                                -25.964160156250045
                            ],
                            [
                                153.0841796875001,
                                -26.303808593749963
                            ],
                            [
                                153.11679687500006,
                                -27.194433593750034
                            ],
                            [
                                153.57568359375003,
                                -28.24052734374999
                            ],
                            [
                                153.60458984375006,
                                -28.854492187500014
                            ],
                            [
                                153.348046875,
                                -29.29042968750001
                            ],
                            [
                                153.27236328125005,
                                -29.89248046875001
                            ],
                            [
                                153.03056640625002,
                                -30.563378906249994
                            ],
                            [
                                152.94394531250012,
                                -31.43486328124999
                            ],
                            [
                                152.5592773437501,
                                -32.045703125
                            ],
                            [
                                152.4704101562501,
                                -32.439062500000034
                            ],
                            [
                                151.812890625,
                                -32.90107421875001
                            ],
                            [
                                151.4837890625,
                                -33.3474609375
                            ],
                            [
                                151.23154296875006,
                                -34.02968749999998
                            ],
                            [
                                150.96035156250005,
                                -34.29707031250001
                            ],
                            [
                                150.69033203125,
                                -35.177734375000014
                            ],
                            [
                                150.19531249999997,
                                -35.83359374999996
                            ],
                            [
                                149.96025390625002,
                                -36.845507812499974
                            ],
                            [
                                149.93271484375012,
                                -37.528515625000026
                            ],
                            [
                                149.29843750000012,
                                -37.802148437499994
                            ],
                            [
                                148.26250000000002,
                                -37.830664062500034
                            ],
                            [
                                147.39560546875006,
                                -38.21914062499995
                            ],
                            [
                                146.8568359375,
                                -38.663476562499966
                            ],
                            [
                                145.93535156250002,
                                -38.90175781250002
                            ],
                            [
                                145.79082031250007,
                                -38.66699218749997
                            ],
                            [
                                144.6652343750001,
                                -38.20996093750003
                            ],
                            [
                                143.53896484375005,
                                -38.82089843749998
                            ],
                            [
                                142.61210937500007,
                                -38.451660156249986
                            ],
                            [
                                141.725,
                                -38.27138671875002
                            ],
                            [
                                141.42421875,
                                -38.36347656250004
                            ],
                            [
                                141.0109375000001,
                                -38.07695312500003
                            ],
                            [
                                140.62724609375007,
                                -38.02841796874999
                            ],
                            [
                                139.78427734375012,
                                -37.24580078124998
                            ],
                            [
                                139.85732421875,
                                -36.662109375
                            ],
                            [
                                139.54873046875,
                                -36.0966796875
                            ],
                            [
                                139.03769531250006,
                                -35.68925781249996
                            ],
                            [
                                138.18437500000002,
                                -35.612695312499994
                            ],
                            [
                                138.5111328125,
                                -35.02441406249996
                            ],
                            [
                                138.48994140625004,
                                -34.76357421875002
                            ],
                            [
                                138.0892578125,
                                -34.16982421875002
                            ],
                            [
                                137.69169921875002,
                                -35.14296875000004
                            ],
                            [
                                136.88359375000007,
                                -35.23974609375004
                            ],
                            [
                                137.01425781250012,
                                -34.91582031250003
                            ],
                            [
                                137.39101562500005,
                                -34.91328124999997
                            ],
                            [
                                137.49384765625004,
                                -34.16113281250003
                            ],
                            [
                                137.9318359375001,
                                -33.57910156250003
                            ],
                            [
                                137.85234375000007,
                                -33.20078124999996
                            ],
                            [
                                137.44228515625,
                                -33.1935546875
                            ],
                            [
                                137.23730468750003,
                                -33.62949218749999
                            ],
                            [
                                136.43066406249997,
                                -34.02998046875004
                            ],
                            [
                                135.64755859375006,
                                -34.93964843750001
                            ],
                            [
                                135.18544921875005,
                                -33.906738281249986
                            ],
                            [
                                134.79101562499997,
                                -33.32832031250001
                            ],
                            [
                                134.30126953124997,
                                -33.16503906249996
                            ],
                            [
                                134.10039062500007,
                                -32.748632812500034
                            ],
                            [
                                134.23417968750007,
                                -32.54853515625004
                            ],
                            [
                                133.55136718750012,
                                -32.182910156249974
                            ],
                            [
                                133.21210937500004,
                                -32.18378906249998
                            ],
                            [
                                132.75742187500012,
                                -31.956249999999983
                            ],
                            [
                                132.21464843750002,
                                -32.00712890624996
                            ],
                            [
                                131.72119140625003,
                                -31.696289062499957
                            ],
                            [
                                131.14365234375006,
                                -31.49570312500005
                            ],
                            [
                                130.78300781250002,
                                -31.604003906249986
                            ],
                            [
                                130.12978515625,
                                -31.579101562499986
                            ],
                            [
                                128.94619140625,
                                -31.702636718750014
                            ],
                            [
                                128.06767578125002,
                                -32.06650390624998
                            ],
                            [
                                127.31982421874997,
                                -32.264062499999994
                            ],
                            [
                                125.91718750000004,
                                -32.296972656250034
                            ],
                            [
                                124.75878906250003,
                                -32.882714843749994
                            ],
                            [
                                124.12607421875006,
                                -33.129394531249986
                            ],
                            [
                                123.65039062499997,
                                -33.83632812500002
                            ],
                            [
                                123.20761718750012,
                                -33.988281249999986
                            ],
                            [
                                122.7775390625001,
                                -33.890820312500026
                            ],
                            [
                                121.40507812500007,
                                -33.826757812500034
                            ],
                            [
                                119.85410156250012,
                                -33.97470703124998
                            ],
                            [
                                119.45058593750005,
                                -34.368261718750034
                            ],
                            [
                                118.89531250000007,
                                -34.47988281250004
                            ],
                            [
                                118.13554687500002,
                                -34.98662109374999
                            ],
                            [
                                117.58193359375005,
                                -35.09775390624998
                            ],
                            [
                                116.51718750000012,
                                -34.98789062499998
                            ],
                            [
                                115.98671875000005,
                                -34.795019531250034
                            ],
                            [
                                115.7262695312501,
                                -34.52607421875004
                            ],
                            [
                                115.00878906250003,
                                -34.25585937499997
                            ],
                            [
                                115.18164062499997,
                                -33.643457031249994
                            ],
                            [
                                115.5153320312501,
                                -33.53134765624998
                            ],
                            [
                                115.68300781250005,
                                -33.19287109375003
                            ],
                            [
                                115.6984375000001,
                                -31.694531250000054
                            ],
                            [
                                115.17685546875006,
                                -30.80800781250001
                            ],
                            [
                                114.99453125000005,
                                -30.216210937499966
                            ],
                            [
                                114.95898437499997,
                                -29.433593749999957
                            ],
                            [
                                114.53740234375007,
                                -28.542871093750037
                            ],
                            [
                                114.16513671875012,
                                -28.08066406250002
                            ],
                            [
                                114.02812500000007,
                                -27.34726562499999
                            ],
                            [
                                113.58164062500006,
                                -26.558105468749986
                            ],
                            [
                                113.85283203125007,
                                -26.33212890625005
                            ],
                            [
                                113.58906250000004,
                                -26.098632812499986
                            ],
                            [
                                113.539453125,
                                -25.625195312499997
                            ],
                            [
                                114.21572265625,
                                -26.289453124999966
                            ],
                            [
                                114.2142578125,
                                -25.851562500000014
                            ],
                            [
                                113.67080078125,
                                -24.97705078125003
                            ],
                            [
                                113.41767578125004,
                                -24.435644531250034
                            ],
                            [
                                113.5529296875001,
                                -23.732812500000023
                            ],
                            [
                                113.7570312500001,
                                -23.418164062500054
                            ],
                            [
                                113.68281250000004,
                                -22.637792968749963
                            ],
                            [
                                113.9583984375,
                                -21.93916015625001
                            ],
                            [
                                114.16386718750002,
                                -22.32333984375002
                            ],
                            [
                                114.37773437500007,
                                -22.341503906249997
                            ],
                            [
                                114.85908203125004,
                                -21.73593749999999
                            ],
                            [
                                115.45615234375012,
                                -21.49169921874997
                            ],
                            [
                                116.0109375000001,
                                -21.030371093749963
                            ],
                            [
                                116.7067382812501,
                                -20.653808593749986
                            ],
                            [
                                117.40625000000003,
                                -20.72119140625003
                            ],
                            [
                                118.19921875000003,
                                -20.37519531249997
                            ],
                            [
                                118.75146484374997,
                                -20.261914062499983
                            ],
                            [
                                119.10449218749997,
                                -19.995312500000026
                            ],
                            [
                                119.58593750000003,
                                -20.038281249999997
                            ],
                            [
                                120.87841796874997,
                                -19.665039062499986
                            ],
                            [
                                121.49355468750005,
                                -19.106445312499957
                            ],
                            [
                                121.83378906250002,
                                -18.477050781249986
                            ],
                            [
                                122.23740234375006,
                                -17.968554687500045
                            ],
                            [
                                122.16025390625006,
                                -17.313671875000026
                            ],
                            [
                                122.72041015625004,
                                -16.78769531249999
                            ],
                            [
                                122.97070312499997,
                                -16.436816406250003
                            ],
                            [
                                123.52519531250007,
                                -17.485742187499994
                            ],
                            [
                                123.77812500000007,
                                -16.867773437499963
                            ],
                            [
                                123.4904296875001,
                                -16.49072265624997
                            ],
                            [
                                124.5768554687501,
                                -16.11367187499998
                            ],
                            [
                                124.38164062500002,
                                -15.758203125000037
                            ],
                            [
                                124.83906250000004,
                                -15.160742187500006
                            ],
                            [
                                125.24326171875006,
                                -14.944531250000011
                            ],
                            [
                                125.17871093749997,
                                -14.714746093749994
                            ],
                            [
                                125.68125000000012,
                                -14.38798828124996
                            ],
                            [
                                126.0207031250001,
                                -14.494531249999994
                            ],
                            [
                                126.11132812499997,
                                -14.114062500000017
                            ],
                            [
                                126.5697265625,
                                -14.160937499999974
                            ],
                            [
                                126.90322265625,
                                -13.744140624999972
                            ],
                            [
                                127.67285156249997,
                                -14.195117187500003
                            ],
                            [
                                128.19941406250004,
                                -14.751757812499989
                            ],
                            [
                                129.45898437499997,
                                -14.933203125000006
                            ],
                            [
                                129.75351562500006,
                                -14.789550781249957
                            ],
                            [
                                129.37871093750002,
                                -14.39248046874998
                            ],
                            [
                                129.70986328125,
                                -13.979980468749972
                            ],
                            [
                                129.83886718749997,
                                -13.572949218749997
                            ],
                            [
                                130.25976562500003,
                                -13.30224609375
                            ],
                            [
                                130.1681640625001,
                                -12.957421875000009
                            ],
                            [
                                130.67236328124997,
                                -12.40693359375004
                            ],
                            [
                                131.43828125000002,
                                -12.27695312500002
                            ],
                            [
                                132.37207031250003,
                                -12.239160156249966
                            ],
                            [
                                132.58378906250002,
                                -12.110253906249994
                            ],
                            [
                                132.6828125000001,
                                -11.505566406249997
                            ],
                            [
                                133.02490234374997,
                                -11.452832031249997
                            ],
                            [
                                133.18525390625004,
                                -11.705664062499991
                            ],
                            [
                                133.90419921875,
                                -11.832031249999972
                            ],
                            [
                                134.23710937500002,
                                -12.00771484374998
                            ],
                            [
                                134.73027343750002,
                                -11.984375000000014
                            ],
                            [
                                135.2179687500001,
                                -12.221679687499957
                            ],
                            [
                                135.7884765625,
                                -11.907031249999974
                            ],
                            [
                                136.08183593750007,
                                -12.422460937500006
                            ],
                            [
                                136.5402343750001,
                                -11.957617187499977
                            ],
                            [
                                136.94746093750004,
                                -12.34990234374996
                            ],
                            [
                                136.53701171875,
                                -12.784277343749991
                            ],
                            [
                                136.59433593750012,
                                -13.003808593750051
                            ],
                            [
                                135.92734375000012,
                                -13.304296874999977
                            ],
                            [
                                135.98955078125002,
                                -13.810156250000006
                            ],
                            [
                                135.88339843750006,
                                -14.153124999999974
                            ],
                            [
                                135.40517578125005,
                                -14.758203124999966
                            ],
                            [
                                135.53076171874997,
                                -15.000390625000023
                            ],
                            [
                                136.20537109375002,
                                -15.403417968749963
                            ],
                            [
                                136.78466796874997,
                                -15.89423828125004
                            ],
                            [
                                137.00214843750004,
                                -15.878320312499994
                            ],
                            [
                                137.70371093750006,
                                -16.233007812499963
                            ],
                            [
                                138.24501953125005,
                                -16.718359374999977
                            ],
                            [
                                139.00986328125006,
                                -16.899316406249994
                            ],
                            [
                                139.2484375,
                                -17.328613281249957
                            ],
                            [
                                140.03583984375004,
                                -17.702636718749957
                            ],
                            [
                                140.51113281250005,
                                -17.62451171875003
                            ],
                            [
                                140.83046875,
                                -17.414453125000037
                            ],
                            [
                                141.21914062500005,
                                -16.646191406250026
                            ],
                            [
                                141.41191406250007,
                                -16.069531250000054
                            ],
                            [
                                141.45156250000005,
                                -15.605273437499974
                            ],
                            [
                                141.62548828124997,
                                -15.056640625000014
                            ],
                            [
                                141.52294921875003,
                                -14.470117187499994
                            ],
                            [
                                141.59433593750006,
                                -14.152832031250014
                            ],
                            [
                                141.47255859375,
                                -13.797558593750011
                            ],
                            [
                                141.64541015625,
                                -13.259082031250003
                            ],
                            [
                                141.67773437500003,
                                -12.491406250000011
                            ],
                            [
                                141.95156250000005,
                                -11.896191406249997
                            ],
                            [
                                142.168359375,
                                -10.946582031249974
                            ],
                            [
                                142.45644531250005,
                                -10.707324218749989
                            ],
                            [
                                142.83681640625,
                                -11.306933593750031
                            ],
                            [
                                142.87255859374997,
                                -11.821386718750034
                            ],
                            [
                                143.17890625000004,
                                -11.954492187499966
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Australia',
                'childNum': 5
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            16.953125,
                            48.598828125
                        ],
                        [
                            17.147363281250023,
                            48.00595703125
                        ],
                        [
                            16.434375000000017,
                            47.367431640625
                        ],
                        [
                            16.453417968750017,
                            47.006787109375
                        ],
                        [
                            16.093066406250017,
                            46.86328125
                        ],
                        [
                            15.957617187500006,
                            46.677636718749994
                        ],
                        [
                            15.000683593750011,
                            46.6259765625
                        ],
                        [
                            14.503515625000006,
                            46.417041015624996
                        ],
                        [
                            13.700000000000017,
                            46.520263671875
                        ],
                        [
                            13.490039062500017,
                            46.555566406249994
                        ],
                        [
                            13.3515625,
                            46.557910156249996
                        ],
                        [
                            13.168750000000017,
                            46.572656249999994
                        ],
                        [
                            12.388281250000006,
                            46.70263671875
                        ],
                        [
                            11.775683593750017,
                            46.986083984375
                        ],
                        [
                            11.025097656250011,
                            46.79697265625
                        ],
                        [
                            10.993261718750006,
                            46.777001953124994
                        ],
                        [
                            10.927343750000006,
                            46.769482421875
                        ],
                        [
                            10.828906250000017,
                            46.775244140625
                        ],
                        [
                            10.759765625,
                            46.793310546875
                        ],
                        [
                            10.689257812500017,
                            46.84638671875
                        ],
                        [
                            10.579785156250011,
                            46.8537109375
                        ],
                        [
                            10.479394531250023,
                            46.855126953124994
                        ],
                        [
                            10.452832031250011,
                            46.86494140625
                        ],
                        [
                            10.45458984375,
                            46.8994140625
                        ],
                        [
                            10.414941406250023,
                            46.964404296874996
                        ],
                        [
                            10.349414062500017,
                            46.984765624999994
                        ],
                        [
                            9.996875000000017,
                            46.8853515625
                        ],
                        [
                            9.580273437500011,
                            47.057373046875
                        ],
                        [
                            9.527539062500011,
                            47.270751953125
                        ],
                        [
                            9.524023437500006,
                            47.524218749999996
                        ],
                        [
                            9.748925781250023,
                            47.575537109375
                        ],
                        [
                            10.034082031250023,
                            47.473583984375
                        ],
                        [
                            10.07421875,
                            47.428515624999996
                        ],
                        [
                            10.066308593750023,
                            47.393359374999996
                        ],
                        [
                            10.096484375000017,
                            47.37958984375
                        ],
                        [
                            10.200292968750006,
                            47.363427734374994
                        ],
                        [
                            10.312792968750017,
                            47.313427734375
                        ],
                        [
                            10.403906250000006,
                            47.4169921875
                        ],
                        [
                            10.430371093750011,
                            47.541064453124996
                        ],
                        [
                            10.439453125,
                            47.551562499999996
                        ],
                        [
                            10.482812500000023,
                            47.541796874999996
                        ],
                        [
                            10.65869140625,
                            47.547216796875
                        ],
                        [
                            10.873046875,
                            47.52021484375
                        ],
                        [
                            11.297949218750006,
                            47.42490234375
                        ],
                        [
                            11.716796875,
                            47.58349609375
                        ],
                        [
                            12.685839843750017,
                            47.669335937499994
                        ],
                        [
                            13.014355468750011,
                            47.478076171874996
                        ],
                        [
                            12.953515625000023,
                            47.890625
                        ],
                        [
                            13.215234375000023,
                            48.301904296874994
                        ],
                        [
                            13.322851562500006,
                            48.33125
                        ],
                        [
                            13.374609375000006,
                            48.361376953124996
                        ],
                        [
                            13.409375000000011,
                            48.394140625
                        ],
                        [
                            13.459863281250023,
                            48.56455078125
                        ],
                        [
                            13.4716796875,
                            48.571826171874996
                        ],
                        [
                            13.486621093750017,
                            48.581835937499996
                        ],
                        [
                            13.675195312500023,
                            48.523046875
                        ],
                        [
                            13.785351562500011,
                            48.587451171874996
                        ],
                        [
                            13.798828125,
                            48.6216796875
                        ],
                        [
                            13.802929687500011,
                            48.747509765625
                        ],
                        [
                            13.814746093750017,
                            48.766943359375
                        ],
                        [
                            14.049121093750017,
                            48.602490234375
                        ],
                        [
                            14.691308593750023,
                            48.59921875
                        ],
                        [
                            14.97216796875,
                            48.983935546874996
                        ],
                        [
                            16.057226562500006,
                            48.754785156249994
                        ],
                        [
                            16.477929687500023,
                            48.800097656249996
                        ],
                        [
                            16.953125,
                            48.598828125
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Austria',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                46.1144531250001,
                                38.877783203125034
                            ],
                            [
                                45.4796875000001,
                                39.006249999999994
                            ],
                            [
                                44.81718750000002,
                                39.65043945312496
                            ],
                            [
                                44.76826171875004,
                                39.70351562500005
                            ],
                            [
                                45.03164062500005,
                                39.76513671874997
                            ],
                            [
                                45.76630859375004,
                                39.37846679687499
                            ],
                            [
                                46.1144531250001,
                                38.877783203125034
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                48.572851562500006,
                                41.84448242187503
                            ],
                            [
                                49.10664062500004,
                                41.30170898437504
                            ],
                            [
                                49.22646484375005,
                                41.026220703125034
                            ],
                            [
                                49.77597656250006,
                                40.583984375
                            ],
                            [
                                49.3244140625001,
                                39.60834960937501
                            ],
                            [
                                49.36279296875003,
                                39.349560546874955
                            ],
                            [
                                49.013476562500074,
                                39.13398437500001
                            ],
                            [
                                48.86875000000006,
                                38.43549804687498
                            ],
                            [
                                48.59267578125005,
                                38.41108398437498
                            ],
                            [
                                48.02324218750002,
                                38.81904296874998
                            ],
                            [
                                48.24199218750002,
                                38.978955078124955
                            ],
                            [
                                47.995898437500074,
                                39.683935546875034
                            ],
                            [
                                47.772851562499994,
                                39.64858398437505
                            ],
                            [
                                46.490624999999994,
                                38.90668945312498
                            ],
                            [
                                46.5500000000001,
                                39.20141601562497
                            ],
                            [
                                45.789648437500006,
                                39.88110351562497
                            ],
                            [
                                45.96464843750002,
                                40.233789062499966
                            ],
                            [
                                45.37617187500004,
                                40.63808593750002
                            ],
                            [
                                45.58750000000006,
                                40.846923828125
                            ],
                            [
                                45.001367187499994,
                                41.29096679687498
                            ],
                            [
                                45.217187499999994,
                                41.423193359375006
                            ],
                            [
                                45.28095703125004,
                                41.449560546875034
                            ],
                            [
                                46.086523437500006,
                                41.183837890625
                            ],
                            [
                                46.43095703125002,
                                41.077050781249994
                            ],
                            [
                                46.30546875000002,
                                41.507714843749994
                            ],
                            [
                                46.42988281250004,
                                41.890966796875006
                            ],
                            [
                                46.74931640625002,
                                41.812597656250006
                            ],
                            [
                                47.26113281250005,
                                41.315087890624994
                            ],
                            [
                                47.861132812500074,
                                41.212744140625034
                            ],
                            [
                                48.572851562500006,
                                41.84448242187503
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Azerbaijan',
                'childNum': 2
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            30.553613281250023,
                            -2.4000976562500114
                        ],
                        [
                            30.53369140625,
                            -2.42626953125
                        ],
                        [
                            30.441992187500006,
                            -2.6134765625000114
                        ],
                        [
                            30.424218750000023,
                            -2.6416015625
                        ],
                        [
                            30.42402343750001,
                            -2.824023437500003
                        ],
                        [
                            30.433496093750023,
                            -2.87451171875
                        ],
                        [
                            30.515039062500023,
                            -2.9175781250000057
                        ],
                        [
                            30.811132812500006,
                            -3.1164062500000114
                        ],
                        [
                            30.400000000000006,
                            -3.6539062500000057
                        ],
                        [
                            30.14716796875001,
                            -4.0853515625000085
                        ],
                        [
                            29.947265625,
                            -4.307324218750011
                        ],
                        [
                            29.7177734375,
                            -4.455859375000003
                        ],
                        [
                            29.403222656250023,
                            -4.449316406250006
                        ],
                        [
                            29.223242187500006,
                            -3.9108398437500114
                        ],
                        [
                            29.224414062500017,
                            -3.053515625000003
                        ],
                        [
                            29.01435546875001,
                            -2.72021484375
                        ],
                        [
                            29.390234375000006,
                            -2.80859375
                        ],
                        [
                            29.8681640625,
                            -2.7164062500000057
                        ],
                        [
                            29.93017578125,
                            -2.3395507812500114
                        ],
                        [
                            30.553613281250023,
                            -2.4000976562500114
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Burundi',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            5.693554687500011,
                            50.774755859375006
                        ],
                        [
                            5.993945312500017,
                            50.75043945312504
                        ],
                        [
                            6.364453125000011,
                            50.31616210937503
                        ],
                        [
                            6.1165039062500455,
                            50.120996093749966
                        ],
                        [
                            6.110058593750068,
                            50.123779296875
                        ],
                        [
                            6.08906250000004,
                            50.15458984374996
                        ],
                        [
                            5.976269531250068,
                            50.167187499999955
                        ],
                        [
                            5.866894531250068,
                            50.08281250000002
                        ],
                        [
                            5.817382812500028,
                            50.01269531250003
                        ],
                        [
                            5.7880859375,
                            49.96123046875002
                        ],
                        [
                            5.744042968749994,
                            49.91962890624998
                        ],
                        [
                            5.73525390625008,
                            49.875634765624994
                        ],
                        [
                            5.7408203125000625,
                            49.85717773437506
                        ],
                        [
                            5.725781250000011,
                            49.83334960937498
                        ],
                        [
                            5.725000000000023,
                            49.80830078125004
                        ],
                        [
                            5.78798828125008,
                            49.758886718750006
                        ],
                        [
                            5.8037109375,
                            49.73217773437497
                        ],
                        [
                            5.880371093749972,
                            49.64477539062503
                        ],
                        [
                            5.789746093749983,
                            49.53828125000001
                        ],
                        [
                            4.867578125000051,
                            49.78813476562502
                        ],
                        [
                            4.174609375000017,
                            50.24648437500005
                        ],
                        [
                            2.8397460937500227,
                            50.71176757812498
                        ],
                        [
                            2.52490234375,
                            51.097119140624955
                        ],
                        [
                            3.35009765625,
                            51.37768554687503
                        ],
                        [
                            3.9020507812500114,
                            51.20766601562502
                        ],
                        [
                            4.226171875000034,
                            51.38647460937503
                        ],
                        [
                            4.503417968750028,
                            51.47470703124998
                        ],
                        [
                            5.827148437500057,
                            51.125634765624994
                        ],
                        [
                            5.693554687500011,
                            50.774755859375006
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Belgium',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            3.5954101562500114,
                            11.6962890625
                        ],
                        [
                            3.5539062500000114,
                            11.631884765624989
                        ],
                        [
                            3.490527343750017,
                            11.499218749999997
                        ],
                        [
                            3.48779296875,
                            11.395410156249994
                        ],
                        [
                            3.6388671875000114,
                            11.176855468749991
                        ],
                        [
                            3.7164062500000057,
                            11.07958984375
                        ],
                        [
                            3.7568359375,
                            10.768749999999997
                        ],
                        [
                            3.83447265625,
                            10.607421875
                        ],
                        [
                            3.6465820312500057,
                            10.408984374999989
                        ],
                        [
                            3.5572265625000057,
                            9.907324218749991
                        ],
                        [
                            3.3251953125,
                            9.778466796874994
                        ],
                        [
                            3.044921875,
                            9.083837890624991
                        ],
                        [
                            2.7748046875000227,
                            9.048535156249997
                        ],
                        [
                            2.703125,
                            8.371826171875
                        ],
                        [
                            2.68603515625,
                            7.873730468749997
                        ],
                        [
                            2.75048828125,
                            7.395068359374989
                        ],
                        [
                            2.7213867187500114,
                            6.980273437499989
                        ],
                        [
                            2.731738281250017,
                            6.852832031249989
                        ],
                        [
                            2.7529296875,
                            6.771630859374994
                        ],
                        [
                            2.7746093750000114,
                            6.711718749999989
                        ],
                        [
                            2.753710937500017,
                            6.661767578124994
                        ],
                        [
                            2.735644531250017,
                            6.595703125
                        ],
                        [
                            2.7064453125000227,
                            6.3692382812499915
                        ],
                        [
                            1.6226562500000057,
                            6.216796875
                        ],
                        [
                            1.6001953125000057,
                            9.050048828125
                        ],
                        [
                            1.42431640625,
                            9.285009765624991
                        ],
                        [
                            1.330078125,
                            9.996972656249994
                        ],
                        [
                            0.7799804687500114,
                            10.359570312499997
                        ],
                        [
                            0.9004882812500057,
                            10.993261718749991
                        ],
                        [
                            1.4267578125,
                            11.447119140624991
                        ],
                        [
                            1.9803710937500227,
                            11.418408203124997
                        ],
                        [
                            2.38916015625,
                            11.897070312499991
                        ],
                        [
                            2.366015625000017,
                            12.221923828125
                        ],
                        [
                            2.8781250000000114,
                            12.367724609374989
                        ],
                        [
                            3.3599609375000057,
                            11.880468749999991
                        ],
                        [
                            3.4498046875000057,
                            11.851953124999994
                        ],
                        [
                            3.5954101562500114,
                            11.6962890625
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Benin',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            0.21748046875001137,
                            14.911474609374991
                        ],
                        [
                            0.16386718750001705,
                            14.497216796874994
                        ],
                        [
                            0.6181640625,
                            13.703417968750003
                        ],
                        [
                            0.9777343750000114,
                            13.551953124999997
                        ],
                        [
                            0.9873046875,
                            13.041894531249994
                        ],
                        [
                            1.56494140625,
                            12.635400390624994
                        ],
                        [
                            2.0738281250000057,
                            12.713964843749991
                        ],
                        [
                            2.0914062500000057,
                            12.277978515624994
                        ],
                        [
                            2.38916015625,
                            11.897070312499991
                        ],
                        [
                            1.9803710937500227,
                            11.418408203124997
                        ],
                        [
                            1.4267578125,
                            11.447119140624991
                        ],
                        [
                            0.9004882812500057,
                            10.993261718749991
                        ],
                        [
                            -0.068603515625,
                            11.115624999999994
                        ],
                        [
                            -0.701416015625,
                            10.988964843749997
                        ],
                        [
                            -2.8299316406249773,
                            10.998388671874991
                        ],
                        [
                            -2.9148925781249773,
                            10.592333984374989
                        ],
                        [
                            -2.78662109375,
                            10.401904296874989
                        ],
                        [
                            -2.6958496093749886,
                            9.481347656249994
                        ],
                        [
                            -2.7666015625,
                            9.424707031249994
                        ],
                        [
                            -2.8167480468749773,
                            9.425830078124989
                        ],
                        [
                            -3.2235351562499943,
                            9.895458984374997
                        ],
                        [
                            -3.5811523437499773,
                            9.92431640625
                        ],
                        [
                            -3.7906249999999773,
                            9.917187499999997
                        ],
                        [
                            -4.18115234375,
                            9.78173828125
                        ],
                        [
                            -4.267187499999977,
                            9.743261718749991
                        ],
                        [
                            -4.332226562499983,
                            9.645703124999997
                        ],
                        [
                            -4.406201171874983,
                            9.647998046874989
                        ],
                        [
                            -4.526611328125,
                            9.723486328124991
                        ],
                        [
                            -4.625830078124977,
                            9.713574218749997
                        ],
                        [
                            -4.721777343749977,
                            9.756542968749997
                        ],
                        [
                            -4.969921874999983,
                            9.930078124999994
                        ],
                        [
                            -5.262304687499977,
                            10.319677734374991
                        ],
                        [
                            -5.523535156249977,
                            10.426025390625
                        ],
                        [
                            -5.457080078124989,
                            10.771386718749994
                        ],
                        [
                            -5.490478515625,
                            11.042382812499994
                        ],
                        [
                            -5.299853515624989,
                            11.205957031249994
                        ],
                        [
                            -5.288134765624989,
                            11.827929687499989
                        ],
                        [
                            -4.797949218749977,
                            12.032128906249994
                        ],
                        [
                            -4.4287109375,
                            12.337597656249997
                        ],
                        [
                            -4.480615234374994,
                            12.672216796874991
                        ],
                        [
                            -4.227099609374989,
                            12.793701171875
                        ],
                        [
                            -4.328710937499977,
                            13.119042968749994
                        ],
                        [
                            -4.051171874999994,
                            13.382421874999991
                        ],
                        [
                            -3.5757812499999773,
                            13.194189453124991
                        ],
                        [
                            -3.3017578125,
                            13.28076171875
                        ],
                        [
                            -3.198437499999983,
                            13.6728515625
                        ],
                        [
                            -2.457226562499983,
                            14.274121093749997
                        ],
                        [
                            -2.113232421874983,
                            14.16845703125
                        ],
                        [
                            -1.767773437499983,
                            14.486035156249997
                        ],
                        [
                            -1.049560546875,
                            14.819531249999997
                        ],
                        [
                            -0.760449218749983,
                            15.047753906249994
                        ],
                        [
                            -0.23588867187498863,
                            15.059423828124991
                        ],
                        [
                            0.21748046875001137,
                            14.911474609374991
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Burkina Faso',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            88.94072265625002,
                            26.24536132812497
                        ],
                        [
                            88.97041015625004,
                            26.250878906250023
                        ],
                        [
                            88.95195312500002,
                            26.412109375
                        ],
                        [
                            89.01865234375012,
                            26.410253906249977
                        ],
                        [
                            89.10830078125005,
                            26.202246093749977
                        ],
                        [
                            89.57275390625003,
                            26.13232421875003
                        ],
                        [
                            89.8229492187501,
                            25.94140625000003
                        ],
                        [
                            89.82490234375004,
                            25.560156250000006
                        ],
                        [
                            89.80087890625012,
                            25.33613281250001
                        ],
                        [
                            89.81406250000006,
                            25.305371093749955
                        ],
                        [
                            89.86630859375012,
                            25.293164062499955
                        ],
                        [
                            90.11962890625003,
                            25.21997070312497
                        ],
                        [
                            90.61308593750002,
                            25.16772460937497
                        ],
                        [
                            92.04970703125005,
                            25.16948242187499
                        ],
                        [
                            92.38496093750004,
                            24.848779296875023
                        ],
                        [
                            92.22666015625012,
                            24.77099609374997
                        ],
                        [
                            92.11748046875002,
                            24.493945312500017
                        ],
                        [
                            92.06416015625004,
                            24.374365234375006
                        ],
                        [
                            91.72656250000003,
                            24.20507812499997
                        ],
                        [
                            91.35019531250012,
                            24.06049804687501
                        ],
                        [
                            91.16044921875007,
                            23.66064453125
                        ],
                        [
                            91.35937500000003,
                            23.06835937500003
                        ],
                        [
                            91.43623046875004,
                            23.19990234375001
                        ],
                        [
                            91.75097656250003,
                            23.053515625000017
                        ],
                        [
                            91.75419921875007,
                            23.287304687499955
                        ],
                        [
                            91.79003906249997,
                            23.361035156249983
                        ],
                        [
                            91.937890625,
                            23.504687500000017
                        ],
                        [
                            91.92949218750007,
                            23.598242187499977
                        ],
                        [
                            91.92958984375,
                            23.68598632812501
                        ],
                        [
                            91.97851562500003,
                            23.691992187499977
                        ],
                        [
                            92.04404296875006,
                            23.677783203125017
                        ],
                        [
                            92.33378906250002,
                            23.242382812499955
                        ],
                        [
                            92.36162109375002,
                            22.929003906250074
                        ],
                        [
                            92.46445312500006,
                            22.734423828125045
                        ],
                        [
                            92.49140625000004,
                            22.685400390625006
                        ],
                        [
                            92.5612304687501,
                            22.04804687500001
                        ],
                        [
                            92.57490234375004,
                            21.978076171875045
                        ],
                        [
                            92.5934570312501,
                            21.46733398437499
                        ],
                        [
                            92.17958984375005,
                            21.293115234375023
                        ],
                        [
                            92.32412109375,
                            20.791845703125063
                        ],
                        [
                            92.0560546875,
                            21.1748046875
                        ],
                        [
                            92.0080078125001,
                            21.684765624999983
                        ],
                        [
                            91.69296875000012,
                            22.504785156249966
                        ],
                        [
                            91.48212890625004,
                            22.79741210937499
                        ],
                        [
                            90.94560546875002,
                            22.597021484375034
                        ],
                        [
                            90.63359375000002,
                            23.09423828124997
                        ],
                        [
                            90.43505859374997,
                            22.751904296874955
                        ],
                        [
                            90.58945312500012,
                            22.258447265624966
                        ],
                        [
                            90.15878906250012,
                            21.816845703124983
                        ],
                        [
                            89.81191406250005,
                            21.983496093750006
                        ],
                        [
                            89.56855468750004,
                            21.767431640625034
                        ],
                        [
                            89.09394531250004,
                            21.872753906249983
                        ],
                        [
                            89.0500000000001,
                            22.274609374999983
                        ],
                        [
                            88.92070312500002,
                            22.632031249999955
                        ],
                        [
                            88.89970703125002,
                            22.843505859375057
                        ],
                        [
                            88.72441406250002,
                            23.254980468750034
                        ],
                        [
                            88.69765625,
                            23.493017578125034
                        ],
                        [
                            88.56738281249997,
                            23.674414062500034
                        ],
                        [
                            88.69980468750006,
                            24.002539062500006
                        ],
                        [
                            88.71376953125,
                            24.069628906250017
                        ],
                        [
                            88.72656250000003,
                            24.186230468749955
                        ],
                        [
                            88.7335937500001,
                            24.23090820312501
                        ],
                        [
                            88.72353515625,
                            24.27490234375
                        ],
                        [
                            88.64228515625004,
                            24.325976562500017
                        ],
                        [
                            88.49853515625003,
                            24.34663085937504
                        ],
                        [
                            88.33750000000006,
                            24.45385742187503
                        ],
                        [
                            88.22500000000005,
                            24.460644531249983
                        ],
                        [
                            88.14550781250003,
                            24.485791015624955
                        ],
                        [
                            88.07910156249997,
                            24.549902343750063
                        ],
                        [
                            88.02343750000003,
                            24.62783203125005
                        ],
                        [
                            88.03027343749997,
                            24.66445312500005
                        ],
                        [
                            88.0451171875001,
                            24.713037109374994
                        ],
                        [
                            88.1498046875,
                            24.914648437500034
                        ],
                        [
                            88.57382812500006,
                            25.187890624999994
                        ],
                        [
                            88.92978515625012,
                            25.222998046875063
                        ],
                        [
                            88.94414062500002,
                            25.290771484375
                        ],
                        [
                            88.85478515625002,
                            25.333544921875017
                        ],
                        [
                            88.76914062500006,
                            25.490478515625
                        ],
                        [
                            88.14746093749997,
                            25.811425781250023
                        ],
                        [
                            88.1066406250001,
                            25.841113281250045
                        ],
                        [
                            88.33398437499997,
                            26.257519531249955
                        ],
                        [
                            88.44042968749997,
                            26.369482421875034
                        ],
                        [
                            88.38623046875003,
                            26.471533203125034
                        ],
                        [
                            88.35146484375005,
                            26.482568359374966
                        ],
                        [
                            88.36992187500002,
                            26.564111328124994
                        ],
                        [
                            88.41816406250004,
                            26.57153320312497
                        ],
                        [
                            88.51826171875004,
                            26.517773437499955
                        ],
                        [
                            88.68066406249997,
                            26.352978515624955
                        ],
                        [
                            88.68281250000004,
                            26.291699218749983
                        ],
                        [
                            88.94072265625002,
                            26.24536132812497
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Bangladesh',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            28.585351562500023,
                            43.742236328124996
                        ],
                        [
                            28.465429687500006,
                            43.389306640624994
                        ],
                        [
                            27.92890625000001,
                            43.1861328125
                        ],
                        [
                            27.88886718750001,
                            42.74970703125
                        ],
                        [
                            27.484765625000023,
                            42.468066406249996
                        ],
                        [
                            27.982714843750017,
                            42.047412109374996
                        ],
                        [
                            28.014453125000017,
                            41.969042968749996
                        ],
                        [
                            27.879199218750017,
                            41.986621093749996
                        ],
                        [
                            27.801660156250023,
                            41.95654296875
                        ],
                        [
                            27.53486328125001,
                            41.920800781249994
                        ],
                        [
                            27.47480468750001,
                            41.946875
                        ],
                        [
                            27.362890625000006,
                            42.025048828124994
                        ],
                        [
                            27.294921875,
                            42.079541015625
                        ],
                        [
                            27.24433593750001,
                            42.09326171875
                        ],
                        [
                            27.193359375,
                            42.077099609375
                        ],
                        [
                            27.01171875,
                            42.058642578124996
                        ],
                        [
                            26.96875,
                            42.02685546875
                        ],
                        [
                            26.884863281250006,
                            41.991845703124994
                        ],
                        [
                            26.800390625000006,
                            41.975146484374996
                        ],
                        [
                            26.615332031250006,
                            41.964892578124996
                        ],
                        [
                            26.549707031250023,
                            41.896728515625
                        ],
                        [
                            26.066015625000006,
                            41.673242187499994
                        ],
                        [
                            26.155175781250023,
                            41.434863281249996
                        ],
                        [
                            25.92333984375,
                            41.311914062499994
                        ],
                        [
                            25.784960937500017,
                            41.330419921875
                        ],
                        [
                            25.723925781250017,
                            41.3150390625
                        ],
                        [
                            25.52705078125001,
                            41.2998046875
                        ],
                        [
                            25.381933593750006,
                            41.26435546875
                        ],
                        [
                            25.25117187500001,
                            41.243554687499994
                        ],
                        [
                            25.133398437500006,
                            41.315771484375
                        ],
                        [
                            24.993554687500023,
                            41.364990234375
                        ],
                        [
                            24.84687500000001,
                            41.39423828125
                        ],
                        [
                            24.595996093750017,
                            41.442724609375
                        ],
                        [
                            24.056054687500023,
                            41.527246093749994
                        ],
                        [
                            24.03291015625001,
                            41.469091796875
                        ],
                        [
                            24.011328125000006,
                            41.46005859375
                        ],
                        [
                            23.973535156250023,
                            41.452294921874994
                        ],
                        [
                            23.880859375,
                            41.455957031249994
                        ],
                        [
                            23.635156250000023,
                            41.386767578124996
                        ],
                        [
                            23.53583984375001,
                            41.386035156249996
                        ],
                        [
                            23.433398437500017,
                            41.398730468749996
                        ],
                        [
                            23.3720703125,
                            41.3896484375
                        ],
                        [
                            23.239843750000006,
                            41.3849609375
                        ],
                        [
                            23.15595703125001,
                            41.322070312499996
                        ],
                        [
                            23.02558593750001,
                            41.325634765625
                        ],
                        [
                            22.916015625,
                            41.336279296875
                        ],
                        [
                            22.9296875,
                            41.356103515624994
                        ],
                        [
                            22.951464843750017,
                            41.605615234374994
                        ],
                        [
                            23.005664062500017,
                            41.716943359374994
                        ],
                        [
                            22.836816406250023,
                            41.993603515625
                        ],
                        [
                            22.344042968750017,
                            42.31396484375
                        ],
                        [
                            22.42207031250001,
                            42.328857421875
                        ],
                        [
                            22.445703125000023,
                            42.359130859375
                        ],
                        [
                            22.523535156250006,
                            42.440966796874996
                        ],
                        [
                            22.524218750000017,
                            42.50390625
                        ],
                        [
                            22.43623046875001,
                            42.6291015625
                        ],
                        [
                            22.463281250000023,
                            42.70947265625
                        ],
                        [
                            22.465625000000017,
                            42.750781249999996
                        ],
                        [
                            22.466796875,
                            42.84248046875
                        ],
                        [
                            22.799902343750006,
                            42.985742187499994
                        ],
                        [
                            22.976855468750017,
                            43.18798828125
                        ],
                        [
                            22.85957031250001,
                            43.252343749999994
                        ],
                        [
                            22.819726562500023,
                            43.300732421875
                        ],
                        [
                            22.767578125,
                            43.354150390624994
                        ],
                        [
                            22.554589843750023,
                            43.454492187499994
                        ],
                        [
                            22.394824218750017,
                            43.706640625
                        ],
                        [
                            22.38691406250001,
                            43.740136718749994
                        ],
                        [
                            22.36962890625,
                            43.781298828124996
                        ],
                        [
                            22.36542968750001,
                            43.862109374999996
                        ],
                        [
                            22.399023437500006,
                            43.969531249999996
                        ],
                        [
                            22.420800781250023,
                            44.007421875
                        ],
                        [
                            22.469042968750017,
                            44.018017578125
                        ],
                        [
                            22.597460937500017,
                            44.07529296875
                        ],
                        [
                            22.705078125,
                            44.23779296875
                        ],
                        [
                            23.224609375,
                            43.873876953125
                        ],
                        [
                            25.4970703125,
                            43.670800781249994
                        ],
                        [
                            26.2158203125,
                            44.007275390625
                        ],
                        [
                            27.0869140625,
                            44.167382812499994
                        ],
                        [
                            28.585351562500023,
                            43.742236328124996
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Bulgaria',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -77.74384765625001,
                            24.707421874999994
                        ],
                        [
                            -78.04492187499997,
                            24.287451171875063
                        ],
                        [
                            -78.298828125,
                            24.753906250000057
                        ],
                        [
                            -78.03330078125,
                            25.143115234375045
                        ],
                        [
                            -77.74384765625001,
                            24.707421874999994
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'The Bahamas',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            19.007128906250045,
                            44.86918945312502
                        ],
                        [
                            19.35683593750005,
                            44.858544921874994
                        ],
                        [
                            19.15185546875003,
                            44.302539062500045
                        ],
                        [
                            19.583789062500017,
                            44.04345703125003
                        ],
                        [
                            19.19433593749997,
                            43.533300781250006
                        ],
                        [
                            18.94023437499999,
                            43.49672851562502
                        ],
                        [
                            18.85107421875003,
                            43.34633789062502
                        ],
                        [
                            18.749218750000068,
                            43.283544921875006
                        ],
                        [
                            18.67421875000008,
                            43.230810546875006
                        ],
                        [
                            18.46601562500001,
                            42.777246093749994
                        ],
                        [
                            18.436328125000017,
                            42.559716796874994
                        ],
                        [
                            17.667578125000063,
                            42.897119140624994
                        ],
                        [
                            17.585156250000068,
                            42.93837890625005
                        ],
                        [
                            17.624804687500074,
                            43.04277343749999
                        ],
                        [
                            16.214257812500023,
                            44.21513671874999
                        ],
                        [
                            15.736621093750045,
                            44.76582031250001
                        ],
                        [
                            15.788085937500057,
                            45.17895507812497
                        ],
                        [
                            16.29335937500005,
                            45.00883789062496
                        ],
                        [
                            16.53066406250008,
                            45.21669921875002
                        ],
                        [
                            17.812792968750074,
                            45.07812500000006
                        ],
                        [
                            18.66259765625,
                            45.07744140624999
                        ],
                        [
                            19.007128906250045,
                            44.86918945312502
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Bosnia and Herzegovina',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            28.14794921875,
                            56.142919921875
                        ],
                        [
                            28.284277343750006,
                            56.055908203125
                        ],
                        [
                            29.375,
                            55.938720703125
                        ],
                        [
                            29.39794921875,
                            55.8810546875
                        ],
                        [
                            29.373144531250006,
                            55.834716796875
                        ],
                        [
                            29.353417968750023,
                            55.784375
                        ],
                        [
                            29.412988281250023,
                            55.724853515625
                        ],
                        [
                            29.482226562500017,
                            55.6845703125
                        ],
                        [
                            29.63007812500001,
                            55.751171875
                        ],
                        [
                            29.6845703125,
                            55.7697265625
                        ],
                        [
                            29.744140625,
                            55.77041015625
                        ],
                        [
                            29.82392578125001,
                            55.7951171875
                        ],
                        [
                            29.881640625000017,
                            55.832324218749996
                        ],
                        [
                            29.93701171875,
                            55.845263671874996
                        ],
                        [
                            30.04267578125001,
                            55.83642578125
                        ],
                        [
                            30.23359375000001,
                            55.84521484375
                        ],
                        [
                            30.625585937500006,
                            55.666259765625
                        ],
                        [
                            30.906835937500006,
                            55.57001953125
                        ],
                        [
                            31.12128906250001,
                            54.648486328124996
                        ],
                        [
                            31.754199218750017,
                            53.81044921875
                        ],
                        [
                            32.45097656250002,
                            53.6533203125
                        ],
                        [
                            32.70429687500001,
                            53.336328125
                        ],
                        [
                            31.849707031250006,
                            53.106201171875
                        ],
                        [
                            31.668261718750017,
                            53.200927734375
                        ],
                        [
                            31.417871093750023,
                            53.196044921875
                        ],
                        [
                            31.38837890625001,
                            53.184814453125
                        ],
                        [
                            31.364550781250017,
                            53.138964843749996
                        ],
                        [
                            31.30292968750001,
                            53.060888671875
                        ],
                        [
                            31.2587890625,
                            53.01669921875
                        ],
                        [
                            31.585546875000006,
                            52.532470703125
                        ],
                        [
                            31.57734375000001,
                            52.312304687499996
                        ],
                        [
                            31.69062500000001,
                            52.220654296875
                        ],
                        [
                            31.758593750000017,
                            52.125830078125
                        ],
                        [
                            31.76337890625001,
                            52.10107421875
                        ],
                        [
                            31.57373046875,
                            52.10810546875
                        ],
                        [
                            31.345996093750017,
                            52.10537109375
                        ],
                        [
                            30.98066406250001,
                            52.046191406249996
                        ],
                        [
                            30.533007812500017,
                            51.596337890624994
                        ],
                        [
                            30.449511718750017,
                            51.274316406249994
                        ],
                        [
                            30.160742187500006,
                            51.477880859375
                        ],
                        [
                            29.346484375000017,
                            51.382568359375
                        ],
                        [
                            29.298828125,
                            51.413037109375
                        ],
                        [
                            29.23046875,
                            51.497021484375
                        ],
                        [
                            29.174218750000023,
                            51.580615234374996
                        ],
                        [
                            29.135644531250023,
                            51.61728515625
                        ],
                        [
                            29.10205078125,
                            51.6275390625
                        ],
                        [
                            29.06074218750001,
                            51.625439453125
                        ],
                        [
                            29.013085937500023,
                            51.598925781249996
                        ],
                        [
                            28.97773437500001,
                            51.57177734375
                        ],
                        [
                            28.793261718750017,
                            51.5103515625
                        ],
                        [
                            28.731250000000017,
                            51.433398437499996
                        ],
                        [
                            28.690234375000017,
                            51.438867187499994
                        ],
                        [
                            28.647753906250017,
                            51.45654296875
                        ],
                        [
                            28.599023437500023,
                            51.542626953124994
                        ],
                        [
                            27.689746093750017,
                            51.572412109374994
                        ],
                        [
                            27.141992187500023,
                            51.75205078125
                        ],
                        [
                            25.785742187500006,
                            51.923828125
                        ],
                        [
                            24.361914062500006,
                            51.867529296875
                        ],
                        [
                            23.61376953125,
                            51.525390625
                        ],
                        [
                            23.605273437500017,
                            51.517919921875
                        ],
                        [
                            23.652441406250006,
                            52.040380859375
                        ],
                        [
                            23.19697265625001,
                            52.25693359375
                        ],
                        [
                            23.8447265625,
                            52.664208984375
                        ],
                        [
                            23.887109375000023,
                            53.0275390625
                        ],
                        [
                            23.484667968750017,
                            53.939794921875
                        ],
                        [
                            24.191308593750023,
                            53.950439453125
                        ],
                        [
                            24.236621093750017,
                            53.919970703124996
                        ],
                        [
                            24.317968750000006,
                            53.89296875
                        ],
                        [
                            24.768164062500006,
                            53.974658203124996
                        ],
                        [
                            25.573046875000017,
                            54.139892578125
                        ],
                        [
                            25.859277343750023,
                            54.919287109375
                        ],
                        [
                            25.964453125000006,
                            54.94716796875
                        ],
                        [
                            26.09296875000001,
                            54.9623046875
                        ],
                        [
                            26.175195312500023,
                            55.003271484375
                        ],
                        [
                            26.291796875000017,
                            55.139599609375
                        ],
                        [
                            26.601171875000006,
                            55.130175781249996
                        ],
                        [
                            26.775683593750017,
                            55.273095703125
                        ],
                        [
                            26.681250000000006,
                            55.306445312499996
                        ],
                        [
                            26.49531250000001,
                            55.318017578125
                        ],
                        [
                            26.457617187500006,
                            55.34248046875
                        ],
                        [
                            26.469531250000017,
                            55.371923828125
                        ],
                        [
                            26.51923828125001,
                            55.44814453125
                        ],
                        [
                            26.56660156250001,
                            55.546484375
                        ],
                        [
                            26.5908203125,
                            55.62265625
                        ],
                        [
                            26.593554687500017,
                            55.667529296874996
                        ],
                        [
                            26.620214843750006,
                            55.679638671875
                        ],
                        [
                            26.82246093750001,
                            55.709228515625
                        ],
                        [
                            27.052539062500017,
                            55.83056640625
                        ],
                        [
                            27.576757812500006,
                            55.798779296875
                        ],
                        [
                            28.14794921875,
                            56.142919921875
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Belarus',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -89.2328125,
                            15.888671875
                        ],
                        [
                            -89.16147460937503,
                            17.814843749999994
                        ],
                        [
                            -88.80634765624998,
                            17.965527343749983
                        ],
                        [
                            -88.52299804687499,
                            18.445898437500063
                        ],
                        [
                            -88.29565429687494,
                            18.47241210937503
                        ],
                        [
                            -88.09721679687502,
                            18.121630859375074
                        ],
                        [
                            -88.27172851562494,
                            17.60986328125
                        ],
                        [
                            -88.31342773437501,
                            16.632763671874983
                        ],
                        [
                            -88.89404296875,
                            15.890625000000028
                        ],
                        [
                            -89.2328125,
                            15.888671875
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Belize',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -58.15976562499999,
                            -20.164648437500006
                        ],
                        [
                            -58.18017578125,
                            -19.81787109375
                        ],
                        [
                            -59.09052734375,
                            -19.286230468750006
                        ],
                        [
                            -60.00737304687499,
                            -19.29755859375001
                        ],
                        [
                            -61.7568359375,
                            -19.645312500000003
                        ],
                        [
                            -61.916943359375,
                            -20.05537109375001
                        ],
                        [
                            -62.276318359375,
                            -20.5625
                        ],
                        [
                            -62.27666015624999,
                            -21.066015625000006
                        ],
                        [
                            -62.65097656249999,
                            -22.233691406250003
                        ],
                        [
                            -62.84335937499999,
                            -21.99726562500001
                        ],
                        [
                            -63.81865234374999,
                            -22.005468750000006
                        ],
                        [
                            -63.92167968749999,
                            -22.028613281250003
                        ],
                        [
                            -63.97612304687499,
                            -22.072558593750003
                        ],
                        [
                            -64.26640624999999,
                            -22.603320312500003
                        ],
                        [
                            -64.30791015624999,
                            -22.79531250000001
                        ],
                        [
                            -64.32529296874999,
                            -22.82763671875
                        ],
                        [
                            -64.373974609375,
                            -22.761035156250003
                        ],
                        [
                            -64.4455078125,
                            -22.58535156250001
                        ],
                        [
                            -64.477734375,
                            -22.4853515625
                        ],
                        [
                            -64.5236328125,
                            -22.37158203125
                        ],
                        [
                            -64.60551757812499,
                            -22.228808593750003
                        ],
                        [
                            -65.0578125,
                            -22.10273437500001
                        ],
                        [
                            -65.48486328125,
                            -22.09814453125
                        ],
                        [
                            -65.686181640625,
                            -22.11025390625001
                        ],
                        [
                            -65.77104492187499,
                            -22.099609375
                        ],
                        [
                            -65.86015624999999,
                            -22.01972656250001
                        ],
                        [
                            -66.05859375,
                            -21.879492187500006
                        ],
                        [
                            -66.098583984375,
                            -21.835058593750006
                        ],
                        [
                            -66.17465820312499,
                            -21.8056640625
                        ],
                        [
                            -66.220166015625,
                            -21.802539062500003
                        ],
                        [
                            -66.24760742187499,
                            -21.83046875000001
                        ],
                        [
                            -66.28212890625,
                            -21.94746093750001
                        ],
                        [
                            -66.3224609375,
                            -22.05312500000001
                        ],
                        [
                            -66.365185546875,
                            -22.11376953125
                        ],
                        [
                            -66.71171874999999,
                            -22.21630859375
                        ],
                        [
                            -66.99111328125,
                            -22.509863281250006
                        ],
                        [
                            -67.19487304687499,
                            -22.82167968750001
                        ],
                        [
                            -67.362255859375,
                            -22.85517578125001
                        ],
                        [
                            -67.57993164062499,
                            -22.891699218750006
                        ],
                        [
                            -67.79443359375,
                            -22.879492187500006
                        ],
                        [
                            -67.87944335937499,
                            -22.82294921875001
                        ],
                        [
                            -67.88173828125,
                            -22.49335937500001
                        ],
                        [
                            -67.950390625,
                            -22.33369140625001
                        ],
                        [
                            -67.95390624999999,
                            -22.20400390625001
                        ],
                        [
                            -67.98837890624999,
                            -22.05712890625
                        ],
                        [
                            -68.07675781249999,
                            -21.98281250000001
                        ],
                        [
                            -68.11215820312499,
                            -21.753027343750006
                        ],
                        [
                            -68.18642578125,
                            -21.61855468750001
                        ],
                        [
                            -68.197021484375,
                            -21.30029296875
                        ],
                        [
                            -68.74516601562499,
                            -20.458593750000006
                        ],
                        [
                            -68.75932617187499,
                            -20.115527343750003
                        ],
                        [
                            -68.69829101562499,
                            -19.72109375000001
                        ],
                        [
                            -68.462890625,
                            -19.43281250000001
                        ],
                        [
                            -68.470166015625,
                            -19.409960937500003
                        ],
                        [
                            -68.49199218749999,
                            -19.381933593750006
                        ],
                        [
                            -68.85795898437499,
                            -19.093359375000006
                        ],
                        [
                            -68.96831054687499,
                            -18.96796875000001
                        ],
                        [
                            -68.97885742187499,
                            -18.81298828125
                        ],
                        [
                            -69.026806640625,
                            -18.65625
                        ],
                        [
                            -69.09228515625,
                            -18.28242187500001
                        ],
                        [
                            -69.0939453125,
                            -18.05048828125001
                        ],
                        [
                            -69.28232421874999,
                            -17.96484375
                        ],
                        [
                            -69.31337890625,
                            -17.943164062500003
                        ],
                        [
                            -69.35800781249999,
                            -17.77167968750001
                        ],
                        [
                            -69.49501953125,
                            -17.61953125000001
                        ],
                        [
                            -69.5109375,
                            -17.50605468750001
                        ],
                        [
                            -69.62485351562499,
                            -17.2001953125
                        ],
                        [
                            -69.020703125,
                            -16.642187500000006
                        ],
                        [
                            -68.9134765625,
                            -16.26191406250001
                        ],
                        [
                            -69.21757812499999,
                            -16.14912109375001
                        ],
                        [
                            -69.41850585937499,
                            -15.603417968750009
                        ],
                        [
                            -69.17246093749999,
                            -15.236621093750003
                        ],
                        [
                            -69.37470703125,
                            -14.962988281250006
                        ],
                        [
                            -69.23491210937499,
                            -14.597070312500009
                        ],
                        [
                            -68.880322265625,
                            -14.198828125000006
                        ],
                        [
                            -69.07412109375,
                            -13.682812500000011
                        ],
                        [
                            -68.97861328124999,
                            -12.880078125000011
                        ],
                        [
                            -68.68525390625,
                            -12.501953125
                        ],
                        [
                            -69.57861328125,
                            -10.951757812500006
                        ],
                        [
                            -68.84833984375,
                            -11.011132812500009
                        ],
                        [
                            -68.678369140625,
                            -11.11279296875
                        ],
                        [
                            -68.0716796875,
                            -10.703125
                        ],
                        [
                            -67.99169921875,
                            -10.674414062500006
                        ],
                        [
                            -67.83500976562499,
                            -10.662792968750011
                        ],
                        [
                            -67.72177734374999,
                            -10.68310546875
                        ],
                        [
                            -67.582421875,
                            -10.505957031250006
                        ],
                        [
                            -67.416943359375,
                            -10.389843750000011
                        ],
                        [
                            -67.33271484375,
                            -10.35791015625
                        ],
                        [
                            -67.28046875,
                            -10.317285156250009
                        ],
                        [
                            -67.1115234375,
                            -10.268945312500009
                        ],
                        [
                            -66.72998046875,
                            -9.975488281250009
                        ],
                        [
                            -66.575341796875,
                            -9.89990234375
                        ],
                        [
                            -66.26357421875,
                            -9.826074218750009
                        ],
                        [
                            -65.396142578125,
                            -9.71240234375
                        ],
                        [
                            -65.31308593749999,
                            -10.253027343750006
                        ],
                        [
                            -65.395458984375,
                            -10.392285156250011
                        ],
                        [
                            -65.4369140625,
                            -10.449023437500003
                        ],
                        [
                            -65.33403320312499,
                            -10.892773437500011
                        ],
                        [
                            -65.32377929687499,
                            -11.024804687500009
                        ],
                        [
                            -65.389892578125,
                            -11.246289062500011
                        ],
                        [
                            -64.99252929687499,
                            -11.975195312500006
                        ],
                        [
                            -64.513427734375,
                            -12.2509765625
                        ],
                        [
                            -64.42050781249999,
                            -12.439746093750003
                        ],
                        [
                            -63.93857421874999,
                            -12.529687500000009
                        ],
                        [
                            -63.7880859375,
                            -12.469433593750011
                        ],
                        [
                            -63.68857421874999,
                            -12.47802734375
                        ],
                        [
                            -63.58564453125,
                            -12.518945312500009
                        ],
                        [
                            -63.3466796875,
                            -12.680078125000009
                        ],
                        [
                            -63.06748046874999,
                            -12.669140625000011
                        ],
                        [
                            -62.76547851562499,
                            -12.997265625000011
                        ],
                        [
                            -62.17607421874999,
                            -13.133691406250009
                        ],
                        [
                            -62.11801757812499,
                            -13.159765625000006
                        ],
                        [
                            -62.09477539062499,
                            -13.241992187500003
                        ],
                        [
                            -61.944726562499994,
                            -13.40625
                        ],
                        [
                            -61.87412109374999,
                            -13.470410156250011
                        ],
                        [
                            -61.789941406249994,
                            -13.525585937500011
                        ],
                        [
                            -61.57568359375,
                            -13.524804687500009
                        ],
                        [
                            -61.51157226562499,
                            -13.541210937500011
                        ],
                        [
                            -61.41606445312499,
                            -13.526562500000011
                        ],
                        [
                            -61.129150390625,
                            -13.49853515625
                        ],
                        [
                            -61.07700195312499,
                            -13.48974609375
                        ],
                        [
                            -60.914501953125,
                            -13.561425781250009
                        ],
                        [
                            -60.506591796875,
                            -13.789843750000003
                        ],
                        [
                            -60.372705078124994,
                            -14.418750000000003
                        ],
                        [
                            -60.17558593749999,
                            -16.269335937500003
                        ],
                        [
                            -58.53793945312499,
                            -16.328222656250006
                        ],
                        [
                            -58.49658203125,
                            -16.32666015625
                        ],
                        [
                            -58.42368164062499,
                            -16.307910156250003
                        ],
                        [
                            -58.345605468749994,
                            -16.28437500000001
                        ],
                        [
                            -58.35039062499999,
                            -16.490820312500006
                        ],
                        [
                            -58.470605468749994,
                            -16.650195312500003
                        ],
                        [
                            -58.47812499999999,
                            -16.70068359375
                        ],
                        [
                            -58.45981445312499,
                            -16.910742187500006
                        ],
                        [
                            -58.417382812499994,
                            -17.08056640625
                        ],
                        [
                            -58.39599609375,
                            -17.23427734375001
                        ],
                        [
                            -57.832470703125,
                            -17.51210937500001
                        ],
                        [
                            -57.780175781249994,
                            -17.67177734375001
                        ],
                        [
                            -57.66166992187499,
                            -17.947363281250006
                        ],
                        [
                            -57.58647460937499,
                            -18.12226562500001
                        ],
                        [
                            -57.57402343749999,
                            -18.279296875
                        ],
                        [
                            -57.80039062499999,
                            -19.08095703125001
                        ],
                        [
                            -58.131494140624994,
                            -19.74453125000001
                        ],
                        [
                            -58.15976562499999,
                            -20.164648437500006
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Bolivia',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                -51.83251953124997,
                                -1.4337890624999687
                            ],
                            [
                                -51.546044921874966,
                                -0.6496093749999972
                            ],
                            [
                                -51.16074218749998,
                                -0.6666992187500114
                            ],
                            [
                                -51.27631835937498,
                                -1.0217773437499602
                            ],
                            [
                                -51.83251953124997,
                                -1.4337890624999687
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -49.62866210937497,
                                -0.22919921874996874
                            ],
                            [
                                -49.11699218749999,
                                -0.1635742187500142
                            ],
                            [
                                -48.37968749999999,
                                -0.35283203125001705
                            ],
                            [
                                -48.539697265624994,
                                -0.8009765625000114
                            ],
                            [
                                -48.928906249999955,
                                -1.4823242187499943
                            ],
                            [
                                -49.587890625,
                                -1.7124023437499716
                            ],
                            [
                                -50.50761718749999,
                                -1.7879882812500085
                            ],
                            [
                                -50.759765625,
                                -1.2402343749999716
                            ],
                            [
                                -50.771386718749966,
                                -0.6454101562500227
                            ],
                            [
                                -50.6455078125,
                                -0.2728515624999801
                            ],
                            [
                                -50.24824218749998,
                                -0.11640624999999716
                            ],
                            [
                                -49.62866210937497,
                                -0.22919921874996874
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -59.69970703125,
                                4.353515625
                            ],
                            [
                                -59.551123046875034,
                                3.9335449218749687
                            ],
                            [
                                -59.854394531249994,
                                3.5874999999999915
                            ],
                            [
                                -59.99433593749998,
                                2.6899902343750313
                            ],
                            [
                                -59.75522460937495,
                                2.27412109375004
                            ],
                            [
                                -59.74350585937498,
                                2.12163085937496
                            ],
                            [
                                -59.75175781249996,
                                1.9624023437500284
                            ],
                            [
                                -59.75620117187498,
                                1.9006347656249716
                            ],
                            [
                                -59.666601562500006,
                                1.7462890624999687
                            ],
                            [
                                -59.59663085937498,
                                1.7180175781249858
                            ],
                            [
                                -59.53569335937499,
                                1.7000000000000455
                            ],
                            [
                                -59.23120117187494,
                                1.3760253906250313
                            ],
                            [
                                -58.968505859375,
                                1.3045898437500512
                            ],
                            [
                                -58.91660156249998,
                                1.2488769531249773
                            ],
                            [
                                -58.86249999999998,
                                1.2036132812499858
                            ],
                            [
                                -58.511865234374966,
                                1.2846679687499858
                            ],
                            [
                                -58.506054687499926,
                                1.4386718750000114
                            ],
                            [
                                -58.39580078124993,
                                1.4817382812499886
                            ],
                            [
                                -58.38037109375,
                                1.5302246093750114
                            ],
                            [
                                -58.34067382812498,
                                1.5875488281250512
                            ],
                            [
                                -58.03466796875,
                                1.5202636718750142
                            ],
                            [
                                -57.982812499999994,
                                1.6484375000000142
                            ],
                            [
                                -57.79565429687497,
                                1.7000000000000455
                            ],
                            [
                                -57.691748046875034,
                                1.7047851562500398
                            ],
                            [
                                -57.59443359375001,
                                1.7041015624999858
                            ],
                            [
                                -57.500439453124955,
                                1.77382812499998
                            ],
                            [
                                -57.412695312500034,
                                1.9089355468749716
                            ],
                            [
                                -57.366796875000006,
                                1.9401367187500256
                            ],
                            [
                                -57.31748046874998,
                                1.9634765624999915
                            ],
                            [
                                -57.11889648437494,
                                2.0139648437499744
                            ],
                            [
                                -57.03759765625,
                                1.9364746093749972
                            ],
                            [
                                -56.96953124999999,
                                1.9164062499999943
                            ],
                            [
                                -56.48281249999994,
                                1.9421386718749858
                            ],
                            [
                                -56.019921874999966,
                                1.842236328124983
                            ],
                            [
                                -56.12939453124997,
                                2.2995117187499687
                            ],
                            [
                                -56.08779296875002,
                                2.3413085937500426
                            ],
                            [
                                -56.02036132812498,
                                2.392773437500054
                            ],
                            [
                                -55.935937499999994,
                                2.516601562500057
                            ],
                            [
                                -55.730566406250006,
                                2.4061523437500227
                            ],
                            [
                                -55.385351562500006,
                                2.4406250000000256
                            ],
                            [
                                -55.34399414062503,
                                2.4887695312499716
                            ],
                            [
                                -55.28603515625002,
                                2.49965820312498
                            ],
                            [
                                -55.18769531249998,
                                2.547509765625037
                            ],
                            [
                                -55.114111328125006,
                                2.539208984375037
                            ],
                            [
                                -55.07031249999994,
                                2.5483398437500284
                            ],
                            [
                                -55.005810546874955,
                                2.592968749999983
                            ],
                            [
                                -54.97866210937502,
                                2.5976562500000426
                            ],
                            [
                                -54.968408203124966,
                                2.5483398437500284
                            ],
                            [
                                -54.92656249999999,
                                2.4973632812500455
                            ],
                            [
                                -54.876074218750006,
                                2.4503906249999687
                            ],
                            [
                                -54.851660156250006,
                                2.4395507812499915
                            ],
                            [
                                -54.76684570312503,
                                2.454736328124966
                            ],
                            [
                                -54.72221679687499,
                                2.4416503906249716
                            ],
                            [
                                -54.69741210937502,
                                2.359814453124997
                            ],
                            [
                                -54.66186523437497,
                                2.3275390624999943
                            ],
                            [
                                -54.61625976562499,
                                2.3267578125000057
                            ],
                            [
                                -54.59194335937502,
                                2.3137695312500313
                            ],
                            [
                                -54.55048828125001,
                                2.2930664062499915
                            ],
                            [
                                -54.51508789062498,
                                2.245458984374963
                            ],
                            [
                                -54.13007812499998,
                                2.1210449218750256
                            ],
                            [
                                -53.76777343749998,
                                2.3548339843750483
                            ],
                            [
                                -52.96484375,
                                2.1835449218749687
                            ],
                            [
                                -52.70063476562501,
                                2.36367187499998
                            ],
                            [
                                -51.99062499999994,
                                3.702001953124963
                            ],
                            [
                                -51.65253906249998,
                                4.061279296874972
                            ],
                            [
                                -51.46152343749998,
                                4.313769531249989
                            ],
                            [
                                -51.219921874999955,
                                4.0936035156249915
                            ],
                            [
                                -51.05239257812502,
                                3.2818359374999915
                            ],
                            [
                                -50.65893554687497,
                                2.1309570312500057
                            ],
                            [
                                -50.458886718749994,
                                1.8295898437499716
                            ],
                            [
                                -49.957128906250006,
                                1.6598632812500398
                            ],
                            [
                                -49.898876953124955,
                                1.16298828124998
                            ],
                            [
                                -50.29443359374997,
                                0.8357421875000028
                            ],
                            [
                                -50.81635742187498,
                                0.17255859374999716
                            ],
                            [
                                -51.28291015625001,
                                -0.08520507812502842
                            ],
                            [
                                -51.70263671874997,
                                -0.7623046874999488
                            ],
                            [
                                -51.94755859374996,
                                -1.586718749999946
                            ],
                            [
                                -51.29736328125003,
                                -1.223535156250037
                            ],
                            [
                                -50.917871093749966,
                                -1.115234375
                            ],
                            [
                                -50.690039062500006,
                                -1.7617187499999858
                            ],
                            [
                                -50.40322265625002,
                                -2.0155273437500085
                            ],
                            [
                                -49.999218749999955,
                                -1.8318359374999744
                            ],
                            [
                                -49.71953125000002,
                                -1.926367187499963
                            ],
                            [
                                -49.31367187500001,
                                -1.7317382812500028
                            ],
                            [
                                -48.99130859374998,
                                -1.8297851562499972
                            ],
                            [
                                -48.11508789062498,
                                -0.7374999999999545
                            ],
                            [
                                -47.39809570312502,
                                -0.6266601562500256
                            ],
                            [
                                -46.81123046875001,
                                -0.7796875000000227
                            ],
                            [
                                -46.61723632812502,
                                -0.9706054687500227
                            ],
                            [
                                -46.219140624999994,
                                -1.0312500000000284
                            ],
                            [
                                -45.64477539062497,
                                -1.3478515624999545
                            ],
                            [
                                -45.07636718749998,
                                -1.4664062499999488
                            ],
                            [
                                -44.651269531249966,
                                -1.7458007812500256
                            ],
                            [
                                -44.30815429687496,
                                -2.53515625
                            ],
                            [
                                -43.93291015624999,
                                -2.583496093749986
                            ],
                            [
                                -42.93671874999998,
                                -2.4650390625000114
                            ],
                            [
                                -42.24960937499998,
                                -2.7919921875
                            ],
                            [
                                -41.876171874999926,
                                -2.746582031249986
                            ],
                            [
                                -41.318212890625034,
                                -2.936230468749997
                            ],
                            [
                                -40.474560546874926,
                                -2.7956054687500256
                            ],
                            [
                                -39.96469726562498,
                                -2.8615234374999545
                            ],
                            [
                                -38.475781249999955,
                                -3.717480468749997
                            ],
                            [
                                -38.04882812500003,
                                -4.216406250000034
                            ],
                            [
                                -37.174658203125006,
                                -4.912402343749974
                            ],
                            [
                                -36.590722656249966,
                                -5.097558593749952
                            ],
                            [
                                -35.979882812499966,
                                -5.05439453125004
                            ],
                            [
                                -35.549414062500006,
                                -5.129394531249957
                            ],
                            [
                                -35.235449218750006,
                                -5.56669921874996
                            ],
                            [
                                -34.988183593749994,
                                -6.393749999999969
                            ],
                            [
                                -34.80546874999999,
                                -7.288378906249974
                            ],
                            [
                                -34.83466796874998,
                                -7.97148437499996
                            ],
                            [
                                -35.15776367187499,
                                -8.930566406249952
                            ],
                            [
                                -35.34086914062499,
                                -9.230664062499983
                            ],
                            [
                                -36.39833984374994,
                                -10.484082031249983
                            ],
                            [
                                -36.93779296874999,
                                -10.820410156250034
                            ],
                            [
                                -37.41181640625001,
                                -11.497265624999983
                            ],
                            [
                                -37.68872070312503,
                                -12.100000000000037
                            ],
                            [
                                -38.23974609375003,
                                -12.844238281249957
                            ],
                            [
                                -38.787988281249966,
                                -12.782714843750028
                            ],
                            [
                                -38.835302734375034,
                                -13.147167968750026
                            ],
                            [
                                -39.08935546875,
                                -13.588183593749989
                            ],
                            [
                                -38.94233398437498,
                                -14.030664062499994
                            ],
                            [
                                -39.05957031249997,
                                -14.654785156249957
                            ],
                            [
                                -38.88061523437503,
                                -15.864257812499972
                            ],
                            [
                                -39.215234374999966,
                                -17.315820312500023
                            ],
                            [
                                -39.154003906249926,
                                -17.70390625000003
                            ],
                            [
                                -39.486767578124955,
                                -17.990136718749994
                            ],
                            [
                                -39.73979492187499,
                                -18.639843750000026
                            ],
                            [
                                -39.69985351562502,
                                -19.27783203124997
                            ],
                            [
                                -40.001367187499994,
                                -19.74199218750003
                            ],
                            [
                                -40.39594726562501,
                                -20.56943359375002
                            ],
                            [
                                -40.78925781250001,
                                -20.90605468750003
                            ],
                            [
                                -41.047265624999994,
                                -21.505664062499974
                            ],
                            [
                                -41.122509765624955,
                                -22.08437500000001
                            ],
                            [
                                -41.70551757812498,
                                -22.30966796874999
                            ],
                            [
                                -41.980419921874955,
                                -22.580664062499963
                            ],
                            [
                                -42.042382812499966,
                                -22.947070312500003
                            ],
                            [
                                -42.95830078124996,
                                -22.96708984374999
                            ],
                            [
                                -43.532812500000006,
                                -23.046386718749986
                            ],
                            [
                                -43.86616210937498,
                                -22.910546875000023
                            ],
                            [
                                -44.63725585937496,
                                -23.05546874999996
                            ],
                            [
                                -45.423291015624955,
                                -23.68535156250003
                            ],
                            [
                                -45.97207031250002,
                                -23.795507812500006
                            ],
                            [
                                -46.86728515624998,
                                -24.236328125000014
                            ],
                            [
                                -47.87656249999998,
                                -24.99746093750001
                            ],
                            [
                                -48.54516601562503,
                                -25.815917968750043
                            ],
                            [
                                -48.67773437499994,
                                -26.702929687500003
                            ],
                            [
                                -48.55415039062498,
                                -27.195996093749997
                            ],
                            [
                                -48.62080078124998,
                                -28.075585937499966
                            ],
                            [
                                -48.799658203125006,
                                -28.575292968749977
                            ],
                            [
                                -49.27128906249999,
                                -28.87119140625005
                            ],
                            [
                                -49.745996093749966,
                                -29.363183593749994
                            ],
                            [
                                -50.299511718749955,
                                -30.42578125000003
                            ],
                            [
                                -51.15727539062499,
                                -30.364257812500014
                            ],
                            [
                                -51.46367187499996,
                                -31.052636718750023
                            ],
                            [
                                -51.97246093749999,
                                -31.383789062499986
                            ],
                            [
                                -52.19355468749998,
                                -31.885546874999974
                            ],
                            [
                                -52.12739257812501,
                                -32.1677734375
                            ],
                            [
                                -52.652246093749994,
                                -33.137792968750006
                            ],
                            [
                                -53.37060546874997,
                                -33.74218750000003
                            ],
                            [
                                -53.39755859374995,
                                -33.737304687500014
                            ],
                            [
                                -53.46357421875001,
                                -33.70986328125002
                            ],
                            [
                                -53.51884765624999,
                                -33.67724609375004
                            ],
                            [
                                -53.531347656250034,
                                -33.65546875000004
                            ],
                            [
                                -53.53764648437499,
                                -33.622851562499974
                            ],
                            [
                                -53.531347656250034,
                                -33.1708984375
                            ],
                            [
                                -53.39521484375001,
                                -33.01035156249998
                            ],
                            [
                                -53.21406249999998,
                                -32.82109374999996
                            ],
                            [
                                -53.12558593749998,
                                -32.73671875000001
                            ],
                            [
                                -53.601708984374994,
                                -32.40302734374997
                            ],
                            [
                                -53.76171875,
                                -32.05683593749997
                            ],
                            [
                                -53.920605468749926,
                                -31.952343749999997
                            ],
                            [
                                -54.220556640625034,
                                -31.855175781249997
                            ],
                            [
                                -54.58764648437503,
                                -31.48515625000003
                            ],
                            [
                                -54.89599609374997,
                                -31.391210937499977
                            ],
                            [
                                -55.036035156249994,
                                -31.27900390625004
                            ],
                            [
                                -55.091162109375034,
                                -31.31396484374997
                            ],
                            [
                                -55.173535156249926,
                                -31.279589843749974
                            ],
                            [
                                -55.25463867187503,
                                -31.225585937499986
                            ],
                            [
                                -55.36606445312498,
                                -31.04619140625003
                            ],
                            [
                                -55.449560546875034,
                                -30.96445312499999
                            ],
                            [
                                -55.557324218749955,
                                -30.8759765625
                            ],
                            [
                                -55.62714843749998,
                                -30.858105468749997
                            ],
                            [
                                -55.650488281250034,
                                -30.89208984375
                            ],
                            [
                                -55.66523437500001,
                                -30.92490234375002
                            ],
                            [
                                -55.705957031249966,
                                -30.94658203124999
                            ],
                            [
                                -55.75634765625,
                                -30.98710937499999
                            ],
                            [
                                -55.807763671874994,
                                -31.036718749999977
                            ],
                            [
                                -55.87368164062502,
                                -31.069628906250017
                            ],
                            [
                                -55.95200195312498,
                                -31.08085937499999
                            ],
                            [
                                -56.00468749999996,
                                -31.079199218750006
                            ],
                            [
                                -56.015527343749966,
                                -31.059667968750034
                            ],
                            [
                                -56.01845703125002,
                                -30.991894531249983
                            ],
                            [
                                -55.998974609374955,
                                -30.837207031250003
                            ],
                            [
                                -56.4072265625,
                                -30.44746093750001
                            ],
                            [
                                -56.83271484374998,
                                -30.107226562499974
                            ],
                            [
                                -57.21445312499995,
                                -30.283398437499983
                            ],
                            [
                                -57.60888671875003,
                                -30.187792968750045
                            ],
                            [
                                -56.938623046874994,
                                -29.594824218750034
                            ],
                            [
                                -55.72548828125002,
                                -28.20410156250003
                            ],
                            [
                                -55.10151367187501,
                                -27.866796874999963
                            ],
                            [
                                -54.82910156250003,
                                -27.55058593750003
                            ],
                            [
                                -54.32700195312495,
                                -27.423535156249997
                            ],
                            [
                                -53.83818359375002,
                                -27.121093750000014
                            ],
                            [
                                -53.67128906249994,
                                -26.22509765625
                            ],
                            [
                                -53.89116210937499,
                                -25.66884765625001
                            ],
                            [
                                -54.15458984374999,
                                -25.523046874999963
                            ],
                            [
                                -54.615869140624994,
                                -25.576074218750023
                            ],
                            [
                                -54.47314453124997,
                                -25.22021484375
                            ],
                            [
                                -54.281005859375,
                                -24.30605468750001
                            ],
                            [
                                -54.31826171874994,
                                -24.128124999999997
                            ],
                            [
                                -54.26689453124996,
                                -24.06582031250001
                            ],
                            [
                                -54.241796875000006,
                                -24.047265624999966
                            ],
                            [
                                -54.44023437500002,
                                -23.90175781249998
                            ],
                            [
                                -54.62548828125,
                                -23.8125
                            ],
                            [
                                -54.98266601562494,
                                -23.974511718749966
                            ],
                            [
                                -55.081884765625006,
                                -23.997656249999977
                            ],
                            [
                                -55.1943359375,
                                -24.017480468750023
                            ],
                            [
                                -55.28691406249993,
                                -24.004296874999994
                            ],
                            [
                                -55.366308593750034,
                                -23.99101562499996
                            ],
                            [
                                -55.41591796875002,
                                -23.95136718749997
                            ],
                            [
                                -55.4423828125,
                                -23.86533203125002
                            ],
                            [
                                -55.4423828125,
                                -23.792578125000034
                            ],
                            [
                                -55.458886718749966,
                                -23.686718750000054
                            ],
                            [
                                -55.65405273437494,
                                -22.81035156250003
                            ],
                            [
                                -55.627587890624966,
                                -22.74091796875001
                            ],
                            [
                                -55.61767578125,
                                -22.67148437499999
                            ],
                            [
                                -55.64741210937501,
                                -22.621875000000003
                            ],
                            [
                                -55.70366210937502,
                                -22.592089843749974
                            ],
                            [
                                -55.74663085937499,
                                -22.51269531249997
                            ],
                            [
                                -55.753271484375006,
                                -22.410156250000043
                            ],
                            [
                                -55.84916992187499,
                                -22.307617187500014
                            ],
                            [
                                -56.18984374999994,
                                -22.28115234375005
                            ],
                            [
                                -56.35185546874999,
                                -22.178613281250023
                            ],
                            [
                                -56.52382812499994,
                                -22.102539062499986
                            ],
                            [
                                -56.63300781249998,
                                -22.23486328125003
                            ],
                            [
                                -56.77519531249999,
                                -22.261328125000006
                            ],
                            [
                                -57.23823242187501,
                                -22.195214843750037
                            ],
                            [
                                -57.56894531249998,
                                -22.18193359374999
                            ],
                            [
                                -57.721093749999966,
                                -22.09921875000002
                            ],
                            [
                                -57.76406250000002,
                                -22.109179687500003
                            ],
                            [
                                -57.82031250000003,
                                -22.142285156249997
                            ],
                            [
                                -57.94267578124999,
                                -21.79833984375
                            ],
                            [
                                -57.830224609374994,
                                -20.99794921875001
                            ],
                            [
                                -58.15976562499998,
                                -20.164648437499977
                            ],
                            [
                                -58.131494140624994,
                                -19.74453125000001
                            ],
                            [
                                -57.80039062499995,
                                -19.08095703125001
                            ],
                            [
                                -57.57402343749993,
                                -18.279296875000014
                            ],
                            [
                                -57.58647460937499,
                                -18.122265624999997
                            ],
                            [
                                -57.66166992187493,
                                -17.94736328124999
                            ],
                            [
                                -57.78017578125002,
                                -17.67177734374998
                            ],
                            [
                                -57.83247070312501,
                                -17.512109375000037
                            ],
                            [
                                -58.39599609374997,
                                -17.234277343750023
                            ],
                            [
                                -58.417382812499994,
                                -17.08056640624997
                            ],
                            [
                                -58.459814453125006,
                                -16.910742187500006
                            ],
                            [
                                -58.478125000000006,
                                -16.70068359375003
                            ],
                            [
                                -58.470605468749994,
                                -16.650195312500045
                            ],
                            [
                                -58.35039062500002,
                                -16.49082031249999
                            ],
                            [
                                -58.34560546875002,
                                -16.284375000000026
                            ],
                            [
                                -58.423681640625034,
                                -16.30791015625003
                            ],
                            [
                                -58.49658203124994,
                                -16.32666015625003
                            ],
                            [
                                -58.537939453125034,
                                -16.32822265624999
                            ],
                            [
                                -60.17558593749996,
                                -16.26933593749999
                            ],
                            [
                                -60.372705078124994,
                                -14.418750000000003
                            ],
                            [
                                -60.506591796875,
                                -13.78984374999996
                            ],
                            [
                                -60.914501953124955,
                                -13.561425781249966
                            ],
                            [
                                -61.077001953125034,
                                -13.489746093750014
                            ],
                            [
                                -61.129150390625,
                                -13.498535156250028
                            ],
                            [
                                -61.41606445312502,
                                -13.526562499999969
                            ],
                            [
                                -61.511572265625006,
                                -13.541210937500011
                            ],
                            [
                                -61.789941406249966,
                                -13.525585937500026
                            ],
                            [
                                -61.87412109374998,
                                -13.470410156249983
                            ],
                            [
                                -61.944726562499966,
                                -13.406249999999972
                            ],
                            [
                                -62.09477539062499,
                                -13.241992187499989
                            ],
                            [
                                -62.118017578125006,
                                -13.15976562500002
                            ],
                            [
                                -62.17607421874993,
                                -13.133691406250037
                            ],
                            [
                                -62.35283203124999,
                                -13.132421874999963
                            ],
                            [
                                -62.765478515625034,
                                -12.99726562500004
                            ],
                            [
                                -63.01518554687502,
                                -12.80556640624998
                            ],
                            [
                                -63.067480468750006,
                                -12.669140624999983
                            ],
                            [
                                -63.34667968749994,
                                -12.680078124999994
                            ],
                            [
                                -63.585644531249955,
                                -12.518945312500037
                            ],
                            [
                                -63.68857421874998,
                                -12.478027343749957
                            ],
                            [
                                -63.7880859375,
                                -12.469433593749983
                            ],
                            [
                                -63.938574218750006,
                                -12.529687499999994
                            ],
                            [
                                -64.42050781249995,
                                -12.439746093749974
                            ],
                            [
                                -64.51342773437497,
                                -12.250976562499972
                            ],
                            [
                                -64.99252929687498,
                                -11.975195312500006
                            ],
                            [
                                -65.389892578125,
                                -11.246289062500011
                            ],
                            [
                                -65.33403320312499,
                                -10.892773437500026
                            ],
                            [
                                -65.43999023437499,
                                -10.586230468750017
                            ],
                            [
                                -65.4369140625,
                                -10.449023437499946
                            ],
                            [
                                -65.39545898437498,
                                -10.392285156250026
                            ],
                            [
                                -65.31308593749998,
                                -10.253027343749991
                            ],
                            [
                                -65.39614257812494,
                                -9.712402343749986
                            ],
                            [
                                -66.26357421875,
                                -9.826074218749966
                            ],
                            [
                                -66.57534179687502,
                                -9.899902343749986
                            ],
                            [
                                -66.72998046875,
                                -9.975488281250023
                            ],
                            [
                                -67.11152343750001,
                                -10.268945312500037
                            ],
                            [
                                -67.28046874999995,
                                -10.317285156250023
                            ],
                            [
                                -67.33271484374995,
                                -10.357910156249957
                            ],
                            [
                                -67.41694335937495,
                                -10.389843749999969
                            ],
                            [
                                -67.58242187500002,
                                -10.505957031250006
                            ],
                            [
                                -67.72177734374998,
                                -10.683105468749943
                            ],
                            [
                                -67.83500976562496,
                                -10.662792968749983
                            ],
                            [
                                -67.99169921875,
                                -10.674414062499949
                            ],
                            [
                                -68.07167968749994,
                                -10.703125000000028
                            ],
                            [
                                -68.678369140625,
                                -11.11279296875
                            ],
                            [
                                -68.84833984374998,
                                -11.01113281249998
                            ],
                            [
                                -69.228515625,
                                -10.955664062499963
                            ],
                            [
                                -69.96035156249997,
                                -10.92988281250004
                            ],
                            [
                                -70.642333984375,
                                -11.010253906249986
                            ],
                            [
                                -70.59916992187499,
                                -9.620507812500009
                            ],
                            [
                                -71.11528320312499,
                                -9.852441406250009
                            ],
                            [
                                -71.237939453125,
                                -9.966015624999955
                            ],
                            [
                                -72.18159179687495,
                                -10.003710937500003
                            ],
                            [
                                -72.37905273437497,
                                -9.510156249999994
                            ],
                            [
                                -73.20942382812493,
                                -9.411425781249946
                            ],
                            [
                                -73.08984375,
                                -9.26572265625002
                            ],
                            [
                                -72.970361328125,
                                -9.120117187500028
                            ],
                            [
                                -72.97402343750002,
                                -8.9931640625
                            ],
                            [
                                -73.07050781249995,
                                -8.8828125
                            ],
                            [
                                -73.20312499999997,
                                -8.719335937499991
                            ],
                            [
                                -73.30244140624995,
                                -8.654003906250011
                            ],
                            [
                                -73.36040039062496,
                                -8.479296875000031
                            ],
                            [
                                -73.39814453125001,
                                -8.458984374999986
                            ],
                            [
                                -73.43588867187498,
                                -8.42705078124996
                            ],
                            [
                                -73.54912109374993,
                                -8.34580078125002
                            ],
                            [
                                -73.77558593749998,
                                -7.9364257812500085
                            ],
                            [
                                -73.72041015624993,
                                -7.782519531250017
                            ],
                            [
                                -73.76689453124999,
                                -7.753515624999963
                            ],
                            [
                                -73.82207031249996,
                                -7.738964843750026
                            ],
                            [
                                -73.89462890624998,
                                -7.654785156250014
                            ],
                            [
                                -73.94687499999998,
                                -7.611230468750023
                            ],
                            [
                                -73.98173828124996,
                                -7.58505859375002
                            ],
                            [
                                -74.00205078125003,
                                -7.556054687499966
                            ],
                            [
                                -73.98173828124996,
                                -7.535742187500006
                            ],
                            [
                                -73.95849609374994,
                                -7.506640625000031
                            ],
                            [
                                -73.96430664062498,
                                -7.378906250000028
                            ],
                            [
                                -73.74946289062498,
                                -7.335351562500037
                            ],
                            [
                                -73.79301757812499,
                                -7.135058593750003
                            ],
                            [
                                -73.75810546874999,
                                -6.90576171875
                            ],
                            [
                                -73.137353515625,
                                -6.4658203125
                            ],
                            [
                                -73.23554687500001,
                                -6.098437500000017
                            ],
                            [
                                -73.209375,
                                -6.028710937500023
                            ],
                            [
                                -73.16289062499996,
                                -5.933398437499974
                            ],
                            [
                                -72.97988281249997,
                                -5.6348632812499915
                            ],
                            [
                                -72.88706054687498,
                                -5.122753906250026
                            ],
                            [
                                -72.83193359374994,
                                -5.093749999999972
                            ],
                            [
                                -72.69873046874997,
                                -5.067187499999989
                            ],
                            [
                                -72.60834960937495,
                                -5.009570312499974
                            ],
                            [
                                -72.46899414062497,
                                -4.901269531250023
                            ],
                            [
                                -72.35283203124993,
                                -4.786035156249994
                            ],
                            [
                                -72.25678710937501,
                                -4.74892578124998
                            ],
                            [
                                -71.8447265625,
                                -4.504394531249986
                            ],
                            [
                                -70.97368164062499,
                                -4.350488281249994
                            ],
                            [
                                -70.86601562499999,
                                -4.229589843749963
                            ],
                            [
                                -70.79951171874995,
                                -4.173339843749957
                            ],
                            [
                                -70.72158203124997,
                                -4.15888671875004
                            ],
                            [
                                -70.53066406249997,
                                -4.167578125000034
                            ],
                            [
                                -70.40463867187498,
                                -4.150097656250026
                            ],
                            [
                                -69.96591796875003,
                                -4.235937500000006
                            ],
                            [
                                -69.66904296875003,
                                -2.667675781249997
                            ],
                            [
                                -69.40024414062498,
                                -1.1949218749999773
                            ],
                            [
                                -69.66748046874997,
                                -0.48242187499995737
                            ],
                            [
                                -70.07050781249993,
                                -0.1388671875000398
                            ],
                            [
                                -70.05390624999993,
                                0.5786132812500284
                            ],
                            [
                                -69.16323242187502,
                                0.8640625000000028
                            ],
                            [
                                -69.36137695312496,
                                1.0640136718749744
                            ],
                            [
                                -69.85214843750003,
                                1.0595214843750398
                            ],
                            [
                                -69.84858398437493,
                                1.7087402343750426
                            ],
                            [
                                -68.17656249999999,
                                1.7198242187499915
                            ],
                            [
                                -67.815087890625,
                                1.790087890625017
                            ],
                            [
                                -67.35195312499997,
                                2.085839843750051
                            ],
                            [
                                -66.87602539062499,
                                1.223046875000037
                            ],
                            [
                                -66.42924804687502,
                                0.8216796874999801
                            ],
                            [
                                -66.06005859375003,
                                0.7853515625000398
                            ],
                            [
                                -65.68144531249999,
                                0.9834472656249886
                            ],
                            [
                                -65.36083984374994,
                                0.8686523437500568
                            ],
                            [
                                -65.10375976562497,
                                1.108105468749983
                            ],
                            [
                                -64.11484375000003,
                                1.619287109375037
                            ],
                            [
                                -64.03544921874993,
                                1.904443359375037
                            ],
                            [
                                -63.393945312499994,
                                2.2225097656250057
                            ],
                            [
                                -63.389257812500006,
                                2.4119140625000455
                            ],
                            [
                                -63.924169921875006,
                                2.4524414062500313
                            ],
                            [
                                -64.21884765625,
                                3.2046874999999915
                            ],
                            [
                                -64.22109375000002,
                                3.5874023437499716
                            ],
                            [
                                -64.56791992187496,
                                3.8998046875000227
                            ],
                            [
                                -64.25566406249996,
                                4.140332031249997
                            ],
                            [
                                -64.02148437500003,
                                3.929101562500051
                            ],
                            [
                                -63.29472656249993,
                                3.92226562499998
                            ],
                            [
                                -62.96865234374994,
                                3.593945312499983
                            ],
                            [
                                -62.71210937499998,
                                4.01791992187502
                            ],
                            [
                                -62.15312499999996,
                                4.098388671874986
                            ],
                            [
                                -61.00283203125002,
                                4.5352539062499915
                            ],
                            [
                                -60.60449218749994,
                                4.99458007812504
                            ],
                            [
                                -60.671972656250034,
                                5.164355468749989
                            ],
                            [
                                -60.71196289062499,
                                5.191552734375023
                            ],
                            [
                                -60.742138671874926,
                                5.202050781250037
                            ],
                            [
                                -60.6513671875,
                                5.221142578125011
                            ],
                            [
                                -60.576416015625,
                                5.192480468750034
                            ],
                            [
                                -60.45952148437499,
                                5.188085937500034
                            ],
                            [
                                -60.40878906249998,
                                5.210156249999997
                            ],
                            [
                                -60.33520507812497,
                                5.199316406250006
                            ],
                            [
                                -60.14204101562498,
                                5.238818359374974
                            ],
                            [
                                -59.990673828124955,
                                5.0828613281249915
                            ],
                            [
                                -60.03178710937499,
                                4.740527343749974
                            ],
                            [
                                -60.12456054687496,
                                4.59765625
                            ],
                            [
                                -60.14863281249998,
                                4.533251953125031
                            ],
                            [
                                -60.11113281249999,
                                4.511181640624969
                            ],
                            [
                                -59.69970703125,
                                4.353515625
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Brazil',
                'childNum': 3
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            115.02675781250005,
                            4.899707031249989
                        ],
                        [
                            114.74667968750006,
                            4.718066406250017
                        ],
                        [
                            114.84023437500005,
                            4.3932128906250085
                        ],
                        [
                            114.60830078125005,
                            4.023974609375017
                        ],
                        [
                            114.0638671875,
                            4.592675781249966
                        ],
                        [
                            114.29941406250012,
                            4.607177734374986
                        ],
                        [
                            115.02675781250005,
                            4.899707031249989
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Brunei',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            91.63193359375003,
                            27.759960937499997
                        ],
                        [
                            91.5947265625,
                            27.557666015624996
                        ],
                        [
                            91.74306640625002,
                            27.442529296874994
                        ],
                        [
                            91.85126953125001,
                            27.438623046874994
                        ],
                        [
                            91.95097656249999,
                            27.458300781249996
                        ],
                        [
                            91.99082031250003,
                            27.4501953125
                        ],
                        [
                            92.044921875,
                            27.364697265624997
                        ],
                        [
                            92.08339843750002,
                            27.290625
                        ],
                        [
                            92.03115234375002,
                            27.214306640624997
                        ],
                        [
                            91.99228515625003,
                            27.099902343749996
                        ],
                        [
                            92.07343750000001,
                            26.914843749999996
                        ],
                        [
                            92.04970703125002,
                            26.874853515625
                        ],
                        [
                            91.99833984374999,
                            26.85498046875
                        ],
                        [
                            91.84208984374999,
                            26.852978515624997
                        ],
                        [
                            91.67158203125001,
                            26.802001953125
                        ],
                        [
                            90.73964843750002,
                            26.771679687499997
                        ],
                        [
                            90.34589843750001,
                            26.890332031249997
                        ],
                        [
                            90.2060546875,
                            26.847509765625
                        ],
                        [
                            90.12294921875002,
                            26.754589843749997
                        ],
                        [
                            89.94316406249999,
                            26.723925781249996
                        ],
                        [
                            89.76386718750001,
                            26.701562499999994
                        ],
                        [
                            89.60996093750003,
                            26.719433593749997
                        ],
                        [
                            89.33212890625003,
                            26.8486328125
                        ],
                        [
                            89.14824218749999,
                            26.816162109375
                        ],
                        [
                            88.85761718750001,
                            26.961474609374996
                        ],
                        [
                            88.73876953125,
                            27.175585937499996
                        ],
                        [
                            88.76035156250003,
                            27.218115234375
                        ],
                        [
                            88.88164062499999,
                            27.2974609375
                        ],
                        [
                            88.89140624999999,
                            27.316064453124994
                        ],
                        [
                            88.94755859374999,
                            27.464013671874994
                        ],
                        [
                            89.53691406249999,
                            28.107421875
                        ],
                        [
                            89.65273437500002,
                            28.15830078125
                        ],
                        [
                            89.74980468749999,
                            28.188183593749997
                        ],
                        [
                            89.81689453125,
                            28.256298828124997
                        ],
                        [
                            89.89785156250002,
                            28.294140624999997
                        ],
                        [
                            89.98105468750003,
                            28.311181640624994
                        ],
                        [
                            90.35273437500001,
                            28.080224609374994
                        ],
                        [
                            90.47734374999999,
                            28.070849609374996
                        ],
                        [
                            90.71572265625002,
                            28.071728515624997
                        ],
                        [
                            91.07773437500003,
                            27.974462890625
                        ],
                        [
                            91.22587890624999,
                            28.071240234374997
                        ],
                        [
                            91.27304687500003,
                            28.078369140625
                        ],
                        [
                            91.60556640625003,
                            27.951708984374996
                        ],
                        [
                            91.64189453124999,
                            27.923242187499994
                        ],
                        [
                            91.63193359375003,
                            27.759960937499997
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Bhutan',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            25.2587890625,
                            -17.793554687500006
                        ],
                        [
                            25.224023437500023,
                            -17.91523437500001
                        ],
                        [
                            25.76123046875,
                            -18.649218750000003
                        ],
                        [
                            26.168066406250006,
                            -19.53828125000001
                        ],
                        [
                            26.67822265625,
                            -19.89277343750001
                        ],
                        [
                            27.221484375000017,
                            -20.145800781250003
                        ],
                        [
                            27.28076171875,
                            -20.47871093750001
                        ],
                        [
                            27.699609375000023,
                            -20.53066406250001
                        ],
                        [
                            27.66943359375,
                            -21.064257812500003
                        ],
                        [
                            28.04560546875001,
                            -21.573046875000003
                        ],
                        [
                            29.03730468750001,
                            -21.811328125000003
                        ],
                        [
                            29.106835937500023,
                            -22.065722656250003
                        ],
                        [
                            29.237207031250023,
                            -22.07949218750001
                        ],
                        [
                            29.315234375000017,
                            -22.15771484375
                        ],
                        [
                            29.364843750000006,
                            -22.193945312500006
                        ],
                        [
                            29.1298828125,
                            -22.21328125000001
                        ],
                        [
                            29.013476562500017,
                            -22.278417968750006
                        ],
                        [
                            28.94580078125,
                            -22.395117187500006
                        ],
                        [
                            28.83984375,
                            -22.48085937500001
                        ],
                        [
                            28.21015625000001,
                            -22.693652343750003
                        ],
                        [
                            27.812597656250006,
                            -23.108007812500006
                        ],
                        [
                            27.7685546875,
                            -23.14892578125
                        ],
                        [
                            27.146386718750023,
                            -23.5244140625
                        ],
                        [
                            27.085546875000006,
                            -23.577929687500003
                        ],
                        [
                            26.98701171875001,
                            -23.70458984375
                        ],
                        [
                            26.835058593750006,
                            -24.240820312500006
                        ],
                        [
                            26.617773437500006,
                            -24.3955078125
                        ],
                        [
                            26.501562500000006,
                            -24.513281250000006
                        ],
                        [
                            26.451757812500006,
                            -24.58271484375001
                        ],
                        [
                            26.39716796875001,
                            -24.613574218750003
                        ],
                        [
                            25.912109375,
                            -24.74746093750001
                        ],
                        [
                            25.518164062500006,
                            -25.66279296875001
                        ],
                        [
                            24.748144531250006,
                            -25.8173828125
                        ],
                        [
                            24.33056640625,
                            -25.74287109375001
                        ],
                        [
                            24.192968750000006,
                            -25.632910156250006
                        ],
                        [
                            23.969531250000017,
                            -25.626074218750006
                        ],
                        [
                            23.89375000000001,
                            -25.600878906250003
                        ],
                        [
                            23.670703125000017,
                            -25.43398437500001
                        ],
                        [
                            23.521484375,
                            -25.34443359375001
                        ],
                        [
                            23.148730468750017,
                            -25.288671875000006
                        ],
                        [
                            22.878808593750023,
                            -25.45791015625001
                        ],
                        [
                            22.79609375000001,
                            -25.67910156250001
                        ],
                        [
                            22.72900390625,
                            -25.85732421875001
                        ],
                        [
                            22.640234375000006,
                            -26.07119140625001
                        ],
                        [
                            22.217578125000017,
                            -26.38886718750001
                        ],
                        [
                            22.090917968750006,
                            -26.580175781250006
                        ],
                        [
                            22.01093750000001,
                            -26.635839843750006
                        ],
                        [
                            21.91455078125,
                            -26.661914062500003
                        ],
                        [
                            21.83320312500001,
                            -26.678320312500006
                        ],
                        [
                            21.78828125000001,
                            -26.710058593750006
                        ],
                        [
                            21.738085937500017,
                            -26.80683593750001
                        ],
                        [
                            21.694726562500023,
                            -26.840917968750006
                        ],
                        [
                            21.646289062500017,
                            -26.85419921875001
                        ],
                        [
                            20.739843750000006,
                            -26.84882812500001
                        ],
                        [
                            20.641406250000017,
                            -26.7421875
                        ],
                        [
                            20.79316406250001,
                            -25.915625000000006
                        ],
                        [
                            20.34521484375,
                            -25.029882812500006
                        ],
                        [
                            19.98046875,
                            -24.77675781250001
                        ],
                        [
                            19.977343750000017,
                            -22.00019531250001
                        ],
                        [
                            20.970996093750017,
                            -22.00019531250001
                        ],
                        [
                            20.976171875000006,
                            -19.74433593750001
                        ],
                        [
                            20.97412109375,
                            -18.31884765625
                        ],
                        [
                            23.298632812500017,
                            -18.02734375
                        ],
                        [
                            23.58056640625,
                            -18.452929687500003
                        ],
                        [
                            24.358984375000006,
                            -17.97822265625001
                        ],
                        [
                            25.2587890625,
                            -17.793554687500006
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Botswana',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            22.86005859375001,
                            10.919677734375
                        ],
                        [
                            23.312304687500017,
                            10.387939453125
                        ],
                        [
                            23.646289062500017,
                            9.822900390624994
                        ],
                        [
                            23.53730468750001,
                            8.815820312499994
                        ],
                        [
                            24.147363281250023,
                            8.665624999999991
                        ],
                        [
                            24.291406250000023,
                            8.291406249999994
                        ],
                        [
                            24.853320312500017,
                            8.137548828124991
                        ],
                        [
                            25.247363281250017,
                            7.724560546874997
                        ],
                        [
                            25.380664062500017,
                            7.333398437499994
                        ],
                        [
                            25.888964843750017,
                            7.06494140625
                        ],
                        [
                            26.36181640625,
                            6.635302734374989
                        ],
                        [
                            26.514257812500006,
                            6.069238281249994
                        ],
                        [
                            27.083398437500023,
                            5.77685546875
                        ],
                        [
                            27.4033203125,
                            5.109179687499989
                        ],
                        [
                            27.11494140625001,
                            5.1978515624999915
                        ],
                        [
                            26.822070312500017,
                            5.062402343749994
                        ],
                        [
                            25.52509765625001,
                            5.3121093749999915
                        ],
                        [
                            25.249316406250017,
                            5.024560546874994
                        ],
                        [
                            24.765527343750023,
                            4.930078124999994
                        ],
                        [
                            24.31982421875,
                            4.994140625
                        ],
                        [
                            23.41718750000001,
                            4.663134765624989
                        ],
                        [
                            22.755761718750023,
                            4.646679687499997
                        ],
                        [
                            22.422167968750017,
                            4.134960937499997
                        ],
                        [
                            21.53759765625,
                            4.244824218749997
                        ],
                        [
                            20.55810546875,
                            4.462695312499989
                        ],
                        [
                            20.226367187500017,
                            4.829638671874989
                        ],
                        [
                            19.806542968750023,
                            5.089306640624997
                        ],
                        [
                            19.5009765625,
                            5.127490234374989
                        ],
                        [
                            19.06855468750001,
                            4.891406249999989
                        ],
                        [
                            18.567480468750006,
                            4.257568359375
                        ],
                        [
                            18.6103515625,
                            3.4784179687499943
                        ],
                        [
                            18.160937500000017,
                            3.4998046874999886
                        ],
                        [
                            17.43798828125,
                            3.6846191406249886
                        ],
                        [
                            16.67333984375,
                            3.5352050781249886
                        ],
                        [
                            16.468554687500017,
                            2.831738281249997
                        ],
                        [
                            16.183398437500017,
                            2.2700683593749886
                        ],
                        [
                            16.0634765625,
                            2.9085937499999943
                        ],
                        [
                            15.775000000000006,
                            3.127197265625
                        ],
                        [
                            15.128710937500017,
                            3.826904296875
                        ],
                        [
                            15.022753906250017,
                            4.358544921874994
                        ],
                        [
                            14.708984375,
                            4.665576171874989
                        ],
                        [
                            14.616894531250011,
                            5.865136718749994
                        ],
                        [
                            14.7392578125,
                            6.27978515625
                        ],
                        [
                            15.245898437500017,
                            7.263574218749994
                        ],
                        [
                            15.480078125000006,
                            7.5237792968749915
                        ],
                        [
                            15.845019531250017,
                            7.475292968749997
                        ],
                        [
                            16.37890625,
                            7.683544921874997
                        ],
                        [
                            16.784765625000006,
                            7.550976562499997
                        ],
                        [
                            17.6494140625,
                            7.983593749999997
                        ],
                        [
                            18.455078125,
                            8.032031249999989
                        ],
                        [
                            19.108691406250017,
                            8.656152343749994
                        ],
                        [
                            19.1455078125,
                            9.015966796874991
                        ],
                        [
                            20.342089843750017,
                            9.127099609374994
                        ],
                        [
                            20.773242187500017,
                            9.405664062499994
                        ],
                        [
                            21.26386718750001,
                            9.974609375
                        ],
                        [
                            21.72578125000001,
                            10.366552734374991
                        ],
                        [
                            21.70654296875,
                            10.574804687499991
                        ],
                        [
                            22.49384765625001,
                            10.996240234374994
                        ],
                        [
                            22.86005859375001,
                            10.919677734375
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Central African Republic',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                -61.10517578124998,
                                45.94472656250002
                            ],
                            [
                                -60.80610351562498,
                                45.73808593750002
                            ],
                            [
                                -60.50493164062502,
                                46.20385742187497
                            ],
                            [
                                -59.86503906249993,
                                46.159521484375006
                            ],
                            [
                                -60.38608398437495,
                                45.65463867187498
                            ],
                            [
                                -61.28369140624994,
                                45.573876953124966
                            ],
                            [
                                -61.49531249999998,
                                45.94145507812499
                            ],
                            [
                                -60.87016601562499,
                                46.796777343749966
                            ],
                            [
                                -60.33173828124998,
                                46.76782226562503
                            ],
                            [
                                -60.57685546875001,
                                46.17216796874999
                            ],
                            [
                                -61.10517578124998,
                                45.94472656250002
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -63.811279296875,
                                46.46870117187501
                            ],
                            [
                                -63.12939453125,
                                46.422216796875034
                            ],
                            [
                                -62.16357421874997,
                                46.487207031249966
                            ],
                            [
                                -62.878369140624926,
                                46.00136718750002
                            ],
                            [
                                -63.641015624999966,
                                46.23046874999997
                            ],
                            [
                                -63.811279296875,
                                46.46870117187501
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -74.70888671874997,
                                45.0038574218751
                            ],
                            [
                                -73.55810546875,
                                45.425097656250045
                            ],
                            [
                                -73.1595703125,
                                46.01005859375002
                            ],
                            [
                                -71.90092773437499,
                                46.63193359375006
                            ],
                            [
                                -71.26118164062495,
                                46.756249999999966
                            ],
                            [
                                -70.51948242187501,
                                47.032519531250045
                            ],
                            [
                                -68.987060546875,
                                48.275000000000034
                            ],
                            [
                                -67.56088867187498,
                                48.85595703125
                            ],
                            [
                                -66.17817382812493,
                                49.21313476562503
                            ],
                            [
                                -64.83632812499994,
                                49.191748046875006
                            ],
                            [
                                -64.26181640624995,
                                48.921875
                            ],
                            [
                                -64.253759765625,
                                48.550390625000006
                            ],
                            [
                                -65.259423828125,
                                48.02124023437503
                            ],
                            [
                                -65.92670898437495,
                                48.188867187499994
                            ],
                            [
                                -65.66645507812495,
                                47.69614257812506
                            ],
                            [
                                -65.22817382812502,
                                47.81127929687497
                            ],
                            [
                                -64.70322265625,
                                47.72485351562503
                            ],
                            [
                                -64.91220703125003,
                                47.36865234375003
                            ],
                            [
                                -65.31889648437502,
                                47.101220703124994
                            ],
                            [
                                -64.90576171874997,
                                46.88793945312497
                            ],
                            [
                                -64.54150390625,
                                46.240332031250034
                            ],
                            [
                                -63.29277343749999,
                                45.751953125
                            ],
                            [
                                -62.483056640624966,
                                45.62182617187506
                            ],
                            [
                                -61.955517578124955,
                                45.86816406249997
                            ],
                            [
                                -61.49228515624998,
                                45.68701171875
                            ],
                            [
                                -61.28378906249998,
                                45.23549804687505
                            ],
                            [
                                -63.761132812499994,
                                44.486425781250034
                            ],
                            [
                                -64.28608398437493,
                                44.55034179687499
                            ],
                            [
                                -64.86235351562499,
                                43.867871093749955
                            ],
                            [
                                -65.73813476562498,
                                43.56074218750001
                            ],
                            [
                                -66.125732421875,
                                43.813818359375034
                            ],
                            [
                                -66.09956054687498,
                                44.367480468750045
                            ],
                            [
                                -65.65673828125,
                                44.760302734375045
                            ],
                            [
                                -64.44882812499995,
                                45.256054687499955
                            ],
                            [
                                -64.89790039062495,
                                45.6259765625
                            ],
                            [
                                -65.88447265624995,
                                45.22290039062506
                            ],
                            [
                                -66.87246093749997,
                                45.067285156249966
                            ],
                            [
                                -67.12485351562498,
                                45.16943359375
                            ],
                            [
                                -67.47255859375002,
                                45.27587890625
                            ],
                            [
                                -67.43266601562496,
                                45.60312499999998
                            ],
                            [
                                -67.78466796875,
                                45.70170898437499
                            ],
                            [
                                -67.806787109375,
                                47.08281249999999
                            ],
                            [
                                -68.310888671875,
                                47.3544921875
                            ],
                            [
                                -68.82871093749998,
                                47.20332031250001
                            ],
                            [
                                -69.24287109374998,
                                47.46298828124998
                            ],
                            [
                                -70.00771484375002,
                                46.70893554687501
                            ],
                            [
                                -70.296240234375,
                                45.90610351562506
                            ],
                            [
                                -70.89799804687493,
                                45.26245117187503
                            ],
                            [
                                -71.327294921875,
                                45.29008789062496
                            ],
                            [
                                -71.51752929687495,
                                45.00756835937497
                            ],
                            [
                                -74.663232421875,
                                45.00390625000003
                            ],
                            [
                                -74.70888671874997,
                                45.0038574218751
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -61.801123046875034,
                                49.093896484374966
                            ],
                            [
                                -62.21953124999999,
                                49.07910156249997
                            ],
                            [
                                -63.565869140624926,
                                49.39931640625002
                            ],
                            [
                                -64.13144531249995,
                                49.94165039062503
                            ],
                            [
                                -62.858544921874966,
                                49.70546875000005
                            ],
                            [
                                -61.817138671875,
                                49.28354492187498
                            ],
                            [
                                -61.801123046875034,
                                49.093896484374966
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -127.19731445312495,
                                50.640380859375
                            ],
                            [
                                -125.48208007812501,
                                50.316796874999966
                            ],
                            [
                                -124.64287109375002,
                                49.428662109374955
                            ],
                            [
                                -123.99580078125,
                                49.22402343750002
                            ],
                            [
                                -123.49702148437498,
                                48.58208007812499
                            ],
                            [
                                -123.59462890624998,
                                48.33354492187496
                            ],
                            [
                                -124.689404296875,
                                48.597314453124966
                            ],
                            [
                                -125.82851562499998,
                                49.09184570312499
                            ],
                            [
                                -125.83544921874997,
                                49.27666015624999
                            ],
                            [
                                -126.54853515625003,
                                49.41894531250003
                            ],
                            [
                                -126.68310546875,
                                49.87646484374997
                            ],
                            [
                                -127.114306640625,
                                49.879736328125034
                            ],
                            [
                                -128.267431640625,
                                50.609277343749966
                            ],
                            [
                                -127.91806640624998,
                                50.86054687500001
                            ],
                            [
                                -127.19731445312495,
                                50.640380859375
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -55.45874023437494,
                                51.53652343750005
                            ],
                            [
                                -55.87138671874999,
                                50.90737304687502
                            ],
                            [
                                -56.693994140624966,
                                50.059667968750006
                            ],
                            [
                                -55.764746093750034,
                                49.96044921875003
                            ],
                            [
                                -55.892041015624926,
                                49.58027343750004
                            ],
                            [
                                -55.02617187500002,
                                49.30537109374998
                            ],
                            [
                                -54.579052734374955,
                                49.490820312500006
                            ],
                            [
                                -53.957714843749955,
                                49.44184570312498
                            ],
                            [
                                -53.57343750000001,
                                49.141210937500034
                            ],
                            [
                                -53.852880859375006,
                                48.81132812499996
                            ],
                            [
                                -53.53120117187501,
                                48.231884765624955
                            ],
                            [
                                -52.95498046874994,
                                48.02929687499997
                            ],
                            [
                                -53.17553710937503,
                                47.652978515624966
                            ],
                            [
                                -52.653662109375034,
                                47.549414062500006
                            ],
                            [
                                -53.06977539062498,
                                46.68125000000006
                            ],
                            [
                                -53.61635742187494,
                                46.680273437500034
                            ],
                            [
                                -54.173730468749994,
                                46.88037109375003
                            ],
                            [
                                -53.86909179687501,
                                47.38701171874996
                            ],
                            [
                                -54.856640624999955,
                                47.385009765625
                            ],
                            [
                                -56.22128906249995,
                                47.67138671874997
                            ],
                            [
                                -56.95249023437498,
                                47.57446289062506
                            ],
                            [
                                -58.33320312499998,
                                47.676855468750034
                            ],
                            [
                                -59.11694335937494,
                                47.570703124999994
                            ],
                            [
                                -59.36240234374995,
                                47.865673828124955
                            ],
                            [
                                -58.71059570312494,
                                48.325048828125006
                            ],
                            [
                                -58.716455078124994,
                                48.59804687500002
                            ],
                            [
                                -58.01582031249998,
                                49.54248046874997
                            ],
                            [
                                -57.29799804687502,
                                50.69873046874997
                            ],
                            [
                                -56.75019531249998,
                                51.27490234375003
                            ],
                            [
                                -56.025585937499955,
                                51.56835937500006
                            ],
                            [
                                -55.45874023437494,
                                51.53652343750005
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -80.73168945312494,
                                52.74726562499998
                            ],
                            [
                                -81.83906249999995,
                                52.957910156249994
                            ],
                            [
                                -81.84731445312494,
                                53.18627929687497
                            ],
                            [
                                -81.135595703125,
                                53.20581054687503
                            ],
                            [
                                -80.73168945312494,
                                52.74726562499998
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -131.7537109375,
                                53.195556640625
                            ],
                            [
                                -131.727294921875,
                                52.75639648437496
                            ],
                            [
                                -132.16508789062493,
                                52.783300781250034
                            ],
                            [
                                -132.34541015625,
                                53.136083984375034
                            ],
                            [
                                -131.7537109375,
                                53.195556640625
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -132.65551757812503,
                                54.12749023437496
                            ],
                            [
                                -131.70253906250002,
                                53.98637695312499
                            ],
                            [
                                -132.01132812499998,
                                53.26518554687496
                            ],
                            [
                                -132.747509765625,
                                53.310498046874955
                            ],
                            [
                                -133.05224609375003,
                                53.77812500000002
                            ],
                            [
                                -132.65551757812503,
                                54.12749023437496
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -78.93559570312496,
                                56.26606445312498
                            ],
                            [
                                -79.17548828124998,
                                55.88505859374999
                            ],
                            [
                                -80.00078124999999,
                                55.93208007812498
                            ],
                            [
                                -79.59633789062502,
                                56.244482421875034
                            ],
                            [
                                -78.93559570312496,
                                56.26606445312498
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -79.54531250000002,
                                62.41171875000006
                            ],
                            [
                                -79.32392578124995,
                                62.02607421875001
                            ],
                            [
                                -79.71425781249997,
                                61.61254882812503
                            ],
                            [
                                -80.27617187499996,
                                61.85859374999998
                            ],
                            [
                                -80.23466796874996,
                                62.152685546875034
                            ],
                            [
                                -79.54531250000002,
                                62.41171875000006
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -82.00048828124997,
                                62.95419921874998
                            ],
                            [
                                -82.11372070312495,
                                62.65224609375002
                            ],
                            [
                                -83.01582031249998,
                                62.20991210937498
                            ],
                            [
                                -83.69887695312497,
                                62.16025390624998
                            ],
                            [
                                -83.899267578125,
                                62.476464843749994
                            ],
                            [
                                -83.37641601562498,
                                62.904931640624994
                            ],
                            [
                                -82.00048828124997,
                                62.95419921874998
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -84.91962890624995,
                                65.26108398437503
                            ],
                            [
                                -83.40712890625002,
                                65.10390625
                            ],
                            [
                                -82.04999999999995,
                                64.64428710937506
                            ],
                            [
                                -81.71611328124996,
                                64.021875
                            ],
                            [
                                -80.82895507812495,
                                64.08994140625
                            ],
                            [
                                -80.30205078124999,
                                63.76220703125003
                            ],
                            [
                                -81.04638671875003,
                                63.461572265624966
                            ],
                            [
                                -82.37812500000001,
                                63.706787109375
                            ],
                            [
                                -82.46708984375002,
                                63.92695312500001
                            ],
                            [
                                -83.49433593749993,
                                64.09921875000003
                            ],
                            [
                                -83.728271484375,
                                63.813378906249966
                            ],
                            [
                                -84.63291015625,
                                63.30922851562502
                            ],
                            [
                                -85.49550781249997,
                                63.13911132812501
                            ],
                            [
                                -85.71416015625002,
                                63.657958984375
                            ],
                            [
                                -87.18891601562498,
                                63.672265624999994
                            ],
                            [
                                -86.252099609375,
                                64.13686523437497
                            ],
                            [
                                -86.37426757812503,
                                64.56582031249997
                            ],
                            [
                                -85.96166992187497,
                                65.704248046875
                            ],
                            [
                                -85.24111328125,
                                65.7955078125
                            ],
                            [
                                -84.91962890624995,
                                65.26108398437503
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -75.67587890624998,
                                68.32250976562506
                            ],
                            [
                                -75.15380859375,
                                68.23403320312497
                            ],
                            [
                                -75.12729492187503,
                                67.53730468749995
                            ],
                            [
                                -75.78007812499996,
                                67.28354492187503
                            ],
                            [
                                -77.0048828125,
                                67.26694335937503
                            ],
                            [
                                -77.30590820312497,
                                67.70610351562505
                            ],
                            [
                                -76.740234375,
                                68.23125000000002
                            ],
                            [
                                -75.67587890624998,
                                68.32250976562506
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -97.439453125,
                                69.64267578125006
                            ],
                            [
                                -96.29995117187494,
                                69.34438476562505
                            ],
                            [
                                -95.68564453125003,
                                68.73583984375006
                            ],
                            [
                                -96.40156249999995,
                                68.47070312500003
                            ],
                            [
                                -97.47202148437498,
                                68.543701171875
                            ],
                            [
                                -99.25400390625002,
                                68.86318359374997
                            ],
                            [
                                -99.56406249999995,
                                69.03413085937498
                            ],
                            [
                                -97.439453125,
                                69.64267578125006
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -74.70888671874997,
                                45.0038574218751
                            ],
                            [
                                -74.76245117187494,
                                44.99907226562502
                            ],
                            [
                                -74.99614257812496,
                                44.970117187499966
                            ],
                            [
                                -75.40126953124997,
                                44.77226562499999
                            ],
                            [
                                -75.81933593749997,
                                44.468017578125
                            ],
                            [
                                -76.18579101562503,
                                44.24223632812502
                            ],
                            [
                                -76.819970703125,
                                43.62880859375011
                            ],
                            [
                                -77.87924804687489,
                                43.62954101562508
                            ],
                            [
                                -78.45825195312497,
                                43.63149414062511
                            ],
                            [
                                -78.72041015624993,
                                43.62495117187501
                            ],
                            [
                                -78.84555664062492,
                                43.58334960937506
                            ],
                            [
                                -79.17187499999997,
                                43.466552734375085
                            ],
                            [
                                -79.0830566406249,
                                43.33139648437509
                            ],
                            [
                                -79.05922851562494,
                                43.27807617187506
                            ],
                            [
                                -79.066064453125,
                                43.10610351562502
                            ],
                            [
                                -79.02617187499996,
                                43.01733398437506
                            ],
                            [
                                -78.98076171874993,
                                42.98061523437502
                            ],
                            [
                                -78.91508789062496,
                                42.90913085937504
                            ],
                            [
                                -79.17373046875,
                                42.74853515625
                            ],
                            [
                                -80.24755859374991,
                                42.366015625000045
                            ],
                            [
                                -81.02822265624997,
                                42.247167968750006
                            ],
                            [
                                -81.50732421874997,
                                42.10346679687504
                            ],
                            [
                                -81.97416992187496,
                                41.88872070312499
                            ],
                            [
                                -82.69003906249995,
                                41.675195312499994
                            ],
                            [
                                -83.141943359375,
                                41.97587890624996
                            ],
                            [
                                -83.10952148437497,
                                42.25068359375001
                            ],
                            [
                                -82.54531249999997,
                                42.62470703124998
                            ],
                            [
                                -82.19038085937495,
                                43.47407226562501
                            ],
                            [
                                -82.137841796875,
                                43.570898437500034
                            ],
                            [
                                -82.48505859374993,
                                45.08374023437503
                            ],
                            [
                                -82.91933593749994,
                                45.51796875000002
                            ],
                            [
                                -83.59267578125,
                                45.81713867187506
                            ],
                            [
                                -83.61596679687503,
                                46.116845703124994
                            ],
                            [
                                -83.97778320312494,
                                46.08491210937507
                            ],
                            [
                                -84.12319335937497,
                                46.50292968749997
                            ],
                            [
                                -84.44047851562496,
                                46.49814453125006
                            ],
                            [
                                -84.66577148437503,
                                46.54326171875002
                            ],
                            [
                                -84.87597656249994,
                                46.89990234375003
                            ],
                            [
                                -85.07006835937497,
                                46.97993164062498
                            ],
                            [
                                -85.65224609375,
                                47.21997070312503
                            ],
                            [
                                -86.67216796874996,
                                47.636425781249955
                            ],
                            [
                                -87.20800781249997,
                                47.848486328125006
                            ],
                            [
                                -87.74389648437497,
                                48.06054687500003
                            ],
                            [
                                -88.37817382812497,
                                48.30307617187506
                            ],
                            [
                                -89.45566406249992,
                                47.99624023437508
                            ],
                            [
                                -90.79731445312495,
                                48.13105468750001
                            ],
                            [
                                -91.38720703124997,
                                48.05854492187498
                            ],
                            [
                                -92.00517578125002,
                                48.301855468750006
                            ],
                            [
                                -92.34843749999996,
                                48.276611328125
                            ],
                            [
                                -92.41459960937493,
                                48.276611328125
                            ],
                            [
                                -92.50058593749995,
                                48.43535156250002
                            ],
                            [
                                -92.83671874999996,
                                48.567773437499994
                            ],
                            [
                                -93.25795898437497,
                                48.62885742187501
                            ],
                            [
                                -93.37788085937498,
                                48.61655273437498
                            ],
                            [
                                -93.85161132812496,
                                48.607275390625034
                            ],
                            [
                                -94.71279296874997,
                                48.863427734374994
                            ],
                            [
                                -94.80346679687497,
                                49.0029296875
                            ],
                            [
                                -94.86040039062493,
                                49.258593750000045
                            ],
                            [
                                -94.85434570312495,
                                49.304589843749994
                            ],
                            [
                                -95.16206054687493,
                                48.991748046875045
                            ],
                            [
                                -95.39790039062493,
                                48.99316406249997
                            ],
                            [
                                -96.25068359374993,
                                48.99316406249997
                            ],
                            [
                                -96.67705078124993,
                                48.99316406249997
                            ],
                            [
                                -97.52983398437493,
                                48.99316406249997
                            ],
                            [
                                -98.80898437499995,
                                48.99316406249997
                            ],
                            [
                                -102.64643554687497,
                                48.993115234375125
                            ],
                            [
                                -105.63110351562497,
                                48.993115234375125
                            ],
                            [
                                -107.76298828124989,
                                48.993115234375125
                            ],
                            [
                                -109.4685546874999,
                                48.993066406250136
                            ],
                            [
                                -112.45322265625,
                                48.993066406250136
                            ],
                            [
                                -115.86425781249993,
                                48.993066406250136
                            ],
                            [
                                -117.99619140624993,
                                48.993066406250136
                            ],
                            [
                                -118.84892578124993,
                                48.993066406250136
                            ],
                            [
                                -119.27534179687494,
                                48.993066406250136
                            ],
                            [
                                -119.70170898437495,
                                48.99301757812495
                            ],
                            [
                                -120.98085937499995,
                                48.99301757812495
                            ],
                            [
                                -122.78876953124994,
                                48.99301757812495
                            ],
                            [
                                -122.82670898437495,
                                49.028417968750034
                            ],
                            [
                                -122.9241699218749,
                                49.07465820312504
                            ],
                            [
                                -123.04921874999998,
                                48.993017578125034
                            ],
                            [
                                -123.33564453124997,
                                49.45917968750004
                            ],
                            [
                                -123.85893554687492,
                                49.48286132812507
                            ],
                            [
                                -124.14160156249994,
                                49.792675781250125
                            ],
                            [
                                -124.78237304687492,
                                50.02011718749992
                            ],
                            [
                                -125.05668945312495,
                                50.418652343750125
                            ],
                            [
                                -126.4044921875,
                                50.529882812500034
                            ],
                            [
                                -126.397119140625,
                                50.80708007812504
                            ],
                            [
                                -127.057568359375,
                                50.86752929687509
                            ],
                            [
                                -127.70810546875,
                                51.15117187499996
                            ],
                            [
                                -127.644873046875,
                                51.47846679687504
                            ],
                            [
                                -128.3576171875,
                                52.1588867187501
                            ],
                            [
                                -128.1087890624999,
                                52.85805664062508
                            ],
                            [
                                -128.6523437499999,
                                53.24384765624998
                            ],
                            [
                                -129.28427734374995,
                                53.393164062500006
                            ],
                            [
                                -129.56372070312506,
                                53.251464843750114
                            ],
                            [
                                -130.33525390625002,
                                53.723925781250074
                            ],
                            [
                                -130.04331054687495,
                                54.13354492187503
                            ],
                            [
                                -130.4302734375,
                                54.42099609374998
                            ],
                            [
                                -129.89013671874994,
                                55.16464843749995
                            ],
                            [
                                -130.01406249999997,
                                55.950537109375006
                            ],
                            [
                                -130.09785156249995,
                                56.10927734375002
                            ],
                            [
                                -130.47709960937496,
                                56.230566406250034
                            ],
                            [
                                -130.649072265625,
                                56.26367187500003
                            ],
                            [
                                -131.86616210937495,
                                56.792822265625006
                            ],
                            [
                                -132.062890625,
                                56.95336914062503
                            ],
                            [
                                -133.00141601562495,
                                57.948974609375
                            ],
                            [
                                -133.27529296875,
                                58.22285156250004
                            ],
                            [
                                -133.54638671874997,
                                58.50346679687499
                            ],
                            [
                                -134.21850585937503,
                                58.849902343750045
                            ],
                            [
                                -134.32963867187505,
                                58.93969726562506
                            ],
                            [
                                -134.39306640625,
                                59.009179687499994
                            ],
                            [
                                -134.67724609374997,
                                59.19926757812499
                            ],
                            [
                                -134.94375,
                                59.28828125000001
                            ],
                            [
                                -135.36787109374998,
                                59.743310546874994
                            ],
                            [
                                -135.70258789062504,
                                59.72875976562506
                            ],
                            [
                                -136.46635742187493,
                                59.459082031250006
                            ],
                            [
                                -136.81328125000002,
                                59.15004882812511
                            ],
                            [
                                -137.59331054687493,
                                59.22626953124998
                            ],
                            [
                                -138.317626953125,
                                59.611132812500074
                            ],
                            [
                                -138.70546874999997,
                                59.90131835937504
                            ],
                            [
                                -139.23476562499997,
                                60.339746093749994
                            ],
                            [
                                -139.67631835937505,
                                60.32832031249998
                            ],
                            [
                                -139.97329101562497,
                                60.183154296875074
                            ],
                            [
                                -140.45283203125004,
                                60.29970703125002
                            ],
                            [
                                -141.00214843750004,
                                60.300244140625125
                            ],
                            [
                                -141.00214843750004,
                                60.884667968749994
                            ],
                            [
                                -141.00214843750004,
                                61.761279296875045
                            ],
                            [
                                -141.00214843750004,
                                63.22226562499998
                            ],
                            [
                                -141.00214843750004,
                                64.09887695312506
                            ],
                            [
                                -141.00214843750004,
                                65.55991210937498
                            ],
                            [
                                -141.00214843750004,
                                66.43652343750006
                            ],
                            [
                                -141.00214843750004,
                                67.89755859374998
                            ],
                            [
                                -141.00214843750004,
                                68.77416992187506
                            ],
                            [
                                -141.00214843750004,
                                69.65078125000011
                            ],
                            [
                                -139.18154296874997,
                                69.51552734375008
                            ],
                            [
                                -138.12836914062495,
                                69.15195312500009
                            ],
                            [
                                -136.71733398437493,
                                68.88916015625003
                            ],
                            [
                                -135.876318359375,
                                68.9169921875
                            ],
                            [
                                -135.6914550781249,
                                69.31118164062502
                            ],
                            [
                                -135.1408203124999,
                                69.46782226562496
                            ],
                            [
                                -133.16313476562496,
                                69.43388671874999
                            ],
                            [
                                -130.49843749999997,
                                70.14316406250006
                            ],
                            [
                                -128.9714355468749,
                                69.71240234375006
                            ],
                            [
                                -127.76494140624995,
                                70.2218750000001
                            ],
                            [
                                -127.13847656249996,
                                70.23935546874995
                            ],
                            [
                                -126.06381835937495,
                                69.46708984374999
                            ],
                            [
                                -124.767919921875,
                                69.99003906249996
                            ],
                            [
                                -123.60913085937491,
                                69.37744140625003
                            ],
                            [
                                -123.02578125,
                                69.81000976562504
                            ],
                            [
                                -120.96245117187502,
                                69.66040039062511
                            ],
                            [
                                -120.29252929687496,
                                69.42055664062511
                            ],
                            [
                                -118.86870117187496,
                                69.25717773437503
                            ],
                            [
                                -118.09521484374994,
                                69.0429199218751
                            ],
                            [
                                -116.06523437500002,
                                68.85541992187507
                            ],
                            [
                                -115.63115234374995,
                                68.97255859375014
                            ],
                            [
                                -114.41386718749993,
                                68.65957031250014
                            ],
                            [
                                -115.42685546874989,
                                67.92353515625013
                            ],
                            [
                                -113.89321289062501,
                                67.70688476562503
                            ],
                            [
                                -112.43515624999988,
                                67.68476562499998
                            ],
                            [
                                -110.9900390624999,
                                67.79082031250007
                            ],
                            [
                                -110.04248046874994,
                                67.97719726562505
                            ],
                            [
                                -109.63037109374991,
                                67.73271484374996
                            ],
                            [
                                -108.59291992187495,
                                67.59086914062502
                            ],
                            [
                                -107.15649414062497,
                                66.88173828124997
                            ],
                            [
                                -108.26103515625002,
                                68.14990234375006
                            ],
                            [
                                -108.31347656249996,
                                68.61079101562498
                            ],
                            [
                                -106.16445312499992,
                                68.91987304687507
                            ],
                            [
                                -105.42861328124994,
                                68.458251953125
                            ],
                            [
                                -104.19355468749994,
                                68.03120117187504
                            ],
                            [
                                -103.47412109374993,
                                68.11503906250005
                            ],
                            [
                                -102.69199218749992,
                                67.81157226562505
                            ],
                            [
                                -101.55498046874992,
                                67.69316406250007
                            ],
                            [
                                -100.45610351562496,
                                67.83945312500003
                            ],
                            [
                                -99.14687499999995,
                                67.72363281250009
                            ],
                            [
                                -98.41713867187497,
                                67.82646484375007
                            ],
                            [
                                -98.21855468750002,
                                68.31743164062507
                            ],
                            [
                                -97.2659179687499,
                                68.45292968749996
                            ],
                            [
                                -96.03603515625,
                                68.15776367187505
                            ],
                            [
                                -96.37138671875002,
                                67.55385742187514
                            ],
                            [
                                -95.78251953124996,
                                67.19379882812513
                            ],
                            [
                                -95.25874023437493,
                                67.26254882812492
                            ],
                            [
                                -95.65048828124986,
                                67.73745117187505
                            ],
                            [
                                -93.65170898437489,
                                68.54311523437505
                            ],
                            [
                                -93.68144531249993,
                                68.88725585937499
                            ],
                            [
                                -94.2708007812499,
                                69.45512695312505
                            ],
                            [
                                -96.11909179687504,
                                69.87187500000005
                            ],
                            [
                                -96.54560546874998,
                                70.32724609375009
                            ],
                            [
                                -96.4056640625,
                                71.27363281249998
                            ],
                            [
                                -95.20122070312493,
                                71.90371093750008
                            ],
                            [
                                -94.30834960937491,
                                71.76489257812506
                            ],
                            [
                                -92.94868164062493,
                                71.26210937500011
                            ],
                            [
                                -92.98144531249994,
                                70.8522460937501
                            ],
                            [
                                -91.56406249999995,
                                70.1782714843751
                            ],
                            [
                                -92.75092773437498,
                                69.71391601562502
                            ],
                            [
                                -90.82211914062495,
                                69.29047851562507
                            ],
                            [
                                -90.468359375,
                                68.86376953125
                            ],
                            [
                                -89.05673828124992,
                                69.26611328125009
                            ],
                            [
                                -88.041357421875,
                                68.81171875000007
                            ],
                            [
                                -87.81030273437491,
                                68.40415039062498
                            ],
                            [
                                -88.34697265624993,
                                68.28828125000001
                            ],
                            [
                                -88.19589843749986,
                                67.76582031249995
                            ],
                            [
                                -87.26625976562494,
                                67.18383789062506
                            ],
                            [
                                -86.53642578125002,
                                67.51616210937507
                            ],
                            [
                                -85.9525878906249,
                                68.0724609375001
                            ],
                            [
                                -85.73383789062493,
                                68.630126953125
                            ],
                            [
                                -84.86220703125,
                                69.07397460937503
                            ],
                            [
                                -85.38676757812493,
                                69.23188476562504
                            ],
                            [
                                -85.50737304687487,
                                69.84526367187493
                            ],
                            [
                                -82.61835937499993,
                                69.69106445312514
                            ],
                            [
                                -82.64204101562495,
                                69.4583984375
                            ],
                            [
                                -81.3286621093749,
                                69.11992187499996
                            ],
                            [
                                -81.28154296874987,
                                68.65722656250003
                            ],
                            [
                                -82.55268554687504,
                                68.44648437500007
                            ],
                            [
                                -81.97646484374997,
                                67.86201171875001
                            ],
                            [
                                -81.27011718749995,
                                67.45991210937498
                            ],
                            [
                                -81.46757812499996,
                                67.0698730468751
                            ],
                            [
                                -82.64150390624991,
                                66.58750000000006
                            ],
                            [
                                -84.47841796875005,
                                66.17929687500003
                            ],
                            [
                                -85.44223632812495,
                                66.53735351562497
                            ],
                            [
                                -86.63320312499991,
                                66.53134765625
                            ],
                            [
                                -86.04287109375,
                                66.02255859375009
                            ],
                            [
                                -87.2914550781249,
                                65.35483398437495
                            ],
                            [
                                -87.28051757812491,
                                64.82617187500006
                            ],
                            [
                                -88.10561523437497,
                                64.18330078125001
                            ],
                            [
                                -88.65302734374993,
                                64.00937499999992
                            ],
                            [
                                -90.14189453125002,
                                63.981982421875045
                            ],
                            [
                                -90.01342773437497,
                                63.80429687500006
                            ],
                            [
                                -91.33007812499997,
                                63.50683593750006
                            ],
                            [
                                -90.74658203124994,
                                63.35156250000003
                            ],
                            [
                                -90.87119140625,
                                62.945947265624994
                            ],
                            [
                                -91.44897460937503,
                                62.804052734375034
                            ],
                            [
                                -92.3612792968749,
                                62.81938476562496
                            ],
                            [
                                -93.42060546874998,
                                61.705810546875114
                            ],
                            [
                                -94.06074218749993,
                                61.317822265625125
                            ],
                            [
                                -94.64677734374993,
                                60.416406249999994
                            ],
                            [
                                -94.78579101562494,
                                59.953320312499955
                            ],
                            [
                                -94.71337890624991,
                                58.903320312500114
                            ],
                            [
                                -94.28706054687493,
                                58.716015625000125
                            ],
                            [
                                -93.2781249999999,
                                58.75639648437502
                            ],
                            [
                                -92.24902343749994,
                                57.00898437500001
                            ],
                            [
                                -91.11127929687498,
                                57.24121093750006
                            ],
                            [
                                -90.59218749999991,
                                57.224462890625034
                            ],
                            [
                                -89.7908203124999,
                                56.98134765625005
                            ],
                            [
                                -88.94848632812489,
                                56.85131835937503
                            ],
                            [
                                -88.07509765624997,
                                56.46728515624994
                            ],
                            [
                                -87.560888671875,
                                56.05634765625007
                            ],
                            [
                                -85.67666015625,
                                55.60107421875006
                            ],
                            [
                                -85.06093749999997,
                                55.285644531250085
                            ],
                            [
                                -83.91059570312493,
                                55.314648437499955
                            ],
                            [
                                -82.57744140625002,
                                55.1487304687501
                            ],
                            [
                                -82.219384765625,
                                54.8134765625
                            ],
                            [
                                -82.41806640624986,
                                54.355810546875034
                            ],
                            [
                                -82.14145507812492,
                                53.81762695312497
                            ],
                            [
                                -82.26044921874993,
                                52.9611328125001
                            ],
                            [
                                -81.5994140624999,
                                52.432617187500085
                            ],
                            [
                                -81.66123046874986,
                                52.29389648437507
                            ],
                            [
                                -80.65795898437503,
                                51.75834960937502
                            ],
                            [
                                -80.36796875000002,
                                51.32988281249996
                            ],
                            [
                                -79.83623046874996,
                                51.17333984375003
                            ],
                            [
                                -79.49755859374997,
                                51.569921875000006
                            ],
                            [
                                -78.97773437499993,
                                51.733789062499994
                            ],
                            [
                                -78.49165039062495,
                                52.25209960937508
                            ],
                            [
                                -78.723779296875,
                                52.627734375000045
                            ],
                            [
                                -79.241796875,
                                54.098876953125085
                            ],
                            [
                                -79.66552734374991,
                                54.69746093749998
                            ],
                            [
                                -77.89111328124989,
                                55.236425781250006
                            ],
                            [
                                -77.32495117187494,
                                55.555517578125006
                            ],
                            [
                                -76.65048828124992,
                                56.10722656249996
                            ],
                            [
                                -76.5728515625,
                                57.1812011718751
                            ],
                            [
                                -77.15678710937496,
                                58.018896484375034
                            ],
                            [
                                -78.51508789062493,
                                58.68237304687503
                            ],
                            [
                                -77.76069335937498,
                                59.38002929687505
                            ],
                            [
                                -77.31181640624993,
                                60.042382812499994
                            ],
                            [
                                -77.8715332031249,
                                60.7858398437501
                            ],
                            [
                                -77.73618164062495,
                                61.43735351562498
                            ],
                            [
                                -78.13715820312493,
                                62.10737304687504
                            ],
                            [
                                -78.06811523437489,
                                62.355419921875125
                            ],
                            [
                                -77.372412109375,
                                62.572509765625114
                            ],
                            [
                                -74.68989257812493,
                                62.18344726562509
                            ],
                            [
                                -73.62998046874989,
                                62.454199218750006
                            ],
                            [
                                -72.0814453125,
                                61.728271484375085
                            ],
                            [
                                -71.42270507812489,
                                61.158935546875085
                            ],
                            [
                                -70.27929687499991,
                                61.06865234374999
                            ],
                            [
                                -69.64047851562489,
                                60.68979492187509
                            ],
                            [
                                -69.75590820312496,
                                60.38852539062498
                            ],
                            [
                                -69.68188476562489,
                                59.34174804687507
                            ],
                            [
                                -69.17348632812497,
                                58.896630859374994
                            ],
                            [
                                -68.69819335937495,
                                58.904541015625
                            ],
                            [
                                -67.5696289062499,
                                58.21347656250006
                            ],
                            [
                                -66.60791015624991,
                                58.548925781250034
                            ],
                            [
                                -65.60624999999993,
                                59.11074218750008
                            ],
                            [
                                -65.4333984374999,
                                59.776513671874994
                            ],
                            [
                                -64.93125,
                                60.252001953125074
                            ],
                            [
                                -64.49941406250005,
                                60.26826171875001
                            ],
                            [
                                -63.18535156249993,
                                58.85776367187506
                            ],
                            [
                                -62.593847656249864,
                                58.47402343750005
                            ],
                            [
                                -62.30566406249997,
                                57.97226562499995
                            ],
                            [
                                -61.899072265624994,
                                57.861328125000085
                            ],
                            [
                                -61.851074218749886,
                                57.38129882812501
                            ],
                            [
                                -61.33374023437494,
                                57.01059570312498
                            ],
                            [
                                -61.514599609374926,
                                56.3903320312501
                            ],
                            [
                                -59.93032226562491,
                                55.25942382812508
                            ],
                            [
                                -58.99711914062496,
                                55.149462890625074
                            ],
                            [
                                -58.780175781249994,
                                54.838378906250114
                            ],
                            [
                                -58.195263671874926,
                                54.86591796875004
                            ],
                            [
                                -57.40444335937494,
                                54.570410156250034
                            ],
                            [
                                -58.15136718749997,
                                54.350439453125006
                            ],
                            [
                                -57.4160644531249,
                                54.162744140625136
                            ],
                            [
                                -57.14897460937493,
                                53.847705078125074
                            ],
                            [
                                -56.46499023437505,
                                53.76503906250011
                            ],
                            [
                                -55.79794921874995,
                                53.211962890625045
                            ],
                            [
                                -55.83364257812494,
                                52.31040039062506
                            ],
                            [
                                -55.695214843749994,
                                52.13779296875006
                            ],
                            [
                                -56.97597656250005,
                                51.45766601562505
                            ],
                            [
                                -58.510351562500006,
                                51.295068359375136
                            ],
                            [
                                -59.16538085937495,
                                50.779882812500006
                            ],
                            [
                                -60.08017578124989,
                                50.2545898437501
                            ],
                            [
                                -61.289746093749926,
                                50.20195312500002
                            ],
                            [
                                -62.71542968749995,
                                50.30166015625008
                            ],
                            [
                                -64.86787109374998,
                                50.27548828125009
                            ],
                            [
                                -65.2686035156249,
                                50.3200195312501
                            ],
                            [
                                -66.49550781249991,
                                50.2118652343751
                            ],
                            [
                                -66.94116210937503,
                                49.993701171875045
                            ],
                            [
                                -67.37202148437495,
                                49.348437500000045
                            ],
                            [
                                -68.28193359374998,
                                49.197167968750136
                            ],
                            [
                                -68.929052734375,
                                48.82895507812509
                            ],
                            [
                                -69.77499999999995,
                                48.09809570312504
                            ],
                            [
                                -69.9055664062499,
                                47.83222656250007
                            ],
                            [
                                -70.70585937499996,
                                47.13979492187505
                            ],
                            [
                                -71.26777343749995,
                                46.79594726562499
                            ],
                            [
                                -71.87958984374998,
                                46.68681640624996
                            ],
                            [
                                -72.98100585937493,
                                46.209716796875085
                            ],
                            [
                                -73.97382812499995,
                                45.345117187499994
                            ],
                            [
                                -74.70888671874997,
                                45.0038574218751
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -114.52153320312502,
                                72.592919921875
                            ],
                            [
                                -113.50004882812502,
                                72.69443359375006
                            ],
                            [
                                -112.75361328125001,
                                72.98603515624995
                            ],
                            [
                                -111.26972656249994,
                                72.71372070312498
                            ],
                            [
                                -108.69829101562502,
                                72.499267578125
                            ],
                            [
                                -108.23823242187498,
                                73.10581054687503
                            ],
                            [
                                -107.72001953125002,
                                73.32905273437504
                            ],
                            [
                                -106.48212890624998,
                                73.19619140624997
                            ],
                            [
                                -105.41513671874995,
                                72.788330078125
                            ],
                            [
                                -104.34956054687501,
                                71.43398437500005
                            ],
                            [
                                -104.56958007812499,
                                71.10405273437507
                            ],
                            [
                                -103.10498046874994,
                                70.51025390624997
                            ],
                            [
                                -101.14853515624998,
                                70.147607421875
                            ],
                            [
                                -100.93510742187493,
                                69.71533203125003
                            ],
                            [
                                -102.18212890624997,
                                69.845947265625
                            ],
                            [
                                -101.85712890625001,
                                69.02397460937505
                            ],
                            [
                                -103.46821289062497,
                                68.80854492187504
                            ],
                            [
                                -105.019580078125,
                                69.08124999999998
                            ],
                            [
                                -106.140869140625,
                                69.16201171875002
                            ],
                            [
                                -106.65908203124997,
                                69.439599609375
                            ],
                            [
                                -107.43989257812497,
                                69.00214843749995
                            ],
                            [
                                -108.9458984375,
                                68.75981445312505
                            ],
                            [
                                -111.3109375,
                                68.54204101562502
                            ],
                            [
                                -113.12773437500002,
                                68.49414062500003
                            ],
                            [
                                -113.69414062499995,
                                69.19501953124998
                            ],
                            [
                                -116.51347656249993,
                                69.42460937500005
                            ],
                            [
                                -117.13544921875,
                                70.10014648437499
                            ],
                            [
                                -114.59233398437497,
                                70.31245117187498
                            ],
                            [
                                -112.63789062499997,
                                70.225244140625
                            ],
                            [
                                -112.11416015624994,
                                70.44687500000003
                            ],
                            [
                                -113.75727539062503,
                                70.69072265625005
                            ],
                            [
                                -115.99091796874997,
                                70.586279296875
                            ],
                            [
                                -117.58706054687498,
                                70.62954101562502
                            ],
                            [
                                -118.26406249999998,
                                70.888330078125
                            ],
                            [
                                -117.81406249999996,
                                71.15844726562503
                            ],
                            [
                                -118.868408203125,
                                71.68676757812503
                            ],
                            [
                                -118.94462890624997,
                                71.98554687499995
                            ],
                            [
                                -118.21347656249998,
                                72.26289062499998
                            ],
                            [
                                -118.37451171875,
                                72.53388671875004
                            ],
                            [
                                -116.57324218749994,
                                73.05493164062506
                            ],
                            [
                                -114.63823242187499,
                                73.37265625000003
                            ],
                            [
                                -114.05170898437497,
                                73.07099609375004
                            ],
                            [
                                -114.52153320312502,
                                72.592919921875
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -105.28891601562499,
                                72.919921875
                            ],
                            [
                                -106.92153320312497,
                                73.479833984375
                            ],
                            [
                                -105.51230468750002,
                                73.765771484375
                            ],
                            [
                                -104.58286132812493,
                                73.35390625000002
                            ],
                            [
                                -105.28891601562499,
                                72.919921875
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -79.53730468749998,
                                73.65449218749998
                            ],
                            [
                                -78.2865234375,
                                73.66582031250007
                            ],
                            [
                                -77.20654296874997,
                                73.49956054687505
                            ],
                            [
                                -76.28955078125003,
                                73.08100585937504
                            ],
                            [
                                -76.400537109375,
                                72.820654296875
                            ],
                            [
                                -77.83593750000003,
                                72.89682617187498
                            ],
                            [
                                -79.50053710937496,
                                72.75595703124998
                            ],
                            [
                                -80.82416992187495,
                                73.38066406250007
                            ],
                            [
                                -80.84887695312503,
                                73.72124023437499
                            ],
                            [
                                -79.53730468749998,
                                73.65449218749998
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -86.58935546874997,
                                71.01079101562507
                            ],
                            [
                                -84.78959960937493,
                                71.09326171875
                            ],
                            [
                                -84.65996093749999,
                                71.58613281249995
                            ],
                            [
                                -85.91162109375,
                                71.98652343749998
                            ],
                            [
                                -85.34111328124993,
                                72.42153320312497
                            ],
                            [
                                -85.64453125000003,
                                72.77446289062502
                            ],
                            [
                                -85.018408203125,
                                73.33549804687502
                            ],
                            [
                                -82.65961914062498,
                                73.72958984375
                            ],
                            [
                                -81.40615234374997,
                                73.634521484375
                            ],
                            [
                                -80.27470703124993,
                                72.74555664062498
                            ],
                            [
                                -80.94121093750002,
                                72.21015624999998
                            ],
                            [
                                -79.000244140625,
                                72.27202148437507
                            ],
                            [
                                -78.00102539062499,
                                72.68759765625
                            ],
                            [
                                -76.89350585937495,
                                72.72065429687498
                            ],
                            [
                                -75.18579101562494,
                                72.434228515625
                            ],
                            [
                                -74.31572265624999,
                                71.84267578125
                            ],
                            [
                                -71.45991210937493,
                                71.46372070312498
                            ],
                            [
                                -70.56093750000002,
                                70.73828124999997
                            ],
                            [
                                -69.16870117187497,
                                70.76416015625
                            ],
                            [
                                -67.36367187499994,
                                70.03442382812503
                            ],
                            [
                                -68.51303710937498,
                                69.57729492187497
                            ],
                            [
                                -66.77084960937498,
                                69.33666992187497
                            ],
                            [
                                -67.75170898437494,
                                69.038671875
                            ],
                            [
                                -68.037939453125,
                                68.55073242187501
                            ],
                            [
                                -67.032958984375,
                                68.32607421874997
                            ],
                            [
                                -66.2251953125,
                                67.958740234375
                            ],
                            [
                                -64.52753906250001,
                                67.81269531250001
                            ],
                            [
                                -64.00795898437502,
                                67.34731445312497
                            ],
                            [
                                -61.96855468749993,
                                67.01904296874997
                            ],
                            [
                                -61.29970703125002,
                                66.64873046875002
                            ],
                            [
                                -61.99160156250002,
                                66.03530273437502
                            ],
                            [
                                -62.65888671874998,
                                65.63994140625002
                            ],
                            [
                                -63.36425781249997,
                                65.54321289062503
                            ],
                            [
                                -63.40180664062498,
                                65.11845703125002
                            ],
                            [
                                -64.66533203125002,
                                65.1689453125
                            ],
                            [
                                -65.401611328125,
                                65.764013671875
                            ],
                            [
                                -65.30537109374993,
                                66.00844726562497
                            ],
                            [
                                -66.47695312499997,
                                66.27973632812498
                            ],
                            [
                                -67.18320312499995,
                                66.03442382812503
                            ],
                            [
                                -68.2568359375,
                                65.93862304687505
                            ],
                            [
                                -67.30341796874993,
                                65.48291015625
                            ],
                            [
                                -66.86064453124996,
                                65.09160156250005
                            ],
                            [
                                -65.2748046875,
                                64.63154296875004
                            ],
                            [
                                -64.67846679687503,
                                64.027978515625
                            ],
                            [
                                -64.51435546874998,
                                63.263964843750045
                            ],
                            [
                                -65.06894531249998,
                                63.26347656250002
                            ],
                            [
                                -65.26582031249995,
                                62.71508789062503
                            ],
                            [
                                -65.978857421875,
                                63.000683593749955
                            ],
                            [
                                -67.67597656249998,
                                63.093554687500045
                            ],
                            [
                                -66.28124999999997,
                                62.302685546874955
                            ],
                            [
                                -66.25668945312503,
                                61.868261718750006
                            ],
                            [
                                -67.44013671874998,
                                62.15126953125002
                            ],
                            [
                                -69.08232421874996,
                                62.405175781249966
                            ],
                            [
                                -69.54516601562503,
                                62.74458007812501
                            ],
                            [
                                -70.23613281250002,
                                62.76337890625001
                            ],
                            [
                                -71.25371093749993,
                                63.042529296875045
                            ],
                            [
                                -73.44365234374996,
                                64.423486328125
                            ],
                            [
                                -74.512451171875,
                                64.670166015625
                            ],
                            [
                                -75.0673828125,
                                64.45668945312502
                            ],
                            [
                                -76.72382812499995,
                                64.24204101562498
                            ],
                            [
                                -77.79116210937497,
                                64.36708984374997
                            ],
                            [
                                -78.09560546875,
                                64.93925781250002
                            ],
                            [
                                -77.44746093749993,
                                65.16157226562501
                            ],
                            [
                                -77.32670898437493,
                                65.45312499999997
                            ],
                            [
                                -75.82832031249993,
                                65.22705078125003
                            ],
                            [
                                -73.56074218750001,
                                65.54291992187498
                            ],
                            [
                                -74.433935546875,
                                66.139013671875
                            ],
                            [
                                -72.22001953124999,
                                67.25429687500002
                            ],
                            [
                                -73.32822265624998,
                                68.26674804687497
                            ],
                            [
                                -74.64794921874997,
                                68.70751953124997
                            ],
                            [
                                -74.91728515624996,
                                68.98286132812501
                            ],
                            [
                                -76.40341796874995,
                                68.69233398437498
                            ],
                            [
                                -75.78715820312499,
                                69.31865234375005
                            ],
                            [
                                -77.12880859374994,
                                69.65273437499997
                            ],
                            [
                                -78.809814453125,
                                70.17856445312498
                            ],
                            [
                                -79.61591796875001,
                                69.89472656249998
                            ],
                            [
                                -81.09829101562494,
                                70.09116210937503
                            ],
                            [
                                -82.13872070312499,
                                69.8412109375
                            ],
                            [
                                -86.32202148437503,
                                70.14541015625
                            ],
                            [
                                -86.62431640624999,
                                70.40126953124997
                            ],
                            [
                                -87.90068359374996,
                                70.25190429687501
                            ],
                            [
                                -88.84843749999993,
                                70.52290039062498
                            ],
                            [
                                -89.36552734374996,
                                71.06718750000002
                            ],
                            [
                                -87.84492187499995,
                                70.94438476562505
                            ],
                            [
                                -87.87246093749997,
                                71.20854492187505
                            ],
                            [
                                -89.079345703125,
                                71.28793945312503
                            ],
                            [
                                -89.84575195312499,
                                71.49228515625
                            ],
                            [
                                -90.02519531249996,
                                71.94877929687502
                            ],
                            [
                                -89.26323242187499,
                                73.068994140625
                            ],
                            [
                                -87.71977539062496,
                                73.72290039062497
                            ],
                            [
                                -86.76875000000001,
                                73.83398437500006
                            ],
                            [
                                -84.97451171874997,
                                73.69477539062501
                            ],
                            [
                                -86.62934570312493,
                                72.87080078125001
                            ],
                            [
                                -86.03613281249997,
                                71.77094726562504
                            ],
                            [
                                -85.02338867187495,
                                71.35322265625001
                            ],
                            [
                                -86.58935546874997,
                                71.01079101562507
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -100.00190429687497,
                                73.9458984375
                            ],
                            [
                                -99.15795898437499,
                                73.73159179687497
                            ],
                            [
                                -97.58183593749996,
                                73.88754882812503
                            ],
                            [
                                -97.001708984375,
                                73.66650390625003
                            ],
                            [
                                -97.79589843749997,
                                73.285302734375
                            ],
                            [
                                -97.05180664062499,
                                72.63681640625003
                            ],
                            [
                                -96.61342773437494,
                                71.83383789062506
                            ],
                            [
                                -98.66289062499993,
                                71.302099609375
                            ],
                            [
                                -99.167138671875,
                                71.36718750000003
                            ],
                            [
                                -100.594482421875,
                                72.15234375000003
                            ],
                            [
                                -101.72392578124996,
                                72.31489257812501
                            ],
                            [
                                -102.71367187499999,
                                72.78291015624995
                            ],
                            [
                                -100.48476562500002,
                                72.77294921874997
                            ],
                            [
                                -100.28266601562497,
                                73.12031249999995
                            ],
                            [
                                -101.45087890625003,
                                73.43095703125002
                            ],
                            [
                                -100.91513671874995,
                                73.80537109374998
                            ],
                            [
                                -100.00190429687497,
                                73.9458984375
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -93.17084960937498,
                                74.16098632812506
                            ],
                            [
                                -92.22270507812502,
                                73.97236328124998
                            ],
                            [
                                -90.35458984375,
                                73.86865234375
                            ],
                            [
                                -92.11791992187497,
                                72.75380859375
                            ],
                            [
                                -93.34062499999993,
                                72.80185546875
                            ],
                            [
                                -94.03754882812498,
                                72.02875976562498
                            ],
                            [
                                -95.19296875,
                                72.02744140624998
                            ],
                            [
                                -95.54760742187497,
                                72.78154296875005
                            ],
                            [
                                -95.63291015625003,
                                73.69545898437497
                            ],
                            [
                                -95.03984374999999,
                                74.02387695312501
                            ],
                            [
                                -93.17084960937498,
                                74.16098632812506
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -119.73632812499997,
                                74.11264648437498
                            ],
                            [
                                -117.96586914062497,
                                74.26606445312495
                            ],
                            [
                                -117.19882812500003,
                                74.171142578125
                            ],
                            [
                                -115.51069335937501,
                                73.61874999999998
                            ],
                            [
                                -115.44687499999998,
                                73.43886718750002
                            ],
                            [
                                -118.96157226562497,
                                72.68413085937499
                            ],
                            [
                                -120.17988281250001,
                                72.21264648437506
                            ],
                            [
                                -120.61933593750001,
                                71.50576171875002
                            ],
                            [
                                -123.210595703125,
                                71.12343750000002
                            ],
                            [
                                -124.00776367187494,
                                71.67744140624998
                            ],
                            [
                                -125.76259765624997,
                                72.13750000000002
                            ],
                            [
                                -124.98710937499997,
                                72.58798828125003
                            ],
                            [
                                -124.83642578125001,
                                73.07626953125003
                            ],
                            [
                                -123.79726562499997,
                                73.76816406250003
                            ],
                            [
                                -124.69624023437497,
                                74.34819335937499
                            ],
                            [
                                -121.31523437499995,
                                74.52998046874998
                            ],
                            [
                                -119.73632812499997,
                                74.11264648437498
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -93.54257812499995,
                                75.0279296875
                            ],
                            [
                                -93.54716796875002,
                                74.69106445312505
                            ],
                            [
                                -94.697265625,
                                74.64218750000003
                            ],
                            [
                                -96.565771484375,
                                75.09873046875003
                            ],
                            [
                                -95.67080078125002,
                                75.52866210937498
                            ],
                            [
                                -94.42724609374997,
                                75.593359375
                            ],
                            [
                                -93.54257812499995,
                                75.0279296875
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -102.22734374999995,
                                76.014892578125
                            ],
                            [
                                -103.31474609374996,
                                75.76420898437499
                            ],
                            [
                                -104.35063476562497,
                                76.18232421875001
                            ],
                            [
                                -102.58408203124996,
                                76.281640625
                            ],
                            [
                                -102.22734374999995,
                                76.014892578125
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -97.70092773437497,
                                76.46650390624998
                            ],
                            [
                                -97.40961914062495,
                                75.55209960937503
                            ],
                            [
                                -97.79936523437496,
                                75.11665039062495
                            ],
                            [
                                -100.234375,
                                75.00771484374997
                            ],
                            [
                                -100.71191406250003,
                                75.40634765625
                            ],
                            [
                                -102.54140624999998,
                                75.51362304687501
                            ],
                            [
                                -101.43134765624995,
                                75.99199218750005
                            ],
                            [
                                -102.13774414062499,
                                76.28486328125001
                            ],
                            [
                                -100.38793945312501,
                                76.61357421875002
                            ],
                            [
                                -97.70092773437497,
                                76.46650390624998
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -108.29238281250001,
                                76.05712890625
                            ],
                            [
                                -105.48090820312503,
                                75.74565429687505
                            ],
                            [
                                -105.86259765624997,
                                75.19155273437505
                            ],
                            [
                                -107.15341796874996,
                                74.9271484375
                            ],
                            [
                                -108.83129882812501,
                                75.06489257812498
                            ],
                            [
                                -110.94086914062501,
                                74.63872070312505
                            ],
                            [
                                -112.51933593749997,
                                74.41684570312503
                            ],
                            [
                                -113.51406249999994,
                                74.430078125
                            ],
                            [
                                -114.45175781250002,
                                75.08789062499997
                            ],
                            [
                                -115.72885742187496,
                                74.968115234375
                            ],
                            [
                                -117.60009765624997,
                                75.27167968750001
                            ],
                            [
                                -116.60976562500001,
                                76.07373046875
                            ],
                            [
                                -115.58066406249996,
                                76.43750000000003
                            ],
                            [
                                -114.19394531249999,
                                76.45146484375005
                            ],
                            [
                                -112.69760742187496,
                                76.20170898437505
                            ],
                            [
                                -111.05268554687495,
                                75.54853515625001
                            ],
                            [
                                -109.08637695312503,
                                75.506494140625
                            ],
                            [
                                -109.79604492187501,
                                75.86303710937497
                            ],
                            [
                                -109.48681640624999,
                                76.14467773437497
                            ],
                            [
                                -110.27001953125003,
                                76.41699218750003
                            ],
                            [
                                -108.62763671875001,
                                76.58671875000005
                            ],
                            [
                                -108.29238281250001,
                                76.05712890625
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -94.29497070312493,
                                76.91245117187498
                            ],
                            [
                                -92.71625976562493,
                                76.60297851562498
                            ],
                            [
                                -91.305029296875,
                                76.68076171875003
                            ],
                            [
                                -90.25136718749994,
                                76.05346679687503
                            ],
                            [
                                -88.201318359375,
                                75.51201171875005
                            ],
                            [
                                -86.06874999999994,
                                75.50224609375002
                            ],
                            [
                                -83.931982421875,
                                75.81894531250003
                            ],
                            [
                                -82.153662109375,
                                75.83105468750003
                            ],
                            [
                                -79.66020507812499,
                                75.44951171875002
                            ],
                            [
                                -79.50795898437497,
                                74.880126953125
                            ],
                            [
                                -80.27773437500002,
                                74.58159179687502
                            ],
                            [
                                -81.80883789062503,
                                74.47661132812505
                            ],
                            [
                                -88.42304687499995,
                                74.49414062499997
                            ],
                            [
                                -89.844384765625,
                                74.54858398437497
                            ],
                            [
                                -91.96157226562502,
                                74.79321289062497
                            ],
                            [
                                -92.427099609375,
                                75.34638671874995
                            ],
                            [
                                -92.18510742187499,
                                75.84653320312498
                            ],
                            [
                                -93.09174804687495,
                                76.35400390624997
                            ],
                            [
                                -95.27387695312498,
                                76.26440429687503
                            ],
                            [
                                -96.63969726562496,
                                76.70292968750002
                            ],
                            [
                                -95.63823242187496,
                                77.06376953124999
                            ],
                            [
                                -94.29497070312493,
                                76.91245117187498
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -115.55126953125001,
                                77.36328125
                            ],
                            [
                                -116.22045898437501,
                                76.61108398437506
                            ],
                            [
                                -119.36791992187497,
                                76.22177734375003
                            ],
                            [
                                -119.91289062499997,
                                75.85883789062501
                            ],
                            [
                                -121.90820312499996,
                                76.03476562500006
                            ],
                            [
                                -121.61376953125001,
                                76.44145507812505
                            ],
                            [
                                -119.09018554687496,
                                77.30507812500002
                            ],
                            [
                                -115.55126953125001,
                                77.36328125
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -95.48437499999997,
                                77.79199218750003
                            ],
                            [
                                -93.30097656249995,
                                77.73979492187505
                            ],
                            [
                                -93.51958007812499,
                                77.47441406250007
                            ],
                            [
                                -95.98706054687497,
                                77.484130859375
                            ],
                            [
                                -95.48437499999997,
                                77.79199218750003
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -110.45805664062496,
                                78.10322265625001
                            ],
                            [
                                -109.77177734374995,
                                77.95742187499997
                            ],
                            [
                                -110.19848632812501,
                                77.52451171874998
                            ],
                            [
                                -111.95195312500002,
                                77.34418945312501
                            ],
                            [
                                -113.16435546875002,
                                77.5302734375
                            ],
                            [
                                -113.21518554687498,
                                77.90351562500001
                            ],
                            [
                                -110.45805664062496,
                                78.10322265625001
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -109.81596679687499,
                                78.65039062500003
                            ],
                            [
                                -109.39052734375,
                                78.33666992187506
                            ],
                            [
                                -113.14995117187502,
                                78.40839843750001
                            ],
                            [
                                -110.40781249999995,
                                78.75664062499999
                            ],
                            [
                                -109.81596679687499,
                                78.65039062500003
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -96.20449218749994,
                                78.53129882812499
                            ],
                            [
                                -94.88715820312498,
                                78.34521484375003
                            ],
                            [
                                -95.08701171875,
                                77.99262695312495
                            ],
                            [
                                -96.98964843749994,
                                77.80600585937503
                            ],
                            [
                                -98.25493164062496,
                                78.42924804687499
                            ],
                            [
                                -96.20449218749994,
                                78.53129882812499
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -103.42602539062499,
                                79.31562500000001
                            ],
                            [
                                -99.60942382812495,
                                78.58305664062507
                            ],
                            [
                                -99.16640625000002,
                                77.85693359375003
                            ],
                            [
                                -100.58603515624996,
                                77.89179687500001
                            ],
                            [
                                -101.07412109375001,
                                78.19384765625
                            ],
                            [
                                -102.73134765624995,
                                78.37104492187495
                            ],
                            [
                                -103.51835937499996,
                                78.76914062500006
                            ],
                            [
                                -105.53564453124999,
                                79.03251953125007
                            ],
                            [
                                -105.38769531249994,
                                79.32358398437503
                            ],
                            [
                                -103.42602539062499,
                                79.31562500000001
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -91.88554687499999,
                                81.13286132812505
                            ],
                            [
                                -90.64301757812498,
                                80.59370117187498
                            ],
                            [
                                -87.67499999999998,
                                80.37211914062505
                            ],
                            [
                                -87.14423828124993,
                                79.66264648437502
                            ],
                            [
                                -85.5013671875,
                                79.53032226562505
                            ],
                            [
                                -85.28984374999999,
                                79.208349609375
                            ],
                            [
                                -87.016455078125,
                                78.89868164062497
                            ],
                            [
                                -88.79101562499997,
                                78.19243164062507
                            ],
                            [
                                -90.614404296875,
                                78.14985351562501
                            ],
                            [
                                -92.67827148437502,
                                78.38911132812498
                            ],
                            [
                                -95.10317382812502,
                                79.289892578125
                            ],
                            [
                                -95.29697265624998,
                                79.653076171875
                            ],
                            [
                                -96.77324218749999,
                                80.13579101562502
                            ],
                            [
                                -95.61445312499998,
                                80.39624023437497
                            ],
                            [
                                -96.1328125,
                                80.69140624999997
                            ],
                            [
                                -95.26977539062503,
                                81.00078125000005
                            ],
                            [
                                -91.88554687499999,
                                81.13286132812505
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -69.4888671875,
                                83.01679687499998
                            ],
                            [
                                -64.77675781249997,
                                82.87646484374997
                            ],
                            [
                                -61.392480468749994,
                                82.44189453125
                            ],
                            [
                                -62.17670898437498,
                                82.04340820312498
                            ],
                            [
                                -64.57402343749996,
                                81.73374023437506
                            ],
                            [
                                -65.48398437499998,
                                81.28476562499998
                            ],
                            [
                                -70.14350585937498,
                                80.39765625000001
                            ],
                            [
                                -71.387841796875,
                                79.76176757812505
                            ],
                            [
                                -75.25947265624995,
                                79.421044921875
                            ],
                            [
                                -76.255859375,
                                79.00683593749997
                            ],
                            [
                                -74.43310546875,
                                78.72412109375003
                            ],
                            [
                                -75.86596679687497,
                                78.00981445312499
                            ],
                            [
                                -78.01259765624997,
                                77.94604492187506
                            ],
                            [
                                -78.07617187500003,
                                77.51904296875
                            ],
                            [
                                -79.34086914062493,
                                77.15839843749998
                            ],
                            [
                                -78.28886718750002,
                                76.97797851562501
                            ],
                            [
                                -78.284326171875,
                                76.57124023437501
                            ],
                            [
                                -80.69028320312495,
                                76.17646484374995
                            ],
                            [
                                -81.17070312499996,
                                76.51274414062499
                            ],
                            [
                                -83.88569335937501,
                                76.45312500000003
                            ],
                            [
                                -85.141259765625,
                                76.30458984375005
                            ],
                            [
                                -89.57006835937497,
                                76.49194335937503
                            ],
                            [
                                -89.49975585937503,
                                76.82680664062502
                            ],
                            [
                                -88.39814453124995,
                                77.10395507812501
                            ],
                            [
                                -86.873779296875,
                                77.20029296875
                            ],
                            [
                                -88.09467773437495,
                                77.71918945312501
                            ],
                            [
                                -87.01796874999997,
                                77.89223632812502
                            ],
                            [
                                -87.5517578125,
                                78.17661132812503
                            ],
                            [
                                -86.80791015624999,
                                78.77436523437495
                            ],
                            [
                                -84.31611328124993,
                                78.97529296874995
                            ],
                            [
                                -85.08979492187498,
                                79.61215820312503
                            ],
                            [
                                -86.42075195312498,
                                79.84521484374997
                            ],
                            [
                                -86.30717773437493,
                                80.31933593750003
                            ],
                            [
                                -83.72363281250003,
                                80.22895507812501
                            ],
                            [
                                -80.05107421874993,
                                80.52856445312503
                            ],
                            [
                                -83.40141601562502,
                                80.71396484375003
                            ],
                            [
                                -85.30742187500002,
                                80.52597656250003
                            ],
                            [
                                -88.92143554687499,
                                80.80561523437498
                            ],
                            [
                                -89.94731445312499,
                                81.17265625000005
                            ],
                            [
                                -88.97836914062503,
                                81.54150390624997
                            ],
                            [
                                -90.49018554687501,
                                81.87724609374999
                            ],
                            [
                                -86.58061523437496,
                                82.18720703124998
                            ],
                            [
                                -82.12250976562498,
                                82.60175781249998
                            ],
                            [
                                -79.88632812500003,
                                82.93852539062502
                            ],
                            [
                                -69.96992187499995,
                                83.11611328125005
                            ],
                            [
                                -69.4888671875,
                                83.01679687499998
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Canada',
                'childNum': 36
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            9.524023437500006,
                            47.524218749999996
                        ],
                        [
                            9.527539062500011,
                            47.270751953125
                        ],
                        [
                            9.580273437500011,
                            47.057373046875
                        ],
                        [
                            9.996875000000017,
                            46.8853515625
                        ],
                        [
                            10.349414062500017,
                            46.984765624999994
                        ],
                        [
                            10.414941406250023,
                            46.964404296874996
                        ],
                        [
                            10.45458984375,
                            46.8994140625
                        ],
                        [
                            10.452832031250011,
                            46.86494140625
                        ],
                        [
                            10.406054687500017,
                            46.73486328125
                        ],
                        [
                            10.39794921875,
                            46.6650390625
                        ],
                        [
                            10.4306640625,
                            46.550048828125
                        ],
                        [
                            10.363085937500017,
                            46.5470703125
                        ],
                        [
                            10.272265625000017,
                            46.564843749999994
                        ],
                        [
                            10.195507812500011,
                            46.62109375
                        ],
                        [
                            10.137500000000017,
                            46.61435546875
                        ],
                        [
                            10.087011718750006,
                            46.599902343749996
                        ],
                        [
                            9.939257812500017,
                            46.36181640625
                        ],
                        [
                            9.203417968750017,
                            46.21923828125
                        ],
                        [
                            8.826757812500006,
                            46.06103515625
                        ],
                        [
                            8.818554687500011,
                            46.0771484375
                        ],
                        [
                            8.458398437500023,
                            46.245898437499996
                        ],
                        [
                            8.370703125000006,
                            46.445117187499996
                        ],
                        [
                            8.08154296875,
                            46.256005859374994
                        ],
                        [
                            7.787890625000017,
                            45.921826171875
                        ],
                        [
                            7.327929687500017,
                            45.912353515625
                        ],
                        [
                            6.772070312500006,
                            46.16513671875
                        ],
                        [
                            6.758105468750017,
                            46.415771484375
                        ],
                        [
                            6.428906250000011,
                            46.430517578125
                        ],
                        [
                            6.27294921875,
                            46.252246093749996
                        ],
                        [
                            6.199414062500011,
                            46.19306640625
                        ],
                        [
                            6.086621093750011,
                            46.147021484374996
                        ],
                        [
                            6.006640625000017,
                            46.142333984375
                        ],
                        [
                            5.971484375000017,
                            46.151220703125
                        ],
                        [
                            5.970019531250017,
                            46.214697265625
                        ],
                        [
                            6.060253906250011,
                            46.428173828125
                        ],
                        [
                            6.429003906250017,
                            46.832275390625
                        ],
                        [
                            7.000585937500006,
                            47.322509765625
                        ],
                        [
                            7.615625000000023,
                            47.592724609375
                        ],
                        [
                            8.414746093750011,
                            47.589599609375
                        ],
                        [
                            8.572656250000023,
                            47.775634765625
                        ],
                        [
                            9.524023437500006,
                            47.524218749999996
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Switzerland',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                -67.07993164062498,
                                -55.15380859374996
                            ],
                            [
                                -68.07001953124995,
                                -55.22109374999999
                            ],
                            [
                                -68.10693359375003,
                                -54.929394531249976
                            ],
                            [
                                -67.245263671875,
                                -54.977636718750034
                            ],
                            [
                                -67.07993164062498,
                                -55.15380859374996
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -69.70297851562503,
                                -54.91904296875
                            ],
                            [
                                -68.90078125000002,
                                -55.01777343750004
                            ],
                            [
                                -68.28266601562495,
                                -55.25517578125
                            ],
                            [
                                -68.293359375,
                                -55.521386718749966
                            ],
                            [
                                -69.41181640624995,
                                -55.44423828124997
                            ],
                            [
                                -69.97978515625002,
                                -55.14746093749999
                            ],
                            [
                                -69.70297851562503,
                                -54.91904296875
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -72.92324218749997,
                                -53.481640625
                            ],
                            [
                                -72.20541992187503,
                                -53.80742187500002
                            ],
                            [
                                -73.30473632812499,
                                -53.943945312500034
                            ],
                            [
                                -73.68652343749997,
                                -53.42685546875003
                            ],
                            [
                                -72.92324218749997,
                                -53.481640625
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -68.62993164062499,
                                -52.65263671875004
                            ],
                            [
                                -68.65322265624994,
                                -54.85361328124999
                            ],
                            [
                                -69.48627929687493,
                                -54.85888671875
                            ],
                            [
                                -69.72343750000002,
                                -54.71210937500003
                            ],
                            [
                                -70.49716796875,
                                -54.80957031249999
                            ],
                            [
                                -71.44091796874997,
                                -54.61962890625002
                            ],
                            [
                                -70.89824218749999,
                                -54.337890625
                            ],
                            [
                                -70.86772460937499,
                                -53.88417968750002
                            ],
                            [
                                -70.09038085937499,
                                -53.41816406250003
                            ],
                            [
                                -70.39067382812496,
                                -53.02646484374999
                            ],
                            [
                                -69.76357421875002,
                                -52.731347656249994
                            ],
                            [
                                -68.62993164062499,
                                -52.65263671875004
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -74.47617187499998,
                                -49.14785156250002
                            ],
                            [
                                -74.59472656249997,
                                -50.00664062500001
                            ],
                            [
                                -75.30009765624996,
                                -49.8474609375
                            ],
                            [
                                -75.184228515625,
                                -49.08359374999999
                            ],
                            [
                                -74.79345703124997,
                                -48.70507812499997
                            ],
                            [
                                -74.47617187499998,
                                -49.14785156250002
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -74.56728515625,
                                -48.591992187500026
                            ],
                            [
                                -74.92304687499998,
                                -48.62646484375003
                            ],
                            [
                                -75.21289062499997,
                                -48.141699218750034
                            ],
                            [
                                -74.72929687499996,
                                -48.12587890625001
                            ],
                            [
                                -74.56728515625,
                                -48.591992187500026
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -72.98613281249999,
                                -44.780078124999974
                            ],
                            [
                                -73.44506835937497,
                                -44.641015624999966
                            ],
                            [
                                -73.20771484374993,
                                -44.33496093749997
                            ],
                            [
                                -72.76406249999997,
                                -44.54902343749997
                            ],
                            [
                                -72.98613281249999,
                                -44.780078124999974
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -73.73535156249997,
                                -44.39453125000003
                            ],
                            [
                                -73.98330078125002,
                                -44.494824218750026
                            ],
                            [
                                -73.72714843749998,
                                -45.11904296875001
                            ],
                            [
                                -74.09907226562498,
                                -45.32539062500004
                            ],
                            [
                                -74.61777343749998,
                                -44.64794921874996
                            ],
                            [
                                -74.50180664062498,
                                -44.47353515624995
                            ],
                            [
                                -73.73535156249997,
                                -44.39453125000003
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -73.77338867187498,
                                -43.3458984375
                            ],
                            [
                                -74.35493164062498,
                                -43.26357421875001
                            ],
                            [
                                -74.20947265625,
                                -42.87871093750003
                            ],
                            [
                                -74.03666992187496,
                                -41.79550781249998
                            ],
                            [
                                -73.52783203124997,
                                -41.89628906249999
                            ],
                            [
                                -73.47080078124998,
                                -42.46630859375004
                            ],
                            [
                                -73.78925781249993,
                                -42.58574218750003
                            ],
                            [
                                -73.43632812499996,
                                -42.9365234375
                            ],
                            [
                                -73.77338867187498,
                                -43.3458984375
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -67.19487304687493,
                                -22.821679687500037
                            ],
                            [
                                -67.00878906249994,
                                -23.00136718750005
                            ],
                            [
                                -67.35620117187503,
                                -24.033789062499963
                            ],
                            [
                                -68.25029296875002,
                                -24.391992187500023
                            ],
                            [
                                -68.56201171875,
                                -24.83769531249996
                            ],
                            [
                                -68.38422851562495,
                                -25.091894531249977
                            ],
                            [
                                -68.60029296874998,
                                -25.48564453124999
                            ],
                            [
                                -68.41450195312498,
                                -26.153710937500023
                            ],
                            [
                                -68.58115234375,
                                -26.518359374999974
                            ],
                            [
                                -68.34599609374996,
                                -27.02792968750005
                            ],
                            [
                                -68.84633789062494,
                                -27.153710937499994
                            ],
                            [
                                -69.17441406249998,
                                -27.924707031250037
                            ],
                            [
                                -69.65693359374995,
                                -28.413574218749986
                            ],
                            [
                                -69.82788085937497,
                                -29.10322265624997
                            ],
                            [
                                -70.02680664062501,
                                -29.324023437500017
                            ],
                            [
                                -69.92763671874997,
                                -29.76914062500002
                            ],
                            [
                                -69.95634765624996,
                                -30.35820312500003
                            ],
                            [
                                -70.16142578124999,
                                -30.44023437499996
                            ],
                            [
                                -70.51958007812493,
                                -31.1484375
                            ],
                            [
                                -70.585205078125,
                                -31.569433593749963
                            ],
                            [
                                -70.16962890624995,
                                -32.471679687500014
                            ],
                            [
                                -70.01982421874999,
                                -33.27148437499997
                            ],
                            [
                                -69.79775390624997,
                                -33.398632812500026
                            ],
                            [
                                -69.85244140625,
                                -34.224316406250026
                            ],
                            [
                                -70.28994140624997,
                                -34.732812499999966
                            ],
                            [
                                -70.47041015624995,
                                -35.32617187499997
                            ],
                            [
                                -70.40478515625,
                                -36.06171874999998
                            ],
                            [
                                -71.05551757812498,
                                -36.52373046874996
                            ],
                            [
                                -71.16757812499998,
                                -37.76230468749996
                            ],
                            [
                                -71.028173828125,
                                -38.041210937500026
                            ],
                            [
                                -70.95161132812493,
                                -38.73847656249997
                            ],
                            [
                                -71.42558593749996,
                                -38.98564453125006
                            ],
                            [
                                -71.53945312499997,
                                -39.60244140624995
                            ],
                            [
                                -71.71992187499995,
                                -39.63525390624997
                            ],
                            [
                                -71.69531250000003,
                                -40.33525390625003
                            ],
                            [
                                -71.93212890624994,
                                -40.69169921874999
                            ],
                            [
                                -71.91127929687497,
                                -41.650390624999986
                            ],
                            [
                                -71.76093749999998,
                                -42.101464843749966
                            ],
                            [
                                -72.12460937499998,
                                -42.298339843750014
                            ],
                            [
                                -72.14643554687498,
                                -42.990039062499974
                            ],
                            [
                                -71.781494140625,
                                -43.16679687500002
                            ],
                            [
                                -71.90498046875001,
                                -43.44013671875001
                            ],
                            [
                                -71.68007812500002,
                                -43.92958984374998
                            ],
                            [
                                -71.82001953124993,
                                -44.38310546875
                            ],
                            [
                                -71.21259765624998,
                                -44.44121093750003
                            ],
                            [
                                -71.26113281250002,
                                -44.763085937499966
                            ],
                            [
                                -71.5962890625,
                                -44.97919921875004
                            ],
                            [
                                -71.35375976562497,
                                -45.23046874999997
                            ],
                            [
                                -71.77265624999995,
                                -45.724414062499974
                            ],
                            [
                                -71.87568359374998,
                                -46.16054687500001
                            ],
                            [
                                -71.69521484375,
                                -46.57841796875006
                            ],
                            [
                                -71.94023437499999,
                                -46.831250000000054
                            ],
                            [
                                -71.90498046875001,
                                -47.201660156250014
                            ],
                            [
                                -72.34594726562497,
                                -47.49267578124997
                            ],
                            [
                                -72.517919921875,
                                -47.87636718749998
                            ],
                            [
                                -72.35473632812497,
                                -48.36582031250005
                            ],
                            [
                                -72.61440429687494,
                                -48.79287109375
                            ],
                            [
                                -73.57626953124998,
                                -49.582910156250016
                            ],
                            [
                                -73.50126953124996,
                                -50.125292968750024
                            ],
                            [
                                -73.15292968749998,
                                -50.73828125000003
                            ],
                            [
                                -72.50981445312496,
                                -50.607519531250034
                            ],
                            [
                                -72.27631835937498,
                                -50.910253906249984
                            ],
                            [
                                -72.40766601562501,
                                -51.54082031250002
                            ],
                            [
                                -71.91865234374995,
                                -51.98955078125004
                            ],
                            [
                                -69.96025390624993,
                                -52.00820312500002
                            ],
                            [
                                -68.443359375,
                                -52.35664062500004
                            ],
                            [
                                -69.24101562499996,
                                -52.20546874999997
                            ],
                            [
                                -69.56059570312496,
                                -52.42158203125004
                            ],
                            [
                                -70.79511718749995,
                                -52.768749999999976
                            ],
                            [
                                -70.99584960937497,
                                -53.77929687499997
                            ],
                            [
                                -71.29775390625002,
                                -53.88339843750004
                            ],
                            [
                                -72.1744140625,
                                -53.632324218749964
                            ],
                            [
                                -71.85273437499993,
                                -53.28574218749996
                            ],
                            [
                                -71.40034179687495,
                                -53.10703124999997
                            ],
                            [
                                -71.38774414062496,
                                -52.76425781250004
                            ],
                            [
                                -72.45830078124993,
                                -53.25449218749999
                            ],
                            [
                                -73.05273437499997,
                                -53.24345703125005
                            ],
                            [
                                -74.01445312499999,
                                -52.63935546875
                            ],
                            [
                                -73.51816406250003,
                                -52.04101562499996
                            ],
                            [
                                -73.9732421875,
                                -51.78447265625
                            ],
                            [
                                -73.8958984375,
                                -51.331445312499994
                            ],
                            [
                                -74.81474609374996,
                                -51.062890624999994
                            ],
                            [
                                -75.09467773437495,
                                -50.681249999999956
                            ],
                            [
                                -74.333740234375,
                                -49.97460937499997
                            ],
                            [
                                -74.34101562499998,
                                -48.59570312499998
                            ],
                            [
                                -74.57719726562496,
                                -48.274414062499986
                            ],
                            [
                                -74.654931640625,
                                -47.702246093750034
                            ],
                            [
                                -74.15839843749998,
                                -47.18251953125002
                            ],
                            [
                                -74.15190429687499,
                                -46.97441406249999
                            ],
                            [
                                -75.65678710937499,
                                -46.610351562499986
                            ],
                            [
                                -74.924462890625,
                                -46.159667968750014
                            ],
                            [
                                -75.06669921874993,
                                -45.874902343749994
                            ],
                            [
                                -74.15786132812497,
                                -45.76718749999999
                            ],
                            [
                                -74.03754882812498,
                                -45.41767578125001
                            ],
                            [
                                -73.54990234375,
                                -45.48378906249998
                            ],
                            [
                                -73.36245117187502,
                                -44.97822265625001
                            ],
                            [
                                -72.73896484375001,
                                -44.73417968750003
                            ],
                            [
                                -72.66386718749999,
                                -44.43642578124995
                            ],
                            [
                                -73.26508789062498,
                                -44.16865234375001
                            ],
                            [
                                -72.99658203125,
                                -43.63154296875001
                            ],
                            [
                                -72.78515625,
                                -42.301269531249986
                            ],
                            [
                                -72.49941406249997,
                                -41.980859374999994
                            ],
                            [
                                -72.66977539062503,
                                -41.65937500000004
                            ],
                            [
                                -73.014990234375,
                                -41.54384765624995
                            ],
                            [
                                -73.52128906249996,
                                -41.797070312500026
                            ],
                            [
                                -73.96586914062493,
                                -41.118261718750034
                            ],
                            [
                                -73.66943359375003,
                                -40.08232421875002
                            ],
                            [
                                -73.41040039062503,
                                -39.78916015624998
                            ],
                            [
                                -73.22646484375002,
                                -39.22441406250003
                            ],
                            [
                                -73.53256835937503,
                                -38.366796875000006
                            ],
                            [
                                -73.46479492187498,
                                -38.04033203125003
                            ],
                            [
                                -73.66181640624998,
                                -37.69853515625003
                            ],
                            [
                                -73.60166015624998,
                                -37.18847656250003
                            ],
                            [
                                -73.21596679687502,
                                -37.16689453124998
                            ],
                            [
                                -73.11806640624997,
                                -36.68837890625002
                            ],
                            [
                                -72.87456054687502,
                                -36.39042968749999
                            ],
                            [
                                -72.62392578125002,
                                -35.5857421875
                            ],
                            [
                                -72.22377929687494,
                                -35.096191406250014
                            ],
                            [
                                -72.00283203124997,
                                -34.16533203125
                            ],
                            [
                                -71.66435546875002,
                                -33.65263671875
                            ],
                            [
                                -71.74296874999996,
                                -33.09511718750001
                            ],
                            [
                                -71.45224609374998,
                                -32.65957031250001
                            ],
                            [
                                -71.52587890624997,
                                -31.805859374999997
                            ],
                            [
                                -71.66196289062498,
                                -31.169531250000034
                            ],
                            [
                                -71.66948242187499,
                                -30.33037109374996
                            ],
                            [
                                -71.40039062499997,
                                -30.142968749999966
                            ],
                            [
                                -71.31572265624996,
                                -29.649707031250017
                            ],
                            [
                                -71.51923828124993,
                                -28.926464843750026
                            ],
                            [
                                -71.18642578125,
                                -28.37783203124998
                            ],
                            [
                                -71.05263671875,
                                -27.727343749999974
                            ],
                            [
                                -70.64658203124998,
                                -26.329394531250017
                            ],
                            [
                                -70.71372070312498,
                                -25.78417968749997
                            ],
                            [
                                -70.44536132812502,
                                -25.17265624999999
                            ],
                            [
                                -70.57412109374994,
                                -24.644335937500003
                            ],
                            [
                                -70.48779296874994,
                                -23.781738281249957
                            ],
                            [
                                -70.56318359374995,
                                -23.057031250000023
                            ],
                            [
                                -70.33168945312494,
                                -22.848632812500014
                            ],
                            [
                                -70.08754882812494,
                                -21.49306640624998
                            ],
                            [
                                -70.19702148437494,
                                -20.725390625000003
                            ],
                            [
                                -70.14814453124995,
                                -19.805078124999994
                            ],
                            [
                                -70.27578125000002,
                                -19.267578125000014
                            ],
                            [
                                -70.33486328124997,
                                -18.82753906249998
                            ],
                            [
                                -70.33608398437494,
                                -18.595214843749986
                            ],
                            [
                                -70.36162109374999,
                                -18.398046874999963
                            ],
                            [
                                -70.41826171874999,
                                -18.345605468750023
                            ],
                            [
                                -70.37749023437496,
                                -18.333593749999977
                            ],
                            [
                                -70.18378906250001,
                                -18.325195312499957
                            ],
                            [
                                -70.05908203125,
                                -18.28349609374999
                            ],
                            [
                                -69.92636718749998,
                                -18.206054687500014
                            ],
                            [
                                -69.85209960937493,
                                -17.70380859375001
                            ],
                            [
                                -69.49501953125002,
                                -17.619531249999994
                            ],
                            [
                                -69.3580078125,
                                -17.771679687499983
                            ],
                            [
                                -69.31337890624997,
                                -17.943164062500017
                            ],
                            [
                                -69.28232421875003,
                                -17.96484375
                            ],
                            [
                                -69.09394531249993,
                                -18.05048828125004
                            ],
                            [
                                -69.09228515624994,
                                -18.28242187500004
                            ],
                            [
                                -69.02680664062493,
                                -18.656249999999986
                            ],
                            [
                                -68.97885742187503,
                                -18.81298828125003
                            ],
                            [
                                -68.96831054687502,
                                -18.967968749999983
                            ],
                            [
                                -68.85795898437499,
                                -19.09335937500005
                            ],
                            [
                                -68.62055664062495,
                                -19.29667968749999
                            ],
                            [
                                -68.54785156249997,
                                -19.341113281249974
                            ],
                            [
                                -68.49199218749996,
                                -19.381933593750034
                            ],
                            [
                                -68.47016601562495,
                                -19.409960937499974
                            ],
                            [
                                -68.46289062499997,
                                -19.43281250000001
                            ],
                            [
                                -68.69829101562499,
                                -19.721093750000037
                            ],
                            [
                                -68.75932617187499,
                                -20.115527343750003
                            ],
                            [
                                -68.74516601562493,
                                -20.458593750000006
                            ],
                            [
                                -68.197021484375,
                                -21.30029296874997
                            ],
                            [
                                -68.18642578124997,
                                -21.618554687499966
                            ],
                            [
                                -68.11215820312503,
                                -21.753027343750006
                            ],
                            [
                                -68.0767578125,
                                -21.982812500000023
                            ],
                            [
                                -67.98837890625003,
                                -22.05712890624997
                            ],
                            [
                                -67.95390624999993,
                                -22.204003906249966
                            ],
                            [
                                -67.95039062499993,
                                -22.333691406249997
                            ],
                            [
                                -67.88173828124997,
                                -22.493359375000026
                            ],
                            [
                                -67.87944335937496,
                                -22.822949218750026
                            ],
                            [
                                -67.57993164062495,
                                -22.89169921874999
                            ],
                            [
                                -67.36225585937493,
                                -22.85517578125001
                            ],
                            [
                                -67.19487304687493,
                                -22.821679687500037
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Chile',
                'childNum': 10
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                110.88876953125006,
                                19.99194335937497
                            ],
                            [
                                111.01367187500003,
                                19.65546875000001
                            ],
                            [
                                110.57216796875005,
                                19.17187499999997
                            ],
                            [
                                110.45126953125012,
                                18.747949218750023
                            ],
                            [
                                109.51933593750007,
                                18.21826171875003
                            ],
                            [
                                108.7015625,
                                18.535253906250034
                            ],
                            [
                                108.69355468750004,
                                19.33828125000005
                            ],
                            [
                                109.17910156250005,
                                19.674121093750074
                            ],
                            [
                                109.21894531250004,
                                19.842822265625074
                            ],
                            [
                                110.1715820312501,
                                20.053710937500057
                            ],
                            [
                                110.88876953125006,
                                19.99194335937497
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                130.52695312500012,
                                42.535400390625
                            ],
                            [
                                130.24033203125006,
                                42.891796874999955
                            ],
                            [
                                129.89824218750002,
                                42.998144531250034
                            ],
                            [
                                129.71972656249997,
                                42.47500000000005
                            ],
                            [
                                129.3136718750001,
                                42.41357421874997
                            ],
                            [
                                128.92343750000006,
                                42.038232421874966
                            ],
                            [
                                128.04521484375007,
                                41.98750000000004
                            ],
                            [
                                128.29091796875,
                                41.56279296875002
                            ],
                            [
                                128.11123046875,
                                41.389257812500006
                            ],
                            [
                                127.17968750000003,
                                41.531347656250006
                            ],
                            [
                                126.9035156250001,
                                41.78105468749999
                            ],
                            [
                                125.98906250000002,
                                40.904638671875034
                            ],
                            [
                                124.8893554687501,
                                40.459814453125006
                            ],
                            [
                                124.36210937500002,
                                40.004052734374994
                            ],
                            [
                                124.10576171875002,
                                39.84101562499998
                            ],
                            [
                                123.34814453124997,
                                39.76293945312497
                            ],
                            [
                                122.33486328125005,
                                39.36611328125005
                            ],
                            [
                                121.64990234375003,
                                38.865087890625034
                            ],
                            [
                                121.78544921875002,
                                39.40083007812501
                            ],
                            [
                                121.51757812499997,
                                39.638964843750045
                            ],
                            [
                                122.27500000000012,
                                40.541845703125034
                            ],
                            [
                                121.85878906250005,
                                40.84208984375002
                            ],
                            [
                                121.1745117187501,
                                40.901269531249994
                            ],
                            [
                                120.47910156250006,
                                40.23095703125003
                            ],
                            [
                                119.59111328125007,
                                39.90263671875002
                            ],
                            [
                                118.9123046875001,
                                39.16640625000005
                            ],
                            [
                                118.29785156249997,
                                39.067089843749955
                            ],
                            [
                                117.78466796875003,
                                39.13447265625001
                            ],
                            [
                                117.5578125000001,
                                38.625146484374994
                            ],
                            [
                                118.01494140625007,
                                38.18339843749996
                            ],
                            [
                                118.94003906250006,
                                38.04277343750002
                            ],
                            [
                                119.08916015625007,
                                37.70073242187496
                            ],
                            [
                                118.95263671875003,
                                37.33115234374998
                            ],
                            [
                                119.4499023437501,
                                37.12475585937503
                            ],
                            [
                                120.37011718749997,
                                37.701025390625006
                            ],
                            [
                                120.75000000000003,
                                37.83393554687501
                            ],
                            [
                                121.64023437500012,
                                37.46035156250002
                            ],
                            [
                                122.60234375000002,
                                37.42641601562502
                            ],
                            [
                                122.51972656250004,
                                36.94682617187502
                            ],
                            [
                                121.93271484375006,
                                36.95947265625003
                            ],
                            [
                                120.98994140625004,
                                36.59794921874999
                            ],
                            [
                                120.39306640625003,
                                36.053857421874994
                            ],
                            [
                                120.05468750000003,
                                35.86113281249999
                            ],
                            [
                                119.21582031250003,
                                35.01176757812496
                            ],
                            [
                                119.58291015625,
                                34.58222656250004
                            ],
                            [
                                120.20146484375007,
                                34.32568359375
                            ],
                            [
                                120.87109374999997,
                                33.016503906249994
                            ],
                            [
                                120.8532226562501,
                                32.66137695312503
                            ],
                            [
                                121.40097656250012,
                                32.371923828125034
                            ],
                            [
                                121.351953125,
                                31.85878906250005
                            ],
                            [
                                121.05537109375004,
                                31.719433593749983
                            ],
                            [
                                121.66064453124997,
                                31.319726562499994
                            ],
                            [
                                121.87792968750003,
                                30.91699218750003
                            ],
                            [
                                120.99765625000012,
                                30.558251953125023
                            ],
                            [
                                121.1593750000001,
                                30.3017578125
                            ],
                            [
                                121.94433593749997,
                                29.89409179687499
                            ],
                            [
                                121.71748046875004,
                                29.25634765625
                            ],
                            [
                                121.60205078124997,
                                28.366601562500023
                            ],
                            [
                                121.09843750000002,
                                28.29052734374997
                            ],
                            [
                                120.2787109375,
                                27.097070312500023
                            ],
                            [
                                119.64824218750002,
                                25.91870117187503
                            ],
                            [
                                119.53945312500005,
                                25.59125976562504
                            ],
                            [
                                119.02460937500004,
                                25.223437500000045
                            ],
                            [
                                118.56035156250007,
                                24.580371093750045
                            ],
                            [
                                118.0871093750001,
                                24.627001953125045
                            ],
                            [
                                118.0560546875,
                                24.24609374999997
                            ],
                            [
                                116.53828125000004,
                                23.17968749999997
                            ],
                            [
                                116.47070312499997,
                                22.945898437500034
                            ],
                            [
                                115.49833984375002,
                                22.718847656250063
                            ],
                            [
                                115.19580078125003,
                                22.81728515625005
                            ],
                            [
                                114.85380859375007,
                                22.616796875000063
                            ],
                            [
                                114.26601562500005,
                                22.540966796874983
                            ],
                            [
                                114.01542968750007,
                                22.51191406250001
                            ],
                            [
                                113.61962890624997,
                                22.861425781249977
                            ],
                            [
                                113.54912109375002,
                                22.225195312500034
                            ],
                            [
                                112.30498046875002,
                                21.74169921875003
                            ],
                            [
                                111.94394531250012,
                                21.84965820312499
                            ],
                            [
                                111.60273437500004,
                                21.55908203125003
                            ],
                            [
                                110.77109375000006,
                                21.386523437500045
                            ],
                            [
                                110.1935546875001,
                                21.037646484375045
                            ],
                            [
                                110.51152343750007,
                                20.51826171875001
                            ],
                            [
                                110.12314453125006,
                                20.263720703125017
                            ],
                            [
                                109.66259765625003,
                                20.91689453125005
                            ],
                            [
                                109.93076171875012,
                                21.480566406250034
                            ],
                            [
                                109.54404296875012,
                                21.537939453125006
                            ],
                            [
                                109.14863281250004,
                                21.425537109375
                            ],
                            [
                                109.03056640625007,
                                21.626513671875017
                            ],
                            [
                                108.32480468750006,
                                21.693505859374994
                            ],
                            [
                                107.97265624999997,
                                21.507958984375023
                            ],
                            [
                                107.35117187500012,
                                21.60888671874997
                            ],
                            [
                                106.72949218749997,
                                22.000341796875063
                            ],
                            [
                                106.53632812500004,
                                22.395410156249994
                            ],
                            [
                                106.78027343749997,
                                22.778906250000034
                            ],
                            [
                                106.14843749999997,
                                22.970068359375006
                            ],
                            [
                                105.8429687500001,
                                22.922802734374955
                            ],
                            [
                                105.27539062500003,
                                23.34521484375003
                            ],
                            [
                                104.86474609375003,
                                23.136376953125023
                            ],
                            [
                                104.79570312500007,
                                22.91113281249997
                            ],
                            [
                                104.37177734375004,
                                22.704052734374983
                            ],
                            [
                                103.35605468750006,
                                22.75468749999999
                            ],
                            [
                                103.00537109375003,
                                22.452978515624977
                            ],
                            [
                                102.51718750000012,
                                22.741015625000045
                            ],
                            [
                                102.42792968750004,
                                22.732812499999966
                            ],
                            [
                                102.2370117187501,
                                22.466015624999983
                            ],
                            [
                                102.1759765625001,
                                22.414648437500006
                            ],
                            [
                                102.12744140624997,
                                22.379199218750045
                            ],
                            [
                                102.02441406250003,
                                22.43920898437503
                            ],
                            [
                                101.84179687500003,
                                22.38847656249999
                            ],
                            [
                                101.75996093750004,
                                22.490332031250034
                            ],
                            [
                                101.73876953124997,
                                22.495263671874994
                            ],
                            [
                                101.70751953125003,
                                22.486572265625
                            ],
                            [
                                101.67148437500006,
                                22.462304687500023
                            ],
                            [
                                101.64619140625004,
                                22.405419921874966
                            ],
                            [
                                101.61992187500002,
                                22.32744140624999
                            ],
                            [
                                101.56787109374997,
                                22.27636718749997
                            ],
                            [
                                101.52451171875006,
                                22.25366210937497
                            ],
                            [
                                101.7365234375001,
                                21.826513671874977
                            ],
                            [
                                101.74394531250007,
                                21.77797851562505
                            ],
                            [
                                101.74726562500004,
                                21.605761718750045
                            ],
                            [
                                101.72294921875007,
                                21.31494140625003
                            ],
                            [
                                101.7630859375,
                                21.278906249999977
                            ],
                            [
                                101.80205078125002,
                                21.235986328125023
                            ],
                            [
                                101.80058593750007,
                                21.212597656249983
                            ],
                            [
                                101.78349609375007,
                                21.204150390625017
                            ],
                            [
                                101.7047851562501,
                                21.15014648437503
                            ],
                            [
                                101.54238281250005,
                                21.23427734375005
                            ],
                            [
                                101.2814453125001,
                                21.184130859375045
                            ],
                            [
                                101.24785156250007,
                                21.197314453125045
                            ],
                            [
                                101.22441406250002,
                                21.223730468750034
                            ],
                            [
                                101.1388671875001,
                                21.567480468749977
                            ],
                            [
                                100.83515625000004,
                                21.655175781249994
                            ],
                            [
                                100.21474609375,
                                21.462988281250006
                            ],
                            [
                                99.9176757812501,
                                22.02802734375001
                            ],
                            [
                                99.19296875000006,
                                22.12597656249997
                            ],
                            [
                                99.33769531250002,
                                22.49804687500003
                            ],
                            [
                                99.34082031249997,
                                23.095898437499955
                            ],
                            [
                                98.86376953125003,
                                23.191259765625034
                            ],
                            [
                                98.76435546875004,
                                24.116064453125063
                            ],
                            [
                                98.21250000000006,
                                24.110644531250017
                            ],
                            [
                                97.68603515624997,
                                23.898095703124966
                            ],
                            [
                                97.53144531250004,
                                24.49169921875003
                            ],
                            [
                                97.8195312500001,
                                25.251855468749994
                            ],
                            [
                                98.01074218749997,
                                25.292529296875017
                            ],
                            [
                                98.6546875,
                                25.917773437500017
                            ],
                            [
                                98.7318359375,
                                26.583398437499994
                            ],
                            [
                                98.65117187500007,
                                27.572460937499983
                            ],
                            [
                                98.29882812499997,
                                27.550097656250045
                            ],
                            [
                                98.09892578125002,
                                28.142285156249983
                            ],
                            [
                                97.53789062500002,
                                28.510205078124983
                            ],
                            [
                                97.43144531250002,
                                28.353906250000023
                            ],
                            [
                                97.35644531249997,
                                28.254492187500006
                            ],
                            [
                                97.3224609375001,
                                28.21796875000004
                            ],
                            [
                                97.28945312500005,
                                28.236816406250057
                            ],
                            [
                                97.14511718750012,
                                28.34033203125
                            ],
                            [
                                97.07539062500004,
                                28.36894531249996
                            ],
                            [
                                96.65283203125003,
                                28.44975585937499
                            ],
                            [
                                96.3890625,
                                28.36791992187503
                            ],
                            [
                                96.32988281250002,
                                28.496826171875
                            ],
                            [
                                96.58085937500007,
                                28.763671875
                            ],
                            [
                                96.14140625000007,
                                28.963476562500034
                            ],
                            [
                                96.18085937500004,
                                29.11767578125
                            ],
                            [
                                96.23496093750006,
                                29.245800781249983
                            ],
                            [
                                96.12851562500012,
                                29.38139648437499
                            ],
                            [
                                96.07958984375003,
                                29.42412109374999
                            ],
                            [
                                96.03535156250004,
                                29.447167968749994
                            ],
                            [
                                95.88505859375007,
                                29.39091796874999
                            ],
                            [
                                95.7103515625,
                                29.313818359375006
                            ],
                            [
                                95.5158203125001,
                                29.206347656250045
                            ],
                            [
                                95.49375000000012,
                                29.137011718750045
                            ],
                            [
                                95.42021484375007,
                                29.054296875000063
                            ],
                            [
                                95.27910156250002,
                                29.04956054687497
                            ],
                            [
                                94.99882812500002,
                                29.14916992187503
                            ],
                            [
                                94.46806640625007,
                                29.216210937499994
                            ],
                            [
                                94.01328125000006,
                                28.90751953124999
                            ],
                            [
                                93.76074218750003,
                                28.729785156250017
                            ],
                            [
                                93.36054687500004,
                                28.654052734375057
                            ],
                            [
                                92.70185546875004,
                                28.147119140624994
                            ],
                            [
                                92.65253906250004,
                                28.093359375000034
                            ],
                            [
                                92.41484375000002,
                                27.824609374999994
                            ],
                            [
                                91.63193359375012,
                                27.759960937499983
                            ],
                            [
                                91.64189453125002,
                                27.923242187500023
                            ],
                            [
                                91.60556640625006,
                                27.95170898437496
                            ],
                            [
                                91.22587890625007,
                                28.071240234374983
                            ],
                            [
                                91.07773437500012,
                                27.974462890624977
                            ],
                            [
                                91.02080078125002,
                                27.970068359374977
                            ],
                            [
                                90.71572265625,
                                28.071728515624983
                            ],
                            [
                                90.47734375000007,
                                28.07084960937499
                            ],
                            [
                                90.3527343750001,
                                28.080224609375023
                            ],
                            [
                                89.98105468750006,
                                28.311181640625023
                            ],
                            [
                                89.8978515625,
                                28.29414062500001
                            ],
                            [
                                89.81689453125003,
                                28.25629882812501
                            ],
                            [
                                89.74980468750002,
                                28.18818359375001
                            ],
                            [
                                89.65273437500005,
                                28.158300781250034
                            ],
                            [
                                89.53691406250007,
                                28.10742187499997
                            ],
                            [
                                88.94755859375002,
                                27.464013671874994
                            ],
                            [
                                88.89140625000002,
                                27.316064453124966
                            ],
                            [
                                88.83251953125003,
                                27.36284179687499
                            ],
                            [
                                88.7648437500001,
                                27.429882812499983
                            ],
                            [
                                88.74902343749997,
                                27.521874999999966
                            ],
                            [
                                88.82988281250002,
                                27.76738281249999
                            ],
                            [
                                88.84882812500004,
                                27.86865234375
                            ],
                            [
                                88.80371093750003,
                                28.006933593750034
                            ],
                            [
                                88.57792968750002,
                                28.093359375000034
                            ],
                            [
                                87.68271484375006,
                                27.821386718750063
                            ],
                            [
                                87.29072265625004,
                                27.821923828124994
                            ],
                            [
                                87.14140625000002,
                                27.838330078124955
                            ],
                            [
                                87.02011718750006,
                                27.928662109374983
                            ],
                            [
                                86.9337890625001,
                                27.96845703125001
                            ],
                            [
                                86.750390625,
                                28.022070312500006
                            ],
                            [
                                86.55449218750007,
                                28.08520507812497
                            ],
                            [
                                86.40869140625003,
                                27.928662109374983
                            ],
                            [
                                86.07871093750006,
                                28.083593750000006
                            ],
                            [
                                85.67832031250012,
                                28.277441406249977
                            ],
                            [
                                85.41064453125003,
                                28.27602539062505
                            ],
                            [
                                85.21210937500004,
                                28.292626953124966
                            ],
                            [
                                85.1224609375,
                                28.315966796875017
                            ],
                            [
                                85.08857421875004,
                                28.37226562500001
                            ],
                            [
                                85.121484375,
                                28.484277343750023
                            ],
                            [
                                85.15908203125,
                                28.592236328124983
                            ],
                            [
                                85.1263671875,
                                28.602636718750063
                            ],
                            [
                                85.06914062500007,
                                28.60966796874999
                            ],
                            [
                                84.85507812500006,
                                28.553613281250023
                            ],
                            [
                                84.7142578125,
                                28.595556640625034
                            ],
                            [
                                84.2287109375001,
                                28.911767578124966
                            ],
                            [
                                84.17558593750002,
                                29.036376953125057
                            ],
                            [
                                84.12783203125005,
                                29.15629882812496
                            ],
                            [
                                84.10136718750002,
                                29.21997070312497
                            ],
                            [
                                84.02197265624997,
                                29.25385742187504
                            ],
                            [
                                83.93593750000005,
                                29.27949218750001
                            ],
                            [
                                83.58349609375003,
                                29.18359375000003
                            ],
                            [
                                83.15546875000004,
                                29.612646484375034
                            ],
                            [
                                83.01396484375007,
                                29.618066406249994
                            ],
                            [
                                82.85429687500002,
                                29.68339843749996
                            ],
                            [
                                82.6408203125001,
                                29.831201171874966
                            ],
                            [
                                82.4865234375001,
                                29.941503906249977
                            ],
                            [
                                82.15898437500007,
                                30.11518554687501
                            ],
                            [
                                82.04335937500005,
                                30.326757812500034
                            ],
                            [
                                81.8548828125,
                                30.362402343750006
                            ],
                            [
                                81.64189453125007,
                                30.38749999999999
                            ],
                            [
                                81.4171875000001,
                                30.33759765625001
                            ],
                            [
                                81.25507812500004,
                                30.09331054687499
                            ],
                            [
                                81.17714843750005,
                                30.039892578125034
                            ],
                            [
                                81.11035156250003,
                                30.03681640625004
                            ],
                            [
                                80.98544921875006,
                                30.23710937499999
                            ],
                            [
                                80.87353515625003,
                                30.290576171875045
                            ],
                            [
                                80.19121093750002,
                                30.56840820312496
                            ],
                            [
                                80.20712890625006,
                                30.683740234375023
                            ],
                            [
                                79.92451171875004,
                                30.888769531250034
                            ],
                            [
                                79.66425781250004,
                                30.965234374999994
                            ],
                            [
                                79.10712890625004,
                                31.402636718750017
                            ],
                            [
                                78.74355468750005,
                                31.323779296875017
                            ],
                            [
                                78.7550781250001,
                                31.55029296875
                            ],
                            [
                                78.69345703125006,
                                31.740380859374994
                            ],
                            [
                                78.49589843750002,
                                32.21577148437504
                            ],
                            [
                                78.4552734375001,
                                32.30034179687502
                            ],
                            [
                                78.41748046874997,
                                32.466699218749994
                            ],
                            [
                                78.38964843749997,
                                32.51987304687498
                            ],
                            [
                                78.73671875,
                                32.55839843750002
                            ],
                            [
                                78.75351562500012,
                                32.49926757812506
                            ],
                            [
                                79.16992187500003,
                                32.497216796874994
                            ],
                            [
                                79.14550781250003,
                                33.00146484375006
                            ],
                            [
                                79.10283203125007,
                                33.05253906249996
                            ],
                            [
                                79.13515625000005,
                                33.17192382812496
                            ],
                            [
                                79.11250000000004,
                                33.22626953125001
                            ],
                            [
                                78.94843750000004,
                                33.346533203125006
                            ],
                            [
                                78.86503906250002,
                                33.43110351562501
                            ],
                            [
                                78.78378906250006,
                                33.80878906250004
                            ],
                            [
                                78.72666015625006,
                                34.013378906249955
                            ],
                            [
                                78.93642578125,
                                34.35195312500002
                            ],
                            [
                                78.86484375000006,
                                34.39033203125001
                            ],
                            [
                                78.32695312500007,
                                34.60639648437498
                            ],
                            [
                                78.15849609375002,
                                34.94648437499998
                            ],
                            [
                                78.07578125000006,
                                35.13491210937502
                            ],
                            [
                                77.79941406250006,
                                35.49589843750002
                            ],
                            [
                                77.44648437500004,
                                35.47558593750006
                            ],
                            [
                                76.25166015625004,
                                35.810937499999994
                            ],
                            [
                                76.07089843750006,
                                35.983007812500034
                            ],
                            [
                                76.01044921875004,
                                35.996337890625
                            ],
                            [
                                75.94511718750002,
                                36.01757812499997
                            ],
                            [
                                75.840234375,
                                36.64970703124999
                            ],
                            [
                                75.34667968749997,
                                36.913476562499966
                            ],
                            [
                                75.05390625000004,
                                36.98715820312498
                            ],
                            [
                                74.94912109375,
                                36.96835937500006
                            ],
                            [
                                74.88925781250006,
                                36.95244140625002
                            ],
                            [
                                74.69218750000007,
                                37.035742187500006
                            ],
                            [
                                74.60058593749997,
                                37.03666992187502
                            ],
                            [
                                74.54140625,
                                37.02216796875001
                            ],
                            [
                                74.52646484375006,
                                37.03066406250005
                            ],
                            [
                                74.49794921875,
                                37.057226562500034
                            ],
                            [
                                74.37617187500004,
                                37.13735351562502
                            ],
                            [
                                74.37216796875006,
                                37.15771484375
                            ],
                            [
                                74.72666015625006,
                                37.29072265625001
                            ],
                            [
                                74.89130859375004,
                                37.231640624999955
                            ],
                            [
                                74.74501953125,
                                38.51000976562497
                            ],
                            [
                                74.27744140625,
                                38.659765625000034
                            ],
                            [
                                73.80166015625,
                                38.60688476562501
                            ],
                            [
                                73.7956054687501,
                                39.00214843749998
                            ],
                            [
                                73.60732421875,
                                39.229199218749955
                            ],
                            [
                                73.63632812500006,
                                39.396679687499955
                            ],
                            [
                                73.63164062500007,
                                39.44887695312502
                            ],
                            [
                                73.82294921875004,
                                39.48896484375004
                            ],
                            [
                                73.8727539062501,
                                39.533300781250006
                            ],
                            [
                                73.9146484375,
                                39.60649414062499
                            ],
                            [
                                73.8353515625,
                                39.800146484375006
                            ],
                            [
                                73.85625000000002,
                                39.828662109375045
                            ],
                            [
                                73.88457031250002,
                                39.87792968750006
                            ],
                            [
                                73.93876953125002,
                                39.97880859374999
                            ],
                            [
                                73.99160156250005,
                                40.04311523437502
                            ],
                            [
                                74.02050781249997,
                                40.05937499999996
                            ],
                            [
                                74.0851562500001,
                                40.074316406250006
                            ],
                            [
                                74.24267578124997,
                                40.09204101562506
                            ],
                            [
                                74.41191406250007,
                                40.13720703125003
                            ],
                            [
                                75.0044921875,
                                40.44951171874996
                            ],
                            [
                                75.58349609375003,
                                40.605322265625006
                            ],
                            [
                                75.65595703125004,
                                40.329248046874966
                            ],
                            [
                                75.87197265625,
                                40.30322265625
                            ],
                            [
                                76.06230468750002,
                                40.387548828125034
                            ],
                            [
                                76.25830078124997,
                                40.43076171875006
                            ],
                            [
                                76.3185546875001,
                                40.352246093749955
                            ],
                            [
                                76.39638671875005,
                                40.389794921874966
                            ],
                            [
                                76.4801757812501,
                                40.44951171874996
                            ],
                            [
                                76.82402343750002,
                                40.982324218749966
                            ],
                            [
                                76.90771484374997,
                                41.02416992187497
                            ],
                            [
                                76.98662109375002,
                                41.039160156250006
                            ],
                            [
                                77.58173828125004,
                                40.99277343750006
                            ],
                            [
                                77.71933593750012,
                                41.024316406249994
                            ],
                            [
                                77.81523437500002,
                                41.05561523437498
                            ],
                            [
                                77.9564453125,
                                41.05068359375005
                            ],
                            [
                                78.1234375,
                                41.07563476562498
                            ],
                            [
                                78.34628906250012,
                                41.28144531249998
                            ],
                            [
                                78.36240234375012,
                                41.37163085937496
                            ],
                            [
                                79.29355468750006,
                                41.78281249999998
                            ],
                            [
                                79.76611328124997,
                                41.89887695312501
                            ],
                            [
                                79.84042968750012,
                                41.99575195312502
                            ],
                            [
                                79.90966796875003,
                                42.014990234375034
                            ],
                            [
                                80.21621093750005,
                                42.03242187500004
                            ],
                            [
                                80.23515625000007,
                                42.04345703124997
                            ],
                            [
                                80.24619140625012,
                                42.05981445312503
                            ],
                            [
                                80.20937500000005,
                                42.190039062500006
                            ],
                            [
                                80.16503906249997,
                                42.66552734375006
                            ],
                            [
                                80.78574218750006,
                                43.16157226562504
                            ],
                            [
                                80.35527343750002,
                                44.09726562500006
                            ],
                            [
                                80.45546875,
                                44.74609375
                            ],
                            [
                                80.05917968750012,
                                45.006445312500006
                            ],
                            [
                                81.60205078125003,
                                45.31083984375002
                            ],
                            [
                                82.4787109375001,
                                45.12358398437499
                            ],
                            [
                                82.32666015625003,
                                45.519921874999994
                            ],
                            [
                                83.0041015625001,
                                47.03349609374996
                            ],
                            [
                                83.19306640625004,
                                47.18657226562499
                            ],
                            [
                                84.78613281249997,
                                46.83071289062505
                            ],
                            [
                                85.65664062500005,
                                47.254638671875
                            ],
                            [
                                85.52597656250006,
                                47.915625000000006
                            ],
                            [
                                85.7494140625,
                                48.38505859374999
                            ],
                            [
                                86.54941406250012,
                                48.52861328125002
                            ],
                            [
                                86.75312500000004,
                                49.00883789062496
                            ],
                            [
                                87.32285156250012,
                                49.085791015625006
                            ],
                            [
                                87.41669921875004,
                                49.07661132812501
                            ],
                            [
                                87.5158203125001,
                                49.122412109375006
                            ],
                            [
                                87.76250000000007,
                                49.16582031249996
                            ],
                            [
                                87.81425781250002,
                                49.162304687499955
                            ],
                            [
                                87.82519531249997,
                                49.116308593750006
                            ],
                            [
                                87.81630859375,
                                49.080273437499955
                            ],
                            [
                                87.83466796875004,
                                49.031933593749955
                            ],
                            [
                                87.87216796875012,
                                49.000146484374966
                            ],
                            [
                                87.85986328125003,
                                48.96552734375001
                            ],
                            [
                                87.74316406250003,
                                48.88164062499999
                            ],
                            [
                                87.9796875000001,
                                48.55512695312498
                            ],
                            [
                                88.30996093750005,
                                48.47207031250002
                            ],
                            [
                                88.41396484375,
                                48.403417968750006
                            ],
                            [
                                88.51708984374997,
                                48.384472656249955
                            ],
                            [
                                88.56679687500005,
                                48.31743164062496
                            ],
                            [
                                88.57597656250007,
                                48.220166015624955
                            ],
                            [
                                88.68183593750004,
                                48.170556640624994
                            ],
                            [
                                88.83828125000005,
                                48.101708984374994
                            ],
                            [
                                88.91777343750007,
                                48.089013671874966
                            ],
                            [
                                89.04765625000007,
                                48.002539062500034
                            ],
                            [
                                89.11562500000005,
                                47.98769531250002
                            ],
                            [
                                89.19628906249997,
                                47.98090820312498
                            ],
                            [
                                89.32988281250007,
                                48.024853515624955
                            ],
                            [
                                89.5609375,
                                48.00395507812496
                            ],
                            [
                                89.63847656250007,
                                47.909082031249994
                            ],
                            [
                                89.69316406250002,
                                47.87915039062503
                            ],
                            [
                                89.72558593749997,
                                47.85249023437501
                            ],
                            [
                                89.77812500000002,
                                47.82700195312498
                            ],
                            [
                                89.91044921875007,
                                47.844335937500034
                            ],
                            [
                                89.95869140625004,
                                47.88632812499998
                            ],
                            [
                                90.02792968750012,
                                47.877685546875
                            ],
                            [
                                90.31328125000007,
                                47.676171874999994
                            ],
                            [
                                90.49619140625012,
                                47.28515625
                            ],
                            [
                                90.86992187500002,
                                46.95449218750005
                            ],
                            [
                                91.03388671875004,
                                46.529003906249955
                            ],
                            [
                                91.00175781250007,
                                46.03579101562502
                            ],
                            [
                                90.6618164062501,
                                45.525244140625006
                            ],
                            [
                                90.87724609375002,
                                45.19609375000002
                            ],
                            [
                                90.95361328125003,
                                45.21591796875006
                            ],
                            [
                                91.05000000000004,
                                45.217431640624994
                            ],
                            [
                                91.31210937500006,
                                45.11811523437501
                            ],
                            [
                                91.44101562500012,
                                45.12475585937503
                            ],
                            [
                                91.51005859375007,
                                45.09824218750006
                            ],
                            [
                                91.584375,
                                45.07651367187498
                            ],
                            [
                                92.42382812499997,
                                45.008935546874994
                            ],
                            [
                                92.57890625000002,
                                45.01098632812506
                            ],
                            [
                                92.78789062500007,
                                45.035742187500034
                            ],
                            [
                                93.6564453125001,
                                44.900976562500006
                            ],
                            [
                                94.71201171875012,
                                44.35083007812503
                            ],
                            [
                                95.35029296875004,
                                44.27807617187503
                            ],
                            [
                                95.35644531250003,
                                44.005957031250006
                            ],
                            [
                                95.52558593750004,
                                43.953955078125006
                            ],
                            [
                                95.8419921875001,
                                43.383691406249994
                            ],
                            [
                                95.9125,
                                43.20649414062504
                            ],
                            [
                                96.29951171875004,
                                42.92871093750003
                            ],
                            [
                                96.38544921875004,
                                42.72036132812502
                            ],
                            [
                                97.20566406250012,
                                42.78979492187506
                            ],
                            [
                                99.46787109375012,
                                42.568212890625034
                            ],
                            [
                                99.98378906250005,
                                42.67734375000006
                            ],
                            [
                                101.71386718749997,
                                42.46582031250006
                            ],
                            [
                                101.97294921875002,
                                42.21586914062502
                            ],
                            [
                                103.07285156250006,
                                42.00595703125006
                            ],
                            [
                                103.7111328125001,
                                41.75131835937506
                            ],
                            [
                                103.99726562500004,
                                41.796972656250034
                            ],
                            [
                                104.30517578124997,
                                41.84614257812501
                            ],
                            [
                                104.49824218750004,
                                41.87700195312499
                            ],
                            [
                                104.49824218750004,
                                41.65869140625
                            ],
                            [
                                105.19707031250002,
                                41.738037109375
                            ],
                            [
                                105.31435546875005,
                                41.77089843750005
                            ],
                            [
                                105.86757812500005,
                                41.993994140625034
                            ],
                            [
                                106.77001953125003,
                                42.28872070312502
                            ],
                            [
                                108.17119140625002,
                                42.44731445312502
                            ],
                            [
                                108.68730468750002,
                                42.416113281250034
                            ],
                            [
                                109.13164062500002,
                                42.440576171874966
                            ],
                            [
                                109.33984374999997,
                                42.43837890625005
                            ],
                            [
                                109.44316406250002,
                                42.455957031249994
                            ],
                            [
                                110.40039062499997,
                                42.77368164062497
                            ],
                            [
                                111.00722656250005,
                                43.34140624999998
                            ],
                            [
                                111.18681640625002,
                                43.391992187499994
                            ],
                            [
                                111.45107421875005,
                                43.47490234375002
                            ],
                            [
                                111.50351562500006,
                                43.492773437500006
                            ],
                            [
                                111.54736328124997,
                                43.49628906250001
                            ],
                            [
                                111.6408203125001,
                                43.563183593749955
                            ],
                            [
                                111.71972656249997,
                                43.62114257812496
                            ],
                            [
                                111.77109375000006,
                                43.66459960937502
                            ],
                            [
                                111.8781250000001,
                                43.68017578125
                            ],
                            [
                                111.93320312500006,
                                43.71142578125006
                            ],
                            [
                                111.94287109374997,
                                43.75244140624997
                            ],
                            [
                                111.93173828125012,
                                43.81494140625
                            ],
                            [
                                111.83691406249997,
                                43.93466796875006
                            ],
                            [
                                111.40224609375005,
                                44.367285156250006
                            ],
                            [
                                111.89804687500006,
                                45.064062500000034
                            ],
                            [
                                112.03261718750005,
                                45.08164062500006
                            ],
                            [
                                112.11289062500006,
                                45.06293945312498
                            ],
                            [
                                112.41132812500004,
                                45.05820312499998
                            ],
                            [
                                112.59677734375006,
                                44.917675781249955
                            ],
                            [
                                112.7067382812501,
                                44.883447265624994
                            ],
                            [
                                113.04941406250006,
                                44.81035156250002
                            ],
                            [
                                113.3009765625001,
                                44.79165039062502
                            ],
                            [
                                113.58701171875006,
                                44.745703125000006
                            ],
                            [
                                113.65263671875002,
                                44.76347656249999
                            ],
                            [
                                113.87705078125012,
                                44.89619140625001
                            ],
                            [
                                114.03027343749997,
                                44.942578124999955
                            ],
                            [
                                114.41914062500004,
                                45.20258789062501
                            ],
                            [
                                114.48730468750003,
                                45.27172851562506
                            ],
                            [
                                114.50224609375002,
                                45.316308593749994
                            ],
                            [
                                114.51718750000006,
                                45.36459960937506
                            ],
                            [
                                114.56015625000012,
                                45.38999023437498
                            ],
                            [
                                114.64433593750002,
                                45.41328125000001
                            ],
                            [
                                114.73876953124997,
                                45.41962890624998
                            ],
                            [
                                114.91923828125007,
                                45.378271484375006
                            ],
                            [
                                115.16259765624997,
                                45.390234375000034
                            ],
                            [
                                115.6810546875,
                                45.45825195312503
                            ],
                            [
                                116.240625,
                                45.795996093750006
                            ],
                            [
                                116.22910156250012,
                                45.84575195312502
                            ],
                            [
                                116.21298828125012,
                                45.88691406249998
                            ],
                            [
                                116.56259765625012,
                                46.28979492187497
                            ],
                            [
                                116.6888671875,
                                46.321972656249955
                            ],
                            [
                                116.78701171875,
                                46.37666015625001
                            ],
                            [
                                116.85908203125004,
                                46.387939453125
                            ],
                            [
                                117.3333984375,
                                46.36201171875004
                            ],
                            [
                                117.6710937500001,
                                46.52207031250006
                            ],
                            [
                                117.81347656249997,
                                46.537695312500034
                            ],
                            [
                                117.91044921875007,
                                46.61933593749998
                            ],
                            [
                                118.30869140625012,
                                46.71704101562497
                            ],
                            [
                                118.72294921875007,
                                46.69189453125
                            ],
                            [
                                118.8439453125001,
                                46.76020507812498
                            ],
                            [
                                118.95712890625006,
                                46.73486328124997
                            ],
                            [
                                119.16210937499997,
                                46.638671875
                            ],
                            [
                                119.33183593750002,
                                46.61381835937499
                            ],
                            [
                                119.47402343750005,
                                46.626660156249955
                            ],
                            [
                                119.62021484375006,
                                46.60395507812504
                            ],
                            [
                                119.70664062500006,
                                46.60600585937502
                            ],
                            [
                                119.74746093750005,
                                46.62719726562497
                            ],
                            [
                                119.86718750000003,
                                46.67216796874999
                            ],
                            [
                                119.89785156250005,
                                46.857812499999966
                            ],
                            [
                                119.71113281250004,
                                47.14999999999998
                            ],
                            [
                                118.49843750000005,
                                47.98398437499998
                            ],
                            [
                                117.76835937500002,
                                47.98789062499998
                            ],
                            [
                                117.67666015625005,
                                47.908300781250034
                            ],
                            [
                                117.55537109375004,
                                47.80468750000006
                            ],
                            [
                                117.45507812500003,
                                47.74135742187502
                            ],
                            [
                                117.38398437500004,
                                47.67573242187498
                            ],
                            [
                                117.3507812500001,
                                47.65219726562498
                            ],
                            [
                                117.28593750000002,
                                47.666357421875034
                            ],
                            [
                                117.19707031250007,
                                47.74028320312499
                            ],
                            [
                                117.06972656250005,
                                47.80639648437506
                            ],
                            [
                                116.95166015624997,
                                47.836572265624966
                            ],
                            [
                                116.90117187500007,
                                47.85307617187496
                            ],
                            [
                                116.76054687500002,
                                47.869775390624994
                            ],
                            [
                                116.65195312500012,
                                47.86450195312497
                            ],
                            [
                                116.51347656250007,
                                47.839550781249955
                            ],
                            [
                                116.37822265625002,
                                47.84404296874999
                            ],
                            [
                                116.31718750000002,
                                47.85986328125
                            ],
                            [
                                116.2311523437501,
                                47.85820312500002
                            ],
                            [
                                116.07480468750012,
                                47.78955078125
                            ],
                            [
                                115.99384765625004,
                                47.71132812500005
                            ],
                            [
                                115.89824218750002,
                                47.68691406250005
                            ],
                            [
                                115.71171875000002,
                                47.798925781250034
                            ],
                            [
                                115.6164062500001,
                                47.874804687500045
                            ],
                            [
                                115.55761718750003,
                                47.945019531249955
                            ],
                            [
                                115.52509765625004,
                                48.13085937499997
                            ],
                            [
                                115.63945312500007,
                                48.18623046874998
                            ],
                            [
                                115.785546875,
                                48.24824218750001
                            ],
                            [
                                115.7965820312501,
                                48.346337890624994
                            ],
                            [
                                115.7916992187501,
                                48.455712890624994
                            ],
                            [
                                115.8205078125001,
                                48.57724609375006
                            ],
                            [
                                115.9538085937501,
                                48.689355468749994
                            ],
                            [
                                116.6833007812501,
                                49.82377929687499
                            ],
                            [
                                117.8734375,
                                49.51347656250002
                            ],
                            [
                                118.4515625,
                                49.84448242187503
                            ],
                            [
                                119.25986328125012,
                                50.06640625000003
                            ],
                            [
                                119.19189453125003,
                                50.37983398437504
                            ],
                            [
                                120.06689453125003,
                                51.60068359375006
                            ],
                            [
                                120.74980468750007,
                                52.096533203125006
                            ],
                            [
                                120.65615234375,
                                52.56665039062503
                            ],
                            [
                                120.09453125000007,
                                52.787207031250034
                            ],
                            [
                                120.70410156249997,
                                53.171826171874955
                            ],
                            [
                                122.33779296875,
                                53.485009765624994
                            ],
                            [
                                123.6078125,
                                53.546533203124994
                            ],
                            [
                                124.81230468750002,
                                53.133837890625045
                            ],
                            [
                                125.64902343750012,
                                53.042285156250045
                            ],
                            [
                                126.312890625,
                                52.39975585937506
                            ],
                            [
                                126.92480468749997,
                                51.10014648437496
                            ],
                            [
                                127.590234375,
                                50.20898437500003
                            ],
                            [
                                127.50244140624997,
                                49.873437499999994
                            ],
                            [
                                127.99960937500006,
                                49.56860351562506
                            ],
                            [
                                128.70400390625,
                                49.60014648437499
                            ],
                            [
                                129.12011718750003,
                                49.36206054687497
                            ],
                            [
                                129.49814453125012,
                                49.38881835937502
                            ],
                            [
                                130.80429687500012,
                                48.34150390624998
                            ],
                            [
                                130.7121093750001,
                                48.12763671875004
                            ],
                            [
                                131.00273437500002,
                                47.69145507812499
                            ],
                            [
                                132.5619140625,
                                47.768505859374955
                            ],
                            [
                                133.14404296875003,
                                48.10566406249998
                            ],
                            [
                                133.46835937500006,
                                48.09716796875003
                            ],
                            [
                                134.29335937500005,
                                48.37343750000002
                            ],
                            [
                                134.56357421875012,
                                48.321728515624955
                            ],
                            [
                                134.75234375,
                                47.71542968749998
                            ],
                            [
                                134.1676757812501,
                                47.30219726562501
                            ],
                            [
                                133.86132812500003,
                                46.24775390625004
                            ],
                            [
                                133.18603515625003,
                                45.49482421875004
                            ],
                            [
                                132.93603515624997,
                                45.029931640624994
                            ],
                            [
                                131.97753906250003,
                                45.243994140625006
                            ],
                            [
                                131.08232421875002,
                                44.91000976562498
                            ],
                            [
                                131.2552734375,
                                44.07158203124999
                            ],
                            [
                                131.26181640625006,
                                43.43305664062501
                            ],
                            [
                                131.06855468750004,
                                42.90224609375005
                            ],
                            [
                                130.57724609375006,
                                42.81162109375006
                            ],
                            [
                                130.52695312500012,
                                42.535400390625
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                121.00878906249997,
                                22.62036132812497
                            ],
                            [
                                120.5812500000001,
                                22.35639648437504
                            ],
                            [
                                120.32558593750005,
                                22.54243164062501
                            ],
                            [
                                120.0724609375001,
                                23.149755859375006
                            ],
                            [
                                120.15898437500002,
                                23.70903320312499
                            ],
                            [
                                121.04062500000006,
                                25.032812500000034
                            ],
                            [
                                121.59365234375,
                                25.275341796874983
                            ],
                            [
                                121.92900390625002,
                                24.973730468749977
                            ],
                            [
                                121.29589843750003,
                                22.96660156249999
                            ],
                            [
                                121.00878906249997,
                                22.62036132812497
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'China',
                'childNum': 2
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -5.262304687499977,
                            10.319677734374991
                        ],
                        [
                            -4.969921874999926,
                            9.930078125000023
                        ],
                        [
                            -4.814453124999972,
                            9.841162109375034
                        ],
                        [
                            -4.72177734374992,
                            9.756542968750026
                        ],
                        [
                            -4.625830078125006,
                            9.713574218749969
                        ],
                        [
                            -4.526611328124943,
                            9.723486328125034
                        ],
                        [
                            -4.406201171874926,
                            9.647998046875031
                        ],
                        [
                            -4.3322265624999545,
                            9.645703124999997
                        ],
                        [
                            -4.18115234375,
                            9.78173828125
                        ],
                        [
                            -3.7906249999999204,
                            9.917187499999983
                        ],
                        [
                            -3.5811523437499773,
                            9.924316406250014
                        ],
                        [
                            -3.2235351562499375,
                            9.895458984374997
                        ],
                        [
                            -2.9882812499999716,
                            9.687353515624963
                        ],
                        [
                            -2.900878906249943,
                            9.534619140625026
                        ],
                        [
                            -2.8751464843749375,
                            9.500927734374997
                        ],
                        [
                            -2.816748046874949,
                            9.425830078124974
                        ],
                        [
                            -2.705761718749983,
                            9.351367187499989
                        ],
                        [
                            -2.5058593750000284,
                            8.208740234375
                        ],
                        [
                            -2.6133789062499773,
                            8.046679687500017
                        ],
                        [
                            -2.6688476562499943,
                            8.022216796875014
                        ],
                        [
                            -2.7897460937499545,
                            7.931933593750003
                        ],
                        [
                            -2.9590820312499773,
                            7.454541015624997
                        ],
                        [
                            -3.2271484374999773,
                            6.7491210937499915
                        ],
                        [
                            -3.200585937499966,
                            6.348242187500034
                        ],
                        [
                            -2.9622558593749204,
                            5.643017578124983
                        ],
                        [
                            -2.7549804687499773,
                            5.432519531249994
                        ],
                        [
                            -2.815673828125,
                            5.153027343749997
                        ],
                        [
                            -3.3475585937499943,
                            5.13066406249996
                        ],
                        [
                            -4.037207031249977,
                            5.23012695312498
                        ],
                        [
                            -5.56474609374996,
                            5.089453125000006
                        ],
                        [
                            -6.8451660156249545,
                            4.671484375000034
                        ],
                        [
                            -7.544970703124989,
                            4.351318359375
                        ],
                        [
                            -7.574658203124983,
                            4.572314453124989
                        ],
                        [
                            -7.585058593749977,
                            4.916748046875
                        ],
                        [
                            -7.39990234375,
                            5.550585937499989
                        ],
                        [
                            -7.636132812499994,
                            5.90771484375
                        ],
                        [
                            -7.730371093749994,
                            5.9190429687499915
                        ],
                        [
                            -7.800927734374994,
                            6.0389160156249915
                        ],
                        [
                            -7.833251953125,
                            6.076367187499983
                        ],
                        [
                            -7.855517578125017,
                            6.150146484375
                        ],
                        [
                            -7.888623046875011,
                            6.234863281250028
                        ],
                        [
                            -7.9815917968749375,
                            6.2861328125
                        ],
                        [
                            -8.203857421875,
                            6.29072265625004
                        ],
                        [
                            -8.587890625,
                            6.490527343749989
                        ],
                        [
                            -8.324511718749989,
                            6.9200195312499915
                        ],
                        [
                            -8.408740234374989,
                            7.411816406249997
                        ],
                        [
                            -8.429980468749989,
                            7.601855468749989
                        ],
                        [
                            -8.231884765624955,
                            7.556738281250034
                        ],
                        [
                            -8.205957031249994,
                            7.590234375000023
                        ],
                        [
                            -8.115429687499926,
                            7.760742187500028
                        ],
                        [
                            -8.016748046874994,
                            8.144921874999966
                        ],
                        [
                            -7.953125,
                            8.477734374999997
                        ],
                        [
                            -7.823583984374977,
                            8.467675781249994
                        ],
                        [
                            -7.950976562499989,
                            8.786816406249997
                        ],
                        [
                            -7.8961914062499545,
                            9.415869140624991
                        ],
                        [
                            -8.136962890624972,
                            9.495703124999991
                        ],
                        [
                            -8.14584960937492,
                            9.881738281250037
                        ],
                        [
                            -8.155175781249937,
                            9.973193359375017
                        ],
                        [
                            -8.136621093749994,
                            10.022070312500034
                        ],
                        [
                            -7.990624999999994,
                            10.162500000000037
                        ],
                        [
                            -7.9609375,
                            10.163476562499966
                        ],
                        [
                            -7.88408203124996,
                            10.185742187499983
                        ],
                        [
                            -7.814208984374943,
                            10.236572265625028
                        ],
                        [
                            -7.749072265624989,
                            10.342285156249986
                        ],
                        [
                            -7.661132812500028,
                            10.427441406250011
                        ],
                        [
                            -7.385058593749989,
                            10.340136718749989
                        ],
                        [
                            -6.950341796874994,
                            10.342333984374989
                        ],
                        [
                            -6.482617187499983,
                            10.561230468749997
                        ],
                        [
                            -6.261132812499994,
                            10.724072265625054
                        ],
                        [
                            -6.0345703124999375,
                            10.194824218750057
                        ],
                        [
                            -5.843847656249977,
                            10.389550781250023
                        ],
                        [
                            -5.694287109374983,
                            10.433203125000034
                        ],
                        [
                            -5.556591796874983,
                            10.439941406249986
                        ],
                        [
                            -5.382275390625011,
                            10.314013671875003
                        ],
                        [
                            -5.262304687499977,
                            10.319677734374991
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Ivory Coast',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            15.480078125000006,
                            7.5237792968749915
                        ],
                        [
                            15.245898437500017,
                            7.263574218749994
                        ],
                        [
                            14.7392578125,
                            6.27978515625
                        ],
                        [
                            14.616894531250011,
                            5.865136718749994
                        ],
                        [
                            14.708984375,
                            4.665576171874989
                        ],
                        [
                            15.022753906250017,
                            4.358544921874994
                        ],
                        [
                            15.128710937500017,
                            3.826904296875
                        ],
                        [
                            15.775000000000006,
                            3.127197265625
                        ],
                        [
                            16.0634765625,
                            2.9085937499999943
                        ],
                        [
                            16.183398437500017,
                            2.2700683593749886
                        ],
                        [
                            16.059375000000017,
                            1.6762207031249972
                        ],
                        [
                            15.741601562500023,
                            1.9149902343749972
                        ],
                        [
                            14.902441406250006,
                            2.0123046874999915
                        ],
                        [
                            14.484082031250011,
                            2.154736328124997
                        ],
                        [
                            13.293554687500006,
                            2.161572265624997
                        ],
                        [
                            13.220312500000006,
                            2.2564453124999915
                        ],
                        [
                            11.348437500000017,
                            2.2997070312499943
                        ],
                        [
                            11.328710937500006,
                            2.167431640624997
                        ],
                        [
                            11.096582031250023,
                            2.16748046875
                        ],
                        [
                            10.790917968750023,
                            2.1675781249999915
                        ],
                        [
                            9.979882812500023,
                            2.1677734374999886
                        ],
                        [
                            9.8701171875,
                            2.2132812499999943
                        ],
                        [
                            9.80078125,
                            2.304443359375
                        ],
                        [
                            9.876171875000011,
                            3.309765624999997
                        ],
                        [
                            9.42529296875,
                            3.922314453124997
                        ],
                        [
                            9.000097656250006,
                            4.091601562499989
                        ],
                        [
                            8.90283203125,
                            4.4351562499999915
                        ],
                        [
                            8.532812500000006,
                            4.605859374999994
                        ],
                        [
                            8.800976562500011,
                            5.197460937499997
                        ],
                        [
                            8.997167968750006,
                            5.917724609375
                        ],
                        [
                            9.490234375,
                            6.418652343749997
                        ],
                        [
                            9.779882812500006,
                            6.760156249999994
                        ],
                        [
                            9.820703125000023,
                            6.783935546875
                        ],
                        [
                            9.874218750000011,
                            6.803271484374989
                        ],
                        [
                            10.038867187500017,
                            6.92138671875
                        ],
                        [
                            10.1435546875,
                            6.9964355468749915
                        ],
                        [
                            10.167773437500017,
                            6.959179687499997
                        ],
                        [
                            10.185546875,
                            6.912792968749997
                        ],
                        [
                            10.205468750000023,
                            6.8916015625
                        ],
                        [
                            10.482324218750023,
                            6.891259765624994
                        ],
                        [
                            10.606250000000017,
                            7.0630859374999915
                        ],
                        [
                            10.954199218750006,
                            6.776562499999997
                        ],
                        [
                            11.032519531250017,
                            6.697900390624994
                        ],
                        [
                            11.079687500000006,
                            6.505517578124994
                        ],
                        [
                            11.1533203125,
                            6.437939453124997
                        ],
                        [
                            11.2373046875,
                            6.4505371093749915
                        ],
                        [
                            11.324609375000023,
                            6.484667968749989
                        ],
                        [
                            11.551660156250023,
                            6.697265625
                        ],
                        [
                            11.767382812500017,
                            7.272265624999989
                        ],
                        [
                            11.809179687500006,
                            7.3450683593749915
                        ],
                        [
                            12.016015625000023,
                            7.589746093749994
                        ],
                        [
                            12.2333984375,
                            8.282324218749991
                        ],
                        [
                            12.403515625000011,
                            8.595556640624991
                        ],
                        [
                            12.582714843750011,
                            8.624121093749991
                        ],
                        [
                            12.651562500000011,
                            8.667773437499989
                        ],
                        [
                            12.929492187500017,
                            9.42626953125
                        ],
                        [
                            13.175488281250011,
                            9.539648437499991
                        ],
                        [
                            13.269921875000023,
                            10.036181640624989
                        ],
                        [
                            13.535351562500011,
                            10.605078124999991
                        ],
                        [
                            13.89208984375,
                            11.140087890624997
                        ],
                        [
                            14.559765625000011,
                            11.492285156249991
                        ],
                        [
                            14.627148437500011,
                            12.108691406249989
                        ],
                        [
                            14.272851562500023,
                            12.356494140624989
                        ],
                        [
                            14.184863281250017,
                            12.447216796874997
                        ],
                        [
                            14.06396484375,
                            13.078515624999994
                        ],
                        [
                            14.244824218750011,
                            13.077343749999997
                        ],
                        [
                            14.516210937500006,
                            12.979736328125
                        ],
                        [
                            14.76123046875,
                            12.655615234374991
                        ],
                        [
                            15.081250000000011,
                            11.845507812499989
                        ],
                        [
                            15.029882812500006,
                            11.113671874999994
                        ],
                        [
                            15.276074218750011,
                            10.357373046874997
                        ],
                        [
                            15.540917968750023,
                            9.960302734374991
                        ],
                        [
                            14.243261718750006,
                            9.979736328125
                        ],
                        [
                            13.977246093750011,
                            9.691552734374994
                        ],
                        [
                            14.332324218750017,
                            9.203515624999994
                        ],
                        [
                            15.1162109375,
                            8.557324218749997
                        ],
                        [
                            15.442968750000006,
                            7.851855468749989
                        ],
                        [
                            15.480078125000006,
                            7.5237792968749915
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Cameroon',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            27.4033203125,
                            5.109179687499989
                        ],
                        [
                            27.7880859375,
                            4.644677734374994
                        ],
                        [
                            28.19208984375001,
                            4.350244140624994
                        ],
                        [
                            28.72705078125,
                            4.5049804687499915
                        ],
                        [
                            29.151464843750006,
                            4.38818359375
                        ],
                        [
                            29.676855468750006,
                            4.5869140625
                        ],
                        [
                            30.194921875000006,
                            3.98193359375
                        ],
                        [
                            30.50830078125,
                            3.835693359375
                        ],
                        [
                            30.83857421875001,
                            3.49072265625
                        ],
                        [
                            30.728613281250006,
                            2.4553710937499886
                        ],
                        [
                            31.176367187500006,
                            2.2700683593749886
                        ],
                        [
                            31.252734375000017,
                            2.0445800781249943
                        ],
                        [
                            30.94257812500001,
                            1.6828124999999972
                        ],
                        [
                            29.94287109375,
                            0.8192382812499943
                        ],
                        [
                            29.934472656250023,
                            0.4990234375
                        ],
                        [
                            29.717675781250023,
                            0.09833984374999716
                        ],
                        [
                            29.561914062500023,
                            -0.9773437500000028
                        ],
                        [
                            29.576953125000017,
                            -1.3878906250000114
                        ],
                        [
                            29.12939453125,
                            -1.8602539062500085
                        ],
                        [
                            29.1064453125,
                            -2.233203125000003
                        ],
                        [
                            28.876367187500023,
                            -2.4002929687500085
                        ],
                        [
                            29.01435546875001,
                            -2.72021484375
                        ],
                        [
                            29.224414062500017,
                            -3.053515625000003
                        ],
                        [
                            29.223242187500006,
                            -3.9108398437500114
                        ],
                        [
                            29.403222656250023,
                            -4.449316406250006
                        ],
                        [
                            29.404199218750023,
                            -4.496679687500006
                        ],
                        [
                            29.32568359375,
                            -4.835644531250011
                        ],
                        [
                            29.3427734375,
                            -4.983105468750011
                        ],
                        [
                            29.42011718750001,
                            -5.1761718750000085
                        ],
                        [
                            29.476464843750023,
                            -5.316601562500011
                        ],
                        [
                            29.594140625000023,
                            -5.6507812500000085
                        ],
                        [
                            29.59638671875001,
                            -5.775976562500006
                        ],
                        [
                            29.490820312500006,
                            -5.965429687500006
                        ],
                        [
                            29.480078125000006,
                            -6.025000000000006
                        ],
                        [
                            29.506250000000023,
                            -6.172070312500011
                        ],
                        [
                            29.540820312500017,
                            -6.3138671875000085
                        ],
                        [
                            29.590625000000017,
                            -6.3944335937500085
                        ],
                        [
                            29.70966796875001,
                            -6.616894531250011
                        ],
                        [
                            29.798144531250017,
                            -6.69189453125
                        ],
                        [
                            29.961816406250023,
                            -6.8031250000000085
                        ],
                        [
                            30.106250000000017,
                            -6.9150390625
                        ],
                        [
                            30.212695312500017,
                            -7.037890625000003
                        ],
                        [
                            30.31318359375001,
                            -7.203710937500006
                        ],
                        [
                            30.40673828125,
                            -7.460644531250011
                        ],
                        [
                            30.75117187500001,
                            -8.193652343750003
                        ],
                        [
                            28.972265625000006,
                            -8.464941406250006
                        ],
                        [
                            28.917773437500017,
                            -8.700585937500009
                        ],
                        [
                            28.400683593750017,
                            -9.224804687500011
                        ],
                        [
                            28.63007812500001,
                            -9.831250000000011
                        ],
                        [
                            28.63886718750001,
                            -10.669238281250003
                        ],
                        [
                            28.357226562500017,
                            -11.483007812500006
                        ],
                        [
                            28.482519531250006,
                            -11.812109375000006
                        ],
                        [
                            29.064355468750023,
                            -12.348828125000011
                        ],
                        [
                            29.427539062500017,
                            -12.431250000000006
                        ],
                        [
                            29.79511718750001,
                            -12.155468750000011
                        ],
                        [
                            29.795312500000023,
                            -13.392773437500011
                        ],
                        [
                            29.55419921875,
                            -13.248925781250009
                        ],
                        [
                            29.014257812500006,
                            -13.368847656250011
                        ],
                        [
                            28.357714843750017,
                            -12.482031250000006
                        ],
                        [
                            27.53339843750001,
                            -12.1953125
                        ],
                        [
                            27.04609375000001,
                            -11.615917968750011
                        ],
                        [
                            26.949609375000023,
                            -11.898828125000009
                        ],
                        [
                            26.59638671875001,
                            -11.972070312500009
                        ],
                        [
                            26.025976562500006,
                            -11.89013671875
                        ],
                        [
                            25.349414062500017,
                            -11.623046875
                        ],
                        [
                            25.184863281250017,
                            -11.242968750000003
                        ],
                        [
                            24.5185546875,
                            -11.4384765625
                        ],
                        [
                            24.319921875000006,
                            -11.07177734375
                        ],
                        [
                            23.96650390625001,
                            -10.871777343750011
                        ],
                        [
                            23.901171875000017,
                            -10.983203125000003
                        ],
                        [
                            23.833886718750023,
                            -11.013671875
                        ],
                        [
                            23.696386718750006,
                            -11.007617187500003
                        ],
                        [
                            23.559960937500023,
                            -10.978613281250006
                        ],
                        [
                            23.400195312500017,
                            -10.976464843750009
                        ],
                        [
                            22.226171875000006,
                            -11.121972656250009
                        ],
                        [
                            22.203515625000023,
                            -10.829492187500009
                        ],
                        [
                            22.307031250000023,
                            -10.691308593750009
                        ],
                        [
                            22.30244140625001,
                            -10.396679687500011
                        ],
                        [
                            22.274511718750006,
                            -10.259082031250003
                        ],
                        [
                            22.19775390625,
                            -10.040625000000006
                        ],
                        [
                            21.81318359375001,
                            -9.46875
                        ],
                        [
                            21.905371093750006,
                            -8.693359375
                        ],
                        [
                            21.751074218750006,
                            -7.305468750000003
                        ],
                        [
                            21.190332031250023,
                            -7.284960937500003
                        ],
                        [
                            20.910937500000017,
                            -7.281445312500011
                        ],
                        [
                            20.607812500000023,
                            -7.2777343750000085
                        ],
                        [
                            20.558398437500017,
                            -7.244433593750003
                        ],
                        [
                            20.536914062500017,
                            -7.121777343750011
                        ],
                        [
                            20.598730468750006,
                            -6.935156250000006
                        ],
                        [
                            20.59003906250001,
                            -6.919921875
                        ],
                        [
                            20.482226562500017,
                            -6.915820312500003
                        ],
                        [
                            20.190039062500006,
                            -6.9462890625
                        ],
                        [
                            19.997460937500023,
                            -6.9764648437500085
                        ],
                        [
                            19.87519531250001,
                            -6.986328125
                        ],
                        [
                            19.527636718750017,
                            -7.1444335937500085
                        ],
                        [
                            19.3408203125,
                            -7.966601562500003
                        ],
                        [
                            19.142675781250006,
                            -8.00146484375
                        ],
                        [
                            18.944433593750006,
                            -8.00146484375
                        ],
                        [
                            18.898339843750023,
                            -7.998144531250006
                        ],
                        [
                            18.56269531250001,
                            -7.9359375000000085
                        ],
                        [
                            18.0087890625,
                            -8.107617187500011
                        ],
                        [
                            17.643359375000017,
                            -8.090722656250009
                        ],
                        [
                            17.57958984375,
                            -8.099023437500009
                        ],
                        [
                            16.984765625000023,
                            -7.257421875000006
                        ],
                        [
                            16.91943359375,
                            -6.9339843750000085
                        ],
                        [
                            16.813085937500006,
                            -6.772558593750006
                        ],
                        [
                            16.742968750000017,
                            -6.618457031250003
                        ],
                        [
                            16.709375000000023,
                            -6.4716796875
                        ],
                        [
                            16.7177734375,
                            -6.241406250000011
                        ],
                        [
                            16.697265625,
                            -6.164257812500011
                        ],
                        [
                            16.537109375,
                            -5.9658203125
                        ],
                        [
                            16.431445312500017,
                            -5.900195312500003
                        ],
                        [
                            16.315234375000017,
                            -5.8656250000000085
                        ],
                        [
                            15.726953125000023,
                            -5.863867187500006
                        ],
                        [
                            14.398632812500011,
                            -5.892675781250006
                        ],
                        [
                            13.978515625,
                            -5.857226562500003
                        ],
                        [
                            13.346484375000017,
                            -5.863378906250006
                        ],
                        [
                            13.003320312500023,
                            -5.836132812500011
                        ],
                        [
                            12.411718750000006,
                            -5.986328125
                        ],
                        [
                            12.503710937500017,
                            -5.69580078125
                        ],
                        [
                            12.518945312500023,
                            -5.424609375000003
                        ],
                        [
                            12.522363281250023,
                            -5.14892578125
                        ],
                        [
                            12.453222656250006,
                            -5.090625000000003
                        ],
                        [
                            12.451464843750017,
                            -5.071484375000011
                        ],
                        [
                            12.502734375000017,
                            -5.036914062500003
                        ],
                        [
                            12.573535156250017,
                            -4.99658203125
                        ],
                        [
                            12.829687500000006,
                            -4.736621093750003
                        ],
                        [
                            13.07275390625,
                            -4.634765625
                        ],
                        [
                            13.414941406250023,
                            -4.83740234375
                        ],
                        [
                            14.358300781250023,
                            -4.299414062500006
                        ],
                        [
                            14.410742187500006,
                            -4.831250000000011
                        ],
                        [
                            14.707910156250023,
                            -4.8817382812500085
                        ],
                        [
                            15.60009765625,
                            -4.030957031250011
                        ],
                        [
                            15.872460937500023,
                            -3.9342773437500114
                        ],
                        [
                            16.146777343750017,
                            -3.464160156250003
                        ],
                        [
                            16.217382812500006,
                            -3.0302734375
                        ],
                        [
                            16.21533203125,
                            -2.1778320312500057
                        ],
                        [
                            16.54072265625001,
                            -1.8401367187500028
                        ],
                        [
                            16.8798828125,
                            -1.2258789062500028
                        ],
                        [
                            17.27880859375,
                            -0.9996093750000057
                        ],
                        [
                            17.752832031250023,
                            -0.5490234375000114
                        ],
                        [
                            17.72412109375,
                            -0.27753906250001137
                        ],
                        [
                            17.8876953125,
                            0.234130859375
                        ],
                        [
                            17.902441406250006,
                            1.1180664062499943
                        ],
                        [
                            18.05781250000001,
                            1.5348632812499972
                        ],
                        [
                            18.072167968750023,
                            2.0132812499999915
                        ],
                        [
                            18.49091796875001,
                            2.9244140624999915
                        ],
                        [
                            18.6103515625,
                            3.4784179687499943
                        ],
                        [
                            18.567480468750006,
                            4.257568359375
                        ],
                        [
                            19.06855468750001,
                            4.891406249999989
                        ],
                        [
                            19.5009765625,
                            5.127490234374989
                        ],
                        [
                            19.806542968750023,
                            5.089306640624997
                        ],
                        [
                            20.226367187500017,
                            4.829638671874989
                        ],
                        [
                            20.55810546875,
                            4.462695312499989
                        ],
                        [
                            21.53759765625,
                            4.244824218749997
                        ],
                        [
                            22.422167968750017,
                            4.134960937499997
                        ],
                        [
                            22.755761718750023,
                            4.646679687499997
                        ],
                        [
                            23.41718750000001,
                            4.663134765624989
                        ],
                        [
                            24.31982421875,
                            4.994140625
                        ],
                        [
                            24.765527343750023,
                            4.930078124999994
                        ],
                        [
                            25.249316406250017,
                            5.024560546874994
                        ],
                        [
                            25.52509765625001,
                            5.3121093749999915
                        ],
                        [
                            26.822070312500017,
                            5.062402343749994
                        ],
                        [
                            27.11494140625001,
                            5.1978515624999915
                        ],
                        [
                            27.4033203125,
                            5.109179687499989
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Democratic Republic of the Congo',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            18.6103515625,
                            3.4784179687499943
                        ],
                        [
                            18.49091796875001,
                            2.9244140624999915
                        ],
                        [
                            18.072167968750023,
                            2.0132812499999915
                        ],
                        [
                            18.05781250000001,
                            1.5348632812499972
                        ],
                        [
                            17.902441406250006,
                            1.1180664062499943
                        ],
                        [
                            17.8876953125,
                            0.234130859375
                        ],
                        [
                            17.72412109375,
                            -0.27753906250001137
                        ],
                        [
                            17.752832031250023,
                            -0.5490234375000114
                        ],
                        [
                            17.27880859375,
                            -0.9996093750000057
                        ],
                        [
                            16.8798828125,
                            -1.2258789062500028
                        ],
                        [
                            16.54072265625001,
                            -1.8401367187500028
                        ],
                        [
                            16.21533203125,
                            -2.1778320312500057
                        ],
                        [
                            16.217382812500006,
                            -3.0302734375
                        ],
                        [
                            16.146777343750017,
                            -3.464160156250003
                        ],
                        [
                            15.872460937500023,
                            -3.9342773437500114
                        ],
                        [
                            15.60009765625,
                            -4.030957031250011
                        ],
                        [
                            14.707910156250023,
                            -4.8817382812500085
                        ],
                        [
                            14.410742187500006,
                            -4.831250000000011
                        ],
                        [
                            14.358300781250023,
                            -4.299414062500006
                        ],
                        [
                            13.414941406250023,
                            -4.83740234375
                        ],
                        [
                            13.07275390625,
                            -4.634765625
                        ],
                        [
                            12.881054687500011,
                            -4.445117187500003
                        ],
                        [
                            12.384570312500017,
                            -4.619140625
                        ],
                        [
                            12.018359375000017,
                            -5.0042968750000085
                        ],
                        [
                            11.777539062500011,
                            -4.5658203125000085
                        ],
                        [
                            11.130175781250017,
                            -3.916308593750003
                        ],
                        [
                            11.504296875000023,
                            -3.520312500000003
                        ],
                        [
                            11.685742187500011,
                            -3.6820312500000085
                        ],
                        [
                            11.934179687500006,
                            -3.3185546875000114
                        ],
                        [
                            11.537792968750011,
                            -2.836718750000003
                        ],
                        [
                            11.5751953125,
                            -2.3970703125000057
                        ],
                        [
                            12.446386718750006,
                            -2.3299804687500085
                        ],
                        [
                            12.43212890625,
                            -1.9289062500000114
                        ],
                        [
                            12.793554687500006,
                            -1.9318359375000114
                        ],
                        [
                            12.991992187500017,
                            -2.3133789062500085
                        ],
                        [
                            13.464941406250006,
                            -2.3954101562500085
                        ],
                        [
                            13.784375000000011,
                            -2.1637695312500114
                        ],
                        [
                            14.08740234375,
                            -2.4668945312500057
                        ],
                        [
                            14.383984375000011,
                            -1.8900390625000085
                        ],
                        [
                            14.480566406250006,
                            -0.6183593750000114
                        ],
                        [
                            13.860058593750011,
                            -0.20332031250001137
                        ],
                        [
                            13.884570312500017,
                            0.19082031249999432
                        ],
                        [
                            14.087500000000006,
                            0.5365722656249972
                        ],
                        [
                            14.434472656250023,
                            0.8114746093749972
                        ],
                        [
                            14.180859375000011,
                            1.3702148437499915
                        ],
                        [
                            13.851367187500017,
                            1.4187499999999886
                        ],
                        [
                            13.274121093750011,
                            1.2410156249999886
                        ],
                        [
                            13.162695312500006,
                            1.6480957031249943
                        ],
                        [
                            13.293554687500006,
                            2.161572265624997
                        ],
                        [
                            14.484082031250011,
                            2.154736328124997
                        ],
                        [
                            14.902441406250006,
                            2.0123046874999915
                        ],
                        [
                            15.741601562500023,
                            1.9149902343749972
                        ],
                        [
                            16.059375000000017,
                            1.6762207031249972
                        ],
                        [
                            16.183398437500017,
                            2.2700683593749886
                        ],
                        [
                            16.468554687500017,
                            2.831738281249997
                        ],
                        [
                            16.67333984375,
                            3.5352050781249886
                        ],
                        [
                            17.43798828125,
                            3.6846191406249886
                        ],
                        [
                            18.160937500000017,
                            3.4998046874999886
                        ],
                        [
                            18.6103515625,
                            3.4784179687499943
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Republic of the Congo',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -71.31972656249997,
                            11.861914062500048
                        ],
                        [
                            -71.95810546875,
                            11.666406250000009
                        ],
                        [
                            -72.24848632812501,
                            11.196435546875009
                        ],
                        [
                            -72.690087890625,
                            10.835839843749994
                        ],
                        [
                            -72.86933593750001,
                            10.49125976562496
                        ],
                        [
                            -73.00654296874998,
                            9.789160156250006
                        ],
                        [
                            -73.35634765625002,
                            9.226855468750031
                        ],
                        [
                            -72.79638671874997,
                            9.108984374999991
                        ],
                        [
                            -72.66542968749994,
                            8.62758789062498
                        ],
                        [
                            -72.41655273437496,
                            8.381982421875037
                        ],
                        [
                            -72.44296875,
                            7.454882812499989
                        ],
                        [
                            -72.08427734374993,
                            7.096874999999969
                        ],
                        [
                            -71.01328124999998,
                            6.994433593749974
                        ],
                        [
                            -70.65507812500002,
                            7.082763671875028
                        ],
                        [
                            -70.09501953124996,
                            6.937939453125011
                        ],
                        [
                            -69.43925781249993,
                            6.1349121093750085
                        ],
                        [
                            -68.47177734375,
                            6.156542968749974
                        ],
                        [
                            -67.85917968749999,
                            6.289892578124963
                        ],
                        [
                            -67.43935546875,
                            6.025537109375023
                        ],
                        [
                            -67.82490234374995,
                            5.270458984375026
                        ],
                        [
                            -67.85527343750002,
                            4.506884765624989
                        ],
                        [
                            -67.66162109375,
                            3.864257812499986
                        ],
                        [
                            -67.3111328125,
                            3.41586914062502
                        ],
                        [
                            -67.83476562499999,
                            2.8928222656250426
                        ],
                        [
                            -67.21083984375,
                            2.3901367187500426
                        ],
                        [
                            -66.87602539062499,
                            1.223046875000037
                        ],
                        [
                            -67.35195312499997,
                            2.085839843750051
                        ],
                        [
                            -67.815087890625,
                            1.790087890625017
                        ],
                        [
                            -68.17656249999999,
                            1.7198242187499915
                        ],
                        [
                            -69.84858398437493,
                            1.7087402343750426
                        ],
                        [
                            -69.85214843750003,
                            1.0595214843750398
                        ],
                        [
                            -69.36137695312496,
                            1.0640136718749744
                        ],
                        [
                            -69.16323242187502,
                            0.8640625000000028
                        ],
                        [
                            -70.05390624999993,
                            0.5786132812500284
                        ],
                        [
                            -70.07050781249993,
                            -0.1388671875000398
                        ],
                        [
                            -69.66748046874997,
                            -0.48242187499995737
                        ],
                        [
                            -69.40024414062498,
                            -1.1949218749999773
                        ],
                        [
                            -69.66904296875003,
                            -2.667675781249997
                        ],
                        [
                            -69.96591796875003,
                            -4.235937500000006
                        ],
                        [
                            -70.16752929687499,
                            -4.0501953125000085
                        ],
                        [
                            -70.24028320312496,
                            -3.8827148437499943
                        ],
                        [
                            -70.2984375,
                            -3.8442382812499716
                        ],
                        [
                            -70.33950195312502,
                            -3.8143554687500085
                        ],
                        [
                            -70.73510742187497,
                            -3.7815429687499886
                        ],
                        [
                            -70.07402343749993,
                            -2.7501953125000114
                        ],
                        [
                            -70.09584960937494,
                            -2.658203125000014
                        ],
                        [
                            -70.91455078125003,
                            -2.2185546874999744
                        ],
                        [
                            -71.39697265625,
                            -2.3340820312499773
                        ],
                        [
                            -71.55947265624997,
                            -2.2242187499999773
                        ],
                        [
                            -71.75253906249995,
                            -2.15273437499998
                        ],
                        [
                            -71.80273437499997,
                            -2.1663085937499886
                        ],
                        [
                            -71.86728515624998,
                            -2.227734374999983
                        ],
                        [
                            -71.932470703125,
                            -2.288671874999963
                        ],
                        [
                            -71.98427734375,
                            -2.3265624999999517
                        ],
                        [
                            -72.13681640624998,
                            -2.38066406249996
                        ],
                        [
                            -72.21845703125001,
                            -2.4004882812500057
                        ],
                        [
                            -72.30073242187498,
                            -2.40927734375002
                        ],
                        [
                            -72.39560546874995,
                            -2.4289062500000256
                        ],
                        [
                            -72.62534179687495,
                            -2.3516601562500057
                        ],
                        [
                            -72.88715820312501,
                            -2.408496093749946
                        ],
                        [
                            -73.15449218749993,
                            -2.278222656249966
                        ],
                        [
                            -73.19697265624995,
                            -1.8302734375000114
                        ],
                        [
                            -73.49628906249993,
                            -1.6930664062500398
                        ],
                        [
                            -73.66430664062497,
                            -1.248828124999946
                        ],
                        [
                            -73.86318359374997,
                            -1.19667968749998
                        ],
                        [
                            -73.92695312500001,
                            -1.125195312499983
                        ],
                        [
                            -74.05439453124995,
                            -1.0286132812500313
                        ],
                        [
                            -74.18076171875,
                            -0.9977539062499545
                        ],
                        [
                            -74.24638671874999,
                            -0.9706054687500227
                        ],
                        [
                            -74.28388671874998,
                            -0.9278320312500057
                        ],
                        [
                            -74.33442382812498,
                            -0.8508789062499602
                        ],
                        [
                            -74.41787109375,
                            -0.5806640624999773
                        ],
                        [
                            -74.80175781249997,
                            -0.20009765624999432
                        ],
                        [
                            -75.13837890624998,
                            -0.050488281249968736
                        ],
                        [
                            -75.18408203124997,
                            -0.04174804687497158
                        ],
                        [
                            -75.22460937499997,
                            -0.04174804687497158
                        ],
                        [
                            -75.28447265624999,
                            -0.1065429687500199
                        ],
                        [
                            -75.46396484374995,
                            -0.038427734375005684
                        ],
                        [
                            -75.61733398437494,
                            0.06289062500003695
                        ],
                        [
                            -75.87978515624994,
                            0.1509765625000341
                        ],
                        [
                            -76.31103515624997,
                            0.4484863281249858
                        ],
                        [
                            -76.49462890624997,
                            0.2354492187500199
                        ],
                        [
                            -77.42275390624997,
                            0.4248535156249602
                        ],
                        [
                            -77.46767578124997,
                            0.636523437500017
                        ],
                        [
                            -78.31210937499995,
                            1.046093750000054
                        ],
                        [
                            -78.85966796874996,
                            1.4553710937500313
                        ],
                        [
                            -78.576904296875,
                            1.7737792968749773
                        ],
                        [
                            -78.617041015625,
                            2.3067871093750227
                        ],
                        [
                            -78.12001953125,
                            2.488183593750037
                        ],
                        [
                            -77.67001953124998,
                            2.87885742187504
                        ],
                        [
                            -77.24277343749998,
                            3.5853515625000085
                        ],
                        [
                            -77.1666015625,
                            3.8622558593750114
                        ],
                        [
                            -77.42729492187499,
                            4.06044921874998
                        ],
                        [
                            -77.28632812499995,
                            4.72172851562496
                        ],
                        [
                            -77.40175781249994,
                            5.416162109375037
                        ],
                        [
                            -77.24926757812497,
                            5.780175781250037
                        ],
                        [
                            -77.46943359374995,
                            6.176757812500014
                        ],
                        [
                            -77.35986328125003,
                            6.5044921874999915
                        ],
                        [
                            -77.90117187499999,
                            7.229345703125048
                        ],
                        [
                            -77.74389648437503,
                            7.536962890625006
                        ],
                        [
                            -77.35078124999995,
                            7.7058593750000455
                        ],
                        [
                            -77.2123046875,
                            8.033886718749997
                        ],
                        [
                            -77.37421874999993,
                            8.65830078125002
                        ],
                        [
                            -76.85185546875002,
                            8.09047851562498
                        ],
                        [
                            -76.88798828124999,
                            8.619873046875
                        ],
                        [
                            -76.27685546875,
                            8.989111328124991
                        ],
                        [
                            -76.135498046875,
                            9.265625000000014
                        ],
                        [
                            -75.63935546874998,
                            9.450439453125014
                        ],
                        [
                            -75.67089843750003,
                            10.196337890625003
                        ],
                        [
                            -75.44599609374995,
                            10.610888671874989
                        ],
                        [
                            -74.84458007812498,
                            11.109716796875006
                        ],
                        [
                            -74.45424804687497,
                            10.989062500000031
                        ],
                        [
                            -74.05913085937502,
                            11.340624999999974
                        ],
                        [
                            -73.31337890624997,
                            11.295751953124991
                        ],
                        [
                            -72.72182617187497,
                            11.712158203125
                        ],
                        [
                            -72.27499999999998,
                            11.88925781250002
                        ],
                        [
                            -72.13574218749994,
                            12.188574218749977
                        ],
                        [
                            -71.71455078124993,
                            12.41997070312496
                        ],
                        [
                            -71.26210937499997,
                            12.335302734375034
                        ],
                        [
                            -71.31972656249997,
                            11.861914062500048
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Colombia',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -83.6419921875,
                            10.917236328125
                        ],
                        [
                            -83.4482421875,
                            10.465917968749991
                        ],
                        [
                            -82.77841796874999,
                            9.669531249999991
                        ],
                        [
                            -82.93984375,
                            9.449169921874997
                        ],
                        [
                            -82.94033203125,
                            9.060107421874989
                        ],
                        [
                            -82.741162109375,
                            8.951708984374989
                        ],
                        [
                            -82.72783203124999,
                            8.916064453124989
                        ],
                        [
                            -82.739990234375,
                            8.898583984374994
                        ],
                        [
                            -82.91704101562499,
                            8.740332031249991
                        ],
                        [
                            -82.855712890625,
                            8.635302734374989
                        ],
                        [
                            -82.86162109374999,
                            8.453515624999994
                        ],
                        [
                            -83.02734375,
                            8.337744140624991
                        ],
                        [
                            -83.16240234374999,
                            8.588183593749989
                        ],
                        [
                            -83.73408203125,
                            8.614453124999997
                        ],
                        [
                            -83.63725585937499,
                            9.035351562499997
                        ],
                        [
                            -83.89555664062499,
                            9.276416015624989
                        ],
                        [
                            -84.65888671875,
                            9.646679687499997
                        ],
                        [
                            -85.114501953125,
                            9.581787109375
                        ],
                        [
                            -85.62485351562499,
                            9.902441406249991
                        ],
                        [
                            -85.83061523437499,
                            10.398144531249997
                        ],
                        [
                            -85.667236328125,
                            10.745019531249994
                        ],
                        [
                            -85.7443359375,
                            11.062109374999991
                        ],
                        [
                            -85.62138671874999,
                            11.184472656249994
                        ],
                        [
                            -84.9091796875,
                            10.9453125
                        ],
                        [
                            -84.348291015625,
                            10.979882812499994
                        ],
                        [
                            -84.168359375,
                            10.780371093749991
                        ],
                        [
                            -83.6419921875,
                            10.917236328125
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Costa Rica',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                -82.56176757812503,
                                21.571679687500023
                            ],
                            [
                                -82.85317382812497,
                                21.443896484375017
                            ],
                            [
                                -83.08251953124997,
                                21.791406250000023
                            ],
                            [
                                -82.71455078124998,
                                21.890283203125023
                            ],
                            [
                                -82.56176757812503,
                                21.571679687500023
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -81.83745117187499,
                                23.163037109374955
                            ],
                            [
                                -81.17861328124997,
                                23.059667968750034
                            ],
                            [
                                -80.65014648437494,
                                23.10307617187499
                            ],
                            [
                                -79.67666015624997,
                                22.743066406250023
                            ],
                            [
                                -79.27568359374999,
                                22.407617187499994
                            ],
                            [
                                -78.68647460937493,
                                22.366845703125023
                            ],
                            [
                                -77.86503906250002,
                                21.900585937499983
                            ],
                            [
                                -77.49726562499995,
                                21.871630859375045
                            ],
                            [
                                -76.64741210937501,
                                21.284521484375063
                            ],
                            [
                                -75.72294921874996,
                                21.111035156249983
                            ],
                            [
                                -75.64277343749998,
                                20.733496093749977
                            ],
                            [
                                -74.882568359375,
                                20.65063476562497
                            ],
                            [
                                -74.25283203124997,
                                20.079687500000006
                            ],
                            [
                                -75.11640624999995,
                                19.901416015625017
                            ],
                            [
                                -76.15844726562497,
                                19.98974609374997
                            ],
                            [
                                -77.715087890625,
                                19.85546874999997
                            ],
                            [
                                -77.21337890625003,
                                20.300390625000034
                            ],
                            [
                                -77.22958984374995,
                                20.643749999999983
                            ],
                            [
                                -77.99731445312494,
                                20.715380859375017
                            ],
                            [
                                -78.49077148437493,
                                21.05371093750003
                            ],
                            [
                                -78.63647460937497,
                                21.51552734375005
                            ],
                            [
                                -79.27441406249997,
                                21.562646484374966
                            ],
                            [
                                -80.23134765625,
                                21.872167968750063
                            ],
                            [
                                -80.49907226562499,
                                22.063525390625045
                            ],
                            [
                                -80.96191406249997,
                                22.052880859375023
                            ],
                            [
                                -81.81621093750002,
                                22.2001953125
                            ],
                            [
                                -81.90341796875,
                                22.679003906250017
                            ],
                            [
                                -82.73803710937497,
                                22.689257812500074
                            ],
                            [
                                -83.37963867187503,
                                22.222998046875034
                            ],
                            [
                                -84.32636718749998,
                                22.074316406250034
                            ],
                            [
                                -84.36127929687498,
                                22.37890625
                            ],
                            [
                                -84.04492187500003,
                                22.666015625000057
                            ],
                            [
                                -83.25781249999997,
                                22.967578125000017
                            ],
                            [
                                -82.10136718750002,
                                23.19042968750003
                            ],
                            [
                                -81.83745117187499,
                                23.163037109374955
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Cuba',
                'childNum': 2
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            34.004492187500006,
                            35.065234374999996
                        ],
                        [
                            32.71269531250002,
                            35.171044921874994
                        ],
                        [
                            32.94160156250001,
                            35.390429687499996
                        ],
                        [
                            33.941992187500006,
                            35.292041015624996
                        ],
                        [
                            34.004492187500006,
                            35.065234374999996
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Northern Cyprus',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            32.71269531250002,
                            35.171044921874994
                        ],
                        [
                            34.004492187500006,
                            35.065234374999996
                        ],
                        [
                            32.94179687500002,
                            34.57587890625
                        ],
                        [
                            32.44902343750002,
                            34.729443359375
                        ],
                        [
                            32.31718750000002,
                            34.9533203125
                        ],
                        [
                            32.71269531250002,
                            35.171044921874994
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Cyprus',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            14.809375000000017,
                            50.858984375
                        ],
                        [
                            16.007226562500023,
                            50.611621093749996
                        ],
                        [
                            16.679101562500023,
                            50.097460937499996
                        ],
                        [
                            17.151953125000006,
                            50.378320312499994
                        ],
                        [
                            17.874804687500017,
                            49.972265625
                        ],
                        [
                            18.562402343750023,
                            49.879345703125
                        ],
                        [
                            18.83222656250001,
                            49.510791015624996
                        ],
                        [
                            18.160937500000017,
                            49.257373046874996
                        ],
                        [
                            17.75849609375001,
                            48.888134765625
                        ],
                        [
                            17.1884765625,
                            48.8609375
                        ],
                        [
                            16.953125,
                            48.598828125
                        ],
                        [
                            16.477929687500023,
                            48.800097656249996
                        ],
                        [
                            16.057226562500006,
                            48.754785156249994
                        ],
                        [
                            14.97216796875,
                            48.983935546874996
                        ],
                        [
                            14.691308593750023,
                            48.59921875
                        ],
                        [
                            14.049121093750017,
                            48.602490234375
                        ],
                        [
                            13.814746093750017,
                            48.766943359375
                        ],
                        [
                            13.769921875000023,
                            48.815966796874996
                        ],
                        [
                            13.547656250000017,
                            48.95966796875
                        ],
                        [
                            13.140527343750023,
                            49.158349609374994
                        ],
                        [
                            12.632031250000011,
                            49.461230468749996
                        ],
                        [
                            12.512500000000017,
                            49.87744140625
                        ],
                        [
                            12.3056640625,
                            50.205712890624994
                        ],
                        [
                            13.016406250000017,
                            50.490380859374994
                        ],
                        [
                            13.18115234375,
                            50.510498046875
                        ],
                        [
                            14.369042968750023,
                            50.898730468749996
                        ],
                        [
                            14.809375000000017,
                            50.858984375
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Czech Republic',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            9.739746093750028,
                            54.82553710937498
                        ],
                        [
                            10.360449218750006,
                            54.43833007812506
                        ],
                        [
                            11.39960937500004,
                            53.94462890625002
                        ],
                        [
                            12.111328125,
                            54.168310546875006
                        ],
                        [
                            12.57539062500004,
                            54.467382812500006
                        ],
                        [
                            13.028613281250017,
                            54.411035156249994
                        ],
                        [
                            13.865527343750074,
                            53.85336914062498
                        ],
                        [
                            14.250000000000057,
                            53.73188476562501
                        ],
                        [
                            14.298730468750051,
                            53.55644531249999
                        ],
                        [
                            14.41455078125,
                            53.28349609374996
                        ],
                        [
                            14.412304687500011,
                            53.216748046874955
                        ],
                        [
                            14.410937500000074,
                            53.19902343749999
                        ],
                        [
                            14.368554687500051,
                            53.105566406250034
                        ],
                        [
                            14.293164062500068,
                            53.026757812499966
                        ],
                        [
                            14.193652343750045,
                            52.982324218749994
                        ],
                        [
                            14.138867187500068,
                            52.93286132812503
                        ],
                        [
                            14.128613281250011,
                            52.87822265625002
                        ],
                        [
                            14.253710937500017,
                            52.78251953124996
                        ],
                        [
                            14.514062500000023,
                            52.645605468750034
                        ],
                        [
                            14.619433593750017,
                            52.52851562499998
                        ],
                        [
                            14.569726562499994,
                            52.431103515624955
                        ],
                        [
                            14.554589843750023,
                            52.35966796874996
                        ],
                        [
                            14.573925781250068,
                            52.31416015625001
                        ],
                        [
                            14.615625000000023,
                            52.277636718750045
                        ],
                        [
                            14.679882812500068,
                            52.25
                        ],
                        [
                            14.705371093750017,
                            52.207470703124955
                        ],
                        [
                            14.752539062500034,
                            52.08183593750002
                        ],
                        [
                            14.601660156250034,
                            51.832373046875006
                        ],
                        [
                            14.9638671875,
                            51.095117187499994
                        ],
                        [
                            13.18115234375,
                            50.510498046875
                        ],
                        [
                            13.016406250000017,
                            50.490380859374994
                        ],
                        [
                            12.3056640625,
                            50.205712890624994
                        ],
                        [
                            12.512500000000017,
                            49.87744140625
                        ],
                        [
                            12.63203125000004,
                            49.46123046875002
                        ],
                        [
                            13.14052734375008,
                            49.15834960937505
                        ],
                        [
                            13.547656250000074,
                            48.95966796874998
                        ],
                        [
                            13.769921875000051,
                            48.81596679687502
                        ],
                        [
                            13.814746093750017,
                            48.76694335937498
                        ],
                        [
                            13.802929687500011,
                            48.74750976562501
                        ],
                        [
                            13.785351562499983,
                            48.58745117187502
                        ],
                        [
                            13.486621093750074,
                            48.58183593750002
                        ],
                        [
                            13.471679687500028,
                            48.57182617187502
                        ],
                        [
                            13.459863281250023,
                            48.564550781250034
                        ],
                        [
                            13.40937500000004,
                            48.39414062500006
                        ],
                        [
                            13.374609375000063,
                            48.361376953125045
                        ],
                        [
                            13.322851562500006,
                            48.33125000000004
                        ],
                        [
                            13.215234375000023,
                            48.301904296874994
                        ],
                        [
                            12.685839843750074,
                            47.66933593750002
                        ],
                        [
                            11.716796875,
                            47.58349609375003
                        ],
                        [
                            11.297949218750034,
                            47.424902343750034
                        ],
                        [
                            10.873046874999972,
                            47.52021484375001
                        ],
                        [
                            10.65869140625,
                            47.547216796875006
                        ],
                        [
                            10.482812500000051,
                            47.54179687499996
                        ],
                        [
                            10.43037109375004,
                            47.54106445312499
                        ],
                        [
                            10.403906250000063,
                            47.41699218750003
                        ],
                        [
                            10.312792968750074,
                            47.31342773437498
                        ],
                        [
                            10.18300781250008,
                            47.27880859375003
                        ],
                        [
                            10.096484375000045,
                            47.379589843749955
                        ],
                        [
                            10.066308593750023,
                            47.39335937500002
                        ],
                        [
                            10.074218750000028,
                            47.42851562499999
                        ],
                        [
                            10.034082031250023,
                            47.47358398437501
                        ],
                        [
                            9.748925781250023,
                            47.575537109375006
                        ],
                        [
                            8.572656250000023,
                            47.775634765625
                        ],
                        [
                            8.414746093750011,
                            47.58959960937503
                        ],
                        [
                            7.615624999999994,
                            47.59272460937504
                        ],
                        [
                            7.584179687499983,
                            48.064306640625006
                        ],
                        [
                            8.134863281250006,
                            48.97358398437498
                        ],
                        [
                            7.450585937500051,
                            49.152197265625034
                        ],
                        [
                            6.735449218750006,
                            49.16059570312498
                        ],
                        [
                            6.344335937500006,
                            49.45273437499998
                        ],
                        [
                            6.4873046875,
                            49.798486328124994
                        ],
                        [
                            6.1165039062500455,
                            50.120996093749966
                        ],
                        [
                            6.364453125000011,
                            50.31616210937503
                        ],
                        [
                            5.993945312500017,
                            50.75043945312504
                        ],
                        [
                            5.894726562500068,
                            50.98422851562506
                        ],
                        [
                            6.193261718750051,
                            51.48891601562502
                        ],
                        [
                            5.948535156250017,
                            51.762402343749955
                        ],
                        [
                            6.7417968750000625,
                            51.91088867187503
                        ],
                        [
                            7.035156250000057,
                            52.38022460937498
                        ],
                        [
                            7.197265625000028,
                            53.28227539062499
                        ],
                        [
                            7.285253906250034,
                            53.68134765625001
                        ],
                        [
                            8.00927734375,
                            53.69072265624999
                        ],
                        [
                            8.53847656250008,
                            53.55688476562497
                        ],
                        [
                            8.897753906250074,
                            53.83569335937503
                        ],
                        [
                            8.95722656250004,
                            54.538330078125
                        ],
                        [
                            8.670312500000023,
                            54.903417968750034
                        ],
                        [
                            9.739746093750028,
                            54.82553710937498
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Germany',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            43.24599609375002,
                            11.499804687499989
                        ],
                        [
                            42.92275390625002,
                            10.999316406249989
                        ],
                        [
                            42.557714843750006,
                            11.080761718749997
                        ],
                        [
                            41.79824218750002,
                            10.98046875
                        ],
                        [
                            41.76650390625002,
                            11.589111328125
                        ],
                        [
                            42.378515625000006,
                            12.466406249999991
                        ],
                        [
                            42.67011718750001,
                            12.376562499999991
                        ],
                        [
                            42.703710937500006,
                            12.380322265624997
                        ],
                        [
                            42.76748046875002,
                            12.4228515625
                        ],
                        [
                            42.88330078125,
                            12.621289062499997
                        ],
                        [
                            43.00566406250002,
                            12.662304687499997
                        ],
                        [
                            43.11669921875,
                            12.708593749999991
                        ],
                        [
                            43.409765625000006,
                            12.18994140625
                        ],
                        [
                            43.272070312500006,
                            11.969531249999989
                        ],
                        [
                            42.79902343750001,
                            11.739404296874994
                        ],
                        [
                            43.24599609375002,
                            11.499804687499989
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Djibouti',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                10.645117187500006,
                                55.60981445312498
                            ],
                            [
                                10.785253906250034,
                                55.13339843749998
                            ],
                            [
                                9.988769531250028,
                                55.163183593750006
                            ],
                            [
                                9.860644531250045,
                                55.515478515625034
                            ],
                            [
                                10.645117187500006,
                                55.60981445312498
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                12.568750000000023,
                                55.785058593749966
                            ],
                            [
                                11.653808593750057,
                                55.186914062499966
                            ],
                            [
                                11.286328125000068,
                                55.20444335937498
                            ],
                            [
                                10.978906250000051,
                                55.721533203125006
                            ],
                            [
                                12.218945312499983,
                                56.11865234374997
                            ],
                            [
                                12.568750000000023,
                                55.785058593749966
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                9.739746093750028,
                                54.82553710937498
                            ],
                            [
                                8.670312500000023,
                                54.903417968750034
                            ],
                            [
                                8.61591796875004,
                                55.41821289062503
                            ],
                            [
                                8.132128906250074,
                                55.59980468749998
                            ],
                            [
                                8.16396484375008,
                                56.60688476562498
                            ],
                            [
                                8.618554687500051,
                                57.11127929687498
                            ],
                            [
                                9.554296874999977,
                                57.23247070312496
                            ],
                            [
                                9.96230468750008,
                                57.580957031249994
                            ],
                            [
                                10.537109375000028,
                                57.44853515625002
                            ],
                            [
                                10.282714843750057,
                                56.620507812499994
                            ],
                            [
                                10.18300781250008,
                                55.86518554687504
                            ],
                            [
                                9.591113281250017,
                                55.49321289062502
                            ],
                            [
                                9.739746093750028,
                                54.82553710937498
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Denmark',
                'childNum': 3
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -71.647216796875,
                            19.195947265624994
                        ],
                        [
                            -71.75742187499999,
                            19.688183593749997
                        ],
                        [
                            -71.557763671875,
                            19.89536132812499
                        ],
                        [
                            -70.95415039062499,
                            19.913964843749994
                        ],
                        [
                            -70.12944335937499,
                            19.636132812499994
                        ],
                        [
                            -69.739404296875,
                            19.299218749999994
                        ],
                        [
                            -68.684765625,
                            18.90478515625
                        ],
                        [
                            -68.35927734375,
                            18.5380859375
                        ],
                        [
                            -68.658837890625,
                            18.22202148437499
                        ],
                        [
                            -68.9349609375,
                            18.408007812500003
                        ],
                        [
                            -69.770654296875,
                            18.443554687499997
                        ],
                        [
                            -70.18310546875,
                            18.251757812500003
                        ],
                        [
                            -71.02783203125,
                            18.273193359375
                        ],
                        [
                            -71.395703125,
                            17.64609374999999
                        ],
                        [
                            -71.63173828125,
                            17.773632812499997
                        ],
                        [
                            -71.7619140625,
                            18.34130859375
                        ],
                        [
                            -71.647216796875,
                            19.195947265624994
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Dominican Republic',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            8.207617187500006,
                            36.518945312499994
                        ],
                        [
                            8.348730468750006,
                            36.367968749999996
                        ],
                        [
                            8.318066406250011,
                            35.654931640624994
                        ],
                        [
                            8.31640625,
                            35.403124999999996
                        ],
                        [
                            8.35986328125,
                            35.299609374999996
                        ],
                        [
                            8.394238281250011,
                            35.203857421875
                        ],
                        [
                            8.312109375000006,
                            35.084619140624994
                        ],
                        [
                            8.27685546875,
                            34.9794921875
                        ],
                        [
                            8.24560546875,
                            34.73408203125
                        ],
                        [
                            7.513867187500011,
                            34.080517578125
                        ],
                        [
                            7.49560546875,
                            33.976513671875
                        ],
                        [
                            7.500195312500011,
                            33.832470703125
                        ],
                        [
                            7.534375000000011,
                            33.717919921874994
                        ],
                        [
                            7.7626953125,
                            33.23310546875
                        ],
                        [
                            7.877246093750017,
                            33.172119140625
                        ],
                        [
                            8.2109375,
                            32.926708984375
                        ],
                        [
                            8.333398437500023,
                            32.543603515624994
                        ],
                        [
                            9.044042968750006,
                            32.07236328125
                        ],
                        [
                            9.160253906250006,
                            31.621337890625
                        ],
                        [
                            9.224023437500023,
                            31.373681640624994
                        ],
                        [
                            9.36328125,
                            30.832910156249994
                        ],
                        [
                            9.518750000000011,
                            30.229394531249994
                        ],
                        [
                            9.310253906250011,
                            30.115234375
                        ],
                        [
                            9.672656250000017,
                            29.5669921875
                        ],
                        [
                            9.842578125000017,
                            28.966992187499997
                        ],
                        [
                            9.916015625,
                            27.785693359374996
                        ],
                        [
                            9.74755859375,
                            27.330859374999996
                        ],
                        [
                            9.859375,
                            26.551953124999997
                        ],
                        [
                            9.491406250000011,
                            26.333740234375
                        ],
                        [
                            9.4482421875,
                            26.067138671875
                        ],
                        [
                            10.000683593750011,
                            25.332080078125003
                        ],
                        [
                            10.255859375,
                            24.591015624999997
                        ],
                        [
                            10.686132812500006,
                            24.55136718749999
                        ],
                        [
                            11.536914062500017,
                            24.290820312500003
                        ],
                        [
                            11.967871093750006,
                            23.517871093750003
                        ],
                        [
                            9.378710937500017,
                            21.991406249999997
                        ],
                        [
                            7.481738281250017,
                            20.873095703125003
                        ],
                        [
                            5.836621093750011,
                            19.479150390624994
                        ],
                        [
                            4.227636718750006,
                            19.142773437499997
                        ],
                        [
                            3.40087890625,
                            18.988427734374994
                        ],
                        [
                            3.1060546875000057,
                            19.150097656249997
                        ],
                        [
                            3.255859375,
                            19.410937500000003
                        ],
                        [
                            3.2037109375000057,
                            19.789697265624994
                        ],
                        [
                            2.4061523437500227,
                            20.063867187499994
                        ],
                        [
                            2.2193359375000057,
                            20.247802734375
                        ],
                        [
                            1.6854492187500227,
                            20.378369140624997
                        ],
                        [
                            1.1657226562500114,
                            20.817431640625003
                        ],
                        [
                            1.1455078125,
                            21.102246093749997
                        ],
                        [
                            -1.29296875,
                            22.696533203125
                        ],
                        [
                            -3.2578613281249886,
                            23.981250000000003
                        ],
                        [
                            -4.822607421874977,
                            24.99560546875
                        ],
                        [
                            -6.238671874999994,
                            25.848193359375003
                        ],
                        [
                            -8.683349609375,
                            27.285937499999996
                        ],
                        [
                            -8.683349609375,
                            27.656445312499997
                        ],
                        [
                            -8.683349609375,
                            27.900390625
                        ],
                        [
                            -8.659912109375,
                            28.718603515625
                        ],
                        [
                            -8.265185546874989,
                            28.980517578124996
                        ],
                        [
                            -7.998925781249994,
                            29.132421875
                        ],
                        [
                            -7.685156249999977,
                            29.349511718749994
                        ],
                        [
                            -7.485742187499994,
                            29.392236328124994
                        ],
                        [
                            -7.094921874999983,
                            29.625195312499997
                        ],
                        [
                            -6.855566406249977,
                            29.601611328124996
                        ],
                        [
                            -6.755126953125,
                            29.583837890625
                        ],
                        [
                            -6.635351562499977,
                            29.568798828124997
                        ],
                        [
                            -6.520556640624989,
                            29.659863281249997
                        ],
                        [
                            -6.510693359374983,
                            29.726025390624997
                        ],
                        [
                            -6.507910156249977,
                            29.7837890625
                        ],
                        [
                            -6.500878906249994,
                            29.809130859374996
                        ],
                        [
                            -5.774999999999977,
                            29.869042968749994
                        ],
                        [
                            -5.448779296874989,
                            29.956933593749994
                        ],
                        [
                            -5.293652343749983,
                            30.058642578124996
                        ],
                        [
                            -5.180126953124983,
                            30.166162109374994
                        ],
                        [
                            -4.96826171875,
                            30.465380859374996
                        ],
                        [
                            -4.778515624999983,
                            30.552392578124994
                        ],
                        [
                            -4.529150390624977,
                            30.625537109374996
                        ],
                        [
                            -4.322851562499977,
                            30.698876953124994
                        ],
                        [
                            -4.148779296874977,
                            30.8095703125
                        ],
                        [
                            -3.7020019531249773,
                            30.944482421874994
                        ],
                        [
                            -3.6667968749999886,
                            30.964013671874994
                        ],
                        [
                            -3.626904296874983,
                            31.000927734374997
                        ],
                        [
                            -3.6725097656249943,
                            31.111376953124996
                        ],
                        [
                            -3.8333984374999943,
                            31.197802734374996
                        ],
                        [
                            -3.837109374999983,
                            31.512353515624994
                        ],
                        [
                            -3.8267578124999773,
                            31.661914062499996
                        ],
                        [
                            -3.4397949218749773,
                            31.704541015624997
                        ],
                        [
                            -3.0173828124999886,
                            31.834277343749996
                        ],
                        [
                            -2.988232421874983,
                            31.874218749999997
                        ],
                        [
                            -2.930859374999983,
                            32.042529296874996
                        ],
                        [
                            -2.8634277343749943,
                            32.07470703125
                        ],
                        [
                            -2.5232421874999886,
                            32.12568359375
                        ],
                        [
                            -2.4483886718749943,
                            32.12998046875
                        ],
                        [
                            -2.0728027343749886,
                            32.115039062499996
                        ],
                        [
                            -1.8169921874999773,
                            32.104785156249996
                        ],
                        [
                            -1.275341796874983,
                            32.089013671874994
                        ],
                        [
                            -1.16259765625,
                            32.399169921875
                        ],
                        [
                            -1.510009765625,
                            32.87763671875
                        ],
                        [
                            -1.550732421874983,
                            33.073583984375
                        ],
                        [
                            -1.67919921875,
                            33.318652343749996
                        ],
                        [
                            -1.7069335937499943,
                            34.176074218749996
                        ],
                        [
                            -1.8496582031249886,
                            34.607324218749994
                        ],
                        [
                            -1.792187499999983,
                            34.723193359374996
                        ],
                        [
                            -1.795605468749983,
                            34.751904296875
                        ],
                        [
                            -1.9208984375,
                            34.835546875
                        ],
                        [
                            -2.131787109374983,
                            34.970849609374994
                        ],
                        [
                            -2.1907714843749773,
                            35.02978515625
                        ],
                        [
                            -2.2196289062499943,
                            35.10419921875
                        ],
                        [
                            -1.3358398437499943,
                            35.3642578125
                        ],
                        [
                            -0.42612304687497726,
                            35.8615234375
                        ],
                        [
                            0.047949218750005684,
                            35.900537109374994
                        ],
                        [
                            0.514941406250017,
                            36.26181640625
                        ],
                        [
                            1.2572265625000227,
                            36.519580078124996
                        ],
                        [
                            2.5933593750000057,
                            36.60068359375
                        ],
                        [
                            2.9728515625000114,
                            36.784472656249996
                        ],
                        [
                            3.7790039062500114,
                            36.89619140625
                        ],
                        [
                            4.758105468750017,
                            36.896337890625
                        ],
                        [
                            5.195605468750017,
                            36.676806640624996
                        ],
                        [
                            6.064746093750017,
                            36.8642578125
                        ],
                        [
                            6.486523437500011,
                            37.085742187499996
                        ],
                        [
                            6.927539062500017,
                            36.91943359375
                        ],
                        [
                            7.607714843750017,
                            36.999755859375
                        ],
                        [
                            7.910449218750017,
                            36.856347656249994
                        ],
                        [
                            8.127148437500011,
                            36.9103515625
                        ],
                        [
                            8.576562500000023,
                            36.93720703125
                        ],
                        [
                            8.207617187500006,
                            36.518945312499994
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Algeria',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                -91.27216796874998,
                                0.02514648437498579
                            ],
                            [
                                -90.799658203125,
                                -0.7520507812499915
                            ],
                            [
                                -90.90551757812497,
                                -0.9405273437500199
                            ],
                            [
                                -91.36918945312493,
                                -0.28720703124997726
                            ],
                            [
                                -91.27216796874998,
                                0.02514648437498579
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -75.28447265624999,
                                -0.1065429687500199
                            ],
                            [
                                -75.34047851562494,
                                -0.14218750000000568
                            ],
                            [
                                -75.47597656250002,
                                -0.15712890625002274
                            ],
                            [
                                -75.26323242187502,
                                -0.555371093749983
                            ],
                            [
                                -75.25937500000003,
                                -0.5901367187499602
                            ],
                            [
                                -75.27871093749997,
                                -0.6539062499999773
                            ],
                            [
                                -75.28359375,
                                -0.7071289062499915
                            ],
                            [
                                -75.24960937499998,
                                -0.9518554687500256
                            ],
                            [
                                -75.570556640625,
                                -1.5312500000000142
                            ],
                            [
                                -75.64165039062502,
                                -1.60732421874998
                            ],
                            [
                                -76.08979492187501,
                                -2.1331054687499744
                            ],
                            [
                                -76.6791015625,
                                -2.5625976562499915
                            ],
                            [
                                -77.860595703125,
                                -2.9816406250000114
                            ],
                            [
                                -78.240380859375,
                                -3.4725585937500085
                            ],
                            [
                                -78.64799804687499,
                                -4.248144531250006
                            ],
                            [
                                -78.68603515625003,
                                -4.562402343749994
                            ],
                            [
                                -78.86152343749998,
                                -4.665039062499943
                            ],
                            [
                                -78.97539062499999,
                                -4.873242187499997
                            ],
                            [
                                -78.99526367187497,
                                -4.908007812499974
                            ],
                            [
                                -79.03330078124998,
                                -4.969140624999994
                            ],
                            [
                                -79.07626953125003,
                                -4.990625000000023
                            ],
                            [
                                -79.18666992187497,
                                -4.958203124999983
                            ],
                            [
                                -79.26811523437493,
                                -4.957617187499949
                            ],
                            [
                                -79.33095703124997,
                                -4.92783203125002
                            ],
                            [
                                -79.39941406249997,
                                -4.840039062499983
                            ],
                            [
                                -79.45576171874998,
                                -4.766210937499949
                            ],
                            [
                                -79.50190429687495,
                                -4.670605468750011
                            ],
                            [
                                -79.51616210937493,
                                -4.539160156249963
                            ],
                            [
                                -79.57768554687496,
                                -4.50058593750002
                            ],
                            [
                                -79.638525390625,
                                -4.454882812500031
                            ],
                            [
                                -79.79726562500002,
                                -4.47636718749996
                            ],
                            [
                                -79.962890625,
                                -4.390332031250026
                            ],
                            [
                                -80.06352539062499,
                                -4.327539062500023
                            ],
                            [
                                -80.13955078125002,
                                -4.296093750000011
                            ],
                            [
                                -80.19746093750001,
                                -4.311035156249943
                            ],
                            [
                                -80.293359375,
                                -4.416796875000003
                            ],
                            [
                                -80.48847656249995,
                                -4.3936523437499915
                            ],
                            [
                                -80.35288085937495,
                                -4.208496093750014
                            ],
                            [
                                -80.453759765625,
                                -4.205175781249963
                            ],
                            [
                                -80.48847656249995,
                                -4.165527343749972
                            ],
                            [
                                -80.49345703124999,
                                -4.119140625000014
                            ],
                            [
                                -80.510009765625,
                                -4.06953125000004
                            ],
                            [
                                -80.49013671874994,
                                -4.010058593750003
                            ],
                            [
                                -80.43720703125001,
                                -3.9786132812499915
                            ],
                            [
                                -80.30327148437499,
                                -4.005078124999969
                            ],
                            [
                                -80.26689453124993,
                                -3.948828124999963
                            ],
                            [
                                -80.17924804687493,
                                -3.87773437499996
                            ],
                            [
                                -80.21757812500002,
                                -3.7876953124999915
                            ],
                            [
                                -80.22060546875002,
                                -3.613183593749966
                            ],
                            [
                                -80.24375000000003,
                                -3.5767578125000057
                            ],
                            [
                                -80.24541015625002,
                                -3.522167968749997
                            ],
                            [
                                -80.26523437499995,
                                -3.4924804687499886
                            ],
                            [
                                -80.27353515624995,
                                -3.424609375000017
                            ],
                            [
                                -80.30312499999994,
                                -3.3748046874999886
                            ],
                            [
                                -79.92158203124993,
                                -3.0901367187499886
                            ],
                            [
                                -79.72988281249997,
                                -2.5791015624999716
                            ],
                            [
                                -80.45009765624997,
                                -2.6259765625000284
                            ],
                            [
                                -80.96279296874997,
                                -2.18925781249996
                            ],
                            [
                                -80.770361328125,
                                -2.0766601562500284
                            ],
                            [
                                -80.80141601562494,
                                -1.3833984375000057
                            ],
                            [
                                -80.90239257812499,
                                -1.0789062499999744
                            ],
                            [
                                -80.55390624999998,
                                -0.8479492187499886
                            ],
                            [
                                -80.48227539062503,
                                -0.36826171874996305
                            ],
                            [
                                -80.1333984375,
                                -0.006054687499982947
                            ],
                            [
                                -80.02499999999995,
                                0.4101562499999858
                            ],
                            [
                                -80.08828124999997,
                                0.7847656250000199
                            ],
                            [
                                -79.74121093749997,
                                0.9797851562500455
                            ],
                            [
                                -78.89965820312503,
                                1.2062499999999972
                            ],
                            [
                                -78.85966796874996,
                                1.4553710937500313
                            ],
                            [
                                -78.31210937499995,
                                1.046093750000054
                            ],
                            [
                                -77.46767578124997,
                                0.636523437500017
                            ],
                            [
                                -77.42275390624997,
                                0.4248535156249602
                            ],
                            [
                                -76.49462890624997,
                                0.2354492187500199
                            ],
                            [
                                -76.31103515624997,
                                0.4484863281249858
                            ],
                            [
                                -75.87978515624994,
                                0.1509765625000341
                            ],
                            [
                                -75.61733398437494,
                                0.06289062500003695
                            ],
                            [
                                -75.46396484374995,
                                -0.038427734375005684
                            ],
                            [
                                -75.28447265624999,
                                -0.1065429687500199
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Ecuador',
                'childNum': 2
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            34.24531250000001,
                            31.208300781249996
                        ],
                        [
                            34.904296875,
                            29.477343749999996
                        ],
                        [
                            34.736425781250006,
                            29.27060546875
                        ],
                        [
                            34.39970703125002,
                            28.016015624999994
                        ],
                        [
                            34.22011718750002,
                            27.764306640624994
                        ],
                        [
                            33.76025390625,
                            28.047656249999996
                        ],
                        [
                            33.24775390625001,
                            28.567724609375
                        ],
                        [
                            33.075781250000006,
                            29.073046874999996
                        ],
                        [
                            32.72148437500002,
                            29.521777343749996
                        ],
                        [
                            32.6318359375,
                            28.992236328124996
                        ],
                        [
                            32.89824218750002,
                            28.565234374999996
                        ],
                        [
                            33.54707031250001,
                            27.898144531249997
                        ],
                        [
                            33.5498046875,
                            27.607373046874997
                        ],
                        [
                            33.89306640625,
                            27.049462890624994
                        ],
                        [
                            33.959082031250006,
                            26.6490234375
                        ],
                        [
                            34.56513671875001,
                            25.691162109375
                        ],
                        [
                            35.19414062500002,
                            24.475146484375003
                        ],
                        [
                            35.62470703125001,
                            24.06601562499999
                        ],
                        [
                            35.52275390625002,
                            23.442529296874994
                        ],
                        [
                            35.697851562500006,
                            22.946191406249994
                        ],
                        [
                            36.22968750000001,
                            22.628808593749994
                        ],
                        [
                            36.87138671875002,
                            21.996728515624994
                        ],
                        [
                            33.91845703125,
                            21.996240234374994
                        ],
                        [
                            31.434472656250023,
                            21.995849609375
                        ],
                        [
                            29.564550781250006,
                            21.9951171875
                        ],
                        [
                            28.036425781250017,
                            21.995361328125
                        ],
                        [
                            24.980273437500017,
                            21.995849609375
                        ],
                        [
                            24.980273437500017,
                            24.466064453125
                        ],
                        [
                            24.980273437500017,
                            26.487109374999996
                        ],
                        [
                            24.980273437500017,
                            29.181884765625
                        ],
                        [
                            24.726464843750023,
                            30.2505859375
                        ],
                        [
                            24.973925781250017,
                            30.776562499999997
                        ],
                        [
                            24.85273437500001,
                            31.334814453125
                        ],
                        [
                            25.150488281250006,
                            31.654980468749997
                        ],
                        [
                            25.382226562500023,
                            31.51279296875
                        ],
                        [
                            25.89326171875001,
                            31.620898437499996
                        ],
                        [
                            27.248046875,
                            31.377880859374997
                        ],
                        [
                            27.967578125000017,
                            31.097412109375
                        ],
                        [
                            28.51484375000001,
                            31.050439453124994
                        ],
                        [
                            29.072070312500017,
                            30.830273437499997
                        ],
                        [
                            29.591601562500017,
                            31.011523437499996
                        ],
                        [
                            30.395117187500006,
                            31.4576171875
                        ],
                        [
                            30.700488281250017,
                            31.403857421874996
                        ],
                        [
                            31.193945312500006,
                            31.587597656249997
                        ],
                        [
                            32.10175781250001,
                            31.092822265624996
                        ],
                        [
                            34.24531250000001,
                            31.208300781249996
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Egypt',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            40.938574218750006,
                            13.983105468749997
                        ],
                        [
                            40.82011718750002,
                            14.111669921874991
                        ],
                        [
                            40.22148437500002,
                            14.431152343749972
                        ],
                        [
                            39.531835937500006,
                            14.536718749999991
                        ],
                        [
                            38.43144531250002,
                            14.428613281249994
                        ],
                        [
                            38.221484375000074,
                            14.649658203124986
                        ],
                        [
                            38.002539062500006,
                            14.737109375000045
                        ],
                        [
                            37.94345703125006,
                            14.810546875
                        ],
                        [
                            37.884179687499994,
                            14.852294921874972
                        ],
                        [
                            37.82031250000003,
                            14.708496093749986
                        ],
                        [
                            37.70839843750005,
                            14.45722656250004
                        ],
                        [
                            37.64843750000003,
                            14.32255859375006
                        ],
                        [
                            37.571191406249994,
                            14.149072265624966
                        ],
                        [
                            37.546777343749994,
                            14.143847656249974
                        ],
                        [
                            37.507226562499994,
                            14.156396484375037
                        ],
                        [
                            37.257226562499994,
                            14.453759765625051
                        ],
                        [
                            37.024511718750006,
                            14.271972656250057
                        ],
                        [
                            36.81191406250005,
                            14.315039062500034
                        ],
                        [
                            36.67910156250005,
                            14.307568359375026
                        ],
                        [
                            36.542382812499994,
                            14.258203124999994
                        ],
                        [
                            36.52431640625005,
                            14.256835937499986
                        ],
                        [
                            36.492285156250006,
                            14.544335937500023
                        ],
                        [
                            36.470800781250006,
                            14.736474609375009
                        ],
                        [
                            36.448144531249994,
                            14.940087890625009
                        ],
                        [
                            36.42675781249997,
                            15.132080078125043
                        ],
                        [
                            36.566015625000006,
                            15.362109375000003
                        ],
                        [
                            36.9137695312501,
                            16.296191406250045
                        ],
                        [
                            36.887792968750006,
                            16.624658203124994
                        ],
                        [
                            36.9787109375001,
                            16.800585937500045
                        ],
                        [
                            36.9757812500001,
                            16.866552734375006
                        ],
                        [
                            36.99521484375006,
                            17.020556640625017
                        ],
                        [
                            37.00898437500004,
                            17.058886718750017
                        ],
                        [
                            37.06152343749997,
                            17.061279296875057
                        ],
                        [
                            37.16953125000006,
                            17.041406249999994
                        ],
                        [
                            37.41103515625005,
                            17.061718749999955
                        ],
                        [
                            37.452929687500074,
                            17.108691406250017
                        ],
                        [
                            37.510156249999994,
                            17.28813476562499
                        ],
                        [
                            37.78242187500004,
                            17.458007812500057
                        ],
                        [
                            38.253515625000006,
                            17.584765625000017
                        ],
                        [
                            38.26728515625004,
                            17.616699218750057
                        ],
                        [
                            38.28984375000002,
                            17.637011718750017
                        ],
                        [
                            38.34736328125004,
                            17.68359375
                        ],
                        [
                            38.37373046875004,
                            17.717333984375045
                        ],
                        [
                            38.42246093750006,
                            17.823925781249983
                        ],
                        [
                            38.60947265625006,
                            18.00507812500004
                        ],
                        [
                            38.91171875000006,
                            17.427148437499994
                        ],
                        [
                            39.14257812500003,
                            16.729150390624966
                        ],
                        [
                            39.298925781250006,
                            15.921093750000011
                        ],
                        [
                            39.5065429687501,
                            15.532128906249994
                        ],
                        [
                            39.86376953124997,
                            15.470312500000034
                        ],
                        [
                            40.20410156250003,
                            15.014111328124983
                        ],
                        [
                            41.17646484375004,
                            14.620312500000054
                        ],
                        [
                            41.65820312499997,
                            13.983056640624994
                        ],
                        [
                            42.24511718749997,
                            13.587646484374986
                        ],
                        [
                            42.796191406250074,
                            12.864257812500057
                        ],
                        [
                            43.08291015625005,
                            12.824609374999966
                        ],
                        [
                            43.11669921874997,
                            12.708593749999963
                        ],
                        [
                            43.00566406250002,
                            12.66230468750004
                        ],
                        [
                            42.88330078124997,
                            12.621289062500026
                        ],
                        [
                            42.767480468749994,
                            12.422851562500014
                        ],
                        [
                            42.70371093750006,
                            12.380322265625054
                        ],
                        [
                            42.67011718750004,
                            12.376562499999991
                        ],
                        [
                            42.479394531249994,
                            12.513623046875026
                        ],
                        [
                            42.37851562500006,
                            12.466406250000006
                        ],
                        [
                            41.76503906250005,
                            13.183935546874991
                        ],
                        [
                            41.362890625000006,
                            13.499804687500031
                        ],
                        [
                            40.938574218750006,
                            13.983105468749997
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Eritrea',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                -16.33447265624997,
                                28.37993164062499
                            ],
                            [
                                -16.65800781249999,
                                28.007177734374977
                            ],
                            [
                                -16.905322265625017,
                                28.33959960937503
                            ],
                            [
                                -16.33447265624997,
                                28.37993164062499
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                3.145312500000017,
                                39.79008789062499
                            ],
                            [
                                3.4618164062500227,
                                39.69775390625003
                            ],
                            [
                                3.0728515625000057,
                                39.30126953124997
                            ],
                            [
                                2.371289062500068,
                                39.61308593749999
                            ],
                            [
                                2.7849609375000455,
                                39.854833984375034
                            ],
                            [
                                3.145312500000017,
                                39.79008789062499
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -1.7940429687499488,
                                43.407324218750006
                            ],
                            [
                                -1.2854492187499886,
                                43.05961914062496
                            ],
                            [
                                -0.041162109374994316,
                                42.689111328124966
                            ],
                            [
                                1.4283203124999773,
                                42.59589843749998
                            ],
                            [
                                1.7060546875,
                                42.50332031250005
                            ],
                            [
                                2.0327148437500284,
                                42.353515625
                            ],
                            [
                                3.21142578125,
                                42.43115234375
                            ],
                            [
                                3.248046875,
                                41.94423828125002
                            ],
                            [
                                2.0826171875000625,
                                41.287402343750045
                            ],
                            [
                                1.0329101562500682,
                                41.06206054687496
                            ],
                            [
                                -0.32700195312494884,
                                39.519873046875006
                            ],
                            [
                                -0.20493164062494884,
                                39.062597656250034
                            ],
                            [
                                0.20156250000007958,
                                38.75917968750002
                            ],
                            [
                                -0.3812499999999943,
                                38.435644531250034
                            ],
                            [
                                -0.6467773437499886,
                                38.15185546875003
                            ],
                            [
                                -0.8221679687499375,
                                37.58076171875004
                            ],
                            [
                                -1.3275390624999375,
                                37.561132812500034
                            ],
                            [
                                -1.7976074218749716,
                                37.23286132812504
                            ],
                            [
                                -2.111523437499983,
                                36.77666015624999
                            ],
                            [
                                -4.366845703124994,
                                36.71811523437506
                            ],
                            [
                                -4.67412109374996,
                                36.506445312500006
                            ],
                            [
                                -5.171484374999949,
                                36.423779296874955
                            ],
                            [
                                -5.62548828125,
                                36.02592773437499
                            ],
                            [
                                -6.0406738281249375,
                                36.18842773437498
                            ],
                            [
                                -6.492431640624943,
                                36.95463867187502
                            ],
                            [
                                -6.88461914062492,
                                37.194238281249966
                            ],
                            [
                                -7.4061523437499375,
                                37.17944335937497
                            ],
                            [
                                -7.503515624999977,
                                37.58549804687502
                            ],
                            [
                                -7.185449218749994,
                                38.00634765625006
                            ],
                            [
                                -7.343017578124943,
                                38.45742187500002
                            ],
                            [
                                -7.0460449218749375,
                                38.907031250000045
                            ],
                            [
                                -7.44511718749996,
                                39.53618164062496
                            ],
                            [
                                -7.036718750000006,
                                39.713964843750034
                            ],
                            [
                                -6.81015624999992,
                                40.343115234375034
                            ],
                            [
                                -6.928466796874972,
                                41.009130859375006
                            ],
                            [
                                -6.212499999999949,
                                41.53203124999996
                            ],
                            [
                                -6.61826171874992,
                                41.9423828125
                            ],
                            [
                                -7.40361328124996,
                                41.833691406249955
                            ],
                            [
                                -7.920849609374983,
                                41.883642578125006
                            ],
                            [
                                -8.213085937499926,
                                42.133691406249966
                            ],
                            [
                                -8.777148437500017,
                                41.941064453124994
                            ],
                            [
                                -8.690917968749943,
                                42.274169921875
                            ],
                            [
                                -9.235205078124977,
                                42.97690429687498
                            ],
                            [
                                -8.873681640624966,
                                43.33442382812498
                            ],
                            [
                                -7.698144531249994,
                                43.76455078125005
                            ],
                            [
                                -7.261962890624972,
                                43.594628906249966
                            ],
                            [
                                -5.66582031249996,
                                43.58247070312498
                            ],
                            [
                                -4.52304687499992,
                                43.41572265625004
                            ],
                            [
                                -3.604638671874966,
                                43.51948242187504
                            ],
                            [
                                -3.045605468749926,
                                43.37158203125
                            ],
                            [
                                -2.337109374999926,
                                43.32802734375002
                            ],
                            [
                                -1.7940429687499488,
                                43.407324218750006
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Spain',
                'childNum': 3
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                22.61738281250004,
                                58.62124023437502
                            ],
                            [
                                23.29287109375008,
                                58.483496093750006
                            ],
                            [
                                22.152929687500006,
                                58.11533203124998
                            ],
                            [
                                22.081347656250074,
                                58.47812499999998
                            ],
                            [
                                22.61738281250004,
                                58.62124023437502
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                28.012500000000045,
                                59.484277343749966
                            ],
                            [
                                27.464453125000034,
                                58.84130859375
                            ],
                            [
                                27.48779296875,
                                58.270068359375045
                            ],
                            [
                                27.75283203125008,
                                57.841015624999955
                            ],
                            [
                                27.542089843750063,
                                57.799414062500006
                            ],
                            [
                                27.400000000000063,
                                57.66679687499999
                            ],
                            [
                                27.35195312500005,
                                57.52812499999999
                            ],
                            [
                                27.187109375000034,
                                57.53833007812503
                            ],
                            [
                                27.033398437500068,
                                57.578759765624994
                            ],
                            [
                                26.96601562500001,
                                57.60913085937506
                            ],
                            [
                                26.899804687500023,
                                57.608789062499994
                            ],
                            [
                                26.29804687500001,
                                57.60107421875
                            ],
                            [
                                25.66015625,
                                57.920166015625
                            ],
                            [
                                25.11103515625004,
                                58.06342773437498
                            ],
                            [
                                24.91132812500001,
                                58.00458984374998
                            ],
                            [
                                24.83906250000004,
                                57.98872070312504
                            ],
                            [
                                24.775781250000023,
                                57.98525390625002
                            ],
                            [
                                24.45888671875005,
                                57.907861328124994
                            ],
                            [
                                24.529101562500045,
                                58.35424804687497
                            ],
                            [
                                23.767578125000057,
                                58.36083984374997
                            ],
                            [
                                23.50927734375003,
                                58.65854492187498
                            ],
                            [
                                23.494433593750017,
                                59.19565429687498
                            ],
                            [
                                24.38037109375003,
                                59.47265625
                            ],
                            [
                                25.50927734374997,
                                59.63901367187506
                            ],
                            [
                                26.974707031250006,
                                59.450634765624955
                            ],
                            [
                                28.012500000000045,
                                59.484277343749966
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Estonia',
                'childNum': 2
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            38.43144531250002,
                            14.428613281249994
                        ],
                        [
                            39.531835937500006,
                            14.536718749999991
                        ],
                        [
                            40.22148437500002,
                            14.43115234375
                        ],
                        [
                            40.82011718750002,
                            14.111669921874991
                        ],
                        [
                            40.938574218750006,
                            13.983105468749997
                        ],
                        [
                            41.362890625000006,
                            13.499804687500003
                        ],
                        [
                            41.76503906250002,
                            13.183935546874991
                        ],
                        [
                            42.378515625000006,
                            12.466406249999991
                        ],
                        [
                            41.76650390625002,
                            11.589111328125
                        ],
                        [
                            41.79824218750002,
                            10.98046875
                        ],
                        [
                            42.557714843750006,
                            11.080761718749997
                        ],
                        [
                            42.92275390625002,
                            10.999316406249989
                        ],
                        [
                            42.65957031250002,
                            10.621386718749989
                        ],
                        [
                            42.84160156250002,
                            10.203076171874997
                        ],
                        [
                            43.181640625,
                            9.879980468749991
                        ],
                        [
                            43.482519531250006,
                            9.379492187499991
                        ],
                        [
                            44.02285156250002,
                            8.986035156249997
                        ],
                        [
                            46.97822265625001,
                            7.9970703125
                        ],
                        [
                            47.97822265625001,
                            7.9970703125
                        ],
                        [
                            45.93496093750002,
                            5.997216796874994
                        ],
                        [
                            44.940527343750006,
                            4.912011718749994
                        ],
                        [
                            44.02812500000002,
                            4.950976562499989
                        ],
                        [
                            43.53828125000001,
                            4.84033203125
                        ],
                        [
                            43.01601562500002,
                            4.5633300781249915
                        ],
                        [
                            42.791601562500006,
                            4.2919921875
                        ],
                        [
                            42.02412109375001,
                            4.137939453125
                        ],
                        [
                            41.91533203125002,
                            4.031298828124989
                        ],
                        [
                            41.88398437500001,
                            3.977734374999997
                        ],
                        [
                            41.73769531250002,
                            3.9790527343749886
                        ],
                        [
                            41.48193359375,
                            3.9632812499999943
                        ],
                        [
                            41.22089843750001,
                            3.943554687499997
                        ],
                        [
                            41.02080078125002,
                            4.0574707031249915
                        ],
                        [
                            40.765234375000006,
                            4.2730468749999915
                        ],
                        [
                            39.84218750000002,
                            3.8514648437499943
                        ],
                        [
                            39.79033203125002,
                            3.7542480468749915
                        ],
                        [
                            39.65751953125002,
                            3.577832031249997
                        ],
                        [
                            39.49443359375002,
                            3.4561035156249886
                        ],
                        [
                            38.608007812500006,
                            3.60009765625
                        ],
                        [
                            38.45156250000002,
                            3.6048339843749915
                        ],
                        [
                            38.22529296875001,
                            3.6189941406249915
                        ],
                        [
                            38.08613281250001,
                            3.6488281249999943
                        ],
                        [
                            37.15458984375002,
                            4.254541015624994
                        ],
                        [
                            36.90556640625002,
                            4.4114746093749915
                        ],
                        [
                            36.08193359375002,
                            4.44970703125
                        ],
                        [
                            36.02197265625,
                            4.4681152343749915
                        ],
                        [
                            35.97871093750001,
                            4.503808593749994
                        ],
                        [
                            35.76308593750002,
                            4.808007812499994
                        ],
                        [
                            35.75615234375002,
                            4.950488281249989
                        ],
                        [
                            35.779296875,
                            5.1055664062499915
                        ],
                        [
                            35.80029296875,
                            5.156933593749997
                        ],
                        [
                            35.74501953125002,
                            5.343994140625
                        ],
                        [
                            35.325292968750006,
                            5.364892578124994
                        ],
                        [
                            35.2646484375,
                            5.412060546874997
                        ],
                        [
                            35.26386718750001,
                            5.457910156249994
                        ],
                        [
                            35.26835937500002,
                            5.4922851562499915
                        ],
                        [
                            35.08193359375002,
                            5.673144531249989
                        ],
                        [
                            34.71064453125001,
                            6.660302734374994
                        ],
                        [
                            34.06425781250002,
                            7.225732421874994
                        ],
                        [
                            33.666113281250006,
                            7.6709960937499915
                        ],
                        [
                            33.22597656250002,
                            7.760644531249994
                        ],
                        [
                            33.01259765625002,
                            7.9515136718749915
                        ],
                        [
                            33.23427734375002,
                            8.396386718749994
                        ],
                        [
                            33.95332031250001,
                            8.443505859374994
                        ],
                        [
                            34.09453125000002,
                            8.582226562499997
                        ],
                        [
                            34.078125,
                            9.461523437499991
                        ],
                        [
                            34.29150390625,
                            10.124755859375
                        ],
                        [
                            34.27568359375002,
                            10.528124999999989
                        ],
                        [
                            34.571875000000006,
                            10.880175781249989
                        ],
                        [
                            34.93144531250002,
                            10.864794921874989
                        ],
                        [
                            35.1123046875,
                            11.816552734374994
                        ],
                        [
                            35.59609375000002,
                            12.537304687499997
                        ],
                        [
                            36.107519531250006,
                            12.726464843749994
                        ],
                        [
                            36.21220703125002,
                            13.271093749999991
                        ],
                        [
                            36.390625,
                            13.626074218749991
                        ],
                        [
                            36.52431640625002,
                            14.2568359375
                        ],
                        [
                            36.54238281250002,
                            14.258203124999994
                        ],
                        [
                            36.67910156250002,
                            14.307568359374997
                        ],
                        [
                            36.81191406250002,
                            14.315039062499991
                        ],
                        [
                            37.024511718750006,
                            14.27197265625
                        ],
                        [
                            37.25722656250002,
                            14.453759765624994
                        ],
                        [
                            37.50722656250002,
                            14.156396484374994
                        ],
                        [
                            37.54677734375002,
                            14.143847656250003
                        ],
                        [
                            37.57119140625002,
                            14.149072265624994
                        ],
                        [
                            37.6484375,
                            14.322558593750003
                        ],
                        [
                            37.70839843750002,
                            14.457226562499997
                        ],
                        [
                            37.8203125,
                            14.70849609375
                        ],
                        [
                            37.88417968750002,
                            14.852294921875
                        ],
                        [
                            37.943457031250006,
                            14.810546875
                        ],
                        [
                            38.002539062500006,
                            14.737109375000003
                        ],
                        [
                            38.22148437500002,
                            14.649658203125
                        ],
                        [
                            38.43144531250002,
                            14.428613281249994
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Ethiopia',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            28.96582031250003,
                            69.02197265625
                        ],
                        [
                            28.470703125000057,
                            68.48837890625
                        ],
                        [
                            29.343847656250006,
                            68.06186523437506
                        ],
                        [
                            29.941210937500074,
                            67.54746093749998
                        ],
                        [
                            29.087011718750034,
                            66.97094726562503
                        ],
                        [
                            29.903417968750006,
                            66.091064453125
                        ],
                        [
                            29.60419921875004,
                            64.968408203125
                        ],
                        [
                            30.072851562500063,
                            64.76503906250005
                        ],
                        [
                            30.10810546875004,
                            64.36611328125005
                        ],
                        [
                            30.50390625000003,
                            64.02060546875
                        ],
                        [
                            30.05537109375004,
                            63.689013671875045
                        ],
                        [
                            31.180859375000097,
                            63.208300781250074
                        ],
                        [
                            31.533984375000017,
                            62.885400390624994
                        ],
                        [
                            31.28564453125003,
                            62.567822265624955
                        ],
                        [
                            29.69013671875004,
                            61.54609375000001
                        ],
                        [
                            27.797656250000074,
                            60.53613281250003
                        ],
                        [
                            25.65644531250004,
                            60.33320312499998
                        ],
                        [
                            23.721777343750063,
                            59.965673828125006
                        ],
                        [
                            22.79345703125003,
                            60.076806640624994
                        ],
                        [
                            22.57587890625001,
                            60.35908203125004
                        ],
                        [
                            21.436035156250057,
                            60.596386718749955
                        ],
                        [
                            21.36054687500004,
                            60.96748046874998
                        ],
                        [
                            21.605957031250057,
                            61.59155273437503
                        ],
                        [
                            21.255957031250063,
                            61.98964843750005
                        ],
                        [
                            21.103613281250034,
                            62.622949218749994
                        ],
                        [
                            22.243261718750034,
                            63.437939453124955
                        ],
                        [
                            23.248730468750068,
                            63.89614257812505
                        ],
                        [
                            24.557910156250045,
                            64.801025390625
                        ],
                        [
                            25.372656250000034,
                            65.00947265625001
                        ],
                        [
                            25.34785156250004,
                            65.47924804687497
                        ],
                        [
                            24.15546875000004,
                            65.80527343750006
                        ],
                        [
                            23.70117187500003,
                            66.48076171874999
                        ],
                        [
                            23.988574218750045,
                            66.81054687500003
                        ],
                        [
                            23.468066406250074,
                            67.449951171875
                        ],
                        [
                            23.63886718750004,
                            67.95439453125002
                        ],
                        [
                            22.78242187500001,
                            68.39101562499997
                        ],
                        [
                            21.99746093750005,
                            68.52060546874998
                        ],
                        [
                            20.622167968750006,
                            69.036865234375
                        ],
                        [
                            21.621777343750068,
                            69.27070312499995
                        ],
                        [
                            22.410937500000074,
                            68.719873046875
                        ],
                        [
                            23.85400390625,
                            68.80590820312503
                        ],
                        [
                            24.94140625000003,
                            68.59326171875006
                        ],
                        [
                            25.748339843750017,
                            68.99013671875
                        ],
                        [
                            26.011523437500074,
                            69.65263671875005
                        ],
                        [
                            26.525390625000057,
                            69.91503906250003
                        ],
                        [
                            27.889941406250045,
                            70.06166992187497
                        ],
                        [
                            29.14160156250003,
                            69.67143554687505
                        ],
                        [
                            28.96582031250003,
                            69.02197265625
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Finland',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                178.28017578124994,
                                -17.37197265625001
                            ],
                            [
                                178.59160156249996,
                                -17.651464843750006
                            ],
                            [
                                178.66767578125004,
                                -18.080859375000003
                            ],
                            [
                                177.8470703125,
                                -18.254882812500014
                            ],
                            [
                                177.32138671875,
                                -18.077539062500037
                            ],
                            [
                                177.26396484375007,
                                -17.86347656250004
                            ],
                            [
                                177.61796875000007,
                                -17.461035156250034
                            ],
                            [
                                178.28017578124994,
                                -17.37197265625001
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                179.99921875000004,
                                -16.168554687499977
                            ],
                            [
                                179.74814453125006,
                                -16.44628906249997
                            ],
                            [
                                179.9279296875001,
                                -16.74443359374996
                            ],
                            [
                                179.00683593750003,
                                -16.90019531249999
                            ],
                            [
                                178.80507812499994,
                                -16.631445312500034
                            ],
                            [
                                179.55175781250003,
                                -16.249902343750023
                            ],
                            [
                                180.00000000000003,
                                -16.15292968749999
                            ],
                            [
                                179.99921875000004,
                                -16.168554687499977
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Fiji',
                'childNum': 2
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                -60.28623046874995,
                                -51.461914062500014
                            ],
                            [
                                -59.26806640625,
                                -51.42753906250003
                            ],
                            [
                                -59.92138671874997,
                                -51.969531250000045
                            ],
                            [
                                -60.28623046874995,
                                -51.461914062500014
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -58.85019531249995,
                                -51.26992187499998
                            ],
                            [
                                -58.473730468750006,
                                -51.50908203125003
                            ],
                            [
                                -57.976513671874955,
                                -51.38437500000003
                            ],
                            [
                                -57.83818359374993,
                                -51.70917968750003
                            ],
                            [
                                -58.68349609375002,
                                -51.93623046875001
                            ],
                            [
                                -59.06801757812502,
                                -52.17304687500003
                            ],
                            [
                                -59.57080078124994,
                                -51.92539062500003
                            ],
                            [
                                -59.05952148437498,
                                -51.685449218749994
                            ],
                            [
                                -58.85019531249995,
                                -51.26992187499998
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'United Kingdom',
                'childNum': 2
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                55.79736328125003,
                                -21.33935546875003
                            ],
                            [
                                55.36269531250005,
                                -21.27363281250004
                            ],
                            [
                                55.311328125000074,
                                -20.90410156249999
                            ],
                            [
                                55.661914062500074,
                                -20.906249999999986
                            ],
                            [
                                55.79736328125003,
                                -21.33935546875003
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -51.65253906249998,
                                4.061279296874972
                            ],
                            [
                                -51.99062499999994,
                                3.702001953124963
                            ],
                            [
                                -52.70063476562501,
                                2.36367187499998
                            ],
                            [
                                -52.96484375,
                                2.1835449218749687
                            ],
                            [
                                -53.76777343749998,
                                2.3548339843750483
                            ],
                            [
                                -54.13007812499998,
                                2.1210449218750256
                            ],
                            [
                                -54.51508789062498,
                                2.245458984374963
                            ],
                            [
                                -54.55048828125001,
                                2.2930664062499915
                            ],
                            [
                                -54.59194335937502,
                                2.3137695312500313
                            ],
                            [
                                -54.61625976562499,
                                2.3267578125000057
                            ],
                            [
                                -54.60473632812497,
                                2.3357910156249915
                            ],
                            [
                                -54.56840820312502,
                                2.3425781250000313
                            ],
                            [
                                -54.53593749999999,
                                2.343310546875003
                            ],
                            [
                                -54.48554687500001,
                                2.4161132812500057
                            ],
                            [
                                -54.402001953124966,
                                2.46152343750002
                            ],
                            [
                                -54.25673828125002,
                                2.7137207031249773
                            ],
                            [
                                -54.19550781249998,
                                2.817871093750057
                            ],
                            [
                                -54.03422851562499,
                                3.62939453125
                            ],
                            [
                                -54.350732421874994,
                                4.054101562500023
                            ],
                            [
                                -54.45219726562496,
                                5.013476562499989
                            ],
                            [
                                -54.085302734375006,
                                5.41181640625004
                            ],
                            [
                                -53.84716796875,
                                5.782226562500014
                            ],
                            [
                                -53.454443359375006,
                                5.563476562500028
                            ],
                            [
                                -52.899316406249966,
                                5.425048828124986
                            ],
                            [
                                -52.29052734375003,
                                4.942187500000031
                            ],
                            [
                                -51.91958007812494,
                                4.52431640624998
                            ],
                            [
                                -51.65253906249998,
                                4.061279296874972
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                9.480371093750023,
                                42.80541992187503
                            ],
                            [
                                9.550683593750051,
                                42.12973632812506
                            ],
                            [
                                9.186132812500034,
                                41.38491210937502
                            ],
                            [
                                8.80751953125008,
                                41.58837890625
                            ],
                            [
                                8.71308593750004,
                                42.54975585937501
                            ],
                            [
                                9.480371093750023,
                                42.80541992187503
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                5.789746093749983,
                                49.53828125000001
                            ],
                            [
                                5.823437500000011,
                                49.50507812499998
                            ],
                            [
                                5.9013671875,
                                49.48974609374997
                            ],
                            [
                                5.928906250000011,
                                49.47753906249997
                            ],
                            [
                                5.959472656250028,
                                49.45463867187502
                            ],
                            [
                                6.074121093750023,
                                49.45463867187502
                            ],
                            [
                                6.119921875000017,
                                49.485205078125034
                            ],
                            [
                                6.242187500000057,
                                49.49433593750001
                            ],
                            [
                                6.277343750000028,
                                49.47753906249997
                            ],
                            [
                                6.344335937500006,
                                49.45273437499998
                            ],
                            [
                                6.735449218750006,
                                49.16059570312498
                            ],
                            [
                                7.450585937500051,
                                49.152197265625034
                            ],
                            [
                                8.134863281250006,
                                48.97358398437498
                            ],
                            [
                                7.584179687499983,
                                48.064306640625006
                            ],
                            [
                                7.615624999999994,
                                47.59272460937504
                            ],
                            [
                                7.000585937500034,
                                47.32250976562506
                            ],
                            [
                                6.429003906250017,
                                46.832275390625
                            ],
                            [
                                6.06025390625004,
                                46.42817382812501
                            ],
                            [
                                6.095898437500011,
                                46.279394531250006
                            ],
                            [
                                5.9700195312500455,
                                46.214697265625034
                            ],
                            [
                                5.971484375000074,
                                46.151220703125006
                            ],
                            [
                                6.0066406250000455,
                                46.14233398437506
                            ],
                            [
                                6.086621093750068,
                                46.14702148437502
                            ],
                            [
                                6.758105468750017,
                                46.41577148437497
                            ],
                            [
                                6.772070312500006,
                                46.16513671874998
                            ],
                            [
                                7.021093750000034,
                                45.92578124999997
                            ],
                            [
                                7.153417968749977,
                                45.40092773437499
                            ],
                            [
                                6.627734375000017,
                                45.11796874999999
                            ],
                            [
                                7.030664062500023,
                                44.71669921875005
                            ],
                            [
                                6.96728515625,
                                44.280029296875
                            ],
                            [
                                7.651464843750034,
                                44.03364257812498
                            ],
                            [
                                7.4931640625,
                                43.767138671875045
                            ],
                            [
                                6.494042968750023,
                                43.169287109375006
                            ],
                            [
                                6.115917968750011,
                                43.07236328124998
                            ],
                            [
                                5.406542968750074,
                                43.228515625
                            ],
                            [
                                5.05976562500004,
                                43.44453125000004
                            ],
                            [
                                4.628710937500074,
                                43.387109374999966
                            ],
                            [
                                4.052636718750023,
                                43.59306640625002
                            ],
                            [
                                3.2588867187500625,
                                43.193212890625006
                            ],
                            [
                                3.051757812500057,
                                42.915136718750006
                            ],
                            [
                                3.21142578125,
                                42.43115234375
                            ],
                            [
                                2.0327148437500284,
                                42.353515625
                            ],
                            [
                                1.7060546875,
                                42.50332031250005
                            ],
                            [
                                1.4283203124999773,
                                42.59589843749998
                            ],
                            [
                                -0.041162109374994316,
                                42.689111328124966
                            ],
                            [
                                -1.2854492187499886,
                                43.05961914062496
                            ],
                            [
                                -1.7940429687499488,
                                43.407324218750006
                            ],
                            [
                                -1.4848632812499432,
                                43.56376953124999
                            ],
                            [
                                -1.2455078124999375,
                                44.55986328124999
                            ],
                            [
                                -1.0660156249999488,
                                45.80566406250003
                            ],
                            [
                                -1.1462890624999602,
                                46.311376953125034
                            ],
                            [
                                -1.7865234374999375,
                                46.51484375000001
                            ],
                            [
                                -2.0188964843749204,
                                47.03764648437502
                            ],
                            [
                                -3.1588378906249943,
                                47.694677734375034
                            ],
                            [
                                -4.312109374999949,
                                47.82290039062502
                            ],
                            [
                                -4.62919921874996,
                                48.085791015625034
                            ],
                            [
                                -4.39316406249992,
                                48.36762695312498
                            ],
                            [
                                -4.72075195312496,
                                48.539892578125006
                            ],
                            [
                                -3.2314453124999716,
                                48.84082031250003
                            ],
                            [
                                -2.692333984374983,
                                48.53681640624998
                            ],
                            [
                                -2.4461914062499375,
                                48.64829101562506
                            ],
                            [
                                -1.4376464843749943,
                                48.64140624999999
                            ],
                            [
                                -1.8564453124999716,
                                49.68378906249998
                            ],
                            [
                                -1.2586425781249488,
                                49.68017578125006
                            ],
                            [
                                -1.1385253906249773,
                                49.38789062500001
                            ],
                            [
                                -0.16347656249993747,
                                49.296777343749994
                            ],
                            [
                                0.18671874999998295,
                                49.703027343749994
                            ],
                            [
                                1.2455078125000512,
                                49.99824218750001
                            ],
                            [
                                1.5515625000000455,
                                50.29394531250003
                            ],
                            [
                                1.5794921875000512,
                                50.73925781249997
                            ],
                            [
                                1.9125000000000512,
                                50.99062500000002
                            ],
                            [
                                2.52490234375,
                                51.097119140624955
                            ],
                            [
                                2.8397460937500227,
                                50.71176757812498
                            ],
                            [
                                4.174609375000017,
                                50.24648437500005
                            ],
                            [
                                4.867578125000051,
                                49.78813476562502
                            ],
                            [
                                5.789746093749983,
                                49.53828125000001
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'France',
                'childNum': 4
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            13.293554687500006,
                            2.161572265624997
                        ],
                        [
                            13.162695312500006,
                            1.6480957031249943
                        ],
                        [
                            13.274121093750011,
                            1.2410156249999886
                        ],
                        [
                            13.851367187500017,
                            1.4187499999999886
                        ],
                        [
                            14.180859375000011,
                            1.3702148437499915
                        ],
                        [
                            14.434472656250023,
                            0.8114746093749972
                        ],
                        [
                            14.087500000000006,
                            0.5365722656249972
                        ],
                        [
                            13.884570312500017,
                            0.19082031249999432
                        ],
                        [
                            13.860058593750011,
                            -0.20332031250001137
                        ],
                        [
                            14.480566406250006,
                            -0.6183593750000114
                        ],
                        [
                            14.383984375000011,
                            -1.8900390625000085
                        ],
                        [
                            14.08740234375,
                            -2.4668945312500057
                        ],
                        [
                            13.784375000000011,
                            -2.1637695312500114
                        ],
                        [
                            13.464941406250006,
                            -2.3954101562500085
                        ],
                        [
                            12.991992187500017,
                            -2.3133789062500085
                        ],
                        [
                            12.793554687500006,
                            -1.9318359375000114
                        ],
                        [
                            12.43212890625,
                            -1.9289062500000114
                        ],
                        [
                            12.446386718750006,
                            -2.3299804687500085
                        ],
                        [
                            11.5751953125,
                            -2.3970703125000057
                        ],
                        [
                            11.537792968750011,
                            -2.836718750000003
                        ],
                        [
                            11.934179687500006,
                            -3.3185546875000114
                        ],
                        [
                            11.685742187500011,
                            -3.6820312500000085
                        ],
                        [
                            11.504296875000023,
                            -3.520312500000003
                        ],
                        [
                            11.130175781250017,
                            -3.916308593750003
                        ],
                        [
                            10.34765625,
                            -3.0130859375000085
                        ],
                        [
                            9.759472656250011,
                            -2.5185546875
                        ],
                        [
                            9.265625,
                            -1.8250976562500085
                        ],
                        [
                            9.330664062500006,
                            -1.5345703125000085
                        ],
                        [
                            9.064648437500011,
                            -1.29833984375
                        ],
                        [
                            8.821386718750006,
                            -0.7083984375000085
                        ],
                        [
                            9.296679687500017,
                            -0.35126953125001137
                        ],
                        [
                            9.470117187500023,
                            0.3619140624999915
                        ],
                        [
                            9.329980468750023,
                            0.61083984375
                        ],
                        [
                            9.625878906250023,
                            0.7794433593749943
                        ],
                        [
                            9.676464843750011,
                            1.07470703125
                        ],
                        [
                            10.028515625000011,
                            1.0040039062499915
                        ],
                        [
                            11.335351562500023,
                            0.9997070312499972
                        ],
                        [
                            11.332324218750017,
                            1.5283691406249886
                        ],
                        [
                            11.328710937500006,
                            2.167431640624997
                        ],
                        [
                            11.348437500000017,
                            2.2997070312499943
                        ],
                        [
                            13.220312500000006,
                            2.2564453124999915
                        ],
                        [
                            13.293554687500006,
                            2.161572265624997
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Gabon',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                -6.218017578125,
                                54.08872070312506
                            ],
                            [
                                -7.3551757812499545,
                                54.12124023437502
                            ],
                            [
                                -8.118261718749977,
                                54.41425781250004
                            ],
                            [
                                -7.2186523437499375,
                                55.09199218749998
                            ],
                            [
                                -6.129150390625028,
                                55.217382812500034
                            ],
                            [
                                -5.470410156249926,
                                54.500195312499955
                            ],
                            [
                                -5.60678710937492,
                                54.272558593750034
                            ],
                            [
                                -6.218017578125,
                                54.08872070312506
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -3.109667968749932,
                                58.515478515625034
                            ],
                            [
                                -3.212353515624983,
                                58.32124023437501
                            ],
                            [
                                -3.99003906249996,
                                57.95903320312502
                            ],
                            [
                                -3.4027832031249545,
                                57.708251953125
                            ],
                            [
                                -2.0740722656249773,
                                57.70239257812506
                            ],
                            [
                                -1.7779296874999488,
                                57.493750000000006
                            ],
                            [
                                -2.6527343750000227,
                                56.318261718749966
                            ],
                            [
                                -3.178222656249943,
                                56.080126953125045
                            ],
                            [
                                -2.1470703124999204,
                                55.90297851562502
                            ],
                            [
                                -1.6553710937499488,
                                55.57036132812502
                            ],
                            [
                                -1.2917480468749432,
                                54.77387695312501
                            ],
                            [
                                -0.6713867187499432,
                                54.50390625
                            ],
                            [
                                -0.20556640625,
                                54.021728515625
                            ],
                            [
                                0.5587890625000398,
                                52.96694335937505
                            ],
                            [
                                1.6567382812499716,
                                52.753710937500045
                            ],
                            [
                                1.5589843750000512,
                                52.08686523437498
                            ],
                            [
                                0.9550781250000284,
                                51.80781249999998
                            ],
                            [
                                0.6865234375000284,
                                51.38657226562506
                            ],
                            [
                                1.4149414062500227,
                                51.36328125
                            ],
                            [
                                0.9601562500000114,
                                50.92587890624998
                            ],
                            [
                                0.205078125,
                                50.763037109375034
                            ],
                            [
                                -1.5167480468749375,
                                50.747460937499966
                            ],
                            [
                                -2.5477539062499375,
                                50.61630859374998
                            ],
                            [
                                -3.40458984374996,
                                50.63242187499998
                            ],
                            [
                                -3.7933593749999375,
                                50.229248046875
                            ],
                            [
                                -4.172558593749926,
                                50.390820312499955
                            ],
                            [
                                -5.342285156249943,
                                50.24614257812496
                            ],
                            [
                                -4.582910156249966,
                                50.7763671875
                            ],
                            [
                                -4.188183593749926,
                                51.18852539062502
                            ],
                            [
                                -3.1359863281249716,
                                51.20502929687501
                            ],
                            [
                                -4.38627929687496,
                                51.74106445312506
                            ],
                            [
                                -4.902294921874926,
                                51.626269531250045
                            ],
                            [
                                -5.088085937499926,
                                51.99589843750002
                            ],
                            [
                                -4.383154296874949,
                                52.19731445312502
                            ],
                            [
                                -3.980322265624949,
                                52.54174804687503
                            ],
                            [
                                -4.111035156249926,
                                53.218945312499955
                            ],
                            [
                                -3.064746093749932,
                                53.426855468750034
                            ],
                            [
                                -2.9250976562499886,
                                53.732763671875006
                            ],
                            [
                                -3.9579101562499943,
                                54.780957031249955
                            ],
                            [
                                -5.135498046875,
                                54.857519531250006
                            ],
                            [
                                -4.7211425781249545,
                                55.42099609375006
                            ],
                            [
                                -5.245605468749972,
                                55.929248046875045
                            ],
                            [
                                -5.6095703124999545,
                                56.055273437500034
                            ],
                            [
                                -5.39194335937492,
                                56.51479492187505
                            ],
                            [
                                -5.936767578125,
                                56.605712890625
                            ],
                            [
                                -5.561914062499994,
                                57.23271484375002
                            ],
                            [
                                -5.8180664062499545,
                                57.43608398437496
                            ],
                            [
                                -5.338281250000023,
                                58.23872070312498
                            ],
                            [
                                -4.715429687499949,
                                58.510009765625
                            ],
                            [
                                -3.109667968749932,
                                58.515478515625034
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'United Kingdom',
                'childNum': 2
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            46.30546875000002,
                            41.507714843749994
                        ],
                        [
                            46.43095703125002,
                            41.077050781249994
                        ],
                        [
                            46.086523437500006,
                            41.183837890625
                        ],
                        [
                            45.28095703125001,
                            41.449560546875
                        ],
                        [
                            45.21718750000002,
                            41.423193359375
                        ],
                        [
                            45.00136718750002,
                            41.290966796875
                        ],
                        [
                            44.97587890625002,
                            41.277490234374994
                        ],
                        [
                            44.81132812500002,
                            41.259375
                        ],
                        [
                            44.81093750000002,
                            41.248583984374996
                        ],
                        [
                            44.84853515625002,
                            41.220166015625
                        ],
                        [
                            44.841406250000006,
                            41.211376953125
                        ],
                        [
                            44.47304687500002,
                            41.191015625
                        ],
                        [
                            44.077246093750006,
                            41.182519531249994
                        ],
                        [
                            43.43339843750002,
                            41.155517578125
                        ],
                        [
                            42.78789062500002,
                            41.563720703125
                        ],
                        [
                            42.466406250000006,
                            41.439843749999994
                        ],
                        [
                            41.92578125,
                            41.495654296874996
                        ],
                        [
                            41.82353515625002,
                            41.432373046875
                        ],
                        [
                            41.779394531250006,
                            41.44052734375
                        ],
                        [
                            41.701757812500006,
                            41.471582031249994
                        ],
                        [
                            41.57656250000002,
                            41.497314453125
                        ],
                        [
                            41.51005859375002,
                            41.517480468749994
                        ],
                        [
                            41.76298828125002,
                            41.970019531249996
                        ],
                        [
                            41.48876953125,
                            42.659326171874994
                        ],
                        [
                            40.83662109375001,
                            43.0634765625
                        ],
                        [
                            40.524023437500006,
                            43.121044921875
                        ],
                        [
                            40.02373046875002,
                            43.48486328125
                        ],
                        [
                            40.648046875000006,
                            43.53388671875
                        ],
                        [
                            41.083105468750006,
                            43.374462890625
                        ],
                        [
                            41.35820312500002,
                            43.333398437499994
                        ],
                        [
                            41.46074218750002,
                            43.276318359375
                        ],
                        [
                            41.58056640625,
                            43.21923828125
                        ],
                        [
                            42.05000000000001,
                            43.19013671875
                        ],
                        [
                            42.27968750000002,
                            43.228076171874996
                        ],
                        [
                            42.419042968750006,
                            43.22421875
                        ],
                        [
                            42.566015625000006,
                            43.155126953125
                        ],
                        [
                            42.660253906250006,
                            43.159082031249994
                        ],
                        [
                            42.76064453125002,
                            43.169580078124994
                        ],
                        [
                            42.99160156250002,
                            43.09150390625
                        ],
                        [
                            43.78261718750002,
                            42.747021484375
                        ],
                        [
                            43.79873046875002,
                            42.727783203125
                        ],
                        [
                            43.79541015625,
                            42.702978515625
                        ],
                        [
                            43.74990234375002,
                            42.657519531249996
                        ],
                        [
                            43.738378906250006,
                            42.616992187499996
                        ],
                        [
                            43.759863281250006,
                            42.59384765625
                        ],
                        [
                            43.82597656250002,
                            42.571533203125
                        ],
                        [
                            43.95742187500002,
                            42.566552734374994
                        ],
                        [
                            44.00468750000002,
                            42.595605468749994
                        ],
                        [
                            44.10273437500001,
                            42.616357421874994
                        ],
                        [
                            44.32949218750002,
                            42.703515624999994
                        ],
                        [
                            44.505859375,
                            42.7486328125
                        ],
                        [
                            44.64433593750002,
                            42.734716796875
                        ],
                        [
                            44.771093750000006,
                            42.616796875
                        ],
                        [
                            44.85048828125002,
                            42.746826171875
                        ],
                        [
                            44.87099609375002,
                            42.756396484374996
                        ],
                        [
                            44.943359375,
                            42.730273437499996
                        ],
                        [
                            45.07158203125002,
                            42.694140624999996
                        ],
                        [
                            45.20820312500001,
                            42.648242187499996
                        ],
                        [
                            45.34375,
                            42.52978515625
                        ],
                        [
                            45.56289062500002,
                            42.5357421875
                        ],
                        [
                            45.7275390625,
                            42.475048828125
                        ],
                        [
                            45.63427734375,
                            42.234716796875
                        ],
                        [
                            45.63857421875002,
                            42.205078125
                        ],
                        [
                            46.21269531250002,
                            41.989892578124994
                        ],
                        [
                            46.30546875000002,
                            41.507714843749994
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Georgia',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -0.068603515625,
                            11.115624999999994
                        ],
                        [
                            -0.05771484374997726,
                            10.630615234375
                        ],
                        [
                            0.36269531250002274,
                            10.236474609374994
                        ],
                        [
                            0.26191406250001137,
                            9.49560546875
                        ],
                        [
                            0.5290039062500114,
                            9.358300781249994
                        ],
                        [
                            0.41533203125001705,
                            8.652734374999994
                        ],
                        [
                            0.6880859375000057,
                            8.304248046874989
                        ],
                        [
                            0.5835937500000057,
                            8.145800781249989
                        ],
                        [
                            0.634765625,
                            7.353662109374994
                        ],
                        [
                            0.5255859375000114,
                            6.8509277343749915
                        ],
                        [
                            0.7369140625000057,
                            6.452587890624997
                        ],
                        [
                            1.1872070312500114,
                            6.089404296874989
                        ],
                        [
                            0.94970703125,
                            5.810253906249997
                        ],
                        [
                            0.25966796875002274,
                            5.75732421875
                        ],
                        [
                            -0.7977050781249773,
                            5.226708984374994
                        ],
                        [
                            -1.6384765624999886,
                            4.980859374999994
                        ],
                        [
                            -2.0018554687499943,
                            4.762451171875
                        ],
                        [
                            -2.7230468749999943,
                            5.013720703124989
                        ],
                        [
                            -2.815673828125,
                            5.153027343749997
                        ],
                        [
                            -2.7549804687499773,
                            5.432519531249994
                        ],
                        [
                            -2.9622558593749773,
                            5.643017578124997
                        ],
                        [
                            -3.2005859374999943,
                            6.3482421874999915
                        ],
                        [
                            -3.2271484374999773,
                            6.7491210937499915
                        ],
                        [
                            -2.9590820312499773,
                            7.454541015624997
                        ],
                        [
                            -2.789746093749983,
                            7.931933593749989
                        ],
                        [
                            -2.6688476562499943,
                            8.022216796875
                        ],
                        [
                            -2.6133789062499773,
                            8.046679687499989
                        ],
                        [
                            -2.505859375,
                            8.208740234375
                        ],
                        [
                            -2.705761718749983,
                            9.351367187499989
                        ],
                        [
                            -2.6958496093749886,
                            9.481347656249994
                        ],
                        [
                            -2.78662109375,
                            10.401904296874989
                        ],
                        [
                            -2.9148925781249773,
                            10.592333984374989
                        ],
                        [
                            -2.8299316406249773,
                            10.998388671874991
                        ],
                        [
                            -0.701416015625,
                            10.988964843749997
                        ],
                        [
                            -0.068603515625,
                            11.115624999999994
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Ghana',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -11.389404296875,
                            12.404394531249991
                        ],
                        [
                            -11.30517578125,
                            12.015429687499989
                        ],
                        [
                            -10.933203124999977,
                            12.205175781249991
                        ],
                        [
                            -10.677343749999977,
                            11.8994140625
                        ],
                        [
                            -10.274853515624983,
                            12.212646484375
                        ],
                        [
                            -9.820703124999994,
                            12.04248046875
                        ],
                        [
                            -9.043066406249977,
                            12.40234375
                        ],
                        [
                            -8.822021484375,
                            11.673242187499994
                        ],
                        [
                            -8.407470703125,
                            11.386279296874989
                        ],
                        [
                            -8.231494140624989,
                            10.43798828125
                        ],
                        [
                            -8.007275390624983,
                            10.321874999999991
                        ],
                        [
                            -7.974462890624977,
                            10.229541015624989
                        ],
                        [
                            -7.990624999999994,
                            10.162499999999994
                        ],
                        [
                            -8.136621093749994,
                            10.022070312499991
                        ],
                        [
                            -8.155175781249994,
                            9.973193359374989
                        ],
                        [
                            -8.145849609374977,
                            9.881738281249994
                        ],
                        [
                            -8.136962890625,
                            9.495703124999991
                        ],
                        [
                            -7.896191406249983,
                            9.415869140624991
                        ],
                        [
                            -7.950976562499989,
                            8.786816406249997
                        ],
                        [
                            -7.823583984374977,
                            8.467675781249994
                        ],
                        [
                            -7.953125,
                            8.477734374999997
                        ],
                        [
                            -8.236962890624994,
                            8.455664062499991
                        ],
                        [
                            -8.256103515625,
                            8.253710937499989
                        ],
                        [
                            -8.016748046874994,
                            8.144921874999994
                        ],
                        [
                            -8.126855468749994,
                            7.867724609374989
                        ],
                        [
                            -8.115429687499983,
                            7.7607421875
                        ],
                        [
                            -8.205957031249994,
                            7.590234374999994
                        ],
                        [
                            -8.231884765624983,
                            7.5567382812499915
                        ],
                        [
                            -8.429980468749989,
                            7.601855468749989
                        ],
                        [
                            -8.486425781249977,
                            7.558496093749994
                        ],
                        [
                            -8.708300781249989,
                            7.658886718749997
                        ],
                        [
                            -8.8896484375,
                            7.2626953125
                        ],
                        [
                            -9.117578124999994,
                            7.2159179687499915
                        ],
                        [
                            -9.459765624999989,
                            7.442529296874994
                        ],
                        [
                            -9.368945312499989,
                            7.639550781249994
                        ],
                        [
                            -9.518261718749983,
                            8.346093749999994
                        ],
                        [
                            -9.768261718749983,
                            8.534570312499994
                        ],
                        [
                            -10.283203125,
                            8.485156249999989
                        ],
                        [
                            -10.677343749999977,
                            8.400585937499997
                        ],
                        [
                            -10.500537109374989,
                            8.687548828124989
                        ],
                        [
                            -10.758593749999989,
                            9.385351562499991
                        ],
                        [
                            -11.047460937499977,
                            9.786328124999997
                        ],
                        [
                            -11.115673828124983,
                            9.843164062499994
                        ],
                        [
                            -11.180859374999983,
                            9.925341796874989
                        ],
                        [
                            -11.205664062499977,
                            9.977734374999997
                        ],
                        [
                            -11.273632812499983,
                            9.996533203124997
                        ],
                        [
                            -11.911083984374983,
                            9.993017578124991
                        ],
                        [
                            -12.142333984375,
                            9.875390624999994
                        ],
                        [
                            -12.557861328125,
                            9.704980468749994
                        ],
                        [
                            -12.958789062499989,
                            9.263330078124994
                        ],
                        [
                            -12.998632812499977,
                            9.146923828124997
                        ],
                        [
                            -13.028027343749983,
                            9.103564453124989
                        ],
                        [
                            -13.077294921874994,
                            9.069628906249989
                        ],
                        [
                            -13.1298828125,
                            9.047558593749997
                        ],
                        [
                            -13.178369140624994,
                            9.060888671874991
                        ],
                        [
                            -13.234228515624977,
                            9.070117187499989
                        ],
                        [
                            -13.302636718749994,
                            9.078369140625
                        ],
                        [
                            -13.269482421874983,
                            9.170556640624994
                        ],
                        [
                            -13.568261718749994,
                            9.543408203124997
                        ],
                        [
                            -13.700488281249989,
                            9.851269531249997
                        ],
                        [
                            -14.426904296874994,
                            10.248339843749989
                        ],
                        [
                            -14.775927734374989,
                            10.931640625
                        ],
                        [
                            -15.043017578124989,
                            10.940136718749997
                        ],
                        [
                            -14.9990234375,
                            10.9921875
                        ],
                        [
                            -14.944433593749977,
                            11.072167968749994
                        ],
                        [
                            -14.779296875,
                            11.405517578125
                        ],
                        [
                            -14.720263671874989,
                            11.48193359375
                        ],
                        [
                            -14.682958984374977,
                            11.508496093749997
                        ],
                        [
                            -13.953222656249977,
                            11.664599609374989
                        ],
                        [
                            -13.732763671874977,
                            11.736035156249997
                        ],
                        [
                            -13.759765625,
                            12.262353515624994
                        ],
                        [
                            -13.673535156249983,
                            12.478515625
                        ],
                        [
                            -13.732617187499983,
                            12.592822265624989
                        ],
                        [
                            -13.729248046875,
                            12.673925781249991
                        ],
                        [
                            -13.40576171875,
                            12.662255859374994
                        ],
                        [
                            -13.082910156249994,
                            12.633544921875
                        ],
                        [
                            -12.291210937499983,
                            12.328027343749994
                        ],
                        [
                            -11.389404296875,
                            12.404394531249991
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Guinea',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -16.763330078124994,
                            13.064160156249997
                        ],
                        [
                            -16.824804687499977,
                            13.341064453125
                        ],
                        [
                            -16.56230468749999,
                            13.587304687499994
                        ],
                        [
                            -15.509667968749994,
                            13.586230468750003
                        ],
                        [
                            -15.834277343749989,
                            13.156445312499997
                        ],
                        [
                            -16.648779296874977,
                            13.154150390624991
                        ],
                        [
                            -16.763330078124994,
                            13.064160156249997
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Gambia',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -13.759765625,
                            12.262353515624994
                        ],
                        [
                            -13.73276367187492,
                            11.736035156249983
                        ],
                        [
                            -13.953222656249977,
                            11.664599609374989
                        ],
                        [
                            -14.682958984374949,
                            11.508496093749983
                        ],
                        [
                            -14.720263671875017,
                            11.481933593749986
                        ],
                        [
                            -14.779296874999972,
                            11.405517578125057
                        ],
                        [
                            -14.944433593749949,
                            11.072167968749994
                        ],
                        [
                            -14.999023437499972,
                            10.992187500000043
                        ],
                        [
                            -15.04301757812496,
                            10.940136718750011
                        ],
                        [
                            -15.093749999999972,
                            11.011035156249974
                        ],
                        [
                            -15.181054687499994,
                            11.034228515625003
                        ],
                        [
                            -15.216699218749994,
                            11.156249999999986
                        ],
                        [
                            -15.39311523437496,
                            11.217236328124983
                        ],
                        [
                            -15.501904296875011,
                            11.723779296874966
                        ],
                        [
                            -16.138427734375,
                            11.917285156250045
                        ],
                        [
                            -16.43681640624996,
                            12.204150390625045
                        ],
                        [
                            -16.711816406249937,
                            12.354833984375006
                        ],
                        [
                            -16.656933593749955,
                            12.364355468749991
                        ],
                        [
                            -16.52133789062495,
                            12.348632812499986
                        ],
                        [
                            -16.41630859374996,
                            12.367675781250057
                        ],
                        [
                            -16.144189453124937,
                            12.457421875000037
                        ],
                        [
                            -15.839550781249955,
                            12.437890624999966
                        ],
                        [
                            -15.57480468749992,
                            12.490380859375009
                        ],
                        [
                            -15.19609374999996,
                            12.679931640624986
                        ],
                        [
                            -14.34921874999992,
                            12.67641601562498
                        ],
                        [
                            -14.064843749999966,
                            12.675292968750014
                        ],
                        [
                            -13.729248046875,
                            12.673925781250006
                        ],
                        [
                            -13.732617187499983,
                            12.592822265625003
                        ],
                        [
                            -13.673535156249926,
                            12.478515624999986
                        ],
                        [
                            -13.759765625,
                            12.262353515624994
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Guinea Bissau',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                11.332324218750017,
                                1.5283691406249886
                            ],
                            [
                                11.335351562500023,
                                0.9997070312500114
                            ],
                            [
                                10.028515625000068,
                                1.0040039062500057
                            ],
                            [
                                9.385937500000068,
                                1.13925781250002
                            ],
                            [
                                9.494238281250006,
                                1.4353027343750284
                            ],
                            [
                                9.632128906250045,
                                1.5655273437500057
                            ],
                            [
                                9.807031250000051,
                                1.9274902343750284
                            ],
                            [
                                9.77968750000008,
                                2.0682128906250057
                            ],
                            [
                                9.870117187500028,
                                2.21328124999998
                            ],
                            [
                                9.979882812499994,
                                2.1677734375000455
                            ],
                            [
                                10.790917968750023,
                                2.1675781250000057
                            ],
                            [
                                11.096582031250051,
                                2.167480468749986
                            ],
                            [
                                11.328710937500006,
                                2.1674316406249687
                            ],
                            [
                                11.332324218750017,
                                1.5283691406249886
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                8.735742187500023,
                                3.7583007812499716
                            ],
                            [
                                8.946093750000074,
                                3.6275390624999773
                            ],
                            [
                                8.704003906250051,
                                3.2236328125000284
                            ],
                            [
                                8.474902343749989,
                                3.2646484375000426
                            ],
                            [
                                8.735742187500023,
                                3.7583007812499716
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Equatorial Guinea',
                'childNum': 2
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                23.852246093749983,
                                35.53544921874999
                            ],
                            [
                                24.31289062500005,
                                35.36381835937502
                            ],
                            [
                                24.721289062500034,
                                35.4248046875
                            ],
                            [
                                25.475683593750063,
                                35.30620117187502
                            ],
                            [
                                25.74501953125005,
                                35.142724609374994
                            ],
                            [
                                24.79980468750003,
                                34.93447265625002
                            ],
                            [
                                24.463671875000045,
                                35.160351562499955
                            ],
                            [
                                23.561621093750034,
                                35.29516601562497
                            ],
                            [
                                23.852246093749983,
                                35.53544921874999
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                23.41542968750008,
                                38.958642578124994
                            ],
                            [
                                24.09902343750008,
                                38.670996093750006
                            ],
                            [
                                23.758789062500057,
                                38.401220703125034
                            ],
                            [
                                23.25214843750004,
                                38.80122070312498
                            ],
                            [
                                23.41542968750008,
                                38.958642578124994
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                26.03896484375008,
                                40.726757812499955
                            ],
                            [
                                25.10449218750003,
                                40.994726562500006
                            ],
                            [
                                24.38378906250003,
                                40.91274414062502
                            ],
                            [
                                23.87890625,
                                40.54438476562501
                            ],
                            [
                                23.66455078125003,
                                40.22382812499998
                            ],
                            [
                                23.31201171874997,
                                40.216455078124994
                            ],
                            [
                                22.629492187500034,
                                40.49555664062501
                            ],
                            [
                                22.59218750000005,
                                40.03691406250002
                            ],
                            [
                                22.919042968750034,
                                39.62890625000003
                            ],
                            [
                                22.96552734375001,
                                39.030908203124966
                            ],
                            [
                                23.25292968750003,
                                38.66123046875006
                            ],
                            [
                                23.96699218750001,
                                38.27499999999998
                            ],
                            [
                                23.58046875000005,
                                38.01054687500002
                            ],
                            [
                                23.193652343750017,
                                37.95903320312499
                            ],
                            [
                                23.16152343750005,
                                37.333837890625006
                            ],
                            [
                                22.765039062500023,
                                37.39331054687503
                            ],
                            [
                                23.073535156250074,
                                36.77495117187499
                            ],
                            [
                                22.6083984375,
                                36.77973632812501
                            ],
                            [
                                22.08046875000008,
                                37.028955078124966
                            ],
                            [
                                21.58291015625005,
                                37.080957031249994
                            ],
                            [
                                21.5712890625,
                                37.54101562500003
                            ],
                            [
                                21.14501953125003,
                                37.91928710937498
                            ],
                            [
                                21.40371093750005,
                                38.19667968750002
                            ],
                            [
                                21.82470703125003,
                                38.32812500000006
                            ],
                            [
                                22.799609375000045,
                                37.981201171875
                            ],
                            [
                                22.421679687500045,
                                38.43852539062499
                            ],
                            [
                                21.47255859375005,
                                38.321386718750006
                            ],
                            [
                                21.113183593750023,
                                38.38466796875002
                            ],
                            [
                                20.779687500000023,
                                39.00854492187503
                            ],
                            [
                                20.300781250000057,
                                39.32709960937501
                            ],
                            [
                                20.099414062500074,
                                39.641259765624966
                            ],
                            [
                                20.382421875000006,
                                39.802636718749994
                            ],
                            [
                                20.4560546875,
                                40.065576171874994
                            ],
                            [
                                20.881640625000017,
                                40.467919921874994
                            ],
                            [
                                20.95576171875001,
                                40.775292968749994
                            ],
                            [
                                21.575781250000034,
                                40.86894531249996
                            ],
                            [
                                21.929492187500045,
                                41.10742187499997
                            ],
                            [
                                21.99335937500001,
                                41.13095703125006
                            ],
                            [
                                22.23769531250005,
                                41.15517578125002
                            ],
                            [
                                22.49355468750005,
                                41.118505859375006
                            ],
                            [
                                22.75507812500004,
                                41.31274414062506
                            ],
                            [
                                22.91601562500003,
                                41.33627929687506
                            ],
                            [
                                23.025585937500068,
                                41.32563476562501
                            ],
                            [
                                23.155957031250068,
                                41.32207031249999
                            ],
                            [
                                23.239843750000034,
                                41.38496093750001
                            ],
                            [
                                23.372070312500057,
                                41.3896484375
                            ],
                            [
                                23.433398437500017,
                                41.39873046874999
                            ],
                            [
                                23.53583984375001,
                                41.38603515624999
                            ],
                            [
                                23.63515625000008,
                                41.386767578125045
                            ],
                            [
                                23.880859375000057,
                                41.45595703125002
                            ],
                            [
                                23.973535156250023,
                                41.452294921874966
                            ],
                            [
                                24.011328124999977,
                                41.460058593750034
                            ],
                            [
                                24.03291015625004,
                                41.469091796875034
                            ],
                            [
                                24.05605468750005,
                                41.527246093749966
                            ],
                            [
                                24.595996093750074,
                                41.44272460937498
                            ],
                            [
                                24.773730468750045,
                                41.356103515624994
                            ],
                            [
                                24.84687500000004,
                                41.394238281249955
                            ],
                            [
                                24.99355468750008,
                                41.36499023437503
                            ],
                            [
                                25.133398437500063,
                                41.31577148437506
                            ],
                            [
                                25.251171875000068,
                                41.243554687499994
                            ],
                            [
                                25.381933593750063,
                                41.264355468749955
                            ],
                            [
                                25.52705078125004,
                                41.2998046875
                            ],
                            [
                                25.723925781250017,
                                41.31503906249998
                            ],
                            [
                                25.784960937500017,
                                41.330419921875006
                            ],
                            [
                                25.923339843750057,
                                41.311914062499966
                            ],
                            [
                                26.155175781250023,
                                41.43486328124999
                            ],
                            [
                                26.320898437500034,
                                41.716552734375
                            ],
                            [
                                26.62490234375008,
                                41.401757812499994
                            ],
                            [
                                26.03896484375008,
                                40.726757812499955
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Greece',
                'childNum': 3
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                -52.73115234375001,
                                69.94472656250005
                            ],
                            [
                                -52.045312499999994,
                                69.8072265625
                            ],
                            [
                                -52.11259765625002,
                                69.48911132812503
                            ],
                            [
                                -53.57841796874996,
                                69.25664062500002
                            ],
                            [
                                -53.78305664062498,
                                69.50629882812498
                            ],
                            [
                                -54.804101562499994,
                                69.63051757812497
                            ],
                            [
                                -54.83046875000002,
                                70.16108398437498
                            ],
                            [
                                -53.37514648437502,
                                70.22128906249998
                            ],
                            [
                                -52.73115234375001,
                                69.94472656250005
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -25.43232421875001,
                                70.92133789062495
                            ],
                            [
                                -25.402246093749994,
                                70.65268554687503
                            ],
                            [
                                -27.89799804687496,
                                70.45400390624997
                            ],
                            [
                                -27.70893554687501,
                                70.897119140625
                            ],
                            [
                                -25.43232421875001,
                                70.92133789062495
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -29.952880859375,
                                83.56484374999997
                            ],
                            [
                                -25.947412109374966,
                                83.28964843750003
                            ],
                            [
                                -24.47031249999995,
                                82.87739257812498
                            ],
                            [
                                -21.520654296874966,
                                82.59541015625001
                            ],
                            [
                                -25.148828124999966,
                                82.001123046875
                            ],
                            [
                                -21.337988281249977,
                                82.068701171875
                            ],
                            [
                                -21.14658203124995,
                                81.695166015625
                            ],
                            [
                                -17.226220703125023,
                                81.430419921875
                            ],
                            [
                                -15.555517578125006,
                                81.83359374999998
                            ],
                            [
                                -12.19287109375,
                                81.64912109374995
                            ],
                            [
                                -11.528808593749972,
                                81.42402343750001
                            ],
                            [
                                -14.503564453124994,
                                80.76328125000006
                            ],
                            [
                                -16.76059570312492,
                                80.573388671875
                            ],
                            [
                                -16.868408203124943,
                                80.19824218750006
                            ],
                            [
                                -19.429199218749943,
                                80.25771484375
                            ],
                            [
                                -20.138476562499932,
                                79.803369140625
                            ],
                            [
                                -19.283593749999937,
                                79.68315429687502
                            ],
                            [
                                -19.07495117187497,
                                79.15234375000006
                            ],
                            [
                                -20.955664062499977,
                                78.55502929687503
                            ],
                            [
                                -20.862597656249932,
                                77.91186523437503
                            ],
                            [
                                -20.23193359374997,
                                77.36840820312497
                            ],
                            [
                                -18.339013671874966,
                                77.21528320312504
                            ],
                            [
                                -18.51030273437496,
                                76.77817382812498
                            ],
                            [
                                -20.942089843749955,
                                76.88701171875005
                            ],
                            [
                                -21.877343749999966,
                                76.57348632812503
                            ],
                            [
                                -21.56909179687497,
                                76.29370117187506
                            ],
                            [
                                -20.10361328124992,
                                76.21909179687503
                            ],
                            [
                                -19.508984374999926,
                                75.75751953124995
                            ],
                            [
                                -19.425976562499983,
                                75.22983398437503
                            ],
                            [
                                -20.484960937500006,
                                75.31425781249999
                            ],
                            [
                                -19.22509765625,
                                74.4794921875
                            ],
                            [
                                -19.36914062499997,
                                74.28403320312498
                            ],
                            [
                                -21.129443359374932,
                                74.11088867187505
                            ],
                            [
                                -20.36728515624992,
                                73.8482421875
                            ],
                            [
                                -20.509667968749966,
                                73.49287109375001
                            ],
                            [
                                -22.18505859374997,
                                73.26987304687506
                            ],
                            [
                                -22.036328124999955,
                                72.91845703125006
                            ],
                            [
                                -22.29321289062497,
                                72.11953125
                            ],
                            [
                                -24.06904296875001,
                                72.49873046874998
                            ],
                            [
                                -24.66684570312492,
                                72.437353515625
                            ],
                            [
                                -22.013330078124966,
                                71.688818359375
                            ],
                            [
                                -21.75224609374999,
                                71.47832031250002
                            ],
                            [
                                -21.522656249999926,
                                70.52622070312503
                            ],
                            [
                                -23.327832031249983,
                                70.45097656250007
                            ],
                            [
                                -23.791796875000017,
                                70.55517578124997
                            ],
                            [
                                -24.377001953124932,
                                71.14638671875
                            ],
                            [
                                -25.74223632812499,
                                71.18359375
                            ],
                            [
                                -26.717919921874994,
                                70.95048828125005
                            ],
                            [
                                -27.68876953124999,
                                70.99345703124999
                            ],
                            [
                                -28.540917968749937,
                                70.476904296875
                            ],
                            [
                                -28.015039062500023,
                                70.40224609375
                            ],
                            [
                                -26.747265624999926,
                                70.47553710937501
                            ],
                            [
                                -23.173242187499937,
                                70.114599609375
                            ],
                            [
                                -23.08823242187492,
                                69.882958984375
                            ],
                            [
                                -26.34140624999992,
                                68.70214843750003
                            ],
                            [
                                -29.24951171874997,
                                68.29877929687501
                            ],
                            [
                                -29.86850585937495,
                                68.31157226562505
                            ],
                            [
                                -30.97856445312499,
                                68.06132812500005
                            ],
                            [
                                -32.13725585937496,
                                68.38491210937497
                            ],
                            [
                                -32.274804687499994,
                                67.92285156249997
                            ],
                            [
                                -33.15698242187497,
                                67.62670898437506
                            ],
                            [
                                -34.101660156250034,
                                66.72592773437503
                            ],
                            [
                                -34.63281249999997,
                                66.43476562499995
                            ],
                            [
                                -36.37919921874996,
                                65.830810546875
                            ],
                            [
                                -37.75419921874996,
                                65.59306640624999
                            ],
                            [
                                -39.96093750000003,
                                65.55620117187502
                            ],
                            [
                                -39.57792968749996,
                                65.34077148437501
                            ],
                            [
                                -40.655468749999926,
                                64.91533203125002
                            ],
                            [
                                -40.18222656249998,
                                64.47993164062495
                            ],
                            [
                                -40.78173828125,
                                64.22177734375003
                            ],
                            [
                                -40.550390625000034,
                                63.72524414062505
                            ],
                            [
                                -42.15297851562502,
                                62.568457031250006
                            ],
                            [
                                -42.32148437499998,
                                62.15273437500005
                            ],
                            [
                                -42.110205078125006,
                                61.857226562500074
                            ],
                            [
                                -42.41874999999999,
                                61.53701171875002
                            ],
                            [
                                -42.717041015625,
                                60.767480468749994
                            ],
                            [
                                -43.32011718749993,
                                59.928124999999994
                            ],
                            [
                                -44.06547851562499,
                                59.92480468750003
                            ],
                            [
                                -45.37924804687495,
                                60.20292968750002
                            ],
                            [
                                -46.04663085937503,
                                60.61572265625
                            ],
                            [
                                -46.01171875000003,
                                61.09682617187505
                            ],
                            [
                                -46.87446289062501,
                                60.81640625000003
                            ],
                            [
                                -47.79624023437495,
                                60.828857421875
                            ],
                            [
                                -48.92207031249998,
                                61.27744140624998
                            ],
                            [
                                -49.28906249999997,
                                61.58994140625006
                            ],
                            [
                                -49.553466796875,
                                62.23271484374999
                            ],
                            [
                                -50.319238281249966,
                                62.473193359375045
                            ],
                            [
                                -50.338330078124955,
                                62.82875976562502
                            ],
                            [
                                -51.46884765624995,
                                63.64228515625001
                            ],
                            [
                                -51.451074218749994,
                                63.90478515625003
                            ],
                            [
                                -52.09340820312502,
                                64.41591796874997
                            ],
                            [
                                -52.259033203125,
                                65.154931640625
                            ],
                            [
                                -53.634716796874955,
                                66.413671875
                            ],
                            [
                                -53.038281249999955,
                                66.82680664062497
                            ],
                            [
                                -53.88442382812502,
                                67.13554687499999
                            ],
                            [
                                -53.418798828125034,
                                67.57456054687498
                            ],
                            [
                                -53.577978515625034,
                                67.83681640625005
                            ],
                            [
                                -53.03945312500002,
                                68.61088867187499
                            ],
                            [
                                -51.24941406250002,
                                68.73994140625001
                            ],
                            [
                                -50.50000000000003,
                                69.935791015625
                            ],
                            [
                                -52.254638671875,
                                70.05893554687503
                            ],
                            [
                                -54.01445312499996,
                                70.42167968750005
                            ],
                            [
                                -54.16582031249999,
                                70.82011718750005
                            ],
                            [
                                -52.405224609374955,
                                70.68676757812506
                            ],
                            [
                                -51.52446289062502,
                                70.43945312500003
                            ],
                            [
                                -51.130078124999955,
                                70.97172851562502
                            ],
                            [
                                -53.117041015625006,
                                71.31289062499997
                            ],
                            [
                                -53.476025390624955,
                                71.64018554687505
                            ],
                            [
                                -54.689062500000006,
                                71.36723632812505
                            ],
                            [
                                -55.59404296874999,
                                71.55351562500005
                            ],
                            [
                                -55.315576171874994,
                                72.11069335937498
                            ],
                            [
                                -55.60170898437494,
                                72.453466796875
                            ],
                            [
                                -54.737939453124994,
                                72.87250976562501
                            ],
                            [
                                -56.10405273437496,
                                73.55815429687499
                            ],
                            [
                                -55.92949218750002,
                                73.89541015625002
                            ],
                            [
                                -56.70634765625002,
                                74.21918945312501
                            ],
                            [
                                -56.80131835937499,
                                74.67167968749999
                            ],
                            [
                                -58.603466796874955,
                                75.38530273437499
                            ],
                            [
                                -58.51621093749995,
                                75.68906250000006
                            ],
                            [
                                -61.18823242187494,
                                76.157861328125
                            ],
                            [
                                -63.438867187499994,
                                76.33945312500003
                            ],
                            [
                                -66.82617187499997,
                                75.96879882812502
                            ],
                            [
                                -69.372900390625,
                                76.331884765625
                            ],
                            [
                                -69.25205078125,
                                76.68613281250006
                            ],
                            [
                                -70.792822265625,
                                76.86909179687504
                            ],
                            [
                                -70.86284179687496,
                                77.175439453125
                            ],
                            [
                                -69.35136718749999,
                                77.467138671875
                            ],
                            [
                                -72.81806640624995,
                                78.1943359375
                            ],
                            [
                                -72.39560546874995,
                                78.50434570312501
                            ],
                            [
                                -68.99345703124999,
                                78.857421875
                            ],
                            [
                                -67.48222656249993,
                                79.11689453125001
                            ],
                            [
                                -65.96787109374998,
                                79.13237304687507
                            ],
                            [
                                -64.79228515624993,
                                80.00063476562502
                            ],
                            [
                                -66.84365234374997,
                                80.07622070312507
                            ],
                            [
                                -66.99589843749999,
                                80.41298828125002
                            ],
                            [
                                -62.99326171874998,
                                81.20698242187504
                            ],
                            [
                                -61.43598632812498,
                                81.13359375000002
                            ],
                            [
                                -61.20292968750002,
                                81.74687500000007
                            ],
                            [
                                -58.717382812500006,
                                82.09306640624999
                            ],
                            [
                                -54.72587890624996,
                                82.35136718750002
                            ],
                            [
                                -52.77558593749998,
                                82.32172851562504
                            ],
                            [
                                -50.894433593749994,
                                81.89521484375001
                            ],
                            [
                                -48.861181640625006,
                                82.40541992187502
                            ],
                            [
                                -45.29106445312502,
                                81.82880859374998
                            ],
                            [
                                -44.23886718749998,
                                82.3681640625
                            ],
                            [
                                -46.47817382812502,
                                82.95190429687497
                            ],
                            [
                                -43.19458007812503,
                                83.25512695312503
                            ],
                            [
                                -32.98442382812499,
                                83.59960937500006
                            ],
                            [
                                -29.952880859375,
                                83.56484374999997
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Denmark',
                'childNum': 3
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -89.2328125,
                            15.888671875
                        ],
                        [
                            -88.53623046874999,
                            15.849609375
                        ],
                        [
                            -88.22832031249999,
                            15.72900390625
                        ],
                        [
                            -88.97641601562499,
                            15.142675781249991
                        ],
                        [
                            -89.142578125,
                            15.072314453125003
                        ],
                        [
                            -89.22236328125,
                            14.866064453124991
                        ],
                        [
                            -89.16220703124999,
                            14.669238281250003
                        ],
                        [
                            -89.17177734375,
                            14.606884765624997
                        ],
                        [
                            -89.28671874999999,
                            14.529980468749997
                        ],
                        [
                            -89.36259765624999,
                            14.416015625
                        ],
                        [
                            -89.872705078125,
                            14.045605468749997
                        ],
                        [
                            -90.09521484375,
                            13.736523437499997
                        ],
                        [
                            -90.60693359375,
                            13.929003906250003
                        ],
                        [
                            -91.14604492187499,
                            13.925585937500003
                        ],
                        [
                            -91.819091796875,
                            14.228222656249997
                        ],
                        [
                            -92.23515624999999,
                            14.54541015625
                        ],
                        [
                            -92.20903320312499,
                            14.570996093749997
                        ],
                        [
                            -92.18706054687499,
                            14.630078124999997
                        ],
                        [
                            -92.159912109375,
                            14.691015624999991
                        ],
                        [
                            -92.17646484375,
                            14.761328124999991
                        ],
                        [
                            -92.18637695312499,
                            14.818359375
                        ],
                        [
                            -92.07480468749999,
                            15.07421875
                        ],
                        [
                            -92.187158203125,
                            15.320898437499991
                        ],
                        [
                            -92.08212890624999,
                            15.495556640624997
                        ],
                        [
                            -91.9572265625,
                            15.703222656249991
                        ],
                        [
                            -91.736572265625,
                            16.07016601562499
                        ],
                        [
                            -91.433984375,
                            16.070458984374994
                        ],
                        [
                            -90.97958984374999,
                            16.07080078125
                        ],
                        [
                            -90.70322265624999,
                            16.071044921875
                        ],
                        [
                            -90.52197265625,
                            16.071191406249994
                        ],
                        [
                            -90.44716796875,
                            16.072705078124997
                        ],
                        [
                            -90.45986328125,
                            16.162353515625
                        ],
                        [
                            -90.450146484375,
                            16.261376953124994
                        ],
                        [
                            -90.4169921875,
                            16.351318359375
                        ],
                        [
                            -90.4169921875,
                            16.391015624999994
                        ],
                        [
                            -90.710693359375,
                            16.70810546874999
                        ],
                        [
                            -90.975830078125,
                            16.867822265624994
                        ],
                        [
                            -90.98916015625,
                            17.81640625
                        ],
                        [
                            -89.16147460937499,
                            17.814843749999994
                        ],
                        [
                            -89.2328125,
                            15.888671875
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Guatemala',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -57.194775390625,
                            5.5484374999999915
                        ],
                        [
                            -57.331005859375,
                            5.020166015624994
                        ],
                        [
                            -57.711083984374994,
                            4.9910644531249915
                        ],
                        [
                            -58.05449218749999,
                            4.171923828124989
                        ],
                        [
                            -57.832666015624994,
                            3.675976562499997
                        ],
                        [
                            -57.54960937499999,
                            3.3528320312499886
                        ],
                        [
                            -57.303662109375,
                            3.3770996093749943
                        ],
                        [
                            -57.20981445312499,
                            2.8828125
                        ],
                        [
                            -56.704345703125,
                            2.0364746093749915
                        ],
                        [
                            -56.482812499999994,
                            1.942138671875
                        ],
                        [
                            -56.96953124999999,
                            1.9164062499999943
                        ],
                        [
                            -57.03759765625,
                            1.9364746093749972
                        ],
                        [
                            -57.41269531249999,
                            1.908935546875
                        ],
                        [
                            -57.500439453125,
                            1.7738281249999943
                        ],
                        [
                            -57.59443359375,
                            1.7041015625
                        ],
                        [
                            -57.69174804687499,
                            1.7047851562499972
                        ],
                        [
                            -57.795654296875,
                            1.6999999999999886
                        ],
                        [
                            -57.982812499999994,
                            1.6484375
                        ],
                        [
                            -58.03466796875,
                            1.520263671875
                        ],
                        [
                            -58.34067382812499,
                            1.5875488281249943
                        ],
                        [
                            -58.38037109375,
                            1.5302246093749972
                        ],
                        [
                            -58.39580078124999,
                            1.4817382812499886
                        ],
                        [
                            -58.5060546875,
                            1.4386718749999972
                        ],
                        [
                            -58.511865234374994,
                            1.28466796875
                        ],
                        [
                            -58.8625,
                            1.20361328125
                        ],
                        [
                            -58.91660156249999,
                            1.2488769531249915
                        ],
                        [
                            -58.968505859375,
                            1.3045898437499943
                        ],
                        [
                            -59.231201171875,
                            1.3760253906249886
                        ],
                        [
                            -59.53569335937499,
                            1.6999999999999886
                        ],
                        [
                            -59.596630859375,
                            1.718017578125
                        ],
                        [
                            -59.66660156249999,
                            1.7462890624999972
                        ],
                        [
                            -59.66850585937499,
                            1.8423339843749886
                        ],
                        [
                            -59.74072265625,
                            1.8741699218749943
                        ],
                        [
                            -59.75620117187499,
                            1.900634765625
                        ],
                        [
                            -59.75522460937499,
                            2.274121093749997
                        ],
                        [
                            -59.9943359375,
                            2.6899902343749886
                        ],
                        [
                            -59.854394531249994,
                            3.5874999999999915
                        ],
                        [
                            -59.55112304687499,
                            3.933544921874997
                        ],
                        [
                            -59.69970703125,
                            4.353515625
                        ],
                        [
                            -59.83334960937499,
                            4.4759277343749915
                        ],
                        [
                            -60.11113281249999,
                            4.511181640624997
                        ],
                        [
                            -60.1486328125,
                            4.533251953124989
                        ],
                        [
                            -60.14091796874999,
                            4.569628906249989
                        ],
                        [
                            -60.12456054687499,
                            4.59765625
                        ],
                        [
                            -60.03178710937499,
                            4.740527343749989
                        ],
                        [
                            -59.990673828125,
                            5.0828613281249915
                        ],
                        [
                            -60.142041015625,
                            5.238818359374989
                        ],
                        [
                            -60.241650390625,
                            5.257958984374994
                        ],
                        [
                            -60.335205078125,
                            5.1993164062499915
                        ],
                        [
                            -60.40878906249999,
                            5.210156249999997
                        ],
                        [
                            -60.45952148437499,
                            5.1880859374999915
                        ],
                        [
                            -60.576416015625,
                            5.1924804687499915
                        ],
                        [
                            -60.6513671875,
                            5.221142578124997
                        ],
                        [
                            -60.742138671875,
                            5.202050781249994
                        ],
                        [
                            -61.37680664062499,
                            5.906982421875
                        ],
                        [
                            -61.3908203125,
                            5.938769531249989
                        ],
                        [
                            -61.303124999999994,
                            6.049511718749997
                        ],
                        [
                            -61.22495117187499,
                            6.129199218749989
                        ],
                        [
                            -61.15947265624999,
                            6.1744140624999915
                        ],
                        [
                            -61.12871093749999,
                            6.214306640624997
                        ],
                        [
                            -61.152294921875,
                            6.3851074218749915
                        ],
                        [
                            -61.151025390624994,
                            6.446533203125
                        ],
                        [
                            -61.20361328125,
                            6.58837890625
                        ],
                        [
                            -61.14560546874999,
                            6.694531249999997
                        ],
                        [
                            -60.717919921874994,
                            6.768310546875
                        ],
                        [
                            -60.586083984374994,
                            6.857080078124994
                        ],
                        [
                            -60.39501953125,
                            6.945361328124989
                        ],
                        [
                            -60.35209960937499,
                            7.002880859374997
                        ],
                        [
                            -60.32207031249999,
                            7.092041015625
                        ],
                        [
                            -60.32548828124999,
                            7.133984374999997
                        ],
                        [
                            -60.34506835937499,
                            7.1499999999999915
                        ],
                        [
                            -60.39238281249999,
                            7.16455078125
                        ],
                        [
                            -60.46494140624999,
                            7.166552734374989
                        ],
                        [
                            -60.523193359375,
                            7.143701171874994
                        ],
                        [
                            -60.71923828125,
                            7.498681640624994
                        ],
                        [
                            -60.513623046875,
                            7.813183593749997
                        ],
                        [
                            -60.032421875,
                            8.053564453124991
                        ],
                        [
                            -59.99072265625,
                            8.162011718749994
                        ],
                        [
                            -59.96484375,
                            8.191601562499997
                        ],
                        [
                            -59.849072265625,
                            8.248681640624994
                        ],
                        [
                            -59.83164062499999,
                            8.305957031249989
                        ],
                        [
                            -60.017529296875,
                            8.54931640625
                        ],
                        [
                            -59.980615234374994,
                            8.532617187499994
                        ],
                        [
                            -59.83652343749999,
                            8.373828124999989
                        ],
                        [
                            -59.66611328124999,
                            8.362597656249989
                        ],
                        [
                            -59.20024414062499,
                            8.074609374999994
                        ],
                        [
                            -58.626611328124994,
                            7.5458984375
                        ],
                        [
                            -58.48056640624999,
                            7.038134765624989
                        ],
                        [
                            -57.982568359374994,
                            6.785888671875
                        ],
                        [
                            -57.19023437499999,
                            6.097314453124994
                        ],
                        [
                            -57.194775390625,
                            5.5484374999999915
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Guyana',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -83.635498046875,
                            14.876416015624997
                        ],
                        [
                            -84.64594726562498,
                            14.661083984375011
                        ],
                        [
                            -84.72978515625002,
                            14.71337890625
                        ],
                        [
                            -84.78916015625003,
                            14.790380859374977
                        ],
                        [
                            -84.98515624999999,
                            14.752441406249972
                        ],
                        [
                            -85.17949218749996,
                            14.343310546875017
                        ],
                        [
                            -85.28417968749997,
                            14.29165039062498
                        ],
                        [
                            -85.37377929687503,
                            14.223876953125028
                        ],
                        [
                            -85.47705078125003,
                            14.108691406250003
                        ],
                        [
                            -85.68193359375002,
                            13.98256835937498
                        ],
                        [
                            -85.731201171875,
                            13.93183593750004
                        ],
                        [
                            -85.72773437499993,
                            13.876074218750048
                        ],
                        [
                            -85.73393554687496,
                            13.85869140625006
                        ],
                        [
                            -85.75341796875,
                            13.852050781250028
                        ],
                        [
                            -85.78671874999995,
                            13.844433593749997
                        ],
                        [
                            -85.98378906249997,
                            13.965673828125006
                        ],
                        [
                            -86.04038085937503,
                            14.050146484374977
                        ],
                        [
                            -86.15122070312495,
                            13.994580078125026
                        ],
                        [
                            -86.23823242187501,
                            13.899462890625017
                        ],
                        [
                            -86.33173828124995,
                            13.770068359375031
                        ],
                        [
                            -86.37695312500003,
                            13.755664062500031
                        ],
                        [
                            -86.61025390624997,
                            13.774853515625026
                        ],
                        [
                            -86.73364257812494,
                            13.763476562500017
                        ],
                        [
                            -86.75898437499995,
                            13.746142578125045
                        ],
                        [
                            -86.77060546875003,
                            13.698730468749972
                        ],
                        [
                            -86.72958984375,
                            13.4072265625
                        ],
                        [
                            -86.710693359375,
                            13.31337890624998
                        ],
                        [
                            -86.918212890625,
                            13.223583984374983
                        ],
                        [
                            -86.92880859375,
                            13.17939453125004
                        ],
                        [
                            -86.93315429687502,
                            13.117529296874963
                        ],
                        [
                            -86.95888671875002,
                            13.053710937500014
                        ],
                        [
                            -87.0591796875,
                            12.991455078125028
                        ],
                        [
                            -87.337255859375,
                            12.979248046875028
                        ],
                        [
                            -87.49838867187498,
                            13.274902343750043
                        ],
                        [
                            -87.781884765625,
                            13.521386718749994
                        ],
                        [
                            -87.71533203125003,
                            13.812695312500011
                        ],
                        [
                            -87.99101562499996,
                            13.879638671874972
                        ],
                        [
                            -88.15102539062497,
                            13.987353515624974
                        ],
                        [
                            -88.44912109374994,
                            13.850976562499994
                        ],
                        [
                            -88.48266601562503,
                            13.854248046875043
                        ],
                        [
                            -88.49765624999998,
                            13.904541015624986
                        ],
                        [
                            -88.50434570312501,
                            13.964208984374963
                        ],
                        [
                            -88.51254882812498,
                            13.97895507812504
                        ],
                        [
                            -89.05712890625,
                            14.329150390624974
                        ],
                        [
                            -89.36259765624996,
                            14.416015625
                        ],
                        [
                            -89.17177734375,
                            14.606884765624983
                        ],
                        [
                            -89.16220703125,
                            14.669238281249989
                        ],
                        [
                            -89.22236328125001,
                            14.86606445312502
                        ],
                        [
                            -89.142578125,
                            15.072314453125031
                        ],
                        [
                            -88.97641601562498,
                            15.142675781249977
                        ],
                        [
                            -88.22832031249999,
                            15.729003906249972
                        ],
                        [
                            -87.61816406249994,
                            15.909863281249955
                        ],
                        [
                            -86.90722656249997,
                            15.762353515625023
                        ],
                        [
                            -86.35664062499998,
                            15.783203125
                        ],
                        [
                            -85.78398437500002,
                            16.002832031249966
                        ],
                        [
                            -85.48369140624996,
                            15.899511718749977
                        ],
                        [
                            -84.97373046874998,
                            15.989892578124994
                        ],
                        [
                            -84.26142578124998,
                            15.822607421875034
                        ],
                        [
                            -83.76044921874998,
                            15.220361328124994
                        ],
                        [
                            -83.635498046875,
                            14.876416015624997
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Honduras',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            18.905371093750006,
                            45.931738281250034
                        ],
                        [
                            19.055078125000023,
                            45.527246093749966
                        ],
                        [
                            19.007128906250045,
                            44.86918945312502
                        ],
                        [
                            18.66259765625,
                            45.07744140624999
                        ],
                        [
                            17.812792968750074,
                            45.07812500000006
                        ],
                        [
                            16.53066406250008,
                            45.21669921875002
                        ],
                        [
                            16.29335937500005,
                            45.00883789062496
                        ],
                        [
                            15.788085937500057,
                            45.17895507812497
                        ],
                        [
                            15.736621093750045,
                            44.76582031250001
                        ],
                        [
                            16.214257812500023,
                            44.21513671874999
                        ],
                        [
                            17.624804687500074,
                            43.04277343749999
                        ],
                        [
                            17.585156250000068,
                            42.93837890625005
                        ],
                        [
                            16.903125000000017,
                            43.392431640625006
                        ],
                        [
                            15.941503906250063,
                            43.656640624999994
                        ],
                        [
                            15.122949218749994,
                            44.256787109374955
                        ],
                        [
                            14.854589843750034,
                            45.08100585937501
                        ],
                        [
                            14.38613281250008,
                            45.342138671875006
                        ],
                        [
                            13.9658203125,
                            44.83564453125004
                        ],
                        [
                            13.62929687500008,
                            45.108203125000045
                        ],
                        [
                            13.878710937500017,
                            45.428369140624994
                        ],
                        [
                            14.95458984375,
                            45.499902343749994
                        ],
                        [
                            15.624804687500017,
                            45.834033203125045
                        ],
                        [
                            15.652148437500074,
                            45.86215820312498
                        ],
                        [
                            15.668066406250034,
                            45.904443359374994
                        ],
                        [
                            15.666210937500011,
                            46.04848632812502
                        ],
                        [
                            15.596875000000011,
                            46.10922851562506
                        ],
                        [
                            15.592578125000017,
                            46.139990234375006
                        ],
                        [
                            15.608984374999977,
                            46.171923828125045
                        ],
                        [
                            15.635937500000068,
                            46.200732421875045
                        ],
                        [
                            16.1064453125,
                            46.382226562499994
                        ],
                        [
                            16.23671875000008,
                            46.48383789062498
                        ],
                        [
                            16.32119140625005,
                            46.53461914062504
                        ],
                        [
                            16.42763671875005,
                            46.5244140625
                        ],
                        [
                            16.516210937499977,
                            46.499902343749966
                        ],
                        [
                            16.569921875000006,
                            46.48500976562505
                        ],
                        [
                            16.748046875000057,
                            46.41640625000002
                        ],
                        [
                            17.310644531250006,
                            45.99614257812502
                        ],
                        [
                            17.96386718750003,
                            45.770263671875
                        ],
                        [
                            18.437304687500045,
                            45.76733398437503
                        ],
                        [
                            18.533593750000023,
                            45.79614257812503
                        ],
                        [
                            18.56464843750004,
                            45.81328124999999
                        ],
                        [
                            18.666015625,
                            45.90747070312497
                        ],
                        [
                            18.905371093750006,
                            45.931738281250034
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Croatia',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -71.647216796875,
                            19.195947265624994
                        ],
                        [
                            -71.74321289062502,
                            18.73291015625
                        ],
                        [
                            -72.000390625,
                            18.59790039062503
                        ],
                        [
                            -72.05986328124993,
                            18.228564453125017
                        ],
                        [
                            -72.87666015624998,
                            18.151757812499994
                        ],
                        [
                            -73.38515625000002,
                            18.251171874999983
                        ],
                        [
                            -73.88496093749998,
                            18.041894531249994
                        ],
                        [
                            -74.41904296874998,
                            18.34619140625
                        ],
                        [
                            -74.22773437499998,
                            18.662695312499977
                        ],
                        [
                            -72.78935546874996,
                            18.434814453125
                        ],
                        [
                            -72.34765624999994,
                            18.674951171874994
                        ],
                        [
                            -72.81108398437496,
                            19.071582031250074
                        ],
                        [
                            -72.70322265625,
                            19.441064453125023
                        ],
                        [
                            -73.05273437499997,
                            19.61074218750005
                        ],
                        [
                            -73.1177734375,
                            19.90380859374997
                        ],
                        [
                            -72.63701171875002,
                            19.90087890625
                        ],
                        [
                            -71.75742187499998,
                            19.68818359375001
                        ],
                        [
                            -71.647216796875,
                            19.195947265624994
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Haiti',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            22.1318359375,
                            48.405322265624996
                        ],
                        [
                            22.253710937500017,
                            48.407373046874994
                        ],
                        [
                            22.582421875000023,
                            48.134033203125
                        ],
                        [
                            22.87666015625001,
                            47.947265625
                        ],
                        [
                            22.290625000000006,
                            47.727832031249996
                        ],
                        [
                            21.151953125000006,
                            46.304345703124994
                        ],
                        [
                            20.613671875000023,
                            46.13349609375
                        ],
                        [
                            20.508105468750017,
                            46.166943359375
                        ],
                        [
                            20.28095703125001,
                            46.1330078125
                        ],
                        [
                            20.241796875000006,
                            46.10859375
                        ],
                        [
                            20.21015625000001,
                            46.126025390624996
                        ],
                        [
                            20.161425781250017,
                            46.141894531249996
                        ],
                        [
                            19.61347656250001,
                            46.169189453125
                        ],
                        [
                            19.421289062500023,
                            46.064453125
                        ],
                        [
                            18.666015625,
                            45.907470703125
                        ],
                        [
                            18.56464843750001,
                            45.813281249999996
                        ],
                        [
                            18.533593750000023,
                            45.796142578125
                        ],
                        [
                            18.437304687500017,
                            45.767333984375
                        ],
                        [
                            17.9638671875,
                            45.770263671875
                        ],
                        [
                            17.310644531250006,
                            45.996142578124996
                        ],
                        [
                            16.748046875,
                            46.416406249999994
                        ],
                        [
                            16.569921875000006,
                            46.485009765624994
                        ],
                        [
                            16.516210937500006,
                            46.499902343749994
                        ],
                        [
                            16.093066406250017,
                            46.86328125
                        ],
                        [
                            16.453417968750017,
                            47.006787109375
                        ],
                        [
                            16.434375000000017,
                            47.367431640625
                        ],
                        [
                            17.147363281250023,
                            48.00595703125
                        ],
                        [
                            17.76191406250001,
                            47.770166015624994
                        ],
                        [
                            18.724218750000006,
                            47.787158203124996
                        ],
                        [
                            18.914160156250006,
                            48.050830078124996
                        ],
                        [
                            19.89863281250001,
                            48.13134765625
                        ],
                        [
                            20.490039062500017,
                            48.526904296874996
                        ],
                        [
                            21.45136718750001,
                            48.55224609375
                        ],
                        [
                            22.1318359375,
                            48.405322265624996
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Hungary',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                120.01250000000002,
                                -9.374707031250026
                            ],
                            [
                                120.50371093750002,
                                -9.674023437499983
                            ],
                            [
                                120.83261718750006,
                                -10.037499999999952
                            ],
                            [
                                120.4391601562501,
                                -10.294042968749991
                            ],
                            [
                                120.14482421875002,
                                -10.200097656249952
                            ],
                            [
                                119.60107421874997,
                                -9.773535156250006
                            ],
                            [
                                119.08544921875003,
                                -9.706933593750023
                            ],
                            [
                                119.18564453125006,
                                -9.384472656249969
                            ],
                            [
                                120.01250000000002,
                                -9.374707031250026
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                125.06816406250002,
                                -9.511914062499997
                            ],
                            [
                                124.42753906250002,
                                -10.14863281250004
                            ],
                            [
                                123.85761718750004,
                                -10.34355468749996
                            ],
                            [
                                123.5892578125,
                                -9.966796875000028
                            ],
                            [
                                123.709375,
                                -9.614843750000006
                            ],
                            [
                                124.0363281250001,
                                -9.341601562500031
                            ],
                            [
                                124.44443359375012,
                                -9.190332031250023
                            ],
                            [
                                124.92226562500005,
                                -8.942480468749977
                            ],
                            [
                                125.06816406250002,
                                -9.511914062499997
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                116.64082031250004,
                                -8.613867187500006
                            ],
                            [
                                116.37724609375002,
                                -8.929003906249989
                            ],
                            [
                                116.07646484375002,
                                -8.744921874999974
                            ],
                            [
                                116.06113281250006,
                                -8.437402343750023
                            ],
                            [
                                116.4015625000001,
                                -8.204199218750034
                            ],
                            [
                                116.73408203125004,
                                -8.38691406250004
                            ],
                            [
                                116.64082031250004,
                                -8.613867187500006
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                122.7829101562501,
                                -8.611718750000009
                            ],
                            [
                                121.65136718749997,
                                -8.898730468749946
                            ],
                            [
                                121.03525390625012,
                                -8.935449218749966
                            ],
                            [
                                119.80791015625002,
                                -8.697656250000023
                            ],
                            [
                                119.91826171875002,
                                -8.445117187500003
                            ],
                            [
                                120.61025390625005,
                                -8.24042968750004
                            ],
                            [
                                121.44453125000004,
                                -8.57783203125004
                            ],
                            [
                                121.96650390625004,
                                -8.455175781250006
                            ],
                            [
                                122.2630859375,
                                -8.624902343750037
                            ],
                            [
                                122.60351562499997,
                                -8.402441406250006
                            ],
                            [
                                122.7829101562501,
                                -8.611718750000009
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                118.24238281250004,
                                -8.317773437499994
                            ],
                            [
                                118.98779296875003,
                                -8.33769531249996
                            ],
                            [
                                119.00625000000005,
                                -8.749609375000034
                            ],
                            [
                                118.18994140624997,
                                -8.840527343749997
                            ],
                            [
                                117.06132812500002,
                                -9.099023437499994
                            ],
                            [
                                116.78847656250005,
                                -9.006347656250028
                            ],
                            [
                                116.83505859375012,
                                -8.532421875000026
                            ],
                            [
                                117.16484375000007,
                                -8.367187500000014
                            ],
                            [
                                117.56708984375004,
                                -8.426367187499991
                            ],
                            [
                                118.11748046875007,
                                -8.12226562500004
                            ],
                            [
                                118.24238281250004,
                                -8.317773437499994
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                115.44785156250012,
                                -8.155175781249994
                            ],
                            [
                                115.70429687500004,
                                -8.40712890624998
                            ],
                            [
                                115.23613281250007,
                                -8.79755859375004
                            ],
                            [
                                114.47529296875004,
                                -8.119433593749989
                            ],
                            [
                                115.44785156250012,
                                -8.155175781249994
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                126.80097656250004,
                                -7.6678710937500085
                            ],
                            [
                                126.4720703125,
                                -7.950390625000011
                            ],
                            [
                                125.84316406250005,
                                -7.81669921874996
                            ],
                            [
                                126.60957031250004,
                                -7.571777343749972
                            ],
                            [
                                126.80097656250004,
                                -7.6678710937500085
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                138.53535156250004,
                                -8.273632812499969
                            ],
                            [
                                138.2962890625,
                                -8.405175781250037
                            ],
                            [
                                137.68515625000012,
                                -8.262207031249943
                            ],
                            [
                                138.00751953125004,
                                -7.641601562499986
                            ],
                            [
                                138.29550781250012,
                                -7.4384765625
                            ],
                            [
                                138.76982421875002,
                                -7.390429687499974
                            ],
                            [
                                138.98906250000002,
                                -7.696093749999989
                            ],
                            [
                                138.53535156250004,
                                -8.273632812499969
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                131.3255859375,
                                -7.999511718749986
                            ],
                            [
                                131.08681640625005,
                                -7.865039062499946
                            ],
                            [
                                131.26005859375007,
                                -7.470507812499946
                            ],
                            [
                                131.69111328125004,
                                -7.438867187499994
                            ],
                            [
                                131.3255859375,
                                -7.999511718749986
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                113.84453125000007,
                                -7.105371093749994
                            ],
                            [
                                113.47070312500003,
                                -7.218457031250026
                            ],
                            [
                                112.76376953125006,
                                -7.139648437499957
                            ],
                            [
                                112.86806640625,
                                -6.899902343749972
                            ],
                            [
                                113.97470703125012,
                                -6.873046875
                            ],
                            [
                                113.84453125000007,
                                -7.105371093749994
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                134.53681640625004,
                                -6.442285156249994
                            ],
                            [
                                134.35595703124997,
                                -6.814843749999952
                            ],
                            [
                                134.05917968750012,
                                -6.769335937500017
                            ],
                            [
                                134.16806640625006,
                                -6.176269531249986
                            ],
                            [
                                134.53681640625004,
                                -6.442285156249994
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                107.37392578125005,
                                -6.007617187499989
                            ],
                            [
                                107.66679687500002,
                                -6.215820312499957
                            ],
                            [
                                108.29501953125012,
                                -6.265039062500037
                            ],
                            [
                                108.67783203125006,
                                -6.790527343749972
                            ],
                            [
                                110.42626953124997,
                                -6.947265625000028
                            ],
                            [
                                110.73691406250006,
                                -6.472363281249997
                            ],
                            [
                                111.15439453125012,
                                -6.669042968750006
                            ],
                            [
                                111.54033203125002,
                                -6.648242187500031
                            ],
                            [
                                112.0873046875,
                                -6.89335937499996
                            ],
                            [
                                112.53925781250004,
                                -6.9264648437499545
                            ],
                            [
                                112.79453125000012,
                                -7.55244140625004
                            ],
                            [
                                113.24843750000005,
                                -7.718164062499952
                            ],
                            [
                                114.07070312500005,
                                -7.633007812500011
                            ],
                            [
                                114.44423828125,
                                -7.895605468749963
                            ],
                            [
                                114.38691406250004,
                                -8.405175781250037
                            ],
                            [
                                114.58378906250002,
                                -8.769628906250034
                            ],
                            [
                                113.25332031250005,
                                -8.286718749999963
                            ],
                            [
                                112.67880859375006,
                                -8.409179687499957
                            ],
                            [
                                111.50996093750004,
                                -8.30507812499998
                            ],
                            [
                                110.60722656250002,
                                -8.149414062499972
                            ],
                            [
                                110.03867187500006,
                                -7.890527343749994
                            ],
                            [
                                109.28164062500005,
                                -7.704882812500003
                            ],
                            [
                                108.74121093749997,
                                -7.667089843750034
                            ],
                            [
                                108.45175781250006,
                                -7.79697265625002
                            ],
                            [
                                107.91748046875003,
                                -7.724121093750014
                            ],
                            [
                                107.28496093750007,
                                -7.471679687500014
                            ],
                            [
                                106.63144531250006,
                                -7.415527343750028
                            ],
                            [
                                106.51972656250004,
                                -7.053710937499943
                            ],
                            [
                                105.48369140625007,
                                -6.781542968750017
                            ],
                            [
                                105.86826171875006,
                                -6.11640625000004
                            ],
                            [
                                106.07500000000002,
                                -5.914160156249963
                            ],
                            [
                                106.82519531249997,
                                -6.098242187499977
                            ],
                            [
                                107.0462890625,
                                -5.90419921874998
                            ],
                            [
                                107.37392578125005,
                                -6.007617187499989
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                134.74697265625,
                                -5.707031249999957
                            ],
                            [
                                134.75585937500003,
                                -6.170605468749983
                            ],
                            [
                                134.44111328125004,
                                -6.334863281249966
                            ],
                            [
                                134.34130859375003,
                                -5.712890624999986
                            ],
                            [
                                134.74697265625,
                                -5.707031249999957
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                122.64511718750012,
                                -5.26943359374998
                            ],
                            [
                                122.39628906250002,
                                -5.069824218749986
                            ],
                            [
                                122.36894531250007,
                                -4.767187499999977
                            ],
                            [
                                122.70195312500002,
                                -4.61865234375
                            ],
                            [
                                122.64511718750012,
                                -5.26943359374998
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                126.86113281250007,
                                -3.087890624999986
                            ],
                            [
                                127.22734375000007,
                                -3.3910156250000085
                            ],
                            [
                                127.22958984375006,
                                -3.6330078125000114
                            ],
                            [
                                126.68632812500007,
                                -3.823632812500037
                            ],
                            [
                                126.21455078125004,
                                -3.6051757812500256
                            ],
                            [
                                126.02646484375012,
                                -3.17050781250002
                            ],
                            [
                                126.86113281250007,
                                -3.087890624999986
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                129.75468750000007,
                                -2.865820312500034
                            ],
                            [
                                130.3791015625001,
                                -2.9893554687499773
                            ],
                            [
                                130.85996093750006,
                                -3.5703125000000284
                            ],
                            [
                                130.805078125,
                                -3.85771484374996
                            ],
                            [
                                129.844140625,
                                -3.3271484374999574
                            ],
                            [
                                129.46767578125005,
                                -3.4532226562499773
                            ],
                            [
                                128.9674804687501,
                                -3.3260742187500085
                            ],
                            [
                                128.41923828125002,
                                -3.416015624999943
                            ],
                            [
                                128.08212890625012,
                                -3.184082031249943
                            ],
                            [
                                128.19853515625002,
                                -2.8659179687499687
                            ],
                            [
                                129.42734375000006,
                                -2.7907226562500114
                            ],
                            [
                                129.75468750000007,
                                -2.865820312500034
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                108.2072265625001,
                                -2.9976562499999773
                            ],
                            [
                                107.61445312500004,
                                -3.209375000000037
                            ],
                            [
                                107.66630859375002,
                                -2.566308593750037
                            ],
                            [
                                108.07441406250004,
                                -2.5969726562499744
                            ],
                            [
                                108.2072265625001,
                                -2.9976562499999773
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                124.96953125000007,
                                -1.70546874999998
                            ],
                            [
                                124.83447265624997,
                                -1.8944335937500227
                            ],
                            [
                                124.41777343750002,
                                -2.0051757812500313
                            ],
                            [
                                124.38085937499997,
                                -1.6874999999999716
                            ],
                            [
                                124.96953125000007,
                                -1.70546874999998
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                135.47421875000006,
                                -1.5917968750000142
                            ],
                            [
                                136.20156250000005,
                                -1.6549804687499972
                            ],
                            [
                                136.19257812500004,
                                -1.859179687499946
                            ],
                            [
                                135.47421875000006,
                                -1.5917968750000142
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                106.04570312500002,
                                -1.6694335937500142
                            ],
                            [
                                106.36591796875004,
                                -2.4648437499999716
                            ],
                            [
                                106.67880859375012,
                                -2.7040039062500227
                            ],
                            [
                                106.54677734375005,
                                -3.055566406249966
                            ],
                            [
                                105.99873046875004,
                                -2.8249023437499545
                            ],
                            [
                                105.7858398437501,
                                -2.18134765625004
                            ],
                            [
                                105.13339843750012,
                                -2.0425781250000057
                            ],
                            [
                                105.58544921875003,
                                -1.5267578124999943
                            ],
                            [
                                106.04570312500002,
                                -1.6694335937500142
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                128.1530273437501,
                                -1.66054687499998
                            ],
                            [
                                127.56162109375012,
                                -1.7285156249999716
                            ],
                            [
                                127.74296875000002,
                                -1.3602539062499943
                            ],
                            [
                                128.1530273437501,
                                -1.66054687499998
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                99.16386718750007,
                                -1.7779296875000057
                            ],
                            [
                                98.87431640625007,
                                -1.6636718749999915
                            ],
                            [
                                98.60175781250004,
                                -1.1978515624999488
                            ],
                            [
                                98.86904296875005,
                                -0.9156250000000057
                            ],
                            [
                                99.2672851562501,
                                -1.6277343749999602
                            ],
                            [
                                99.16386718750007,
                                -1.7779296875000057
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                135.38300781250004,
                                -0.6513671875
                            ],
                            [
                                135.89355468749997,
                                -0.7257812499999687
                            ],
                            [
                                135.83876953125,
                                -1.1194335937499602
                            ],
                            [
                                135.38300781250004,
                                -0.6513671875
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                140.97343750000007,
                                -2.6097656250000085
                            ],
                            [
                                140.97353515625,
                                -2.8034179687500256
                            ],
                            [
                                140.97441406250002,
                                -4.836328125000023
                            ],
                            [
                                140.97519531250006,
                                -6.90537109375002
                            ],
                            [
                                140.97617187500012,
                                -9.118750000000006
                            ],
                            [
                                139.93476562500004,
                                -8.101171875000006
                            ],
                            [
                                139.38564453125,
                                -8.189062499999963
                            ],
                            [
                                138.85615234375004,
                                -8.145117187499991
                            ],
                            [
                                139.08798828125012,
                                -7.587207031250017
                            ],
                            [
                                138.74794921875,
                                -7.25146484375
                            ],
                            [
                                138.86455078125007,
                                -6.858398437499943
                            ],
                            [
                                138.43867187500004,
                                -6.343359375000006
                            ],
                            [
                                138.2521484375001,
                                -5.688183593750026
                            ],
                            [
                                137.75908203125007,
                                -5.256152343750017
                            ],
                            [
                                137.08925781250005,
                                -4.924414062500034
                            ],
                            [
                                136.61884765625004,
                                -4.818749999999994
                            ],
                            [
                                135.97968750000004,
                                -4.530859374999963
                            ],
                            [
                                135.19560546875007,
                                -4.450683593749972
                            ],
                            [
                                134.14707031250006,
                                -3.79677734374998
                            ],
                            [
                                133.97382812500004,
                                -3.817968750000034
                            ],
                            [
                                133.65312500000007,
                                -3.3643554687499915
                            ],
                            [
                                133.24873046875004,
                                -4.062304687499989
                            ],
                            [
                                132.96855468750007,
                                -4.094921874999969
                            ],
                            [
                                132.75390625000003,
                                -3.703613281250014
                            ],
                            [
                                132.75136718750005,
                                -3.294628906249997
                            ],
                            [
                                132.34824218750006,
                                -2.97509765625
                            ],
                            [
                                133.19101562500006,
                                -2.43779296874996
                            ],
                            [
                                133.60869140625007,
                                -2.54716796874996
                            ],
                            [
                                133.90488281250012,
                                -2.390917968750003
                            ],
                            [
                                133.92158203125004,
                                -2.1020507812499574
                            ],
                            [
                                132.96279296875005,
                                -2.272558593749963
                            ],
                            [
                                132.30761718749997,
                                -2.24228515625002
                            ],
                            [
                                132.02343749999997,
                                -1.99033203125002
                            ],
                            [
                                131.93037109375004,
                                -1.559667968750034
                            ],
                            [
                                131.29375000000002,
                                -1.3934570312500085
                            ],
                            [
                                131.2572265625,
                                -0.8554687500000142
                            ],
                            [
                                131.80429687500006,
                                -0.7038085937500256
                            ],
                            [
                                132.12841796874997,
                                -0.45410156249995737
                            ],
                            [
                                132.62509765625006,
                                -0.3588867187500142
                            ],
                            [
                                133.47265624999997,
                                -0.726171874999963
                            ],
                            [
                                133.97451171875,
                                -0.7443359375000256
                            ],
                            [
                                134.25957031250007,
                                -1.3629882812500256
                            ],
                            [
                                134.105859375,
                                -1.720996093749946
                            ],
                            [
                                134.15566406250005,
                                -2.1952148437500227
                            ],
                            [
                                135.03740234375002,
                                -3.33310546875002
                            ],
                            [
                                135.48662109375002,
                                -3.34511718749998
                            ],
                            [
                                136.24326171875006,
                                -2.5831054687499915
                            ],
                            [
                                136.38994140625002,
                                -2.273339843750037
                            ],
                            [
                                136.84326171875003,
                                -2.19765624999998
                            ],
                            [
                                137.1234375,
                                -1.840917968749963
                            ],
                            [
                                137.80625000000012,
                                -1.4832031250000028
                            ],
                            [
                                138.6498046875,
                                -1.79111328125002
                            ],
                            [
                                139.78955078125003,
                                -2.34824218750002
                            ],
                            [
                                140.62255859374997,
                                -2.44580078125
                            ],
                            [
                                140.97343750000007,
                                -2.6097656250000085
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                97.48154296875006,
                                1.4650878906249716
                            ],
                            [
                                97.93193359375002,
                                0.9739257812500028
                            ],
                            [
                                97.87646484374997,
                                0.6283203125000227
                            ],
                            [
                                97.40537109375012,
                                0.9469726562500256
                            ],
                            [
                                97.24423828125006,
                                1.4236328124999602
                            ],
                            [
                                97.48154296875006,
                                1.4650878906249716
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                124.88886718750004,
                                0.9953125000000114
                            ],
                            [
                                124.42753906250002,
                                0.47060546875005116
                            ],
                            [
                                123.75380859375,
                                0.3055175781249915
                            ],
                            [
                                123.26542968750007,
                                0.3266113281250256
                            ],
                            [
                                123.08251953125003,
                                0.48583984375004263
                            ],
                            [
                                121.8419921875001,
                                0.4365722656250455
                            ],
                            [
                                121.42578125000003,
                                0.49482421875001137
                            ],
                            [
                                120.34902343750005,
                                0.44921875000004263
                            ],
                            [
                                120.01328125000012,
                                -0.19619140624999432
                            ],
                            [
                                120.09746093750002,
                                -0.6499023437499716
                            ],
                            [
                                120.51757812499997,
                                -1.0394531250000085
                            ],
                            [
                                120.66738281250005,
                                -1.3701171874999716
                            ],
                            [
                                121.03369140624997,
                                -1.406542968750017
                            ],
                            [
                                121.51933593750007,
                                -0.8555664062500341
                            ],
                            [
                                121.96962890625005,
                                -0.9333007812499687
                            ],
                            [
                                122.27998046875004,
                                -0.7570312500000256
                            ],
                            [
                                122.88876953125006,
                                -0.7551757812500028
                            ],
                            [
                                122.50664062500002,
                                -1.3478515624999545
                            ],
                            [
                                121.71875000000003,
                                -1.8627929687499716
                            ],
                            [
                                121.34882812500004,
                                -1.9459960937499545
                            ],
                            [
                                121.84824218750012,
                                -2.331542968749986
                            ],
                            [
                                122.39902343750006,
                                -3.200878906249997
                            ],
                            [
                                122.25292968749997,
                                -3.620410156250017
                            ],
                            [
                                122.87734375,
                                -4.109082031250011
                            ],
                            [
                                122.89736328125005,
                                -4.349121093749972
                            ],
                            [
                                122.20712890625012,
                                -4.49638671874996
                            ],
                            [
                                121.91699218749997,
                                -4.847949218749989
                            ],
                            [
                                121.48652343750004,
                                -4.581054687499972
                            ],
                            [
                                121.61806640625,
                                -4.092675781249952
                            ],
                            [
                                120.9142578125001,
                                -3.5557617187499915
                            ],
                            [
                                121.05429687500012,
                                -3.167089843749949
                            ],
                            [
                                121.0521484375,
                                -2.7516601562499545
                            ],
                            [
                                120.65361328125002,
                                -2.6675781249999773
                            ],
                            [
                                120.25410156250004,
                                -3.052832031250034
                            ],
                            [
                                120.43662109375012,
                                -3.70732421874996
                            ],
                            [
                                120.36250000000004,
                                -4.08574218749996
                            ],
                            [
                                120.42011718750004,
                                -4.617382812500011
                            ],
                            [
                                120.27929687499997,
                                -5.146093749999977
                            ],
                            [
                                120.31162109375006,
                                -5.541601562500006
                            ],
                            [
                                119.71728515625003,
                                -5.693359375000014
                            ],
                            [
                                119.3761718750001,
                                -5.424804687500014
                            ],
                            [
                                119.61171875,
                                -4.42353515625004
                            ],
                            [
                                119.62363281250006,
                                -4.034374999999983
                            ],
                            [
                                119.41982421875,
                                -3.47539062499996
                            ],
                            [
                                118.99462890624997,
                                -3.5375976562500284
                            ],
                            [
                                118.81249999999997,
                                -3.1566406249999517
                            ],
                            [
                                118.78369140624997,
                                -2.764746093749963
                            ],
                            [
                                119.09218750000005,
                                -2.482910156250014
                            ],
                            [
                                119.32187500000012,
                                -1.9296875000000142
                            ],
                            [
                                119.308984375,
                                -1.408203125
                            ],
                            [
                                119.7716796875001,
                                -0.48359375000001137
                            ],
                            [
                                119.80927734375004,
                                0.23867187500005116
                            ],
                            [
                                120.26953125000003,
                                0.9708007812499915
                            ],
                            [
                                120.51660156249997,
                                0.8175292968750227
                            ],
                            [
                                120.91210937499997,
                                1.288964843749966
                            ],
                            [
                                121.44003906250006,
                                1.2144042968749744
                            ],
                            [
                                121.59179687499997,
                                1.0679687499999773
                            ],
                            [
                                122.43662109375006,
                                1.0180664062500284
                            ],
                            [
                                122.89248046875005,
                                0.8499999999999801
                            ],
                            [
                                123.27812500000002,
                                0.9280761718749915
                            ],
                            [
                                123.84667968750003,
                                0.8381835937499744
                            ],
                            [
                                124.27363281250004,
                                1.0222656249999886
                            ],
                            [
                                124.98925781249997,
                                1.7010253906249915
                            ],
                            [
                                125.2337890625,
                                1.5022949218750057
                            ],
                            [
                                124.88886718750004,
                                0.9953125000000114
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                127.73271484375007,
                                0.8481445312500426
                            ],
                            [
                                128.16074218750006,
                                1.1578124999999915
                            ],
                            [
                                128.42412109375007,
                                1.5175292968749972
                            ],
                            [
                                128.68837890625,
                                1.572558593750017
                            ],
                            [
                                128.70263671874997,
                                1.1063964843749972
                            ],
                            [
                                128.29882812500003,
                                0.8768066406250199
                            ],
                            [
                                128.69160156250004,
                                0.3603515624999716
                            ],
                            [
                                127.9831054687501,
                                0.4718750000000398
                            ],
                            [
                                127.88740234375004,
                                0.29833984375004263
                            ],
                            [
                                128.04638671875003,
                                -0.7060546874999432
                            ],
                            [
                                127.69160156250004,
                                -0.24189453124998295
                            ],
                            [
                                127.70869140625004,
                                0.2880859374999858
                            ],
                            [
                                127.5553710937501,
                                0.48964843750002274
                            ],
                            [
                                127.4203125,
                                1.2519531249999858
                            ],
                            [
                                127.63173828125,
                                1.8437011718750114
                            ],
                            [
                                128.0109375000001,
                                1.7012207031250313
                            ],
                            [
                                128.01171874999997,
                                1.331738281249983
                            ],
                            [
                                127.65283203124997,
                                1.0138671874999687
                            ],
                            [
                                127.73271484375007,
                                0.8481445312500426
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                96.46367187500002,
                                2.360009765625037
                            ],
                            [
                                95.80859374999997,
                                2.655615234375034
                            ],
                            [
                                95.80625000000012,
                                2.9160156249999716
                            ],
                            [
                                96.41728515625007,
                                2.5151855468750313
                            ],
                            [
                                96.46367187500002,
                                2.360009765625037
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                117.5744140625001,
                                4.17060546875004
                            ],
                            [
                                117.62988281250003,
                                3.6363281249999915
                            ],
                            [
                                117.16640625,
                                3.5919921875000256
                            ],
                            [
                                117.61064453125002,
                                3.0643554687499943
                            ],
                            [
                                118.0666015625001,
                                2.3178222656249687
                            ],
                            [
                                117.8646484375,
                                1.9684082031250085
                            ],
                            [
                                118.08037109375007,
                                1.701855468749983
                            ],
                            [
                                118.98496093750006,
                                0.982128906249983
                            ],
                            [
                                118.53476562500006,
                                0.813525390625017
                            ],
                            [
                                118.19609375000002,
                                0.8743652343749773
                            ],
                            [
                                117.74511718749997,
                                0.7296386718749801
                            ],
                            [
                                117.52216796875004,
                                0.23588867187501705
                            ],
                            [
                                117.46289062500003,
                                -0.32373046874995737
                            ],
                            [
                                117.56249999999997,
                                -0.7708984375000085
                            ],
                            [
                                116.55449218750007,
                                -1.4739257812499744
                            ],
                            [
                                116.42353515625004,
                                -2.0525390624999886
                            ],
                            [
                                116.56542968749997,
                                -2.2997070312499943
                            ],
                            [
                                116.33066406250012,
                                -2.9021484374999744
                            ],
                            [
                                115.95615234375012,
                                -3.595019531250003
                            ],
                            [
                                114.6935546875001,
                                -4.169726562500017
                            ],
                            [
                                114.53613281249997,
                                -3.4944335937500313
                            ],
                            [
                                114.0822265625001,
                                -3.2789062499999915
                            ],
                            [
                                113.79580078125,
                                -3.4562499999999687
                            ],
                            [
                                113.52597656250006,
                                -3.184082031249943
                            ],
                            [
                                112.97148437500002,
                                -3.187109375000034
                            ],
                            [
                                112.60029296875004,
                                -3.4004882812499773
                            ],
                            [
                                112.28496093750002,
                                -3.32099609375004
                            ],
                            [
                                111.82207031250007,
                                -3.5325195312499744
                            ],
                            [
                                111.82304687500007,
                                -3.057226562499949
                            ],
                            [
                                111.367578125,
                                -2.9336914062500057
                            ],
                            [
                                111.04433593750005,
                                -3.0557617187500057
                            ],
                            [
                                110.57402343750007,
                                -2.891406250000003
                            ],
                            [
                                110.2326171875001,
                                -2.9250976562500313
                            ],
                            [
                                110.01923828125004,
                                -1.3988281249999517
                            ],
                            [
                                109.78740234375007,
                                -1.011328124999963
                            ],
                            [
                                109.45380859375004,
                                -0.868749999999963
                            ],
                            [
                                109.160546875,
                                -0.49492187500001705
                            ],
                            [
                                109.2575195312501,
                                0.03115234375005116
                            ],
                            [
                                108.94453125000004,
                                0.35566406249999716
                            ],
                            [
                                108.90585937500006,
                                0.7939453125000142
                            ],
                            [
                                109.07587890625004,
                                1.4958984375000313
                            ],
                            [
                                109.37851562500006,
                                1.922705078125034
                            ],
                            [
                                109.62890625000003,
                                2.027539062499983
                            ],
                            [
                                109.65400390625004,
                                1.6148925781250227
                            ],
                            [
                                110.50576171875005,
                                0.8619628906250227
                            ],
                            [
                                110.93808593750006,
                                1.0173339843750568
                            ],
                            [
                                111.76972656250004,
                                0.9994628906249687
                            ],
                            [
                                112.078515625,
                                1.1433593749999744
                            ],
                            [
                                112.1857421875001,
                                1.4390624999999915
                            ],
                            [
                                112.47617187500006,
                                1.5590820312500284
                            ],
                            [
                                113.6222656250001,
                                1.2359375000000057
                            ],
                            [
                                113.90234375000003,
                                1.4342773437499972
                            ],
                            [
                                114.54589843750003,
                                1.467138671875034
                            ],
                            [
                                114.83056640625003,
                                1.9800292968749886
                            ],
                            [
                                114.78642578125002,
                                2.250488281250014
                            ],
                            [
                                115.1791015625,
                                2.5231933593749716
                            ],
                            [
                                115.086328125,
                                2.8411132812499886
                            ],
                            [
                                115.45439453125002,
                                3.0343261718750085
                            ],
                            [
                                115.56845703125006,
                                3.9387695312499744
                            ],
                            [
                                115.89619140625004,
                                4.348681640624989
                            ],
                            [
                                117.10058593750003,
                                4.337060546875023
                            ],
                            [
                                117.5744140625001,
                                4.17060546875004
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                96.49257812500005,
                                5.2293457031249915
                            ],
                            [
                                97.54716796875002,
                                5.205859375000003
                            ],
                            [
                                98.2484375,
                                4.41455078125
                            ],
                            [
                                98.3073242187501,
                                4.09287109375002
                            ],
                            [
                                99.73232421875005,
                                3.1830566406250256
                            ],
                            [
                                100.45703124999997,
                                2.2574218750000057
                            ],
                            [
                                100.68525390625004,
                                2.120068359374997
                            ],
                            [
                                101.04619140625002,
                                2.2574707031250227
                            ],
                            [
                                101.47666015625006,
                                1.693066406250054
                            ],
                            [
                                101.78476562500012,
                                1.621386718750017
                            ],
                            [
                                102.15722656249997,
                                1.2588867187499773
                            ],
                            [
                                102.46923828124997,
                                0.7792968750000568
                            ],
                            [
                                102.84941406250002,
                                0.7154785156250227
                            ],
                            [
                                103.06650390625012,
                                0.4919921874999602
                            ],
                            [
                                103.47890625000005,
                                0.4801757812500398
                            ],
                            [
                                103.78671875000012,
                                0.046972656249991473
                            ],
                            [
                                103.42851562500007,
                                -0.1917968749999801
                            ],
                            [
                                103.43115234374997,
                                -0.5335937499999801
                            ],
                            [
                                103.72109375,
                                -0.8867187499999858
                            ],
                            [
                                104.36054687500004,
                                -1.0383789062499744
                            ],
                            [
                                104.56875,
                                -1.9217773437499943
                            ],
                            [
                                104.84521484375003,
                                -2.0929687499999687
                            ],
                            [
                                104.66845703124997,
                                -2.3855468749999744
                            ],
                            [
                                105.39697265624997,
                                -2.380175781249946
                            ],
                            [
                                105.58203124999997,
                                -2.4919921874999886
                            ],
                            [
                                106.0443359375,
                                -3.1062499999999886
                            ],
                            [
                                105.84374999999997,
                                -3.61367187499998
                            ],
                            [
                                105.88720703124997,
                                -5.009570312499974
                            ],
                            [
                                105.81611328125004,
                                -5.67656249999996
                            ],
                            [
                                105.6185546875,
                                -5.799609374999989
                            ],
                            [
                                105.34941406250007,
                                -5.549511718750011
                            ],
                            [
                                105.08134765625002,
                                -5.74550781249998
                            ],
                            [
                                104.63955078125005,
                                -5.520410156250037
                            ],
                            [
                                104.60156249999997,
                                -5.90458984374996
                            ],
                            [
                                103.8314453125,
                                -5.079589843750028
                            ],
                            [
                                102.91894531249997,
                                -4.470703124999972
                            ],
                            [
                                102.53769531250006,
                                -4.152148437499989
                            ],
                            [
                                102.12753906250006,
                                -3.599218749999963
                            ],
                            [
                                101.57861328124997,
                                -3.166992187500014
                            ],
                            [
                                100.848046875,
                                -2.143945312499966
                            ],
                            [
                                100.85527343750002,
                                -1.9341796874999488
                            ],
                            [
                                100.48652343750004,
                                -1.2991210937499744
                            ],
                            [
                                100.30820312500006,
                                -0.82666015625
                            ],
                            [
                                99.59765624999997,
                                0.10244140625000853
                            ],
                            [
                                99.15917968749997,
                                0.35175781249999716
                            ],
                            [
                                98.79638671874997,
                                1.4946289062500426
                            ],
                            [
                                98.56425781250002,
                                1.902148437500017
                            ],
                            [
                                97.70078125000006,
                                2.3585449218750085
                            ],
                            [
                                97.59082031249997,
                                2.846582031250037
                            ],
                            [
                                97.3913085937501,
                                2.9752929687499687
                            ],
                            [
                                96.9689453125001,
                                3.5751464843749687
                            ],
                            [
                                96.44472656250005,
                                3.81630859374998
                            ],
                            [
                                95.49472656250006,
                                4.761376953125051
                            ],
                            [
                                95.20664062500006,
                                5.284033203125034
                            ],
                            [
                                95.39609375000012,
                                5.628808593750023
                            ],
                            [
                                95.84130859374997,
                                5.514501953125006
                            ],
                            [
                                96.13330078125003,
                                5.2942871093749915
                            ],
                            [
                                96.49257812500005,
                                5.2293457031249915
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Indonesia',
                'childNum': 30
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            78.71972656250009,
                            31.887646484374983
                        ],
                        [
                            78.69345703125006,
                            31.740380859374994
                        ],
                        [
                            78.7550781250001,
                            31.55029296875
                        ],
                        [
                            78.74355468750005,
                            31.323779296875017
                        ],
                        [
                            79.10712890625004,
                            31.402636718750102
                        ],
                        [
                            79.66425781250004,
                            30.965234374999994
                        ],
                        [
                            79.92451171875004,
                            30.888769531250034
                        ],
                        [
                            80.20712890625006,
                            30.683740234375023
                        ],
                        [
                            80.19121093750002,
                            30.56840820312496
                        ],
                        [
                            80.87353515625003,
                            30.290576171875045
                        ],
                        [
                            80.98544921875006,
                            30.23710937499999
                        ],
                        [
                            80.54902343750015,
                            29.899804687499994
                        ],
                        [
                            80.40185546875003,
                            29.730273437500102
                        ],
                        [
                            80.31689453125014,
                            29.572070312500017
                        ],
                        [
                            80.25488281250009,
                            29.423339843750114
                        ],
                        [
                            80.25595703125006,
                            29.318017578125136
                        ],
                        [
                            80.23300781250006,
                            29.194628906250045
                        ],
                        [
                            80.16953125000012,
                            29.124316406250102
                        ],
                        [
                            80.13046875000006,
                            29.100390625000045
                        ],
                        [
                            80.08457031249995,
                            28.994189453125074
                        ],
                        [
                            80.05166015625,
                            28.870312500000068
                        ],
                        [
                            80.07070312500005,
                            28.830175781250063
                        ],
                        [
                            80.58701171875006,
                            28.64960937500004
                        ],
                        [
                            81.85263671875018,
                            27.867089843750136
                        ],
                        [
                            81.89687500000011,
                            27.87446289062504
                        ],
                        [
                            81.94521484375005,
                            27.89926757812495
                        ],
                        [
                            81.98769531250016,
                            27.91376953125004
                        ],
                        [
                            82.03701171875,
                            27.90058593750004
                        ],
                        [
                            82.11191406250006,
                            27.86494140625004
                        ],
                        [
                            82.28769531250018,
                            27.756542968749983
                        ],
                        [
                            82.45136718750004,
                            27.671826171874955
                        ],
                        [
                            82.62988281249997,
                            27.687060546875045
                        ],
                        [
                            82.67734375000006,
                            27.67343749999995
                        ],
                        [
                            82.71083984375005,
                            27.596679687500114
                        ],
                        [
                            82.73339843750003,
                            27.518994140625097
                        ],
                        [
                            83.28974609375004,
                            27.370996093750136
                        ],
                        [
                            84.09101562499993,
                            27.491357421875136
                        ],
                        [
                            84.22978515625007,
                            27.427832031250006
                        ],
                        [
                            84.48085937500005,
                            27.348193359375102
                        ],
                        [
                            84.61015625000002,
                            27.298681640624977
                        ],
                        [
                            84.64072265625012,
                            27.249853515624977
                        ],
                        [
                            84.65478515625014,
                            27.20366210937499
                        ],
                        [
                            84.68535156250013,
                            27.041015625000057
                        ],
                        [
                            85.29296875000009,
                            26.741015625000045
                        ],
                        [
                            86.36611328125,
                            26.57441406250004
                        ],
                        [
                            86.70136718750015,
                            26.435058593750057
                        ],
                        [
                            87.01640625000002,
                            26.555419921875085
                        ],
                        [
                            87.2874023437499,
                            26.360302734375125
                        ],
                        [
                            87.41357421875014,
                            26.42294921875009
                        ],
                        [
                            87.63339843750006,
                            26.39912109375004
                        ],
                        [
                            87.84921875000006,
                            26.43691406250008
                        ],
                        [
                            87.99511718750014,
                            26.38237304687499
                        ],
                        [
                            88.02695312500023,
                            26.395019531250085
                        ],
                        [
                            88.05488281250004,
                            26.43002929687492
                        ],
                        [
                            88.11152343750004,
                            26.58642578125
                        ],
                        [
                            88.1615234375,
                            26.724804687500125
                        ],
                        [
                            88.15722656250009,
                            26.807324218750068
                        ],
                        [
                            88.1110351562501,
                            26.928466796875057
                        ],
                        [
                            87.99316406250009,
                            27.086083984374994
                        ],
                        [
                            87.98437499999997,
                            27.133935546874994
                        ],
                        [
                            88.02412109375015,
                            27.40888671875004
                        ],
                        [
                            88.14697265625014,
                            27.749218750000097
                        ],
                        [
                            88.10898437499995,
                            27.93300781250005
                        ],
                        [
                            88.57792968750002,
                            28.093359375000034
                        ],
                        [
                            88.80371093750003,
                            28.006933593750034
                        ],
                        [
                            88.74902343749997,
                            27.521875000000136
                        ],
                        [
                            88.7648437500001,
                            27.429882812500068
                        ],
                        [
                            88.83251953125003,
                            27.362841796875074
                        ],
                        [
                            88.89140625000002,
                            27.316064453125136
                        ],
                        [
                            88.88164062500007,
                            27.29746093750009
                        ],
                        [
                            88.76035156250006,
                            27.21811523437509
                        ],
                        [
                            88.73876953125009,
                            27.175585937499932
                        ],
                        [
                            88.85761718750015,
                            26.961474609375017
                        ],
                        [
                            89.14824218750002,
                            26.816162109375085
                        ],
                        [
                            89.33212890625018,
                            26.848632812500114
                        ],
                        [
                            89.60917968750013,
                            26.762207031250114
                        ],
                        [
                            89.60996093750012,
                            26.719433593750097
                        ],
                        [
                            89.71093750000009,
                            26.713916015625045
                        ],
                        [
                            89.76386718750004,
                            26.701562499999994
                        ],
                        [
                            89.94316406250013,
                            26.723925781249932
                        ],
                        [
                            90.12294921875011,
                            26.754589843749983
                        ],
                        [
                            90.20605468749997,
                            26.847509765625063
                        ],
                        [
                            90.34589843750004,
                            26.890332031250097
                        ],
                        [
                            90.73964843750005,
                            26.771679687500068
                        ],
                        [
                            91.67158203124993,
                            26.80200195312503
                        ],
                        [
                            91.84208984375013,
                            26.852978515625125
                        ],
                        [
                            91.94375000000005,
                            26.860839843750114
                        ],
                        [
                            91.99833984375013,
                            26.85498046875
                        ],
                        [
                            92.04970703125016,
                            26.87485351562495
                        ],
                        [
                            92.07343750000015,
                            26.914843750000102
                        ],
                        [
                            91.99228515625018,
                            27.09990234374999
                        ],
                        [
                            92.0833984375,
                            27.29062499999992
                        ],
                        [
                            92.04492187499997,
                            27.364697265625097
                        ],
                        [
                            91.99082031249995,
                            27.450195312500114
                        ],
                        [
                            91.59472656250009,
                            27.55766601562499
                        ],
                        [
                            91.63193359375012,
                            27.759960937499983
                        ],
                        [
                            92.41484375000002,
                            27.824609374999994
                        ],
                        [
                            92.65253906250004,
                            28.093359375000034
                        ],
                        [
                            92.70185546875004,
                            28.147119140624994
                        ],
                        [
                            93.36054687500004,
                            28.654052734375057
                        ],
                        [
                            93.76074218750003,
                            28.729785156250017
                        ],
                        [
                            94.01328125000006,
                            28.90751953124999
                        ],
                        [
                            94.46806640625007,
                            29.216210937499994
                        ],
                        [
                            94.99882812500002,
                            29.14916992187503
                        ],
                        [
                            95.27910156250002,
                            29.04956054687497
                        ],
                        [
                            95.42021484375007,
                            29.054296875000063
                        ],
                        [
                            95.49375000000012,
                            29.137011718750045
                        ],
                        [
                            95.51582031250015,
                            29.20634765624996
                        ],
                        [
                            95.71035156250011,
                            29.313818359375006
                        ],
                        [
                            95.88505859375007,
                            29.390917968750074
                        ],
                        [
                            96.03535156250015,
                            29.447167968749994
                        ],
                        [
                            96.07958984375009,
                            29.424121093750074
                        ],
                        [
                            96.12851562500012,
                            29.381396484375074
                        ],
                        [
                            96.23496093750006,
                            29.245800781249983
                        ],
                        [
                            96.18085937500004,
                            29.11767578125
                        ],
                        [
                            96.14140625000007,
                            28.963476562500034
                        ],
                        [
                            96.58085937500007,
                            28.763671875
                        ],
                        [
                            96.32988281250002,
                            28.496826171875
                        ],
                        [
                            96.3890625,
                            28.36791992187503
                        ],
                        [
                            96.65283203125003,
                            28.44975585937499
                        ],
                        [
                            97.07539062500004,
                            28.368945312500045
                        ],
                        [
                            97.14511718750012,
                            28.340332031250085
                        ],
                        [
                            97.28945312500005,
                            28.23681640624997
                        ],
                        [
                            97.32246093750015,
                            28.21796875000004
                        ],
                        [
                            97.30273437499997,
                            28.08598632812496
                        ],
                        [
                            97.34355468750002,
                            27.982324218749994
                        ],
                        [
                            97.04970703125005,
                            27.760009765625
                        ],
                        [
                            96.96279296875,
                            27.698291015625017
                        ],
                        [
                            96.79785156249997,
                            27.29619140624999
                        ],
                        [
                            96.19082031250005,
                            27.26127929687499
                        ],
                        [
                            95.20146484375007,
                            26.641406250000017
                        ],
                        [
                            95.05976562500015,
                            26.473974609375006
                        ],
                        [
                            95.06894531250006,
                            26.191113281250097
                        ],
                        [
                            95.10839843750014,
                            26.091406250000034
                        ],
                        [
                            95.12929687500011,
                            26.070410156250034
                        ],
                        [
                            95.13242187500006,
                            26.041259765624943
                        ],
                        [
                            94.99199218750002,
                            25.77045898437504
                        ],
                        [
                            94.66777343750007,
                            25.458886718749966
                        ],
                        [
                            94.70371093750012,
                            25.097851562499955
                        ],
                        [
                            94.49316406250003,
                            24.637646484374983
                        ],
                        [
                            94.37724609375002,
                            24.473730468750006
                        ],
                        [
                            94.07480468750006,
                            23.8720703125
                        ],
                        [
                            93.68339843750007,
                            24.00654296875004
                        ],
                        [
                            93.45214843750003,
                            23.987402343750034
                        ],
                        [
                            93.32626953125006,
                            24.064208984375057
                        ],
                        [
                            93.41494140625011,
                            23.68208007812501
                        ],
                        [
                            93.36601562500007,
                            23.132519531249955
                        ],
                        [
                            93.34941406250007,
                            23.08496093750003
                        ],
                        [
                            93.20390625000002,
                            23.03701171875005
                        ],
                        [
                            93.07871093750018,
                            22.718212890625097
                        ],
                        [
                            93.16201171875,
                            22.360205078125006
                        ],
                        [
                            93.07060546875002,
                            22.20942382812501
                        ],
                        [
                            92.77138671875,
                            22.104785156250017
                        ],
                        [
                            92.5612304687501,
                            22.04804687500001
                        ],
                        [
                            92.49140625000004,
                            22.685400390625006
                        ],
                        [
                            92.46445312500006,
                            22.734423828125045
                        ],
                        [
                            92.36162109375002,
                            22.929003906250074
                        ],
                        [
                            92.33378906250002,
                            23.242382812499955
                        ],
                        [
                            92.04404296875006,
                            23.677783203125017
                        ],
                        [
                            91.97851562500003,
                            23.691992187500063
                        ],
                        [
                            91.92958984375011,
                            23.685986328125097
                        ],
                        [
                            91.92949218750019,
                            23.598242187499977
                        ],
                        [
                            91.93789062500011,
                            23.504687500000102
                        ],
                        [
                            91.75419921875013,
                            23.28730468750004
                        ],
                        [
                            91.75097656250003,
                            23.053515625000017
                        ],
                        [
                            91.43623046875004,
                            23.19990234375001
                        ],
                        [
                            91.35937500000003,
                            23.06835937500003
                        ],
                        [
                            91.16044921875019,
                            23.660644531250085
                        ],
                        [
                            91.35019531250012,
                            24.06049804687501
                        ],
                        [
                            91.72656250000003,
                            24.20507812499997
                        ],
                        [
                            92.06416015625004,
                            24.374365234375006
                        ],
                        [
                            92.11748046875002,
                            24.493945312500017
                        ],
                        [
                            92.22666015625012,
                            24.77099609374997
                        ],
                        [
                            92.38496093750004,
                            24.848779296875023
                        ],
                        [
                            92.04970703125016,
                            25.16948242187499
                        ],
                        [
                            90.61308593750002,
                            25.16772460937497
                        ],
                        [
                            90.11962890625003,
                            25.21997070312497
                        ],
                        [
                            89.86630859375012,
                            25.293164062499955
                        ],
                        [
                            89.81406250000006,
                            25.305371093749955
                        ],
                        [
                            89.80087890625012,
                            25.33613281250001
                        ],
                        [
                            89.82490234375004,
                            25.560156250000006
                        ],
                        [
                            89.82294921875015,
                            25.94140625000003
                        ],
                        [
                            89.57275390625003,
                            26.13232421875003
                        ],
                        [
                            89.10830078125005,
                            26.202246093750063
                        ],
                        [
                            89.01865234375012,
                            26.410253906249977
                        ],
                        [
                            88.95195312500002,
                            26.412109375
                        ],
                        [
                            88.97041015625004,
                            26.250878906250023
                        ],
                        [
                            88.94072265625002,
                            26.24536132812497
                        ],
                        [
                            88.68281250000004,
                            26.291699218749983
                        ],
                        [
                            88.68066406250009,
                            26.35297851562504
                        ],
                        [
                            88.51826171875004,
                            26.51777343750004
                        ],
                        [
                            88.41816406250015,
                            26.57153320312497
                        ],
                        [
                            88.36992187500002,
                            26.56411132812508
                        ],
                        [
                            88.35146484375005,
                            26.482568359374966
                        ],
                        [
                            88.38623046875003,
                            26.471533203125034
                        ],
                        [
                            88.44042968749997,
                            26.369482421875034
                        ],
                        [
                            88.33398437499997,
                            26.257519531249955
                        ],
                        [
                            88.1066406250001,
                            25.841113281250045
                        ],
                        [
                            88.14746093749997,
                            25.811425781250023
                        ],
                        [
                            88.76914062500006,
                            25.490478515625
                        ],
                        [
                            88.85478515625002,
                            25.333544921875017
                        ],
                        [
                            88.94414062500002,
                            25.290771484375
                        ],
                        [
                            88.92978515625012,
                            25.222998046875063
                        ],
                        [
                            88.57382812500006,
                            25.187890624999994
                        ],
                        [
                            88.31337890625011,
                            24.8818359375
                        ],
                        [
                            88.14980468750011,
                            24.91464843749995
                        ],
                        [
                            88.04511718750015,
                            24.71303710937508
                        ],
                        [
                            88.03027343750009,
                            24.664453125000136
                        ],
                        [
                            88.02343750000003,
                            24.627832031250136
                        ],
                        [
                            88.07910156250009,
                            24.549902343750063
                        ],
                        [
                            88.14550781250003,
                            24.485791015624955
                        ],
                        [
                            88.22500000000005,
                            24.460644531249983
                        ],
                        [
                            88.33750000000006,
                            24.45385742187503
                        ],
                        [
                            88.49853515625003,
                            24.34663085937504
                        ],
                        [
                            88.64228515625015,
                            24.325976562500102
                        ],
                        [
                            88.72353515625011,
                            24.27490234375
                        ],
                        [
                            88.7335937500001,
                            24.230908203125097
                        ],
                        [
                            88.72656250000009,
                            24.18623046875004
                        ],
                        [
                            88.71376953125016,
                            24.069628906250102
                        ],
                        [
                            88.69980468750006,
                            24.00253906249992
                        ],
                        [
                            88.56738281250009,
                            23.674414062500034
                        ],
                        [
                            88.69765625,
                            23.493017578125034
                        ],
                        [
                            88.72441406250002,
                            23.254980468750034
                        ],
                        [
                            88.89970703125002,
                            22.843505859375057
                        ],
                        [
                            88.92070312500002,
                            22.632031249999955
                        ],
                        [
                            89.0500000000001,
                            22.274609374999983
                        ],
                        [
                            89.05166015625,
                            21.654101562500045
                        ],
                        [
                            88.25371093750002,
                            21.622314453124943
                        ],
                        [
                            87.94843749999993,
                            21.825439453124943
                        ],
                        [
                            87.10068359375006,
                            21.500781250000074
                        ],
                        [
                            86.85957031250004,
                            21.23671875000008
                        ],
                        [
                            86.97548828125005,
                            20.70014648437501
                        ],
                        [
                            86.75039062500011,
                            20.313232421875057
                        ],
                        [
                            86.27949218750021,
                            19.919433593749943
                        ],
                        [
                            85.57500000000002,
                            19.69291992187499
                        ],
                        [
                            84.77099609375009,
                            19.125390625000023
                        ],
                        [
                            84.10410156250018,
                            18.29267578125001
                        ],
                        [
                            83.65429687499997,
                            18.069873046875102
                        ],
                        [
                            83.19833984375006,
                            17.608984374999977
                        ],
                        [
                            82.35957031250004,
                            17.09619140624997
                        ],
                        [
                            82.25878906250014,
                            16.55986328124996
                        ],
                        [
                            81.76191406250015,
                            16.32949218750008
                        ],
                        [
                            81.23857421875013,
                            16.263964843750074
                        ],
                        [
                            80.97871093750004,
                            15.758349609375074
                        ],
                        [
                            80.64658203125006,
                            15.895019531250028
                        ],
                        [
                            80.29345703125014,
                            15.710742187499989
                        ],
                        [
                            80.0534179687501,
                            15.074023437499932
                        ],
                        [
                            80.16542968750016,
                            14.577832031250097
                        ],
                        [
                            80.11171875000005,
                            14.212207031250045
                        ],
                        [
                            80.34238281250006,
                            13.361328125000071
                        ],
                        [
                            80.22910156250018,
                            12.690332031249966
                        ],
                        [
                            79.85849609375018,
                            11.988769531250043
                        ],
                        [
                            79.75410156250004,
                            11.575292968750006
                        ],
                        [
                            79.84863281250009,
                            11.196875000000006
                        ],
                        [
                            79.83818359375002,
                            10.322558593750045
                        ],
                        [
                            79.39052734375005,
                            10.305957031250045
                        ],
                        [
                            78.99628906249993,
                            9.683105468749986
                        ],
                        [
                            78.97958984375018,
                            9.268554687500085
                        ],
                        [
                            78.42148437500006,
                            9.105029296874989
                        ],
                        [
                            78.19248046874995,
                            8.890869140625057
                        ],
                        [
                            78.06015625000006,
                            8.384570312499932
                        ],
                        [
                            77.51757812500003,
                            8.078320312500068
                        ],
                        [
                            76.96689453125012,
                            8.407275390624946
                        ],
                        [
                            76.5534179687501,
                            8.902783203124997
                        ],
                        [
                            76.32460937500016,
                            9.452099609374997
                        ],
                        [
                            76.34648437500002,
                            9.922119140625
                        ],
                        [
                            75.92255859375015,
                            10.784082031250108
                        ],
                        [
                            75.72382812500015,
                            11.361767578125026
                        ],
                        [
                            75.1966796875,
                            12.057519531250009
                        ],
                        [
                            74.77050781250009,
                            13.077343750000125
                        ],
                        [
                            74.67089843749997,
                            13.667626953125122
                        ],
                        [
                            74.38222656250005,
                            14.494726562500048
                        ],
                        [
                            73.94921875000014,
                            15.074755859375088
                        ],
                        [
                            73.93193359375013,
                            15.39697265625
                        ],
                        [
                            73.47607421875003,
                            16.05424804687496
                        ],
                        [
                            73.33759765625004,
                            16.459863281249994
                        ],
                        [
                            73.15605468750019,
                            17.62192382812495
                        ],
                        [
                            72.87089843750019,
                            18.683056640624955
                        ],
                        [
                            72.66777343750019,
                            19.83095703125005
                        ],
                        [
                            72.88115234375013,
                            20.56318359375001
                        ],
                        [
                            72.81386718750011,
                            21.117187500000085
                        ],
                        [
                            72.54306640625,
                            21.69658203124999
                        ],
                        [
                            72.59013671875019,
                            22.27812499999999
                        ],
                        [
                            72.33261718750012,
                            22.270214843749955
                        ],
                        [
                            72.03720703125006,
                            21.82304687499999
                        ],
                        [
                            72.25400390625006,
                            21.531005859375
                        ],
                        [
                            72.01523437500012,
                            21.155712890625097
                        ],
                        [
                            71.0246093750001,
                            20.73886718750009
                        ],
                        [
                            70.71933593750006,
                            20.740429687500068
                        ],
                        [
                            70.12734375,
                            21.094677734375097
                        ],
                        [
                            69.00878906250009,
                            22.196777343750114
                        ],
                        [
                            70.17724609375014,
                            22.57275390624997
                        ],
                        [
                            70.2511718750001,
                            22.970898437500068
                        ],
                        [
                            69.66464843750006,
                            22.759082031250074
                        ],
                        [
                            69.23593749999995,
                            22.848535156250023
                        ],
                        [
                            68.64072265625006,
                            23.189941406250114
                        ],
                        [
                            68.16503906250009,
                            23.857324218749994
                        ],
                        [
                            68.28251953125013,
                            23.927978515625
                        ],
                        [
                            68.38125000000016,
                            23.950878906250068
                        ],
                        [
                            68.48867187500011,
                            23.96723632812501
                        ],
                        [
                            68.5866210937501,
                            23.966601562500074
                        ],
                        [
                            68.72412109375003,
                            23.964697265625034
                        ],
                        [
                            68.72812500000012,
                            24.265625000000057
                        ],
                        [
                            68.73964843750016,
                            24.291992187500085
                        ],
                        [
                            68.75898437499993,
                            24.307226562500006
                        ],
                        [
                            68.78115234375011,
                            24.313720703125085
                        ],
                        [
                            68.80000000000004,
                            24.30908203125003
                        ],
                        [
                            68.82832031250004,
                            24.26401367187509
                        ],
                        [
                            68.86347656250015,
                            24.26650390625005
                        ],
                        [
                            68.90078125000011,
                            24.29243164062501
                        ],
                        [
                            68.98457031250015,
                            24.273095703124966
                        ],
                        [
                            69.11953125000011,
                            24.26865234374995
                        ],
                        [
                            69.23505859374993,
                            24.268261718750068
                        ],
                        [
                            69.44345703124995,
                            24.275390625000085
                        ],
                        [
                            69.55917968750006,
                            24.273095703124966
                        ],
                        [
                            69.63417968750016,
                            24.225195312499977
                        ],
                        [
                            69.71621093750016,
                            24.172607421875114
                        ],
                        [
                            70.54677734375,
                            24.418310546875063
                        ],
                        [
                            70.71630859375009,
                            24.237988281250097
                        ],
                        [
                            70.7672851562501,
                            24.245410156250017
                        ],
                        [
                            70.80507812500011,
                            24.26196289062503
                        ],
                        [
                            70.88623046875014,
                            24.34374999999997
                        ],
                        [
                            70.92812500000016,
                            24.362353515625045
                        ],
                        [
                            70.98281250000011,
                            24.361035156250125
                        ],
                        [
                            71.04404296875006,
                            24.400097656250097
                        ],
                        [
                            71.04531250000005,
                            24.42998046874996
                        ],
                        [
                            71.00625,
                            24.444335937500057
                        ],
                        [
                            70.97324218750012,
                            24.48740234374995
                        ],
                        [
                            70.97636718750013,
                            24.618750000000063
                        ],
                        [
                            71.00234375000016,
                            24.65390624999992
                        ],
                        [
                            71.04785156250003,
                            24.687744140625085
                        ],
                        [
                            71.02070312500021,
                            24.75766601562492
                        ],
                        [
                            70.95087890625015,
                            24.89160156250003
                        ],
                        [
                            70.87773437500019,
                            25.06298828124997
                        ],
                        [
                            70.65205078125004,
                            25.422900390625102
                        ],
                        [
                            70.10019531250006,
                            25.91005859375005
                        ],
                        [
                            70.14921875000002,
                            26.347558593749994
                        ],
                        [
                            70.05937500000002,
                            26.578759765625023
                        ],
                        [
                            69.47001953125002,
                            26.804443359375
                        ],
                        [
                            69.56796875,
                            27.174609375000102
                        ],
                        [
                            69.89628906250007,
                            27.473632812500085
                        ],
                        [
                            70.04980468750009,
                            27.694726562500023
                        ],
                        [
                            70.14453125000003,
                            27.849023437499994
                        ],
                        [
                            70.19394531250006,
                            27.89487304687492
                        ],
                        [
                            70.24433593750004,
                            27.934130859375102
                        ],
                        [
                            70.4037109375,
                            28.025048828124994
                        ],
                        [
                            70.48857421875013,
                            28.023144531250125
                        ],
                        [
                            70.62910156250015,
                            27.937451171875068
                        ],
                        [
                            70.64912109375015,
                            27.83535156250005
                        ],
                        [
                            70.73740234374995,
                            27.729003906250057
                        ],
                        [
                            70.79794921875012,
                            27.709619140625023
                        ],
                        [
                            70.87490234375016,
                            27.71445312499995
                        ],
                        [
                            71.54296875000003,
                            27.869873046875
                        ],
                        [
                            71.71669921875005,
                            27.91508789062496
                        ],
                        [
                            71.8703125000001,
                            27.962500000000034
                        ],
                        [
                            71.88886718750004,
                            28.04746093749992
                        ],
                        [
                            71.94804687500002,
                            28.177294921875102
                        ],
                        [
                            72.34189453125006,
                            28.751904296875097
                        ],
                        [
                            72.90332031250003,
                            29.02875976562501
                        ],
                        [
                            73.38164062500013,
                            29.934374999999932
                        ],
                        [
                            73.46748046875004,
                            29.971679687500085
                        ],
                        [
                            73.8091796875,
                            30.093359375000006
                        ],
                        [
                            73.89931640625,
                            30.435351562500045
                        ],
                        [
                            74.00898437500004,
                            30.519677734374994
                        ],
                        [
                            74.21562500000013,
                            30.768994140624983
                        ],
                        [
                            74.33935546875003,
                            30.893554687499943
                        ],
                        [
                            74.38037109375003,
                            30.89340820312509
                        ],
                        [
                            74.50976562500009,
                            30.959667968750097
                        ],
                        [
                            74.63281250000014,
                            31.034667968750114
                        ],
                        [
                            74.62578125000002,
                            31.068750000000108
                        ],
                        [
                            74.61035156250009,
                            31.112841796875045
                        ],
                        [
                            74.51767578125012,
                            31.185595703124932
                        ],
                        [
                            74.53496093750007,
                            31.261376953125108
                        ],
                        [
                            74.59394531249993,
                            31.465380859375102
                        ],
                        [
                            74.58183593750013,
                            31.523925781250114
                        ],
                        [
                            74.50996093750015,
                            31.712939453125074
                        ],
                        [
                            74.73945312500015,
                            31.948828125000006
                        ],
                        [
                            75.07148437500015,
                            32.08935546875003
                        ],
                        [
                            75.13876953125,
                            32.10478515624999
                        ],
                        [
                            75.25410156250004,
                            32.140332031250125
                        ],
                        [
                            75.33349609374997,
                            32.279199218749994
                        ],
                        [
                            74.98730468749997,
                            32.46220703124996
                        ],
                        [
                            74.78886718750013,
                            32.45781249999996
                        ],
                        [
                            74.6857421875001,
                            32.493798828124994
                        ],
                        [
                            74.65781250000006,
                            32.518945312499966
                        ],
                        [
                            74.66328125000004,
                            32.75766601562495
                        ],
                        [
                            74.35458984375012,
                            32.76870117187505
                        ],
                        [
                            74.30361328125005,
                            32.991796875000034
                        ],
                        [
                            73.99423828125006,
                            33.24218750000006
                        ],
                        [
                            74.15,
                            33.506982421874994
                        ],
                        [
                            73.96123046875007,
                            34.653466796874994
                        ],
                        [
                            74.05585937500015,
                            34.68066406250003
                        ],
                        [
                            74.17197265624995,
                            34.72089843750004
                        ],
                        [
                            74.30039062500006,
                            34.76538085937506
                        ],
                        [
                            75.70917968750004,
                            34.50307617187508
                        ],
                        [
                            76.04101562500014,
                            34.66992187499997
                        ],
                        [
                            76.4567382812501,
                            34.756103515625114
                        ],
                        [
                            76.5944335937501,
                            34.73583984375006
                        ],
                        [
                            76.69628906249997,
                            34.78691406249999
                        ],
                        [
                            76.74902343750014,
                            34.84755859375008
                        ],
                        [
                            76.7829101562501,
                            34.90019531249999
                        ],
                        [
                            77.00087890625011,
                            34.99199218750002
                        ],
                        [
                            77.04863281250007,
                            35.109912109375074
                        ],
                        [
                            77.79941406250006,
                            35.49589843750002
                        ],
                        [
                            78.07578125000006,
                            35.13491210937502
                        ],
                        [
                            78.15849609375002,
                            34.94648437499998
                        ],
                        [
                            78.32695312500007,
                            34.60639648437498
                        ],
                        [
                            78.86484375000006,
                            34.39033203125001
                        ],
                        [
                            78.93642578125,
                            34.35195312500002
                        ],
                        [
                            78.72666015625006,
                            34.013378906249955
                        ],
                        [
                            78.78378906250006,
                            33.80878906250004
                        ],
                        [
                            78.86503906250002,
                            33.43110351562501
                        ],
                        [
                            78.94843750000004,
                            33.346533203125006
                        ],
                        [
                            79.11250000000004,
                            33.22626953125001
                        ],
                        [
                            79.13515625000005,
                            33.17192382812496
                        ],
                        [
                            79.10283203125007,
                            33.05253906249996
                        ],
                        [
                            79.14550781250003,
                            33.00146484375006
                        ],
                        [
                            79.16992187500003,
                            32.497216796874994
                        ],
                        [
                            78.75351562500012,
                            32.49926757812506
                        ],
                        [
                            78.73671875,
                            32.55839843750002
                        ],
                        [
                            78.38964843749997,
                            32.51987304687498
                        ],
                        [
                            78.41748046874997,
                            32.466699218749994
                        ],
                        [
                            78.4552734375001,
                            32.30034179687502
                        ],
                        [
                            78.49589843750002,
                            32.21577148437504
                        ],
                        [
                            78.71972656250009,
                            31.887646484374983
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'India',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -6.218017578125,
                            54.08872070312506
                        ],
                        [
                            -6.027392578124989,
                            52.927099609375006
                        ],
                        [
                            -6.561083984374989,
                            52.18881835937498
                        ],
                        [
                            -7.527294921874926,
                            52.09887695312503
                        ],
                        [
                            -8.813427734374926,
                            51.584912109374955
                        ],
                        [
                            -10.37871093749996,
                            51.86875000000006
                        ],
                        [
                            -9.955810546874972,
                            52.13666992187501
                        ],
                        [
                            -9.299218749999966,
                            53.09755859375002
                        ],
                        [
                            -9.795410156249972,
                            53.39497070312504
                        ],
                        [
                            -9.995947265624977,
                            54.27602539062502
                        ],
                        [
                            -8.623144531249977,
                            54.34687499999998
                        ],
                        [
                            -8.763916015624972,
                            54.68120117187496
                        ],
                        [
                            -8.274609374999955,
                            55.146289062500045
                        ],
                        [
                            -7.246679687499949,
                            55.35302734374997
                        ],
                        [
                            -7.2186523437499375,
                            55.09199218749998
                        ],
                        [
                            -8.118261718749977,
                            54.41425781250004
                        ],
                        [
                            -7.3551757812499545,
                            54.12124023437502
                        ],
                        [
                            -6.218017578125,
                            54.08872070312506
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Ireland',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            46.1144531250001,
                            38.877783203125034
                        ],
                        [
                            46.490624999999994,
                            38.90668945312498
                        ],
                        [
                            47.772851562499994,
                            39.64858398437505
                        ],
                        [
                            47.995898437500074,
                            39.683935546875034
                        ],
                        [
                            48.24199218750002,
                            38.978955078124955
                        ],
                        [
                            48.02324218750002,
                            38.81904296874998
                        ],
                        [
                            48.59267578125005,
                            38.41108398437498
                        ],
                        [
                            48.86875000000006,
                            38.43549804687498
                        ],
                        [
                            49.08095703125005,
                            37.667578125000034
                        ],
                        [
                            49.470117187499994,
                            37.496679687500034
                        ],
                        [
                            50.130468750000006,
                            37.407128906249994
                        ],
                        [
                            50.53320312499997,
                            37.01367187500006
                        ],
                        [
                            51.11855468750005,
                            36.742578124999966
                        ],
                        [
                            52.19013671875004,
                            36.62172851562505
                        ],
                        [
                            54.017187500000006,
                            36.952490234375034
                        ],
                        [
                            53.91416015625006,
                            37.34355468750002
                        ],
                        [
                            54.745214843750006,
                            37.501904296874955
                        ],
                        [
                            55.075585937499994,
                            37.90249023437502
                        ],
                        [
                            55.57841796875002,
                            38.099755859374994
                        ],
                        [
                            56.22880859375002,
                            38.07338867187502
                        ],
                        [
                            56.4406250000001,
                            38.249414062499994
                        ],
                        [
                            57.1935546875001,
                            38.216406250000034
                        ],
                        [
                            57.42382812500003,
                            37.94770507812501
                        ],
                        [
                            58.4357421875001,
                            37.63852539062498
                        ],
                        [
                            58.81542968750003,
                            37.683496093749994
                        ],
                        [
                            59.240820312500006,
                            37.52075195312497
                        ],
                        [
                            59.5622070312501,
                            37.178906249999955
                        ],
                        [
                            60.06279296875002,
                            36.962890625
                        ],
                        [
                            60.34130859375003,
                            36.63764648437501
                        ],
                        [
                            61.11962890625003,
                            36.64257812500003
                        ],
                        [
                            61.26201171875002,
                            35.61958007812498
                        ],
                        [
                            61.08007812499997,
                            34.85561523437505
                        ],
                        [
                            60.95117187499997,
                            34.65385742187499
                        ],
                        [
                            60.91474609375004,
                            34.63398437500001
                        ],
                        [
                            60.80234375000006,
                            34.55463867187501
                        ],
                        [
                            60.7262695312501,
                            34.51826171874998
                        ],
                        [
                            60.48574218750005,
                            34.09477539062502
                        ],
                        [
                            60.485937500000006,
                            33.7119140625
                        ],
                        [
                            60.91699218749997,
                            33.505224609375006
                        ],
                        [
                            60.56054687499997,
                            33.13784179687502
                        ],
                        [
                            60.576562499999994,
                            32.99487304687503
                        ],
                        [
                            60.71044921874997,
                            32.599999999999966
                        ],
                        [
                            60.82929687500004,
                            32.24941406250005
                        ],
                        [
                            60.854101562500006,
                            31.483251953125006
                        ],
                        [
                            61.110742187499994,
                            31.45112304687504
                        ],
                        [
                            61.346484375000074,
                            31.42163085937497
                        ],
                        [
                            61.66015625000003,
                            31.382421874999977
                        ],
                        [
                            61.7550781250001,
                            31.285302734374994
                        ],
                        [
                            61.814257812500074,
                            31.072558593750017
                        ],
                        [
                            61.810839843750074,
                            30.913281249999983
                        ],
                        [
                            61.78417968749997,
                            30.831933593750023
                        ],
                        [
                            61.55947265625005,
                            30.59936523437497
                        ],
                        [
                            61.10410156250006,
                            30.12841796875003
                        ],
                        [
                            60.84335937500006,
                            29.85869140624999
                        ],
                        [
                            61.03417968750003,
                            29.663427734374977
                        ],
                        [
                            61.15214843750002,
                            29.542724609375
                        ],
                        [
                            61.62304687500003,
                            28.791601562500006
                        ],
                        [
                            61.8898437500001,
                            28.546533203124994
                        ],
                        [
                            62.762500000000074,
                            28.202050781249994
                        ],
                        [
                            62.782324218750006,
                            27.800537109375
                        ],
                        [
                            62.75273437500002,
                            27.265625
                        ],
                        [
                            63.16679687500002,
                            27.25249023437499
                        ],
                        [
                            63.168066406250006,
                            26.66557617187496
                        ],
                        [
                            62.31230468750002,
                            26.490869140624994
                        ],
                        [
                            61.8698242187501,
                            26.242431640625
                        ],
                        [
                            61.66865234375004,
                            25.76899414062501
                        ],
                        [
                            61.61542968750004,
                            25.28613281250003
                        ],
                        [
                            61.58789062499997,
                            25.20234375000001
                        ],
                        [
                            61.533105468749994,
                            25.195507812499955
                        ],
                        [
                            61.41220703125006,
                            25.102099609375017
                        ],
                        [
                            60.66386718750002,
                            25.28222656250003
                        ],
                        [
                            59.45605468749997,
                            25.481494140625045
                        ],
                        [
                            59.0460937500001,
                            25.417285156250017
                        ],
                        [
                            58.79785156249997,
                            25.554589843750023
                        ],
                        [
                            57.7960937500001,
                            25.65302734375001
                        ],
                        [
                            57.334570312500006,
                            25.791552734375074
                        ],
                        [
                            56.98222656250002,
                            26.905468749999983
                        ],
                        [
                            56.3561523437501,
                            27.20024414062499
                        ],
                        [
                            55.650292968749994,
                            26.9775390625
                        ],
                        [
                            54.89580078125002,
                            26.55668945312499
                        ],
                        [
                            53.70576171875004,
                            26.72558593750003
                        ],
                        [
                            53.45498046875005,
                            26.94326171875005
                        ],
                        [
                            52.69160156250004,
                            27.323388671875023
                        ],
                        [
                            52.475878906250074,
                            27.61650390624999
                        ],
                        [
                            51.666308593750074,
                            27.84497070312497
                        ],
                        [
                            51.278906250000006,
                            28.13134765624997
                        ],
                        [
                            51.06201171874997,
                            28.72612304687499
                        ],
                        [
                            50.67519531250005,
                            29.146582031250034
                        ],
                        [
                            50.64960937500004,
                            29.420068359374966
                        ],
                        [
                            49.9831054687501,
                            30.209375000000023
                        ],
                        [
                            49.55488281250004,
                            30.028955078125023
                        ],
                        [
                            48.91914062500004,
                            30.120898437500017
                        ],
                        [
                            48.54648437500006,
                            29.962353515624955
                        ],
                        [
                            48.47851562499997,
                            30.003808593749966
                        ],
                        [
                            48.43457031249997,
                            30.03759765625
                        ],
                        [
                            48.40136718749997,
                            30.188330078124977
                        ],
                        [
                            48.33105468749997,
                            30.28544921874996
                        ],
                        [
                            48.01494140625002,
                            30.46562499999999
                        ],
                        [
                            48.01347656250002,
                            30.656445312499955
                        ],
                        [
                            48.012011718750074,
                            30.823632812500023
                        ],
                        [
                            48.01064453125005,
                            30.989794921875017
                        ],
                        [
                            47.679492187500074,
                            31.00239257812501
                        ],
                        [
                            47.679492187500074,
                            31.400585937499955
                        ],
                        [
                            47.75390624999997,
                            31.601367187500017
                        ],
                        [
                            47.829980468749994,
                            31.79443359375
                        ],
                        [
                            47.71455078125004,
                            31.936425781249966
                        ],
                        [
                            47.5915039062501,
                            32.087988281250034
                        ],
                        [
                            47.51191406250004,
                            32.15083007812504
                        ],
                        [
                            47.3712890625001,
                            32.42373046875002
                        ],
                        [
                            47.3297851562501,
                            32.45551757812501
                        ],
                        [
                            47.28515625000003,
                            32.474023437499966
                        ],
                        [
                            47.121386718750074,
                            32.46660156249996
                        ],
                        [
                            46.569921875000006,
                            32.83393554687501
                        ],
                        [
                            46.37705078125006,
                            32.92924804687499
                        ],
                        [
                            45.39707031250006,
                            33.970849609374994
                        ],
                        [
                            45.43759765625006,
                            34.415136718750006
                        ],
                        [
                            45.63750000000002,
                            34.573828125000006
                        ],
                        [
                            45.661523437499994,
                            34.61269531250002
                        ],
                        [
                            45.660058593749994,
                            34.74877929687503
                        ],
                        [
                            45.67812500000005,
                            34.798437500000034
                        ],
                        [
                            45.92089843750003,
                            35.02851562500001
                        ],
                        [
                            46.04179687500002,
                            35.08017578125006
                        ],
                        [
                            46.13378906249997,
                            35.127636718749955
                        ],
                        [
                            46.15468750000005,
                            35.19672851562498
                        ],
                        [
                            46.112109375000074,
                            35.32167968750005
                        ],
                        [
                            45.97109375000005,
                            35.524169921875
                        ],
                        [
                            46.03740234375002,
                            35.67314453124999
                        ],
                        [
                            46.180957031250074,
                            35.71137695312504
                        ],
                        [
                            46.262500000000074,
                            35.74414062500006
                        ],
                        [
                            46.27343749999997,
                            35.77324218750002
                        ],
                        [
                            46.16748046874997,
                            35.820556640625
                        ],
                        [
                            45.72343750000002,
                            35.83666992187497
                        ],
                        [
                            45.36162109375002,
                            36.015332031249955
                        ],
                        [
                            45.241113281249994,
                            36.35595703125
                        ],
                        [
                            45.20654296874997,
                            36.397167968749955
                        ],
                        [
                            45.15527343749997,
                            36.407373046874994
                        ],
                        [
                            45.11240234375006,
                            36.409277343750034
                        ],
                        [
                            45.03105468750002,
                            36.52607421875001
                        ],
                        [
                            44.76513671875003,
                            37.142431640625006
                        ],
                        [
                            44.56123046875004,
                            37.74462890625003
                        ],
                        [
                            44.211328124999994,
                            37.908056640625006
                        ],
                        [
                            44.43085937500004,
                            38.356787109375034
                        ],
                        [
                            44.02324218750002,
                            39.37744140625006
                        ],
                        [
                            44.81718750000002,
                            39.65043945312496
                        ],
                        [
                            45.4796875000001,
                            39.006249999999994
                        ],
                        [
                            46.1144531250001,
                            38.877783203125034
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Iran',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            47.14824218750002,
                            30.0009765625
                        ],
                        [
                            46.905859375000006,
                            29.537499999999994
                        ],
                        [
                            46.53144531250001,
                            29.096240234374996
                        ],
                        [
                            46.3564453125,
                            29.063671874999997
                        ],
                        [
                            44.69082031250002,
                            29.202343749999997
                        ],
                        [
                            42.85771484375002,
                            30.49521484375
                        ],
                        [
                            42.07441406250001,
                            31.080371093749996
                        ],
                        [
                            40.36933593750001,
                            31.93896484375
                        ],
                        [
                            40.02783203125,
                            31.995019531249994
                        ],
                        [
                            39.7041015625,
                            32.042529296874996
                        ],
                        [
                            39.14541015625002,
                            32.12451171875
                        ],
                        [
                            38.773535156250006,
                            33.372216796874994
                        ],
                        [
                            40.689453125,
                            34.33203125
                        ],
                        [
                            40.98701171875001,
                            34.429052734375
                        ],
                        [
                            41.19472656250002,
                            34.768994140625
                        ],
                        [
                            41.354101562500006,
                            35.640429687499996
                        ],
                        [
                            41.24560546875,
                            36.073388671874994
                        ],
                        [
                            41.41679687500002,
                            36.5146484375
                        ],
                        [
                            41.78857421875,
                            36.59716796875
                        ],
                        [
                            42.358984375000006,
                            37.10859375
                        ],
                        [
                            42.77460937500001,
                            37.371874999999996
                        ],
                        [
                            43.51582031250001,
                            37.244531249999994
                        ],
                        [
                            43.83642578125,
                            37.223535156249994
                        ],
                        [
                            44.01318359375,
                            37.313525390624996
                        ],
                        [
                            44.11445312500001,
                            37.30185546875
                        ],
                        [
                            44.15625,
                            37.282958984375
                        ],
                        [
                            44.19179687500002,
                            37.249853515625
                        ],
                        [
                            44.20839843750002,
                            37.20263671875
                        ],
                        [
                            44.20166015625,
                            37.051806640624996
                        ],
                        [
                            44.281835937500006,
                            36.97802734375
                        ],
                        [
                            44.32558593750002,
                            37.0107421875
                        ],
                        [
                            44.401953125000006,
                            37.058496093749994
                        ],
                        [
                            44.49599609375002,
                            37.110546875
                        ],
                        [
                            44.566015625000006,
                            37.158251953124996
                        ],
                        [
                            44.60595703125,
                            37.176025390625
                        ],
                        [
                            44.66933593750002,
                            37.173583984375
                        ],
                        [
                            44.73095703125,
                            37.165283203125
                        ],
                        [
                            44.798437500000006,
                            37.063867187499994
                        ],
                        [
                            44.880859375,
                            36.79931640625
                        ],
                        [
                            45.03105468750002,
                            36.52607421875
                        ],
                        [
                            45.112402343750006,
                            36.40927734375
                        ],
                        [
                            45.1552734375,
                            36.407373046874994
                        ],
                        [
                            45.20654296875,
                            36.39716796875
                        ],
                        [
                            45.24111328125002,
                            36.35595703125
                        ],
                        [
                            45.36162109375002,
                            36.01533203125
                        ],
                        [
                            45.72343750000002,
                            35.836669921875
                        ],
                        [
                            46.16748046875,
                            35.820556640625
                        ],
                        [
                            46.2734375,
                            35.773242187499996
                        ],
                        [
                            46.26250000000002,
                            35.744140625
                        ],
                        [
                            46.18095703125002,
                            35.711376953125
                        ],
                        [
                            46.03740234375002,
                            35.673144531249996
                        ],
                        [
                            45.97109375000002,
                            35.524169921875
                        ],
                        [
                            46.11210937500002,
                            35.321679687499994
                        ],
                        [
                            46.15468750000002,
                            35.196728515625
                        ],
                        [
                            46.1337890625,
                            35.12763671875
                        ],
                        [
                            46.04179687500002,
                            35.08017578125
                        ],
                        [
                            45.9208984375,
                            35.028515625
                        ],
                        [
                            45.67812500000002,
                            34.7984375
                        ],
                        [
                            45.437597656250006,
                            34.41513671875
                        ],
                        [
                            45.397070312500006,
                            33.970849609374994
                        ],
                        [
                            46.377050781250006,
                            32.929248046874996
                        ],
                        [
                            46.569921875000006,
                            32.833935546875
                        ],
                        [
                            47.12138671875002,
                            32.466601562499996
                        ],
                        [
                            47.28515625,
                            32.474023437499994
                        ],
                        [
                            47.32978515625001,
                            32.455517578125
                        ],
                        [
                            47.37128906250001,
                            32.423730468749994
                        ],
                        [
                            47.51191406250001,
                            32.150830078125
                        ],
                        [
                            47.59150390625001,
                            32.08798828125
                        ],
                        [
                            47.71455078125001,
                            31.936425781249994
                        ],
                        [
                            47.82998046875002,
                            31.79443359375
                        ],
                        [
                            47.75390625,
                            31.601367187499996
                        ],
                        [
                            47.67949218750002,
                            31.400585937499997
                        ],
                        [
                            47.67949218750002,
                            31.002392578124997
                        ],
                        [
                            48.01064453125002,
                            30.989794921874996
                        ],
                        [
                            48.01201171875002,
                            30.823632812499994
                        ],
                        [
                            48.01347656250002,
                            30.656445312499997
                        ],
                        [
                            48.01494140625002,
                            30.465624999999996
                        ],
                        [
                            48.3310546875,
                            30.285449218749996
                        ],
                        [
                            48.4013671875,
                            30.188330078125
                        ],
                        [
                            48.4345703125,
                            30.03759765625
                        ],
                        [
                            48.546484375000006,
                            29.962353515624997
                        ],
                        [
                            48.454199218750006,
                            29.9384765625
                        ],
                        [
                            48.141699218750006,
                            30.040917968749994
                        ],
                        [
                            48.07275390625,
                            30.043212890625
                        ],
                        [
                            47.982519531250006,
                            30.011328125
                        ],
                        [
                            47.97871093750001,
                            29.982812499999994
                        ],
                        [
                            47.67275390625002,
                            30.095605468749994
                        ],
                        [
                            47.14824218750002,
                            30.0009765625
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Iraq',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -15.543115234374994,
                            66.228515625
                        ],
                        [
                            -14.740429687499983,
                            66.050830078125
                        ],
                        [
                            -14.757519531249983,
                            65.7556640625
                        ],
                        [
                            -13.616015624999989,
                            65.487158203125
                        ],
                        [
                            -13.569677734374977,
                            65.068115234375
                        ],
                        [
                            -14.547070312499983,
                            64.445947265625
                        ],
                        [
                            -16.060449218749994,
                            64.11123046875
                        ],
                        [
                            -16.640332031249983,
                            63.865478515625
                        ],
                        [
                            -17.81572265624999,
                            63.71298828125
                        ],
                        [
                            -18.65361328124999,
                            63.406689453125
                        ],
                        [
                            -20.198144531249994,
                            63.555810546874994
                        ],
                        [
                            -21.832763671875,
                            64.205419921875
                        ],
                        [
                            -21.950341796874994,
                            64.514990234375
                        ],
                        [
                            -22.683984374999994,
                            65.0263671875
                        ],
                        [
                            -22.005761718749994,
                            65.49345703125
                        ],
                        [
                            -22.902490234374994,
                            65.58046875
                        ],
                        [
                            -24.223974609374977,
                            65.48720703125
                        ],
                        [
                            -23.527929687499977,
                            66.164404296875
                        ],
                        [
                            -22.426123046874977,
                            66.430126953125
                        ],
                        [
                            -21.303466796875,
                            65.87646484375
                        ],
                        [
                            -21.22998046875,
                            65.42060546875
                        ],
                        [
                            -20.356640624999983,
                            65.71904296875
                        ],
                        [
                            -20.356591796874994,
                            66.033251953125
                        ],
                        [
                            -18.777539062499983,
                            66.168798828125
                        ],
                        [
                            -16.748437499999994,
                            66.131640625
                        ],
                        [
                            -15.985400390624989,
                            66.5146484375
                        ],
                        [
                            -15.543115234374994,
                            66.228515625
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Iceland',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            35.78730468750001,
                            32.734912109374996
                        ],
                        [
                            35.572851562500006,
                            32.640869140625
                        ],
                        [
                            35.56904296875001,
                            32.619873046875
                        ],
                        [
                            35.55146484375001,
                            32.3955078125
                        ],
                        [
                            35.484375,
                            32.401660156249996
                        ],
                        [
                            35.40263671875002,
                            32.450634765625
                        ],
                        [
                            35.38671875,
                            32.493017578125
                        ],
                        [
                            35.303808593750006,
                            32.512939453125
                        ],
                        [
                            35.19326171875002,
                            32.534423828125
                        ],
                        [
                            35.065039062500006,
                            32.46044921875
                        ],
                        [
                            35.01054687500002,
                            32.338183593749996
                        ],
                        [
                            34.95595703125002,
                            32.160937499999996
                        ],
                        [
                            34.98974609375,
                            31.913281249999997
                        ],
                        [
                            34.880468750000006,
                            31.3681640625
                        ],
                        [
                            35.45058593750002,
                            31.479296874999996
                        ],
                        [
                            35.43925781250002,
                            31.132421875
                        ],
                        [
                            35.17402343750001,
                            30.52392578125
                        ],
                        [
                            34.97343750000002,
                            29.555029296875
                        ],
                        [
                            34.904296875,
                            29.477343749999996
                        ],
                        [
                            34.24531250000001,
                            31.208300781249996
                        ],
                        [
                            34.52558593750001,
                            31.525634765625
                        ],
                        [
                            35.10859375000001,
                            33.08369140625
                        ],
                        [
                            35.411230468750006,
                            33.07568359375
                        ],
                        [
                            35.869140625,
                            33.43173828125
                        ],
                        [
                            35.78730468750001,
                            32.734912109374996
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Israel',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                15.576562500000051,
                                38.220312500000034
                            ],
                            [
                                15.099511718750023,
                                37.458593750000006
                            ],
                            [
                                15.294531250000063,
                                37.01328125000006
                            ],
                            [
                                15.112597656250017,
                                36.687841796875006
                            ],
                            [
                                14.501855468750023,
                                36.798681640625034
                            ],
                            [
                                12.435546874999972,
                                37.819775390624955
                            ],
                            [
                                13.351660156250006,
                                38.180517578125006
                            ],
                            [
                                13.68154296875008,
                                38.00073242187497
                            ],
                            [
                                14.505957031250063,
                                38.045507812500034
                            ],
                            [
                                14.84589843750004,
                                38.17167968749999
                            ],
                            [
                                15.576562500000051,
                                38.220312500000034
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                9.632031250000011,
                                40.88203124999998
                            ],
                            [
                                9.805273437500063,
                                40.499560546875045
                            ],
                            [
                                9.562500000000057,
                                39.16601562500006
                            ],
                            [
                                9.056347656250068,
                                39.23916015625002
                            ],
                            [
                                8.881347656250028,
                                38.91289062499999
                            ],
                            [
                                8.418164062500068,
                                39.205712890624966
                            ],
                            [
                                8.471289062500063,
                                40.29267578124998
                            ],
                            [
                                8.189941406250028,
                                40.651611328125
                            ],
                            [
                                9.283007812500045,
                                41.20166015624997
                            ],
                            [
                                9.632031250000011,
                                40.88203124999998
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                13.420996093750006,
                                46.212304687499994
                            ],
                            [
                                13.663476562500023,
                                45.7919921875
                            ],
                            [
                                13.831152343750006,
                                45.680419921875
                            ],
                            [
                                13.206347656250074,
                                45.771386718749966
                            ],
                            [
                                12.27431640625008,
                                45.44604492187503
                            ],
                            [
                                12.523437500000028,
                                44.96796874999998
                            ],
                            [
                                12.248339843750045,
                                44.72250976562498
                            ],
                            [
                                12.396289062500074,
                                44.223876953125
                            ],
                            [
                                13.56416015625004,
                                43.57128906250003
                            ],
                            [
                                14.010449218750011,
                                42.68955078125006
                            ],
                            [
                                14.86611328125008,
                                42.05253906249996
                            ],
                            [
                                15.168750000000017,
                                41.93403320312498
                            ],
                            [
                                15.96406250000004,
                                41.939453125
                            ],
                            [
                                16.01259765625005,
                                41.435400390625006
                            ],
                            [
                                17.10341796875008,
                                41.062158203124994
                            ],
                            [
                                17.954980468749994,
                                40.65517578125002
                            ],
                            [
                                18.485839843750057,
                                40.104833984375006
                            ],
                            [
                                18.077929687500017,
                                39.93696289062498
                            ],
                            [
                                17.865039062500074,
                                40.28017578125002
                            ],
                            [
                                16.92822265625,
                                40.45805664062502
                            ],
                            [
                                16.52998046875004,
                                39.85966796874996
                            ],
                            [
                                16.597753906249977,
                                39.63891601562503
                            ],
                            [
                                17.114550781250017,
                                39.38061523437497
                            ],
                            [
                                17.174609375000017,
                                38.998095703125045
                            ],
                            [
                                16.55898437500008,
                                38.714794921874955
                            ],
                            [
                                16.54560546875001,
                                38.40908203125002
                            ],
                            [
                                16.05683593750001,
                                37.941845703124955
                            ],
                            [
                                15.72451171875008,
                                37.93911132812502
                            ],
                            [
                                15.97236328125004,
                                38.712597656249955
                            ],
                            [
                                16.19677734375,
                                38.759228515624955
                            ],
                            [
                                16.02363281250001,
                                39.35361328124998
                            ],
                            [
                                15.692773437499994,
                                39.99018554687501
                            ],
                            [
                                14.95087890625004,
                                40.23901367187497
                            ],
                            [
                                14.765722656250063,
                                40.66840820312498
                            ],
                            [
                                14.102343749999989,
                                40.82714843749997
                            ],
                            [
                                13.733398437500057,
                                41.23564453124999
                            ],
                            [
                                13.088671875000074,
                                41.243847656249955
                            ],
                            [
                                12.630859374999972,
                                41.469677734374955
                            ],
                            [
                                11.637304687500063,
                                42.287548828124955
                            ],
                            [
                                10.708398437500023,
                                42.93632812499999
                            ],
                            [
                                10.047656250000045,
                                44.01997070312504
                            ],
                            [
                                8.76582031250004,
                                44.42231445312501
                            ],
                            [
                                8.081640625000006,
                                43.91894531250006
                            ],
                            [
                                7.4931640625,
                                43.767138671875045
                            ],
                            [
                                7.651464843750034,
                                44.03364257812498
                            ],
                            [
                                6.96728515625,
                                44.280029296875
                            ],
                            [
                                7.030664062500023,
                                44.71669921875005
                            ],
                            [
                                6.627734375000017,
                                45.11796874999999
                            ],
                            [
                                7.153417968749977,
                                45.40092773437499
                            ],
                            [
                                7.021093750000034,
                                45.92578124999997
                            ],
                            [
                                7.327929687500017,
                                45.912353515625
                            ],
                            [
                                7.787890625000017,
                                45.92182617187498
                            ],
                            [
                                7.9931640625,
                                46.01591796874996
                            ],
                            [
                                8.081542968750057,
                                46.25600585937502
                            ],
                            [
                                8.818554687500011,
                                46.0771484375
                            ],
                            [
                                8.826757812500006,
                                46.06103515625
                            ],
                            [
                                9.203417968750017,
                                46.21923828125
                            ],
                            [
                                9.939257812500074,
                                46.36181640625
                            ],
                            [
                                10.041015625000028,
                                46.23808593750002
                            ],
                            [
                                10.14521484375004,
                                46.25351562500006
                            ],
                            [
                                10.038281250000011,
                                46.483203125000045
                            ],
                            [
                                10.061230468750068,
                                46.54677734375002
                            ],
                            [
                                10.087011718750063,
                                46.59990234375002
                            ],
                            [
                                10.137500000000045,
                                46.614355468750034
                            ],
                            [
                                10.195507812500068,
                                46.62109374999997
                            ],
                            [
                                10.272265625000074,
                                46.564843749999966
                            ],
                            [
                                10.363085937500017,
                                46.54707031249998
                            ],
                            [
                                10.4306640625,
                                46.55004882812497
                            ],
                            [
                                10.438281250000045,
                                46.618847656249955
                            ],
                            [
                                10.39794921875,
                                46.66503906250006
                            ],
                            [
                                10.406054687500045,
                                46.73486328124997
                            ],
                            [
                                10.452832031249983,
                                46.86494140625001
                            ],
                            [
                                10.47939453125008,
                                46.85512695312505
                            ],
                            [
                                10.579785156250011,
                                46.85371093750001
                            ],
                            [
                                10.689257812500017,
                                46.846386718749955
                            ],
                            [
                                10.759765625,
                                46.79331054687498
                            ],
                            [
                                10.828906250000045,
                                46.775244140625034
                            ],
                            [
                                10.927343750000034,
                                46.76948242187501
                            ],
                            [
                                10.993261718750034,
                                46.77700195312502
                            ],
                            [
                                11.02509765625004,
                                46.796972656250006
                            ],
                            [
                                11.775683593750017,
                                46.986083984375
                            ],
                            [
                                12.388281250000034,
                                46.70263671874997
                            ],
                            [
                                13.168750000000045,
                                46.572656249999966
                            ],
                            [
                                13.3515625,
                                46.55791015624999
                            ],
                            [
                                13.490039062500045,
                                46.55556640625002
                            ],
                            [
                                13.700000000000017,
                                46.52026367187503
                            ],
                            [
                                13.679687500000057,
                                46.46289062499997
                            ],
                            [
                                13.420996093750006,
                                46.212304687499994
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Italy',
                'childNum': 3
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -77.261474609375,
                            18.457421874999994
                        ],
                        [
                            -76.349853515625,
                            18.15185546875
                        ],
                        [
                            -76.524609375,
                            17.8662109375
                        ],
                        [
                            -76.85322265625,
                            17.97373046874999
                        ],
                        [
                            -77.36142578124999,
                            17.833691406249997
                        ],
                        [
                            -77.76816406249999,
                            17.877392578124997
                        ],
                        [
                            -78.21669921875,
                            18.44809570312499
                        ],
                        [
                            -77.8734375,
                            18.522216796875
                        ],
                        [
                            -77.261474609375,
                            18.457421874999994
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Jamaica',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            39.14541015625002,
                            32.12451171875
                        ],
                        [
                            38.9970703125,
                            32.007470703124994
                        ],
                        [
                            38.96230468750002,
                            31.994921874999996
                        ],
                        [
                            37.77382812500002,
                            31.696337890624996
                        ],
                        [
                            37.49335937500001,
                            31.625878906249994
                        ],
                        [
                            37.21562500000002,
                            31.556103515624997
                        ],
                        [
                            36.958593750000006,
                            31.491503906249996
                        ],
                        [
                            37.980078125000006,
                            30.5
                        ],
                        [
                            37.862890625000006,
                            30.442626953125
                        ],
                        [
                            37.66972656250002,
                            30.34814453125
                        ],
                        [
                            37.64990234375,
                            30.330957031249994
                        ],
                        [
                            37.63359375000002,
                            30.313281249999996
                        ],
                        [
                            37.55361328125002,
                            30.144580078124996
                        ],
                        [
                            37.49072265625,
                            30.01171875
                        ],
                        [
                            37.46923828125,
                            29.995068359374997
                        ],
                        [
                            36.75527343750002,
                            29.866015624999996
                        ],
                        [
                            36.70390625000002,
                            29.831640625
                        ],
                        [
                            36.591796875,
                            29.66611328125
                        ],
                        [
                            36.47607421875,
                            29.4951171875
                        ],
                        [
                            36.282812500000006,
                            29.355371093749994
                        ],
                        [
                            36.068457031250006,
                            29.200537109375
                        ],
                        [
                            36.01542968750002,
                            29.190478515624996
                        ],
                        [
                            34.950781250000006,
                            29.353515625
                        ],
                        [
                            34.97343750000002,
                            29.555029296875
                        ],
                        [
                            35.17402343750001,
                            30.52392578125
                        ],
                        [
                            35.43925781250002,
                            31.132421875
                        ],
                        [
                            35.45058593750002,
                            31.479296874999996
                        ],
                        [
                            35.49941406250002,
                            31.67236328125
                        ],
                        [
                            35.55898437500002,
                            31.765527343749994
                        ],
                        [
                            35.55146484375001,
                            32.3955078125
                        ],
                        [
                            35.56904296875001,
                            32.619873046875
                        ],
                        [
                            35.572851562500006,
                            32.640869140625
                        ],
                        [
                            35.78730468750001,
                            32.734912109374996
                        ],
                        [
                            36.3720703125,
                            32.3869140625
                        ],
                        [
                            36.818359375,
                            32.317285156249994
                        ],
                        [
                            38.773535156250006,
                            33.372216796874994
                        ],
                        [
                            39.14541015625002,
                            32.12451171875
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Jordan',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                131.17460937500007,
                                33.602587890625045
                            ],
                            [
                                131.58300781249997,
                                33.65239257812496
                            ],
                            [
                                131.89658203125006,
                                33.25458984375001
                            ],
                            [
                                131.97666015625012,
                                32.84394531250001
                            ],
                            [
                                131.73212890625004,
                                32.59282226562502
                            ],
                            [
                                131.33720703125007,
                                31.404687499999994
                            ],
                            [
                                130.58876953125,
                                31.178515625000017
                            ],
                            [
                                130.20068359374997,
                                31.291894531250023
                            ],
                            [
                                130.3219726562501,
                                31.601464843750023
                            ],
                            [
                                130.19443359375012,
                                32.090771484374955
                            ],
                            [
                                130.64052734375005,
                                32.61923828124998
                            ],
                            [
                                129.58007812500003,
                                33.23627929687501
                            ],
                            [
                                130.36503906250007,
                                33.634472656249955
                            ],
                            [
                                130.4837890625,
                                33.834619140624966
                            ],
                            [
                                130.95312499999997,
                                33.87202148437504
                            ],
                            [
                                131.17460937500007,
                                33.602587890625045
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                134.35742187500003,
                                34.25634765625
                            ],
                            [
                                134.63750000000007,
                                34.22661132812499
                            ],
                            [
                                134.73886718750012,
                                33.82050781250001
                            ],
                            [
                                134.37705078125012,
                                33.60839843749997
                            ],
                            [
                                134.18164062500003,
                                33.24721679687502
                            ],
                            [
                                133.63203125000004,
                                33.51098632812503
                            ],
                            [
                                133.28593750000007,
                                33.35996093749998
                            ],
                            [
                                132.97724609375004,
                                32.84199218749998
                            ],
                            [
                                132.49511718749997,
                                32.91660156249998
                            ],
                            [
                                132.36591796875004,
                                33.51245117187506
                            ],
                            [
                                132.78427734375006,
                                33.99243164062506
                            ],
                            [
                                133.58203124999997,
                                34.01713867187502
                            ],
                            [
                                134.07587890625004,
                                34.3583984375
                            ],
                            [
                                134.35742187500003,
                                34.25634765625
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                141.22929687500007,
                                41.372656250000006
                            ],
                            [
                                141.46279296875005,
                                40.61113281250002
                            ],
                            [
                                141.7970703125001,
                                40.29116210937502
                            ],
                            [
                                141.97783203125007,
                                39.84443359374998
                            ],
                            [
                                141.90078125,
                                39.111328125
                            ],
                            [
                                141.65859375000005,
                                38.974853515625
                            ],
                            [
                                141.4674804687501,
                                38.404150390625006
                            ],
                            [
                                141.10839843750003,
                                38.33793945312502
                            ],
                            [
                                140.92792968750004,
                                37.94960937500002
                            ],
                            [
                                141.0363281250001,
                                37.46723632812498
                            ],
                            [
                                140.96835937500012,
                                37.002050781250006
                            ],
                            [
                                140.62734375,
                                36.50278320312498
                            ],
                            [
                                140.62197265625005,
                                36.05922851562502
                            ],
                            [
                                140.87402343749997,
                                35.72495117187506
                            ],
                            [
                                140.457421875,
                                35.51025390625
                            ],
                            [
                                140.41650390625003,
                                35.26699218750002
                            ],
                            [
                                139.79921875000005,
                                34.956933593749994
                            ],
                            [
                                139.65000000000012,
                                35.40913085937501
                            ],
                            [
                                139.24941406250005,
                                35.27802734375004
                            ],
                            [
                                138.98261718750004,
                                34.698388671874994
                            ],
                            [
                                138.57714843750003,
                                35.08647460937502
                            ],
                            [
                                138.18906250000012,
                                34.596337890624994
                            ],
                            [
                                136.88457031250002,
                                34.80585937500004
                            ],
                            [
                                136.53300781250007,
                                34.678369140624994
                            ],
                            [
                                136.8537109375001,
                                34.324072265625034
                            ],
                            [
                                136.32988281250007,
                                34.17685546875006
                            ],
                            [
                                135.91621093750004,
                                33.561718749999955
                            ],
                            [
                                135.4528320312501,
                                33.55336914062505
                            ],
                            [
                                135.17539062500006,
                                33.898046875000006
                            ],
                            [
                                135.10009765624997,
                                34.288378906250045
                            ],
                            [
                                135.38476562499997,
                                34.50043945312498
                            ],
                            [
                                134.74003906250007,
                                34.765234375000006
                            ],
                            [
                                134.20830078125002,
                                34.69765625000002
                            ],
                            [
                                133.96826171874997,
                                34.52729492187504
                            ],
                            [
                                133.14238281250002,
                                34.30244140624998
                            ],
                            [
                                132.23808593750002,
                                34.227001953124955
                            ],
                            [
                                132.090234375,
                                33.85546875000003
                            ],
                            [
                                131.74052734375007,
                                34.05205078125002
                            ],
                            [
                                130.91884765625,
                                33.97573242187502
                            ],
                            [
                                131.00419921875007,
                                34.39257812500003
                            ],
                            [
                                131.35439453125,
                                34.41318359375006
                            ],
                            [
                                132.92294921875006,
                                35.511279296875045
                            ],
                            [
                                133.37646484375003,
                                35.458837890625006
                            ],
                            [
                                134.21406250000004,
                                35.539257812499955
                            ],
                            [
                                135.17431640625003,
                                35.74707031250003
                            ],
                            [
                                135.68027343750006,
                                35.50312499999998
                            ],
                            [
                                136.09531250000006,
                                35.767626953125045
                            ],
                            [
                                136.00625000000005,
                                35.99057617187498
                            ],
                            [
                                136.69814453125005,
                                36.742041015625034
                            ],
                            [
                                137.34257812500002,
                                36.770361328125034
                            ],
                            [
                                137.5140625,
                                36.95156250000002
                            ],
                            [
                                138.31992187500012,
                                37.21840820312502
                            ],
                            [
                                138.88505859375007,
                                37.84394531250001
                            ],
                            [
                                139.40097656250006,
                                38.142578125
                            ],
                            [
                                140.04814453125002,
                                39.463720703125034
                            ],
                            [
                                139.90800781250002,
                                40.02172851562503
                            ],
                            [
                                139.92285156250003,
                                40.59843750000002
                            ],
                            [
                                140.28125000000003,
                                40.84609375000002
                            ],
                            [
                                141.1185546875,
                                40.88227539062501
                            ],
                            [
                                141.22929687500007,
                                41.372656250000006
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                143.82431640625012,
                                44.11699218749999
                            ],
                            [
                                144.79853515625004,
                                43.940234375000045
                            ],
                            [
                                145.10107421874997,
                                43.76455078125005
                            ],
                            [
                                145.34746093750007,
                                43.17670898437501
                            ],
                            [
                                144.63076171875,
                                42.946923828124994
                            ],
                            [
                                143.96933593750006,
                                42.88139648437499
                            ],
                            [
                                143.42949218750002,
                                42.41889648437498
                            ],
                            [
                                143.2365234375001,
                                42.000195312499955
                            ],
                            [
                                141.85136718750007,
                                42.57905273437501
                            ],
                            [
                                140.98613281250002,
                                42.34213867187498
                            ],
                            [
                                140.70976562500002,
                                42.555615234374955
                            ],
                            [
                                140.31523437500002,
                                42.33427734374999
                            ],
                            [
                                140.65986328125004,
                                41.815576171874994
                            ],
                            [
                                140.3849609375001,
                                41.51928710937503
                            ],
                            [
                                139.99531250000004,
                                41.57641601562503
                            ],
                            [
                                140.10839843749997,
                                41.912939453125034
                            ],
                            [
                                139.82089843750012,
                                42.387597656249994
                            ],
                            [
                                139.89111328125003,
                                42.64921875000002
                            ],
                            [
                                140.78066406250005,
                                43.214990234374994
                            ],
                            [
                                141.29628906250005,
                                43.19965820312498
                            ],
                            [
                                141.64472656250004,
                                44.01943359375002
                            ],
                            [
                                141.78222656249997,
                                44.71635742187496
                            ],
                            [
                                141.58300781250003,
                                45.15595703125001
                            ],
                            [
                                141.93769531250004,
                                45.509521484375
                            ],
                            [
                                142.70410156249997,
                                44.81918945312506
                            ],
                            [
                                143.82431640625012,
                                44.11699218749999
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Japan',
                'childNum': 4
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            77.04863281249999,
                            35.109912109374996
                        ],
                        [
                            76.76689453124999,
                            35.66171875
                        ],
                        [
                            77.44648437500001,
                            35.4755859375
                        ],
                        [
                            77.04863281249999,
                            35.109912109374996
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Kashmir',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            87.32285156250012,
                            49.085791015625006
                        ],
                        [
                            86.75312500000004,
                            49.00883789062496
                        ],
                        [
                            86.54941406250012,
                            48.52861328125002
                        ],
                        [
                            85.7494140625,
                            48.38505859374999
                        ],
                        [
                            85.52597656250006,
                            47.915625000000006
                        ],
                        [
                            85.65664062500005,
                            47.254638671875
                        ],
                        [
                            84.78613281249997,
                            46.83071289062505
                        ],
                        [
                            83.19306640625004,
                            47.18657226562499
                        ],
                        [
                            83.0041015625001,
                            47.03349609374996
                        ],
                        [
                            82.32666015625003,
                            45.519921874999994
                        ],
                        [
                            82.4787109375001,
                            45.12358398437499
                        ],
                        [
                            81.60205078125003,
                            45.31083984375002
                        ],
                        [
                            80.05917968750012,
                            45.006445312500006
                        ],
                        [
                            80.45546875,
                            44.74609375
                        ],
                        [
                            80.35527343750002,
                            44.09726562500006
                        ],
                        [
                            80.78574218750006,
                            43.16157226562504
                        ],
                        [
                            80.16503906249997,
                            42.66552734375006
                        ],
                        [
                            80.20937500000005,
                            42.190039062500006
                        ],
                        [
                            80.07128906249997,
                            42.302978515625
                        ],
                        [
                            79.92109375000004,
                            42.41313476562496
                        ],
                        [
                            79.49013671875,
                            42.45756835937496
                        ],
                        [
                            79.20302734375005,
                            42.66601562499997
                        ],
                        [
                            79.16484375000007,
                            42.759033203125
                        ],
                        [
                            78.52421875000002,
                            42.86464843750002
                        ],
                        [
                            77.23554687500004,
                            42.912646484375045
                        ],
                        [
                            77.05732421875004,
                            42.970654296874955
                        ],
                        [
                            76.50917968750005,
                            42.91889648437498
                        ],
                        [
                            75.9322265625,
                            42.92851562499999
                        ],
                        [
                            75.84033203125003,
                            42.93750000000003
                        ],
                        [
                            75.78955078124997,
                            42.93291015624999
                        ],
                        [
                            75.68173828125,
                            42.830468749999994
                        ],
                        [
                            75.04765625000007,
                            42.904394531250034
                        ],
                        [
                            74.20908203125006,
                            43.24038085937502
                        ],
                        [
                            73.88603515625002,
                            43.132568359375
                        ],
                        [
                            73.55625,
                            43.002783203125006
                        ],
                        [
                            73.42187499999997,
                            42.59350585937503
                        ],
                        [
                            73.2829101562501,
                            42.50410156250004
                        ],
                        [
                            72.85507812500006,
                            42.561132812500006
                        ],
                        [
                            72.75292968750003,
                            42.63789062500001
                        ],
                        [
                            72.54316406250004,
                            42.67773437500006
                        ],
                        [
                            72.27578125,
                            42.757666015625006
                        ],
                        [
                            72.16181640625004,
                            42.76069335937498
                        ],
                        [
                            71.816796875,
                            42.822167968749994
                        ],
                        [
                            71.76054687500002,
                            42.82148437500004
                        ],
                        [
                            71.734765625,
                            42.818896484375045
                        ],
                        [
                            71.5142578125,
                            42.766943359375006
                        ],
                        [
                            71.42207031250004,
                            42.78315429687504
                        ],
                        [
                            71.25664062500002,
                            42.733544921874966
                        ],
                        [
                            71.02275390625007,
                            42.53544921875002
                        ],
                        [
                            70.89287109375007,
                            42.339990234374994
                        ],
                        [
                            70.94677734374997,
                            42.24868164062505
                        ],
                        [
                            70.41601562500003,
                            42.07856445312501
                        ],
                        [
                            70.09560546875,
                            41.82050781250004
                        ],
                        [
                            69.06494140625003,
                            41.366943359375
                        ],
                        [
                            68.29189453125,
                            40.656103515625034
                        ],
                        [
                            67.9357421875001,
                            41.19658203125002
                        ],
                        [
                            66.81425781250002,
                            41.14238281249999
                        ],
                        [
                            66.66865234375004,
                            41.27075195312503
                        ],
                        [
                            66.49863281250006,
                            41.99487304687503
                        ],
                        [
                            66.00957031250007,
                            42.00488281250003
                        ],
                        [
                            66.1002929687501,
                            42.99082031249998
                        ],
                        [
                            65.80302734375002,
                            42.87695312500006
                        ],
                        [
                            65.49619140625,
                            43.310546875
                        ],
                        [
                            64.9054687500001,
                            43.714697265625006
                        ],
                        [
                            64.44316406250007,
                            43.551171874999994
                        ],
                        [
                            63.20703125000003,
                            43.62797851562502
                        ],
                        [
                            61.99023437500003,
                            43.492138671874955
                        ],
                        [
                            61.16074218750006,
                            44.16860351562502
                        ],
                        [
                            61.007910156250006,
                            44.39379882812497
                        ],
                        [
                            58.555273437500006,
                            45.55537109375001
                        ],
                        [
                            55.97568359375006,
                            44.99492187499996
                        ],
                        [
                            55.9767578125001,
                            42.69951171874996
                        ],
                        [
                            55.97744140625005,
                            41.32221679687504
                        ],
                        [
                            55.54521484375002,
                            41.26274414062499
                        ],
                        [
                            54.90371093750005,
                            41.91909179687505
                        ],
                        [
                            54.120996093749994,
                            42.335205078125
                        ],
                        [
                            53.25009765625006,
                            42.20585937500002
                        ],
                        [
                            52.4938476562501,
                            41.780371093750034
                        ],
                        [
                            52.59658203125005,
                            42.760156249999966
                        ],
                        [
                            51.84414062500005,
                            42.910449218750045
                        ],
                        [
                            51.30175781249997,
                            43.48237304687501
                        ],
                        [
                            50.8307617187501,
                            44.192773437499966
                        ],
                        [
                            50.331152343750006,
                            44.32548828125002
                        ],
                        [
                            50.409472656250074,
                            44.6240234375
                        ],
                        [
                            50.86035156250003,
                            44.62875976562498
                        ],
                        [
                            51.4157226562501,
                            45.35786132812501
                        ],
                        [
                            52.53105468750002,
                            45.39863281249998
                        ],
                        [
                            52.773828124999994,
                            45.57275390625
                        ],
                        [
                            53.13525390625003,
                            46.19165039062497
                        ],
                        [
                            53.17021484375002,
                            46.66904296875006
                        ],
                        [
                            52.91601562500003,
                            46.95439453125002
                        ],
                        [
                            52.18876953125002,
                            46.839501953124966
                        ],
                        [
                            51.178027343750074,
                            47.110156250000045
                        ],
                        [
                            50.41933593750005,
                            46.879492187500034
                        ],
                        [
                            49.99980468750002,
                            46.63427734375
                        ],
                        [
                            49.347460937500074,
                            46.51914062499998
                        ],
                        [
                            49.232226562500074,
                            46.33715820312503
                        ],
                        [
                            48.610156250000074,
                            46.566455078125045
                        ],
                        [
                            48.95937500000005,
                            46.77460937499998
                        ],
                        [
                            48.109960937500006,
                            47.74541015624996
                        ],
                        [
                            47.09326171875003,
                            47.947705078124955
                        ],
                        [
                            47.004296874999994,
                            48.28447265625002
                        ],
                        [
                            46.660937500000074,
                            48.41225585937502
                        ],
                        [
                            46.70263671875003,
                            48.80556640625002
                        ],
                        [
                            47.014257812500006,
                            49.09833984374998
                        ],
                        [
                            46.80205078125002,
                            49.36708984375002
                        ],
                        [
                            47.32646484375002,
                            50.273535156250034
                        ],
                        [
                            47.7057617187501,
                            50.37797851562502
                        ],
                        [
                            48.43427734375004,
                            49.828515624999966
                        ],
                        [
                            48.84326171875003,
                            50.01313476562501
                        ],
                        [
                            48.80839843750002,
                            50.60131835937503
                        ],
                        [
                            49.49804687500003,
                            51.08359375000006
                        ],
                        [
                            50.246875000000074,
                            51.28950195312498
                        ],
                        [
                            50.79394531249997,
                            51.729199218749955
                        ],
                        [
                            51.60908203125004,
                            51.483984375000006
                        ],
                        [
                            52.33105468749997,
                            51.681298828124966
                        ],
                        [
                            52.57119140625005,
                            51.481640624999955
                        ],
                        [
                            53.33808593750004,
                            51.48237304687504
                        ],
                        [
                            54.04150390624997,
                            51.11518554687501
                        ],
                        [
                            54.42148437500006,
                            50.78032226562499
                        ],
                        [
                            54.72714843750006,
                            50.998095703125045
                        ],
                        [
                            55.54228515625002,
                            50.60180664062506
                        ],
                        [
                            55.79765625000002,
                            50.60205078125
                        ],
                        [
                            56.49140625000004,
                            51.01953124999997
                        ],
                        [
                            57.01171874999997,
                            51.06518554687503
                        ],
                        [
                            57.44218750000002,
                            50.88886718749998
                        ],
                        [
                            57.83886718750003,
                            51.091650390625006
                        ],
                        [
                            58.359179687500074,
                            51.063818359375034
                        ],
                        [
                            58.88369140625005,
                            50.694433593750006
                        ],
                        [
                            59.812402343749994,
                            50.58203125
                        ],
                        [
                            59.95517578125006,
                            50.79926757812504
                        ],
                        [
                            60.637988281250074,
                            50.66372070312505
                        ],
                        [
                            61.38945312500002,
                            50.86103515625001
                        ],
                        [
                            61.585058593750006,
                            51.22968749999998
                        ],
                        [
                            61.363085937500074,
                            51.44189453125003
                        ],
                        [
                            60.464746093749994,
                            51.651171875000045
                        ],
                        [
                            60.06552734375006,
                            51.976464843749966
                        ],
                        [
                            60.937597656250006,
                            52.28056640625002
                        ],
                        [
                            60.80234375000006,
                            52.74472656249998
                        ],
                        [
                            61.047460937500006,
                            52.97246093750002
                        ],
                        [
                            61.97421875000006,
                            52.94375000000002
                        ],
                        [
                            62.01464843750003,
                            53.10786132812498
                        ],
                        [
                            61.19921874999997,
                            53.28715820312502
                        ],
                        [
                            60.97949218749997,
                            53.62172851562505
                        ],
                        [
                            61.33369140625004,
                            54.04926757812501
                        ],
                        [
                            61.92871093750003,
                            53.94648437500004
                        ],
                        [
                            63.0739257812501,
                            54.10522460937506
                        ],
                        [
                            64.46123046875002,
                            54.38417968750002
                        ],
                        [
                            65.1578125,
                            54.364404296874994
                        ],
                        [
                            65.476953125,
                            54.62329101562497
                        ],
                        [
                            66.22265624999997,
                            54.667382812499994
                        ],
                        [
                            68.07382812500006,
                            54.959570312500006
                        ],
                        [
                            68.20625000000004,
                            55.16093750000002
                        ],
                        [
                            68.9772460937501,
                            55.389599609374955
                        ],
                        [
                            70.18242187500002,
                            55.162451171875034
                        ],
                        [
                            70.73808593750007,
                            55.30517578125
                        ],
                        [
                            71.18554687500003,
                            54.59931640624998
                        ],
                        [
                            71.09316406250005,
                            54.21220703124999
                        ],
                        [
                            72.91406249999997,
                            54.10732421875002
                        ],
                        [
                            73.39941406250003,
                            53.81147460937498
                        ],
                        [
                            73.28574218750006,
                            53.598388671875
                        ],
                        [
                            74.45195312500007,
                            53.64726562500002
                        ],
                        [
                            76.26660156249997,
                            54.31196289062498
                        ],
                        [
                            76.57568359374997,
                            53.94252929687502
                        ],
                        [
                            77.70439453125007,
                            53.379150390625
                        ],
                        [
                            78.47548828125005,
                            52.638427734375
                        ],
                        [
                            79.46884765625012,
                            51.49311523437498
                        ],
                        [
                            79.98623046875,
                            50.774560546874966
                        ],
                        [
                            80.87734375,
                            51.28144531250004
                        ],
                        [
                            81.46591796875006,
                            50.739843750000006
                        ],
                        [
                            82.49394531250007,
                            50.72758789062499
                        ],
                        [
                            83.35732421875005,
                            50.99458007812504
                        ],
                        [
                            83.94511718750007,
                            50.774658203125
                        ],
                        [
                            84.25781249999997,
                            50.28823242187502
                        ],
                        [
                            84.9894531250001,
                            50.061425781249994
                        ],
                        [
                            85.21015625000004,
                            49.66484375000002
                        ],
                        [
                            86.1808593750001,
                            49.49931640624996
                        ],
                        [
                            86.61425781249997,
                            49.60971679687498
                        ],
                        [
                            87.32285156250012,
                            49.085791015625006
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Kazakhstan',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            35.325292968750006,
                            5.364892578124994
                        ],
                        [
                            35.745019531249994,
                            5.343994140625
                        ],
                        [
                            35.80029296874997,
                            5.156933593749983
                        ],
                        [
                            35.77929687499997,
                            5.105566406250006
                        ],
                        [
                            35.756152343750074,
                            4.950488281250031
                        ],
                        [
                            35.76308593750005,
                            4.808007812500051
                        ],
                        [
                            35.9787109375001,
                            4.50380859374998
                        ],
                        [
                            36.02197265625003,
                            4.4681152343749915
                        ],
                        [
                            36.081933593749994,
                            4.449707031249972
                        ],
                        [
                            36.90556640625002,
                            4.4114746093749915
                        ],
                        [
                            37.15458984375002,
                            4.254541015624994
                        ],
                        [
                            37.944921875000006,
                            3.7467285156250227
                        ],
                        [
                            38.0861328125001,
                            3.648828124999966
                        ],
                        [
                            38.22529296875004,
                            3.61899414062502
                        ],
                        [
                            38.45156250000005,
                            3.6048339843749773
                        ],
                        [
                            38.608007812500006,
                            3.600097656249986
                        ],
                        [
                            39.49443359375002,
                            3.45610351562496
                        ],
                        [
                            39.65751953125002,
                            3.577832031249983
                        ],
                        [
                            39.79033203125002,
                            3.754248046875034
                        ],
                        [
                            39.842187499999994,
                            3.851464843750037
                        ],
                        [
                            40.765234375000006,
                            4.273046875000034
                        ],
                        [
                            41.02080078125002,
                            4.0574707031249915
                        ],
                        [
                            41.22089843750004,
                            3.9435546874999687
                        ],
                        [
                            41.372460937499994,
                            3.94619140624998
                        ],
                        [
                            41.48193359375003,
                            3.9632812499999943
                        ],
                        [
                            41.737695312499994,
                            3.979052734375003
                        ],
                        [
                            41.88398437500004,
                            3.9777343750000114
                        ],
                        [
                            41.6134765625001,
                            3.59047851562498
                        ],
                        [
                            41.34179687499997,
                            3.20166015625
                        ],
                        [
                            40.964453125000006,
                            2.8146484375000256
                        ],
                        [
                            40.97001953125002,
                            1.3781738281250284
                        ],
                        [
                            40.9787109375001,
                            -0.8703125000000114
                        ],
                        [
                            41.249804687500074,
                            -1.220507812499946
                        ],
                        [
                            41.4269531250001,
                            -1.4495117187499744
                        ],
                        [
                            41.521874999999994,
                            -1.5722656250000284
                        ],
                        [
                            41.38691406250004,
                            -1.8669921875000313
                        ],
                        [
                            40.99550781250005,
                            -1.9505859375000085
                        ],
                        [
                            40.64414062500006,
                            -2.53945312499998
                        ],
                        [
                            40.22246093750002,
                            -2.688378906250037
                        ],
                        [
                            40.1154296875001,
                            -3.2505859374999915
                        ],
                        [
                            39.49091796875004,
                            -4.478417968750023
                        ],
                        [
                            39.221777343750006,
                            -4.692382812500014
                        ],
                        [
                            37.79726562500005,
                            -3.6744140625000057
                        ],
                        [
                            37.608691406250074,
                            -3.4602539062499886
                        ],
                        [
                            37.643847656250074,
                            -3.0454101562500284
                        ],
                        [
                            35.83691406249997,
                            -2.036621093749943
                        ],
                        [
                            33.979394531249994,
                            -1.002050781250034
                        ],
                        [
                            33.90322265625005,
                            -1.002050781250034
                        ],
                        [
                            33.94316406250002,
                            0.17377929687496874
                        ],
                        [
                            34.160937500000074,
                            0.6051757812500256
                        ],
                        [
                            34.4108398437501,
                            0.8672851562500341
                        ],
                        [
                            34.48173828125002,
                            1.0421386718750512
                        ],
                        [
                            34.535253906250006,
                            1.1015624999999858
                        ],
                        [
                            34.64912109375004,
                            1.1853027343749858
                        ],
                        [
                            34.78759765625003,
                            1.230712890625
                        ],
                        [
                            34.96523437500005,
                            1.6433593749999602
                        ],
                        [
                            34.9640625000001,
                            2.06240234374998
                        ],
                        [
                            34.742480468750074,
                            2.818115234375014
                        ],
                        [
                            34.44785156250006,
                            3.163476562500037
                        ],
                        [
                            34.392871093750074,
                            3.6915039062500483
                        ],
                        [
                            34.16503906250003,
                            3.812988281250014
                        ],
                        [
                            34.18574218750004,
                            3.869775390625037
                        ],
                        [
                            34.13203125000004,
                            3.889160156249986
                        ],
                        [
                            33.97607421874997,
                            4.220214843750028
                        ],
                        [
                            34.176855468750006,
                            4.419091796875037
                        ],
                        [
                            34.38017578125002,
                            4.620654296874974
                        ],
                        [
                            34.6398437500001,
                            4.875488281250028
                        ],
                        [
                            34.878320312499994,
                            5.109570312500026
                        ],
                        [
                            35.08447265624997,
                            5.31186523437502
                        ],
                        [
                            35.268359375000074,
                            5.492285156250006
                        ],
                        [
                            35.325292968750006,
                            5.364892578124994
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Kenya',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            72.63994140625002,
                            39.385986328125
                        ],
                        [
                            71.73222656250002,
                            39.422998046874994
                        ],
                        [
                            71.404296875,
                            39.5978515625
                        ],
                        [
                            71.32851562500002,
                            39.568701171875
                        ],
                        [
                            71.27285156250002,
                            39.535302734374994
                        ],
                        [
                            71.11806640625002,
                            39.513574218749994
                        ],
                        [
                            71.0650390625,
                            39.493408203125
                        ],
                        [
                            71.0048828125,
                            39.411865234375
                        ],
                        [
                            70.79931640625,
                            39.3947265625
                        ],
                        [
                            70.73310546875001,
                            39.41328125
                        ],
                        [
                            70.67861328125002,
                            39.4712890625
                        ],
                        [
                            70.60781250000002,
                            39.564404296875
                        ],
                        [
                            70.24482421875001,
                            39.542626953124994
                        ],
                        [
                            70.20927734375002,
                            39.574999999999996
                        ],
                        [
                            70.17109375000001,
                            39.5841796875
                        ],
                        [
                            70.13681640625,
                            39.557568359375
                        ],
                        [
                            69.7720703125,
                            39.55673828125
                        ],
                        [
                            69.59882812500001,
                            39.573779296874996
                        ],
                        [
                            69.47099609375002,
                            39.990624999999994
                        ],
                        [
                            69.966796875,
                            40.20224609375
                        ],
                        [
                            70.59921875,
                            39.974511718749994
                        ],
                        [
                            70.9580078125,
                            40.2388671875
                        ],
                        [
                            71.69248046875,
                            40.15234375
                        ],
                        [
                            72.3892578125,
                            40.427392578124994
                        ],
                        [
                            73.13212890625002,
                            40.828515624999994
                        ],
                        [
                            72.36406250000002,
                            41.04345703125
                        ],
                        [
                            71.60625000000002,
                            41.367431640625
                        ],
                        [
                            71.4208984375,
                            41.34189453125
                        ],
                        [
                            71.40839843750001,
                            41.136035156249996
                        ],
                        [
                            71.39306640625,
                            41.123388671875
                        ],
                        [
                            71.298828125,
                            41.152490234374994
                        ],
                        [
                            71.11074218750002,
                            41.152636718749996
                        ],
                        [
                            70.78242187500001,
                            41.262499999999996
                        ],
                        [
                            70.18095703125002,
                            41.571435546874994
                        ],
                        [
                            70.45498046875002,
                            41.725048828125
                        ],
                        [
                            70.84189453125,
                            42.01962890625
                        ],
                        [
                            71.0322265625,
                            42.077783203124994
                        ],
                        [
                            71.228515625,
                            42.162890624999996
                        ],
                        [
                            71.23232421875002,
                            42.186279296875
                        ],
                        [
                            71.21269531250002,
                            42.206445312499994
                        ],
                        [
                            71.12998046875,
                            42.25
                        ],
                        [
                            71.03603515625002,
                            42.28466796875
                        ],
                        [
                            70.97900390625,
                            42.266552734375
                        ],
                        [
                            70.94677734375,
                            42.248681640624994
                        ],
                        [
                            70.89287109375002,
                            42.339990234374994
                        ],
                        [
                            71.02275390625002,
                            42.535449218749996
                        ],
                        [
                            71.25664062500002,
                            42.733544921874994
                        ],
                        [
                            71.42207031250001,
                            42.783154296875
                        ],
                        [
                            71.5142578125,
                            42.766943359375
                        ],
                        [
                            71.73476562500002,
                            42.818896484374996
                        ],
                        [
                            71.76054687500002,
                            42.821484375
                        ],
                        [
                            71.81679687500002,
                            42.822167968749994
                        ],
                        [
                            72.16181640625001,
                            42.760693359375
                        ],
                        [
                            72.27578125000002,
                            42.757666015625
                        ],
                        [
                            72.54316406250001,
                            42.677734375
                        ],
                        [
                            72.7529296875,
                            42.637890625
                        ],
                        [
                            72.855078125,
                            42.5611328125
                        ],
                        [
                            73.28291015625001,
                            42.5041015625
                        ],
                        [
                            73.49296875000002,
                            42.409033203125
                        ],
                        [
                            73.421875,
                            42.593505859375
                        ],
                        [
                            73.55625,
                            43.002783203125
                        ],
                        [
                            73.88603515625002,
                            43.132568359375
                        ],
                        [
                            74.20908203125,
                            43.240380859374994
                        ],
                        [
                            75.04765625000002,
                            42.90439453125
                        ],
                        [
                            75.68173828125,
                            42.830468749999994
                        ],
                        [
                            75.78955078125,
                            42.932910156249996
                        ],
                        [
                            75.84033203125,
                            42.9375
                        ],
                        [
                            75.9322265625,
                            42.928515624999996
                        ],
                        [
                            76.50917968750002,
                            42.918896484375
                        ],
                        [
                            77.05732421875001,
                            42.970654296875
                        ],
                        [
                            77.23554687500001,
                            42.912646484374996
                        ],
                        [
                            78.52421874999999,
                            42.864648437499994
                        ],
                        [
                            79.16484374999999,
                            42.759033203125
                        ],
                        [
                            79.20302734375002,
                            42.666015625
                        ],
                        [
                            79.29550781250003,
                            42.604833984375
                        ],
                        [
                            79.36777343750003,
                            42.547216796875
                        ],
                        [
                            79.49013671875002,
                            42.457568359374996
                        ],
                        [
                            79.92109375000001,
                            42.413134765624996
                        ],
                        [
                            80.0712890625,
                            42.302978515625
                        ],
                        [
                            80.20937500000002,
                            42.1900390625
                        ],
                        [
                            80.24619140625003,
                            42.059814453125
                        ],
                        [
                            80.23515624999999,
                            42.04345703125
                        ],
                        [
                            80.21621093750002,
                            42.032421875
                        ],
                        [
                            79.90966796875,
                            42.014990234375
                        ],
                        [
                            79.84042968750003,
                            41.995751953124994
                        ],
                        [
                            79.29355468750003,
                            41.7828125
                        ],
                        [
                            78.36240234375003,
                            41.371630859374996
                        ],
                        [
                            78.34628906250003,
                            41.2814453125
                        ],
                        [
                            78.12343750000002,
                            41.075634765625
                        ],
                        [
                            77.95644531250002,
                            41.050683593749994
                        ],
                        [
                            77.81523437499999,
                            41.055615234375
                        ],
                        [
                            77.71933593750003,
                            41.024316406249994
                        ],
                        [
                            77.58173828125001,
                            40.9927734375
                        ],
                        [
                            76.98662109374999,
                            41.03916015625
                        ],
                        [
                            76.90771484375,
                            41.024169921875
                        ],
                        [
                            76.82402343749999,
                            40.982324218749994
                        ],
                        [
                            76.48017578125001,
                            40.449511718749996
                        ],
                        [
                            76.39638671875002,
                            40.389794921874994
                        ],
                        [
                            76.31855468750001,
                            40.35224609375
                        ],
                        [
                            76.25830078125,
                            40.43076171875
                        ],
                        [
                            75.87197265625002,
                            40.30322265625
                        ],
                        [
                            75.67714843750002,
                            40.305810546874994
                        ],
                        [
                            75.65595703125001,
                            40.329248046874994
                        ],
                        [
                            75.58349609375,
                            40.605322265625
                        ],
                        [
                            75.0044921875,
                            40.449511718749996
                        ],
                        [
                            74.41191406250002,
                            40.13720703125
                        ],
                        [
                            74.24267578125,
                            40.092041015625
                        ],
                        [
                            74.08515625000001,
                            40.07431640625
                        ],
                        [
                            74.0205078125,
                            40.059374999999996
                        ],
                        [
                            73.99160156250002,
                            40.043115234374994
                        ],
                        [
                            73.93876953125002,
                            39.978808593749996
                        ],
                        [
                            73.88457031250002,
                            39.8779296875
                        ],
                        [
                            73.85625000000002,
                            39.828662109374996
                        ],
                        [
                            73.83535156250002,
                            39.800146484375
                        ],
                        [
                            73.9146484375,
                            39.606494140624996
                        ],
                        [
                            73.87275390625001,
                            39.53330078125
                        ],
                        [
                            73.82294921875001,
                            39.48896484375
                        ],
                        [
                            73.71572265625002,
                            39.462255859375
                        ],
                        [
                            73.63164062500002,
                            39.448876953124994
                        ],
                        [
                            73.47041015625001,
                            39.460595703124994
                        ],
                        [
                            73.38740234375001,
                            39.442724609375
                        ],
                        [
                            73.33613281250001,
                            39.412353515625
                        ],
                        [
                            73.2349609375,
                            39.374560546874996
                        ],
                        [
                            72.63994140625002,
                            39.385986328125
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Kyrgyzstan',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            104.42636718750006,
                            10.411230468749991
                        ],
                        [
                            104.26240234375004,
                            10.541259765625014
                        ],
                        [
                            103.53222656249997,
                            10.604638671874966
                        ],
                        [
                            103.72187500000004,
                            10.890136718750043
                        ],
                        [
                            103.5324218750001,
                            11.146679687499997
                        ],
                        [
                            103.15283203124997,
                            10.913720703125051
                        ],
                        [
                            103.12548828124997,
                            11.460644531250011
                        ],
                        [
                            102.93388671875002,
                            11.706689453125037
                        ],
                        [
                            102.73662109375007,
                            12.089794921875011
                        ],
                        [
                            102.75566406250002,
                            12.42626953125
                        ],
                        [
                            102.49960937500012,
                            12.669970703125003
                        ],
                        [
                            102.31972656250005,
                            13.539990234375054
                        ],
                        [
                            102.72890625,
                            13.841894531250006
                        ],
                        [
                            103.19941406250004,
                            14.332617187499977
                        ],
                        [
                            104.77900390625004,
                            14.427832031250006
                        ],
                        [
                            104.96972656249997,
                            14.366113281250051
                        ],
                        [
                            105.07412109375005,
                            14.227441406250037
                        ],
                        [
                            105.12597656250003,
                            14.280957031250011
                        ],
                        [
                            105.16914062500004,
                            14.336083984374966
                        ],
                        [
                            105.1833007812501,
                            14.346240234374989
                        ],
                        [
                            105.18554687500003,
                            14.319091796874972
                        ],
                        [
                            105.20703125000003,
                            14.259374999999977
                        ],
                        [
                            105.24570312500006,
                            14.200537109374977
                        ],
                        [
                            105.35019531250006,
                            14.109570312500011
                        ],
                        [
                            105.9044921875001,
                            13.924511718750054
                        ],
                        [
                            106.12470703125004,
                            14.049121093750031
                        ],
                        [
                            106.09667968749997,
                            14.127099609375023
                        ],
                        [
                            106.00410156250004,
                            14.262890624999983
                        ],
                        [
                            105.97890625,
                            14.343017578125043
                        ],
                        [
                            106.00839843750012,
                            14.357177734375
                        ],
                        [
                            106.1652343750001,
                            14.372363281249989
                        ],
                        [
                            106.35498046875003,
                            14.454785156249997
                        ],
                        [
                            106.44697265625004,
                            14.515039062500009
                        ],
                        [
                            106.50146484375003,
                            14.578222656250006
                        ],
                        [
                            106.53115234375005,
                            14.549414062499991
                        ],
                        [
                            106.5636718750001,
                            14.505078125000026
                        ],
                        [
                            106.81992187500006,
                            14.314697265625057
                        ],
                        [
                            107.10937499999997,
                            14.416699218750054
                        ],
                        [
                            107.29267578125004,
                            14.592382812500048
                        ],
                        [
                            107.37988281250003,
                            14.555322265625051
                        ],
                        [
                            107.41474609375004,
                            14.562890624999994
                        ],
                        [
                            107.51943359375005,
                            14.705078125
                        ],
                        [
                            107.3314453125,
                            14.126611328125009
                        ],
                        [
                            107.60546874999997,
                            13.437792968750017
                        ],
                        [
                            107.47539062500002,
                            13.030371093749963
                        ],
                        [
                            107.50644531250006,
                            12.364550781250031
                        ],
                        [
                            106.93066406249997,
                            12.077490234374991
                        ],
                        [
                            106.41386718750002,
                            11.948437499999997
                        ],
                        [
                            106.33984374999997,
                            11.68183593750004
                        ],
                        [
                            105.85146484375005,
                            11.635009765625
                        ],
                        [
                            105.85605468750006,
                            11.294287109375048
                        ],
                        [
                            106.16796874999997,
                            11.012304687499977
                        ],
                        [
                            105.38652343750002,
                            10.940087890625009
                        ],
                        [
                            104.85058593749997,
                            10.534472656249974
                        ],
                        [
                            104.42636718750006,
                            10.411230468749991
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Cambodia',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            128.37460937500012,
                            38.623437499999994
                        ],
                        [
                            128.61884765625004,
                            38.17607421874999
                        ],
                        [
                            129.33515625000004,
                            37.274560546874994
                        ],
                        [
                            129.47343750000007,
                            36.74189453125001
                        ],
                        [
                            129.40351562500004,
                            36.052148437499994
                        ],
                        [
                            129.5617187500001,
                            35.94765625000002
                        ],
                        [
                            129.21416015625007,
                            35.18183593749998
                        ],
                        [
                            128.5109375000001,
                            35.10097656250002
                        ],
                        [
                            128.44394531250012,
                            34.87036132812503
                        ],
                        [
                            127.71484374999997,
                            34.95468749999998
                        ],
                        [
                            126.50830078125003,
                            34.35063476562502
                        ],
                        [
                            126.29111328125012,
                            35.154150390625034
                        ],
                        [
                            126.71738281250006,
                            35.76884765625002
                        ],
                        [
                            126.5482421875,
                            36.477636718750006
                        ],
                        [
                            126.21718750000005,
                            36.870947265625006
                        ],
                        [
                            126.74638671875002,
                            37.19355468750001
                        ],
                        [
                            126.63388671875012,
                            37.78183593750006
                        ],
                        [
                            127.16953125000006,
                            38.304541015625034
                        ],
                        [
                            128.03896484375,
                            38.30854492187498
                        ],
                        [
                            128.37460937500012,
                            38.623437499999994
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'South Korea',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            21.5625,
                            42.247509765625
                        ],
                        [
                            21.560839843750017,
                            42.24765625
                        ],
                        [
                            21.389550781250023,
                            42.21982421875
                        ],
                        [
                            21.25634765625,
                            42.099511718749994
                        ],
                        [
                            21.05976562500001,
                            42.171289062499994
                        ],
                        [
                            20.566210937500017,
                            41.873681640624994
                        ],
                        [
                            20.485449218750006,
                            42.223388671875
                        ],
                        [
                            20.06396484375,
                            42.547265624999994
                        ],
                        [
                            20.344335937500006,
                            42.827929687499996
                        ],
                        [
                            21.057031250000023,
                            43.091699218749994
                        ],
                        [
                            21.390625,
                            42.751416015625
                        ],
                        [
                            21.52998046875001,
                            42.349999999999994
                        ],
                        [
                            21.5625,
                            42.247509765625
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Kosovo',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            48.442480468750006,
                            28.542919921874983
                        ],
                        [
                            47.671289062499994,
                            28.53315429687504
                        ],
                        [
                            47.433203125000006,
                            28.989550781250017
                        ],
                        [
                            46.53144531250004,
                            29.09624023437499
                        ],
                        [
                            46.90585937500006,
                            29.537499999999994
                        ],
                        [
                            47.14824218750002,
                            30.0009765625
                        ],
                        [
                            47.67275390625005,
                            30.095605468750023
                        ],
                        [
                            47.75390624999997,
                            30.076611328124955
                        ],
                        [
                            47.97871093750004,
                            29.98281250000005
                        ],
                        [
                            48.00566406250002,
                            29.835791015625034
                        ],
                        [
                            48.13613281250005,
                            29.618115234374983
                        ],
                        [
                            47.96962890625005,
                            29.61669921874997
                        ],
                        [
                            48.18378906250004,
                            28.979394531249994
                        ],
                        [
                            48.442480468750006,
                            28.542919921874983
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Kuwait',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            102.12744140625011,
                            22.37919921874999
                        ],
                        [
                            102.94863281250008,
                            21.569775390625068
                        ],
                        [
                            102.85117187500009,
                            21.26591796874999
                        ],
                        [
                            102.8837890625,
                            21.202587890625068
                        ],
                        [
                            103.1044921875,
                            20.89165039062499
                        ],
                        [
                            103.21074218749999,
                            20.840625000000045
                        ],
                        [
                            103.46357421874995,
                            20.779833984375102
                        ],
                        [
                            103.6350585937501,
                            20.697070312500102
                        ],
                        [
                            104.05205078124999,
                            20.941210937500102
                        ],
                        [
                            104.1953125,
                            20.91396484375008
                        ],
                        [
                            104.349609375,
                            20.82109374999999
                        ],
                        [
                            104.58320312500001,
                            20.646679687499955
                        ],
                        [
                            104.61884765624995,
                            20.374511718750114
                        ],
                        [
                            104.92792968750007,
                            20.01811523437499
                        ],
                        [
                            104.81513671875001,
                            19.90400390625001
                        ],
                        [
                            104.80175781250011,
                            19.836132812500068
                        ],
                        [
                            104.74316406250006,
                            19.754736328124977
                        ],
                        [
                            104.58789062500006,
                            19.618749999999977
                        ],
                        [
                            104.54628906250014,
                            19.610546875000068
                        ],
                        [
                            104.25986328125003,
                            19.685498046875068
                        ],
                        [
                            104.06279296875005,
                            19.678417968750068
                        ],
                        [
                            104.03203124999999,
                            19.67514648437492
                        ],
                        [
                            103.89638671875002,
                            19.339990234375023
                        ],
                        [
                            103.91835937500008,
                            19.268505859375068
                        ],
                        [
                            104.10859375000013,
                            19.195556640625114
                        ],
                        [
                            104.44580078125006,
                            18.983837890625125
                        ],
                        [
                            104.61328125000006,
                            18.860644531250102
                        ],
                        [
                            104.71650390624995,
                            18.803417968749955
                        ],
                        [
                            104.9931640625,
                            18.728320312499932
                        ],
                        [
                            105.11513671875002,
                            18.678857421875023
                        ],
                        [
                            105.146484375,
                            18.650976562499977
                        ],
                        [
                            105.14541015625014,
                            18.616796874999977
                        ],
                        [
                            105.08583984375008,
                            18.450097656250136
                        ],
                        [
                            105.11455078125005,
                            18.405273437500057
                        ],
                        [
                            105.16328125000007,
                            18.338720703124977
                        ],
                        [
                            105.27324218750005,
                            18.235351562500057
                        ],
                        [
                            105.33349609375006,
                            18.18964843750001
                        ],
                        [
                            105.45820312500007,
                            18.154296875000057
                        ],
                        [
                            105.51855468750011,
                            18.077441406250045
                        ],
                        [
                            105.58847656250015,
                            17.983691406249932
                        ],
                        [
                            105.69140625,
                            17.737841796874932
                        ],
                        [
                            106.50224609374999,
                            16.9541015625
                        ],
                        [
                            106.69609375000005,
                            16.458984375
                        ],
                        [
                            106.93066406250006,
                            16.35312499999992
                        ],
                        [
                            107.21738281250003,
                            16.136328124999977
                        ],
                        [
                            107.29648437500015,
                            16.08403320312499
                        ],
                        [
                            107.39638671875008,
                            16.04301757812499
                        ],
                        [
                            107.36064453125005,
                            15.921728515624977
                        ],
                        [
                            107.18886718750008,
                            15.838623046875114
                        ],
                        [
                            107.16591796875002,
                            15.802490234375
                        ],
                        [
                            107.18955078125003,
                            15.747265624999955
                        ],
                        [
                            107.27939453125003,
                            15.618701171875045
                        ],
                        [
                            107.33876953125002,
                            15.560498046875125
                        ],
                        [
                            107.56425781249999,
                            15.3916015625
                        ],
                        [
                            107.62167968750015,
                            15.309863281250045
                        ],
                        [
                            107.65312499999999,
                            15.255224609375091
                        ],
                        [
                            107.63369140625008,
                            15.18984375000008
                        ],
                        [
                            107.58964843749999,
                            15.118457031250102
                        ],
                        [
                            107.55527343750009,
                            15.057031250000023
                        ],
                        [
                            107.48037109375014,
                            14.979882812500136
                        ],
                        [
                            107.5046875000001,
                            14.91591796875008
                        ],
                        [
                            107.52451171875003,
                            14.871826171874943
                        ],
                        [
                            107.51376953124998,
                            14.817382812500057
                        ],
                        [
                            107.51943359375008,
                            14.705078125000114
                        ],
                        [
                            107.46513671875005,
                            14.664990234375125
                        ],
                        [
                            107.41474609375007,
                            14.56289062500008
                        ],
                        [
                            107.37988281250006,
                            14.555322265625136
                        ],
                        [
                            107.29267578125007,
                            14.592382812500034
                        ],
                        [
                            107.10937500000006,
                            14.416699218749955
                        ],
                        [
                            106.81992187500003,
                            14.314697265624943
                        ],
                        [
                            106.56367187500007,
                            14.505078125000011
                        ],
                        [
                            106.53115234375002,
                            14.549414062499977
                        ],
                        [
                            106.50146484375,
                            14.578222656250034
                        ],
                        [
                            106.35498046875,
                            14.454785156250068
                        ],
                        [
                            106.22539062500005,
                            14.476220703125023
                        ],
                        [
                            106.16523437500007,
                            14.372363281249989
                        ],
                        [
                            106.00839843750009,
                            14.357177734375114
                        ],
                        [
                            105.97890625000014,
                            14.343017578125057
                        ],
                        [
                            106.00410156250013,
                            14.262890625000068
                        ],
                        [
                            106.09667968750011,
                            14.127099609375136
                        ],
                        [
                            106.12470703124995,
                            14.049121093750045
                        ],
                        [
                            105.90449218750007,
                            13.924511718750068
                        ],
                        [
                            105.5315429687501,
                            14.156152343750023
                        ],
                        [
                            105.35019531250009,
                            14.109570312500125
                        ],
                        [
                            105.24570312500015,
                            14.200537109374977
                        ],
                        [
                            105.20703125000006,
                            14.259375000000091
                        ],
                        [
                            105.18554687499994,
                            14.319091796875
                        ],
                        [
                            105.18330078125001,
                            14.346240234374989
                        ],
                        [
                            105.24365234375006,
                            14.367871093749955
                        ],
                        [
                            105.34218750000008,
                            14.416699218749955
                        ],
                        [
                            105.47558593750006,
                            14.530126953124977
                        ],
                        [
                            105.51318359375011,
                            15.360888671874932
                        ],
                        [
                            105.61562500000002,
                            15.488281249999943
                        ],
                        [
                            105.62207031250006,
                            15.699951171875114
                        ],
                        [
                            105.46201171875015,
                            15.780419921875136
                        ],
                        [
                            105.39892578125011,
                            15.829882812500102
                        ],
                        [
                            105.37324218750001,
                            15.889697265625045
                        ],
                        [
                            105.40625,
                            15.987451171875023
                        ],
                        [
                            105.33066406250003,
                            16.037890625000045
                        ],
                        [
                            105.1487304687501,
                            16.09355468749999
                        ],
                        [
                            105.04716796874999,
                            16.16025390625009
                        ],
                        [
                            104.81933593749994,
                            16.466064453125057
                        ],
                        [
                            104.75058593750015,
                            16.647558593750034
                        ],
                        [
                            104.75898437500013,
                            17.0771484375
                        ],
                        [
                            104.81601562499998,
                            17.30029296875
                        ],
                        [
                            104.42812500000002,
                            17.698974609375057
                        ],
                        [
                            104.19619140625002,
                            17.988378906250034
                        ],
                        [
                            104.04873046875002,
                            18.216699218749966
                        ],
                        [
                            103.94960937500008,
                            18.318994140625023
                        ],
                        [
                            103.89882812500002,
                            18.295312500000023
                        ],
                        [
                            103.79228515624999,
                            18.31650390625009
                        ],
                        [
                            103.62968750000005,
                            18.382568359375057
                        ],
                        [
                            103.36699218750005,
                            18.42333984375
                        ],
                        [
                            103.05136718750003,
                            18.02851562500001
                        ],
                        [
                            102.71757812500005,
                            17.892236328125136
                        ],
                        [
                            102.35185546874999,
                            18.045947265625045
                        ],
                        [
                            102.23164062500001,
                            18.148974609375045
                        ],
                        [
                            102.14824218750005,
                            18.203857421875057
                        ],
                        [
                            102.10146484375014,
                            18.21064453125001
                        ],
                        [
                            102.03457031250002,
                            18.169824218750023
                        ],
                        [
                            101.94746093750001,
                            18.081494140624955
                        ],
                        [
                            101.87548828125011,
                            18.046435546874932
                        ],
                        [
                            101.81865234375005,
                            18.064648437500125
                        ],
                        [
                            101.77480468750002,
                            18.033398437500125
                        ],
                        [
                            101.10517578125001,
                            17.479541015625102
                        ],
                        [
                            100.9084960937501,
                            17.583886718750023
                        ],
                        [
                            101.11328125000006,
                            18.033544921874977
                        ],
                        [
                            101.0505859375001,
                            18.407031250000045
                        ],
                        [
                            101.06044921875014,
                            18.479003906249943
                        ],
                        [
                            101.10634765625008,
                            18.533544921875034
                        ],
                        [
                            101.16552734375006,
                            18.61831054687508
                        ],
                        [
                            101.28632812499995,
                            18.977148437500034
                        ],
                        [
                            101.19755859374999,
                            19.327929687500045
                        ],
                        [
                            101.22080078125015,
                            19.486621093750045
                        ],
                        [
                            101.15468750000014,
                            19.57919921875009
                        ],
                        [
                            100.62548828125,
                            19.49985351562509
                        ],
                        [
                            100.39765625000013,
                            19.756103515625057
                        ],
                        [
                            100.51455078125014,
                            19.996337890625
                        ],
                        [
                            100.54306640625009,
                            20.08867187499999
                        ],
                        [
                            100.53994140625014,
                            20.13237304687499
                        ],
                        [
                            100.51953125000006,
                            20.177929687500068
                        ],
                        [
                            100.31796875000003,
                            20.385888671875136
                        ],
                        [
                            100.2493164062501,
                            20.730273437499932
                        ],
                        [
                            100.56513671875013,
                            20.82509765625008
                        ],
                        [
                            100.5222656250001,
                            20.921923828125102
                        ],
                        [
                            100.53613281250006,
                            20.992382812500068
                        ],
                        [
                            100.56660156250007,
                            21.038183593749977
                        ],
                        [
                            100.61367187500008,
                            21.059326171875
                        ],
                        [
                            100.65917968750011,
                            21.130371093750114
                        ],
                        [
                            100.703125,
                            21.25136718750008
                        ],
                        [
                            100.75664062500005,
                            21.312646484375136
                        ],
                        [
                            100.81953125000007,
                            21.314208984375057
                        ],
                        [
                            100.92753906250005,
                            21.3662109375
                        ],
                        [
                            101.17539062500009,
                            21.407519531250102
                        ],
                        [
                            101.22441406249999,
                            21.22373046874992
                        ],
                        [
                            101.24785156249993,
                            21.197314453125045
                        ],
                        [
                            101.28144531250007,
                            21.184130859375045
                        ],
                        [
                            101.44355468750001,
                            21.230810546874977
                        ],
                        [
                            101.54238281250008,
                            21.234277343750136
                        ],
                        [
                            101.70478515625013,
                            21.150146484375057
                        ],
                        [
                            101.78349609374999,
                            21.204150390625045
                        ],
                        [
                            101.8005859375001,
                            21.212597656249955
                        ],
                        [
                            101.80205078125005,
                            21.235986328125136
                        ],
                        [
                            101.76308593750014,
                            21.278906249999977
                        ],
                        [
                            101.7229492187501,
                            21.314941406250057
                        ],
                        [
                            101.74726562500007,
                            21.60576171874999
                        ],
                        [
                            101.7439453125001,
                            21.77797851562508
                        ],
                        [
                            101.73652343750001,
                            21.826513671874977
                        ],
                        [
                            101.52451171874998,
                            22.253662109375
                        ],
                        [
                            101.56787109375011,
                            22.2763671875
                        ],
                        [
                            101.6199218750001,
                            22.327441406250102
                        ],
                        [
                            101.67148437500009,
                            22.462304687500023
                        ],
                        [
                            101.70751953125,
                            22.486572265625
                        ],
                        [
                            101.73876953125011,
                            22.495263671874966
                        ],
                        [
                            101.75996093750001,
                            22.490332031250034
                        ],
                        [
                            101.841796875,
                            22.388476562500102
                        ],
                        [
                            102.02441406250006,
                            22.439208984375114
                        ],
                        [
                            102.09150390625007,
                            22.412255859375136
                        ],
                        [
                            102.12744140625011,
                            22.37919921874999
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Laos',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            35.869140625,
                            33.43173828125
                        ],
                        [
                            35.411230468750006,
                            33.07568359375
                        ],
                        [
                            35.10859375000001,
                            33.08369140625
                        ],
                        [
                            35.97626953125001,
                            34.629199218749996
                        ],
                        [
                            36.43300781250002,
                            34.6134765625
                        ],
                        [
                            36.5849609375,
                            34.221240234374996
                        ],
                        [
                            35.9423828125,
                            33.667578125
                        ],
                        [
                            35.869140625,
                            33.43173828125
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Lebanon',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -8.486425781249977,
                            7.558496093749994
                        ],
                        [
                            -8.408740234374989,
                            7.411816406249997
                        ],
                        [
                            -8.324511718749989,
                            6.9200195312499915
                        ],
                        [
                            -8.587890625,
                            6.490527343749989
                        ],
                        [
                            -8.203857421875,
                            6.290722656249997
                        ],
                        [
                            -7.981591796874994,
                            6.2861328125
                        ],
                        [
                            -7.888623046874983,
                            6.23486328125
                        ],
                        [
                            -7.800927734374994,
                            6.0389160156249915
                        ],
                        [
                            -7.730371093749994,
                            5.9190429687499915
                        ],
                        [
                            -7.636132812499994,
                            5.90771484375
                        ],
                        [
                            -7.39990234375,
                            5.550585937499989
                        ],
                        [
                            -7.585058593749977,
                            4.916748046875
                        ],
                        [
                            -7.574658203124983,
                            4.572314453124989
                        ],
                        [
                            -7.544970703124989,
                            4.351318359375
                        ],
                        [
                            -8.259033203125,
                            4.589990234374994
                        ],
                        [
                            -9.132177734374977,
                            5.054638671874997
                        ],
                        [
                            -10.2763671875,
                            6.07763671875
                        ],
                        [
                            -10.785595703124983,
                            6.3101562499999915
                        ],
                        [
                            -10.849023437499994,
                            6.465087890625
                        ],
                        [
                            -11.507519531249983,
                            6.906542968749989
                        ],
                        [
                            -11.267675781249977,
                            7.232617187499997
                        ],
                        [
                            -10.878076171874994,
                            7.538232421874994
                        ],
                        [
                            -10.389550781249994,
                            8.157617187499994
                        ],
                        [
                            -10.283203125,
                            8.485156249999989
                        ],
                        [
                            -9.768261718749983,
                            8.534570312499994
                        ],
                        [
                            -9.518261718749983,
                            8.346093749999994
                        ],
                        [
                            -9.368945312499989,
                            7.639550781249994
                        ],
                        [
                            -9.459765624999989,
                            7.442529296874994
                        ],
                        [
                            -9.117578124999994,
                            7.2159179687499915
                        ],
                        [
                            -8.8896484375,
                            7.2626953125
                        ],
                        [
                            -8.708300781249989,
                            7.658886718749997
                        ],
                        [
                            -8.486425781249977,
                            7.558496093749994
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Liberia',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            25.150488281250006,
                            31.654980468749997
                        ],
                        [
                            24.85273437500001,
                            31.334814453125
                        ],
                        [
                            24.973925781250017,
                            30.776562499999997
                        ],
                        [
                            24.726464843750023,
                            30.2505859375
                        ],
                        [
                            24.980273437500017,
                            29.181884765625
                        ],
                        [
                            24.980273437500017,
                            26.487109374999996
                        ],
                        [
                            24.980273437500017,
                            24.466064453125
                        ],
                        [
                            24.980273437500017,
                            21.995849609375
                        ],
                        [
                            24.9794921875,
                            20.002587890624994
                        ],
                        [
                            23.980273437500017,
                            19.99594726562499
                        ],
                        [
                            23.980273437500017,
                            19.496630859375003
                        ],
                        [
                            21.5849609375,
                            20.679492187500003
                        ],
                        [
                            19.668554687500006,
                            21.62583007812499
                        ],
                        [
                            18.231347656250023,
                            22.33554687499999
                        ],
                        [
                            15.984082031250011,
                            23.445214843749994
                        ],
                        [
                            14.97900390625,
                            22.99619140624999
                        ],
                        [
                            14.230761718750017,
                            22.618457031250003
                        ],
                        [
                            13.481250000000017,
                            23.18017578125
                        ],
                        [
                            11.967871093750006,
                            23.517871093750003
                        ],
                        [
                            11.536914062500017,
                            24.290820312500003
                        ],
                        [
                            10.686132812500006,
                            24.55136718749999
                        ],
                        [
                            10.255859375,
                            24.591015624999997
                        ],
                        [
                            10.000683593750011,
                            25.332080078125003
                        ],
                        [
                            9.4482421875,
                            26.067138671875
                        ],
                        [
                            9.491406250000011,
                            26.333740234375
                        ],
                        [
                            9.859375,
                            26.551953124999997
                        ],
                        [
                            9.74755859375,
                            27.330859374999996
                        ],
                        [
                            9.916015625,
                            27.785693359374996
                        ],
                        [
                            9.842578125000017,
                            28.966992187499997
                        ],
                        [
                            9.672656250000017,
                            29.5669921875
                        ],
                        [
                            9.310253906250011,
                            30.115234375
                        ],
                        [
                            9.518750000000011,
                            30.229394531249994
                        ],
                        [
                            9.637988281250017,
                            30.28232421875
                        ],
                        [
                            9.807421875000017,
                            30.342236328124997
                        ],
                        [
                            9.89501953125,
                            30.3873046875
                        ],
                        [
                            9.932519531250023,
                            30.425341796874996
                        ],
                        [
                            10.059765625000011,
                            30.580078125
                        ],
                        [
                            10.216406250000006,
                            30.783203125
                        ],
                        [
                            10.114941406250011,
                            31.463769531249994
                        ],
                        [
                            10.274609375000011,
                            31.684960937499994
                        ],
                        [
                            10.475781250000011,
                            31.736035156249997
                        ],
                        [
                            10.60888671875,
                            31.929541015625
                        ],
                        [
                            10.826367187500011,
                            32.0806640625
                        ],
                        [
                            11.005175781250017,
                            32.172705078125
                        ],
                        [
                            11.168261718750017,
                            32.256738281249994
                        ],
                        [
                            11.358007812500006,
                            32.34521484375
                        ],
                        [
                            11.504980468750006,
                            32.413671875
                        ],
                        [
                            11.535937500000017,
                            32.47333984375
                        ],
                        [
                            11.533789062500006,
                            32.524951171874996
                        ],
                        [
                            11.453906250000017,
                            32.642578125
                        ],
                        [
                            11.453906250000017,
                            32.781689453125
                        ],
                        [
                            11.467187500000023,
                            32.965722656249994
                        ],
                        [
                            11.504589843750011,
                            33.181933593749996
                        ],
                        [
                            11.657128906250023,
                            33.118896484375
                        ],
                        [
                            11.8134765625,
                            33.093701171875
                        ],
                        [
                            12.279882812500006,
                            32.858544921874994
                        ],
                        [
                            12.753515625000006,
                            32.801074218749996
                        ],
                        [
                            13.283496093750017,
                            32.9146484375
                        ],
                        [
                            14.237109375000017,
                            32.68125
                        ],
                        [
                            14.513378906250011,
                            32.511083984375
                        ],
                        [
                            15.176562500000017,
                            32.391162109374996
                        ],
                        [
                            15.496386718750017,
                            31.656787109374996
                        ],
                        [
                            16.123046875,
                            31.264453124999996
                        ],
                        [
                            17.349218750000006,
                            31.081494140624997
                        ],
                        [
                            18.1904296875,
                            30.777294921874997
                        ],
                        [
                            18.936425781250023,
                            30.290429687499994
                        ],
                        [
                            19.29169921875001,
                            30.2880859375
                        ],
                        [
                            19.713281250000023,
                            30.48837890625
                        ],
                        [
                            20.150976562500006,
                            31.07861328125
                        ],
                        [
                            19.926367187500006,
                            31.817529296874994
                        ],
                        [
                            20.121484375000023,
                            32.21875
                        ],
                        [
                            20.62109375,
                            32.58017578125
                        ],
                        [
                            21.63593750000001,
                            32.937304687499996
                        ],
                        [
                            22.187402343750023,
                            32.918261718749996
                        ],
                        [
                            23.090625000000017,
                            32.61875
                        ],
                        [
                            23.28632812500001,
                            32.213818359375
                        ],
                        [
                            24.129687500000017,
                            32.009228515625
                        ],
                        [
                            24.683886718750017,
                            32.015966796875
                        ],
                        [
                            25.150488281250006,
                            31.654980468749997
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Libya',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            79.98232421875,
                            9.812695312500011
                        ],
                        [
                            80.25283203125005,
                            9.796337890625054
                        ],
                        [
                            80.71113281250004,
                            9.366357421875023
                        ],
                        [
                            81.37285156250002,
                            8.431445312499989
                        ],
                        [
                            81.4359375,
                            8.118896484374986
                        ],
                        [
                            81.87412109375012,
                            7.288330078124986
                        ],
                        [
                            81.86142578125012,
                            6.901269531249994
                        ],
                        [
                            81.63740234375004,
                            6.4251464843749915
                        ],
                        [
                            80.72412109375003,
                            5.97905273437496
                        ],
                        [
                            80.09531250000012,
                            6.153173828125006
                        ],
                        [
                            79.85937499999997,
                            6.829296874999983
                        ],
                        [
                            79.79208984375006,
                            7.585205078124972
                        ],
                        [
                            79.92890625000004,
                            8.899218749999974
                        ],
                        [
                            80.1183593750001,
                            9.326855468749969
                        ],
                        [
                            79.98232421875,
                            9.812695312500011
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Sri Lanka',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            28.128710937500017,
                            -30.52509765625001
                        ],
                        [
                            28.05683593750001,
                            -30.63105468750001
                        ],
                        [
                            27.75312500000001,
                            -30.60000000000001
                        ],
                        [
                            27.364062500000017,
                            -30.27919921875001
                        ],
                        [
                            27.19355468750001,
                            -29.94130859375001
                        ],
                        [
                            27.294531250000006,
                            -29.519335937500003
                        ],
                        [
                            27.73554687500001,
                            -28.940039062500006
                        ],
                        [
                            28.625781250000017,
                            -28.58173828125001
                        ],
                        [
                            29.301367187500006,
                            -29.08984375
                        ],
                        [
                            29.370898437500017,
                            -29.21845703125001
                        ],
                        [
                            29.390722656250006,
                            -29.26972656250001
                        ],
                        [
                            29.38671875,
                            -29.31972656250001
                        ],
                        [
                            29.34882812500001,
                            -29.441992187500006
                        ],
                        [
                            29.293554687500006,
                            -29.56689453125
                        ],
                        [
                            29.142187500000006,
                            -29.700976562500003
                        ],
                        [
                            29.098046875000023,
                            -29.919042968750006
                        ],
                        [
                            28.39208984375,
                            -30.147558593750006
                        ],
                        [
                            28.128710937500017,
                            -30.52509765625001
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Lesotho',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            25.573046875000017,
                            54.139892578125
                        ],
                        [
                            24.768164062499977,
                            53.97465820312499
                        ],
                        [
                            24.317968750000006,
                            53.892968749999966
                        ],
                        [
                            24.236621093750045,
                            53.91997070312496
                        ],
                        [
                            24.19130859375005,
                            53.95043945312503
                        ],
                        [
                            23.484667968750074,
                            53.939794921875006
                        ],
                        [
                            23.453613281250057,
                            54.14345703125002
                        ],
                        [
                            23.01552734375005,
                            54.34833984375001
                        ],
                        [
                            22.766210937499977,
                            54.356787109375034
                        ],
                        [
                            22.83125000000001,
                            54.838476562500034
                        ],
                        [
                            21.235742187500023,
                            55.26411132812498
                        ],
                        [
                            21.06191406250005,
                            55.81342773437498
                        ],
                        [
                            21.053808593750006,
                            56.02294921875003
                        ],
                        [
                            21.04609375000004,
                            56.07006835937503
                        ],
                        [
                            21.31464843750004,
                            56.18813476562502
                        ],
                        [
                            21.65351562500004,
                            56.314550781250006
                        ],
                        [
                            22.084570312500034,
                            56.40673828125006
                        ],
                        [
                            22.96826171875003,
                            56.38041992187502
                        ],
                        [
                            23.042968750000057,
                            56.324072265625006
                        ],
                        [
                            23.119824218749983,
                            56.330664062500006
                        ],
                        [
                            23.195898437500034,
                            56.36713867187498
                        ],
                        [
                            23.81269531250001,
                            56.329248046874994
                        ],
                        [
                            24.120703125000063,
                            56.26425781249998
                        ],
                        [
                            24.90302734375001,
                            56.398193359375
                        ],
                        [
                            25.069921875000006,
                            56.20039062500004
                        ],
                        [
                            25.585742187500017,
                            56.13017578125002
                        ],
                        [
                            26.281250000000057,
                            55.75043945312501
                        ],
                        [
                            26.40107421875004,
                            55.70380859375001
                        ],
                        [
                            26.54287109375005,
                            55.67241210937502
                        ],
                        [
                            26.593554687500074,
                            55.66752929687502
                        ],
                        [
                            26.590820312500057,
                            55.622656250000006
                        ],
                        [
                            26.56660156250001,
                            55.546484375000034
                        ],
                        [
                            26.51923828125004,
                            55.448144531249994
                        ],
                        [
                            26.469531250000045,
                            55.371923828125006
                        ],
                        [
                            26.457617187500006,
                            55.342480468749955
                        ],
                        [
                            26.175195312500023,
                            55.003271484375034
                        ],
                        [
                            26.092968750000068,
                            54.96230468750005
                        ],
                        [
                            25.964453124999977,
                            54.947167968749966
                        ],
                        [
                            25.85927734375005,
                            54.91928710937498
                        ],
                        [
                            25.573046875000017,
                            54.139892578125
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Lithuania',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            6.4873046875,
                            49.798486328124994
                        ],
                        [
                            6.348437500000017,
                            49.5126953125
                        ],
                        [
                            6.344335937500006,
                            49.452734375
                        ],
                        [
                            6.27734375,
                            49.4775390625
                        ],
                        [
                            6.2421875,
                            49.4943359375
                        ],
                        [
                            6.119921875000017,
                            49.485205078125
                        ],
                        [
                            6.074121093750023,
                            49.454638671874996
                        ],
                        [
                            5.95947265625,
                            49.454638671874996
                        ],
                        [
                            5.928906250000011,
                            49.4775390625
                        ],
                        [
                            5.9013671875,
                            49.48974609375
                        ],
                        [
                            5.823437500000011,
                            49.505078125
                        ],
                        [
                            5.789746093750011,
                            49.53828125
                        ],
                        [
                            5.88037109375,
                            49.644775390625
                        ],
                        [
                            5.8037109375,
                            49.732177734375
                        ],
                        [
                            5.787988281250023,
                            49.75888671875
                        ],
                        [
                            5.725000000000023,
                            49.80830078125
                        ],
                        [
                            5.725781250000011,
                            49.833349609375
                        ],
                        [
                            5.740820312500006,
                            49.857177734375
                        ],
                        [
                            5.735253906250023,
                            49.875634765624994
                        ],
                        [
                            5.744042968750023,
                            49.91962890625
                        ],
                        [
                            5.7880859375,
                            49.961230468749996
                        ],
                        [
                            5.8173828125,
                            50.0126953125
                        ],
                        [
                            5.866894531250011,
                            50.082812499999996
                        ],
                        [
                            5.976269531250011,
                            50.1671875
                        ],
                        [
                            6.089062500000011,
                            50.154589843749996
                        ],
                        [
                            6.116503906250017,
                            50.120996093749994
                        ],
                        [
                            6.108300781250023,
                            50.09423828125
                        ],
                        [
                            6.109765625000023,
                            50.034375
                        ],
                        [
                            6.13818359375,
                            49.97431640625
                        ],
                        [
                            6.256054687500011,
                            49.87216796875
                        ],
                        [
                            6.4873046875,
                            49.798486328124994
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Luxembourg',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            28.14794921875,
                            56.142919921875
                        ],
                        [
                            27.576757812500006,
                            55.798779296875
                        ],
                        [
                            27.052539062500017,
                            55.83056640625
                        ],
                        [
                            26.82246093750001,
                            55.709228515625
                        ],
                        [
                            26.620214843750006,
                            55.679638671875
                        ],
                        [
                            26.593554687500017,
                            55.667529296874996
                        ],
                        [
                            26.542871093750023,
                            55.672412109374996
                        ],
                        [
                            26.40107421875001,
                            55.70380859375
                        ],
                        [
                            26.28125,
                            55.750439453125
                        ],
                        [
                            25.585742187500017,
                            56.130175781249996
                        ],
                        [
                            25.069921875000006,
                            56.200390625
                        ],
                        [
                            24.90302734375001,
                            56.398193359375
                        ],
                        [
                            24.120703125000006,
                            56.2642578125
                        ],
                        [
                            23.81269531250001,
                            56.329248046875
                        ],
                        [
                            23.195898437500006,
                            56.367138671875
                        ],
                        [
                            23.11982421875001,
                            56.3306640625
                        ],
                        [
                            23.04296875,
                            56.324072265625
                        ],
                        [
                            22.96826171875,
                            56.380419921874996
                        ],
                        [
                            22.084570312500006,
                            56.40673828125
                        ],
                        [
                            21.730566406250006,
                            56.325976562499996
                        ],
                        [
                            21.65351562500001,
                            56.31455078125
                        ],
                        [
                            21.31464843750001,
                            56.188134765625
                        ],
                        [
                            21.04609375000001,
                            56.070068359375
                        ],
                        [
                            21.0712890625,
                            56.82373046875
                        ],
                        [
                            21.72871093750001,
                            57.57099609375
                        ],
                        [
                            22.554589843750023,
                            57.724267578125
                        ],
                        [
                            23.647753906250017,
                            56.971044921875
                        ],
                        [
                            24.403222656250023,
                            57.324999999999996
                        ],
                        [
                            24.322558593750017,
                            57.87060546875
                        ],
                        [
                            24.775781250000023,
                            57.98525390625
                        ],
                        [
                            24.83906250000001,
                            57.988720703125
                        ],
                        [
                            24.91132812500001,
                            58.00458984375
                        ],
                        [
                            25.11103515625001,
                            58.063427734375
                        ],
                        [
                            25.66015625,
                            57.920166015625
                        ],
                        [
                            26.29804687500001,
                            57.60107421875
                        ],
                        [
                            26.899804687500023,
                            57.6087890625
                        ],
                        [
                            26.96601562500001,
                            57.609130859375
                        ],
                        [
                            27.03339843750001,
                            57.578759765625
                        ],
                        [
                            27.187109375000006,
                            57.538330078125
                        ],
                        [
                            27.326562500000023,
                            57.52548828125
                        ],
                        [
                            27.4697265625,
                            57.5240234375
                        ],
                        [
                            27.511132812500023,
                            57.508154296875
                        ],
                        [
                            27.538671875000006,
                            57.42978515625
                        ],
                        [
                            27.796875,
                            57.316943359374996
                        ],
                        [
                            27.814550781250006,
                            57.16689453125
                        ],
                        [
                            27.639453125000017,
                            56.845654296875
                        ],
                        [
                            27.806054687500023,
                            56.86708984375
                        ],
                        [
                            28.14794921875,
                            56.142919921875
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Latvia',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -1.16259765625,
                            32.399169921875
                        ],
                        [
                            -1.275341796874983,
                            32.089013671874966
                        ],
                        [
                            -1.8169921874999204,
                            32.10478515624999
                        ],
                        [
                            -2.0728027343749886,
                            32.11503906250002
                        ],
                        [
                            -2.4483886718749375,
                            32.12998046875006
                        ],
                        [
                            -2.523242187500017,
                            32.12568359374998
                        ],
                        [
                            -2.8634277343749375,
                            32.07470703124997
                        ],
                        [
                            -2.930859374999926,
                            32.04252929687499
                        ],
                        [
                            -2.988232421874983,
                            31.874218749999983
                        ],
                        [
                            -3.01738281249996,
                            31.834277343750017
                        ],
                        [
                            -3.439794921874949,
                            31.704541015624983
                        ],
                        [
                            -3.8267578124999204,
                            31.66191406249999
                        ],
                        [
                            -3.837109374999983,
                            31.512353515624994
                        ],
                        [
                            -3.8333984374999375,
                            31.197802734375045
                        ],
                        [
                            -3.6725097656249375,
                            31.111376953125017
                        ],
                        [
                            -3.6269042968749545,
                            31.000927734374983
                        ],
                        [
                            -3.666796874999932,
                            30.964013671875023
                        ],
                        [
                            -3.7020019531249773,
                            30.944482421875023
                        ],
                        [
                            -3.9853515624999716,
                            30.91352539062504
                        ],
                        [
                            -4.148779296874977,
                            30.8095703125
                        ],
                        [
                            -4.322851562500006,
                            30.698876953124994
                        ],
                        [
                            -4.52915039062492,
                            30.62553710937499
                        ],
                        [
                            -4.778515624999926,
                            30.552392578124994
                        ],
                        [
                            -4.968261718749943,
                            30.465380859375045
                        ],
                        [
                            -5.0619140624999375,
                            30.326416015625057
                        ],
                        [
                            -5.1801269531249545,
                            30.166162109374994
                        ],
                        [
                            -5.293652343749983,
                            30.058642578125045
                        ],
                        [
                            -5.44877929687496,
                            29.956933593750023
                        ],
                        [
                            -5.774999999999977,
                            29.86904296875005
                        ],
                        [
                            -6.500878906249994,
                            29.809130859375017
                        ],
                        [
                            -6.507910156250006,
                            29.783789062500006
                        ],
                        [
                            -6.5106933593749545,
                            29.726025390624955
                        ],
                        [
                            -6.520556640624989,
                            29.659863281249983
                        ],
                        [
                            -6.635351562499949,
                            29.568798828124983
                        ],
                        [
                            -6.755126953125,
                            29.583837890625034
                        ],
                        [
                            -6.855566406249949,
                            29.601611328125017
                        ],
                        [
                            -7.0949218749999545,
                            29.62519531250001
                        ],
                        [
                            -7.23491210937496,
                            29.574902343749983
                        ],
                        [
                            -7.485742187499994,
                            29.392236328124994
                        ],
                        [
                            -7.685156249999949,
                            29.349511718749994
                        ],
                        [
                            -7.998925781249994,
                            29.132421874999977
                        ],
                        [
                            -8.265185546874989,
                            28.980517578125045
                        ],
                        [
                            -8.659912109375,
                            28.718603515625063
                        ],
                        [
                            -8.683349609375,
                            27.900390625
                        ],
                        [
                            -8.817822265624955,
                            27.65644531250004
                        ],
                        [
                            -8.817773437499937,
                            27.655908203125023
                        ],
                        [
                            -8.813916015624955,
                            27.613867187500063
                        ],
                        [
                            -8.784570312499994,
                            27.530859375000034
                        ],
                        [
                            -8.774365234374983,
                            27.460546875000006
                        ],
                        [
                            -8.753857421874955,
                            27.191015624999977
                        ],
                        [
                            -8.753857421874955,
                            27.150976562500006
                        ],
                        [
                            -8.794873046874983,
                            27.120703125000034
                        ],
                        [
                            -9.001904296874955,
                            27.090429687500006
                        ],
                        [
                            -9.413037109374955,
                            27.088476562500063
                        ],
                        [
                            -9.7353515625,
                            26.860937500000063
                        ],
                        [
                            -10.251464843749972,
                            26.860937500000063
                        ],
                        [
                            -10.922802734374983,
                            27.010351562500034
                        ],
                        [
                            -11.392578125000028,
                            26.883398437500006
                        ],
                        [
                            -11.361279296874955,
                            26.793554687500006
                        ],
                        [
                            -11.316845703124955,
                            26.744726562500006
                        ],
                        [
                            -11.337890624999972,
                            26.633398437499977
                        ],
                        [
                            -11.718212890624926,
                            26.104101562500063
                        ],
                        [
                            -11.754882812499943,
                            26.086523437500034
                        ],
                        [
                            -11.880859375000028,
                            26.070898437500034
                        ],
                        [
                            -12.03076171875,
                            26.030859375000063
                        ],
                        [
                            -12.310986328124983,
                            25.110937500000063
                        ],
                        [
                            -12.360839843750028,
                            24.970312500000006
                        ],
                        [
                            -12.40087890625,
                            24.880468750000006
                        ],
                        [
                            -12.431152343749943,
                            24.830664062500063
                        ],
                        [
                            -12.500976562499972,
                            24.770117187499977
                        ],
                        [
                            -12.630810546874955,
                            24.680273437499977
                        ],
                        [
                            -12.710937500000028,
                            24.630468750000034
                        ],
                        [
                            -12.991162109375011,
                            24.470312500000006
                        ],
                        [
                            -13.061035156249943,
                            24.400976562500006
                        ],
                        [
                            -13.310986328124955,
                            23.981054687499977
                        ],
                        [
                            -13.391113281249943,
                            23.941015625000006
                        ],
                        [
                            -13.5810546875,
                            23.870703124999977
                        ],
                        [
                            -13.661083984374955,
                            23.830664062500006
                        ],
                        [
                            -13.770947265624955,
                            23.790625000000006
                        ],
                        [
                            -13.840771484374983,
                            23.750585937500034
                        ],
                        [
                            -13.891113281250028,
                            23.691015625000063
                        ],
                        [
                            -14.020996093750028,
                            23.410742187499977
                        ],
                        [
                            -14.10107421875,
                            23.100195312500034
                        ],
                        [
                            -14.121093749999972,
                            22.960546875000006
                        ],
                        [
                            -14.1708984375,
                            22.760351562499977
                        ],
                        [
                            -14.221191406249943,
                            22.310156250000034
                        ],
                        [
                            -14.52099609375,
                            21.990869140624994
                        ],
                        [
                            -14.670849609374983,
                            21.600195312499977
                        ],
                        [
                            -14.750976562499972,
                            21.500585937500034
                        ],
                        [
                            -14.840820312499972,
                            21.450781250000006
                        ],
                        [
                            -14.971142578124955,
                            21.441015625000063
                        ],
                        [
                            -15.15087890625,
                            21.441015625000063
                        ],
                        [
                            -15.4609375,
                            21.450781250000006
                        ],
                        [
                            -15.610791015624983,
                            21.470312499999977
                        ],
                        [
                            -15.750927734374926,
                            21.490820312499977
                        ],
                        [
                            -16.73095703125003,
                            21.470312499999977
                        ],
                        [
                            -16.951123046874926,
                            21.430273437500006
                        ],
                        [
                            -17.003076171874937,
                            21.420703125000017
                        ],
                        [
                            -16.930859374999983,
                            21.900000000000034
                        ],
                        [
                            -16.514404296874943,
                            22.33349609374997
                        ],
                        [
                            -16.21025390624999,
                            23.097900390625
                        ],
                        [
                            -15.586328124999966,
                            24.07275390625003
                        ],
                        [
                            -15.038867187499989,
                            24.54882812500003
                        ],
                        [
                            -14.856054687499977,
                            24.871582031250057
                        ],
                        [
                            -14.794921874999943,
                            25.404150390625006
                        ],
                        [
                            -14.413867187499932,
                            26.25371093749999
                        ],
                        [
                            -13.575781250000006,
                            26.735107421875
                        ],
                        [
                            -13.175976562499983,
                            27.655712890624983
                        ],
                        [
                            -12.948925781249926,
                            27.914160156250034
                        ],
                        [
                            -11.986083984374972,
                            28.129296874999994
                        ],
                        [
                            -11.430175781249943,
                            28.382031249999983
                        ],
                        [
                            -11.080957031249937,
                            28.713769531249966
                        ],
                        [
                            -10.486474609374994,
                            29.06494140625
                        ],
                        [
                            -9.667089843749949,
                            30.10927734375005
                        ],
                        [
                            -9.652929687499977,
                            30.447558593750045
                        ],
                        [
                            -9.875488281249943,
                            30.717919921874966
                        ],
                        [
                            -9.80869140624992,
                            31.42460937499996
                        ],
                        [
                            -9.347460937499932,
                            32.086376953124955
                        ],
                        [
                            -9.245849609375,
                            32.572460937499955
                        ],
                        [
                            -8.301171874999937,
                            33.37436523437498
                        ],
                        [
                            -7.562353515624977,
                            33.640283203124994
                        ],
                        [
                            -6.900976562499949,
                            33.96904296874999
                        ],
                        [
                            -6.353125000000006,
                            34.77607421875001
                        ],
                        [
                            -5.924804687499943,
                            35.78579101562502
                        ],
                        [
                            -5.397363281249966,
                            35.929882812499955
                        ],
                        [
                            -5.105371093749994,
                            35.46777343749997
                        ],
                        [
                            -4.3299804687499375,
                            35.161474609375006
                        ],
                        [
                            -3.6932617187499943,
                            35.27998046874998
                        ],
                        [
                            -2.839941406249949,
                            35.127832031249994
                        ],
                        [
                            -2.4237304687499943,
                            35.12348632812498
                        ],
                        [
                            -2.219628906249966,
                            35.10419921874998
                        ],
                        [
                            -2.131787109374926,
                            34.970849609374994
                        ],
                        [
                            -1.9208984374999432,
                            34.835546875000034
                        ],
                        [
                            -1.795605468749926,
                            34.751904296874955
                        ],
                        [
                            -1.706933593749966,
                            34.17607421874999
                        ],
                        [
                            -1.67919921875,
                            33.31865234375002
                        ],
                        [
                            -1.5507324218749545,
                            33.073583984375006
                        ],
                        [
                            -1.510009765625,
                            32.877636718749955
                        ],
                        [
                            -1.16259765625,
                            32.399169921875
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Morocco',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            28.212500000000006,
                            45.450439453125
                        ],
                        [
                            28.099707031250006,
                            45.972607421875
                        ],
                        [
                            28.24433593750001,
                            46.45126953125
                        ],
                        [
                            28.07177734375,
                            46.978417968749994
                        ],
                        [
                            27.3369140625,
                            47.63974609375
                        ],
                        [
                            26.980761718750017,
                            48.155029296875
                        ],
                        [
                            26.618945312500017,
                            48.25986328125
                        ],
                        [
                            27.549218750000023,
                            48.477734375
                        ],
                        [
                            28.34052734375001,
                            48.144433593749994
                        ],
                        [
                            28.53046875000001,
                            48.150292968749994
                        ],
                        [
                            28.601660156250006,
                            48.144384765625
                        ],
                        [
                            28.773828125000023,
                            48.119580078125
                        ],
                        [
                            28.865820312500006,
                            47.995654296874996
                        ],
                        [
                            28.97333984375001,
                            47.933007812499994
                        ],
                        [
                            29.19482421875,
                            47.882421875
                        ],
                        [
                            29.21113281250001,
                            47.775
                        ],
                        [
                            29.210742187500017,
                            47.73154296875
                        ],
                        [
                            29.18603515625,
                            47.658593749999994
                        ],
                        [
                            29.122949218750023,
                            47.53037109375
                        ],
                        [
                            29.159765625000006,
                            47.4556640625
                        ],
                        [
                            29.455664062500006,
                            47.292626953124994
                        ],
                        [
                            29.57197265625001,
                            46.964013671874994
                        ],
                        [
                            29.877832031250023,
                            46.828906249999996
                        ],
                        [
                            29.942480468750006,
                            46.723779296874994
                        ],
                        [
                            29.93476562500001,
                            46.625
                        ],
                        [
                            29.92431640625,
                            46.538867187499996
                        ],
                        [
                            29.837890625,
                            46.350537109375
                        ],
                        [
                            29.458789062500017,
                            46.453759765624994
                        ],
                        [
                            29.146289062500017,
                            46.526904296874996
                        ],
                        [
                            28.92744140625001,
                            46.424121093749996
                        ],
                        [
                            28.930566406250023,
                            46.362255859375
                        ],
                        [
                            28.943750000000023,
                            46.288427734375
                        ],
                        [
                            29.006250000000023,
                            46.17646484375
                        ],
                        [
                            28.97187500000001,
                            46.12763671875
                        ],
                        [
                            28.94775390625,
                            46.049951171874994
                        ],
                        [
                            28.849511718750023,
                            45.978662109374994
                        ],
                        [
                            28.73876953125,
                            45.937158203124994
                        ],
                        [
                            28.491601562500023,
                            45.665771484375
                        ],
                        [
                            28.513769531250006,
                            45.572412109374994
                        ],
                        [
                            28.4990234375,
                            45.517724609374994
                        ],
                        [
                            28.47138671875001,
                            45.507177734375
                        ],
                        [
                            28.310351562500017,
                            45.498583984374996
                        ],
                        [
                            28.26484375000001,
                            45.48388671875
                        ],
                        [
                            28.212500000000006,
                            45.450439453125
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Moldova',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            49.53828125000004,
                            -12.432128906250014
                        ],
                        [
                            49.93750000000003,
                            -13.072265624999957
                        ],
                        [
                            50.17382812500003,
                            -14.040234374999983
                        ],
                        [
                            50.23535156249997,
                            -14.732031249999963
                        ],
                        [
                            50.482714843750074,
                            -15.385644531249994
                        ],
                        [
                            50.20898437499997,
                            -15.960449218750028
                        ],
                        [
                            49.89257812500003,
                            -15.457714843750011
                        ],
                        [
                            49.64990234374997,
                            -15.566992187499991
                        ],
                        [
                            49.83105468750003,
                            -16.255859375
                        ],
                        [
                            49.76718750000006,
                            -16.815136718749983
                        ],
                        [
                            49.43710937500006,
                            -17.3466796875
                        ],
                        [
                            49.477832031250074,
                            -17.89853515624999
                        ],
                        [
                            48.60703125000006,
                            -20.457519531249957
                        ],
                        [
                            48.35078125000004,
                            -21.349023437500037
                        ],
                        [
                            47.9083984375001,
                            -22.465820312500014
                        ],
                        [
                            47.55800781250005,
                            -23.874609374999963
                        ],
                        [
                            47.177343750000006,
                            -24.787207031249977
                        ],
                        [
                            46.72851562499997,
                            -25.14990234374997
                        ],
                        [
                            46.15869140624997,
                            -25.230371093750023
                        ],
                        [
                            45.5080078125001,
                            -25.56318359374997
                        ],
                        [
                            45.11523437500003,
                            -25.543066406249963
                        ],
                        [
                            44.81289062500005,
                            -25.33417968750004
                        ],
                        [
                            44.34589843750004,
                            -25.226074218749957
                        ],
                        [
                            44.0353515625001,
                            -24.995703125000006
                        ],
                        [
                            43.68750000000003,
                            -24.357910156250014
                        ],
                        [
                            43.722265625000006,
                            -23.529687500000037
                        ],
                        [
                            43.397851562499994,
                            -22.886328124999963
                        ],
                        [
                            43.26660156249997,
                            -22.04931640624997
                        ],
                        [
                            43.50185546875005,
                            -21.356445312499957
                        ],
                        [
                            43.800195312499994,
                            -21.179199218749986
                        ],
                        [
                            44.239648437499994,
                            -20.379687500000003
                        ],
                        [
                            44.40468750000005,
                            -19.922070312500026
                        ],
                        [
                            44.44882812500006,
                            -19.42871093749997
                        ],
                        [
                            44.23876953124997,
                            -19.075195312499986
                        ],
                        [
                            44.23310546875004,
                            -18.740625000000037
                        ],
                        [
                            44.04003906249997,
                            -18.288476562500023
                        ],
                        [
                            43.979394531249994,
                            -17.3916015625
                        ],
                        [
                            44.42138671874997,
                            -16.70263671874997
                        ],
                        [
                            44.476171875000006,
                            -16.217285156249957
                        ],
                        [
                            44.90917968749997,
                            -16.174511718750026
                        ],
                        [
                            45.16679687500002,
                            -15.982812499999966
                        ],
                        [
                            45.598242187500006,
                            -15.992578125000009
                        ],
                        [
                            45.70019531249997,
                            -15.813769531249989
                        ],
                        [
                            46.331445312499994,
                            -15.713671875000031
                        ],
                        [
                            46.47509765625003,
                            -15.513476562500003
                        ],
                        [
                            47.81152343749997,
                            -14.544824218750023
                        ],
                        [
                            47.995507812499994,
                            -13.960449218749986
                        ],
                        [
                            48.796484375000006,
                            -13.267480468750023
                        ],
                        [
                            48.89960937500004,
                            -12.458496093749972
                        ],
                        [
                            49.20703124999997,
                            -12.079589843749957
                        ],
                        [
                            49.53828125000004,
                            -12.432128906250014
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Madagascar',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -97.14624023437494,
                            25.961474609375045
                        ],
                        [
                            -97.50708007812503,
                            25.01455078125005
                        ],
                        [
                            -97.66767578124995,
                            24.389990234374977
                        ],
                        [
                            -97.85781249999997,
                            22.62451171875003
                        ],
                        [
                            -97.63754882812495,
                            21.603662109374966
                        ],
                        [
                            -97.12143554687498,
                            20.614990234375
                        ],
                        [
                            -96.45605468749994,
                            19.869775390624966
                        ],
                        [
                            -96.28955078124994,
                            19.34375
                        ],
                        [
                            -95.82109374999999,
                            18.75463867187497
                        ],
                        [
                            -95.18183593749995,
                            18.700732421875017
                        ],
                        [
                            -94.79814453124996,
                            18.51459960937501
                        ],
                        [
                            -94.54619140624996,
                            18.17485351562499
                        ],
                        [
                            -94.18901367187493,
                            18.195263671874983
                        ],
                        [
                            -93.55234375,
                            18.430468750000017
                        ],
                        [
                            -92.88476562499997,
                            18.468652343749966
                        ],
                        [
                            -92.710107421875,
                            18.611669921874977
                        ],
                        [
                            -91.97377929687502,
                            18.715869140625074
                        ],
                        [
                            -91.80297851562503,
                            18.470605468750023
                        ],
                        [
                            -91.27524414062498,
                            18.62446289062501
                        ],
                        [
                            -91.34306640624996,
                            18.900585937499955
                        ],
                        [
                            -90.73925781249994,
                            19.352246093749955
                        ],
                        [
                            -90.507080078125,
                            19.911865234375
                        ],
                        [
                            -90.48413085937503,
                            20.55634765625004
                        ],
                        [
                            -90.35312499999998,
                            21.009423828124966
                        ],
                        [
                            -89.81977539062495,
                            21.274609374999983
                        ],
                        [
                            -88.87871093749999,
                            21.41411132812499
                        ],
                        [
                            -88.13164062500002,
                            21.615869140624966
                        ],
                        [
                            -86.824072265625,
                            21.421679687500017
                        ],
                        [
                            -86.86469726562501,
                            20.885058593750045
                        ],
                        [
                            -87.4671875,
                            20.102148437500063
                        ],
                        [
                            -87.42475585937498,
                            19.583349609375063
                        ],
                        [
                            -87.76181640624998,
                            18.446142578125006
                        ],
                        [
                            -88.19677734374997,
                            18.719677734374955
                        ],
                        [
                            -88.29565429687494,
                            18.47241210937503
                        ],
                        [
                            -88.52299804687499,
                            18.445898437500063
                        ],
                        [
                            -88.80634765624998,
                            17.965527343749983
                        ],
                        [
                            -89.16147460937503,
                            17.814843749999994
                        ],
                        [
                            -90.98916015624997,
                            17.81640624999997
                        ],
                        [
                            -90.975830078125,
                            16.867822265624994
                        ],
                        [
                            -90.41699218750003,
                            16.391015625000023
                        ],
                        [
                            -90.41699218750003,
                            16.351318359375
                        ],
                        [
                            -90.45014648437493,
                            16.261376953124994
                        ],
                        [
                            -90.45986328124997,
                            16.16235351562497
                        ],
                        [
                            -90.44716796874994,
                            16.07270507812501
                        ],
                        [
                            -90.52197265625,
                            16.07119140625005
                        ],
                        [
                            -90.70322265624998,
                            16.07104492187503
                        ],
                        [
                            -90.97958984374998,
                            16.07080078124997
                        ],
                        [
                            -91.433984375,
                            16.070458984374994
                        ],
                        [
                            -91.736572265625,
                            16.070166015625006
                        ],
                        [
                            -91.95722656250001,
                            15.703222656250034
                        ],
                        [
                            -92.08212890624998,
                            15.495556640625011
                        ],
                        [
                            -92.18715820312497,
                            15.320898437499963
                        ],
                        [
                            -92.07480468749998,
                            15.074218749999972
                        ],
                        [
                            -92.18637695312498,
                            14.818359374999986
                        ],
                        [
                            -92.17646484375001,
                            14.761328125000006
                        ],
                        [
                            -92.159912109375,
                            14.691015624999977
                        ],
                        [
                            -92.18706054687493,
                            14.630078124999997
                        ],
                        [
                            -92.20903320312499,
                            14.57099609375004
                        ],
                        [
                            -92.26455078125,
                            14.567773437500009
                        ],
                        [
                            -92.80893554687498,
                            15.138574218750037
                        ],
                        [
                            -93.91606445312493,
                            16.053564453125006
                        ],
                        [
                            -94.374169921875,
                            16.284765625000034
                        ],
                        [
                            -94.66152343750002,
                            16.20190429687503
                        ],
                        [
                            -94.90043945312502,
                            16.41748046875
                        ],
                        [
                            -95.46440429687499,
                            15.974707031250034
                        ],
                        [
                            -96.51083984375,
                            15.651904296875074
                        ],
                        [
                            -97.18466796874998,
                            15.909277343750006
                        ],
                        [
                            -97.75478515624994,
                            15.966845703125017
                        ],
                        [
                            -98.52031249999993,
                            16.30483398437505
                        ],
                        [
                            -98.76220703125,
                            16.534765624999977
                        ],
                        [
                            -99.69067382812499,
                            16.719628906249994
                        ],
                        [
                            -100.02451171874996,
                            16.920507812500063
                        ],
                        [
                            -100.847802734375,
                            17.20048828124999
                        ],
                        [
                            -101.60029296875,
                            17.651562499999955
                        ],
                        [
                            -101.84707031250001,
                            17.92226562500005
                        ],
                        [
                            -102.69956054687495,
                            18.062841796875006
                        ],
                        [
                            -103.44160156249995,
                            18.32539062500001
                        ],
                        [
                            -103.91245117187496,
                            18.828466796875006
                        ],
                        [
                            -104.9384765625,
                            19.309375000000017
                        ],
                        [
                            -105.482080078125,
                            19.97607421875003
                        ],
                        [
                            -105.66943359374997,
                            20.385595703124977
                        ],
                        [
                            -105.23706054687499,
                            21.119189453125045
                        ],
                        [
                            -105.20869140624998,
                            21.490820312499977
                        ],
                        [
                            -105.64912109375001,
                            21.988085937500045
                        ],
                        [
                            -105.79179687500003,
                            22.627490234375017
                        ],
                        [
                            -106.40224609374997,
                            23.195605468750017
                        ],
                        [
                            -106.93549804687497,
                            23.881249999999994
                        ],
                        [
                            -107.95117187499994,
                            24.614892578124966
                        ],
                        [
                            -108.37368164062497,
                            25.1943359375
                        ],
                        [
                            -109.38496093750001,
                            25.727148437500006
                        ],
                        [
                            -109.42563476562495,
                            26.032568359375063
                        ],
                        [
                            -109.19970703125003,
                            26.30522460937499
                        ],
                        [
                            -109.48286132812498,
                            26.710351562500023
                        ],
                        [
                            -109.75478515624995,
                            26.702929687500017
                        ],
                        [
                            -109.94399414062495,
                            27.079345703125057
                        ],
                        [
                            -110.47778320312499,
                            27.322656249999966
                        ],
                        [
                            -110.52988281249995,
                            27.864208984374983
                        ],
                        [
                            -111.12138671875002,
                            27.966992187499983
                        ],
                        [
                            -111.68007812499997,
                            28.470556640624977
                        ],
                        [
                            -112.16176757812495,
                            29.018896484375034
                        ],
                        [
                            -112.223486328125,
                            29.26948242187501
                        ],
                        [
                            -112.73837890625,
                            29.985449218750034
                        ],
                        [
                            -113.11044921874996,
                            30.793310546875063
                        ],
                        [
                            -113.04672851562495,
                            31.17924804687499
                        ],
                        [
                            -114.14931640624995,
                            31.507373046875045
                        ],
                        [
                            -114.83950195312497,
                            31.79853515625004
                        ],
                        [
                            -114.88188476562499,
                            31.156396484375023
                        ],
                        [
                            -114.63330078124997,
                            30.50688476562496
                        ],
                        [
                            -114.64975585937495,
                            30.238134765625006
                        ],
                        [
                            -114.37260742187497,
                            29.83022460937505
                        ],
                        [
                            -113.75546875,
                            29.367480468750017
                        ],
                        [
                            -113.49970703124995,
                            28.92670898437501
                        ],
                        [
                            -112.870849609375,
                            28.42421875000005
                        ],
                        [
                            -112.73403320312501,
                            27.825976562500017
                        ],
                        [
                            -112.32919921874996,
                            27.52343750000003
                        ],
                        [
                            -112.00908203125003,
                            26.967089843750017
                        ],
                        [
                            -111.56967773437495,
                            26.707617187500006
                        ],
                        [
                            -111.29160156249996,
                            25.78979492187497
                        ],
                        [
                            -111.03442382812497,
                            25.526953124999977
                        ],
                        [
                            -110.67724609374994,
                            24.78852539062501
                        ],
                        [
                            -110.54697265624996,
                            24.214160156250045
                        ],
                        [
                            -110.02280273437502,
                            24.17460937499999
                        ],
                        [
                            -109.42084960937495,
                            23.480126953124994
                        ],
                        [
                            -109.45805664062499,
                            23.214746093750023
                        ],
                        [
                            -109.92343749999993,
                            22.885888671874994
                        ],
                        [
                            -110.08603515624993,
                            23.005468750000006
                        ],
                        [
                            -110.3626953125,
                            23.60493164062501
                        ],
                        [
                            -111.41933593749997,
                            24.329003906249994
                        ],
                        [
                            -112.07255859374999,
                            24.84003906250001
                        ],
                        [
                            -112.05576171874995,
                            25.48823242187501
                        ],
                        [
                            -112.37724609374997,
                            26.21391601562496
                        ],
                        [
                            -113.02075195312499,
                            26.58325195312497
                        ],
                        [
                            -113.20576171875001,
                            26.85698242187496
                        ],
                        [
                            -113.59853515625001,
                            26.721289062500034
                        ],
                        [
                            -113.84096679687502,
                            26.966503906249983
                        ],
                        [
                            -114.44526367187503,
                            27.218164062499994
                        ],
                        [
                            -114.99350585937499,
                            27.736035156249983
                        ],
                        [
                            -114.30058593749995,
                            27.87299804687501
                        ],
                        [
                            -114.04848632812502,
                            28.426171874999994
                        ],
                        [
                            -114.14550781249997,
                            28.60541992187501
                        ],
                        [
                            -114.99350585937499,
                            29.384423828124994
                        ],
                        [
                            -115.67382812500003,
                            29.756396484375017
                        ],
                        [
                            -116.06215820312501,
                            30.80415039062504
                        ],
                        [
                            -116.66215820312495,
                            31.56489257812504
                        ],
                        [
                            -117.12827148437495,
                            32.533349609374994
                        ],
                        [
                            -114.72475585937495,
                            32.71533203125003
                        ],
                        [
                            -114.83593749999994,
                            32.50830078125003
                        ],
                        [
                            -112.93896484375001,
                            31.91625976562503
                        ],
                        [
                            -111.0419921875,
                            31.32421875000003
                        ],
                        [
                            -108.21445312499993,
                            31.329443359375034
                        ],
                        [
                            -108.21181640625002,
                            31.779345703125017
                        ],
                        [
                            -106.45322265624995,
                            31.770166015625023
                        ],
                        [
                            -106.25571289062495,
                            31.544677734375
                        ],
                        [
                            -104.97880859374996,
                            30.645947265624955
                        ],
                        [
                            -104.40063476562503,
                            29.57373046875
                        ],
                        [
                            -103.25771484374998,
                            29.001123046874966
                        ],
                        [
                            -102.8919921875,
                            29.216406250000034
                        ],
                        [
                            -102.73417968749999,
                            29.643945312500023
                        ],
                        [
                            -102.26894531249998,
                            29.871191406250034
                        ],
                        [
                            -101.44038085937503,
                            29.77685546875
                        ],
                        [
                            -100.65864257812501,
                            29.06855468750004
                        ],
                        [
                            -100.29604492187495,
                            28.32768554687499
                        ],
                        [
                            -99.50532226562497,
                            27.54833984375003
                        ],
                        [
                            -99.4435546875,
                            27.03666992187499
                        ],
                        [
                            -99.10776367187498,
                            26.446923828124994
                        ],
                        [
                            -98.08281249999999,
                            26.06445312499997
                        ],
                        [
                            -97.35815429687497,
                            25.870507812499994
                        ],
                        [
                            -97.14624023437494,
                            25.961474609375045
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Mexico',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            22.344042968750017,
                            42.31396484375
                        ],
                        [
                            22.836816406250023,
                            41.993603515625
                        ],
                        [
                            23.005664062500017,
                            41.716943359374994
                        ],
                        [
                            22.951464843750017,
                            41.605615234374994
                        ],
                        [
                            22.9296875,
                            41.356103515624994
                        ],
                        [
                            22.916015625,
                            41.336279296875
                        ],
                        [
                            22.75507812500001,
                            41.312744140625
                        ],
                        [
                            22.72480468750001,
                            41.178515624999996
                        ],
                        [
                            22.603613281250006,
                            41.140185546874996
                        ],
                        [
                            22.493554687500023,
                            41.118505859375
                        ],
                        [
                            22.237695312500023,
                            41.155175781249994
                        ],
                        [
                            21.99335937500001,
                            41.13095703125
                        ],
                        [
                            21.929492187500017,
                            41.107421875
                        ],
                        [
                            21.575781250000006,
                            40.868945312499996
                        ],
                        [
                            20.964257812500023,
                            40.849902343749996
                        ],
                        [
                            20.48896484375001,
                            41.272607421874994
                        ],
                        [
                            20.566210937500017,
                            41.873681640624994
                        ],
                        [
                            21.05976562500001,
                            42.171289062499994
                        ],
                        [
                            21.25634765625,
                            42.099511718749994
                        ],
                        [
                            21.389550781250023,
                            42.21982421875
                        ],
                        [
                            21.560839843750017,
                            42.24765625
                        ],
                        [
                            21.5625,
                            42.247509765625
                        ],
                        [
                            21.904101562500017,
                            42.322070312499996
                        ],
                        [
                            22.052050781250017,
                            42.304638671875
                        ],
                        [
                            22.14667968750001,
                            42.324999999999996
                        ],
                        [
                            22.23974609375,
                            42.358154296875
                        ],
                        [
                            22.344042968750017,
                            42.31396484375
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Macedonia',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            4.227636718750006,
                            19.142773437499997
                        ],
                        [
                            4.234667968750017,
                            16.996386718750003
                        ],
                        [
                            4.121289062500011,
                            16.357714843750003
                        ],
                        [
                            3.876953125,
                            15.755273437499994
                        ],
                        [
                            3.5042968750000227,
                            15.356347656249994
                        ],
                        [
                            1.3001953125000227,
                            15.272265625000003
                        ],
                        [
                            0.9600585937500057,
                            14.986914062499991
                        ],
                        [
                            0.21748046875001137,
                            14.911474609374991
                        ],
                        [
                            -0.23588867187498863,
                            15.059423828124991
                        ],
                        [
                            -0.760449218749983,
                            15.047753906249994
                        ],
                        [
                            -1.049560546875,
                            14.819531249999997
                        ],
                        [
                            -1.767773437499983,
                            14.486035156249997
                        ],
                        [
                            -2.113232421874983,
                            14.16845703125
                        ],
                        [
                            -2.457226562499983,
                            14.274121093749997
                        ],
                        [
                            -3.198437499999983,
                            13.6728515625
                        ],
                        [
                            -3.3017578125,
                            13.28076171875
                        ],
                        [
                            -3.5757812499999773,
                            13.194189453124991
                        ],
                        [
                            -4.051171874999994,
                            13.382421874999991
                        ],
                        [
                            -4.328710937499977,
                            13.119042968749994
                        ],
                        [
                            -4.227099609374989,
                            12.793701171875
                        ],
                        [
                            -4.480615234374994,
                            12.672216796874991
                        ],
                        [
                            -4.4287109375,
                            12.337597656249997
                        ],
                        [
                            -4.797949218749977,
                            12.032128906249994
                        ],
                        [
                            -5.288134765624989,
                            11.827929687499989
                        ],
                        [
                            -5.299853515624989,
                            11.205957031249994
                        ],
                        [
                            -5.490478515625,
                            11.042382812499994
                        ],
                        [
                            -5.457080078124989,
                            10.771386718749994
                        ],
                        [
                            -5.523535156249977,
                            10.426025390625
                        ],
                        [
                            -5.556591796874983,
                            10.43994140625
                        ],
                        [
                            -5.694287109374983,
                            10.433203124999991
                        ],
                        [
                            -5.843847656249977,
                            10.389550781249994
                        ],
                        [
                            -5.907568359374977,
                            10.307226562499991
                        ],
                        [
                            -6.034570312499994,
                            10.19482421875
                        ],
                        [
                            -6.482617187499983,
                            10.561230468749997
                        ],
                        [
                            -6.950341796874994,
                            10.342333984374989
                        ],
                        [
                            -7.385058593749989,
                            10.340136718749989
                        ],
                        [
                            -7.6611328125,
                            10.427441406249997
                        ],
                        [
                            -7.749072265624989,
                            10.34228515625
                        ],
                        [
                            -7.814208984375,
                            10.236572265625
                        ],
                        [
                            -7.884082031249989,
                            10.185742187499997
                        ],
                        [
                            -7.9609375,
                            10.163476562499994
                        ],
                        [
                            -7.990624999999994,
                            10.162499999999994
                        ],
                        [
                            -7.974462890624977,
                            10.229541015624989
                        ],
                        [
                            -8.007275390624983,
                            10.321874999999991
                        ],
                        [
                            -8.231494140624989,
                            10.43798828125
                        ],
                        [
                            -8.407470703125,
                            11.386279296874989
                        ],
                        [
                            -8.822021484375,
                            11.673242187499994
                        ],
                        [
                            -9.043066406249977,
                            12.40234375
                        ],
                        [
                            -9.820703124999994,
                            12.04248046875
                        ],
                        [
                            -10.274853515624983,
                            12.212646484375
                        ],
                        [
                            -10.677343749999977,
                            11.8994140625
                        ],
                        [
                            -10.933203124999977,
                            12.205175781249991
                        ],
                        [
                            -11.30517578125,
                            12.015429687499989
                        ],
                        [
                            -11.389404296875,
                            12.404394531249991
                        ],
                        [
                            -11.390380859375,
                            12.941992187499991
                        ],
                        [
                            -11.634960937499983,
                            13.369873046875
                        ],
                        [
                            -12.05419921875,
                            13.633056640625
                        ],
                        [
                            -12.019189453124994,
                            14.206494140624997
                        ],
                        [
                            -12.280615234374977,
                            14.809033203124997
                        ],
                        [
                            -12.104687499999983,
                            14.745361328125
                        ],
                        [
                            -11.872851562499989,
                            14.995166015625003
                        ],
                        [
                            -11.842236328124983,
                            15.12939453125
                        ],
                        [
                            -11.828759765624994,
                            15.244873046875
                        ],
                        [
                            -11.760156249999994,
                            15.425537109375
                        ],
                        [
                            -11.675878906249977,
                            15.512060546874991
                        ],
                        [
                            -11.502685546875,
                            15.636816406249991
                        ],
                        [
                            -11.455224609374994,
                            15.625390624999994
                        ],
                        [
                            -10.895605468749977,
                            15.150488281249991
                        ],
                        [
                            -10.815087890624994,
                            15.28173828125
                        ],
                        [
                            -10.696582031249989,
                            15.422656250000003
                        ],
                        [
                            -9.94140625,
                            15.373779296875
                        ],
                        [
                            -9.293701171875,
                            15.502832031249994
                        ],
                        [
                            -8.171240234374977,
                            15.496142578125003
                        ],
                        [
                            -6.335742187499989,
                            15.496191406249991
                        ],
                        [
                            -6.131787109374983,
                            15.496240234374994
                        ],
                        [
                            -5.723876953125,
                            15.496289062499997
                        ],
                        [
                            -5.512499999999989,
                            15.496289062499997
                        ],
                        [
                            -5.359912109374989,
                            16.282861328124994
                        ],
                        [
                            -5.509619140624977,
                            16.442041015624994
                        ],
                        [
                            -5.628662109375,
                            16.568652343750003
                        ],
                        [
                            -5.65625,
                            16.8095703125
                        ],
                        [
                            -5.684765624999983,
                            17.058251953124994
                        ],
                        [
                            -5.713183593749989,
                            17.306884765625
                        ],
                        [
                            -5.74169921875,
                            17.555566406249994
                        ],
                        [
                            -5.827099609374983,
                            18.301562500000003
                        ],
                        [
                            -5.855566406249977,
                            18.550244140624997
                        ],
                        [
                            -5.884082031249989,
                            18.798876953125003
                        ],
                        [
                            -5.941015624999977,
                            19.296191406250003
                        ],
                        [
                            -5.969482421875,
                            19.544873046874997
                        ],
                        [
                            -5.997949218749994,
                            19.793505859375003
                        ],
                        [
                            -6.083398437499994,
                            20.539501953124997
                        ],
                        [
                            -6.396582031249977,
                            23.274804687499994
                        ],
                        [
                            -6.482031249999977,
                            24.020800781250003
                        ],
                        [
                            -6.538964843749994,
                            24.51816406249999
                        ],
                        [
                            -6.5673828125,
                            24.766796874999997
                        ],
                        [
                            -6.594091796874977,
                            24.99462890625
                        ],
                        [
                            -6.287207031249977,
                            24.994824218749997
                        ],
                        [
                            -5.959814453124977,
                            24.99497070312499
                        ],
                        [
                            -5.172900390624989,
                            24.995410156250003
                        ],
                        [
                            -4.822607421874977,
                            24.99560546875
                        ],
                        [
                            -3.2578613281249886,
                            23.981250000000003
                        ],
                        [
                            -1.29296875,
                            22.696533203125
                        ],
                        [
                            1.1455078125,
                            21.102246093749997
                        ],
                        [
                            1.1657226562500114,
                            20.817431640625003
                        ],
                        [
                            1.6854492187500227,
                            20.378369140624997
                        ],
                        [
                            2.2193359375000057,
                            20.247802734375
                        ],
                        [
                            2.4061523437500227,
                            20.063867187499994
                        ],
                        [
                            3.2037109375000057,
                            19.789697265624994
                        ],
                        [
                            3.255859375,
                            19.410937500000003
                        ],
                        [
                            3.1060546875000057,
                            19.150097656249997
                        ],
                        [
                            3.40087890625,
                            18.988427734374994
                        ],
                        [
                            4.227636718750006,
                            19.142773437499997
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Mali',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            101.1388671875001,
                            21.567480468749977
                        ],
                        [
                            100.92753906250002,
                            21.3662109375
                        ],
                        [
                            100.81953125000004,
                            21.31420898437497
                        ],
                        [
                            100.75664062500002,
                            21.312646484375023
                        ],
                        [
                            100.70312500000003,
                            21.251367187499966
                        ],
                        [
                            100.65917968749997,
                            21.13037109375
                        ],
                        [
                            100.613671875,
                            21.059326171875
                        ],
                        [
                            100.56660156250004,
                            21.038183593750063
                        ],
                        [
                            100.53613281250003,
                            20.992382812499955
                        ],
                        [
                            100.24931640625002,
                            20.730273437500045
                        ],
                        [
                            100.12246093750005,
                            20.316650390625057
                        ],
                        [
                            99.8903320312501,
                            20.424414062499977
                        ],
                        [
                            99.33789062499997,
                            20.078906250000017
                        ],
                        [
                            99.02070312500004,
                            20.041796875000017
                        ],
                        [
                            98.9166992187501,
                            19.77290039062504
                        ],
                        [
                            98.23906250000002,
                            19.69067382812497
                        ],
                        [
                            97.816796875,
                            19.459960937500057
                        ],
                        [
                            97.75400390625006,
                            18.620800781249983
                        ],
                        [
                            97.48496093750006,
                            18.494238281250034
                        ],
                        [
                            97.79296875000003,
                            17.681250000000063
                        ],
                        [
                            98.4388671875,
                            16.975683593750034
                        ],
                        [
                            98.68925781250002,
                            16.30541992187497
                        ],
                        [
                            98.56523437500007,
                            15.403564453125014
                        ],
                        [
                            98.19101562500012,
                            15.204101562499972
                        ],
                        [
                            98.24599609375005,
                            14.814746093750045
                        ],
                        [
                            99.13681640625006,
                            13.716699218749994
                        ],
                        [
                            99.21982421875012,
                            12.73974609375
                        ],
                        [
                            99.40507812500002,
                            12.547900390625003
                        ],
                        [
                            99.61474609374997,
                            11.781201171875026
                        ],
                        [
                            99.1901367187501,
                            11.105273437499989
                        ],
                        [
                            98.7572265625,
                            10.660937499999974
                        ],
                        [
                            98.70253906250005,
                            10.19038085937504
                        ],
                        [
                            98.46494140625006,
                            10.675830078124989
                        ],
                        [
                            98.67558593750007,
                            10.986914062500034
                        ],
                        [
                            98.74140625000004,
                            11.591699218749966
                        ],
                        [
                            98.57597656250002,
                            13.161914062500031
                        ],
                        [
                            98.24541015625002,
                            13.733496093750034
                        ],
                        [
                            97.81230468750007,
                            14.858935546874989
                        ],
                        [
                            97.7103515625,
                            15.875537109375074
                        ],
                        [
                            97.58427734375007,
                            16.019580078125017
                        ],
                        [
                            97.61962890624997,
                            16.537207031250034
                        ],
                        [
                            97.37587890625005,
                            16.52294921874997
                        ],
                        [
                            97.17832031250012,
                            17.06201171875003
                        ],
                        [
                            96.62246093750005,
                            16.563916015624983
                        ],
                        [
                            95.76328125000006,
                            16.169042968750006
                        ],
                        [
                            95.38955078125005,
                            15.722753906250034
                        ],
                        [
                            94.9425781250001,
                            15.818261718750023
                        ],
                        [
                            94.58750000000006,
                            16.28881835937497
                        ],
                        [
                            94.21425781250005,
                            16.126611328124966
                        ],
                        [
                            94.58896484375006,
                            17.5693359375
                        ],
                        [
                            94.43076171875012,
                            18.20166015625003
                        ],
                        [
                            94.07001953125004,
                            18.893408203125006
                        ],
                        [
                            93.70546875000005,
                            19.026904296875017
                        ],
                        [
                            93.99814453125006,
                            19.440869140624983
                        ],
                        [
                            93.36230468750003,
                            20.05830078125001
                        ],
                        [
                            93.00195312499997,
                            20.074853515624994
                        ],
                        [
                            92.73564453125007,
                            20.56269531250001
                        ],
                        [
                            92.32412109375,
                            20.791845703125063
                        ],
                        [
                            92.17958984375005,
                            21.293115234375023
                        ],
                        [
                            92.5934570312501,
                            21.46733398437499
                        ],
                        [
                            92.57490234375004,
                            21.978076171875045
                        ],
                        [
                            92.77138671875,
                            22.104785156250017
                        ],
                        [
                            93.07060546875002,
                            22.20942382812501
                        ],
                        [
                            93.16201171875,
                            22.360205078125006
                        ],
                        [
                            93.07871093750006,
                            22.71821289062501
                        ],
                        [
                            93.20390625000002,
                            23.03701171875005
                        ],
                        [
                            93.34941406250007,
                            23.08496093750003
                        ],
                        [
                            93.36601562500007,
                            23.132519531249955
                        ],
                        [
                            93.41494140625,
                            23.68208007812501
                        ],
                        [
                            93.32626953125006,
                            24.064208984375057
                        ],
                        [
                            93.45214843750003,
                            23.987402343750034
                        ],
                        [
                            93.68339843750007,
                            24.00654296875004
                        ],
                        [
                            94.07480468750006,
                            23.8720703125
                        ],
                        [
                            94.37724609375002,
                            24.473730468750006
                        ],
                        [
                            94.49316406250003,
                            24.637646484374983
                        ],
                        [
                            94.70371093750012,
                            25.097851562499955
                        ],
                        [
                            94.66777343750007,
                            25.458886718749966
                        ],
                        [
                            94.99199218750002,
                            25.77045898437504
                        ],
                        [
                            95.01523437500006,
                            25.912939453125006
                        ],
                        [
                            95.0929687500001,
                            25.98730468749997
                        ],
                        [
                            95.13242187500006,
                            26.041259765625057
                        ],
                        [
                            95.12929687500005,
                            26.070410156250034
                        ],
                        [
                            95.10839843749997,
                            26.091406250000034
                        ],
                        [
                            95.06894531250006,
                            26.19111328125001
                        ],
                        [
                            95.0597656250001,
                            26.473974609375006
                        ],
                        [
                            95.20146484375007,
                            26.641406250000017
                        ],
                        [
                            96.19082031250005,
                            27.26127929687499
                        ],
                        [
                            96.79785156249997,
                            27.29619140624999
                        ],
                        [
                            96.96279296875,
                            27.698291015625017
                        ],
                        [
                            97.04970703125005,
                            27.760009765625
                        ],
                        [
                            97.34355468750002,
                            27.982324218749994
                        ],
                        [
                            97.30273437499997,
                            28.08598632812496
                        ],
                        [
                            97.3224609375001,
                            28.21796875000004
                        ],
                        [
                            97.35644531249997,
                            28.254492187500006
                        ],
                        [
                            97.43144531250002,
                            28.353906250000023
                        ],
                        [
                            97.53789062500002,
                            28.510205078124983
                        ],
                        [
                            98.09892578125002,
                            28.142285156249983
                        ],
                        [
                            98.29882812499997,
                            27.550097656250045
                        ],
                        [
                            98.65117187500007,
                            27.572460937499983
                        ],
                        [
                            98.7318359375,
                            26.583398437499994
                        ],
                        [
                            98.6546875,
                            25.917773437500017
                        ],
                        [
                            98.01074218749997,
                            25.292529296875017
                        ],
                        [
                            97.8195312500001,
                            25.251855468749994
                        ],
                        [
                            97.53144531250004,
                            24.49169921875003
                        ],
                        [
                            97.68603515624997,
                            23.898095703124966
                        ],
                        [
                            98.21250000000006,
                            24.110644531250017
                        ],
                        [
                            98.76435546875004,
                            24.116064453125063
                        ],
                        [
                            98.86376953125003,
                            23.191259765625034
                        ],
                        [
                            99.34082031249997,
                            23.095898437499955
                        ],
                        [
                            99.33769531250002,
                            22.49804687500003
                        ],
                        [
                            99.19296875000006,
                            22.12597656249997
                        ],
                        [
                            99.9176757812501,
                            22.02802734375001
                        ],
                        [
                            100.21474609375,
                            21.462988281250006
                        ],
                        [
                            100.83515625000004,
                            21.655175781249994
                        ],
                        [
                            101.1388671875001,
                            21.567480468749977
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Myanmar',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            19.670996093750006,
                            43.163964843749994
                        ],
                        [
                            20.344335937500006,
                            42.827929687499996
                        ],
                        [
                            20.06396484375,
                            42.547265624999994
                        ],
                        [
                            19.65449218750001,
                            42.628564453124994
                        ],
                        [
                            19.280664062500023,
                            42.17255859375
                        ],
                        [
                            19.342382812500006,
                            41.869091796875
                        ],
                        [
                            18.436328125000017,
                            42.559716796874994
                        ],
                        [
                            18.46601562500001,
                            42.777246093749994
                        ],
                        [
                            18.674218750000023,
                            43.230810546875
                        ],
                        [
                            18.74921875000001,
                            43.283544921875
                        ],
                        [
                            18.85107421875,
                            43.346337890624994
                        ],
                        [
                            19.036718750000006,
                            43.357324218749994
                        ],
                        [
                            18.940234375000017,
                            43.496728515624994
                        ],
                        [
                            19.21875,
                            43.449951171875
                        ],
                        [
                            19.670996093750006,
                            43.163964843749994
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Montenegro',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            116.68330078125001,
                            49.823779296874996
                        ],
                        [
                            115.95380859375001,
                            48.689355468749994
                        ],
                        [
                            115.82050781250001,
                            48.57724609375
                        ],
                        [
                            115.79169921875001,
                            48.455712890624994
                        ],
                        [
                            115.79658203125001,
                            48.346337890624994
                        ],
                        [
                            115.78554687500002,
                            48.2482421875
                        ],
                        [
                            115.63945312499999,
                            48.18623046875
                        ],
                        [
                            115.52509765625001,
                            48.130859375
                        ],
                        [
                            115.5576171875,
                            47.94501953125
                        ],
                        [
                            115.61640625000001,
                            47.874804687499996
                        ],
                        [
                            115.71171874999999,
                            47.79892578125
                        ],
                        [
                            115.89824218749999,
                            47.686914062499994
                        ],
                        [
                            115.99384765625001,
                            47.711328124999994
                        ],
                        [
                            116.07480468750003,
                            47.78955078125
                        ],
                        [
                            116.23115234375001,
                            47.858203124999996
                        ],
                        [
                            116.31718749999999,
                            47.85986328125
                        ],
                        [
                            116.37822265624999,
                            47.844042968749996
                        ],
                        [
                            116.51347656249999,
                            47.83955078125
                        ],
                        [
                            116.65195312500003,
                            47.864501953125
                        ],
                        [
                            116.76054687499999,
                            47.869775390624994
                        ],
                        [
                            116.90117187499999,
                            47.853076171874996
                        ],
                        [
                            116.95166015625,
                            47.836572265624994
                        ],
                        [
                            117.06972656250002,
                            47.806396484375
                        ],
                        [
                            117.19707031249999,
                            47.740283203124996
                        ],
                        [
                            117.28593749999999,
                            47.666357421875
                        ],
                        [
                            117.35078125000001,
                            47.652197265625
                        ],
                        [
                            117.38398437500001,
                            47.675732421875
                        ],
                        [
                            117.455078125,
                            47.741357421874994
                        ],
                        [
                            117.55537109375001,
                            47.8046875
                        ],
                        [
                            117.67666015625002,
                            47.90830078125
                        ],
                        [
                            117.76835937499999,
                            47.987890625
                        ],
                        [
                            118.49843750000002,
                            47.983984375
                        ],
                        [
                            119.71113281250001,
                            47.15
                        ],
                        [
                            119.89785156250002,
                            46.857812499999994
                        ],
                        [
                            119.8671875,
                            46.672167968749996
                        ],
                        [
                            119.74746093750002,
                            46.627197265625
                        ],
                        [
                            119.70664062500003,
                            46.606005859374996
                        ],
                        [
                            119.62021484375003,
                            46.603955078125
                        ],
                        [
                            119.47402343750002,
                            46.62666015625
                        ],
                        [
                            119.162109375,
                            46.638671875
                        ],
                        [
                            118.95712890625003,
                            46.73486328125
                        ],
                        [
                            118.84394531250001,
                            46.760205078125
                        ],
                        [
                            118.30869140625003,
                            46.717041015625
                        ],
                        [
                            117.91044921874999,
                            46.6193359375
                        ],
                        [
                            117.8134765625,
                            46.5376953125
                        ],
                        [
                            117.67109375000001,
                            46.5220703125
                        ],
                        [
                            117.33339843750002,
                            46.36201171875
                        ],
                        [
                            116.97880859374999,
                            46.361767578125
                        ],
                        [
                            116.85908203125001,
                            46.387939453125
                        ],
                        [
                            116.78701171875002,
                            46.37666015625
                        ],
                        [
                            116.68886718750002,
                            46.32197265625
                        ],
                        [
                            116.56259765625003,
                            46.289794921875
                        ],
                        [
                            116.21298828125003,
                            45.8869140625
                        ],
                        [
                            116.22910156250003,
                            45.845751953124996
                        ],
                        [
                            116.24062500000002,
                            45.79599609375
                        ],
                        [
                            115.68105468750002,
                            45.458251953125
                        ],
                        [
                            115.16259765625,
                            45.390234375
                        ],
                        [
                            114.91923828124999,
                            45.378271484375
                        ],
                        [
                            114.73876953125,
                            45.41962890625
                        ],
                        [
                            114.64433593749999,
                            45.41328125
                        ],
                        [
                            114.56015625000003,
                            45.389990234375
                        ],
                        [
                            114.51718750000003,
                            45.364599609375
                        ],
                        [
                            114.41914062500001,
                            45.202587890625
                        ],
                        [
                            114.0302734375,
                            44.942578125
                        ],
                        [
                            113.87705078125003,
                            44.89619140625
                        ],
                        [
                            113.65263671874999,
                            44.763476562499996
                        ],
                        [
                            113.58701171875003,
                            44.745703125
                        ],
                        [
                            113.04941406250003,
                            44.810351562499996
                        ],
                        [
                            112.70673828125001,
                            44.883447265624994
                        ],
                        [
                            112.59677734375003,
                            44.91767578125
                        ],
                        [
                            112.49931640624999,
                            45.0109375
                        ],
                        [
                            112.41132812500001,
                            45.058203125
                        ],
                        [
                            112.11289062500003,
                            45.062939453125
                        ],
                        [
                            112.03261718750002,
                            45.081640625
                        ],
                        [
                            111.89804687500003,
                            45.0640625
                        ],
                        [
                            111.40224609375002,
                            44.36728515625
                        ],
                        [
                            111.8369140625,
                            43.93466796875
                        ],
                        [
                            111.93173828125003,
                            43.81494140625
                        ],
                        [
                            111.94287109375,
                            43.75244140625
                        ],
                        [
                            111.93320312500003,
                            43.71142578125
                        ],
                        [
                            111.87812500000001,
                            43.68017578125
                        ],
                        [
                            111.77109375000003,
                            43.664599609374996
                        ],
                        [
                            111.7197265625,
                            43.621142578124996
                        ],
                        [
                            111.64082031250001,
                            43.56318359375
                        ],
                        [
                            111.54736328125,
                            43.4962890625
                        ],
                        [
                            111.50351562500003,
                            43.4927734375
                        ],
                        [
                            111.00722656250002,
                            43.34140625
                        ],
                        [
                            110.400390625,
                            42.773681640625
                        ],
                        [
                            109.44316406249999,
                            42.455957031249994
                        ],
                        [
                            109.33984375,
                            42.438378906249994
                        ],
                        [
                            108.68730468749999,
                            42.41611328125
                        ],
                        [
                            108.17119140624999,
                            42.447314453124996
                        ],
                        [
                            106.77001953125,
                            42.288720703124994
                        ],
                        [
                            105.86757812500002,
                            41.993994140625
                        ],
                        [
                            105.31435546875002,
                            41.770898437499994
                        ],
                        [
                            105.19707031249999,
                            41.738037109375
                        ],
                        [
                            104.49824218750001,
                            41.65869140625
                        ],
                        [
                            104.49824218750001,
                            41.877001953124996
                        ],
                        [
                            104.30517578125,
                            41.846142578125
                        ],
                        [
                            103.99726562500001,
                            41.79697265625
                        ],
                        [
                            103.71113281250001,
                            41.751318359375
                        ],
                        [
                            103.07285156250003,
                            42.00595703125
                        ],
                        [
                            101.97294921874999,
                            42.215869140624996
                        ],
                        [
                            101.7138671875,
                            42.4658203125
                        ],
                        [
                            99.98378906250002,
                            42.67734375
                        ],
                        [
                            99.46787109375003,
                            42.568212890625
                        ],
                        [
                            97.20566406250003,
                            42.789794921875
                        ],
                        [
                            96.38544921875001,
                            42.720361328124994
                        ],
                        [
                            95.91250000000002,
                            43.206494140625
                        ],
                        [
                            95.85957031250001,
                            43.2759765625
                        ],
                        [
                            95.84199218750001,
                            43.383691406249994
                        ],
                        [
                            95.52558593750001,
                            43.953955078125
                        ],
                        [
                            95.3564453125,
                            44.00595703125
                        ],
                        [
                            95.35029296875001,
                            44.278076171875
                        ],
                        [
                            94.71201171875003,
                            44.350830078125
                        ],
                        [
                            93.65644531250001,
                            44.9009765625
                        ],
                        [
                            92.78789062499999,
                            45.0357421875
                        ],
                        [
                            92.57890624999999,
                            45.010986328125
                        ],
                        [
                            92.423828125,
                            45.008935546874994
                        ],
                        [
                            92.17265624999999,
                            45.03525390625
                        ],
                        [
                            92.02978515625,
                            45.068505859374994
                        ],
                        [
                            91.58437500000002,
                            45.076513671875
                        ],
                        [
                            91.51005859374999,
                            45.0982421875
                        ],
                        [
                            91.44101562500003,
                            45.124755859375
                        ],
                        [
                            91.31210937500003,
                            45.118115234375
                        ],
                        [
                            91.05000000000001,
                            45.217431640624994
                        ],
                        [
                            90.87724609374999,
                            45.196093749999996
                        ],
                        [
                            90.66181640625001,
                            45.525244140625
                        ],
                        [
                            91.00175781249999,
                            46.035791015624994
                        ],
                        [
                            91.03388671875001,
                            46.52900390625
                        ],
                        [
                            90.86992187499999,
                            46.954492187499994
                        ],
                        [
                            90.49619140625003,
                            47.28515625
                        ],
                        [
                            90.31328124999999,
                            47.676171874999994
                        ],
                        [
                            90.10322265625001,
                            47.745410156249996
                        ],
                        [
                            90.02792968750003,
                            47.877685546875
                        ],
                        [
                            89.95869140625001,
                            47.886328125
                        ],
                        [
                            89.77812499999999,
                            47.827001953125
                        ],
                        [
                            89.7255859375,
                            47.852490234375
                        ],
                        [
                            89.69316406249999,
                            47.879150390625
                        ],
                        [
                            89.63847656249999,
                            47.909082031249994
                        ],
                        [
                            89.56093750000002,
                            48.003955078124996
                        ],
                        [
                            89.32988281249999,
                            48.024853515625
                        ],
                        [
                            89.1962890625,
                            47.980908203125
                        ],
                        [
                            89.11562500000002,
                            47.987695312499994
                        ],
                        [
                            89.04765624999999,
                            48.0025390625
                        ],
                        [
                            88.97109375000002,
                            48.049951171874994
                        ],
                        [
                            88.91777343749999,
                            48.089013671874994
                        ],
                        [
                            88.83828125000002,
                            48.101708984374994
                        ],
                        [
                            88.68183593750001,
                            48.170556640624994
                        ],
                        [
                            88.57597656249999,
                            48.220166015625
                        ],
                        [
                            88.56679687500002,
                            48.317431640624996
                        ],
                        [
                            88.51708984375,
                            48.38447265625
                        ],
                        [
                            88.41396484375002,
                            48.40341796875
                        ],
                        [
                            88.30996093750002,
                            48.472070312499994
                        ],
                        [
                            87.97968750000001,
                            48.555126953125
                        ],
                        [
                            87.7431640625,
                            48.881640624999996
                        ],
                        [
                            87.85986328125,
                            48.96552734375
                        ],
                        [
                            87.87216796875003,
                            49.000146484374994
                        ],
                        [
                            87.83466796875001,
                            49.03193359375
                        ],
                        [
                            87.81630859375002,
                            49.0802734375
                        ],
                        [
                            87.8251953125,
                            49.11630859375
                        ],
                        [
                            87.81425781249999,
                            49.1623046875
                        ],
                        [
                            87.93476562500001,
                            49.16455078125
                        ],
                        [
                            88.02851562500001,
                            49.219775390624996
                        ],
                        [
                            88.11572265625,
                            49.256298828125
                        ],
                        [
                            88.19257812500001,
                            49.451708984374996
                        ],
                        [
                            88.33779296875002,
                            49.472558593749994
                        ],
                        [
                            88.39335937499999,
                            49.482861328125
                        ],
                        [
                            88.45244140624999,
                            49.472705078124996
                        ],
                        [
                            88.54433593750002,
                            49.482568359374994
                        ],
                        [
                            88.63320312500002,
                            49.486132812499996
                        ],
                        [
                            88.83164062500003,
                            49.4484375
                        ],
                        [
                            89.65410156249999,
                            49.71748046875
                        ],
                        [
                            89.64384765624999,
                            49.90302734375
                        ],
                        [
                            91.80429687500003,
                            50.693603515625
                        ],
                        [
                            94.25107421875003,
                            50.556396484375
                        ],
                        [
                            94.61474609375,
                            50.023730468749996
                        ],
                        [
                            94.67548828125001,
                            50.028076171875
                        ],
                        [
                            94.71806640624999,
                            50.043261718749996
                        ],
                        [
                            94.93027343750003,
                            50.043749999999996
                        ],
                        [
                            95.04433593750002,
                            49.961572265624994
                        ],
                        [
                            95.52265625000001,
                            49.91123046875
                        ],
                        [
                            95.56718749999999,
                            49.94384765625
                        ],
                        [
                            95.70781249999999,
                            49.966015625
                        ],
                        [
                            95.78935546874999,
                            50.012499999999996
                        ],
                        [
                            95.85195312500002,
                            50.012939453125
                        ],
                        [
                            95.8994140625,
                            49.990576171875
                        ],
                        [
                            95.93574218750001,
                            49.960009765624996
                        ],
                        [
                            96.06552734375003,
                            49.99873046875
                        ],
                        [
                            96.31503906250003,
                            49.901123046875
                        ],
                        [
                            96.98574218750002,
                            49.8828125
                        ],
                        [
                            97.20859375000003,
                            49.730810546875
                        ],
                        [
                            97.35976562500002,
                            49.741455078125
                        ],
                        [
                            97.58935546875,
                            49.911474609375
                        ],
                        [
                            98.10341796875002,
                            50.07783203125
                        ],
                        [
                            98.27949218750001,
                            50.533251953124996
                        ],
                        [
                            97.82529296875003,
                            50.985253906249994
                        ],
                        [
                            97.94687500000003,
                            51.348437499999996
                        ],
                        [
                            98.35273437500001,
                            51.717626953125
                        ],
                        [
                            98.64052734375002,
                            51.801171875
                        ],
                        [
                            98.8486328125,
                            52.070068359375
                        ],
                        [
                            99.92167968749999,
                            51.755517578125
                        ],
                        [
                            100.03457031250002,
                            51.737109374999996
                        ],
                        [
                            100.23037109375002,
                            51.729833984375
                        ],
                        [
                            100.46894531250001,
                            51.72607421875
                        ],
                        [
                            100.53623046875003,
                            51.7134765625
                        ],
                        [
                            101.22324218750003,
                            51.51328125
                        ],
                        [
                            101.30449218749999,
                            51.474755859374994
                        ],
                        [
                            101.38125000000002,
                            51.45263671875
                        ],
                        [
                            101.57089843750003,
                            51.467187499999994
                        ],
                        [
                            101.82119140625002,
                            51.421044921874994
                        ],
                        [
                            102.11152343750001,
                            51.353466796875
                        ],
                        [
                            102.15566406250002,
                            51.313769531249996
                        ],
                        [
                            102.16005859375002,
                            51.26083984375
                        ],
                        [
                            102.14238281249999,
                            51.216064453125
                        ],
                        [
                            102.15195312500003,
                            51.10751953125
                        ],
                        [
                            102.21503906250001,
                            50.829443359375
                        ],
                        [
                            102.28837890624999,
                            50.585107421874994
                        ],
                        [
                            102.68330078125001,
                            50.387158203125
                        ],
                        [
                            102.85966796874999,
                            50.333251953125
                        ],
                        [
                            103.03945312500002,
                            50.300634765625
                        ],
                        [
                            103.16171875000003,
                            50.29072265625
                        ],
                        [
                            103.23378906250002,
                            50.2642578125
                        ],
                        [
                            103.30439453125001,
                            50.20029296875
                        ],
                        [
                            103.63291015625003,
                            50.138574218749994
                        ],
                        [
                            103.72324218750003,
                            50.153857421874996
                        ],
                        [
                            103.80263671875002,
                            50.176074218749996
                        ],
                        [
                            104.1796875,
                            50.16943359375
                        ],
                        [
                            104.25996093750001,
                            50.214453125
                        ],
                        [
                            104.35390625000002,
                            50.275292968749994
                        ],
                        [
                            104.46630859375,
                            50.30615234375
                        ],
                        [
                            104.97695312500002,
                            50.38291015625
                        ],
                        [
                            105.0947265625,
                            50.389941406249996
                        ],
                        [
                            105.38359374999999,
                            50.47373046875
                        ],
                        [
                            106.21787109375003,
                            50.304589843749994
                        ],
                        [
                            106.36845703124999,
                            50.317578125
                        ],
                        [
                            106.57441406250001,
                            50.32880859375
                        ],
                        [
                            106.71113281250001,
                            50.31259765625
                        ],
                        [
                            106.94130859375002,
                            50.196679687499994
                        ],
                        [
                            107.04023437500001,
                            50.086474609374996
                        ],
                        [
                            107.14306640625,
                            50.033007812499996
                        ],
                        [
                            107.23330078125002,
                            49.989404296874994
                        ],
                        [
                            107.34707031250002,
                            49.986669921875
                        ],
                        [
                            107.63095703125003,
                            49.98310546875
                        ],
                        [
                            107.94785156250003,
                            49.924707031249994
                        ],
                        [
                            108.03378906250003,
                            49.593994140625
                        ],
                        [
                            108.40693359375001,
                            49.396386718749994
                        ],
                        [
                            108.5224609375,
                            49.34150390625
                        ],
                        [
                            108.61367187500002,
                            49.322802734374996
                        ],
                        [
                            108.73300781250003,
                            49.33564453125
                        ],
                        [
                            109.23671875000002,
                            49.334912109375
                        ],
                        [
                            109.45371093750003,
                            49.296337890625
                        ],
                        [
                            109.52871093750002,
                            49.269873046875
                        ],
                        [
                            110.19990234375001,
                            49.17041015625
                        ],
                        [
                            110.82792968749999,
                            49.166162109374994
                        ],
                        [
                            111.20419921875003,
                            49.304296875
                        ],
                        [
                            111.33662109375001,
                            49.355859374999994
                        ],
                        [
                            113.05556640625002,
                            49.616259765624996
                        ],
                        [
                            113.09208984374999,
                            49.692529296874994
                        ],
                        [
                            113.16416015625003,
                            49.797167968749996
                        ],
                        [
                            113.31904296875001,
                            49.874316406249996
                        ],
                        [
                            113.44550781250001,
                            49.9416015625
                        ],
                        [
                            113.57421875,
                            50.00703125
                        ],
                        [
                            114.29707031250001,
                            50.2744140625
                        ],
                        [
                            114.7431640625,
                            50.233691406249996
                        ],
                        [
                            115.27451171875003,
                            49.948876953124994
                        ],
                        [
                            116.13457031249999,
                            50.010791015624996
                        ],
                        [
                            116.68330078125001,
                            49.823779296874996
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Mongolia',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            32.112890625000006,
                            -26.839453125000006
                        ],
                        [
                            32.10595703125,
                            -26.52001953125
                        ],
                        [
                            32.04140625000002,
                            -26.28125
                        ],
                        [
                            31.9482421875,
                            -25.957617187500006
                        ],
                        [
                            31.920312500000023,
                            -25.77392578125
                        ],
                        [
                            31.98457031250001,
                            -25.631933593750006
                        ],
                        [
                            31.98701171875001,
                            -25.263476562500003
                        ],
                        [
                            31.984375,
                            -24.844042968750003
                        ],
                        [
                            31.983203125000017,
                            -24.638281250000006
                        ],
                        [
                            31.98583984375,
                            -24.46064453125001
                        ],
                        [
                            31.858300781250023,
                            -24.04023437500001
                        ],
                        [
                            31.799609375000017,
                            -23.892187500000006
                        ],
                        [
                            31.54560546875001,
                            -23.48232421875001
                        ],
                        [
                            31.287890625000017,
                            -22.40205078125001
                        ],
                        [
                            31.429492187500017,
                            -22.298828125
                        ],
                        [
                            32.37109375,
                            -21.33486328125001
                        ],
                        [
                            32.477636718750006,
                            -20.712988281250006
                        ],
                        [
                            33.00673828125002,
                            -19.873828125000003
                        ],
                        [
                            32.71650390625001,
                            -19.00185546875001
                        ],
                        [
                            32.99306640625002,
                            -18.35957031250001
                        ],
                        [
                            32.94804687500002,
                            -16.71230468750001
                        ],
                        [
                            32.243261718750006,
                            -16.44873046875
                        ],
                        [
                            31.939843750000023,
                            -16.428808593750006
                        ],
                        [
                            31.236230468750023,
                            -16.02363281250001
                        ],
                        [
                            30.437792968750017,
                            -15.995312500000011
                        ],
                        [
                            30.396093750000006,
                            -15.64306640625
                        ],
                        [
                            30.231835937500023,
                            -14.990332031250006
                        ],
                        [
                            31.728906250000023,
                            -14.49609375
                        ],
                        [
                            33.201757812500006,
                            -14.013378906250011
                        ],
                        [
                            33.63642578125001,
                            -14.568164062500003
                        ],
                        [
                            34.33251953125,
                            -14.408593750000009
                        ],
                        [
                            34.50527343750002,
                            -14.59814453125
                        ],
                        [
                            34.54082031250002,
                            -15.297265625000009
                        ],
                        [
                            34.24824218750001,
                            -15.887500000000003
                        ],
                        [
                            34.28828125000001,
                            -15.936132812500006
                        ],
                        [
                            34.3759765625,
                            -16.023730468750003
                        ],
                        [
                            34.40302734375001,
                            -16.08027343750001
                        ],
                        [
                            34.395117187500006,
                            -16.130859375
                        ],
                        [
                            34.3955078125,
                            -16.19921875
                        ],
                        [
                            34.93339843750002,
                            -16.760351562500006
                        ],
                        [
                            35.11210937500002,
                            -16.898535156250006
                        ],
                        [
                            35.242773437500006,
                            -16.37539062500001
                        ],
                        [
                            35.358496093750006,
                            -16.16054687500001
                        ],
                        [
                            35.59931640625001,
                            -16.12587890625001
                        ],
                        [
                            35.70888671875002,
                            -16.095800781250006
                        ],
                        [
                            35.75527343750002,
                            -16.05830078125001
                        ],
                        [
                            35.79121093750001,
                            -15.958691406250011
                        ],
                        [
                            35.83027343750001,
                            -15.4189453125
                        ],
                        [
                            35.80537109375001,
                            -15.265625
                        ],
                        [
                            35.86669921875,
                            -14.86376953125
                        ],
                        [
                            35.84716796875,
                            -14.6708984375
                        ],
                        [
                            35.6904296875,
                            -14.465527343750011
                        ],
                        [
                            35.48847656250001,
                            -14.201074218750009
                        ],
                        [
                            35.37578125000002,
                            -14.058691406250006
                        ],
                        [
                            35.24746093750002,
                            -13.896875000000009
                        ],
                        [
                            34.54570312500002,
                            -13.21630859375
                        ],
                        [
                            34.542578125000006,
                            -13.108691406250003
                        ],
                        [
                            34.36083984375,
                            -12.210546875000006
                        ],
                        [
                            34.60625000000002,
                            -11.690039062500006
                        ],
                        [
                            34.65957031250002,
                            -11.588671875000003
                        ],
                        [
                            34.82656250000002,
                            -11.57568359375
                        ],
                        [
                            34.95947265625,
                            -11.578125
                        ],
                        [
                            35.1826171875,
                            -11.574804687500006
                        ],
                        [
                            35.41826171875002,
                            -11.583203125000011
                        ],
                        [
                            35.50439453125,
                            -11.604785156250003
                        ],
                        [
                            35.56435546875002,
                            -11.602343750000003
                        ],
                        [
                            35.630957031250006,
                            -11.58203125
                        ],
                        [
                            35.78544921875002,
                            -11.452929687500003
                        ],
                        [
                            35.91132812500001,
                            -11.454687500000006
                        ],
                        [
                            36.08222656250001,
                            -11.537304687500011
                        ],
                        [
                            36.17548828125001,
                            -11.609277343750009
                        ],
                        [
                            36.19130859375002,
                            -11.670703125000003
                        ],
                        [
                            36.3056640625,
                            -11.706347656250003
                        ],
                        [
                            36.518652343750006,
                            -11.716210937500009
                        ],
                        [
                            36.673828125,
                            -11.684277343750011
                        ],
                        [
                            36.771093750000006,
                            -11.6103515625
                        ],
                        [
                            36.872656250000006,
                            -11.5712890625
                        ],
                        [
                            36.97890625000002,
                            -11.566992187500006
                        ],
                        [
                            37.54169921875001,
                            -11.675097656250003
                        ],
                        [
                            37.92021484375002,
                            -11.294726562500003
                        ],
                        [
                            38.491796875000006,
                            -11.413281250000011
                        ],
                        [
                            38.79472656250002,
                            -11.228906250000009
                        ],
                        [
                            38.98750000000001,
                            -11.167285156250003
                        ],
                        [
                            39.170996093750006,
                            -11.166894531250009
                        ],
                        [
                            39.32158203125002,
                            -11.12255859375
                        ],
                        [
                            39.5634765625,
                            -10.978515625
                        ],
                        [
                            39.81708984375001,
                            -10.912402343750003
                        ],
                        [
                            39.98867187500002,
                            -10.82080078125
                        ],
                        [
                            40.46357421875001,
                            -10.46435546875
                        ],
                        [
                            40.544531250000006,
                            -11.065625000000011
                        ],
                        [
                            40.40283203125,
                            -11.33203125
                        ],
                        [
                            40.58085937500002,
                            -12.635546875000003
                        ],
                        [
                            40.54511718750001,
                            -13.462890625
                        ],
                        [
                            40.63994140625002,
                            -14.390039062500009
                        ],
                        [
                            40.83515625000001,
                            -14.79150390625
                        ],
                        [
                            40.55898437500002,
                            -15.473437500000003
                        ],
                        [
                            40.10878906250002,
                            -15.979296875000003
                        ],
                        [
                            39.84462890625002,
                            -16.435644531250006
                        ],
                        [
                            39.08437500000002,
                            -16.97285156250001
                        ],
                        [
                            38.14492187500002,
                            -17.242773437500006
                        ],
                        [
                            37.24453125000002,
                            -17.73994140625001
                        ],
                        [
                            36.540136718750006,
                            -18.518164062500006
                        ],
                        [
                            35.65126953125002,
                            -19.163867187500003
                        ],
                        [
                            34.947851562500006,
                            -19.81269531250001
                        ],
                        [
                            34.75576171875002,
                            -19.82197265625001
                        ],
                        [
                            34.69814453125002,
                            -20.404394531250006
                        ],
                        [
                            34.98232421875002,
                            -20.806250000000006
                        ],
                        [
                            35.267675781250006,
                            -21.650976562500006
                        ],
                        [
                            35.31572265625002,
                            -22.39687500000001
                        ],
                        [
                            35.57539062500001,
                            -22.96308593750001
                        ],
                        [
                            35.37041015625002,
                            -23.79824218750001
                        ],
                        [
                            35.438085937500006,
                            -24.171191406250003
                        ],
                        [
                            35.15595703125001,
                            -24.54140625000001
                        ],
                        [
                            34.60732421875002,
                            -24.8212890625
                        ],
                        [
                            33.34746093750002,
                            -25.26093750000001
                        ],
                        [
                            32.79218750000001,
                            -25.644335937500003
                        ],
                        [
                            32.590429687500006,
                            -26.00410156250001
                        ],
                        [
                            32.93359375,
                            -26.25234375000001
                        ],
                        [
                            32.88916015625,
                            -26.83046875000001
                        ],
                        [
                            32.88613281250002,
                            -26.84931640625001
                        ],
                        [
                            32.353515625,
                            -26.861621093750003
                        ],
                        [
                            32.19960937500002,
                            -26.83349609375
                        ],
                        [
                            32.112890625000006,
                            -26.839453125000006
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Mozambique',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -5.359912109374989,
                            16.282861328124994
                        ],
                        [
                            -5.512499999999989,
                            15.496289062499983
                        ],
                        [
                            -5.723876953124972,
                            15.496289062499983
                        ],
                        [
                            -6.131787109374926,
                            15.496240234374966
                        ],
                        [
                            -6.335742187500017,
                            15.496191406250063
                        ],
                        [
                            -8.17124023437492,
                            15.496142578125045
                        ],
                        [
                            -9.293701171875,
                            15.502832031249994
                        ],
                        [
                            -9.941406249999972,
                            15.373779296874986
                        ],
                        [
                            -10.696582031249989,
                            15.422656250000003
                        ],
                        [
                            -10.895605468749949,
                            15.150488281249977
                        ],
                        [
                            -10.9482421875,
                            15.151123046875014
                        ],
                        [
                            -11.007421874999977,
                            15.222900390624986
                        ],
                        [
                            -11.169335937500023,
                            15.358642578125014
                        ],
                        [
                            -11.455224609374994,
                            15.625390624999994
                        ],
                        [
                            -11.760156249999937,
                            15.425537109375057
                        ],
                        [
                            -11.828759765624966,
                            15.244873046875014
                        ],
                        [
                            -11.842236328124955,
                            15.129394531250028
                        ],
                        [
                            -11.872851562499989,
                            14.995166015625031
                        ],
                        [
                            -12.104687499999955,
                            14.745361328125043
                        ],
                        [
                            -12.40869140625,
                            14.889013671874991
                        ],
                        [
                            -12.858496093749977,
                            15.242529296874977
                        ],
                        [
                            -13.105273437499989,
                            15.57177734375
                        ],
                        [
                            -13.40966796875,
                            16.059179687500006
                        ],
                        [
                            -13.756640624999989,
                            16.172509765624994
                        ],
                        [
                            -14.300097656249932,
                            16.58027343750001
                        ],
                        [
                            -15.11264648437492,
                            16.64492187500005
                        ],
                        [
                            -15.768212890624994,
                            16.485107421875
                        ],
                        [
                            -16.168359374999966,
                            16.54707031250001
                        ],
                        [
                            -16.34667968749997,
                            16.926416015625023
                        ],
                        [
                            -16.07890624999996,
                            17.54584960937501
                        ],
                        [
                            -16.084960937499943,
                            18.52119140625001
                        ],
                        [
                            -16.213085937499926,
                            19.003320312500023
                        ],
                        [
                            -16.51445312499996,
                            19.361962890624994
                        ],
                        [
                            -16.283398437499955,
                            19.787158203125017
                        ],
                        [
                            -16.21044921875003,
                            20.227929687500023
                        ],
                        [
                            -16.42978515624995,
                            20.652343750000057
                        ],
                        [
                            -17.06396484375,
                            20.898828124999994
                        ],
                        [
                            -16.96455078125001,
                            21.329248046875023
                        ],
                        [
                            -15.231201171875,
                            21.331298828125
                        ],
                        [
                            -13.855371093749937,
                            21.332958984374983
                        ],
                        [
                            -13.396728515624943,
                            21.333544921875017
                        ],
                        [
                            -13.167431640624926,
                            21.333789062500074
                        ],
                        [
                            -13.016210937499949,
                            21.33393554687501
                        ],
                        [
                            -13.025097656249983,
                            21.46679687499997
                        ],
                        [
                            -13.032226562500028,
                            21.572070312500017
                        ],
                        [
                            -13.041748046875,
                            21.71381835937504
                        ],
                        [
                            -13.051220703124983,
                            21.854785156250074
                        ],
                        [
                            -13.094335937499977,
                            22.49599609375005
                        ],
                        [
                            -13.107324218749937,
                            22.56074218750001
                        ],
                        [
                            -13.155957031250011,
                            22.689306640625006
                        ],
                        [
                            -13.166503906250028,
                            22.753222656250045
                        ],
                        [
                            -13.153271484374983,
                            22.820507812499983
                        ],
                        [
                            -13.12089843749996,
                            22.884082031250074
                        ],
                        [
                            -13.031494140624943,
                            23.000244140625
                        ],
                        [
                            -12.372900390624977,
                            23.318017578124994
                        ],
                        [
                            -12.023437499999943,
                            23.467578125000017
                        ],
                        [
                            -12.016308593749983,
                            23.97021484375
                        ],
                        [
                            -12.016308593749983,
                            24.378662109375
                        ],
                        [
                            -12.016308593749983,
                            24.923242187499994
                        ],
                        [
                            -12.016308593749983,
                            25.331689453124994
                        ],
                        [
                            -12.016308593749983,
                            25.740136718749994
                        ],
                        [
                            -12.016308593749983,
                            25.995410156250017
                        ],
                        [
                            -10.562451171874926,
                            25.995458984375034
                        ],
                        [
                            -9.81718749999996,
                            25.995458984375034
                        ],
                        [
                            -9.258203124999966,
                            25.99550781250005
                        ],
                        [
                            -8.885644531249994,
                            25.99550781250005
                        ],
                        [
                            -8.682226562499949,
                            25.99550781250005
                        ],
                        [
                            -8.68212890625,
                            26.109472656250006
                        ],
                        [
                            -8.68212890625,
                            26.273193359375057
                        ],
                        [
                            -8.682324218749955,
                            26.49770507812505
                        ],
                        [
                            -8.683349609375,
                            27.285937500000045
                        ],
                        [
                            -6.2386718749999375,
                            25.848193359375045
                        ],
                        [
                            -4.822607421874949,
                            24.99560546875
                        ],
                        [
                            -5.172900390624989,
                            24.995410156250045
                        ],
                        [
                            -5.959814453124977,
                            24.994970703125063
                        ],
                        [
                            -6.287207031249977,
                            24.99482421875001
                        ],
                        [
                            -6.594091796874977,
                            24.99462890624997
                        ],
                        [
                            -6.396582031249977,
                            23.274804687499994
                        ],
                        [
                            -6.0833984374999375,
                            20.539501953124983
                        ],
                        [
                            -5.9979492187499375,
                            19.793505859375045
                        ],
                        [
                            -5.969482421875,
                            19.54487304687504
                        ],
                        [
                            -5.941015624999977,
                            19.29619140624999
                        ],
                        [
                            -5.884082031250017,
                            18.798876953125017
                        ],
                        [
                            -5.855566406249977,
                            18.550244140624983
                        ],
                        [
                            -5.8270996093749545,
                            18.301562500000045
                        ],
                        [
                            -5.741699218749943,
                            17.555566406250023
                        ],
                        [
                            -5.713183593750017,
                            17.306884765625
                        ],
                        [
                            -5.684765624999983,
                            17.058251953124966
                        ],
                        [
                            -5.628662109375028,
                            16.568652343750045
                        ],
                        [
                            -5.50961914062492,
                            16.442041015625023
                        ],
                        [
                            -5.359912109374989,
                            16.282861328124994
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Mauritania',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            34.95947265625003,
                            -11.578125000000028
                        ],
                        [
                            34.82656250000005,
                            -11.575683593749972
                        ],
                        [
                            34.65957031250005,
                            -11.58867187499996
                        ],
                        [
                            34.61855468750005,
                            -11.620214843749991
                        ],
                        [
                            34.60625000000002,
                            -11.690039062500006
                        ],
                        [
                            34.36083984375003,
                            -12.210546874999991
                        ],
                        [
                            34.542578125000006,
                            -13.108691406250003
                        ],
                        [
                            34.54570312500002,
                            -13.21630859375
                        ],
                        [
                            35.247460937499994,
                            -13.896874999999994
                        ],
                        [
                            35.37578125000002,
                            -14.05869140625002
                        ],
                        [
                            35.48847656250004,
                            -14.20107421874998
                        ],
                        [
                            35.69042968749997,
                            -14.465527343750026
                        ],
                        [
                            35.84716796875003,
                            -14.670898437500043
                        ],
                        [
                            35.86669921875003,
                            -14.863769531249986
                        ],
                        [
                            35.83027343750004,
                            -15.418945312500028
                        ],
                        [
                            35.7912109375001,
                            -15.958691406250026
                        ],
                        [
                            35.75527343750005,
                            -16.058300781249983
                        ],
                        [
                            35.708886718749994,
                            -16.095800781249977
                        ],
                        [
                            35.5993164062501,
                            -16.12587890624998
                        ],
                        [
                            35.35849609375006,
                            -16.160546875000023
                        ],
                        [
                            35.242773437500006,
                            -16.37539062500001
                        ],
                        [
                            35.11210937500002,
                            -16.898535156250006
                        ],
                        [
                            34.93339843750002,
                            -16.760351562500006
                        ],
                        [
                            34.39550781249997,
                            -16.19921875
                        ],
                        [
                            34.39511718750006,
                            -16.130859375000014
                        ],
                        [
                            34.4030273437501,
                            -16.08027343750001
                        ],
                        [
                            34.37597656249997,
                            -16.02373046874996
                        ],
                        [
                            34.2482421875001,
                            -15.887500000000003
                        ],
                        [
                            34.54082031250002,
                            -15.297265625000009
                        ],
                        [
                            34.50527343750005,
                            -14.598144531249957
                        ],
                        [
                            34.33251953125003,
                            -14.40859374999998
                        ],
                        [
                            33.63642578125004,
                            -14.568164062499974
                        ],
                        [
                            33.148046875000006,
                            -13.94091796875
                        ],
                        [
                            32.797460937500006,
                            -13.6884765625
                        ],
                        [
                            32.67207031250004,
                            -13.610351562499986
                        ],
                        [
                            32.851855468750074,
                            -13.457031250000014
                        ],
                        [
                            32.96757812500002,
                            -13.224999999999994
                        ],
                        [
                            32.99999999999997,
                            -12.89960937500004
                        ],
                        [
                            32.97050781250002,
                            -12.864746093749957
                        ],
                        [
                            32.945605468750074,
                            -12.804394531250011
                        ],
                        [
                            33.021582031250006,
                            -12.63046875000002
                        ],
                        [
                            33.24345703125002,
                            -12.556542968749966
                        ],
                        [
                            33.39794921874997,
                            -12.489843749999963
                        ],
                        [
                            33.43066406249997,
                            -12.460449218750014
                        ],
                        [
                            33.48320312500002,
                            -12.403417968750034
                        ],
                        [
                            33.4914062500001,
                            -12.331054687499943
                        ],
                        [
                            33.25234375000005,
                            -12.112597656250031
                        ],
                        [
                            33.226367187500074,
                            -11.534863281250011
                        ],
                        [
                            33.23271484375002,
                            -11.417675781250026
                        ],
                        [
                            33.26835937500002,
                            -11.403906249999977
                        ],
                        [
                            33.379785156249994,
                            -11.15791015625004
                        ],
                        [
                            33.338671875000074,
                            -11.085156249999969
                        ],
                        [
                            33.29326171875002,
                            -10.981152343749997
                        ],
                        [
                            33.27275390625002,
                            -10.915039062499943
                        ],
                        [
                            33.261328125000006,
                            -10.893359374999974
                        ],
                        [
                            33.29277343750002,
                            -10.85234374999996
                        ],
                        [
                            33.3449218750001,
                            -10.812695312499969
                        ],
                        [
                            33.464746093749994,
                            -10.78310546874998
                        ],
                        [
                            33.65908203125005,
                            -10.590527343749997
                        ],
                        [
                            33.528906250000006,
                            -10.234667968749974
                        ],
                        [
                            33.31152343750003,
                            -10.037988281249966
                        ],
                        [
                            33.3371093750001,
                            -9.954003906249994
                        ],
                        [
                            33.350976562499994,
                            -9.862207031250037
                        ],
                        [
                            33.148046875000006,
                            -9.603515625
                        ],
                        [
                            32.91992187500003,
                            -9.407421875000026
                        ],
                        [
                            34.32089843750006,
                            -9.731542968749977
                        ],
                        [
                            34.524218750000074,
                            -10.03017578124998
                        ],
                        [
                            34.66181640625004,
                            -10.71005859375002
                        ],
                        [
                            34.59765625000003,
                            -11.037500000000023
                        ],
                        [
                            34.77382812500005,
                            -11.341699218750009
                        ],
                        [
                            34.93701171874997,
                            -11.463476562500034
                        ],
                        [
                            34.95263671874997,
                            -11.54374999999996
                        ],
                        [
                            34.95947265625003,
                            -11.578125000000028
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Malawi',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                102.10107421874997,
                                6.242236328125031
                            ],
                            [
                                102.98242187499997,
                                5.524951171875003
                            ],
                            [
                                103.41582031250007,
                                4.85029296875004
                            ],
                            [
                                103.46875000000003,
                                4.393261718750011
                            ],
                            [
                                103.36201171875004,
                                3.769140624999963
                            ],
                            [
                                103.43945312499997,
                                2.93310546875
                            ],
                            [
                                103.8122070312501,
                                2.5804687500000085
                            ],
                            [
                                104.28847656250005,
                                1.4806640625000398
                            ],
                            [
                                103.6945312500001,
                                1.4496582031250256
                            ],
                            [
                                102.72714843750012,
                                1.855566406250034
                            ],
                            [
                                101.29550781250012,
                                2.8852050781250114
                            ],
                            [
                                101.29990234375012,
                                3.253271484375034
                            ],
                            [
                                100.71542968750006,
                                3.966210937499966
                            ],
                            [
                                100.61455078125002,
                                4.652246093750023
                            ],
                            [
                                100.35263671875006,
                                5.587695312499989
                            ],
                            [
                                100.34326171874997,
                                5.984179687500031
                            ],
                            [
                                100.11914062499997,
                                6.441992187500048
                            ],
                            [
                                100.3454101562501,
                                6.549902343750006
                            ],
                            [
                                101.05351562500002,
                                6.2425781250000085
                            ],
                            [
                                101.2570312500001,
                                5.7893554687500455
                            ],
                            [
                                101.5560546875,
                                5.907763671875003
                            ],
                            [
                                101.87363281250012,
                                5.8252929687499915
                            ],
                            [
                                102.10107421874997,
                                6.242236328125031
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                117.5744140625001,
                                4.17060546875004
                            ],
                            [
                                117.10058593750003,
                                4.337060546875023
                            ],
                            [
                                115.89619140625004,
                                4.348681640624989
                            ],
                            [
                                115.56845703125006,
                                3.9387695312499744
                            ],
                            [
                                115.45439453125002,
                                3.0343261718750085
                            ],
                            [
                                115.086328125,
                                2.8411132812499886
                            ],
                            [
                                115.1791015625,
                                2.5231933593749716
                            ],
                            [
                                114.78642578125002,
                                2.250488281250014
                            ],
                            [
                                114.83056640625003,
                                1.9800292968749886
                            ],
                            [
                                114.54589843750003,
                                1.467138671875034
                            ],
                            [
                                113.90234375000003,
                                1.4342773437499972
                            ],
                            [
                                113.6222656250001,
                                1.2359375000000057
                            ],
                            [
                                112.47617187500006,
                                1.5590820312500284
                            ],
                            [
                                112.1857421875001,
                                1.4390624999999915
                            ],
                            [
                                112.078515625,
                                1.1433593749999744
                            ],
                            [
                                111.76972656250004,
                                0.9994628906249687
                            ],
                            [
                                110.93808593750006,
                                1.0173339843750568
                            ],
                            [
                                110.50576171875005,
                                0.8619628906250227
                            ],
                            [
                                109.65400390625004,
                                1.6148925781250227
                            ],
                            [
                                109.62890625000003,
                                2.027539062499983
                            ],
                            [
                                109.9845703125001,
                                1.7176269531249915
                            ],
                            [
                                110.34921875000012,
                                1.7197265624999716
                            ],
                            [
                                110.93994140625003,
                                1.5173339843750426
                            ],
                            [
                                111.15419921875005,
                                1.7387695312500426
                            ],
                            [
                                111.24218749999997,
                                2.4357421875000114
                            ],
                            [
                                111.72773437500004,
                                2.853808593750003
                            ],
                            [
                                112.73730468749997,
                                3.0704589843749943
                            ],
                            [
                                113.04472656250002,
                                3.2052246093750085
                            ],
                            [
                                113.92392578125006,
                                4.243212890625003
                            ],
                            [
                                114.0638671875,
                                4.592675781249966
                            ],
                            [
                                114.60830078125005,
                                4.023974609375017
                            ],
                            [
                                114.84023437500005,
                                4.3932128906250085
                            ],
                            [
                                114.74667968750006,
                                4.718066406250017
                            ],
                            [
                                115.02675781250005,
                                4.899707031249989
                            ],
                            [
                                115.1400390625,
                                4.8997558593749915
                            ],
                            [
                                115.51982421875002,
                                5.048925781250006
                            ],
                            [
                                115.41904296875012,
                                5.413183593749963
                            ],
                            [
                                115.79687499999997,
                                5.536132812499972
                            ],
                            [
                                116.1383789062501,
                                6.12954101562498
                            ],
                            [
                                116.49472656250006,
                                6.521679687500026
                            ],
                            [
                                117.29404296875006,
                                6.676904296875023
                            ],
                            [
                                117.64570312500004,
                                6.473681640625017
                            ],
                            [
                                117.61718750000003,
                                5.940722656249974
                            ],
                            [
                                118.00380859375,
                                6.0533203124999915
                            ],
                            [
                                118.11582031250006,
                                5.862500000000026
                            ],
                            [
                                118.59482421875006,
                                5.592089843750003
                            ],
                            [
                                119.22343750000007,
                                5.412646484375031
                            ],
                            [
                                119.13222656250005,
                                5.100488281250037
                            ],
                            [
                                118.18535156250002,
                                4.828515625000051
                            ],
                            [
                                118.49804687499997,
                                4.362353515625031
                            ],
                            [
                                117.5744140625001,
                                4.17060546875004
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Malaysia',
                'childNum': 2
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            23.380664062500017,
                            -17.640625
                        ],
                        [
                            24.27490234375,
                            -17.481054687500006
                        ],
                        [
                            24.932421875000017,
                            -17.54345703125
                        ],
                        [
                            25.2587890625,
                            -17.793554687500006
                        ],
                        [
                            24.358984375000006,
                            -17.97822265625001
                        ],
                        [
                            23.58056640625,
                            -18.452929687500003
                        ],
                        [
                            23.298632812500017,
                            -18.02734375
                        ],
                        [
                            20.97412109375,
                            -18.31884765625
                        ],
                        [
                            20.976171875000006,
                            -19.74433593750001
                        ],
                        [
                            20.970996093750017,
                            -22.00019531250001
                        ],
                        [
                            19.977343750000017,
                            -22.00019531250001
                        ],
                        [
                            19.98046875,
                            -24.77675781250001
                        ],
                        [
                            19.98046875,
                            -26.53115234375001
                        ],
                        [
                            19.98046875,
                            -28.310351562500003
                        ],
                        [
                            19.02607421875001,
                            -28.92792968750001
                        ],
                        [
                            18.310839843750017,
                            -28.88623046875
                        ],
                        [
                            17.44794921875001,
                            -28.69814453125001
                        ],
                        [
                            17.1884765625,
                            -28.13251953125001
                        ],
                        [
                            17.1494140625,
                            -28.08222656250001
                        ],
                        [
                            17.056250000000006,
                            -28.031054687500003
                        ],
                        [
                            16.93330078125001,
                            -28.069628906250003
                        ],
                        [
                            16.875292968750017,
                            -28.1279296875
                        ],
                        [
                            16.841210937500023,
                            -28.21894531250001
                        ],
                        [
                            16.810156250000006,
                            -28.26455078125001
                        ],
                        [
                            16.787500000000023,
                            -28.39472656250001
                        ],
                        [
                            16.755761718750023,
                            -28.4521484375
                        ],
                        [
                            16.447558593750017,
                            -28.61757812500001
                        ],
                        [
                            15.719042968750017,
                            -27.9658203125
                        ],
                        [
                            15.341503906250011,
                            -27.386523437500003
                        ],
                        [
                            14.9677734375,
                            -26.31806640625001
                        ],
                        [
                            14.84521484375,
                            -25.725683593750006
                        ],
                        [
                            14.837109375000011,
                            -25.033203125
                        ],
                        [
                            14.501562500000006,
                            -24.201953125000003
                        ],
                        [
                            14.473828125000011,
                            -23.28115234375001
                        ],
                        [
                            14.525976562500006,
                            -22.70253906250001
                        ],
                        [
                            14.321875000000006,
                            -22.18994140625
                        ],
                        [
                            13.973242187500006,
                            -21.767578125
                        ],
                        [
                            13.450585937500023,
                            -20.91669921875001
                        ],
                        [
                            13.168359375000023,
                            -20.184667968750006
                        ],
                        [
                            12.458203125000011,
                            -18.9267578125
                        ],
                        [
                            12.095703125,
                            -18.54091796875001
                        ],
                        [
                            11.77587890625,
                            -18.001757812500003
                        ],
                        [
                            11.733496093750006,
                            -17.7509765625
                        ],
                        [
                            11.743066406250023,
                            -17.24921875000001
                        ],
                        [
                            11.902539062500011,
                            -17.2265625
                        ],
                        [
                            12.013964843750017,
                            -17.168554687500006
                        ],
                        [
                            12.21337890625,
                            -17.2099609375
                        ],
                        [
                            12.359277343750023,
                            -17.205859375000003
                        ],
                        [
                            12.548144531250017,
                            -17.212695312500003
                        ],
                        [
                            13.101171875000006,
                            -16.96767578125001
                        ],
                        [
                            13.403710937500023,
                            -17.0078125
                        ],
                        [
                            13.93798828125,
                            -17.388769531250006
                        ],
                        [
                            15.765820312500011,
                            -17.3896484375
                        ],
                        [
                            16.1484375,
                            -17.390234375000006
                        ],
                        [
                            16.531054687500017,
                            -17.39082031250001
                        ],
                        [
                            16.913671875000006,
                            -17.391406250000003
                        ],
                        [
                            17.296289062500023,
                            -17.39199218750001
                        ],
                        [
                            17.835351562500023,
                            -17.39277343750001
                        ],
                        [
                            18.396386718750023,
                            -17.3994140625
                        ],
                        [
                            18.718066406250017,
                            -17.703222656250006
                        ],
                        [
                            18.825976562500017,
                            -17.76630859375001
                        ],
                        [
                            19.076464843750017,
                            -17.817675781250003
                        ],
                        [
                            20.392968750000023,
                            -17.88740234375001
                        ],
                        [
                            20.507617187500017,
                            -17.95253906250001
                        ],
                        [
                            20.625097656250006,
                            -17.996679687500006
                        ],
                        [
                            21.416894531250023,
                            -18.00068359375001
                        ],
                        [
                            21.960839843750023,
                            -17.90517578125001
                        ],
                        [
                            22.32421875,
                            -17.837500000000006
                        ],
                        [
                            22.6240234375,
                            -17.78164062500001
                        ],
                        [
                            23.068261718750023,
                            -17.698828125000006
                        ],
                        [
                            23.380664062500017,
                            -17.640625
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Namibia',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            164.20234375000004,
                            -20.246093749999957
                        ],
                        [
                            164.4359375,
                            -20.282226562499957
                        ],
                        [
                            165.191796875,
                            -20.768847656249974
                        ],
                        [
                            165.66279296875004,
                            -21.267187499999977
                        ],
                        [
                            166.94238281250003,
                            -22.09013671875003
                        ],
                        [
                            166.77412109375004,
                            -22.37617187500004
                        ],
                        [
                            166.09609375,
                            -21.95664062500002
                        ],
                        [
                            165.24199218750002,
                            -21.52548828125002
                        ],
                        [
                            164.45468750000012,
                            -20.829101562499986
                        ],
                        [
                            164.16972656250007,
                            -20.48017578125004
                        ],
                        [
                            164.20234375000004,
                            -20.246093749999957
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'France',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            14.97900390625,
                            22.99619140624999
                        ],
                        [
                            15.172265625000023,
                            21.922070312499997
                        ],
                        [
                            15.181835937500011,
                            21.523388671874997
                        ],
                        [
                            15.607324218750023,
                            20.954394531250003
                        ],
                        [
                            15.587109375000011,
                            20.733300781249994
                        ],
                        [
                            15.948828125000006,
                            20.303173828124997
                        ],
                        [
                            15.735058593750011,
                            19.904052734375
                        ],
                        [
                            15.474316406250011,
                            16.908398437499997
                        ],
                        [
                            14.367968750000017,
                            15.750146484374994
                        ],
                        [
                            13.4482421875,
                            14.380664062500003
                        ],
                        [
                            13.505761718750023,
                            14.134423828124994
                        ],
                        [
                            13.606347656250023,
                            13.70458984375
                        ],
                        [
                            13.426953125000011,
                            13.701757812499991
                        ],
                        [
                            13.323828125000006,
                            13.670849609374997
                        ],
                        [
                            12.65478515625,
                            13.326562499999994
                        ],
                        [
                            12.463183593750017,
                            13.09375
                        ],
                        [
                            11.411914062500017,
                            13.353613281249991
                        ],
                        [
                            10.958886718750023,
                            13.371533203124997
                        ],
                        [
                            10.475878906250017,
                            13.330224609374994
                        ],
                        [
                            10.229589843750006,
                            13.281005859375
                        ],
                        [
                            10.184667968750006,
                            13.270117187499991
                        ],
                        [
                            9.615917968750011,
                            12.810644531249991
                        ],
                        [
                            9.201562500000023,
                            12.821484374999997
                        ],
                        [
                            8.750585937500006,
                            12.908154296874997
                        ],
                        [
                            8.4560546875,
                            13.059667968749991
                        ],
                        [
                            8.095019531250017,
                            13.291162109374994
                        ],
                        [
                            7.955761718750011,
                            13.32275390625
                        ],
                        [
                            7.788671875000006,
                            13.337890625
                        ],
                        [
                            7.056738281250006,
                            13.000195312499997
                        ],
                        [
                            6.804296875000006,
                            13.107666015625
                        ],
                        [
                            6.386328125000006,
                            13.603613281249991
                        ],
                        [
                            6.2998046875,
                            13.658789062499991
                        ],
                        [
                            6.184277343750011,
                            13.663671874999991
                        ],
                        [
                            5.838183593750017,
                            13.765380859375
                        ],
                        [
                            5.491992187500017,
                            13.872851562500003
                        ],
                        [
                            5.415820312500017,
                            13.859179687500003
                        ],
                        [
                            5.361621093750017,
                            13.836865234374997
                        ],
                        [
                            5.241894531250011,
                            13.757226562499994
                        ],
                        [
                            4.823339843750006,
                            13.759765625
                        ],
                        [
                            4.147558593750006,
                            13.457714843749997
                        ],
                        [
                            3.9478515625000057,
                            12.775048828124994
                        ],
                        [
                            3.6466796875000114,
                            12.529980468749997
                        ],
                        [
                            3.618457031250017,
                            11.827734374999991
                        ],
                        [
                            3.5954101562500114,
                            11.6962890625
                        ],
                        [
                            3.4498046875000057,
                            11.851953124999994
                        ],
                        [
                            3.3599609375000057,
                            11.880468749999991
                        ],
                        [
                            2.8781250000000114,
                            12.367724609374989
                        ],
                        [
                            2.366015625000017,
                            12.221923828125
                        ],
                        [
                            2.38916015625,
                            11.897070312499991
                        ],
                        [
                            2.0914062500000057,
                            12.277978515624994
                        ],
                        [
                            2.0738281250000057,
                            12.713964843749991
                        ],
                        [
                            1.56494140625,
                            12.635400390624994
                        ],
                        [
                            0.9873046875,
                            13.041894531249994
                        ],
                        [
                            0.9777343750000114,
                            13.551953124999997
                        ],
                        [
                            0.6181640625,
                            13.703417968750003
                        ],
                        [
                            0.16386718750001705,
                            14.497216796874994
                        ],
                        [
                            0.21748046875001137,
                            14.911474609374991
                        ],
                        [
                            0.9600585937500057,
                            14.986914062499991
                        ],
                        [
                            1.3001953125000227,
                            15.272265625000003
                        ],
                        [
                            3.5042968750000227,
                            15.356347656249994
                        ],
                        [
                            3.876953125,
                            15.755273437499994
                        ],
                        [
                            4.121289062500011,
                            16.357714843750003
                        ],
                        [
                            4.234667968750017,
                            16.996386718750003
                        ],
                        [
                            4.227636718750006,
                            19.142773437499997
                        ],
                        [
                            5.836621093750011,
                            19.479150390624994
                        ],
                        [
                            7.481738281250017,
                            20.873095703125003
                        ],
                        [
                            9.378710937500017,
                            21.991406249999997
                        ],
                        [
                            11.967871093750006,
                            23.517871093750003
                        ],
                        [
                            13.481250000000017,
                            23.18017578125
                        ],
                        [
                            14.230761718750017,
                            22.618457031250003
                        ],
                        [
                            14.97900390625,
                            22.99619140624999
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Niger',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            6.804296875000006,
                            13.107666015625
                        ],
                        [
                            7.056738281250006,
                            13.00019531250004
                        ],
                        [
                            7.788671875000006,
                            13.337890625
                        ],
                        [
                            7.955761718750011,
                            13.322753906250028
                        ],
                        [
                            8.095019531250045,
                            13.29116210937498
                        ],
                        [
                            8.750585937500034,
                            12.908154296875026
                        ],
                        [
                            9.20156250000008,
                            12.82148437500004
                        ],
                        [
                            9.615917968750011,
                            12.810644531249963
                        ],
                        [
                            10.184667968750063,
                            13.270117187499963
                        ],
                        [
                            10.229589843749977,
                            13.281005859375043
                        ],
                        [
                            10.475878906250074,
                            13.330224609375037
                        ],
                        [
                            10.958886718750051,
                            13.371533203125011
                        ],
                        [
                            11.411914062500074,
                            13.353613281250006
                        ],
                        [
                            12.463183593750017,
                            13.09375
                        ],
                        [
                            12.654785156250057,
                            13.326562500000009
                        ],
                        [
                            13.323828125000034,
                            13.670849609374983
                        ],
                        [
                            13.426953125000068,
                            13.701757812499963
                        ],
                        [
                            13.606347656250023,
                            13.704589843750014
                        ],
                        [
                            13.932324218750011,
                            13.258496093749997
                        ],
                        [
                            14.06396484375,
                            13.078515625000009
                        ],
                        [
                            14.160058593750023,
                            12.612792968749986
                        ],
                        [
                            14.184863281250017,
                            12.447216796874997
                        ],
                        [
                            14.272851562500023,
                            12.356494140624989
                        ],
                        [
                            14.627148437500068,
                            12.10869140624996
                        ],
                        [
                            14.559765625000011,
                            11.492285156249963
                        ],
                        [
                            13.892089843750057,
                            11.140087890624983
                        ],
                        [
                            13.53535156250004,
                            10.605078124999963
                        ],
                        [
                            13.269921875000051,
                            10.036181640624974
                        ],
                        [
                            13.175488281250011,
                            9.539648437499977
                        ],
                        [
                            12.929492187500074,
                            9.426269531249972
                        ],
                        [
                            12.651562500000011,
                            8.667773437499989
                        ],
                        [
                            12.40351562500004,
                            8.59555664062502
                        ],
                        [
                            12.311328125000074,
                            8.419726562499989
                        ],
                        [
                            12.2333984375,
                            8.282324218749977
                        ],
                        [
                            12.016015625000051,
                            7.5897460937500085
                        ],
                        [
                            11.809179687500006,
                            7.3450683593749915
                        ],
                        [
                            11.767382812500017,
                            7.272265624999989
                        ],
                        [
                            11.551660156250023,
                            6.697265625
                        ],
                        [
                            11.153320312500057,
                            6.437939453125011
                        ],
                        [
                            11.079687500000063,
                            6.505517578125023
                        ],
                        [
                            11.032519531250045,
                            6.697900390625037
                        ],
                        [
                            10.954199218750006,
                            6.776562499999997
                        ],
                        [
                            10.606250000000074,
                            7.063085937500006
                        ],
                        [
                            10.143554687500057,
                            6.99643554687502
                        ],
                        [
                            10.038867187500045,
                            6.921386718750014
                        ],
                        [
                            9.874218750000068,
                            6.803271484375017
                        ],
                        [
                            9.82070312500008,
                            6.783935546874986
                        ],
                        [
                            9.779882812500034,
                            6.760156250000023
                        ],
                        [
                            9.725585937499972,
                            6.649999999999963
                        ],
                        [
                            9.659960937500017,
                            6.531982421874986
                        ],
                        [
                            9.490234375,
                            6.418652343749997
                        ],
                        [
                            8.997167968750006,
                            5.917724609375
                        ],
                        [
                            8.800976562499983,
                            5.197460937499983
                        ],
                        [
                            8.293066406250006,
                            4.557617187500014
                        ],
                        [
                            7.15468750000008,
                            4.514404296875
                        ],
                        [
                            6.757031250000068,
                            4.343554687500017
                        ],
                        [
                            6.07656250000008,
                            4.290624999999977
                        ],
                        [
                            5.587792968750051,
                            4.647216796874972
                        ],
                        [
                            5.232421875000057,
                            5.483789062499966
                        ],
                        [
                            4.861035156250068,
                            6.026318359374997
                        ],
                        [
                            4.431347656250011,
                            6.348583984375026
                        ],
                        [
                            4.125878906250023,
                            6.411376953125028
                        ],
                        [
                            2.706445312500051,
                            6.369238281249963
                        ],
                        [
                            2.7356445312500455,
                            6.595703125
                        ],
                        [
                            2.7537109374999886,
                            6.661767578124966
                        ],
                        [
                            2.774609374999983,
                            6.711718750000017
                        ],
                        [
                            2.7529296875000284,
                            6.771630859374966
                        ],
                        [
                            2.7317382812500455,
                            6.852832031249989
                        ],
                        [
                            2.721386718750068,
                            6.980273437500017
                        ],
                        [
                            2.750488281250057,
                            7.39506835937496
                        ],
                        [
                            2.686035156250057,
                            7.873730468750054
                        ],
                        [
                            2.703125,
                            8.371826171875
                        ],
                        [
                            2.7748046875000227,
                            9.048535156250026
                        ],
                        [
                            3.044921875,
                            9.08383789062502
                        ],
                        [
                            3.3251953124999716,
                            9.778466796875051
                        ],
                        [
                            3.5572265625000057,
                            9.907324218750006
                        ],
                        [
                            3.6465820312500057,
                            10.408984374999989
                        ],
                        [
                            3.83447265625,
                            10.607421875
                        ],
                        [
                            3.7568359375,
                            10.768749999999997
                        ],
                        [
                            3.7164062500000057,
                            11.07958984375
                        ],
                        [
                            3.63886718750004,
                            11.176855468750006
                        ],
                        [
                            3.4877929687499716,
                            11.395410156250037
                        ],
                        [
                            3.490527343750017,
                            11.499218750000054
                        ],
                        [
                            3.55390625000004,
                            11.631884765624989
                        ],
                        [
                            3.595410156250068,
                            11.696289062500057
                        ],
                        [
                            3.6184570312500455,
                            11.827734375000006
                        ],
                        [
                            3.6466796875000114,
                            12.529980468749983
                        ],
                        [
                            3.9478515625000057,
                            12.775048828124994
                        ],
                        [
                            4.147558593750006,
                            13.457714843749983
                        ],
                        [
                            4.823339843750006,
                            13.759765624999972
                        ],
                        [
                            5.241894531250011,
                            13.757226562499994
                        ],
                        [
                            5.361621093750074,
                            13.836865234375054
                        ],
                        [
                            5.415820312500017,
                            13.859179687499974
                        ],
                        [
                            5.491992187500074,
                            13.872851562500003
                        ],
                        [
                            6.184277343750068,
                            13.66367187500002
                        ],
                        [
                            6.2998046875,
                            13.658789062500006
                        ],
                        [
                            6.386328125000034,
                            13.603613281250048
                        ],
                        [
                            6.804296875000006,
                            13.107666015625
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Nigeria',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -83.6419921875,
                            10.917236328125
                        ],
                        [
                            -84.168359375,
                            10.780371093749991
                        ],
                        [
                            -84.348291015625,
                            10.979882812499994
                        ],
                        [
                            -84.9091796875,
                            10.9453125
                        ],
                        [
                            -85.62138671874999,
                            11.184472656249994
                        ],
                        [
                            -85.7443359375,
                            11.062109374999991
                        ],
                        [
                            -86.468896484375,
                            11.73828125
                        ],
                        [
                            -86.755615234375,
                            12.156640624999994
                        ],
                        [
                            -87.66752929687499,
                            12.903564453125
                        ],
                        [
                            -87.54331054687499,
                            13.039697265624994
                        ],
                        [
                            -87.33725585937499,
                            12.979248046875
                        ],
                        [
                            -87.05917968749999,
                            12.991455078125
                        ],
                        [
                            -86.95888671875,
                            13.0537109375
                        ],
                        [
                            -86.93315429687499,
                            13.117529296874991
                        ],
                        [
                            -86.92880859374999,
                            13.179394531249997
                        ],
                        [
                            -86.918212890625,
                            13.223583984374997
                        ],
                        [
                            -86.87353515625,
                            13.266503906249994
                        ],
                        [
                            -86.710693359375,
                            13.313378906249994
                        ],
                        [
                            -86.77060546874999,
                            13.69873046875
                        ],
                        [
                            -86.758984375,
                            13.746142578125003
                        ],
                        [
                            -86.733642578125,
                            13.763476562500003
                        ],
                        [
                            -86.61025390625,
                            13.774853515624997
                        ],
                        [
                            -86.376953125,
                            13.755664062500003
                        ],
                        [
                            -86.33173828125,
                            13.770068359375003
                        ],
                        [
                            -86.238232421875,
                            13.899462890625003
                        ],
                        [
                            -86.15122070312499,
                            13.994580078124997
                        ],
                        [
                            -86.0892578125,
                            14.037207031249991
                        ],
                        [
                            -86.04038085937499,
                            14.050146484374991
                        ],
                        [
                            -85.9837890625,
                            13.965673828124991
                        ],
                        [
                            -85.78671874999999,
                            13.844433593749997
                        ],
                        [
                            -85.75341796875,
                            13.85205078125
                        ],
                        [
                            -85.73393554687499,
                            13.858691406250003
                        ],
                        [
                            -85.727734375,
                            13.876074218749991
                        ],
                        [
                            -85.731201171875,
                            13.931835937499997
                        ],
                        [
                            -85.68193359374999,
                            13.982568359374994
                        ],
                        [
                            -85.47705078125,
                            14.108691406250003
                        ],
                        [
                            -85.373779296875,
                            14.223876953125
                        ],
                        [
                            -85.17949218749999,
                            14.343310546875003
                        ],
                        [
                            -84.98515624999999,
                            14.75244140625
                        ],
                        [
                            -84.78916015624999,
                            14.790380859374991
                        ],
                        [
                            -84.72978515624999,
                            14.71337890625
                        ],
                        [
                            -84.645947265625,
                            14.661083984374997
                        ],
                        [
                            -83.635498046875,
                            14.876416015624997
                        ],
                        [
                            -83.4150390625,
                            15.008056640625
                        ],
                        [
                            -83.187744140625,
                            14.340087890625
                        ],
                        [
                            -83.4123046875,
                            13.996484374999994
                        ],
                        [
                            -83.567333984375,
                            13.3203125
                        ],
                        [
                            -83.5109375,
                            12.411816406249997
                        ],
                        [
                            -83.76933593749999,
                            11.931640625
                        ],
                        [
                            -83.6517578125,
                            11.642041015624997
                        ],
                        [
                            -83.86787109375,
                            11.300048828125
                        ],
                        [
                            -83.6419921875,
                            10.917236328125
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Nicaragua',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                4.226171875000034,
                                51.38647460937503
                            ],
                            [
                                3.9020507812500114,
                                51.20766601562502
                            ],
                            [
                                3.35009765625,
                                51.37768554687503
                            ],
                            [
                                4.226171875000034,
                                51.38647460937503
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                4.226171875000034,
                                51.38647460937503
                            ],
                            [
                                3.5869140625,
                                51.45390624999999
                            ],
                            [
                                4.482812500000023,
                                52.30917968749998
                            ],
                            [
                                4.6783203125000625,
                                52.80976562500001
                            ],
                            [
                                6.062207031250068,
                                53.407080078125006
                            ],
                            [
                                6.8162109375000455,
                                53.44116210937503
                            ],
                            [
                                7.197265625000028,
                                53.28227539062499
                            ],
                            [
                                7.035156250000057,
                                52.38022460937498
                            ],
                            [
                                6.7417968750000625,
                                51.91088867187503
                            ],
                            [
                                5.948535156250017,
                                51.762402343749955
                            ],
                            [
                                6.193261718750051,
                                51.48891601562502
                            ],
                            [
                                5.894726562500068,
                                50.98422851562506
                            ],
                            [
                                5.993945312500017,
                                50.75043945312504
                            ],
                            [
                                5.693554687500011,
                                50.774755859375006
                            ],
                            [
                                5.827148437500057,
                                51.125634765624994
                            ],
                            [
                                4.503417968750028,
                                51.47470703124998
                            ],
                            [
                                4.226171875000034,
                                51.38647460937503
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Netherlands',
                'childNum': 2
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                30.869726562500006,
                                69.78344726562506
                            ],
                            [
                                28.96582031250003,
                                69.02197265625
                            ],
                            [
                                29.14160156250003,
                                69.67143554687505
                            ],
                            [
                                27.889941406250045,
                                70.06166992187497
                            ],
                            [
                                26.525390625000057,
                                69.91503906250003
                            ],
                            [
                                26.011523437500074,
                                69.65263671875005
                            ],
                            [
                                25.748339843750017,
                                68.99013671875
                            ],
                            [
                                24.94140625000003,
                                68.59326171875006
                            ],
                            [
                                23.85400390625,
                                68.80590820312503
                            ],
                            [
                                22.410937500000074,
                                68.719873046875
                            ],
                            [
                                21.621777343750068,
                                69.27070312499995
                            ],
                            [
                                20.622167968750006,
                                69.036865234375
                            ],
                            [
                                19.969824218750063,
                                68.35639648437501
                            ],
                            [
                                18.378613281250068,
                                68.56240234374997
                            ],
                            [
                                17.91669921875001,
                                67.96489257812502
                            ],
                            [
                                17.324609375000023,
                                68.10380859374999
                            ],
                            [
                                16.12744140625,
                                67.42583007812507
                            ],
                            [
                                16.40351562500004,
                                67.05498046875002
                            ],
                            [
                                15.422949218750006,
                                66.48984374999998
                            ],
                            [
                                14.543261718750045,
                                66.12934570312498
                            ],
                            [
                                14.47968750000004,
                                65.30146484374998
                            ],
                            [
                                13.650292968750023,
                                64.58154296874997
                            ],
                            [
                                14.077636718750028,
                                64.464013671875
                            ],
                            [
                                13.960546875000063,
                                64.01401367187498
                            ],
                            [
                                12.662500000000023,
                                63.94047851562502
                            ],
                            [
                                11.999902343750051,
                                63.29169921875001
                            ],
                            [
                                12.218164062500023,
                                63.00063476562502
                            ],
                            [
                                12.303515625000074,
                                62.28559570312501
                            ],
                            [
                                12.155371093750006,
                                61.720751953125045
                            ],
                            [
                                12.88076171875008,
                                61.35229492187506
                            ],
                            [
                                12.29414062500004,
                                61.00268554687506
                            ],
                            [
                                12.588671874999989,
                                60.450732421875045
                            ],
                            [
                                12.486132812500074,
                                60.10678710937506
                            ],
                            [
                                11.680761718750034,
                                59.59228515625003
                            ],
                            [
                                11.798144531250074,
                                59.28989257812498
                            ],
                            [
                                11.388281250000063,
                                59.036523437499966
                            ],
                            [
                                10.834472656250028,
                                59.18393554687498
                            ],
                            [
                                9.618457031250017,
                                58.946044921875
                            ],
                            [
                                8.166113281250063,
                                58.145312500000045
                            ],
                            [
                                7.0048828125,
                                58.024218750000074
                            ],
                            [
                                5.706835937500074,
                                58.52363281250001
                            ],
                            [
                                5.84521484375,
                                59.35346679687498
                            ],
                            [
                                5.1316406250000455,
                                59.22646484375002
                            ],
                            [
                                5.244042968750023,
                                60.569580078125
                            ],
                            [
                                5.049121093750017,
                                60.70751953125006
                            ],
                            [
                                4.927832031249977,
                                61.71069335937506
                            ],
                            [
                                5.5333007812500625,
                                62.310888671875034
                            ],
                            [
                                6.352929687500051,
                                62.61113281249999
                            ],
                            [
                                7.283789062499977,
                                62.60229492187497
                            ],
                            [
                                7.00849609375004,
                                62.957666015624994
                            ],
                            [
                                8.100585937500028,
                                63.090966796874966
                            ],
                            [
                                8.398144531249983,
                                63.53510742187504
                            ],
                            [
                                9.696875000000006,
                                63.624560546875045
                            ],
                            [
                                9.708007812500028,
                                63.864892578124994
                            ],
                            [
                                11.349902343750045,
                                64.90590820312497
                            ],
                            [
                                12.15966796875,
                                65.178955078125
                            ],
                            [
                                12.272851562500051,
                                65.56816406250007
                            ],
                            [
                                12.976074218750057,
                                66.01918945312502
                            ],
                            [
                                13.104687500000068,
                                66.53940429687503
                            ],
                            [
                                14.754980468750006,
                                67.49902343749997
                            ],
                            [
                                14.798925781250063,
                                67.80932617187503
                            ],
                            [
                                16.064550781249977,
                                68.19990234375001
                            ],
                            [
                                16.65185546875,
                                68.62578125000002
                            ],
                            [
                                18.101464843749994,
                                69.15629882812499
                            ],
                            [
                                18.293164062500068,
                                69.47509765625003
                            ],
                            [
                                20.068945312500006,
                                69.883447265625
                            ],
                            [
                                21.163085937500057,
                                69.88950195312498
                            ],
                            [
                                21.400390625,
                                70.17446289062505
                            ],
                            [
                                22.684570312500057,
                                70.374755859375
                            ],
                            [
                                23.046484375000063,
                                70.10185546874999
                            ],
                            [
                                24.420019531250034,
                                70.70200195312503
                            ],
                            [
                                24.658007812500017,
                                71.00102539062505
                            ],
                            [
                                25.768164062500063,
                                70.85317382812502
                            ],
                            [
                                27.59707031250005,
                                71.09130859375003
                            ],
                            [
                                29.102343750000045,
                                70.86074218749997
                            ],
                            [
                                30.944140625000017,
                                70.27441406249997
                            ],
                            [
                                29.601367187500017,
                                69.97675781249998
                            ],
                            [
                                29.792089843750063,
                                69.727880859375
                            ],
                            [
                                30.869726562500006,
                                69.78344726562506
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                21.60810546875004,
                                78.59570312499997
                            ],
                            [
                                23.11669921874997,
                                77.99150390624999
                            ],
                            [
                                24.23828125000003,
                                77.89853515625006
                            ],
                            [
                                23.84121093750005,
                                77.49775390625004
                            ],
                            [
                                22.056835937500068,
                                77.50117187500001
                            ],
                            [
                                21.6083984375,
                                77.91606445312499
                            ],
                            [
                                20.22792968750005,
                                78.47783203125005
                            ],
                            [
                                21.60810546875004,
                                78.59570312499997
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                16.786718750000034,
                                79.90673828125
                            ],
                            [
                                18.58144531250008,
                                79.57158203125002
                            ],
                            [
                                19.089453125000034,
                                79.15703125000002
                            ],
                            [
                                20.458203125000068,
                                79.12924804687506
                            ],
                            [
                                21.388769531250034,
                                78.74042968749998
                            ],
                            [
                                19.76875000000004,
                                78.62270507812497
                            ],
                            [
                                18.439257812500074,
                                78.025048828125
                            ],
                            [
                                16.700488281250045,
                                76.57929687499995
                            ],
                            [
                                14.365820312500034,
                                77.23447265625003
                            ],
                            [
                                14.920800781250023,
                                77.68881835937506
                            ],
                            [
                                13.680566406250051,
                                78.02812499999999
                            ],
                            [
                                11.77382812500008,
                                78.71640625000006
                            ],
                            [
                                10.86591796875004,
                                79.79658203125001
                            ],
                            [
                                12.753515625000006,
                                79.77578124999997
                            ],
                            [
                                13.383593750000045,
                                79.48076171874999
                            ],
                            [
                                15.816113281250011,
                                79.68183593750001
                            ],
                            [
                                16.786718750000034,
                                79.90673828125
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                20.897851562500023,
                                80.24995117187501
                            ],
                            [
                                22.450781250000034,
                                80.40224609375005
                            ],
                            [
                                26.86083984375,
                                80.16000976562498
                            ],
                            [
                                27.19863281250008,
                                79.90659179687506
                            ],
                            [
                                25.641210937500034,
                                79.40302734374995
                            ],
                            [
                                23.94775390625,
                                79.19428710937498
                            ],
                            [
                                20.861132812500017,
                                79.39785156249997
                            ],
                            [
                                18.725000000000023,
                                79.7607421875
                            ],
                            [
                                18.343847656250006,
                                80.05957031250006
                            ],
                            [
                                20.897851562500023,
                                80.24995117187501
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Norway',
                'childNum': 4
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            88.10976562500002,
                            27.87060546875
                        ],
                        [
                            88.14697265625,
                            27.749218749999997
                        ],
                        [
                            88.02412109375001,
                            27.408886718749997
                        ],
                        [
                            87.984375,
                            27.133935546874994
                        ],
                        [
                            87.9931640625,
                            27.086083984374994
                        ],
                        [
                            88.11103515625001,
                            26.928466796875
                        ],
                        [
                            88.1572265625,
                            26.807324218749997
                        ],
                        [
                            88.16152343750002,
                            26.724804687499997
                        ],
                        [
                            88.11152343750001,
                            26.58642578125
                        ],
                        [
                            88.05488281250001,
                            26.430029296875
                        ],
                        [
                            88.02695312500003,
                            26.39501953125
                        ],
                        [
                            87.9951171875,
                            26.382373046874996
                        ],
                        [
                            87.84921875000003,
                            26.436914062499994
                        ],
                        [
                            87.63339843750003,
                            26.399121093749997
                        ],
                        [
                            87.28740234374999,
                            26.360302734374997
                        ],
                        [
                            87.01640624999999,
                            26.555419921875
                        ],
                        [
                            86.70136718750001,
                            26.43505859375
                        ],
                        [
                            86.36611328125002,
                            26.574414062499997
                        ],
                        [
                            85.29296875,
                            26.741015624999996
                        ],
                        [
                            84.68535156249999,
                            27.041015625
                        ],
                        [
                            84.65478515625,
                            27.203662109374996
                        ],
                        [
                            84.64072265625003,
                            27.249853515625
                        ],
                        [
                            84.61015624999999,
                            27.298681640625
                        ],
                        [
                            84.48085937500002,
                            27.348193359374996
                        ],
                        [
                            84.22978515624999,
                            27.42783203125
                        ],
                        [
                            84.09101562500001,
                            27.491357421874994
                        ],
                        [
                            83.28974609375001,
                            27.370996093749994
                        ],
                        [
                            82.7333984375,
                            27.518994140624997
                        ],
                        [
                            82.71083984375002,
                            27.5966796875
                        ],
                        [
                            82.67734375000003,
                            27.6734375
                        ],
                        [
                            82.6298828125,
                            27.687060546874996
                        ],
                        [
                            82.45136718750001,
                            27.671826171874997
                        ],
                        [
                            82.28769531250003,
                            27.756542968749997
                        ],
                        [
                            82.11191406250003,
                            27.864941406249997
                        ],
                        [
                            82.03701171875002,
                            27.900585937499997
                        ],
                        [
                            81.98769531250002,
                            27.913769531249997
                        ],
                        [
                            81.94521484375002,
                            27.899267578125
                        ],
                        [
                            81.89687500000002,
                            27.874462890624997
                        ],
                        [
                            81.85263671875003,
                            27.867089843749994
                        ],
                        [
                            80.58701171875003,
                            28.649609374999997
                        ],
                        [
                            80.07070312500002,
                            28.83017578125
                        ],
                        [
                            80.05166015625002,
                            28.870312499999997
                        ],
                        [
                            80.08457031250003,
                            28.994189453124996
                        ],
                        [
                            80.13046875000003,
                            29.100390624999996
                        ],
                        [
                            80.16953125000003,
                            29.124316406249996
                        ],
                        [
                            80.23300781250003,
                            29.194628906249996
                        ],
                        [
                            80.25595703125003,
                            29.318017578124994
                        ],
                        [
                            80.2548828125,
                            29.42333984375
                        ],
                        [
                            80.31689453125,
                            29.572070312499996
                        ],
                        [
                            80.40185546875,
                            29.730273437499996
                        ],
                        [
                            80.90761718750002,
                            30.171923828124996
                        ],
                        [
                            81.1103515625,
                            30.036816406249997
                        ],
                        [
                            81.17714843750002,
                            30.039892578125
                        ],
                        [
                            81.25507812500001,
                            30.093310546874996
                        ],
                        [
                            81.41718750000001,
                            30.337597656249997
                        ],
                        [
                            81.64189453124999,
                            30.387499999999996
                        ],
                        [
                            81.85488281250002,
                            30.36240234375
                        ],
                        [
                            82.04335937500002,
                            30.3267578125
                        ],
                        [
                            82.15898437499999,
                            30.115185546874997
                        ],
                        [
                            82.48652343750001,
                            29.94150390625
                        ],
                        [
                            82.64082031250001,
                            29.831201171874994
                        ],
                        [
                            82.85429687499999,
                            29.683398437499996
                        ],
                        [
                            83.01396484374999,
                            29.618066406249994
                        ],
                        [
                            83.15546875000001,
                            29.612646484375
                        ],
                        [
                            83.58349609375,
                            29.18359375
                        ],
                        [
                            83.93593750000002,
                            29.279492187499997
                        ],
                        [
                            84.02197265625,
                            29.253857421874997
                        ],
                        [
                            84.10136718749999,
                            29.219970703125
                        ],
                        [
                            84.12783203125002,
                            29.156298828124996
                        ],
                        [
                            84.17558593749999,
                            29.036376953125
                        ],
                        [
                            84.22871093750001,
                            28.911767578124994
                        ],
                        [
                            84.71425781250002,
                            28.595556640625
                        ],
                        [
                            84.85507812500003,
                            28.553613281249994
                        ],
                        [
                            85.06914062499999,
                            28.609667968749996
                        ],
                        [
                            85.12636718750002,
                            28.60263671875
                        ],
                        [
                            85.15908203125002,
                            28.592236328124997
                        ],
                        [
                            85.16015625,
                            28.571875
                        ],
                        [
                            85.12148437500002,
                            28.484277343749994
                        ],
                        [
                            85.08857421875001,
                            28.372265624999997
                        ],
                        [
                            85.12246093750002,
                            28.315966796874996
                        ],
                        [
                            85.21210937500001,
                            28.292626953124994
                        ],
                        [
                            85.41064453125,
                            28.276025390624994
                        ],
                        [
                            85.67832031250003,
                            28.27744140625
                        ],
                        [
                            85.75947265625001,
                            28.220654296874997
                        ],
                        [
                            85.84023437500002,
                            28.1353515625
                        ],
                        [
                            85.9541015625,
                            27.92822265625
                        ],
                        [
                            86.40869140625,
                            27.928662109374997
                        ],
                        [
                            86.55449218749999,
                            28.085205078125
                        ],
                        [
                            86.69052734375003,
                            28.094921874999997
                        ],
                        [
                            87.02011718750003,
                            27.928662109374997
                        ],
                        [
                            87.14140624999999,
                            27.838330078124997
                        ],
                        [
                            87.29072265625001,
                            27.821923828124994
                        ],
                        [
                            87.68271484375003,
                            27.82138671875
                        ],
                        [
                            88.10976562500002,
                            27.87060546875
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Nepal',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                173.11533203125006,
                                -41.27929687499997
                            ],
                            [
                                173.73789062500006,
                                -40.98896484374998
                            ],
                            [
                                174.30253906249996,
                                -41.019531249999986
                            ],
                            [
                                174.09238281250018,
                                -41.50517578124999
                            ],
                            [
                                174.2831054687501,
                                -41.740624999999994
                            ],
                            [
                                173.54511718750004,
                                -42.517968749999994
                            ],
                            [
                                173.22119140624997,
                                -42.976562499999986
                            ],
                            [
                                172.71855468750002,
                                -43.258789062500014
                            ],
                            [
                                172.9206054687501,
                                -43.89140625000002
                            ],
                            [
                                172.38525390624997,
                                -43.82958984374996
                            ],
                            [
                                171.44257812500004,
                                -44.13583984374999
                            ],
                            [
                                171.11328125000003,
                                -45.03925781250001
                            ],
                            [
                                170.7005859375,
                                -45.68427734374997
                            ],
                            [
                                169.68662109375006,
                                -46.55166015625002
                            ],
                            [
                                168.38212890625007,
                                -46.60537109374995
                            ],
                            [
                                168.1891601562501,
                                -46.362207031249966
                            ],
                            [
                                167.68222656250012,
                                -46.19296875000005
                            ],
                            [
                                166.73154296875006,
                                -46.19785156249998
                            ],
                            [
                                166.73398437500012,
                                -45.54355468749999
                            ],
                            [
                                167.19453125000004,
                                -44.963476562500034
                            ],
                            [
                                167.78701171875,
                                -44.59501953125002
                            ],
                            [
                                168.3666015625,
                                -44.08203124999996
                            ],
                            [
                                168.80644531250002,
                                -43.9919921875
                            ],
                            [
                                169.66152343750005,
                                -43.591210937499966
                            ],
                            [
                                170.30283203125012,
                                -43.10761718750004
                            ],
                            [
                                170.7416015625,
                                -42.92734374999999
                            ],
                            [
                                171.2570312500001,
                                -42.465332031250014
                            ],
                            [
                                171.48623046875,
                                -41.7947265625
                            ],
                            [
                                172.01074218749997,
                                -41.44472656250001
                            ],
                            [
                                172.13945312500002,
                                -40.947265625000014
                            ],
                            [
                                172.64062499999997,
                                -40.51826171875001
                            ],
                            [
                                172.988671875,
                                -40.84824218749999
                            ],
                            [
                                173.11533203125006,
                                -41.27929687499997
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                173.26943359375,
                                -34.93476562499998
                            ],
                            [
                                173.84394531250015,
                                -35.02626953124998
                            ],
                            [
                                174.32031250000003,
                                -35.246679687500034
                            ],
                            [
                                174.54345703125003,
                                -35.58203124999996
                            ],
                            [
                                174.39101562500005,
                                -35.77373046875002
                            ],
                            [
                                174.8021484375,
                                -36.30947265625001
                            ],
                            [
                                174.72246093750007,
                                -36.84121093749998
                            ],
                            [
                                175.29951171875004,
                                -36.99326171874996
                            ],
                            [
                                175.46083984375005,
                                -36.475683593750034
                            ],
                            [
                                175.8761718750001,
                                -36.95771484375
                            ],
                            [
                                176.10839843749997,
                                -37.64511718749998
                            ],
                            [
                                177.33593749999997,
                                -37.99082031249998
                            ],
                            [
                                178.0091796875,
                                -37.55488281249998
                            ],
                            [
                                178.53623046875006,
                                -37.69208984375004
                            ],
                            [
                                178.26767578125006,
                                -38.55117187500001
                            ],
                            [
                                177.976171875,
                                -38.72226562500005
                            ],
                            [
                                177.96562500000002,
                                -39.14248046875004
                            ],
                            [
                                177.40751953124996,
                                -39.08115234374998
                            ],
                            [
                                176.95410156249997,
                                -39.367578124999966
                            ],
                            [
                                177.10986328125009,
                                -39.673144531250045
                            ],
                            [
                                176.8421875000001,
                                -40.15781250000002
                            ],
                            [
                                175.98291015625003,
                                -41.21328125000002
                            ],
                            [
                                175.30976562499998,
                                -41.610644531249974
                            ],
                            [
                                174.63535156250012,
                                -41.289453124999994
                            ],
                            [
                                175.16250000000005,
                                -40.62158203125
                            ],
                            [
                                175.25410156250004,
                                -40.28935546875
                            ],
                            [
                                175.00927734375009,
                                -39.95214843749996
                            ],
                            [
                                173.93437500000013,
                                -39.50908203125002
                            ],
                            [
                                173.84433593750006,
                                -39.13935546875001
                            ],
                            [
                                174.59736328124998,
                                -38.78505859374995
                            ],
                            [
                                174.92802734375002,
                                -37.80449218750003
                            ],
                            [
                                174.72919921875004,
                                -37.44873046875003
                            ],
                            [
                                174.78203125000013,
                                -36.943749999999994
                            ],
                            [
                                174.47558593750009,
                                -36.94189453124997
                            ],
                            [
                                174.44687500000006,
                                -36.45087890625004
                            ],
                            [
                                173.94511718750002,
                                -36.17587890624998
                            ],
                            [
                                173.11669921874997,
                                -35.205273437500026
                            ],
                            [
                                173.26943359375,
                                -34.93476562499998
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'New Zealand',
                'childNum': 2
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            56.38798828125002,
                            24.97919921875004
                        ],
                        [
                            56.64062499999997,
                            24.470312500000006
                        ],
                        [
                            57.219824218750006,
                            23.922753906250023
                        ],
                        [
                            58.57802734375005,
                            23.64345703125005
                        ],
                        [
                            59.80000000000004,
                            22.21992187500001
                        ],
                        [
                            59.37148437500005,
                            21.498828125000017
                        ],
                        [
                            58.89570312500004,
                            21.11279296874997
                        ],
                        [
                            58.47421875000006,
                            20.406884765624966
                        ],
                        [
                            57.86181640624997,
                            20.24414062500003
                        ],
                        [
                            57.71513671875002,
                            19.60693359374997
                        ],
                        [
                            57.81162109375006,
                            19.01708984374997
                        ],
                        [
                            57.176562500000074,
                            18.902587890625
                        ],
                        [
                            56.655078125000074,
                            18.58735351562501
                        ],
                        [
                            56.55078124999997,
                            18.165966796874955
                        ],
                        [
                            56.27031250000002,
                            17.950781249999977
                        ],
                        [
                            55.479101562500006,
                            17.84326171875003
                        ],
                        [
                            55.06416015625004,
                            17.038916015625034
                        ],
                        [
                            54.06816406250002,
                            17.005517578124966
                        ],
                        [
                            53.60986328124997,
                            16.75996093750004
                        ],
                        [
                            53.08564453125004,
                            16.648388671874955
                        ],
                        [
                            52.72919921875004,
                            17.300390625000006
                        ],
                        [
                            51.977636718750006,
                            18.996142578125074
                        ],
                        [
                            53.66689453125005,
                            19.559130859375017
                        ],
                        [
                            54.97734375000002,
                            19.995947265625006
                        ],
                        [
                            55.641015624999994,
                            22.001855468749994
                        ],
                        [
                            55.185839843750074,
                            22.7041015625
                        ],
                        [
                            55.1999023437501,
                            23.034765625000034
                        ],
                        [
                            55.76083984375006,
                            24.24267578125
                        ],
                        [
                            55.795703125000074,
                            24.868115234374955
                        ],
                        [
                            56.06386718750005,
                            24.73876953125
                        ],
                        [
                            56.38798828125002,
                            24.97919921875004
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Oman',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            76.76689453124999,
                            35.66171875
                        ],
                        [
                            77.04863281249999,
                            35.109912109374996
                        ],
                        [
                            77.00087890625002,
                            34.991992187499996
                        ],
                        [
                            76.78291015625001,
                            34.900195312499996
                        ],
                        [
                            76.7490234375,
                            34.847558593749994
                        ],
                        [
                            76.6962890625,
                            34.786914062499996
                        ],
                        [
                            76.59443359375001,
                            34.73583984375
                        ],
                        [
                            75.70917968750001,
                            34.503076171874994
                        ],
                        [
                            74.300390625,
                            34.765380859375
                        ],
                        [
                            74.17197265625,
                            34.7208984375
                        ],
                        [
                            74.05585937500001,
                            34.6806640625
                        ],
                        [
                            73.96123046875002,
                            34.653466796874994
                        ],
                        [
                            74.15,
                            33.506982421874994
                        ],
                        [
                            73.99423828125,
                            33.2421875
                        ],
                        [
                            74.30361328125002,
                            32.991796875
                        ],
                        [
                            74.35458984375,
                            32.768701171874994
                        ],
                        [
                            74.6578125,
                            32.518945312499994
                        ],
                        [
                            74.68574218750001,
                            32.493798828124994
                        ],
                        [
                            74.78886718750002,
                            32.457812499999996
                        ],
                        [
                            74.9873046875,
                            32.462207031249996
                        ],
                        [
                            75.33349609375,
                            32.279199218749994
                        ],
                        [
                            75.25410156250001,
                            32.14033203125
                        ],
                        [
                            75.13876953125,
                            32.104785156249996
                        ],
                        [
                            75.07148437500001,
                            32.08935546875
                        ],
                        [
                            74.73945312500001,
                            31.948828125
                        ],
                        [
                            74.50996093750001,
                            31.712939453124996
                        ],
                        [
                            74.58183593750002,
                            31.52392578125
                        ],
                        [
                            74.59394531250001,
                            31.465380859374996
                        ],
                        [
                            74.53496093750002,
                            31.261376953124994
                        ],
                        [
                            74.51767578125,
                            31.185595703124996
                        ],
                        [
                            74.6103515625,
                            31.112841796874996
                        ],
                        [
                            74.62578125000002,
                            31.068749999999994
                        ],
                        [
                            74.6328125,
                            31.03466796875
                        ],
                        [
                            74.509765625,
                            30.959667968749997
                        ],
                        [
                            74.38037109375,
                            30.893408203125
                        ],
                        [
                            74.33935546875,
                            30.8935546875
                        ],
                        [
                            74.21562500000002,
                            30.768994140624997
                        ],
                        [
                            74.00898437500001,
                            30.519677734374994
                        ],
                        [
                            73.89931640625002,
                            30.435351562499996
                        ],
                        [
                            73.8091796875,
                            30.093359375
                        ],
                        [
                            73.65800781250002,
                            30.033203125
                        ],
                        [
                            73.46748046875001,
                            29.9716796875
                        ],
                        [
                            73.38164062500002,
                            29.934374999999996
                        ],
                        [
                            72.9033203125,
                            29.028759765624997
                        ],
                        [
                            72.34189453125,
                            28.751904296874997
                        ],
                        [
                            72.17919921875,
                            28.421777343749994
                        ],
                        [
                            71.94804687500002,
                            28.177294921874996
                        ],
                        [
                            71.88886718750001,
                            28.0474609375
                        ],
                        [
                            71.87031250000001,
                            27.9625
                        ],
                        [
                            71.71669921875002,
                            27.915087890624996
                        ],
                        [
                            71.54296875,
                            27.869873046875
                        ],
                        [
                            71.18476562500001,
                            27.831640625
                        ],
                        [
                            70.87490234375002,
                            27.714453125
                        ],
                        [
                            70.79794921875,
                            27.709619140624994
                        ],
                        [
                            70.73740234375,
                            27.72900390625
                        ],
                        [
                            70.69160156250001,
                            27.768994140624997
                        ],
                        [
                            70.64912109375001,
                            27.835351562499994
                        ],
                        [
                            70.62910156250001,
                            27.937451171874997
                        ],
                        [
                            70.40371093750002,
                            28.025048828124994
                        ],
                        [
                            70.24433593750001,
                            27.934130859374996
                        ],
                        [
                            70.1939453125,
                            27.894873046875
                        ],
                        [
                            70.14453125,
                            27.849023437499994
                        ],
                        [
                            70.0498046875,
                            27.694726562499994
                        ],
                        [
                            69.89628906250002,
                            27.4736328125
                        ],
                        [
                            69.56796875,
                            27.174609374999996
                        ],
                        [
                            69.47001953125002,
                            26.804443359375
                        ],
                        [
                            70.05937500000002,
                            26.578759765624994
                        ],
                        [
                            70.14921875000002,
                            26.347558593749994
                        ],
                        [
                            70.1001953125,
                            25.910058593749994
                        ],
                        [
                            70.65205078125001,
                            25.422900390625003
                        ],
                        [
                            70.87773437500002,
                            25.06298828125
                        ],
                        [
                            70.95087890625001,
                            24.8916015625
                        ],
                        [
                            71.02070312500001,
                            24.75766601562499
                        ],
                        [
                            71.0478515625,
                            24.687744140625
                        ],
                        [
                            71.00234375000002,
                            24.65390624999999
                        ],
                        [
                            70.97636718750002,
                            24.61874999999999
                        ],
                        [
                            70.9732421875,
                            24.48740234374999
                        ],
                        [
                            71.00625000000002,
                            24.4443359375
                        ],
                        [
                            71.04531250000002,
                            24.429980468750003
                        ],
                        [
                            71.04404296875,
                            24.400097656249997
                        ],
                        [
                            70.98281250000002,
                            24.361035156249997
                        ],
                        [
                            70.92812500000002,
                            24.362353515625003
                        ],
                        [
                            70.88623046875,
                            24.34375
                        ],
                        [
                            70.80507812500002,
                            24.261962890625
                        ],
                        [
                            70.76728515625001,
                            24.245410156250003
                        ],
                        [
                            70.71630859375,
                            24.237988281249997
                        ],
                        [
                            70.54677734375002,
                            24.41831054687499
                        ],
                        [
                            69.71621093750002,
                            24.172607421875
                        ],
                        [
                            69.63417968750002,
                            24.22519531249999
                        ],
                        [
                            69.5591796875,
                            24.273095703124994
                        ],
                        [
                            69.44345703125,
                            24.275390625
                        ],
                        [
                            69.23505859375001,
                            24.268261718749997
                        ],
                        [
                            69.11953125000002,
                            24.26865234374999
                        ],
                        [
                            69.05156250000002,
                            24.286328124999997
                        ],
                        [
                            68.98457031250001,
                            24.273095703124994
                        ],
                        [
                            68.90078125000002,
                            24.292431640624997
                        ],
                        [
                            68.86347656250001,
                            24.266503906249994
                        ],
                        [
                            68.82832031250001,
                            24.26401367187499
                        ],
                        [
                            68.80000000000001,
                            24.30908203125
                        ],
                        [
                            68.78115234375002,
                            24.313720703125
                        ],
                        [
                            68.75898437500001,
                            24.30722656249999
                        ],
                        [
                            68.73964843750002,
                            24.2919921875
                        ],
                        [
                            68.728125,
                            24.265625
                        ],
                        [
                            68.72412109375,
                            23.96469726562499
                        ],
                        [
                            68.48867187500002,
                            23.967236328124997
                        ],
                        [
                            68.38125000000002,
                            23.950878906249997
                        ],
                        [
                            68.28251953125002,
                            23.927978515625
                        ],
                        [
                            68.1650390625,
                            23.857324218749994
                        ],
                        [
                            67.66845703125,
                            23.810986328124997
                        ],
                        [
                            67.30937500000002,
                            24.1748046875
                        ],
                        [
                            67.171484375,
                            24.756103515625
                        ],
                        [
                            66.70302734375002,
                            24.86093749999999
                        ],
                        [
                            66.69863281250002,
                            25.226318359375
                        ],
                        [
                            66.42861328125002,
                            25.575341796874994
                        ],
                        [
                            65.6796875,
                            25.355273437500003
                        ],
                        [
                            64.77666015625002,
                            25.307324218749997
                        ],
                        [
                            64.65898437500002,
                            25.18408203125
                        ],
                        [
                            63.72089843750001,
                            25.385888671874994
                        ],
                        [
                            63.49140625000001,
                            25.210839843749994
                        ],
                        [
                            62.57246093750001,
                            25.25473632812499
                        ],
                        [
                            62.31533203125002,
                            25.134912109374994
                        ],
                        [
                            61.74365234375,
                            25.13818359375
                        ],
                        [
                            61.56689453125,
                            25.186328125000003
                        ],
                        [
                            61.587890625,
                            25.202343749999997
                        ],
                        [
                            61.61542968750001,
                            25.2861328125
                        ],
                        [
                            61.64013671875,
                            25.584619140624994
                        ],
                        [
                            61.66865234375001,
                            25.768994140624997
                        ],
                        [
                            61.86982421875001,
                            26.242431640625
                        ],
                        [
                            62.31230468750002,
                            26.490869140624994
                        ],
                        [
                            63.168066406250006,
                            26.665576171874996
                        ],
                        [
                            63.16679687500002,
                            27.252490234374996
                        ],
                        [
                            62.75273437500002,
                            27.265625
                        ],
                        [
                            62.782324218750006,
                            27.800537109375
                        ],
                        [
                            62.76250000000002,
                            28.202050781249994
                        ],
                        [
                            61.88984375000001,
                            28.546533203124994
                        ],
                        [
                            61.623046875,
                            28.7916015625
                        ],
                        [
                            61.15214843750002,
                            29.542724609375
                        ],
                        [
                            61.0341796875,
                            29.663427734375
                        ],
                        [
                            60.843359375000006,
                            29.858691406249996
                        ],
                        [
                            61.22441406250002,
                            29.749414062499994
                        ],
                        [
                            62.37343750000002,
                            29.425390625
                        ],
                        [
                            62.4765625,
                            29.408349609374994
                        ],
                        [
                            63.56757812500001,
                            29.497998046874997
                        ],
                        [
                            64.09873046875,
                            29.391943359375
                        ],
                        [
                            64.52109375,
                            29.564501953124996
                        ],
                        [
                            65.09550781250002,
                            29.559472656249994
                        ],
                        [
                            66.17705078125002,
                            29.835595703124994
                        ],
                        [
                            66.39716796875001,
                            30.91220703125
                        ],
                        [
                            66.92431640625,
                            31.305615234374997
                        ],
                        [
                            67.28730468750001,
                            31.217822265624996
                        ],
                        [
                            67.57822265625,
                            31.506494140624994
                        ],
                        [
                            68.21396484375,
                            31.807373046875
                        ],
                        [
                            68.86894531250002,
                            31.634228515624997
                        ],
                        [
                            69.279296875,
                            31.936816406249996
                        ],
                        [
                            69.24140625000001,
                            32.433544921875
                        ],
                        [
                            69.5015625,
                            33.020068359374996
                        ],
                        [
                            69.92011718750001,
                            33.1125
                        ],
                        [
                            70.2841796875,
                            33.369042968749994
                        ],
                        [
                            69.86806640625002,
                            33.89765625
                        ],
                        [
                            71.05156250000002,
                            34.049707031249994
                        ],
                        [
                            70.96562500000002,
                            34.53037109375
                        ],
                        [
                            71.62050781250002,
                            35.183007812499994
                        ],
                        [
                            71.42753906250002,
                            35.833740234375
                        ],
                        [
                            71.18505859375,
                            36.04208984375
                        ],
                        [
                            71.62050781250002,
                            36.436474609375
                        ],
                        [
                            72.62285156250002,
                            36.82958984375
                        ],
                        [
                            73.769140625,
                            36.888476562499996
                        ],
                        [
                            74.00185546875002,
                            36.823095703125
                        ],
                        [
                            74.54140625000002,
                            37.02216796875
                        ],
                        [
                            74.8892578125,
                            36.952441406249996
                        ],
                        [
                            74.94912109375002,
                            36.968359375
                        ],
                        [
                            75.05390625000001,
                            36.987158203125
                        ],
                        [
                            75.3466796875,
                            36.913476562499994
                        ],
                        [
                            75.84023437500002,
                            36.649707031249996
                        ],
                        [
                            75.94511718750002,
                            36.017578125
                        ],
                        [
                            76.01044921875001,
                            35.996337890625
                        ],
                        [
                            76.07089843750003,
                            35.9830078125
                        ],
                        [
                            76.25166015625001,
                            35.810937499999994
                        ],
                        [
                            76.76689453124999,
                            35.66171875
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Pakistan',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -77.37421874999993,
                            8.65830078125002
                        ],
                        [
                            -77.2123046875,
                            8.033886718749997
                        ],
                        [
                            -77.35078124999995,
                            7.7058593750000455
                        ],
                        [
                            -77.74389648437503,
                            7.536962890625006
                        ],
                        [
                            -77.90117187499999,
                            7.229345703125048
                        ],
                        [
                            -78.17011718749998,
                            7.543798828124977
                        ],
                        [
                            -78.42158203124995,
                            8.060986328125011
                        ],
                        [
                            -78.11386718749998,
                            8.379589843749983
                        ],
                        [
                            -79.08637695312495,
                            8.997167968750034
                        ],
                        [
                            -79.44150390625,
                            9.00600585937498
                        ],
                        [
                            -79.75043945312498,
                            8.595507812500017
                        ],
                        [
                            -80.36870117187499,
                            8.288769531250054
                        ],
                        [
                            -80.45810546875,
                            8.077050781249994
                        ],
                        [
                            -80.07519531249997,
                            7.6670410156250455
                        ],
                        [
                            -80.4388671875,
                            7.274951171875003
                        ],
                        [
                            -80.84555664062498,
                            7.22006835937502
                        ],
                        [
                            -81.03510742187495,
                            7.711132812500054
                        ],
                        [
                            -81.50415039062503,
                            7.721191406249972
                        ],
                        [
                            -81.72763671875,
                            8.137548828124977
                        ],
                        [
                            -83.02734375,
                            8.337744140624991
                        ],
                        [
                            -82.86162109374999,
                            8.453515625000037
                        ],
                        [
                            -82.91704101562502,
                            8.740332031250034
                        ],
                        [
                            -82.739990234375,
                            8.898583984375023
                        ],
                        [
                            -82.72783203125002,
                            8.916064453125031
                        ],
                        [
                            -82.74116210937498,
                            8.951708984375017
                        ],
                        [
                            -82.93984374999994,
                            9.449169921875026
                        ],
                        [
                            -82.801025390625,
                            9.591796875000028
                        ],
                        [
                            -82.37080078124993,
                            9.428564453124991
                        ],
                        [
                            -82.24418945312499,
                            9.031494140625014
                        ],
                        [
                            -81.35478515624996,
                            8.78056640624996
                        ],
                        [
                            -80.83867187499999,
                            8.887207031250014
                        ],
                        [
                            -80.12709960937497,
                            9.20991210937504
                        ],
                        [
                            -79.57729492187497,
                            9.597851562500026
                        ],
                        [
                            -78.50434570312495,
                            9.406298828124989
                        ],
                        [
                            -78.08276367187494,
                            9.236279296874997
                        ],
                        [
                            -77.37421874999993,
                            8.65830078125002
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Panama',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -73.137353515625,
                            -6.4658203125
                        ],
                        [
                            -73.75810546874999,
                            -6.90576171875
                        ],
                        [
                            -73.79301757812499,
                            -7.135058593750003
                        ],
                        [
                            -73.749462890625,
                            -7.3353515625000085
                        ],
                        [
                            -73.964306640625,
                            -7.37890625
                        ],
                        [
                            -73.95849609375,
                            -7.506640625000003
                        ],
                        [
                            -73.98173828124999,
                            -7.535742187500006
                        ],
                        [
                            -74.00205078124999,
                            -7.5560546875000085
                        ],
                        [
                            -73.98173828124999,
                            -7.585058593750006
                        ],
                        [
                            -73.94687499999999,
                            -7.6112304687500085
                        ],
                        [
                            -73.89462890624999,
                            -7.65478515625
                        ],
                        [
                            -73.82207031249999,
                            -7.738964843750011
                        ],
                        [
                            -73.76689453124999,
                            -7.753515625000006
                        ],
                        [
                            -73.72041015625,
                            -7.782519531250003
                        ],
                        [
                            -73.7755859375,
                            -7.9364257812500085
                        ],
                        [
                            -73.54912109374999,
                            -8.345800781250006
                        ],
                        [
                            -73.43588867187499,
                            -8.427050781250003
                        ],
                        [
                            -73.39814453125,
                            -8.458984375
                        ],
                        [
                            -73.36040039062499,
                            -8.479296875000003
                        ],
                        [
                            -73.351708984375,
                            -8.51416015625
                        ],
                        [
                            -73.35673828124999,
                            -8.566992187500006
                        ],
                        [
                            -73.30244140625,
                            -8.654003906250011
                        ],
                        [
                            -73.203125,
                            -8.719335937500006
                        ],
                        [
                            -73.0705078125,
                            -8.8828125
                        ],
                        [
                            -72.9740234375,
                            -8.9931640625
                        ],
                        [
                            -72.970361328125,
                            -9.1201171875
                        ],
                        [
                            -73.08984375,
                            -9.265722656250006
                        ],
                        [
                            -73.209423828125,
                            -9.411425781250003
                        ],
                        [
                            -72.379052734375,
                            -9.510156250000009
                        ],
                        [
                            -72.181591796875,
                            -10.003710937500003
                        ],
                        [
                            -71.60800781249999,
                            -10.006054687500011
                        ],
                        [
                            -71.237939453125,
                            -9.966015625000011
                        ],
                        [
                            -71.11528320312499,
                            -9.852441406250009
                        ],
                        [
                            -70.59916992187499,
                            -9.620507812500009
                        ],
                        [
                            -70.642333984375,
                            -11.01025390625
                        ],
                        [
                            -70.22006835937499,
                            -11.047656250000003
                        ],
                        [
                            -69.57861328125,
                            -10.951757812500006
                        ],
                        [
                            -68.68525390625,
                            -12.501953125
                        ],
                        [
                            -68.97861328124999,
                            -12.880078125000011
                        ],
                        [
                            -69.07412109375,
                            -13.682812500000011
                        ],
                        [
                            -68.880322265625,
                            -14.198828125000006
                        ],
                        [
                            -69.23491210937499,
                            -14.597070312500009
                        ],
                        [
                            -69.37470703125,
                            -14.962988281250006
                        ],
                        [
                            -69.17246093749999,
                            -15.236621093750003
                        ],
                        [
                            -69.41850585937499,
                            -15.603417968750009
                        ],
                        [
                            -69.21757812499999,
                            -16.14912109375001
                        ],
                        [
                            -68.9134765625,
                            -16.26191406250001
                        ],
                        [
                            -69.020703125,
                            -16.642187500000006
                        ],
                        [
                            -69.62485351562499,
                            -17.2001953125
                        ],
                        [
                            -69.5109375,
                            -17.50605468750001
                        ],
                        [
                            -69.85209960937499,
                            -17.70380859375001
                        ],
                        [
                            -69.92636718749999,
                            -18.2060546875
                        ],
                        [
                            -70.05908203125,
                            -18.283496093750003
                        ],
                        [
                            -70.1837890625,
                            -18.3251953125
                        ],
                        [
                            -70.37749023437499,
                            -18.333593750000006
                        ],
                        [
                            -70.41826171874999,
                            -18.34560546875001
                        ],
                        [
                            -71.33696289062499,
                            -17.68251953125001
                        ],
                        [
                            -71.5322265625,
                            -17.29433593750001
                        ],
                        [
                            -72.111279296875,
                            -17.002539062500006
                        ],
                        [
                            -72.46767578125,
                            -16.708105468750006
                        ],
                        [
                            -73.824951171875,
                            -16.15283203125
                        ],
                        [
                            -74.14707031249999,
                            -15.912500000000009
                        ],
                        [
                            -75.104248046875,
                            -15.411914062500003
                        ],
                        [
                            -75.533642578125,
                            -14.899218750000003
                        ],
                        [
                            -75.93388671874999,
                            -14.633593750000003
                        ],
                        [
                            -76.289013671875,
                            -14.133105468750003
                        ],
                        [
                            -76.18393554687499,
                            -13.515234375000006
                        ],
                        [
                            -76.83212890624999,
                            -12.348730468750006
                        ],
                        [
                            -77.152734375,
                            -12.060351562500003
                        ],
                        [
                            -77.22031249999999,
                            -11.663378906250003
                        ],
                        [
                            -77.633203125,
                            -11.287792968750011
                        ],
                        [
                            -77.736083984375,
                            -10.836718750000003
                        ],
                        [
                            -78.18559570312499,
                            -10.089062500000011
                        ],
                        [
                            -78.76225585937499,
                            -8.616992187500003
                        ],
                        [
                            -79.37724609374999,
                            -7.835546875000006
                        ],
                        [
                            -79.761962890625,
                            -7.066503906250006
                        ],
                        [
                            -80.11025390625,
                            -6.649609375000011
                        ],
                        [
                            -80.81162109374999,
                            -6.2822265625
                        ],
                        [
                            -81.18051757812499,
                            -5.9423828125
                        ],
                        [
                            -80.88271484375,
                            -5.758984375000011
                        ],
                        [
                            -81.10849609374999,
                            -5.02783203125
                        ],
                        [
                            -81.33662109375,
                            -4.669531250000006
                        ],
                        [
                            -81.283203125,
                            -4.322265625
                        ],
                        [
                            -80.798583984375,
                            -3.7310546875000057
                        ],
                        [
                            -80.503662109375,
                            -3.49609375
                        ],
                        [
                            -80.324658203125,
                            -3.3878906250000114
                        ],
                        [
                            -80.27353515624999,
                            -3.424609375000003
                        ],
                        [
                            -80.26523437499999,
                            -3.492480468750003
                        ],
                        [
                            -80.24541015624999,
                            -3.5221679687500114
                        ],
                        [
                            -80.24374999999999,
                            -3.5767578125000057
                        ],
                        [
                            -80.22060546875,
                            -3.6131835937500085
                        ],
                        [
                            -80.21757812499999,
                            -3.7876953125000057
                        ],
                        [
                            -80.17924804687499,
                            -3.877734375000003
                        ],
                        [
                            -80.26689453124999,
                            -3.9488281250000057
                        ],
                        [
                            -80.30327148437499,
                            -4.005078125000011
                        ],
                        [
                            -80.43720703125,
                            -3.9786132812500057
                        ],
                        [
                            -80.49013671875,
                            -4.010058593750003
                        ],
                        [
                            -80.510009765625,
                            -4.069531250000011
                        ],
                        [
                            -80.49345703124999,
                            -4.119140625
                        ],
                        [
                            -80.4884765625,
                            -4.16552734375
                        ],
                        [
                            -80.453759765625,
                            -4.205175781250006
                        ],
                        [
                            -80.4884765625,
                            -4.393652343750006
                        ],
                        [
                            -80.293359375,
                            -4.416796875000003
                        ],
                        [
                            -80.1974609375,
                            -4.31103515625
                        ],
                        [
                            -80.13955078125,
                            -4.296093750000011
                        ],
                        [
                            -79.63852539062499,
                            -4.454882812500003
                        ],
                        [
                            -79.57768554687499,
                            -4.500585937500006
                        ],
                        [
                            -79.51616210937499,
                            -4.539160156250006
                        ],
                        [
                            -79.501904296875,
                            -4.670605468750011
                        ],
                        [
                            -79.45576171875,
                            -4.766210937500006
                        ],
                        [
                            -79.3994140625,
                            -4.840039062500011
                        ],
                        [
                            -79.33095703125,
                            -4.927832031250006
                        ],
                        [
                            -79.26811523437499,
                            -4.957617187500006
                        ],
                        [
                            -79.186669921875,
                            -4.958203125000011
                        ],
                        [
                            -79.07626953124999,
                            -4.9906250000000085
                        ],
                        [
                            -79.03330078124999,
                            -4.9691406250000085
                        ],
                        [
                            -78.995263671875,
                            -4.908007812500003
                        ],
                        [
                            -78.97539062499999,
                            -4.873242187500011
                        ],
                        [
                            -78.8615234375,
                            -4.6650390625
                        ],
                        [
                            -78.68603515625,
                            -4.5624023437500085
                        ],
                        [
                            -78.64799804687499,
                            -4.248144531250006
                        ],
                        [
                            -78.240380859375,
                            -3.4725585937500085
                        ],
                        [
                            -77.860595703125,
                            -2.9816406250000114
                        ],
                        [
                            -76.6791015625,
                            -2.5625976562500057
                        ],
                        [
                            -76.089794921875,
                            -2.133105468750003
                        ],
                        [
                            -75.64165039062499,
                            -1.6073242187500085
                        ],
                        [
                            -75.570556640625,
                            -1.53125
                        ],
                        [
                            -75.42041015625,
                            -0.9622070312500028
                        ],
                        [
                            -75.24960937499999,
                            -0.9518554687500114
                        ],
                        [
                            -75.28359375,
                            -0.7071289062500057
                        ],
                        [
                            -75.2787109375,
                            -0.6539062500000057
                        ],
                        [
                            -75.25937499999999,
                            -0.5901367187500028
                        ],
                        [
                            -75.26323242187499,
                            -0.5553710937500114
                        ],
                        [
                            -75.32524414062499,
                            -0.5065429687500114
                        ],
                        [
                            -75.42470703125,
                            -0.40888671875001137
                        ],
                        [
                            -75.49106445312499,
                            -0.24833984375000284
                        ],
                        [
                            -75.63203125,
                            -0.15761718750000853
                        ],
                        [
                            -75.4759765625,
                            -0.15712890625000853
                        ],
                        [
                            -75.340478515625,
                            -0.14218750000000568
                        ],
                        [
                            -75.28447265624999,
                            -0.10654296875000568
                        ],
                        [
                            -75.224609375,
                            -0.041748046875
                        ],
                        [
                            -75.18408203125,
                            -0.041748046875
                        ],
                        [
                            -75.13837890625,
                            -0.05048828125001137
                        ],
                        [
                            -74.8017578125,
                            -0.20009765625000853
                        ],
                        [
                            -74.41787109375,
                            -0.5806640625000057
                        ],
                        [
                            -74.334423828125,
                            -0.8508789062500028
                        ],
                        [
                            -74.28388671875,
                            -0.9278320312500057
                        ],
                        [
                            -74.24638671874999,
                            -0.9706054687500085
                        ],
                        [
                            -74.05439453125,
                            -1.0286132812500028
                        ],
                        [
                            -73.98681640625,
                            -1.09814453125
                        ],
                        [
                            -73.926953125,
                            -1.1251953125000114
                        ],
                        [
                            -73.86318359375,
                            -1.1966796875000085
                        ],
                        [
                            -73.664306640625,
                            -1.2488281250000028
                        ],
                        [
                            -73.4962890625,
                            -1.6930664062500114
                        ],
                        [
                            -73.19697265625,
                            -1.8302734375000114
                        ],
                        [
                            -73.1544921875,
                            -2.2782226562500085
                        ],
                        [
                            -72.887158203125,
                            -2.408496093750003
                        ],
                        [
                            -72.39560546874999,
                            -2.4289062500000114
                        ],
                        [
                            -72.300732421875,
                            -2.4092773437500057
                        ],
                        [
                            -72.21845703125,
                            -2.4004882812500057
                        ],
                        [
                            -72.13681640624999,
                            -2.380664062500003
                        ],
                        [
                            -71.98427734375,
                            -2.3265625000000085
                        ],
                        [
                            -71.93247070312499,
                            -2.2886718750000057
                        ],
                        [
                            -71.86728515624999,
                            -2.2277343750000114
                        ],
                        [
                            -71.802734375,
                            -2.166308593750003
                        ],
                        [
                            -71.75253906249999,
                            -2.1527343750000085
                        ],
                        [
                            -71.55947265625,
                            -2.2242187500000057
                        ],
                        [
                            -71.39697265625,
                            -2.3340820312500057
                        ],
                        [
                            -70.91455078125,
                            -2.218554687500003
                        ],
                        [
                            -70.095849609375,
                            -2.658203125
                        ],
                        [
                            -70.07402343749999,
                            -2.7501953125000114
                        ],
                        [
                            -70.735107421875,
                            -3.781542968750003
                        ],
                        [
                            -70.5296875,
                            -3.8664062500000114
                        ],
                        [
                            -70.48583984375,
                            -3.8693359375000114
                        ],
                        [
                            -70.42109375,
                            -3.849609375
                        ],
                        [
                            -70.37919921874999,
                            -3.8187500000000085
                        ],
                        [
                            -70.339501953125,
                            -3.8143554687500085
                        ],
                        [
                            -70.29843749999999,
                            -3.84423828125
                        ],
                        [
                            -70.24028320312499,
                            -3.8827148437500085
                        ],
                        [
                            -70.16752929687499,
                            -4.0501953125000085
                        ],
                        [
                            -70.40463867187499,
                            -4.150097656250011
                        ],
                        [
                            -70.5306640625,
                            -4.167578125000006
                        ],
                        [
                            -70.72158203125,
                            -4.158886718750011
                        ],
                        [
                            -70.79951171875,
                            -4.17333984375
                        ],
                        [
                            -70.97368164062499,
                            -4.3504882812500085
                        ],
                        [
                            -71.8447265625,
                            -4.50439453125
                        ],
                        [
                            -72.256787109375,
                            -4.7489257812500085
                        ],
                        [
                            -72.35283203124999,
                            -4.7860351562500085
                        ],
                        [
                            -72.468994140625,
                            -4.9012695312500085
                        ],
                        [
                            -72.608349609375,
                            -5.009570312500003
                        ],
                        [
                            -72.69873046875,
                            -5.067187500000003
                        ],
                        [
                            -72.83193359375,
                            -5.09375
                        ],
                        [
                            -72.88706054687499,
                            -5.122753906250011
                        ],
                        [
                            -72.9798828125,
                            -5.634863281250006
                        ],
                        [
                            -73.16289062499999,
                            -5.933398437500003
                        ],
                        [
                            -73.209375,
                            -6.0287109375000085
                        ],
                        [
                            -73.235546875,
                            -6.098437500000003
                        ],
                        [
                            -73.137353515625,
                            -6.4658203125
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Peru',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                126.00595703125006,
                                9.320947265625009
                            ],
                            [
                                126.19335937499997,
                                9.276708984374963
                            ],
                            [
                                126.31953125000004,
                                8.844726562499986
                            ],
                            [
                                126.43535156250002,
                                7.832812499999974
                            ],
                            [
                                126.58154296875003,
                                7.247753906249969
                            ],
                            [
                                126.08007812500003,
                                6.733349609374983
                            ],
                            [
                                125.82441406250004,
                                7.333300781249989
                            ],
                            [
                                125.40097656250012,
                                6.7957519531249915
                            ],
                            [
                                125.58847656250012,
                                6.465771484374997
                            ],
                            [
                                125.66796874999997,
                                5.97866210937498
                            ],
                            [
                                125.45585937500002,
                                5.664257812500054
                            ],
                            [
                                124.63632812500012,
                                5.998193359375051
                            ],
                            [
                                124.07812500000003,
                                6.404443359375037
                            ],
                            [
                                123.98525390625,
                                6.993701171875003
                            ],
                            [
                                124.20664062500006,
                                7.396435546874983
                            ],
                            [
                                123.96845703125004,
                                7.6646484374999915
                            ],
                            [
                                123.49306640625,
                                7.80791015624996
                            ],
                            [
                                123.39091796875007,
                                7.407519531250017
                            ],
                            [
                                122.49794921875005,
                                7.672753906250051
                            ],
                            [
                                122.17617187500005,
                                7.004199218750003
                            ],
                            [
                                122.04716796875002,
                                7.363574218750031
                            ],
                            [
                                122.13183593749997,
                                7.81049804687504
                            ],
                            [
                                122.33710937500004,
                                8.028417968750034
                            ],
                            [
                                122.91113281250003,
                                8.156445312499997
                            ],
                            [
                                123.01757812500003,
                                8.398339843749994
                            ],
                            [
                                123.43457031249997,
                                8.70332031250004
                            ],
                            [
                                124.22578125000004,
                                8.271386718749966
                            ],
                            [
                                124.45126953125006,
                                8.606347656249994
                            ],
                            [
                                124.7311523437501,
                                8.562988281250043
                            ],
                            [
                                124.86894531250002,
                                8.972265625000034
                            ],
                            [
                                125.5333984375001,
                                9.140917968750017
                            ],
                            [
                                125.52089843750005,
                                9.75913085937502
                            ],
                            [
                                126.00595703125006,
                                9.320947265625009
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                124.59384765625006,
                                9.787207031249963
                            ],
                            [
                                124.35986328125003,
                                9.630224609375048
                            ],
                            [
                                123.93564453125012,
                                9.623974609375011
                            ],
                            [
                                123.81718750000002,
                                9.817382812499986
                            ],
                            [
                                124.17285156250003,
                                10.135205078124983
                            ],
                            [
                                124.57714843749997,
                                10.026708984374991
                            ],
                            [
                                124.59384765625006,
                                9.787207031249963
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                123.13085937500003,
                                9.064111328124994
                            ],
                            [
                                122.56249999999997,
                                9.482812500000037
                            ],
                            [
                                122.47148437500007,
                                9.961523437500034
                            ],
                            [
                                122.85556640625006,
                                10.0869140625
                            ],
                            [
                                122.81699218750012,
                                10.503808593750023
                            ],
                            [
                                122.98330078125,
                                10.886621093750037
                            ],
                            [
                                123.25664062500007,
                                10.99394531249996
                            ],
                            [
                                123.5675781250001,
                                10.780761718750057
                            ],
                            [
                                123.16201171875,
                                9.864257812500028
                            ],
                            [
                                123.30839843750002,
                                9.356982421874974
                            ],
                            [
                                123.13085937500003,
                                9.064111328124994
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                123.37031250000004,
                                9.449609375000023
                            ],
                            [
                                123.38623046874997,
                                9.967089843750017
                            ],
                            [
                                123.71142578124997,
                                10.473681640625017
                            ],
                            [
                                123.95214843750003,
                                10.316601562499997
                            ],
                            [
                                123.64335937500007,
                                10.020214843750011
                            ],
                            [
                                123.37031250000004,
                                9.449609375000023
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                117.31113281250012,
                                8.439599609375051
                            ],
                            [
                                117.34990234375002,
                                8.713574218749997
                            ],
                            [
                                117.88476562499997,
                                9.240673828124997
                            ],
                            [
                                118.11484375000006,
                                9.346679687500014
                            ],
                            [
                                119.52666015625002,
                                10.953173828125003
                            ],
                            [
                                119.68437500000007,
                                10.551708984375011
                            ],
                            [
                                119.19150390625012,
                                10.061083984374989
                            ],
                            [
                                118.78212890625005,
                                9.91611328125002
                            ],
                            [
                                118.4349609375,
                                9.256005859375009
                            ],
                            [
                                117.98955078125002,
                                8.877099609375009
                            ],
                            [
                                117.31113281250012,
                                8.439599609375051
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                124.574609375,
                                11.343066406250031
                            ],
                            [
                                124.92998046875002,
                                11.372851562499974
                            ],
                            [
                                125.01318359374997,
                                10.785693359374989
                            ],
                            [
                                125.25332031250005,
                                10.26381835937498
                            ],
                            [
                                125.02656250000004,
                                10.033105468749966
                            ],
                            [
                                124.78076171874997,
                                10.16806640625002
                            ],
                            [
                                124.78671875000012,
                                10.781396484375009
                            ],
                            [
                                124.44550781250004,
                                10.923583984375014
                            ],
                            [
                                124.574609375,
                                11.343066406250031
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                122.49619140625006,
                                11.615087890625034
                            ],
                            [
                                123.15830078125012,
                                11.535546874999994
                            ],
                            [
                                123.01650390625,
                                11.116503906249974
                            ],
                            [
                                122.76992187500005,
                                10.823828125000034
                            ],
                            [
                                122.19765625,
                                10.622900390625048
                            ],
                            [
                                121.96435546874997,
                                10.871679687500006
                            ],
                            [
                                122.10351562499997,
                                11.64291992187502
                            ],
                            [
                                122.49619140625006,
                                11.615087890625034
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                125.23955078125002,
                                12.527880859375003
                            ],
                            [
                                125.53564453125003,
                                12.191406250000028
                            ],
                            [
                                125.45654296874997,
                                11.952539062500037
                            ],
                            [
                                125.62812500000004,
                                11.132031250000026
                            ],
                            [
                                125.23339843749997,
                                11.145068359375017
                            ],
                            [
                                124.88427734375003,
                                11.77548828125002
                            ],
                            [
                                124.38486328125006,
                                12.243994140624991
                            ],
                            [
                                124.29472656250007,
                                12.569335937500014
                            ],
                            [
                                125.23955078125002,
                                12.527880859375003
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                120.70439453125002,
                                13.479492187499986
                            ],
                            [
                                121.20273437500006,
                                13.432324218749969
                            ],
                            [
                                121.52275390625007,
                                13.131201171874991
                            ],
                            [
                                121.54062500000006,
                                12.63818359375
                            ],
                            [
                                121.23671875000005,
                                12.218798828125003
                            ],
                            [
                                120.92216796875002,
                                12.51162109374998
                            ],
                            [
                                120.76367187499997,
                                12.969824218749977
                            ],
                            [
                                120.50830078124997,
                                13.260058593750045
                            ],
                            [
                                120.70439453125002,
                                13.479492187499986
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                121.10156249999997,
                                18.615283203125017
                            ],
                            [
                                121.84560546875,
                                18.29541015625003
                            ],
                            [
                                122.29980468749997,
                                18.402783203124983
                            ],
                            [
                                122.15097656250006,
                                17.756494140625023
                            ],
                            [
                                122.23681640624997,
                                17.434863281250045
                            ],
                            [
                                122.51914062500012,
                                17.124853515625034
                            ],
                            [
                                122.13515625000005,
                                16.18481445312503
                            ],
                            [
                                121.59531250000012,
                                15.933251953125023
                            ],
                            [
                                121.39228515625004,
                                15.324414062499969
                            ],
                            [
                                121.69541015625006,
                                14.7373046875
                            ],
                            [
                                121.80048828125004,
                                14.113867187499991
                            ],
                            [
                                122.49082031250012,
                                14.32236328125002
                            ],
                            [
                                122.85605468750006,
                                14.250781250000003
                            ],
                            [
                                123.23144531249997,
                                13.747363281250017
                            ],
                            [
                                123.43232421875004,
                                13.966259765625026
                            ],
                            [
                                123.72597656250005,
                                13.884326171875031
                            ],
                            [
                                123.6081054687501,
                                13.528076171875028
                            ],
                            [
                                124.14277343750004,
                                13.035791015625009
                            ],
                            [
                                124.0597656250001,
                                12.567089843749997
                            ],
                            [
                                123.31093750000005,
                                13.044091796875009
                            ],
                            [
                                123.16328125000004,
                                13.44174804687502
                            ],
                            [
                                122.59521484374997,
                                13.90761718749998
                            ],
                            [
                                122.40693359375004,
                                13.49277343750002
                            ],
                            [
                                121.77792968750006,
                                13.93764648437498
                            ],
                            [
                                121.20351562500005,
                                13.640283203124966
                            ],
                            [
                                120.61679687500006,
                                14.188037109375003
                            ],
                            [
                                120.9220703125001,
                                14.493115234374983
                            ],
                            [
                                120.8880859375,
                                14.715771484375054
                            ],
                            [
                                120.08212890625012,
                                14.851074218749986
                            ],
                            [
                                119.83076171875004,
                                16.326562500000023
                            ],
                            [
                                120.15976562500012,
                                16.047656250000045
                            ],
                            [
                                120.38925781250006,
                                16.221630859374955
                            ],
                            [
                                120.30527343750006,
                                16.52924804687501
                            ],
                            [
                                120.40888671875004,
                                16.955615234375017
                            ],
                            [
                                120.35839843749997,
                                17.63818359375
                            ],
                            [
                                120.59970703125012,
                                18.507861328125074
                            ],
                            [
                                121.10156249999997,
                                18.615283203125017
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Philippines',
                'childNum': 10
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                155.95761718750006,
                                -6.686816406249989
                            ],
                            [
                                155.71933593750012,
                                -6.862792968749957
                            ],
                            [
                                155.34404296875007,
                                -6.721679687499986
                            ],
                            [
                                155.20214843750003,
                                -6.3076171875
                            ],
                            [
                                154.78193359375004,
                                -5.970703125000028
                            ],
                            [
                                154.74111328125005,
                                -5.545312499999952
                            ],
                            [
                                154.99707031249997,
                                -5.539941406250023
                            ],
                            [
                                155.46699218750004,
                                -6.145117187500034
                            ],
                            [
                                155.82255859375002,
                                -6.38046875000002
                            ],
                            [
                                155.95761718750006,
                                -6.686816406249989
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                151.91562500000006,
                                -4.296777343749966
                            ],
                            [
                                152.40566406250005,
                                -4.340722656249952
                            ],
                            [
                                152.40000000000006,
                                -4.731250000000017
                            ],
                            [
                                152.01328125000006,
                                -5.003808593750037
                            ],
                            [
                                152.14296875,
                                -5.357031249999963
                            ],
                            [
                                151.86542968750004,
                                -5.564843750000023
                            ],
                            [
                                151.51513671874997,
                                -5.552343749999963
                            ],
                            [
                                151.22929687500002,
                                -5.919921874999986
                            ],
                            [
                                150.42832031250012,
                                -6.276171874999989
                            ],
                            [
                                149.65253906250004,
                                -6.290429687499966
                            ],
                            [
                                149.38232421874997,
                                -6.078124999999972
                            ],
                            [
                                149.12656250000012,
                                -6.127636718750026
                            ],
                            [
                                148.40117187500002,
                                -5.765039062499952
                            ],
                            [
                                148.34472656249997,
                                -5.544921874999972
                            ],
                            [
                                148.9992187500001,
                                -5.484570312500011
                            ],
                            [
                                149.24531250000004,
                                -5.573046875000017
                            ],
                            [
                                150.29873046875,
                                -5.535644531250028
                            ],
                            [
                                150.95292968750002,
                                -5.423730468749966
                            ],
                            [
                                151.32656250000005,
                                -4.96035156249998
                            ],
                            [
                                151.67119140625007,
                                -4.88330078125
                            ],
                            [
                                151.55195312500004,
                                -4.345507812499946
                            ],
                            [
                                151.91562500000006,
                                -4.296777343749966
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                140.97617187500012,
                                -9.118750000000006
                            ],
                            [
                                140.97519531250006,
                                -6.90537109375002
                            ],
                            [
                                140.97441406250002,
                                -4.836328125000023
                            ],
                            [
                                140.97353515625,
                                -2.8034179687500256
                            ],
                            [
                                140.97343750000007,
                                -2.6097656250000085
                            ],
                            [
                                141.18564453125006,
                                -2.6278320312499517
                            ],
                            [
                                142.21152343750006,
                                -3.0834960937499716
                            ],
                            [
                                142.90517578125,
                                -3.32070312499998
                            ],
                            [
                                143.50898437500004,
                                -3.431152343750014
                            ],
                            [
                                144.01582031250004,
                                -3.783593749999966
                            ],
                            [
                                144.4777343750001,
                                -3.82529296875002
                            ],
                            [
                                145.08779296875,
                                -4.349121093749972
                            ],
                            [
                                145.33457031250012,
                                -4.385253906249972
                            ],
                            [
                                145.7669921875,
                                -4.823046874999989
                            ],
                            [
                                145.85283203125002,
                                -5.471289062499977
                            ],
                            [
                                146.40341796875006,
                                -5.6166015625000085
                            ],
                            [
                                147.03427734375006,
                                -5.919238281250017
                            ],
                            [
                                147.37666015625004,
                                -5.950781249999963
                            ],
                            [
                                147.80205078125002,
                                -6.31523437499996
                            ],
                            [
                                147.84550781250007,
                                -6.662402343749989
                            ],
                            [
                                147.11914062499997,
                                -6.721679687499986
                            ],
                            [
                                146.96074218750002,
                                -6.9288085937499915
                            ],
                            [
                                147.19003906250012,
                                -7.3781249999999545
                            ],
                            [
                                147.72431640625004,
                                -7.876269531250017
                            ],
                            [
                                148.12675781250007,
                                -8.103613281249963
                            ],
                            [
                                148.24687500000002,
                                -8.554296875000034
                            ],
                            [
                                148.58310546875006,
                                -9.051757812499957
                            ],
                            [
                                149.24765625000006,
                                -9.070996093749969
                            ],
                            [
                                149.20302734375005,
                                -9.406835937499991
                            ],
                            [
                                149.76123046874997,
                                -9.805859375000011
                            ],
                            [
                                149.87441406250005,
                                -10.012988281250031
                            ],
                            [
                                150.66699218749997,
                                -10.257128906250031
                            ],
                            [
                                150.48242187500003,
                                -10.63691406250004
                            ],
                            [
                                150.0167968750001,
                                -10.577148437500028
                            ],
                            [
                                149.75410156250004,
                                -10.353027343750028
                            ],
                            [
                                147.76865234375012,
                                -10.070117187500031
                            ],
                            [
                                146.69658203125002,
                                -9.025390625
                            ],
                            [
                                146.03320312499997,
                                -8.076367187500011
                            ],
                            [
                                144.97382812500004,
                                -7.8021484375000085
                            ],
                            [
                                144.50986328125006,
                                -7.567382812499972
                            ],
                            [
                                143.61376953125003,
                                -8.200390624999969
                            ],
                            [
                                143.0949218750001,
                                -8.311230468749983
                            ],
                            [
                                143.36621093750003,
                                -8.961035156250034
                            ],
                            [
                                142.6471679687501,
                                -9.327832031249969
                            ],
                            [
                                142.22958984375012,
                                -9.169921874999957
                            ],
                            [
                                141.13320312500005,
                                -9.221289062500034
                            ],
                            [
                                140.97617187500012,
                                -9.118750000000006
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                152.96582031249997,
                                -4.756347656249986
                            ],
                            [
                                152.68066406250003,
                                -4.498437500000023
                            ],
                            [
                                152.59843750000007,
                                -3.9948242187500114
                            ],
                            [
                                153.1242187500001,
                                -4.25234374999998
                            ],
                            [
                                152.96582031249997,
                                -4.756347656249986
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Papua New Guinea',
                'childNum': 4
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            23.484667968750017,
                            53.939794921875
                        ],
                        [
                            23.887109375000023,
                            53.0275390625
                        ],
                        [
                            23.8447265625,
                            52.664208984375
                        ],
                        [
                            23.19697265625001,
                            52.25693359375
                        ],
                        [
                            23.652441406250006,
                            52.040380859375
                        ],
                        [
                            23.605273437500017,
                            51.517919921875
                        ],
                        [
                            23.664453125000023,
                            51.31005859375
                        ],
                        [
                            24.105761718750017,
                            50.844970703125
                        ],
                        [
                            23.711718750000017,
                            50.377343749999994
                        ],
                        [
                            22.6494140625,
                            49.539013671875
                        ],
                        [
                            22.538671875000006,
                            49.072705078125
                        ],
                        [
                            21.6396484375,
                            49.411962890625
                        ],
                        [
                            20.616113281250023,
                            49.39169921875
                        ],
                        [
                            19.80224609375,
                            49.192333984375
                        ],
                        [
                            19.44160156250001,
                            49.597705078124996
                        ],
                        [
                            18.83222656250001,
                            49.510791015624996
                        ],
                        [
                            18.562402343750023,
                            49.879345703125
                        ],
                        [
                            17.874804687500017,
                            49.972265625
                        ],
                        [
                            17.151953125000006,
                            50.378320312499994
                        ],
                        [
                            16.679101562500023,
                            50.097460937499996
                        ],
                        [
                            16.007226562500023,
                            50.611621093749996
                        ],
                        [
                            14.809375000000017,
                            50.858984375
                        ],
                        [
                            14.9638671875,
                            51.095117187499994
                        ],
                        [
                            14.601660156250006,
                            51.832373046875
                        ],
                        [
                            14.752539062500006,
                            52.081835937499996
                        ],
                        [
                            14.70458984375,
                            52.110205078125
                        ],
                        [
                            14.6923828125,
                            52.150048828125
                        ],
                        [
                            14.705371093750017,
                            52.207470703125
                        ],
                        [
                            14.679882812500011,
                            52.25
                        ],
                        [
                            14.615625000000023,
                            52.277636718749996
                        ],
                        [
                            14.573925781250011,
                            52.31416015625
                        ],
                        [
                            14.554589843750023,
                            52.359667968749996
                        ],
                        [
                            14.569726562500023,
                            52.431103515625
                        ],
                        [
                            14.619433593750017,
                            52.528515625
                        ],
                        [
                            14.514062500000023,
                            52.64560546875
                        ],
                        [
                            14.253710937500017,
                            52.782519531249996
                        ],
                        [
                            14.128613281250011,
                            52.878222656249996
                        ],
                        [
                            14.138867187500011,
                            52.932861328125
                        ],
                        [
                            14.193652343750017,
                            52.98232421875
                        ],
                        [
                            14.293164062500011,
                            53.0267578125
                        ],
                        [
                            14.368554687500023,
                            53.10556640625
                        ],
                        [
                            14.410937500000017,
                            53.199023437499996
                        ],
                        [
                            14.412304687500011,
                            53.216748046875
                        ],
                        [
                            14.41455078125,
                            53.283496093749996
                        ],
                        [
                            14.715722656250023,
                            54.018310546875
                        ],
                        [
                            16.186328125000017,
                            54.290380859375
                        ],
                        [
                            16.55976562500001,
                            54.55380859375
                        ],
                        [
                            17.26191406250001,
                            54.729541015624996
                        ],
                        [
                            18.32343750000001,
                            54.838183593749996
                        ],
                        [
                            18.66962890625001,
                            54.430908203125
                        ],
                        [
                            18.97626953125001,
                            54.348925781249996
                        ],
                        [
                            19.407128906250023,
                            54.386083984375
                        ],
                        [
                            19.560156250000006,
                            54.434619140624996
                        ],
                        [
                            19.604394531250023,
                            54.4591796875
                        ],
                        [
                            19.64423828125001,
                            54.447070312499996
                        ],
                        [
                            19.92431640625,
                            54.433984375
                        ],
                        [
                            20.20820312500001,
                            54.420751953125
                        ],
                        [
                            22.16845703125,
                            54.35986328125
                        ],
                        [
                            23.015527343750023,
                            54.34833984375
                        ],
                        [
                            23.45361328125,
                            54.14345703125
                        ],
                        [
                            23.484667968750017,
                            53.939794921875
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Poland',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -66.12939453125003,
                            18.444921875000034
                        ],
                        [
                            -65.62880859375,
                            18.381396484375045
                        ],
                        [
                            -65.97080078124995,
                            17.974365234375
                        ],
                        [
                            -67.19687499999998,
                            17.994189453125045
                        ],
                        [
                            -67.15864257812501,
                            18.499218749999983
                        ],
                        [
                            -66.12939453125003,
                            18.444921875000034
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'United States of America',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            130.52695312500012,
                            42.535400390625
                        ],
                        [
                            130.68730468750007,
                            42.30253906249999
                        ],
                        [
                            130.45751953124997,
                            42.30170898437501
                        ],
                        [
                            129.75634765624997,
                            41.712255859375006
                        ],
                        [
                            129.70869140625004,
                            40.857324218749994
                        ],
                        [
                            128.30449218750002,
                            40.03593749999999
                        ],
                        [
                            127.56816406250002,
                            39.78198242187503
                        ],
                        [
                            127.39453125000003,
                            39.207910156249966
                        ],
                        [
                            127.78613281250003,
                            39.084130859374966
                        ],
                        [
                            128.37460937500012,
                            38.623437499999994
                        ],
                        [
                            128.03896484375,
                            38.30854492187498
                        ],
                        [
                            127.16953125000006,
                            38.304541015625034
                        ],
                        [
                            126.63388671875012,
                            37.78183593750006
                        ],
                        [
                            125.94169921875007,
                            37.87368164062502
                        ],
                        [
                            125.44931640625012,
                            37.73022460937503
                        ],
                        [
                            125.19316406250007,
                            38.03779296874998
                        ],
                        [
                            124.69091796874997,
                            38.12919921875002
                        ],
                        [
                            125.06738281250003,
                            38.556738281250006
                        ],
                        [
                            125.40966796875003,
                            39.28837890625002
                        ],
                        [
                            125.36083984375003,
                            39.52661132812497
                        ],
                        [
                            124.55742187500002,
                            39.79057617187502
                        ],
                        [
                            124.36210937500002,
                            40.004052734374994
                        ],
                        [
                            124.8893554687501,
                            40.459814453125006
                        ],
                        [
                            125.98906250000002,
                            40.904638671875034
                        ],
                        [
                            126.9035156250001,
                            41.78105468749999
                        ],
                        [
                            127.17968750000003,
                            41.531347656250006
                        ],
                        [
                            128.11123046875,
                            41.389257812500006
                        ],
                        [
                            128.29091796875,
                            41.56279296875002
                        ],
                        [
                            128.04521484375007,
                            41.98750000000004
                        ],
                        [
                            128.92343750000006,
                            42.038232421874966
                        ],
                        [
                            129.3136718750001,
                            42.41357421874997
                        ],
                        [
                            129.71972656249997,
                            42.47500000000005
                        ],
                        [
                            129.89824218750002,
                            42.998144531250034
                        ],
                        [
                            130.24033203125006,
                            42.891796874999955
                        ],
                        [
                            130.52695312500012,
                            42.535400390625
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'North Korea',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -7.4061523437499375,
                            37.17944335937497
                        ],
                        [
                            -7.834130859374994,
                            37.005712890625034
                        ],
                        [
                            -8.597656249999943,
                            37.12133789062506
                        ],
                        [
                            -8.814160156249983,
                            37.43081054687502
                        ],
                        [
                            -8.914794921874972,
                            38.512109374999966
                        ],
                        [
                            -9.474121093749972,
                            38.73085937500002
                        ],
                        [
                            -9.374755859374972,
                            39.338281249999966
                        ],
                        [
                            -9.148291015624949,
                            39.542578125000034
                        ],
                        [
                            -8.886621093750023,
                            40.179443359375
                        ],
                        [
                            -8.655566406249932,
                            41.02949218749998
                        ],
                        [
                            -8.777148437500017,
                            41.941064453124994
                        ],
                        [
                            -8.213085937499926,
                            42.133691406249966
                        ],
                        [
                            -7.920849609374983,
                            41.883642578125006
                        ],
                        [
                            -7.40361328124996,
                            41.833691406249955
                        ],
                        [
                            -6.61826171874992,
                            41.9423828125
                        ],
                        [
                            -6.212499999999949,
                            41.53203124999996
                        ],
                        [
                            -6.928466796874972,
                            41.009130859375006
                        ],
                        [
                            -6.81015624999992,
                            40.343115234375034
                        ],
                        [
                            -7.036718750000006,
                            39.713964843750034
                        ],
                        [
                            -7.44511718749996,
                            39.53618164062496
                        ],
                        [
                            -7.0460449218749375,
                            38.907031250000045
                        ],
                        [
                            -7.343017578124943,
                            38.45742187500002
                        ],
                        [
                            -7.185449218749994,
                            38.00634765625006
                        ],
                        [
                            -7.503515624999977,
                            37.58549804687502
                        ],
                        [
                            -7.4061523437499375,
                            37.17944335937497
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Portugal',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -58.15976562499999,
                            -20.164648437500006
                        ],
                        [
                            -57.830224609374994,
                            -20.99794921875001
                        ],
                        [
                            -57.94267578124999,
                            -21.79833984375
                        ],
                        [
                            -57.98569335937499,
                            -22.04638671875
                        ],
                        [
                            -57.95590820312499,
                            -22.109179687500003
                        ],
                        [
                            -57.8203125,
                            -22.14228515625001
                        ],
                        [
                            -57.764062499999994,
                            -22.109179687500003
                        ],
                        [
                            -57.721093749999994,
                            -22.099218750000006
                        ],
                        [
                            -57.238232421875,
                            -22.19521484375001
                        ],
                        [
                            -56.77519531249999,
                            -22.261328125000006
                        ],
                        [
                            -56.523828124999994,
                            -22.1025390625
                        ],
                        [
                            -56.44780273437499,
                            -22.076171875
                        ],
                        [
                            -56.39487304687499,
                            -22.09267578125001
                        ],
                        [
                            -56.189843749999994,
                            -22.28115234375001
                        ],
                        [
                            -55.84916992187499,
                            -22.3076171875
                        ],
                        [
                            -55.75327148437499,
                            -22.41015625
                        ],
                        [
                            -55.74663085937499,
                            -22.5126953125
                        ],
                        [
                            -55.70366210937499,
                            -22.592089843750003
                        ],
                        [
                            -55.647412109375,
                            -22.621875000000003
                        ],
                        [
                            -55.61767578125,
                            -22.671484375000006
                        ],
                        [
                            -55.627587890624994,
                            -22.74091796875001
                        ],
                        [
                            -55.654052734375,
                            -22.810351562500003
                        ],
                        [
                            -55.458886718749994,
                            -23.68671875000001
                        ],
                        [
                            -55.4423828125,
                            -23.792578125000006
                        ],
                        [
                            -55.4423828125,
                            -23.865332031250006
                        ],
                        [
                            -55.415917968749994,
                            -23.95136718750001
                        ],
                        [
                            -55.36630859374999,
                            -23.991015625000003
                        ],
                        [
                            -55.28691406249999,
                            -24.00429687500001
                        ],
                        [
                            -55.1943359375,
                            -24.01748046875001
                        ],
                        [
                            -55.08188476562499,
                            -23.997656250000006
                        ],
                        [
                            -54.982666015625,
                            -23.97451171875001
                        ],
                        [
                            -54.62548828125,
                            -23.8125
                        ],
                        [
                            -54.44023437499999,
                            -23.90175781250001
                        ],
                        [
                            -54.37080078125,
                            -23.97119140625
                        ],
                        [
                            -54.24179687499999,
                            -24.04726562500001
                        ],
                        [
                            -54.281005859375,
                            -24.30605468750001
                        ],
                        [
                            -54.47314453125,
                            -25.22021484375
                        ],
                        [
                            -54.610546875,
                            -25.432714843750006
                        ],
                        [
                            -54.615869140624994,
                            -25.57607421875001
                        ],
                        [
                            -54.63193359374999,
                            -26.00576171875001
                        ],
                        [
                            -54.677734375,
                            -26.30878906250001
                        ],
                        [
                            -54.934472656249994,
                            -26.70253906250001
                        ],
                        [
                            -55.426660156249994,
                            -27.00927734375
                        ],
                        [
                            -55.59726562499999,
                            -27.207617187500006
                        ],
                        [
                            -55.63291015624999,
                            -27.35712890625001
                        ],
                        [
                            -55.789990234375,
                            -27.41640625000001
                        ],
                        [
                            -56.1640625,
                            -27.32148437500001
                        ],
                        [
                            -56.437158203124994,
                            -27.553808593750006
                        ],
                        [
                            -57.11181640625,
                            -27.47011718750001
                        ],
                        [
                            -58.16826171874999,
                            -27.2734375
                        ],
                        [
                            -58.60483398437499,
                            -27.31435546875001
                        ],
                        [
                            -58.641748046874994,
                            -27.196093750000003
                        ],
                        [
                            -58.32255859374999,
                            -26.85761718750001
                        ],
                        [
                            -58.33466796875,
                            -26.82490234375001
                        ],
                        [
                            -58.31767578124999,
                            -26.7958984375
                        ],
                        [
                            -58.191308593749994,
                            -26.629980468750006
                        ],
                        [
                            -57.943115234375,
                            -26.05292968750001
                        ],
                        [
                            -57.587158203125,
                            -25.405078125000003
                        ],
                        [
                            -57.821679687499994,
                            -25.13642578125001
                        ],
                        [
                            -58.365380859374994,
                            -24.959277343750003
                        ],
                        [
                            -58.422802734375,
                            -24.894140625000006
                        ],
                        [
                            -58.51962890624999,
                            -24.842871093750006
                        ],
                        [
                            -59.372949218749994,
                            -24.453906250000003
                        ],
                        [
                            -59.60859375,
                            -24.26679687500001
                        ],
                        [
                            -59.892480468749994,
                            -24.093554687500003
                        ],
                        [
                            -60.83984375,
                            -23.85810546875001
                        ],
                        [
                            -61.084716796875,
                            -23.65644531250001
                        ],
                        [
                            -61.79853515625,
                            -23.18203125000001
                        ],
                        [
                            -62.372509765625,
                            -22.43916015625001
                        ],
                        [
                            -62.54155273437499,
                            -22.349609375
                        ],
                        [
                            -62.6259765625,
                            -22.29042968750001
                        ],
                        [
                            -62.62568359375,
                            -22.261523437500003
                        ],
                        [
                            -62.65097656249999,
                            -22.233691406250003
                        ],
                        [
                            -62.27666015624999,
                            -21.066015625000006
                        ],
                        [
                            -62.276318359375,
                            -20.5625
                        ],
                        [
                            -61.916943359375,
                            -20.05537109375001
                        ],
                        [
                            -61.7568359375,
                            -19.645312500000003
                        ],
                        [
                            -60.00737304687499,
                            -19.29755859375001
                        ],
                        [
                            -59.09052734375,
                            -19.286230468750006
                        ],
                        [
                            -58.18017578125,
                            -19.81787109375
                        ],
                        [
                            -58.15976562499999,
                            -20.164648437500006
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Paraguay',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            34.880468750000006,
                            31.3681640625
                        ],
                        [
                            34.98974609374997,
                            31.913281249999955
                        ],
                        [
                            34.955957031249994,
                            32.16093749999996
                        ],
                        [
                            35.01054687500002,
                            32.33818359375002
                        ],
                        [
                            35.06503906250006,
                            32.46044921875006
                        ],
                        [
                            35.19326171875005,
                            32.53442382812503
                        ],
                        [
                            35.303808593750006,
                            32.512939453125
                        ],
                        [
                            35.38671875000003,
                            32.493017578125034
                        ],
                        [
                            35.402636718750074,
                            32.45063476562501
                        ],
                        [
                            35.48437500000003,
                            32.40166015624999
                        ],
                        [
                            35.5514648437501,
                            32.39550781250006
                        ],
                        [
                            35.55898437500005,
                            31.765527343749966
                        ],
                        [
                            35.499414062499994,
                            31.67236328125
                        ],
                        [
                            35.450585937499994,
                            31.479296875000017
                        ],
                        [
                            34.880468750000006,
                            31.3681640625
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Israel',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            51.26796875000002,
                            24.607226562500003
                        ],
                        [
                            51.093359375000006,
                            24.564648437499997
                        ],
                        [
                            51.02275390625002,
                            24.565234375000003
                        ],
                        [
                            50.96601562500001,
                            24.573925781249997
                        ],
                        [
                            50.928320312500006,
                            24.595117187499994
                        ],
                        [
                            50.85566406250001,
                            24.679638671874997
                        ],
                        [
                            50.80439453125001,
                            24.789257812499997
                        ],
                        [
                            50.8359375,
                            24.850390625000003
                        ],
                        [
                            50.846777343750006,
                            24.888574218749994
                        ],
                        [
                            50.76289062500001,
                            25.444726562499994
                        ],
                        [
                            51.00312500000001,
                            25.9814453125
                        ],
                        [
                            51.262304687500006,
                            26.153271484374997
                        ],
                        [
                            51.543066406250006,
                            25.902392578125003
                        ],
                        [
                            51.4853515625,
                            25.524707031250003
                        ],
                        [
                            51.60195312500002,
                            25.14794921875
                        ],
                        [
                            51.26796875000002,
                            24.607226562500003
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Qatar',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            28.212500000000006,
                            45.450439453125
                        ],
                        [
                            28.317675781250017,
                            45.347119140625
                        ],
                        [
                            28.451269531250006,
                            45.2921875
                        ],
                        [
                            28.78828125000001,
                            45.240966796875
                        ],
                        [
                            29.223535156250023,
                            45.4029296875
                        ],
                        [
                            29.705859375000017,
                            45.259912109374994
                        ],
                        [
                            29.557519531250023,
                            44.843408203124994
                        ],
                        [
                            29.048242187500023,
                            44.757568359375
                        ],
                        [
                            28.645410156250023,
                            44.295654296875
                        ],
                        [
                            28.585351562500023,
                            43.742236328124996
                        ],
                        [
                            27.0869140625,
                            44.167382812499994
                        ],
                        [
                            26.2158203125,
                            44.007275390625
                        ],
                        [
                            25.4970703125,
                            43.670800781249994
                        ],
                        [
                            23.224609375,
                            43.873876953125
                        ],
                        [
                            22.705078125,
                            44.23779296875
                        ],
                        [
                            22.687890625000023,
                            44.248291015625
                        ],
                        [
                            21.384375000000006,
                            44.870068359375
                        ],
                        [
                            21.431445312500017,
                            45.192529296874994
                        ],
                        [
                            20.774218750000017,
                            45.484423828124996
                        ],
                        [
                            20.775000000000006,
                            45.749804687499996
                        ],
                        [
                            20.35859375000001,
                            45.975488281249994
                        ],
                        [
                            20.241796875000006,
                            46.10859375
                        ],
                        [
                            20.28095703125001,
                            46.1330078125
                        ],
                        [
                            20.508105468750017,
                            46.166943359375
                        ],
                        [
                            20.613671875000023,
                            46.13349609375
                        ],
                        [
                            21.151953125000006,
                            46.304345703124994
                        ],
                        [
                            22.290625000000006,
                            47.727832031249996
                        ],
                        [
                            22.87666015625001,
                            47.947265625
                        ],
                        [
                            23.628710937500017,
                            47.995849609375
                        ],
                        [
                            24.578906250000017,
                            47.931054687499994
                        ],
                        [
                            24.893359375000017,
                            47.7177734375
                        ],
                        [
                            26.162695312500006,
                            47.992529296875
                        ],
                        [
                            26.236230468750023,
                            48.064355468749994
                        ],
                        [
                            26.276953125000006,
                            48.113232421875
                        ],
                        [
                            26.3056640625,
                            48.203759765624994
                        ],
                        [
                            26.4423828125,
                            48.22998046875
                        ],
                        [
                            26.618945312500017,
                            48.25986328125
                        ],
                        [
                            26.980761718750017,
                            48.155029296875
                        ],
                        [
                            27.3369140625,
                            47.63974609375
                        ],
                        [
                            28.07177734375,
                            46.978417968749994
                        ],
                        [
                            28.24433593750001,
                            46.45126953125
                        ],
                        [
                            28.099707031250006,
                            45.972607421875
                        ],
                        [
                            28.212500000000006,
                            45.450439453125
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Romania',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                148.59951171875,
                                45.317626953125
                            ],
                            [
                                147.91376953125004,
                                44.99038085937502
                            ],
                            [
                                147.76943359375,
                                45.19072265624999
                            ],
                            [
                                148.59951171875,
                                45.317626953125
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                142.76103515625002,
                                54.393945312499966
                            ],
                            [
                                142.91796875000003,
                                53.79423828125002
                            ],
                            [
                                143.22363281250003,
                                53.29604492187505
                            ],
                            [
                                143.32363281250005,
                                52.61357421874999
                            ],
                            [
                                143.15556640625002,
                                52.08374023437497
                            ],
                            [
                                143.45546875,
                                51.47148437500002
                            ],
                            [
                                143.81601562500006,
                                50.28261718750002
                            ],
                            [
                                144.27207031250012,
                                49.311328125000045
                            ],
                            [
                                143.10498046875003,
                                49.198828125000034
                            ],
                            [
                                142.57421874999997,
                                48.07216796875002
                            ],
                            [
                                142.55693359375002,
                                47.737890625000034
                            ],
                            [
                                143.0055664062501,
                                47.22270507812496
                            ],
                            [
                                143.28232421875006,
                                46.55898437500002
                            ],
                            [
                                142.57802734375005,
                                46.700781250000034
                            ],
                            [
                                142.20859375000006,
                                46.0888671875
                            ],
                            [
                                141.83037109375002,
                                46.451074218749966
                            ],
                            [
                                142.03867187500012,
                                47.140283203124966
                            ],
                            [
                                141.9640625000001,
                                47.58745117187502
                            ],
                            [
                                142.18173828125012,
                                48.01337890625001
                            ],
                            [
                                141.86630859375006,
                                48.750097656250006
                            ],
                            [
                                142.1422851562501,
                                49.56914062499999
                            ],
                            [
                                142.07109375000007,
                                50.514990234375034
                            ],
                            [
                                142.20673828125004,
                                51.22255859375002
                            ],
                            [
                                141.72236328125004,
                                51.73632812499997
                            ],
                            [
                                141.82353515625007,
                                53.33950195312502
                            ],
                            [
                                142.52617187500002,
                                53.44746093749998
                            ],
                            [
                                142.76103515625002,
                                54.393945312499966
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                21.235742187500023,
                                55.26411132812498
                            ],
                            [
                                22.83125000000001,
                                54.838476562500034
                            ],
                            [
                                22.766210937499977,
                                54.356787109375034
                            ],
                            [
                                22.168457031250057,
                                54.35986328125006
                            ],
                            [
                                20.20820312500004,
                                54.420751953125034
                            ],
                            [
                                19.92431640625003,
                                54.433984374999966
                            ],
                            [
                                19.644238281250068,
                                54.44707031249996
                            ],
                            [
                                19.604394531250023,
                                54.45917968750004
                            ],
                            [
                                19.85888671875,
                                54.63383789062499
                            ],
                            [
                                19.944140625000074,
                                54.75000000000003
                            ],
                            [
                                19.974511718750023,
                                54.92119140625002
                            ],
                            [
                                20.520312500000017,
                                54.994873046875
                            ],
                            [
                                20.89980468750008,
                                55.286669921875045
                            ],
                            [
                                20.957812500000074,
                                55.27890625000006
                            ],
                            [
                                21.235742187500023,
                                55.26411132812498
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                163.63515625000005,
                                58.603369140625006
                            ],
                            [
                                163.7609375000001,
                                59.01503906250002
                            ],
                            [
                                164.61572265624997,
                                58.885595703125034
                            ],
                            [
                                163.63515625000005,
                                58.603369140625006
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -179.79853515625,
                                68.9404296875
                            ],
                            [
                                -178.873876953125,
                                68.75410156249995
                            ],
                            [
                                -177.40751953124996,
                                68.24516601562502
                            ],
                            [
                                -175.34521484375,
                                67.67807617187503
                            ],
                            [
                                -174.44760742187503,
                                67.10312500000003
                            ],
                            [
                                -173.6796875,
                                67.144775390625
                            ],
                            [
                                -172.64057617187504,
                                66.92499999999998
                            ],
                            [
                                -171.79555664062502,
                                66.93173828125003
                            ],
                            [
                                -170.191943359375,
                                66.20126953124998
                            ],
                            [
                                -170.66630859375,
                                65.62153320312501
                            ],
                            [
                                -172.21157226562502,
                                65.42519531249997
                            ],
                            [
                                -172.21318359375,
                                65.04814453124999
                            ],
                            [
                                -172.92402343750004,
                                64.70493164062506
                            ],
                            [
                                -173.00913085937498,
                                64.2974609375
                            ],
                            [
                                -173.72973632812497,
                                64.36450195312497
                            ],
                            [
                                -174.318017578125,
                                64.63764648437504
                            ],
                            [
                                -175.39511718749998,
                                64.80239257812502
                            ],
                            [
                                -175.85385742187498,
                                65.01083984375003
                            ],
                            [
                                -176.09326171875,
                                65.471044921875
                            ],
                            [
                                -177.05624999999998,
                                65.613623046875
                            ],
                            [
                                -178.31020507812502,
                                65.48486328124997
                            ],
                            [
                                -178.87934570312495,
                                65.93647460937495
                            ],
                            [
                                -179.7283203125,
                                65.80380859375
                            ],
                            [
                                -179.352099609375,
                                65.51674804687497
                            ],
                            [
                                -180,
                                65.06723632812498
                            ],
                            [
                                -180,
                                65.31196289062501
                            ],
                            [
                                -180,
                                65.55678710937497
                            ],
                            [
                                -180,
                                65.80156250000002
                            ],
                            [
                                -180,
                                66.04628906250002
                            ],
                            [
                                -180,
                                66.29106445312499
                            ],
                            [
                                -180,
                                66.53583984375004
                            ],
                            [
                                -180,
                                66.78056640625005
                            ],
                            [
                                -180,
                                67.02534179687501
                            ],
                            [
                                -180,
                                67.27011718750006
                            ],
                            [
                                -180,
                                67.51484374999998
                            ],
                            [
                                -180,
                                67.75961914062503
                            ],
                            [
                                -180,
                                68.00439453124997
                            ],
                            [
                                -180,
                                68.24912109375
                            ],
                            [
                                -180,
                                68.49389648437497
                            ],
                            [
                                -180,
                                68.738671875
                            ],
                            [
                                -179.79853515625,
                                68.9404296875
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                50.265234375000006,
                                69.18559570312502
                            ],
                            [
                                49.62626953125002,
                                68.85971679687498
                            ],
                            [
                                48.66699218750003,
                                68.73315429687503
                            ],
                            [
                                48.27880859374997,
                                69.04033203124999
                            ],
                            [
                                48.63134765625003,
                                69.43603515624997
                            ],
                            [
                                49.22519531250006,
                                69.51123046875
                            ],
                            [
                                50.265234375000006,
                                69.18559570312502
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                169.20078125000006,
                                69.58046875
                            ],
                            [
                                167.78886718750002,
                                69.83686523437501
                            ],
                            [
                                168.35791015625003,
                                70.01567382812502
                            ],
                            [
                                169.37480468750007,
                                69.88261718749999
                            ],
                            [
                                169.20078125000006,
                                69.58046875
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                60.450488281250074,
                                69.93486328124999
                            ],
                            [
                                59.57822265625006,
                                69.73862304687498
                            ],
                            [
                                58.473046874999994,
                                70.26684570312503
                            ],
                            [
                                59.00527343750005,
                                70.46518554687503
                            ],
                            [
                                60.450488281250074,
                                69.93486328124999
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                178.8615234375001,
                                70.826416015625
                            ],
                            [
                                178.89111328125009,
                                71.23110351562502
                            ],
                            [
                                180.00000000000003,
                                71.53774414062505
                            ],
                            [
                                180.00000000000003,
                                70.993017578125
                            ],
                            [
                                178.8615234375001,
                                70.826416015625
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -178.87646484375,
                                71.57705078124997
                            ],
                            [
                                -177.53217773437504,
                                71.26308593750005
                            ],
                            [
                                -177.82177734375,
                                71.06757812500001
                            ],
                            [
                                -179.50668945312498,
                                70.92343750000003
                            ],
                            [
                                -179.999951171875,
                                70.993017578125
                            ],
                            [
                                -179.999951171875,
                                71.53774414062505
                            ],
                            [
                                -178.87646484375,
                                71.57705078124997
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                55.31982421875003,
                                73.30830078124998
                            ],
                            [
                                56.42958984375005,
                                73.201171875
                            ],
                            [
                                56.08378906250002,
                                72.78940429687503
                            ],
                            [
                                55.35595703124997,
                                72.46508789062506
                            ],
                            [
                                55.29785156249997,
                                71.93535156250005
                            ],
                            [
                                57.14589843750005,
                                70.58911132812506
                            ],
                            [
                                54.60117187500006,
                                70.68007812500002
                            ],
                            [
                                53.7223632812501,
                                70.81445312500003
                            ],
                            [
                                53.36386718750006,
                                71.54165039062505
                            ],
                            [
                                51.812597656250006,
                                71.49130859375
                            ],
                            [
                                51.58251953124997,
                                72.07119140625
                            ],
                            [
                                52.62207031250003,
                                72.30097656250001
                            ],
                            [
                                53.753222656250074,
                                73.29326171875002
                            ],
                            [
                                55.31982421875003,
                                73.30830078124998
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                142.18486328125007,
                                73.89589843750005
                            ],
                            [
                                143.34374999999997,
                                73.56875000000002
                            ],
                            [
                                143.19326171875005,
                                73.22075195312499
                            ],
                            [
                                140.66279296875004,
                                73.45200195312503
                            ],
                            [
                                141.08476562500002,
                                73.86586914062497
                            ],
                            [
                                142.18486328125007,
                                73.89589843750005
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                113.38720703124997,
                                74.40043945312499
                            ],
                            [
                                112.78242187500004,
                                74.09506835937503
                            ],
                            [
                                111.64296875,
                                74.27294921875003
                            ],
                            [
                                112.08447265624997,
                                74.54897460937505
                            ],
                            [
                                113.38720703124997,
                                74.40043945312499
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                146.79521484375007,
                                75.37075195312505
                            ],
                            [
                                150.10390625,
                                75.21923828125
                            ],
                            [
                                150.64628906250002,
                                74.944580078125
                            ],
                            [
                                149.5968750000001,
                                74.77260742187505
                            ],
                            [
                                148.09238281250006,
                                74.82568359375003
                            ],
                            [
                                146.14853515625012,
                                75.19829101562499
                            ],
                            [
                                146.79521484375007,
                                75.37075195312505
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                140.04873046875,
                                75.82895507812503
                            ],
                            [
                                140.94414062500007,
                                75.70048828124999
                            ],
                            [
                                142.46035156250005,
                                75.90361328125007
                            ],
                            [
                                143.68583984375002,
                                75.86367187500002
                            ],
                            [
                                145.25527343750005,
                                75.58559570312502
                            ],
                            [
                                144.01972656250004,
                                75.04467773437506
                            ],
                            [
                                141.98730468750003,
                                74.99125976562499
                            ],
                            [
                                139.09912109374997,
                                74.65654296875002
                            ],
                            [
                                138.09228515625003,
                                74.79746093750003
                            ],
                            [
                                137.00625,
                                75.23500976562502
                            ],
                            [
                                137.50117187500004,
                                75.90966796874997
                            ],
                            [
                                138.81396484374997,
                                76.19970703125
                            ],
                            [
                                140.04873046875,
                                75.82895507812503
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                67.7653320312501,
                                76.23759765624999
                            ],
                            [
                                66.28242187500004,
                                75.98369140625005
                            ],
                            [
                                61.35595703124997,
                                75.31484375000002
                            ],
                            [
                                60.22246093750002,
                                74.79658203124995
                            ],
                            [
                                58.53466796875003,
                                74.49892578124997
                            ],
                            [
                                58.44140625000003,
                                74.12885742187498
                            ],
                            [
                                56.96386718750003,
                                73.36655273437503
                            ],
                            [
                                55.00683593750003,
                                73.45385742187506
                            ],
                            [
                                53.7628906250001,
                                73.76616210937499
                            ],
                            [
                                54.64267578125006,
                                73.95957031250006
                            ],
                            [
                                57.08750000000006,
                                75.38383789062505
                            ],
                            [
                                58.881249999999994,
                                75.85478515625007
                            ],
                            [
                                62.97148437500002,
                                76.23666992187498
                            ],
                            [
                                65.862890625,
                                76.61333007812505
                            ],
                            [
                                67.53496093750007,
                                77.00776367187504
                            ],
                            [
                                68.48574218750005,
                                76.93369140625003
                            ],
                            [
                                68.8998046875,
                                76.57294921875001
                            ],
                            [
                                67.7653320312501,
                                76.23759765624999
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                130.68730468750007,
                                42.30253906249999
                            ],
                            [
                                130.52695312500012,
                                42.535400390625
                            ],
                            [
                                130.57724609375006,
                                42.81162109375006
                            ],
                            [
                                131.06855468750004,
                                42.90224609375005
                            ],
                            [
                                131.26181640625006,
                                43.43305664062501
                            ],
                            [
                                131.2552734375,
                                44.07158203124999
                            ],
                            [
                                131.08232421875002,
                                44.91000976562498
                            ],
                            [
                                131.97753906250003,
                                45.243994140625006
                            ],
                            [
                                132.93603515624997,
                                45.029931640624994
                            ],
                            [
                                133.18603515625003,
                                45.49482421875004
                            ],
                            [
                                133.86132812500003,
                                46.24775390625004
                            ],
                            [
                                134.1676757812501,
                                47.30219726562501
                            ],
                            [
                                134.75234375,
                                47.71542968749998
                            ],
                            [
                                134.56357421875012,
                                48.321728515624955
                            ],
                            [
                                134.29335937500005,
                                48.37343750000002
                            ],
                            [
                                133.46835937500006,
                                48.09716796875003
                            ],
                            [
                                133.14404296875003,
                                48.10566406249998
                            ],
                            [
                                132.5619140625,
                                47.768505859374955
                            ],
                            [
                                131.00273437500002,
                                47.69145507812499
                            ],
                            [
                                130.7121093750001,
                                48.12763671875004
                            ],
                            [
                                130.80429687500012,
                                48.34150390624998
                            ],
                            [
                                129.49814453125012,
                                49.38881835937502
                            ],
                            [
                                129.12011718750003,
                                49.36206054687497
                            ],
                            [
                                128.70400390625,
                                49.60014648437499
                            ],
                            [
                                127.99960937500006,
                                49.56860351562506
                            ],
                            [
                                127.50244140624997,
                                49.873437499999994
                            ],
                            [
                                127.590234375,
                                50.20898437500003
                            ],
                            [
                                126.92480468749997,
                                51.10014648437496
                            ],
                            [
                                126.312890625,
                                52.39975585937506
                            ],
                            [
                                125.64902343750012,
                                53.042285156250045
                            ],
                            [
                                124.81230468750002,
                                53.133837890625045
                            ],
                            [
                                123.6078125,
                                53.546533203124994
                            ],
                            [
                                122.33779296875,
                                53.485009765624994
                            ],
                            [
                                120.70410156249997,
                                53.171826171874955
                            ],
                            [
                                120.09453125000007,
                                52.787207031250034
                            ],
                            [
                                120.65615234375,
                                52.56665039062503
                            ],
                            [
                                120.74980468750007,
                                52.096533203125006
                            ],
                            [
                                120.06689453125003,
                                51.60068359375006
                            ],
                            [
                                119.19189453125003,
                                50.37983398437504
                            ],
                            [
                                119.25986328125012,
                                50.06640625000003
                            ],
                            [
                                118.4515625,
                                49.84448242187503
                            ],
                            [
                                117.8734375,
                                49.51347656250002
                            ],
                            [
                                116.6833007812501,
                                49.82377929687499
                            ],
                            [
                                116.13457031250002,
                                50.01079101562499
                            ],
                            [
                                115.27451171875006,
                                49.948876953124994
                            ],
                            [
                                114.74316406249997,
                                50.23369140625002
                            ],
                            [
                                114.29707031250004,
                                50.27441406250006
                            ],
                            [
                                113.57421874999997,
                                50.00703125000001
                            ],
                            [
                                113.44550781250004,
                                49.94160156250001
                            ],
                            [
                                113.31904296875004,
                                49.87431640624999
                            ],
                            [
                                113.16416015625012,
                                49.79716796874999
                            ],
                            [
                                113.09208984375007,
                                49.692529296874994
                            ],
                            [
                                113.05556640625,
                                49.61625976562499
                            ],
                            [
                                111.3366210937501,
                                49.355859374999966
                            ],
                            [
                                111.20419921875012,
                                49.304296875000034
                            ],
                            [
                                110.82792968750002,
                                49.16616210937505
                            ],
                            [
                                110.19990234375004,
                                49.17041015625003
                            ],
                            [
                                109.5287109375,
                                49.269873046875034
                            ],
                            [
                                109.45371093750012,
                                49.29633789062501
                            ],
                            [
                                109.23671875000005,
                                49.334912109374955
                            ],
                            [
                                108.61367187500005,
                                49.32280273437499
                            ],
                            [
                                108.52246093750003,
                                49.34150390624998
                            ],
                            [
                                108.4069335937501,
                                49.39638671875005
                            ],
                            [
                                108.03378906250012,
                                49.59399414062497
                            ],
                            [
                                107.94785156250012,
                                49.92470703125002
                            ],
                            [
                                107.63095703125012,
                                49.98310546875004
                            ],
                            [
                                107.3470703125,
                                49.986669921875034
                            ],
                            [
                                107.23330078125,
                                49.989404296874994
                            ],
                            [
                                107.14306640625003,
                                50.03300781249999
                            ],
                            [
                                107.04023437500004,
                                50.086474609375045
                            ],
                            [
                                106.94130859375005,
                                50.19667968750002
                            ],
                            [
                                106.71113281250004,
                                50.312597656250006
                            ],
                            [
                                106.57441406250004,
                                50.32880859375004
                            ],
                            [
                                106.36845703125002,
                                50.317578124999955
                            ],
                            [
                                106.21787109375006,
                                50.304589843749966
                            ],
                            [
                                105.38359375000002,
                                50.47373046874998
                            ],
                            [
                                105.09472656249997,
                                50.38994140624996
                            ],
                            [
                                104.97695312500005,
                                50.382910156250034
                            ],
                            [
                                104.46630859375003,
                                50.30615234375003
                            ],
                            [
                                104.35390625000005,
                                50.27529296875005
                            ],
                            [
                                104.2599609375001,
                                50.214453125000006
                            ],
                            [
                                104.17968749999997,
                                50.16943359374997
                            ],
                            [
                                103.63291015625006,
                                50.138574218749994
                            ],
                            [
                                103.49628906250004,
                                50.16494140625005
                            ],
                            [
                                103.42119140625002,
                                50.18706054687502
                            ],
                            [
                                103.3043945312501,
                                50.200292968750034
                            ],
                            [
                                103.2337890625,
                                50.264257812500034
                            ],
                            [
                                103.16171875000006,
                                50.29072265624998
                            ],
                            [
                                103.03945312500005,
                                50.30063476562506
                            ],
                            [
                                102.85966796875002,
                                50.33325195312506
                            ],
                            [
                                102.68330078125004,
                                50.38715820312501
                            ],
                            [
                                102.28837890625007,
                                50.58510742187502
                            ],
                            [
                                102.31660156250004,
                                50.71845703125001
                            ],
                            [
                                102.21503906250004,
                                50.82944335937506
                            ],
                            [
                                102.15195312500006,
                                51.107519531250034
                            ],
                            [
                                102.14238281250007,
                                51.21606445312503
                            ],
                            [
                                102.16005859375005,
                                51.260839843750006
                            ],
                            [
                                102.1556640625,
                                51.31376953124996
                            ],
                            [
                                102.1115234375001,
                                51.353466796874955
                            ],
                            [
                                101.97919921875004,
                                51.382226562499966
                            ],
                            [
                                101.82119140625,
                                51.421044921874966
                            ],
                            [
                                101.57089843750006,
                                51.46718750000005
                            ],
                            [
                                101.38125,
                                51.45263671875
                            ],
                            [
                                101.30449218750007,
                                51.474755859374994
                            ],
                            [
                                101.22324218750012,
                                51.513281250000006
                            ],
                            [
                                100.53623046875006,
                                51.713476562500034
                            ],
                            [
                                100.46894531250004,
                                51.72607421875003
                            ],
                            [
                                100.23037109375,
                                51.729833984375006
                            ],
                            [
                                100.0345703125,
                                51.73710937499996
                            ],
                            [
                                99.92167968750002,
                                51.755517578124994
                            ],
                            [
                                99.71923828124997,
                                51.87163085937502
                            ],
                            [
                                98.84863281249997,
                                52.07006835937503
                            ],
                            [
                                98.64052734375005,
                                51.80117187500005
                            ],
                            [
                                98.3527343750001,
                                51.717626953125006
                            ],
                            [
                                97.94687500000012,
                                51.34843750000002
                            ],
                            [
                                97.82529296875012,
                                50.985253906249994
                            ],
                            [
                                98.2794921875001,
                                50.53325195312502
                            ],
                            [
                                98.10341796875005,
                                50.077832031249955
                            ],
                            [
                                97.58935546875003,
                                49.911474609375006
                            ],
                            [
                                97.35976562500005,
                                49.741455078125
                            ],
                            [
                                97.20859375000006,
                                49.73081054687506
                            ],
                            [
                                96.98574218750005,
                                49.88281250000003
                            ],
                            [
                                96.31503906250012,
                                49.90112304687503
                            ],
                            [
                                96.06552734375006,
                                49.99873046875001
                            ],
                            [
                                95.9357421875001,
                                49.960009765625045
                            ],
                            [
                                95.89941406249997,
                                49.990576171875034
                            ],
                            [
                                95.851953125,
                                50.01293945312497
                            ],
                            [
                                95.78935546875002,
                                50.01249999999999
                            ],
                            [
                                95.70781250000002,
                                49.96601562500001
                            ],
                            [
                                95.56718750000007,
                                49.94384765625003
                            ],
                            [
                                95.52265625000004,
                                49.911230468750034
                            ],
                            [
                                95.04433593750005,
                                49.961572265624994
                            ],
                            [
                                94.93027343750006,
                                50.043750000000045
                            ],
                            [
                                94.71806640625002,
                                50.04326171875002
                            ],
                            [
                                94.67548828125004,
                                50.02807617187506
                            ],
                            [
                                94.61474609375003,
                                50.02373046874996
                            ],
                            [
                                94.25107421875006,
                                50.55639648437503
                            ],
                            [
                                91.80429687500006,
                                50.693603515625
                            ],
                            [
                                89.64384765625002,
                                49.90302734374998
                            ],
                            [
                                89.65410156250007,
                                49.71748046875001
                            ],
                            [
                                88.83164062500012,
                                49.44843749999998
                            ],
                            [
                                88.633203125,
                                49.486132812500045
                            ],
                            [
                                88.5443359375,
                                49.48256835937502
                            ],
                            [
                                88.45244140625002,
                                49.47270507812496
                            ],
                            [
                                88.39335937500007,
                                49.48286132812498
                            ],
                            [
                                88.33779296875005,
                                49.47255859375002
                            ],
                            [
                                88.19257812500004,
                                49.451708984375045
                            ],
                            [
                                88.13554687500002,
                                49.38149414062502
                            ],
                            [
                                88.11572265624997,
                                49.25629882812501
                            ],
                            [
                                88.0285156250001,
                                49.219775390625045
                            ],
                            [
                                87.98808593750002,
                                49.186914062499994
                            ],
                            [
                                87.9347656250001,
                                49.16455078124997
                            ],
                            [
                                87.81425781250002,
                                49.162304687499955
                            ],
                            [
                                87.76250000000007,
                                49.16582031249996
                            ],
                            [
                                87.5158203125001,
                                49.122412109375006
                            ],
                            [
                                87.41669921875004,
                                49.07661132812501
                            ],
                            [
                                87.32285156250012,
                                49.085791015625006
                            ],
                            [
                                86.61425781249997,
                                49.60971679687498
                            ],
                            [
                                86.1808593750001,
                                49.49931640624996
                            ],
                            [
                                85.21015625000004,
                                49.66484375000002
                            ],
                            [
                                84.9894531250001,
                                50.061425781249994
                            ],
                            [
                                84.25781249999997,
                                50.28823242187502
                            ],
                            [
                                83.94511718750007,
                                50.774658203125
                            ],
                            [
                                83.35732421875005,
                                50.99458007812504
                            ],
                            [
                                82.49394531250007,
                                50.72758789062499
                            ],
                            [
                                81.46591796875006,
                                50.739843750000006
                            ],
                            [
                                80.87734375,
                                51.28144531250004
                            ],
                            [
                                79.98623046875,
                                50.774560546874966
                            ],
                            [
                                79.46884765625012,
                                51.49311523437498
                            ],
                            [
                                78.47548828125005,
                                52.638427734375
                            ],
                            [
                                77.70439453125007,
                                53.379150390625
                            ],
                            [
                                76.57568359374997,
                                53.94252929687502
                            ],
                            [
                                76.26660156249997,
                                54.31196289062498
                            ],
                            [
                                74.45195312500007,
                                53.64726562500002
                            ],
                            [
                                73.28574218750006,
                                53.598388671875
                            ],
                            [
                                73.39941406250003,
                                53.81147460937498
                            ],
                            [
                                72.91406249999997,
                                54.10732421875002
                            ],
                            [
                                71.09316406250005,
                                54.21220703124999
                            ],
                            [
                                71.18554687500003,
                                54.59931640624998
                            ],
                            [
                                70.73808593750007,
                                55.30517578125
                            ],
                            [
                                70.18242187500002,
                                55.162451171875034
                            ],
                            [
                                68.9772460937501,
                                55.389599609374955
                            ],
                            [
                                68.20625000000004,
                                55.16093750000002
                            ],
                            [
                                68.07382812500006,
                                54.959570312500006
                            ],
                            [
                                66.22265624999997,
                                54.667382812499994
                            ],
                            [
                                65.476953125,
                                54.62329101562497
                            ],
                            [
                                65.1578125,
                                54.364404296874994
                            ],
                            [
                                64.46123046875002,
                                54.38417968750002
                            ],
                            [
                                63.0739257812501,
                                54.10522460937506
                            ],
                            [
                                61.92871093750003,
                                53.94648437500004
                            ],
                            [
                                61.33369140625004,
                                54.04926757812501
                            ],
                            [
                                60.97949218749997,
                                53.62172851562505
                            ],
                            [
                                61.19921874999997,
                                53.28715820312502
                            ],
                            [
                                62.01464843750003,
                                53.10786132812498
                            ],
                            [
                                61.97421875000006,
                                52.94375000000002
                            ],
                            [
                                61.047460937500006,
                                52.97246093750002
                            ],
                            [
                                60.80234375000006,
                                52.74472656249998
                            ],
                            [
                                60.937597656250006,
                                52.28056640625002
                            ],
                            [
                                60.06552734375006,
                                51.976464843749966
                            ],
                            [
                                60.464746093749994,
                                51.651171875000045
                            ],
                            [
                                61.363085937500074,
                                51.44189453125003
                            ],
                            [
                                61.585058593750006,
                                51.22968749999998
                            ],
                            [
                                61.38945312500002,
                                50.86103515625001
                            ],
                            [
                                60.637988281250074,
                                50.66372070312505
                            ],
                            [
                                59.95517578125006,
                                50.79926757812504
                            ],
                            [
                                59.812402343749994,
                                50.58203125
                            ],
                            [
                                58.88369140625005,
                                50.694433593750006
                            ],
                            [
                                58.359179687500074,
                                51.063818359375034
                            ],
                            [
                                57.83886718750003,
                                51.091650390625006
                            ],
                            [
                                57.44218750000002,
                                50.88886718749998
                            ],
                            [
                                57.01171874999997,
                                51.06518554687503
                            ],
                            [
                                56.49140625000004,
                                51.01953124999997
                            ],
                            [
                                55.79765625000002,
                                50.60205078125
                            ],
                            [
                                55.54228515625002,
                                50.60180664062506
                            ],
                            [
                                54.72714843750006,
                                50.998095703125045
                            ],
                            [
                                54.42148437500006,
                                50.78032226562499
                            ],
                            [
                                54.04150390624997,
                                51.11518554687501
                            ],
                            [
                                53.33808593750004,
                                51.48237304687504
                            ],
                            [
                                52.57119140625005,
                                51.481640624999955
                            ],
                            [
                                52.33105468749997,
                                51.681298828124966
                            ],
                            [
                                51.60908203125004,
                                51.483984375000006
                            ],
                            [
                                50.79394531249997,
                                51.729199218749955
                            ],
                            [
                                50.246875000000074,
                                51.28950195312498
                            ],
                            [
                                49.49804687500003,
                                51.08359375000006
                            ],
                            [
                                48.80839843750002,
                                50.60131835937503
                            ],
                            [
                                48.84326171875003,
                                50.01313476562501
                            ],
                            [
                                48.43427734375004,
                                49.828515624999966
                            ],
                            [
                                47.7057617187501,
                                50.37797851562502
                            ],
                            [
                                47.32646484375002,
                                50.273535156250034
                            ],
                            [
                                46.80205078125002,
                                49.36708984375002
                            ],
                            [
                                47.014257812500006,
                                49.09833984374998
                            ],
                            [
                                46.70263671875003,
                                48.80556640625002
                            ],
                            [
                                46.660937500000074,
                                48.41225585937502
                            ],
                            [
                                47.004296874999994,
                                48.28447265625002
                            ],
                            [
                                47.09326171875003,
                                47.947705078124955
                            ],
                            [
                                48.109960937500006,
                                47.74541015624996
                            ],
                            [
                                48.95937500000005,
                                46.77460937499998
                            ],
                            [
                                48.610156250000074,
                                46.566455078125045
                            ],
                            [
                                49.232226562500074,
                                46.33715820312503
                            ],
                            [
                                48.15917968750003,
                                45.73701171874998
                            ],
                            [
                                47.524218750000074,
                                45.60170898437505
                            ],
                            [
                                46.753027343750006,
                                44.42065429687503
                            ],
                            [
                                47.22988281250005,
                                44.19238281249997
                            ],
                            [
                                47.5089843750001,
                                43.50971679687498
                            ],
                            [
                                47.463183593750074,
                                43.03505859375002
                            ],
                            [
                                48.572851562500006,
                                41.84448242187503
                            ],
                            [
                                47.861132812500074,
                                41.212744140625034
                            ],
                            [
                                47.26113281250005,
                                41.315087890624994
                            ],
                            [
                                46.74931640625002,
                                41.812597656250006
                            ],
                            [
                                46.42988281250004,
                                41.890966796875006
                            ],
                            [
                                46.21269531250002,
                                41.989892578124994
                            ],
                            [
                                45.63857421875005,
                                42.20507812500003
                            ],
                            [
                                45.63427734374997,
                                42.234716796875034
                            ],
                            [
                                45.72753906249997,
                                42.47504882812498
                            ],
                            [
                                45.562890624999994,
                                42.53574218749998
                            ],
                            [
                                45.34375000000003,
                                42.52978515625003
                            ],
                            [
                                45.20820312500004,
                                42.64824218749999
                            ],
                            [
                                45.16025390625006,
                                42.67500000000004
                            ],
                            [
                                45.07158203125002,
                                42.69414062500002
                            ],
                            [
                                44.94335937499997,
                                42.73027343750002
                            ],
                            [
                                44.870996093749994,
                                42.75639648437499
                            ],
                            [
                                44.850488281249994,
                                42.746826171875
                            ],
                            [
                                44.77109375000006,
                                42.61679687499998
                            ],
                            [
                                44.691796874999994,
                                42.709619140624966
                            ],
                            [
                                44.64433593750002,
                                42.734716796875034
                            ],
                            [
                                44.50585937500003,
                                42.748632812500006
                            ],
                            [
                                44.329492187499994,
                                42.703515624999966
                            ],
                            [
                                44.10273437500004,
                                42.616357421874994
                            ],
                            [
                                44.004687500000074,
                                42.59560546875002
                            ],
                            [
                                43.95742187500005,
                                42.56655273437505
                            ],
                            [
                                43.825976562500074,
                                42.571533203125
                            ],
                            [
                                43.759863281250006,
                                42.593847656250006
                            ],
                            [
                                43.738378906250006,
                                42.61699218750002
                            ],
                            [
                                43.74990234375005,
                                42.65751953125002
                            ],
                            [
                                43.79541015624997,
                                42.702978515625034
                            ],
                            [
                                43.78261718750005,
                                42.747021484374955
                            ],
                            [
                                43.62304687500003,
                                42.80771484374998
                            ],
                            [
                                43.5578125000001,
                                42.844482421875
                            ],
                            [
                                43.089160156250074,
                                42.98906249999996
                            ],
                            [
                                42.991601562499994,
                                43.09150390624998
                            ],
                            [
                                42.76064453125005,
                                43.169580078124966
                            ],
                            [
                                42.660253906250006,
                                43.159082031249966
                            ],
                            [
                                42.566015625000006,
                                43.15512695312506
                            ],
                            [
                                42.41904296875006,
                                43.224218750000006
                            ],
                            [
                                42.279687499999994,
                                43.22807617187499
                            ],
                            [
                                42.05000000000004,
                                43.19013671874998
                            ],
                            [
                                41.58056640624997,
                                43.21923828124997
                            ],
                            [
                                41.460742187500074,
                                43.276318359374955
                            ],
                            [
                                41.35820312500002,
                                43.33339843750005
                            ],
                            [
                                40.64804687500006,
                                43.53388671875004
                            ],
                            [
                                40.02373046875002,
                                43.48486328125
                            ],
                            [
                                39.873632812500006,
                                43.47280273437502
                            ],
                            [
                                38.71728515624997,
                                44.28808593750003
                            ],
                            [
                                38.181250000000006,
                                44.41967773437503
                            ],
                            [
                                37.851464843749994,
                                44.698828125000034
                            ],
                            [
                                37.41132812500004,
                                44.73535156250003
                            ],
                            [
                                36.941210937500074,
                                45.289697265624994
                            ],
                            [
                                37.6471679687501,
                                45.37719726562506
                            ],
                            [
                                37.93310546875003,
                                46.001708984375
                            ],
                            [
                                38.18359374999997,
                                46.09482421875006
                            ],
                            [
                                37.9679687500001,
                                46.61801757812506
                            ],
                            [
                                39.29345703125003,
                                47.105761718750045
                            ],
                            [
                                38.21435546875003,
                                47.091455078124966
                            ],
                            [
                                38.287402343750074,
                                47.559179687500034
                            ],
                            [
                                38.90029296875005,
                                47.85512695312502
                            ],
                            [
                                39.735937500000006,
                                47.84482421875006
                            ],
                            [
                                39.96103515625006,
                                48.23793945312502
                            ],
                            [
                                39.68652343749997,
                                49.007910156250034
                            ],
                            [
                                40.07001953125004,
                                49.20029296874998
                            ],
                            [
                                39.780566406250074,
                                49.57202148437503
                            ],
                            [
                                39.17480468750003,
                                49.85595703124997
                            ],
                            [
                                38.258593750000074,
                                50.052343750000006
                            ],
                            [
                                38.04687500000003,
                                49.92001953125006
                            ],
                            [
                                37.42285156249997,
                                50.411474609375006
                            ],
                            [
                                36.619433593750074,
                                50.209228515625
                            ],
                            [
                                35.41162109375003,
                                50.539697265624994
                            ],
                            [
                                35.31191406250005,
                                51.043896484374955
                            ],
                            [
                                34.280664062499994,
                                51.311669921874966
                            ],
                            [
                                34.397851562499994,
                                51.780419921874994
                            ],
                            [
                                33.735253906249994,
                                52.344775390625045
                            ],
                            [
                                32.507910156250006,
                                52.30854492187504
                            ],
                            [
                                31.763378906250097,
                                52.10107421875003
                            ],
                            [
                                31.758593750000017,
                                52.125830078125034
                            ],
                            [
                                31.69062500000004,
                                52.22065429687498
                            ],
                            [
                                31.57734375000004,
                                52.31230468749999
                            ],
                            [
                                31.585546875000006,
                                52.532470703125
                            ],
                            [
                                31.53515624999997,
                                52.798242187499966
                            ],
                            [
                                31.442773437499994,
                                52.86181640625003
                            ],
                            [
                                31.35302734374997,
                                52.93344726562498
                            ],
                            [
                                31.295117187500097,
                                52.98979492187499
                            ],
                            [
                                31.25878906249997,
                                53.01669921875006
                            ],
                            [
                                31.364550781250017,
                                53.13896484375002
                            ],
                            [
                                31.388378906250097,
                                53.18481445312503
                            ],
                            [
                                31.41787109375005,
                                53.196044921875
                            ],
                            [
                                31.849707031250006,
                                53.106201171875
                            ],
                            [
                                32.70429687500004,
                                53.336328124999994
                            ],
                            [
                                32.45097656250002,
                                53.6533203125
                            ],
                            [
                                31.754199218750017,
                                53.81044921875002
                            ],
                            [
                                31.12128906250004,
                                54.64848632812496
                            ],
                            [
                                30.906835937500063,
                                55.57001953125004
                            ],
                            [
                                30.625585937500006,
                                55.666259765625
                            ],
                            [
                                30.23359375000004,
                                55.84521484375006
                            ],
                            [
                                30.04267578125004,
                                55.83642578125003
                            ],
                            [
                                29.93701171874997,
                                55.84526367187499
                            ],
                            [
                                29.881640625000074,
                                55.83232421875002
                            ],
                            [
                                29.82392578125004,
                                55.79511718749998
                            ],
                            [
                                29.74414062499997,
                                55.770410156249994
                            ],
                            [
                                29.68457031249997,
                                55.769726562499955
                            ],
                            [
                                29.482226562500074,
                                55.6845703125
                            ],
                            [
                                29.412988281249994,
                                55.72485351562506
                            ],
                            [
                                29.35341796875005,
                                55.78437500000001
                            ],
                            [
                                29.373144531250063,
                                55.83471679687506
                            ],
                            [
                                29.39794921874997,
                                55.88105468749998
                            ],
                            [
                                29.37499999999997,
                                55.938720703125
                            ],
                            [
                                28.284277343750006,
                                56.055908203125
                            ],
                            [
                                27.806054687499994,
                                56.86708984375005
                            ],
                            [
                                27.639453125000074,
                                56.84565429687504
                            ],
                            [
                                27.814550781250034,
                                57.16689453125002
                            ],
                            [
                                27.796875,
                                57.316943359375045
                            ],
                            [
                                27.538671875000063,
                                57.429785156250034
                            ],
                            [
                                27.51113281250005,
                                57.508154296875006
                            ],
                            [
                                27.469726562500057,
                                57.524023437500034
                            ],
                            [
                                27.35195312500005,
                                57.52812499999999
                            ],
                            [
                                27.400000000000063,
                                57.66679687499999
                            ],
                            [
                                27.542089843750063,
                                57.799414062500006
                            ],
                            [
                                27.75283203125008,
                                57.841015624999955
                            ],
                            [
                                27.48779296875,
                                58.270068359375045
                            ],
                            [
                                27.464453125000034,
                                58.84130859375
                            ],
                            [
                                28.012500000000045,
                                59.484277343749966
                            ],
                            [
                                28.013964843750074,
                                59.72475585937502
                            ],
                            [
                                28.947265625000057,
                                59.828759765624994
                            ],
                            [
                                29.069140625000017,
                                60.19145507812499
                            ],
                            [
                                28.65058593750001,
                                60.61098632812502
                            ],
                            [
                                27.797656250000074,
                                60.53613281250003
                            ],
                            [
                                29.69013671875004,
                                61.54609375000001
                            ],
                            [
                                31.28564453125003,
                                62.567822265624955
                            ],
                            [
                                31.533984375000017,
                                62.885400390624994
                            ],
                            [
                                31.180859375000097,
                                63.208300781250074
                            ],
                            [
                                30.05537109375004,
                                63.689013671875045
                            ],
                            [
                                30.50390625000003,
                                64.02060546875
                            ],
                            [
                                30.10810546875004,
                                64.36611328125005
                            ],
                            [
                                30.072851562500063,
                                64.76503906250005
                            ],
                            [
                                29.60419921875004,
                                64.968408203125
                            ],
                            [
                                29.903417968750006,
                                66.091064453125
                            ],
                            [
                                29.087011718750034,
                                66.97094726562503
                            ],
                            [
                                29.941210937500074,
                                67.54746093749998
                            ],
                            [
                                29.343847656250006,
                                68.06186523437506
                            ],
                            [
                                28.470703125000057,
                                68.48837890625
                            ],
                            [
                                28.96582031250003,
                                69.02197265625
                            ],
                            [
                                30.869726562500006,
                                69.78344726562506
                            ],
                            [
                                31.98457031250004,
                                69.95366210937499
                            ],
                            [
                                32.94169921875002,
                                69.75185546875
                            ],
                            [
                                32.37773437500002,
                                69.47910156250003
                            ],
                            [
                                33.68437500000002,
                                69.31025390625001
                            ],
                            [
                                35.85791015625003,
                                69.19174804687503
                            ],
                            [
                                40.38066406250002,
                                67.831884765625
                            ],
                            [
                                40.96640625000006,
                                67.71347656250003
                            ],
                            [
                                41.18896484375003,
                                66.82617187500003
                            ],
                            [
                                40.52158203125006,
                                66.44663085937498
                            ],
                            [
                                39.28906250000003,
                                66.13203125000001
                            ],
                            [
                                38.39755859375006,
                                66.064453125
                            ],
                            [
                                35.51347656250002,
                                66.39580078125002
                            ],
                            [
                                34.824609374999994,
                                66.61113281249999
                            ],
                            [
                                32.46367187500002,
                                66.91630859374999
                            ],
                            [
                                33.566699218750074,
                                66.32099609374995
                            ],
                            [
                                34.7863281250001,
                                65.86455078125005
                            ],
                            [
                                34.40644531250004,
                                65.39575195312503
                            ],
                            [
                                34.803515625000074,
                                64.98598632812502
                            ],
                            [
                                34.869531249999994,
                                64.56000976562501
                            ],
                            [
                                35.64707031250006,
                                64.37832031250005
                            ],
                            [
                                36.3649414062501,
                                64.00283203125002
                            ],
                            [
                                37.44218750000002,
                                63.813378906249966
                            ],
                            [
                                38.07080078124997,
                                64.02583007812498
                            ],
                            [
                                37.953710937500006,
                                64.32011718749999
                            ],
                            [
                                37.183691406250006,
                                64.40849609375007
                            ],
                            [
                                36.52822265625005,
                                64.84736328125004
                            ],
                            [
                                36.88281249999997,
                                65.17236328124997
                            ],
                            [
                                38.009375000000006,
                                64.87875976562503
                            ],
                            [
                                39.7580078125001,
                                64.57705078125002
                            ],
                            [
                                40.28125000000003,
                                64.99809570312502
                            ],
                            [
                                39.816503906250006,
                                65.59794921874999
                            ],
                            [
                                41.4757812500001,
                                66.12343750000002
                            ],
                            [
                                42.21054687500006,
                                66.51967773437502
                            ],
                            [
                                44.09716796874997,
                                66.23505859375004
                            ],
                            [
                                44.488671874999994,
                                66.67177734375002
                            ],
                            [
                                44.29179687500002,
                                67.09965820312505
                            ],
                            [
                                43.7824218750001,
                                67.25449218749998
                            ],
                            [
                                44.22539062500002,
                                67.99560546874997
                            ],
                            [
                                44.04804687500004,
                                68.54882812499997
                            ],
                            [
                                45.891992187499994,
                                68.47968750000001
                            ],
                            [
                                46.69042968750003,
                                67.84882812500001
                            ],
                            [
                                45.52871093750005,
                                67.75756835937497
                            ],
                            [
                                44.93945312499997,
                                67.47744140625002
                            ],
                            [
                                45.98603515625004,
                                66.853125
                            ],
                            [
                                46.690820312499994,
                                66.82553710937498
                            ],
                            [
                                47.70908203125006,
                                67.04501953125003
                            ],
                            [
                                47.87470703125004,
                                67.58417968749998
                            ],
                            [
                                48.87792968749997,
                                67.73134765625005
                            ],
                            [
                                50.83886718749997,
                                68.34995117187503
                            ],
                            [
                                52.550097656250074,
                                68.59243164062497
                            ],
                            [
                                53.80195312500004,
                                68.99589843750002
                            ],
                            [
                                53.967871093750006,
                                68.22734375000002
                            ],
                            [
                                54.5612304687501,
                                68.273046875
                            ],
                            [
                                56.04365234375004,
                                68.64887695312501
                            ],
                            [
                                57.126855468749994,
                                68.55400390625005
                            ],
                            [
                                58.91894531249997,
                                69.00380859375002
                            ],
                            [
                                59.897363281249994,
                                68.42192382812505
                            ],
                            [
                                60.93359374999997,
                                68.98676757812501
                            ],
                            [
                                60.17060546875004,
                                69.59091796875
                            ],
                            [
                                61.01591796875002,
                                69.85146484374997
                            ],
                            [
                                63.36142578125006,
                                69.67529296875003
                            ],
                            [
                                68.50419921875007,
                                68.34843749999999
                            ],
                            [
                                69.14052734375005,
                                68.95063476562501
                            ],
                            [
                                68.54277343750002,
                                68.96708984374999
                            ],
                            [
                                68.00585937499997,
                                69.48002929687505
                            ],
                            [
                                67.06445312500003,
                                69.69370117187498
                            ],
                            [
                                66.92636718750006,
                                70.0142578125
                            ],
                            [
                                67.28476562500006,
                                70.73872070312498
                            ],
                            [
                                66.66611328125006,
                                70.90058593750001
                            ],
                            [
                                66.91757812500006,
                                71.28237304687497
                            ],
                            [
                                68.2692382812501,
                                71.68281250000001
                            ],
                            [
                                69.03906249999997,
                                72.66992187500003
                            ],
                            [
                                69.64511718750006,
                                72.89755859375006
                            ],
                            [
                                71.5001953125001,
                                72.91367187500003
                            ],
                            [
                                72.812109375,
                                72.69140624999997
                            ],
                            [
                                72.62441406250005,
                                72.079443359375
                            ],
                            [
                                71.91201171875005,
                                71.54794921874998
                            ],
                            [
                                72.73164062500004,
                                70.82285156249998
                            ],
                            [
                                72.52968750000005,
                                70.172509765625
                            ],
                            [
                                72.52734374999997,
                                69.08051757812501
                            ],
                            [
                                73.5480468750001,
                                68.57451171875005
                            ],
                            [
                                73.15214843750002,
                                67.86503906250002
                            ],
                            [
                                72.59433593750006,
                                67.58696289062502
                            ],
                            [
                                71.551171875,
                                66.76044921874995
                            ],
                            [
                                70.93945312499997,
                                66.54814453125005
                            ],
                            [
                                69.8771484375001,
                                66.84545898437506
                            ],
                            [
                                69.19433593749997,
                                66.57866210937505
                            ],
                            [
                                70.33945312500006,
                                66.34238281250006
                            ],
                            [
                                71.91699218749997,
                                66.24672851562502
                            ],
                            [
                                72.4173828125,
                                66.56079101562506
                            ],
                            [
                                73.79208984375,
                                66.99531250000001
                            ],
                            [
                                74.07451171875007,
                                67.41411132812499
                            ],
                            [
                                74.76953124999997,
                                67.76635742187497
                            ],
                            [
                                74.39140625000007,
                                68.42060546874995
                            ],
                            [
                                74.57958984375003,
                                68.751220703125
                            ],
                            [
                                76.45917968750004,
                                68.97827148437497
                            ],
                            [
                                77.2384765625001,
                                68.46958007812498
                            ],
                            [
                                77.65068359375007,
                                68.90302734375001
                            ],
                            [
                                76.00097656249997,
                                69.23505859374998
                            ],
                            [
                                74.81484375,
                                69.09057617187503
                            ],
                            [
                                73.77568359375002,
                                69.19824218750003
                            ],
                            [
                                73.56015625,
                                69.70722656249998
                            ],
                            [
                                74.3109375,
                                70.65361328125005
                            ],
                            [
                                73.08623046875007,
                                71.44492187500006
                            ],
                            [
                                73.67177734375,
                                71.84506835937503
                            ],
                            [
                                75.74140625000004,
                                72.29624023437503
                            ],
                            [
                                76.03242187500004,
                                71.91040039062503
                            ],
                            [
                                78.4826171875001,
                                72.39497070312498
                            ],
                            [
                                79.4220703125001,
                                72.38076171875002
                            ],
                            [
                                80.76250000000002,
                                72.08916015625002
                            ],
                            [
                                81.51123046874997,
                                71.74614257812505
                            ],
                            [
                                82.98613281250007,
                                71.74868164062502
                            ],
                            [
                                82.18359375000003,
                                72.23754882812497
                            ],
                            [
                                80.82705078125005,
                                72.48828124999997
                            ],
                            [
                                80.50966796875005,
                                73.086083984375
                            ],
                            [
                                80.5832031250001,
                                73.56845703125003
                            ],
                            [
                                83.54472656250002,
                                73.66650390625003
                            ],
                            [
                                86.59140625000006,
                                73.89428710937497
                            ],
                            [
                                86.65146484375012,
                                74.68242187500005
                            ],
                            [
                                87.67138671874997,
                                75.12958984375004
                            ],
                            [
                                90.18496093750005,
                                75.59106445312497
                            ],
                            [
                                93.54980468750003,
                                75.8541015625
                            ],
                            [
                                94.57558593750005,
                                76.1517578125
                            ],
                            [
                                96.87919921875002,
                                75.93105468749997
                            ],
                            [
                                100.32236328125012,
                                76.479150390625
                            ],
                            [
                                100.98994140625004,
                                76.99047851562497
                            ],
                            [
                                103.13144531250012,
                                77.62646484375006
                            ],
                            [
                                104.01455078125,
                                77.73041992187501
                            ],
                            [
                                105.89453125000003,
                                77.4888671875
                            ],
                            [
                                104.91191406250002,
                                77.17470703125002
                            ],
                            [
                                107.42978515625006,
                                76.92656250000002
                            ],
                            [
                                108.02792968750006,
                                76.71845703124998
                            ],
                            [
                                111.11484375000012,
                                76.72304687500002
                            ],
                            [
                                112.61953125,
                                76.38354492187506
                            ],
                            [
                                113.87099609375005,
                                75.85600585937505
                            ],
                            [
                                113.61357421875007,
                                75.29296875
                            ],
                            [
                                111.86826171875006,
                                74.74003906250007
                            ],
                            [
                                109.84033203124997,
                                74.32197265624998
                            ],
                            [
                                108.19951171875002,
                                73.69409179687497
                            ],
                            [
                                107.27109375000006,
                                73.62104492187501
                            ],
                            [
                                106.47792968750005,
                                73.13940429687503
                            ],
                            [
                                107.750390625,
                                73.17314453125007
                            ],
                            [
                                109.85527343750002,
                                73.47246093750002
                            ],
                            [
                                110.2614257812501,
                                74.01743164062503
                            ],
                            [
                                112.14726562500007,
                                73.70893554687498
                            ],
                            [
                                114.06054687500003,
                                73.58466796874995
                            ],
                            [
                                115.33769531250007,
                                73.70258789062501
                            ],
                            [
                                118.45019531249997,
                                73.58979492187504
                            ],
                            [
                                118.43027343750012,
                                73.24653320312501
                            ],
                            [
                                119.750390625,
                                72.97910156250006
                            ],
                            [
                                123.1603515625001,
                                72.95488281250002
                            ],
                            [
                                123.62226562500004,
                                73.19326171875
                            ],
                            [
                                123.305078125,
                                73.53291015624998
                            ],
                            [
                                124.3880859375,
                                73.75483398437498
                            ],
                            [
                                125.61708984375,
                                73.52060546874998
                            ],
                            [
                                127.74033203125012,
                                73.48154296875
                            ],
                            [
                                128.88867187500003,
                                73.19023437499999
                            ],
                            [
                                129.41171875000006,
                                72.31547851562505
                            ],
                            [
                                128.3587890625,
                                72.08833007812504
                            ],
                            [
                                129.76191406250004,
                                71.11953125000002
                            ],
                            [
                                131.15742187500004,
                                70.74218749999997
                            ],
                            [
                                131.56201171875003,
                                70.901025390625
                            ],
                            [
                                132.56230468750007,
                                71.89531250000005
                            ],
                            [
                                134.10283203125002,
                                71.37895507812502
                            ],
                            [
                                135.88476562499997,
                                71.63056640625004
                            ],
                            [
                                137.31542968750003,
                                71.35942382812505
                            ],
                            [
                                138.11845703125002,
                                71.56616210937497
                            ],
                            [
                                139.69511718750007,
                                71.70043945312497
                            ],
                            [
                                139.60117187500012,
                                72.49609374999997
                            ],
                            [
                                142.06142578125005,
                                72.72080078125
                            ],
                            [
                                145.4857421875,
                                72.54208984375
                            ],
                            [
                                146.59414062500005,
                                72.30244140624995
                            ],
                            [
                                148.40205078125004,
                                72.31196289062504
                            ],
                            [
                                149.50156250000012,
                                72.16430664062497
                            ],
                            [
                                150.59980468750004,
                                71.5201171875
                            ],
                            [
                                151.58242187500005,
                                71.28696289062503
                            ],
                            [
                                152.50878906250003,
                                70.83447265625003
                            ],
                            [
                                153.79414062500004,
                                70.87998046874998
                            ],
                            [
                                155.8952148437501,
                                71.09550781250002
                            ],
                            [
                                158.03701171875005,
                                71.03925781250001
                            ],
                            [
                                159.35068359375006,
                                70.79072265625001
                            ],
                            [
                                160.00644531250006,
                                70.30966796875006
                            ],
                            [
                                159.83251953125003,
                                69.78496093749999
                            ],
                            [
                                161.53691406250002,
                                69.379541015625
                            ],
                            [
                                162.37568359375004,
                                69.64907226562502
                            ],
                            [
                                164.1595703125,
                                69.71928710937505
                            ],
                            [
                                164.51328125000006,
                                69.60913085937497
                            ],
                            [
                                166.82031250000003,
                                69.49956054687505
                            ],
                            [
                                167.62812500000004,
                                69.74033203125006
                            ],
                            [
                                168.30302734375002,
                                69.27148437500003
                            ],
                            [
                                169.31064453125006,
                                69.07954101562498
                            ],
                            [
                                169.60986328124997,
                                68.78603515624997
                            ],
                            [
                                170.53759765624997,
                                68.82539062500001
                            ],
                            [
                                170.99541015625002,
                                69.04531250000005
                            ],
                            [
                                170.58222656250004,
                                69.58334960937506
                            ],
                            [
                                170.48681640625003,
                                70.107568359375
                            ],
                            [
                                172.55957031249997,
                                69.96835937499998
                            ],
                            [
                                173.27744140625006,
                                69.823828125
                            ],
                            [
                                176.10751953125018,
                                69.86030273437498
                            ],
                            [
                                176.92441406250018,
                                69.64599609375003
                            ],
                            [
                                178.84833984375004,
                                69.38720703124997
                            ],
                            [
                                180.00000000000003,
                                68.98344726562505
                            ],
                            [
                                180.00000000000003,
                                65.06723632812498
                            ],
                            [
                                178.51953125000003,
                                64.60297851562498
                            ],
                            [
                                177.46718750000005,
                                64.73681640624997
                            ],
                            [
                                177.43291015625002,
                                64.44448242187502
                            ],
                            [
                                178.65029296875005,
                                63.96528320312501
                            ],
                            [
                                178.7064453125001,
                                63.52153320312502
                            ],
                            [
                                179.33232421875007,
                                63.19018554687503
                            ],
                            [
                                179.5705078125001,
                                62.6875
                            ],
                            [
                                179.12070312500012,
                                62.32036132812499
                            ],
                            [
                                177.3512695312501,
                                62.587451171875045
                            ],
                            [
                                175.26787109375002,
                                62.102392578125006
                            ],
                            [
                                174.51435546875015,
                                61.823632812499966
                            ],
                            [
                                173.6234375,
                                61.716064453125
                            ],
                            [
                                172.85654296875006,
                                61.469189453124955
                            ],
                            [
                                172.39277343750004,
                                61.061767578125
                            ],
                            [
                                170.60820312500007,
                                60.434912109375034
                            ],
                            [
                                169.9826171875001,
                                60.067089843749955
                            ],
                            [
                                169.2267578125001,
                                60.59594726562497
                            ],
                            [
                                168.13750000000007,
                                60.57392578125001
                            ],
                            [
                                166.9640625000001,
                                60.307031249999994
                            ],
                            [
                                166.35214843750006,
                                60.48481445312498
                            ],
                            [
                                165.08457031250006,
                                60.09858398437498
                            ],
                            [
                                163.78007812500007,
                                60.041113281250006
                            ],
                            [
                                163.40996093750007,
                                59.8349609375
                            ],
                            [
                                163.27285156250005,
                                59.302587890625006
                            ],
                            [
                                162.14160156249997,
                                58.44741210937502
                            ],
                            [
                                161.96005859375012,
                                58.07690429687506
                            ],
                            [
                                162.4669921875001,
                                57.766210937500006
                            ],
                            [
                                163.21386718750003,
                                57.68681640624999
                            ],
                            [
                                162.77929687500003,
                                57.35761718749998
                            ],
                            [
                                162.80263671875,
                                56.81147460937501
                            ],
                            [
                                163.2565429687501,
                                56.68803710937499
                            ],
                            [
                                163.33554687500012,
                                56.232519531250006
                            ],
                            [
                                162.52822265625005,
                                56.260693359374955
                            ],
                            [
                                162.08496093749997,
                                56.08964843750002
                            ],
                            [
                                161.72939453125,
                                55.358007812500006
                            ],
                            [
                                162.10556640625006,
                                54.75214843750004
                            ],
                            [
                                161.72568359375006,
                                54.53295898437506
                            ],
                            [
                                160.77265625000004,
                                54.54135742187498
                            ],
                            [
                                160.0744140625001,
                                54.18916015625001
                            ],
                            [
                                159.84374999999997,
                                53.78364257812498
                            ],
                            [
                                159.89765625000004,
                                53.380761718749966
                            ],
                            [
                                158.6087890625,
                                52.873632812500034
                            ],
                            [
                                158.46347656250006,
                                52.30498046875002
                            ],
                            [
                                158.10351562500003,
                                51.80961914062499
                            ],
                            [
                                156.74775390625004,
                                50.969287109375045
                            ],
                            [
                                156.500390625,
                                51.47509765625006
                            ],
                            [
                                156.36474609374997,
                                52.509375000000006
                            ],
                            [
                                156.0988281250001,
                                53.00649414062502
                            ],
                            [
                                155.62031250000004,
                                54.86455078125002
                            ],
                            [
                                155.5548828125001,
                                55.348486328125034
                            ],
                            [
                                155.98251953125012,
                                56.69521484375002
                            ],
                            [
                                156.72841796875,
                                57.152246093749966
                            ],
                            [
                                157.4503906250001,
                                57.79926757812498
                            ],
                            [
                                158.21044921875003,
                                58.02529296875002
                            ],
                            [
                                159.03691406250002,
                                58.423925781250034
                            ],
                            [
                                159.8473632812501,
                                59.127148437499955
                            ],
                            [
                                161.75351562500012,
                                60.15229492187501
                            ],
                            [
                                162.0036132812501,
                                60.42016601562503
                            ],
                            [
                                162.97314453124997,
                                60.782910156249955
                            ],
                            [
                                163.70996093749997,
                                60.916796875000045
                            ],
                            [
                                164.00546875000006,
                                61.34379882812499
                            ],
                            [
                                164.25566406250007,
                                62.69658203124999
                            ],
                            [
                                163.33173828125004,
                                62.550927734374994
                            ],
                            [
                                163.00927734374997,
                                61.79150390625
                            ],
                            [
                                162.39257812500003,
                                61.662109375
                            ],
                            [
                                160.76660156249997,
                                60.753320312499966
                            ],
                            [
                                159.88310546875002,
                                61.291796875000074
                            ],
                            [
                                160.31738281250003,
                                61.79335937500002
                            ],
                            [
                                159.55234375000012,
                                61.71948242187497
                            ],
                            [
                                159.07666015625003,
                                61.922265624999994
                            ],
                            [
                                158.07011718750002,
                                61.75361328125001
                            ],
                            [
                                157.46933593750012,
                                61.798925781250006
                            ],
                            [
                                156.68027343750006,
                                61.480615234374994
                            ],
                            [
                                156.62968750000002,
                                61.27246093750003
                            ],
                            [
                                155.85332031250007,
                                60.77714843750002
                            ],
                            [
                                154.97080078125012,
                                60.376660156249955
                            ],
                            [
                                154.29306640625006,
                                59.833349609375034
                            ],
                            [
                                154.58251953124997,
                                59.540087890625074
                            ],
                            [
                                155.16669921875004,
                                59.36015624999999
                            ],
                            [
                                154.01093750000004,
                                59.075537109375006
                            ],
                            [
                                153.36113281250002,
                                59.214794921874955
                            ],
                            [
                                152.8822265625,
                                58.939062500000006
                            ],
                            [
                                151.99003906250002,
                                59.160058593749994
                            ],
                            [
                                151.48574218750005,
                                59.52412109375001
                            ],
                            [
                                150.45722656250004,
                                59.590722656249994
                            ],
                            [
                                149.64257812499997,
                                59.770410156249994
                            ],
                            [
                                149.20498046875,
                                59.488183593749966
                            ],
                            [
                                147.87460937500006,
                                59.388037109375006
                            ],
                            [
                                147.51445312500002,
                                59.2685546875
                            ],
                            [
                                146.44433593749997,
                                59.43046875000002
                            ],
                            [
                                145.93164062499997,
                                59.198388671874994
                            ],
                            [
                                145.55458984375,
                                59.413525390624955
                            ],
                            [
                                143.19218750000002,
                                59.3701171875
                            ],
                            [
                                142.02539062499997,
                                58.99965820312502
                            ],
                            [
                                141.75468750000007,
                                58.745263671874966
                            ],
                            [
                                140.6849609375,
                                58.21215820312497
                            ],
                            [
                                140.44687500000006,
                                57.81367187499998
                            ],
                            [
                                140.00234375000005,
                                57.68750000000003
                            ],
                            [
                                138.66210937500003,
                                56.96552734375004
                            ],
                            [
                                137.69150390625006,
                                56.13935546875004
                            ],
                            [
                                136.1751953125,
                                55.35224609374998
                            ],
                            [
                                135.26250000000002,
                                54.94331054687498
                            ],
                            [
                                135.85156249999997,
                                54.583935546874955
                            ],
                            [
                                136.797265625,
                                54.62099609375005
                            ],
                            [
                                136.68300781250005,
                                53.93129882812505
                            ],
                            [
                                137.15537109375012,
                                53.82167968750002
                            ],
                            [
                                137.3283203125001,
                                53.538964843749966
                            ],
                            [
                                138.29218750000004,
                                53.59243164062502
                            ],
                            [
                                138.66074218750012,
                                53.744775390624966
                            ],
                            [
                                138.695703125,
                                54.32001953125001
                            ],
                            [
                                139.707421875,
                                54.27714843749999
                            ],
                            [
                                140.3470703125,
                                53.812597656250034
                            ],
                            [
                                141.4020507812501,
                                53.18398437500002
                            ],
                            [
                                141.16982421875005,
                                52.36840820312497
                            ],
                            [
                                141.48525390625,
                                52.17851562500002
                            ],
                            [
                                140.68769531250004,
                                51.23227539062506
                            ],
                            [
                                140.47636718750007,
                                50.545996093750034
                            ],
                            [
                                140.51718750000012,
                                49.59614257812498
                            ],
                            [
                                140.17060546875004,
                                48.52368164062497
                            ],
                            [
                                139.37265625000006,
                                47.88735351562502
                            ],
                            [
                                139.00136718750005,
                                47.38330078125
                            ],
                            [
                                138.58681640625005,
                                47.057226562500006
                            ],
                            [
                                138.33691406250003,
                                46.543408203124955
                            ],
                            [
                                137.68544921875,
                                45.81835937500003
                            ],
                            [
                                136.80351562500002,
                                45.171142578125
                            ],
                            [
                                136.14228515625004,
                                44.489111328125034
                            ],
                            [
                                135.87460937500012,
                                44.37353515625003
                            ],
                            [
                                135.1310546875001,
                                43.52573242187506
                            ],
                            [
                                133.709375,
                                42.82993164062506
                            ],
                            [
                                133.15996093750007,
                                42.69697265624998
                            ],
                            [
                                132.30380859375006,
                                42.88330078125
                            ],
                            [
                                131.72207031250005,
                                43.20263671875006
                            ],
                            [
                                131.15830078125012,
                                42.62602539062499
                            ],
                            [
                                130.68730468750007,
                                42.30253906249999
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                102.88476562499997,
                                79.25395507812505
                            ],
                            [
                                105.14599609375003,
                                78.81884765625006
                            ],
                            [
                                105.31259765625012,
                                78.49990234375
                            ],
                            [
                                103.71933593750006,
                                78.25825195312498
                            ],
                            [
                                101.20410156249997,
                                78.19194335937505
                            ],
                            [
                                100.28398437500002,
                                78.67919921875003
                            ],
                            [
                                101.59062500000002,
                                79.350439453125
                            ],
                            [
                                102.88476562499997,
                                79.25395507812505
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                97.67451171875004,
                                80.15825195312499
                            ],
                            [
                                100.0612304687501,
                                79.77709960937506
                            ],
                            [
                                99.43955078125012,
                                78.834228515625
                            ],
                            [
                                97.55546875000002,
                                78.82656250000002
                            ],
                            [
                                94.65234375000003,
                                79.12749023437505
                            ],
                            [
                                93.07080078124997,
                                79.49531250000001
                            ],
                            [
                                94.98730468749997,
                                80.096826171875
                            ],
                            [
                                97.67451171875004,
                                80.15825195312499
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                62.167773437500074,
                                80.83476562500005
                            ],
                            [
                                61.05126953124997,
                                80.418603515625
                            ],
                            [
                                59.30439453125004,
                                80.52153320312502
                            ],
                            [
                                59.59228515625003,
                                80.81650390624998
                            ],
                            [
                                62.167773437500074,
                                80.83476562500005
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                54.71894531250004,
                                81.11596679687497
                            ],
                            [
                                57.69414062500002,
                                80.79228515625002
                            ],
                            [
                                55.71250000000006,
                                80.63730468749998
                            ],
                            [
                                54.06660156250004,
                                80.81362304687502
                            ],
                            [
                                54.71894531250004,
                                81.11596679687497
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                96.52656250000004,
                                81.0755859375
                            ],
                            [
                                97.83183593750002,
                                80.79829101562498
                            ],
                            [
                                97.1751953125,
                                80.24101562500007
                            ],
                            [
                                94.56503906250012,
                                80.12607421875
                            ],
                            [
                                93.87236328125002,
                                80.010107421875
                            ],
                            [
                                91.52382812500005,
                                80.35854492187502
                            ],
                            [
                                93.26250000000002,
                                80.79125976562497
                            ],
                            [
                                93.06513671875004,
                                80.98847656250001
                            ],
                            [
                                95.80068359375005,
                                81.28046874999998
                            ],
                            [
                                96.52656250000004,
                                81.0755859375
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Russia',
                'childNum': 22
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            30.50996093750001,
                            -1.0672851562500085
                        ],
                        [
                            30.47705078125,
                            -1.0830078125
                        ],
                        [
                            30.47021484375,
                            -1.1311523437500028
                        ],
                        [
                            30.508105468750017,
                            -1.2082031250000114
                        ],
                        [
                            30.631933593750006,
                            -1.3674804687500028
                        ],
                        [
                            30.710742187500017,
                            -1.3967773437500028
                        ],
                        [
                            30.76220703125,
                            -1.4586914062500114
                        ],
                        [
                            30.812597656250006,
                            -1.5630859375000057
                        ],
                        [
                            30.827539062500023,
                            -1.6936523437500028
                        ],
                        [
                            30.806738281250006,
                            -1.8506835937500057
                        ],
                        [
                            30.819140625000017,
                            -1.9674804687500114
                        ],
                        [
                            30.828710937500006,
                            -2.3384765625000057
                        ],
                        [
                            30.762500000000017,
                            -2.3716796875000057
                        ],
                        [
                            30.71484375,
                            -2.3634765625000114
                        ],
                        [
                            30.656640625000023,
                            -2.373828125000003
                        ],
                        [
                            30.593359375000006,
                            -2.396777343750003
                        ],
                        [
                            30.553613281250023,
                            -2.4000976562500114
                        ],
                        [
                            29.93017578125,
                            -2.3395507812500114
                        ],
                        [
                            29.8681640625,
                            -2.7164062500000057
                        ],
                        [
                            29.390234375000006,
                            -2.80859375
                        ],
                        [
                            29.01435546875001,
                            -2.72021484375
                        ],
                        [
                            28.876367187500023,
                            -2.4002929687500085
                        ],
                        [
                            29.1064453125,
                            -2.233203125000003
                        ],
                        [
                            29.12939453125,
                            -1.8602539062500085
                        ],
                        [
                            29.576953125000017,
                            -1.3878906250000114
                        ],
                        [
                            30.1015625,
                            -1.36865234375
                        ],
                        [
                            30.360253906250023,
                            -1.0746093750000085
                        ],
                        [
                            30.41230468750001,
                            -1.0630859375000057
                        ],
                        [
                            30.46992187500001,
                            -1.0660156250000057
                        ],
                        [
                            30.50996093750001,
                            -1.0672851562500085
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Rwanda',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -8.683349609375,
                            27.285937499999996
                        ],
                        [
                            -8.682324218749983,
                            26.497705078124994
                        ],
                        [
                            -8.68212890625,
                            26.273193359375
                        ],
                        [
                            -8.68212890625,
                            26.10947265625
                        ],
                        [
                            -8.682226562499977,
                            25.995507812499994
                        ],
                        [
                            -9.258203124999994,
                            25.995507812499994
                        ],
                        [
                            -9.817187499999989,
                            25.99545898437499
                        ],
                        [
                            -10.376123046874994,
                            25.99545898437499
                        ],
                        [
                            -12.016308593749983,
                            25.995410156250003
                        ],
                        [
                            -12.016308593749983,
                            25.740136718749994
                        ],
                        [
                            -12.016308593749983,
                            25.331689453124994
                        ],
                        [
                            -12.016308593749983,
                            24.923242187499994
                        ],
                        [
                            -12.016308593749983,
                            24.378662109375
                        ],
                        [
                            -12.016308593749983,
                            23.97021484375
                        ],
                        [
                            -12.0234375,
                            23.467578125000003
                        ],
                        [
                            -12.372900390624977,
                            23.318017578124994
                        ],
                        [
                            -13.031494140625,
                            23.000244140625
                        ],
                        [
                            -13.120898437499989,
                            22.884082031250003
                        ],
                        [
                            -13.16650390625,
                            22.753222656250003
                        ],
                        [
                            -13.155957031249983,
                            22.68930664062499
                        ],
                        [
                            -13.107324218749994,
                            22.560742187499997
                        ],
                        [
                            -13.094335937499977,
                            22.495996093749994
                        ],
                        [
                            -13.051220703124983,
                            21.854785156250003
                        ],
                        [
                            -13.041748046875,
                            21.713818359374997
                        ],
                        [
                            -13.0322265625,
                            21.572070312500003
                        ],
                        [
                            -13.025097656249983,
                            21.466796875
                        ],
                        [
                            -13.016210937499977,
                            21.333935546874997
                        ],
                        [
                            -15.231201171875,
                            21.331298828125
                        ],
                        [
                            -16.964550781249983,
                            21.329248046874994
                        ],
                        [
                            -17.06396484375,
                            20.898828124999994
                        ],
                        [
                            -17.003076171874994,
                            21.420703125000003
                        ],
                        [
                            -16.951123046874983,
                            21.43027343749999
                        ],
                        [
                            -16.73095703125,
                            21.47031249999999
                        ],
                        [
                            -15.750927734374983,
                            21.49082031249999
                        ],
                        [
                            -15.610791015624983,
                            21.47031249999999
                        ],
                        [
                            -15.4609375,
                            21.45078124999999
                        ],
                        [
                            -15.15087890625,
                            21.44101562499999
                        ],
                        [
                            -14.971142578124983,
                            21.44101562499999
                        ],
                        [
                            -14.8408203125,
                            21.45078124999999
                        ],
                        [
                            -14.7509765625,
                            21.50058593749999
                        ],
                        [
                            -14.670849609374983,
                            21.60019531249999
                        ],
                        [
                            -14.52099609375,
                            21.990869140624994
                        ],
                        [
                            -14.22119140625,
                            22.31015624999999
                        ],
                        [
                            -14.1708984375,
                            22.76035156249999
                        ],
                        [
                            -14.12109375,
                            22.96054687499999
                        ],
                        [
                            -14.10107421875,
                            23.10019531249999
                        ],
                        [
                            -14.02099609375,
                            23.41074218749999
                        ],
                        [
                            -13.89111328125,
                            23.69101562499999
                        ],
                        [
                            -13.840771484374983,
                            23.75058593749999
                        ],
                        [
                            -13.770947265624983,
                            23.79062499999999
                        ],
                        [
                            -13.661083984374983,
                            23.83066406249999
                        ],
                        [
                            -13.5810546875,
                            23.87070312499999
                        ],
                        [
                            -13.39111328125,
                            23.94101562499999
                        ],
                        [
                            -13.310986328124983,
                            23.98105468749999
                        ],
                        [
                            -13.06103515625,
                            24.40097656249999
                        ],
                        [
                            -12.991162109374983,
                            24.47031249999999
                        ],
                        [
                            -12.7109375,
                            24.63046874999999
                        ],
                        [
                            -12.630810546874983,
                            24.68027343749999
                        ],
                        [
                            -12.5009765625,
                            24.77011718749999
                        ],
                        [
                            -12.43115234375,
                            24.83066406249999
                        ],
                        [
                            -12.40087890625,
                            24.88046874999999
                        ],
                        [
                            -12.36083984375,
                            24.97031249999999
                        ],
                        [
                            -12.310986328124983,
                            25.11093749999999
                        ],
                        [
                            -12.03076171875,
                            26.030859375
                        ],
                        [
                            -11.880859375,
                            26.0708984375
                        ],
                        [
                            -11.7548828125,
                            26.0865234375
                        ],
                        [
                            -11.718212890624983,
                            26.1041015625
                        ],
                        [
                            -11.69921875,
                            26.1626953125
                        ],
                        [
                            -11.684521484374983,
                            26.2134765625
                        ],
                        [
                            -11.63720703125,
                            26.2955078125
                        ],
                        [
                            -11.337890625,
                            26.6333984375
                        ],
                        [
                            -11.316845703124983,
                            26.7447265625
                        ],
                        [
                            -11.361279296874983,
                            26.7935546875
                        ],
                        [
                            -11.392578125,
                            26.8833984375
                        ],
                        [
                            -10.922802734374983,
                            27.0103515625
                        ],
                        [
                            -10.25146484375,
                            26.8609375
                        ],
                        [
                            -9.7353515625,
                            26.8609375
                        ],
                        [
                            -9.413037109374983,
                            27.0884765625
                        ],
                        [
                            -9.001904296874983,
                            27.0904296875
                        ],
                        [
                            -8.794873046874983,
                            27.120703125
                        ],
                        [
                            -8.753857421874983,
                            27.1509765625
                        ],
                        [
                            -8.753857421874983,
                            27.191015625
                        ],
                        [
                            -8.802685546874983,
                            27.3609375
                        ],
                        [
                            -8.817822265624983,
                            27.656445312499997
                        ],
                        [
                            -8.683349609375,
                            27.656445312499997
                        ],
                        [
                            -8.683349609375,
                            27.285937499999996
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Western Sahara',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            46.53144531250004,
                            29.09624023437499
                        ],
                        [
                            47.433203125000006,
                            28.989550781250017
                        ],
                        [
                            47.671289062499994,
                            28.53315429687504
                        ],
                        [
                            48.442480468750006,
                            28.542919921874983
                        ],
                        [
                            48.90644531250004,
                            27.62905273437505
                        ],
                        [
                            49.40527343749997,
                            27.18095703124996
                        ],
                        [
                            49.986132812500074,
                            26.828906250000017
                        ],
                        [
                            50.21386718750003,
                            26.30849609375005
                        ],
                        [
                            50.031640624999994,
                            26.11098632812505
                        ],
                        [
                            50.55791015625002,
                            25.086669921875
                        ],
                        [
                            50.66689453125005,
                            24.96381835937501
                        ],
                        [
                            50.72558593749997,
                            24.869384765625057
                        ],
                        [
                            50.80439453125004,
                            24.789257812499983
                        ],
                        [
                            50.85566406250004,
                            24.67963867187501
                        ],
                        [
                            50.928320312500006,
                            24.595117187500023
                        ],
                        [
                            50.96601562500004,
                            24.573925781249983
                        ],
                        [
                            51.56835937500003,
                            24.286181640625074
                        ],
                        [
                            51.592578125000074,
                            24.07885742187503
                        ],
                        [
                            52.55507812500005,
                            22.932812499999955
                        ],
                        [
                            55.104296875000074,
                            22.621484375000023
                        ],
                        [
                            55.185839843750074,
                            22.7041015625
                        ],
                        [
                            55.641015624999994,
                            22.001855468749994
                        ],
                        [
                            54.97734375000002,
                            19.995947265625006
                        ],
                        [
                            53.66689453125005,
                            19.559130859375017
                        ],
                        [
                            51.977636718750006,
                            18.996142578125074
                        ],
                        [
                            49.04199218750003,
                            18.58178710937503
                        ],
                        [
                            48.17216796875002,
                            18.156933593749983
                        ],
                        [
                            47.57958984374997,
                            17.448339843750034
                        ],
                        [
                            47.441796874999994,
                            17.111865234375045
                        ],
                        [
                            46.97568359375006,
                            16.953466796875034
                        ],
                        [
                            46.682031249999994,
                            17.2685546875
                        ],
                        [
                            46.31035156250002,
                            17.231298828125063
                        ],
                        [
                            45.40654296875002,
                            17.319775390624955
                        ],
                        [
                            45.14804687500006,
                            17.427441406249955
                        ],
                        [
                            44.1559570312501,
                            17.398535156250006
                        ],
                        [
                            43.91699218749997,
                            17.32470703124997
                        ],
                        [
                            43.41796875000003,
                            17.516259765625023
                        ],
                        [
                            43.19091796875003,
                            17.35937500000003
                        ],
                        [
                            43.16503906249997,
                            16.689404296874955
                        ],
                        [
                            42.79931640624997,
                            16.37177734375001
                        ],
                        [
                            42.69882812500006,
                            16.736962890624966
                        ],
                        [
                            42.38330078124997,
                            17.122460937499994
                        ],
                        [
                            42.29394531249997,
                            17.434960937499966
                        ],
                        [
                            41.75000000000003,
                            17.88574218749997
                        ],
                        [
                            41.22949218750003,
                            18.678417968749983
                        ],
                        [
                            41.116015625000074,
                            19.082177734374994
                        ],
                        [
                            40.75917968750005,
                            19.755468750000034
                        ],
                        [
                            40.080664062500006,
                            20.265917968750017
                        ],
                        [
                            39.613671874999994,
                            20.517675781249977
                        ],
                        [
                            39.093554687500074,
                            21.31035156249999
                        ],
                        [
                            38.98789062500006,
                            21.88173828125005
                        ],
                        [
                            39.09589843750004,
                            22.392773437499983
                        ],
                        [
                            38.9411132812501,
                            22.881835937500057
                        ],
                        [
                            38.46416015625002,
                            23.71186523437504
                        ],
                        [
                            37.91972656250002,
                            24.185400390625063
                        ],
                        [
                            37.54306640625006,
                            24.291650390625023
                        ],
                        [
                            37.18085937500004,
                            24.82001953125001
                        ],
                        [
                            37.148828124999994,
                            25.291113281249977
                        ],
                        [
                            36.76269531250003,
                            25.751318359375034
                        ],
                        [
                            36.24960937500006,
                            26.594775390625017
                        ],
                        [
                            35.581347656250074,
                            27.432470703125063
                        ],
                        [
                            35.18046875000002,
                            28.03486328125004
                        ],
                        [
                            34.61621093749997,
                            28.148339843749966
                        ],
                        [
                            34.779882812500006,
                            28.50732421875
                        ],
                        [
                            34.950781250000006,
                            29.353515625
                        ],
                        [
                            36.01542968750002,
                            29.190478515625017
                        ],
                        [
                            36.068457031250006,
                            29.200537109375006
                        ],
                        [
                            36.28281250000006,
                            29.355371093750023
                        ],
                        [
                            36.47607421874997,
                            29.49511718749997
                        ],
                        [
                            36.59179687500003,
                            29.666113281250006
                        ],
                        [
                            36.703906250000074,
                            29.831640624999977
                        ],
                        [
                            36.75527343750005,
                            29.86601562499996
                        ],
                        [
                            37.46923828125003,
                            29.995068359374955
                        ],
                        [
                            37.49072265625003,
                            30.01171874999997
                        ],
                        [
                            37.55361328125005,
                            30.14458007812496
                        ],
                        [
                            37.63359375000002,
                            30.313281250000045
                        ],
                        [
                            37.64990234374997,
                            30.330957031249994
                        ],
                        [
                            37.669726562500074,
                            30.34814453125003
                        ],
                        [
                            37.862890625000006,
                            30.44262695312503
                        ],
                        [
                            37.98007812500006,
                            30.49999999999997
                        ],
                        [
                            37.47900390624997,
                            31.007763671874955
                        ],
                        [
                            37.10527343750002,
                            31.35517578125004
                        ],
                        [
                            36.95859375000006,
                            31.491503906250017
                        ],
                        [
                            37.21562500000002,
                            31.55610351562501
                        ],
                        [
                            37.49335937500004,
                            31.625878906250023
                        ],
                        [
                            37.773828124999994,
                            31.69633789062499
                        ],
                        [
                            38.962304687499994,
                            31.99492187499999
                        ],
                        [
                            39.145410156249994,
                            32.12451171875
                        ],
                        [
                            39.36865234374997,
                            32.09174804687498
                        ],
                        [
                            39.70410156250003,
                            32.04252929687499
                        ],
                        [
                            40.02783203124997,
                            31.995019531249994
                        ],
                        [
                            40.3693359375001,
                            31.93896484375003
                        ],
                        [
                            42.07441406250004,
                            31.08037109374999
                        ],
                        [
                            42.857714843750074,
                            30.495214843749977
                        ],
                        [
                            44.69082031250005,
                            29.20234375000001
                        ],
                        [
                            46.35644531250003,
                            29.06367187500001
                        ],
                        [
                            46.53144531250004,
                            29.09624023437499
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Saudi Arabia',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            36.87138671875002,
                            21.996728515624994
                        ],
                        [
                            36.92695312500001,
                            21.58652343749999
                        ],
                        [
                            37.15058593750001,
                            21.103759765625
                        ],
                        [
                            37.2275390625,
                            20.55673828124999
                        ],
                        [
                            37.24843750000002,
                            19.58188476562499
                        ],
                        [
                            37.471289062500006,
                            18.820117187500003
                        ],
                        [
                            37.921875,
                            18.555908203125
                        ],
                        [
                            38.609472656250006,
                            18.005078124999997
                        ],
                        [
                            38.422460937500006,
                            17.823925781249997
                        ],
                        [
                            38.39716796875001,
                            17.778369140625003
                        ],
                        [
                            38.38554687500002,
                            17.751269531250003
                        ],
                        [
                            38.37373046875001,
                            17.717333984375003
                        ],
                        [
                            38.34736328125001,
                            17.68359375
                        ],
                        [
                            38.28984375000002,
                            17.637011718750003
                        ],
                        [
                            38.26728515625001,
                            17.61669921875
                        ],
                        [
                            38.253515625000006,
                            17.584765625000003
                        ],
                        [
                            37.78242187500001,
                            17.4580078125
                        ],
                        [
                            37.51015625000002,
                            17.288134765625003
                        ],
                        [
                            37.45292968750002,
                            17.108691406250003
                        ],
                        [
                            37.41103515625002,
                            17.061718749999997
                        ],
                        [
                            37.24882812500002,
                            17.056884765625
                        ],
                        [
                            37.169531250000006,
                            17.041406249999994
                        ],
                        [
                            37.0615234375,
                            17.061279296875
                        ],
                        [
                            37.00898437500001,
                            17.058886718750003
                        ],
                        [
                            36.995214843750006,
                            17.020556640625003
                        ],
                        [
                            36.97578125000001,
                            16.86655273437499
                        ],
                        [
                            36.97871093750001,
                            16.800585937500003
                        ],
                        [
                            36.887792968750006,
                            16.624658203124994
                        ],
                        [
                            36.91376953125001,
                            16.296191406250003
                        ],
                        [
                            36.566015625000006,
                            15.362109375000003
                        ],
                        [
                            36.4267578125,
                            15.132080078125
                        ],
                        [
                            36.44814453125002,
                            14.940087890624994
                        ],
                        [
                            36.470800781250006,
                            14.736474609374994
                        ],
                        [
                            36.52431640625002,
                            14.2568359375
                        ],
                        [
                            36.390625,
                            13.626074218749991
                        ],
                        [
                            36.21220703125002,
                            13.271093749999991
                        ],
                        [
                            36.107519531250006,
                            12.726464843749994
                        ],
                        [
                            35.59609375000002,
                            12.537304687499997
                        ],
                        [
                            35.1123046875,
                            11.816552734374994
                        ],
                        [
                            34.93144531250002,
                            10.864794921874989
                        ],
                        [
                            34.571875000000006,
                            10.880175781249989
                        ],
                        [
                            34.27568359375002,
                            10.528124999999989
                        ],
                        [
                            34.29150390625,
                            10.124755859375
                        ],
                        [
                            34.078125,
                            9.461523437499991
                        ],
                        [
                            33.892187500000006,
                            10.198974609375
                        ],
                        [
                            33.17216796875002,
                            10.850146484374989
                        ],
                        [
                            33.07304687500002,
                            11.591503906249997
                        ],
                        [
                            33.199316406250006,
                            12.21728515625
                        ],
                        [
                            32.73671875000002,
                            12.009667968749994
                        ],
                        [
                            32.072265625,
                            12.006738281249994
                        ],
                        [
                            32.33535156250002,
                            11.716015624999997
                        ],
                        [
                            32.40410156250002,
                            11.057763671874994
                        ],
                        [
                            31.933007812500023,
                            10.662499999999994
                        ],
                        [
                            31.654882812500006,
                            10.221142578124997
                        ],
                        [
                            31.224902343750017,
                            9.799267578124997
                        ],
                        [
                            30.739355468750006,
                            9.74267578125
                        ],
                        [
                            30.003027343750006,
                            10.277392578124989
                        ],
                        [
                            29.60546875,
                            10.065087890624994
                        ],
                        [
                            29.47314453125,
                            9.768603515624989
                        ],
                        [
                            28.999609375000006,
                            9.610156249999989
                        ],
                        [
                            28.844531250000017,
                            9.326074218749994
                        ],
                        [
                            28.048925781250006,
                            9.32861328125
                        ],
                        [
                            27.885839843750006,
                            9.599658203124989
                        ],
                        [
                            27.07421875,
                            9.613818359374989
                        ],
                        [
                            26.551367187500006,
                            9.525830078124997
                        ],
                        [
                            25.891503906250023,
                            10.202734374999991
                        ],
                        [
                            25.79804687500001,
                            10.420507812499991
                        ],
                        [
                            25.066992187500006,
                            10.293798828124991
                        ],
                        [
                            24.785253906250006,
                            9.774658203125
                        ],
                        [
                            24.53193359375001,
                            8.886914062499997
                        ],
                        [
                            24.147363281250023,
                            8.665624999999991
                        ],
                        [
                            23.53730468750001,
                            8.815820312499994
                        ],
                        [
                            23.646289062500017,
                            9.822900390624994
                        ],
                        [
                            23.312304687500017,
                            10.387939453125
                        ],
                        [
                            22.86005859375001,
                            10.919677734375
                        ],
                        [
                            22.922656250000017,
                            11.344873046874994
                        ],
                        [
                            22.55634765625001,
                            11.669531249999991
                        ],
                        [
                            22.352343750000017,
                            12.660449218749989
                        ],
                        [
                            21.825292968750006,
                            12.79052734375
                        ],
                        [
                            22.228125000000006,
                            13.32958984375
                        ],
                        [
                            22.10761718750001,
                            13.730322265624991
                        ],
                        [
                            22.50996093750001,
                            14.12744140625
                        ],
                        [
                            22.38154296875001,
                            14.550488281249997
                        ],
                        [
                            22.6708984375,
                            14.722460937500003
                        ],
                        [
                            22.93232421875001,
                            15.162109375
                        ],
                        [
                            23.10517578125001,
                            15.702539062499994
                        ],
                        [
                            23.9833984375,
                            15.780175781249994
                        ],
                        [
                            23.982226562500017,
                            17.266357421875
                        ],
                        [
                            23.980273437500017,
                            19.496630859375003
                        ],
                        [
                            23.980273437500017,
                            19.99594726562499
                        ],
                        [
                            24.9794921875,
                            20.002587890624994
                        ],
                        [
                            24.980273437500017,
                            21.995849609375
                        ],
                        [
                            28.036425781250017,
                            21.995361328125
                        ],
                        [
                            29.564550781250006,
                            21.9951171875
                        ],
                        [
                            31.434472656250023,
                            21.995849609375
                        ],
                        [
                            33.91845703125,
                            21.996240234374994
                        ],
                        [
                            36.87138671875002,
                            21.996728515624994
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Sudan',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            34.078125,
                            9.461523437499991
                        ],
                        [
                            34.09453125000002,
                            8.582226562499997
                        ],
                        [
                            33.95332031250001,
                            8.443505859374994
                        ],
                        [
                            33.23427734375002,
                            8.396386718749994
                        ],
                        [
                            33.01259765625002,
                            7.9515136718749915
                        ],
                        [
                            33.22597656250002,
                            7.760644531249994
                        ],
                        [
                            33.666113281250006,
                            7.6709960937499915
                        ],
                        [
                            34.06425781250002,
                            7.225732421874994
                        ],
                        [
                            34.71064453125001,
                            6.660302734374994
                        ],
                        [
                            35.08193359375002,
                            5.673144531249989
                        ],
                        [
                            35.26835937500002,
                            5.4922851562499915
                        ],
                        [
                            35.08447265625,
                            5.3118652343749915
                        ],
                        [
                            34.87832031250002,
                            5.109570312499997
                        ],
                        [
                            34.63984375000001,
                            4.87548828125
                        ],
                        [
                            34.38017578125002,
                            4.620654296874989
                        ],
                        [
                            34.176855468750006,
                            4.419091796874994
                        ],
                        [
                            33.97607421875,
                            4.22021484375
                        ],
                        [
                            33.74160156250002,
                            3.9852539062499943
                        ],
                        [
                            33.568457031250006,
                            3.811718749999997
                        ],
                        [
                            33.489355468750006,
                            3.755078124999997
                        ],
                        [
                            32.83808593750001,
                            3.7984863281249943
                        ],
                        [
                            32.33574218750002,
                            3.7062011718749943
                        ],
                        [
                            32.09941406250002,
                            3.5291992187499943
                        ],
                        [
                            31.79804687500001,
                            3.8026367187499943
                        ],
                        [
                            31.47998046875,
                            3.6804687499999886
                        ],
                        [
                            31.15234375,
                            3.785595703124997
                        ],
                        [
                            30.83857421875001,
                            3.49072265625
                        ],
                        [
                            30.50830078125,
                            3.835693359375
                        ],
                        [
                            30.194921875000006,
                            3.98193359375
                        ],
                        [
                            29.676855468750006,
                            4.5869140625
                        ],
                        [
                            29.151464843750006,
                            4.38818359375
                        ],
                        [
                            28.72705078125,
                            4.5049804687499915
                        ],
                        [
                            28.19208984375001,
                            4.350244140624994
                        ],
                        [
                            27.7880859375,
                            4.644677734374994
                        ],
                        [
                            27.4033203125,
                            5.109179687499989
                        ],
                        [
                            27.083398437500023,
                            5.77685546875
                        ],
                        [
                            26.514257812500006,
                            6.069238281249994
                        ],
                        [
                            26.36181640625,
                            6.635302734374989
                        ],
                        [
                            25.888964843750017,
                            7.06494140625
                        ],
                        [
                            25.380664062500017,
                            7.333398437499994
                        ],
                        [
                            25.247363281250017,
                            7.724560546874997
                        ],
                        [
                            24.853320312500017,
                            8.137548828124991
                        ],
                        [
                            24.291406250000023,
                            8.291406249999994
                        ],
                        [
                            24.147363281250023,
                            8.665624999999991
                        ],
                        [
                            24.53193359375001,
                            8.886914062499997
                        ],
                        [
                            24.785253906250006,
                            9.774658203125
                        ],
                        [
                            25.066992187500006,
                            10.293798828124991
                        ],
                        [
                            25.79804687500001,
                            10.420507812499991
                        ],
                        [
                            25.891503906250023,
                            10.202734374999991
                        ],
                        [
                            26.551367187500006,
                            9.525830078124997
                        ],
                        [
                            27.07421875,
                            9.613818359374989
                        ],
                        [
                            27.885839843750006,
                            9.599658203124989
                        ],
                        [
                            28.048925781250006,
                            9.32861328125
                        ],
                        [
                            28.844531250000017,
                            9.326074218749994
                        ],
                        [
                            28.999609375000006,
                            9.610156249999989
                        ],
                        [
                            29.47314453125,
                            9.768603515624989
                        ],
                        [
                            29.60546875,
                            10.065087890624994
                        ],
                        [
                            30.003027343750006,
                            10.277392578124989
                        ],
                        [
                            30.739355468750006,
                            9.74267578125
                        ],
                        [
                            31.224902343750017,
                            9.799267578124997
                        ],
                        [
                            31.654882812500006,
                            10.221142578124997
                        ],
                        [
                            31.933007812500023,
                            10.662499999999994
                        ],
                        [
                            32.40410156250002,
                            11.057763671874994
                        ],
                        [
                            32.33535156250002,
                            11.716015624999997
                        ],
                        [
                            32.072265625,
                            12.006738281249994
                        ],
                        [
                            32.73671875000002,
                            12.009667968749994
                        ],
                        [
                            33.199316406250006,
                            12.21728515625
                        ],
                        [
                            33.07304687500002,
                            11.591503906249997
                        ],
                        [
                            33.17216796875002,
                            10.850146484374989
                        ],
                        [
                            33.892187500000006,
                            10.198974609375
                        ],
                        [
                            34.078125,
                            9.461523437499991
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'South Sudan',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -12.280615234374977,
                            14.809033203124997
                        ],
                        [
                            -12.019189453124994,
                            14.206494140624997
                        ],
                        [
                            -12.05419921875,
                            13.633056640625
                        ],
                        [
                            -11.634960937499983,
                            13.369873046875
                        ],
                        [
                            -11.390380859375,
                            12.941992187499991
                        ],
                        [
                            -11.389404296875,
                            12.404394531249991
                        ],
                        [
                            -12.291210937499983,
                            12.328027343749994
                        ],
                        [
                            -13.082910156249994,
                            12.633544921875
                        ],
                        [
                            -13.40576171875,
                            12.662255859374994
                        ],
                        [
                            -13.729248046875,
                            12.673925781249991
                        ],
                        [
                            -14.064843749999994,
                            12.67529296875
                        ],
                        [
                            -14.349218749999977,
                            12.676416015624994
                        ],
                        [
                            -15.196093749999989,
                            12.679931640625
                        ],
                        [
                            -15.3779296875,
                            12.588964843749991
                        ],
                        [
                            -15.574804687499977,
                            12.490380859374994
                        ],
                        [
                            -15.839550781249983,
                            12.437890624999994
                        ],
                        [
                            -16.144189453124994,
                            12.457421874999994
                        ],
                        [
                            -16.41630859374999,
                            12.36767578125
                        ],
                        [
                            -16.521337890624977,
                            12.3486328125
                        ],
                        [
                            -16.656933593749983,
                            12.364355468749991
                        ],
                        [
                            -16.711816406249994,
                            12.354833984374991
                        ],
                        [
                            -16.763330078124994,
                            13.064160156249997
                        ],
                        [
                            -16.648779296874977,
                            13.154150390624991
                        ],
                        [
                            -15.834277343749989,
                            13.156445312499997
                        ],
                        [
                            -15.509667968749994,
                            13.586230468750003
                        ],
                        [
                            -16.56230468749999,
                            13.587304687499994
                        ],
                        [
                            -17.168066406249977,
                            14.640625
                        ],
                        [
                            -17.147167968749983,
                            14.922021484374994
                        ],
                        [
                            -16.570751953124983,
                            15.734423828125003
                        ],
                        [
                            -16.441015624999977,
                            16.204541015624997
                        ],
                        [
                            -16.168359374999994,
                            16.547070312499997
                        ],
                        [
                            -15.768212890624994,
                            16.485107421875
                        ],
                        [
                            -15.112646484374977,
                            16.644921874999994
                        ],
                        [
                            -14.300097656249989,
                            16.580273437499997
                        ],
                        [
                            -13.756640624999989,
                            16.172509765624994
                        ],
                        [
                            -13.40966796875,
                            16.05917968749999
                        ],
                        [
                            -13.105273437499989,
                            15.57177734375
                        ],
                        [
                            -12.858496093749977,
                            15.242529296874991
                        ],
                        [
                            -12.40869140625,
                            14.889013671874991
                        ],
                        [
                            -12.280615234374977,
                            14.809033203124997
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Senegal',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -37.10332031249996,
                            -54.06562500000004
                        ],
                        [
                            -36.32646484374996,
                            -54.251171875000004
                        ],
                        [
                            -35.79858398437497,
                            -54.76347656250002
                        ],
                        [
                            -36.08549804687499,
                            -54.86679687500001
                        ],
                        [
                            -37.10332031249996,
                            -54.06562500000004
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'United Kingdom',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                161.71533203124997,
                                -10.387304687499991
                            ],
                            [
                                162.10537109375005,
                                -10.45380859375004
                            ],
                            [
                                162.12363281250012,
                                -10.824414062499955
                            ],
                            [
                                161.53789062500007,
                                -10.566406249999972
                            ],
                            [
                                161.71533203124997,
                                -10.387304687499991
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                159.75039062500005,
                                -9.272656250000011
                            ],
                            [
                                159.97060546875,
                                -9.433300781249969
                            ],
                            [
                                160.35458984375006,
                                -9.421582031249983
                            ],
                            [
                                160.62548828124997,
                                -9.588867187499957
                            ],
                            [
                                160.64921875000002,
                                -9.92861328124998
                            ],
                            [
                                159.85371093750004,
                                -9.791503906250028
                            ],
                            [
                                159.62187500000007,
                                -9.532128906250023
                            ],
                            [
                                159.75039062500005,
                                -9.272656250000011
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                160.7494140625,
                                -8.313964843750014
                            ],
                            [
                                160.99765625000006,
                                -8.612011718749983
                            ],
                            [
                                161.2584960937501,
                                -9.316894531249972
                            ],
                            [
                                160.87343750000005,
                                -9.156835937499949
                            ],
                            [
                                160.59042968750006,
                                -8.372753906249997
                            ],
                            [
                                160.7494140625,
                                -8.313964843750014
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                159.8791015625001,
                                -8.534277343749949
                            ],
                            [
                                158.9440429687501,
                                -8.04072265625004
                            ],
                            [
                                159.10937500000003,
                                -7.903515624999969
                            ],
                            [
                                159.84306640625002,
                                -8.326953124999989
                            ],
                            [
                                159.8791015625001,
                                -8.534277343749949
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Solomon Islands',
                'childNum': 4
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -10.758593749999989,
                            9.385351562499991
                        ],
                        [
                            -10.500537109375017,
                            8.687548828125017
                        ],
                        [
                            -10.677343749999977,
                            8.400585937499997
                        ],
                        [
                            -10.570849609374932,
                            8.071142578125034
                        ],
                        [
                            -10.878076171874994,
                            7.538232421874994
                        ],
                        [
                            -11.267675781249977,
                            7.232617187499997
                        ],
                        [
                            -11.507519531249983,
                            6.906542968750003
                        ],
                        [
                            -11.733447265625017,
                            7.088574218750054
                        ],
                        [
                            -12.48564453124996,
                            7.3862792968750455
                        ],
                        [
                            -12.510449218749955,
                            7.66572265625004
                        ],
                        [
                            -12.88095703124992,
                            7.856640625000026
                        ],
                        [
                            -12.956933593749966,
                            8.145312500000045
                        ],
                        [
                            -13.272753906249989,
                            8.429736328124989
                        ],
                        [
                            -13.271630859374937,
                            8.987402343750006
                        ],
                        [
                            -13.292675781249955,
                            9.04921874999998
                        ],
                        [
                            -13.234228515624949,
                            9.070117187499974
                        ],
                        [
                            -13.129882812500028,
                            9.047558593749997
                        ],
                        [
                            -13.077294921874966,
                            9.069628906249974
                        ],
                        [
                            -13.028027343749955,
                            9.103564453125045
                        ],
                        [
                            -12.958789062499989,
                            9.263330078124994
                        ],
                        [
                            -12.557861328125,
                            9.704980468749994
                        ],
                        [
                            -12.142333984375,
                            9.875390624999994
                        ],
                        [
                            -11.911083984374955,
                            9.993017578124977
                        ],
                        [
                            -11.273632812499955,
                            9.996533203124983
                        ],
                        [
                            -11.205664062499949,
                            9.977734374999969
                        ],
                        [
                            -11.047460937499977,
                            9.786328125000054
                        ],
                        [
                            -10.758593749999989,
                            9.385351562499991
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Sierra Leone',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -89.36259765624999,
                            14.416015625
                        ],
                        [
                            -88.51254882812499,
                            13.978955078124997
                        ],
                        [
                            -88.504345703125,
                            13.964208984374991
                        ],
                        [
                            -88.49765624999999,
                            13.904541015625
                        ],
                        [
                            -88.482666015625,
                            13.854248046875
                        ],
                        [
                            -88.44912109375,
                            13.850976562499994
                        ],
                        [
                            -88.40849609374999,
                            13.875390624999994
                        ],
                        [
                            -88.27622070312499,
                            13.942675781250003
                        ],
                        [
                            -88.151025390625,
                            13.987353515625003
                        ],
                        [
                            -87.99101562499999,
                            13.879638671875
                        ],
                        [
                            -87.80224609375,
                            13.889990234374991
                        ],
                        [
                            -87.71533203125,
                            13.812695312499997
                        ],
                        [
                            -87.781884765625,
                            13.521386718749994
                        ],
                        [
                            -87.930859375,
                            13.1806640625
                        ],
                        [
                            -88.51201171874999,
                            13.183935546874991
                        ],
                        [
                            -89.27763671874999,
                            13.478076171875003
                        ],
                        [
                            -89.80419921875,
                            13.560107421875003
                        ],
                        [
                            -90.09521484375,
                            13.736523437499997
                        ],
                        [
                            -89.872705078125,
                            14.045605468749997
                        ],
                        [
                            -89.36259765624999,
                            14.416015625
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'El Salvador',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            48.938574218750006,
                            11.258447265624994
                        ],
                        [
                            48.938085937500006,
                            9.451757812499991
                        ],
                        [
                            47.97822265625001,
                            7.9970703125
                        ],
                        [
                            46.97822265625001,
                            7.9970703125
                        ],
                        [
                            44.02285156250002,
                            8.986035156249997
                        ],
                        [
                            43.482519531250006,
                            9.379492187499991
                        ],
                        [
                            43.181640625,
                            9.879980468749991
                        ],
                        [
                            42.84160156250002,
                            10.203076171874997
                        ],
                        [
                            42.65957031250002,
                            10.621386718749989
                        ],
                        [
                            42.92275390625002,
                            10.999316406249989
                        ],
                        [
                            43.24599609375002,
                            11.499804687499989
                        ],
                        [
                            43.85273437500001,
                            10.784277343749991
                        ],
                        [
                            44.38652343750002,
                            10.430224609374989
                        ],
                        [
                            44.942968750000006,
                            10.436718749999997
                        ],
                        [
                            45.81669921875002,
                            10.835888671874997
                        ],
                        [
                            46.565039062500006,
                            10.745996093749994
                        ],
                        [
                            47.40498046875001,
                            11.174023437499997
                        ],
                        [
                            48.01923828125001,
                            11.139355468749997
                        ],
                        [
                            48.674414062500006,
                            11.322656249999994
                        ],
                        [
                            48.938574218750006,
                            11.258447265624994
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Somaliland',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            41.88398437500001,
                            3.977734374999997
                        ],
                        [
                            41.91533203125002,
                            4.031298828124989
                        ],
                        [
                            42.02412109375001,
                            4.137939453125
                        ],
                        [
                            42.791601562500006,
                            4.2919921875
                        ],
                        [
                            43.01601562500002,
                            4.5633300781249915
                        ],
                        [
                            43.53828125000001,
                            4.84033203125
                        ],
                        [
                            44.02812500000002,
                            4.950976562499989
                        ],
                        [
                            44.940527343750006,
                            4.912011718749994
                        ],
                        [
                            45.93496093750002,
                            5.997216796874994
                        ],
                        [
                            47.97822265625001,
                            7.9970703125
                        ],
                        [
                            48.938085937500006,
                            9.451757812499991
                        ],
                        [
                            48.938574218750006,
                            11.258447265624994
                        ],
                        [
                            50.11005859375001,
                            11.529296875
                        ],
                        [
                            50.79228515625002,
                            11.983691406249989
                        ],
                        [
                            51.2548828125,
                            11.830712890624994
                        ],
                        [
                            51.08427734375002,
                            11.335644531249997
                        ],
                        [
                            51.140625,
                            10.656884765624994
                        ],
                        [
                            50.8984375,
                            10.253124999999997
                        ],
                        [
                            50.82500000000002,
                            9.428173828124997
                        ],
                        [
                            50.429785156250006,
                            8.845263671874989
                        ],
                        [
                            50.10283203125002,
                            8.199804687499991
                        ],
                        [
                            49.34853515625002,
                            6.990527343749989
                        ],
                        [
                            49.04931640625,
                            6.173632812499989
                        ],
                        [
                            48.649023437500006,
                            5.494384765625
                        ],
                        [
                            47.97529296875001,
                            4.497021484374997
                        ],
                        [
                            46.87880859375002,
                            3.28564453125
                        ],
                        [
                            46.05117187500002,
                            2.4751464843749886
                        ],
                        [
                            44.92021484375002,
                            1.8101562499999915
                        ],
                        [
                            44.33271484375001,
                            1.3909667968749915
                        ],
                        [
                            43.46767578125002,
                            0.6216308593749886
                        ],
                        [
                            41.97988281250002,
                            -0.9730468750000085
                        ],
                        [
                            41.73222656250002,
                            -1.4300781250000085
                        ],
                        [
                            41.52187500000002,
                            -1.572265625
                        ],
                        [
                            41.42695312500001,
                            -1.4495117187500028
                        ],
                        [
                            41.24980468750002,
                            -1.2205078125000028
                        ],
                        [
                            40.97871093750001,
                            -0.8703125000000114
                        ],
                        [
                            40.97001953125002,
                            1.378173828125
                        ],
                        [
                            40.964453125000006,
                            2.814648437499997
                        ],
                        [
                            41.341796875,
                            3.20166015625
                        ],
                        [
                            41.61347656250001,
                            3.5904785156249943
                        ],
                        [
                            41.88398437500001,
                            3.977734374999997
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Somalia',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            21.431445312500017,
                            45.192529296874994
                        ],
                        [
                            21.384375000000034,
                            44.87006835937501
                        ],
                        [
                            22.687890625000023,
                            44.248291015625
                        ],
                        [
                            22.42080078125005,
                            44.00742187500006
                        ],
                        [
                            22.399023437500063,
                            43.96953124999996
                        ],
                        [
                            22.36542968750004,
                            43.86210937500002
                        ],
                        [
                            22.36962890625003,
                            43.78129882812499
                        ],
                        [
                            22.386914062500068,
                            43.74013671875005
                        ],
                        [
                            22.39482421874999,
                            43.70664062500006
                        ],
                        [
                            22.436328125000017,
                            43.66547851562501
                        ],
                        [
                            22.47412109375,
                            43.60224609375001
                        ],
                        [
                            22.55458984375005,
                            43.45449218750002
                        ],
                        [
                            22.767578125,
                            43.35415039062502
                        ],
                        [
                            22.81972656250005,
                            43.300732421874955
                        ],
                        [
                            22.85957031250001,
                            43.252343749999966
                        ],
                        [
                            22.97685546874999,
                            43.18798828125
                        ],
                        [
                            22.799902343750006,
                            42.985742187499994
                        ],
                        [
                            22.466796875,
                            42.842480468749955
                        ],
                        [
                            22.465625000000045,
                            42.75078125000002
                        ],
                        [
                            22.46328125000008,
                            42.70947265625003
                        ],
                        [
                            22.436230468749983,
                            42.62910156250001
                        ],
                        [
                            22.523535156250006,
                            42.440966796875045
                        ],
                        [
                            22.44570312500005,
                            42.35913085937497
                        ],
                        [
                            22.42207031250004,
                            42.32885742187503
                        ],
                        [
                            22.344042968750045,
                            42.31396484375003
                        ],
                        [
                            22.23974609375003,
                            42.35815429687506
                        ],
                        [
                            22.146679687500068,
                            42.325000000000045
                        ],
                        [
                            22.052050781250074,
                            42.30463867187498
                        ],
                        [
                            21.904101562500045,
                            42.32207031249999
                        ],
                        [
                            21.56249999999997,
                            42.24750976562498
                        ],
                        [
                            21.541601562499977,
                            42.28081054687502
                        ],
                        [
                            21.51894531250008,
                            42.32841796875002
                        ],
                        [
                            21.52998046875001,
                            42.349999999999994
                        ],
                        [
                            21.390625000000057,
                            42.751416015624955
                        ],
                        [
                            21.057031250000023,
                            43.09169921875002
                        ],
                        [
                            20.339941406250063,
                            42.89287109375002
                        ],
                        [
                            19.670996093750006,
                            43.163964843749994
                        ],
                        [
                            19.19433593749997,
                            43.533300781250006
                        ],
                        [
                            19.583789062500017,
                            44.04345703125003
                        ],
                        [
                            19.15185546875003,
                            44.302539062500045
                        ],
                        [
                            19.35683593750005,
                            44.858544921874994
                        ],
                        [
                            19.007128906250045,
                            44.86918945312502
                        ],
                        [
                            19.055078125000023,
                            45.527246093749966
                        ],
                        [
                            18.905371093750006,
                            45.931738281250034
                        ],
                        [
                            19.421289062500023,
                            46.064453125
                        ],
                        [
                            19.61347656250001,
                            46.169189453125
                        ],
                        [
                            20.161425781250017,
                            46.14189453124996
                        ],
                        [
                            20.210156250000068,
                            46.12602539062502
                        ],
                        [
                            20.241796875000034,
                            46.10859375000001
                        ],
                        [
                            20.35859375000004,
                            45.975488281249994
                        ],
                        [
                            20.775000000000034,
                            45.74980468750002
                        ],
                        [
                            20.774218750000074,
                            45.484423828125045
                        ],
                        [
                            21.431445312500017,
                            45.192529296874994
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Republic of Serbia',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -54.03422851562499,
                            3.62939453125
                        ],
                        [
                            -54.203125,
                            3.13818359375
                        ],
                        [
                            -54.17070312499999,
                            2.993603515624997
                        ],
                        [
                            -54.18808593749999,
                            2.8748535156249915
                        ],
                        [
                            -54.1955078125,
                            2.81787109375
                        ],
                        [
                            -54.256738281249994,
                            2.7137207031249915
                        ],
                        [
                            -54.402001953124994,
                            2.4615234374999915
                        ],
                        [
                            -54.53593749999999,
                            2.3433105468749886
                        ],
                        [
                            -54.56840820312499,
                            2.3425781249999886
                        ],
                        [
                            -54.604736328125,
                            2.3357910156249915
                        ],
                        [
                            -54.61625976562499,
                            2.3267578124999915
                        ],
                        [
                            -54.661865234375,
                            2.3275390624999943
                        ],
                        [
                            -54.697412109374994,
                            2.359814453124997
                        ],
                        [
                            -54.72221679687499,
                            2.441650390625
                        ],
                        [
                            -54.766845703125,
                            2.4547363281249943
                        ],
                        [
                            -54.85166015624999,
                            2.4395507812499915
                        ],
                        [
                            -54.87607421874999,
                            2.450390624999997
                        ],
                        [
                            -54.92656249999999,
                            2.4973632812499886
                        ],
                        [
                            -54.968408203124994,
                            2.54833984375
                        ],
                        [
                            -54.978662109374994,
                            2.59765625
                        ],
                        [
                            -55.005810546875,
                            2.592968749999997
                        ],
                        [
                            -55.0703125,
                            2.54833984375
                        ],
                        [
                            -55.11411132812499,
                            2.5392089843749943
                        ],
                        [
                            -55.1876953125,
                            2.5475097656249943
                        ],
                        [
                            -55.286035156249994,
                            2.4996582031249943
                        ],
                        [
                            -55.343994140625,
                            2.48876953125
                        ],
                        [
                            -55.38535156249999,
                            2.440624999999997
                        ],
                        [
                            -55.73056640624999,
                            2.4061523437499943
                        ],
                        [
                            -55.935937499999994,
                            2.5166015625
                        ],
                        [
                            -56.02036132812499,
                            2.392773437499997
                        ],
                        [
                            -56.087792968749994,
                            2.34130859375
                        ],
                        [
                            -56.12939453125,
                            2.299511718749997
                        ],
                        [
                            -56.019921874999994,
                            1.8422363281249972
                        ],
                        [
                            -56.482812499999994,
                            1.942138671875
                        ],
                        [
                            -56.704345703125,
                            2.0364746093749915
                        ],
                        [
                            -57.20981445312499,
                            2.8828125
                        ],
                        [
                            -57.303662109375,
                            3.3770996093749943
                        ],
                        [
                            -57.54960937499999,
                            3.3528320312499886
                        ],
                        [
                            -57.832666015624994,
                            3.675976562499997
                        ],
                        [
                            -58.05449218749999,
                            4.171923828124989
                        ],
                        [
                            -57.711083984374994,
                            4.9910644531249915
                        ],
                        [
                            -57.331005859375,
                            5.020166015624994
                        ],
                        [
                            -57.194775390625,
                            5.5484374999999915
                        ],
                        [
                            -56.96982421874999,
                            5.992871093749997
                        ],
                        [
                            -55.93955078124999,
                            5.795458984374989
                        ],
                        [
                            -55.82817382812499,
                            5.961669921875
                        ],
                        [
                            -54.83369140625,
                            5.988330078124989
                        ],
                        [
                            -54.05419921875,
                            5.807910156249989
                        ],
                        [
                            -54.15595703125,
                            5.3589843749999915
                        ],
                        [
                            -54.45219726562499,
                            5.013476562499989
                        ],
                        [
                            -54.350732421874994,
                            4.054101562499994
                        ],
                        [
                            -54.03422851562499,
                            3.62939453125
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Suriname',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            22.538671875000006,
                            49.072705078125
                        ],
                        [
                            22.52412109375001,
                            49.031396484374994
                        ],
                        [
                            22.432031250000023,
                            48.933544921875
                        ],
                        [
                            22.389453125000017,
                            48.873486328125
                        ],
                        [
                            22.295214843750017,
                            48.685839843749996
                        ],
                        [
                            22.142871093750017,
                            48.568505859374994
                        ],
                        [
                            22.1318359375,
                            48.405322265624996
                        ],
                        [
                            21.45136718750001,
                            48.55224609375
                        ],
                        [
                            20.490039062500017,
                            48.526904296874996
                        ],
                        [
                            19.89863281250001,
                            48.13134765625
                        ],
                        [
                            18.914160156250006,
                            48.050830078124996
                        ],
                        [
                            18.724218750000006,
                            47.787158203124996
                        ],
                        [
                            17.76191406250001,
                            47.770166015624994
                        ],
                        [
                            17.147363281250023,
                            48.00595703125
                        ],
                        [
                            16.953125,
                            48.598828125
                        ],
                        [
                            17.1884765625,
                            48.8609375
                        ],
                        [
                            17.75849609375001,
                            48.888134765625
                        ],
                        [
                            18.160937500000017,
                            49.257373046874996
                        ],
                        [
                            18.83222656250001,
                            49.510791015624996
                        ],
                        [
                            19.44160156250001,
                            49.597705078124996
                        ],
                        [
                            19.80224609375,
                            49.192333984375
                        ],
                        [
                            20.616113281250023,
                            49.39169921875
                        ],
                        [
                            21.6396484375,
                            49.411962890625
                        ],
                        [
                            22.538671875000006,
                            49.072705078125
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Slovakia',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            16.516210937500006,
                            46.499902343749994
                        ],
                        [
                            16.427636718750023,
                            46.5244140625
                        ],
                        [
                            16.321191406250023,
                            46.534619140625
                        ],
                        [
                            16.1064453125,
                            46.382226562499994
                        ],
                        [
                            16.00068359375001,
                            46.30537109375
                        ],
                        [
                            15.784277343750006,
                            46.233984375
                        ],
                        [
                            15.704199218750006,
                            46.213232421875
                        ],
                        [
                            15.635937500000011,
                            46.200732421874996
                        ],
                        [
                            15.608984375000006,
                            46.171923828124996
                        ],
                        [
                            15.592578125000017,
                            46.139990234375
                        ],
                        [
                            15.596875000000011,
                            46.109228515625
                        ],
                        [
                            15.666210937500011,
                            46.048486328124994
                        ],
                        [
                            15.668066406250006,
                            45.904443359374994
                        ],
                        [
                            15.652148437500017,
                            45.862158203125
                        ],
                        [
                            15.624804687500017,
                            45.834033203124996
                        ],
                        [
                            14.95458984375,
                            45.499902343749994
                        ],
                        [
                            13.878710937500017,
                            45.428369140624994
                        ],
                        [
                            13.831152343750006,
                            45.680419921875
                        ],
                        [
                            13.663476562500023,
                            45.7919921875
                        ],
                        [
                            13.420996093750006,
                            46.212304687499994
                        ],
                        [
                            13.399511718750006,
                            46.317529296874994
                        ],
                        [
                            13.6796875,
                            46.462890625
                        ],
                        [
                            13.700000000000017,
                            46.520263671875
                        ],
                        [
                            14.503515625000006,
                            46.417041015624996
                        ],
                        [
                            15.000683593750011,
                            46.6259765625
                        ],
                        [
                            15.957617187500006,
                            46.677636718749994
                        ],
                        [
                            16.093066406250017,
                            46.86328125
                        ],
                        [
                            16.516210937500006,
                            46.499902343749994
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Slovenia',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                19.076464843750045,
                                57.8359375
                            ],
                            [
                                18.699902343750068,
                                57.24272460937502
                            ],
                            [
                                18.136523437500045,
                                57.55664062500003
                            ],
                            [
                                18.53740234374999,
                                57.83056640625006
                            ],
                            [
                                19.076464843750045,
                                57.8359375
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                24.15546875000004,
                                65.80527343750006
                            ],
                            [
                                23.102343750000074,
                                65.73535156250003
                            ],
                            [
                                21.565527343750063,
                                65.40810546874997
                            ],
                            [
                                21.138183593750057,
                                64.80869140625006
                            ],
                            [
                                21.46503906250001,
                                64.37958984375004
                            ],
                            [
                                20.76269531250003,
                                63.86782226562505
                            ],
                            [
                                19.034374999999983,
                                63.23774414062501
                            ],
                            [
                                18.46308593750004,
                                62.895849609375006
                            ],
                            [
                                17.895605468749977,
                                62.83051757812501
                            ],
                            [
                                17.417285156250074,
                                61.74067382812504
                            ],
                            [
                                17.13076171875005,
                                61.57573242187499
                            ],
                            [
                                17.25097656250003,
                                60.700781250000006
                            ],
                            [
                                17.955761718750068,
                                60.589794921874955
                            ],
                            [
                                18.557519531250023,
                                60.25356445312502
                            ],
                            [
                                18.970507812500045,
                                59.757226562499994
                            ],
                            [
                                18.285351562500068,
                                59.10937499999997
                            ],
                            [
                                17.45673828125001,
                                58.8583984375
                            ],
                            [
                                16.651953125000006,
                                58.43432617187503
                            ],
                            [
                                16.527929687500063,
                                57.06816406249999
                            ],
                            [
                                15.996679687500006,
                                56.22260742187501
                            ],
                            [
                                14.782031250000017,
                                56.16191406250002
                            ],
                            [
                                14.21503906250004,
                                55.83261718749998
                            ],
                            [
                                14.341699218749994,
                                55.52773437500002
                            ],
                            [
                                13.321386718750063,
                                55.34638671875004
                            ],
                            [
                                12.592578124999989,
                                56.13759765625002
                            ],
                            [
                                12.883691406250051,
                                56.61772460937496
                            ],
                            [
                                12.421484375000034,
                                56.906396484374966
                            ],
                            [
                                11.449316406250063,
                                58.118359374999955
                            ],
                            [
                                11.14716796875004,
                                58.98862304687498
                            ],
                            [
                                11.388281250000063,
                                59.036523437499966
                            ],
                            [
                                11.798144531250074,
                                59.28989257812498
                            ],
                            [
                                11.680761718750034,
                                59.59228515625003
                            ],
                            [
                                12.486132812500074,
                                60.10678710937506
                            ],
                            [
                                12.588671874999989,
                                60.450732421875045
                            ],
                            [
                                12.29414062500004,
                                61.00268554687506
                            ],
                            [
                                12.88076171875008,
                                61.35229492187506
                            ],
                            [
                                12.155371093750006,
                                61.720751953125045
                            ],
                            [
                                12.303515625000074,
                                62.28559570312501
                            ],
                            [
                                12.218164062500023,
                                63.00063476562502
                            ],
                            [
                                11.999902343750051,
                                63.29169921875001
                            ],
                            [
                                12.662500000000023,
                                63.94047851562502
                            ],
                            [
                                13.960546875000063,
                                64.01401367187498
                            ],
                            [
                                14.077636718750028,
                                64.464013671875
                            ],
                            [
                                13.650292968750023,
                                64.58154296874997
                            ],
                            [
                                14.47968750000004,
                                65.30146484374998
                            ],
                            [
                                14.543261718750045,
                                66.12934570312498
                            ],
                            [
                                15.422949218750006,
                                66.48984374999998
                            ],
                            [
                                16.40351562500004,
                                67.05498046875002
                            ],
                            [
                                16.12744140625,
                                67.42583007812507
                            ],
                            [
                                17.324609375000023,
                                68.10380859374999
                            ],
                            [
                                17.91669921875001,
                                67.96489257812502
                            ],
                            [
                                18.378613281250068,
                                68.56240234374997
                            ],
                            [
                                19.969824218750063,
                                68.35639648437501
                            ],
                            [
                                20.622167968750006,
                                69.036865234375
                            ],
                            [
                                21.99746093750005,
                                68.52060546874998
                            ],
                            [
                                22.78242187500001,
                                68.39101562499997
                            ],
                            [
                                23.63886718750004,
                                67.95439453125002
                            ],
                            [
                                23.468066406250074,
                                67.449951171875
                            ],
                            [
                                23.988574218750045,
                                66.81054687500003
                            ],
                            [
                                23.70117187500003,
                                66.48076171874999
                            ],
                            [
                                24.15546875000004,
                                65.80527343750006
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Sweden',
                'childNum': 2
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            31.9482421875,
                            -25.957617187500006
                        ],
                        [
                            32.04140625000002,
                            -26.28125
                        ],
                        [
                            32.10595703125,
                            -26.52001953125
                        ],
                        [
                            32.112890625000006,
                            -26.839453125000006
                        ],
                        [
                            32.02480468750002,
                            -26.811132812500006
                        ],
                        [
                            31.994726562500006,
                            -26.817480468750006
                        ],
                        [
                            31.967187500000023,
                            -26.96064453125001
                        ],
                        [
                            31.946093750000017,
                            -27.173632812500003
                        ],
                        [
                            31.958398437500023,
                            -27.30585937500001
                        ],
                        [
                            31.742578125000023,
                            -27.30996093750001
                        ],
                        [
                            31.469531250000017,
                            -27.295507812500006
                        ],
                        [
                            31.274023437500006,
                            -27.238378906250006
                        ],
                        [
                            31.063378906250023,
                            -27.1123046875
                        ],
                        [
                            30.794335937500023,
                            -26.764257812500006
                        ],
                        [
                            30.803320312500006,
                            -26.41347656250001
                        ],
                        [
                            31.207324218750017,
                            -25.843359375000006
                        ],
                        [
                            31.415136718750006,
                            -25.74658203125
                        ],
                        [
                            31.921679687500017,
                            -25.96875
                        ],
                        [
                            31.9482421875,
                            -25.957617187500006
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Swaziland',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            42.358984375000006,
                            37.10859375
                        ],
                        [
                            41.78857421875,
                            36.59716796875
                        ],
                        [
                            41.41679687500002,
                            36.5146484375
                        ],
                        [
                            41.24560546875,
                            36.073388671874994
                        ],
                        [
                            41.354101562500006,
                            35.640429687499996
                        ],
                        [
                            41.19472656250002,
                            34.768994140625
                        ],
                        [
                            40.98701171875001,
                            34.429052734375
                        ],
                        [
                            40.689453125,
                            34.33203125
                        ],
                        [
                            38.773535156250006,
                            33.372216796874994
                        ],
                        [
                            36.818359375,
                            32.317285156249994
                        ],
                        [
                            36.3720703125,
                            32.3869140625
                        ],
                        [
                            35.78730468750001,
                            32.734912109374996
                        ],
                        [
                            35.869140625,
                            33.43173828125
                        ],
                        [
                            35.9423828125,
                            33.667578125
                        ],
                        [
                            36.5849609375,
                            34.221240234374996
                        ],
                        [
                            36.43300781250002,
                            34.6134765625
                        ],
                        [
                            35.97626953125001,
                            34.629199218749996
                        ],
                        [
                            35.94306640625001,
                            35.223828125
                        ],
                        [
                            35.76445312500002,
                            35.571582031249996
                        ],
                        [
                            36.12734375000002,
                            35.831445312499994
                        ],
                        [
                            36.53750000000002,
                            36.457421874999994
                        ],
                        [
                            36.54667968750002,
                            36.50634765625
                        ],
                        [
                            36.59687500000001,
                            36.7013671875
                        ],
                        [
                            36.62841796875,
                            36.777685546875
                        ],
                        [
                            36.65859375000002,
                            36.802539062499996
                        ],
                        [
                            36.77656250000001,
                            36.79267578125
                        ],
                        [
                            36.94179687500002,
                            36.7583984375
                        ],
                        [
                            36.9853515625,
                            36.702392578125
                        ],
                        [
                            37.06621093750002,
                            36.652636718749996
                        ],
                        [
                            37.43632812500002,
                            36.643310546875
                        ],
                        [
                            37.523535156250006,
                            36.6783203125
                        ],
                        [
                            37.720312500000006,
                            36.743701171874996
                        ],
                        [
                            37.90664062500002,
                            36.79462890625
                        ],
                        [
                            38.19169921875002,
                            36.9015625
                        ],
                        [
                            38.7666015625,
                            36.693115234375
                        ],
                        [
                            39.1083984375,
                            36.680566406249994
                        ],
                        [
                            39.35664062500001,
                            36.681591796875
                        ],
                        [
                            39.50146484375,
                            36.70224609375
                        ],
                        [
                            39.6865234375,
                            36.738623046875
                        ],
                        [
                            40.01640625000002,
                            36.826074218749994
                        ],
                        [
                            40.705664062500006,
                            37.097705078124996
                        ],
                        [
                            41.886816406250006,
                            37.156396484374994
                        ],
                        [
                            42.358984375000006,
                            37.10859375
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Syria',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            23.980273437500017,
                            19.496630859375003
                        ],
                        [
                            23.982226562500017,
                            17.266357421875
                        ],
                        [
                            23.9833984375,
                            15.780175781249994
                        ],
                        [
                            23.10517578125001,
                            15.702539062499994
                        ],
                        [
                            22.93232421875001,
                            15.162109375
                        ],
                        [
                            22.6708984375,
                            14.722460937500003
                        ],
                        [
                            22.38154296875001,
                            14.550488281249997
                        ],
                        [
                            22.50996093750001,
                            14.12744140625
                        ],
                        [
                            22.10761718750001,
                            13.730322265624991
                        ],
                        [
                            22.228125000000006,
                            13.32958984375
                        ],
                        [
                            21.825292968750006,
                            12.79052734375
                        ],
                        [
                            22.352343750000017,
                            12.660449218749989
                        ],
                        [
                            22.55634765625001,
                            11.669531249999991
                        ],
                        [
                            22.922656250000017,
                            11.344873046874994
                        ],
                        [
                            22.86005859375001,
                            10.919677734375
                        ],
                        [
                            22.49384765625001,
                            10.996240234374994
                        ],
                        [
                            21.70654296875,
                            10.574804687499991
                        ],
                        [
                            21.72578125000001,
                            10.366552734374991
                        ],
                        [
                            21.26386718750001,
                            9.974609375
                        ],
                        [
                            20.773242187500017,
                            9.405664062499994
                        ],
                        [
                            20.342089843750017,
                            9.127099609374994
                        ],
                        [
                            19.1455078125,
                            9.015966796874991
                        ],
                        [
                            19.108691406250017,
                            8.656152343749994
                        ],
                        [
                            18.455078125,
                            8.032031249999989
                        ],
                        [
                            17.6494140625,
                            7.983593749999997
                        ],
                        [
                            16.784765625000006,
                            7.550976562499997
                        ],
                        [
                            16.37890625,
                            7.683544921874997
                        ],
                        [
                            15.845019531250017,
                            7.475292968749997
                        ],
                        [
                            15.480078125000006,
                            7.5237792968749915
                        ],
                        [
                            15.442968750000006,
                            7.851855468749989
                        ],
                        [
                            15.1162109375,
                            8.557324218749997
                        ],
                        [
                            14.332324218750017,
                            9.203515624999994
                        ],
                        [
                            13.977246093750011,
                            9.691552734374994
                        ],
                        [
                            14.243261718750006,
                            9.979736328125
                        ],
                        [
                            15.540917968750023,
                            9.960302734374991
                        ],
                        [
                            15.276074218750011,
                            10.357373046874997
                        ],
                        [
                            15.029882812500006,
                            11.113671874999994
                        ],
                        [
                            15.081250000000011,
                            11.845507812499989
                        ],
                        [
                            14.76123046875,
                            12.655615234374991
                        ],
                        [
                            14.516210937500006,
                            12.979736328125
                        ],
                        [
                            14.244824218750011,
                            13.077343749999997
                        ],
                        [
                            14.06396484375,
                            13.078515624999994
                        ],
                        [
                            13.932324218750011,
                            13.258496093749997
                        ],
                        [
                            13.606347656250023,
                            13.70458984375
                        ],
                        [
                            13.505761718750023,
                            14.134423828124994
                        ],
                        [
                            13.4482421875,
                            14.380664062500003
                        ],
                        [
                            14.367968750000017,
                            15.750146484374994
                        ],
                        [
                            15.474316406250011,
                            16.908398437499997
                        ],
                        [
                            15.735058593750011,
                            19.904052734375
                        ],
                        [
                            15.948828125000006,
                            20.303173828124997
                        ],
                        [
                            15.587109375000011,
                            20.733300781249994
                        ],
                        [
                            15.607324218750023,
                            20.954394531250003
                        ],
                        [
                            15.181835937500011,
                            21.523388671874997
                        ],
                        [
                            15.172265625000023,
                            21.922070312499997
                        ],
                        [
                            14.97900390625,
                            22.99619140624999
                        ],
                        [
                            15.984082031250011,
                            23.445214843749994
                        ],
                        [
                            18.231347656250023,
                            22.33554687499999
                        ],
                        [
                            19.668554687500006,
                            21.62583007812499
                        ],
                        [
                            21.5849609375,
                            20.679492187500003
                        ],
                        [
                            23.980273437500017,
                            19.496630859375003
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Chad',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            0.9004882812500057,
                            10.993261718749991
                        ],
                        [
                            0.7799804687500114,
                            10.359570312499997
                        ],
                        [
                            1.330078125,
                            9.996972656249994
                        ],
                        [
                            1.42431640625,
                            9.285009765624991
                        ],
                        [
                            1.6001953125000057,
                            9.050048828125
                        ],
                        [
                            1.6226562500000057,
                            6.216796875
                        ],
                        [
                            1.1872070312500114,
                            6.089404296874989
                        ],
                        [
                            0.7369140625000057,
                            6.452587890624997
                        ],
                        [
                            0.5255859375000114,
                            6.8509277343749915
                        ],
                        [
                            0.634765625,
                            7.353662109374994
                        ],
                        [
                            0.5835937500000057,
                            8.145800781249989
                        ],
                        [
                            0.6880859375000057,
                            8.304248046874989
                        ],
                        [
                            0.41533203125001705,
                            8.652734374999994
                        ],
                        [
                            0.5290039062500114,
                            9.358300781249994
                        ],
                        [
                            0.26191406250001137,
                            9.49560546875
                        ],
                        [
                            0.36269531250002274,
                            10.236474609374994
                        ],
                        [
                            -0.05771484374997726,
                            10.630615234375
                        ],
                        [
                            -0.068603515625,
                            11.115624999999994
                        ],
                        [
                            0.9004882812500057,
                            10.993261718749991
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Togo',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            100.12246093750005,
                            20.316650390625057
                        ],
                        [
                            100.31796875000006,
                            20.38588867187505
                        ],
                        [
                            100.51953125000003,
                            20.17792968750004
                        ],
                        [
                            100.53994140625,
                            20.13237304687499
                        ],
                        [
                            100.54306640625012,
                            20.088671875000074
                        ],
                        [
                            100.51455078125,
                            19.996337890625
                        ],
                        [
                            100.39765625000004,
                            19.756103515625
                        ],
                        [
                            100.62548828125003,
                            19.499853515625006
                        ],
                        [
                            101.1546875,
                            19.579199218750006
                        ],
                        [
                            101.22080078125006,
                            19.486621093750074
                        ],
                        [
                            101.19755859375007,
                            19.327929687500074
                        ],
                        [
                            101.2863281250001,
                            18.977148437500006
                        ],
                        [
                            101.16552734375003,
                            18.618310546874994
                        ],
                        [
                            101.10634765625005,
                            18.533544921875063
                        ],
                        [
                            101.06044921875,
                            18.479003906250057
                        ],
                        [
                            101.05058593750002,
                            18.407031250000045
                        ],
                        [
                            101.11328125000003,
                            18.033544921874977
                        ],
                        [
                            100.90849609375002,
                            17.583886718750023
                        ],
                        [
                            101.10517578125004,
                            17.47954101562499
                        ],
                        [
                            101.68750000000003,
                            17.889404296875
                        ],
                        [
                            101.77480468750005,
                            18.03339843750004
                        ],
                        [
                            101.81865234375002,
                            18.06464843750001
                        ],
                        [
                            101.87548828124997,
                            18.046435546875017
                        ],
                        [
                            101.94746093750004,
                            18.081494140624983
                        ],
                        [
                            102.03457031250005,
                            18.169824218750023
                        ],
                        [
                            102.10146484375,
                            18.210644531249983
                        ],
                        [
                            102.14824218750002,
                            18.20385742187503
                        ],
                        [
                            102.23164062500004,
                            18.148974609375045
                        ],
                        [
                            102.35185546875002,
                            18.045947265625017
                        ],
                        [
                            102.71757812500002,
                            17.892236328124966
                        ],
                        [
                            103.05136718750006,
                            18.02851562500001
                        ],
                        [
                            103.36699218750007,
                            18.42333984374997
                        ],
                        [
                            103.62968750000002,
                            18.38256835937503
                        ],
                        [
                            103.79228515625002,
                            18.316503906249977
                        ],
                        [
                            103.89882812500005,
                            18.295312500000023
                        ],
                        [
                            103.949609375,
                            18.31899414062505
                        ],
                        [
                            104.04873046875005,
                            18.216699218749994
                        ],
                        [
                            104.42812500000005,
                            17.69897460937503
                        ],
                        [
                            104.81601562500012,
                            17.30029296874997
                        ],
                        [
                            104.75898437500004,
                            17.0771484375
                        ],
                        [
                            104.75058593750012,
                            16.647558593750063
                        ],
                        [
                            104.81933593750003,
                            16.46606445312503
                        ],
                        [
                            104.94990234375004,
                            16.339941406250006
                        ],
                        [
                            105.02578125000005,
                            16.23798828125001
                        ],
                        [
                            105.04716796875007,
                            16.160253906249977
                        ],
                        [
                            105.14873046875007,
                            16.09355468749999
                        ],
                        [
                            105.33066406250006,
                            16.037890625000017
                        ],
                        [
                            105.40625000000003,
                            15.987451171875051
                        ],
                        [
                            105.37324218750004,
                            15.889697265625017
                        ],
                        [
                            105.39892578124997,
                            15.829882812500017
                        ],
                        [
                            105.46201171875012,
                            15.780419921874966
                        ],
                        [
                            105.62207031250003,
                            15.699951171875
                        ],
                        [
                            105.51318359374997,
                            15.360888671875031
                        ],
                        [
                            105.47558593750003,
                            14.530126953124977
                        ],
                        [
                            105.34218750000005,
                            14.416699218750054
                        ],
                        [
                            105.24365234375003,
                            14.367871093750054
                        ],
                        [
                            105.1833007812501,
                            14.346240234374989
                        ],
                        [
                            105.16914062500004,
                            14.336083984374966
                        ],
                        [
                            105.12597656250003,
                            14.280957031250011
                        ],
                        [
                            105.07412109375005,
                            14.227441406250037
                        ],
                        [
                            104.96972656249997,
                            14.366113281250051
                        ],
                        [
                            104.77900390625004,
                            14.427832031250006
                        ],
                        [
                            103.19941406250004,
                            14.332617187499977
                        ],
                        [
                            102.72890625,
                            13.841894531250006
                        ],
                        [
                            102.31972656250005,
                            13.539990234375054
                        ],
                        [
                            102.49960937500012,
                            12.669970703125003
                        ],
                        [
                            102.75566406250002,
                            12.42626953125
                        ],
                        [
                            102.73662109375007,
                            12.089794921875011
                        ],
                        [
                            102.93388671875002,
                            11.706689453125037
                        ],
                        [
                            102.76298828125002,
                            12.012451171874986
                        ],
                        [
                            101.72363281250003,
                            12.689355468750037
                        ],
                        [
                            101.44492187500006,
                            12.618945312499989
                        ],
                        [
                            100.86328124999997,
                            12.714501953125009
                        ],
                        [
                            100.96269531250007,
                            13.431982421874991
                        ],
                        [
                            100.65605468750002,
                            13.521289062499974
                        ],
                        [
                            100.01748046875,
                            13.353173828125009
                        ],
                        [
                            100.08994140625006,
                            13.045654296874972
                        ],
                        [
                            99.96396484375006,
                            12.690039062500006
                        ],
                        [
                            99.98906250000007,
                            12.170800781249994
                        ],
                        [
                            99.62734375,
                            11.462890625000014
                        ],
                        [
                            99.48691406250006,
                            10.889550781250009
                        ],
                        [
                            99.16503906250003,
                            10.319824218750028
                        ],
                        [
                            99.16074218750006,
                            9.734033203124966
                        ],
                        [
                            99.39384765625002,
                            9.21372070312502
                        ],
                        [
                            99.7238281250001,
                            9.314208984374972
                        ],
                        [
                            99.9046875,
                            9.112890624999991
                        ],
                        [
                            99.96064453125004,
                            8.671240234374991
                        ],
                        [
                            100.27939453125006,
                            8.268505859375011
                        ],
                        [
                            100.453515625,
                            7.442285156249994
                        ],
                        [
                            100.58623046875002,
                            7.175976562500011
                        ],
                        [
                            101.01787109375002,
                            6.860937500000034
                        ],
                        [
                            101.49794921875005,
                            6.865283203125031
                        ],
                        [
                            102.10107421874997,
                            6.242236328125031
                        ],
                        [
                            101.87363281250012,
                            5.8252929687499915
                        ],
                        [
                            101.5560546875,
                            5.907763671875003
                        ],
                        [
                            101.2570312500001,
                            5.7893554687500455
                        ],
                        [
                            101.05351562500002,
                            6.2425781250000085
                        ],
                        [
                            100.3454101562501,
                            6.549902343750006
                        ],
                        [
                            100.11914062499997,
                            6.441992187500048
                        ],
                        [
                            99.69599609375004,
                            6.87666015625004
                        ],
                        [
                            99.72031250000012,
                            7.106201171875
                        ],
                        [
                            99.05107421875002,
                            7.887841796874994
                        ],
                        [
                            98.57919921875006,
                            8.344287109374989
                        ],
                        [
                            98.23818359375005,
                            8.423095703124972
                        ],
                        [
                            98.37138671875002,
                            9.290527343750028
                        ],
                        [
                            98.70253906250005,
                            10.19038085937504
                        ],
                        [
                            98.7572265625,
                            10.660937499999974
                        ],
                        [
                            99.1901367187501,
                            11.105273437499989
                        ],
                        [
                            99.61474609374997,
                            11.781201171875026
                        ],
                        [
                            99.40507812500002,
                            12.547900390625003
                        ],
                        [
                            99.21982421875012,
                            12.73974609375
                        ],
                        [
                            99.13681640625006,
                            13.716699218749994
                        ],
                        [
                            98.24599609375005,
                            14.814746093750045
                        ],
                        [
                            98.19101562500012,
                            15.204101562499972
                        ],
                        [
                            98.56523437500007,
                            15.403564453125014
                        ],
                        [
                            98.68925781250002,
                            16.30541992187497
                        ],
                        [
                            98.4388671875,
                            16.975683593750034
                        ],
                        [
                            97.79296875000003,
                            17.681250000000063
                        ],
                        [
                            97.48496093750006,
                            18.494238281250034
                        ],
                        [
                            97.75400390625006,
                            18.620800781249983
                        ],
                        [
                            97.816796875,
                            19.459960937500057
                        ],
                        [
                            98.23906250000002,
                            19.69067382812497
                        ],
                        [
                            98.9166992187501,
                            19.77290039062504
                        ],
                        [
                            99.02070312500004,
                            20.041796875000017
                        ],
                        [
                            99.33789062499997,
                            20.078906250000017
                        ],
                        [
                            99.8903320312501,
                            20.424414062499977
                        ],
                        [
                            100.12246093750005,
                            20.316650390625057
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Thailand',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            70.95800781250003,
                            40.238867187500034
                        ],
                        [
                            70.59921875,
                            39.974511718749994
                        ],
                        [
                            69.96679687499997,
                            40.202246093750034
                        ],
                        [
                            69.47099609375002,
                            39.990624999999994
                        ],
                        [
                            69.22910156250006,
                            39.761083984375034
                        ],
                        [
                            69.29765625000007,
                            39.52480468750005
                        ],
                        [
                            69.59882812500004,
                            39.57377929687499
                        ],
                        [
                            69.7720703125,
                            39.55673828124998
                        ],
                        [
                            70.13681640625006,
                            39.55756835937498
                        ],
                        [
                            70.17109375000004,
                            39.58417968749998
                        ],
                        [
                            70.20927734375007,
                            39.57499999999996
                        ],
                        [
                            70.24482421875004,
                            39.54262695312502
                        ],
                        [
                            70.6078125,
                            39.56440429687504
                        ],
                        [
                            70.67861328125005,
                            39.47128906249998
                        ],
                        [
                            70.73310546875004,
                            39.41328124999998
                        ],
                        [
                            70.79931640625003,
                            39.39472656250001
                        ],
                        [
                            71.00488281249997,
                            39.41186523437503
                        ],
                        [
                            71.06503906250006,
                            39.49340820312506
                        ],
                        [
                            71.11806640625005,
                            39.513574218749966
                        ],
                        [
                            71.27285156250005,
                            39.535302734374966
                        ],
                        [
                            71.328515625,
                            39.568701171875006
                        ],
                        [
                            71.40429687499997,
                            39.59785156250001
                        ],
                        [
                            71.73222656250002,
                            39.422998046874994
                        ],
                        [
                            72.63994140625002,
                            39.385986328125
                        ],
                        [
                            73.2349609375,
                            39.37456054687499
                        ],
                        [
                            73.3361328125001,
                            39.41235351562506
                        ],
                        [
                            73.38740234375004,
                            39.442724609375034
                        ],
                        [
                            73.4704101562501,
                            39.46059570312502
                        ],
                        [
                            73.63164062500007,
                            39.44887695312502
                        ],
                        [
                            73.63632812500006,
                            39.396679687499955
                        ],
                        [
                            73.60732421875,
                            39.229199218749955
                        ],
                        [
                            73.7956054687501,
                            39.00214843749998
                        ],
                        [
                            73.80166015625,
                            38.60688476562501
                        ],
                        [
                            74.27744140625,
                            38.659765625000034
                        ],
                        [
                            74.74501953125,
                            38.51000976562497
                        ],
                        [
                            74.89130859375004,
                            37.231640624999955
                        ],
                        [
                            74.65937500000004,
                            37.39448242187501
                        ],
                        [
                            74.25966796875005,
                            37.41542968750002
                        ],
                        [
                            74.20351562500005,
                            37.37246093750005
                        ],
                        [
                            74.16708984375,
                            37.32944335937498
                        ],
                        [
                            73.74960937500006,
                            37.23178710937498
                        ],
                        [
                            73.6535156250001,
                            37.239355468750034
                        ],
                        [
                            73.72060546875,
                            37.41874999999999
                        ],
                        [
                            73.65712890625005,
                            37.43046874999996
                        ],
                        [
                            73.63261718750002,
                            37.43720703125001
                        ],
                        [
                            73.6046875000001,
                            37.44604492187503
                        ],
                        [
                            73.48134765625,
                            37.4716796875
                        ],
                        [
                            73.38291015625006,
                            37.462255859375034
                        ],
                        [
                            73.21113281250004,
                            37.40849609375002
                        ],
                        [
                            72.89550781250003,
                            37.26752929687498
                        ],
                        [
                            72.65742187500004,
                            37.029052734375
                        ],
                        [
                            71.665625,
                            36.696923828124994
                        ],
                        [
                            71.530859375,
                            36.845117187499994
                        ],
                        [
                            71.43291015625007,
                            37.12753906249998
                        ],
                        [
                            71.5050781250001,
                            37.60292968750002
                        ],
                        [
                            71.58037109375007,
                            37.86425781250006
                        ],
                        [
                            71.5822265625001,
                            37.91010742187498
                        ],
                        [
                            71.55195312500004,
                            37.93315429687496
                        ],
                        [
                            71.48779296874997,
                            37.93188476562497
                        ],
                        [
                            71.38964843750003,
                            37.90629882812502
                        ],
                        [
                            71.31992187500006,
                            37.90185546875
                        ],
                        [
                            71.27851562500004,
                            37.91840820312498
                        ],
                        [
                            71.2828125,
                            38.007910156250006
                        ],
                        [
                            71.33271484375004,
                            38.170263671875034
                        ],
                        [
                            71.25585937499997,
                            38.306982421875006
                        ],
                        [
                            70.87890624999997,
                            38.45639648437498
                        ],
                        [
                            70.41777343750002,
                            38.075439453125
                        ],
                        [
                            70.25146484374997,
                            37.66416015625006
                        ],
                        [
                            69.9849609375,
                            37.566162109375
                        ],
                        [
                            69.8208984375,
                            37.60957031250004
                        ],
                        [
                            69.62578125000002,
                            37.59404296874999
                        ],
                        [
                            69.49208984375,
                            37.55307617187498
                        ],
                        [
                            69.42011718750004,
                            37.486718749999966
                        ],
                        [
                            69.39921875000007,
                            37.39931640625002
                        ],
                        [
                            69.42968749999997,
                            37.290869140625034
                        ],
                        [
                            69.414453125,
                            37.20776367187497
                        ],
                        [
                            69.35380859375007,
                            37.15004882812502
                        ],
                        [
                            69.3039062500001,
                            37.11694335937503
                        ],
                        [
                            69.26484375000004,
                            37.1083984375
                        ],
                        [
                            69.18017578125003,
                            37.158300781250034
                        ],
                        [
                            68.96044921875003,
                            37.32504882812498
                        ],
                        [
                            68.6691406250001,
                            37.258398437500006
                        ],
                        [
                            68.3869140625001,
                            37.13749999999996
                        ],
                        [
                            68.2121093750001,
                            37.02153320312496
                        ],
                        [
                            68.0677734375,
                            36.949804687500006
                        ],
                        [
                            67.95800781249997,
                            36.972021484375006
                        ],
                        [
                            67.83447265624997,
                            37.06420898437506
                        ],
                        [
                            67.75898437500004,
                            37.172216796875034
                        ],
                        [
                            67.7980468750001,
                            37.244970703125006
                        ],
                        [
                            67.81435546875005,
                            37.48701171875004
                        ],
                        [
                            68.29404296875006,
                            38.032910156249955
                        ],
                        [
                            68.04785156250003,
                            38.669287109375006
                        ],
                        [
                            68.10351562499997,
                            38.96201171875006
                        ],
                        [
                            67.67656250000002,
                            39.00849609375001
                        ],
                        [
                            67.34960937499997,
                            39.242089843749994
                        ],
                        [
                            67.71904296875007,
                            39.62138671875002
                        ],
                        [
                            68.46328125,
                            39.53671874999998
                        ],
                        [
                            68.6525390625001,
                            40.182666015625045
                        ],
                        [
                            69.22832031250007,
                            40.18759765624998
                        ],
                        [
                            69.2062500000001,
                            40.566552734374994
                        ],
                        [
                            69.71289062500003,
                            40.65698242187503
                        ],
                        [
                            70.44150390625006,
                            41.0234375
                        ],
                        [
                            70.75097656250003,
                            40.739599609375006
                        ],
                        [
                            70.38261718750002,
                            40.453515624999966
                        ],
                        [
                            70.95800781250003,
                            40.238867187500034
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Tajikistan',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            66.52226562500007,
                            37.34848632812506
                        ],
                        [
                            66.4718750000001,
                            37.3447265625
                        ],
                        [
                            66.35029296875004,
                            37.36816406249997
                        ],
                        [
                            66.10839843750003,
                            37.414746093749955
                        ],
                        [
                            65.7650390625,
                            37.56914062499996
                        ],
                        [
                            65.55498046875002,
                            37.25117187500004
                        ],
                        [
                            65.30361328125005,
                            37.24677734375001
                        ],
                        [
                            65.08964843750007,
                            37.237939453124994
                        ],
                        [
                            64.9515625,
                            37.19355468750001
                        ],
                        [
                            64.81630859375005,
                            37.13208007812503
                        ],
                        [
                            64.7824218750001,
                            37.05927734375001
                        ],
                        [
                            64.75312500000004,
                            36.964794921874955
                        ],
                        [
                            64.60253906250003,
                            36.554541015625034
                        ],
                        [
                            64.51103515625002,
                            36.34067382812498
                        ],
                        [
                            64.18437500000002,
                            36.14892578125
                        ],
                        [
                            63.8625000000001,
                            36.012353515624994
                        ],
                        [
                            63.30166015625005,
                            35.85839843749997
                        ],
                        [
                            63.1789062500001,
                            35.85844726562499
                        ],
                        [
                            63.12998046875006,
                            35.84619140624997
                        ],
                        [
                            63.10859375000004,
                            35.81870117187506
                        ],
                        [
                            63.12998046875006,
                            35.76674804687505
                        ],
                        [
                            63.15078125000005,
                            35.72827148437503
                        ],
                        [
                            63.169726562500074,
                            35.67812500000002
                        ],
                        [
                            63.05664062500003,
                            35.44580078125003
                        ],
                        [
                            62.98027343750002,
                            35.40917968750003
                        ],
                        [
                            62.85800781250006,
                            35.34965820312499
                        ],
                        [
                            62.72265625000003,
                            35.271337890625006
                        ],
                        [
                            62.688085937500006,
                            35.25532226562504
                        ],
                        [
                            62.6105468750001,
                            35.23315429687506
                        ],
                        [
                            61.98388671874997,
                            35.443701171875034
                        ],
                        [
                            61.54277343750002,
                            35.457861328125006
                        ],
                        [
                            61.34472656249997,
                            35.62949218750006
                        ],
                        [
                            61.26201171875002,
                            35.61958007812498
                        ],
                        [
                            61.11962890625003,
                            36.64257812500003
                        ],
                        [
                            60.34130859375003,
                            36.63764648437501
                        ],
                        [
                            60.06279296875002,
                            36.962890625
                        ],
                        [
                            59.5622070312501,
                            37.178906249999955
                        ],
                        [
                            59.240820312500006,
                            37.52075195312497
                        ],
                        [
                            58.81542968750003,
                            37.683496093749994
                        ],
                        [
                            58.4357421875001,
                            37.63852539062498
                        ],
                        [
                            57.42382812500003,
                            37.94770507812501
                        ],
                        [
                            57.1935546875001,
                            38.216406250000034
                        ],
                        [
                            56.4406250000001,
                            38.249414062499994
                        ],
                        [
                            56.22880859375002,
                            38.07338867187502
                        ],
                        [
                            55.57841796875002,
                            38.099755859374994
                        ],
                        [
                            55.075585937499994,
                            37.90249023437502
                        ],
                        [
                            54.745214843750006,
                            37.501904296874955
                        ],
                        [
                            53.91416015625006,
                            37.34355468750002
                        ],
                        [
                            53.823535156250074,
                            37.92792968749998
                        ],
                        [
                            53.86865234375003,
                            38.949267578125045
                        ],
                        [
                            53.61757812500005,
                            39.21596679687502
                        ],
                        [
                            53.12480468750002,
                            39.43208007812498
                        ],
                        [
                            53.472265625000006,
                            39.66879882812498
                        ],
                        [
                            53.404199218749994,
                            39.96035156250005
                        ],
                        [
                            52.98750000000004,
                            39.98759765625002
                        ],
                        [
                            52.73369140625002,
                            40.39873046875002
                        ],
                        [
                            53.1452148437501,
                            40.82495117187497
                        ],
                        [
                            53.87001953125005,
                            40.64868164062503
                        ],
                        [
                            54.710058593750006,
                            40.89111328125006
                        ],
                        [
                            54.592187499999994,
                            41.19355468750001
                        ],
                        [
                            54.094824218750006,
                            41.51938476562506
                        ],
                        [
                            53.846484375000074,
                            42.091162109375006
                        ],
                        [
                            52.97001953125002,
                            41.97622070312505
                        ],
                        [
                            52.4938476562501,
                            41.780371093750034
                        ],
                        [
                            53.25009765625006,
                            42.20585937500002
                        ],
                        [
                            54.120996093749994,
                            42.335205078125
                        ],
                        [
                            54.90371093750005,
                            41.91909179687505
                        ],
                        [
                            55.54521484375002,
                            41.26274414062499
                        ],
                        [
                            55.97744140625005,
                            41.32221679687504
                        ],
                        [
                            57.017968749999994,
                            41.26347656249996
                        ],
                        [
                            56.96406250000004,
                            41.856542968750006
                        ],
                        [
                            57.38173828125005,
                            42.15629882812496
                        ],
                        [
                            57.814257812500074,
                            42.18984375000005
                        ],
                        [
                            58.25966796875005,
                            42.688085937500034
                        ],
                        [
                            58.5890625000001,
                            42.778466796874966
                        ],
                        [
                            59.451074218749994,
                            42.29951171875001
                        ],
                        [
                            59.858300781249994,
                            42.295166015625
                        ],
                        [
                            60.10693359374997,
                            41.90742187499998
                        ],
                        [
                            60.089648437500074,
                            41.39941406250003
                        ],
                        [
                            60.454980468749994,
                            41.221630859374955
                        ],
                        [
                            61.32890625000002,
                            41.19511718749999
                        ],
                        [
                            61.953515624999994,
                            41.030615234375034
                        ],
                        [
                            62.095019531250074,
                            40.68330078124998
                        ],
                        [
                            62.65068359375002,
                            39.858496093750006
                        ],
                        [
                            64.16279296875004,
                            38.95361328125003
                        ],
                        [
                            65.612890625,
                            38.23857421875002
                        ],
                        [
                            65.8571289062501,
                            38.26875000000004
                        ],
                        [
                            66.629296875,
                            37.932031249999994
                        ],
                        [
                            66.52558593750004,
                            37.785742187500034
                        ],
                        [
                            66.51132812500006,
                            37.59916992187496
                        ],
                        [
                            66.51064453125,
                            37.45869140625004
                        ],
                        [
                            66.52226562500007,
                            37.34848632812506
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Turkmenistan',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            125.06816406250002,
                            -9.511914062499997
                        ],
                        [
                            124.92226562500005,
                            -8.942480468749977
                        ],
                        [
                            125.17802734375002,
                            -8.647851562499994
                        ],
                        [
                            125.80429687500006,
                            -8.4921875
                        ],
                        [
                            126.61972656250006,
                            -8.459472656249986
                        ],
                        [
                            126.91523437500004,
                            -8.715234374999966
                        ],
                        [
                            126.38251953125004,
                            -8.957617187499963
                        ],
                        [
                            125.40800781250002,
                            -9.275781250000023
                        ],
                        [
                            125.06816406250002,
                            -9.511914062499997
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'East Timor',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -61.012109374999966,
                            10.134326171874989
                        ],
                        [
                            -61.59667968750003,
                            10.064648437499997
                        ],
                        [
                            -61.464746093749966,
                            10.538964843749994
                        ],
                        [
                            -61.03374023437502,
                            10.669873046875026
                        ],
                        [
                            -61.012109374999966,
                            10.134326171874989
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Trinidad and Tobago',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            10.274609375000011,
                            31.684960937499994
                        ],
                        [
                            10.114941406250068,
                            31.46376953125005
                        ],
                        [
                            10.216406250000063,
                            30.78320312500003
                        ],
                        [
                            10.05976562500004,
                            30.58007812500003
                        ],
                        [
                            9.932519531250051,
                            30.42534179687496
                        ],
                        [
                            9.895019531250028,
                            30.387304687500034
                        ],
                        [
                            9.807421875000045,
                            30.342236328124983
                        ],
                        [
                            9.51875000000004,
                            30.229394531249994
                        ],
                        [
                            9.4580078125,
                            30.465380859375045
                        ],
                        [
                            9.363281250000057,
                            30.832910156250023
                        ],
                        [
                            9.224023437500023,
                            31.373681640624994
                        ],
                        [
                            9.160253906250006,
                            31.621337890625
                        ],
                        [
                            9.044042968750034,
                            32.072363281250034
                        ],
                        [
                            8.333398437500051,
                            32.54360351562502
                        ],
                        [
                            8.210937500000057,
                            32.92670898437501
                        ],
                        [
                            7.877246093750017,
                            33.172119140625
                        ],
                        [
                            7.762695312500028,
                            33.23310546874998
                        ],
                        [
                            7.627539062500034,
                            33.54863281249996
                        ],
                        [
                            7.534375000000068,
                            33.717919921874994
                        ],
                        [
                            7.500195312500011,
                            33.83247070312498
                        ],
                        [
                            7.495605468750057,
                            33.97651367187501
                        ],
                        [
                            7.513867187500068,
                            34.080517578124955
                        ],
                        [
                            8.24560546875,
                            34.73408203124998
                        ],
                        [
                            8.276855468750057,
                            34.97949218749997
                        ],
                        [
                            8.312109375000063,
                            35.084619140624994
                        ],
                        [
                            8.394238281250011,
                            35.20385742187503
                        ],
                        [
                            8.318066406250011,
                            35.654931640624994
                        ],
                        [
                            8.348730468750063,
                            36.367968750000045
                        ],
                        [
                            8.207617187500006,
                            36.518945312499994
                        ],
                        [
                            8.444238281250051,
                            36.76074218750003
                        ],
                        [
                            8.601269531250068,
                            36.83393554687504
                        ],
                        [
                            8.576562500000023,
                            36.93720703125001
                        ],
                        [
                            9.687988281250057,
                            37.34038085937499
                        ],
                        [
                            10.196386718750063,
                            37.205859375000045
                        ],
                        [
                            10.571289062500057,
                            36.87944335937496
                        ],
                        [
                            11.12666015625004,
                            36.874072265625045
                        ],
                        [
                            10.525683593749989,
                            36.323339843750006
                        ],
                        [
                            10.590820312500028,
                            35.88725585937499
                        ],
                        [
                            11.00429687500008,
                            35.63383789062496
                        ],
                        [
                            11.120117187500057,
                            35.24028320312499
                        ],
                        [
                            10.534863281250011,
                            34.544726562500045
                        ],
                        [
                            10.200390625000068,
                            34.34604492187506
                        ],
                        [
                            10.158984375000074,
                            33.85004882812501
                        ],
                        [
                            10.722753906250006,
                            33.514404296875
                        ],
                        [
                            11.084570312500006,
                            33.56289062500002
                        ],
                        [
                            11.202636718749972,
                            33.24921874999998
                        ],
                        [
                            11.50458984375004,
                            33.181933593750045
                        ],
                        [
                            11.502441406250028,
                            33.15556640624999
                        ],
                        [
                            11.467187500000051,
                            32.96572265625005
                        ],
                        [
                            11.453906250000017,
                            32.64257812500003
                        ],
                        [
                            11.533789062500034,
                            32.52495117187496
                        ],
                        [
                            11.535937500000017,
                            32.47333984375001
                        ],
                        [
                            11.504980468750034,
                            32.413671875000034
                        ],
                        [
                            11.358007812500006,
                            32.34521484375003
                        ],
                        [
                            11.168261718750074,
                            32.25673828125002
                        ],
                        [
                            11.005175781250074,
                            32.17270507812506
                        ],
                        [
                            10.826367187500068,
                            32.080664062500034
                        ],
                        [
                            10.771582031250006,
                            32.02119140625001
                        ],
                        [
                            10.60888671875,
                            31.929541015624977
                        ],
                        [
                            10.47578125000004,
                            31.736035156249983
                        ],
                        [
                            10.274609375000011,
                            31.684960937499994
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Tunisia',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                43.43339843750002,
                                41.155517578125
                            ],
                            [
                                43.43945312500003,
                                41.10712890625001
                            ],
                            [
                                43.72265624999997,
                                40.71953124999999
                            ],
                            [
                                43.56933593750003,
                                40.48237304687498
                            ],
                            [
                                43.79169921875004,
                                40.07026367187498
                            ],
                            [
                                44.28925781250004,
                                40.040380859375006
                            ],
                            [
                                44.76826171875004,
                                39.70351562500005
                            ],
                            [
                                44.81718750000002,
                                39.65043945312496
                            ],
                            [
                                44.02324218750002,
                                39.37744140625006
                            ],
                            [
                                44.43085937500004,
                                38.356787109375034
                            ],
                            [
                                44.211328124999994,
                                37.908056640625006
                            ],
                            [
                                44.56123046875004,
                                37.74462890625003
                            ],
                            [
                                44.76513671875003,
                                37.142431640625006
                            ],
                            [
                                44.73095703124997,
                                37.16528320312503
                            ],
                            [
                                44.66933593750005,
                                37.17358398437503
                            ],
                            [
                                44.60595703124997,
                                37.176025390625
                            ],
                            [
                                44.566015625000006,
                                37.15825195312502
                            ],
                            [
                                44.401953125000006,
                                37.05849609375002
                            ],
                            [
                                44.325585937499994,
                                37.0107421875
                            ],
                            [
                                44.28183593750006,
                                36.97802734374997
                            ],
                            [
                                44.24570312500006,
                                36.983300781249994
                            ],
                            [
                                44.20166015624997,
                                37.05180664062502
                            ],
                            [
                                44.208398437499994,
                                37.20263671875
                            ],
                            [
                                44.191796874999994,
                                37.249853515625034
                            ],
                            [
                                44.15624999999997,
                                37.28295898437503
                            ],
                            [
                                44.11445312500004,
                                37.30185546875006
                            ],
                            [
                                44.01318359375003,
                                37.313525390625045
                            ],
                            [
                                43.51582031250004,
                                37.24453125000002
                            ],
                            [
                                42.77460937500004,
                                37.37187500000002
                            ],
                            [
                                41.886816406250006,
                                37.156396484374994
                            ],
                            [
                                40.70566406250006,
                                37.09770507812502
                            ],
                            [
                                40.4503906250001,
                                37.00888671875006
                            ],
                            [
                                40.016406250000074,
                                36.82607421875002
                            ],
                            [
                                39.68652343749997,
                                36.73862304687506
                            ],
                            [
                                39.50146484374997,
                                36.702246093750034
                            ],
                            [
                                39.35664062500004,
                                36.68159179687498
                            ],
                            [
                                39.10839843749997,
                                36.68056640625005
                            ],
                            [
                                38.76660156249997,
                                36.69311523437503
                            ],
                            [
                                38.19169921875002,
                                36.90156250000004
                            ],
                            [
                                37.90664062500005,
                                36.79462890625001
                            ],
                            [
                                37.720312500000006,
                                36.74370117187502
                            ],
                            [
                                37.52353515625006,
                                36.678320312500034
                            ],
                            [
                                37.436328125000074,
                                36.643310546875
                            ],
                            [
                                37.327050781249994,
                                36.64658203125006
                            ],
                            [
                                37.18740234375005,
                                36.655908203124994
                            ],
                            [
                                37.066210937500074,
                                36.652636718750045
                            ],
                            [
                                36.98535156250003,
                                36.70239257812506
                            ],
                            [
                                36.941796874999994,
                                36.758398437500006
                            ],
                            [
                                36.77656250000004,
                                36.79267578124998
                            ],
                            [
                                36.65859375000005,
                                36.80253906250002
                            ],
                            [
                                36.62841796875003,
                                36.777685546875034
                            ],
                            [
                                36.5968750000001,
                                36.70136718750001
                            ],
                            [
                                36.546679687500074,
                                36.50634765625
                            ],
                            [
                                36.537499999999994,
                                36.457421874999966
                            ],
                            [
                                36.127343749999994,
                                35.831445312499994
                            ],
                            [
                                35.81093750000005,
                                36.30986328125002
                            ],
                            [
                                36.18847656250003,
                                36.65898437499999
                            ],
                            [
                                36.048925781250006,
                                36.91059570312501
                            ],
                            [
                                35.537402343750074,
                                36.59702148437498
                            ],
                            [
                                34.601367187500074,
                                36.78447265624999
                            ],
                            [
                                33.694726562499994,
                                36.18198242187498
                            ],
                            [
                                32.794824218749994,
                                36.03588867187497
                            ],
                            [
                                32.37773437500002,
                                36.18364257812496
                            ],
                            [
                                32.02197265625003,
                                36.53530273437502
                            ],
                            [
                                31.35253906249997,
                                36.80107421874999
                            ],
                            [
                                30.64404296874997,
                                36.86567382812501
                            ],
                            [
                                30.48359375000004,
                                36.310400390625034
                            ],
                            [
                                29.689062500000006,
                                36.15668945312498
                            ],
                            [
                                29.14326171875001,
                                36.39721679687506
                            ],
                            [
                                29.03828125000004,
                                36.693457031250006
                            ],
                            [
                                28.48359375000001,
                                36.803808593750006
                            ],
                            [
                                28.13369140625005,
                                37.02949218749998
                            ],
                            [
                                27.348925781250045,
                                37.01958007812502
                            ],
                            [
                                27.077832031250068,
                                37.687695312499955
                            ],
                            [
                                27.23242187500003,
                                37.978662109374994
                            ],
                            [
                                26.68281250000004,
                                38.198339843750006
                            ],
                            [
                                26.763671875,
                                38.709619140624966
                            ],
                            [
                                27.013671875000057,
                                38.88686523437502
                            ],
                            [
                                26.68183593750004,
                                39.292236328125
                            ],
                            [
                                26.113085937500074,
                                39.46738281249998
                            ],
                            [
                                26.18134765625004,
                                39.99008789062498
                            ],
                            [
                                26.738085937500045,
                                40.40024414062506
                            ],
                            [
                                27.4755859375,
                                40.319921875000034
                            ],
                            [
                                28.2890625,
                                40.40302734374998
                            ],
                            [
                                29.36474609375003,
                                40.80927734374998
                            ],
                            [
                                29.32226562499997,
                                41.227734374999955
                            ],
                            [
                                31.25488281249997,
                                41.10761718750001
                            ],
                            [
                                31.45800781249997,
                                41.32001953125004
                            ],
                            [
                                32.306445312500074,
                                41.72958984374998
                            ],
                            [
                                33.38134765625003,
                                42.01757812500003
                            ],
                            [
                                34.75048828124997,
                                41.95683593749999
                            ],
                            [
                                35.297753906249994,
                                41.72851562500003
                            ],
                            [
                                36.05175781249997,
                                41.68256835937498
                            ],
                            [
                                36.509667968749994,
                                41.262500000000045
                            ],
                            [
                                38.38105468750004,
                                40.92451171875001
                            ],
                            [
                                39.426367187500006,
                                41.10644531250003
                            ],
                            [
                                40.26523437500006,
                                40.96132812500005
                            ],
                            [
                                40.95947265624997,
                                41.21162109374998
                            ],
                            [
                                41.41435546875002,
                                41.42363281249999
                            ],
                            [
                                41.510058593750074,
                                41.51748046875002
                            ],
                            [
                                41.70175781250006,
                                41.471582031249994
                            ],
                            [
                                41.77939453125006,
                                41.44052734374998
                            ],
                            [
                                41.823535156250074,
                                41.432373046875
                            ],
                            [
                                41.92578125000003,
                                41.49565429687502
                            ],
                            [
                                42.466406250000006,
                                41.43984375000002
                            ],
                            [
                                42.787890625000074,
                                41.56372070312503
                            ],
                            [
                                43.43339843750002,
                                41.155517578125
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                26.03896484375008,
                                40.726757812499955
                            ],
                            [
                                26.62490234375008,
                                41.401757812499994
                            ],
                            [
                                26.320898437500034,
                                41.716552734375
                            ],
                            [
                                26.549707031250023,
                                41.896728515625
                            ],
                            [
                                26.579687500000006,
                                41.947949218749955
                            ],
                            [
                                26.615332031250063,
                                41.964892578125045
                            ],
                            [
                                26.884863281250006,
                                41.99184570312502
                            ],
                            [
                                26.96875000000003,
                                42.02685546875006
                            ],
                            [
                                27.01171875,
                                42.05864257812496
                            ],
                            [
                                27.193359375000057,
                                42.07709960937498
                            ],
                            [
                                27.24433593750004,
                                42.09326171875
                            ],
                            [
                                27.294921875000057,
                                42.079541015624955
                            ],
                            [
                                27.362890625000034,
                                42.02504882812505
                            ],
                            [
                                27.47480468750001,
                                41.946875000000006
                            ],
                            [
                                27.53486328125001,
                                41.92080078125002
                            ],
                            [
                                27.661132812500057,
                                41.96132812500002
                            ],
                            [
                                27.80166015625008,
                                41.95654296875003
                            ],
                            [
                                27.831933593750023,
                                41.981298828125006
                            ],
                            [
                                27.879199218750074,
                                41.98662109375002
                            ],
                            [
                                28.014453125000017,
                                41.96904296874999
                            ],
                            [
                                28.197851562500063,
                                41.55449218750002
                            ],
                            [
                                29.057226562500006,
                                41.22973632812503
                            ],
                            [
                                28.780371093749977,
                                40.97416992187499
                            ],
                            [
                                28.172167968750074,
                                41.08071289062502
                            ],
                            [
                                27.49941406250005,
                                40.97314453124997
                            ],
                            [
                                27.258007812499983,
                                40.687353515625006
                            ],
                            [
                                26.10546875000003,
                                40.61132812499997
                            ],
                            [
                                26.03896484375008,
                                40.726757812499955
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Turkey',
                'childNum': 2
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            33.90322265625005,
                            -1.002050781250034
                        ],
                        [
                            33.979394531249994,
                            -1.002050781250034
                        ],
                        [
                            35.83691406249997,
                            -2.036621093749943
                        ],
                        [
                            37.643847656250074,
                            -3.0454101562500284
                        ],
                        [
                            37.608691406250074,
                            -3.4602539062499886
                        ],
                        [
                            37.79726562500005,
                            -3.6744140625000057
                        ],
                        [
                            39.221777343750006,
                            -4.692382812500014
                        ],
                        [
                            38.81923828125005,
                            -5.877636718749983
                        ],
                        [
                            38.87402343750003,
                            -6.331250000000026
                        ],
                        [
                            39.47236328125004,
                            -6.878613281249983
                        ],
                        [
                            39.51923828125004,
                            -7.1241210937499915
                        ],
                        [
                            39.288476562499994,
                            -7.517871093750003
                        ],
                        [
                            39.441015625000006,
                            -8.011523437499946
                        ],
                        [
                            39.304003906250074,
                            -8.44384765625
                        ],
                        [
                            39.783789062500006,
                            -9.914550781249957
                        ],
                        [
                            40.46357421875004,
                            -10.464355468749972
                        ],
                        [
                            39.988671874999994,
                            -10.820800781250014
                        ],
                        [
                            39.56347656249997,
                            -10.978515625
                        ],
                        [
                            39.321582031250074,
                            -11.122558593749943
                        ],
                        [
                            39.17099609375006,
                            -11.166894531250009
                        ],
                        [
                            38.98750000000004,
                            -11.167285156250003
                        ],
                        [
                            38.79472656250002,
                            -11.228906250000023
                        ],
                        [
                            38.49179687500006,
                            -11.413281250000026
                        ],
                        [
                            37.92021484375002,
                            -11.294726562500031
                        ],
                        [
                            37.54169921875004,
                            -11.675097656249974
                        ],
                        [
                            36.97890625000005,
                            -11.566992187499977
                        ],
                        [
                            36.87265625000006,
                            -11.571289062499972
                        ],
                        [
                            36.77109375000006,
                            -11.610351562500028
                        ],
                        [
                            36.67382812499997,
                            -11.684277343749983
                        ],
                        [
                            36.51865234375006,
                            -11.716210937500009
                        ],
                        [
                            36.30566406250003,
                            -11.706347656249946
                        ],
                        [
                            36.191308593749994,
                            -11.670703124999974
                        ],
                        [
                            35.785449218750074,
                            -11.452929687500017
                        ],
                        [
                            35.63095703125006,
                            -11.582031250000028
                        ],
                        [
                            35.564355468749994,
                            -11.602343749999989
                        ],
                        [
                            35.50439453125003,
                            -11.60478515624996
                        ],
                        [
                            35.418261718750074,
                            -11.583203124999997
                        ],
                        [
                            35.18261718750003,
                            -11.574804687499977
                        ],
                        [
                            34.95947265625003,
                            -11.578125000000028
                        ],
                        [
                            34.95263671874997,
                            -11.54374999999996
                        ],
                        [
                            34.93701171874997,
                            -11.463476562500034
                        ],
                        [
                            34.77382812500005,
                            -11.341699218750009
                        ],
                        [
                            34.59765625000003,
                            -11.037500000000023
                        ],
                        [
                            34.66181640625004,
                            -10.71005859375002
                        ],
                        [
                            34.524218750000074,
                            -10.03017578124998
                        ],
                        [
                            34.32089843750006,
                            -9.731542968749977
                        ],
                        [
                            32.91992187500003,
                            -9.407421875000026
                        ],
                        [
                            32.75664062500002,
                            -9.322265625
                        ],
                        [
                            31.94257812500004,
                            -9.05400390624996
                        ],
                        [
                            31.673632812500017,
                            -8.908789062499963
                        ],
                        [
                            31.534863281250097,
                            -8.713281250000009
                        ],
                        [
                            31.44921874999997,
                            -8.653906249999991
                        ],
                        [
                            31.35058593750003,
                            -8.607031250000034
                        ],
                        [
                            31.07636718750004,
                            -8.611914062499963
                        ],
                        [
                            30.830664062500063,
                            -8.385546875000031
                        ],
                        [
                            30.720898437500097,
                            -8.104394531250037
                        ],
                        [
                            30.40673828125003,
                            -7.460644531249983
                        ],
                        [
                            30.313183593750097,
                            -7.203710937499949
                        ],
                        [
                            30.212695312500017,
                            -7.037890625000017
                        ],
                        [
                            30.106250000000017,
                            -6.915039062500028
                        ],
                        [
                            29.961816406249994,
                            -6.803124999999966
                        ],
                        [
                            29.798144531250017,
                            -6.691894531249957
                        ],
                        [
                            29.70966796875004,
                            -6.61689453125004
                        ],
                        [
                            29.590625000000017,
                            -6.394433593750023
                        ],
                        [
                            29.540820312500017,
                            -6.313867187500037
                        ],
                        [
                            29.506249999999994,
                            -6.172070312500011
                        ],
                        [
                            29.480078125000006,
                            -6.024999999999977
                        ],
                        [
                            29.490820312500063,
                            -5.96542968750002
                        ],
                        [
                            29.59638671875004,
                            -5.775976562499963
                        ],
                        [
                            29.59414062500005,
                            -5.650781250000037
                        ],
                        [
                            29.47646484375005,
                            -5.316601562499997
                        ],
                        [
                            29.42011718750004,
                            -5.17617187499998
                        ],
                        [
                            29.34277343749997,
                            -4.983105468749997
                        ],
                        [
                            29.32568359374997,
                            -4.835644531249969
                        ],
                        [
                            29.404199218749994,
                            -4.49667968750002
                        ],
                        [
                            29.40322265625005,
                            -4.449316406249963
                        ],
                        [
                            29.71777343750003,
                            -4.45585937499996
                        ],
                        [
                            29.94726562499997,
                            -4.307324218749983
                        ],
                        [
                            30.147167968750097,
                            -4.08535156249998
                        ],
                        [
                            30.400000000000006,
                            -3.6539062500000057
                        ],
                        [
                            30.811132812500006,
                            -3.1164062500000114
                        ],
                        [
                            30.515039062499994,
                            -2.9175781250000057
                        ],
                        [
                            30.433496093749994,
                            -2.8745117187500284
                        ],
                        [
                            30.424023437500097,
                            -2.82402343749996
                        ],
                        [
                            30.42421875000005,
                            -2.641601562500014
                        ],
                        [
                            30.441992187500006,
                            -2.6134765624999687
                        ],
                        [
                            30.53369140624997,
                            -2.426269531250014
                        ],
                        [
                            30.55361328125005,
                            -2.4000976562500114
                        ],
                        [
                            30.593359375000063,
                            -2.39677734374996
                        ],
                        [
                            30.65664062500005,
                            -2.3738281249999886
                        ],
                        [
                            30.71484375000003,
                            -2.3634765625000114
                        ],
                        [
                            30.828710937500006,
                            -2.3384765624999773
                        ],
                        [
                            30.876562500000006,
                            -2.143359375000017
                        ],
                        [
                            30.819140625000017,
                            -1.967480468749983
                        ],
                        [
                            30.82753906250005,
                            -1.6936523437499744
                        ],
                        [
                            30.812597656250006,
                            -1.56308593750002
                        ],
                        [
                            30.76220703124997,
                            -1.458691406249983
                        ],
                        [
                            30.710742187500074,
                            -1.3967773437499744
                        ],
                        [
                            30.631933593750006,
                            -1.3674804687499602
                        ],
                        [
                            30.508105468750074,
                            -1.2082031250000256
                        ],
                        [
                            30.47021484374997,
                            -1.1311523437499602
                        ],
                        [
                            30.47705078124997,
                            -1.0830078125
                        ],
                        [
                            30.509960937500097,
                            -1.0672851562499943
                        ],
                        [
                            30.519921874999994,
                            -1.0625
                        ],
                        [
                            30.741992187500017,
                            -1.0075195312499972
                        ],
                        [
                            30.809179687500063,
                            -0.9949218750000028
                        ],
                        [
                            30.82363281250005,
                            -0.9990234374999432
                        ],
                        [
                            30.84472656250003,
                            -1.002050781250034
                        ],
                        [
                            31.48310546875004,
                            -1.002050781250034
                        ],
                        [
                            32.905175781249994,
                            -1.002050781250034
                        ],
                        [
                            33.90322265625005,
                            -1.002050781250034
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'United Republic of Tanzania',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            30.50996093750001,
                            -1.0672851562500085
                        ],
                        [
                            30.46992187500001,
                            -1.0660156250000057
                        ],
                        [
                            30.41230468750001,
                            -1.0630859375000057
                        ],
                        [
                            30.360253906250023,
                            -1.0746093750000085
                        ],
                        [
                            30.1015625,
                            -1.36865234375
                        ],
                        [
                            29.576953125000017,
                            -1.3878906250000114
                        ],
                        [
                            29.561914062500023,
                            -0.9773437500000028
                        ],
                        [
                            29.717675781250023,
                            0.09833984374999716
                        ],
                        [
                            29.934472656250023,
                            0.4990234375
                        ],
                        [
                            29.94287109375,
                            0.8192382812499943
                        ],
                        [
                            30.94257812500001,
                            1.6828124999999972
                        ],
                        [
                            31.252734375000017,
                            2.0445800781249943
                        ],
                        [
                            31.176367187500006,
                            2.2700683593749886
                        ],
                        [
                            30.728613281250006,
                            2.4553710937499886
                        ],
                        [
                            30.83857421875001,
                            3.49072265625
                        ],
                        [
                            31.15234375,
                            3.785595703124997
                        ],
                        [
                            31.47998046875,
                            3.6804687499999886
                        ],
                        [
                            31.79804687500001,
                            3.8026367187499943
                        ],
                        [
                            32.09941406250002,
                            3.5291992187499943
                        ],
                        [
                            32.33574218750002,
                            3.7062011718749943
                        ],
                        [
                            32.83808593750001,
                            3.7984863281249943
                        ],
                        [
                            33.489355468750006,
                            3.755078124999997
                        ],
                        [
                            33.568457031250006,
                            3.811718749999997
                        ],
                        [
                            33.74160156250002,
                            3.9852539062499943
                        ],
                        [
                            33.97607421875,
                            4.22021484375
                        ],
                        [
                            34.13203125000001,
                            3.88916015625
                        ],
                        [
                            34.18574218750001,
                            3.8697753906249943
                        ],
                        [
                            34.1650390625,
                            3.81298828125
                        ],
                        [
                            34.39287109375002,
                            3.6915039062499915
                        ],
                        [
                            34.447851562500006,
                            3.1634765624999943
                        ],
                        [
                            34.74248046875002,
                            2.818115234375
                        ],
                        [
                            34.96406250000001,
                            2.0624023437499943
                        ],
                        [
                            34.96523437500002,
                            1.6433593749999886
                        ],
                        [
                            34.78759765625,
                            1.230712890625
                        ],
                        [
                            34.64912109375001,
                            1.185302734375
                        ],
                        [
                            34.535253906250006,
                            1.1015625
                        ],
                        [
                            34.48173828125002,
                            1.0421386718749943
                        ],
                        [
                            34.41083984375001,
                            0.8672851562499915
                        ],
                        [
                            34.16093750000002,
                            0.6051757812499972
                        ],
                        [
                            33.94316406250002,
                            0.17377929687499716
                        ],
                        [
                            33.90322265625002,
                            -1.0020507812500057
                        ],
                        [
                            32.90517578125002,
                            -1.0020507812500057
                        ],
                        [
                            31.48310546875001,
                            -1.0020507812500057
                        ],
                        [
                            30.8447265625,
                            -1.0020507812500057
                        ],
                        [
                            30.823632812500023,
                            -0.9990234375
                        ],
                        [
                            30.809179687500006,
                            -0.9949218750000028
                        ],
                        [
                            30.741992187500017,
                            -1.0075195312500114
                        ],
                        [
                            30.672753906250023,
                            -1.0513671875000057
                        ],
                        [
                            30.598730468750006,
                            -1.0697265625000085
                        ],
                        [
                            30.519921875000023,
                            -1.0625
                        ],
                        [
                            30.50996093750001,
                            -1.0672851562500085
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Uganda',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            38.21435546875003,
                            47.091455078124966
                        ],
                        [
                            37.543359374999994,
                            47.07456054687498
                        ],
                        [
                            37.33984375000003,
                            46.91689453125002
                        ],
                        [
                            36.2794921875001,
                            46.658593749999966
                        ],
                        [
                            35.82714843749997,
                            46.62431640625002
                        ],
                        [
                            34.84960937500003,
                            46.189892578124955
                        ],
                        [
                            35.02285156250005,
                            45.70097656250002
                        ],
                        [
                            35.558007812499994,
                            45.310888671875034
                        ],
                        [
                            36.170507812500006,
                            45.453076171874955
                        ],
                        [
                            36.39335937500002,
                            45.06538085937501
                        ],
                        [
                            35.472558593749994,
                            45.098486328125006
                        ],
                        [
                            35.08769531250002,
                            44.802636718749966
                        ],
                        [
                            34.716894531250006,
                            44.80712890625
                        ],
                        [
                            33.909960937500074,
                            44.387597656249966
                        ],
                        [
                            33.45068359374997,
                            44.553662109374955
                        ],
                        [
                            33.55517578125003,
                            45.09765625000003
                        ],
                        [
                            32.5080078125001,
                            45.40380859375006
                        ],
                        [
                            33.664843750000074,
                            45.94707031249996
                        ],
                        [
                            33.20224609375006,
                            46.17573242187501
                        ],
                        [
                            32.47675781250004,
                            46.08369140625001
                        ],
                        [
                            32.035742187500006,
                            46.26098632812497
                        ],
                        [
                            31.974316406250097,
                            46.70878906249999
                        ],
                        [
                            30.796289062499994,
                            46.55200195312503
                        ],
                        [
                            30.219042968750074,
                            45.866748046875045
                        ],
                        [
                            29.601660156250063,
                            45.682519531249994
                        ],
                        [
                            28.78828125000001,
                            45.240966796875
                        ],
                        [
                            28.451269531250006,
                            45.292187499999955
                        ],
                        [
                            28.317675781250045,
                            45.347119140624955
                        ],
                        [
                            28.212500000000034,
                            45.45043945312506
                        ],
                        [
                            28.26484375000004,
                            45.48388671875003
                        ],
                        [
                            28.310351562500074,
                            45.49858398437499
                        ],
                        [
                            28.47138671875001,
                            45.50717773437506
                        ],
                        [
                            28.499023437500057,
                            45.517724609374994
                        ],
                        [
                            28.513769531250034,
                            45.57241210937502
                        ],
                        [
                            28.49160156250005,
                            45.66577148437503
                        ],
                        [
                            28.94775390624997,
                            46.049951171874966
                        ],
                        [
                            28.97187500000001,
                            46.12763671874998
                        ],
                        [
                            29.00625000000008,
                            46.17646484374998
                        ],
                        [
                            28.94375000000005,
                            46.28842773437506
                        ],
                        [
                            28.930566406250023,
                            46.36225585937501
                        ],
                        [
                            28.92744140625001,
                            46.42412109374999
                        ],
                        [
                            29.146289062500017,
                            46.52690429687496
                        ],
                        [
                            29.458789062500017,
                            46.453759765624994
                        ],
                        [
                            29.83789062499997,
                            46.35053710937501
                        ],
                        [
                            29.92431640624997,
                            46.53886718750002
                        ],
                        [
                            29.934765625000097,
                            46.62499999999997
                        ],
                        [
                            29.942480468750063,
                            46.72377929687502
                        ],
                        [
                            29.877832031249994,
                            46.828906250000045
                        ],
                        [
                            29.455664062500006,
                            47.292626953124994
                        ],
                        [
                            29.159765625000006,
                            47.455664062500006
                        ],
                        [
                            29.12294921875005,
                            47.530371093750034
                        ],
                        [
                            29.18603515625003,
                            47.65859375000005
                        ],
                        [
                            29.210742187500017,
                            47.73154296874998
                        ],
                        [
                            29.211132812500097,
                            47.775000000000034
                        ],
                        [
                            29.194824218750057,
                            47.88242187499998
                        ],
                        [
                            28.77382812500008,
                            48.11958007812498
                        ],
                        [
                            28.601660156250006,
                            48.14438476562498
                        ],
                        [
                            28.530468750000068,
                            48.15029296875002
                        ],
                        [
                            28.34052734375001,
                            48.144433593749994
                        ],
                        [
                            27.54921875000008,
                            48.47773437500004
                        ],
                        [
                            26.4423828125,
                            48.22998046875
                        ],
                        [
                            26.162695312500063,
                            47.992529296875034
                        ],
                        [
                            25.90869140625,
                            47.96757812500002
                        ],
                        [
                            25.46425781250005,
                            47.910791015624994
                        ],
                        [
                            24.89335937499999,
                            47.71777343750003
                        ],
                        [
                            24.578906250000074,
                            47.93105468750005
                        ],
                        [
                            23.628710937500017,
                            47.995849609375
                        ],
                        [
                            22.582421875000023,
                            48.134033203125
                        ],
                        [
                            22.253710937500017,
                            48.407373046874994
                        ],
                        [
                            22.142871093750017,
                            48.568505859374966
                        ],
                        [
                            22.295214843750045,
                            48.68583984374999
                        ],
                        [
                            22.432031250000023,
                            48.93354492187498
                        ],
                        [
                            22.57998046875005,
                            49.077197265625045
                        ],
                        [
                            22.705664062500006,
                            49.17119140624999
                        ],
                        [
                            22.6494140625,
                            49.53901367187498
                        ],
                        [
                            23.711718750000045,
                            50.377343749999966
                        ],
                        [
                            24.105761718750045,
                            50.84497070312503
                        ],
                        [
                            23.664453125000023,
                            51.31005859375
                        ],
                        [
                            23.61376953125,
                            51.525390625
                        ],
                        [
                            24.361914062500006,
                            51.86752929687498
                        ],
                        [
                            25.785742187500006,
                            51.923828125
                        ],
                        [
                            27.14199218750008,
                            51.75205078124998
                        ],
                        [
                            27.689746093750017,
                            51.572412109374994
                        ],
                        [
                            28.59902343750008,
                            51.54262695312505
                        ],
                        [
                            28.647753906250074,
                            51.45654296875
                        ],
                        [
                            28.690234375000017,
                            51.43886718750005
                        ],
                        [
                            28.731250000000017,
                            51.43339843749999
                        ],
                        [
                            28.793261718750045,
                            51.510351562500034
                        ],
                        [
                            28.97773437500004,
                            51.57177734375003
                        ],
                        [
                            29.01308593750005,
                            51.59892578124996
                        ],
                        [
                            29.06074218750001,
                            51.625439453124955
                        ],
                        [
                            29.102050781250057,
                            51.627539062500034
                        ],
                        [
                            29.13564453125008,
                            51.61728515624998
                        ],
                        [
                            29.174218750000023,
                            51.58061523437496
                        ],
                        [
                            29.23046875000003,
                            51.49702148437498
                        ],
                        [
                            29.29882812500003,
                            51.41303710937501
                        ],
                        [
                            29.346484375000017,
                            51.38256835937503
                        ],
                        [
                            30.160742187500006,
                            51.477880859375006
                        ],
                        [
                            30.449511718750017,
                            51.274316406249994
                        ],
                        [
                            30.533007812500017,
                            51.596337890624966
                        ],
                        [
                            30.980664062500097,
                            52.04619140624996
                        ],
                        [
                            31.217968750000097,
                            52.05024414062498
                        ],
                        [
                            31.345996093750074,
                            52.10537109375002
                        ],
                        [
                            31.57373046875003,
                            52.108105468749955
                        ],
                        [
                            31.763378906250097,
                            52.10107421875003
                        ],
                        [
                            32.507910156250006,
                            52.30854492187504
                        ],
                        [
                            33.735253906249994,
                            52.344775390625045
                        ],
                        [
                            34.397851562499994,
                            51.780419921874994
                        ],
                        [
                            34.280664062499994,
                            51.311669921874966
                        ],
                        [
                            35.31191406250005,
                            51.043896484374955
                        ],
                        [
                            35.41162109375003,
                            50.539697265624994
                        ],
                        [
                            36.619433593750074,
                            50.209228515625
                        ],
                        [
                            37.42285156249997,
                            50.411474609375006
                        ],
                        [
                            38.04687500000003,
                            49.92001953125006
                        ],
                        [
                            38.258593750000074,
                            50.052343750000006
                        ],
                        [
                            39.17480468750003,
                            49.85595703124997
                        ],
                        [
                            39.780566406250074,
                            49.57202148437503
                        ],
                        [
                            40.07001953125004,
                            49.20029296874998
                        ],
                        [
                            39.68652343749997,
                            49.007910156250034
                        ],
                        [
                            39.96103515625006,
                            48.23793945312502
                        ],
                        [
                            39.735937500000006,
                            47.84482421875006
                        ],
                        [
                            38.90029296875005,
                            47.85512695312502
                        ],
                        [
                            38.287402343750074,
                            47.559179687500034
                        ],
                        [
                            38.21435546875003,
                            47.091455078124966
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Ukraine',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -57.81059570312499,
                            -30.85859375000001
                        ],
                        [
                            -57.872509765625,
                            -30.59101562500001
                        ],
                        [
                            -57.55229492187499,
                            -30.26123046875
                        ],
                        [
                            -57.21445312499999,
                            -30.28339843750001
                        ],
                        [
                            -56.83271484375,
                            -30.107226562500003
                        ],
                        [
                            -56.4072265625,
                            -30.44746093750001
                        ],
                        [
                            -55.998974609375,
                            -30.837207031250003
                        ],
                        [
                            -56.018457031249994,
                            -30.99189453125001
                        ],
                        [
                            -56.015527343749994,
                            -31.059667968750006
                        ],
                        [
                            -56.00468749999999,
                            -31.079199218750006
                        ],
                        [
                            -55.95200195312499,
                            -31.080859375000003
                        ],
                        [
                            -55.873681640624994,
                            -31.069628906250003
                        ],
                        [
                            -55.807763671874994,
                            -31.036718750000006
                        ],
                        [
                            -55.75634765625,
                            -30.987109375000003
                        ],
                        [
                            -55.705957031249994,
                            -30.946582031250003
                        ],
                        [
                            -55.665234375,
                            -30.924902343750006
                        ],
                        [
                            -55.55732421875,
                            -30.8759765625
                        ],
                        [
                            -55.44956054687499,
                            -30.964453125000006
                        ],
                        [
                            -55.36606445312499,
                            -31.046191406250003
                        ],
                        [
                            -55.254638671875,
                            -31.2255859375
                        ],
                        [
                            -55.17353515625,
                            -31.279589843750003
                        ],
                        [
                            -55.09116210937499,
                            -31.31396484375
                        ],
                        [
                            -54.89599609375,
                            -31.391210937500006
                        ],
                        [
                            -54.587646484375,
                            -31.485156250000003
                        ],
                        [
                            -54.22055664062499,
                            -31.85517578125001
                        ],
                        [
                            -53.92060546875,
                            -31.95234375000001
                        ],
                        [
                            -53.76171875,
                            -32.05683593750001
                        ],
                        [
                            -53.601708984374994,
                            -32.40302734375001
                        ],
                        [
                            -53.12558593749999,
                            -32.73671875000001
                        ],
                        [
                            -53.2140625,
                            -32.82109375
                        ],
                        [
                            -53.39521484375,
                            -33.010351562500006
                        ],
                        [
                            -53.53134765624999,
                            -33.1708984375
                        ],
                        [
                            -53.53037109374999,
                            -33.50029296875
                        ],
                        [
                            -53.53764648437499,
                            -33.6228515625
                        ],
                        [
                            -53.53134765624999,
                            -33.65546875000001
                        ],
                        [
                            -53.51884765624999,
                            -33.67724609375
                        ],
                        [
                            -53.37060546875,
                            -33.7421875
                        ],
                        [
                            -53.419580078124994,
                            -33.77919921875001
                        ],
                        [
                            -53.47246093749999,
                            -33.84931640625001
                        ],
                        [
                            -53.53452148437499,
                            -34.01748046875001
                        ],
                        [
                            -53.785302734374994,
                            -34.38037109375
                        ],
                        [
                            -54.16855468749999,
                            -34.670703125
                        ],
                        [
                            -54.902294921875,
                            -34.93281250000001
                        ],
                        [
                            -55.67314453124999,
                            -34.77568359375
                        ],
                        [
                            -56.117919921875,
                            -34.90791015625001
                        ],
                        [
                            -56.85517578125,
                            -34.67666015625001
                        ],
                        [
                            -57.17070312499999,
                            -34.45234375000001
                        ],
                        [
                            -57.8291015625,
                            -34.47734375
                        ],
                        [
                            -58.40019531249999,
                            -33.91240234375
                        ],
                        [
                            -58.35336914062499,
                            -33.26005859375
                        ],
                        [
                            -58.08232421874999,
                            -32.893652343750006
                        ],
                        [
                            -58.12958984375,
                            -32.75722656250001
                        ],
                        [
                            -58.16220703124999,
                            -32.566503906250006
                        ],
                        [
                            -58.201171875,
                            -32.4716796875
                        ],
                        [
                            -58.18901367187499,
                            -31.92421875000001
                        ],
                        [
                            -57.81059570312499,
                            -30.85859375000001
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Uruguay',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                -155.58134765624996,
                                19.012011718750017
                            ],
                            [
                                -155.88129882812495,
                                19.07050781250001
                            ],
                            [
                                -156.04868164062498,
                                19.749951171874983
                            ],
                            [
                                -155.83164062499998,
                                20.27583007812501
                            ],
                            [
                                -155.198779296875,
                                19.99438476562503
                            ],
                            [
                                -154.80419921875,
                                19.524462890625045
                            ],
                            [
                                -155.58134765624996,
                                19.012011718750017
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -72.50976562500003,
                                40.98603515625001
                            ],
                            [
                                -73.19428710937495,
                                40.654199218749994
                            ],
                            [
                                -73.573828125,
                                40.91962890624998
                            ],
                            [
                                -72.50976562500003,
                                40.98603515625001
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -94.80346679687497,
                                49.0029296875
                            ],
                            [
                                -94.71279296874997,
                                48.863427734374994
                            ],
                            [
                                -93.85161132812496,
                                48.607275390625034
                            ],
                            [
                                -93.37788085937498,
                                48.61655273437498
                            ],
                            [
                                -93.25795898437497,
                                48.62885742187501
                            ],
                            [
                                -92.83671874999996,
                                48.567773437499994
                            ],
                            [
                                -92.50058593749995,
                                48.43535156250002
                            ],
                            [
                                -92.41459960937493,
                                48.276611328125
                            ],
                            [
                                -92.34843749999996,
                                48.276611328125
                            ],
                            [
                                -92.00517578125002,
                                48.301855468750006
                            ],
                            [
                                -91.38720703124997,
                                48.05854492187498
                            ],
                            [
                                -90.79731445312495,
                                48.13105468750001
                            ],
                            [
                                -89.4556640625,
                                47.996240234374994
                            ],
                            [
                                -88.37817382812497,
                                48.30307617187498
                            ],
                            [
                                -87.74389648437497,
                                48.06054687500003
                            ],
                            [
                                -87.20800781249997,
                                47.848486328125006
                            ],
                            [
                                -86.67216796874996,
                                47.636425781249955
                            ],
                            [
                                -85.65224609375,
                                47.21997070312503
                            ],
                            [
                                -85.07006835937497,
                                46.97993164062498
                            ],
                            [
                                -84.87597656249994,
                                46.89990234375003
                            ],
                            [
                                -84.66577148437503,
                                46.54326171875002
                            ],
                            [
                                -84.44047851562496,
                                46.49814453125006
                            ],
                            [
                                -84.12319335937497,
                                46.50292968749997
                            ],
                            [
                                -83.97778320312503,
                                46.08491210937498
                            ],
                            [
                                -83.61596679687503,
                                46.116845703124994
                            ],
                            [
                                -83.59267578125,
                                45.81713867187506
                            ],
                            [
                                -82.91933593749994,
                                45.51796875000002
                            ],
                            [
                                -82.48505859374993,
                                45.08374023437503
                            ],
                            [
                                -82.137841796875,
                                43.570898437500034
                            ],
                            [
                                -82.19038085937495,
                                43.47407226562501
                            ],
                            [
                                -82.54531249999997,
                                42.62470703124998
                            ],
                            [
                                -83.10952148437497,
                                42.25068359375001
                            ],
                            [
                                -83.141943359375,
                                41.97587890624996
                            ],
                            [
                                -82.69003906249995,
                                41.675195312499994
                            ],
                            [
                                -81.97416992187496,
                                41.88872070312499
                            ],
                            [
                                -81.50732421874997,
                                42.10346679687504
                            ],
                            [
                                -81.02822265624997,
                                42.247167968750006
                            ],
                            [
                                -80.24755859375,
                                42.36601562499996
                            ],
                            [
                                -79.17373046875,
                                42.74853515625
                            ],
                            [
                                -78.91508789062496,
                                42.90913085937504
                            ],
                            [
                                -78.98076171874993,
                                42.98061523437502
                            ],
                            [
                                -79.02617187499996,
                                43.01733398437506
                            ],
                            [
                                -79.066064453125,
                                43.10610351562502
                            ],
                            [
                                -79.17187499999997,
                                43.466552734375
                            ],
                            [
                                -79.00249023437502,
                                43.52714843749999
                            ],
                            [
                                -78.845556640625,
                                43.58334960937498
                            ],
                            [
                                -78.72041015625001,
                                43.62495117187501
                            ],
                            [
                                -78.45825195312497,
                                43.63149414062502
                            ],
                            [
                                -77.87924804687498,
                                43.629541015624994
                            ],
                            [
                                -76.819970703125,
                                43.62880859375002
                            ],
                            [
                                -76.18579101562503,
                                44.24223632812502
                            ],
                            [
                                -75.81933593749997,
                                44.468017578125
                            ],
                            [
                                -75.40126953124997,
                                44.77226562499999
                            ],
                            [
                                -74.99614257812496,
                                44.970117187499966
                            ],
                            [
                                -74.76245117187494,
                                44.99907226562502
                            ],
                            [
                                -74.663232421875,
                                45.00390625000003
                            ],
                            [
                                -71.51752929687495,
                                45.00756835937497
                            ],
                            [
                                -71.327294921875,
                                45.29008789062496
                            ],
                            [
                                -70.89799804687493,
                                45.26245117187503
                            ],
                            [
                                -70.296240234375,
                                45.90610351562506
                            ],
                            [
                                -70.00771484375002,
                                46.70893554687501
                            ],
                            [
                                -69.24287109374998,
                                47.46298828124998
                            ],
                            [
                                -68.82871093749998,
                                47.20332031250001
                            ],
                            [
                                -68.310888671875,
                                47.3544921875
                            ],
                            [
                                -67.806787109375,
                                47.08281249999999
                            ],
                            [
                                -67.78466796875,
                                45.70170898437499
                            ],
                            [
                                -67.43266601562496,
                                45.60312499999998
                            ],
                            [
                                -67.47255859375002,
                                45.27587890625
                            ],
                            [
                                -67.12485351562498,
                                45.16943359375
                            ],
                            [
                                -67.10673828125002,
                                44.88505859374996
                            ],
                            [
                                -67.90703124999999,
                                44.45361328125
                            ],
                            [
                                -68.27744140625003,
                                44.50737304687502
                            ],
                            [
                                -69.79160156249995,
                                43.80522460937499
                            ],
                            [
                                -70.17880859374998,
                                43.76635742187506
                            ],
                            [
                                -70.82905273437493,
                                42.82534179687502
                            ],
                            [
                                -71.04619140624993,
                                42.331103515625045
                            ],
                            [
                                -70.73828125,
                                42.228857421875006
                            ],
                            [
                                -70.4046875,
                                41.62690429687501
                            ],
                            [
                                -70.65712890625,
                                41.53422851562496
                            ],
                            [
                                -71.42656249999999,
                                41.63330078125
                            ],
                            [
                                -71.52285156249997,
                                41.378955078125045
                            ],
                            [
                                -72.84716796875003,
                                41.265869140625
                            ],
                            [
                                -73.5830078125,
                                41.02187500000005
                            ],
                            [
                                -74.22670898437497,
                                40.608007812500006
                            ],
                            [
                                -73.95761718750003,
                                40.32836914062497
                            ],
                            [
                                -74.17612304687495,
                                39.726611328125045
                            ],
                            [
                                -74.42880859375,
                                39.38720703125
                            ],
                            [
                                -74.89702148437502,
                                39.14545898437504
                            ],
                            [
                                -75.3921875,
                                39.09277343750006
                            ],
                            [
                                -75.03588867187497,
                                38.50332031250005
                            ],
                            [
                                -75.65927734374995,
                                37.953955078125034
                            ],
                            [
                                -76.501318359375,
                                38.53217773437498
                            ],
                            [
                                -76.26425781250003,
                                37.89355468749997
                            ],
                            [
                                -76.26347656249996,
                                37.357031250000006
                            ],
                            [
                                -75.89042968749999,
                                36.65703124999996
                            ],
                            [
                                -76.47880859374993,
                                36.028173828125006
                            ],
                            [
                                -75.77221679687497,
                                35.89990234375
                            ],
                            [
                                -75.77392578124997,
                                35.64697265624997
                            ],
                            [
                                -76.51293945312497,
                                35.270410156249994
                            ],
                            [
                                -76.74497070312498,
                                34.94096679687502
                            ],
                            [
                                -77.750732421875,
                                34.28496093749996
                            ],
                            [
                                -78.01333007812502,
                                33.91181640624998
                            ],
                            [
                                -78.57768554687496,
                                33.87324218750001
                            ],
                            [
                                -78.92031249999997,
                                33.65869140625
                            ],
                            [
                                -79.27602539062497,
                                33.135400390624966
                            ],
                            [
                                -80.84921875,
                                32.113916015624966
                            ],
                            [
                                -81.52041015624997,
                                30.874658203125023
                            ],
                            [
                                -81.24951171875003,
                                29.793798828125006
                            ],
                            [
                                -80.89999999999995,
                                29.049853515625045
                            ],
                            [
                                -80.52412109374995,
                                28.48608398437503
                            ],
                            [
                                -80.65009765624993,
                                28.18090820312503
                            ],
                            [
                                -80.22612304687499,
                                27.207031250000057
                            ],
                            [
                                -80.04130859374999,
                                26.568603515625
                            ],
                            [
                                -80.1263671875,
                                25.83349609375
                            ],
                            [
                                -80.36694335937497,
                                25.331249999999983
                            ],
                            [
                                -80.73652343749995,
                                25.156347656249977
                            ],
                            [
                                -81.09765625,
                                25.319140625000017
                            ],
                            [
                                -81.36494140625001,
                                25.83105468750003
                            ],
                            [
                                -81.715478515625,
                                25.98315429687503
                            ],
                            [
                                -81.86655273437495,
                                26.435009765624983
                            ],
                            [
                                -82.44135742187501,
                                27.059667968750034
                            ],
                            [
                                -82.66088867187497,
                                27.71840820312505
                            ],
                            [
                                -82.84350585937494,
                                27.845996093750017
                            ],
                            [
                                -82.66064453125,
                                28.48583984374997
                            ],
                            [
                                -82.65146484375,
                                28.887500000000017
                            ],
                            [
                                -83.29047851562498,
                                29.45190429687497
                            ],
                            [
                                -83.69438476562502,
                                29.92597656250001
                            ],
                            [
                                -84.04423828124996,
                                30.10380859374999
                            ],
                            [
                                -84.38281250000003,
                                29.90737304687505
                            ],
                            [
                                -85.31894531249995,
                                29.680224609375045
                            ],
                            [
                                -85.35361328125,
                                29.875732421875
                            ],
                            [
                                -86.25737304687502,
                                30.493017578124977
                            ],
                            [
                                -87.79028320312503,
                                30.291796875000017
                            ],
                            [
                                -88.90522460937495,
                                30.415136718750006
                            ],
                            [
                                -89.58847656249998,
                                30.165966796874955
                            ],
                            [
                                -90.22529296874995,
                                30.379296874999994
                            ],
                            [
                                -90.41303710937501,
                                30.140332031249983
                            ],
                            [
                                -89.58950195312502,
                                29.9150390625
                            ],
                            [
                                -90.10136718749993,
                                29.181787109375023
                            ],
                            [
                                -90.37919921874996,
                                29.29511718750001
                            ],
                            [
                                -90.75102539062496,
                                29.13085937500003
                            ],
                            [
                                -91.29013671875,
                                29.288964843749994
                            ],
                            [
                                -91.33095703124997,
                                29.513574218750023
                            ],
                            [
                                -91.8931640625,
                                29.836035156249977
                            ],
                            [
                                -92.26083984374995,
                                29.55683593750004
                            ],
                            [
                                -93.17568359375,
                                29.778955078124994
                            ],
                            [
                                -93.69482421874997,
                                29.769921875000023
                            ],
                            [
                                -94.88828125000003,
                                29.37055664062501
                            ],
                            [
                                -95.27348632812499,
                                28.96386718750003
                            ],
                            [
                                -95.85341796875,
                                28.64033203125001
                            ],
                            [
                                -96.44873046874997,
                                28.594482421875
                            ],
                            [
                                -96.56171874999998,
                                28.367138671874955
                            ],
                            [
                                -97.156494140625,
                                28.144335937500045
                            ],
                            [
                                -97.17143554687493,
                                27.87958984375001
                            ],
                            [
                                -97.55468749999994,
                                26.96733398437496
                            ],
                            [
                                -97.40234375,
                                26.396533203125045
                            ],
                            [
                                -97.14624023437494,
                                25.961474609375045
                            ],
                            [
                                -97.35815429687497,
                                25.870507812499994
                            ],
                            [
                                -98.08281249999999,
                                26.06445312499997
                            ],
                            [
                                -99.10776367187498,
                                26.446923828124994
                            ],
                            [
                                -99.4435546875,
                                27.03666992187499
                            ],
                            [
                                -99.50532226562497,
                                27.54833984375003
                            ],
                            [
                                -100.29604492187495,
                                28.32768554687499
                            ],
                            [
                                -100.65864257812501,
                                29.06855468750004
                            ],
                            [
                                -101.44038085937503,
                                29.77685546875
                            ],
                            [
                                -102.26894531249998,
                                29.871191406250034
                            ],
                            [
                                -102.73417968749999,
                                29.643945312500023
                            ],
                            [
                                -102.8919921875,
                                29.216406250000034
                            ],
                            [
                                -103.25771484374998,
                                29.001123046874966
                            ],
                            [
                                -104.40063476562503,
                                29.57373046875
                            ],
                            [
                                -104.97880859374996,
                                30.645947265624955
                            ],
                            [
                                -106.25571289062495,
                                31.544677734375
                            ],
                            [
                                -106.45322265624995,
                                31.770166015625023
                            ],
                            [
                                -108.21181640625002,
                                31.779345703125017
                            ],
                            [
                                -108.21445312499993,
                                31.329443359375034
                            ],
                            [
                                -111.0419921875,
                                31.32421875000003
                            ],
                            [
                                -112.93896484375001,
                                31.91625976562503
                            ],
                            [
                                -114.83593749999994,
                                32.50830078125003
                            ],
                            [
                                -114.72475585937495,
                                32.71533203125003
                            ],
                            [
                                -117.12827148437495,
                                32.533349609374994
                            ],
                            [
                                -117.46743164062495,
                                33.295507812500006
                            ],
                            [
                                -118.506201171875,
                                34.01738281249999
                            ],
                            [
                                -119.14374999999995,
                                34.11201171874998
                            ],
                            [
                                -119.60605468749999,
                                34.41801757812499
                            ],
                            [
                                -120.48120117187503,
                                34.47163085937498
                            ],
                            [
                                -120.64467773437502,
                                34.57998046875002
                            ],
                            [
                                -120.63359374999999,
                                35.07646484375002
                            ],
                            [
                                -120.86030273437498,
                                35.36542968749998
                            ],
                            [
                                -121.87739257812498,
                                36.33105468749997
                            ],
                            [
                                -121.78999023437494,
                                36.732275390625006
                            ],
                            [
                                -122.394921875,
                                37.20751953125003
                            ],
                            [
                                -122.46689453125,
                                37.83818359374999
                            ],
                            [
                                -122.96816406249998,
                                38.097021484375034
                            ],
                            [
                                -122.9865234375,
                                38.27709960937506
                            ],
                            [
                                -123.701123046875,
                                38.90727539062502
                            ],
                            [
                                -123.88447265624997,
                                39.86079101562501
                            ],
                            [
                                -124.32402343749999,
                                40.25195312500003
                            ],
                            [
                                -124.3716796875,
                                40.49121093750003
                            ],
                            [
                                -124.06850585937498,
                                41.38417968750005
                            ],
                            [
                                -124.53964843750003,
                                42.812890624999966
                            ],
                            [
                                -124.14873046874997,
                                43.691748046875034
                            ],
                            [
                                -123.92934570312495,
                                45.57695312499996
                            ],
                            [
                                -124.0716796875,
                                46.74477539062502
                            ],
                            [
                                -124.376025390625,
                                47.658642578124955
                            ],
                            [
                                -124.62109375000001,
                                47.90415039062498
                            ],
                            [
                                -124.63261718749997,
                                48.37504882812499
                            ],
                            [
                                -123.97578125,
                                48.16845703125
                            ],
                            [
                                -122.24199218750002,
                                48.01074218750003
                            ],
                            [
                                -122.52031249999996,
                                48.229101562500006
                            ],
                            [
                                -122.40854492187502,
                                48.29389648437498
                            ],
                            [
                                -122.49677734374995,
                                48.50556640625001
                            ],
                            [
                                -122.56201171875001,
                                48.777978515624994
                            ],
                            [
                                -122.65302734375,
                                48.76386718749998
                            ],
                            [
                                -122.72246093750002,
                                48.85302734375003
                            ],
                            [
                                -122.78876953125003,
                                48.993017578125034
                            ],
                            [
                                -119.70170898437495,
                                48.993017578125034
                            ],
                            [
                                -119.27534179687494,
                                48.99306640625005
                            ],
                            [
                                -118.84892578124993,
                                48.99306640625005
                            ],
                            [
                                -117.99619140625002,
                                48.99306640625005
                            ],
                            [
                                -116.71704101562501,
                                48.99306640625005
                            ],
                            [
                                -114.58510742187501,
                                48.99306640625005
                            ],
                            [
                                -112.45322265625,
                                48.99306640625005
                            ],
                            [
                                -109.46855468749999,
                                48.99306640625005
                            ],
                            [
                                -107.76298828124997,
                                48.993115234374955
                            ],
                            [
                                -104.35195312499997,
                                48.993115234374955
                            ],
                            [
                                -102.22001953124996,
                                48.993115234374955
                            ],
                            [
                                -98.80898437499995,
                                48.99316406249997
                            ],
                            [
                                -97.52983398437493,
                                48.99316406249997
                            ],
                            [
                                -96.67705078124993,
                                48.99316406249997
                            ],
                            [
                                -96.25068359374993,
                                48.99316406249997
                            ],
                            [
                                -95.39790039062493,
                                48.99316406249997
                            ],
                            [
                                -95.16206054687493,
                                48.991748046875045
                            ],
                            [
                                -94.85434570312495,
                                49.304589843749994
                            ],
                            [
                                -94.86040039062493,
                                49.258593750000045
                            ],
                            [
                                -94.80346679687497,
                                49.0029296875
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -163.476025390625,
                                54.98071289062497
                            ],
                            [
                                -163.35810546874995,
                                54.73569335937506
                            ],
                            [
                                -164.4634765625,
                                54.427343750000034
                            ],
                            [
                                -164.887646484375,
                                54.60781250000002
                            ],
                            [
                                -164.52978515625,
                                54.88085937500006
                            ],
                            [
                                -163.476025390625,
                                54.98071289062497
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -130.97915039062502,
                                55.489160156249994
                            ],
                            [
                                -131.08276367187497,
                                55.26679687499998
                            ],
                            [
                                -131.810986328125,
                                55.223095703124955
                            ],
                            [
                                -131.62495117187504,
                                55.831689453124966
                            ],
                            [
                                -131.26923828125004,
                                55.95537109375002
                            ],
                            [
                                -130.97915039062502,
                                55.489160156249994
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -133.56611328125,
                                56.33920898437498
                            ],
                            [
                                -133.202978515625,
                                56.31982421875003
                            ],
                            [
                                -131.97641601562498,
                                55.208593750000034
                            ],
                            [
                                -131.98085937499997,
                                54.80483398437502
                            ],
                            [
                                -132.78232421875,
                                55.04848632812502
                            ],
                            [
                                -133.44697265624995,
                                55.797021484374994
                            ],
                            [
                                -133.56611328125,
                                56.33920898437498
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -133.36621093750006,
                                57.003515625000034
                            ],
                            [
                                -132.95415039062502,
                                56.880273437499994
                            ],
                            [
                                -133.212646484375,
                                56.46459960937503
                            ],
                            [
                                -133.602783203125,
                                56.46411132812503
                            ],
                            [
                                -133.86596679687497,
                                57.068701171875006
                            ],
                            [
                                -133.36621093750006,
                                57.003515625000034
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -134.96977539062496,
                                57.351416015625034
                            ],
                            [
                                -134.62070312499998,
                                56.71831054687502
                            ],
                            [
                                -135.78710937500003,
                                57.31728515625002
                            ],
                            [
                                -134.96977539062496,
                                57.351416015625034
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -152.89804687499998,
                                57.82392578125004
                            ],
                            [
                                -152.21621093749997,
                                57.577001953125006
                            ],
                            [
                                -153.793212890625,
                                56.98950195312503
                            ],
                            [
                                -154.33896484374998,
                                56.9208984375
                            ],
                            [
                                -154.70595703124997,
                                57.335351562499966
                            ],
                            [
                                -154.2814453125,
                                57.638085937499994
                            ],
                            [
                                -153.81835937499997,
                                57.595605468749966
                            ],
                            [
                                -152.89804687499998,
                                57.82392578125004
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -135.73037109375002,
                                58.244238281250034
                            ],
                            [
                                -135.00209960937497,
                                58.05107421875002
                            ],
                            [
                                -135.08486328124997,
                                57.51103515624996
                            ],
                            [
                                -136.07661132812504,
                                57.67456054687497
                            ],
                            [
                                -136.51230468749998,
                                58.095996093750045
                            ],
                            [
                                -135.73037109375002,
                                58.244238281250034
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -134.68027343749998,
                                58.16166992187499
                            ],
                            [
                                -133.93701171874997,
                                57.581591796875045
                            ],
                            [
                                -133.92529296875,
                                57.33676757812498
                            ],
                            [
                                -134.43530273437497,
                                57.056982421875006
                            ],
                            [
                                -134.65986328124998,
                                57.638085937499994
                            ],
                            [
                                -134.68027343749998,
                                58.16166992187499
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -166.13544921875,
                                60.38354492187503
                            ],
                            [
                                -165.72968750000004,
                                60.31420898437503
                            ],
                            [
                                -165.591796875,
                                59.913134765625045
                            ],
                            [
                                -166.187548828125,
                                59.773828124999994
                            ],
                            [
                                -167.13886718749998,
                                60.00854492187503
                            ],
                            [
                                -166.13544921875,
                                60.38354492187503
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -171.46303710937494,
                                63.640039062499994
                            ],
                            [
                                -170.430419921875,
                                63.69882812499998
                            ],
                            [
                                -169.77778320312498,
                                63.09375
                            ],
                            [
                                -171.46303710937494,
                                63.640039062499994
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -141.00214843750004,
                                68.77416992187506
                            ],
                            [
                                -141.00214843750004,
                                67.89755859374998
                            ],
                            [
                                -141.00214843750004,
                                66.43652343750006
                            ],
                            [
                                -141.00214843750004,
                                65.55991210937498
                            ],
                            [
                                -141.00214843750004,
                                64.09887695312506
                            ],
                            [
                                -141.00214843750004,
                                63.22226562499998
                            ],
                            [
                                -141.00214843750004,
                                61.761279296875045
                            ],
                            [
                                -141.00214843750004,
                                60.884667968749994
                            ],
                            [
                                -141.00214843750004,
                                60.30024414062504
                            ],
                            [
                                -140.45283203125004,
                                60.29970703125002
                            ],
                            [
                                -139.97329101562497,
                                60.183154296875074
                            ],
                            [
                                -139.67631835937505,
                                60.32832031249998
                            ],
                            [
                                -139.23476562499997,
                                60.339746093749994
                            ],
                            [
                                -138.70546874999997,
                                59.90131835937504
                            ],
                            [
                                -138.317626953125,
                                59.611132812500074
                            ],
                            [
                                -137.59331054687493,
                                59.22626953124998
                            ],
                            [
                                -136.81328125000002,
                                59.150048828124994
                            ],
                            [
                                -136.46635742187493,
                                59.459082031250006
                            ],
                            [
                                -135.70258789062504,
                                59.72875976562506
                            ],
                            [
                                -135.36787109374998,
                                59.743310546874994
                            ],
                            [
                                -134.94375,
                                59.28828125000001
                            ],
                            [
                                -134.67724609374997,
                                59.19926757812499
                            ],
                            [
                                -134.39306640625,
                                59.009179687499994
                            ],
                            [
                                -134.32963867187505,
                                58.93969726562506
                            ],
                            [
                                -134.21850585937503,
                                58.849902343750045
                            ],
                            [
                                -133.54638671874997,
                                58.50346679687499
                            ],
                            [
                                -133.27529296875,
                                58.22285156250004
                            ],
                            [
                                -133.00141601562495,
                                57.948974609375
                            ],
                            [
                                -132.062890625,
                                56.95336914062503
                            ],
                            [
                                -131.86616210937495,
                                56.792822265625006
                            ],
                            [
                                -130.649072265625,
                                56.26367187500003
                            ],
                            [
                                -130.47709960937496,
                                56.230566406250034
                            ],
                            [
                                -130.09785156249995,
                                56.10927734375002
                            ],
                            [
                                -130.01406249999997,
                                55.950537109375006
                            ],
                            [
                                -130.036572265625,
                                55.29790039062502
                            ],
                            [
                                -130.2140625,
                                55.02587890625003
                            ],
                            [
                                -130.57534179687497,
                                54.769677734374966
                            ],
                            [
                                -131.04785156249997,
                                55.157666015624955
                            ],
                            [
                                -130.9185546875,
                                55.735986328124994
                            ],
                            [
                                -131.28759765624994,
                                56.01210937500005
                            ],
                            [
                                -131.84384765625003,
                                56.16010742187498
                            ],
                            [
                                -132.81425781249993,
                                57.04072265624998
                            ],
                            [
                                -133.465869140625,
                                57.17216796875002
                            ],
                            [
                                -133.55419921874997,
                                57.69506835937497
                            ],
                            [
                                -134.06333007812503,
                                58.21108398437502
                            ],
                            [
                                -134.77612304687506,
                                58.45385742187503
                            ],
                            [
                                -135.3025390625,
                                58.25590820312502
                            ],
                            [
                                -135.86171874999997,
                                58.577050781249966
                            ],
                            [
                                -136.5826171875,
                                58.24521484375006
                            ],
                            [
                                -137.54399414062502,
                                58.58120117187502
                            ],
                            [
                                -138.35249023437498,
                                59.08730468750002
                            ],
                            [
                                -140.216748046875,
                                59.72666015624998
                            ],
                            [
                                -140.84316406249997,
                                59.74887695312506
                            ],
                            [
                                -141.67016601562497,
                                59.969873046874966
                            ],
                            [
                                -142.94565429687503,
                                60.09697265625002
                            ],
                            [
                                -143.80507812499997,
                                60.01289062500001
                            ],
                            [
                                -144.901318359375,
                                60.335156249999955
                            ],
                            [
                                -145.898876953125,
                                60.47817382812505
                            ],
                            [
                                -146.8740234375,
                                61.00488281249997
                            ],
                            [
                                -148.34443359374998,
                                60.853564453125045
                            ],
                            [
                                -148.197607421875,
                                60.167773437500045
                            ],
                            [
                                -148.430712890625,
                                59.98911132812498
                            ],
                            [
                                -149.4322265625,
                                60.00102539062499
                            ],
                            [
                                -150.9607421875,
                                59.24399414062506
                            ],
                            [
                                -151.73818359375002,
                                59.18852539062502
                            ],
                            [
                                -151.734521484375,
                                59.98833007812499
                            ],
                            [
                                -151.45146484375,
                                60.20263671875003
                            ],
                            [
                                -151.32177734375,
                                60.74291992187497
                            ],
                            [
                                -150.44125976562503,
                                61.02358398437505
                            ],
                            [
                                -150.61225585937495,
                                61.301123046875006
                            ],
                            [
                                -151.73398437499998,
                                60.91074218749998
                            ],
                            [
                                -151.75048828124994,
                                60.75488281250003
                            ],
                            [
                                -153.10605468749998,
                                59.87504882812502
                            ],
                            [
                                -154.088330078125,
                                59.36328125000003
                            ],
                            [
                                -154.129833984375,
                                59.119873046875
                            ],
                            [
                                -153.33442382812495,
                                58.85786132812498
                            ],
                            [
                                -154.01987304687498,
                                58.49296874999999
                            ],
                            [
                                -154.247021484375,
                                58.15942382812497
                            ],
                            [
                                -155.006884765625,
                                58.01606445312501
                            ],
                            [
                                -156.47841796875002,
                                57.32788085937506
                            ],
                            [
                                -156.475146484375,
                                57.10517578124998
                            ],
                            [
                                -157.57836914062503,
                                56.63447265625001
                            ],
                            [
                                -158.41440429687498,
                                56.435839843750045
                            ],
                            [
                                -158.27563476562497,
                                56.19624023437498
                            ],
                            [
                                -159.52324218749993,
                                55.81000976562498
                            ],
                            [
                                -159.96230468749997,
                                55.79487304687501
                            ],
                            [
                                -161.09951171875,
                                55.405712890624955
                            ],
                            [
                                -161.74155273437498,
                                55.39116210937502
                            ],
                            [
                                -162.073974609375,
                                55.13930664062505
                            ],
                            [
                                -162.86503906249996,
                                54.954541015624955
                            ],
                            [
                                -162.961962890625,
                                55.18383789062506
                            ],
                            [
                                -162.15712890625,
                                55.71943359375001
                            ],
                            [
                                -161.21562500000005,
                                56.02143554687498
                            ],
                            [
                                -160.85131835937497,
                                55.77187500000002
                            ],
                            [
                                -160.30205078125,
                                56.31411132812502
                            ],
                            [
                                -159.28310546874997,
                                56.688574218750006
                            ],
                            [
                                -158.32094726562497,
                                57.29790039062499
                            ],
                            [
                                -157.60756835937502,
                                57.601464843749994
                            ],
                            [
                                -157.52363281249998,
                                58.421337890624955
                            ],
                            [
                                -158.50317382812494,
                                58.85034179687497
                            ],
                            [
                                -159.08266601562497,
                                58.469775390625074
                            ],
                            [
                                -159.67026367187498,
                                58.9111328125
                            ],
                            [
                                -160.36313476562498,
                                59.05117187500002
                            ],
                            [
                                -161.75546874999998,
                                58.61201171874998
                            ],
                            [
                                -161.82871093749998,
                                59.588623046875
                            ],
                            [
                                -162.24248046875,
                                60.17832031249998
                            ],
                            [
                                -163.21938476562497,
                                59.84560546875005
                            ],
                            [
                                -163.90688476562494,
                                59.80678710937505
                            ],
                            [
                                -164.662255859375,
                                60.303808593750034
                            ],
                            [
                                -165.35380859375002,
                                60.54121093750001
                            ],
                            [
                                -164.80517578125,
                                60.89204101562498
                            ],
                            [
                                -165.56586914062498,
                                61.10234375000002
                            ],
                            [
                                -166.07880859375,
                                61.803124999999966
                            ],
                            [
                                -165.19453125,
                                62.47353515625002
                            ],
                            [
                                -164.40903320312503,
                                63.21503906250001
                            ],
                            [
                                -163.358837890625,
                                63.04575195312498
                            ],
                            [
                                -161.97397460937498,
                                63.45292968749999
                            ],
                            [
                                -161.09970703125003,
                                63.557910156250045
                            ],
                            [
                                -160.778564453125,
                                63.818945312500034
                            ],
                            [
                                -160.93193359374996,
                                64.5791015625
                            ],
                            [
                                -161.75937499999998,
                                64.816259765625
                            ],
                            [
                                -162.80703124999997,
                                64.37421875000001
                            ],
                            [
                                -163.71308593749998,
                                64.588232421875
                            ],
                            [
                                -164.89951171875003,
                                64.46064453125004
                            ],
                            [
                                -166.1427734375,
                                64.58276367187503
                            ],
                            [
                                -167.40400390625,
                                65.42211914062497
                            ],
                            [
                                -168.08837890624997,
                                65.65776367187502
                            ],
                            [
                                -164.67412109375002,
                                66.55502929687503
                            ],
                            [
                                -163.77548828125,
                                66.53110351562503
                            ],
                            [
                                -163.69536132812502,
                                66.08383789062503
                            ],
                            [
                                -161.81630859375002,
                                66.05366210937504
                            ],
                            [
                                -161.5568359375,
                                66.25053710937499
                            ],
                            [
                                -160.23168945312503,
                                66.420263671875
                            ],
                            [
                                -160.864013671875,
                                66.67084960937501
                            ],
                            [
                                -161.85668945312497,
                                66.70034179687497
                            ],
                            [
                                -161.96542968750003,
                                67.04956054687497
                            ],
                            [
                                -163.001708984375,
                                67.02729492187495
                            ],
                            [
                                -163.720556640625,
                                67.19555664062506
                            ],
                            [
                                -164.1251953125,
                                67.60673828125007
                            ],
                            [
                                -166.38051757812502,
                                68.425146484375
                            ],
                            [
                                -166.20908203125,
                                68.88535156250003
                            ],
                            [
                                -164.30234375000003,
                                68.93647460937498
                            ],
                            [
                                -163.16147460937498,
                                69.38793945312503
                            ],
                            [
                                -162.95209960937504,
                                69.75810546875005
                            ],
                            [
                                -161.88095703125003,
                                70.33173828125001
                            ],
                            [
                                -160.9962890625,
                                70.30458984375
                            ],
                            [
                                -159.31450195312496,
                                70.87851562500003
                            ],
                            [
                                -157.998486328125,
                                70.8453125
                            ],
                            [
                                -156.78330078124998,
                                71.31894531250006
                            ],
                            [
                                -155.16684570312498,
                                71.09921875000006
                            ],
                            [
                                -154.19521484375002,
                                70.80112304687498
                            ],
                            [
                                -153.23291015625,
                                70.93256835937504
                            ],
                            [
                                -152.23291015625,
                                70.81035156249999
                            ],
                            [
                                -151.94467773437498,
                                70.45209960937501
                            ],
                            [
                                -149.26943359374997,
                                70.50078124999999
                            ],
                            [
                                -147.70537109375,
                                70.21723632812495
                            ],
                            [
                                -145.82314453124997,
                                70.16005859375002
                            ],
                            [
                                -145.19736328125003,
                                70.00869140625002
                            ],
                            [
                                -143.218310546875,
                                70.11625976562499
                            ],
                            [
                                -141.40791015625,
                                69.65336914062502
                            ],
                            [
                                -141.00214843750004,
                                69.65078125000002
                            ],
                            [
                                -141.00214843750004,
                                68.77416992187506
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'United States of America',
                'childNum': 14
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            70.94677734374997,
                            42.24868164062505
                        ],
                        [
                            70.97900390625003,
                            42.26655273437504
                        ],
                        [
                            71.03603515625,
                            42.28466796875
                        ],
                        [
                            71.12998046875006,
                            42.25000000000003
                        ],
                        [
                            71.21269531250002,
                            42.20644531250005
                        ],
                        [
                            71.23232421875005,
                            42.18627929687503
                        ],
                        [
                            71.22851562499997,
                            42.16289062499996
                        ],
                        [
                            71.03222656249997,
                            42.07778320312505
                        ],
                        [
                            70.84189453125,
                            42.019628906250006
                        ],
                        [
                            70.45498046875005,
                            41.72504882812504
                        ],
                        [
                            70.18095703125007,
                            41.571435546874994
                        ],
                        [
                            70.78242187500004,
                            41.262500000000045
                        ],
                        [
                            71.11074218750005,
                            41.152636718750045
                        ],
                        [
                            71.29882812500003,
                            41.152490234374994
                        ],
                        [
                            71.39306640625003,
                            41.123388671875034
                        ],
                        [
                            71.40839843750004,
                            41.13603515625002
                        ],
                        [
                            71.60625000000002,
                            41.367431640625
                        ],
                        [
                            72.36406250000002,
                            41.04345703125
                        ],
                        [
                            73.13212890625002,
                            40.828515624999994
                        ],
                        [
                            72.3892578125,
                            40.427392578124994
                        ],
                        [
                            71.69248046875,
                            40.15234375
                        ],
                        [
                            70.95800781250003,
                            40.238867187500034
                        ],
                        [
                            70.38261718750002,
                            40.453515624999966
                        ],
                        [
                            70.75097656250003,
                            40.739599609375006
                        ],
                        [
                            70.44150390625006,
                            41.0234375
                        ],
                        [
                            69.71289062500003,
                            40.65698242187503
                        ],
                        [
                            69.2062500000001,
                            40.566552734374994
                        ],
                        [
                            69.22832031250007,
                            40.18759765624998
                        ],
                        [
                            68.6525390625001,
                            40.182666015625045
                        ],
                        [
                            68.46328125,
                            39.53671874999998
                        ],
                        [
                            67.71904296875007,
                            39.62138671875002
                        ],
                        [
                            67.34960937499997,
                            39.242089843749994
                        ],
                        [
                            67.67656250000002,
                            39.00849609375001
                        ],
                        [
                            68.10351562499997,
                            38.96201171875006
                        ],
                        [
                            68.04785156250003,
                            38.669287109375006
                        ],
                        [
                            68.29404296875006,
                            38.032910156249955
                        ],
                        [
                            67.81435546875005,
                            37.48701171875004
                        ],
                        [
                            67.7980468750001,
                            37.244970703125006
                        ],
                        [
                            67.75898437500004,
                            37.172216796875034
                        ],
                        [
                            67.75292968749997,
                            37.199804687500034
                        ],
                        [
                            67.70000000000002,
                            37.227246093749955
                        ],
                        [
                            67.60742187499997,
                            37.22250976562506
                        ],
                        [
                            67.5172851562501,
                            37.26665039062499
                        ],
                        [
                            67.44169921875007,
                            37.25800781250001
                        ],
                        [
                            67.3197265625,
                            37.209570312500006
                        ],
                        [
                            67.1955078125001,
                            37.23520507812498
                        ],
                        [
                            67.06884765624997,
                            37.334814453125006
                        ],
                        [
                            66.82773437500006,
                            37.37128906249998
                        ],
                        [
                            66.52226562500007,
                            37.34848632812506
                        ],
                        [
                            66.51064453125,
                            37.45869140625004
                        ],
                        [
                            66.51132812500006,
                            37.59916992187496
                        ],
                        [
                            66.52558593750004,
                            37.785742187500034
                        ],
                        [
                            66.629296875,
                            37.932031249999994
                        ],
                        [
                            65.8571289062501,
                            38.26875000000004
                        ],
                        [
                            65.612890625,
                            38.23857421875002
                        ],
                        [
                            64.16279296875004,
                            38.95361328125003
                        ],
                        [
                            62.65068359375002,
                            39.858496093750006
                        ],
                        [
                            62.095019531250074,
                            40.68330078124998
                        ],
                        [
                            61.953515624999994,
                            41.030615234375034
                        ],
                        [
                            61.32890625000002,
                            41.19511718749999
                        ],
                        [
                            60.454980468749994,
                            41.221630859374955
                        ],
                        [
                            60.089648437500074,
                            41.39941406250003
                        ],
                        [
                            60.10693359374997,
                            41.90742187499998
                        ],
                        [
                            59.858300781249994,
                            42.295166015625
                        ],
                        [
                            59.451074218749994,
                            42.29951171875001
                        ],
                        [
                            58.5890625000001,
                            42.778466796874966
                        ],
                        [
                            58.25966796875005,
                            42.688085937500034
                        ],
                        [
                            57.814257812500074,
                            42.18984375000005
                        ],
                        [
                            57.38173828125005,
                            42.15629882812496
                        ],
                        [
                            56.96406250000004,
                            41.856542968750006
                        ],
                        [
                            57.017968749999994,
                            41.26347656249996
                        ],
                        [
                            55.97744140625005,
                            41.32221679687504
                        ],
                        [
                            55.9767578125001,
                            42.69951171874996
                        ],
                        [
                            55.97568359375006,
                            44.99492187499996
                        ],
                        [
                            58.555273437500006,
                            45.55537109375001
                        ],
                        [
                            61.007910156250006,
                            44.39379882812497
                        ],
                        [
                            61.16074218750006,
                            44.16860351562502
                        ],
                        [
                            61.99023437500003,
                            43.492138671874955
                        ],
                        [
                            63.20703125000003,
                            43.62797851562502
                        ],
                        [
                            64.44316406250007,
                            43.551171874999994
                        ],
                        [
                            64.9054687500001,
                            43.714697265625006
                        ],
                        [
                            65.49619140625,
                            43.310546875
                        ],
                        [
                            65.80302734375002,
                            42.87695312500006
                        ],
                        [
                            66.1002929687501,
                            42.99082031249998
                        ],
                        [
                            66.00957031250007,
                            42.00488281250003
                        ],
                        [
                            66.49863281250006,
                            41.99487304687503
                        ],
                        [
                            66.66865234375004,
                            41.27075195312503
                        ],
                        [
                            66.81425781250002,
                            41.14238281249999
                        ],
                        [
                            67.9357421875001,
                            41.19658203125002
                        ],
                        [
                            68.29189453125,
                            40.656103515625034
                        ],
                        [
                            69.06494140625003,
                            41.366943359375
                        ],
                        [
                            70.09560546875,
                            41.82050781250004
                        ],
                        [
                            70.41601562500003,
                            42.07856445312501
                        ],
                        [
                            70.94677734374997,
                            42.24868164062505
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Uzbekistan',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            -60.742138671874926,
                            5.202050781250037
                        ],
                        [
                            -60.71196289062499,
                            5.191552734375023
                        ],
                        [
                            -60.671972656250034,
                            5.164355468749989
                        ],
                        [
                            -60.60449218749994,
                            4.99458007812504
                        ],
                        [
                            -61.00283203125002,
                            4.5352539062499915
                        ],
                        [
                            -62.15312499999996,
                            4.098388671874986
                        ],
                        [
                            -62.71210937499998,
                            4.01791992187502
                        ],
                        [
                            -62.96865234374994,
                            3.593945312499983
                        ],
                        [
                            -63.29472656249993,
                            3.92226562499998
                        ],
                        [
                            -64.02148437500003,
                            3.929101562500051
                        ],
                        [
                            -64.25566406249996,
                            4.140332031249997
                        ],
                        [
                            -64.56791992187496,
                            3.8998046875000227
                        ],
                        [
                            -64.22109375000002,
                            3.5874023437499716
                        ],
                        [
                            -64.21884765625,
                            3.2046874999999915
                        ],
                        [
                            -63.924169921875006,
                            2.4524414062500313
                        ],
                        [
                            -63.389257812500006,
                            2.4119140625000455
                        ],
                        [
                            -63.393945312499994,
                            2.2225097656250057
                        ],
                        [
                            -64.03544921874993,
                            1.904443359375037
                        ],
                        [
                            -64.11484375000003,
                            1.619287109375037
                        ],
                        [
                            -65.10375976562497,
                            1.108105468749983
                        ],
                        [
                            -65.36083984374994,
                            0.8686523437500568
                        ],
                        [
                            -65.68144531249999,
                            0.9834472656249886
                        ],
                        [
                            -66.06005859375003,
                            0.7853515625000398
                        ],
                        [
                            -66.42924804687502,
                            0.8216796874999801
                        ],
                        [
                            -66.87602539062499,
                            1.223046875000037
                        ],
                        [
                            -67.21083984375,
                            2.3901367187500426
                        ],
                        [
                            -67.83476562499999,
                            2.8928222656250426
                        ],
                        [
                            -67.3111328125,
                            3.41586914062502
                        ],
                        [
                            -67.66162109375,
                            3.864257812499986
                        ],
                        [
                            -67.85527343750002,
                            4.506884765624989
                        ],
                        [
                            -67.82490234374995,
                            5.270458984375026
                        ],
                        [
                            -67.43935546875,
                            6.025537109375023
                        ],
                        [
                            -67.85917968749999,
                            6.289892578124963
                        ],
                        [
                            -68.47177734375,
                            6.156542968749974
                        ],
                        [
                            -69.43925781249993,
                            6.1349121093750085
                        ],
                        [
                            -70.09501953124996,
                            6.937939453125011
                        ],
                        [
                            -70.65507812500002,
                            7.082763671875028
                        ],
                        [
                            -71.01328124999998,
                            6.994433593749974
                        ],
                        [
                            -72.08427734374993,
                            7.096874999999969
                        ],
                        [
                            -72.44296875,
                            7.454882812499989
                        ],
                        [
                            -72.41655273437496,
                            8.381982421875037
                        ],
                        [
                            -72.66542968749994,
                            8.62758789062498
                        ],
                        [
                            -72.79638671874997,
                            9.108984374999991
                        ],
                        [
                            -73.35634765625002,
                            9.226855468750031
                        ],
                        [
                            -73.00654296874998,
                            9.789160156250006
                        ],
                        [
                            -72.86933593750001,
                            10.49125976562496
                        ],
                        [
                            -72.690087890625,
                            10.835839843749994
                        ],
                        [
                            -72.24848632812501,
                            11.196435546875009
                        ],
                        [
                            -71.95810546875,
                            11.666406250000009
                        ],
                        [
                            -71.31972656249997,
                            11.861914062500048
                        ],
                        [
                            -71.86865234374997,
                            11.627343750000037
                        ],
                        [
                            -71.94697265624995,
                            11.414453125000009
                        ],
                        [
                            -71.59433593749995,
                            10.657373046875051
                        ],
                        [
                            -72.11284179687499,
                            9.815576171874966
                        ],
                        [
                            -71.68671874999995,
                            9.072509765625028
                        ],
                        [
                            -71.29794921874998,
                            9.125634765625009
                        ],
                        [
                            -71.08583984375002,
                            9.348242187499977
                        ],
                        [
                            -71.05268554687501,
                            9.705810546874986
                        ],
                        [
                            -71.38662109375002,
                            10.263769531249977
                        ],
                        [
                            -71.54462890624995,
                            10.778710937499994
                        ],
                        [
                            -71.46953124999993,
                            10.964160156250017
                        ],
                        [
                            -70.23251953124998,
                            11.372998046874997
                        ],
                        [
                            -70.28652343749997,
                            11.886035156249989
                        ],
                        [
                            -70.00395507812496,
                            12.177880859375023
                        ],
                        [
                            -69.63159179687494,
                            11.479931640625026
                        ],
                        [
                            -68.827978515625,
                            11.431738281249977
                        ],
                        [
                            -68.39863281249995,
                            11.160986328124977
                        ],
                        [
                            -68.23408203124998,
                            10.569140625000017
                        ],
                        [
                            -67.87163085937496,
                            10.472070312500051
                        ],
                        [
                            -66.98906250000002,
                            10.610644531250031
                        ],
                        [
                            -66.24721679687497,
                            10.632226562499994
                        ],
                        [
                            -65.85175781249995,
                            10.257763671874997
                        ],
                        [
                            -65.12910156249998,
                            10.070068359375043
                        ],
                        [
                            -64.85048828125,
                            10.098095703124969
                        ],
                        [
                            -63.873437499999966,
                            10.663769531250026
                        ],
                        [
                            -62.702343749999955,
                            10.74980468749996
                        ],
                        [
                            -62.843017578125,
                            10.417919921875026
                        ],
                        [
                            -62.51513671875,
                            10.176123046874977
                        ],
                        [
                            -60.79248046874997,
                            9.360742187500037
                        ],
                        [
                            -61.05307617187495,
                            9.095117187500009
                        ],
                        [
                            -61.30400390624999,
                            8.410400390625043
                        ],
                        [
                            -60.800976562499926,
                            8.592138671875034
                        ],
                        [
                            -60.01752929687501,
                            8.549316406250014
                        ],
                        [
                            -59.83164062499998,
                            8.305957031250003
                        ],
                        [
                            -59.84907226562498,
                            8.248681640624966
                        ],
                        [
                            -59.96484375000003,
                            8.191601562499969
                        ],
                        [
                            -59.99072265624997,
                            8.16201171874998
                        ],
                        [
                            -60.032421874999926,
                            8.053564453125006
                        ],
                        [
                            -60.51362304687501,
                            7.813183593749969
                        ],
                        [
                            -60.71923828124997,
                            7.498681640625023
                        ],
                        [
                            -60.523193359375,
                            7.1437011718750085
                        ],
                        [
                            -60.464941406250034,
                            7.1665527343750455
                        ],
                        [
                            -60.39238281249999,
                            7.164550781249986
                        ],
                        [
                            -60.34506835937495,
                            7.150000000000048
                        ],
                        [
                            -60.32548828124996,
                            7.133984374999983
                        ],
                        [
                            -60.32207031249996,
                            7.092041015625043
                        ],
                        [
                            -60.35209960937496,
                            7.002880859374997
                        ],
                        [
                            -60.39501953125,
                            6.945361328125003
                        ],
                        [
                            -60.58608398437502,
                            6.857080078124966
                        ],
                        [
                            -60.717919921874966,
                            6.768310546875
                        ],
                        [
                            -61.14560546874998,
                            6.694531249999983
                        ],
                        [
                            -61.12871093749999,
                            6.214306640625026
                        ],
                        [
                            -61.15947265624996,
                            6.174414062499977
                        ],
                        [
                            -61.22495117187498,
                            6.129199218750003
                        ],
                        [
                            -61.303124999999994,
                            6.049511718750026
                        ],
                        [
                            -61.39082031250001,
                            5.938769531250017
                        ],
                        [
                            -61.376806640625006,
                            5.906982421875028
                        ],
                        [
                            -61.167187499999926,
                            5.674218750000037
                        ],
                        [
                            -60.95400390625002,
                            5.437402343750023
                        ],
                        [
                            -60.742138671874926,
                            5.202050781250037
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Venezuela',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            107.97265624999997,
                            21.507958984375023
                        ],
                        [
                            107.40996093750002,
                            21.284814453125023
                        ],
                        [
                            107.35429687500007,
                            21.055175781250057
                        ],
                        [
                            106.67548828125004,
                            20.960498046875045
                        ],
                        [
                            106.75341796875003,
                            20.73505859375004
                        ],
                        [
                            106.51796875000005,
                            20.28886718749999
                        ],
                        [
                            105.98408203125004,
                            19.939062500000034
                        ],
                        [
                            105.62177734375004,
                            18.96630859375003
                        ],
                        [
                            105.88828125000006,
                            18.502490234375045
                        ],
                        [
                            106.49902343749997,
                            17.946435546874994
                        ],
                        [
                            106.47890625000005,
                            17.719580078125063
                        ],
                        [
                            107.18037109375004,
                            16.89794921875
                        ],
                        [
                            108.02939453125012,
                            16.331103515625074
                        ],
                        [
                            108.82128906249997,
                            15.377929687500028
                        ],
                        [
                            109.08486328125,
                            14.716162109375034
                        ],
                        [
                            109.30332031250012,
                            13.856445312500043
                        ],
                        [
                            109.271875,
                            13.279345703124974
                        ],
                        [
                            109.42392578125006,
                            12.955957031249994
                        ],
                        [
                            109.21894531250004,
                            12.64580078124996
                        ],
                        [
                            109.1986328125,
                            11.724853515625014
                        ],
                        [
                            108.82080078125003,
                            11.3154296875
                        ],
                        [
                            108.2716796875001,
                            10.934277343749969
                        ],
                        [
                            107.26152343750007,
                            10.39838867187504
                        ],
                        [
                            106.81269531250004,
                            10.433300781250054
                        ],
                        [
                            106.57246093750004,
                            9.641113281250043
                        ],
                        [
                            105.50097656249997,
                            9.093212890624983
                        ],
                        [
                            105.32226562499997,
                            8.801123046874977
                        ],
                        [
                            104.89189453125002,
                            8.583251953125
                        ],
                        [
                            104.81855468750004,
                            8.801855468750034
                        ],
                        [
                            104.84521484375003,
                            9.606152343750026
                        ],
                        [
                            105.02783203125003,
                            10.067431640625031
                        ],
                        [
                            104.66347656250005,
                            10.169921875000043
                        ],
                        [
                            104.42636718750006,
                            10.411230468749991
                        ],
                        [
                            104.85058593749997,
                            10.534472656249974
                        ],
                        [
                            105.38652343750002,
                            10.940087890625009
                        ],
                        [
                            106.16796874999997,
                            11.012304687499977
                        ],
                        [
                            105.85605468750006,
                            11.294287109375048
                        ],
                        [
                            105.85146484375005,
                            11.635009765625
                        ],
                        [
                            106.33984374999997,
                            11.68183593750004
                        ],
                        [
                            106.41386718750002,
                            11.948437499999997
                        ],
                        [
                            106.93066406249997,
                            12.077490234374991
                        ],
                        [
                            107.50644531250006,
                            12.364550781250031
                        ],
                        [
                            107.47539062500002,
                            13.030371093749963
                        ],
                        [
                            107.60546874999997,
                            13.437792968750017
                        ],
                        [
                            107.3314453125,
                            14.126611328125009
                        ],
                        [
                            107.51943359375005,
                            14.705078125
                        ],
                        [
                            107.51376953125012,
                            14.817382812500057
                        ],
                        [
                            107.52451171875012,
                            14.871826171875043
                        ],
                        [
                            107.50468750000007,
                            14.915917968749966
                        ],
                        [
                            107.48037109375,
                            14.979882812500037
                        ],
                        [
                            107.55527343750006,
                            15.057031250000023
                        ],
                        [
                            107.58964843750002,
                            15.118457031250017
                        ],
                        [
                            107.63369140625005,
                            15.189843749999994
                        ],
                        [
                            107.65312500000002,
                            15.255224609374991
                        ],
                        [
                            107.62167968750006,
                            15.309863281250017
                        ],
                        [
                            107.56425781250002,
                            15.391601562499972
                        ],
                        [
                            107.45957031250012,
                            15.4658203125
                        ],
                        [
                            107.33876953125,
                            15.560498046875011
                        ],
                        [
                            107.27939453125006,
                            15.618701171875045
                        ],
                        [
                            107.18955078125006,
                            15.747265624999955
                        ],
                        [
                            107.16591796875005,
                            15.802490234375028
                        ],
                        [
                            107.1888671875,
                            15.838623046875
                        ],
                        [
                            107.36064453125002,
                            15.921728515624977
                        ],
                        [
                            107.39638671875,
                            16.04301757812499
                        ],
                        [
                            107.29648437500006,
                            16.08403320312499
                        ],
                        [
                            107.21738281250006,
                            16.136328125000006
                        ],
                        [
                            106.93066406249997,
                            16.353125000000034
                        ],
                        [
                            106.69609375000007,
                            16.458984375
                        ],
                        [
                            106.50224609375002,
                            16.95410156249997
                        ],
                        [
                            106.26953125000003,
                            17.21679687500003
                        ],
                        [
                            105.69140625000003,
                            17.737841796875045
                        ],
                        [
                            105.58847656250012,
                            17.983691406250045
                        ],
                        [
                            105.51855468749997,
                            18.077441406250045
                        ],
                        [
                            105.45820312500004,
                            18.15429687499997
                        ],
                        [
                            105.33349609375003,
                            18.189648437499983
                        ],
                        [
                            105.27324218750002,
                            18.235351562500057
                        ],
                        [
                            105.1632812500001,
                            18.338720703124977
                        ],
                        [
                            105.11455078125002,
                            18.40527343750003
                        ],
                        [
                            105.08583984375005,
                            18.450097656250023
                        ],
                        [
                            105.11347656250004,
                            18.573046875000017
                        ],
                        [
                            105.14541015625,
                            18.616796875000063
                        ],
                        [
                            105.14648437500003,
                            18.650976562500006
                        ],
                        [
                            105.11513671875005,
                            18.678857421874994
                        ],
                        [
                            104.99316406249997,
                            18.728320312500045
                        ],
                        [
                            104.7165039062501,
                            18.803417968749955
                        ],
                        [
                            104.61328125000003,
                            18.86064453124999
                        ],
                        [
                            104.44580078125003,
                            18.983837890624955
                        ],
                        [
                            104.10859375000004,
                            19.195556640625
                        ],
                        [
                            103.91835937500005,
                            19.26850585937504
                        ],
                        [
                            103.89638671875,
                            19.339990234375023
                        ],
                        [
                            104.03203125000002,
                            19.675146484375006
                        ],
                        [
                            104.06279296875007,
                            19.678417968749983
                        ],
                        [
                            104.25986328125006,
                            19.685498046874983
                        ],
                        [
                            104.5462890625,
                            19.61054687500001
                        ],
                        [
                            104.58789062500003,
                            19.618749999999977
                        ],
                        [
                            104.74316406250003,
                            19.754736328124977
                        ],
                        [
                            104.80175781249997,
                            19.83613281250004
                        ],
                        [
                            104.81513671875004,
                            19.90400390625001
                        ],
                        [
                            104.9279296875001,
                            20.01811523437499
                        ],
                        [
                            104.6188476562501,
                            20.37451171875003
                        ],
                        [
                            104.58320312500004,
                            20.646679687499955
                        ],
                        [
                            104.34960937499997,
                            20.821093750000074
                        ],
                        [
                            104.19531249999997,
                            20.913964843749966
                        ],
                        [
                            104.05205078125002,
                            20.941210937500017
                        ],
                        [
                            103.63505859375007,
                            20.697070312500017
                        ],
                        [
                            103.46357421875004,
                            20.779833984375017
                        ],
                        [
                            103.21074218750002,
                            20.840625000000045
                        ],
                        [
                            103.10449218749997,
                            20.891650390625045
                        ],
                        [
                            102.88378906250003,
                            21.202587890624983
                        ],
                        [
                            102.85117187500006,
                            21.26591796874999
                        ],
                        [
                            102.9486328125,
                            21.56977539062501
                        ],
                        [
                            102.69531250000003,
                            21.66210937499997
                        ],
                        [
                            102.58251953125003,
                            21.90429687500003
                        ],
                        [
                            102.1830078125,
                            22.284033203125034
                        ],
                        [
                            102.12744140624997,
                            22.379199218750045
                        ],
                        [
                            102.1759765625001,
                            22.414648437500006
                        ],
                        [
                            102.2370117187501,
                            22.466015624999983
                        ],
                        [
                            102.42792968750004,
                            22.732812499999966
                        ],
                        [
                            102.51718750000012,
                            22.741015625000045
                        ],
                        [
                            103.00537109375003,
                            22.452978515624977
                        ],
                        [
                            103.35605468750006,
                            22.75468749999999
                        ],
                        [
                            104.37177734375004,
                            22.704052734374983
                        ],
                        [
                            104.79570312500007,
                            22.91113281249997
                        ],
                        [
                            104.86474609375003,
                            23.136376953125023
                        ],
                        [
                            105.27539062500003,
                            23.34521484375003
                        ],
                        [
                            105.8429687500001,
                            22.922802734374955
                        ],
                        [
                            106.14843749999997,
                            22.970068359375006
                        ],
                        [
                            106.78027343749997,
                            22.778906250000034
                        ],
                        [
                            106.53632812500004,
                            22.395410156249994
                        ],
                        [
                            106.72949218749997,
                            22.000341796875063
                        ],
                        [
                            107.35117187500012,
                            21.60888671874997
                        ],
                        [
                            107.97265624999997,
                            21.507958984375023
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Vietnam',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                167.41250000000005,
                                -16.095898437499997
                            ],
                            [
                                167.83662109375004,
                                -16.449707031249957
                            ],
                            [
                                167.44931640625012,
                                -16.554980468750003
                            ],
                            [
                                167.41250000000005,
                                -16.095898437499997
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                166.74580078125004,
                                -14.826855468750011
                            ],
                            [
                                166.81015625000012,
                                -15.15742187500004
                            ],
                            [
                                167.13164062500002,
                                -15.135351562499977
                            ],
                            [
                                167.19960937500005,
                                -15.485742187500037
                            ],
                            [
                                166.82578125000006,
                                -15.634863281249963
                            ],
                            [
                                166.63105468750004,
                                -15.406054687499974
                            ],
                            [
                                166.52724609375,
                                -14.850097656249957
                            ],
                            [
                                166.74580078125004,
                                -14.826855468750011
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Vanuatu',
                'childNum': 2
            }
        },
        {
            'geometry': {
                'type': 'MultiPolygon',
                'coordinates': [
                    [
                        [
                            [
                                53.76318359374997,
                                12.636816406249991
                            ],
                            [
                                54.18740234375005,
                                12.664013671875026
                            ],
                            [
                                54.12949218750006,
                                12.360644531250045
                            ],
                            [
                                53.59833984375004,
                                12.342285156250028
                            ],
                            [
                                53.76318359374997,
                                12.636816406249991
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                53.08564453125004,
                                16.648388671874955
                            ],
                            [
                                52.327734375000006,
                                16.293554687500063
                            ],
                            [
                                52.2174804687501,
                                15.655517578125
                            ],
                            [
                                51.6037109375001,
                                15.336816406250009
                            ],
                            [
                                50.52705078125004,
                                15.038183593750006
                            ],
                            [
                                50.16689453125005,
                                14.851025390624983
                            ],
                            [
                                49.34990234375002,
                                14.637792968749977
                            ],
                            [
                                48.668359374999994,
                                14.050146484374977
                            ],
                            [
                                47.9899414062501,
                                14.048095703125
                            ],
                            [
                                47.40771484374997,
                                13.661621093750057
                            ],
                            [
                                46.78886718750002,
                                13.465576171874986
                            ],
                            [
                                45.65732421875006,
                                13.338720703124991
                            ],
                            [
                                45.03867187500006,
                                12.815869140624969
                            ],
                            [
                                44.617773437500006,
                                12.817236328124977
                            ],
                            [
                                43.929785156250006,
                                12.616503906250031
                            ],
                            [
                                43.47529296875004,
                                12.83901367187498
                            ],
                            [
                                43.23193359375003,
                                13.267089843750057
                            ],
                            [
                                43.28261718750005,
                                13.639843749999969
                            ],
                            [
                                43.08906250000004,
                                14.010986328125
                            ],
                            [
                                43.02109375000006,
                                14.55488281250004
                            ],
                            [
                                42.71718750000005,
                                15.654638671875006
                            ],
                            [
                                42.79931640624997,
                                16.37177734375001
                            ],
                            [
                                43.16503906249997,
                                16.689404296874955
                            ],
                            [
                                43.19091796875003,
                                17.35937500000003
                            ],
                            [
                                43.41796875000003,
                                17.516259765625023
                            ],
                            [
                                43.91699218749997,
                                17.32470703124997
                            ],
                            [
                                44.1559570312501,
                                17.398535156250006
                            ],
                            [
                                45.14804687500006,
                                17.427441406249955
                            ],
                            [
                                45.40654296875002,
                                17.319775390624955
                            ],
                            [
                                46.31035156250002,
                                17.231298828125063
                            ],
                            [
                                46.682031249999994,
                                17.2685546875
                            ],
                            [
                                46.97568359375006,
                                16.953466796875034
                            ],
                            [
                                47.441796874999994,
                                17.111865234375045
                            ],
                            [
                                47.57958984374997,
                                17.448339843750034
                            ],
                            [
                                48.17216796875002,
                                18.156933593749983
                            ],
                            [
                                49.04199218750003,
                                18.58178710937503
                            ],
                            [
                                51.977636718750006,
                                18.996142578125074
                            ],
                            [
                                52.72919921875004,
                                17.300390625000006
                            ],
                            [
                                53.08564453125004,
                                16.648388671874955
                            ]
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Yemen',
                'childNum': 2
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            31.921679687500017,
                            -25.96875
                        ],
                        [
                            31.415136718750063,
                            -25.74658203125003
                        ],
                        [
                            31.207324218750074,
                            -25.843359375000034
                        ],
                        [
                            30.803320312500006,
                            -26.41347656250001
                        ],
                        [
                            30.79433593750005,
                            -26.764257812499977
                        ],
                        [
                            31.06337890625005,
                            -27.1123046875
                        ],
                        [
                            31.274023437500063,
                            -27.23837890625002
                        ],
                        [
                            31.469531250000017,
                            -27.29550781250002
                        ],
                        [
                            31.74257812500005,
                            -27.309960937500037
                        ],
                        [
                            31.95839843750005,
                            -27.305859374999997
                        ],
                        [
                            31.946093750000017,
                            -27.173632812499974
                        ],
                        [
                            31.96718750000005,
                            -26.96064453125001
                        ],
                        [
                            31.994726562500006,
                            -26.817480468749977
                        ],
                        [
                            32.024804687499994,
                            -26.81113281250002
                        ],
                        [
                            32.112890625000006,
                            -26.83945312500002
                        ],
                        [
                            32.199609374999994,
                            -26.833496093749957
                        ],
                        [
                            32.35351562499997,
                            -26.861621093750003
                        ],
                        [
                            32.7765625000001,
                            -26.850976562499966
                        ],
                        [
                            32.88613281250005,
                            -26.849316406249983
                        ],
                        [
                            32.53476562500006,
                            -28.19970703125003
                        ],
                        [
                            32.285742187500006,
                            -28.62148437499998
                        ],
                        [
                            31.778222656249994,
                            -28.937109374999977
                        ],
                        [
                            31.335156250000097,
                            -29.378125000000026
                        ],
                        [
                            30.288671875000063,
                            -30.970117187499994
                        ],
                        [
                            28.85595703125003,
                            -32.29423828125002
                        ],
                        [
                            27.860644531250017,
                            -33.05390624999997
                        ],
                        [
                            27.077441406250074,
                            -33.52119140625004
                        ],
                        [
                            26.429492187500045,
                            -33.75957031250002
                        ],
                        [
                            25.989550781250045,
                            -33.711328124999966
                        ],
                        [
                            25.63818359375003,
                            -34.01113281250002
                        ],
                        [
                            25.00292968750003,
                            -33.97363281250003
                        ],
                        [
                            24.8271484375,
                            -34.16894531250003
                        ],
                        [
                            23.585546875000006,
                            -33.985156249999974
                        ],
                        [
                            23.268164062500006,
                            -34.08115234374999
                        ],
                        [
                            22.24550781250005,
                            -34.06914062500003
                        ],
                        [
                            21.788964843750023,
                            -34.37265624999996
                        ],
                        [
                            21.060156250000063,
                            -34.36464843750001
                        ],
                        [
                            20.529882812500034,
                            -34.4630859375
                        ],
                        [
                            20.020605468750006,
                            -34.785742187500006
                        ],
                        [
                            19.63496093750004,
                            -34.753320312499966
                        ],
                        [
                            18.80878906250004,
                            -34.10820312499999
                        ],
                        [
                            18.354394531250023,
                            -33.93906249999999
                        ],
                        [
                            18.43300781250005,
                            -33.71728515625003
                        ],
                        [
                            17.87822265624999,
                            -32.96152343749998
                        ],
                        [
                            18.25087890625008,
                            -32.6521484375
                        ],
                        [
                            18.31074218750001,
                            -32.12246093750001
                        ],
                        [
                            18.21083984375008,
                            -31.74248046874996
                        ],
                        [
                            17.67744140625004,
                            -31.019042968750014
                        ],
                        [
                            17.189062500000006,
                            -30.09980468749997
                        ],
                        [
                            16.950000000000074,
                            -29.40341796875002
                        ],
                        [
                            16.480761718750045,
                            -28.641503906249966
                        ],
                        [
                            16.755761718750023,
                            -28.45214843750003
                        ],
                        [
                            16.787500000000023,
                            -28.39472656249997
                        ],
                        [
                            16.810156250000006,
                            -28.264550781249994
                        ],
                        [
                            16.841210937500023,
                            -28.21894531250004
                        ],
                        [
                            16.875292968750045,
                            -28.12792968749997
                        ],
                        [
                            16.93330078125004,
                            -28.06962890624999
                        ],
                        [
                            17.056250000000063,
                            -28.03105468750003
                        ],
                        [
                            17.14941406250003,
                            -28.082226562499983
                        ],
                        [
                            17.1884765625,
                            -28.13251953125001
                        ],
                        [
                            17.44794921875001,
                            -28.698144531249966
                        ],
                        [
                            18.310839843750017,
                            -28.88623046875
                        ],
                        [
                            19.026074218750068,
                            -28.92792968749997
                        ],
                        [
                            19.98046875,
                            -28.310351562500003
                        ],
                        [
                            19.98046875,
                            -26.531152343749994
                        ],
                        [
                            19.98046875,
                            -24.77675781249998
                        ],
                        [
                            20.345214843750057,
                            -25.029882812500034
                        ],
                        [
                            20.79316406250001,
                            -25.915625000000006
                        ],
                        [
                            20.641406250000017,
                            -26.7421875
                        ],
                        [
                            20.739843749999977,
                            -26.84882812499997
                        ],
                        [
                            21.64628906249999,
                            -26.854199218749997
                        ],
                        [
                            21.694726562500023,
                            -26.840917968749963
                        ],
                        [
                            21.738085937500045,
                            -26.806835937500026
                        ],
                        [
                            21.788281250000068,
                            -26.710058593750034
                        ],
                        [
                            21.833203125000068,
                            -26.678320312499963
                        ],
                        [
                            21.91455078125003,
                            -26.66191406249999
                        ],
                        [
                            22.01093750000004,
                            -26.635839843750006
                        ],
                        [
                            22.090917968749977,
                            -26.580175781250034
                        ],
                        [
                            22.217578125000045,
                            -26.38886718749997
                        ],
                        [
                            22.640234374999977,
                            -26.071191406249994
                        ],
                        [
                            22.729003906250057,
                            -25.857324218750037
                        ],
                        [
                            22.79609375000004,
                            -25.679101562499966
                        ],
                        [
                            22.878808593750023,
                            -25.457910156250023
                        ],
                        [
                            23.148730468750017,
                            -25.288671875000006
                        ],
                        [
                            23.52148437500003,
                            -25.344433593749997
                        ],
                        [
                            23.670703125000045,
                            -25.433984375000037
                        ],
                        [
                            23.89375000000001,
                            -25.600878906250017
                        ],
                        [
                            23.96953124999999,
                            -25.62607421874999
                        ],
                        [
                            24.192968750000034,
                            -25.632910156249963
                        ],
                        [
                            24.748144531250006,
                            -25.817382812499986
                        ],
                        [
                            25.518164062500006,
                            -25.66279296875001
                        ],
                        [
                            25.91210937499997,
                            -24.747460937499966
                        ],
                        [
                            26.39716796875004,
                            -24.61357421874996
                        ],
                        [
                            26.451757812500063,
                            -24.582714843749983
                        ],
                        [
                            26.617773437500034,
                            -24.39550781250003
                        ],
                        [
                            26.761132812500023,
                            -24.297167968749974
                        ],
                        [
                            26.835058593750063,
                            -24.240820312499963
                        ],
                        [
                            26.97060546875005,
                            -23.76347656249996
                        ],
                        [
                            26.98701171875004,
                            -23.704589843749957
                        ],
                        [
                            27.085546875000034,
                            -23.577929687500003
                        ],
                        [
                            27.14638671875008,
                            -23.52441406250003
                        ],
                        [
                            27.7685546875,
                            -23.14892578125
                        ],
                        [
                            27.812597656250006,
                            -23.108007812500006
                        ],
                        [
                            28.210156249999983,
                            -22.693652343749974
                        ],
                        [
                            28.83984375000003,
                            -22.480859374999966
                        ],
                        [
                            28.94580078125003,
                            -22.39511718749999
                        ],
                        [
                            29.013476562500045,
                            -22.27841796875002
                        ],
                        [
                            29.129882812500057,
                            -22.21328124999998
                        ],
                        [
                            29.364843750000063,
                            -22.19394531250005
                        ],
                        [
                            29.37744140625003,
                            -22.19277343749998
                        ],
                        [
                            29.66308593749997,
                            -22.146289062500017
                        ],
                        [
                            30.19042968750003,
                            -22.291113281250034
                        ],
                        [
                            30.460156250000097,
                            -22.329003906250023
                        ],
                        [
                            30.71162109375004,
                            -22.297851562499986
                        ],
                        [
                            31.07343750000004,
                            -22.30781249999997
                        ],
                        [
                            31.19726562499997,
                            -22.344921874999983
                        ],
                        [
                            31.287890625000074,
                            -22.402050781249983
                        ],
                        [
                            31.54560546875004,
                            -23.48232421874998
                        ],
                        [
                            31.799609375000017,
                            -23.89218749999999
                        ],
                        [
                            31.858300781249994,
                            -24.04023437500004
                        ],
                        [
                            31.98583984374997,
                            -24.460644531249983
                        ],
                        [
                            31.983203125000017,
                            -24.63828125000002
                        ],
                        [
                            31.98437500000003,
                            -24.844042968750017
                        ],
                        [
                            31.987011718750097,
                            -25.263476562500017
                        ],
                        [
                            31.98457031250004,
                            -25.631933593750034
                        ],
                        [
                            31.920312499999994,
                            -25.773925781250014
                        ],
                        [
                            31.921679687500017,
                            -25.96875
                        ]
                    ],
                    [
                        [
                            27.19355468750001,
                            -29.94130859375001
                        ],
                        [
                            27.364062500000017,
                            -30.27919921875001
                        ],
                        [
                            27.753125000000068,
                            -30.599999999999994
                        ],
                        [
                            28.05683593750001,
                            -30.63105468750001
                        ],
                        [
                            28.128710937500017,
                            -30.52509765625001
                        ],
                        [
                            28.39208984375003,
                            -30.14755859375002
                        ],
                        [
                            29.09804687500005,
                            -29.919042968750006
                        ],
                        [
                            29.142187500000063,
                            -29.70097656249999
                        ],
                        [
                            29.293554687500006,
                            -29.56689453125003
                        ],
                        [
                            29.348828125000097,
                            -29.441992187499977
                        ],
                        [
                            29.38671874999997,
                            -29.319726562500023
                        ],
                        [
                            29.390722656250006,
                            -29.26972656249997
                        ],
                        [
                            29.370898437500017,
                            -29.218457031249997
                        ],
                        [
                            29.301367187500006,
                            -29.08984375
                        ],
                        [
                            28.625781250000017,
                            -28.581738281250054
                        ],
                        [
                            27.73554687500004,
                            -28.940039062500034
                        ],
                        [
                            27.294531250000063,
                            -29.519335937500017
                        ],
                        [
                            27.19355468750001,
                            -29.94130859375001
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'South Africa',
                'childNum': 2
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            33.148046875000006,
                            -9.603515625
                        ],
                        [
                            33.35097656250002,
                            -9.862207031250009
                        ],
                        [
                            33.33710937500001,
                            -9.954003906250009
                        ],
                        [
                            33.3115234375,
                            -10.037988281250009
                        ],
                        [
                            33.65908203125002,
                            -10.590527343750011
                        ],
                        [
                            33.46474609375002,
                            -10.783105468750009
                        ],
                        [
                            33.40312500000002,
                            -10.8017578125
                        ],
                        [
                            33.34492187500001,
                            -10.812695312500011
                        ],
                        [
                            33.29277343750002,
                            -10.852343750000003
                        ],
                        [
                            33.261328125000006,
                            -10.893359375000003
                        ],
                        [
                            33.29326171875002,
                            -10.981152343750011
                        ],
                        [
                            33.33867187500002,
                            -11.085156250000011
                        ],
                        [
                            33.37978515625002,
                            -11.157910156250011
                        ],
                        [
                            33.26835937500002,
                            -11.403906250000006
                        ],
                        [
                            33.23271484375002,
                            -11.417675781250011
                        ],
                        [
                            33.22636718750002,
                            -11.534863281250011
                        ],
                        [
                            33.25234375000002,
                            -12.112597656250003
                        ],
                        [
                            33.34013671875002,
                            -12.308300781250011
                        ],
                        [
                            33.49140625000001,
                            -12.3310546875
                        ],
                        [
                            33.48320312500002,
                            -12.403417968750006
                        ],
                        [
                            33.4306640625,
                            -12.46044921875
                        ],
                        [
                            33.39794921875,
                            -12.489843750000006
                        ],
                        [
                            33.24345703125002,
                            -12.556542968750009
                        ],
                        [
                            33.021582031250006,
                            -12.630468750000006
                        ],
                        [
                            32.94560546875002,
                            -12.804394531250011
                        ],
                        [
                            32.97050781250002,
                            -12.86474609375
                        ],
                        [
                            33,
                            -12.899609375000011
                        ],
                        [
                            32.96757812500002,
                            -13.225000000000009
                        ],
                        [
                            32.797460937500006,
                            -13.6884765625
                        ],
                        [
                            33.148046875000006,
                            -13.94091796875
                        ],
                        [
                            33.201757812500006,
                            -14.013378906250011
                        ],
                        [
                            31.728906250000023,
                            -14.49609375
                        ],
                        [
                            30.231835937500023,
                            -14.990332031250006
                        ],
                        [
                            30.396093750000006,
                            -15.64306640625
                        ],
                        [
                            29.729589843750006,
                            -15.644628906250006
                        ],
                        [
                            28.9130859375,
                            -15.98779296875
                        ],
                        [
                            28.760644531250023,
                            -16.53193359375001
                        ],
                        [
                            27.932226562500006,
                            -16.89619140625001
                        ],
                        [
                            27.020800781250017,
                            -17.95839843750001
                        ],
                        [
                            25.86328125,
                            -17.951953125000003
                        ],
                        [
                            25.2587890625,
                            -17.793554687500006
                        ],
                        [
                            24.932421875000017,
                            -17.54345703125
                        ],
                        [
                            24.27490234375,
                            -17.481054687500006
                        ],
                        [
                            23.380664062500017,
                            -17.640625
                        ],
                        [
                            23.181640625,
                            -17.474414062500003
                        ],
                        [
                            22.955859375000017,
                            -17.285742187500006
                        ],
                        [
                            22.305078125000023,
                            -16.689550781250006
                        ],
                        [
                            22.193945312500006,
                            -16.62812500000001
                        ],
                        [
                            22.150683593750017,
                            -16.59716796875
                        ],
                        [
                            21.979785156250017,
                            -15.95556640625
                        ],
                        [
                            21.979785156250017,
                            -15.72412109375
                        ],
                        [
                            21.97968750000001,
                            -15.403222656250009
                        ],
                        [
                            21.9794921875,
                            -14.761425781250011
                        ],
                        [
                            21.979394531250023,
                            -14.440527343750006
                        ],
                        [
                            21.979296875000017,
                            -14.11962890625
                        ],
                        [
                            21.979101562500006,
                            -13.798730468750009
                        ],
                        [
                            21.979101562500006,
                            -13.477734375000011
                        ],
                        [
                            21.978906250000023,
                            -13.0009765625
                        ],
                        [
                            22.209570312500006,
                            -13.0009765625
                        ],
                        [
                            23.843164062500023,
                            -13.0009765625
                        ],
                        [
                            23.90937500000001,
                            -12.636132812500009
                        ],
                        [
                            23.991308593750006,
                            -12.422167968750003
                        ],
                        [
                            23.96650390625001,
                            -10.871777343750011
                        ],
                        [
                            24.319921875000006,
                            -11.07177734375
                        ],
                        [
                            24.5185546875,
                            -11.4384765625
                        ],
                        [
                            25.184863281250017,
                            -11.242968750000003
                        ],
                        [
                            25.349414062500017,
                            -11.623046875
                        ],
                        [
                            26.025976562500006,
                            -11.89013671875
                        ],
                        [
                            26.59638671875001,
                            -11.972070312500009
                        ],
                        [
                            26.949609375000023,
                            -11.898828125000009
                        ],
                        [
                            27.04609375000001,
                            -11.615917968750011
                        ],
                        [
                            27.53339843750001,
                            -12.1953125
                        ],
                        [
                            28.357714843750017,
                            -12.482031250000006
                        ],
                        [
                            29.014257812500006,
                            -13.368847656250011
                        ],
                        [
                            29.55419921875,
                            -13.248925781250009
                        ],
                        [
                            29.795312500000023,
                            -13.392773437500011
                        ],
                        [
                            29.79511718750001,
                            -12.155468750000011
                        ],
                        [
                            29.427539062500017,
                            -12.431250000000006
                        ],
                        [
                            29.064355468750023,
                            -12.348828125000011
                        ],
                        [
                            28.482519531250006,
                            -11.812109375000006
                        ],
                        [
                            28.357226562500017,
                            -11.483007812500006
                        ],
                        [
                            28.63886718750001,
                            -10.669238281250003
                        ],
                        [
                            28.63007812500001,
                            -9.831250000000011
                        ],
                        [
                            28.400683593750017,
                            -9.224804687500011
                        ],
                        [
                            28.917773437500017,
                            -8.700585937500009
                        ],
                        [
                            28.972265625000006,
                            -8.464941406250006
                        ],
                        [
                            30.75117187500001,
                            -8.193652343750003
                        ],
                        [
                            30.830664062500006,
                            -8.385546875000003
                        ],
                        [
                            31.07636718750001,
                            -8.611914062500006
                        ],
                        [
                            31.3505859375,
                            -8.607031250000006
                        ],
                        [
                            31.44921875,
                            -8.653906250000006
                        ],
                        [
                            31.53486328125001,
                            -8.713281250000009
                        ],
                        [
                            31.673632812500017,
                            -8.908789062500006
                        ],
                        [
                            31.94257812500001,
                            -9.054003906250003
                        ],
                        [
                            32.75664062500002,
                            -9.322265625
                        ],
                        [
                            33.148046875000006,
                            -9.603515625
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Zambia',
                'childNum': 1
            }
        },
        {
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [
                            31.287890625000017,
                            -22.40205078125001
                        ],
                        [
                            31.07343750000001,
                            -22.30781250000001
                        ],
                        [
                            30.71162109375001,
                            -22.2978515625
                        ],
                        [
                            30.46015625000001,
                            -22.32900390625001
                        ],
                        [
                            29.6630859375,
                            -22.146289062500003
                        ],
                        [
                            29.37744140625,
                            -22.19277343750001
                        ],
                        [
                            29.364843750000006,
                            -22.193945312500006
                        ],
                        [
                            29.315234375000017,
                            -22.15771484375
                        ],
                        [
                            29.237207031250023,
                            -22.07949218750001
                        ],
                        [
                            29.106835937500023,
                            -22.065722656250003
                        ],
                        [
                            29.03730468750001,
                            -21.811328125000003
                        ],
                        [
                            28.04560546875001,
                            -21.573046875000003
                        ],
                        [
                            27.66943359375,
                            -21.064257812500003
                        ],
                        [
                            27.699609375000023,
                            -20.53066406250001
                        ],
                        [
                            27.28076171875,
                            -20.47871093750001
                        ],
                        [
                            27.221484375000017,
                            -20.145800781250003
                        ],
                        [
                            26.67822265625,
                            -19.89277343750001
                        ],
                        [
                            26.168066406250006,
                            -19.53828125000001
                        ],
                        [
                            25.76123046875,
                            -18.649218750000003
                        ],
                        [
                            25.224023437500023,
                            -17.91523437500001
                        ],
                        [
                            25.2587890625,
                            -17.793554687500006
                        ],
                        [
                            25.86328125,
                            -17.951953125000003
                        ],
                        [
                            27.020800781250017,
                            -17.95839843750001
                        ],
                        [
                            27.932226562500006,
                            -16.89619140625001
                        ],
                        [
                            28.760644531250023,
                            -16.53193359375001
                        ],
                        [
                            28.9130859375,
                            -15.98779296875
                        ],
                        [
                            29.729589843750006,
                            -15.644628906250006
                        ],
                        [
                            30.396093750000006,
                            -15.64306640625
                        ],
                        [
                            30.437792968750017,
                            -15.995312500000011
                        ],
                        [
                            31.236230468750023,
                            -16.02363281250001
                        ],
                        [
                            31.939843750000023,
                            -16.428808593750006
                        ],
                        [
                            32.243261718750006,
                            -16.44873046875
                        ],
                        [
                            32.94804687500002,
                            -16.71230468750001
                        ],
                        [
                            32.99306640625002,
                            -18.35957031250001
                        ],
                        [
                            32.71650390625001,
                            -19.00185546875001
                        ],
                        [
                            33.00673828125002,
                            -19.873828125000003
                        ],
                        [
                            32.477636718750006,
                            -20.712988281250006
                        ],
                        [
                            32.37109375,
                            -21.33486328125001
                        ],
                        [
                            31.429492187500017,
                            -22.298828125
                        ],
                        [
                            31.287890625000017,
                            -22.40205078125001
                        ]
                    ]
                ]
            },
            'properties': {
                'name': 'Zimbabwe',
                'childNum': 1
            }
        }
    ]
};


export const  geoCoordMap = {
    'Amsterdam': [4.895168,52.370216],
    'Athens': [-83.357567,33.951935],
    'Auckland': [174.763332,-36.84846],
    'Bangkok': [100.501765,13.756331],
    'Barcelona': [2.173403,41.385064],
    'Beijing': [116.407395,39.904211],
    'Berlin': [13.404954,52.520007],
    'Bogotá': [-74.072092,4.710989],
    'Bratislava': [17.107748,48.148596],
    'Brussels': [4.35171,50.85034],
    'Budapest': [19.040235,47.497912],
    'Buenos Aires': [-58.381559,-34.603684],
    'Bucharest': [26.102538,44.426767],
    'Caracas': [-66.903606,10.480594],
    'Chicago': [-87.629798,41.878114],
    'Delhi': [77.209021,28.613939],
    'Doha': [51.53104,25.285447],
    'Dubai': [55.270783,25.204849],
    'Dublin': [-6.26031,53.349805],
    'Frankfurt': [8.682127,50.110922],
    'Geneva': [6.143158,46.204391],
    'Helsinki': [24.938379,60.169856],
    'Hong Kong': [114.109497,22.396428],
    'Istanbul': [28.978359,41.008238],
    'Jakarta': [106.845599,-6.208763],
    'Johannesburg': [28.047305,-26.204103],
    'Cairo': [31.235712,30.04442],
    'Kiev': [30.5234,50.4501],
    'Copenhagen': [12.568337,55.676097],
    'Kuala Lumpur': [101.686855,3.139003],
    'Lima': [-77.042793,-12.046374],
    'Lisbon': [-9.139337,38.722252],
    'Ljubljana': [14.505751,46.056947],
    'London': [-0.127758,51.507351],
    'Los Angeles': [-118.243685,34.052234],
    'Luxembourg': [6.129583,49.815273],
    'Lyon': [4.835659,45.764043],
    'Madrid': [-3.70379,40.416775],
    'Milan': [9.185924,45.465422],
    'Manama': [50.58605,26.228516],
    'Manila': [120.984219,14.599512],
    'Mexico City': [-99.133208,19.432608],
    'Miami': [-80.19179,25.76168],
    'Montreal': [-73.567256,45.501689],
    'Moscow': [37.6173,55.755826],
    'Mumbai': [72.877656,19.075984],
    'Munich': [11.581981,48.135125],
    'Nairobi': [36.821946,-1.292066],
    'New York': [-74.005941,40.712784],
    'Nicosia': [33.382276,35.185566],
    'Oslo': [10.752245,59.913869],
    'Paris': [2.352222,48.856614],
    'Prague': [14.4378,50.075538],
    'Riga': [24.105186,56.949649],
    'Rio de Janeiro': [-43.172896,-22.906847],
    'Rome': [12.496366,41.902783],
    'Santiago de Chile': [-70.669265,-33.44889],
    'São Paulo': [-46.633309,-23.55052],
    'Seoul': [126.977969,37.566535],
    'Shanghai': [121.473701,31.230416],
    'Singapore': [103.819836,1.352083],
    'Sofia': [23.321868,42.697708],
    'Stockholm': [18.068581,59.329323],
    'Sydney': [151.209296,-33.86882],
    'Taipei': [121.565418,25.032969],
    'Tallinn': [24.753575,59.436961],
    'Tel Aviv': [34.781768,32.0853],
    'Tokyo': [139.691706,35.689487],
    'Toronto': [-79.383184,43.653226],
    'Vilnius': [25.279651,54.687156],
    'Warsaw': [21.012229,52.229676],
    'Vienna': [16.373819,48.208174],
    'Zurich': [8.541694,47.376887]
};

export const  schema = [ 
    'Cities',
    'Gross purchasing power',
    'Net purchasing power',
    'Prices (excl. rent)',
    'Prices (incl. rent)',
    'Gross wages',
    'Net wages',
    'Working time [hours per year]',
    'Vacation [paid working days per year]',
    'Time required for 1 Big Mac [minutes]',
    'Time required for 1 kg of bread [minutes]',
    'Time required for 1 kg of rice [minutes]',
    'Time required for 1 iPhone 4S, 16 GB [hours]',
    'City break',
    'Inflation 2006',
    'Inflation 2007',
    'Inflation 2008',
    'Inflation 2009',
    'Inflation 2010',
    'Inflation 2011',
    'Prices (incl. rent)',
    'Food basket',
    'Services',
    'Normal local rent medium [USD per month]',
    'Household appliances',
    'Bus or tram or underground',
    'Train',
    'Taxi  [USD per 5 km trip]',
    'Medium-sized cars price',
    'Medium-sized cars tax',
    'Medium-sized cars gas',
    'Restaurant [USD per dinner]',
    'Hotel *** [USD per night]',
    'Hotel ***** [USD per night]',
    'Women\'s medium clothing',
    'Men\'s medium clothing',
    'Furnished medium 4-room apartment [USD per month]',
    'Unfurnished medium 3-room apartment [USD per month]',
    'Net hourly wages [USD per hour]',
    'Gross hourly wages [USD per hour]',
    'Taxes and social security contributions',
    'Primary school teacher [USD per year]',
    'Bus driver [USD per year]',
    'Automobile mechanic [USD per year]',
    'Building labourer [USD per year]',
    'Skilled industrial worker [USD per year]',
    'Cook [USD per year]',
    'Departement head [USD per year]',
    'Product manager [USD per year]',
    'Engineer [USD per year]',
    'Bank credit clerk [USD per year]',
    'Secretary [USD per year]',
    'Saleswoman [USD per year]',
    'Female industrial worker [USD per year]',
    'Female call center worker [USD per year]',
    'Financial analyst [USD per year]',
    'Financial analyst [USD pro Jahr]'
];

export const worldMap = [{'code':'AF' , 'name':'Afghanistan', 'value':32358260, 'color':'#eea638'},
    {'code':'AL' , 'name':'Albania', 'value':3215988, 'color':'#d8854f'},
    {'code':'DZ' , 'name':'Algeria', 'value':35980193, 'color':'#de4c4f'},
    {'code':'AO' , 'name':'Angola', 'value':19618432, 'color':'#de4c4f'},
    {'code':'AR' , 'name':'Argentina', 'value':40764561, 'color':'#86a965'},
    {'code':'AM' , 'name':'Armenia', 'value':3100236, 'color':'#d8854f'},
    {'code':'AU' , 'name':'Australia', 'value':22605732, 'color':'#8aabb0'},
    {'code':'AT' , 'name':'Austria', 'value':8413429, 'color':'#d8854f'},
    {'code':'AZ' , 'name':'Azerbaijan', 'value':9306023, 'color':'#d8854f'},
    {'code':'BH' , 'name':'Bahrain', 'value':1323535, 'color':'#eea638'},
    {'code':'BD' , 'name':'Bangladesh', 'value':150493658, 'color':'#eea638'},
    {'code':'BY' , 'name':'Belarus', 'value':9559441, 'color':'#d8854f'},
    {'code':'BE' , 'name':'Belgium', 'value':10754056, 'color':'#d8854f'},
    {'code':'BJ' , 'name':'Benin', 'value':9099922, 'color':'#de4c4f'},
    {'code':'BT' , 'name':'Bhutan', 'value':738267, 'color':'#eea638'},
    {'code':'BO' , 'name':'Bolivia', 'value':10088108, 'color':'#86a965'},
    {'code':'BA' , 'name':'Bosnia and Herzegovina', 'value':3752228, 'color':'#d8854f'},
    {'code':'BW' , 'name':'Botswana', 'value':2030738, 'color':'#de4c4f'},
    {'code':'BR' , 'name':'Brazil', 'value':196655014, 'color':'#86a965'},
    {'code':'BN' , 'name':'Brunei', 'value':405938, 'color':'#eea638'},
    {'code':'BG' , 'name':'Bulgaria', 'value':7446135, 'color':'#d8854f'},
    {'code':'BF' , 'name':'Burkina Faso', 'value':16967845, 'color':'#de4c4f'},
    {'code':'BI' , 'name':'Burundi', 'value':8575172, 'color':'#de4c4f'},
    {'code':'KH' , 'name':'Cambodia', 'value':14305183, 'color':'#eea638'},
    {'code':'CM' , 'name':'Cameroon', 'value':20030362, 'color':'#de4c4f'},
    {'code':'CA' , 'name':'Canada', 'value':34349561, 'color':'#a7a737'},
    {'code':'CV' , 'name':'Cape Verde', 'value':500585, 'color':'#de4c4f'},
    {'code':'CF' , 'name':'Central African Rep.', 'value':4486837, 'color':'#de4c4f'},
    {'code':'TD' , 'name':'Chad', 'value':11525496, 'color':'#de4c4f'},
    {'code':'CL' , 'name':'Chile', 'value':17269525, 'color':'#86a965'},
    {'code':'CN' , 'name':'China', 'value':1347565324, 'color':'#eea638'},
    {'code':'CO' , 'name':'Colombia', 'value':46927125, 'color':'#86a965'},
    {'code':'KM' , 'name':'Comoros', 'value':753943, 'color':'#de4c4f'},
    {'code':'CD' , 'name':'Congo, Dem. Rep.', 'value':67757577, 'color':'#de4c4f'},
    {'code':'CG' , 'name':'Congo, Rep.', 'value':4139748, 'color':'#de4c4f'},
    {'code':'CR' , 'name':'Costa Rica', 'value':4726575, 'color':'#a7a737'},
    {'code':'CI' , 'name':'Cote d\'Ivoire', 'value':20152894, 'color':'#de4c4f'},
    {'code':'HR' , 'name':'Croatia', 'value':4395560, 'color':'#d8854f'},
    {'code':'CU' , 'name':'Cuba', 'value':11253665, 'color':'#a7a737'},
    {'code':'CY' , 'name':'Cyprus', 'value':1116564, 'color':'#d8854f'},
    {'code':'CZ' , 'name':'Czech Rep.', 'value':10534293, 'color':'#d8854f'},
    {'code':'DK' , 'name':'Denmark', 'value':5572594, 'color':'#d8854f'},
    {'code':'DJ' , 'name':'Djibouti', 'value':905564, 'color':'#de4c4f'},
    {'code':'DO' , 'name':'Dominican Rep.', 'value':10056181, 'color':'#a7a737'},
    {'code':'EC' , 'name':'Ecuador', 'value':14666055, 'color':'#86a965'},
    {'code':'EG' , 'name':'Egypt', 'value':82536770, 'color':'#de4c4f'},
    {'code':'SV' , 'name':'El Salvador', 'value':6227491, 'color':'#a7a737'},
    {'code':'GQ' , 'name':'Equatorial Guinea', 'value':720213, 'color':'#de4c4f'},
    {'code':'ER' , 'name':'Eritrea', 'value':5415280, 'color':'#de4c4f'},
    {'code':'EE' , 'name':'Estonia', 'value':1340537, 'color':'#d8854f'},
    {'code':'ET' , 'name':'Ethiopia', 'value':84734262, 'color':'#de4c4f'},
    {'code':'FJ' , 'name':'Fiji', 'value':868406, 'color':'#8aabb0'},
    {'code':'FI' , 'name':'Finland', 'value':5384770, 'color':'#d8854f'},
    {'code':'FR' , 'name':'France', 'value':63125894, 'color':'#d8854f'},
    {'code':'GA' , 'name':'Gabon', 'value':1534262, 'color':'#de4c4f'},
    {'code':'GM' , 'name':'Gambia', 'value':1776103, 'color':'#de4c4f'},
    {'code':'GE' , 'name':'Georgia', 'value':4329026, 'color':'#d8854f'},
    {'code':'DE' , 'name':'Germany', 'value':82162512, 'color':'#d8854f'},
    {'code':'GH' , 'name':'Ghana', 'value':24965816, 'color':'#de4c4f'},
    {'code':'GR' , 'name':'Greece', 'value':11390031, 'color':'#d8854f'},
    {'code':'GT' , 'name':'Guatemala', 'value':14757316, 'color':'#a7a737'},
    {'code':'GN' , 'name':'Guinea', 'value':10221808, 'color':'#de4c4f'},
    {'code':'GW' , 'name':'Guinea-Bissau', 'value':1547061, 'color':'#de4c4f'},
    {'code':'GY' , 'name':'Guyana', 'value':756040, 'color':'#86a965'},
    {'code':'HT' , 'name':'Haiti', 'value':10123787, 'color':'#a7a737'},
    {'code':'HN' , 'name':'Honduras', 'value':7754687, 'color':'#a7a737'},
    {'code':'HK' , 'name':'Hong Kong, China', 'value':7122187, 'color':'#eea638'},
    {'code':'HU' , 'name':'Hungary', 'value':9966116, 'color':'#d8854f'},
    {'code':'IS' , 'name':'Iceland', 'value':324366, 'color':'#d8854f'},
    {'code':'IN' , 'name':'India', 'value':1241491960, 'color':'#eea638'},
    {'code':'ID' , 'name':'Indonesia', 'value':242325638, 'color':'#eea638'},
    {'code':'IR' , 'name':'Iran', 'value':74798599, 'color':'#eea638'},
    {'code':'IQ' , 'name':'Iraq', 'value':32664942, 'color':'#eea638'},
    {'code':'IE' , 'name':'Ireland', 'value':4525802, 'color':'#d8854f'},
    {'code':'IL' , 'name':'Israel', 'value':7562194, 'color':'#eea638'},
    {'code':'IT' , 'name':'Italy', 'value':60788694, 'color':'#d8854f'},
    {'code':'JM' , 'name':'Jamaica', 'value':2751273, 'color':'#a7a737'},
    {'code':'JP' , 'name':'Japan', 'value':126497241, 'color':'#eea638'},
    {'code':'JO' , 'name':'Jordan', 'value':6330169, 'color':'#eea638'},
    {'code':'KZ' , 'name':'Kazakhstan', 'value':16206750, 'color':'#eea638'},
    {'code':'KE' , 'name':'Kenya', 'value':41609728, 'color':'#de4c4f'},
    {'code':'KP' , 'name':'Korea, Dem. Rep.', 'value':24451285, 'color':'#eea638'},
    {'code':'KR' , 'name':'Korea, Rep.', 'value':48391343, 'color':'#eea638'},
    {'code':'KW' , 'name':'Kuwait', 'value':2818042, 'color':'#eea638'},
    {'code':'KG' , 'name':'Kyrgyzstan', 'value':5392580, 'color':'#eea638'},
    {'code':'LA' , 'name':'Laos', 'value':6288037, 'color':'#eea638'},
    {'code':'LV' , 'name':'Latvia', 'value':2243142, 'color':'#d8854f'},
    {'code':'LB' , 'name':'Lebanon', 'value':4259405, 'color':'#eea638'},
    {'code':'LS' , 'name':'Lesotho', 'value':2193843, 'color':'#de4c4f'},
    {'code':'LR' , 'name':'Liberia', 'value':4128572, 'color':'#de4c4f'},
    {'code':'LY' , 'name':'Libya', 'value':6422772, 'color':'#de4c4f'},
    {'code':'LT' , 'name':'Lithuania', 'value':3307481, 'color':'#d8854f'},
    {'code':'LU' , 'name':'Luxembourg', 'value':515941, 'color':'#d8854f'},
    {'code':'MK' , 'name':'Macedonia, FYR', 'value':2063893, 'color':'#d8854f'},
    {'code':'MG' , 'name':'Madagascar', 'value':21315135, 'color':'#de4c4f'},
    {'code':'MW' , 'name':'Malawi', 'value':15380888, 'color':'#de4c4f'},
    {'code':'MY' , 'name':'Malaysia', 'value':28859154, 'color':'#eea638'},
    {'code':'ML' , 'name':'Mali', 'value':15839538, 'color':'#de4c4f'},
    {'code':'MR' , 'name':'Mauritania', 'value':3541540, 'color':'#de4c4f'},
    {'code':'MU' , 'name':'Mauritius', 'value':1306593, 'color':'#de4c4f'},
    {'code':'MX' , 'name':'Mexico', 'value':114793341, 'color':'#a7a737'},
    {'code':'MD' , 'name':'Moldova', 'value':3544864, 'color':'#d8854f'},
    {'code':'MN' , 'name':'Mongolia', 'value':2800114, 'color':'#eea638'},
    {'code':'ME' , 'name':'Montenegro', 'value':632261, 'color':'#d8854f'},
    {'code':'MA' , 'name':'Morocco', 'value':32272974, 'color':'#de4c4f'},
    {'code':'MZ' , 'name':'Mozambique', 'value':23929708, 'color':'#de4c4f'},
    {'code':'MM' , 'name':'Myanmar', 'value':48336763, 'color':'#eea638'},
    {'code':'NA' , 'name':'Namibia', 'value':2324004, 'color':'#de4c4f'},
    {'code':'NP' , 'name':'Nepal', 'value':30485798, 'color':'#eea638'},
    {'code':'NL' , 'name':'Netherlands', 'value':16664746, 'color':'#d8854f'},
    {'code':'NZ' , 'name':'New Zealand', 'value':4414509, 'color':'#8aabb0'},
    {'code':'NI' , 'name':'Nicaragua', 'value':5869859, 'color':'#a7a737'},
    {'code':'NE' , 'name':'Niger', 'value':16068994, 'color':'#de4c4f'},
    {'code':'NG' , 'name':'Nigeria', 'value':162470737, 'color':'#de4c4f'},
    {'code':'NO' , 'name':'Norway', 'value':4924848, 'color':'#d8854f'},
    {'code':'OM' , 'name':'Oman', 'value':2846145, 'color':'#eea638'},
    {'code':'PK' , 'name':'Pakistan', 'value':176745364, 'color':'#eea638'},
    {'code':'PA' , 'name':'Panama', 'value':3571185, 'color':'#a7a737'},
    {'code':'PG' , 'name':'Papua New Guinea', 'value':7013829, 'color':'#8aabb0'},
    {'code':'PY' , 'name':'Paraguay', 'value':6568290, 'color':'#86a965'},
    {'code':'PE' , 'name':'Peru', 'value':29399817, 'color':'#86a965'},
    {'code':'PH' , 'name':'Philippines', 'value':94852030, 'color':'#eea638'},
    {'code':'PL' , 'name':'Poland', 'value':38298949, 'color':'#d8854f'},
    {'code':'PT' , 'name':'Portugal', 'value':10689663, 'color':'#d8854f'},
    {'code':'PR' , 'name':'Puerto Rico', 'value':3745526, 'color':'#a7a737'},
    {'code':'QA' , 'name':'Qatar', 'value':1870041, 'color':'#eea638'},
    {'code':'RO' , 'name':'Romania', 'value':21436495, 'color':'#d8854f'},
    {'code':'RU' , 'name':'Russia', 'value':142835555, 'color':'#d8854f'},
    {'code':'RW' , 'name':'Rwanda', 'value':10942950, 'color':'#de4c4f'},
    {'code':'SA' , 'name':'Saudi Arabia', 'value':28082541, 'color':'#eea638'},
    {'code':'SN' , 'name':'Senegal', 'value':12767556, 'color':'#de4c4f'},
    {'code':'RS' , 'name':'Serbia', 'value':9853969, 'color':'#d8854f'},
    {'code':'SL' , 'name':'Sierra Leone', 'value':5997486, 'color':'#de4c4f'},
    {'code':'SG' , 'name':'Singapore', 'value':5187933, 'color':'#eea638'},
    {'code':'SK' , 'name':'Slovak Republic', 'value':5471502, 'color':'#d8854f'},
    {'code':'SI' , 'name':'Slovenia', 'value':2035012, 'color':'#d8854f'},
    {'code':'SB' , 'name':'Solomon Islands', 'value':552267, 'color':'#8aabb0'},
    {'code':'SO' , 'name':'Somalia', 'value':9556873, 'color':'#de4c4f'},
    {'code':'ZA' , 'name':'South Africa', 'value':50459978, 'color':'#de4c4f'},
    {'code':'ES' , 'name':'Spain', 'value':46454895, 'color':'#d8854f'},
    {'code':'LK' , 'name':'Sri Lanka', 'value':21045394, 'color':'#eea638'},
    {'code':'SD' , 'name':'Sudan', 'value':34735288, 'color':'#de4c4f'},
    {'code':'SR' , 'name':'Suriname', 'value':529419, 'color':'#86a965'},
    {'code':'SZ' , 'name':'Swaziland', 'value':1203330, 'color':'#de4c4f'},
    {'code':'SE' , 'name':'Sweden', 'value':9440747, 'color':'#d8854f'},
    {'code':'CH' , 'name':'Switzerland', 'value':7701690, 'color':'#d8854f'},
    {'code':'SY' , 'name':'Syria', 'value':20766037, 'color':'#eea638'},
    {'code':'TW' , 'name':'Taiwan', 'value':23072000, 'color':'#eea638'},
    {'code':'TJ' , 'name':'Tajikistan', 'value':6976958, 'color':'#eea638'},
    {'code':'TZ' , 'name':'Tanzania', 'value':46218486, 'color':'#de4c4f'},
    {'code':'TH' , 'name':'Thailand', 'value':69518555, 'color':'#eea638'},
    {'code':'TG' , 'name':'Togo', 'value':6154813, 'color':'#de4c4f'},
    {'code':'TT' , 'name':'Trinidad and Tobago', 'value':1346350, 'color':'#a7a737'},
    {'code':'TN' , 'name':'Tunisia', 'value':10594057, 'color':'#de4c4f'},
    {'code':'TR' , 'name':'Turkey', 'value':73639596, 'color':'#d8854f'},
    {'code':'TM' , 'name':'Turkmenistan', 'value':5105301, 'color':'#eea638'},
    {'code':'UG' , 'name':'Uganda', 'value':34509205, 'color':'#de4c4f'},
    {'code':'UA' , 'name':'Ukraine', 'value':45190180, 'color':'#d8854f'},
    {'code':'AE' , 'name':'United Arab Emirates', 'value':7890924, 'color':'#eea638'},
    {'code':'GB' , 'name':'United Kingdom', 'value':62417431, 'color':'#d8854f'},
    {'code':'US' , 'name':'United States', 'value':313085380, 'color':'#a7a737'},
    {'code':'UY' , 'name':'Uruguay', 'value':3380008, 'color':'#86a965'},
    {'code':'UZ' , 'name':'Uzbekistan', 'value':27760267, 'color':'#eea638'},
    {'code':'VE' , 'name':'Venezuela', 'value':29436891, 'color':'#86a965'},
    {'code':'PS' , 'name':'West Bank and Gaza', 'value':4152369, 'color':'#eea638'},
    {'code':'VN' , 'name':'Vietnam', 'value':88791996, 'color':'#eea638'},
    {'code':'YE' , 'name':'Yemen, Rep.', 'value':24799880, 'color':'#eea638'},
    {'code':'ZM' , 'name':'Zambia', 'value':13474959, 'color':'#de4c4f'},
    {'code':'ZW' , 'name':'Zimbabwe', 'value':12754378, 'color':'#de4c4f'}];


export const rawData = [
    ['Amsterdam',101.6,90.1,77.1,69.1,78.3,69.4,1755,24,15,7,9,44,720,1.651,1.59,2.205,0.974,0.93,2.477,67.4,364,690,1113,4960,3.19,30.05,16.34,24000,689,1.8,50,200,390,690,1040,2331,1580,17.5,25.5,30,48400,39200,26300,30200,55400,39800,104400,58700,64600,49200,40300,31100,40300,27700,66700,66700  ],
    ['Athens',62.6,60.5,66.2,58.2,41.4,40,1822,22,29,13,25,86,590,3.314,2.991,4.236,1.349,4.701,3.1,56.8,390,580,880,4620,1.81,13.81,5.5,24900,389,2.02,54,100,210,630,1110,1489,647,10.1,13.5,24,26200,23300,18500,17100,24500,24200,57200,44000,34100,30700,21000,17700,15400,16300,34400,34400  ],
    ['Auckland',77.9,82.9,76.7,67.8,59.8,63.5,1852,20,15,16,7,51,580,3.362,2.377,3.959,2.116,2.303,4.027,66.1,496,630,1023,4450,2.57,40.86,13.62,23900,226,1.33,45,190,280,560,670,1644,1333,16,19.5,17,35700,31500,36500,28500,41800,31100,61300,55000,56300,37300,33400,26900,27200,27500,64900,64900  ],
    ['Bangkok',26.4,31.4,55.4,48.2,14.6,17.4,2312,7,36,25,20,165,550,4.637,2.242,5.468,-0.845,3.272,3.807,47,422,440,414,4370,0.75,3.47,2.47,29600,103,1,56,90,320,400,600,1463,932,4.4,4.8,5,8300,8400,11100,3000,10900,10900,32200,22400,24600,14500,7800,6000,5800,6500,19400,19400  ],
    ['Barcelona',79.7,78.6,74.7,65.6,59.6,58.7,1760,29,18,11,6,52,740,3.563,2.844,4.13,-0.238,2.043,3.052,64,393,750,984,5000,2.59,41.96,10.36,26900,177,1.77,51,170,330,580,1110,1269,1087,14.8,19.4,23,41300,34100,29100,29800,31500,32100,40800,67000,43100,38900,28900,25500,25000,28000,58300,58300  ],
    ['Beijing',28.2,29.9,60.3,51.8,17,18,1979,9,34,27,16,184,730,1.467,4.767,5.852,-0.683,3.325,5.417,50.6,463,420,310,4370,0.26,14.25,3.64,23800,67,1.24,41,160,400,660,700,1554,660,4.5,5.6,17,11400,7000,8500,7600,6200,11900,13300,11700,10700,18300,17100,8900,5400,7600,19800,19800  ],
    ['Berlin',109.7,97.1,72.2,64.1,79.2,70.1,1742,28,16,11,9,56,720,1.784,2.276,2.754,0.234,1.15,2.483,62.5,389,530,841,4670,2.98,80.3,10.79,35600,246,2.1,34,120,230,570,710,2395,1178,17.7,25.8,30,56900,38600,35500,28500,47400,57600,84200,74500,72100,51700,38100,28200,32000,28100,81700,81700  ],
    ['Bogotá',41.4,40.7,53.1,47,22.3,22,1981,15,52,34,17,142,540,4.296,5.544,6.998,4.202,2.27,3.416,45.8,363,410,634,4170,0.84,null,2.81,20200,303,1.24,25,140,300,310,440,1554,841,5.5,7.3,12,11100,7600,8400,7000,7300,11900,27600,65800,19700,14600,12300,7400,4100,5300,31900,31900  ],
    ['Bratislava',51.3,50.7,53.9,47.1,27.7,27.3,1884,23,31,20,19,126,490,4.264,1.89,3.935,0.925,0.697,4.079,45.9,344,330,414,4740,1.08,22.97,5.61,26700,65,1.93,28,120,230,250,340,1683,841,6.9,9,22,11300,14100,11300,10000,16300,18900,20300,43300,22800,15800,16100,9600,12600,16400,48300,48300  ],
    ['Brussels',107.5,78.5,75.8,68.8,81.5,59.5,1729,20,19,10,11,54,730,2.337,1.814,4.493,-0.009,2.332,3.469,67.1,433,690,1243,4580,2.42,26.03,15.71,23200,500,1.91,63,130,280,630,800,2538,1839,15,26.5,42,44000,36500,38200,34200,52100,43600,97000,73700,67100,56900,42300,35200,33500,36000,78200,78200  ],
    ['Budapest',35.5,32,56.7,50.4,20.1,18.1,1912,22,49,13,26,206,740,3.878,7.934,6.067,4.209,4.85,3.9,49.1,340,390,556,5270,1.43,15.96,7.64,22600,76,1.95,28,130,410,580,920,2123,1165,4.6,6.6,28,8900,11500,9300,7000,10900,16800,25600,21100,23300,21400,11100,8200,6500,8900,29300,29300  ],
    ['Buenos Aires',42.9,46.2,55,47.7,23.6,25.4,1830,13,45,12,16,187,620,10.898,8.83,8.585,6.27,10.461,9.775,46.5,310,380,401,5170,0.28,null,7.97,20200,307,1.27,25,160,280,400,660,1359,738,6.4,7.7,16,8700,16300,11900,10200,11400,15800,34300,17600,19200,17100,15800,14600,7500,10400,15400,15400  ],
    ['Bucharest',37.1,34,39.8,34.8,14.8,13.5,1836,26,57,21,26,230,370,6.552,4.84,7.848,5.581,6.101,5.812,33.9,244,270,388,3830,0.75,9.43,3.17,11700,36,1.9,25,100,190,180,300,984,530,3.4,4.8,29,5600,7500,9500,7900,7400,14900,13900,22000,11400,8800,6000,8200,5800,5300,13200,13200  ],
    ['Caracas',21.9,25.7,91.1,85.4,20,23.4,1878,17,80,59,13,272,830,13.654,18.703,30.37,27.081,28.187,26.09,83.3,689,690,2098,8460,0.35,null,11.65,49000,18,0.01,68,190,400,520,950,3820,2784,5.9,6.5,7,10500,5400,10900,6100,9700,25400,16800,30600,22800,15800,8400,9500,5400,6700,12400,12400  ],
    ['Chicago',105.3,101.9,79.1,72.9,83.3,80.6,1853,12,11,15,9,32,540,3.222,2.86,3.798,-0.321,1.641,3.142,71.1,460,780,1398,4270,2.25,34.99,12.5,22100,95,1.11,38,200,270,740,1200,3535,2214,20.3,27.1,25,49300,52100,44000,49000,58500,48300,79300,88500,88200,40300,42600,23200,33800,38700,103500,103500  ],
    ['Delhi',23,25,33.2,29.5,7.6,8.3,2264,13,65,15,40,370,370,6.177,6.372,8.349,10.882,11.99,8.628,28.8,208,210,466,4590,0.25,10.58,1.95,15300,93,0.77,18,100,250,260,410,867,556,2.1,2.5,11,4500,2500,1900,1300,4800,10200,13500,11100,9600,6700,5400,3000,1800,4000,14100,14100  ],
    ['Doha',38.8,50.2,68.6,66.9,26.6,34.4,2165,25,20,12,15,82,690,11.828,13.764,15.049,-4.865,-2.433,2,65.3,355,870,1735,3790,1.14,null,4.12,17000,27,0.24,63,200,360,340,470,4869,3004,8.7,8.7,0,22300,10400,9800,4100,20600,13700,49500,47000,32900,27100,19800,10900,3200,11500,23100,23100  ],
    ['Dubai',63.5,82,78.2,77.2,49.6,64.2,2095,24,11,10,14,46,1120,9.272,11.115,11.454,1.56,0.878,0.882,75.3,484,790,2447,4550,0.54,null,8.17,23100,94,1.01,95,200,680,1270,1450,4882,3483,16.2,16.2,0,35900,16300,14200,3600,38300,58100,116800,91400,64800,26200,22900,13600,9800,19600,80000,80000  ],
    ['Dublin',101.9,103.3,76.3,69.7,77.7,78.8,1707,21,14,7,10,39,580,2.7,2.873,3.108,-1.683,-1.557,1.139,68,454,720,1554,5160,2.74,34.53,14.89,32000,427,2.01,55,130,260,470,600,2331,1592,19.8,25.3,20,57100,45500,38300,28200,42600,42100,88800,86100,54300,45800,31700,25300,28200,28800,85000,85000  ],
    ['Frankfurt',102.2,90.5,86.3,77.2,88.2,78.1,1731,28,14,8,10,42,950,1.784,2.276,2.754,0.234,1.15,2.483,75.3,439,710,1282,5510,3.24,86.77,16.23,36800,78,1.89,68,130,370,840,890,2370,1644,19.7,28.7,30,60900,33300,40400,34100,48800,40000,83300,77700,77700,61600,50600,37900,34100,29100,104100,104100  ],
    ['Geneva',116,111.8,106.6,96.8,123.6,119.2,1893,20,14,6,7,24,1220,1.047,0.732,2.43,-0.476,0.685,0.228,94.5,714,1080,1567,5330,3.67,58.23,27.78,25200,135,2.04,99,270,620,850,1150,4701,2434,30,40.2,24,89600,77400,61900,58900,78400,76500,105800,113300,89400,110900,64900,49200,55400,61800,171100,171100  ],
    ['Helsinki',93,86,86.5,82.3,80.2,74.2,1712,28,16,13,6,44,960,1.279,1.584,3.9,1.635,1.686,3.323,80.3,497,840,1437,5400,3.28,34.83,12.71,34600,152,1.97,44,200,450,610,1200,8677,1437,18.7,26.1,27,48000,42200,38200,38700,40500,41200,89900,60500,69200,44700,38600,34600,33500,35600,74500,74500  ],
    ['Hong Kong',58.5,68.1,73.1,75.2,42.8,49.8,2295,11,9,23,9,53,970,2.018,2.027,4.285,0.588,2.312,5.281,73.4,651,520,1800,4770,1.33,20.48,3.99,23800,509,1.65,58,290,610,390,620,9661,4222,12.5,13.9,9,52500,20100,20100,18900,23100,40800,64400,63700,44600,22300,25100,22100,14000,19000,62600,62600  ],
    ['Istanbul',39,39.4,71.5,65.6,27.9,28.2,2139,19,42,9,14,166,720,9.597,8.756,10.444,6.251,8.567,6.472,64,430,630,1282,5490,0.95,15.84,8.94,34600,1189,2.37,44,240,420,630,880,3147,1476,7.1,9.1,20,14800,14600,13500,9500,20300,51300,38600,39100,34100,19300,13500,9500,9200,9300,33500,33500  ],
    ['Jakarta',14.7,17.2,53.7,48.6,7.9,9.2,2111,11,62,100,27,348,500,13.104,6.034,9.777,4.813,5.133,5.357,47.4,369,330,673,4460,0.38,2.64,2.93,47800,717,0.76,19,160,320,190,390,2719,1087,2.3,2.6,7,2700,2600,2200,1300,10100,4400,17000,13000,10700,5300,4800,2700,1400,3200,15800,15800  ],
    ['Johannesburg',80.6,75.5,52.1,47.3,41.5,38.9,1886,15,26,9,10,94,490,4.688,7.09,11.504,7.125,4.27,4.999,46.1,310,400,738,3800,1.25,15.59,4.07,35500,56,1.45,28,100,320,310,400,2162,1295,9.8,13.5,21,17700,11900,21000,7500,41800,18000,51500,36800,74900,11900,22400,10500,15900,6600,65900,65900  ],
    ['Cairo',26.2,28.7,42.4,36.3,11,12.1,2331,15,67,8,19,290,420,4.198,10.952,11.704,16.244,11.703,11.068,35.4,300,300,168,4220,0.19,5.24,1.49,21500,50,0.15,27,100,200,380,430,1113,492,3,3.6,15,1600,1400,2700,2600,6600,48800,27700,20500,9600,3200,6200,5400,1900,3100,10900,10900  ],
    ['Kiev',19.5,20.9,53.1,46.9,10.5,11.2,1850,24,45,18,29,266,930,9.009,12.843,25.201,15.9,9.365,7.958,45.7,263,450,556,4140,0.25,12.15,4.56,24900,null,1.22,42,150,530,430,600,1631,854,2.8,3.4,16,3800,5300,6500,4900,7000,10500,11100,14100,6700,10000,4900,4100,4000,3900,13300,13300  ],
    ['Copenhagen',122,92.6,100.9,88.8,123.1,93.4,1674,29,15,9,6,36,1060,1.9,1.712,3.399,1.319,2.298,2.757,86.7,567,960,1100,5060,4.88,59.92,17.33,63400,641,1.99,72,270,490,950,1150,2616,1735,23.5,40.1,41,86500,56300,68400,58500,68900,61000,86400,94500,89200,72900,61900,46000,64800,42200,109200,109200  ],
    ['Kuala Lumpur',41.1,42,52.1,46.2,21.5,22,1986,15,25,20,21,129,500,3.609,2.027,5.4,0.6,1.7,3.2,45.1,346,400,777,4300,0.68,7.06,2.44,25700,91,0.59,58,120,220,230,540,1256,621,5.5,7,17,10400,8300,10700,6100,12800,17100,44000,31200,23600,11400,13200,7400,5100,7100,22700,22700  ],
    ['Lima',43.6,45.5,50.8,44.5,22.2,23.1,2107,27,21,20,15,162,600,2.004,1.78,5.788,2.936,1.53,3.369,43.4,303,410,492,4480,0.47,null,4.52,19300,95,1.36,36,130,400,310,470,1502,543,5.8,7.2,15,6300,6200,7600,5400,15900,12200,37300,40700,24300,10100,18900,6700,7600,8300,29400,29400  ],
    ['Lisbon',65.3,63.2,67.5,60.2,44,42.6,1695,22,22,13,8,96,720,3.043,2.423,2.646,-0.903,1.391,3.558,58.7,310,570,1100,5180,1.83,27.63,11.5,38100,181,1.95,45,80,390,390,510,1308,1178,10.7,14.3,22,32000,22500,19700,13900,25900,33700,33700,35200,36100,32400,17500,15000,18500,14900,63100,63100  ],
    ['Ljubljana',57.5,50.5,63.3,55.2,36.4,32,1792,22,25,22,38,101,550,2.458,3.611,5.7,0.855,1.834,1.828,53.8,368,490,479,4970,2.03,18.49,7.25,24600,140,1.71,32,140,240,560,1000,1774,1023,8.1,11.9,30,29000,15000,18100,13000,17900,28000,56600,41700,35000,23400,16300,12800,12400,17900,27800,27800  ],
    ['London',91.2,86.2,87.2,83,79.5,75.2,1786,22,15,6,13,42,930,2.3,2.346,3.629,2.12,3.339,4.454,81,436,770,1981,4910,3.7,81.95,23.03,28000,217,2.4,50,200,440,480,800,4830,3263,19,25.9,26,55700,44400,40900,39100,51700,36400,80300,75000,65900,46600,40700,26300,37600,27100,64200,64200  ],
    ['Los Angeles',113.8,106.7,75.8,68.7,86.3,80.9,1942,12,11,18,6,33,520,3.222,2.86,3.798,-0.321,1.641,3.142,67,501,580,1204,3590,1.5,34.33,25.06,30100,296,1.13,37,170,270,710,1240,2564,1877,20.4,28.1,28,55700,60200,42100,47000,62300,51900,58500,69200,90500,46100,44900,36500,47300,45700,80300,80300  ],
    ['Luxembourg',111.6,116.2,94.4,85.5,105.4,109.7,1788,25,11,9,9,30,970,2.667,2.313,3.383,0.37,2.274,3.409,83.4,524,860,1813,4660,1.94,54.13,19.43,31100,118,1.6,81,140,380,960,1440,2305,1839,27.6,34.3,18,113300,93900,38900,28500,45300,35500,126300,58300,66800,93900,45700,28500,29900,37200,89400,89400  ],
    ['Lyon',81.8,82.5,78.4,68.8,64.2,64.7,1641,26,16,10,12,52,740,1.912,1.607,3.159,0.103,1.735,2.294,67.2,477,700,945,4540,2.07,40.02,13.08,30400,null,1.84,29,180,310,740,1180,1554,1217,16.3,20.9,21,33900,30300,26200,24000,29400,43500,81500,70600,45100,55200,26000,22500,23800,24700,100900,100900  ],
    ['Madrid',83.6,85,69.7,61.7,57,57.9,1733,30,18,9,6,53,590,3.563,2.844,4.13,-0.238,2.043,3.052,60.2,432,630,1049,4940,1.94,44.51,13.27,21600,177,1.76,53,170,240,580,910,1295,1061,14.6,18.5,19,29100,25400,20500,23200,27300,35900,39200,63500,52700,53500,26900,17500,19900,25600,65100,65100  ],
    ['Milan',88.2,77.2,79.7,72.2,70.3,61.5,1753,23,17,14,15,55,770,2.217,2.038,3.5,0.764,1.639,2.903,70.4,487,710,1256,4790,1.94,34.75,16.84,18800,186,2.24,73,170,320,870,1170,2862,1813,15.5,22.9,31,32800,36500,29400,28900,36900,51000,68000,60400,57900,43500,32000,29700,28800,23200,58700,58700  ],
    ['Manama',56.4,71.9,54,49.5,30.5,38.8,1989,22,19,5,9,72,530,2.041,3.252,3.533,2.786,1.969,1,48.3,278,510,906,3810,0.8,null,11.52,18600,93,0.93,18,170,350,390,510,2486,1282,9.8,9.9,1,19800,10900,9200,7600,24000,11500,57900,33700,34400,15000,15300,19900,11800,14400,27800,27800  ],
    ['Manila',19.2,19.5,41.5,35.9,8,8.1,2245,10,72,70,27,435,450,6.234,2.8,9.299,4.191,3.793,4.761,35,292,300,194,5630,0.34,5.58,2.88,17400,47,1.02,18,160,300,140,270,1437,505,2,2.6,19,3400,2500,2700,2700,3600,8900,10900,18800,9800,3500,4000,3100,2800,4800,9700,9700  ],
    ['Mexico City',26.8,29.5,51.2,45.7,13.7,15.1,2375,6,48,25,21,220,440,3.629,3.967,5.125,5.297,4.155,3.403,44.6,259,480,738,4580,0.37,null,3.6,19400,28,0.81,31,130,210,620,930,1398,984,3.8,4.5,12,8400,3800,3400,3100,4800,19800,21400,21200,13600,7100,15500,4800,3100,14600,28000,28000  ],
    ['Miami',106.2,103.7,77,70.7,81.8,79.9,1938,12,12,12,5,32,560,3.222,2.86,3.798,-0.321,1.641,3.142,69,499,610,1515,3580,1.83,27.33,15.32,32400,98,1.05,28,160,330,630,880,2693,1929,20.1,26.6,23,63500,30600,40700,40100,56500,46200,70300,101500,95100,49300,46800,30700,33800,37700,71500,71500  ],
    ['Montreal',93.1,80.9,81.8,73.7,76.2,66.2,1782,13,19,14,14,44,720,2.018,2.123,2.385,0.3,1.776,2.891,71.9,519,690,1359,4610,2.87,70.58,12.98,21400,308,1.33,51,210,310,630,1100,2266,1735,16.7,24.8,32,56700,48700,43800,27600,42500,44600,59700,65100,61600,58900,40800,25900,35400,32000,56300,56300  ],
    ['Moscow',45.1,50.3,66.2,61.3,30.4,33.8,1799,25,18,7,12,119,970,9.679,9.007,14.117,11.654,6.854,8.443,59.8,314,690,854,5050,0.85,10.56,13.24,21200,73,0.93,73,200,530,820,1040,3639,2784,8.5,9.9,14,11900,18600,15800,13000,15900,30300,28800,29500,25500,19200,16800,12200,11800,10000,46100,46100  ],
    ['Mumbai',24.9,27.3,34.1,31,8.5,9.3,2251,19,56,29,30,338,400,6.177,6.372,8.349,10.882,11.99,8.628,30.3,186,210,453,3610,0.13,2.72,1.76,11500,754,0.91,24,160,280,320,540,1683,802,2.3,2.8,11,3800,3200,2100,1300,3100,7900,19800,21100,15400,7500,3800,2700,1400,4800,18000,18000  ],
    ['Munich',108.3,89.9,84.6,75.1,91.5,76,1755,25,14,11,11,42,800,1.784,2.276,2.754,0.234,1.15,2.483,73.3,500,720,971,4770,3.24,71.23,18.04,38400,150,1.86,53,170,340,830,1130,2499,1813,19.2,29.8,34,51200,40000,37300,28000,51700,55200,105900,115500,79500,78200,50100,40300,31500,30200,105900,105900  ],
    ['Nairobi',21.4,21,48.6,43.7,10.4,10.2,2196,21,83,27,41,292,490,14.455,9.76,13.1,10.552,4.087,13.998,42.7,291,370,479,4340,0.54,35.8,7.16,23900,null,1.29,22,170,220,220,280,2382,1230,2.6,3.4,24,4000,3100,2600,2500,4400,16200,11400,16600,21000,7500,4300,3400,2800,4900,14200,14200  ],
    ['New York',100,100,100,100,100,100,2061,13,9,12,5,28,1180,3.222,2.86,3.798,-0.321,1.641,3.142,97.6,552,1000,3354,3960,2.42,85.98,8.5,20500,100,1.15,71,340,730,570,980,7239,4299,25.2,32.6,22,62900,57200,50000,69300,79100,85500,118200,119300,107400,68400,54800,42300,41300,41700,120600,120600  ],
    ['Nicosia',95,107.1,64,57,60.8,68.5,1778,22,11,7,8,54,490,2.245,2.165,4.377,0.174,2.564,3.486,55.6,370,490,919,5480,1.45,null,9.31,24600,74,1.67,33,140,270,630,950,1877,932,17.3,19.8,11,47400,24700,28600,24500,33800,52700,62800,48600,47700,45600,23100,20200,21000,21100,107000,107000  ],
    ['Oslo',102.7,83.9,116,104.5,119.1,97.4,1749,25,17,11,12,36,1000,2.332,0.729,3.766,2.166,2.4,1.301,102,599,1270,1968,5190,5.12,48.58,23.22,42700,574,2.37,98,220,410,840,1450,3250,2214,24.5,38.8,36,61500,60000,78200,62800,72900,59800,123400,97400,79400,69300,64900,49500,63700,46500,144000,144000  ],
    ['Paris',94.8,89.4,83.9,77.5,78.1,73.6,1557,29,15,14,12,44,1100,1.912,1.607,3.159,0.103,1.735,2.294,75.6,522,770,1670,5030,2.16,42.87,9.39,33700,486,1.89,64,210,600,1020,1410,3250,2279,18.5,25.4,26,38700,32800,28000,25900,32100,36800,71000,71100,67100,80700,34700,25300,25400,25600,86900,86900  ],
    ['Prague',45.1,46.2,54.3,48,24.5,25.1,1829,20,34,13,16,132,740,2.543,2.862,6.339,1.034,1.464,1.929,46.8,295,460,725,4900,1.37,12.78,8.05,27800,116,1.88,58,120,350,250,530,1230,867,6.3,8,20,13200,16200,13600,9100,15400,17100,24500,25300,21000,20100,13200,12200,9200,10500,21900,21900  ],
    ['Riga',44.3,39.2,54.5,47.2,24.2,21.4,1806,23,33,22,22,168,580,6.571,10.083,15.252,3.259,-1.224,4.223,46,316,410,466,4460,1.3,9.23,6.34,29700,98,1.76,36,110,300,440,570,932,841,5.4,7.9,31,8400,13700,15500,14600,15300,14400,23300,30400,16800,18900,13700,8700,9300,13000,33000,33000  ],
    ['Rio de Janeiro',44.4,45,61.2,55.5,27.2,27.5,1895,30,45,33,11,160,710,4.196,3.638,5.672,4.888,5.039,6.636,54.2,354,530,764,5400,1.56,null,7.29,16100,357,0.88,23,180,440,230,350,3198,1320,6.9,8.9,15,13700,9800,14200,8400,18600,21200,62400,33300,37600,10900,10700,6600,6300,7500,70300,70300  ],
    ['Rome',69.6,60.9,79.2,73.8,55.1,48.2,1898,22,23,17,19,70,650,2.217,2.038,3.5,0.764,1.639,2.903,72,497,690,1813,5190,1.94,36.69,9.38,32400,389,2.27,36,210,320,910,830,3237,2033,12.1,17.9,31,30600,33500,22900,19600,31300,74200,53100,65100,28000,34300,34800,22100,16200,13200,94500,94500  ],
    ['Santiago de Chile',42.8,40.6,52.9,47.6,22.6,21.5,2034,15,55,22,21,157,630,3.392,4.408,8.716,1.485,1.408,3.34,46.5,348,460,673,4520,1.17,6.49,7.13,13400,255,1.24,33,140,410,360,610,2344,1023,5.4,7.4,23,15400,10000,11000,6700,12200,12300,28100,36700,32000,21600,11300,8200,7400,8500,30600,30600  ],
    ['São Paulo',48.7,49.4,61.7,56.1,30.1,30.5,1809,30,39,27,7,106,770,4.196,3.638,5.672,4.888,5.039,6.636,54.8,379,540,854,5010,1.53,null,6.83,23700,485,1.28,42,310,470,300,600,2810,1580,7.7,9.8,16,10600,11300,12600,6600,12600,25500,61900,67000,28200,14600,13900,8700,7100,4800,19900,19900  ],
    ['Seoul',80.8,74,67.9,66.3,54.8,50.2,2308,13,16,14,10,56,780,1.467,4.767,5.852,2.757,2.938,4.026,64.7,629,590,2175,4790,0.9,17.54,3.4,26000,523,1.56,60,240,510,220,300,3444,2641,12.7,17.9,23,65400,43600,11700,13000,41600,69800,65400,52300,82900,34100,27100,21800,10500,24500,130800,130800  ],
    ['Shanghai',37.2,38.4,56.2,49.7,20.9,21.6,1966,9,28,43,8,142,740,2.242,2.535,4.674,-0.683,3.325,5.417,48.5,404,470,712,3950,0.58,10.4,3.64,29400,76,1.26,64,250,440,400,960,1424,919,5.4,6.8,17,12200,8500,9300,6700,9200,23700,39600,24700,15800,28900,12300,7400,8200,7600,53200,53200  ],
    ['Singapore',50.8,53.3,94.9,89.2,48.2,50.7,2036,14,18,21,12,58,920,0.973,2.096,6.514,0.589,2.824,5.247,87.1,589,710,1994,5120,1.36,null,8.65,124900,966,1.25,88,180,410,530,840,4455,3496,12.8,15.7,18,41200,21900,19600,15000,27200,30400,82600,77600,46800,27800,28200,19000,14500,21600,86800,86800  ],
    ['Sofia',32.6,32.1,42.4,36.5,13.8,13.6,1894,22,36,20,28,248,420,7.417,7.571,11.95,2.473,3.036,3.389,35.6,265,290,336,3890,0.66,6.62,2,27700,119,1.71,19,80,220,270,430,764,453,3.4,4.5,23,4700,7300,7500,5700,9300,11100,18100,15200,10400,10700,7400,7400,5100,10900,21900,21900  ],
    ['Stockholm',90.2,84.9,92,81.7,82.9,78.1,1795,26,17,18,11,45,810,1.498,1.677,3.298,1.989,1.907,1.366,79.7,553,900,1178,4950,4.52,41.6,24.64,36400,334,2.14,68,180,340,760,1240,2525,1826,19.7,27,26,46600,41300,41300,43300,44300,45800,88800,81900,72500,48600,41100,37400,37900,41700,83400,83400  ],
    ['Sydney',112.5,117.1,83.7,77.8,94.1,98,1846,15,11,9,6,32,690,3.538,2.332,4.353,1.82,2.845,3.389,75.9,508,680,1644,5210,3.43,39.35,9.75,22200,245,1.5,45,220,350,580,820,4183,2175,24.7,30.6,18,57400,41800,39400,39200,72000,55900,111000,93400,79000,52200,50500,40400,50500,36800,110300,110300  ],
    ['Taipei',52,61.4,63.9,58,33.3,39.3,2115,11,15,9,11,79,650,0.598,1.798,3.527,-0.872,0.963,1.422,56.6,448,490,945,4290,0.68,11.25,4.37,22400,382,1.1,63,120,350,980,1070,2434,1696,9.9,10.8,8,25600,23200,16700,20100,24700,41300,61500,44400,29300,20600,15500,12700,11000,14800,36000,36000  ],
    ['Tallinn',47.9,48.6,58.3,50.2,28,28.3,1760,28,27,20,15,139,490,4.43,6.598,10.366,-0.085,2.894,5.121,49,333,380,453,5200,1.81,9.79,5.86,21200,null,1.72,34,160,250,610,830,984,712,7.1,9.1,20,11400,17700,21200,10600,17900,21400,51000,16600,27300,11800,14200,8800,10600,13600,27800,27800  ],
    ['Tel Aviv',57,57.6,75.4,68.5,43,43.5,1966,16,17,9,20,100,600,2.107,0.516,4.745,3.342,2.686,3.45,66.8,476,620,1282,5740,1.72,16.95,13.57,33900,413,2.14,40,180,360,440,580,2577,1709,11,14,18,20700,26500,18900,21000,25000,33500,48700,56200,56200,28900,18400,12300,15200,19700,83000,83000  ],
    ['Tokyo',84.7,82.9,109,100.1,92.4,90.4,2012,16,8,14,15,35,1190,0.3,0,1.396,-1.347,-0.72,-0.283,97.7,927,940,1631,4820,2.46,44.72,21.42,26300,495,1.62,72,370,730,1220,1880,6177,2486,22.8,30.1,24,78200,56300,54000,47000,77700,70200,89400,102100,77200,79400,48800,35100,48000,44700,144000,144000  ],
    ['Toronto',103.4,92.4,74.3,67.2,76.8,68.6,1847,14,10,11,9,38,680,2.018,2.123,2.385,0.3,1.776,2.891,65.6,453,750,1087,4520,3.08,35.62,13.31,15000,75,1.25,71,150,340,310,840,2564,2020,17.3,25,27,82900,36700,33300,46200,44300,53000,66600,47300,84800,32900,26300,28000,29700,37400,74600,74600  ],
    ['Vilnius',42.6,41.6,50.9,43.6,21.7,21.2,1789,24,32,19,33,168,410,3.788,5.772,11.138,4.164,1.19,4.124,42.5,284,360,323,4770,0.94,13.73,4.63,23700,null,1.72,22,90,220,480,510,984,492,5.3,7.1,23,10500,12200,13900,9800,17700,21900,23600,18500,16600,20200,10400,6500,8500,8000,38500,38500  ],
    ['Warsaw',44.3,40.8,53.7,48,23.8,21.9,1792,23,35,13,24,141,650,1.033,2.493,4.215,3.45,2.514,4.268,46.8,291,420,712,4410,0.79,14.62,3.15,25000,55,1.76,31,110,280,580,950,1618,1204,5.5,7.7,28,11900,10000,12600,9700,13200,20500,20900,27600,17900,11900,11700,11000,8400,7600,24900,24900  ],
    ['Vienna',100.6,88.8,81.3,72.1,80.2,70.8,1786,25,13,8,8,46,830,1.686,2.203,3.223,0.401,1.69,3.6,70.3,503,680,945,5560,2.59,42.03,17.27,29800,453,1.8,47,140,360,980,1040,2486,1424,17.8,26.1,29,44700,42900,34100,29500,56200,49000,96100,82900,69800,49100,49900,30000,25400,32600,72500,72500  ],
    ['Zurich',119.1,120.3,110,102.5,131.1,132.4,1887,23,12,5,5,22,1250,1.047,0.732,2.43,-0.476,0.685,0.228,100,704,1130,2551,5130,4.66,68.47,28.93,45200,426,2.01,90,280,630,1100,1190,4481,2499,33.4,42.7,21,104600,90700,68900,61800,79800,69900,137200,130000,115700,96900,71100,61400,53200,58900,140400,140400  ]

];
