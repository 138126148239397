import React, {
	useState,
	useEffect,
	useCallback,
	useRef,
	useReducer,
} from "react";
import "antd/dist/antd.css";
import { useDispatch } from "react-redux";
import "./styles.scss";
import { FilterType, WorkspaceData } from "./types";
import ClusterPage from "./ClusterPage";
import { WorkspaceList } from "./workspaceList";
import { errorAlert,infoAlert, successAlert } from "@components/toastify/notify-toast";
import { WorkSpaceHandler } from "@api/workSpace-handler";
import { DeleteWorkSpaceModal } from "./confirm";
import { WorkSpaceModal } from "./WorkSpaceModal";
import { InPageSpinner } from "@components/spinners/in-page-spinner";
import { ShowWhenTrue } from "@helpers/showwhentrue";
import { getAllClusters, setWorkspaceList } from "@store/cluster";
import { useSelector } from "react-redux";
import { RootState } from "@store/types";
import { completedCMCluster } from "@store/coachMark";
import JoyRide, { ACTIONS, EVENTS, STATUS, Step } from "react-joyride";
import { CoachMark, tourStyle } from "@components/coachmark";
import { useAppSelector } from "@store/hooks";
import { InitHandler } from "@api/init";
import { CLUSTER_COUCHMARK_STATUS } from "@store/coachMark/constants";
import { useMountedRef } from "../../hooks/mount";
import {isEmpty} from 'lodash';
import { StorageConnectionData } from "@pages/file-browser/types";
import { Env } from "@constants/settings";

const TOUR_STEPS: Step[] = [
	{
		target: "#Btn_NewWorkspace",
		content: "Click here to create a new workspace.",
		disableBeacon: true
	},
	{
		target: ".mf_model",
		content: "Enter all details to create a workspace.",
		disableBeacon: true
	},
	{
		target: '.filter_div_container',
		content: "Filter different environment",
		disableBeacon: true
	},
	{
		target: '.ant-list .ant-list-items',
		content: 'You can enable or disable different environment',
		disableBeacon: true
	},
	{
		target: ".newCluster",
		content: "Click here to create a new cluster.",
		disableBeacon: true
	},
	{
		target: ".addedit-cluster-Modal ",
		content: "Enter all details to create a cluster.",
		disableBeacon: true,
		floaterProps: {
			placement: "bottom"
		}
	},
	{
		target: ".componentdesign .cardbg:first-child",
		content: "Show the cluster details",
		disableBeacon: true
	},
	{
		target: ".componentdesign .cardbg:first-child .status-badge",
		content: "Show the status of the cluster",
		disableBeacon: true
	},
	
];

const INITIAL_STATE = {
	key: new Date(), // This field makes the tour to re-render when we restart the tour
	run: false,
	continuous: true, // Show next button
	loading: false,
	stepIndex: 0, // Make the component controlled
	steps: TOUR_STEPS,
	hideBackButton: true,
	disableOverlayClose: true,
    spotlightPadding: 5,
	//hideCloseButton: false
};

// Reducer will manage updating the local state

const TIMER_GET_WORKSPACES = 15000;

export const Cluster = () => {
	const mountedRef = useMountedRef();
	const dispatch1 = useDispatch();
    const isAdmin = useAppSelector((state) => state.AccountReducer.activeUserInfo.id ? state.AccountReducer.activeUserInfo.isAdmin : true);
	const [workSpaceData, setWorkSpaceData] = useState<WorkspaceData[]>([]);
	const [showWorkSpaceModal, setShowWorkSpaceModal] = useState(false);
	const [showDeletePopup, toggleDeletePopup] = useState(false);
	const [isNoWorkSpaceActive, setIsNoWorkSpaceActive] = useState(false);
	const [isMFRunning, setMFRunning] = useState(false);
	const enabledWorkSpace =  workSpaceData.filter((item)=>item.isEnabled)[0];
	const reducer = (state = INITIAL_STATE, action: any) => {
		if(!isMFRunning) return;
		switch (action.type) {
			// start the tour
			case "START":
				return { ...state, run: true };
			case "OPEN":
				return { ...state, run: true, stepIndex: action.value };
			// Reset to 0th step
			case "RESET":
				return { ...state, stepIndex: 0 };
			// Stop the tour
			case "STOP":
				return { ...state, run: false };
			// Update the steps for next / back button click
			case "NEXT_OR_PREV":
				return { ...state, ...action.payload };
			// Restart the tour - reset go to 1st step, restart create new tour
			case "RESTART":
				return {
					...state,
					stepIndex: 0,
					run: true,
					loading: false,
					key: new Date(),
				};
			default:
				return state;
		}
	};

	const { 
       start_cluster_mf,
	   cluster_status,
	   file_browser_status
    } = useSelector((store: RootState) => store.CoachMarkReducer);
	const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

	const reset = () => {
		dispatch1(completedCMCluster(0));
		setMFRunning(true);
		setTimeout(() =>{
			dispatch({ type: "RESTART" });
		}, 10);
	};

	const [
		workSpaceDataModal,
		setWorkSpaceDataModal,
	] = useState<WorkspaceData | null>(null);
	const [
		selectedDelActionItem,
		setSelectedDelActionItem,
	] = useState<WorkspaceData | null>(null);

	const onHandleDeleteWorkSpace = (data: WorkspaceData) => {
		setSelectedDelActionItem(data);
		toggleDeletePopup(true);
	};
	const [isLoading, setLoadingState] = useState(false);

	useEffect(() => {
		if (mountedRef.current && start_cluster_mf !== 0) {
			setMFRunning(true);
			setTimeout(()=>{
				dispatch({ type: "RESTART" });
			}, 10);
		}
	}, [start_cluster_mf]);

	const onEnableWorkSpace = (item: WorkspaceData) => {
		const obj = {
			id: item.workspaceId,
			defaultWorkspace: !item.isEnabled,
		};
		if(isMFRunning) {
			dispatch({
				type: "STOP",
				payload: {}
			});
		}
		setTimeout(() => {
			setLoadingState(true);
			if (item && item.workspaceId) {
				WorkSpaceHandler.enableWorkSpace(
					obj,
					(res) => {
						getAllWorkSPaces(item);
						dispatch1(getAllClusters());
						successAlert(res.message);
					},
					(e: any) => {
						setLoadingState(false);
						errorAlert(e.data.message);
					}
				);
			}
		}, 400);
	};

	const onResetWorkSpace = (item: WorkspaceData) => {
		if(isMFRunning) {
			dispatch({
				type: "STOP",
				payload: {}
			});
		}
		setTimeout(() => {
			setLoadingState(true);
			if (item && item.workspaceId) {
				const obj = {...item,reset:true};
				WorkSpaceHandler.UpdateWorkSpace(
					obj,
					(res) => {
						getAllWorkSPaces(item);
						dispatch1(getAllClusters());
						infoAlert(res.message);
					},
					(e: any) => {
						setLoadingState(false);
						errorAlert(e.data.message);
					}
				);
			}
		}, 400);
	};

	const getAllWorkSPaces = React.useCallback((item?: WorkspaceData, delay?: boolean) => {
		if(delay) {
			dispatch({
				type: "STOP"
			});
		}
		WorkSpaceHandler.GetWorkSpaceList((res) => {
			dispatch1(setWorkspaceList(res));
			setWorkSpaceData(res);
			const workSpaceConnection = res.filter((item) => item.isEnabled);
			const hasEnabled = workSpaceConnection.length === 0 && res.length > 0;
			setIsNoWorkSpaceActive(hasEnabled);
			setLoadingState(false);
			if(isMFRunning && ((item && !item.isEnabled) || (delay && workSpaceConnection.length !== 0)) ) {
				setTimeout(() => {
					dispatch({
						type: "OPEN",
						value: 4
					});
				}, 300);
			}
		});
	}, [isMFRunning]);

	useEffect(() => {
		getAllWorkSPaces();
		setLoadingState(true);
			let timer: NodeJS.Timeout | null = setInterval( () =>{
				getAllWorkSPaces();
			}, TIMER_GET_WORKSPACES);
			return () => {
				timer && clearInterval(timer);
				timer = null;
			};
	}, []);

	const onDelete = () => {
		setLoadingState(true);
		toggleDeletePopup(false);
		if (selectedDelActionItem && selectedDelActionItem.workspaceId) {
			WorkSpaceHandler.DeleteWorkSpace(
				selectedDelActionItem.workspaceId,
				(res) => {
					getAllWorkSPaces();
					toggleDeletePopup(false);
					successAlert(res.message);
				},
				(e: any) => {
					setLoadingState(false);
					errorAlert(e.data.message);
				}
			);
		}
	};


	const closeWorkSpaceModal = useCallback(() => {
		setWorkSpaceDataModal(null);
		setShowWorkSpaceModal(false);
		dispatch({ type: "OPEN", value: 2 });
	}, []);

	const callback = (data: any) => {
		const { action, index, type, status } = data;
		if(status === STATUS.FINISHED) {
			InitHandler.SetStateForCounchMark({
				name: 'init_setup',
				data: "CLUSTER_COMPLETE," + (file_browser_status === "COMPLETE" ? "FILE_BROWSER_COMPLETE" : "")
			}, ()=> {
				setMFRunning(false);
				dispatch1({
					type: CLUSTER_COUCHMARK_STATUS,
					payload: {
						cluster_status: "COMPLETE"
					}
				})
			})
		} else
		if (
			// If close button clicked then close the tour
			action === ACTIONS.CLOSE ||
			// If skipped or end tour, then close the tour
			(status === STATUS.SKIPPED && tourState.run)
		) {
			dispatch({ type: "STOP" });
			setMFRunning(false);
		// } else if (type === EVENTS.TARGET_NOT_FOUND) {
		} else if (type === EVENTS.STEP_AFTER) {
			// Check whether next or back button click and update the step
			if(index === 1 || index === 5) {
				dispatch({
					type: "STOP",
					payload: {}
				});
				return;
			}
			dispatch({
				type: "NEXT_OR_PREV",
				payload: {
					stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
				},
			});
		}
	};

	useEffect(() => {
		// Auto start the tour if the tour is not viewed before
		if(isAdmin && (cluster_status !== "COMPLETE" && !isEmpty(cluster_status))) {
			setMFRunning(true);
			setTimeout(()=>{
				dispatch({ type: "START" });
			}, 10);
		}
	}, []);

	return (
		<>
			<ShowWhenTrue show={Env.enableDataStudioWalkthrough}>
				<JoyRide
					{...tourState}
					callback={callback}
					styles={tourStyle}
					locale={{
						last: 'Done'
					}}
				/>
			</ShowWhenTrue>
			<section className="compute__container">
				<div className="left_container">
					<div>
						<div className="page_left_title">Compute</div>
						<div className="subtitle">Workspaces</div>
					</div>
					<div className="newBtn__box">
						<button
							id="Btn_NewWorkspace"
							className="btn-sm btn-yellow-transparent btn__new__workflow btn__new__action"
							onClick={() => {
								setShowWorkSpaceModal(true);
								setTimeout(() => {
									dispatch({ type: "OPEN", value: 1 });
								}, 100);
							}}
						>
							<img src="/icons/workflow/add.svg" alt="" />
							<span>New Workspace</span>
						</button>
					</div>
					<WorkspaceList
						workSpace={workSpaceData}
						setWorkSpaceModal={setShowWorkSpaceModal}
						setWorkSpaceDataModal={setWorkSpaceDataModal}
						handleDeleteWorkSpace={onHandleDeleteWorkSpace}
						handleEnableWorkSpace={onEnableWorkSpace}
						handleResetWorkSpace={onResetWorkSpace}
						isLoading={isLoading}
					/>
				</div>
				<div className="right_container">
					{isLoading ? (
						<div className="in_center">
							<InPageSpinner />
						</div>
					) : (
						<>
							<ShowWhenTrue show={workSpaceData.length === 0}>
								<div className="create-new-connection-text">
									<button
										onClick={() => {
											setShowWorkSpaceModal(true);
											setTimeout(() => {
												dispatch({
													type: "OPEN",
													value: 1,
												});
											});
										}}
									>
										Create a New WorkSpace
									</button>
								</div>
							</ShowWhenTrue>

							<ShowWhenTrue show={workSpaceData.length > 0}>
								<ShowWhenTrue show={isNoWorkSpaceActive}>
									<div className="no-active-connection-text">
										No Active WorkSpace Found
									</div>
								</ShowWhenTrue>
								<ShowWhenTrue show={!isNoWorkSpaceActive}>
									<div className={"clusterBoard"}>
										<ClusterPage
											enabledWorkSpace={enabledWorkSpace}
											dispatchMC={dispatch}
										/>
									</div>
								</ShowWhenTrue>
							</ShowWhenTrue>
						</>
					)}
					<DeleteWorkSpaceModal
						onConfirm={onDelete}
						showModal={showDeletePopup}
						toggleClose={() => {
							toggleDeletePopup(false);
						}}
						currentWorkSpace={selectedDelActionItem}
					/>

					<WorkSpaceModal
						showModal={showWorkSpaceModal}
						toggleClose={closeWorkSpaceModal}
						getWorkspaceData={getAllWorkSPaces}
						workspaceData={workSpaceDataModal}
					/>
				</div>
			</section>
			<CoachMark reset={reset} />
		</>
	);
};

export default Cluster;
