import _ from 'lodash';
import { CronReducerState, ActionTypes } from './types';

const initialState: CronReducerState = {
    cron: {
        description: '', 
        executionDates: []
    }
};


export const CronExpressionReducer = (state = initialState, action: ActionTypes): CronReducerState => {
    const new_state = _.cloneDeep(state);

    switch(action.type){
        case 'GET_CRON':
            _.setWith(new_state, ['cron'], action.payload);
            return new_state;
        default:
            return state;
    }
};