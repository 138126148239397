import { GET_CLUSTERS, UPDATE_CLUSTER_STATUS, UPDATE_CLUSTER_DEFAULT, CREATED_BY_ME, DELETE_CLUSTER, ADD_CLUSTER, GET_NODE_TYPE_LIST, GET_CLUSTER_MODE,GET_ACCESS_NODE,GET_USERS_LIST, GET_DATABRICKS_VERSION_LIST, SET_WORKSPACE_LIST, SET_ROUTE_OPTIMIZATION_LIST } from './constants';
import { AddClusterActionPayload, ClusterActionTypes, ClusterReducerState, DeleteClusterActionPayload, GetClustersActionPayload, GetCusterModeListActionPayload, GetAccessNodeListActionPayload, GetUsersListActionPayload, GetDatabricksVersionListActionPayload, GetNodeTypeListActionPayload, UpdateClusterDefaultActionPayload, UpdateClusterStatusActionPayload } from './types';
import { AccessNodeType, UsersType, Cluster, ClusterModeType, DataBricksVersionListType } from '../../api/databricks-handler/types';

export const clusterReducerInitialState: ClusterReducerState = {
    clusters: [],
    defaultClusterInfo: null,
    createdByMe: false,
    metadata:{
        nodeTypeList: [],
        clusterMode: [],
        dataSecurityMode: [],
        singleUserName: [],
        dataBricksVersionList: []
    },
    workspaceList:[],
    routeOptimizationList:[]
};

export const ClusterReducer = (state: ClusterReducerState = clusterReducerInitialState, action: ClusterActionTypes): ClusterReducerState => {
    switch (action.type) {
        case GET_CLUSTERS: {
            const getClustersActionPayload = action.payload as GetClustersActionPayload;
            const defaultClusterInfo = getClustersActionPayload.clusters.find(cluster => cluster.defaultCluster) || null;
            return { ...state, clusters: getClustersActionPayload.clusters, defaultClusterInfo };
        }
        case UPDATE_CLUSTER_STATUS: {
            const updateClusterStatusPayload = action.payload as UpdateClusterStatusActionPayload;
            const idx = state.clusters.findIndex((cluster: Cluster) => cluster.clusterId === updateClusterStatusPayload.clusterId);
            const newClusterList = [...state.clusters];
            if (idx >= 0) {
                newClusterList[idx].state = updateClusterStatusPayload.newState;
            }
            return { ...state, clusters: newClusterList };

        }
        case UPDATE_CLUSTER_DEFAULT: {
            const updateClusterDefaultPayload = action.payload as UpdateClusterDefaultActionPayload;
            const idx = state.clusters.findIndex((cluster: Cluster) => cluster.clusterId === updateClusterDefaultPayload.clusterId);
            const newClusterList = [...state.clusters];
            let defaultClusterInfo: ClusterReducerState['defaultClusterInfo'] = null;
            if (idx >= 0) {
                if (updateClusterDefaultPayload.defaultCluster) {
                    // Find a cluster that is currently default and set that to false
                    const currentDefaultCluster = newClusterList.find( (cluster) => cluster.defaultCluster);
                    if (currentDefaultCluster) {
                        currentDefaultCluster.defaultCluster = false;
                    }
                }
                // Now we can update the defaultCluster on the cluster                
                newClusterList[idx].defaultCluster = updateClusterDefaultPayload.defaultCluster;
                // Update defaultClusterinfo if defaultCluster is true
                if(updateClusterDefaultPayload.defaultCluster) {
                    defaultClusterInfo = newClusterList[idx];
                }
            }
            return { ...state, clusters: newClusterList, defaultClusterInfo };
        }
        case DELETE_CLUSTER: {
            const deleteClusterPayload = action.payload as DeleteClusterActionPayload;
            const newClusterList = [...state.clusters].filter((cluster: Cluster) => cluster.clusterId !== deleteClusterPayload.clusterId);
            return { ...state, clusters: newClusterList };
        }
        case ADD_CLUSTER: {
            const addClusterPayload = action.payload as AddClusterActionPayload;
            return { ...state, clusters: [...state.clusters, addClusterPayload.cluster] };
        }
        case CREATED_BY_ME: {
            const createdByMe = state.createdByMe;
            return { ...state, createdByMe: !createdByMe };
        }
        case GET_NODE_TYPE_LIST: {
            const getNodeTypeListActionPayload = action.payload as GetNodeTypeListActionPayload;
            return { ...state, 
                metadata: { 
                    ...state.metadata, 
                    nodeTypeList: getNodeTypeListActionPayload.nodeTypeList, 
                }
            };
        }
        case GET_CLUSTER_MODE: {
            const getCusterModeListActionPayload = action.payload as GetCusterModeListActionPayload;
            return { ...state, 
                metadata: { 
                    ...state.metadata, 
                    clusterMode: getCusterModeListActionPayload.custerModeList.map((custerMode: ClusterModeType )=>{
                        return {
                            label: custerMode.name,
                            value: custerMode.id + ''
                        };
                    })
                }
            };
        }
        case GET_ACCESS_NODE: {
            const getAccessNodeListActionPayload = action.payload as GetAccessNodeListActionPayload;
            return { ...state, 
                metadata: { 
                    ...state.metadata, 
                    dataSecurityMode: getAccessNodeListActionPayload.accessNodeList.map((dataSecurityMode: AccessNodeType )=>{
                        return {
                            label: dataSecurityMode.label,
                            value: dataSecurityMode.value + '',
                            disabled: dataSecurityMode.disabled
                        };
                    })
                }
            };
        }
        case GET_USERS_LIST: {
            const getUsersListActionPayload = action.payload as GetUsersListActionPayload;
            return { ...state, 
                metadata: { 
                    ...state.metadata, 
                    singleUserName: getUsersListActionPayload.usersList.map((singleUserName: UsersType )=>{
                        return {
                            label: singleUserName.value,
                            value: singleUserName.value + ''
                        };
                    })
                }
            };
        }
        case GET_DATABRICKS_VERSION_LIST: {
            const getCusterModeListActionPayload = action.payload as GetDatabricksVersionListActionPayload;
            return { ...state, 
                metadata: { 
                    ...state.metadata, 
                    dataBricksVersionList: getCusterModeListActionPayload.databricksVersionList.map((custerMode: DataBricksVersionListType )=>{
                        return {
                            label: custerMode.name,
                            value: custerMode.key 
                        };
                    })
                }
            };
        }
        case SET_WORKSPACE_LIST:
            return {...state,workspaceList:action.payload }
        case SET_ROUTE_OPTIMIZATION_LIST:
                return {...state,routeOptimizationList:action.payload }
        default:
            return state;
    }
};
