import React from 'react';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import * as yup from 'yup';
import { isFunction } from 'util';

type _formikprops = { _formikprops: FormikProps<FormikValues> };

export interface FormProps {
    initialValues: object;
    validationSchema?: yup.ObjectSchema | yup.ArraySchema<Record<string, any>> | yup.ArraySchema<string>;
    onSubmit: (arg0: any, arg1: FormikHelpers<any>) => any;
    className?: string;
    render?: (props: FormikProps<object>) => React.ReactNode;
    enableReinitialize?: boolean;
    children?: (({ _formikprops }: _formikprops) => React.ReactNode) | React.ReactNode ;
    validateOnMount?: boolean;
    ref?: React.RefObject<HTMLFormElement>;
}


const Form: React.FC<FormProps> = ({ initialValues, validationSchema, onSubmit, className, render, enableReinitialize, validateOnMount = false, children, ref=null }): JSX.Element => {
    // Get types of values from the schema
    type initialValueTypes = yup.InferType<typeof validationSchema>
    
    return(
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={!!(enableReinitialize)}
            validateOnMount={validateOnMount}
            render={render}
        >
            {(_formikprops: FormikProps<initialValueTypes>) => {
                return(
                    <form 
                        className={className} 
                        onSubmit={_formikprops.handleSubmit}
                        ref={ref}
                    >
                        {/* 
                        // @ts-ignore */}
                        {isFunction(children) ? children({ _formikprops }) : children }
                    </form>
                );
            }}
        </Formik>
    );
};

export { Form };