import React from 'react';
import classNames from 'classnames';

export const Chevron: React.FC<{active: boolean}> = ({ active }) => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
        <defs>
            <polygon
                id="chevron-path"
                points="9.67712551 6 8.25 7.41 12.8856275 12 8.25 16.59 9.67712551 18 15.75 12"
            />
        </defs>
        <g
            id="Symbols"
            stroke="none"
            strokeWidth={1}
            fill="none"
            fillRule="evenodd"
        >
            <g id="Workfllow/action/Chevron/Down">
                <mask id="chevron-mask" fill="white">
                    <use xlinkHref="#chevron-path" />
                </mask>
                <g
                    id="Mask"
                    transform="translate(12.000000, 12.000000) rotate(90.000000) translate(-12.000000, -12.000000) "
                />
                <g
                    id="\u21B3-\uD83C\uDFA8Color/Neutral/200"
                    mask="url(#chevron-mask)"
                    className={classNames('chevron', {'chevron__active': active })}
                >
                    <rect id="Color-Container" x={0} y={0} width={24} height={24} />
                </g>
            </g>
        </g>
    </svg>
); 