import { NewDataSourceReducerState, TOGGLE_DATASOURCES_MODAL, ToggleDataBrowserModalType, ToggleColumnManagementType, TOGGLE_COLUMN_MANAGEMENT, TOGGLE_CONNECTIONS_TREEVIEW,TOGGLE_ADMINISTRATOR_TREEVIEW , SET_ALL_CONNECTIONS_INFO,SET_FILE_BROWSER_CONNECTION, SET_ACTION_TYPE } from './types';
import { AppThunk } from '../types';
import { ConnectionInfo } from '../../api/data-source-handler';
import { StorageConnectionData } from '@pages/file-browser/types';

export const toggleDataBrowserModal = (modalType: keyof NewDataSourceReducerState['showModal'], action: boolean): ToggleDataBrowserModalType =>
    ({ type: TOGGLE_DATASOURCES_MODAL, payload: {[modalType]: action } }) as any;

export const toggleColumnManagement = (action?: boolean): AppThunk => (dispatch, getState): ToggleColumnManagementType => {
    const { isColumnManagementMinimized } = getState().NewDataSourceReducer;
    if(action === undefined) action = !isColumnManagementMinimized;
    return dispatch({ type: TOGGLE_COLUMN_MANAGEMENT, payload: action });
};

export const setAllConnectionsInfo = (payload: ConnectionInfo[]) => 
    ({ type: SET_ALL_CONNECTIONS_INFO, payload });

export const setActionType = (payload: string) => 
    ({ type: SET_ACTION_TYPE, payload });

export const toggleConnectionsTreeview = () => ({ type: TOGGLE_CONNECTIONS_TREEVIEW });

export const  toggleAdministratorTreeview = () =>({type:TOGGLE_ADMINISTRATOR_TREEVIEW});

export const setFileBrowserConnectionList = (payload: StorageConnectionData[]) =>
    ({ type: SET_FILE_BROWSER_CONNECTION, payload });