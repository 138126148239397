import React from "react";

type IconsType = {
	[key: string]: React.ComponentType;
};

export const Icons: IconsType = {
	folder: () => {
		return (
			<svg
				width="20"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M0.666626 4H13.3333C14.4379 4 15.3333 4.89543 15.3333 6V12.6667C15.3333 13.7712 14.4379 14.6667 13.3333 14.6667H2.66663C1.56206 14.6667 0.666626 13.7712 0.666626 12.6667V4Z"
					fill="#2193FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M0.666626 3.33337C0.666626 2.2288 1.56206 1.33337 2.66663 1.33337H5.7471C6.49851 1.33337 7.18651 1.75454 7.52827 2.42373L8.33329 4.00004H0.666626V3.33337Z"
					fill="#2F68FF"
				/>
			</svg>
		);
	},
	backFolder: () => {
		return (
			<svg
				width={20}
				height={16}
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.4163 5.47941L9.08301 2.81275C8.83967 2.61808 8.49367 2.61808 8.25034 2.81275L4.91701 5.47941C4.62901 5.70941 4.58301 6.12875 4.81301 6.41608C5.04234 6.70341 5.46234 6.75008 5.74967 6.52075L7.99967 4.72008V10.6667C7.99967 11.0341 7.70101 11.3334 7.33301 11.3334H3.99967C3.63167 11.3334 3.33301 11.6321 3.33301 12.0001C3.33301 12.3681 3.63167 12.6667 3.99967 12.6667H7.33301C8.43634 12.6667 9.33301 11.7694 9.33301 10.6667V4.72008L11.5837 6.52075C11.7063 6.61875 11.8537 6.66675 11.9997 6.66675C12.195 6.66675 12.389 6.58075 12.5203 6.41608C12.7503 6.12875 12.7043 5.70941 12.4163 5.47941Z"
					fill="#9495A0"
				/>
				<mask
					id="mask0_0_47872"
					style={{
						maskType: "alpha",
					}}
					maskUnits="userSpaceOnUse"
					x={3}
					y={2}
					width={10}
					height={11}
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M12.4163 5.47941L9.08301 2.81275C8.83967 2.61808 8.49367 2.61808 8.25034 2.81275L4.91701 5.47941C4.62901 5.70941 4.58301 6.12875 4.81301 6.41608C5.04234 6.70341 5.46234 6.75008 5.74967 6.52075L7.99967 4.72008V10.6667C7.99967 11.0341 7.70101 11.3334 7.33301 11.3334H3.99967C3.63167 11.3334 3.33301 11.6321 3.33301 12.0001C3.33301 12.3681 3.63167 12.6667 3.99967 12.6667H7.33301C8.43634 12.6667 9.33301 11.7694 9.33301 10.6667V4.72008L11.5837 6.52075C11.7063 6.61875 11.8537 6.66675 11.9997 6.66675C12.195 6.66675 12.389 6.58075 12.5203 6.41608C12.7503 6.12875 12.7043 5.70941 12.4163 5.47941Z"
						fill="white"
					/>
				</mask>
				<g mask="url(#mask0_0_47872)">
					<rect width={16} height={16} fill="#D4D6E0" />
				</g>
			</svg>
		);
	},
	unknown: () => {
		return (
			<svg
				width="20"
				height="20"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M17.5044 19.1724H6.46997V4H15.0217L17.5044 6.48279V19.1724Z"
					fill="#E3E4D8"
				/>
				<path
					d="M6.46997 15.0344H17.5044V20H6.46997V15.0344Z"
					fill="#B7B5B5"
				/>
				<path
					d="M15.0217 4V6.48279H17.5044L15.0217 4Z"
					fill="#D0CEBD"
				/>
				<path
					d="M10.3319 10.7661H8.125C7.97278 10.7661 7.84912 10.6426 7.84912 10.4904C7.84912 10.338 7.97278 10.2145 8.125 10.2145H10.3319C10.4843 10.2145 10.6078 10.338 10.6078 10.4904C10.6078 10.6426 10.4843 10.7661 10.3319 10.7661Z"
					fill="#A09893"
				/>
				<path
					d="M14.1941 10.7661H11.4355C11.2832 10.7661 11.1597 10.6426 11.1597 10.4904C11.1597 10.338 11.2832 10.2145 11.4355 10.2145H14.1941C14.3464 10.2145 14.47 10.338 14.47 10.4904C14.47 10.6426 14.3464 10.7661 14.1941 10.7661Z"
					fill="#A09893"
				/>
				<path
					d="M13.3666 11.8696H10.332C10.1798 11.8696 10.0562 11.746 10.0562 11.5938C10.0562 11.4415 10.1798 11.3179 10.332 11.3179H13.3666C13.5188 11.3179 13.6423 11.4415 13.6423 11.5938C13.6423 11.746 13.5188 11.8696 13.3666 11.8696Z"
					fill="#A09893"
				/>
				<path
					d="M15.849 10.7661H15.2974C15.145 10.7661 15.0215 10.6426 15.0215 10.4904C15.0215 10.338 15.145 10.2145 15.2974 10.2145H15.849C16.0013 10.2145 16.1249 10.338 16.1249 10.4904C16.1249 10.6426 16.0013 10.7661 15.849 10.7661Z"
					fill="#A09893"
				/>
				<path
					d="M15.849 11.8696H14.4697C14.3175 11.8696 14.1938 11.746 14.1938 11.5938C14.1938 11.4415 14.3175 11.3179 14.4697 11.3179H15.849C16.0013 11.3179 16.1249 11.4415 16.1249 11.5938C16.1249 11.746 16.0013 11.8696 15.849 11.8696Z"
					fill="#A09893"
				/>
				<path
					d="M9.22852 11.8696H8.125C7.97278 11.8696 7.84912 11.746 7.84912 11.5938C7.84912 11.4415 7.97278 11.3179 8.125 11.3179H9.22852C9.38074 11.3179 9.50427 11.4415 9.50427 11.5938C9.50427 11.746 9.38074 11.8696 9.22852 11.8696Z"
					fill="#A09893"
				/>
				<path
					d="M10.3319 12.973H8.125C7.97278 12.973 7.84912 12.8495 7.84912 12.6971C7.84912 12.5449 7.97278 12.4214 8.125 12.4214H10.3319C10.4843 12.4214 10.6078 12.5449 10.6078 12.6971C10.6078 12.8495 10.4843 12.973 10.3319 12.973Z"
					fill="#A09893"
				/>
				<path
					d="M14.1941 12.973H11.4355C11.2832 12.973 11.1597 12.8495 11.1597 12.6971C11.1597 12.5449 11.2832 12.4214 11.4355 12.4214H14.1941C14.3464 12.4214 14.47 12.5449 14.47 12.6971C14.47 12.8495 14.3464 12.973 14.1941 12.973Z"
					fill="#A09893"
				/>
				<path
					d="M15.849 12.973H15.2974C15.145 12.973 15.0215 12.8495 15.0215 12.6971C15.0215 12.5449 15.145 12.4214 15.2974 12.4214H15.849C16.0013 12.4214 16.1249 12.5449 16.1249 12.6971C16.1249 12.8495 16.0013 12.973 15.849 12.973Z"
					fill="#A09893"
				/>
				<path
					d="M15.8491 9.66272H13.6422C13.49 9.66272 13.3665 9.53918 13.3665 9.38684C13.3665 9.23462 13.49 9.11096 13.6422 9.11096H15.8491C16.0015 9.11096 16.125 9.23462 16.125 9.38684C16.125 9.53918 16.0015 9.66272 15.8491 9.66272Z"
					fill="#A09893"
				/>
				<path
					d="M12.5389 9.66272H9.78027C9.62805 9.66272 9.50439 9.53918 9.50439 9.38684C9.50439 9.23462 9.62805 9.11096 9.78027 9.11096H12.5389C12.6912 9.11096 12.8148 9.23462 12.8148 9.38684C12.8148 9.53918 12.6912 9.66272 12.5389 9.66272Z"
					fill="#A09893"
				/>
				<path
					d="M13.6423 8.55933H10.6079C10.4556 8.55933 10.332 8.43567 10.332 8.28345C10.332 8.1311 10.4556 8.00757 10.6079 8.00757H13.6423C13.7947 8.00757 13.9182 8.1311 13.9182 8.28345C13.9182 8.43567 13.7947 8.55933 13.6423 8.55933Z"
					fill="#A09893"
				/>
				<path
					d="M8.67676 9.66272H8.125C7.97278 9.66272 7.84912 9.53918 7.84912 9.38684C7.84912 9.23462 7.97278 9.11096 8.125 9.11096H8.67676C8.82898 9.11096 8.95264 9.23462 8.95264 9.38684C8.95264 9.53918 8.82898 9.66272 8.67676 9.66272Z"
					fill="#A09893"
				/>
				<path
					d="M9.50427 8.55933H8.125C7.97278 8.55933 7.84912 8.43567 7.84912 8.28345C7.84912 8.1311 7.97278 8.00757 8.125 8.00757H9.50427C9.65662 8.00757 9.78015 8.1311 9.78015 8.28345C9.78015 8.43567 9.65662 8.55933 9.50427 8.55933Z"
					fill="#A09893"
				/>
				<path
					d="M15.849 8.55933H14.7456C14.5933 8.55933 14.4697 8.43567 14.4697 8.28345C14.4697 8.1311 14.5933 8.00757 14.7456 8.00757H15.849C16.0013 8.00757 16.1249 8.1311 16.1249 8.28345C16.1249 8.43567 16.0013 8.55933 15.849 8.55933Z"
					fill="#A09893"
				/>
			</svg>
		);
	},
	csv: () => {
		return (
			<svg
				width="20"
				height="20"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M17.5044 19.1724H6.46997V4H15.0217L17.5044 6.48279V19.1724Z"
					fill="#E3E4D8"
				/>
				<path
					d="M15.0217 4V6.48279H17.5044L15.0217 4Z"
					fill="#D0CEBD"
				/>
				<path
					d="M6.46997 15.0344H17.5044V20H6.46997V15.0344Z"
					fill="#2F9171"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8.125 9.24133H10.056C10.2084 9.24133 10.3319 9.1178 10.3319 8.96558V7.86206C10.3319 7.70984 10.2084 7.58618 10.056 7.58618H8.125C7.97278 7.58618 7.84912 7.70984 7.84912 7.86206V8.96558C7.84912 9.1178 7.97278 9.24133 8.125 9.24133ZM9.78015 8.6897H8.40088V8.13794H9.78015V8.6897Z"
					fill="#A09893"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.0562 9.24133H11.9872C12.1395 9.24133 12.2631 9.1178 12.2631 8.96558V7.86206C12.2631 7.70984 12.1395 7.58618 11.9872 7.58618H10.0562C9.90393 7.58618 9.78027 7.70984 9.78027 7.86206V8.96558C9.78027 9.1178 9.90393 9.24133 10.0562 9.24133ZM11.7113 8.6897H10.332V8.13794H11.7113V8.6897Z"
					fill="#A09893"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M11.9871 9.24133H13.9181C14.0704 9.24133 14.194 9.1178 14.194 8.96558V7.86206C14.194 7.70984 14.0704 7.58618 13.9181 7.58618H11.9871C11.8348 7.58618 11.7112 7.70984 11.7112 7.86206V8.96558C11.7112 9.1178 11.8348 9.24133 11.9871 9.24133ZM13.6422 8.6897H12.2629V8.13794H13.6422V8.6897Z"
					fill="#A09893"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M13.918 9.24133H15.849C16.0013 9.24133 16.1249 9.1178 16.1249 8.96558V7.86206C16.1249 7.70984 16.0013 7.58618 15.849 7.58618H13.918C13.7657 7.58618 13.6421 7.70984 13.6421 7.86206V8.96558C13.6421 9.1178 13.7657 9.24133 13.918 9.24133ZM15.5731 8.6897H14.1938V8.13794H15.5731V8.6897Z"
					fill="#A09893"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8.125 10.3448H10.056C10.2084 10.3448 10.3319 10.2212 10.3319 10.069V8.96558C10.3319 8.81323 10.2084 8.6897 10.056 8.6897H8.125C7.97278 8.6897 7.84912 8.81323 7.84912 8.96558V10.069C7.84912 10.2212 7.97278 10.3448 8.125 10.3448ZM9.78015 9.79309H8.40088V9.24133H9.78015V9.79309Z"
					fill="#A09893"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.0562 10.3448H11.9872C12.1395 10.3448 12.2631 10.2212 12.2631 10.069V8.96558C12.2631 8.81323 12.1395 8.6897 11.9872 8.6897H10.0562C9.90393 8.6897 9.78027 8.81323 9.78027 8.96558V10.069C9.78027 10.2212 9.90393 10.3448 10.0562 10.3448ZM11.7113 9.79309H10.332V9.24133H11.7113V9.79309Z"
					fill="#A09893"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M11.9871 10.3448H13.9181C14.0704 10.3448 14.194 10.2212 14.194 10.069V8.96558C14.194 8.81323 14.0704 8.6897 13.9181 8.6897H11.9871C11.8348 8.6897 11.7112 8.81323 11.7112 8.96558V10.069C11.7112 10.2212 11.8348 10.3448 11.9871 10.3448ZM13.6422 9.79309H12.2629V9.24133H13.6422V9.79309Z"
					fill="#A09893"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M13.918 10.3448H15.849C16.0013 10.3448 16.1249 10.2212 16.1249 10.069V8.96558C16.1249 8.81323 16.0013 8.6897 15.849 8.6897H13.918C13.7657 8.6897 13.6421 8.81323 13.6421 8.96558V10.069C13.6421 10.2212 13.7657 10.3448 13.918 10.3448ZM15.5731 9.79309H14.1938V9.24133H15.5731V9.79309Z"
					fill="#A09893"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8.125 11.4482H10.056C10.2084 11.4482 10.3319 11.3247 10.3319 11.1724V10.069C10.3319 9.91675 10.2084 9.79309 10.056 9.79309H8.125C7.97278 9.79309 7.84912 9.91675 7.84912 10.069V11.1724C7.84912 11.3247 7.97278 11.4482 8.125 11.4482ZM9.78015 10.8966H8.40088V10.3448H9.78015V10.8966Z"
					fill="#A09893"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.0562 11.4482H11.9872C12.1395 11.4482 12.2631 11.3247 12.2631 11.1724V10.069C12.2631 9.91675 12.1395 9.79309 11.9872 9.79309H10.0562C9.90393 9.79309 9.78027 9.91675 9.78027 10.069V11.1724C9.78027 11.3247 9.90393 11.4482 10.0562 11.4482ZM11.7113 10.8966H10.332V10.3448H11.7113V10.8966Z"
					fill="#A09893"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M11.9871 11.4482H13.9181C14.0704 11.4482 14.194 11.3247 14.194 11.1724V10.069C14.194 9.91675 14.0704 9.79309 13.9181 9.79309H11.9871C11.8348 9.79309 11.7112 9.91675 11.7112 10.069V11.1724C11.7112 11.3247 11.8348 11.4482 11.9871 11.4482ZM13.6422 10.8966H12.2629V10.3448H13.6422V10.8966Z"
					fill="#A09893"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M13.918 11.4482H15.849C16.0013 11.4482 16.1249 11.3247 16.1249 11.1724V10.069C16.1249 9.91675 16.0013 9.79309 15.849 9.79309H13.918C13.7657 9.79309 13.6421 9.91675 13.6421 10.069V11.1724C13.6421 11.3247 13.7657 11.4482 13.918 11.4482ZM15.5731 10.8966H14.1938V10.3448H15.5731V10.8966Z"
					fill="#A09893"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8.125 12.5518H10.056C10.2084 12.5518 10.3319 12.4281 10.3319 12.2759V11.1724C10.3319 11.0201 10.2084 10.8966 10.056 10.8966H8.125C7.97278 10.8966 7.84912 11.0201 7.84912 11.1724V12.2759C7.84912 12.4281 7.97278 12.5518 8.125 12.5518ZM9.78015 12H8.40088V11.4482H9.78015V12Z"
					fill="#A09893"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.0562 12.5518H11.9872C12.1395 12.5518 12.2631 12.4281 12.2631 12.2759V11.1724C12.2631 11.0201 12.1395 10.8966 11.9872 10.8966H10.0562C9.90393 10.8966 9.78027 11.0201 9.78027 11.1724V12.2759C9.78027 12.4281 9.90393 12.5518 10.0562 12.5518ZM11.7113 12H10.332V11.4482H11.7113V12Z"
					fill="#A09893"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M11.9871 12.5518H13.9181C14.0704 12.5518 14.194 12.4281 14.194 12.2759V11.1724C14.194 11.0201 14.0704 10.8966 13.9181 10.8966H11.9871C11.8348 10.8966 11.7112 11.0201 11.7112 11.1724V12.2759C11.7112 12.4281 11.8348 12.5518 11.9871 12.5518ZM13.6422 12H12.2629V11.4482H13.6422V12Z"
					fill="#A09893"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M13.918 12.5518H15.849C16.0013 12.5518 16.1249 12.4281 16.1249 12.2759V11.1724C16.1249 11.0201 16.0013 10.8966 15.849 10.8966H13.918C13.7657 10.8966 13.6421 11.0201 13.6421 11.1724V12.2759C13.6421 12.4281 13.7657 12.5518 13.918 12.5518ZM15.5731 12H14.1938V11.4482H15.5731V12Z"
					fill="#A09893"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8.125 13.6552H10.056C10.2084 13.6552 10.3319 13.5316 10.3319 13.3793V12.2759C10.3319 12.1235 10.2084 12 10.056 12H8.125C7.97278 12 7.84912 12.1235 7.84912 12.2759V13.3793C7.84912 13.5316 7.97278 13.6552 8.125 13.6552ZM9.78015 13.1034H8.40088V12.5518H9.78015V13.1034Z"
					fill="#A09893"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.0562 13.6552H11.9872C12.1395 13.6552 12.2631 13.5316 12.2631 13.3793V12.2759C12.2631 12.1235 12.1395 12 11.9872 12H10.0562C9.90393 12 9.78027 12.1235 9.78027 12.2759V13.3793C9.78027 13.5316 9.90393 13.6552 10.0562 13.6552ZM11.7113 13.1034H10.332V12.5518H11.7113V13.1034Z"
					fill="#A09893"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M11.9871 13.6552H13.9181C14.0704 13.6552 14.194 13.5316 14.194 13.3793V12.2759C14.194 12.1235 14.0704 12 13.9181 12H11.9871C11.8348 12 11.7112 12.1235 11.7112 12.2759V13.3793C11.7112 13.5316 11.8348 13.6552 11.9871 13.6552ZM13.6422 13.1034H12.2629V12.5518H13.6422V13.1034Z"
					fill="#A09893"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M13.918 13.6552H15.849C16.0013 13.6552 16.1249 13.5316 16.1249 13.3793V12.2759C16.1249 12.1235 16.0013 12 15.849 12H13.918C13.7657 12 13.6421 12.1235 13.6421 12.2759V13.3793C13.6421 13.5316 13.7657 13.6552 13.918 13.6552ZM15.5731 13.1034H14.1938V12.5518H15.5731V13.1034Z"
					fill="#A09893"
				/>
				<path
					d="M9.78015 19.1724H8.6792C8.22156 19.1724 7.84912 18.8 7.84912 18.3423V16.6921C7.84912 16.2345 8.22156 15.8621 8.6792 15.8621H9.78015C9.9325 15.8621 10.056 15.9856 10.056 16.1379C10.056 16.2902 9.9325 16.4138 9.78015 16.4138H8.6792C8.52551 16.4138 8.40088 16.5388 8.40088 16.6921V18.3423C8.40088 18.4957 8.52551 18.6207 8.6792 18.6207H9.78015C9.9325 18.6207 10.056 18.7443 10.056 18.8966C10.056 19.0488 9.9325 19.1724 9.78015 19.1724Z"
					fill="white"
				/>
				<path
					d="M11.8492 19.1724H10.8838C10.7314 19.1724 10.6079 19.0488 10.6079 18.8966C10.6079 18.7443 10.7314 18.6207 10.8838 18.6207H11.8492C12.0774 18.6207 12.2631 18.4351 12.2631 18.2069C12.2631 17.9788 12.0774 17.7931 11.8492 17.7931H11.5734C11.041 17.7931 10.6079 17.36 10.6079 16.8276C10.6079 16.2952 11.041 15.8621 11.5734 15.8621H12.5389C12.6912 15.8621 12.8148 15.9856 12.8148 16.1379C12.8148 16.2902 12.6912 16.4138 12.5389 16.4138H11.5734C11.3453 16.4138 11.1597 16.5995 11.1597 16.8276C11.1597 17.0557 11.3453 17.2413 11.5734 17.2413H11.8492C12.3817 17.2413 12.8148 17.6744 12.8148 18.2069C12.8148 18.7393 12.3817 19.1724 11.8492 19.1724Z"
					fill="white"
				/>
				<path
					d="M14.7457 19.1724C14.633 19.1724 14.5316 19.1038 14.4894 18.9992L13.386 16.2406C13.3295 16.099 13.3981 15.9385 13.5397 15.882C13.6814 15.8257 13.8417 15.8943 13.8983 16.0357L14.7457 18.1539L15.5929 16.0357C15.6497 15.8943 15.8097 15.8251 15.9516 15.882C16.093 15.9387 16.1618 16.099 16.1051 16.2406L15.0017 18.9992C14.9599 19.1038 14.8585 19.1724 14.7457 19.1724Z"
					fill="white"
				/>
			</svg>
		);
	},
	txt: () => {
		return (
			<svg
				width="20"
				height="20"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M17.5044 19.1724H6.46997V4H15.0217L17.5044 6.48279V19.1724Z"
					fill="#E3E4D8"
				/>
				<path
					d="M15.0217 4V6.48279H17.5044L15.0217 4Z"
					fill="#D0CEBD"
				/>
				<path
					d="M6.46997 15.0344H17.5044V20H6.46997V15.0344Z"
					fill="#4E74BA"
				/>
				<path
					d="M10.3319 11.1724H8.125C7.97278 11.1724 7.84912 11.0488 7.84912 10.8966C7.84912 10.7443 7.97278 10.6207 8.125 10.6207H10.3319C10.4843 10.6207 10.6078 10.7443 10.6078 10.8966C10.6078 11.0488 10.4843 11.1724 10.3319 11.1724Z"
					fill="#A09893"
				/>
				<path
					d="M14.1941 11.1724H11.4355C11.2832 11.1724 11.1597 11.0488 11.1597 10.8966C11.1597 10.7443 11.2832 10.6207 11.4355 10.6207H14.1941C14.3464 10.6207 14.47 10.7443 14.47 10.8966C14.47 11.0488 14.3464 11.1724 14.1941 11.1724Z"
					fill="#A09893"
				/>
				<path
					d="M13.3666 12.2759H10.332C10.1798 12.2759 10.0562 12.1522 10.0562 12C10.0562 11.8478 10.1798 11.7241 10.332 11.7241H13.3666C13.5188 11.7241 13.6423 11.8478 13.6423 12C13.6423 12.1522 13.5188 12.2759 13.3666 12.2759Z"
					fill="#A09893"
				/>
				<path
					d="M15.849 11.1724H15.2974C15.145 11.1724 15.0215 11.0488 15.0215 10.8966C15.0215 10.7443 15.145 10.6207 15.2974 10.6207H15.849C16.0013 10.6207 16.1249 10.7443 16.1249 10.8966C16.1249 11.0488 16.0013 11.1724 15.849 11.1724Z"
					fill="#A09893"
				/>
				<path
					d="M15.849 12.2759H14.4697C14.3175 12.2759 14.1938 12.1522 14.1938 12C14.1938 11.8478 14.3175 11.7241 14.4697 11.7241H15.849C16.0013 11.7241 16.1249 11.8478 16.1249 12C16.1249 12.1522 16.0013 12.2759 15.849 12.2759Z"
					fill="#A09893"
				/>
				<path
					d="M9.22852 12.2759H8.125C7.97278 12.2759 7.84912 12.1522 7.84912 12C7.84912 11.8478 7.97278 11.7241 8.125 11.7241H9.22852C9.38074 11.7241 9.50427 11.8478 9.50427 12C9.50427 12.1522 9.38074 12.2759 9.22852 12.2759Z"
					fill="#A09893"
				/>
				<path
					d="M10.3319 13.3793H8.125C7.97278 13.3793 7.84912 13.2557 7.84912 13.1034C7.84912 12.9512 7.97278 12.8276 8.125 12.8276H10.3319C10.4843 12.8276 10.6078 12.9512 10.6078 13.1034C10.6078 13.2557 10.4843 13.3793 10.3319 13.3793Z"
					fill="#A09893"
				/>
				<path
					d="M14.1941 13.3793H11.4355C11.2832 13.3793 11.1597 13.2557 11.1597 13.1034C11.1597 12.9512 11.2832 12.8276 11.4355 12.8276H14.1941C14.3464 12.8276 14.47 12.9512 14.47 13.1034C14.47 13.2557 14.3464 13.3793 14.1941 13.3793Z"
					fill="#A09893"
				/>
				<path
					d="M15.849 13.3793H15.2974C15.145 13.3793 15.0215 13.2557 15.0215 13.1034C15.0215 12.9512 15.145 12.8276 15.2974 12.8276H15.849C16.0013 12.8276 16.1249 12.9512 16.1249 13.1034C16.1249 13.2557 16.0013 13.3793 15.849 13.3793Z"
					fill="#A09893"
				/>
				<path
					d="M15.8491 10.069H13.6422C13.49 10.069 13.3665 9.94543 13.3665 9.79309C13.3665 9.64087 13.49 9.51721 13.6422 9.51721H15.8491C16.0015 9.51721 16.125 9.64087 16.125 9.79309C16.125 9.94543 16.0015 10.069 15.8491 10.069Z"
					fill="#A09893"
				/>
				<path
					d="M12.5389 10.069H9.78027C9.62805 10.069 9.50439 9.94543 9.50439 9.79309C9.50439 9.64087 9.62805 9.51721 9.78027 9.51721H12.5389C12.6912 9.51721 12.8148 9.64087 12.8148 9.79309C12.8148 9.94543 12.6912 10.069 12.5389 10.069Z"
					fill="#A09893"
				/>
				<path
					d="M13.6423 8.96558H10.6079C10.4556 8.96558 10.332 8.84192 10.332 8.6897C10.332 8.53735 10.4556 8.41382 10.6079 8.41382H13.6423C13.7947 8.41382 13.9182 8.53735 13.9182 8.6897C13.9182 8.84192 13.7947 8.96558 13.6423 8.96558Z"
					fill="#A09893"
				/>
				<path
					d="M8.67676 10.069H8.125C7.97278 10.069 7.84912 9.94543 7.84912 9.79309C7.84912 9.64087 7.97278 9.51721 8.125 9.51721H8.67676C8.82898 9.51721 8.95264 9.64087 8.95264 9.79309C8.95264 9.94543 8.82898 10.069 8.67676 10.069Z"
					fill="#A09893"
				/>
				<path
					d="M9.50427 8.96558H8.125C7.97278 8.96558 7.84912 8.84192 7.84912 8.6897C7.84912 8.53735 7.97278 8.41382 8.125 8.41382H9.50427C9.65662 8.41382 9.78015 8.53735 9.78015 8.6897C9.78015 8.84192 9.65662 8.96558 9.50427 8.96558Z"
					fill="#A09893"
				/>
				<path
					d="M15.849 8.96558H14.7456C14.5933 8.96558 14.4697 8.84192 14.4697 8.6897C14.4697 8.53735 14.5933 8.41382 14.7456 8.41382H15.849C16.0013 8.41382 16.1249 8.53735 16.1249 8.6897C16.1249 8.84192 16.0013 8.96558 15.849 8.96558Z"
					fill="#A09893"
				/>
				<path
					d="M9.78015 16.4138H8.125C7.97278 16.4138 7.84912 16.2902 7.84912 16.1379C7.84912 15.9856 7.97278 15.8621 8.125 15.8621H9.78015C9.9325 15.8621 10.056 15.9856 10.056 16.1379C10.056 16.2902 9.9325 16.4138 9.78015 16.4138Z"
					fill="white"
				/>
				<path
					d="M8.95264 19.1724C8.80029 19.1724 8.67676 19.0488 8.67676 18.8966V16.1379C8.67676 15.9856 8.80029 15.8621 8.95264 15.8621C9.10486 15.8621 9.22852 15.9856 9.22852 16.1379V18.8966C9.22852 19.0488 9.10486 19.1724 8.95264 19.1724Z"
					fill="white"
				/>
				<path
					d="M11.1592 19.1723C11.111 19.1723 11.062 19.1596 11.0178 19.1329C10.887 19.0545 10.8448 18.8852 10.9232 18.7545L12.5783 15.9958C12.6567 15.8651 12.8264 15.8228 12.9569 15.9012C13.0876 15.9796 13.1298 16.1489 13.0515 16.2797L11.3963 19.0383C11.3444 19.1246 11.2531 19.1723 11.1592 19.1723Z"
					fill="white"
				/>
				<path
					d="M12.815 19.1724C12.7213 19.1724 12.6299 19.1247 12.5783 19.0386L10.9232 16.2801C10.8448 16.1493 10.887 15.9799 11.0178 15.9015C11.1483 15.8229 11.3179 15.8654 11.3963 15.9961L13.0515 18.7548C13.1298 18.8856 13.0876 19.055 12.9569 19.1334C12.9124 19.1597 12.8633 19.1724 12.815 19.1724Z"
					fill="white"
				/>
				<path
					d="M15.8492 16.4138H14.1941C14.0419 16.4138 13.9182 16.2902 13.9182 16.1379C13.9182 15.9856 14.0419 15.8621 14.1941 15.8621H15.8492C16.0016 15.8621 16.1251 15.9856 16.1251 16.1379C16.1251 16.2902 16.0016 16.4138 15.8492 16.4138Z"
					fill="white"
				/>
				<path
					d="M15.0217 19.1724C14.8694 19.1724 14.7458 19.0488 14.7458 18.8966V16.1379C14.7458 15.9856 14.8694 15.8621 15.0217 15.8621C15.174 15.8621 15.2976 15.9856 15.2976 16.1379V18.8966C15.2976 19.0488 15.174 19.1724 15.0217 19.1724Z"
					fill="white"
				/>
			</svg>
		);
	},
	code: () => {
		return (
			<svg
				width="20"
				height="20"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M17.5044 19.1724H6.46997V4H15.0217L17.5044 6.48279V19.1724Z"
					fill="#E3E4D8"
				/>
				<path
					d="M15.0217 4V6.48279H17.5044L15.0217 4Z"
					fill="#D0CEBD"
				/>
				<path
					d="M6.46997 15.0344H17.5044V20H6.46997V15.0344Z"
					fill="#616161"
				/>
				<path
					d="M10.3319 11.1724H8.125C7.97278 11.1724 7.84912 11.0488 7.84912 10.8966C7.84912 10.7443 7.97278 10.6207 8.125 10.6207H10.3319C10.4843 10.6207 10.6078 10.7443 10.6078 10.8966C10.6078 11.0488 10.4843 11.1724 10.3319 11.1724Z"
					fill="#A09893"
				/>
				<path
					d="M14.1941 11.1724H11.4355C11.2832 11.1724 11.1597 11.0488 11.1597 10.8966C11.1597 10.7443 11.2832 10.6207 11.4355 10.6207H14.1941C14.3464 10.6207 14.47 10.7443 14.47 10.8966C14.47 11.0488 14.3464 11.1724 14.1941 11.1724Z"
					fill="#A09893"
				/>
				<path
					d="M13.3666 12.2759H10.332C10.1798 12.2759 10.0562 12.1522 10.0562 12C10.0562 11.8478 10.1798 11.7241 10.332 11.7241H13.3666C13.5188 11.7241 13.6423 11.8478 13.6423 12C13.6423 12.1522 13.5188 12.2759 13.3666 12.2759Z"
					fill="#A09893"
				/>
				<path
					d="M15.849 11.1724H15.2974C15.145 11.1724 15.0215 11.0488 15.0215 10.8966C15.0215 10.7443 15.145 10.6207 15.2974 10.6207H15.849C16.0013 10.6207 16.1249 10.7443 16.1249 10.8966C16.1249 11.0488 16.0013 11.1724 15.849 11.1724Z"
					fill="#A09893"
				/>
				<path
					d="M15.849 12.2759H14.4697C14.3175 12.2759 14.1938 12.1522 14.1938 12C14.1938 11.8478 14.3175 11.7241 14.4697 11.7241H15.849C16.0013 11.7241 16.1249 11.8478 16.1249 12C16.1249 12.1522 16.0013 12.2759 15.849 12.2759Z"
					fill="#A09893"
				/>
				<path
					d="M9.22852 12.2759H8.125C7.97278 12.2759 7.84912 12.1522 7.84912 12C7.84912 11.8478 7.97278 11.7241 8.125 11.7241H9.22852C9.38074 11.7241 9.50427 11.8478 9.50427 12C9.50427 12.1522 9.38074 12.2759 9.22852 12.2759Z"
					fill="#A09893"
				/>
				<path
					d="M10.3319 13.3793H8.125C7.97278 13.3793 7.84912 13.2557 7.84912 13.1034C7.84912 12.9512 7.97278 12.8276 8.125 12.8276H10.3319C10.4843 12.8276 10.6078 12.9512 10.6078 13.1034C10.6078 13.2557 10.4843 13.3793 10.3319 13.3793Z"
					fill="#A09893"
				/>
				<path
					d="M14.1941 13.3793H11.4355C11.2832 13.3793 11.1597 13.2557 11.1597 13.1034C11.1597 12.9512 11.2832 12.8276 11.4355 12.8276H14.1941C14.3464 12.8276 14.47 12.9512 14.47 13.1034C14.47 13.2557 14.3464 13.3793 14.1941 13.3793Z"
					fill="#A09893"
				/>
				<path
					d="M15.849 13.3793H15.2974C15.145 13.3793 15.0215 13.2557 15.0215 13.1034C15.0215 12.9512 15.145 12.8276 15.2974 12.8276H15.849C16.0013 12.8276 16.1249 12.9512 16.1249 13.1034C16.1249 13.2557 16.0013 13.3793 15.849 13.3793Z"
					fill="#A09893"
				/>
				<path
					d="M15.8491 10.069H13.6422C13.49 10.069 13.3665 9.94543 13.3665 9.79309C13.3665 9.64087 13.49 9.51721 13.6422 9.51721H15.8491C16.0015 9.51721 16.125 9.64087 16.125 9.79309C16.125 9.94543 16.0015 10.069 15.8491 10.069Z"
					fill="#A09893"
				/>
				<path
					d="M12.5389 10.069H9.78027C9.62805 10.069 9.50439 9.94543 9.50439 9.79309C9.50439 9.64087 9.62805 9.51721 9.78027 9.51721H12.5389C12.6912 9.51721 12.8148 9.64087 12.8148 9.79309C12.8148 9.94543 12.6912 10.069 12.5389 10.069Z"
					fill="#A09893"
				/>
				<path
					d="M13.6423 8.96558H10.6079C10.4556 8.96558 10.332 8.84192 10.332 8.6897C10.332 8.53735 10.4556 8.41382 10.6079 8.41382H13.6423C13.7947 8.41382 13.9182 8.53735 13.9182 8.6897C13.9182 8.84192 13.7947 8.96558 13.6423 8.96558Z"
					fill="#A09893"
				/>
				<path
					d="M8.67676 10.069H8.125C7.97278 10.069 7.84912 9.94543 7.84912 9.79309C7.84912 9.64087 7.97278 9.51721 8.125 9.51721H8.67676C8.82898 9.51721 8.95264 9.64087 8.95264 9.79309C8.95264 9.94543 8.82898 10.069 8.67676 10.069Z"
					fill="#A09893"
				/>
				<path
					d="M9.50427 8.96558H8.125C7.97278 8.96558 7.84912 8.84192 7.84912 8.6897C7.84912 8.53735 7.97278 8.41382 8.125 8.41382H9.50427C9.65662 8.41382 9.78015 8.53735 9.78015 8.6897C9.78015 8.84192 9.65662 8.96558 9.50427 8.96558Z"
					fill="#A09893"
				/>
				<path
					d="M15.849 8.96558H14.7456C14.5933 8.96558 14.4697 8.84192 14.4697 8.6897C14.4697 8.53735 14.5933 8.41382 14.7456 8.41382H15.849C16.0013 8.41382 16.1249 8.53735 16.1249 8.6897C16.1249 8.84192 16.0013 8.96558 15.849 8.96558Z"
					fill="#A09893"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.9082 18.7616C10.8527 18.8131 10.7838 18.8388 10.7015 18.8388C10.6439 18.8388 10.5925 18.8285 10.5472 18.8079L8.25769 17.8329C8.17129 17.7958 8.10341 17.7413 8.05404 17.6693C8.00467 17.5973 7.97998 17.514 7.97998 17.4194C7.97998 17.3289 8.00467 17.2466 8.05404 17.1725C8.10341 17.0985 8.17129 17.045 8.25769 17.0121L10.5472 16.0309C10.6007 16.0103 10.6521 16 10.7015 16C10.7838 16 10.8527 16.0257 10.9082 16.0771C10.9638 16.1286 10.9916 16.1954 10.9916 16.2777C10.9916 16.3847 10.9401 16.4608 10.8373 16.506L8.65882 17.4194L10.8373 18.3327C10.9401 18.378 10.9916 18.4541 10.9916 18.5611C10.9916 18.6434 10.9638 18.7102 10.9082 18.7616ZM13.4127 18.8079C13.3675 18.8285 13.3161 18.8388 13.2585 18.8388C13.1762 18.8388 13.1073 18.8131 13.0517 18.7616C12.9962 18.7102 12.9684 18.6434 12.9684 18.5611C12.9684 18.4541 13.0198 18.378 13.1227 18.3327L15.3011 17.4194L13.1227 16.506C13.0198 16.4608 12.9684 16.3847 12.9684 16.2777C12.9684 16.1954 12.9962 16.1286 13.0517 16.0771C13.1073 16.0257 13.1762 16 13.2585 16C13.3078 16 13.3593 16.0103 13.4127 16.0309L15.7023 17.0121C15.7887 17.045 15.8566 17.0985 15.9059 17.1725C15.9553 17.2466 15.98 17.3289 15.98 17.4194C15.98 17.514 15.9553 17.5973 15.9059 17.6693C15.8566 17.7413 15.7887 17.7958 15.7023 17.8329L13.4127 18.8079Z"
					fill="white"
				/>
			</svg>
		);
	},
	jar: () => {
		return (
			<svg
				width="20"
				height="20"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M17.5044 19.1724H6.46997V4H15.0217L17.5044 6.48279V19.1724Z"
					fill="#E3E4D8"
				/>
				<path
					d="M15.0217 4V6.48279H17.5044L15.0217 4Z"
					fill="#D0CEBD"
				/>
				<path
					d="M6.46997 15.0344H17.5044V20H6.46997V15.0344Z"
					fill="#616161"
				/>
				<path
					d="M10.3319 11.1724H8.125C7.97278 11.1724 7.84912 11.0488 7.84912 10.8966C7.84912 10.7443 7.97278 10.6207 8.125 10.6207H10.3319C10.4843 10.6207 10.6078 10.7443 10.6078 10.8966C10.6078 11.0488 10.4843 11.1724 10.3319 11.1724Z"
					fill="#A09893"
				/>
				<path
					d="M14.1941 11.1724H11.4355C11.2832 11.1724 11.1597 11.0488 11.1597 10.8966C11.1597 10.7443 11.2832 10.6207 11.4355 10.6207H14.1941C14.3464 10.6207 14.47 10.7443 14.47 10.8966C14.47 11.0488 14.3464 11.1724 14.1941 11.1724Z"
					fill="#A09893"
				/>
				<path
					d="M13.3666 12.2759H10.332C10.1798 12.2759 10.0562 12.1522 10.0562 12C10.0562 11.8478 10.1798 11.7241 10.332 11.7241H13.3666C13.5188 11.7241 13.6423 11.8478 13.6423 12C13.6423 12.1522 13.5188 12.2759 13.3666 12.2759Z"
					fill="#A09893"
				/>
				<path
					d="M15.849 11.1724H15.2974C15.145 11.1724 15.0215 11.0488 15.0215 10.8966C15.0215 10.7443 15.145 10.6207 15.2974 10.6207H15.849C16.0013 10.6207 16.1249 10.7443 16.1249 10.8966C16.1249 11.0488 16.0013 11.1724 15.849 11.1724Z"
					fill="#A09893"
				/>
				<path
					d="M15.849 12.2759H14.4697C14.3175 12.2759 14.1938 12.1522 14.1938 12C14.1938 11.8478 14.3175 11.7241 14.4697 11.7241H15.849C16.0013 11.7241 16.1249 11.8478 16.1249 12C16.1249 12.1522 16.0013 12.2759 15.849 12.2759Z"
					fill="#A09893"
				/>
				<path
					d="M9.22852 12.2759H8.125C7.97278 12.2759 7.84912 12.1522 7.84912 12C7.84912 11.8478 7.97278 11.7241 8.125 11.7241H9.22852C9.38074 11.7241 9.50427 11.8478 9.50427 12C9.50427 12.1522 9.38074 12.2759 9.22852 12.2759Z"
					fill="#A09893"
				/>
				<path
					d="M10.3319 13.3793H8.125C7.97278 13.3793 7.84912 13.2557 7.84912 13.1034C7.84912 12.9512 7.97278 12.8276 8.125 12.8276H10.3319C10.4843 12.8276 10.6078 12.9512 10.6078 13.1034C10.6078 13.2557 10.4843 13.3793 10.3319 13.3793Z"
					fill="#A09893"
				/>
				<path
					d="M14.1941 13.3793H11.4355C11.2832 13.3793 11.1597 13.2557 11.1597 13.1034C11.1597 12.9512 11.2832 12.8276 11.4355 12.8276H14.1941C14.3464 12.8276 14.47 12.9512 14.47 13.1034C14.47 13.2557 14.3464 13.3793 14.1941 13.3793Z"
					fill="#A09893"
				/>
				<path
					d="M15.849 13.3793H15.2974C15.145 13.3793 15.0215 13.2557 15.0215 13.1034C15.0215 12.9512 15.145 12.8276 15.2974 12.8276H15.849C16.0013 12.8276 16.1249 12.9512 16.1249 13.1034C16.1249 13.2557 16.0013 13.3793 15.849 13.3793Z"
					fill="#A09893"
				/>
				<path
					d="M15.8491 10.069H13.6422C13.49 10.069 13.3665 9.94543 13.3665 9.79309C13.3665 9.64087 13.49 9.51721 13.6422 9.51721H15.8491C16.0015 9.51721 16.125 9.64087 16.125 9.79309C16.125 9.94543 16.0015 10.069 15.8491 10.069Z"
					fill="#A09893"
				/>
				<path
					d="M12.5389 10.069H9.78027C9.62805 10.069 9.50439 9.94543 9.50439 9.79309C9.50439 9.64087 9.62805 9.51721 9.78027 9.51721H12.5389C12.6912 9.51721 12.8148 9.64087 12.8148 9.79309C12.8148 9.94543 12.6912 10.069 12.5389 10.069Z"
					fill="#A09893"
				/>
				<path
					d="M13.6423 8.96558H10.6079C10.4556 8.96558 10.332 8.84192 10.332 8.6897C10.332 8.53735 10.4556 8.41382 10.6079 8.41382H13.6423C13.7947 8.41382 13.9182 8.53735 13.9182 8.6897C13.9182 8.84192 13.7947 8.96558 13.6423 8.96558Z"
					fill="#A09893"
				/>
				<path
					d="M8.67676 10.069H8.125C7.97278 10.069 7.84912 9.94543 7.84912 9.79309C7.84912 9.64087 7.97278 9.51721 8.125 9.51721H8.67676C8.82898 9.51721 8.95264 9.64087 8.95264 9.79309C8.95264 9.94543 8.82898 10.069 8.67676 10.069Z"
					fill="#A09893"
				/>
				<path
					d="M9.50427 8.96558H8.125C7.97278 8.96558 7.84912 8.84192 7.84912 8.6897C7.84912 8.53735 7.97278 8.41382 8.125 8.41382H9.50427C9.65662 8.41382 9.78015 8.53735 9.78015 8.6897C9.78015 8.84192 9.65662 8.96558 9.50427 8.96558Z"
					fill="#A09893"
				/>
				<path
					d="M15.849 8.96558H14.7456C14.5933 8.96558 14.4697 8.84192 14.4697 8.6897C14.4697 8.53735 14.5933 8.41382 14.7456 8.41382H15.849C16.0013 8.41382 16.1249 8.53735 16.1249 8.6897C16.1249 8.84192 16.0013 8.96558 15.849 8.96558Z"
					fill="#A09893"
				/>
				<path
					d="M8.72006 19.045C8.49806 19.045 8.29406 19.0075 8.10806 18.9325C7.92506 18.8575 7.77656 18.745 7.66256 18.595C7.55156 18.445 7.49306 18.2635 7.48706 18.0505C7.48706 18.0235 7.49606 18.001 7.51406 17.983C7.53206 17.962 7.55606 17.9515 7.58606 17.9515H8.02256C8.09156 17.9515 8.13506 17.989 8.15306 18.064C8.17706 18.22 8.24006 18.3355 8.34206 18.4105C8.44706 18.4855 8.57906 18.523 8.73806 18.523C8.92406 18.523 9.06656 18.463 9.16556 18.343C9.26756 18.22 9.31856 18.049 9.31856 17.83V16.39H7.79306C7.76306 16.39 7.73756 16.3795 7.71656 16.3585C7.69556 16.3375 7.68506 16.3105 7.68506 16.2775V15.958C7.68506 15.925 7.69556 15.8995 7.71656 15.8815C7.73756 15.8605 7.76306 15.85 7.79306 15.85H9.85856C9.89156 15.85 9.91706 15.8605 9.93506 15.8815C9.95606 15.9025 9.96656 15.9295 9.96656 15.9625V17.848C9.96656 18.097 9.91556 18.3115 9.81356 18.4915C9.71156 18.6715 9.56606 18.8095 9.37706 18.9055C9.19106 18.9985 8.97206 19.045 8.72006 19.045Z"
					fill="white"
				/>
				<path
					d="M10.4961 19C10.4691 19 10.4466 18.991 10.4286 18.973C10.4106 18.955 10.4016 18.9325 10.4016 18.9055L10.4106 18.865L11.4906 15.9625C11.5206 15.8875 11.5746 15.85 11.6526 15.85H12.1476C12.2256 15.85 12.2796 15.8875 12.3096 15.9625L13.3896 18.865L13.3986 18.9055C13.3986 18.9325 13.3881 18.955 13.3671 18.973C13.3491 18.991 13.3281 19 13.3041 19H12.9036C12.8406 19 12.7971 18.9715 12.7731 18.9145L12.5661 18.37H11.2341L11.0271 18.9145C11.0031 18.9715 10.9596 19 10.8966 19H10.4961ZM11.3916 17.848H12.4086L11.9001 16.462L11.3916 17.848Z"
					fill="white"
				/>
				<path
					d="M14.0346 19C14.0046 19 13.9791 18.9895 13.9581 18.9685C13.9371 18.9475 13.9266 18.922 13.9266 18.892V15.9625C13.9266 15.9295 13.9356 15.9025 13.9536 15.8815C13.9746 15.8605 14.0016 15.85 14.0346 15.85H15.2001C15.5691 15.85 15.8571 15.9355 16.0641 16.1065C16.2741 16.2775 16.3791 16.5205 16.3791 16.8355C16.3791 17.0545 16.3266 17.239 16.2216 17.389C16.1166 17.536 15.9696 17.6425 15.7806 17.7085L16.4331 18.856C16.4421 18.874 16.4466 18.8905 16.4466 18.9055C16.4466 18.9325 16.4361 18.955 16.4151 18.973C16.3971 18.991 16.3761 19 16.3521 19H15.9381C15.8901 19 15.8526 18.9895 15.8256 18.9685C15.7986 18.9445 15.7746 18.9145 15.7536 18.8785L15.1776 17.812H14.5611V18.892C14.5611 18.922 14.5506 18.9475 14.5296 18.9685C14.5086 18.9895 14.4816 19 14.4486 19H14.0346ZM15.1866 17.299C15.3666 17.299 15.5016 17.26 15.5916 17.182C15.6816 17.101 15.7266 16.984 15.7266 16.831C15.7266 16.678 15.6816 16.561 15.5916 16.48C15.5016 16.396 15.3666 16.354 15.1866 16.354H14.5611V17.299H15.1866Z"
					fill="white"
				/>
			</svg>
		);
	},
	egg: () => {
		return (
			<svg
				width="20"
				height="20"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M17.5044 19.1724H6.46997V4H15.0217L17.5044 6.48279V19.1724Z"
					fill="#E3E4D8"
				/>
				<path
					d="M15.0217 4V6.48279H17.5044L15.0217 4Z"
					fill="#D0CEBD"
				/>
				<path
					d="M6.46997 15.0344H17.5044V20H6.46997V15.0344Z"
					fill="#AB4EBA"
				/>
				<path
					d="M10.3319 11.1724H8.125C7.97278 11.1724 7.84912 11.0488 7.84912 10.8966C7.84912 10.7443 7.97278 10.6207 8.125 10.6207H10.3319C10.4843 10.6207 10.6078 10.7443 10.6078 10.8966C10.6078 11.0488 10.4843 11.1724 10.3319 11.1724Z"
					fill="#A09893"
				/>
				<path
					d="M14.1941 11.1724H11.4355C11.2832 11.1724 11.1597 11.0488 11.1597 10.8966C11.1597 10.7443 11.2832 10.6207 11.4355 10.6207H14.1941C14.3464 10.6207 14.47 10.7443 14.47 10.8966C14.47 11.0488 14.3464 11.1724 14.1941 11.1724Z"
					fill="#A09893"
				/>
				<path
					d="M13.3666 12.2759H10.332C10.1798 12.2759 10.0562 12.1522 10.0562 12C10.0562 11.8478 10.1798 11.7241 10.332 11.7241H13.3666C13.5188 11.7241 13.6423 11.8478 13.6423 12C13.6423 12.1522 13.5188 12.2759 13.3666 12.2759Z"
					fill="#A09893"
				/>
				<path
					d="M15.849 11.1724H15.2974C15.145 11.1724 15.0215 11.0488 15.0215 10.8966C15.0215 10.7443 15.145 10.6207 15.2974 10.6207H15.849C16.0013 10.6207 16.1249 10.7443 16.1249 10.8966C16.1249 11.0488 16.0013 11.1724 15.849 11.1724Z"
					fill="#A09893"
				/>
				<path
					d="M15.849 12.2759H14.4697C14.3175 12.2759 14.1938 12.1522 14.1938 12C14.1938 11.8478 14.3175 11.7241 14.4697 11.7241H15.849C16.0013 11.7241 16.1249 11.8478 16.1249 12C16.1249 12.1522 16.0013 12.2759 15.849 12.2759Z"
					fill="#A09893"
				/>
				<path
					d="M9.22852 12.2759H8.125C7.97278 12.2759 7.84912 12.1522 7.84912 12C7.84912 11.8478 7.97278 11.7241 8.125 11.7241H9.22852C9.38074 11.7241 9.50427 11.8478 9.50427 12C9.50427 12.1522 9.38074 12.2759 9.22852 12.2759Z"
					fill="#A09893"
				/>
				<path
					d="M10.3319 13.3793H8.125C7.97278 13.3793 7.84912 13.2557 7.84912 13.1034C7.84912 12.9512 7.97278 12.8276 8.125 12.8276H10.3319C10.4843 12.8276 10.6078 12.9512 10.6078 13.1034C10.6078 13.2557 10.4843 13.3793 10.3319 13.3793Z"
					fill="#A09893"
				/>
				<path
					d="M14.1941 13.3793H11.4355C11.2832 13.3793 11.1597 13.2557 11.1597 13.1034C11.1597 12.9512 11.2832 12.8276 11.4355 12.8276H14.1941C14.3464 12.8276 14.47 12.9512 14.47 13.1034C14.47 13.2557 14.3464 13.3793 14.1941 13.3793Z"
					fill="#A09893"
				/>
				<path
					d="M15.849 13.3793H15.2974C15.145 13.3793 15.0215 13.2557 15.0215 13.1034C15.0215 12.9512 15.145 12.8276 15.2974 12.8276H15.849C16.0013 12.8276 16.1249 12.9512 16.1249 13.1034C16.1249 13.2557 16.0013 13.3793 15.849 13.3793Z"
					fill="#A09893"
				/>
				<path
					d="M15.8491 10.069H13.6422C13.49 10.069 13.3665 9.94543 13.3665 9.79309C13.3665 9.64087 13.49 9.51721 13.6422 9.51721H15.8491C16.0015 9.51721 16.125 9.64087 16.125 9.79309C16.125 9.94543 16.0015 10.069 15.8491 10.069Z"
					fill="#A09893"
				/>
				<path
					d="M12.5389 10.069H9.78027C9.62805 10.069 9.50439 9.94543 9.50439 9.79309C9.50439 9.64087 9.62805 9.51721 9.78027 9.51721H12.5389C12.6912 9.51721 12.8148 9.64087 12.8148 9.79309C12.8148 9.94543 12.6912 10.069 12.5389 10.069Z"
					fill="#A09893"
				/>
				<path
					d="M13.6423 8.96558H10.6079C10.4556 8.96558 10.332 8.84192 10.332 8.6897C10.332 8.53735 10.4556 8.41382 10.6079 8.41382H13.6423C13.7947 8.41382 13.9182 8.53735 13.9182 8.6897C13.9182 8.84192 13.7947 8.96558 13.6423 8.96558Z"
					fill="#A09893"
				/>
				<path
					d="M8.67676 10.069H8.125C7.97278 10.069 7.84912 9.94543 7.84912 9.79309C7.84912 9.64087 7.97278 9.51721 8.125 9.51721H8.67676C8.82898 9.51721 8.95264 9.64087 8.95264 9.79309C8.95264 9.94543 8.82898 10.069 8.67676 10.069Z"
					fill="#A09893"
				/>
				<path
					d="M9.50427 8.96558H8.125C7.97278 8.96558 7.84912 8.84192 7.84912 8.6897C7.84912 8.53735 7.97278 8.41382 8.125 8.41382H9.50427C9.65662 8.41382 9.78015 8.53735 9.78015 8.6897C9.78015 8.84192 9.65662 8.96558 9.50427 8.96558Z"
					fill="#A09893"
				/>
				<path
					d="M15.849 8.96558H14.7456C14.5933 8.96558 14.4697 8.84192 14.4697 8.6897C14.4697 8.53735 14.5933 8.41382 14.7456 8.41382H15.849C16.0013 8.41382 16.1249 8.53735 16.1249 8.6897C16.1249 8.84192 16.0013 8.96558 15.849 8.96558Z"
					fill="#A09893"
				/>
				<path
					d="M7.78256 19.0001C7.75256 19.0001 7.72706 18.9896 7.70606 18.9686C7.68506 18.9476 7.67456 18.9221 7.67456 18.8921V15.9626C7.67456 15.9296 7.68356 15.9026 7.70156 15.8816C7.72256 15.8606 7.74956 15.8501 7.78256 15.8501H9.76706C9.80006 15.8501 9.82706 15.8606 9.84806 15.8816C9.86906 15.9026 9.87956 15.9296 9.87956 15.9626V16.2641C9.87956 16.2971 9.86906 16.3241 9.84806 16.3451C9.82706 16.3631 9.80006 16.3721 9.76706 16.3721H8.29106V17.1596H9.66806C9.70106 17.1596 9.72806 17.1701 9.74906 17.1911C9.77006 17.2121 9.78056 17.2391 9.78056 17.2721V17.5556C9.78056 17.5886 9.77006 17.6156 9.74906 17.6366C9.72806 17.6546 9.70106 17.6636 9.66806 17.6636H8.29106V18.4781H9.80306C9.83606 18.4781 9.86306 18.4886 9.88406 18.5096C9.90506 18.5276 9.91556 18.5531 9.91556 18.5861V18.8921C9.91556 18.9251 9.90506 18.9521 9.88406 18.9731C9.86306 18.9911 9.83606 19.0001 9.80306 19.0001H7.78256Z"
					fill="white"
				/>
				<path
					d="M11.7929 19.0451C11.3789 19.0451 11.0564 18.9386 10.8254 18.7256C10.5974 18.5126 10.4759 18.2156 10.4609 17.8346C10.4579 17.7476 10.4564 17.6081 10.4564 17.4161C10.4564 17.2241 10.4579 17.0846 10.4609 16.9976C10.4729 16.6256 10.5959 16.3346 10.8299 16.1246C11.0639 15.9116 11.3849 15.8051 11.7929 15.8051C12.0689 15.8051 12.3059 15.8531 12.5039 15.9491C12.7049 16.0421 12.8564 16.1591 12.9584 16.3001C13.0604 16.4381 13.1144 16.5746 13.1204 16.7096C13.1204 16.7366 13.1114 16.7591 13.0934 16.7771C13.0754 16.7951 13.0514 16.8041 13.0214 16.8041H12.5489C12.5189 16.8041 12.4964 16.7981 12.4814 16.7861C12.4664 16.7741 12.4529 16.7546 12.4409 16.7276C12.3989 16.6166 12.3254 16.5221 12.2204 16.4441C12.1154 16.3661 11.9729 16.3271 11.7929 16.3271C11.5829 16.3271 11.4179 16.3841 11.2979 16.4981C11.1809 16.6091 11.1194 16.7831 11.1134 17.0201C11.1104 17.1071 11.1089 17.2391 11.1089 17.4161C11.1089 17.5901 11.1104 17.7221 11.1134 17.8121C11.1194 18.0551 11.1824 18.2351 11.3024 18.3521C11.4224 18.4661 11.5874 18.5231 11.7974 18.5231C12.0104 18.5231 12.1814 18.4661 12.3104 18.3521C12.4394 18.2381 12.5039 18.0671 12.5039 17.8391V17.6996H11.9549C11.9249 17.6996 11.8994 17.6891 11.8784 17.6681C11.8574 17.6471 11.8469 17.6216 11.8469 17.5916V17.3441C11.8469 17.3141 11.8574 17.2886 11.8784 17.2676C11.8994 17.2466 11.9249 17.2361 11.9549 17.2361H13.0349C13.0679 17.2361 13.0949 17.2466 13.1159 17.2676C13.1369 17.2886 13.1474 17.3141 13.1474 17.3441V17.8211C13.1474 18.0701 13.0919 18.2876 12.9809 18.4736C12.8699 18.6566 12.7124 18.7976 12.5084 18.8966C12.3044 18.9956 12.0659 19.0451 11.7929 19.0451Z"
					fill="white"
				/>
				<path
					d="M15.0607 19.0451C14.6467 19.0451 14.3242 18.9386 14.0932 18.7256C13.8652 18.5126 13.7437 18.2156 13.7287 17.8346C13.7257 17.7476 13.7242 17.6081 13.7242 17.4161C13.7242 17.2241 13.7257 17.0846 13.7287 16.9976C13.7407 16.6256 13.8637 16.3346 14.0977 16.1246C14.3317 15.9116 14.6527 15.8051 15.0607 15.8051C15.3367 15.8051 15.5737 15.8531 15.7717 15.9491C15.9727 16.0421 16.1242 16.1591 16.2262 16.3001C16.3282 16.4381 16.3822 16.5746 16.3882 16.7096C16.3882 16.7366 16.3792 16.7591 16.3612 16.7771C16.3432 16.7951 16.3192 16.8041 16.2892 16.8041H15.8167C15.7867 16.8041 15.7642 16.7981 15.7492 16.7861C15.7342 16.7741 15.7207 16.7546 15.7087 16.7276C15.6667 16.6166 15.5932 16.5221 15.4882 16.4441C15.3832 16.3661 15.2407 16.3271 15.0607 16.3271C14.8507 16.3271 14.6857 16.3841 14.5657 16.4981C14.4487 16.6091 14.3872 16.7831 14.3812 17.0201C14.3782 17.1071 14.3767 17.2391 14.3767 17.4161C14.3767 17.5901 14.3782 17.7221 14.3812 17.8121C14.3872 18.0551 14.4502 18.2351 14.5702 18.3521C14.6902 18.4661 14.8552 18.5231 15.0652 18.5231C15.2782 18.5231 15.4492 18.4661 15.5782 18.3521C15.7072 18.2381 15.7717 18.0671 15.7717 17.8391V17.6996H15.2227C15.1927 17.6996 15.1672 17.6891 15.1462 17.6681C15.1252 17.6471 15.1147 17.6216 15.1147 17.5916V17.3441C15.1147 17.3141 15.1252 17.2886 15.1462 17.2676C15.1672 17.2466 15.1927 17.2361 15.2227 17.2361H16.3027C16.3357 17.2361 16.3627 17.2466 16.3837 17.2676C16.4047 17.2886 16.4152 17.3141 16.4152 17.3441V17.8211C16.4152 18.0701 16.3597 18.2876 16.2487 18.4736C16.1377 18.6566 15.9802 18.7976 15.7762 18.8966C15.5722 18.9956 15.3337 19.0451 15.0607 19.0451Z"
					fill="white"
				/>
			</svg>
		);
	},
	whl: () => {
		return (
			<svg
				width="20"
				height="20"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M17.5044 19.1724H6.46997V4H15.0217L17.5044 6.48279V19.1724Z"
					fill="#E3E4D8"
				/>
				<path
					d="M15.0217 4V6.48279H17.5044L15.0217 4Z"
					fill="#D0CEBD"
				/>
				<path
					d="M6.46997 15.0344H17.5044V20H6.46997V15.0344Z"
					fill="#574EBA"
				/>
				<path
					d="M10.3319 11.1724H8.125C7.97278 11.1724 7.84912 11.0488 7.84912 10.8966C7.84912 10.7443 7.97278 10.6207 8.125 10.6207H10.3319C10.4843 10.6207 10.6078 10.7443 10.6078 10.8966C10.6078 11.0488 10.4843 11.1724 10.3319 11.1724Z"
					fill="#A09893"
				/>
				<path
					d="M14.1941 11.1724H11.4355C11.2832 11.1724 11.1597 11.0488 11.1597 10.8966C11.1597 10.7443 11.2832 10.6207 11.4355 10.6207H14.1941C14.3464 10.6207 14.47 10.7443 14.47 10.8966C14.47 11.0488 14.3464 11.1724 14.1941 11.1724Z"
					fill="#A09893"
				/>
				<path
					d="M13.3666 12.2759H10.332C10.1798 12.2759 10.0562 12.1522 10.0562 12C10.0562 11.8478 10.1798 11.7241 10.332 11.7241H13.3666C13.5188 11.7241 13.6423 11.8478 13.6423 12C13.6423 12.1522 13.5188 12.2759 13.3666 12.2759Z"
					fill="#A09893"
				/>
				<path
					d="M15.849 11.1724H15.2974C15.145 11.1724 15.0215 11.0488 15.0215 10.8966C15.0215 10.7443 15.145 10.6207 15.2974 10.6207H15.849C16.0013 10.6207 16.1249 10.7443 16.1249 10.8966C16.1249 11.0488 16.0013 11.1724 15.849 11.1724Z"
					fill="#A09893"
				/>
				<path
					d="M15.849 12.2759H14.4697C14.3175 12.2759 14.1938 12.1522 14.1938 12C14.1938 11.8478 14.3175 11.7241 14.4697 11.7241H15.849C16.0013 11.7241 16.1249 11.8478 16.1249 12C16.1249 12.1522 16.0013 12.2759 15.849 12.2759Z"
					fill="#A09893"
				/>
				<path
					d="M9.22852 12.2759H8.125C7.97278 12.2759 7.84912 12.1522 7.84912 12C7.84912 11.8478 7.97278 11.7241 8.125 11.7241H9.22852C9.38074 11.7241 9.50427 11.8478 9.50427 12C9.50427 12.1522 9.38074 12.2759 9.22852 12.2759Z"
					fill="#A09893"
				/>
				<path
					d="M10.3319 13.3793H8.125C7.97278 13.3793 7.84912 13.2557 7.84912 13.1034C7.84912 12.9512 7.97278 12.8276 8.125 12.8276H10.3319C10.4843 12.8276 10.6078 12.9512 10.6078 13.1034C10.6078 13.2557 10.4843 13.3793 10.3319 13.3793Z"
					fill="#A09893"
				/>
				<path
					d="M14.1941 13.3793H11.4355C11.2832 13.3793 11.1597 13.2557 11.1597 13.1034C11.1597 12.9512 11.2832 12.8276 11.4355 12.8276H14.1941C14.3464 12.8276 14.47 12.9512 14.47 13.1034C14.47 13.2557 14.3464 13.3793 14.1941 13.3793Z"
					fill="#A09893"
				/>
				<path
					d="M15.849 13.3793H15.2974C15.145 13.3793 15.0215 13.2557 15.0215 13.1034C15.0215 12.9512 15.145 12.8276 15.2974 12.8276H15.849C16.0013 12.8276 16.1249 12.9512 16.1249 13.1034C16.1249 13.2557 16.0013 13.3793 15.849 13.3793Z"
					fill="#A09893"
				/>
				<path
					d="M15.8491 10.069H13.6422C13.49 10.069 13.3665 9.94543 13.3665 9.79309C13.3665 9.64087 13.49 9.51721 13.6422 9.51721H15.8491C16.0015 9.51721 16.125 9.64087 16.125 9.79309C16.125 9.94543 16.0015 10.069 15.8491 10.069Z"
					fill="#A09893"
				/>
				<path
					d="M12.5389 10.069H9.78027C9.62805 10.069 9.50439 9.94543 9.50439 9.79309C9.50439 9.64087 9.62805 9.51721 9.78027 9.51721H12.5389C12.6912 9.51721 12.8148 9.64087 12.8148 9.79309C12.8148 9.94543 12.6912 10.069 12.5389 10.069Z"
					fill="#A09893"
				/>
				<path
					d="M13.6423 8.96558H10.6079C10.4556 8.96558 10.332 8.84192 10.332 8.6897C10.332 8.53735 10.4556 8.41382 10.6079 8.41382H13.6423C13.7947 8.41382 13.9182 8.53735 13.9182 8.6897C13.9182 8.84192 13.7947 8.96558 13.6423 8.96558Z"
					fill="#A09893"
				/>
				<path
					d="M8.67676 10.069H8.125C7.97278 10.069 7.84912 9.94543 7.84912 9.79309C7.84912 9.64087 7.97278 9.51721 8.125 9.51721H8.67676C8.82898 9.51721 8.95264 9.64087 8.95264 9.79309C8.95264 9.94543 8.82898 10.069 8.67676 10.069Z"
					fill="#A09893"
				/>
				<path
					d="M9.50427 8.96558H8.125C7.97278 8.96558 7.84912 8.84192 7.84912 8.6897C7.84912 8.53735 7.97278 8.41382 8.125 8.41382H9.50427C9.65662 8.41382 9.78015 8.53735 9.78015 8.6897C9.78015 8.84192 9.65662 8.96558 9.50427 8.96558Z"
					fill="#A09893"
				/>
				<path
					d="M15.849 8.96558H14.7456C14.5933 8.96558 14.4697 8.84192 14.4697 8.6897C14.4697 8.53735 14.5933 8.41382 14.7456 8.41382H15.849C16.0013 8.41382 16.1249 8.53735 16.1249 8.6897C16.1249 8.84192 16.0013 8.96558 15.849 8.96558Z"
					fill="#A09893"
				/>
				<path
					d="M7.94823 19C7.86123 19 7.80723 18.955 7.78623 18.865L7.25523 15.9805L7.25073 15.9445C7.25073 15.9175 7.25973 15.895 7.27773 15.877C7.29573 15.859 7.31823 15.85 7.34523 15.85H7.75023C7.78923 15.85 7.81773 15.8575 7.83573 15.8725C7.85373 15.8845 7.86573 15.907 7.87173 15.94L8.24523 17.965L8.64573 16.1125C8.67573 16.0375 8.72523 16 8.79423 16H9.04173C9.08073 16 9.11223 16.012 9.13623 16.036C9.16023 16.057 9.17673 16.0825 9.18573 16.1125L9.59073 17.965L9.95973 15.94C9.96873 15.88 10.0107 15.85 10.0857 15.85H10.4907C10.5147 15.85 10.5357 15.859 10.5537 15.877C10.5717 15.895 10.5807 15.9175 10.5807 15.9445C10.5807 15.9595 10.5792 15.9715 10.5762 15.9805L10.0452 18.865C10.0392 18.907 10.0212 18.94 9.99123 18.964C9.96423 18.988 9.92823 19 9.88323 19H9.57723C9.53523 19 9.50073 18.9895 9.47373 18.9685C9.44673 18.9445 9.42873 18.9175 9.41973 18.8875L8.91573 17.3935L8.41173 18.8875C8.38773 18.9625 8.33523 19 8.25423 19H7.94823Z"
					fill="white"
				/>
				<path
					d="M11.3251 19C11.2951 19 11.2696 18.9895 11.2486 18.9685C11.2276 18.9475 11.2171 18.922 11.2171 18.892V15.9625C11.2171 15.9295 11.2261 15.9025 11.2441 15.8815C11.2651 15.8605 11.2921 15.85 11.3251 15.85H11.7391C11.7721 15.85 11.7991 15.8605 11.8201 15.8815C11.8411 15.9025 11.8516 15.9295 11.8516 15.9625V17.119H13.1656V15.9625C13.1656 15.9295 13.1761 15.9025 13.1971 15.8815C13.2181 15.8605 13.2436 15.85 13.2736 15.85H13.6921C13.7251 15.85 13.7506 15.8605 13.7686 15.8815C13.7896 15.9025 13.8001 15.9295 13.8001 15.9625V18.892C13.8001 18.922 13.7896 18.9475 13.7686 18.9685C13.7506 18.9895 13.7251 19 13.6921 19H13.2736C13.2436 19 13.2181 18.9895 13.1971 18.9685C13.1761 18.9475 13.1656 18.922 13.1656 18.892V17.6905H11.8516V18.892C11.8516 18.922 11.8411 18.9475 11.8201 18.9685C11.7991 18.9895 11.7721 19 11.7391 19H11.3251Z"
					fill="white"
				/>
				<path
					d="M14.7247 19C14.6947 19 14.6692 18.9895 14.6482 18.9685C14.6272 18.9475 14.6167 18.922 14.6167 18.892V15.958C14.6167 15.925 14.6272 15.8995 14.6482 15.8815C14.6692 15.8605 14.6947 15.85 14.7247 15.85H15.1477C15.1807 15.85 15.2062 15.8605 15.2242 15.8815C15.2452 15.8995 15.2557 15.925 15.2557 15.958V18.46H16.6822C16.7152 18.46 16.7422 18.4705 16.7632 18.4915C16.7842 18.5125 16.7947 18.538 16.7947 18.568V18.892C16.7947 18.922 16.7842 18.9475 16.7632 18.9685C16.7422 18.9895 16.7152 19 16.6822 19H14.7247Z"
					fill="white"
				/>
			</svg>
		);
	},
	upload: () => {
		return (
			<svg
				width={16}
				height={16}
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M7.39498 4.68466L5.89649 6.18315L5.06009 5.34745L7.56821 2.84002C7.79855 2.60899 8.17306 2.60899 8.40391 2.84002L10.912 5.34745L10.076 6.18315L8.57697 4.68466L8.57697 10.9416H7.39498L7.39498 4.68466ZM12.1233 12.1237V9.75942H13.3055V12.1237C13.3055 12.7734 12.7733 13.3057 12.1233 13.3057H3.84867C3.19863 13.3057 2.6665 12.7734 2.6665 12.1237V9.75942H3.84867V12.1237H12.1233Z"
					fill="black"
				/>
				<mask
					id="mask0_2829_396983"
					style={{
						maskType: "alpha",
					}}
					maskUnits="userSpaceOnUse"
					x={2}
					y={2}
					width={12}
					height={12}
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M7.39498 4.68466L5.89649 6.18315L5.06009 5.34745L7.56821 2.84002C7.79855 2.60899 8.17306 2.60899 8.40391 2.84002L10.912 5.34745L10.076 6.18315L8.57697 4.68466L8.57697 10.9416H7.39498L7.39498 4.68466ZM12.1233 12.1237V9.75942H13.3055V12.1237C13.3055 12.7734 12.7733 13.3057 12.1233 13.3057H3.84867C3.19863 13.3057 2.6665 12.7734 2.6665 12.1237V9.75942H3.84867V12.1237H12.1233Z"
						fill="white"
					/>
				</mask>
				<g mask="url(#mask0_2829_396983)">
					<rect width={16} height={16} fill="#D4D6E0" />
				</g>
			</svg>
		);
	},
	refresh: () => {
		return (
			<svg
				width={20}
				height={20}
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M16.6667 10.0115C16.6714 13.0679 14.5814 15.7317 11.6058 16.4618C8.63014 17.192 5.53958 15.7993 4.12212 13.0895C2.70466 10.3798 3.3274 7.05472 5.63006 5.0381C7.93272 3.02148 11.3189 2.83554 13.8296 4.58787L13.7483 4.35206C13.6178 3.95526 13.8344 3.52801 14.2323 3.39778C14.6301 3.26754 15.0585 3.48364 15.189 3.88045L15.9279 6.09115C16.0022 6.31596 15.9637 6.5627 15.8244 6.75437C15.6791 6.95849 15.4398 7.07505 15.189 7.06387H12.9726C12.5646 7.06387 12.2338 6.73394 12.2338 6.32696C12.2393 6.00695 12.4512 5.72705 12.7583 5.63427C10.7593 4.38814 8.16843 4.64795 6.45815 6.26604C4.74788 7.88414 4.35104 10.451 5.4932 12.5078C6.63536 14.5646 9.02745 15.5908 11.3099 15.0031C13.5924 14.4155 15.1877 12.3627 15.189 10.0115C15.189 9.6045 15.5198 9.27457 15.9279 9.27457C16.3359 9.27457 16.6667 9.6045 16.6667 10.0115Z"
					fill="black"
				/>
				<mask
					id="mask0_0_47713"
					style={{
						maskType: "alpha",
					}}
					maskUnits="userSpaceOnUse"
					x={3}
					y={3}
					width={14}
					height={14}
				>
					<path
						d="M16.6667 10.0115C16.6714 13.0679 14.5814 15.7317 11.6058 16.4618C8.63014 17.192 5.53958 15.7993 4.12212 13.0895C2.70466 10.3798 3.3274 7.05472 5.63006 5.0381C7.93272 3.02148 11.3189 2.83554 13.8296 4.58787L13.7483 4.35206C13.6178 3.95526 13.8344 3.52801 14.2323 3.39778C14.6301 3.26754 15.0585 3.48364 15.189 3.88045L15.9279 6.09115C16.0022 6.31596 15.9637 6.5627 15.8244 6.75437C15.6791 6.95849 15.4398 7.07505 15.189 7.06387H12.9726C12.5646 7.06387 12.2338 6.73394 12.2338 6.32696C12.2393 6.00695 12.4512 5.72705 12.7583 5.63427C10.7593 4.38814 8.16843 4.64795 6.45815 6.26604C4.74788 7.88414 4.35104 10.451 5.4932 12.5078C6.63536 14.5646 9.02745 15.5908 11.3099 15.0031C13.5924 14.4155 15.1877 12.3627 15.189 10.0115C15.189 9.6045 15.5198 9.27457 15.9279 9.27457C16.3359 9.27457 16.6667 9.6045 16.6667 10.0115Z"
						fill="white"
					/>
				</mask>
				<g mask="url(#mask0_0_47713)">
					<rect width={20} height={20} fill="#14182A" />
				</g>
			</svg>
		);
	},
	search: () => {
		return (
			<svg
				width={16}
				height={16}
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M7 12C4.23858 12 2 9.76142 2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7C12 8.15126 11.6109 9.21163 10.9571 10.0568L13.5846 12.7302C13.8427 12.9928 13.8391 13.4149 13.5765 13.673C13.3139 13.9311 12.8918 13.9274 12.6337 13.6648L10.0086 10.9939C9.17145 11.6255 8.12946 12 7 12ZM10.6667 7C10.6667 9.02504 9.02504 10.6667 7 10.6667C4.97496 10.6667 3.33333 9.02504 3.33333 7C3.33333 4.97496 4.97496 3.33333 7 3.33333C9.02504 3.33333 10.6667 4.97496 10.6667 7Z"
					fill="white"
				/>
				<mask
					id="mask0_2829_395878"
					style={{
						maskType: "alpha",
					}}
					maskUnits="userSpaceOnUse"
					x={2}
					y={2}
					width={12}
					height={12}
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M7 12C4.23858 12 2 9.76142 2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7C12 8.15126 11.6109 9.21163 10.9571 10.0568L13.5846 12.7302C13.8427 12.9928 13.8391 13.4149 13.5765 13.673C13.3139 13.9311 12.8918 13.9274 12.6337 13.6648L10.0086 10.9939C9.17145 11.6255 8.12946 12 7 12ZM10.6667 7C10.6667 9.02504 9.02504 10.6667 7 10.6667C4.97496 10.6667 3.33333 9.02504 3.33333 7C3.33333 4.97496 4.97496 3.33333 7 3.33333C9.02504 3.33333 10.6667 4.97496 10.6667 7Z"
						fill="white"
					/>
				</mask>
				<g mask="url(#mask0_2829_395878)">
					<rect width={16} height={16} fill="#C7C7CD" />
				</g>
			</svg>
		);
	},
};
