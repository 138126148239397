import classNames from 'classnames';
import { useFormikContext } from 'formik';
import {  cloneDeep, isEmpty, set } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react'; 
import { ShowWhenTrue } from '../../../../helpers';
import { InputField } from '../../../form';
import { CheckboxField, CheckboxSelectionType } from '../../../form/checkbox-field';
import { SelectField, _selectoptionType } from '../../../form/select-field';
import { 
    autoResizeOptions,
    balanceBatchesOptions,
    balanceModeOptions,
    buildModelOptions,
    colorModeOptionsForDropdown,
    DataCategoryOptions, 
    dataCategoryOptionsForDropdown, 
    DataSet, 
    dataSetOptions, 
    encodeCategoricalOptions, 
    encodeCategoricalTargetOptions, 
    evalMetricClassOptionsForDropdown, 
    evalMetricRegOptionsForDropdown, 
    evalMetricSklearnClassOptionsForDropdown, 
    evalMetricSklearnClusteringOptionsForDropdown, 
    evalMetricSklearnRegOptionsForDropdown, 
    ExtraOperationOptions, 
    featuresFieldData, 
    filterFormData, 
    inputTypeFieldName, 
    InputTypeOptions, 
    inputTypeOptionsForDropdown, 
    labelColFieldData, 
    labelModeOptionsForDropdown, 
    MlPipelineStageKeys, 
    ModelLibraryOptions, 
    modelLibraryOptionsForDropdown, 
    ModeOptions, 
    OutputFormatOptions, 
    outputFormatOptionsForDropdown, 
    PipelineCategoryOptions, 
    pipelineCategoryOptionsForDropdown, 
    PythonModelLibraryOptions, 
    sampleFromDatasetsOptions, 
    showIntermediateValuesOptions, 
    SparkModelLibraryOptions, 
    splitForTestOptions, 
    SplitForTestTypes, 
    splitForTestTypesOptions, 
    splitForWindowOptions, 
    useGeneratorOptions, 
    ValidateModelOptions, 
    validateModelOptionsForDropdown 
} from '../enums';
import { InputField as CaptureSchemaField } from '../../field-creator';
import { UpChevronIcon } from '../../icons';
import {  useDidUpdate, usePrevious } from 'rooks';

import { getKeyInPipelineStage, getNumberOfDigitsAfterDecimal,  getValueInPipelineStage, numberFieldSchema, showSplitForTestTypesFun, showValidateModelFieldsFun, showValidateModelInnerFieldsFun, stringFieldSchema, useGetStagesInfo } from '../utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/types';
import { FieldSchemaValidator } from '../../schema-creator';
import { CreateTrainModelStageParams } from '.';
import { BuildPipelineRHSProps } from './build-pipeline-RHS';
import { ExecutionEnvModes } from '../../../../constants/enums';
import { RadioField } from '../../../form/radio-field';
import { uuid } from 'uuidv4';
import { Env } from '../../../../constants/settings';
import scrollIntoView from 'scroll-into-view-if-needed';
import { FileExplorer } from '@components/FileExplorer';
import { useFileStorage } from '@pages/file-browser/hooks/useFileStorage';
import Table from '@components/formcreators/fieldComponents/table';
import { useMlPipelineContext } from '../context';
import NewExperiments from '../NewExperiments';


export type BuildPipelineLHSProps = {
    handleCreateTrainModelStage: (arg0: CreateTrainModelStageParams) => any;
    handleModelLibraryChange: (arg0: ModelLibraryOptions) => any;
    handlePipelineCategoryClick: (arg0: PipelineCategoryOptions) => any;
    resetStageOperation: (stageId: string) => any;
    // captureSchemaOptions: BuildPipelineModalProps['captureSchemaOptions'];
    handleStagesInfo: BuildPipelineRHSProps['handleStagesInfo'];
    resetPipelineCategoryFieldInfo: {label: string; value: string}  | null;
}


const FitAndFitTransformDataFields: React.FC<BuildPipelineLHSProps> = ({ handleCreateTrainModelStage, handlePipelineCategoryClick, handleModelLibraryChange, handleStagesInfo, resetPipelineCategoryFieldInfo, resetStageOperation }) => {
    const { captureSchemaOptions } = useMlPipelineContext();
    const { initialValues, values, setFieldValue } = useFormikContext<any>();
    const  { customComponentsList } = useSelector((state: RootState) => state.WorkflowReducer);
    const sparkMode = values.model_library === SparkModelLibraryOptions['Spark'];
    const buildModelMode = values.build_model === 'true';
    const [stagesInfo, setStagesInfo] = useMemo(() => handleStagesInfo, [handleStagesInfo]);
    const activeExecutionEnv = useSelector((store: RootState) => store.CommonReducer.activeExecutionEnv);
    const {  modelLibraryStages } = useGetStagesInfo(stagesInfo);
    const filePathDivRef = useRef<HTMLDivElement>(null);

    const updateSplitTrainRatio = (trainVal: string, keyToBeUpdated: string) => {
        let testVal: any = 1.00;
        if(trainVal) {
            const trainValue = parseFloat(trainVal);
            testVal = 1.00 - trainValue;
            testVal = testVal.toFixed(getNumberOfDigitsAfterDecimal(trainVal));
        }
        setFieldValue(keyToBeUpdated, testVal);
    };

    useEffect(() => {
        if(values.split_for_test) {
            updateSplitTrainRatio(values.split_for_test_train, 'split_for_test_test');
        }
    }, [values.split_for_test_train, values.split_for_test]);

    const showSplitForTestTypes = showSplitForTestTypesFun(values);
    const renderSplitForTestFields = () => (
        <ShowWhenTrue show={buildModelMode && (values.model_library === PythonModelLibraryOptions.Tensorflow ?  values[inputTypeFieldName] !== InputTypeOptions['Parquet folder']:true)}>
            <CheckboxField 
                name="split_for_test"
                options={splitForTestOptions}
                color="gold"
                className={classNames({'checkbox__active': !!(values.split_for_test)})}
            />
            
            <ShowWhenTrue show={!!(values.split_for_test) && values[inputTypeFieldName] !== InputTypeOptions['Parquet folder']}>
                <ShowWhenTrue show={showSplitForTestTypesFun(values)}>
                    <RadioField 
                        name="split_for_test_type"
                        options={splitForTestTypesOptions}
                        infoText='Specifies a regex pattern to categorize files for training and testing data.'
                    />
                    <ShowWhenTrue show={values.split_for_test_type === SplitForTestTypes['Split By Pattern']}>
                        <InputField 
                            name="train_files_pattern"
                            label="Training Files Pattern"
                            validate={FieldSchemaValidator(stringFieldSchema)}
                            placeholder="regex pattern"
                            required
                            infoText='Specifies the split pattern for training data.'
                        />
                        <InputField 
                            name="validation_files_pattern"
                            label="Validation Files Pattern"
                            validate={FieldSchemaValidator(stringFieldSchema)}
                            placeholder="regex pattern"
                            required
                            infoText='Specifies the split pattern for validation data.'
                        />
                    </ShowWhenTrue>
                </ShowWhenTrue>
                <ShowWhenTrue show={showSplitForTestTypes ? values.split_for_test_type === SplitForTestTypes['Split By Fraction']: true}>
                    <div
                        className="split_for_test_fields"
                    >
                        <InputField 
                            name="split_for_test_train"
                            label="Train"
                            inputMode="decimal"
                        />
                        <InputField 
                            name="split_for_test_test"
                            label="Test"
                            readOnly
                        />
                    </div>
                </ShowWhenTrue>
                
            </ShowWhenTrue>
        </ShowWhenTrue>
    );



    useEffect(() => {
        if(values.validate_model_type === ValidateModelOptions['Holdout']) {
            updateSplitTrainRatio(values.validate_model_train, 'validate_model_validation');
        }
    }, [values.validate_model_train, values.validate_model_type]);

    const evalMetricOptions = useMemo(() => {
        let options: _selectoptionType[] = [];
        if(values.model_library === PythonModelLibraryOptions['Sklearn']) {
            const __optionsRef = {
                [PipelineCategoryOptions['Classification']]: evalMetricSklearnClassOptionsForDropdown,
                [PipelineCategoryOptions['Regression']]: evalMetricSklearnRegOptionsForDropdown,
                [PipelineCategoryOptions['Clustering']]: evalMetricSklearnClusteringOptionsForDropdown,
            };
        
            options = cloneDeep(__optionsRef[values.pipeline_category as keyof typeof __optionsRef]) || [];
            options.push({ label: 'Custom', value: 'custom' });
        } else {
            options = values.pipeline_category ===  PipelineCategoryOptions.Classification 
                ? cloneDeep(evalMetricClassOptionsForDropdown) : cloneDeep(evalMetricRegOptionsForDropdown);
        }
        return options;
    }, [values.pipeline_category, values.model_library]);

    const customComponentOptions: _selectoptionType[] = useMemo(() => {
        if(!isEmpty(customComponentsList)) {
            return customComponentsList.map(component => ({ label: component.name, value: component.id }));
        } 
        return [];
    }, [customComponentsList]);

    const showValidateModelFields = showValidateModelFieldsFun(values);

    const renderValidateModelFields = () => (
        <ShowWhenTrue show={showValidateModelFields}>
            <CheckboxField 
                name="validate_model"
                options={splitForWindowOptions}
                color="gold"
                className={classNames({'checkbox__active': !!(values.validate_model)})}
            />
            <ShowWhenTrue show={showValidateModelInnerFieldsFun(values)}>
                <div className="validate_model_fields">
                    <div className="validate_model_inner_fields">
                        <SelectField 
                            name="validate_model_type"
                            options={validateModelOptionsForDropdown}
                            label="Type"
                            infoText="Hold-out validates on train test split and cross validation validates on k folds"
                        />
                        <SelectField 
                            name="validate_eval_metric"
                            options={evalMetricOptions}
                            label="Evaluation Metric"
                            infoText='Specifies the metric to analyze while doing validation.'
                        />
                    </div>
                    <div className="split_for_test_fields">
                        {values.validate_model_type === ValidateModelOptions['Holdout'] ?
                            <>
                                <InputField 
                                    name="validate_model_train"
                                    label="Train"
                                    inputMode="decimal"
                                />
                                <InputField 
                                    name="validate_model_validation"
                                    label="Validation"
                                    readOnly
                                />
                            </>
                            :
                            <InputField 
                                name="validate_num_folds"
                                label="Number of Folds"
                                inputMode="decimal"
                            />
                        }
                    </div>
                    <ShowWhenTrue show={!!(values.validate_model) && values.validate_eval_metric === 'custom'}>
                        <div>   
                            <SelectField 
                                name="validate_model_custom_func"
                                options={customComponentOptions}
                                label="Custom Function"
                            />
                            {/* <EditorField 
                                name="validate_model_custom_func"
                                editor_type="python"
                                label="Custom Function"
                                editorHeight="75px"
                            />   */}
                        </div>
                    </ShowWhenTrue>
                </div>
            </ShowWhenTrue>
        </ShowWhenTrue>
    );

    const renderOutputFormat = (condition: boolean) => (
        <ShowWhenTrue show={condition}>
            <SelectField 
                name="output_format"
                options={outputFormatOptionsForDropdown}
                className="highlightLabel"
                label="Output Format"
            />
        </ShowWhenTrue>
        
    );   

    const renderOutputPredictionColFields = () => (
        <ShowWhenTrue show={values.mode === ModeOptions['Fit and Transform Data'] && values[inputTypeFieldName] !== InputTypeOptions['Parquet folder']}>
            {renderOutputFormat(!buildModelMode && values.model_library === SparkModelLibraryOptions['Spark'])}
            <ShowWhenTrue show={buildModelMode}>
                {values.model_library !== SparkModelLibraryOptions['Spark'] ?
                    <InputField 
                        name="prediction_col"
                        label="Prediction Column"
                        className="highlightLabel"
                        validate={FieldSchemaValidator(stringFieldSchema)}
                        infoText="Name to use for the column containing final output of the pipeline"
                        required
                    />
                    :
                    <ShowWhenTrue show={values.output_format === OutputFormatOptions['Vectorized']}>
                        <InputField 
                            name="output_col"
                            className="highlightLabel"
                            label="Output Column"
                            validate={FieldSchemaValidator(stringFieldSchema)}
                            infoText="Name to use for the column containing final output of the pipeline"
                            required
                        />
                    </ShowWhenTrue>
                }
            </ShowWhenTrue>
        </ShowWhenTrue>
    );

    const [showFileSelection, toggleFileSelection] = useState(false);

    const renderInputTypeFields = () => {
        const handleFileSelection = (filePath: string) => {
        setFieldValue('file_path',  `${Env.mlPipelineAzureBlobFileSystemPrefix}${filePath}`);
    };

        const handleFileSelectorBtnClick = () => {
            toggleFileSelection(val => {
                if(!val) {
                    setTimeout(() => {
                        // this is to scroll the file browser into view
                        filePathDivRef.current && scrollIntoView(filePathDivRef.current)

                    }, 200);
                }
                return !val;
            });
        };
    
        const inputTypeOtherMode = values[inputTypeFieldName] !== InputTypeOptions['Default (dataframes)'];

        const {
            files,
            folderChain,
            selectedFiles,
            setSelectedFiles,
            setFolderChain,
            fileSystemPrefix,
        } = useFileStorage('Azure', Env.mlPipelineAzureBlobFileSystemPrefix);

        return(
            <ShowWhenTrue show={values.model_library === PythonModelLibraryOptions['Tensorflow']}>
                <div
                    className="inputTypeFields__box"
                >
                    <div className={ classNames({'pipelineInfo__fields': inputTypeOtherMode})}>
                        <SelectField 
                            name={inputTypeFieldName}
                            options={inputTypeOptionsForDropdown}
                            label="Input Type"
                            infoText="Indicates the type of data for input pipeline."
                        />
                        <ShowWhenTrue show={inputTypeOtherMode}>
                            <SelectField 
                                name="label_mode"
                                options={labelModeOptionsForDropdown}
                                label="Label Mode"
                                infoText={`•	'int': means that the labels are encoded as integers (e.g. for sparse_categorical_crossentropy loss).
                                •	'categorical' means that the labels are encoded as a categorical vector (e.g. for categorical_crossentropy loss).
                                •	'binary' means that the labels (there can be only 2) are encoded as float32 scalars with values 0 or 1 (e.g. for binary_crossentropy). 
                                • None (no labels). 
                                `}
                            />
                        </ShowWhenTrue>
                        
                    </div>
                    <ShowWhenTrue show={values[inputTypeFieldName] !== InputTypeOptions['Default (dataframes)']}>
                        <div
                            className="filePath__selectorBox"
                            ref={filePathDivRef}
                        >
                            <InputField 
                                name="file_path"
                                label="Folder Location"
                                validate={FieldSchemaValidator(stringFieldSchema)}
                                required
                                infoText='Specifies the location of input data.'
                            />
                            <button
                                className={classNames('btn btn-grey btn-showFileSelector',
                                    {rotate: showFileSelection}
                                )}
                                onClick={handleFileSelectorBtnClick}
                            >
                                <UpChevronIcon />
                            </button>
                        </div>
                        <div 
                            className={classNames(
                                'fileBrowserModal mlPipelineFileBrowser',
                                {collapse: !showFileSelection}
                            )}
                        >
                            {/* {Env.azureDeltaLakes ? 
                                <AzureBlobStorageFileBrowser  
                                    onFileSelection={handleFileSelection}
                                    readOnly
                                />
                                :
                                <FileBrowserComponent 
                                    showAsModal={false}
                                    toggleClose={() => {return;}}
                                    show={false}
                                    onFileSelection={handleFileSelection}
                                    initialPath=""
                                    readOnly
                                /> */}
                                <FileExplorer
                                    view='List'
                                    files={files}
                                    actionBarOptions={{hideRefresh: true, hideUpload: true, hideSearch: true}}
                                    folderChain={folderChain}
                                    selectedFiles={selectedFiles}
                                    setSelectedFiles={setSelectedFiles}
                                    setFolderChain={setFolderChain}
                                    clearSelectionOnOutsideClick={false}
                                    onFileSelect={(files) => setFieldValue('file_path', fileSystemPrefix + files[0].path)}
                                />
                            {/* } */}
                        </div>
                    </ShowWhenTrue>
                    
                </div>
            </ShowWhenTrue>
            
        );
    };

    const __pipelineCategoryOptions = useMemo(() => {
        switch(values.model_library) {
            case PythonModelLibraryOptions['Tensorflow']:
                return pipelineCategoryOptionsForDropdown.slice(-1,);
            case PythonModelLibraryOptions['Sklearn']:
                return pipelineCategoryOptionsForDropdown.slice(0, 4)
        }
        return pipelineCategoryOptionsForDropdown.slice(0,3);
    }, [values.model_library]);

    const trainModelStageInfo = useMemo(() => 
        modelLibraryStages[0]
    ,[modelLibraryStages]); 


    // const addANewStage = () => {
    //     const __stagesInfo = cloneDeep(stagesInfo);
    //     __stagesInfo.unshift({ id: uuid(), operation: null });
    //     setStagesInfo(__stagesInfo);
    // };


    const handleBuildModelClick = (type: CheckboxSelectionType) => {
        if(type === 'checked') {
            if(!trainModelStageInfo.touched) {
                // TO CHANGE THE VALUE OF PIPELINE CATEGORY, INITIAL VALUE IS SET
                initialValues.pipeline_category = PipelineCategoryOptions['Classification'];
                setFieldValue('pipeline_category', PipelineCategoryOptions['Classification']);
                handleCreateTrainModelStage({
                    trainModelStageInfo, 
                    pipelineCategory: PipelineCategoryOptions['Classification'],
                    // Since checking and unchecking of buildModel is allowed only in spark 
                    mlPipelineStageKey: sparkMode ? MlPipelineStageKeys['Spark']: MlPipelineStageKeys['sklearn']
                });
            } else {
                // IF PIPELINE CATEGORY IS SELECTED AND BUILD MODEL IS DISABLED AND THEN ENABLED, 
                // MODEL LIBRARY, PIPELINE CATEGORY and MODEL_TYPE IN TRAIN MODEL STAGE IS LOSING ITS STATE
                initialValues.pipeline_category = values.pipeline_category;
                initialValues.model_library = values.model_library;
                set(initialValues, getKeyInPipelineStage('model_type', trainModelStageInfo.id), getValueInPipelineStage(values, 'model_type', trainModelStageInfo.id));
            }
        } else {
            const activeModelLibrary: MlPipelineStageKeys = values.model_library === PythonModelLibraryOptions['Sklearn'] ? MlPipelineStageKeys.PREPROCESSING_Sklearn: MlPipelineStageKeys.PREPROCESSING_Spark; 
            // WHEN BUILD MODEL IS UNCHECKED AND TRAIN MODEL IS THE ONLY STAGE, ADD A NEW STAGE
            if(stagesInfo[activeModelLibrary].length === 0) {
                const __stagesInfo = cloneDeep(stagesInfo);
                __stagesInfo[activeModelLibrary] = [{
                    id: uuid(),
                    operation: null,
                    touched: false
                }];
                setStagesInfo(__stagesInfo);
            }
        }
    };

    const prevDataCategory = usePrevious(values.data_category);

    const handleDataCategoryChange = () => {
        if(prevDataCategory === DataCategoryOptions['Geospatial Data'] || prevDataCategory === DataCategoryOptions['Text Data']) {
            const __stagesInfo = cloneDeep(stagesInfo);
            // Data category stages are added in preprocessing 
            let selectedStageInfo = __stagesInfo[MlPipelineStageKeys['PREPROCESSING_Spark']];
            const stageToBeRemoved = selectedStageInfo.find(stage => stage.operation === ExtraOperationOptions['Structured Learning'] ||  
                stage.operation === ExtraOperationOptions['Feature Vectorizers'] ||  stage.operation === ExtraOperationOptions['Text Processors']);
            if(stageToBeRemoved) {
                selectedStageInfo = selectedStageInfo.map(stage => {
                    if(stage.id === stageToBeRemoved.id) stage.operation = null;
                    return stage;
                });
                setStagesInfo(__stagesInfo);
                setFieldValue('stage_'+stageToBeRemoved.id+'.operation', null);
                initialValues['stage_'+stageToBeRemoved.id+'.operation'] = null;
                resetStageOperation(stageToBeRemoved.id);
            }   
        }
    };

    const resetPipelineCategoryFieldRef = useRef<SelectField>(null);

    useDidUpdate(() => {
        if(resetPipelineCategoryFieldInfo) {
            resetPipelineCategoryFieldRef.current?.setOption(resetPipelineCategoryFieldInfo);
        }
    }, [resetPipelineCategoryFieldInfo]);


    const modelLibraryOptionsToBeShown = useMemo(() => {
        if(activeExecutionEnv === ExecutionEnvModes['Spark'])
            return modelLibraryOptionsForDropdown.slice(0, 1);
        return modelLibraryOptionsForDropdown.slice(1, 3);
    }, [activeExecutionEnv, modelLibraryOptionsForDropdown]);


    return(
        <>
            <NewExperiments /> 
            <InputField 
                name="pipeline_name"
                label="Pipeline Name"
                className="highlightLabel"
                validate={FieldSchemaValidator(stringFieldSchema)}
                infoText="Pipeline Name serves as the identifier for the pipeline model, which can be used while transforming or predicting in a new workflow"
                required
            />
            <CheckboxField 
                name="build_model"
                options={buildModelOptions}
                color="gold"
                className='checkbox__encodeCategFeatures'
                onOptionClick={handleBuildModelClick}
                disabled={values.model_library === PythonModelLibraryOptions['Tensorflow']}
                infoText={`Check this box to enable model building on top of data. This will be the final stage of the pipeline.${values.model_library === PythonModelLibraryOptions['Tensorflow'] ? ' Currently preprocessing steps are unavailable': ''}`}
            />
            <ShowWhenTrue show={buildModelMode}>
                <div className="pipelineInfo__fields">
                    <SelectField 
                        name="model_library"
                        options={modelLibraryOptionsToBeShown}
                        label="Model Library"
                        className="highlightLabel"
                        onOptionClick={(option) => handleModelLibraryChange(option.value)}
                        infoText="Indicates the library to use for pipeline. "
                        disabled={activeExecutionEnv === ExecutionEnvModes['Spark']}
                    />
                    <SelectField 
                        name="pipeline_category"
                        options={__pipelineCategoryOptions}
                        label="Model Category"
                        placeholder="<Select>"
                        // onOptionClick={handlePipelineCategoryClick}
                        onOptionClick={(option) => handlePipelineCategoryClick(option.value)}
                        className="highlightLabel"
                        infoText="Indicates the category of model."
                        ref={resetPipelineCategoryFieldRef}
                        required
                    />
                </div>
            </ShowWhenTrue>
            {renderInputTypeFields()}
            
            <ShowWhenTrue show={!(values.model_library === PythonModelLibraryOptions['Tensorflow'] && values[inputTypeFieldName] !== InputTypeOptions['Default (dataframes)'])}>
                <CaptureSchemaField 
                    fieldData={featuresFieldData}
                    captureSchemaOptions={captureSchemaOptions || []}
                    type="textarea"
                    rows={3}
                />
            </ShowWhenTrue>
            <ShowWhenTrue show={values.model_library === PythonModelLibraryOptions['Tensorflow']}>
                <ShowWhenTrue show={values[inputTypeFieldName] === InputTypeOptions['Default (dataframes)']}>
                    <CaptureSchemaField 
                        fieldData={labelColFieldData}
                        captureSchemaOptions={captureSchemaOptions || []}
                    />
                </ShowWhenTrue>
                <ShowWhenTrue show={values[inputTypeFieldName] === InputTypeOptions['Parquet folder']}>
                    <div className='useCaptureSchema'>
                        <Table fieldData={{
                            "key" : "train_data_filters",
                            "type": "table",
                            "defaultValue": "",
                            "templateOptions": {
                                "hideCaptureSchema":  true,
                                "variable_type": "string",
                                "label": "Train Data Filters",
                                "options": filterFormData,
                                "qtip": ""
                            }
                        }} />
                    </div>
                    <div className='useCaptureSchema'>
                        <Table fieldData={{
                            "key" : "validation_data_filters",
                            "type": "table",
                            "defaultValue": "",
                            "templateOptions": {
                                "hideCaptureSchema":  true,
                                "variable_type": "string",
                                "label": "Validation Data Filters",
                                "options": filterFormData,
                                "qtip": ""
                            }
                        }} />
                    </div>
                </ShowWhenTrue>
                <div
                    className="split__fields--halfwide"
                >
                    <ShowWhenTrue show={values[inputTypeFieldName] === InputTypeOptions['Image folder']}>
                        <SelectField 
                            name="color_mode"
                            options={colorModeOptionsForDropdown}
                            label="Color Mode"
                            infoText='Indicates the color mode to be used for image dataset.'
                        />
                    </ShowWhenTrue>
                    <ShowWhenTrue show={values[inputTypeFieldName] === InputTypeOptions['Parquet folder']}>
                        <RadioField 
                            name="dataset_type"
                            options={dataSetOptions}
                            label='Dataset type'
                            infoText='Specifies the type of data stored in parquet files.'
                        />
                        <ShowWhenTrue show={values['dataset_type'] === DataSet['Images']}>
                            <InputField 
                                name="image_column"
                                label="Image Column"
                                infoText='Specifies the image column in which raw bytes of images are stored.'
                            />
                            <InputField 
                                name="image_size"
                                label="Image Size"
                                infoText='Specifies the original size of each image in Input data.'
                            />
                            <RadioField 
                                name="auto_resize"
                                options={autoResizeOptions}
                                label="Auto Resize"
                                infoText='Indicates if auto resizing has to be done on input images. Images will get auto resized appropriately according to input shape of model if this option is enabled.'
                            />
                        </ShowWhenTrue>
                        
                        <ShowWhenTrue show={values['dataset_type'] === DataSet['Table']}>
                            <InputField 
                                name="features"
                                label="Columns"
                                infoText='Specifies the feature columns for training the model.'
                            />
                        </ShowWhenTrue>
                    </ShowWhenTrue>
                    
                    <InputField 
                        name="num_epochs"
                        label="Epochs"
                        validate={FieldSchemaValidator(numberFieldSchema)}
                        required
                        infoText='Specifies the epochs for model training.'
                    />
                    <InputField 
                        name="batch_size"
                        label="Batch Size"
                        validate={FieldSchemaValidator(numberFieldSchema)}
                        required
                        infoText='Specifies the batch size for model training. Default value is 32.'
                    />
                    <InputField 
                        name="train_steps_per_epoch"
                        label="Train Steps per epoch"
                        infoText='Optionally specifies the number of batches to be used in each epoch. This parameter is inferred automatically by default.'
                    />
                    <ShowWhenTrue show={values[inputTypeFieldName] !== InputTypeOptions['Parquet folder']}>
                        <InputField 
                            name="validation_steps_per_epoch"
                            label="Validation Steps per epoch"
                            infoText='Optionally specifies the number of batches to be used while doing validation after each epoch on validation dataset.'
                        />
                        <InputField 
                            name="seed"
                            label="Seed"
                            infoText="Optionally specifies the random seed for shuffling the input data."
                            type="number"
                        />
                    </ShowWhenTrue>
                  
                    <ShowWhenTrue show={values[inputTypeFieldName] === InputTypeOptions['Parquet folder']}>
                        <InputField 
                            name="label_column"
                            label="Label column"
                            infoText='Specifies the label column for training. A label column is often referred as a target column in industry.'
                        />
                        <ShowWhenTrue show={values.mode === ModeOptions['Fit and Transform Data']}>
                            <InputField 
                                name="prediction_col"
                                label="Prediction Column"
                            />
                            <InputField 
                                name="required_columns"
                                label="Required Columns"
                            />
                        </ShowWhenTrue>
                    </ShowWhenTrue>
                </div>
                <ShowWhenTrue show={values[inputTypeFieldName] === InputTypeOptions['Numpy array folder']}>
                    <CheckboxField 
                        name="use_generator"
                        options={useGeneratorOptions}
                        color="gold"
                        className={classNames({'checkbox__active': !!(values.use_generator)})}
                    />
                    {
                        values.use_generator ?
                            <CheckboxField 
                                name="balance_batches"
                                options={balanceBatchesOptions}
                                color="gold"
                                className={classNames({'checkbox__active': !!(values.balance_batches)})}
                            />
                            :  
                            <CheckboxField 
                                name="sample_from_datasets"
                                options={sampleFromDatasetsOptions}
                                color="gold"
                                className={classNames({'checkbox__active': !!(values.use_generator)})}
                            />
                    }
                   
                    <RadioField 
                        name="balance_mode"
                        options={balanceModeOptions}
                        infoText="Indicates if balancing has to done using undersampling of majority classes or oversampling of minority classes."
                        label='Balance Mode'
                        className='balanceMode__radioField'
                    />

                </ShowWhenTrue>
            </ShowWhenTrue>
            <div 
                className={
                    classNames('pipelineInfo__fields datacategory__fields',
                        {'hideDataCategory': !sparkMode}                    
                    )
                }>
                <ShowWhenTrue show={sparkMode}>
                    <SelectField 
                        name="data_category"
                        options={dataCategoryOptionsForDropdown}
                        className="highlightLabel"
                        label="Data Category"
                        infoText="Select category of data used in ML pipeline"
                        onOptionClick={handleDataCategoryChange}
                    />
                </ShowWhenTrue>
                {renderOutputPredictionColFields()}
            </div>
            <div className="inner_fields">
                {renderSplitForTestFields()}
                {/* {renderApplyWindowFields()} */}
                {renderValidateModelFields()}
            </div>

            
            <ShowWhenTrue show={values.model_library === SparkModelLibraryOptions['Spark']}>
                <CheckboxField 
                    name="encode_categ_features"
                    options={encodeCategoricalOptions}
                    color="gold"
                    className='checkbox__encodeCategFeatures'
                />
                <CheckboxField 
                    name="encode_categ_target"
                    options={encodeCategoricalTargetOptions}
                    color="gold"
                    className='checkbox__encodeCategFeatures'
                />
            </ShowWhenTrue>
            <ShowWhenTrue show={values.model_library === SparkModelLibraryOptions['Spark'] && values.mode === ModeOptions['Fit and Transform Data']}>
                <CheckboxField 
                    name="show_intermediate_values"
                    options={showIntermediateValuesOptions}
                    color="gold"
                    className='checkbox__encodeCategFeatures'
                />
            </ShowWhenTrue>
            {renderOutputFormat(buildModelMode && values.show_intermediate_values === 'true')}
        </>
    );
};

export  { FitAndFitTransformDataFields as BuildPipelineLHS };