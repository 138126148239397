import { ExecutionEnvModes } from '../../constants/enums';
import { workflowRunData } from '../../pages/workflow-page';
import { JobStates } from '../job-handler';

export interface ExecutionEnvType {
    env: ExecutionEnvModes;
}

export interface CreateWorkflow extends ExecutionEnvType {
    payload: string;
    details: string;
    projectName: string;
    status: true;
    jobtype?: 'DT';
    directoryId?: number;
    newDirectory?: string;
    config: string;
}

export interface UpdateWorkflow{
    projectName: string;
    payload: string;
    details: string;
    create_new_version: boolean;
    isStarred: boolean;
    notes: string;
    hasVariables: boolean;
    componentCounter: number;
    directoryId: number;
    env: ExecutionEnvModes;
    zepplinData: string | null;
    config: string;
}

export type WorkflowSortType = 'created' | 'updated';
export type WorflowImportType = 'directory' | 'workflow';

export interface WorkflowDirectoriesQueryParams extends ExecutionEnvType {
    sort: WorkflowSortType;
}


export interface WorkflowSearchQuery extends ExecutionEnvType { 
    page: number;
    size: number;
    user_id?: string;
    workflowSearch?: string;
    isStarred?: boolean;
    notes: string;
    directory_id?: number;
    sort?: WorkflowSortType;
    repo?: string
}

export type RunWorkflowData = {
    jars: string[];
    name: string;
    payload: workflowRunData;
    sparkVersion: '2.x';
    type: 'run' | 'preview';
    deployMode: string;
    files: string[];
    workflowId: number;
    advanceOptions?: {
        dynamicAllocation?: boolean;
        initialExecutors?: number;
        minExecutors?: number;
        maxExecutors?: number;
        driverMemory?: string;
        executorMemory?: string;
        driverCores?: number;
        executorCores?: number;
        noOfExecutors?: number;
    };
    additionalConfig?: Record<string, any>;
    // This key is for all streaming workflows in databricks
    streamingMode?: boolean;
    isStreamingWorkflow?: boolean;
    clusterId?: string;
    commandLineArgs: string[];
}

export interface SavedWorkflowInfo{
    id: number;
    projectName: string;
    jobtype?: 'DT';
    version: number;
    created: string;
    updated: string;
    createdBy: string;
    isStarred: boolean;
    notes: string;
    executable: boolean;
    readOnly: boolean;
    zeppelinNotebookId: string | null;
    componentCounter: number;
    directoryId: number;
    env: ExecutionEnvModes;
}

export interface CreateWorkflowResponse {
    success: boolean;
    message: string;
    data: SavedWorkflowInfo;
}

export interface UpdateWorkflowResponse {
    success: boolean;
    message: string;
    data: SavedWorkflowInfo;
}

export interface ZepplinWorkflowData  {
    id: string;
    index: number;
    isCustom: boolean;
    isDeepiq: boolean;
    isZeeplin: boolean;
    data?: string; 
}

export interface DetailedWorkflowInfo{
    success: boolean;
    data: (SavedWorkflowInfo & { 
        payload: string; 
        details: string; 
        zepplinData: ZepplinWorkflowData[];
        hasVariables: boolean;
        position: number;
        config: string;
    });
}

export interface WorkflowList{
    success: boolean;
    message: 'Returning all workflow without payload';
    data: SavedWorkflowInfo[];
    totalCount: number;
}

export interface DirectoryInfo {
    id: number;
    name: string;
    userId: string;
    readOnly?: boolean;
    workflowCount?: number;
}
export interface DirectoriesInfoResponse{
    success: boolean;
    message: string;
    data: DirectoryInfo[];
}

export interface CreateDirectoryResponse{
    success: boolean;
    message: string;
    data: Omit<DirectoryInfo, 'userId'> & { status: boolean };
}

export interface DownloadWorkflowResponse {
    script: string;
}

export type DownloadDagWorkflowResponse = Record<string,string>

export interface CronSearchQuery {
    mins?: number | '*';
    hrs?: number | '*';
    day?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | '*';
    weeknumber?: 1 | 2 | 3 | 4 | 5 | '*' | string;
    weekOfTheMonth?: 1 | 2 | 3 | 4 | 5 | '*';      
}

export interface CronSearchQueryResponse{
    success: boolean;
    message: string;
    data: {
        description: string;
        executionDates: string[];
    };
}

export interface WorkflowHistory {
    revision: {
        revisionId: number;
        version: number;
        revisionDate: string;
        isScheduled: boolean;
    };
    entity: DetailedWorkflowInfo['data'];
}

export interface DeleteWorkflows{
    ids: number[];
}

export interface ScheduleWorkflowData {
    jobtype: 'py_spark';
    // string
    weekOfMonth?: CronSearchQuery['weekOfTheMonth'];
    // number
    day: CronSearchQuery['day'];
    // string
    hrs: CronSearchQuery['hrs'];
    // string
    mins: CronSearchQuery['mins'];
	name: string;
	scheduleType: 'weekly' | 'hourly' | 'daily' | 'monthly';
	modeId: WorkflowHistory['entity']['id'];
	modeVersion: WorkflowHistory['entity']['version'];
	commandLine?: string;
	modeName?: '';
	commandNameAdditional?: string;
	deployMode: string;
    jarFilesList: string[] | '';
    sparkJAR: string;
    files: string;
    cronExpression?: string;
    retries: number;
    retryDelay: number;
    overrideNotificationEmail: boolean;
	notificationEmails: string;
    dependencies: string;
    // command is string of _command - json stringify the command and use it as command 
    command?: string;
    notificationType: string;
    duration: string | null;
    _command?: {
        showRunAdvance: boolean; 
        commandNameAdditional: string;
        deployMode: ScheduleWorkflowData['deployMode'];
        jarList: ScheduleWorkflowData['jarFilesList'];
        advance: {
            spark_dynamicAllocation_enabled: boolean;
            spark_dynamicAllocation_initialExecutors: string;
            spark_dynamicAllocation_minExecutors: string;
            spark_dynamicAllocation_maxExecutors: string;
            driver_memory: string;
            executor_memory: string; 
            driver_cores: string;
            executor_cores: string;
            num_executors: string;
        } | {};
        filepath: string;
        additionalConfig: Record<string, any> | null;
        commandLineArgs: string;
    };
    scheduleAdvancedMode: boolean;
    additionalConfig: string | null;
    commandLineArgs: string;   
}

export const PREVIEW_INITIALIZED = 'starting';
export const PREVIEW_RUNNING = 'running';
export const PREVIEW_SUCCESS = 'success';
export const PREVIEW_FAILED = 'failed';

export type PreviewComponentStatusTypes = typeof PREVIEW_INITIALIZED | typeof PREVIEW_RUNNING | typeof PREVIEW_SUCCESS | typeof PREVIEW_FAILED;

export interface PreviewStatus {
    id: number;
    sessionId: string;
    componentId: string;
    resultStatus: PreviewComponentStatusTypes;
    lastUpdatedTime: string;
    isSent: false;
    output: '';
} 

export type PreviewStatusResponse = ({ componentId: string; sessionId: string; state: PreviewComponentStatusTypes})[]

export interface OutputDataFormat {
    data: Record<string, any>[];
    schema: Record<'fields', {type: string; name: string}[]>;
}

export interface ComponentOutputTypes {
    output1: OutputDataFormat;
    summary1: OutputDataFormat;
    output2?:  OutputDataFormat;
    summary2?: OutputDataFormat;
}

export interface ComponentPreviewStatus extends Omit<PreviewStatus, 'output'>{
    // JSON.parse(output) = ComponentOutputTypes :
    output: null | string | OutputDataFormat;
}


export interface GetComponentOutputResponse {
    componentId: string;
    output: null | string | OutputDataFormat;
    sessionId: string;
    state: PreviewComponentStatusTypes;
}


export interface CreateProfile {
    command: string;
    name: string;
}

export interface ProfileInfo{
    id: number;
    command: string;
    name: string;
    deleted?: boolean;
    shareProfile: boolean;
    active: boolean;
    clusterName?: string;
}

export interface UpdateProfileInfo extends CreateProfile {
    shareProfile: true;
}

export interface GetProfilesResponse{
    success: boolean;
    message: string;
    data: ProfileInfo[];
    paging: {
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        pageNumber: number;
        pageSize: number;
        sortingCriteria: string;
        totalNumberOfPages: number;
        totalNumberOfRecords: number;
    };
}

export interface GetActiveProfile {
    success: boolean;
    message: string;
    data: ProfileInfo | false;
}

export type CaptureSchemaPayload = { payload: workflowRunData; clusterId?: string; removeLimit: boolean, workspaceId?:string; workspaceType?:string } & ExecutionEnvType;

export type DataframeType = {
    name: string;
    dtypes: string;
}

export interface CaptureSchemaResponse {
    dataframes: null | DataframeType[];
    componentId: string;
    filePath: string;
    id: string;
    jobId: string;
    state: JobStates;
}

export interface CloneWorkflowData {
    id: number;
    payload: string;
    details: string;
    directoryId?: number;
    config: string;
}

export interface SelectedProfileResponse{
    success: boolean;
    message: string;
    data: ProfileInfo;
}

export type ProfileSearchQuery = {
    name?: string;
    pageNumber?: number;
    pageSize?: number;
}

export type ClusterInfo = {
    label: string;
    value: number;
}

export type ClusterInfo1 = {
    clusterName: string;
    clusterId: number;
    active: boolean;
}

export type SyncJobResponse = {
    data: string[];
}

export type ShareWorkflowPostData = {
    'workflowIds': number[];
    'userIds': string[];
}

export type DownloadDirectoryData = {
    "directoryId": number;
    "env": string;
}

export type DagPostWorkflowInfo = workflowRunData & {
    dag_id: string;
    'description': string;
    'schedule_interval': string;
    'scheduleType': string;
    'start_date': string;
    'isDatabricks': boolean;
    'default_args': {
        'owner': 'Airflow';
        'depends_on_past': false;
        'email': string[];
        'email_on_failure': boolean;
        'email_on_retry': boolean;
        'retries': number;
        'retry_delay': number;
        execution_timeout: number | null;
    };

}

export type OpenDatabricksNotebookPayload = {
    name: string;
    sparkVersion: '2.x';
    env: ExecutionEnvModes;
    workflowId: number;
    type: 'run';
    payload: workflowRunData;
}

export interface RepositoriesList{ 
accessToken: string;
branch: string;
created: string;
createdBy: string;
deleted : boolean;
id : number
licenseId : string;
name: string;
platform : string;
updated : string;
updatedBy : string;
url : string;
username : string
directories? : any
isCloned: boolean
}