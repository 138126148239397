/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Radio, Tooltip, Checkbox } from "antd";
import FromClause from "./fromClause";
import SelectClause from "./selectClause";
import WhereClause from "./whereClause";
import GroupByClause from "./groupbyClause";
import indicator from "./classes/indicator";

const QueryBuilderUI = (props) => {
  const { db, query, updateQueryState, runPopupFix } = props;
  const [distinct, setDistinct] = useState(false);
  const [distinctDisabled, setDistinctDisabled] = useState(false);
  const deleteTabIndex = () => {
    [
      ...document.querySelectorAll("[data-react-beautiful-dnd-drag-handle]"),
    ].map((elem) => elem.removeAttribute("tabindex"));
  };
  useEffect(() => {
    if (query.geoSpatial.isGeoSpatialEnabled) {
      setDistinct(true);
      setDistinctDisabled(true);
      props.query.select.setDistinct(true);
      props.updateQueryState();
    } else {
      setDistinctDisabled(false);
    }
  }, [query.geoSpatial.isGeoSpatialEnabled]);
  return (
    <div
      id="querybuilder_holder"
      style={{
        marginLeft: "5px",
        marginRight: "5px",
        overflow: "auto",
        height: "490px",
        backgroundColor: "rgba(11, 14, 28, 0.48)",
      }}
    >
      <Row
        style={{
          border: "1px solid rgba(102, 106, 141, 0.32)",
          borderRadius: "4px",
          padding: "5px",
        }}
      >
        <Col span={24}>
          <div>
            <span id="fromText" style={{ color: "white", fontSize: "12px" }}>
              FROM
            </span>
            <Tooltip
              placement="top"
              title="The FROM clause is used to choose the required input dataframe(s)"
            >
              <img
                src="/icons/info-fields.png"
                width="16"
                height="16"
                style={{ marginLeft: "3px" }}
                className="info__icon"
                alt=""
              />
            </Tooltip>
            {query.geoSpatial.isGeoSpatialEnabled ? null : (
              <Tooltip placement="top" title="Adds more input data frame(s)">
                <Button
                  onClick={() => {
                    query.from.handleAddJoinRowClick(
                      query.from.fromJoinRows.length - 1
                    );
                    deleteTabIndex();
                    updateQueryState();

                    setTimeout(() => {
                      indicator.fromIndicator();
                    }, 100);
                  }}
                  shape="circle"
                  className="addButtonIcon"
                  style={{ marginLeft: "5px" }}
                  size="small"
                  icon="plus"
                />
              </Tooltip>
            )}
          </div>
          <div
            id="indicator_from"
            style={{ minHeight: "34px" }}
            className="query_indicator"
          ></div>
          <FromClause
            db={db}
            query={query}
            updateQueryState={updateQueryState}
            runPopupFix={runPopupFix}
          />
        </Col>
      </Row>
      <Row
        style={{
          border: "1px solid rgba(102, 106, 141, 0.32)",
          borderRadius: "4px",
          padding: "5px",
        }}
      >
        <Col span={24}>
          <div>
            <span id="selectText" style={{ color: "white", fontSize: "12px" }}>
              SELECT
            </span>
            <Tooltip
              placement="top"
              title="The SELECT clause is used to select the fields/columns required in the output dataframe from the input dataframe(s). To select all fields/columns use * "
            >
              <img
                src="/icons/info-fields.png"
                width="16"
                height="16"
                style={{ marginLeft: "3px" }}
                className="info__icon"
                alt=""
              />
            </Tooltip>

            <Tooltip
              placement="top"
              title="Add additional column functions or aggregrations"
            >
              <Button
                onClick={() => {
                  query.select.handleAddClick(
                    query.select.selectRows.length - 1
                  );
                  deleteTabIndex();
                  updateQueryState();
                  setTimeout(() => {
                    indicator.selectIndicator();
                  }, 100);
                }}
                shape="circle"
                className="addButtonIcon"
                style={{ marginLeft: 5 }}
                size="small"
                icon="plus"
              />
            </Tooltip>
            <Checkbox
              checked={distinct}
              disabled={distinctDisabled}
              style={{
                float: "right",
              }}
              onChange={(e) => {
                setDistinct(e.target.checked);
                props.query.select.setDistinct(e.target.checked);
                props.updateQueryState();
              }}
            >
              <span
                style={{
                  color: "white",
                }}
              >
                DISTINCT
              </span>
            </Checkbox>
          </div>
          <div
            id="indicator_select"
            style={{ minHeight: "34px" }}
            className="query_indicator"
          ></div>
          <SelectClause
            db={db}
            query={query}
            updateQueryState={updateQueryState}
          />
        </Col>
      </Row>
      <Row
        style={{
          border: "1px solid rgba(102, 106, 141, 0.32)",
          borderRadius: "4px",
          padding: "5px",
        }}
      >
        <Col span={24}>
          <div>
            <span id="whereText" style={{ color: "white", fontSize: "12px" }}>
              WHERE
            </span>
            <Tooltip
              placement="top"
              title="The WHERE clause is used to filter records. This clause is used to extract only those records that fulfill a specified condition. Example: WHERE start_date >= ’04-20-2010'  "
            >
              <img
                src="/icons/info-fields.png"
                width="16"
                height="16"
                style={{ marginLeft: "3px" }}
                className="info__icon"
                alt=""
              />
            </Tooltip>
            <Tooltip placement="top" title="Add more where clauses">
              <Button
                onClick={() => {
                  query.where.handleAddClick(query.where.whereRows.length - 1);
                  deleteTabIndex();
                  updateQueryState();
                  setTimeout(() => {
                    indicator.whereIndicator();
                  }, 100);
                }}
                shape="circle"
                className="addButtonIcon"
                style={{ marginLeft: "5px" }}
                size="small"
                icon="plus"
              />
            </Tooltip>
            <div
              className="whereoption"
              style={{ display: "inline-block", marginLeft: "10px" }}
            >
              <Radio.Group
                value={query.where.operator}
                onChange={(e) => {
                  query.where._modifyOperator(e.target.value);
                  updateQueryState();
                }}
              >
                <Radio.Button value="AND">AND</Radio.Button>
                <Radio.Button value="OR">OR</Radio.Button>
              </Radio.Group>
            </div>
          </div>
          <div
            id="indicator_where"
            style={{ minHeight: "34px" }}
            className="query_indicator"
          ></div>
          <WhereClause
            db={db}
            query={query}
            updateQueryState={updateQueryState}
          />
        </Col>
      </Row>
      <Row
        style={{
          border: "1px solid rgba(102, 106, 141, 0.32)",
          borderRadius: "4px",
          padding: "5px",
        }}
      >
        <Col span={24}>
          <div>
            <span id="groupbyText" style={{ color: "white", fontSize: "12px" }}>
              GROUP BY
            </span>
            <Tooltip
              placement="top"
              title="The GROUP BY statement groups rows that have the same values into summary rows. This statement is often used with aggregate functions (COUNT, MAX, MIN, SUM, AVG) to group the result-set by one or more columns "
            >
              <img
                src="/icons/info-fields.png"
                width="16"
                height="16"
                style={{ marginLeft: "3px" }}
                className="info__icon"
                alt=""
              />
            </Tooltip>
            <Tooltip placement="top" title="Add more group by clauses">
              <Button
                onClick={() => {
                  query.groupby.handleAddClick(
                    query.groupby.groupbyRows.length - 1
                  );
                  deleteTabIndex();
                  updateQueryState();

                  setTimeout(() => {
                    indicator.groupbyIndicator();
                  }, 100);
                }}
                shape="circle"
                className="addButtonIcon"
                style={{ marginLeft: "5px" }}
                size="small"
                icon="plus"
              />
            </Tooltip>
          </div>
          <div
            id="indicator_groupby"
            style={{ minHeight: "34px" }}
            className="query_indicator"
          ></div>
          <GroupByClause
            db={db}
            query={query}
            updateQueryState={updateQueryState}
          />
        </Col>
      </Row>
    </div>
  );
};

export default QueryBuilderUI;
