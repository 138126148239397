import { Modal } from '../../../components/modals';
import { object, string, InferType } from 'yup';
import React from 'react';
import Form, { InputField } from '../../../components/form';
import { toggleModal, setCustomComponentModalType, setCustomComponentUpdateId, setActiveUpdataParamter } from '../../../store/workflow';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/types';
import { NewComponentResponse, newComponentHandler } from '../../../api/new-custom-component';
import { errorAlert } from '../../../components/toastify/notify-toast';

const newComponentSchema = object().shape({
    component_name: string()
        .required('Component name is required.')
});

type newComponentSchema = InferType<typeof newComponentSchema>


interface NewComponentModalProps {
    handleCreateNewComponentSuccess: (arg0: NewComponentResponse) => void;
}

export const NewComponentModal: React.FC<NewComponentModalProps> = ({ handleCreateNewComponentSuccess }) => {
    const showModal = useSelector((store: RootState) => store.WorkflowReducer.showModal.newComponent);
    const dispatch = useDispatch();
    const { activeExecutionEnv } = useSelector((store: RootState) => store.CommonReducer);
    function toggleClose() {
        dispatch(toggleModal('newComponent', false));
    }

    function handleComponentSuccessResponse(response: NewComponentResponse) {
        handleCreateNewComponentSuccess(response);
        dispatch(setCustomComponentModalType('new'));
        dispatch(setCustomComponentUpdateId(0));
        dispatch(setActiveUpdataParamter(false));
        toggleClose();
    }
    function handleErrorCallback(error: any) {
        // toggleClose();
        errorAlert(`${error.response.data.message}. Try different name.`);
    }
    function handleCreateNewComponent(values: newComponentSchema) {
        newComponentHandler.getComponentNameValidation(values.component_name, activeExecutionEnv, handleComponentSuccessResponse, handleErrorCallback);
    }



    return (
        <Modal
            isOpen={showModal}
            toggleClose={toggleClose}
            className="new__workflow__modal"
            title="Create a Component"
        >
            <Form
                validationSchema={newComponentSchema}
                initialValues={{ component_name: '' }}
                onSubmit={handleCreateNewComponent}
            >
                <InputField
                    name="component_name"
                    label="Name your Component"
                    autoComplete="off"
                    autoFocus
                />
                <div className="modalBtns__box">
                    <button
                        id="component_name_submit"
                        className="fl-r btn-md btn-yellow"
                        type="submit"
                    >
                        Create
                    </button>
                    <button
                        id="component_name_cancel"
                        className="fl-r btn-md btn-cancel"
                        type="button"
                        onClick={toggleClose}
                    >
                        Cancel
                    </button>

                </div>


            </Form>

        </Modal>
    );
};