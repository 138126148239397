import { PreviewComponentStatusTypes, PREVIEW_INITIALIZED, PREVIEW_RUNNING, PREVIEW_SUCCESS, PREVIEW_FAILED } from '../../api/workflow-handler';

export enum PortTypes {
    IN = 'IN',
    OUT = 'OUT'
}

export const DS = {
    name: 'DS'
};

export const GraphTypes = {
    line: 'line',
    column: 'Column',
    pie: 'Pie',
    radial_bar: 'RadialBar'
};

export const AnalyticsTypes = {
    table: 'Table',
    customizePlot: 'Customize Plot'
};

export const OutputTypes = {
    output1: 'Output1',
    summary1: 'Summary1' 
};

export const previewStatusInfo: Record<PreviewComponentStatusTypes, {className: string; step: number}> = {
    [PREVIEW_INITIALIZED]: {className: 'status--initialized',  step: 1},
    [PREVIEW_RUNNING]: {className: 'status--running', step: 2},
    [PREVIEW_SUCCESS]: {className: 'status--success', step: 3},
    [PREVIEW_FAILED]: {className: 'status--failed', step: 4}
};

const twoPortsPositionGenerator = (positionNumber: number) => {
    // at x=-6px, port is just outside the component, 
    // at y=-2px, port is at the y axis center of node port
    // Add 2px to the y-coords below the center of default port
    // Subract -2px to the x-coords above the center of default port
    switch(positionNumber){
        case 0:
            return {x: -16, y: -12};
        case 1:
            return {x: -16, y: 15};
        default:
            return { x: 1, y: 1};
    }
};

const threePortsPositionGenerator = (positionNumber: number) => {
    switch(positionNumber){
        case 0:
            return {x: -9, y: -16};
        case 1:
            return {x: -20, y: 2};
        case 2:
            return {x: -9, y: 19};
        default:
            return { x: 1, y: 1};
    }
};

const fourPortsPositionGenerator = (positionNumber: number) => {
    switch(positionNumber){
        case 0:
            return {x: -9, y: -21};
        case 1:
            return {x: -20, y: -8};
        case 2:
            return {x: -20, y: 11};
        case 3:
            return {x: -9, y: 24};
        default:
            return { x: 1, y: 1};
    }
};

const fivePortsPositionGenerator = (positionNumber: number) => {
    switch(positionNumber){
        case 0:
            return {x: -11, y: -34};
        case 1:
            return {x: -36, y: -22};
        case 2:
            return {x: -44, y: 2};
        case 3:
            return {x: -35, y: 25};
        case 4:
            return {x: -10, y: 38};
        default:
            return { x: 1, y: 1};
    }
};

const sixPortsPositionGenerator = (positionNumber: number) => {
    switch(positionNumber){
        case 0:
            return {x: -11, y: -34};
        case 1:
            return {x: -30, y: -27};
        case 2:
            return {x: -42, y: -11};
        case 3:
            return {x: -42, y: 13};
        case 4:
            return {x: -29, y: 31};
        case 5:
            return {x: -10, y: 38};
        default:
            return { x: 1, y: 1};
    }
};

const sevenPortsPositionGenerator = (positionNumber: number) => {
    switch(positionNumber){
        case 0:
            return {x: -9, y: -35};
        case 1:
            return {x: -25, y: -29};
        case 2:
            return {x: -39, y: -16};
        case 3:
            return {x: -44, y: 2};
        case 4:
            return {x: -38, y: 20};
        case 5:
            return {x: -25, y: 32};
        case 6:
            return {x: -9, y: 38};
       
    }
};

const eightPortsPositionGenerator = (positionNumber: number) => {
    if(positionNumber >= 0 && positionNumber <= 6) 
        return sevenPortsPositionGenerator(positionNumber);
    else 
        return {x: -60, y: 2};
};

const ninePortsPositionGenerator = (positionNumber: number) => {
    if(positionNumber >= 0 && positionNumber <= 6) 
        return sevenPortsPositionGenerator(positionNumber);
    else 
        switch(positionNumber){
            case 7:
                return {x: -45, y: -34};
            case 8:
                return {x: -44, y: 38};
        }
};

const tenPortsPositionGenerator = (positionNumber: number) => {
    if(positionNumber >= 0 && positionNumber <= 6) 
        return sevenPortsPositionGenerator(positionNumber);
    else 
        switch(positionNumber){
            case 7:
                return {x: -45, y: -34};
            case 8:
                return {x: -60, y: 2};
            case 9:
                return {x: -44, y: 38};
        }
};

const elevenPortsPositionGenerator = (positionNumber: number) => {
    if(positionNumber >= 0 && positionNumber <= 6) 
        return sevenPortsPositionGenerator(positionNumber);
    else 
        switch(positionNumber){
            case 7:
                return {x: -22, y: -46};
            case 8:
                return {x: -45, y: -33};
            case 9:
                return {x: -45, y: 38};
            case 10:
                return {x: -21, y: 50};
        }
};


const twelvePortsPositionGenerator = (positionNumber: number) => {
    if(positionNumber >= 0 && positionNumber <= 6) 
        return sevenPortsPositionGenerator(positionNumber);
    else 
        switch(positionNumber){
            case 7:
                return {x: -22, y: -46};
            case 8:
                return {x: -45, y: -33};
            case 9:
                return {x: -60, y: 2};
            case 10:
                return {x: -45, y: 38};
            case 11:
                return {x: -21, y: 50};
        }
};




export const getPortPositionGenerator = {
    2: twoPortsPositionGenerator, 3: threePortsPositionGenerator,
    4: fourPortsPositionGenerator, 5: fivePortsPositionGenerator,
    6: sixPortsPositionGenerator, 7: sevenPortsPositionGenerator,
    8: eightPortsPositionGenerator, 9: ninePortsPositionGenerator, 
    10: tenPortsPositionGenerator, 
    11: elevenPortsPositionGenerator, 12: twelvePortsPositionGenerator
};

// d=""
export const pathConnectingPorts = {
    2: 'M80 10 Q 32 32 80 56',
    3: 'M80 10 Q 40 32 80 56',
    // 4: 'M50 4 Q 4 32 50 62',
    4: 'M80 4 Q 35 30 80 60',
    // 5: 'M50 4 C -9 4 -9 78 50 78',
    // 6: 'M50 4 C -9 4 -9 78 50 78',
    // 7: 'M50 4 C -9 4 -9 78 50 78',
    5: 'M80 21 C 45 15 -2 81 80 95',
    6: 'M80 21 C 45 15 -2 81 80 95',
    // 7: 'M80 4 C 40 -6 4 65 80 78',
    // 8: 'M80 4 C 40 -6 4 65 80 78',
    7: 'M80 21 C 45 15 -2 81 80 95',
    8: 'M80 21 C 45 15 -2 81 80 95',
    9: 'M80 21 C 45 15 -2 81 80 95',
    10: 'M80 21 C 45 15 -2 81 80 95',
    11: 'M80 21 C 45 15 -2 81 80 95',
    12: 'M80 21 C 45 15 -2 81 80 95'
};

