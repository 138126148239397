/*eslint-disable no-console */
import { AccountReducerState } from '@store/account';
import { throttle } from 'lodash';
import { store } from '..';
import { CanvasReducerState } from '../canvas';
import { CommonReducerState } from '../common';
import { RootState } from '../types';
import { WorkflowReducerState } from '../workflow';
import { saveReduxStateInLS } from './localstorage';
export * from './localstorage';

const stringifyMap = (t: Map<any, any>): string => JSON.stringify(Array.from(t));

type CanvasPageInfo = {
    activeTab: CanvasReducerState['workflowEditor']['activeTab'] | CanvasReducerState['analytics']['activeTab'];
    openTabs: string; 
}

export type PersistedReduxState = {
    CommonReducer: Pick<CommonReducerState, 'lastActiveWorkflowInfo' | 'activeExecutionEnv'>;
    WorkflowReducer: Pick<WorkflowReducerState, 'isPropertiesMinimized'>;
    CanvasReducer: CanvasReducerState;
    AccountReducer: Pick<AccountReducerState, 'activeUserInfo'>
}


type SerializedReduxState = Omit<PersistedReduxState, 'CanvasReducer'> & {
    CanvasReducer: Record<keyof CanvasReducerState, CanvasPageInfo>;
}

export const serializeReduxState = (state: RootState): string => {
    try {
        const { 
            CommonReducer: { lastActiveWorkflowInfo, activeExecutionEnv }, 
            CanvasReducer: { analytics, workflowEditor },
            WorkflowReducer: { isPropertiesMinimized },
            AccountReducer: { activeUserInfo }
        } = state;

        const canvasReducerState: SerializedReduxState['CanvasReducer'] = {
            analytics: {
                activeTab: analytics.activeTab,
                openTabs: stringifyMap(analytics.openTabs),
            },
            workflowEditor: {
                activeTab: workflowEditor.activeTab,
                openTabs: stringifyMap(workflowEditor.openTabs),
            },
        };
        // Save lastActiveWorkflowInfo and activeExecutionEnv info only
        const commonReducerState: SerializedReduxState['CommonReducer'] = {
            lastActiveWorkflowInfo,
            activeExecutionEnv
        };

        const workflowReducerState: SerializedReduxState['WorkflowReducer'] = {
            isPropertiesMinimized
        };

        const stateToBeSerialized: SerializedReduxState = {
            CanvasReducer: canvasReducerState,
            CommonReducer: commonReducerState,
            WorkflowReducer: workflowReducerState,
            AccountReducer:{ activeUserInfo }
        };

        return JSON.stringify(stateToBeSerialized);
    } catch (err) {
        console.log(err);
        return '';
    }
};


export const deserializeReduxState = (serializedString: string): PersistedReduxState | undefined => {
    try {
        const serializedState = JSON.parse(serializedString) as SerializedReduxState;
        const { 
            CanvasReducer: { analytics, workflowEditor },
            CommonReducer,
            WorkflowReducer,
            AccountReducer
        } = serializedState;

        // AccountReducer info is not loaded into the redux store - its only stored for reference

        const persistedState: PersistedReduxState = {
            CanvasReducer: {
                analytics: {
                    activeTab: analytics.activeTab as CanvasReducerState['analytics']['activeTab'],
                    openTabs: new Map(JSON.parse(analytics.openTabs)),
                },
                workflowEditor:{
                    activeTab: workflowEditor.activeTab as CanvasReducerState['workflowEditor']['activeTab'],
                    openTabs:new Map(JSON.parse(workflowEditor.openTabs)),
                }
            },
            CommonReducer,
            WorkflowReducer,
            AccountReducer
        };
        return persistedState;
    } catch (err) {
        console.log(err);
        return undefined;
    }
};

export const persistReduxState = (_store: typeof store) => {
    _store.subscribe(
        throttle(() => 
            saveReduxStateInLS(_store.getState())
        , 2500)
    );
};