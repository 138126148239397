import React from 'react';
import { Grid } from 'semantic-ui-react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import indicator from './classes/indicator';
import WhereRow from './whereRow';

const WhereClause = props => {
    const { query, updateQueryState, db } = props;

    const handleDraggableDrop = result => {
        document.body.classList.remove('editorTab');
        document.body.classList.remove('queryBuilder');
        if (result.destination) {
            const startIndex =
        result.destination.index < result.source.index
            ? result.destination.index
            : result.source.index;

            const endIndex =
        result.destination.index < result.source.index
            ? result.source.index
            : result.destination.index;

            query.where.handleDraggableDrop(
                result.source.index,
                result.destination.index,
                startIndex,
                endIndex
            );

            updateQueryState();
            setTimeout(()=>{
                indicator.whereIndicator();
            });
        }
    };

    const handleDraggableStart = ()=>{
        document.body.classList.add('editorTab');
        document.body.classList.add('queryBuilder');
    };

    return (
        <DragDropContext onBeforeDragStart={handleDraggableStart} onDragEnd={handleDraggableDrop}>
            <Droppable droppableId="droppableWhere">
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        <Grid.Column width={12}  id="whereHolder" style={{ width: '98%', padding: '5px 10px', margin: '10px', marginTop: 0 }}>
                            {query.where.whereRows.map((row, i) => {
                                return (
                                    <WhereRow
                                        key={`whereRow-${i}`}
                                        query={query}
                                        updateQueryState={updateQueryState}
                                        rowIndex={i}
                                        row={row}
                                        db={db}
                                    />
                                );
                            })}
                        </Grid.Column>
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default WhereClause;
