import { RasterConfig } from "@api/analytics-handler";
import { AddRasterPlusIcon } from "@pages/workflow-analytics-page/assets/icons";
import { useAnalyticsContext } from "@pages/workflow-analytics-page/useAnalyticsContext";
import classNames from "classnames";
import { useFormikContext } from "formik";
import React, { useCallback, useMemo, useRef } from "react";
import { PlotSelectionFormType, PlotSelectionModalProps } from ".";
import styles from "../../styles.module.scss";
import LoseUnSavedChangesPromptPlotModal from "./LoseUnSavedChangesPrompt";

type RasterTabsProps = Pick<
	PlotSelectionModalProps,
	| "handleAddNewRaster"
	| "setRasterSelectionInfo"
> & {
	showDiscardChangesPrompt: boolean;
	toggleDiscardChangesPrompt: (action: boolean) => void;
	isRasterFormUpdated: (formValues: PlotSelectionFormType) => boolean;
	isDataExplorer: boolean
};

const RasterTabs: React.FC<RasterTabsProps> = ({
	handleAddNewRaster,
	showDiscardChangesPrompt,
	toggleDiscardChangesPrompt,
	isRasterFormUpdated,
    setRasterSelectionInfo,
	isDataExplorer
}) => {
	const discardChangesPromptCb = useRef<null | Function>(null);

	const {
		initialDataForPlotSelection,
		rasterSelectionInfo,
	} = useAnalyticsContext();

	const handleShowDiscardChangesPrompt = (actionCb: () => void) => {
		toggleDiscardChangesPrompt(true);
		discardChangesPromptCb.current = actionCb;
	};

	const addNewRaster = (formValues: PlotSelectionFormType) => {
		const addNewRasterCb = () => {
			if (isDataExplorer || initialDataForPlotSelection?.graphIdentifier) {
				handleAddNewRaster(
					initialDataForPlotSelection?.graphIdentifier || ''
				);
			}
		};

		if (isRasterFormUpdated(formValues)) {
			handleShowDiscardChangesPrompt(addNewRasterCb);
		} else {
			addNewRasterCb();
		}
	};

	const onCancelUnSavedChangesPrompt = useCallback(() => {
		toggleDiscardChangesPrompt(false);
		discardChangesPromptCb.current = null;
	}, []);

	const onDiscardRasterChanges = () => {
		discardChangesPromptCb.current?.();
		onCancelUnSavedChangesPrompt();
	};

	const handleSelectOtherRasterTab = (
		formValues: PlotSelectionFormType,
		rasterConfig: RasterConfig
	) => {
		const onOtherRasterSelection = () => {
			if (initialDataForPlotSelection?.graphIdentifier)
				setRasterSelectionInfo(
					initialDataForPlotSelection.graphIdentifier,
					rasterConfig
				);
		};

		if (isRasterFormUpdated(formValues)) {
			handleShowDiscardChangesPrompt(onOtherRasterSelection);
		} else {
			onOtherRasterSelection();
		}
	};

	const rasterConfigTabs = useMemo(() => {
		if (
			initialDataForPlotSelection?.plot_type === "raster" &&
			initialDataForPlotSelection.rasterInfo?.raster_configs
		) {
			let multiBandRasterCounter = 1;
			return initialDataForPlotSelection.rasterInfo?.raster_configs.map(
				(rasterConfig) => {
					const label = rasterConfig.singleband
						? rasterConfig.y[0]
						: `Multi Band Raster ${multiBandRasterCounter}`;

					if (!rasterConfig.singleband) {
						multiBandRasterCounter += 1;
					}

					return {
						...rasterConfig,
						label,
					};
				}
			);
		}
		return undefined;
	}, [initialDataForPlotSelection, rasterSelectionInfo]);

	const { values } = useFormikContext<PlotSelectionFormType>();

	return (
		<>
			{!!rasterConfigTabs && (
				<div className={styles["rasterConfig__tabsOuter"]}>
					{rasterConfigTabs?.map((rasterConfig) => {
						const isActive =
							rasterConfig.id ===
							rasterSelectionInfo?.activeTabId;
						return (
							<div
								key={rasterConfig.id}
								className={classNames(
									styles["rasterConfig__tab"],
									{
										[styles[
											"rasterConfig__tab--active"
										]]: isActive,
									}
								)}
								onClick={() => {
									if (
										!isActive &&
										initialDataForPlotSelection
									) {
										handleSelectOtherRasterTab(
											values,
											rasterConfig
										);
									}
								}}
							>
								{rasterConfig.label}
							</div>
						);
					})}
					{rasterSelectionInfo?.type === "new" &&
					rasterSelectionInfo?.newRasterConfigInfo ? (
						<div
							className={classNames(styles["rasterConfig__tab"], {
								[styles["rasterConfig__tab--active"]]:
									rasterSelectionInfo?.activeTabId,
							})}
							onClick={() => {
								if (!rasterSelectionInfo?.activeTabId) {
									// setRasterSelectionInfo(rasterConfig)
								}
							}}
						>
							New Raster
						</div>
					) : (
						rasterConfigTabs.length === 1 && (
							<button
								id="modal-add-new-raster"
								onClick={() => addNewRaster(values)}
								type="button"
							>
								<AddRasterPlusIcon /> Add Raster
							</button>
						)
					)}
				</div>
			)}
			<LoseUnSavedChangesPromptPlotModal
				title={
					rasterSelectionInfo?.type === "edit" &&
					rasterSelectionInfo.config?.y
						? rasterSelectionInfo.config.y[0]
						: "New Raster"
				}
				showPrompt={showDiscardChangesPrompt}
				onCancel={onCancelUnSavedChangesPrompt}
				onDiscard={onDiscardRasterChanges}
			/>
		</>
	);
};

export default RasterTabs;
