import { WorkflowConfigItem, WorkflowConfig } from "@services/WorkflowConfig";
import { has, isEqual } from "lodash";
import { DsNodeModel } from "../../workflow-canvas/node/ds_nodemodel";
import {
	getComponentFieldsInfoUsingPropertiesContainerRef,
	getVarMappingInfo,
	getWorkflowConfigMappingInfo,
} from "./utils";

export type ComponentFieldsInfo = {
	label: string;
	id: string;
	hidden: boolean;
	fieldKey: string;
	type: "select" | "input";
};

export type ConnectedComponentFieldInfo = ComponentFieldsInfo & {
	selected: boolean;
};

export type ConnectedComponentFields = Record<
	string,
	// value is the key
	ConnectedComponentFieldInfo
>;

export type VariableComponentMappingInfo = {
	name: string;
	variableComponentId: string;
	connectedComponentFields: ConnectedComponentFields;
	linkId: string;
	isEditorType: boolean;
};

export type WorkflowConfigMappingInfo = WorkflowConfigItem & {
	connectedComponentFields: ConnectedComponentFields;
};

export type WorkflowConfigMappingInFormikContext = {
	selectedConfigKeys: string;
	keyFieldMapping: Record<
		// workflow config keys
		string,
		// component field keys
		string
	>;
	// component field keys
	selectedFieldKeys: string[];
};

type VariableReducerState = {
	variablesMappingInfo: VariableComponentMappingInfo[];
	workflowConfigMappingInfo: WorkflowConfigMappingInfo[];
	componentFieldsArr: ComponentFieldsInfo[];
};

type VariableReducerActions =
	| {
			type: "SET_COMPONENT_FIELDS_ARR";
			payload: {
				currentFormFieldsInfo: ComponentFieldsInfo[];
				propertiesFieldsContainerRef: HTMLDivElement;
				activeNodeInfo: DsNodeModel;
				variableMappingInFormikContext: any;
				isSourcePortAffected: boolean;
				workflowConfig: WorkflowConfig;
				workflowConfigMappingInFormikContext: WorkflowConfigMappingInFormikContext;
			};
	  }
	| {
			type: "SET_VARIABLE_MAPPING_INFO";
			payload: {
				activeNodeInfo: DsNodeModel;
				variableMappingInFormikContext: any;
				componentFieldsArr: ComponentFieldsInfo[];
			};
	  }
	| {
			type: "UPDATE_COMPONENT_FIELD_SELECTION_OF_VARIABLE_OR_WORKFLOW_CONFIG_ITEM";
			payload: {
				label: string;
				selectionType: "workflowConfig" | "variable";
				componentFieldId: string;
				isFieldSelected: boolean;
			};
	  }
	| {
			type: "SET_WORKFLOW_CONFIG_MAPPING_INFO";
			payload: {
				workflowConfig: WorkflowConfig;
				workflowConfigMappingInFormikContext: WorkflowConfigMappingInFormikContext;
				componentFieldsArr: ComponentFieldsInfo[];
			};
	  }
	| {
			type: "REMOVE_FIELD_SELECTION_OF_WORKFLOW_CONFIG_ITEM";
			payload: string;
	  };

export const variableFormStateReducerInitialState: VariableReducerState = {
	variablesMappingInfo: [],
	componentFieldsArr: [],
	workflowConfigMappingInfo: [],
};

export const variableFormStateReducer = (
	state: VariableReducerState,
	action: VariableReducerActions
): VariableReducerState => {
	switch (action.type) {
		case "SET_COMPONENT_FIELDS_ARR": {
			const {
				propertiesFieldsContainerRef,
				currentFormFieldsInfo,
				activeNodeInfo,
				variableMappingInFormikContext,
				isSourcePortAffected,
				workflowConfig,
				workflowConfigMappingInFormikContext,
			} = action.payload;
			const updatedFormFields = getComponentFieldsInfoUsingPropertiesContainerRef(
				propertiesFieldsContainerRef
			);
			// Refresh properties when component is selected
			// And variable is connected the component
			// TODO: Optimize
			const workflowConfigMappingInfo = getWorkflowConfigMappingInfo(
				workflowConfig,
				workflowConfigMappingInFormikContext,
				updatedFormFields
			);
			if (
				!isEqual(updatedFormFields, currentFormFieldsInfo) ||
				isSourcePortAffected
			) {
				const variablesMappingInfo = getVarMappingInfo(
					activeNodeInfo,
					variableMappingInFormikContext,
					updatedFormFields
				);
				return {
					...state,
					componentFieldsArr: updatedFormFields,
					variablesMappingInfo,
					workflowConfigMappingInfo,
				};
			} else {
				return { ...state, workflowConfigMappingInfo };
			}
		}
		case "SET_VARIABLE_MAPPING_INFO": {
			const {
				activeNodeInfo,
				variableMappingInFormikContext,
				componentFieldsArr,
			} = action.payload;
			const variablesMappingInfo = getVarMappingInfo(
				activeNodeInfo,
				variableMappingInFormikContext,
				componentFieldsArr
			);
			return { ...state, variablesMappingInfo };
		}
		case "UPDATE_COMPONENT_FIELD_SELECTION_OF_VARIABLE_OR_WORKFLOW_CONFIG_ITEM": {
			const {
				label,
				selectionType,
				componentFieldId,
				isFieldSelected,
			} = action.payload;

			if (selectionType === "workflowConfig") {
				const workflowConfigMappingInfo = state.workflowConfigMappingInfo.map(
					(workflowConfigItem) => {
						if (
							workflowConfigItem.key === label &&
							has(
								workflowConfigItem.connectedComponentFields,
								componentFieldId
							)
						) {
							workflowConfigItem.connectedComponentFields[
								componentFieldId
							].selected = isFieldSelected;
						}
						return workflowConfigItem;
					}
				);
				return { ...state, workflowConfigMappingInfo };
			} else {
				const variablesMappingInfo = state.variablesMappingInfo.map(
					(varMap) => {
						if (
							varMap.name === label &&
							has(
								varMap.connectedComponentFields,
								componentFieldId
							)
						) {
							varMap.connectedComponentFields[
								componentFieldId
							].selected = isFieldSelected;
						}
						return varMap;
					}
				);
				return { ...state, variablesMappingInfo };
			}
		}
		case "REMOVE_FIELD_SELECTION_OF_WORKFLOW_CONFIG_ITEM": {
			const workflowConfigMappingInfo = state.workflowConfigMappingInfo.map(
				(workflowConfigItem) => {
					if (workflowConfigItem.key === action.payload) {
						workflowConfigItem.connectedComponentFields = Object.keys(
							workflowConfigItem.connectedComponentFields
						).reduce((fieldConfig, fieldId) => {
							fieldConfig[fieldId] = { ...workflowConfigItem.connectedComponentFields[fieldId], selected: false}
							return fieldConfig
						}, {} as WorkflowConfigMappingInfo['connectedComponentFields'] );
					}
					return workflowConfigItem;
				}
			);
			return { ...state, workflowConfigMappingInfo };
		}
		case "SET_WORKFLOW_CONFIG_MAPPING_INFO": {
			const {
				workflowConfig,
				workflowConfigMappingInFormikContext,
				componentFieldsArr,
			} = action.payload;
			const workflowConfigMappingInfo = getWorkflowConfigMappingInfo(
				workflowConfig,
				workflowConfigMappingInFormikContext,
				componentFieldsArr
			);
			return { ...state, workflowConfigMappingInfo };
		}
	}
};
