import React, { useEffect, useRef, useState } from 'react';
import { InformativeIcon } from '@pages/workflow-page/assets/icons';
import classNames from 'classnames';
import styles from './../styles.module.scss';
import { useAppSelector } from '@store/hooks';
import Form, { SelectField } from '@components/form';
import { _selectoptionType } from '@components/form/select-field';
import { ClusterState } from '@api/databricks-handler';
import { Env } from '@constants/settings';
import { format } from 'sql-formatter';
import { Modal } from "@components/modals";
import { isEmpty } from 'lodash';
import { TableData } from '../types';
import { DataSourceHandler } from '@api/data-source-handler';
import { AiFillSetting } from 'react-icons/ai';
import { GoPlay } from 'react-icons/go';
import { RxLightningBolt } from 'react-icons/rx'
import { TooltipTop } from '@components/tooltips';
import { BsStopCircle } from 'react-icons/bs'
import { useDataExplorerContext } from '../useDataExplorerContext';
import TableContainer from './tableContainer';
import MoreInfo from './moreInfo';
import OpenAISettings from './openaiSettings';
import { infoAlert } from '@components/toastify/notify-toast';
import { useGenAIContext } from '../gen-ai/useGenAIContext';
import TableContainerGenAI from '../gen-ai/container/TableContainer';
import QueryContainer from './queryContainer';

export const aiModelQuery = [{
    label: "DeepQuery",
    value: "DeepQuery6"
}, {
    label: "GEN AI",
    value: "GENAI"
}, {
    label: "Demo",
    value: 'DEMO'
}];

const MiddleContainer: React.FC<{
    startDataFetch: (sqlData: string) => void,
    sqlDataError: string
    tableSchemaData: TableData[],
    fetchingData: boolean,
    cancelJob: () => void,
    clearError: any
}> = ({ startDataFetch, sqlDataError, tableSchemaData, fetchingData, cancelJob, clearError}) => {
    const { clusters } = useAppSelector((store) => store.ClusterReducer);
    const [clusterData, setClusterDropDown] = useState<_selectoptionType[]>([]);
    const [ queryGenerator, setQueryGenerator ] = useState<_selectoptionType[]>([]);
    const [isOpenMoreInfoModal,setIsOpenMoreInfoModal] = useState(false);
    const [showOpenAISettings,setOpenAISettingsView] = useState(false);
    const [sqlData, setSQLData] = useState("");
    const [sqlWarning, setSQLWarning] = useState("");
    const [sqlError, setSQLError] = useState("");
    const [loadingQuery, setLoadingQuery] = useState(false);
    const disable = useRef<boolean>(false);
    const [queryData, setQueryData] = useState("");
    const [ruleEngine, setRuleEngine] = useState("GENAI");
    const { database, gotData, setCluster, cluster, setEngine, setSQLQuery } = useDataExplorerContext();
    const { setResultMode, resultMode, setCygnetValue } = useGenAIContext()
    const openAiSettings = useRef<any>({
        temperature: 0,
        max_tokens: 1000,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
        best_of: 3
    });

    useEffect(() => {
        if(Env.showDropDownDEEPAI !== "ALL") {
            setRuleEngine(Env.showDropDownDEEPAI || 'GENAI');
            setEngine(Env.showDropDownDEEPAI as any || 'GENAI')
            if(Env.showDropDownDEEPAISubtType) {
                setResultMode(Env.showDropDownDEEPAISubtType)
            }
        }
    }, []);

    useEffect(() => {
        setSQLData("");
        setLoadingQuery(false);
        setQueryData("");
    }, [database.id]);

    const onCancelJob = async () => {
        if(!disable.current){
            cancelJob();
        }
    };

    const onGenerateQuery = async () => {
        try {
            if(queryData.trim().length === 0) {
                setQueryData("");
                setSQLError("Please enter a valid text to execute.");
                return infoAlert("Please enter a valid text to execute.");
            }
            if(queryData) {
                setLoadingQuery(true);
                setSQLError("");
                setSQLData("");
                setSQLWarning("")
                clearError();
                let sql = "";
                const error = "";
                if(ruleEngine === "DeepQuery6") {
                    const _queryData = database.databaseName === "postgres" ? queryData + ". Additionally, Include latitude and longitude columns. Enclose column names with double quotes": queryData;
                    const resp = await DataSourceHandler.GenerativeAINew(database.id, _queryData, {
                        check_whrecols: true,
                        if_diff: true,
                        df_cutoff_wval: 0.9,
                        if_diff_tables: false,
                        if_debug_rb: false,
                        use_graph: false,
                        fuzzy_cutoff: 80,
                        fuzzy_best_match_only: true,
                        db_type: 'SQLServer',
                        use_table_matches: true,
                        use_table_paths: true,
                        use_db_object_matches: true,
                        ...openAiSettings.current
                    });
                    if(resp.data.error) {
                        setSQLData('');
                        setSQLError(resp.data?.error?.message || 'Unable to parse your question. Please rephrase your question.');
                    } else {
                        const sqlKeyName = resp.data?.sql_to_use;
                        sql = format(resp.data[`${sqlKeyName}`]);
                        setSQLData(sql);
                        setSQLError("");
                        setSQLWarning(resp.data?.warning || '');
                    }
                }
                else if(ruleEngine === "DEMO") {
                    const resp = await DataSourceHandler.GenerativeAICygnet(database.id, queryData);
                    if(resp.data.error) {
                        setSQLData('');
                        setSQLError(resp.data?.error?.message || 'Unable to parse your question. Please rephrase your question.');
                    } else {
                        const sqlKeyName = resp.data?.sql_to_use;
                        sql = format(resp.data[`${sqlKeyName}`]);
                        setSQLData(sql);
                        setSQLError("");
                        setSQLWarning(resp.data?.warning || '');
                    }
                }
                setLoadingQuery(false);
                // saveResponseData(queryData, error, sql);
                return sql;
            } else {
                setSQLError("Please enter your text");
            }
        } catch(e) {
            setLoadingQuery(false);
            setSQLError("Unable to parse your question. Please rephrase your question.");
        }
    };

    const onDataGenerate = async () => {
        if(isEmpty((window as any).cluster || cluster) ) return infoAlert("Please select/start a cluster to proceed.");
        if(disable.current) return;
        clearError();
        setSQLError("");
        const sql:any = Env.enableDataExplorerSqlQueryView ? sqlData : await onGenerateQuery();
        startDataFetch(sql);
        setSQLQuery(sql)
        disable.current = true;
        setTimeout(() => {
            disable.current = false;
        }, 3000);
    };

    const onDataGenerateAI = async (sql: string, cluster1: string) => {
        if(isEmpty( (window as any).cluster || cluster1) ) return infoAlert("Please select/start a cluster to proceed.");
        if(disable.current) return;
        clearError();
        setSQLError("");
        startDataFetch(sql);
        setSQLQuery(sql)
        disable.current = true;
        setTimeout(() => {
            disable.current = false;
        }, 3000);
    };

    useEffect(() => {
        const options: _selectoptionType[] = [{
            label: "CHOOSE A CLUSTER",
            value: "choose_cluster"
        }];

        clusters.forEach(cluster => {
            if(cluster.npipWorkSpace && cluster.state === ClusterState['RUNNING']) {
                options.push({
                    label:cluster.clusterName,
                    value: cluster.clusterId
                })
            }
        });
        setClusterDropDown(options)
        setQueryGenerator(aiModelQuery);
    }, [clusters]);

    return (
        <div id="middle_container" className={styles["middle_container"]}>
            <div className={styles['mb-1']}>
                <div className={styles["space-between"]}>
                    <div className={styles["page_title"]}>Sources</div>
                    {Env.enableDataExplorerMoreInfo &&<div onClick={()=>setIsOpenMoreInfoModal(true)}>
                        <button className='align-middle'>
                            <InformativeIcon /> &nbsp; More Info
                        </button>
                    </div>}
                </div>
                <div className={styles["space-between"]}>
                    <div className={styles["subtitle"]}>{database.name}</div>
                    <div className={styles["flex-end"]} style={{alignItems: 'center', alignContent: 'center'}}>
                        { ruleEngine !== "GENAI" && Env.enableDataExplorerSqlQueryView &&<div className={styles["ml-1"]}>
                            <TooltipTop placement="topRight" overlay="Convert Text to Query" >
                                <button className='iconButton' onClick={onGenerateQuery}>
                                    <RxLightningBolt   />
                                </button>
                            </TooltipTop>
                        </div>}
                        {
                            ruleEngine !== "GENAI" || (ruleEngine === "GENAI" && resultMode === "spark") ? (
                                fetchingData ? (
                                    <div className={styles["ml-1"]}>
                                        <TooltipTop placement="topRight" overlay="Cancel running job" >
                                            <button className='iconButton' onClick={onCancelJob}>
                                                <BsStopCircle />
                                            </button>
                                        </TooltipTop>
                                    </div>) : (
                                    <div className={styles["ml-1"]}>
                                        <TooltipTop placement="topRight" overlay={Env.enableDataExplorerSqlQueryView?"Run query":"Translate & Run"}>
                                            <button className='iconButton' onClick={onDataGenerate}>
                                                <GoPlay />
                                            </button>
                                        </TooltipTop>
                                    </div>
                                )
                            ) : null
                        }
                        <div className={styles["ml-1"]}>
                            <Form
                                initialValues={{ cluster: 'choose_cluster', queryGenerator: 'GENAI', session: 'sql'}}
                                enableReinitialize
                                onSubmit={() => { 
                                    return; 
                                }}
                                className="flex"
                            >
                                {
                                    ruleEngine !== "GENAI" || (ruleEngine === "GENAI" && resultMode === "spark") ? (
                                        <SelectField
                                            name="cluster"
                                            options={clusterData}
                                            className={styles["mb-0"]}
                                            onOptionClick={(option) => {
                                                (window as any).cluster = option.value;
                                                setCluster(option.value)
                                            }}
                                        />
                                    ) : null
                                }
                                {
                                    ruleEngine === "GENAI" && !Env.showDropDownDEEPAISubtType ? (
                                        <div style={{ marginLeft: '1rem' }}>
                                            <SelectField
                                                name="session"
                                                options={[
                                                    {
                                                        label: 'SPARK',
                                                        value: 'spark'
                                                    },
                                                    {
                                                        label: 'NO SPARK',
                                                        value: 'sql'
                                                    }
                                                ]}
                                                className={styles["mb-0"]}
                                                onOptionClick={(option) => {
                                                    setResultMode(option.value)
                                                }}
                                            />
                                        </div>
                                    ) : null
                                }
                                {
                                    Env.showDropDownDEEPAI === "ALL" ? (
                                        <SelectField
                                            className={classNames(styles["ml-1"], styles["mb-0"])}
                                            name="queryGenerator"
                                            options={queryGenerator}
                                            onOptionClick={(option) => {
                                                if(option.value === "DEMO") {
                                                    setRuleEngine("GENAI");
                                                    setEngine("GENAI");
                                                    setCygnetValue(true)
                                                    return;
                                                }
                                                setRuleEngine(option.value);
                                                setEngine(option.value)
                                                setCygnetValue(false)
                                            }}
                                        />
                                    ) : null
                                }
                                {
                                    ["DeepQuery6", "GenerativeAI"].includes(ruleEngine) ? (
                                        <button className={classNames("iconButton", styles["ml-1"])} onClick={()=>{
                                            setOpenAISettingsView(true);
                                        }}>
                                            <AiFillSetting />
                                        </button>
                                    ) : null
                                }
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            <QueryContainer 
                ruleEngine={ruleEngine}
                sqlDataError={sqlDataError}
                tableSchemaData={tableSchemaData}
                queryData={queryData}
                setQueryData={setQueryData}
                sqlError={sqlError}
                sqlData={sqlData}
                setSQLData={setSQLData}
                loadingQuery={loadingQuery || fetchingData}
                sqlWarning={sqlWarning}
                onDataGenerate={onDataGenerateAI}
                onCancelJob={onCancelJob}
            />
            <div>
                {
                    sqlDataError && <div className={styles['error-text']}>{sqlDataError}</div>
                }
                {
                    loadingQuery || fetchingData? ( <div className={styles['error-text']} style={{color: 'white'}}>Loading... Please wait...</div>) : null
                }
                {
                    !(loadingQuery || fetchingData) && !sqlDataError && gotData && (ruleEngine !== "GENAI" || (ruleEngine === "GENAI" && resultMode === "spark")) && <TableContainer />
                }
                {
                    ruleEngine === "GENAI" && resultMode === "sql" ? <TableContainerGenAI  /> : null
                }
            </div>
            <Modal
				isOpen={isOpenMoreInfoModal}
				toggleClose={()=>setIsOpenMoreInfoModal(false)}
				title="Information"
                className={
                    styles['moreInfoModal']
                }
                showCloseMark
			>
                <MoreInfo/>
            </Modal>
            <Modal
                isOpen={showOpenAISettings}
                toggleClose={()=>{setOpenAISettingsView(false)}}
                title="Settings"
                subtitle=""
                className={
                    styles['moreInfoModal']
                }
                showCloseMark
            >
                <OpenAISettings 
                    value={openAiSettings.current}
                    visible={showOpenAISettings}
                    onSubmit={(val: any) => {
                        openAiSettings.current = val;
                        setOpenAISettingsView(false);
                    }}
                />
            </Modal> 
        </div>
    );
};



export default MiddleContainer;