import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useOutsideClickRef } from 'rooks';
import {debounce, cloneDeep, isEmpty} from 'lodash';
import {Icon} from 'antd';
import './styles.scss';
import classNames from 'classnames';

interface SeachProps{
    onBlur: Function;
    onSelect: Function;
    elem: React.RefObject<HTMLInputElement>;
    data: any;
    hideShow: boolean;
}

const Search = ({ value, setValue, valueFieldName, hideShow=false, disableSearchDropdown = false, ...props}: any) => {
    const ref = useRef<HTMLInputElement>(null);
    const [value1, setValue1] = useState('');
    const [data, setData] = useState<any>([]);
    const [showDetails, setShowDetails] = useState(false);
    useEffect(debounce(()=>{
        const arr = cloneDeep(props.schemaData);
        setValue && setValue(props.data || '');
        if(hideShow) {
            setValue1((props.data || '').replace('options.'));
        } else {
            setValue1(props.data || '');
        }
        if(!isEmpty(props.data)){
            const [d1, d2] = props.data.split('.');
            const results = arr.find((_arr: any)=>{
                return _arr.value === d1;
            });
            const result = results?.results?.find((_result: any)=>{
                return _result.title === d2;
            });
            if(result){
                result.isSelected = true;
            }
        }
        setData(arr);
    }, 250), [props.data, props.schemaData]);

    return (
        <>
            {/* IN VARIABLE DROPDOWN, KEY IS SHOWN AS LABEL, VALUE KEY IS SPECIFIED FOR IT TO UPDATE VARIABLE IN THE VALUE */}
            <input 
                ref={ref}
                type="text" 
                className={classNames('inputfield')} 
                value={value || value1}
                onChange={(e)=>{
                    if(setValue){
                        setValue(e.target.value);
                    } else {
                        props.onChange(e.target.value, props.index);
                    }
                    setValue1(e.target.value);
                    
                }}
                onClick={() => {
                    !disableSearchDropdown && setShowDetails(!showDetails);
                }}
                // "VALUE = KEY, valueFieldName = key of value"
                data-fieldlabelforvariable={!!(valueFieldName) && !!(value || value1) ?  (value || value1): ''}
                data-fieldkey={valueFieldName}
            />
            {
                !disableSearchDropdown && showDetails?
                    (
                        <Portal>
                            <SearchCategoryUI 
                                elem={ref}
                                data={data}
                                hideShow={hideShow}
                                onBlur={()=>{
                                    props.onChange(value || value1, props.index);
                                    setShowDetails(false);
                                }}
                                onSelect={(value: string, index1: number, index2: number)=>{
                                    props.onChange(value, props.index);
                                    setValue && setValue(value);
                                    setValue1(value);
                                    setShowDetails(false);
                                    const arr = cloneDeep(props.schemaData);
                                    arr[index1].results[index2].isSelected = true;
                                    setData(arr);
                                }}
                            />
                        </Portal>
                    ) : null
            }
        </>
    );
};

export const SearchCategoryUI = (props: SeachProps) => {
    const [data, setData] = useState([] as any);
    const [position, setPosition] = useState({
        X: 0,
        Y: 0
    });
    
    const [ref] = useOutsideClickRef(()=>{
        props.onBlur();
    });
    const hRef = useRef<HTMLDivElement>(null);

    const calcPosition = (props: any) => {
        let count = 0;
        for(let i=0;i<props.data.length;i++) {
            for(let j=0;j<props.data[i].results.length;j++) {
                count++;
                if(count >= 12){
                    break;
                }
            }
        }
        const rect: any = props.elem.current?.getBoundingClientRect() || {};
        if( ( rect.left + 285) >  window.innerWidth) {
            setPosition({
                X: rect.left + (window.innerWidth - (rect.left + 285)),
                Y: rect.top - (count >= 12 ? 300 : (count * 28)) - 5
            });
        }else {
            setPosition({
                X: rect.left,
                Y: rect.top - (count >= 12 ? 300 : (count * 28)) - 5
            });
        }
    };


    useEffect(()=>{
        window.addEventListener('resize', (e) => calcPosition(props));
    
        setData(props.data);
        calcPosition(props);
        return () => {
            window.removeEventListener('resize', (e) => calcPosition(props));
            
        };
    }, [props.data, props.elem]);

    const onSelect = (value: string, index1: number, index2: number) => {
        props.onSelect(value, index1, index2);
    };

    return (
        <div 
            ref={ref}
            className="deepiq-search"
            style={{
                left: position.X,
                top: position.Y
            }}
        >
            {
                (!Array.isArray(data) || data.length===0)? null : 
                    <div ref={hRef} style={{
                        maxHeight: 300,
                        overflow: 'auto',
                        width: '100%',
                        height: 'auto'

                    }}>
                        {
                            data?.map((elem: any, index1: number) => {
                                return (
                                    <div key={`cat_${elem.value}`} className="category">
                                        {
                                            props.hideShow ? null : <div className="name">{elem.name}</div>
                                        }
                                        <div className="results">
                                            {
                                                elem?.results.map((result: any, index2: number)=>{
                                                    return (
                                                        <div key={`result_${result.title}`} onClick={()=>{
                                                            if(props.hideShow) {
                                                                onSelect(`${result.title}`, index1, index2);
                                                            } else {
                                                                onSelect(`${elem.value}.${result.title}`, index1, index2);
                                                            }
                                                            
                                                        }} className="result">
                                                            {
                                                                result.isSelected? <Icon type="check" className="result-icon" /> : null
                                                            }
                                                            <div className="content">{result.title}</div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
            }
        </div>
    );
};

export const Portal: React.FC  = ({
    children
}) => {
    const [container] = useState(() => {
        const el = document.createElement('div');
        el.classList.add('deepiq-portal');
        return el;
    });
  
    useEffect(() => {
        document.body.appendChild(container);
        return () => {
            document.body.removeChild(container);
        };
    }, []);
  
    return ReactDOM.createPortal(children, container);
};

export default Search;