import React, { useMemo } from 'react';
import { UserDetails } from './user-details';
import { ShowWhenTrue } from '../../helpers';
import { useHistory } from 'react-router-dom';


export const Header: React.FC = () => {
    const history = useHistory();
    const currentUrl = history.location.pathname;

    const pageTitle = useMemo(() => {
        const _currentUrl = currentUrl.toLowerCase();
        if(_currentUrl.includes('filemanagement')) 
            return 'File Browser';
        else if(_currentUrl.includes('jobdashboard'))
            // return 'Job Dashboard';
            return '';
        else if(_currentUrl.includes('jobhistory'))
            return 'Job History';
        else if(_currentUrl.includes('mlloop'))
            // return 'Model Management';
            return '';
        else if(_currentUrl.includes('help'))
            return 'Help Center';
        else if(_currentUrl.includes('admin'))
            return 'Administrator';
        return '';
    }, [currentUrl]);

    return (
        <ShowWhenTrue show={!(currentUrl.includes('workflow') || currentUrl.includes('advancedanalytics') || currentUrl.includes('datasource/browser') || currentUrl.includes('tagbrowser') || currentUrl.includes('cluster') || currentUrl.includes('admin') || currentUrl.includes('filemanagement') || currentUrl.includes('dataexplorer'))}>
            <div className="pageHeader">
                <span className="pageTitle">
                    {pageTitle}
                </span>
                <div>
                    <UserDetails hideNameOnMinimize={false} />
                </div>
            </div>  
        </ShowWhenTrue>        
    );
};