import { ModalSummaryVisualizationProps } from './table-model-summary';
import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { analyticsDimensions } from './node_component';
import { ShowWhenTrue } from '../../../helpers';
// import { graphData } from '../../../constants/sample-graph';
import classNames from 'classnames';

interface ModalSummaryGraphTypes extends ModalSummaryVisualizationProps{
    modelSummarygraphType: 'pie' | 'bar' | 'gauge';
    analyticsDimensions: analyticsDimensions;
    propName?: string;
}


export const GraphsForModelSummary: React.FC<ModalSummaryGraphTypes> = ({ title, data, modelSummarygraphType,
    //  propName,
    //   analyticsDimensions 
}) => {

    const getBarGraphData = () => {
        const xAxisData = Object.keys(data);
        const seriesData = Object.values(data).map(_value => parseFloat(_value));
        return (
            {
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} : {c}'
                },
                xAxis: {
                    type: 'category',
                    data: xAxisData ,
                    axisLine: {
                        onZero: true,
                        show: true,
                        lineStyle: {
                            type: 'solid',
                            color: '#fff'
                        },
                        nameLocation: 'middle', offset: 3,nameTextStyle: { color: '#B0BAC9', fontWeight: 500 }
                    },
                    axisLabel: {
                        formatter: (x: string) => x.slice(0, 10)
                    }
                },
                yAxis: {
                    type: 'value',
                    nameLocation: 'middle', nameGap: 35, nameTextStyle: { color: '#B0BAC9', fontWeight: 500 },
                    axisLine: {
                        onZero: true,
                        show: true,
                        lineStyle: {
                            type: 'solid',
                            color: '#fff'
                        },
                    },
                },
                series: [{
                    data: seriesData,
                    type: 'bar',
                    itemStyle: {
                        color: '#00C896'
                    },
                    barWidth: '10px'
                }],
                toolbox: {
                    feature: {
                        saveAsImage: {
                            title: 'Download'
                        }
                    }
                }
            });
    };


    const getPieGraphData = () => {
        const graphData = Object.keys(data).map(name => ({ name, value: data[name], label:  {formatter: ({ name, value }: any) => `${name.slice(0, 15)}: ${value}`} }));

        return({
            // tooltip: {
            //     trigger: 'item',
            //     formatter: '{b} : {c} ({d}%)'
            // },
            toolbox: {
                feature: {
                    saveAsImage: {
                        title: 'Download'
                    }
                }
            },
            series: [
                {
                    // name: '姓名',
                    type: modelSummarygraphType,
                    radius: '55%',
                    center: ['50%', '50%'],
                    data: graphData,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        }

        );
    };

    const getGaugeData = () => {
        return ({
            tooltip: {
                formatter: '{a} <br/>{b} : {c}%'
            },
            toolbox: {
                feature: {
                    saveAsImage: {
                        title: 'Download'
                    }
                }
            },
            series: [
                {
                    // name: propName,
                    type: 'gauge',
                    min: 0,
                    max: 1,
                    detail: {formatter: '{value}', fontSize: 20},
                    data: [{value: data }],
                    axisLine: {   
                        lineStyle: {
                            color: [[0.20, 'rgb(194, 53, 49)'], [0.8, 'rgb(99, 134, 158)'], [1, 'rgb(145, 199, 174)']],
                        }
                    },
                    pointer: {
                        width: 5,
                    },
                    title: {color: 'white', fontWeight: '600'}
                }
            ]
        });
    };

    // SAFE = rgb(145, 199, 174)
    // UNSAFE = rgb(194, 53, 49)
    return(
        <div className={classNames({'gaugeGraphType': modelSummarygraphType === 'gauge'}, {'featureImportanceGraph': title === 'Feature importances' || title === 'Cluster Sizes'} )}>
            <span>{title}</span>
            <ShowWhenTrue show={modelSummarygraphType === 'pie'}>
                <ReactEcharts
                    // style={analyticsDimensions}
                    option={getPieGraphData()}
                />
            </ShowWhenTrue>
            <ShowWhenTrue show={modelSummarygraphType === 'bar'}>
                <ReactEcharts
                    // style={analyticsDimensions}
                    option={getBarGraphData()}
                />
            </ShowWhenTrue>
            <ShowWhenTrue show={modelSummarygraphType === 'gauge'}>
                <ReactEcharts
                    option={getGaugeData()}
                />
            </ShowWhenTrue>
        </div>

    );
};