import {
	WorkflowAnalyticsTabInfo,
	updateAWorkflowAnalyticsTabInfo,
	getTabType,
} from "../../store/canvas";
import {
	AnalyticsHandler,
	SubmitStatementData,
	CreateSessionData,
	SubmitPlotInfo,
	RasterConfig,
	SessionInfo as ApiSessionInfoResponse,
} from "../../api/analytics-handler";
import {
	convertWorkflowDataForExecution,
	WorkflowUserinfoForNodeData,
} from "../workflow-page/utils";
import { workflowRunData } from "../workflow-page";
import { isEmpty, isEqual, omit, range, set } from "lodash";
import { store } from "../../store";
import moment from "moment-mini";
import { WorkflowCanvas } from "../../components/workflow-canvas";
import { ExecutionEnvModes } from "../../constants/enums";
import { Env } from "../../constants/settings";
import { ColorValueRange } from "./Canvas/raster-plot";
import { PlotData, RasterColorConfigInfo } from "./Canvas";
import { getChromaRef } from "../../services/geo-tiff/utils";
import { BandType, ColorModes, PlotCreationErrorMessages } from "./enums";
import { roundNumberToString } from "../../utils";
import {
	MultiBandColumnsInfo,
	PlotSelectionFormType,
	RasterSelectionInfo,
} from "./modals/plot-selection";
import { uuid } from "uuidv4";
import { runOrPreviewWorkflowOnDatabricks } from "@store/workflow";
import { Cluster, ClusterState } from "@api/databricks-handler";
import { _selectoptionType } from "@components/form/select-field";
import { WorkflowConfig } from "@services/WorkflowConfig";

type SessionInfo = { sessionId: string; jobId: string; clusterId?: string, componentId: string };

export type SessionInfoTypes =
	| { type: "SESSION_ACTIVE"; response: SessionInfo }
	| { type: "SESSION_INACTIVE"; response: CreateSessionData }
	| { type: "SESSION_NOT_FOUND"; response: null };

// This class starts a session -> Submits the statement (preview data) -> Updates SessionId, PreviewStatmentId and DfList in openTabs Info
// Also checks if there's an existing session and updates dflist if the session is still active
export class AnalyticsSession {
	unsubscribe: any;
	private activeTabInfo: WorkflowAnalyticsTabInfo | null;
	activeExecutionEnv: ExecutionEnvModes;
	workflowUserInfo: WorkflowUserinfoForNodeData;
	databricksEnv: boolean;
	clusterId: string | undefined;
	workflowConfig: WorkflowConfig | undefined;
	constructor(
		activeExecutionEnv: ExecutionEnvModes,
		workflowUserInfo: WorkflowUserinfoForNodeData,
		clusterId: string | undefined,
		workflowConfig: WorkflowConfig | undefined
	) {
		this.activeTabInfo = null;
		this.activeExecutionEnv = activeExecutionEnv;
		this.workflowUserInfo = workflowUserInfo;
		this.databricksEnv = Env.databricks;
		this.workflowConfig = workflowConfig;
		this.clusterId = clusterId;
	}

	private setActiveTabInfo = (activeTabData?: WorkflowAnalyticsTabInfo) => {
		if(activeTabData) {
			this.activeTabInfo = activeTabData;
			return
		}
		const {
			activeTab,
			openTabs,
		} = store.getState().CanvasReducer.analytics;
		const activeTabInfo = openTabs.get(activeTab.id)?.info;
		if (activeTabInfo && !isEqual(activeTabInfo, this.activeTabInfo))
			this.activeTabInfo = activeTabInfo;
	};


	private handleUpdateTabInfo = (
		__info: Partial<WorkflowAnalyticsTabInfo>
	) => {
		if (
			this.activeTabInfo &&
			!isEqual(this.activeTabInfo, { ...this.activeTabInfo, ...__info })
		) {
			store.dispatch(
				updateAWorkflowAnalyticsTabInfo({
					type: getTabType("analytics", this.activeTabInfo.env),
					info: { ...this.activeTabInfo, ...__info },
				})
			);
		}
	};

	setClusterId = (clusterId: string | undefined) => {
		this.clusterId = clusterId;
	};

	updateStatementInfoIfDone = (activeTabData?: WorkflowAnalyticsTabInfo): Promise<
		boolean | PlotCreationErrorMessages
	> =>
		new Promise((resolve, reject) => {
			this.setActiveTabInfo(activeTabData);
			if (
				this.activeTabInfo?.sessionId &&
				this.activeTabInfo.previewStatementId
			) {
				const { sessionId, previewStatementId } = this.activeTabInfo;
				AnalyticsHandler.GetStatementInfo(sessionId, previewStatementId)
					.then((response) => {
						const { state, dfList } = response.data;
						if (state !== "waiting" && state !== "running") {
							if (state === "success") {
								this.handleUpdateTabInfo({ dfList });
								resolve(true);
							} else {
								reject(
									PlotCreationErrorMessages.NotAValidWorkflow
								);
							}
						} else {
							resolve(false);
						}
					})
					.catch((e) => {
						if (
							typeof e?.response?.data === "string" &&
							e.response.data
								.toLowerCase()
								.includes("statement not found")
						) {
							reject(PlotCreationErrorMessages.StatementNotFound);
						}
						reject(PlotCreationErrorMessages.StatementFailed);
					});
			} else {
				reject(PlotCreationErrorMessages.StatementFailed);
			}
		});

	private runStatementPolling = (activeTabData?: WorkflowAnalyticsTabInfo) =>
		new Promise<boolean | PlotCreationErrorMessages>((resolve, reject) => {
			const runStatementFunc = async () => {
				try {
					const isUpdateDone = await this.updateStatementInfoIfDone(activeTabData);
					if (isUpdateDone) {
						intervalId && clearInterval(intervalId);
						resolve(true);
					}
				} catch (e) {
					intervalId && clearInterval(intervalId);
					reject(e);
				}
			};
			runStatementFunc();
			const intervalId = setInterval(runStatementFunc, 3000);
		});

	createClouderaSession = async (
		data: CreateSessionData
	): Promise<string | null> => {
		try {
			const sessionInfoResponse = await AnalyticsHandler.StartSessionPromise(
				data
			);
			const sessionInfo = sessionInfoResponse.data;
			if (sessionInfo.sessionId) {
				this.handleUpdateTabInfo({
					sessionId: sessionInfoResponse.data.sessionId,
					previewStatementId: null,
				});
				return sessionInfo.sessionId;
			}
			// if (sessionInfo.state === "idle") {
			// 	const payload: workflowRunData = convertWorkflowDataForExecution(
			// 		WorkflowCanvas.model,
			// 		false,
			// 		this.activeExecutionEnv,
			// 		this.workflowUserInfo
			// 	);
			// 	const data: SubmitStatementData = {
			// 		sessionId: sessionInfo.sessionId,
			// 		payload,
			// 		env: this.activeExecutionEnv,
			// 	};
			// 	const statementInfo = await AnalyticsHandler.SubmitStatementPromise(
			// 		data
			// 	);
			// 	return {
			// 		sessionId: sessionInfo.sessionId,
			// 		jobId: statementInfo.data.jobId,
			// 	};
			// }
			return null;
		} catch {
			return null;
		}
	};

	private submitStatementData = async (_data?: any): Promise<boolean> => {
		try {
			if(_data) {
				const statementInfo = await AnalyticsHandler.SubmitStatementPromise(
					_data
				);
				this.handleUpdateTabInfo({
					previewStatementId: statementInfo.data.jobId,
				});
				return true;
			} else if (this.activeTabInfo?.sessionId) {
				const payload: workflowRunData = convertWorkflowDataForExecution(
					WorkflowCanvas.model,
					false,
					this.activeExecutionEnv,
					this.workflowUserInfo,
					this.workflowConfig
				);
				const data: SubmitStatementData = {
					sessionId: this.activeTabInfo.sessionId,
					payload,
					env: this.activeExecutionEnv,
				};
				if (this.clusterId) {
					data.clusterId = this.clusterId;
				}
				const statementInfo = await AnalyticsHandler.SubmitStatementPromise(
					data
				);
				this.handleUpdateTabInfo({
					previewStatementId: statementInfo.data.jobId,
				});
				return true;
			}
			return false;
		} catch (e) {
			/* eslint-disable no-console */
			console.log(e);
			return false;
		}
	};

	prepareDataForPlot = async (activeTabData?: WorkflowAnalyticsTabInfo, statementData?: any) => {
		this.setActiveTabInfo(activeTabData);
		const dataPrepState: {
			statementSubmitted: boolean;
			retrievedStatementInfo: boolean;
			error: PlotCreationErrorMessages | null;
			componentId: string;
		} = {
			statementSubmitted: false,
			retrievedStatementInfo: false,
			error: null,
			componentId: this.activeTabInfo?.activeComponentIdForAnalytics as string
		};
		if (!this.activeTabInfo?.previewStatementId) {
			// submits the statement if there's no statementid
			const isStatementSubmitted = await this.submitStatementData(statementData);
			if (isStatementSubmitted) {
				dataPrepState.statementSubmitted = true;
			} else {
				dataPrepState.error =
					PlotCreationErrorMessages.StatementSubmissionFailed;
				return dataPrepState;
			}
		}

		try {
			await this.runStatementPolling(activeTabData);
			dataPrepState.retrievedStatementInfo = true;
		} catch (e) {
			dataPrepState.error = e as any;
		}
		return dataPrepState;
	};

	createSession = async (data: CreateSessionData) => {
		// setActiveTabInfo is added here as it is not being initialized
		this.setActiveTabInfo();
		const sessionInfo = await this.createClouderaSession(data);
		const status = false;
		return { status, sessionInfo };
	};

	createDatabricksSession = () => {
		return new Promise<string>((resolve, reject) => {
			this.setActiveTabInfo();
			if (this.activeTabInfo?.id)
				store.dispatch(
					runOrPreviewWorkflowOnDatabricks("analytics", "preview", {
						activeTabId: this.activeTabInfo.id,
						successCbForPreview: async (sessionId: string) => {
							this.handleUpdateTabInfo({
								sessionId,
								previewStatementId: null,
							});
							resolve(sessionId);
						},
						errorCbForPreview: () => {
							reject();
						},
						clusterId: this.clusterId,
						showExecutionAlerts: false,
					}) as any
				);
		});
	};

	private removeEmptyStringsAndNullKeys = (obj: Record<string, any>) => {
		const updatedObj: Record<string, any> = {};
		Object.entries(obj).forEach(([key, value]) => {
			if (value !== null && value !== "") updatedObj[key] = value;
		});
		return updatedObj;
	};

	private getSessionInfo = (
		sessionId: string
	): Promise<ApiSessionInfoResponse> => {
		return new Promise((resolve, reject) => {
			AnalyticsHandler.GetSessionInfo(sessionId)
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => {
					reject(err.response.data);
				});
		});
	};

	checkIfActiveTabSessionIsActive = async (
		showAlerts = false,
		tabInfoData?: WorkflowAnalyticsTabInfo
	): Promise<SessionInfoTypes> => {
		/* eslint-disable-next-line no-async-promise-executor */
		return new Promise<SessionInfoTypes>(async (resolve, reject) => {
			// setActiveTabInfo is added here as activeTabInfo is not updated properly when used in initialize
			this.setActiveTabInfo(tabInfoData);

			const resolveProm = (sessionState: SessionInfoTypes) => {
				// session state is updated in the active tab info
				this.handleUpdateTabInfo({
					sessionState
				})
				resolve(sessionState)
			}


			if (
				this.activeTabInfo?.sessionId &&
				this.activeTabInfo.previewStatementId
			) {
				let sessionInfo: ApiSessionInfoResponse | null = null;
				try {
					// When a workflow is opened from preview state in workflow page
					sessionInfo = await this.getSessionInfo(
						this.activeTabInfo.sessionId
					);
				} catch (err) {
					resolveProm({ type: "SESSION_NOT_FOUND", response: null });
				}

				let paramsForCreatingSession: CreateSessionData | null = null;

				if (
					((Env.databricks && sessionInfo?.state === "Running") ||
						sessionInfo?.state === "idle") &&
					!!this.activeTabInfo?.sessionId &&
					!!this.activeTabInfo.previewStatementId &&
					(Env.databricks
						? !!this.activeTabInfo.activeClusterIdForPreview
						: true)
				) {
					// session is still active
					const activeSessionInfo: SessionInfo = {
						sessionId: this.activeTabInfo.sessionId,
						jobId: this.activeTabInfo.previewStatementId,
						clusterId: this.activeTabInfo.activeClusterIdForPreview,
						componentId: this.activeTabInfo.activeComponentIdForAnalytics as string
					};
					resolveProm({
						type: "SESSION_ACTIVE",
						response: activeSessionInfo,
					});
				} else if (sessionInfo) {
					// session is dead but parameters needed for creating session are present
					paramsForCreatingSession = omit(
						sessionInfo,
						"createdDate",
						"log",
						"sessionId",
						"state",
						"userId",
						"livySessionId",
						"params"
					);

					if (sessionInfo?.additionalConfig) {
						// additionalConfig is saved as "{key1=value2,key2=value2,key3=value3}"
						const __additionalConfig: Record<string, string> = {};
						sessionInfo.additionalConfig
							.replace("{", "")
							.replace("}", "    ")
							.split(",")
							.forEach((dict: string) => {
								const dictInfo = dict.split("=");
								if (dictInfo.length === 2) {
									__additionalConfig[dictInfo[0]] =
										dictInfo[1];
								}
							});
						paramsForCreatingSession.additionalConfig = __additionalConfig;
					}

					paramsForCreatingSession = this.removeEmptyStringsAndNullKeys(
						paramsForCreatingSession
					) as CreateSessionData;
					paramsForCreatingSession.name =
						this.activeTabInfo.name + " - " + moment().toString();
					resolveProm({
						type: "SESSION_INACTIVE",
						response: paramsForCreatingSession,
					});
				} else {
					resolveProm({ type: "SESSION_NOT_FOUND", response: null });
				}
			} else {
				resolveProm({ type: "SESSION_NOT_FOUND", response: null });
			}
		});
	};
}

export const getRasterColorValuesRange = (
	{
		minValue,
		maxValue,
		valueRange,
	}: { minValue: number; maxValue: number; valueRange: number },
	rasterColorConfig: RasterColorConfigInfo
) => {
	const colorValuesRange: ColorValueRange[] = [];
	const chromaScale = getChromaRef(rasterColorConfig);

	const isContinousType =
		rasterColorConfig.colorMode === ColorModes.Continous;
	const numberOfIntervals = isContinousType
		? 5
		: rasterColorConfig.noOfClasses;

	const interval = valueRange / numberOfIntervals;

	range(0, numberOfIntervals).forEach((valuePosition) => {
		const currentValue = minValue + interval * valuePosition;

		const nextValue = minValue + interval * (valuePosition + 1);

		// ranges are added if its not continous type
		// valuePosition < numberOfIntervals is to skip the last value
		if (
			isContinousType ||
			(!isContinousType && valuePosition < numberOfIntervals)
		) {
			colorValuesRange.push({
				label:
					roundNumberToString(currentValue, 3) +
					(!isContinousType
						? ` - ${roundNumberToString(nextValue, 3)}`
						: ""),
				color: chromaScale(
					(currentValue - minValue) / valueRange
				).hex(),
			});
		}
	});

	if (isContinousType) {
		colorValuesRange.push({
			label: roundNumberToString(maxValue,3),
			color: chromaScale(maxValue).hex(),
		});
	}

	return colorValuesRange;
};

export const getMultiRasterColorValuesRange = (rasterConfig: RasterConfig) => {
	const colorValuesRange: ColorValueRange[] = [
		{
			label: rasterConfig.y[0] + " (Red)",
			color: "rgb(255,0,0)",
		},
		{
			label: rasterConfig.y[1] + " (Green)",
			color: "rgb(0,128,0)",
		},
		{
			label: rasterConfig.y[2] + " (Blue)",
			color: "rgb(0,0,255)",
		},
	];

	// if (
	//     valuePosition < numberOfIntervals)
	//     {
	//     colorValuesRange.push({
	//         label: roundNumberToString(currentValue, 3),
	//         color: (currentValue - minValue) / valueRange

	//     });
	// }

	return colorValuesRange;
};

type SubmitPlotFormData = {
	sessionId: string;
	keys: string[];
	values: string[];
	seriesGroupings: string[];
	multiBandCols: MultiBandColumnsInfo;
	selectedRasterConfig?: RasterConfig;
	rasterSelectionInfo: RasterSelectionInfo | null;
	currentRasterConfigs?: RasterConfig[];
};

export const getSelectedRasterConfigInfo = (
	currentRasterConfigs: RasterConfig[],
	rasterSelectionInfo: RasterSelectionInfo
) => {
	let selectedRasterConfig: RasterConfig | undefined = undefined;
	let isNewRasterPlot = false;
	if (
		rasterSelectionInfo?.type === "edit" &&
		rasterSelectionInfo.config?.id
	) {
		selectedRasterConfig = currentRasterConfigs.find(
			(config) => config.id === rasterSelectionInfo.config?.id
		);
	} else {
		isNewRasterPlot = true;
	}

	return { selectedRasterConfig, isNewRasterPlot };
};

export const getSubmitPlotPayloadFromPlotSelectionForm = (
	formValues: PlotSelectionFormType,
	{
		sessionId,
		keys,
		values,
		seriesGroupings,
		multiBandCols,
		rasterSelectionInfo,
		currentRasterConfigs,
	}: SubmitPlotFormData
): SubmitPlotInfo => {
	let plotInfo: SubmitPlotInfo = {
		df: formValues.connectedComponentInfo.output,
		output_key: {
			workflow_session_id: sessionId,
			component_id: formValues.connectedComponentInfo.component_id,
			target_component_id:
				formValues.connectedComponentInfo.target_component_id,
		},
		x: keys[0],
		y: values,
		groupby_cols: seriesGroupings,
		aggregate_type: formValues.aggregate_type,
		plot_type: formValues.plot_type,
	};
	if (
		formValues.plot_type === "map" ||
		formValues.plot_type === "geospatial" ||
		formValues.plot_type === "raster"
	) {
		plotInfo = omit(plotInfo, "x", "groupby_cols");
		if (formValues.plot_type === "raster") {
			let selectedRasterConfig: RasterConfig | undefined = undefined;
			if (currentRasterConfigs && rasterSelectionInfo) {
				selectedRasterConfig = getSelectedRasterConfigInfo(
					currentRasterConfigs,
					rasterSelectionInfo
				).selectedRasterConfig;
			}
			plotInfo.projection_code = formValues.projection_code;
			let yCol: string[] = [];
			if (formValues.bandType === BandType.Multi) {
				yCol = [
					multiBandCols.red[0],
					multiBandCols.green[0],
					multiBandCols.blue[0],
				].filter((t) => !!t);
			} else {
				yCol = [seriesGroupings[0]];
			}
			const rasterId = selectedRasterConfig?.id || uuid();
			const rasterConfigs: RasterConfig[] = currentRasterConfigs || [];
			const currentRasterConfig = {
				id: rasterId,
				singleband: formValues.bandType === BandType.Single,
				latitude: keys[0],
				longitude: values[0],
				y: yCol,
				resolution:formValues.resolution,
				mode: formValues.mode,
				min_lat: formValues.mode === "custom" ? formValues.min_lat : "",
				max_lat: formValues.mode === "custom" ? formValues.max_lat : "",
				min_lon: formValues.mode === "custom" ? formValues.min_lon : "",
				max_lon: formValues.mode === "custom" ? formValues.max_lon : "",
			};

			if (selectedRasterConfig) {
				const selectedRasterConfigIndex = rasterConfigs.findIndex(
					(config) => config.id === rasterId
				);
				if (selectedRasterConfigIndex !== -1) {
					rasterConfigs[
						selectedRasterConfigIndex
					] = currentRasterConfig;
				}
			} else {
				rasterConfigs.push(currentRasterConfig);
			}

			plotInfo.raster_info = {
				raster_id: rasterId,
				raster_configs: rasterConfigs,
			};

			if (rasterSelectionInfo?.statementId) {
				// statementId is the submit-plot statement id of the raster plot that's already added or an existing raster plot
				// statement_id_for_older_tiff_file_path is included to get the reference
				// of first raster's file path in pytrans
				set(
					plotInfo.raster_info,
					"statement_id_for_older_tiff_file_path",
					rasterSelectionInfo?.statementId
				);
			}
		} else if (formValues.plot_type === "geospatial") {
			plotInfo.geometries = seriesGroupings;
			plotInfo.y = [];
		} else {
			plotInfo.y = seriesGroupings;
		}
	} else if (formValues.plot_type === "correlation") {
		// Backend takes all the columns list from df
		plotInfo.plot_type = "heatmap";
	} else if (formValues.plot_type === "boxplot") {
		plotInfo.y = keys;
	}
	if (formValues.additional_params) {
		plotInfo.additional_params = formValues.additional_params;
	}
	return plotInfo;
};

export const isRasterUpdateFormTouched = (
	initialRasterConfig: RasterConfig,
	currentValues: {
		keys: string[];
		values: string[];
		seriesGroupings: string[];
		multiBandCols: MultiBandColumnsInfo;
	}
) => {
	const { keys, values, seriesGroupings, multiBandCols } = currentValues;
	if (
		initialRasterConfig.latitude !== keys[0] ||
		initialRasterConfig.longitude !== values[0]
	) {
		return true;
	} else if (initialRasterConfig.singleband) {
		return !isEqual(initialRasterConfig.y, seriesGroupings);
	} else {
		return !isEqual(initialRasterConfig.y, [
			multiBandCols.red[0],
			multiBandCols.green[0],
			multiBandCols.blue[0],
		]);
	}
};

export const isRasterNewFormTouched = (
	bandType: BandType,
	currentValues: {
		keys: string[];
		values: string[];
		seriesGroupings: string[];
		multiBandCols: MultiBandColumnsInfo;
	}
) => {
	const { keys, values, seriesGroupings, multiBandCols } = currentValues;

	if (!isEmpty(keys) || !isEmpty(values)) {
		return true;
	}

	if (bandType === BandType.Single) {
		return !isEmpty(seriesGroupings);
	} else {
		return (
			!isEmpty(multiBandCols.red) ||
			!isEmpty(multiBandCols.green) ||
			!isEmpty(multiBandCols.blue)
		);
	}

	return false;
};


export const checkIfClusterCanRunPlots = (
	clusters: Cluster[],
	clusterId: string
) => {
	const clusterState: { valid: boolean; error: string | null } = {
		valid: false,
		error: null,
	};
	const clusterOfOldSession = clusters.find(
		(cluster) => cluster.clusterId === clusterId
	);
	// cluster should exist and should be in running state
	if (
		clusterOfOldSession?.npipWorkSpace &&
		clusterOfOldSession.state === ClusterState.RUNNING &&
		clusterOfOldSession.workspaceType === 'databricks'
	) {
		clusterState.valid = true;
	}else if(clusterOfOldSession?.state === ClusterState.RUNNING &&
		clusterOfOldSession.workspaceType === 'cloudera') {
		clusterState.valid = true;
	} else {
		let error = "";
		if (clusterOfOldSession)
			if (!clusterOfOldSession?.npipWorkSpace) {
				error =
					clusterOfOldSession.clusterName +
					" is not a private cluster";
			} else if (clusterOfOldSession.state !== ClusterState.RUNNING) {
				error =
					clusterOfOldSession.clusterName +
					" is not in running state";
			} else {
				error = "Cluster doesn't exist";
			}
		clusterState.error = error;
	}
	return clusterState;
};

export const validateNewPlotTypeForColumnReset = (
	oldPlotType: PlotData["plotType"],
	newPlotType: PlotData["plotType"]
) => {
	const mapPlots: Partial<PlotData["plotType"]>[] = [
		"raster",
		"map",
		"geospatial",
	];
	const nonMapPlots: Partial<PlotData["plotType"]>[] = [
		"scatter",
		"area",
		"bar",
		"line",
		"heatmap",
		"boxplot",
	];
	return (
		newPlotType === "correlation" ||
		(mapPlots.includes(oldPlotType) && nonMapPlots.includes(newPlotType)) ||
		(nonMapPlots.includes(oldPlotType) && mapPlots.includes(newPlotType))
	);
};

export const getConnectedComponentsListOfComponent = (
	componentId: string,
	activeTabInfo: WorkflowAnalyticsTabInfo | undefined
): _selectoptionType[] => {
	const connectedComponentsList: _selectoptionType[] = [];

	if (activeTabInfo) {
		activeTabInfo?.dfList.forEach((dfInfo) => {
			const activeNode = WorkflowCanvas.getNode(componentId);
			if (activeNode) {
				if (dfInfo.component_id === componentId) {
					const portConnectedToTargetComponent = activeNode
						.getOutPorts()
						.find((outPort) =>
							outPort.getLinksInArray().find(
								(outLink) =>
									outLink
										.getTargetPort()
										.getParent()
										.getID() ===
										dfInfo.target_component_id ||
									outLink
										.getSourcePort()
										.getParent()
										.getID() === dfInfo.target_component_id
							)
						);
					// first port in the component - OUT0
					// second port in the component - OUT1
					const portNumber = portConnectedToTargetComponent
						?.getOptions()
						.name.split("-")[1];
					const label = portNumber
						? "Output" + (parseInt(portNumber) + 1)
						: WorkflowCanvas.getNodeTitle(
								dfInfo.target_component_id
						  );
					connectedComponentsList.push({
						label,
						value: dfInfo,
					});
				}
			}
		});
	}

	return connectedComponentsList;
};
