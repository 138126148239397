import { Flex } from "@components/ui/Flex";
import { Text } from "@components/ui/Text";
import { Checkbox } from "antd";
import moment from "moment-mini";
import React from "react";
import { Icons } from "./Icons";
import { File, FileExplorerProps } from "./types";
import { ShowWhenTrue } from "@helpers/showwhentrue";

type Props = {
	file: File;
	onClick: (event: React.MouseEvent, file: File) => void;
	onDoubleClick: (file: File) => void;
	onContextMenu: (file: File) => void;
	isSelected: boolean;
	enableMultiSelect?:boolean
} & Pick<FileExplorerProps, 'view'>;

export const FileItem = ({
	file,
	isSelected,
	onClick,
	onDoubleClick,
	onContextMenu,
	view,
	enableMultiSelect
}: Props) => {
	const Icon = file.isDir
		? Icons.folder
		: Icons[file.type || "unknown"] || Icons.unknown;

	const getFileSize = (bytes: number) => {
		const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
		if (bytes === 0) return "0 Byte";
		const i = parseInt(
			Math.floor(Math.log(bytes) / Math.log(1024)).toString(),
			10
		);
		return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
	};

	return (
		<tr
			className={isSelected ? "selected" : ""}
			onClick={(e) =>  enableMultiSelect? !file.isDir  && onClick(e, file):onClick(e, file)}
			onDoubleClick={() => onDoubleClick(file)}
			onContextMenu={() => onContextMenu(file)}
		>
			<td>
				<Flex>
					<ShowWhenTrue show={!file.isDir && !!enableMultiSelect}>
						<Checkbox
							checked={isSelected}
						/>
					</ShowWhenTrue>
					<Flex flex={1} ml={2}>
						<Icon />
						<Text pl="10px" lineBreak="anywhere">
							{file.name}
						</Text>
					</Flex>
				</Flex>
			</td>
			{view === "Table" && (
				<>
					<td>
						{file.type && (
							<Text textTransform="uppercase">.{file.type}</Text>
						)}
					</td>
					<td>{file.size && getFileSize(file.size)}</td>
					<td>
						{file.modDate &&
							moment(file.modDate).format("YYYY-MM-DD hh:mm:ss")}
					</td>
				</>
			)}
		</tr>
	);
};
