import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Button } from "antd";
import { isEmpty } from "lodash";
import classNames from "classnames";
import { Modal } from "@components/modals";
import { PlotForm } from "../ui/plotForm";
import PlotLayout from "../ui/plotLayout";
import { useVisualization } from "../hook/useVisualization";
import "../styles.scss";
import styles from '../../data-explorer/styles.module.scss';

export const DataExplorerVisualization: React.FC<{}> = () => {
	const {
		showVisualizationPopup: showVisualizationPopup,
		plotData,
		queryOutput,
		openPlotPopUp,
		handleSettings,
		handleClose,
		columnsList,
		handlePlotSubmit,
		selectedPlotItem,
		initialValuesPlotForm,
		handleDeletePlot,
		handleOnDragEnd,
	} = useVisualization();

	return (
		<>
			<div id="right-container" className={styles['right_container']}>
				<div className={"mt-1"}>
					<div className={"page_title"}>
						Visualizations (no spark)
					</div>
					<div className={"subtitle"}>Sources</div>
				</div>
				<div className={classNames("text-center", "m-1", "text")}>
					Configure a plot to view custom visualizations
				</div>
				<div
					className={classNames("align-middle", {
						["bottom"]: !isEmpty(plotData),
					})}
				>
					<Button
						disabled={isEmpty(queryOutput)}
						className={classNames("button", "m-1")}
						onClick={openPlotPopUp}
					>
						Configure Plot
					</Button>
				</div>
				<div>
					<DragDropContext onDragEnd={handleOnDragEnd}>
						<Droppable droppableId="plot-layout">
							{(provided) => (
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
								>
									<PlotLayout
										plotData={plotData}
										onSettingsClick={handleSettings}
										onDeleteClick={handleDeletePlot}
									/>
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</div>
			</div>

			<Modal
				isOpen={showVisualizationPopup}
				toggleClose={handleClose}
				title="Customize Plot To View"
				subtitle={""}
				className="plotSelectionModal__container"
				shouldCloseOnOverlayClick={false}
				showCloseMark
			>
				<PlotForm
					columnsList={columnsList}
					toggleClose={handleClose}
					handlePlot={handlePlotSubmit}
					selectedPlotItem={selectedPlotItem}
					initialValuesPlotForm={initialValuesPlotForm}
				/>
			</Modal>
		</>
	);
};
