import { useOutsideClick } from 'rooks5';
import classNames from 'classnames';
import { useFormikContext, useField } from 'formik';
import { map, round, cloneDeep, omit, isEqual, get } from 'lodash';
import React, { useMemo, useEffect, useState, useRef } from 'react';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Column, Table, TableCellRenderer } from 'react-virtualized';
import { useDebounce, usePrevious, useDidUpdate } from 'rooks';
import { ShowWhenTrue} from '../../../../helpers';
import { ColumnSelectorIcon, ColumnSelectorProps, useColumnsSelector } from '../../../columns-selector';
import { InputField } from '../../../form';
import { CheckboxField } from '../../../form/checkbox-field';
import { RadioField } from '../../../form/radio-field';
import { SelectFieldUsingPortal } from '../../../form/select-field-portal';
import { ToggleField } from '../../../form/toggle-field';
import { TooltipTop } from '../../../tooltips';
import { Portal } from '../../../use-portal';
import { FieldSchemaValidator } from '../../schema-creator';
import { TuneTypes, HyperParamsInfo } from '../enums';
import { getNumberOfDigitsAfterDecimal, numberFieldSchema, rangeValidator, getIsTuneEnabledInfo, stringFieldSchema } from '../utils';



interface RangeFieldProps {
    name: string;
}


const isInputValid = (inp: string) => inp !== undefined && inp !== '';



const RangeField: React.FC<RangeFieldProps> = ({ name }) => {
    const { values } = useFormikContext<any>();
    const [,,{setValue}] = useField(name);
 
    const rangeFieldNames = useMemo(() => 
        [`${name}_tune_start`, `${name}_tune_stop`, `${name}_tune_step`], [name]);

    const rangeFieldValues = useMemo(() => 
        [get(values,rangeFieldNames[0]), get(values, rangeFieldNames[1]), get(values, rangeFieldNames[2])], [values]);

    const calculateRange = useDebounce((rangeFieldValues: any[]) => {
        let actualValues: number[] = [];
        let start = rangeFieldValues[0];
        let stop = rangeFieldValues[1];
        let step = rangeFieldValues[2];

        if(isInputValid(start) && isInputValid(stop) && isInputValid(step)) {
            start = parseFloat(start);
            stop = parseFloat(stop);
            step = parseFloat(step);
            while(start <= stop){
                actualValues.push(start);
                start += step;
            }
            const numberOfDigitsAfterDecimal = getNumberOfDigitsAfterDecimal(step);
            if(numberOfDigitsAfterDecimal) actualValues = map(actualValues, (val) => round(val, numberOfDigitsAfterDecimal));
            setValue(actualValues.join(','));
        }
        
    }, 500);

    useEffect(() => {
        calculateRange(rangeFieldValues);
    }, rangeFieldValues);

    // const initialValues = useMemo(() => {
    //     let __value = initialValue;
    //     if(!__value) __value = TuneFieldOptions.List; 
    //     return { [rangeFieldKey]: __value };
    // }, [initialValue]);

    return(
        <div
            className="tuneField__rangeBox"
        >
            <InputField 
                name={rangeFieldNames[0]}
                label="Start"
                inputMode="decimal"
                className="numberInput_field"
                validate={FieldSchemaValidator(numberFieldSchema)}
                useToolTipForErrorMessage
            />
            <InputField 
                name={rangeFieldNames[1]}
                label="Stop"
                inputMode="decimal"
                className="numberInput_field"
                validate={rangeValidator(numberFieldSchema, rangeFieldValues, 'stop')}
                useToolTipForErrorMessage
            />
            <InputField 
                name={rangeFieldNames[2]}
                label="Step"
                inputMode="decimal"
                className="numberInput_field"
                validate={rangeValidator(numberFieldSchema, rangeFieldValues, 'step')}
                useToolTipForErrorMessage
            />
        </div>
    );
};


const ROW_HEIGHT= 32;

interface DropdownProps {
    options: string[];
    value: string;
    onClickItem: (arg0: string) => any;
    className?: string;
}

const Dropdown: React.FC<DropdownProps> = ({ options, value, onClickItem, className= '' }) => {
    const [showDropdown, toggleDropdown] = useState(false);
    const labelFieldRef = useRef<HTMLDivElement>(null);
    const optionsListRef = useRef<HTMLUListElement>(null);

    const handleScroll = (e: Event) => {
        if(e.target !== optionsListRef.current) {
            // SCROLL OUTSIDE THE OPTIONS LIST
            toggleDropdown(false);
        }
    };

    useOutsideClick(labelFieldRef, () => {
        toggleDropdown(false);
    }, showDropdown);

    useEffect(() => {
        if(showDropdown) {
            window.addEventListener('scroll', handleScroll, true);
        }
        return () => {
            if(showDropdown)
                window.removeEventListener('scroll', handleScroll, true);
        };
    }, [showDropdown]);
 

    return(
        <div>
            <div 
                className={classNames('select-field-box', {'select-field-box--active': showDropdown}, {[className]: className })}
                ref={labelFieldRef}    
            >
                <input
                    className={'select-field-value'} 
                    value={value}
                    onClick={() => toggleDropdown(!showDropdown)}
                    autoComplete="off"
                    readOnly
                />
            </div>
            <ShowWhenTrue show={showDropdown}>
                <Portal
                    parentRef={labelFieldRef}
                    childRef={optionsListRef}
                    showChild={showDropdown}
                    className={className}
                    setParentWidthForChild
                >
                    <ul 
                        className="select-field-options-outer"
                        ref={optionsListRef}
                    >
                        {options.map((option, index) => {
                            return(
                                <React.Fragment key={option+index}>
                                    <li 
                                        className='select-field-option'
                                        onClick={() => {
                                            toggleDropdown(false);
                                            onClickItem && onClickItem(option);
                                        }} 
                                    >
                                        {option}
                                    </li>
                                </React.Fragment>
                            );
                        })}
                    </ul>
                </Portal>
            </ShowWhenTrue>
        </div>
    );
};

const rangeOptions = [TuneTypes.Range, TuneTypes.List];

interface HyperParamsTableProps {
    hyperParams: HyperParamsInfo['info'];
    name: string;
    showTypeColumn: boolean;
    hasDisableExpression?: boolean;
    hideTune?: boolean;
}

// [stage_stageId]: {
//      [hyperParamCategory]: {
//          [hyperParamName]: {
//              "tune": boolean,
//              "value": string
//          }
//      } 
// } 

// {
//     "stage_228592f6-8cbc-4e22-a588-71e044e59a37": {
//         "operation": "reduce_dimensions",
//         "reduce_dimensions_hyperparams": {
//         "k": {
//             "tune": true,
//             "value": "2,4",
//              "_isFloat": true/false,
//              "_isBoolean": true/false,
//         },
//         "selectedHyperParams": {
//             "k": true
//         }
//         }
//     }
// }

function strictFunc(values: any, expression: string): any {
    'use strict';
    try {
        const tempFn = new Function('values', 'return ' + expression);
        return tempFn(values);
    } catch (e) {
        return false;
    }
}

export const getHyperParamFieldKey = (name: string, hyperParamName: string) => {
    //  "stage_228592f6-8cbc-4e22-a588-71e044e59a37.reduce_dimensions_hyperparams.k"
    return name + '.' + hyperParamName;
};





// export const HyperParamsTable: React.FC<HyperParamsTableProps> = ({ hyperParams, name, showTypeColumn, hasDisableExpression = false, hideTune }) => {
export const HyperParamsTable: React.FC<HyperParamsTableProps> = ({ hyperParams, name, showTypeColumn,hideTune }) => {   
// name = "stage_228592f6-8cbc-4e22-a588-71e044e59a37.reduce_dimensions_hyperparams"
    const [dataForhyperParams, setDataForHyperParams] = useState<HyperParamsInfo['info']>([]);
    // For storing the list of selected hyperparams
    const [,{ initialValue }] = useField(name);
    const { values, setFieldValue } = useFormikContext<any>();
    const cellMeasurerCache = useRef(new CellMeasurerCache({
        fixedWidth: true,
        minHeight: 32,
    }));
    // useRef is used as setting the list in fn handleSetHyperParamsListForColumnSelector() is causing an infinite loop
    const hyperParamsListForColumnSelector = useRef<ColumnSelectorProps['columnsList']>([]);
    const previousHyperParamsList = usePrevious(hyperParamsListForColumnSelector.current);
    const [selectedHyperParamsIndices, renderColumnSelector, setHyperParamsIndices] = useColumnsSelector({ columnsList: hyperParamsListForColumnSelector.current, title: 'Select Parameters', selectAllColumnsInitially: false });
    
    const handleSetHyperParamsListForColumnSelector = useDebounce(({ hyperParams, previousHyperParamsList, values }: {hyperParams: HyperParamsInfo['info']; previousHyperParamsList: HyperParamsInfo['info']; values: any; selectedHyperParamsIndices: Record<number, true>}) => {
        let __selectedHyperParamsIndices = cloneDeep(selectedHyperParamsIndices); 
        const __currentHyperParams = hyperParams.map((param, index) => {
            let disabled = param.disabled;
            if(param.hideExpression) {
                // DISABLE ALL THE FIELDS THAT HAVE HIDE EXPRESSION
                // THEIR SELECTION IS CONTROLLED ON THE BASIS OF HIDE EXPRESSION
                disabled = true;
                const isHidden = strictFunc(values, param.hideExpression);
                if(isHidden) {
                    __selectedHyperParamsIndices = omit(__selectedHyperParamsIndices, index);
                } else {
                    __selectedHyperParamsIndices[index] = true;

                }
            }
            return ({ label: param.hyperParamLabel, disabled });
        });
        if(!isEqual(previousHyperParamsList, __currentHyperParams)) {
            hyperParamsListForColumnSelector.current = __currentHyperParams;
        }
        if(!isEqual(selectedHyperParamsIndices, __selectedHyperParamsIndices)) {
            setHyperParamsIndices(__selectedHyperParamsIndices);
        }
    
    }, 500);

    useEffect(() => {
        handleSetHyperParamsListForColumnSelector({ hyperParams, values, selectedHyperParamsIndices, previousHyperParamsList: previousHyperParamsList || [] });
    }, [hyperParams, values]);


    const isTuneEnabled = getIsTuneEnabledInfo(values);

    const handleDisableTune = useDebounce((isTuneEnabled: boolean) => {
        if(!isTuneEnabled) {
            // AUTOMATICALLY SET TUNE TO FALSE WHEN TUNE IS ENABLED
            setDataForHyperParams(dataForhyperParams => (dataForhyperParams.map(hyperParam => {
                if(hyperParam.tune) hyperParam.tune = false;
                return hyperParam;
            })));
        }
    }, 100);

    useDidUpdate(() => {
        // handleDisableTune is called multiple times when form is initialized
        handleDisableTune(isTuneEnabled);
    }, [isTuneEnabled]);


    const hyperParamsToBeShown = useMemo(() => {
        const dataToBeShown = dataForhyperParams
            .filter((hyperParam, index) => selectedHyperParamsIndices[index]);
        // add placeholder at end of the params list  
        // @ts-ignore
        dataToBeShown.push({ placeholder: true });
        return dataToBeShown;
    }, [dataForhyperParams, selectedHyperParamsIndices, showTypeColumn]);


    const setSelectedHyperParamsOnInitialise = (selectedHyperParams: any) => {
        // TO SET INITIAL VALUES OF SELECTED HYPER PARAMS
        // "reduce_dimensions_hyperparams": {
        //         "k": {
        //             "tune": true,
        //             "value": "2,4",
        //              "_isFloat": true/false,
        //              "_isBoolean": true/false,
        //         },
        //         "selectedHyperParams": {
        //             "k": true
        //         }
        //      }
        const selectedIndices: Record<number, true> = {};
        hyperParams.forEach((param, index) => {
            if(selectedHyperParams?.[param.name]) {
                selectedIndices[index] = true;
            }
        });
        setHyperParamsIndices(selectedIndices);
    };

    const handleSetHyperParamValuesOnMount = (initialValue: any, name: string, hyperParams: HyperParamsTableProps['hyperParams']) => {
        if(initialValue?.selectedHyperParams) {
            setSelectedHyperParamsOnInitialise(initialValue.selectedHyperParams);
        } 
        
        if(initialValue?._info) {
            // RETRIEVE EXISTING VALUES
            const __dataForhyperParams = get(initialValue, '_info');
            setDataForHyperParams(__dataForhyperParams);
        } else {
            // MOUNTING FOR THE FIRST TIME 
            hyperParams.forEach(hyperParam => {
                // Do not forget to update getDefaultValuesForSklearnParams in src\components\formcreators\ml-pipeline\utils.ts
                // if code inside this loop is updated
                const hyperParamKey = getHyperParamFieldKey(name, hyperParam.name);
                setFieldValue(hyperParamKey + '.tune', hyperParam.tune);
                setFieldValue(hyperParamKey + '._isFloat', hyperParam.variableType === 'float');
                setFieldValue(hyperParamKey + '._isBoolean', hyperParam.variableType === 'boolean');
            });
            setDataForHyperParams(hyperParams);
        }
    };

    useEffect(() => {
        handleSetHyperParamValuesOnMount(initialValue, name, hyperParams);
    }, [initialValue, name, hyperParams]);

    useDidUpdate(() => {
        setFieldValue(name + '._info', dataForhyperParams);
    }, [dataForhyperParams]);


    const handleToggleTune = (hyperParamName: string) => {
        const _dataForhyperParams =  dataForhyperParams.map((data) => {
            if(data.name === hyperParamName){
                setFieldValue(name + '.' + data.name + '.tune', !data.tune);
                data.tune = !data.tune;
            } 
            return data; 
        });
        setDataForHyperParams(_dataForhyperParams);
    };

    const handleSetSelectedHyperParams = (selectedHyperParamsIndices: Record<number, true>) => {
        // For storing the list of selected hyperparams
        cellMeasurerCache.current.clearAll();
        const selectedHyperParams: Record<string, true> = {};
        hyperParams.forEach((hyperParam, index) => {
            if(selectedHyperParamsIndices[index]) {
                selectedHyperParams[hyperParam.name] = true;
                return true;
            }
        });
        setFieldValue(name + '.' + 'selectedHyperParams', selectedHyperParams);
    };

    useDidUpdate(() => {
        handleSetSelectedHyperParams(selectedHyperParamsIndices);
    }, [selectedHyperParamsIndices]);

    const labelCellRenderer: TableCellRenderer = ({ cellData, rowIndex, rowData, columnIndex, parent }) => {
        return(
            <CellMeasurer
                cache={cellMeasurerCache.current}
                columnIndex={columnIndex}
                // key={dataKey}
                parent={parent}
                rowIndex={rowIndex}
            >
                <div
                    className={classNames('hyperParamLabel',{'hyperParamLabel__placeholder': rowData.placeholder })}
                >
                    {rowData.placeholder ?
                        <>
                            <span>Use&nbsp; <ColumnSelectorIcon /></span> 
                            <span>&nbsp;to select parameters</span> 
                        </>
                        :
                        <TooltipTop
                            overlay={rowData.qtip}
                            className="hyperParamLabel__textToolTipWrapper"
                        >
                            <span>
                                {cellData}
                            </span> 
                            {rowData.qtip &&
                                <img src="/icons/info-fields.png" width="16" height="16" className="info__icon" alt="information-icon" />
                            }

                        </TooltipTop>
                    }
                </div>

            </CellMeasurer>
        );
    };

    const tuneCellRenderer: TableCellRenderer = ({ cellData, rowData }) => {
        return(
            <ToggleField
                active={cellData}
                onClick={() => handleToggleTune(rowData.name)}
                className={classNames('toggleTuneField', {'placeholder': rowData.placeholder})}
                disabled={!isTuneEnabled}
            />
        );
    };

    const handleSetHyperParamType = (hyperParamName: number, param: TuneTypes) => {
        if(param === TuneTypes['Range']) {
            // This is to trigger Range field errors
            setTimeout(() => setFieldValue('toTriggerError', '1'), 1);
        }
        setDataForHyperParams((_dataForhyperParams: any[]) => 
            _dataForhyperParams.map((data) => {
                if(hyperParamName === data.name) data.hyperParamType = param;
                return data; 
            }));
    };

    const hyperParamTypeRenderer: TableCellRenderer = ({ cellData, rowData }) => {
        return(
            isTuneEnabled && rowData.tune && (cellData === TuneTypes.Range || cellData === TuneTypes.List) ? 
                <Dropdown
                    options={rangeOptions}
                    value={cellData}
                    className="hyperParamTypeDropdown"
                    onClickItem={(option) => handleSetHyperParamType(rowData.name, option as any)}
                />
                :
                <div
                    className={classNames('hyperParamBox justify-content-center', {'placeholder': rowData.placeholder})}
                >
                    -
                </div>
        );
    };

    const hyperParamActualFieldRenderer: TableCellRenderer = ({ rowData }) => {
        //  "stage_228592f6-8cbc-4e22-a588-71e044e59a37.reduce_dimensions_hyperparams.k.value"
        const hyperParamName = getHyperParamFieldKey(name, rowData.name) + '.value';
        return(
            <div
                className={classNames('hyperParamBox', {'placeholder': rowData.placeholder})}
            >
                {rowData.hyperParamType === TuneTypes.Dropdown ?
                    <SelectFieldUsingPortal
                        name={hyperParamName}
                        multiple_select={rowData.tune}
                        className="hyperParamField__dropdown"
                        options={rowData.paramBasedOptions ?  
                        // For generalized linear regression - link field options has to change on the basis of family 
                        // Here, value of family is used to show the options
                        // paramName: family, check genlinearRegressionHyperParams in enums.ts for better understanding
                            rowData.paramBasedOptions.options[get(values, getHyperParamFieldKey(name, rowData.paramBasedOptions.paramName) + '.value')]
                                || 
                                []
                            :
                            rowData.options
                        }
                    />
                    :
                    rowData.hyperParamType === TuneTypes.Checkbox ?
                        isTuneEnabled && !!rowData.tune ? 
                            <CheckboxField 
                                name={hyperParamName}
                                options={rowData.options}
                                color="gold"
                                className="hyperParamField__checkbox"
                            />
                            :
                            <RadioField 
                                name={hyperParamName}
                                options={rowData.options}
                                className="hyperParamField__radio"
                            />
                        :
                        isTuneEnabled && !!rowData.tune && rowData.hyperParamType === TuneTypes.Range ?
                            <RangeField 
                                name={hyperParamName}
                            />
                            :
                            <InputField 
                                className="hyperParamField__list"
                                name={hyperParamName}
                                disabled={rowData.placeholder}
                                // validate={!rowData.placeholder ? FieldSchemaValidator(
                                //     rowData.tune ? 
                                //         stringFieldSchema: rowData.variableType === 'string' ? stringFieldSchema: numberFieldSchema): undefined
                                //     }
                                validate={!rowData.placeholder ? 
                                    rowData.variableType === 'float' && !rowData.tune ?
                                        FieldSchemaValidator(numberFieldSchema)
                                        :
                                        FieldSchemaValidator(stringFieldSchema)
                                    :
                                    undefined
                                }
                                useToolTipForErrorMessage
                            />
                }
            </div>
        );
    };

    const tableHeight = useMemo(() => {
        return hyperParamsToBeShown.reduce((prevValue, currentValue, index) => {
            return prevValue + cellMeasurerCache.current.rowHeight({ index });
        }, ROW_HEIGHT);
    }, [hyperParamsToBeShown]);
    
    return(
        <div 
            className={classNames('hyperParamsTable__box', {'tune__disabled' :hideTune })}
        >
            {renderColumnSelector}
            <AutoSizer disableHeight>
                {({ width }) => (
                    <Table
                        width={width}
                        className="hyperParamsTable"
                        headerClassName="hyperParamsTable__header"
                        rowClassName="hyperParamsTable__row"
                        height={tableHeight}
                        rowHeight={cellMeasurerCache.current.rowHeight}
                        headerHeight={ROW_HEIGHT}
                        rowCount={hyperParamsToBeShown.length}
                        rowGetter={({ index}) => hyperParamsToBeShown[index]}
                        autoHeight
                    >
                        <Column 
                            label="Parameter"
                            dataKey="hyperParamLabel"
                            cellRenderer={labelCellRenderer}
                            width={hideTune ? 188: 170}
                            flexGrow={1}
                        />
                        {!hideTune ? 
                            <Column 
                                label="Tune"
                                dataKey="tune"
                                cellRenderer={tuneCellRenderer}
                                width={52}
                            />
                            :
                            null
                        }
                        {showTypeColumn && !hideTune ?
                            <Column 
                                label="Type"
                                dataKey="hyperParamType"
                                cellRenderer={hyperParamTypeRenderer}
                                width={76}
                                className="hyperParamTypeField"
                            />
                            :
                            null
                        }
                        <Column 
                            label="Value"
                            dataKey="hyperParamValue"
                            cellRenderer={hyperParamActualFieldRenderer}
                            width={showTypeColumn ? 219: 288}
                        />
                    </Table>
                )}
            </AutoSizer>
        </div>
    );
};
