import React from 'react';
import { Table} from '../../../components/table';
import styles from './../styles.module.scss';
import { ConcurrentUser } from '../types';
import moment from 'moment-mini';

export type UsersTableProps = {
    // tableWidth: number;
    usersList: ConcurrentUser[];
};

export const UserConcurrentTable: React.FC<UsersTableProps> = ({ usersList }) => {
    return (
        <div className={styles['usersTable']}>
            <Table
                height={500}
                rowHeight={42}
                rowCount={usersList.length}
                width={1200}
                headerHeight={42}
                rowGetter={({ index }) => usersList[index]}
                >
                    <Table.Column
                        width={1200 }
                        dataKey="username"
                        label="User Name"
                    />
                    <Table.Column
                        width={1200 }
                        dataKey="ipAddress"
                        label="IP Address"
                    />
                    <Table.Column
                        width={1200}
                        dataKey="start"
                        label="Session Start"
                        cellRenderer={({ cellData, rowData }) => {
                            return (
                                <span
                                >
                                    {moment(cellData).format('LLL')}
                                </span>
                            );
                        }}
                    />
                    <Table.Column
                        width={1200}
                        dataKey="lastAccess"
                        label="Last Access"
                        cellRenderer={({ cellData, rowData }) => {
                            return (
                                <span
                                >
                                    {moment(cellData).format('LLL')}
                                </span>
                            );
                        }}
                    />
            </Table>
               
        </div>
    );
};
