/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types */
import _ from 'lodash';
import React, { Component } from 'react';
import { Select } from 'antd';

const { Option, OptGroup } = Select;

export default class SelectColumnSearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            results: {},
            fullResults: {},
            first: true,
            firstFocus: true,
        };
    }

    componentDidMount() {
        this.resetComponent();
        //this.setState({ value: this.props.selectedTable });
        this.setFullResultsState();
        this.setState({results: this.fullResults});
        //this.runPopupFix();
    }

    runPopupFix = () => {
        const searchBar = document.getElementById(
            `search-bar-${this.props.type}-${this.props.rowIndex}`
        );
        searchBar.focus();
        searchBar.blur();
    };

    modifyColumn = (value) => {
        if (this.props.type === 'select') {
            this.props.query.select.modifySelectColumn(
                this.props.rowIndex,
                value
            );
        } 
        this.props.updateQueryState();
    };

    setFullResultsState = () => {
        let fullResults = {};

        fullResults.All = {
            name: 'all',
            alias: null,
            results: [{ tableName: 'all', alias: null, title: '*' }],
        };

        fullResults = {
            ...fullResults,
            ...this.props.query.fullResults.results.reduce((resultDrop, result) => {
                let tep = null
                if(this.props.query.geoSpatial.isGeoSpatialEnabled ){
                    tep = this.props.query.from.fromJoinRows.find((_from)=>{
                        return _from.tableAlias === result.tableAlias;
                    });
                }
                // eslint-disable-next-line no-param-reassign

                resultDrop[
                    result.tableMetadata.name + ' (' + result.tableAlias + ')'
                ] = {
                    name: result.tableMetadata.name + ' (' + result.tableAlias + ')',
                    tableAlias: result.tableAlias,
                    _id: result.tableMetadata.id,
                    results: result.tableMetadata.fields
                        ? result.tableMetadata.fields.map(column => ({
                            alias: result.tableAlias,
                            tablename: result.tableMetadata.name,
                            title: column.name,
                            _id: (tep && tep.geotable) || result.tableMetadata.id
                        }))
                        : [],
                };
                return resultDrop;
            }, {}),
        };

        Object.keys(fullResults).forEach(key => {
            if (key !== 'All') {
                fullResults[key].results.unshift({
                    tableName: fullResults[key].name,
                    alias: fullResults[key].tableAlias,
                    title: '*',
                    _id: fullResults[key]._id
                });
            }
        });

        this.setState({
            // eslint-disable-next-line react/no-unused-state
            isLoading: false,
            fullResults,
        });
    };

    resetComponent = () =>
        this.setState({ isLoading: false, results: [], first: true /*value: ''*/ });

    handleSearchChangeMousedown = (value) => {
        //this.modifyColumn(null, null, '');

        this.setFullResultsState();
        setTimeout(() => {
            const filteredResults = this.state.fullResults;
            this.setState({
                isLoading: false,
                results: filteredResults,
            });
        }, 100);
    };

    handleChange(value) {
        this.modifyColumn(value);
    }

    render(){
        const { fullResults } = this.state;
        return (
            <Select
                placeholder="Select Columns"
                mode="multiple"
                style={{ width: '100%', minWidth: '400px' }}
                onChange={this.handleChange.bind(this)}
                dropdownClassName="editorTab"
                onFocus={this.handleSearchChangeMousedown.bind(this)}
            >
                {
                    Object.keys(fullResults).map((result, i)=>{
                        const temp = fullResults[result];
                        return (
                            <OptGroup value={temp.tableAlias} label={temp.tableAlias} key={i}>
                                {temp['results'].map((_obj)=>{
                                    if(result === 'All'){
                                        return <Option key={_obj.title} id={_obj.title} value={`${_obj.title}`}>{_obj.title}</Option>;
                                    } else {
                                        return <Option key={`${_obj._id}.${_obj.title}`}  id={_obj.title} value={`${_obj._id}.${_obj.title}`}>{_obj.title}</Option>;
                                    }
                                })}
                            </OptGroup>);
                    })
                }
            </Select>
        );
    }
}
