import React from 'react';
import cs from 'classnames';
import { ShowWhenTrue } from '../../helpers';
import { TooltipTop } from '../tooltips';

export interface ToggleFieldProps {
    label?: string;
    active: boolean;
    onClick: (e: any) => any;
    className?: string;
    disabled?: boolean;
    infoText?: string;
    unSelectedLabel?: string;
}

export const ToggleField: React.FC<ToggleFieldProps> = ({ active, onClick, label, className = '', disabled = false, infoText = '', unSelectedLabel = '' }) => {
    return(
        <div 
            className={cs('toggleField__container', {'disabled': disabled }, {[className]: !!(className)})} 
            onClick={(e) => !disabled && onClick(e)}
        >
            {!!unSelectedLabel &&
                <label className={cs('toggleField__label', { 'toggleField__label__active': !active  })}>{unSelectedLabel}</label>
            }
            <div 
                className={cs('toggleField', {'active': active})}
            >
                <div className="toggleDot" />
            </div>
            {!!(label) && 
                <>
                    <label className={cs('toggleField__label', { 'toggleField__label__active': active })} >{label}</label>
                    <ShowWhenTrue show={!!infoText}>
                        <TooltipTop overlay={infoText}>
                            <img src="/icons/info-fields.png" width="16" height="16" className="info__icon" alt="information-icon" />
                        </TooltipTop>
                    </ShowWhenTrue>
                </>
            }
        </div>
    );
};
