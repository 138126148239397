/* eslint-disable no-console */
import React, { useEffect, useReducer } from 'react';
import { BaseFieldType } from '../../components/formcreators/types';
import { useSelector, useDispatch } from 'react-redux';
import { DsNodeExtras } from '../../components/workflow-canvas/node/ds_nodemodel';
import { SerializedData } from '../../components/workflow-canvas';
import './styles.scss';
import { NewComponentResponse, } from '../../api/new-custom-component';
import { NodeData, LinkData, WorkflowConfigInPayload } from './utils';
import { NewComponentModal } from './modals/new-component';
import { ComponentEditorModal } from './modals/customcomponent-editor';
import { RootState } from '../../store/types';
import { ModalTypes, toggleModal, setActiveTab, setCustomComponentName, 
    RetrieveWorkflowsPayload, setcomponentParamenters, setCustomComponentModalType, setCustomComponentUpdateId, setActiveUpdataParamter, handleOpenWorkflowUsingWorkflowId, SHOW_COMPONENTS_TREEVIEW, setWorkflowTabSelection } from '../../store/workflow';
import { WorkflowTreeview } from './treeview-LHS/workflows';
import { CanvasTabs, canvasTabsList, DagCanvasTabs, dagCanvasTabsList, getInitialCustombuilderValues } from './enums';
import classNames from 'classnames';
import { ProfileTreeview } from './treeview-LHS/profile-list';
import { Canvas } from './canvas';
import { PropertiesRHS } from './properties-rhs';
import { Dropdown } from '../../components/form/dropdown';
import { ComponentsTreeView } from './treeview-LHS/treeview';
import { Env, showExecutionEnvToggle } from '../../constants/settings';
import { ShowWhenTrue } from '../../helpers';
import { SwitchExecutionEnv } from '../../components/execution-env';
import { ExecutionEnvModes } from '../../constants/enums';
import { StreamingPreviewWorkflowModal } from './modals/streaming-preview-workflow';
import { tourStyle } from '@components/coachmark';
import JoyRide, { ACTIONS, EVENTS, STATUS, Step, Styles } from "react-joyride";
import { useMountedRef } from '../../hooks/mount';
import { setActiveExecutionEnv, updateExecutionEnv } from '@store/common';
import { isEmpty } from 'lodash';
import { overFlowMenu } from '@assets/icons';

export interface Workflow {
    name: string;
    id: number;
    version: number;
    details: SerializedData | {};
    saved: boolean;
    createdBy: number;
}

export interface ActiveComponent {
    id: string;
    name: string;
    formDetails: BaseFieldType[];
    portManagementDetails?: DsNodeExtras['portManagement'];
    isPropertiesValid: boolean;
}

const INIT_TOUR_STEPS: Step[] = [
	{
		target: "#btn_new_workflow",
		content: "Create New Workflow",
		disableBeacon: true,
        placement: 'bottom'
	},
	{
		target: ".treeview .left-tree-holder",
		content: "Drag and Drop built-in components to your workflow to read/write/transform your data and build your AI models. Once you drop a component onto your workflow, double-click the component to access the properties",
        placement: 'right',
		disableBeacon: true
	},
    {
		target: ".btn-run-toolbar",
		content: "Execute your workflow by clicking Run. Choose the Cluster you would like to run the workflow on. You can also set a default cluster to run your workflows on the Compute page",
        placement: 'bottom',
		disableBeacon: true
	},
    {
		target: ".dropdown__master.runWorkflow__options",
		content: "Preview is another way to execute your workflows when you want to review the data summary at various stages of your workflow",
        placement: 'bottom',
		disableBeacon: true
	},
    {
		target: ".btn-editor",
		content: "Open your workflow in your favorite notebook experience",
        placement: 'bottom',
		disableBeacon: true
	},
    {
		target: "#DownloadCanvas_btn",
		content: "You can download the python code for your workflow",
        placement: 'bottom',
		disableBeacon: true
	},
    {
		target: ".ant-tabs-content",
		content: "Access your workflows here. You can choose to save them to directories",
        placement: 'bottom',
		disableBeacon: true
	},
    {
		target: ".executionEnv__outerBox .activeExecutionEnv__optionItem",
		content: "Select Spark/Python/Streaming/Pipelines experience to build corresponding workflows. Pipelines experience can be used to schedule your workflows from other experiences",
        placement: 'bottom',
		disableBeacon: true
	},
    {
		target: "#WK_JobStatusIcon",
		content: "Access your workflow/job logs from here. This will open the console at the bottom of the canvas with the log",
        placement: 'bottom',
		disableBeacon: true
	},
    {
		target: "#btn-toggleHelpPanel",
		content: "For additional help, use the help menu to access documentation",
        placement: 'bottom',
		disableBeacon: true
	}
];

const INITIAL_STATE = {
	key: new Date(), // This field makes the tour to re-render when we restart the tour
	run: false,
	continuous: true, // Show next button
	loading: false,
	stepIndex: 0, // Make the component controlled
	steps: INIT_TOUR_STEPS,
	hideBackButton: true,
    disableOverlayClose: true,
    spotlightPadding: 5,
	//hideCloseButton: false
};


export type selectionType = 'single' | 'multiple';
export type selectedWorkflowInfo = { id: number; name: string }
export type workflowRunData = { workflow_config: WorkflowConfigInPayload ;nodes: NodeData[]; links: LinkData[] };

const WorkflowView: React.FC = () => {
    const dispatch1 = useDispatch();
    const mountedRef = useMountedRef();
    const isComponentTreeviewMinimized = useSelector((store: RootState) => store.WorkflowReducer.isComponentTreeviewMinimized);
    const activeCanvasTab = useSelector((store: RootState) => store.WorkflowReducer.activeTab);
    const { openTabs, activeTab } = useSelector((store: RootState) => store.CanvasReducer.workflowEditor);
    const isDagEnv = useSelector((store: RootState) => store.CommonReducer.activeExecutionEnv === ExecutionEnvModes.Pipelines);
    const { 
        show_workflow_help
    } = useSelector((store: RootState) => store.CoachMarkReducer);

    function handleToggleModal(name: ModalTypes, action: boolean) {
        dispatch1(toggleModal(name, action));
    }

    function handleCreateNewComponentSuccess(response: NewComponentResponse) {
        const payload: any = response.data;
        // console.log(payload);
        dispatch1(setcomponentParamenters(getInitialCustombuilderValues()));
        dispatch1(setCustomComponentName(payload));
        toggleModal('newComponent', false);
        handleToggleModal('customComoponentParameters', true);
    }

    const handleShowCustomComponentParamsModal = () => {
        dispatch1(setcomponentParamenters(getInitialCustombuilderValues()));
        dispatch1(setCustomComponentModalType('new'));
        dispatch1(setCustomComponentUpdateId(0));
        dispatch1(setActiveUpdataParamter(false));
        handleToggleModal('customComoponentParameters', true);
    };

    // function handleCustomComponentParametersSuccess() {
    //     handleToggleModal('customEditor', true);
    // }
    function handleComponentEditorSucess() {
        handleToggleModal('customEditor', false);
    }

    const setActiveCanvasTab = (tab: CanvasTabs | DagCanvasTabs) => {
        dispatch1(setActiveTab(tab));
    };

    // const handleGetWorkflowSuccess = (pageNo: number, workflow: DetailedWorkflowInfo) => {
    //     dispatch(openANewWorkflowEditorTabUsingWorkflowInfo(workflow.data));
    // };

    function handleClickWorkflow(this: any, workflow: RetrieveWorkflowsPayload['results'][0]){
        dispatch1(handleOpenWorkflowUsingWorkflowId(workflow.id));
        // if (!isActiveComponentFormSaved) {
        //     dispatch(closeActiveForm());
        // }
        // else if (activeTabRef.current.id !== workflow.id) {
        //     const isWorkflowAlreadyOpen = openTabsRef.current.get(workflow.id);
        //     dispatch(toggleWorkflowsLoadingState([workflow.id], true));

        //     if (isWorkflowAlreadyOpen) {
        //         // switchWorkflowEditor already calls saveCurrentWorkflowTabState 
        //         dispatch(switchWorkflowEditorTab(workflow.id));
        //     }
        //     else {
        //         dispatch(saveCurrentWorkflowTabState());
        //         WorkflowHandler.GetWorkflowInfo(workflow.id, handleGetWorkflowSuccess.bind(this, workflow.page));
        //     }
        // }
    }

    const getDropDownOptions = () => {

        const results = [ 
            'Components',
            'Workflows',
            'Profiles'
        ];
        const result: any = {};


        if(!Env.profiles) {
            results.splice(results.indexOf('Profiles'), 1);
        } 
        results.forEach((key: string)=>{
            result[key] = { 
                action: () => setActiveCanvasTab(CanvasTabs[key.toUpperCase() as CanvasTabs]) 
            };
        });

        return result;
    };

    useEffect(() => {
        if(mountedRef.current && show_workflow_help !== 0) {
            dispatch1(setActiveTab(CanvasTabs.WORKFLOWS));
            dispatch1({type: SHOW_COMPONENTS_TREEVIEW});
            dispatch1({
                type: "SET_WORKFLOW_TAB_SELECTION",
                payload: {
                    activeInnerTab: "Workflows"
                }
            });
            //dispatch1(setActiveExecutionEnv(ExecutionEnvModes.Spark));
            dispatch1(updateExecutionEnv(ExecutionEnvModes.Spark))
            setTimeout(() => {
                dispatch({ type: "RESTART" });
            }, 600);
        }
    }, [show_workflow_help])

    const reducer = (state = INITIAL_STATE, action: any) => {
		switch (action.type) {
			// start the tour
			case "START":
				return { ...state, run: true };
			case "OPEN":
				return { ...state, run: true, stepIndex: action.value };
			// Reset to 0th step
			case "RESET":
				return { ...state, stepIndex: 0 };
			// Stop the tour
			case "STOP":
				return { ...state, run: false };
			// Update the steps for next / back button click
			case "NEXT_OR_PREV":
				return { ...state, ...action.payload };
			// Restart the tour - reset go to 1st step, restart create new tour
			case "RESTART":
				return {
					...state,
					stepIndex: 0,
					run: true,
					loading: false,
					key: new Date(),
				};
			default:
				return state;
		}
	};
    const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

    const callback = (data: any) => {
		const { action, index, type, status } = data;
		if (
			action === ACTIONS.CLOSE 
		) {
            dispatch1({
                type: "HIDE_WORKFLOW_HELP",
            });
            dispatch({ type: "STOP" });
		} else if (type === EVENTS.STEP_AFTER) {
            if(index === 0) {
                dispatch1(setActiveTab(CanvasTabs.COMPONENTS));
                return setTimeout(() => {
                    dispatch({
                        type: "NEXT_OR_PREV",
                        payload: {
                            stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
                        },
                    });
                }, 500);
            }
            if(index === 5) {
                dispatch1(setActiveTab(CanvasTabs.WORKFLOWS));
                return setTimeout(() => {
                    dispatch({
                        type: "NEXT_OR_PREV",
                        payload: {
                            stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
                        },
                    });
                }, 500);
            }

            dispatch({
				type: "NEXT_OR_PREV",
				payload: {
					stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
				},
			});
		} else if(status === STATUS.FINISHED) {
            dispatch1({
                type: "HIDE_WORKFLOW_HELP",
            });
        }
	};
    return (
        <>
            <ShowWhenTrue show={Env.enableDataStudioWalkthrough}>
                <JoyRide
                    {...tourState}
                    callback={callback}
                    styles={tourStyle}
                    locale={{
                        last: 'Done'
                    }}
                />
            </ShowWhenTrue>
            <section className={classNames('workflow__container', { showExecutionEnvToggle })}>
                <div className={classNames(
                    'treeview__container', 
                    { 'treeview--minimized': isComponentTreeviewMinimized }
                )}>
                    <p id='LHS_title' className="LHS__title">
                        Canvas
                    </p>
                    <p className="LHS__subtitle">Select an experience to get started</p>
                    <ShowWhenTrue show={showExecutionEnvToggle}>
                        <SwitchExecutionEnv 
                            page="workflowEditor"
                        />
                    </ShowWhenTrue>
                    <div className="WorkflowLHS__selection__box">
                        <div className="WorkflowLHS__selectionOptions__outer">
                            <ul
                                className={classNames(
                                    'WorkflowLHS__selectionOptions',
                                    { 'showProfile': activeCanvasTab === CanvasTabs.PROFILES, 'dagOptions': isDagEnv },
                                    // { 'showClusters': activeCanvasTab === CanvasTabs.CLUSTERS }
                                )}
                            >
                                {(isDagEnv ? dagCanvasTabsList:canvasTabsList).map(tab => (
                                    <li
                                        id={`tab_${tab.label}`}
                                        key={tab.label}
                                        className={classNames('switchable__tab', { 'switchable__tab--active': activeCanvasTab === tab.value })}
                                        onClick={() => setActiveCanvasTab(tab.value)}
                                        role="treeview__tab"
                                        aria-label={tab.label}
                                    >
                                        {tab.label}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <ShowWhenTrue show={(Env.profiles) && !isDagEnv}>
                        <Dropdown
                                title={overFlowMenu()}
                                dropdownOptions={getDropDownOptions()}
                            />
                        </ShowWhenTrue>
                    </div>
                    <div className="treeview__master">
                        <div className={classNames('treeview__inner',
                            { 'showWorkflows': activeCanvasTab === CanvasTabs.WORKFLOWS },
                            { 'showProfiles': activeCanvasTab === CanvasTabs.PROFILES },
                            // { 'showClusters': activeCanvasTab === CanvasTabs.CLUSTERS },
                        )}>
                            <ComponentsTreeView
                                allowDrag
                                hideTreeview={activeCanvasTab !== CanvasTabs.COMPONENTS}
                                showCustomComponentModal={handleShowCustomComponentParamsModal}
                            />
                            <WorkflowTreeview
                                hideWorkflowTreeview={activeCanvasTab !== CanvasTabs.WORKFLOWS}
                                handleClickWorkflow={handleClickWorkflow}
                                openTabs={openTabs}
                                activeTabId={activeTab.id}
                            />
                            <ProfileTreeview />
                        </div>
                    </div>
                </div>
                <Canvas />
                <PropertiesRHS />

                <NewComponentModal
                    handleCreateNewComponentSuccess={handleCreateNewComponentSuccess}
                />
                {/* <CustomComponentParametersModal
                    CustomComponentParametersSuccess={handleCustomComponentParametersSuccess}
                /> */}
                
                <StreamingPreviewWorkflowModal />
            </section>
        </>
    );
};

export default WorkflowView;