import styled from "styled-components";
import React from "react";
import { FileExplorerProps } from "./types";

const Loader = styled.tr`
	td {
		height: 30px;
		position: relative;
		&::after {
			border-radius: 4px;
			content: "";
			position: absolute;
			height: 20px;
			top: 5px;
			left: 16px;
			background-color: #666a8d;
			background-image: linear-gradient(
				90deg,
				rgba(42, 44, 66, 0.6),
				#2a2c42,
				rgba(42, 44, 66, 0.6)
			);
			background-size: 400% 400%;
			animation: shine-lines 1.2s infinite linear;
		}

		&:nth-child(1) {
			&::after {
				width: 200px;
			}
		}

		&:nth-child(2),
		&:nth-child(3) {
			&::after {
				width: 40px;
			}
		}

		&:nth-child(4) {
			&::after {
				width: 150px;
			}
		}
		&:nth-child(5) {
			&::after {
				width: 80px;
			}
		}
	}

	@keyframes shine-lines {
		0% {
			background-position: 0% 0%;
		}
		100% {
			background-position: -135% 0%;
		}
	}
`;

export const FileListLoader = ({ view }: Pick<FileExplorerProps, "view">) => (
	<>
		{[1, 2, 3, 4, 5].map((i) => (
			<Loader key={"file_loader" + i}>
				<td></td>
				{view === "Table" && (
					<>
						<td></td>
						<td></td>
						<td></td>
					</>
				)}
			</Loader>
		))}
	</>
);
