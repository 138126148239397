import _ from 'lodash';
const selectOptions = [
    { key: 1, text: 'Avg', value: 'avg', type: {'row' : true, 'col': true}, default: 'row' },
    { key: 2, text: 'Count', value: 'count', type: {'row' : false, 'col': true}, default: 'col' },
    { key: 3, text: 'Min', value: 'min', type: {'row' : false, 'col': true}, default: 'col'},
    { key: 4, text: 'Max', value: 'max', type: {'row' : false, 'col': true}, default: 'col' },
    { key: 5, text: 'Sum', value: 'sum', type: {'row' : true, 'col': true}, default: 'row' },
    { key: 6, text: 'StdDev', value: 'stddev', type: {'row' : false, 'col': true}, default: 'col' },
    { key: 7, text: 'Variance', value: 'variance', type: {'row' : false, 'col': true}, default: 'col' },
    { key: 8, text: 'Area', value: 'Area', type: {'row' : true, 'col': false}, default: 'row', suffix: 'ST_' },
    { key: 9, text: 'Centroid', value: 'Centroid', type: {'row' : true, 'col': false}, default: 'row', suffix: 'ST_' },
    { key: 10, text: 'MaxX', value: 'MaxX', type: {'row' : true, 'col': false}, default: 'row', suffix: 'ST_' },
    { key: 11, text: 'MinX', value: 'MinX', type: {'row' : true, 'col': false}, default: 'row', suffix: 'ST_' },
    { key: 12, text: 'MaxY', value: 'MaxY', type: {'row' : true, 'col': false}, default: 'row', suffix: 'ST_' },
    { key: 13, text: 'MinY', value: 'MinY', type: {'row' : true, 'col': false}, default: 'row', suffix: 'ST_' },
    { key: 14, text: 'X', value: 'X', type: {'row' : true, 'col': false}, default: 'row', suffix: 'ST_' },
    { key: 15, text: 'Y', value: 'Y', type: {'row' : true, 'col': false}, default: 'row', suffix: 'ST_' },
    { key: 16, text: 'Bin', value: 'Bin', type: {'row' : true, 'col': false}, default: 'row', suffix: 'ST_' },
    { key: 17, text: 'SpatialReferenceID', value: 'SRID', type: {'row' : true, 'col': false}, default: 'row', suffix: 'ST_' },
    { key: 18, text: 'AsGeoJSON', value: 'AsGeoJSON', type: {'row' : true, 'col': false}, default: 'row', suffix: 'ST_' },
    { key: 19, text: 'AsText', value: 'AsText', type: {'row' : true, 'col': false}, default: 'row', suffix: 'ST_' },
    { key: 20, text: 'GeodesicLengthWGS84', value: 'GeodesicLengthWGS84', type: {'row' : true, 'col': false}, default: 'row', suffix: 'ST_' }
];

const whereOptions = [
    { key: 21, text: 'Contains', value: 'Contains', type: {'row' : false, 'col': true}, default: 'col', suffix: 'ST_', isGeoSpatial: true },
    { key: 22, text: 'EnvIntersects', value: 'EnvIntersects', type: {'row' : false, 'col': true}, default: 'col', suffix: 'ST_', isGeoSpatial: true },
    { key: 23, text: 'Equals', value: 'Equals', type: {'row' : false, 'col': true}, default: 'col', suffix: 'ST_', isGeoSpatial: false },
    { key: 24, text: 'Intersects', value: 'Intersects', type: {'row' : false, 'col': true}, default: 'col', suffix: 'ST_', isGeoSpatial: true },
    { key: 25, text: 'Overlaps', value: 'Overlaps', type: {'row' : false, 'col': true}, default: 'col', suffix: 'ST_', isGeoSpatial: true },
    { key: 26, text: 'Touches', value: 'Touches', type: {'row' : false, 'col': true}, default: 'col', suffix: 'ST_', isGeoSpatial: true },
    { key: 27, text: 'Distance', value: 'Distance', type: {'row' : false, 'col': true}, default: 'col', suffix: 'ST_', isGeoSpatial: false },
    { key: 28, text: 'Point', value: 'Point', type: {'row' : false, 'col': true}, default: 'col', suffix: 'ST_', isGeoSpatial: false }
];

export default  {
    select: _.orderBy(selectOptions, ['text']),
    where: _.orderBy(selectOptions.concat(whereOptions), ['text']),
    geoSpatial: _.orderBy(whereOptions.filter(res=>res.isGeoSpatial), ['text']),
};
