import React from 'react';
import { useField } from 'formik';
import { ShowWhenTrue } from '../../helpers';
import classNames from 'classnames';
import { TooltipTop } from '../tooltips';

type option = { label: string; value: string | number | boolean; infoText ?: string; }

export interface RadioProps {
    name: string;
    label?: string;
    required?: boolean;
    className?: string;
    inline?: boolean;
    options: option[];
    readOnly?: boolean;
    infoText?: string;
    onChange?: any;
}

export const RadioField: React.FC<RadioProps> = ({ name, label, required, options, readOnly, children, className, infoText = '', inline=false, onChange }): JSX.Element => {
    const [field, , { setValue }] = useField(name);
    return(
        <div 
            className={classNames('radiofield__container', className && {[className]: !!(className)})}
            data-fieldname={name}
        >
            <div className={classNames('radiofield__inner__container', {'readonly-radio': !!(readOnly)}, {'inline': inline})}>
                <ShowWhenTrue show={!!(label)}>
                    <label className={classNames('inputfield__label m-r-30')}>
                        <span className={required ? 'red-star': ''}>{label}</span>
                        <ShowWhenTrue show={!!infoText}>
                            <TooltipTop placement="topRight" overlay={infoText}>
                                <img src="/icons/info-fields.png" width="16" height="16" className="info__icon" alt="information-icon" />
                            </TooltipTop>
                        </ShowWhenTrue>
                    </label>
                </ShowWhenTrue>

                {(options || []).map((option: option) => {
                    const id = option.label+Math.random();
                    return(
                        <label htmlFor={id} key={id} className="radiofield m-l-0">
                            <input
                                type="radio"
                                id={id}
                                {...field}
                                checked={field.value === option.value}
                                onChange={() => {
                                    if(!(readOnly)) {
                                        setValue(option.value)
                                        onChange && onChange(option.value);
                                    }
                                }}
                                readOnly={!!(readOnly)}
                            />
                            <span className="label">
                                <span className='whiteDot' />
                            </span>
                            <span className="option__label" >
                                {option.label}
                                <ShowWhenTrue show={!!option.infoText}>
                                    <TooltipTop placement="topRight" overlay={option.infoText}>
                                        <img src="/icons/info-fields.png" width="16" height="16" className="info__icon" alt="information-icon" />
                                    </TooltipTop>
                                </ShowWhenTrue>
                            </span>
                        </label>
                    );
                })}
            </div>
            {children}

        </div>
    );
    
};