/* eslint-disable @typescript-eslint/no-use-before-define */

import { Modal } from "@components/modals";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@store/types";
import { toggleAnalyticsModal } from "@store/analytics";
import {
	AnalyticsHandler,
	ColumnsListResponse,
	DfInfo,
	RasterConfig,
} from "@api/analytics-handler";
import { GraphInfo, PlotData, TiffColorPlotInfo } from "../../Canvas";
import { _selectoptionType, SelectField } from "@components/form/select-field";
import { WorkflowCanvas } from "@components/workflow-canvas";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";
import { isEqual } from "lodash";
import Form, { InputField } from "@components/form";
import {
	aggregationTypes,
	BandType,
	bandTypeOptions,
	graphTypes,
	mapExtentTypes,
	projectionCodeOptions,
} from "../../enums";
import { DynamicKeyValueFieldGenerator } from "@components/form/dynamic-field-generator";
import { getBooleanCheckboxOptions, useGetActiveTabInfo } from "@utils/index";
import { WorkflowAnalyticsTabInfo } from "@store/canvas";
import {
	getSelectedRasterConfigInfo,
	isRasterNewFormTouched,
	isRasterUpdateFormTouched,
	validateNewPlotTypeForColumnReset,
} from "../../utils";
import { InPageSpinner } from "@components/spinners/in-page-spinner";
import { RadioField } from "@components/form/radio-field";
import ColumnSelection from "./ColumnSelection";
import styles from "../../styles.module.scss";
import { SubmitPlotDataRef } from "./usePlotApi";
import RasterTabs from "./RasterTabs";
import { useAnalyticsContext } from "@pages/workflow-analytics-page/useAnalyticsContext";
import { errorAlert, infoAlert } from "@components/toastify/notify-toast";
import { CheckboxField } from "@components/form/checkbox-field";
import { FieldSchemaValidator } from "@components/formcreators/schema-creator";
import * as yup from "yup";
import { ShowWhenTrue } from "@helpers/showwhentrue";
import { Env } from "@constants/settings";

export type RasterSelectionInfo = {
	type: "new" | "edit";
	config: RasterConfig | null;
	tiffColorPlotInfo: TiffColorPlotInfo;
	statementId: string | null;
	newRasterConfigInfo: {
		id: string;
	} | null;
	activeTabId: string | null;
};
export interface PlotSelectionModalProps {
	handleAddGraph: (arg0: string, arg1: GraphInfo) => any;
	handleUpdateGraph: (
		graphIdentifier: string,
		arg1: Partial<GraphInfo>
	) => any;
	setRasterSelectionInfo: (
		graphIdentifier: string,
		rasterConfig: RasterConfig
	) => void;
	handleAddNewRaster: (graphIdentifier: string) => void;
	submitPlot: (plotDataRef: SubmitPlotDataRef, formValues: PlotSelectionFormType) => any;
	showPlotSelectionSpinner: boolean;
	togglePlotSelectionSpinner: (arg0: boolean) => any;
	onCancel: () => void;
	isDataExplorer: boolean;
	showPopup?: boolean;
	subtitle?: string;
	sessionId?: string;
	captureSchemaData?: any;
}

export interface ConnectedComponents {
	label: string;
	value: DfInfo;
}

export type PlotSelectionFormType = {
	_id: number;
	connectedComponentInfo: DfInfo;
	aggregate_type: string;
	plot_type: PlotData["plotType"];
	additional_params: Record<string, any>;
	bandType: BandType;
	projection_code: string;
	custom_projection_code: string;
	resolution:string,
	mode: string,
    min_lat: string,
    max_lat: string,
    min_lon: string,
    max_lon: string,
};
export type MultiBandColTypes = "red" | "green" | "blue";
export type NormalColTypes = "keys" | "values" | "seriesGroupings";

export type TypesOfColumns = NormalColTypes | MultiBandColTypes;
export type SourceColumns = TypesOfColumns | DroppableIds.ColumnsList;
export type MultiBandColumnsInfo = Record<MultiBandColTypes, string[]>;

export type ColumnErrors = Partial<Record<SourceColumns, boolean>>;

export enum DroppableIds {
	ColumnsList = "ColumnsList",
	keys = "keys",
	values = "values",
	seriesGroupings = "seriesGroupings",
	red = "red",
	green = "green",
	blue = "blue",
}

// type StreamingSubscriptionInfo = Record<
// 	string,
// 	{
// 		componentId: string;
// 		subscription: StompSubscription;
// 		sessionId: string;
// 		data: PlotResponse | null;
// 	}
// >;

const projectionCodeSchema = yup
	.number()
	.typeError("Has to be a number")
	.required("Projection Code is Mandatory")
	.min(1024, "Cannot be less than 1024")
	.max(32767, "Cannot be greater than 32767")

const resolutionCodeSchema = yup
	.number()
	.typeError("Has to be a number")
	.required("This field is Mandatory")

export const PlotSelectionModal: React.FC<PlotSelectionModalProps> = ({
	setRasterSelectionInfo,
	handleAddNewRaster,
	showPlotSelectionSpinner,
	submitPlot,
	onCancel,
	togglePlotSelectionSpinner,
	isDataExplorer,
	showPopup,
	subtitle,
	sessionId,
	captureSchemaData
}) => {
	const showModal = useSelector(
		(store: RootState) => store.AnalyticsReducer.showModal.plotSelection
	);
	const dispatch = useDispatch();
	// list of components connected to input ports of componentIdForAnalytics
	// const [connectedComponents, setConnectedComponents] = useState<
	// 	ConnectedComponents[]
	// >([]);
	const [columnsList, setColumnsList] = useState<string[]>([]);
	// const [initialValues, setInitialValues] = useState<PlotSelectionFormType>(
	// 	initialFormData
	// );
	const activeTabInfo = isDataExplorer? null : useGetActiveTabInfo(
		"analytics"
	) as WorkflowAnalyticsTabInfo;

	const componentIdForAnalytics =
		activeTabInfo?.activeComponentIdForAnalytics || null;
	// const [columnErrors, setColumnErrors] = useState<ColumnErrors>({});
	const [showDiscardChangesPrompt, toggleDiscardChangesPrompt] = useState(
		false
	);

	const {
		keys,
		values,
		seriesGroupings,
		multiBandCols,
		columnErrors,
		sendAnalyticsReducerCmd,
		connectedComponents,
		initialValuesForPlotSelectionForm,
		initialDataForPlotSelection,
		rasterSelectionInfo,
	} = useAnalyticsContext();

	const handleClose = () => {
		// this is called only when the modal is closed using esc and cancel
		onCancel();
		dispatch(toggleAnalyticsModal("plotSelection", false));
		sendAnalyticsReducerCmd({ type: "RESET_PLOT_SELECTION_STATE" });
	};

	const handleColumnsListResponse = (response: ColumnsListResponse) => {
		const columnsList: string[] = []; 
		response.dataframes.forEach((df) => {
			if(df.name) {
				columnsList.push(df.name)
			}
		});
		setColumnsList(columnsList);
		togglePlotSelectionSpinner(false);
	};

	const handleColumnsListErroResponse = () => {
		handleClose();
		togglePlotSelectionSpinner(false);
		errorAlert(
			"Error retrieving columns, close the workflow and run it again"
		);
	};

	const retrieveColumns = (connectedComponentInfo: DfInfo) => {
		if (isDataExplorer || activeTabInfo?.sessionId && (componentIdForAnalytics === connectedComponentInfo.component_id)) {
			togglePlotSelectionSpinner(true);
			AnalyticsHandler.GetComponentColumnsList(
				sessionId || activeTabInfo?.sessionId,
				connectedComponentInfo.component_id,
				connectedComponentInfo.target_component_id,
				handleColumnsListResponse,
				handleColumnsListErroResponse
			);
		}
	};

	useEffect(() => {
		if(isDataExplorer) return;
		if (showModal && !!initialValuesForPlotSelectionForm.connectedComponentInfo.component_id) {
			retrieveColumns(initialValuesForPlotSelectionForm.connectedComponentInfo);
		}
	}, [initialValuesForPlotSelectionForm.connectedComponentInfo, showModal]);

	useEffect(() => {
		if(!isDataExplorer) return;
		if (isDataExplorer && (showPopup || showModal )) {
			togglePlotSelectionSpinner(true);
			handleColumnsListResponse({...captureSchemaData});
			// retrieveColumns(connectedComponentInfo);
		} else {
			dispatch(toggleAnalyticsModal("plotSelection", false));
			sendAnalyticsReducerCmd({ type: "RESET_PLOT_SELECTION_STATE" });
		}
	}, [ showPopup || showModal]);

	const setColumnErrors = useCallback(
		(columnErrors: Partial<ColumnErrors>) => {
			sendAnalyticsReducerCmd({
				type: "UPDATE_STATE",
				payload: { columnErrors },
			});
		},
		[]
	);

	const handleResetSelectedColumns = useCallback(() => {
		sendAnalyticsReducerCmd({ type: "RESET_COLUMNS" });
	}, []);

	const { selectedRasterConfig, isNewRasterPlot } = useMemo(() => {
		if (
			initialDataForPlotSelection?.plot_type === "raster" &&
			initialDataForPlotSelection.rasterInfo?.raster_configs &&
			rasterSelectionInfo
		) {
			return getSelectedRasterConfigInfo(
				initialDataForPlotSelection.rasterInfo?.raster_configs,
				rasterSelectionInfo
			);
		}
		return { selectedRasterConfig: undefined, isNewRasterPlot: false };
	}, [initialDataForPlotSelection, rasterSelectionInfo]);

	const handleRemoveColumn = (type: TypesOfColumns, columnName: string) => {
		sendAnalyticsReducerCmd({
			type: "COLUMN_REMOVE",
			payload: { type, columnName },
		});
	};

	const handleColumnDrop = (
		columnName: string,
		target: TypesOfColumns,
		source: SourceColumns,
		plotType: PlotData["plotType"]
	) => {
		sendAnalyticsReducerCmd({
			type: "COLUMN_ADD",
			payload: { plotType, columnName, target, source },
		});
		if (source !== DroppableIds.ColumnsList) {
			handleRemoveColumn(source, columnName);
		}
		// set Error of target column to false
		// setColumnErrors((colErrors) => ({ ...colErrors, [target]: false }));
	};

	const validateColumnSelection = (
		plotType: PlotData["plotType"],
		bandType: BandType
	) => {
		let columnErrors: ColumnErrors = {};
		if (plotType !== "correlation") {
			if (plotType === "geospatial") {
				columnErrors = {
					seriesGroupings: isEmpty(seriesGroupings),
				};
			} else {
				columnErrors = {
					keys: isEmpty(keys),
					values: isEmpty(values),
				};
				if (plotType === "raster" && bandType === BandType.Single) {
					columnErrors.seriesGroupings = isEmpty(seriesGroupings);
				} else if (
					plotType === "raster" &&
					bandType === BandType.Multi
				) {
					columnErrors = {
						...columnErrors,
						red: isEmpty(multiBandCols.red),
						green: isEmpty(multiBandCols.green),
						blue: isEmpty(multiBandCols.blue),
					};
				}
			}
		}
		return columnErrors;
	};

	async function handleSubmitForm(formValues: PlotSelectionFormType) {
		const columnErrors = validateColumnSelection(
			formValues.plot_type,
			formValues.bandType
		);
		setColumnErrors(columnErrors);
		if (isEmpty(Object.values(columnErrors).filter(Boolean))) {
			const submitPlotDataRef: SubmitPlotDataRef = {
				columnSelectionInfo: {
					keys,
					values,
					seriesGroupings,
					multiBandCols,
				},
				formValues,
				rasterSelectionInfo,
				currentRasterConfigs:
					initialDataForPlotSelection?.rasterInfo?.raster_configs,
			};

			submitPlot(submitPlotDataRef, formValues);
		}
		// else {
		// 	errorAlert("Cannot submit plot data as session failed to create.");
		// }
	}

	const renderSelectClearButtons = (
		type: NormalColTypes,
		selectedColumnsOfType: string[],
		disableSelectAll = false
	) => {
		const handleSelectClearAll = (selectAll = true) => {
			const __colsList = selectAll ? columnsList : [];
			if (selectAll) {
				setColumnErrors({
					...columnErrors,
					[type]: false,
				});
				sendAnalyticsReducerCmd({
					type: "UPDATE_STATE",
					payload: { [type]: __colsList },
				});
			} else {
				sendAnalyticsReducerCmd({
					type: "COLUMN_CLEAR_ALL",
					payload: type,
				});
			}
		};

		return (
			<div className="columnActions__box">
				<button
					onClick={() => handleSelectClearAll(true)}
					className="btn-selectAll"
					disabled={
						disableSelectAll ||
						selectedColumnsOfType === columnsList
					}
					type="button"
				>
					Select All
				</button>
				<button
					onClick={() => handleSelectClearAll(false)}
					className="btn-clearAll"
					disabled={isEmpty(selectedColumnsOfType)}
					type="button"
				>
					Clear All
				</button>
			</div>
		);
	};

	const isRasterFormUpdated = (formValues: PlotSelectionFormType) => {
		if (rasterSelectionInfo?.type === "edit" && selectedRasterConfig) {
			return (
				!isEqual(initialValuesForPlotSelectionForm, formValues) ||
				isRasterUpdateFormTouched(selectedRasterConfig, {
					keys,
					values,
					seriesGroupings,
					multiBandCols,
				})
			);
		} else if (rasterSelectionInfo?.type === "new") {
			return (
				!isEqual(initialValuesForPlotSelectionForm, formValues) ||
				isRasterNewFormTouched(formValues.bandType, {
					keys,
					values,
					seriesGroupings,
					multiBandCols,
				})
			);
		}
		return false;
	};

	const customProjectionCodeOptions = useMemo(() => 
		getBooleanCheckboxOptions('Custom')
	, []) 

	// disable projection code when plot is isNewRasterPlot
	// if first raster is added with projection code, second raster has to be added with projection code
	const disableProjectionCodeField = useMemo(() => !!selectedRasterConfig || isNewRasterPlot, [selectedRasterConfig, isNewRasterPlot])
	
	return (
		<Modal
			isOpen={showPopup || showModal}
			toggleClose={handleClose}
			title="Customize Plot To View"
			subtitle={
				subtitle || (componentIdForAnalytics
					? WorkflowCanvas.getNodeTitle(componentIdForAnalytics)
					: "")
			}
			className="plotSelectionModal__container"
			shouldCloseOnOverlayClick={false}
			showCloseMark
		>
			<Form
				initialValues={initialValuesForPlotSelectionForm}
				onSubmit={handleSubmitForm}
				className="plotOptionsForm"
				enableReinitialize
			>
				{({ _formikprops }) => {
					const isCurrentGraphRaster =
						_formikprops.values.plot_type === "raster";
					const disableColumnSelection =
						_formikprops.values.plot_type === "correlation";
					const isCustomProjectionCode = _formikprops.values.custom_projection_code === 'true'

					return (
						<>
							<RasterTabs
								showDiscardChangesPrompt={
									showDiscardChangesPrompt
								}
								toggleDiscardChangesPrompt={
									toggleDiscardChangesPrompt
								}
								handleAddNewRaster={handleAddNewRaster}
								setRasterSelectionInfo={setRasterSelectionInfo}
								isRasterFormUpdated={isRasterFormUpdated}
								isDataExplorer={isDataExplorer}
							/>
							{showPlotSelectionSpinner && <InPageSpinner />}
							<div
								className={classNames({
									disableSelection: disableColumnSelection,
									hide: showPlotSelectionSpinner,
								})}
							>
								{
									!isDataExplorer ? (
										<div
											className={
												"plot_selection_selectField_wrapper"
											}
										>
											<span>Select Output</span>
											<SelectField
												name="connectedComponentInfo"
												options={connectedComponents}
												className={
													"plot_selection_selectField_input"
												}
												label=""
												onOptionClick={(
													option: ConnectedComponents
												) => {
													if (
														_formikprops.values
															.connectedComponentInfo
															.target_component_id !==
														option.value.target_component_id
													)
														handleResetSelectedColumns();
													retrieveColumns(option.value);
												}}
											/>
										</div>
									) : null
								}
								<div className="split__fields--halfwide">
									<SelectField
										name="plot_type"
										options={graphTypes}
										label="Display Type"
										onOptionClick={(option) => {
											if(Env.disableRasterPlot && option.value === "raster"){
												infoAlert("Raster plot is not available with this subscription");
											}
											if (
												validateNewPlotTypeForColumnReset(
													_formikprops.values
														.plot_type,
													option.value
												)
											) {
												handleResetSelectedColumns();
											}
										}}
										// disable graph type when plot is isNewRasterPlot
										disabled={
											!!selectedRasterConfig ||
											isNewRasterPlot
										}
									/>
									{isCurrentGraphRaster  ? (
										<div className={styles.projectionField}>
											<CheckboxField 
												name="custom_projection_code"
												options={customProjectionCodeOptions}
												className={styles.customProjectionCodeCheckbox}
												disabled={disableProjectionCodeField}
											/>
											<InputField 
												name="projection_code"
												label="Projection Code"
												disabled={disableProjectionCodeField || (Env.disableRasterPlot && _formikprops.values.plot_type === "raster")}
												className={classNames({ "hide" : !isCustomProjectionCode })}
												infoText="For EPSG:32719 enter 32719."
												placeholder="32719"
												validate={isCustomProjectionCode ? FieldSchemaValidator(projectionCodeSchema as unknown as yup.StringSchema): undefined}
											/>
											<SelectField
												name="projection_code"
												options={projectionCodeOptions}
												label="Projection Code"
												className={classNames("selectField__Aggregate", { "hide" :isCustomProjectionCode })}
												disabled={disableProjectionCodeField || (Env.disableRasterPlot && _formikprops.values.plot_type === "raster")}
												infoText="Select Custom if Projection Code not in list."
											/>
										</div>
									) : (
										<SelectField
											name="aggregate_type"
											options={aggregationTypes}
											label="Aggregation"
											className="selectField__Aggregate"
											disabled={disableColumnSelection}
										/>
									)}
								</div>
								{isCurrentGraphRaster && (
									<div className="split__fields--halfwide">
										<RadioField
											options={bandTypeOptions}
											name="bandType"
											label="Select Band Type"
											inline
										/>
										<div className={_formikprops.values.mode !== "default" ? "split__fields--halfwide":""}>
											<SelectField
												name="mode"
												options={mapExtentTypes}
												label="Map Extent"
												disabled={disableProjectionCodeField || (Env.disableRasterPlot && _formikprops.values.plot_type === "raster")}
											/>
											<ShowWhenTrue show={_formikprops.values.mode !== "default"}>
												<InputField 
													name="resolution"
													label="Resolution"
													height={26}
													autoComplete="off"
													required
													disabled={disableProjectionCodeField}
													validate={FieldSchemaValidator(resolutionCodeSchema as unknown as yup.StringSchema)}
												/>
											</ShowWhenTrue>
										</div>
									</div>
								)}
								{isCurrentGraphRaster && _formikprops.values.mode === "custom" && (
									<div  className={classNames('split__fields--halfwide', 'mb-1')}
								>
										<div className="split__fields--halfwide">
											<InputField 
												name="min_lat"
												label="Min Latitude"
												autoComplete="off"
												required
												validate={FieldSchemaValidator(resolutionCodeSchema as unknown as yup.StringSchema)}
											/>
											<InputField 
												name="max_lat"
												label="Max Latitude"
												autoComplete="off"
												required
												validate={FieldSchemaValidator(resolutionCodeSchema as unknown as yup.StringSchema)}
											/>
										</div>
										<div className="split__fields--halfwide">
											<InputField 
												name="min_lon"
												label="Min Longitude"
												autoComplete="off"
												required
												validate={FieldSchemaValidator(resolutionCodeSchema as unknown as yup.StringSchema)}
											/>
											<InputField 
												name="max_lon"
												label="Max Longitude"
												autoComplete="off"
												required
												validate={FieldSchemaValidator(resolutionCodeSchema as unknown as yup.StringSchema)}
											/>
										</div>
									</div>
								)}
								<ColumnSelection
									columnsList={columnsList}
									keys={keys}
									values={values}
									seriesGroupings={seriesGroupings}
									multiBandCols={multiBandCols}
									handleRemoveColumn={handleRemoveColumn}
									handleColumnDrop={(
										column,
										target,
										source
									) =>
										handleColumnDrop(
											column,
											target,
											source,
											_formikprops.values.plot_type
										)
									}
									plotType={_formikprops.values.plot_type}
									renderSelectClearButtons={
										renderSelectClearButtons
									}
									bandType={_formikprops.values.bandType}
									columnErrors={columnErrors}
									isDataExplorer={isDataExplorer}
								/>
								{!isCurrentGraphRaster && (
									<DynamicKeyValueFieldGenerator
										keyToSaveKeyValueInfo="additional_params"
										addButtonText="+ Add a configuration"
									/>
								)}
							</div>
							<div className="btns__box">
								<button
									className="btn-md btn-cancel"
									type="button"
									onClick={handleClose}
								>
									Cancel
								</button>
								<button
									id="btn_plot_submit"
									className="btn-md btn-yellow"
									type="submit"
									disabled={showPlotSelectionSpinner || (Env.disableRasterPlot && _formikprops.values.plot_type === "raster")}
								>
									Create Plot
								</button>
							</div>
						</>
					);
				}}
			</Form>
		</Modal>
	);
};
