import React, { useRef, useEffect, useState } from 'react';
import { useField, Field, FieldProps } from 'formik';
import './styles.scss';
import { ShowWhenTrue } from '../../helpers';
import Classnames  from 'classnames';
import { TooltipTop } from '../tooltips';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { Input } from 'antd';

export interface InputFieldProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>{
    name: string;
    label?: string;
    required?: boolean;
    className?: string;
    // mozilla shows a validation error when a float is entered in number field - hence float type is used
    type?: 'text' | 'email' | 'password' | 'number' | 'textarea' | 'float';
    infoText?: string;
    placeholder?: string;
    readOnly?: boolean;
    autoComplete?: 'off' | 'on';
    autoFocus?: boolean;
    rows?: number;
    highlightDivOnError?: boolean;
    showLabelIfNotEmpty?: boolean;
    showError?: boolean;
    onClick?: (e: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement> ) => void;
    // DivProps: React.ComponentPropsWithoutRef<HTMLDivElement>
    children?: React.ReactChildren | React.ReactNode;
    as?: 'div' | 'td'; 
    validate?: any;
    onChange?: (arg0: any) => any;
    useToolTipForErrorMessage?: boolean;
    isSecureInput?: boolean
}


const InputField: React.FC<InputFieldProps> = ({ 
    as = 'div', 
    label, 
    required, 
    className, 
    children, 
    highlightDivOnError, 
    showLabelIfNotEmpty, 
    showError = false,
    autoFocus = false,
    infoText = '',
    onChange,
    useToolTipForErrorMessage = false,
    isSecureInput,
    ...props
}) => {

    const [{ onBlur } , meta] = useField({ name: props.name, validate: props.validate  });
    const input_ref= useRef<HTMLInputElement>(null);
    const show_label = !!(label) && !!(showLabelIfNotEmpty) ? meta.value !== '' : true;
    const ParentTag = as;
    const intersectionObserverRef = useRef<any>();
    const [showErrorToolTip, toggleErrorToolTip] = useState(false);
   
    useEffect(() => {
        onChange && onChange(meta.value);
    }, [meta.value]);


    const showErrorMessage = (meta.error && meta.touched) || showError;

    useEffect(() => {
        if(useToolTipForErrorMessage && showErrorMessage) {
            // THIS IT TO HIDE ERROR MESSAGE TOOLTIP WHEN THE INPUTFIELD IS NOT VISIBLE
            const handleIntersectionObserver = new IntersectionObserver((entries) => {
                if(!isEmpty(entries) && showErrorMessage) {
                    // console.log(entries[0].intersectionRatio);
                    if(entries[0].intersectionRatio !== 0) {
                        toggleErrorToolTip(true);
                    } else {
                        toggleErrorToolTip(false);
                    }
                }});
            intersectionObserverRef.current = handleIntersectionObserver;
            input_ref.current && handleIntersectionObserver.observe(input_ref.current);
            return () => {
                if(input_ref.current) intersectionObserverRef.current?.unobserve(input_ref.current);
            };
        }
    }, [showErrorMessage, useToolTipForErrorMessage]);

    
    return (
        <ParentTag 
            className={Classnames(
                'inputfield__container', 
                {'textarea__container': props.type === 'textarea'},
                { [className as string]: !!(className) }, 
                {'inputfield--error': highlightDivOnError && showErrorMessage}  
            )}
            onClick={() => input_ref.current && input_ref.current.focus()}
            data-fieldname={props.name}
        >
            <ShowWhenTrue show={show_label}>
                <label 
                    className="inputfield__label"
                    htmlFor={props.name}
                >
                    <span className={required ? 'red-star': ''}>{label}</span>
                    <ShowWhenTrue show={!!infoText}>
                        <TooltipTop placement="topRight" overlay={infoText}>
                            <img src="/icons/info-fields.png" width="16" height="16" className="info__icon" alt="information-icon" />
                        </TooltipTop>
                    </ShowWhenTrue>
                </label>
            </ShowWhenTrue>
            <TooltipTop 
                placement='bottomRight' 
                overlay={meta.error} 
                visible={useToolTipForErrorMessage && showErrorMessage && showErrorToolTip}
                className="errorToolTip"
                hideCaret={false}
                align={{ offset: [7, -9]}}
            >
                <Field 
                    className={classNames('inputfield', {'inputfield--error': !highlightDivOnError && showErrorMessage, 'showCrossIcon': useToolTipForErrorMessage})}
                    // validate={props.validate ? props.validate(): null}
                    innerRef={input_ref}
                    autoFocus={autoFocus}
                    // style={_style}
                    as={props.type === 'textarea' ? 'textarea': ''}
                    value={meta.value || ''}
                    readOnly={!!(props.readOnly)}
                    id={props.name}
                    onBlur={onBlur}
                    render={isSecureInput && (({ field }: FieldProps<any>) => <Input.Password {...field} />)}
                    {...props}
                />
            </TooltipTop>
            {!useToolTipForErrorMessage && showErrorMessage && <span className="inputfield__errormessage" role="error-message">{meta.error}</span>}
            {children}
        </ParentTag>
    ); 
};

// since the function inside forward ref has no name, it needs a display name for dev tools
InputField.displayName = 'InputField';

export { InputField };