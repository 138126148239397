import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { cloneDeep, get, has } from 'lodash';
import React, { useMemo } from 'react';
import { uuid } from 'uuidv4';
import { PythonSingleModelParamInfo, PythonSingleModelTypeInfoOptions, TensorflowExtraModelParamsInfo } from '.';
import { ShowWhenTrue } from '../../../../helpers';
import { SelectField } from '../../../form/select-field';
import { NewExperimentAddIcon } from '../../icons';
import { HyperParamsInfo, TensorflowExtraModelParams } from '../enums';
import { getStageKey, getTensorflowModelParamsKey, getValueInPipelineStage } from '../utils';
import { BuildPipelineRHSProps, MlPipelineStageInfo } from './build-pipeline-RHS';
import { HyperParamsTable } from './hyper-params-table';

type TensorflowModelParams = {
    extraModelParamsInfo: TensorflowExtraModelParamsInfo;
    handleModelParamStagesInfo: BuildPipelineRHSProps['handleModelParamStagesInfo'];
    onMultiOptimizerSelection: (selected: boolean) => void;
}

type ModelParamStage = {
    modelParamType: TensorflowExtraModelParams;
    modelParamInfo: PythonSingleModelParamInfo;
    stageId: string;
    createNewStage: (position: number, modelParamType: TensorflowExtraModelParams) => void;
    deleteStage: (stageId: string) => void;
    stagePosition: number;
    stageInfo: {create: boolean; delete: boolean; isMultiOptimizerStage: boolean; stageInfoForMultiOptimizer ?: MlPipelineStageInfo};
    onMultiOptimizerSelection: TensorflowModelParams['onMultiOptimizerSelection'];
}


const ModelParamStage: React.FC<ModelParamStage> = ({ modelParamInfo, modelParamType, stageId, stagePosition, createNewStage, deleteStage, stageInfo, onMultiOptimizerSelection }) => {
    const { values, setFieldValue } = useFormikContext<any>();


    const selectedModelParamKey = getTensorflowModelParamsKey(stageId, modelParamType, 'type'); 
    const selectedModelParam = get(values, selectedModelParamKey);

    const hyperParams: HyperParamsInfo['info'] = useMemo(() => {
        return modelParamInfo?.params[selectedModelParam]?.info || [];
    }, [selectedModelParam, modelParamInfo]);

    const isOptimizerType = modelParamType === TensorflowExtraModelParams['optimizers']

    const stageInfoForMultiOptimizer = stageInfo.stageInfoForMultiOptimizer

    return (
        <div
            className={classNames("modelParam__box", {
                "multiOptimizer__stage": stageInfo.isMultiOptimizerStage
            })}
        >
            <div>
            <SelectField 
                name={selectedModelParamKey}
                options={modelParamInfo.options}
                label={<>
                    {stageInfoForMultiOptimizer ? 
                        getValueInPipelineStage(values, 'model_type', stageInfoForMultiOptimizer.id)
                        :
                        modelParamInfo.label
                    }
                    <ShowWhenTrue show={stageInfo.delete}>
                        <button
                            onClick={() => deleteStage(stageId)}
                            id="btn_delete_modelParamStage"
                        >
                            <img src="/icons/delete-filled.svg" alt="delete-stage"/>
                        </button>
                    </ShowWhenTrue>
                </>}
                className="modelParam__selectField"
                onOptionClick={(option: PythonSingleModelTypeInfoOptions) => {
                    const selectedModelParamPythonModuleKey = getTensorflowModelParamsKey(stageId, modelParamType, 'python_module'); 
                    setFieldValue(selectedModelParamPythonModuleKey, option.pythonModule);
                    if(!stageInfo.isMultiOptimizerStage  && isOptimizerType) {
                        onMultiOptimizerSelection(option.value === 'MultiOptimizer')
                    }
                }}
            />
            </div>
            <HyperParamsTable 
                name={getTensorflowModelParamsKey(stageId, modelParamType, 'params', selectedModelParam)}
                hyperParams={hyperParams}
                showTypeColumn={false}
                hideTune
            />
            <ShowWhenTrue show={stageInfo.create}>
                <button
                    className="btn-grey-transparent btn_new_stage btn_new_modelParamStage"
                    onClick={() => createNewStage(stagePosition+1, modelParamType)}
                    id="btn_new_stage_1"
                >
                    <NewExperimentAddIcon /> <span>New {modelParamInfo.label}</span>
                </button>
            </ShowWhenTrue>
        </div>
    );
}; 



export const TensorflowModelParams: React.FC<TensorflowModelParams> = ({
    extraModelParamsInfo,
    handleModelParamStagesInfo,
    onMultiOptimizerSelection
}) => {
    const { setFieldValue } = useFormikContext<any>();

    const [[modelParamStagesInfo, setModelParamStagesInfo], _modelParamStagesCreateDeleteInfo] = useMemo(() => {
        const _modelStagesInfo: Record<string, ModelParamStage['stageInfo']> = {};

        const multipleStagesCreateRef: Record<string, number> = {};
        const multipleStagesDeleteRef: Record<string, number> = {};

        const modelParamStagesInfo = handleModelParamStagesInfo[0];

        modelParamStagesInfo.forEach((stage, index) => {
            _modelStagesInfo[stage.id] = {
                create: false,
                delete: false,
                isMultiOptimizerStage: !!stage.isMultiOptimizerStage,
                stageInfoForMultiOptimizer: stage.pipelineStageInfoForMultiOptimizer
            };
            if(stage.selectionType === 'multiple') {
                multipleStagesCreateRef[stage.type] = index;
                if(has(multipleStagesDeleteRef, stage.type)) {
                    multipleStagesDeleteRef[stage.type] += 1;
                } else {
                    multipleStagesDeleteRef[stage.type] = 1;
                }
            } 
        });

        modelParamStagesInfo.forEach((stage, index) => {
            if(stage.selectionType === 'multiple') {
                if(multipleStagesCreateRef[stage.type] === index) {
                    _modelStagesInfo[stage.id].create = true;
                }
                if(multipleStagesDeleteRef[stage.type] > 1) {
                    _modelStagesInfo[stage.id].delete = true;
                }
            }
        });

        return [handleModelParamStagesInfo, _modelStagesInfo];
    }, [handleModelParamStagesInfo]);

    

    const getModelParamInfo = (modelParamType: TensorflowExtraModelParams): PythonSingleModelParamInfo => 
        extraModelParamsInfo?.[modelParamType];

    const handleCreateNewStage = (selectedPosition: number, modelType: TensorflowExtraModelParams) => {
        const __modelParamStagesInfo = cloneDeep(modelParamStagesInfo);
        __modelParamStagesInfo.splice(selectedPosition, 0, { id: uuid(), type: modelType, selectionType: 'multiple' });
        setModelParamStagesInfo(__modelParamStagesInfo);
    };

    const handleDeleteStage = (stageId: string) => {
        setModelParamStagesInfo(stages => stages.filter(stage => stage.id !== stageId));
        setFieldValue(getStageKey(stageId), null);
    };

    return(
        <div
            className="pipelineStage__form tensorflowParams__innerBox"
        >
            {
                modelParamStagesInfo.map((stageInfo, index) => (
                    <ModelParamStage
                        key={stageInfo.id}
                        stageId={stageInfo.id}
                        modelParamInfo={getModelParamInfo(stageInfo.type)}
                        modelParamType={stageInfo.type}
                        stagePosition={index}
                        createNewStage={handleCreateNewStage}
                        deleteStage={handleDeleteStage}
                        stageInfo={_modelParamStagesCreateDeleteInfo[stageInfo.id]}
                        onMultiOptimizerSelection={onMultiOptimizerSelection}
                    />
                ))
            }
        </div>
    );
};