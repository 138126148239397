import { Modal } from "@components/modals";
import { errorAlert, successAlert } from "@components/toastify/notify-toast";
import { WorkflowConfig } from "@services/WorkflowConfig";
import classNames from "classnames";
import { cloneDeep } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ImportJSONIcon } from "../../assets/common";
import styles from "../styles.module.scss";
import { parseConfigJson } from "./ConfigParser";
import ImportedConfigItemsSelection from "./ImportedConfigItemsSelection";

enum Steps {
	Processing = "processing",
	ImportedConfigItemsSelection = "importedConfigItemsSelection",
}

export type SelectedConfigItems = Record<string, boolean>;

type Props = {
	config: WorkflowConfig;
	onWorkflowConfigUpdate: (config: WorkflowConfig) => void;
};

const WorkflowConfigImport: React.FC<Props> = ({
	config,
	onWorkflowConfigUpdate,
}) => {
	const [importedConfig, setImportedConfig] = useState<WorkflowConfig>([]);
	const [currentStep, setCurrentStep] = useState<null | Steps>(null);
	const [selectedConfigItemsCount, setSelectedConfigItemsCount] = useState<
		number
	>(0);

	const onDropAccepted = (files: File[]) => {
		if (files.length) {
			setCurrentStep(Steps.Processing);

			parseConfigJson(
				files[0],
				(config) => {
					setImportedConfig(config);
					setCurrentStep(Steps.ImportedConfigItemsSelection);
				},
				(error) => { 
					setCurrentStep(null)
					errorAlert(error || "Error parsing config")
				}
			);
		}
	};

	const { getRootProps, getInputProps } = useDropzone({
		multiple: false,
		accept: [".json"],
		onDropAccepted,
	});

	const showModal = !!currentStep;
	const closeModal = useCallback(() => setCurrentStep(null), [
		setCurrentStep,
	]);

	const { title, subtitle } = useMemo(() => {
		let title = "",
			subtitle = "";

		if (currentStep === Steps.Processing) {
			title = "Processing...";
			subtitle = "This might take some time";
		} else if (currentStep === Steps.ImportedConfigItemsSelection) {
			title = "Import Configurations";
			subtitle = !selectedConfigItemsCount
				? "No Configuration selected"
				: `${selectedConfigItemsCount} Configuration${
						selectedConfigItemsCount > 1 ? "s" : ""
				  } selected`;
		}

		return {
			title,
			subtitle,
		};
	}, [currentStep, selectedConfigItemsCount]);

	const onImportedConfigItemSelectionSubmit = useCallback(
		(
			newConfigItems: WorkflowConfig,
			existingConfigItems: WorkflowConfig
		) => {
			const _config = [...cloneDeep(config), ...newConfigItems];
			existingConfigItems.forEach((existingConfigitem) => {
				const idx = _config.findIndex(
					(configItem) => configItem.key === existingConfigitem.key
				);
				if (idx !== -1) {
					// only value and type are changed as id is already referenced in components
					_config[idx] = { ..._config[idx], value: existingConfigitem.value, type: existingConfigitem.type };
				}
			});
			onWorkflowConfigUpdate(_config);
			successAlert("Imported successfully")
			closeModal();
		},
		[config, onWorkflowConfigUpdate]
	);

	return (
		<>
			<div
				{...getRootProps()}
				className={classNames(
					"btn btn-md btn-grey-transparent",
					styles.deleteConfigItemsButton
				)}
			>
				<ImportJSONIcon />
				Import JSON
				<input {...getInputProps()} />
			</div>
			<Modal
				isOpen={showModal}
				toggleClose={closeModal}
				title={title}
				subtitle={subtitle}
				className={classNames(styles.configImportModal, {
					[styles[currentStep as Steps]]: currentStep,
				})}
			>
				{currentStep === Steps.ImportedConfigItemsSelection && (
					<ImportedConfigItemsSelection
						importedConfig={importedConfig}
						currentConfig={config}
						setSelectedConfigItemsCount={
							setSelectedConfigItemsCount
						}
						onSubmit={onImportedConfigItemSelectionSubmit}
					>
						<div className="modalBtns__box">
							<button
								className="btn-md btn-cancel"
								type="button"
								onClick={closeModal}
							>
								Cancel
							</button>
							<button className="btn-md btn-yellow" type="submit" disabled={!selectedConfigItemsCount}>
								Import
							</button>
						</div>
					</ImportedConfigItemsSelection>
				)}
			</Modal>
		</>
	);
};

export default WorkflowConfigImport;
