import React, { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import moment from "moment";
import styles from "../styles.module.scss";
import Form, { InputField, NewSelectField } from "@components/form";
import { _selectoptionType } from "@components/form/select-field";
import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { DataSourceHandler } from "@api/data-source-handler";
import { awsRegions } from "@pages/file-browser/ui/StorageConnectionForm";
import { ShowWhenTrue } from "@helpers/showwhentrue";
import { infoAlert } from "@components/toastify/notify-toast";
import CustomDateTimePicker from "@components/form/date-picker";
import { isEmpty } from "lodash";

const workspaceFormSchema = yup.object({
	workspaceType: yup.string().required("This is a required field"),
	workspaceName: yup.string().required("This is a required field"),
	workspaceUrl: yup
		.string()
		.required("This is a required field"),
	npipWorkSpace: yup.boolean(),
	useDockerImage: yup.boolean(),
	configType: yup.string(),
	patToken: yup.string().when(["configType", "workspaceType"], {
		is: (configType, workspaceType) => configType === "PAT" && workspaceType === "databricks" || workspaceType === "cloudera",
		then: yup.string().required("This is a required field"),
	}),
	// expiryTime: yup.string().when(["configType", "workspaceType"], {
	// 	is: (configType, workspaceType) => configType === "PAT" && workspaceType === "databricks",
	// 	then: yup.string().matches(
	// 		/^\d+$/,
	// 		"Only epoch time is allowed"
	// 	),
	// }),
	clientId: yup.string().when(["configType", "workspaceType"], {
		is: (configType, workspaceType) => (configType === "ASP" && workspaceType === "databricks"),
		then: yup.string().required("This is a required field"),
	}),

	clientSecret: yup.string().when(["configType", "workspaceType"], {
		is: (configType, workspaceType) => configType === "ASP" && workspaceType === "databricks",
		then: yup.string().required("This is a required field"),
	}),
	tenantId: yup.string().when(["configType", "workspaceType"], {
		is: (configType, workspaceType) => configType === "ASP" && workspaceType === "databricks",
		then: yup.string().required("This is a required field"),
	}),
	username: yup.string().when("workspaceType", {
		is: "cloudera",
		then: yup.string().required("This is a required field"),
	}),
	accessKey: yup.string().when("workspaceType", {
		is: "cloudera",
		then: yup.string().required("This is a required field"),
	}),
	secretKey: yup.string().when("workspaceType", {
		is: "cloudera",
		then: yup.string().required("This is a required field"),
	}),
	region: yup.string().when("workspaceType", {
		is: "cloudera",
		then: yup.string().required("This is a required field"),
	}),
	bucketName: yup.string().when("workspaceType", {
		is: "cloudera",
		then: yup.string().required("This is a required field"),
	}),
	cloudProvider: yup.string().when("workspaceType", {
		is: "databricks",
		then: yup
			.string()
			.required("This is a required field"),
	}),
});

export type WorkspaceFormValues = yup.InferType<typeof workspaceFormSchema>;

export type WorkSpaceFormProps = {
	type: "UPDATE" | "ADD";
	initialValues: any;
	submitButtons?: React.ReactNode;
	onSubmit?: (arg0: WorkspaceFormValues) => any;
};

const workspaceTypeOptions = [
	{ value: "databricks", label: "Databricks" }, 
	{ value: "cloudera", label: "Cloudera" }
];
const cloudProviderTypeOptions = [{ value: "Aws", label: "Aws" }, { value: "Azure", label: "Azure" }];

export const WorkspaceForm: React.FC<WorkSpaceFormProps> = ({
	onSubmit,
	type,
	initialValues,
	submitButtons,
}) => {
	const [awsConnectionList,setAwsConnectionList] = useState<_selectoptionType[]>([]);
	const handleFormSubmit = (values: WorkspaceFormValues) => {
		onSubmit && onSubmit(values);
	};

	const [configType, setConfigType] = useState(
		initialValues?.configType || "PAT"
	);
	const [npipType, setNpipType] = useState(
		initialValues?.npipWorkSpace || true
	);
	const [dockerImageType, setDockerImageType] = useState(
		type ==="UPDATE" ?initialValues?.useDockerImage : true
	);

	const initialValuesForForm: WorkspaceFormValues = useMemo(() => {
		return {
			workspaceType: initialValues?.workspaceType || workspaceTypeOptions[0].value,
			workspaceName: initialValues?.workspaceName || "",
			workspaceUrl: initialValues?.workspaceUrl || "",
			npipWorkSpace: initialValues?.npipWorkSpace || true,
			useDockerImage:type ==="UPDATE" ?initialValues?.useDockerImage : true,
			configType: initialValues?.configType || "PAT",
			patToken: initialValues?.patToken || "",
			expiryTime: initialValues?.expiryTime || "",
			clientId: initialValues?.clientId || "",
			clientSecret: initialValues?.clientSecret || "",
			tenantId: initialValues?.tenantId || "",
			username: initialValues?.username || "",
			cloudProvider: initialValues?.cloudProvider || "Azure",
			accessKey: initialValues?.accessKey || "",
			secretKey: initialValues?.secretKey || "",
			region: initialValues?.region || "",
			bucketName: initialValues?.bucketName || "",
			expiryTimeType:initialValues?.expiryTimeType || false
		};
	}, [initialValues, type]);

	useEffect(() => {
		DataSourceHandler.GetAWSConnectionList((res) => {
			setAwsConnectionList(
				res.sort().map((item) => {
					return {
						label: item.name,
						value: item.id,
					};
				})
			);
		});
	}, []);

	const initialExpiryTime = initialValues?.expiryTime ? moment(initialValues.expiryTime, "YYYY,M,D,H,m") : moment();

	const {
		value: expirytimeValue,
		setValue: setExpiryTimeValue,
	} = CustomDateTimePicker.useCustomBasicController({ 
		initialValue: initialExpiryTime
	});

	const handleSetDtValue = (newDate: moment.Moment | null,setFieldValue:any) => {
		setExpiryTimeValue(newDate);
		setFieldValue("expiryTime", newDate);
	};

	return (
		<Form
			initialValues={initialValuesForForm}
			validationSchema={workspaceFormSchema}
			onSubmit={handleFormSubmit}
			enableReinitialize
		>
			{({ _formikprops: { setFieldValue, values, errors } }) => (
				<>
					<div className={styles["workspaceForm"]}>
						<NewSelectField
							name="workspaceType"
							label="Workspace Type"
							options={workspaceTypeOptions}
							required={true}
							className="mb-0"
						/>
						{values.workspaceType === "databricks" && <div>
							<p className="config-type-text">
								Configuration Type
							</p>
							<Radio.Group
								name="configType"
								onChange={(e: RadioChangeEvent) => {
									setConfigType(e.target.value);
									setFieldValue("configType", e.target.value);
								}}
								value={configType}
							>
								<Radio value="PAT">Personal Access Token</Radio>
								<Radio value="ASP" disabled>
									Azure Service Principle
								</Radio>
							</Radio.Group>
						</div>}
						{values.workspaceType === "databricks" && <div>
							<div>
								<p className="config-type-text">
									Enable No Public IP
								</p>
								<Radio.Group
									name="npipWorkSpace"
									className="mb-1"
									value={npipType}
									onChange={(e: RadioChangeEvent) => {
										setNpipType(e.target.value);
										setFieldValue(
											"npipWorkSpace",
											e.target.value
										);
									}}
								>
									<Radio value={true} checked={npipType}>
										Yes
									</Radio>
									<Radio value={false} checked={npipType}>
										No
									</Radio>
								</Radio.Group>
							</div>
							<div>
								<p className="config-type-text">
									Support Docker 
								</p>
								<Radio.Group
									name="useDockerImage"
									className="mb-1"
									value={dockerImageType}
									onChange={(e: RadioChangeEvent) => {
										if(type==="ADD"){
											setDockerImageType(e.target.value);
											setFieldValue(
												"useDockerImage",
												e.target.value
											);
										}else{
											infoAlert("not allowed!")
										}	
									}}
								>
									<Radio  value={true} checked={dockerImageType}>
										Yes
									</Radio>
									<Radio  value={false} checked={dockerImageType}>
										No
									</Radio>
								</Radio.Group>
							</div>
							<NewSelectField
								name="cloudProvider"
								label="Cloud Provider Type"
								options={cloudProviderTypeOptions}
								required={true}
								className="mb-0"
							/>
						</div>}
						<InputField
							name="workspaceName"
							label="Workspace Name"
							placeholder="Add Workspace Name"
							required={true}
							autoComplete="off"
						/>
						<InputField
							name="workspaceUrl"
							label="Workspace URL"
							placeholder="Add URL"
							required={true}
							autoComplete="off"
						/>
						{configType === "PAT" && values.workspaceType === "databricks" && (
							<>
								<InputField
									name="patToken"
									label="PAT Token / Password"
									className="secure_input_field"
									required={true}
									isSecureInput
								/>
								{type=='UPDATE'?<span style={{color:'red'}}>Note: Please update the clusters if there are any single-user clusters.</span>:""}
								<div>
									<p className="config-type-text">
									Expiry Time
									</p>
									<Radio.Group
										name="expiryTimeType"
										className="mb-1"
										value={values?.expiryTimeType}
										onChange={(e: RadioChangeEvent) => {
											setFieldValue("expiryTimeType", e.target.value);
										}}
									>
										<Radio  value={true} checked={values?.expiryTimeType}>
											Yes
										</Radio>
										<Radio   value={false} checked={values?.expiryTimeType}>
											Never Expire
										</Radio>
									</Radio.Group>
								</div>
								<ShowWhenTrue show={values.expiryTimeType}>
									{/* <InputField
										name="expiryTime"
										label="Expiry Time"
										placeholder="Add Expiry Time"
										required={false}
									/> */}
									<CustomDateTimePicker
										label=""
										value={expirytimeValue}
										type="basic"
										setValue={(newDate)=>handleSetDtValue(newDate,setFieldValue)}
										disablePastDates
										showTime
									/>
								</ShowWhenTrue>
							</>
						)}
						{configType === "ASP" && values.workspaceType === "databricks" && (
							<>
								<InputField
									name="clientId"
									label="Client Id"
									required={true}
									autoComplete="off"
								/>
								<InputField
									name="clientSecret"
									className="secure_input_field"
									label="Client Secret"
									isSecureInput
									required={true}
									autoComplete="off"
								/>
								<InputField
									name="tenantId"
									label="Tenant Id"
									required={true}
									autoComplete="off"
								/>
							</>
						)}

						{values.workspaceType === "cloudera" && (
							<>
								<InputField
									name="patToken"
									label="PAT Token / Password"
									className="secure_input_field"
									required={true}
									isSecureInput
								/>
								<InputField
									name="username"
									label="User Name"
									required={true}
									autoComplete="off"
								/>
								<NewSelectField
									name="region"
									label="Region"
									options={awsRegions}
									required={true}
									className="mb-0"
								/>
								<InputField
									name="accessKey"
									className="secure_input_field"
									label={"Access Key"}
									required={true}
									autoComplete="off"
									isSecureInput
								/>
								<InputField
									name="secretKey"
									className="secure_input_field"
									label={"Secret Key"}
									required={true}
									autoComplete="off"
									isSecureInput
								/>
								<InputField
									name="bucketName"
									label={"Bucket Name"}
									required={true}
									autoComplete="off"
								/>
							</>
						)}
					</div>
					{!!submitButtons && submitButtons}
				</>
			)
			}
		</Form>
	);
};
