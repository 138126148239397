import React from 'react';

export const WorkflowIcon = () => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.95937 9.19536C6.59379 9.19536 7.91875 7.8704 7.91875 6.23599C7.91875 4.60157 6.59379 3.27661 4.95937 3.27661C3.32496 3.27661 2 4.60157 2 6.23599C2 7.8704 3.32496 9.19536 4.95937 9.19536Z"
            fill="#EE534F"
        />
        <path
            opacity="0.1"
            d="M3.20855 6.2361C3.20855 4.80872 4.2191 3.61755 5.56375 3.33856C5.36863 3.2981 5.16656 3.27661 4.95945 3.27661C3.325 3.27661 2 4.60165 2 6.2361C2 7.87056 3.325 9.19556 4.95949 9.19556C5.1666 9.19556 5.36867 9.17407 5.56379 9.1336C4.2191 8.85462 3.20855 7.66345 3.20855 6.2361Z"
            fill="black"
        />
        <path
            d="M18.7897 21.3333C20.4241 21.3333 21.7491 20.0083 21.7491 18.3739C21.7491 16.7395 20.4241 15.4146 18.7897 15.4146C17.1553 15.4146 15.8303 16.7395 15.8303 18.3739C15.8303 20.0083 17.1553 21.3333 18.7897 21.3333Z"
            fill="#029AE6"
        />
        <path
            opacity="0.1"
            d="M17.0387 18.374C17.0387 16.9467 18.0492 15.7554 19.3938 15.4765C19.1987 15.436 18.9966 15.4146 18.7895 15.4146C17.1551 15.4146 15.8301 16.7396 15.8301 18.374C15.8301 20.0085 17.1551 21.3335 18.7895 21.3335C18.9966 21.3335 19.1987 21.312 19.3938 21.2715C18.0492 20.9926 17.0387 19.8014 17.0387 18.374Z"
            fill="black"
        />
        <rect
            x="2.23096"
            y="15.6455"
            width="5.45707"
            height="5.45707"
            fill="#66BB6A"
        />
        <rect
            opacity="0.1"
            x="2.23096"
            y="15.6455"
            width="1.61141"
            height="5.45707"
            fill="black"
        />
        <path
            d="M22 6.23584L18.7897 9.44616L15.5794 6.23584L18.7897 3.02552L22 6.23584Z"
            fill="#D14FFF"
        />
        <path
            opacity="0.1"
            d="M16.7877 6.23627L19.3938 3.63018L18.7895 3.02588L15.5791 6.23627L18.7895 9.44662L19.3938 8.84232L16.7877 6.23627Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.17323 10.4203C5.13011 10.3771 5.07312 10.3459 5.01261 10.3366C4.9548 10.3277 4.89796 10.3331 4.84354 10.3549C4.8069 10.3696 4.77378 10.3924 4.74589 10.4203L3.53187 11.6343C3.41386 11.7523 3.41386 11.9436 3.53187 12.0615C3.64987 12.1796 3.84116 12.1796 3.95913 12.0615L4.65745 11.3632V13.9766C4.65745 14.1435 4.79272 14.2788 4.9596 14.2788C5.12647 14.2788 5.26175 14.1435 5.26175 13.9766V11.3632L5.96011 12.0615C6.01909 12.1206 6.09644 12.1501 6.17374 12.1501C6.25104 12.1501 6.32839 12.1206 6.38737 12.0615C6.50538 11.9435 6.50538 11.7523 6.38737 11.6343C6.38729 11.6343 5.17327 10.4203 5.17323 10.4203Z"
            fill="#6D707E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.2175 12.549C20.0994 12.431 19.9082 12.431 19.7902 12.549L19.0919 13.2473V10.6339C19.0919 10.4671 18.9566 10.3318 18.7897 10.3318C18.6228 10.3318 18.4876 10.4671 18.4876 10.6339V13.2473L17.7892 12.549C17.6712 12.431 17.4799 12.431 17.3619 12.549C17.2439 12.667 17.2439 12.8583 17.3619 12.9763L18.576 14.1903C18.6623 14.2766 18.7987 14.3035 18.9107 14.2536C18.945 14.2383 18.9768 14.217 19.0034 14.1903L20.2174 12.9763C20.3354 12.8583 20.3354 12.667 20.2175 12.549Z"
            fill="#6D707E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.6058 6.40375C13.64 6.35148 13.6584 6.29129 13.6569 6.22875C13.655 6.15207 13.6227 6.07676 13.5685 6.02254L12.3545 4.80848C12.2364 4.69047 12.0452 4.69047 11.9272 4.80848C11.8092 4.92648 11.8092 5.11777 11.9272 5.23574L12.6255 5.9341H10.0121C9.84523 5.9341 9.70996 6.06934 9.70996 6.23625C9.70996 6.40313 9.84523 6.5384 10.0121 6.5384H12.6255L11.9272 7.23672C11.8092 7.35473 11.8092 7.54602 11.9272 7.66398C11.9862 7.72297 12.0635 7.7525 12.1408 7.7525C12.2181 7.7525 12.2955 7.72301 12.3545 7.66398C12.3545 7.66398 13.5685 6.44996 13.5685 6.44992C13.5826 6.43586 13.595 6.42035 13.6058 6.40375Z"
            fill="#6D707E"
        />
    </svg>
);