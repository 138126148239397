import { Timeline } from 'antd';
import moment from 'moment-mini';
import React from 'react';
import { Modal } from '../../../components/modals';
import { PiRequestStates } from '../pi-managed-requests-table';
import styles from '../styles.module.scss';
import clsx from "classnames";

interface ViewRequestDataModalProps {
  isOpen: boolean;
  data: any[];
  onClose: () => void;
  title: string;
}

export const ViewLogsRequestDataModal: React.FC<ViewRequestDataModalProps> = ({ isOpen, data, onClose, title }) => {

  const getColor = (state: string) => {
    let color = "#ffffff";
    switch (state) {
      case PiRequestStates.New:
        color = "#ffffff";
        break;
      case PiRequestStates.Submitted:
        color = "#3dbef7";
        break;
      case PiRequestStates.Queued:
        color = "#0099f2";
        break;
      case PiRequestStates.Failed:
        color = "#ff9700";
        break;
      case PiRequestStates.Running:
        color = "#0fc981";
        break;
      case PiRequestStates.Stopped:
        color = "#ff7766";
        break;
      case PiRequestStates.Success:
        color = "#15ea83";
        break;
      case PiRequestStates.Retrying:
        color = "#08c46a";
        break;
      case PiRequestStates.Stopping:
        color = "#ff9a82";
    }
    return color;
  }

  const renderState = (state: string) => (
    <div
      className={clsx(styles.requestState, styles[state])}
    >
      <span>{state}</span>
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      toggleClose={onClose}
      title={title}
      subtitle={""}
      className="viewLogsModal__container"
      showCloseMark
    >
      <div style={{ paddingTop: 10, maxHeight: 320, overflowY: 'auto' }}>
        <Timeline>
          {data.map((item) => <Timeline.Item key={item.id} color={getColor(item.state)} style={{ color: '#fff' }}>{renderState(item.state)}  updated on {moment(item.stateUpdatedDate).format('MMMM Do YYYY, h:mm:ss a')}</Timeline.Item>)}
        </Timeline>
      </div>
    </Modal>
  );
};