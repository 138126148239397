
import { CLUSTER_CREATION_COUCHMARK, USER_STATUS_COUCHMARK } from './constants';

export const completedCMCluster = (value: number) => ({  
    type: CLUSTER_CREATION_COUCHMARK, 
    payload: {
        cluster_cm_flow_index: value
    }});

export const initCoachMarkUserSettings = (value: any) => ({  
    type: USER_STATUS_COUCHMARK, 
    payload: {
        init_status : value
    }})



