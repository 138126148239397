import { RESET_STATE } from '../types';

export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const ADD_NEW_SYNC_SET_INFO = 'ADD_NEW_SYNC_SET_INFO';


export type ActionTypes = {
    type: typeof TOGGLE_MODAL | typeof ADD_NEW_SYNC_SET_INFO | typeof RESET_STATE;
    payload?: any;
}

export type TagBrowserReducerState = {   
    showModal: { 
        editPlantSite: boolean;
        editSyncSets: boolean;
        deleteAction: boolean;
    };
    newSyncSetInfo: {id: number; key: string};

}