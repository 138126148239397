/* global SITE_CONFIG */
/* eslint-disable */
import geoblaze from 'geoblaze';
import GeoRasterLayer from './georaster-layer-for-leaflet';
import  { fromUrl } from 'geotiff';
import { plot, addColorScale } from './plotty/plotty';
import calcStats from "calc-stats";
import { get, has, range, set, reverse } from 'lodash';
import { ColorRamps, RasterPlotFailureMessages } from '../../pages/workflow-analytics-page/enums';
import chroma from 'chroma-js';

const getResolution = () => {
  return 256

}


const options = {
  calcCount : false,
  calcHistogram : false,
  calcInvalid : false,
  calcMax : true,
  calcMean : false,
  calcMedian : false,
  calcMin : true,
  calcMode : false,
  calcModes : false,
  calcRange : false,
  calcStd : false,
  calcSum : false,
  calcValid : false,
  calcVariance : false,
  calcUniques : false,
}

const cache = {}


const getInvertedColorRampColor = (colorRamp) => colorRamp + '_inverted';


(function(){
  const positionsFor11Colors = [...range(0, 1, 1/10), 1]
  const positionsFor9Colors = [...range(0, 1, 1/8), 1]

  const greyColors = ['#000000','#ffffff'];
  const spectralColors = ['#9e0142', '#d53e4f', '#f46d43', '#fdae61', '#fee08b', '#ffffbf', '#e6f598', '#abdda4', '#66c2a5', '#3288bd', '#5e4fa2'];
  const rdylgnColors = ['#a50026', '#d73027', '#f46d43', '#fdae61', '#fee08b', '#ffffbf', '#d9ef8b', '#a6d96a', '#66bd63', '#1a9850', '#006837'];
  const rdpuColors = ['#fff7f3', '#fde0dd', '#fcc5c0', '#fa9fb5', '#f768a1', '#dd3497', '#ae017e', '#7a0177', '#49006a'];
  const ylgnbuColors = ['#ffffd9', '#edf8b1', '#c7e9b4', '#7fcdbb', '#41b6c4', '#1d91c0', '#225ea8', '#253494', '#081d58'];


  addColorScale(ColorRamps.Grey, greyColors, [0, 1]);
  addColorScale(ColorRamps.Spectral, spectralColors, positionsFor11Colors)
  addColorScale(ColorRamps.RDYlGn, rdylgnColors, positionsFor11Colors)
  addColorScale(ColorRamps.RdPu, rdpuColors, positionsFor9Colors)
  addColorScale(ColorRamps.YlGnBu, ylgnbuColors, positionsFor9Colors)

  addColorScale(getInvertedColorRampColor(ColorRamps.Grey), reverse([...greyColors]), [0, 1]);
  addColorScale(getInvertedColorRampColor(ColorRamps.Spectral), reverse([...spectralColors]), positionsFor11Colors)
  addColorScale(getInvertedColorRampColor(ColorRamps.RDYlGn), reverse([...rdylgnColors]), positionsFor11Colors)
  addColorScale(getInvertedColorRampColor(ColorRamps.RdPu), reverse([...rdpuColors]), positionsFor9Colors)
  addColorScale(getInvertedColorRampColor(ColorRamps.YlGnBu), reverse([...ylgnbuColors]), positionsFor9Colors)



}());

const RasterService = {
  processRasterInfoFromUrl(url) {
    return new Promise(async (resolve, reject) => {
      try{
        if(has(cache, url)) {
          resolve(get(cache, url))
        } else {
          const tiff = await fromUrl(url);
          const image = await tiff.getImage();
          // console.log(image)
          const data = await image.readRasters();
          // console.log(data)
          const results = data
            .reduce((result, raster) => 
              {
                const currentResult = calcStats(raster, { noData: image.getGDALNoData() ?? -3.402820018375656e+38, filter: ({ value }) => value === 0 || (!Number.isNaN(value) && Math.abs(value) > 1e-10 && Math.abs(value) < 1e10), ...options });
                result.mins.push(currentResult.min);
                result.maxs.push(currentResult.max);
                result.ranges.push(currentResult.max - currentResult.min);
                return result
              }, { mins: [], maxs: [], ranges: [] })
            
            ;
          const rasterInfo = { tiff, image, data, results };
          set(cache, url, rasterInfo)
          resolve(rasterInfo)
        }
      } catch(e) {
        if(typeof(e) === 'object' && e.message.includes('Error fetching data')) {
          reject(RasterPlotFailureMessages.ErrorFetchingTiff)  
        }
        reject(RasterPlotFailureMessages.ErrorLoadingTIff)
      }
    })

  },


  loadRasterFromUrl(url, canvasId, colorOptions, isSingleband) {
    return new Promise(async (resolve, reject) => {
      try{
        let { image, data, results } = await this.processRasterInfoFromUrl(url)
        const canvas = document.getElementById(canvasId);
        const { colorRamp, invertColors, colorMode, noOfClasses } = colorOptions;
        console.log(results)
        const _plot = new plot({
          canvas,
          data: data[0],
          multiBandData: data,
          stats: results,
          width: image.getWidth(),
          height: image.getHeight(),
          domain: [results.mins[0], results.maxs[0]],
          colorScale: invertColors ? getInvertedColorRampColor(colorRamp): colorRamp,
          // disable webgl so that plotty uses multiBandData for plotting  
          isSingleband
        });
        _plot.render();
        results= {...results,plotUrl:_plot.canvas.toDataURL()};
        resolve(results);
      } catch(e) {
        reject(e)
      }
    })
    
  },




  createRaster(input, colorOptions = {}) {

    return new Promise((resolve, reject) => {
      geoblaze.load(input)
        .then(georaster => {
          let georasterValues = georaster?.values?.[0];
          const results = georasterValues
          .reduce((result, raster) => 
            {
                const currentResult = calcStats(raster, { filter: ({ value }) => value === 0 || (!Number.isNaN(value) && Math.abs(value) > 1e-10 && Math.abs(value) < 1e10), ...options });
                result.mins.push(currentResult.min);
                result.maxs.push(currentResult.max);
                result.ranges.push(currentResult.max - currentResult.min);
                return result
            }, { mins: [], maxs: [], ranges: [] })
          
          ;
          
          const _maxs = Math.max(...results?.maxs.filter(o => o));
          // resolve(georaster)
          const _georaster ={...georaster,maxs:[_maxs],ranges:[_maxs-georaster?.mins[0]]}
          const { colorRamp, invertColors, colorMode, noOfClasses, alpha } = colorOptions;
          let _options = {
            georaster: _georaster,
            opacity: alpha,
            resolution: getResolution(),
            colorRamp,
            invertColors,
            colorMode, 
            noOfClasses
          };
          const raster = new GeoRasterLayer(_options);
          resolve(raster);
        }, error => {
          if(typeof(error) === 'object' && error.message.includes("could not load the file")) {
            reject(RasterPlotFailureMessages.ErrorFetchingTiff)
          }
          reject(RasterPlotFailureMessages.ErrorLoadingTIff);
        }).catch(error => {
          reject(error);
        });
    });
  },

  createRasterFromGeoraster(georaster) {
    const options = {
      georaster,
      opacity: 0.7,
      resolution: getResolution()
    }
    return new GeoRasterLayer(options);
  }
}

export default RasterService;
