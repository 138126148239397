import { useCallback } from "react";
import React from "react";
import classnames from "classnames";
import { TooltipTop } from "@components/tooltips";
import styles from "./../styles.module.scss";
import { NiFiSinkData } from "./types";

const imagePathRef = { 
	"AzureEventHub": "/icons/sink-kind/azure-event-hub.png",
	"S3": "/icons/sink-kind/aws-s3-sink.png",
	"Kinesis": "/icons/sink-kind/kinesis.png",
	"AzureBlob": "/icons/sink-kind/azure-storage-blob.png",
	"ADLSGen2": "/icons/sink-kind/adls.png"
}

type NifiTilesProps = {
	nifiData: NiFiSinkData[];
	setNifiSinkModal: (value:boolean)=>void;
	setNifiSinkDataModal: (data:NiFiSinkData)=>void;
}
const NifiTiles: React.FC<NifiTilesProps> = ({ nifiData = [], setNifiSinkModal, setNifiSinkDataModal }) => {

	const onEditNode = useCallback((data: NiFiSinkData) => {
		setNifiSinkDataModal(data);
		setNifiSinkModal(true);
	}, []);


	return (
		<ul className={classnames(styles["nifi_titles"])}>
			{nifiData.map((_data) => {
				const imgSrc = imagePathRef[_data.sinkKind as keyof typeof imagePathRef]
				
				return (
					<li key={_data.sinkName} className={styles["sinkCard"]}>
						<div className={styles["sinkTitleContainer"]}>
							<TooltipTop title={_data.sinkName}>
								<div className={styles["center"]}>
									<img
										width="32"
										height="32"
										src={imgSrc}
									/>
								</div>
								<h2
								className={classnames(styles["sinkTitle"],styles["truncate"])}
								>
									{_data.sinkName}
								</h2>
							</TooltipTop>
						</div>
						<div className={styles["divider"]}></div>
						<div className={styles["sinkInfo"]}>
							<div className={styles["key"]}>Sink Type</div>
							<div>{_data.sinkKind}</div>
						</div>

						<div className={styles["sinkInfo"]}>
							<div className={styles["key"]}>Connection</div>
							<div>{_data.connectionName}</div>
						</div>
						<div className={styles["divider"]}></div>
						<div className={styles["actionContainer"]}>
							<button
								className={styles["editButton"]}
								onClick={() => {
									onEditNode(_data);
								}}
							>
								<div className={styles["center"]}>
									<img src="/icons/pencil.svg" />
									Edit Sink
								</div>
							</button>
						</div>
					</li>
				);
			})}
		</ul>
	);
};

export default NifiTiles;
