import { CaptureSchemaResponse } from "@api/workflow-handler";
import { SelectField, _selectoptionType } from "@components/form/select-field";
import { errorAlert } from "@components/toastify/notify-toast";
import { TooltipTop } from "@components/tooltips";
import { ShowWhenTrue } from "@helpers/showwhentrue";
import { JobCloseWorkflowIcon } from "@pages/workflow-page/assets/icons";
import { useAppSelector } from "@store/hooks";
import { handleShowCaptureSchemaLogs } from "@store/workflow";
import { useSchemaCapture } from "@utils/schema-capture-hook";
import classNames from "classnames";
import { useField } from "formik";
import { isEmpty, has, omit } from "lodash";
import moment from "moment";
import { useState, useMemo, useEffect, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useDidUpdate } from "rooks";
import { CaptureSchemaOption, SchemaCaptureProps } from "../field-creator";
import { ResetCaptureSchemaIcon } from "../icons";
import { CaptureSchemaButton } from "./CaptureSchemaButton";
import React from "react";
import { useGetActiveTabInfo } from "@utils/get-active-tab-info";

export const CaptureSchemaComponent: React.FC<SchemaCaptureProps> = ({
	showDropdownFields,
	toggleShowDropdownFields,
	schemaCaptureProgress,
	captureSchemaOptions,
	fieldData,
	multipleSelection = true,
}) => {
	const dispatch = useDispatch();
	const activeComponentId = useAppSelector(
		(store) => store.WorkflowReducer.activeComponentInfo?.id
	);
	const [, { initialValue, value }, { setValue }] = useField(fieldData.key);

	const selectedComponentFieldName = useMemo(
		() =>
			"extraData." + fieldData.key + ".captureSchema._selectedComponent",
		[fieldData.key]
	);
	const [
		,
		{ value: selectedComponentFieldId },
		{ setValue: setSelectedComponentFieldValue },
	] = useField(selectedComponentFieldName);

	const [selectedOptions, setSelectedOptions] = useState<Record<string, any>>(
		{}
	);
	const [isSchemaCaptureInProgress, toggleSchemaCaptureInProgress] = useMemo(
		() => schemaCaptureProgress,
		schemaCaptureProgress
	);
	const [optionsForSchemaCapture, setOptionsForSchemaCapture] = useMemo(
		() => captureSchemaOptions,
		captureSchemaOptions
	);
	const activeExecutionEnv = useAppSelector(
		(store) => store.CommonReducer.activeExecutionEnv
	);
	const activeUserInfo = useAppSelector(
		(store) => store.AccountReducer.activeUserInfo
	);
	const activeTab = useGetActiveTabInfo(
		'workflowEditor'
	);
	const defaultClusterId = useAppSelector(
		(store) => store.ClusterReducer.defaultClusterInfo?.clusterId
	);
	const selectedComponentRef = useRef<SelectField | null>(null);

	const handleSchemaCaptureSuccess = useCallback(
		(response: CaptureSchemaResponse[]) => {
			setOptionsForSchemaCapture(
				response.reduce(
					(optionsForSchemaCapture, nodeDataframeInfo) => {
						let _options: CaptureSchemaOption[] = [];
						if (nodeDataframeInfo.dataframes) {
							_options = nodeDataframeInfo.dataframes.map(
								(option) => ({
									label: option.name,
									value: option.name,
									dType: option.dtypes,
								})
							);
						}
						optionsForSchemaCapture[
							nodeDataframeInfo.componentId
						] = _options;
						return optionsForSchemaCapture;
					},
					{} as typeof optionsForSchemaCapture
				)
			);
			toggleShowDropdownFields(true);
		},
		[]
	);

	const handleSchemaCaptureFailure = useCallback(() => {
		errorAlert("Capture Schema Failed.");
		toggleShowDropdownFields(true);
	}, []);

	const {
		initiateSchemaCapture,
		cancelSchemaCapture,
		sessionId,
		connectedNodesInfo,
	} = useSchemaCapture({
		componentId: activeComponentId || "",
		handleSchemaCaptureSuccess,
		toggleSchemaCaptureInProgress,
		handleSchemaCaptureFailure,
		activeExecutionEnv,
		workflowUserInfo: {
			workflowName: activeTab?.name || '',
			userName: activeUserInfo.name,
		},
		workflowConfig: activeTab?.config,
		clusterId: defaultClusterId,
	});

	const handleInitiateSchemaCapture = (removeLimit = false) => {
		// re-run schema capture when user clicks on populate schema button if fields are already shown
		if (!isEmpty(optionsForSchemaCapture) && !showDropdownFields) {
			toggleShowDropdownFields(true);
		} else if (initiateSchemaCapture(removeLimit)) {
			toggleShowDropdownFields(false);
		}
	};

	useDidUpdate(() => {
		setValue(
			Object.values(selectedOptions)
				.sort()
				.join(",")
		);
	}, [selectedOptions]);

	useEffect(() => {
		let valueWithoutSymbol = value;
		const regex = /<<([^>>]+)>>/g;
		if(value?.includes("<<")){
			valueWithoutSymbol = value.replace(regex, '');
		}
		// use value as user can change the initial value
		if (
			showDropdownFields &&
			!isEmpty(optionsForSchemaCapture) &&
            !isEmpty(connectedNodesInfo)
		) {
            // there's already a initial value
            if(!!value && typeof value === "string") {
                if (multipleSelection) {
                    // split the values("value1,value2,value3") and create an obj: { value1: true, value2: true,...}
                    const initialValueRef = valueWithoutSymbol
                        .split(",")
                        .reduce((initialValueRef:any, optionValue:any) => {
                            initialValueRef[optionValue] = true;
                            return initialValueRef;
                        }, {} as Record<string, true>);
    
                    const initialSelectedOptions = Object.values(
                        optionsForSchemaCapture
                    ).reduce((initialSelectedOptions, componentOptions) => {
                        componentOptions.forEach((option) => {
                            if (has(initialValueRef, option.value)) {
                                initialSelectedOptions[option.label] = option.value;
                            }
                        });
                        return initialSelectedOptions;
                    }, {} as typeof selectedOptions);
                    setSelectedOptions(initialSelectedOptions);
                } else {
                    const selectedNodeId = Object.keys(
                        optionsForSchemaCapture
                    ).find((nodeId) => {
                        const options = optionsForSchemaCapture[nodeId];
                        return !!options.find((opt) => opt.value === value);
                    });
                    if (selectedNodeId) {
                        setSelectedComponentFieldValue(selectedNodeId);
                        setSelectedOptions({
                            [value]: value,
                        });
    
                        selectedComponentRef.current?.setOption({
                            label: connectedNodesInfo[selectedNodeId],
                            value: selectedNodeId,
                        });
                    }
                }
            } else if(!multipleSelection) {
                // Auto select first component if there's no initial value 
                const firstComponentId = Object.keys(connectedNodesInfo)[0];
                setSelectedComponentFieldValue(firstComponentId);
                selectedComponentRef.current?.setOption({
                    label: connectedNodesInfo[firstComponentId],
                    value: firstComponentId,
                });
                
            }

			
		}
	}, [
		optionsForSchemaCapture,
		initialValue,
		multipleSelection,
		connectedNodesInfo,
		showDropdownFields,
	]);

	const selectedColumns: string[] = useMemo(() => {
		return Object.keys(selectedOptions);
	}, [selectedOptions]);

	const handleSingleOptionSelection = useCallback(
		(option: _selectoptionType) => {
			setSelectedOptions({ [option.label]: option.value });
		},
		[]
	);

	const handleMultipleOptionSelection = useCallback(
		(option: _selectoptionType) => {
			setSelectedOptions((selectedOptions) => {
				let __selectedOptions = { ...selectedOptions };
				const label = option.label;
				if (has(selectedOptions, label)) {
					__selectedOptions = omit(__selectedOptions, label);
				} else {
					__selectedOptions[option.label] = option.value;
				}
				return __selectedOptions;
			});
		},
		[]
	);

	const selectAllTrigger = useCallback(
		(__selectedOptions: typeof selectedOptions) => {
			setSelectedOptions(__selectedOptions);
		},
		[]
	);

	const disableCaptureSchemaMode = useCallback(() => {
		toggleShowDropdownFields(false);
	}, []);

	const getCaptureSchemaTimeAgo = useMemo(() => {
		return moment();
	}, [showDropdownFields]);

	const handleViewLogs = () => {
		dispatch(handleShowCaptureSchemaLogs(sessionId));
	};

	const connectedNodesInfoOptions: _selectoptionType[] = useMemo(
		() =>
			Object.keys(connectedNodesInfo).map((nodeId) => ({
				label: connectedNodesInfo[nodeId],
				value: nodeId,
			})),
		[connectedNodesInfo]
	);

	const onComponentSelection = useCallback(() => {
		// reset column selection on different component selection
		setSelectedOptions({});
	}, []);

	const componentComponentDropdownProps = {
		useSelectedOptionsFromProps: true,
		selectedOptionsFromProps: selectedOptions,
		renderOptionLabel: (option: _selectoptionType) => (
			<div className="titleInfoText__selectFieldOptionLabel">
				<span>{option.label}</span>
				<span>{(option as CaptureSchemaOption).dType}</span>
			</div>
		),
	};

	return (
		<>
			<div
				id={`btn_captureSchema_${fieldData.templateOptions.label.replace(
					" ",
					""
				)}`}
				className={classNames("btns__captureSchema", {
					moveButtonToLeft: showDropdownFields,
				})}
			>
				{showDropdownFields && (
					<label className="inputfield__label">
						{fieldData.templateOptions.label}
						<ShowWhenTrue show={!!fieldData.templateOptions.qtip}>
							<TooltipTop
								placement="topRight"
								overlay={fieldData.templateOptions.qtip}
							>
								<img
									src="/icons/info-fields.png"
									width="16"
									height="16"
									className="info__icon"
									alt="information-icon"
								/>
							</TooltipTop>
						</ShowWhenTrue>
					</label>
				)}
				<div className="innerCaptureSchemabox">
					<CaptureSchemaButton
						isSchemaCaptureInProgress={isSchemaCaptureInProgress}
						initiateSchemaCapture={handleInitiateSchemaCapture}
						className="btnCaptureSchema__captureSchemaField"
					/>
					{showDropdownFields && (
						<>
							<button
								className="btn-viewLog"
								onClick={handleViewLogs}
								type="button"
							>
								View log
							</button>
							<span className="captureSchemaTimeAgo">
								{moment(getCaptureSchemaTimeAgo).fromNow()}
							</span>
						</>
					)}
					{isSchemaCaptureInProgress && (
						<button
							onClick={cancelSchemaCapture}
							className="btn-cancelSchemaCapture"
							type="button"
						>
							<JobCloseWorkflowIcon />
						</button>
					)}
				</div>
				<ShowWhenTrue show={showDropdownFields}>
					<div className="captureSchema__Fields">
						<button
							className="btn-resetCaptureSchema"
							onClick={disableCaptureSchemaMode}
						>
							<ResetCaptureSchemaIcon />
						</button>
						{multipleSelection ? (
							<>
								<label className="inputfield__label">
									Column Selection
								</label>
								<div className="selectedFieldsBox">
									{isEmpty(selectedColumns) && (
										<span className="noColsMsg">
											Selected columns will appear here
										</span>
									)}
									{selectedColumns.map((column, index) => (
										<span
											key={column + index}
											className="multiple-select-tags"
										>
											{column}
											<span
												className="closeIcon"
												onClick={() => {
													// since label and value are same for column names
													handleMultipleOptionSelection(
														{
															label: column,
															value: column,
														}
													);
												}}
											>
												X
											</span>
										</span>
									))}
								</div>
								{Object.keys(connectedNodesInfo).map(
									(nodeId, index) => {
										const nodeName =
											connectedNodesInfo[nodeId];
										return (
											<SelectField
												key={nodeName + index}
												label={nodeName}
												// to make nodename unique
												name={nodeName + index}
												multiple_select={
													multipleSelection
												}
												options={
													optionsForSchemaCapture[
														nodeId
													] || []
												}
												onOptionClick={
													handleMultipleOptionSelection
												}
												onSelectAll={selectAllTrigger}
												selectAll={true}
												{...componentComponentDropdownProps}
											/>
										);
									}
								)}
							</>
						) : (
							<div className="singleColumn__selection">
								<SelectField
									name={selectedComponentFieldName}
									options={connectedNodesInfoOptions}
									label="Component"
									ref={selectedComponentRef}
									onOptionClick={onComponentSelection}
								/>
								<SelectField
									// to make nodename unique
									name={
										fieldData.key +
										"_" +
										selectedComponentFieldId
									}
									options={
										optionsForSchemaCapture[
											selectedComponentFieldId
										] || []
									}
									onOptionClick={handleSingleOptionSelection}
									label={"Column"}
									{...componentComponentDropdownProps}
								/>
							</div>
						)}
					</div>
				</ShowWhenTrue>
			</div>
			<ShowWhenTrue show={isSchemaCaptureInProgress}>
				<div className="captureSchema__loadingSkeleton">
					<div className="field">
						<div className="line1"></div>
						<div className="line2"></div>
					</div>
					{Object.keys(connectedNodesInfo).map((node, index) => (
						<div className="field" key={node + index}>
							<div className="line1"></div>
							<div className="line2"></div>
						</div>
					))}
				</div>
			</ShowWhenTrue>
		</>
	);
};
