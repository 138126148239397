import { HttpClient } from '../services';
import { API, Env } from '../../constants/settings';
import { ApiResponse } from '../data-source-handler';

class DocsHandlerClass {
    GetAllDocs(callback: ApiResponse<string[]>) {
        HttpClient.Get('Get all docs', `${Env.s3Enable ? API.s3Url : API.azureblobUrl}/helpfiles/list`, callback);
    }

    GetDocByName(docName: string, callback: ApiResponse<string>) {
        HttpClient.Get('Get doc by name', `${Env.s3Enable ? API.s3Url : API.azureblobUrl}/helpfiles/read?fileName=${docName}`, callback);
    }
}

const DocsHandler = new DocsHandlerClass();

export { DocsHandler };