/* eslint-disable no-console */

import { AxiosError, AxiosResponse } from 'axios';
import { errorAlert } from '../../components/toastify/notify-toast';
import { has } from 'lodash';
import { _Axios } from './httpsettings';




export const commonErrorHandler = (error: AxiosError) => {
    if(error.response) {
        const { status } = error.response;
        switch(status) {
            case 401:
                // if((originalRequestInfo.url !== userInfoUrl) && (originalRequestInfo.url !== loginUrl)) {
                //     localStorage.clear();
                //     window.location.href = '/';
                // }
                break;
            case 403:
                errorAlert('You do not have the necessary permissions to perform this action');
                // window.location.href= '/';
                break;
            case 404:
                // Need to show a page with no page on this url found info 
                // and options to go back to prev page / go to home
                errorAlert('This page is not available');
                break;
            case 500:
                errorAlert('Something went wrong, please contact admin');
                break;
        }
    }  else if (error.code === 'ECONNABORTED') {
        // errorAlert('Request timed out'); 
    } else if(_Axios().isCancel(error)) {
        // Request canceled by user
    } else {
        // store.dispatch(showNetworkErrorPage() as any);
        errorAlert('There\'s trouble connecting to the server.');
    }
};


export const errorHandlerFor400 = (error: AxiosResponse) => {
    
    if(has(error, 'data.message')) {
        errorAlert(error.data.message);
    } else if (typeof(error.data) === 'string') {
        errorAlert(error.data);
    }
};