import { WorkflowCanvasTabInfo } from "@store/canvas";
import { useGetActiveTabInfo } from "@utils/get-active-tab-info";
import moment from "moment-mini";
import React, { memo, useMemo } from "react";
import styles from './styles.module.scss';

type Props = {
    workflowInfo: WorkflowCanvasTabInfo
}

const WorkflowDetailsTab: React.FC<Props> = memo(({ workflowInfo }) => {
    const activeWorkflowInfo = useGetActiveTabInfo('workflowEditor');

    const { created, modified } = useMemo(() => {
        let created = '', modified = '';
        if(activeWorkflowInfo?.created) {
            created = moment(activeWorkflowInfo.created).format('dddd, Do MMMM YYYY') + ' at ' + moment(activeWorkflowInfo.created).format('LT')
            modified = moment(activeWorkflowInfo.updated).format('dddd, Do MMMM YYYY') + ' at ' + moment(activeWorkflowInfo.updated).format('LT')
        }
        return { created, modified }
    }, [activeWorkflowInfo])

    return(
        activeWorkflowInfo ?
        <div className={styles["workflowDetails__RHS"]}>
            <p className={styles["workflowName__title"]}>{activeWorkflowInfo.name}</p>
            {created &&
                <div className={styles["workflowMetaData"]}>
                    <p className={styles["label"]}>Created</p>
                    <p className={styles["value"]}>{created}</p>
                </div>
            }
            {modified &&
                <div className={styles["workflowMetaData"]}>
                    <p className={styles["label"]}>Modified</p>
                    <p className={styles["value"]}>{modified}</p>
                </div>
            }
        </div>
        :
        null
    )
})

WorkflowDetailsTab.displayName= "WorkflowDetailsTab";

export default WorkflowDetailsTab;