import React, { Component } from 'react';
import { Tooltip } from 'antd';
import _ from 'lodash';
import Search from '../../../components/search/search2';

export default class JoinSearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            result1: [],
            value: '',
        };
    }

    modifyPreviousTableJoinColumn = (alias, tableName, column) => {
        this.props.query.from.modifyPreviousTableJoinColumn(
            this.props.rowIndex,
            this.props.joinColumnIndex,
            alias,
            tableName,
            column
        );
        this.props.updateQueryState();
    };

    getData = ()=> {
        if(this.props.query.geoSpatial.isGeoSpatialEnabled){
            const temp =  _.reduce(
                this.props.previousTablesJoinColumns,
                (resultDrop, previousTable) => {
                    // eslint-disable-next-line no-param-reassign
                    resultDrop[
                        previousTable.tableMetadata.name
                    ] = {
                        name:
                            previousTable.tableMetadata.name, // +
                        // ' (' +
                        // previousTable.tableAlias +
                        // ')',
                        results: previousTable.tableMetadata.fields
                            ? _.map(_.filter(previousTable.tableMetadata.fields, (column)=>{
                                return column.name.includes('point_deepiq_idx') || column.name.includes('polygon_deepiq_idx');
                            }), column => ({
                                alias: previousTable.tableAlias,
                                tablename: previousTable.tableMetadata.name,
                                title: column.name,
                            }))
                            : [],
                    };
                    return resultDrop;
                },
                {}
            );
            Object.keys(temp).forEach((key)=>{
                if(temp[key].results.length === 0){
                    delete temp[key];
                }
            });
            return temp;
        } else {
            return _.reduce(
                this.props.previousTablesJoinColumns,
                (resultDrop, previousTable) => {
                    // eslint-disable-next-line no-param-reassign
                    resultDrop[
                        previousTable.tableMetadata.name //+
                    ] = {
                        name:
                            previousTable.tableMetadata.name,
                        results: previousTable.tableMetadata.fields
                            ? _.map(previousTable.tableMetadata.fields, column => ({
                                alias: previousTable.tableAlias,
                                tablename: previousTable.tableMetadata.name,
                                title: column.name,
                                value: previousTable.tableAlias
                            }))
                            : [],
                    };
                    return resultDrop;
                },
                {}
            );
        }
    }
    setJoinColumnsState = () => {
        this.setState({
            previousTablesJoinColumns: _.reduce(
                this.props.previousTablesJoinColumns,
                (resultDrop, previousTable) => {
                    resultDrop[
                        previousTable.tableMetadata.name 
                    ] = {
                        name: previousTable.tableMetadata.name, 
                        results: previousTable.tableMetadata.fields
                            ? _.map(previousTable.tableMetadata.fields, column => ({
                                alias: previousTable.tableAlias,
                                tablename: previousTable.tableMetadata.name,
                                title: column.name,
                            }))
                            : [],
                    };
                    return resultDrop;
                },
                {}
            ),
        });
    };

    filterResult = (value) => {
        this.modifyPreviousTableJoinColumn(null, null, value);
        this.setJoinColumnsState();

        setTimeout(() => {
            const re = new RegExp(
                _.escapeRegExp(
                    this.props.previousTableJoinColumn.split('.')[1] ||
                    this.props.previousTableJoinColumn.split('.')[0] ||
                    this.props.previousTableJoinColumn
                ),
                'i'
            );
        
            const isMatch = result => {
                if (this.props.previousTableJoinColumn) {
                    return (
                        re.test(result.title) ||
                    re.test(result.tablename) ||
                    re.test(result.tableAlias)
                    );
                } else {
                    return true;
                }
            };
        
            const filteredResults = _.reduce(
                this.state.previousTablesJoinColumns,
                (memo, data, name) => {
                    const results = _.filter(data.results, isMatch);
                    if (results.length) memo[name] = { name, results }; // eslint-disable-line no-param-reassign
        
                    return memo;
                },
                {}
            );

            const data = [];

            Object.keys(filteredResults).forEach((name)=>{
                const results = filteredResults[name].results;
                data.push({
                    name,
                    results
                });
            });

            this.setState({
                result1: data
            });
        });
    };

    render() {
        return (
            <Tooltip
                title={this.props.text}
                mouseLeaveDelay={0}
            >
                <Search 
                    placeholder={`Input ${this.props.rowIndex} to input ${this.props.rowIndex + 1} join column`}
                    data={this.state.result1} 
                    selectedValue={this.props.previousTableJoinColumn}
                    getData={()=>{
                        if(this.props.previousTableJoinColumn){
                            this.filterResult(this.props.previousTableJoinColumn);
                        } else {
                            const data =[];
                            const obj = this.getData();
                            Object.keys(obj).forEach((name)=>{
                                const results = obj[name].results;
                                data.push({
                                    name,
                                    results
                                });
                            });
                            this.setState({
                                result1: data
                            });
                        }
                        
                    }}
                    onSearch={(key)=>{
                        this.filterResult(key);
                        //console.log("temp", key);
                    }}
                    onSelect={(value)=>{
                        this.modifyPreviousTableJoinColumn(
                            value.alias,
                            value.tablename,
                            value.alias + '.' + value.title
                        );
                    }}
                />
            </Tooltip>
        );
    }
}
