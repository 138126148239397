import React from 'react';

export interface Item {
    hasNodes: boolean;
    isOpen: boolean;
    level: number;
    key: string;
    label: string;
    [x: string]: any;
  }

export interface TreeMenuItem extends Item {
    active?: boolean;
    onClick: (event: React.MouseEvent<HTMLLIElement>) => void;
    toggleNode?: () => void;
  }
  

const openedIcon = <img draggable={false} className="arrow  arrow--active" src="/icons/treeview/Right.svg" alt=""  />;
const closedIcon = <img draggable={false} className="arrow" src="/icons/treeview/Right.svg" alt=""  />;




const ToggleIcon = ({
    on,
    openedIcon,
    closedIcon,
}: {
    on: boolean;
    openedIcon: React.ReactNode;
    closedIcon: React.ReactNode;
  }) => (
    <div role="img" aria-label="Toggle" className="rstm-toggle-icon-symbol">
        {on ? openedIcon : closedIcon}
    </div>
);

const DEFAULT_PADDING = 10;
const ICON_SIZE = 8;
const LEVEL_SPACE = 20;

export const ItemComponent: React.FunctionComponent<TreeMenuItem> = ({
    isOpen = false,
    level = 0,
    onClick,
    toggleNode,
    active,
    focused,
    label = 'unknown',
    style = {},
}) => {
    const showToggle = level !== 2;
    return(
        <li
            className={`rstm-tree-item ${active ? 'rstm-tree-item--active': ''} ${focused ?  'rstm-tree-item--focused':''}`}
            style={{
                paddingLeft: DEFAULT_PADDING + ICON_SIZE 
                + level * LEVEL_SPACE,
                paddingRight: 25,
                ...style,
            }}
            role="button"
            aria-pressed={active}
            onClick={onClick}
        >
            {showToggle && (
                <div
                    className="rstm-toggle-icon"
                    onClick={() => {
                        toggleNode && toggleNode();
                    }}
                >
                    <ToggleIcon on={isOpen} openedIcon={openedIcon} closedIcon={closedIcon} />
                </div>
            )}
            <span 
                onClick={() => {
                    showToggle && toggleNode && toggleNode();
                }}
                className="treeviewItem__label"
            >
                {label}
            </span>
            {/* {level === 2 && 
                <div className="newBtn__box btn__syncSet">
                    <button 
                        className="btn-sm btn-yellow-transparent btn__new__action" 
                        onClick={() => dispatch(toggleTagBrowserModal('editSyncSets', true))}
                    >
                        <img src="/icons/workflow/add.svg" alt=""/> New
                    </button>
                </div>
            } */}
        </li>
    );
};