import styled from "styled-components";
import { FileExplorerProps } from "../types";

export const FileListWrapper = styled.div<Pick<FileExplorerProps, "view">>`
	width: 100%;
	flex: 1;
	overflow-y: scroll;
	margin-top: 12px;
	${({ view }) =>
		view === "List" && "border-top: 1px solid #6D707E; padding-top: 4px;"}

	#react-contextmenu-wrapper {
		width: 100%;
	}
`;
