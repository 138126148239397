import React from "react";
import { Modal } from "../../components/modals";
import { WorkspaceData } from "./types";

type ConfirmModalProps = {
	currentWorkSpace: WorkspaceData | null;
	showModal: boolean;
	toggleClose: () => void;
	onConfirm: () => void;
};

export const DeleteWorkSpaceModal: React.FC<ConfirmModalProps> = ({
	showModal,
	currentWorkSpace,
	toggleClose,
	onConfirm,
}) => {
	let title= "";
	let subtitle = "";
	if(currentWorkSpace?.isEnabled){
		title = `This action can't be performed because ${currentWorkSpace?.workspaceName} connection is enabled.`;
		subtitle = "First disable connection, and try again.";
	}else{
		title = `Are you absolutely sure you want to delete this WorkSpace ${currentWorkSpace?.workspaceName}?`;
		subtitle = "This action is irreversible";
	}

	return (
		<Modal
			isOpen={showModal}
			toggleClose={toggleClose}
			title={title}
			subtitle={subtitle}
			className="deleteCluster__modal"
		>
			<p className="warning__msg">
				Warning: This action <span>cannot</span> be undone. This will
				permanently delete the{" "}
				<span>{currentWorkSpace?.workspaceName}</span> WorkSpace.
			</p>
			<div className="btns__outer">
				<button className="btn-md btn-yellow" onClick={onConfirm} disabled ={currentWorkSpace?.isEnabled}>
					Yes
				</button>
				<button
					className="btn-md btn-cancel"
					type="button"
					onClick={toggleClose}
				>
					Cancel
				</button>
			</div>
		</Modal>
	);
};
