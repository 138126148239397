import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import classNames from "classnames";
import {
	aggregationTypes,
	graphTypes,
} from "@pages/workflow-analytics-page/enums";
import { PlotData } from "@pages/workflow-analytics-page/Canvas";
import {
	ColumnErrors,
	NormalColTypes,
	SourceColumns,
	TypesOfColumns,
} from "@pages/workflow-analytics-page/modals/plot-selection";
import Form, { SelectField } from "@components/form";
import { DynamicKeyValueFieldGenerator } from "@components/form/dynamic-field-generator";
import ColumnSelection from "./ColumnSelection";
import { Plot, PlotConfig } from "@api/visualization-handler";

interface Props {
	columnsList: string[];
	toggleClose: () => void;
	handlePlot: (payload: Plot) => void;
	selectedPlotItem: Plot | null;
	initialValuesPlotForm: PlotConfig;
}

export const PlotForm: React.FC<Props> = ({
	columnsList,
	toggleClose,
	handlePlot,
	selectedPlotItem,
	initialValuesPlotForm,
}) => {
	const [keys, setKeys] = useState<string[]>([]);
	const [values, setValues] = useState<string[]>([]);
	const [seriesGroupings, setSeriesGroupings] = useState<string[]>([]);
	const [columnErrors, setColumnErrors] = useState<ColumnErrors>({});

	const validateColumnSelection = (plotType: PlotData["plotType"]) => {
		let columnErrors: ColumnErrors = {};
		if (plotType !== "correlation") {
			if (plotType === "geospatial") {
				columnErrors = {
					seriesGroupings: isEmpty(seriesGroupings),
				};
			} else {
				columnErrors = {
					keys: isEmpty(keys),
					values: isEmpty(values),
				};
			}
		}
		return columnErrors;
	};

	const handleSubmitForm = (formValues: PlotConfig) => {
		const columnErrors = validateColumnSelection(formValues.plot_type);
		setColumnErrors(columnErrors);
		if (isEmpty(Object.values(columnErrors).filter(Boolean))) {
			const payload = {
				config: {
					...formValues,
					x: keys[0],
					y: [...values],
					geometries: seriesGroupings,
					groupby_cols: seriesGroupings,
				},
			};
			handlePlot(payload);
		}
	};

	const handleRemoveColumn = (type: TypesOfColumns, columnName: string) => {
		if (type === "keys") {
			const updatedKeys = keys.filter((item) => item !== columnName);
			setKeys(updatedKeys);
		}
		if (type === "values") {
			const updatedKeys = values.filter((item) => item !== columnName);
			setValues(updatedKeys);
		}
	};

	const handleColumnDrop = (
		columnName: string,
		target: TypesOfColumns,
		source: SourceColumns,
		plotType: PlotData["plotType"]
	) => {
		setColumnErrors({});
		if (target === "keys") {
			const isColumnExist = keys?.includes(columnName);
			if (!isColumnExist) {
				setKeys([...keys, columnName]);
			}
		}
		if (target === "values") {
			const isColumnExist = values?.includes(columnName);
			if (!isColumnExist) {
				setValues([...values, columnName]);
			}
		}
		if (target === "seriesGroupings") {
			const isColumnExist = seriesGroupings?.includes(columnName);
			if (!isColumnExist) {
				setSeriesGroupings([...seriesGroupings, columnName]);
			}
		}
	};

	useEffect(() => {
		if (!isEmpty(selectedPlotItem)) {
			setKeys([selectedPlotItem.config.x || ""]);
			setValues(selectedPlotItem.config.y);
			setSeriesGroupings(selectedPlotItem.config.groupby_cols);
		}
	}, []);

	const renderSelectClearButtons = (
		type: NormalColTypes,
		selectedColumnsOfType: string[],
		disableSelectAll = false
	) => {
		const handleSelectClearAll = (selectAll = true) => {
			const __colsList = selectAll ? columnsList : [];
			if (selectAll) {
				if (type === "keys") {
					setKeys(__colsList);
				}
				if (type === "values") {
					setValues(__colsList);
				}
				if (type === "seriesGroupings") {
					setSeriesGroupings(__colsList);
				}
			} else {
				if (type === "keys") {
					setKeys(__colsList);
				}
				if (type === "values") {
					setValues(__colsList);
				}
				if (type === "seriesGroupings") {
					setSeriesGroupings(__colsList);
				}
			}
		};

		return (
			<div className="columnActions__box">
				<button
					onClick={() => handleSelectClearAll(true)}
					className="btn-selectAll"
					disabled={
						disableSelectAll ||
						selectedColumnsOfType === columnsList
					}
					type="button"
				>
					Select All
				</button>
				<button
					onClick={() => handleSelectClearAll(false)}
					className="btn-clearAll"
					disabled={isEmpty(selectedColumnsOfType)}
					type="button"
				>
					Clear All
				</button>
			</div>
		);
	};
	const _graphTypes = [graphTypes[0],graphTypes[1],graphTypes[2],graphTypes[3],graphTypes[5],graphTypes[7]]
	return (
		<Form
			initialValues={initialValuesPlotForm}
			onSubmit={handleSubmitForm}
			className="plotOptionsForm"
			enableReinitialize
		>
			{({ _formikprops }) => {
				return (
					<>
						<div className="split__fields--halfwide">
							<SelectField
								name="plot_type"
								options={_graphTypes}
								label="Display Type"
							/>
							<SelectField
								name="aggregate_type"
								options={aggregationTypes}
								label="Aggregation"
								// disabled={
								// 	_formikprops.values.plot_type ===
								// 	"correlation"
								// }
								disabled
								className="selectField__Aggregate"
							/>
						</div>
						<ColumnSelection
							columnsList={columnsList}
							keys={keys}
							values={values}
							seriesGroupings={seriesGroupings}
							multiBandCols={{ red: [], green: [], blue: [] }}
							handleRemoveColumn={handleRemoveColumn}
							handleColumnDrop={(column, target, source) =>
								handleColumnDrop(
									column,
									target,
									source,
									_formikprops.values.plot_type
								)
							}
							plotType={_formikprops.values.plot_type}
							renderSelectClearButtons={renderSelectClearButtons}
							bandType={_formikprops.values.bandType}
							columnErrors={columnErrors}
							isDataExplorer={true}
						/>
						{/* <div>
							<DynamicKeyValueFieldGenerator
								keyToSaveKeyValueInfo="additional_params"
								addButtonText="+ Add a configuration"
							/>
						</div> */}

						<div className={classNames("btns__box")}>
							<button
								className="btn-md btn-cancel"
								type="button"
								onClick={toggleClose}
							>
								Cancel
							</button>
							<button
								id="btn_plot_submit"
								className="btn-md btn-yellow"
								type="submit"
							>
								Create Plot
							</button>
						</div>
					</>
				);
			}}
		</Form>
	);
};
