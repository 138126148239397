import React from 'react';

export const InvalidPropertiesIcon = () => (
    <svg width="16px" height="16px" viewBox="0 0 16 16">
        <defs>
            <path
                d="M12,4 C12.9456501,4 13.81014,4.5344381 14.2326359,5.38032717 L19.7367824,16.38907 C20.123329,17.1625796 20.0818959,18.0810918 19.6272283,18.8165839 C19.1725607,19.5521979 18.3693675,20 17.5046339,20 L6.49536612,20 C5.6306325,20 4.82756111,19.5521979 4.37277167,18.8165839 C3.91810412,18.0810918 3.87667096,17.1625796 4.26321764,16.38907 L9.76736406,5.38032717 C10.18986,4.5344381 11.05435,4 12,4 Z M11.9931465,15.6243824 C11.3016326,15.6243824 10.7404039,16.1855594 10.7404039,16.8770098 C10.7404039,17.5684601 11.3016326,18.1296372 11.9931465,18.1296372 C12.6846604,18.1296372 13.2458891,17.5684601 13.2458891,16.8770098 C13.2458891,16.1855594 12.6846604,15.6243824 11.9931465,15.6243824 Z M11.9931465,7.00630578 C11.3016326,7.00630578 10.7404039,7.50797482 10.7404039,8.12610275 L10.7404039,12.6052906 C10.7404039,13.2234185 11.3016326,13.7250876 11.9931465,13.7250876 C12.6846604,13.7250876 13.2458891,13.2234185 13.2458891,12.6052906 L13.2458891,8.12610275 C13.2458891,7.50797482 12.6846604,7.00630578 11.9931465,7.00630578 Z"
                id="path-1-IP"
            />
        </defs>
        <g
            id="Symbols"
            stroke="none"
            strokeWidth={1}
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="Icons/Standard/Caution"
                transform="translate(-4.000000, -4.000000)"
            >
                <mask id="mask-2-IP" fill="white">
                    <use xlinkHref="#path-1-IP" />
                </mask>
                <use
                    id="Combined-Shape"
                    fill="#FF7761"
                    fillRule="nonzero"
                    xlinkHref="#path-1-IP"
                />
            </g>
        </g>
    </svg>
);

export const PortManagementIcon = () => (
    <svg width="16px" height="16px" viewBox="0 0 24 24">
        <defs>
            <path
                d="M19.4978974,11.0252307 L12.5021026,11.0252307 L12.5021026,9.01682044 L13.5063077,9.01682044 C13.7834683,9.01682044 14.0084102,8.79187849 14.0084102,8.51471788 L14.0084102,5.50210255 C14.0084102,5.22494194 13.7834683,5 13.5063077,5 L10.4936923,5 C10.2165317,5 9.99158978,5.22494194 9.99158978,5.50210255 L9.99158978,8.51471788 C9.99158978,8.79187849 10.2165317,9.01682044 10.4936923,9.01682044 L11.4978974,9.01682044 L11.4978974,11.0252307 L4.50210255,11.0252307 C4.22494194,11.0252307 4,11.2501726 4,11.5273332 C4,11.8044938 4.22494194,12.0294358 4.50210255,12.0294358 L6.97897446,12.0294358 L6.97897446,14.037846 L5.97476935,14.037846 C5.69760874,14.037846 5.47266679,14.2627879 5.47266679,14.5399485 L5.47266679,17.5525639 C5.47266679,17.8297245 5.69760874,18.0546664 5.97476935,18.0546664 L8.98738467,18.0546664 C9.26454528,18.0546664 9.48948723,17.8297245 9.48948723,17.5525639 L9.48948723,14.5399485 C9.48948723,14.2627879 9.26454528,14.037846 8.98738467,14.037846 L7.98317956,14.037846 L7.98317956,12.0294358 L16.0168204,12.0294358 L16.0168204,14.037846 L15.0126153,14.037846 C14.7354547,14.037846 14.5105128,14.2627879 14.5105128,14.5399485 L14.5105128,17.5525639 C14.5105128,17.8297245 14.7354547,18.0546664 15.0126153,18.0546664 L18.0252307,18.0546664 C18.3023913,18.0546664 18.5273332,17.8297245 18.5273332,17.5525639 L18.5273332,14.5399485 C18.5273332,14.2627879 18.3023913,14.037846 18.0252307,14.037846 L17.0210255,14.037846 L17.0210255,12.0294358 L19.4978974,12.0294358 C19.7750581,12.0294358 20,11.8044938 20,11.5273332 C20,11.2501726 19.7750581,11.0252307 19.4978974,11.0252307 Z"
                id="path-1-PM"
            />
        </defs>
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
            <mask id="mask-2-PM" fill="white">
                <use xlinkHref="#path-1-PM" />
            </mask>
            <use
                id="Shape"
                fill="#000000"
                fillRule="nonzero"
                xlinkHref="#path-1-PM"
            />
            <polygon
                id="Path"
                fill="#000000"
                fillRule="nonzero"
                mask="url(#mask-2-PM)"
                points="8.48528212 15.0420511 8.48528212 17.0504613 6.4768719 17.0504613 6.4768719 15.0420511"
            />
            <polygon
                id="Path"
                fill="#000000"
                fillRule="nonzero"
                mask="url(#mask-2-PM)"
                points="10.9957949 8.01261533 10.9957949 6.00420511 13.0042051 6.00420511 13.0042051 8.01261533"
            />
            <polygon
                id="Path"
                fill="#000000"
                fillRule="nonzero"
                mask="url(#mask-2-PM)"
                points="17.5231281 15.0420511 17.5231281 17.0504613 15.5147179 17.0504613 15.5147179 15.0420511"
            />
            <g id="↳-🎨Color/Neutral/100" mask="url(#mask-2-PM)" fill="#D4D6E0">
                <rect id="Color-Container" x={0} y={0} width={24} height={24} />
            </g>
        </g>
    </svg>
);

export const PlusIconForAnalytics = ({ nodeId }: { nodeId: string }) => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
        <defs>
            <path
                d="M12,3 C12.5522847,3 13,3.44494629 13,3.99339768 L13,11 L20.007,11 C20.5158571,11 20.9359031,11.3828367 20.9933134,11.8826924 L21,12 C21,12.552 20.555,13 20.007,13 L20.007,13 L13,13 L13,20.0066023 C13,20.5160523 12.6172798,20.935933 12.1173326,20.9933167 L12,21 C11.4477153,21 11,20.5550537 11,20.0066023 L11,13 L3.993,13 C3.48414286,13 3.06409694,12.6180255 3.00668659,12.1174923 L3,12 C3,11.448 3.445,11 3.993,11 L3.993,11 L11,11 L11,3.99339768 C11,3.48394775 11.3827202,3.06406704 11.8826674,3.00668331 L12,3 Z"
                id={`path-1-plus-${nodeId}`}
            />
        </defs>
        <g
            id="standard/action/add"
            stroke="none"
            strokeWidth={1}
            fill="none"
            fillRule="evenodd"
        >
            <mask id={`mask-2-plus-${nodeId}`} fill="white">
                <use xlinkHref={`#path-1-plus-${nodeId}`} />
            </mask>
            <use id="new" fill="#42526E" xlinkHref={`#path-1-plus-${nodeId}`} />
            <g
                id="↳-🎨Color/Neutral/200"
                mask={`url(#mask-2-plus-${nodeId})`}
                fill="#FFF"
            >
                <g transform="translate(-73.000000, -18.000000)" id="Color-Container">
                    <rect x={0} y={0} width={171} height={60} />
                </g>
            </g>
        </g>
    </svg>
);

export const UnAvailableIcon = ({ nodeId }: { nodeId: string }) => (
    <svg
        width="32px"
        height="32px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                d="M17.634375,6.365625 C16.125,4.85628125 14.1281562,4 12,4 C7.60446875,4 4,7.597375 4,12 C4,16.4049375 7.59609375,20 12,20 C16.3845625,20 20,16.4190938 20,12 C20,9.87184375 19.14375,7.87496875 17.634375,6.365625 Z M12,18.09375 C8.6316875,18.09375 5.90625,15.3754687 5.90625,12 C5.90625,10.6874687 6.31875,9.44059375 7.078125,8.40934375 L15.5906562,16.9124687 C14.5593437,17.68125 13.3125312,18.09375 12,18.09375 Z M16.9125,15.5905938 L8.40934375,7.078125 C9.44065625,6.31875 10.6874687,5.90625 12,5.90625 C15.3684062,5.90625 18.09375,8.62453125 18.09375,12 C18.09375,13.3124688 17.68125,14.5593438 16.9125,15.5905938 Z"
                id={`path-1-un-${nodeId}`}
            />
        </defs>
        <g
            id="Icons/Analytics/disabled"
            stroke="none"
            strokeWidth={1}
            fill="none"
            fillRule="evenodd"
        >
            <mask id={`mask-1-un-${nodeId}`} fill="#CF5633">
                <use xlinkHref={`#path-1-un-${nodeId}`} />
            </mask>
            <use id="Shape" fill="#000000" fillRule="nonzero" xlinkHref={`#path-1-un-${nodeId}`} />
            <g id="↳-🎨Color/Secondary/200" mask={`url(#mask-1-un-${nodeId})`} fill="#CF5633">
                <rect id="Color-Container" x={0} y={0} width={24} height={24} />
            </g>
        </g>
    </svg>
);


export const StreamingWorkflowPlayIcon = () => (
    <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={0.2}
      d="M14.26 7.573l-9-5.499a.5.5 0 00-.76.427v10.998a.5.5 0 00.76.427l9-5.5a.5.5 0 000-.853z"
      fill="#000"
    />
    <path
      d="M14.26 7.573l-9-5.499a.5.5 0 00-.76.427v10.998a.5.5 0 00.76.427l9-5.5a.5.5 0 000-.853z"
      stroke="#000"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)


export const PreviewDisabledIcon = () => (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity={0.2}
        d="M12 5.25C4.5 5.25 1.5 12 1.5 12s3 6.75 10.5 6.75S22.5 12 22.5 12s-3-6.75-10.5-6.75zm0 10.5a3.75 3.75 0 110-7.5 3.75 3.75 0 010 7.5z"
        fill="#C7C7CD"
      />
      <path
        d="M4.5 3.75l15 16.5M14.522 14.775a3.75 3.75 0 01-5.045-5.55"
        stroke="#C7C7CD"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.937 6.43C3.115 8.367 1.5 12 1.5 12s3 6.75 10.5 6.75c1.757.013 3.493-.392 5.062-1.181M19.557 15.853C21.6 14.023 22.5 12 22.5 12s-3-6.75-10.5-6.75a11.75 11.75 0 00-1.94.157M12.705 8.316a3.753 3.753 0 013.028 3.331"
        stroke="#C7C7CD"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )

