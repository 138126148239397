import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export type CheckboxProps = {
    checked: boolean;
    partiallySelected?: boolean;
    color?: 'normal' | 'gold'; 
    onClick: (e: React.MouseEvent<HTMLSpanElement>) => void;
    size?: 'sm' | 'md';
}

export const Checkbox: React.FC<CheckboxProps> = ({ checked, onClick, color = 'normal', size = 'md', partiallySelected = false }) => {
    return(
        <label 
            className={classNames(styles['checkbox__container'], 
                {
                    'checkbox__active': checked,
                    [styles['checkbox__gold']]: color === 'gold',
                    [styles['checkmark__partialselected']]:partiallySelected,
                    'checkbox__sm': size === 'sm'
                }
            )}
        >
            <input type="checkbox" className={styles['dummyCheckbox']} checked={checked && !partiallySelected} onChange={() => {return;}} value="" />
            <span className={styles['checkmark']}  onClick={onClick}  />
        </label>  
    );
};