import { AppThunk, AppDispatch } from '../types';
import { CronExpressionHandler, CronExpressionResponse } from '../../api/cron-expression';
import { GET_CRON, CronPayload } from './types';

//Get Cron Action
const handleGetCronSuccess = (dispatch: AppDispatch, response: CronExpressionResponse) => {
    const payload: CronPayload = { description: response.data.description, executionDates: response.data.executionDates };
    dispatch({ type: GET_CRON, payload });
};

export function get_cron(this: any, expression: string): AppThunk {
    return function(this: any, dispatch) {
        CronExpressionHandler.GetCron(expression, handleGetCronSuccess.bind(this, dispatch as unknown as AppDispatch));
    };
}
