import React, {
	useState,
	useRef,
	useEffect,
	useMemo,
	forwardRef,
	useImperativeHandle,
	useCallback,
	Fragment,
} from "react";
import { FieldValidator, useField } from "formik";
import "./styles.scss";
import { ShowWhenTrue } from "../../helpers";
import { useDidUpdate } from "rooks";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";
import { omit, isEqual, has } from "lodash";
import { TooltipTop } from "../tooltips";
import { useOutsideClick } from "rooks5";
import { Portal } from "@components/use-portal";

export type _selectoptionType = {
	value: any;
	label: string;
	key?: string;
	[x: string]: any;
};

export interface SelectFieldProps {
	options: _selectoptionType[];
	initial_value?: _selectoptionType | _selectoptionType["value"];
	name: string;
	label?: React.ReactNode;
	className?: string;
	required?: boolean;
	placeholder?: string;
	autoSelectFirstOption?: boolean;
	hideInputField?: boolean;
	position?: "top" | "normal" | "left" | "right";
	reset_options?: boolean;
	onOptionClick?: (
		option: _selectoptionType,
		selectedOptions?: Record<string, any>,
		selected?: boolean
	) => void;
	onSelectAll?: (selectedOptions: Record<string, any>) => void;
	multiple_select?: boolean;
	disabled?: boolean;
	useSelectedOptionsFromProps?: boolean;
	selectedOptionsFromProps?: Record<string, any>;
	infoText?: string;
	onFilterTextChange?: (arg0: string) => any;
	useOptionSearch?: boolean;
	useDynamicWidth?: boolean;
	no_wrap_multiple_select?: boolean;
	max_items?: number;
	handleFilterInParent?: boolean;
	renderItemAtOptionsBottom?: () => JSX.Element;
	children?: React.ReactNode;
	renderOptionLabel?: (option: _selectoptionType) => JSX.Element;
	selectAll?: boolean;
	validate?: FieldValidator;
	usePortal?: boolean;
	setWidthManually?: boolean;
}

export type SelectField = {
	resetSelectedOption: () => void;
	setOption: (
		option: _selectoptionType,
		selectedOptionsForMultiSelect?: Record<string, any>
	) => void;
	closeDropdown: () => void;
	setFilterText: (text: string) => void;
	revalidateOptions: () => void;
	getSelectedOptionValue: () => string;
};

const position_classname: any = {
	normal: "",
	top: "select-field-options--top",
	left: "select-field-options--left",
	right: "select-field-options--right",
};

export const NewSelectField = forwardRef<SelectField, SelectFieldProps>(
	(
		{
			options,
			label,
			className = "",
			required,
			infoText = "",
			autoSelectFirstOption,
			position = "normal",
			no_wrap_multiple_select = false,
			reset_options,
			placeholder = "",
			hideInputField = false,
			onOptionClick,
			onSelectAll,
			multiple_select,
			disabled,
			useSelectedOptionsFromProps = false,
			selectedOptionsFromProps = {},
			onFilterTextChange,
			useOptionSearch = true,
			useDynamicWidth = false,
			children,
			max_items = -1,
			handleFilterInParent = false,
			renderItemAtOptionsBottom,
			renderOptionLabel,
			selectAll = false,
			validate,
			usePortal,
			setWidthManually,
			// handleFilterInParent is set to true when filtering is handled at the parent component
			...props
		},
		ref
	) => {
		const [, setAllOptionsSelected] = useState(false);
		const [showOptions, toggleOptionsList] = useState(false);
		const [fieldHandlers, fieldMeta, { setValue }] = useField({
			validate,
			name: props.name,
		});
		const selectRef = useRef<HTMLDivElement>(null);
		const labelFieldRef = useRef<HTMLDivElement | HTMLLabelElement | any>(
			null
		);
		const [selectedOptions, setSelectedOptions] = useState<
			Record<string, any>
		>({});
		const selectedOptionsRef = useRef<any>(null);
		const [filterText, setFilterText] = useState("");
		const inputFieldRef = useRef<HTMLInputElement>(null);
		const optionsListRef = useRef<HTMLDivElement>(null);
		const resizeObserverRef = useRef<any>();
		const intersectionObserverRef = useRef<any>();
		const portalRef = useRef<Portal>(null);

		const activeOption = useMemo(() => {
			let label = "",
				value = "";
			if (multiple_select) {
				// for multiple selection, values should be a string of values separated by commas
				const valuesList = fieldMeta.value?.split(",") || "";
				const _selectedOptions: typeof selectedOptions = {};
				valuesList.forEach((initialValue: string) => {
					const __value = initialValue.trim();
					const option = options.find(
						(_opt) => _opt.value === __value
					);
					if (option) {
						value += (value !== "" ? "," : "") + option.value;
						label += (label !== "" ? ", " : "") + option.label;
						_selectedOptions[option.label] = option.value;
					}
				});
				setSelectedOptions(_selectedOptions);
			} else {
				const selectedOption = options.find(
					(opt) => opt.value === fieldMeta.value
				);
				if (selectedOption) {
					label = selectedOption.label;
					value = selectedOption.value;
				}
			}
			return { label, value };
		}, [multiple_select, options, fieldMeta.value]);

		const activeOptionRef = useRef<_selectoptionType>(activeOption);

		useEffect(() => {
			if (
				useDynamicWidth &&
				!multiple_select &&
				activeOption.label &&
				inputFieldRef.current
			) {
				inputFieldRef.current.size = activeOption.label.length + 1;
			}
		}, [activeOption.label]);

		useEffect(() => {
			selectedOptionsRef.current = selectedOptions;
		}, [selectedOptions]);

		useEffect(() => {
			activeOptionRef.current = activeOption;
		}, [activeOption]);

		// to close the options list if user clicks outside
		useOutsideClick(
			usePortal ? optionsListRef : selectRef,
			(e) => {
				if(usePortal) {
					// stopPropagation is added as dropdown doesnt close on clicking the select field 
					e.stopPropagation();
				}
				toggleOptionsList(false);
			},
			showOptions
		);

		const handleValueSelection = (option: _selectoptionType) => {
			if (!useSelectedOptionsFromProps) setValue(option.value);
			toggleOptionsList(false);
		};

		const updateSelectedValue = (
			__selectedOptions: typeof selectedOptions
		) => {
			let newValue = "";
			let activeOptionName = "";
			Object.entries(__selectedOptions).forEach(([__label, value]) => {
				newValue += (newValue !== "" ? "," : "") + value;
				activeOptionName +=
					(activeOptionName !== "" ? ", " : "") + __label;
			});
			if (!useSelectedOptionsFromProps) {
				// state is handled in the parent component
				setValue(newValue);
			}
			setSelectedOptions(__selectedOptions);
		};

		const handleMultipleValueSelection = (
			option: _selectoptionType,
			_selectedOptions: typeof selectedOptions
		) => {
			let __selectedOptions = { ..._selectedOptions };
			let selected = true;
			const label = option.label;
			if (selectedOptions[label]) {
				__selectedOptions = omit(__selectedOptions, label);
				selected = false;
			} else {
				__selectedOptions[label] = option.value;
			}
			updateSelectedValue(__selectedOptions);
			onOptionClick && onOptionClick(option, __selectedOptions, selected);
		};

		const revalidateOptions = () => {
			const __selectedOptions: typeof selectedOptions = {};
			options.forEach((option) => {
				if (selectedOptionsRef.current[option.label]) {
					__selectedOptions[option.label] = option.value;
				}
			});
			if (!isEqual(__selectedOptions, selectedOptionsRef.current)) {
				setSelectedOptions(__selectedOptions);
			}
		};

		useImperativeHandle(
			ref,
			() => ({
				resetSelectedOption: () => {
					if (multiple_select) {
						setValue("");
						setSelectedOptions({});
					} else {
						handleValueSelection({ label: "", value: "" });
					}
				},
				setOption: (
					option: _selectoptionType,
					_selectedOptions = selectedOptionsRef.current
				) => {
					if (multiple_select && _selectedOptions) {
						handleMultipleValueSelection(option, _selectedOptions);
					} else {
						// for single selection
						handleValueSelection(option);
					}
				},
				closeDropdown: () => {
					toggleOptionsList(false);
				},
				setFilterText: (_t: string) => {
					setFilterText(_t);
				},
				revalidateOptions,
				getSelectedOptionValue: () => {
					if (multiple_select) {
						return selectedOptionsRef.current.join(",");
					}
					return activeOptionRef.current.value;
				},
			}),
			[multiple_select]
		);

		useDidUpdate(() => {
			// reset options when select field changes from select to multi select or vice versa
			handleValueSelection({ label: "", value: "" });
			setSelectedOptions({});
		}, [multiple_select]);

		const selectAllCb = () => {
			setAllOptionsSelected(true);
			const __selectedOptions: typeof selectedOptions = {};
			options.forEach((option) => {
				__selectedOptions[option.label] = option.value;
			});
			updateSelectedValue(__selectedOptions);
			onSelectAll && onSelectAll(__selectedOptions);
		};

		const getOptionSelectedStatus = useCallback(
			(optionLabel: string): boolean =>
				useSelectedOptionsFromProps
					? has(selectedOptionsFromProps, optionLabel)
					: !!selectedOptions[optionLabel],
			[
				useSelectedOptionsFromProps,
				selectedOptionsFromProps,
				selectedOptions,
			]
		);

		const selectedOptionsToDisplay = useMemo(
			() =>
				useSelectedOptionsFromProps
					? options.filter((option) =>
							getOptionSelectedStatus(option.label)
					  )
					: Object.keys(selectedOptions).map(
							(label) =>
								({
									label,
									value: selectedOptions[label],
								} as _selectoptionType)
					  ),
			[
				useSelectedOptionsFromProps,
				getOptionSelectedStatus,
				selectedOptions,
				options,
			]
		);

		const selectedOptionInSingleSelection = useMemo(() => {
			if (
				useSelectedOptionsFromProps &&
				!isEmpty(selectedOptionsFromProps)
			) {
				const optionKey = Object.keys(selectedOptionsFromProps)[0];
				return {
					label: optionKey,
					value: selectedOptionsFromProps[optionKey],
				};
			}
			return activeOption;
		}, [
			activeOption,
			useSelectedOptionsFromProps,
			selectedOptionsFromProps,
		]);

		useDidUpdate(() => {
			onFilterTextChange && onFilterTextChange(filterText);
		}, [filterText]);

		const handleFilterOptions = (
			event: React.ChangeEvent<HTMLInputElement>
		) => {
			setFilterText(event.target.value);
		};

		useEffect(() => {
			if (!showOptions && filterText) setFilterText("");
		}, [showOptions]);

		const filteredOptions: _selectoptionType[] = useMemo(
			() =>
				filterText && !handleFilterInParent
					? options.filter((_option) =>
							_option.label
								.toLowerCase()
								.includes(filterText.toLowerCase())
					  )
					: options,
			[filterText, handleFilterInParent, options]
		);

		const searchMode = useOptionSearch && showOptions;

		const handleScroll = useCallback((e: Event) => {
			if (e.target !== optionsListRef.current) {
				// SCROLL OUTSIDE THE OPTIONS LIST
				toggleOptionsList(false);
			}
		}, []);

		useEffect(() => {
			if (usePortal) {
				if (showOptions) {
					window.addEventListener("scroll", handleScroll, true);
				}
				return () => {
					if (showOptions)
						window.removeEventListener(
							"scroll",
							handleScroll,
							true
						);
				};
			} else {
				const handleIntersectionObserver = new IntersectionObserver(
					(entries) => {
						if (
							!isEmpty(entries) &&
							optionsListRef.current &&
							showOptions &&
							selectRef.current
						) {
							// IF intersectionRatio < 1 => move options list to above the label
							if (entries[0].intersectionRatio < 1) {
								optionsListRef.current.style.bottom = `${selectRef
									.current.clientHeight + 6}px`;
								optionsListRef.current.style.top = "auto";
							}
						} else if (!showOptions && optionsListRef.current) {
							optionsListRef.current.style.bottom = "auto";
							optionsListRef.current.style.top = "30px";
						}
					}
				);
				intersectionObserverRef.current = handleIntersectionObserver;
				optionsListRef.current &&
					handleIntersectionObserver.observe(optionsListRef.current);
				return () => {
					if (optionsListRef.current)
						intersectionObserverRef.current?.unobserve(
							optionsListRef.current
						);
				};
			}
		}, [showOptions]);

		const additionalStyleForOptionsDropdown = useMemo(
			() =>
				hideInputField ? { left: -150, top: -5, minWidth: 170 } : {},
			[hideInputField]
		);

		useEffect(() => {
			if (multiple_select) {
				// to adjust the height of multi select dropdown
				// @ts-ignore
				const handleResizeObserver = new ResizeObserver((entries) => {
					if (optionsListRef.current && !isEmpty(entries)) {
						if (usePortal) {
							portalRef.current?.reCalculatePosition();
						} else {
							optionsListRef.current.style.top = `${entries[0]
								.contentRect.height + 6}px`;
						}
					}
				});
				resizeObserverRef.current = handleResizeObserver;
				labelFieldRef.current &&
					handleResizeObserver.observe(labelFieldRef.current);
			}
			return () => {
				if (multiple_select && labelFieldRef.current)
					resizeObserverRef.current?.unobserve(labelFieldRef.current);
			};
		}, [usePortal]);

		const minimumHeightOfOptions = useMemo(
			() =>
				filteredOptions?.length
					? filteredOptions.length > 4
						? 120
						: filteredOptions.length * 24
					: 0,
			[filteredOptions]
		);

		const showErrorMessage = useMemo(
			() => fieldMeta.error && fieldMeta.touched,
			[fieldMeta.error, fieldMeta.touched]
		);

		const OptionsWrapper = usePortal ? Portal : Fragment;
		const wrapperProps = usePortal
			? {
					parentRef: multiple_select ? labelFieldRef : inputFieldRef,
					childRef: optionsListRef,
					showChild: showOptions,
					className,
					rightAlign: position === "right",
					setParentWidthForChild: !setWidthManually,
					offset: { top: multiple_select ? 3 : 2 },
					ref: portalRef,
			  }
			: {};

		const onSelectClick = () => {
			if(!disabled) {
				toggleOptionsList(!showOptions)
			}
		}

		return (
			<div
				className={classNames("select-field", {
					[className]: className,
				})}
				data-fieldname={props.name}
				{...props}
			>
				<label
					className="inputfield__label"
					ref={hideInputField && usePortal ? labelFieldRef : null}
				>
					<span
						className={required ? "red-star" : ""}
						ref={!hideInputField ? null : selectRef}
						onClick={() => toggleOptionsList(!showOptions)}
					>
						{label}
					</span>
					<ShowWhenTrue show={!!infoText}>
						<TooltipTop placement="topRight" overlay={infoText}>
							<img
								src="/icons/info-fields.png"
								width="16"
								height="16"
								className="info__icon"
								alt="information-icon"
							/>
						</TooltipTop>
					</ShowWhenTrue>
				</label>

				<div
					className={classNames("select-field-box", {
						"select-field-box--active": showOptions,
						"select-field-error": showErrorMessage,
					})}
					ref={hideInputField ? null : selectRef}
					onBlur={fieldHandlers.onBlur}
				>
					{multiple_select ? (
						<ShowWhenTrue show={!hideInputField}>
							<div
								className={classNames(
									"select-field-value multiple-select-values",
									{ "no-wrap": no_wrap_multiple_select },
									{ "select-field-disabled": disabled }
								)}
								onClick={onSelectClick}
								ref={labelFieldRef}
							>
								<div className="multiple-select-tags-list">
									{selectedOptionsToDisplay.map((_option) => {
										return (
											<span
												key={
													_option.label +
													_option.value
												}
												className="multiple-select-tags"
											>
												{_option.label}
												{!disabled ? (
													<span
														className="closeIcon"
														onClick={(e) => {
															e.stopPropagation();
															!disabled &&
																handleMultipleValueSelection(
																	_option,
																	selectedOptions
																);
															// onOptionClick && onOptionClick(_option, selectedOptions);
														}}
													>
														X
													</span>
												) : null}
											</span>
										);
									})}
								</div>
								<ShowWhenTrue show={showOptions}>
									<input
										className={"multi-select-field-search"}
										onClick={(e) => e.stopPropagation()}
										value={filterText}
										autoComplete="off"
										placeholder="Type here to Search"
										onChange={handleFilterOptions}
										readOnly={!searchMode}
										ref={inputFieldRef}
										autoFocus
									/>
								</ShowWhenTrue>
							</div>
						</ShowWhenTrue>
					) : (
						<input
							className={"select-field-value"}
							value={
								searchMode
									? filterText
									: selectedOptionInSingleSelection.label
							}
							onClick={onSelectClick}
							autoComplete="off"
							placeholder={classNames(
								{ [placeholder]: !searchMode },
								{
									[selectedOptionInSingleSelection.label]: searchMode,
								}
							)}
							disabled={!!disabled}
							onChange={handleFilterOptions}
							readOnly={!searchMode}
							ref={inputFieldRef}
							name={props.name}
						/>
					)}
					<ShowWhenTrue
						show={
							max_items !== -1 &&
							Object.keys(selectedOptions).length >= max_items
						}
					>
						<span
							className=""
							style={{
								color: "red",
								fontSize: "12px",
							}}
						>
							Max {max_items} items
						</span>
					</ShowWhenTrue>
					<OptionsWrapper {...wrapperProps}>
						<div>
							<div
								className={classNames(
									`select-field-options-outer ${position_classname[position]}`,
									{
										"select-field-bottom-fixed-option": !!renderItemAtOptionsBottom,
									},
									{ hide: !showOptions || disabled }
								)}
								ref={optionsListRef}
								style={{ minHeight: minimumHeightOfOptions }}
							>
								<ul
									style={additionalStyleForOptionsDropdown}
									className={"select-field-options"}
								>
									{multiple_select && selectAll ? (
										<li
											className="select-field-option select-field-option-multiple"
											onClick={selectAllCb}
										>
											--- Select All ---
										</li>
									) : null}
									{multiple_select
										? (filteredOptions || []).map(
												(option, index) => {
													const __selected = getOptionSelectedStatus(
														option.label
													);
													return (
														<li
															key={
																option.label +
																index
															}
															className="select-field-option select-field-option-multiple"
															onClick={() => {
																if (
																	max_items !==
																		-1 &&
																	Object.keys(
																		selectedOptions
																	).length >=
																		max_items
																)
																	return;
																handleMultipleValueSelection(
																	option,
																	selectedOptions
																);
															}}
														>
															<label className="checkbox__container">
																<input
																	type="checkbox"
																	checked={
																		__selected
																	}
																	onChange={() => {
																		return;
																	}}
																/>
																<span
																	className="checkmark"
																	onClick={(
																		e
																	) =>
																		e.preventDefault()
																	}
																/>
															</label>
															{renderOptionLabel
																? renderOptionLabel(
																		option
																  )
																: option.label}
														</li>
													);
												}
										  )
										: (filteredOptions || []).map(
												(option, index) => {
													return (
														<li
															key={
																option.key
																	? option.key
																	: option.label +
																	  index
															}
															className={classNames(
																"select-field-option",
																{
																	"select-field-option--selected":
																		option.value ===
																		activeOption.value,
																}
															)}
															onClick={() => {
																handleValueSelection(
																	option
																);
																onOptionClick &&
																	onOptionClick(
																		option
																	);
															}}
															id={`selectedUser_WK_${option.label.replaceAll(
																" ",
																""
															)}`}
														>
															{renderOptionLabel
																? renderOptionLabel(
																		option
																  )
																: option.label}
														</li>
													);
												}
										  )}
								</ul>
								{renderItemAtOptionsBottom ? (
									<div
										className={classNames(
											"select-field-option",
											"select-field-option-bottom-fixed"
										)}
									>
										{renderItemAtOptionsBottom()}
									</div>
								) : null}
							</div>
							{showErrorMessage && (
								<span
									className="inputfield__errormessage"
									role="error-message"
								>
									{fieldMeta.error}
								</span>
							)}
						</div>
					</OptionsWrapper>
				</div>

				{children}
			</div>
		);
	}
);

NewSelectField.displayName = "NewSelectField";
