import { InputField, InputFieldType } from "@components/formcreators/field-creator";
import { FieldSchemaCreator, FieldSchemaValidator } from "@components/formcreators/schema-creator";
import { ComponentPropertiesValuesType } from "@components/formcreators/types";
import { useFormikContext } from "formik";
import get from "lodash/get";
import React, { useCallback } from "react";


const UniqueValueField = (props: InputFieldType) => {
    const { initialValues } = useFormikContext<ComponentPropertiesValuesType>();

    const validate = useCallback(() => {
        const currentFieldAlreadyUsedValuesList = get(initialValues.extraData?.__refValuesForUniqueValueField, props.fieldData.key) || [];
        const schema = FieldSchemaCreator(props.fieldData.templateOptions)
            .notOneOf(Object.values(currentFieldAlreadyUsedValuesList), "Must be unique")
            .test('empty space check', 'Cannot start with space', (value: string) => {
                return !value.startsWith(' ')
            });

        return FieldSchemaValidator(schema)
    }, [initialValues.extraData?.__refValuesForUniqueValueField, props.fieldData.key, props.fieldData.templateOptions])

    return (
        <InputField 
            {...props}
            validate={validate()}
        />
    )

}

export default UniqueValueField;