import { UserData } from '../../api/admin-handler';

export const TOGGLE_USERMANAGEMENT_MODAL = 'TOGGLE_USERMANAGEMENT_MODAL';
export const ACTIVE_INACTIVE_USERS = 'ACTIVE_INACTIVE_USERS';
export const SET_ACTIVE_ADMIN_TAB = 'SET_ACTIVE_ADMIN_TAB';

export type ActionTypes = {
    type: typeof ACTIVE_INACTIVE_USERS | typeof SET_ACTIVE_ADMIN_TAB;
    payload: any;
}
export type ToggleUserManagementModalType = {
    type: typeof TOGGLE_USERMANAGEMENT_MODAL;
    payload: Record<keyof UserManagementReducerState['showModal'], boolean>;
}

export type UserManagementReducerState = {
    showModal: { 
        addUser: boolean;
        editUser: boolean;
        editCluster: boolean;
    };
}



export type AdminReducerState = {
    users: UserData[];
    activeAdminTab: 'databricksclustersettings' | 'usermanagement';
}