import React, { memo } from 'react';
import { ErrorContent } from './error-page-content';
import { useHistory } from 'react-router-dom';


export const InvalidUrlPage: React.FC = memo(() => {
    const history = useHistory();

    return(
        <ErrorContent 
            title={
                <p className="title">
                    Whoops. Invalid URL. 
                </p>
            }
            subtitle={
                <p 
                    className="subtitle text-underline c-pointer"
                    onClick={() => history.push('/workflow')}    
                >
                    Click here to go to Home page.
                </p>
            }
        />
    );
});

InvalidUrlPage.displayName = 'Invalid URL Page';