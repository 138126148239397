/* eslint-disable @typescript-eslint/no-use-before-define */
import {
	AnalyticsHandler,
	PlotResponse,
	RasterConfig,
	StatementInfo,
	SubmitPlotInfo,
	SubmitPlotInfoData,
} from "@api/analytics-handler";
import { errorAlert } from "@components/toastify/notify-toast";
import { Env } from "@constants/settings";
import { PlotData } from "@pages/workflow-analytics-page/Canvas";
import { PlotCreationErrorMessages } from "@pages/workflow-analytics-page/enums";
import {
	AnalyticsSession,
	checkIfClusterCanRunPlots,
	getSubmitPlotPayloadFromPlotSelectionForm,
	SessionInfoTypes,
} from "@pages/workflow-analytics-page/utils";
import {
	ClusterSelectionInfoInPlot,
	setClusterInfoInPlot,
	setPlotDataProgress,
	toggleAnalyticsModal,
	togglePlotInProgressSpinner,
} from "@store/analytics";
import { WorkflowAnalyticsTabInfo } from "@store/canvas";
import { useAppSelector } from "@store/hooks";
import { SingleItemInfo } from "@store/workflow";
import {
	setSingleItemInfoForModals,
	toggleModal,
} from "@store/workflow/actions";
import { useGetActiveTabInfo } from "@utils/get-active-tab-info";
import { isEmpty, omit } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInterval } from "rooks";
import {
	MultiBandColumnsInfo,
	PlotSelectionFormType,
	RasterSelectionInfo,
} from ".";
import { RootState } from "@store/types";

export type usePlotApiProps = {
	onSubmitPlotSuccessCb: (
		formValues: PlotSelectionFormType,
		plotInfo: SubmitPlotInfo,
		plotStatementId: string
	) => void;
	onSubmitPlotFailureCb: (message: string) => void;
	onPlotDataSuccessCb: (plotData: PlotData) => void;
	onPlotDataFailureCb: (message: string) => void;
	handleSetInitialDataForPlotForm: (plotDataRef: SubmitPlotDataRef) => void;
	showPlotSelectionModal: (componentId: string, isNewPlot: boolean) => void;
	hidePlotSelectionModal: () => void;
};

export type usePlotApiReturnType = {
	submitPlot: (arg0: SubmitPlotDataRef, tabInfoData?: any, setSession?: any) => Promise<void>;
	showPlotSelectionSpinner: boolean;
	togglePlotSelectionSpinner: (arg0: boolean) => void
	startNewAnalyticsSession: () => void;
	createSessionAndPrepareData: (clusterId: string, sessionId?: string) => Promise<boolean>;
	dataPrepState: boolean;
	toggleSessionCreationLoadingState: (action: boolean) => void;
	onClusterSelectionCancel: () => void;
	onPlotSelectionModalCancel: () => void;
	prepareDataForPlot: (tabData?: WorkflowAnalyticsTabInfo, statementData?: any)=> void
};

export type SubmitPlotDataRef = {
	columnSelectionInfo: {
		multiBandCols: MultiBandColumnsInfo;
	} & Record<"keys" | "values" | "seriesGroupings", string[]>;
	formValues: PlotSelectionFormType;
	// plotInfo: SubmitPlotInfo;
	rasterSelectionInfo: RasterSelectionInfo | null;
	currentRasterConfigs: RasterConfig[] | undefined;
};

const usePlotApi = ({
	onSubmitPlotSuccessCb,
	onPlotDataFailureCb,
	onPlotDataSuccessCb,
	onSubmitPlotFailureCb,
	handleSetInitialDataForPlotForm,
	showPlotSelectionModal,
	hidePlotSelectionModal,
}: usePlotApiProps): usePlotApiReturnType => {
	const [showPlotSelectionSpinner, togglePlotSelectionSpinner] = useState(
		false
	);
	const activeUserInfo = useAppSelector(
		(store) => store.AccountReducer.activeUserInfo
	);
	const [statementIdForPolling, setStatementIdForPolling] = useState<
		string | null
	>(null);
	const dispatch = useDispatch();
	const activeTabInfo = useGetActiveTabInfo(
		"analytics"
	) as WorkflowAnalyticsTabInfo;
	const activeTab = useAppSelector(
		(store) => store.CanvasReducer.analytics.activeTab
	);
    const workSpaceData = useSelector((store: RootState) => store.ClusterReducer.workspaceList);
    const enabledWorkspace = workSpaceData.filter((item)=>item.isEnabled)[0];

	const activeTabInfoRef = useRef(activeTabInfo);
	const clusters = useAppSelector((store) => store.ClusterReducer.clusters);
	const submitPlotDataRef = useRef<SubmitPlotDataRef | null>(null);
	const [dataPrepState, setDataPrepState] = useState(false);

	useEffect(() => {
		activeTabInfoRef.current = activeTabInfo;
	}, [activeTabInfo]);

	const initializeSessionCreator = (_clusterId?: string) => {
		if (activeTabInfoRef.current) {
			const clusterId =
				_clusterId ||
				activeTabInfoRef.current.activeClusterIdForPreview;
			const sessionCreator = new AnalyticsSession(
				activeTabInfoRef.current.env,
				{
					userName: activeUserInfo.name,
					workflowName: activeTabInfoRef.current.name,
				},
				clusterId,
				activeTabInfoRef.current.config
			);
			return sessionCreator;
		}
		return null;
	};

	const sessionCreatorRef = useRef<AnalyticsSession | null>(
		initializeSessionCreator()
	);

	useEffect(() => {
		sessionCreatorRef.current = initializeSessionCreator();
		handleSetSubmitPlotDataRef(null);
	}, [activeTab, activeUserInfo]);

	const handleToggleClusterSelectionModal = (
		type: ClusterSelectionInfoInPlot["type"],
		error: ClusterSelectionInfoInPlot["error"] = ""
	) => {
		dispatch(setClusterInfoInPlot({ type, error }));
		dispatch(toggleAnalyticsModal("clusterSelection", true));
		toggleSessionCreationLoadingState(false);
	};

	const sessionCreator = sessionCreatorRef.current;

	const toggleSessionCreationLoadingState = (action: boolean) => {
		setDataPrepState(action);
		dispatch(togglePlotInProgressSpinner(action));
	};

	const createDatabricksSession = async (clusterId: string, _sessionId?: string) => {
		const clusterState = checkIfClusterCanRunPlots(clusters, clusterId);
		let sessionId=_sessionId, error;
		if (clusterState.valid && !_sessionId) {
			const sessionCreator = initializeSessionCreator(clusterId);
			sessionCreatorRef.current = sessionCreator;
			if (sessionCreator)
				try {
					// @ts-ignore
					sessionId = await sessionCreator.createDatabricksSession();
				} catch {
					error = PlotCreationErrorMessages.SessionCreationFailed;
				}
		} else {
			if (submitPlotDataRef.current) {
				// close plot selection modal and show modal cluster selection
				hidePlotSelectionModal();
			}

			error = clusterState.error;
			handleToggleClusterSelectionModal(
				clusterState.error ? "error" : "new",
				clusterState.error || undefined
			);
		}

		return { sessionId, error };
	};

	const prepareDataForPlot = async (tabData?: WorkflowAnalyticsTabInfo, statementData?: any) => {		
		const dataPrepState = await sessionCreatorRef.current?.prepareDataForPlot(tabData, statementData);
		if (
			dataPrepState &&
			(!dataPrepState.statementSubmitted ||
				!dataPrepState.retrievedStatementInfo)
		)
			toggleSessionCreationLoadingState(false);
			togglePlotSelectionSpinner(false)
		return dataPrepState;
	};

	const createSessionAndPrepareData = async (clusterId: string, sessionId?: string, tabData?: WorkflowAnalyticsTabInfo, statementData?: any, setSession?: any) => {
		// let sessionState = { sessionId}
		const sessionInfo = await createDatabricksSession(clusterId, sessionId);
		if (!sessionInfo.error && sessionCreator) {
			const dataPrepState = await prepareDataForPlot(tabData, statementData);
			if (
				dataPrepState &&
				dataPrepState.retrievedStatementInfo &&
				dataPrepState.statementSubmitted
			) {
				// if submitPlotDataRef exists that means session is created
				// after a plot is selected
				if (submitPlotDataRef.current) {
					submitPlot(submitPlotDataRef.current);
				} else {
					//
					toggleSessionCreationLoadingState(false);
					togglePlotSelectionSpinner(false);
					showPlotSelectionModal(dataPrepState.componentId, true)
				}
				return true;
			} else if (dataPrepState?.error) {
				toggleSessionCreationLoadingState(false);
				togglePlotSelectionSpinner(false);
				errorAlert(dataPrepState.error);
			}
		} else if (sessionInfo.error) {
			toggleSessionCreationLoadingState(false);
			togglePlotSelectionSpinner(false);
			errorAlert(sessionInfo.error);
		}
		return false;
	};

	const handleNewSessionCreation = async (
		sessionInfo: SessionInfoTypes,
		tabData?: WorkflowAnalyticsTabInfo, 
		statementData?: any,
		setSession?: any
	) => {
		if (sessionCreator)
			if (sessionInfo.type === "SESSION_INACTIVE") {
				if (Env.databricks) {
					// create session again
					if (sessionInfo.response.clusterId) {
						createSessionAndPrepareData(
							sessionInfo.response.clusterId,
							undefined, tabData, statementData, setSession
						);
					} else {
						handleToggleClusterSelectionModal("new");
					}
				} else {
					// needs to be validated
					const sessionId= await sessionCreator?.createClouderaSession(
						// @ts-ignore
						sessionInfo.response
					);
					setSession && setSession(sessionId);
					prepareDataForPlot(tabData, statementData);
				}
			} else {
				// SESSION_NOT_FOUND
				if (Env.databricks) {
					handleToggleClusterSelectionModal("new");
				} else {
					// when there's no session
					// To show run preview form
					const { name, id, version } = activeTabInfoRef.current;
					const selectedWorkflowInfo: SingleItemInfo = {
						name,
						id,
						version,
					};
					dispatch(setSingleItemInfoForModals(selectedWorkflowInfo));
					toggleSessionCreationLoadingState(false);
					dispatch(toggleModal("runWorkflow", true));
				}
			}
	};

	const startNewAnalyticsSession = async () => {
		if (sessionCreator) {
			const sessionInfo = await sessionCreator.checkIfActiveTabSessionIsActive();
			switch (sessionInfo.type) {
				case "SESSION_ACTIVE": {
					const dataPrepState = await prepareDataForPlot();
					if (dataPrepState?.error) {
						// if session is active and data is invalid
						if (Env.databricks) {
							// create session again
							if (sessionInfo.response.clusterId) {
								createSessionAndPrepareData(
									sessionInfo.response.clusterId
								);
							} else {
								handleToggleClusterSelectionModal("new");
							}
						}
						// else {
						// createCloudersession and submitplotdata
						// }
					} else {
						// show plot selection modal if data prep state is success
						// startNewAnalyticsSession is triggered when a component is clicked
						showPlotSelectionModal(
							sessionInfo.response.componentId,
							true
						);
						toggleSessionCreationLoadingState(false);
					}
					break;
				}
				case "SESSION_INACTIVE":
				case "SESSION_NOT_FOUND":
					handleNewSessionCreation(sessionInfo);
					break;
			}
		}
	};

	const handleSetSubmitPlotDataRef = useCallback(
		(data: SubmitPlotDataRef | null) => {
			submitPlotDataRef.current = data;
		},
		[]
	);

	const handleStopProgressPolling = () => {
		stopProgressPolling();
		dispatch(togglePlotInProgressSpinner(false));
	};

	const handlePlotDataFailure = (message?: string) => {
		onPlotDataFailureCb(message || "No plot data found");
		handleStopProgressPolling();
	};

	const handleGetPlotDataResponse = (response: PlotResponse) => {
		if (!isEmpty(response)) {
			handleStopProgressPolling();
			const plotData: PlotData = omit(
				response,
				"id",
				"sessionId",
				"componentId"
			);
			toggleSessionCreationLoadingState(false);
			onPlotDataSuccessCb(plotData);
		} else {
			handlePlotDataFailure("Error generating plot by the api");
		}
	};

	function handlePlotDataSuccessProgress(response: number) {
		dispatch(setPlotDataProgress(response));
		if (response === 1) {
			stopProgressPolling();
			if (activeTabInfo?.sessionId) {
				if (activeTabInfo.isStreaming) {
					// connectToSocketForStreamingData(activeTabInfo.sessionId);
				} else if (statementIdForPolling) {
					AnalyticsHandler.GetPlotData(
						activeTabInfo.sessionId,
						statementIdForPolling,
						handleGetPlotDataResponse,
						handlePlotDataFailure
					);
				}
			}
		}
	}

	const getProgressStatus = () => {
		togglePlotSelectionSpinner(false);
		activeTabInfo?.sessionId &&
			statementIdForPolling &&
			AnalyticsHandler.GetPlotDataStatus(
				activeTabInfo.sessionId,
				statementIdForPolling,
				handlePlotDataSuccessProgress,
				handlePlotDataFailure
			);
	};

	const [startProgressPolling, stopProgressPolling] = useInterval(
		getProgressStatus,
		1000
	);

	const onSubmitPlotError = (response: any) => {
		togglePlotSelectionSpinner(false);
		onSubmitPlotFailureCb(response.data);
	};

	const onSubmitPlotSuccess = (
		formValues: PlotSelectionFormType,
		plotInfo: SubmitPlotInfo,
		response: StatementInfo
	) => {
		if (response.state !== "error" && response.state !== "cancelled") {
			onSubmitPlotSuccessCb(formValues, plotInfo, response.jobId);
			setStatementIdForPolling(response.jobId);
			dispatch(togglePlotInProgressSpinner(true));
			startProgressPolling();
		} else {
			togglePlotSelectionSpinner(false);
			onSubmitPlotFailureCb("Error submitting the plot statement");
		}
	};

	const handleSubmitPlot = (
		sessionId: string,
		clusterId: string | undefined,
		submitPlotData: SubmitPlotDataRef
	) => {
		const {
			formValues,
			columnSelectionInfo: {
				keys,
				values,
				seriesGroupings,
				multiBandCols,
			},
			rasterSelectionInfo,
			currentRasterConfigs,
		} = submitPlotData;
		const plotInfo = getSubmitPlotPayloadFromPlotSelectionForm(formValues, {
			sessionId,
			keys,
			values,
			seriesGroupings,
			multiBandCols,
			rasterSelectionInfo,
			currentRasterConfigs,
		});
		// sessionid needs to be updated in output_key if a new session is created
		// plotInfo.output_key.workflow_session_id = sessionId;
		const data: SubmitPlotInfoData = {
			sessionId,
			payload: plotInfo,
			workspaceType:enabledWorkspace?.workspaceType,
      		workspaceId:enabledWorkspace?.workspaceId
		};
		if (Env.databricks) {
			data.clusterId = clusterId;
		}
		handleSetSubmitPlotDataRef(null);
		AnalyticsHandler.SubmitPlot(
			data,
			onSubmitPlotSuccess.bind(null, submitPlotData.formValues, plotInfo),
			onSubmitPlotError
		);
	};

	const submitPlot = async (submitPlotData: SubmitPlotDataRef, tabInfoData?: WorkflowAnalyticsTabInfo, statementData?: any, setSession?: any) => {
		if (sessionCreator) {
			togglePlotSelectionSpinner(true);

			const sessionState = await sessionCreator.checkIfActiveTabSessionIsActive(false, tabInfoData);
			switch (sessionState.type) {
				case "SESSION_ACTIVE":
					handleSubmitPlot(
						sessionState.response.sessionId,
						sessionState.response.clusterId,
						submitPlotData
					);
					break;
				case "SESSION_INACTIVE":
				case "SESSION_NOT_FOUND":
					handleSetSubmitPlotDataRef(submitPlotData);
					handleNewSessionCreation(sessionState, tabInfoData, statementData, setSession);
			}
		}
	};

	const onClusterSelectionCancel = useCallback(() => {
		if (submitPlotDataRef.current) {
			// cluster selection modal is shown after user tries to submit plot
			// and user closed the cluster modal
			// hence show submit plot modal
			// and retain user's old state
			handleSetInitialDataForPlotForm(submitPlotDataRef.current);
			togglePlotSelectionSpinner(false);
			showPlotSelectionModal(
				submitPlotDataRef.current.formValues.connectedComponentInfo
					.component_id,
				false
			);
		}
	}, []);

	const onPlotSelectionModalCancel = useCallback(() => {
		handleSetSubmitPlotDataRef(null);
	}, []);

	return {
		submitPlot,
		showPlotSelectionSpinner,
		startNewAnalyticsSession,
		createSessionAndPrepareData,
		dataPrepState,
		toggleSessionCreationLoadingState,
		onClusterSelectionCancel,
		onPlotSelectionModalCancel,
		togglePlotSelectionSpinner,
		prepareDataForPlot
	};
};

export default usePlotApi;
