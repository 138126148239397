import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/types';
import { ProfileInfo, WorkflowHandler, GetProfilesResponse, SelectedProfileResponse, GetActiveProfile } from '../../../api/workflow-handler';
import { useDidMount, useDebounce, useDidUpdate } from 'rooks';
import { toInteger } from 'lodash';
import { InPageSpinner } from '../../../components/spinners/in-page-spinner';
import { Dropdown } from '../../../components/form/dropdown';
import _ from 'lodash';
import { setActiveSelectionType, toggleModal, setActiveProfile, changeWorkflowsSelection, setSingleItemInfoForModals, setModalInfoTypeInWorkflowsPage, WorkflowsSelectionStatus } from '../../../store/workflow';
import { ProfileModalTypes, CanvasTabs } from '../enums';
import { NewEditProfileModal } from '../modals/new-edit-profile';
import { ToggleField } from '../../../components/form/toggle-field';
import { successAlert } from '../../../components/toastify/notify-toast';
import { ThreeDotsIcon } from '../assets/icons';
import { ShowWhenTrue } from '../../../helpers';
import Paginate from '../../../components/paginate';
import { useGetNumberOfItemsPerPage } from '../../../utils';
import { encryptAndExport } from '../utils';
import { TooltipTop } from '../../../components/tooltips';

export const ProfileTreeview: React.FC = () => {
    const { selectionType, activeProfile, activeTab: activeCanvasTab } = useSelector((store: RootState) => store.WorkflowReducer);
    const hideProfileTreeView = activeCanvasTab !== CanvasTabs.PROFILES;
    const [profileSearchString, setProfileSearchString] = useState('');
    // const activeProfile = useSelector((store: RootState) => store.WorkflowReducer.activeProfile);
    const [profileList, setProfileList] = useState<{ results: ProfileInfo[]; count: number }>({ results: [], count: 0 });
    const [modalType, setModalType] = useState<ProfileModalTypes>(ProfileModalTypes.new);
    const [profileInfoForEdit, setProfileInfoForEdit] = useState<ProfileInfo>({ id: 0, name: '', command: '', shareProfile: true, active: false });
    const dispatch = useDispatch();
    const [profilesSelectedState, changeProfilesSelection] = useState<WorkflowsSelectionStatus>({});
    const [showSpinner, toggleSpinner] = useState(true);
    // const selectionType = useSelector((store: RootState) => store.WorkflowReducer.selectionType);
    const multipleSelectionType = selectionType === 'multiple';
    // const [searchQuery, setSearchQuery] = useState<ProfileSearchQuery>({ pageNumber: 1, name: '' })

    const selectedProfilesStatus = Object.values(profilesSelectedState);
    const isFullySelected = multipleSelectionType && _.isEqual(selectedProfilesStatus.filter(profile => profile.status === true), selectedProfilesStatus);
    const isNotSelected = multipleSelectionType && _.isEqual(selectedProfilesStatus.filter(profile => profile.status === false), selectedProfilesStatus);
    const isPartiallySelected = multipleSelectionType && !isFullySelected && !isNotSelected;
    const numberOfProfilesPerPage = useGetNumberOfItemsPerPage();
    // use store and make activeprofile call on mount
    // const [activeProfile, setActiveProfile] = useState<ProfileInfo | null>(null);

    useDidUpdate(() => {
        dispatch(changeWorkflowsSelection(profilesSelectedState));
    }, [profilesSelectedState]);

    const addIdsToSelectedProfileState = (__profileList: ProfileInfo[]) => {
        const _selectedState: WorkflowsSelectionStatus = {};
        __profileList.forEach(profile => _selectedState[profile.id] = { name: profile.name, status: false });
        changeProfilesSelection(_selectedState);
    };

    const handleProfileResponse = (response: GetProfilesResponse) => {
        const results = response.data;
        setProfileList({ results, count: response.paging.totalNumberOfRecords });
        addIdsToSelectedProfileState(results);
        toggleSpinner(false);
    };

    const handleProfileModal = (action: boolean) => {
        dispatch(toggleModal('profile', action));
    };

    const handleSetActiveProfile = (response: GetActiveProfile) => {
        if (response.data) dispatch(setActiveProfile(response.data));
    };

    useDidMount(() => {
        WorkflowHandler.GetSelectedProfile(handleSetActiveProfile);
    });


    const retrieveUserProfiles = ({ page = 1, size = numberOfProfilesPerPage, profileSearch = profileSearchString }) => {
        toggleSpinner(true);
        WorkflowHandler.GetProfiles({ pageNumber: page, pageSize: size, name: profileSearch }, handleProfileResponse);
    };



    useEffect(() => {
        if (!hideProfileTreeView) retrieveUserProfiles({});
    }, [hideProfileTreeView, numberOfProfilesPerPage]);

    const searchProfiles = useDebounce((searchString: string) => {
        retrieveUserProfiles({ profileSearch: searchString, page: 1 });
    }, 500);

    const handleProfileSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchString = e.target.value;
        setProfileSearchString(searchString);
        searchProfiles(searchString);
    };

    const handleAllProfilesSelection = (status: boolean) => {
        const _profilesSelected: WorkflowsSelectionStatus = {};
        Object.keys(profilesSelectedState).forEach(id => {
            _profilesSelected[toInteger(id)] = { ...profilesSelectedState[id as unknown as number], status: true };
        });
        changeProfilesSelection(_profilesSelected);
        dispatch(setActiveSelectionType('multiple'));
    };

    const selectAllProfiles = () => handleAllProfilesSelection(true);
    const deSelectAllProfiles = () => handleAllProfilesSelection(false);

    const handleMultipleProfileSelection = (id: number) => {
        dispatch(setActiveSelectionType('multiple'));
        changeProfilesSelection({ ...profilesSelectedState, [id]: { ...profilesSelectedState[id], status: !profilesSelectedState[id].status } });
    };

    function handleSetProfileSuccess(response: SelectedProfileResponse) {
        if (response.success && response.data) {
            const __profileList = profileList.results.map(profile => {
                if (profile.id === response.data.id) {
                    profile.active = !profile.active;
                } else profile.active = false;
                return profile;
            });
            setProfileList({ ...profileList, results: __profileList });
            let message = response.message;
            if (response.data.active) {
                dispatch(setActiveProfile(response.data));
            } else {
                message = 'Removed default profile';
                dispatch(setActiveProfile(null));
            }
            successAlert(message);
        }
    }

    const handleToggleActiveProfile = (profile: ProfileInfo) => {
        WorkflowHandler.SetUserProfile(profile.id, handleSetProfileSuccess);
    };

    const setEditMode = (profile: ProfileInfo) => {
        setModalType('EDIT');
        setProfileInfoForEdit(profile);
        handleProfileModal(true);
    };

    const handlePageChange = (page: number) => {
        retrieveUserProfiles({ page });
    };

    const handleCloneProfiles = (ids: number[]) => {
        WorkflowHandler.CloneProfiles(ids, retrieveUserProfiles);
    };

    function toggleDeleteModal(this: any) {
        dispatch(setModalInfoTypeInWorkflowsPage('profile'));
        dispatch(toggleModal('deleteWorkflow', true, retrieveUserProfiles.bind(this, {})));
    }

    const getSelectedProfiles = () => {
        const _selectedIds: number[] = [];
        Object.entries(profilesSelectedState).forEach(([id, profile]) => {
            if (profile.status) _selectedIds.push(toInteger(id));
        });
        return _selectedIds;
    };

    const copyMultipleProfiles = () => {
        const ids = getSelectedProfiles();
        handleCloneProfiles(ids);
    };

    const exportProfile = (profile: ProfileInfo) => {
        encryptAndExport(profile.command, profile.name, 'profile');
    };

    const exportMultipleProfiles = () => {
        const selectedIds = getSelectedProfiles();
        selectedIds.forEach(id => {
            const _selectedProfile = profileList.results.find(_profile => _profile.id === id);
            _selectedProfile && exportProfile(_selectedProfile);
        });
    };

    return (
        <div className={classNames('profiles__container', { 'hide__treeview': hideProfileTreeView })}>
            <div className="activeProfile__box">
                <span className="_title">Active profile</span>
                <div className="activeProfile__innerBox" >
                    {!!(activeProfile) &&
                        <>
                            <span className="_name">
                                {activeProfile.name}
                            </span>
                            <ToggleField
                                active={true}
                                onClick={() => handleToggleActiveProfile(activeProfile)}
                            />
                        </>
                    }
                </div>
            </div>

            <div className="search__input__container">
                <input
                    value={profileSearchString}
                    onChange={handleProfileSearch}
                    className="search_input"
                    placeholder={'Try "Profile"'}
                />
                {!!(profileSearchString) &&
                    <img
                        src="/icons/treeview/search-cross.svg"
                        alt=""
                        className="search_reset"
                        onClick={() => {
                            setProfileSearchString('');
                            searchProfiles('');
                        }}
                    />
                }
            </div>
            {showSpinner &&
                (<InPageSpinner />)}
            <div className={classNames('saved__profiles__innerContainer', { 'hide': showSpinner })}>
                <div className="saved__workflows__options">
                    <div className="workflows__options__toggleAllContainer">
                        <label className={classNames('checkbox__container', { 'checkmark__partialselected': isPartiallySelected })}>
                            <input type="checkbox" checked={isFullySelected} onChange={() => { return; }} />
                            <span className="checkmark" onClick={() => (isFullySelected || isPartiallySelected) ? deSelectAllProfiles() : selectAllProfiles()} />
                        </label>
                        <Dropdown
                            title={
                                <span className="dropdown__triangle">
                                    &#x25BE;
                                </span>
                            }
                            dropdownOptions={{
                                All: { action: selectAllProfiles },
                                None: { action: deSelectAllProfiles }
                            }}
                        />
                    </div>
                    {(isPartiallySelected || isFullySelected) ?
                        <div className="common__workflow__icons">
                            <button onClick={copyMultipleProfiles}>
                                <img src="/icons/copy.svg" className="icon__square__hover" alt="" />
                            </button>
                            <button onClick={exportMultipleProfiles}>
                                <img src="/icons/export.svg" className="icon__square__hover export__icon" alt="" />
                            </button>
                            <button onClick={toggleDeleteModal}>
                                <img src="/icons/delete.svg" className="icon__square__hover" alt="" />
                            </button>
                        </div>
                        :
                        <div className="component_action_wrapper">
                            <TooltipTop title="Import Profile">
                                <button
                                    id="btn_import_profile"
                                    className="  btn__new__workflow  btn__import"
                                    onClick={function (this: any) {
                                        dispatch(toggleModal('componentImport', true, retrieveUserProfiles.bind(this, {})));
                                    }}
                                >
                                    <img src="/icons/workflow/Resting.svg" alt="" />
                                </button>
                            </TooltipTop>

                            <TooltipTop title="Create a Profile">
                                <button
                                    id="btn_new_profile"
                                    className="btn-sm btn-yellow-transparent btn__new__workflow  btn__new__action"
                                    onClick={() => {
                                        setModalType('NEW');
                                        handleProfileModal(true);
                                    }}
                                >
                                    <img src="/icons/workflow/add.svg" alt="" />
                                    <span>New </span>
                                </button>
                            </TooltipTop>
                        </div>
                    }
                </div>
                {profileList.results.map((profile: ProfileInfo, index: number) => {
                    const __selected = profilesSelectedState[profile.id]?.status;
                    return (
                        <div
                            key={index + profile.name}
                            className={classNames('workflow__item', { 'workflow__item--selected': __selected })}
                            id={`Profile_${profile.name}`}
                        >
                            <label className="checkbox__container" >
                                <input type="checkbox" checked={__selected} onChange={() => { return; }} value="" />
                                <span className="checkmark" onClick={() => handleMultipleProfileSelection(profile.id)} />
                            </label>
                            <span
                                className="workflow__title"
                            >
                                {profile.name}
                            </span>
                            <ToggleField
                                active={profile.active}
                                onClick={() => handleToggleActiveProfile(profile)}
                            />
                            <Dropdown
                                title={<ThreeDotsIcon />}
                                dropdownOptions={{
                                    'Apply': { action: () => handleToggleActiveProfile(profile) },
                                    'Edit': { action: () => setEditMode(profile) },
                                    'Duplicate': { action: () => handleCloneProfiles([profile.id]) },
                                    'Export': { action: () => exportProfile(profile) },
                                    'Delete': {
                                        action: () => {
                                            dispatch(setActiveSelectionType('single'));
                                            // optimise
                                            dispatch(setSingleItemInfoForModals(profile as any));
                                            toggleDeleteModal();
                                        }
                                    },
                                }}
                                position="right"
                            />
                        </div>
                    );
                })}
                <ShowWhenTrue show={profileList.count !== 0}>
                    <div className="pagination__container">
                        <ShowWhenTrue show={!!(profileSearchString)}>
                            <span className="pagination__searchResultsNumber">
                                {profileList.count} results for <br />
                                &#39;{profileSearchString}&#39;
                            </span>
                        </ShowWhenTrue>
                        <Paginate
                            startPage={1}
                            totalCount={profileList.count}
                            itemsPerPage={numberOfProfilesPerPage}
                            handlePageChange={handlePageChange}
                            showArrowsForPageNav
                        />
                    </div>
                </ShowWhenTrue>
            </div>
            {/* } */}
            <NewEditProfileModal
                handleSuccess={() => retrieveUserProfiles({})}
                profileInfoForEdit={profileInfoForEdit}
                modalType={modalType}
            />
        </div>
    );
};