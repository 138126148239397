/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import _ from 'lodash';
import React, { Component } from 'react';
import { Select, Radio } from 'antd';
import aggretorOptions from './aggregatorOptions';
const { Option } = Select;

export default class Aggregator extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            results: [],
            firstFocus: true,
            row: true,
            column: false,
            row_col_value: 'ROW',
            agg_type: null,
            suffix: '',
            option_list: aggretorOptions.select.filter(e => e.type.row),
            options: aggretorOptions.select
        };
    }

    render() {
        return (
            <>
                <Select 
                    placeholder="Select function"
                    dropdownClassName="editorTab"
                    style={{ 
                        width: 120 ,
                        display: 'inline-block'
                    }} 
                    onChange={(value) => {
                        if(value){
                            const obj = this.state.options.filter((option)=>{
                                return option.value === value;
                            });
                            this.setState({
                                agg_type: value,
                                row: obj[0].type.row,
                                column: obj[0].type.col,
                                row_col_value: obj[0].default.toUpperCase(),
                                suffix: obj[0].suffix
                            });
                            this.props.query.select.modifySelectAggregator(this.props.rowIndex, `${value}_${obj[0].default}`, obj[0].suffix);
                        } else {
                            this.setState({
                                agg_type: value || null,
                                suffix: ''
                            });
                            this.props.query.select.modifySelectAggregator(this.props.rowIndex, `${value || ''}_${this.state.row_col_value.toLowerCase()}`, '');
                        }
                        this.props.updateQueryState();
                    }} 
                    allowClear>
                    {this.state.option_list.map(option => (
                        <Option  id={option.text} key={option.key} value={option.value} >{option.text}</Option>
                    ))}
                </Select>
                <Radio.Group 
                    value={this.state.row_col_value} 
                    className="select_row_col"
                    style={{
                        margin: '0 5px',
                        display: 'inline-block'
                    }}
                    onChange={(e)=>{
                        const obj = this.state.options.filter((option)=>{
                            return option.value === this.state.agg_type;
                        });
                        const type = e.target.value.toLowerCase();
                        this.setState({
                            row_col_value: e.target.value,
                            option_list: this.state.options.filter(e => e.type[type]),
                        });

                        if(this.state.agg_type){
                            this.props.query.select.modifySelectAggregator(this.props.rowIndex, `${this.state.agg_type}_${type}`, obj[0].suffix || '');
                        } else {
                            this.props.query.select.modifySelectAggregator(this.props.rowIndex, `${type}`, '');
                        }
                        
                        this.props.updateQueryState();
                    }}>
                    <Radio.Button value="ROW">
                        ROW
                    </Radio.Button>
                    <Radio.Button value="COL">
                        COL
                    </Radio.Button>
                </Radio.Group>
            </>
        );
    }
}
