import React, { useMemo, useState, useEffect } from 'react';
import { Modal } from '../../../components/modals';
import { RootState } from '../../../store/types';
import { useSelector, useDispatch } from 'react-redux';
import { toggleModal, setActiveComponentInfo } from '../../../store/workflow';
import Form from '../../../components/form';
import { BaseFieldType } from '../../../components/formcreators/types';
import { FieldsCreator } from '../../../components/formcreators/field-creator';
import { initialValuesCreator, getFieldDefaultValue } from '../../../components/formcreators/schema-creator';
import { ShowWhenTrue } from '../../../helpers';
import _, { has, merge } from 'lodash';
import { DsNodeModel } from '../../../components/workflow-canvas/node/ds_nodemodel';
import isEmpty from 'lodash/isEmpty';
import { WorkflowCanvas } from '../../../components/workflow-canvas';
import classNames from 'classnames';
import { getHiddenFieldNames, updatePortCountOfComponent, runNumberOfPortsExpression, PortsCount, getFieldTypeUsingPropsType, getHiddenFieldNameKey, getNumPortsBaseField } from '../utils';
import { useGetActiveTabInfo } from '../../../utils';
import { CheckboxField } from '../../../components/form/checkbox-field';
import { removeVariableInfoFromComponent } from '@components/formcreators/WorkflowConfigVariablePortFields/utils';
import { MultiplePortDetails } from 'types/component';

type PortsState = {
    min: number;
    max: number;
    current: number;
} 

type portTypes = 'Input' | 'Output'
const portTypes = ['Input', 'Output'];

const variableInputPortOptions = [{label: 'Variable Input Port', value: 'true' }];

export const ManagePortsModal: React.FC = () => {
    const showModal = useSelector((store: RootState) => store.WorkflowReducer.showModal.managePorts);
    const dispatch = useDispatch();
    const [fields, setFields] = useState<BaseFieldType[]>([]);
    const [currentInputPortsCount, setCurrentInputPorts] = useState<PortsState>({ min: 0, max: 7, current: 0 });
    const [currentOutputPortsCount, setCurrentOutputPorts] = useState<PortsState>({ min: 0, max: 7, current: 0 });
    const [isPortsEditable, togglePortsEditable] = useState({ Input: false, Output: false });
    const [showWarningMessage, toggleWarningMessage] = useState(false);
    const showForm = !isEmpty(fields);
    const activeComponentInfo = useSelector((store: RootState) => store.WorkflowReducer.activeComponentInfo);
    const isActiveComponentPropsOpen =  useSelector((store: RootState) => store.WorkflowReducer.activeComponentPropertiesInfo.show);
    const activeWorkflowInfo = useGetActiveTabInfo('workflowEditor');

    const toggleClose = () => dispatch(toggleModal('managePorts', false));

    const getCurrentNodeInPortsCount = () => {
        if(activeComponentInfo){
            const currentNode = WorkflowCanvas.getNode(activeComponentInfo.id) as DsNodeModel;
            let inPortsCount = currentNode.getInPorts().length;
            if(currentNode.hasVariableInputPort) {
                inPortsCount -= 1;
            }
            return inPortsCount;
        }
        return 0;
        
    }; 

    const getCurrentNodeOutPortsCount = () => {
        if(activeComponentInfo){
            const currentNode = WorkflowCanvas.getNode(activeComponentInfo.id) as DsNodeModel;
            return currentNode.getOutPorts().length;
        }
        return 0;
    }; 

    const setInitialValuesForPorts = (portdetails: MultiplePortDetails, currentPortsCount: number, setPortsCount: React.Dispatch<React.SetStateAction<PortsState>>, setFieldsInfo: boolean) => {
        if(portdetails.fields && setFieldsInfo){
            setFields(portdetails.fields);
        } else {
            const __currentCount: PortsState = { min: 0, max: 12, current: 0 }; 
            __currentCount.current = currentPortsCount;
            if (portdetails.min) __currentCount.min = portdetails.min;
            if (portdetails.max) __currentCount.max = portdetails.max;
            setPortsCount(__currentCount);
        }
    };

    const addPortsCountToFormDetails = (activeNode: DsNodeModel, portsCount: { inputPorts: number; outputPorts: number }) => {
        if(Array.isArray(activeNode.extras.formData)) {
            let isInputPortFieldAdded = false;
            let isOutputPortFieldAdded = false;
            activeNode.extras.formData = activeNode.extras.formData.map(field => {
                if(field.key === 'num_ip_ports') {
                    field.value = portsCount.inputPorts;
                    isInputPortFieldAdded = true;
                } else if(field.key === 'num_op_ports'){
                    field.value = portsCount.outputPorts;
                    isOutputPortFieldAdded = true;
                }
                return field;
            });
    
            if(!isInputPortFieldAdded) activeNode.extras.formData.push(
                getNumPortsBaseField('input', portsCount.inputPorts));
    
            if(!isOutputPortFieldAdded) activeNode.extras.formData.push(
                getNumPortsBaseField('output', portsCount.outputPorts));
        }
    };

    useEffect(() => {
        if(showModal && activeComponentInfo?.portManagementDetails){
            const { inputPorts, outputPorts, fields } = activeComponentInfo.portManagementDetails;
            if(fields || (has(activeComponentInfo.portManagementDetails, 'inputPorts.defaultValue') && has(activeComponentInfo.portManagementDetails, 'outputPorts.defaultValue'))) {
                // NEW STRUCTURE
                fields && setFields(fields);
                setInitialValuesForPorts(inputPorts, getCurrentNodeInPortsCount(), setCurrentInputPorts, false);
                setInitialValuesForPorts(outputPorts, getCurrentNodeOutPortsCount(), setCurrentOutputPorts, false);

                if(has(inputPorts, 'min') || has(inputPorts, 'max')) togglePortsEditable(_isPortsEditable => ({ ..._isPortsEditable, Input: true }));
                if(has(outputPorts, 'min') || has(outputPorts, 'max')) togglePortsEditable(_isPortsEditable => ({ ..._isPortsEditable, Output: true }));
            } else {
                // OLD STRUCTURE
                setInitialValuesForPorts(inputPorts, getCurrentNodeInPortsCount(), setCurrentInputPorts, true);
                setInitialValuesForPorts(outputPorts, getCurrentNodeOutPortsCount(), setCurrentOutputPorts, true);
                if(!isEmpty(inputPorts)) togglePortsEditable(_isPortsEditable => ({ ..._isPortsEditable, Input: true }));
                if(!isEmpty(outputPorts)) togglePortsEditable(_isPortsEditable => ({ ..._isPortsEditable, Output: true }));
            }
        } else {
            // reset fields on close
            setFields([]);
            togglePortsEditable({ Input: false, Output: false });
        }

        if(activeComponentInfo?.portManagementDetails) {
            const activeNode = WorkflowCanvas.getNode(activeComponentInfo.id);
            if(activeNode) {
                addPortsCountToFormDetails(activeNode, {
                    inputPorts: activeNode.portsIn.length - (activeNode.hasVariableInputPort && !!activeNode.portsIn.length ? 1: 0),
                    outputPorts:  activeNode.portsOut.length
                });
            }
        }
    }, [showModal, activeComponentInfo]);

    const initialValues = useMemo(() => {
        const _obj: any = {};
        if(activeComponentInfo && showModal){
            const currentNode = WorkflowCanvas.getNode(activeComponentInfo.id);
            if(currentNode) {
                _obj['enableVariableInputPort'] = currentNode.hasVariableInputPort ? 'true':'';
            }
            return merge(initialValuesCreator({ formData: fields }), _obj);
        }
        return {}
    }, [fields, showModal]);

    const handleManagePortModalSubmit = (portsCount: PortsCount, values: any) => {
        if(activeComponentInfo) {
            updatePortCountOfComponent(activeComponentInfo.id, portsCount);
            const activeNode = WorkflowCanvas.getNode(activeComponentInfo.id) as DsNodeModel;
            addPortsCountToFormDetails(activeNode, portsCount);
            if (!isEmpty(values) && activeComponentInfo.portManagementDetails) {
                // Only condition based manage ports configuration has form values
                const hiddenFieldNames = getHiddenFieldNames();
                const { inputPorts, outputPorts, fields } = activeComponentInfo.portManagementDetails;

                let newStructure = false;
                let formFields = null;
                if(fields) {
                    // NEW STRUCTURE
                    formFields = fields;
                    newStructure = true;
                } else {
                    // OLD STRUCTURE
                    if(inputPorts.fields) {
                        formFields = inputPorts.fields;
                    } else if (outputPorts.fields) {
                        formFields = outputPorts.fields;
                    }
                }

                if(formFields) {
                    // add values to portManagementForm
                    const updatedPortManagementFormDetails = formFields.map(field => {
                        const fieldName = field['key'];
                        field['value'] = values[fieldName];
                        const hiddenFieldKey = getHiddenFieldNameKey(fieldName, getFieldTypeUsingPropsType(field.type));
                        field['isFieldHidden'] = !!(hiddenFieldNames[hiddenFieldKey]) && !getFieldDefaultValue(field);
                        return field;
                    });
                    if (activeNode.extras.portManagement) {
                        if(newStructure && fields) {
                            activeNode.extras.portManagement.fields = updatedPortManagementFormDetails;
                        } else {
                            // OLD STRUCTURE
                            if (inputPorts.fields) activeNode.extras.portManagement.inputPorts.fields = updatedPortManagementFormDetails;
                            else activeNode.extras.portManagement.outputPorts.fields = updatedPortManagementFormDetails;
                        }
                    }
                }

                // add values to actualFormDetails
                const updatedActualFormDetails = activeComponentInfo.formDetails.map(field => {
                    const fieldName = field['key'];
                    if (_.has(values, fieldName)) {
                        // only update the fields that are in the portmanagementform
                        field['value'] = values[fieldName];
                        const hiddenFieldKey = getHiddenFieldNameKey(fieldName, getFieldTypeUsingPropsType(field.type));
                        field['isFieldHidden'] = !!(hiddenFieldNames[hiddenFieldKey]) && !getFieldDefaultValue(field);
                    }
                    return field;
                });

                activeNode.extras.formData = updatedActualFormDetails;
            }

            if(portsCount.removeVariablePort) {
                removeVariableInfoFromComponent(activeNode)
            }

            // this is to update initialValues with the latest values so that changes not saved changes to changes saved
            if (isActiveComponentPropsOpen) {
                // Update the values in form if form is open 
                dispatch(setActiveComponentInfo({ ...activeComponentInfo, formDetails: activeNode.extras.formData, extraData: activeNode.extras.extraData }));
            }
            WorkflowCanvas.repaintCanvas();
            dispatch(toggleModal('managePorts', false));
        }
    };

    const getRunExpressionIfExists = (portsInfo: MultiplePortDetails) => {
        if(portsInfo.numberOfPortsExpression)  {
            return portsInfo.numberOfPortsExpression;
        } else if(portsInfo.portsExpression) return portsInfo.portsExpression;
        return null;
    };

    const handleFormSubmit = (values: any) => {
        if(activeComponentInfo){
            const nodeInfo = WorkflowCanvas.getNode(activeComponentInfo.id); 
            if(nodeInfo) {
                const portsCount: PortsCount = { 
                    inputPorts: getCurrentNodeInPortsCount(), 
                    outputPorts: getCurrentNodeOutPortsCount(), 
                    addVariablePort: false,
                    removeVariablePort: false
                };

                if(values.enableVariableInputPort === 'true' && !nodeInfo.hasVariableInputPort) {
                    portsCount.addVariablePort = true;
                } else if (values.enableVariableInputPort !== 'true' && nodeInfo.hasVariableInputPort) {
                    portsCount.removeVariablePort = true;
                }

                if(activeComponentInfo.portManagementDetails) {
                    const { inputPorts, outputPorts } = activeComponentInfo.portManagementDetails;
                    // REMOVE AFTER ALL THE COMPONENTS ARE MIGRATED TO NEW FORMAT
                    if(showForm) {
                        const inputPortsExpression = getRunExpressionIfExists(inputPorts);
                        if(inputPortsExpression) {
                            portsCount.inputPorts = runNumberOfPortsExpression(values, inputPortsExpression);
                        } 
                        const outputPortsExpression = getRunExpressionIfExists(outputPorts);  
                        if(outputPortsExpression) {
                            portsCount.outputPorts = runNumberOfPortsExpression(values, outputPortsExpression);
                        }
                    } 
                    else { 
                        portsCount.inputPorts = currentInputPortsCount.current;
                        portsCount.outputPorts = currentOutputPortsCount.current;
                    }
                }
                
                handleManagePortModalSubmit(portsCount, values);
            }
        }
    };

    const handleIncreaseCount = (portType: portTypes) => {
        if(portType === 'Input'){
            if(currentInputPortsCount.current !== currentInputPortsCount.max){
                setCurrentInputPorts({ ...currentInputPortsCount, current: currentInputPortsCount.current + 1 });
            }
        } else if(currentOutputPortsCount.current !== currentOutputPortsCount.max){
            setCurrentOutputPorts({ ...currentOutputPortsCount, current: currentOutputPortsCount.current + 1 });
        } 
    };

    const handleDecreaseCount = (portType: portTypes) => {
        !showWarningMessage && toggleWarningMessage(true);
        if(portType === 'Input'){
            if(currentInputPortsCount.current !== currentInputPortsCount.min){
                setCurrentInputPorts({ ...currentInputPortsCount, current: currentInputPortsCount.current - 1 });
            }
        } else if(currentOutputPortsCount.current !== currentOutputPortsCount.min){
            setCurrentOutputPorts({ ...currentOutputPortsCount, current: currentOutputPortsCount.current - 1 });
        }
    };

    const portInfo = {'Input': currentInputPortsCount, 'Output': currentOutputPortsCount};
    return(
        <Modal
            title="Manage Ports Configuration"
            subtitle={activeComponentInfo?.name || ''}
            isOpen={showModal}
            toggleClose={toggleClose}
            className="managePorts__modal"
            showCloseMark
        >
            <Form
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
                enableReinitialize
            >
                {({ _formikprops}) => (
                    <>
                        <div className="properties__fields__container">
                            {showForm ?
                                <FieldsCreator 
                                    formData={fields} 
                                />
                                :
                                <div className="portTickers__main">
                                    {portTypes.map((portType) => {
                                        const portCount = portInfo[portType as portTypes];
                                        const canChangePorts = isPortsEditable[portType as portTypes];
                                        return(
                                            <div className="portTicker__box" key={portType}>
                                                <span className="portType">{portType} Ports</span>
                                                <div className="portTicker__Btnbox">
                                                    <button 
                                                        onClick={() => handleDecreaseCount(portType as portTypes)} 
                                                        disabled={(portCount.current === portCount.min) || !canChangePorts}
                                                        type="button"
                                                        className="decrement__btn"
                                                    >
                                                        <span>&#8722;</span>
                                                    </button>
                                                    <span className={classNames('countText', {'disabled': !canChangePorts})}>
                                                        {portCount.current}
                                                    </span>
                                                    <button 
                                                        onClick={() => handleIncreaseCount(portType as portTypes)} 
                                                        disabled={(portCount.current === portCount.max) || !canChangePorts}
                                                        type="button"
                                                        className="increment__btn"
                                                    >
                                                        <span>&#43;</span>
                                                    </button>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            }
                            <ShowWhenTrue show={!!activeWorkflowInfo?.hasVariables}>
                                <div
                                    className="varInputPort__box"
                                >
                                    <CheckboxField 
                                        name="enableVariableInputPort"
                                        options={variableInputPortOptions}
                                        color="gold"
                                        className=""
                                    />
                                </div>
                            </ShowWhenTrue>
                            
                            

                            <ShowWhenTrue show={(!_.isEqual(initialValues, _formikprops.values) ||showWarningMessage)}>
                                <span className="warning__msg">
                                    Note: Existing links between components shall be 
                                    deleted when ports are decremented.
                                </span>
                            </ShowWhenTrue>
                        </div>
                        
                        <div className="modalBtns__box">
                            <button 
                                className="btn-md btn-yellow"
                                type="submit"
                            >
                                Submit
                            </button>
                            <button 
                                className="btn-md btn-cancel"
                                type="button"
                                onClick={toggleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </>
                )}
            </Form>
        </Modal>
    );
};