/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { Grid } from 'semantic-ui-react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import indicator from './classes/indicator';
import GroupByRow from './groupbyRow';

const GroupByClause = props => {
    const { query, updateQueryState, db } = props;

    const handleDraggableDrop = result => {
        document.body.classList.remove('editorTab');
        document.body.classList.remove('queryBuilder');
        if (result.destination) {
            const startIndex =
        result.destination.index < result.source.index
            ? result.destination.index
            : result.source.index;

            const endIndex =
        result.destination.index < result.source.index
            ? result.source.index
            : result.destination.index;

            query.select.handleDraggableDrop(
                result.source.index,
                result.destination.index,
                startIndex,
                endIndex
            );

            updateQueryState();
            setTimeout(()=>{
                indicator.groupbyIndicator();
            });
        }
    };

    const handleDraggableStart = ()=>{
        document.body.classList.add('editorTab');
        document.body.classList.add('queryBuilder');
    };

    return (
        <DragDropContext onBeforeDragStart={handleDraggableStart} onDragEnd={handleDraggableDrop}>
            <Droppable droppableId="droppableGroupby">
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        <div id="groupbyHolder" style={{ width: '98%', padding: '5px 10px', margin: '10px', marginTop: 0 }}>
                            {query.groupby.groupbyRows.map((row, i) => {
                                return (
                                    <GroupByRow
                                        key={`groupByRow-${i}`}
                                        query={query}
                                        updateQueryState={updateQueryState}
                                        rowIndex={i}
                                        row={row}
                                        db={db}
                                    />
                                );
                            })}
                        </div>
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default GroupByClause;
