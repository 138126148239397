import styled from "styled-components";

export const FileUploadUploaderWrapper = styled.div`
	width: 100%;
	height: 100%;
	border: 1px dashed #666a8d;
	border-radius: 8px;
	cursor: pointer;

	.file-uploader-placeholder > svg {
		width: 72px;
		height: 72px;
	}

  .file-list-renderer-list-icon {
    position: relative;
  }

	.file-list-renderer-list-icon > svg {
		width: 48px;
		height: 48px;
	}

  #btn_deleteFile {
    height: 24px;
  }

	.ant-progress-text {
		color: white;
	}
`;
