import { IngestionTime } from '../../helpers/utils';

export const TOGGLE_USER = 'TOGGLE_USER';
export const TOGGLE_INGESTION_TIME = 'TOGGLE_INGESTION_TIME';
export const RESET_FILTERS = 'RESET_FILTERS';
export const CHANGE_PATTERN_NAME = 'CHANGE_PATTERN_NAME';
export const CHANGE_DATE_RANGE = 'CHANGE_DATE_RANGE';

export type ActionTypes = {
    type: typeof TOGGLE_USER | typeof TOGGLE_INGESTION_TIME | typeof RESET_FILTERS | typeof CHANGE_PATTERN_NAME | typeof CHANGE_DATE_RANGE;
    payload: boolean | IngestionTime | string | any[];
}

export type BreadCrumbReducerState = {
    user_view: boolean;
    ingestion_time: IngestionTime;
    resetCount: number;
    patternname: string;
    dateRange: any[];
}