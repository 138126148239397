import React, { useEffect, useRef } from 'react';
import Tooltip, { TooltipProps } from 'antd/lib/tooltip';
import classNames from 'classnames';

type _TooltipProps = TooltipProps & { hideCaret?: boolean; modifiedOverlayClassName?: string }

const Tooltip_: React.FC<_TooltipProps> = ({ children, modifiedOverlayClassName, hideCaret = true, ...props }) => {
    const toolTipRef = useRef<Tooltip>(null);

    const handleScroll = () => {
        if(toolTipRef.current?.state.visible) {
            toolTipRef.current.setState({ visible: false });
        }
    };


    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);
        return () => {
            window.removeEventListener('scroll', handleScroll, true);
        };
    }, []);

    return(
        <Tooltip
            overlayClassName={classNames('customTooltip', {hideCaret: hideCaret}, modifiedOverlayClassName)}
            {...props}
            ref={toolTipRef}
        >
            {children}
        </Tooltip>
    );
};


export const TooltipTop: React.FC<_TooltipProps> = (props) => (
    <Tooltip_
        placement="top"
        align={{ offset: [0, 5]}}
        {...props}
    />
);

export const TooltipBottom: React.FC<_TooltipProps> = (props) => (
    <Tooltip_
        placement="bottom"
        align={{ offset: [0, -5]}}
        {...props}
    />
);

export const TooltipRight: React.FC<_TooltipProps> = (props) => (
    <Tooltip_
        placement="right"
        // align={{ offset: [0, 5]}}
        {...props}
    />
);
