import React from "react";
import { InPageSpinner } from "../../../components/spinners/in-page-spinner";
import clsx from "classnames";
import { ConnectionTypes } from "../enums";
import { isEmpty } from "lodash";

export interface Item {
	hasNodes: boolean;
	isOpen: boolean;
	level: number;
	key: string;
	label: string;
	[x: string]: any;
}

export interface TreeMenuItem extends Item {
	active?: boolean;
	onClick: (event: React.MouseEvent<HTMLLIElement>) => void;
	toggleNode?: () => void;
}

const openedIcon = (
	<img
		draggable={false}
		className="arrow  arrow--active"
		src="/icons/treeview/Right.svg"
		alt=""
	/>
);
const closedIcon = (
	<img
		draggable={false}
		className="arrow"
		src="/icons/treeview/Right.svg"
		alt=""
	/>
);

const TableIcon = (
	<svg
		width="24px"
		height="24px"
		viewBox="0 0 24 24"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<defs>
			<path
				d="M18,3 C19.6568542,3 21,4.34314575 21,6 L21,18 C21,19.6568542 19.6568542,21 18,21 L6,21 C4.34314575,21 3,19.6568542 3,18 L3,6 C3,4.34314575 4.34314575,3 6,3 L18,3 Z M10.7142857,14.3684211 L5.57142857,14.3684211 L5.57142857,18.1578947 L10.7142857,18.1578947 L10.7142857,14.3684211 Z M18.4285714,14.3684211 L13.2857143,14.3684211 L13.2857143,18.1578947 L18.4285714,18.1578947 L18.4285714,14.3684211 Z M10.7142857,7.73684211 L5.57142857,7.73684211 L5.57142857,11.5263158 L10.7142857,11.5263158 L10.7142857,7.73684211 Z M18.4285714,7.73684211 L13.2857143,7.73684211 L13.2857143,11.5263158 L18.4285714,11.5263158 L18.4285714,7.73684211 Z"
				id="path-1-TID"
			/>
		</defs>
		<g
			id="Icons/Table/General"
			stroke="none"
			strokeWidth={1}
			fill="none"
			fillRule="evenodd"
		>
			<mask id="mask-2-TID" fill="white">
				<use xlinkHref="#path-1-TID" />
			</mask>
			<use id="Combined-Shape" fill="#7CC4FF" xlinkHref="#path-1-TID" />
			<g id="↳-🎨Color/Info/300" mask="url(#mask-2-TID)" fill="#5CD1E7">
				<rect id="Color-Container" x={0} y={0} width={24} height={24} />
			</g>
		</g>
	</svg>
);

const ToggleIcon = ({
	on,
	openedIcon,
	closedIcon,
}: {
	on: boolean;
	openedIcon: React.ReactNode;
	closedIcon: React.ReactNode;
}) => (
	<div role="img" aria-label="Toggle" className="rstm-toggle-icon-symbol">
		{on ? openedIcon : closedIcon}
	</div>
);

const DEFAULT_PADDING = 10;
const ICON_SIZE = 8;
const LEVEL_SPACE = 20;

export const ItemComponent: React.FunctionComponent<TreeMenuItem> = ({
	hasNodes = false /* eslint-disable-line */,
	isOpen = false,
	level = 0,
	onClick,
	toggleNode,
	active,
	focused,
	label = "unknown",
	style = {},
	children,
	connectionType,
	...props
}) => {
	let _label = label;
	if(_label === "My Sql"){
		_label = "My SQL"
	}
	const showTableIcon = level === 4 && connectionType !== "WITSML";
	const showOnlineOfflineStatus =
		level === 3 && connectionType === ConnectionTypes["NiFi Managed"];
	const nifiManagedStatus= connectionType == "Edge_Managed_Oracle" || connectionType == "Edge_Managed_FTP";

	const _toggleNode = () => {
		!showTableIcon && toggleNode && toggleNode();
	};

	const _onClick = (e: any) => {
		if (label !== "loading" && label !== "error" && isEmpty(e.target.id)) {
			onClick(e);
			_toggleNode();
		}
	};
	//  level - 0: Connection Types, level 1 - Database types, level 2 -  Databases/Schemas, level 3 - Tables
	
	return (
		<li
			className={clsx("rstm-tree-item", {
				"rstm-tree-item--active": active,
				"rstm-tree-item--focused": focused,
			})}
			style={{
				paddingLeft: DEFAULT_PADDING + ICON_SIZE + level * LEVEL_SPACE,
				paddingRight: 25,
				...style,
			}}
			role="button"
			aria-pressed={active}
			onClick={_onClick}
		>
			{label === "loading" ? (
				<InPageSpinner size="XSmall" />
			) : showTableIcon || showOnlineOfflineStatus || nifiManagedStatus ? (
				<>
					{!!showTableIcon && TableIcon}
						{(showOnlineOfflineStatus || nifiManagedStatus) && (
							<div className={clsx("nodeStatus", {
								"nodeStatus__online": props.managedNodeInfo?.online ?? props.node?.online,
								"nodeStatus__offline": !(props.managedNodeInfo?.online ?? props.node?.online)
							})}></div>							
						)}
				</>
			) : (
				<div className="rstm-toggle-icon" onClick={_toggleNode}>
					<ToggleIcon
						on={isOpen}
						openedIcon={openedIcon}
						closedIcon={closedIcon}
					/>
				</div>
			)}
			{children}
			<span className="treeviewItem__labels" title={_label}>{_label}</span>
		</li>
	);
};
