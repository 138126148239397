import { HttpClient } from '../services';
import { SearchData, AnalyticFlowResponse, AnalyticJobBriefInfo, KillScheduleJobInfo } from './types';
import { makeParamURL } from './utiles';
import { API } from '../../constants/settings';
import { ApiResponse } from '../data-source-handler';

class AnalyticHandlerClass {
    PagenationSearchSort(data: SearchData, callback: (arg0: AnalyticFlowResponse)  => void, errorHandlerCallback? : (arg0: any) => void) {
        const paramURL: string = makeParamURL(data);
        HttpClient.Get('Pagenation Search Sort', `${API.url}/v1/analytic_flow/pagination_search_sort/` + paramURL+'&sortby=time&orderby=desc', callback, errorHandlerCallback);
    }

    Get(id: number, callback: (arg0: any) => void, errorHandlerCallback?: (arg0: any) => void ) {
        HttpClient.Get('Get By ID', `${API.url}/v1/analytic_flow/` + id, callback, errorHandlerCallback);
    }

    Delete(id: number, callback: (arg0: any) => void, errorHandlerCallback?: (arg0: any) => void) {
        HttpClient.Delete('Delete', `${API.url}/v1/analytic_flow/` + id, callback, errorHandlerCallback);
    }

    GetAllJobs(callback: ApiResponse<{data: AnalyticJobBriefInfo[]}>) {
        HttpClient.Get('Get By ID', `${API.url}/v2/analytic_flow/`, callback);
    }

    CreateKillJob(data: KillScheduleJobInfo, callback: ApiResponse<any>) {
        HttpClient.Post('Create a kill job', `${API.url}/v2/scheduled-kill-job/`, data, callback);
    }


    GetKillScheduledJobs(data: SearchData, callback: any) {
        // data = { page: 1, size: 10 };
        const paramURL: string = makeParamURL(data);
        HttpClient.Get('Get Kill Scheduled jobs', `${API.url}/v2/scheduled-kill-job/` + paramURL+'&sortby=time&orderby=desc', callback);
    }

}

const AnalyticHandler = new AnalyticHandlerClass();

export { AnalyticHandler };