import { ExecutionEnvModes } from '../../constants/enums';
import { PageTypes } from '../canvas';
import { LoadInitialState, RESET_STATE } from '../types';

export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';
export const TOGGLE_LANDING_SCREEN = 'TOGGLE_LANDING_SCREEN';
export enum SpinnerTypes {
    initialPageLoadSpinner = 'initialPageLoadSpinner',
    inPageSpinner = 'inPageSpinner'
}
export const GO_TO_ANALYTICS_PAGE = 'GO_TO_ANALYTICS_PAGE';
export const TOGGLE_NETWORK_ERROR = 'TOGGLE_NETWORK_ERROR';
export const SET_EXECUTION_ENV = 'SET_EXECUTION_ENV';
export const SET_LAST_ACTIVE_WORKFLOW_INFO = 'SET_LAST_ACTIVE_WORKFLOW_INFO';
export const SET_COMPONENTS_SEARCH_STATE = 'SET_COMPONENTS_SEARCH_STATE';

export type SetLastActiveWorkflowInfoType= { page: PageTypes; env: ExecutionEnvModes; workflowId: number | null };
export type SetComponentsSearchState = { env: ExecutionEnvModes; openNodesState: string[] };

interface CommomAction {
    type: typeof SHOW_SPINNER | typeof HIDE_SPINNER | typeof TOGGLE_LANDING_SCREEN  | typeof GO_TO_ANALYTICS_PAGE
     | typeof TOGGLE_NETWORK_ERROR | typeof SET_EXECUTION_ENV | typeof SET_LAST_ACTIVE_WORKFLOW_INFO | typeof RESET_STATE | typeof SET_COMPONENTS_SEARCH_STATE;
    payload: SetLastActiveWorkflowInfoType | SetComponentsSearchState | any;
}

export type CommonActionTypes = CommomAction | LoadInitialState;

export interface CommonReducerState {
    initialPageLoadSpinner: boolean;
    inPageSpinner: boolean;
    showLandingPage: boolean;
    goToAnalyticsPage: boolean;
    showNetworkErrorPage: boolean;
    activeExecutionEnv: ExecutionEnvModes; 
    lastActiveWorkflowInfo: Record<PageTypes, Record<ExecutionEnvModes, number | null>>;
    componentsSearchState: Record<ExecutionEnvModes, string[]>;
} 