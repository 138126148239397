import React, { useEffect, useState } from 'react';
import { Modal } from '../../components/modals';
import { ShowWhenTrue } from '../../helpers';
import { Form } from '../../components/form/form';
import { baseFormSchemaForClusterForm, ENVVARIABLES_SEPARATOR, schemaTypes, SPARKCONFIG_SEPARATOR, SPOT_FALL_BACK_TO_OD, TERMINATE } from './forms/form-schema';
import classNames from 'classnames';
import { InPageSpinner } from '../../components/spinners/in-page-spinner';
import { Tabs } from 'antd';
import { ClusterForm } from './forms/ClusterForm';
import { useDispatch, useSelector } from 'react-redux';
import { addCluster, getClusterMode, getAccessNode, getUsersList, getDataBricksVersionList, getNodeTypeList, updateCluster } from '../../store/cluster';
import { RootState } from '../../store/types';
import { ClusterDetail, ClusterState } from '../../api/databricks-handler';
import { getClusterFormValues, getKeyValuePairObject } from './forms/helper';
import { dbfsPrefix } from '../../components/form/dynamic-field-generator-cluster';
import { TooltipTop } from '../../components/tooltips';
import { LibrariesTab } from './libraries-tab';
import { AccountReducerState } from '../../store/account';
import { WorkspaceData } from './types';

const { TabPane } = Tabs;

const updateButtonLabel = 'Confirm Changes';
const addButtonLabel = 'Create Cluster';

export interface ClusterModalProps {
    showModal: boolean;
    toggleModal: () => void;
    currentClusterDetail?: ClusterDetail & {state?: ClusterState } ;
    currentUser: AccountReducerState['activeUserInfo'];
    isPipeline?: boolean
    callback?: any;
    enabledWorkspace:WorkspaceData
}


export const ClusterModal: React.FC<ClusterModalProps> = ({showModal, toggleModal, currentClusterDetail, currentUser, callback, isPipeline=false, enabledWorkspace }) => {
    const viewType = 'ui';
    const showSpinner = false;

    const dispatch = useDispatch();
    const { nodeTypeList, clusterMode, dataSecurityMode, singleUserName, dataBricksVersionList } = useSelector((store: RootState) => store.ClusterReducer.metadata);

    const initialValues: schemaTypes = {
        name: '',
        enableAutoScaling: '',
        terminate: TERMINATE,
        minutesOfInactivity: 120,
        workerType: '',
        minWorkers: 1,
        maxWorkers: 2,
        driverType: '',
        spotInstances: '',
        noOfWorkers: 1,
        sparkConfig: '',
        sparkEnvVars: '',
        initScripts: [],
        npipWorkSpace: false,
        clusterModeId: '2',
        dataSecurityMode: 'CUSTOM',
        singleUserName: singleUserName?.[0]?.value ?? '',
        sparkRunTimeVersion: '',
        showDocker: 'true',
        modelType: 'cluster',
        dockerContainer: 'latest',
        firstOnDemand: 1,
        spotFallbackToOD:SPOT_FALL_BACK_TO_OD
    };
    

    const [formValues, setFormValues] = useState(initialValues);
    const [loading, setLoading] = useState(false);
    const [firstOnDemand, setFirstOnDemand] = useState(currentClusterDetail?.awsAttributes?.first_on_demand||1);

    const toggleClose = () => {
        toggleModal();
        setFormValues(initialValues);
    };

    useEffect(() => {
        if(!Array.isArray(nodeTypeList) || nodeTypeList.length === 0) {
            dispatch(getNodeTypeList());
            dispatch(getClusterMode());
            dispatch(getAccessNode());
            dispatch(getUsersList());
            dispatch(getDataBricksVersionList());
        }
    }, []);

    useEffect(()=>{
        dispatch(getUsersList());
    },[enabledWorkspace.patToken])

    useEffect(() => {
        if (currentClusterDetail) {
            const _currentClusterDetail =  typeof currentClusterDetail === 'string'? JSON.parse(currentClusterDetail) : currentClusterDetail;
            const temp = getClusterFormValues(_currentClusterDetail);
            if ((temp.singleUserName === null || temp.singleUserName === undefined) && singleUserName.length > 0) {
                temp.singleUserName = singleUserName[0].value;
            }
            temp.singleUserName = singleUserName[0]?.value;
            temp.modelType = isPipeline ? 'pipeline' : 'cluster';
            setFormValues(temp);
        }
    }, [currentClusterDetail, showModal]);

    const handleNewClusterSubmit = (values: schemaTypes) => {
        setLoading(true)
        const clusterData = {
            autoterminationMinutes: values.terminate ? values.minutesOfInactivity : 0,
            clusterName: values.name,
            nodeTypeId: values.workerType,
            driverNodeTypeId: values.driverType,
            isSpotInstances: values.spotInstances ? true : false,
            sparkEnvVars: {},
            sparkConf: {},
            initScripts: [],
            npipWorkSpace: enabledWorkspace?.npipWorkSpace || false,
            clusterModeId: values.clusterModeId,
            dataSecurityMode: values.dataSecurityMode,
            singleUserName: values.singleUserName,
            sparkRunTimeVersion: values.sparkRunTimeVersion
        };

        const checkInitScripts = () => { 
            if (values.initScripts.length > 0) {
                const initscriptArray: Record<string, {destination: string}>[] = [];
                values.initScripts.forEach((values: any) => {
                    const initScript: Record<string, {destination: string}> = {
                        [values[0]]: {
                            destination: `${dbfsPrefix}${values[1]}`
                        }
                    };
                    initscriptArray.push(initScript);
                });
                return initscriptArray;
            }
            return [];
        };

        const checkautoScaling = () => {
            if (values.enableAutoScaling) {
                return {
                    autoscale: {
                        min_workers: values.minWorkers,
                        max_workers: values.maxWorkers
                    },
                    numWorkers: 0
                };
            } else {
                return {
                    autoscale: null,
                    numWorkers: values.noOfWorkers,
                };
            }        
        };

        const checkAwsAttributes= () => {
            if(enabledWorkspace?.cloudProvider === 'Aws'){
                return {
                    awsAttributes: {
                        first_on_demand: firstOnDemand,
                        availability: values.spotFallbackToOD?'SPOT_WITH_FALLBACK':"SPOT",
                    }
                }
            }else{
                return{
                    awsAttributes: null
                }
            }
        };

        const enableAutoScaling = checkautoScaling();
        const awsAttributes = checkAwsAttributes();

        const sparkConfObj = values.sparkConfig ?
            getKeyValuePairObject(values.sparkConfig, SPARKCONFIG_SEPARATOR) : {};

        const sparkEnvVarsObj = values.sparkEnvVars ? 
            getKeyValuePairObject(values.sparkEnvVars, ENVVARIABLES_SEPARATOR) : {};

        const initscriptArray = checkInitScripts();    

        const clusterDetailPayload = {  
            ...clusterData, 
            ...enableAutoScaling,
            ...awsAttributes,
            sparkConf: sparkConfObj,
            sparkEnvVars: sparkEnvVarsObj,
            initScripts: initscriptArray,
            npipWorkSpace: enabledWorkspace?.npipWorkSpace || false,
            useDockerImage: enabledWorkspace?.useDockerImage || false,
            dockerContainer: values.dockerContainer,
            workspaceId : enabledWorkspace?.workspaceId || "",
            cloudProvider:enabledWorkspace?.cloudProvider,
            workspaceType: "databricks",
        };
        const onSuccessHandler = () => {
            setLoading(false);
            toggleClose();    
        };

        const onFailureHandler = () => {
            setLoading(false)
            //TODO: Show Error while creating cluster
        };

        if(isPipeline) {
            setLoading(false);
            callback && callback(clusterDetailPayload, clusterMode);
        } else {
            if (currentClusterDetail) {
                const updateClusterDetailPayload = {  
                    clusterId: currentClusterDetail.clusterId,
                    ...clusterDetailPayload
                }; 
                dispatch(updateCluster(updateClusterDetailPayload, onSuccessHandler, onFailureHandler ));
            }else{
                dispatch(addCluster(clusterDetailPayload, onSuccessHandler, onFailureHandler ));
            }
        }
    };

    const handleAccessModeChange = (mode: string) => {
        if(mode=='SINGLE_USER'){
            dispatch(getUsersList());
        }
    };
    
    return (
        <Modal
            isOpen={showModal}
            toggleClose={toggleClose}
            title="Cluster Settings"
            subtitle={formValues.name}
            className="addedit-cluster-Modal"
            shouldCloseOnOverlayClick={false}
        >
            <div className="innerCanvas">
                <Tabs defaultActiveKey={ isPipeline ? '1' : (currentUser.isAdmin ? '1': '2')} className="addedit-cluster-tabs">
                    {(currentUser.isAdmin || isPipeline) &&
                        <TabPane tab="Configuration" key="1" className="addedit-cluster-innertab addedit-cluster-tabs"  >
                            <ShowWhenTrue show={viewType === 'ui'}>
                                <div className="addedit-cluster-ui-form">
                                    <Form
                                        initialValues={formValues}
                                        validationSchema={baseFormSchemaForClusterForm}
                                        onSubmit={(data)=>{handleNewClusterSubmit(data);}}
                                        className={classNames({ showSpinner: showSpinner })}
                                        enableReinitialize
                                    >
                                        {showSpinner ? (
                                            <InPageSpinner />
                                        ) : (
                                            <ClusterForm
                                                nodeTypeList={nodeTypeList}
                                                clusterMode={clusterMode}
                                                dataBricksVersionList={dataBricksVersionList}
                                                currentClusterDetail={currentClusterDetail}
                                                isPipeline={isPipeline}
                                                firstOnDemand={firstOnDemand}
                                                setFirstOnDemand={setFirstOnDemand}
                                                dataSecurityMode={dataSecurityMode}
                                                singleUserName={singleUserName} 
                                                onAccessModeChange={handleAccessModeChange} 
                                            />
                                        )}
                                        <div className="modalBtns__box">
                                            <button
                                                className="btn-md btn-yellow"
                                                type="submit"
                                                disabled={loading}
                                            >
                                                {loading ?
                                                    <InPageSpinner size="XSmall" color="black" />
                                                    :
                                                    currentClusterDetail ? 
                                                    updateButtonLabel : addButtonLabel
                                                }   
                                            </button>
                                            <button
                                                className="btn-md btn-cancel"
                                                type="button"
                                                onClick={toggleClose}
                                            >
                                            Cancel
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                            </ShowWhenTrue>
                        </TabPane>
                    }
                    {
                        !isPipeline ? (
                            <TabPane  
                                disabled={currentClusterDetail?.state !== 'RUNNING'} 
                                key="2" 
                                className="editClusterInnerTab editClusterModal librariesTab"
                                tab={(
                                
                                    <TooltipTop title={currentClusterDetail?.state !== 'RUNNING' ? 'Cluster has to be in running mode': ''}  >
                                        <span>Libraries</span>
                                    </TooltipTop>
                                )}
                            >
                                <LibrariesTab 
                                    clusterId={currentClusterDetail?.clusterId || ''} 
                                    handleCloseModal={toggleClose} 
                                />
                            </TabPane>
                        ):  null
                    }
                </Tabs>
            </div>
        </Modal>
    );
};
