import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import { cloneDeep, get, range } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { FieldCreatorProps, FieldsCreator } from '../field-creator';
import { BaseFieldType } from '../types';

const RepeatV2: React.FC<FieldCreatorProps> = ({ fieldData }) => {
    const [numberOfReps, setNumberOfReps] = useState(1);
    const { setFieldValue, values } = useFormikContext<any>();
    const [, { initialValue }, { setValue }] = useField('extraData.repeatV2_' + fieldData.key);

    useEffect(() => {
        setValue(numberOfReps);
    }, [numberOfReps]);

    useEffect(() => {
        setNumberOfReps(initialValue || 1);
    }, [initialValue]);


    const fields = useMemo(() => {
        const _t: BaseFieldType[] = [];
        range(1, numberOfReps+1).forEach(rep =>  
            fieldData.fields?.forEach(field => {
                const _field = cloneDeep(field);
                _field.key = fieldData.key + '[' + (rep - 1)  + ']';
                _field.templateOptions.label = field.templateOptions.label + ' ' + rep;
                _t.push(_field);
            })
        );
        return _t;
    }, [numberOfReps]);
    
    const handleDecreaseCount = () => {
        setNumberOfReps(t => t-1);
        const _val = get(values, fieldData.key);
        setFieldValue(fieldData.key, _val.slice(0, numberOfReps - 1));
    }; 

    const renderCountTicker = () => (
        <div className="portTicker__box">
            <span className="portType">{fieldData.repeatOptions?.label || 'Number of Fields'}</span>
            <div className="portTicker__Btnbox">
                <button 
                    onClick={handleDecreaseCount} 
                    disabled={numberOfReps === 1}
                    type="button"
                    className="decrement__btn"
                >
                    <span>&#8722;</span>
                </button>
                <span className={classNames('countText')}>
                    {numberOfReps}
                </span>
                <button 
                    onClick={() => setNumberOfReps(t => t + 1)} 
                    type="button"
                    className="increment__btn"
                >
                    <span>&#43;</span>
                </button>
            </div>
        </div>
    );


    return (<div
        className="repeatV2"
    >
        {renderCountTicker()}
        <FieldsCreator 
            formData={fields}
        />
    </div>);
};

export default RepeatV2;