export enum WorkflowConfigItemType {
	"String" = "string",
	"Integer" = "integer",
	"Decimal" = "decimal",
	"Boolean" = "boolean",
	/**
	 * @deprecated - integer and decimal is used instead of number
	 */
	"Number" = "number",
}

export enum WorkflowConfigSelectionType {
	Default = "default",
	"Multiple Arguments" = "N-Args",
}

export const WORKFLOW_CONFIG_SELECTION_KEY = '__workflowConfigSelection';