import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { omit, cloneDeep, merge } from 'lodash';
import { InputField, SelectField } from '.';
import { JobCloseWorkflowIcon } from '../../pages/workflow-page/assets/icons';
import { uuid } from 'uuidv4';
import { useDebounce } from 'rooks';

interface Props {
  keyToSaveKeyValueInfo: string;
  addButtonText: string;
}

export const dbfsPrefix = 'dbfs:/';

const AddSVGIcon = () => (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.33333 9.99992C3.33333 6.32376 6.3241 3.33325 10 3.33325C13.6759 3.33325 16.6667 6.32376 16.6667 9.99992C16.6667 13.6761 13.6759 16.6666 10 16.6666C6.3241 16.6666 3.33333 13.6761 3.33333 9.99992ZM10.5128 10.5127H13.2051C13.4882 10.5127 13.7179 10.283 13.7179 9.99992C13.7179 9.71684 13.4882 9.48709 13.2051 9.48709H10.5128V6.92299C10.5128 6.63992 10.2831 6.41017 9.99998 6.41017C9.7169 6.41017 9.48716 6.63992 9.48716 6.92299V9.48709H6.79485C6.51178 9.48709 6.28203 9.71684 6.28203 9.99992C6.28203 10.283 6.51178 10.5127 6.79485 10.5127H9.48716V13.3332C9.48716 13.6163 9.7169 13.8461 9.99998 13.8461C10.2831 13.8461 10.5128 13.6163 10.5128 13.3332V10.5127Z"
            fill="black"
        />
        <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={3}
            y={3}
            width={14}
            height={14}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.33333 9.99992C3.33333 6.32376 6.3241 3.33325 10 3.33325C13.6759 3.33325 16.6667 6.32376 16.6667 9.99992C16.6667 13.6761 13.6759 16.6666 10 16.6666C6.3241 16.6666 3.33333 13.6761 3.33333 9.99992ZM10.5128 10.5127H13.2051C13.4882 10.5127 13.7179 10.283 13.7179 9.99992C13.7179 9.71684 13.4882 9.48709 13.2051 9.48709H10.5128V6.92299C10.5128 6.63992 10.2831 6.41017 9.99998 6.41017C9.7169 6.41017 9.48716 6.63992 9.48716 6.92299V9.48709H6.79485C6.51178 9.48709 6.28203 9.71684 6.28203 9.99992C6.28203 10.283 6.51178 10.5127 6.79485 10.5127H9.48716V13.3332C9.48716 13.6163 9.7169 13.8461 9.99998 13.8461C10.2831 13.8461 10.5128 13.6163 10.5128 13.3332V10.5127Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask0)">
            <rect width={20} height={20}/>
        </g>
    </svg>
);

const ScriptDestinationList = [
    {
        label: 'dbfs',
        value: 'dbfs'
    },
];

export const DynamicKeyValueFieldGeneratorCluster: React.FC<Props> = ({
    keyToSaveKeyValueInfo = 'additionalConfig',
    addButtonText = '+ Add Additional Configurations'
}) => {
    const { values, setValues, setFieldValue, initialValues } = useFormikContext<
    any
  >();
    const [addedFieldIds, setAddedFieldIds] = useState<string[]>([]);

    useEffect(() => {
        if (initialValues[keyToSaveKeyValueInfo]) {
            const __initialFieldIds: string[] = [];
            const __initialValues: Record<string, any> = cloneDeep(values);
            
            initialValues[keyToSaveKeyValueInfo].forEach((element: Record<string, any>) => {     
                Object.entries(element).map(
                    ([key, value]) => {
                        const _id = uuid();
                        __initialFieldIds.push(_id);
                        __initialValues[_id] = { key, value };
                        setFieldValue(_id,{ key, value });
                    }
                );
            });
            merge(initialValues,__initialValues);
            setAddedFieldIds(__initialFieldIds);
        }
    }, [initialValues]);

    

    const addValuesToFormikContext = useDebounce((addedFieldIds: string[], values: any) => {
    
        // if (!isEmpty(addedFieldIds)) {
        const additionalConfig: any=[];
        addedFieldIds.map(_id => {
            const _configInfo = values[_id];
            if (_configInfo)  {
                additionalConfig.push([_configInfo.key,_configInfo.value]);
            } 
        });
        setFieldValue(keyToSaveKeyValueInfo, additionalConfig);
        // }
    }, 500);

    const handleAddConfigFields = () => {
        const _id = uuid();
        const __initialValues: Record<string, any> = cloneDeep(values);
        const key = 'dbfs';
        __initialValues[_id] = { key };
        setFieldValue(_id,{ key });
        // initialValues is set for the new destination dropdown to show dbfs
        merge(initialValues,__initialValues);
        setAddedFieldIds([...addedFieldIds, _id]);
    };

    const handleRemoveConfigField = (idToBeRemoved: string) => {
        const __updatedConfigFields = [...addedFieldIds].filter(
            id => id !== idToBeRemoved
        );
    
        setAddedFieldIds(__updatedConfigFields);
        addValuesToFormikContext(__updatedConfigFields, values);
        if (values[idToBeRemoved]) {
            // const configKey = values[idToBeRemoved].value;
            const __values = omit(values, idToBeRemoved) as Record<string, any>;
            // __values[keyToSaveKeyValueInfo] = remove(
            //     __values[keyToSaveKeyValueInfo],function(n: string[]) {
            //         return n.indexOf(configKey) < 0;
            //     });
            setValues(__values as any);
        }
    };

    const getConnectorClassName = (index: number) => {
        return index === 0 ? 'dashed-connector first-child' : 'dashed-connector';
    };

    return (
        <div className="dynamicKeyValueGenBoxCluster dynamicKeyValueGenBox">
            <button
                type="button"
                onClick={handleAddConfigFields}
                className="addButton"
            >
                <AddSVGIcon/> {addButtonText}
            </button>
            {addedFieldIds.map((_id, i) => (
                <div className="section-wrapper" key={i}>
                    <span id="dashed-connector" className={getConnectorClassName(i)}/>
                    <div className="fieldsRow" key={_id}>
                        <div className="field_wrapper">
                            <SelectField
                                name={`${_id}.key`}
                                label="Destination"
                                className="ClusterName"
                                options={ScriptDestinationList}
                                onOptionClick={() => addValuesToFormikContext(addedFieldIds, values)}
                            />
                            <div className="UrlInput">
                                <InputField
                                    name={`${_id}.value`}
                                    label="Init Script Path"
                                    onChange={() => addValuesToFormikContext(addedFieldIds, values)}
                                >
                                    <span className="prefix">{dbfsPrefix}</span>
                                </InputField>
                            </div>
                        </div>
                        <button type="button" onClick={() => handleRemoveConfigField(_id)}>
                            <JobCloseWorkflowIcon />
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
};
