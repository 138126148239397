import styled from "styled-components";
import { FileExplorerProps } from "../types";

export const FileExplorerTable = styled.table<Pick<FileExplorerProps, "view">>`
	font-size: 12px;
	color: white;
	width: 100%;

	thead {
		th {
			background-color: ${({ theme }) => theme.colors.secondary500};
			font-weight: bold;
			transition: 0.3s;
			position: sticky;
			top: 0px;

			&:first-child {
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}
			&:last-child {
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}

			&:hover {
				background-color: #15162f;
			}
		}
	}

	th,
	td {
		padding: 7px 16px;
		cursor: pointer;

		&:nth-child(1) {
			width: ${({ view }) => (view === "Table" ? "40%" : "100%")};
		}
		&:nth-child(2) {
			width: 15%;
		}
		&:nth-child(3) {
			width: 15%;
		}
		&:nth-child(4) {
			width: 30%;
		}
	}

	tbody {
		tr {
			border-radius: 2px;

			&:hover {
				td {
					background-color: rgba(255, 255, 255, 0.32);
				}
			}

			&.selected {
				td {
					background-color: #5b608f;
				}
			}
		}

		td {
			transition: 0.3s;

			&:first-child {
				border-top-left-radius: 2px;
				border-bottom-left-radius: 2px;
			}
			&:last-child {
				border-top-right-radius: 2px;
				border-bottom-right-radius: 2px;
			}
		}
	}
`;
