import React, { useCallback, useMemo, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown'
import { useDispatch, useSelector } from 'react-redux';
import Form, { SelectField } from '../../../components/form';
import { _selectoptionType } from '../../../components/form/select-field';
import { getDocByName, toggleDocSpinner } from '../../../store/help';
import { RootState } from '../../../store/types';
import scrollIntoView from 'scroll-into-view-if-needed';
import { ImagePreview } from '../modals/image-preview';
import { InPageSpinner } from '../../../components/spinners/in-page-spinner';
import { Link } from 'react-router-dom';
import { convertTextToSlugForDocHeadings, getHelpDocImageUrl, getImagesCountInHelpDoc, parseDocDataForHeadings } from '../utils';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import remarkGfm from 'remark-gfm';


const flatten = (text: string, child: any): string => {
    return typeof child === 'string'
        ? text + child
        : React.Children.toArray(child.props.children).reduce(flatten, text);
};

export type ActiveImgInfo = { src: string; aspectRatio: number }

export const HelpTabRenderer: React.FC<{ hide?: boolean }> = ({ hide = false }) => {
    const dispatch = useDispatch();
    const { activeHelpDoc, docsList, showDocSpinner } = useSelector((store: RootState) => store.HelpReducer);
    const [showImageModal, toggleImagePreviewModal] = useState(false);
    const [activeImgInfo, setActiveImgInfo] = useState<ActiveImgInfo>({ src: '', aspectRatio: 1});

    const subsectionDropdownRef = useRef<SelectField>(null);
    const imagesRef = useRef<Record<string, HTMLImageElement | null>>({});
    const imagesCountToHideSpinnerRef = useRef<number>(0);
    const imageCounterOnLoadRef = useRef<number>(0);

    const docsListToBeShown = useMemo(() => {
        return docsList.map(docName => {
            return({ label: docName, value: docName });
        });
    }, [docsList]);


    const handleOpenDoc = (option: _selectoptionType) => {
        dispatch(toggleDocSpinner(true));
        dispatch(getDocByName(option.label));
        subsectionDropdownRef.current?.resetSelectedOption();
    };


    const handleNavigateToHeading = (headingId: string) => {
        const target = document.getElementById(headingId);
        if(target)
            scrollIntoView(target ,{ behavior: 'smooth', block: 'start' });
    };

    const handleClickLink = (option: _selectoptionType) => {
        handleNavigateToHeading(option.value);
    };


    const handleShowImagePreviewModal = (src: string) => {
        toggleImagePreviewModal(true);
        const naturalWidth = imagesRef.current[src]?.naturalWidth;
        const naturalHeight = imagesRef.current[src]?.naturalHeight;
        if(naturalWidth && naturalHeight) {
            setActiveImgInfo({ src, aspectRatio: naturalWidth/naturalHeight });
        }
    };

    const toggleSpinnerAndNavigateToHeading = () => {
        dispatch(toggleDocSpinner(false));
        setTimeout(() => activeHelpDoc?.subHeadingSlug && handleNavigateToHeading(activeHelpDoc.subHeadingSlug), 500);
    };

    const handleImageOnLoad = () => {
        if(imagesCountToHideSpinnerRef.current === 0 && imageCounterOnLoadRef.current === 0) {
            // WHEN IMAGES BEFORE SUBHEADING ARE 0 
            toggleSpinnerAndNavigateToHeading();
        }
        // Incremented by 1 everytime a new image load
        imageCounterOnLoadRef.current += 1;
        if(imageCounterOnLoadRef.current === imagesCountToHideSpinnerRef.current) {
            // If there's an active subheading, imagesCountToHideSpinnerRef = imagesTillSubHeading
            // else imagesCountToHideSpinnerRef = totalImagesInDocument
            toggleSpinnerAndNavigateToHeading();
        } 
    };

    const renderHeadings = ({ node, ...props }: any) => {
        /**
         * HeadingRenderer is a custom renderer
         * It parses the heading and attaches an id to it to be used as an anchor
         */
        const children = React.Children.toArray(props.children);
        const text = children.reduce(flatten, '');
        const slug = convertTextToSlugForDocHeadings(text);
        return React.createElement('h' + props.level, { id: slug }, props.children);
    }

    const renderers = {
        //This custom renderer changes how images are rendered
        //we use it to constrain the max width of an image to its container
        /* eslint-disable react/display-name */
        img: ({
            alt,
            src,
            title,
        }: any) => {
            // const _src = helpImagesUrl + '/' + activeHelpDoc?.title + '/' + src;
            const _src = getHelpDocImageUrl(activeHelpDoc?.title || '', src);
            // https://dsadls.blob.core.windows.net/datastudio/HelpFiles/Images/Custom Component/Custom Component.001.png
            // IMAGES ARE COPIED TO A FOLDER WITH NAME AS ITS TITLE
            return(
                <img 
                    alt={alt} 
                    src={_src}
                    title={title} 
                    style={{ maxWidth: 630, cursor: 'pointer', minHeight: 50 }}  
                    onClick={() => {
                        handleShowImagePreviewModal(_src);

                    }}
                    onLoad={handleImageOnLoad}
                    ref={(r) => imagesRef.current[_src] = r}
                />);
        },
        h1: renderHeadings,
        h2: renderHeadings,
        h3: renderHeadings,
        h4: renderHeadings,
        h5: renderHeadings,
        h6: renderHeadings,
        td: ({ node, ...props }: any) => {
            let hasSep = false;
            const _children: JSX.Element[] = [];
            // Using sep creates rows within rows - Refer to Components -> Data Quality – Out of the Box Data Quality Rules 
            props?.children?.forEach((child: any, i: number) => {
                if(child === "<sep>") {
                    hasSep = true
                } else {
                    _children.push(<span key={i}>{child}</span>)
                }
            })
            return <td style={props.style} className={classNames({ separated: hasSep })}>
                {_children}
            </td>;
        }
    };

    const subsectionHeadersList: _selectoptionType[] = useMemo(() => {
        // identifies # headings in the data and converts them to options
        if(activeHelpDoc?.data) {
            const _headings = parseDocDataForHeadings(activeHelpDoc?.data);
            let subHeadingTitle;
            if(activeHelpDoc?.subHeadingSlug) {
                const subHeadingOption = _headings.find(a => a.value === activeHelpDoc.subHeadingSlug);
                if(subHeadingOption?.label) {
                    subHeadingTitle = '$$ ' + subHeadingOption.label;
                } 
            }
            const imagesInfo = getImagesCountInHelpDoc(activeHelpDoc.data, subHeadingTitle);
            imagesCountToHideSpinnerRef.current = imagesInfo.imagesTillSubHeading !== null ? 
                imagesInfo.imagesTillSubHeading: imagesInfo.totalImages;
            imageCounterOnLoadRef.current = 0;
            return _headings;
        }
        return [];
    }, [activeHelpDoc]);

    const markdownData: string = useMemo(() => {
        // replaces sub heading titles with empty string
        // ## Canvas $$ Canvas Overview - Canvas Overview is removed 
        return (activeHelpDoc?.data || '').replaceAll(/ \$\$ (.*)/g, '');
    }, [activeHelpDoc?.data]);

    const renderMarkdown = useCallback(() => {
        return <ReactMarkdown 
            components={renderers}
            remarkPlugins={[remarkGfm]}
        >
            {markdownData}
        </ReactMarkdown>;
    }, [markdownData]);

    return(
        <div 
            className={classNames('console__helpTab', {'hide': hide})}
        >
            <div className="helpPanelHeader__box">
                <div className="helpPanelHeader__title">
                    HELP CENTER
                    <Link
                        to="/help"
                    >
                        <img src="/icons/export.svg" className="icon__square__hover export__icon" alt="" />
                    </Link>
                </div>
                <Form
                    initialValues={{ documentTitle: activeHelpDoc?.title, subsectionTitle: activeHelpDoc?.subHeadingSlug || '' }}
                    enableReinitialize
                    onSubmit={() => { return; }}
                >   
                    <SelectField
                        name="documentTitle"
                        className="documentTitle__dropdown"
                        options={docsListToBeShown}
                        onOptionClick={handleOpenDoc}
                        label="Help Document"
                        placeholder="Select a Topic"
                    />
                    <SelectField
                        name="subsectionTitle"
                        className={classNames('documentTitle__dropdown', {hide: isEmpty(subsectionHeadersList)})}
                        options={subsectionHeadersList}
                        onOptionClick={handleClickLink}
                        placeholder="Select a Topic"
                        label="Topics"
                        ref={subsectionDropdownRef}
                    />
                </Form>
            </div>
            <div
                className="helpDocument__box"
            >
                <InPageSpinner hide={!showDocSpinner} />
                <div
                    className={classNames({'hide': showDocSpinner })}
                >
                    {renderMarkdown()}
                </div>
            </div>
            <ImagePreview 
                show={showImageModal}
                toggleModal={() => toggleImagePreviewModal(false)}
                imgInfo={activeImgInfo}
            />
        </div>
    );
};