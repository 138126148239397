/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types */
import _ from 'lodash';
import React, { Component } from 'react';
import { Tooltip } from 'antd';
import Search1 from '../../../components/search/search2';
export default class WhereColumnSearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            results: [],
            result1: [],
            fullResults: [],
            first: true,
            firstFocus: true,
        };
    }

    componentDidMount() {
        this.setFullResultsState();
    }

    modifyColumn = (alias, tableName, value, id) => {
        let tep = null;
        if (this.props.query.geoSpatial.isGeoSpatialEnabled && alias) {
            const [talias] = alias.split('.');
            tep = this.props.query.from.fromJoinRows.find((_from) => {
                return _from.tableAlias === talias;
            });
        }
        if (this.props.type === 'select') {
            this.props.query.select.modifySelectColumn(
                this.props.rowIndex,
                alias,
                tableName,
                value,
                (tep && tep.geotable) || id
            );
        } else if (this.props.type === 'where') {

            this.props.query.where.modifyWhereColumn(
                this.props.rowIndex,
                alias,
                tableName,
                value,
                (tep && tep.geotable) || id,
                'single'
            );
        } else if (this.props.type === 'groupby') {
            this.props.query.groupby.modifyGroupByColumn(
                this.props.rowIndex,
                alias,
                tableName,
                value,
                (tep && tep.geotable) || id
            );
        }
        this.props.updateQueryState();
    };

    setFullResultsState = () => {
        let fullResults = {};

        if (this.props.type === 'select' || this.props.type === 'groupby') {
            fullResults.All = {
                name: 'all',
                alias: null,
                results: [{ tableName: 'all', alias: null, title: '*' }],
            };
        }

        fullResults = {
            ...fullResults,
            ...this.props.query.fullResults.results.reduce((resultDrop, result) => {
                // eslint-disable-next-line no-param-reassign

                resultDrop[
                    result.tableMetadata.name //+ ' (' + result.tableAlias + ')'
                ] = {
                    name: result.tableMetadata.name, //+ ' (' + result.tableAlias + ')',
                    tableAlias: result.tableAlias,
                    _id: result.tableMetadata.id,
                    results: result.tableMetadata.fields
                        ? result.tableMetadata.fields.map(column => ({
                            alias: result.tableAlias,
                            tablename: result.tableMetadata.name,
                            title: column.name,
                            _id: result.tableMetadata.id
                        }))
                        : [],
                };
                return resultDrop;
            }, {}),
        };

        if (this.props.type === 'select' || this.props.type === 'groupby') {
            Object.keys(fullResults).forEach(key => {
                if (key !== 'All') {
                    fullResults[key].results.unshift({
                        tableName: fullResults[key].name,
                        alias: fullResults[key].tableAlias,
                        title: '*',
                        _id: fullResults[key]._id
                    });
                }
            });
        }

        this.setState({
            // eslint-disable-next-line react/no-unused-state
            fullResults,
        });
    };

    handleResultSelect = (e, { result }) => {
        this.modifyColumn(result.alias, result.tablename, result.title, result._id);
    }

    handleSearchChange = (value) => {
        this.modifyColumn(null, null, value, '');
        this.setFullResultsState();

        setTimeout(() => {
            const re = new RegExp(
                _.escapeRegExp(
                    this.props.value.split('.')[1] ||
                    this.props.value.split('.')[0] ||
                    this.props.value
                ),
                'i'
            );

            const isMatch = result => {
                if (this.props.value) {
                    return (
                        re.test(result.title) ||
                        re.test(result.tablename) ||
                        re.test(result.tableAlias) || 
                        re.test(result._id)
                    );
                } else {
                    return true;
                }
            };

            const filteredResults = _.reduce(
                this.state.fullResults,
                (resultDrop, data, name) => {
                    const results = _.filter(data.results, isMatch);
                    if (results.length) resultDrop[name] = { name, results }; // eslint-disable-line no-param-reassign

                    return resultDrop;
                },
                {}
            );

            const data = [];

            Object.keys(filteredResults).forEach((name)=>{
                const results = filteredResults[name].results;
                data.push({
                    name,
                    results
                });
            });
            this.setState({
                results: data
            });
        }, 100);
    };

    getData = () => {
        let fullResults = {};

        if (this.props.type === 'select' || this.props.type === 'groupby') {
            fullResults.All = {
                name: 'all',
                alias: null,
                results: [{ tableName: 'all', alias: null, title: '*' }],
            };
        }

        fullResults = {
            ...fullResults,
            ...this.props.query.fullResults.results.reduce((resultDrop, result) => {
                // eslint-disable-next-line no-param-reassign

                resultDrop[
                    result.tableMetadata.name //+ ' (' + result.tableAlias + ')'
                ] = {
                    name: result.tableMetadata.name, //+ ' (' + result.tableAlias + ')',
                    tableAlias: result.tableAlias,
                    _id: result.tableMetadata.id,
                    results: result.tableMetadata.fields
                        ? result.tableMetadata.fields.map(column => ({
                            alias: result.tableAlias,
                            tablename: result.tableMetadata.name,
                            title: column.name,
                            _id: result.tableMetadata.id
                        }))
                        : [],
                };
                return resultDrop;
            }, {}),
        };

        if (this.props.type === 'select' || this.props.type === 'groupby') {
            Object.keys(fullResults).forEach(key => {
                if (key !== 'All') {
                    fullResults[key].results.unshift({
                        tableName: fullResults[key].name,
                        alias: fullResults[key].tableAlias,
                        title: '*',
                        _id: fullResults[key]._id
                    });
                }
            });
        }

        return fullResults;
    };

    render() {
        return (
            <Tooltip
                title={this.props.text}
                mouseLeaveDelay={0}
            >
                <Search1 
                    placeholder={`${
                        this.props.type.toUpperCase()
                    } column ${this.props.rowIndex + 1}`}
                    data={this.state.results} 
                    selectedValue={this.props.value}
                    getData={()=>{
                        if(this.props.value) {
                            this.handleSearchChange(this.props.value);
                        } else {
                            const data =[];
                            const obj =  this.getData();
                            Object.keys(obj).forEach((name)=>{
                                const results = obj[name].results;
                                data.push({
                                    name,
                                    results
                                });
                            });
                            this.setState({
                                results: data
                            });
                        }
                    }}
                    onSearch={(key)=>{
                        this.handleSearchChange(key);
                    }}
                    onSelect={(value)=>{
                        this.modifyColumn(value.alias, value.tablename, value.title, value._id);
                    }}
                />
            </Tooltip>
        );
    }
}
