import { Tag } from 'antd';
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { ShowWhenTrue } from '../../helpers';
import { TooltipTop } from '../tooltips';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import scrollIntoView from 'scroll-into-view-if-needed';
import { useOutsideClick } from 'rooks5';

export type TagFieldOption = { label: string; value: string; textForTag?: string; [x: string]: any }; 

interface Props  {
    options: TagFieldOption[];
    required?: boolean;
    label?: string;
    infoText?: string;
    handleSelectOptions: (selectedOptions: TagFieldOption[]) => void;
    autoFocus?: boolean;
}

type TagInfo = { label: string; value: string } 

export const TagFieldWithSuggestions: React.FC<Props> = ({ options, required = false, label = '', autoFocus = false, infoText, handleSelectOptions }) => {
    const [showSuggestionsDropdown, toggleSuggestionsDropdown] = useState(false);
    const [tagsList, setTagsList] = useState<TagInfo[]>([]);
    const [searchText, setSearchText] = useState('');
    const dropdownOuterBoxRef = useRef<HTMLDivElement>(null);
    const tagsOuterBoxRef = useRef<HTMLDivElement>(null);
    const searchFieldRef = useRef<HTMLInputElement>(null);
    const [selectedTagsMap,setSelectedTagsMap] = useState<Record<string, true>>({});
    const [activeItemIndexOnKeydown, setActiveItemIndexOnKeyDown] = useState<null | number>(null);
    const identifierForOptions = useRef(Math.random().toString(36).substring(2,7));

    useOutsideClick(dropdownOuterBoxRef, () => showSuggestionsDropdown && toggleSuggestionsDropdown(false), showSuggestionsDropdown);
    

    useLayoutEffect(() => {
        if(showSuggestionsDropdown && !!tagsOuterBoxRef.current && !!dropdownOuterBoxRef.current) {
            dropdownOuterBoxRef.current.style.top = (tagsOuterBoxRef.current.clientHeight + 4) + 'px';
        }
    }, [showSuggestionsDropdown]);



    useEffect(() => {
        const _selectedTagsMap: typeof selectedTagsMap = {};
        tagsList.forEach(tag => _selectedTagsMap[tag.value] = true);
        setSelectedTagsMap(_selectedTagsMap);
        handleSelectOptions(tagsList);
    }, [tagsList]);
 
    const onSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        // setActiveItemIndexOnKeyDown(null);
        setSearchText(val);
        if(val === '' && showSuggestionsDropdown) {
            toggleSuggestionsDropdown(false);
        } else if(val !== '')   {
            setActiveItemIndexOnKeyDown(0);
            if(!showSuggestionsDropdown)
                toggleSuggestionsDropdown(true);
        }
    };

    const handleAddTag = (option: TagFieldOption) => {
        const optionLabel = (option).textForTag ? (option).textForTag : (option).text;
        setTagsList(tags => [...tags, { label: (optionLabel), value: (option).value} ]);
    };

    const handleDeleteTag = (option: TagFieldOption) => {
        setTagsList(tags => tags.filter(tag => tag.value !== option.value));
    };

    const handleSelectOption = (option: TagFieldOption) => {
        toggleSuggestionsDropdown(false);
        setSearchText('');
        setActiveItemIndexOnKeyDown(null);
        handleAddTag(option);
        searchFieldRef.current?.focus();
    };

    const optionsToShow: TagFieldOption[] = useMemo(() => options
        .filter(opt => !selectedTagsMap[opt.value] && opt.label.toLowerCase().includes(searchText.toLowerCase()))
    , [searchText, options, selectedTagsMap]);


    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === 'Backspace' && !searchText) {
            handleDeleteTag(tagsList[tagsList.length - 1]);
        } else if(searchText) {
            let updatedIndex: number | null = null;
            switch(e.key) {
                case 'ArrowDown': {
                    if(activeItemIndexOnKeydown !== null && (activeItemIndexOnKeydown + 1 < optionsToShow.length)) {
                        updatedIndex = activeItemIndexOnKeydown + 1;
                    }
                    break;
                }
                case 'ArrowUp': {
                    if(activeItemIndexOnKeydown !== null && activeItemIndexOnKeydown !== 0) {
                        updatedIndex = activeItemIndexOnKeydown - 1;
                    }
                    break;
                }
                case 'Enter': {
                    if(searchText.trim()) {
                        // Prevent pressing 'Enter' from submitting the form when a searchText is present.
                        e.preventDefault();
                    }
                    
                    if(!!searchText && activeItemIndexOnKeydown !== null && optionsToShow[activeItemIndexOnKeydown]) {
                        handleSelectOption(optionsToShow[activeItemIndexOnKeydown]);
                    }
                }
            }

            if(updatedIndex !== null) {
                setActiveItemIndexOnKeyDown(updatedIndex);
                const currentActiveItem = document.getElementById(identifierForOptions.current+'_'+updatedIndex);
                currentActiveItem && scrollIntoView(currentActiveItem, { 'block': 'nearest'});
            }
        }
    };

    

    return(
        <div
            className="tagFieldWithSuggestions__container"
        >
            <label 
                className="inputfield__label"
            >
                <span className={required ? 'red-star': ''}>{label}</span>
                <ShowWhenTrue show={!!infoText}>
                    <TooltipTop placement="topRight" overlay={infoText}>
                        <img src="/icons/info-fields.png" width="16" height="16" className="info__icon" alt="information-icon" />
                    </TooltipTop>
                </ShowWhenTrue>
            </label>
            
            <div
                className="tagFieldWithSuggestions__tagsOuterBox"
                ref={tagsOuterBoxRef}
                
            >
                <div
                    className="tagsBox"
                    // onClick={}
                >
                    {tagsList.map(tag => (<Tag key={tag.label} color="#0455A4" onClose={() => handleDeleteTag(tag)} closable>{tag.label}</Tag>))}
                    <input 
                        value={searchText} 
                        onChange={onSearchTextChange} 
                        placeholder={'Email'}
                        onKeyDown={handleKeyDown}
                        ref={searchFieldRef}
                        autoFocus={autoFocus}
                    />
                </div>
                <div className={classNames('suggestions__outer', { hide: !showSuggestionsDropdown || isEmpty(optionsToShow) })} ref={dropdownOuterBoxRef}>
                    <ul>
                        {optionsToShow.map(((option, index) => (
                            <li
                                onClick={() => handleSelectOption(option)}
                                key={option.label}
                                className={classNames({  'activeItem': activeItemIndexOnKeydown === index })}
                                onMouseOver={() => setActiveItemIndexOnKeyDown(index)}
                                id={identifierForOptions.current+'_'+index}
                            >
                                {option.label}
                            </li>
                        )))}
                    </ul>
                </div>
            </div>
        </div>

    );
};