import { _Axios } from './httpsettings';
import { AxiosResponse } from 'axios';
import axios from 'axios';
import { commonErrorHandler } from './errorhandler';

export type responseCallback = (arg0: AxiosResponse['data']) => any
export type errorHandlerCallback =  (arg0: AxiosResponse) => void

// Add a request interceptor
// axios.interceptors.request.use(
//     config => {
        
//         const token = localStorage.get('token');
//         console.log("token", token)
//         if (token) {
//             config.headers['Authorization'] = 'Bearer ' + token;
//         }
//         // config.headers['Content-Type'] = 'application/json';
//         return config;
//     },
//     error => {
//         Promise.reject(error)
//     });

// //Add a response interceptor

axios.interceptors.response.use((response) => {
    return response;
},  async (error) => {
    commonErrorHandler(error);
    return Promise.reject(error);
});



class HttpInvokerClass {
    timeOut: number;

    constructor(){
        this.timeOut = 60000000; 
    }

    _get(url: string, cors = false) {
        return _Axios(cors)
            .get(url);
    }

    _post(url: string, data: object, cors = false) {
        return _Axios(cors)
            .post(url, data);
    }

    _put(url: string, data: object, cors = false) {
        return _Axios(cors)
            .put(url, data);
    }

    _patch(url: string, data: object, cors = false) {
        return _Axios(cors)
            .patch(url, data);
    }

    _delete(url: string, cors = false) {
        return _Axios(cors)
            .delete(url);
    }

    Get(url: string, callback: responseCallback, errorHandlerCallBack: errorHandlerCallback, cors = false) {
        this._get(url, cors)
            .then(function (response) {
            // handle success
                callback(response.data);
            })
            .catch(function (error) {
            // handle error
                error.response && errorHandlerCallBack(error);
            })
            .finally(function () {
            // always executed
            });
    }

    Post(url: string, data: object, callback: responseCallback, errorHandlerCallBack: errorHandlerCallback, cors = false) {
        this._post(url, data, cors)
            .then(function (response) {
            // handle success
                callback(response.data);
            })
            .catch(function (error) {
            // handle error
                error.response && errorHandlerCallBack(error.response);
            })
            .finally(function () {
            // always executed
            });
    }


    Put(url: string, data: object, callback: responseCallback, errorHandlerCallBack: errorHandlerCallback, cors = false) {
        this._put(url, data, cors)
            .then(function (response) {
            // handle success
                callback(response.data);
            })
            .catch(function (error) {
            // handle error
                error.response && errorHandlerCallBack(error.response);
            })
            .finally(function () {
            // always executed
            });
    }

    Patch(url: string, data: object, callback: responseCallback, errorHandlerCallBack: errorHandlerCallback, cors = false) {
        this._patch(url, data, cors)
            .then(function (response) {
            // handle success
                callback(response.data);
            })
            .catch(function (error) {
            // handle error
                error.response && errorHandlerCallBack(error.response);
            })
            .finally(function () {
            // always executed
            });
    }


    Delete(url: string, callback: responseCallback, errorHandlerCallBack: errorHandlerCallback, cors = false) {
        this._delete(url, cors)
            .then(function (response) {
            // handle success
                callback(response.data);
            })
            .catch(function (error) {
            // handle error
                error.response && errorHandlerCallBack(error.response);
            })
            .finally(function () {
            // always executed
            });
    }

    Initialize(timeOut: number) {
        this.timeOut = timeOut;
    }
}


const HttpInvoker = new HttpInvokerClass();

export default HttpInvoker;