/* eslint-disable react/no-render-return-value */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { KeycloakService } from './services';

// ReactDOM.render(<App />, document.getElementById('root'));

const isDrillingDataExplorer = window.location.pathname.includes('data-explorer-drilling-app');

if(isDrillingDataExplorer){
    ReactDOM.render(<App />, document.getElementById('root'));
}

if(!isDrillingDataExplorer){
    KeycloakService.initKeycloak(() => {
        return ReactDOM.render(<App />, document.getElementById('root'))
    });
}
