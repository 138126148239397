export const GET_CRON = 'GET_CRON';

export type CronPayload = { description: string; executionDates: string[] };

export type ActionTypes = {
    type: typeof GET_CRON;
    payload: CronPayload;
}

export type CronReducerState = {
    cron: CronPayload;
}