import { Flex } from "@components/ui/Flex";
import React, { useState } from "react";
import { ActionBar } from "./ActionBar";
import { FileList } from "./FileList";
import { FileExplorerProps } from "./types";

export const FileExplorer = ({
	view = "Table",
	...props
}: FileExplorerProps) => {
	const [search, setSearch] = useState("");
	const [searchResultsCount, setSearchResultCount] = useState(0);

	return (
		<Flex
			width="100%"
			height="100%"
			bg={props.bg|| "secondary400"}
			p={props.padding || "16px"}
			flexDirection="column"
			borderRadius="8px"
		>
			{props.hideActionBar || (
				<ActionBar
					setSearch={setSearch}
					search={search}
					searchResultCount={searchResultsCount}
					view={view}
					{...props}
				/>
			)}
			<FileList
				view={view}
				{...props}
				setSearch={setSearch}
				search={search}
				setSearchResultCount={setSearchResultCount}
			/>
		</Flex>
	);
};
