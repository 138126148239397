import Form, { InputField, NewSelectField } from "@components/form";
import { booleanOptions } from "@components/formcreators/ml-pipeline/enums";
import {
	Table,
	useTableCheckboxColumn,
	useTableCheckboxColumnType,
} from "@components/table";
import {
	WorkflowConfig,
	WorkflowConfigItemType,
} from "@services/WorkflowConfig";
import { useFormikContext } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
	TableCellProps,
	TableHeaderRowProps,
	TableProps,
} from "react-virtualized";
import styles from "../styles.module.scss";
import { getWorkflowConfigSchema } from "../WorkflowConfigForm";
import * as yup from "yup";
import classNames from "classnames";
import { ShowWhenTrue } from "@helpers/showwhentrue";
import isEmpty from "lodash/isEmpty";
import { useWindowSize } from "rooks5";
import { Checkbox } from "@components/form/elements";

type Props = {
	importedConfig: WorkflowConfig;
	currentConfig: WorkflowConfig;
	setSelectedConfigItemsCount: (count: number) => void;
	onSubmit: (newKeys: WorkflowConfig, existingKeys: WorkflowConfig) => void;
};

type FormValues = {
	newKeys: WorkflowConfig;
	// existingKeys are the keys that already exist in the existing workflow config
	existingKeys: WorkflowConfig;
};

const ROW_HEIGHT = 52;
const HEADER_HEIGHT = 32;
const TABLE_WIDTH = 540;

const schema = yup.object().shape({
	newKeys: yup.array().of(getWorkflowConfigSchema()),
	existingKeys: yup.array().of(getWorkflowConfigSchema()),
});

const getFormValues = (config: WorkflowConfig, currentConfig: WorkflowConfig) =>
	config.reduce(
		(configMap, configItem) => {
			if (
				currentConfig.some(
					(currentConfigItem) =>
						currentConfigItem.key === configItem.key
				)
			) {
				configMap.existingKeys.push(configItem);
			} else {
				configMap.newKeys.push(configItem);
			}
			return configMap;
		},
		{ newKeys: [], existingKeys: [] } as FormValues
	);

const getSelectedKeys = (
	arr: WorkflowConfig,
	selection: Record<number, boolean>
) =>
	Object.entries(selection).reduce((selectedConfig, [idx, selected]) => {
		if (selected) {
			selectedConfig.push(arr[parseInt(idx)]);
		}
		return selectedConfig;
	}, [] as WorkflowConfig);

type ConfigTableProps = {
	config: WorkflowConfig;
	renderCheckboxColumn: ReturnType<
		useTableCheckboxColumnType
	>["renderCheckboxColumn"];
	fieldKey: keyof FormValues;
	tableHeight: number;
};

const ConfigTable: React.FC<ConfigTableProps> = ({
	config,
	renderCheckboxColumn,
	fieldKey,
	tableHeight,
}) => {
	const { values } = useFormikContext<FormValues>();
	const rowCount = config.length;
	const isExistingKeys = fieldKey === "existingKeys";
	// const tableHeight = Math.min(6.8, rowCount) * ROW_HEIGHT + HEADER_HEIGHT + (isExistingKeys ? 10:0);
	const configFormValues = values[fieldKey];
	// newKeys[1].key
	const getConfigFormKey = (rowIndex: number, key: string) =>
		`${fieldKey}[${rowIndex}].${key}`;

	const tableProps: TableProps = {
		height: tableHeight,
		rowHeight: ROW_HEIGHT,
		rowCount: rowCount,
		width: TABLE_WIDTH,
		headerHeight: HEADER_HEIGHT,
		rowGetter: () => ({}),
		rowClassName: classNames(styles.importConfigItemsTableRow, {
			[styles.importConfigItemsTableRowExistingKeys]: isExistingKeys,
		}),
		className: styles.importConfigTable,
		disableHoverState: true,
		containerClassName: styles["configTable__container"],
	};

	const existingKeysHeaderRowRenderer = ({
		className,
		columns,
		style,
	}: TableHeaderRowProps) => {
		return (
			<div
				className={classNames(className, styles["existingKeysHeader"])}
				role="row"
				style={{ ...style, height: 40 }}
			>
				{/* for the checkbox */}
				{columns[0]}
				<div className={styles.identicalKeysMessage}>
					{config.length} IDENTICAL KEY{config.length > 1 ? "s" : ""}{" "}
					FOUND
				</div>
			</div>
		);
	};

	if (isExistingKeys) {
		tableProps.headerRowRenderer = existingKeysHeaderRowRenderer;
	}

	return (
		<Table {...tableProps}>
			{renderCheckboxColumn()}
			<Table.Column
				dataKey=""
				label="Key"
				cellRenderer={({ rowIndex }: TableCellProps) => (
					<InputField
						name={getConfigFormKey(rowIndex, "key")}
						readOnly
					/>
				)}
				width={TABLE_WIDTH / 3}
				className={styles.tableCellField}
				headerClassName={styles.tableCellHeaderField}
			/>
			<Table.Column
				dataKey=""
				label="Value"
				cellRenderer={({ rowIndex }: TableCellProps) => {
					const isBooleanValue =
						configFormValues?.[rowIndex]?.type ===
						WorkflowConfigItemType.Boolean;
					const key = getConfigFormKey(rowIndex, "value");

					return isBooleanValue ? (
						<NewSelectField
							name={key}
							options={booleanOptions}
							usePortal
						/>
					) : (
						<InputField name={key} useToolTipForErrorMessage />
					);
				}}
				width={TABLE_WIDTH / 3}
				className={styles.tableCellField}
				headerClassName={styles.tableCellHeaderField}
			/>
			<Table.Column
				dataKey=""
				label="Type"
				cellRenderer={({ rowIndex }: TableCellProps) => (
					<div className={styles.configTypeText}>
						{configFormValues?.[rowIndex]?.type}
					</div>
				)}
				width={TABLE_WIDTH / 4}
				className={classNames(styles.tableCellField)}
				headerClassName={styles.tableCellHeaderField}
			/>
		</Table>
	);
};

const ImportedConfigItemsSelection: React.FC<Props> = ({
	importedConfig,
	currentConfig,
	setSelectedConfigItemsCount,
	onSubmit,
	children,
}) => {
	const [config, setConfig] = useState<FormValues>({
		newKeys: [],
		existingKeys: [],
	});

	useEffect(() => {
		setConfig(getFormValues(importedConfig, currentConfig));
	}, [importedConfig, currentConfig]);

	const {
		renderCheckboxColumn: renderCheckboxColumnOfExistingKeysConfig,
		rowSelection: existingKeysSelection,
		noOfRowsSelected: noOfExistingKeysSelected,
		...existingKeysCheckboxUtils
	} = useTableCheckboxColumn({
		rowCount: config.existingKeys.length,
		checkboxSize: "md",
		defaultSelected: true,
		hideSelectAll: !config.newKeys.length,
	});

	const {
		renderCheckboxColumn: renderCheckboxColumnOfNewKeysConfig,
		rowSelection: newKeysSelection,
		noOfRowsSelected: noOfNewKeysSelected,
		...newKeysCheckboxUtils
	} = useTableCheckboxColumn({
		rowCount: config.newKeys.length,
		checkboxSize: "md",
		defaultSelected: true,
	});

	const handleRenderCheckboxColumnOfNewKeysConfig = useCallback(() => {
		const {
			allRowsSelected: allExistingKeysSelected,
			rowsPartiallySelected: existingKeysPartiallySelected,
		} = existingKeysCheckboxUtils;

		const {
			allRowsSelected: allNewKeysSelected,
			rowsPartiallySelected: newKeysPartiallySelected,
		} = newKeysCheckboxUtils;
		const allRowsSelected = allExistingKeysSelected && allNewKeysSelected;

		const partiallySelected =
			!allRowsSelected &&
			(newKeysPartiallySelected ||
				existingKeysPartiallySelected ||
				allExistingKeysSelected ||
				allExistingKeysSelected);

		const handleSelectUnSelectAllKeys = () => {
			if (partiallySelected || allRowsSelected) {
				existingKeysCheckboxUtils.unSelectAll();
				newKeysCheckboxUtils.unSelectAll();
			} else {
				existingKeysCheckboxUtils.selectAll();
				newKeysCheckboxUtils.selectAll();
			}
		};

		return renderCheckboxColumnOfNewKeysConfig({
			headerLabel: (
				<Checkbox
					checked={allRowsSelected}
					onClick={handleSelectUnSelectAllKeys}
					size="md"
					color="gold"
					partiallySelected={partiallySelected}
				/>
			),
		});
	}, [
		renderCheckboxColumnOfNewKeysConfig,
		existingKeysCheckboxUtils,
		newKeysCheckboxUtils,
	]);

	useEffect(() => {
		setSelectedConfigItemsCount(
			noOfNewKeysSelected + noOfExistingKeysSelected
		);
	}, [noOfNewKeysSelected, noOfExistingKeysSelected]);

	const _onSubmit = useCallback(
		(values: FormValues) => {
			onSubmit(
				getSelectedKeys(values.newKeys, newKeysSelection),
				getSelectedKeys(values.existingKeys, existingKeysSelection)
			);
		},
		[onSubmit, newKeysSelection, existingKeysSelection]
	);

	const { innerHeight } = useWindowSize();

	const { newKeysTableHeight, existingKeysTableHeight } = useMemo(() => {
		let newKeysTableHeight = 0;
		let existingKeysTableHeight = 0;
		if (innerHeight) {
			const heightAllowed = 0.6 * innerHeight - HEADER_HEIGHT;

			const newKeysExist = !!config.newKeys.length;
			const existingKeysExist = !!config.existingKeys.length;
			if (newKeysExist && !existingKeysExist) {
				newKeysTableHeight = heightAllowed + 25;
			} else if (!newKeysExist && existingKeysExist) {
				existingKeysTableHeight = heightAllowed - 12;
			} else {
				const maxHeightAllowed = heightAllowed / 2 + 10;

				newKeysTableHeight = Math.min(
					(config.newKeys.length + 0.75) * ROW_HEIGHT,
					maxHeightAllowed
				);
				existingKeysTableHeight = Math.min(
					config.existingKeys.length * ROW_HEIGHT,
					maxHeightAllowed
				);

				if (newKeysTableHeight < maxHeightAllowed) {
					existingKeysTableHeight +=
						maxHeightAllowed - newKeysTableHeight;
				} else if (existingKeysTableHeight < maxHeightAllowed) {
					existingKeysTableHeight += 32;
					newKeysTableHeight +=
						maxHeightAllowed - existingKeysTableHeight;
				}
			}
		}

		return { newKeysTableHeight, existingKeysTableHeight };
	}, [config, innerHeight]);

	return (
		<Form
			initialValues={config}
			onSubmit={_onSubmit}
			validationSchema={schema}
			validateOnMount
			enableReinitialize
		>
			<div className={styles["formInnerContainer"]}>
				<ConfigTable
					config={config.newKeys}
					renderCheckboxColumn={
						handleRenderCheckboxColumnOfNewKeysConfig
					}
					fieldKey="newKeys"
					tableHeight={newKeysTableHeight}
				/>
				<ShowWhenTrue show={!isEmpty(config.existingKeys)}>
					<ConfigTable
						config={config.existingKeys}
						renderCheckboxColumn={
							renderCheckboxColumnOfExistingKeysConfig
						}
						fieldKey="existingKeys"
						tableHeight={existingKeysTableHeight}
					/>
				</ShowWhenTrue>
			</div>
			{children}
		</Form>
	);
};

export default ImportedConfigItemsSelection;
