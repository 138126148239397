/* eslint-disable complexity */
import * as yup from 'yup';
import { BaseFieldType, ComponentPropertiesValuesType } from './types';
import _, { has, get, isArray, isEmpty, omit, isString, set, unset, cloneDeep, range } from 'lodash';
import {  BalanceModes, ClassificationTypes, ColorModeOptions, DataCategoryOptions, DataSet, EvalMetricClassOptions, inputTypeFieldName, InputTypeOptions, LabelModeOptions, MlPipelineStageKeys, ModeOptions, OperationOptions, OutputFormatOptions, PipelineCategoryOptions, PREPROCESSING, PythonModelLibraryOptions, SparkModelLibraryOptions, SplitForTestTypes, ValidateModelOptions } from './ml-pipeline/enums';
import { CurrentModelLibrarySelection, getCurrentPythonModuleKey, getKeyInPipelineStage, getMlPipelineStagesInitialVal, getModelLibrarySelectionKey, getOldFormatPythonModuleKey } from './ml-pipeline/utils';
import { MlPipelineStageInfo, MlPipelineStages } from './ml-pipeline/build-pipeline-modal/build-pipeline-RHS';
import { ExecutionEnvModes } from '../../constants/enums';
import { errorAlert } from '../toastify/notify-toast';
import { KeyPairTableValueType } from './fieldComponents/keyPairComponents';
import { uuid } from 'uuidv4';
import { getVariableUniqueId } from './WorkflowConfigVariablePortFields/utils';
import { WorkflowFieldSelectionObj } from './WorkflowField';
import { WorkflowConfigMappingInFormikContext } from './WorkflowConfigVariablePortFields/reducer';
import { RepeatV3ValueType } from './fieldComponents/RepeatV3';
import { DsNodeExtras } from '@components/workflow-canvas/node/ds_nodemodel';
import { WorkflowCanvas } from '@components/workflow-canvas';
import { getRefValuesForUniqueValueField } from './fieldComponents/UniqueValue/utils';
import { WorkflowConfigItem } from '@services/WorkflowConfig';

export const getRepeatV3InitialValue = (fieldData: BaseFieldType, extraData: DsNodeExtras['extraData']): RepeatV3ValueType => {
	const count = fieldData.repeat_options?.initialCount || 0
	let repeatData: RepeatV3ValueType['repeatData'] = []

	if(count > 0 && fieldData.fields) {
		const initialValues = omit(initialValuesCreator({ formData: fieldData.fields, extraData }), 'extraData')
		repeatData = range(0, count).map(() => cloneDeep(initialValues))
	}

	return {
		count,
		repeatData
	}
}

type schema = {[x: string]: yup.StringSchema | yup.NumberSchema }
type baseObject = Record<string, any>

export function SchemaCreator(formData: BaseFieldType[]): yup.ObjectSchema {
    const fieldsSchema: schema = {};
    formData.forEach((fieldData: BaseFieldType) => {
        const { key, templateOptions } = fieldData;
        let datatype: 'string' | 'number' = 'string';
        if(templateOptions.variable_type === 'integer' || templateOptions.variable_type === 'float'
            || templateOptions.variable_type === 'number'){
            datatype = 'number';
        }
        let validator = yup[datatype]();
        if(templateOptions.required){
            validator = validator.required('This is a required field');
        }
        if(templateOptions.maxLength){
            validator = validator.max(templateOptions.maxLength, `Length cannot be more than ${templateOptions.maxLength}`);
        }
        fieldsSchema[key] = validator;
    });
    const schema = yup.object().shape(fieldsSchema); 
    return schema;
}

export function getFieldDefaultValue(fieldData: BaseFieldType) {
    // let defaultValue: BaseFieldType['defaultValue'] = '';
    // if(fieldData.defaultValue !== '' && fieldData.defaultValue !== undefined) defaultValue= fieldData.defaultValue;
    if(has(fieldData, 'defaultValue')) return fieldData.defaultValue;
    return '';
}

export function getFieldValue(fieldData: BaseFieldType): BaseFieldType['defaultValue'] {
    let value: BaseFieldType['defaultValue']  = '';
    if(has(fieldData, 'value')) {
        value = fieldData.value;
    } else if((has(fieldData, 'defaultValue'))) {
        value = fieldData.defaultValue;
    }

    // workitem #2460 fix 
    if(fieldData.key === "token_type" && fieldData.value === "key"){
        value = fieldData.defaultValue;
    }
     // workitem #3169 fix 
    if(fieldData.key === "ignore_error" && fieldData.type === "radio" && fieldData.defaultValue === 0 && fieldData.value === false){
        value = fieldData.defaultValue;
    }
    // if(fieldData.value === 0 || (fieldData.value !== undefined && fieldData.value !== null)) {
    //     value = fieldData.value;
    // } else if(!!(fieldData.defaultValue)  || fieldData.defaultValue === false || fieldData.defaultValue === 0){
    //     value = fieldData.defaultValue;
    // }
    return value;
}

export function initialValuesCreator({ 
    formData, 
    extraData = {}, 
    activeExecutionEnv = ExecutionEnvModes['Spark'], 
    workflowCanvas = WorkflowCanvas
}: { 
    formData: BaseFieldType[], extraData?: any, activeExecutionEnv ?: ExecutionEnvModes, workflowCanvas ?: typeof WorkflowCanvas
}): baseObject  {
    const initialValues: ComponentPropertiesValuesType = {
        extraData: { ...(extraData || {}) }
    };

    const formDataObj: Record<string, BaseFieldType> = {};
    try {
        formData.forEach((fieldData: BaseFieldType) => {
            if(fieldData.type === 'unique-value') {
                // TODO: To be refactored - activeNode and WorkflowCanvas has to be passed as an argument
                const currentFieldKey = fieldData.key;
                // Refer to ComponentPropertiesValuesType
                const refValuesForUniqueValueField = getRefValuesForUniqueValueField(currentFieldKey, workflowCanvas, true);
                set(initialValues, `extraData.__refValuesForUniqueValueField.${currentFieldKey}`, refValuesForUniqueValueField)
            }
            set(initialValues, fieldData.key, getFieldValue(fieldData));
            set(formDataObj, fieldData.key, fieldData);
        });
        // if(!isEmpty(extraData)){
        //     set(initialValues, 'extraData', extraData);
        // }
        
        //for loop component
        formData.forEach((fieldData)=>{
            if(fieldData.type === 'repeat-v3') {
                if(!fieldData.value && fieldData.fields) {
                    set(initialValues, fieldData.key, getRepeatV3InitialValue(fieldData, extraData))
                }

            } 
            else if(fieldData.type === 'repeat') {
                const data_field  = fieldData.repeat_options?.data_field || '';
                const outputFormat = fieldData.repeat_options?.outputFormat;
                if(outputFormat){
                    const outputFormatKey = Object.keys(outputFormat);
                    const extraData = initialValues.extraData || {};
                    const output: any = {};
                    const regex = /[.-]/g;
                    const obj: any = extraData[fieldData.key] || {};
                    let _renderData: any = [];
                    
                    if(fieldData.repeat_options?.data_type === 'extraData') {
                        _renderData = initialValues?.extraData?.[data_field] || [];
                    } else {
                        const str = initialValues?.[data_field].trim() || '';
                        _renderData = isEmpty( str ) ?  [] : str.split(',');
                    }
                    outputFormatKey.forEach(key => {
                        output[key] = (obj[key] || '').split(outputFormat[key].delimiter);
                    });

                    _renderData?.forEach((element: any, index: number) => {
                        outputFormatKey.forEach(key=>{
                            let _key = '';
                            _key = key + ( (fieldData.repeat_options?.data_type === 'extraData'? element._value : element) ||'').replace(regex, '_');
                            let out = output[key][index];
                            if(isEmpty(out)) {
                                out = fieldData.fields?.filter(o=>o.key === key)[0].defaultValue;
                            }
                            set(initialValues, _key, out);
                        });
                    });
                    set(initialValues, 'extraData', extraData);
                }

            } else if(fieldData.type === 'component-list'){
                let data: any = null;
                try{
                    data = JSON.parse(fieldData.value);
                } catch(e){
                    return;
                }
                data.forEach((element: any) => {
                    const arr: any = [];
                    const componentId = element.component_id.replace(/-/g, '_');
                    element.data?.forEach((element1: any) => {
                        const _key = componentId + '__' + (element1.key);  
                        arr.push(element1.key);  
                        set(initialValues, _key, element1.value);
                    });
                    set(initialValues, componentId, arr.join(','));
                });
            } else if(fieldData.type === 'prefixSuffix'){
                const repeatOptions = fieldData.repeatOptions;
                const listenToPort = repeatOptions?.listenToPort;
                if(listenToPort){
                    const port_type = repeatOptions?.listenToPort === 'input'? 'num_ip_ports' : 'num_op_ports';
                    let data: any = null;
                    try{
                        data = JSON.parse(fieldData.value);
                    } catch(e){
                        return;
                    }
                    Array.from(new Array(parseInt(initialValues[port_type] || 0, 10))).forEach((_items: any, index: number) => {
                        fieldData.fields?.forEach((field)=>{
                            const key = field.key + '_' + index;
                            set(initialValues, key, (data[index] && data[index][field.key]|| null));
                        });
                    });
                }
            } else if(fieldData.type === 'ml-pipeline') {
                if(fieldData.value) {
                    let isModelLibraryKeyAdded = false;
                    let isBuildModelKeyAdded = false;
                    let isSparkSelectionKeyAdded = false;
                    let isStagesInfoNewFormat = true;
                    let isInputTypeKeyAdded = false;
                    let isSklearnPreprocessingStagesAdded = true;
                    Object.entries(omit(fieldData.value, 'toTriggerError')).forEach(([key, value]: [string, any]) => {
                        if(key === 'model_library') isModelLibraryKeyAdded = true;
                        if(key === 'build_model') isBuildModelKeyAdded = true;
                        if(key === 'Spark__current_selection') isSparkSelectionKeyAdded = true;
                        if(key === 'stagesInfo') {
                            if(isArray(value)) {
                                isStagesInfoNewFormat = false;
                            }
                            else if(!has(value, MlPipelineStageKeys.PREPROCESSING_Sklearn)) {
                                isSklearnPreprocessingStagesAdded = false;
                            }
                        }
                        if(key === inputTypeFieldName) isInputTypeKeyAdded = true;
                        set(initialValues, key, value);
                    });
                    if(!isModelLibraryKeyAdded) {
                        set(initialValues, 'model_library', SparkModelLibraryOptions['Spark']);
                    } else if(fieldData.value.model_library === 'Sklearn') {
                        set(initialValues, 'model_library', PythonModelLibraryOptions['Sklearn']);
                        // set(initialValues, 'model_library', PythonModelLibraryOptions['Sklearn']);
                    }
                    const currentModelLibrary = initialValues.model_library;

                    if(fieldData.value.pipeline_category && !isBuildModelKeyAdded) {
                        const _value = fieldData.value.pipeline_category !== PREPROCESSING ? 'true': '';
                        set(initialValues, 'build_model', _value);
                    }
                    if(!isSparkSelectionKeyAdded && currentModelLibrary === SparkModelLibraryOptions['Spark']) {
                        if((!fieldData.value.pipeline_category || fieldData.value.pipeline_category === PREPROCESSING)) {
                            set(initialValues, 'Spark__current_selection', { 
                                activePipelineCategory: PipelineCategoryOptions['Classification'], 
                                modelTypeSelection: { [PipelineCategoryOptions['Classification']]: ClassificationTypes['Random Forest Classification']}
                            });
                        } else if(has(fieldData, 'value.stagesInfo')){
                            const trainStageInfo: MlPipelineStageInfo | undefined = fieldData.value.stagesInfo.find((stage: { operation: OperationOptions }) => stage.operation === OperationOptions['Train Model']);
                            if(trainStageInfo) {
                                const modelTypeKey = getKeyInPipelineStage('model_type', trainStageInfo.id);
                                const modelType = get(fieldData.value, modelTypeKey);
                                set(initialValues, 'Spark__current_selection', { 
                                    activePipelineCategory: fieldData.value.pipeline_category, 
                                    modelTypeSelection: { [fieldData.value.pipeline_category]: modelType}
                                });
                            }
                            
                        }
                    }
                    if(!isStagesInfoNewFormat) {
                        const stagesInfo: MlPipelineStages = getMlPipelineStagesInitialVal();
                        let isPreprocessingStageAdded = false;
                        if(fieldData.value.model_library === SparkModelLibraryOptions['Spark']) {
                            fieldData.value.stagesInfo.forEach((stageInfo: any) => {
                                const newStageInfo = {
                                    ...stageInfo,
                                    touched: true
                                };
                                if(stageInfo.operation === OperationOptions['Train Model']) {
                                    stagesInfo.Spark = [newStageInfo];
                                } else {
                                    if(!isPreprocessingStageAdded) {
                                        stagesInfo.PREPROCESSING_spark = [newStageInfo];
                                        isPreprocessingStageAdded = true;
                                    } else {
                                        stagesInfo.PREPROCESSING_spark.push(newStageInfo);
                                    }
                                }
                            });
                        } else if(fieldData.value.model_library === PythonModelLibraryOptions['Sklearn']) {
                            fieldData.value.stagesInfo.forEach((stageInfo: any) => {
                                if(stageInfo.operation === OperationOptions['Train Model']) {
                                    stagesInfo.sklearn = [{
                                        ...stageInfo,
                                        touched: true
                                    }];
                                }
                            });
                        }
                        set(initialValues, 'stagesInfo', stagesInfo);
                    }

                    if(!isSklearnPreprocessingStagesAdded) {
                        set(initialValues, 'stagesInfo.' + MlPipelineStageKeys.PREPROCESSING_Sklearn, getMlPipelineStagesInitialVal()['PREPROCESSING_sklearn']);
                    }

                    if(fieldData.value.model_library !== SparkModelLibraryOptions['Spark']) {
                        // oldkey = pythonModuleInfoforSklearn
                        // old format2 = pythonModuleInfo: { [pipelineCategory]: value } 
                        // New format = pythonModuleInfo: { stage_4afc8cce-75c5-48d9-a642-9fe14cd7a410: { [pipelineCategory]: value } } 

                        const oldCurrentPythonModule = get(fieldData.value, getModelLibrarySelectionKey(fieldData.value.model_library) + '.pythonModuleInfoForSklearn');
                        const oldFormatTwoPythonModule = get(fieldData.value, getOldFormatPythonModuleKey(fieldData.value.model_library, fieldData.value.pipeline_category));
                        if(typeof(oldCurrentPythonModule) === 'string' || typeof(oldFormatTwoPythonModule) === 'string') {
                            const currentStagesInfo = initialValues.stagesInfo[fieldData.value.model_library.toLowerCase()];
                            if(currentStagesInfo?.length > 0) {
                                const currentStageId = currentStagesInfo[0].id;
                                if(typeof(oldFormatTwoPythonModule) === 'string') {
                                    const pythonModuleInfo = cloneDeep(get(fieldData.value, getModelLibrarySelectionKey(fieldData.value.model_library) + '.pythonModuleInfo'));
                                    // pythonModuleInfo = {"regression": "...", "classification": "..."}
                                    // Value of entire pythonModuleInfo is set here
                                    set(initialValues, getModelLibrarySelectionKey(fieldData.value.model_library) + '.pythonModuleInfo.' + currentStageId, pythonModuleInfo);
                                    unset(initialValues, getOldFormatPythonModuleKey(fieldData.value.model_library, fieldData.value.pipeline_category));
                                } else {
                                    set(initialValues, getCurrentPythonModuleKey(fieldData.value.model_library, currentStageId, fieldData.value.pipeline_category), oldCurrentPythonModule);
                                    unset(initialValues, getModelLibrarySelectionKey(fieldData.value.model_library) + '.pythonModuleInfoForSklearn');
        
                                }
                            }
                            
                        }
                    }
                    if(!isInputTypeKeyAdded) {
                        set(initialValues, inputTypeFieldName, InputTypeOptions['Default (dataframes)']);
                    }
                    set(initialValues, 'fs_type', 'azure_blob')
                } else {
                    const isActiveExecutionEnvPython = activeExecutionEnv === ExecutionEnvModes['Python'];
                    const stagesInfo: MlPipelineStages = getMlPipelineStagesInitialVal();
                    const initialMlPipelineValues = {
                        mode: ModeOptions['Fit Data'],
                        experiment_info: {id: '0', name: 'Default'},
                        // pipeline_category: PipelineCategoryOptions['Preprocessing'],
                        build_model: '',
                        encode_categ_features: '',
                        encode_categ_target: '',
                        split_for_test: '', 
                        split_for_validation: '', 
                        stagesInfo, 
                        data_category: DataCategoryOptions.Other,
                        validate_model: '',
                        validate_model_type: ValidateModelOptions.Holdout,
                        validate_eval_metric: EvalMetricClassOptions.Accuracy,
                        validate_model_train: 0.75,
                        split_for_test_train: 0.75,
                        validate_model_validation: '0.25',
                        output_format: OutputFormatOptions['Vectorized'],
                        model_library: isActiveExecutionEnvPython ? PythonModelLibraryOptions['Sklearn']: SparkModelLibraryOptions['Spark'],
                        num_ip_ports: 1,
                        num_op_ports: 0,
                        num_epochs: 1,
                        [inputTypeFieldName]: InputTypeOptions['Default (dataframes)'],
                        batch_size: 32,
                        split_for_test_type: SplitForTestTypes['Split By Fraction'],
                        label_mode: LabelModeOptions['Int'],
                        color_mode: ColorModeOptions['rgb'],
                        seed: '',
                        validation_files_pattern: '',
                        train_files_pattern: '',
                        fs_type: 'azure_blob',
                        file_type: 'dbfs',
                        validation_steps_per_epoch: '',
                        train_steps_per_epoch: '',
                        use_generator: '',
                        balance_batches: '',
                        sample_from_datasets: '',
                        balance_classes: '',
                        balance_mode: BalanceModes.Oversample,
                        image_col: '',
                        label_col: '',
                        image_size: '',
                        dataset_type: DataSet.Images,
                        auto_resize: 'true',
                        file_location: '',
                        connection_type: 'custom',
                        connection_id: '',
                        uname: '',
                        passwd: '',
                        token_type: 'sas',
                    };

                    if(!isActiveExecutionEnvPython) {
                    //     libraryCurrentSelectionValue =  {
                    //         activePipelineCategory: PipelineCategoryOptions['Classification'],
                    //         modelTypeSelection: { [PipelineCategoryOptions['Classification']]: 'AdaBoostClassifier'}
                    //     };
                    //     _.set(initialMlPipelineValues, 'pipeline_category', PipelineCategoryOptions['Classification']);
                    //     _.set(initialMlPipelineValues, getKeyInPipelineStage('operation', stagesInfo.sklearn[0].id), OperationOptions['Train Model']);
                    //     // _.set(initialMlPipelineValues, getKeyInPipelineStage('operation', stagesInfo.tensorflow[0].id), OperationOptions['Train Model'])
                    //     _.set(initialMlPipelineValues, getKeyInPipelineStage('model_type', stagesInfo.sklearn[0].id), 'AdaBoostClassifier');
                        
                        // } else {
                        const libraryCurrentSelectionValue: CurrentModelLibrarySelection = { 
                            activePipelineCategory: PipelineCategoryOptions['Classification'],
                            // @ts-ignore 
                            modelTypeSelection: { [PipelineCategoryOptions['Classification']]: ClassificationTypes['Random Forest Classification']}
                        };

                        _.set(
                            initialMlPipelineValues, 
                            getModelLibrarySelectionKey(initialMlPipelineValues.model_library), 
                            libraryCurrentSelectionValue
                        )
                    }
                    _.set(initialValues, 'ml-pipeline', initialMlPipelineValues);
                    Object.entries(initialMlPipelineValues).forEach(([key, value]) => {
                        set(initialValues, key, value);
                    });
                }
                
            } else if(fieldData.type === 'keyvaluetable') {
                if(isString(fieldData.value) || !fieldData) {
                    const _value: KeyPairTableValueType = {
                        rows: [],
                        data: {}
                    };
                    try {
                        // parsedValue = "[[\"key1\",\"value1\"],[\"key2\",\"value2\"]]"
                        const parsedValue = JSON.parse(fieldData.value || '[]'); 
                        parsedValue.forEach((rowData: any) => {
                            const rowId = uuid();
                            const [key, value] = rowData;
                            _value.rows.push(rowId);
                            _.set(_value.data, rowId, { key, value });
                        });
                    } catch(e) {
                        /* eslint-disable no-console */
                        console.log(e);
                    }
                    _.set(initialValues, fieldData.key, _value);
                }

            } else if(fieldData.type === 'workflow') {
                if(fieldData.templateOptions.showPropertySelection) {
                    const fieldKey = `extraData.${fieldData.templateOptions.showPropertySelection.key}`;
                    // fieldData.templateOptions.showPropertySelection.key
                    if(!has(initialValues, fieldKey)) {
                        const _initialVal: WorkflowFieldSelectionObj = {
                            componentPropertiesValuesRef: {},
                            componentPropertiesArr: [],
                            workflowInfo: {
                                payload: {
                                    nodes: [],
                                    links: [],
                                    workflow_config: {}
                                },
                                name: "",
                                id: 0
                            }
                        }
                        set(initialValues, fieldKey, _initialVal)
                    }
                }
            } 
        });

        if(has(initialValues, 'extraData.workflow_var_mapping.all_fields_list')) {
            // CONVERTS OLD FORMAT VARIABLE COMPONENTS TO NEW FORMAT
            // OLD_FORMAT
            // "all_fields_list": ["tags", "headers"]
            // "tags":"tags,people"
            // "var1":"headers"
            
            const workflowVarMapping = get(initialValues, 'extraData.workflow_var_mapping');
            const allFieldsList = get(workflowVarMapping, 'all_fields_list');

            
            // new variable 
            if(!isEmpty(allFieldsList)) {
                const updatedFieldsList = [...allFieldsList];
                const updatedWorkflowMapping = {...workflowVarMapping};
                const __workflowMappingWithoutFieldsList = omit(workflowVarMapping, 'all_fields_list');
                Object.entries(__workflowMappingWithoutFieldsList).some(([variableName, fieldIds]) => {
                // new format field ids include _____
                // fieldIds = tags,people (value of variable's multi select dropdown )

                    if(!fieldIds.includes('_____') && fieldIds !== '')  {
                        const __updatedVariableValue: string[]= [];
                        fieldIds.split(',').forEach((_fieldId: string) => {
                            const fieldInfo = formDataObj[_fieldId];
                            if(fieldInfo) {
                                const newFormatFieldId = getVariableUniqueId(fieldInfo.templateOptions.label, fieldInfo.key);
                                updatedFieldsList.push(newFormatFieldId);
                                __updatedVariableValue.push(newFormatFieldId);
                            }
                        });

                        set(updatedWorkflowMapping, variableName, __updatedVariableValue.join(','));
                        
                    }
                });
                

                set(initialValues, 'extraData.workflow_var_mapping', updatedWorkflowMapping);
                set(initialValues, 'extraData.workflow_var_mapping.all_fields_list', updatedFieldsList);
            }


        } else {
            set(initialValues, 'extraData.workflow_var_mapping.all_fields_list', []);
        }

        if(!has(initialValues, 'extraData.workflow_config_mapping')) {
            const workflowConfigMappingInFormikContext: WorkflowConfigMappingInFormikContext = {
                selectedConfigKeys: "",
                selectedFieldKeys: [],
                keyFieldMapping: {}
             }
            set(initialValues, 'extraData.workflow_config_mapping', workflowConfigMappingInFormikContext);

        }
    } catch (e){
        /* eslint-disable no-console */
        console.log(e);
        errorAlert('Error setting initial values for the component');
    }
    return initialValues;
}

export const FieldSchemaValidator = (schema: (yup.StringSchema | yup.NumberSchema)) => (value: any) => (
    schema
        .validate(value)
        .then(() => undefined)
        .catch((error) => error.message)
);

function emptyStringToNull(value: any, originalValue: any) {
    if (typeof originalValue === 'string' && originalValue === '') {
        return null;
    }
    return value;
}

type SchemaCreatorProps = Pick<BaseFieldType['templateOptions'], 'variable_type' | 'required' | 'maxLength' | 'validationType'>

export function FieldSchemaCreator(fieldInfo: SchemaCreatorProps,workflowConfigItems?:WorkflowConfigItem[]|undefined){
    let datatype: 'string' | 'number' = 'string';
    if(fieldInfo.variable_type === 'integer' || fieldInfo.variable_type === 'float'
        || fieldInfo.variable_type === 'number'){
        datatype = 'number';
    }
    let validator = yup[datatype]();
    if(fieldInfo.required){
        validator = validator.required('This is a required field');
    }
    if(fieldInfo.maxLength){
        validator = validator.max(fieldInfo.maxLength, `Length cannot be more than ${fieldInfo.maxLength}`);
    }
    if(fieldInfo.variable_type === 'number' || fieldInfo.variable_type === 'float' || fieldInfo.variable_type === 'integer') {
        validator = validator.typeError('Must be a ' + fieldInfo.variable_type).transform(emptyStringToNull).nullable() as yup.NumberSchema<number>;
    }
    if(fieldInfo.validationType ==="STRING_WITHOUT_QUOTES"){
        validator = validator.test('string without quotes', 'quotes not allowed', (value: string) => {
            return !/["]/.test(value)
        });
    }
    if(fieldInfo.validationType ==="POSITIVE_NUMBER"){
        validator = validator.test('positive number', 'only positive number is allowed', (value: string) => {
            return /^\d*$/.test(value)
        });
    }
    if(fieldInfo.validationType ==="INTEGER_OR_CONFIG_TYPE_INTEGER"){
        validator = validator.test('string or config type', 'value should be a integer', (value) => { 
            let isValidValue = false;
            if (typeof(value) === "string" && value.startsWith("<<")) {
                const regex = /<<([^>]+)>>/g;
                const matches: string[] = [];
                let match;
                while ((match = regex.exec(value)) !== null) {
                    matches.push(match[1]);
                }
                const workflowConfigInteerTypeItems = workflowConfigItems?.filter((item) => item.type === "integer").map((_item) => _item.key);
                matches.forEach((match) => {
                    if(workflowConfigInteerTypeItems?.includes(match)){
                        isValidValue = true;
                    }else{
                        isValidValue = false;
                    }
                })
            }
            return (isValidValue || !isNaN(value))
        });
    }

    return validator;
}