import React from 'react';

export const UnderConstruction = () => {
    return(
        <section className="errorPage underConstructionPage">
            <div className="errorContentBox">
                <svg width="396" height="326" viewBox="0 0 396 326" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.12" d="M343.396 306.342C377.233 293.836 377.569 264.432 365.18 235.995C338.345 174.404 357.546 180.089 375.511 136.406C397.552 82.8185 336.896 63.0385 299.874 85.0731C228.488 127.559 218.022 79.1454 189.646 50.6427C146.792 7.59304 56.4987 25.2452 70.3363 90.9332C89.6562 182.637 23.0119 158.994 15.9823 235.997C12.0178 279.418 35.4584 298.32 52.8699 306.321C89.006 304.721 140.599 303.718 197.879 303.718C255.429 303.718 307.239 304.729 343.396 306.342Z" fill="#3F3D56"/>
                    <path opacity="0.24" d="M343.607 307.548C307.427 305.366 255.585 304 198 304C140.685 304 89.0606 305.355 52.9024 307.516C20.0752 309.479 0 312.111 0 315C0 321.078 88.6487 326 198 326C307.351 326 396 321.078 396 315C396 312.128 376.132 309.51 343.607 307.548Z" fill="#3F3D56"/>
                    <path d="M50.0918 85.7169C50.0918 86.3332 49.909 86.9356 49.5667 87.448C49.2243 87.9604 48.7377 88.3597 48.1683 88.5956C47.599 88.8314 46.9725 88.8931 46.3681 88.7729C45.7637 88.6526 45.2085 88.3559 44.7727 87.9201C44.337 87.4844 44.0402 86.9292 43.92 86.3248C43.7998 85.7204 43.8615 85.0939 44.0973 84.5245C44.3331 83.9552 44.7325 83.4686 45.2449 83.1262C45.7573 82.7838 46.3597 82.6011 46.9759 82.6011C47.8023 82.6011 48.5948 82.9294 49.1792 83.5137C49.7635 84.098 50.0918 84.8905 50.0918 85.7169Z" fill="#373489"/>
                    <path d="M376.319 61.5992C376.319 62.2155 376.137 62.8179 375.794 63.3303C375.452 63.8427 374.965 64.2421 374.396 64.4779C373.827 64.7137 373.2 64.7754 372.596 64.6552C371.991 64.535 371.436 64.2382 371 63.8025C370.565 63.3667 370.268 62.8115 370.148 62.2071C370.027 61.6027 370.089 60.9762 370.325 60.4069C370.561 59.8375 370.96 59.3509 371.473 59.0085C371.985 58.6661 372.587 58.4834 373.204 58.4834C374.03 58.4834 374.822 58.8117 375.407 59.396C375.991 59.9803 376.319 60.7729 376.319 61.5992Z" fill="#373489"/>
                    <path d="M57.7084 161.061C57.3843 161.061 57.0734 160.932 56.8441 160.703C56.6149 160.473 56.4862 160.163 56.4862 159.838V151.204C56.4793 151.039 56.5059 150.875 56.5643 150.72C56.6226 150.566 56.7117 150.425 56.8259 150.306C56.9402 150.187 57.0774 150.092 57.2292 150.027C57.3811 149.963 57.5444 149.929 57.7095 149.929C57.8746 149.929 58.0379 149.963 58.1898 150.027C58.3416 150.092 58.4788 150.187 58.593 150.306C58.7073 150.425 58.7963 150.566 58.8547 150.72C58.9131 150.875 58.9397 151.039 58.9328 151.204V159.838C58.9322 160.163 58.803 160.474 58.5735 160.703C58.3439 160.932 58.0328 161.061 57.7084 161.061Z" fill="url(#paint0_linear)"/>
                    <path d="M62.0148 156.744H53.3808C53.0652 156.73 52.7668 156.596 52.5481 156.368C52.3294 156.14 52.2073 155.836 52.2073 155.52C52.2073 155.204 52.3294 154.901 52.5481 154.673C52.7668 154.445 53.0652 154.31 53.3808 154.297H62.0148C62.1797 154.29 62.3443 154.317 62.4987 154.375C62.6531 154.433 62.7941 154.522 62.9132 154.637C63.0324 154.751 63.1271 154.888 63.1919 155.04C63.2566 155.192 63.29 155.355 63.29 155.52C63.29 155.685 63.2566 155.849 63.1919 156.001C63.1271 156.152 63.0324 156.29 62.9132 156.404C62.7941 156.518 62.6531 156.607 62.4987 156.665C62.3443 156.724 62.1797 156.75 62.0148 156.744Z" fill="url(#paint1_linear)"/>
                    <path d="M171.561 41.3966C171.237 41.3966 170.926 41.2678 170.696 41.0386C170.467 40.8094 170.338 40.4985 170.338 40.1743V31.5403C170.332 31.3754 170.358 31.2108 170.417 31.0564C170.475 30.902 170.564 30.761 170.678 30.6419C170.792 30.5228 170.93 30.428 171.082 30.3633C171.233 30.2985 171.397 30.2651 171.562 30.2651C171.727 30.2651 171.89 30.2985 172.042 30.3633C172.194 30.428 172.331 30.5228 172.445 30.6419C172.56 30.761 172.649 30.902 172.707 31.0564C172.765 31.2108 172.792 31.3754 172.785 31.5403V40.1743C172.785 40.4987 172.655 40.8096 172.426 41.0387C172.196 41.2679 171.885 41.3966 171.561 41.3966Z" fill="#373489"/>
                    <path d="M175.88 37.0795H167.246C167.081 37.0864 166.916 37.0598 166.762 37.0014C166.607 36.9431 166.466 36.854 166.347 36.7398C166.228 36.6255 166.133 36.4883 166.069 36.3365C166.004 36.1846 165.97 36.0213 165.97 35.8562C165.97 35.6911 166.004 35.5278 166.069 35.3759C166.133 35.2241 166.228 35.0869 166.347 34.9727C166.466 34.8584 166.607 34.7694 166.762 34.711C166.916 34.6526 167.081 34.626 167.246 34.6329H175.88C176.195 34.646 176.494 34.7806 176.712 35.0086C176.931 35.2366 177.053 35.5403 177.053 35.8562C177.053 36.1721 176.931 36.4758 176.712 36.7038C176.494 36.9318 176.195 37.0664 175.88 37.0795Z" fill="#373489"/>
                    <path d="M356.97 181.957C356.741 181.728 356.613 181.416 356.613 181.092C356.613 180.767 356.741 180.456 356.97 180.226L363.075 174.123C363.189 174.01 363.323 173.92 363.472 173.858C363.62 173.796 363.779 173.765 363.94 173.765C364.1 173.765 364.26 173.796 364.408 173.857C364.556 173.919 364.691 174.009 364.805 174.122C364.919 174.236 365.009 174.371 365.07 174.519C365.132 174.667 365.164 174.827 365.164 174.987C365.164 175.148 365.132 175.307 365.071 175.455C365.01 175.604 364.92 175.739 364.806 175.852L358.701 181.957C358.471 182.186 358.16 182.315 357.836 182.315C357.511 182.315 357.2 182.186 356.97 181.957Z" fill="#373489"/>
                    <path d="M363.077 181.958L356.972 175.853C356.743 175.623 356.614 175.312 356.614 174.988C356.614 174.664 356.743 174.353 356.972 174.124C357.201 173.894 357.512 173.766 357.837 173.766C358.161 173.766 358.472 173.894 358.701 174.124L364.806 180.227C365.036 180.456 365.165 180.767 365.165 181.091C365.165 181.416 365.036 181.727 364.807 181.957C364.578 182.186 364.267 182.315 363.942 182.315C363.618 182.316 363.307 182.187 363.077 181.958Z" fill="#373489"/>
                    <path d="M273.279 78.9428C273.05 78.7132 272.921 78.4019 272.921 78.0773C272.921 77.7527 273.05 77.4414 273.279 77.2118L279.382 71.1068C279.614 70.8864 279.922 70.7652 280.241 70.7691C280.561 70.773 280.866 70.9017 281.092 71.1277C281.318 71.3536 281.447 71.659 281.451 71.9785C281.455 72.298 281.334 72.6064 281.113 72.8378L275.008 78.9428C274.778 79.1711 274.468 79.2993 274.144 79.2993C273.82 79.2993 273.509 79.1711 273.279 78.9428Z" fill="#373489"/>
                    <path d="M279.387 78.9428L273.282 72.8378C273.052 72.6085 272.923 72.2975 272.923 71.973C272.923 71.6486 273.051 71.3374 273.281 71.1078C273.51 70.8783 273.821 70.7492 274.145 70.749C274.47 70.7488 274.781 70.8775 275.01 71.1068L281.115 77.2118C281.229 77.3253 281.319 77.4601 281.381 77.6085C281.442 77.7569 281.474 77.9159 281.474 78.0766C281.474 78.2372 281.443 78.3963 281.381 78.5447C281.32 78.6932 281.23 78.8281 281.117 78.9417C281.003 79.0554 280.868 79.1456 280.72 79.2072C280.571 79.2687 280.412 79.3005 280.252 79.3006C280.091 79.3007 279.932 79.2691 279.784 79.2077C279.635 79.1464 279.5 79.0563 279.387 78.9428Z" fill="#373489"/>
                    <path d="M120.804 109.195C120.229 109.195 119.666 109.025 119.188 108.705C118.709 108.385 118.337 107.931 118.116 107.4C117.896 106.868 117.839 106.283 117.951 105.719C118.063 105.155 118.34 104.636 118.747 104.229C119.154 103.823 119.672 103.546 120.236 103.433C120.801 103.321 121.386 103.379 121.917 103.599C122.449 103.819 122.903 104.192 123.223 104.67C123.542 105.149 123.713 105.711 123.713 106.286C123.712 107.058 123.406 107.797 122.86 108.343C122.315 108.888 121.575 109.195 120.804 109.195ZM120.804 104.511C120.453 104.511 120.11 104.615 119.818 104.81C119.526 105.005 119.298 105.283 119.164 105.607C119.029 105.931 118.994 106.288 119.063 106.633C119.131 106.977 119.3 107.293 119.549 107.542C119.797 107.79 120.113 107.959 120.458 108.028C120.802 108.096 121.159 108.061 121.483 107.927C121.808 107.792 122.085 107.565 122.28 107.273C122.475 106.981 122.579 106.638 122.579 106.286C122.579 105.816 122.392 105.364 122.059 105.032C121.726 104.699 121.275 104.512 120.804 104.511Z" fill="#373489"/>
                    <path d="M324.116 135.689C323.541 135.689 322.979 135.518 322.5 135.199C322.022 134.879 321.649 134.425 321.429 133.893C321.209 133.362 321.151 132.777 321.263 132.213C321.376 131.648 321.653 131.13 322.06 130.723C322.466 130.316 322.985 130.039 323.549 129.927C324.113 129.815 324.698 129.872 325.23 130.093C325.761 130.313 326.216 130.686 326.535 131.164C326.855 131.642 327.025 132.205 327.025 132.78C327.025 133.551 326.718 134.291 326.173 134.836C325.627 135.382 324.888 135.688 324.116 135.689ZM324.116 131.005C323.765 131.005 323.422 131.109 323.13 131.304C322.838 131.499 322.611 131.776 322.476 132.101C322.342 132.425 322.307 132.782 322.375 133.126C322.444 133.471 322.613 133.787 322.861 134.035C323.109 134.284 323.426 134.453 323.77 134.521C324.115 134.59 324.471 134.555 324.796 134.42C325.12 134.286 325.398 134.058 325.593 133.766C325.788 133.474 325.892 133.131 325.892 132.78C325.891 132.309 325.704 131.858 325.371 131.525C325.038 131.192 324.587 131.005 324.116 131.005Z" fill="#373489"/>
                    <path d="M247.536 9.95757C246.961 9.95798 246.399 9.78799 245.92 9.46907C245.442 9.15015 245.069 8.69659 244.848 8.1657C244.627 7.63481 244.568 7.05039 244.68 6.48626C244.791 5.92213 245.067 5.4036 245.472 4.99614C245.878 4.58869 246.395 4.3106 246.959 4.19699C247.523 4.08339 248.107 4.13937 248.639 4.35786C249.171 4.57636 249.626 4.94756 249.947 5.42459C250.268 5.90162 250.441 6.46308 250.443 7.03806C250.444 7.4207 250.369 7.7998 250.224 8.15369C250.078 8.50757 249.864 8.8293 249.594 9.10045C249.325 9.37161 249.004 9.58688 248.65 9.73395C248.297 9.88103 247.918 9.95701 247.536 9.95757ZM247.536 5.27326C247.185 5.27326 246.841 5.37738 246.549 5.57245C246.258 5.76753 246.03 6.0448 245.896 6.36921C245.761 6.69361 245.726 7.05058 245.795 7.39496C245.863 7.73935 246.032 8.05569 246.28 8.30397C246.529 8.55226 246.845 8.72135 247.189 8.78985C247.534 8.85835 247.891 8.82319 248.215 8.68882C248.54 8.55445 248.817 8.32689 249.012 8.03494C249.207 7.74298 249.311 7.39974 249.311 7.04861C249.312 6.81466 249.267 6.58279 249.178 6.3663C249.09 6.14981 248.959 5.95296 248.794 5.78704C248.629 5.62112 248.433 5.48939 248.217 5.39942C248.001 5.30944 247.77 5.26298 247.536 5.2627V5.27326Z" fill="#373489"/>
                    <path d="M180.358 158.88C180.569 158.737 180.793 158.585 181.012 158.426C181.012 158.426 181.103 158.175 181.223 157.719C182.044 154.781 184.39 143.331 173.881 135.119C163.943 127.344 149.653 128.364 144.804 128.976C144.662 128.994 144.529 129.054 144.42 129.147C144.312 129.241 144.233 129.364 144.193 129.501C144.153 129.639 144.154 129.785 144.196 129.922C144.238 130.058 144.319 130.18 144.429 130.272C147.027 132.421 152.82 137.66 155.497 143.603C158.942 151.262 168.266 166.862 180.358 158.88Z" fill="#7A7C82"/>
                    <path opacity="0.32" d="M180.358 158.88C180.569 158.737 180.793 158.585 181.012 158.426C181.012 158.426 181.103 158.175 181.223 157.719C178.516 154.14 175.535 150.777 172.306 147.66C168.073 143.538 163.527 139.751 158.707 136.333C158.632 136.279 158.546 136.241 158.456 136.22C158.365 136.2 158.272 136.198 158.181 136.214C158.089 136.23 158.002 136.264 157.924 136.315C157.847 136.365 157.779 136.43 157.727 136.507C157.675 136.584 157.638 136.67 157.62 136.761C157.601 136.852 157.601 136.945 157.619 137.036C157.638 137.127 157.674 137.213 157.726 137.29C157.778 137.367 157.844 137.433 157.922 137.483C162.681 140.86 167.17 144.601 171.35 148.672C174.622 151.827 177.634 155.241 180.358 158.88Z" fill="white"/>
                    <path d="M191.075 170.586C191.075 170.586 191.21 170.47 191.447 170.242C193.471 168.295 202.92 158.209 197.668 144.107C192.984 131.534 190.67 123.228 191.462 117.608C191.49 117.431 191.459 117.249 191.373 117.091C191.287 116.933 191.151 116.808 190.986 116.735C190.822 116.663 190.638 116.647 190.463 116.69C190.289 116.734 190.134 116.834 190.022 116.975C182.074 126.897 160.679 157.694 189.874 170.092C190.267 170.265 190.666 170.425 191.075 170.586Z" fill="#6A6C72"/>
                    <path opacity="0.32" d="M191.075 170.586C191.075 170.586 191.211 170.47 191.447 170.242C185.695 155.465 187.461 133.474 187.485 133.2C187.5 133.016 187.442 132.833 187.323 132.692C187.204 132.55 187.033 132.462 186.849 132.446C186.665 132.431 186.482 132.489 186.341 132.608C186.199 132.727 186.111 132.898 186.096 133.082C185.67 139.323 185.71 145.586 186.216 151.821C186.824 159.079 188.048 165.184 189.874 170.098C190.267 170.265 190.666 170.425 191.075 170.586Z" fill="white"/>
                    <path d="M49.0535 291.143C49.0535 291.143 43.1153 288.821 37.9897 284.094C37.9982 284.101 45.3677 286.644 49.0535 291.143Z" fill="#191A2A"/>
                    <path d="M52.2411 294.098C52.2411 294.098 44.7111 292.11 37.2002 292.697C37.2002 292.692 45.355 295.154 52.2411 294.098Z" fill="#191A2A"/>
                    <path d="M48.283 279.349C48.283 279.349 51.6943 286.249 52.5345 293.619C52.5345 293.619 48.5426 286.203 48.283 279.349Z" fill="#191A2A"/>
                    <path d="M53.7929 282.15C53.7929 282.15 57.1051 289.507 57.4872 297.212C57.4977 297.212 53.5628 289.288 53.7929 282.15Z" fill="#191A2A"/>
                    <path d="M59.2287 286.336C59.2287 286.336 62.7541 293.855 63.1341 301.746C63.1341 301.74 58.9522 293.644 59.2287 286.336Z" fill="#191A2A"/>
                    <path d="M66.729 290.235C66.729 290.235 68.7365 298.284 67.5881 306.11C67.5881 306.11 65.0486 297.366 66.729 290.235Z" fill="#191A2A"/>
                    <path d="M57.0208 298.02C57.0208 298.02 49.3367 296.716 41.906 297.97C41.906 297.97 50.2571 299.682 57.0208 298.02Z" fill="#191A2A"/>
                    <path d="M62.1081 302.88C62.1081 302.88 53.6641 301.689 45.8386 303.724C45.8386 303.724 55.0552 305.39 62.1081 302.88Z" fill="#191A2A"/>
                    <path d="M66.3743 307.361C66.3743 307.361 57.8734 306.616 50.1682 309.05C50.1682 309.057 59.465 310.237 66.3743 307.361Z" fill="#191A2A"/>
                    <path d="M69.8573 311.022C69.8573 311.022 65.2616 303.446 49.4629 291.409C49.4629 291.409 66.7098 303.019 71.5228 310.644L69.8573 311.022Z" fill="#191A2A"/>
                    <path d="M71.2867 280.632C71.2867 280.632 67.0816 275.838 64.6941 269.292C64.6941 269.292 70.0729 274.937 71.2867 280.632Z" fill="#191A2A"/>
                    <path d="M72.7579 284.709C72.7579 284.709 66.9822 279.486 60.0413 276.56C60.0413 276.56 66.1526 282.488 72.7579 284.709Z" fill="#191A2A"/>
                    <path d="M76.0109 269.794C76.0109 269.794 75.8758 277.489 73.2392 284.423C73.2392 284.423 73.0956 276.002 76.0109 269.794Z" fill="#191A2A"/>
                    <path d="M79.6211 274.812C79.6211 274.812 79.1862 282.868 75.988 289.889C75.988 289.889 76.14 281.046 79.6211 274.812Z" fill="#191A2A"/>
                    <path d="M82.5279 281.027C82.5279 281.027 82.2091 289.323 78.9265 296.505C78.9265 296.505 78.9286 287.392 82.5279 281.027Z" fill="#191A2A"/>
                    <path d="M87.4021 287.932C87.4021 287.932 85.4896 296.007 80.8792 302.43C80.8792 302.43 82.6355 293.497 87.4021 287.932Z" fill="#191A2A"/>
                    <path d="M75.2027 290.393C75.2027 290.393 68.9753 285.704 61.7979 283.408C61.7979 283.408 68.4306 288.761 75.2027 290.393Z" fill="#191A2A"/>
                    <path d="M77.491 297.054C77.491 297.054 70.5247 292.118 62.6506 290.334C62.6506 290.326 70.075 296.036 77.491 297.054Z" fill="#191A2A"/>
                    <path d="M79.2241 302.986C79.2241 302.986 72.015 298.42 64.0417 297.054C64.0417 297.054 71.7659 302.367 79.2241 302.986Z" fill="#191A2A"/>
                    <path d="M80.6383 307.834C80.6383 307.834 80.0346 298.995 71.5251 281.047C71.5251 281.047 81.5165 299.278 82.2912 308.264L80.6383 307.834Z" fill="#191A2A"/>
                    <path d="M89.2068 299.032C89.2068 299.032 93.5217 285.732 104.089 282.053C104.089 282.053 107.315 291.284 105.312 300.182C103.308 309.08 89.2068 299.032 89.2068 299.032Z" fill="#6A6C72"/>
                    <path d="M97.4146 300.961C97.4146 300.961 98.527 293.064 101.425 287.837C99.9291 292.763 98.9596 297.833 98.5334 302.963L97.4146 300.961Z" fill="white"/>
                    <path d="M94.0791 308.746C97.9718 294.621 111.375 294.231 118.381 291.117C118.381 291.117 120.939 306.133 106.713 313.251C101.035 315.453 94.6069 317.606 94.0791 308.746Z" fill="#6A6C72"/>
                    <path opacity="0.32" d="M99.3606 309.692C99.3606 309.692 105.035 300.794 113.487 297.237C113.487 297.237 102.143 305.246 100.585 311.14L99.3606 309.692Z" fill="white"/>
                    <path d="M86.885 315.287C86.885 315.287 78.8315 307.362 81.428 289.38C81.428 289.38 82.1838 292.587 88.8165 295.688C95.4493 298.789 101.436 305.019 98.3519 315.166L86.885 315.287Z" fill="#7A7C82"/>
                    <path opacity="0.32" d="M94.1932 312.774C94.1932 312.774 86.4374 303.697 83.7036 295.042C83.7036 295.042 84.8921 302.614 93.5704 313.747L94.1932 312.774Z" fill="white"/>
                    <path d="M73.4504 315.641C73.4504 315.641 61.975 313.808 59.864 303.253C59.864 303.253 82.2406 291.432 89.2069 315.286L73.4504 315.641Z" fill="#6A6C72"/>
                    <path opacity="0.32" d="M81.5125 313.61C81.5125 313.61 76.178 306.937 65.8721 304.604C65.8721 304.604 75.8318 307.807 80.2438 313.878L81.5125 313.61Z" fill="white"/>
                    <path d="M196.756 291.054C202.561 294.585 208.35 296.68 214.488 298.406L220.42 312.744H250.318L255.908 298.269C262.011 296.397 268.104 293.802 273.466 290.496L287.949 296.806L308.954 275.31L302.298 260.98C305.831 255.175 307.923 249.387 309.65 243.248L323.988 237.314L323.636 207.781L309.16 202.191C307.288 196.09 304.694 189.998 301.388 184.634L307.7 170.151L286.197 149.155L272.233 156.165C266.427 152.631 260.639 150.539 254.5 148.813L248.566 134.475H218.67L213.078 148.939C206.975 150.812 200.883 153.406 195.519 156.712L180.672 150.045L159.67 171.548L166.324 185.875C162.792 191.681 160.7 197.471 158.971 203.608L144.633 209.542L145.351 239.429L159.826 245.021C161.699 251.122 164.293 257.214 167.599 262.579L161.287 277.062L182.424 297.701L196.756 291.054ZM209.232 249.843C204.042 244.775 200.472 238.283 198.972 231.186C197.473 224.089 198.111 216.707 200.807 209.973C203.503 203.239 208.135 197.456 214.117 193.355C220.1 189.253 227.164 187.018 234.417 186.932C241.67 186.845 248.786 188.911 254.864 192.869C260.943 196.826 265.711 202.498 268.567 209.165C271.423 215.833 272.237 223.198 270.907 230.328C269.577 237.459 266.162 244.034 261.095 249.224C257.741 252.689 253.734 255.455 249.305 257.362C244.877 259.27 240.114 260.282 235.292 260.339C230.471 260.397 225.685 259.499 221.212 257.697C216.74 255.896 212.668 253.226 209.232 249.843Z" fill="#373489"/>
                    <path opacity="0.6" d="M235.151 278.709C265.76 277.985 289.959 253.204 289.598 222.949C289.237 192.694 264.091 168.143 233.838 168.504C203.228 169.228 179.032 194.01 179.391 224.262C179.75 254.515 204.898 279.07 235.151 278.709ZM234.927 259.982C227.733 260.068 220.674 258.019 214.645 254.093C208.615 250.168 203.885 244.543 201.052 237.929C198.219 231.315 197.411 224.01 198.73 216.937C200.05 209.864 203.436 203.341 208.463 198.193C213.489 193.044 219.929 189.502 226.968 188.014C234.008 186.526 241.33 187.158 248.01 189.832C254.69 192.505 260.427 197.099 264.496 203.033C268.565 208.967 270.782 215.974 270.869 223.169C270.94 227.95 270.065 232.697 268.295 237.139C266.525 241.581 263.894 245.629 260.554 249.05C257.213 252.471 253.23 255.198 248.832 257.075C244.434 258.951 239.708 259.939 234.927 259.982Z" fill="#524F9F"/>
                    <path d="M92.9771 217.188C97.2139 218.049 101.13 218.051 105.181 217.76L111.828 225.068L129.723 218.619L129.945 208.748C133.192 206.31 136.278 203.441 138.775 200.304L148.807 200.954L156.731 183.553L149.657 176.412C150.519 172.175 150.521 168.259 150.23 164.21L157.529 157.563L150.943 139.963L141.074 139.74C138.636 136.493 135.767 133.407 132.63 130.909L133.282 120.872L115.881 112.947L109.035 120.152C104.799 119.29 100.883 119.288 96.8318 119.58L90.1864 112.28L72.2893 118.731L72.0634 128.606C68.8167 131.042 65.7304 133.911 63.2331 137.05L52.9082 136.265L44.9814 153.666L52.0575 160.805C51.1962 165.042 51.1941 168.96 51.4854 173.009L44.1982 179.654L51.078 197.387L60.949 197.598C63.3851 200.845 66.254 203.931 69.393 206.428L68.7407 216.46L85.8398 224.251L92.9771 217.188ZM91.5501 189.827C87.3498 187.915 83.8111 184.799 81.3815 180.875C78.9519 176.951 77.7404 172.395 77.9003 167.782C78.0602 163.17 79.5843 158.708 82.2799 154.962C84.9754 151.216 88.7214 148.353 93.044 146.736C97.3666 145.118 102.072 144.819 106.564 145.876C111.057 146.933 115.135 149.298 118.284 152.673C121.432 156.047 123.509 160.28 124.252 164.835C124.995 169.39 124.37 174.063 122.457 178.263C121.197 181.061 119.396 183.581 117.157 185.678C114.918 187.776 112.286 189.409 109.413 190.484C106.539 191.559 103.482 192.055 100.416 191.942C97.3499 191.829 94.3367 191.111 91.5501 189.827Z" fill="#373489"/>
                    <path opacity="0.72" d="M101.712 204.132C121.383 203.665 136.932 187.74 136.702 168.299C136.472 148.859 120.31 133.077 100.868 133.31C81.1978 133.774 65.6482 149.702 65.8783 169.142C66.1084 188.582 82.2702 204.364 101.712 204.132ZM101.569 192.099C96.9453 192.154 92.4092 190.836 88.5345 188.313C84.6597 185.789 81.6203 182.174 79.8007 177.923C77.9811 173.672 77.463 168.977 78.312 164.431C79.1609 159.886 81.3388 155.694 84.5701 152.387C87.8015 149.079 91.9411 146.804 96.4655 145.85C100.99 144.895 105.696 145.304 109.988 147.024C114.28 148.744 117.966 151.698 120.579 155.513C123.192 159.328 124.615 163.832 124.667 168.456C124.711 171.527 124.147 174.576 123.008 177.429C121.87 180.281 120.179 182.881 118.032 185.077C115.886 187.274 113.326 189.025 110.501 190.23C107.676 191.435 104.64 192.069 101.569 192.097V192.099Z" fill="#524F9F"/>
                    <path d="M84.2546 250.971L79.0024 263.637L84.1047 268.619C84.1385 271.5 83.8176 274.741 84.2082 277.274L79.2262 282.376L84.7781 294.915L91.9809 294.831C93.6739 297.196 95.7714 299.244 98.1767 300.879L98.2632 308.084L110.929 313.336L115.913 308.232C118.793 308.198 122.035 308.521 124.556 308.13L129.658 313.112L142.199 307.56L142.113 300.355C144.478 298.663 146.526 296.566 148.163 294.162L155.366 294.075L160.618 281.409L155.516 276.427C155.482 273.546 155.803 270.305 155.412 267.785L160.394 262.68L154.842 250.141L147.639 250.226C145.947 247.86 143.849 245.812 141.444 244.178L141.359 236.973L128.693 231.723L123.711 236.825C120.83 236.859 117.589 236.538 115.067 236.926L109.964 231.944L97.423 237.496L97.5096 244.701C95.1444 246.394 93.0963 248.491 91.4595 250.895L84.2546 250.971ZM104.239 265.501C107.383 257.179 117.06 253.101 125.744 256.601C134.068 259.744 138.144 269.421 134.646 278.106C131.503 286.429 121.826 290.506 113.139 287.008C104.818 283.864 100.742 274.188 104.239 265.501Z" fill="#2A2770"/>
                    <path opacity="0.48" d="M95.749 281.626C100.647 294.596 115.728 300.95 129.265 295.498C142.235 290.6 148.589 275.519 143.137 261.981C138.239 249.011 123.158 242.657 109.62 248.11C96.6504 253.007 90.2963 268.088 95.749 281.626ZM113.139 256.6C121.826 253.1 131.505 257.179 134.646 265.5C138.144 274.187 134.068 283.866 125.746 287.007C117.06 290.505 107.381 286.429 104.239 278.105C100.742 269.418 104.818 259.741 113.139 256.6Z" fill="#524F9F"/>
                    <path d="M205.388 307.608C205.388 307.608 206.196 279.017 216.048 276.906C225.9 274.795 227.308 294.733 216.048 314.435L205.388 307.608Z" fill="#7A7C82"/>
                    <path opacity="0.32" d="M210.64 310.904C215.337 291.615 217.359 284.516 217.378 284.451L217.064 284.36C217.043 284.43 215.02 291.537 210.321 310.825L210.64 310.904Z" fill="white"/>
                    <path d="M201.975 314.429C201.975 314.429 186.963 299.886 192.593 292.85C198.223 285.814 212.295 298.01 216.049 314.429H201.975Z" fill="#6A6C72"/>
                    <path opacity="0.32" d="M207.689 312.383L207.991 312.255C204.267 303.644 196.999 296.785 196.927 296.718L196.701 296.958C196.775 297.026 204.001 303.838 207.689 312.383Z" fill="white"/>
                    <path d="M214.68 314.429C214.68 314.429 218.512 297.894 226.253 299.3C233.994 300.706 226.253 314.313 226.253 314.313L214.68 314.429Z" fill="#6A6C72"/>
                    <path opacity="0.32" d="M226.582 301.663L223.993 311.322L224.313 311.408L226.902 301.749L226.582 301.663Z" fill="white"/>
                    <path d="M283.681 33.9914L283.738 39.4589L285.906 39.4357C286.291 41.3406 287.06 43.147 288.166 44.7448L286.653 46.2901L290.552 50.1131L292.063 48.5679C293.685 49.6402 295.508 50.3707 297.421 50.7147L297.444 52.8827L302.91 52.8236L302.886 50.6556C304.791 50.2708 306.598 49.5015 308.196 48.3948L309.741 49.9083L313.566 46.003L312.021 44.4894C313.092 42.8673 313.822 41.0445 314.168 39.1317L316.336 39.1085L316.276 33.6431L314.108 33.6663C313.722 31.7617 312.953 29.9554 311.848 28.3571L313.359 26.8119L309.454 22.9889L307.942 24.5341C306.321 23.4618 304.498 22.7313 302.585 22.3872L302.561 20.2192L297.096 20.2784L297.119 22.4463C295.214 22.8313 293.407 23.5998 291.808 24.7051L290.263 23.1936L286.44 27.099L287.985 28.6126C286.914 30.2337 286.184 32.0559 285.84 33.9682L283.681 33.9914ZM299.912 27.2953C301.743 27.2741 303.539 27.7965 305.073 28.7964C306.608 29.7962 307.811 31.2287 308.531 32.9124C309.251 34.5962 309.455 36.4557 309.118 38.2556C308.781 40.0555 307.917 41.715 306.637 43.0241C305.356 44.3332 303.716 45.2331 301.924 45.61C300.132 45.9869 298.269 45.8238 296.569 45.1413C294.87 44.4589 293.411 43.2878 292.378 41.7761C291.344 40.2645 290.782 38.4803 290.763 36.6492C290.737 34.1959 291.686 31.8327 293.402 30.0788C295.117 28.3248 297.459 27.3237 299.912 27.2953Z" fill="#373489"/>
                    <path d="M300.035 38.7159C300.463 38.7113 300.88 38.5798 301.234 38.338C301.588 38.0962 301.862 37.7549 302.021 37.3575C302.181 36.96 302.219 36.5241 302.131 36.1048C302.043 35.6856 301.833 35.3019 301.526 35.0023C301.22 34.7027 300.832 34.5006 300.411 34.4215C299.99 34.3424 299.555 34.39 299.161 34.5581C298.767 34.7263 298.432 35.0075 298.198 35.3662C297.964 35.7249 297.841 36.1449 297.846 36.5733C297.849 36.8577 297.908 37.1387 298.019 37.4004C298.131 37.662 298.293 37.899 298.496 38.098C298.7 38.297 298.94 38.454 299.204 38.56C299.468 38.666 299.75 38.719 300.035 38.7159Z" fill="#373489"/>
                    <path d="M317.573 49.6273L317.609 52.8614L318.89 52.8466C319.118 53.9736 319.574 55.0424 320.228 55.9877L319.333 56.9039L321.655 59.1648L322.548 58.2507C323.507 58.8839 324.584 59.3149 325.715 59.5173L325.728 60.8008L328.962 60.767L328.949 59.4836C330.076 59.2562 331.145 58.8016 332.09 58.1473L333.004 59.0424L335.265 56.7308L334.351 55.8358C334.984 54.8774 335.415 53.7999 335.618 52.6693L336.899 52.6545L336.865 49.4204L335.582 49.4352C335.355 48.3081 334.9 47.2392 334.245 46.294L335.14 45.38L332.818 43.117L331.923 44.031C330.964 43.3988 329.887 42.9678 328.757 42.7644L328.742 41.481L325.508 41.5147L325.523 42.7982C324.396 43.0268 323.327 43.4821 322.382 44.1366L321.468 43.2415L319.205 45.551L320.119 46.446C319.487 47.4052 319.056 48.4823 318.852 49.6125L317.573 49.6273ZM327.178 45.665C328.261 45.6538 329.323 45.9639 330.229 46.5561C331.136 47.1483 331.846 47.9961 332.271 48.9922C332.696 49.9883 332.816 51.088 332.616 52.1523C332.416 53.2165 331.905 54.1976 331.147 54.9714C330.39 55.7452 329.42 56.277 328.36 56.4996C327.3 56.7222 326.198 56.6255 325.193 56.2219C324.188 55.8182 323.326 55.1257 322.714 54.2318C322.103 53.3379 321.771 52.2829 321.759 51.2C321.743 49.748 322.305 48.3492 323.32 47.3113C324.336 46.2733 325.722 45.6811 327.174 45.665H327.178Z" fill="#373489"/>
                    <path d="M327.245 52.422C327.499 52.4191 327.746 52.3411 327.955 52.1979C328.164 52.0546 328.326 51.8526 328.42 51.6174C328.514 51.3822 328.537 51.1242 328.484 50.8763C328.432 50.6283 328.307 50.4014 328.126 50.2243C327.945 50.0472 327.715 49.9278 327.466 49.8812C327.217 49.8346 326.96 49.863 326.727 49.9626C326.494 50.0623 326.295 50.2288 326.157 50.4411C326.019 50.6535 325.946 50.902 325.949 51.1554C325.951 51.3238 325.986 51.4902 326.052 51.645C326.118 51.7999 326.214 51.9402 326.334 52.0578C326.455 52.1755 326.597 52.2683 326.754 52.3308C326.91 52.3933 327.077 52.4243 327.245 52.422Z" fill="#373489"/>
                    <path d="M313.036 13.4196L313.093 18.7457L315.204 18.7225C315.582 20.5765 316.331 22.3346 317.408 23.8902L315.931 25.3953L319.73 29.1212L321.208 27.614C322.788 28.6589 324.564 29.3708 326.429 29.706L326.45 31.817L331.776 31.76L331.753 29.649C333.609 29.2744 335.369 28.5252 336.927 27.4472L338.432 28.9249L342.158 25.1251L340.652 23.6474C341.697 22.0675 342.409 20.2912 342.744 18.4269L344.855 18.4058L344.798 13.0798L342.687 13.103C342.311 11.2468 341.561 9.48653 340.484 7.92892L341.961 6.42166L338.162 2.69786L336.684 4.203C335.105 3.15831 333.329 2.44649 331.465 2.111L331.442 0L326.116 0.0570011L326.139 2.168C324.283 2.54329 322.522 3.29319 320.965 4.37188L319.46 2.89419L315.734 6.69398L317.239 8.17168C316.197 9.75252 315.486 11.5285 315.149 13.3922L313.036 13.4196ZM328.852 6.89453C330.636 6.87542 332.385 7.38565 333.878 8.36069C335.372 9.33572 336.543 10.7318 337.243 12.3723C337.943 14.0128 338.141 15.8241 337.812 17.5771C337.482 19.3301 336.641 20.9461 335.393 22.2207C334.145 23.4953 332.548 24.3713 330.802 24.7379C329.056 25.1045 327.241 24.9452 325.586 24.2801C323.931 23.6151 322.51 22.4742 321.504 21.0017C320.497 19.5292 319.95 17.7913 319.931 16.0077C319.918 14.8235 320.139 13.6484 320.58 12.5495C321.022 11.4505 321.675 10.4493 322.504 9.60305C323.332 8.75677 324.319 8.08199 325.408 7.61726C326.498 7.15252 327.668 6.90693 328.852 6.89453Z" fill="#373489"/>
                    <path d="M328.972 18.0212C329.39 18.017 329.797 17.8891 330.141 17.6535C330.486 17.418 330.754 17.0855 330.909 16.6981C331.065 16.3106 331.103 15.8857 331.017 15.477C330.931 15.0683 330.726 14.6942 330.428 14.402C330.129 14.1098 329.751 13.9127 329.341 13.8356C328.93 13.7585 328.506 13.8048 328.122 13.9688C327.738 14.1327 327.411 14.4069 327.183 14.7566C326.955 15.1063 326.835 15.5159 326.84 15.9334C326.846 16.4929 327.074 17.0271 327.474 17.4186C327.874 17.8101 328.413 18.0268 328.972 18.0212Z" fill="#373489"/>
                    <path d="M144.978 23.8141L146.244 20.6329L144.978 20.1347C145.203 18.9385 145.183 17.7088 144.918 16.5206L146.162 15.9823L144.798 12.8433L143.557 13.3837C142.869 12.3793 141.984 11.5253 140.956 10.8737L141.454 9.60712L138.271 8.35107L137.773 9.61767C136.577 9.39193 135.348 9.41204 134.161 9.67678L133.622 8.43551L130.483 9.7971L131.022 11.0405C130.018 11.7274 129.165 12.6116 128.514 13.6391L127.247 13.1409L125.991 16.3243L127.258 16.8225C127.032 18.018 127.052 19.2467 127.315 20.4344L126.074 20.9749L127.437 24.1139L128.679 23.5735C129.366 24.5778 130.251 25.4312 131.279 26.0814L130.781 27.348L133.962 28.604L134.463 27.3374C135.658 27.5638 136.887 27.5445 138.075 27.2804L138.613 28.5217L141.752 27.158L141.212 25.9167C142.215 25.2293 143.069 24.3452 143.721 23.3181L144.978 23.8141ZM133.983 23.869C132.917 23.4485 132 22.7214 131.347 21.7795C130.694 20.8376 130.335 19.7232 130.316 18.5774C130.296 17.4315 130.617 16.3056 131.237 15.342C131.858 14.3784 132.75 13.6203 133.801 13.1637C134.852 12.707 136.015 12.5723 137.142 12.7766C138.27 12.9808 139.312 13.5149 140.136 14.3112C140.96 15.1075 141.53 16.1303 141.773 17.2503C142.016 18.3703 141.921 19.5371 141.501 20.6033C140.937 22.033 139.828 23.1801 138.418 23.7925C137.009 24.4049 135.413 24.4324 133.983 23.869Z" fill="#373489"/>
                    <path d="M136.607 17.2168C136.358 17.1186 136.085 17.0966 135.823 17.1534C135.561 17.2103 135.322 17.3436 135.136 17.5364C134.95 17.7291 134.825 17.9728 134.777 18.2365C134.729 18.5002 134.761 18.7722 134.868 19.018C134.974 19.2638 135.152 19.4724 135.377 19.6174C135.602 19.7625 135.866 19.8375 136.134 19.8329C136.402 19.8283 136.662 19.7444 136.883 19.5917C137.103 19.4391 137.273 19.2245 137.371 18.9752C137.503 18.6407 137.496 18.2677 137.353 17.938C137.21 17.6084 136.941 17.349 136.607 17.2168Z" fill="#373489"/>
                    <path d="M135.275 55.7996L137.342 50.558L135.263 49.7368C135.635 47.768 135.603 45.744 135.168 43.7881L137.213 42.8993L134.971 37.7295L132.926 38.6182C131.792 36.9642 130.333 35.5586 128.638 34.487L129.459 32.4076L124.218 30.3389L123.397 32.4182C121.428 32.0457 119.404 32.078 117.448 32.5132L116.559 30.4697L111.391 32.7137L112.28 34.7593C110.627 35.891 109.221 37.3477 108.149 39.0404L106.07 38.2192L104.001 43.4629L106.08 44.282C105.708 46.2516 105.74 48.2763 106.175 50.2329L104.132 51.1217L106.376 56.2894L108.421 55.4028C109.553 57.0563 111.01 58.4619 112.702 59.534L111.881 61.6133L117.125 63.68L117.946 61.6007C119.915 61.9731 121.939 61.9408 123.895 61.5057L124.781 63.5512L129.951 61.3072L129.06 59.2617C130.714 58.1287 132.12 56.6715 133.194 54.9785L135.275 55.7996ZM117.169 55.8862C115.414 55.1931 113.904 53.9953 112.829 52.444C111.755 50.8927 111.165 49.0577 111.133 47.171C111.102 45.2843 111.63 43.4306 112.652 41.8442C113.674 40.2579 115.143 39.0101 116.874 38.2587C118.605 37.5073 120.52 37.286 122.377 37.6228C124.233 37.9596 125.948 38.8393 127.305 40.1508C128.662 41.4622 129.599 43.1465 129.999 44.9907C130.399 46.8349 130.242 48.7561 129.55 50.5116C129.09 51.6774 128.405 52.7413 127.534 53.6424C126.663 54.5434 125.622 55.2641 124.473 55.7631C123.323 56.2622 122.086 56.53 120.833 56.5511C119.58 56.5722 118.335 56.3463 117.169 55.8862Z" fill="#373489"/>
                    <path d="M121.491 44.9328C121.079 44.7722 120.629 44.7371 120.198 44.8319C119.767 44.9268 119.373 45.1474 119.068 45.4658C118.762 45.7842 118.557 46.1862 118.479 46.6209C118.402 47.0556 118.455 47.5035 118.632 47.9081C118.809 48.3127 119.102 48.6557 119.473 48.8939C119.845 49.1321 120.279 49.2547 120.721 49.2462C121.162 49.2378 121.591 49.0987 121.954 48.8464C122.316 48.5942 122.596 48.2402 122.757 47.8291C122.973 47.2771 122.961 46.6618 122.724 46.1187C122.486 45.5755 122.042 45.149 121.491 44.9328Z" fill="#373489"/>
                    <defs>
                        <linearGradient id="paint0_linear" x1="14514" y1="4309.85" x2="14566.3" y2="4203.85" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#382152"/>
                            <stop offset="1" stopColor="#AD5389"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear" x1="8534.97" y1="-9735.11" x2="8680.55" y2="-10030.2" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#382152"/>
                            <stop offset="1" stopColor="#AD5389"/>
                        </linearGradient>
                    </defs>
                </svg>
                <p className="title">
                    Under Construction
                </p>
                <p className="subtitle">
                    Please try again after some time
                </p>
            </div>
        </section>
    );
};