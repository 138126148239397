import React, { CSSProperties } from "react";
import { Tabs } from 'antd';
import { TabsItemType } from "./types";
import "./styles.scss";

type TabsProps = {
    items: TabsItemType[];
    size?: "default" | "small" | "large";
    onTabChange: (key: string) => any;
    className?: string;
    theme?: string
}

const TabsComponent: React.FC<TabsProps> = ({ items, onTabChange, size = "default", className, theme }) => {
    const tabStyle: CSSProperties | undefined = theme ? {
        background: theme === "dark" ? '#030613' : '#F4F4F5',
        color: theme === "dark" ? '#fff' : '#000',
        cursor: 'context-menu'
    } : undefined;
    return (
        <Tabs defaultActiveKey={items[0].key} onChange={onTabChange} size={size} className={className} style={tabStyle || {}}>
            {items.map((item: TabsItemType) => <Tabs.TabPane tab={<span style={tabStyle ? { color: tabStyle.color } : {}}>{item.label}</span>} className={item.className} key={item.key} style={tabStyle || {}}>{item.children}</Tabs.TabPane>)}
        </Tabs>
    )
}

export default TabsComponent;