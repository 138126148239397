import classNames from "classnames";
import range from "lodash/range";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Table } from ".";
import { Checkbox, CheckboxProps } from "../form/elements";
import styles from "./styles.module.scss";

type RowSelection = Record<number, boolean>;

export type useTableCheckboxColumnType = (arg0: {
	rowCount: number;
	hideSelectAll?: boolean;
	checkboxSize?: CheckboxProps["size"];
	defaultSelected?: boolean;
}) => {
	renderCheckboxColumn: (props?: {
		className?: string;
		headerLabel?: React.ReactNode;
	}) => JSX.Element;
	rowSelection: RowSelection;
	noOfRowsSelected: number;
	setRowSelection: React.Dispatch<React.SetStateAction<RowSelection>>;
	allRowsSelected: boolean;
	rowsPartiallySelected: boolean;
    unSelectAll: () => void;
    selectAll: () => void;
};

export const useTableCheckboxColumn: useTableCheckboxColumnType = ({
	rowCount,
	checkboxSize,
	defaultSelected,
	hideSelectAll = false,
}) => {
	const [rowSelection, setRowSelection] = useState<RowSelection>({});

	useEffect(() => {
		if (defaultSelected) {
			onSelectUnSelectAll();
		}
	}, [defaultSelected, rowCount]);

	const onCheckboxClick = useCallback((rowIndex: number) => {
		setRowSelection((rowSelection) => ({
			...rowSelection,
			[rowIndex]: !rowSelection[rowIndex],
		}));
	}, []);

	const [
		allRowsSelected,
		rowsPartiallySelected,
		noOfRowsSelected,
	] = useMemo(() => {
		const noOfRowsSelected = Object.values(rowSelection).filter(
			(isSelected) => isSelected
		).length;
		return [
			noOfRowsSelected === rowCount,
			noOfRowsSelected !== 0 && noOfRowsSelected !== rowCount,
			noOfRowsSelected,
		];
	}, [rowCount, rowSelection]);

	const unSelectAll = useCallback(() => {
		setRowSelection({})
	}, [])

	const selectAll = useCallback(() => {
		const _selection = range(0, rowCount).reduce(
			(selection, rowIndex) => {
				selection[rowIndex] = true;
				return selection;
			},
			{} as typeof rowSelection
		);
		setRowSelection(_selection);
	}, [rowCount])

	const onSelectUnSelectAll = useCallback(() => {
		if (allRowsSelected || rowsPartiallySelected) {
			unSelectAll()
		} else {
			selectAll()
		}
	}, [allRowsSelected, rowsPartiallySelected, selectAll, unSelectAll]);

	const renderCheckboxColumn = useCallback(
		({
			className = "",
			headerLabel,
		}: Parameters<
			ReturnType<useTableCheckboxColumnType>["renderCheckboxColumn"]
		>[0] = {}) => {
			return (
				<Table.Column
					width={64}
					dataKey=""
					label={
						headerLabel ??
						(!hideSelectAll && (
							<Checkbox
								checked={allRowsSelected}
								onClick={onSelectUnSelectAll}
								size={checkboxSize}
								color="gold"
								partiallySelected={rowsPartiallySelected}
							/>
						))
					}
					cellRenderer={({ rowIndex }) => (
						<Checkbox
							checked={!!rowSelection[rowIndex]}
							onClick={(e) => {
								// stop click propagation to rowClick event handler
								e.preventDefault();
								e.stopPropagation();
								onCheckboxClick(rowIndex);
							}}
							size={checkboxSize}
							color="gold"
						/>
					)}
					className={classNames(styles["tableCheckbox"], className)}
					headerClassName={styles["tableCheckbox"]}
				/>
			);
		},
		[
			hideSelectAll,
			allRowsSelected,
			rowsPartiallySelected,
			onSelectUnSelectAll,
			checkboxSize,
			rowSelection,
		]
	);

	return {
		renderCheckboxColumn,
		rowSelection,
		noOfRowsSelected,
		setRowSelection,
		allRowsSelected,
		rowsPartiallySelected,
        selectAll,
		unSelectAll
	};
};
