
import React, { useEffect, useRef } from 'react';
import styles from './../styles.module.scss';

const Loading:React.FC<{title: string}> = ({title = "Loading"}) => {
    return (
        <p className={styles['loading']}>{title }</p>
    )
}
export default Loading;
		


	

	
	
