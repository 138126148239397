import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Avatar, List } from 'antd';
import Paginate from '@components/paginate';
import moment from 'moment-mini';
import { InPageSpinner } from '@components/spinners/in-page-spinner';
import { isEmpty } from 'lodash';
import { CaretDown } from '@components/coachmark/Icons';
import { DataSourceHandler, queryResponse } from '@api/data-source-handler';
import MonacoEditor from "react-monaco-editor";
import * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';
import styles from './../styles.module.scss';

const options: any = {
    extraEditorClassName: 'codeEditor',
    selectOnLineNumbers: true,
    'contextmenu': false,
    'formatOnPaste': true,
    'accessibilitySupport': 'auto',
    'autoIndent': 'full',
    'highlightActiveIndentGuide': true,
    'quickSuggestions': true,
    'readOnly':true,
    'fontSize':'14px',
    'textAlign':'right',
    renderLineHighlight:'none',
    formatOnType: true,
    minimap: {
        enabled: false,
    },
    letterSpacing: 0.25,
    lineNumbersMinChars: 0,
    lineDecorationsWidth: 0,
    scrollbar:{
        verticalScrollbarSize: 0,
        horizontalScrollbarSize: 0,
        verticalSliderSize: 0,
        horizontalSliderSize: 0,
        useShadows: false,
		verticalHasArrows: true,
		horizontalHasArrows: true,
		vertical: 'hidden',
		horizontal: 'hidden',
		arrowSize: 30 
    },
    glyphMargin: false,
    automaticLayout: true,
    lineNumbers: 'off',
    folding: false,
};

const ShowHistory: React.FC<{}> = () => {
    const [expandedKeys,setExpandedKeys] = useState<string[]>([]);
    const [queryRespoData,setQueryRespoData] = useState<queryResponse | undefined>(undefined);
    const [isQueryRespoLoading,setIsQueryRespoLoading] = useState(false);

    const handlePagination = useCallback((page:number) => {
        setIsQueryRespoLoading(true);
		DataSourceHandler.GetAllQueryResponse(page,(res) => {
			setQueryRespoData(res);
			setIsQueryRespoLoading(false);
		});
	}, []);

    useEffect(()=>{
        handlePagination(1);
    },[])

    return (
        <div className='history-listing'>
            <List
                className={styles["historyModal"]}
                itemLayout="vertical"
                size="large"
                loading={isQueryRespoLoading?{ indicator: <InPageSpinner size="Large" color="white" /> }:false}
                dataSource={queryRespoData?.queries}
                renderItem={(item) => {
                    const height = item.query.split(/\r\n|\r|\n/).length*20 + "px";
                    return(
                        <div>
                            <List.Item
                                key={item.id}
                                className={styles['list_item_container']}
                            >
                                <List.Item.Meta
                                    title={<div className={styles["flex"]}><Avatar size="small"  className={styles["list_item_avatar"]}>{item.createdBy[0]}</Avatar>&nbsp;&nbsp;<div className={styles["list_item_title"]
                                    }>{item.inputText}</div></div>}
                                />
                                <div className={styles['editor_container']}>
                                    <Avatar className={styles["logo"]}>DS</Avatar>
                                    {!isEmpty(item.response) &&<div className={styles['error-text']}>{item.response}</div>}
                                    <div className='editor'>
                                        {isEmpty(item.response) && <MonacoEditor
                                            height={expandedKeys.includes(item.id)?height:'40px'}
                                            width='450px'
                                            language='sql'
                                            theme='vs-dark'
                                            value={item.query}
                                            options={options}
                                        />}
                                    </div>
                                </div>
                            </List.Item>
                            <div className={styles["date-text"]}>{moment(item.created).calendar()}</div>
                            {isEmpty(item.response) &&<div onClick={()=>{
                                        if(expandedKeys.includes(item.id)){
                                            const _new = expandedKeys.filter((x:string)=>x!=item.id);
                                            setExpandedKeys([..._new]);
                                        }else{
                                            setExpandedKeys([...expandedKeys,item.id]);
                                        }
                                    }}  className={styles['button_expand']} >
                                Expand Query
                                <button 
                                    className={classNames(!expandedKeys.includes(item.id) ? '': styles['rotate-180'], 'align-middle', styles['animate'])} 
                                >
                                    <CaretDown /> 
                                </button>
                            </div>}
                        </div>
                    )
                }}
            />
            {queryRespoData &&<div className={styles['pagination']}>
                <Paginate
                    handlePageChange={handlePagination}
                    itemsPerPage={5}
                    startPage={1}
                    totalCount={queryRespoData?.totalItems||0}
                />
            </div>}
        </div>
    );
};

export default ShowHistory;