import { Icons } from "@components/FileExplorer/Icons";
import { Flex } from "@components/ui/Flex";
import { Text } from "@components/ui/Text";
import { ShowWhenTrue } from "@helpers/showwhentrue";
import { isEmpty } from "lodash";
import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { FileListRenderer } from "./FileListRenderer";
import {
	FileListRendererProps,
	FileUploaderProps,
	UploadedFileInfo,
} from "./types";
import { FileUploadUploaderWrapper } from "./ui/FileUploaderWrapper";

const FileUploader: React.FC<FileUploaderProps> = ({
	filesList,
	filesMetaData,
	onDropAccepted,
	onDropRejected,
	maxFiles = 5,
	acceptedFileTypes,
	placeholderIconOnEmpty,
	handleDeleteFile,
}) => {
	const [fileInUploadingState, uploadedFiles] = useMemo(() => {
		const [fileInUploadingState, uploadedFiles]: UploadedFileInfo[][] = [
			[],
			[],
		];
		filesList.forEach((file) => {
			if (filesMetaData[file.id]?.status === "IN_PROGRESS") {
				fileInUploadingState.push(file);
			} else {
				uploadedFiles.push(file);
			}
		});

		return [fileInUploadingState, uploadedFiles];
	}, [filesList, filesMetaData]);

	const { getRootProps, getInputProps } = useDropzone({
		multiple: true,
		accept: acceptedFileTypes,
		onDropAccepted,
		onDropRejected,
		maxFiles: maxFiles - filesList.length,
	});

	const commonFileListProps: Omit<
		FileListRendererProps,
		"title" | "filesList"
	> = {
		filesMetaData,
		handleDeleteFile,
		placeholderIconOnEmpty,
	};

	return (
		<FileUploadUploaderWrapper {...getRootProps()}>
			{isEmpty(fileInUploadingState) && isEmpty(uploadedFiles) ? (
				<Flex
					width="100%"
					height="100%"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					gap="8px"
				>
					<Flex className="file-uploader-placeholder">
						<Icons.txt />
						<Icons.whl />
						<Icons.code />
					</Flex>
					<Flex gap="4px" flexDirection="column" alignItems="center">
						<Text color="#FFF" fontSize="16px" fontWeight="bold">
							Drag &amp; Drop any file or Click to upload
						</Text>
						<Text fontSize="12px" color="#D4D6E0">
							(Up to 5 files at once)
						</Text>
					</Flex>
				</Flex>
			) : (
				<>
					<Flex width="100%" height="100%">
						<Flex width="50%" height="100%" overflowY="scroll">
							<FileListRenderer
								title="Uploaded"
								filesList={uploadedFiles}
								{...commonFileListProps}
							/>
						</Flex>
						<ShowWhenTrue show={!isEmpty(fileInUploadingState)}>
							<Flex
								width="1px"
								height="calc(100% - 16px)"
								bg="#666A8D"
								my="8px"
							/>
						</ShowWhenTrue>
						<ShowWhenTrue show={!isEmpty(fileInUploadingState)}>
							<Flex width="50%" height="100%" overflowY="scroll">
								<FileListRenderer
									title="Uploading"
									filesList={fileInUploadingState}
									{...commonFileListProps}
								/>
							</Flex>
						</ShowWhenTrue>
					</Flex>
				</>
			)}
			<input {...getInputProps()} />
		</FileUploadUploaderWrapper>
	);
};

export default FileUploader;
