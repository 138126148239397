import React, { useState, useMemo, useEffect } from 'react';
import { CanvasTabsComponent } from './canvas-tabs';
import { WorkflowCanvasRenderer } from './workflow-canvas-renderer';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/types';
import { LoseUnsavedChangesModal } from '../modals/lose-unsaved-changes';
import { toggleModal, closeActiveForm, setActiveComponentPropsState, switchWorkflowEditorTab, disableHelpAnimationOfActiveWorkflow, handleRetrieveJobsList, closeActiveComponentNotes } from '../../../store/workflow';
import {  BasicTabInfo, OpenTabsType, updateOpenTabsOfACanvas, WorkflowCanvasTabInfo, closeWorkflowEditorAnalyticsTab } from '../../../store/canvas';
import { JobStatusPanel } from '../JobStatusPanel';
import { setActiveDoc, toggleDocSpinner, toggleHelpTab } from '../../../store/help';
import { BulbIconForHelp } from '../assets/icons';
import { CodeEditor } from './code-editor';
import classnames from 'classnames';
import { useGetActiveTabInfo } from '../../../utils';
import { filterTabsFuncForWorkflowEditorVisualizations } from '../utils';
import { isEmpty } from 'lodash';

export const Canvas: React.FC = () => {
    const { openTabs, activeTab } = useSelector((store: RootState) => store.CanvasReducer.workflowEditor);
    const dispatch = useDispatch();
    const [workflowIdToBeClosed, setWorkflowIdToBeClosed] = useState<BasicTabInfo['id']>(0);
    const {
        isSaved: isActiveComponentFormSaved,
        isNotesSaved,
    } = useSelector((store: RootState) => store.WorkflowReducer.activeComponentPropertiesInfo);
    const jobsList = useSelector((store: RootState) => store.WorkflowReducer.jobsList);
    const activeTabInfo = useGetActiveTabInfo('workflowEditor') as WorkflowCanvasTabInfo  | undefined;
    const { activeExecutionEnv } = useSelector((store: RootState) => store.CommonReducer);

    const getRecentJobs = () => {
        dispatch(handleRetrieveJobsList());
    }; 
    
    const switchWorkflowTab = (workflow: BasicTabInfo) => {
        if (workflow.id !== activeTab.id) {
            if(!isActiveComponentFormSaved){
                dispatch(closeActiveForm());
                return;
            }
            if(!isNotesSaved) {
                dispatch(closeActiveComponentNotes());
                return;
            }
            dispatch(switchWorkflowEditorTab(workflow.id as number));
            //     dispatch(switchToExistingCanvasTab('workflowEditor' ,workflow.id));
            //     if(showJobLogs) dispatch(hideLogsInWorkflowPage());
        }
    };


    const handleCloseWorkflow = (id: BasicTabInfo['id']) => {
        dispatch(closeWorkflowEditorAnalyticsTab('workflowEditor', id as number));
        dispatch(setActiveComponentPropsState({ show: false }));
    };


    const closeWorkflowTab = (workflowTabInfo: BasicTabInfo) => {
        if(!isActiveComponentFormSaved) {
            dispatch(closeActiveForm());
            return;
        }
        if(!isNotesSaved) {
            dispatch(closeActiveComponentNotes());
            return;
        }
        const id = workflowTabInfo.id;
        const selectedTabInfo = openTabs.get(id);
        if (selectedTabInfo && !selectedTabInfo.info.saved) {
            setWorkflowIdToBeClosed(id);
            dispatch(toggleModal('loseUnsavedChanges', true));
        }
        else handleCloseWorkflow(id);
    };

    const handleForceCloseWorkflow = () => {
        handleCloseWorkflow(workflowIdToBeClosed); 
        dispatch(toggleModal('loseUnsavedChanges', false));
    };

    const workflowNameToBeClosed = useMemo(() => {
        const tabInfo = openTabs.get(workflowIdToBeClosed);
        if(tabInfo){
            return tabInfo.info.name;
        }
        return '';
    }, [workflowIdToBeClosed]);

    const workflowDetailsToBeClosed = useMemo(() => {
        const tabInfo = openTabs.get(workflowIdToBeClosed);
        if(tabInfo){
            return isEmpty(tabInfo.info.details);
        }
        return false;
    }, [workflowIdToBeClosed]);

    const handleCreateNewWorkflow = () => {
        if(!isActiveComponentFormSaved) {
            dispatch(closeActiveForm());
        } else if(!isNotesSaved) {
            dispatch(closeActiveComponentNotes());
        } else {
            dispatch(toggleModal('newWorkflow', true));
        }
    };

    const handleUpdateOpenTabs = (__updatedOpenTabs: OpenTabsType) => {
        dispatch(updateOpenTabsOfACanvas('workflowEditor', __updatedOpenTabs));
    };

    const handleShowHelp = () => {
        if(activeTabInfo?.showHelpBulbAnimation && activeTabInfo.helpDocRef) {
            const { docTitle, subHeadingSlug, docData } = activeTabInfo.helpDocRef;
            dispatch(toggleDocSpinner(true));
            dispatch(setActiveDoc({ title: docTitle, subHeadingSlug, data: docData }));
            dispatch(disableHelpAnimationOfActiveWorkflow());
        }
        dispatch(toggleHelpTab());
    };

    useEffect(() => {
        getRecentJobs();
    }, [activeExecutionEnv]);

    const alertUser = (e:any) => {
        e.preventDefault();
        e.returnValue = "";
    };

    useEffect(() => {
        const isWorkflowSaved = Array.from(openTabs.values()).find((item)=>!item.info.saved);
        if(isWorkflowSaved){
            window.addEventListener("beforeunload", alertUser);
        }
        return () => {
          window.removeEventListener("beforeunload", alertUser);
        };
    }, [openTabs]);

    const showCodeEditor = activeTabInfo?.showCodeEditor;
    return(
        <div className="workflow__master__container">
            
            <CanvasTabsComponent 
                handlePlusIconClick={handleCreateNewWorkflow}
                openTabs={openTabs}
                activeTab={activeTab.id}
                switchTab={switchWorkflowTab}
                closeTab={closeWorkflowTab}
                updateOpenTabs={handleUpdateOpenTabs}
                filterTabsFunc={filterTabsFuncForWorkflowEditorVisualizations('workflowEditor', activeExecutionEnv)}
                showSavedStatus
            >
                <div
                    className="helpPanel__outerBox jobStatusIndicator"
                >
                    <button
                        id="btn-toggleHelpPanel"
                        onClick={handleShowHelp}
                    >
                        <BulbIconForHelp animate={activeTabInfo?.showHelpBulbAnimation} /> Help
                    </button>
                </div>
                <JobStatusPanel 
                    jobsList={jobsList} 
                    getRecentJobs={getRecentJobs}
                />
            </CanvasTabsComponent>
            <div
                className={classnames('canvas__master', {
                    'd-none': showCodeEditor
                })}
            >
                <WorkflowCanvasRenderer 
                    getRecentJobs={getRecentJobs}
                />

            </div>
            <CodeEditor />
            <LoseUnsavedChangesModal
                isEmptyWorkflow={workflowDetailsToBeClosed}
                workflowName={workflowNameToBeClosed}
                onSubmit={handleForceCloseWorkflow}
            />
        </div>
    );
};