/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types */
import _ from 'lodash';
import React, { Component } from 'react';
import { Search } from 'semantic-ui-react';
import { Tooltip } from 'antd';

export default class JoinSearchBarSource extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            results: [],
            firstFocus: true,
        };
    }

    componentDidMount() {
        this.resetComponent();
        //this.setState({ value: this.props.selectedColumn });
    }

    resetComponent = () =>
        this.setState({
            isLoading: false,
            results: [],
            //value: '',
        });

    modifyRowTableJoinColumn = (alias, tableName, column) => {
        this.props.query.from.modifyRowTableJoinColumn(
            this.props.rowIndex,
            this.props.joinColumnIndex,
            alias,
            tableName,
            column
        );
        this.props.updateQueryState();
    };

    handleResultSelect = (e, { result }) => {
        this.modifyRowTableJoinColumn(
            result.alias,
            result.tablename,
            result.alias + '.' + result.title
        );
        //this.setState({ value: `${result.tablename}.${result.title}` });
    };

    handleSearchChange = (e, { value }) => {
        this.modifyRowTableJoinColumn(null, null, value);
        //this.setState({ isLoading: true /*value*/ });
    
        setTimeout(() => {
            //if (this.state.value.length < 1) return this.resetComponent();
    
            const re = new RegExp(
                _.escapeRegExp(
                    this.props.columnText.split('.')[1] || this.props.columnText
                ),
                'i'
            );
            const isMatch = result =>
                this.props.columnText ? re.test(result.title) : true;
            
            if(this.props.query.geoSpatial.isGeoSpatialEnabled){
                this.setState({
                    isLoading: false,
                    results: _.filter(
                        this.props.table.fields
                            ? this.props.table.fields.filter((res)=>{
                                return res.name.includes('point_deepiq_idx') || res.name.includes('polygon_deepiq_idx');
                            }).map(column => {
                                return {
                                    tablename: this.props.table.name,
                                    title: column.name,
                                    alias: this.props.tableAlias,
                                };
                            })
                            : [],
                        isMatch
                    ),
                });
            } else {
                this.setState({
                    isLoading: false,
                    results: _.filter(
                        this.props.table.fields
                            ? this.props.table.fields.map(column => {
                                return {
                                    tablename: this.props.table.name,
                                    title: column.name,
                                    alias: this.props.tableAlias,
                                };
                            })
                            : [],
                        isMatch
                    ),
                });
    
            }
    
        }, 100);
    };
    
    handleSearchChangeMousedown = (e, { value }) => {
        //this.setState({ isLoading: true /*value*/ });
        this.modifyRowTableJoinColumn(null, null, value);
    
        setTimeout(() => {
            //if (this.state.value.length < 1) return this.resetComponent();
    
            if(this.props.query.geoSpatial.isGeoSpatialEnabled){
                this.setState({
                    isLoading: false,
                    results: this.props.table.fields.filter((res)=>{
                        return res.name.includes('point_deepiq_idx') || res.name.includes('polygon_deepiq_idx');
                    }).map(column => {
                        return {
                            tablename: this.props.table.name,
                            title: column.name,
                            alias: this.props.tableAlias,
                        };
                    }),
                });
            } else {
                this.setState({
                    isLoading: false,
                    results: this.props.table.fields.map(column => {
                        return {
                            tablename: this.props.table.name,
                            title: column.name,
                            alias: this.props.tableAlias,
                        };
                    }),
                });
            }
        }, 100);
    };

    render() {
        const { isLoading, results } = this.state;

        return (
            <Tooltip
                title={this.props.text}
                mouseLeaveDelay={0}
            >
                <Search
                    size="mini"
                    icon="columns"
                    placeholder={`Input ${this.props.rowIndex + 1} to input ${
                        this.props.rowIndex
                    } join column`}
                    input={{
                        error: !this.props.initial && this.props.error,
                    }}
                    className="column-search-bar"
                    //loading={isLoading}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={_.debounce(this.handleSearchChange, 500, {
                        leading: true,
                    })}
                    onBlur={(e, data) => {
                        this.setState({ firstFocus: true });
                        this.props.query.from.fromJoinRows[
                            this.props.rowIndex
                        ].joinColumns[
                            this.props.joinColumnIndex
                        ].rowTableJoinColumn.initial = false;
                        this.handleSearchChange(e, data);
                    }}
                    minCharacters={0}
                    onFocus={(e, data) => {
                        if (this.state.firstFocus) {
                            this.handleSearchChangeMousedown(e, data);
                            this.setState({ firstFocus: false });
                        } else {
                            this.handleSearchChange(e, data);
                        }
                        e.target.select();
                    }}
                    //onMouseDown={this.handleSearchChangeMousedown}
                    results={results}
                    value={this.props.columnText}
                />
            </Tooltip>
        );
    }
}
