import React, { } from 'react';
import { Col, Row } from 'antd';
import { Capabilities, Example, Limitations} from '@pages/workflow-page/assets/icons';

import styles from './../styles.module.scss';

const moreInfoData={
    "examples":["Explain quantum computing in simple terms","Got any creative ideas for a 10 year old’s birthday","How do I make an HTTP request in Javascript"],
    "capabilities":["Remember what user said earlier in the conversation","Allows user to provide follow-up corrections","Trained to decline inappropriate requests"],
    "limitations":["May occasionally generate incorrect information","May occasionally produce harmful instructions or biased content","Limited knowledge of world and events after 2021"]
};

const MoreInfo: React.FC<{}> = () => {
    return(
        <Row gutter={16} className={styles["moreInfoModal_row"]}>
            <Col span={8}>
                <div>
                    <div className={styles["moreInfoModal_col_title_container"]}>
                        <Example />
                        <div className={styles['moreInfoModal_col_title']}>Examples</div>
                    </div>
                    {moreInfoData["examples"].map((item)=><div key={item} className={styles["moreInfoModal_col_content"]}>{item}</div>)}
                </div>
            </Col>
            <Col span={8}>
                <div>
                    <div className={styles["moreInfoModal_col_title_container"]}>
                        <Capabilities/>
                        <div className={styles['moreInfoModal_col_title']}>Capabilities</div>
                    </div>
                    {moreInfoData["capabilities"].map((item)=><div key={item} className={styles["moreInfoModal_col_content"]}>{item}</div>)}
                </div>
            </Col>
            <Col span={8}>
                <div>
                    <div className={styles["moreInfoModal_col_title_container"]}>
                        <Limitations/>
                        <div className={styles['moreInfoModal_col_title']}>Limitations</div>
                    </div>
                    {moreInfoData["limitations"].map((item)=><div key={item} className={styles["moreInfoModal_col_content"]}>{item}</div>)}
                </div>
            </Col>
        </Row>
    );
};

export default MoreInfo;
