import chroma from 'chroma-js';
import { ColorModes, ColorRamps } from '../../pages/workflow-analytics-page/enums';

type ChromaOptions = {
    colorRamp: ColorRamps;
    colorMode: ColorModes;
    invertColors: boolean;
    noOfClasses: number;
    alpha: number;
}

export const greyRampColors = ['black', 'white']

export const getChromaRef = (options: ChromaOptions): chroma.Scale<chroma.Color> => {
    const colorScale = options.colorRamp === ColorRamps.Grey ? greyRampColors: options.colorRamp; 
      let scale = chroma.scale(colorScale as string);
      if(options.colorMode === ColorModes["Equal Interval"] && Number.isInteger(options.noOfClasses)) {
        scale = scale.classes(options.noOfClasses)
      }
    
      if(options.invertColors) {
        scale = scale.domain([1,0]);
      }

      return scale
}