import React, { useCallback } from "react";
import moment, { Moment } from "moment";
import clsx from "classnames";
import Picker, { RangePicker } from "rc-picker";
import momentGenerator from "rc-picker/lib/generate/moment";
import en_US from "rc-picker/lib/locale/en_US";
import "rc-picker/assets/index.css";
import { RangeValue } from "rc-picker/lib/interface";
import classNames from "classnames";

type DatePickerTypes = 'basic' | 'range';

export type DatePickerValue<T extends DatePickerTypes> = T extends 'basic' ? (Moment | null): RangeValue<Moment> | null;

type useCustomDTController<T extends DatePickerTypes> = (arg0 ?: {
	initialValue?: DatePickerValue<T>
	format?: string
}) => {
	value: DatePickerValue<T>
	setValue: (arg0: DatePickerValue<T>) => void;
};

type CustomDatePickerProps<T extends DatePickerTypes> = Omit<ReturnType<useCustomDTController<T>>, 'setValue'> & {
	label?: string;
	showTime?: boolean;
	placeholder?: T extends 'basic' ? string : [string, string];
	disablePastDates?: boolean;
	disableFutureDates?: boolean;
	containerClassName?: string;
	type: T,
	value: DatePickerValue<T>
	setValue ?: (arg0: DatePickerValue<'basic'>) => void; 
	setRangeValue ?: (arg0: DatePickerValue<'range'>) => void; 
	startRangeLabel ?: string;
	endRangeLabel ?: string;

} & (T extends 'basic' ? { setValue: (arg0: DatePickerValue<'basic'>) => void; }: { setRangeValue: (arg0: DatePickerValue<'range'>) => void; });

function handleDisablePastDates(current: moment.Moment | null) {
	return moment(current).isBefore(moment().subtract(1,"day"))
}

function handleDisableFutureDates(current: moment.Moment | null) {
	// Can select days until today
	if(current) {
		const today = moment()
		const daysDiff = today.diff(current, 'days');
		if(daysDiff === 0) {
			return today.date() !== current.date()
		} 
		return daysDiff < 0
	}
	return false
}

// function handleDisablePastTime(current: Moment | null) {
// 	if (current && current.valueOf() < moment.now()) {
// 		console.log(current);
// 	}
// 	return {};
// }

const useCustomBasicController: useCustomDTController<'basic'> = ({
	initialValue = null,
	format = null
}= {}) => {
	if(format && initialValue?.isValid && !initialValue.isValid()) {
		initialValue = moment(initialValue, format);
	}
	const [value, setValue] = React.useState<DatePickerValue<'basic'>>(
		initialValue || moment()
	);

	return {
		value,
		setValue,
	};
};


const useCustomRangeController: useCustomDTController<'range'> = ({
	initialValue = null
} = {}) => {
	const [value, setValue] = React.useState<DatePickerValue<'range'>>(
		initialValue
	);

	return {
		value,
		setValue,
	};
};

function CustomDateTimePicker<T extends DatePickerTypes>({
	label,
	startRangeLabel,
	endRangeLabel,
	showTime,
	placeholder,
	disablePastDates,
	disableFutureDates,
	containerClassName = "",
	value,
	setValue,
	setRangeValue,
	type
}: CustomDatePickerProps<DatePickerTypes>) {
	const onSelect = useCallback((newValue: Moment) => {
		setValue && setValue(newValue);
	}, [setValue]);

	const onChange = useCallback(
		(newValue: Moment | null, formatString?: string) => {
			setValue && setValue(newValue);
		},
		[setValue]
	);


	const onChangeRange = useCallback((newValue: RangeValue<Moment> | null) => {
		setRangeValue && setRangeValue(newValue);
	}, []);


	const sharedProps = {
		showTime,
		disabledDate: disablePastDates ? handleDisablePastDates : disableFutureDates ? handleDisableFutureDates: undefined,
		dropdownClassName: "customDatePicker",
		locale: en_US,
		generateConfig: momentGenerator,
		className: classNames("customDatePicker__input", {showTime})
	};

	const resetRangeFilter = () => {
		onChangeRange(null);
	}

	const handleRenderPanel = (originPanel: React.ReactNode) => {
		return(
			<div>
				{originPanel}
				<button
					id="btn-clear-range"
					onClick={resetRangeFilter}
					type="button"
				>
					Reset all
				</button>
			</div>
		)
	}

	return (
		<div className={clsx("datePicker__container", containerClassName)}>
			{type === 'basic' ? 
				<>
					{!!label && <label className="inputfield__label">{label}</label>}
					<Picker
						onChange={onChange}
						onSelect={onSelect}
						placeholder={placeholder as string ?? "Select Date"}
						value={value as DatePickerValue<'basic'>}
						{...sharedProps}
					/>
				</>
				:
				<>
				<div className="rangePicker__labels">
					{!!startRangeLabel && <label className="inputfield__label">{startRangeLabel}</label>}
					{!!endRangeLabel && <label className="inputfield__label">{endRangeLabel}</label>}
				</div>
				<RangePicker 
					onChange={onChangeRange} 
					value={value as DatePickerValue<'range'>}
					separator=""
					allowEmpty={[true, true]}
					placeholder={placeholder as [string, string] ?? ["yyyy-mm-dd", "yyyy-mm-dd"]}
					picker="date"
					panelRender={handleRenderPanel}
					{...sharedProps} 
				/>
				</>

			}
			
		</div>
	);
}

CustomDateTimePicker.useCustomBasicController = useCustomBasicController;
CustomDateTimePicker.useCustomRangeController = useCustomRangeController;

export default CustomDateTimePicker;
