import { AzureBlobFileInfo, FileHandler } from "@api/file-handler";
import { File } from "@components/FileExplorer/types";
import { Env } from "@constants/settings";
import { uuid } from "uuidv4";
import { FileStorageController } from ".";

export const Azure: FileStorageController = {
	prefix: Env.azureBlobFileSystemPrefix,

	getFiles: (path, successCallback, errorCallback) => {
		FileHandler.getBlobFilesAndDirectoriesUsingPath(
			path,
			(fileList: AzureBlobFileInfo[]) => {
				const fileArray: File[] = fileList.map((file) => ({
					id: uuid(),
					name: file.name,
					isDir: file.isDirectory,
					size: file.size ? file.size : undefined,
					modDate: file.lastModified
						? new Date(file.lastModified)
						: undefined,
					type: file.isDirectory
						? undefined
						: file.name.split(".").pop(),
					path: "/" + path + "/" + file.name,
				}));
				successCallback(fileArray);
			},
			errorCallback
		);
	},

	deleteFile: (filePath, successCallback, errorCallback) => {
		FileHandler.deleteFileBlob(filePath, successCallback, errorCallback);
	},

	downloadFile: (filePath, fileName) => {
		FileHandler.downloadFileblob(filePath, fileName);
	},

	uploadFile: (
		path,
		requestData,
		successCallback,
		errorCallback,
		uploadProgress
	) => {
		return FileHandler.blobUploadFile(
			path,
			requestData,
			successCallback,
			errorCallback,
			uploadProgress
		);
	},
};
