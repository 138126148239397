import React from 'react';
import { Route, RouteComponentProps, RouteProps, useLocation } from 'react-router-dom';
import { Header } from '../components/header-sidebar/header';
import { LandingPage } from '../components/landing-page';

interface PrivateRouteProps extends RouteProps{
    component: React.ComponentType<RouteComponentProps> | any;
}

function PrivateRoute({ component: Component, ...rest }: PrivateRouteProps): JSX.Element {
    const location = useLocation();
    const isDrillingDataExplorer = location.pathname.includes('data-explorer-drilling-app');
    return (
        <Route 
            {...rest} 
            render={props => (
                <>
                    {!isDrillingDataExplorer && <Header />}
                    <LandingPage />
                    <Component {...props} />
                </>)
            }
        />
    );
}

export { PrivateRoute };