import _ from 'lodash';

export function makeParamURL(searchData: { [key: string]: any }): string {
    const keyArr = Object.keys(searchData);
    let paramURL = '';
    _.map(keyArr, (key: string) => {
        if (searchData[key]) {
            if (paramURL === '') {
                paramURL += '?' + key + '=' + searchData[key];
            } else {
                paramURL += '&' + key + '=' + searchData[key];
            }
        }
    });

    return paramURL;
}