import { CLUSTER_CREATION_COUCHMARK, FILE_BROWSER_COUCHMARK_STATUS, USER_STATUS_COUCHMARK, START_CLUSTER_COUCHMARK, START_FILE_BROWSER_COUCHMARK, CLUSTER_COUCHMARK_STATUS, START_ADMIN_COUCHMARK, SHOW_WORKFLOW_HELP, HIDE_WORKFLOW_HELP} from './constants';
import { CoachMarkReducerState, CoachMarkActionTypes } from './types';

export const clusterReducerInitialState: CoachMarkReducerState = {
    init_status: '',
    cluster_cm_flow_index: 0,
    cluster_cm_complete_index: 0,
    cluster_cm_flow_total: 5,
    filebrowser_cm_flow_index: 0,
    start_cluster_mf: 0,
    start_filebrowser_mf: 0,
    cluster_status: '',
    file_browser_status: '',
    show_progress_ui: 0,
    show_workflow_help: 0,
    workflow_help: false
};

export const CoachMarkReducer = (state: CoachMarkReducerState = clusterReducerInitialState, action: CoachMarkActionTypes): CoachMarkReducerState => {
    switch (action.type) {
        case USER_STATUS_COUCHMARK: {
            let init_status = action.payload.init_status;
            const out = { ...state }
            if(!init_status) {
                init_status = "INIT";
            } 
            out.init_status = init_status;
            
            if(init_status === "INIT") {
                out.cluster_status = 'INIT';
                out.file_browser_status = 'INIT';
            } 

            if(init_status.includes("CLUSTER_COMPLETE")) {
                out.cluster_status = 'COMPLETE';
                out.file_browser_status = 'INIT';
            }
            
            if(init_status.includes("FILE_BROWSER_COMPLETE")) {
                out.cluster_status = 'INIT';
                out.file_browser_status = 'COMPLETE';
            } 
            
            if(init_status.includes("COMPLETE")) {
                out.cluster_status = 'COMPLETE';
                out.file_browser_status = 'COMPLETE';
            }
            
            return out;
        }
        case CLUSTER_CREATION_COUCHMARK: {
            return { 
                ...state, 
                cluster_cm_flow_index: action.payload.cluster_cm_flow_index
            };
        }
        case START_CLUSTER_COUCHMARK: {
            return { 
                ...state, 
                start_cluster_mf: +Date.now()
            };
        }

        case START_FILE_BROWSER_COUCHMARK: {
            return { 
                ...state, 
                start_filebrowser_mf: +Date.now()
            };
        }
        
        case FILE_BROWSER_COUCHMARK_STATUS: {
            return { 
                ...state, 
                file_browser_status:  action.payload.file_browser_status
            };
        }
        case CLUSTER_COUCHMARK_STATUS: {
            return { 
                ...state, 
                cluster_status:  action.payload.cluster_status
            };
        }

        case START_ADMIN_COUCHMARK: {
            return { 
                ...state, 
                show_progress_ui: +Date.now()
            };
        }

        case SHOW_WORKFLOW_HELP: {
            return { 
                ...state, 
                show_workflow_help: +Date.now(),
                workflow_help: true
            };
        }

        case HIDE_WORKFLOW_HELP: {
            return { 
                ...state, 
                workflow_help: false
            };
        }
       
        default:
            return state;
    }
};

