import { DiagramEngine } from '@projectstorm/react-diagrams';
import { AbstractModelFactory } from '@projectstorm/react-canvas-core';
import { DsPortModel } from './ds_portmodel';
import { DS } from '../enums';

export class SimplePortFactory extends AbstractModelFactory<DsPortModel, DiagramEngine> {
    constructor(){
        super(DS.name);
    } 

    generateModel(): DsPortModel {
        return new DsPortModel(false);
    }
}