import React from 'react';
import { Modal, Select, Button } from 'antd';

interface ErrorHandlingModalProps {
  visible: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  onOptionChange: (value: string) => void;
}

const errorOptionsArray = [
  { label: 'Take Remote', value: 'REMOTE' },
  { label: 'Keep Local', value: 'LOCAL' },
  { label: 'Create a local copy', value: 'LOCAL_COPY' }
];

const ErrorHandlingModal: React.FC<ErrorHandlingModalProps> = ({ visible, onSubmit, onCancel, onOptionChange }) => {
  return (
    <Modal
      title="Error Handling"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onSubmit}>
          Submit
        </Button>
      ]}
    >
      <Select
        placeholder="Select an option"
        onChange={(value) => onOptionChange(value as string)} // Cast value to string
        style={{ width: '100%' }}
      >
        {errorOptionsArray.map(option => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </Modal>
  );
};

export default ErrorHandlingModal;
