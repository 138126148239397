import { WorkflowCanvas } from '..';
import { AppDispatch } from '../../../store/types';
import { deleteCanvasEntities, setActiveComponentPropsState } from '../../../store/workflow';
import { DsNodeModel } from './ds_nodemodel';

export const handleDeleteNode = (node: DsNodeModel, dispatch: AppDispatch) => {
    const removeNodeFn = () => {
        node.remove();
        WorkflowCanvas.repaintCanvas();
        dispatch(setActiveComponentPropsState({ isSaved: true }) as any);
    };
    dispatch(deleteCanvasEntities([node], removeNodeFn) as any);
};

export const createNodeTestId = (nodeName: string) => {
    return nodeName.toLowerCase().replace(/\s/g, '-')
}