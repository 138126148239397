import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from '../../../components/modals';
import { ProfileInfo } from '../../../api/workflow-handler';
import _ from 'lodash';
import { getRunWorkflowFormInitialValues, RunWorkflowForm, runWorkflowValidationSchema } from '@pages/workflow-page/modals/run-preview-workflow';
import { addCluster, updateCluster } from '@store/cluster';
import { errorAlert, successAlert } from '@components/toastify/notify-toast';

interface Props {
    showModal: boolean;
    profileInfoForEdit: ProfileInfo | null;
    toggleClose: () => void;
    enableWorkSpaceId: string;
}

export const ClouderaProfileModal: React.FC<Props> = ({ profileInfoForEdit, showModal, toggleClose, enableWorkSpaceId }) => {
    const dispatch = useDispatch();

    const onSuccessHandler = () => {
        successAlert('Cloudera profile added successfully!');
        toggleClose();
    };

    const onFailureHandler = () => {
        errorAlert("Error in adding cloudera profile!");    };


    function handleRunFormSubmit(this: any, values: runWorkflowValidationSchema & { name: string }) {
        const runWorkflowInfo = JSON.stringify(_.omit(values, 'name'));
        const data: any = { clusterName: values.name, command: runWorkflowInfo, workspaceType: 'cloudera', workspaceId : enableWorkSpaceId,clusterId:profileInfoForEdit?.id };

        if (profileInfoForEdit) {
            dispatch(updateCluster(data, onSuccessHandler, onFailureHandler));
        } else {
            dispatch(addCluster(data, onSuccessHandler, onFailureHandler));
        }
    }

    const initialValues = useMemo(() => {
        if (profileInfoForEdit) {
            const commandValues = JSON.parse(profileInfoForEdit.command);
            return { ...commandValues, name: profileInfoForEdit.clusterName };
        } else {
            return getRunWorkflowFormInitialValues();
        }
    }, [profileInfoForEdit, showModal]);

    return (
        <Modal
            isOpen={showModal}
            title={profileInfoForEdit ? 'Edit Profile' : 'Add a New Profile'}
            subtitle={profileInfoForEdit ? profileInfoForEdit.name : ''}
            toggleClose={toggleClose}
            className="profileModal__container"
            showCloseMark
        >
            <RunWorkflowForm
                initialValues={initialValues}
                onSubmit={handleRunFormSubmit as (arg0: runWorkflowValidationSchema) => any}
                toggleClose={toggleClose}
                showSpinner={false}
                submitText="Save"
                profileTab
            />
        </Modal>
    );
};