import React, { useMemo } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import _ from 'lodash';
import { reorder } from '../utils';
import { BasicTabInfo, OpenTabsType, TabTypes } from '../../../store/canvas';
import { TooltipBottom } from '../../../components/tooltips';
import { WorkflowTabIcon, CloseTabIcon } from '../assets/icons';
import { ShowWhenTrue } from '../../../helpers';
import { Env } from '@constants/settings';
import { AddTabBtn } from '@assets/icons';

interface Props {
    handlePlusIconClick?: () => any;
    openTabs: OpenTabsType;
    activeTab: BasicTabInfo['id'];
    switchTab: (arg0: BasicTabInfo) => any;
    closeTab: (arg0: BasicTabInfo) => any;
    updateOpenTabs: (arg0: OpenTabsType) => any;
    hidePlusIcon?: boolean;
    filterTabsFunc?: (arg0: TabTypes) => boolean;
    showSavedStatus: boolean
}


export const CanvasTabsComponent: React.FC<Props> = ({ filterTabsFunc, openTabs, activeTab, updateOpenTabs, switchTab, closeTab, handlePlusIconClick, children, hidePlusIcon = false, showSavedStatus = false }) => {
    // let __openTabsList = useMemo(() => openTabsList, [openTabsList]);

    const __openTabsList: BasicTabInfo[] = useMemo(() => {
        let __openTabsInArray = Array.from(openTabs.values());
        if(filterTabsFunc && Env.dataExplorer) {
            const _data = Env.dataExplorer
            __openTabsInArray = __openTabsInArray.filter(filterTabsFunc).filter(d => d.info.id !== _data)
        }
        return __openTabsInArray.map(tab => ({ id: tab.info.id, name: tab.info.name, saved: tab.info.saved }));
    }, [openTabs, activeTab, filterTabsFunc]);

    function onDragEndCallback(result: any) {
        if (!result.destination) {
            return;
        }
        const updatedTabsList = reorder(__openTabsList, result.source.index,
            result.destination.index) as BasicTabInfo[];
        
        const __updatedOpenTabs: OpenTabsType = new Map();
        updatedTabsList.forEach(_tab =>{
            const tabInfo = openTabs.get(_tab.id);
            tabInfo && __updatedOpenTabs.set(_tab.id, tabInfo);
        });
        updateOpenTabs(__updatedOpenTabs);
    }

    const getItemStyle = (isDragging: any, draggableStyle: any) => ({
        // styles we need to apply on draggables
        ...draggableStyle,
    });

    const handleCloseTab = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, tabInfo: BasicTabInfo) => {
        e.stopPropagation(); 
        closeTab(tabInfo);
    };

    return(
        <>
            <DragDropContext onDragEnd={onDragEndCallback}>
                <Droppable droppableId="droppable" direction="horizontal">
                    {(provided) => (
                        <div 
                            className="workflow__tabs__container__outer" ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            <ul 
                                className='workflow__tabs__container'
                            >
                                {__openTabsList.map((tab: any, index: any) => {
                                    return (
                                        <Draggable key={tab.name + _.toString(index)} draggableId={`${tab.id}`} index={index}>
                                            {(provided, snapshot) => (
                                                <TooltipBottom
                                                    key={tab.name + _.toString(index)}
                                                    title={tab.name}
                                                >
                                                    <li
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                        className={classNames('workflow__tab', activeTab && {'workflow__tab--active' : activeTab === tab.id})}
                                                        onClick={() => switchTab(tab)}
                                                    >
                                                        <div className="workflow__tab__inner">
                                                            <div className="workflow__name__box">
                                                                <WorkflowTabIcon />
                                                                {/* <img src="/icons/workflow_icon.svg" alt="" /> */}
                                                                <span
                                                                    id = {`workflow_tab_${tab.name.replace(/[^a-zA-Z ]/g, '')}`}
                                                                    className={'workflow__name'}
                                                                >
                                                                    {tab.name}
                                                                </span>
                                                            </div>
                                                            <div className={classNames('workflow__state', { '--saved': tab.saved, '--unsaved': !tab.saved && showSavedStatus })} />
                                                            <div className="close_tab_icon">
                                                                <CloseTabIcon
                                                                    onClick={(e) => handleCloseTab(e, tab)}
                                                                />
                                                                {/* <img
                                                                    src="/icons/close_tab.svg"
                                                                    onClick={(e) => handleCloseTab(e, tab)}
                                                                    alt=""
                                                                /> */}
                                                            </div>
                                                        </div>
                                                        <div className="hide__hoverCurve" />
                                                    </li>
                                                </TooltipBottom>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {/* <div className="workflow__add__tab">
                                    <Tooltip
                                        title={'Create Workflow'}
                                        placement="bottom"
                                    >
                                        <img
                                            src="/icons/add_tab.svg"
                                            onClick={() => {
                                                handleToggleModal('newWorkflow', true);
                                            }}

                                            alt=""
                                        />
                                    </Tooltip>
                                </div> */}
                            </ul>
                            {provided.placeholder}
                            <ShowWhenTrue show={!hidePlusIcon}>
                                <div className="jobStatusIndicator">
                                    <TooltipBottom
                                        title={'Create Workflow'}
                                        align={{offset: [0,10]}}
                                    >
                                        <div className='btn-cursor' onClick={handlePlusIconClick}>
                                            <AddTabBtn/>
                                        </div>
                                    </TooltipBottom>
                                </div>    
                            </ShowWhenTrue>
                            
                            {children}
                            
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
};

// export const CanvasTabsComponent = memo(WrapperComponent);