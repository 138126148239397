const templateIndicator = (type) => {

    let handleGrab = document.querySelectorAll(`#${type}Holder .query_position_indicator`);
    if (handleGrab.length > 0) {
        const fromText = document.getElementById(`${type}Text`);
        handleGrab = handleGrab[handleGrab.length - 1];
        const rect = handleGrab.getBoundingClientRect();
        const rect1 = fromText.getBoundingClientRect();
        document.getElementById(`indicator_${type}`).style.height = (Math.abs(rect.y - rect1.y) - rect.height - rect1.height + 5) + 'px';
    }


}

const fromIndicator = () => {
    templateIndicator('from');
}

const selectIndicator = () => {
    templateIndicator('select');
}

const whereIndicator = () => {
    templateIndicator('where');
}

const groupbyIndicator = () => {
    templateIndicator('groupby');
}

const componentbuilderIndicator = () => {
    templateIndicator('componentBuilder');
}

const temp = {
    fromIndicator,
    selectIndicator,
    whereIndicator,
    groupbyIndicator,
    componentbuilderIndicator
};

export default temp;