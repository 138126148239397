import * as React from "react"

export const LimitReachedIcon = () => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity={0.2}
        d="M20.563 4h-9.125a.987.987 0 00-.7.288l-6.45 6.45a.988.988 0 00-.288.7v9.124a.987.987 0 00.288.7l6.45 6.45a.99.99 0 00.7.288h9.124a.987.987 0 00.7-.288l6.45-6.45a.987.987 0 00.288-.7v-9.125a.987.987 0 00-.288-.7l-6.45-6.45a.986.986 0 00-.7-.287z"
        fill="#FBC441"
      />
      <path
        d="M16 10v7"
        stroke="#FBC441"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.563 4h-9.125a.987.987 0 00-.7.288l-6.45 6.45a.988.988 0 00-.288.7v9.124a.987.987 0 00.288.7l6.45 6.45a.99.99 0 00.7.288h9.124a.987.987 0 00.7-.288l6.45-6.45a.987.987 0 00.288-.7v-9.125a.987.987 0 00-.288-.7l-6.45-6.45a.986.986 0 00-.7-.287v0z"
        stroke="#FBC441"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M17 21.5a1 1 0 11-2 0 1 1 0 012 0z"
        fill="#FBC441"
        stroke="#FBC441"
      />
    </svg>
  )
}
