import { NoPayloadType } from '../datasources';
import { ResetReducerState } from '../types';

export const TOGGLE_WORKFLOWS_IN_ANALYTICS = 'TOGGLE_WORKFLOWS_IN_ANALYTICS';
export const RUN_ANALYTICS_ON_ACTIVE_COMPONENT = 'RUN_ANALYTICS_ON_ACTIVE_COMPONENT';
export const SET_PLOTDATA_PROGRESS = 'SET_PLOTDATA_PROGRESS';
export const TOGGLE_MODAL = 'TOGGLE_ANALYTICS_MODAL';
export const SET_SESSION_INFO_FOR_ERROR_MODAL = 'SET_SESSION_INFO_FOR_ERROR_MODAL';
export const WORKFLOW_CLICKED = 'WORKFLOW_CLICKED';
export const CLUSTER_SELECTION_INFO = 'CLUSTER_SELECTION_INFO';

export type ModalTypes = keyof AnalyticsReducerState['showModal'];

export type ToggleWorkflowInAnalytics = {
    type: typeof TOGGLE_WORKFLOWS_IN_ANALYTICS;
    payload: boolean;
}

export type RunAnalyticsOnActiveComponent = {
    type: typeof RUN_ANALYTICS_ON_ACTIVE_COMPONENT;
}

export type SetPlotDataProgress = {
    type: typeof SET_PLOTDATA_PROGRESS;
    payload: number;
}

export type ToggleModal  = {
    type: typeof TOGGLE_MODAL;
    payload: Record<Partial<ModalTypes>, boolean>;
}

export type SessionInfoForErrorModal = {
    type: typeof SET_SESSION_INFO_FOR_ERROR_MODAL;
    payload: string | null; 
}


export type ClusterSelectionInfoInPlot = {
    type: "new" | "error",
    error: string
}

export type SetClusterSelectionInfoInPlot = {
    type: typeof CLUSTER_SELECTION_INFO;
    payload: ClusterSelectionInfoInPlot
}


export type ActionTypes = 
    | ToggleWorkflowInAnalytics 
    | RunAnalyticsOnActiveComponent 
    | ToggleModal 
    | SetPlotDataProgress
    | SessionInfoForErrorModal
    | NoPayloadType<typeof WORKFLOW_CLICKED>
    | ResetReducerState
    | SetClusterSelectionInfoInPlot

export type AnalyticsReducerState = {
    isWorkflowTreeviewMinimized: boolean;
    runAnalyticsOnActiveComponent: boolean;
    plotDataProgress: number;
    showModal: {
        plotSelection: boolean;
        errorLogs: boolean;
        tiffColorPicker: boolean;
        clusterSelection: boolean;
    };
    plotStatementIdForErrorModal: string | null;
    workflowClicked: boolean;
    clusterSelectionInfo: ClusterSelectionInfoInPlot;
}