import { Job } from '../../api/analytic-flow-handler';

export const EXECUTION_ANALYTICS_FLOW_ALL = 'EXECUTION_ANALYTICS_FLOW_ALL';

export type ExecutionAnalyticsFlowAllPayload = { 
    totalCount: number; 
    data: Job[]; 
    jobFrequency: any[]; 
    hourlyBarchart: number[]; 
    dailyBarchart: number[];
    weeklyBarchart: number[];
    monthlyBarchart: number[];
    totalBarchart: number[];
    is_loaded?: boolean;
 };

export type ActionTypes = {
    type: typeof EXECUTION_ANALYTICS_FLOW_ALL;
    payload: ExecutionAnalyticsFlowAllPayload;
}

export type AnalyticsFlowReducerState = {
    jobs: ExecutionAnalyticsFlowAllPayload;
}