import { errorAlert } from "@components/toastify/notify-toast";
import { WorkflowCanvas } from "@components/workflow-canvas";
import { DsNodeModel } from "@components/workflow-canvas/node/ds_nodemodel";
import { get, difference, has, range, isNil } from "lodash";
import { getWorkflowConfigNumberDataType } from "../WorkflowActionHandler/utils";
import {
	WorkflowConfigItemType,
	WorkflowConfigSelectionType,
	WORKFLOW_CONFIG_SELECTION_KEY,
} from "./enums";
import {
	CommonKeysInfoForGlobalArguments,
	ComponentWorkflowConfigItemInfoForPayload,
	GlobalPipelineConfigMap,
	WorkflowConfig,
	WorkflowConfigInComponentItemInfo,
	WorkflowConfigItem,
	WorkflowConfigItemInfoForPayload,
	WorkflowConfigNArgsPayload,
	WorkflowConfigSelectionInFormikContext,
} from "./types";

export function getWorkflowConfigSelectionKey(
	format: "new" | "old" = "new",
	fieldKey: string
): string {
	if (format === "new") {
		return `${fieldKey}.${WORKFLOW_CONFIG_SELECTION_KEY}`;
	}
	return `${fieldKey}.workflowConfigSelection`;
}

export const getUniqueKeyForWorkflowConfigItem = (
	configItem: WorkflowConfigItem
) => configItem.key + "______________" + configItem.type;

export const getGlobalValueInfoForConfigItem = (globalPipelineConfig: GlobalPipelineConfigMap, configItem: WorkflowConfigInComponentItemInfo) => {
	const globalValue = get(
		globalPipelineConfig,
		getUniqueKeyForWorkflowConfigItem(
			configItem
		)
	)?.value;
	const hasGlobalValue = !isNil(globalValue)
				

	return {
		globalValue,
		hasGlobalValue,
		isGloballySelected: hasGlobalValue && !!configItem.usesGlobalValue
	}
}

export const convertNArgsDataToPayload = (
	nodesData: {
		[id: string]: DsNodeModel;
	},
	globalPipelineConfig: WorkflowConfig<"workflow"> | undefined
): WorkflowConfigNArgsPayload => {
	const nArgsComponentRef: Record<string, WorkflowConfig<"component">[]> = {};
	let maxNArgs = 0;
	const _payload: WorkflowConfigNArgsPayload = [];

	const globalPipelineConfigMapping =
		globalPipelineConfig?.reduce((acc, config) => {
			acc[getUniqueKeyForWorkflowConfigItem(config)] = config;
			return acc;
		}, {} as GlobalPipelineConfigMap) || {};

	try {
		Object.entries(nodesData).forEach(([nodeId, nodeInfo]) => {
			const savedFormData = nodeInfo.extras.formData;
			const extraData = nodeInfo.extras.extraData || {};

			savedFormData.some((field) => {
				if (field.type === "workflow") {
					const newFormatWorkflowConfig = get(
						extraData,
						field.key + "." + WORKFLOW_CONFIG_SELECTION_KEY
					) as WorkflowConfigSelectionInFormikContext;
					const oldFormatWorkflowConfig = get(
						extraData,
						field.key + ".workflowConfigSelection"
					);

					const workflowConfigRef =
						newFormatWorkflowConfig?.type ===
						WorkflowConfigSelectionType["Multiple Arguments"]
							? newFormatWorkflowConfig.nArgsConfig
							: newFormatWorkflowConfig?.type ===
							  WorkflowConfigSelectionType.Default
							? [newFormatWorkflowConfig.config]
							: oldFormatWorkflowConfig
							? [oldFormatWorkflowConfig]
							: [];

					nArgsComponentRef[nodeId] = workflowConfigRef;

					if (workflowConfigRef.length > maxNArgs) {
						maxNArgs = workflowConfigRef.length;
					}

					return true;
				}

				return false;
			});
		});

		if (maxNArgs) {
			const componentIdsList = Object.keys(nArgsComponentRef);

			range(0, maxNArgs).forEach((argIndex) => {
				const componentConfigs = componentIdsList.reduce(
					(componentConfigs, componentId) => {
						const componentNArgsConfig =
							nArgsComponentRef[componentId][argIndex] ||
							nArgsComponentRef[componentId]?.[0] ||
							[];
						componentConfigs[
							componentId
						] = componentNArgsConfig.reduce(
							(componentArgItemsInfo, configItemInfo) => {
								let value = configItemInfo.value;
								if (configItemInfo.usesGlobalValue) {
									// use global value if item has global value selection
									// Check global mapping to make sure current config item has value
									value =
										globalPipelineConfigMapping[
											getUniqueKeyForWorkflowConfigItem(
												configItemInfo
											)
										]?.value ?? value;
								}
								componentArgItemsInfo[configItemInfo.key] = {
									value,
									type:
										configItemInfo.type ===
										WorkflowConfigItemType.Number
											? getWorkflowConfigNumberDataType(
													configItemInfo.value as number
											  )
											: configItemInfo.type,
								} as WorkflowConfigItemInfoForPayload;
								return componentArgItemsInfo;
							},
							{} as ComponentWorkflowConfigItemInfoForPayload
						);

						return componentConfigs;
					},
					{} as WorkflowConfigNArgsPayload[0]
				);

				_payload.push(componentConfigs);
			});
		}
	} catch (err) {
		/* eslint-disable no-console */
		console.log(err);
		errorAlert("Error generating payload");
	}

	return _payload;
};

export const getWorkflowConfigOfAllComponents = (
	workflowCanvas = WorkflowCanvas,
	currentGlobalArguments: WorkflowConfig | undefined
) => {
	let updatedGlobalArguments = currentGlobalArguments
		? [...currentGlobalArguments]
		: [];

	type WorkflowConfigOfAllComponents = Record<
		string,
		WorkflowConfigItem & { count: number }
	>;
	// loop through all components of the workflow and
	// create a mapping of all workflow config
	const workflowConfigOfAllComponents: WorkflowConfigOfAllComponents = Object.values(
		workflowCanvas.getAllNodes()
	).reduce((worklowArgumentMapping, node) => {
		const { extraData, formData } = node.extras;
		const workflowFieldKey = formData.find(
			(field) => field.type === "workflow"
		)?.key;
		if (workflowFieldKey) {
			// check new format first
			let workflowConfig: WorkflowConfig | undefined = (get(
				extraData,
				getWorkflowConfigSelectionKey("new", workflowFieldKey)
			) as WorkflowConfigSelectionInFormikContext)?.config;
			// then old format
			if (!workflowConfig) {
				workflowConfig = get(
					extraData,
					getWorkflowConfigSelectionKey("old", workflowFieldKey)
				);
			}

			if (workflowConfig) {
				workflowConfig.forEach((configItem) => {
					// Two different components can have same key with different type
					// Hence type is also used to generate the key
					const itemKey = getUniqueKeyForWorkflowConfigItem(
						configItem
					);

					if (has(worklowArgumentMapping, itemKey)) {
						worklowArgumentMapping[itemKey].count += 1;
					} else {
						worklowArgumentMapping[itemKey] = {
							...configItem,
							count: 1,
						};
					}
				});
			}
		}
		return worklowArgumentMapping;
	}, {} as WorkflowConfigOfAllComponents);

	// Remove unused keys in global arguments if the keys dont exist in the components.
	updatedGlobalArguments = updatedGlobalArguments.filter((configItem) =>
		has(
			workflowConfigOfAllComponents,
			getUniqueKeyForWorkflowConfigItem(configItem)
		)
	);

	const commonKeysInfoForGlobalArguments: CommonKeysInfoForGlobalArguments = [];

	Object.entries(workflowConfigOfAllComponents).forEach(
		([uniqueConfigItemKey, allComponentsConfigItem]) => {
			// Show keys which are repeated in more than one component
			// And not added as config
			if (
				allComponentsConfigItem.count > 1 &&
				!updatedGlobalArguments.some(
					(__configItem) =>
						uniqueConfigItemKey ===
						getUniqueKeyForWorkflowConfigItem(__configItem)
				)
			) {
				commonKeysInfoForGlobalArguments.push({
					label: allComponentsConfigItem.key,
					value: allComponentsConfigItem.key,
					configItem: allComponentsConfigItem,
				});
			}
		}
	);

	return {
		globalArgumentsConfig: updatedGlobalArguments,
		commonKeysInfoForGlobalArguments,
	};
};

export const validateWorkflowConfig = (
	currentWorkflowConfig:
		| WorkflowConfig<"component">
		| WorkflowConfig<"workflow">,
	latestWorkflowConfig: WorkflowConfig,
	configItemPositionMapping: Record<string, number>,
	globalPipelineConfig: GlobalPipelineConfigMap = {}
) => {
	const workflowConfig: WorkflowConfig<"component"> = new Array(
		latestWorkflowConfig.length
	);

	const _addedItemIndices: number[] = [];

	let areKeysAddedorDeleted = false;
	let areValuesUpdated = false;
	let isGlobalPipelineConfigUpdated = false

	currentWorkflowConfig.forEach((workflowConfigItem) => {
		// config keys positioning is changed if an existing key is deleted
		if (has(configItemPositionMapping, workflowConfigItem.key)) {
			const workflowConfigPosition = get(
				configItemPositionMapping,
				workflowConfigItem.key
			);

			// make sure current config item still exists in global pipeline config
			const {
				hasGlobalValue,
				isGloballySelected
			 } = getGlobalValueInfoForConfigItem(globalPipelineConfig, workflowConfigItem as WorkflowConfigInComponentItemInfo)


			if((workflowConfigItem as WorkflowConfigInComponentItemInfo).usesGlobalValue && !hasGlobalValue) {
				// it had global value and the key was deleted from the global pipeline config
				isGlobalPipelineConfigUpdated = true
			}

			workflowConfig[workflowConfigPosition] = {
				...workflowConfigItem,
				usesGlobalValue: isGloballySelected,
			};

			if (
				workflowConfigItem.value !==
				latestWorkflowConfig[workflowConfigPosition].value
			) {
				areValuesUpdated = true;
			}

			_addedItemIndices.push(workflowConfigPosition);
		} else {
			areKeysAddedorDeleted = true;
		}
	});

	// missingConfigIndices is list of newly added config items
	const missingConfigIndices = difference(
		range(0, latestWorkflowConfig.length),
		_addedItemIndices
	);

	// missing config index (newly added key) is added in workflow config
	missingConfigIndices.forEach((missingIdx) => {
		areKeysAddedorDeleted = true;
		workflowConfig[missingIdx] = {
			...latestWorkflowConfig[missingIdx],
			usesGlobalValue: false
		};
	});

	// converts number data type to integer/decimal
	workflowConfig.forEach((configItem) => {
		if (configItem.type === WorkflowConfigItemType.Number) {
			configItem.type = getWorkflowConfigNumberDataType(
				configItem.value as string | number
			);
		}
	});

	return {
		areKeysAddedorDeleted,
		areValuesUpdated,
		workflowConfig,
		isGlobalPipelineConfigUpdated
	};
};
