import { rootReducer } from '.';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { PersistedReduxState } from './utils';

//azkaban APIs actions
export const EXECUTION_ANALYTICS_FLOW_ALL = 'EXECUTION_ANALYTICS_FLOW_ALL';
export const INGESTION_LOG = 'INGESTION_LOG';

//datasource APIs actions
export const GET_DATA_SOURCE = 'GET_DATA_SOURCE';

export type ActionTypes = { 
    type: typeof EXECUTION_ANALYTICS_FLOW_ALL |
        typeof INGESTION_LOG |
        typeof GET_DATA_SOURCE;
    payload: any;
};

export const RESET_STATE = 'RESET_STATE';
export const LOAD_INITIAL_STATE = 'LOAD_INITIAL_STATE';

export type ResetReducerState = { type: typeof RESET_STATE }
export type LoadInitialState = { type: typeof LOAD_INITIAL_STATE, payload: PersistedReduxState }

export type RootState = ReturnType<typeof rootReducer>  
export type AppDispatch = ThunkDispatch<RootState, void, Action>;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    null,
    Action<string>
>