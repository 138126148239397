import { cloneDeep, isEmpty } from 'lodash';
import { RESET_STATE } from '../types';
import { AccountReducerState, ActionTypes, GET_USER_INFO, SET_ACTIVE_SESSIONS, SET_ALL_USERS_LIST, SET_USER_DIRECTORIES } from './types';

const initialState: AccountReducerState = {
    activeUserInfo: {
        id: '',
        username: '',
        name: '',
        email: '',
        isAdmin: false
    },
    allUsersList: [],
    userDirectories: {
        spark: {
            defaultDirectoryId: null,
            directories: []
        },
        python:  {
            defaultDirectoryId: null,
            directories: []
        },
        dag:  {
            defaultDirectoryId: null,
            directories: []
        },
        streaming: {
            defaultDirectoryId: null,
            directories: []
        }
    },
    activeSessionsInfo: null
};


export const AccountReducer = (state = initialState, action: ActionTypes): AccountReducerState => {
    switch(action.type){
        case GET_USER_INFO:
            return { ...state, activeUserInfo: action.payload };
        case SET_ALL_USERS_LIST:
            return {...state, allUsersList: action.payload };
        case SET_ACTIVE_SESSIONS:
            return {...state, activeSessionsInfo: action.payload };
        case SET_USER_DIRECTORIES: {
            const currentDirectoriesInfo = state.userDirectories[action.key];
            let defaultDirectoryId = currentDirectoriesInfo.defaultDirectoryId;
            if(!defaultDirectoryId) {
                defaultDirectoryId = action.payload.find(dir => dir.name.toLowerCase() === 'default directory')?.id || null;
                if(!defaultDirectoryId && !isEmpty(action.payload)) {
                    defaultDirectoryId = action.payload[0].id;
                }
            }
            return {
                ...state, 
                userDirectories: { ...state.userDirectories, [action.key] : { directories: action.payload, defaultDirectoryId } }
            };
        }
        case RESET_STATE:
            return cloneDeep(initialState);
        default:
            return state;
    }
};