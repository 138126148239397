import { KeycloakService } from '@services/keycloak';
import _axios from 'axios';
import { Env } from '@constants/settings';

const licenseId = Env.keycloakUrl?.split(".")[1];

export function _Axios(cors = false): typeof _axios {
    // _axios.defaults.baseURL = API.url;
    _axios.defaults.headers.common['Authorization'] = 'Bearer ' + KeycloakService.getToken();
    // _axios.defaults.headers.common['Licenseid'] = licenseId;
    _axios.defaults.timeout = 90000;
    _axios.defaults.withCredentials = cors || false;
    return _axios;
}