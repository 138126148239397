import React, { useMemo } from "react"
import { useDispatch } from "react-redux"
import { Modal } from "../../../components/modals"
import { toggleDataBrowserModal } from "../../../store/datasources"
import { useAppSelector } from "../../../store/hooks"
import { DownloadIcon } from "../icons"
import { PiRequestInfo } from "../pi-managed-requests-table";
import clsx from "classnames";
import styles from '../styles.module.scss';
import { saveTextToFile } from "../../../utils"

export type SelectedPropertyForRequestLogModal = 'warning' | 'error'

type RequestLogsModalProps = {
    name: string,
    logs: string[];
    property: SelectedPropertyForRequestLogModal;
}


export const RequestLogsModal: React.FC<RequestLogsModalProps> = ({name, logs, property }) => {
    const showModal = useAppSelector((store) => store.NewDataSourceReducer.showModal.requestLogs)
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(toggleDataBrowserModal('requestLogs', false))
    }

    const downloadLogs = () => {
        if(logs?.length)
            saveTextToFile(
                `request_${name}_${property}_${Date.now()}.log`,
                JSON.stringify(logs)
            );
    }
    

    return (<Modal
        isOpen={showModal}
        toggleClose={handleClose}
        title="Error Logs"
        subtitle=""
        className="error__container"
        showCloseMark
    >
        <div className="log_wrapper">
            <pre
                className="output__text"
            >
                {(logs).map((_text, index) =>
                    <code key={index}>
                        {_text}
                    </code>
                )}
            </pre>
        </div>
        <div className={styles["modalAction__btns"]}>
                <button
                    className={clsx("btn btn-md btn-grey-transparent", styles["download__btn"])}
                    type="button"
                    onClick={downloadLogs}
                >
                    <DownloadIcon /> Download
                </button>
                <button
                    className="btn-md btn-grey-transparent"
                    type="button"
                    onClick={handleClose}
                >
                    Close
                </button>

            </div>
    </Modal>)
}