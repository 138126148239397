import Form, { SelectField } from "@components/form";
import { Modal } from "@components/modals";
import { toggleAnalyticsModal } from "@store/analytics";
import { useAppSelector } from "@store/hooks";
import { useGetRunPreviewClusters } from "@utils/useGetRunPreviewClusters";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import styles from '../styles.module.scss';
import { isEmpty } from "lodash";

const formSchema = yup.object().shape({
	clusterId: yup.string().required("Cluster must be selected"),
});

type FormType = yup.InferType<typeof formSchema>;

type Props = {
	onClusterSubmit: (clusterId: string) => void;
	onCancel: () => void;
}

const SelectClusterModal: React.FC<Props> = ({ onClusterSubmit, onCancel }) => {
	const showModal = useAppSelector(
		(store) => store.AnalyticsReducer.showModal.clusterSelection
	);
	const dispatch = useDispatch();
	const clusters = useGetRunPreviewClusters();
	const [initialValues, setInitialValues] = useState<FormType>({
		clusterId: "",
	});

    const clusterSelectionInfo = useAppSelector(store => store.AnalyticsReducer.clusterSelectionInfo)

	useEffect(() => {
		if(showModal) {
			setInitialValues({ clusterId: clusters?.[0]?.value || '' })
		}
	}, [showModal, clusterSelectionInfo])


	const toggleClose = () => {
		dispatch(toggleAnalyticsModal("clusterSelection", false));
	};

	const handleSubmit = (values: FormType) => {
		onClusterSubmit(values.clusterId)
    };

    const isErrorType = clusterSelectionInfo.type === 'error' ;

	const handleCancel = () => {
		onCancel();
		toggleClose()
	}

	return (
		<Modal
			isOpen={showModal}
			toggleClose={handleCancel}
			title={""}
            className={classNames(styles["selectCluster__modal"], { [styles["selectCluster__error"]]: isErrorType })}
		>
			<Form
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validationSchema={formSchema}
				enableReinitialize
			>
                <div className={styles["selectCluster__header"]}>
                    <span className={styles["title"]}>{isErrorType ? "Do you want to create plot on another cluster?":"Choose a cluster"}</span>
                    <span className={styles["subtitle"]}>{isErrorType ? `${clusterSelectionInfo.error || "Visualization cannot be generated as the session is not found"}. Change the cluster and try again.`:isEmpty(clusters)?"Please select/start a cluster to proceed":"You must choose a cluster to proceed"}</span>
                </div>
				<SelectField
					name="clusterId"
					options={clusters}
					label={isErrorType ? "Choose Cluster":""}
				/>
                <div className={styles["modalBtns__box"]}>
                    <button
                        className={classNames("btn-md", styles["btn-cancel"])}
                        type="button"
                        onClick={handleCancel}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn-md btn-yellow"
                    >
                        Continue
                    </button>
                    
                </div>
			</Form>
		</Modal>
	);
};

export default SelectClusterModal;
