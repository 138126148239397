
import React from 'react';
import styles from './../styles.module.scss';

const Loading: React.FC<{ title: string, color: string }> = ({ title = "Loading", color = "#000" }) => {
    return (
        <p className={styles['loading']} style={{ color: color }}>{title}</p>
    )
}
export default Loading;
