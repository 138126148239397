import { SelectField, _selectoptionType } from "@components/form/select-field";
import React, { memo, useRef, useState } from "react";
import { FieldSchemaValidator } from "../schema-creator";
import { InputField } from '../../form';

import NewExperiments from "./NewExperiments";
import { stringFieldSchema } from "./utils";
import { BaseFieldType } from "../types";
import { FieldsCreator } from "../field-creator";
import { PythonModelLibraryOptions } from "./enums";
import { convertEnumsToOptions } from "@utils/common";

export const pipelineCategoryOptionsForDropdown = convertEnumsToOptions(PythonModelLibraryOptions);

const UploadModelFields: React.FC<{
    formData: BaseFieldType[]
}> = ({formData}) => {
    return(
        <>
            <NewExperiments />
            <InputField 
                name="pipeline_name"
                label="Pipeline Name"
                className="highlightLabel"
                validate={FieldSchemaValidator(stringFieldSchema)}
                infoText="Pipeline Name serves as the identifier for the pipeline model, which can be used while transforming or predicting in a new workflow"
                required
            />
            <SelectField 
                name="model_library"
                options={pipelineCategoryOptionsForDropdown}
                label="Model Library"
                className="highlightLabel"
                required
            />
            {formData && formData[0].templateOptions.formData && <FieldsCreator formData={formData[0].templateOptions.formData as BaseFieldType[]} />}
        </>
    )
}

export default UploadModelFields;