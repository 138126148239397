import * as React from "react"

export const SearchIcon = ({ className }: { className ?: string }) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.69 6.677a4.011 4.011 0 00-4.012-4.01A4.011 4.011 0 108.8 10.082l2.983 2.985a.908.908 0 001.286-1.283l-2.984-2.987a3.99 3.99 0 00.606-2.12zm-6.66 0a2.649 2.649 0 115.297.001 2.649 2.649 0 01-5.298-.001z"
        fill="#fff"
      />
    </svg>
  )
}

export const PencilEditIcon = ({ className }: { className ?: string }) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.749 5.095l2.154 2.155"
        stroke="#C7C7CD"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <path
        d="M5.917 13H3.443A.443.443 0 013 12.557v-2.474a.437.437 0 01.127-.31l6.642-6.64a.443.443 0 01.63 0L12.869 5.6a.442.442 0 010 .631l-6.641 6.642a.436.436 0 01-.31.127v0z"
        stroke="#C7C7CD"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const ImportJSONIcon = () => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 3H2a.5.5 0 00-.5.5V12a1 1 0 001 1h11a1 1 0 001-1V3.5A.5.5 0 0014 3zM2.5 7H5v2H2.5V7zM6 7h7.5v2H6V7zm-3.5 3H5v2H2.5v-2zm11 2H6v-2h7.5v2z"
        fill="#C7C7CD"
      />
    </svg>
  )
}