import React, { useState, useRef, useEffect } from 'react';
import { ShowWhenTrue } from '../../helpers';
import { useDidMount } from 'rooks';
import { useOutsideClick } from 'rooks5';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
export type DropdownOptions = {[optionName: string]: {action?: () => any; disabled?: boolean}} 

interface DropdownProps{
    title: React.ReactNode | string;
    onClickItem?: (arg0: string) => void;
    useDropdownSeparator?: boolean;
    indexForDropdownSeparator?: number; 
    // dropdownOptions: {[x: string]: () => void};
    dropdownOptions: DropdownOptions;
    position?: 'left' | 'right';
    dynamicTitle?: boolean;
    className?: string;
    disabled?: boolean;
    initialValue?: string;
    onShow?: () => void;
    onHide?: () => void;
    id?: string;
}

// const dropdownOptions = ['Analytics', 'Properties', 'Copy', 'Delete'];

export const Dropdown: React.FC<DropdownProps> = 
    ({ 
        title, 
        onClickItem, 
        indexForDropdownSeparator, 
        useDropdownSeparator, 
        dropdownOptions, 
        position = 'left',
        dynamicTitle = false,
        className,
        disabled = false,
        initialValue = '',
        onHide,
        onShow,
        id = '',
    }) => {
      
        const [activeOptionTitle, setActiveTitle] = useState('');
        const [show_dropdown, toggleDropdown] = useState(false);
        const dropdown_ref = useRef<HTMLDivElement>(null);

        useEffect(() => {
            if(initialValue && dynamicTitle) {
                const isTitleFound = Object.keys(dropdownOptions).find(title => title === initialValue);
                if(isTitleFound) setActiveTitle(initialValue);
            }
        }, [initialValue]);

        useOutsideClick(dropdown_ref, () => {toggleDropdown(false);}, show_dropdown);

        useDidMount(() => {
            if(dynamicTitle && !isEmpty(dropdownOptions) && !initialValue) setActiveTitle(Object.keys(dropdownOptions)[0]);
        });

        useEffect(() => show_dropdown ? onShow?.() : onHide?.(), [show_dropdown]);
    
        return (
            <div id={'Dropdown_Wrapper'} ref={dropdown_ref} className={classNames('dropdown__master', {'dropdown--active': show_dropdown }, {'dropdown--disabled': disabled, [className || '']: !!className && !dynamicTitle})}>
                {dynamicTitle ?
                    <div className={`select-field ${className ? className : ''}`}>
                        <label className="inputfield__label">
                            {title}
                        </label>
                        <div className={classNames('select-field-box', {'select-field-box--active': show_dropdown})}>
                            <input
                                id={id}
                                className={'select-field-value'} 
                                value={activeOptionTitle}
                                onClick={() => !disabled && toggleDropdown(!show_dropdown)}
                                autoComplete="off"
                                readOnly
                            />
                        </div>
                    </div>
                    :
                    <div 
                        id={id}
                        className={classNames('dropdown__title', {'dynamicTitle': dynamicTitle})} 
                        onClick={(e) => {
                            e.stopPropagation();
                            !disabled && toggleDropdown(!show_dropdown);
                        }}
                    >
                        {title} 
                    </div>
                }
                <ShowWhenTrue show={show_dropdown}>
                    <ul className={dynamicTitle ? 'select-field-options-outer' : classNames('dropdown__menu', {'dropdown__menu--right': position === 'right'})}>
                        {Object.entries(dropdownOptions).map(([optionName, optionInfo], index) => {
                            const isOptionDisabled = optionInfo?.disabled;
                            return(
                                <React.Fragment key={optionName+index}>
                                    <li 
                                        id={`drop_${optionName}`}
                                        className={dynamicTitle ? classNames('select-field-option', `dropDown_${optionName.replace('','_')}`  , {'disabled' : isOptionDisabled}) : classNames('dropdown__menu__item',`dropDown_${optionName.replace(/ /g, '_')}`  , {'disabled' : isOptionDisabled})}
                                        onClick={() => {
                                            if(!isOptionDisabled){
                                                toggleDropdown(false);
                                                onClickItem && onClickItem(optionName);
                                                optionInfo.action && optionInfo.action();
                                                dynamicTitle && setActiveTitle(optionName);
                                            }
                                        }} 
                                    >
                                        {optionName}
                                    </li>
                                    {useDropdownSeparator && indexForDropdownSeparator && index === indexForDropdownSeparator - 1 && <div className="dropdown__items__separator"/>}
                                </React.Fragment>
                            );
                        })}
                    </ul>
                </ShowWhenTrue>
            </div>
        );
    };