import { AllConnectionsInfo, BriefConnectionInfo } from "@api/data-source-handler";
import { reverse, startCase } from "lodash";
import { sourceTypeNameMapping, ConnectionTypes } from "./enums";
import { CustomTreeNodeInArray } from "./treeviews/connections-treeview";

const getChildFromParentData = (data: CustomTreeNodeInArray[], identifier: string) => data.find(child => child.key === identifier);

export const getLoadingNode: () => CustomTreeNodeInArray[] = () => ([{ label: 'loading', key: '__loading' }]);

export const handleAddNodeToTreeViewData = (dataForTreeview: CustomTreeNodeInArray[], nodeIdentifier: string, dataToBeAdded: CustomTreeNodeInArray[]) => {
    // nodeIdentifier(key in tree item) of analytic_flow => WITSML/2Test_witsml_5/1.3.1.1/20413-1, 1z Tornadowells4.18624948
    // WITSML - 0 level, 2Test_witsml_5 - 1 level, 1.3.1.1- 2 level, 20413-1, 1z Tornadowells4.18624948 - 3 level
    // Data is added as nodes to 20413-1, 1z Tornadowells4.18624948 item in the above example

    let treeViewData = [...dataForTreeview];

    // ["WITSML", "2Test_witsml_5", "1.3.1.1", "20413-1, 1z Tornadowells4.18624948"]
    const levelIdentifiers = nodeIdentifier.split('/'); 

    //  dataMatchingIdentifier =[[...zero level data], [first level data matching identifier: "WITSML"], [second level data matching dentifier: "2Test_witsml_5"]]....
    // identifier is used for matching the getting data of next item in array using the data in its object
    // 0: {data: Array(5), identifier: "WITSML"}
    // 1: {data: Array(8), identifier: "2Test_witsml_5"}
    // 2: {data: Array(2), identifier: "1.3.1.1"}
    // 3: {data: Array(502), identifier: "20413-1, 1z Tornadowells4a5dce242b4e52.18624948"}

    const dataMatchingIdentifier: {data: CustomTreeNodeInArray[]; identifier: string}[] = [];

    levelIdentifiers.some((identifier, index) => {
        dataMatchingIdentifier.push({ data: treeViewData, identifier });
        const testData =  getChildFromParentData(treeViewData, identifier);
        if(testData) {
            if(index + 1 === levelIdentifiers.length) {
                // loop has reached the last key
                return true;
            } else if(testData.nodes) {
                treeViewData = testData.nodes;
                return false;
            }
        } return false; 
    });

    let identifier = '';
    let updatedTreeViewData: CustomTreeNodeInArray[] = [];

    // dataMatchingIdentifier is reversed -- 
    // 0: {data: Array(502), identifier: "20413-1, 1z Tornadowells4a5dce242b4e52.18624948"}
    // 1: {data: Array(2), identifier: "1.3.1.1"}
    // 2: {data: Array(8), identifier: "2Test_witsml_5"} ....
    
    reverse(dataMatchingIdentifier).forEach((child, index) => {
        if(index === 0) {
            // 0th index i.e data matching identifier: "20413-1, 1z Tornadowells4a5dce242b4e52.18624948" to which data needs to be added
            updatedTreeViewData = child.data.map(grandChild => {
                if(grandChild.key === child.identifier) {
                    // dataToBeAdded as nodes to identifier "20413-1, 1z Tornadowells4a5dce242b4e52.18624948"
                    grandChild.nodes = dataToBeAdded;
                } return grandChild;
            });
            
        } else if(identifier) {
            // parent data of "20413-1, 1z Tornadowells4a5dce242b4e52.18624948" is added as nodes to data of 1.3.1.1 
            // parent data of 1.3.1.1 is added as nodes to data of 2Test_witsml_5
            // Entire treeview is constructed in reverse fashion
            updatedTreeViewData = child.data.map(grandChild => {
                if(grandChild.key === identifier) {
                    grandChild.nodes = updatedTreeViewData;
                }
                return grandChild;
            });
        }
        identifier = child.identifier;
    });
    return updatedTreeViewData
}


export const parseConnectionsResponseIntoNewFormat = (connections: AllConnectionsInfo) => {
    const parsedConnectionData: CustomTreeNodeInArray[] = Object.entries(connections).map(([platformType, platformInfo]) => {
        const masterConnectionNode: CustomTreeNodeInArray = { 
            label: startCase(platformType),
            key: platformType, 
            nodes: Object.entries(platformInfo).map(([sourceType, sourceInfo]) => {
                const sourceConnectionNode: CustomTreeNodeInArray = {
                    label: sourceTypeNameMapping[sourceType] || startCase(sourceType),
                    key: platformType + '_' + sourceType,
                     // sourceInfo[0] should be changed to sourceInfo after response update    
                    nodes: Object.entries(sourceInfo).map(([connectionType, connectionsList]) => {
                        const connectionNode: CustomTreeNodeInArray =  {
                            label: startCase(connectionType),
                            key: connectionType + '_' + sourceType + '_' + platformType,
                            nodes: []
                        };

                        if(connectionType === 'Edge_Managed') {
                            connectionNode.nodes = connectionsList.map((__connection: any) => {
                                const connectionInfo = {
                                    label: __connection.name,
                                    key: connectionType + '_' + platformType + '_' + sourceType + '_' + __connection.id + '_' + __connection.name,
                                    isConnection: false,
                                    ...__connection,
                                    connectionType,
                                    nodes: [],
                                    sourceType: __connection.connectionType,
                                    edgeName: __connection.nodeName
                                };
                                return connectionInfo
                            })
                        }
                        else if(connectionType === 'MySql' || connectionType === 'SQLServer' || connectionType === ConnectionTypes.WITSML) {
                            connectionNode.nodes = connectionsList.map(dbConnection => {
                                const dbConnectionInfo: CustomTreeNodeInArray = {
                                    ...dbConnection, 
                                    label: dbConnection.name, 
                                    key: dbConnection.id + dbConnection.name, 
                                    isConnection: true,
                                    nodes: getLoadingNode(),
                                    isDatabaseType: true,
                                    connectionType,
                                    sourceType: dbConnection.connectionType,
                                }
                                return dbConnectionInfo
                            })
                        } else if(connectionType === 'Edge_Managed_Oracle'){
                            connectionNode.nodes = connectionsList.map((__connection: any) => {
                                const connectionInfo = {
                                    label: __connection.name,
                                    key: connectionType + '_' + platformType + '_' + sourceType + '_' + __connection.id + '_' + __connection.name,
                                    isConnection: false,
                                    ...__connection,
                                    connectionType,
                                    nodes: [],
                                    sourceType: __connection.connectionType,
                                    edgeName: __connection.nodeName
                                };
                                return connectionInfo
                            })
                        } else if(connectionType === 'Edge_Managed_FTP'){
                            connectionNode.nodes = connectionsList.map((__connection: any) => {
                                const connectionInfo = {
                                    label: __connection.name,
                                    key: connectionType + '_' + platformType + '_' + sourceType + '_' + __connection.id + '_' + __connection.name,
                                    isConnection: false,
                                    ...__connection,
                                    connectionType,
                                    nodes: [],
                                    sourceType: __connection.connectionType,
                                    edgeName: __connection.nodeName
                                };
                                return connectionInfo
                            })
                        }else  {
                            connectionNode.nodes = connectionsList.map((__connection: any) => {
                                const connectionInfo = {
                                    label: __connection.name,
                                    key: connectionType + '_' + platformType + '_' + sourceType + '_' + __connection.id + '_' + __connection.name,
                                    isConnection: true,
                                    ...__connection,
                                    connectionType,
                                    nodes: [],
                                    sourceType: __connection.connectionType,
                                    edgeName: __connection.nodeName
                                };
                                if(connectionType === ConnectionTypes.WITSML) connectionInfo.nodes = getLoadingNode();
                                return connectionInfo
                            })
                        }

                        return connectionNode

                    })
                }

                return sourceConnectionNode
            })
        };
        return masterConnectionNode
    }); 

    return parsedConnectionData
}

export const parseConnectionsResponseIntoOldFormat = (connections: AllConnectionsInfo) => {
    const __parsedConnectionData: CustomTreeNodeInArray[] = [];

    // @ts-ignore
    Object.entries(connections).forEach(([connectionType, connectionTypeInfo]: [string, BriefConnectionInfo[]]) => {
        const masterConnectionNode: CustomTreeNodeInArray = { label: startCase(connectionType), key: connectionType, nodes: [] };
        if (connectionType === 'PI' || connectionType === 'API' || connectionType === 'WITSML' || connectionType === 'AzureBlobStorage') {
            connectionTypeInfo.forEach((__connection: any) => {
                const connectionInfo = {
                    label: __connection.name,
                    key: (__connection.id + __connection.name),
                    isConnection: true,
                    ...__connection,
                    nodes: []
                };
                if(connectionType === ConnectionTypes.WITSML) connectionInfo.nodes = getLoadingNode();
                masterConnectionNode.nodes ?.push(connectionInfo);

            });
        } else {
            connectionTypeInfo.forEach(__connection => {
                Object.entries(__connection).forEach(([databaseType, databasesInfo]) => {
                    const databaseTypeNode: CustomTreeNodeInArray = { label: databaseType, key: databaseType, nodes: [] };
                    databasesInfo.forEach(_database => {
                        const databaseInfoNode: CustomTreeNodeInArray = { ..._database, label: _database.name, key: _database.id + _database.name, isConnection: true };
                        if(connectionType !== 'OPCUA') databaseInfoNode.nodes = getLoadingNode();
                        databaseTypeNode.nodes ?.push(databaseInfoNode);
                    });
                    masterConnectionNode.nodes ?.push(databaseTypeNode);
                });
            });
        }
        
        __parsedConnectionData.push(masterConnectionNode);
    }); 

    return __parsedConnectionData;
}