import React from 'react';

export const PlusIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.33334 10H16.6667" stroke="#C7C7CD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 3.33333V16.6667" stroke="#C7C7CD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
}

export const MinusIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.16666 10H15.8333" stroke="#C7C7CD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
}

export const ThreeDotIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 13C12.8284 13 13.5 12.3284 13.5 11.5C13.5 10.6716 12.8284 10 12 10C11.1716 10 10.5 10.6716 10.5 11.5C10.5 12.3284 11.1716 13 12 13Z" fill="#C7C7CD" />
            <path d="M12 7C12.8284 7 13.5 6.32843 13.5 5.5C13.5 4.67157 12.8284 4 12 4C11.1716 4 10.5 4.67157 10.5 5.5C10.5 6.32843 11.1716 7 12 7Z" fill="#C7C7CD" />
            <path d="M12 19C12.8284 19 13.5 18.3284 13.5 17.5C13.5 16.6716 12.8284 16 12 16C11.1716 16 10.5 16.6716 10.5 17.5C10.5 18.3284 11.1716 19 12 19Z" fill="#C7C7CD" />
        </svg>

    );
}

export const DataWorkflow = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5182_52197)">
                <path opacity="0.2" d="M20 21.0909C27.732 21.0909 34 17.672 34 13.4545C34 9.23709 27.732 5.81818 20 5.81818C12.268 5.81818 6 9.23709 6 13.4545C6 17.672 12.268 21.0909 20 21.0909Z" fill="#E25A1C" />
                <path d="M20 21.0909C27.732 21.0909 34 17.672 34 13.4545C34 9.23709 27.732 5.81818 20 5.81818C12.268 5.81818 6 9.23709 6 13.4545C6 17.672 12.268 21.0909 20 21.0909Z" stroke="#BDC2FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6 13.4547V21.091C6 25.3085 12.268 28.7274 20 28.7274C27.732 28.7274 34 25.3085 34 21.091V13.4547" stroke="#BDC2FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6 21.0909V28.7273C6 32.9447 12.268 36.3636 20 36.3636C27.732 36.3636 34 32.9447 34 28.7273V21.0909" stroke="#BDC2FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <rect x="26" y="30" width="18" height="14.3077" rx="2" fill="#212233" />
                <path d="M35.4567 33.0782H39.0986C39.6509 33.0782 40.0986 33.5259 40.0986 34.0782V36.212C40.0986 36.7643 39.6509 37.212 39.0986 37.212H33.2949C32.7426 37.212 32.2949 37.6597 32.2949 38.212V40.1061C32.2949 40.6584 32.7426 41.1061 33.2949 41.1061H35.8486" stroke="#E25A1C" strokeWidth="1.25" />
                <rect x="27.3487" y="31.1741" width="7.95105" height="4.21358" rx="1" stroke="#E25A1C" strokeWidth="1.25" />
                <rect x="36.3496" y="38.9993" width="7.95105" height="4.21358" rx="1" stroke="#E25A1C" strokeWidth="1.25" />
                <rect x="26" y="30" width="18" height="14.3077" rx="2" stroke="#212233" strokeWidth="2" />
            </g>
            <defs>
                <clipPath id="clip0_5182_52197">
                    <rect width="48" height="48" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const DataTable = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.2" d="M20 21.0909C27.732 21.0909 34 17.672 34 13.4545C34 9.23709 27.732 5.81818 20 5.81818C12.268 5.81818 6 9.23709 6 13.4545C6 17.672 12.268 21.0909 20 21.0909Z" fill="#58B2D6" />
            <path d="M20 21.0909C27.732 21.0909 34 17.672 34 13.4545C34 9.23709 27.732 5.81818 20 5.81818C12.268 5.81818 6 9.23709 6 13.4545C6 17.672 12.268 21.0909 20 21.0909Z" stroke="#BDC2FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 13.4547V21.091C6 25.3085 12.268 28.7274 20 28.7274C27.732 28.7274 34 25.3085 34 21.091V13.4547" stroke="#BDC2FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 21.0909V28.7273C6 32.9447 12.268 36.3636 20 36.3636C27.732 36.3636 34 32.9447 34 28.7273V21.0909" stroke="#BDC2FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M42.3846 31H27.6154C27.4522 31 27.2956 31.0648 27.1802 31.1802C27.0648 31.2956 27 31.4522 27 31.6154V42.0769C27 42.4033 27.1297 42.7164 27.3605 42.9472C27.5913 43.178 27.9043 43.3077 28.2308 43.3077H41.7692C42.0957 43.3077 42.4087 43.178 42.6395 42.9472C42.8703 42.7164 43 42.4033 43 42.0769V31.6154C43 31.4522 42.9352 31.2956 42.8198 31.1802C42.7044 31.0648 42.5478 31 42.3846 31ZM28.2308 35.9231H31.3077V38.3846H28.2308V35.9231ZM32.5385 35.9231H41.7692V38.3846H32.5385V35.9231ZM28.2308 39.6154H31.3077V42.0769H28.2308V39.6154ZM41.7692 42.0769H32.5385V39.6154H41.7692V42.0769Z" fill="#58B2D6" />
            <rect x="26" y="30" width="18" height="14.3077" rx="2" stroke="#212233" strokeWidth="2" />
        </svg>

    )
}

export const PauseTable = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5182_52318)">
                <path opacity="0.2" d="M10.5 19.5L10.5 4.5C10.5 4.08579 10.1642 3.75 9.75 3.75L6 3.75C5.58579 3.75 5.25 4.08579 5.25 4.5L5.25 19.5C5.25 19.9142 5.58579 20.25 6 20.25H9.75C10.1642 20.25 10.5 19.9142 10.5 19.5Z" fill="#BDC2FF" />
                <path opacity="0.2" d="M18.75 19.5V4.5C18.75 4.08579 18.4142 3.75 18 3.75L14.25 3.75C13.8358 3.75 13.5 4.08579 13.5 4.5V19.5C13.5 19.9142 13.8358 20.25 14.25 20.25H18C18.4142 20.25 18.75 19.9142 18.75 19.5Z" fill="#BDC2FF" />
                <path d="M10.5 19.5L10.5 4.5C10.5 4.08579 10.1642 3.75 9.75 3.75L6 3.75C5.58579 3.75 5.25 4.08579 5.25 4.5L5.25 19.5C5.25 19.9142 5.58579 20.25 6 20.25H9.75C10.1642 20.25 10.5 19.9142 10.5 19.5Z" stroke="#BDC2FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18.75 19.5V4.5C18.75 4.08579 18.4142 3.75 18 3.75L14.25 3.75C13.8358 3.75 13.5 4.08579 13.5 4.5V19.5C13.5 19.9142 13.8358 20.25 14.25 20.25H18C18.4142 20.25 18.75 19.9142 18.75 19.5Z" stroke="#BDC2FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_5182_52318">
                    <rect width="24" height="24" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
}

export const WarningCircleIcon = () => {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 1.5A6.5 6.5 0 1014.5 8 6.507 6.507 0 008 1.5zM7.5 5a.5.5 0 111 0v3.5a.5.5 0 01-1 0V5zm.5 6.5A.75.75 0 118 10a.75.75 0 010 1.5z"
                fill="#F76"
            />
        </svg>
    );
}

export const MetaDataIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" />
            <path d="M22.25 5.25V18.75C22.25 19.1478 22.0839 19.5294 21.7881 19.8107C21.4924 20.092 21.0913 20.25 20.6731 20.25H3.32692C2.9087 20.25 2.5076 20.092 2.21187 19.8107C1.91614 19.5294 1.75 19.1478 1.75 18.75V5.25C1.75 4.85218 1.91614 4.47064 2.21187 4.18934C2.5076 3.90804 2.9087 3.75 3.32692 3.75H20.6731C21.0913 3.75 21.4924 3.90804 21.7881 4.18934C22.0839 4.47064 22.25 4.85218 22.25 5.25ZM20.6731 18.75V5.25H3.32692V18.75H20.6731Z" fill="white"/>
            <path d="M4.57034 11.6888C4.52397 11.7854 4.49993 11.8912 4.5 11.9983C4.49993 12.1054 4.52397 12.2112 4.57034 12.3078C4.6167 12.4044 4.6842 12.4893 4.76784 12.5563L8.33902 15.4132C8.4123 15.4719 8.4964 15.5155 8.58653 15.5416C8.67667 15.5677 8.77106 15.5758 8.86433 15.5655C8.95759 15.5551 9.04791 15.5265 9.13011 15.4812C9.21231 15.4359 9.28479 15.3749 9.34341 15.3016C9.40203 15.2284 9.44565 15.1443 9.47177 15.0541C9.49788 14.964 9.50599 14.8696 9.49563 14.7763C9.48527 14.6831 9.45664 14.5927 9.41137 14.5105C9.3661 14.4283 9.30509 14.3559 9.23181 14.2972L6.35791 11.9983L9.23181 9.69935C9.30509 9.64073 9.3661 9.56825 9.41137 9.48604C9.45664 9.40384 9.48527 9.31353 9.49563 9.22026C9.50599 9.127 9.49788 9.0326 9.47177 8.94247C9.44565 8.85234 9.40203 8.76823 9.34341 8.69496C9.28479 8.62168 9.21231 8.56066 9.13011 8.5154C9.04791 8.47013 8.95759 8.4415 8.86433 8.43114C8.77106 8.42078 8.67667 8.42888 8.58653 8.455C8.4964 8.48112 8.4123 8.52473 8.33902 8.58336L4.76784 11.4403C4.6842 11.5073 4.6167 11.5922 4.57034 11.6888Z" fill="white"/>
            <path d="M19.4297 11.6888C19.476 11.7854 19.5001 11.8912 19.5 11.9983C19.5001 12.1054 19.476 12.2112 19.4297 12.3078C19.3833 12.4044 19.3158 12.4893 19.2322 12.5563L15.661 15.4132C15.5877 15.4719 15.5036 15.5155 15.4135 15.5416C15.3233 15.5677 15.2289 15.5758 15.1357 15.5655C15.0424 15.5551 14.9521 15.5265 14.8699 15.4812C14.7877 15.4359 14.7152 15.3749 14.6566 15.3016C14.598 15.2284 14.5544 15.1443 14.5282 15.0541C14.5021 14.964 14.494 14.8696 14.5044 14.7763C14.5147 14.6831 14.5434 14.5927 14.5886 14.5105C14.6339 14.4283 14.6949 14.3559 14.7682 14.2972L17.6421 11.9983L14.7682 9.69935C14.6949 9.64073 14.6339 9.56825 14.5886 9.48604C14.5434 9.40384 14.5147 9.31353 14.5044 9.22026C14.494 9.127 14.5021 9.0326 14.5282 8.94247C14.5544 8.85234 14.598 8.76823 14.6566 8.69496C14.7152 8.62168 14.7877 8.56066 14.8699 8.5154C14.9521 8.47013 15.0424 8.4415 15.1357 8.43114C15.2289 8.42078 15.3233 8.42888 15.4135 8.455C15.5036 8.48112 15.5877 8.52473 15.661 8.58336L19.2322 11.4403C19.3158 11.5073 19.3833 11.5922 19.4297 11.6888Z" fill="white"/>
            <path d="M12.5 7.25L11.5 16.75" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    )
}

export const ThumbUp = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.49951 8.125H6.24951V16.25H2.49951C2.33375 16.25 2.17478 16.1842 2.05757 16.0669C1.94036 15.9497 1.87451 15.7908 1.87451 15.625V8.75C1.87451 8.58424 1.94036 8.42527 2.05757 8.30806C2.17478 8.19085 2.33375 8.125 2.49951 8.125V8.125Z" stroke="#C7C7CD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.24951 8.125L9.37451 1.875C9.70282 1.875 10.0279 1.93966 10.3312 2.0653C10.6345 2.19094 10.9101 2.37509 11.1423 2.60723C11.3744 2.83938 11.5586 3.11498 11.6842 3.41829C11.8098 3.72161 11.8745 4.0467 11.8745 4.375V6.25H16.7085C16.8858 6.25 17.061 6.28769 17.2226 6.36058C17.3841 6.43346 17.5283 6.53987 17.6456 6.67275C17.7629 6.80562 17.8506 6.96192 17.9029 7.13128C17.9552 7.30063 17.9709 7.47917 17.9489 7.65504L17.0114 15.155C16.9736 15.4574 16.8267 15.7355 16.5983 15.9371C16.3699 16.1387 16.0757 16.25 15.771 16.25H6.24951" stroke="#C7C7CD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const ThumbDown = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.49951 3.75H6.24951V11.875H2.49951C2.33375 11.875 2.17478 11.8092 2.05757 11.6919C1.94036 11.5747 1.87451 11.4158 1.87451 11.25V4.375C1.87451 4.20924 1.94036 4.05027 2.05757 3.93306C2.17478 3.81585 2.33375 3.75 2.49951 3.75V3.75Z" stroke="#C7C7CD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.24951 11.875L9.37451 18.125C9.70282 18.125 10.0279 18.0603 10.3312 17.9347C10.6345 17.8091 10.9101 17.6249 11.1423 17.3928C11.3744 17.1606 11.5586 16.885 11.6842 16.5817C11.8098 16.2784 11.8745 15.9533 11.8745 15.625V13.75H16.7085C16.8858 13.75 17.061 13.7123 17.2226 13.6394C17.3841 13.5665 17.5283 13.4601 17.6456 13.3273C17.7629 13.1944 17.8506 13.0381 17.9029 12.8687C17.9552 12.6994 17.9709 12.5208 17.9489 12.345L17.0114 4.84496C16.9736 4.54264 16.8267 4.26453 16.5983 4.0629C16.3699 3.86127 16.0757 3.75 15.771 3.75H6.24951" stroke="#C7C7CD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const ContextEditor = () => {
    return (
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 0.5H1C0.801088 0.5 0.610322 0.579018 0.46967 0.71967C0.329018 0.860322 0.25 1.05109 0.25 1.25V14C0.25 14.3978 0.408035 14.7794 0.68934 15.0607C0.970644 15.342 1.35218 15.5 1.75 15.5H18.25C18.6478 15.5 19.0294 15.342 19.3107 15.0607C19.592 14.7794 19.75 14.3978 19.75 14V1.25C19.75 1.05109 19.671 0.860322 19.5303 0.71967C19.3897 0.579018 19.1989 0.5 19 0.5ZM1.75 6.5H5.5V9.5H1.75V6.5ZM7 6.5H18.25V9.5H7V6.5ZM1.75 11H5.5V14H1.75V11ZM18.25 14H7V11H18.25V14Z" fill="#BDC2FF"/>
        </svg>
    )
}
