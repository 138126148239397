import React from 'react';
import { Modal } from '../../../components/modals';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../../store/workflow';
import { RootState } from '../../../store/types';
import { newComponentHandler, DeleteCustomComponentResponse } from '../../../api/new-custom-component';
import { successAlert, errorAlert } from '../../../components/toastify/notify-toast';
import { capitalize } from 'lodash';

interface DeleteComponentModalProps {
    componentId: number;
    componentName: string;
    handleDeleteComponentSuccess: () => void;
}

export const DeleteComponentModal: React.FC<DeleteComponentModalProps> = ({ componentId, componentName, handleDeleteComponentSuccess }) => {
    const dispatch = useDispatch();
    const showModal = useSelector((store: RootState) => store.WorkflowReducer.showModal.deleteComponent);
    const title = `Delete ${capitalize(componentName)}`;

    function toggleClose() {
        dispatch(toggleModal('deleteComponent', false));
    }

    function handleComponentDeleteSuccess(response: DeleteCustomComponentResponse) {
        handleDeleteComponentSuccess();
        if (response.success) {
            successAlert(response.message);
            toggleClose();
        }
    }

    function handleComponenError(error: any) {
        toggleClose();
        errorAlert(error.response.data.message);
    }

    function handledeleteComponent() {
        newComponentHandler.deleteCustomComponent(componentId, handleComponentDeleteSuccess, handleComponenError);
    }

    return (
        <Modal
            isOpen={showModal}
            toggleClose={toggleClose}
            title={title}
            className="deleteWorkflowModal"
        >
            <p className="deleteWorkflowModal__areYouSureMsg">Are you sure you want to delete the Component?  </p>
            <p className="deleteWorkflowModal__warningMsg">Warning: You can&apos;t undo this action!</p>
            <div className="modalBtns__box">
                <button
                    className="btn-md btn-yellow"
                    onClick={handledeleteComponent}
                    type="submit"
                >
                    Yes
                </button>
                <button
                    className="btn-md btn-cancel"
                    type="button"
                    onClick={toggleClose}
                >
                    Cancel
                </button>
            </div>
        </Modal>
    );
};