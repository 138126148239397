import React from 'react';
import { ClusterDetail } from '../../api/databricks-handler';
import { Modal } from '../../components/modals';
import { restartCluster } from '../../store/cluster';
import { useDispatch } from 'react-redux';

type RestartClusterModalProps = {
    showModal: boolean;
    toggleModal: () => void;
    currentClusterDetail: ClusterDetail | null;
}


export const RestartClusterModal: React.FC<RestartClusterModalProps> = ({ showModal, toggleModal, currentClusterDetail }) => {
    const dispatch = useDispatch();

    const handleRestartCluster = () => {
        if(currentClusterDetail?.clusterId)
            dispatch(restartCluster(currentClusterDetail.clusterId));
            toggleModal()
    };

    return(
        <Modal
            isOpen={showModal}
            toggleClose={toggleModal}
            title={"Restart " + currentClusterDetail?.clusterName}
            className="deleteCluster__modal"
        >
            {/* <p className="warning__msg">Warning: This action <span>cannot</span> be undone. This will permanently delete the <span>{currentClusterDetail?.clusterName}</span> cluster.</p> */}
            
            <p>Do you want to restart {currentClusterDetail?.clusterName}?</p>
            <div className="btns__outer">
                <button 
                    className="btn-md"
                    type="button"
                    onClick={toggleModal}
                >
                    Cancel
                </button>  
                <button 
                    className="btn-md btn-yellow"
                    type="submit"
                    onClick={handleRestartCluster}
                >
                    <span>Yes</span>
                </button>
            </div>
        </Modal>
    );
};