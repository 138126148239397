import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import classNames from 'classnames';
import { UserDetails } from '../../components/header-sidebar/user-details';
// import { toggleColumnManagement } from '../../store/datasources';

export const ColumnManagementRhs: React.FC = () => {
    const { isColumnManagementMinimized } = useSelector((store: RootState) => store.NewDataSourceReducer);
    // const dispatch = useDispatch();

    // const handlePropertiesToggleClick = (e: any, action?: boolean) => {
    //     dispatch(toggleColumnManagement(action));
    // };

    
    return(
        <div className={classNames('properties__container', {'properties__container--minimized' : isColumnManagementMinimized})}>
            <UserDetails hideNameOnMinimize={true} />
            <div className={classNames('treeview__header properties__header', {'hide' : isColumnManagementMinimized})}>
                <span className="switchable__tab switchable__tab--active">PROPERTIES</span>
            </div>
            {/* <div className={'properties__body'}>
                {isColumnManagementMinimized ?
                    <div className="properties__tab">
                        <div className="tabs__separator" />
                        <Tooltip placement="left" title={'Component Properties'} >
                            <span className="tab__item" onClick={handlePropertiesToggleClick.bind(true)}>
                                <PropertiesIcon active={false} />
                            </span>
                        </Tooltip>
                    </div>
                    :
                    // body to be shown goes here
                    <div />
                }


            </div>

            <PropertiesToggle
                className={classNames('properties__toggle', { 'reverse__img': !isColumnManagementMinimized })}
                onClick={handlePropertiesToggleClick}
            /> */}
        </div>
    );
};