import React, { useState } from "react";
import { FileExplorer } from "@components/FileExplorer";
import { File, FileContextMenuItem } from "@components/FileExplorer/types";
import { errorAlert, infoAlert } from "@components/toastify/notify-toast";
import { uuid } from "uuidv4";
import { useFileStorage } from "../hooks/useFileStorage";
import FileUploadModal from "../modals/FileUploadModal";
import "../styles.scss";
import { isEmpty } from "lodash";

export const FileManagement = () => {
	const {
		files,
		folderChain,
		setFolderChain,
		loading,
		refreshFileList,
		currentPath,
		deleteFile,
		downloadFile,
		uploadFile,
		selectedFiles,
		setSelectedFiles,
	} = useFileStorage("Azure");

	const [showUploadFileModal, toggleUploadFileModal] = useState(false);

	const handleUploadClick = () => {
		toggleUploadFileModal(true);
	};

	const handleDownloads  = () =>{
		selectedFiles.forEach((file: File) => {
			if(file.isDir){
				errorAlert('Folder download not supported!');
			}else{
				infoAlert("Downloading " + file.name);
				downloadFile(file.path, file.name);
			}
		});
	}

	const handleDeletes = () =>{
		selectedFiles.forEach((file: File) => {
			deleteFile(
				file.path,
				() => {
					infoAlert(`${file.name} deleted successfully`);
					refreshFileList();
				},
				() => {
					errorAlert(file.isDir?'Folder delete not supported!':`Failed to delete ${file.name}`);
				}
			);
		});
	}

	const contextMenu: FileContextMenuItem[] = [
		{
			id: uuid(),
			label: "Download",
			multipleFileSupport: true,
			folderSupport: false,
			action: (files: File[]) => {
				files.forEach((file: File) => {
					if(file.isDir){
						errorAlert('Folder download not supported!');
					}else{
						infoAlert("Downloading " + file.name);
						downloadFile(file.path, file.name);
					}
				});
			},
		},
		{
			id: uuid(),
			label: "Delete",
			multipleFileSupport: true,
			folderSupport: false,
			action: (files: File[]) => {
				files.forEach((file: File) => {
					deleteFile(
						file.path,
						() => {
							if(file.isDir){
								errorAlert('Folder delete not supported!');
							}else{
								infoAlert(`${file.name} deleted successfully`);
								refreshFileList();
							}
						},
						() => {
							errorAlert(`Failed to delete ${file.name}`);
						}
					);
				});
			},
		},
	];

	return (
		<div className="file_container">
			<FileExplorer
				loading={loading}
				bg={"#3E4161"}
				padding={"0px"}
				files={files}
				folderChain={folderChain}
				setFolderChain={setFolderChain}
				selectedFiles={selectedFiles}
				setSelectedFiles={setSelectedFiles}
				contextMenu={contextMenu}
				onRefresh={refreshFileList}
				onUpload={handleUploadClick}
				clearSelectionOnOutsideClick={true}
				enableMultiSelect
                actionBarOptions={{hideDownload: isEmpty(selectedFiles),hideDelete: isEmpty(selectedFiles)}}
				onDownload={handleDownloads}
				onDelete={handleDeletes}
			/>
			<FileUploadModal
				showModal={showUploadFileModal}
				closeModal={() => toggleUploadFileModal(false)}
				currentPath={currentPath}
				refreshFileList={refreshFileList}
				deleteFile={deleteFile}
				uploadFile={uploadFile}
			/>
		</div>
	);
};
