import classNames from "classnames";
import React, { useMemo, useRef, useState } from "react";
import {
	AutoSizer,
	CellMeasurer,
	CellMeasurerCache,
	GridCellRenderer,
	MultiGrid,
	InfiniteLoader,
	IndexRange,
	Index,
} from "react-virtualized";

const cache = new CellMeasurerCache({
	defaultWidth: 100,
	defaultHeight: 32,
	// minWidth: 75,
	fixedHeight: true,
});

type TableProps = {
	data: string[][],
	columnList?: string[],
	isInfinite?: boolean
	loadMoreRows?: (params: IndexRange) => Promise<any>
};

export const AdjustToCellSizeTable: React.FC<TableProps> = ({ data, isInfinite, loadMoreRows, columnList }) => {
	const gridRef = useRef<MultiGrid>(null);
	const [isLoading, setLoading] = useState(false);
	const [hoverGridIndex, setHoverGridIndex] = useState({
		row: -1,
		column: -1,
	});

	const isRowLoaded = (index: Index) => {
		return !!data[index.index];
	}

	const askMoreData = async (index: IndexRange) => {
		setLoading(true);
		try{
			loadMoreRows && await loadMoreRows(index)
		} catch(e){
			//
		}
		setLoading(false);
	}

	const dataToBeShown = useMemo(() => {
		// if content count is same between renders, it doesnt re-render
		!isInfinite && gridRef.current?.forceUpdateGrids();
		// reset cell and column width just before new data is loaded
		cache.clearAll();
		return data;
	}, [data]);

	const renderGridOnHover = (rowIndex: number, columnIndex: number) => {
		if (rowIndex !== 0) {
			// change the row color on cell hover
			setHoverGridIndex({ row: rowIndex, column: -1 });
		} else {
			// change the column color on header hover
			setHoverGridIndex({ row: -1, column: columnIndex });
		}
		!isInfinite && gridRef.current?.forceUpdateGrids();
	};

	const cellRenderer: GridCellRenderer = ({
		columnIndex,
		key,
		parent,
		rowIndex,
		style,
	}) => {
		if(!data[rowIndex]) {
			return <>Loading</>
		}
		const content = dataToBeShown[rowIndex][columnIndex];
		const onMouseOver = () => {
			renderGridOnHover(rowIndex, columnIndex);
		};

		const hoveredRowIndex = hoverGridIndex.row;
		const hoveredColumnIndex = hoverGridIndex.column;
		return (
			<CellMeasurer
				cache={cache}
				columnIndex={columnIndex}
				key={key}
				parent={parent}
				rowIndex={rowIndex}
			>
				
				<div
					className={classNames("dataBrowser__table__cell", {
						dataBrowser__table__cell_even: rowIndex % 2 === 0,
						"dataBrowser__table__cell--hover":
							hoveredRowIndex === rowIndex,
						"dataBrowser__table__cell--hover--firstCell":
							hoveredRowIndex === rowIndex && columnIndex === 0,
						"dataBrowser__table__cell--hover--lastCell":
							hoveredRowIndex === rowIndex &&
							columnIndex + 1 === dataToBeShown[rowIndex].length,
						"dataBrowser__table__cell--headerHover":
							hoveredColumnIndex === columnIndex,
						"dataBrowser__table__cell--headerHover--firstCell":
							hoveredColumnIndex === columnIndex &&
							rowIndex === 0,
					})}
					style={{
						...style,
						whiteSpace: "nowrap",
					}}
					onMouseOver={onMouseOver}
				>
					{content}

					{/* {rowIndex === 0 && (content || '').length > 40 && 
                        <div className="resizeArrow" />
                    } */}
				</div>
			</CellMeasurer>
		);
	};

	const tableHeight = useMemo(() => {
		if(!dataToBeShown) return;
		return dataToBeShown.length * 32 > window.innerHeight - 350
			? window.innerHeight - 350
			: dataToBeShown.length * 32;
	}, [dataToBeShown]);

	const colWidthArr = Array.from({
		length: dataToBeShown[0]?.length || 0,
	}).map((_, i) => cache.columnWidth({ index: i }));
	const tableWidth = colWidthArr.reduce((acc, val) => acc + val, 0) + 10;

	return (
		isInfinite && loadMoreRows ? (
			<InfiniteLoader 
				isRowLoaded={isRowLoaded}
				loadMoreRows={loadMoreRows}
				rowCount={data.length + 1}>
				{
					({onRowsRendered, registerChild}) => (
						<div
							className="dataBrowser__table"
							onMouseLeave={() => {
								renderGridOnHover(-1, -1);
							}}
						>
							<AutoSizer>
							{({ width }) => {
								return (
									<MultiGrid
										deferredMeasurementCache={cache}
										width={tableWidth}
										height={500}
										rowHeight={cache.rowHeight}
										rowCount={dataToBeShown.length}
										columnCount={columnList?.length || 0}
										columnWidth={cache.columnWidth}
										fixedRowCount={1}
										fixedColumnCount={0}
										cellRenderer={cellRenderer}
										classNameTopRightGrid="dataBrowser__table__header"
										classNameBottomRightGrid="dataBrowser__table__dataBox"
										ref={registerChild}
										onSectionRendered={({
											columnStartIndex,
											columnStopIndex,
											rowStartIndex,
											rowStopIndex,
										}) => {
											const colLen = columnList?.length || 0
											const startIndex = rowStartIndex * colLen + columnStartIndex;
											const stopIndex = rowStopIndex * colLen + columnStopIndex;
										
											onRowsRendered({
												startIndex,
												stopIndex,
											});
										}}
									/>
								)
							}}
						</AutoSizer>
						</div>
					)
				}
			</InfiniteLoader>
		) : (
			<AutoSizer disableHeight>
				{({ width }) => (
					<div
						className="dataBrowser__table"
						onMouseLeave={() => {
							renderGridOnHover(-1, -1);
						}}
					>
						<MultiGrid
							deferredMeasurementCache={cache}
							width={width}
							height={tableHeight || 100}
							rowHeight={cache.rowHeight}
							rowCount={dataToBeShown ? dataToBeShown.length : 0}
							columnCount={dataToBeShown[0]?.length || 0}
							columnWidth={cache.columnWidth}
							fixedRowCount={1}
							fixedColumnCount={0}
							cellRenderer={cellRenderer}
							classNameTopRightGrid="dataBrowser__table__header"
							classNameBottomRightGrid="dataBrowser__table__dataBox"
							ref={gridRef}
						/>
					</div>
				)}
			</AutoSizer>
		)
	);
};
