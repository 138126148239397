import React from "react";
import { InPageSpinner } from "../spinners/in-page-spinner";

type _HtmlButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

type ButtonProps = {
    showSpinner ?: boolean;
    disabled ?: boolean;
    title: string;
    btnType ?: _HtmlButtonProps['type']
    onClick ?: _HtmlButtonProps['onClick']
}

const Button: React.FC<ButtonProps> = ({ title, onClick, showSpinner = false, disabled = false, btnType = 'button' }) => {
    return(
        <button 
            className="btn-md btn-yellow"
            type={btnType}
            disabled={disabled}
            onClick={onClick}
        >
            {showSpinner ?
                <InPageSpinner size="XSmall" color="black" />
                :
                <span>{title}</span>
            }
        </button>
    )
}

export default Button;