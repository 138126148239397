import { cloneDeep } from 'lodash';
import { ExecutionEnvModes } from '../../constants/enums';
import { LOAD_INITIAL_STATE, RESET_STATE } from '../types';
import { SHOW_SPINNER, HIDE_SPINNER, CommonActionTypes, CommonReducerState, 
    TOGGLE_LANDING_SCREEN, GO_TO_ANALYTICS_PAGE, TOGGLE_NETWORK_ERROR, SET_EXECUTION_ENV, SET_LAST_ACTIVE_WORKFLOW_INFO, SetLastActiveWorkflowInfoType, SET_COMPONENTS_SEARCH_STATE, SetComponentsSearchState } from './types';


export const commonReducerInitialState: CommonReducerState = {
    initialPageLoadSpinner: true,
    inPageSpinner: true,
    showLandingPage: false,
    goToAnalyticsPage: false,
    showNetworkErrorPage: false,
    activeExecutionEnv: ExecutionEnvModes['Spark'],
    lastActiveWorkflowInfo: {
        workflowEditor: {
            spark: null,
            python: null,
            dag: null,
            streaming: null
        },
        analytics: {
            spark: null,
            python: null,
            dag: null,
            streaming: null
        }
    },
    componentsSearchState: {
        spark: [],
        python: [],
        dag: [],
        streaming: []
    }
};


export const CommonReducer = (state = commonReducerInitialState, action: CommonActionTypes): CommonReducerState => {
    switch(action.type){
        case SHOW_SPINNER:
            return {...state, [action.payload]: true };
        case HIDE_SPINNER:
            return {...state, [action.payload]: false };
        case TOGGLE_LANDING_SCREEN:
            return {...state, showLandingPage: action.payload};
        case GO_TO_ANALYTICS_PAGE:
            return {...state, goToAnalyticsPage: !state.goToAnalyticsPage};
        case TOGGLE_NETWORK_ERROR:
            return {...state, showNetworkErrorPage: action.payload};
        case SET_EXECUTION_ENV:
            return {...state, activeExecutionEnv: action.payload};
        case SET_LAST_ACTIVE_WORKFLOW_INFO: {
            const { page, env, workflowId } = action.payload as SetLastActiveWorkflowInfoType;
            const lastActiveWorkflowInfo = cloneDeep(state.lastActiveWorkflowInfo);
            lastActiveWorkflowInfo[page][env] = workflowId;
            return { ...state, lastActiveWorkflowInfo };
        }
        case SET_COMPONENTS_SEARCH_STATE: {
            const { env, openNodesState } = action.payload as SetComponentsSearchState;
            return { ...state, componentsSearchState: { ...state.componentsSearchState, [env]: openNodesState }};
        }
        case RESET_STATE:
            return { ...cloneDeep(commonReducerInitialState), initialPageLoadSpinner: false, inPageSpinner: false };
        case LOAD_INITIAL_STATE:
            return { ...state, ...action.payload.CommonReducer }
        default:
            return state;
    }
};
