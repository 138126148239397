import React, { memo } from 'react';
import { Tooltip } from 'antd';
import { WorkflowCanvas } from '../../../../components/workflow-canvas';
import { FitToView, ZoomInView, ZoomOutView } from '@assets/icons';

const WrapperComponent: React.FC = () => {
    const handleZoomIn = () => {
        const current_zoom_level = WorkflowCanvas.model.getZoomLevel();
        WorkflowCanvas.model.setZoomLevel(current_zoom_level + 4);
        WorkflowCanvas.repaintCanvas();
    };

    const handleZoomOut = () => {
        const current_zoom_level = WorkflowCanvas.model.getZoomLevel();
        WorkflowCanvas.model.setZoomLevel(current_zoom_level - 4);
        WorkflowCanvas.repaintCanvas();
    };

    return(
        <div className="zoom_icons_div">
            <div className="upperBox">
                <button
                    className="zoom_icon"
                    onClick={() => {
                        WorkflowCanvas.engine.zoomToFitNodes({ margin: 30 });
                    }}
                    aria-label="canvas-zoom-to-fit"
                >
                    <Tooltip
                        title={'Fit To View'}
                        placement="right"
                    >
                        <div className='centerh'><FitToView/></div>
                    </Tooltip>
                </button>
            </div>
            <div className="lowerBox">
                <button 
                    onClick={handleZoomIn} 
                    className="zoom_icon"
                    aria-label="canvas-zoom-in"
                >
                    <Tooltip
                        title={'Zoom In'}
                        placement="right"
                    >
                        <div className='centerh'><ZoomInView/></div>
                    </Tooltip>
                </button>
                <div className="separator" />
                <button 
                    onClick={handleZoomOut} 
                    className="zoom_icon"
                    aria-label="canvas-zoom-out"    
                >
                    <Tooltip
                        title={'Zoom Out'}
                        placement="right"
                    >
                         <div className='centerh'> <ZoomOutView/></div>
                    </Tooltip>
                </button>
            </div>

        </div>
    );
};

export const WorkflowCanvasMagnificationTools = memo(WrapperComponent);
