import { useTimeAgo } from 'rooks';


export function TimeAgo({ time }: {time: string}): JSX.Element {
    const current_time = new Date().getTime();
    const _time = new Date(time).getTime();
    const difference_in_time = current_time - _time;
    return (
        useTimeAgo(current_time- difference_in_time)
    );
}