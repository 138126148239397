import { DetailedWorkflowInfo } from "@api/workflow-handler";
import { NewSelectField } from "@components/form/NewSelectField";
import { _selectoptionType } from "@components/form/select-field";
import {
	DsNodeModel,
	DsNodeExtras,
} from "@components/workflow-canvas/node/ds_nodemodel";
import { convertSerializedWorkflowDataToModel } from "@components/workflow-canvas/utils";
import { convertWorkflowDataForExecution } from "@pages/workflow-page/utils";
import {
	DiagramModel,
	DiagramModelGenerics,
} from "@projectstorm/react-diagrams";
import { useAppSelector } from "@store/hooks";
import { useGetActiveTabInfo } from "@utils/get-active-tab-info";
import {
	useFormikContext,
	useField,
	FieldArrayRenderProps,
	FieldArray,
} from "formik";
import { has, get, merge, set } from "lodash";
import React, { useState, useEffect, useMemo } from "react";
import { WorkflowFieldSelectionObj } from ".";
import { SchemaCaptureProps } from "../field-creator";
import { getRepeatV3FieldsPayload } from "../fieldComponents/RepeatV3/utils";
import { CrossIcon } from "../icons";
import { initialValuesCreator } from "../schema-creator";

export type ComponentFieldSelectionType = {
	componentId: string;
	properties: string;
};

type ComponentPropertySelectionRowProps = {
	position: number;
	removeRow: () => void;
	componentNames: _selectoptionType[];
	getPropertyKey: (property: keyof ComponentFieldSelectionType) => string;
	value: ComponentFieldSelectionType;
	workflowModel: DiagramModel<DiagramModelGenerics> | null;
};

type ComponentFieldSelectionProps = {
	workflowInfo: DetailedWorkflowInfo | null;
	captureSchemaOptions: SchemaCaptureProps["captureSchemaOptions"];
	fieldKey: string;
};

const ComponentPropertySelectionRow: React.FC<ComponentPropertySelectionRowProps> = ({
	position,
	removeRow,
	componentNames,
	getPropertyKey,
	value,
	workflowModel,
}) => {
	const [
		selectedComponent,
		setSelectedComponent,
	] = useState<DsNodeModel | null>(null);

	useEffect(() => {
		// Update Component Props on changing component
		if (value.componentId) {
			const selectedComponent = workflowModel
				?.getActiveNodeLayer()
				.getModel(value.componentId);
			if (selectedComponent) {
				setSelectedComponent(selectedComponent as DsNodeModel);
			}
		}
	}, [workflowModel, value.componentId]);

	const selectedComponentProps: _selectoptionType[] = useMemo(() => {
		if (selectedComponent) {
			const { formData: formFields } = selectedComponent.getOptions()
				.extras as DsNodeExtras;
			if (formFields) {
				const _fieldsList: _selectoptionType[] = [];

				formFields.forEach((field, i, arr) => {
					if (
						(field.type === "repeat" ||
							field.type === "repeat-v3") &&
						field.repeat_options
					) {
						// Check if field of repeat options has arg index

						const dataFieldKey = field.repeat_options.data_field;

						const dataFieldLabel = arr.find(
							(field) => field.key === dataFieldKey
						)?.templateOptions.label;

						// Only include the fields that has arg_index
						// arg_index and useKey of fields in field.fields are added in field.repeat_options.outputFormat
						const repeatFieldsToConsider: Record<
							string,
							true
						> = Object.entries(
							field.repeat_options.outputFormat
						).reduce((fieldsToConsider, [fieldKey, fieldInfo]) => {
							if (fieldInfo.arg_index) {
								fieldsToConsider[fieldKey] = true;
							}

							return fieldsToConsider;
						}, {} as Record<string, true>);

						field.fields?.forEach((field) => {
							if (
								field.type !== "label" &&
								repeatFieldsToConsider[field.key] &&
								!field.templateOptions.ignoreForPropertySelection
							) {
								// Add the actual field label to the repeat field
								_fieldsList.push({
									label:
										(dataFieldLabel
											? `${dataFieldLabel} -`
											: "") + field.templateOptions.label,
									value: field.key,
								});
							}
						});
					} else if (!field.isFieldHidden) {
						_fieldsList.push({
							label: field.templateOptions.label,
							value: field.key,
						});
					}
				});
				return _fieldsList;
			}
		}
		return [];
	}, [selectedComponent]);

	return (
		<div key={position} className="componentPropertySelection__row">
			<button
				onClick={removeRow}
				className="btn__removeComponentRow"
				type="button"
			>
				<CrossIcon />
			</button>
			<NewSelectField
				label="Component"
				options={componentNames}
				name={getPropertyKey("componentId")}
				infoText="Select a component from the workflow. Only fuzzy membership and fuzzy aggregation components are supported."
				// onOptionClick={onComponentSelection}
			/>
			<NewSelectField
				label={`${
					selectedComponent?.getOptions().title
						? selectedComponent?.getOptions().title
						: "Component"
				} Properties`}
				options={selectedComponentProps}
				name={getPropertyKey("properties")}
				infoText={`Select properties of the components that needs to be optimized.
				For fuzzy membership components, select “Columns to operate”, “Columns to operate – Midpoint” and “Columns to operate – Spread”. 
				For fuzzy aggregation components, select “Columns to operate” and “Gamma”. Note that only gamma type aggregation is currently supported.`}
				multiple_select
			/>
		</div>
	);
};

const ComponentFieldSelection: React.FC<ComponentFieldSelectionProps> = ({
	workflowInfo,
	fieldKey,
}) => {
	const activeExecutionEnv = useAppSelector(
		(store) => store.CommonReducer.activeExecutionEnv
	);
	const activeUserInfo = useAppSelector(
		(store) => store.AccountReducer.activeUserInfo
	);
	const activeTabInfo = useGetActiveTabInfo("workflowEditor");

	const { setFieldValue } = useFormikContext<any>();

	const COMPONENT_FIELD_SELECTION_KEY = `extraData.${fieldKey ||
		"componentFieldSelection"}`;

	const { selectedWorkflowModel, nodesRef, componentNames } = useMemo(() => {
		let selectedWorkflowModel: DiagramModel<
				DiagramModelGenerics
			> | null = null,
			nodesRef: { [id: string]: DsNodeModel } = {},
			componentNames: _selectoptionType[] = [];
		if (workflowInfo?.data.details) {
			selectedWorkflowModel = convertSerializedWorkflowDataToModel(
				workflowInfo.data.details
			);
			nodesRef = selectedWorkflowModel
				.getActiveNodeLayer()
				.getModels() as typeof nodesRef;
			componentNames = Object.values(nodesRef).map((component) => ({
				label: component.getOptions().title,
				value: component.getID(),
			}));

			setTimeout(() => {
				if (selectedWorkflowModel) {
					const selectedWorkflowRunDataInfo = convertWorkflowDataForExecution(
						selectedWorkflowModel,
						false,
						activeExecutionEnv,
						{
							userName: activeUserInfo.username,
							workflowName: workflowInfo.data.projectName,
						},
						activeTabInfo?.config
					);
					setFieldValue(
						`${COMPONENT_FIELD_SELECTION_KEY}.workflowInfo`,
						{
							payload: selectedWorkflowRunDataInfo,
							name: workflowInfo.data.projectName,
							id: workflowInfo.data.id,
						}
					);

					const componentPropertiesValuesRef = Object.values(
						nodesRef
					).reduce((initialValues, currentNode) => {
						if (currentNode.getOptions().extras) {
							const {
								formData,
								extraData,
							} = currentNode.getOptions().extras as DsNodeExtras;

							const _nodeInitialValues = initialValuesCreator({
								formData,
								extraData: extraData ? { ...extraData } : {}
							});

							formData.forEach((field) => {
								// if fields of repeat field have midpoint, type, spread
								// values of midpoint, type and spread are added to the initialValues
								if (field.type === "repeat") {
									// value of fields in repeat field is stored in extraData
									if (
										has(
											_nodeInitialValues.extraData,
											field.key
										)
									) {
										const repeatFieldsValue = get(
											_nodeInitialValues.extraData,
											field.key
										);
										merge(
											_nodeInitialValues,
											repeatFieldsValue
										);
									}
								} else if (field.type === "repeat-v3") {
									// values of repeatv3 fields is stored within field's value
									const v3FieldsPayload = getRepeatV3FieldsPayload(
										field
									);
									v3FieldsPayload.forEach((fieldPayload) => {
										set(
											_nodeInitialValues,
											fieldPayload.key,
											fieldPayload.value
										);
									});
								}
							});

							initialValues[
								currentNode.getID() as string
							] = _nodeInitialValues;
						}
						return initialValues;
						// initialValuesCreator(currentNode.getOptions().extras)
					}, {} as WorkflowFieldSelectionObj["componentPropertiesValuesRef"]);

					setFieldValue(
						`${COMPONENT_FIELD_SELECTION_KEY}.componentPropertiesValuesRef`,
						componentPropertiesValuesRef
					);
				}
			}, 1);
		}
		return { selectedWorkflowModel, nodesRef, componentNames };
	}, [workflowInfo]);

	const [{ value: workflowFieldSelectionObj }] = useField<
		WorkflowFieldSelectionObj
	>(COMPONENT_FIELD_SELECTION_KEY);

	const handleAddNewComponentFieldGroup = (
		arrayHelpers: FieldArrayRenderProps
	) => {
		const rowInitialValue: ComponentFieldSelectionType = {
			componentId: "",
			properties: "",
		};
		arrayHelpers.push(rowInitialValue);
	};

	return (
		<FieldArray
			// refer to WorkflowFieldSelectionObj
			name={`${COMPONENT_FIELD_SELECTION_KEY}.componentPropertiesArr`}
			render={(arrayHelpers) => (
				<div className="componentFieldSelection">
					<button
						onClick={handleAddNewComponentFieldGroup.bind(
							null,
							arrayHelpers
						)}
						className="btn-sm btn-yellow btn__addComponentRow"
						type="button"
					>
						<img src="/icons/workflow/add.svg" alt="" /> Add
					</button>

					{workflowFieldSelectionObj?.componentPropertiesArr?.map(
						(
							componentFieldSelection: ComponentFieldSelectionType,
							position: number
						) => {
							const getKey = (keyName: string) =>
								`${COMPONENT_FIELD_SELECTION_KEY}.componentPropertiesArr[${position}].${keyName}`;

							//componentFieldSelection[0].componentName
							return (
								<ComponentPropertySelectionRow
									position={position}
									removeRow={arrayHelpers.handleRemove(position)}
									value={componentFieldSelection}
									componentNames={componentNames}
									getPropertyKey={getKey}
									workflowModel={selectedWorkflowModel}
									key={position}
								/>
							);
						}
					)}
				</div>
			)}
		/>
	);
};

export default ComponentFieldSelection;
