import { HttpClient } from '../services';
import { ClientSiteResponse,ClientSiteTagsResponse, BasePaginationSortSearchQuery, CreateClientSiteInfo, 
    ClientSiteInfo, CreateSyncSetInfo, SyncSetInfo, SyncSetsResponse } from './types';
import { API } from '../../constants/settings';
import { AxiosResponse } from 'axios';
import queryString from 'query-string';

class TagsHandlerClass {
    CreateClientSite(data: CreateClientSiteInfo, callback: (arg0: ClientSiteInfo) => any, errorHandlerCallBack: (arg0: any) => any) {
        HttpClient.Post('Create client site',`${API.tagsUrl}/clientsites`, data, callback, errorHandlerCallBack);
    }
    
    GetClientSitesList(): Promise<AxiosResponse<ClientSiteResponse>> {
        return HttpClient.GetPromise(`${API.tagsUrl}/clientsites`);
    }

    CreateSyncSet(data: CreateSyncSetInfo, callback: (arg0: SyncSetInfo) => any) {
        HttpClient.Post('Create client site',`${API.tagsUrl}/syncsets`, data, callback);
    }

    GetSyncSets(clientSiteId: number ,callback: (arg0: SyncSetsResponse) => any) {
        HttpClient.Get('active_inactive_users', `${API.tagsUrl}/clients/${clientSiteId}/syncsets`, callback);

    }

    // GetClientSiteTags(clientSiteId: number, searchQuery: BasePaginationSortSearchQuery & { searchText?: string }, callback: (arg0: ClientSiteTagsResponse) => any){
    //     const stringifiedQuery =  queryString.stringify(searchQuery);
    //     HttpClient.Get('Get Client"s site tags' ,`${API.tagsUrl}/${clientSiteId}/sitetags/?${stringifiedQuery}`, callback);
    // }

    GetServerTags(serverId: number, searchQuery: BasePaginationSortSearchQuery & { searchText?: string }, callback: (arg0: ClientSiteTagsResponse) => any){
        const stringifiedQuery =  queryString.stringify(searchQuery, { encode: true });
        HttpClient.Get('Get Client"s site tags' ,`${API.tagsUrl}/${serverId}/sitetags/?${stringifiedQuery}`, callback);
    }

    DeleteSyncSet(syncSetId: number, callback: any){
        HttpClient.Delete('active_inactive_users', `${API.tagsUrl}/syncsets/${syncSetId}`, callback);
    }

    DeleteClientSite(clientSiteId: number, callback: any){
        HttpClient.Delete('active_inactive_users', `${API.tagsUrl}/clientsites/${clientSiteId}`, callback);
    }

    GetTagsOfSyncSet(syncsetId: number, searchQuery: BasePaginationSortSearchQuery & { searchText?: string }, callback: (arg0: ClientSiteTagsResponse) => any){
        const stringifiedQuery =  queryString.stringify(searchQuery, { encode: true });
        return HttpClient.Get('Get tags of a syncset', `${API.tagsUrl}/tags/${syncsetId}?${stringifiedQuery}`, callback);
    }


}

const TagsHandler = new TagsHandlerClass();

export { TagsHandler };