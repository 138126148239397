export const getOption = (graphData: any[]) => {
	return {
		backgroundColor: '#2a2c42',
		xAxis: {
			type: 'category',
			name: 'Time',
			data: graphData.map(item => item.changeTime),
			axisLine: {
				lineStyle: {
					color: 'white',
				}
			},
			axisLabel: {
				interval: Math.ceil(graphData.length / 10),
				rotate: 10, 
				margin: 10, 
			},
		},
		yAxis: {
			type: 'value',
			name: 'Status',
			axisLine: {
				lineStyle: {
					color: 'white',
				}
			},
			min: -1, 
			max: 1,
			data: ['InActive', 'Active'],
			axisLabel: {
				formatter: (value: number) => {
					if (value === -1) return '';
					if (value === 0) return 'InActive'; 
					if (value === 1) return 'Active';
					return value;
				}
			}
		},
		series: [{
			data: graphData.map(item => item.newStatus),
			type: 'line',
			lineStyle: {
				color: 'red',
			},
		}],
		legend: {
			data: [
				{ name: 'Active', icon: 'circle' },
				{ name: 'InActive', icon: 'circle' }
			],
			textStyle: {
				color: 'white',
			}
		},
		toolbox: {
			show: true,
			backgroundColor: "#13182a",
			orient: "horizontal",
			itemSize: 20,
			itemGap: 10,
			padding: 10,
			showTitle: false,
			feature: {
				dataZoom: {
					show: true,
					title: {
						zoom: "Zoom In",
						back: "Zoom Out",
					},
					icon: {
						zoom: "image:///icons/graphicons/zoomin.png",
						back: "image:///icons/graphicons/zoomout.png",
					},
				},
				restore: {
					show: true,
					title: "Reset",
					icon: "image:///icons/graphicons/restore.png",
				},
				saveAsImage: {
					show: true,
					title: "Download",
					name: "Deepiq_chart",
					backgroundColor: "#2a2c42",
					icon: "image:///icons/graphicons/download.png",
					iconStyle: {
						borderColor: "#fff",
						color: "#fff",
						borderWidth: 1,
						borderType: "solid",
						textBackgroundColor: "#fff",
					},
				},
			},
			tooltip: {
				show: true,
				formatter: function(param: any) {
					return "<div>" + param.title + "</div>";
				},
				backgroundColor: "#222",
				textStyle: {
					fontSize: 12,
				},
				extraCssText: "box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);", 
			},
			z: 3,
			left: "auto",
			right: "2.8%",
		},
		 dataZoom: [
			{
				type: 'inside',
				start: 0,
				end: 100
			}, {
				start: 0,
				end: 10,
				borderColor: 'transparent',
				dataBackground: {
					areaStyle:{
						color: '#00C896'
					}
				},
				backgroundColor: '#2a2c42',
				handleSize: '100%',
				handleStyle: {
					color: '#fcad01',
				}
			},
			{
				type: 'inside',
				orient: 'vertical',
				start: 0,
				end: 100
			}, {
				orient: 'vertical',
				start: 0,
				end: 10,
				borderColor: 'transparent',
				dataBackground: {
					areaStyle:{
						color: '#00C896'
					}
				},
				backgroundColor: '#2a2c42',
				handleSize: '100%',
				handleStyle: {
					color: '#fcad01',
				}
			}
		],
	};
};

export const getPieOptions = (graphData: any[]) => {
	return {
		backgroundColor: '#2a2c42',
		tooltip: {
			trigger: 'item',
			formatter: '{a} <br/>{b}: {c} ({d}%)',
			backgroundColor: '#222',
			textStyle: {
				fontSize: 12,
			},
			extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);', 
		},
		legend: {
			orient: 'vertical',
			left: 10,
			top: 20,
			textStyle: {
				color: 'white',
			},
			data: ['Active Days', 'Inactive Days'],
		},
		series: [
			{
				name: 'Status',
				type: 'pie',
				radius: '50%',
				center: ['50%', '50%'],
				avoidLabelOverlap: false,
				label: {
					show: false,
				},
				emphasis: {
					label: {
						show: true,
						fontSize: '20',
						fontWeight: 'bold',
					},
				},
				labelLine: {
					show: true,
				},
				data: graphData,
			},
		],
	};
};

export const daysArray=[{label:'48 Days', value:'48days'},{label:'3 Months', value:'90days'},{label:'6 Months', value:'180days'}];

export const graphTypeArray=[{label:'Line Chart', value:'line'},{label:'Pie Chart', value:'pie'}];
