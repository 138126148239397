/* eslint-disable @typescript-eslint/no-use-before-define */
import React, {
    useState,
    useEffect
} from 'react';
import { useField, useFormikContext } from 'formik';
import { FieldCreatorProps } from '../field-creator';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/types';
import { Tooltip, Button } from 'antd';
import { CaptureSchemaResponse } from '../../../api/workflow-handler';
import { ShowWhenTrue } from '../../../helpers';
import { useSchemaCapture } from '../../../utils/schema-capture-hook';
import Search from '../../search';
import { cloneDeep, isEmpty } from 'lodash';
import { TooltipTop } from '../../tooltips';
import { handleShowCaptureSchemaLogs } from '../../../store/workflow';
import { CaptureSchemaButton } from './CaptureSchemaButton';
import clsx from "classnames";
import { SelectField, _selectoptionType } from '@components/form/select-field';
import { useGetActiveTabInfo } from '@utils/get-active-tab-info';

const Table: React.FC<FieldCreatorProps> = ({ fieldData }) => {
    const [items, setItems] = useState<any>([]);
    const [schemaData, setSchemaData] = useState<any>([]);
    const [schemaValue, setSchemaValue] = useState<any>([]);
    const { setFieldValue, initialValues } = useFormikContext<any>();
    const [isSchemaCaptureInProgress, setSchemaCaptureProgess] = useState(false);
    // const [schemaCaptureRan, setSchemaCaptureRan] = useState(false);
    const [schemaCaptureFailed, setSchemaCaptureFailed] = useState(false);
    const label = fieldData.templateOptions.label;
    const hideCaptureSchema = fieldData.templateOptions.hideCaptureSchema;
    const activeComponentId = useSelector(
        (store: RootState) => store.WorkflowReducer.activeComponentInfo ?.id
    );
    const defaultClusterId = useSelector(
        (store: RootState) => store.ClusterReducer.defaultClusterInfo?.clusterId
    );
    const { activeExecutionEnv } = useSelector((store: RootState) => store.CommonReducer);
    const { activeUserInfo } = useSelector((store: RootState) => store.AccountReducer);
    const activeTab = useGetActiveTabInfo('workflowEditor');
    const [, { value: fieldValue }, {setValue}] = useField(fieldData.key);

    const saveValue = (value: any) => {
        setValue(JSON.stringify(value));
    };

    const getValue = () => {
        try {
            return cloneDeep(JSON.parse(fieldValue) );
        } catch {
            return [];
        }
    };

    const dispatch = useDispatch();

    const addData = () => {
        const items = getValue();
        setItems([...items, []]);
        saveValue([...items, []]);
    };
    
    const removeData = (index: any) => {
        const items = getValue();
        items.splice(index, 1);
        setItems([...items]);
        saveValue([...items]);
    };

    useEffect(() => {
        if(fieldData.templateOptions.options) {
            items.forEach((item: any, count: number) => {
                const label = "options" + fieldData.key + "_" + count
                setFieldValue(label, item[2] || '');
            });
        }
    }, [items]);

    useEffect(() => {
        let data: any = [];
        try {
            data = JSON.parse(initialValues[fieldData.key]);
            setItems(data);
        } catch (e) {
            setItems([]);
        }
    }, [initialValues]);

    const handleSchemaCaptureSuccess = (response: CaptureSchemaResponse[]) => {
        setSchemaCaptureProgess(false);
        // setSchemaCaptureRan(true);
        const __optionsForSchemaCapture: any = [];
        response.map((nodeDataframeInfo, index) => {
            const temp: any = {
                name: connectedNodesInfo[nodeDataframeInfo.componentId],
                id: nodeDataframeInfo.componentId,
                results: [],
                value: `input${index}`
            };
            if (nodeDataframeInfo.dataframes) {
                temp.results = nodeDataframeInfo.dataframes.map(dataframe => ({
                    title: dataframe.name,
                    isSelected: false,

                }));
            }
            __optionsForSchemaCapture.push(temp);
        });
        setSchemaData(__optionsForSchemaCapture);
        if(!fieldData.templateOptions.options) {
            setSchemaValue(__optionsForSchemaCapture);
        }
    };

    const toggleSchemaCaptureInProgress = ()=> {
        setSchemaCaptureProgess(true);
    };

    const handleSchemaCaptureFailure = () =>{
        setSchemaCaptureProgess(false);
        // setSchemaCaptureRan(true);
        setSchemaCaptureFailed(true);
    };

    const { 
        initiateSchemaCapture, 
        // cancelSchemaCapture, 
        sessionId, 
        connectedNodesInfo 
    } = useSchemaCapture({
        componentId: activeComponentId || '', 
        handleSchemaCaptureSuccess, 
        toggleSchemaCaptureInProgress, 
        handleSchemaCaptureFailure,
        activeExecutionEnv,
        workflowUserInfo: { workflowName: activeTab?.name || '', userName: activeUserInfo.name},
        clusterId: defaultClusterId,
        workflowConfig: activeTab?.config
    });


    const handleViewLogs = () => {
        dispatch(handleShowCaptureSchemaLogs(sessionId));
    };

    const onChange = (value: string, index: number, arrIndex: number) => {
        const __items = getValue();
        __items[index][arrIndex] = value;
        saveValue(__items);
    };

    useEffect(() => {
        if(fieldData.templateOptions.options) {
            const tmp: any = [];
            tmp.push({
                name: "options",
                id: "options",
                results: fieldData.templateOptions.options.map((_option)=>{
                    return {
                        title: _option.label,
                        value: _option.value,
                        isSelected: false
                    }
                }),
                value: `options`
            });
            setSchemaValue(tmp);
        }
    }, []);

    return (
        <div className="tableHolder tableComponent">
            <div style={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <div className="align-middle">
                    <label className="inputfield__label">{label}</label>
                    <ShowWhenTrue show={!!fieldData.templateOptions.qtip}>
                        <TooltipTop
                            overlay={fieldData.templateOptions.qtip}
                        >
                            <img
                                src="/icons/info-fields.png"
                                width="16"
                                height="16"
                                className="info__icon"
                                alt="information-icon"
                            />
                        </TooltipTop>
                    </ShowWhenTrue>
                </div>
                <div className="align-middle">
                    {
                        hideCaptureSchema? null :
                        (
                            <div className={clsx("innerCaptureSchemabox useCaptureSchema tableComponent__captureSchema", {
                                schemaCaptureInProgress: isSchemaCaptureInProgress
                            })} style={{
                                height: 25, display: 'flex'
                            }}>
                                {sessionId && (schemaCaptureFailed || !isEmpty(schemaData))&&
                                    <button className="btn-viewLog" onClick={handleViewLogs} type="button">
                                        View log
                                    </button>
                                }
                                <CaptureSchemaButton 
                                    isSchemaCaptureInProgress={isSchemaCaptureInProgress}
                                    initiateSchemaCapture={initiateSchemaCapture}
                                />
                            </div>
                        ) 
                    }
                    <Tooltip overlay="Add Row" placement="top">
                        <img
                            src="/icons/add_tab.svg"
                            style={{
                                width: 25,
                                transform: 'scale(0.55)'
                            }}
                            onClick={() => {
                                addData();
                            }}
                        ></img>
                    </Tooltip>
                </div>
            </div>
            {
                schemaCaptureFailed ? <div className="properties__nomessage" style={{
                    opacity: 1,
                    marginTop: 10,
                    lineHeight: 'initial',
                    color: '#FFCCCC'
                }}>Schema capture failed, Please retry.</div> : null
            }
            <table style={{
                width: '100%',
                marginTop: 10
            }}>
                <tbody>
                    {
                        (items || [] ).length === 0 ? <span className='white inputfield__label'>No Data Available</span> : null
                    }
                    {
                        items ?.map((item: any, index: number) => {
                            return (
                                <tr key={`result__${index}`}>
                                    <td>
                                        <Search schemaData={schemaData} data={item[0]} index={index} hideShow={!!fieldData.templateOptions.options} onChange={(value: string, index: number) => {
                                            onChange(value, index, 0);
                                        }} />
                                    </td>
                                    <td > 
                                        { fieldData.templateOptions.options  ? 
                                        (
                                            <SelectField 
                                                name={"options" + fieldData.key + "_" + index}  
                                                onOptionClick={(option: _selectoptionType) => {
                                                    onChange(option.value, index, 2);
                                                }}
                                                options={fieldData.templateOptions.options as _selectoptionType[]} 
                                                key={"options" + fieldData.key + "_" + index}  
                                            />
                                        ) : '='} 
                                    </td>
                                    <td>
                                        <Search schemaData={schemaValue} data={item[1]} index={index} hideShow={!!fieldData.templateOptions.options} onChange={(value: string, index: number) => {
                                            onChange(value, index, 1);
                                        }} />
                                    </td>
                                    <td>
                                        <Tooltip
                                            placement="topLeft"
                                            overlay="Remove row">
                                            <img
                                                onClick={() => {
                                                    removeData(index);
                                                }}
                                                src="/icons/close_tab.svg"
                                                alt="" />
                                        </Tooltip>
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        </div>
    );
};

export default Table;