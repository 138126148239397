import { Modal } from "@components/modals";
import {
	getTabType,
	updateAWorkflowEditorTabInfo,
	WorkflowCanvasTabInfo,
} from "@store/canvas";
import { RootState } from "@store/types";
import {
	saveCurrentWorkflowTabState,
	setCurrentWorkflowStateToUnsaved,
	setWorkflowsListInStore,
} from "@store/workflow";
import { useGetActiveTabInfo } from "@utils/get-active-tab-info";
import classNames from "classnames";
import { cloneDeep } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedValue, useDidUpdate } from "rooks5";
import { NoteEditor } from "./NoteEditor";
import styles from "./styles.module.scss";

type Props = {
	isModalOpen: boolean;
	closeModal: () => void;
	visible: boolean;
};

export const WorkflowNote = ({ isModalOpen, closeModal, visible }: Props) => {
	const [notes, setNotes] = useState("");
	const [debouncedNotes] = useDebouncedValue(notes, 500);
	const [lastSaved, setLastSaved] = useState<Date | null>();
	const [activeWorkflowId, setActiveWorkflowId] = useState<number>();
	const activeTabInfo = useGetActiveTabInfo("workflowEditor") as
		| WorkflowCanvasTabInfo
		| undefined;
	const { openTabs } = useSelector(
		(store: RootState) => store.CanvasReducer.workflowEditor
	);
	const { workflows } = useSelector(
		(store: RootState) => store.WorkflowReducer
	);
	const dispatch = useDispatch();

	// To handle undo/redo
	const notesHistory = useRef<string[]>([]);
	const historyIndex = useRef(0);

	const undoOrRedo = useCallback((e: KeyboardEvent) => {
		if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === "z") {
			e.preventDefault();
			historyIndex.current =
				historyIndex.current + 1 < notesHistory.current.length
					? historyIndex.current + 1
					: notesHistory.current.length - 1;
			setNotes(notesHistory.current[historyIndex.current]);
			return;
		}

		if ((e.ctrlKey || e.metaKey) && e.key === "z") {
			e.preventDefault();
			historyIndex.current =
				historyIndex.current - 1 > 0 ? historyIndex.current - 1 : 0;
			setNotes(notesHistory.current[historyIndex.current]);
		}
	}, []);

	useEffect(() => {
		if (activeWorkflowId === activeTabInfo?.id) {
			return;
		}
		setActiveWorkflowId(activeTabInfo?.id);
		const note = activeTabInfo?.notes ?? "";
		setNotes(note);
		setLastSaved(null);
		notesHistory.current = [note];
		historyIndex.current = 0;
	}, [activeTabInfo]);

	const onSave = useCallback(
		(note: string) => {
			const selectedWorkflowData =
				activeTabInfo && openTabs.get(activeTabInfo.id);
			selectedWorkflowData &&
				dispatch(
					updateAWorkflowEditorTabInfo({
						type: getTabType(
							"workflowEditor",
							selectedWorkflowData.info.env
						),
						info: { ...selectedWorkflowData.info, notes: note },
					})
				);

			const updatedWorkflows = cloneDeep(workflows);
			updatedWorkflows.results = updatedWorkflows.results.map(
				(workflow) => {
					workflow.notes =
						workflow.id === activeTabInfo?.id
							? note
							: workflow.notes;
					return workflow;
				}
			);

			// Push note to history only if it's not undo / redo change
			if (note !== notesHistory.current[historyIndex.current]) {
				historyIndex.current += 1;
				notesHistory.current = notesHistory.current.slice(
					0,
					historyIndex.current
				);
				notesHistory.current = [...notesHistory.current, note];
			}

			dispatch(setWorkflowsListInStore(updatedWorkflows));
			dispatch(setCurrentWorkflowStateToUnsaved());
			dispatch(saveCurrentWorkflowTabState());
			setLastSaved(new Date());
		},
		[activeTabInfo, openTabs, dispatch]
	);

	const onChange = (note: string) => {
		// Execute browser undo command to immediately undo the change. This is to prevent the browser undo event from triggering for workflow notes.
		document.execCommand("undo");

		setNotes(note);
	};

	useDidUpdate(() => {
		if (notes === activeTabInfo?.notes) {
			return;
		}
		onSave(notes);
	}, [debouncedNotes]);

	const onFocus = () => {
		document.addEventListener("keydown", undoOrRedo);
	};

	const onBlur = () => {
		document.removeEventListener("keydown", undoOrRedo);
	};

	return (
		<div
			className={classNames(styles.workflowNoteWrapper, {
				[styles.visible]: visible,
			})}
		>
			<NoteEditor
				lastSaved={lastSaved}
				placeholder="Add notes to yourself here..."
				note={notes}
				onSave={onSave}
				isVisible={true}
				onChange={onChange}
				hideActionButtons
				onFocus={onFocus}
				onBlur={onBlur}
				lastSavedPosition="TopRight"
			/>

			<Modal
				title={
					<span className={styles.modalTitle}>
						{activeTabInfo?.name}
					</span>
				}
				image="/icons/notification/workflow.svg"
				subtitle={<span className={styles.modalSubtitle}>NOTES</span>}
				className={styles.modal}
				isOpen={isModalOpen}
				toggleClose={closeModal}
				showCloseMark
			>
				<div className="properties__fields__container">
					<NoteEditor
						lastSaved={lastSaved}
						note={notes}
						onChange={onChange}
						onSave={(noteText: string) => {
							onSave(noteText);
							closeModal();
						}}
						hideActionButtons
						onFocus={onFocus}
						onBlur={onBlur}
						isVisible={isModalOpen}
						lastSavedPosition="TopRight"
					/>
				</div>
			</Modal>
		</div>
	);
};
