import React from 'react';
import { Modal } from '../../../components/modals';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/types';
import { successAlert } from '../../../components/toastify/notify-toast';
import { TagsHandler } from '../../../api/tags-handler';
import { toggleTagBrowserModal } from '../../../store/tag-browser';

export interface DeleteSiteSyncSetModalProps {
    itemInfo: { id: number; name: string; type: 'site' | 'syncset'};
    handleDeleteSuccess: () => any;
}

export const DeleteSiteSyncSetModal: React.FC<DeleteSiteSyncSetModalProps> = ({ itemInfo, handleDeleteSuccess }) => {
    const dispatch = useDispatch();
    const  { deleteAction: showModal } = useSelector((store: RootState) => store.TagBrowserReducer.showModal);
    const title = `Delete ${itemInfo.name}`;

    function toggleClose() {
        dispatch(toggleTagBrowserModal('deleteAction', false));
    }

    function handleComponentDeleteSuccess() {
        handleDeleteSuccess();
        successAlert(`${itemInfo.name} deleted successfully.`);
        toggleClose();
    }


    function handledeleteComponent() {
        if(itemInfo.type === 'site') {
            TagsHandler.DeleteClientSite(itemInfo.id, handleComponentDeleteSuccess);
        } else {
            TagsHandler.DeleteSyncSet(itemInfo.id, handleComponentDeleteSuccess);
        }
    }

    return (
        <Modal
            isOpen={showModal}
            toggleClose={toggleClose}
            title={title}
            className="deleteWorkflowModal"
        >
            <p className="deleteWorkflowModal__areYouSureMsg">Are you sure you want to delete the {itemInfo.name}? </p>
            <p className="deleteWorkflowModal__warningMsg">Warning: You can&apos;t undo this action!</p>
            <div className="modalBtns__box">
                <button
                    className="btn-md btn-yellow"
                    onClick={handledeleteComponent}
                    type="submit"
                >
                    Yes
                </button>
                <button
                    className="btn-md btn-cancel"
                    type="button"
                    onClick={toggleClose}
                >
                    Cancel
                </button>
            </div>
        </Modal>
    );
};