
import { store } from '../store';
import { useHistory } from 'react-router-dom';
import { _selectoptionType } from '../components/form/select-field';
import { RESET_STATE } from '../store/types';
import { ExecutionEnvModes } from '../constants/enums';
import { number, string } from 'yup';
import { uuid } from 'uuidv4';

export const convertToSelectFieldOptions = (items: string[]): _selectoptionType[] => (
    items.map(opt => ({ label: opt, value: opt })));

export const convertEnumsToOptions = (_enum: Record<string, any>): _selectoptionType[] => 
    Object.entries(_enum).map(([label, value]) => ({label, value}));

    
export function resetStoreToInitialState() {
    const dispatch = store.dispatch;
    setTimeout(() => {
        dispatch({ type: RESET_STATE });
    }, 1000);
    
}

export function logout(history: ReturnType<typeof useHistory>) {
    localStorage.clear();
    history.push('/');
    resetStoreToInitialState();
}


export const saveTextToFile = (filename: string, text: string) => {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};

export const getActiveWorkflowType = (activeExecutionEnv: ExecutionEnvModes) => activeExecutionEnv === ExecutionEnvModes.Pipelines ? 'pipeline': 'local';


export enum TimePeriod {
    oneHour = 60*60,
    oneMin = 60
}

const addZeroPrefixIfRequired = (s: number) => {
    const _s = s.toString();
    if(_s.length === 1) return '0' + _s;
    return s;
}; 

export const getDurationFromSeconds = (seconds: number) => {
    let hours = 0, mins = 0, __seconds = seconds;
    const secs = Math.floor(seconds % TimePeriod.oneMin);
    if(__seconds >= TimePeriod.oneHour) {
        hours = Math.floor(__seconds / TimePeriod.oneHour);
        __seconds = __seconds - (hours * TimePeriod.oneHour);
    }  
    if(__seconds >= TimePeriod.oneMin) {
        mins = Math.floor(__seconds / TimePeriod.oneMin);

    }
        


    return `${addZeroPrefixIfRequired(hours)}:${addZeroPrefixIfRequired(mins)}:${addZeroPrefixIfRequired(secs)}`;
};

export const roundNumberToString = (num: number, precision: number) => {
    // @ts-ignore
    const roundedNumber = Number(Math.round(num + "e+" + precision) + "e-" + precision)
    if(typeof(roundedNumber) === 'number' && isNaN(roundedNumber)) {
        return num?.toPrecision(precision)        
    }
    return roundedNumber.toString()
}

// export const roundNumberToString = (num: number, precision: number) => roundNumber(num, precision).toString()
export const getBooleanCheckboxOptions = (label: string, infoText?: string) => 
    [{label, value: 'true', infoText }]


export const getRequiredFieldSchema = (fieldName: string) => string().required(fieldName + " is a required field")
export const getRequiredNumberFieldSchema = (fieldName: string) => number().required(fieldName + " is a required field").typeError('This must be a number.')


export const getUniqueId = () => uuid();
