import React from "react";
import { ShowWhenTrue } from "@helpers/showwhentrue";
import {
	GreenTickIcon,
	RedDeleteIcon,
	WarningIcon,
} from "@pages/adminstrator_old/icons";
import { Progress } from "antd";
import { FileListRendererProps } from "./types";
import { Flex } from "@components/ui/Flex";
import { Text } from "@components/ui/Text";
import { Icons } from "@components/FileExplorer/Icons";

export const FileListRenderer: React.FC<FileListRendererProps> = ({
	title,
	filesList,
	filesMetaData,
	handleDeleteFile,
}) => (
	<Flex width="100%" flexDirection="column" p="24px" gap="16px">
		<Text color="#C7C7CD" fontSize="12px" fontWeight="bold">
			{title} ({filesList.length})
		</Text>
		<Flex width="100%" flexDirection="column" gap="20px" pb="24px">
			{filesList.map((file) => {
				const fileInfo = filesMetaData[file.id];
				const Icon =
					Icons[fileInfo.type.split("/").pop() || "unknown"] ||
					Icons.unknown;
				return (
					<Flex
						key={file.id}
						justifyContent="space-between"
						alignItems="center"
					>
						<Flex alignItems="center">
							<Flex
								className="file-list-renderer-list-icon"
								position="relative"
							>
								<Icon />
								<ShowWhenTrue
									show={fileInfo.status !== "IN_PROGRESS"}
								>
									<Flex
										position="absolute"
										top="10px"
										right="4px"
									>
										{fileInfo.status === "SUCCESS" && (
											<GreenTickIcon />
										)}
										{(fileInfo.status === "FAILED" || fileInfo.status === "DELETED") && (
											<WarningIcon />
										)}
									</Flex>
								</ShowWhenTrue>
							</Flex>
							<Flex flexDirection="column">
								<Text fontSize="10px" color="#FFFFFF" lineBreak="anywhere">
									{file.file.name}
								</Text>
								<Flex
									color="#C7C7CD"
									alignItems="center"
									gap="4px"
								>
									<Text fontSize="10px">
										{fileInfo.uploadedAt}
									</Text>
									<Flex
										width="4px"
										height="4px"
										borderRadius="50%"
										bg="#9495A0"
									/>
									<Text fontSize="10px">{fileInfo.size}</Text>
									{(fileInfo.status === "FAILED" ||
										fileInfo.status === "DELETED") && (
										<>
											<Flex
												width="4px"
												height="4px"
												borderRadius="50%"
												bg="#9495A0"
											/>
											<Text fontSize="10px">
												{fileInfo.status === "FAILED"
													? "Upload Failed"
													: "File Deleted"}
											</Text>
										</>
									)}
								</Flex>
							</Flex>
						</Flex>
						{fileInfo.status === "IN_PROGRESS" && (
							<Flex width="36px" height="36px">
								<Progress
									type="circle"
									percent={fileInfo.uploadedPer}
									width={40}
									strokeColor="#FCAD01"
									trailColor="#9495A0"
								/>
							</Flex>
						)}
						{fileInfo.status === "SUCCESS" && (
							<>
								<button
									id="btn_deleteFile"
									onClick={(e) => {
										e.stopPropagation();
										handleDeleteFile(
											file.id,
											file.file.name
										);
									}}
								>
									<RedDeleteIcon />
								</button>
							</>
						)}
					</Flex>
				);
			})}
		</Flex>
	</Flex>
);
