import { Button } from "antd";
import React from "react";
import { Dropdown, DropdownOptions } from "../../form/dropdown";
import { TooltipTop } from "../../tooltips";
import { CaptureSchemaIcon } from "../icons";
import clsx from "classnames";

type CaptureSchemaButtonProps = {
    isSchemaCaptureInProgress: boolean;
    initiateSchemaCapture: (removeLimit ?: boolean) => void;
    className ?: string;
}

const CaptureSchemaButton: React.FC<CaptureSchemaButtonProps> = ({ isSchemaCaptureInProgress, initiateSchemaCapture, className="" }) => {
    // const captureSchemaDropdownOptions = () => {
    const options: DropdownOptions = {
        'CHOOSE A MODE': {action: () => {return;}, disabled: true},
        'Quick Capture (Default)': {action: () => initiateSchemaCapture(false)},
        'Full Capture': {action: () => initiateSchemaCapture(true)}, 
    };
        // return options;
    // };
    
    
    return(
        <div className={clsx("btnCaptureSchema__outer", className)}>
            <TooltipTop overlay="Populate Schema" placement="top">
                <Button
                    onClick={options['Quick Capture (Default)'].action}
                    className="btn-yellow btn-capture"                      
                    size="small"
                    loading={isSchemaCaptureInProgress}
                >
                    {!isSchemaCaptureInProgress && 
                    <>
                        <CaptureSchemaIcon />
                        {/* <span>Capture</span> */}
                    </>
                    }
                </Button>
            </TooltipTop>
            {!isSchemaCaptureInProgress &&
                <Dropdown
                    title={
                        <span className="btn-yellow btn__dropdown__triangle">
                            &#x25BE;
                        </span>
                    }
                    position="right"
                    dropdownOptions={options}
                    className="runWorkflow__options"
                />
            }
        </div>
    )
}

export { CaptureSchemaButton };