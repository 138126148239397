import { WorkspaceData } from '@pages/cluster_redisign/types';
import { AxiosResponse } from 'axios';
import { Cluster, ClusterDetail, ClusterModeType, AccessNodeType, UsersType, ClusterState, DataBricksVersionListType, NodeType } from '../../api/databricks-handler';
import { NewDatabricksHandlerClass } from '../../api/databricks-handler/handler';
import { errorHandlerFor400 } from '../../api/services/errorhandler';
import { AppThunk } from '../types';
import { GET_CLUSTERS, UPDATE_CLUSTER_STATUS, UPDATE_CLUSTER_DEFAULT, CREATED_BY_ME, DELETE_CLUSTER, ADD_CLUSTER, GET_NODE_TYPE_LIST, GET_CLUSTER_MODE, GET_ACCESS_NODE, GET_USERS_LIST, GET_DATABRICKS_VERSION_LIST, SET_WORKSPACE_LIST, SET_ROUTE_OPTIMIZATION_LIST } from './constants';


export const getAllClusters = (): AppThunk => (dispatch) => {
    const dispatchClusterList = (clusterList: Cluster[]) => {
        dispatch({ type: GET_CLUSTERS, payload: { 'clusters': clusterList } });
    };
    NewDatabricksHandlerClass.GetAllClusterList(dispatchClusterList);
};

export const restartCluster = (clusterId: string): AppThunk => (dispatch) => {
    const dispatchClusterStart = () => {
        dispatch({ type: UPDATE_CLUSTER_STATUS, payload: { clusterId: clusterId, newState: ClusterState.RESTARTING } });
    };
    NewDatabricksHandlerClass.RestartCluster(clusterId, dispatchClusterStart);
};

export const startCluster = (clusterId: string): AppThunk => (dispatch) => {
    const dispatchClusterReStart = () => {
        dispatch({ type: UPDATE_CLUSTER_STATUS, payload: { clusterId: clusterId, newState: ClusterState.PENDING } });
    };
    NewDatabricksHandlerClass.startCluster(clusterId, dispatchClusterReStart);
};

export const terminateCluster = (clusterId: string): AppThunk => (dispatch) => {
    const dispatchClusterTerminate = () => {
        dispatch({ type: UPDATE_CLUSTER_STATUS, payload: { clusterId: clusterId, newState: ClusterState.TERMINATING } });
    };
    NewDatabricksHandlerClass.TerminateCluster(clusterId, dispatchClusterTerminate);
};

export const changeClusterDefault = (clusterId: string, defaultCluster: boolean): AppThunk => (dispatch) => {
    const dispatchUpdateClusterDefault = () => {
        dispatch({ type: UPDATE_CLUSTER_DEFAULT, payload: { clusterId: clusterId, defaultCluster: defaultCluster } });
    };
    NewDatabricksHandlerClass.UpdateClusterDefaultStatus(clusterId, defaultCluster, dispatchUpdateClusterDefault);
};

export const deleteCluster = (clusterId: string, successCb?: () => void, failureCb?: () => void): AppThunk => (dispatch) => {
    const dispatchClusterDelete = () => {
        successCb && successCb();
        dispatch({ type: DELETE_CLUSTER, payload: { clusterId: clusterId } });
    };
    NewDatabricksHandlerClass.DeleteCluster(clusterId, dispatchClusterDelete, failureCb);
};

export const addCluster = (clusterDetail: Omit<ClusterDetail, 'modelType' >,
    onSuccessHandler: () => void,
    onFailureHandler: () => void): AppThunk => (dispatch) => {

    const dispatchAddCluster = (cluster: Cluster) => {
        dispatch({ type: ADD_CLUSTER, payload: { cluster: cluster } });
        onSuccessHandler();
    };
    const errorCallbackHandler = (errorResponse: AxiosResponse) => {
        onFailureHandler();
        errorHandlerFor400(errorResponse);
    };
    NewDatabricksHandlerClass.CreateNewCluster(clusterDetail, dispatchAddCluster, errorCallbackHandler);
};

export const getNodeTypeList = (): AppThunk => (dispatch: any) => {
    const dispatchNodeTypeList = (nodeTypeList: NodeType[]) => {
        dispatch({ type: GET_NODE_TYPE_LIST, payload: { 'nodeTypeList': nodeTypeList } });
    };
    NewDatabricksHandlerClass.GetNodeTypeList(dispatchNodeTypeList);
};

export const getClusterMode = (): AppThunk => (dispatch: any) => {
    const dispatchNodeTypeList = (clusterModeList: ClusterModeType[]) => {
        dispatch({ type: GET_CLUSTER_MODE, payload: { 'custerModeList': clusterModeList } });
    };
    NewDatabricksHandlerClass.GetClusterMode(dispatchNodeTypeList);
};

export const getAccessNode = (): AppThunk => (dispatch: any) => {
    const dispatchAccessNodeList = (accessNodeList: AccessNodeType[]) => {
        dispatch({ type: GET_ACCESS_NODE, payload: { 'accessNodeList': accessNodeList } });
    };
    NewDatabricksHandlerClass.GetAccessNode(dispatchAccessNodeList);
};

export const getUsersList = (): AppThunk => (dispatch: any) => {
    const dispatchUsersList = (usersList: UsersType[]) => {
        dispatch({ type: GET_USERS_LIST, payload: { 'usersList': usersList } });
    };
    NewDatabricksHandlerClass.GetUsersList(dispatchUsersList);
};

export const getDataBricksVersionList = (): AppThunk => (dispatch: any) => {
    const dispatchNodeTypeList = (databricksVersionList: DataBricksVersionListType[]) => {
        dispatch({ type: GET_DATABRICKS_VERSION_LIST, payload: { 'databricksVersionList': databricksVersionList } });
    };
    NewDatabricksHandlerClass.GetDataBricksRunTimeVersion(dispatchNodeTypeList);
};

export const getCluster = (clusterId: string,
    onSuccessHandler: (clusterDetail: ClusterDetail) => void): AppThunk => () => {

    const successCallbackHandler = (clusterDetail: ClusterDetail) => {
        onSuccessHandler(clusterDetail);
    };
    NewDatabricksHandlerClass.GetCluster(clusterId, successCallbackHandler);
};

export const updateCluster = (clusterDetail: Omit<ClusterDetail, 'modelType' >,
    onSuccessHandler: () => void,
    onFailureHandler: () => void): AppThunk => (dispatch) => {

    const successCallbackHandler = () => {
        dispatch(getAllClusters());
        onSuccessHandler();
    };
    const errorCallbackHandler = (err: AxiosResponse) => {
        onFailureHandler();
        errorHandlerFor400(err);
    };
    NewDatabricksHandlerClass.UpdateCluster(clusterDetail, successCallbackHandler, errorCallbackHandler);
};

export const toggleCreatedByMe = (): AppThunk => (dispatch) => {
    dispatch({ type: CREATED_BY_ME });
};

export const setWorkspaceList = (payload: WorkspaceData[]) =>
    ({ type: SET_WORKSPACE_LIST, payload });

export const setRouteOptimizationist = (payload: any) =>
    ({ type: SET_ROUTE_OPTIMIZATION_LIST, payload });