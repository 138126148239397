import React from 'react';
import { Modal } from '../../../components/modals';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/types';
import {  DeleteCustomComponentResponse } from '../../../api/new-custom-component';
import { successAlert,errorAlert } from '../../../components/toastify/notify-toast';
import { DataSourceHandler } from '../../../api/data-source-handler';
import { toggleDataBrowserModal } from '../../../store/datasources';

interface DeleteConnectionModalProps {
    selectedConnectionInfo: { id: number; name: string };
    onDeleteConnSuccess: () => void;
}

export const DeleteConnectionModal: React.FC<DeleteConnectionModalProps> = ({ selectedConnectionInfo, onDeleteConnSuccess }) => {
    const dispatch = useDispatch();
    const { deleteConnection: showModal } = useSelector((store: RootState) => store.NewDataSourceReducer.showModal);

    function toggleClose() {
        dispatch(toggleDataBrowserModal('deleteConnection', false));
    }

    function handleConnectionDeleteSuccess(response: DeleteCustomComponentResponse) {
        onDeleteConnSuccess();
        // if (response.success) {
        successAlert(response.message);
        toggleClose();
        // }
    }

    function handleConnectionDeleteError(error: any) {
        toggleClose();
        errorAlert(error.response.data.message);
    }

    function handledeleteConnection() {
        DataSourceHandler.DeleteConnection(selectedConnectionInfo.id, handleConnectionDeleteSuccess, handleConnectionDeleteError);
    }

    return (
        <Modal
            isOpen={showModal}
            toggleClose={toggleClose}
            title={`Delete ${selectedConnectionInfo.name} Connection`}
            className="deleteWorkflowModal"
        >
            <p className="deleteWorkflowModal__areYouSureMsg">Are you sure you want to delete the selected connection?  </p>
            <p className="deleteWorkflowModal__warningMsg">Warning: You can&apos;t undo this action!</p>
            <div className="modalBtns__box">
                <button
                    id={'Workfflow_deletemodal_btn'}
                    className="btn-md btn-yellow"
                    onClick={handledeleteConnection}
                    type="submit"
                >
                    Yes
                </button>
                <button
                    id={'Workfflow_deletemodal_cancelbtn'}
                    className="btn-md btn-cancel"
                    type="button"
                    onClick={toggleClose}
                >
                    Cancel
                </button>
            </div>
        </Modal>
    );
};