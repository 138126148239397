import { has, isEmpty } from 'lodash';
import { HelpActionTypes, SET_DOCS_LIST, TOGGLE_DOC_SPINNER } from '.';
import { DocsHandler } from '../../api/docs-handler';
import { Env } from '../../constants/settings';
import { parseDocDataForHeadings } from '../../pages/workflow-page/utils';
import { WorkflowCanvasTabInfo } from '../canvas';
import { AppDispatch, AppThunk } from '../types';
import { HelpReducerState, SET_ACTIVE_HELP_DOC, SET_DEMO_WORKFLOWS_HELP_DOC_LIST, TOGGLE_HELP_BULB_ANIMATION, TOGGLE_SHOW_HELP } from './types';


export const toggleDocSpinner = (action?: boolean): AppThunk => (dispatch, getState) =>  {
    if(action === undefined) {
        action = !getState().HelpReducer.showDocSpinner;
    }
    dispatch({ type: TOGGLE_DOC_SPINNER, payload: action });
};

    
export const toggleHelpTab = (): HelpActionTypes => 
    ({ type: TOGGLE_SHOW_HELP, payload:'' });
// export const hideSpinner = (spinnerType: SpinnerTypes): CommonActionTypes => ({type: HIDE_SPINNER, payload: spinnerType });


export const saveHelpDemoWorkflows = (workflowHelpDocName: string, dispatch: AppDispatch) => {
    DocsHandler.GetDocByName(workflowHelpDocName, (data) => {
        const helpWorkflowsList = parseDocDataForHeadings(data);
        const payload: HelpReducerState['helpDemoWorkflowsInfo'] = { headings: helpWorkflowsList, data };
        // const imageUrls = generateImageUrlsFromHelpDoc(workflowHelpDocName ,t.data);
        // console.log(imageUrls)
        // preloadDocImages(imageUrls);
        return dispatch({ type: SET_DEMO_WORKFLOWS_HELP_DOC_LIST, payload });
    });
};


export const toggleHelpBulbAnimation = (action: boolean): HelpActionTypes => 
    ({ type: TOGGLE_HELP_BULB_ANIMATION, payload: action });


export const DEMO_WORKFLOW_DOC_TITLE = 'Demo Workflows Help';

export const setActiveDoc = (docInfo: HelpReducerState['activeHelpDoc'])=> ({ type: SET_ACTIVE_HELP_DOC, payload: docInfo });

export const getDocByName = (docName: string): AppThunk => (dispatch, getState) => {
    DocsHandler.GetDocByName(docName, (data) => {
        const docInfo: HelpReducerState['activeHelpDoc'] = { title: docName, data };
        dispatch(setActiveDoc(docInfo));
        const { showDocSpinner } = getState().HelpReducer;
        if(showDocSpinner &&  !data.match(/!\[(.*?)\]\((.*?)\)/g)){
            dispatch(toggleDocSpinner(false));
        }
    });
}; 

export const getAllDocs = (): AppThunk => (dispatch) => {
    DocsHandler.GetAllDocs(docsList => {
        let _docs: string[] = [];
        const orderDic = {
            'general overview of datastudio': 0,
            'general component help': 1,
            'custom component': 2,
            'how to create a workflow': 3,
            'how to run, preview and schedule workflow': 4,
            'loop component': 5,
            'sample workflows - pi data ingestion': 6,
            'variables': 7,
            'ml pipeline': 8,
            'model management': 9,	
            'overview of code editor in datastudio': 10,
            'templates - master, data quality, uom': 11
        };
        docsList.forEach((doc) => {
            if(has(orderDic, doc.toLowerCase())) {
                const _order = orderDic[doc.toLowerCase() as keyof typeof orderDic];
                _docs[_order] = doc;
            }
        });
        _docs = _docs.filter(doc => !!doc);
        if(isEmpty(!_docs)) {
            // PreSelect General Overview of Datastudio
            dispatch(getDocByName(_docs[0]));

            if(Env.userNameForDemoWorkflows) {
                // Get demo workflows help doc
                const workflowHelpDocName = docsList.find(w => w.toLowerCase().includes(DEMO_WORKFLOW_DOC_TITLE.toLowerCase()));
                workflowHelpDocName && saveHelpDemoWorkflows(workflowHelpDocName, dispatch as unknown as AppDispatch);
            }
        }
        dispatch({ type: SET_DOCS_LIST, payload: _docs });
    });
}; 



export const checkIfCurrentWorkflowHasHelpDocs = (workflowName: string, helpDemoWorkflowsInfo: HelpReducerState['helpDemoWorkflowsInfo']): WorkflowCanvasTabInfo['helpDocRef'] => {
    workflowName = workflowName.toLowerCase();
    if(workflowName.includes('pi data ingestion')) {
        const workflowHasDocs = helpDemoWorkflowsInfo.headings.find(heading => workflowName.includes(heading.label.toLowerCase()) );
        if(workflowHasDocs) {
            return ({ docTitle: DEMO_WORKFLOW_DOC_TITLE, subHeadingTitle: workflowHasDocs.label, subHeadingSlug: workflowHasDocs.value, docData: helpDemoWorkflowsInfo.data } );
        }
    }
    
    return null;
};