import { ClusterDetail } from '../../../api/databricks-handler';
import { dbfsPrefix } from '../../../components/form/dynamic-field-generator-cluster';
import { AUTOSCALE, ENVVARIABLES_SEPARATOR, schemaTypes, SEPARATOR_TYPE, SPARKCONFIG_SEPARATOR, SPOT_FALL_BACK_TO_OD, SPOT_INSTANCES, TERMINATE } from './form-schema';

/**
 * concats key and value with sperator and returns a string
 * @param object expects key value pair object
 * @param separator expects a type within SEPERATOR_TYPE 
 * @returns a concatinated string
 */
export const getSparkFieldString = (object: Record<string, string>, separator: SEPARATOR_TYPE) => {
    let convertedString = '';
    if(object){
        for (const [key, value] of Object.entries(object)){
            const concatedValue = `${key}${separator}${value}`;
            if(convertedString){
                convertedString = convertedString.concat(`\n${concatedValue}`);
            }else{
                convertedString = concatedValue;
            }
        }
    }
    return convertedString;
};

/**
 * split the string based on seperator and returns a key value pair object
 * @param str expects a string
 * @param separator expects a type within SEPERATOR_TYPE
 * @returns a converted key value pair object
 */
export const getKeyValuePairObject = (str: string, separator: SEPARATOR_TYPE) => {
    const convertedObj: Record<string, string> = {};
    if(str){
        const regex = separator === ENVVARIABLES_SEPARATOR ? /=(.+)/ : / (.+)/;
        const configArr = str.split('\n').filter(e => e.trim().length > 0);
        configArr.map(element => {
            const data = element.split(regex).filter(e => e.trim().length > 0);
            return convertedObj[data[0].trim()] = data[1].trim();
        });
    }
    return convertedObj;
};

/**
 * validates the string to check if it's a keyvaluepair
 * @param str expects a string
 * @param separator expects a type within SEPERATOR_TYPE
 * @returns true if valid keyvaluepair else false for invalid key pair
 */
export const keyValuePairValidator = (str: string, separator: SEPARATOR_TYPE) => {
    if (str) {
        const valueList = str.split('\n').filter((e: string) => e.trim().length > 0);
        const validatedArr = valueList.map((str: string) => {
            const seperatorIndex = str.trim().indexOf(separator);
            return seperatorIndex > 0 && seperatorIndex < str.length-1;
        });
        return !validatedArr.includes(false);
    }
    return true;
};

/**
 * format array of type initscripts to array of objects
 * @param data expects a list with type of initscript
 * @returns 
 */
export const getInitScriptsList = (data: Record<string, {destination: string}>[]) => {
    const initscriptArray: any = [];
    data.forEach((values: Record<string, {destination: string}>) => {
        for (const [key, value] of Object.entries(values)){
            const convertedValue = value.destination.replace(dbfsPrefix,'');
            const set = {[key]:convertedValue};
            initscriptArray.push(set);
        }
    });
    return initscriptArray;
};

/**
 * converts cluster details to cluster form object
 * @param clusterDetail expects an object of type ClusterDetail  
 * @returns a cluster form value object
 */
 export const getClusterFormValues = (clusterDetail: ClusterDetail) => {
    const _enableAutoScaling = clusterDetail.autoscale ? AUTOSCALE : '';
    const _spotInstances = clusterDetail.isSpotInstances ? SPOT_INSTANCES : '';
    const _terminate = clusterDetail.autoterminationMinutes === 0 ? '' : TERMINATE;
    const _minutesOfInactivity = _terminate === TERMINATE ? clusterDetail.autoterminationMinutes : '';
    const _sparkConfig = clusterDetail.sparkConf ? getSparkFieldString(clusterDetail.sparkConf, SPARKCONFIG_SEPARATOR):'';
    const _sparkEnvVars = clusterDetail.sparkConf ? getSparkFieldString(clusterDetail.sparkEnvVars, ENVVARIABLES_SEPARATOR):'';
    const _initScripts = clusterDetail.initScripts ? getInitScriptsList(clusterDetail.initScripts): [];
    const _public = clusterDetail.npipWorkSpace? false : true || false; 
    
    const formValues: schemaTypes = {
        name: clusterDetail.clusterName,
        enableAutoScaling: _enableAutoScaling,
        terminate: _terminate,
        minutesOfInactivity: Number(_minutesOfInactivity),
        workerType: clusterDetail.nodeTypeId,
        minWorkers: clusterDetail.autoscale ?.min_workers || 0,
        maxWorkers: clusterDetail.autoscale ?.max_workers || 0,
        driverType: clusterDetail.driverNodeTypeId,
        noOfWorkers: clusterDetail.numWorkers,
        spotInstances: _spotInstances,
        sparkConfig: _sparkConfig,
        sparkEnvVars: _sparkEnvVars,
        initScripts: _initScripts,
        npipWorkSpace: _public,
        clusterModeId: (clusterDetail.clusterModeId || '2') + '',
        dataSecurityMode: (clusterDetail.dataSecurityMode|| "CUSTOM")+ '',
        singleUserName: clusterDetail.singleUserName,
        sparkRunTimeVersion: clusterDetail.sparkRunTimeVersion || '',
        modelType: 'cluster',
        showDocker: clusterDetail.useDockerImage === true ? 'true': '',
        dockerContainer: clusterDetail.dockerContainer,
        firstOnDemand: clusterDetail.awsAttributes?.first_on_demand || 1,
        spotFallbackToOD: clusterDetail.awsAttributes?.availability === 'SPOT_WITH_FALLBACK' ? SPOT_FALL_BACK_TO_OD : 'SPOT',
    };
    return formValues;
};