import React, { useMemo, useState } from "react";
import MonacoEditor from "react-monaco-editor";
import * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';
import 'monaco-editor/esm/vs/basic-languages/monaco.contribution';
import 'monaco-editor/esm/vs/editor/edcore.main';
import 'monaco-editor/esm/vs/language/typescript/monaco.contribution';
import 'monaco-editor/esm/vs/editor/standalone/browser/standaloneServices';
import 'monaco-editor/esm/vs/language/json/monaco.contribution';
import { ClearIcon, CopyIcon } from "../../assets/icons";
import { editorOptions } from "../form/editor";
import { infoAlert } from "../toastify/notify-toast";
import styles from "./styles.module.scss";
import { TooltipTop } from "../tooltips";


type CustomMonacoEditorControllerProps = {
    initialValue ?: string;
}

type useCustomMonacoEditorController = (props?: CustomMonacoEditorControllerProps) => {
    editorValue: string;
    setEditorValue: (arg0: string) => void;
    clearEditor: () => void;
    copyText: () => void;
}

const useCustomMonacoEditorController: useCustomMonacoEditorController = ({ initialValue = '' } = { initialValue: ''}) => {
    const [editorValue, setEditorValue] = useState(initialValue);


    const copyText = () => {
        const el = document.createElement('textarea');
        el.value = editorValue;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        infoAlert('Copied to Clipboard.');
    };

    const clearEditor = () => {
        setEditorValue('');
    };

    return({
        editorValue,
        setEditorValue,
        clearEditor,
        copyText
    })
}

type ToolbarOptions = 'Copy' | 'Clear'

type CustomMonacoEditorProps = ReturnType<useCustomMonacoEditorController> & {
    language ?: string;
    editorHeight ?: string | number;
    editorWidth ?: string | number;
    children ?: React.ReactNode;
    readOnly ?: boolean;
    toolbarOptions ?: ToolbarOptions[];
    label ?: string;
    required ?: boolean;
    infoText ?: string;
}



const CustomMonacoEditor = ({  label = undefined, required = false, infoText = undefined, editorWidth = '100%', editorHeight = '100%', toolbarOptions = ['Clear', 'Copy'] , readOnly = false, editorValue, setEditorValue, copyText, clearEditor, children, language }: CustomMonacoEditorProps) => {

    const handleEditorDidMount = (editor: monacoEditor.editor.IStandaloneCodeEditor, monaco: typeof monacoEditor) => {
        monaco.editor.defineTheme('ds', {
            base: 'vs-dark',
            inherit: true,
            rules: [],
            colors: {
                'editor.foreground': '#14182a',
                'editor.background': '#14182a',
                'editor.lineHighlightBackground': '#14182a',
            }
        });
        monaco.editor.setTheme('ds');
    };

    

    const memoizedEditorOptions = useMemo(() => {
        return {...editorOptions, readOnly }
    }, [readOnly])

    const { showClear, showCopy } = useMemo(() =>{
        let showCopy = false;
        let showClear = false;
        
        toolbarOptions.forEach(option => {
            if(option === 'Clear') showClear = true;
            else if(option === 'Copy') showCopy = true;
        })

        return { showClear, showCopy }
    }, [toolbarOptions])


    return(
        <div className='queryEditorMaster'>
            {!!label && 
                <label 
                    className="inputfield__label"
                >
                    <span className={required ? 'red-star': ''}>{label}</span>
                    {!!infoText &&
                        <TooltipTop placement="topRight" overlay={infoText}>
                            <img src="/icons/info-fields.png" width="16" height="16" className="info__icon" alt="information-icon" />
                        </TooltipTop>
                    }
                </label>
            }
            <div className={styles["editorHeader"]}>
                {showCopy && 
                    <button
                        onClick={copyText}
                        type="button"
                    >
                        <CopyIcon /> Copy
                    </button>
                }
                {showClear && 
                    <button
                        onClick={clearEditor}
                        type="button"
                    >
                        <ClearIcon /> Clear
                    </button>
                }
            </div>
            <div className={styles["editor"]}>
            <MonacoEditor
                height={editorHeight}
                width={editorWidth}
                language={language}
                theme={'vs-dark'}
                value={editorValue}
                options={memoizedEditorOptions}
                onChange={setEditorValue}
                editorDidMount={handleEditorDidMount}
            />
            </div>
            {children}
        </div>
    )
}

CustomMonacoEditor.useCustomMonacoEditorController = useCustomMonacoEditorController;


export default CustomMonacoEditor;