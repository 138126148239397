import { _selectoptionType } from "../../components/form/select-field";
import { convertEnumsToOptions } from "../../utils";
import { PlotData } from "./Canvas";

// correlation is sent as heatmap to backend
export const graphTypes: { label: string; value: PlotData["plotType"] }[] = [
	{ label: "Scatter plot", value: "scatter" },
	{ label: "Bar", value: "bar" },
	{ label: "Area", value: "area" },
	{ label: "Line", value: "line" },
	// { label: "Map", value: "map" },
	{ label: "Geospatial", value: "geospatial" },
	{ label: "Heatmap", value: "heatmap" },
	{ label: "Correlation", value: "correlation" },
	{ label: "Box Plot", value: "boxplot" },
	{ label: "Raster Plot", value: "raster" },
];

export const aggregationTypes: _selectoptionType[] = [
	{ label: "Count", value: "count" },
	{ label: "Average", value: "mean" },
	{ label: "Sum", value: "sum" },
	{ label: "Min", value: "min" },
	{ label: "Max", value: "max" },
	{ label: "None", value: "" },
];

export const mapExtentTypes: _selectoptionType[] = [
	{ label: "Default", value: "default" },
	{ label: "Infer", value: "infer" },
	{ label: "Custom", value: "custom" },
];

export const projectionCodeOptions: _selectoptionType[] = [
	{ label: "None", value: "None" },
	{ label: "EPSG:4326: WGS 84", value: 4326 },
	{ label: "EPSG:3067: ETRS89 / ETRS-TM35FIN", value: 3067 },
	{ label: "EPSG:32719: WGS 84/ UTM zone19S ", value: 32719 },
	{ label: "EPSG:32361: WGS 84/ UTM zone 31N ", value: 32361 },
	{ label: "EPSG:32757: WGS 84 / UTM zone 57S ", value: 32757 },
	{ label: "EPSG:32516: WGS 72BE/ UTM zone 16S ", value: 32516 },
	{
		label: "EPSG:2000: Anguilla 1957 / British West Indies Grid",
		value: 2000,
	},
	{
		label: "EPSG:2001: Antigua 1943 / British West Indies Grid",
		value: 2001,
	},
	{
		label: "EPSG:2002: Dominica 1945 / British West Indies Grid",
		value: 2002,
	},
	{
		label: "EPSG:2003: Grenada 1953 / British West Indies Grid",
		value: 2003,
	},
	{
		label: "EPSG:2004: Montserrat 1958 / British West Indies Grid",
		value: 2004,
	},
	{
		label: "EPSG:2005: St. Kitts 1955 / British West Indies Grid",
		value: 2005,
	},
	{
		label: "EPSG:2006: St. Lucia 1955 / British West Indies Grid",
		value: 2006,
	},
	{
		label: "EPSG:2007: St. Vincent 45 / British West Indies Grid",
		value: 2007,
	},
	{ label: "EPSG:2008: NAD27(CGQ77) / SCoPQ zone 2", value: 2008 },
	{ label: "EPSG:2009: NAD27(CGQ77) / SCoPQ zone 3", value: 2009 },
	{ label: "EPSG:2010: NAD27(CGQ77) / SCoPQ zone 4", value: 2010 },
	{ label: "EPSG:2011: NAD27(CGQ77) / SCoPQ zone 5", value: 2011 },
	{ label: "EPSG:2012: NAD27(CGQ77) / SCoPQ zone 6", value: 2012 },
	{ label: "EPSG:2013: NAD27(CGQ77) / SCoPQ zone 7", value: 2013 },
	{ label: "EPSG:2014: NAD27(CGQ77) / SCoPQ zone 8", value: 2014 },
	{ label: "EPSG:2015: NAD27(CGQ77) / SCoPQ zone 9", value: 2015 },
	{ label: "EPSG:2016: NAD27(CGQ77) / SCoPQ zone 10", value: 2016 },
	{ label: "EPSG:2017: NAD27(76) / MTM zone 8", value: 2017 },
	{ label: "EPSG:2018: NAD27(76) / MTM zone 9", value: 2018 },
	{ label: "EPSG:2019: NAD27(76) / MTM zone 10", value: 2019 },
	{ label: "EPSG:2020: NAD27(76) / MTM zone 11", value: 2020 },
	{ label: "EPSG:2021: NAD27(76) / MTM zone 12", value: 2021 },
	{ label: "EPSG:2022: NAD27(76) / MTM zone 13", value: 2022 },
	{ label: "EPSG:2023: NAD27(76) / MTM zone 14", value: 2023 },
	{ label: "EPSG:2024: NAD27(76) / MTM zone 15", value: 2024 },
	{ label: "EPSG:2025: NAD27(76) / MTM zone 16", value: 2025 },
	{ label: "EPSG:2026: NAD27(76) / MTM zone 17", value: 2026 },
	{ label: "EPSG:2027: NAD27(76) / UTM zone 15N", value: 2027 },
	{ label: "EPSG:2028: NAD27(76) / UTM zone 16N", value: 2028 },
	{ label: "EPSG:2029: NAD27(76) / UTM zone 17N", value: 2029 },
	{ label: "EPSG:2030: NAD27(76) / UTM zone 18N", value: 2030 },
	{ label: "EPSG:2031: NAD27(CGQ77) / UTM zone 17N", value: 2031 },
	{ label: "EPSG:2032: NAD27(CGQ77) / UTM zone 18N", value: 2032 },
	{ label: "EPSG:2033: NAD27(CGQ77) / UTM zone 19N", value: 2033 },
	{ label: "EPSG:2034: NAD27(CGQ77) / UTM zone 20N", value: 2034 },
	{ label: "EPSG:2035: NAD27(CGQ77) / UTM zone 21N", value: 2035 },
	{ label: "EPSG:2036: NAD83(CSRS98) / New Brunswick Stereo", value: 2036 },
	{ label: "EPSG:2037: NAD83(CSRS98) / UTM zone 19N", value: 2037 },
	{ label: "EPSG:2038: NAD83(CSRS98) / UTM zone 20N", value: 2038 },
	{ label: "EPSG:2039: Israel / Israeli TM Grid", value: 2039 },
	{ label: "EPSG:2040: Locodjo 1965 / UTM zone 30N", value: 2040 },
	{ label: "EPSG:2041: Abidjan 1987 / UTM zone 30N", value: 2041 },
	{ label: "EPSG:2042: Locodjo 1965 / UTM zone 29N", value: 2042 },
	{ label: "EPSG:2043: Abidjan 1987 / UTM zone 29N", value: 2043 },
	{ label: "EPSG:2044: Hanoi 1972 / Gauss-Kruger zone 18", value: 2044 },
	{ label: "EPSG:2045: Hanoi 1972 / Gauss-Kruger zone 19", value: 2045 },
	{ label: "EPSG:2046: Hartebeesthoek94 / Lo15", value: 2046 },
	{ label: "EPSG:2047: Hartebeesthoek94 / Lo17", value: 2047 },
	{ label: "EPSG:2048: Hartebeesthoek94 / Lo19", value: 2048 },
	{ label: "EPSG:2049: Hartebeesthoek94 / Lo21", value: 2049 },
	{ label: "EPSG:2050: Hartebeesthoek94 / Lo23", value: 2050 },
	{ label: "EPSG:2051: Hartebeesthoek94 / Lo25", value: 2051 },
	{ label: "EPSG:2052: Hartebeesthoek94 / Lo27", value: 2052 },
	{ label: "EPSG:2053: Hartebeesthoek94 / Lo29", value: 2053 },
	{ label: "EPSG:2054: Hartebeesthoek94 / Lo31", value: 2054 },
	{ label: "EPSG:2055: Hartebeesthoek94 / Lo33", value: 2055 },
	{ label: "EPSG:2056: CH1903+ / LV95", value: 2056 },
	{ label: "EPSG:2057: Rassadiran / Nakhl e Taqi", value: 2057 },
	{ label: "EPSG:2058: ED50(ED77) / UTM zone 38N", value: 2058 },
	{ label: "EPSG:2059: ED50(ED77) / UTM zone 39N", value: 2059 },
	{ label: "EPSG:2060: ED50(ED77) / UTM zone 40N", value: 2060 },
	{ label: "EPSG:2061: ED50(ED77) / UTM zone 41N", value: 2061 },
	{ label: "EPSG:2062: Madrid 1870 (Madrid) / Spain", value: 2062 },
	{ label: "EPSG:2063: Dabola 1981 / UTM zone 28N", value: 2063 },
	{ label: "EPSG:2064: Dabola 1981 / UTM zone 29N", value: 2064 },
	{ label: "EPSG:2065: S-JTSK (Ferro) / Krovak", value: 2065 },
	{ label: "EPSG:2066: Mount Dillon / Tobago Grid", value: 2066 },
	{ label: "EPSG:2067: Naparima 1955 / UTM zone 20N", value: 2067 },
	{ label: "EPSG:2068: ELD79 / Libya zone 5", value: 2068 },
	{ label: "EPSG:2069: ELD79 / Libya zone 6", value: 2069 },
	{ label: "EPSG:2070: ELD79 / Libya zone 7", value: 2070 },
	{ label: "EPSG:2071: ELD79 / Libya zone 8", value: 2071 },
	{ label: "EPSG:2072: ELD79 / Libya zone 9", value: 2072 },
	{ label: "EPSG:2073: ELD79 / Libya zone 10", value: 2073 },
	{ label: "EPSG:2074: ELD79 / Libya zone 11", value: 2074 },
	{ label: "EPSG:2075: ELD79 / Libya zone 12", value: 2075 },
	{ label: "EPSG:2076: ELD79 / Libya zone 13", value: 2076 },
	{ label: "EPSG:2077: ELD79 / UTM zone 32N", value: 2077 },
	{ label: "EPSG:2078: ELD79 / UTM zone 33N", value: 2078 },
	{ label: "EPSG:2079: ELD79 / UTM zone 34N", value: 2079 },
	{ label: "EPSG:2080: ELD79 / UTM zone 35N", value: 2080 },
	{ label: "EPSG:2081: Chos Malal 1914 / Argentina zone 2", value: 2081 },
	{ label: "EPSG:2082: Pampa del Castillo / Argentina zone 2", value: 2082 },
	{ label: "EPSG:2083: Hito XVIII 1963 / Argentina zone 2", value: 2083 },
	{ label: "EPSG:2084: Hito XVIII 1963 / UTM zone 19S", value: 2084 },
	{ label: "EPSG:2085: NAD27 / Cuba Norte", value: 2085 },
	{ label: "EPSG:2086: NAD27 / Cuba Sur", value: 2086 },
	{ label: "EPSG:2087: ELD79 / TM 12 NE", value: 2087 },
	{ label: "EPSG:2088: Carthage / TM 11 NE", value: 2088 },
	{ label: "EPSG:2089: Yemen NGN96 / UTM zone 38N", value: 2089 },
	{ label: "EPSG:2090: Yemen NGN96 / UTM zone 39N", value: 2090 },
	{ label: "EPSG:2091: South Yemen / Gauss Kruger zone 8", value: 2091 },
	{ label: "EPSG:2092: South Yemen / Gauss Kruger zone 9", value: 2092 },
	{ label: "EPSG:2093: Hanoi 1972 / GK 106 NE", value: 2093 },
	{ label: "EPSG:2094: WGS 72BE / TM 106 NE", value: 2094 },
	{ label: "EPSG:2095: Bissau / UTM zone 28N", value: 2095 },
	{ label: "EPSG:2096: Korean 1985 / Korea East Belt", value: 2096 },
	{ label: "EPSG:2097: Korean 1985 / Korea Central Belt", value: 2097 },
	{ label: "EPSG:2098: Korean 1985 / Korea West Belt", value: 2098 },
	{ label: "EPSG:2099: Qatar 1948 / Qatar Grid", value: 2099 },
	{ label: "PSG:2100: GGRS87 / Greek Grid", value: 2100 },
	{ label: "EPSG:2101: Lake / Maracaibo Grid M1", value: 2101 },
	{ label: "EPSG:2102: Lake / Maracaibo Grid", value: 2102 },
	{ label: "EPSG:2103: Lake / Maracaibo Grid M3", value: 2103 },
	{ label: "EPSG:2104: Lake / Maracaibo La Rosa Grid", value: 2104 },
	{ label: "EPSG:2105: NZGD2000 / Mount Eden 2000", value: 2105 },
	{ label: "EPSG:2106: NZGD2000 / Bay of Plenty 2000", value: 2106 },
	{ label: "EPSG:2107: NZGD2000 / Poverty Bay 2000", value: 2107 },
	{ label: "EPSG:2108: NZGD2000 / Hawkes Bay 2000", value: 2108 },
	{ label: "EPSG:2109: NZGD2000 / Taranaki 2000", value: 2109 },
	{ label: "EPSG:2110: NZGD2000 / Tuhirangi 2000", value: 2110 },
	{ label: "EPSG:2111: NZGD2000 / Wanganui 2000", value: 2111 },
	{ label: "EPSG:2112: NZGD2000 / Wairarapa 2000", value: 2112 },
	{ label: "EPSG:2113: NZGD2000 / Wellington 2000", value: 2113 },
	{ label: "EPSG:2114: NZGD2000 / Collingwood 2000", value: 2114 },
	{ label: "EPSG:2115: NZGD2000 / Nelson 2000", value: 2115 },
	{ label: "EPSG:2116: NZGD2000 / Karamea 2000", value: 2116 },
	{ label: "EPSG:2117: NZGD2000 / Buller 2000", value: 2117 },
	{ label: "EPSG:2118: NZGD2000 / Grey 2000", value: 2118 },
	{ label: "EPSG:2119: NZGD2000 / Amuri 2000", value: 2119 },
	{ label: "EPSG:2120: NZGD2000 / Marlborough 2000", value: 2120 },
	{ label: "EPSG:2121: NZGD2000 / Hokitika 2000", value: 2121 },
	{ label: "EPSG:2122: NZGD2000 / Okarito 2000", value: 2122 },
	{ label: "EPSG:2123: NZGD2000 / Jacksons Bay 2000", value: 2123 },
	{ label: "EPSG:2124: NZGD2000 / Mount Pleasant 2000", value: 2124 },
	{ label: "EPSG:2125: NZGD2000 / Gawler 2000", value: 2125 },
	{ label: "EPSG:2126: NZGD2000 / Timaru 2000", value: 2126 },
	{ label: "EPSG:2127: NZGD2000 / Lindis Peak 2000", value: 2127 },
	{ label: "EPSG:2128: NZGD2000 / Mount Nicholas 2000", value: 2128 },
	{ label: "EPSG:2129: NZGD2000 / Mount York 2000", value: 2129 },
	{ label: "EPSG:2130: NZGD2000 / Observation Point 2000", value: 2130 },
	{ label: "EPSG:2131: NZGD2000 / North Taieri 2000", value: 2131 },
	{ label: "EPSG:2132: NZGD2000 / Bluff 2000", value: 2132 },
	{ label: "EPSG:2133: NZGD2000 / UTM zone 58S", value: 2133 },
	{ label: "EPSG:2134: NZGD2000 / UTM zone 59S", value: 2134 },
	{ label: "EPSG:2135: NZGD2000 / UTM zone 60S", value: 2135 },
	{ label: "EPSG:2136: Accra / Ghana National Grid", value: 2136 },
	{ label: "EPSG:2137: Accra / TM 1 NW", value: 2137 },
	{ label: "EPSG:2138: NAD27(CGQ77) / Quebec Lambert", value: 2138 },
	{ label: "EPSG:2139: NAD83(CSRS98) / SCoPQ zone 2", value: 2139 },
	{ label: "EPSG:2140: NAD83(CSRS98) / MTM zone 3", value: 2140 },
	{ label: "EPSG:2141: NAD83(CSRS98) / MTM zone 4", value: 2141 },
	{ label: "EPSG:2142: NAD83(CSRS98) / MTM zone 5", value: 2142 },
	{ label: "EPSG:2143: NAD83(CSRS98) / MTM zone 6", value: 2143 },
	{ label: "EPSG:2144: NAD83(CSRS98) / MTM zone 7", value: 2144 },
	{ label: "EPSG:2145: NAD83(CSRS98) / MTM zone 8", value: 2145 },
	{ label: "EPSG:2146: NAD83(CSRS98) / MTM zone 9", value: 2146 },
	{ label: "EPSG:2147: NAD83(CSRS98) / MTM zone 10", value: 2147 },
	{ label: "EPSG:2148: NAD83(CSRS98) / UTM zone 21N", value: 2148 },
	{ label: "EPSG:2149: NAD83(CSRS98) / UTM zone 18N", value: 2149 },
	{ label: "EPSG:2150: NAD83(CSRS98) / UTM zone 17N", value: 2150 },
	{ label: "EPSG:2151: NAD83(CSRS98) / UTM zone 13N", value: 2151 },
	{ label: "EPSG:2152: NAD83(CSRS98) / UTM zone 12N", value: 2152 },
	{ label: "EPSG:2153: NAD83(CSRS98) / UTM zone 11N", value: 2153 },
	{ label: "EPSG:2154: RGF93 / Lambert-93", value: 2154 },
	{
		label: "EPSG:2155: American Samoa 1962 / American Samoa Lambert",
		value: 2155,
	},
	{ label: "EPSG:2156: NAD83(HARN) / UTM zone 59S", value: 2156 },
	{ label: "EPSG:2157: IRENET95 / Irish Transverse Mercator", value: 2157 },
	{ label: "EPSG:2158: IRENET95 / UTM zone 29N", value: 2158 },
	{ label: "EPSG:2159: Sierra Leone 1924 / New Colony Grid", value: 2159 },
	{
		label: "EPSG:2160: Sierra Leone 1924 / New War Office Grid",
		value: 2160,
	},
	{ label: "EPSG:2161: Sierra Leone 1968 / UTM zone 28N", value: 2161 },
	{ label: "EPSG:2162: Sierra Leone 1968 / UTM zone 29N", value: 2162 },
	{ label: "EPSG:2163: US National Atlas Equal Area", value: 2163 },
	{ label: "EPSG:2164: Locodjo 1965 / TM 5 NW", value: 2164 },
	{ label: "EPSG:2165: Abidjan 1987 / TM 5 NW", value: 2165 },
	{ label: "EPSG:2166: Pulkovo 1942(83) / Gauss Kruger zone 3", value: 2166 },
	{ label: "EPSG:2167: Pulkovo 1942(83) / Gauss Kruger zone 4", value: 2167 },
	{ label: "EPSG:2168: Pulkovo 1942(83) / Gauss Kruger zone 5", value: 2168 },
	{ label: "EPSG:2169: Luxembourg 1930 / Gauss", value: 2169 },
	{ label: "EPSG:2170: MGI / Slovenia Grid", value: 2170 },
	{ label: "EPSG:2171: Pulkovo 1942(58) / Poland zone I", value: 2171 },
	{ label: "EPSG:2172: Pulkovo 1942(58) / Poland zone II", value: 2172 },
	{ label: "EPSG:2173: Pulkovo 1942(58) / Poland zone III", value: 2173 },
	{ label: "EPSG:2174: Pulkovo 1942(58) / Poland zone IV", value: 2174 },
	{ label: "EPSG:2175: Pulkovo 1942(58) / Poland zone V", value: 2175 },
	{ label: "EPSG:2176: ETRS89 / Poland CS2000 zone 5", value: 2176 },
	{ label: "EPSG:2177: ETRS89 / Poland CS2000 zone 6", value: 2177 },
	{ label: "EPSG:2178: ETRS89 / Poland CS2000 zone 7", value: 2178 },
	{ label: "EPSG:2179: ETRS89 / Poland CS2000 zone 8", value: 2179 },
	{ label: "EPSG:2180: ETRS89 / Poland CS92", value: 2180 },
	{ label: "EPSG:2188: Azores Occidental 1939 / UTM zone 25N", value: 2188 },
	{ label: "EPSG:2189: Azores Central 1948 / UTM zone 26N", value: 2189 },
	{ label: "EPSG:2190: Azores Oriental 1940 / UTM zone 26N", value: 2190 },
	{ label: "EPSG:2191: Madeira 1936 / UTM zone 28N", value: 2191 },
	{ label: "EPSG:2192: ED50 / France EuroLambert", value: 2192 },
	{
		label: "EPSG:2193: NZGD2000 / New Zealand Transverse Mercator 2000",
		value: 2193,
	},
	{
		label: "EPSG:2194: American Samoa 1962 / American Samoa Lambert",
		value: 2194,
	},
	{ label: "EPSG:2195: NAD83(HARN) / UTM zone 2S", value: 2195 },
	{ label: "EPSG:2196: ETRS89 / Kp2000 Jutland", value: 2196 },
	{ label: "EPSG:2197: ETRS89 / Kp2000 Zealand", value: 2197 },
	{ label: "EPSG:2198: ETRS89 / Kp2000 Bornholm", value: 2198 },
	{ label: "EPSG:2199: Albanian 1987 / Gauss Kruger zone 4", value: 2199 },
	{
		label: "EPSG:2200: ATS77 / New Brunswick Stereographic (ATS77)",
		value: 2200,
	},
	{ label: "EPSG:2201: REGVEN / UTM zone 18N", value: 2201 },
	{ label: "EPSG:2202: REGVEN / UTM zone 19N", value: 2202 },
	{ label: "EPSG:2203: REGVEN / UTM zone 20N", value: 2203 },
	{ label: "EPSG:2204: NAD27 / Tennessee", value: 2204 },
	{ label: "EPSG:2205: NAD83 / Kentucky North", value: 2205 },
	{ label: "EPSG:2206: ED50 / 3-degree Gauss-Kruger zone 9", value: 2206 },
	{ label: "EPSG:2257: NAD83 / New Mexico East (ftUS)", value: 2257 },
	{ label: "EPSG:2258: NAD83 / New Mexico Central (ftUS)", value: 2258 },
	{ label: "EPSG:2259: NAD83 / New Mexico West (ftUS)", value: 2259 },
	{ label: "EPSG:2260: NAD83 / New York East (ftUS)", value: 2260 },
	{ label: "EPSG:2261: NAD83 / New York Central (ftUS)", value: 2261 },
	{ label: "EPSG:2262: NAD83 / New York West (ftUS)", value: 2262 },
	{ label: "EPSG:2263: NAD83 / New York Long Island (ftUS)", value: 2263 },
	{ label: "EPSG:2264: NAD83 / North Carolina (ftUS)", value: 2264 },
	{ label: "EPSG:2265: NAD83 / North Dakota North (ft)", value: 2265 },
	{ label: "EPSG:2266: NAD83 / North Dakota South (ft)", value: 2266 },
	{ label: "EPSG:2267: NAD83 / Oklahoma North (ftUS)", value: 2267 },
	{ label: "EPSG:2268: NAD83 / Oklahoma South (ftUS)", value: 2268 },
	{ label: "EPSG:2269: NAD83 / Oregon North (ft)", value: 2269 },
	{ label: "EPSG:2270: NAD83 / Oregon South (ft)", value: 2270 },
	{ label: "EPSG:2271: NAD83 / Pennsylvania North (ftUS)", value: 2271 },
	{ label: "EPSG:2272: NAD83 / Pennsylvania South (ftUS)", value: 2272 },
	{ label: "EPSG:2273: NAD83 / South Carolina (ft)", value: 2273 },
	{ label: "EPSG:2274: NAD83 / Tennessee (ftUS)", value: 2274 },
	{ label: "EPSG:2275: NAD83 / Texas North (ftUS)", value: 2275 },
	{ label: "EPSG:2276: NAD83 / Texas North Central (ftUS)", value: 2276 },
	{ label: "EPSG:2277: NAD83 / Texas Central (ftUS)", value: 2277 },
	{ label: "EPSG:2278: NAD83 / Texas South Central (ftUS)", value: 2278 },
	{ label: "EPSG:2279: NAD83 / Texas South (ftUS)", value: 2279 },
	{ label: "EPSG:2280: NAD83 / Utah North (ft)", value: 2280 },
	{ label: "EPSG:2281: NAD83 / Utah Central (ft)", value: 2281 },
	{ label: "EPSG:2282: NAD83 / Utah South (ft)", value: 2282 },
	{ label: "EPSG:2283: NAD83 / Virginia North (ftUS)", value: 2283 },
	{ label: "EPSG:2284: NAD83 / Virginia South (ftUS)", value: 2284 },
	{ label: "EPSG:2285: NAD83 / Washington North (ftUS)", value: 2285 },
	{ label: "EPSG:2286: NAD83 / Washington South (ftUS)", value: 2286 },
	{ label: "EPSG:2287: NAD83 / Wisconsin North (ftUS)", value: 2287 },
	{ label: "EPSG:2288: NAD83 / Wisconsin Central (ftUS)", value: 2288 },
	{ label: "EPSG:2289: NAD83 / Wisconsin South (ftUS)", value: 2289 },
	{
		label: "EPSG:2290: ATS77 / Prince Edward Isl. Stereographic (ATS77)",
		value: 2290,
	},
	{
		label:
			"EPSG:2291: NAD83(CSRS98) / Prince Edward Isl. Stereographic (NAD83)",
		value: 2291,
	},
	{
		label:
			"EPSG:2292: NAD83(CSRS98) / Prince Edward Isl. Stereographic (NAD83)",
		value: 2292,
	},
	{ label: "EPSG:2294: ATS77 / MTM Nova Scotia zone 4", value: 2294 },
	{ label: "EPSG:2295: ATS77 / MTM Nova Scotia zone 5", value: 2295 },
	{
		label: "EPSG:2296: Ammassalik 1958 / Greenland zone 7 east",
		value: 2296,
	},
	{ label: "EPSG:2297: Qornoq 1927 / Greenland zone 1 east", value: 2297 },
	{ label: "EPSG:2298: Qornoq 1927 / Greenland zone 2 east", value: 2298 },
	{ label: "EPSG:2299: Qornoq 1927 / Greenland zone 2 west", value: 2299 },
	{ label: "EPSG:2300: Qornoq 1927 / Greenland zone 3 east", value: 2300 },
	{ label: "EPSG:2301: Qornoq 1927 / Greenland zone 3 west", value: 2301 },
	{ label: "EPSG:2302: Qornoq 1927 / Greenland zone 4 east", value: 2302 },
	{ label: "EPSG:2303: Qornoq 1927 / Greenland zone 4 west", value: 2303 },
	{ label: "EPSG:2304: Qornoq 1927 / Greenland zone 5 west", value: 2304 },
	{ label: "EPSG:2305: Qornoq 1927 / Greenland zone 6 west", value: 2305 },
	{ label: "EPSG:2306: Qornoq 1927 / Greenland zone 7 west", value: 2306 },
	{ label: "EPSG:2307: Qornoq 1927 / Greenland zone 8 east", value: 2307 },
	{ label: "EPSG:2308: Batavia / TM 109 SE", value: 2308 },
	{ label: "EPSG:2309: WGS 84 / TM 116 SE", value: 2309 },
	{ label: "EPSG:2310: WGS 84 / TM 132 SE", value: 2310 },
	{ label: "EPSG:2311: WGS 84 / TM 6 NE", value: 2311 },
	{ label: "EPSG:2312: Garoua / UTM zone 33N", value: 2312 },
	{ label: "EPSG:2313: Kousseri / UTM zone 33N", value: 2313 },
	{ label: "EPSG:2314: Trinidad 1903 / Trinidad Grid (ftCla)", value: 2314 },
	{ label: "EPSG:2315: Campo Inchauspe / UTM zone 19S", value: 2315 },
	{ label: "EPSG:2316: Campo Inchauspe / UTM zone 20S", value: 2316 },
	{ label: "EPSG:2317: PSAD56 / ICN Regional", value: 2317 },
	{ label: "EPSG:2318: Ain el Abd / Aramco Lambert", value: 2318 },
	{ label: "EPSG:2319: ED50 / TM27", value: 2319 },
	{ label: "EPSG:2320: ED50 / TM30", value: 2320 },
	{ label: "EPSG:2321: ED50 / TM33", value: 2321 },
	{ label: "EPSG:2322: ED50 / TM36", value: 2322 },
	{ label: "EPSG:2323: ED50 / TM39", value: 2323 },
	{ label: "EPSG:2324: ED50 / TM42", value: 2324 },
	{ label: "EPSG:2325: ED50 / TM45", value: 2325 },
	{ label: "EPSG:2326: Hong Kong 1980 Grid System", value: 2326 },
	{ label: "EPSG:2327: Xian 1980 / Gauss-Kruger zone 13", value: 2327 },
	{ label: "EPSG:2328: Xian 1980 / Gauss-Kruger zone 14", value: 2328 },
	{ label: "EPSG:2329: Xian 1980 / Gauss-Kruger zone 15", value: 2329 },
	{ label: "EPSG:2330: Xian 1980 / Gauss-Kruger zone 16", value: 2330 },
	{ label: "EPSG:2331: Xian 1980 / Gauss-Kruger zone 17", value: 2331 },
	{ label: "EPSG:2332: Xian 1980 / Gauss-Kruger zone 18", value: 2332 },
	{ label: "EPSG:2333: Xian 1980 / Gauss-Kruger zone 19", value: 2333 },
	{ label: "EPSG:2334: Xian 1980 / Gauss-Kruger zone 20", value: 2334 },
	{ label: "EPSG:2335: Xian 1980 / Gauss-Kruger zone 21", value: 2335 },
	{ label: "EPSG:2336: Xian 1980 / Gauss-Kruger zone 22", value: 2336 },
	{ label: "EPSG:2337: Xian 1980 / Gauss-Kruger zone 23", value: 2337 },
	{ label: "EPSG:2338: Xian 1980 / Gauss-Kruger CM 75E", value: 2338 },
	{ label: "EPSG:2339: Xian 1980 / Gauss-Kruger CM 81E", value: 2339 },
	{ label: "EPSG:2340: Xian 1980 / Gauss-Kruger CM 87E", value: 2340 },
	{ label: "EPSG:2341: Xian 1980 / Gauss-Kruger CM 93E", value: 2341 },
	{ label: "EPSG:2342: Xian 1980 / Gauss-Kruger CM 99E", value: 2342 },
	{ label: "EPSG:2343: Xian 1980 / Gauss-Kruger CM 105E", value: 2343 },
	{ label: "EPSG:2344: Xian 1980 / Gauss-Kruger CM 111E", value: 2344 },
	{ label: "EPSG:2345: Xian 1980 / Gauss-Kruger CM 117E", value: 2345 },
	{ label: "EPSG:2346: Xian 1980 / Gauss-Kruger CM 123E", value: 2346 },
	{ label: "EPSG:2347: Xian 1980 / Gauss-Kruger CM 129E", value: 2347 },
	{ label: "EPSG:2348: Xian 1980 / Gauss-Kruger CM 135E", value: 2348 },
	{
		label: "EPSG:2349: Xian 1980 / 3-degree Gauss-Kruger zone 25",
		value: 2349,
	},
	{
		label: "EPSG:2350: Xian 1980 / 3-degree Gauss-Kruger zone 26",
		value: 2350,
	},
	{
		label: "EPSG:2351: Xian 1980 / 3-degree Gauss-Kruger zone 27",
		value: 2351,
	},
	{
		label: "EPSG:2352: Xian 1980 / 3-degree Gauss-Kruger zone 28",
		value: 2352,
	},
	{
		label: "EPSG:2353: Xian 1980 / 3-degree Gauss-Kruger zone 29",
		value: 2353,
	},
	{
		label: "EPSG:2354: Xian 1980 / 3-degree Gauss-Kruger zone 30",
		value: 2354,
	},
	{
		label: "EPSG:2355: Xian 1980 / 3-degree Gauss-Kruger zone 31",
		value: 2355,
	},
	{
		label: "EPSG:2356: Xian 1980 / 3-degree Gauss-Kruger zone 32",
		value: 2356,
	},
	{
		label: "EPSG:2357: Xian 1980 / 3-degree Gauss-Kruger zone 33",
		value: 2357,
	},
	{
		label: "EPSG:2358: Xian 1980 / 3-degree Gauss-Kruger zone 34",
		value: 2358,
	},
	{
		label: "EPSG:2359: Xian 1980 / 3-degree Gauss-Kruger zone 35",
		value: 2359,
	},
	{
		label: "EPSG:2360: Xian 1980 / 3-degree Gauss-Kruger zone 36",
		value: 2360,
	},
	{
		label: "EPSG:2361: Xian 1980 / 3-degree Gauss-Kruger zone 37",
		value: 2361,
	},
	{
		label: "EPSG:2362: Xian 1980 / 3-degree Gauss-Kruger zone 38",
		value: 2362,
	},
	{
		label: "EPSG:2363: Xian 1980 / 3-degree Gauss-Kruger zone 39",
		value: 2363,
	},
	{
		label: "EPSG:2364: Xian 1980 / 3-degree Gauss-Kruger zone 40",
		value: 2364,
	},
	{
		label: "EPSG:2365: Xian 1980 / 3-degree Gauss-Kruger zone 41",
		value: 2365,
	},
	{
		label: "EPSG:2366: Xian 1980 / 3-degree Gauss-Kruger zone 42",
		value: 2366,
	},
	{
		label: "EPSG:2367: Xian 1980 / 3-degree Gauss-Kruger zone 43",
		value: 2367,
	},
	{
		label: "EPSG:2368: Xian 1980 / 3-degree Gauss-Kruger zone 44",
		value: 2368,
	},
	{
		label: "EPSG:2369: Xian 1980 / 3-degree Gauss-Kruger zone 45",
		value: 2369,
	},
	{
		label: "EPSG:2370: Xian 1980 / 3-degree Gauss-Kruger CM 75E",
		value: 2370,
	},
	{
		label: "EPSG:2371: Xian 1980 / 3-degree Gauss-Kruger CM 78E",
		value: 2371,
	},
	{
		label: "EPSG:2372: Xian 1980 / 3-degree Gauss-Kruger CM 81E",
		value: 2372,
	},
	{
		label: "EPSG:2373: Xian 1980 / 3-degree Gauss-Kruger CM 84E",
		value: 2373,
	},
	{
		label: "EPSG:2374: Xian 1980 / 3-degree Gauss-Kruger CM 87E",
		value: 2374,
	},
	{
		label: "EPSG:2375: Xian 1980 / 3-degree Gauss-Kruger CM 90E",
		value: 2375,
	},
	{
		label: "EPSG:2376: Xian 1980 / 3-degree Gauss-Kruger CM 93E",
		value: 2376,
	},
	{
		label: "EPSG:2377: Xian 1980 / 3-degree Gauss-Kruger CM 96E",
		value: 2377,
	},
	{
		label: "EPSG:2378: Xian 1980 / 3-degree Gauss-Kruger CM 99E",
		value: 2378,
	},
	{
		label: "EPSG:2379: Xian 1980 / 3-degree Gauss-Kruger CM 102E",
		value: 2379,
	},
	{
		label: "EPSG:2380: Xian 1980 / 3-degree Gauss-Kruger CM 105E",
		value: 2380,
	},
	{
		label: "EPSG:2381: Xian 1980 / 3-degree Gauss-Kruger CM 108E",
		value: 2381,
	},
	{
		label: "EPSG:2382: Xian 1980 / 3-degree Gauss-Kruger CM 111E",
		value: 2382,
	},
	{
		label: "EPSG:2383: Xian 1980 / 3-degree Gauss-Kruger CM 114E",
		value: 2383,
	},
	{
		label: "EPSG:2384: Xian 1980 / 3-degree Gauss-Kruger CM 117E",
		value: 2384,
	},
	{
		label: "EPSG:2385: Xian 1980 / 3-degree Gauss-Kruger CM 120E",
		value: 2385,
	},
	{
		label: "EPSG:2386: Xian 1980 / 3-degree Gauss-Kruger CM 123E",
		value: 2386,
	},
	{
		label: "EPSG:2387: Xian 1980 / 3-degree Gauss-Kruger CM 126E",
		value: 2387,
	},
	{
		label: "EPSG:2388: Xian 1980 / 3-degree Gauss-Kruger CM 129E",
		value: 2388,
	},
	{
		label: "EPSG:2389: Xian 1980 / 3-degree Gauss-Kruger CM 132E",
		value: 2389,
	},
	{
		label: "EPSG:2390: Xian 1980 / 3-degree Gauss-Kruger CM 135E",
		value: 2390,
	},
	{ label: "EPSG:2391: KKJ / Finland zone 1", value: 2391 },
	{ label: "EPSG:2392: KKJ / Finland zone 2", value: 2392 },
	{
		label: "EPSG:2393: KKJ / Finland Uniform Coordinate System",
		value: 2393,
	},
	{ label: "EPSG:2394: KKJ / Finland zone 4", value: 2394 },
	{ label: "EPSG:2395: South Yemen / Gauss-Kruger zone 8", value: 2395 },
	{ label: "EPSG:2396: South Yemen / Gauss-Kruger zone 9", value: 2396 },
	{
		label: "EPSG:2397: Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 3",
		value: 2397,
	},
	{
		label: "EPSG:2398: Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 4",
		value: 2398,
	},
	{
		label: "EPSG:2399: Pulkovo 1942(83) / 3-degree Gauss-Kruger zone 5",
		value: 2399,
	},
	{ label: "EPSG:2400: RT90 2.5 gon W", value: 2400 },
	{
		label: "EPSG:2401: Beijing 1954 / 3-degree Gauss-Kruger zone 25",
		value: 2401,
	},
	{
		label: "EPSG:2402: Beijing 1954 / 3-degree Gauss-Kruger zone 26",
		value: 2402,
	},
	{
		label: "EPSG:2403: Beijing 1954 / 3-degree Gauss-Kruger zone 27",
		value: 2403,
	},
	{
		label: "EPSG:2404: Beijing 1954 / 3-degree Gauss-Kruger zone 28",
		value: 2404,
	},
	{
		label: "EPSG:2405: Beijing 1954 / 3-degree Gauss-Kruger zone 29",
		value: 2405,
	},
	{
		label: "EPSG:2406: Beijing 1954 / 3-degree Gauss-Kruger zone 30",
		value: 2406,
	},
	{
		label: "EPSG:2407: Beijing 1954 / 3-degree Gauss-Kruger zone 31",
		value: 2407,
	},
	{
		label: "EPSG:2408: Beijing 1954 / 3-degree Gauss-Kruger zone 32",
		value: 2408,
	},
	{
		label: "EPSG:2409: Beijing 1954 / 3-degree Gauss-Kruger zone 33",
		value: 2409,
	},
	{
		label: "EPSG:2410: Beijing 1954 / 3-degree Gauss-Kruger zone 34",
		value: 2410,
	},
	{
		label: "EPSG:2411: Beijing 1954 / 3-degree Gauss-Kruger zone 35",
		value: 2411,
	},
	{
		label: "EPSG:2412: Beijing 1954 / 3-degree Gauss-Kruger zone 36",
		value: 2412,
	},
	{
		label: "EPSG:2413: Beijing 1954 / 3-degree Gauss-Kruger zone 37",
		value: 2413,
	},
	{
		label: "EPSG:2414: Beijing 1954 / 3-degree Gauss-Kruger zone 38",
		value: 2414,
	},
	{
		label: "EPSG:2415: Beijing 1954 / 3-degree Gauss-Kruger zone 39",
		value: 2415,
	},
	{
		label: "EPSG:2416: Beijing 1954 / 3-degree Gauss-Kruger zone 40",
		value: 2416,
	},
	{
		label: "EPSG:2417: Beijing 1954 / 3-degree Gauss-Kruger zone 41",
		value: 2417,
	},
	{
		label: "EPSG:2418: Beijing 1954 / 3-degree Gauss-Kruger zone 42",
		value: 2418,
	},
	{
		label: "EPSG:2419: Beijing 1954 / 3-degree Gauss-Kruger zone 43",
		value: 2419,
	},
	{
		label: "EPSG:2420: Beijing 1954 / 3-degree Gauss-Kruger zone 44",
		value: 2420,
	},
	{
		label: "EPSG:2421: Beijing 1954 / 3-degree Gauss-Kruger zone 45",
		value: 2421,
	},
	{
		label: "EPSG:2422: Beijing 1954 / 3-degree Gauss-Kruger CM 75E",
		value: 2422,
	},
	{
		label: "EPSG:2423: Beijing 1954 / 3-degree Gauss-Kruger CM 78E",
		value: 2423,
	},
	{
		label: "EPSG:2424: Beijing 1954 / 3-degree Gauss-Kruger CM 81E",
		value: 2424,
	},
	{
		label: "EPSG:2425: Beijing 1954 / 3-degree Gauss-Kruger CM 84E",
		value: 2425,
	},
	{
		label: "EPSG:2426: Beijing 1954 / 3-degree Gauss-Kruger CM 87E",
		value: 2426,
	},
	{
		label: "EPSG:2427: Beijing 1954 / 3-degree Gauss-Kruger CM 90E",
		value: 2427,
	},
	{
		label: "EPSG:2428: Beijing 1954 / 3-degree Gauss-Kruger CM 93E",
		value: 2428,
	},
	{
		label: "EPSG:2429: Beijing 1954 / 3-degree Gauss-Kruger CM 96E",
		value: 2429,
	},
	{
		label: "EPSG:2430: Beijing 1954 / 3-degree Gauss-Kruger CM 99E",
		value: 2430,
	},
	{
		label: "EPSG:2431: Beijing 1954 / 3-degree Gauss-Kruger CM 102E",
		value: 2431,
	},
	{
		label: "EPSG:2432: Beijing 1954 / 3-degree Gauss-Kruger CM 105E",
		value: 2432,
	},
	{
		label: "EPSG:2433: Beijing 1954 / 3-degree Gauss-Kruger CM 108E",
		value: 2433,
	},
	{
		label: "EPSG:2434: Beijing 1954 / 3-degree Gauss-Kruger CM 111E",
		value: 2434,
	},
	{
		label: "EPSG:2435: Beijing 1954 / 3-degree Gauss-Kruger CM 114E",
		value: 2435,
	},
	{
		label: "EPSG:2436: Beijing 1954 / 3-degree Gauss-Kruger CM 117E",
		value: 2436,
	},
	{
		label: "EPSG:2437: Beijing 1954 / 3-degree Gauss-Kruger CM 120E",
		value: 2437,
	},
	{
		label: "EPSG:2438: Beijing 1954 / 3-degree Gauss-Kruger CM 123E",
		value: 2438,
	},
	{
		label: "EPSG:2439: Beijing 1954 / 3-degree Gauss-Kruger CM 126E",
		value: 2439,
	},
	{
		label: "EPSG:2440: Beijing 1954 / 3-degree Gauss-Kruger CM 129E",
		value: 2440,
	},
	{
		label: "EPSG:2441: Beijing 1954 / 3-degree Gauss-Kruger CM 132E",
		value: 2441,
	},
	{
		label: "EPSG:2442: Beijing 1954 / 3-degree Gauss-Kruger CM 135E",
		value: 2442,
	},
	{ label: "EPSG:2443: JGD2000 / Japan Plane Rectangular CS I", value: 2443 },
	{
		label: "EPSG:2444: JGD2000 / Japan Plane Rectangular CS II",
		value: 2444,
	},
	{
		label: "EPSG:2445: JGD2000 / Japan Plane Rectangular CS III",
		value: 2445,
	},
	{
		label: "EPSG:2446: JGD2000 / Japan Plane Rectangular CS IV",
		value: 2446,
	},
	{ label: "EPSG:2447: JGD2000 / Japan Plane Rectangular CS V", value: 2447 },
	{
		label: "EPSG:2448: JGD2000 / Japan Plane Rectangular CS VI",
		value: 2448,
	},
	{
		label: "EPSG:2449: JGD2000 / Japan Plane Rectangular CS VII",
		value: 2449,
	},
	{
		label: "EPSG:2450: JGD2000 / Japan Plane Rectangular CS VIII",
		value: 2450,
	},
	{
		label: "EPSG:2451: JGD2000 / Japan Plane Rectangular CS IX",
		value: 2451,
	},
	{ label: "EPSG:2452: JGD2000 / Japan Plane Rectangular CS X", value: 2452 },
	{
		label: "EPSG:2453: JGD2000 / Japan Plane Rectangular CS XI",
		value: 2453,
	},
	{
		label: "EPSG:2454: JGD2000 / Japan Plane Rectangular CS XII",
		value: 2454,
	},
	{
		label: "EPSG:2455: JGD2000 / Japan Plane Rectangular CS XIII",
		value: 2455,
	},
	{
		label: "EPSG:2456: JGD2000 / Japan Plane Rectangular CS XIV",
		value: 2456,
	},
	{
		label: "EPSG:2457: JGD2000 / Japan Plane Rectangular CS XV",
		value: 2457,
	},
	{
		label: "EPSG:2458: JGD2000 / Japan Plane Rectangular CS XVI",
		value: 2458,
	},
	{
		label: "EPSG:2459: JGD2000 / Japan Plane Rectangular CS XVII",
		value: 2459,
	},
	{
		label: "EPSG:2460: JGD2000 / Japan Plane Rectangular CS XVIII",
		value: 2460,
	},
	{
		label: "EPSG:2461: JGD2000 / Japan Plane Rectangular CS XIX",
		value: 2461,
	},
	{ label: "EPSG:2462: Albanian 1987 / Gauss-Kruger zone 4", value: 2462 },
	{ label: "EPSG:2463: Pulkovo 1995 / Gauss-Kruger CM 21E", value: 2463 },
	{ label: "EPSG:2464: Pulkovo 1995 / Gauss-Kruger CM 27E", value: 2464 },
	{ label: "EPSG:2465: Pulkovo 1995 / Gauss-Kruger CM 33E", value: 2465 },
	{ label: "EPSG:2466: Pulkovo 1995 / Gauss-Kruger CM 39E", value: 2466 },
	{ label: "EPSG:2467: Pulkovo 1995 / Gauss-Kruger CM 45E", value: 2467 },
	{ label: "EPSG:2468: Pulkovo 1995 / Gauss-Kruger CM 51E", value: 2468 },
	{ label: "EPSG:2469: Pulkovo 1995 / Gauss-Kruger CM 57E", value: 2469 },
	{ label: "EPSG:2470: Pulkovo 1995 / Gauss-Kruger CM 63E", value: 2470 },
	{ label: "EPSG:2471: Pulkovo 1995 / Gauss-Kruger CM 69E", value: 2471 },
	{ label: "EPSG:2472: Pulkovo 1995 / Gauss-Kruger CM 75E", value: 2472 },
	{ label: "EPSG:2473: Pulkovo 1995 / Gauss-Kruger CM 81E", value: 2473 },
	{ label: "EPSG:2474: Pulkovo 1995 / Gauss-Kruger CM 87E", value: 2474 },
	{ label: "EPSG:2475: Pulkovo 1995 / Gauss-Kruger CM 93E", value: 2475 },
	{ label: "EPSG:2476: Pulkovo 1995 / Gauss-Kruger CM 99E", value: 2476 },
	{ label: "EPSG:2477: Pulkovo 1995 / Gauss-Kruger CM 105E", value: 2477 },
	{ label: "EPSG:2478: Pulkovo 1995 / Gauss-Kruger CM 111E", value: 2478 },
	{ label: "EPSG:2479: Pulkovo 1995 / Gauss-Kruger CM 117E", value: 2479 },
	{ label: "EPSG:2480: Pulkovo 1995 / Gauss-Kruger CM 123E", value: 2480 },
	{ label: "EPSG:2481: Pulkovo 1995 / Gauss-Kruger CM 129E", value: 2481 },
	{ label: "EPSG:2482: Pulkovo 1995 / Gauss-Kruger CM 135E", value: 2482 },
	{ label: "EPSG:2483: Pulkovo 1995 / Gauss-Kruger CM 141E", value: 2483 },
	{ label: "EPSG:2484: Pulkovo 1995 / Gauss-Kruger CM 147E", value: 2484 },
	{ label: "EPSG:2485: Pulkovo 1995 / Gauss-Kruger CM 153E", value: 2485 },
	{ label: "EPSG:2486: Pulkovo 1995 / Gauss-Kruger CM 159E", value: 2486 },
	{ label: "EPSG:2487: Pulkovo 1995 / Gauss-Kruger CM 165E", value: 2487 },
	{ label: "EPSG:2488: Pulkovo 1995 / Gauss-Kruger CM 171E", value: 2488 },
	{ label: "EPSG:2489: Pulkovo 1995 / Gauss-Kruger CM 177E", value: 2489 },
	{ label: "EPSG:2490: Pulkovo 1995 / Gauss-Kruger CM 177W", value: 2490 },
	{ label: "EPSG:2491: Pulkovo 1995 / Gauss-Kruger CM 171W", value: 2491 },
	{ label: "EPSG:2492: Pulkovo 1942 / Gauss-Kruger CM 9E", value: 2492 },
	{ label: "EPSG:2493: Pulkovo 1942 / Gauss-Kruger CM 15E", value: 2493 },
	{ label: "EPSG:2494: Pulkovo 1942 / Gauss-Kruger CM 21E", value: 2494 },
	{ label: "EPSG:2495: Pulkovo 1942 / Gauss-Kruger CM 27E", value: 2495 },
	{ label: "EPSG:2496: Pulkovo 1942 / Gauss-Kruger CM 33E", value: 2496 },
	{ label: "EPSG:2497: Pulkovo 1942 / Gauss-Kruger CM 39E", value: 2497 },
	{ label: "EPSG:2498: Pulkovo 1942 / Gauss-Kruger CM 45E", value: 2498 },
	{ label: "EPSG:2499: Pulkovo 1942 / Gauss-Kruger CM 51E", value: 2499 },
	{ label: "EPSG:2500: Pulkovo 1942 / Gauss-Kruger CM 57E", value: 2500 },
	{ label: "EPSG:2501: Pulkovo 1942 / Gauss-Kruger CM 63E", value: 2501 },
	{ label: "EPSG:2502: Pulkovo 1942 / Gauss-Kruger CM 69E", value: 2502 },
	{ label: "EPSG:2503: Pulkovo 1942 / Gauss-Kruger CM 75E", value: 2503 },
	{ label: "EPSG:2504: Pulkovo 1942 / Gauss-Kruger CM 81E", value: 2504 },
	{ label: "EPSG:2505: Pulkovo 1942 / Gauss-Kruger CM 87E", value: 2505 },
	{ label: "EPSG:2506: Pulkovo 1942 / Gauss-Kruger CM 93E", value: 2506 },
	{ label: "EPSG:2507: Pulkovo 1942 / Gauss-Kruger CM 99E", value: 2507 },
	{ label: "EPSG:2508: Pulkovo 1942 / Gauss-Kruger CM 105E", value: 2508 },
	{ label: "EPSG:2509: Pulkovo 1942 / Gauss-Kruger CM 111E", value: 2509 },
	{ label: "EPSG:2510: Pulkovo 1942 / Gauss-Kruger CM 117E", value: 2510 },
	{ label: "EPSG:2511: Pulkovo 1942 / Gauss-Kruger CM 123E", value: 2511 },
	{ label: "EPSG:2512: Pulkovo 1942 / Gauss-Kruger CM 129E", value: 2512 },
	{ label: "EPSG:2513: Pulkovo 1942 / Gauss-Kruger CM 135E", value: 2513 },
	{ label: "EPSG:2514: Pulkovo 1942 / Gauss-Kruger CM 141E", value: 2514 },
	{ label: "EPSG:2515: Pulkovo 1942 / Gauss-Kruger CM 147E", value: 2515 },
	{ label: "EPSG:2516: Pulkovo 1942 / Gauss-Kruger CM 153E", value: 2516 },
	{ label: "EPSG:2517: Pulkovo 1942 / Gauss-Kruger CM 159E", value: 2517 },
	{ label: "EPSG:2518: Pulkovo 1942 / Gauss-Kruger CM 165E", value: 2518 },
	{ label: "EPSG:2519: Pulkovo 1942 / Gauss-Kruger CM 171E", value: 2519 },
	{ label: "EPSG:2520: Pulkovo 1942 / Gauss-Kruger CM 177E", value: 2520 },
	{ label: "EPSG:2521: Pulkovo 1942 / Gauss-Kruger CM 177W", value: 2521 },
	{ label: "EPSG:2522: Pulkovo 1942 / Gauss-Kruger CM 171W", value: 2522 },
	{
		label: "EPSG:2523: Pulkovo 1942 / 3-degree Gauss-Kruger zone 7",
		value: 2523,
	},
	{
		label: "EPSG:2524: Pulkovo 1942 / 3-degree Gauss-Kruger zone 8",
		value: 2524,
	},
	{
		label: "EPSG:2525: Pulkovo 1942 / 3-degree Gauss-Kruger zone 9",
		value: 2525,
	},
	{
		label: "EPSG:2526: Pulkovo 1942 / 3-degree Gauss-Kruger zone 10",
		value: 2526,
	},
	{
		label: "EPSG:2527: Pulkovo 1942 / 3-degree Gauss-Kruger zone 11",
		value: 2527,
	},
	{
		label: "EPSG:2528: Pulkovo 1942 / 3-degree Gauss-Kruger zone 12",
		value: 2528,
	},
	{
		label: "EPSG:2529: Pulkovo 1942 / 3-degree Gauss-Kruger zone 13",
		value: 2529,
	},
	{
		label: "EPSG:2530: Pulkovo 1942 / 3-degree Gauss-Kruger zone 14",
		value: 2530,
	},
	{
		label: "EPSG:2531: Pulkovo 1942 / 3-degree Gauss-Kruger zone 15",
		value: 2531,
	},
	{
		label: "EPSG:2532: Pulkovo 1942 / 3-degree Gauss-Kruger zone 16",
		value: 2532,
	},
	{
		label: "EPSG:2533: Pulkovo 1942 / 3-degree Gauss-Kruger zone 17",
		value: 2533,
	},
	{
		label: "EPSG:2534: Pulkovo 1942 / 3-degree Gauss-Kruger zone 18",
		value: 2534,
	},
	{
		label: "EPSG:2535: Pulkovo 1942 / 3-degree Gauss-Kruger zone 19",
		value: 2535,
	},
	{
		label: "EPSG:2536: Pulkovo 1942 / 3-degree Gauss-Kruger zone 20",
		value: 2536,
	},
	{
		label: "EPSG:2537: Pulkovo 1942 / 3-degree Gauss-Kruger zone 21",
		value: 2537,
	},
	{
		label: "EPSG:2538: Pulkovo 1942 / 3-degree Gauss-Kruger zone 22",
		value: 2538,
	},
	{
		label: "EPSG:2539: Pulkovo 1942 / 3-degree Gauss-Kruger zone 23",
		value: 2539,
	},
	{
		label: "EPSG:2540: Pulkovo 1942 / 3-degree Gauss-Kruger zone 24",
		value: 2540,
	},
	{
		label: "EPSG:2541: Pulkovo 1942 / 3-degree Gauss-Kruger zone 25",
		value: 2541,
	},
	{
		label: "EPSG:2542: Pulkovo 1942 / 3-degree Gauss-Kruger zone 26",
		value: 2542,
	},
	{
		label: "EPSG:2543: Pulkovo 1942 / 3-degree Gauss-Kruger zone 27",
		value: 2543,
	},
	{
		label: "EPSG:2544: Pulkovo 1942 / 3-degree Gauss-Kruger zone 28",
		value: 2544,
	},
	{
		label: "EPSG:2545: Pulkovo 1942 / 3-degree Gauss-Kruger zone 29",
		value: 2545,
	},
	{
		label: "EPSG:2546: Pulkovo 1942 / 3-degree Gauss-Kruger zone 30",
		value: 2546,
	},
	{
		label: "EPSG:2547: Pulkovo 1942 / 3-degree Gauss-Kruger zone 31",
		value: 2547,
	},
	{
		label: "EPSG:2548: Pulkovo 1942 / 3-degree Gauss-Kruger zone 32",
		value: 2548,
	},
	{
		label: "EPSG:2549: Pulkovo 1942 / 3-degree Gauss-Kruger zone 33",
		value: 2549,
	},
	{ label: "EPSG:2550: Samboja / UTM zone 50S", value: 2550 },
	{
		label: "EPSG:2551: Pulkovo 1942 / 3-degree Gauss-Kruger zone 34",
		value: 2551,
	},
	{
		label: "EPSG:2552: Pulkovo 1942 / 3-degree Gauss-Kruger zone 35",
		value: 2552,
	},
	{
		label: "EPSG:2553: Pulkovo 1942 / 3-degree Gauss-Kruger zone 36",
		value: 2553,
	},
	{
		label: "EPSG:2554: Pulkovo 1942 / 3-degree Gauss-Kruger zone 37",
		value: 2554,
	},
	{
		label: "EPSG:2555: Pulkovo 1942 / 3-degree Gauss-Kruger zone 38",
		value: 2555,
	},
	{
		label: "EPSG:2556: Pulkovo 1942 / 3-degree Gauss-Kruger zone 39",
		value: 2556,
	},
	{
		label: "EPSG:2557: Pulkovo 1942 / 3-degree Gauss-Kruger zone 40",
		value: 2557,
	},
];

export enum BandType {
	Single = "Single",
	Multi = "Multi",
}

export const bandTypeOptions = convertEnumsToOptions(BandType);

export const lastColBoxNameRef: Record<string, string> = {
	raster: "Gray Scale Column",
	map: "Y Axis",
	geospatial: "Geometries",
};

export const lastColBoxIdNameRef: Record<string, string> = {
	raster: "Gray_Scale_Column",
	map: "YAxis",
	geospatial: "Geometries",
};

//  export const colorRampsRef = {
//      spectral: { startColor: '#e31a1c', endColor: '#a8d1eb'},
//      rdpu: { startColor: '#d7191c', endColor: '#d7191c'},
//      rdylgn: { startColor: '#feebe2', endColor: '#7a0177'},
//  }

export enum ColorRamps {
	Grey = "Grey",
	Spectral = "Spectral",
	YlGnBu = "YlGnBu",
	RdPu = "RdPu",
	RDYlGn = "RDYlGn",
}

export const colorRampOptions = convertEnumsToOptions(ColorRamps);

export enum ColorModes{
	Continous = 'Continous',
	"Equal Interval" = "Equal Interval"
}

export const colorModeOptions = convertEnumsToOptions(ColorModes);


export enum SessionCreationErrorMessages {
	SessionNotFound = 'Session not found'
}

export enum RasterPlotFailureMessages {
	ErrorFetchingTiff = 'Error fetching the raster, try plotting again.',
	ErrorLoadingTIff = 'Failed to plot the raster'
}

export enum PlotCreationErrorMessages {
	SessionCreationFailed = "Session creation failed",
	StatementNotFound = "Statement not found",
	NotAValidWorkflow = "Failed running the workflow",
	StatementFailed = "Statement state failed",
	StatementSubmissionFailed = "Statement submission failed"
}