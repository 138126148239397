import _ from 'lodash';
import { AzkabanReducerState, ActionTypes } from './types';

const initialState: AzkabanReducerState = {
    execution_analytics_flow: {
        totalCount: 0,
        data: [],
        is_loaded: false
    }
};


export const AzkabanReducer = (state = initialState, action: ActionTypes): AzkabanReducerState => {
    const new_state = _.cloneDeep(state);

    switch(action.type){
        case 'AZKABAN_EXECUTION_ANALYTICS_FLOW_ALL':
            _.setWith(new_state, ['execution_analytics_flow'], action.payload);
            return new_state;
        default:
            return state;
    }
};