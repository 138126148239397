import { Modal } from "@components/modals";
import classNames from "classnames";
import React from "react";
import styles from "../styles.module.scss";
import { StorageConnectionData } from "../types";

interface ConfirmModalProps {
	showModal: boolean;
	selectedConnectionItem: StorageConnectionData | null;
	toggleClose: () => void;
	onConfirm: () => void;
}

export const DeleteStorageConnection: React.FC<ConfirmModalProps> = ({
	showModal,
	toggleClose,
	onConfirm,
	selectedConnectionItem
}) => {

	let title= "";
	let subtitle = "";
	if(selectedConnectionItem?.isEnabled){
		title = `This action can't be performed because ${selectedConnectionItem?.connectionName} connection is enabled.`;
		subtitle = "First disable connection, and try again.";
	}else{
		title = `Are you sure you want to delete this storage connection ${selectedConnectionItem?.connectionName}?`;
		subtitle = "This action is irreversible";
	}

	return (
		<Modal
			isOpen={showModal}
			toggleClose={toggleClose}
			title={
				<div className={styles["delConnectionTitleContainer"]}>
					<img src="/icons/data-browser/error.svg" />
					<div className={styles["delConnectionTitle"]}>{title}</div>
				</div>
			}
			subtitle={
				<div className={styles["delConnectionSubTitle"]}>{subtitle}</div>
			}
			className={classNames(
				"runWorkflowModal__container",
				styles["deleteConnectionModal"]
			)}
		>
			<div className="modalBtns__box">
				<button
					id={"Workfflow_deletemodal_btn"}
					className="btn-md btn-yellow"
					onClick={onConfirm}
					type="submit"
					disabled ={selectedConnectionItem?.isEnabled}
				>
					Confirm Delete
				</button>
				<button
					id={"Workfflow_deletemodal_cancelbtn"}
					className="btn-md btn-cancel"
					type="button"
					onClick={toggleClose}
				>
					Cancel
				</button>
			</div>
		</Modal>
	);
};
