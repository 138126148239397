import { JobInfo } from './types';
import { HttpClient } from '../services';
import { API, Env } from '../../constants/settings';
import { ApiResponse } from '../data-source-handler';
import { ExecutionEnvModes } from '../../constants/enums';
import { stringifyQueryParams } from '../../helpers/utils';

class JobsHandlerClass {
    url: string;
    constructor() {
        this.url = Env.databricks ? API.databricksUrl: API.url;
    }
    

    GetRecentJobs(env: ExecutionEnvModes, callback: (arg0: JobInfo[]) => any) {
        HttpClient.Get('Gets latest 10 jobs', `${this.url}/batches/job/recent${stringifyQueryParams({ env })}`, callback);
    }

    TerminateAJob(jobId: string, callback: (arg0: JobInfo[]) => any, errorHandlerCallBack: any) {
        HttpClient.Delete('Delete a job', `${this.url}/batches/job/${jobId}?version=2`, callback, errorHandlerCallBack);
    }

    TerminateAPreviewJob(sessionId: string, statementId: string, callback: any, errorHandlerCallBack: any){
        HttpClient.Delete('Terminate a preview job', `${this.url}/sessions/${sessionId}/statements/${statementId}/cancel`, callback, errorHandlerCallBack);
    }

    GetJobLogs(jobId: string, callback: ApiResponse<string[]>) {
        HttpClient.Get('Gets latest 10 jobs', `${API.url}/batches/job/${jobId}/download/log`, callback);
    }

}

const JobsHandler = new JobsHandlerClass();

export { JobsHandler };