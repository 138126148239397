import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
    CanvasWidget
} from '@projectstorm/react-canvas-core';
import { Resizable } from 're-resizable';
import { OFFSET_VARIABLE_PLACEHOLDER, TABS_HEIGHT } from '../../enums';
import { isEmpty, has, cloneDeep, capitalize } from 'lodash';
import { WorkflowCanvasMagnificationTools } from './workflow-canvas-magnification-tools';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { RootState } from '../../../../store/types';
import { UpdateWorkflowResponse } from '../../../../api/workflow-handler';
import { toggleModal, closeActiveForm, setActiveComponentPropsState, ActiveComponentInfo, setActiveComponentInfo, 
    toggleWorkflowsLoadingState, setCurrentWorkflowStateToUnsaved, saveCurrentWorkflowTabState, updateConsolePositionInfo, 
    increaseComponentCounterInActiveWorkflow, getComponentDfNameAndIncrementCounter, getComponentDfNameUsingCounter, saveActiveWorkflow, 
    setActiveWorkflowStateToReadOnly, 
    updateSourcePortConnectedStatusOfActiveComponent,
    closeActiveComponentNotes} from '../../../../store/workflow';
import { _selectoptionType } from '../../../../components/form/select-field';
import { WorkflowCanvasTabInfo } from '../../../../store/canvas';
import classNames from 'classnames';
import { DsNodeModel } from '../../../../components/workflow-canvas/node/ds_nodemodel';
import { useWillUnmount, useDidUpdate, useDebounce, useDidMount } from 'rooks';
import { RunPreviewWorkflowModal, RunPreviewWorkflowModalProps } from '../../modals/run-preview-workflow';
import { WorkflowCanvas, SerializedData } from '../../../../components/workflow-canvas';
import { ConsoleComponent } from './console';
import { ManagePortsModal } from '../../modals/manage-ports';
import { errorAlert, successAlert } from '../../../../components/toastify/notify-toast';
import { BaseFieldType } from '../../../../components/formcreators/types';
import {  ContextMenuComponent } from '../../../../components/context-menu';
import { ShowWhenTrue } from '../../../../helpers';
import { CanvasFloatingToolbar } from './floating-toolbar';
import { LinkModel } from '@projectstorm/react-diagrams';
import { InPageSpinner } from '../../../../components/spinners/in-page-spinner';
import { uuidRegex } from '../../../../constants/enums';
import { getActiveWorkflowType } from '../../../../utils';
import { DsPortModel } from '../../../../components/workflow-canvas/port/ds_portmodel';
import { DsLinkModel } from '../../../../components/workflow-canvas/link/ds_linkmodel';
import { updateWorkflowVariablesInfo } from '../../utils';
import { setDefaultValueForUniqueValueField } from '@components/formcreators/fieldComponents/UniqueValue/utils';

const addDefaultsForComponentFormData = (node: DsNodeModel, workflowCanvas: typeof WorkflowCanvas) => {
    node.extras.formData.forEach((field) => {
        if(field.type === 'unique-value') {
            setDefaultValueForUniqueValueField(node, field, workflowCanvas)
        }
    })
}

export const WorkflowCanvasRenderer: React.FC<{ getRecentJobs: () => void}> = ({ getRecentJobs }) => {
    const dispatch = useDispatch();
    const { openTabs, activeTab } = useSelector((store: RootState) => store.CanvasReducer.workflowEditor);
    const activeWorkflowInfo: WorkflowCanvasTabInfo | undefined = useMemo(() => (openTabs.get(activeTab.id)?.info), [activeTab, openTabs]);
   
    const [numberOfVersions, setWorkflowVersions] = useState<_selectoptionType[]>([]);
    const  { 
        componentToBeCopied,
        activeComponentPropertiesInfo: activeComponentPropsInfo,
        variableComponent,
        showWorkflowCanvasSpinner
    } = useSelector((store: RootState) => store.WorkflowReducer);
    const [actionType, setActionType] = useState<RunPreviewWorkflowModalProps['actionType']>('run');
    const [canvasMinMaxHeight, setCanvasMinMaxHeight] = useState({ min: 200, max: window.innerHeight - TABS_HEIGHT });
    const [canvasDimensions, setCanvasDimensions] = useState({ width: '100%', height: canvasMinMaxHeight.max });
    const canvasMasterRef = useRef<HTMLDivElement>(null);
    const contextMenuRef = useRef<ContextMenuComponent>(null);
    const [showAddVarPlaceholder, toggleAddVarPlaceholder] = useState(false);
    const varPlaceholderRef = useRef<HTMLDivElement>(null);
    const { activeExecutionEnv } = useSelector((store: RootState) => store.CommonReducer);
    const { 
        workflow_help
    } = useSelector((store: RootState) => store.CoachMarkReducer);

    const handleSetCurrentWorkflowStateToUnsaved = useDebounce(() => {
        dispatch(setCurrentWorkflowStateToUnsaved());
    }, 250);


    const handleUpdateConsolePosition = useDebounce((_canvasDimensions: typeof canvasDimensions) => {
        dispatch(updateConsolePositionInfo(_canvasDimensions.height));
    }, 300);


    useEffect(() => {
        if(activeTab.id) {
            handleUpdateConsolePosition(canvasDimensions);
        }
    }, [canvasDimensions, activeTab]);

    const handleUpdateWorkflowVariablesInfo = () => {
        dispatch(updateWorkflowVariablesInfo());
    };
    

    const addTogglePropertiesListenerToNodes = (node: DsNodeModel) => {
        node.registerListener({
            selectionChanged: function (event: any) {
                dispatch(setActiveComponentPropsState({ show: event.isSelected }));
                if (event.isSelected) {
                    const __node = event.entity as DsNodeModel;
                    const options = __node.getOptions();
                    const extras = __node.extras;
                    
                    if (options.id) {
                        const __componentInfo: ActiveComponentInfo = {
                            id: options.id,
                            formDetails: extras.formData,
                            name: options.title,
                            componentInfo: extras.componentInfo,
                            extraData: extras.extraData,
                            type: options.nodeType,
                            isVariableSourcePortConnected: __node.isVariableInPortConnected()
                        };
                        if (extras.portManagement) __componentInfo.portManagementDetails = extras.portManagement;
                        if(!node.getOptions().isPropertiesTouched) node.setIsPropertiesTouched(true);
                        dispatch(setActiveComponentInfo(__componentInfo));
                    }
                } else {
                    dispatch(setActiveComponentInfo(null));
                }
            },
            entityRemoved: function () {
                handleUpdateWorkflowVariablesInfo();
                dispatch(setActiveComponentInfo(null));
                dispatch(setActiveComponentPropsState({ show: false }));
                dispatch(saveCurrentWorkflowTabState());
            },
            linkConnected: function (e: any) {
                // This is triggered only target port is set
                // so both source port component and target port component needs to be checked
                const sourcePort = e.sourcePort as DsPortModel;
                const targetPort =  e.targetPort as DsPortModel;
                const link = e.link as DsLinkModel;

                const componentToBeUpdated =  sourcePort?.getNode()?.isSelected() ? sourcePort.getNode(): targetPort?.getNode()?.isSelected() ? targetPort.getNode():null;
                if(componentToBeUpdated) {
                    if(sourcePort && targetPort) {
                        dispatch(updateSourcePortConnectedStatusOfActiveComponent(componentToBeUpdated.getID(), sourcePort, targetPort));
                    }
                    if(link) {
                        link.registerListener({
                            entityRemoved: ()  => dispatch(updateSourcePortConnectedStatusOfActiveComponent(componentToBeUpdated.getID(), e.sourcePort as DsPortModel, e.targetPort as DsPortModel))
                        });
                    }
                }
                
            },              
            positionChanged: handleSetCurrentWorkflowStateToUnsaved
        });
    };

    useDidUpdate(() => {
        WorkflowCanvas.repaintCanvas();
    }, [activeWorkflowInfo?.previewStatusInfo]);

    const checkIfThereIsARequiredFieldInFormData = (formData: BaseFieldType[]): boolean => {
        let hasRequiredFields = false;
        formData.some(field => {
            if(field?.templateOptions?.required) {
                hasRequiredFields = true;
                return true;
            }
        });
        return !hasRequiredFields;
    };

    


    const handleCreateComponentOnDrop = (event: React.DragEvent<HTMLDivElement>) => {
        if(activeWorkflowInfo) {
            if(activeWorkflowInfo?.isReadOnly) {
                errorAlert('You don\'t have permission to perform this action');
            } else {
                const componentInfo = event.dataTransfer.getData('storm-diagram-node');
                if (componentInfo) {
                    const data = JSON.parse(componentInfo);
                    const points = WorkflowCanvas.engine.getRelativeMousePoint(event);
                    const title = data.label;
                    const subtitle = data.parent.split('/')[0];
                    const iconKey = data.parent;
                    let [numberOfInputPorts, numberOfOutputPorts] = [0, 0];
                    if(data.details.input_port) {
                        numberOfInputPorts = data.details.input_port;
                    } else if(has(data.details, 'portManagement.inputPorts.defaultValue')) {
                        numberOfInputPorts = data.details.portManagement.inputPorts.defaultValue;
                    }

                    if(data.details.output_port) {
                        numberOfOutputPorts = data.details.output_port;
                    } else if(has(data.details, 'portManagement.outputPorts.defaultValue')) {
                        numberOfOutputPorts = data.details.portManagement.outputPorts.defaultValue;
                    }

            
                    const isPropertiesValid = checkIfThereIsARequiredFieldInFormData(data.details.formData);
                    const isPropertiesTouched = false;

                    // for custom component, use component name as for dfName
                    const actualNameForDf = data.isCustomComponent ? title: data.details.key;


                    const node = WorkflowCanvas.createNode({ 
                        title, 
                        actualTitle: title,
                        subtitle, 
                        iconKey, 
                        isPropertiesValid, 
                        isPropertiesTouched, 
                        numberOfInputPorts, 
                        numberOfOutputPorts, 
                        posx: points.x, 
                        posy: points.y, 
                        details: data.details,
                        nodeType: 'component',
                        dfName: dispatch(getComponentDfNameAndIncrementCounter(actualNameForDf)) as unknown as any,
                        hash: data.hash,
                        isPropertiesSavedAtleastOnce: false,
                        notes: '',
                        customComponentId: data.isCustomComponent ? data.id: undefined
                    });
                    addDefaultsForComponentFormData(node, WorkflowCanvas)
                    addTogglePropertiesListenerToNodes(node);
                    WorkflowCanvas.model.addNode(node);
                    WorkflowCanvas.repaintCanvas();
                    setTimeout(() => {
                        dispatch(saveCurrentWorkflowTabState());
                    }, 400);
                }
            }
        } else {
            errorAlert('To start working with components, create a workflow or use an existing workflow');
        }

    };

    const handleSaveWorkflowSuccess = (saveAsVersion: boolean, workflowInfo: UpdateWorkflowResponse) => {
        // dispatch(retrieveWorkflows({ user_id })) as any
        successAlert('Successfully saved ' + getActiveWorkflowType(activeExecutionEnv));
        if(saveAsVersion) {
            const updatedVersion = workflowInfo.data.version + 1;
            setWorkflowVersions([{label: `Ver ${updatedVersion}`, value:updatedVersion }, ...numberOfVersions ]);
        }
        
    };

    const handleSaveWorkflow =  (saveAsVersion = false) => {
        dispatch(saveActiveWorkflow(handleSaveWorkflowSuccess.bind(null, saveAsVersion), saveAsVersion, 'workflowEditor'));
    };


    const loadWorkflowOntoCanvas = (activeWorkflowInfo: WorkflowCanvasTabInfo) => {
        WorkflowCanvas.clearSelection();
        WorkflowCanvas.resetWorkflow();
        if (!isEmpty(activeWorkflowInfo.details)) {
            const { isReadOnly: lockEntities, details, activeComponentInfo, componentCounter } = activeWorkflowInfo;
            // WITHOUT SETTIMEOUT, WORKFLOW ISN'T SHOWING UP WHEN YOU SWITCH BETWEEN PAGES
            // LOCK ALL NODES AND LINKS IN READONLY MODE
            let counterForOlderWorkflows = componentCounter;
            setTimeout(() => {
                const hasLoaded = WorkflowCanvas.deSerializeModel((details as SerializedData));
                if(hasLoaded) {
                    // Add event listeners to all nodes
                    Object.values(WorkflowCanvas.getAllNodes()).forEach(
                        (node: DsNodeModel) => {
                            addTogglePropertiesListenerToNodes(node);
                            node.setLocked(lockEntities);
                            node.extras.isReadOnly = lockEntities;
                            if(activeComponentInfo?.id === node.getID()) {
                                node.setSelected(true);
                            }
                            
                            
                            const { dfName: componentDfName, extras: componentExtras} = node.getOptions();
                            let __dfNameRef;
                            if(has(componentExtras, 'customComponentPayload')) {
                                // FOR CUSTOM COMPONENT;
                                // if(componentDfName === undefined  || componentDfName.includes(componentExtras.key)  || componentDfName.includes(componentExtras.id)) {
                                if(componentDfName === undefined  || componentDfName.match(uuidRegex)) {
                                    // 437607d0-95a5-4583-8a4e-ece7058d50de_14_0=custom(sql_query_1_0,spark,component_id='a891b225-6bd1-4345-9992-b545c99d2f32')
                                    // old custom components have uuid as dfname;
                                    __dfNameRef = node.getOptions().title;
                                }
                            } else if(componentDfName === undefined) {
                                // normal component
                                __dfNameRef = node.extras.key;
                            }
                            // __dfNameRef = true -> dfName has changed 
                            if(__dfNameRef)
                                node.getOptions().dfName = getComponentDfNameUsingCounter(__dfNameRef, counterForOlderWorkflows);
                            // componentCounter = 0 -> older workflows - count needs to be updated by adding all components
                            // __dfNameRef = true -> increase the counter 
                            if(componentCounter === 0 || __dfNameRef) {
                                counterForOlderWorkflows += 1;
                            }
                        }
                    );

                    if(lockEntities) {
                        Object.values(WorkflowCanvas.getAllLinks()).forEach(
                            (link: LinkModel) => {
                                link.setLocked(true);
                            }
                        );
                    }

                    if(componentCounter !== counterForOlderWorkflows) {
                        dispatch(increaseComponentCounterInActiveWorkflow(counterForOlderWorkflows));
                    }
                } else {
                    // set workflow state to readonly if the workflow fails to laod
                    dispatch(setActiveWorkflowStateToReadOnly());

                }
                handleUpdateWorkflowVariablesInfo();

            }, 1); 
        }
        dispatch(toggleWorkflowsLoadingState([activeWorkflowInfo.id], false));
    };

    useEffect(() => {
        if (activeWorkflowInfo) {
            loadWorkflowOntoCanvas(activeWorkflowInfo);
            // WorkflowHandler.GetWorkflowHistory(activeWorkflowInfo.id, handleWorkflowHistory);
            if(activeWorkflowInfo.consoleInfo?.height) {
                setCanvasDimensions({ width: '100%', height: activeWorkflowInfo.consoleInfo.height });
            } else if(!activeWorkflowInfo.consoleInfo?.logs){
                // SET HEIGHT TO MAX ONLY WHEN LOGS ARE EMPTY
                setCanvasDimensions({ width: '100%', height: canvasMinMaxHeight.max });
            }
        } else if(canvasDimensions.height !== canvasMinMaxHeight.max) {
            // SET CANVAS HEIGHT TO MAX ONLY WHEN THERE'S NO ACTIVE TAB
            setCanvasDimensions({ width: '100%', height: canvasMinMaxHeight.max });
        }
    }, [activeTab]);


    useDidMount(() => {
        WorkflowCanvas.initializeModelWithStateChangeListener(handleSetCurrentWorkflowStateToUnsaved);
    });

    const handlePasteComponent = (e: React.MouseEvent) => {
        contextMenuRef.current?.hideContextMenu();
        if(componentToBeCopied){
            const { title, subtitle, actualTitle, isPropertiesValid, inputPorts, outputPorts, details, iconKey, nodeType, env, hash, notes, customComponentId = undefined } = componentToBeCopied;
            if((nodeType === 'component' && env === activeExecutionEnv) || nodeType === 'variable') {
                const __details = _.cloneDeep(details);
                const points = WorkflowCanvas.engine.getRelativeMousePoint(e);
                const node = WorkflowCanvas.createNode({ title, subtitle, actualTitle, iconKey, isPropertiesValid, isPropertiesTouched: false, isPropertiesSavedAtleastOnce: false, numberOfInputPorts: inputPorts, numberOfOutputPorts: outputPorts, posx: points.x, posy: points.y, details: __details, nodeType, dfName: dispatch(getComponentDfNameAndIncrementCounter(__details.key)) as unknown as any, hash, notes, customComponentId });
                addDefaultsForComponentFormData(node, WorkflowCanvas);
                handleUpdateWorkflowVariablesInfo();
                addTogglePropertiesListenerToNodes(node);
                WorkflowCanvas.model.addNode(node);
                setTimeout(() => {
                    WorkflowCanvas.repaintCanvas();
                }, 1);
            } else {
                // title = Read CSV (python) - errorMessage = Read CSV (python) cannot be copied into Spark experience
                // title = Read CSV - errorMessage = Read CSV (Spark experience) cannot be copied into Python experience
                errorAlert(`${title}${!title.includes(env) ? `(${capitalize(env)} experience)`: ''} cannot be copied into ${capitalize(activeExecutionEnv)} experience`);
            }
            
        }
    };

    const handleWindowResize = () => {
        setCanvasMinMaxHeight({ min: 200, max: window.innerHeight - TABS_HEIGHT });
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    
    const canvasZoomLevel = WorkflowCanvas.model.getZoomLevel()/100;

    const handleUpdatePlaceholderPosition = (e: MouseEvent) => {
        if(varPlaceholderRef.current && canvasMasterRef.current) {
            const parentDim = canvasMasterRef.current.getBoundingClientRect();
            const distanceToLeft = e.clientX - parentDim.left - (OFFSET_VARIABLE_PLACEHOLDER.x);
            const distanceToTop = e.clientY - parentDim.top - OFFSET_VARIABLE_PLACEHOLDER.y;

            // width: 120, height: 126
            // const elementDim = varPlaceholderRef.current.getBoundingClientRect();
            // if(canvasZoomLevel < 1) {
            //     distanceToLeft -= ((elementDim.width - 120) *  WorkflowCanvas.model.getZoomLevel());
            //     distanceToTop -= ((elementDim.height - 126) * WorkflowCanvas.model.getZoomLevel());
            // } else {
            //     distanceToLeft -= 120 - elementDim.width;
            //     distanceToTop -= 126 - elementDim.height;
            // }
         

            if(distanceToLeft >= 0 && distanceToLeft < (parentDim.width - 115) && distanceToTop >= 0 && distanceToTop < (parentDim.height - 115)) {
                varPlaceholderRef.current.style.left = `${distanceToLeft}px`; 
                varPlaceholderRef.current.style.top = `${distanceToTop}px`;
            }
            canvasMasterRef.current.style.cursor = 'grabbing';
            // varPlaceholderRef.current.style.top = e.clientY + 'px'
            // varPlaceholderRef.current.style.left = e.clientX + 'px'
        }
    };

    const handleShowVarPlaceHolder = () => {
        toggleAddVarPlaceholder(true);
        window.addEventListener('mousemove', handleUpdatePlaceholderPosition);
    };

    const removeListener = () => {
        window.removeEventListener('mousemove', handleUpdatePlaceholderPosition);
    };

    const handleClickOnCanvas = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if(showAddVarPlaceholder && variableComponent) {
            toggleAddVarPlaceholder(false);
            removeListener();
            if(canvasMasterRef.current) {
                canvasMasterRef.current.style.cursor = 'cursor';
            }
            const points = WorkflowCanvas.engine.getRelativeMousePoint({ 
                clientX: e.clientX - OFFSET_VARIABLE_PLACEHOLDER.x, 
                clientY: e.clientY - OFFSET_VARIABLE_PLACEHOLDER.y
            });
            const node = WorkflowCanvas.createNode({ 
                title: '', 
                subtitle: '', 
                actualTitle: '',
                iconKey: '', 
                isPropertiesValid: true, 
                isPropertiesTouched: false, 
                numberOfInputPorts: 1, 
                numberOfOutputPorts: 1, 
                posx: points.x, 
                posy: points.y, 
                details: cloneDeep(variableComponent.details),
                nodeType: 'variable' ,
                dfName: '',
                hash: variableComponent.hash,
                isPropertiesSavedAtleastOnce: false,
                notes:'',
                customComponentId: undefined
            });
            addDefaultsForComponentFormData(node, WorkflowCanvas);
            addTogglePropertiesListenerToNodes(node);
            WorkflowCanvas.model.addNode(node);
            WorkflowCanvas.repaintCanvas();
            handleUpdateWorkflowVariablesInfo();

        }
    };

    useWillUnmount(() => {
        dispatch(saveCurrentWorkflowTabState());
        removeListener();
    });

    return (
        <>
            <Resizable
                defaultSize={{ width: '100%', height: canvasMinMaxHeight.max }}
                minHeight={canvasMinMaxHeight.min}
                maxHeight={canvasMinMaxHeight.max}
                // enable only when there're tabs
                enable={{ bottom: !!(activeWorkflowInfo) }}
                size={{ width: canvasDimensions.width, height: canvasDimensions.height }}
                onResizeStop={(e, direction, ref, d) => {
                    setCanvasDimensions({
                        width: '100%',
                        height: canvasDimensions.height + d.height,
                    });
                }}
            >
                <div
                    className="workflowCanvas__outer"
                    onDrop={handleCreateComponentOnDrop}
                    onDragOver={event => event.preventDefault()}
                    onContextMenu={contextMenuRef.current?.showContextMenu}
                    onClick={handleClickOnCanvas}
                    ref={canvasMasterRef}
                >
                    {!activeWorkflowInfo || activeWorkflowInfo.env !== activeExecutionEnv ?
                        <>
                            {
                                workflow_help? (
                                    <CanvasFloatingToolbar 
                                        handleSaveWorkflow={handleSaveWorkflow}
                                        activeWorkflowInfo={activeWorkflowInfo}
                                        setActionType={setActionType}
                                        handleShowVarPlaceHolder={handleShowVarPlaceHolder}
                                        getRecentJobs={getRecentJobs}
                                    />    
                                ): null
                            }
                            
                            <div
                                className="canvas"
                            >
                                <div className="noworkflows__msg">
                                    <ul>
                                        <li onClick={() => dispatch(toggleModal('newWorkflow', true))} className="workflow__actions">Create a New {getActiveWorkflowType(activeExecutionEnv)}</li>
                                        {/* <li onClick={() => dispatch(setActiveTab(CanvasTabs.WORKFLOWS))} className="workflow__actions"> Open an Existing Workflow</li> */}
                                    </ul>
                                </div>
                            </div>
                        </>
                        :
                        <>
                      
                            <CanvasFloatingToolbar 
                                handleSaveWorkflow={handleSaveWorkflow}
                                activeWorkflowInfo={activeWorkflowInfo}
                                setActionType={setActionType}
                                handleShowVarPlaceHolder={handleShowVarPlaceHolder}
                                getRecentJobs={getRecentJobs}
                            />
                         
                            <CanvasWidget
                                className="canvas"
                                engine={WorkflowCanvas.engine}
                            />
                            
                            <ShowWhenTrue show={showAddVarPlaceholder}>
                                <div
                                    ref={varPlaceholderRef}
                                    className="varPlaceholder__box"
                                    style={{ transform: `scale(${canvasZoomLevel})`}}
                                >
                                    <svg width="85px" height="72px" viewBox="0 0 85 72">
                                        <g id="Studio" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g id="13-3-dragged" transform="translate(-1507.000000, -399.000000)">
                                                <g id="Group-5-Copy-2" transform="translate(1513.000000, 399.000000)">
                                                    <g id="Group-4" transform="translate(1.000000, -0.000000)">
                                                        <circle id="Oval" fill="#1E2038" cx="36" cy="36" r="36"></circle>
                                                        <rect id="Rectangle" fill="#666A8D" opacity="0.200000003" x="14" y="42" width="44" height="12" rx="2"></rect>
                                                        <circle id="Oval" fill="#2A2C42" cx="36" cy="25" r="9"></circle>
                                                    </g>
                                                    <circle id="Oval-Copy-2" stroke="#1E2038" strokeWidth="6" fill="#1E2038" cx="71" cy="36" r="5"></circle>
                                                    <circle id="Oval-Copy-3" stroke="#1E2038" strokeWidth="6" fill="#1E2038" cx="2" cy="36" r="5"></circle>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </ShowWhenTrue>
                            <div
                                className={classNames('canvasDummy', { 'disableActions': (!activeComponentPropsInfo.isSaved || !activeComponentPropsInfo.isNotesSaved) && activeComponentPropsInfo.show })}
                                onClick={() => {
                                    if(!activeComponentPropsInfo.isSaved) {
                                        dispatch(closeActiveForm())
                                    } else if(!activeComponentPropsInfo.isNotesSaved) {
                                        dispatch(closeActiveComponentNotes())
                                    }
                                }}
                            />
                            <ShowWhenTrue show={showWorkflowCanvasSpinner}>
                                <div className="canvasDummy disableActions spinnerDiv">
                                    <InPageSpinner />
                                </div>
                            </ShowWhenTrue>

                            <WorkflowCanvasMagnificationTools />
                            {/* <div
                                ref={canvasContextMenuRef}
                                className={classNames('canvasContextMenu', {'show': showContextMenu})}
                            > */}
                            <ContextMenuComponent
                                ref={contextMenuRef}
                                className="canvasContextMenu"
                            >
                                <button
                                    onClick={handlePasteComponent}
                                    disabled={!componentToBeCopied}
                                >
                                    {componentToBeCopied ? `Paste "${componentToBeCopied.title}"` : 'Nothing to Paste'}
                                </button>
                            </ContextMenuComponent>
                        </>
                    }
                </div>
            </Resizable>
            <ConsoleComponent
                canvasDimensions={canvasDimensions}
                setCanvasDimensions={setCanvasDimensions}
                canvasMinMaxHeight={canvasMinMaxHeight}
                consoleInfo={activeWorkflowInfo?.consoleInfo}
                activeTabName={activeWorkflowInfo?.name || ''}
            />
            <RunPreviewWorkflowModal
                actionType={actionType}
                handleSaveWorkflow={handleSaveWorkflow}
                getRecentJobs={getRecentJobs}
            />
            {/* <ScheduleWorkflowModal type="NEW" /> */}
            <ManagePortsModal />
        </>
    );
};