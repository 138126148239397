import styled from "styled-components";

type Props = {
	children: React.ReactNode;
	primary?: boolean;
};

export const Button = styled.button<Props>`
	font-size: 12px;
	font-weight: bold;
	color: ${(props) => (props.primary ? "#030613" : "#FFF")};
	background-color: ${(props) => (props.primary ? "#D8D8D8" : "transparent")};
	border: 1px solid #c7c7cd;
	border-radius: 2px;
	padding: 4px 12px;
	padding-left: 6px;
  transition: 0.3s;

	&:hover {
		color: ${(props) => (props.primary ? "#FFF" : "#030613")};
		background-color: ${(props) =>
			props.primary ? "transparent" : "#D8D8D8"};

		svg {
			* {
				fill: ${(props) => (props.primary ? "#FFF" : "#030613")};
			}
		}
	}
`;
