import React, { useState } from "react";
import classnames from "classnames";
import styles from "../styles.module.scss";
import { StorageConnectionData } from "../types";
import { InPageSpinner } from "@components/spinners/in-page-spinner";
import { errorAlert, successAlert } from "@components/toastify/notify-toast";
import { Modal } from "@components/modals";
import { StorageConnectionForm } from "./StorageConnectionForm";
import { FileHandler } from "@api/file-handler";

type ModalProps = {
	showModal: boolean;
	toggleClose: () => void;
	getStorageConnectionData: () => void;
	storageConnectionData: StorageConnectionData | null;
};

const StorageConnectionModal: React.FC<ModalProps> = ({
	showModal,
	toggleClose,
	storageConnectionData,
	getStorageConnectionData,
}) => {
	const [showLoader, toggleLoader] = useState(false);

	const onSubmit = (values: any) => {
		const storageConnectionType = values.storageConnectionType;
		let obj: any = {
			connectionName: values.connectionName,
			storageConnectionType: values.storageConnectionType,
		};
		switch (storageConnectionType) {
			case "S3":
				obj = {
					...obj,
					accessKey: values.accessKey,
					secretKey: values.secretKey,
					region: values.region,
					bucketName: values.bucketName,
				};
				break;
			case "AzureBlobStorage":
				obj = {
					...obj,
					accountName: values.accountName,
					containerName: values.containerName,
					accessTypeName: values.accessTypeName,
					accountKeyorSasToken: values.accountKeyorSasToken,
					isAccessKey: values.accessTypeName === "Access Key",
				};
				break;
			case "AzureDataLake":	
				obj = {
					...obj,
					accountName: values.accountName,
					containerName: values.containerName,
					accessTypeName: values.accessTypeName,
					accountKeyorSasToken: values.accountKeyorSasToken,
					isAccessKey: values.accessTypeName === "Access Key",
				};
				break;
			default:
				obj;
		}

		toggleLoader(true);
		if (storageConnectionData) {
			obj = { ...obj, id: storageConnectionData.id };
			FileHandler.updateConnection(
				obj,
				(res) => {
					getStorageConnectionData();
					toggleLoader(false);
					successAlert(res.message);
					toggleClose();
				},
				(e: any) => {
					errorAlert(e.data.message);
					toggleLoader(false);
				}
			);
		} else {
			FileHandler.createConnection(
				obj,
				(res) => {
					getStorageConnectionData();
					toggleLoader(false);
					toggleClose();
					successAlert(res.message);
				},
				(e: any) => {
					errorAlert(e.data.message);
					toggleLoader(false);
				}
			);
		}
	};

	return (
		<>
			<Modal
				isOpen={showModal}
				toggleClose={toggleClose}
				title={
					storageConnectionData
						? "Edit Storage Connection"
						: "Create New Storage Connection"
				}
				subtitle={
					<div className={styles["connection-modal-subtitle"]}>
						{storageConnectionData?.connectionName || ""}
					</div>
				}
				className={classnames(
					"editConnection_container",
					styles["addEditConnectionModal"]
				)}
				shouldCloseOnOverlayClick={false}
			>
				<div
					className={styles["addEditConnectionModal__innerContainer"]}
				>
					<StorageConnectionForm
						type={!storageConnectionData ? "ADD" : "UPDATE"}
						onSubmit={onSubmit}
						initialValues={storageConnectionData}
						submitButtons={
							<div
								className={
									styles["addEditConnectionModal__actionBtns"]
								}
							>
								<div>
									<button
										className={classnames(
											"btn btn-lg btn-yellow",
											styles["connection-edit-btn"]
										)}
										type="submit"
										disabled={showLoader}
									>
										{showLoader ? (
											<InPageSpinner
												size="XSmall"
												color="black"
												className={styles["auto"]}
											/>
										) : !storageConnectionData ? (
											"Create"
										) : (
											"Update"
										)}
									</button>
									<button
										className={classnames(
											"btn btn-lg",
											styles["connection-cancel-btn"]
										)}
										type="button"
										onClick={toggleClose}
									>
										Cancel
									</button>
								</div>
							</div>
						}
					></StorageConnectionForm>
				</div>
			</Modal>
		</>
	);
};

export { StorageConnectionModal };
