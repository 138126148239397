/* eslint-disable no-useless-escape */
import { Modal } from '../../../components/modals';
import { object, string, InferType } from 'yup';
import React, { useState, useMemo, useRef, useEffect } from 'react';
import { InputField } from '../../../components/form';
import { callSuccessCallbackFunc, getWorkflowDirectories, importWorkflow, setWorkflowTabSelection, toggleModal } from '../../../store/workflow';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/types';
import { NewComponentResponse, newComponentHandler } from '../../../api/new-custom-component';
import lzString from 'lz-string';
import classNames from 'classnames';
import { errorAlert, successAlert } from '../../../components/toastify/notify-toast';
import { CanvasTabs } from '../enums';
import { WorkflowHandler, CreateProfile, CreateWorkflowResponse } from '../../../api/workflow-handler';
import { capitalize, get, has, isEmpty } from 'lodash';
import { ExecutionEnvModes, noBlankSpaceInputFieldRegex } from '../../../constants/enums';
import { WorkflowMetaData, WorkflowSerializedObjData } from '../../../components/workflow-canvas';
import { WarningIcon } from '../../adminstrator_old/icons';
import { updateExecutionEnv } from '../../../store/common';
import { RadioField } from '@components/form/radio-field';
import { Formik, Form } from 'formik';
import { errorHandlerFor400 } from '@api/services/errorhandler';
import { getUniqueId } from '@utils/common';

const newComponentSchema = (type: string) => object().shape({
    name: string().matches(noBlankSpaceInputFieldRegex,
        'Blank spaces not allowed'
    )
        /* eslint-disable no-useless-escape */
        .required(`${type} name is required.`).matches(/^[a-zA-Z0-9\.\_\-\(\)\s]*$/, {
            message: 'Only alphanumberic characters, -, _, and () is allowed'
        })
});

type newComponentSchema = InferType<typeof newComponentSchema>

interface ComponentImportModalProps {
    handleComponentImportSuccess: (arg0: NewComponentResponse) => void;
}

const importFileNamesRegex = /-\d{1,13}.dco/;
const directoryFileNamesRegex = /-\d{1,13}.zip/;

export const ComponentImportModal: React.FC<ComponentImportModalProps> = ({ handleComponentImportSuccess }) => {
    const showModal = useSelector((store: RootState) => store.WorkflowReducer.showModal.componentImport);
    const dispatch = useDispatch();
    const [fileContent, setfile] = useState({});
    const [showError, setError] = useState(false);
    const { activeTab } = useSelector((store: RootState) => store.WorkflowReducer);

    const isComponentsTabActive = activeTab === CanvasTabs.COMPONENTS;
    const isWorkflowsTabActive = activeTab === CanvasTabs.WORKFLOWS;
    const fileUploadFieldRef = useRef<HTMLInputElement>(null);
    const { activeExecutionEnv } = useSelector((store: RootState) => store.CommonReducer);
    const [uploadedWorkflowMetaData, setUploadedWorkflowMetaData] = useState<WorkflowMetaData | null>(null);
    const [initialValues, setInitialValues] = useState({ 
        name: '', 
        importtype: 'workflow',
        env: ''
     });
    const [isSubmitted, setSubmitted] = useState(false);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (showModal) {
            setLoading(false);
            setError(false);
            setfile({});
            setUploadedWorkflowMetaData(null);
            setInitialValues({ name: '', importtype: 'workflow',
            env: '' });
        }
    }, [showModal]);

    const activeType = useMemo(() => {
        let _text = 'Profile';
        if (isComponentsTabActive) _text = 'Component';
        else if (isWorkflowsTabActive) _text = 'Workflow';
        return _text;
    }, [isComponentsTabActive, isWorkflowsTabActive]);

    function toggleClose() {
        dispatch(toggleModal('componentImport', false));
    }
    function handleApiImportSuccessResponse(response: any) {
        setLoading(false);
        handleComponentImportSuccess(response);
        successAlert(response.message);
        toggleClose();
    }

    function handleErrorResponse(error: any) {
        setLoading(false);
        toggleClose();
        errorAlert(error.data.message);
    }

    function handleCreateSuccess() {
        setLoading(false);
        dispatch(callSuccessCallbackFunc());
        toggleClose();
    }

    const uploadedWorkflowEnv = uploadedWorkflowMetaData?.env

    const isUploadedWorkflowDifferentEnv = isWorkflowsTabActive && !!uploadedWorkflowEnv && uploadedWorkflowEnv !== activeExecutionEnv;

    const handleImportWorkflowSuccess = (res: CreateWorkflowResponse) => {
        if (isUploadedWorkflowDifferentEnv && !!uploadedWorkflowEnv) {
            dispatch(updateExecutionEnv(uploadedWorkflowEnv, false));
            dispatch(setWorkflowTabSelection('selectedDirectory', { 'id': res.data.directoryId, name: 'Default Directory' }));
        }
        setLoading(false);
        handleCreateSuccess();
        successAlert('Successfully imported ' + (isComponentsTabActive ? 'component' : 'workflow'));
    };

    const handleImportWorkflowError = (e: any) => {
        setLoading(false);
        setSubmitted(false);
        errorHandlerFor400(e)
    };

    const resetFileName = () => {
        if (fileUploadFieldRef.current) {
            // RESET FIELD NAME
            fileUploadFieldRef.current.value = '';
            setInitialValues({ 
                name: '', 
                importtype: 'workflow',
                env: ''
            });
        }
    };

    function handleImportComponent(values: any) {
        if(isLoading) return;
        if(values.importtype === 'workflow') {
            if (isEmpty(fileContent)) {
                return setError(true);
            }
            if (isComponentsTabActive) {
                try{
                    const ComponentData: any = fileContent;
                    const parseData: any = JSON.parse(ComponentData);
                    const payload = {
                        name: values.name,
                        payload: JSON.parse(ComponentData).defaultValue,
                        formData: JSON.parse(parseData.formData),
                        env: activeExecutionEnv
                    };
                    // Add a new key for the component on importing
                    payload.formData.key = getUniqueId();
                    setLoading(true);
                    newComponentHandler.CreateComponent(payload, handleApiImportSuccessResponse, handleErrorResponse);
                }catch(err){
                    errorAlert("Not a valid component")
                }
            } else if (isWorkflowsTabActive) {
                setLoading(true);
                dispatch(
                    importWorkflow(
                        {
                            serializedWorkflowInfo: fileContent as string,
                            workflowMetaData: {
                                env: uploadedWorkflowEnv ?? activeExecutionEnv,
                                config: uploadedWorkflowMetaData?.config || '[]',
                                workflowDependenciesRef: uploadedWorkflowMetaData?.workflowDependenciesRef || {},
                                workflowsInfo: uploadedWorkflowMetaData?.workflowsInfo || {},
                                workflowName: values.name                                
                            },
                        }, 
                        handleImportWorkflowSuccess, 
                        handleImportWorkflowError
                    )
                );
            } else {
                const data: CreateProfile = { command: fileContent as string, name: values.name };
                WorkflowHandler.CreateProfile(data, () => {
                    handleCreateSuccess();
                    successAlert('Successfully created profile');
                });
            }
        } else {
            if(fileUploadFieldRef.current?.files && fileUploadFieldRef.current.files.length < 1)  
                return setError(true);
            setSubmitted(true);
            setLoading(true);
            const formData = new FormData();
            // @ts-ignore
            formData.append("file", fileUploadFieldRef.current?.files[0]);
            WorkflowHandler.UploadDirectory(values.name, activeExecutionEnv, formData).then((data) => {
                dispatch(getWorkflowDirectories());
                handleCreateSuccess();
                successAlert('Directory uploaded successfully, workflow will be created in the background, this will take few mins to complete');
                setLoading(false);
                setSubmitted(false);
            }).catch((data)=>{
                errorAlert('Failed to create directory');
                setLoading(false);
                setSubmitted(false);
            });
        }
    }

    function onChangeFileHandler(event: React.ChangeEvent<HTMLInputElement>, values: any, setValues: any) {
        const filesList = event.target.files;
        if (filesList && filesList[0]) {
            const fileName = filesList[0].name;
            if(values.importtype === "workflow") {
                if (fileName.includes('.dco')) {
                    setError(false);
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        try {
                            const importFileContent: any = fileReader.result;
                            const importFileDecompressedContent: any = lzString.decompressFromUTF16(importFileContent);
                            if (importFileDecompressedContent) {
                                setfile(importFileDecompressedContent);
                                setInitialValues({
                                    name: fileName
                                        .replace(importFileNamesRegex, '') // Works only for file names with _timestamp.dco
                                        .replace('.dco', ''), 
                                    env: '',
                                    importtype: 'workflow'
                                });
                                if (isWorkflowsTabActive) {
                                    const _workflowInfo = JSON.parse(importFileDecompressedContent as string) as WorkflowSerializedObjData;
                                    setUploadedWorkflowMetaData({
                                        env: get(_workflowInfo, 'workflowMetaData.env'),
                                        config: get(_workflowInfo, 'workflowMetaData.config') || '[]',
                                        workflowDependenciesRef: get(_workflowInfo, 'workflowMetaData.workflowDependenciesRef'),
                                        workflowsInfo: get(_workflowInfo, 'workflowMetaData.workflowsInfo'),
                                        workflowName: get(_workflowInfo, 'workflowMetaData.workflowName')
                                    });
                                } else {
                                    setUploadedWorkflowMetaData(null)
                                }
                            } else {
                                errorAlert('File is empty or invalid');
                                resetFileName();
                            }
                        } catch {
                            errorAlert('Unable to import workflow');
                        }
                    };
                    fileReader.readAsText(filesList[0]);
                } else {
                    errorAlert('File format not supported, Please import .dco');
                    resetFileName();
                }
            } else {
                if (!fileName.includes('.zip')) {
                    errorAlert('File format not supported, Please import .zip');
                    resetFileName();
                } else {
                    const fileReader = new FileReader();
                    fileReader.onload = ()=>{
                        import("jszip").then((zip) => {
                            zip.loadAsync(fileReader.result as ArrayBuffer).then((contents) => {
                                const tfile = Object.keys(contents.files);
                                if(tfile.length > 1) {
                                    errorAlert('File is empty or invalid');
                                    fileUploadFieldRef.current && (fileUploadFieldRef.current.value = '');
                                    setfile({});
                                    setError(true);
                                    return;
                                }
                                tfile.forEach((filename) => {
                                    contents.files[filename]?.async('nodebuffer').then((content) => {
                                        try {
                                            const temp = JSON.parse(atob(content.toString()));
                                            setInitialValues({
                                                name: fileName
                                                    .replaceAll(' ', '_')
                                                    .replace(directoryFileNamesRegex, '') // Works only for file names with _timestamp.dco, 
                                                    .replace('.zip', ''),
                                                env: temp.metadata?.env,
                                                importtype: 'directory' // if file name is changed, above regex doesnt work - hence the fallback for replacing .dco
                                            });
                                            setfile(temp);
                                            setError(false);
                                        } catch(e) {
                                            errorAlert('File is empty or invalid');
                                            fileUploadFieldRef.current && (fileUploadFieldRef.current.value = '');
                                            setfile({});
                                            setError(true);
                                        }
                                    });
                                });
                            }).catch((err) => {
                                errorAlert('File is empty or invalid');
                                fileUploadFieldRef.current && (fileUploadFieldRef.current.value = '');
                                setfile({});
                                setError(true);
                            });
                        });
                    };
                    fileReader.readAsArrayBuffer(filesList[0])
                }
            }
        } else if (isEmpty(fileContent)) {
            setError(true);
            setfile({});
        } else {
            setfile({});
        }
    }

    return (
        <Modal
            isOpen={showModal}
            toggleClose={toggleClose}
            className="new__workflow__modal"
            title={'Import ' + activeType}
        >
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={newComponentSchema(activeType)}
                onSubmit={handleImportComponent}>
                {({ errors, values, touched, setValues, resetForm }) => (
                    <Form>
                        {
                            isLoading ? <><span style={{color: 'white'}}>Please wait, {values.importtype === 'workflow' ? activeType.toLowerCase() : 'directory' } is getting created</span></> : null
                        }
                        {
                            isWorkflowsTabActive? (
                                <div style={{ color: 'white' }}>
                                <RadioField
                                    name="importtype"
                                    label="Import Type"
                                    onChange={()=>{
                                        setfile({});
                                        fileUploadFieldRef.current && (fileUploadFieldRef.current.value = '');
                                    }}
                                    options={
                                        [
                                            { label: 'Workflow', value: 'workflow' },
                                            { label: 'Directory', value: 'directory' },
                                        ]
                                    }
                                />
                            </div>
                            ): null
                        }
                       
                        <InputField
                            name="name"
                            label={`Name of ${values.importtype === 'workflow' ? activeType : 'directory'}`}
                            autoComplete="off"
                            autoFocus
                        />
                        <div className='upload-btn'>
                            <label htmlFor="file"> {
                                values.importtype === 'workflow' ? `Upload ${activeType} File(.dco)` : `Upload directory file (.zip)` 
                            }  
                            </label>
                            <input className={classNames('upload-btn', { 'showinputfileError': showError })} type="file" name="file" onChange={e => onChangeFileHandler(e, values, setValues)}
                                accept={values.importtype === 'workflow' ? '.dco' : '.zip'}
                                ref={fileUploadFieldRef}
                            />
                            <span className={classNames({ 'showerror': showError })}>File is required</span>
                        </div>
                        {
                            values.importtype === 'directory' && !isEmpty(fileContent) && !isEmpty(initialValues.env) ? (
                                <div
                                className="incorrectEnv__prompt"
                            >
                                <div>
                                    <span>
                                        The file uploaded is configured to {initialValues.env} environment, the directory will be created in {initialValues.env} environment
                                    </span>
                                </div>
                            </div>) : null
                         }
                        {((isUploadedWorkflowDifferentEnv) && !!uploadedWorkflowEnv) &&
                            <div
                                className="incorrectEnv__prompt"
                            >
                                <WarningIcon fill='#fff' />
                                <div>
                                    <span>The file you uploaded is a {capitalize(uploadedWorkflowEnv)} workflow.</span>
                                    <button
                                        id="import-workflow-in-different-env"
                                        type="submit"
                                    >
                                        Do you want to import to {capitalize(uploadedWorkflowEnv)} environment?
                                    </button>
                                </div>
                            </div>
                        }
                        {isWorkflowsTabActive && activeExecutionEnv === ExecutionEnvModes.Pipelines &&
                            <span className="workflow__dagMsg">Please ensure that you have the dependent workflows to configure this DAG</span>
                        }
                        <div className="modalBtns__box">
                            <button
                                className="fl-r btn-md btn-yellow"
                                type="submit"
                                disabled={isLoading || Object.keys(errors).length !== 0 || ((values.importtype === 'directory' && (isSubmitted || isEmpty(fileContent))) || (values.importtype === 'workflow' && (isUploadedWorkflowDifferentEnv || isEmpty(fileContent))))}
                            >
                                Import
                            </button>
                            <button
                                className="fl-r btn-md btn-cancel"
                                type="button"
                                onClick={toggleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};