import React from "react";

export const useMountedRef = () => {
    const mountedRef = React.useRef(false);
  
    React.useEffect(() => {
      setTimeout(() => {
        mountedRef.current = true;
      });
    }, []);
  
    return mountedRef;
  };