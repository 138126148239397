import { ActionTypes, CanvasReducerState, CLOSE_CANVAS_TAB, OPEN_A_NEW_CANVAS_TAB, SWITCH_TO_EXISTING_CANVAS_TAB, UPDATE_A_CANVAS_TAB_INFO, CLOSE_ALL_CANVAS_TABS, TabTypes, UPDATE_ALL_OPEN_TABS, RESET_ACTIVE_TAB, WorkflowEditorTypes, WorkflowVisualizationTypes} from './types';
import { cloneDeep } from 'lodash';
import { LOAD_INITIAL_STATE, RESET_STATE } from '../types';


const initialState: CanvasReducerState = {
    workflowEditor: {
        openTabs: new Map(),
        activeTab: { type: WorkflowEditorTypes['WORKFLOW_SPARK'], id: 0, name: '' }
    },
    analytics: {
        openTabs: new Map(),
        activeTab: { type: WorkflowVisualizationTypes['WORKFLOW_VISUALIZATION_SPARK'], id: 0, name: '' }
    }
    
};

const getClone = (item: Map<any, any>): Map<any, any> => cloneDeep(item);

const addATabToOpenTabs = (openTabs: Map<any, any>, tabInfo: TabTypes) => {
    const openTabsCopy = getClone(openTabs);
    openTabsCopy.set(tabInfo.info.id, tabInfo);
    return openTabsCopy;
};

const updatePageInfo = (state: CanvasReducerState, page: keyof CanvasReducerState, payload: Partial<CanvasReducerState['analytics']> | Partial<CanvasReducerState['workflowEditor']>): CanvasReducerState => (
    { ...state, [page]: { ...state[page], ...payload }} 
);


export const CanvasReducer = (state = initialState, action: ActionTypes): CanvasReducerState => {
    switch(action.type) {
        case OPEN_A_NEW_CANVAS_TAB: {
            const { tabInfo, page } = action; 
            const updatedOpenTabs = addATabToOpenTabs(state[page].openTabs, tabInfo);
            return updatePageInfo(state, page, { activeTab: { type: tabInfo.type, id: tabInfo.info.id, name: tabInfo.info.name }, openTabs: updatedOpenTabs } as any); 
        }
        case SWITCH_TO_EXISTING_CANVAS_TAB: {
            const { page, id } = action;
            const selectedPage = state[page];
            const selectedTabInfo = selectedPage.openTabs.get(id);
            if(selectedTabInfo) return updatePageInfo(state, page, { activeTab: { type: selectedTabInfo.type, id: action.id,  name: selectedTabInfo.info.name  } } as any);
            return state;
        }
        case CLOSE_CANVAS_TAB: {
            const { page, openPenultimateTab, filterTabsFn } = action;
            const selectedPageInfo = state[page];
            const openTabs = getClone(selectedPageInfo.openTabs);
            openTabs.delete(action.id);
            const updatedPageInfo = { ...selectedPageInfo, openTabs };
            if(openTabs.size === 0 || !openPenultimateTab) {
                updatedPageInfo.activeTab = { type: updatedPageInfo.activeTab.type, id: 0, name: '' };
            } else if(openPenultimateTab) {
                // Open penultimate tab if exists
                let tabsList = Array.from(openTabs.values());
                if(filterTabsFn) {
                    // filterTabsFn is used for filtering activeExecutionEnv 
                    // In WorkflowEditor/Analytics - Spark and Python env are shown separately on the basis of toggle
                    // In spark mode, if user closes a spark tab, tab that has to be opened should be of type spark
                    // hence filterTabsFn is added
                    tabsList = tabsList.filter(filterTabsFn);
                }
                const penultimateTabInfo = tabsList[tabsList.length - 1];
                if(penultimateTabInfo) updatedPageInfo.activeTab = { type: penultimateTabInfo.type, id: penultimateTabInfo.info.id, name: penultimateTabInfo.info.name };
            }
            return updatePageInfo(state, page, updatedPageInfo);
        }
        case UPDATE_A_CANVAS_TAB_INFO:{
            const { page, tabInfo } = action;
            const updatedOpenTabs = addATabToOpenTabs(state[page].openTabs, tabInfo);
            return updatePageInfo(state, page, { openTabs: updatedOpenTabs });
        }
        case CLOSE_ALL_CANVAS_TABS: {
            if(action.page) {
                return updatePageInfo(state, action.page, initialState[action.page]);
            }   
            return cloneDeep(initialState);
        }
        case RESET_STATE:
            return cloneDeep(initialState);
        case UPDATE_ALL_OPEN_TABS: {
            const { page, updatedTabs } = action;
            return updatePageInfo(state, page, { openTabs: updatedTabs as any });
        }
        case RESET_ACTIVE_TAB: {
            const selectedPageInfo = state[action.page];
            selectedPageInfo.activeTab = initialState.workflowEditor.activeTab;
            return ({ ...state, [action.page]: selectedPageInfo });
        }
        case LOAD_INITIAL_STATE:
            return action.payload.CanvasReducer
        default:
            return state;        
    }

};