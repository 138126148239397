import classNames from 'classnames';
import { useFormikContext } from 'formik';
import {  cloneDeep, replace, isEmpty } from 'lodash';
import React, { useState, useMemo, useEffect } from 'react';
import { useDebounce } from 'rooks';
import { FieldCreatorProps, strictFunc, useGetClassNameIfHidden, convertStringToStringLiteral, FieldsCreator } from '../field-creator';
import { BaseFieldType } from '../types';


const Repeat: React.FC<FieldCreatorProps> = ({ fieldData }) => {
    const { values } = useFormikContext<any>();
    const [renderData, setRenderData] = useState([]);

    // const temp1 = useMemo(() => fieldData.field, [fieldData]);
    const [temp1, labelFieldTemplate] = useMemo(() => 
        [fieldData.fields, fieldData.fields?.find(l => l.template)?.template, fieldData.readOnly]
    ,[fieldData]);

    
    const data_field = fieldData.repeat_options ?.data_field || '';
    const _className = useGetClassNameIfHidden(fieldData);
    const regex = /[.-]/g;

    const cb = useDebounce(()=>{
        const outputFormat = fieldData.repeat_options?.outputFormat;
        if(outputFormat) {
            const outputFormatKey = Object.keys(outputFormat);
            const output: any = {};
            const extraData = values.extraData || {};
            const _extraData: any = {};
            let _renderData = [];

            if(fieldData.repeat_options?.data_type === 'extraData') {
                _renderData = values?.extraData ?.[data_field] || [];
            } else {
                const str = values?.[data_field].trim() || '';
                _renderData = isEmpty( str ) ?  [] : str.split(',');
            }
            outputFormatKey.forEach(key => {
                output[key] = [];
            });
            _renderData ?.forEach((element: any) => {
                if(fieldData.repeat_options?.data_type === 'extraData') {
                    outputFormatKey.forEach(key => {
                        output[key].push(values[key + (element._value.replace(regex, '_'))] || '');
                    });
                } else {
                    outputFormatKey.forEach(key => {
                        output[key].push(values[key + (element.replace(regex, '_'))] || '');
                    });
                }
            });
            if(fieldData.repeat_options?.data_type === 'extraData') {
                outputFormatKey.forEach(key => {
                    _extraData[key] = output[key].join(outputFormat[key].delimiter || ',');
                });
                extraData[fieldData.key] = _extraData;
            } else {
                outputFormatKey.forEach((key) => {
                    _extraData[key] = values[key] = output[key].join(outputFormat[key].delimiter || ',');
                });
                extraData[fieldData.key] = _extraData;
            }
            setRenderData(_renderData);
        }

    }, 500);

    useEffect(() => {
        cb();
    }, [values]);

    function getComponent(item: BaseFieldType, index: any) {
        const _item: any = cloneDeep(item);
        const compoenentId = (fieldData.repeat_options?.data_type === 'extraData' ? index['component_id']: index).replace(regex, '_');
        _item.key = item.key + compoenentId;
        _item.defaultValue = values[_item.key];
        _item.readOnly = fieldData.readOnly;
        _item['initialValue'] = values[_item.key];
        _item['value'] = values[_item.key];
        if (_item.hideExpression) {
            _item.hideExpression = replace(_item.hideExpression || '', /{index}/g, compoenentId + '');
        }
        const itemParentLabel = strictFunc( index, convertStringToStringLiteral(labelFieldTemplate || ''));
        
        switch (item.type) {
            case 'label':
                return <label key={_item.key} className="inputfield__label" style={{marginLeft: 17, marginTop: 10}}>{itemParentLabel}</label>;
            default:
                _item.labelForVariable = item.templateOptions.label  + ` (${itemParentLabel}),${_item.key}`;
                return <FieldsCreator key={_item.key} formData={[_item]} />;
        }
    }

    return (
        <div className={classNames('__repeatField',_className)}>
            {
                renderData.map((_items: any) => {
                    return (
                        temp1 ?.map((obj) => {
                            return getComponent(obj, _items);
                        })
                    );
                })
            }
        </div>
    );
};

export default Repeat;