import { cloneDeep } from 'lodash';
import { RESET_STATE } from '../types';
import { AnalyticsReducerState, ActionTypes, TOGGLE_WORKFLOWS_IN_ANALYTICS, TOGGLE_MODAL, SET_PLOTDATA_PROGRESS, RUN_ANALYTICS_ON_ACTIVE_COMPONENT, SET_SESSION_INFO_FOR_ERROR_MODAL, WORKFLOW_CLICKED } from './types';

const initialState: AnalyticsReducerState = {
    isWorkflowTreeviewMinimized: false,
    runAnalyticsOnActiveComponent: false,
    plotDataProgress: 0,
    showModal: {
        plotSelection: false,
        errorLogs: false,
        tiffColorPicker: false,
        clusterSelection: false
    },
    plotStatementIdForErrorModal: null,
    workflowClicked: false,
    clusterSelectionInfo: {
        error: '',
        type: 'new'
    }
};


const _keyRef: Partial<Record<ActionTypes['type'] , keyof AnalyticsReducerState>> = {
    'TOGGLE_WORKFLOWS_IN_ANALYTICS': 'isWorkflowTreeviewMinimized',
    'SET_PLOTDATA_PROGRESS': 'plotDataProgress',
    'SET_SESSION_INFO_FOR_ERROR_MODAL': 'plotStatementIdForErrorModal',
    'CLUSTER_SELECTION_INFO': 'clusterSelectionInfo'
}

export const AnalyticsReducer = (state = initialState, action: ActionTypes): AnalyticsReducerState => {
    switch(action.type){
        case TOGGLE_WORKFLOWS_IN_ANALYTICS:
        case SET_PLOTDATA_PROGRESS: 
        case SET_SESSION_INFO_FOR_ERROR_MODAL:
            return { ...state, [_keyRef[action.type] as any]: action.payload };
        case RUN_ANALYTICS_ON_ACTIVE_COMPONENT:
            return { ...state, runAnalyticsOnActiveComponent: !state.runAnalyticsOnActiveComponent };
        case TOGGLE_MODAL:
            return { ...state, showModal: { ...state.showModal, ...action.payload } };
        case WORKFLOW_CLICKED:
            return { ...state, workflowClicked: !state.workflowClicked};
        case RESET_STATE:
            return cloneDeep(initialState);
        default:
            return state;
    }
};