import { IngestionTime } from '../../helpers/utils';
import { 
    TOGGLE_USER, 
    TOGGLE_INGESTION_TIME, 
    RESET_FILTERS,
    CHANGE_PATTERN_NAME,
    CHANGE_DATE_RANGE
} from './types';

export const toggleUser = (user_view: boolean) => ({ type: TOGGLE_USER, payload: user_view }); 

export const toggleIngestionTime = (ingestion_time: IngestionTime) => ({type: TOGGLE_INGESTION_TIME, payload: ingestion_time});

export const resetFilters = () => ({type: RESET_FILTERS});

export const changePatternName = (patternName: string) => ({type: CHANGE_PATTERN_NAME, payload: patternName});

export const changeDateRange = (dateRange: any[]) => ({type: CHANGE_DATE_RANGE, payload: dateRange});