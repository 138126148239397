import _ from 'lodash';
import {
    TOGGLE_INGESTION_TIME,
    TOGGLE_USER,
    RESET_FILTERS,
    CHANGE_PATTERN_NAME,
    CHANGE_DATE_RANGE,
    BreadCrumbReducerState, 
    ActionTypes 
} from './types';
import { IngestionTime } from '../../helpers/utils';

const initialState: BreadCrumbReducerState = {
    user_view: false,
    ingestion_time: IngestionTime.Last7Days,
    resetCount: 0,
    patternname: '',
    dateRange: []
};


export const BreadCrumbReducer = (state = initialState, action: ActionTypes): BreadCrumbReducerState => {
    const new_state = _.cloneDeep(state);
    switch(action.type){
        case TOGGLE_USER:
            _.setWith(new_state, ['user_view'], action.payload);
            return new_state;
        case TOGGLE_INGESTION_TIME:
            _.setWith(new_state, ['ingestion_time'], action.payload);
            return new_state;
        case RESET_FILTERS:
            _.setWith(new_state, ['resetCount'], new_state.resetCount + 1);
            _.setWith(new_state, ['patternname'], '');
            return new_state;
        case CHANGE_PATTERN_NAME:
            _.setWith(new_state, ['patternname'], action.payload);
            return new_state;
        case CHANGE_DATE_RANGE:
            _.setWith(new_state, ['dateRange'], action.payload);
            return new_state;
        default:
            return state;
    }
};