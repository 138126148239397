import React, { useRef, useState, useImperativeHandle } from 'react';
import classNames from 'classnames';
import './styles.scss';
import { useOutsideClick } from 'rooks5';

interface ContextMenuProps {
    className?: string;
    children?: React.ReactNode;
    useWindowAsReference?: boolean;
}
export type ContextMenuComponent = {
    showContextMenu(e: React.MouseEvent<HTMLElement| SVGElement, MouseEvent>): void;
    hideContextMenu(): void;
    isOpen: () => boolean;
}

export const ContextMenuComponent = React.forwardRef<ContextMenuComponent, ContextMenuProps>(({ children, className, useWindowAsReference = false }, ref) => {
    const contextMenuRef = useRef<HTMLDivElement>(null);
    const [showContextMenu, toggleContextMenu] = useState(false);
   

    useOutsideClick(contextMenuRef, () => toggleContextMenu(false), showContextMenu);


    const handleShowContextMenu = (e:  React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        const clickX = e.clientX;
        const clickY = e.clientY;
        
        if(contextMenuRef.current && contextMenuRef.current.parentElement) {
            if(useWindowAsReference) {
                contextMenuRef.current.style.left = `${clickX}px`; 
                contextMenuRef.current.style.top = `${clickY}px`; 
            } else {
                const parentDim = contextMenuRef.current.parentElement.getBoundingClientRect();
                contextMenuRef.current.style.left = `${clickX - parentDim.left}px`; 
                contextMenuRef.current.style.top = `${clickY - parentDim.top}px`; 
            }
           
            toggleContextMenu(true);
        }
    };

    useImperativeHandle(ref, () => ({
        showContextMenu: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            handleShowContextMenu(e);
        },
        hideContextMenu: () => {
            toggleContextMenu(false);
        },
        isOpen: () => {
            // setToggle(!toggle);
            return showContextMenu; 
        }
    }), [contextMenuRef, showContextMenu]);


    return(
        <div 
            ref={contextMenuRef}
            className={classNames('contextMenuBox',{'show': showContextMenu}, className && {[className]: className})}
        >
            {children}
        </div>
    );
});

ContextMenuComponent.displayName = 'ContextMenuComponent';