import { HttpClient } from '../services';
import { API } from '../../constants/settings';
import { ApiResponse } from '../data-source-handler';
import { WorkspaceSuccessResponse, EnableWorkSpaceReq } from './types';
import { WorkspaceData } from '@pages/cluster_redisign/types';

class WorkSpaceHandlerClass {
    
    CreateNewWorkSpace(data: WorkspaceData, callback: ApiResponse<WorkspaceSuccessResponse>, errorHandlerCallback: ApiResponse<any>) {
        HttpClient.Post('Create a WorkSpace', API.databricksUrl + '/workspace/create', data, callback, errorHandlerCallback);
    }
    
    GetWorkSpaceList( callback: ApiResponse<WorkspaceData[]>) {
        HttpClient.Get('Get all WorkSpace list', API.databricksUrl + '/workspace/list', callback);
    }
  
    UpdateWorkSpace(data: WorkspaceData, callback: ApiResponse<WorkspaceSuccessResponse>, errorHandlerCallback: ApiResponse<any>) {
        HttpClient.Put('Update WorkSpace Details', API.databricksUrl + '/workspace/update', data, callback, errorHandlerCallback);
    }

    DeleteWorkSpace(_workspaceId: string, callback: ApiResponse<WorkspaceSuccessResponse>, errorHandlerCallback?: ApiResponse<any>) {
        HttpClient.Delete('Delete WorkSpace', API.databricksUrl + `/workspace/delete/${_workspaceId}`, callback, errorHandlerCallback);
    }
     
    enableWorkSpace(data: EnableWorkSpaceReq, callback: ApiResponse<WorkspaceSuccessResponse>, errorHandlerCallback: ApiResponse<any>) {
        HttpClient.Post('Enable/disable a WorkSpace', API.databricksUrl + '/workspace/default', data, callback, errorHandlerCallback);
    }
    
}

const WorkSpaceHandler = new WorkSpaceHandlerClass();

export { WorkSpaceHandler };