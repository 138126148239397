import { Env } from "@constants/settings";
import React, { createContext, useContext, useMemo, useReducer } from "react";

type DataExplorerReducerState = {
	keys: string[];
	values: string[];
	database: {
		id: number;
		name: string;
		databaseName: string;
		dbType: string;
		tableId: number;
	},
	db_table: string;
	graphIdentifier: string;
	schema: any,
	cluster: string,
	tableData: any,
	gotData: boolean,
	engine: string,
	theme: string,
	sqlQuery: string,
	workflow_details: {
		name: string,
		id: number,
		directory: number
	}
};

const dataExplorerReducerInitialState: DataExplorerReducerState = {
	keys: [],
	values: [],
	database: {
		id: -1,
		name: "",
		databaseName: "",
		dbType: "",
		tableId: -1
	},
	db_table: '',
	graphIdentifier: '',
	schema: null,
	cluster: "",
	tableData: [],
	gotData: false,
	engine: "RULE",
	theme: "dark",
	sqlQuery: "",
	workflow_details: {
		name: "",
		id: 0,
		directory: 0
	}
};

type SelectedDatabaseAction = {
	type: "SELECTED_DATABASE";
	payload: {
		id: number;
		name: string;
		databaseName: string;
		dbType: string;
		tableId: number
	};
}

type SelectedPlotData = {
	type: "SELECTED_PLOT";
	payload: {
		graphIdentifier: string;
	};
}

type CaptureSchema = {
	type: "CAPTURE_SCHEMA";
	payload: {
		data: any;
		type: "append" | "new" | "clear"
	};
}

type Cluster = {
	type: "CLUSTER";
	payload: {
		cluster: string;
	}
}

type TableData = {
	type: "TABLE_DATA";
	payload: {
		tableData: any;
	}
}

type GotData = {
	type: "GOT_DATA";
	payload: {
		gotData: boolean;
	}
}

type SQLQuery = {
	type: "SQL_QUERY";
	payload: {
		query: string;
	}
}

type SELECTEDTABLE = {
	type: "SELECTED_TABLE";
	payload: {
		table: string
	}
}
export type ENGINE_TYPE = "RULE" | "OPENAI" | "RESDSQL" | "DeepQuery6" | "DeepQuery5" | "GenerativeAI" | "GENAI";

type Engine = {
	type: "ENGINE";
	payload: {
		engine: ENGINE_TYPE
	}
}

type Theme = {
	type: "THEME";
	payload: {
		theme: string
	}
}

type DATA_EXPLORER_WORKFLOW_DETAILS = {
	type: "DATA_EXPLORER_WORKFLOW_DETAILS",
	payload: {
		workflow_details: string
	}
}

type DataExplorerReducerAction =
	| SelectedDatabaseAction
	| SelectedPlotData
	| CaptureSchema
	| Cluster
	| TableData
	| GotData
	| SQLQuery
	| Engine
	| Theme
	| DATA_EXPLORER_WORKFLOW_DETAILS
	| SELECTEDTABLE
	| { type: "RESET" }

const dataExplorerReducer = (
	state: DataExplorerReducerState,
	action: DataExplorerReducerAction
): DataExplorerReducerState => {
	switch (action.type) {
		case "SELECTED_DATABASE":
			return { ...state, ...{ database: action.payload, db_table: '' } }
		case "SELECTED_TABLE":
			return { ...state, ...{ db_table: action.payload.table } }
		case "SELECTED_PLOT":
			return { ...state, ...{ graphIdentifier: action.payload.graphIdentifier } }
		case "CAPTURE_SCHEMA":
			if (action.payload.type === "new")
				state.schema = action.payload.data;
			else if (action.payload.type === "clear") {
				state.schema = null;
			}
			return { ...state }
		case "CLUSTER":
			return { ...state, cluster: action.payload.cluster }
		case "TABLE_DATA":
			return { ...state, tableData: action.payload.tableData }
		case "RESET":
			return { ...state, tableData: null, schema: null, gotData: false }
		case "GOT_DATA":
			return { ...state, gotData: action.payload.gotData }
		case "SQL_QUERY":
			return { ...state, sqlQuery: action.payload.query }
		case "ENGINE":
			return { ...state, engine: action.payload.engine }
		case "THEME":
			return { ...state, theme: action.payload.theme }
		case "DATA_EXPLORER_WORKFLOW_DETAILS":
			// eslint-disable-next-line no-case-declarations
			const workflow_details = action.payload.workflow_details.split('|');
			Env.dataExplorer = Number(workflow_details[0])
			return {
				...state, ...{
					workflow_details: {
						directory: Number(workflow_details[2]),
						name: workflow_details[1],
						id: Number(workflow_details[0])
					}
				}
			}
		default:
			return state;
	}
};

type DataExplorerContextType = DataExplorerReducerState & {
	sendDataExplorerReducerCmd: (arg0: DataExplorerReducerAction) => void;
	setEngine: (value: ENGINE_TYPE) => void,
	setTheme: (value: string) => void,
	setWorkflowDetails: (value: string) => void,
	setSQLQuery: (sql: string) => void,
	setCluster: (cluster: string) => void,
	setPlot: (cluster: string) => void,
	setDBTable: (tblName: string) => void
};

const DataExplorerContext = createContext<DataExplorerContextType>(
	{} as DataExplorerContextType
);

const DataExplorerProvider: React.FC<{}> = ({ children }) => {
	const [state, dispatch] = useReducer(
		dataExplorerReducer,
		dataExplorerReducerInitialState
	);

	const setWorkflowDetails = (value: string) => {
		dispatch({
			type: "DATA_EXPLORER_WORKFLOW_DETAILS",
			payload: {
				workflow_details: value
			}
		})
	}

	const setEngine = (value: ENGINE_TYPE) => {
		dispatch({
			type: "ENGINE",
			payload: {
				engine: value
			}
		})
	}

	const setTheme = (value: string) => {
		dispatch({
			type: "THEME",
			payload: {
				theme: value
			}
		})
	}

	const setSQLQuery = (sql: string) => {
		dispatch({
			type: "SQL_QUERY",
			payload: {
				query: sql
			}
		})
	}

	const setCluster = (cluster: string) => {
		dispatch({
			type: "CLUSTER",
			payload: {
				cluster
			}
		})
	}

	const setPlot = (plot: string) => {
		dispatch({
			type: 'SELECTED_PLOT',
			payload: {
				graphIdentifier: plot
			}
		});
	}

	const setDBTable = (tblName: string) => {
		dispatch({
			type: 'SELECTED_TABLE',
			payload: {
				table: tblName
			}
		});
	}

	const _analyticsContext: DataExplorerContextType = useMemo(() => {
		return {
			...state,
			sendDataExplorerReducerCmd: dispatch,
			setEngine,
			setTheme,
			setWorkflowDetails,
			setSQLQuery,
			setCluster,
			setPlot,
			setDBTable
		};
	}, [state, dispatch]);

	return (
		<DataExplorerContext.Provider value={_analyticsContext}>
			{children}
		</DataExplorerContext.Provider>
	);
};

const useDataExplorerContext = () => {
	return useContext(DataExplorerContext);
};

export { DataExplorerProvider, useDataExplorerContext };
