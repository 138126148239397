import React from 'react';

export const FileBrowserIcon: React.FC = () => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
        <defs>
            <path
                d="M12.79976,12.8 L12.79976,12 C12.79976,11.5584 12.44216,11.2 11.99976,11.2 C11.55736,11.2 11.19976,11.5584 11.19976,12 L11.19976,12.8 L10.39976,12.8 C9.95736,12.8 9.59976,13.1584 9.59976,13.6 C9.59976,14.0416 9.95736,14.4 10.39976,14.4 L11.19976,14.4 L11.19976,15.2 C11.19976,15.6416 11.55736,16 11.99976,16 C12.44216,16 12.79976,15.6416 12.79976,15.2 L12.79976,14.4 L13.59976,14.4 C14.04216,14.4 14.39976,14.0416 14.39976,13.6 C14.39976,13.1584 14.04216,12.8 13.59976,12.8 L12.79976,12.8 Z M7.644,18.4 C7.3992,18.4 7.2,18.2208 7.2,18 L7.2,6 C7.2,5.7792 7.3992,5.6 7.644,5.6 L12,5.6 L12,8.12 C12,9.3776 12.9736,10.4 14.1712,10.4 L16.8,10.4 L16.8,18 C16.8,18.2208 16.6008,18.4 16.3552,18.4 L7.644,18.4 Z M13.6,6.3824 L15.7936,8.8 L14.1712,8.8 C13.856,8.8 13.6,8.4952 13.6,8.12 L13.6,6.3824 Z M13.8368,4.2624 C13.6848,4.0952 13.4704,4 13.244,4 L7.644,4 C6.5168,4 5.6,4.8976 5.6,6 L5.6,18 C5.6,19.1024 6.5168,20 7.644,20 L16.3552,20 C17.4824,20 18.4,19.1024 18.4,18 L18.4,9.6 C18.4,9.4008 18.3256,9.2096 18.192,9.0624 L13.8368,4.2624 Z"
                id="path-1-FB"
            />
        </defs>
        <g
            id="Icons/File-browser"
            stroke="none"
            strokeWidth={1}
            fill="none"
            fillRule="evenodd"
        >
            <mask id="mask-2-FB" fill="white">
                <use xlinkHref="#path-1-FB" />
            </mask>
            <use id="🎨-Icon-Сolor" fill="#222B45" xlinkHref="#path-1-FB" />
            <g
                id="↳-🎨Color/Neutral/300"
                mask="url(#mask-2-FB)"
                fill="rgba(0, 0, 0, 0.5)"
            >
                <rect id="Color-Container" x={0} y={0} width={24} height={24} />
            </g>
        </g>
    </svg>
);

export const CaptureSchemaIcon: React.FC = () => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
        <defs>
            <path
                d="M15.9294833,11.9130435 C18.1741882,11.9130435 20,13.7267273 20,15.9565217 C20,18.1863162 18.1741882,20 15.9294833,20 C13.6847783,20 11.8589666,18.1863162 11.8589666,15.9565217 C11.8589666,13.7267273 13.6847783,11.9130435 15.9294833,11.9130435 Z M16.0000146,13 C15.7545471,13 15.550393,13.1768741 15.5080559,13.410124 L15.5,13.5 L15.5,15.499978 L13.5,15.499978 C13.2238563,15.499978 13,15.7238344 13,15.9999854 C13,16.2454529 13.1768741,16.449607 13.410124,16.4919441 L13.5,16.5 L15.499978,16.5 L15.499978,18.5 C15.499978,18.7761437 15.7238344,19 15.9999854,19 C16.2454529,19 16.449607,18.8231259 16.4919441,18.589876 L16.5,18.5 L16.5,16.5 L18.5,16.5 C18.7761144,16.5 19,16.2761656 19,16.0000146 C19,15.7545471 18.8231259,15.550393 18.589876,15.5080559 L18.5,15.5 L16.5,15.5 L16.5,13.5 C16.5,13.2238563 16.2761656,13 16.0000146,13 Z M16.6638298,4 C17.6629784,4 18.4729483,4.80458972 18.4729483,5.79710145 L18.472,10.7 L17.568,10.7 L17.5683891,5.79710145 C17.5683891,5.33629243 17.2191928,4.95650081 16.7693205,4.90459593 L16.6638298,4.89855072 L14.1455927,4.89855072 L14.1455927,10.6985507 L13.0455927,10.6985507 L13.0455927,4.89855072 L9.45,4.89855072 L9.45,17.4782609 L10.499,17.478 L10.499,18.376 L5.80911854,18.3768116 C4.80996996,18.3768116 4,17.5722219 4,16.5797101 L4,5.79710145 C4,4.80458972 4.80996996,4 5.80911854,4 L16.6638298,4 Z M8.35,4.89855072 L5.80911854,4.89855072 C5.34522813,4.89855072 4.96289687,5.24542742 4.9106449,5.69231145 L4.90455927,5.79710145 L4.90455927,16.5797101 C4.90455927,17.0405192 5.2537555,17.4203108 5.70362782,17.4722157 L5.80911854,17.4782609 L8.35,17.4782609 L8.35,4.89855072 Z"
                id="path-1-CS"
            />
        </defs>
        <g
            id="Icons/Capture-Columns"
            stroke="none"
            strokeWidth={1}
            fill="none"
            fillRule="evenodd"
        >
            <mask id="mask-2-CS" fill="white">
                <use xlinkHref="#path-1-CS" />
            </mask>
            <use
                id="Combined-Shape"
                fill="#000000"
                fillRule="nonzero"
                xlinkHref="#path-1-CS"
            />
            <g
                id="↳-🎨Color/Neutral/300"
                mask="url(#mask-2-CS)"
            >
                <rect id="Color-Container" x={0} y={0} width={24} height={24} />
            </g>
        </g>
    </svg>
);

export const ResetCaptureSchemaIcon = () => (
    <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                d="M20.0000096,12.0138169 C20.0056823,15.6814886 17.4976365,18.8781009 13.9268958,19.7542495 C10.3561552,20.6303981 6.64748137,18.9591754 4.94653252,15.7074694 C3.24558367,12.4557634 3.99287687,8.46571118 6.75606464,6.04576281 C9.51925241,3.62581443 13.5827157,3.40269569 16.5955325,5.50549223 L16.4980087,5.22252159 C16.3413224,4.74635484 16.6013151,4.23365515 17.0787186,4.07737478 C17.5561222,3.92109442 18.0701535,4.18041356 18.2268398,4.65658031 L19.1134199,7.30943005 C19.2026061,7.57920098 19.1564294,7.87528227 18.9892987,8.10528497 C18.8148999,8.35022993 18.5277451,8.49010504 18.2268398,8.47668394 L15.5670996,8.47668394 C15.0774549,8.47668394 14.6805195,8.08077684 14.6805195,7.59240069 C14.687093,7.20837979 14.9414478,6.87250076 15.3099913,6.76117444 C12.9112011,5.26581106 9.80211152,5.57758382 7.74977655,7.51929638 C5.69744158,9.46100894 5.22124422,12.5412734 6.59183167,15.0094187 C7.96241911,17.477564 10.8329377,18.7089783 13.5719257,18.003786 C16.3109136,17.2985938 18.225217,14.8352577 18.2268398,12.0138169 C18.2268398,11.5254408 18.6237753,11.1295337 19.1134199,11.1295337 C19.6030646,11.1295337 20.0000096,11.5254408 20.0000096,12.0138169 L20.0000096,12.0138169 Z"
                id="path-1-RCS"
            />
        </defs>
        <g
            id="Icons/Table/Reset"
            stroke="none"
            strokeWidth={1}
            fill="none"
            fillRule="evenodd"
        >
            <mask id="mask-2-RCS" fill="white">
                <use xlinkHref="#path-1-RCS" />
            </mask>
            <use
                id="Path"
                fill="#000000"
                fillRule="nonzero"
                transform="translate(12.020606, 12.008657) scale(1, -1) rotate(-135.000000) translate(-12.020606, -12.008657) "
                xlinkHref="#path-1-RCS"
            />
            <g id="↳-🎨Color/Neutral/300" mask="url(#mask-2-RCS)" fill="#9495A0">
                <rect id="Color-Container" x={0} y={0} width={24} height={24} />
            </g>
        </g>
    </svg>
);

export const NewExperimentAddIcon = () => (
    <svg
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                d="M10,2.5 C10.4602373,2.5 10.8333333,2.87078857 10.8333333,3.3278314 L10.833,9.166 L16.6725,9.16666667 C17.0940385,9.16666667 17.4424408,9.48193294 17.4935469,9.89485966 L17.5,10 C17.5,10.46 17.1291667,10.8333333 16.6725,10.8333333 L16.6725,10.8333333 L10.833,10.833 L10.8333333,16.6721686 C10.8333333,17.0941981 10.518163,17.4424674 10.1051625,17.49355 L10,17.5 C9.53976271,17.5 9.16666667,17.1292114 9.16666667,16.6721686 L9.166,10.833 L3.3275,10.8333333 C2.90596154,10.8333333 2.55755917,10.5187771 2.50645312,10.1053042 L2.5,10 C2.5,9.54 2.87083333,9.16666667 3.3275,9.16666667 L3.3275,9.16666667 L9.166,9.166 L9.16666667,3.3278314 C9.16666667,2.90580187 9.48183695,2.55753265 9.89483751,2.50644998 L10,2.5 Z"
                id="path-1-NEAI"
            />
        </defs>
        <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
            <g
                id="Data-Studio---VD-V3"
                transform="translate(-509.000000, -67.000000)"
            >
                <g
                    id="standard/action/add"
                    transform="translate(507.000000, 65.000000)"
                >
                    <mask id="mask-2-NEAI" fill="white">
                        <use xlinkHref="#path-1-NEAI" />
                    </mask>
                    <use id="new" fill="white" fillRule="evenodd" xlinkHref="#path-1-NEAI" />
                    {/* <g
                        id="White-/-B200"
                        mask="url(#mask-2-NEAI)"
                        fill="#E0E1EC"
                        fillRule="evenodd"
                    >
                        <polygon id="B200" points="0 20 20 20 20 0 0 0" />
                    </g> */}
                </g>
            </g>
        </g>
    </svg>
);


export const UpChevronIcon = () => {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
            <mask id="a-UCI" maskUnits="userSpaceOnUse" x={6} y={7} width={12} height={8}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18 13.573L16.59 15 12 10.364 7.41 15 6 13.573 12 7.5l6 6.073z"
                    fill="#fff"
                />
            </mask>
            <g mask="url(#a-UCI)">
                <path
                    transform="matrix(1 0 0 -1 0 24)"
                    fill="#C7C7CD"
                    d="M0 0H24V24H0z"
                />
            </g>
        </svg>
    );
};

export const CrossIcon = () => (
    <svg 
        width={20} 
        height={20} 
        viewBox="0 0 16 16" 
        fill="none"
        id="errorCrossIcon"    
    >
        <path fillRule="evenodd" clipRule="evenodd" d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM10.7702 10.7704C11.0766 10.464 11.0766 9.96869 10.7702 9.66227L9.10814 8.0001L10.7702 6.33793C11.0766 6.03151 11.0766 5.53544 10.7702 5.22981C10.4638 4.9234 9.96856 4.9234 9.66216 5.22981L8.0001 6.89198L6.33725 5.22981C6.03164 4.9234 5.53561 4.9234 5.22921 5.22981C4.9236 5.53544 4.9236 6.03151 5.22921 6.33793L6.89127 8.0001L5.22921 9.66227C4.9236 9.96869 4.9236 10.464 5.22921 10.7704C5.3828 10.9232 5.58262 11 5.78323 11C5.98384 11 6.18445 10.9232 6.33725 10.7704L8.0001 9.10821L9.66216 10.7704C9.81497 10.9232 10.0156 11 10.2162 11C10.4168 11 10.6174 10.9232 10.7702 10.7704Z" fill="#B01900" />
        <mask id="mask0-CI" mask-type="alpha" maskUnits="userSpaceOnUse" x={2} y={2} width={12} height={12}>
            <path fillRule="evenodd" clipRule="evenodd" d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM10.7702 10.7704C11.0766 10.464 11.0766 9.96869 10.7702 9.66227L9.10814 8.0001L10.7702 6.33793C11.0766 6.03151 11.0766 5.53544 10.7702 5.22981C10.4638 4.9234 9.96856 4.9234 9.66216 5.22981L8.0001 6.89198L6.33725 5.22981C6.03164 4.9234 5.53561 4.9234 5.22921 5.22981C4.9236 5.53544 4.9236 6.03151 5.22921 6.33793L6.89127 8.0001L5.22921 9.66227C4.9236 9.96869 4.9236 10.464 5.22921 10.7704C5.3828 10.9232 5.58262 11 5.78323 11C5.98384 11 6.18445 10.9232 6.33725 10.7704L8.0001 9.10821L9.66216 10.7704C9.81497 10.9232 10.0156 11 10.2162 11C10.4168 11 10.6174 10.9232 10.7702 10.7704Z" fill="white" />
        </mask>
    </svg>

)

export const SmallAddWhiteIcon = () => {
  return (
    <svg
      width={17}
      height={16}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 8h11M8.5 2.5v11"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  )
}