import { AppThunk } from '../types';
import { GET_USER_INFO, AccountReducerState, SET_ALL_USERS_LIST, SET_USER_DIRECTORIES, SetUserDirectories, SET_ACTIVE_SESSIONS } from './types';
import { ActiveSessionsInfo, AllUsersInfo, UserHandler } from '../../api/user-handler';
import LogRocket from 'logrocket';
import { Env } from '../../constants/settings';
import { onLoadSuccess } from '../common';
import { DirectoryInfo } from '../../api/workflow-handler';
import { KeycloakUserInfo } from '../../services';

export const setUserInfoInStore = (retrieveInfoOnSuccess: boolean, response: KeycloakUserInfo, isAdmin: boolean): AppThunk => (dispatch) => {
    const payload: AccountReducerState['activeUserInfo'] = { 
        isAdmin: false,
        id: response.preferred_username,
        username: response.preferred_username,
        name: response.given_name,
        email: response.email
    };
    payload.isAdmin = isAdmin;
    if(Env.enableLogRocket) {
        const identifier = (payload.id ? payload.id: response.email) + '__' + payload.id;
        LogRocket.identify(identifier, {
            name: response.name,
            email: response.email,
            id: payload.id,
            username: payload.id,
        });
    }

    if(retrieveInfoOnSuccess) {
        dispatch(onLoadSuccess() as any);
    }

    dispatch({ type: GET_USER_INFO, payload });
};


export const getAllUsersList = (): AppThunk => (dispatch) => {
    UserHandler.GetAllUsers((response: AllUsersInfo) => {
        const __usersList: AccountReducerState['allUsersList'] = response;
        dispatch({type: SET_ALL_USERS_LIST, payload: __usersList });
    });
    
};

export const setUserDirectories = (key: SetUserDirectories['key'], payload: DirectoryInfo[]): SetUserDirectories => 
    ({ type: SET_USER_DIRECTORIES, payload, key });

export const setActiveSessionsInfo = (payload: ActiveSessionsInfo) =>
    ({ type: SET_ACTIVE_SESSIONS, payload });