import React from 'react';
import { Tag, Input, Icon } from 'antd';
import classNames from 'classnames';
import { TooltipTop } from '../tooltips';
import { ShowWhenTrue } from '../../helpers';
// import { TweenOneGroup } from 'rc-tween-one';
import { connect, FormikContextType, isString } from 'formik';
import { isEqual } from 'lodash';


interface TagFieldProps { 
    required?: boolean; 
    infoText?: string; 
    name: string; 
    label: string;
    labelForNewTag?: string;
}

type TagFieldState = { tags: string[]; inputVisible: boolean; inputValue: string }

type _TagFieldProps = TagFieldProps & { formik: FormikContextType<any>}

class _TagField extends React.Component<_TagFieldProps> {
  state: TagFieldState = {
      tags: [],
      inputVisible: false,
      inputValue: '',
  };

  componentDidUpdate(prevProps: _TagFieldProps , prevState: { tags: string[]}) {
      if(prevProps.formik.initialValues.name !== this.props.formik.initialValues.name) {
          const initialValue = this.props.formik.initialValues.name;
          if(isString(initialValue)) {
              this.setState({ tags: initialValue.split(',')});
          }
      }
      if(!isEqual(prevState.tags, this.state.tags)) {
          this.props.formik.setFieldValue(this.props.name, this.state.tags.join(','));
      }
  }

  handleRemoveTag = (removedTag: string) => {
      const tags = this.state.tags.filter(tag => tag !== removedTag);
      this.setState({ tags });
  };

  showInput = () => {
      this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = (e: { target: { value: any } }) => {
      this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
      const { inputValue } = this.state;
      let { tags } = this.state;
      if (inputValue && tags.indexOf(inputValue) === -1) {
          tags = [...tags, inputValue];
      }
      this.setState({
          tags,
          inputVisible: false,
          inputValue: '',
      });
  };

  saveInputRef = (input: any) => (this.input = input);

  forMap = (tag: string) => {
      const tagElem = (
          <Tag
              closable={false}
              className="multiple-select-tags "
          >
              {tag}
              <span 
                  className="closeIcon"
                  onClick={(e: { preventDefault: () => void }) => {
                      e.preventDefault();
                      this.handleRemoveTag(tag);
                  }}
              >
            X
              </span>
          </Tag>
      );
      return (
          <span key={tag} className="tagField__tags" style={{ display: 'inline-block' }}>
              {tagElem}
          </span>
      );
  };
    input: any;

    render() {
        const { tags, inputVisible, inputValue } = this.state;
        const tagChild = tags.map(this.forMap);
        const { required, infoText, name, label, labelForNewTag = 'New Tag' } = this.props;
        return (
        
            <div
                className="tagField__outerBox"
    
            >
                <label 
                    className="inputfield__label"
                    htmlFor={name}
                >
                    <span className={required ? 'red-star': ''}>{label}</span>
                    <ShowWhenTrue show={!!infoText}>
                        <TooltipTop placement="topRight" overlay={infoText}>
                            <img src="/icons/info-fields.png" width="16" height="16" className="info__icon" alt="information-icon" />
                        </TooltipTop>
                    </ShowWhenTrue>
                </label>
                <div
                    className={classNames('tagField__innerBox', {'active': inputVisible})}    
                >
                    {tagChild}
                    {(inputVisible) && (
                        <Input
                            ref={this.saveInputRef}
                            type="text"
                            size="small"
                            style={{ minWidth: 78 }}
                            value={inputValue}
                            onChange={this.handleInputChange}
                            onBlur={this.handleInputConfirm}
                            onPressEnter={this.handleInputConfirm}
                            className=""
                        />
                    )}
                    {!inputVisible && (
                        <Tag 
                            onClick={this.showInput} 
                            className="btn-grey"    
                        >
                            <Icon type="plus" /> {labelForNewTag}
                        </Tag>
                    )}
                </div>
            </div>
        );
    }
}

const TagField = connect<TagFieldProps, _TagFieldProps>(_TagField);
export { TagField };