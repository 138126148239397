import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { cloneDeep, upperCase, get, has, omit, startCase, lowerCase, isEmpty, set } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { AutoSizer, Table, Column, TableCellRenderer, TableHeaderRenderer, TableRowRenderer } from 'react-virtualized';
import { defaultRowRenderer } from 'react-virtualized/dist/es/Table';
import { useInterval } from 'rooks';
import { ClusterLibraryInfo, InstallClusterInfo, NewDatabricksHandlerClass } from '../../api/databricks-handler';
import { FileHandler } from '../../api/file-handler';
import Form, { InputField, SelectField } from '../../components/form';
import { RadioField } from '../../components/form/radio-field';
import { stringFieldSchema } from '../../components/formcreators/ml-pipeline/utils';
import { FieldSchemaValidator } from '../../components/formcreators/schema-creator';
import Paginate from '../../components/paginate';
import { errorAlert, successAlert } from '../../components/toastify/notify-toast';
import { ShowWhenTrue } from '../../helpers';
import { convertEnumsToOptions } from '../../utils';
import {FileRejection, useDropzone} from 'react-dropzone';
import { EggIcon, GreenTickIcon, JarIcon, RedDeleteIcon, WarningIcon, WhlIcon } from '../adminstrator_old/icons';
import moment from 'moment';
import { uuid } from 'uuidv4';
import { Progress } from 'antd';
import { Env } from '../../constants/settings';
import { GoBackArrow } from '../workflow-analytics-page/assets/icons';


const COLORS_STATUS_TAG: Record<ClusterLibraryInfo['status'], string> = {
    'INSTALLED': '#8FFF73',
    'PENDING': '#C7C7CD',
    'RESOLVING': '#5CD1E7',
    'INSTALLING': '#25B100',
    'SKIPPED': '#666A8D',
    'FAILED': '#B01900',
    'UNINSTALL_ON_RESTART': '#FF795D'
};

enum LibrarySources {
    Upload = 'upload',
    PyPi = 'pypi',
    Maven = 'maven',
    CRAN = 'cran',
}

// const librarySourcesOptions = convertEnumsToOptions(LibrarySources);

const _librarySourcesOptions = [{ label: 'PyPi', value: 'pypi'}];

enum UploadLibraryTypes {
    Jar = 'jar',
    'Python Egg' = 'egg',
    'Python Whl' = 'whl'
}

const UploadLibraryOptions = convertEnumsToOptions(UploadLibraryTypes);

const placeholderIcons: Record<UploadLibraryTypes, () => JSX.Element> = {
    'jar': JarIcon,
    'egg': EggIcon,
    'whl': WhlIcon
};

// const TEST_CLUSTER_ID = '0303-103415-amend886';

type FilesMetaData = Record<string, {
    uploadedAt: string; 
    uploadedPer: number; 
    status: 'SUCCESS' | 'FAILED' | 'IN_PROGRESS';
    type: UploadLibraryTypes;
    size: string;
}>

type FileInfo = {
    id: string;
    file: File;
}

type InstallLibraryFormProps = {
    handleCloseModal: () => void;
    discardPrompt: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

const InstallLibraryForm: React.FC<InstallLibraryFormProps> = ({ handleCloseModal, discardPrompt }) => {
    const { values, setFieldValue } = useFormikContext<any>();
    const [filesList, setFilesList] = useState<FileInfo[]>([]);
    const [filesMetaData, setFilesMetaData] = useState<FilesMetaData>({});
    const [showDiscardPrompt, toggleDiscardPrompt] = discardPrompt;

    const handleFileUploadFailure = (fileId: string) => {
        setFilesMetaData(fileMeta => {
            const _fileMeta = cloneDeep(fileMeta);
            if(_fileMeta[fileId]) 
                _fileMeta[fileId].status = 'FAILED';
            return _fileMeta;            
        });
    };
    
    useEffect(() => {
        setFieldValue('filesList', filesList.map(file => file.file.name));
    }, [filesList]);
    

    const handleFileuploadSucess = (fileName: string) => {
        successAlert(fileName + ' Uploaded Successfully');
        return;
    };
    
    const handleUploadProgress = (fileId: string, progressEvent: ProgressEvent) => {
        const percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        setFilesMetaData(fileMeta => {
            const _fileMeta = cloneDeep(fileMeta);
            if(_fileMeta[fileId])
                _fileMeta[fileId].uploadedPer = percentCompleted;
            if(percentCompleted === 100) {
                _fileMeta[fileId].status = 'SUCCESS';
            }
            return _fileMeta;            
        });
    };

    const formatFileSize = (bytes: number) => {
        if(bytes == 0) return '0 Bytes';
        const k = 1000;
        const sizes = ['B', 'Kb', 'Mb', 'Gb'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    function handleFileUpload(this: any, fileId: string, fileType: UploadLibraryTypes, file: File){
        const formData = new FormData();
        formData.append('file', file);
        setFilesMetaData(fileMeta => {
            const _fileMeta = cloneDeep(fileMeta);
            const timeObj = moment();
            _fileMeta[fileId] = {
                uploadedAt: timeObj.format('D MMM, YYYY') + ' at ' + timeObj.format('HH:mm'),
                status: 'IN_PROGRESS',
                uploadedPer: 0,
                type: fileType,
                size: formatFileSize(file.size)
            };
            return _fileMeta;
        });
        FileHandler.uploadFile(Env.uploadLibraryPath, formData, handleFileuploadSucess.bind(this, file.name), handleFileUploadFailure.bind(this, fileId), handleUploadProgress.bind(this, fileId));
    }


    const handleDropRejection = (fileRejections: FileRejection[]) => {
        fileRejections.forEach(f => {
            // errorAlert('File format not supported, Please import .'+ values.libraryTypeForUpload);
            errorAlert(f.file.name + ' - '+ f.errors[0].message);
        });
    };

    const handleDropAccept = (uploadedFiles: File[]) => {

        const __files: FileInfo[] = uploadedFiles.map(file => { 
            const id = uuid();
            handleFileUpload(id, values.libraryTypeForUpload, file);
            return({ id, file });
        });
        
        setFilesList([ ...filesList, ...__files ]);
    };

    const validateFileDrop = (file: File) => {
        if(/\s/.test(file.name)){
            return {message:'Cannot have spaces', code: 'no-spaces'};
        } return null;
    };

    const {
        getRootProps,
        getInputProps,
        
    } = useDropzone({
        multiple: true, 
        accept: '.'+values.libraryTypeForUpload, 
        onDropRejected: handleDropRejection, 
        maxFiles: 5 - filesList.length, 
        onDropAccepted: handleDropAccept,
        validator: validateFileDrop
    });


    const [fileInUploadingState, uploadedFiles] = useMemo(() => {
        const [fileInUploadingState, uploadedFiles]: FileInfo[][] =  [[], []];
        filesList.forEach(file => {
            if(filesMetaData[file.id]?.status === 'IN_PROGRESS') {
                fileInUploadingState.push(file);
            } else {
                uploadedFiles.push(file);
            }
        });

        return [fileInUploadingState, uploadedFiles];
        // return [uploadedFiles, uploadedFiles]

    }, [filesList, filesMetaData]);


    const handleDeleteFile = (fileId: string, fileName: string) => {
        FileHandler.DeleteFile('/'+Env.uploadLibraryPath + '/' + fileName, () => {
            return;
        });
        
        setFilesList(filesList => filesList.filter(file => file.id !== fileId));
        setFilesMetaData(filesMetaData =>  omit(filesMetaData, fileId));
    };

    const handleDiscardFiles = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        filesList.forEach(f => {
            handleDeleteFile(f.id, f.file.name);
        });
        toggleDiscardPrompt(false);
    };

    // @ts-ignore
    const PlaceholderIcon = placeholderIcons[values.libraryTypeForUpload as UploadLibraryTypes];

    const renderFilesInfo = (title: string,  files: FileInfo[]) => (
        <div
            className="fileUpload__innerBox"
        >
            <span className="uploadedFilesType">{title} ({files.length})</span>
            {files.map((file) => {
                const fileInfo = filesMetaData[file.id];
                return(
                    <div
                        className="fileUploadedStatus__box"
                        key={file.id}
                    >
                        <div
                            className="fileUploadedStatus__innerBox"
                        >
                            
                            <PlaceholderIcon />
                            <ShowWhenTrue show={fileInfo.status !== 'IN_PROGRESS'}>
                                <div
                                    className="fileUploadStatus__icon"
                                >
                                    {fileInfo.status === 'SUCCESS' ?
                                        <GreenTickIcon />
                                        :
                                        <WarningIcon />
                                    }
                                </div>
                            </ShowWhenTrue>
                            <div 
                                className="fileInfo__box"
                            >
                                <span className="fileName">{file.file.name}</span>
                                <div>
                                    {fileInfo.uploadedAt} . {fileInfo.size}
                                </div>
                            </div>
                        </div>
                        {
                            fileInfo.status === 'IN_PROGRESS' ?
                                <div
                                    className="progressCircle"
                                >
                                    <Progress type="circle" percent={fileInfo.uploadedPer} width={40} strokeColor="#FCAD01" trailColor="#9495A0"  /> 
                                </div>
                                :
                                <button
                                    id="btn_deleteFile"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteFile(file.id, file.file.name);}
                                    }
                                >
                                    <RedDeleteIcon />
                                </button>
                        }
                        
                    </div>
                );})
            }
        </div>
    );

    const showPlaceholder = isEmpty(fileInUploadingState) && isEmpty(uploadedFiles);

    const handleShowDiscardPrompt = () => {
        if(!showPlaceholder) {
            toggleDiscardPrompt(true);
        }
    };


    const renderUploadFields = () => {
        return(
            <>
                <div
                    className={classNames({'disableSelection': !showPlaceholder })}
                    onClick={handleShowDiscardPrompt}
                >
                    <RadioField 
                        name="libraryTypeForUpload"
                        label="Library Type"
                        options={UploadLibraryOptions}
                        className="libraryTypeForUpload"
                    />
                </div>
                
                <div
                    {...getRootProps({className: classNames('fileUpload__outerBox', { showPlaceholder })})}
                >
                    {isEmpty(fileInUploadingState) && isEmpty(uploadedFiles) ? 
                        <div
                            className="fileUpload__placeholder"
                        >
                            <PlaceholderIcon />
                            <span> 
                                Drop {' '+ upperCase(values.libraryTypeForUpload)+ ' '}here
                            </span>
                            <span
                                className="fileUpload__placeholder__subTitle"
                            >
                                (Upload up to 5 files)
                            </span>
                           
                        </div>
                        :
                        <>
                            <ShowWhenTrue show={!isEmpty(fileInUploadingState)}>
                                {renderFilesInfo('Uploading', fileInUploadingState)}
                            </ShowWhenTrue>
                            <ShowWhenTrue show={!isEmpty(fileInUploadingState) && !isEmpty(uploadedFiles)}>
                                <div className="filesSeparator" />
                            </ShowWhenTrue>
                            <ShowWhenTrue show={!isEmpty(uploadedFiles)}>
                                {renderFilesInfo('Uploaded', uploadedFiles)}
                            </ShowWhenTrue>
                            <ShowWhenTrue show={showDiscardPrompt}> 
                                <div
                                    className="fileUpload__discardChanges"
                                >
                                    <label>Discard Uploads?</label>
                                    <div>
                                        <button
                                            className="btn-sm btn-yellow"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                toggleDiscardPrompt(false);}
                                            }
                                        >
                                                Cancel
                                        </button>
                                        <button
                                            className="btn-sm"
                                            onClick={handleDiscardFiles}
                                        >
                                                Yes
                                        </button>
                                    </div>
                                </div>
                            </ShowWhenTrue>
                        </>
                    }
                    <input 
                        {...getInputProps()}
                    />
                </div>
            </>
        );
    };

    const renderPyPiFields = () => (
        <div
            className="librarySource__fields"
        >
            <InputField 
                name="pypi.package"
                label="Package"
                placeholder="PyPI package (simplejson or simplejson==3.8.0)"
                validate={FieldSchemaValidator(stringFieldSchema)}
                required
            />
            <InputField 
                name="pypi.repo"
                label="Repository"
                placeholder="Optional"
            />
        </div>
    );

    const renderCranFields = () => (
        <div
            className="librarySource__fields"
        >
            <InputField 
                name="cran.package"
                label="Package"
                placeholder="CRAN package"
                validate={FieldSchemaValidator(stringFieldSchema)}
                required
            />
            <InputField 
                name="cran.repo"
                label="Repository"
                placeholder="Optional"
            />
        </div>
    );

    const renderMavenFields = () => (
        <div
            className="librarySource__fields maven__fields"
        >
            <InputField 
                name="maven.coordinates"
                label="Package"
                placeholder="Maven Coordinates (com.databricks:spark-csv_2.10:1.0.0)"
                validate={FieldSchemaValidator(stringFieldSchema)}
                required
            />
            <InputField 
                name="maven.repo"
                label="Repository"
                placeholder="Optional"
            />
            <InputField 
                name="maven.exclusions"
                label="Exclusions"
                placeholder="Dependencies to exclude (log4j:log4j,junit:junit)"
            />
        </div>
    );

    const librarySource = values.librarySource as LibrarySources;

    const fieldsRenderers: Record<LibrarySources, () => JSX.Element> = {
        [LibrarySources['Upload']]: renderUploadFields,
        [LibrarySources['PyPi']]: renderPyPiFields,
        [LibrarySources['Maven']]: renderMavenFields,
        [LibrarySources['CRAN']]: renderCranFields,
    };

    const disableInstallBtn = useMemo(() => {
        let disabled = false;
        switch(values.librarySource as LibrarySources){
            case(LibrarySources['Upload']): {
                let failedFilesCount = 0;
                filesList.some((file) => {
                    const fileStatus = filesMetaData[file.id].status; 
                    if(fileStatus === 'IN_PROGRESS') {
                        disabled = true;
                        return true;
                    }
                    else if(fileStatus === 'FAILED') {
                        failedFilesCount += 1;
                    }
                });
                
                if(failedFilesCount === filesList.length) {
                    disabled = true;
                }
                break;
            } 
            case LibrarySources['PyPi']:
            case LibrarySources['CRAN']: {
                disabled = !(get(values, values.librarySource + '.package'));
                break;
            }
            case LibrarySources['Maven']: {
                disabled = !(get(values, 'maven.coordinates'));
            }

        }
        return disabled;
    }, [filesList, filesMetaData, values.librarySource, get(values, 'maven.coordinates'), get(values, values.librarySource + '.package')]);

    // const handleBackBtnPress = () => {
    //     console.log(uploadedFiles.length)
    //     if(isEmpty(uploadedFiles)) {
    //         toggleDiscardPrompt(true)
    //     } else if(!showDiscardPrompt){
    //         handleGoBackToLibraries()
    //     }
    // }

    return(
        <>
            <div
                className="librarySource__outer"
            >
                <div
                    className={classNames(
                        'librarySource__upper', 
                        { 'disableSelection': values.librarySource === LibrarySources['Upload'] && !isEmpty(uploadedFiles) }
                    )}
                    onClick={handleShowDiscardPrompt}
                >
                    <SelectField 
                        name="librarySource"
                        options={_librarySourcesOptions}
                        label="Library Source"
                        className="librarySource__dropdown"
                    />
                </div>
                {fieldsRenderers[librarySource]()}
            </div>
            <div
                className="libraries__installationOptions__buttons"
            >
                <button
                    className="btn-md btn-grey"
                    onClick={handleCloseModal}
                    type="button"
                >
                Cancel
                </button>
                <button
                    className="btn-md btn-yellow"
                    disabled={disableInstallBtn}
                    type="submit"
                >
                Install
                </button>

            </div>
        </>
    );
};



export const LibrariesTab: React.FC<{clusterId: string; handleCloseModal: () => any }> = ({ clusterId, handleCloseModal }) => {
    const [librariesList, setLibrariesList] = useState<ClusterLibraryInfo[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedLibraries, setSelectedLibraries] = useState<Record<string, ClusterLibraryInfo>>({});
    const [showInstallOptions, toggleShowInstallOptions] = useState(false);
    const [showDiscardPrompt, toggleDiscardPrompt] = useState(false);

    const librariesToBeShown: ClusterLibraryInfo[] = useMemo(() => {
        return librariesList.slice(currentPage*10 - 10,currentPage*10-1);
        //     .map(row => {
        //     const rowKeys = Object.keys(row) as (keyof ClusterLibraryInfo)[]
        //     const rowInfo: string[] = [];

        //     (rowKeys).forEach((rowKey, index) => {
        //         rowInfo[index] = row[rowKey] || '';
        //     })
        //     return rowInfo
        // })
    }, [librariesList, currentPage]);

    const { start: startLibrariesCall, stop: stopLibrariesCall } = useInterval(() => {
        /* eslint-disable @typescript-eslint/no-use-before-define */
        retrieveLibraries();
    }, 3000);

    const retrieveLibraries = () => {
        NewDatabricksHandlerClass.GetClusterLibrariesList(clusterId, (t) => {
        // NewDatabricksHandlerClass.GetClusterLibrariesList(TEST_CLUSTER_ID, (t) => {
            if(t.find(lib => lib.status === 'INSTALLING')) {
                startLibrariesCall();
            } else {
                stopLibrariesCall();
            }
            setLibrariesList(t);
        });
    };

    useEffect(() => {
        clusterId && retrieveLibraries();
    }, [clusterId]);

    const handleSetSelectedLibraries = (name: string, libraryInfo: ClusterLibraryInfo) => {
        let _selectedLibraries = cloneDeep(selectedLibraries);
        if(has(_selectedLibraries, name)) {
            _selectedLibraries = omit(_selectedLibraries, name);
        } else {
            _selectedLibraries[name] = libraryInfo;
        }
        setSelectedLibraries(_selectedLibraries);
    };

    const renderCheckbox: TableCellRenderer = ({ rowIndex, rowData }) => {
        const name = rowData.name;
        const isDisabled = rowData.status === 'SKIPPED';
        return(
            <div
                className="checkbox__outerContainer"
            >
                <label className={classNames('checkbox__container checkbox__gold', { checkboxfield__disabled: isDisabled })} >
                    <input type="checkbox" checked={has(selectedLibraries, name+rowIndex)} onChange={() => { return; }} value="" />
                    <span  className="checkmark" onClick={() =>  !isDisabled && handleSetSelectedLibraries(name+rowIndex, rowData)} />
                </label>
            </div>
        );
    };

    const [
        isFullySelected, 
        isNotSelected,
        isPartiallySelected
    ] = useMemo(() => {
        const librariesThatCanBeSelected = librariesToBeShown.filter(lib => lib.status !== 'SKIPPED');
        const isFullySelected = Object.values(selectedLibraries).length === librariesThatCanBeSelected.length;
        const isNotSelected =Object.values(selectedLibraries).length === 0;
        const isPartiallySelected = isNotSelected ? false: !isFullySelected;

        return [isFullySelected, isNotSelected, isPartiallySelected];
    }, [librariesToBeShown,  selectedLibraries]);

    
    const handleAllLibrarySelection = (action: boolean) => {
        // const __selectedLibraries = cloneDeep(selectedLibraries);
        const __selectedLibraries: typeof selectedLibraries = {};
        if(action === true) {
            librariesToBeShown.forEach((lib, index) => {
                if(lib.status !== 'SKIPPED') {
                    __selectedLibraries[lib.name+index] = lib;
                }
            });
        }
        
        setSelectedLibraries(__selectedLibraries);
    };

    const renderCheckboxInHeader: TableHeaderRenderer = () => {
        return(
            <div
                className="checkbox__outerContainer"
            >
                <label className={classNames('checkbox__container', { 'checkmark__partialselected': isPartiallySelected })}>
                    <input type="checkbox" checked={isFullySelected} onChange={() => { return; }} value="" />
                    <span  className="checkmark" onClick={() => (isFullySelected || isPartiallySelected) ? handleAllLibrarySelection(false) : handleAllLibrarySelection(true)} />
                </label>
            </div>
        );
    };

    const renderStatus: TableCellRenderer = ({ cellData }) => {
        return(
            <div
                className="libraryStatus__box"
            >
                <div
                    className="libraryStatus__tag"
                >
                    <div style={{ borderColor: COLORS_STATUS_TAG[cellData as ClusterLibraryInfo['status']] }}/>
                    {startCase(lowerCase(cellData))}
                </div>
            </div>
        );
    };

    const handleRowRenderer: TableRowRenderer  = ({ className, index, ...args}) => {
        const rowName = args.rowData.name + index;
        return(
            defaultRowRenderer({ className: classNames(className, { 'clusterLibrariesTable__row__selected':  selectedLibraries[rowName], 'clusterLibrariesTable__row__disabled': args.rowData.status === 'SKIPPED' }), index, ...args})
        );
    };

    const handlePageChange = (currentPage: number) => {
        setCurrentPage(currentPage);
        setSelectedLibraries({});
    };

    

    const handleInstallSuccess = () => {
        toggleShowInstallOptions(false);
        setSelectedLibraries({});
        retrieveLibraries();
    };

    const handleSuccessError = () => {
        errorAlert('Installation Failed');
    };

    const handleInstallFormSubmit = (values: any) => {
        const data: InstallClusterInfo = {
            'cluster_id': clusterId,
            libraries: []
        };
        switch(values.librarySource as LibrarySources) {
            case LibrarySources['Upload']:
                // let data: InstallClusterInfo['libraries'][0] = {};

                data.libraries = values.filesList.map((fileName: string) => ({
                    [values.libraryTypeForUpload]: `dbfs:/users/${Env.uploadLibraryPath}/` + fileName
                }));
                break;
            case LibrarySources['PyPi']:
            case LibrarySources['CRAN']: {
                const _libraryInfo: InstallClusterInfo['libraries'][0] = {
                    [values.librarySource]: {
                        package: get(values, values.librarySource + '.package'),
                    }
                };
                const repo = get(values, values.librarySource + '.repo');
                if(repo){
                    set(_libraryInfo, values.librarySource + '.repo', repo);
                }
                // @ts-ignore
                data.libraries = [_libraryInfo];
                break;
            }
            case LibrarySources['Maven']: {
                const _libraryInfo: InstallClusterInfo['libraries'][0] = {
                    maven: {
                        coordinates: get(values, 'maven.coordinates'),
                    }
                };
                const repo = get(values, values.librarySource + '.repo');
                if(repo){
                    set(_libraryInfo, 'maven.repo', repo);

                }
                const exclusions = get(values, values.librarySource + '.exclusions');
                if(exclusions){
                    set(_libraryInfo, 'maven.exclusions', repo);
                    // _libraryInfo['exclusions'] = exclusions;
                }
                // @ts-ignore
                data.libraries = [_libraryInfo];
                break;
            }
        }
        
        NewDatabricksHandlerClass.InstallLibrariesOnCluster(data, handleInstallSuccess, handleSuccessError);
    };

    const handleUninstallSelectedLibraries = () => {
        const data: InstallClusterInfo = {
            cluster_id: clusterId,
            libraries: []
        };
        Object.values(selectedLibraries).forEach(lib => {
            if(lib.source) {
                const types = {jar: UploadLibraryTypes['Jar'], 'wheel': UploadLibraryTypes['Python Whl'], egg: UploadLibraryTypes['Python Egg']};
            
                // installed using dbfs
                data.libraries.push({
                    [types[lib.type.toLowerCase() as keyof typeof types]]: lib.source
                });
            } else {
                switch(lib.type.toLowerCase()) {
                    case LibrarySources['PyPi']:
                    case LibrarySources['CRAN']: {
                        data.libraries.push({
                            [lib.type.toLowerCase()]: {
                                package: lib.name
                            }
                        });
                        break;
                    }
                    case LibrarySources['Maven']: {
                        data.libraries.push({
                            maven: {
                                coordinates: lib.name
                            }
                        });
                    }
                }
            }

            
        });
        NewDatabricksHandlerClass.UninstallLibrariesOnCluster(data, handleInstallSuccess, handleSuccessError);

    };

    return(
        <>
            <div
                className={classNames('libraries__upperHalf', {active: showInstallOptions})}
            >
                {
                    showInstallOptions ?
                        <>
                            <button
                                className="btn-grey btn-sm btn-goBackWithArrow"
                                onClick={() => toggleShowInstallOptions(false)}
                            >
                                <GoBackArrow />
                            Back
                            </button>
                            <div
                                className="libraries__installationOptions"
                            >
                                <label
                                    className="libraries__installationOptions__title"
                                >
                                Install Library
                                </label>
                                <Form
                                    initialValues={{ librarySource: LibrarySources['Upload'], libraryTypeForUpload: UploadLibraryTypes['Jar']}}
                                    onSubmit={handleInstallFormSubmit}
                                    className="installLibrary__form"
                                >
                                    <InstallLibraryForm 
                                        handleCloseModal={handleCloseModal}
                                        discardPrompt={[showDiscardPrompt, toggleDiscardPrompt]}
                                    />
                                    
                                </Form>
                            </div>
                        </>
                        :
                        <div
                            className="actionBtns__box"
                        >
                            <button
                                className="btn-grey btn-sm"
                                disabled={isNotSelected}
                                onClick={handleUninstallSelectedLibraries}
                            >
                                Uninstall
                            </button>
                            <button
                                className="btn-sm btn-yellow-transparent btn__new__workflow btn__new__action"
                                onClick={() => toggleShowInstallOptions(true)}
                            >
                                <img src="/icons/workflow/add.svg" alt="" />
                                <span>Install New</span>
                            </button>
                        </div>
                }
            </div>
            <ShowWhenTrue show={!showInstallOptions}>
                <AutoSizer disableHeight>
                    {({ width }) => (
                        <Table
                            height={(librariesToBeShown.length + 1)*32}
                            width={width}
                            rowHeight={32}
                            headerHeight={32}
                            rowCount={librariesToBeShown.length}
                            rowGetter={({ index}) => librariesToBeShown[index]}
                            className="clusterLibrariesTable"
                            rowClassName="clusterLibrariesTable__row"
                            rowRenderer={handleRowRenderer}
                            headerClassName="clusterLibrariesTable__header"
                        >
                            <Column 
                                width={30}
                                dataKey=""
                                cellRenderer={renderCheckbox}
                                headerRenderer={renderCheckboxInHeader}
                            />
                            {/* 290(width of other columns) = 30 + 90 + 160 */}
                            <Column 
                                width={(width - 290)/2}
                                dataKey="name"
                                label="Name"
                            />
                            <Column 
                                width={90}
                                dataKey="type"
                                label="Type"
                            />
                            <Column 
                                width={160}
                                dataKey="status"
                                cellRenderer={renderStatus}
                                label="Status"
                            />
                            <Column 
                                width={(width - 290)/2}
                                dataKey="source"
                                label="Source"
                            />
                        </Table>
                    )}
                </AutoSizer>
                <Paginate 
                    startPage={1}
                    totalCount={librariesList.length}
                    itemsPerPage={10}
                    handlePageChange={handlePageChange}
                />
            </ShowWhenTrue>
        </>
    );
};