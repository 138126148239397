import { Flex } from "@components/ui/Flex";
import { Text } from "@components/ui/Text";
import React from "react";
import { Icons } from "./Icons";
import { FileExplorerProps } from "./types";

type Props = {
	onDoubleClick: () => void;
} & Pick<FileExplorerProps, "view">;

export const BackFolder = ({ onDoubleClick, view }: Props) => {
	return (
		<tr onDoubleClick={onDoubleClick}>
			<td>
				<Flex>
					<Icons.backFolder />
					<Text pl="10px">..</Text>
				</Flex>
			</td>
			{view === "Table" && (
				<>
					<td></td>
					<td></td>
					<td></td>
				</>
			)}
		</tr>
	);
};
