import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { AzureIcon, PlushIcon, SearchIcon } from './icons';
import { getCluster, getClusterMode, getAccessNode, getUsersList, getDataBricksVersionList, getNodeTypeList, toggleCreatedByMe } from '../../store/cluster/actions';
import { Cluster, ClusterDetail } from '../../api/databricks-handler/types';
import ClusterCard from './ClusterCard';
import { RootState } from '../../store/types';
import { ClusterModal } from './ClusterModal';
import { UserDetails } from '../../components/header-sidebar/user-details';
import { DeleteClusterModal } from './DeleteClusterModal';
import { RestartClusterModal } from './RestartClusterModal';
import { ClouderaProfileModal } from './forms/clouderaProfile';
import { ProfileInfo } from '@api/workflow-handler';
import { WorkspaceData } from './types';

const MemoClusterModal = React.memo(ClusterModal);

const subtitleTxtRef:any ={
    "all":"Clusters / Profiles",
    "databricks": "Clusters",
    "cloudera": "Profiles"
}

// const TIMER_GET_CLUSTERS = 15000;
export interface ClusterPageProps {   
    dispatchMC: any;
    enabledWorkSpace: WorkspaceData
}
const ClusterPage: React.FC<ClusterPageProps> = ({dispatchMC,enabledWorkSpace}) => {
    const dispatch = useDispatch();

    const [searchString, setSearchString] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [showDeleteModal, setDeleteModal] = useState(false);
    const [showRestartModal, setRestartModal] = useState(false);
    const [currentClusterDetail, setCurrentClusterDetail] = useState<ClusterDetail|null>(null);
    const [profileInfoForEdit, setProfileInfoForEdit] = useState<any>(null);

    const clusters = useSelector((store: RootState) => store.ClusterReducer.clusters);
    const createdByMe = useSelector((store: RootState) => store.ClusterReducer.createdByMe);
    const currentUser = useSelector((store: RootState) => store.AccountReducer.activeUserInfo);
    
    const filteredClusters = useMemo( () => {
        const createdByClusters = createdByMe  ? clusters.filter( (cluster: Cluster) => cluster.createdBy === currentUser.email) : clusters;
        return createdByClusters.filter( (cluster: Cluster) => cluster.clusterName.toLowerCase().indexOf(searchString.toLowerCase()) >= 0 );
    }, [createdByMe, clusters, searchString, currentUser] );

    const dispatchToggleCreatedByMe = () => {
        dispatch(toggleCreatedByMe());
    };

    const dispatchNodeTypeList = () => {
        dispatch(getNodeTypeList());
    };

    const dispatchClusterMode = () => {
        dispatch(getClusterMode());
    };

    const dispatchAccessNode = () => {
        dispatch(getAccessNode());
    };

    const dispatchUsersList = () => {
        dispatch(getUsersList());
    };

    const dispatchDataBricksVersionList = () => {
        dispatch(getDataBricksVersionList());
    };
    
    useEffect(() => {
        dispatchNodeTypeList();
        dispatchClusterMode();
        dispatchAccessNode();
        dispatchUsersList();
        dispatchDataBricksVersionList();
    }, []);
    
    const searchStringHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(e.target.value);
    };

    const showModalWindow = () => {
        setShowModal(true);
        setTimeout(() => {
            dispatchMC({ type: "OPEN", value: 5 });
        });
    };

    const showModalWindow_NewCluster = () => {
        if(_enabledWorkspaceType ==="databricks"){
           setCurrentClusterDetail(null)
        }
        setShowModal(true);
        setTimeout(() => {
            dispatchMC({ type: "OPEN", value: 5 });
        });
    };


    const showProfileModalWindow = () => {
        setShowProfileModal(true);
    };

    const closeModalWindow = useCallback(
        () => {
            setShowModal(false);
            setCurrentClusterDetail(null);
            dispatchMC({ type: "OPEN", value: 6 });
        },
        [showModal, currentClusterDetail],
    );

    const closeProfileModalWindow = useCallback(
        () => {
            setShowProfileModal(false);
            setCurrentClusterDetail(null);
        },
        [showModal, currentClusterDetail],
    );

    const handleGetClusterSucess = (clusterDetail: ClusterDetail) => {
        setCurrentClusterDetail(clusterDetail);
        showModalWindow();
    };

    const editCluster = (cluster: Cluster) => {
        if(cluster.workspaceType === "databricks"){
            dispatch(getCluster(cluster.clusterId, handleGetClusterSucess));
        }else{
            const currentProfile = { id: cluster.clusterId, name: '', command: cluster?.command||"", shareProfile: true, active: false,clusterName:cluster.clusterName }
            setProfileInfoForEdit(currentProfile);
            setShowProfileModal(true)
        }
        
    };

    const closeDeleteModal = () => {
        setDeleteModal(false);
    };

    const closeRestartModal = () => {
        setRestartModal(false);
    }
    const restartCluster = (clusterId: string, clusterName: string) => {
        // @ts-ignore
        setCurrentClusterDetail({ clusterId, clusterName });
        setRestartModal(true);
    }

    const deleteCluster = (clusterId: string, clusterName: string) => {
        // @ts-ignore
        setCurrentClusterDetail({ clusterId, clusterName });
        setDeleteModal(true);
    };
    const  _enabledWorkspaceType = enabledWorkSpace?.workspaceType;
    const  isClusterBtnDisabled = enabledWorkSpace?.state!=="active";
    const title = _enabledWorkspaceType?.substring(0,1).toUpperCase() + _enabledWorkspaceType?.substring(1);
    
    const btnTxt = _enabledWorkspaceType === "databricks" ?"New Cluster" :"New Profile";
    const subTitleTxt = _enabledWorkspaceType === "databricks" ?"Clusters" :"Profiles";

    return (
        <React.Fragment>
            <div className="clusterPage innerCanvas">
                <div className="pageHeading">
                    <div className="heading-left ">
                        <AzureIcon/>
                        <span> {title} {subTitleTxt} Settings</span>
                    </div>
                    <UserDetails/>
                </div>

                <div className="secoundHeading">
                    <div>
                        <Row>
                            <Col span={12}>
                                <div className="secoundleft">
                                    <div className="secoundtext">
                                        <h5>{subTitleTxt}</h5>
                                        {currentUser.isAdmin && !isClusterBtnDisabled &&<button className="newCluster"  onClick={_enabledWorkspaceType ==="databricks"? showModalWindow_NewCluster : showProfileModalWindow}>
                                                    <PlushIcon/> <span>{btnTxt}</span>
                                                </button>
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="secoundright">
                                    <div className="btn-Created">
                                        <button 
                                            {...(!createdByMe  && { className: 'selected'} ) }
                                            {...(createdByMe  && {onClick: () => {dispatchToggleCreatedByMe();} } ) }>
                                            <span>All</span>
                                        </button>
                                        <button 
                                            {...(createdByMe  && { className: 'selected'})}
                                            {...(!createdByMe  && {onClick: () => {dispatchToggleCreatedByMe();} } ) }>
                                            <span>Created by me</span>
                                        </button>
                                    </div>
                                    <div className="filterinput">
                                        <SearchIcon/>
                                        <input type="text" placeholder="Filter" onChange={searchStringHandler}/>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="componentdesign">
                    <div className="card-wrapper">
                        {
                            filteredClusters.length > 0 && filteredClusters.map((cluster: Cluster) => (
                                <ClusterCard 
                                    key={`${cluster.clusterId}-${cluster.defaultCluster}-${cluster.state}`} 
                                    cluster={cluster} 
                                    currentUser={currentUser}
                                    editCluster={editCluster}
                                    restartCluster={restartCluster}
                                    deleteCluster={deleteCluster}
                                />
                            ))
                        }
                    </div>
                    {showModal && <MemoClusterModal 
                        key='cluster-modal'
                        showModal={showModal} 
                        toggleModal={closeModalWindow}
                        {...(currentClusterDetail && {currentClusterDetail:currentClusterDetail})}
                        currentUser={currentUser}
                        enabledWorkspace={enabledWorkSpace}
                    />}
                    {showProfileModal && <ClouderaProfileModal
                        showModal={showProfileModal}
                        profileInfoForEdit={profileInfoForEdit}
                        toggleClose={closeProfileModalWindow}
                        enableWorkSpaceId={enabledWorkSpace?.workspaceId||""}
                    />}
                    <RestartClusterModal 
                        showModal={showRestartModal}
                        toggleModal={closeRestartModal}
                        currentClusterDetail={currentClusterDetail}
                    />
                    <DeleteClusterModal 
                        showModal={showDeleteModal}
                        toggleModal={closeDeleteModal}
                        currentClusterDetail={currentClusterDetail}
                    />
                </div>
            </div>
            
        </React.Fragment>
    );
};

export default ClusterPage;