import React, { useState } from "react";
import classNames from "classnames";
import {
	CloseGraphIcon,
	GoToFullScreenIcon,
	RestoreIcon,
	SettingsIcon,
} from "@pages/workflow-analytics-page/assets/icons";
import { Plot } from "@api/visualization-handler";
import { TooltipTop } from "@components/tooltips";
import GraphCard from "./graph";
import "../styles.scss";
import { Text } from "@components/ui/Text";

const PlotLayout: React.FC<{
	plotData: Plot[];
	onSettingsClick: (item: Plot) => void;
	onDeleteClick: (item: Plot) => void;
}> = ({ plotData, onSettingsClick, onDeleteClick }) => {
	const [isFullScreen, setIsFullScreen] = useState(false);
	const [selectedPlotFOrFullScreen, setSelectedPlotFOrFullScreen] = useState<
		Plot
	>({} as Plot);
	const handleSettings = (item: Plot) => {
		onSettingsClick(item);
	};

	const handleRemovePlot = (item: Plot) => {
		onDeleteClick(item);
	};

	const handleFullScreenToggle = (item: Plot) => {
		setSelectedPlotFOrFullScreen(item);
		setIsFullScreen(!isFullScreen);
	};

	return (
		<div className="dataexplorer__container">
			<div
				className="react-grid-layout layout"
				style={{ height: "80vh" }}
			>
				{plotData.map((item: Plot, index: number) => {
					const plotTitle = item.config.plot_type.toUpperCase();
					return (
						<div
							key={item.config.id}
							className={classNames("grid-graph", {
								"full-screen": isFullScreen,
							})}
						>
							<div className={classNames("innerPlotBox", {})}>
								<div className="workflowCanvas__header">
									<>
										<button>
											<TooltipTop overlay={""}>
												<span
													className={classNames(
														"graphCardIcon skeletonIcon-Graph",
														{
															disabled: false,
														}
													)}
													onClick={() =>
														handleSettings(item)
													}
												>
													<SettingsIcon />
												</span>
											</TooltipTop>
										</button>
										<Text
											pl="1rem"
											fontSize="1rem"
											color="white"
										>
											{plotTitle}
										</Text>
										<div className="separator" />
									</>

									<button
										className="graphCardIcon"
										onClick={() =>
											handleFullScreenToggle(item)
										}
									>
										{isFullScreen ? (
											<RestoreIcon />
										) : (
											<GoToFullScreenIcon />
										)}
									</button>
									{
										<button
											className="graphCardIcon"
											onClick={() =>
												handleRemovePlot(item)
											}
										>
											<CloseGraphIcon />
										</button>
									}
								</div>
								<GraphCard
									plotData={
										isFullScreen
											? selectedPlotFOrFullScreen
											: item
									}
								/>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default PlotLayout;
