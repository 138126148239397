/* eslint-disable react/jsx-key */

import React, { useRef, memo, useEffect } from 'react';
import { useTable, useSortBy } from 'react-table';
import { ComponentAnalyticsProps } from './graphs';
import classNames from 'classnames';

export interface TableAnalytics extends ComponentAnalyticsProps{
    headers: {Header: string; accessor: (arg0: any) => any}[];
    data: Record<string, string | number | boolean>[];
}


const WrappedTable: React.FC<TableAnalytics> = ({ 
    // graphData, 
    headers,
    data,
    analyticsDimensions, 
    show,
    className
}) => {
    // const getColumnsAndData = useMemo(() => {
    //     if(graphData){
    //         const columns = graphData.schema.fields.map(field => ({Header: field.name, accessor: field.name }));
    //         return ({ columns, data: graphData.data });
    //     } else {
    //         return ({ columns: [], data: []});
    //     }
        
    // }, [graphData]);



    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns: headers, data }, useSortBy );
    
    const ref = useRef<HTMLTableElement>(null);

    useEffect(() => {
        // to prevent scrolling of canvas
        ref.current?.addEventListener('wheel', (event) => event.stopPropagation());
        return () => ref.current?.removeEventListener('wheel', (event) => event.stopPropagation());
    }, [ref.current]);

    // console.log(headers, data)
    return(
        <table 
            {...getTableProps()} 
            style={analyticsDimensions}
            ref={ref}
            className={classNames('analyticsTable', {'hide': !show}, className && {[className]: !!(className)})}
        >
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()} className="analyticsTable__header">
                        {headerGroup.headers.map(column => (
                            // Add the sorting props to control sorting. For this example
                            // we can add them into the header props
                            <th {...column.getHeaderProps((column as any).getSortByToggleProps())}>
                                <span>
                                    {column.render('Header')}
                        &nbsp;
                                    {/* Add a sort direction indicator */}
                                    {(column as any).isSorted
                                        ? (column as any).isSortedDesc
                                            ? '\u25BC'
                                            : '\u25B2'
                                        : ''}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(
                    (row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} className="analyticsTable__values">
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    );
                                })}
                            </tr>
                        );}
                )}
            </tbody>
        </table>
    );
};

export const Table = memo(WrappedTable);