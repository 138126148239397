import React from 'react'
import RowRender from '../components/rowRender';
import { RobotIcon } from '../components/Icons';
import ButtonGroup, { ButtonList } from '../components/buttonGroup';
import { useGenAIContext } from '../useGenAIContext';
import { KEY_VALUE, NEXT_INPUT_OPTIONS } from '../types';
import { _selectoptionType } from '@components/form/select-field';
import { isEmpty } from 'lodash';
import { RootState } from '@store/types';
import { useSelector } from 'react-redux';
import TableContainerGenAI from '../container/TableContainer';

const RenderButton: React.FC<{
  options: NEXT_INPUT_OPTIONS,
  message?: string
  showFeedback: any
  query_uuid: string,
  isFeedbackSubmitted: boolean
}> = ({
  options,
  message,
  showFeedback,
  query_uuid,
  isFeedbackSubmitted
}) => {
    const { runQuery, addContent, sqlQuery } = useGenAIContext();
    const { activeUserInfo: { username } } = useSelector((store: RootState) => store.AccountReducer);
    const buttonList: ButtonList[] = (options.choices as KEY_VALUE).map((option: KEY_VALUE) => {
      const key = Object.keys(option);
      return {
        title: option[key[0]] as string,
        value: key[0]
      }
    })

    return (
      <>
        <RowRender
          text={message || "What would you like to do next?"}
          icon={<RobotIcon />}
          direction='ai'
          table={!isEmpty(sqlQuery) ? <TableContainerGenAI /> : null}
          date={new Date()}
          isFeedbackSubmitted={isFeedbackSubmitted}
          noFeedback={false}
          showFeedback={showFeedback}
          query_uuid={query_uuid}
        />

        <ButtonGroup
          buttonList={buttonList}
          onClick={function (buttonValue: string) {
            const text = (options.choices as KEY_VALUE).filter((option: KEY_VALUE) => !isEmpty(option[buttonValue]));
            addContent(text[0][buttonValue] as string, 'human', query_uuid, true);
            runQuery('', buttonValue, [], "", "", username);
          }}
        />
      </>
    )
  }

export default RenderButton