
// @ts-ignore
const L = window.L;


class RasterPlotService {
    map: null | any;
    drawControl: null | any;
    rasterRef: Record<string, any>;
    fitToViewRef: Record<string, boolean>;
    // tiff: null | any;
    // image: null | any;
    // drawingPoints: null;
    constructor() {
        this.map = null;
        this.drawControl = null;
        this.rasterRef = {};
        this.fitToViewRef= {}
        // this.image = null;
        // this.raster = null;
        // this.drawingPoints = null;
    }

    initialize({ mapId = 'map' } = {}) {
        // let self = this;
        // store = window.store;
        try {

        // add map
        this.map = L.map(mapId);
        this.map.setView([0, 0], 2);
        this.map.options.minZoom = 2;

        const resizeObserver = new ResizeObserver(() => {
          this.map.invalidateSize();
        });

        this.map.resizeObserver = resizeObserver;
        
        resizeObserver.observe(document.getElementById(mapId) as HTMLElement);
    
        // const drawOptions = {
        //   draw: false
        // };
        // this.drawControl = new L.Control.Draw(drawOptions);
        // this.map.addControl(this.drawControl);
    
        // add osm basemap
        const openStreetMapMapnik = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
          attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        });
        openStreetMapMapnik.addTo(this.map);
    
      } catch(e) {
        /* eslint-disable-next-line no-console */
        console.log(e)
      }
    }

      addRaster(rasterId: string, rasterPlotUrl: string, layer: any) {
        if (this.rasterRef[rasterId]) this.map.removeLayer(this.rasterRef[rasterId]);
        layer.addTo(this.map);
    
        const layerBounds = layer.getBounds();
        if(!this.fitToViewRef[rasterPlotUrl]) {
          // Fit layer to the view only once per URL - only on mount
          this.map.fitBounds(layerBounds);
          this.fitToViewRef[rasterPlotUrl] = true;
        }
    
        this.rasterRef[rasterId] = layer;
      }
    
      refresh() {
        this.map && this.map.invalidateSize()
      }
    
      removeRaster(rasterId: string) {
        this.map.removeLayer(this.rasterRef[rasterId]);
        this.map.resizeObserver.disconnect();
      }

      unSubscribe() {
        this.map?.resizeObserver?.disconnect();
      }
    
}

export default RasterPlotService;