import React, { useState } from "react";
import classnames from "classnames";
import styles from "./styles.module.scss";
import { WorkspaceData } from "./types";
import { InPageSpinner } from "@components/spinners/in-page-spinner";
import { errorAlert, successAlert } from "@components/toastify/notify-toast";
import { Modal } from "@components/modals";
import { WorkspaceForm } from "./forms/WorkspaceForm";
import { WorkSpaceHandler } from "@api/workSpace-handler";

type ModalProps = {
	showModal: boolean;
	toggleClose: () => void;
	getWorkspaceData: (item: any, delay: boolean) => void;
	workspaceData: WorkspaceData | null;
};

const WorkSpaceModal: React.FC<ModalProps> = ({
	showModal,
	toggleClose,
	getWorkspaceData,
	workspaceData,
}) => {
	const [showLoader, toggleLoader] = useState(false);

	const onSubmit = (values: any) => {
		const configType = values.configType;
		let obj: any = {
			workspaceType: values.workspaceType,
			workspaceName: values.workspaceName,
			workspaceUrl: values.workspaceUrl,
		};
		if (values.workspaceType === "databricks") {
			obj ={...obj,cloudProvider: values.cloudProvider,expiryTimeType:values.expiryTimeType};
			const workspaceUrl = values.workspaceUrl;
			const cloudProvider = values.cloudProvider;
			let isValidUrl = false;
			if(cloudProvider === "Azure"){
				isValidUrl = workspaceUrl.includes("azuredatabricks");
			}else{
				isValidUrl = workspaceUrl.includes("cloud.databricks");
			}
			if(!isValidUrl){
				errorAlert("workspace url and cloud provider type doesn't match")
				return;
			}
			if (configType === "PAT") {
				obj = {
					...obj,
					patToken: values.patToken,
					expiryTime: values.expiryTime,
					npipWorkSpace: values.npipWorkSpace,
					useDockerImage:values.useDockerImage
				};
			} else {
				obj = {
					...obj,
					npipWorkSpace: values.npipWorkSpace,
					servicePrincipleInfo: {
						clientId: values.clientId,
						clientSecret: values.clientSecret,
						tenantId: values.tenantId,
					},
				};
			}
		} else {
			obj = {
				...obj,
				patToken: values.patToken,
				awsBucketUrl: values.awsBucketUrl,
				username: values.username,
				accessKey: values?.accessKey,
				secretKey: values?.secretKey,
				region: values?.region,
				bucketName: values?.bucketName,
			}
		}

		toggleLoader(true);
		if (workspaceData) {
			obj = { ...obj, workspaceId: workspaceData.workspaceId };
			WorkSpaceHandler.UpdateWorkSpace(
				obj,
				(res) => {
					getWorkspaceData(null, true);
					toggleLoader(false);
					toggleClose();
					successAlert(res.message);
				},
				(e: any) => {
					errorAlert(e.data.message);
					toggleLoader(false);
				}
			);
		} else {
			WorkSpaceHandler.CreateNewWorkSpace(
				obj,
				(res) => {
					getWorkspaceData(null, true);
					toggleLoader(false);
					toggleClose();
					successAlert(res.message);
				},
				(e: any) => {
					errorAlert(e.data.message);
					toggleLoader(false);
				}
			);
		}
	};

	return (
		<>
			<Modal
				isOpen={showModal}
				toggleClose={toggleClose}
				title={workspaceData ? "Edit Workspace" : "New Workspace"}
				subtitle={
					<div className={styles["workspace-modal-subtitle"]}>
						{workspaceData?.workspaceName || ""}
					</div>
				}
				className={classnames("mf_model",
					styles["addEditWorkspaceModal_"]
				)}
				shouldCloseOnOverlayClick={false}
			>
				<div
					className={styles["addEditWorkspaceModal__innerContainer"]}
				>
					<WorkspaceForm
						type={!workspaceData ? "ADD" : "UPDATE"}
						onSubmit={onSubmit}
						initialValues={workspaceData}
						submitButtons={
							<div
								className={
									styles["addEditWorkspaceModal__actionBtns"]
								}
							>
								<div>
									<button
										className={classnames(
											"btn btn-lg btn-yellow",
											styles["workspace-edit-btn"]
										)}
										type="submit"
										disabled={showLoader}
									>
										{showLoader ? (
											<InPageSpinner
												size="XSmall"
												color="black"
												className={styles["auto"]}
											/>
										) : !workspaceData ? (
											"Create"
										) : (
											"Update"
										)}
									</button>
									<button
										className={classnames(
											"btn btn-lg",
											styles["workspace-cancel-btn"]
										)}
										type="button"
										onClick={toggleClose}
									>
										Cancel
									</button>
								</div>
							</div>
						}
					></WorkspaceForm>
				</div>
			</Modal>
		</>
	);
};

export { WorkSpaceModal };
