import React from "react";
import { useTimeAgo } from "rooks";
import styles from "./styles.module.scss";

export const RefreshedAgo = ({ time }: { time: Date }) => {
	const refreshTimeAgo = useTimeAgo(time);
	return (
		<span className={styles.timeAgo}>
			Refreshed <br />
			{refreshTimeAgo}
		</span>
	);
};
