/* eslint-disable @typescript-eslint/no-use-before-define */

import { Modal } from '../../../components/modals';
import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import Form, { InputField, SelectField, TagField } from '../../../components/form';
import { object, string, InferType, boolean, StringSchema } from 'yup';
import { WorkflowHandler, GetProfilesResponse, RunWorkflowData } from '../../../api/workflow-handler';
import { useFormikContext } from 'formik';
import classNames from 'classnames';
import { _selectoptionType } from '../../../components/form/select-field';
import { useDidMount } from 'rooks';
import isEmpty from 'lodash/isEmpty';
import { RadioField } from '../../../components/form/radio-field';
import { ShowWhenTrue } from '../../../helpers';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/types';
import { clearWorkflowJobLogs, clearWorkflowPreviewData, toggleModal, updateWorkflowPreviewStatus } from '../../../store/workflow';
import { InPageSpinner } from '../../../components/spinners/in-page-spinner';
import _, { isString } from 'lodash';
import { FieldSchemaValidator, FieldSchemaCreator } from '../../../components/formcreators/schema-creator';
import { BaseFieldType } from '../../../components/formcreators/types';
import { ToggleField } from '../../../components/form/toggle-field';
import moment from 'moment-mini';
import { AnalyticsHandler, SessionInfo, SubmitStatementData, CreateSessionData, StatementInfo } from '../../../api/analytics-handler';
import { errorAlert, infoAlert } from '../../../components/toastify/notify-toast';
import { DynamicKeyValueFieldGenerator } from '../../../components/form/dynamic-field-generator';
import { Env, clientMode } from '../../../constants/settings';
import { convertWorkflowDataForExecution, addRunTillHereIdIfExists } from '../utils';
import { useGetActiveTabInfo } from '../../../utils';
import { WorkflowCanvas } from '../../../components/workflow-canvas';
import { workflowRunData } from '..';

export interface RunPreviewWorkflowModalProps {
    actionType: 'run' | 'preview' | 'visualize';
    handleSaveWorkflow?: () => any;
    handleSessionCreateSuccess?: (sessionId: string, isStreaming: boolean) => any;
    getRecentJobs?: () => void;
}

const runWorkflowValidationSchema = object().shape({
    deployMode: string(),
    files: string(),
    jarFiles: string(),
    dynamicAllocation: boolean(),
    initialExecutors: string(),
    minExecutors: string(),
    maxExecutors: string(),
    driverMemory: string(),
    executorMemory: string(),
    driverCores: string(),
    executorCores: string(),
    noOfExecutors: string(),
    showAdvancedMode: boolean(),
    additionalConfig: object(),
    streamingMode: boolean(),
    commandLineArgs: string()
});




export type RunWorkflowSchemaType = InferType<typeof runWorkflowValidationSchema> & { [key: string]: any }

// export const INITIAL_COMMAND = 'spark2-submit --master';
const Client: any = clientMode;
export const INITIAL_COMMAND =  Client[Env.deepiq_client] ;



const dynamicAllocationFieldsOnTrue = [
    { label: 'Initial Executors', name: 'initialExecutors' },
    { label: 'Minimum Executors', name: 'minExecutors' },
    { label: 'Maximum Executors', name: 'maxExecutors' },
];
const dynamicAllocationFieldsOnFalse = [
    { label: 'Driver Memory', name: 'driverMemory' },
    { label: 'Executor Memory', name: 'executorMemory' },
    { label: 'Driver Cores', name: 'driverCores' },
    { label: 'Executor Cores', name: 'executorCores' },
    { label: 'Number of Executors', name: 'noOfExecutors' },
];

export type runWorkflowValidationSchema = InferType<typeof runWorkflowValidationSchema> & { [key: string]: any }

export const getRunWorkflowFormInitialValues = (): RunWorkflowSchemaType => ({
    deployMode: Env.disableLocalMode ?  'client': 'local',
    dynamicAllocation: false,
    files: '',
    jarFiles: '',
    initialExecutors: '1',
    minExecutors: '1',
    maxExecutors: '10',
    driverMemory: '',
    executorMemory: '',
    driverCores: '',
    executorCores: '',
    noOfExecutors: '',
    showAdvancedMode: false,
    additionalConfig: {},
    activeProfile: -1,
    streamingMode: false,
    commandLineArgs: ''
});


export const RunPreviewWorkflowModal: React.FC<RunPreviewWorkflowModalProps> = ({ actionType = 'preview', handleSaveWorkflow, handleSessionCreateSuccess, getRecentJobs }) => {
    const showModal = useSelector((store: RootState) => store.WorkflowReducer.showModal.runWorkflow);
    const selectedWorkflowInfo = useSelector((store: RootState) => store.WorkflowReducer.singleItemInfoForModals);
    const dispatch = useDispatch();
    const [showSpinner, toggleSpinner] = useState(false);
    const showModalRef = useRef(showModal);
    const activeTabInfo = useGetActiveTabInfo('workflowEditor');
    const { activeExecutionEnv } = useSelector((store: RootState) => store.CommonReducer);
    const { activeUserInfo } = useSelector((store: RootState) => store.AccountReducer);

    
    const runWorkflowData = useMemo(() => {
        let workflowData: workflowRunData = { workflow_config: {}, nodes: [], links: []};
        if(showModal) {
            workflowData = convertWorkflowDataForExecution(WorkflowCanvas.model, false, activeExecutionEnv, {userName: activeUserInfo.name, workflowName: activeTabInfo?.name || ''  }, activeTabInfo?.config);
            if(actionType !== 'visualize') {
                workflowData.nodes = dispatch(addRunTillHereIdIfExists(workflowData.nodes)) as any;
            }
        }   
        return workflowData;
    }, [showModal, activeExecutionEnv, activeUserInfo, activeTabInfo]);

    useEffect(() => {
        showModalRef.current = showModal;
    }, [showModal]);

    function toggleClose() {
        dispatch(toggleModal('runWorkflow', false));
    }

    const onActionSuccess = () => {
        if(handleSaveWorkflow && !activeTabInfo?.saved) handleSaveWorkflow();
        getRecentJobs && getRecentJobs();
        activeTabInfo?.id && dispatch(clearWorkflowJobLogs(activeTabInfo.id));
        toggleSpinner(false);
        toggleClose();
        infoAlert('Workflow execution in progress');
    };


    const handleSubmitStatementSuccess = (response: StatementInfo) => {
        WorkflowHandler.GetPreviewStatusPromise(response.sessionId)
            .then(statusResponse => {
                activeTabInfo && 
                    dispatch(updateWorkflowPreviewStatus({ componentsInfo: statusResponse.data, statementId: response.jobId, canvasTabId: activeTabInfo.id }));
            });
        onActionSuccess();
    };

    function handleRunWorkflowSubmitSuccess() {
        onActionSuccess();
        dispatch(clearWorkflowPreviewData());
    }

    function handleRunOrPreviewWorkflowFailure(response: any) {
        if(isString(response.data)) {
            errorAlert(response.data);
        } else if(isString(response.data.message)) {
            errorAlert(response.data.message);
        }
        toggleSpinner(false);
    }

    useEffect(() => {
        // set the spinner to false when modal closes
        if (!showModal) toggleSpinner(false);
    }, [showModal]);



    const handleSessionStartSuccess = (response: SessionInfo) => {
        const data: SubmitStatementData = {
            sessionId: response.sessionId,
            payload: runWorkflowData,
            env: activeExecutionEnv
        };
        if (showModalRef.current) {
            if(actionType === 'preview') {
                AnalyticsHandler.SubmitStatement(data, handleSubmitStatementSuccess, handleRunOrPreviewWorkflowFailure);
            } else {
                handleSessionCreateSuccess && handleSessionCreateSuccess(response.sessionId, !!(response.isStreamingWorkflow));
            }
        } else {
            // if the modal is closed before executing the statement, session is killed
            AnalyticsHandler.KillSession(response.sessionId);
        }
    };

    function handleRunWorkflowSubmit(values: runWorkflowValidationSchema) {
        toggleSpinner(true);
        const files = _.map(values.files.split(','), (val) => _.trim(val));
        const postData: RunWorkflowData = {
            name: selectedWorkflowInfo.name + ' - ' + moment().toString(),
            jars: values.jarFiles.split(','),
            payload: runWorkflowData,
            type: actionType === 'run' ? 'run' : 'preview',
            sparkVersion: '2.x',
            deployMode: values.deployMode,
            workflowId: selectedWorkflowInfo.id,
            commandLineArgs: values.commandLineArgs.length > 0 ? values.commandLineArgs.split(','): [],
            files
        };
        if (values.showAdvancedMode) {
            if (values.dynamicAllocation) {
                postData.advanceOptions = {
                    initialExecutors: parseInt(values.initialExecutors),
                    minExecutors: parseInt(values.minExecutors),
                    maxExecutors: parseInt(values.maxExecutors),
                };
                // remove null values
                Object.keys(postData.advanceOptions).forEach((key: any) => {
                    if(!postData.advanceOptions?.[key as keyof RunWorkflowData['advanceOptions']]){
                        postData.advanceOptions = _.omit(postData.advanceOptions, key);
                    }
                });
            } else {
                postData.advanceOptions = {
                    driverCores: parseInt(values.driverCores),
                    driverMemory: values.driverMemory,
                    executorMemory: values.executorMemory,
                    executorCores: parseInt(values.executorCores),
                    noOfExecutors: parseInt(values.noOfExecutors)
                };
            }
            postData.advanceOptions.dynamicAllocation = !!(values.dynamicAllocation);
        }
        if (!isEmpty(values.additionalConfig)) {
            postData.additionalConfig = values.additionalConfig;
        }
        // if (Env.enableStreamingMode) {
        //     postData.streamingMode = values.streamingMode;
        // } else {
        //     postData.isStreamingWorkflow = values.streamingMode;
        // }


        if (actionType === 'preview' || actionType === 'visualize') {
            // Refactor to use sessionCreator in visualizations page to start session instead of calling here directly
            const previewData: CreateSessionData = { ..._.omit(postData, 'payload'), env: activeExecutionEnv };
            AnalyticsHandler.StartSession(previewData, handleSessionStartSuccess, handleRunOrPreviewWorkflowFailure);
        } else {
            WorkflowHandler.RunWorkflow(activeExecutionEnv, postData, handleRunWorkflowSubmitSuccess, handleRunOrPreviewWorkflowFailure);
        }
    }

    const getModalTitle = useCallback(() => {
        return (
            <>
                <img className="previewIcon" src="/icons/workflow/preview.svg" alt="" />
                {_.capitalize(actionType)} Workflow
            </>
        );
    }, [actionType]);

    return (
        <Modal
            isOpen={showModal}
            toggleClose={toggleClose}
            className="runWorkflowModal__container"
            title={getModalTitle()}
            subtitle={selectedWorkflowInfo.name}
            shouldCloseOnOverlayClick={false}
            showCloseMark
        >
            <div className="steps__progress">
                <span className={classNames('progress__bar', { 'progress__step3--slow': showSpinner })} />
                <div className="step1 " />
                <div className="step3" />
            </div>
            <RunWorkflowForm
                initialValues={getRunWorkflowFormInitialValues()}
                onSubmit={handleRunWorkflowSubmit}
                toggleClose={toggleClose}
                showSpinner={showSpinner}
                submitText={`${_.capitalize(actionType)}`}
            />

        </Modal>
    );
};

interface RunWorkflowForm {
    onSubmit: (arg0: RunWorkflowSchemaType) => void;
    toggleClose: () => void;
    initialValues: RunWorkflowSchemaType & { name?: string; activeProfile?: string };
    submitText?: string;
    showSpinner: boolean;
    profileTab?: boolean;
    showCommandFields?: boolean;
}

export const RunWorkflowForm: React.FC<RunWorkflowForm> =
    ({
        initialValues,
        onSubmit,
        toggleClose,
        children,
        showSpinner,
        submitText = 'Run Workflow',
        profileTab = false,
        showCommandFields = false
    }) => {
        const [additionalInitialValues, setAdditionalInitialValues] = useState({});
        return (
            <Form
                initialValues={{ ...initialValues, ...additionalInitialValues }}
                validationSchema={runWorkflowValidationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ _formikprops }: any) => (
                    <>
                        {showSpinner ?
                            (<InPageSpinner />)
                            :
                            <>
                                <RunWorkflowFormComponents showCommandFields={showCommandFields} profileTab={profileTab} setAdditionalInitialValues={setAdditionalInitialValues} />
                                {typeof (children) === 'function' ? children({ _formikprops }) : children}
                            </>
                        }
                        <div className="modalBtns__box">
                            <button
                                className="btn-md btn-yellow"
                                type="submit"
                                disabled={showSpinner}
                                id='wk_run_preview_btn'
                            >
                                {submitText}
                            </button>
                            <button
                                className="btn-md btn-cancel"
                                type="button"
                                onClick={toggleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </>)}
            </Form>
        );
    };

const nameField: BaseFieldType = { key: 'name', type: 'input', defaultValue: '', ace: false, templateOptions: { label: 'Name', required: true, options: [] }, fields: [] };
const schemaForNameField = FieldSchemaCreator(nameField.templateOptions) as StringSchema;

const RunWorkflowFormComponents: React.FC<{ profileTab: boolean; setAdditionalInitialValues: any; showCommandFields?: boolean }> = ({ profileTab, setAdditionalInitialValues, showCommandFields = false }) => {
    // Components are separated to access the formik context
    const { values, setFieldValue } = useFormikContext<RunWorkflowSchemaType>();
    const [jarFilesList, setJarFilesList] = useState<_selectoptionType[]>([]);
    const [profileList, setProfileList] = useState<_selectoptionType[]>([]);
    // const [clusterList, setClusterList] = useState<_selectoptionType[]>([]);
    const { showAdvancedMode } = values;
    const showScheduleModal = useSelector((store: RootState) => store.WorkflowReducer.showModal.scheduleWorkflow);
    const showRunWorkflowModal = useSelector((store: RootState) => store.WorkflowReducer.showModal.runWorkflow);
    const activeProfile = useSelector((store: RootState) => store.WorkflowReducer.activeProfile);
    // const { activeCluster } = useSelector((store: RootState) => store.WorkflowReducer);
 
    const changeProfile = (option: _selectoptionType) => {
        if (option.command) {
            const commands = JSON.parse(option.command);
            const valuesForForm = { ...values, ...commands, activeProfile: option.id };
            setAdditionalInitialValues(valuesForForm);
        } else if (option.value === -1) {
            // No profile 
            setAdditionalInitialValues(getRunWorkflowFormInitialValues());
        }
    };

    const handleProfileResponse = (response: GetProfilesResponse) => {
        // let _activeProfile: _selectoptionType = { id: 0, label: '', value: '',command: '', name: '', shareProfile: false, active: false };
        let isActiveProfileInOptions = false;
        const _options: _selectoptionType[] = response.data.map(profile => {
            const _option = { ...profile, label: profile.name, value: profile.id };
            if (activeProfile ?.id === profile.id) isActiveProfileInOptions = true;
            return _option;
        });
        if (activeProfile) {
            const activeProfileOption = { ...activeProfile, label: activeProfile.name, value: activeProfile.id };
            changeProfile(activeProfileOption);
            if (!isActiveProfileInOptions) {
                // Add active profile option to list if its missing
                _options.unshift(activeProfileOption);
            }
        }
        _options.unshift({ label: 'No Profile', value: -1 });
        setProfileList(_options);
    };

    const getProfiles = () => {
        WorkflowHandler.GetProfiles({}, handleProfileResponse);
    };

    function handleJarFilesResponse(response: string[]) {
        if (!isEmpty(response)) {
            const _jarFilesList = response.map((jarFile: string): _selectoptionType => {
                const actualJarFileName = jarFile.split('/').pop() || '';
                return ({ label: actualJarFileName, value: jarFile });
            });
            setJarFilesList(_jarFilesList);
        }
    }
    // const changeCluster = (option: _selectoptionType) => {
    //     if (option.label) {
    //         const valuesForForm = { ...values, clusterId: option.value };
    //         setAdditionalInitialValues(valuesForForm);
    //     } else if (option.value === -1) {
    //         // No profile 
    //         setAdditionalInitialValues(getRunWorkflowFormInitialValues());
    //     }
    // };
    // function handleGetListSuccess(response: any) {
    //     let isActiveClusterInOptions = false;
    //     const _options = response.map((cluster: any) => {
    //         const  _option = {...cluster,label: cluster.cluster_name, value: cluster.cluster_id};
    //         if (activeCluster ?.value === cluster.value)  isActiveClusterInOptions = true ;
    //         return _option;
    //     });
    //     if (activeCluster) {
    //         const activeClusterOption = { ...activeCluster, label: activeCluster.label, value: activeCluster.value };
    //         changeCluster(activeClusterOption);
    //         if (!isActiveClusterInOptions) {
    //             _options.unshift(activeClusterOption);
    //         }
    //     }
    //     _options.unshift({ label: 'No Cluster', value: -1 });
    //     setClusterList(_options);
    // }
    useDidMount(() => {
        if (showRunWorkflowModal || showScheduleModal || profileTab || showCommandFields) {
            !profileTab && getProfiles();
            if(Env.databricks) {
                WorkflowHandler.ListDatabricksJarFiles(handleJarFilesResponse);
            } else {
                WorkflowHandler.ListJarFiles(handleJarFilesResponse);
            }
        }
    });



    useEffect(() => {
        if (showScheduleModal || showCommandFields) {
            let additionalConfig = '';
            if(!isEmpty(values.additionalConfig)) {
                Object.keys(values.additionalConfig).forEach((key, i) => {
                    additionalConfig += `${i > 0 ? ' ': ''}--conf ${key}=${values.additionalConfig[key as keyof typeof values['additionalConfig']]}`; 
                });
            }
            setFieldValue('command',
                classNames(
                    INITIAL_COMMAND,
                    values.deployMode,
                    { [`--conf spark.dynamicAllocation.initialExecutors=${values.initialExecutors}`]: showAdvancedMode && values.initialExecutors },
                    { [`--conf spark.dynamicAllocation.minExecutors=${values.minExecutors}`]: showAdvancedMode && values.minExecutors },
                    { [`--conf spark.dynamicAllocation.maxExecutors=${values.maxExecutors}`]: showAdvancedMode && values.maxExecutors },
                    { '--conf spark.dynamicAllocation.enabled=true': showAdvancedMode && values.dynamicAllocation },
                    { [`--driver-memory ${values.driverMemory}g`]: showAdvancedMode && values.driverMemory },
                    { [`--executor-memory ${values.executorMemory}g`]: showAdvancedMode && values.executorMemory },
                    { [`--driver-cores ${values.driverCores}`]: showAdvancedMode && values.driverCores },
                    { [`--executor-cores ${values.executorCores}`]: showAdvancedMode && values.executorCores },
                    { [`--num-executors ${values.noOfExecutors}`]: showAdvancedMode && values.noOfExecutors },
                    { [`--jars ${values.jarFiles}`]: !!(values.jarFiles) },
                    { [`--files "${values.files}"`]: !!(values.files) },
                    { [additionalConfig]: !!additionalConfig },
                    values.additionalCommand,
                ));
        }
    }, [values, showAdvancedMode, showCommandFields]);


    const deployModesToBeShown = useMemo(() => {
        const deployModes = [];
        
        if(!Env.disableClientMode) {
            deployModes.push({ label: 'Client', value: 'yarn --deploy-mode client' });
        }

        if(!Env.disableLocalMode) {
            deployModes.unshift({ label: 'Local', value: 'local' });
        }
        if(!Env.disableClusterMode) {
            deployModes.push({ label: 'Cluster', value: 'yarn --deploy-mode cluster' });
        }
        if(Env.sparkDeployMode && showScheduleModal) {
            deployModes.push({ label: 'Spark', value: Env.sparkDeployMode as string });
        }

        return deployModes;
    }, [Env, showScheduleModal]);

    return (
        <div className="runWorkflow__form">
            <div className="profileField">
                {profileTab ?
                    <InputField
                        className="nameField"
                        autoComplete="off"
                        name={nameField.key}
                        required
                        label={nameField.templateOptions.label}
                        disabled={!Env.profiles}
                        validate={FieldSchemaValidator(schemaForNameField)}
                    />
                    :
                    <SelectField
                        name="activeProfile"
                        label="Profile"
                        options={profileList}
                        disabled={!Env.profiles}
                        onOptionClick={(option) => changeProfile(option)}
                    />
                }
            </div>
            <div className="runWorkflow__innerForm">
                {(showScheduleModal || showCommandFields) &&
                    <>
                        <InputField
                            label="Command"
                            type="textarea"
                            name="command"
                            rows={1}
                            className="command__textArea"
                            readOnly
                        />
                        <InputField
                            label="Additional Command (optional)"
                            name="additionalCommand"
                            type="textarea"
                            rows={1}
                            disabled={!Env.profiles}
                            autoComplete="off"
                            className="command__textArea"
                        />
                    </>
                }
                
                <SelectField
                    name="deployMode"
                    label="Deploy Mode"
                    disabled={!Env.profiles}
                    options={deployModesToBeShown}
                />
                <SelectField
                    name="jarFiles"
                    label="Jar Files"
                    options={jarFilesList}
                    multiple_select
                />
                <InputField
                    name="files"
                    label="Files"
                />
                <RadioField
                    name="streamingMode"
                    label="Streaming Mode"
                    options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
                    readOnly={!Env.profiles}
                    inline
                />
                <ToggleField
                    label="Advanced Mode"
                    disabled={!Env.profiles}
                    active={values.showAdvancedMode}
                    onClick={() => setFieldValue('showAdvancedMode', !values.showAdvancedMode)}
                />
                <ShowWhenTrue show={showAdvancedMode}>
                    <RadioField
                        name="dynamicAllocation"
                        label="Dynamic Allocation"
                        options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
                        inline
                    />
                    <div className="split__fields--halfwide">
                        {(values.dynamicAllocation ? dynamicAllocationFieldsOnTrue : dynamicAllocationFieldsOnFalse).map((fieldInfo, index) => (

                            <InputField
                                key={index + fieldInfo.name}
                                name={fieldInfo.name}
                                type="number"
                                label={fieldInfo.label}
                            />
                        ))}
                    </div>

                </ShowWhenTrue>
                <DynamicKeyValueFieldGenerator
                    keyToSaveKeyValueInfo='additionalConfig'
                    addButtonText='+ Configurations'
                />
                {!showScheduleModal &&
                    <TagField 
                        name="commandLineArgs"
                        label="Additional Arguments"
                        labelForNewTag="New Argument"
                    />
                }
            </div>
        </div>
    );

};