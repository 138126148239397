import classNames from 'classnames';
import styles from './../styles.module.scss';
import { _selectoptionType } from '@components/form/select-field';
import GenAI from '../gen-ai';
import { useDataExplorerContext } from '@pages/data-explorer-new/useDataExplorerContext';

const QueryContainer = () => {
    const { theme } = useDataExplorerContext();
    return (
        <div id="chat_middle_container">
            <div className={classNames(styles['panel'], styles['m-1'])}>
                <div className={classNames(styles["flex-row"])}>
                    <div className={styles["DataStudio-AI-text"]} style={{ color: theme === "dark" ? '#fff' : '#000' }}>Unleash the power of <span style={{ color: "#FCAD01" }}>DataStudio AI</span></div>
                    <div className={styles["page_title_info"]} style={{ color: theme === "dark" ? '#fff' : '#000' }}>Access any data is just easier as asking question</div>
                </div>
                <div >
                    <div className={styles["panel-container"]}
                    >
                        <div>
                            <div className={classNames(styles["mb-half"])} style={{ position: 'relative' }}>
                                <GenAI />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
};


export default QueryContainer;