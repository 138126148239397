import { RepeatV3ValueType } from "@components/formcreators/fieldComponents/RepeatV3";
import {
	getFieldValue,
	getRepeatV3InitialValue,
} from "@components/formcreators/schema-creator";
import { BaseFieldType } from "@components/formcreators/types";
import {
	DsNodeExtras,
	DsNodeModel,
} from "@components/workflow-canvas/node/ds_nodemodel";
import {
	PortsCount,
	runNumberOfPortsExpression,
	updatePortCountOfComponent,
} from "@pages/workflow-page/utils";
import { AppThunk } from "@store/types";
import { getComponentDfNameAndIncrementCounter } from "@store/workflow";
import { MultiplePortDetails } from "types/component";
import { cloneDeep, get, has, isEmpty, isEqual, range } from "lodash";
import { ComponentTreeView } from "./types";
import { uuidRegex } from "@constants/enums";
import validateComponentPropertiesForRefresh from "./validateComponentPropertiesForRefresh";

// Here parent is top level nodes - DAG/Data Transformation/Structured Analytics
const getComponentFromParentInfo = ({
	parentInfo,
	nodeKey,
	nodeTitle,
	isCustomComponent,
}: {
	parentInfo: any;
	nodeKey: string;
	nodeTitle: string;
	isCustomComponent: boolean;
}) => {
	let componentInfo = null;
	parentInfo.nodes.some((child: any) => {
		if (!child.is_component) {
			// For components that are in the second level like Data Transformation, Structured Analytics
			child.nodes.forEach((grandChild: any) => {
				if (grandChild.key === nodeKey) {
					componentInfo = { ...grandChild };
					return true;
				}
			});
		} else if (child.key === nodeKey || child?.details.key === nodeKey) {
			// For components that are in the first level like Streaming Analytics, Geospatial Analytics
			// Custom components are in the first level - Also verify custom component name
			if(isCustomComponent && !nodeKey.match(uuidRegex) && child.name !== nodeTitle) {
				// newer custom components has uuid key 
				// older components have function name as key
				// For older components check if the component name matches
				return false
			}
			componentInfo = child;
			return true;
		}
	});
	return componentInfo;
};

export const getComponentInfoFromRiTree = (
	treeData: any,
	selectedComponent: DsNodeModel
): any => {
	let componentInfo = null;
	const isCustomComponent = !!selectedComponent.extras?.customComponentPayload;
	treeData.some((parentInfo: any) => {
		componentInfo = getComponentFromParentInfo({
			parentInfo,
			nodeKey: selectedComponent.extras.key,
			isCustomComponent,
			nodeTitle: selectedComponent.getOptions().title,
		});
		return !!componentInfo;
	});

	return componentInfo;
};

type ValueInfoRef = {
	value: any;
	isFieldHidden: boolean;
	type: BaseFieldType["type"];
	repeat_options: BaseFieldType["repeat_options"];
};

const getFormValues = (
	formData: BaseFieldType[]
): Record<string, ValueInfoRef> => {
	// Refer to formSubmitWithDebounce in properties-rhs.tsx
	const formValues: Record<string, ValueInfoRef> = {};
	formData.forEach((field) => {
		formValues[field.key] = {
			value: getFieldValue(field),
			isFieldHidden: !!field.isFieldHidden,
			type: field.type,
			repeat_options: field.repeat_options,
		};
	});
	return formValues;
};

const migrateFuzzyAggregationToRepeatV3 = (
	valuesRef: ReturnType<typeof getFormValues>,
	formData: BaseFieldType[],
	extraData: DsNodeExtras["extraData"]
) => {
	let isFuzzyAggregationUpdated = false;
	const _formData = formData.map((newField) => {
		if (!has(valuesRef, newField.key) && newField.type === "repeat-v3") {
			const newFieldValue: RepeatV3ValueType = getRepeatV3InitialValue(
				newField,
				extraData
			);

			Object.keys(newFieldValue.repeatData[0]).forEach((fieldKey) => {
				if (has(valuesRef, fieldKey)) {
					newFieldValue.repeatData[0][fieldKey] =
						valuesRef[fieldKey].value;
				}
			});

			newField.value = newFieldValue;
			isFuzzyAggregationUpdated = true;
		}
		return newField;
	});

	return {
		_formData,
		isFuzzyAggregationUpdated,
	};
};

const updateFormDataValues = (
	valuesRef: ReturnType<typeof getFormValues>,
	formData: BaseFieldType[],
	extraData: DsNodeExtras["extraData"]
): BaseFieldType[] =>
	formData.map((newField) => {
		if (has(valuesRef, newField.key)) {
			const { type: oldType, repeat_options, isFieldHidden, value } = get(
				valuesRef,
				newField.key
			);
			if (
				newField.type === "repeat-v3" &&
				oldType === "repeat" &&
				repeat_options?.data_field
			) {
				let newFieldValue: RepeatV3ValueType = getRepeatV3InitialValue(
					newField,
					extraData
				);

				const {
					data_field,
					data_field_delimiter = ",",
					outputFormat,
				} = repeat_options;

				// data_field is the key of the field that repeat options is dependent on
				// dataFieldValue = "col1,col2,col3"
				// dataFieldValueInArr = ["col1","col2","col3"]
				const dataFieldValue = get(valuesRef, data_field).value;
				const dataFieldValueInArr =
					typeof dataFieldValue === "string"
						? dataFieldValue.split(data_field_delimiter)
						: [];
				const count = dataFieldValueInArr.length;
				// extraData = { "repeat-field-key": { "midpoint": "m1,m2", "spread": "s1,s2" }}

				const repeatFieldValue = extraData[newField.key];
				const repeatData = Array.from(
					{ length: count },
					() => ({} as RepeatV3ValueType["repeatData"][0])
				);

				Object.entries(outputFormat).forEach(
					([key, outputFormatInfo]) => {
						// value - "m1,m2"
						// delimiter - ","
						const outputKeyValueInArr = repeatFieldValue[key].split(
							outputFormatInfo.delimiter
						);
						// 'm1,m2' in extraData is converted to value of repeat-field [{ midpoint: 'm1', "spread": "s1" }, { midpoint: 'm2', "spread": "s2" }]
						range(0, count).forEach((idx) => {
							repeatData[idx][key] = outputKeyValueInArr[idx];
						});
					}
				);

				newFieldValue = { count, repeatData };
				newField.value = newFieldValue;
			} else {
				newField.value = value;
			}
			newField.isFieldHidden = isFieldHidden;
		}
		return newField;
	});

const __getPortInfoAndUpdatePortManagementForm = (
	portInfo: MultiplePortDetails,
	currentPortCount: number,
	formValues: Record<string, any>
) => {
	let portCount = currentPortCount;
	if (!isEmpty(formValues) && portInfo.portsExpression) {
		const values: Record<string, any> = {};
		Object.keys(formValues).forEach(
			(key) => (values[key] = formValues[key].value)
		);
		// component has form fields
		portCount = runNumberOfPortsExpression(
			values,
			portInfo.portsExpression
		);
	} else if (portInfo.max && currentPortCount > portInfo.max) {
		// If existing component's ports are greater than the updated version
		portCount = portInfo.max;
	} else if (portInfo.min && currentPortCount < portInfo.min) {
		// If existing component's ports are less than the updated version
		portCount = portInfo.min;
	} else {
		portCount = portInfo.defaultValue;
	}
	return portCount;
};

const getPortManagementInfoAndUpdateFormFields = (
	oldComponent: DsNodeModel,
	newComponentInfo: DsNodeExtras
) => {
	const portsCount: PortsCount = {
		inputPorts: 0,
		outputPorts: 0,
		addVariablePort: false,
		removeVariablePort: false,
	};
	const currentInputPortsCount = oldComponent.getInPorts().length;
	const currentOutputPortsCount = oldComponent.getOutPorts().length;

	let values: Record<string, any> = {};
	if (
		oldComponent.extras.portManagement?.fields &&
		newComponentInfo.portManagement?.fields
	) {
		const extraData = oldComponent.extras.extraData;
		values = getFormValues(oldComponent.extras.portManagement.fields);
		// Updates Port Fields values
		newComponentInfo.portManagement.fields = updateFormDataValues(
			values,
			newComponentInfo.portManagement.fields,
			extraData
		);
		newComponentInfo.formData = updateFormDataValues(
			values,
			newComponentInfo.formData,
			extraData
		);
	}
	// Components can have portExpression which are dependent on its properties instead of fields in portManagement
	if (
		has(newComponentInfo, "portManagement.inputPorts.portsExpression") ||
		has(newComponentInfo, "portManagement.outputPorts.portsExpression")
	) {
		values = { ...values, ...getFormValues(oldComponent.extras.formData) };
	}
	const {
		inputPorts: inputPortInfo,
		outputPorts: outputPortInfo,
	} = newComponentInfo.portManagement;
	portsCount.inputPorts = __getPortInfoAndUpdatePortManagementForm(
		inputPortInfo,
		currentInputPortsCount,
		values
	);
	portsCount.outputPorts = __getPortInfoAndUpdatePortManagementForm(
		outputPortInfo,
		currentOutputPortsCount,
		values
	);
	return portsCount;
};

const refreshComponentInfo = (
	component: DsNodeModel,
	treeData: ComponentTreeView
): AppThunk => (dispatch) => {
	let isComponentUpdated = false;
	const componentInfoInRiTree = cloneDeep(
		getComponentInfoFromRiTree(treeData, component)
	);
	if (componentInfoInRiTree) {
		const isCustomComponent = !!get(component.extras, "customComponentPayload");

		if(isCustomComponent) {
			// since custom components do not have hash
			isComponentUpdated = validateComponentPropertiesForRefresh(component.extras, componentInfoInRiTree.details);
		} else if (componentInfoInRiTree.hash !== component.getOptions().hash) {
			component.getOptions().hash = componentInfoInRiTree.hash;
			isComponentUpdated = true;
		}
		

		const existingComponentFormValues = getFormValues(
			component.extras.formData
		);

		const extraData = component.extras.extraData;

		componentInfoInRiTree.details.formData = updateFormDataValues(
			existingComponentFormValues,
			componentInfoInRiTree.details.formData,
			extraData
		);

		if (
			component.extras.key === "fuzzy_aggregation" &&
			!component.extras.isUpgradedToFuzzyAggregationV3
		) {
			const {
				_formData,
				isFuzzyAggregationUpdated,
			} = migrateFuzzyAggregationToRepeatV3(
				existingComponentFormValues,
				componentInfoInRiTree.details.formData,
				extraData
			);
			componentInfoInRiTree.details.formData = _formData;
			component.extras.isUpgradedToFuzzyAggregationV3 = isFuzzyAggregationUpdated;
		}

		let arePortsUpdated = false;
		let portsCount: PortsCount | undefined;
		if (
			!isEqual(
				component.extras.portManagement,
				componentInfoInRiTree.details.portManagement
			)
		) {
			// portmanagement fields are updated in getPortInfoAndUpdatePortManagementForm function
			portsCount = getPortManagementInfoAndUpdateFormFields(
				component,
				componentInfoInRiTree.details
			);
			updatePortCountOfComponent(component.getID(), portsCount);
			arePortsUpdated = true;
		}

		let inputNumPortField: BaseFieldType | undefined;
		let outputNumPortField: BaseFieldType | undefined;
		component.extras.formData.forEach((field) => {
			if (field.key === "num_ip_ports") inputNumPortField = field;
			if (field.key === "num_op_ports") outputNumPortField = field;
		});

		if (
			arePortsUpdated &&
			portsCount &&
			inputNumPortField &&
			outputNumPortField
		) {
			inputNumPortField.value = portsCount.inputPorts;
			outputNumPortField.value = portsCount.outputPorts;
		}

		if (inputNumPortField && outputNumPortField) {
			let isInputPortFieldAdded = false;
			let isOutputPortFieldAdded = false;
			componentInfoInRiTree.details.formData.forEach(
				(field: BaseFieldType, idx: number) => {
					if (field.key === "num_ip_ports") {
						isInputPortFieldAdded = true;
						componentInfoInRiTree.details.formData[
							idx
						] = inputNumPortField;
					} else if (field.key === "num_op_ports") {
						isOutputPortFieldAdded = true;
						componentInfoInRiTree.details.formData[
							idx
						] = outputNumPortField;
					}
				}
			);
			if (!isInputPortFieldAdded)
				componentInfoInRiTree.details.formData.push(inputNumPortField);
			if (!isOutputPortFieldAdded)
				componentInfoInRiTree.details.formData.push(outputNumPortField);
		}
		componentInfoInRiTree.details.isReadOnly = component.extras.isReadOnly;
		componentInfoInRiTree.details.extraData = component.extras.extraData;
		component.extras = componentInfoInRiTree.details;

		let __dfName = componentInfoInRiTree.details.key;
		if (has(component.extras, "customComponentPayload")) {
			__dfName = component.getOptions().title;
		}
		component.getOptions().dfName = (dispatch(
			getComponentDfNameAndIncrementCounter(__dfName)
		) as unknown) as string;
	}
	return isComponentUpdated;
};

export default refreshComponentInfo;
