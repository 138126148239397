import { DsNodeModel } from "@components/workflow-canvas/node/ds_nodemodel";
import { getUniqueId } from "@utils/common";
import { get, isEmpty } from "lodash";

export const getCustomComponentsFnNameMapping = (nodesData: {[id: string]: DsNodeModel}) => {
    const customComponentFnNameRef = Object.entries(nodesData).reduce((customComponentsInfo, [nodeId, node]) => {
        const { customComponentPayload: currentNodeCustomComponentPayload, fnName } = node.extras;
        if(currentNodeCustomComponentPayload) {
            const fnCustomComponentRef = get(customComponentsInfo, fnName);
            if(isEmpty(fnCustomComponentRef)) {
                customComponentsInfo[fnName] = [[nodeId]];
            } else {
                // check if there is already a component with same code
                // ComponentIds of same code are grouped together in buckets
                const componentWithSameCodeFound = fnCustomComponentRef.some(componentIdsBucket => {
                     return componentIdsBucket.some(componentId => {
                        if(currentNodeCustomComponentPayload === nodesData[componentId].extras.customComponentPayload) {
                            componentIdsBucket.push(nodeId);
                            return true;
                        }
                        return false;
                    })
                })
                if(!componentWithSameCodeFound) {
                    fnCustomComponentRef.push([nodeId]);
                }
            }
        }
        return customComponentsInfo
    }, {} as { [fnName: string]: string[][] })

    return Object.entries(customComponentFnNameRef).reduce(({ customComponentIdFnNameMapping, fnNameComponentIdMapping }, [fnName, componentIdBuckets]) => {
        if(componentIdBuckets.length === 1) {
            // Since there's only one bucket which means the all the components have same code, 
            // all the components can use the actual function name
            fnNameComponentIdMapping[fnName] = componentIdBuckets[0]
            componentIdBuckets[0].forEach(componentId => {
                customComponentIdFnNameMapping[componentId] = fnName;
            })
        } else {
            // Since there are multiple buckets, the components with same code in each bucket will have same function name
            componentIdBuckets.forEach(bucket => {
                const newFnName = fnName + '_' + getUniqueId().replaceAll('-', '_')
                fnNameComponentIdMapping[newFnName] = bucket;
                bucket.forEach(componentId => {
                    customComponentIdFnNameMapping[componentId] = newFnName;
                })
            })
        }
        
        return { customComponentIdFnNameMapping, fnNameComponentIdMapping }
    }, { customComponentIdFnNameMapping: {}, fnNameComponentIdMapping: {} } as { customComponentIdFnNameMapping: { [componentId: string]: string }, fnNameComponentIdMapping: { [fnName: string] : string[] } })
}
