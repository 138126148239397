import classNames from "classnames";
import React from "react";
import {
	Draggable,
	DraggableProvided,
	DraggableStateSnapshot,
	DraggingStyle,
	NotDraggingStyle,
	DroppableProvided,
	DroppableStateSnapshot,
} from "react-beautiful-dnd";
import { DroppableIds, TypesOfColumns } from ".";
import { ColumnSelectionProps } from "./ColumnSelection";

export type ColumnInfo = {
    label: string;
    type: TypesOfColumns;
    selectedColumns: string[];
    droppableId: DroppableIds;
    id: string;
    showEmptyError: boolean,
    disableAllColumnSelection: boolean;
	disabled: boolean;
}

type SelectedColumnsProps = Omit<ColumnInfo, 'disableAllColumnSelection'|'droppableId'> & {
	handleRemoveColumn: ColumnSelectionProps["handleRemoveColumn"];
	getDraggableStyle: (
		provided: DraggableProvided,
		snapshot: DraggableStateSnapshot
	) => DraggingStyle | NotDraggingStyle | undefined;
	droppableProvided: DroppableProvided;
	droppableSnapshot: DroppableStateSnapshot;
	isDataExplorer: boolean;
};

const RenderSelectedColumns: React.FC<SelectedColumnsProps> = ({
	type,
	selectedColumns,
	handleRemoveColumn,
	getDraggableStyle,
	droppableProvided,
	droppableSnapshot,
	id,
    showEmptyError,
    label,
	isDataExplorer
}) => {
	return (
		<>
			<div
				ref={droppableProvided.innerRef}
				className={classNames("columnDrop__box", {
					showDropMessage: droppableSnapshot.isDraggingOver,
                    showEmptyError
				})}
				id={id}
			>
				{selectedColumns.map((column, index) => (
					<Draggable
						key={column + "_" + index}
						draggableId={column + "_" + type}
						index={index}
					>
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								{...provided.draggableProps}
								{...provided.dragHandleProps}
								style={getDraggableStyle(provided, snapshot)}
								className={classNames({
									"drag": isDataExplorer
								})}
							>
								<span className="multiple-select-tags">
									{column}
									<span
										className="closeIcon"
										onClick={() =>
											handleRemoveColumn(type, column)
										}
									>
										X
									</span>
								</span>
							</div>
						)}
					</Draggable>
				))}
    			{droppableProvided.placeholder}

                {showEmptyError && <span className="inputfield__errormessage">{label} cannot be empty</span>}
			</div>
		</>
	);
};

export default RenderSelectedColumns;
