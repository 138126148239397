import { Slider } from "../../../components/form/Slider";
import React, { useEffect, useState } from 'react';
import styles from "../styles.module.scss";
import { Divider } from "antd";

const OpenAISettings: React.FC<{
    onSubmit: any,
    value: any
    visible: boolean
}> = ({onSubmit, value, visible}) => {
    const [temperature, setTemperature] = useState(1);
    const [max_tokens, setMaxLength] = useState(750);
    const [top_p, setTopProb] = useState(0.5);
    const [frequency_penalty, setFreq] = useState(0.55);
    const [presence_penalty, setPresence] = useState(0.49);
    const [best_of, setBestOf] = useState(5);

    useEffect(() => {
        setTemperature(value.temperature);
        setMaxLength(value.max_tokens);
        setTopProb(value.top_p);
        setFreq(value.frequency_penalty);
        setPresence(value.presence_penalty)
        setBestOf(value.best_of)
    }, [visible]);

    return (
        <div className='history-listing' style={{height: 500, width: 400}}>
            <Slider label={"Temperature - " + temperature} min={0} step={0.01} max={1} value={temperature} onChange={setTemperature as any} className={styles["alphaSlider"]} />
            <Slider label={"Max length (tokens) - " + max_tokens} step={1} min={0} max={4000} value={max_tokens} onChange={setMaxLength as any} className={styles["alphaSlider"]} />
            <Slider label={"Top probabilities - " + top_p } step={0.01} min={0} max={1} value={top_p} onChange={setTopProb as any} className={styles["alphaSlider"]} />
            <Slider label={"Frequency penalty - " + frequency_penalty} min={0} step={0.01} max={1} value={frequency_penalty} onChange={setFreq as any} className={styles["alphaSlider"]} />
            <Slider label={"Presence penalty - " + presence_penalty} min={0} step={0.01} max={1} value={presence_penalty} onChange={setPresence as any} className={styles["alphaSlider"]} />
            <Slider label={"Best of - " + best_of} min={0} max={20} step={1} value={best_of} onChange={setBestOf as any} className={styles["alphaSlider"]} />
            <Divider style={{backgroundColor:'white'}} />
            <div>
                <button 
                    className="btn-sm btn-yellow-transparent btn__new__workflow btn__new__action"
                    style={{float:'right'}}
                    onClick={()=>{
                        onSubmit({
                            temperature,
                            max_tokens,
                            top_p,
                            frequency_penalty,
                            presence_penalty,
                            best_of
                        });
                    }}>Confirm</button>
            </div>
        </div>
    );
};

export default OpenAISettings;