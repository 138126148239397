import React, { useEffect, useState } from 'react';
import { useWindowSize } from 'rooks';
import { Modal } from '../../../components/modals';
import { ActiveImgInfo } from '../help-renderer/help-console-tab-renderer';

interface Props {
    imgInfo: ActiveImgInfo;
    show: boolean;
    toggleModal: () => any;
}


export const ImagePreview: React.FC<Props> = ({ imgInfo, show, toggleModal }) => {
    const [imgDim, setImgDim] = useState({ width: 1000, height: 1000 });
    const windowSize = useWindowSize();

    const handleSetImgDims = () => {
        let height = windowSize.innerHeight * 0.75;
        let width = height * imgInfo.aspectRatio;
        if ((width) > (windowSize.innerWidth - 80)) {
            width = windowSize.innerWidth - 100;
            height = width/imgInfo.aspectRatio;
        }
        setImgDim({ width, height });
    };

    useEffect(() => {
        if(show) {
            handleSetImgDims();
        }
    }, [show, imgInfo, windowSize]);


    return(
        <Modal
            title=""
            isOpen={show }
            toggleClose={toggleModal}
            className="imagePreviewModal"
        >
            <img 
                src={imgInfo.src} 
                alt="" 
                style={{
                    ...imgDim
                }}
            />
        </Modal>
    );
};