import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

export interface ModalSummaryVisualizationProps {
    title: string;
    data: Record<string, any>;
    style?: React.HTMLAttributes<HTMLTableElement>['style'];
}

export const TableForModelSummary: React.FC<ModalSummaryVisualizationProps> = ({ data, title, style = {} }) => {
    return(
        <div>
            <span>{_.startCase(_.capitalize(title))}</span>
            <table 
                className={classNames('analyticsTable modelSummaryTable')}
                style={style}
            >
                <tbody>
                    {Object.entries(data).map(([key, value]) => (
                        <tr key={key}>
                            <td className={classNames('_keys', {'_upperCase': key.length < 5 })}>
                                {key}
                            </td>
                            <td style={{ color: 'rgba(256, 256, 256, 0.7)' }}>{value.toString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};