import React, {
    useState,
    useEffect
} from 'react';
import { useFormikContext } from 'formik';
import { debounce, replace } from 'lodash';
import { FieldCreatorProps, strictFunc } from '../field-creator';
import LoopRender from './loopRender';

const ComponentList: React.FC<FieldCreatorProps> = ({ fieldData }) => {
    const { setFieldValue, values } = useFormikContext<any>();
    const [renderData, setRenderData] = useState([]);
    const triggerField = fieldData.async && fieldData.async.triggerField && strictFunc(values, fieldData.async.triggerField);
    const renderOn = fieldData ?.component ?.renderOn && strictFunc(values, fieldData ?.component ?.renderOn);

    useEffect(debounce(() => {
        const extraData = values.extraData || {};
        extraData[fieldData.key] = {};
        setFieldValue('extraData' as never, extraData);
    }, 100), [triggerField]);

    useEffect(debounce(() => {
        const extraData = values.extraData || {};
        setRenderData(extraData['workflow_component'] || []);
    }, 100), [renderOn]);

    useEffect(debounce(() => {
        const key1 = renderOn.split(',');
        const key = key1.map((v: any) => replace(v, /-/g, '_'));
        const data: any = [];
        key.forEach((element: any, index: number) => {
            let obj = values[element];
            if (!obj) return;
            obj = obj.split(',');
            const _value: any = [];
            const v1 = values ?.extraData ?.workflow_component ?.find((element: any) => {
                return element.id === key1[index];
            });
            const portIn = (v1?.extras?.append || v1?.extras?.input_df_to_key) ? 0 : (v1?.portsInOrder?.length || 0);
            obj ?.forEach((_o: any) => {
                let arg_index = v1 ?.extras ?.formData ?.find((res: any) => res.key === _o) ?.templateOptions.arg_index;
                arg_index = isNaN(parseInt(arg_index, 10))? 0 : parseInt(arg_index, 10);
                
                if(!(v1.extras?.customComponentPayload)) {
                    arg_index = arg_index + portIn;
                }
                const _key = element + '__' + _o;
                _value.push({
                    key: _o,
                    value: values[_key],
                    arg_index
                });
            });
            data.push({
                component_id: replace(element, /_/g, '-'),
                data: _value
            });
        });
        values[fieldData.key] = JSON.stringify(data);
    }, 1000), [values]);

    return (
        <div className="p-5">
            {
                renderData.map((_items: any, index)=>{
                    return (
                        <LoopRender key={`${fieldData.key}__componentList_${index}`} fieldData={fieldData} _items={_items} />  
                    );
                })
            }
        </div>
    );
};

export default ComponentList;