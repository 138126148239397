import { WorkflowConfigMappingInFormikContext } from "@components/formcreators/WorkflowConfigVariablePortFields/reducer";
import { WorkflowConfigItemType } from "@services/WorkflowConfig";
import { isInteger } from "lodash";
import {
	WorkflowConfigItemInfoInNode,
	WorkflowConfigItemToUpdate,
} from "./types";

const getNodeWorkflowConfigItems = (
	workflowConfigItemsToBeChecked: WorkflowConfigItemToUpdate[],
	workflowConfigData: WorkflowConfigMappingInFormikContext
): WorkflowConfigItemInfoInNode[] => {
	const workflowConfigItemsInCurrentNode: WorkflowConfigItemInfoInNode[] = [];

	workflowConfigItemsToBeChecked.forEach((workflowConfigItem) => {
		const selectedFieldsOfWorkflowConfigItem =
			workflowConfigData.keyFieldMapping[workflowConfigItem.key];
		// store field ids of the fields that has the workflow config item
		if (selectedFieldsOfWorkflowConfigItem) {
			workflowConfigItemsInCurrentNode.push({
				...workflowConfigItem,
				selectedFieldIdMapping: selectedFieldsOfWorkflowConfigItem
					.split(",")
					.reduce((fieldIdMapping, fieldId) => {
						fieldIdMapping[fieldId] = true;
						return fieldIdMapping;
					}, {} as Record<string, true>),
			});
		}
	});

	return workflowConfigItemsInCurrentNode;
};

const getWorkflowConfigNumberDataType = (value: string | number) => {
	try {
		return isInteger(+value)
			? WorkflowConfigItemType.Integer
			: WorkflowConfigItemType.Decimal;
	} catch {
		return WorkflowConfigItemType.Integer;
	}
};

export { getNodeWorkflowConfigItems, getWorkflowConfigNumberDataType };
