/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Button, Radio, Tooltip } from 'antd';

const JoinPopup = (props) => {
    const [value, setValue] = useState('');
    
    useEffect(()=>{
        if(props.query.geoSpatial.isGeoSpatialEnabled){
            setValue('INNER JOIN');
            props.query.from.handleJoinTypeClick(props.rowIndex, 'INNER JOIN');
            props.updateQueryState();
        }
    }, [props.query.geoSpatial.isGeoSpatialEnabled]);

    const handleSizeChange = e => {
        setValue(e.target.value );
        props.query.from.handleJoinTypeClick(props.rowIndex, e.target.value); // Update from row join type
        props.updateQueryState();
    };

    return (
        <Radio.Group value={value} onChange={handleSizeChange}>
            <Tooltip placement="top" title="INNER JOIN">
                <Radio.Button value="INNER JOIN">
                    <img
                        src='/icons/querybuilder/jointype/Inner.svg'
                        alt=""
                    />
                </Radio.Button>
            </Tooltip>
            <Tooltip placement="top" title="LEFT JOIN">
                <Radio.Button disabled={props.query.geoSpatial.isGeoSpatialEnabled} value="LEFT JOIN">
                    <img
                        src='/icons/querybuilder/jointype/Left.svg'
                        alt=""
                    />
                </Radio.Button>
            </Tooltip>
            <Tooltip placement="top" title="RIGHT JOIN">
                <Radio.Button disabled={props.query.geoSpatial.isGeoSpatialEnabled} value="RIGHT JOIN">
                    <img
                        src='/icons/querybuilder/jointype/Right.svg'
                        alt=""
                    />
                </Radio.Button>
            </Tooltip>
            <Tooltip placement="top" title="FULL JOIN">
                <Radio.Button disabled={props.query.geoSpatial.isGeoSpatialEnabled} value="FULL JOIN">
                    <img
                        src='/icons/querybuilder/jointype/Full.svg'
                        alt=""
                    />
                </Radio.Button>
            </Tooltip>
        </Radio.Group>
    );
};

export default JoinPopup;