import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/types';
import classNames from 'classnames';
import { InPageSpinner } from '../../../components/spinners/in-page-spinner';
import QueryBuilder from '../../../components/builder/sql';
import { Modal } from '../../../components/modals';
import { queryBrowserTabsList, queryBrowserTabs } from '../enums';
// import { Dropdown } from '../../../components/form/dropdown';
import Form, { SelectField } from '../../../components/form';
import { _selectoptionType } from '../../../components/form/select-field';
import { toggleDataBrowserModal } from '../../../store/datasources';
import CustomMonacoEditor from '../../../components/monacoEditor';
import isEmpty from 'lodash/isEmpty';

interface QueryBuilderModalProps {
    onSubmit: (arg0: string) => any;
}

const queryBuildertabs = { 
    editor: 'Editor',
    queryBuilder: 'Query Builder'
};

const queryBuildertabsList = Object.values(queryBuildertabs);
type FieldInfoForQueryBuilder = { name: string; type: 'text'; fkTargetTables: []; 'constraint': null; nullable: false };
type TableInfoForQueryBuilder =  {
    name: string;
    id: string;
    fields: FieldInfoForQueryBuilder[];
}

export const QueryEditorBuilderModal: React.FC<QueryBuilderModalProps> = ({ onSubmit }) => { /* eslint-disable-line */
    const { showModal, allConnectionsInfo } = useSelector((store: RootState) => store.NewDataSourceReducer);
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(queryBuildertabs.editor);
    const [ queryData, setQueryData ] = useState<TableInfoForQueryBuilder[] | null>(null); /* eslint-disable-line */
    const [editorValue, setEditorValue] = useState('');
    const [activeQueryBrowserTab, setActiveQueryBrowserTab] = useState(queryBrowserTabs.queryHistory);

    const connectionsOptions: _selectoptionType[] = useMemo(() => {
        return allConnectionsInfo.map(_connection => ({ label: _connection.name, value: _connection.id }));
    }, [allConnectionsInfo]);

    const toggleClose = () => {
        dispatch(toggleDataBrowserModal('queryEditorBuilder' ,false));
    };

    const onEditorValueChange = (value: string) => {
        setEditorValue(value);
    };


    const customMonacoEditorController = CustomMonacoEditor.useCustomMonacoEditorController()

    const getActionButtons = () => {
        return(
            <div className="actionButtons__box">
                <button
                    className="btn-md btn-yellow"
                >
                    Run
                </button>
                <button
                    className="btn-md btn-yellow-transparent"
                >
                    Save
                </button>
            </div>
        );
    };


    return(
        <Modal
            title="Run a Query"
            isOpen={showModal.queryEditorBuilder}
            toggleClose={toggleClose}
            className="queryBuilderModal__container"
        >
            <Form 
                className="connectionSelectionForm"
                initialValues={{ connection: '' }}
                onSubmit={() => {return;}}
            >
                <SelectField 
                    name="connection"
                    options={connectionsOptions}
                    label="Connection: "
                    useOptionSearch={false}
                    useDynamicWidth
                />
            </Form>
            <div className="tabs__outer">
                <ul className="switchable__tabs__outer">
                    {queryBuildertabsList.map(_tab => (
                        <li 
                            className={classNames('switchable__tab', {'switchable__tab--active': _tab === activeTab})}
                            onClick={() => setActiveTab(_tab)}
                            key={_tab}
                           
                        >
                            <span  id={`${(_tab).replace(' ','')}`} >
                                {_tab}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="queryBuilderModal__inner">
                {activeTab === queryBuildertabs.editor ?
                        <CustomMonacoEditor 
                            {...customMonacoEditorController}
                            language="sql"
                            editorHeight="220px"
                        >
                            {getActionButtons()}
                            <div className="queryList__box">
                                <ul className="switchable__tabs__outer">
                                    {queryBrowserTabsList.map(_tab => (
                                        <li 
                                            className={classNames('switchable__tab', {'switchable__tab--active': _tab === activeQueryBrowserTab})}
                                            onClick={() => setActiveQueryBrowserTab(_tab)}
                                            key={_tab}
                                        >
                                            {_tab}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </CustomMonacoEditor>
                    :
                    <div  className="editorTab" style={{overflow: 'auto'}}>
                        {isEmpty(queryData) ? 
                            (<div 
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <InPageSpinner />
                                <span style={{color:'white'}}>Fetching schema, please wait...</span>
                            </div>)
                            :  
                            <QueryBuilder
                                data={queryData}
                                setSQLQuery={onEditorValueChange}
                            />
                        }
                        {getActionButtons()}
                    </div>
                }
            </div>
        </Modal>
    );
};