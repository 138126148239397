import React from 'react';
import { useDispatch } from 'react-redux';
import { Cluster, ClusterState } from '../../api/databricks-handler';
import { TooltipTop } from '../../components/tooltips';
import { ShowWhenTrue } from '../../helpers';
import { AccountReducerState } from '../../store/account/types';
import { startCluster, terminateCluster, changeClusterDefault } from '../../store/cluster';
import { SettingsSVGIcon, RestartSVGIcon, DeleteSVGIcon, StartSVGIcon, StopSVGIcon, WhiteInfoIcon, BlackInfoIcon } from './icons';

interface ClusterCardProps {
    cluster: Cluster;
    currentUser: AccountReducerState['activeUserInfo'];
    restartCluster: (clusterId: string, clusterName: string) => void;
    editCluster: (cluster: Cluster) => void;
    deleteCluster: (clusterId: string, clusterName: string) => void;
}

const workspaceTypeRef:Record<string,string> = {
    "databricks":"Databricks",
    "cloudera":"Cloudera",
    "spark":"Spark"
}

const ClusterCard: React.FC<ClusterCardProps> = ({ cluster, currentUser, restartCluster, editCluster, deleteCluster }) => {

    const dispatch = useDispatch();

    const changeThisClusterDefault = () => {
        
        dispatch( changeClusterDefault(cluster.clusterId, !cluster.defaultCluster) );
    };

    const startThisCluster = () => {
        dispatch(startCluster(cluster.clusterId));
    };

    const restartThisCluster = () => {
        restartCluster(cluster.clusterId, cluster.clusterName)
    };

    const terminateThisCluster = () => {
        dispatch(terminateCluster(cluster.clusterId));
    };

    const deleteThisCluster = () => {
        deleteCluster(cluster.clusterId, cluster.clusterName);
    };

    const editThisCluster = () => {
        if(cluster.state !== ClusterState.PENDING){
            editCluster(cluster);
        }
    };

    const isAdmin = currentUser.isAdmin;
    const isPublicCluster = !cluster.npipWorkSpace;

    return (
        <div className="card-layout">
            
            <div className="cardbg">
                <div className="carttext">
                    <div className="cart-contact">
                        <h4>{cluster.clusterName}</h4>
                        <div className="badge-section">
                            {cluster.defaultCluster &&
                                    <div className="default-badge">
                                        <span className="label">default</span>
                                    </div>
                            }
                            <div className={`${'status-badge'} ${cluster.state?.toLowerCase()}`}>
                                <span className="label"> {cluster.state?.toLowerCase()}</span>
                                <ShowWhenTrue show={!!cluster.stateMessage}>
                                    <TooltipTop placement="topRight" overlay={cluster.stateMessage}>
                                        <div className="info__icon">
                                            {cluster.state === ClusterState.PENDING || cluster.state === ClusterState.RESTARTING ? 
                                                <BlackInfoIcon />
                                                :
                                                <WhiteInfoIcon />
                                            }
                                        </div>
                                    </TooltipTop>
                                </ShowWhenTrue>
                            </div>
                        </div>
                    </div>
                    <div>
                        {cluster.workspaceType === "databricks" && <>
                            <div className="cart-contact-border">
                                <h5>Driver</h5>
                                <h6>{cluster.driverNodeTypeId || ''}</h6>
                            </div>
                            <div className="cart-contact-border">
                                <h5>Runtime</h5>
                                <h6>{cluster.sparkRunTimeVersion || 'Spark Runtime version'}</h6>
                            </div>
                            <div className="cart-contact-border">
                                <h5>Creator</h5>
                                <h6>{cluster.createdBy || ''}</h6>
                            </div>
                            <div className="cart-contact-border">
                                <h5>Cluster Type</h5>
                                <h6>{isPublicCluster ? 'Public' : 'Private'}</h6>
                            </div>
                        </>}
                        <div className="cart-contact-border">
                            <h5>Workspace Type</h5>
                            <h6>{workspaceTypeRef[cluster.workspaceType]}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cardfooter">
                {/* {isAdmin ?  */}
                <div className="cardftext">
                    <div className="footer-btn-wrapper">
                        <ShowWhenTrue show={isAdmin && cluster.workspaceType === "databricks"}> 

                            {cluster.state === ClusterState.RUNNING &&
                                        <button className="stop-btn" onClick={terminateThisCluster}>
                                            <StopSVGIcon />
                                            <span>Stop</span>
                                        </button>
                            }
                            {cluster.state === ClusterState.TERMINATED &&
                                        <button className='btn-yellow' onClick={startThisCluster}>
                                            <StartSVGIcon />
                                            <span>Start</span>
                                        </button>
                            }
                        </ShowWhenTrue>
                        {/* <TooltipTop title={isPublicCluster ? 'Public cluster cannot be made default': ''}>  */}
                            <div>
                                <button 
                                    className='btn-md btn-grey'
                                    onClick={changeThisClusterDefault}
                                    // disabled={isPublicCluster}    
                                >
                                    {cluster.defaultCluster ? 'Remove Default' : 'Make Default'}
                                </button>
                            </div>
                        {/* </TooltipTop> */}

                    </div>
                    <div className="svgfooter">
                        {cluster.state !== ClusterState.PENDING && 
                                    <TooltipTop title="Cluster settings"><div onClick={editThisCluster}><SettingsSVGIcon /></div></TooltipTop>
                        } 
                        {
                            cluster.state === ClusterState.RUNNING && cluster.workspaceType === "databricks" &&
                                    <TooltipTop title="Restart cluster"><div onClick={restartThisCluster}><RestartSVGIcon /></div></TooltipTop>
                        }
                        <ShowWhenTrue show={isAdmin}> 
                            <TooltipTop title="Delete cluster"><div onClick={deleteThisCluster}><DeleteSVGIcon /></div></TooltipTop>
                        </ShowWhenTrue>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClusterCard;