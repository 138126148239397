import {useCallback } from 'react';
import React from 'react';
import classnames from 'classnames';
import styles from './../styles.module.scss';
import { NifiEdgeData } from './types';
import { DataSourceHandler } from '@api/data-source-handler';
import FileSaver from 'file-saver';
import { AxiosResponse } from 'axios';
import { errorAlert } from '@components/toastify/notify-toast';
import { TooltipTop } from '@components/tooltips';
import {
	GoToFullScreenIcon
} from "@pages/workflow-analytics-page/assets/icons";

type NifiTilesProps={
    nifiData: NifiEdgeData[],
    setNifiEdgeModal: (value:boolean)=>void,
    setNifiEdgeDataModal: (data:NifiEdgeData)=>void
    onEdgeNodeClick: (clickedNodeData: NifiEdgeData, time: string) => void;
}

const NifiTiles: React.FC<NifiTilesProps> = ({
    nifiData = [],
    setNifiEdgeModal,
    setNifiEdgeDataModal,
    onEdgeNodeClick,
}) => {

    const onDownloadCertificate = useCallback((id: number, _data: NifiEdgeData) => {
        DataSourceHandler.DownloadEdgeCertificate(_data.nodeName)
            .then((response: AxiosResponse<any>) => {
                // Extract filename from url
                const link = response.config.url;
                if (link) {
                    const match = link.match(/\/v2\/([^/]+)\/generate-config/);
                    const extractedWord = match ? match[1] : null;
    
                    let filename = `${extractedWord ? extractedWord : 'config'}.tar.gz`; 
                    if (extractedWord) {
                        const filenameMatch = extractedWord.match(/filename="(.+)"/);
                        if (filenameMatch && filenameMatch.length === 2) {
                            filename = filenameMatch[1];
                        }
                    }
                    FileSaver.saveAs(new Blob([response.data]), filename);
                } else {
                    throw new Error("Failed to extract filename from the download link. Please try again later.");
                }
            })
            .catch(() => {
                errorAlert("Something went wrong, please try again after sometime");
            });
    }, []);
    
    

    const onEditNode = useCallback((data: any) => {
        setNifiEdgeDataModal(data)
        setNifiEdgeModal(true);
    }, []);
    
    return (
        <ul className={classnames(styles['nifi_titles'])}>
            {
                nifiData.map((_data) => {
                    return (
                        <li key={_data.nodeName}>
                            <div className={styles['mt-0']}>
                                <TooltipTop
                                    title={_data.nodeName}
                                >
                                    <h2 className={styles['title'] + ' ' +  styles['truncate']}>{_data.nodeName}</h2>
                                </TooltipTop>
                                <div className={styles['badge-section']}>
                                    <div className={classnames(styles['status-badge'], {
                                        [styles['running']]: _data.online,
                                        [styles['terminated']]: !_data.online,
                                    })}>
                                        <div className={styles['circle']}></div>
                                        <span className={styles['label']}>
                                            {
                                                _data.online? 'Online' : 'Offline'
                                            }
                                        </span>
                                    </div>
                                    <div className={styles['go-to-full-screen-icon']} onClick={() => onEdgeNodeClick(_data, '48days')}>
                                <GoToFullScreenIcon  />
                                    </div>
                                </div>
                            </div>
                            <div className={styles['divider']}></div>
                            <div >
                                <div className={styles['key']}>
                                    Node Kind
                                </div>
                                <div>
                                    {_data.nodeKind}
                                </div>
                            </div>
                            <div className={styles['divider']}></div>
                            
                            <button className={styles['editButton']} onClick={()=>{
                                onEditNode(_data);
                            }} >
                                <div className={styles['center']}>
                                    <img src='/icons/pencil.svg' />
                                    Edit Edge Node
                                </div>
                            </button>
                            <button className={styles['editButton']} onClick={() => {
                                onDownloadCertificate(_data.id, _data)
                            }} >
                                <div className={styles['center']}>
                                    <img src='/icons/certificate.svg' />
                                    Download Configuration
                                </div>
                            </button>
                        </li>
                    )
                })
            }
        </ul>
    );
};

export default NifiTiles;
