import React from "react";
import styles from './styles.module.scss';

export const HelpTab = () => {
	return (
		<div className={styles.helpText}>
			PI application sends the request to PI server and performs the
			necessary action to process and push the data to a cloud sink. We
			can build workflows on the streaming data after reading it from the
			cloud sink and do ETL operations using DataStudio components.
		</div>
	);
};
