import { DiagramModel } from "@projectstorm/react-diagrams";
import { SerializedData, WorkflowCanvas, WorkflowMetaData } from ".";

export const convertSerializedWorkflowDataToModel = (serializedWorkflowInfo: SerializedData) => {
    const _model = new DiagramModel();
    if(serializedWorkflowInfo) {
        let __SerializedData: ReturnType<DiagramModel['serialize']>;
        if(typeof(serializedWorkflowInfo) === 'object'){
            __SerializedData = serializedWorkflowInfo;
        } else {
            __SerializedData = JSON.parse(serializedWorkflowInfo);
        }
        _model.deserializeModel(__SerializedData, WorkflowCanvas.engine);
    } 
    return _model;
};


export const serializeWorkflowModel = (model: DiagramModel, workflowMetaData: WorkflowMetaData): string => {
    const __workflowData: SerializedData = {...model.serialize(), workflowMetaData };
    return JSON.stringify(__workflowData);
};