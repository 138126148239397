import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/types';
import { UserDetails } from '../../components/header-sidebar/user-details';
import {  Modal as ModalAntd, Tooltip } from 'antd';
import { Modal } from '@components/modals'
import { Flex } from '@components/ui/Flex';
import { showProperties, hideProperties, ActiveComponentInfo, 
    setActiveComponentInfo, setAdditionalFormInfo, closeActiveForm, setCurrentWorkflowStateToUnsaved, updateTabDagRhsFormValues, saveCurrentWorkflowTabState, closeActiveComponentNotes,saveActiveWorkflow } from '../../store/workflow';
import { PropertiesIcon } from '../../components/header-sidebar/icons';
import { FormCreator } from '../../components/formcreators';
import { PropertiesToggle } from './assets/icons';
import classNames from 'classnames';
import { _canvasdagFormInitialValues, getDagWorkflowInfo, getFieldTypeUsingPropsType, getHiddenFieldNameKey, getHiddenFieldNames } from './utils';
import { WorkflowCanvas } from '../../components/workflow-canvas';
import { DsNodeModel } from '../../components/workflow-canvas/node/ds_nodemodel';
import _, { has, isEmpty, isEqual  } from 'lodash';
import { useDidUpdate } from 'rooks';
import {errorAlert, infoAlert, successAlert} from '../../components/toastify/notify-toast';
import { useGetActiveTabInfo } from '../../utils';
import { getTabType, updateAWorkflowEditorTabInfo, WorkflowCanvasTabInfo } from '../../store/canvas';
import { ShowWhenTrue } from '../../helpers';
import { ExecutionEnvModes } from '../../constants/enums';
import {  WorkflowHandler } from '../../api/workflow-handler';
import { SchedulePipelinesForm } from './SchedulePipelinesForm';
import classnames from 'classnames';
import { ComponentNote } from './Notes';
import WorkflowDetailsTab from './RHS-Tabs/WorkflowDetailsTab';
import WorkflowConfigTab from './RHS-Tabs/WorkflowConfigTab';
import { useDebounce } from 'rooks5';
import moment from "moment";
import {ScheduleTiming} from "@pages/workflow-page/modals/schedule-workflow";
import workflowActionHandler from '@services/WorkflowActionHandler';
import { CommonKeysInfoForGlobalArguments, getWorkflowConfigOfAllComponents, WorkflowConfigItemType } from '@services/WorkflowConfig';
import { WorkflowConfig } from '@services/WorkflowConfig';
import { errorHandlerFor400 } from '@api/services/errorhandler';
import { uuid } from 'uuidv4';
import { Env } from '@constants/settings';
import { Text } from '@components/ui/Text';
import { _selectoptionType } from '@components/form/NewSelectField';
import RefreshComponentInProperties from '@components/formcreators/RefreshComponent';


export const PropertiesRHS = () => {
    const { 
        isPropertiesMinimized, 
        activeComponentInfo, 
        activeComponentPropertiesInfo, 
        additionalValuesForForm: additionalInitialData 
    } = useSelector((store: RootState) => store.WorkflowReducer);
    const [canvasdagFormInitialValues,setCanvasdagFormInitialValues] = useState(_canvasdagFormInitialValues);
    const activeTabInfo = useGetActiveTabInfo('workflowEditor') as WorkflowCanvasTabInfo | undefined;
    const dispatch = useDispatch();
    const isDagEnv = useSelector((store: RootState) => store.CommonReducer.activeExecutionEnv === ExecutionEnvModes['Pipelines']);
    const [isJobScheduled,setIsJobScheduled] = useState(false);
    type activeViewTabIndexes = 0 | 1 | 2;
    const [activeViewIndex, setActiveViewIndex] = useState<activeViewTabIndexes>(0);
    const { openTabs, activeTab } = useSelector((store: RootState) => store.CanvasReducer.workflowEditor);
    const activeWorkflowInfo: WorkflowCanvasTabInfo | undefined = useMemo(() => (openTabs.get(activeTab.id)?.info), [activeTab, openTabs]);
    const workSpaceData = useSelector((store: RootState) => store.ClusterReducer.workspaceList);
    const enabledWorkspace = workSpaceData?.filter((item)=>item.isEnabled)?.[0];
    const { activeExecutionEnv } = useSelector((store: RootState) => store.CommonReducer);
    const isRouteOptimisationComponent = (
        activeComponentInfo?.formDetails?.findIndex(field => field.key === "prioritize_critical_high") ?? -1
    ) > -1;
    const [isRouteOptimisationModalOpen, setIsRouteOptimisationModalOpen] = useState(false);
 
    useDidUpdate(() => {
        // clear additional initial data if activeComponent change
        !isEmpty(additionalInitialData) && dispatch(setAdditionalFormInfo({}));
    }, [activeComponentInfo?.formDetails]);

    useEffect(() => {
        setActiveViewIndex(0);
    }, [activeComponentInfo]);

    const formSubmitWithDebounce = useDebounce((values: any, activeComponentInfo: ActiveComponentInfo | null, successCb ?: Function) => {
        if(activeComponentInfo){
            const hiddenFieldNames = getHiddenFieldNames();
            const _ComponentDetailsCopy = activeComponentInfo.formDetails.map(field => {
                const fieldName = field['key'];
                // get is used to access values of nested keys = numOfTrees.max
                field['value'] = _.get(values, fieldName);
                const hiddenFieldKey = getHiddenFieldNameKey(fieldName, getFieldTypeUsingPropsType(field.type));
                field['isFieldHidden'] = !!(hiddenFieldNames[hiddenFieldKey]);
                return field;
            });

    
            const _component = WorkflowCanvas.getNode(activeComponentInfo.id) as DsNodeModel;
            _component.extras.formData = _ComponentDetailsCopy;
            _component.extras.extraData = values.extraData;
            _component.setIsPropertiesSavedAtleastOnce(true);

            dispatch(setActiveComponentInfo({ ...activeComponentInfo, formDetails: _ComponentDetailsCopy, extraData: values.extraData }));
            //Don't re-render if it is loop component, which causes the property window to render, which causes un-expected behaviour.
            if(_component.extras.key !== 'loop'){
                WorkflowCanvas.repaintCanvas();  
            }
            // Optimise this function
            dispatch(setCurrentWorkflowStateToUnsaved());
            dispatch(saveCurrentWorkflowTabState());
            successCb?.();
        }
    }, 250);

    const handleFormSubmit = (values: any, successCb ?: Function) => {
        formSubmitWithDebounce(values, activeComponentInfo, successCb);
        setIsRouteOptimisationModalOpen(false);
    };

    const handlePropertiesToggleClick =  () => {
        if(!activeComponentPropertiesInfo.isSaved) {
            dispatch(closeActiveForm());
        } else if(!activeComponentPropertiesInfo.isNotesSaved) {
            dispatch(closeActiveComponentNotes());
        } else {
            if (isPropertiesMinimized) 
                dispatch(showProperties());
            else 
                dispatch(hideProperties());
        }
    };

    const activeComponentInfoFormDetails = useMemo(() => {
        if(activeComponentInfo) {
            if(activeTabInfo?.isReadOnly) {
                // TO CONVERT ALL THE FIELDS TO READONLY
                return activeComponentInfo.formDetails.map(field => ({ ...field, readOnly: true }));
            }
            return activeComponentInfo.formDetails;
        }
        return [];
    }, [activeComponentInfo?.formDetails]);

    const handleScheduleRhsFormSubmit = (values: WorkflowCanvasTabInfo['scheduleInfoForDag']) => {
        if(enabledWorkspace){
            setIsJobScheduled(true);
            dispatch(updateTabDagRhsFormValues({ values, reset: false }));
            handleScheduleDagWorkflow(values);
        }else{
            errorAlert("Please enable workspace")
        }
    };

    const isEmptyWorkflow = isEmpty(activeTabInfo?.details);

    const handleScheduleDagWorkflow = (values?: WorkflowCanvasTabInfo['scheduleInfoForDag']) => {
        const allPropertiesValidArr = Object.values(WorkflowCanvas.getAllNodes()).filter((item) => item.getOptions().isPropertiesValid === true);
        const isAllPropertiesValid = allPropertiesValidArr.length !== Object.values(WorkflowCanvas.getAllNodes()).length;
        if (isAllPropertiesValid) {
            ModalAntd.confirm({
                title: "Validation failed",
                content: "Do you want to proceed ?",
                onOk: () => {
                    if (isEmpty(WorkflowCanvas.getAllNodes())) {
                        errorAlert('Please add components to start executing the workflow');
                    } else {
                        
        if(isEmptyWorkflow){
            errorAlert("Empty workflow");
            setIsJobScheduled(false);
            return;
        }

            const startTime = moment(activeTabInfo?.scheduleInfoForDag?.startDate, "YYYY,M,D,H,m");

            if(activeTabInfo?.scheduleInfoForDag?.scheduleTimingInfo === ScheduleTiming["Run Once"] &&
                !(startTime.isValid() && startTime.isSameOrBefore(moment()))) {
                return errorAlert("'Run Once', the schedule date should be greater than current time");
            }
            let dagPostWorkflowInfo:any = getDagWorkflowInfo(dispatch);
            const dataBricksOperatorId = "0099f361-f877-4600-85f4-58b7445bc143";
            const nodes = [
                {
                    id: dataBricksOperatorId,
                    fn_id: "DatabricksSubmitRunOperator",
                    fnName: "DatabricksSubmitRunOperator",
                    title: "Databricks Operator",
                    inputPorts: [],
                    outputPorts: [
                        {
                            id: "4eb20487-942d-48b9-aea0-7b32d94c58c4",
                            type: "OUT-0",
                            link: [],
                            parentNode: dataBricksOperatorId
                        }
                    ],
                    data: [
                        {
                            key: "task_id",
                            value: "test1_628bcc55-0af1-4ddf-8bc0-84edff90b7d7",
                            arg_index: 1,
                            variable_type: "string",
                            useKey: true
                        },
                        {
                            key: "existing_workflow",
                            value: true,
                            arg_index: 6,
                            variable_type: "boolean",
                            useKey: true
                        },
                        {
                            key: "workflow_id",
                            value: activeTabInfo?.id,
                            arg_index: 2,
                            variable_type: "integer",
                            useKey: true
                        },
                        {
                            key: "databricks_conn_id",
                            value: enabledWorkspace?.workspaceName,
                            arg_index: 3,
                            variable_type: "string",
                            useKey: true
                        },
                        {
                            key: "notebook_task",
                            value: "ssh_spark",
                            arg_index: 4,
                            variable_type: "string",
                            useKey: true
                        },
                        {
                            key: "existing_cluster_id",
                            value: values?.cluster,
                            arg_index: 5,
                            variable_type: "string",
                            useKey: true
                        },
                        {
                            key: "base_parameters",
                            value: "",
                            arg_index: 8,
                            variable_type: "string",
                            useKey: true
                        },
                        {
                            key: "databrick_file_path",
                            value: "",
                            arg_index: 7,
                            variable_type: "string",
                            useKey: true
                        }
                    ],
                    type: "component",
                    df_name: "databricks_submit_run_operator_1",
                    previewAllowed: true,
                    field_workflow_config_mapping: {},
                    field_var_mapping: [],
                    extraData: {}
                }
            ];
            dagPostWorkflowInfo= {...dagPostWorkflowInfo,workspaceType:enabledWorkspace?.workspaceType,max_active_runs:values?.max_active_runs||"0",catchup:values?.catchup||false};
            if(!isDagEnv){
                if(isEmpty(values?.cluster) || values?.cluster==="choose_cluster"){
                    infoAlert("Please select a cluster");
                    setIsJobScheduled(false);
                    return;
                }
                const workflow_config_data:any  = [{
                    [dataBricksOperatorId]:
                        dagPostWorkflowInfo.workflow_config
                    
                }];
 
                dagPostWorkflowInfo = {...dagPostWorkflowInfo,links:[],workflow_config: {data: workflow_config_data||[],isNArgs: false},nodes:nodes}
            }
           
            if(dagPostWorkflowInfo) {
                infoAlert('Scheduling workflow');
                dispatch(saveActiveWorkflow(()=>WorkflowHandler.ScheduleDagWorkflow(dagPostWorkflowInfo, () => {
                    successAlert('Successfully saved pipeline');
                    successAlert('Scheduled successfully');
                    dispatch(updateTabDagRhsFormValues({ reset: true }));
                    setCanvasdagFormInitialValues({...canvasdagFormInitialValues,cluster:values?.cluster||"",dag_id:uuid()})
                    setIsJobScheduled(false)
                },(err)=>{
                    errorHandlerFor400(err);
                    setIsJobScheduled(false);
                }), false, 'workflowEditor'));
            } else {
                errorAlert("Failed to schedule workflow");
                setIsJobScheduled(false)
            }
        }
    },
    onCancel: () => {
        setIsJobScheduled(false);
    },
    okText: 'Yes',
     cancelText: 'Cancel',
     className:'confirmModal',
})
} else {
    if(isEmptyWorkflow){
        errorAlert("Empty workflow");
        setIsJobScheduled(false);
        return;
    }

        const startTime = moment(activeTabInfo?.scheduleInfoForDag?.startDate, "YYYY,M,D,H,m");

        if(activeTabInfo?.scheduleInfoForDag?.scheduleTimingInfo === ScheduleTiming["Run Once"] &&
            !(startTime.isValid() && startTime.isSameOrBefore(moment()))) {
            return errorAlert("'Run Once', the schedule date should be greater than current time");
        }
        let dagPostWorkflowInfo:any = getDagWorkflowInfo(dispatch);
        const dataBricksOperatorId = "0099f361-f877-4600-85f4-58b7445bc143";
        const nodes = [
            {
                id: dataBricksOperatorId,
                fn_id: "DatabricksSubmitRunOperator",
                fnName: "DatabricksSubmitRunOperator",
                title: "Databricks Operator",
                inputPorts: [],
                outputPorts: [
                    {
                        id: "4eb20487-942d-48b9-aea0-7b32d94c58c4",
                        type: "OUT-0",
                        link: [],
                        parentNode: dataBricksOperatorId
                    }
                ],
                data: [
                    {
                        key: "task_id",
                        value: "test1_628bcc55-0af1-4ddf-8bc0-84edff90b7d7",
                        arg_index: 1,
                        variable_type: "string",
                        useKey: true
                    },
                    {
                        key: "existing_workflow",
                        value: true,
                        arg_index: 6,
                        variable_type: "boolean",
                        useKey: true
                    },
                    {
                        key: "workflow_id",
                        value: activeTabInfo?.id,
                        arg_index: 2,
                        variable_type: "integer",
                        useKey: true
                    },
                    {
                        key: "databricks_conn_id",
                        value: enabledWorkspace?.workspaceName,
                        arg_index: 3,
                        variable_type: "string",
                        useKey: true
                    },
                    {
                        key: "notebook_task",
                        value: "ssh_spark",
                        arg_index: 4,
                        variable_type: "string",
                        useKey: true
                    },
                    {
                        key: "existing_cluster_id",
                        value: values?.cluster,
                        arg_index: 5,
                        variable_type: "string",
                        useKey: true
                    },
                    {
                        key: "base_parameters",
                        value: "",
                        arg_index: 8,
                        variable_type: "string",
                        useKey: true
                    },
                    {
                        key: "databrick_file_path",
                        value: "",
                        arg_index: 7,
                        variable_type: "string",
                        useKey: true
                    }
                ],
                type: "component",
                df_name: "databricks_submit_run_operator_1",
                previewAllowed: true,
                field_workflow_config_mapping: {},
                field_var_mapping: [],
                extraData: {}
            }
        ];
        dagPostWorkflowInfo= {...dagPostWorkflowInfo,workspaceType:enabledWorkspace?.workspaceType,max_active_runs:values?.max_active_runs||"0",catchup:values?.catchup||false};
        if(!isDagEnv){
            if(isEmpty(values?.cluster) || values?.cluster==="choose_cluster"){
                infoAlert("Please select a cluster");
                setIsJobScheduled(false);
                return;
            }
            const workflow_config_data:any  = [{
                [dataBricksOperatorId]:
                    dagPostWorkflowInfo.workflow_config
                
            }];

            dagPostWorkflowInfo = {...dagPostWorkflowInfo,links:[],workflow_config: {data: workflow_config_data||[],isNArgs: false},nodes:nodes}
        }
       
        if(dagPostWorkflowInfo) {
            infoAlert('Scheduling workflow');
            dispatch(saveActiveWorkflow(()=>WorkflowHandler.ScheduleDagWorkflow(dagPostWorkflowInfo, () => {
                successAlert('Successfully saved pipeline');
                successAlert('Scheduled successfully');
                dispatch(updateTabDagRhsFormValues({ reset: true }));
                setCanvasdagFormInitialValues({...canvasdagFormInitialValues,cluster:values?.cluster||"",dag_id:uuid()})
                setIsJobScheduled(false)
            },(err)=>{
                errorHandlerFor400(err);
                setIsJobScheduled(false);
            }), false, 'workflowEditor'));
        } else {
            errorAlert("Failed to schedule workflow");
            setIsJobScheduled(false)
        }
}
};
 
    useEffect(() => {
        if(isDagEnv) {
            if(!activeTabInfo?.scheduleInfoForDag) {
                dispatch(updateTabDagRhsFormValues({ reset: true }));
            }
        } else if(activeTabInfo?.config.length) {
            if(!has(activeTabInfo.config[0], 'id')) {
                // For old workflow config items
                dispatch(workflowActionHandler({ type: "ADD_IDS_TO_WORKFLOW_CONFIG_ITEMS_OF_ACTIVE_TAB" }))
            }
            if(activeTabInfo.config.some(config => 
                config.type === WorkflowConfigItemType.Number)
            ) {
                // For old workflow config items
                dispatch(workflowActionHandler({ type: "CONVERT_NUMBER_TYPE_TO_INT_OR_DECIMAL" }))
            }  
        } 
    }, [activeTabInfo, isDagEnv]);

    const switchActiveView = (index: activeViewTabIndexes) => {
        if(index !== 0 && !activeComponentPropertiesInfo.isSaved) {
            dispatch(closeActiveForm());
            return;
        }
        if(index !== 1 && !activeComponentPropertiesInfo.isNotesSaved ) {
            dispatch(closeActiveComponentNotes());
            return;
        }
        setActiveViewIndex(index);
    };

    const [commonKeysInfoForGlobalArguments, setCommonKeysInfoForGlobalArguments] = useState<CommonKeysInfoForGlobalArguments>([])

    const handleRouteOptimisationModal = () => {
        setIsRouteOptimisationModalOpen(false)
    }

    const onWorkflowConfigUpdate = useCallback((config: WorkflowConfig) => {
        if(activeTabInfo) {
            dispatch(workflowActionHandler({
                type: "UPDATE_WORKFLOW_CONFIG_MAP_IN_COMPONENTS",
                payload: config
            }))

            activeTabInfo.config = config;
            dispatch(updateAWorkflowEditorTabInfo({ 
                type: getTabType('workflowEditor', activeTabInfo.env) , 
                info: activeTabInfo 
            }))
            // Optimise this function
            dispatch(setCurrentWorkflowStateToUnsaved());
            dispatch(saveCurrentWorkflowTabState());
        }
    }, [activeTabInfo])

    useEffect(() => {
        if(isDagEnv && activeViewIndex === 1 && activeTabInfo?.id)  {
            // setTimeout is used as it takes workflow canvas to get updated with latest workflow info
            setTimeout(() => {
                const {
                    globalArgumentsConfig,
                    commonKeysInfoForGlobalArguments
                } = getWorkflowConfigOfAllComponents(WorkflowCanvas, activeTabInfo?.config);
                
                if(!isEqual(globalArgumentsConfig, activeTabInfo?.config)) {
                    onWorkflowConfigUpdate(globalArgumentsConfig)
                }

                setCommonKeysInfoForGlobalArguments(commonKeysInfoForGlobalArguments)
            }, 100)
        } else {
            setCommonKeysInfoForGlobalArguments([])
        }
    }, [activeViewIndex, isDagEnv, activeComponentInfo, activeTabInfo?.config, onWorkflowConfigUpdate])

    return (
		<div
			className={classNames("properties__container", {
				"properties__container--minimized": isPropertiesMinimized,
			})}
		>
			<UserDetails hideNameOnMinimize={true} />
			<div
				className={classNames("treeview__header properties__header", {
					hide: isPropertiesMinimized,
				})}
			>
				<span
					onClick={() => switchActiveView(0)}
					className={classnames("switchable__tab", {
						"switchable__tab--active": activeViewIndex === 0,
					})}
				>
					{activeComponentInfo ? 
						"PROPERTIES"
                        :
                        isDagEnv ? "SCHEDULE SETTINGS" : "DETAILS"
                    }
				</span>
				<span
					onClick={() => switchActiveView(1)}
					className={classnames("switchable__tab", {
						"switchable__tab--active": activeViewIndex === 1,
					})}
				>
					{activeComponentInfo ?
                        "NOTES"
                        :
                        isDagEnv ? "GLOBAL ARGUMENTS":"CONFIGURATION"
                    }
				</span>
                {!isDagEnv && !activeComponentInfo && Env.enableScheduleSettings &&<span
					onClick={() => switchActiveView(2)}
					className={classnames("switchable__tab", {
						"switchable__tab--active": activeViewIndex === 2,
					})}
				>
					SCHEDULE SETTINGS
				</span>}
				{/* {Env.showPropertyJSONUpload && <UploadJSONForDevelopment/>} */}
			</div>
			<div className={"properties__body"}>
				{isPropertiesMinimized ? (
					<div className="properties__tab">
						<div className="tabs__separator" />
						<ShowWhenTrue show={!activeTabInfo?.showCodeEditor}>
							<Tooltip
								placement="left"
								title={"Component Properties"}
							>
								<span
									className="tab__item"
									onClick={handlePropertiesToggleClick}
								>
									<PropertiesIcon active={false} />
								</span>
							</Tooltip>
						</ShowWhenTrue>
					</div>
				) : activeViewIndex === 1 ? (
					activeTabInfo?.id && activeComponentInfo ? (
                        <ShowWhenTrue show={(!activeWorkflowInfo || activeWorkflowInfo.env !== activeExecutionEnv)?false:true}>
						<ComponentNote/>
                        </ShowWhenTrue>
					) : activeTabInfo && (
                        <ShowWhenTrue show={(!activeWorkflowInfo || activeWorkflowInfo.env !== activeExecutionEnv)?false:true}>
						<WorkflowConfigTab 
                            config={activeTabInfo.config || []} 
                            onWorkflowConfigUpdate={onWorkflowConfigUpdate}
                            resetParamsProp={activeTabInfo.id}
                            type={isDagEnv ? "GLOBAL_ARGUMENTS": "CONFIGURATION"}
                            commonKeysInfoForGlobalArguments={isDagEnv ? commonKeysInfoForGlobalArguments: undefined}
                            showImportCsv={!isDagEnv}
                            workflowName={activeTabInfo.name}
                        />
                        </ShowWhenTrue>
					)
				) : activeComponentPropertiesInfo.show &&
				  activeComponentInfo &&
				  !!activeTabInfo ? (
                     <ShowWhenTrue show={(!activeWorkflowInfo || activeWorkflowInfo.env !== activeExecutionEnv)?false:true}>
                        <ShowWhenTrue show={!isRouteOptimisationComponent}>
                            <FormCreator
                                formData={activeComponentInfoFormDetails}
                                componentInfo={activeComponentInfo.componentInfo}
                                onSubmit={handleFormSubmit}
                                extraData={activeComponentInfo.extraData}
                                uniqueId={activeComponentInfo.id}
                                isReadOnly={activeTabInfo?.isReadOnly}
                                activeTabInfo={activeTabInfo}
                            />
                        </ShowWhenTrue>
                        <ShowWhenTrue show={isRouteOptimisationComponent}>
                            <Flex display={"flex"} flexDirection={"column"}>
                                <div className='componentMetaData'>
                                    {activeComponentInfo?.componentInfo[0]?.defaultValue}
                                    <RefreshComponentInProperties />
                                </div>
                                <div className={"configure-route-button"}>
                                    <button
                                        className="btn_configure_pipeline"
                                        onClick={() => setIsRouteOptimisationModalOpen(true)}
                                        type="button"
                                    >
                                        Configure Route Optimization
                                    </button>
                                </div>
                            </Flex>
                            <Modal
                                title={<Text fontSize="16px" color="white">Route Optimization</Text>}
                                isOpen={isRouteOptimisationModalOpen}
                                className="RouteOptimisationModal"
                                showCloseMark={true} 
                                toggleClose={handleRouteOptimisationModal}	
                                shouldCloseOnOverlayClick={false}
                            >
                                <FormCreator
                                    formData={activeComponentInfoFormDetails}
                                    componentInfo={activeComponentInfo.componentInfo}
                                    onSubmit={handleFormSubmit}
                                    extraData={activeComponentInfo.extraData}
                                    uniqueId={activeComponentInfo.id}
                                    isReadOnly={activeTabInfo?.isReadOnly}
                                    activeTabInfo={activeTabInfo}
                                    isRouteOptimisationComponent={isRouteOptimisationComponent}
                                />
                            </Modal>
                        </ShowWhenTrue>
                     </ShowWhenTrue>
				) : isDagEnv && activeTabInfo?.id ? (
                    activeTabInfo.scheduleInfoForDag && !isEmpty(activeTabInfo.scheduleInfoForDag) &&
					<SchedulePipelinesForm
						dagFormInitialValues={activeTabInfo.scheduleInfoForDag}
						handleScheduleDagWorkflow={handleScheduleDagWorkflow}
						handleScheduleRhsFormSubmit={
							handleScheduleRhsFormSubmit
						}
                        isJobScheduled = {isJobScheduled}
                        isDagEnv = {isDagEnv}
					/>
				) : activeTabInfo && activeViewIndex === 0 ?
                <ShowWhenTrue show={(!activeWorkflowInfo || activeWorkflowInfo.env !== activeExecutionEnv)?false:true}>
                    <WorkflowDetailsTab workflowInfo={activeTabInfo} />
                    </ShowWhenTrue>
                    : activeTabInfo && activeViewIndex === 2 && 
                    <ShowWhenTrue show={(!activeWorkflowInfo || activeWorkflowInfo.env !== activeExecutionEnv)?false:true}>
                        <SchedulePipelinesForm
                            dagFormInitialValues={canvasdagFormInitialValues}
                            handleScheduleDagWorkflow={handleScheduleDagWorkflow}
                            handleScheduleRhsFormSubmit={
                                handleScheduleRhsFormSubmit
                            }
                            isJobScheduled = {isJobScheduled}
                            isDagEnv = {isDagEnv}
                        />
                        </ShowWhenTrue>
				}
			</div>
			<ShowWhenTrue show={!activeTabInfo?.showCodeEditor}>
				<PropertiesToggle
					className={classNames("properties__toggle", {
						reverse__img: !isPropertiesMinimized,
					})}
					onClick={handlePropertiesToggleClick}
				/>
			</ShowWhenTrue>
		</div>
	);
};