import { Middleware } from 'redux';
import { CLOSE_CANVAS_TAB, OPEN_A_NEW_CANVAS_TAB, PageTypes, SWITCH_TO_EXISTING_CANVAS_TAB } from './canvas';
import { setLastActiveWorkflowInfo } from './common';
import { RootState } from './types';

export const middlewareToSaveActiveWorkflowInfo: Middleware<{}, RootState> = store => next => action => {
    const result = next(action);
    if(action.type === OPEN_A_NEW_CANVAS_TAB || action.type === SWITCH_TO_EXISTING_CANVAS_TAB || action.type === CLOSE_CANVAS_TAB) {
        const { CommonReducer, CanvasReducer } = store.getState();
        const page = action.page as PageTypes;
        const  { activeTab, openTabs } = CanvasReducer[page];
        const activeTabInfo = openTabs.get(activeTab.id);
        if(activeTabInfo?.info.env === CommonReducer.activeExecutionEnv) {
            store.dispatch(setLastActiveWorkflowInfo(page, CommonReducer.activeExecutionEnv, activeTab.id || null)); 
        }
    }
    return result;
};