import { cloneDeep } from 'lodash';
import { RESET_STATE } from '../types';
import { TagBrowserReducerState, ActionTypes, TOGGLE_MODAL, ADD_NEW_SYNC_SET_INFO } from './types';

const initialState: TagBrowserReducerState = {
    showModal: {
        editPlantSite: false,
        editSyncSets: false,
        deleteAction: false
    },
    newSyncSetInfo: { id: 0, key: ''}
};

export const TagBrowserReducer = (state = initialState, action: ActionTypes): TagBrowserReducerState => {
    switch(action.type) {
        case TOGGLE_MODAL:
            return { ...state, showModal: { ...state.showModal, ...action.payload } };
        case ADD_NEW_SYNC_SET_INFO:
            return { ...state, newSyncSetInfo: action.payload };
        case RESET_STATE:
            return cloneDeep(initialState);
        default:
            return state;        
    }

};