import { Portal } from "@components/use-portal";
import { ShowWhenTrue } from "@helpers/showwhentrue";
import React from "react";

type Props = {
    title: string;
    showPrompt: boolean;
    onCancel: () => void;
    onDiscard: () => void;
}

const LoseUnSavedChangesPromptPlotModal: React.FC<Props> = ({ title, showPrompt, onCancel, onDiscard }) => {
    return(
        <ShowWhenTrue show={showPrompt}>
            <div className="loseUnSavedChangesbgOverlay"/>
            <Portal
                className="loseUnsavedChangesPortalBox"
            >
                <div
                    className="loseUnsavedFormChangesInPlotSelection  loseUnsavedFormChangesBox"
                >
                    <p className="">
                    Do you want to discard the changes applied to {title}?
                    </p>

                    <div className="modalBtns__box">
                        <button
                            type="button"
                            onClick={onCancel}
                            className="btn-md btn-transparent"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={onDiscard}
                            className="btn-md btn-yellow"
                        >
                            Yes, Discard
                        </button>
                    </div>
                </div>

            </Portal>
        </ShowWhenTrue>
    )
}

export default LoseUnSavedChangesPromptPlotModal;