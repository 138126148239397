import React, { useState, useRef, useMemo } from 'react';
import TreeMenu, { TreeNodeInArray } from 'react-simple-tree-menu';
import classNames from 'classnames';
import { useDidMount, useDidUpdate } from 'rooks';
import { ItemComponent, Item } from './treeview-item';
import { useDispatch, useSelector } from 'react-redux';
import { ClientSiteInfo, TagsHandler, SyncSetInfo, CreateSyncSetInfo, SyncSetsResponse } from '../../../api/tags-handler';
import { toggleTagBrowserModal } from '../../../store/tag-browser';
import { NewEditPlantSite, NewEditPlantSiteProps } from '../modals/new-edit-plant-site';
import { ContextMenuComponent } from '../../../components/context-menu';
import { NewSyncSetModal, addSyncSetSchema } from '../modals/new-sync-set';
import { DeleteSiteSyncSetModal, DeleteSiteSyncSetModalProps } from '../modals/delete-syncset-site';
import { RootState } from '../../../store/types';
import { infoAlert } from '../../../components/toastify/notify-toast';


type CustomTreeNodeInArray = TreeNodeInArray & { id?: number | string};

interface Props {
    // handleRetrieveSiteTags: (arg0: number) => any;
    handleRetrieveSyncSetTags: (arg0: Partial<SyncSetInfo>) => any;
    selectedSiteName: string;
    selectedSyncSetName: string;
    handleShowSyncSetCreationMode: (siteInfo: ClientSiteInfo, syncSetInfo: CreateSyncSetInfo) => any;
    // setSyncSetToBeCreatedInfo: React.Dispatch<React.SetStateAction<CreateSyncSetInfo>>
}


export const SitesTreeview: React.FC<Props> = ({ handleShowSyncSetCreationMode, selectedSiteName, selectedSyncSetName, handleRetrieveSyncSetTags }) => {
    const [searchInput, setSearchInput] = useState('');
    const [dataForTreeview, setDataForTreeview] = useState<CustomTreeNodeInArray[]>([{key: 'sites', label: 'Sites', nodes: [] }]);
    const dispatch = useDispatch();
    const [plantActionType, setPlantActionType] = useState<NewEditPlantSiteProps['actionType']>('new');
    const contextMenuRef = useRef<ContextMenuComponent>(null);
    const [activeContextMenuData, setActiveContextMenuData] = useState<Record<any, any>>({});
    const newSyncSetInfo = useSelector((store: RootState) => store.TagBrowserReducer.newSyncSetInfo);

    
    const handleTreeViewSearch = (searchFunc: any, searchString: string) => {
        searchFunc && searchFunc(searchString);
        setSearchInput(searchString);
    };

    const getClientSitesList = () => {
        TagsHandler.GetClientSitesList()
            .then(response => {
                const dataForTreeviewClone = [...dataForTreeview];
                dataForTreeviewClone[0].nodes = response.data.map(_site => ({ label: _site.siteName,  key: 'sites'+_site.id.toString(), isOpen: false, nodes: [{label: 'Loading...',  key: 'Loading'+_site.id.toString()}], ..._site }));
                setDataForTreeview(dataForTreeviewClone);
            });
    };

    useDidMount(() => {
        getClientSitesList();
    });

    const handleGetSyncSetsSuccess = (siteKey: string, response: SyncSetsResponse) => {
        const dataForTreeviewClone = [...dataForTreeview];
        dataForTreeviewClone[0].nodes = dataForTreeviewClone[0].nodes?.map(siteInfo => {
            if(siteInfo.key === siteKey) {
                if(response.length !== 0) {
                    siteInfo.nodes = response.map(syncSetInfo => ({ label: syncSetInfo.name,  key: 'syncSet'+ syncSetInfo.syncId.toString(), nodes: [], ...syncSetInfo }));
                } else {
                    siteInfo.nodes = [{label: 'No Data found',  key: 'noDataFound'+ siteKey}];
                }
            }
            return siteInfo;
        });
        setDataForTreeview(dataForTreeviewClone);
    };

    function getSyncSets(this: any, siteId: number, key: string) {
        TagsHandler.GetSyncSets(siteId, handleGetSyncSetsSuccess.bind(this, key));
    }

    function handleItemClick(props: ClientSiteInfo & Item){
        if(props.level === 1) {
            getSyncSets(props.id, props.key.split('/')[1]);
            // handleRetrieveSiteTags(props.id);
        } else if(props.level === 2 && !props.key.includes('noDataFound') && !props.key.includes('Loading')){
            handleRetrieveSyncSetTags({ syncId: props.syncId, name: props.label, clientId: props.id });
        }
    }

    useDidUpdate(() => {
        getSyncSets(newSyncSetInfo.id, newSyncSetInfo.key);
    }, [newSyncSetInfo]);

    const onSyncSetCreation = (values: addSyncSetSchema) => {
        const syncSetData: CreateSyncSetInfo = { ...values, clientId: activeContextMenuData.id, tagIds: [] };
        handleShowSyncSetCreationMode(activeContextMenuData as unknown as ClientSiteInfo, syncSetData);
    };

    const itemInfoForDeleteModal: DeleteSiteSyncSetModalProps['itemInfo'] = useMemo(() => {
        if(activeContextMenuData.level === 2) return { id: activeContextMenuData.syncId, name: activeContextMenuData.label, type: 'syncset'};
        return { id: activeContextMenuData.id, name: activeContextMenuData.label, type: 'site'};
    }, [activeContextMenuData]);
    

    const handleDeleteSuccess = () => {
        const dataForTreeviewClone = [...dataForTreeview];
        if(itemInfoForDeleteModal.type === 'site') {
            // client site is deleted
            dataForTreeviewClone[0].nodes = dataForTreeviewClone[0].nodes?.filter(_clientSite => _clientSite.label !== activeContextMenuData.label);
        } else {
            // sync site is deleted
            dataForTreeviewClone[0].nodes = dataForTreeviewClone[0].nodes?.filter(_clientSite => {
                if(_clientSite.id === activeContextMenuData.clientSite.id) {
                    if(_clientSite.nodes?.length === 1) {
                        _clientSite.nodes = [{label: 'No Data found',  key: 'noDataFound'+ _clientSite.id}];
                    } else {
                        _clientSite.nodes = _clientSite.nodes?.filter(_syncset => _syncset.label !== activeContextMenuData.label);
                    }
                }

                return _clientSite;
                
                
            });
        }
        setDataForTreeview(dataForTreeviewClone);

    };

    const handleRefreshData = (clientSiteInfo: ClientSiteInfo) => {
        const dataForTreeviewClone = [...dataForTreeview];
        const __clientSite = { label: clientSiteInfo.siteName,  key: 'sites'+clientSiteInfo.id.toString(), isOpen: false, nodes: [{label: 'Loading...',  key: 'Loading'+clientSiteInfo.id.toString()}], ...clientSiteInfo };
        dataForTreeviewClone[0].nodes?.push(__clientSite);
        setDataForTreeview(dataForTreeviewClone);
    };

   
    return(
        <div className="connections__treeview">
            <TreeMenu
                data={dataForTreeview}
                // matchSearch={(({ label, searchTerm, ...other }) => {
                //     if (other.is_component) {
                //         return label.toLowerCase().includes(searchTerm.toLowerCase());
                //     } return false;
                // })}
                onClickItem={handleItemClick as any}
                hasSearch
            >
                {({ search, items, searchTerm }) => (
                    <>
                        <div className="search__input__container">
                            <input
                                onChange={e => handleTreeViewSearch(search, e.target.value)}
                                className="search_input"
                                placeholder="Try 'table'"
                                value={searchInput}
                            />
                            {!!(searchTerm) && <img src="/icons/treeview/search-cross.svg" alt="" className="search_reset" onClick={() => handleTreeViewSearch(search, '')} />}
                        </div>
                        <div className="newBtn__box">
                            <button 
                                className="btn-sm btn-yellow-transparent btn__new__action" 
                                onClick={() => dispatch(toggleTagBrowserModal('editPlantSite', true))}
                            >
                                <img src="/icons/workflow/add.svg" alt=""/> New
                            </button>
                        </div>
                        <div 
                            className="treeview__list"
                        >
                            {items.map(({ key, ...props }) => (
                                <>
                                    <div
                                        className={classNames('treeitem__container', 
                                            { 'treeitem__heading': props.level === 0 }, 
                                            { 'treeitem__info': props.level === 1 },
                                            { 'treeitem__syncset': props.level === 2 },
                                            {'activeItem': (props.level === 1 && selectedSiteName === props.label) || (props.level === 2 && selectedSyncSetName === props.label) },
                                        )}
                                        key={key + 1}
                                        onContextMenu={(e) => {
                                            if(props.level !== 0 && !key.includes('noDataFound') && !key.includes('Loading')) {
                                                setActiveContextMenuData(props as any);
                                                contextMenuRef.current?.showContextMenu(e);
                                            } else {
                                                e.preventDefault();
                                            }
                                        }}
                                    >
                                        <ItemComponent
                                            key={key}
                                            className='treeview__item'
                                            {...props}
                                        />
                                    </div>
                                </>
                            ))}
                            <ContextMenuComponent
                                ref={contextMenuRef}
                                className="canvasContextMenu"
                            >
                                {activeContextMenuData.level === 1 &&
                                    <>
                                        <button
                                            onClick={() => dispatch(toggleTagBrowserModal('editSyncSets', true))}
                                        >
                                            New Sync Set
                                        </button>
                                        <button
                                            onClick={() => infoAlert('Tags are being refreshed.')}
                                        >
                                            Refresh
                                        </button>
                                    </>
                                }
                                <button
                                    onClick={() => setPlantActionType('edit')}
                                >
                                    Edit
                                </button>
                                <button
                                    onClick={() => dispatch(toggleTagBrowserModal('deleteAction', true))}    
                                >
                                    Delete
                                </button>
                            </ContextMenuComponent>
                        </div>
                    </>
                )}
            </TreeMenu>
            <NewEditPlantSite 
                actionType={plantActionType}
                refreshClientList={handleRefreshData}
            />
            <NewSyncSetModal 
                actionType={plantActionType}
                onCreateSuccess={onSyncSetCreation}
                serversList={activeContextMenuData.siteServers || []}
            />
            <DeleteSiteSyncSetModal
                itemInfo={itemInfoForDeleteModal}
                handleDeleteSuccess={handleDeleteSuccess}
            />
        </div>
    );
};