import { GlobeIcon } from "@assets/icons";
import Form, { InputField, NewSelectField } from "@components/form";
import { Modal } from "@components/modals";
import { TooltipTop } from "@components/tooltips";
import { DeleteFilledIcon } from "@pages/workflow-analytics-page/assets/icons";
import {
	getGlobalValueInfoForConfigItem,
	WorkflowConfigItemType,
} from "@services/WorkflowConfig";
import { WorkflowConfig } from "@services/WorkflowConfig";
import {
	getRequiredFieldSchema,
	getRequiredNumberFieldSchema,
} from "@utils/common";
import classNames from "classnames";
import { ArrayHelpers, FieldArray, useFormikContext } from "formik";
import { capitalize, get,  min } from "lodash";
import range from "lodash/range";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
	CellMeasurerCache,
	Column,
	GridCellProps,
	MultiGrid,
	ScrollSync,
	Table,
	TableCellProps,
} from "react-virtualized";
import { useFreshRef } from "rooks5";
import { SmallAddWhiteIcon } from "../icons";
import { booleanOptions } from "../ml-pipeline/enums";
import { FieldSchemaValidator } from "../schema-creator";
import { WorkflowConfigSelectionProps } from "./workflowConfigSelection";

type globalPipelineConfigType = Pick<
	WorkflowConfigSelectionProps,
	"globalPipelineConfig"
>;

type WorkflowNArgsModalProps = globalPipelineConfigType & {
	isOpen: boolean;
	toggleClose: () => void;
	fieldKey: string;
	config: WorkflowConfig<"component">;
	onWorkflowNArgsUpdate: (
		nArgsConfig: WorkflowConfig<"component">[],
		comments: string[]
	) => void;
	nArgsConfig: WorkflowConfig<"component">[];
	comments: string[];
};

type WorkflowNArgsConfigForm = {
	_nArgsConfig: WorkflowConfig<"component">[];
	_comments: string[];
};

type WorkflowNArgsFormProps = globalPipelineConfigType &
	Pick<WorkflowNArgsModalProps, "config">;

const NARGS_CONFIG_KEY = "_nArgsConfig";
const COMMENTS_CONFIG_KEY = "_comments";
const COLUMN_WIDTH = 106;
const HEADER_HEIGHT = 48;
const COLUMN_HEIGHT = 52;
const COMMENT_COLUMN_WIDTH = 254;
const INDEX_COLUMN_WIDTH = 90;

const cache = new CellMeasurerCache({
	defaultWidth: COLUMN_WIDTH,
	defaultHeight: COLUMN_HEIGHT,
	fixedHeight: false,
});

const requiredStringField = getRequiredFieldSchema("This");
const requiredNumberField = getRequiredNumberFieldSchema("This");

const fieldSchemaMapping: Partial<Record<WorkflowConfigItemType, any>> = {
	[WorkflowConfigItemType.String]: requiredStringField,
	[WorkflowConfigItemType.Integer]: requiredNumberField.integer(
		"Must be a Integer"
	),
	[WorkflowConfigItemType.Number]: requiredNumberField,
	[WorkflowConfigItemType.Decimal]: requiredNumberField,
};

const renderGlobalValue = (value: string | number | boolean) => {
	if (typeof value === "boolean") {
		return capitalize(JSON.stringify(value));
	}
	return value;
};

const WorkflowNArgsForm: React.FC<WorkflowNArgsFormProps> = ({
	config,
	globalPipelineConfig,
}) => {
	const { values, setFieldValue } = useFormikContext<
		WorkflowNArgsConfigForm
	>();
	const commentsRef = useFreshRef(values._comments);
	const scrollContainerRef = useRef<Table>(null);

	const noOfNArgs = values?._nArgsConfig?.length || 1;
	const noOfArgKeys = config.length;

	const updateGlobalArgumentSelection = (
		rowIndex: number,
		fieldKeyIndex: number
	) => {
		const _actualKey = `${NARGS_CONFIG_KEY}[${rowIndex}][${fieldKeyIndex}].usesGlobalValue`;
		const currentSelection = get(values, _actualKey);
		setFieldValue(_actualKey, !currentSelection);
	};

	const handleAddNewComponentFieldGroup = (arrayHelpers: ArrayHelpers) => {
		const tableElementRef = document.getElementsByClassName(
			"commentTable__scrollable"
		)?.[0];

		arrayHelpers.insert(noOfNArgs, config);
		setTimeout(() => {
			if (tableElementRef) {
				// To scroll newly added row into view
				tableElementRef.scrollTop = tableElementRef.scrollHeight;
			}
		}, 1);
	};

	const height = min([
		COLUMN_HEIGHT * (noOfNArgs + 1),
		COLUMN_HEIGHT * 9,
	]) as number;
	const width =
		COLUMN_WIDTH * noOfArgKeys + COMMENT_COLUMN_WIDTH + INDEX_COLUMN_WIDTH;

	useLayoutEffect(() => {
		range(0, values?._nArgsConfig?.length + 1).forEach((rowIndex) => {
			// To set width of first column
			const rowHeight = rowIndex !== 0 ? COLUMN_HEIGHT : HEADER_HEIGHT;
			cache.set(rowIndex, 0, INDEX_COLUMN_WIDTH, rowHeight);
			config.forEach((_, columnIndex) => {
				// set width of input fields
				cache.set(rowIndex, columnIndex + 1, COLUMN_WIDTH, rowHeight);
				gridRef.current?.recomputeGridSize({
					columnIndex,
					rowIndex,
				});
			});
		});
	}, [config, values._nArgsConfig]);

	const handleDeleteRow = (arrayHelpers: ArrayHelpers, rowIndex: number) => {
		setFieldValue(
			COMMENTS_CONFIG_KEY,
			commentsRef.current?.filter((_, idx) => idx + 1 !== rowIndex)
		);
		arrayHelpers.remove(rowIndex - 1);
	};

	const onCellRenderer = (
		arrayHelpers: ArrayHelpers,
		{ columnIndex, rowIndex, style }: GridCellProps
	) => {
		const argumentInfo = config[columnIndex - 1];
		const fieldDataType = [
			WorkflowConfigItemType.Integer,
			WorkflowConfigItemType.Decimal,
		].includes(argumentInfo?.type)
			? "number"
			: "text";
		const fieldSchema = argumentInfo?.type
			? fieldSchemaMapping[argumentInfo.type]
			: undefined;

		const key = rowIndex + "_" + columnIndex;
		if (rowIndex === 0) {
			return (
				<div key={key} style={style} className="argumentHeaderColumn">
					{columnIndex === 0 ? (
						<>
							<span className="argumentKey">Arguments</span>
							<br />
							<span className="argumentType">Data type</span>
						</>
					) : argumentInfo ? (
						<>
							<span className="argumentKey">
								{argumentInfo.key}
							</span>
							<br />
							<span className="argumentType">
								{argumentInfo.type}
							</span>
						</>
					) : (
						<span className="argumentKey">Notes</span>
					)}
				</div>
			);
		} else {
			const currentRow = rowIndex - 1;
			const currentColumnIndex = columnIndex - 1;

			let globalValueInfo: ReturnType<typeof getGlobalValueInfoForConfigItem> = {
				globalValue: false,
				hasGlobalValue: "" as any,
				isGloballySelected: false,
			};

			if (argumentInfo) {
				globalValueInfo = getGlobalValueInfoForConfigItem(
					globalPipelineConfig || {},
					values._nArgsConfig[currentRow][currentColumnIndex]
				);
			}
			const {
				globalValue,
				hasGlobalValue,
				isGloballySelected,
			} = globalValueInfo;

			const isBooleanValue =
				argumentInfo?.type === WorkflowConfigItemType.Boolean;

			return (
				<div key={key} style={style}>
					{columnIndex === 0 ? (
						<div className="argsIndex__Column">
							<span>{rowIndex}</span>
							<button
								onClick={handleDeleteRow.bind(
									null,
									arrayHelpers,
									rowIndex
								)}
								type="button"
								disabled={noOfNArgs === 1}
							>
								<DeleteFilledIcon />
							</button>
						</div>
					) : (
						<div
							className={classNames("argument__inputFields", {
								globalValueMode: hasGlobalValue,
								globallySelectedValue: isGloballySelected,
								isBoolean: isBooleanValue,
							})}
						>
							{isGloballySelected ? (
								<TooltipTop title={globalValue}>
									<div className="globalValue">
										{renderGlobalValue(globalValue as any)}
									</div>
								</TooltipTop>
							) : isBooleanValue ? (
								<>
									<NewSelectField
										name={`${NARGS_CONFIG_KEY}[${currentRow}].[${currentColumnIndex}].value`}
										options={booleanOptions}
										usePortal
									/>
								</>
							) : (
								<InputField
									name={`${NARGS_CONFIG_KEY}[${currentRow}].[${currentColumnIndex}].value`}
									type={fieldDataType}
									validate={FieldSchemaValidator(
										fieldSchema as any
									)}
								/>
							)}
							{hasGlobalValue && (
								<TooltipTop
									title={`Click to assign ${
										isGloballySelected ? "local" : "global"
									} value`}
								>
									<button
										onClick={updateGlobalArgumentSelection.bind(
											null,
											currentRow,
											currentColumnIndex
										)}
										type="button"
										className="globalIconBtn"
									>
										<GlobeIcon
											active={isGloballySelected}
										/>
									</button>
								</TooltipTop>
							)}
						</div>
					)}
				</div>
			);
		}
	};

	const commentFieldRenderer = ({ rowIndex }: TableCellProps) => {
		return (
			<div className="argument__inputFields commentField">
				<InputField name={`${COMMENTS_CONFIG_KEY}[${rowIndex}]`} />
			</div>
		);
	};

	const gridRef = useRef<MultiGrid>(null);

	const rowCount = noOfNArgs + 1;

	const headerRenderer = () => {
		return (
			<div
				style={{ height: HEADER_HEIGHT }}
				className="argumentHeaderColumn"
			>
				<span className="argumentKey">Notes</span>
			</div>
		);
	};

	return (
		<FieldArray
			// refer to WorkflowFieldSelectionObj
			name={NARGS_CONFIG_KEY}
			render={(arrayHelpers) => {
				return (
					<div className="nArgsContainer">
						<div className="innerTableContent">
							<ScrollSync>
								{({ scrollTop, onScroll }) => (
									<div
										style={{
											display: "flex",
											width:
												noOfArgKeys > 5
													? 578 + COMMENT_COLUMN_WIDTH
													: width,
										}}
									>
										<div
											className="argumentHeaderBg"
											style={{ height: HEADER_HEIGHT }}
										/>
										<MultiGrid
											rowHeight={cache.rowHeight}
											rowCount={rowCount}
											height={height}
											width={
												noOfArgKeys > 5 ? 578 : width
											}
											columnWidth={cache.columnWidth}
											fixedRowCount={1}
											cellRenderer={onCellRenderer.bind(
												null,
												arrayHelpers
											)}
											scrollTop={scrollTop}
											columnCount={noOfArgKeys + 1}
											onScroll={onScroll}
											classNameBottomRightGrid="argumentsTable"
											className="argumentsTable"
											ref={gridRef}
											deferredMeasurementCache={cache}
											overscanColumnCount={6}
										/>
										<Table
											height={height}
											rowHeight={COLUMN_HEIGHT}
											rowCount={rowCount - 1}
											width={COMMENT_COLUMN_WIDTH}
											headerHeight={HEADER_HEIGHT}
											rowGetter={() => {
												return {};
											}}
											scrollTop={scrollTop}
											className="commentTable"
											onScroll={onScroll as any}
											gridClassName="commentTable__scrollable"
											ref={scrollContainerRef}
										>
											<Column
												dataKey="Comment"
												width={COMMENT_COLUMN_WIDTH}
												cellRenderer={
													commentFieldRenderer
												}
												headerRenderer={headerRenderer}
											/>
										</Table>
									</div>
								)}
							</ScrollSync>
						</div>

						<button
							onClick={handleAddNewComponentFieldGroup.bind(
								null,
								arrayHelpers
							)}
							className="btn__addComponentRow"
							type="button"
						>
							<SmallAddWhiteIcon /> Add Argument
						</button>
					</div>
				);
			}}
		/>
	);
};

const WorkflowNArgsModal: React.FC<WorkflowNArgsModalProps> = ({
	isOpen,
	toggleClose,
	config,
	onWorkflowNArgsUpdate,
	nArgsConfig,
	comments,
	globalPipelineConfig,
}) => {
	const [workflowNArgsConfig, setWorkflowNArgsConfig] = useState<
		WorkflowNArgsConfigForm
	>({ _nArgsConfig: [], _comments: [] });

	useEffect(() => {
		if (isOpen) {
			setWorkflowNArgsConfig({
				_nArgsConfig: nArgsConfig,
				_comments: comments,
			});
		}
	}, [isOpen, nArgsConfig, comments]);

	const onSubmit = (values: WorkflowNArgsConfigForm) => {
		toggleClose();
		onWorkflowNArgsUpdate(values._nArgsConfig, values._comments);
	};

	return (
		<Modal
			isOpen={isOpen}
			toggleClose={toggleClose}
			title="Edit Multiple Arguments Configuration"
			className="workflowNArgsModal"
		>
			<Form
				initialValues={workflowNArgsConfig}
				onSubmit={onSubmit}
				enableReinitialize
			>
				<WorkflowNArgsForm
					config={config}
					globalPipelineConfig={globalPipelineConfig}
				/>

				<div className="modalBtns__box">
					<button type="submit" className="btn-md btn-yellow">
						Save
					</button>
					<button
						type="button"
						className="btn-md btn-cancel"
						onClick={toggleClose}
					>
						Cancel
					</button>
				</div>
			</Form>
		</Modal>
	);
};

export default WorkflowNArgsModal;
