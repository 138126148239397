import createEngine, { 
    DiagramModel, 
    DiagramEngine,
} from '@projectstorm/react-diagrams';
import { SimplePortFactory } from './port/ds_simpleportfactory';
import { DsNodeFactory } from './node/ds_nodefactory';
import { DsNodeModel, DsNodeExtras, DsNodeModelOptions } from './node/ds_nodemodel';
import { DsLinkFactory } from './link/ds_linkfactory';
import { isEmpty } from 'lodash';
import { DefaultDiagramState } from './diagram-state/default-state';
import { DsLinkModel } from './link/ds_linkmodel';
import { CustomDeleteItemsAction } from './custom-actions/delete-action';
import { errorAlert } from '../toastify/notify-toast';
import { ExecutionEnvModes } from '../../constants/enums';
import { serializeWorkflowModel } from './utils';
import { WorkflowDependenciesRef } from '@pages/workflow-page/utils';
import { workflowRunData } from '@pages/workflow-page';


export type WorkflowMetaData = {
    env: ExecutionEnvModes;
    config: string;
    workflowDependenciesRef: WorkflowDependenciesRef;
    workflowsInfo: Record<number, { details: SerializedData, payload: string }>
    workflowName: string;
}

export type WorkflowSerializedObjData = ReturnType<DiagramModel['serialize']> & { workflowMetaData: WorkflowMetaData };
export type SerializedData =  string | WorkflowSerializedObjData;

export interface CreateNodeProps {
    title: string; 
    actualTitle: string;
    subtitle: string; 
    iconKey: string;
    isPropertiesTouched: boolean;
    isPropertiesValid: boolean; 
    isPropertiesSavedAtleastOnce: boolean;
    numberOfInputPorts: number; 
    numberOfOutputPorts: number; 
    posx: number; 
    posy: number; 
    details: DsNodeExtras;
    nodeType: DsNodeModelOptions['nodeType'];
    dfName: string;
    hash: DsNodeModelOptions['hash'];
    notes: string;
    customComponentId: number | undefined;
}

class WorkflowCanvasClass {
    engine: DiagramEngine;
    model: DiagramModel;
    setCurrentWorkflowStateToUnsaved: (() => any) | undefined;
    // DO NOT FORGET TO INITIALIZE MODEL WITH STATECHANGELISTENER
    constructor(){
        this.engine = createEngine({ registerDefaultDeleteItemsAction: false });
        this.model = new DiagramModel();
        this.engine.setModel(this.model);
        this.engine.setMaxNumberPointsPerLink(0);
        this.engine
            .getPortFactories()
            .registerFactory(new SimplePortFactory());
        this.engine
            .getNodeFactories().registerFactory(new DsNodeFactory());
        this.engine
            .getLinkFactories().registerFactory(new DsLinkFactory());
     
        // DefaultDiagramState
        // REMOVE THIS LINE AND ENTIRE DIAGRAM-STATE FOLDER IF https://github.com/projectstorm/react-diagrams/pull/668 
        // IS MERGED WITH THE MASTER - THIS IS CAUSING A isSelected: null issue in visualizations 
        // After a modal is opened by selecting a node in the canvas in visualizations, when canvas is panned, it crashes
        // lines 25 and 26 in src\components\workflow-canvas\diagram-state\move-items-state.ts solve this issue.
        this.engine.getStateMachine().pushState(new DefaultDiagramState());

        // this.engine.getActionEventBus().registerAction(new DeleteItemsAction({ keyCodes: [46], modifiers: { ctrlKey: true } }));
        this.engine.getActionEventBus().registerAction(new CustomDeleteItemsAction());
        // this.testingMode();
    }

    // testingMode(){
    //     this.deSerializeModel(testData as any);
    // }

    initializeModelWithStateChangeListener(_listener: () => any){
        this.setCurrentWorkflowStateToUnsaved = _listener;
        this.model = this.createNewModel();
        this.engine.setModel(this.model);
    }

    private addPorts(node: DsNodeModel, noOfPorts: number, methodToAddPort: 'addInPort' | 'addOutPort') {
        if(noOfPorts > 0) {
            let i = 1;
            for(i;i<=noOfPorts;i++){
                // ports indexing starts from zero
                node[methodToAddPort](i-1);
            }
        }
    }

    handleCanvasDrag(e: any) {
        const { offsetX, offsetY } = e;
        const backgroundRef = document.getElementsByClassName('canvas')[0] as HTMLDivElement;
        backgroundRef.style.backgroundPosition = `${offsetX}px ${offsetY}px`;

        // const zoomLevel = this.model.getZoomLevel();
        // const zoomAdjusmtent = (zoomLevel / 100);
        // backgroundRef.style.width = `${100 / zoomAdjusmtent}%`;
        // backgroundRef.style.height = `${100 / zoomAdjusmtent }%`;
        // const backgroundScale = `scale(${zoomAdjusmtent})`;
        // backgroundRef.style.transform = backgroundScale;

    }

    private createNewModel(){
        const _model = new DiagramModel();
        _model.registerListener({
            nodesUpdated: () => this.setCurrentWorkflowStateToUnsaved && this.setCurrentWorkflowStateToUnsaved(),
            linksUpdated: () => this.setCurrentWorkflowStateToUnsaved &&this.setCurrentWorkflowStateToUnsaved(),
            offsetUpdated: (e) => {
                this.handleCanvasDrag(e);
                this.setCurrentWorkflowStateToUnsaved && this.setCurrentWorkflowStateToUnsaved();
            },
        });
        return _model;
    }


    addInPort(node: DsNodeModel) {
        const inPortsLength = node.portsIn.length;
        if(inPortsLength < 7){
            // only 7 ports can be added
            node.addInPort(inPortsLength);
            this.repaintCanvas();
        }
    }

    addOutPort(node: DsNodeModel) {
        const outPortsLength = node.portsOut.length;
        if(outPortsLength < 7){
            // only 7 ports can be added
            node.addOutPort(outPortsLength);
            this.repaintCanvas();
        }
    }
 

    createNode({ title, subtitle, iconKey, isPropertiesValid, isPropertiesTouched, isPropertiesSavedAtleastOnce, posx, posy, details, numberOfInputPorts, numberOfOutputPorts, nodeType, actualTitle, dfName, hash, notes, customComponentId = undefined }: CreateNodeProps): DsNodeModel {
        const node = new DsNodeModel({ title, subtitle, actualTitle, iconKey, isPropertiesTouched, isPropertiesSavedAtleastOnce, isPropertiesValid, nodeType, isMounted: false, dfName, hash, notes, customComponentId });
        node.setPosition(posx, posy);
        node.extras = details;
       
        this.addPorts(node, numberOfInputPorts, 'addInPort');
        this.addPorts(node, numberOfOutputPorts, 'addOutPort');
        return node;
    }

    addNodeToModel(node: DsNodeModel): void{
        this.model.addNode(node);
    }

    resetWorkflow = (): void => {
        this.model = this.createNewModel();
        this.engine.setModel(this.model);
        this.repaintCanvas();
    }

    repaintCanvas(): void;
    repaintCanvas(promise: true): Promise<any>;
    repaintCanvas(promise: true | undefined = undefined): Promise<any> | void{
        if(promise)
            return this.engine.repaintCanvas(promise);
        else 
            this.engine.repaintCanvas();                
    }

    serializeModel = (workflowMetaData: WorkflowMetaData): string => {
        // const __workflowData: SerializedData = {...this.model.serialize(), workflowMetaData };
        return serializeWorkflowModel(this.model, workflowMetaData);
    };

    deSerializeModel = (serialized_data: SerializedData): boolean => {
        try {
            let __serialized_data: ReturnType<DiagramModel['serialize']>;
            if(typeof(serialized_data) === 'object'){
                __serialized_data = serialized_data;
            } else {
                __serialized_data = JSON.parse(serialized_data);
            }
            const _model = this.createNewModel();
            _model.deserializeModel(__serialized_data, this.engine);
            this.engine.setModel(_model);
            this.model = _model;
            this.repaintCanvas();
            return true;
        } catch(e) {
            /* eslint-disable no-console */
            console.log(e);
            errorAlert('Unable to load workflow');
            return false;
        }
        
    }

    clearSelection = () => {
        this.model.clearSelection();
    }

    getNode = (id: string) => {
        return this.model.getActiveNodeLayer().getModel(id) as undefined | DsNodeModel;
    }

    getLink = (id: string) => {
        return this.model.getActiveLinkLayer().getModel(id) as DsLinkModel;
    }

    getAllNodes = () => {
        // getAllNodesArray doesnt return the latest nodes list 
        return this.model.getActiveNodeLayer().getModels() as {[id: string]: DsNodeModel};
    }

    getAllLinks = () => {
        return this.model.getActiveLinkLayer().getLinks();
    }

    areAllLinksConnected = (): boolean => {
        const _links = Object.values(this.getAllLinks());
        if(isEmpty(_links)){
            return true;
        }
        return !_links.some(_link => {
            return !_link.getTargetPort();
        });
    }


    getNodeTitle = (id: string) => {
        const nodeInfo = this.getNode(id) as DsNodeModel;
        if(nodeInfo) return nodeInfo.getOptions().title;
        return '';
    }

}


const WorkflowCanvas = new WorkflowCanvasClass();
export { WorkflowCanvas };
