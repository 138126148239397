import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { omit, isEmpty, debounce } from 'lodash';
import { InputField } from '.';
import { JobCloseWorkflowIcon } from '../../pages/workflow-page/assets/icons';
import { uuid } from 'uuidv4';
import classNames from 'classnames';

interface Props {
    keyToSaveKeyValueInfo: string;
    addButtonText: string;
    disabled?: boolean;
}

export const DynamicKeyValueFieldGenerator: React.FC<Props> = ({ keyToSaveKeyValueInfo = 'additionalConfig', addButtonText = '+ Add Additional Configurations', disabled = false }) => {
    // Works only in formik context
    // adds two input fields - key and value; 
    // updates formikContext with { keyToSaveKeyValueInfo: { key: value }} 
    // Example: { additionalConfig: { "default.spark.memory": "10G" }} 

    const { values, setValues, setFieldValue, initialValues } = useFormikContext<any>();
    const [addedFieldIds, setAddedFieldIds] = useState<string[]>([]);

    
    useEffect(() => {
        if(initialValues[keyToSaveKeyValueInfo]) {
            const __initialFieldIds: string[] = [];
            // const __initialValues: Record<string, any> = {};
            
            Object.entries(initialValues[keyToSaveKeyValueInfo]).map(([key, value]) => {
                const _id = uuid();
                __initialFieldIds.push(_id);
                // __initialValues[_id] = { key, value };
                setFieldValue(_id,{ key, value });
            });
            setAddedFieldIds(__initialFieldIds);
            // setValues({ ...values, ...__initialValues});
            
           
        }
    }, [initialValues]);

    const handleAddConfigFields = () => {
        const _id = uuid();
        setAddedFieldIds([ ...addedFieldIds, _id]);
    };

    const addValuesToFormikContext = (debounce(()=>{
        if(!isEmpty(addedFieldIds)){
            const additionalConfig: Record<string, string> = {};
            addedFieldIds.map(_id => {
                const _configInfo = values[_id];
                if(_configInfo) additionalConfig[_configInfo.key] = _configInfo.value;
            });
            setFieldValue(keyToSaveKeyValueInfo, additionalConfig);
        }
    }, 500));

    const handleRemoveConfigField = (idToBeRemoved: string) => {
        const __updatedConfigFields = [ ...addedFieldIds].filter(id => id !== idToBeRemoved);
        setAddedFieldIds(__updatedConfigFields);
        if(values[idToBeRemoved]) {
            const configKey = values[idToBeRemoved].key;
            const __values = omit(values, idToBeRemoved) as Record<string, any>;
            __values[keyToSaveKeyValueInfo] = omit(__values[keyToSaveKeyValueInfo], configKey);
            setValues(__values as any);
        }
    };

    return(
        <div 
            className={classNames('dynamicKeyValueGenBox', { disabled })}
        >
            <button 
                type="button"
                onClick={handleAddConfigFields}
                className="addButton"
                disabled={disabled}
            >
                {addButtonText}
            </button>
            {addedFieldIds.map(_id => (
                <div 
                    className="fieldsRow"
                    key={_id}
                >
                    <InputField 
                        name={`${_id}.key`}
                        label="key"
                        onChange={addValuesToFormikContext}
                    />
                    <InputField 
                        name={`${_id}.value`}
                        label="value"
                        onChange={addValuesToFormikContext}
                    />
                    <button
                        type="button"
                        onClick={() => handleRemoveConfigField(_id)}
                    >
                        <JobCloseWorkflowIcon />
                    </button>
                </div>
            ))}
        </div>
    );
};