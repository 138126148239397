import { JobInfo } from "@api/job-handler";
import { RootState } from "@store/types";
import { closeActiveForm, closeActiveComponentNotes } from "@store/workflow";
import { useDispatch, useSelector } from "react-redux";
import JobItem from "./JobItem"
import React from "react";

type JobItemListProps = {
    toggleJobStatusPanel: React.Dispatch<React.SetStateAction<boolean>>;
    jobs: JobInfo[];
    showJobLog: (job: JobInfo) => any;
    refreshJobTimeInfo: boolean;
}

const JobItemList: React.FC<JobItemListProps> = ({ jobs, toggleJobStatusPanel, showJobLog, refreshJobTimeInfo }) => {
    const {isSaved: isActiveComponentFormSaved, isNotesSaved} = useSelector(
        (store: RootState) =>
            store.WorkflowReducer.activeComponentPropertiesInfo
    );
    const dispatch = useDispatch();
    
    const checkIfActiveComponentFormIsSaved = () => {
        if (!isActiveComponentFormSaved) {
            dispatch(closeActiveForm());
        }
        return isActiveComponentFormSaved;
    };

    const checkIfActiveComponentNotesIsSaved = () => {
        if (!isNotesSaved) {
            dispatch(closeActiveComponentNotes());
        }
        return isNotesSaved;
    };


    return(
        <>
            {jobs.map(job => (
                <JobItem
                    job={job}
                    key={job.id}
                    toggleJobStatusPanel={toggleJobStatusPanel}
                    checkIfActiveComponentFormIsSaved={
                        checkIfActiveComponentFormIsSaved
                    }
                    checkIfActiveComponentNotesIsSaved={
                        checkIfActiveComponentNotesIsSaved
                    }
                    showJobLog={showJobLog}
                    refreshJobTimeInfo={refreshJobTimeInfo}
                />
            ))}
        </>
    )
}

export default JobItemList;