import { has, omit } from "lodash";
import xml2js from "xml2js";
import { SoapWsdlObject } from "./types";

const stripPrefix = xml2js.processors.stripPrefix;

const parser = new xml2js.Parser({
	trim: true,
	tagNameProcessors: [stripPrefix],
});

function convertArrayToDict(arr: any[]): Record<string, any> {
	return arr.reduce((_dict, item) => {
		_dict[item.$.name] = item;
		return _dict;
	}, {});
}

function getHeadersFromOperation(operation: Record<string, any>) {
	return omit(operation[0].operation[0].$, "style");
}

function getBody(
	body: any,
	input: any,
	simpleTypeDict: Record<string, any>,
	complexTypeDict: Record<string, any>
) {
	// input -> Element
	// Every element has
	// complex Type -> sequence and element
	// element has type -> Using type, Check if element is complexType or simpleType
	// Complex - call same function
	// Simple -> build the body
	// Assume if its complexType -> Access first element of complexType and first element of sequence

	const tagName = input.$._tagName || input.$.name;

	body[tagName] = {};

	const sequence = input.complexType
		? input.complexType[0].sequence[0]
		: input.sequence[0];

	sequence.element.forEach((element: { $: { name: any; type: string } }) => {
		const innerTagName = element.$.name;
		const type = stripPrefix(element.$.type);
		if (complexTypeDict[type]) {
			const complexTypeId = stripPrefix(element.$.type);

			if (complexTypeDict[complexTypeId]) {
				const innerComplexType = complexTypeDict[complexTypeId];
				// Update the tagname
				innerComplexType.$._tagName = innerTagName;
				body[tagName] = getBody(
					body[tagName],
					innerComplexType,
					simpleTypeDict,
					complexTypeDict
				);
			}
		} else {
			body[tagName][innerTagName] = {
				_: ".",
			};
		}
	});

	return body;
}

const buildSoapXmlUsingObject = (object: Record<string, any>) => {
	const builder = new xml2js.Builder();
	return builder.buildObject(object);
};

const parseSoapWsdl = (data = ""): Promise<SoapWsdlObject> => {
	return new Promise((resolve, reject) => {
		parser.parseString(data, function(err, result) {
			if (has(result, "definitions")) {
				const serviceObj: SoapWsdlObject = {};
				const _res = result["definitions"];

				const [
					simpleTypes,
					complexTypes,
					elementTypes,
				] = _res.types[0].schema.reduce(
					(acc: [any, any, any], schema: { [x: string]: any }) => {
						let [_simpleTypes, _complexTypes, _elementTypes] = acc;
						if (schema["simpleType"]) {
							_simpleTypes = [
								..._simpleTypes,
								...schema["simpleType"],
							];
						}
						if (schema["complexType"]) {
							_complexTypes = [
								..._complexTypes,
								...schema["complexType"],
							];
						}

						if (schema["element"]) {
							_elementTypes = [
								..._elementTypes,
								...schema["element"],
							];
						}
						return [_simpleTypes, _complexTypes, _elementTypes];
					},
					[[] as any, [] as any, [] as any]
				);

				const simpleTypeDict = convertArrayToDict(simpleTypes);

				const complexTypeDict = convertArrayToDict(complexTypes);
				const elementDict = convertArrayToDict(elementTypes);

				_res["service"].forEach((service: any) => {
					const serviceName = service.$.name;

					serviceObj[serviceName] = service["port"].map(
						(port: any) => {
							const bindingName = stripPrefix(
								port["$"]["binding"]
							);
							const endpointUrl = port.address[0].$.location;
							const inputTypes: any[] = [];
							let headers: Record<string, string> = {};
							_res["binding"].forEach((binding: any) => {
								if (binding.$.name === bindingName) {
									if (has(binding, "operation")) {
										headers = getHeadersFromOperation(
											binding["operation"]
										);
									}
									const bindingType = stripPrefix(
										binding.$.type
									);

									_res["portType"].forEach(
										(portType: any) => {
											if (
												portType.$.name === bindingType
											) {
												const inputType = stripPrefix(
													portType["operation"][0][
														"input"
													][0].$.message
												);

												// Find element using inputType
												const body = getBody(
													{},
													elementDict[inputType],
													simpleTypeDict,
													complexTypeDict
												);
												inputTypes.push({
													body,
													inputType,
												});
											}
										}
									);
								}
							});
							return {
								binding: bindingName,
								inputTypes,
								endpointUrl,
								headers,
							};
						}
					);
				});
				resolve(serviceObj);
			} else {
				reject();
			}
		});
	});
};

export { parseSoapWsdl, buildSoapXmlUsingObject };
