export const SETUP_CLUSTERS = 'SETUP_CLUSTERS';
export const SETUP_FILE_BROWSER = 'SETUP_FILE_BROWSER';
export const CLUSTER_CREATION_COUCHMARK = 'CLUSTER_CREATION_COUCHMARK';
export const FILEBROWSER_CREATION_COUCHMARK = 'FILEBROWSER_CREATION_COUCHMARK';
export const USER_STATUS_COUCHMARK = 'USER_STATUS_COUCHMARK';
export const START_CLUSTER_COUCHMARK = 'START_CLUSTER_COUCHMARK';
export const START_FILE_BROWSER_COUCHMARK = 'START_FILE_BROWSER_COUCHMARK';
export const CLUSTER_COUCHMARK_STATUS = 'CLUSTER_COUCHMARK_STATUS';
export const FILE_BROWSER_COUCHMARK_STATUS = 'FILE_BROWSER_COUCHMARK_STATUS';
export const START_ADMIN_COUCHMARK = 'START_ADMIN_COUCHMARK';
export const SHOW_WORKFLOW_HELP = 'SHOW_WORKFLOW_HELP';
export const HIDE_WORKFLOW_HELP = 'HIDE_WORKFLOW_HELP';

