import React, { useMemo, useRef, useState } from 'react';
// @ts-ignore
// import baseTiff from './base.tif';
// @ts-ignore
// import topTiff from './top.tif';
import './styles.scss';
import 'leaflet/dist/leaflet.css';
import { BandType, ColorModes, ColorRamps } from '../workflow-analytics-page/enums';
import { RasterPlot } from '../workflow-analytics-page/Canvas/raster-plot';
import { PlotData, PlotSelectionFormInfo } from '../workflow-analytics-page/Canvas';
import { uuid } from 'uuidv4';
// import { LeafletEventHandlerFnMap, Map as MapType } from 'leaflet';
// import { MapContainer, TileLayer } from 'react-leaflet';

export const LoadTiff = () => {
    const divRef = useRef<HTMLDivElement>(null);
    

    const [showSpinner, toggleSpinner] = useState(true);


    const { plotSelectionFormInfo, plotData, tiffColorPlotInfo } = useMemo(() => {
        const plotSelectionFormInfo: PlotSelectionFormInfo = {
            _id: 1,
            additional_params: {},
            aggregate_type: "",
            connectedComponentInfo: {
                component_id: '',
                target_component_id: '',
                output: ''
            },
            plot_type: 'raster',
            bandType: BandType.Single,
            projection_code: 'None',
            xAxis: 'lat',
            yAxis: ['lon'],
            groupByColumns: ['Till_gc_Co'],
            graphIdentifier: 'c5c1ad69-409e-4f06-95ec-c357ad21c39f',
            multiBandCols: {"blue":[],"red":[],"green":[]},
            resolution:"",
            mode: "default",
            min_lat: "",
            max_lat: "",
            min_lon: "",
            max_lon: "",
            custom_projection_code: ''
        }

        const baseTiffId = uuid();
        const topTiffId = uuid();

        const plotData: PlotData = {
            plotType: 'raster',
            plotData: {},
            x: '',
            y: [],
            latitude: null,
            longitude: null,
            tiffFilePath: {
                // [baseTiffId]: baseTiff,
                // [topTiffId]: topTiff
            },
            raster_info: {
                raster_id: "",
                raster_configs: [
                    {
                        id: baseTiffId,
                        singleband: true,
                        latitude: 'lat',
                        longitude: 'lon',
                        y: ['Till_gc_Co'],
                        resolution:"",
                        mode: "default",
                        min_lat: "",
                        max_lat: "",
                        min_lon: "",
                        max_lon: "",
                    },
                    // {
                    //     id: topTiffId,
                    //     singleband: true,
                    //     latitude: 'lat',
                    //     longitude: 'lon',
                    //     y: ['Col232']
                    // },
                ]
            }
        }

        const tiffColorPlotInfo = {
            [baseTiffId]: {
                startColor: '',
                endColor: '',
                colorRamp: ColorRamps.Grey,
                invertColors: false,
                noOfClasses: 0,
                colorMode: ColorModes.Continous,
                alpha: 1,
                hidden: false
            },
            [topTiffId]: {
                startColor: '',
                endColor: '',
                colorRamp: ColorRamps.Spectral,
                invertColors: false,
                noOfClasses: 0,
                colorMode: ColorModes.Continous,
                alpha: 0.5,
                hidden: false
            }
        }

        return { plotSelectionFormInfo, plotData, tiffColorPlotInfo }
    }, [])


    
    return (
        <section className="map_outer" ref={divRef}>
            <div style={{ width: 750, height: 600, margin: 100 }}>
            <RasterPlot 
                    rasterPlotsInfo={plotData.raster_info || null}
                    tiffFilePath={plotData.tiffFilePath}
                    isFullScreenActive={false}
                    isSpinnerActive={showSpinner}
                    tiffColorPlotInfo={tiffColorPlotInfo}
                    handleToggleSpinner={(e) => toggleSpinner(e === 'show')}
                    graphId={plotSelectionFormInfo.graphIdentifier as string}
                    plotSelectionFormInfo={plotSelectionFormInfo} showPlotSelectionModal={function (): void {
                        // throw new Error('Function not implemented.');
                    } } showColorPicker={function (): void {
                        // throw new Error('Function not implemented.');
                    } } deleteRaster={function (): void {
                        // throw new Error('Function not implemented.');
                    } } handleToggleVisibility={function (): void {
                        // throw new Error('Function not implemented.');
                    } } handleShowRasterError={function (): void {
                        // throw new Error('Function not implemented.');
                    } }
                    isErrorState={false} switchToFullScreen={function (): void {
                        // throw new Error('Function not implemented.');
                    } }    
                    isDataExplorer={false}
                    />
            </div>
                
                {/* <div id='map1' className='raster-leaflet-map'></div> */}
        
            {/* <canvas id="canvas" width={500} height={500} /> */}
            {/* <MapContainer
                center={[0, 0]}
                zoom={2}
                id="map"
                minZoom={2}
                whenCreated={initializeMap}
            >
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    maxZoom={19}
                />
            </MapContainer> */}
        </section>
    );
};
