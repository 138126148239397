import React from 'react';
import './styles.scss';
import { HelpTabRenderer } from '../workflow-page/help-renderer/help-console-tab-renderer';
import { useHistory } from 'react-router-dom';

const HelpPage: React.FC = () => {
    const history = useHistory();
    return(
        <section
            className="helpPage__section"
        >
            <button
                onClick={() => history.goBack()}
                className="btn-goBack"
            >
                Go Back
            </button>
            <HelpTabRenderer />
        </section>
    );
};

export default HelpPage;