import { DefaultLinkFactory } from '@projectstorm/react-diagrams';
import { DsLinkModel } from './ds_linkmodel';
import { DsLinkSegment } from './ds_linksegment';
import { DS } from '../enums';
import React from 'react';

export class DsLinkFactory extends DefaultLinkFactory {
    constructor() {
        super(DS.name);
    }

    generateModel(): DsLinkModel {
        return new DsLinkModel();
    }

    generateLinkSegment(model: DsLinkModel, selected: boolean, path: string) {
        return ( <DsLinkSegment model={model} selected={selected} path={path} />);
    }

}