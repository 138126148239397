import * as yup from 'yup';
import { keyValuePairValidator } from './helper';

export const TERMINATE = 'terminate';
export const SPOT_INSTANCES = 'spotInstances';
export const SPOT_FALL_BACK_TO_OD = 'spotFallbackToOD';
export const AUTOSCALE = 'autoscale';

export const SPARKCONFIG_SEPARATOR = ' ';
export const ENVVARIABLES_SEPARATOR = '=';
export type SEPARATOR_TYPE = typeof SPARKCONFIG_SEPARATOR | typeof ENVVARIABLES_SEPARATOR;
export const accessNodeText=`Single user:
Run SQL, Python, R and Scala workloads as a single user, with access to data secured in Unity Catalog.

Shared:
Multiple users can share the cluster to run SQL, Python and Scala workloads on data secured in Unity Catalog.

No isolation shared:
Multiple users can share the cluster to run workloads in any language, with no isolation between users.`;

export const usersListText=`When a user runs a command on a cluster with Single user access mode enabled, that user's Azure Active Directory credentials are passed automatically to Spark, allowing the user to access data in Azure Data Lake Storage (ADLS) without 5.0) having to specify their credentials manually. ADLS Gen1 requires Databricks Runtime 5.1+. ADLS Gen2 requires 5.3+.`;

export const baseFormSchemaForClusterForm = yup.object().shape({
    name: yup.string().required().label('Name'),
    enableAutoScaling: yup.string().
        default(''),
    terminate: yup.string().
        default(''),
    minutesOfInactivity: yup.number().
        default(0).
        when('terminate', {
            is: TERMINATE,
            then: yup.number().required().min(10, 'Has to be atleast 10 mins')
        }),
    minWorkers: yup.number(),
    maxWorkers: yup.number().
        when('enableAutoScaling', {
            is: AUTOSCALE,
            then: yup.number().required().label('Max Workers').moreThan(yup.ref('minWorkers'), 'Should be more than Min Workers')
        }),
    workerType: yup.string().
        when('clusterModeId', {
            is: (val) => val !== '1',
            then: yup.string().required().label('Worker Type'),
            otherwise: yup.string()
        }),
    driverType: yup.string().
        when('clusterModeId', {
            is: (val) => val !== '1',
            then: yup.string(). required().label('Driver Type'),
            otherwise: yup.string()
        }),
    spotInstances: yup.string().
        default(''),
    noOfWorkers: yup.number().
        when('enableAutoScaling', {
            is: AUTOSCALE,
            otherwise: yup.number().when('clusterModeId', {
                is: (val) => val === '1',
                then: yup.number(),
                otherwise:  yup.number().required().moreThan(0, 'There should be atleast 1 Worker')
            })
        }),
    sparkConfig: yup.string()
        .test('is_valid_sparkconfig_keyvaluepairs', 
            'Should be key value pairs', 
            value => keyValuePairValidator(value, SPARKCONFIG_SEPARATOR)
        ),
    sparkEnvVars: yup.string()
        .test('is_valid_envvars_keyvaluepairs', 
            'Should be key value pairs', 
            value => keyValuePairValidator(value, ENVVARIABLES_SEPARATOR)
        ),
    initScripts: yup.array(),
    npipWorkSpace: yup.boolean(),
    clusterModeId: yup.string(),
    dataSecurityMode: yup.string(),
    singleUserName: yup.string(),
    sparkRunTimeVersion: yup.string().required().label('Runtime version'),
    modelType: yup.string(),
    showDocker: yup.string(),
    dockerContainer: yup.string(),
    firstOnDemand:yup.number(),
    spotFallbackToOD: yup.string()
});

export type schemaTypes = yup.InferType<typeof baseFormSchemaForClusterForm>