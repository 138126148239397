/* eslint-disable react/no-unknown-property */

import React from 'react';

export const PlusIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.33334 10H16.6667" stroke="#C7C7CD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 3.33333V16.6667" stroke="#C7C7CD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
}

export const MinusIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.16666 10H15.8333" stroke="#C7C7CD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
}

export const ThreeDotIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 13C12.8284 13 13.5 12.3284 13.5 11.5C13.5 10.6716 12.8284 10 12 10C11.1716 10 10.5 10.6716 10.5 11.5C10.5 12.3284 11.1716 13 12 13Z" fill="#C7C7CD" />
            <path d="M12 7C12.8284 7 13.5 6.32843 13.5 5.5C13.5 4.67157 12.8284 4 12 4C11.1716 4 10.5 4.67157 10.5 5.5C10.5 6.32843 11.1716 7 12 7Z" fill="#C7C7CD" />
            <path d="M12 19C12.8284 19 13.5 18.3284 13.5 17.5C13.5 16.6716 12.8284 16 12 16C11.1716 16 10.5 16.6716 10.5 17.5C10.5 18.3284 11.1716 19 12 19Z" fill="#C7C7CD" />
        </svg>

    );
}

export const DataWorkflow = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5182_52197)">
                <path opacity="0.2" d="M20 21.0909C27.732 21.0909 34 17.672 34 13.4545C34 9.23709 27.732 5.81818 20 5.81818C12.268 5.81818 6 9.23709 6 13.4545C6 17.672 12.268 21.0909 20 21.0909Z" fill="#E25A1C" />
                <path d="M20 21.0909C27.732 21.0909 34 17.672 34 13.4545C34 9.23709 27.732 5.81818 20 5.81818C12.268 5.81818 6 9.23709 6 13.4545C6 17.672 12.268 21.0909 20 21.0909Z" stroke="#BDC2FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6 13.4547V21.091C6 25.3085 12.268 28.7274 20 28.7274C27.732 28.7274 34 25.3085 34 21.091V13.4547" stroke="#BDC2FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6 21.0909V28.7273C6 32.9447 12.268 36.3636 20 36.3636C27.732 36.3636 34 32.9447 34 28.7273V21.0909" stroke="#BDC2FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <rect x="26" y="30" width="18" height="14.3077" rx="2" fill="#212233" />
                <path d="M35.4567 33.0782H39.0986C39.6509 33.0782 40.0986 33.5259 40.0986 34.0782V36.212C40.0986 36.7643 39.6509 37.212 39.0986 37.212H33.2949C32.7426 37.212 32.2949 37.6597 32.2949 38.212V40.1061C32.2949 40.6584 32.7426 41.1061 33.2949 41.1061H35.8486" stroke="#E25A1C" strokeWidth="1.25" />
                <rect x="27.3487" y="31.1741" width="7.95105" height="4.21358" rx="1" stroke="#E25A1C" strokeWidth="1.25" />
                <rect x="36.3496" y="38.9993" width="7.95105" height="4.21358" rx="1" stroke="#E25A1C" strokeWidth="1.25" />
                <rect x="26" y="30" width="18" height="14.3077" rx="2" stroke="#212233" strokeWidth="2" />
            </g>
            <defs>
                <clipPath id="clip0_5182_52197">
                    <rect width="48" height="48" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const DataTable = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.2" d="M20 21.0909C27.732 21.0909 34 17.672 34 13.4545C34 9.23709 27.732 5.81818 20 5.81818C12.268 5.81818 6 9.23709 6 13.4545C6 17.672 12.268 21.0909 20 21.0909Z" fill="#58B2D6" />
            <path d="M20 21.0909C27.732 21.0909 34 17.672 34 13.4545C34 9.23709 27.732 5.81818 20 5.81818C12.268 5.81818 6 9.23709 6 13.4545C6 17.672 12.268 21.0909 20 21.0909Z" stroke="#BDC2FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 13.4547V21.091C6 25.3085 12.268 28.7274 20 28.7274C27.732 28.7274 34 25.3085 34 21.091V13.4547" stroke="#BDC2FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 21.0909V28.7273C6 32.9447 12.268 36.3636 20 36.3636C27.732 36.3636 34 32.9447 34 28.7273V21.0909" stroke="#BDC2FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M42.3846 31H27.6154C27.4522 31 27.2956 31.0648 27.1802 31.1802C27.0648 31.2956 27 31.4522 27 31.6154V42.0769C27 42.4033 27.1297 42.7164 27.3605 42.9472C27.5913 43.178 27.9043 43.3077 28.2308 43.3077H41.7692C42.0957 43.3077 42.4087 43.178 42.6395 42.9472C42.8703 42.7164 43 42.4033 43 42.0769V31.6154C43 31.4522 42.9352 31.2956 42.8198 31.1802C42.7044 31.0648 42.5478 31 42.3846 31ZM28.2308 35.9231H31.3077V38.3846H28.2308V35.9231ZM32.5385 35.9231H41.7692V38.3846H32.5385V35.9231ZM28.2308 39.6154H31.3077V42.0769H28.2308V39.6154ZM41.7692 42.0769H32.5385V39.6154H41.7692V42.0769Z" fill="#58B2D6" />
            <rect x="26" y="30" width="18" height="14.3077" rx="2" stroke="#212233" strokeWidth="2" />
        </svg>

    )
}

export const PauseTable = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5182_52318)">
                <path opacity="0.2" d="M10.5 19.5L10.5 4.5C10.5 4.08579 10.1642 3.75 9.75 3.75L6 3.75C5.58579 3.75 5.25 4.08579 5.25 4.5L5.25 19.5C5.25 19.9142 5.58579 20.25 6 20.25H9.75C10.1642 20.25 10.5 19.9142 10.5 19.5Z" fill="#BDC2FF" />
                <path opacity="0.2" d="M18.75 19.5V4.5C18.75 4.08579 18.4142 3.75 18 3.75L14.25 3.75C13.8358 3.75 13.5 4.08579 13.5 4.5V19.5C13.5 19.9142 13.8358 20.25 14.25 20.25H18C18.4142 20.25 18.75 19.9142 18.75 19.5Z" fill="#BDC2FF" />
                <path d="M10.5 19.5L10.5 4.5C10.5 4.08579 10.1642 3.75 9.75 3.75L6 3.75C5.58579 3.75 5.25 4.08579 5.25 4.5L5.25 19.5C5.25 19.9142 5.58579 20.25 6 20.25H9.75C10.1642 20.25 10.5 19.9142 10.5 19.5Z" stroke="#BDC2FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18.75 19.5V4.5C18.75 4.08579 18.4142 3.75 18 3.75L14.25 3.75C13.8358 3.75 13.5 4.08579 13.5 4.5V19.5C13.5 19.9142 13.8358 20.25 14.25 20.25H18C18.4142 20.25 18.75 19.9142 18.75 19.5Z" stroke="#BDC2FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_5182_52318">
                    <rect width="24" height="24" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
}

export const WarningCircleIcon = () => {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 1.5A6.5 6.5 0 1014.5 8 6.507 6.507 0 008 1.5zM7.5 5a.5.5 0 111 0v3.5a.5.5 0 01-1 0V5zm.5 6.5A.75.75 0 118 10a.75.75 0 010 1.5z"
                fill="#F76"
            />
        </svg>
    );
}

export const MetaDataIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" />
            <path d="M22.25 5.25V18.75C22.25 19.1478 22.0839 19.5294 21.7881 19.8107C21.4924 20.092 21.0913 20.25 20.6731 20.25H3.32692C2.9087 20.25 2.5076 20.092 2.21187 19.8107C1.91614 19.5294 1.75 19.1478 1.75 18.75V5.25C1.75 4.85218 1.91614 4.47064 2.21187 4.18934C2.5076 3.90804 2.9087 3.75 3.32692 3.75H20.6731C21.0913 3.75 21.4924 3.90804 21.7881 4.18934C22.0839 4.47064 22.25 4.85218 22.25 5.25ZM20.6731 18.75V5.25H3.32692V18.75H20.6731Z" fill="white" />
            <path d="M4.57034 11.6888C4.52397 11.7854 4.49993 11.8912 4.5 11.9983C4.49993 12.1054 4.52397 12.2112 4.57034 12.3078C4.6167 12.4044 4.6842 12.4893 4.76784 12.5563L8.33902 15.4132C8.4123 15.4719 8.4964 15.5155 8.58653 15.5416C8.67667 15.5677 8.77106 15.5758 8.86433 15.5655C8.95759 15.5551 9.04791 15.5265 9.13011 15.4812C9.21231 15.4359 9.28479 15.3749 9.34341 15.3016C9.40203 15.2284 9.44565 15.1443 9.47177 15.0541C9.49788 14.964 9.50599 14.8696 9.49563 14.7763C9.48527 14.6831 9.45664 14.5927 9.41137 14.5105C9.3661 14.4283 9.30509 14.3559 9.23181 14.2972L6.35791 11.9983L9.23181 9.69935C9.30509 9.64073 9.3661 9.56825 9.41137 9.48604C9.45664 9.40384 9.48527 9.31353 9.49563 9.22026C9.50599 9.127 9.49788 9.0326 9.47177 8.94247C9.44565 8.85234 9.40203 8.76823 9.34341 8.69496C9.28479 8.62168 9.21231 8.56066 9.13011 8.5154C9.04791 8.47013 8.95759 8.4415 8.86433 8.43114C8.77106 8.42078 8.67667 8.42888 8.58653 8.455C8.4964 8.48112 8.4123 8.52473 8.33902 8.58336L4.76784 11.4403C4.6842 11.5073 4.6167 11.5922 4.57034 11.6888Z" fill="white" />
            <path d="M19.4297 11.6888C19.476 11.7854 19.5001 11.8912 19.5 11.9983C19.5001 12.1054 19.476 12.2112 19.4297 12.3078C19.3833 12.4044 19.3158 12.4893 19.2322 12.5563L15.661 15.4132C15.5877 15.4719 15.5036 15.5155 15.4135 15.5416C15.3233 15.5677 15.2289 15.5758 15.1357 15.5655C15.0424 15.5551 14.9521 15.5265 14.8699 15.4812C14.7877 15.4359 14.7152 15.3749 14.6566 15.3016C14.598 15.2284 14.5544 15.1443 14.5282 15.0541C14.5021 14.964 14.494 14.8696 14.5044 14.7763C14.5147 14.6831 14.5434 14.5927 14.5886 14.5105C14.6339 14.4283 14.6949 14.3559 14.7682 14.2972L17.6421 11.9983L14.7682 9.69935C14.6949 9.64073 14.6339 9.56825 14.5886 9.48604C14.5434 9.40384 14.5147 9.31353 14.5044 9.22026C14.494 9.127 14.5021 9.0326 14.5282 8.94247C14.5544 8.85234 14.598 8.76823 14.6566 8.69496C14.7152 8.62168 14.7877 8.56066 14.8699 8.5154C14.9521 8.47013 15.0424 8.4415 15.1357 8.43114C15.2289 8.42078 15.3233 8.42888 15.4135 8.455C15.5036 8.48112 15.5877 8.52473 15.661 8.58336L19.2322 11.4403C19.3158 11.5073 19.3833 11.5922 19.4297 11.6888Z" fill="white" />
            <path d="M12.5 7.25L11.5 16.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
    )
}

export const ThumbUp = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.49951 8.125H6.24951V16.25H2.49951C2.33375 16.25 2.17478 16.1842 2.05757 16.0669C1.94036 15.9497 1.87451 15.7908 1.87451 15.625V8.75C1.87451 8.58424 1.94036 8.42527 2.05757 8.30806C2.17478 8.19085 2.33375 8.125 2.49951 8.125V8.125Z" stroke="#C7C7CD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.24951 8.125L9.37451 1.875C9.70282 1.875 10.0279 1.93966 10.3312 2.0653C10.6345 2.19094 10.9101 2.37509 11.1423 2.60723C11.3744 2.83938 11.5586 3.11498 11.6842 3.41829C11.8098 3.72161 11.8745 4.0467 11.8745 4.375V6.25H16.7085C16.8858 6.25 17.061 6.28769 17.2226 6.36058C17.3841 6.43346 17.5283 6.53987 17.6456 6.67275C17.7629 6.80562 17.8506 6.96192 17.9029 7.13128C17.9552 7.30063 17.9709 7.47917 17.9489 7.65504L17.0114 15.155C16.9736 15.4574 16.8267 15.7355 16.5983 15.9371C16.3699 16.1387 16.0757 16.25 15.771 16.25H6.24951" stroke="#C7C7CD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const ThumbDown = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.49951 3.75H6.24951V11.875H2.49951C2.33375 11.875 2.17478 11.8092 2.05757 11.6919C1.94036 11.5747 1.87451 11.4158 1.87451 11.25V4.375C1.87451 4.20924 1.94036 4.05027 2.05757 3.93306C2.17478 3.81585 2.33375 3.75 2.49951 3.75V3.75Z" stroke="#C7C7CD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.24951 11.875L9.37451 18.125C9.70282 18.125 10.0279 18.0603 10.3312 17.9347C10.6345 17.8091 10.9101 17.6249 11.1423 17.3928C11.3744 17.1606 11.5586 16.885 11.6842 16.5817C11.8098 16.2784 11.8745 15.9533 11.8745 15.625V13.75H16.7085C16.8858 13.75 17.061 13.7123 17.2226 13.6394C17.3841 13.5665 17.5283 13.4601 17.6456 13.3273C17.7629 13.1944 17.8506 13.0381 17.9029 12.8687C17.9552 12.6994 17.9709 12.5208 17.9489 12.345L17.0114 4.84496C16.9736 4.54264 16.8267 4.26453 16.5983 4.0629C16.3699 3.86127 16.0757 3.75 15.771 3.75H6.24951" stroke="#C7C7CD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const ContextEditor = () => {
    return (
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 0.5H1C0.801088 0.5 0.610322 0.579018 0.46967 0.71967C0.329018 0.860322 0.25 1.05109 0.25 1.25V14C0.25 14.3978 0.408035 14.7794 0.68934 15.0607C0.970644 15.342 1.35218 15.5 1.75 15.5H18.25C18.6478 15.5 19.0294 15.342 19.3107 15.0607C19.592 14.7794 19.75 14.3978 19.75 14V1.25C19.75 1.05109 19.671 0.860322 19.5303 0.71967C19.3897 0.579018 19.1989 0.5 19 0.5ZM1.75 6.5H5.5V9.5H1.75V6.5ZM7 6.5H18.25V9.5H7V6.5ZM1.75 11H5.5V14H1.75V11ZM18.25 14H7V11H18.25V14Z" fill="#BDC2FF" />
        </svg>
    )
}

export const EmptyPlot = () => {
    return (

        <svg width="122" height="79" viewBox="0 0 122 79" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.70459 10.5541C0.70459 7.79266 2.94317 5.55408 5.70459 5.55408H10.6215C13.383 5.55408 15.6215 7.79265 15.6215 10.5541V78.8258H0.70459V10.5541Z" fill="#212233" />
            <path d="M21.8198 34.3546C21.8198 31.5932 24.0584 29.3546 26.8198 29.3546H31.7368C34.4982 29.3546 36.7368 31.5932 36.7368 34.3546V78.8259H21.8198V34.3546Z" fill="#212233" />
            <path d="M42.9346 17.2244C42.9346 14.4629 45.1731 12.2244 47.9346 12.2244H52.8515C55.6129 12.2244 57.8515 14.4629 57.8515 17.2244V78.8258H42.9346V17.2244Z" fill="#212233" />
            <path d="M64.0488 5.71063C64.0488 2.94921 66.2874 0.710632 69.0488 0.710632H73.9658C76.7272 0.710632 78.9658 2.94921 78.9658 5.71063V78.8258H64.0488V5.71063Z" fill="#212233" />
            <path d="M85.1641 48.1149C85.1641 45.3535 87.4026 43.1149 90.1641 43.1149H95.081C97.8424 43.1149 100.081 45.3535 100.081 48.1149V78.8259H85.1641V48.1149Z" fill="#212233" />
            <path d="M106.278 34.3546C106.278 31.5932 108.516 29.3546 111.278 29.3546H116.195C118.956 29.3546 121.195 31.5932 121.195 34.3546V78.8258H106.278V34.3546Z" fill="#212233" />
        </svg>

    )
}

export const PlusIconRound = () => {
    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_8_430)">
                <path d="M9.61035 6.75C9.61035 6.43934 9.35851 6.1875 9.04785 6.1875C8.73719 6.1875 8.48535 6.43934 8.48535 6.75L8.48535 8.43752H6.79785C6.48719 8.43752 6.23535 8.68936 6.23535 9.00002C6.23535 9.31068 6.48719 9.56252 6.79785 9.56252H8.48535V11.25C8.48535 11.5607 8.73719 11.8125 9.04785 11.8125C9.35851 11.8125 9.61035 11.5607 9.61035 11.25L9.61035 9.56252H11.2979C11.6085 9.56252 11.8604 9.31068 11.8604 9.00002C11.8604 8.68936 11.6085 8.43752 11.2979 8.43752H9.61035V6.75Z" fill="#6D707E" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.09089 0.9375H9.00482C7.27353 0.937491 5.91674 0.937484 4.85813 1.07981C3.77458 1.22549 2.91956 1.5295 2.24845 2.2006C1.57735 2.87171 1.27334 3.72673 1.12766 4.81028C0.985335 5.86889 0.985342 7.22567 0.985352 8.95695V9.04303C0.985342 10.7743 0.985335 12.1311 1.12766 13.1897C1.27334 14.2733 1.57735 15.1283 2.24845 15.7994C2.91956 16.4705 3.77458 16.7745 4.85813 16.9202C5.91675 17.0625 7.27353 17.0625 9.00482 17.0625H9.09088C10.8222 17.0625 12.179 17.0625 13.2376 16.9202C14.3211 16.7745 15.1761 16.4705 15.8472 15.7994C16.5184 15.1283 16.8224 14.2733 16.968 13.1897C17.1104 12.1311 17.1104 10.7743 17.1104 9.04303V8.95697C17.1104 7.22567 17.1104 5.8689 16.968 4.81028C16.8224 3.72673 16.5184 2.87171 15.8472 2.2006C15.1761 1.5295 14.3211 1.22549 13.2376 1.07981C12.179 0.937484 10.8222 0.937491 9.09089 0.9375ZM3.04395 2.9961C3.47119 2.56886 4.04923 2.32369 5.00803 2.19478C5.98302 2.0637 7.26418 2.0625 9.04785 2.0625C10.8315 2.0625 12.1127 2.0637 13.0877 2.19478C14.0465 2.32369 14.6245 2.56886 15.0518 2.9961C15.479 3.42334 15.7242 4.00138 15.8531 4.96018C15.9842 5.93517 15.9854 7.21633 15.9854 9C15.9854 10.7837 15.9842 12.0648 15.8531 13.0398C15.7242 13.9986 15.479 14.5767 15.0518 15.0039C14.6245 15.4311 14.0465 15.6763 13.0877 15.8052C12.1127 15.9363 10.8315 15.9375 9.04785 15.9375C7.26418 15.9375 5.98302 15.9363 5.00803 15.8052C4.04923 15.6763 3.47119 15.4311 3.04395 15.0039C2.61671 14.5767 2.37154 13.9986 2.24263 13.0398C2.11155 12.0648 2.11035 10.7837 2.11035 9C2.11035 7.21633 2.11155 5.93517 2.24263 4.96018C2.37154 4.00138 2.61671 3.42334 3.04395 2.9961Z" fill="#6D707E" />
            </g>
            <defs>
                <clipPath id="clip0_8_430">
                    <rect width="18" height="18" fill="white" transform="translate(0.0478516)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export const Refresh = () => {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.19808 9.35665C2.25183 5.63618 5.30714 2.64392 9.05915 2.64392C11.5405 2.64392 13.7153 3.95213 14.92 5.91183C15.0827 6.17648 15.0001 6.52291 14.7355 6.68561C14.4708 6.84831 14.1244 6.76566 13.9617 6.50101C12.9545 4.86276 11.1363 3.76892 9.05915 3.76892C5.92088 3.76892 3.37779 6.2647 3.32325 9.35505L3.62353 9.05711C3.84407 8.83831 4.20022 8.83971 4.41902 9.06024C4.63783 9.28077 4.63643 9.63692 4.4159 9.85573L3.15604 11.1057C2.93673 11.3233 2.58298 11.3233 2.36368 11.1057L1.10382 9.85573C0.883287 9.63692 0.881888 9.28077 1.10069 9.06024C1.3195 8.83971 1.67565 8.83831 1.89618 9.05711L2.19808 9.35665ZM14.8398 8.80636C15.0589 8.58978 15.4115 8.58978 15.6307 8.80636L16.8953 10.0564C17.1163 10.2747 17.1184 10.6309 16.9 10.8518C16.6816 11.0728 16.3254 11.0749 16.1045 10.8565L15.7971 10.5526C15.745 14.2767 12.6763 17.2689 8.91187 17.2689C6.42284 17.2689 4.24036 15.9617 3.0309 14.0018C2.86775 13.7375 2.9498 13.3909 3.21417 13.2277C3.47854 13.0646 3.82512 13.1466 3.98827 13.411C4.9992 15.0491 6.82499 16.1439 8.91187 16.1439C12.0665 16.1439 14.6191 13.6442 14.6719 10.5541L14.366 10.8565C14.145 11.0749 13.7889 11.0728 13.5705 10.8518C13.3521 10.6309 13.3542 10.2747 13.5751 10.0564L14.8398 8.80636Z" fill="white" />
        </svg>


    )
}

export const Database = () => {
    return (

        <svg width="14" height="14" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.4375 4.5C0.4375 3.34373 1.36022 2.44836 2.5058 1.87556C3.68503 1.28595 5.27476 0.9375 7 0.9375C8.72524 0.9375 10.315 1.28595 11.4942 1.87556C12.6398 2.44836 13.5625 3.34373 13.5625 4.5V13.5C13.5625 14.6563 12.6398 15.5516 11.4942 16.1244C10.315 16.7141 8.72524 17.0625 7 17.0625C5.27476 17.0625 3.68503 16.7141 2.5058 16.1244C1.36022 15.5516 0.4375 14.6563 0.4375 13.5V4.5ZM1.5625 4.5C1.5625 3.99941 1.98293 3.39479 3.00892 2.8818C4.00126 2.38562 5.41154 2.0625 7 2.0625C8.58846 2.0625 9.99874 2.38562 10.9911 2.8818C12.0171 3.39479 12.4375 3.99941 12.4375 4.5C12.4375 5.00059 12.0171 5.60521 10.9911 6.11821C9.99874 6.61438 8.58846 6.9375 7 6.9375C5.41154 6.9375 4.00126 6.61438 3.00892 6.11821C1.98293 5.60521 1.5625 5.00059 1.5625 4.5ZM1.5625 13.5C1.5625 14.0006 1.98293 14.6052 3.00892 15.1182C4.00126 15.6144 5.41154 15.9375 7 15.9375C8.58846 15.9375 9.99874 15.6144 10.9911 15.1182C12.0171 14.6052 12.4375 14.0006 12.4375 13.5V11.0304C12.1549 11.2558 11.8344 11.4543 11.4942 11.6244C10.315 12.214 8.72524 12.5625 7 12.5625C5.27476 12.5625 3.68503 12.214 2.5058 11.6244C2.1656 11.4543 1.84506 11.2558 1.5625 11.0304V13.5ZM12.4375 6.53041V9C12.4375 9.50059 12.0171 10.1052 10.9911 10.6182C9.99874 11.1144 8.58846 11.4375 7 11.4375C5.41154 11.4375 4.00126 11.1144 3.00892 10.6182C1.98293 10.1052 1.5625 9.50059 1.5625 9V6.53041C1.84506 6.75579 2.1656 6.95434 2.5058 7.12444C3.68503 7.71405 5.27476 8.0625 7 8.0625C8.72524 8.0625 10.315 7.71405 11.4942 7.12444C11.8344 6.95434 12.1549 6.75579 12.4375 6.53041Z" fill="#8D92B5" />
        </svg>

    )
}

export const Send = () => {
    return (
        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.08539 10.2664L9.35592 6.12332C11.47 3.44526 12.527 2.10624 13.5132 2.3892C14.4994 2.67217 14.4994 4.31448 14.4994 7.59911V7.90881C14.4994 9.0935 14.4994 9.68585 14.9037 10.0574L14.9251 10.0767C15.3381 10.4404 15.9966 10.4404 17.3135 10.4404C19.6834 10.4404 20.8684 10.4404 21.2688 11.1133C21.2755 11.1244 21.2819 11.1357 21.2882 11.147C21.6662 11.8303 20.9801 12.6995 19.6079 14.4377L16.3374 18.5807C14.2233 21.2587 13.1662 22.5977 12.18 22.3148C11.1938 22.0318 11.1939 20.3895 11.1939 17.1048L11.1939 16.7952C11.1939 15.6105 11.194 15.0182 10.7897 14.6466L10.7683 14.6274C10.3552 14.2637 9.69676 14.2637 8.37981 14.2637C6.00994 14.2637 4.825 14.2637 4.42453 13.5907C4.4179 13.5796 4.41146 13.5683 4.4052 13.557C4.02716 12.8737 4.71324 12.0046 6.08539 10.2664Z" fill="#030613" />
        </svg>
    )
}