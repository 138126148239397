import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react'
import { AdjustToCellSizeTable } from '@components/table';
import { useGenAIContext } from '../useGenAIContext';
import classNames from 'classnames';
import styles from './../../styles.module.scss';
import { NextPrevPagination } from '@components/table/NextPrevPagination';
import QueryChartsModal from '@pages/data-explorer-new/components/queryChartsModal';
import { Modal } from "@components/modals";
import { DataSourceHandler } from '@api/data-source-handler';
import { isEmpty } from 'lodash';
import { GenAITableOutput, DataColumn } from '../types';
import { useSelector } from 'react-redux';
import { RootState } from '@store/types';
import { useDataExplorerContext } from '@pages/data-explorer-new/useDataExplorerContext';
import { Table } from 'antd';

const TableContainerGenAI = () => {
    const { sqlQuery, sessionId, _sessionId, newData, isCygnet } = useGenAIContext();
    const { database, theme } = useDataExplorerContext();
    const { activeUserInfo: { username: loggedUserName } } = useSelector((store: RootState) => store.AccountReducer);

    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pageData, setPageData] = useState<{ [pageNo: string]: string[][] }>({});
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLastPage, setLastPage] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isError, setError] = useState<boolean>(false);
    const [showQueryCharts, setShowQueryCharts] = useState<boolean>(false);
    const [columnStructure, setColumnStructure] = useState<DataColumn[]>([])

    const fetchData = useCallback(async (pageNo: number, pageSize: number) => {
        if (isLoading || isEmpty(_sessionId)) return;

        setLoading(true);
        setError(false);

        try {
            const resp = isCygnet ? await DataSourceHandler.CygnetSQLChat(sessionId, {
                human_message: {
                    content: "",
                    next_action: "retrieve_data"
                },
            }) : await DataSourceHandler.DeepSQLChat(database?.id, sessionId, {
                human_message: {
                    content: "",
                    next_action: "retrieve_data"
                },
            }, loggedUserName);

            parseTableData(resp.data.ai_message.output?.output_value || {}, pageNo, pageSize);
            const apiData=resp.data.ai_message.output?.output_value || {};
            const lengths = Object.values(apiData).map(arr => arr.length);
            const maxLength = Math.max(...lengths);
            const columns: DataColumn[] = Object.keys(apiData).map(key => ({
                title: key,
                dataIndex: key
            }));
            setColumnStructure(columns)
            setTotalRecords(maxLength)
        } catch (e) {
            setError(true);
            console.error("Error fetching data:", e);
        } finally {
            setLoading(false);
        }
    }, [sessionId, _sessionId, isCygnet, database?.id, loggedUserName]);

    const isFloat = (n: any) => {
        return Number(n) === n && n % 1 !== 0;
    };

    const parseTableData = (data: GenAITableOutput, pageNo: number, pageSize: number) => {
        const columnList = Object.keys(data);

        if (columnList.length === 0 || data[columnList[0]].length === 0) {
            setLastPage(true);
            setPageData({});
            return;
        }

        const tableData: string[][] = [];

        Object.keys(data[columnList[0]]).forEach((key: string, index) => {
            let temp: string[] = [];

            if (index === 0) {
                columnList.forEach((column) => {
                    temp.push(column);
                });
                tableData.push(temp);
                temp = [];
            }

            columnList.forEach((column) => {
                const temp1 = data[column][key];
                temp.push(isFloat(temp1) ? parseFloat(temp1).toFixed(2) : temp1);
            });

            tableData.push(temp);
        });

        setPageData((prevData) => ({
            ...prevData,
            [pageNo.toString()]: tableData,
        }));
    };

    useEffect(() => {
        if (isEmpty(_sessionId)) return;

        fetchData(pageNo, pageSize);
    }, [fetchData, _sessionId, pageNo, pageSize]);

    const handleTableChange = (pagination: any) => {
        setPageNo(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const handleQueryChartsOpen = useCallback(() => {
        setShowQueryCharts(true);
    }, []);

    const handleQueryChartsClose = useCallback(() => {
        setShowQueryCharts(false);
    }, []);

    const transformData = (pageDataArray: any): { [key: string]: any }[] => {
        if (!pageDataArray || pageDataArray.length === 0) return [];

        const headers = pageDataArray[0];
        const dataRows = pageDataArray.slice(1);

        return dataRows.map((row: any) => {
            const obj: { [key: string]: any } = {};
            row.forEach((value: any, index: number) => {
                obj[headers[index]] = value;
            });
            return obj;
        });
    };

    const data = useMemo(() => {
        if (!pageData[pageNo.toString()]) {
            return [];
        }

        return transformData(pageData[pageNo.toString()]);
    }, [pageData, pageNo]);

    const textStyle: CSSProperties | undefined = theme ? {
        color: theme === "dark" ? '#fff' : '#000',
        borderColor: theme === "dark" ? '#fff' : '#000',
    } : undefined;

    if (isLoading) {
        return <div className={styles['error-text']} style={{ color: 'white' }}>Loading... Please wait...</div>;
    }

    return (
        <>
                  {data.length>0 && (
                    <div className={classNames(styles['table-holder'])}>
                <div style={{ minHeight: '6rem' }}>
                    <Table
                        columns={columnStructure}
                        dataSource={data}
                        rowClassName={() => 'custom-row'}
                        scroll={{ x: 'max-content' }}
                        pagination={{
                            current: pageNo,
                            pageSize: pageSize,
                            total: totalRecords,
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '50', '100', '250'],
                        }}
                        onChange={handleTableChange}
                        className="hidden-pagination"
                        style={{ overflow: 'hidden' }}
                    />
                </div>
                <div style={{ marginTop: -30 }}>
                    <div className={styles["space-between"]} style={{ justifyContent: 'flex-end', marginTop: '0rem' }}>
                        <div>&nbsp;</div>
                        <button
                            className={classNames(styles['downloadCsvButton'], styles['ml-1'])}
                            onClick={handleQueryChartsOpen}
                            style={textStyle}
                        >
                            Explain
                        </button>
                    </div>
                </div>
            </div>
                  )}  
            <Modal
                isOpen={showQueryCharts}
                toggleClose={handleQueryChartsClose}
                title="Explanation"
                className={classNames("mf_model", styles["addEditWriteCsvModal_"])}
                showCloseMark
                shouldCloseOnOverlayClick={false}
            >
                <div className={styles["queryChartsContainer"]}>
                    <QueryChartsModal onClose={handleQueryChartsClose} sqlQuery={sqlQuery} />
                </div>
            </Modal>
        </>
    );
};

export default TableContainerGenAI;