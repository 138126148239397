import { cloneDeep } from 'lodash';
import { RESET_STATE } from '../types';
import { NewDataSourceReducerState, ActionTypes, TOGGLE_DATASOURCES_MODAL, SET_ACTION_TYPE, TOGGLE_COLUMN_MANAGEMENT, TOGGLE_CONNECTIONS_TREEVIEW, SET_ALL_CONNECTIONS_INFO, TOGGLE_ADMINISTRATOR_TREEVIEW, SET_FILE_BROWSER_CONNECTION } from './types';

const initialState: NewDataSourceReducerState = {
    showModal: {
        newEditConnection: false,
        deleteConnection: false,
        queryEditorBuilder: false,
        newPiRequest: false,
        requestLogs: false
    },
    isColumnManagementMinimized: true,
    showConnectionsTreeview: true,
    showAdministratorTreeView: true,
    allConnectionsInfo: [],
    fileBrowserConnectionList:[],
    actionType: ''

};

export const NewDataSourceReducer = (state = initialState, action: ActionTypes): NewDataSourceReducerState => {
    switch(action.type) {
        case TOGGLE_DATASOURCES_MODAL:
            return { ...state, showModal: { ...state.showModal, ...action.payload } };
        case SET_ACTION_TYPE:
            return { ...state, actionType: action.payload };
        case TOGGLE_COLUMN_MANAGEMENT:
            return { ...state, isColumnManagementMinimized: action.payload };
        case TOGGLE_CONNECTIONS_TREEVIEW:
            return { ...state, showConnectionsTreeview: !state.showConnectionsTreeview };
        case SET_ALL_CONNECTIONS_INFO:
            return { ...state, allConnectionsInfo: action.payload };
        case TOGGLE_ADMINISTRATOR_TREEVIEW:
            return {...state,showAdministratorTreeView: !state.showAdministratorTreeView};
        case RESET_STATE:
            return cloneDeep(initialState);
        case SET_FILE_BROWSER_CONNECTION:
            return {...state,fileBrowserConnectionList:action.payload }
        default:
            return state;        
    }

};