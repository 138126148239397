import React, { useEffect, useState, useCallback, useMemo } from "react";
import { UserDetails } from "@components/header-sidebar/user-details";
import { InPageSpinner } from "@components/spinners/in-page-spinner";
import { DataSourceHandler } from "@api/data-source-handler";
import styles from "./../styles.module.scss";
import classNames from "classnames";
import LeftNav from "../left-nav";
import NifiTiles from "./NifiTiles";
import NifiSinksTiles from "./NifiSinksTiles";
import { NifiEdgeData, NiFiSinkData, GraphData } from "./types";
import { getOption, getPieOptions, daysArray, graphTypeArray } from "./graphConstants";
import { NifiEdgeModal } from "./modals/edit-nifi-edge";
import { NifiSinkModal } from "./modals/edit-nifi-sink";
import { Modal } from "@components/modals";
import ReactEcharts from 'echarts-for-react';
import TabsComponent from "@components/tabs";
import { Select } from 'antd';
const { Option } = Select;
interface ChartProps {
	graphType: 'line' | 'pie' | string; 
	graphData: any[]; 
  }

const NifiManagement: React.FC = () => {
	const [activeTab, setActiveTab] = useState("NE");
	const [isLoading, setLoadingState] = useState(false);
	const [nifiEdgeData, setNifiEdgeData] = useState<NifiEdgeData[]>([]);
	const [nifiSinkData, setNifiSinkData] = useState<NiFiSinkData[]>([]);
	const [
		nifiEdgeDataModal,
		setNifiEdgeDataModal,
	] = useState<NifiEdgeData | null>(null);
	const [
		nifiSinkDataModal,
		setNifiSinkDataModal,
	] = useState<NiFiSinkData | null>(null);
	const [showNifiEdgeModal, setNifiEdgeModal] = useState(false);
	const [showNifiSinkModal, setNifiSinkModal] = useState(false);
	const [showGraphModal, setShowGraphModal]= useState(false);
	const [graphData, setGraphData] = useState<GraphData[]>([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [edgeFilteredResults, setEdgeFilteredResult] = useState<
		NifiEdgeData[]
	>([]);
	const [sinkFilteredResults, setSinkFilteredResult] = useState<
		NiFiSinkData[]
	>([]);
	const [clickedNodeData, setClickedNodeData] = useState("");
	const [days, setDays] = useState("48days");
    const [graphType, setGraphType] = useState("line");

	const getNifiEdgeData = useCallback(() => {
		setLoadingState(true);
		DataSourceHandler.GetEdgeNodes((res) => {
			setNifiEdgeData(res);
			setLoadingState(false);
		});
	}, []);

	const getNifiSinkData = useCallback(() => {
		setLoadingState(true);
		DataSourceHandler.GetSinkNodes((res) => {
			setNifiSinkData(res);
			setLoadingState(false);
		});
	}, []);

	const closeNifiEdgeModal = useCallback(() => {
		setNifiEdgeModal(false);
	}, []);

	const closeNifiSinkModal = useCallback(() => {
		setNifiSinkModal(false);
	}, []);

	const searchData = (text: string) => {
		setSearchTerm(text);
		activeTab === "NE"
			? setEdgeFilteredResult(
					nifiEdgeData.filter((data) =>
						data.nodeName.toLowerCase().includes(text.toLowerCase())
					)
			  )
			: setSinkFilteredResult(
					nifiSinkData.filter((data) =>
						data.sinkName.toLowerCase().includes(text.toLowerCase())
					)
			  );
	};

	useEffect(() => {
		getNifiEdgeData();
		getNifiSinkData();
	}, []);

	const handleTabChange = (key: string) => {
		setSearchTerm("");
		setActiveTab(key);
	};

	const isNoFilterApplied =
		!isLoading &&
		searchTerm.length !== 0 &&
		(activeTab === "NE"
			? edgeFilteredResults.length === 0
			: sinkFilteredResults.length === 0);

	const Wrapper = ({ children }: { children: React.ReactElement }) => {
		return (
			<div className={styles["tabContent__container"]}>
				{isNoFilterApplied ? (
					<h3 className="white">
						No data available for the filter applied
					</h3>
				) : null}
				{isLoading ? (
					<div className={styles["centerLoading"]}>
						<InPageSpinner size="XSmall" color="white" />
					</div>
				) : !isNoFilterApplied &&
				  children.props.nifiData.length === 0 ? (
					<h3 className="white">No data available.</h3>
				) : (
					children
				)}
			</div>
		);
	};

	const handleGraphModal=()=>{
		setShowGraphModal(!showGraphModal)
	}
	

	

	const handleEdgeNodeClick = (NodeData: NifiEdgeData) => {
		setClickedNodeData(NodeData.nodeName)
		DataSourceHandler.GetNifiGraphData(NodeData.nodeName, days,graphType,  (response) => {
			let modifiedResponse=response;
			if(graphType === 'line'){
			 modifiedResponse = response.xaxis.map((changeTime: string, index: number) => ({
				newStatus: response.data[index],
				changeTime
			  }));
			  setGraphData(modifiedResponse);
			} else {
				setGraphData(response.data)
			}
			handleGraphModal();
		});
	};

	const tabItems = [
		{
			label: "NiFi Edges",
			key: "NE",
			children: (
				<Wrapper>
					<NifiTiles
						nifiData={
							searchTerm.length === 0
								? nifiEdgeData
								: edgeFilteredResults
						}
						setNifiEdgeModal={setNifiEdgeModal}
						setNifiEdgeDataModal={setNifiEdgeDataModal}
						onEdgeNodeClick={handleEdgeNodeClick}
					/>
				</Wrapper>
			),
		},
		{
			label: "Edge Sinks",
			key: "NS",
			children: (
				<Wrapper>
					<NifiSinksTiles
						nifiData={
							searchTerm.length === 0
								? nifiSinkData
								: sinkFilteredResults
						}
						setNifiSinkModal={setNifiSinkModal}
						setNifiSinkDataModal={setNifiSinkDataModal}
					/>
				</Wrapper>
			),
		},
	];

	const { onReloadClick, newBtnText, onNewButtonClick } = useMemo(() => {
		const isActiveTabNifiEdges = activeTab === "NE";
		return {
			onReloadClick: isActiveTabNifiEdges
				? getNifiEdgeData
				: getNifiSinkData,
			newBtnText: isActiveTabNifiEdges ? "New Edge" : "New Sink",
			onNewButtonClick: () => {
				if (isActiveTabNifiEdges) {
					setNifiEdgeDataModal(null);
					setNifiEdgeModal(true);
				} else {
					setNifiSinkDataModal(null);
					setNifiSinkModal(true);
				}
			},
		};
	}, [activeTab]);
		

	const getIndex = (selectedDays: string, selectedGraphType: string) => {
        setDays(selectedDays);
        setGraphType(selectedGraphType);
        DataSourceHandler.GetNifiGraphData(clickedNodeData, selectedDays, selectedGraphType, (response) => {
			let modifiedResponse=response;
			if(selectedGraphType === 'line'){
			 modifiedResponse = response.xaxis.map((changeTime: string, index: number) => ({
				newStatus: response.data[index],
				changeTime
			  }));
			  setGraphData(modifiedResponse);
			} else {
				setGraphData(response.data)
			}
            
        });
    };

	const ChartComponent: React.FC<ChartProps> = ({ graphType, graphData }) => {
		return (
			<div>
				{graphType === 'line' && (
					<ReactEcharts
						option={getOption(graphData)}
						style={{ width: '100%', height: '400px' }}
					/>
				)}
				{graphType !== 'line' && (
					<ReactEcharts
						option={getPieOptions(graphData)}
						style={{ width: '100%', height: '400px' }}
					/>
				)}
			</div>
		);
	};

	return (
		<section className={classNames(styles["admin__container"])}>
			<LeftNav selected_id="nifi" />
			<div className={styles["nifi_content"]}>
				<header>
					<h1>
						<img src="/icons/nifi.svg" alt="" />
						Edge Management
					</h1>
					<button>
						<span className={styles["help_center"]}>
							<img src="/icons/help.svg" />
							Help
						</span>
					</button>
				</header>
				<div className={styles["search_holder"]}>
					<div
						className={classNames(
							"search__input__container",
							styles["search_container"]
						)}
					>
						<input
							autoFocus
							className={classNames(
								"search_input",
								styles["search_input"]
							)}
							placeholder="Search"
							value={searchTerm}
							onChange={(e) => searchData(e.target.value)}
						/>
						{searchTerm.length === 0 ? null : (
							<img
								src="/icons/treeview/search-cross.svg"
								alt=""
								className="search_reset"
								style={{
									top: 26,
								}}
								onClick={() => {
									searchData("");
								}}
							></img>
						)}
					</div>
					<button
						className={classNames(
							"btn-lg btn-yellow-transparent",
							styles["center"],
							styles["btn-lg"]
						)}
						onClick={onNewButtonClick}
					>
						<img src="/icons/workflow/add.svg" alt="" />
						<span>{newBtnText}</span>
					</button>
					<button
						className={classNames(
							"btn-lg",
							styles["center"],
							styles["btn-lg"]
						)}
						onClick={onReloadClick}
					>
						<span>Reload</span>
					</button>
				</div>
				<TabsComponent
					items={tabItems}
					onTabChange={handleTabChange}
					size="large"
				/>
			</div>
			<div className="properties__container properties__container--minimized">
				<UserDetails hideNameOnMinimize={true} />
			</div>
			<NifiEdgeModal
				showModal={showNifiEdgeModal}
				toggleClose={closeNifiEdgeModal}
				getNifiData={getNifiEdgeData}
				nifidata={nifiEdgeDataModal}
			/>
			<NifiSinkModal
				showModal={showNifiSinkModal}
				toggleClose={closeNifiSinkModal}
				getNifiData={getNifiSinkData}
				nifidata={nifiSinkDataModal}
			/>
			<Modal
			title={clickedNodeData}
			isOpen={showGraphModal}
			toggleClose={handleGraphModal} 
			className="modalNifi"
			showCloseMark={true}
			>
				<div className={styles["select_container"]}>
				<Select
                defaultValue={days}
                style={{ width: 120, marginRight:'3px' }}
                onChange={(value) => getIndex(value, graphType)}
                >
                {daysArray.map((option) => (
                    <Option key={option.value} value={option.value}>{option.label}</Option>
                ))}
            </Select>

            <Select
                defaultValue={graphType}
                style={{ width: 120 }}
                onChange={(value) => getIndex(days, value)}
            >
                {graphTypeArray.map((option) => (
                    <Option key={option.value} value={option.value}>{option.label}</Option>
                ))}
            </Select>
			</div>
			<ChartComponent
			graphType={graphType}
			graphData={graphData}
			/>
			</Modal>
		</section>
	);
};

export default NifiManagement;