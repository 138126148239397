import {  Modal } from '../../../components/modals';
// import { object, string, InferType } from 'yup';
import React, { useState } from 'react';
import Form from '../../../components/form';
// import { toggleModal } from '../../../store/workflow';
// import { useSelector, useDispatch } from 'react-redux';
// import { RootState } from '../../../store/types';
// import { NewComponentResponse, NewComponent, newComponentHandler } from '../../../api/new-custom-component';
import { errorAlert } from '../../../components/toastify/notify-toast';
import classNames from 'classnames';
import { FileHandler } from '../../../api/file-handler';
import { InPageSpinner } from '../../../components/spinners/in-page-spinner';


interface FileUploadModalprops {
    path: string;
    handleUploadSuccess: () => void;
    showModal: boolean;
    toggleClose: () => void;
}

export const ServerFileUploadModal: React.FC<FileUploadModalprops> = ({path, handleUploadSuccess, showModal, toggleClose }) => {
    const [fileContent, setfile] = useState<FormData>();
    const [showError, setError] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [fileUploadProgress, setFileUploadProgress] = useState(0);

    function handleFileuploadSucess() {
        setShowSpinner(false);
        handleUploadSuccess();
        toggleClose();
    }

    function handleErrorCallback (params: any) {
        setShowSpinner(false);
        errorAlert(params);
    }

    const handleFileUploadProgress = (progressEvent: ProgressEvent) => {
        const percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        setFileUploadProgress(percentCompleted);
    };

    function handleImportComponent() {
        setShowSpinner(true);
        setFileUploadProgress(0);
        // @ts-ignore
        FileHandler.uploadFilesElfinder(path, [fileContent?.get('file')],handleFileuploadSucess,handleErrorCallback, handleFileUploadProgress);
    }

    function onChangeFileHandler(event: any) {
        if (event.target.files[0]) {
            setError(false);
            const formData = new FormData();
            formData.append('file', event.target.files[0]);
            setfile(formData);
        } else {
            setError(true);
        }
    }

    function onClickError(event: any) {
        if (event.values === undefined) {
            setError(true);
        }
    }



    return (
        <Modal
            isOpen={showModal}
            toggleClose={toggleClose}
            className="new__workflow__modal"
            title={showSpinner ? 'Uploading in Progress':'Upload File'}
        >
            {showSpinner? (
                <div
                    className="percentageBox"
                >
                    <InPageSpinner />
                    <span>
                        {fileUploadProgress} %
                    </span>
                </div>
            ) : (
                <Form
                    initialValues={{ name: '' }}
                    onSubmit={handleImportComponent}
                >
                    <div className='upload-btn'>
                        <label htmlFor="file"> Upload File  </label>
                        <input className={classNames('upload-btn', { 'showinputfileError': showError })} type="file" name="file" onChange={onChangeFileHandler}
                            onClick={onClickError}
                        />
                        <span className={classNames({ 'showerror': showError })}>File is required</span>
                    </div>

                    <div className="modalBtns__box">
                        <button
                            className="fl-r btn-md btn-yellow"
                            type="submit"
                        >
                        Upload
                        </button>
                        <button
                            className="fl-r btn-md btn-cancel"
                            type="button"
                            onClick={toggleClose}
                        >
                        Cancel
                        </button>

                    </div>


                </Form>

            )}
           

        </Modal>
    );
};