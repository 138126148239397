import { HttpClient } from '../services';
import { ApiResponse } from '../data-source-handler';
import { BriefExperimentInfo, CreateExperimentInfo, ModelInfo, ModelVersionInfo, PythonModelsInfoResponse, UpdateExperimentName } from './types';
import { API } from '../../constants/settings';
import queryString from 'query-string';
import { PipelineCategoryOptions } from '../../components/formcreators/ml-pipeline/enums';

class MlflowHandlerClass {
    CreateExperiment(data: CreateExperimentInfo, callback: ApiResponse<{experiment_id: string }>) {
        HttpClient.Post('Create an experiment', API.mlFlowUrl + '/mlflow/create',data, callback);
    }

    RenameExperiment(data: UpdateExperimentName, callback: ApiResponse<{}>) {
        HttpClient.Post('Rename an experiment', 'ajax-api/2.0/preview/mlflow/experiments/update',data, callback);
    } 

    GetExperimentsList(callback: ApiResponse<BriefExperimentInfo[]>) {
        HttpClient.Get('Get experiments list', API.mlFlowUrl + '/mlflow/list' ,callback);
    }

    DeleteExperiment(experiment_id: string, callback: ApiResponse<{}>) {
        HttpClient.Post('Rename an experiment', 'ajax-api/2.0/preview/mlflow/experiments/delete', { experiment_id}, callback);
    }

    GetArtifactLocations(callback: ApiResponse<string[]>) {
        HttpClient.Get('Get experiments list', API.mlFlowUrl + '/mlflow/artifact-location' ,callback);
    }

    GetModels(callback: ApiResponse<{ registered_models: ModelInfo[] }>) {
        HttpClient.Get('Get Models list', API.mlFlowUrl + '/mlflow/model/registered/list' ,callback);
    }

    GetVersionsOfModel(modelName: string, callback: ApiResponse<{ model_versions: ModelVersionInfo[] }>) {
        HttpClient.Get('Get Models list', API.mlFlowUrl + `/mlflow/model/versions/search?filter=name%3D%27${modelName}%27` ,callback);
    }

    GetModelCategoryInfo(name: string, version: string, callback: ApiResponse<PipelineCategoryOptions | 'Not Found'>) {
        const _searchQuery = queryString.stringify({ name, version }, { encode: true });
        HttpClient.Get('Get Models list', API.mlFlowUrl + `/mlflow/model/run-tag?${_searchQuery}`, callback);
    }

    GetSklearnInfo(callback: ApiResponse<PythonModelsInfoResponse>) {
        HttpClient.Get('Get Sklearn Models list', API.mlFlowUrl + '/mlflow/sklearn', callback);
    }


    GetPythonModels(callback: ApiResponse<PythonModelsInfoResponse>) {
        HttpClient.Get('Get Python Models list', API.mlFlowUrl + '/mlflow/python', callback);
    }
}

const MlflowHandler = new MlflowHandlerClass();

export { MlflowHandler };