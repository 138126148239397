import { FileHandler } from "@api/file-handler";
import { File } from "@components/FileExplorer/types";
import {
	FileUploader,
	useFileUploaderController,
} from "@components/FileUploader";
import { UploadedFileInfo } from "@components/FileUploader/types";
import { Modal } from "@components/modals";
import { Flex } from "@components/ui/Flex";
import { Text } from "@components/ui/Text";
import React, { useMemo } from "react";
import { DeleteFile, UploadFile } from "../hooks/storage";
import "./file-upload-modal.scss";

type FileUploadModalProps = {
	showModal: boolean;
	closeModal: () => void;
	currentPath: string;
	refreshFileList: () => void;
	deleteFile: DeleteFile;
	uploadFile: UploadFile;
};

const FileUploadModal = ({
	showModal,
	closeModal,
	currentPath,
	refreshFileList,
	deleteFile,
	uploadFile
}: FileUploadModalProps) => {
	const [showCancelModal, setShowCancelModal] = React.useState(false);

	const handleFileUploadSucess = () => {
		refreshFileList();
	};

	const handleErrorCallback = (fileId: string) => {
		onFileUploadFailure(fileId);
	};

	const onFileUpload = (files: UploadedFileInfo[]) => {
		files.forEach((file) => {
			const requestData = new FormData();
			requestData.append("file", file.file);
			const abort = uploadFile(
				currentPath,
				requestData,
				handleFileUploadSucess,
				handleErrorCallback.bind(null, file.id),
				setUploadProgress.bind(null, file.id)
			);
			addFileAborter({ [file.id]: abort });
		});
	};

	const {
		setUploadProgress,
		onFileUploadFailure,
		clearFilesList,
		uploadInProgress,
		markFileAsDeleted,
		addFileAborter,
		abortUpload,
		...controllerProps
	} = useFileUploaderController({ onFileUpload });

	const subtitle = useMemo(() => {
		return currentPath.split("/").join(" > ");
	}, [currentPath]);

	const _closeModal = () => {
		if (uploadInProgress) {
			return;
		}
		closeModal();
	};

	const handleDeleteFile = (fileId: string, fileName: string) => {
		const filePath = `/${currentPath}/${fileName}`;
		deleteFile(filePath, () => {
			markFileAsDeleted(fileId);
			refreshFileList();
		});
	};

	const cancelFileUpload = () => {
		abortUpload();
		closeUploadModal();
	};

	const closeUploadModal = () => {
		clearFilesList();
		closeModal();
		setShowCancelModal(false);
	};

	return (
		<Modal
			isOpen={showModal}
			className="file-upload-modal"
			toggleClose={_closeModal}
			showCloseMark={false}
			title="Upload File/s"
			subtitle={
				<Text color="rgba(255, 255, 255, .72)" mt="8px">
					{subtitle}
				</Text>
			}
		>
			<Flex height="500px" flexDirection="column">
				<Flex height="450px">
					<FileUploader
						{...controllerProps}
						handleDeleteFile={handleDeleteFile}
					/>
				</Flex>

				<Flex justifyContent="flex-end" pt="12px" color="#FFF">
					<button
						className="btn-md file-upload-modal__cancel-btn"
						onClick={() =>
							uploadInProgress
								? setShowCancelModal(true)
								: closeUploadModal()
						}
					>
						Cancel
					</button>
					<button
						disabled={uploadInProgress}
						className="btn-md btn-yellow"
						onClick={closeUploadModal}
					>
						Done
					</button>
				</Flex>
			</Flex>

			<Modal
				isOpen={showCancelModal}
				toggleClose={() => setShowCancelModal(false)}
				title="Cancel File Upload"
			>
				<Flex flexDirection="column">
					<Text color="white">
						Are you sure you want to cancel the upload?
					</Text>
					<Text fontSize="12px" color="#DB4641">
						Warning: Files that are being uploaded will be deleted.
					</Text>
					<Flex justifyContent="flex-end" gap="8px" mt="24px">
						<button
							className="btn-md file-upload-modal__cancel-btn"
							type="submit"
							onClick={() => setShowCancelModal(false)}
						>
							Cancel
						</button>
						<button
							className="btn-md btn-yellow"
							type="button"
							onClick={cancelFileUpload}
						>
							Yes
						</button>
					</Flex>
				</Flex>
			</Modal>
		</Modal>
	);
};

export default FileUploadModal;
