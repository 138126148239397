import styled from "styled-components";
import {
	color,
	ColorProps,
	layout,
	LayoutProps,
	space,
	SpaceProps,
	typography,
	TypographyProps,
} from "styled-system";

interface Props extends LayoutProps, ColorProps, SpaceProps, TypographyProps {
	children: React.ReactNode;
	textTransform?: string;
	lineBreak?: string;
}

export const Text = styled.div<Props>`
	${(props) => props.textTransform && `text-transform: ${props.textTransform};`}
	${(props) => props.lineBreak && `line-break: ${props.lineBreak};`}
	${space};
	${color};
	${layout};
	${typography};
`;
