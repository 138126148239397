import { Modal } from '../../../components/modals';
import { object, string, InferType } from 'yup';
import React, { useState, useEffect, useMemo } from 'react';
import Form, { InputField } from '../../../components/form';
import { WorkflowHandler, UpdateWorkflow,  DetailedWorkflowInfo, UpdateWorkflowResponse } from '../../../api/workflow-handler';
import { toggleModal } from '../../../store/workflow';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/types';
import { getTabType, updateAWorkflowEditorTabInfo } from '../../../store/canvas';
import { capitalize } from 'lodash';
import { getActiveWorkflowType } from '../../../utils';
import { noBlankSpaceInputFieldRegex } from '@constants/enums';

const NewWorkflowSchema = (workflowType: string) => object().shape({
    projectName: string()
        .required(`${workflowType} name is required.`)
        .matches(noBlankSpaceInputFieldRegex,
            'Blank spaces not allowed'
        ),
});

type NewWorkflowSchema = InferType<ReturnType<typeof NewWorkflowSchema>>


interface RenameWorkflowModalProps {
    retrieveWorkflowsOnSuccess: any;
}


export const RenameWorkflowModal: React.FC<RenameWorkflowModalProps> =  ({ retrieveWorkflowsOnSuccess }) => {
    const showModal = useSelector((store: RootState) => store.WorkflowReducer.showModal.renameWorkflow);
    const dispatch = useDispatch();
    const [currentWorkflowInfo, setCurrentWorkflowInfo] = useState<DetailedWorkflowInfo['data'] | null>(null); 
    const openTabs = useSelector((store: RootState) => store.CanvasReducer.workflowEditor.openTabs);
    const singleWorkflowInfo = useSelector((store: RootState) => store.WorkflowReducer.singleItemInfoForModals);
    const activeExecutionEnv = useSelector((store: RootState) => store.CommonReducer.activeExecutionEnv);

    function toggleClose() {
        dispatch(toggleModal('renameWorkflow', false));
    }

    const handleUpdateWorkflowSuccess = (response: UpdateWorkflowResponse) => {
        const updatedWorkflowInfo = response.data;
        retrieveWorkflowsOnSuccess({});
        const selectedWorkflowData = openTabs.get(updatedWorkflowInfo.id);
        if (selectedWorkflowData) dispatch(updateAWorkflowEditorTabInfo({ type: getTabType('workflowEditor', selectedWorkflowData.info.env), info: { ...selectedWorkflowData.info, name: updatedWorkflowInfo.projectName } }));
        dispatch(toggleModal('renameWorkflow', false));
    };
    
    function handleUpdateWorkflowName(values: NewWorkflowSchema) {
        if(currentWorkflowInfo) {
            const data: UpdateWorkflow = { 
                projectName: values.projectName, 
                create_new_version: false, 
                details: currentWorkflowInfo.details, 
                payload: currentWorkflowInfo.payload, 
                isStarred: currentWorkflowInfo.isStarred, 
                notes: currentWorkflowInfo.notes,
                hasVariables: currentWorkflowInfo.hasVariables, 
                componentCounter: currentWorkflowInfo.componentCounter, 
                directoryId: currentWorkflowInfo.directoryId, 
                env: currentWorkflowInfo.env,
                zepplinData: typeof currentWorkflowInfo.zepplinData === 'string'? (currentWorkflowInfo.zepplinData as any) : JSON.stringify(currentWorkflowInfo.zepplinData || []),
                config: currentWorkflowInfo.config
            };
            WorkflowHandler.UpdateWorkflow(currentWorkflowInfo.id ,data, handleUpdateWorkflowSuccess);
        }
    }

    const handleWorkflowInfoSuccess = (response: DetailedWorkflowInfo) => {
        setCurrentWorkflowInfo(response.data);

    };

    useEffect(() => {
        if(showModal && singleWorkflowInfo.id) {
            WorkflowHandler.GetWorkflowInfo(singleWorkflowInfo.id, handleWorkflowInfoSuccess);
        }
    }, [showModal, singleWorkflowInfo]);

    const activeWorkflowType = useMemo(() => capitalize(getActiveWorkflowType(activeExecutionEnv)), [activeExecutionEnv]);


    const formSchema = useMemo(() => 
        NewWorkflowSchema(activeWorkflowType)
    , [activeWorkflowType]);
    const heading=activeWorkflowType=='Local'?'Workflow':activeWorkflowType;
    
    return(
        <Modal
            isOpen={showModal}
            toggleClose={toggleClose}
            className="new__workflow__modal"
            title={`Rename a '${heading}`}
        >
            <Form
                validationSchema={formSchema}
                initialValues={{projectName: currentWorkflowInfo?.projectName || ''}}
                onSubmit={handleUpdateWorkflowName}
                enableReinitialize
            >
                <InputField
                    name="projectName"
                    label={`Name your ${heading}`}
                    autoComplete="off"
                    autoFocus
                />
                <div className="modalBtns__box">
                    <button 
                        className="btn-md btn-yellow"
                        id="rename_btn_yellow"
                        type="submit"
                    >
                        Update
                    </button>
                    <button 
                        className="btn-md btn-cancel"
                        type="button"
                        id="rename_btn_cancel"
                        onClick={toggleClose}
                    >
                        Cancel
                    </button>
                </div>
                
            </Form>

        </Modal>
    );
};
