import React from 'react';
import { useState } from 'react';
import { ClusterDetail } from '../../api/databricks-handler';
import { InputField } from '../../components/form';
import { Form } from '../../components/form/form';
import { Modal } from '../../components/modals';
import { object, string } from 'yup';
import { deleteCluster } from '../../store/cluster';
import { useDispatch } from 'react-redux';
import { InPageSpinner } from '../../components/spinners/in-page-spinner';

type DeleteClusterModalProps = {
    showModal: boolean;
    toggleModal: () => void;
    currentClusterDetail: ClusterDetail | null;
}


const deleteClusterSchema = object().shape({
    clusterName: string().test('test eq', 'Name doesn\'t match', function(this, val) {
        return this.parent.actualClusterName === val;
    }),

});

export const DeleteClusterModal: React.FC<DeleteClusterModalProps> = ({ showModal, toggleModal, currentClusterDetail }) => {
    // const {} = useState()
    const dispatch = useDispatch();
    const [showSpinner, toggleSpinner] = useState(false);

    const onFormSubmit = () => {
        const successCb = () => {
            toggleSpinner(false);
            toggleModal();
        };

        const failureCb = () => {
            toggleSpinner(false);
        };

        if(currentClusterDetail?.clusterId) {
            dispatch(deleteCluster(currentClusterDetail.clusterId, successCb, failureCb));
        }
    };

    return(
        <Modal
            isOpen={showModal}
            toggleClose={toggleModal}
            title="Are you absolutely sure you want to delete this cluster?"
            className="deleteCluster__modal"
        >
            <p className="warning__msg">Warning: This action <span>cannot</span> be undone. This will permanently delete the <span>{currentClusterDetail?.clusterName}</span> cluster.</p>
            <Form
                initialValues={{ actualClusterName: currentClusterDetail?.clusterName, clusterName: '' }}
                onSubmit={onFormSubmit}
                className=""
                validationSchema={deleteClusterSchema}
                enableReinitialize
            >
                <p>Please type <span>{currentClusterDetail?.clusterName}</span></p>
                <InputField 
                    name="clusterName"
                    placeholder="Enter the cluster name"
                />
                <div className="btns__outer">
                    <button 
                        className="btn-md"
                        type="submit"
                    >
                        {showSpinner ?
                            <InPageSpinner size="XSmall" color="white" />
                            :
                            <span>Yes</span>
                        }
                    </button>
                    <button 
                        className="btn-md btn-yellow"
                        type="button"
                        onClick={toggleModal}
                    >
                        Cancel
                    </button>  
                </div>
            </Form>

        </Modal>
    );
};