import React from 'react';

export const OpenQueryBuilderIcon = () => (
    <svg
        width="12px"
        height="12px"
        viewBox="0 0 12 12"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                d="M11.5555062,2.66659259 C12.5356489,2.66659259 13.3332741,3.46421779 13.3332741,4.44436049 L13.3332741,7.9998963 C13.3332741,8.32700559 13.0683867,8.5924856 12.7406848,8.5924856 C12.4129829,8.5924856 12.1480955,8.32700559 12.1480955,7.9998963 L12.1480955,4.44436049 C12.1480955,4.11784379 11.8826155,3.85177119 11.5555062,3.85177119 L4.44443457,3.85177119 C4.11732527,3.85177119 3.85184527,4.11784379 3.85184527,4.44436049 L3.85184527,11.5554321 C3.85184527,11.8819488 4.11732527,12.1480214 4.44443457,12.1480214 L7.99997037,12.1480214 C8.32767225,12.1480214 8.59255967,12.4135014 8.59255967,12.7406107 C8.59255967,13.06772 8.32767225,13.3332 7.99997037,13.3332 L4.44443457,13.3332 C3.46429187,13.3332 2.66666667,12.5355748 2.66666667,11.5554321 L2.66666667,4.44436049 C2.66666667,3.46421779 3.46429187,2.66659259 4.44443457,2.66659259 L11.5555062,2.66659259 Z M7.58099492,7.58100974 C7.81269734,7.34930732 8.18721377,7.34930732 8.41891619,7.58100974 L8.41891619,7.58100974 L12.1433399,11.3054335 L12.1480807,10.3673646 C12.1498584,10.0396627 12.4165236,9.7759605 12.7436329,9.77773827 C13.0701496,9.77951604 13.3332593,10.0444035 13.3332593,10.3703276 L13.3332593,10.3703276 L13.3332593,10.3732905 L13.3214075,12.74187 C13.3196297,13.0677941 13.0553349,13.3314963 12.7294108,13.3314963 L12.7294108,13.3314963 L10.3709053,13.3332741 C10.0432035,13.3332741 9.77772346,13.0683867 9.77772346,12.7412774 L9.77772346,12.7412774 L9.78546741,12.6446047 C9.83132316,12.3631343 10.0753218,12.1480955 10.3697202,12.1480955 L10.3697202,12.1480955 L11.3095668,12.1475029 L7.58099492,8.41893101 C7.46544001,8.30337609 7.40736626,8.15167323 7.40736626,7.99997037 C7.40736626,7.84826751 7.46544001,7.69656465 7.58099492,7.58100974 Z"
                id="path-1-OQBI"
            />
        </defs>
        <g
            id="Data-Browser"
            stroke="none"
            strokeWidth={1}
            fill="none"
            fillRule="evenodd"
        >
            <g id="2-9-3-Stars" transform="translate(-527.000000, -170.000000)">
                <g id="Query-Builder" transform="translate(411.000000, 164.000000)">
                    <g
                        id="Icons/Standard/Export/Resting"
                        transform="translate(114.000000, 4.000000)"
                    >
                        <mask id="mask-2-OQBI" fill="white">
                            <use xlinkHref="#path-1-OQBI" />
                        </mask>
                        <use
                            id="🎨-Icon-Сolor"
                            fill="#222B45"
                            fillRule="evenodd"
                            transform="translate(7.999970, 7.999933) scale(-1, 1) rotate(180.000000) translate(-7.999970, -7.999933) "
                            xlinkHref="#path-1-OQBI"
                        />
                        <g
                            id="↳-🎨Color/Secondary/100"
                            mask="url(#mask-2-OQBI)"
                            fill="#C9CDEC"
                            fillRule="evenodd"
                        >
                            <rect id="Color-Container" x={0} y={0} width={16} height={16} />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const DownloadIcon = () => {
    return(
        <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.721 11.155l1.873-1.874 1.046 1.045-3.135 3.134a.738.738 0 01-1.045 0l-3.135-3.134L7.37 9.281l1.874 1.873v-7.82h1.477v7.82zm4.433 4v-2.956h1.478v2.956c0 .812-.665 1.477-1.478 1.477H4.811a1.482 1.482 0 01-1.478-1.477v-2.956h1.478v2.956h10.343z"
                fill="#000"
            />
            <mask
                id="a-di"
                style={{
                maskType: "alpha"
                }}
                maskUnits="userSpaceOnUse"
                x={3}
                y={3}
                width={14}
                height={14}
            >
                <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.721 11.155l1.873-1.874 1.046 1.045-3.135 3.134a.738.738 0 01-1.045 0l-3.135-3.134L7.37 9.281l1.874 1.873v-7.82h1.477v7.82zm4.433 4v-2.956h1.478v2.956c0 .812-.665 1.477-1.478 1.477H4.811a1.482 1.482 0 01-1.478-1.477v-2.956h1.478v2.956h10.343z"
                fill="#fff"
                />
            </mask>
            <g mask="url(#a-di)">
                <path fill="#D4D6E0" d="M0 0H20V20H0z" />
            </g>
        </svg>
    )
}

export const AtoZSortingIcon = ({ className = ''}) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.726 9.355a.608.608 0 00.78-.354l.308-.82H6.7l.307.82a.606.606 0 001.135-.426L6.325 3.726a.607.607 0 00-1.135 0L3.372 8.575a.607.607 0 00.354.78zm2.031-3.69l.49 1.305h-.979l.49-1.305zm2.303 5.91l-2.909 3.88h2.425a.606.606 0 110 1.211H3.938a.606.606 0 01-.485-.969l2.91-3.879H3.938a.606.606 0 110-1.212h3.636a.606.606 0 01.485.97zm2.546-.97a.606.606 0 101.212 0V5.403l1.39 1.39a.604.604 0 00.857 0 .606.606 0 000-.857L11.64 3.512a.605.605 0 00-.858 0L8.359 5.935a.606.606 0 10.857.857l1.39-1.39v5.204zm3.636 3.992l1.39-1.39a.606.606 0 11.857.858l-2.424 2.423a.604.604 0 01-.66.132.604.604 0 01-.198-.132l-2.424-2.423a.606.606 0 11.857-.857l1.39 1.39v-5.81a.606.606 0 111.212 0v5.81z"
        fill="#000"
      />
      <mask
        id="a-AtoZSortingIcon"
        style={{
          maskType: "alpha"
        }}
        maskUnits="userSpaceOnUse"
        x={3}
        y={3}
        width={14}
        height={14}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.726 9.355a.608.608 0 00.78-.354l.308-.82H6.7l.307.82a.606.606 0 001.135-.426L6.325 3.726a.607.607 0 00-1.135 0L3.372 8.575a.607.607 0 00.354.78zm2.031-3.69l.49 1.305h-.979l.49-1.305zm2.303 5.91l-2.909 3.88h2.425a.606.606 0 110 1.211H3.938a.606.606 0 01-.485-.969l2.91-3.879H3.938a.606.606 0 110-1.212h3.636a.606.606 0 01.485.97zm2.546-.97a.606.606 0 101.212 0V5.403l1.39 1.39a.604.604 0 00.857 0 .606.606 0 000-.857L11.64 3.512a.605.605 0 00-.858 0L8.359 5.935a.606.606 0 10.857.857l1.39-1.39v5.204zm3.636 3.992l1.39-1.39a.606.606 0 11.857.858l-2.424 2.423a.604.604 0 01-.66.132.604.604 0 01-.198-.132l-2.424-2.423a.606.606 0 11.857-.857l1.39 1.39v-5.81a.606.606 0 111.212 0v5.81z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#a-AtoZSortingIcon)">
        <path fill="#FCAD01" d="M0 0H20V20H0z" />
      </g>
    </svg>
  )
}

