import React, { useState, useEffect } from 'react';
import  { InputField } from '../../../components/form';
import { toggleModal, setcomponentParamenters, setCustomComponentName } from '../../../store/workflow';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/types';
import classNames from 'classnames';
import {  SelectField } from '../../../components/form/select-field';
import { Formik, Field, FieldArray } from 'formik';
import { Row, Col, Button, Modal } from 'antd';
import { object, string, InferType, boolean, number, array } from 'yup';
import { ToggleFieldFormik } from '../../../components/form/toggle-field-formik';
import { useDebounce, useDidUpdate } from 'rooks';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import HandGrab from '../../../components/builder/sql/handGrab';
import { cloneDeep } from 'lodash';
import { uuid } from 'uuidv4';
import { errorAlert } from '../../../components/toastify/notify-toast';
import { getInitialCustombuilderValues } from '../enums';
import { ShowWhenTrue } from '../../../helpers';
import { newComponentHandler } from '../../../api/new-custom-component';
import { noEmptySpaceRegex, noSpecialCharactersRegex } from '@constants/enums';
interface ComponentParameters {
    inputPorts: number;
    outputPorts: number;
    argsCount: number;
}
const componentParameterValidationSchema = object().shape({
    name: string()
        .required('Name is required.')
        .matches(noSpecialCharactersRegex,
            'No Special Characters allowed'
        )
        .matches(noEmptySpaceRegex,
            'Blank spaces  not allowed'
        ),
    functionType: string()
        .required('Function Type is required.'),
    componentDesc: string().max(300,'Component Description has a maximum limit of 300 characters.'),
    functionName: string()
        .required('Function Name is required.')
        .matches(noSpecialCharactersRegex,
            'No Special Characters allowed'
        )
        .matches(/^([A-z0-9!@#$%^&*().,<>{}[\]<>?_=+\-|;:'"])*[^\s]\1*$/,
            'No Spaces allowed'
        )
        .matches(noEmptySpaceRegex,
            'Blank spaces  not allowed'
        ),
    inputPorts: number()
        .required('Input Ports is required.'),
    outputPorts: number()
        .required('Output Ports is required.'),
    noOfArgs: number(),
    functionString: string(),
    extraFn: string(),
    importCode: string(),
    outputList: array(),
    inputList: array(),
    argList: array().of(
        object().shape({
            id: string(),
            name: string()
                .required('Argument Title is required.')
                .matches(noSpecialCharactersRegex,
                    'No Special Characters allowed'
                )
                .matches(/^([A-z0-9!@#$%^&*().,<>{}[\]<>?_=+\-|;:'"])*[^\s]\1*$/,
                    'No Spaces allowed'
                )
                .matches(noEmptySpaceRegex,
                    'Blank spaces  not allowed'
                ),
            title: string()
                .required('Title is required.')
                .matches(noSpecialCharactersRegex,
                    'No Special Characters allowed'
                )
                .matches(noEmptySpaceRegex,
                    'Blank spaces  not allowed'
                ),
            typeOf: string()
                .required('TypeOf is required.')
                .matches(noEmptySpaceRegex,
                    'Blank spaces  not allowed'
                ),
            variable_type: string()
                .required('variable type is required.')
                .matches(/^[a-zA-ZÀ-ÿ '-]*[a-zA-ZÀ-ÿ'-][a-zA-ZÀ-ÿ '-]*/,
                    'Blank spaces  not allowed'
                ),
            defaultValue: string()
                .when("typeOf", {
                    is: "dropdown",
                    then: string().required("Default Value is required.")
                  })
                .test({
                    name: 'duplicate value',
                    exclusive: false,
                    params: { },
                    message: 'Duplicate Values are not allowed',
                    test: function (value) {
                        // You can access the dropdown field with `this.parent`.
                        if(this.parent.typeOf === 'dropdown' && value) {
                            const str_array = value.split(',');
                            const duplicateboolean =  new Set(str_array).size !== str_array.length;
                            if (duplicateboolean) { return false; } else { return true; }
                        } else {
                            return true;
                        }
                        
                    },
                }),
            tooltip: string(),
            required: boolean()
                .required('Default Value is required.'),
        })
    )
});
type componentParameterSchemaType = InferType<typeof componentParameterValidationSchema>
interface CustomComponentParametersModalProps {
    CustomComponentParametersSuccess: (arg0: ComponentParameters) => void;

}

const typeOfList = [
    { label: 'Text', value: 'text' },
    { label: 'Dropdown', value: 'dropdown' }];
const variableTypeList = [
    { label: 'Boolean', value: 'boolean' },
    { label: 'String', value: 'string' },
    { label: 'Integer', value: 'integer' },
    { label: 'Number', value: 'number' },
    { label: 'Array', value: 'array' },
    { label: 'Float', value: 'float' }];

const functionList = [{ label: 'Python', value: 'python' },
    { label: 'PySpark', value: 'pyspark' }];


export const CustomComponentParametersModal: React.FC<CustomComponentParametersModalProps> = ({ CustomComponentParametersSuccess }) => {
    const dispatch = useDispatch();
    const { activeExecutionEnv } = useSelector((store: RootState) => store.CommonReducer);
    const [argsCount, setArgsCount] = useState( activeExecutionEnv === 'spark' ? 1 : 0);
    const [indicatorHeight, setIndicatorHeight] = useState('55px');
    const { componentParamenters, 
        customComponentName: { name: customComponentName },
        updateComponentParameter: updateParamterBoolean,
        showModal: { customComoponentParameters: showModal }
    } = useSelector((store: RootState) => store.WorkflowReducer);
    const [duplicateComponentNameError, toggleDuplicateComponentNameError] = useState(false);


    const updateComponentId = useSelector((store: RootState) => store.WorkflowReducer.customComponentUpdateId);
    
    const [initialValues, setUpdateValues]: any = useState(getInitialCustombuilderValues());
    const handleIndicator = () => {
        const type = 'from';
        let handleGrab: any = document.querySelectorAll(`#${type}Holder .query_position_indicator`);
        if (handleGrab.length > 0) {
            const fromText: any = document.getElementById(`${type}Text`);
            handleGrab = handleGrab[handleGrab.length - 1];
            const rect = handleGrab.getBoundingClientRect();
            const rect1 = fromText.getBoundingClientRect();
            const element: any = document.getElementById(`indicator_${type}`);
            element.style.height = (Math.abs(rect.y - rect1.y) - rect.height - rect1.height + 5) + 'px';
            const height = (Math.abs(rect.y - rect1.y) - rect.height - rect1.height + 5) + 'px';
            setIndicatorHeight(height);
        }
    };
    useDidUpdate(() => {
        if (updateParamterBoolean && updateComponentId !== 0) {
            setUpdateValues({ ...componentParamenters, name: componentParamenters.name || customComponentName });
        } else {
            setUpdateValues({ ...componentParamenters, name: componentParamenters.name || customComponentName });
        }
        setTimeout(() => {
            handleIndicator();
        }, 1000);
    }, [updateParamterBoolean, updateComponentId, showModal]);

    useEffect(() => {
        setUpdateValues({ ...componentParamenters, name: componentParamenters.name || customComponentName });
        setTimeout(() => {
            handleIndicator();
        }, 1000);
    }, [componentParamenters, customComponentName]);


    function toggleClose() {
        dispatch(toggleModal('customComoponentParameters', false));
    }

    const handleShowCustomComponentEditorModal = (values: any) => {
        dispatch(setcomponentParamenters(values));
        CustomComponentParametersSuccess(values);
        // dispatch(setCustomComponentName({ name: values.name }));
        toggleClose();
    };

    function handleComponentParameters(values: any) {
        const valuesArgList = values.argList;
        if(!duplicateComponentNameError)
            if (valuesArgList.length > 0) {
                const nameArr: any = [];
                valuesArgList.forEach((element: any) => {
                    nameArr.push(element.name);
                });
                if (new Set(nameArr).size !== nameArr.length) {
                    errorAlert('Duplicate Argument Name found, Please have unique Argument Name');
                } else {
                    handleShowCustomComponentEditorModal(values);
                }
            } else {
                handleShowCustomComponentEditorModal(values);
            }
        

    }
    function setRemoveInputString(params: number, _setValues: any, values: any) {
        const inputDfList: any = values.inputList;
        inputDfList.pop();
        _setValues('inputList', inputDfList);
    }
    // function setRemoveoutputString(params: number, _setValues: any, values: any) {
    //     const outputListString: any = values.outputList[0];
    //     let outputListArr: any = outputListString.split('return');
    //     const outputlist = outputListArr[1];
    //     const removearr = outputlist.split(',');
    //     removearr.pop();
    //     const outstring = removearr.join(',');
    //     outputListArr[1] = outstring;
    //     outputListArr = [outputListArr.join('return')];
    //     _setValues('outputList', outputListArr);
    // }
    function setInputString(params: number, _setValues: any, values: any) {
        if (values.inputList === '') {
            let inputDfList = '';
            let inputIgnoreString = '';
            for (let i = 1; i <= params; i++) {
                const dfList = `df${i},`;
                const ignString = `#df${i} = ignore \r\n`;
                inputIgnoreString = inputIgnoreString.concat(ignString);
                inputDfList = inputDfList.concat(dfList);
            }
            _setValues('inputList', inputDfList);
        } else {
            const inputDfList: any = values.inputList;
            const valuelen = values.inputList.length;
            if (params !== valuelen) {
                for (let i = valuelen + 1; i <= params; i++) {
                    const dfList = `df${i}`;
                    inputDfList.push(dfList);
                }
            }
            _setValues('inputList', inputDfList);
        }
    }
    function setOutputString(params: number, _setValues: any, values: any) {
        if (params === 1) {
            const Dflist = ['   return output1'];
            _setValues('outputList', Dflist);
        } else if (params > 7) {
            // setOutputportCount(7);
            _setValues('outputPorts', 7);
            errorAlert('Only 7 ports are allowed');
        } else {
            const outputStringArr = values.outputList[0];
            const returnarr = outputStringArr.split('return');
            let outputlistArr: any;
            if (returnarr.includes(',')) {
                outputlistArr = returnarr;
            } else {
                outputlistArr = returnarr[1].split(',');
            }
    
            for (let i = outputlistArr.length + 1; i <= params; i++) {
                const Dflist = `output${i}`;
                outputlistArr.push(Dflist);
            }
            const returnstring = outputlistArr.join(',');
            returnarr[1] = returnstring;
            const outputList = [returnarr.join('return')];
            _setValues('outputList', outputList);
        }
    }

    const handleDecreaseCount = (params: string, setValue: any, formValues: any) => {
        if (params === 'input') {
            setValue('inputPorts', formValues.inputPorts - 1);
            // setInputportCount(inputportCount - 1);
            setRemoveInputString(formValues.inputPorts - 1, setValue, formValues);
        } else if (params === 'output') {
            setValue('outputPorts', formValues.outputPorts - 1);
            // setOutputportCount(outputportCount - 1);
            // setRemoveoutputString(formValues.inputPorts - 1, setValue, formValues);
            setOutputString(formValues.outputPorts - 1, setValue, formValues);
        } else {
            setValue('noOfArgs', argsCount - 1);
            setArgsCount(argsCount - 1);
        }
    };
    const handleIncreaseCount = (params: string, setValue: any, formValues: any) => {
        if (params === 'input') {
            setValue('inputPorts', formValues.inputPorts + 1);
            // setInputportCount(inputportCount + 1);
            setInputString(formValues.inputPorts + 1, setValue, formValues);
        } else if (params === 'output') {
            setValue('outputPorts', formValues.outputPorts + 1);
            // setOutputportCount(outputportCount + 1);
            setOutputString( formValues.outputPorts + 1, setValue, formValues);
        } else {
            setValue('noOfArgs', argsCount + 1);
            setArgsCount(argsCount + 1);
        }
    };

    const handleDraggableDrop = (result: any, values: any) => {
        if (result.destination) {
            let cloneValues = cloneDeep(values);
            const _argList: any = Array.from(cloneValues.argList);
            const [removed] = _argList.splice(result.source.index, 1);
            _argList.splice(result.destination.index, 0, removed);
            cloneValues = { ...cloneValues, ...{argList: _argList } };
            setUpdateValues(cloneValues);
            setTimeout(() => {
                handleIndicator();
            }, 1000);
        }
    };
    const handleDraggableStart = () => {
        document.body.classList.add('editorTab');
        document.body.classList.add('queryBuilder');
    };

    const handleNameValidationFailure = () => {
        toggleDuplicateComponentNameError(true);
        
    };


    const handleNameValidationSuccess = () => {
        toggleDuplicateComponentNameError(false);
    };

    const handleValidateComponentName = useDebounce((componentName: string) => {
        if(componentName)
            if(!updateParamterBoolean || (updateParamterBoolean && updateComponentId !== 0 && componentName !== customComponentName))
                newComponentHandler.getComponentNameValidation(componentName, activeExecutionEnv, handleNameValidationSuccess, handleNameValidationFailure);
            else handleNameValidationSuccess();
        else handleNameValidationSuccess();
    }, 300);

    const handleClickCancel = () => {
        dispatch(setcomponentParamenters(getInitialCustombuilderValues()));
        dispatch(setCustomComponentName({ name: ''}));
        toggleClose();
    };

    return (

        <div>
            <Modal
                title={`${customComponentName} Component Builder`}
                visible={showModal}
                // width={'1200px'}
                // bodyStyle={{ height: '650px', paddingBottom: '25px' }}
                okButtonProps={{ style: { display: 'none' }}}
                cancelButtonProps={{ style: { display: 'none' } }}
                className="editorTab customComponentParameters__modal"
            >
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={handleComponentParameters as (arg0: componentParameterSchemaType) => any}
                    validationSchema={componentParameterValidationSchema}
                    render={({ handleSubmit, setFieldValue, values }) => (
                        <form 
                            onSubmit={handleSubmit}
                        >
                            <div
                                className="component_parameter_wrapper"    
                            >
                                <Row gutter={[24, 8]}>
                                    <Col span={7}>
                                        <InputField
                                            name="name"
                                            label="Name of the Component"
                                            autoComplete="off"
                                            onChange={(e) => handleValidateComponentName(e)}
                                            showError={duplicateComponentNameError}
                                            autoFocus
                                            required
                                        >
                                            {duplicateComponentNameError && 
                                                <span className="inputfield__errormessage" role="error-message">
                                                    Name exists, use a different name
                                                </span>
                                            }
                                        </InputField>
                                    </Col>
                                    <Col span={4} offset={1}>    
                                        <div className="portTicker__box" >
                                            <span className="portType"> Input Ports</span>
                                            <div className="portTicker__Btnbox">
                                                <button
                                                    onClick={() => handleDecreaseCount('input', setFieldValue, values)}
                                                    disabled={(values.inputPorts <= 0)}
                                                    type="button"
                                                    className="decrement__btn"
                                                    id='input_ports_decrement__btn'
                                                >
                                                    <span>&#8722;</span>
                                                </button>
                                                <Field className={classNames('countText', { 'disabled': (values.inputPorts  <= 0) })} type="text" name="inputPorts" disabled />
                                                <button
                                                    onClick={() => handleIncreaseCount('input', setFieldValue, values)}
                                                    disabled={false}
                                                    type="button"
                                                    className="increment__btn"
                                                    id='input_ports_increment__btn'
                                                >
                                                    <span>&#43;</span>
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={4} offset={0}>    
                                        <div className="portTicker__box" >
                                            <span className="portType"> Output Ports</span>
                                            <div className="portTicker__Btnbox">
                                                <button
                                                    onClick={() => handleDecreaseCount('output', setFieldValue, values)}
                                                    disabled={(values.outputPorts <= 0)}
                                                    type="button"
                                                    className="decrement__btn"
                                                    id='output_ports_decrement__btn'
                                                >
                                                    <span>&#8722;</span>
                                                </button>
                                                <Field className={classNames('countText', { 'disabled': (values.outputPorts <= 0) })} type="text" name="outputPorts" disabled />
                                                <button
                                                    onClick={() => handleIncreaseCount('output', setFieldValue, values)}
                                                    disabled={false}
                                                    type="button"
                                                    className="increment__btn"
                                                    id='output_ports_increment__btn'
                                                >
                                                    <span>&#43;</span>
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={[24, 8]}>
                                    <Col span={7}>
                                        <InputField
                                            label="Function Name"
                                            name="functionName"
                                            infoText={'The function name given in this text box is used as the main function and needs to be the first function after imports'}
                                            placeholder="Enter Function Name"
                                            required
                                        />
                                    </Col>
                                    <Col span={7}>    
                                        {
                                            activeExecutionEnv === 'spark'? (
                                                <SelectField
                                                    name="functionType"
                                                    label="Function Type"
                                                    options={functionList}
                                                />
                                            ) : null                                            
                                        }
                                    </Col>
                                </Row>
                                <Row justify="center" gutter={[24, 8]}>
                                    <Col span={24}>      <div>
                                        <InputField
                                            label="Component Description"
                                            name="componentDesc"
                                            type="textarea"
                                            placeholder="Enter Description of Component"
                                            // required
                                        //   className={'width20'}
                                        />
                                    </div></Col>

                                </Row>
                                <Row justify="center" gutter={[24, 8]}>
                                    <Col span={24}>      <div>
                                        <InputField
                                            label="Import Statements"
                                            name="importCode"
                                            type="textarea"
                                            placeholder="Enter the import statements"
                                        />
                                    </div></Col>
                                </Row>
                                {/* <Row> */}
                                <div 
                                    className="editorTab argsList__box" 
                                >
                                    <Row style={{ border: '1px solid rgba(102, 106, 141, 0.32)', borderRadius: '4px', padding: '5px' }}>
                                        <Col span={24}>
                                            <FieldArray name="argList"
                                                render={(arrayHelpers) => (
                                                    <div>
                                                        <span id="fromText" style={{ color: 'white', fontSize: '12px' }}>
                                                                Argument List
                                                        </span>
                                                        <Button
                                                            onClick={() => {
                                                                let cloneValues = cloneDeep(values);
                                                                const argList = cloneValues.argList;
                                                                const newintialValue: any  =  { id: uuid(), title: '', typeOf: 'text', defaultvalue: '', variable_type: 'string', tooltip: '' };
                                                                argList.push(newintialValue);
                                                                cloneValues = { ...cloneValues, ...{ 'argList': argList } };
                                                                setUpdateValues(cloneValues);
                                                                handleIncreaseCount('args', setFieldValue, values);
                                                                setTimeout(() => {
                                                                    handleIndicator();
                                                                }, 2000);
                                                            }}
                                                            shape="circle"
                                                            className="addButtonIcon"
                                                            style={{ marginLeft: '6px', fontSize: '17px' }}
                                                            size="small"
                                                            icon="plus"
                                                        />

                                                        <ShowWhenTrue show={values.argList.length > 0} >
                                                            <div id="indicator_from" style={{ height: indicatorHeight }} className="query_indicator indicator_component"></div>
                                                        </ShowWhenTrue>
                                                        <DragDropContext onBeforeDragStart={handleDraggableStart} onDragEnd={(result: any) => {
                                                            handleDraggableDrop(result, values);
                                                        }}>
                                                            <Droppable droppableId="droppableFrom">
                                                                {(provided) => (
                                                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                                                        <div id="fromHolder" style={{ width: '98%', padding: '5px 10px', margin: '10px', marginTop: 0 }}>
                                                                            {values.argList && values.argList.length > 0 ? (
                                                                                values.argList.map((arg, index) => {
                                                                                const currentValue = values.argList[index];
                                                                                return(
                                                                                    <div key={index}>
                                                                                        <Draggable key={`itemA_${index}`} draggableId={`draggableA${index}`} index={index}>
                                                                                            {(provided) => (
                                                                                                <div
                                                                                                    ref={provided.innerRef}
                                                                                                    {...provided.draggableProps}
                                                                                                    {...provided.dragHandleProps}
                                                                                                >
                                                                                                    <div className="drag"
                                                                                                        style={{
                                                                                                            backgroundColor: 'rgba(102, 106, 141, 0.72)',
                                                                                                            borderRadius: '2px',
                                                                                                            margin: '5px 0',
                                                                                                            padding: '5px',
                                                                                                            marginBottom:'45px'
                                                                                                        }}>
                                                                                                        <div
                                                                                                            style={{
                                                                                                                position: 'relative',
                                                                                                                width: '100%',
                                                                                                                height: '100px',
                                                                                                                zIndex: (100 - index) 
                                                                                                            }}
                                                                                                        >
                                                                                                            <div style={{ display: 'inline-block' }}>
                                                                                                                <div className="query_position_indicator arg_list_wrapper"></div>
                                                                                                                <div
                                                                                                                    {...provided.dragHandleProps}
                                                                                                                    style={{
                                                                                                                        display: 'flex',
                                                                                                                        position: 'absolute',
                                                                                                                        top: '60%',
                                                                                                                        left: '50%',
                                                                                                                        width: '100%',
                                                                                                                        transform: 'translate(-50%, -50%)',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <HandGrab />

                                                                                                                    <Row gutter={24}>

                                                                                                                        <Col id={`argList_${index}`} className="gutter-row" span={3}>
                                                                                                                            <InputField
                                                                                                                                label="Title"
                                                                                                                                name={`argList[${index}].title`}
                                                                                                                                placeholder="Enter Title Name"
                                                                                                                                infoText = {'Title Name'}
                                                                                                                                required
                                                                                                                            />
                                                                                                                        </Col>
                                                                                                                        <Col className="gutter-row" span={3}>
                                                                                                                            <InputField
                                                                                                                                label="Name"
                                                                                                                                name={`argList[${index}].name`}
                                                                                                                                placeholder="Enter Argument Name"
                                                                                                                                infoText = {'Argument Name'}
                                                                                                                                required
                                                                                                                                // onChange={() => {
                                                                                                                                //     handleArgNameChange(values.argList);
                                                                                                                                // }}
                                                                                                                            />
                                                                                                                        </Col>
                                                                                                                        <Col className="gutter-row" span={4}>
                                                                                                                            <SelectField
                                                                                                                                name={`argList[${index}].variable_type`}
                                                                                                                                label="Variable Type"
                                                                                                                                options={variableTypeList}
                                                                                                                                infoText = {'Variable Type'}
                                                                                                                                required
                                                                                                                            />
                                                                                                                        </Col>
                                                                                                                        <Col className="gutter-row" span={3}>
                                                                                                                            <SelectField
                                                                                                                                name={`argList[${index}].typeOf`}
                                                                                                                                label="Type Of"
                                                                                                                                infoText={'Type of Argument'}
                                                                                                                                options={typeOfList}
                                                                                                                                // autoSelectFirstOption = {true}
                                                                                                                                // onOptionClick={(option: any) => handleDefaultValue(option, setFieldValue, index)}
                                                                                                                                required
                                                                                                                            />
                                                                                                                        </Col>
                                                                                                                        <Col className="gutter-row" span={3}>
                                                                                                                            <InputField
                                                                                                                                label="Tooltip"
                                                                                                                                name={`argList[${index}].tooltip`}
                                                                                                                                placeholder="Enter Tooltip"
                                                                                                                                infoText={'Tooltip for the Argument'}
                                                                                                                                // required
                                                                                                                            />
                                                                                                                        </Col>
                                                                                                                        <Col className="gutter-row" span={4}>
                                                                                                                            <InputField
                                                                                                                                label="Default Value"
                                                                                                                                name={`argList[${index}].defaultValue`}
                                                                                                                                placeholder={(values.argList[index].typeOf === 'text') ? 'Text' : 'OPTION_CITY,OPTION_STATE,OPTION_COUNTRY'}
                                                                                                                                infoText={'Default value of Argument'}
                                                                                                                                required = {(values.argList[index].typeOf !== 'text')}
                                                                                                                            />
                                                                                                                        </Col>
                                                                                                                        <Col className="gutter-row input-field-options" span={3} offset={0}>
                                                                                                                            <ToggleFieldFormik className="comp-parameter-required-field" label="Required" name={`argList[${index}].required`} />
                                                                                                                            {currentValue.typeOf === typeOfList[0].value && currentValue.variable_type === "string" &&
                                                                                                                                <ToggleFieldFormik className="comp-parameter-required-field" label="Use Capture Schema" name={`argList[${index}].useCaptureSchema`} />
                                                                                                                            }
                                                                                                                        </Col>
                                                                                                                        <Col className="gutter-row" span={1} offset={0}>
                                                                                                                            <div className="removeIconArg">
                                                                                                                                <div className='portTicker__box'>
                                                                                                                                    <div className='portTicker__Btnbox'>
                                                                                                                                        <Button
                                                                                                                                            onClick={() => {
                                                                                                                                                handleDecreaseCount('args', setFieldValue, values); arrayHelpers.remove(index);
                                                                                                                                                let cloneValues = cloneDeep(values);
                                                                                                                                                const argList = cloneValues.argList;
                                                                                                                                                if (index > -1) {
                                                                                                                                                    argList.splice(index, 1);
                                                                                                                                                }
                                                                                                                                                cloneValues = { ...cloneValues, ...{ 'argList': argList } };
                                                                                                                                                setUpdateValues(cloneValues);
                                                                                                                                                setTimeout(() => {
                                                                                                                                                    handleIndicator();
                                                                                                                                                    // indicator.fromIndicator();
                                                                                                                                                }, 100);
                                                                                                                                            }}
                                                                                                                                            id="custom-btn"
                                                                                                                                            className="removeButtonIcon removeButtonIconArg "
                                                                                                                                            shape="circle"
                                                                                                                                            style={{
                                                                                                                                                marginRight: '5px'
                                                                                                                                            }}
                                                                                                                                            size="small"
                                                                                                                                            icon="close"
                                                                                                                                        />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                        </Draggable>


                                                                                    </div>
                                                                                )})
                                                                            ) : (
                                                                                <div className='dynamicKeyValueGenBox'>
                                                                                    <button className="addButton" type="button" onClick={() => {
                                                                                        handleIncreaseCount('args', setFieldValue, values); arrayHelpers.push('');
                                                                                    }}>
                                                                                                Click here to  Add a Argument
                                                                                    </button>
                                                                                </div>

                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )}

                                                            </Droppable>
                                                        </DragDropContext>

                                                    </div>
                                                )}
                                            />



                                        </Col>
                                    </Row>
                                </div>
                                {/* </Row> */}
                            </div>
                            
                            <div className="modalBtns__box">
                                <button
                                    className="fl-r btn-md btn-yellow"
                                    type="submit"
                                    id={'Component_Parameters_saveBtn'}
                                >
                                    Apply
                                </button>
                                <button
                                    className="fl-r btn-md btn-cancel"
                                    type="button"
                                    onClick={handleClickCancel}
                                    id={'Component_Parameters_cancelBtn'}
                                >
                                    Cancel
                                </button>

                            </div>
                        </form>
                    )}>
                </Formik>
            </Modal>
        </div>
    );
};
