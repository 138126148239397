import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Form, { InputField } from "../../../components/form";
import { SelectField } from "../../../components/form/select-field";
import { Modal } from "../../../components/modals";
import { useAppSelector } from "../../../store/hooks";
import { runOrPreviewWorkflowOnDatabricks, toggleModal } from "../../../store/workflow";
import * as yup from 'yup'
import { InformativeIcon } from "../assets/icons";
import { InPageSpinner } from "../../../components/spinners/in-page-spinner";
import { useGetRunPreviewClusters } from "@utils/useGetRunPreviewClusters";

const previewFormSchema = yup.object().shape({
    clusterId: yup.string(),
    noOfRecordsToIngest: yup.number().typeError('No of Records to Ingest is a number').required('This is a required field'),
    maxTimeout: yup.number().typeError('Max Timeout is a number').required('This is a required field').min(20, "Cannot be less than 20 seconds")
})

const StreamingPreviewWorkflowModal: React.FC<{}> = () => {
    const showModal = useAppSelector(store => store.WorkflowReducer.showModal.streamingPreviewWorkflow)
    const { activeTab } = useAppSelector(store => store.CanvasReducer.workflowEditor)
    const dispatch = useDispatch();
    const [formInitialValues, setInitialValues] = useState({ clusterId: '', noOfRecordsToIngest: 50, maxTimeout: 20 })
    const [showSpinner, toggleSpinner] = useState(false);
    const clusters = useGetRunPreviewClusters()    


    const toggleClose = () => {
        dispatch(toggleModal('streamingPreviewWorkflow', false))
    }

    

    useEffect(() => {
        if(showModal) {
            setInitialValues({ clusterId: !isEmpty(clusters) ? clusters[0].value: '', noOfRecordsToIngest: 50, maxTimeout: 20 })
        }
    }, [showModal])

    const onPreviewSuccess = () =>{ 
        toggleSpinner(false);
        toggleClose();
    }

    const onPreviewFailure = () =>{ 
        toggleSpinner(false);
    }

    const handleSubmit = (values: typeof formInitialValues) => {
        toggleSpinner(true)
        dispatch(runOrPreviewWorkflowOnDatabricks('workflowEditor', "preview", {
            ...values,
            activeTabId: activeTab.id,
            successCbForStatementSubmission: onPreviewSuccess,
            errorCbForPreview: onPreviewFailure
        }))
    }


    return(
        <Modal 
            isOpen={showModal} 
            toggleClose={toggleClose} 
            title="Preview Workflow"
            subtitle={activeTab.name}
            className="profileModal__container streamingPreviewWorkflowModal__container"
            showCloseMark
        >
            <Form 
                initialValues={formInitialValues} 
                onSubmit={handleSubmit}
                validationSchema={previewFormSchema}
                enableReinitialize
            >
                <SelectField 
                    name="clusterId"
                    options={clusters}
                    label="Choose a Cluster"
                />
                <InputField 
                    name="noOfRecordsToIngest"
                    label="No of Records to Ingest"
                    required
                />
                <InputField 
                    name="maxTimeout"
                    label="Maximum Timeout(in seconds)"
                    required
                />  
                <span className="infoMsg">
                    <InformativeIcon />
                    Preview will stop once the Number of records has been ingested or max timeout has been reached.</span>
                <div className="modalBtns__box">
                    <button 
                        className="btn-md btn-yellow"
                        // id="rename_btn_yellow"
                        type="submit"
                        disabled={showSpinner || isEmpty(clusters)}
                    >
                        {showSpinner ? 
                            <InPageSpinner size="XSmall" color="black" />
                            :
                            <span>Preview</span>
                        }
                    </button>
                    <button 
                        className="btn-md btn-cancel"
                        type="button"
                        // id="rename_btn_cancel"
                        onClick={toggleClose}
                    >
                        Cancel
                    </button>
                </div>
            </Form>

        </Modal>

    )
}

export { StreamingPreviewWorkflowModal };