import { MouseEvent } from 'react';
import { Action, ActionEvent, InputType } from '@projectstorm/react-canvas-core';
import  _ from 'lodash';
import { DiagramEngine, PortModel, PointModel, LinkModel } from '@projectstorm/react-diagrams';
import { MoveItemsState } from './move-items-state';

export class DragDiagramItemsState extends MoveItemsState<DiagramEngine> {
    constructor() {
        super();
        this.registerAction(
            new Action({
                type: InputType.MOUSE_UP,
                // @ts-ignore
                fire: (event: ActionEvent<MouseEvent>) => {
                    const item = this.engine.getMouseElement(event.event);
                    if (item instanceof PortModel) {
                        _.forEach(this.initialPositions, (position) => {
                            if (position.item instanceof PointModel) {
                                const link = position.item.getParent() as LinkModel;

                                // only care about the last links
                                if (link.getLastPoint() !== position.item) {
                                    return;
                                }
                                if (link.getSourcePort().canLinkToPort(item)) {
                                    link.setTargetPort(item);
                                    item.reportPosition();
                                    this.engine.repaintCanvas();
                                }
                            }
                        });
                    }
                }
            })
        );
    }
}