import { WorkflowCanvas } from "@components/workflow-canvas";
import { useAppSelector } from "@store/hooks";
import React, { useCallback, useMemo } from "react";

type Props = {
    fieldValue: string;
}

const UpdateComponentNameButton: React.FC<Props> = ({ fieldValue }) => {
    const [, setForceUpdate] = React.useState(0);
    const activeComponentId = useAppSelector(state => state.WorkflowReducer.activeComponentInfo?.id)
    const activeComponentModel = useMemo(() => {
        if(activeComponentId) {
            return WorkflowCanvas.getNode(activeComponentId)
        }
        return undefined
    }, [activeComponentId])

    const handleUpdateComponentName = useCallback(() => {
        if(activeComponentModel) {
            activeComponentModel.getOptions().title = fieldValue;
            WorkflowCanvas.repaintCanvas();
            // Update button is not getting disabled after updating the component name
            setForceUpdate(t => t +1);
        }
    }, [activeComponentModel, fieldValue])

    return(
        <button type="button" className="btn btn-grey btn-update-component-name" disabled={activeComponentModel?.getOptions().title === fieldValue} onClick={handleUpdateComponentName}>
            Update
        </button>
    )
}

export default UpdateComponentNameButton;